export const validatePhoneNumber = (input) => {
  const phoneNumberInput = input;
  const phoneNumber = phoneNumberInput.value;

  if (/^05\d{1}$/.test(phoneNumber)) {
    clearError(phoneNumberInput);
    return true;
  } else {
    const errorText = phoneNumberInput.getAttribute("data-error-text")
      ? phoneNumberInput.getAttribute("data-error-text")
      : "tel is invalid";
    setError(phoneNumberInput, errorText);
    return false;
  }
};

export function isIsraelNumber(input) {
  const phoneNumber = input.value;
  const israelMobilePrefixes = [
    "050",
    "052",
    "053",
    "054",
    "055",
    "056",
    "057",
    "058",
  ];

  const cleanedNumber = phoneNumber.trim().replace(/\D/g, "");

  return israelMobilePrefixes.some((prefix) =>
    cleanedNumber.startsWith(prefix)
  );
}

export const validateFullPhoneNumber = (input) => {
  const phoneNumberInput = input;
  const phoneNumber = phoneNumberInput.value;

  if (/^05\d{8}$/.test(phoneNumber)) {
    clearError(phoneNumberInput);
    return true;
  } else {
    const errorText = phoneNumberInput.getAttribute("data-error-text")
      ? phoneNumberInput.getAttribute("data-error-text")
      : "tel is invalid";
    setError(phoneNumberInput, errorText);
    return false;
  }
};
export const validateDateInput = (input) => {
  if (!input.value) {
    const errorText = input.getAttribute("data-error-text")
      ? input.getAttribute("data-error-text")
      : "Date is required";
    setError(input, errorText);
    return false;
  } else {
    clearError(input);
    return true;
  }
};


export const validateTextInput = (input, charsCount, wordsCount = null) => {
  const textInput = input;
  if (textInput?.value?.length < charsCount) {
    const errorText = textInput.getAttribute("data-error-text")
      ? textInput.getAttribute("data-error-text")
      : "input text is invalid";
    setError(textInput, errorText);
    return false;
  } else {
    if (
      wordsCount &&
      (input.value.split(" ").length !== wordsCount ||
        (input.value.split(" ").length === wordsCount &&
          input.value.split(" ")[wordsCount - 1] === ""))
    ) {
      const errorText = textInput.getAttribute("data-error-text")
        ? textInput.getAttribute("data-error-text")
        : "there must be 2 words";
      setError(textInput, errorText);
      return false;
    } else {
      clearError(textInput);
      return true;
    }
  }
};
export const validateEventDate = (isBirthYear, isBirthMonth, isBirthDay) => {
  // Get current date
  debugger;
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Construct input date with zero-indexed month
  const inputDate = new Date(isBirthYear, isBirthMonth - 1, isBirthDay);
  inputDate.setHours(0, 0, 0, 0);

  // Check if input date is before today
  if (inputDate < currentDate) {
    return 'Date has passed';
  }

  // Check if input date is not a valid date
  const isValidDate = (year, month, day) => {
    const maxDaysInMonth = new Date(year, month, 0).getDate();
    return day > 0 && day <= maxDaysInMonth;
  };
  
  if (!isValidDate(isBirthYear, isBirthMonth, isBirthDay)) {
    return 'Date not available';
  }

  // Check if input date is more than 30 days away
  const differenceInDays = Math.ceil((inputDate - currentDate) / (1000 * 60 * 60 * 24));
  if (differenceInDays > 30) {
    return 'Date too far';
  }

  // If none of the above conditions are met, the date is valid
  return 'Date is valid';
};
















export const validateName = (input, minCharsPerWord, minWords) => {
  const textInput = input;
  
  // Check if input value exists and is a string
  if (typeof textInput?.value !== 'string') {
    const errorText = textInput.getAttribute("data-error-text")
      ? textInput.getAttribute("data-error-text")
      : "Input text is invalid";
    setError(textInput, errorText);
    return false;
  }
  
  // Split the input value into words
  const words = textInput.value.trim().split(/\s+/);
  
  // Check if the number of words is less than the required minimum
  if (words.length < minWords) {
    const errorText = `Input must contain at least ${minWords} words`;
    setError(textInput, errorText);
    return false;
  }
  
  // Check each word to ensure it has at least minCharsPerWord characters
  for (const word of words) {
    if (word.length < minCharsPerWord) {
      const errorText = `Each word must contain at least ${minCharsPerWord} characters`;
      setError(textInput, errorText);
      return false;
    }
  }
  
  // If all checks pass, clear any existing error message
  clearError(textInput);
  return true;
};









export const validateEmailInput = (input) => {
  const emailInput = input;
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput.value)) {
    clearError(emailInput);
    return true;
  } else {
    const errorText = emailInput.getAttribute("data-error-text")
      ? emailInput.getAttribute("data-error-text")
      : "אימייל לא חוקי";
    setError(emailInput, errorText);
    return false;
  }
};

export const validateFixedCountInput = (input, count, isWholeNumber) => {
  const idInput = input;
  if (
    isWholeNumber &&
    idInput.value.length === count &&
    isIsraelNumber(idInput)
  ) {
    clearError(idInput);
    return true;
  } else if (isWholeNumber == undefined && idInput.value.length === count) {
    clearError(idInput);
    return true;
  } else {
    const errorText = idInput.getAttribute("data-error-text")
      ? idInput.getAttribute("data-error-text")
      : "must be 9-digit";
    setError(idInput, errorText);
    return false;
  }
};

export const inputMaxCount = (input, count, prefix) => {
  let value = input.value;
  if (value.length > count) value = value.slice(0, count);
  input.value = value;
  // if (prefix) {
  //   // const isIsraelNumberv = isIsraelNumber(input.value)
  //   // isIsraelNumber(value)
  // }
};

export const setError = (el, text) => {
  const error = el.parentElement.querySelector(".error");
  if (error) {
    error.innerText = text;
  }
  if (
    el.closest("label") &&
    !el.closest("label").classList.contains("input-error")
  ) {
    el.closest("label").classList.add("input-error");
  } else if (
    el.closest(".label") &&
    !el.closest(".label").classList.contains("input-error")
  ) {
    el.closest(".label").classList.add("input-error");
  }
};
export const clearError = (el) => {
  const error = el.parentElement.querySelector(".error");
  if (error) {
    error.innerText = "";
  }
  if (el.closest("label")) {
    if (el.closest("label").classList.contains("input-error")) {
      el.closest("label").classList.remove("input-error");
    }
  } else if (el.closest(".label")) {
    if (el.closest(".label").classList.contains("input-error")) {
      el.closest(".label").classList.remove("input-error");
    }
  }
};
