export const citiesText = `city_code,city_name,street_code,street_name
967 ,אבו ג'ווייעד (שבט) ,9000 ,אבו ג'ווייעד (שבט)
472 ,אבו גוש ,9000 ,אבו גוש
472 ,אבו גוש ,159 ,אל רשיד
472 ,אבו גוש ,142 ,אל-חנאא'
472 ,אבו גוש ,144 ,אלעבד עלי
472 ,אבו גוש ,134 ,בית הבד
472 ,אבו גוש ,119 ,ג'בר
472 ,אבו גוש ,161 ,דאוד עבדאללה
472 ,אבו גוש ,147 ,דולב
472 ,אבו גוש ,130 ,דרך הגוש
472 ,אבו גוש ,101 ,דרך השלום
472 ,אבו גוש ,105 ,דרך מעלה החמשה
472 ,אבו גוש ,109 ,האגוז
472 ,אבו גוש ,151 ,האורנים
472 ,אבו גוש ,150 ,האיריס
472 ,אבו גוש ,149 ,האלה
472 ,אבו גוש ,136 ,הארזים
472 ,אבו גוש ,139 ,הארמונות
472 ,אבו גוש ,123 ,הבושם
472 ,אבו גוש ,153 ,הברוש
472 ,אבו גוש ,117 ,הגורן
472 ,אבו גוש ,124 ,הגנים
472 ,אבו גוש ,137 ,הגפנים
472 ,אבו גוש ,118 ,הדובדבנים
472 ,אבו גוש ,102 ,הוואדי
472 ,אבו גוש ,140 ,הורד
472 ,אבו גוש ,106 ,הזיתים
472 ,אבו גוש ,132 ,החווה
472 ,אבו גוש ,112 ,החומה
472 ,אבו גוש ,162 ,החרוב
472 ,אבו גוש ,116 ,הלימון
472 ,אבו גוש ,111 ,המאפיה הישנה
472 ,אבו גוש ,138 ,המעברה
472 ,אבו גוש ,154 ,המפעל
472 ,אבו גוש ,129 ,המצפה
472 ,אבו גוש ,127 ,המשתלה
472 ,אבו גוש ,158 ,הנענע
472 ,אבו גוש ,156 ,הנרקיס
472 ,אבו גוש ,148 ,העוזרד
472 ,אבו גוש ,108 ,הפול
472 ,אבו גוש ,157 ,הפרחים
472 ,אבו גוש ,114 ,הרימונים
472 ,אבו גוש ,115 ,השדות
472 ,אבו גוש ,135 ,השושן
472 ,אבו גוש ,146 ,השזיף
472 ,אבו גוש ,103 ,השיכון
472 ,אבו גוש ,152 ,השקדים
472 ,אבו גוש ,133 ,התאנים
472 ,אבו גוש ,126 ,התות
472 ,אבו גוש ,104 ,התמר
472 ,אבו גוש ,160 ,חאג' עותמאן
472 ,אבו גוש ,125 ,חג' מוסא
472 ,אבו גוש ,113 ,יסמין
472 ,אבו גוש ,107 ,מחמוד רשיד
472 ,אבו גוש ,110 ,מרכז הכפר
472 ,אבו גוש ,128 ,נוטרדאם
472 ,אבו גוש ,122 ,נטאף
472 ,אבו גוש ,121 ,עבדאל עזיז
472 ,אבו גוש ,131 ,עבדאל רחמן
472 ,אבו גוש ,155 ,עמאוס
472 ,אבו גוש ,141 ,ענבר
472 ,אבו גוש ,143 ,ציפורן
472 ,אבו גוש ,120 ,שייח איסמעיל
473 ,אבו סנאן ,118 ,א-זייתון
473 ,אבו סנאן ,235 ,א-טאלע
473 ,אבו סנאן ,135 ,א-לוז
473 ,אבו סנאן ,214 ,א-נביא זכריא
473 ,אבו סנאן ,289 ,א-נור
473 ,אבו סנאן ,114 ,א-נח'יל
473 ,אבו סנאן ,112 ,א-נסים
473 ,אבו סנאן ,233 ,א-סאפי
473 ,אבו סנאן ,138 ,א-סדאקה
473 ,אבו סנאן ,226 ,א-סהל
473 ,אבו סנאן ,120 ,א-סנאבל
473 ,אבו סנאן ,236 ,א-סנאעה
473 ,אבו סנאן ,221 ,א-סרוואת
473 ,אבו סנאן ,222 ,א-רביע
473 ,אבו סנאן ,107 ,א-רוואבי
473 ,אבו סנאן ,151 ,א-רייחאן
473 ,אבו סנאן ,228 ,א-רסאפי
473 ,אבו סנאן ,141 ,א-שועלה
473 ,אבו סנאן ,204 ,א-שורוק
473 ,אבו סנאן ,218 ,א-שיח' ג'בר מועד
473 ,אבו סנאן ,131 ,א-שימאלי
473 ,אבו סנאן ,213 ,א-שפק
473 ,אבו סנאן ,196 ,א-תסאמוח
473 ,אבו סנאן ,9000 ,אבו סנאן
473 ,אבו סנאן ,6733 ,אבו-אלחסן
473 ,אבו סנאן ,229 ,אום כולת'ום
473 ,אבו סנאן ,6719 ,אזור תעשייה
473 ,אבו סנאן ,179 ,איבן היית'ם
473 ,אבו סנאן ,198 ,איבן חייאן
473 ,אבו סנאן ,110 ,איבן סינא
473 ,אבו סנאן ,232 ,איסמהאן
473 ,אבו סנאן ,6723 ,אל שוקפאן
473 ,אבו סנאן ,126 ,אל-אהראם
473 ,אבו סנאן ,105 ,אל-אוח'וה
473 ,אבו סנאן ,123 ,אל-אזהר
473 ,אבו סנאן ,177 ,אל-איח'לס
473 ,אבו סנאן ,203 ,אל-אמל
473 ,אבו סנאן ,129 ,אל-אנדלוס
473 ,אבו סנאן ,290 ,אל-אנואר
473 ,אבו סנאן ,220 ,אל-אסאס
473 ,אבו סנאן ,169 ,אל-אסיל
473 ,אבו סנאן ,152 ,אל-אשג'אר
473 ,אבו סנאן ,127 ,אל-בדר
473 ,אבו סנאן ,122 ,אל-בוסתאן
473 ,אבו סנאן ,149 ,אל-ביידר
473 ,אבו סנאן ,171 ,אל-בילסאן
473 ,אבו סנאן ,207 ,אל-בירוני
473 ,אבו סנאן ,161 ,אל-בראעם
473 ,אבו סנאן ,217 ,אל-ג'בל
473 ,אבו סנאן ,130 ,אל-הילאל
473 ,אבו סנאן ,172 ,אל-וורוד
473 ,אבו סנאן ,180 ,אל-ח'אנוקה
473 ,אבו סנאן ,153 ,אל-ח'ואריזמי
473 ,אבו סנאן ,219 ,אל-ח'יל
473 ,אבו סנאן ,133 ,אל-ח'לה
473 ,אבו סנאן ,237 ,אל-חארה
473 ,אבו סנאן ,111 ,אל-יאסמין
473 ,אבו סנאן ,174 ,אל-כנאיס
473 ,אבו סנאן ,117 ,אל-כרום
473 ,אבו סנאן ,144 ,אל-מדארס
473 ,אבו סנאן ,212 ,אל-מוסתקים
473 ,אבו סנאן ,208 ,אל-מותנבי
473 ,אבו סנאן ,199 ,אל-מחבה
473 ,אבו סנאן ,145 ,אל-מנארה
473 ,אבו סנאן ,109 ,אל-מסאג'יד
473 ,אבו סנאן ,134 ,אל-מעבר
473 ,אבו סנאן ,148 ,אל-מראחין
473 ,אבו סנאן ,128 ,אל-מראע
473 ,אבו סנאן ,215 ,אל-מרג'
473 ,אבו סנאן ,175 ,אל-מרג'אן
473 ,אבו סנאן ,160 ,אל-עאלי
473 ,אבו סנאן ,136 ,אל-עין
473 ,אבו סנאן ,224 ,אל-עמל
473 ,אבו סנאן ,108 ,אל-ענדליב
473 ,אבו סנאן ,201 ,אל-פג'ר
473 ,אבו סנאן ,116 ,אל-פראבי
473 ,אבו סנאן ,106 ,אל-פראהידי
473 ,אבו סנאן ,223 ,אל-קמה
473 ,אבו סנאן ,6720 ,אלג'ורה
473 ,אבו סנאן ,6731 ,אלהדאפה
473 ,אבו סנאן ,176 ,אלוחדה
473 ,אבו סנאן ,6718 ,אלחלקאת
473 ,אבו סנאן ,6711 ,אלחרוב ש חייל החדשה
473 ,אבו סנאן ,6701 ,אלחרוב ש חייל הישנה
473 ,אבו סנאן ,6715 ,אלמלה
473 ,אבו סנאן ,6724 ,אלמפצלה
473 ,אבו סנאן ,6732 ,אלע'וזה
473 ,אבו סנאן ,6712 ,אלעקבה
473 ,אבו סנאן ,165 ,אקלידס
473 ,אבו סנאן ,181 ,בוסנון
473 ,אבו סנאן ,243 ,ד"ר אחמד סעד
473 ,אבו סנאן ,242 ,דרך אל-ח'רוב
473 ,אבו סנאן ,247 ,דרך אל-עקבה
473 ,אבו סנאן ,241 ,דרך עשואן
473 ,אבו סנאן ,6721 ,הואדי
473 ,אבו סנאן ,6713 ,השכונה הדרומית
473 ,אבו סנאן ,192 ,טרופיקה
473 ,אבו סנאן ,291 ,טריק א-סלאלם
473 ,אבו סנאן ,6725 ,כרם אלרג'ומה
473 ,אבו סנאן ,121 ,מועאוייה
473 ,אבו סנאן ,6710 ,מרכז הכפר
473 ,אבו סנאן ,113 ,נג'מת א-סובוח
473 ,אבו סנאן ,104 ,סולטאן באשא אל-אטרש
473 ,אבו סנאן ,244 ,סלמאן עזאם
473 ,אבו סנאן ,288 ,עאטף משלב
473 ,אבו סנאן ,132 ,עבד אל-ווהאב
473 ,אבו סנאן ,250 ,עבד אל-חלים
473 ,אבו סנאן ,239 ,עבד-אללה ח'יר
473 ,אבו סנאן ,6716 ,עמדאן סעד
473 ,אבו סנאן ,6722 ,עשואן
473 ,אבו סנאן ,293 ,פארס ח'ורי
473 ,אבו סנאן ,156 ,פריד אל-אטרש
473 ,אבו סנאן ,6717 ,קריית החינוך
473 ,אבו סנאן ,6714 ,ראס אלכרם
473 ,אבו סנאן ,187 ,רח 200
473 ,אבו סנאן ,209 ,רח 300
473 ,אבו סנאן ,188 ,רח 210
473 ,אבו סנאן ,210 ,רח 310
473 ,אבו סנאן ,170 ,רח 120
473 ,אבו סנאן ,189 ,רח 220
473 ,אבו סנאן ,211 ,רח 320
473 ,אבו סנאן ,173 ,רח 130
473 ,אבו סנאן ,190 ,רח 230
473 ,אבו סנאן ,184 ,רח 330
473 ,אבו סנאן ,178 ,רח 140
473 ,אבו סנאן ,191 ,רח 240
473 ,אבו סנאן ,227 ,רח 340
473 ,אבו סנאן ,150 ,רח 150
473 ,אבו סנאן ,194 ,רח 250
473 ,אבו סנאן ,183 ,רח 160
473 ,אבו סנאן ,195 ,רח 260
473 ,אבו סנאן ,246 ,רח 70
473 ,אבו סנאן ,240 ,רח 170
473 ,אבו סנאן ,185 ,רח 180
473 ,אבו סנאן ,202 ,רח 280
473 ,אבו סנאן ,216 ,רח 90
473 ,אבו סנאן ,186 ,רח 190
473 ,אבו סנאן ,119 ,רח 290
473 ,אבו סנאן ,142 ,רח 41
473 ,אבו סנאן ,163 ,רח 51
473 ,אבו סנאן ,115 ,רח 2
473 ,אבו סנאן ,124 ,רח 22
473 ,אבו סנאן ,193 ,רח 82
473 ,אבו סנאן ,146 ,רח 53
473 ,אבו סנאן ,140 ,רח 63
473 ,אבו סנאן ,147 ,רח 54
473 ,אבו סנאן ,154 ,רח 105
473 ,אבו סנאן ,125 ,רח 25
473 ,אבו סנאן ,155 ,רח 45
473 ,אבו סנאן ,230 ,רח 55
473 ,אבו סנאן ,157 ,רח 46
473 ,אבו סנאן ,231 ,רח 56
473 ,אבו סנאן ,292 ,רח 76
473 ,אבו סנאן ,158 ,רח 47
473 ,אבו סנאן ,143 ,רח 108
473 ,אבו סנאן ,139 ,רח 38
473 ,אבו סנאן ,159 ,רח 48
473 ,אבו סנאן ,197 ,רח רח 270
473 ,אבו סנאן ,6702 ,שכ הכנסייה
473 ,אבו סנאן ,6730 ,שכונת המסגד
473 ,אבו סנאן ,6726 ,שכונת השוק
935 ,אבו סריחאן (שבט) ,9000 ,אבו סריחאן (שבט)
958 ,אבו עבדון (שבט) ,9000 ,אבו עבדון (שבט)
1042 ,אבו עמאר (שבט) ,9000 ,אבו עמאר (שבט)
932 ,אבו עמרה (שבט) ,9000 ,אבו עמרה (שבט)
968 ,אבו קורינאת (שבט) ,9000 ,אבו קורינאת (שבט)
1342 ,אבו קרינאת (יישוב) ,9000 ,אבו קרינאת (יישוב)
966 ,אבו רובייעה (שבט) ,9000 ,אבו רובייעה (שבט)
966 ,אבו רובייעה (שבט) ,102 ,אל קרעאן
961 ,אבו רוקייק (שבט) ,103 ,אבו כף
961 ,אבו רוקייק (שבט) ,106 ,אבו סבית
961 ,אבו רוקייק (שבט) ,9000 ,אבו רוקייק (שבט)
961 ,אבו רוקייק (שבט) ,107 ,טרשאן
1375 ,אבו תלול ,9000 ,אבו תלול
652 ,אבטין ,107 ,אבו בכר אלסדיק
652 ,אבטין ,9000 ,אבטין
652 ,אבטין ,116 ,אל בוח'ארי
652 ,אבטין ,106 ,אל בסאתין
652 ,אבטין ,117 ,אל הודא
652 ,אבטין ,119 ,אל ואדי
652 ,אבטין ,111 ,אל זהראא
652 ,אבטין ,104 ,אל זייתון
652 ,אבטין ,110 ,אל זעתר
652 ,אבטין ,120 ,אל ח'רוב
652 ,אבטין ,115 ,אל יאסמין
652 ,אבטין ,121 ,אל מוח'תאר
652 ,אבטין ,122 ,אל מנארה
652 ,אבטין ,105 ,אל מרכז
652 ,אבטין ,101 ,אל נח'יל
652 ,אבטין ,108 ,אל נרג'ס
652 ,אבטין ,114 ,אל סלאם
652 ,אבטין ,113 ,אל פרדוס
652 ,אבטין ,102 ,אל קודס
652 ,אבטין ,109 ,אל רמאן
652 ,אבטין ,118 ,אלבלוט
652 ,אבטין ,112 ,טיבה
652 ,אבטין ,103 ,עומר אבן אלחטאב
1275 ,אבטליון ,9000 ,אבטליון
1275 ,אבטליון ,101 ,בית הבד
1275 ,אבטליון ,102 ,בראשית
1275 ,אבטליון ,103 ,דרך ההר
1275 ,אבטליון ,104 ,האקליפטוס
1275 ,אבטליון ,105 ,הבקעה
1275 ,אבטליון ,106 ,הזית
1275 ,אבטליון ,107 ,החורש
1275 ,אבטליון ,108 ,יפה נוף
1275 ,אבטליון ,109 ,מצפה הימים
679 ,אביאל ,9000 ,אביאל
679 ,אביאל ,102 ,האלה
679 ,אביאל ,109 ,האלון
679 ,אביאל ,108 ,הברושים
679 ,אביאל ,103 ,הגפן
679 ,אביאל ,107 ,הזית
679 ,אביאל ,104 ,החרוב
679 ,אביאל ,106 ,הרימון
679 ,אביאל ,105 ,השקד
679 ,אביאל ,101 ,התאנה
1115 ,אביבים ,9000 ,אביבים
819 ,אביגדור ,9000 ,אביגדור
175 ,אביחיל ,9000 ,אביחיל
175 ,אביחיל ,110 ,בן צבי
175 ,אביחיל ,120 ,דרך הים
175 ,אביחיל ,121 ,דרך פרחים
175 ,אביחיל ,101 ,הבנים
175 ,אביחיל ,102 ,הגדוד העברי
175 ,אביחיל ,114 ,הדקל
175 ,אביחיל ,103 ,ההגנה
175 ,אביחיל ,113 ,ההדרים
175 ,אביחיל ,122 ,החורשה
175 ,אביחיל ,117 ,הלח"י
175 ,אביחיל ,104 ,העצמאות
175 ,אביחיל ,105 ,הפלמ"ח
175 ,אביחיל ,118 ,הפרדס
175 ,אביחיל ,123 ,הצבעוני
175 ,אביחיל ,119 ,הרותם
175 ,אביחיל ,106 ,השומר
175 ,אביחיל ,112 ,לוחמי גליפולי
175 ,אביחיל ,107 ,נהגי הפרדות
175 ,אביחיל ,111 ,עין העובד
175 ,אביחיל ,108 ,צה"ל
175 ,אביחיל ,115 ,שד בן גוריון
175 ,אביחיל ,116 ,שוורצברד
175 ,אביחיל ,109 ,תל חי
2052 ,אביטל ,9000 ,אביטל
1070 ,אביעזר ,9000 ,אביעזר
1220 ,אבירים ,9000 ,אבירים
182 ,אבן יהודה ,206 ,אביבית
182 ,אבן יהודה ,9000 ,אבן יהודה
182 ,אבן יהודה ,282 ,אבני החושן
182 ,אבן יהודה ,207 ,אגמון
182 ,אבן יהודה ,275 ,אדווה
182 ,אבן יהודה ,104 ,אדם
182 ,אבן יהודה ,109 ,אדמונית
182 ,אבן יהודה ,117 ,אהבת ציון
182 ,אבן יהודה ,264 ,אופק
182 ,אבן יהודה ,6517 ,אזור תעשייה
182 ,אבן יהודה ,310 ,אחוזת מישרים
182 ,אבן יהודה ,131 ,אילת השחר
182 ,אבן יהודה ,208 ,אירוס הארגמן
182 ,אבן יהודה ,271 ,אלמוג
182 ,אבן יהודה ,373 ,אלמוגן
182 ,אבן יהודה ,209 ,אמנון ותמר
182 ,אבן יהודה ,169 ,אנפה
182 ,אבן יהודה ,130 ,אסותא
182 ,אבן יהודה ,379 ,ארז
182 ,אבן יהודה ,190 ,אשל
182 ,אבן יהודה ,125 ,באר גנים
182 ,אבן יהודה ,315 ,בושמת
182 ,אבן יהודה ,158 ,בית השואבה
182 ,אבן יהודה ,133 ,בני בנימין
182 ,אבן יהודה ,210 ,ברקן
182 ,אבן יהודה ,285 ,ברקת
182 ,אבן יהודה ,211 ,גבעונית
182 ,אבן יהודה ,6516 ,גבעת רימון
182 ,אבן יהודה ,270 ,גל
182 ,אבן יהודה ,280 ,דבורה
182 ,אבן יהודה ,312 ,דגן
182 ,אבן יהודה ,213 ,דודאים
182 ,אבן יהודה ,214 ,דולב
182 ,אבן יהודה ,248 ,דן
182 ,אבן יהודה ,246 ,דניאל
182 ,אבן יהודה ,186 ,דפנה
182 ,אבן יהודה ,380 ,דרור
182 ,אבן יהודה ,170 ,דרך בין השדות
182 ,אבן יהודה ,215 ,דרך רבין
182 ,אבן יהודה ,205 ,האגוז
182 ,אבן יהודה ,216 ,האגס
182 ,אבן יהודה ,217 ,האודם
182 ,אבן יהודה ,218 ,האוכמניות
182 ,אבן יהודה ,276 ,האורזות
182 ,אבן יהודה ,151 ,האורן
182 ,אבן יהודה ,305 ,האיזמרגד
182 ,אבן יהודה ,107 ,האילנות
182 ,אבן יהודה ,303 ,האירוסים
182 ,אבן יהודה ,124 ,האלה
182 ,אבן יהודה ,106 ,האלון
182 ,אבן יהודה ,219 ,האתרוג
182 ,אבן יהודה ,150 ,הבאר
182 ,אבן יהודה ,108 ,הבנים
182 ,אבן יהודה ,153 ,הברוש
182 ,אבן יהודה ,152 ,הגבעה
182 ,אבן יהודה ,184 ,הגולן
182 ,אבן יהודה ,161 ,הגליל
182 ,אבן יהודה ,178 ,הגפן
182 ,אבן יהודה ,171 ,הגת
182 ,אבן יהודה ,204 ,הדובדבן
182 ,אבן יהודה ,220 ,הדליות
182 ,אבן יהודה ,102 ,הדס
182 ,אבן יהודה ,181 ,הדקל
182 ,אבן יהודה ,137 ,ההדרים
182 ,אבן יהודה ,127 ,ההסתדרות
182 ,אבן יהודה ,176 ,ההרדופים
182 ,אבן יהודה ,221 ,הורד
182 ,אבן יהודה ,185 ,הורדים
182 ,אבן יהודה ,113 ,הזית
182 ,אבן יהודה ,191 ,החרוב
182 ,אבן יהודה ,179 ,היבולים
182 ,אבן יהודה ,377 ,היונה
182 ,אבן יהודה ,260 ,היצירה
182 ,אבן יהודה ,281 ,הכינור
182 ,אבן יהודה ,148 ,הלוחמים
182 ,אבן יהודה ,118 ,המחתרת
182 ,אבן יהודה ,132 ,המיסדים
182 ,אבן יהודה ,147 ,המעפילים
182 ,אבן יהודה ,136 ,המתמיד
182 ,אבן יהודה ,143 ,הנגב
182 ,אבן יהודה ,135 ,הנוטע
182 ,אבן יהודה ,120 ,הנוטר
182 ,אבן יהודה ,101 ,הנופר
182 ,אבן יהודה ,192 ,הנורית
182 ,אבן יהודה ,183 ,הנרקיסים
182 ,אבן יהודה ,245 ,הסוף
182 ,אבן יהודה ,222 ,הסחלב
182 ,אבן יהודה ,110 ,הסייפן
182 ,אבן יהודה ,223 ,העירית
182 ,אבן יהודה ,114 ,העצמאות
182 ,אבן יהודה ,302 ,הערבה
182 ,אבן יהודה ,224 ,הפטל
182 ,אבן יהודה ,141 ,הפלמ"ח
182 ,אבן יהודה ,225 ,הפרגים
182 ,אבן יהודה ,304 ,הצאלון
182 ,אבן יהודה ,128 ,הקשת
182 ,אבן יהודה ,251 ,הרותם
182 ,אבן יהודה ,180 ,הרימונים
182 ,אבן יהודה ,182 ,הרקפות
182 ,אבן יהודה ,140 ,השומר
182 ,אבן יהודה ,149 ,השומרון
182 ,אבן יהודה ,243 ,השושן
182 ,אבן יהודה ,138 ,השיח
182 ,אבן יהודה ,311 ,השיטה
182 ,אבן יהודה ,115 ,השלום
182 ,אבן יהודה ,201 ,השלושה
182 ,אבן יהודה ,301 ,השקמה
182 ,אבן יהודה ,134 ,השרון
182 ,אבן יהודה ,177 ,התאנה
182 ,אבן יהודה ,111 ,התומר
182 ,אבן יהודה ,226 ,התות
182 ,אבן יהודה ,193 ,התעשיה
182 ,אבן יהודה ,175 ,התפוז
182 ,אבן יהודה ,258 ,התקומה
182 ,אבן יהודה ,142 ,ותיקים
182 ,אבן יהודה ,247 ,זויתן
182 ,אבן יהודה ,254 ,חבצלת
182 ,אבן יהודה ,103 ,חבצלת השרון
182 ,אבן יהודה ,265 ,חוף
182 ,אבן יהודה ,227 ,חלמונית
182 ,אבן יהודה ,252 ,חמנית
182 ,אבן יהודה ,154 ,חן
182 ,אבן יהודה ,194 ,חצב
182 ,אבן יהודה ,187 ,חרצית
182 ,אבן יהודה ,283 ,יהלום
182 ,אבן יהודה ,273 ,ים
182 ,אבן יהודה ,375 ,יסמין
182 ,אבן יהודה ,372 ,יערה
182 ,אבן יהודה ,228 ,כליל החורש
182 ,אבן יהודה ,196 ,כלנית
182 ,אבן יהודה ,188 ,כרכום
182 ,אבן יהודה ,229 ,לוטם
182 ,אבן יהודה ,255 ,לוע הארי
182 ,אבן יהודה ,307 ,לילך
182 ,אבן יהודה ,309 ,מור
182 ,אבן יהודה ,371 ,מורן
182 ,אבן יהודה ,202 ,מכבי
182 ,אבן יהודה ,318 ,מנטה
182 ,אבן יהודה ,157 ,מעיין
182 ,אבן יהודה ,172 ,מעלה הארגמן
182 ,אבן יהודה ,173 ,מעלה הלבונה
182 ,אבן יהודה ,174 ,מעלה הרתמים
182 ,אבן יהודה ,119 ,מרבד הקסמים
182 ,אבן יהודה ,378 ,מרגנית
182 ,אבן יהודה ,314 ,מרווה
182 ,אבן יהודה ,6504 ,נווה הדר
182 ,אבן יהודה ,259 ,נחל אל-על
182 ,אבן יהודה ,249 ,נחל סער
182 ,אבן יהודה ,261 ,נחל פולג
182 ,אבן יהודה ,262 ,נחל שניר
182 ,אבן יהודה ,274 ,נחשול
182 ,אבן יהודה ,189 ,ניצן
182 ,אבן יהודה ,250 ,נר הלילה
182 ,אבן יהודה ,198 ,סביון
182 ,אבן יהודה ,242 ,סהרון
182 ,אבן יהודה ,129 ,סיני
182 ,אבן יהודה ,203 ,סמ האלה
182 ,אבן יהודה ,253 ,סמ החרמון
182 ,אבן יהודה ,139 ,סמ הלוטוס
182 ,אבן יהודה ,162 ,סמ הניצנים
182 ,אבן יהודה ,122 ,סמ הקולנוע
182 ,אבן יהודה ,155 ,סמ השושנה
182 ,אבן יהודה ,126 ,סמ השישה
182 ,אבן יהודה ,123 ,סמ יעקובי
182 ,אבן יהודה ,381 ,סמ יערה
182 ,אבן יהודה ,200 ,סמטת ברקן
182 ,אבן יהודה ,212 ,סמטת גומא
182 ,אבן יהודה ,168 ,סנונית
182 ,אבן יהודה ,284 ,ספיר
182 ,אבן יהודה ,197 ,סתוונית
182 ,אבן יהודה ,167 ,עגור
182 ,אבן יהודה ,370 ,עדעד
182 ,אבן יהודה ,241 ,עופרית
182 ,אבן יהודה ,144 ,עליה
182 ,אבן יהודה ,145 ,עממי
182 ,אבן יהודה ,164 ,עפרוני
182 ,אבן יהודה ,160 ,עציון
182 ,אבן יהודה ,268 ,פלג
182 ,אבן יהודה ,230 ,פעמונית
182 ,אבן יהודה ,277 ,פרי ההדר
182 ,אבן יהודה ,231 ,צבעוני
182 ,אבן יהודה ,376 ,צבר
182 ,אבן יהודה ,266 ,צדף
182 ,אבן יהודה ,257 ,צה"ל
182 ,אבן יהודה ,279 ,צופית
182 ,אבן יהודה ,272 ,צוק
182 ,אבן יהודה ,232 ,צורית
182 ,אבן יהודה ,233 ,ציפורן
182 ,אבן יהודה ,146 ,קבוץ גלויות
182 ,אבן יהודה ,308 ,קורנית
182 ,אבן יהודה ,244 ,קחון
182 ,אבן יהודה ,234 ,קינמון
182 ,אבן יהודה ,235 ,קקטוס
182 ,אבן יהודה ,156 ,קרן היסוד
182 ,אבן יהודה ,316 ,רוזמרין
182 ,אבן יהודה ,121 ,ריחן
182 ,אבן יהודה ,267 ,ריף
182 ,אבן יהודה ,6510 ,רמת בן גוריון
182 ,אבן יהודה ,6500 ,רמת היובל
182 ,אבן יהודה ,6501 ,ש אסבסטונים
182 ,אבן יהודה ,6502 ,ש הותיקים
182 ,אבן יהודה ,6503 ,ש חן
182 ,אבן יהודה ,6505 ,ש יבנאי
182 ,אבן יהודה ,6506 ,ש מכבי
182 ,אבן יהודה ,6507 ,ש עובדים
182 ,אבן יהודה ,6514 ,ש עין יעקב
182 ,אבן יהודה ,6508 ,ש עממי
182 ,אבן יהודה ,6509 ,ש שכטר
182 ,אבן יהודה ,287 ,שבו
182 ,אבן יהודה ,116 ,שבזי
182 ,אבן יהודה ,163 ,שביל הנוטע
182 ,אבן יהודה ,278 ,שביל הפרדס
182 ,אבן יהודה ,236 ,שדמה
182 ,אבן יהודה ,286 ,שוהם
182 ,אבן יהודה ,112 ,שומשום
182 ,אבן יהודה ,269 ,שונית
182 ,אבן יהודה ,166 ,שחף
182 ,אבן יהודה ,199 ,שיזף
182 ,אבן יהודה ,6511 ,שכ הדסים
182 ,אבן יהודה ,6515 ,שכ פאר גנים
182 ,אבן יהודה ,6513 ,שכ קרוונים
182 ,אבן יהודה ,165 ,שלדג
182 ,אבן יהודה ,237 ,שלהבת
182 ,אבן יהודה ,239 ,שן הארי
182 ,אבן יהודה ,238 ,שנהב
182 ,אבן יהודה ,306 ,שקד
182 ,אבן יהודה ,159 ,תבור
182 ,אבן יהודה ,256 ,תדהר
182 ,אבן יהודה ,317 ,תורמוס
182 ,אבן יהודה ,313 ,תירוש
182 ,אבן יהודה ,6512 ,תל צור
182 ,אבן יהודה ,263 ,תמר
182 ,אבן יהודה ,240 ,תפוח
1081 ,אבן מנחם ,9000 ,אבן מנחם
783 ,אבן ספיר ,9000 ,אבן ספיר
783 ,אבן ספיר ,109 ,אגט
783 ,אבן ספיר ,107 ,אודם
783 ,אבן ספיר ,102 ,ברקת
783 ,אבן ספיר ,111 ,גביש
783 ,אבן ספיר ,112 ,גרניט
783 ,אבן ספיר ,101 ,החרסית
783 ,אבן ספיר ,106 ,חלמיש
783 ,אבן ספיר ,110 ,טופז
783 ,אבן ספיר ,103 ,יהלום
783 ,אבן ספיר ,105 ,לשם
783 ,אבן ספיר ,108 ,צור
783 ,אבן ספיר ,104 ,שוהם
400 ,אבן שמואל ,9000 ,אבן שמואל
400 ,אבן שמואל ,113 ,דרך הפרחים
400 ,אבן שמואל ,101 ,דרך הראשונים
400 ,אבן שמואל ,112 ,האודם
400 ,אבן שמואל ,122 ,האירוס
400 ,אבן שמואל ,127 ,האנפה
400 ,אבן שמואל ,106 ,הברקת
400 ,אבן שמואל ,103 ,הגפן
400 ,אבן שמואל ,120 ,הדוכיפת
400 ,אבן שמואל ,114 ,הדרור
400 ,אבן שמואל ,125 ,הורד
400 ,אבן שמואל ,104 ,הזית
400 ,אבן שמואל ,115 ,הזמיר
400 ,אבן שמואל ,107 ,החושן
400 ,אבן שמואל ,109 ,היהלום
400 ,אבן שמואל ,118 ,הכלנית
400 ,אבן שמואל ,116 ,הנורית
400 ,אבן שמואל ,117 ,הנחליאלי
400 ,אבן שמואל ,123 ,הנרקיס
400 ,אבן שמואל ,126 ,הסביון
400 ,אבן שמואל ,119 ,הסנונית
400 ,אבן שמואל ,110 ,הספיר
400 ,אבן שמואל ,121 ,העפרוני
400 ,אבן שמואל ,105 ,הרב אשר ליטוב
400 ,אבן שמואל ,102 ,הרימון
400 ,אבן שמואל ,124 ,השלדג
400 ,אבן שמואל ,111 ,השקד
400 ,אבן שמואל ,108 ,התאנה
4011 ,אבני איתן ,9000 ,אבני איתן
3793 ,אבני חפץ ,9000 ,אבני חפץ
3793 ,אבני חפץ ,104 ,אודם
3793 ,אבני חפץ ,113 ,איבי הנחל
3793 ,אבני חפץ ,119 ,ארז
3793 ,אבני חפץ ,117 ,ברקת
3793 ,אבני חפץ ,111 ,גינת אגוז
3793 ,אבני חפץ ,120 ,גלבוע
3793 ,אבני חפץ ,107 ,גלעד
3793 ,אבני חפץ ,115 ,דרך הרא"ה
3793 ,אבני חפץ ,101 ,החרמון
3793 ,אבני חפץ ,114 ,הירדן
3793 ,אבני חפץ ,110 ,המור
3793 ,אבני חפץ ,105 ,חושן
3793 ,אבני חפץ ,122 ,טופז
3793 ,אבני חפץ ,106 ,יהלום
3793 ,אבני חפץ ,108 ,כרמל
3793 ,אבני חפץ ,112 ,לבונה
3793 ,אבני חפץ ,118 ,לשם
3793 ,אבני חפץ ,6501 ,מצפה חפץ
3793 ,אבני חפץ ,103 ,ספיר
3793 ,אבני חפץ ,109 ,שחר
3793 ,אבני חפץ ,6500 ,שכ מעלה יעקב
3793 ,אבני חפץ ,102 ,שניר
3793 ,אבני חפץ ,121 ,שקד
3786 ,אבנת ,9000 ,אבנת
1311 ,אבשלום ,9000 ,אבשלום
1311 ,אבשלום ,101 ,שכ קרוונים
3759 ,אדורה ,9000 ,אדורה
3759 ,אדורה ,101 ,האורן
3759 ,אדורה ,104 ,האלה
3759 ,אדורה ,102 ,האלון
3759 ,אדורה ,103 ,הברוש
113 ,אדירים ,9000 ,אדירים
1068 ,אדמית ,9000 ,אדמית
1068 ,אדמית ,115 ,ברקן
1068 ,אדמית ,107 ,האורן
1068 ,אדמית ,104 ,האלה
1068 ,אדמית ,118 ,האלון
1068 ,אדמית ,103 ,הארזים
1068 ,אדמית ,114 ,הברוש
1068 ,אדמית ,117 ,הדפנה
1068 ,אדמית ,116 ,ההדס
1068 ,אדמית ,110 ,הזית
1068 ,אדמית ,112 ,החרוב
1068 ,אדמית ,106 ,הלבנה
1068 ,אדמית ,105 ,השקד
1068 ,אדמית ,113 ,התאנה
1068 ,אדמית ,6002 ,כיכר גן חמד
1068 ,אדמית ,6003 ,כיכר הפרחים
1068 ,אדמית ,6004 ,כיכר מעלה ההר
1068 ,אדמית ,6001 ,כיכר נוף החרמון
1068 ,אדמית ,125 ,מורן
1068 ,אדמית ,102 ,נוף הים
1068 ,אדמית ,122 ,סמטת הכלניות
1068 ,אדמית ,119 ,סמטת הנרקיסים
1068 ,אדמית ,120 ,סמטת הסחלב
1068 ,אדמית ,121 ,סמטת הסייפן
1068 ,אדמית ,124 ,סמטת העירית
1068 ,אדמית ,123 ,סמטת הרקפות
1068 ,אדמית ,111 ,שביל הבוסתן
1068 ,אדמית ,109 ,שביל הבושם
1068 ,אדמית ,108 ,שביל הקשת
1068 ,אדמית ,101 ,שער הנוף
1123 ,אדרת ,9000 ,אדרת
446 ,אודים ,9000 ,אודים
446 ,אודים ,117 ,האורן
446 ,אודים ,114 ,האזדרכת
446 ,אודים ,118 ,האיריס
446 ,אודים ,108 ,הברוש
446 ,אודים ,116 ,הגבעה
446 ,אודים ,104 ,הדגן
446 ,אודים ,103 ,הדקל
446 ,אודים ,107 ,ההדרים
446 ,אודים ,119 ,החצב
446 ,אודים ,105 ,החרוב
446 ,אודים ,110 ,היקינטון
446 ,אודים ,120 ,הכלנית
446 ,אודים ,109 ,הנרקיס
446 ,אודים ,121 ,העירית
446 ,אודים ,106 ,הפקאן
446 ,אודים ,101 ,הרימון
446 ,אודים ,111 ,הרקפת
446 ,אודים ,123 ,השומר
446 ,אודים ,115 ,השיטה
446 ,אודים ,122 ,התלתן
446 ,אודים ,102 ,התמר
446 ,אודים ,112 ,סמ המגדל
446 ,אודים ,113 ,צאלון
4010 ,אודם ,9000 ,אודם
1046 ,אוהד ,9000 ,אוהד
2710 ,אום אל-פחם ,215 ,אבו ד'ר אלע'פארי
2710 ,אום אל-פחם ,217 ,אבו הורירה
2710 ,אום אל-פחם ,214 ,אבו חניפה
2710 ,אום אל-פחם ,451 ,אבו נואס
2710 ,אום אל-פחם ,6714 ,אבו סברי
2710 ,אום אל-פחם ,462 ,אבו פראס אלחמדאני
2710 ,אום אל-פחם ,450 ,אבו תמאם
2710 ,אום אל-פחם ,441 ,אבן אל מונדר
2710 ,אום אל-פחם ,463 ,אבן אל-מוקפע
2710 ,אום אל-פחם ,446 ,אבן אל-נדים
2710 ,אום אל-פחם ,198 ,אבן אלאתיר
2710 ,אום אל-פחם ,199 ,אבן אלג'אוזי
2710 ,אום אל-פחם ,202 ,אבן אלהיתם
2710 ,אום אל-פחם ,459 ,אבן אלח'שאב
2710 ,אום אל-פחם ,201 ,אבן אלנפיס
2710 ,אום אל-פחם ,382 ,אבן אלערבי
2710 ,אום אל-פחם ,200 ,אבן אלקיים
2710 ,אום אל-פחם ,454 ,אבן אסחאק
2710 ,אום אל-פחם ,203 ,אבן בטוטה
2710 ,אום אל-פחם ,208 ,אבן זיידון
2710 ,אום אל-פחם ,206 ,אבן ח'לדון
2710 ,אום אל-פחם ,205 ,אבן חזם
2710 ,אום אל-פחם ,211 ,אבן כת'יר
2710 ,אום אל-פחם ,212 ,אבן מאגה
2710 ,אום אל-פחם ,213 ,אבן מסעוד
2710 ,אום אל-פחם ,275 ,אבן סינא
2710 ,אום אל-פחם ,209 ,אבן סירין
2710 ,אום אל-פחם ,460 ,אבן עאמר
2710 ,אום אל-פחם ,210 ,אבן עבאס
2710 ,אום אל-פחם ,465 ,אבן פרנאס
2710 ,אום אל-פחם ,207 ,אבן רשד
2710 ,אום אל-פחם ,204 ,אבן תימיה
2710 ,אום אל-פחם ,9000 ,אום אל-פחם
2710 ,אום אל-פחם ,280 ,אום אלעבהר
2710 ,אום אל-פחם ,101 ,אומייה
2710 ,אום אל-פחם ,379 ,אח'ואן אלספא
2710 ,אום אל-פחם ,218 ,אחמד אבן חנבל
2710 ,אום אל-פחם ,485 ,אחמד זוויל
2710 ,אום אל-פחם ,477 ,אחמד ראמי
2710 ,אום אל-פחם ,470 ,איבן אל-נדם
2710 ,אום אל-פחם ,102 ,איוב
2710 ,אום אל-פחם ,429 ,איליאא
2710 ,אום אל-פחם ,455 ,אל מנסור
2710 ,אום אל-פחם ,368 ,אל ניל
2710 ,אום אל-פחם ,432 ,אל קיאמה
2710 ,אום אל-פחם ,375 ,אל-אהראם
2710 ,אום אל-פחם ,420 ,אל-אמין
2710 ,אום אל-פחם ,356 ,אל-גזאלי
2710 ,אום אל-פחם ,230 ,אל-זביר
2710 ,אום אל-פחם ,249 ,אל-ח'ליל
2710 ,אום אל-פחם ,248 ,אל-חמה
2710 ,אום אל-פחם ,471 ,אל-כואכבי
2710 ,אום אל-פחם ,234 ,אל-מוארדי
2710 ,אום אל-פחם ,235 ,אל-מעתצם
2710 ,אום אל-פחם ,452 ,אל-מקריזי
2710 ,אום אל-פחם ,232 ,אל-סיוטי
2710 ,אום אל-פחם ,228 ,אל-ראזי
2710 ,אום אל-פחם ,229 ,אל-ראשדון
2710 ,אום אל-פחם ,250 ,אל-רבאט
2710 ,אום אל-פחם ,251 ,אל-ריאץ'
2710 ,אום אל-פחם ,252 ,אל-שאם
2710 ,אום אל-פחם ,121 ,אלאג'באריה
2710 ,אום אל-פחם ,392 ,אלאדריסי
2710 ,אום אל-פחם ,313 ,אלאח'ווה
2710 ,אום אל-פחם ,412 ,אלאחסאן
2710 ,אום אל-פחם ,342 ,אלאמל
2710 ,אום אל-פחם ,116 ,אלאנדלוס
2710 ,אום אל-פחם ,315 ,אלאנואר
2710 ,אום אל-פחם ,425 ,אלאנסאר
2710 ,אום אל-פחם ,341 ,אלאסלאח
2710 ,אום אל-פחם ,122 ,אלאקואס
2710 ,אום אל-פחם ,288 ,אלאקסא
2710 ,אום אל-פחם ,180 ,אלארג'ואן
2710 ,אום אל-פחם ,287 ,אלארז
2710 ,אום אל-פחם ,314 ,אלארשאד
2710 ,אום אל-פחם ,219 ,אלאשערי
2710 ,אום אל-פחם ,289 ,אלבהגה
2710 ,אום אל-פחם ,181 ,אלבוסתאן
2710 ,אום אל-פחם ,478 ,אלבוסתאני
2710 ,אום אל-פחם ,371 ,אלבוראק
2710 ,אום אל-פחם ,221 ,אלבח'ארי
2710 ,אום אל-פחם ,124 ,אלבחרה
2710 ,אום אל-פחם ,220 ,אלבחתורי
2710 ,אום אל-פחם ,410 ,אלביארה
2710 ,אום אל-פחם ,487 ,אלביאתי
2710 ,אום אל-פחם ,290 ,אלבידר
2710 ,אום אל-פחם ,423 ,אלבילסאן
2710 ,אום אל-פחם ,222 ,אלבירוני
2710 ,אום אל-פחם ,182 ,אלבלוט
2710 ,אום אל-פחם ,317 ,אלבנאא
2710 ,אום אל-פחם ,316 ,אלברכאת
2710 ,אום אל-פחם ,481 ,אלג'ואהרי
2710 ,אום אל-פחם ,366 ,אלג'זאר
2710 ,אום אל-פחם ,298 ,אלג'נאן
2710 ,אום אל-פחם ,141 ,אלד'הר
2710 ,אום אל-פחם ,277 ,אלד'הרה
2710 ,אום אל-פחם ,311 ,אלהג'רה
2710 ,אום אל-פחם ,312 ,אלהדאיה
2710 ,אום אל-פחם ,337 ,אלהודא
2710 ,אום אל-פחם ,418 ,אלהלאל
2710 ,אום אל-פחם ,353 ,אלואדי
2710 ,אום אל-פחם ,178 ,אלוורוד
2710 ,אום אל-פחם ,435 ,אלוחדאת
2710 ,אום אל-פחם ,338 ,אלוחדה
2710 ,אום אל-פחם ,426 ,אלוטן
2710 ,אום אל-פחם ,339 ,אלופאא
2710 ,אום אל-פחם ,136 ,אלזהראא
2710 ,אום אל-פחם ,467 ,אלזהראוי
2710 ,אום אל-פחם ,187 ,אלזיתון
2710 ,אום אל-פחם ,397 ,אלזמח'שרי
2710 ,אום אל-פחם ,173 ,אלזנבק
2710 ,אום אל-פחם ,227 ,אלח'וארזמי
2710 ,אום אל-פחם ,132 ,אלח'יאם
2710 ,אום אל-פחם ,361 ,אלח'נסאא
2710 ,אום אל-פחם ,184 ,אלח'רוב
2710 ,אום אל-פחם ,304 ,אלחאמדון
2710 ,אום אל-פחם ,430 ,אלחג'אז
2710 ,אום אל-פחם ,434 ,אלחדת'
2710 ,אום אל-פחם ,458 ,אלחוסין
2710 ,אום אל-פחם ,322 ,אלחוריה
2710 ,אום אל-פחם ,324 ,אלחכמה
2710 ,אום אל-פחם ,466 ,אלחלילי
2710 ,אום אל-פחם ,395 ,אלחסן
2710 ,אום אל-פחם ,355 ,אלטברי
2710 ,אום אל-פחם ,330 ,אלטיבאת
2710 ,אום אל-פחם ,401 ,אלטייב סאלח
2710 ,אום אל-פחם ,297 ,אליאסמין
2710 ,אום אל-פחם ,367 ,אלירמוק
2710 ,אום אל-פחם ,145 ,אלכולייה
2710 ,אום אל-פחם ,334 ,אלכות'ר
2710 ,אום אל-פחם ,468 ,אלכנדי
2710 ,אום אל-פחם ,282 ,אלכנדק
2710 ,אום אל-פחם ,293 ,אלכראמה
2710 ,אום אל-פחם ,301 ,אללוז
2710 ,אום אל-פחם ,302 ,אללימון
2710 ,אום אל-פחם ,197 ,אלמאמון
2710 ,אום אל-פחם ,335 ,אלמג'ד
2710 ,אום אל-פחם ,150 ,אלמדינה
2710 ,אום אל-פחם ,303 ,אלמונתזה
2710 ,אום אל-פחם ,444 ,אלמות'נא
2710 ,אום אל-פחם ,146 ,אלמותנבי
2710 ,אום אל-פחם ,147 ,אלמחאג'נה
2710 ,אום אל-פחם ,351 ,אלמנאר
2710 ,אום אל-פחם ,294 ,אלמנארה
2710 ,אום אל-פחם ,421 ,אלמנזול
2710 ,אום אל-פחם ,440 ,אלמערי
2710 ,אום אל-פחם ,350 ,אלמערפה
2710 ,אום אל-פחם ,310 ,אלמרווה
2710 ,אום אל-פחם ,279 ,אלמרמאלה
2710 ,אום אל-פחם ,151 ,אלמרתפעה
2710 ,אום אל-פחם ,336 ,אלמשאעל
2710 ,אום אל-פחם ,413 ,אלנג'אח
2710 ,אום אל-פחם ,154 ,אלנוזהה
2710 ,אום אל-פחם ,352 ,אלנור
2710 ,אום אל-פחם ,196 ,אלנח'יל
2710 ,אום אל-פחם ,358 ,אלנסאיי
2710 ,אום אל-פחם ,177 ,אלנענאע
2710 ,אום אל-פחם ,453 ,אלנקשבנדי
2710 ,אום אל-פחם ,176 ,אלנרג'ס
2710 ,אום אל-פחם ,370 ,אלסאחל
2710 ,אום אל-פחם ,190 ,אלסבר
2710 ,אום אל-פחם ,377 ,אלסולטאן סולימאן
2710 ,אום אל-פחם ,306 ,אלסועדאא
2710 ,אום אל-פחם ,191 ,אלסופסאף
2710 ,אום אל-פחם ,138 ,אלסוק
2710 ,אום אל-פחם ,482 ,אלסיאב
2710 ,אום אל-פחם ,189 ,אלסינדיאן
2710 ,אום אל-פחם ,345 ,אלסלאם
2710 ,אום אל-פחם ,394 ,אלסמואאל
2710 ,אום אל-פחם ,427 ,אלסמוד
2710 ,אום אל-פחם ,424 ,אלסנאבל
2710 ,אום אל-פחם ,309 ,אלספא
2710 ,אום אל-פחם ,449 ,אלע'אפקי
2710 ,אום אל-פחם ,292 ,אלעג'מי
2710 ,אום אל-פחם ,417 ,אלעדאלה
2710 ,אום אל-פחם ,416 ,אלעהד
2710 ,אום אל-פחם ,448 ,אלעואם
2710 ,אום אל-פחם ,445 ,אלעז אבן עבד אלסלאם
2710 ,אום אל-פחם ,331 ,אלעזה
2710 ,אום אל-פחם ,332 ,אלעטאא
2710 ,אום אל-פחם ,278 ,אלעין
2710 ,אום אל-פחם ,378 ,אלעסקלאני
2710 ,אום אל-פחם ,476 ,אלעקאד
2710 ,אום אל-פחם ,362 ,אלפאתח
2710 ,אום אל-פחם ,333 ,אלפדאא
2710 ,אום אל-פחם ,174 ,אלפול
2710 ,אום אל-פחם ,369 ,אלפוראת
2710 ,אום אל-פחם ,346 ,אלפח'ר
2710 ,אום אל-פחם ,436 ,אלפיחאא
2710 ,אום אל-פחם ,347 ,אלפנאר
2710 ,אום אל-פחם ,357 ,אלפראבי
2710 ,אום אל-פחם ,457 ,אלפראהידי
2710 ,אום אל-פחם ,300 ,אלפרדוס
2710 ,אום אל-פחם ,389 ,אלפרזדק
2710 ,אום אל-פחם ,348 ,אלקאדי
2710 ,אום אל-פחם ,283 ,אלקאדסייה
2710 ,אום אל-פחם ,117 ,אלקאהרה
2710 ,אום אל-פחם ,118 ,אלקודס
2710 ,אום אל-פחם ,456 ,אלקלים
2710 ,אום אל-פחם ,419 ,אלקלם
2710 ,אום אל-פחם ,349 ,אלקנדיל
2710 ,אום אל-פחם ,296 ,אלקרנפול
2710 ,אום אל-פחם ,291 ,אלראפדין
2710 ,אום אל-פחם ,325 ,אלראפה
2710 ,אום אל-פחם ,185 ,אלרביע
2710 ,אום אל-פחם ,305 ,אלרודה
2710 ,אום אל-פחם ,134 ,אלרוחה
2710 ,אום אל-פחם ,299 ,אלרומאן
2710 ,אום אל-פחם ,326 ,אלרחמה
2710 ,אום אל-פחם ,171 ,אלריאחין
2710 ,אום אל-פחם ,172 ,אלריחאן
2710 ,אום אל-פחם ,135 ,אלריף
2710 ,אום אל-פחם ,415 ,אלרסאלה
2710 ,אום אל-פחם ,307 ,אלשאכרון
2710 ,אום אל-פחם ,139 ,אלשאע'ור
2710 ,אום אל-פחם ,376 ,אלשאפעי
2710 ,אום אל-פחם ,431 ,אלשג'רה
2710 ,אום אל-פחם ,437 ,אלשהבאא
2710 ,אום אל-פחם ,328 ,אלשועלה
2710 ,אום אל-פחם ,327 ,אלשורוק
2710 ,אום אל-פחם ,364 ,אלשיח' חסין
2710 ,אום אל-פחם ,344 ,אלת'נאא
2710 ,אום אל-פחם ,320 ,אלתואסול
2710 ,אום אל-פחם ,343 ,אלתופיק
2710 ,אום אל-פחם ,321 ,אלתוריה
2710 ,אום אל-פחם ,318 ,אלתחריר
2710 ,אום אל-פחם ,128 ,אלתסאמוח
2710 ,אום אל-פחם ,319 ,אלתעמיר
2710 ,אום אל-פחם ,403 ,אמנה בנת והב
2710 ,אום אל-פחם ,340 ,אפאק
2710 ,אום אל-פחם ,246 ,אריחא
2710 ,אום אל-פחם ,247 ,אשבליה
2710 ,אום אל-פחם ,254 ,בג'דאד
2710 ,אום אל-פחם ,284 ,בדר
2710 ,אום אל-פחם ,104 ,ביברז
2710 ,אום אל-פחם ,256 ,בירות
2710 ,אום אל-פחם ,255 ,בית אל-מקדס
2710 ,אום אל-פחם ,103 ,בלאל
2710 ,אום אל-פחם ,236 ,ג'אבר אבן חיאן
2710 ,אום אל-פחם ,373 ,ג'בל אלערב
2710 ,אום אל-פחם ,409 ,ג'מילה
2710 ,אום אל-פחם ,257 ,ג'נין
2710 ,אום אל-פחם ,388 ,ג'ריר
2710 ,אום אל-פחם ,267 ,ג'רנאטה
2710 ,אום אל-פחם ,433 ,ד'י קאר
2710 ,אום אל-פחם ,385 ,דאהר אל עומר
2710 ,אום אל-פחם ,260 ,דמשק
2710 ,אום אל-פחם ,276 ,דרך אבו בכר
2710 ,אום אל-פחם ,360 ,דרך אבו נופול
2710 ,אום אל-פחם ,216 ,דרך אבו עבידה
2710 ,אום אל-פחם ,123 ,דרך אלבאטן
2710 ,אום אל-פחם ,125 ,דרך אלביאדר
2710 ,אום אל-פחם ,126 ,דרך אלביאר
2710 ,אום אל-פחם ,127 ,דרך אלביר
2710 ,אום אל-פחם ,129 ,דרך אלג'בארין
2710 ,אום אל-פחם ,130 ,דרך אלגדוע אלספלי
2710 ,אום אל-פחם ,131 ,דרך אלגדוע אלעלוי
2710 ,אום אל-פחם ,152 ,דרך אלמועלקה
2710 ,אום אל-פחם ,148 ,דרך אלמחאמיד
2710 ,אום אל-פחם ,149 ,דרך אלמחג'ר
2710 ,אום אל-פחם ,153 ,דרך אלמלסאא
2710 ,אום אל-פחם ,137 ,דרך אלסולטאנה
2710 ,אום אל-פחם ,144 ,דרך אלע'זלאת
2710 ,אום אל-פחם ,143 ,דרך אלעיון
2710 ,אום אל-פחם ,133 ,דרך אלרבזה
2710 ,אום אל-פחם ,140 ,דרך אלשרפה
2710 ,אום אל-פחם ,155 ,דרך בידר דבאלה
2710 ,אום אל-פחם ,166 ,דרך ואדי אלנסור
2710 ,אום אל-פחם ,168 ,דרך ואדי מלחם
2710 ,אום אל-פחם ,156 ,דרך טלעת אלמידאן
2710 ,אום אל-פחם ,160 ,דרך עין אלד'רווה
2710 ,אום אל-פחם ,163 ,דרך עין אלוסטא
2710 ,אום אל-פחם ,161 ,דרך עין אלמע'ארה
2710 ,אום אל-פחם ,162 ,דרך עין אלנבי
2710 ,אום אל-פחם ,158 ,דרך עין אלתינה
2710 ,אום אל-פחם ,164 ,דרך עין גראר
2710 ,אום אל-פחם ,165 ,דרך עין ח'אלד
2710 ,אום אל-פחם ,115 ,האג'ר
2710 ,אום אל-פחם ,380 ,הארון אלרשיד
2710 ,אום אל-פחם ,167 ,ואדי ג'סאל
2710 ,אום אל-פחם ,169 ,זמזם
2710 ,אום אל-פחם ,406 ,זנוביה
2710 ,אום אל-פחם ,479 ,זריאב
2710 ,אום אל-פחם ,240 ,ח'אלד אבן אלוליד
2710 ,אום אל-פחם ,106 ,ח'דיג'ה בנת ח'וילד
2710 ,אום אל-פחם ,428 ,ח'וביזה
2710 ,אום אל-פחם ,399 ,ח'ליל אלסכאניני
2710 ,אום אל-פחם ,386 ,חאתם אלטאאי
2710 ,אום אל-פחם ,237 ,חד'יפה
2710 ,אום אל-פחם ,258 ,חטין
2710 ,אום אל-פחם ,259 ,חיפא
2710 ,אום אל-פחם ,475 ,חנדלה
2710 ,אום אל-פחם ,238 ,חסאן אבן ת'אבת
2710 ,אום אל-פחם ,461 ,חסן אלבסרי
2710 ,אום אל-פחם ,109 ,טאהא חסין
2710 ,אום אל-פחם ,243 ,טארק אבן זיאד
2710 ,אום אל-פחם ,354 ,טיבה
2710 ,אום אל-פחם ,263 ,טראבלס
2710 ,אום אל-פחם ,274 ,יאפא
2710 ,אום אל-פחם ,402 ,יוסף אדריס
2710 ,אום אל-פחם ,245 ,מאלכ אבן אנס
2710 ,אום אל-פחם ,472 ,מוחמד אבו ח'דיר
2710 ,אום אל-פחם ,383 ,מוסא בן מימון
2710 ,אום אל-פחם ,390 ,מוסא בן נוסיר
2710 ,אום אל-פחם ,486 ,מוסטפא אלתל
2710 ,אום אל-פחם ,363 ,מוסעב בן עומיר
2710 ,אום אל-פחם ,119 ,מועאויה
2710 ,אום אל-פחם ,271 ,מכה
2710 ,אום אל-פחם ,6722 ,מרכז-אגבריה ומחג'נה
2710 ,אום אל-פחם ,6720 ,מרכז-ג'בארין ומחמיד
2710 ,אום אל-פחם ,272 ,נאבלס
2710 ,אום אל-פחם ,473 ,נאזים חכמת
2710 ,אום אל-פחם ,113 ,נוח
2710 ,אום אל-פחם ,273 ,ניסבור
2710 ,אום אל-פחם ,404 ,סבאא-שבא
2710 ,אום אל-פחם ,447 ,סולטאן באשא אלאטרש
2710 ,אום אל-פחם ,242 ,סיבויה
2710 ,אום אל-פחם ,480 ,סייד דרויש
2710 ,אום אל-פחם ,108 ,סלאח אלדין
2710 ,אום אל-פחם ,107 ,סלמאן אלפארסי
2710 ,אום אל-פחם ,241 ,סעד אבן אבי וקאץ
2710 ,אום אל-פחם ,398 ,סעד זג'לול
2710 ,אום אל-פחם ,262 ,ספד
2710 ,אום אל-פחם ,439 ,סקר קורייש
2710 ,אום אל-פחם ,261 ,סרפנד
2710 ,אום אל-פחם ,268 ,ע'זה
2710 ,אום אל-פחם ,244 ,עאישה
2710 ,אום אל-פחם ,400 ,עבד אלחמיד
2710 ,אום אל-פחם ,393 ,עבד אלמאלק בן מרואן
2710 ,אום אל-פחם ,264 ,עדן
2710 ,אום אל-פחם ,483 ,עומר אבו רישה
2710 ,אום אל-פחם ,114 ,עומר אלמוח'תאר
2710 ,אום אל-פחם ,396 ,עומר בן אבי רביעה
2710 ,אום אל-פחם ,391 ,עומר בן אלעאס
2710 ,אום אל-פחם ,381 ,עומר בן עבד אל-עזיז
2710 ,אום אל-פחם ,438 ,עוקאד'
2710 ,אום אל-פחם ,111 ,עות'מאן בן עפאן
2710 ,אום אל-פחם ,157 ,עין אבראהים
2710 ,אום אל-פחם ,159 ,עין אלדאליה
2710 ,אום אל-פחם ,285 ,עין ג'אלוט
2710 ,אום אל-פחם ,265 ,עכא
2710 ,אום אל-פחם ,112 ,עלי בן אבי טאלב
2710 ,אום אל-פחם ,266 ,עמאן
2710 ,אום אל-פחם ,442 ,ענתרה בן שדאד
2710 ,אום אל-פחם ,170 ,ערפה
2710 ,אום אל-פחם ,359 ,קבאא
2710 ,אום אל-פחם ,269 ,קורטבה
2710 ,אום אל-פחם ,6730 ,קטאין אלשומר
2710 ,אום אל-פחם ,365 ,קטר אלנדא
2710 ,אום אל-פחם ,374 ,קיירואן
2710 ,אום אל-פחם ,270 ,קיסאריה
2710 ,אום אל-פחם ,407 ,ראבעה אל-עדויה
2710 ,אום אל-פחם ,443 ,רג'אא אבן חיוה
2710 ,אום אל-פחם ,408 ,רוז אל-יוסף
2710 ,אום אל-פחם ,474 ,רוחי אלח'אלדי
2710 ,אום אל-פחם ,405 ,שהרזאד
2710 ,אום אל-פחם ,6592 ,שכ אבו לאחם
2710 ,אום אל-פחם ,6546 ,שכ אבו נופל
2710 ,אום אל-פחם ,6530 ,שכ אל סולטאנה
2710 ,אום אל-פחם ,6525 ,שכ אלבאטן
2710 ,אום אל-פחם ,6571 ,שכ אלביאדר
2710 ,אום אל-פחם ,6550 ,שכ אלביאר
2710 ,אום אל-פחם ,6535 ,שכ אלביר
2710 ,אום אל-פחם ,6533 ,שכ אלדהר
2710 ,אום אל-פחם ,6532 ,שכ אלכאנוק
2710 ,אום אל-פחם ,6585 ,שכ אלכדור
2710 ,אום אל-פחם ,6537 ,שכ אלכינא
2710 ,אום אל-פחם ,6510 ,שכ אלכלאיל
2710 ,אום אל-פחם ,6591 ,שכ אלמועלקה
2710 ,אום אל-פחם ,6505 ,שכ אלמחג'ר
2710 ,אום אל-פחם ,6565 ,שכ אלמידאן
2710 ,אום אל-פחם ,6586 ,שכ אלמלסא
2710 ,אום אל-פחם ,6551 ,שכ אלמסאיאת
2710 ,אום אל-פחם ,6536 ,שכ אלע'זלאת
2710 ,אום אל-פחם ,6502 ,שכ אלעיון
2710 ,אום אל-פחם ,6548 ,שכ אלעראיש
2710 ,אום אל-פחם ,6601 ,שכ אלקואס
2710 ,אום אל-פחם ,6731 ,שכ אלשאג'ור
2710 ,אום אל-פחם ,6522 ,שכ אסכנדר
2710 ,אום אל-פחם ,6511 ,שכ בורגול
2710 ,אום אל-פחם ,6520 ,שכ בידר דבאלה
2710 ,אום אל-פחם ,6506 ,שכ ג'דוע
2710 ,אום אל-פחם ,6501 ,שכ ואדי מלחם
2710 ,אום אל-פחם ,6596 ,שכ ואדי נסור
2710 ,אום אל-פחם ,6602 ,שכ ואדי עסאן
2710 ,אום אל-פחם ,6568 ,שכ מסגד אבו עובדיה
2710 ,אום אל-פחם ,6580 ,שכ מסגד אבובכר
2710 ,אום אל-פחם ,6575 ,שכ מסגד ג'בארין
2710 ,אום אל-פחם ,6500 ,שכ סויסה
2710 ,אום אל-פחם ,6600 ,שכ סתכיזראן
2710 ,אום אל-פחם ,6555 ,שכ ע'רבי
2710 ,אום אל-פחם ,6570 ,שכ עומר בן אלחטאב
2710 ,אום אל-פחם ,6541 ,שכ עין איברהים
2710 ,אום אל-פחם ,6547 ,שכ עין אל זיתונה
2710 ,אום אל-פחם ,6581 ,שכ עין אלד'רווה
2710 ,אום אל-פחם ,6597 ,שכ עין אלוסטא
2710 ,אום אל-פחם ,6598 ,שכ עין אלמעארה
2710 ,אום אל-פחם ,6521 ,שכ עין אלנבי
2710 ,אום אל-פחם ,6515 ,שכ עין אלשערה
2710 ,אום אל-פחם ,6595 ,שכ עין אלתינה
2710 ,אום אל-פחם ,6590 ,שכ עין ג'ראר
2710 ,אום אל-פחם ,6552 ,שכ עין כאלד
2710 ,אום אל-פחם ,6540 ,שכ עקאדה
2710 ,אום אל-פחם ,6545 ,שכ עראק אלשבאב
2710 ,אום אל-פחם ,6531 ,שכ קחאוש
2710 ,אום אל-פחם ,6560 ,שכ ראס אלהיש
2710 ,אום אל-פחם ,6549 ,שכ רובזה
2710 ,אום אל-פחם ,6516 ,שכ שרפה
2710 ,אום אל-פחם ,422 ,שקאייק אלנועמאן
2710 ,אום אל-פחם ,372 ,תדמור
2710 ,אום אל-פחם ,384 ,תמים אלדארי
2710 ,אום אל-פחם ,411 ,תקוא
2024 ,אום אל-קוטוף ,9000 ,אום אל-קוטוף
1358 ,אום בטין ,9000 ,אום בטין
1108 ,אומן ,9000 ,אומן
1108 ,אומן ,118 ,אירוסים
1108 ,אומן ,107 ,אלה
1108 ,אומן ,106 ,אלומות
1108 ,אומן ,109 ,ארז
1108 ,אומן ,110 ,אשל הירדן
1108 ,אומן ,108 ,גפן
1108 ,אומן ,101 ,דרך אלי ביטון
1108 ,אומן ,102 ,דרך הגלבוע
1108 ,אומן ,104 ,הדס
1108 ,אומן ,121 ,חבצלת
1108 ,אומן ,112 ,חצב
1108 ,אומן ,117 ,כלנית
1108 ,אומן ,103 ,לוטם
1108 ,אומן ,119 ,נורית
1108 ,אומן ,115 ,נרקיס
1108 ,אומן ,113 ,סחלב
1108 ,אומן ,114 ,סייפן
1108 ,אומן ,120 ,עירית
1108 ,אומן ,111 ,רקפת
1108 ,אומן ,116 ,שושנים
1108 ,אומן ,105 ,שקד
680 ,אומץ ,9000 ,אומץ
31 ,אופקים ,328 ,אבנר בן נר
31 ,אופקים ,242 ,אברהם הרשקו
31 ,אופקים ,300 ,אגוז
31 ,אופקים ,502 ,אגמית
31 ,אופקים ,360 ,אדמונית
31 ,אופקים ,243 ,אהרון צ'חנובר
31 ,אופקים ,209 ,אודם
31 ,אופקים ,9000 ,אופקים
31 ,אופקים ,178 ,אור החיים
31 ,אופקים ,439 ,אורים
31 ,אופקים ,6500 ,אזור התעשיה והמלאכה
31 ,אופקים ,361 ,אטד
31 ,אופקים ,380 ,אירוס
31 ,אופקים ,244 ,אלברט איינשטיין
31 ,אופקים ,197 ,אלון יגאל
31 ,אופקים ,144 ,אלי כהן
31 ,אופקים ,314 ,אליהו התשבי
31 ,אופקים ,346 ,אלישע
31 ,אופקים ,503 ,אנפה
31 ,אופקים ,320 ,אסא
31 ,אופקים ,158 ,אסיף
31 ,אופקים ,341 ,אסתר המלכה
31 ,אופקים ,222 ,אפיקים בנגב
31 ,אופקים ,200 ,אצ"ל
31 ,אופקים ,362 ,אקליפטוס
31 ,אופקים ,256 ,ארבעת המינים
31 ,אופקים ,246 ,אריה ורשל
31 ,אופקים ,364 ,אשחר
31 ,אופקים ,365 ,אתרוג
31 ,אופקים ,440 ,בארי
31 ,אופקים ,523 ,בארית
31 ,אופקים ,188 ,בוזגלו דוד
31 ,אופקים ,509 ,בז
31 ,אופקים ,202 ,ביל"ו
31 ,אופקים ,177 ,בן איש חי
31 ,אופקים ,356 ,בן באר יהושע
31 ,אופקים ,342 ,בן בארי הושע
31 ,אופקים ,265 ,בן שמן
31 ,אופקים ,353 ,בניהו
31 ,אופקים ,167 ,בצלאל
31 ,אופקים ,366 ,ברוש
31 ,אופקים ,224 ,ברזילי
31 ,אופקים ,335 ,ברק
31 ,אופקים ,367 ,ברקן
31 ,אופקים ,208 ,ברקת
31 ,אופקים ,368 ,בשמת
31 ,אופקים ,122 ,גבורי ישראל
31 ,אופקים ,305 ,גד
31 ,אופקים ,330 ,גדעון
31 ,אופקים ,121 ,גולומב
31 ,אופקים ,369 ,גומא
31 ,אופקים ,372 ,גיבסנית
31 ,אופקים ,436 ,גלאון
31 ,אופקים ,333 ,גלגל
31 ,אופקים ,318 ,גלעד
31 ,אופקים ,350 ,דבורה הנביאה
31 ,אופקים ,373 ,דגן
31 ,אופקים ,374 ,דגנית
31 ,אופקים ,128 ,דדו
31 ,אופקים ,375 ,דובדבן
31 ,אופקים ,327 ,דוד המלך
31 ,אופקים ,506 ,דוכיפת
31 ,אופקים ,376 ,דולב
31 ,אופקים ,245 ,דן שכטמן
31 ,אופקים ,348 ,דניאל
31 ,אופקים ,241 ,דניאל כהנמן
31 ,אופקים ,414 ,דפנה
31 ,אופקים ,377 ,דקל
31 ,אופקים ,455 ,דקל הדום
31 ,אופקים ,251 ,דקלה
31 ,אופקים ,510 ,דרור
31 ,אופקים ,141 ,דרך אילת
31 ,אופקים ,160 ,דרך בית וגן
31 ,אופקים ,444 ,דרך הבשמים
31 ,אופקים ,250 ,דרך הותיקים
31 ,אופקים ,211 ,דרך הטייסים
31 ,אופקים ,310 ,דרך הנביאים
31 ,אופקים ,1087 ,דרך חסידות חב"ד
31 ,אופקים ,371 ,דרך ישרים
31 ,אופקים ,480 ,דרך משה כחלון
31 ,אופקים ,238 ,דרך סיירת שקד
31 ,אופקים ,126 ,דרך רבי יעקב אוחנה
31 ,אופקים ,255 ,האורן
31 ,אופקים ,378 ,האלה
31 ,אופקים ,150 ,האלון
31 ,אופקים ,180 ,האר"י
31 ,אופקים ,149 ,הארזים
31 ,אופקים ,218 ,הבורסקאי
31 ,אופקים ,166 ,הבנאים
31 ,אופקים ,261 ,הבציר
31 ,אופקים ,270 ,הגורן
31 ,אופקים ,253 ,הדס
31 ,אופקים ,252 ,הדר
31 ,אופקים ,199 ,ההגנה
31 ,אופקים ,153 ,הורד
31 ,אופקים ,119 ,הורדים
31 ,אופקים ,156 ,הזורע
31 ,אופקים ,263 ,הזית
31 ,אופקים ,258 ,הזמורה
31 ,אופקים ,120 ,החבצלת
31 ,אופקים ,157 ,החורש
31 ,אופקים ,176 ,החיד"א
31 ,אופקים ,268 ,החיטה
31 ,אופקים ,151 ,החרוב
31 ,אופקים ,219 ,החרש
31 ,אופקים ,259 ,היוגב
31 ,אופקים ,221 ,היוזמה
31 ,אופקים ,165 ,היוצר
31 ,אופקים ,117 ,הכלנית
31 ,אופקים ,111 ,הכנסת הגדולה
31 ,אופקים ,271 ,הכרמים
31 ,אופקים ,223 ,הכשרת הישוב
31 ,אופקים ,182 ,הלל
31 ,אופקים ,169 ,המלאכה
31 ,אופקים ,168 ,המסגר
31 ,אופקים ,433 ,הנגב
31 ,אופקים ,217 ,הנפח
31 ,אופקים ,110 ,הנשיא
31 ,אופקים ,262 ,העינב
31 ,אופקים ,237 ,העצמאות
31 ,אופקים ,170 ,הפלדה
31 ,אופקים ,115 ,הפלמ"ח
31 ,אופקים ,470 ,הר ארגמן
31 ,אופקים ,443 ,הר חורשה
31 ,אופקים ,450 ,הר חלמיש
31 ,אופקים ,452 ,הר יעלון
31 ,אופקים ,454 ,הר כרכום
31 ,אופקים ,453 ,הר רתמים
31 ,אופקים ,451 ,הר שגיא
31 ,אופקים ,155 ,הרב אבוחצירה
31 ,אופקים ,185 ,הרב אלנקווה רפאל
31 ,אופקים ,363 ,הרב אלעזר אבוחצירה
31 ,אופקים ,127 ,הרב הרצוג
31 ,אופקים ,147 ,הרב חורי
31 ,אופקים ,406 ,הרב חיים קמיל
31 ,אופקים ,422 ,הרב יעקב הורוביץ
31 ,אופקים ,445 ,הרב מרדכי חורי
31 ,אופקים ,426 ,הרב עובדיה יוסף
31 ,אופקים ,113 ,הרב עוזיאל
31 ,אופקים ,379 ,הרב פינקוס
31 ,אופקים ,114 ,הרב קוק
31 ,אופקים ,449 ,הרי אילת
31 ,אופקים ,267 ,הרימון
31 ,אופקים ,101 ,הרמב"ם
31 ,אופקים ,220 ,הרתך
31 ,אופקים ,326 ,השילוח
31 ,אופקים ,152 ,השקד
31 ,אופקים ,266 ,התאנה
31 ,אופקים ,349 ,התבור
31 ,אופקים ,260 ,התירוש
31 ,אופקים ,269 ,התמר
31 ,אופקים ,104 ,התעשיה
31 ,אופקים ,446 ,ותיקי מלחה"ע השניה
31 ,אופקים ,137 ,ז'בוטינסקי
31 ,אופקים ,313 ,זכריה
31 ,אופקים ,511 ,זמיר
31 ,אופקים ,315 ,חגי
31 ,אופקים ,520 ,חוגלה
31 ,אופקים ,513 ,חוחית
31 ,אופקים ,517 ,חופית
31 ,אופקים ,332 ,חורב
31 ,אופקים ,175 ,חזון איש
31 ,אופקים ,234 ,חט אלכסנדרוני
31 ,אופקים ,232 ,חט גבעתי
31 ,אופקים ,236 ,חט גולני
31 ,אופקים ,231 ,חט הנגב
31 ,אופקים ,235 ,חט כרמלי
31 ,אופקים ,233 ,חט עציוני
31 ,אופקים ,381 ,חלבלוב
31 ,אופקים ,382 ,חמנייה
31 ,אופקים ,340 ,חנניה
31 ,אופקים ,524 ,חסידה
31 ,אופקים ,183 ,חפץ חיים
31 ,אופקים ,383 ,חצב
31 ,אופקים ,428 ,חצרים
31 ,אופקים ,384 ,חרצית
31 ,אופקים ,196 ,טרומפלדור
31 ,אופקים ,194 ,יהדות דרום אפריקה
31 ,אופקים ,116 ,יהודה הלוי
31 ,אופקים ,447 ,יהודה מרציאנו
31 ,אופקים ,303 ,יהונתן
31 ,אופקים ,352 ,יהוצדק
31 ,אופקים ,312 ,יהושע בן נון
31 ,אופקים ,321 ,יהושפט
31 ,אופקים ,206 ,יהלום
31 ,אופקים ,319 ,יואב
31 ,אופקים ,338 ,יואל
31 ,אופקים ,347 ,יונה
31 ,אופקים ,129 ,יוני
31 ,אופקים ,309 ,יחזקאל
31 ,אופקים ,146 ,יסמין
31 ,אופקים ,514 ,יסעור
31 ,אופקים ,351 ,יעקב הכהן
31 ,אופקים ,385 ,יערה
31 ,אופקים ,336 ,יפתח
31 ,אופקים ,264 ,יצהר
31 ,אופקים ,143 ,יצחק רבין
31 ,אופקים ,386 ,יקינטון
31 ,אופקים ,304 ,ירושלים
31 ,אופקים ,302 ,ירמיהו
31 ,אופקים ,512 ,ירקון
31 ,אופקים ,317 ,ישי
31 ,אופקים ,308 ,ישעיהו
31 ,אופקים ,249 ,ישראל אומן
31 ,אופקים ,6018 ,כ' חיים ורבקה אמיר
31 ,אופקים ,6002 ,כיכר ברטי לובנר
31 ,אופקים ,6013 ,כיכר הזריחה
31 ,אופקים ,6014 ,כיכר החינוך
31 ,אופקים ,6017 ,כיכר היונים
31 ,אופקים ,6009 ,כיכר הספורט
31 ,אופקים ,6007 ,כיכר העיר
31 ,אופקים ,6011 ,כיכר הפארק
31 ,אופקים ,6015 ,כיכר השחפים
31 ,אופקים ,6004 ,כיכר השער לנגב
31 ,אופקים ,6005 ,כיכר חורבת פטיש
31 ,אופקים ,6016 ,כיכר חכמי ישראל
31 ,אופקים ,6012 ,כיכר יהדות התפוצות
31 ,אופקים ,6006 ,כיכר מטרו-ווסט
31 ,אופקים ,6008 ,כיכר מעגל החיים
31 ,אופקים ,6010 ,כיכר ספרא
31 ,אופקים ,6001 ,ככר גבעתי
31 ,אופקים ,387 ,כליל החורש
31 ,אופקים ,429 ,כפר דרום
31 ,אופקים ,123 ,כצנלסון
31 ,אופקים ,388 ,כרכום
31 ,אופקים ,331 ,כרמל
31 ,אופקים ,389 ,לוטוס
31 ,אופקים ,390 ,לוטם
31 ,אופקים ,201 ,לח"י
31 ,אופקים ,391 ,ליבנה
31 ,אופקים ,392 ,לילך
31 ,אופקים ,214 ,מבצע דקל
31 ,אופקים ,225 ,מבצע הראל
31 ,אופקים ,227 ,מבצע חורב
31 ,אופקים ,228 ,מבצע חירם
31 ,אופקים ,229 ,מבצע יואב
31 ,אופקים ,226 ,מבצע יפתח
31 ,אופקים ,132 ,מבצע משה
31 ,אופקים ,213 ,מבצע נחשון
31 ,אופקים ,230 ,מבצע עובדה
31 ,אופקים ,508 ,מגלן
31 ,אופקים ,427 ,מורן
31 ,אופקים ,247 ,מיכאל לויט
31 ,אופקים ,442 ,מכתש רמון
31 ,אופקים ,311 ,מלאכי
31 ,אופקים ,240 ,מנחם בגין
31 ,אופקים ,354 ,מסיקה יעקב
31 ,אופקים ,203 ,מרבד הקסמים
31 ,אופקים ,393 ,מרגנית
31 ,אופקים ,343 ,מרדכי
31 ,אופקים ,394 ,מרווה
31 ,אופקים ,430 ,משמר הנגב
31 ,אופקים ,106 ,משעול האיריס
31 ,אופקים ,108 ,משעול הגפן
31 ,אופקים ,105 ,משעול הנרקיס
31 ,אופקים ,138 ,משעול הרקפת
31 ,אופקים ,103 ,משעול השקמה
31 ,אופקים ,432 ,נבטים
31 ,אופקים ,171 ,נחושתן
31 ,אופקים ,465 ,נחל אופקים
31 ,אופקים ,281 ,נחל אמציהו
31 ,אופקים ,282 ,נחל ארדון
31 ,אופקים ,274 ,נחל אשלים
31 ,אופקים ,283 ,נחל באר חיל
31 ,אופקים ,463 ,נחל באר שבע
31 ,אופקים ,284 ,נחל גוב
31 ,אופקים ,461 ,נחל גרר
31 ,אופקים ,285 ,נחל גשרון
31 ,אופקים ,462 ,נחל דוד
31 ,אופקים ,467 ,נחל הבשור
31 ,אופקים ,295 ,נחל הרועה
31 ,אופקים ,297 ,נחל זיו
31 ,אופקים ,174 ,נחל זיתן
31 ,אופקים ,288 ,נחל חברון
31 ,אופקים ,289 ,נחל חווה
31 ,אופקים ,290 ,נחל חיון
31 ,אופקים ,291 ,נחל חצרה
31 ,אופקים ,280 ,נחל חתירה
31 ,אופקים ,292 ,נחל ימין
31 ,אופקים ,293 ,נחל יתיר
31 ,אופקים ,458 ,נחל כיסופים
31 ,אופקים ,173 ,נחל כסיף
31 ,אופקים ,294 ,נחל לבן
31 ,אופקים ,286 ,נחל ממשית
31 ,אופקים ,296 ,נחל נוקד
31 ,אופקים ,457 ,נחל נטפים
31 ,אופקים ,277 ,נחל ניצנה
31 ,אופקים ,476 ,נחל נצר
31 ,אופקים ,279 ,נחל נקרות
31 ,אופקים ,273 ,נחל עבדת
31 ,אופקים ,456 ,נחל ערוגות
31 ,אופקים ,298 ,נחל עתק
31 ,אופקים ,478 ,נחל פארן
31 ,אופקים ,299 ,נחל פורה
31 ,אופקים ,464 ,נחל פטיש
31 ,אופקים ,471 ,נחל פרס
31 ,אופקים ,460 ,נחל צאלים
31 ,אופקים ,287 ,נחל צופר
31 ,אופקים ,278 ,נחל ציחור
31 ,אופקים ,459 ,נחל צין
31 ,אופקים ,475 ,נחל צפית
31 ,אופקים ,472 ,נחל קטורה
31 ,אופקים ,473 ,נחל רודד
31 ,אופקים ,276 ,נחל רות
31 ,אופקים ,474 ,נחל רחם
31 ,אופקים ,466 ,נחל רמון
31 ,אופקים ,477 ,נחל שני
31 ,אופקים ,468 ,נחל שקמה
31 ,אופקים ,275 ,נחל שרביט
31 ,אופקים ,272 ,נחל שרשרת
31 ,אופקים ,172 ,נחל תמנע
31 ,אופקים ,479 ,נחל תמר
31 ,אופקים ,518 ,נחליאלי
31 ,אופקים ,344 ,נחמיה
31 ,אופקים ,438 ,נירים
31 ,אופקים ,395 ,נירית
31 ,אופקים ,306 ,נתן
31 ,אופקים ,396 ,סביון
31 ,אופקים ,397 ,סחלב
31 ,אופקים ,469 ,סיגלון
31 ,אופקים ,398 ,סייפן
31 ,אופקים ,399 ,סיתוונית
31 ,אופקים ,501 ,סלעית
31 ,אופקים ,145 ,סמדר
31 ,אופקים ,519 ,סנונית
31 ,אופקים ,207 ,ספיר
31 ,אופקים ,515 ,עגור
31 ,אופקים ,400 ,עדעד
31 ,אופקים ,324 ,עוזיהו
31 ,אופקים ,401 ,עוזרד
31 ,אופקים ,195 ,עולי הגרדום
31 ,אופקים ,345 ,עזרא
31 ,אופקים ,307 ,עידו הנביא
31 ,אופקים ,505 ,עיט
31 ,אופקים ,323 ,עין גדי
31 ,אופקים ,402 ,עירית
31 ,אופקים ,339 ,עמוס
31 ,אופקים ,448 ,עמרם ביתן
31 ,אופקים ,179 ,עמרם בן דיואן
31 ,אופקים ,329 ,עמשא
31 ,אופקים ,504 ,עפרוני
31 ,אופקים ,254 ,ערבה
31 ,אופקים ,403 ,פטל
31 ,אופקים ,186 ,פינטו חיים
31 ,אופקים ,404 ,פיקוס
31 ,אופקים ,405 ,פעמונית
31 ,אופקים ,257 ,פרי מגדים
31 ,אופקים ,521 ,פשוש
31 ,אופקים ,148 ,צאלה
31 ,אופקים ,370 ,צאלון
31 ,אופקים ,408 ,צבעוני
31 ,אופקים ,409 ,צבר
31 ,אופקים ,325 ,צדקיהו
31 ,אופקים ,124 ,צה"ל
31 ,אופקים ,507 ,צוקית
31 ,אופקים ,410 ,ציפור גן עדן
31 ,אופקים ,411 ,ציפורן
31 ,אופקים ,412 ,צפצפה
31 ,אופקים ,190 ,קארו יוסף
31 ,אופקים ,109 ,קבוץ גלויות
31 ,אופקים ,437 ,קדמה
31 ,אופקים ,112 ,קדש
31 ,אופקים ,187 ,קהילות בן יעקב
31 ,אופקים ,441 ,קהילת מטרו ווסט
31 ,אופקים ,413 ,קורנית
31 ,אופקים ,215 ,קיקוס חנית
31 ,אופקים ,159 ,קציר
31 ,אופקים ,337 ,קרית ספר
31 ,אופקים ,142 ,קרן היסוד
31 ,אופקים ,131 ,רבי דוד דהן
31 ,אופקים ,181 ,רבי עקיבא
31 ,אופקים ,415 ,רוזמרין
31 ,אופקים ,416 ,רותם
31 ,אופקים ,198 ,רזיאל דוד
31 ,אופקים ,357 ,רחבת אביר
31 ,אופקים ,358 ,רחבת אדר
31 ,אופקים ,417 ,ריחן
31 ,אופקים ,6507 ,רמת שקד
31 ,אופקים ,189 ,רש"י
31 ,אופקים ,6506 ,ש משטרה
31 ,אופקים ,239 ,ש"י עגנון
31 ,אופקים ,301 ,שאול המלך
31 ,אופקים ,184 ,שבזי
31 ,אופקים ,435 ,שד הציונות
31 ,אופקים ,107 ,שד הרצל
31 ,אופקים ,418 ,שד' השיטה
31 ,אופקים ,431 ,שובל
31 ,אופקים ,205 ,שוהם
31 ,אופקים ,419 ,שושן
31 ,אופקים ,522 ,שחף
31 ,אופקים ,420 ,שיבולים
31 ,אופקים ,421 ,שיזף
31 ,אופקים ,355 ,שילה
31 ,אופקים ,6510 ,שכ בן גוריון
31 ,אופקים ,6511 ,שכ בנה ביתך
31 ,אופקים ,6526 ,שכ הארזים
31 ,אופקים ,6512 ,שכ הרי"ף
31 ,אופקים ,6525 ,שכ מישור הגפן
31 ,אופקים ,6516 ,שכ שפירא
31 ,אופקים ,6514 ,שכונת דדו
31 ,אופקים ,6515 ,שכונת יוני
31 ,אופקים ,516 ,שליו
31 ,אופקים ,322 ,שלמה המלך
31 ,אופקים ,316 ,שמואל הרואה
31 ,אופקים ,248 ,שמעון פרס
31 ,אופקים ,334 ,שמשון
31 ,אופקים ,130 ,שרת
31 ,אופקים ,216 ,תוצרת הארץ
31 ,אופקים ,423 ,תורמוס
31 ,אופקים ,424 ,תות
31 ,אופקים ,425 ,תלתן
31 ,אופקים ,434 ,תקומה
31 ,אופקים ,204 ,תרשיש
1294 ,אור הגנוז ,9000 ,אור הגנוז
67 ,אור הנר ,9000 ,אור הנר
2400 ,אור יהודה ,276 ,אבי ואביב
2400 ,אור יהודה ,222 ,אביב משה
2400 ,אור יהודה ,121 ,אבנר
2400 ,אור יהודה ,112 ,אדם יקותיאל
2400 ,אור יהודה ,136 ,אהובה עוזרי
2400 ,אור יהודה ,261 ,אודם
2400 ,אור יהודה ,125 ,אופירה
2400 ,אור יהודה ,9000 ,אור יהודה
2400 ,אור יהודה ,251 ,אורן
2400 ,אור יהודה ,6518 ,אזור תעשיה ספיר
2400 ,אור יהודה ,6517 ,אזור תעשייה א
2400 ,אור יהודה ,227 ,אזור תעשייה אלמוג
2400 ,אור יהודה ,6516 ,אזור תעשייה ב
2400 ,אור יהודה ,6515 ,אזור תעשייה יהלום
2400 ,אור יהודה ,242 ,אטד
2400 ,אור יהודה ,269 ,איילת השחר
2400 ,אור יהודה ,115 ,איריס
2400 ,אור יהודה ,265 ,אלמוג
2400 ,אור יהודה ,106 ,אלעזר דוד
2400 ,אור יהודה ,209 ,אסירי ציון
2400 ,אור יהודה ,305 ,אצ"ל
2400 ,אור יהודה ,182 ,ארבל
2400 ,אור יהודה ,145 ,ארבע אמהות
2400 ,אור יהודה ,252 ,אשכול לוי
2400 ,אור יהודה ,256 ,אשל
2400 ,אור יהודה ,3104 ,בן איש חי
2400 ,אור יהודה ,314 ,בר יוחאי
2400 ,אור יהודה ,243 ,בר לב חיים
2400 ,אור יהודה ,257 ,ברוש
2400 ,אור יהודה ,309 ,ברנר
2400 ,אור יהודה ,263 ,ברקת
2400 ,אור יהודה ,277 ,גולדה מאיר
2400 ,אור יהודה ,310 ,גורדון
2400 ,אור יהודה ,147 ,דבורה הנביאה
2400 ,אור יהודה ,157 ,דבורה עומר
2400 ,אור יהודה ,105 ,דגניה
2400 ,אור יהודה ,313 ,דה וינצי
2400 ,אור יהודה ,255 ,דולב
2400 ,אור יהודה ,239 ,דיין משה
2400 ,אור יהודה ,249 ,דקל
2400 ,אור יהודה ,217 ,דקלה
2400 ,אור יהודה ,233 ,האגוז
2400 ,אור יהודה ,200 ,האוניברסיטה
2400 ,אור יהודה ,253 ,האלה
2400 ,אור יהודה ,241 ,הארז
2400 ,אור יהודה ,232 ,האתרוג
2400 ,אור יהודה ,349 ,הגלבוע
2400 ,אור יהודה ,312 ,הגליל
2400 ,אור יהודה ,341 ,הדדי משה
2400 ,אור יהודה ,205 ,ההגנה
2400 ,אור יהודה ,210 ,ההסתדרות
2400 ,אור יהודה ,328 ,הוז דב
2400 ,אור יהודה ,113 ,הורד
2400 ,אור יהודה ,133 ,החבצלת
2400 ,אור יהודה ,206 ,החלוצים
2400 ,אור יהודה ,114 ,החצב
2400 ,אור יהודה ,223 ,החרושת
2400 ,אור יהודה ,219 ,היוזמים
2400 ,אור יהודה ,338 ,היוצרים
2400 ,אור יהודה ,131 ,היסמין
2400 ,אור יהודה ,220 ,היצירה
2400 ,אור יהודה ,343 ,הירדן
2400 ,אור יהודה ,111 ,הכלנית
2400 ,אור יהודה ,204 ,הל"ה
2400 ,אור יהודה ,304 ,הלח"י
2400 ,אור יהודה ,156 ,הלנה כגן
2400 ,אור יהודה ,344 ,המכבים
2400 ,אור יהודה ,221 ,המלאכה
2400 ,אור יהודה ,339 ,המסגר
2400 ,אור יהודה ,207 ,המעפילים
2400 ,אור יהודה ,337 ,המפעל
2400 ,אור יהודה ,141 ,הנרייטה סאלד
2400 ,אור יהודה ,3102 ,הנרקיס רמת פנקס
2400 ,אור יהודה ,340 ,הסדנא
2400 ,אור יהודה ,212 ,הסוכנות היהודית
2400 ,אור יהודה ,116 ,הסייפן
2400 ,אור יהודה ,224 ,העבודה
2400 ,אור יהודה ,104 ,העצמאות
2400 ,אור יהודה ,119 ,הערמון
2400 ,אור יהודה ,336 ,הפלדה
2400 ,אור יהודה ,307 ,הפלמ"ח
2400 ,אור יהודה ,303 ,הראשונים
2400 ,אור יהודה ,325 ,הרב הרצוג
2400 ,אור יהודה ,346 ,הרב חי טייב
2400 ,אור יהודה ,324 ,הרב כמוס עגיב
2400 ,אור יהודה ,327 ,הרב פלח כמוס
2400 ,אור יהודה ,120 ,הרדוף
2400 ,אור יהודה ,124 ,הרותם
2400 ,אור יהודה ,234 ,הרימון
2400 ,אור יהודה ,3105 ,הרמ"א רמת פנקס
2400 ,אור יהודה ,311 ,הרצל
2400 ,אור יהודה ,132 ,הרקפת
2400 ,אור יהודה ,332 ,השומר
2400 ,אור יהודה ,3103 ,השושן
2400 ,אור יהודה ,128 ,השיטה
2400 ,אור יהודה ,117 ,השלהבת
2400 ,אור יהודה ,123 ,השקמה
2400 ,אור יהודה ,244 ,התאנה
2400 ,אור יהודה ,302 ,התעשיה
2400 ,אור יהודה ,129 ,ז'בוטינסקי
2400 ,אור יהודה ,246 ,זית
2400 ,אור יהודה ,109 ,חטיבת אלכסנדרוני
2400 ,אור יהודה ,353 ,חייק משה
2400 ,אור יהודה ,148 ,חנה מרון
2400 ,אור יהודה ,247 ,חרוב
2400 ,אור יהודה ,183 ,חרמון
2400 ,אור יהודה ,264 ,טופז
2400 ,אור יהודה ,202 ,יהדות קנדה
2400 ,אור יהודה ,267 ,יהלום
2400 ,אור יהודה ,107 ,יוסף חיים
2400 ,אור יהודה ,142 ,יפה ירקוני
2400 ,אור יהודה ,150 ,יצחק רבין
2400 ,אור יהודה ,130 ,ירושלים
2400 ,אור יהודה ,322 ,כביש לוד
2400 ,אור יהודה ,271 ,כדורי יצחק
2400 ,אור יהודה ,351 ,כהן דוד
2400 ,אור יהודה ,6011 ,כיכר איינשטיין אריק
2400 ,אור יהודה ,6013 ,כיכר גאון יהורם
2400 ,אור יהודה ,6015 ,כיכר דמארי שושנה
2400 ,אור יהודה ,6012 ,כיכר הירש נורית
2400 ,אור יהודה ,6010 ,כיכר הספורטאים
2400 ,אור יהודה ,6014 ,כיכר חיטמן עוזי
2400 ,אור יהודה ,6008 ,כיכר חפר חיים
2400 ,אור יהודה ,6006 ,כיכר טהר לב יורם
2400 ,אור יהודה ,6007 ,כיכר שיר סמדר
2400 ,אור יהודה ,6005 ,כיכר שמר נעמי
2400 ,אור יהודה ,6002 ,ככר 11 בספטמבר
2400 ,אור יהודה ,6004 ,ככר המורשת
2400 ,אור יהודה ,6001 ,ככר הנשיא
2400 ,אור יהודה ,6003 ,ככר י"א חללי מינכן
2400 ,אור יהודה ,250 ,כליל החורש
2400 ,אור יהודה ,184 ,כנען
2400 ,אור יהודה ,345 ,כנרת
2400 ,אור יהודה ,102 ,כצנלסון
2400 ,אור יהודה ,187 ,כרמל
2400 ,אור יהודה ,149 ,לאה גולדברג
2400 ,אור יהודה ,259 ,ליבנה
2400 ,אור יהודה ,3101 ,לנדאו רמת פנקס
2400 ,אור יהודה ,238 ,לשם
2400 ,אור יהודה ,318 ,מונטיפיורי
2400 ,אור יהודה ,228 ,מועלם יצחק
2400 ,אור יהודה ,122 ,מיכאל
2400 ,אור יהודה ,186 ,מירון
2400 ,אור יהודה ,144 ,מניה שוחט
2400 ,אור יהודה ,229 ,מעלה החומה
2400 ,אור יהודה ,226 ,מעלה המגדל
2400 ,אור יהודה ,181 ,מצדה
2400 ,אור יהודה ,155 ,מרים נאור
2400 ,אור יהודה ,6519 ,מרכז העיר
2400 ,אור יהודה ,300 ,משה גניש
2400 ,אור יהודה ,6502 ,נווה הדרים
2400 ,אור יהודה ,6511 ,נווה סביון
2400 ,אור יהודה ,151 ,ניצן
2400 ,אור יהודה ,143 ,נעמי שמר
2400 ,אור יהודה ,213 ,נתניהו יהונתן
2400 ,אור יהודה ,118 ,סביון
2400 ,אור יהודה ,245 ,סגלון
2400 ,אור יהודה ,203 ,סיני
2400 ,אור יהודה ,330 ,סמ בית אל
2400 ,אור יהודה ,352 ,סמ גואטה אפרים
2400 ,אור יהודה ,347 ,סמ הרמב"ן
2400 ,אור יהודה ,348 ,סמ חכם שמעון
2400 ,אור יהודה ,329 ,סמ מחולה
2400 ,אור יהודה ,306 ,סמ ניל"י
2400 ,אור יהודה ,134 ,סמטת במעלה
2400 ,אור יהודה ,188 ,סמטת דוכיפת
2400 ,אור יהודה ,192 ,סמטת דרור
2400 ,אור יהודה ,342 ,סמטת הבשן
2400 ,אור יהודה ,335 ,סמטת הגן
2400 ,אור יהודה ,333 ,סמטת הלל
2400 ,אור יהודה ,154 ,סמטת השחר
2400 ,אור יהודה ,291 ,סמטת זמיר
2400 ,אור יהודה ,331 ,סמטת חברון
2400 ,אור יהודה ,189 ,סמטת נחליאלי
2400 ,אור יהודה ,190 ,סמטת סנונית
2400 ,אור יהודה ,211 ,סמטת עולי הגרדום
2400 ,אור יהודה ,195 ,סמטת עפרוני
2400 ,אור יהודה ,193 ,סמטת פשוש
2400 ,אור יהודה ,225 ,סמטת קליה
2400 ,אור יהודה ,194 ,סמטת שחף
2400 ,אור יהודה ,334 ,סמטת שמאי
2400 ,אור יהודה ,270 ,סעדון אליהו
2400 ,אור יהודה ,135 ,סעיד משיח
2400 ,אור יהודה ,237 ,ספיר
2400 ,אור יהודה ,215 ,סקלאר
2400 ,אור יהודה ,103 ,עבודי יחזקאל
2400 ,אור יהודה ,3106 ,עגיב כמוס רמת פנקס
2400 ,אור יהודה ,138 ,עופרה חזה
2400 ,אור יהודה ,321 ,עזרא הסופר
2400 ,אור יהודה ,126 ,עין גדי
2400 ,אור יהודה ,127 ,עין יהב
2400 ,אור יהודה ,236 ,ענבר
2400 ,אור יהודה ,235 ,ערבה
2400 ,אור יהודה ,326 ,פנקס צבי
2400 ,אור יהודה ,218 ,פרייב ישראל
2400 ,אור יהודה ,254 ,צאלון
2400 ,אור יהודה ,152 ,צפריר
2400 ,אור יהודה ,317 ,קארו יוסף
2400 ,אור יהודה ,308 ,קזז יחזקאל
2400 ,אור יהודה ,6500 ,קרית גיורא
2400 ,אור יהודה ,139 ,רונית אלקבץ
2400 ,אור יהודה ,323 ,רח ו
2400 ,אור יהודה ,301 ,רח יח
2400 ,אור יהודה ,140 ,רחל המשוררת
2400 ,אור יהודה ,316 ,רמז דוד
2400 ,אור יהודה ,6600 ,רמת פנקס
2400 ,אור יהודה ,6501 ,ש אזבסטונים
2400 ,אור יהודה ,6503 ,ש המרכז
2400 ,אור יהודה ,6504 ,ש ממשלתי
2400 ,אור יהודה ,6505 ,ש עמידר
2400 ,אור יהודה ,6506 ,ש עממי
2400 ,אור יהודה ,231 ,שד אלון יגאל
2400 ,אור יהודה ,230 ,שד בגין מנחם
2400 ,אור יהודה ,240 ,שד בן גוריון
2400 ,אור יהודה ,216 ,שד בן פורת מרדכי
2400 ,אור יהודה ,214 ,שד הבנים
2400 ,אור יהודה ,180 ,שדרות שמעון פרס
2400 ,אור יהודה ,262 ,שוהם
2400 ,אור יהודה ,137 ,שושנה דמארי
2400 ,אור יהודה ,110 ,שטרן יאיר
2400 ,אור יהודה ,268 ,שיזף
2400 ,אור יהודה ,153 ,שירת הזמיר
2400 ,אור יהודה ,6514 ,שכ ההסתדרות
2400 ,אור יהודה ,6513 ,שכ הפרדס
2400 ,אור יהודה ,6507 ,שכ כפר ענה א
2400 ,אור יהודה ,6508 ,שכ כפר ענה ב
2400 ,אור יהודה ,6512 ,שכ נווה רבין
2400 ,אור יהודה ,6509 ,שכ סקיה א
2400 ,אור יהודה ,6510 ,שכ סקיה ב
2400 ,אור יהודה ,319 ,שלום עליכם
2400 ,אור יהודה ,315 ,שלמה
2400 ,אור יהודה ,208 ,שפרינצק
2400 ,אור יהודה ,260 ,שקד
2400 ,אור יהודה ,146 ,שרה אהרונסון
2400 ,אור יהודה ,275 ,שרון אריאל
2400 ,אור יהודה ,185 ,תבור
2400 ,אור יהודה ,258 ,תדהר
2400 ,אור יהודה ,320 ,תפארת ישראל
2400 ,אור יהודה ,266 ,תרשיש
1020 ,אור עקיבא ,220 ,אבן גבירול
1020 ,אור עקיבא ,9000 ,אור עקיבא
1020 ,אור עקיבא ,6514 ,אזור התעשייה
1020 ,אור עקיבא ,6513 ,אזור תעשייה צפון
1020 ,אור עקיבא ,223 ,אטלס יהודה
1020 ,אור עקיבא ,140 ,איחוד העם
1020 ,אור עקיבא ,143 ,אילות השדה
1020 ,אור עקיבא ,138 ,איסר הראל
1020 ,אור עקיבא ,135 ,אלעזר דוד
1020 ,אור עקיבא ,102 ,ביאליק
1020 ,אור עקיבא ,106 ,בלפור
1020 ,אור עקיבא ,104 ,בן יהודה
1020 ,אור עקיבא ,179 ,גלפנשטיין מיכאל
1020 ,אור עקיבא ,6509 ,גני אור
1020 ,אור עקיבא ,141 ,דרדשתי
1020 ,אור עקיבא ,181 ,האילן
1020 ,אור עקיבא ,150 ,האירוס
1020 ,אור עקיבא ,171 ,האלה
1020 ,אור עקיבא ,160 ,האלון
1020 ,אור עקיבא ,156 ,האצ"ל
1020 ,אור עקיבא ,210 ,הארבל
1020 ,אור עקיבא ,121 ,הארי
1020 ,אור עקיבא ,167 ,הברוש
1020 ,אור עקיבא ,200 ,הגולן
1020 ,אור עקיבא ,113 ,הגיבורים
1020 ,אור עקיבא ,201 ,הגלבוע
1020 ,אור עקיבא ,163 ,הגפן
1020 ,אור עקיבא ,188 ,הדודאים
1020 ,אור עקיבא ,174 ,הדפנה
1020 ,אור עקיבא ,157 ,ההגנה
1020 ,אור עקיבא ,186 ,ההדס
1020 ,אור עקיבא ,151 ,הורד
1020 ,אור עקיבא ,224 ,הזז חיים
1020 ,אור עקיבא ,182 ,הזית
1020 ,אור עקיבא ,176 ,החבצלת
1020 ,אור עקיבא ,187 ,החוחים
1020 ,אור עקיבא ,185 ,החרוב
1020 ,אור עקיבא ,214 ,החרמון
1020 ,אור עקיבא ,152 ,היסמין
1020 ,אור עקיבא ,169 ,הכלנית
1020 ,אור עקיבא ,202 ,הכרמל
1020 ,אור עקיבא ,159 ,הלח"י
1020 ,אור עקיבא ,153 ,הלילך
1020 ,אור עקיבא ,114 ,הלל
1020 ,אור עקיבא ,170 ,המרגלית
1020 ,אור עקיבא ,112 ,הנביאים
1020 ,אור עקיבא ,166 ,הנרקיס
1020 ,אור עקיבא ,164 ,הסביונים
1020 ,אור עקיבא ,172 ,הסיגלית
1020 ,אור עקיבא ,116 ,העליה
1020 ,אור עקיבא ,115 ,העצמאות
1020 ,אור עקיבא ,184 ,הערבה
1020 ,אור עקיבא ,158 ,הפלמ"ח
1020 ,אור עקיבא ,212 ,הקסטל
1020 ,אור עקיבא ,203 ,הר המוריה
1020 ,אור עקיבא ,209 ,הר הצופים
1020 ,אור עקיבא ,211 ,הר מירון
1020 ,אור עקיבא ,205 ,הר נבו
1020 ,אור עקיבא ,208 ,הר סיני
1020 ,אור עקיבא ,227 ,הר שלמה
1020 ,אור עקיבא ,108 ,הרב הרצוג
1020 ,אור עקיבא ,130 ,הרב חי טייב
1020 ,אור עקיבא ,117 ,הרב כהנא
1020 ,אור עקיבא ,136 ,הרב מלול יוסף
1020 ,אור עקיבא ,109 ,הרב קוק
1020 ,אור עקיבא ,177 ,הרותם
1020 ,אור עקיבא ,204 ,הרי אפרים
1020 ,אור עקיבא ,206 ,הרי יהודה
1020 ,אור עקיבא ,207 ,הרי מנשה
1020 ,אור עקיבא ,165 ,הרימון
1020 ,אור עקיבא ,101 ,הרצל
1020 ,אור עקיבא ,194 ,השולמית
1020 ,אור עקיבא ,168 ,השיטה
1020 ,אור עקיבא ,173 ,השיקמים
1020 ,אור עקיבא ,175 ,השקד
1020 ,אור עקיבא ,161 ,התאנה
1020 ,אור עקיבא ,213 ,התבור
1020 ,אור עקיבא ,183 ,התדהר
1020 ,אור עקיבא ,162 ,התמר
1020 ,אור עקיבא ,128 ,ויסנברג יחיאל
1020 ,אור עקיבא ,110 ,ז'בוטינסקי
1020 ,אור עקיבא ,118 ,זך יהודה
1020 ,אור עקיבא ,193 ,חבצלת השרון
1020 ,אור עקיבא ,195 ,חגוי הסלע
1020 ,אור עקיבא ,137 ,חכם מנחם
1020 ,אור עקיבא ,126 ,חללי דקר
1020 ,אור עקיבא ,217 ,י.ל.פרץ
1020 ,אור עקיבא ,129 ,יאנסן עליזה
1020 ,אור עקיבא ,226 ,יהודה הלוי
1020 ,אור עקיבא ,219 ,ינוש קורצ'ק
1020 ,אור עקיבא ,122 ,כהן אלי
1020 ,אור עקיבא ,149 ,כרם
1020 ,אור עקיבא ,147 ,לבונה
1020 ,אור עקיבא ,134 ,מאיר דגן
1020 ,אור עקיבא ,133 ,מאיר עמית
1020 ,אור עקיבא ,225 ,מגד אהרון
1020 ,אור עקיבא ,222 ,מוסינזון יגאל
1020 ,אור עקיבא ,145 ,מור
1020 ,אור עקיבא ,6502 ,מעברה
1020 ,אור עקיבא ,144 ,מרכבות
1020 ,אור עקיבא ,132 ,מרכז קליטה
1020 ,אור עקיבא ,155 ,ניל"י
1020 ,אור עקיבא ,127 ,סטנלי מאיר
1020 ,אור עקיבא ,120 ,סמ חואטו סייג
1020 ,אור עקיבא ,189 ,סמדר
1020 ,אור עקיבא ,216 ,ספינת האגוז
1020 ,אור עקיבא ,199 ,עמותת תקע יתד בארץ
1020 ,אור עקיבא ,111 ,עשרת הרוגי מלכות
1020 ,אור עקיבא ,190 ,פארק תעשיה צפון
1020 ,אור עקיבא ,192 ,פרדס רימונים
1020 ,אור עקיבא ,191 ,פרי מגדים
1020 ,אור עקיבא ,131 ,ציוני מנחם
1020 ,אור עקיבא ,124 ,קופלוביץ
1020 ,אור עקיבא ,139 ,רבי נחמן מברסלב
1020 ,אור עקיבא ,103 ,רוטשילד
1020 ,אור עקיבא ,107 ,רמב"ם
1020 ,אור עקיבא ,119 ,רש"י
1020 ,אור עקיבא ,6503 ,ש בן גוריון
1020 ,אור עקיבא ,6506 ,ש קנדי
1020 ,אור עקיבא ,221 ,שבזי
1020 ,אור עקיבא ,154 ,שד המלך דוד
1020 ,אור עקיבא ,105 ,שד הנשיא וייצמן
1020 ,אור עקיבא ,215 ,שד ירושלים
1020 ,אור עקיבא ,180 ,שד שידלובסקי
1020 ,אור עקיבא ,146 ,שושנת העמקים
1020 ,אור עקיבא ,6501 ,שיכון דו משפחתי
1020 ,אור עקיבא ,6510 ,שכ אורות
1020 ,אור עקיבא ,6511 ,שכ בנה ביתך
1020 ,אור עקיבא ,6512 ,שכ גני רבין
1020 ,אור עקיבא ,6505 ,שכ היובל
1020 ,אור עקיבא ,6507 ,שכ נוה אלון
1020 ,אור עקיבא ,6504 ,שכ קרוונים
1020 ,אור עקיבא ,6508 ,שכונת שז"ר
1020 ,אור עקיבא ,218 ,שלום עליכם
1020 ,אור עקיבא ,148 ,שניר
1020 ,אור עקיבא ,123 ,שפירא אברהם
1020 ,אור עקיבא ,142 ,תלפיות
780 ,אורה ,9000 ,אורה
780 ,אורה ,101 ,אורן
780 ,אורה ,102 ,אלה
780 ,אורה ,103 ,ארז
780 ,אורה ,104 ,גפן
780 ,אורה ,105 ,דולב
780 ,אורה ,106 ,דקל
780 ,אורה ,107 ,זית
780 ,אורה ,108 ,חצב
780 ,אורה ,109 ,יסמין
780 ,אורה ,110 ,לוטם
780 ,אורה ,111 ,נורית
780 ,אורה ,112 ,נרקיס
780 ,אורה ,113 ,ערבה
780 ,אורה ,114 ,רימון
780 ,אורה ,115 ,רקפת
780 ,אורה ,116 ,תאנה
780 ,אורה ,117 ,תלתן
2012 ,אורות ,9000 ,אורות
2012 ,אורות ,104 ,האילן
2012 ,אורות ,105 ,הדקל
2012 ,אורות ,102 ,החרוב
2012 ,אורות ,103 ,הצבר
2012 ,אורות ,101 ,שכ קרוונים
4013 ,אורטל ,9000 ,אורטל
403 ,אורים ,9000 ,אורים
403 ,אורים ,101 ,בית ראשון במולדת
882 ,אורנים ,9000 ,אורנים
3760 ,אורנית ,9000 ,אורנית
3760 ,אורנית ,173 ,איילון
3760 ,אורנית ,146 ,אירוס
3760 ,אורנית ,199 ,אלכסנדר
3760 ,אורנית ,113 ,אמנון ותמר
3760 ,אורנית ,185 ,בוקק
3760 ,אורנית ,164 ,דובדבן
3760 ,אורנית ,186 ,דן
3760 ,אורנית ,172 ,דרגות
3760 ,אורנית ,149 ,דרך אודם
3760 ,אורנית ,145 ,דרך החורש
3760 ,אורנית ,155 ,האגוז
3760 ,אורנית ,108 ,האורן
3760 ,אורנית ,137 ,האלה
3760 ,אורנית ,123 ,האלון
3760 ,אורנית ,190 ,הארבל
3760 ,אורנית ,158 ,הארגמן
3760 ,אורנית ,148 ,הארז
3760 ,אורנית ,143 ,האשל
3760 ,אורנית ,152 ,האתרוג
3760 ,אורנית ,101 ,הברוש
3760 ,אורנית ,175 ,הגלבוע
3760 ,אורנית ,198 ,הגעתון
3760 ,אורנית ,139 ,הגפן
3760 ,אורנית ,159 ,הדגנית
3760 ,אורנית ,151 ,הדולב
3760 ,אורנית ,141 ,הדקל
3760 ,אורנית ,110 ,ההדס
3760 ,אורנית ,111 ,ההרדוף
3760 ,אורנית ,124 ,הורד
3760 ,אורנית ,112 ,הזית
3760 ,אורנית ,120 ,החבצלת
3760 ,אורנית ,103 ,החיטה
3760 ,אורנית ,117 ,החצב
3760 ,אורנית ,181 ,החצבני
3760 ,אורנית ,138 ,החרוב
3760 ,אורנית ,177 ,החרמון
3760 ,אורנית ,119 ,החרצית
3760 ,אורנית ,130 ,הטבק
3760 ,אורנית ,129 ,היסמין
3760 ,אורנית ,126 ,היקינטון
3760 ,אורנית ,189 ,הירדן
3760 ,אורנית ,183 ,הירקון
3760 ,אורנית ,107 ,הכלנית
3760 ,אורנית ,121 ,הכרכום
3760 ,אורנית ,176 ,הכרמל
3760 ,אורנית ,153 ,הלוטוס
3760 ,אורנית ,125 ,הלוטם
3760 ,אורנית ,161 ,הליבנה
3760 ,אורנית ,140 ,הלילך
3760 ,אורנית ,162 ,המורן
3760 ,אורנית ,160 ,המרגנית
3760 ,אורנית ,115 ,הנורית
3760 ,אורנית ,127 ,הנרקיס
3760 ,אורנית ,131 ,הסביון
3760 ,אורנית ,122 ,הסחלב
3760 ,אורנית ,114 ,הסיגלית
3760 ,אורנית ,136 ,הסייפן
3760 ,אורנית ,135 ,הערבה
3760 ,אורנית ,204 ,הפטל
3760 ,אורנית ,154 ,הפיקוס
3760 ,אורנית ,116 ,הפעמונית
3760 ,אורנית ,207 ,הפקאן
3760 ,אורנית ,128 ,הפרג
3760 ,אורנית ,142 ,הצאלון
3760 ,אורנית ,118 ,הצבעוני
3760 ,אורנית ,102 ,הצבר
3760 ,אורנית ,182 ,הקישון
3760 ,אורנית ,179 ,הר הצופים
3760 ,אורנית ,178 ,הר כנען
3760 ,אורנית ,144 ,הרותם
3760 ,אורנית ,133 ,הרימון
3760 ,אורנית ,106 ,הרקפת
3760 ,אורנית ,147 ,השיזף
3760 ,אורנית ,105 ,השיטה
3760 ,אורנית ,104 ,השקד
3760 ,אורנית ,132 ,התאנה
3760 ,אורנית ,170 ,התבור
3760 ,אורנית ,156 ,התדהר
3760 ,אורנית ,157 ,התלתן
3760 ,אורנית ,201 ,התמר
3760 ,אורנית ,188 ,זיו
3760 ,אורנית ,109 ,לוע הארי
3760 ,אורנית ,150 ,מבוא הזית
3760 ,אורנית ,191 ,מירון
3760 ,אורנית ,174 ,נעמן
3760 ,אורנית ,206 ,סמטת האגס
3760 ,אורנית ,205 ,סמטת האפרסק
3760 ,אורנית ,200 ,סמטת הארבל
3760 ,אורנית ,208 ,סמטת המנגו
3760 ,אורנית ,203 ,סמטת התפוז
3760 ,אורנית ,202 ,סמטת התפוח
3760 ,אורנית ,192 ,עצמון
3760 ,אורנית ,184 ,ערוגות
3760 ,אורנית ,197 ,פארן
3760 ,אורנית ,180 ,פולג
3760 ,אורנית ,187 ,פרצים
3760 ,אורנית ,193 ,צין
3760 ,אורנית ,171 ,רביבים
3760 ,אורנית ,194 ,שורק
3760 ,אורנית ,6501 ,שכ בית וגן
3760 ,אורנית ,6500 ,שכ צמרות
3760 ,אורנית ,195 ,שניר
3760 ,אורנית ,163 ,תות
3760 ,אורנית ,196 ,תנינים
278 ,אושה ,9000 ,אושה
565 ,אזור ,9000 ,אזור
565 ,אזור ,6503 ,אזור התעשייה
565 ,אזור ,105 ,אחד במאי
565 ,אזור ,111 ,אחד העם
565 ,אזור ,150 ,אלי כהן
565 ,אזור ,123 ,אנילביץ
565 ,אזור ,121 ,ארלוזורוב
565 ,אזור ,119 ,בורוכוב
565 ,אזור ,129 ,ביאליק
565 ,אזור ,131 ,ביל"ו
565 ,אזור ,138 ,בן צבי יצחק
565 ,אזור ,126 ,בנבנישתי אברהם
565 ,אזור ,151 ,ברזילי
565 ,אזור ,6509 ,גני אזור
565 ,אזור ,153 ,דרך אריאל שרון
565 ,אזור ,134 ,דרך השבעה
565 ,אזור ,175 ,האיכרים
565 ,אזור ,161 ,האקליפטוס
565 ,אזור ,115 ,הבנים
565 ,אזור ,167 ,הברוש
565 ,אזור ,125 ,ההגנה
565 ,אזור ,124 ,ההסתדרות
565 ,אזור ,166 ,החצב
565 ,אזור ,132 ,החשמונאים
565 ,אזור ,141 ,היסמין
565 ,אזור ,162 ,המצודה
565 ,אזור ,127 ,העליה השניה
565 ,אזור ,104 ,הפלמ"ח
565 ,אזור ,165 ,הפרדס
565 ,אזור ,164 ,הצבר
565 ,אזור ,106 ,הצנחנים
565 ,אזור ,148 ,הרימון
565 ,אזור ,149 ,הרצוג
565 ,אזור ,101 ,הרצל
565 ,אזור ,144 ,השבעה
565 ,אזור ,114 ,השלום
565 ,אזור ,163 ,השקמה
565 ,אזור ,107 ,ויצמן
565 ,אזור ,139 ,ז'בוטינסקי
565 ,אזור ,147 ,ז'ז'ק דוד
565 ,אזור ,109 ,חסין חיים
565 ,אזור ,154 ,יוסף מגנזי
565 ,אזור ,118 ,יצחק שדה
565 ,אזור ,168 ,ירושלים
565 ,אזור ,110 ,כצנלסון
565 ,אזור ,116 ,ליכט
565 ,אזור ,136 ,מדורגי המצודה
565 ,אזור ,117 ,מכבים
565 ,אזור ,158 ,משעול יעקב
565 ,אזור ,108 ,נורדאו
565 ,אזור ,102 ,סוקולוב
565 ,אזור ,143 ,סמטה אלמונית
565 ,אזור ,122 ,סנש חנה
565 ,אזור ,140 ,ספיר פנחס
565 ,אזור ,152 ,עורקבי צדוק
565 ,אזור ,133 ,פינסקר
565 ,אזור ,130 ,פרץ
565 ,אזור ,112 ,קפלן
565 ,אזור ,128 ,רמב"ם
565 ,אזור ,113 ,רמז
565 ,אזור ,6500 ,ש גג
565 ,אזור ,6501 ,ש החקלאים
565 ,אזור ,6502 ,ש חדש
565 ,אזור ,6504 ,ש עולים
565 ,אזור ,6505 ,ש צריפונים
565 ,אזור ,6506 ,ש רסקו
565 ,אזור ,146 ,שבזי
565 ,אזור ,137 ,שד בן גוריון
565 ,אזור ,120 ,שוחט מניה
565 ,אזור ,6511 ,שכ בן גוריון
565 ,אזור ,6507 ,שכ גן ורבורג
565 ,אזור ,6510 ,שכ יצחק שדה
565 ,אזור ,6508 ,שכ שבענה
565 ,אזור ,103 ,שפינוזה
565 ,אזור ,142 ,שפירא
565 ,אזור ,157 ,שפרינצק
565 ,אזור ,135 ,שרת משה
1157 ,אחווה ,9000 ,אחווה
1157 ,אחווה ,106 ,הגפן
1157 ,אחווה ,103 ,זית
1157 ,אחווה ,101 ,חיטה
1157 ,אחווה ,6500 ,נאות אחווה
1157 ,אחווה ,104 ,רימון
1157 ,אחווה ,102 ,שעורה
1157 ,אחווה ,105 ,תמר
821 ,אחוזם ,9000 ,אחוזם
1330 ,אחוזת ברק ,9000 ,אחוזת ברק
1330 ,אחוזת ברק ,101 ,אירוסים
1330 ,אחוזת ברק ,104 ,אלה
1330 ,אחוזת ברק ,103 ,אלון
1330 ,אחוזת ברק ,118 ,בית ראשון במולדת
1330 ,אחוזת ברק ,117 ,דבורנית
1330 ,אחוזת ברק ,119 ,הארז
1330 ,אחוזת ברק ,125 ,הדקל
1330 ,אחוזת ברק ,126 ,התאנה
1330 ,אחוזת ברק ,105 ,זית
1330 ,אחוזת ברק ,112 ,חבצלת
1330 ,אחוזת ברק ,122 ,חצב
1330 ,אחוזת ברק ,109 ,כלניות
1330 ,אחוזת ברק ,111 ,כרכום
1330 ,אחוזת ברק ,123 ,לילך
1330 ,אחוזת ברק ,116 ,מורן
1330 ,אחוזת ברק ,108 ,נוריות
1330 ,אחוזת ברק ,107 ,נרקיסים
1330 ,אחוזת ברק ,121 ,סחלב
1330 ,אחוזת ברק ,124 ,סייפן
1330 ,אחוזת ברק ,113 ,סיתוונית
1330 ,אחוזת ברק ,115 ,רותם
1330 ,אחוזת ברק ,120 ,רימונים
1330 ,אחוזת ברק ,110 ,רקפות
1330 ,אחוזת ברק ,106 ,שושנים
1330 ,אחוזת ברק ,114 ,תורמוס
785 ,אחיהוד ,9000 ,אחיהוד
785 ,אחיהוד ,104 ,הגפן
785 ,אחיהוד ,101 ,הדקל
785 ,אחיהוד ,103 ,הזית
785 ,אחיהוד ,110 ,החרוב
785 ,אחיהוד ,106 ,הצאלון
785 ,אחיהוד ,109 ,הרימון
785 ,אחיהוד ,105 ,השקד
785 ,אחיהוד ,108 ,התאנה
785 ,אחיהוד ,102 ,התמר
785 ,אחיהוד ,107 ,שביל החלב
850 ,אחיטוב ,9000 ,אחיטוב
850 ,אחיטוב ,104 ,אלמוג
850 ,אחיטוב ,101 ,דקלים
850 ,אחיטוב ,110 ,האורן
850 ,אחיטוב ,108 ,האלה
850 ,אחיטוב ,109 ,הארז
850 ,אחיטוב ,103 ,הגפן
850 ,אחיטוב ,105 ,הזית
850 ,אחיטוב ,107 ,הלוטם
850 ,אחיטוב ,6500 ,הרחבה
850 ,אחיטוב ,102 ,הרימון
850 ,אחיטוב ,6002 ,ככר הבנים
850 ,אחיטוב ,6001 ,ככר המייסדים
850 ,אחיטוב ,106 ,תאנה
804 ,אחיסמך ,9000 ,אחיסמך
804 ,אחיסמך ,102 ,הגפן
804 ,אחיסמך ,105 ,הדקל
804 ,אחיסמך ,103 ,הזית
804 ,אחיסמך ,104 ,התאנה
804 ,אחיסמך ,101 ,שבעת המינים
797 ,אחיעזר ,9000 ,אחיעזר
797 ,אחיעזר ,108 ,האלון
797 ,אחיעזר ,105 ,האתרוג
797 ,אחיעזר ,101 ,הגפן
797 ,אחיעזר ,106 ,הדקל
797 ,אחיעזר ,103 ,הזית
797 ,אחיעזר ,104 ,הרימון
797 ,אחיעזר ,102 ,התאנה
797 ,אחיעזר ,107 ,יסמין
965 ,אטרש (שבט) ,9000 ,אטרש (שבט)
338 ,איבים ,9000 ,איבים
716 ,אייל ,9000 ,אייל
77 ,איילת השחר ,9000 ,איילת השחר
77 ,איילת השחר ,101 ,איילת השחר (מ ק)
294 ,אילון ,9000 ,אילון
1126 ,אילות ,9000 ,אילות
49 ,אילניה ,9000 ,אילניה
49 ,אילניה ,110 ,אל המעיין
49 ,אילניה ,109 ,דרך הברוש
49 ,אילניה ,111 ,האלונים
49 ,אילניה ,102 ,הגורן
49 ,אילניה ,104 ,הגת
49 ,אילניה ,105 ,הזית
49 ,אילניה ,107 ,החאן
49 ,אילניה ,108 ,המושבה
49 ,אילניה ,103 ,הצבר
49 ,אילניה ,7001 ,חות השומר
49 ,אילניה ,112 ,מגדל המים
49 ,אילניה ,106 ,מח"ל
49 ,אילניה ,101 ,מעלה גולני
49 ,אילניה ,113 ,נוף תבור
2600 ,אילת ,801 ,אביב
2600 ,אילת ,436 ,אבני החושן
2600 ,אילת ,687 ,אברהם אבינו
2600 ,אילת ,110 ,אגמונים
2600 ,אילת ,141 ,אדום
2600 ,אילת ,499 ,אדמון
2600 ,אילת ,416 ,אדמונית
2600 ,אילת ,140 ,אדר
2600 ,אילת ,417 ,אודם
2600 ,אילת ,391 ,אוכם
2600 ,אילת ,267 ,אופירה
2600 ,אילת ,454 ,אוריון
2600 ,אילת ,418 ,אזוב
2600 ,אילת ,6500 ,אזור המלונות
2600 ,אילת ,6501 ,אזור התעשיה
2600 ,אילת ,803 ,אח"י אילת
2600 ,אילת ,455 ,אטד
2600 ,אילת ,510 ,איזמרגד
2600 ,אילת ,796 ,אייר
2600 ,אילת ,105 ,אילות
2600 ,אילת ,9000 ,אילת
2600 ,אילת ,203 ,אכסניית הנסיך הקטן
2600 ,אילת ,152 ,אכסנית אביב
2600 ,אילת ,546 ,אכסנית אל ארצי
2600 ,אילת ,156 ,אכסנית בית הערבה
2600 ,אילת ,548 ,אכסנית הבית הלבן
2600 ,אילת ,549 ,אכסנית המקלט
2600 ,אילת ,131 ,אכסנית הנוער
2600 ,אילת ,154 ,אכסנית הרי אדום
2600 ,אילת ,160 ,אכסנית טאבה
2600 ,אילת ,155 ,אכסנית לב העיר
2600 ,אילת ,784 ,אלוורה
2600 ,אילת ,800 ,אלול
2600 ,אילת ,529 ,אנטיב
2600 ,אילת ,447 ,אננס
2600 ,אילת ,252 ,אנפה
2600 ,אילת ,682 ,אסתר המלכה
2600 ,אילת ,456 ,אפון
2600 ,אילת ,238 ,אפעה
2600 ,אילת ,262 ,אריקה
2600 ,אילת ,567 ,ארנבת
2600 ,אילת ,142 ,ארנון
2600 ,אילת ,186 ,ביח יוספטל
2600 ,אילת ,129 ,בית אורן
2600 ,אילת ,180 ,בית אמיתי
2600 ,אילת ,193 ,בית החייל
2600 ,אילת ,158 ,בית הכורה
2600 ,אילת ,159 ,בית הממשלה
2600 ,אילת ,590 ,בית עופר
2600 ,אילת ,6502 ,בנה ביתך
2600 ,אילת ,594 ,בני ישראל
2600 ,אילת ,772 ,בקיה
2600 ,אילת ,103 ,ברנע
2600 ,אילת ,349 ,ברקן
2600 ,אילת ,419 ,ברקת
2600 ,אילת ,225 ,בשמת
2600 ,אילת ,280 ,בשן
2600 ,אילת ,457 ,בתרן
2600 ,אילת ,751 ,גד השדה
2600 ,אילת ,781 ,גדילן
2600 ,אילת ,458 ,גומא
2600 ,אילת ,498 ,גיטרן
2600 ,אילת ,127 ,גן-בנימין
2600 ,אילת ,124 ,גנים
2600 ,אילת ,597 ,גרביל
2600 ,אילת ,752 ,גרגיר הנחלים
2600 ,אילת ,170 ,גרופית
2600 ,אילת ,380 ,גרניט
2600 ,אילת ,104 ,גשרון
2600 ,אילת ,680 ,דבורה הנביאה
2600 ,אילת ,144 ,דגנית
2600 ,אילת ,624 ,דובדבן
2600 ,אילת ,776 ,דודאים
2600 ,אילת ,753 ,דוחן
2600 ,אילת ,420 ,דולב
2600 ,אילת ,445 ,דולפין
2600 ,אילת ,146 ,דום
2600 ,אילת ,562 ,דורבן
2600 ,אילת ,268 ,די זהב
2600 ,אילת ,459 ,דפדוף
2600 ,אילת ,754 ,דפנה
2600 ,אילת ,460 ,דקר
2600 ,אילת ,355 ,דרבן
2600 ,אילת ,461 ,דרדר
2600 ,אילת ,253 ,דרור
2600 ,אילת ,729 ,דרך ארץ החיים
2600 ,אילת ,730 ,דרך באר שבע
2600 ,אילת ,760 ,דרך הבשמים
2600 ,אילת ,635 ,דרך הגיא
2600 ,אילת ,726 ,דרך המטעים
2600 ,אילת ,692 ,דרך המלכים
2600 ,אילת ,115 ,דרך הערבה
2600 ,אילת ,572 ,דרך הרים
2600 ,אילת ,725 ,דרך השיירות
2600 ,אילת ,750 ,דרך התבלינים
2600 ,אילת ,147 ,דרך יותם
2600 ,אילת ,255 ,דרך מצריים
2600 ,אילת ,727 ,דרךהציפורים הנודדות
2600 ,אילת ,585 ,האדומים
2600 ,אילת ,359 ,האדריכל
2600 ,אילת ,656 ,האהבה
2600 ,אילת ,331 ,האומן
2600 ,אילת ,392 ,האורגים
2600 ,אילת ,657 ,האושר
2600 ,אילת ,659 ,האחווה
2600 ,אילת ,393 ,האחות
2600 ,אילת ,166 ,האיצטדיון
2600 ,אילת ,347 ,האירוס
2600 ,אילת ,582 ,האכדים
2600 ,אילת ,340 ,האלה
2600 ,אילת ,111 ,האלמוגים
2600 ,אילת ,577 ,האמורים
2600 ,אילת ,818 ,האסיף
2600 ,אילת ,623 ,האפרסק
2600 ,אילת ,299 ,האשל
2600 ,אילת ,306 ,הבורסקאי
2600 ,אילת ,813 ,הביכורים
2600 ,אילת ,319 ,הבלן
2600 ,אילת ,324 ,הבנאי
2600 ,אילת ,308 ,הברדלס
2600 ,אילת ,345 ,הברוש
2600 ,אילת ,658 ,הברכה
2600 ,אילת ,128 ,הגופרית
2600 ,אילת ,373 ,הגיר
2600 ,אילת ,316 ,הגלב
2600 ,אילת ,578 ,הגלעדים
2600 ,אילת ,598 ,הגמלים
2600 ,אילת ,395 ,הגנן
2600 ,אילת ,446 ,הגפן
2600 ,אילת ,394 ,הדייג
2600 ,אילת ,462 ,הדס
2600 ,אילת ,257 ,ההשכלה
2600 ,אילת ,333 ,הזגג
2600 ,אילת ,437 ,החוצב
2600 ,אילת ,119 ,החורב
2600 ,אילת ,370 ,החותרים
2600 ,אילת ,587 ,החיתים
2600 ,אילת ,422 ,החרצית
2600 ,אילת ,360 ,החרש
2600 ,אילת ,575 ,החשמונאים
2600 ,אילת ,733 ,הטרמינל
2600 ,אילת ,366 ,היוצק
2600 ,אילת ,543 ,הים
2600 ,אילת ,570 ,הירח
2600 ,אילת ,661 ,הכוזרים
2600 ,אילת ,579 ,הכנענים
2600 ,אילת ,665 ,הלב
2600 ,אילת ,580 ,המדיינים
2600 ,אילת ,581 ,המואבים
2600 ,אילת ,323 ,המוצר
2600 ,אילת ,396 ,המיילדת
2600 ,אילת ,535 ,המים
2600 ,אילת ,660 ,המכבים
2600 ,אילת ,273 ,המכס
2600 ,אילת ,332 ,המלאכה
2600 ,אילת ,125 ,המלחה
2600 ,אילת ,321 ,המסגר
2600 ,אילת ,497 ,המערב
2600 ,אילת ,8002 ,המרינה
2600 ,אילת ,588 ,הנבטים
2600 ,אילת ,325 ,הנגר
2600 ,אילת ,334 ,הנהג
2600 ,אילת ,439 ,הנווט
2600 ,אילת ,318 ,הנחתום
2600 ,אילת ,8001 ,הנמל
2600 ,אילת ,326 ,הנפח
2600 ,אילת ,421 ,הנץ
2600 ,אילת ,266 ,הנשר
2600 ,אילת ,662 ,הנתינה
2600 ,אילת ,339 ,הסוור
2600 ,אילת ,365 ,הסוללים
2600 ,אילת ,464 ,הסיגלית
2600 ,אילת ,397 ,הסנדלר
2600 ,אילת ,372 ,הספורטאים
2600 ,אילת ,317 ,הספן
2600 ,אילת ,309 ,הסתת
2600 ,אילת ,583 ,העמונים
2600 ,אילת ,254 ,העפרוני
2600 ,אילת ,817 ,העצמאות
2600 ,אילת ,364 ,הפחח
2600 ,אילת ,245 ,הפלמ"ח
2600 ,אילת ,233 ,הפרטיזנים
2600 ,אילת ,341 ,הצאלון
2600 ,אילת ,120 ,הצבאים
2600 ,אילת ,398 ,הצדפים
2600 ,אילת ,330 ,הצלחה
2600 ,אילת ,348 ,הצפצפה
2600 ,אילת ,819 ,הקציר
2600 ,אילת ,571 ,הרנוג
2600 ,אילת ,666 ,הרעות
2600 ,אילת ,363 ,הרצף
2600 ,אילת ,307 ,הרתך
2600 ,אילת ,584 ,השומרונים
2600 ,אילת ,589 ,השומרים
2600 ,אילת ,116 ,השחם
2600 ,אילת ,298 ,השיטה
2600 ,אילת ,667 ,השלווה
2600 ,אילת ,438 ,השמאי
2600 ,אילת ,663 ,השמחה
2600 ,אילת ,362 ,השרברב
2600 ,אילת ,367 ,השרטט
2600 ,אילת ,328 ,התבונה
2600 ,אילת ,399 ,התוכנה
2600 ,אילת ,400 ,התופרת
2600 ,אילת ,327 ,התושיה
2600 ,אילת ,664 ,התקווה
2600 ,אילת ,670 ,ונוס
2600 ,אילת ,677 ,זהבית
2600 ,אילת ,466 ,זהורית
2600 ,אילת ,467 ,זהרון
2600 ,אילת ,676 ,זוגן
2600 ,אילת ,496 ,זיתן
2600 ,אילת ,322 ,זעת אפיך
2600 ,אילת ,230 ,זרזיר
2600 ,אילת ,789 ,חג האילנות
2600 ,אילת ,806 ,חג החנוכה
2600 ,אילת ,810 ,חג הסוכות
2600 ,אילת ,814 ,חג השבועות
2600 ,אילת ,785 ,חודשי השנה
2600 ,אילת ,7003 ,חוות רודד
2600 ,אילת ,6504 ,חוף אלמוג
2600 ,אילת ,123 ,חטיבת גולני
2600 ,אילת ,109 ,חטיבת הנגב
2600 ,אילת ,773 ,חיטת הבר
2600 ,אילת ,512 ,חיל ההנדסה
2600 ,אילת ,507 ,חיל הים
2600 ,אילת ,782 ,חיננית
2600 ,אילת ,171 ,חכליל
2600 ,אילת ,678 ,חלמית
2600 ,אילת ,469 ,חניתן
2600 ,אילת ,235 ,חרדל
2600 ,אילת ,791 ,חשון
2600 ,אילת ,552 ,חתול הבר
2600 ,אילת ,799 ,ט"ו באב
2600 ,אילת ,793 ,טבת
2600 ,אילת ,470 ,טווסון
2600 ,אילת ,423 ,טופז
2600 ,אילת ,234 ,טוריים
2600 ,אילת ,511 ,טורקיז
2600 ,אילת ,636 ,טיילת רחבעם זאבי
2600 ,אילת ,816 ,טל ומטר
2600 ,אילת ,232 ,יהודה
2600 ,אילת ,451 ,יהושפט
2600 ,אילת ,574 ,יהל
2600 ,אילת ,118 ,יהלום
2600 ,אילת ,329 ,יוזמה
2600 ,אילת ,471 ,יולית ים סוף
2600 ,אילת ,788 ,יום אילת
2600 ,אילת ,150 ,ים סוף
2600 ,אילת ,424 ,יסמין
2600 ,אילת ,689 ,יעקב אבינו
2600 ,אילת ,425 ,יערה
2600 ,אילת ,551 ,יפה זנב
2600 ,אילת ,688 ,יצחק אבינו
2600 ,אילת ,568 ,ירבוע
2600 ,אילת ,442 ,ירדן
2600 ,אילת ,138 ,ירושלים השלמה
2600 ,אילת ,755 ,כוכב
2600 ,אילת ,6037 ,כיכר אדן
2600 ,אילת ,6034 ,כיכר אנטיב
2600 ,אילת ,6045 ,כיכר בגין
2600 ,אילת ,6044 ,כיכר גולדה מאיר
2600 ,אילת ,6033 ,כיכר האוניברסיטה
2600 ,אילת ,6052 ,כיכר הג'אז
2600 ,אילת ,6051 ,כיכר הים האדום
2600 ,אילת ,6050 ,כיכר הרי אילת
2600 ,אילת ,6048 ,כיכר הרמזורים
2600 ,אילת ,6049 ,כיכר השחפים
2600 ,אילת ,6041 ,כיכר השלושה
2600 ,אילת ,6021 ,כיכר ויצ"ו
2600 ,אילת ,6035 ,כיכר יאלטה
2600 ,אילת ,6042 ,כיכר יגאל אלון
2600 ,אילת ,6020 ,כיכר ים סוף
2600 ,אילת ,6053 ,כיכר יצחק נבון
2600 ,אילת ,6047 ,כיכר יצחק שמיר
2600 ,אילת ,6046 ,כיכר לוי אשכול
2600 ,אילת ,6043 ,כיכר משה שרת
2600 ,אילת ,6001 ,ככר אילים
2600 ,אילת ,6015 ,ככר אריות
2600 ,אילת ,6002 ,ככר גרוף
2600 ,אילת ,6031 ,ככר דוד בן גוריון
2600 ,אילת ,6028 ,ככר הבונים החופשיים
2600 ,אילת ,6029 ,ככר הדולפינים
2600 ,אילת ,6003 ,ככר הזאבים
2600 ,אילת ,6017 ,ככר המייסדים
2600 ,אילת ,6023 ,ככר הנופלים
2600 ,אילת ,6004 ,ככר הנמרים
2600 ,אילת ,6024 ,ככר הנשים
2600 ,אילת ,6039 ,ככר ורד
2600 ,אילת ,6016 ,ככר זעמן
2600 ,אילת ,6026 ,ככר חב"ד
2600 ,אילת ,6038 ,ככר חורשף
2600 ,אילת ,6005 ,ככר חצרות
2600 ,אילת ,6019 ,ככר טורונטו
2600 ,אילת ,6032 ,ככר יצחק מודעי
2600 ,אילת ,6022 ,ככר ירושלים
2600 ,אילת ,6006 ,ככר ליבנה
2600 ,אילת ,6025 ,ככר מגדלי התאומים
2600 ,אילת ,6007 ,ככר מלכי יהודה
2600 ,אילת ,6008 ,ככר מלכי ישראל
2600 ,אילת ,6009 ,ככר מקהלות
2600 ,אילת ,6010 ,ככר נטפים
2600 ,אילת ,6012 ,ככר צנינית
2600 ,אילת ,6018 ,ככר ראשי העיר
2600 ,אילת ,6027 ,ככר רבין
2600 ,אילת ,6030 ,ככר רוטרי
2600 ,אילת ,6013 ,ככר רפידים
2600 ,אילת ,6014 ,ככר תל אביב
2600 ,אילת ,756 ,כליל החורש
2600 ,אילת ,441 ,כנרת
2600 ,אילת ,792 ,כסלו
2600 ,אילת ,728 ,כפות תמרים
2600 ,אילת ,6505 ,כפר הנופש
2600 ,אילת ,495 ,כריש
2600 ,אילת ,721 ,כרמל
2600 ,אילת ,757 ,כרפס
2600 ,אילת ,777 ,לבונה
2600 ,אילת ,778 ,לבנדר
2600 ,אילת ,472 ,להבית
2600 ,אילת ,779 ,לואיזה
2600 ,אילת ,149 ,לוחמי הגיטאות
2600 ,אילת ,426 ,לוטוס
2600 ,אילת ,139 ,לוס אנג'לס
2600 ,אילת ,774 ,לוף ארץ ישראלי
2600 ,אילת ,342 ,לילך
2600 ,אילת ,758 ,לימונית
2600 ,אילת ,427 ,לשם
2600 ,אילת ,731 ,מגדל הפיקוח
2600 ,אילת ,358 ,מגדל חי
2600 ,אילת ,197 ,מגדלי אילת
2600 ,אילת ,148 ,מדיין
2600 ,אילת ,214 ,מועדון הים התיכון
2600 ,אילת ,201 ,מועדון צלילה סנובה
2600 ,אילת ,787 ,מועדי ישראל
2600 ,אילת ,775 ,מור
2600 ,אילת ,6506 ,מח סוב
2600 ,אילת ,6507 ,מחנה יוכבד
2600 ,אילת ,684 ,מיכל בת שאול
2600 ,אילת ,145 ,מישר
2600 ,אילת ,215 ,מלון אמבסדור
2600 ,אילת ,212 ,מלון אמריקנה
2600 ,אילת ,187 ,מלון בית אשל
2600 ,אילת ,210 ,מלון בל
2600 ,אילת ,242 ,מלון בקעת הירדן
2600 ,אילת ,240 ,מלון בקעת הירח
2600 ,אילת ,531 ,מלון גולדן טוליפ
2600 ,אילת ,244 ,מלון גלי אילת
2600 ,אילת ,196 ,מלון גני שולמית
2600 ,אילת ,508 ,מלון דורטל סול
2600 ,אילת ,205 ,מלון דליה
2600 ,אילת ,250 ,מלון דן
2600 ,אילת ,520 ,מלון דן פנורמה
2600 ,אילת ,157 ,מלון דקל
2600 ,אילת ,530 ,מלון הולידיי אין
2600 ,אילת ,189 ,מלון הים האדום
2600 ,אילת ,218 ,מלון המלך שלמה
2600 ,אילת ,536 ,מלון הני-מון
2600 ,אילת ,185 ,מלון הסלע האדום
2600 ,אילת ,504 ,מלון הקונכיה
2600 ,אילת ,537 ,מלון הרודס
2600 ,אילת ,264 ,מלון הרי אדום
2600 ,אילת ,505 ,מלון הריף
2600 ,אילת ,263 ,מלון ויסטה
2600 ,אילת ,188 ,מלון זוהר
2600 ,אילת ,542 ,מלון טופז
2600 ,אילת ,199 ,מלון לב אילת
2600 ,אילת ,524 ,מלון לב פתאל
2600 ,אילת ,241 ,מלון לגונה
2600 ,אילת ,591 ,מלון לויס טאואר
2600 ,אילת ,207 ,מלון מוריה פלאזה
2600 ,אילת ,521 ,מלון מיראג'
2600 ,אילת ,132 ,מלון מלכת שבא
2600 ,אילת ,213 ,מלון מפרץ השמש
2600 ,אילת ,6503 ,מלון מרדיאן
2600 ,אילת ,540 ,מלון מרידיאן
2600 ,אילת ,151 ,מלון נובה
2600 ,אילת ,376 ,מלון נוף המפרץ
2600 ,אילת ,184 ,מלון נפטון
2600 ,אילת ,191 ,מלון סיני
2600 ,אילת ,211 ,מלון סנטר
2600 ,אילת ,190 ,מלון סנפיר
2600 ,אילת ,217 ,מלון ספורט
2600 ,אילת ,198 ,מלון עדי
2600 ,אילת ,169 ,מלון עציון
2600 ,אילת ,538 ,מלון פטיו
2600 ,אילת ,161 ,מלון פטרה
2600 ,אילת ,204 ,מלון פייר
2600 ,אילת ,539 ,מלון פלמירה
2600 ,אילת ,502 ,מלון פנורמה
2600 ,אילת ,690 ,מלון פרדייז גארדן
2600 ,אילת ,130 ,מלון צברה
2600 ,אילת ,208 ,מלון קיסר
2600 ,אילת ,501 ,מלון קלאב אין
2600 ,אילת ,503 ,מלון קלאב הוטל
2600 ,אילת ,509 ,מלון קלאב מד
2600 ,אילת ,522 ,מלון קראון פלאזה
2600 ,אילת ,182 ,מלון קרוון
2600 ,אילת ,506 ,מלון רד סי ספורט
2600 ,אילת ,532 ,מלון רויאל ביץ'
2600 ,אילת ,523 ,מלון רויאל גארדן
2600 ,אילת ,558 ,מלון רויאל פארק
2600 ,אילת ,533 ,מלון ריביירה
2600 ,אילת ,534 ,מלון שלום פלאזה
2600 ,אילת ,181 ,מלון שלמה
2600 ,אילת ,165 ,מלון תמר
2600 ,אילת ,164 ,מלון תרשיש
2600 ,אילת ,780 ,מליסה
2600 ,אילת ,375 ,מלכת שבא
2600 ,אילת ,759 ,מעוג
2600 ,אילת ,102 ,מעונות מכבי
2600 ,אילת ,6508 ,מערב 1
2600 ,אילת ,6509 ,מערב 2
2600 ,אילת ,6542 ,מצפה נוף
2600 ,אילת ,474 ,מקור החסידה
2600 ,אילת ,783 ,מרגנית
2600 ,אילת ,107 ,מרווה
2600 ,אילת ,569 ,מריון
2600 ,אילת ,126 ,מרינה
2600 ,אילת ,369 ,מרכז קליטה
2600 ,אילת ,515 ,מרכז רזין
2600 ,אילת ,6511 ,מרכז רכטר
2600 ,אילת ,6510 ,מרכז תיירות
2600 ,אילת ,452 ,משעול אבובן
2600 ,אילת ,611 ,משעול אבוקדו
2600 ,אילת ,453 ,משעול אבנון
2600 ,אילת ,604 ,משעול אגוז
2600 ,אילת ,694 ,משעול אהרון הכהן
2600 ,אילת ,405 ,משעול אירית
2600 ,אילת ,607 ,משעול אלון
2600 ,אילת ,631 ,משעול אנונה
2600 ,אילת ,612 ,משעול אפרסמון
2600 ,אילת ,440 ,משעול אקליפטוס
2600 ,אילת ,617 ,משעול אשכולית
2600 ,אילת ,615 ,משעול אתרוג
2600 ,אילת ,638 ,משעול בננה
2600 ,אילת ,630 ,משעול גוייבה
2600 ,אילת ,734 ,משעול דקוטה
2600 ,אילת ,608 ,משעול האגס
2600 ,אילת ,260 ,משעול האמוראים
2600 ,אילת ,526 ,משעול הבז
2600 ,אילת ,258 ,משעול הגאונים
2600 ,אילת ,596 ,משעול הגירית
2600 ,אילת ,463 ,משעול החמנית
2600 ,אילת ,586 ,משעול הלויים
2600 ,אילת ,737 ,משעול הנורד
2600 ,אילת ,221 ,משעול העורב
2600 ,אילת ,404 ,משעול הקנה
2600 ,אילת ,595 ,משעול הר נבו
2600 ,אילת ,639 ,משעול התורן
2600 ,אילת ,272 ,משעול התלמוד
2600 ,אילת ,259 ,משעול התנאים
2600 ,אילת ,739 ,משעול וייקאונט
2600 ,אילת ,601 ,משעול זית
2600 ,אילת ,632 ,משעול חבושים
2600 ,אילת ,368 ,משעול חלבלוב
2600 ,אילת ,468 ,משעול חלילן
2600 ,אילת ,403 ,משעול חצב
2600 ,אילת ,618 ,משעול חרוב
2600 ,אילת ,236 ,משעול כלנית
2600 ,אילת ,637 ,משעול כרמים
2600 ,אילת ,473 ,משעול לופית
2600 ,אילת ,614 ,משעול לימון
2600 ,אילת ,278 ,משעול מחצבים
2600 ,אילת ,412 ,משעול מילחית
2600 ,אילת ,621 ,משעול מישמש
2600 ,אילת ,603 ,משעול מנגו
2600 ,אילת ,620 ,משעול מנדרינה
2600 ,אילת ,693 ,משעול משה רבנו
2600 ,אילת ,407 ,משעול נורית
2600 ,אילת ,406 ,משעול נרקיס
2600 ,אילת ,409 ,משעול סהרון
2600 ,אילת ,227 ,משעול סמר
2600 ,אילת ,223 ,משעול סנפיר
2600 ,אילת ,414 ,משעול עדעד
2600 ,אילת ,622 ,משעול עינב
2600 ,אילת ,602 ,משעול ערמון
2600 ,אילת ,633 ,משעול פומלה
2600 ,אילת ,413 ,משעול פלגית
2600 ,אילת ,610 ,משעול פקאן
2600 ,אילת ,410 ,משעול צבעוני
2600 ,אילת ,229 ,משעול צופר
2600 ,אילת ,402 ,משעול צלף
2600 ,אילת ,276 ,משעול צניפים
2600 ,אילת ,738 ,משעול קומודור
2600 ,אילת ,735 ,משעול קומנדו
2600 ,אילת ,736 ,משעול קטלינה
2600 ,אילת ,277 ,משעול רביב
2600 ,אילת ,411 ,משעול רוגן
2600 ,אילת ,685 ,משעול רחל אימנו
2600 ,אילת ,401 ,משעול ריחן
2600 ,אילת ,408 ,משעול ריסן
2600 ,אילת ,283 ,משעול שגיא
2600 ,אילת ,619 ,משעול שזיף
2600 ,אילת ,625 ,משעול שיקמה
2600 ,אילת ,605 ,משעול שקד
2600 ,אילת ,606 ,משעול תאנה
2600 ,אילת ,609 ,משעול תות
2600 ,אילת ,613 ,משעול תפוז
2600 ,אילת ,616 ,משעול תפוח
2600 ,אילת ,811 ,מתן תורה
2600 ,אילת ,269 ,נביעות
2600 ,אילת ,559 ,נווה מדבר
2600 ,אילת ,560 ,נוף מדבר
2600 ,אילת ,428 ,נופר
2600 ,אילת ,707 ,נחל גרעינית
2600 ,אילת ,640 ,נחל זרחן
2600 ,אילת ,643 ,נחל חביון
2600 ,אילת ,641 ,נחל חיון
2600 ,אילת ,270 ,נחל כתובות
2600 ,אילת ,642 ,נחל ממשית
2600 ,אילת ,143 ,נחל ניצוץ
2600 ,אילת ,194 ,נחל עמרם
2600 ,אילת ,655 ,נחל רחם
2600 ,אילת ,626 ,נחל שחרות
2600 ,אילת ,294 ,נחשון
2600 ,אילת ,795 ,ניסן
2600 ,אילת ,475 ,ניצרן
2600 ,אילת ,672 ,נירית
2600 ,אילת ,133 ,נמל אילת
2600 ,אילת ,600 ,נמנמן
2600 ,אילת ,271 ,נעמה
2600 ,אילת ,761 ,נענע משולבת
2600 ,אילת ,476 ,נפטון
2600 ,אילת ,429 ,נציץ
2600 ,אילת ,343 ,סביון
2600 ,אילת ,477 ,סולית
2600 ,אילת ,720 ,סוסיתא
2600 ,אילת ,722 ,סופה
2600 ,אילת ,344 ,סחלב
2600 ,אילת ,797 ,סיון
2600 ,אילת ,478 ,סייפן
2600 ,אילת ,385 ,סילון
2600 ,אילת ,135 ,סיני
2600 ,אילת ,762 ,סירה קוצנית
2600 ,אילת ,383 ,סמ אזורית
2600 ,אילת ,281 ,סמ אשליל
2600 ,אילת ,295 ,סמ ברק
2600 ,אילת ,290 ,סמ גבים
2600 ,אילת ,337 ,סמ דיה
2600 ,אילת ,303 ,סמ דישונים
2600 ,אילת ,286 ,סמ האשלג
2600 ,אילת ,723 ,סמ המכונאים
2600 ,אילת ,288 ,סמ המכרות
2600 ,אילת ,450 ,סמ המלך אחז
2600 ,אילת ,291 ,סמ המנגן
2600 ,אילת ,284 ,סמ המנהרות
2600 ,אילת ,285 ,סמ המסילות
2600 ,אילת ,292 ,סמ המערות
2600 ,אילת ,724 ,סמ הנגרים
2600 ,אילת ,122 ,סמ הנחושת
2600 ,אילת ,287 ,סמ הצוק
2600 ,אילת ,352 ,סמ הקורא
2600 ,אילת ,652 ,סמ הר גשור
2600 ,אילת ,649 ,סמ הר דלעת
2600 ,אילת ,651 ,סמ הר חלוקים
2600 ,אילת ,653 ,סמ הר יעלון
2600 ,אילת ,650 ,סמ הר לוץ
2600 ,אילת ,350 ,סמ הר סיני
2600 ,אילת ,654 ,סמ הר סעד
2600 ,אילת ,648 ,סמ הר שלמה
2600 ,אילת ,378 ,סמ הרדוף
2600 ,אילת ,302 ,סמ התנינים
2600 ,אילת ,338 ,סמ ורדית
2600 ,אילת ,310 ,סמ זיק
2600 ,אילת ,371 ,סמ חבצלת
2600 ,אילת ,382 ,סמ חלמיש
2600 ,אילת ,312 ,סמ טירן
2600 ,אילת ,415 ,סמ יסעור
2600 ,אילת ,304 ,סמ יענים
2600 ,אילת ,179 ,סמ כרכום
2600 ,אילת ,353 ,סמ לילית
2600 ,אילת ,377 ,סמ לענה
2600 ,אילת ,282 ,סמ מואב
2600 ,אילת ,494 ,סמ מולית
2600 ,אילת ,374 ,סמ מלכית
2600 ,אילת ,108 ,סמ נחושתן
2600 ,אילת ,706 ,סמ נחל אורה
2600 ,אילת ,629 ,סמ נחל חימר
2600 ,אילת ,704 ,סמ נחל חמדה
2600 ,אילת ,705 ,סמ נחל חרוז
2600 ,אילת ,628 ,סמ נחל מנוחה
2600 ,אילת ,702 ,סמ נחל ציחור
2600 ,אילת ,701 ,סמ נחל קטורה
2600 ,אילת ,703 ,סמ נחל שני
2600 ,אילת ,314 ,סמ ניצנה
2600 ,אילת ,293 ,סמ סיגים
2600 ,אילת ,209 ,סמ סלעית
2600 ,אילת ,335 ,סמ סנונית
2600 ,אילת ,315 ,סמ עומר
2600 ,אילת ,112 ,סמ עופרים
2600 ,אילת ,336 ,סמ עיט
2600 ,אילת ,384 ,סמ עירית
2600 ,אילת ,174 ,סמ ערד
2600 ,אילת ,173 ,סמ עשוש
2600 ,אילת ,279 ,סמ צוקים
2600 ,אילת ,301 ,סמ ראמים
2600 ,אילת ,289 ,סמ רביבים
2600 ,אילת ,305 ,סמ רימון
2600 ,אילת ,192 ,סמ שדמית
2600 ,אילת ,389 ,סמ שיזר
2600 ,אילת ,313 ,סמ שני
2600 ,אילת ,296 ,סמ שעיר
2600 ,אילת ,247 ,סמ שפיפון
2600 ,אילת ,493 ,סמ שפריר
2600 ,אילת ,563 ,סמור
2600 ,אילת ,379 ,סמטת ציפחה
2600 ,אילת ,434 ,ספיר
2600 ,אילת ,763 ,סרפד
2600 ,אילת ,346 ,סתונית
2600 ,אילת ,802 ,סתיו
2600 ,אילת ,527 ,עגור
2600 ,אילת ,786 ,עונות שנה
2600 ,אילת ,557 ,עטלפים
2600 ,אילת ,646 ,עין בוקק
2600 ,אילת ,634 ,עין גדי
2600 ,אילת ,686 ,עין דור
2600 ,אילת ,248 ,עין החתול
2600 ,אילת ,627 ,עין יהב
2600 ,אילת ,645 ,עין מור
2600 ,אילת ,576 ,עין נטפים
2600 ,אילת ,647 ,עין עקב
2600 ,אילת ,644 ,עין עקרבים
2600 ,אילת ,479 ,ערוד
2600 ,אילת ,675 ,ערער
2600 ,אילת ,137 ,פארן
2600 ,אילת ,7002 ,פארק תמנע
2600 ,אילת ,592 ,פולטי טאור
2600 ,אילת ,480 ,פזית
2600 ,אילת ,481 ,פטישן
2600 ,אילת ,482 ,פטל
2600 ,אילת ,573 ,פיאסטאני
2600 ,אילת ,483 ,פיקוס
2600 ,אילת ,484 ,פסון
2600 ,אילת ,815 ,פסח
2600 ,אילת ,561 ,פסמון
2600 ,אילת ,732 ,פקח טיסה
2600 ,אילת ,764 ,פרג
2600 ,אילת ,265 ,פרס
2600 ,אילת ,485 ,פרפרון
2600 ,אילת ,679 ,פרקרק
2600 ,אילת ,239 ,פתן
2600 ,אילת ,486 ,צבעון
2600 ,אילת ,671 ,צדק
2600 ,אילת ,6512 ,צופית עלית
2600 ,אילת ,6513 ,צופית תחתית
2600 ,אילת ,390 ,צור
2600 ,אילת ,136 ,צין
2600 ,אילת ,593 ,צפחות
2600 ,אילת ,237 ,צפע
2600 ,אילת ,246 ,קאמן
2600 ,אילת ,249 ,קאמפן
2600 ,אילת ,6514 ,קבוץ המאוחד
2600 ,אילת ,202 ,קדש
2600 ,אילת ,599 ,קוצן זהוב
2600 ,אילת ,767 ,קורנית
2600 ,אילת ,175 ,קטורה
2600 ,אילת ,766 ,קטלב מצוי
2600 ,אילת ,488 ,קידה
2600 ,אילת ,176 ,קידר
2600 ,אילת ,765 ,קינמון
2600 ,אילת ,430 ,קיסוס
2600 ,אילת ,490 ,קיסר הדור
2600 ,אילת ,565 ,קיפוד
2600 ,אילת ,489 ,קיפודג
2600 ,אילת ,231 ,קרלובי-וארי
2600 ,אילת ,550 ,קרקל
2600 ,אילת ,808 ,קשת
2600 ,אילת ,809 ,ראש השנה
2600 ,אילת ,807 ,ראש חודש
2600 ,אילת ,6552 ,רובע האבות
2600 ,אילת ,6551 ,רובע האמהות
2600 ,אילת ,6548 ,רובע הבשמים
2600 ,אילת ,6550 ,רובע המעיינות
2600 ,אילת ,6547 ,רובע הנחלים
2600 ,אילת ,6553 ,רובע העונות
2600 ,אילת ,6546 ,רובע הפירות
2600 ,אילת ,6549 ,רובע שבטי ישראל
2600 ,אילת ,106 ,רודד
2600 ,אילת ,768 ,רוזמרין
2600 ,אילת ,681 ,רות המואביה
2600 ,אילת ,261 ,רותם
2600 ,אילת ,6523 ,רמת יותם
2600 ,אילת ,431 ,רקפת
2600 ,אילת ,113 ,רתמים
2600 ,אילת ,6515 ,ש אופיר
2600 ,אילת ,6516 ,ש אטונגים
2600 ,אילת ,6517 ,ש בתי ברמן
2600 ,אילת ,6518 ,ש האשל
2600 ,אילת ,6520 ,ש סנפיר
2600 ,אילת ,6522 ,ש פטיו
2600 ,אילת ,6525 ,ש צופית
2600 ,אילת ,794 ,שבט
2600 ,אילת ,275 ,שביל בוקר
2600 ,אילת ,386 ,שביל הצבי
2600 ,אילת ,465 ,שביל השמש
2600 ,אילת ,220 ,שביל התור
2600 ,אילת ,553 ,שביל יחמורים
2600 ,אילת ,224 ,שביל יטבתה
2600 ,אילת ,219 ,שביל לוטם
2600 ,אילת ,556 ,שביל סוסים
2600 ,אילת ,226 ,שביל עברונה
2600 ,אילת ,163 ,שביל עידן
2600 ,אילת ,228 ,שביל עתק
2600 ,אילת ,545 ,שביל פעמי השלום
2600 ,אילת ,554 ,שביל פראים
2600 ,אילת ,387 ,שביל צבר
2600 ,אילת ,361 ,שביל שונית
2600 ,אילת ,555 ,שביל שועלים
2600 ,אילת ,222 ,שביל שיזף
2600 ,אילת ,168 ,שד ארגמן
2600 ,אילת ,121 ,שד התמרים
2600 ,אילת ,695 ,שד טורונטו
2600 ,אילת ,117 ,שד יעלים
2600 ,אילת ,114 ,שד עובדה
2600 ,אילת ,177 ,שד ששת הימים
2600 ,אילת ,740 ,שדה התעופה
2600 ,אילת ,172 ,שובל
2600 ,אילת ,435 ,שוהם
2600 ,אילת ,500 ,שומר
2600 ,אילת ,691 ,שופרון
2600 ,אילת ,134 ,שור
2600 ,אילת ,432 ,שושן
2600 ,אילת ,167 ,שחורת
2600 ,אילת ,178 ,שחמון
2600 ,אילת ,251 ,שחף
2600 ,אילת ,769 ,שיבולים
2600 ,אילת ,6529 ,שכ אורים
2600 ,אילת ,6530 ,שכ אמדר
2600 ,אילת ,6536 ,שכ גנים
2600 ,אילת ,6545 ,שכ גנים ב
2600 ,אילת ,6535 ,שכ הדקל
2600 ,אילת ,6544 ,שכ המלחה
2600 ,אילת ,6541 ,שכ העמים
2600 ,אילת ,6531 ,שכ יעלים
2600 ,אילת ,6538 ,שכ יפה נוף
2600 ,אילת ,6539 ,שכ מצפה ים
2600 ,אילת ,6543 ,שכ נווה מדבר
2600 ,אילת ,6537 ,שכ עידן
2600 ,אילת ,6532 ,שכ ערבה
2600 ,אילת ,6533 ,שכ צאלים
2600 ,אילת ,6540 ,שכ שחמון
2600 ,אילת ,6534 ,שכ שקמונה
2600 ,אילת ,200 ,שלדג
2600 ,אילת ,812 ,שמיני עצרת
2600 ,אילת ,771 ,שן הארי
2600 ,אילת ,566 ,שפן סלעים
2600 ,אילת ,354 ,שקמונה
2600 ,אילת ,491 ,שרביטן
2600 ,אילת ,683 ,שרה אימנו
2600 ,אילת ,256 ,שרקרק
2600 ,אילת ,492 ,תוכינון
2600 ,אילת ,770 ,תורמוס
2600 ,אילת ,433 ,תלתן
2600 ,אילת ,798 ,תמוז
2600 ,אילת ,297 ,תמר
2600 ,אילת ,513 ,תפאר
2600 ,אילת ,300 ,תפוח סדום
2600 ,אילת ,544 ,תרשיש
2600 ,אילת ,790 ,תשרי
1336 ,אירוס ,9000 ,אירוס
1336 ,אירוס ,139 ,דוכיפת
1336 ,אירוס ,129 ,הבזלת
1336 ,אירוס ,130 ,הגרניט
1336 ,אירוס ,131 ,הדולומיט
1336 ,אירוס ,126 ,החצב
1336 ,אירוס ,132 ,הכורכר
1336 ,אירוס ,117 ,הכלנית
1336 ,אירוס ,118 ,הנופר
1336 ,אירוס ,122 ,הנורית
1336 ,אירוס ,134 ,הנחליאלי
1336 ,אירוס ,125 ,הנרקיס
1336 ,אירוס ,121 ,הסביון
1336 ,אירוס ,127 ,הסחלב
1336 ,אירוס ,123 ,הסייפן
1336 ,אירוס ,135 ,העגור
1336 ,אירוס ,119 ,העירית
1336 ,אירוס ,138 ,העפרוני
1336 ,אירוס ,133 ,הפרדס
1336 ,אירוס ,136 ,הפשוש
1336 ,אירוס ,120 ,הצבעוני
1336 ,אירוס ,128 ,הרותם
1336 ,אירוס ,124 ,הרקפת
1336 ,אירוס ,137 ,השחף
1336 ,אירוס ,6001 ,כיכר יהדות בולגריה
1336 ,אירוס ,105 ,מאדים
1336 ,אירוס ,103 ,נגה
1336 ,אירוס ,114 ,נחל איילון
1336 ,אירוס ,106 ,נחל ברקן
1336 ,אירוס ,108 ,נחל גלים
1336 ,אירוס ,109 ,נחל דרגות
1336 ,אירוס ,112 ,נחל האלה
1336 ,אירוס ,113 ,נחל הבשור
1336 ,אירוס ,111 ,נחל הירדן
1336 ,אירוס ,110 ,נחל הירקון
1336 ,אירוס ,107 ,נחל לכיש
1336 ,אירוס ,116 ,נחל פארן
1336 ,אירוס ,115 ,נחל ציפורי
1336 ,אירוס ,102 ,נחל שורק
1336 ,אירוס ,104 ,שבתאי
1336 ,אירוס ,101 ,שדרות גן רווה
3762 ,איתמר ,9000 ,איתמר
37 ,איתן ,6501 ,אחוזת ענוג
37 ,איתן ,9000 ,איתן
886 ,איתנים ,9000 ,איתנים
478 ,אכסאל ,6721 ,אזור בתי הספר
478 ,אכסאל ,9000 ,אכסאל
478 ,אכסאל ,6732 ,דנאנה
478 ,אכסאל ,6713 ,הזיתים
478 ,אכסאל ,6730 ,זייתון אלשמאלי
478 ,אכסאל ,6720 ,מרכז-מדרום למסגד
478 ,אכסאל ,6722 ,מרכז-מצפון למסגד
478 ,אכסאל ,6711 ,סעד וסעיד
478 ,אכסאל ,139 ,רח 100
478 ,אכסאל ,251 ,רח 200
478 ,אכסאל ,245 ,רח 500
478 ,אכסאל ,264 ,רח 10
478 ,אכסאל ,148 ,רח 110
478 ,אכסאל ,262 ,רח 510
478 ,אכסאל ,233 ,רח 20
478 ,אכסאל ,152 ,רח 120
478 ,אכסאל ,172 ,רח 30
478 ,אכסאל ,216 ,רח 40
478 ,אכסאל ,105 ,רח 50
478 ,אכסאל ,203 ,רח 150
478 ,אכסאל ,115 ,רח 60
478 ,אכסאל ,223 ,רח 160
478 ,אכסאל ,199 ,רח 80
478 ,אכסאל ,159 ,רח 90
478 ,אכסאל ,157 ,רח 1
478 ,אכסאל ,140 ,רח 101
478 ,אכסאל ,252 ,רח 201
478 ,אכסאל ,226 ,רח 11
478 ,אכסאל ,149 ,רח 111
478 ,אכסאל ,234 ,רח 21
478 ,אכסאל ,154 ,רח 121
478 ,אכסאל ,208 ,רח 31
478 ,אכסאל ,217 ,רח 41
478 ,אכסאל ,106 ,רח 51
478 ,אכסאל ,204 ,רח 151
478 ,אכסאל ,118 ,רח 61
478 ,אכסאל ,205 ,רח 161
478 ,אכסאל ,126 ,רח 71
478 ,אכסאל ,200 ,רח 81
478 ,אכסאל ,160 ,רח 91
478 ,אכסאל ,164 ,רח 2
478 ,אכסאל ,141 ,רח 102
478 ,אכסאל ,253 ,רח 202
478 ,אכסאל ,246 ,רח 502
478 ,אכסאל ,227 ,רח 12
478 ,אכסאל ,150 ,רח 112
478 ,אכסאל ,244 ,רח 512
478 ,אכסאל ,235 ,רח 22
478 ,אכסאל ,132 ,רח 122
478 ,אכסאל ,209 ,רח 32
478 ,אכסאל ,218 ,רח 42
478 ,אכסאל ,107 ,רח 52
478 ,אכסאל ,119 ,רח 62
478 ,אכסאל ,258 ,רח 162
478 ,אכסאל ,173 ,רח 82
478 ,אכסאל ,182 ,רח 92
478 ,אכסאל ,165 ,רח 3
478 ,אכסאל ,254 ,רח 203
478 ,אכסאל ,247 ,רח 503
478 ,אכסאל ,228 ,רח 13
478 ,אכסאל ,151 ,רח 113
478 ,אכסאל ,171 ,רח 23
478 ,אכסאל ,133 ,רח 123
478 ,אכסאל ,210 ,רח 33
478 ,אכסאל ,219 ,רח 43
478 ,אכסאל ,108 ,רח 53
478 ,אכסאל ,155 ,רח 153
478 ,אכסאל ,156 ,רח 63
478 ,אכסאל ,259 ,רח 163
478 ,אכסאל ,192 ,רח 73
478 ,אכסאל ,174 ,רח 83
478 ,אכסאל ,161 ,רח 93
478 ,אכסאל ,166 ,רח 4
478 ,אכסאל ,142 ,רח 104
478 ,אכסאל ,248 ,רח 504
478 ,אכסאל ,229 ,רח 14
478 ,אכסאל ,186 ,רח 114
478 ,אכסאל ,237 ,רח 24
478 ,אכסאל ,134 ,רח 124
478 ,אכסאל ,211 ,רח 34
478 ,אכסאל ,109 ,רח 54
478 ,אכסאל ,188 ,רח 154
478 ,אכסאל ,121 ,רח 64
478 ,אכסאל ,265 ,רח 164
478 ,אכסאל ,193 ,רח 74
478 ,אכסאל ,175 ,רח 84
478 ,אכסאל ,162 ,רח 94
478 ,אכסאל ,167 ,רח 5
478 ,אכסאל ,143 ,רח 105
478 ,אכסאל ,249 ,רח 505
478 ,אכסאל ,158 ,רח 15
478 ,אכסאל ,127 ,רח 115
478 ,אכסאל ,238 ,רח 25
478 ,אכסאל ,212 ,רח 35
478 ,אכסאל ,100 ,רח 45
478 ,אכסאל ,110 ,רח 55
478 ,אכסאל ,243 ,רח 155
478 ,אכסאל ,122 ,רח 65
478 ,אכסאל ,194 ,רח 75
478 ,אכסאל ,184 ,רח 95
478 ,אכסאל ,195 ,רח 195
478 ,אכסאל ,168 ,רח 6
478 ,אכסאל ,144 ,רח 106
478 ,אכסאל ,230 ,רח 16
478 ,אכסאל ,128 ,רח 116
478 ,אכסאל ,239 ,רח 26
478 ,אכסאל ,213 ,רח 36
478 ,אכסאל ,101 ,רח 46
478 ,אכסאל ,111 ,רח 56
478 ,אכסאל ,222 ,רח 156
478 ,אכסאל ,123 ,רח 66
478 ,אכסאל ,220 ,רח 76
478 ,אכסאל ,176 ,רח 86
478 ,אכסאל ,135 ,רח 96
478 ,אכסאל ,145 ,רח 107
478 ,אכסאל ,250 ,רח 507
478 ,אכסאל ,263 ,רח 17
478 ,אכסאל ,129 ,רח 117
478 ,אכסאל ,240 ,רח 27
478 ,אכסאל ,261 ,רח 37
478 ,אכסאל ,102 ,רח 47
478 ,אכסאל ,112 ,רח 57
478 ,אכסאל ,116 ,רח 157
478 ,אכסאל ,124 ,רח 67
478 ,אכסאל ,196 ,רח 77
478 ,אכסאל ,177 ,רח 87
478 ,אכסאל ,136 ,רח 97
478 ,אכסאל ,169 ,רח 8
478 ,אכסאל ,146 ,רח 108
478 ,אכסאל ,231 ,רח 18
478 ,אכסאל ,130 ,רח 118
478 ,אכסאל ,241 ,רח 28
478 ,אכסאל ,214 ,רח 38
478 ,אכסאל ,103 ,רח 48
478 ,אכסאל ,113 ,רח 58
478 ,אכסאל ,117 ,רח 158
478 ,אכסאל ,125 ,רח 68
478 ,אכסאל ,197 ,רח 78
478 ,אכסאל ,178 ,רח 88
478 ,אכסאל ,137 ,רח 98
478 ,אכסאל ,170 ,רח 9
478 ,אכסאל ,147 ,רח 109
478 ,אכסאל ,232 ,רח 19
478 ,אכסאל ,131 ,רח 119
478 ,אכסאל ,206 ,רח 29
478 ,אכסאל ,215 ,רח 39
478 ,אכסאל ,104 ,רח 49
478 ,אכסאל ,114 ,רח 59
478 ,אכסאל ,260 ,רח 159
478 ,אכסאל ,198 ,רח 79
478 ,אכסאל ,179 ,רח 89
478 ,אכסאל ,138 ,רח 99
478 ,אכסאל ,6714 ,שכונה חדשה
478 ,אכסאל ,6710 ,שכונה מזרחית
478 ,אכסאל ,6731 ,שכונה מערבית
478 ,אכסאל ,6712 ,שכונה צפונית
1359 ,אל סייד ,9000 ,אל סייד
1339 ,אל-עזי ,9000 ,אל-עזי
1316 ,אל-עריאן ,100 ,א-נהדה
1316 ,אל-עריאן ,103 ,א-נרג'ס
1316 ,אל-עריאן ,104 ,א-סלאם
1316 ,אל-עריאן ,105 ,א-שיח'
1316 ,אל-עריאן ,101 ,אל-הודא
1316 ,אל-עריאן ,102 ,אל-וורוד
1316 ,אל-עריאן ,9000 ,אל-עריאן
4003 ,אל-רום ,9000 ,אל-רום
1145 ,אלומה ,9000 ,אלומה
1145 ,אלומה ,101 ,חזון יחזקאל
330 ,אלומות ,9000 ,אלומות
1182 ,אלון הגליל ,9000 ,אלון הגליל
1182 ,אלון הגליל ,101 ,דרך הרקפות
1182 ,אלון הגליל ,106 ,האלונים
1182 ,אלון הגליל ,105 ,הגת
1182 ,אלון הגליל ,104 ,החורש
1182 ,אלון הגליל ,108 ,החצבים
1182 ,אלון הגליל ,102 ,החקלאים
1182 ,אלון הגליל ,103 ,המוביל
1182 ,אלון הגליל ,109 ,הנחל
1182 ,אלון הגליל ,107 ,השלף
3579 ,אלון מורה ,9000 ,אלון מורה
3579 ,אלון מורה ,102 ,ארץ חמדה
3579 ,אלון מורה ,107 ,בשן
3579 ,אלון מורה ,108 ,גלעד
3579 ,אלון מורה ,110 ,דרך שמונה עליות
3579 ,אלון מורה ,106 ,חרמון
3579 ,אלון מורה ,109 ,כנסת ישראל
3579 ,אלון מורה ,105 ,מוריה
3579 ,אלון מורה ,111 ,משעול הכרכום
3579 ,אלון מורה ,112 ,משעול המור
3579 ,אלון מורה ,113 ,משעול הציפורן
3579 ,אלון מורה ,114 ,משעול הצרי
3579 ,אלון מורה ,6501 ,נווה תרצה
3579 ,אלון מורה ,101 ,נצח ישראל
3579 ,אלון מורה ,103 ,סיני
3579 ,אלון מורה ,6500 ,שכ רמי
3579 ,אלון מורה ,104 ,שניר
3604 ,אלון שבות ,116 ,אל ההר
3604 ,אלון שבות ,9000 ,אלון שבות
3604 ,אלון שבות ,120 ,גבעת החי"ש
3604 ,אלון שבות ,101 ,האגוז
3604 ,אלון שבות ,122 ,האלה
3604 ,אלון שבות ,111 ,האלון
3604 ,אלון שבות ,107 ,הארז
3604 ,אלון שבות ,109 ,הזית
3604 ,אלון שבות ,123 ,החורש
3604 ,אלון שבות ,124 ,הישיבה
3604 ,אלון שבות ,114 ,הל"ה
3604 ,אלון שבות ,121 ,הרימון
3604 ,אלון שבות ,103 ,הרקפת
3604 ,אלון שבות ,104 ,השיירות
3604 ,אלון שבות ,105 ,התאנה
3604 ,אלון שבות ,110 ,התמר
3604 ,אלון שבות ,102 ,ישיבת הר עציון
3604 ,אלון שבות ,119 ,כ"ח באייר
3604 ,אלון שבות ,118 ,מגדל עדר
3604 ,אלון שבות ,113 ,מגיני הגוש
3604 ,אלון שבות ,106 ,מעלה מיכאל
3604 ,אלון שבות ,112 ,משעול הגפן
3604 ,אלון שבות ,126 ,משעול הדקלים
3604 ,אלון שבות ,108 ,משעול הורד
3604 ,אלון שבות ,117 ,נצח ירושלים
3604 ,אלון שבות ,115 ,קבוצת אברהם
3604 ,אלון שבות ,6500 ,שכ גבעות
3604 ,אלון שבות ,6502 ,שכ גבעת הברכה
3604 ,אלון שבות ,6501 ,שכ גבעת העץ
3604 ,אלון שבות ,6503 ,שכ משתלות
429 ,אלוני אבא ,9000 ,אלוני אבא
429 ,אלוני אבא ,106 ,האגוז
429 ,אלוני אבא ,117 ,האלה
429 ,אלוני אבא ,101 ,האלונים
429 ,אלוני אבא ,104 ,הגפן
429 ,אלוני אבא ,113 ,הדולב
429 ,אלוני אבא ,111 ,ההדרים
429 ,אלוני אבא ,112 ,הזית
429 ,אלוני אבא ,119 ,החוה
429 ,אלוני אבא ,115 ,החורש
429 ,אלוני אבא ,109 ,החרוב
429 ,אלוני אבא ,114 ,הכלניות
429 ,אלוני אבא ,108 ,הכרמים
429 ,אלוני אבא ,105 ,הסמטה הירוקה
429 ,אלוני אבא ,120 ,העמל
429 ,אלוני אבא ,116 ,העמק
429 ,אלוני אבא ,107 ,הרמון
429 ,אלוני אבא ,103 ,הרקפות
429 ,אלוני אבא ,118 ,השקד
429 ,אלוני אבא ,110 ,סמטת הדקלים
429 ,אלוני אבא ,121 ,סמטת הצבר
429 ,אלוני אבא ,102 ,שדרת הברושים
4017 ,אלוני הבשן ,9000 ,אלוני הבשן
868 ,אלוני יצחק ,9000 ,אלוני יצחק
868 ,אלוני יצחק ,6500 ,כפר הנוער
285 ,אלונים ,9000 ,אלונים
4002 ,אלי-עד ,9000 ,אלי-עד
1365 ,אליאב ,9000 ,אליאב
1365 ,אליאב ,102 ,אשחר
1365 ,אליאב ,103 ,האטד
1365 ,אליאב ,104 ,האשל
1365 ,אליאב ,105 ,הגפן
1365 ,אליאב ,101 ,הזית
1365 ,אליאב ,107 ,הלוטם
1365 ,אליאב ,106 ,הליבנה
1365 ,אליאב ,108 ,הצבר
1365 ,אליאב ,110 ,השיטה
1365 ,אליאב ,109 ,השקד
1365 ,אליאב ,100 ,חרוב
41 ,אליכין ,9000 ,אליכין
41 ,אליכין ,107 ,בשמת
41 ,אליכין ,6500 ,גבעת הבריכה
41 ,אליכין ,124 ,האיה
41 ,אליכין ,108 ,האירוס
41 ,אליכין ,121 ,האנפה
41 ,אליכין ,153 ,הגפן
41 ,אליכין ,120 ,הדרור
41 ,אליכין ,123 ,הורד
41 ,אליכין ,118 ,הזמיר
41 ,אליכין ,114 ,החוגלה
41 ,אליכין ,115 ,החוחית
41 ,אליכין ,113 ,החופית
41 ,אליכין ,112 ,החצב
41 ,אליכין ,111 ,החרצית
41 ,אליכין ,154 ,היסמין
41 ,אליכין ,110 ,היסעור
41 ,אליכין ,125 ,הכלנית
41 ,אליכין ,137 ,הלילך
41 ,אליכין ,109 ,הנורית
41 ,אליכין ,126 ,הנחליאלי
41 ,אליכין ,155 ,הנרקיס
41 ,אליכין ,127 ,הסביון
41 ,אליכין ,128 ,הסחלב
41 ,אליכין ,129 ,הסייפן
41 ,אליכין ,122 ,הסלעית
41 ,אליכין ,119 ,הסנונית
41 ,אליכין ,130 ,העפרוני
41 ,אליכין ,117 ,הפשוש
41 ,אליכין ,131 ,הצבעוני
41 ,אליכין ,132 ,הצופית
41 ,אליכין ,133 ,הצפורן
41 ,אליכין ,152 ,הרימון
41 ,אליכין ,156 ,הרקפת
41 ,אליכין ,101 ,הרש"ש
41 ,אליכין ,134 ,השחף
41 ,אליכין ,116 ,השלדג
41 ,אליכין ,151 ,התאנה
41 ,אליכין ,135 ,התמר
41 ,אליכין ,105 ,חבצלת השרון
41 ,אליכין ,103 ,כנפי נשרים
41 ,אליכין ,102 ,מרבד הקסמים
41 ,אליכין ,138 ,סיגלית
41 ,אליכין ,106 ,רמב"ם
41 ,אליכין ,104 ,שבזי
41 ,אליכין ,6501 ,שכ האלונים
41 ,אליכין ,136 ,שקד
1248 ,אליפז ,9000 ,אליפז
730 ,אליפלט ,9000 ,אליפלט
730 ,אליפלט ,112 ,האורן
730 ,אליפלט ,106 ,האתרוג
730 ,אליפלט ,105 ,הברוש
730 ,אליפלט ,101 ,הגפן
730 ,אליפלט ,108 ,הדולב
730 ,אליפלט ,110 ,ההדס
730 ,אליפלט ,102 ,הזית
730 ,אליפלט ,109 ,הערבה
730 ,אליפלט ,107 ,הרימון
730 ,אליפלט ,104 ,השיטה
730 ,אליפלט ,103 ,התאנה
730 ,אליפלט ,111 ,התמר
682 ,אליקים ,9000 ,אליקים
204 ,אלישיב ,9000 ,אלישיב
204 ,אלישיב ,102 ,הבאר
204 ,אלישיב ,106 ,הגבעה
204 ,אלישיב ,110 ,הגורן
204 ,אלישיב ,103 ,הגפן
204 ,אלישיב ,105 ,הזית
204 ,אלישיב ,112 ,החיטה
204 ,אלישיב ,111 ,החרוב
204 ,אלישיב ,113 ,הכלנית
204 ,אלישיב ,107 ,הראשונים
204 ,אלישיב ,108 ,הרימון
204 ,אלישיב ,109 ,השעורה
204 ,אלישיב ,104 ,התאנה
204 ,אלישיב ,101 ,התמר
841 ,אלישמע ,9000 ,אלישמע
841 ,אלישמע ,105 ,הדקל
841 ,אלישמע ,101 ,הורדים
841 ,אלישמע ,104 ,הנרקיס
841 ,אלישמע ,6500 ,הרחבה
841 ,אלישמע ,103 ,הרקפת
841 ,אלישמע ,102 ,כלנית
841 ,אלישמע ,114 ,סמ אורן
841 ,אלישמע ,119 ,סמ אלה
841 ,אלישמע ,117 ,סמ אלון
841 ,אלישמע ,118 ,סמ אקליפטוס
841 ,אלישמע ,113 ,סמ ברוש
841 ,אלישמע ,110 ,סמ הפקאן
841 ,אלישמע ,115 ,סמ זית
841 ,אלישמע ,112 ,סמ חרוב
841 ,אלישמע ,111 ,סמ צאלון
841 ,אלישמע ,116 ,סמ תמר
1125 ,אלמגור ,9000 ,אלמגור
3556 ,אלמוג ,9000 ,אלמוג
1309 ,אלעד ,123 ,אבטליון
1309 ,אלעד ,220 ,אבן גבירול
1309 ,אלעד ,211 ,אבן עזרא
1309 ,אלעד ,9000 ,אלעד
1309 ,אלעד ,210 ,בעלי התוספות
1309 ,אלעד ,223 ,הב"ח
1309 ,אלעד ,126 ,הזמיר
1309 ,אלעד ,113 ,הלל
1309 ,אלעד ,110 ,המאירי
1309 ,אלעד ,224 ,המהרש"ל
1309 ,אלעד ,225 ,הר"ן
1309 ,אלעד ,104 ,הרא"ש
1309 ,אלעד ,218 ,הרי"ף
1309 ,אלעד ,106 ,התאנה
1309 ,אלעד ,127 ,התור
1309 ,אלעד ,103 ,חוני המעגל
1309 ,אלעד ,109 ,סעדיה גאון
1309 ,אלעד ,217 ,עובדיה מברטנורה
1309 ,אלעד ,219 ,ראב"ד
1309 ,אלעד ,108 ,רבי חייא
1309 ,אלעד ,124 ,רבי טרפון
1309 ,אלעד ,213 ,רבי יהודה הלוי
1309 ,אלעד ,105 ,רבי יהודה הנשיא
1309 ,אלעד ,221 ,רבי יהושע
1309 ,אלעד ,203 ,רבי יונתן בן עוזיאל
1309 ,אלעד ,204 ,רבי יוסי בן קיסמא
1309 ,אלעד ,107 ,רבי יוסף קארו
1309 ,אלעד ,201 ,רבי מאיר
1309 ,אלעד ,116 ,רבי עקיבא
1309 ,אלעד ,202 ,רבי פנחס בן יאיר
1309 ,אלעד ,114 ,רבי שמואל הנגיד
1309 ,אלעד ,215 ,רבינו בחיי
1309 ,אלעד ,214 ,רבינו גרשום
1309 ,אלעד ,212 ,רבינו חננאל
1309 ,אלעד ,222 ,רבן גמליאל
1309 ,אלעד ,111 ,רבן יוחנן בן זכאי
1309 ,אלעד ,101 ,רבנו ניסים גאון
1309 ,אלעד ,102 ,רבנו תם
1309 ,אלעד ,115 ,רד"ק
1309 ,אלעד ,6509 ,רובע A
1309 ,אלעד ,6505 ,רובע B
1309 ,אלעד ,6508 ,רובע C
1309 ,אלעד ,6506 ,רובע D
1309 ,אלעד ,6501 ,רובע E
1309 ,אלעד ,6503 ,רובע F
1309 ,אלעד ,6504 ,רובע G
1309 ,אלעד ,6502 ,רובע H
1309 ,אלעד ,122 ,ריטב"א
1309 ,אלעד ,226 ,רמ"ה
1309 ,אלעד ,209 ,רמב"ם
1309 ,אלעד ,207 ,רמב"ן
1309 ,אלעד ,208 ,רש"י
1309 ,אלעד ,206 ,רשב"י
1309 ,אלעד ,121 ,רשב"ם
1309 ,אלעד ,6500 ,שכ מרומי אלעד
1309 ,אלעד ,112 ,שמאי
1309 ,אלעד ,216 ,שמעון בן שטח
1309 ,אלעד ,205 ,שמעון הצדיק
1309 ,אלעד ,125 ,שמעיה
3618 ,אלעזר ,9000 ,אלעזר
3618 ,אלעזר ,113 ,בית זכריה
3618 ,אלעזר ,112 ,בית צור
3618 ,אלעזר ,101 ,גופנה
3618 ,אלעזר ,114 ,גוש קטיף
3618 ,אלעזר ,115 ,גן אור
3618 ,אלעזר ,117 ,גני טל
3618 ,אלעזר ,120 ,דרך נתיב האבות
3618 ,אלעזר ,110 ,חשמונאים
3618 ,אלעזר ,102 ,יהודה
3618 ,אלעזר ,103 ,יהודית
3618 ,אלעזר ,104 ,יהונתן
3618 ,אלעזר ,105 ,יוחנן
3618 ,אלעזר ,111 ,מודיעין
3618 ,אלעזר ,109 ,מעלה בית חורון
3618 ,אלעזר ,106 ,מתתיהו
3618 ,אלעזר ,116 ,נווה דקלים
3618 ,אלעזר ,119 ,ניסנית
3618 ,אלעזר ,121 ,נצר חזני
3618 ,אלעזר ,118 ,נצרים
3618 ,אלעזר ,6500 ,שכ נתיב האבות
3618 ,אלעזר ,107 ,שלומציון
3618 ,אלעזר ,108 ,שמעון
3750 ,אלפי מנשה ,106 ,אדיר
3750 ,אלפי מנשה ,121 ,אודם
3750 ,אלפי מנשה ,6503 ,אזור תעשייה
3750 ,אלפי מנשה ,220 ,אילון
3750 ,אלפי מנשה ,230 ,אירוס
3750 ,אלפי מנשה ,9000 ,אלפי מנשה
3750 ,אלפי מנשה ,212 ,אמנון ותמר
3750 ,אלפי מנשה ,108 ,ארבל
3750 ,אלפי מנשה ,221 ,בשור
3750 ,אלפי מנשה ,6504 ,גבעת טל
3750 ,אלפי מנשה ,134 ,גולן
3750 ,אלפי מנשה ,109 ,גלבוע
3750 ,אלפי מנשה ,133 ,גליל
3750 ,אלפי מנשה ,102 ,גלעד
3750 ,אלפי מנשה ,217 ,גפן
3750 ,אלפי מנשה ,123 ,גרניט
3750 ,אלפי מנשה ,116 ,דגנית
3750 ,אלפי מנשה ,155 ,דוכיפת
3750 ,אלפי מנשה ,213 ,דם המכבים
3750 ,אלפי מנשה ,222 ,דן
3750 ,אלפי מנשה ,159 ,דרור
3750 ,אלפי מנשה ,119 ,דרך חסדאי אליעזר
3750 ,אלפי מנשה ,202 ,הזית
3750 ,אלפי מנשה ,201 ,החרוב
3750 ,אלפי מנשה ,132 ,העמק
3750 ,אלפי מנשה ,110 ,הרדוף
3750 ,אלפי מנשה ,138 ,השפלה
3750 ,אלפי מנשה ,218 ,התאנה
3750 ,אלפי מנשה ,231 ,חבצלת
3750 ,אלפי מנשה ,151 ,חוחית
3750 ,אלפי מנשה ,160 ,חלוצי התעשיה
3750 ,אלפי מנשה ,114 ,חרמון
3750 ,אלפי מנשה ,204 ,חרצית
3750 ,אלפי מנשה ,122 ,טופז
3750 ,אלפי מנשה ,135 ,יהודה
3750 ,אלפי מנשה ,214 ,יסמין
3750 ,אלפי מנשה ,224 ,ירדן
3750 ,אלפי מנשה ,208 ,כלנית
3750 ,אלפי מנשה ,113 ,כנען
3750 ,אלפי מנשה ,131 ,כנרת
3750 ,אלפי מנשה ,104 ,כרכום
3750 ,אלפי מנשה ,111 ,כרמל
3750 ,אלפי מנשה ,206 ,לבנה
3750 ,אלפי מנשה ,219 ,לוטם
3750 ,אלפי מנשה ,225 ,לכיש
3750 ,אלפי מנשה ,112 ,מירון
3750 ,אלפי מנשה ,117 ,מנור
3750 ,אלפי מנשה ,207 ,מרגנית
3750 ,אלפי מנשה ,211 ,משעול סייפן
3750 ,אלפי מנשה ,137 ,נגב
3750 ,אלפי מנשה ,215 ,נרקיס
3750 ,אלפי מנשה ,205 ,סביון
3750 ,אלפי מנשה ,210 ,סייפן
3750 ,אלפי מנשה ,153 ,סלעית
3750 ,אלפי מנשה ,118 ,סמ תבור
3750 ,אלפי מנשה ,158 ,סנונית
3750 ,אלפי מנשה ,154 ,עפרוני
3750 ,אלפי מנשה ,101 ,עצמון
3750 ,אלפי מנשה ,130 ,ערבה
3750 ,אלפי מנשה ,226 ,פולג
3750 ,אלפי מנשה ,156 ,פשוש
3750 ,אלפי מנשה ,203 ,צבעוני
3750 ,אלפי מנשה ,157 ,צופית
3750 ,אלפי מנשה ,227 ,קדרון
3750 ,אלפי מנשה ,115 ,רביד
3750 ,אלפי מנשה ,216 ,רותם
3750 ,אלפי מנשה ,209 ,רקפת
3750 ,אלפי מנשה ,105 ,שגיא
3750 ,אלפי מנשה ,223 ,שובל
3750 ,אלפי מנשה ,136 ,שומרון
3750 ,אלפי מנשה ,228 ,שורק
3750 ,אלפי מנשה ,6501 ,שכ יובלים
3750 ,אלפי מנשה ,6500 ,שכ כפיר יוסף
3750 ,אלפי מנשה ,6502 ,שכ נופיה
3750 ,אלפי מנשה ,152 ,שלדג
3750 ,אלפי מנשה ,229 ,שניר
3750 ,אלפי מנשה ,107 ,תבור
603 ,אלקוש ,9000 ,אלקוש
603 ,אלקוש ,112 ,דרך הבציר
603 ,אלקוש ,109 ,דרך הוואדי
603 ,אלקוש ,108 ,דרך החריש
603 ,אלקוש ,113 ,דרך המסיק
603 ,אלקוש ,106 ,דרך הרגבים
603 ,אלקוש ,101 ,דרך התאנה
603 ,אלקוש ,107 ,דרך התלמים
603 ,אלקוש ,111 ,האלון
603 ,אלקוש ,103 ,הר אדיר
603 ,אלקוש ,104 ,הר חירם
603 ,אלקוש ,102 ,הר מתת
603 ,אלקוש ,105 ,הרימון
603 ,אלקוש ,110 ,השקד
3560 ,אלקנה ,139 ,אבקת רוכל
3560 ,אלקנה ,120 ,אדני פז
3560 ,אלקנה ,121 ,אהלי קידר
3560 ,אלקנה ,119 ,איבי הנחל
3560 ,אלקנה ,149 ,אילות השדה
3560 ,אלקנה ,170 ,אלף המגן
3560 ,אלקנה ,9000 ,אלקנה
3560 ,אלקנה ,125 ,אעלה בתמר
3560 ,אלקנה ,111 ,אפיקי מים
3560 ,אלקנה ,165 ,ארזים
3560 ,אלקנה ,118 ,אשכולות הגפן
3560 ,אלקנה ,154 ,בנות ירושלים
3560 ,אלקנה ,140 ,גבורי ישראל
3560 ,אלקנה ,117 ,גבעת הלבונה
3560 ,אלקנה ,107 ,גינת אגוז
3560 ,אלקנה ,167 ,הכרם
3560 ,אלקנה ,146 ,הר גלעד
3560 ,אלקנה ,147 ,הר המור
3560 ,אלקנה ,116 ,הרי בשמים
3560 ,אלקנה ,105 ,חבצלת השרון
3560 ,אלקנה ,112 ,חגוי הסלע
3560 ,אלקנה ,115 ,חוט השני
3560 ,אלקנה ,131 ,חרמון
3560 ,אלקנה ,126 ,טורי זהב
3560 ,אלקנה ,163 ,כרכום
3560 ,אלקנה ,148 ,כרמל
3560 ,אלקנה ,101 ,מגדל דוד
3560 ,אלקנה ,129 ,מגדל הלבנון
3560 ,אלקנה ,6503 ,מגן דן
3560 ,אלקנה ,145 ,מחניים
3560 ,אלקנה ,161 ,מים חיים
3560 ,אלקנה ,6501 ,מכללת אורות
3560 ,אלקנה ,127 ,מעין גנים
3560 ,אלקנה ,155 ,מעלה ארגמן
3560 ,אלקנה ,128 ,משכנות הרועים
3560 ,אלקנה ,151 ,משעול ספיר
3560 ,אלקנה ,152 ,משעול תרשיש
3560 ,אלקנה ,166 ,ניצנים
3560 ,אלקנה ,141 ,סמדר
3560 ,אלקנה ,160 ,עורי צפון
3560 ,אלקנה ,144 ,עין גדי
3560 ,אלקנה ,130 ,עמודי שיש
3560 ,אלקנה ,113 ,עקבי הצאן
3560 ,אלקנה ,106 ,ערוגות הבושם
3560 ,אלקנה ,124 ,עת הזמיר
3560 ,אלקנה ,108 ,פלח הרימון
3560 ,אלקנה ,164 ,פרדס רימונים
3560 ,אלקנה ,109 ,פרי מגדים
3560 ,אלקנה ,110 ,צרור המור
3560 ,אלקנה ,104 ,קול התור
3560 ,אלקנה ,162 ,קינמון
3560 ,אלקנה ,6500 ,קרית חינוך
3560 ,אלקנה ,114 ,רשפי אש
3560 ,אלקנה ,150 ,שדרות שלמה המלך
3560 ,אלקנה ,123 ,שומרי החומות
3560 ,אלקנה ,168 ,שושנים
3560 ,אלקנה ,102 ,שושנת העמקים
3560 ,אלקנה ,103 ,שיר השירים
3560 ,אלקנה ,122 ,שלטי גבורים
3560 ,אלקנה ,132 ,שניר
3560 ,אלקנה ,143 ,תאנה
3560 ,אלקנה ,153 ,תלפיות
3560 ,אלקנה ,142 ,תפוח
772 ,אמונים ,9000 ,אמונים
772 ,אמונים ,106 ,הדס
772 ,אמונים ,102 ,הדקל
772 ,אמונים ,101 ,הזית
772 ,אמונים ,103 ,הרימון
772 ,אמונים ,105 ,השקד
772 ,אמונים ,104 ,התאנה
1064 ,אמירים ,9000 ,אמירים
1253 ,אמנון ,9000 ,אמנון
1253 ,אמנון ,106 ,האלה
1253 ,אמנון ,104 ,האלון
1253 ,אמנון ,108 ,הדקל
1253 ,אמנון ,103 ,הזית
1253 ,אמנון ,110 ,הפיקוס
1253 ,אמנון ,101 ,הרימון
1253 ,אמנון ,107 ,השיטה
1253 ,אמנון ,109 ,השיקמה
1253 ,אמנון ,105 ,השקד
1253 ,אמנון ,102 ,התאנה
23 ,אמציה ,9000 ,אמציה
4012 ,אניעם ,9000 ,אניעם
960 ,אסד (שבט) ,9000 ,אסד (שבט)
3754 ,אספר ,101 ,אמרי מרדכי
3754 ,אספר ,9000 ,אספר
3754 ,אספר ,106 ,נחל דוד
3754 ,אספר ,104 ,נחל דרגה
3754 ,אספר ,107 ,נחל עמוס
3754 ,אספר ,102 ,נחל ערוגות
3754 ,אספר ,105 ,נחל קדם
3754 ,אספר ,103 ,נחל תקוע
3754 ,אספר ,6500 ,שכ פני קדם
529 ,אעבלין ,6712 ,אבו ח'רביש
529 ,אעבלין ,6722 ,אבו נח'לה
529 ,אעבלין ,6730 ,אל רג'ום
529 ,אעבלין ,6724 ,אלביאדר
529 ,אעבלין ,6710 ,אלג'בל
529 ,אעבלין ,6732 ,אלג'למה
529 ,אעבלין ,6737 ,אלח'לה
529 ,אעבלין ,6721 ,אלכרום
529 ,אעבלין ,6720 ,אלפס
529 ,אעבלין ,6716 ,אלשמאלי
529 ,אעבלין ,6733 ,אם אלשתויה
529 ,אעבלין ,9000 ,אעבלין
529 ,אעבלין ,6735 ,בח'ית
529 ,אעבלין ,6731 ,בין אלג'לאים
529 ,אעבלין ,6711 ,בלישה
529 ,אעבלין ,6723 ,גרעין הכפר
529 ,אעבלין ,6736 ,ואדי אלסהילה
529 ,אעבלין ,6734 ,מקתול
529 ,אעבלין ,6715 ,סירת אלעג'אל
529 ,אעבלין ,6738 ,עין אלתינה
529 ,אעבלין ,6713 ,ראס נעמה
529 ,אעבלין ,6714 ,שכונת מגרש כדורגל
963 ,אעצם (שבט) ,9000 ,אעצם (שבט)
959 ,אפיניש (שבט) ,9000 ,אפיניש (שבט)
4301 ,אפיק ,9000 ,אפיק
176 ,אפיקים ,9000 ,אפיקים
313 ,אפק ,9000 ,אפק
3650 ,אפרת ,115 ,אלה
3650 ,אפרת ,9000 ,אפרת
3650 ,אפרת ,168 ,אריאל
3650 ,אפרת ,134 ,בועז
3650 ,אפרת ,170 ,בית הבחירה
3650 ,אפרת ,197 ,בת-שבע
3650 ,אפרת ,6503 ,גבעת הזית
3650 ,אפרת ,167 ,דביר
3650 ,אפרת ,162 ,האלומה
3650 ,אפרת ,132 ,הגואל
3650 ,אפרת ,125 ,הגורן
3650 ,אפרת ,116 ,הגפן
3650 ,אפרת ,169 ,הדבש
3650 ,אפרת ,163 ,הדגן
3650 ,אפרת ,123 ,הדקל
3650 ,אפרת ,159 ,הזורעים
3650 ,אפרת ,164 ,החריש
3650 ,אפרת ,117 ,הכרמים
3650 ,אפרת ,137 ,הל"ה
3650 ,אפרת ,152 ,המנורה
3650 ,אפרת ,124 ,המעיין
3650 ,אפרת ,160 ,העומר
3650 ,אפרת ,156 ,הציפורן
3650 ,אפרת ,161 ,הקציר
3650 ,אפרת ,126 ,השיירות
3650 ,אפרת ,120 ,השקמה
3650 ,אפרת ,118 ,התאנה
3650 ,אפרת ,149 ,זית שמן
3650 ,אפרת ,139 ,זכריה
3650 ,אפרת ,146 ,זרובבל
3650 ,אפרת ,143 ,חגי
3650 ,אפרת ,158 ,חזקיהו המלך
3650 ,אפרת ,151 ,חנה
3650 ,אפרת ,199 ,ידידיה
3650 ,אפרת ,148 ,יהודה המכבי
3650 ,אפרת ,147 ,יונתן החשמונאי
3650 ,אפרת ,172 ,כורש
3650 ,אפרת ,102 ,מבוא אלון
3650 ,אפרת ,104 ,מבוא הדס
3650 ,אפרת ,122 ,מבוא הדר
3650 ,אפרת ,182 ,מבוא ההר הטוב
3650 ,אפרת ,103 ,מבוא חרוב
3650 ,אפרת ,178 ,מבוא יצהר
3650 ,אפרת ,183 ,מבוא ניקנור
3650 ,אפרת ,105 ,מבוא ערבה
3650 ,אפרת ,142 ,מגדל עדר
3650 ,אפרת ,181 ,מוריה
3650 ,אפרת ,136 ,מורית
3650 ,אפרת ,204 ,מיכל
3650 ,אפרת ,192 ,מעלה אביב
3650 ,אפרת ,108 ,מעלה אורן
3650 ,אפרת ,109 ,מעלה ברוש
3650 ,אפרת ,190 ,מעלה גבעול
3650 ,אפרת ,195 ,מעלה הביכורים
3650 ,אפרת ,110 ,מעלה הזית
3650 ,אפרת ,194 ,מעלה הלקט
3650 ,אפרת ,188 ,מעלה המגל
3650 ,אפרת ,189 ,מעלה המורג
3650 ,אפרת ,193 ,מעלה המנחה
3650 ,אפרת ,177 ,מעלה הסלע
3650 ,אפרת ,171 ,מעלה השקד
3650 ,אפרת ,187 ,מעלה התלם
3650 ,אפרת ,111 ,מעלה יערה
3650 ,אפרת ,127 ,מעלה כפר עציון
3650 ,אפרת ,191 ,מעלה כרמל
3650 ,אפרת ,114 ,מעלה לוטם
3650 ,אפרת ,131 ,מעלה משואות יצחק
3650 ,אפרת ,141 ,מעלה עין צורים
3650 ,אפרת ,112 ,מעלה קטלב
3650 ,אפרת ,138 ,מעלה רבדים
3650 ,אפרת ,121 ,מעלה שיבולת
3650 ,אפרת ,113 ,מעלה תדהר
3650 ,אפרת ,202 ,מרים
3650 ,אפרת ,153 ,מתתיהו הכהן
3650 ,אפרת ,175 ,נחל אשכול
3650 ,אפרת ,145 ,נחמיה
3650 ,אפרת ,203 ,נחשון
3650 ,אפרת ,155 ,נטף
3650 ,אפרת ,133 ,נעמי
3650 ,אפרת ,128 ,נצח ירושלים
3650 ,אפרת ,129 ,נצר ישי
3650 ,אפרת ,176 ,סמדר
3650 ,אפרת ,157 ,עוזיהו המלך
3650 ,אפרת ,144 ,עזרא
3650 ,אפרת ,205 ,עמינדב
3650 ,אפרת ,186 ,עץ החיים
3650 ,אפרת ,154 ,פיטום הקטורת
3650 ,אפרת ,201 ,קהלת
3650 ,אפרת ,6500 ,קרית המבתר
3650 ,אפרת ,130 ,רות
3650 ,אפרת ,150 ,רחל אמנו
3650 ,אפרת ,106 ,רימון
3650 ,אפרת ,173 ,שאלתיאל
3650 ,אפרת ,180 ,שבות ישראל
3650 ,אפרת ,135 ,שד המלך דוד
3650 ,אפרת ,185 ,שד המלך שלמה
3650 ,אפרת ,140 ,שיבת ציון
3650 ,אפרת ,198 ,שיר השירים
3650 ,אפרת ,6501 ,שכ גבעת רימון
3650 ,אפרת ,6505 ,שכ דקל
3650 ,אפרת ,6502 ,שכ הגפן
3650 ,אפרת ,6507 ,שכ הדגן
3650 ,אפרת ,6504 ,שכ תאנה
3650 ,אפרת ,6506 ,שכ תמר
3650 ,אפרת ,184 ,שלם
3650 ,אפרת ,119 ,תירוש
3650 ,אפרת ,166 ,תלפיות
3650 ,אפרת ,107 ,תמר
701 ,ארבל ,9000 ,ארבל
701 ,ארבל ,114 ,האלה
701 ,ארבל ,111 ,האלונים
701 ,ארבל ,107 ,הגפן
701 ,ארבל ,102 ,הזית
701 ,ארבל ,110 ,החרוב
701 ,ארבל ,103 ,המייסדים
701 ,ארבל ,105 ,הנוטעים
701 ,ארבל ,104 ,הראשונים
701 ,ארבל ,115 ,הרימון
701 ,ארבל ,112 ,השיזף
701 ,ארבל ,106 ,השיטה
701 ,ארבל ,108 ,השקד
701 ,ארבל ,113 ,התאנה
701 ,ארבל ,6002 ,כיכר האילנות
701 ,ארבל ,6001 ,כיכר המייסדים
701 ,ארבל ,101 ,מורד הצבר
701 ,ארבל ,109 ,שדרת הברושים
3598 ,ארגמן ,9000 ,ארגמן
714 ,ארז ,9000 ,ארז
3570 ,אריאל ,106 ,אבנר
3570 ,אריאל ,153 ,אדום
3570 ,אריאל ,6503 ,אזור תעשיה מערב
3570 ,אריאל ,126 ,אילות
3570 ,אריאל ,173 ,אירוס
3570 ,אריאל ,258 ,אלמוג
3570 ,אריאל ,9000 ,אריאל
3570 ,אריאל ,251 ,בזלת
3570 ,אריאל ,142 ,בית אל
3570 ,אריאל ,133 ,בית לחם
3570 ,אריאל ,113 ,בקעת הירדן
3570 ,אריאל ,204 ,בראון אורי
3570 ,אריאל ,110 ,ברק
3570 ,אריאל ,2596 ,ברקת
3570 ,אריאל ,111 ,גדעון
3570 ,אריאל ,145 ,גילה
3570 ,אריאל ,306 ,גנים
3570 ,אריאל ,109 ,דבורה
3570 ,אריאל ,312 ,דן
3570 ,אריאל ,121 ,דרך אפרתה
3570 ,אריאל ,202 ,דרך הנחשונים
3570 ,אריאל ,102 ,דרך הציונות
3570 ,אריאל ,303 ,דרך עפרון
3570 ,אריאל ,150 ,דרך רמת הגולן
3570 ,אריאל ,208 ,האלון
3570 ,אריאל ,205 ,האצ"ל
3570 ,אריאל ,209 ,הארז
3570 ,אריאל ,317 ,הבנאי
3570 ,אריאל ,138 ,הבשן
3570 ,אריאל ,136 ,הגלבוע
3570 ,אריאל ,112 ,הגליל
3570 ,אריאל ,137 ,הגלעד
3570 ,אריאל ,201 ,הדקל
3570 ,אריאל ,206 ,ההגנה
3570 ,אריאל ,307 ,הורדים
3570 ,אריאל ,130 ,החרוב
3570 ,אריאל ,311 ,החרמון
3570 ,אריאל ,309 ,הירדן
3570 ,אריאל ,314 ,המלאכה
3570 ,אריאל ,316 ,המסגר
3570 ,אריאל ,213 ,המציל היהודי
3570 ,אריאל ,114 ,הנגב
3570 ,אריאל ,304 ,הסביונים
3570 ,אריאל ,313 ,העבודה
3570 ,אריאל ,315 ,העמל
3570 ,אריאל ,210 ,העצמאות
3570 ,אריאל ,117 ,הערבה
3570 ,אריאל ,203 ,הפסגה
3570 ,אריאל ,105 ,הקוממיות
3570 ,אריאל ,148 ,הרדוף
3570 ,אריאל ,308 ,השומרון
3570 ,אריאל ,131 ,השקד
3570 ,אריאל ,207 ,השקמה
3570 ,אריאל ,135 ,התבור
3570 ,אריאל ,132 ,חברון
3570 ,אריאל ,170 ,חופית
3570 ,אריאל ,250 ,חושן
3570 ,אריאל ,257 ,טופז
3570 ,אריאל ,212 ,יגאל כהן-אורגד
3570 ,אריאל ,302 ,יהודה
3570 ,אריאל ,253 ,יהלום
3570 ,אריאל ,115 ,יואב
3570 ,אריאל ,152 ,יודפת
3570 ,אריאל ,119 ,ימית
3570 ,אריאל ,172 ,יסמין
3570 ,אריאל ,146 ,יפה נוף
3570 ,אריאל ,116 ,יפתח
3570 ,אריאל ,107 ,כביש 101
3570 ,אריאל ,160 ,כביש 31
3570 ,אריאל ,161 ,כביש 32
3570 ,אריאל ,162 ,כביש 33
3570 ,אריאל ,164 ,כביש 34
3570 ,אריאל ,165 ,כביש 35
3570 ,אריאל ,166 ,כביש 36
3570 ,אריאל ,147 ,כלילת יופי
3570 ,אריאל ,305 ,כלניות
3570 ,אריאל ,175 ,לילך
3570 ,אריאל ,252 ,לשם
3570 ,אריאל ,156 ,מבוא חמה
3570 ,אריאל ,103 ,מבצע דני
3570 ,אריאל ,125 ,מבצע הראל
3570 ,אריאל ,101 ,מבצע חירם
3570 ,אריאל ,104 ,מבצע עובדה
3570 ,אריאל ,122 ,מבצע קדש
3570 ,אריאל ,154 ,מואב
3570 ,אריאל ,143 ,מוריה
3570 ,אריאל ,7001 ,מכללת אריאל
3570 ,אריאל ,211 ,מעלה הגבעה
3570 ,אריאל ,124 ,מפרץ אילת
3570 ,אריאל ,123 ,מפרץ שלמה
3570 ,אריאל ,139 ,מצדה
3570 ,אריאל ,320 ,משעול א
3570 ,אריאל ,321 ,משעול ב
3570 ,אריאל ,330 ,משעול ברכה
3570 ,אריאל ,322 ,משעול ג
3570 ,אריאל ,323 ,משעול ד
3570 ,אריאל ,324 ,משעול ה
3570 ,אריאל ,301 ,משעול הזית
3570 ,אריאל ,325 ,משעול ו
3570 ,אריאל ,326 ,משעול ז
3570 ,אריאל ,328 ,משעול רימון
3570 ,אריאל ,329 ,משעול תאנה
3570 ,אריאל ,327 ,משעול תמר
3570 ,אריאל ,141 ,נוה שאנן
3570 ,אריאל ,174 ,נורית
3570 ,אריאל ,171 ,סחלב
3570 ,אריאל ,259 ,ספיר
3570 ,אריאל ,157 ,עיר דוד
3570 ,אריאל ,144 ,עיר היונה
3570 ,אריאל ,260 ,ענבר
3570 ,אריאל ,155 ,עצמון
3570 ,אריאל ,108 ,צה"ל
3570 ,אריאל ,140 ,ציון
3570 ,אריאל ,6502 ,קרית המדע
3570 ,אריאל ,120 ,שד ירושלים
3570 ,אריאל ,254 ,שוהם
3570 ,אריאל ,6510 ,שכ ארבל
3570 ,אריאל ,6511 ,שכ המור
3570 ,אריאל ,6505 ,שכ הפרחים
3570 ,אריאל ,6506 ,שכ הראשונים
3570 ,אריאל ,6507 ,שכ יובלים
3570 ,אריאל ,6509 ,שכ עצמה
3570 ,אריאל ,6501 ,שכ קרוונים
3570 ,אריאל ,6508 ,שכ רמים
3570 ,אריאל ,6512 ,שכ שגיא
3570 ,אריאל ,255 ,שנהב
3570 ,אריאל ,310 ,שניר
3570 ,אריאל ,134 ,שער הגיא
3570 ,אריאל ,118 ,ששת הימים
3570 ,אריאל ,149 ,תאשור
3570 ,אריאל ,151 ,תדהר
1324 ,ארסוף ,101 ,אלמוג
1324 ,ארסוף ,9000 ,ארסוף
1324 ,ארסוף ,104 ,הגלים
1324 ,ארסוף ,105 ,מבוא הים
1324 ,ארסוף ,102 ,מול הים
1324 ,ארסוף ,103 ,נחשול
1324 ,ארסוף ,106 ,צדף ים
71 ,אשבול ,9000 ,אשבול
1276 ,אשבל ,9000 ,אשבל
70 ,אשדוד ,370 ,אב
70 ,אשדוד ,209 ,אבות ישורון
70 ,אשדוד ,765 ,אבטליון
70 ,אשדוד ,768 ,אביי
70 ,אשדוד ,161 ,אבן גבירול
70 ,אשדוד ,240 ,אבן עזרא
70 ,אשדוד ,746 ,אבני החושן
70 ,אשדוד ,733 ,אבנר בן נר
70 ,אשדוד ,148 ,אגדיר
70 ,אשדוד ,336 ,אגוז
70 ,אשדוד ,872 ,אגס
70 ,אשדוד ,783 ,אגת-שבו
70 ,אשדוד ,568 ,אדוננו ברוך
70 ,אשדוד ,654 ,אדיסון
70 ,אשדוד ,220 ,אדמו"ר מפיטסבורג
70 ,אשדוד ,365 ,אדר
70 ,אשדוד ,460 ,אהוד מנור
70 ,אשדוד ,607 ,אהרונסון שרה
70 ,אשדוד ,744 ,אודם
70 ,אשדוד ,333 ,אוניון
70 ,אשדוד ,682 ,אוסישקין מנחם
70 ,אשדוד ,785 ,אופל
70 ,אשדוד ,557 ,אור החיים
70 ,אשדוד ,327 ,אור לציון
70 ,אשדוד ,200 ,אורט
70 ,אשדוד ,813 ,אוריה החיתי
70 ,אשדוד ,6549 ,אזור בקמה
70 ,אשדוד ,6540 ,אזור המרינה
70 ,אשדוד ,215 ,אזור התעשיה
70 ,אשדוד ,6500 ,אזור יא
70 ,אשדוד ,6563 ,אזור תעשייה 1
70 ,אשדוד ,6564 ,אזור תעשייה 2
70 ,אשדוד ,6562 ,אזור תעשייה 3
70 ,אשדוד ,6501 ,אזורים
70 ,אשדוד ,227 ,אח"י אילת
70 ,אשדוד ,216 ,אחד העם
70 ,אשדוד ,594 ,אחוזת בית
70 ,אשדוד ,683 ,אחימאיר אבא
70 ,אשדוד ,246 ,אחיעזר
70 ,אשדוד ,782 ,אחלמה
70 ,אשדוד ,687 ,אטלי
70 ,אשדוד ,619 ,אידלסון בבה
70 ,אשדוד ,236 ,אייל
70 ,אשדוד ,367 ,אייר
70 ,אשדוד ,409 ,אילון
70 ,אשדוד ,299 ,איתמר
70 ,אשדוד ,320 ,אל הציפור
70 ,אשדוד ,182 ,אלבז נתן
70 ,אשדוד ,661 ,אלברט איינשטיין
70 ,אשדוד ,371 ,אלול
70 ,אשדוד ,290 ,אלון יגאל
70 ,אשדוד ,322 ,אליאב לובה
70 ,אשדוד ,280 ,אליאשוילי
70 ,אשדוד ,753 ,אליעזר בן הורקנוס
70 ,אשדוד ,288 ,אלתרמן נתן
70 ,אשדוד ,111 ,אמנון ליפקין שחק
70 ,אשדוד ,784 ,אמרלד
70 ,אשדוד ,675 ,אנילביץ מרדכי
70 ,אשדוד ,6502 ,אסבסטונים
70 ,אשדוד ,603 ,אסתר המלכה
70 ,אשדוד ,353 ,אפרים לנגסנר
70 ,אשדוד ,874 ,אפרסמון
70 ,אשדוד ,870 ,אפרסק
70 ,אשדוד ,233 ,אפרתי אליהו
70 ,אשדוד ,332 ,אקסודוס
70 ,אשדוד ,601 ,ארבע האמהות
70 ,אשדוד ,457 ,אריק איינשטיין
70 ,אשדוד ,279 ,ארלוזורוב
70 ,אשדוד ,248 ,ארן זלמן
70 ,אשדוד ,9000 ,אשדוד
70 ,אשדוד ,879 ,אשכולית
70 ,אשדוד ,179 ,בארי
70 ,אשדוד ,590 ,בבלי
70 ,אשדוד ,621 ,בגין עליזה
70 ,אשדוד ,185 ,בובר מרטין
70 ,אשדוד ,285 ,בוסקילה יצחק
70 ,אשדוד ,173 ,בורוכוב
70 ,אשדוד ,660 ,בורחס חורחה
70 ,אשדוד ,277 ,בורלא יהודה
70 ,אשדוד ,902 ,בז
70 ,אשדוד ,669 ,בטהובן
70 ,אשדוד ,278 ,ביאליק
70 ,אשדוד ,573 ,ביל"ו
70 ,אשדוד ,143 ,בית קנדה
70 ,אשדוד ,205 ,בלפור
70 ,אשדוד ,247 ,בן אליעזר אריה
70 ,אשדוד ,569 ,בן ברוך אורי
70 ,אשדוד ,570 ,בן דיוואן עמרם
70 ,אשדוד ,341 ,בן הכט
70 ,אשדוד ,201 ,בן יוסף שלמה
70 ,אשדוד ,340 ,בן עמי עובד
70 ,אשדוד ,504 ,בעלי מלאכה
70 ,אשדוד ,436 ,בקעת הירח
70 ,אשדוד ,666 ,ברהמס
70 ,אשדוד ,612 ,ברוריה
70 ,אשדוד ,204 ,ברנר
70 ,אשדוד ,818 ,ברק בן אבינועם
70 ,אשדוד ,745 ,ברקת
70 ,אשדוד ,451 ,ג'ו עמר
70 ,אשדוד ,318 ,גאפונוב
70 ,אשדוד ,742 ,גביש
70 ,אשדוד ,6503 ,גבעת יונה
70 ,אשדוד ,592 ,גבעת עדה
70 ,אשדוד ,572 ,גדרה
70 ,אשדוד ,656 ,גוגול
70 ,אשדוד ,611 ,גולדברג לאה
70 ,אשדוד ,232 ,גור דב
70 ,אשדוד ,697 ,גור מרדכי
70 ,אשדוד ,659 ,גטה
70 ,אשדוד ,595 ,גיא אוני
70 ,אשדוד ,424 ,גלעד
70 ,אשדוד ,694 ,גלעדי ישראל
70 ,אשדוד ,237 ,גרונר דב
70 ,אשדוד ,351 ,גרינברג אורי צבי
70 ,אשדוד ,228 ,גשר עד הלום
70 ,אשדוד ,602 ,דבורה הנביאה
70 ,אשדוד ,700 ,דבורה עומר
70 ,אשדוד ,578 ,דגניה
70 ,אשדוד ,668 ,דה וינצ'י
70 ,אשדוד ,114 ,דוד אלעזר
70 ,אשדוד ,150 ,דוכיפת
70 ,אשדוד ,509 ,דולומיט
70 ,אשדוד ,256 ,דיין משה
70 ,אשדוד ,113 ,דן שומרון
70 ,אשדוד ,249 ,דקר
70 ,אשדוד ,153 ,דרור
70 ,אשדוד ,479 ,דרך אריאל שרון
70 ,אשדוד ,343 ,דרך ארץ
70 ,אשדוד ,580 ,דרך בגין מנחם
70 ,אשדוד ,533 ,דרך המכס
70 ,אשדוד ,323 ,דרך הרכבת
70 ,אשדוד ,480 ,דרך לסקוב
70 ,אשדוד ,486 ,דרך ניר גלים
70 ,אשדוד ,481 ,דרך תל מור
70 ,אשדוד ,861 ,ה' באייר
70 ,אשדוד ,289 ,האדמו"ר מבעלז
70 ,אשדוד ,328 ,האדמו"ר מגור
70 ,אשדוד ,326 ,האדמו"ר מויז'ניץ
70 ,אשדוד ,508 ,האומן
70 ,אשדוד ,538 ,האוניה
70 ,אשדוד ,268 ,האופים
70 ,אשדוד ,501 ,האורגים
70 ,אשדוד ,135 ,האורן
70 ,אשדוד ,116 ,האיריס
70 ,אשדוד ,104 ,האלון
70 ,אשדוד ,254 ,האלמוגים
70 ,אשדוד ,211 ,האצ"ל
70 ,אשדוד ,556 ,האר"י הקדוש
70 ,אשדוד ,137 ,הארז
70 ,אשדוד ,127 ,האשכול
70 ,אשדוד ,131 ,האשל
70 ,אשדוד ,491 ,האשלג
70 ,אשדוד ,301 ,האתרוג
70 ,אשדוד ,507 ,הבושם
70 ,אשדוד ,492 ,הבטיחות
70 ,אשדוד ,500 ,הבנאים
70 ,אשדוד ,330 ,הבנים
70 ,אשדוד ,282 ,הבעל שם טוב
70 ,אשדוד ,426 ,הבשור
70 ,אשדוד ,331 ,הגדוד העברי
70 ,אשדוד ,431 ,הגולשים
70 ,אשדוד ,534 ,הגוררת
70 ,אשדוד ,255 ,הגלים
70 ,אשדוד ,824 ,הגלימה
70 ,אשדוד ,133 ,הגפן
70 ,אשדוד ,559 ,הגר"א
70 ,אשדוד ,535 ,הדוברה
70 ,אשדוד ,252 ,הדולפין
70 ,אשדוד ,130 ,הדס
70 ,אשדוד ,138 ,הדקל
70 ,אשדוד ,140 ,ההגנה
70 ,אשדוד ,850 ,ההגשמה
70 ,אשדוד ,503 ,ההדרים
70 ,אשדוד ,144 ,ההסתדרות
70 ,אשדוד ,541 ,ההפלגה
70 ,אשדוד ,487 ,ההשכלה
70 ,אשדוד ,235 ,הוז דב
70 ,אשדוד ,510 ,הוסטל שלום
70 ,אשדוד ,119 ,הורדים
70 ,אשדוד ,129 ,הזית
70 ,אשדוד ,124 ,החבצלות
70 ,אשדוד ,286 ,החובלים
70 ,אשדוד ,587 ,החולה
70 ,אשדוד ,441 ,החותרים
70 ,אשדוד ,560 ,החיד"א
70 ,אשדוד ,851 ,החלוצים
70 ,אשדוד ,493 ,החניכים
70 ,אשדוד ,494 ,החרושת
70 ,אשדוד ,300 ,החרצית
70 ,אשדוד ,485 ,החשמל
70 ,אשדוד ,325 ,הטיילת
70 ,אשדוד ,502 ,היהלומים
70 ,אשדוד ,269 ,היוצר
70 ,אשדוד ,498 ,היזמה
70 ,אשדוד ,670 ,היידן
70 ,אשדוד ,484 ,הימאים
70 ,אשדוד ,121 ,היסמין
70 ,אשדוד ,497 ,היציקה
70 ,אשדוד ,309 ,הכלנית
70 ,אשדוד ,822 ,הכתר
70 ,אשדוד ,172 ,הלביאה
70 ,אשדוד ,763 ,הלל
70 ,אשדוד ,241 ,הלפרין ירמיהו
70 ,אשדוד ,862 ,המאבק
70 ,אשדוד ,295 ,המברק
70 ,אשדוד ,548 ,המגדלור
70 ,אשדוד ,293 ,המגיד
70 ,אשדוד ,499 ,המדע
70 ,אשדוד ,496 ,המחקר
70 ,אשדוד ,297 ,המליץ
70 ,אשדוד ,805 ,המלך אליקים
70 ,אשדוד ,810 ,המלך אמציה
70 ,אשדוד ,806 ,המלך אסא
70 ,אשדוד ,801 ,המלך דוד
70 ,אשדוד ,820 ,המלך הדד
70 ,אשדוד ,804 ,המלך חזקיה
70 ,אשדוד ,808 ,המלך יהוא
70 ,אשדוד ,819 ,המלך יהואחז
70 ,אשדוד ,816 ,המלך יהויכין
70 ,אשדוד ,807 ,המלך יהושפט
70 ,אשדוד ,821 ,המלך יובב
70 ,אשדוד ,814 ,המלך יותם
70 ,אשדוד ,815 ,המלך ירבעם
70 ,אשדוד ,803 ,המלך עזריה
70 ,אשדוד ,811 ,המלך עמרי
70 ,אשדוד ,812 ,המלך רחבעם
70 ,אשדוד ,817 ,המלך שאול
70 ,אשדוד ,802 ,המלך שלמה
70 ,אשדוד ,823 ,המלכים
70 ,אשדוד ,271 ,המסגר
70 ,אשדוד ,122 ,המסחר
70 ,אשדוד ,546 ,המעבורת
70 ,אשדוד ,544 ,המעגן
70 ,אשדוד ,145 ,המעפילים
70 ,אשדוד ,550 ,המפרץ
70 ,אשדוד ,262 ,המפרש
70 ,אשדוד ,547 ,המצפן
70 ,אשדוד ,857 ,המרי
70 ,אשדוד ,294 ,המשקיף
70 ,אשדוד ,287 ,המתכת
70 ,אשדוד ,319 ,המתמיד
70 ,אשדוד ,860 ,המתנדבים
70 ,אשדוד ,714 ,הנביא אליהו
70 ,אשדוד ,707 ,הנביא אלישע
70 ,אשדוד ,716 ,הנביא גד
70 ,אשדוד ,703 ,הנביא דניאל
70 ,אשדוד ,712 ,הנביא הושע
70 ,אשדוד ,706 ,הנביא זכריה
70 ,אשדוד ,722 ,הנביא חבקוק
70 ,אשדוד ,723 ,הנביא חגי
70 ,אשדוד ,708 ,הנביא יואל
70 ,אשדוד ,719 ,הנביא יחזקאל
70 ,אשדוד ,718 ,הנביא ירמיהו
70 ,אשדוד ,717 ,הנביא ישעיהו
70 ,אשדוד ,721 ,הנביא מיכה
70 ,אשדוד ,711 ,הנביא מלאכי
70 ,אשדוד ,725 ,הנביא נחום
70 ,אשדוד ,709 ,הנביא נחמיה
70 ,אשדוד ,713 ,הנביא נתן
70 ,אשדוד ,726 ,הנביא עובדיה
70 ,אשדוד ,727 ,הנביא עמוס
70 ,אשדוד ,724 ,הנביא צפניה
70 ,אשדוד ,705 ,הנביא שמואל
70 ,אשדוד ,704 ,הנביאים
70 ,אשדוד ,272 ,הנגר
70 ,אשדוד ,304 ,הנורית
70 ,אשדוד ,273 ,הנחושת
70 ,אשדוד ,545 ,הנחשול
70 ,אשדוד ,488 ,הנפט
70 ,אשדוד ,314 ,הנרקיס
70 ,אשדוד ,213 ,הנשיא ויצמן
70 ,אשדוד ,120 ,הסביון
70 ,אשדוד ,305 ,הסחלב
70 ,אשדוד ,316 ,הסייפן
70 ,אשדוד ,539 ,הסיפון
70 ,אשדוד ,537 ,הספנות
70 ,אשדוד ,274 ,העבודה
70 ,אשדוד ,549 ,העוגן
70 ,אשדוד ,171 ,העופרים
70 ,אשדוד ,275 ,העופרת
70 ,אשדוד ,731 ,העליה
70 ,אשדוד ,276 ,העמל
70 ,אשדוד ,852 ,העצמאות
70 ,אשדוד ,141 ,העשרים ואחד
70 ,אשדוד ,490 ,הפלדה
70 ,אשדוד ,207 ,הפלמ"ח
70 ,אשדוד ,489 ,הפריון
70 ,אשדוד ,170 ,הצבי
70 ,אשדוד ,313 ,הצבעוני
70 ,אשדוד ,433 ,הצוללים
70 ,אשדוד ,505 ,הצורפים
70 ,אשדוד ,856 ,הציונות
70 ,אשדוד ,296 ,הצפירה
70 ,אשדוד ,506 ,הקדמה
70 ,אשדוד ,495 ,הקיטור
70 ,אשדוד ,853 ,הקליטה
70 ,אשדוד ,6545 ,הקריה-מגורים
70 ,אשדוד ,6551 ,הקריה-מע"ר
70 ,אשדוד ,434 ,הר אביטל
70 ,אשדוד ,419 ,הר גלבוע
70 ,אשדוד ,416 ,הר הצופים
70 ,אשדוד ,428 ,הר כנען
70 ,אשדוד ,422 ,הר כרמל
70 ,אשדוד ,418 ,הר מירון
70 ,אשדוד ,420 ,הר נבו
70 ,אשדוד ,425 ,הר ציון
70 ,אשדוד ,421 ,הר תבור
70 ,אשדוד ,555 ,הרא"ש
70 ,אשדוד ,234 ,הראם
70 ,אשדוד ,118 ,הראשונים
70 ,אשדוד ,267 ,הרב אבוחצירה
70 ,אשדוד ,281 ,הרב אבן דנן
70 ,אשדוד ,183 ,הרב אברמסקי
70 ,אשדוד ,561 ,הרב בוזגלו דוד
70 ,אשדוד ,194 ,הרב בלולו
70 ,אשדוד ,219 ,הרב הגר
70 ,אשדוד ,159 ,הרב הרצוג
70 ,אשדוד ,453 ,הרב חיים שושנה
70 ,אשדוד ,525 ,הרב טייב
70 ,אשדוד ,105 ,הרב יהושע מאמאן
70 ,אשדוד ,515 ,הרב יורם אברג'ל
70 ,אשדוד ,160 ,הרב מימון
70 ,אשדוד ,563 ,הרב משאש יוסף
70 ,אשדוד ,298 ,הרב ניסים
70 ,אשדוד ,163 ,הרב קוק
70 ,אשדוד ,566 ,הרב קלישר
70 ,אשדוד ,264 ,הרב רוזובסקי
70 ,אשדוד ,564 ,הרב ריינס
70 ,אשדוד ,259 ,הרב שאולי
70 ,אשדוד ,303 ,הרותם
70 ,אשדוד ,412 ,הרי גולן
70 ,אשדוד ,586 ,הרי"ם לוין
70 ,אשדוד ,558 ,הרי"ף
70 ,אשדוד ,881 ,הרימון
70 ,אשדוד ,526 ,הרמ"א
70 ,אשדוד ,345 ,הרפואה
70 ,אשדוד ,542 ,הרציף
70 ,אשדוד ,315 ,הרקפת
70 ,אשדוד ,136 ,השיטה
70 ,אשדוד ,432 ,השייטים
70 ,אשדוד ,134 ,השקמה
70 ,אשדוד ,132 ,התאנה
70 ,אשדוד ,439 ,התורן
70 ,אשדוד ,329 ,התלמוד
70 ,אשדוד ,513 ,התעסוקה
70 ,אשדוד ,855 ,התקוה
70 ,אשדוד ,854 ,התקומה
70 ,אשדוד ,512 ,התקשורת
70 ,אשדוד ,334 ,ואלוס
70 ,אשדוד ,673 ,ואן גוך
70 ,אשדוד ,684 ,וולפסון דוד
70 ,אשדוד ,671 ,ויקטור הוגו
70 ,אשדוד ,695 ,ויתקין יוסף
70 ,אשדוד ,720 ,ורדימון יצחק
70 ,אשדוד ,181 ,ז'בוטינסקי
70 ,אשדוד ,664 ,זולה אמיל
70 ,אשדוד ,696 ,זייד אלכסנדר
70 ,אשדוד ,527 ,זינגר בשביס
70 ,אשדוד ,577 ,זכרון יעקב
70 ,אשדוד ,688 ,זלמן דוד לבונטין
70 ,אשדוד ,151 ,זמיר
70 ,אשדוד ,689 ,זנגביל
70 ,אשדוד ,574 ,חדרה
70 ,אשדוד ,155 ,חוחית
70 ,אשדוד ,618 ,חולדה הנביאה
70 ,אשדוד ,6539 ,חוף באר שבע
70 ,אשדוד ,6552 ,חוף הקשתות
70 ,אשדוד ,109 ,חוף לידו
70 ,אשדוד ,177 ,חוף מי עמי
70 ,אשדוד ,423 ,חורב
70 ,אשדוד ,198 ,חורי חיים
70 ,אשדוד ,692 ,חזנוב עמרם
70 ,אשדוד ,225 ,חטיבת גבעתי
70 ,אשדוד ,222 ,חטיבת גולני
70 ,אשדוד ,231 ,חטיבת הנגב
70 ,אשדוד ,221 ,חטיבת יפתח
70 ,אשדוד ,223 ,חטיבת כרמלי
70 ,אשדוד ,117 ,חיבת ציון
70 ,אשדוד ,115 ,חיים ברלב
70 ,אשדוד ,106 ,חיים הרצוג
70 ,אשדוד ,482 ,חלוצי התעשיה
70 ,אשדוד ,755 ,חנינא בן דוסא
70 ,אשדוד ,693 ,חנקין יהושע
70 ,אשדוד ,665 ,חסידי אומות העולם
70 ,אשדוד ,197 ,חסן ובן גרה
70 ,אשדוד ,244 ,חפץ חיים
70 ,אשדוד ,476 ,חרטום
70 ,אשדוד ,413 ,חרמון
70 ,אשדוד ,361 ,חשון
70 ,אשדוד ,363 ,טבת
70 ,אשדוד ,658 ,טולסטוי
70 ,אשדוד ,6538 ,טופ סנטר
70 ,אשדוד ,265 ,טרומפלדור
70 ,אשדוד ,208 ,טשרניחובסקי
70 ,אשדוד ,190 ,יאיר
70 ,אשדוד ,593 ,יבנאל
70 ,אשדוד ,112 ,יגאל ידין
70 ,אשדוד ,214 ,יד העפלה ממרוקו
70 ,אשדוד ,217 ,יהודה הלוי
70 ,אשדוד ,196 ,יהודה המכבי
70 ,אשדוד ,732 ,יהושע בן נון
70 ,אשדוד ,730 ,יואב בן צרויה
70 ,אשדוד ,685 ,יואל משה סלומון
70 ,אשדוד ,620 ,יוהנה זבוטינסקי
70 ,אשדוד ,758 ,יוחנן בן זכאי
70 ,אשדוד ,690 ,יום טוב צמח
70 ,אשדוד ,193 ,יונה הנביא
70 ,אשדוד ,458 ,יוסי בנאי
70 ,אשדוד ,752 ,יוסי בר חלפתא
70 ,אשדוד ,146 ,יוספטל
70 ,אשדוד ,156 ,יורדי הסירה
70 ,אשדוד ,162 ,ילין דוד
70 ,אשדוד ,576 ,יסוד המעלה
70 ,אשדוד ,617 ,יעל
70 ,אשדוד ,454 ,יפה ירקוני
70 ,אשדוד ,348 ,יצחק אילן
70 ,אשדוד ,180 ,יצחק הנשיא
70 ,אשדוד ,167 ,יצחק שדה
70 ,אשדוד ,344 ,יצחק שמיר
70 ,אשדוד ,321 ,יקינתון
70 ,אשדוד ,414 ,ירמוך
70 ,אשדוד ,408 ,ירקון
70 ,אשדוד ,743 ,ירקן
70 ,אשדוד ,788 ,ישפה
70 ,אשדוד ,859 ,כ"ט בנובמבר
70 ,אשדוד ,6001 ,כהן אלי ככר
70 ,אשדוד ,392 ,כוכב הדרום
70 ,אשדוד ,540 ,כוכב הים
70 ,אשדוד ,390 ,כוכב הצפון
70 ,אשדוד ,391 ,כוכב השחר
70 ,אשדוד ,6012 ,כיכר הדייגים
70 ,אשדוד ,450 ,כינור
70 ,אשדוד ,6008 ,ככר 12 השבטים
70 ,אשדוד ,6009 ,ככר אלתא
70 ,אשדוד ,6011 ,ככר הבונים החופשיים
70 ,אשדוד ,6005 ,ככר המדינה
70 ,אשדוד ,6006 ,ככר העונות
70 ,אשדוד ,6003 ,ככר סיגל
70 ,אשדוד ,6007 ,ככר ציון
70 ,אשדוד ,6010 ,ככר קהילת סלוניקי
70 ,אשדוד ,6004 ,ככר שרת משה
70 ,אשדוד ,406 ,כנרת
70 ,אשדוד ,362 ,כסלו
70 ,אשדוד ,308 ,כפתור החולות
70 ,אשדוד ,514 ,לוחמי האש
70 ,אשדוד ,710 ,לוי שלמה
70 ,אשדוד ,253 ,לויתן
70 ,אשדוד ,342 ,לילי
70 ,אשדוד ,292 ,למרחב
70 ,אשדוד ,781 ,לשם
70 ,אשדוד ,760 ,מאיר בעל הנס
70 ,אשדוד ,757 ,מבוא האמוראים
70 ,אשדוד ,312 ,מבוא הגומא
70 ,אשדוד ,520 ,מבוא הדגנית
70 ,אשדוד ,523 ,מבוא הדודאים
70 ,אשדוד ,310 ,מבוא הדמומית
70 ,אשדוד ,521 ,מבוא החמציץ
70 ,אשדוד ,522 ,מבוא הכרכום
70 ,אשדוד ,483 ,מבוא הספנים
70 ,אשדוד ,311 ,מבוא העירית
70 ,אשדוד ,863 ,מבוא הקריה
70 ,אשדוד ,261 ,מבוא הרב אלקלעי
70 ,אשדוד ,306 ,מבוא השרביטן
70 ,אשדוד ,756 ,מבוא התנאים
70 ,אשדוד ,284 ,מבוא מבצע חורב
70 ,אשדוד ,283 ,מבוא מבצע נחשון
70 ,אשדוד ,901 ,מבצע ברק
70 ,אשדוד ,734 ,מדרחוב הנביאים
70 ,אשדוד ,212 ,מורדי הגטאות
70 ,אשדוד ,430 ,מוריה
70 ,אשדוד ,579 ,מזכרת בתיה
70 ,אשדוד ,588 ,מטולה
70 ,אשדוד ,243 ,מיכשוילי
70 ,אשדוד ,260 ,מילמן
70 ,אשדוד ,653 ,מיסטרל
70 ,אשדוד ,672 ,מכאל אנג'לו
70 ,אשדוד ,442 ,מכולות
70 ,אשדוד ,613 ,מלכת שבא
70 ,אשדוד ,876 ,מנגו
70 ,אשדוד ,699 ,מעגל השמחה
70 ,אשדוד ,339 ,מפקורה
70 ,אשדוד ,415 ,מצדה
70 ,אשדוד ,589 ,מקוה ישראל
70 ,אשדוד ,192 ,מקלנבורג
70 ,אשדוד ,125 ,מרזוק ושמואל
70 ,אשדוד ,606 ,מרים הנביאה
70 ,אשדוד ,238 ,מרכז קליטה
70 ,אשדוד ,437 ,מרכז קליטה שלום
70 ,אשדוד ,347 ,משה לוי(מוישה וחצי)
70 ,אשדוד ,187 ,משמר הירדן
70 ,אשדוד ,873 ,משמש
70 ,אשדוד ,596 ,משנה
70 ,אשדוד ,174 ,משעול האירוסים
70 ,אשדוד ,6534 ,מתחם אלתא
70 ,אשדוד ,6510 ,נאות ספיר
70 ,אשדוד ,199 ,נגרו אהרן
70 ,אשדוד ,581 ,נהלל
70 ,אשדוד ,6505 ,נוה דניה
70 ,אשדוד ,6506 ,נוה יהונתן
70 ,אשדוד ,6516 ,נוה ים
70 ,אשדוד ,747 ,נופך
70 ,אשדוד ,176 ,נורדאו
70 ,אשדוד ,403 ,נחל דן
70 ,אשדוד ,531 ,נחל הליטני
70 ,אשדוד ,401 ,נחל לכיש
70 ,אשדוד ,427 ,נחל ערוגות
70 ,אשדוד ,402 ,נחל שורק
70 ,אשדוד ,411 ,נחל שניר
70 ,אשדוד ,152 ,נחליאלי
70 ,אשדוד ,551 ,נחמן מברסלב
70 ,אשדוד ,108 ,ניסים חדד
70 ,אשדוד ,366 ,ניסן
70 ,אשדוד ,6560 ,נמל אשדוד
70 ,אשדוד ,443 ,נמלי ישראל
70 ,אשדוד ,571 ,נס ציונה
70 ,אשדוד ,616 ,נעמי
70 ,אשדוד ,405 ,נעמן
70 ,אשדוד ,858 ,נצח ישראל
70 ,אשדוד ,651 ,סאלק יונה
70 ,אשדוד ,158 ,סולד הנריטה
70 ,אשדוד ,728 ,סופר אברהם
70 ,אשדוד ,149 ,סטרומה
70 ,אשדוד ,368 ,סיון
70 ,אשדוד ,789 ,סיטרין
70 ,אשדוד ,188 ,סילבר אבא הלל
70 ,אשדוד ,139 ,סיני
70 ,אשדוד ,681 ,סירקין נחמן
70 ,אשדוד ,338 ,סלבדור
70 ,אשדוד ,101 ,סמ אל הים
70 ,אשדוד ,809 ,סמ יהואש
70 ,אשדוד ,168 ,סמטה א
70 ,אשדוד ,169 ,סמטה ב
70 ,אשדוד ,452 ,סמי אלמגריבי
70 ,אשדוד ,440 ,סנפיר
70 ,אשדוד ,230 ,סנש חנה
70 ,אשדוד ,317 ,ספיח
70 ,אשדוד ,740 ,ספיר
70 ,אשדוד ,166 ,עגנון שי
70 ,אשדוד ,352 ,עדי עם שלום
70 ,אשדוד ,444 ,עובדי הנמל
70 ,אשדוד ,461 ,עוזי חיטמן
70 ,אשדוד ,251 ,עופר אברהם
70 ,אשדוד ,729 ,עזרא הסופר
70 ,אשדוד ,786 ,עינבר
70 ,אשדוד ,877 ,ענבים
70 ,אשדוד ,754 ,עקביא בן מהללאל
70 ,אשדוד ,337 ,פאנצו
70 ,אשדוד ,417 ,פארן
70 ,אשדוד ,6535 ,פארק השיטה המלבינה
70 ,אשדוד ,715 ,פורצי הדרך
70 ,אשדוד ,780 ,פטדה
70 ,אשדוד ,691 ,פיינברג יוסף
70 ,אשדוד ,567 ,פינטו חיים
70 ,אשדוד ,291 ,פן אלכסנדר
70 ,אשדוד ,749 ,פנינה
70 ,אשדוד ,662 ,פסטר לואי
70 ,אשדוד ,357 ,פרופ' אברהם הרשקו
70 ,אשדוד ,356 ,פרופ' אהרון צ'חנובר
70 ,אשדוד ,358 ,פרופ' דן שכטמן
70 ,אשדוד ,354 ,פרופ' ישראל אומן
70 ,אשדוד ,359 ,פרופ' מרסל מכלוף
70 ,אשדוד ,608 ,פרנק אנה
70 ,אשדוד ,584 ,פתח תקווה
70 ,אשדוד ,410 ,צאלים
70 ,אשדוד ,263 ,צדקת יוסף
70 ,אשדוד ,463 ,צה"ל
70 ,אשדוד ,864 ,צור הדסה
70 ,אשדוד ,195 ,ציזלינג
70 ,אשדוד ,591 ,צפת
70 ,אשדוד ,552 ,קארו יוסף
70 ,אשדוד ,147 ,קבוץ גלויות
70 ,אשדוד ,175 ,קדושי בלזן
70 ,אשדוד ,404 ,קדרון
70 ,אשדוד ,142 ,קדש
70 ,אשדוד ,787 ,קורל
70 ,אשדוד ,674 ,קורצ'ק יאנוש
70 ,אשדוד ,407 ,קישון
70 ,אשדוד ,657 ,קמי אלברט
70 ,אשדוד ,702 ,קק"ל
70 ,אשדוד ,6518 ,קרית חרדים
70 ,אשדוד ,6507 ,קרית פוניבש
70 ,אשדוד ,258 ,קרן היסוד
70 ,אשדוד ,583 ,ראש פינה
70 ,אשדוד ,770 ,רב אשי
70 ,אשדוד ,771 ,רב חיסדא
70 ,אשדוד ,772 ,רב ינאי
70 ,אשדוד ,769 ,רבא
70 ,אשדוד ,456 ,רבי דוד אלקיים
70 ,אשדוד ,462 ,רבי דוד בן חסין
70 ,אשדוד ,761 ,רבי טרפון
70 ,אשדוד ,762 ,רבי יהודה הנשיא
70 ,אשדוד ,266 ,רבי עקיבא
70 ,אשדוד ,622 ,רבין יצחק
70 ,אשדוד ,767 ,רבינא
70 ,אשדוד ,554 ,רבנו גרשום
70 ,אשדוד ,565 ,רבנו וידל הצרפתי
70 ,אשדוד ,553 ,רבנו תם
70 ,אשדוד ,623 ,רובינא חנה
70 ,אשדוד ,6531 ,רובע א
70 ,אשדוד ,6532 ,רובע ב
70 ,אשדוד ,6533 ,רובע ג
70 ,אשדוד ,6508 ,רובע ה
70 ,אשדוד ,6536 ,רובע ו
70 ,אשדוד ,6530 ,רובע ז
70 ,אשדוד ,6509 ,רובע ח
70 ,אשדוד ,6529 ,רובע ט
70 ,אשדוד ,6550 ,רובע ט"ו
70 ,אשדוד ,6548 ,רובע ט"ז
70 ,אשדוד ,6527 ,רובע י
70 ,אשדוד ,6546 ,רובע י"ב
70 ,אשדוד ,6547 ,רובע י"ד
70 ,אשדוד ,6528 ,רובע יג
70 ,אשדוד ,6537 ,רובע יז
70 ,אשדוד ,128 ,רוגוזין
70 ,אשדוד ,686 ,רופין ארתור
70 ,אשדוד ,615 ,רות המואביה
70 ,אשדוד ,191 ,רזיאל דוד
70 ,אשדוד ,614 ,רחל אמנו
70 ,אשדוד ,609 ,רחל ינאית
70 ,אשדוד ,157 ,רמב"ם
70 ,אשדוד ,667 ,רמברנדט
70 ,אשדוד ,663 ,רנטגן
70 ,אשדוד ,346 ,רפאל איתן (רפול)
70 ,אשדוד ,759 ,רשב"י
70 ,אשדוד ,6511 ,ש אגש
70 ,אשדוד ,6512 ,ש אזור ד
70 ,אשדוד ,6514 ,ש אשטרום
70 ,אשדוד ,6525 ,ש חב חשמל
70 ,אשדוד ,6517 ,ש חסכון
70 ,אשדוד ,6522 ,ש רסקו
70 ,אשדוד ,676 ,שאגל מארק
70 ,אשדוד ,107 ,שאול בן שמחון
70 ,אשדוד ,239 ,שבזי
70 ,אשדוד ,364 ,שבט
70 ,אשדוד ,382 ,שבט אשר
70 ,אשדוד ,386 ,שבט בנימין
70 ,אשדוד ,381 ,שבט גד
70 ,אשדוד ,379 ,שבט דן
70 ,אשדוד ,384 ,שבט זבולון
70 ,אשדוד ,378 ,שבט יהודה
70 ,אשדוד ,385 ,שבט יוסף
70 ,אשדוד ,383 ,שבט יששכר
70 ,אשדוד ,377 ,שבט לוי
70 ,אשדוד ,380 ,שבט נפתלי
70 ,אשדוד ,375 ,שבט ראובן
70 ,אשדוד ,376 ,שבט שמעון
70 ,אשדוד ,126 ,שבי ציון
70 ,אשדוד ,164 ,שביל יא
70 ,אשדוד ,242 ,שגב
70 ,אשדוד ,335 ,שד אלטלנה
70 ,אשדוד ,597 ,שד בן גוריון
70 ,אשדוד ,165 ,שד בני ברית
70 ,אשדוד ,680 ,שד גורדון
70 ,אשדוד ,701 ,שד המיסדים
70 ,אשדוד ,307 ,שד הפרחים
70 ,אשדוד ,189 ,שד הרצל
70 ,אשדוד ,186 ,שד יפה נוף
70 ,אשדוד ,302 ,שד ירושלים
70 ,אשדוד ,677 ,שד מונטיפיורי
70 ,אשדוד ,206 ,שד סנה משה
70 ,אשדוד ,229 ,שד עוזיהו
70 ,אשדוד ,679 ,שד רוטשילד
70 ,אשדוד ,678 ,שד תל חי
70 ,אשדוד ,698 ,שדרות הים התיכון
70 ,אשדוד ,438 ,שדרות נתיבי הים
70 ,אשדוד ,575 ,שדרות ראשון לציון
70 ,אשדוד ,585 ,שדרות רחובות
70 ,אשדוד ,562 ,שדרות רש"י
70 ,אשדוד ,741 ,שהם
70 ,אשדוד ,543 ,שובר הגלים
70 ,אשדוד ,610 ,שוחט מניה
70 ,אשדוד ,429 ,שומרון
70 ,אשדוד ,224 ,שועלי שמשון
70 ,אשדוד ,455 ,שושנה דמארי
70 ,אשדוד ,536 ,שושנת הרוחות
70 ,אשדוד ,226 ,שחם יואב
70 ,אשדוד ,655 ,שילר
70 ,אשדוד ,355 ,שיר השירים
70 ,אשדוד ,6523 ,שכ ונטורה
70 ,אשדוד ,154 ,שלדג
70 ,אשדוד ,605 ,שלומציון המלכה
70 ,אשדוד ,350 ,שלונסקי אברהם
70 ,אשדוד ,270 ,שלזינגר ארווין
70 ,אשדוד ,766 ,שמאי
70 ,אשדוד ,218 ,שמואל הנגיד
70 ,אשדוד ,750 ,שמעון בן שטח
70 ,אשדוד ,764 ,שמעיה
70 ,אשדוד ,735 ,שמר
70 ,אשדוד ,435 ,שער האריות
70 ,אשדוד ,478 ,שער הים
70 ,אשדוד ,245 ,שערי יושר
70 ,אשדוד ,582 ,שפיה
70 ,אשדוד ,202 ,שפירא אברהם
70 ,אשדוד ,257 ,שפירא משה חיים
70 ,אשדוד ,604 ,שרה אמנו
70 ,אשדוד ,6561 ,תחנת הכח
70 ,אשדוד ,652 ,תיאודור רוזוולט
70 ,אשדוד ,369 ,תמוז
70 ,אשדוד ,250 ,תנין
70 ,אשדוד ,878 ,תפוז
70 ,אשדוד ,871 ,תפוח
70 ,אשדוד ,748 ,תרשיש
70 ,אשדוד ,845 ,תש"ח
70 ,אשדוד ,360 ,תשרי
199 ,אשדות יעקב (איחוד) ,9000 ,אשדות יעקב (איחוד)
188 ,אשדות יעקב (מאוחד) ,9000 ,אשדות יעקב (מאוחד)
1188 ,אשחר ,9000 ,אשחר
3722 ,אשכולות ,9000 ,אשכולות
3722 ,אשכולות ,6500 ,סנסנה
2021 ,אשל הנשיא ,9000 ,אשל הנשיא
1152 ,אשלים ,9000 ,אשלים
1152 ,אשלים ,7001 ,חוות השחפים
7100 ,אשקלון ,1010 ,אב
7100 ,אשקלון ,660 ,אבא ווביה
7100 ,אשקלון ,794 ,אבוקדו
7100 ,אשקלון ,1064 ,אביבית
7100 ,אשקלון ,545 ,אבימלך
7100 ,אשקלון ,1250 ,אביר יעקב
7100 ,אשקלון ,592 ,אבישג
7100 ,אשקלון ,457 ,אבן עזרא
7100 ,אשקלון ,770 ,אבני החושן
7100 ,אשקלון ,1110 ,אבני חן
7100 ,אשקלון ,372 ,אבנר בן נר
7100 ,אשקלון ,780 ,אבצן
7100 ,אשקלון ,612 ,אברבנאל
7100 ,אשקלון ,347 ,אברהם ברזילי
7100 ,אשקלון ,805 ,אבשלום
7100 ,אשקלון ,261 ,אגם דלתון
7100 ,אשקלון ,260 ,אגם זוהר
7100 ,אשקלון ,1302 ,אגמון
7100 ,אשקלון ,1136 ,אגמית
7100 ,אשקלון ,788 ,אגס
7100 ,אשקלון ,831 ,אדית ומשה דורות
7100 ,אשקלון ,1194 ,אדלר חנה
7100 ,אשקלון ,379 ,אדם יקותיאל
7100 ,אשקלון ,987 ,אדמונד דה רוטשילד
7100 ,אשקלון ,1011 ,אדר
7100 ,אשקלון ,983 ,אהבת שלום
7100 ,אשקלון ,541 ,אהוד
7100 ,אשקלון ,1257 ,אהרון עמיקם
7100 ,אשקלון ,896 ,אהרונסון שרה
7100 ,אשקלון ,775 ,אודם
7100 ,אשקלון ,475 ,אוסישקין
7100 ,אשקלון ,1095 ,אופירה
7100 ,אשקלון ,979 ,אור החיים
7100 ,אשקלון ,974 ,אורה ושמחה
7100 ,אשקלון ,377 ,אורט
7100 ,אשקלון ,6548 ,אז תעשיה גבעת ציון
7100 ,אשקלון ,6550 ,אזור הסיטי
7100 ,אשקלון ,6547 ,אזור תעשייה דרום
7100 ,אשקלון ,6546 ,אזור תעשייה צפון
7100 ,אשקלון ,1320 ,אח"י דקר
7100 ,אשקלון ,890 ,אחד העם
7100 ,אשקלון ,1186 ,אחווה
7100 ,אשקלון ,384 ,אחוזת בית
7100 ,אשקלון ,378 ,אחימאיר אב"א
7100 ,אשקלון ,682 ,אחישר אריה
7100 ,אשקלון ,772 ,אחלמה
7100 ,אשקלון ,606 ,איגר עקיבא
7100 ,אשקלון ,1050 ,איילה
7100 ,אשקלון ,1012 ,אייר
7100 ,אשקלון ,1147 ,אילים
7100 ,אשקלון ,304 ,אילת
7100 ,אשקלון ,1084 ,אירוס
7100 ,אשקלון ,763 ,איתן רפאל
7100 ,אשקלון ,813 ,אלבז
7100 ,אשקלון ,1033 ,אלול
7100 ,אשקלון ,419 ,אלון יעקב
7100 ,אשקלון ,416 ,אלי כהן
7100 ,אשקלון ,1177 ,אלי סיני
7100 ,אשקלון ,754 ,אליאב אורי
7100 ,אשקלון ,964 ,אליהו גניש
7100 ,אשקלון ,514 ,אליהו הנביא
7100 ,אשקלון ,522 ,אלישע
7100 ,אשקלון ,721 ,אלכסנדרוני
7100 ,אשקלון ,994 ,אלמגריבי סמי
7100 ,אשקלון ,981 ,אלמוג
7100 ,אשקלון ,814 ,אלעד
7100 ,אשקלון ,993 ,אלפסיה זוהרה
7100 ,אשקלון ,891 ,אלקלעי יהודה
7100 ,אשקלון ,598 ,אלקנה
7100 ,אשקלון ,252 ,אמנון
7100 ,אשקלון ,487 ,אנה פרנק
7100 ,אשקלון ,424 ,אנחל סאנס בריס
7100 ,אשקלון ,1166 ,אנייה
7100 ,אשקלון ,680 ,אנילביץ מרדכי
7100 ,אשקלון ,793 ,אננס
7100 ,אשקלון ,1135 ,אנפה
7100 ,אשקלון ,585 ,אסף הרופא
7100 ,אשקלון ,593 ,אסתר המלכה
7100 ,אשקלון ,1304 ,אפיקי מים
7100 ,אשקלון ,337 ,אפל דוד וצילה
7100 ,אשקלון ,506 ,אפרים
7100 ,אשקלון ,848 ,אפרסמון
7100 ,אשקלון ,790 ,אפרסק
7100 ,אשקלון ,1217 ,אצוה
7100 ,אשקלון ,1018 ,אקסודוס
7100 ,אשקלון ,1224 ,ארגמן
7100 ,אשקלון ,567 ,ארד
7100 ,אשקלון ,1006 ,אריאל
7100 ,אשקלון ,421 ,אריאל שרון
7100 ,אשקלון ,1053 ,אריה
7100 ,אשקלון ,1256 ,אריה כץ
7100 ,אשקלון ,1120 ,אשדוד
7100 ,אשקלון ,1075 ,אשכולי פז
7100 ,אשקלון ,795 ,אשכולית
7100 ,אשקלון ,9000 ,אשקלון
7100 ,אשקלון ,553 ,אשר
7100 ,אשקלון ,1127 ,אשתאול
7100 ,אשקלון ,1139 ,אתם
7100 ,אשקלון ,821 ,בדולח
7100 ,אשקלון ,1117 ,בהט
7100 ,אשקלון ,658 ,בוטראשווילי אהרון
7100 ,אשקלון ,559 ,בועז
7100 ,אשקלון ,555 ,בוקרה רחמים
7100 ,אשקלון ,462 ,בורוכוב
7100 ,אשקלון ,632 ,ביאליק
7100 ,אשקלון ,829 ,ביכורי ענבים
7100 ,אשקלון ,677 ,ביל"ו
7100 ,אשקלון ,564 ,בית אבות
7100 ,אשקלון ,1121 ,בית אל
7100 ,אשקלון ,1314 ,בית הבאר
7100 ,אשקלון ,476 ,בית המשפט
7100 ,אשקלון ,584 ,בית השואבה
7100 ,אשקלון ,634 ,בית זולוטוב חדש
7100 ,אשקלון ,633 ,בית זולוטוב ישן
7100 ,אשקלון ,932 ,בית"ר
7100 ,אשקלון ,989 ,בלסבלג אברהם
7100 ,אשקלון ,900 ,בלפור
7100 ,אשקלון ,884 ,בן אליעזר אריה
7100 ,אשקלון ,941 ,בן דוד
7100 ,אשקלון ,650 ,בן זומא
7100 ,אשקלון ,960 ,בן יהודה אליעזר
7100 ,אשקלון ,669 ,בן עוזאי
7100 ,אשקלון ,646 ,בן עטר
7100 ,אשקלון ,826 ,בן עמר
7100 ,אשקלון ,375 ,בן צבי יצחק
7100 ,אשקלון ,651 ,בן שטח
7100 ,אשקלון ,725 ,בני זמירו
7100 ,אשקלון ,933 ,בני עקיבא
7100 ,אשקלון ,552 ,בנימין
7100 ,אשקלון ,610 ,בעל שם טוב
7100 ,אשקלון ,265 ,בקעת סיירים
7100 ,אשקלון ,266 ,בקעת רימון
7100 ,אשקלון ,453 ,בר יוחאי
7100 ,אשקלון ,394 ,בר כוכבא
7100 ,אשקלון ,1133 ,ברבור
7100 ,אשקלון ,635 ,ברגסון
7100 ,אשקלון ,648 ,ברגר דוד
7100 ,אשקלון ,591 ,ברוריה
7100 ,אשקלון ,668 ,ברטנורא
7100 ,אשקלון ,376 ,ברייר דב
7100 ,אשקלון ,273 ,בריכת המשושים
7100 ,אשקלון ,272 ,בריכת רם
7100 ,אשקלון ,1233 ,בריק
7100 ,אשקלון ,815 ,ברנשטיין פרץ
7100 ,אשקלון ,393 ,ברק
7100 ,אשקלון ,820 ,ברקת
7100 ,אשקלון ,1218 ,בשומת
7100 ,אשקלון ,827 ,בת גלים
7100 ,אשקלון ,751 ,בת חן
7100 ,אשקלון ,6500 ,בתי ברמן
7100 ,אשקלון ,6501 ,בתי לוינסון
7100 ,אשקלון ,6502 ,בתי רסקו
7100 ,אשקלון ,998 ,ג'ו וסטנלי כהן
7100 ,אשקלון ,1122 ,גבע
7100 ,אשקלון ,465 ,גבעון
7100 ,אשקלון ,348 ,גבעת המים
7100 ,אשקלון ,6518 ,גבעת הפרחים
7100 ,אשקלון ,6516 ,גבעת ציון
7100 ,אשקלון ,382 ,גבעתי
7100 ,אשקלון ,550 ,גד
7100 ,אשקלון ,645 ,גדוד הפורצים
7100 ,אשקלון ,6048 ,גדוד עוז שבעים ושבע
7100 ,אשקלון ,539 ,גדעון
7100 ,אשקלון ,391 ,גדעון בן יואש
7100 ,אשקלון ,1202 ,גובר רבקה
7100 ,אשקלון ,386 ,גולני
7100 ,אשקלון ,1305 ,גומא
7100 ,אשקלון ,473 ,גורדון
7100 ,אשקלון ,364 ,גורן יונה
7100 ,אשקלון ,412 ,גוש 1250
7100 ,אשקלון ,409 ,גוש 1252
7100 ,אשקלון ,405 ,גוש 1243
7100 ,אשקלון ,411 ,גוש 1253
7100 ,אשקלון ,415 ,גוש 1234
7100 ,אשקלון ,401 ,גוש 1244
7100 ,אשקלון ,414 ,גוש 1254
7100 ,אשקלון ,429 ,גוש 1246
7100 ,אשקלון ,410 ,גוש 1257
7100 ,אשקלון ,408 ,גוש 1218
7100 ,אשקלון ,417 ,גוש 1228
7100 ,אשקלון ,438 ,גוש 1248
7100 ,אשקלון ,302 ,גוש עציון
7100 ,אשקלון ,1003 ,גושן
7100 ,אשקלון ,1182 ,גילה
7100 ,אשקלון ,1005 ,גינוסר
7100 ,אשקלון ,448 ,גלמן פולינה
7100 ,אשקלון ,1007 ,גלעד
7100 ,אשקלון ,1019 ,גמלא
7100 ,אשקלון ,6517 ,גן הורדים
7100 ,אשקלון ,6512 ,גני ברנע
7100 ,אשקלון ,1097 ,גרופית
7100 ,אשקלון ,605 ,גרופר יעקב
7100 ,אשקלון ,711 ,גרשון
7100 ,אשקלון ,980 ,גשר החיים
7100 ,אשקלון ,463 ,ד"ר בוטוויניק מיכאל
7100 ,אשקלון ,1129 ,ד"ר עדי רן כהן
7100 ,אשקלון ,526 ,דבורה הנביאה
7100 ,אשקלון ,731 ,דביר
7100 ,אשקלון ,1069 ,דובב
7100 ,אשקלון ,1063 ,דוגית
7100 ,אשקלון ,918 ,דוד אריאל
7100 ,אשקלון ,563 ,דוד חג'ג'
7100 ,אשקלון ,752 ,דולב
7100 ,אשקלון ,253 ,דולפין
7100 ,אשקלון ,1312 ,דורה
7100 ,אשקלון ,1221 ,דוריף
7100 ,אשקלון ,1046 ,דותן
7100 ,אשקלון ,604 ,דיבולט
7100 ,אשקלון ,986 ,דיזראלי
7100 ,אשקלון ,346 ,דיין משה
7100 ,אשקלון ,1180 ,דיצה
7100 ,אשקלון ,759 ,דליות
7100 ,אשקלון ,1203 ,דמארי שושנה
7100 ,אשקלון ,548 ,דן
7100 ,אשקלון ,1119 ,דן שומרון
7100 ,אשקלון ,524 ,דניאל
7100 ,אשקלון ,753 ,דפנה
7100 ,אשקלון ,1307 ,דרדר
7100 ,אשקלון ,1210 ,דרך ארץ
7100 ,אשקלון ,845 ,דרך היין
7100 ,אשקלון ,1211 ,דרך הים
7100 ,אשקלון ,762 ,דרכי אי"ש
7100 ,אשקלון ,799 ,דרכי נועם
7100 ,אשקלון ,486 ,האבץ
7100 ,אשקלון ,850 ,האגוז
7100 ,אשקלון ,1164 ,האדווה
7100 ,אשקלון ,730 ,האומן
7100 ,אשקלון ,1171 ,האומץ
7100 ,אשקלון ,741 ,האופה
7100 ,אשקלון ,812 ,האופן
7100 ,אשקלון ,1155 ,האוקיינוס
7100 ,אשקלון ,742 ,האורג
7100 ,אשקלון ,488 ,האורים
7100 ,אשקלון ,335 ,האילן
7100 ,אשקלון ,356 ,האלון
7100 ,אשקלון ,594 ,האמהות
7100 ,אשקלון ,1160 ,האפיק
7100 ,אשקלון ,611 ,האר"י
7100 ,אשקלון ,305 ,הארז
7100 ,אשקלון ,1162 ,האשד
7100 ,אשקלון ,332 ,האשוח
7100 ,אשקלון ,314 ,האשל
7100 ,אשקלון ,368 ,האתרוג
7100 ,אשקלון ,579 ,הבדיל
7100 ,אשקלון ,840 ,הבוסתן
7100 ,אשקלון ,1231 ,הבוצרים
7100 ,אשקלון ,572 ,הבנאי
7100 ,אשקלון ,816 ,הבנים
7100 ,אשקלון ,750 ,הבציר
7100 ,אשקלון ,352 ,הברוש
7100 ,אשקלון ,575 ,הברזל
7100 ,אשקלון ,657 ,הבשן
7100 ,אשקלון ,1156 ,הגאות
7100 ,אשקלון ,1070 ,הגבורה
7100 ,אשקלון ,479 ,הגביש
7100 ,אשקלון ,467 ,הגדוד העברי
7100 ,אשקלון ,469 ,הגדנ"ע
7100 ,אשקלון ,1045 ,הגולן
7100 ,אשקלון ,616 ,הגולשים
7100 ,אשקלון ,811 ,הגלגל
7100 ,אשקלון ,931 ,הגליל
7100 ,אשקלון ,877 ,הגלשן
7100 ,אשקלון ,344 ,הגפן
7100 ,אשקלון ,580 ,הגר"א
7100 ,אשקלון ,1229 ,הגתות
7100 ,אשקלון ,609 ,הדגל
7100 ,אשקלון ,849 ,הדובדבן
7100 ,אשקלון ,617 ,הדייגים
7100 ,אשקלון ,679 ,הדישון
7100 ,אשקלון ,342 ,הדקל
7100 ,אשקלון ,321 ,הדרור
7100 ,אשקלון ,468 ,ההגנה
7100 ,אשקלון ,259 ,ההדס
7100 ,אשקלון ,320 ,ההדרים
7100 ,אשקלון ,630 ,ההסתדרות
7100 ,אשקלון ,327 ,ההרדוף
7100 ,אשקלון ,308 ,הוורדים
7100 ,אשקלון ,899 ,הולצברג שמחה
7100 ,אשקלון ,518 ,הושע
7100 ,אשקלון ,924 ,הזגג
7100 ,אשקלון ,328 ,הזית
7100 ,אשקלון ,495 ,הזכוכית
7100 ,אשקלון ,341 ,הזמיר
7100 ,אשקלון ,872 ,הזריחה
7100 ,אשקלון ,309 ,החבצלת
7100 ,אשקלון ,638 ,החובלים
7100 ,אשקלון ,1004 ,החולה
7100 ,אשקלון ,369 ,החיטה
7100 ,אשקלון ,470 ,החלוץ
7100 ,אשקלון ,334 ,החרוב
7100 ,אשקלון ,588 ,החרט
7100 ,אשקלון ,749 ,החרש
7100 ,אשקלון ,667 ,החשמונאים
7100 ,אשקלון ,732 ,החשמל
7100 ,אשקלון ,926 ,הטוחן
7100 ,אשקלון ,381 ,הטייסים
7100 ,אשקלון ,1157 ,היובל
7100 ,אשקלון ,1213 ,היוצרים
7100 ,אשקלון ,1225 ,היינן
7100 ,אשקלון ,1165 ,הים התיכון
7100 ,אשקלון ,499 ,היצול
7100 ,אשקלון ,755 ,היקב
7100 ,אשקלון ,684 ,הירדן
7100 ,אשקלון ,683 ,הירמוך
7100 ,אשקלון ,671 ,הירקון
7100 ,אשקלון ,857 ,הכדים
7100 ,אשקלון ,1230 ,הכורמים
7100 ,אשקלון ,810 ,הכישור
7100 ,אשקלון ,360 ,הכלנית
7100 ,אשקלון ,464 ,הכרם
7100 ,אשקלון ,373 ,הלוז
7100 ,אשקלון ,1000 ,הלוח העברי
7100 ,אשקלון ,1047 ,הלוחם
7100 ,אשקלון ,367 ,הלולב
7100 ,אשקלון ,565 ,הלילך
7100 ,אשקלון ,458 ,הלל
7100 ,אשקלון ,1031 ,המופת
7100 ,אשקלון ,343 ,המורה מאיר
7100 ,אשקלון ,498 ,המחוגה
7100 ,אשקלון ,948 ,המחרשה
7100 ,אשקלון ,1215 ,המיל השלישי
7100 ,אשקלון ,947 ,המלגזה
7100 ,אשקלון ,618 ,המלחים
7100 ,אשקלון ,587 ,המסגר
7100 ,אשקלון ,494 ,המסלול
7100 ,אשקלון ,1161 ,המעיין
7100 ,אשקלון ,1158 ,המפל
7100 ,אשקלון ,914 ,המציל היהודי
7100 ,אשקלון ,500 ,המרכבה
7100 ,אשקלון ,442 ,המשנה
7100 ,אשקלון ,744 ,המתכנן
7100 ,אשקלון ,992 ,המתמיד
7100 ,אשקלון ,517 ,הנביאים
7100 ,אשקלון ,1163 ,הנביעות
7100 ,אשקלון ,1072 ,הנגב
7100 ,אשקלון ,589 ,הנגר
7100 ,אשקלון ,502 ,הנוטרים
7100 ,אשקלון ,934 ,הנוער העובד
7100 ,אשקלון ,659 ,הנחשול
7100 ,אשקלון ,1106 ,הנמל
7100 ,אשקלון ,745 ,הנפח
7100 ,אשקלון ,359 ,הנרקיס
7100 ,אשקלון ,319 ,הנשיא
7100 ,אשקלון ,1315 ,הסביל
7100 ,אשקלון ,603 ,הסוחר
7100 ,אשקלון ,838 ,הסוכה
7100 ,אשקלון ,935 ,הסוכנות היהודית
7100 ,אשקלון ,443 ,הסופרים היהודים
7100 ,אשקלון ,366 ,הסנה
7100 ,אשקלון ,322 ,הסנונית
7100 ,אשקלון ,623 ,הספנים
7100 ,אשקלון ,946 ,הסרגל
7100 ,אשקלון ,440 ,העבודה
7100 ,אשקלון ,949 ,העגלה
7100 ,אשקלון ,982 ,העוגן
7100 ,אשקלון ,1091 ,העונות
7100 ,אשקלון ,576 ,העופרת
7100 ,אשקלון ,425 ,העליה
7100 ,אשקלון ,746 ,העמל
7100 ,אשקלון ,756 ,הענב
7100 ,אשקלון ,301 ,העצמאות
7100 ,אשקלון ,358 ,הערמון
7100 ,אשקלון ,1159 ,הפלג
7100 ,אשקלון ,503 ,הפלדה
7100 ,אשקלון ,482 ,הפלטינה
7100 ,אשקלון ,471 ,הפלמ"ח
7100 ,אשקלון ,493 ,הפנינים
7100 ,אשקלון ,361 ,הפרג
7100 ,אשקלון ,1076 ,הפרדס
7100 ,אשקלון ,331 ,הצאלה
7100 ,אשקלון ,407 ,הצבר
7100 ,אשקלון ,876 ,הצדפים
7100 ,אשקלון ,936 ,הצופים
7100 ,אשקלון ,878 ,הצוק
7100 ,אשקלון ,747 ,הצורף
7100 ,אשקלון ,383 ,הצנחנים
7100 ,אשקלון ,357 ,הצפצפה
7100 ,אשקלון ,925 ,הקדר
7100 ,אשקלון ,542 ,הקוממיות
7100 ,אשקלון ,873 ,הקונכייה
7100 ,אשקלון ,365 ,הקיקיון
7100 ,אשקלון ,885 ,הר ארבל
7100 ,אשקלון ,889 ,הר חצור
7100 ,אשקלון ,913 ,הר חרמון
7100 ,אשקלון ,886 ,הר כנען
7100 ,אשקלון ,888 ,הר מירון
7100 ,אשקלון ,887 ,הר תבור
7100 ,אשקלון ,385 ,הראל
7100 ,אשקלון ,991 ,הרב אונטרמן
7100 ,אשקלון ,764 ,הרב בוזגלו דוד
7100 ,אשקלון ,1193 ,הרב בן דיוואן עמרם
7100 ,אשקלון ,1191 ,הרב בן חמו יעקב
7100 ,אשקלון ,743 ,הרב ברוך אברהמי
7100 ,אשקלון ,444 ,הרב גניש חואטו
7100 ,אשקלון ,596 ,הרב דוד אברג'ל
7100 ,אשקלון ,966 ,הרב דוד קדוש
7100 ,אשקלון ,450 ,הרב הרצוג
7100 ,אשקלון ,733 ,הרב חי טייב
7100 ,אשקלון ,1130 ,הרב חיים שושנה
7100 ,אשקלון ,672 ,הרב טולדנו
7100 ,אשקלון ,1252 ,הרב יוסף חיים בלוי
7100 ,אשקלון ,1251 ,הרב יוסף שרביט
7100 ,אשקלון ,1152 ,הרב יורם אברג'ל
7100 ,אשקלון ,428 ,הרב יצחק ברזני
7100 ,אשקלון ,445 ,הרב כהנא חיים
7100 ,אשקלון ,737 ,הרב כלפון משה הכהן
7100 ,אשקלון ,761 ,הרב לוין אריה
7100 ,אשקלון ,1255 ,הרב מאיר יהודה גץ
7100 ,אשקלון ,681 ,הרב מימון
7100 ,אשקלון ,990 ,הרב מנצור בן שמעון
7100 ,אשקלון ,1301 ,הרב מצליח מאזוז
7100 ,אשקלון ,719 ,הרב מרדכי אליהו
7100 ,אשקלון ,735 ,הרב משאש שלום
7100 ,אשקלון ,952 ,הרב משה צבי נריה
7100 ,אשקלון ,996 ,הרב נבון גד
7100 ,אשקלון ,374 ,הרב נורוק
7100 ,אשקלון ,254 ,הרב נסים יצחק
7100 ,אשקלון ,724 ,הרב סבג ברוך
7100 ,אשקלון ,556 ,הרב ספו
7100 ,אשקלון ,629 ,הרב עוזיאל
7100 ,אשקלון ,736 ,הרב ערוסי יחיא
7100 ,אשקלון ,422 ,הרב פינטו
7100 ,אשקלון ,557 ,הרב פרג'ון
7100 ,אשקלון ,729 ,הרב צבי יהודה קוק
7100 ,אשקלון ,942 ,הרב קארו יוסף
7100 ,אשקלון ,995 ,הרב קוק
7100 ,אשקלון ,1192 ,הרב רקח יעקב
7100 ,אשקלון ,478 ,הרב שאול הכהן
7100 ,אשקלון ,897 ,הרב שלמה גורן
7100 ,אשקלון ,1300 ,הרבי מלובביץ'
7100 ,אשקלון ,955 ,הרבנית ברכה קפאח
7100 ,אשקלון ,940 ,הרי"ף
7100 ,אשקלון ,317 ,הרימון
7100 ,אשקלון ,566 ,הרכבת
7100 ,אשקלון ,927 ,הרפד
7100 ,אשקלון ,435 ,הרצל
7100 ,אשקלון ,371 ,הרקפת
7100 ,אשקלון ,570 ,הרתך
7100 ,אשקלון ,554 ,השבטים
7100 ,אשקלון ,858 ,השוטר
7100 ,אשקלון ,797 ,השולמית
7100 ,אשקלון ,937 ,השומר הצעיר
7100 ,אשקלון ,1038 ,השומרון
7100 ,אשקלון ,1107 ,השונית
7100 ,אשקלון ,538 ,השופטים
7100 ,אשקלון ,307 ,השושנים
7100 ,אשקלון ,655 ,השחיינים
7100 ,אשקלון ,329 ,השיטה
7100 ,אשקלון ,362 ,השייטת
7100 ,אשקלון ,370 ,השעורה
7100 ,אשקלון ,1067 ,השפלה
7100 ,אשקלון ,427 ,השקד
7100 ,אשקלון ,871 ,השקיעה
7100 ,אשקלון ,315 ,השקמה
7100 ,אשקלון ,1111 ,השרון
7100 ,אשקלון ,851 ,השריונאים
7100 ,אשקלון ,323 ,התור
7100 ,אשקלון ,355 ,התותחנים
7100 ,אשקלון ,507 ,התחיה
7100 ,אשקלון ,446 ,התלמוד
7100 ,אשקלון ,312 ,התמר
7100 ,אשקלון ,1077 ,התפוז
7100 ,אשקלון ,333 ,התפוח
7100 ,אשקלון ,906 ,התקווה
7100 ,אשקלון ,984 ,ובר משה
7100 ,אשקלון ,727 ,וייס יעקב
7100 ,אשקלון ,898 ,ויצמן חיים
7100 ,אשקלון ,1190 ,וקסנר בלה
7100 ,אשקלון ,796 ,ורון יורם
7100 ,אשקלון ,643 ,ז'בוטינסקי
7100 ,אשקלון ,765 ,זאבי רחבעם
7100 ,אשקלון ,544 ,זבולון
7100 ,אשקלון ,874 ,זהבון
7100 ,אשקלון ,313 ,זונאבנד יחזקאל
7100 ,אשקלון ,836 ,זכרון יעקב
7100 ,אשקלון ,530 ,זכריה
7100 ,אשקלון ,757 ,זמורה
7100 ,אשקלון ,702 ,זרובבל
7100 ,אשקלון ,1071 ,זרעית
7100 ,אשקלון ,847 ,ח"ן
7100 ,אשקלון ,583 ,חב"ד
7100 ,אשקלון ,698 ,חביב אבשלום
7100 ,אשקלון ,531 ,חבקוק
7100 ,אשקלון ,515 ,חגי
7100 ,אשקלון ,1183 ,חדווה
7100 ,אשקלון ,644 ,חובבי ציון
7100 ,אשקלון ,804 ,חוגלה
7100 ,אשקלון ,1078 ,חודשי השנה
7100 ,אשקלון ,1056 ,חוחית
7100 ,אשקלון ,574 ,חולדה הנביאה
7100 ,אשקלון ,1322 ,חומה ומגדל
7100 ,אשקלון ,663 ,חוני המעגל
7100 ,אשקלון ,1066 ,חופית
7100 ,אשקלון ,748 ,חוצות היוצר
7100 ,אשקלון ,6521 ,חות רסקו
7100 ,אשקלון ,722 ,חזון איש
7100 ,אשקלון ,1299 ,חזון עובדיה
7100 ,אשקלון ,330 ,חזקיהו
7100 ,אשקלון ,354 ,חטיבת עציוני
7100 ,אשקלון ,854 ,חי"ל
7100 ,אשקלון ,628 ,חיד"א
7100 ,אשקלון ,970 ,חייבי אהרון
7100 ,אשקלון ,739 ,חיים אלישע
7100 ,אשקלון ,614 ,חיל הים
7100 ,אשקלון ,908 ,חכימי יצחק
7100 ,אשקלון ,1219 ,חכליל
7100 ,אשקלון ,689 ,חכם שמעון
7100 ,אשקלון ,738 ,חכמי איטליה
7100 ,אשקלון ,1092 ,חלמונית
7100 ,אשקלון ,1214 ,חמרא
7100 ,אשקלון ,571 ,חנה ושבעת בניה
7100 ,אשקלון ,1049 ,חניתה
7100 ,אשקלון ,490 ,חננאל
7100 ,אשקלון ,705 ,חנניה
7100 ,אשקלון ,1057 ,חסידה
7100 ,אשקלון ,599 ,חפני
7100 ,אשקלון ,1079 ,חצב
7100 ,אשקלון ,1096 ,חצבה
7100 ,אשקלון ,1318 ,חקלאי
7100 ,אשקלון ,1080 ,חרצית
7100 ,אשקלון ,1138 ,חשמונה
7100 ,אשקלון ,607 ,חת"ם סופר
7100 ,אשקלון ,678 ,חתן סופר
7100 ,אשקלון ,726 ,טבועי אגוז
7100 ,אשקלון ,710 ,טבועי סטרומה
7100 ,אשקלון ,1132 ,טבלן
7100 ,אשקלון ,1020 ,טבריה
7100 ,אשקלון ,1013 ,טבת
7100 ,אשקלון ,954 ,טדי קולק
7100 ,אשקלון ,1114 ,טופז
7100 ,אשקלון ,1118 ,טורקיז
7100 ,אשקלון ,921 ,טיילת שמשון ודלילה
7100 ,אשקלון ,1201 ,טיכו אנה
7100 ,אשקלון ,1065 ,טירן
7100 ,אשקלון ,817 ,טרומפלדור
7100 ,אשקלון ,619 ,טריווש יוסף אליהו
7100 ,אשקלון ,631 ,טשרניחובסקי
7100 ,אשקלון ,967 ,י"ח משפחות
7100 ,אשקלון ,622 ,י"ל פרץ
7100 ,אשקלון ,582 ,יבנה
7100 ,אשקלון ,1185 ,ידידות
7100 ,אשקלון ,447 ,יהדות אמריקה הלטנ
7100 ,אשקלון ,1060 ,יהדות בריטניה
7100 ,אשקלון ,1062 ,יהדות ספרד
7100 ,אשקלון ,595 ,יהואש
7100 ,אשקלון ,608 ,יהודה הלוי
7100 ,אשקלון ,956 ,יהודה עמיחי
7100 ,אשקלון ,573 ,יהודית
7100 ,אשקלון ,551 ,יהודית שיינין
7100 ,אשקלון ,528 ,יהושע
7100 ,אשקלון ,339 ,יהושפט
7100 ,אשקלון ,778 ,יהלום
7100 ,אשקלון ,715 ,יואב
7100 ,אשקלון ,529 ,יואל
7100 ,אשקלון ,1009 ,יודפת
7100 ,אשקלון ,310 ,יוהנסבורג
7100 ,אשקלון ,1253 ,יוחנן
7100 ,אשקלון ,1074 ,יוטבתה
7100 ,אשקלון ,577 ,יוכבד
7100 ,אשקלון ,692 ,יוליש משה
7100 ,אשקלון ,508 ,יונה
7100 ,אשקלון ,641 ,יוספטל
7100 ,אשקלון ,626 ,יורדי הים
7100 ,אשקלון ,802 ,יותם
7100 ,אשקלון ,968 ,יחזקאל לוין
7100 ,אשקלון ,905 ,יחיעם
7100 ,אשקלון ,713 ,יכין
7100 ,אשקלון ,547 ,ילין
7100 ,אשקלון ,1205 ,ילן-שטקליס מרים
7100 ,אשקלון ,590 ,ילתא
7100 ,אשקלון ,1102 ,ים המלח
7100 ,אשקלון ,1104 ,ים סוף
7100 ,אשקלון ,1042 ,ימית
7100 ,אשקלון ,316 ,ינאי המלך
7100 ,אשקלון ,581 ,ינוקא
7100 ,אשקלון ,501 ,יסודי עזרא
7100 ,אשקלון ,1081 ,יסמין
7100 ,אשקלון ,1137 ,יסעור
7100 ,אשקלון ,520 ,יעל
7100 ,אשקלון ,664 ,יעקב נוי
7100 ,אשקלון ,961 ,יעקב עמיצור
7100 ,אשקלון ,1223 ,יערה
7100 ,אשקלון ,306 ,יפה נוף
7100 ,אשקלון ,586 ,יפתח
7100 ,אשקלון ,392 ,יפתח הגלעדי
7100 ,אשקלון ,769 ,יצחק בנימין
7100 ,אשקלון ,420 ,יצחק שמיר
7100 ,אשקלון ,466 ,יריחו
7100 ,אשקלון ,527 ,ירמיהו
7100 ,אשקלון ,978 ,ישא ברכה
7100 ,אשקלון ,714 ,ישי
7100 ,אשקלון ,532 ,ישעיהו
7100 ,אשקלון ,959 ,ישעיהו ארנפלד
7100 ,אשקלון ,777 ,ישפה
7100 ,אשקלון ,543 ,יששכר
7100 ,אשקלון ,1321 ,כ"ג יורדי הסירה
7100 ,אשקלון ,767 ,כ"ט בנובמבר
7100 ,אשקלון ,909 ,כהן שלמה
7100 ,אשקלון ,6525 ,כוכב הצפון
7100 ,אשקלון ,255 ,כוכב ים
7100 ,אשקלון ,670 ,כורש
7100 ,אשקלון ,6046 ,כיכר אליעזר עמר
7100 ,אשקלון ,6033 ,כיכר אמונה
7100 ,אשקלון ,6020 ,כיכר אמסלם שלום
7100 ,אשקלון ,6028 ,כיכר אריס חיים
7100 ,אשקלון ,6049 ,כיכר בכור כחלון
7100 ,אשקלון ,6001 ,כיכר ברק
7100 ,אשקלון ,6002 ,כיכר גולומב
7100 ,אשקלון ,6031 ,כיכר גוש קטיף
7100 ,אשקלון ,6037 ,כיכר גרוס מרדכי
7100 ,אשקלון ,6041 ,כיכר ד"ר מיכאל לביא
7100 ,אשקלון ,6039 ,כיכר דוד אהרוני
7100 ,אשקלון ,6003 ,כיכר דוד המלך
7100 ,אשקלון ,6054 ,כיכר דניאל רבי
7100 ,אשקלון ,6022 ,כיכר דקטור מקס
7100 ,אשקלון ,6057 ,כיכר הליקוודטורים
7100 ,אשקלון ,6035 ,כיכר המשטרה
7100 ,אשקלון ,6018 ,כיכר הנרקיס
7100 ,אשקלון ,6006 ,כיכר העצמאות
7100 ,אשקלון ,6014 ,כיכר הרבי מלובביץ
7100 ,אשקלון ,6017 ,כיכר הרופא
7100 ,אשקלון ,6051 ,כיכר ויטקה שור
7100 ,אשקלון ,6032 ,כיכר ויצ"ו
7100 ,אשקלון ,6024 ,כיכר זנזורי שמעון
7100 ,אשקלון ,6030 ,כיכר חזקיהו
7100 ,אשקלון ,6040 ,כיכר חיים אביבי
7100 ,אשקלון ,6023 ,כיכר חיימסון
7100 ,אשקלון ,6053 ,כיכר יעקב יעקבי
7100 ,אשקלון ,6055 ,כיכר יצחק חלפון
7100 ,אשקלון ,6007 ,כיכר לוי
7100 ,אשקלון ,6036 ,כיכר לח"י
7100 ,אשקלון ,6045 ,כיכר מוחי בוקרה
7100 ,אשקלון ,6038 ,כיכר מיכה עמיר
7100 ,אשקלון ,6021 ,כיכר מיכואלס שלמה
7100 ,אשקלון ,6050 ,כיכר מינה כהן
7100 ,אשקלון ,6047 ,כיכר ממלכת הכהנים
7100 ,אשקלון ,6008 ,כיכר מנגר
7100 ,אשקלון ,6005 ,כיכר מרגלית משה
7100 ,אשקלון ,6025 ,כיכר ניו יורק
7100 ,אשקלון ,6052 ,כיכר ניסים חגאי
7100 ,אשקלון ,6034 ,כיכר נעמ"ת
7100 ,אשקלון ,6015 ,כיכר סטרייג' הנרי
7100 ,אשקלון ,6009 ,כיכר סמואל
7100 ,אשקלון ,6010 ,כיכר עתניאל
7100 ,אשקלון ,6044 ,כיכר פנחס חסן
7100 ,אשקלון ,6029 ,כיכר רוטרי
7100 ,אשקלון ,6012 ,כיכר שאול המלך
7100 ,אשקלון ,6026 ,כיכר שושן
7100 ,אשקלון ,6027 ,כיכר שושנה יוסף
7100 ,אשקלון ,6013 ,כיכר שלמה המלך
7100 ,אשקלון ,6042 ,כיכר שלמה חוגי
7100 ,אשקלון ,6016 ,כיכר שרמן ארצ'י
7100 ,אשקלון ,969 ,ככר פעילי גבעת ציון
7100 ,אשקלון ,1022 ,כל ישראל חברים
7100 ,אשקלון ,324 ,כליל החורש
7100 ,אשקלון ,1021 ,כנפי נשרים
7100 ,אשקלון ,1101 ,כנרת
7100 ,אשקלון ,1014 ,כסלו
7100 ,אשקלון ,6557 ,כפר גולף
7100 ,אשקלון ,1178 ,כפר דרום
7100 ,אשקלון ,351 ,כפר הנופש הצרפתי
7100 ,אשקלון ,855 ,כפר הנופש לחיילים
7100 ,אשקלון ,6558 ,כפרי הנופש
7100 ,אשקלון ,477 ,כצנלסון
7100 ,אשקלון ,1082 ,כרכום
7100 ,אשקלון ,6532 ,כרמי ציון
7100 ,אשקלון ,923 ,כרמל
7100 ,אשקלון ,636 ,כתב סופר
7100 ,אשקלון ,1052 ,לביאה
7100 ,אשקלון ,1149 ,לבנה
7100 ,אשקלון ,1234 ,לגין
7100 ,אשקלון ,533 ,לוחמי הגטאות
7100 ,אשקלון ,758 ,לוטוס
7100 ,אשקלון ,256 ,לויתן
7100 ,אשקלון ,325 ,לוע הארי
7100 ,אשקלון ,1204 ,ליבוביץ נחמה
7100 ,אשקלון ,973 ,לייפר- מילר רחל
7100 ,אשקלון ,1023 ,לכיש
7100 ,אשקלון ,436 ,לנדאו לב
7100 ,אשקלון ,825 ,לשם
7100 ,אשקלון ,1036 ,מאדים
7100 ,אשקלון ,1303 ,מבוע
7100 ,אשקלון ,768 ,מבצע אסף
7100 ,אשקלון ,766 ,מבצע חורב
7100 ,אשקלון ,353 ,מבצע חירם
7100 ,אשקלון ,1024 ,מבצע משה
7100 ,אשקלון ,1236 ,מבשלה
7100 ,אשקלון ,6520 ,מגדל
7100 ,אשקלון ,615 ,מגדלור
7100 ,אשקלון ,1025 ,מגידו
7100 ,אשקלון ,1143 ,מדבר סין
7100 ,אשקלון ,336 ,מודיעין
7100 ,אשקלון ,1146 ,מוסרות
7100 ,אשקלון ,976 ,מועדים לשמחה
7100 ,אשקלון ,728 ,מור
7100 ,אשקלון ,712 ,מור משולם
7100 ,אשקלון ,1176 ,מורג
7100 ,אשקלון ,1235 ,מזקקה
7100 ,אשקלון ,806 ,מח"ל
7100 ,אשקלון ,1123 ,מחניים
7100 ,אשקלון ,1195 ,מיטיב בני
7100 ,אשקלון ,525 ,מיכה
7100 ,אשקלון ,654 ,מילרוד אליעזר
7100 ,אשקלון ,703 ,מישאל
7100 ,אשקלון ,1098 ,מיתר
7100 ,אשקלון ,257 ,מכמורת
7100 ,אשקלון ,509 ,מלאכי
7100 ,אשקלון ,834 ,מלון החוף
7100 ,אשקלון ,326 ,מלכי ישראל
7100 ,אשקלון ,1112 ,מלכיט
7100 ,אשקלון ,789 ,מנגו
7100 ,אשקלון ,620 ,מנדלי מוכר ספרים
7100 ,אשקלון ,505 ,מנשה
7100 ,אשקלון ,1145 ,מסעי בני ישראל
7100 ,אשקלון ,363 ,מעגן
7100 ,אשקלון ,625 ,מעון אקדמאים
7100 ,אשקלון ,696 ,מעון קשישים
7100 ,אשקלון ,860 ,מעלה הגת
7100 ,אשקלון ,911 ,מעלות אשר
7100 ,אשקלון ,274 ,מפל התנור
7100 ,אשקלון ,258 ,מפרץ
7100 ,אשקלון ,1168 ,מפרשית
7100 ,אשקלון ,661 ,מצדה
7100 ,אשקלון ,6513 ,מצפה ברנע
7100 ,אשקלון ,1142 ,מקהלות
7100 ,אשקלון ,977 ,מקור הברכה
7100 ,אשקלון ,537 ,מרגלית
7100 ,אשקלון ,965 ,מרדכי ברששת
7100 ,אשקלון ,1015 ,מרחשוון
7100 ,אשקלון ,953 ,מרטי דיויס
7100 ,אשקלון ,912 ,מרידור יעקב
7100 ,אשקלון ,521 ,מרים
7100 ,אשקלון ,957 ,מרים בן פורת
7100 ,אשקלון ,1108 ,מרינה
7100 ,אשקלון ,665 ,מרכוס
7100 ,אשקלון ,1100 ,מרכז נפתי
7100 ,אשקלון ,400 ,מרכז קליטה בית קנדה
7100 ,אשקלון ,901 ,מרכז קליטה כלנית
7100 ,אשקלון ,1196 ,מרכז רפואי ברזילי
7100 ,אשקלון ,950 ,משה לנדוי
7100 ,אשקלון ,823 ,משכית
7100 ,אשקלון ,1140 ,מתקה
7100 ,אשקלון ,6540 ,נאות אשקלון
7100 ,אשקלון ,6509 ,נאות ברנע
7100 ,אשקלון ,6519 ,נאות גנים
7100 ,אשקלון ,985 ,נבון אופירה
7100 ,אשקלון ,723 ,נהרדעא
7100 ,אשקלון ,1041 ,נוגה
7100 ,אשקלון ,6553 ,נוה אילן
7100 ,אשקלון ,6552 ,נוה הדקלים
7100 ,אשקלון ,6551 ,נוה ההדרים
7100 ,אשקלון ,6514 ,נווה אלונים
7100 ,אשקלון ,1308 ,נווה מדבר
7100 ,אשקלון ,1059 ,נווה שלום
7100 ,אשקלון ,803 ,נועה
7100 ,אשקלון ,1310 ,נופר
7100 ,אשקלון ,892 ,נורדאו
7100 ,אשקלון ,1083 ,נורית
7100 ,אשקלון ,474 ,נח"ל
7100 ,אשקלון ,497 ,נחושתן
7100 ,אשקלון ,279 ,נחל דרגות
7100 ,אשקלון ,280 ,נחל חלילים
7100 ,אשקלון ,917 ,נחל לוטם
7100 ,אשקלון ,880 ,נחל מורן
7100 ,אשקלון ,930 ,נחל נטפים
7100 ,אשקלון ,1103 ,נחל נעמן
7100 ,אשקלון ,919 ,נחל נקרות
7100 ,אשקלון ,882 ,נחל נריה
7100 ,אשקלון ,1128 ,נחל עירון
7100 ,אשקלון ,278 ,נחל עמוד
7100 ,אשקלון ,269 ,נחל פרת
7100 ,אשקלון ,281 ,נחל צאלים
7100 ,אשקלון ,277 ,נחל צלמון
7100 ,אשקלון ,881 ,נחל קשת
7100 ,אשקלון ,879 ,נחל שני
7100 ,אשקלון ,945 ,נחל שפירים
7100 ,אשקלון ,271 ,נחל תנינים
7100 ,אשקלון ,1232 ,נחלה
7100 ,אשקלון ,1058 ,נחליאלי
7100 ,אשקלון ,511 ,נחמיה
7100 ,אשקלון ,396 ,נחשון
7100 ,אשקלון ,859 ,נטעים
7100 ,אשקלון ,484 ,ניל"י
7100 ,אשקלון ,6056 ,נינה פינטו אבקסיס
7100 ,אשקלון ,1016 ,ניסן
7100 ,אשקלון ,1175 ,ניסנית
7100 ,אשקלון ,1094 ,ניצנה
7100 ,אשקלון ,691 ,ניצנים
7100 ,אשקלון ,1051 ,נמר
7100 ,אשקלון ,578 ,נעמי
7100 ,אשקלון ,549 ,נפתלי
7100 ,אשקלון ,516 ,נתן הנביא
7100 ,אשקלון ,988 ,נתניהו יונתן
7100 ,אשקלון ,472 ,סאלד הנרייטה
7100 ,אשקלון ,1085 ,סביון
7100 ,אשקלון ,1216 ,סברי מרנן
7100 ,אשקלון ,395 ,סגל צבי
7100 ,אשקלון ,1254 ,סוזי רביץ
7100 ,אשקלון ,1086 ,סחלב
7100 ,אשקלון ,734 ,סטרייג' הנרי
7100 ,אשקלון ,350 ,סיגל
7100 ,אשקלון ,1017 ,סיון
7100 ,אשקלון ,1087 ,סייפן
7100 ,אשקלון ,662 ,סיני
7100 ,אשקלון ,1167 ,סירה
7100 ,אשקלון ,830 ,סלע חיים
7100 ,אשקלון ,1073 ,סלעית
7100 ,אשקלון ,601 ,סמ חמד
7100 ,אשקלון ,380 ,סמ כרמלי
7100 ,אשקלון ,801 ,סמ מילכה
7100 ,אשקלון ,920 ,סמ שמגר
7100 ,אשקלון ,1113 ,סמ תיסלית
7100 ,אשקלון ,822 ,סמ' איילון
7100 ,אשקלון ,828 ,סמדר
7100 ,אשקלון ,642 ,סמולנסקין
7100 ,אשקלון ,251 ,סנפיר
7100 ,אשקלון ,716 ,סנש חנה
7100 ,אשקלון ,939 ,סעדון אילן
7100 ,אשקלון ,461 ,סעדיה גאון
7100 ,אשקלון ,1169 ,ספינה
7100 ,אשקלון ,771 ,ספיר
7100 ,אשקלון ,694 ,עגנון ש"י
7100 ,אשקלון ,1008 ,עדולם
7100 ,אשקלון ,519 ,עובדיה
7100 ,אשקלון ,388 ,עודד
7100 ,אשקלון ,944 ,עוזר אריה
7100 ,אשקלון ,699 ,עולי הגרדום
7100 ,אשקלון ,510 ,עזרא
7100 ,אשקלון ,704 ,עזריה
7100 ,אשקלון ,1316 ,עיינות
7100 ,אשקלון ,6511 ,עין הים
7100 ,אשקלון ,1313 ,עין הנצי"ב
7100 ,אשקלון ,283 ,עין התכלת
7100 ,אשקלון ,276 ,עין כפיר
7100 ,אשקלון ,282 ,עין נטפים
7100 ,אשקלון ,262 ,עין עבדת
7100 ,אשקלון ,264 ,עין עברונה
7100 ,אשקלון ,1309 ,עין עקב
7100 ,אשקלון ,275 ,עין שוקק
7100 ,אשקלון ,263 ,עינות צוקים
7100 ,אשקלון ,1088 ,עירית
7100 ,אשקלון ,706 ,עלי
7100 ,אשקלון ,938 ,עלית הנוער
7100 ,אשקלון ,512 ,עמוס
7100 ,אשקלון ,1026 ,עמיעד
7100 ,אשקלון ,903 ,עמיעוז
7100 ,אשקלון ,1039 ,עמישב
7100 ,אשקלון ,491 ,עמנואל
7100 ,אשקלון ,404 ,עמנואל מורנו
7100 ,אשקלון ,997 ,עמר ג'ו
7100 ,אשקלון ,1319 ,ענבי בר
7100 ,אשקלון ,824 ,ענבר
7100 ,אשקלון ,1227 ,עסיס (ענבים)
7100 ,אשקלון ,1055 ,עפרוני
7100 ,אשקלון ,784 ,עצי פרי
7100 ,אשקלון ,1144 ,ערבות מואב
7100 ,אשקלון ,1028 ,עשרת השבטים
7100 ,אשקלון ,883 ,עתניאל
7100 ,אשקלון ,833 ,פארק אשקלון
7100 ,אשקלון ,776 ,פטדה
7100 ,אשקלון ,597 ,פינחס
7100 ,אשקלון ,674 ,פינס יוסף
7100 ,אשקלון ,902 ,פלוגות
7100 ,אשקלון ,1116 ,פנינת ים
7100 ,אשקלון ,441 ,פסטרנק בוריס
7100 ,אשקלון ,349 ,פעמונית
7100 ,אשקלון ,791 ,פקאן
7100 ,אשקלון ,1029 ,פקיעין
7100 ,אשקלון ,832 ,פרידן יונתן
7100 ,אשקלון ,1089 ,צבעוני
7100 ,אשקלון ,951 ,צדוק דורון
7100 ,אשקלון ,1037 ,צדק
7100 ,אשקלון ,437 ,צה"ל
7100 ,אשקלון ,1179 ,צהלה
7100 ,אשקלון ,1170 ,צוללת
7100 ,אשקלון ,1054 ,צופית
7100 ,אשקלון ,639 ,צור אפרים
7100 ,אשקלון ,904 ,צוריאל
7100 ,אשקלון ,686 ,צין
7100 ,אשקלון ,1061 ,ציפורי
7100 ,אשקלון ,1090 ,ציפורן
7100 ,אשקלון ,399 ,צלופחד
7100 ,אשקלון ,1148 ,צלמונה
7100 ,אשקלון ,1093 ,צלף
7100 ,אשקלון ,1317 ,צלצל
7100 ,אשקלון ,485 ,קאפח אהרון
7100 ,אשקלון ,1032 ,קדומים
7100 ,אשקלון ,907 ,קדושי קהיר
7100 ,אשקלון ,449 ,קול משה
7100 ,אשקלון ,1226 ,קונדיטון
7100 ,אשקלון ,430 ,קופת חולים
7100 ,אשקלון ,640 ,קורצ'אק
7100 ,אשקלון ,397 ,קיבוץ גלויות
7100 ,אשקלון ,1030 ,קיסריה
7100 ,אשקלון ,1306 ,קני סוף
7100 ,אשקלון ,1212 ,קנקני אשקלון
7100 ,אשקלון ,318 ,קפסטט
7100 ,אשקלון ,835 ,קצרין
7100 ,אשקלון ,434 ,קק"ל
7100 ,אשקלון ,389 ,קרייתי
7100 ,אשקלון ,6561 ,קרית גולדה מאיר
7100 ,אשקלון ,6549 ,קרית חינוך דרום
7100 ,אשקלון ,6560 ,קרית חינוך צפון
7100 ,אשקלון ,895 ,קרן היסוד
7100 ,אשקלון ,546 ,ראובן
7100 ,אשקלון ,837 ,ראשון לציון
7100 ,אשקלון ,454 ,רבי מאיר
7100 ,אשקלון ,480 ,רבי עקיבא
7100 ,אשקלון ,426 ,רבי רפאל אנקווה
7100 ,אשקלון ,568 ,רבקה
7100 ,אשקלון ,1206 ,רובינא חנה
7100 ,אשקלון ,523 ,רות
7100 ,אשקלון ,345 ,רותם
7100 ,אשקלון ,489 ,רזיאל דוד
7100 ,אשקלון ,1151 ,רחבת הרב אבהר שלום
7100 ,אשקלון ,6019 ,רחבת ווסק
7100 ,אשקלון ,958 ,רחל ינאית בן צבי
7100 ,אשקלון ,1181 ,רינה
7100 ,אשקלון ,456 ,רמב"ם
7100 ,אשקלון ,451 ,רמז דוד
7100 ,אשקלון ,6515 ,רמת בן גוריון
7100 ,אשקלון ,6523 ,רמת חן
7100 ,אשקלון ,6522 ,רמת כרמים
7100 ,אשקלון ,452 ,רנבסקיה פאינה
7100 ,אשקלון ,1184 ,רעות
7100 ,אשקלון ,1141 ,רפידים
7100 ,אשקלון ,875 ,רפסודה
7100 ,אשקלון ,6571 ,רצועת החוף והמלונות
7100 ,אשקלון ,460 ,רש"י
7100 ,אשקלון ,6524 ,ש אגש שמשון
7100 ,אשקלון ,6562 ,ש בנה ביתך
7100 ,אשקלון ,6503 ,ש ברנע
7100 ,אשקלון ,6526 ,ש דרומי
7100 ,אשקלון ,6528 ,ש הצכים
7100 ,אשקלון ,6563 ,ש וילות
7100 ,אשקלון ,6541 ,ש זוגות צעירים
7100 ,אשקלון ,6505 ,ש חסכון א
7100 ,אשקלון ,6530 ,ש חסכון ב
7100 ,אשקלון ,6506 ,ש יובל גד
7100 ,אשקלון ,6507 ,ש מגדל המים
7100 ,אשקלון ,6531 ,ש מפונים
7100 ,אשקלון ,6508 ,ש נוה רז
7100 ,אשקלון ,6533 ,ש צפון ב
7100 ,אשקלון ,6564 ,ש קרוונים
7100 ,אשקלון ,6534 ,ש רמז
7100 ,אשקלון ,6535 ,ש רמת אשכול
7100 ,אשקלון ,6536 ,ש שוטרים
7100 ,אשקלון ,6527 ,ש שיקמים
7100 ,אשקלון ,6543 ,ש שמשון
7100 ,אשקלון ,1150 ,ש.ב.ישעיה
7100 ,אשקלון ,1222 ,שאנין
7100 ,אשקלון ,868 ,שאר ישוב
7100 ,אשקלון ,773 ,שבו
7100 ,אשקלון ,455 ,שבזי
7100 ,אשקלון ,1068 ,שבט
7100 ,אשקלון ,637 ,שבט סופר
7100 ,אשקלון ,1040 ,שביט
7100 ,אשקלון ,916 ,שביל ד"ר פיינגולד ג
7100 ,אשקלון ,915 ,שביל דינור ברוך
7100 ,אשקלון ,928 ,שביל החייט
7100 ,אשקלון ,1001 ,שביל הלימון
7100 ,אשקלון ,929 ,שביל הסנדלר
7100 ,אשקלון ,1109 ,שביל העוז
7100 ,אשקלון ,338 ,שביל חכם יעקב
7100 ,אשקלון ,922 ,שביל נווה חוף
7100 ,אשקלון ,387 ,שביל שבע
7100 ,אשקלון ,1311 ,שבשבט
7100 ,אשקלון ,1043 ,שבתאי
7100 ,אשקלון ,856 ,שד אצ"ל
7100 ,אשקלון ,653 ,שד בן גוריון
7100 ,אשקלון ,740 ,שד בר-לב חיים
7100 ,אשקלון ,311 ,שד דרום אפריקה
7100 ,אשקלון ,496 ,שד התעשיה
7100 ,אשקלון ,390 ,שד ירושלים
7100 ,אשקלון ,818 ,שד מונטיפיורי
7100 ,אשקלון ,270 ,שד עמק האלה
7100 ,אשקלון ,267 ,שד עמק חפר
7100 ,אשקלון ,268 ,שד עמק יזרעאל
7100 ,אשקלון ,6011 ,שד צפניה
7100 ,אשקלון ,861 ,שד קדש
7100 ,אשקלון ,693 ,שד שפירא
7100 ,אשקלון ,1035 ,שד' בגין מנחם
7100 ,אשקלון ,893 ,שד' הציונות
7100 ,אשקלון ,561 ,שד' עופר
7100 ,אשקלון ,1115 ,שד' רבין יצחק
7100 ,אשקלון ,779 ,שוהם
7100 ,אשקלון ,842 ,שורק
7100 ,אשקלון ,844 ,שורשים
7100 ,אשקלון ,785 ,שזיף
7100 ,אשקלון ,1131 ,שחף
7100 ,אשקלון ,971 ,שטיפטל מרק
7100 ,אשקלון ,340 ,שטרן אברהם
7100 ,אשקלון ,540 ,שיבת ציון
7100 ,אשקלון ,1125 ,שילה
7100 ,אשקלון ,1220 ,שירת היין
7100 ,אשקלון ,6510 ,שכ אפרידר
7100 ,אשקלון ,6539 ,שכ אשכולי פז
7100 ,אשקלון ,6556 ,שכ ברנע ב'
7100 ,אשקלון ,6555 ,שכ ברנע ג'
7100 ,אשקלון ,6542 ,שכ האגמים
7100 ,אשקלון ,6545 ,שכ הנשיא הרצוג
7100 ,אשקלון ,6537 ,שכ הצפון א
7100 ,אשקלון ,6544 ,שכ ז'בוטינסקי
7100 ,אשקלון ,6538 ,שכ נוה חוף
7100 ,אשקלון ,6565 ,שכ נוה ים
7100 ,אשקלון ,6529 ,שכ קדש ברנע
7100 ,אשקלון ,6566 ,שכ שמשון ה
7100 ,אשקלון ,6504 ,שכון ופתוח
7100 ,אשקלון ,1187 ,שלווה
7100 ,אשקלון ,621 ,שלום עליכם
7100 ,אשקלון ,652 ,שלומציון
7100 ,אשקלון ,999 ,שלמה אביבי
7100 ,אשקלון ,459 ,שמאי
7100 ,אשקלון ,513 ,שמואל
7100 ,אשקלון ,6043 ,שמואל וסרל קויברי
7100 ,אשקלון ,975 ,שמחת עולם
7100 ,אשקלון ,600 ,שמעון פרס
7100 ,אשקלון ,1200 ,שמר נעמי
7100 ,אשקלון ,760 ,שמש יוסף
7100 ,אשקלון ,697 ,שני אליהו
7100 ,אשקלון ,1034 ,שניר
7100 ,אשקלון ,787 ,שסק
7100 ,אשקלון ,1228 ,שפיה
7100 ,אשקלון ,602 ,שפינוזה
7100 ,אשקלון ,504 ,שפירא יוסף
7100 ,אשקלון ,1134 ,שקנאי
7100 ,אשקלון ,569 ,שרה
7100 ,אשקלון ,1124 ,שרה ומנחם וקשטוק
7100 ,אשקלון ,843 ,שריג
7100 ,אשקלון ,690 ,שרמן ארצ'י
7100 ,אשקלון ,894 ,שרת משה
7100 ,אשקלון ,846 ,שתילים
7100 ,אשקלון ,786 ,תאנה
7100 ,אשקלון ,972 ,תגר אריה
7100 ,אשקלון ,852 ,תגר עליזה
7100 ,אשקלון ,403 ,תורמוס
7100 ,אשקלון ,792 ,תות
7100 ,אשקלון ,841 ,תירוש
7100 ,אשקלון ,303 ,תל חי
7100 ,אשקלון ,1105 ,תלתן
7100 ,אשקלון ,1048 ,תמוז
7100 ,אשקלון ,1099 ,תמנע
7100 ,אשקלון ,839 ,תנובת השדה
7100 ,אשקלון ,1126 ,תענך
7100 ,אשקלון ,800 ,תרצה
7100 ,אשקלון ,774 ,תרשיש
7100 ,אשקלון ,1044 ,תשרי
1256 ,אשרת ,9000 ,אשרת
1256 ,אשרת ,132 ,דרך האבוקדו
1256 ,אשרת ,101 ,דרך החרוב
1256 ,אשרת ,102 ,דרך העצים
1256 ,אשרת ,117 ,דרך הפרחים
1256 ,אשרת ,130 ,ההרדוף
1256 ,אשרת ,131 ,החרצית
1256 ,אשרת ,110 ,התאנה
1256 ,אשרת ,129 ,שביל האירוס
1256 ,אשרת ,113 ,שביל האלה
1256 ,אשרת ,108 ,שביל האלון
1256 ,אשרת ,104 ,שביל הארז
1256 ,אשרת ,106 ,שביל הברוש
1256 ,אשרת ,114 ,שביל הגפן
1256 ,אשרת ,118 ,שביל הדפנה
1256 ,אשרת ,105 ,שביל הזית
1256 ,אשרת ,125 ,שביל החצב
1256 ,אשרת ,126 ,שביל היסמין
1256 ,אשרת ,124 ,שביל הכרכום
1256 ,אשרת ,109 ,שביל הלימון
1256 ,אשרת ,119 ,שביל המורן
1256 ,אשרת ,122 ,שביל הנרקיס
1256 ,אשרת ,120 ,שביל הסביון
1256 ,אשרת ,121 ,שביל הסחלב
1256 ,אשרת ,111 ,שביל הפיקוס
1256 ,אשרת ,127 ,שביל הצבעוני
1256 ,אשרת ,128 ,שביל הרותם
1256 ,אשרת ,116 ,שביל הרימון
1256 ,אשרת ,123 ,שביל הרקפת
1256 ,אשרת ,103 ,שביל השקד
1256 ,אשרת ,112 ,שביל השקמה
1256 ,אשרת ,107 ,שביל התמר
1256 ,אשרת ,115 ,שביל כליל החורש
1256 ,אשרת ,133 ,שביל עץ התות
1256 ,אשרת ,6002 ,שכונת הזיתים
1256 ,אשרת ,6001 ,שכונת הראשונים
740 ,אשתאול ,9000 ,אשתאול
1298 ,אתגר ,9000 ,אתגר
1298 ,אתגר ,101 ,ש קרוונים
6000 ,באקה אל-גרביה ,217 ,אאל יאסר
6000 ,באקה אל-גרביה ,216 ,אאסיה
6000 ,באקה אל-גרביה ,6721 ,אבו אלהאוה
6000 ,באקה אל-גרביה ,213 ,אבו בקר אלסדיק
6000 ,באקה אל-גרביה ,210 ,אבו ד'ר אלגפארי
6000 ,באקה אל-גרביה ,209 ,אבו חניפה
6000 ,באקה אל-גרביה ,211 ,אבו כביר
6000 ,באקה אל-גרביה ,212 ,אבו נאר
6000 ,באקה אל-גרביה ,6711 ,אבו קביר
6000 ,באקה אל-גרביה ,202 ,אבן אלג'ראח
6000 ,באקה אל-גרביה ,203 ,אבן אלשאטר
6000 ,באקה אל-גרביה ,204 ,אבן בטוטה
6000 ,באקה אל-גרביה ,207 ,אבן ח'ולדון
6000 ,באקה אל-גרביה ,206 ,אבן חנבל
6000 ,באקה אל-גרביה ,208 ,אבן רושד
6000 ,באקה אל-גרביה ,205 ,אבן תיימה
6000 ,באקה אל-גרביה ,201 ,אבראהים טוקאן
6000 ,באקה אל-גרביה ,215 ,אוסאמה בן זייד
6000 ,באקה אל-גרביה ,6760 ,אזור התעשייה
6000 ,באקה אל-גרביה ,214 ,אחמד שאווקי
6000 ,באקה אל-גרביה ,6764 ,אל קביליה
6000 ,באקה אל-גרביה ,226 ,אלאהליה
6000 ,באקה אל-גרביה ,219 ,אלאזדהאר
6000 ,באקה אל-גרביה ,227 ,אלאימאן
6000 ,באקה אל-גרביה ,223 ,אלאמאם אחמד
6000 ,באקה אל-גרביה ,224 ,אלאמל
6000 ,באקה אל-גרביה ,225 ,אלאנדלוס
6000 ,באקה אל-גרביה ,221 ,אלאסמעי
6000 ,באקה אל-גרביה ,220 ,אלאספהאני
6000 ,באקה אל-גרביה ,222 ,אלאקחוואן
6000 ,באקה אל-גרביה ,218 ,אלארז
6000 ,באקה אל-גרביה ,228 ,אלבוחתורי
6000 ,באקה אל-גרביה ,231 ,אלבוסתאן
6000 ,באקה אל-גרביה ,230 ,אלבוראק
6000 ,באקה אל-גרביה ,235 ,אלבוריני
6000 ,באקה אל-גרביה ,236 ,אלביארק
6000 ,באקה אל-גרביה ,234 ,אלבלוט
6000 ,באקה אל-גרביה ,6712 ,אלבסה
6000 ,באקה אל-גרביה ,233 ,אלבסוס
6000 ,באקה אל-גרביה ,232 ,אלבסמה
6000 ,באקה אל-גרביה ,229 ,אלבראעם
6000 ,באקה אל-גרביה ,6740 ,אלברייה
6000 ,באקה אל-גרביה ,6750 ,אלג'באל
6000 ,באקה אל-גרביה ,239 ,אלג'בל
6000 ,באקה אל-גרביה ,291 ,אלג'זאלי
6000 ,באקה אל-גרביה ,241 ,אלג'זירה
6000 ,באקה אל-גרביה ,240 ,אלג'זרי
6000 ,באקה אל-גרביה ,242 ,אלג'למה
6000 ,באקה אל-גרביה ,243 ,אלג'מיזה
6000 ,באקה אל-גרביה ,6732 ,אלדרדס
6000 ,באקה אל-גרביה ,335 ,אלהודא
6000 ,באקה אל-גרביה ,334 ,אלהודהוד
6000 ,באקה אל-גרביה ,336 ,אלהלאל
6000 ,באקה אל-גרביה ,337 ,אלוואחה
6000 ,באקה אל-גרביה ,338 ,אלווחדה
6000 ,באקה אל-גרביה ,340 ,אלוופאא
6000 ,באקה אל-גרביה ,341 ,אלוופאק
6000 ,באקה אל-גרביה ,339 ,אלוורוד
6000 ,באקה אל-גרביה ,265 ,אלזהראווי
6000 ,באקה אל-גרביה ,266 ,אלזוהור
6000 ,באקה אל-גרביה ,267 ,אלזיתון
6000 ,באקה אל-גרביה ,253 ,אלח'נדק
6000 ,באקה אל-גרביה ,6741 ,אלח'רבה
6000 ,באקה אל-גרביה ,244 ,אלחג'ה נפיסה
6000 ,באקה אל-גרביה ,251 ,אלחולפאא אלראשדין
6000 ,באקה אל-גרביה ,247 ,אלחוסיין
6000 ,באקה אל-גרביה ,252 ,אלחליל
6000 ,באקה אל-גרביה ,249 ,אלחמד
6000 ,באקה אל-גרביה ,246 ,אלחסון
6000 ,באקה אל-גרביה ,245 ,אלחסן
6000 ,באקה אל-גרביה ,248 ,אלחקמה
6000 ,באקה אל-גרביה ,250 ,אלחרוב
6000 ,באקה אל-גרביה ,287 ,אלטאאוס
6000 ,באקה אל-גרביה ,642 ,אליאסמין
6000 ,באקה אל-גרביה ,344 ,אלימאמה
6000 ,באקה אל-גרביה ,343 ,אלירמוק
6000 ,באקה אל-גרביה ,307 ,אלכנארי
6000 ,באקה אל-גרביה ,254 ,אלכנסאא
6000 ,באקה אל-גרביה ,310 ,אלמג'דל
6000 ,באקה אל-גרביה ,311 ,אלמדינה אלמונאוורה
6000 ,באקה אל-גרביה ,6761 ,אלמוארס
6000 ,באקה אל-גרביה ,324 ,אלמוהאג'רין
6000 ,באקה אל-גרביה ,325 ,אלמוואדה
6000 ,באקה אל-גרביה ,315 ,אלמוסטפא
6000 ,באקה אל-גרביה ,309 ,אלמותנבי
6000 ,באקה אל-גרביה ,322 ,אלמנזול
6000 ,באקה אל-גרביה ,323 ,אלמנפלוטי
6000 ,באקה אל-גרביה ,317 ,אלמעסרה
6000 ,באקה אל-גרביה ,316 ,אלמערפה
6000 ,באקה אל-גרביה ,318 ,אלמקדאד
6000 ,באקה אל-גרביה ,320 ,אלמקריזי
6000 ,באקה אל-גרביה ,314 ,אלמרווה
6000 ,באקה אל-גרביה ,326 ,אלנבעה
6000 ,באקה אל-גרביה ,332 ,אלנור
6000 ,באקה אל-גרביה ,329 ,אלנזלה
6000 ,באקה אל-גרביה ,333 ,אלניל
6000 ,באקה אל-גרביה ,327 ,אלנכיל
6000 ,באקה אל-גרביה ,331 ,אלנמארק
6000 ,באקה אל-גרביה ,6762 ,אלנמלה
6000 ,באקה אל-גרביה ,330 ,אלנסר
6000 ,באקה אל-גרביה ,328 ,אלנרג'ס
6000 ,באקה אל-גרביה ,279 ,אלסבאח
6000 ,באקה אל-גרביה ,268 ,אלסבאע
6000 ,באקה אל-גרביה ,281 ,אלסדאקה
6000 ,באקה אל-גרביה ,275 ,אלסוויקה
6000 ,באקה אל-גרביה ,273 ,אלסומעה
6000 ,באקה אל-גרביה ,280 ,אלסחאבה
6000 ,באקה אל-גרביה ,272 ,אלסלאם
6000 ,באקה אל-גרביה ,394 ,אלסלאמה
6000 ,באקה אל-גרביה ,274 ,אלסנדיאן
6000 ,באקה אל-גרביה ,285 ,אלסנובר
6000 ,באקה אל-גרביה ,283 ,אלספאא
6000 ,באקה אל-גרביה ,284 ,אלסקר
6000 ,באקה אל-גרביה ,282 ,אלסראט
6000 ,באקה אל-גרביה ,271 ,אלסרוו
6000 ,באקה אל-גרביה ,290 ,אלעאוודה
6000 ,באקה אל-גרביה ,288 ,אלעג'מי
6000 ,באקה אל-גרביה ,395 ,אלעזה
6000 ,באקה אל-גרביה ,289 ,אלעקאד
6000 ,באקה אל-גרביה ,292 ,אלפאראבי
6000 ,באקה אל-גרביה ,297 ,אלפוארס
6000 ,באקה אל-גרביה ,295 ,אלפוראת
6000 ,באקה אל-גרביה ,296 ,אלפרדוס
6000 ,באקה אל-גרביה ,298 ,אלקאדסיה
6000 ,באקה אל-גרביה ,308 ,אלקאוות'ר
6000 ,באקה אל-גרביה ,299 ,אלקאסמי
6000 ,באקה אל-גרביה ,300 ,אלקודס
6000 ,באקה אל-גרביה ,301 ,אלקורונפול
6000 ,באקה אל-גרביה ,302 ,אלקזאז
6000 ,באקה אל-גרביה ,304 ,אלקמה
6000 ,באקה אל-גרביה ,303 ,אלקמר
6000 ,באקה אל-גרביה ,305 ,אלקראמה
6000 ,באקה אל-גרביה ,306 ,אלקרוואן
6000 ,באקה אל-גרביה ,262 ,אלראוודה
6000 ,באקה אל-גרביה ,255 ,אלראזי
6000 ,באקה אל-גרביה ,256 ,אלרבאט
6000 ,באקה אל-גרביה ,257 ,אלרביע
6000 ,באקה אל-גרביה ,259 ,אלרדוואן
6000 ,באקה אל-גרביה ,261 ,אלרומאן
6000 ,באקה אל-גרביה ,258 ,אלרחמה
6000 ,באקה אל-גרביה ,263 ,אלריאחין
6000 ,באקה אל-גרביה ,264 ,אלריאן
6000 ,באקה אל-גרביה ,260 ,אלרפעה
6000 ,באקה אל-גרביה ,6731 ,אלשבאסיה ג'ת
6000 ,באקה אל-גרביה ,6710 ,אלשוקפאן
6000 ,באקה אל-גרביה ,278 ,אלשייח' תיים
6000 ,באקה אל-גרביה ,6751 ,אלשמיה
6000 ,באקה אל-גרביה ,277 ,אלשמס
6000 ,באקה אל-גרביה ,237 ,אלתאבעין
6000 ,באקה אל-גרביה ,238 ,אלתקווא
6000 ,באקה אל-גרביה ,6730 ,אם אלוואוויאת
6000 ,באקה אל-גרביה ,6743 ,אם אלרקאקי
6000 ,באקה אל-גרביה ,9000 ,באקה אל-גרביה
6000 ,באקה אל-גרביה ,349 ,ביר באקה
6000 ,באקה אל-גרביה ,350 ,ביר בורין
6000 ,באקה אל-גרביה ,347 ,בלאל בן רבאח
6000 ,באקה אל-גרביה ,352 ,ג'ובראן
6000 ,באקה אל-גרביה ,359 ,דג'לה
6000 ,באקה אל-גרביה ,367 ,דראר בן אלאזוור
6000 ,באקה אל-גרביה ,6752 ,המרכז (העיר הישנה)
6000 ,באקה אל-גרביה ,6722 ,ואדי אלח'רובי
6000 ,באקה אל-גרביה ,360 ,זהרת אלמדאאן
6000 ,באקה אל-גרביה ,6720 ,ח'לת אלמרדאוי
6000 ,באקה אל-גרביה ,6742 ,ח'לת זיתא
6000 ,באקה אל-גרביה ,353 ,חאתם אלטאאי
6000 ,באקה אל-גרביה ,354 ,חסן אלבסרי
6000 ,באקה אל-גרביה ,369 ,טאהה חוסיין
6000 ,באקה אל-גרביה ,368 ,טארק בן זיאד
6000 ,באקה אל-גרביה ,392 ,יאפא
6000 ,באקה אל-גרביה ,393 ,ית'רב
6000 ,באקה אל-גרביה ,6763 ,למריבעה
6000 ,באקה אל-גרביה ,383 ,מועאד בן ג'בל
6000 ,באקה אל-גרביה ,385 ,מכה אלמוקרמה
6000 ,באקה אל-גרביה ,384 ,מערוף אלרסאפי
6000 ,באקה אל-גרביה ,6713 ,מרג'ת אלע'וזלאן
6000 ,באקה אל-גרביה ,365 ,סלאח אלדין
6000 ,באקה אל-גרביה ,138 ,סמ 20 1
6000 ,באקה אל-גרביה ,133 ,סמ 1 1
6000 ,באקה אל-גרביה ,134 ,סמ 1 2
6000 ,באקה אל-גרביה ,136 ,סמ 12 2
6000 ,באקה אל-גרביה ,139 ,סמ 15 1
6000 ,באקה אל-גרביה ,135 ,סמ 6 1
6000 ,באקה אל-גרביה ,137 ,סמ 19 1
6000 ,באקה אל-גרביה ,366 ,סנעאא
6000 ,באקה אל-גרביה ,361 ,סעד בן אבי וקאס
6000 ,באקה אל-גרביה ,370 ,עובאדה בן אלסאמט
6000 ,באקה אל-גרביה ,376 ,עומר אלמוכתאר
6000 ,באקה אל-גרביה ,375 ,עומר בן אלח'טאב
6000 ,באקה אל-גרביה ,372 ,עוקבה
6000 ,באקה אל-גרביה ,371 ,עותמאן בן עפאן
6000 ,באקה אל-גרביה ,373 ,עלי בן אבי טאלב
6000 ,באקה אל-גרביה ,374 ,עמאר בן יאסר
6000 ,באקה אל-גרביה ,377 ,ענתרה
6000 ,באקה אל-גרביה ,378 ,פאטמה אלזהאא
6000 ,באקה אל-גרביה ,110 ,רח 10
6000 ,באקה אל-גרביה ,120 ,רח 20
6000 ,באקה אל-גרביה ,130 ,רח 30
6000 ,באקה אל-גרביה ,101 ,רח 1
6000 ,באקה אל-גרביה ,111 ,רח 11
6000 ,באקה אל-גרביה ,121 ,רח 21
6000 ,באקה אל-גרביה ,131 ,רח 31
6000 ,באקה אל-גרביה ,102 ,רח 2
6000 ,באקה אל-גרביה ,112 ,רח 12
6000 ,באקה אל-גרביה ,122 ,רח 22
6000 ,באקה אל-גרביה ,132 ,רח 32
6000 ,באקה אל-גרביה ,103 ,רח 3
6000 ,באקה אל-גרביה ,113 ,רח 13
6000 ,באקה אל-גרביה ,123 ,רח 23
6000 ,באקה אל-גרביה ,104 ,רח 4
6000 ,באקה אל-גרביה ,114 ,רח 14
6000 ,באקה אל-גרביה ,124 ,רח 24
6000 ,באקה אל-גרביה ,105 ,רח 5
6000 ,באקה אל-גרביה ,115 ,רח 15
6000 ,באקה אל-גרביה ,125 ,רח 25
6000 ,באקה אל-גרביה ,106 ,רח 6
6000 ,באקה אל-גרביה ,116 ,רח 16
6000 ,באקה אל-גרביה ,126 ,רח 26
6000 ,באקה אל-גרביה ,107 ,רח 7
6000 ,באקה אל-גרביה ,117 ,רח 17
6000 ,באקה אל-גרביה ,127 ,רח 27
6000 ,באקה אל-גרביה ,108 ,רח 8
6000 ,באקה אל-גרביה ,118 ,רח 18
6000 ,באקה אל-גרביה ,128 ,רח 28
6000 ,באקה אל-גרביה ,109 ,רח 9
6000 ,באקה אל-גרביה ,119 ,רח 19
6000 ,באקה אל-גרביה ,129 ,רח 29
6000 ,באקה אל-גרביה ,364 ,שקאאק אלנעמאן
6000 ,באקה אל-גרביה ,363 ,שרחביל בן חסנה
6000 ,באקה אל-גרביה ,351 ,תבוק
21 ,באר אורה ,101 ,אורה
21 ,באר אורה ,9000 ,באר אורה
21 ,באר אורה ,106 ,משעול אוכם
21 ,באר אורה ,110 ,משעול דוחן
21 ,באר אורה ,109 ,משעול דרדר
21 ,באר אורה ,128 ,משעול חכליל
21 ,באר אורה ,111 ,משעול כוכב
21 ,באר אורה ,112 ,משעול לענה
21 ,באר אורה ,130 ,משעול מכרות
21 ,באר אורה ,125 ,משעול נשף
21 ,באר אורה ,113 ,משעול סהרון
21 ,באר אורה ,107 ,משעול סיתוונית
21 ,באר אורה ,124 ,משעול עמרם
21 ,באר אורה ,108 ,משעול פיקוס
21 ,באר אורה ,115 ,משעול צלף
21 ,באר אורה ,127 ,משעול רחם
21 ,באר אורה ,129 ,משעול שגיא
21 ,באר אורה ,126 ,משעול שחורת
21 ,באר אורה ,114 ,משעול שנהבית
21 ,באר אורה ,104 ,סמ דקל דום
21 ,באר אורה ,105 ,סמ האשל
21 ,באר אורה ,123 ,סמ הר כרכום
21 ,באר אורה ,121 ,סמ הר צניפים
21 ,באר אורה ,122 ,סמ הר שני
21 ,באר אורה ,102 ,סמ השיטה
21 ,באר אורה ,103 ,סמ שיזף
21 ,באר אורה ,135 ,עמודי שלמה
21 ,באר אורה ,116 ,רותם המדבר
21 ,באר אורה ,120 ,תמנע
1376 ,באר גנים ,122 ,אדווה
1376 ,באר גנים ,112 ,איבי הנחל
1376 ,באר גנים ,120 ,אלמוג
1376 ,באר גנים ,111 ,אפיקי מים
1376 ,באר גנים ,9000 ,באר גנים
1376 ,באר גנים ,123 ,גפן
1376 ,באר גנים ,110 ,דרך האור
1376 ,באר גנים ,113 ,דרך הגן
1376 ,באר גנים ,101 ,דרך הים
1376 ,באר גנים ,116 ,האסיף
1376 ,באר גנים ,114 ,הבציר
1376 ,באר גנים ,115 ,החריש
1376 ,באר גנים ,103 ,הסביון
1376 ,באר גנים ,124 ,זית
1376 ,באר גנים ,104 ,חבצלת החוף
1376 ,באר גנים ,118 ,טופז
1376 ,באר גנים ,109 ,ישפה
1376 ,באר גנים ,107 ,לשם
1376 ,באר גנים ,117 ,מלכית
1376 ,באר גנים ,105 ,נוף ים
1376 ,באר גנים ,106 ,ספיר
1376 ,באר גנים ,119 ,צדף
1376 ,באר גנים ,102 ,רותם המדבר
1376 ,באר גנים ,125 ,רימון
1376 ,באר גנים ,108 ,שוהם
1376 ,באר גנים ,121 ,שונית
155 ,באר טוביה ,9000 ,באר טוביה
155 ,באר טוביה ,101 ,שכ קרוונים
2530 ,באר יעקב ,113 ,אבא הלל
2530 ,באר יעקב ,211 ,אביב
2530 ,באר יעקב ,204 ,אגמיה
2530 ,באר יעקב ,196 ,אודם
2530 ,באר יעקב ,6511 ,אזור תעשייה
2530 ,באר יעקב ,143 ,איילה
2530 ,באר יעקב ,212 ,אלות
2530 ,באר יעקב ,116 ,אליהו
2530 ,באר יעקב ,9000 ,באר יעקב
2530 ,באר יעקב ,119 ,בי"ח אסף הרופא
2530 ,באר יעקב ,125 ,בי"ח מלבן
2530 ,באר יעקב ,123 ,בי"ח שמואל הרופא
2530 ,באר יעקב ,132 ,בן יוסף שלמה
2530 ,באר יעקב ,234 ,ברקת
2530 ,באר יעקב ,6500 ,גבעת חוטר
2530 ,באר יעקב ,224 ,גולדברג לאה
2530 ,באר יעקב ,240 ,גיא
2530 ,באר יעקב ,6514 ,גני מנחם
2530 ,באר יעקב ,246 ,דגן
2530 ,באר יעקב ,148 ,דוד הכהן
2530 ,באר יעקב ,194 ,דוכיפת
2530 ,באר יעקב ,178 ,דיזנגוף
2530 ,באר יעקב ,183 ,דן
2530 ,באר יעקב ,110 ,דניאל יצחקי
2530 ,באר יעקב ,144 ,דרך חיים
2530 ,באר יעקב ,156 ,דרך סטון יוסף
2530 ,באר יעקב ,104 ,דרך צה"ל
2530 ,באר יעקב ,103 ,דרך רון ארד
2530 ,באר יעקב ,154 ,האורן
2530 ,באר יעקב ,151 ,האיריס
2530 ,באר יעקב ,117 ,האלה
2530 ,באר יעקב ,165 ,האלון
2530 ,באר יעקב ,175 ,האנפה
2530 ,באר יעקב ,164 ,הארז
2530 ,באר יעקב ,198 ,האתרוג
2530 ,באר יעקב ,179 ,הבנים
2530 ,באר יעקב ,160 ,הברוש
2530 ,באר יעקב ,213 ,הבשור
2530 ,באר יעקב ,180 ,הגולן
2530 ,באר יעקב ,108 ,הגפן
2530 ,באר יעקב ,230 ,הדובדבן
2530 ,באר יעקב ,168 ,הדקל
2530 ,באר יעקב ,188 ,הדר
2530 ,באר יעקב ,174 ,הדרור
2530 ,באר יעקב ,201 ,הולצברג שמחה
2530 ,באר יעקב ,139 ,הורד
2530 ,באר יעקב ,189 ,הזית
2530 ,באר יעקב ,173 ,הזמיר
2530 ,באר יעקב ,152 ,החבצלת
2530 ,באר יעקב ,118 ,החצב
2530 ,באר יעקב ,181 ,החרמון
2530 ,באר יעקב ,120 ,היוזמה
2530 ,באר יעקב ,147 ,היוצר
2530 ,באר יעקב ,187 ,הירדן
2530 ,באר יעקב ,185 ,הירמוך
2530 ,באר יעקב ,184 ,הירקון
2530 ,באר יעקב ,150 ,הכלנית
2530 ,באר יעקב ,131 ,המושבה
2530 ,באר יעקב ,191 ,הנקר
2530 ,באר יעקב ,153 ,הנרקיס
2530 ,באר יעקב ,172 ,הסנונית
2530 ,באר יעקב ,176 ,העפרוני
2530 ,באר יעקב ,214 ,הערבה
2530 ,באר יעקב ,171 ,הפרדס
2530 ,באר יעקב ,121 ,הצמיחה
2530 ,באר יעקב ,186 ,הקישון
2530 ,באר יעקב ,130 ,הרב ניסים טולדנו
2530 ,באר יעקב ,102 ,הרב עוזיאל
2530 ,באר יעקב ,105 ,הרב קוק
2530 ,באר יעקב ,157 ,הרב שפירא
2530 ,באר יעקב ,142 ,השושנים
2530 ,באר יעקב ,162 ,השיטה
2530 ,באר יעקב ,145 ,השניים
2530 ,באר יעקב ,163 ,השקד
2530 ,באר יעקב ,155 ,התאנה
2530 ,באר יעקב ,182 ,התבור
2530 ,באר יעקב ,161 ,התמר
2530 ,באר יעקב ,124 ,התנופה
2530 ,באר יעקב ,197 ,התפוז
2530 ,באר יעקב ,209 ,ורדית
2530 ,באר יעקב ,112 ,ז'בוטינסקי
2530 ,באר יעקב ,202 ,חופית
2530 ,באר יעקב ,228 ,חושן
2530 ,באר יעקב ,106 ,חזון איש
2530 ,באר יעקב ,216 ,חרוד
2530 ,באר יעקב ,220 ,חתני פרס ישראל
2530 ,באר יעקב ,225 ,טופז
2530 ,באר יעקב ,149 ,יהודה
2530 ,באר יעקב ,239 ,יהלום
2530 ,באר יעקב ,115 ,יחזקאל באטה
2530 ,באר יעקב ,141 ,יסמין
2530 ,באר יעקב ,207 ,יסעור
2530 ,באר יעקב ,122 ,ישיבת באר יעקב
2530 ,באר יעקב ,135 ,ישיבת שארית יוסף
2530 ,באר יעקב ,238 ,ישפה
2530 ,באר יעקב ,6008 ,כ חלוצי מעברת חוטר
2530 ,באר יעקב ,6004 ,כיכר המייסדים
2530 ,באר יעקב ,6007 ,כיכר השוטר
2530 ,באר יעקב ,6005 ,כיכר ערים תאומות
2530 ,באר יעקב ,6006 ,כיכר תנועות הנוער
2530 ,באר יעקב ,6001 ,ככר חנה
2530 ,באר יעקב ,6002 ,ככר קפלן
2530 ,באר יעקב ,6003 ,ככר רוטרי
2530 ,באר יעקב ,6501 ,כפר יוהנה זבוטינסקי
2530 ,באר יעקב ,242 ,כרמים
2530 ,באר יעקב ,203 ,ליבנית
2530 ,באר יעקב ,200 ,ליכטנשטיין אברהם
2530 ,באר יעקב ,227 ,לשם
2530 ,באר יעקב ,107 ,מאיר בעל הנס
2530 ,באר יעקב ,221 ,מנור אהוד
2530 ,באר יעקב ,6503 ,נוה ספיר
2530 ,באר יעקב ,6502 ,נווה דורון
2530 ,באר יעקב ,235 ,נופך
2530 ,באר יעקב ,114 ,נחום
2530 ,באר יעקב ,195 ,נחליאלי
2530 ,באר יעקב ,140 ,סביון
2530 ,באר יעקב ,208 ,סלעית
2530 ,באר יעקב ,177 ,סמ האנקור
2530 ,באר יעקב ,159 ,סמ האשל
2530 ,באר יעקב ,158 ,סמ הצאלון
2530 ,באר יעקב ,233 ,סמ הרימון
2530 ,באר יעקב ,231 ,סמ הרקפת
2530 ,באר יעקב ,167 ,סמ השיקמה
2530 ,באר יעקב ,232 ,סמ התפוח
2530 ,באר יעקב ,226 ,ספיר
2530 ,באר יעקב ,6504 ,סרפנד אל עמר
2530 ,באר יעקב ,126 ,עודד
2530 ,באר יעקב ,245 ,עומר
2530 ,באר יעקב ,244 ,עמק
2530 ,באר יעקב ,129 ,עמרם נעים
2530 ,באר יעקב ,215 ,ערוגות
2530 ,באר יעקב ,6516 ,פארק תל"מ
2530 ,באר יעקב ,206 ,פלמינגו
2530 ,באר יעקב ,190 ,פשוש
2530 ,באר יעקב ,192 ,צופית
2530 ,באר יעקב ,205 ,צוקית
2530 ,באר יעקב ,6505 ,צריפין
2530 ,באר יעקב ,223 ,קישון אפרים
2530 ,באר יעקב ,243 ,קציר
2530 ,באר יעקב ,166 ,קרן היסוד
2530 ,באר יעקב ,6506 ,ש אגש
2530 ,באר יעקב ,6507 ,ש טוניס
2530 ,באר יעקב ,6508 ,ש נוה עובד
2530 ,באר יעקב ,6509 ,ש עובדי מלבן
2530 ,באר יעקב ,134 ,שא נס
2530 ,באר יעקב ,236 ,שבו
2530 ,באר יעקב ,127 ,שביט
2530 ,באר יעקב ,169 ,שד האלונים
2530 ,באר יעקב ,170 ,שד רבין יצחק
2530 ,באר יעקב ,222 ,שד שמר נעמי
2530 ,באר יעקב ,241 ,שדות
2530 ,באר יעקב ,210 ,שדרות יצחק שמיר
2530 ,באר יעקב ,237 ,שוהם
2530 ,באר יעקב ,193 ,שחף
2530 ,באר יעקב ,6515 ,שכ הרצל
2530 ,באר יעקב ,6510 ,שכ חתני פרס ישראל
2530 ,באר יעקב ,6512 ,שכ רמבם
2530 ,באר יעקב ,6513 ,שכ תלמי מנשה
2530 ,באר יעקב ,199 ,שלדג
2530 ,באר יעקב ,146 ,שמוליק בורג
1278 ,באר מילכה ,9000 ,באר מילכה
9000 ,באר שבע ,551 ,אבוחצירא
9000 ,באר שבע ,245 ,אבטליון
9000 ,באר שבע ,1029 ,אביבית
9000 ,באר שבע ,1287 ,אביגד
9000 ,באר שבע ,1054 ,אביגדור המאירי
9000 ,באר שבע ,449 ,אביגור שאול
9000 ,באר שבע ,1111 ,אביגיל
9000 ,באר שבע ,552 ,אבידוב צבי
9000 ,באר שבע ,1031 ,אבידום מנחם
9000 ,באר שבע ,1332 ,אבידן שמעון
9000 ,באר שבע ,201 ,אביה השופט
9000 ,באר שבע ,1112 ,אביטל
9000 ,באר שבע ,1113 ,אבישג
9000 ,באר שבע ,497 ,אבישי
9000 ,באר שבע ,1114 ,אביתר הכהן
9000 ,באר שבע ,1236 ,אבן ארי מיכאל
9000 ,באר שבע ,401 ,אבן גבירול
9000 ,באר שבע ,290 ,אבן עזרא
9000 ,באר שבע ,1115 ,אבן שפרוט
9000 ,באר שבע ,1381 ,אבן-שושן אברהם
9000 ,באר שבע ,475 ,אבנר
9000 ,באר שבע ,347 ,אברבנאל
9000 ,באר שבע ,202 ,אברהם אבינו
9000 ,באר שבע ,1344 ,אברמסון שרגא
9000 ,באר שבע ,1116 ,אבשלום
9000 ,באר שבע ,554 ,אג"ן
9000 ,באר שבע ,1117 ,אגוז
9000 ,באר שבע ,1420 ,אגס
9000 ,באר שבע ,1376 ,אגרנט שמעון
9000 ,באר שבע ,171 ,אדלה עאזר (כדן)
9000 ,באר שבע ,555 ,אדלר
9000 ,באר שבע ,1032 ,אדמון ידידיה
9000 ,באר שבע ,976 ,אהוד בן גרא
9000 ,באר שבע ,1227 ,אהרוני יוחנן
9000 ,באר שבע ,1118 ,אהרונסון
9000 ,באר שבע ,1222 ,אהרליך פאול
9000 ,באר שבע ,963 ,אוהלי קידר
9000 ,באר שבע ,1447 ,אוכמנית
9000 ,באר שבע ,187 ,אולגה שמיר
9000 ,באר שבע ,1119 ,אוליצקי אריה
9000 ,באר שבע ,1228 ,אולנדורף פרנץ
9000 ,באר שבע ,556 ,אולשן יצחק
9000 ,באר שבע ,521 ,אוניברס בן גוריון
9000 ,באר שבע ,1465 ,אוניברסיטת ב גוריון
9000 ,באר שבע ,984 ,אוסטרובסקי גרשון
9000 ,באר שבע ,801 ,אוסישקין
9000 ,באר שבע ,1383 ,אורבך אפרים
9000 ,באר שבע ,1088 ,אורות ישראל
9000 ,באר שבע ,1288 ,אורי צבי
9000 ,באר שבע ,901 ,אוריה החיתי
9000 ,באר שבע ,1385 ,אורלנד יעקב
9000 ,באר שבע ,1446 ,אורן
9000 ,באר שבע ,6606 ,אזור התעשיה
9000 ,באר שבע ,355 ,אחאב
9000 ,באר שבע ,802 ,אחד העם
9000 ,באר שבע ,1120 ,אחימאיר אב"א
9000 ,באר שבע ,1127 ,אחינועם
9000 ,באר שבע ,1001 ,אטד
9000 ,באר שבע ,163 ,אידה בוקסבוים
9000 ,באר שבע ,1314 ,אייזנשטדט
9000 ,באר שבע ,1099 ,איילת השחר
9000 ,באר שבע ,988 ,אימבר
9000 ,באר שבע ,402 ,אינשטיין
9000 ,באר שבע ,1015 ,אירוס הנגב
9000 ,באר שבע ,1253 ,אלבק חנוך
9000 ,באר שבע ,1265 ,אלדד
9000 ,באר שבע ,1448 ,אלה
9000 ,באר שבע ,616 ,אלון יגאל
9000 ,באר שבע ,1320 ,אלוף אדם יקותיאל
9000 ,באר שבע ,1323 ,אלוף יריב אהרון
9000 ,באר שבע ,1321 ,אלוף מגן קלמן
9000 ,באר שבע ,1322 ,אלוף מנדלר אלברט
9000 ,באר שבע ,1128 ,אלחריזי
9000 ,באר שבע ,172 ,אליאונור ברנהיים
9000 ,באר שבע ,203 ,אליהו הנביא
9000 ,באר שבע ,558 ,אלישבע
9000 ,באר שבע ,903 ,אלישע
9000 ,באר שבע ,1258 ,אלישר
9000 ,באר שבע ,205 ,אלכסנדר ינאי
9000 ,באר שבע ,1449 ,אלמוג
9000 ,באר שבע ,204 ,אלעזר בן יאיר
9000 ,באר שבע ,348 ,אלפסי
9000 ,באר שבע ,1289 ,אלקחי מרדכי
9000 ,באר שבע ,884 ,אלקיים ניסים
9000 ,באר שבע ,1262 ,אלקלעי יהודה
9000 ,באר שבע ,1121 ,אלרואי דוד
9000 ,באר שבע ,454 ,אלתרמן
9000 ,באר שבע ,1313 ,אמיר אברהם
9000 ,באר שבע ,186 ,אמירה בלייברג
9000 ,באר שבע ,1122 ,אמנון
9000 ,באר שבע ,902 ,אנילביץ
9000 ,באר שבע ,1311 ,אנפה
9000 ,באר שבע ,118 ,אנקור
9000 ,באר שבע ,298 ,אסא
9000 ,באר שבע ,611 ,אסבסטונים
9000 ,באר שבע ,601 ,אסירי ציון
9000 ,באר שבע ,1035 ,אסנת
9000 ,באר שבע ,159 ,אסנת ברזאני
9000 ,באר שבע ,403 ,אסף הרופא
9000 ,באר שבע ,1203 ,אסר טוביאס
9000 ,באר שבע ,1123 ,אסתר המלכה
9000 ,באר שבע ,871 ,אפיקים בנגב
9000 ,באר שבע ,489 ,אפק
9000 ,באר שבע ,904 ,אפרים
9000 ,באר שבע ,1450 ,אפרסמון
9000 ,באר שבע ,559 ,אפרת יעקב
9000 ,באר שבע ,1307 ,אפשטיין ישראל
9000 ,באר שבע ,900 ,אצ"ג
9000 ,באר שבע ,373 ,אצ"ל
9000 ,באר שבע ,1437 ,אקליפטוס
9000 ,באר שבע ,560 ,אקצין בנימין
9000 ,באר שבע ,1036 ,ארבע הגיבורות
9000 ,באר שבע ,1384 ,ארגוב אלכסנדר
9000 ,באר שבע ,1387 ,ארדון מרדכי
9000 ,באר שבע ,286 ,אריסטובולוס
9000 ,באר שבע ,501 ,ארלוזורוב
9000 ,באר שבע ,1110 ,ארליך שמחה
9000 ,באר שבע ,1221 ,ארלנגר ג'וזף
9000 ,באר שבע ,468 ,ארן זלמן
9000 ,באר שבע ,561 ,ארנון יצחק
9000 ,באר שבע ,266 ,ארניה אסוולדו
9000 ,באר שבע ,1095 ,ארץ חמדה
9000 ,באר שבע ,667 ,ארתור ווסרמיל
9000 ,באר שבע ,369 ,אש שלום
9000 ,באר שבע ,1037 ,אשבל מיכאל
9000 ,באר שבע ,1371 ,אשכולית
9000 ,באר שבע ,1002 ,אשלים
9000 ,באר שבע ,905 ,אשר
9000 ,באר שבע ,9000 ,באר שבע
9000 ,באר שבע ,428 ,בארון דבורה
9000 ,באר שבע ,534 ,בגין מנחם
9000 ,באר שבע ,1268 ,בדר יוחנן
9000 ,באר שבע ,553 ,בובר מרטין
9000 ,באר שבע ,528 ,בוגלה יונה
9000 ,באר שבע ,1353 ,בודנהיימר
9000 ,באר שבע ,161 ,בונה אויערבך
9000 ,באר שבע ,1038 ,בוסקוביץ אלכסנדר
9000 ,באר שבע ,1124 ,בוסתנאי
9000 ,באר שבע ,134 ,בורג יוסף
9000 ,באר שבע ,803 ,בורוכוב
9000 ,באר שבע ,343 ,בורלא יהודה
9000 ,באר שבע ,1214 ,בורן מכס
9000 ,באר שבע ,638 ,בושמת
9000 ,באר שבע ,131 ,בז
9000 ,באר שבע ,804 ,בזל
9000 ,באר שבע ,1017 ,בי"ס חקלאי
9000 ,באר שבע ,404 ,ביאליק
9000 ,באר שבע ,974 ,ביח הדסה
9000 ,באר שבע ,1039 ,ביל"ו
9000 ,באר שבע ,832 ,בינט מקס
9000 ,באר שבע ,346 ,בית אל
9000 ,באר שבע ,906 ,בית אשל
9000 ,באר שבע ,993 ,בית החייל
9000 ,באר שבע ,991 ,בית יציב
9000 ,באר שבע ,381 ,בית לחם
9000 ,באר שבע ,1291 ,בית צורי אליהו
9000 ,באר שבע ,334 ,בית רמט
9000 ,באר שבע ,486 ,בית שאן
9000 ,באר שבע ,1125 ,בלהה
9000 ,באר שבע ,1233 ,בלוך רודולף
9000 ,באר שבע ,805 ,בלפור
9000 ,באר שבע ,1126 ,בלקינד נעמן
9000 ,באר שבע ,562 ,בן איש חי
9000 ,באר שבע ,563 ,בן אשר
9000 ,באר שבע ,1205 ,בן דוד יוסף
9000 ,באר שבע ,564 ,בן חביב
9000 ,באר שבע ,1040 ,בן חיים פאול
9000 ,באר שבע ,502 ,בן יהודה
9000 ,באר שבע ,1290 ,בן יוסף שלמה
9000 ,באר שבע ,432 ,בן יעקב אהרון
9000 ,באר שבע ,1041 ,בן יעקב צבי
9000 ,באר שבע ,1130 ,בן לברט
9000 ,באר שבע ,1131 ,בן סרוק מנחם
9000 ,באר שבע ,433 ,בן עדי הרברט
9000 ,באר שבע ,895 ,בן עמי אברהם
9000 ,באר שבע ,834 ,בן צבי יצחק
9000 ,באר שבע ,243 ,בן שטח שמעון
9000 ,באר שבע ,1408 ,בן-אסא בנימין
9000 ,באר שבע ,663 ,בן-חור אליהו
9000 ,באר שבע ,6611 ,בנה ביתך רמות
9000 ,באר שבע ,1243 ,בנטואיץ יוסף
9000 ,באר שבע ,1129 ,בנטוב מרדכי
9000 ,באר שבע ,291 ,בני אור
9000 ,באר שבע ,907 ,בנימין
9000 ,באר שבע ,267 ,בנימין מטודלה
9000 ,באר שבע ,7001 ,בסיס חצרים
9000 ,באר שבע ,602 ,בסקין צבי
9000 ,באר שבע ,279 ,בעלי התוספות
9000 ,באר שבע ,1201 ,בער יצחק
9000 ,באר שבע ,405 ,בצלאל
9000 ,באר שבע ,1345 ,בקי רוברטו
9000 ,באר שבע ,806 ,בר אילן
9000 ,באר שבע ,1248 ,בר הלל יהושע
9000 ,באר שבע ,241 ,בר יוחאי
9000 ,באר שבע ,908 ,בר כוכבא
9000 ,באר שבע ,1043 ,בר ניסן
9000 ,באר שבע ,664 ,בר-לב חיים
9000 ,באר שבע ,132 ,ברבור
9000 ,באר שבע ,1247 ,ברגסון אנרי
9000 ,באר שבע ,1042 ,ברדיצ'ב אבא
9000 ,באר שבע ,565 ,ברדיצ'בסקי
9000 ,באר שבע ,1132 ,ברוד מקס
9000 ,באר שבע ,1256 ,ברוור אברהם
9000 ,באר שבע ,1133 ,ברוידס אברהם
9000 ,באר שבע ,1134 ,ברזילי ישראל
9000 ,באר שבע ,1292 ,ברזני משה
9000 ,באר שבע ,566 ,ברטונוב יהושע
9000 ,באר שבע ,567 ,ברית כהונה
9000 ,באר שבע ,190 ,ברכה צפירה
9000 ,באר שבע ,1231 ,ברני רוברט
9000 ,באר שבע ,1395 ,ברנע דב
9000 ,באר שבע ,268 ,ברנפלד שמעון
9000 ,באר שבע ,807 ,ברנר
9000 ,באר שבע ,822 ,ברנשטיין לאונרד
9000 ,באר שבע ,137 ,ברנשטיין פרץ
9000 ,באר שבע ,909 ,ברק
9000 ,באר שבע ,1136 ,ברקת ראובן
9000 ,באר שבע ,1045 ,ברש אשר
9000 ,באר שבע ,1137 ,בת שבע
9000 ,באר שבע ,184 ,בת שבע בן אליהו
9000 ,באר שבע ,1404 ,גבולות
9000 ,באר שבע ,503 ,גבעתי
9000 ,באר שבע ,1293 ,גבריהו חיים
9000 ,באר שבע ,431 ,גבתי חיים
9000 ,באר שבע ,910 ,גד
9000 ,באר שבע ,1138 ,גדליהו
9000 ,באר שבע ,872 ,גוזלן
9000 ,באר שבע ,1100 ,גוטמן נחום
9000 ,באר שבע ,569 ,גויטיין דוד
9000 ,באר שבע ,427 ,גולדברג לאה
9000 ,באר שבע ,1363 ,גולדמן נחום
9000 ,באר שבע ,1046 ,גולדשטיין פרץ
9000 ,באר שבע ,504 ,גולומב
9000 ,באר שבע ,1047 ,גולי קניה
9000 ,באר שבע ,505 ,גולני
9000 ,באר שבע ,1445 ,גומא
9000 ,באר שבע ,1048 ,גור יהודה
9000 ,באר שבע ,808 ,גורדון א ד
9000 ,באר שבע ,506 ,גוש עציון
9000 ,באר שבע ,657 ,גיבור דניס
9000 ,באר שבע ,1049 ,גיבתון חנוך
9000 ,באר שבע ,1237 ,גינצבורג דוד
9000 ,באר שבע ,325 ,גירית
9000 ,באר שבע ,1050 ,גלזמן יוסף
9000 ,באר שבע ,1361 ,גלילי ישראל
9000 ,באר שבע ,568 ,גלנטי
9000 ,באר שבע ,208 ,גלעד
9000 ,באר שבע ,324 ,גמל
9000 ,באר שבע ,1295 ,גרונר דב
9000 ,באר שבע ,1051 ,גרוסמן מאיר
9000 ,באר שבע ,1139 ,גרינבוים יצחק
9000 ,באר שבע ,1346 ,גרינפלד
9000 ,באר שבע ,1140 ,גרינשפן הרשל
9000 ,באר שבע ,406 ,גרץ
9000 ,באר שבע ,209 ,גרר
9000 ,באר שבע ,911 ,גרשון
9000 ,באר שבע ,1251 ,גרשם שלום
9000 ,באר שבע ,841 ,גרשפלד
9000 ,באר שבע ,487 ,גת
9000 ,באר שבע ,165 ,ד"ר ג'ין קלוס-פישמן
9000 ,באר שבע ,175 ,ד"ר וילהלמינה כהן
9000 ,באר שבע ,1161 ,ד"ר טיכו
9000 ,באר שבע ,891 ,ד"ר פליקס זנדמן
9000 ,באר שבע ,183 ,ד"ר קריסטינה סיקורה
9000 ,באר שבע ,467 ,דב יוסף
9000 ,באר שבע ,912 ,דבורה
9000 ,באר שבע ,1350 ,דביר
9000 ,באר שבע ,1306 ,דה הוושי גאורג
9000 ,באר שבע ,1141 ,דה-בוטון
9000 ,באר שבע ,1235 ,דה-שליט עמוס
9000 ,באר שבע ,136 ,דהאן מישאל
9000 ,באר שבע ,1372 ,דובדבן
9000 ,באר שבע ,407 ,דובנוב
9000 ,באר שבע ,372 ,דוד אלעזר
9000 ,באר שבע ,210 ,דוד המלך
9000 ,באר שבע ,1142 ,דוד הראובני
9000 ,באר שבע ,1003 ,דודאים
9000 ,באר שבע ,1204 ,דוידוביץ לב
9000 ,באר שבע ,1317 ,דוכיפת
9000 ,באר שבע ,1431 ,דולב
9000 ,באר שבע ,1426 ,דולפין
9000 ,באר שבע ,1108 ,דולצ'ין אריה
9000 ,באר שבע ,155 ,דונה גרציה נשיא
9000 ,באר שבע ,570 ,דונקלבלום מנחם
9000 ,באר שבע ,374 ,דורי יעקב
9000 ,באר שבע ,873 ,דורפמן
9000 ,באר שבע ,1351 ,דותן
9000 ,באר שבע ,1053 ,די זהב אפרים
9000 ,באר שבע ,1362 ,דידנר מנחם
9000 ,באר שבע ,121 ,דיה
9000 ,באר שבע ,572 ,דיין משה
9000 ,באר שבע ,434 ,דינה
9000 ,באר שבע ,1103 ,דינור בן-ציון
9000 ,באר שבע ,1430 ,דליה
9000 ,באר שבע ,177 ,דליה רביקוביץ
9000 ,באר שבע ,913 ,דן
9000 ,באר שבע ,1378 ,דנציגר יצחק
9000 ,באר שבע ,631 ,דפנה
9000 ,באר שבע ,1411 ,דקל
9000 ,באר שבע ,448 ,דקלה
9000 ,באר שבע ,1143 ,דקר
9000 ,באר שבע ,530 ,דר ניסתר
9000 ,באר שבע ,321 ,דרבן
9000 ,באר שבע ,1144 ,דרויאנוב אברהם
9000 ,באר שבע ,130 ,דרור
9000 ,באר שבע ,1294 ,דרזנר יחיאל
9000 ,באר שבע ,1220 ,דרך אביטל אביגדור
9000 ,באר שבע ,1389 ,דרך אילן רמון
9000 ,באר שבע ,702 ,דרך אילת
9000 ,באר שבע ,103 ,דרך אליהו נאוי
9000 ,באר שבע ,359 ,דרך ג'ו אלון
9000 ,באר שבע ,810 ,דרך המשחררים
9000 ,באר שבע ,102 ,דרך חברון
9000 ,באר שבע ,277 ,דרך מצדה
9000 ,באר שבע ,309 ,דרך שמשון
9000 ,באר שבע ,914 ,האבות
9000 ,באר שבע ,571 ,האדר"ת
9000 ,באר שבע ,1358 ,האוזנר גדעון
9000 ,באר שבע ,531 ,האורגים
9000 ,באר שבע ,1238 ,האז גיאורג
9000 ,באר שבע ,332 ,האיילה
9000 ,באר שבע ,211 ,האיסיים
9000 ,באר שבע ,272 ,האמוראים
9000 ,באר שבע ,141 ,האנרגיה
9000 ,באר שבע ,855 ,הבדיל
9000 ,באר שבע ,865 ,הבונים
9000 ,באר שבע ,886 ,הברזל
9000 ,באר שבע ,207 ,הבשור
9000 ,באר שבע ,603 ,הגאולים
9000 ,באר שבע ,1146 ,הגאונים
9000 ,באר שבע ,978 ,הגדודים
9000 ,באר שבע ,342 ,הגלבוע
9000 ,באר שבע ,887 ,הגנן
9000 ,באר שבע ,985 ,הגפן
9000 ,באר שבע ,916 ,הגר
9000 ,באר שבע ,852 ,הדגן
9000 ,באר שבע ,917 ,הדסה
9000 ,באר שבע ,1200 ,הדעת
9000 ,באר שבע ,1405 ,הדר
9000 ,באר שבע ,1319 ,הדרי זאב (ויניה)
9000 ,באר שבע ,918 ,ההגנה
9000 ,באר שבע ,919 ,ההסתדרות
9000 ,באר שבע ,408 ,הוברמן
9000 ,באר שבע ,1250 ,הוגו שמואל
9000 ,באר שבע ,662 ,הוכברג נתנאל
9000 ,באר שבע ,500 ,הולצברג שמחה
9000 ,באר שבע ,920 ,הושע
9000 ,באר שבע ,274 ,הזוהר
9000 ,באר שבע ,888 ,הזורע
9000 ,באר שבע ,421 ,הזז חיים
9000 ,באר שבע ,144 ,החדשנות
9000 ,באר שבע ,889 ,החורש
9000 ,באר שבע ,367 ,החיד"א
9000 ,באר שבע ,921 ,החלוץ
9000 ,באר שבע ,863 ,החרט
9000 ,באר שבע ,1147 ,החשמונאים
9000 ,באר שבע ,853 ,החשמלאי
9000 ,באר שבע ,869 ,הטחנה
9000 ,באר שבע ,858 ,היוצרים
9000 ,באר שבע ,1246 ,היזה פאול
9000 ,באר שבע ,1148 ,היילפרין ליפמן
9000 ,באר שבע ,604 ,הכ"ג
9000 ,באר שבע ,409 ,הכוזרי
9000 ,באר שבע ,809 ,הכותל המערבי
9000 ,באר שבע ,1259 ,הכט ראובן
9000 ,באר שבע ,251 ,הכנסת
9000 ,באר שבע ,615 ,הכשרת הישוב
9000 ,באר שבע ,959 ,הל"ה
9000 ,באר שבע ,247 ,הלל הזקן
9000 ,באר שבע ,989 ,הלמן אברהם
9000 ,באר שבע ,605 ,הלפרין
9000 ,באר שבע ,573 ,המאירי מנחם
9000 ,באר שבע ,574 ,המבי"ט
9000 ,באר שבע ,1240 ,המדע
9000 ,באר שבע ,575 ,המהר"ל
9000 ,באר שבע ,981 ,המוכתר
9000 ,באר שבע ,831 ,המוסכים
9000 ,באר שבע ,437 ,המייסדים
9000 ,באר שבע ,922 ,המכבים
9000 ,באר שבע ,864 ,המכונאי
9000 ,באר שבע ,212 ,המכתשים
9000 ,באר שבע ,833 ,המלאכה
9000 ,באר שבע ,1149 ,המלכים
9000 ,באר שבע ,859 ,המנוף
9000 ,באר שבע ,862 ,המסגר
9000 ,באר שבע ,990 ,המסילה
9000 ,באר שבע ,606 ,המעפילים
9000 ,באר שבע ,1337 ,המר זבולון
9000 ,באר שבע ,1296 ,המרד
9000 ,באר שבע ,1409 ,המשביר
9000 ,באר שבע ,866 ,המשק
9000 ,באר שבע ,215 ,הנבטים
9000 ,באר שבע ,1367 ,הנגבי
9000 ,באר שבע ,835 ,הנגרים
9000 ,באר שבע ,525 ,הנדיב
9000 ,באר שבע ,507 ,הנוטר
9000 ,באר שבע ,958 ,הנוקם
9000 ,באר שבע ,854 ,הנחושת
9000 ,באר שבע ,890 ,הנפח
9000 ,באר שבע ,812 ,הס משה
9000 ,באר שבע ,410 ,הסופרים
9000 ,באר שבע ,1016 ,הע"ל
9000 ,באר שבע ,6502 ,העיר העתיקה
9000 ,באר שבע ,304 ,העליה
9000 ,באר שבע ,953 ,העצמאות
9000 ,באר שבע ,860 ,הפועלים
9000 ,באר שבע ,861 ,הפחח
9000 ,באר שבע ,857 ,הפלדה
9000 ,באר שבע ,923 ,הפלמ"ח
9000 ,באר שבע ,856 ,הפלס
9000 ,באר שבע ,1055 ,הפרטיזנים
9000 ,באר שבע ,331 ,הצבי
9000 ,באר שבע ,281 ,הצדיק מירושלים
9000 ,באר שבע ,614 ,הציונות
9000 ,באר שבע ,1057 ,הצנחנים
9000 ,באר שבע ,252 ,הקנאים
9000 ,באר שבע ,6530 ,הקריה ע"ש א ברגמן
9000 ,באר שבע ,1282 ,הר בוקר
9000 ,באר שבע ,1283 ,הר בקע
9000 ,באר שבע ,1278 ,הר חרמון
9000 ,באר שבע ,1280 ,הר כנען
9000 ,באר שבע ,1285 ,הר מירון
9000 ,באר שבע ,1281 ,הר נס
9000 ,באר שבע ,1286 ,הר עודד
9000 ,באר שבע ,1277 ,הר עצמון
9000 ,באר שבע ,1276 ,הר רמון
9000 ,באר שבע ,1284 ,הר תבור
9000 ,באר שבע ,577 ,הר"ן
9000 ,באר שבע ,349 ,הרא"ש
9000 ,באר שבע ,557 ,הרב אונטרמן
9000 ,באר שבע ,899 ,הרב אלעזר קליין
9000 ,באר שבע ,269 ,הרב אסף שמחה
9000 ,באר שבע ,255 ,הרב גבאי מנחם
9000 ,באר שבע ,1375 ,הרב גורן שלמה
9000 ,באר שבע ,473 ,הרב הרצוג
9000 ,באר שבע ,1156 ,הרב חביב חיים
9000 ,באר שבע ,370 ,הרב חורי חיים
9000 ,באר שבע ,153 ,הרב טייב חי
9000 ,באר שבע ,435 ,הרב טנא שלמה
9000 ,באר שבע ,138 ,הרב כ"ץ אליהו
9000 ,באר שבע ,236 ,הרב מימון
9000 ,באר שבע ,490 ,הרב מרקדו
9000 ,באר שבע ,232 ,הרב סוסו יוסף
9000 ,באר שבע ,264 ,הרב סילברט
9000 ,באר שבע ,1176 ,הרב עדני
9000 ,באר שבע ,378 ,הרב פרדס
9000 ,באר שבע ,598 ,הרב פרנק
9000 ,באר שבע ,1189 ,הרב קפאח
9000 ,באר שבע ,1400 ,הרב ריינס
9000 ,באר שבע ,653 ,הרב רצאבי
9000 ,באר שבע ,1391 ,הרב שושנה
9000 ,באר שבע ,924 ,הרבבה
9000 ,באר שבע ,840 ,הרבי מלובביטש
9000 ,באר שבע ,576 ,הרדב"ז
9000 ,באר שבע ,1022 ,הרדוף
9000 ,באר שבע ,1324 ,הרמלין יוסף
9000 ,באר שבע ,925 ,הרצל
9000 ,באר שבע ,826 ,הרצפלד אברהם
9000 ,באר שבע ,892 ,הרתך
9000 ,באר שבע ,926 ,השבטים
9000 ,באר שבע ,983 ,השווקים
9000 ,באר שבע ,813 ,השומר
9000 ,באר שבע ,1388 ,השופט אלקיים
9000 ,באר שבע ,1352 ,השופטים
9000 ,באר שבע ,508 ,השלום
9000 ,באר שבע ,140 ,התבונה
9000 ,באר שבע ,1135 ,התורה הגואלת
9000 ,באר שבע ,1150 ,התיבונים
9000 ,באר שבע ,271 ,התלמוד
9000 ,באר שבע ,273 ,התנאים
9000 ,באר שבע ,828 ,התקוה
9000 ,באר שבע ,838 ,וולפסון דוד
9000 ,באר שבע ,1058 ,ויטנברג יצחק
9000 ,באר שבע ,1297 ,וייס יעקב
9000 ,באר שבע ,1298 ,וילנאי זאב
9000 ,באר שבע ,1377 ,וילנסקי משה
9000 ,באר שבע ,1059 ,וילנר אריה
9000 ,באר שבע ,509 ,וינגייט
9000 ,באר שבע ,1151 ,ויניק זאב
9000 ,באר שבע ,814 ,ויצמן
9000 ,באר שבע ,1215 ,וירשובסקי חיים
9000 ,באר שבע ,578 ,ויתקון אלפרד
9000 ,באר שבע ,1354 ,ולבר יצחק
9000 ,באר שבע ,874 ,ונטורה
9000 ,באר שבע ,1223 ,וקסמן זלמן
9000 ,באר שבע ,815 ,ורבורג
9000 ,באר שבע ,1021 ,ורד
9000 ,באר שבע ,310 ,ורדימון יצחק
9000 ,באר שבע ,1224 ,ורטהיימר חיים
9000 ,באר שבע ,607 ,ז'בוטינסקי זאב
9000 ,באר שבע ,194 ,ז'קלין כהנוב
9000 ,באר שבע ,927 ,זבולון
9000 ,באר שבע ,1213 ,זהרי מיכאל
9000 ,באר שבע ,168 ,זוהרה אלפסיה
9000 ,באר שבע ,579 ,זוסמן יואל
9000 ,באר שבע ,580 ,זילברג משה
9000 ,באר שבע ,1060 ,זינדר חמדה וצבי
9000 ,באר שבע ,510 ,זיסו
9000 ,באר שבע ,1044 ,זכות משה
9000 ,באר שבע ,928 ,זכריה
9000 ,באר שבע ,668 ,זלמן כספי
9000 ,באר שבע ,1152 ,זלפה
9000 ,באר שבע ,581 ,זמורה משה
9000 ,באר שבע ,1260 ,זמיר ישעיהו
9000 ,באר שבע ,1153 ,זמנהוף
9000 ,באר שבע ,1154 ,זנגויל ישראל
9000 ,באר שבע ,1216 ,זק"ש נלי
9000 ,באר שבע ,1155 ,זרובבל
9000 ,באר שבע ,318 ,זרזיר
9000 ,באר שבע ,1239 ,זרחין אלכסנדר
9000 ,באר שבע ,1325 ,זריצקי יוסף
9000 ,באר שבע ,658 ,ח"ן
9000 ,באר שבע ,1299 ,חביב אבשלום
9000 ,באר שבע ,582 ,חבס ברכה
9000 ,באר שבע ,1005 ,חבצלת הנגב
9000 ,באר שבע ,929 ,חבקוק
9000 ,באר שבע ,930 ,חגי
9000 ,באר שבע ,317 ,חגלה
9000 ,באר שבע ,1061 ,חובב משה
9000 ,באר שבע ,1157 ,חוה
9000 ,באר שבע ,125 ,חוחית
9000 ,באר שבע ,511 ,חומה ומגדל
9000 ,באר שבע ,1424 ,חופית
9000 ,באר שבע ,411 ,חז"ל
9000 ,באר שבע ,1062 ,חזמה יעקב
9000 ,באר שבע ,1308 ,חזן יעקב
9000 ,באר שבע ,470 ,חזני מיכאל
9000 ,באר שבע ,358 ,חזקיהו
9000 ,באר שבע ,1063 ,חטיבה שמונה
9000 ,באר שבע ,931 ,חטיבת הנגב
9000 ,באר שבע ,526 ,חיבת ציון
9000 ,באר שבע ,665 ,חיים סנה
9000 ,באר שבע ,111 ,חיים רביב
9000 ,באר שבע ,1300 ,חכים אליהו
9000 ,באר שבע ,1364 ,חלילי
9000 ,באר שבע ,142 ,חלקיקי האור
9000 ,באר שבע ,1330 ,חמיאל יצחק
9000 ,באר שבע ,1232 ,חן ארנסט
9000 ,באר שבע ,1158 ,חנה
9000 ,באר שבע ,176 ,חנה אבנור
9000 ,באר שבע ,932 ,חנני
9000 ,באר שבע ,522 ,חנני חיים
9000 ,באר שבע ,870 ,חנקין
9000 ,באר שבע ,129 ,חסידה
9000 ,באר שבע ,1403 ,חסמן מרק
9000 ,באר שבע ,1006 ,חצב
9000 ,באר שבע ,384 ,חצור
9000 ,באר שבע ,327 ,חרדון
9000 ,באר שבע ,1397 ,חרוב
9000 ,באר שבע ,1025 ,חרצית
9000 ,באר שבע ,583 ,חשין
9000 ,באר שבע ,368 ,חת"ם סופר
9000 ,באר שבע ,875 ,טאוב
9000 ,באר שבע ,1159 ,טביוב
9000 ,באר שבע ,340 ,טבנקין
9000 ,באר שבע ,389 ,טבריה
9000 ,באר שבע ,1347 ,טדסקי גד
9000 ,באר שבע ,1160 ,טוב משה
9000 ,באר שבע ,180 ,טובה ברץ
9000 ,באר שבע ,173 ,טובה סנהדראי
9000 ,באר שבע ,1310 ,טווס
9000 ,באר שבע ,659 ,טולידנו
9000 ,באר שבע ,1245 ,טור סיני נפתלי
9000 ,באר שבע ,1064 ,טיומקין זאב
9000 ,באר שבע ,1092 ,טל חרמון
9000 ,באר שבע ,584 ,טלמון יעקב
9000 ,באר שבע ,1065 ,טננבוים מרדכי
9000 ,באר שבע ,164 ,טרודי קוזלובסקי
9000 ,באר שבע ,933 ,טרומפלדור
9000 ,באר שבע ,1342 ,טרק גבריאל
9000 ,באר שבע ,412 ,טשרניחובסקי
9000 ,באר שבע ,436 ,יא' הספורטאים
9000 ,באר שבע ,851 ,יאיר
9000 ,באר שבע ,299 ,יאשיהו
9000 ,באר שבע ,608 ,יד ושם
9000 ,באר שבע ,354 ,יהוא
9000 ,באר שבע ,376 ,יהואש
9000 ,באר שבע ,263 ,יהודה הלוי
9000 ,באר שבע ,843 ,יהודה הנחתום
9000 ,באר שבע ,250 ,יהודה הנשיא
9000 ,באר שבע ,352 ,יהודי סוריה
9000 ,באר שבע ,357 ,יהויכין
9000 ,באר שבע ,297 ,יהורם
9000 ,באר שבע ,846 ,יהושע הצורף
9000 ,באר שבע ,371 ,יהושפט
9000 ,באר שבע ,218 ,יואל השופט
9000 ,באר שבע ,1066 ,יוהנה ז'בוטינסקי
9000 ,באר שבע ,242 ,יוחנן בן זכאי
9000 ,באר שבע ,219 ,יוחנן הורקנוס
9000 ,באר שבע ,585 ,יוכבד בת מרים
9000 ,באר שבע ,935 ,יונה הנביא
9000 ,באר שבע ,844 ,יוסי הבורסקאי
9000 ,באר שבע ,936 ,יוסף
9000 ,באר שבע ,276 ,יוסף בן מתתיהו
9000 ,באר שבע ,915 ,יוסף ומאיר
9000 ,באר שבע ,225 ,יוספטל גיורא
9000 ,באר שבע ,1366 ,יוסקה
9000 ,באר שבע ,937 ,יחזקאל
9000 ,באר שבע ,885 ,יחיל חיים
9000 ,באר שבע ,220 ,יטבתה
9000 ,באר שבע ,1067 ,ייבין יהושע
9000 ,באר שבע ,1068 ,יל"ג
9000 ,באר שבע ,1249 ,ילין דוד
9000 ,באר שבע ,1316 ,ילין-מור
9000 ,באר שבע ,586 ,ילן-שטקליס מרים
9000 ,באר שבע ,1326 ,ינקו מרסל
9000 ,באר שבע ,124 ,ינשוף
9000 ,באר שבע ,1018 ,יסמין
9000 ,באר שבע ,128 ,יסעור
9000 ,באר שבע ,816 ,יסקי
9000 ,באר שבע ,237 ,יעבץ יעקב
9000 ,באר שבע ,1318 ,יעקב (בודה) עורב
9000 ,באר שבע ,221 ,יעקב אבינו
9000 ,באר שבע ,439 ,יעקב עורבי
9000 ,באר שבע ,1444 ,יערה
9000 ,באר שבע ,1102 ,יערי מאיר
9000 ,באר שבע ,1338 ,יפה אברהם
9000 ,באר שבע ,170 ,יפה ירקוני
9000 ,באר שבע ,842 ,יפה לייב
9000 ,באר שבע ,977 ,יפתח הגלעדי
9000 ,באר שבע ,222 ,יצחק אבינו
9000 ,באר שבע ,847 ,יצחק נפחא
9000 ,באר שבע ,301 ,יציאת אירופה
9000 ,באר שבע ,333 ,ירבוע
9000 ,באר שבע ,1422 ,ירדן
9000 ,באר שבע ,382 ,יריחו
9000 ,באר שבע ,934 ,ירמיהו
9000 ,באר שבע ,587 ,ירקוני עמוס
9000 ,באר שבע ,588 ,יש"א ברכ"ה
9000 ,באר שבע ,938 ,ישמעאל
9000 ,באר שבע ,939 ,ישעיהו הנביא
9000 ,באר שבע ,491 ,ישעיהו ישראל
9000 ,באר שבע ,105 ,ישראל ריבק
9000 ,באר שבע ,940 ,יששכר
9000 ,באר שבע ,613 ,כהן אלי
9000 ,באר שבע ,422 ,כהן יעקב
9000 ,באר שבע ,896 ,כהן לאונרד
9000 ,באר שבע ,351 ,כחול לבן
9000 ,באר שבע ,6026 ,כיכר חנניה עמר
9000 ,באר שבע ,6016 ,כיכר שפרן אלכסנדר
9000 ,באר שבע ,6001 ,ככר אביר
9000 ,באר שבע ,6022 ,ככר אדיס אבבה
9000 ,באר שבע ,6019 ,ככר איפרגן
9000 ,באר שבע ,6024 ,ככר אקלום פרדה יזזא
9000 ,באר שבע ,6000 ,ככר ארגוני נשים
9000 ,באר שבע ,6002 ,ככר בני שמעון
9000 ,באר שבע ,6012 ,ככר הבונים החופשים
9000 ,באר שבע ,6003 ,ככר הברית
9000 ,באר שבע ,6004 ,ככר הגנים
9000 ,באר שבע ,6017 ,ככר המתנדבים
9000 ,באר שבע ,6005 ,ככר הקיני
9000 ,באר שבע ,6006 ,ככר הקניזי
9000 ,באר שבע ,6015 ,ככר התאומים
9000 ,באר שבע ,6013 ,ככר וופרטל
9000 ,באר שבע ,6007 ,ככר זיסו
9000 ,באר שבע ,6014 ,ככר ליון
9000 ,באר שבע ,6018 ,ככר מונטריאול
9000 ,באר שבע ,6008 ,ככר מעון
9000 ,באר שבע ,6025 ,ככר סגל שרגא
9000 ,באר שבע ,6023 ,ככר סימון
9000 ,באר שבע ,6021 ,ככר פרידמן
9000 ,באר שבע ,6009 ,ככר קפלן
9000 ,באר שבע ,6011 ,ככר רוטרי
9000 ,באר שבע ,6010 ,ככר רחבים
9000 ,באר שבע ,1019 ,כלנית
9000 ,באר שבע ,971 ,כפר הדרום
9000 ,באר שבע ,6560 ,כפר רפאל
9000 ,באר שבע ,817 ,כצנלסון
9000 ,באר שבע ,1443 ,כרכום
9000 ,באר שבע ,512 ,כרמלי
9000 ,באר שבע ,1162 ,לאה
9000 ,באר שבע ,700 ,לאה רבין
9000 ,באר שבע ,1413 ,לאון אדרי
9000 ,באר שבע ,1333 ,לב משה
9000 ,באר שבע ,1340 ,לבון פנחס
9000 ,באר שבע ,1069 ,לברי מרק
9000 ,באר שבע ,897 ,להב
9000 ,באר שבע ,1163 ,לוזון אפרים
9000 ,באר שבע ,827 ,לוין שמריהו
9000 ,באר שבע ,1301 ,לויצקי יעקב
9000 ,באר שבע ,1339 ,לוריא צבי
9000 ,באר שבע ,1419 ,ליבנה
9000 ,באר שבע ,1257 ,ליליאן
9000 ,באר שבע ,1023 ,לילך
9000 ,באר שבע ,1398 ,לימון
9000 ,באר שבע ,635 ,לימונית
9000 ,באר שבע ,1212 ,לינדנר יוחנן
9000 ,באר שבע ,1209 ,ליפמן גבריאל
9000 ,באר שבע ,1164 ,לישנסקי יוסף
9000 ,באר שבע ,383 ,לכיש
9000 ,באר שבע ,423 ,למדן יצחק
9000 ,באר שבע ,1105 ,לנדאו חיים
9000 ,באר שבע ,1165 ,לסקר פרץ
9000 ,באר שבע ,120 ,לרון אפרים
9000 ,באר שבע ,1070 ,מאפו אברהם
9000 ,באר שבע ,162 ,מארי קירי
9000 ,באר שבע ,960 ,מבצע אסף
9000 ,באר שבע ,280 ,מבצע דני
9000 ,באר שבע ,293 ,מבצע חורב
9000 ,באר שבע ,292 ,מבצע חירם
9000 ,באר שבע ,285 ,מבצע יואב
9000 ,באר שבע ,282 ,מבצע לוט
9000 ,באר שבע ,1071 ,מבצע משה
9000 ,באר שבע ,283 ,מבצע נחשון
9000 ,באר שבע ,284 ,מבצע עובדה
9000 ,באר שבע ,388 ,מגידו
9000 ,באר שבע ,295 ,מודיעין
9000 ,באר שבע ,1329 ,מודעי יצחק
9000 ,באר שבע ,1402 ,מוטה גור
9000 ,באר שבע ,1166 ,מויאל אליהו
9000 ,באר שבע ,1167 ,מולכו שלמה
9000 ,באר שבע ,818 ,מונטיפיורי
9000 ,באר שבע ,385 ,מוצא
9000 ,באר שבע ,941 ,מורדי הגטאות
9000 ,באר שבע ,104 ,מורטון מנדל
9000 ,באר שבע ,849 ,מוריה
9000 ,באר שבע ,589 ,מזוז מצליח
9000 ,באר שבע ,1380 ,מזר בנימין
9000 ,באר שבע ,876 ,מזרחי
9000 ,באר שבע ,1052 ,מי מרום
9000 ,באר שבע ,1217 ,מיכאלסון יצחק
9000 ,באר שבע ,942 ,מיכה
9000 ,באר שבע ,1168 ,מיכל
9000 ,באר שבע ,429 ,מינץ
9000 ,באר שבע ,379 ,מכללת קיי
9000 ,באר שבע ,943 ,מלאכי
9000 ,באר שבע ,961 ,מלון ערבה
9000 ,באר שבע ,839 ,מלון פרדייז
9000 ,באר שבע ,893 ,מלל סעדיה
9000 ,באר שבע ,478 ,ממשית
9000 ,באר שבע ,819 ,מנדלי מוכר ספרים
9000 ,באר שבע ,413 ,מנדלסון
9000 ,באר שבע ,537 ,מנצ'ל פועה ד"ר
9000 ,באר שבע ,944 ,מנשה
9000 ,באר שבע ,590 ,מסקין אהרון
9000 ,באר שבע ,300 ,מעון עולים
9000 ,באר שבע ,1302 ,מעלה החטיבה
9000 ,באר שבע ,337 ,מעלה עקרבים
9000 ,באר שבע ,1083 ,מקור חיים
9000 ,באר שבע ,377 ,מקלף מרדכי
9000 ,באר שבע ,302 ,מרבד הקסמים
9000 ,באר שבע ,877 ,מרגולין
9000 ,באר שבע ,167 ,מרגיט להמן
9000 ,באר שבע ,1328 ,מרגלית מאיר
9000 ,באר שבע ,632 ,מרווה
9000 ,באר שבע ,223 ,מרטון יחזקאל
9000 ,באר שבע ,1034 ,מרידור
9000 ,באר שבע ,189 ,מרים בן פורת
9000 ,באר שבע ,1169 ,מרים הנביאה
9000 ,באר שבע ,609 ,מרכוס
9000 ,באר שבע ,6605 ,מרכז אזרחי
9000 ,באר שבע ,1470 ,מרכז ביג
9000 ,באר שבע ,426 ,מרכז הנגב
9000 ,באר שבע ,992 ,מרכז קליטה א
9000 ,באר שבע ,400 ,מרכז קליטה ג
9000 ,באר שבע ,536 ,מרכז קליטה חרוב
9000 ,באר שבע ,330 ,מרכז קליטה יעלים
9000 ,באר שבע ,535 ,מרכז קליטה קלישר
9000 ,באר שבע ,1466 ,מרכז רפואי סורוקה
9000 ,באר שבע ,1170 ,מרלא
9000 ,באר שבע ,945 ,משה אדר
9000 ,באר שבע ,1241 ,משה יצחק
9000 ,באר שבע ,1271 ,משה לוי
9000 ,באר שבע ,287 ,משעול בית"ר
9000 ,באר שבע ,452 ,משעול גבעון
9000 ,באר שבע ,954 ,משעול הערבה
9000 ,באר שבע ,481 ,משעול חפר
9000 ,באר שבע ,1208 ,משעול טם איגור
9000 ,באר שבע ,294 ,משעול יודפת
9000 ,באר שבע ,951 ,משעול לוי
9000 ,באר שבע ,399 ,משעול מבצע יונתן
9000 ,באר שבע ,451 ,משעול מצפה
9000 ,באר שבע ,336 ,משעול ניצנה
9000 ,באר שבע ,380 ,משעול סוסיה
9000 ,באר שבע ,453 ,משעול עקרון
9000 ,באר שבע ,1104 ,משעול פנקס דוד
9000 ,באר שבע ,946 ,מתתיהו
9000 ,באר שבע ,6602 ,נאות אילן
9000 ,באר שבע ,338 ,נאות הככר
9000 ,באר שבע ,6601 ,נאות חצרים
9000 ,באר שבע ,6565 ,נאות לון
9000 ,באר שבע ,339 ,נאות מדבר
9000 ,באר שבע ,947 ,נגבה
9000 ,באר שבע ,375 ,נדב
9000 ,באר שבע ,836 ,נהרים
9000 ,באר שבע ,6600 ,נווה זאב
9000 ,באר שבע ,6555 ,נווה מנחם
9000 ,באר שבע ,1219 ,נויפלד הנרי
9000 ,באר שבע ,533 ,נורדאו מקס
9000 ,באר שבע ,1020 ,נורית
9000 ,באר שבע ,948 ,נחום
9000 ,באר שבע ,677 ,נחל בוקק
9000 ,באר שבע ,685 ,נחל גוב
9000 ,באר שבע ,693 ,נחל דישון
9000 ,באר שבע ,691 ,נחל דליות
9000 ,באר שבע ,670 ,נחל חבר
9000 ,באר שבע ,682 ,נחל חיון
9000 ,באר שבע ,679 ,נחל יבניאל
9000 ,באר שבע ,676 ,נחל יהל
9000 ,באר שבע ,678 ,נחל ימין
9000 ,באר שבע ,687 ,נחל לבן
9000 ,באר שבע ,688 ,נחל משושים
9000 ,באר שבע ,674 ,נחל משמר
9000 ,באר שבע ,683 ,נחל נטיפים
9000 ,באר שבע ,672 ,נחל נקרות
9000 ,באר שבע ,692 ,נחל סער
9000 ,באר שבע ,681 ,נחל עמוד
9000 ,באר שבע ,689 ,נחל ערוגות
9000 ,באר שבע ,6556 ,נחל עשן
9000 ,באר שבע ,684 ,נחל פרס
9000 ,באר שבע ,671 ,נחל פרת
9000 ,באר שבע ,690 ,נחל ציחור
9000 ,באר שבע ,680 ,נחל צלמון
9000 ,באר שבע ,673 ,נחל קדרון
9000 ,באר שבע ,675 ,נחל שילה
9000 ,באר שבע ,224 ,נחליאל
9000 ,באר שבע ,1315 ,נטר
9000 ,באר שבע ,1072 ,ניב דוד
9000 ,באר שבע ,513 ,ניל"י
9000 ,באר שבע ,878 ,ניסנמן
9000 ,באר שבע ,550 ,ניצני עזריאל
9000 ,באר שבע ,1171 ,ניר נחום
9000 ,באר שבע ,1109 ,נמיר מרדכי
9000 ,באר שבע ,1172 ,נעמי
9000 ,באר שבע ,160 ,נעמי שמר
9000 ,באר שבע ,949 ,נפתלי
9000 ,באר שבע ,1303 ,נקר מאיר
9000 ,באר שבע ,260 ,נרבוני
9000 ,באר שבע ,1027 ,נרקיס
9000 ,באר שבע ,1360 ,נרקיס עוזי
9000 ,באר שבע ,127 ,נשר
9000 ,באר שבע ,1106 ,סבידור מנחם
9000 ,באר שבע ,1406 ,סביון
9000 ,באר שבע ,1252 ,סגל צבי
9000 ,באר שבע ,1242 ,סדן דב
9000 ,באר שבע ,867 ,סדנא
9000 ,באר שבע ,1097 ,סוד השבועה
9000 ,באר שבע ,192 ,סוזן דניאל-נטף
9000 ,באר שבע ,214 ,סוכות
9000 ,באר שבע ,837 ,סולד הנריאטה
9000 ,באר שבע ,820 ,סוקולוב
9000 ,באר שבע ,591 ,סטולר שמואל
9000 ,באר שבע ,303 ,סטרומה
9000 ,באר שבע ,1442 ,סיגלון
9000 ,באר שבע ,1407 ,סייפן
9000 ,באר שבע ,1073 ,סילמן יהודה
9000 ,באר שבע ,1230 ,סימון ארנסט דוד
9000 ,באר שבע ,335 ,סיני
9000 ,באר שבע ,1074 ,סירני
9000 ,באר שבע ,879 ,סלמה
9000 ,באר שבע ,592 ,סלנט שמואל
9000 ,באר שבע ,315 ,סלעית
9000 ,באר שבע ,979 ,סמ הגדם
9000 ,באר שבע ,265 ,סמ יהונתן
9000 ,באר שבע ,253 ,סמ ערד
9000 ,באר שבע ,230 ,סמ צקלג
9000 ,באר שבע ,980 ,סמ קטורה
9000 ,באר שבע ,972 ,סמ ראומה
9000 ,באר שבע ,233 ,סמ רפידים
9000 ,באר שבע ,1255 ,סמבורסקי שמואל
9000 ,באר שבע ,1173 ,סמולנסקין
9000 ,באר שבע ,821 ,סמטס
9000 ,באר שבע ,1075 ,סמילן רות
9000 ,באר שבע ,950 ,סמילנסקי
9000 ,באר שבע ,1396 ,סנה משה
9000 ,באר שבע ,1416 ,סנה נחום
9000 ,באר שבע ,244 ,סנהדרין
9000 ,באר שבע ,514 ,סנש חנה
9000 ,באר שבע ,414 ,סעדיה גאון
9000 ,באר שבע ,1076 ,סקולסקי שלמה
9000 ,באר שבע ,1175 ,סרלין יוסף
9000 ,באר שבע ,1077 ,סרן דב
9000 ,באר שבע ,593 ,סרנה יחזקאל
9000 ,באר שבע ,1078 ,סתוי משה
9000 ,באר שבע ,1008 ,סתונית
9000 ,באר שבע ,488 ,עבדת
9000 ,באר שבע ,1312 ,עגור
9000 ,באר שבע ,344 ,עגנון
9000 ,באר שבע ,158 ,עדה גלר
9000 ,באר שבע ,226 ,עוזיה המלך
9000 ,באר שבע ,610 ,עולי הגרדום
9000 ,באר שבע ,182 ,עופרה חזה
9000 ,באר שבע ,312 ,עורב
9000 ,באר שבע ,126 ,עיט
9000 ,באר שבע ,484 ,עין גדי
9000 ,באר שבע ,1004 ,עירית
9000 ,באר שבע ,483 ,עכו
9000 ,באר שבע ,519 ,עליאש מרדכי
9000 ,באר שבע ,156 ,עליזה בגין
9000 ,באר שבע ,185 ,עליזה בירון
9000 ,באר שבע ,952 ,עמוס
9000 ,באר שבע ,1435 ,עמיעז
9000 ,באר שבע ,1348 ,עמיצור שמשון
9000 ,באר שבע ,594 ,עמיר אנדה
9000 ,באר שבע ,868 ,עמל
9000 ,באר שבע ,6608 ,עמק שרה
9000 ,באר שבע ,296 ,עמרי
9000 ,באר שבע ,139 ,עפרוני
9000 ,באר שבע ,323 ,עפרים
9000 ,באר שבע ,1177 ,פאבל
9000 ,באר שבע ,179 ,פאני מוגרבי
9000 ,באר שבע ,227 ,פארן
9000 ,באר שבע ,157 ,פולה בן גוריון
9000 ,באר שבע ,1178 ,פועה
9000 ,באר שבע ,1080 ,פורת לאה
9000 ,באר שבע ,1368 ,פורת קלמן
9000 ,באר שבע ,1000 ,פטל
9000 ,באר שבע ,1382 ,פטנקין דן
9000 ,באר שבע ,305 ,פטריה
9000 ,באר שבע ,1081 ,פיארברג
9000 ,באר שבע ,1179 ,פייגנבאום אריה
9000 ,באר שבע ,1180 ,פיינברג אבשלום
9000 ,באר שבע ,1304 ,פיינשטיין מאיר
9000 ,באר שבע ,1082 ,פיכמן יעקב
9000 ,באר שבע ,1181 ,פילדרמן
9000 ,באר שבע ,133 ,פינסקר
9000 ,באר שבע ,1401 ,פינקל שמעון
9000 ,באר שבע ,1349 ,פיקרד
9000 ,באר שבע ,1269 ,פלדברג שמואל
9000 ,באר שבע ,1379 ,פלוסר דוד
9000 ,באר שבע ,166 ,פלורי אשר
9000 ,באר שבע ,1211 ,פליקס
9000 ,באר שבע ,1028 ,פלפלון
9000 ,באר שבע ,845 ,פנחס החוצב
9000 ,באר שבע ,595 ,פני משה
9000 ,באר שבע ,1182 ,פנינה
9000 ,באר שבע ,326 ,פסמון
9000 ,באר שבע ,1429 ,פעמונית
9000 ,באר שבע ,1009 ,פקועה
9000 ,באר שבע ,1218 ,פראוור יהושע
9000 ,באר שבע ,415 ,פרויד
9000 ,באר שבע ,596 ,פרומקין גד
9000 ,באר שבע ,438 ,פרופ' מחרז אברהם
9000 ,באר שבע ,975 ,פרופ' עלי דיוויס
9000 ,באר שבע ,1460 ,פרופ' רוזן נתן
9000 ,באר שבע ,1197 ,פרופ' שיבא
9000 ,באר שבע ,1084 ,פרטוש עדן
9000 ,באר שבע ,597 ,פרי חדש
9000 ,באר שבע ,986 ,פריאר רחה
9000 ,באר שבע ,1343 ,פריבס משה
9000 ,באר שבע ,1202 ,פריד אלפרד
9000 ,באר שבע ,661 ,פריץ אלברט
9000 ,באר שבע ,1085 ,פרישמן דוד
9000 ,באר שבע ,1207 ,פרנק ג'ימס
9000 ,באר שבע ,1183 ,פרסיץ שושנה
9000 ,באר שבע ,1086 ,פרץ י ל
9000 ,באר שבע ,123 ,פשוש
9000 ,באר שבע ,1184 ,צ'לנוב יחיאל
9000 ,באר שבע ,1024 ,צאלון
9000 ,באר שבע ,1421 ,צאלים
9000 ,באר שבע ,1261 ,צבי-רן שלמה
9000 ,באר שבע ,228 ,צביה המלכה
9000 ,באר שבע ,1087 ,צביה ויצחק
9000 ,באר שבע ,1010 ,צבעוני
9000 ,באר שבע ,1011 ,צבר
9000 ,באר שבע ,356 ,צדקיהו
9000 ,באר שבע ,1229 ,צונדק ברנרד והרמן
9000 ,באר שבע ,1433 ,צוער
9000 ,באר שבע ,119 ,צוקית
9000 ,באר שבע ,1101 ,ציזלינג
9000 ,באר שבע ,229 ,צין
9000 ,באר שבע ,143 ,ציר הזמן
9000 ,באר שבע ,1185 ,צפורה
9000 ,באר שבע ,387 ,צפורי
9000 ,באר שבע ,955 ,צפניה
9000 ,באר שבע ,1279 ,צפצפה
9000 ,באר שבע ,345 ,צפת
9000 ,באר שבע ,306 ,קדושי בגדד
9000 ,באר שבע ,1186 ,קדושי יאסי
9000 ,באר שבע ,1187 ,קדושי סלוניקי
9000 ,באר שבע ,492 ,קדיש לוז
9000 ,באר שבע ,231 ,קדש
9000 ,באר שבע ,1394 ,קהירי
9000 ,באר שבע ,1030 ,קובנר אבא
9000 ,באר שבע ,6515 ,קוטגים
9000 ,באר שבע ,455 ,קויפמן
9000 ,באר שבע ,1079 ,קול התור
9000 ,באר שבע ,1033 ,קול מבשר
9000 ,באר שבע ,314 ,קורא
9000 ,באר שבע ,1188 ,קורא הדורות
9000 ,באר שבע ,600 ,קורבן העדה
9000 ,באר שבע ,634 ,קורנית
9000 ,באר שבע ,880 ,קטינקא
9000 ,באר שבע ,636 ,קינמון
9000 ,באר שבע ,496 ,קיסריה
9000 ,באר שבע ,322 ,קיפוד
9000 ,באר שבע ,962 ,קיפניס לוין
9000 ,באר שבע ,1423 ,קישון
9000 ,באר שבע ,520 ,קלאוזנר
9000 ,באר שבע ,515 ,קלישר
9000 ,באר שבע ,599 ,קלצ'קין רפאל
9000 ,באר שבע ,982 ,קנדל הנרי
9000 ,באר שבע ,703 ,קניון הנגב
9000 ,באר שבע ,307 ,קפריסין
9000 ,באר שבע ,1234 ,קציר אהרון
9000 ,באר שבע ,1386 ,קראוס גרטרוד
9000 ,באר שבע ,829 ,קרויצר מנחם
9000 ,באר שבע ,881 ,קרונמן
9000 ,באר שבע ,1244 ,קרוק גלעד דורותיה
9000 ,באר שבע ,516 ,קרייתי
9000 ,באר שבע ,6500 ,קרית וולפסון
9000 ,באר שבע ,6607 ,קרית יהודית
9000 ,באר שבע ,612 ,קרן היסוד
9000 ,באר שבע ,956 ,קרן קיימת לישראל
9000 ,באר שבע ,1305 ,קשאני אליעזר
9000 ,באר שבע ,957 ,ראובן
9000 ,באר שבע ,1145 ,רב האי גאון
9000 ,באר שבע ,278 ,רבי טרפון
9000 ,באר שבע ,240 ,רבי מאיר בעל הנס
9000 ,באר שבע ,254 ,רבי עקיבא
9000 ,באר שבע ,249 ,רבן גמליאל
9000 ,באר שבע ,288 ,רבנו תם
9000 ,באר שבע ,1190 ,רבקה
9000 ,באר שבע ,350 ,רד"ק
9000 ,באר שבע ,1334 ,רובין דב
9000 ,באר שבע ,420 ,רובין ראובן
9000 ,באר שבע ,651 ,רובינא חנה
9000 ,באר שבע ,882 ,רובק
9000 ,באר שבע ,652 ,רודנסקי שמואל
9000 ,באר שבע ,191 ,רוזה גינצברג גינוסר
9000 ,באר שבע ,633 ,רוזמרין
9000 ,באר שבע ,1191 ,רוזן פנחס
9000 ,באר שבע ,1089 ,רוזנבלום הרצל
9000 ,באר שבע ,1090 ,רוט אגון
9000 ,באר שבע ,517 ,רוטנברג
9000 ,באר שבע ,898 ,רוטנברג יוליה ושמוא
9000 ,באר שבע ,1390 ,רוטנשטרייך
9000 ,באר שבע ,1091 ,רונאל דב
9000 ,באר שבע ,270 ,רוסי אלפרד
9000 ,באר שבע ,471 ,רופין ארתור
9000 ,באר שבע ,1107 ,רוקח ישראל
9000 ,באר שבע ,1192 ,רות
9000 ,באר שבע ,178 ,רות הילמן
9000 ,באר שבע ,1012 ,רותם
9000 ,באר שבע ,524 ,רזיאל
9000 ,באר שבע ,1365 ,רחבעם זאבי
9000 ,באר שבע ,477 ,רחבת אופירה
9000 ,באר שבע ,391 ,רחבת אחזיה
9000 ,באר שבע ,461 ,רחבת איבצן
9000 ,באר שבע ,462 ,רחבת אלכסנדרוני
9000 ,באר שבע ,392 ,רחבת אמציה
9000 ,באר שבע ,973 ,רחבת באר אברהם
9000 ,באר שבע ,466 ,רחבת בן נון
9000 ,באר שבע ,362 ,רחבת בן עטר
9000 ,באר שבע ,476 ,רחבת גדעון
9000 ,באר שבע ,458 ,רחבת המרי העברי
9000 ,באר שבע ,465 ,רחבת הראל
9000 ,באר שבע ,361 ,רחבת הרב עוזיאל
9000 ,באר שבע ,364 ,רחבת הרב קוק
9000 ,באר שבע ,365 ,רחבת השל"ה
9000 ,באר שבע ,523 ,רחבת חי"ל
9000 ,באר שבע ,480 ,רחבת יבנה
9000 ,באר שבע ,393 ,רחבת יותם
9000 ,באר שבע ,482 ,רחבת ימית
9000 ,באר שבע ,529 ,רחבת יעל
9000 ,באר שבע ,464 ,רחבת לח"י
9000 ,באר שבע ,397 ,רחבת מבצע ארז
9000 ,באר שבע ,390 ,רחבת מבצע ברוש
9000 ,באר שבע ,396 ,רחבת מבצע יקב
9000 ,באר שבע ,398 ,רחבת מבצע קדם
9000 ,באר שבע ,395 ,רחבת מבצע קילשון
9000 ,באר שבע ,457 ,רחבת מולדת
9000 ,באר שבע ,456 ,רחבת מילוס
9000 ,באר שבע ,474 ,רחבת עשהאל
9000 ,באר שבע ,463 ,רחבת פל-ים
9000 ,באר שבע ,363 ,רחבת רמ"א
9000 ,באר שבע ,394 ,רחבת שלומציון
9000 ,באר שבע ,459 ,רחבת שמגר השופט
9000 ,באר שבע ,1193 ,רחל אמנו
9000 ,באר שבע ,425 ,רחל המשוררת
9000 ,באר שבע ,1266 ,רחל ינאית
9000 ,באר שבע ,193 ,רחל צברי
9000 ,באר שבע ,1206 ,ריבי איזידור
9000 ,באר שבע ,637 ,ריחן
9000 ,באר שבע ,1093 ,רייס רפאל
9000 ,באר שבע ,527 ,רייק חביבה
9000 ,באר שבע ,1373 ,רימון
9000 ,באר שבע ,1194 ,רימלט אלימלך
9000 ,באר שבע ,258 ,רינגלבלום
9000 ,באר שבע ,1225 ,רינר מרכוס
9000 ,באר שבע ,964 ,רמב"ם
9000 ,באר שבע ,289 ,רמב"ן
9000 ,באר שבע ,6610 ,רמות באר שבע
9000 ,באר שבע ,416 ,רמח"ל
9000 ,באר שבע ,494 ,רמלה
9000 ,באר שבע ,1210 ,רנה קסן
9000 ,באר שבע ,1309 ,רפאל איתן (רפול)
9000 ,באר שבע ,1226 ,רקח יואל
9000 ,באר שבע ,1026 ,רקפת
9000 ,באר שבע ,275 ,רש"י
9000 ,באר שבע ,1392 ,רש"ל
9000 ,באר שבע ,654 ,רשב"א
9000 ,באר שבע ,6576 ,ש ביח רוקיה
9000 ,באר שבע ,6616 ,ש בתי אבן
9000 ,באר שבע ,6595 ,ש דרום חדש
9000 ,באר שבע ,6596 ,ש חצרים
9000 ,באר שבע ,6550 ,ש יא
9000 ,באר שבע ,6617 ,ש משכנות
9000 ,באר שבע ,6597 ,ש סולל בונה
9000 ,באר שבע ,6577 ,ש עובדים ב
9000 ,באר שבע ,6585 ,ש עממי א
9000 ,באר שבע ,6618 ,ש עממי ג
9000 ,באר שבע ,6578 ,ש רסקו
9000 ,באר שבע ,353 ,שאול המלך
9000 ,באר שבע ,1195 ,שאול ואליהו
9000 ,באר שבע ,308 ,שאר ישוב
9000 ,באר שבע ,848 ,שבזי
9000 ,באר שבע ,1355 ,שד אשכול
9000 ,באר שבע ,101 ,שד בן גוריון
9000 ,באר שבע ,1270 ,שד בנ"צ כרמל
9000 ,באר שבע ,472 ,שד גולדה מאיר
9000 ,באר שבע ,1267 ,שד האנדרטה
9000 ,באר שבע ,1056 ,שד הנשיא הרצוג חיים
9000 ,באר שבע ,1369 ,שד חכם דוד
9000 ,באר שבע ,366 ,שד טוביהו דוד
9000 ,באר שבע ,485 ,שד ידין יגאל
9000 ,באר שבע ,311 ,שד יעלים
9000 ,באר שבע ,360 ,שד ירושלים
9000 ,באר שבע ,1336 ,שד מרש יעקב
9000 ,באר שבע ,1370 ,שד צה"ל
9000 ,באר שבע ,811 ,שד רגר יצחק
9000 ,באר שבע ,1410 ,שד רוטשילד
9000 ,באר שבע ,965 ,שדה יצחק
9000 ,באר שבע ,145 ,שדרת היחסות
9000 ,באר שבע ,1356 ,שדרת הערים התאומות
9000 ,באר שבע ,995 ,שדרת חיל הנדסה
9000 ,באר שבע ,135 ,שוחט ישראל
9000 ,באר שבע ,1196 ,שולמית
9000 ,באר שבע ,495 ,שומרון
9000 ,באר שבע ,518 ,שועלי שמשון
9000 ,באר שבע ,1335 ,שופט אברהם
9000 ,באר שבע ,1094 ,שופמן גרשון
9000 ,באר שבע ,1436 ,שורק
9000 ,באר שבע ,174 ,שושנה דמארי
9000 ,באר שבע ,195 ,שושנה שבבו
9000 ,באר שבע ,701 ,שז"ר זלמן
9000 ,באר שבע ,823 ,שח"ל
9000 ,באר שבע ,1432 ,שחף
9000 ,באר שבע ,1013 ,שטה
9000 ,באר שבע ,656 ,שטיין ריכרד
9000 ,באר שבע ,655 ,שטיינברג יהודה
9000 ,באר שבע ,417 ,שטרוק
9000 ,באר שבע ,1327 ,שטרייכמן יחזקאל
9000 ,באר שבע ,1341 ,שטרן יוסף
9000 ,באר שבע ,479 ,שיבטה
9000 ,באר שבע ,1014 ,שיזף
9000 ,באר שבע ,460 ,שיטרית
9000 ,באר שבע ,1357 ,שינדלר אוסקר
9000 ,באר שבע ,1434 ,שיפון
9000 ,באר שבע ,830 ,שיפר יצחק
9000 ,באר שבע ,1254 ,שירמן חיים
9000 ,באר שבע ,6587 ,שכ א
9000 ,באר שבע ,6579 ,שכ ב
9000 ,באר שבע ,6620 ,שכ ג
9000 ,באר שבע ,6501 ,שכ ד
9000 ,באר שבע ,6568 ,שכ ה
9000 ,באר שבע ,6503 ,שכ ו
9000 ,באר שבע ,6561 ,שכ ט
9000 ,באר שבע ,6514 ,שכ כלניות
9000 ,באר שבע ,6598 ,שכ נוה נוי
9000 ,באר שבע ,6599 ,שכ נחל בקע
9000 ,באר שבע ,6540 ,שכ סיגליות
9000 ,באר שבע ,6612 ,שכ רמות ב'
9000 ,באר שבע ,6613 ,שכ רמות ד'
9000 ,באר שבע ,386 ,שכם
9000 ,באר שבע ,122 ,שלדג
9000 ,באר שבע ,418 ,שלום עליכם
9000 ,באר שבע ,341 ,שלונסקי
9000 ,באר שבע ,987 ,שלושת המצפים
9000 ,באר שבע ,313 ,שליו
9000 ,באר שבע ,234 ,שלמה המלך
9000 ,באר שבע ,966 ,שלשת בני עין חרוד
9000 ,באר שבע ,248 ,שמאי
9000 ,באר שבע ,1096 ,שמאלי אליעזר
9000 ,באר שבע ,967 ,שמואל הנביא
9000 ,באר שבע ,968 ,שמחה
9000 ,באר שבע ,969 ,שמחוני אסף
9000 ,באר שבע ,660 ,שמחת כהן
9000 ,באר שבע ,328 ,שממית
9000 ,באר שבע ,206 ,שמעון בר גיורא
9000 ,באר שבע ,419 ,שמעוני
9000 ,באר שבע ,246 ,שמעיה
9000 ,באר שבע ,329 ,שנונית
9000 ,באר שבע ,424 ,שניאור
9000 ,באר שבע ,883 ,שניידרוביץ
9000 ,באר שבע ,1399 ,שסק
9000 ,באר שבע ,1414 ,שעורה
9000 ,באר שבע ,499 ,שער הגיא
9000 ,באר שבע ,824 ,שפירא
9000 ,באר שבע ,1198 ,שפרה
9000 ,באר שבע ,238 ,שפרינצק
9000 ,באר שבע ,262 ,שקד
9000 ,באר שבע ,1374 ,שקמה
9000 ,באר שבע ,970 ,שרה אמנו
9000 ,באר שבע ,639 ,שרה ומאיר בץ
9000 ,באר שבע ,169 ,שרה לוי-תנאי
9000 ,באר שבע ,188 ,שרה עזריהו
9000 ,באר שבע ,1359 ,שריג נחום
9000 ,באר שבע ,1199 ,שרירא גאון
9000 ,באר שבע ,532 ,שרעבי
9000 ,באר שבע ,1393 ,שרשבסקי
9000 ,באר שבע ,469 ,שרת משה
9000 ,באר שבע ,450 ,ששון אליהו
9000 ,באר שבע ,498 ,ששת הימים
9000 ,באר שבע ,1264 ,שתל יצחק
9000 ,באר שבע ,1415 ,תאנה
9000 ,באר שבע ,1417 ,תאשור
9000 ,באר שבע ,994 ,תבורי חיים
9000 ,באר שבע ,1418 ,תדהר
9000 ,באר שבע ,113 ,תוצרת הארץ
9000 ,באר שבע ,1425 ,תור
9000 ,באר שבע ,112 ,תורן יהושע
9000 ,באר שבע ,825 ,תל חי
9000 ,באר שבע ,1098 ,תלמה
9000 ,באר שבע ,1331 ,תלמי מאיר
9000 ,באר שבע ,430 ,תמיר שמואל
9000 ,באר שבע ,493 ,תמנע
9000 ,באר שבע ,1007 ,תמר
9000 ,באר שבע ,181 ,תמר שחר
9000 ,באר שבע ,1412 ,תנובה
9000 ,באר שבע ,1427 ,תפוז
9000 ,באר שבע ,1428 ,תפוח
9000 ,באר שבע ,1263 ,תקוע יוסף
450 ,בארות יצחק ,101 ,אולפן
450 ,בארות יצחק ,9000 ,בארות יצחק
697 ,בארותיים ,9000 ,בארותיים
697 ,בארותיים ,102 ,האפיק
697 ,בארותיים ,103 ,היובלים
697 ,בארותיים ,104 ,הנחלים
697 ,בארותיים ,101 ,הפלג
399 ,בארי ,9000 ,בארי
559 ,בוסתן הגליל ,9000 ,בוסתן הגליל
559 ,בוסתן הגליל ,101 ,דרך הגנים
559 ,בוסתן הגליל ,102 ,דרך הזית
559 ,בוסתן הגליל ,103 ,דרך הים
559 ,בוסתן הגליל ,105 ,דרך הנחל
559 ,בוסתן הגליל ,110 ,כפר הנוער נירים
559 ,בוסתן הגליל ,109 ,שביל האלמוג
559 ,בוסתן הגליל ,111 ,שביל הדוגית
559 ,בוסתן הגליל ,108 ,שביל החבצלת
559 ,בוסתן הגליל ,114 ,שביל החוף
559 ,בוסתן הגליל ,112 ,שביל המפרש
559 ,בוסתן הגליל ,107 ,שביל העוגן
559 ,בוסתן הגליל ,106 ,שביל השונית
559 ,בוסתן הגליל ,113 ,שביל השחף
559 ,בוסתן הגליל ,104 ,שד האקליפטוס
482 ,בועיינה-נוג'ידאת ,157 ,אבו בכר
482 ,בועיינה-נוג'ידאת ,176 ,אל-סופסאף
482 ,בועיינה-נוג'ידאת ,178 ,אלאבטאל
482 ,בועיינה-נוג'ידאת ,129 ,אלאופוק
482 ,בועיינה-נוג'ידאת ,117 ,אלאח'וה
482 ,בועיינה-נוג'ידאת ,153 ,אלאמאל
482 ,בועיינה-נוג'ידאת ,115 ,אלבוראק
482 ,בועיינה-נוג'ידאת ,104 ,אלבטוף
482 ,בועיינה-נוג'ידאת ,131 ,אלביאדר
482 ,בועיינה-נוג'ידאת ,140 ,אלבלאבל
482 ,בועיינה-נוג'ידאת ,145 ,אלג'אמע
482 ,בועיינה-נוג'ידאת ,183 ,אלג'בל
482 ,בועיינה-נוג'ידאת ,130 ,אלג'ורן
482 ,בועיינה-נוג'ידאת ,147 ,אלג'יעה
482 ,בועיינה-נוג'ידאת ,180 ,אלג'ליל
482 ,בועיינה-נוג'ידאת ,116 ,אלדחנון
482 ,בועיינה-נוג'ידאת ,123 ,אלהודא
482 ,בועיינה-נוג'ידאת ,161 ,אלופאא
482 ,בועיינה-נוג'ידאת ,182 ,אלזהור
482 ,בועיינה-נוג'ידאת ,113 ,אלזהראא
482 ,בועיינה-נוג'ידאת ,114 ,אלזיתון
482 ,בועיינה-נוג'ידאת ,169 ,אלח'ולאפאא
482 ,בועיינה-נוג'ידאת ,149 ,אלח'יאל
482 ,בועיינה-נוג'ידאת ,165 ,אלח'נסאא
482 ,בועיינה-נוג'ידאת ,168 ,אלח'רבה
482 ,בועיינה-נוג'ידאת ,166 ,אלח'רוב
482 ,בועיינה-נוג'ידאת ,179 ,אלחורייה
482 ,בועיינה-נוג'ידאת ,122 ,אליאסמין
482 ,בועיינה-נוג'ידאת ,175 ,אלירמוך
482 ,בועיינה-נוג'ידאת ,139 ,אלכורום
482 ,בועיינה-נוג'ידאת ,121 ,אלכפה
482 ,בועיינה-נוג'ידאת ,167 ,אלכראמה
482 ,בועיינה-נוג'ידאת ,160 ,אלכרם
482 ,בועיינה-נוג'ידאת ,148 ,אללוז
482 ,בועיינה-נוג'ידאת ,155 ,אלמידאן
482 ,בועיינה-נוג'ידאת ,132 ,אלמיהבאש
482 ,בועיינה-נוג'ידאת ,154 ,אלמנזול
482 ,בועיינה-נוג'ידאת ,151 ,אלמסבע'ה
482 ,בועיינה-נוג'ידאת ,142 ,אלמעאלי
482 ,בועיינה-נוג'ידאת ,144 ,אלמעסרה
482 ,בועיינה-נוג'ידאת ,146 ,אלמראח
482 ,בועיינה-נוג'ידאת ,137 ,אלמרוה
482 ,בועיינה-נוג'ידאת ,143 ,אלנג'אח
482 ,בועיינה-נוג'ידאת ,164 ,אלנור
482 ,בועיינה-נוג'ידאת ,184 ,אלנרג'ס
482 ,בועיינה-נוג'ידאת ,172 ,אלסולטאן
482 ,בועיינה-נוג'ידאת ,119 ,אלסלאם
482 ,בועיינה-נוג'ידאת ,156 ,אלסנדיאן
482 ,בועיינה-נוג'ידאת ,107 ,אלע'זאלין
482 ,בועיינה-נוג'ידאת ,127 ,אלע'רוב
482 ,בועיינה-נוג'ידאת ,106 ,אלעביר
482 ,בועיינה-נוג'ידאת ,120 ,אלעין
482 ,בועיינה-נוג'ידאת ,174 ,אלקאדסייה
482 ,בועיינה-נוג'ידאת ,103 ,אלקודס
482 ,בועיינה-נוג'ידאת ,159 ,אלקלעה
482 ,בועיינה-נוג'ידאת ,141 ,אלרבאעין
482 ,בועיינה-נוג'ידאת ,162 ,אלרביע
482 ,בועיינה-נוג'ידאת ,150 ,אלרדואן
482 ,בועיינה-נוג'ידאת ,138 ,אלרחמה
482 ,בועיינה-נוג'ידאת ,134 ,אלריחאן
482 ,בועיינה-נוג'ידאת ,124 ,אלרשיד
482 ,בועיינה-נוג'ידאת ,152 ,אלשאם
482 ,בועיינה-נוג'ידאת ,126 ,אלשאפעי
482 ,בועיינה-נוג'ידאת ,136 ,אלשורוק
482 ,בועיינה-נוג'ידאת ,158 ,אלשיח'
482 ,בועיינה-נוג'ידאת ,108 ,אלתוחיד
482 ,בועיינה-נוג'ידאת ,133 ,אלתין
482 ,בועיינה-נוג'ידאת ,181 ,אלתנור
482 ,בועיינה-נוג'ידאת ,171 ,אלתקוא
482 ,בועיינה-נוג'ידאת ,9000 ,בועיינה-נוג'ידאת
482 ,בועיינה-נוג'ידאת ,102 ,בועינה
482 ,בועיינה-נוג'ידאת ,111 ,בילאל
482 ,בועיינה-נוג'ידאת ,173 ,דרב אלראס
482 ,בועיינה-נוג'ידאת ,163 ,ואדי אלברכה
482 ,בועיינה-נוג'ידאת ,118 ,ואדי ע'וראב
482 ,בועיינה-נוג'ידאת ,105 ,חטין
482 ,בועיינה-נוג'ידאת ,110 ,טארק בן זיאד
482 ,בועיינה-נוג'ידאת ,112 ,מועאד' בן ג'בל
482 ,בועיינה-נוג'ידאת ,128 ,מסלח'ית
482 ,בועיינה-נוג'ידאת ,177 ,נג'ד
482 ,בועיינה-נוג'ידאת ,101 ,נוג'ידאת
482 ,בועיינה-נוג'ידאת ,135 ,סבאח אלח'יר
482 ,בועיינה-נוג'ידאת ,109 ,סלאח אלדין
482 ,בועיינה-נוג'ידאת ,170 ,עומר בן אלח'טאב
482 ,בועיינה-נוג'ידאת ,125 ,קורטובה
4001 ,בוקעאתא ,9000 ,בוקעאתא
698 ,בורגתה ,9000 ,בורגתה
698 ,בורגתה ,101 ,הארז
698 ,בורגתה ,103 ,הברוש
698 ,בורגתה ,105 ,הגפן
698 ,בורגתה ,104 ,הדקל
698 ,בורגתה ,102 ,ההדס
2043 ,בחן ,102 ,אגוז
2043 ,בחן ,103 ,אורן
2043 ,בחן ,107 ,אלה
2043 ,בחן ,101 ,אלון
2043 ,בחן ,105 ,אלמוגן
2043 ,בחן ,106 ,ארז
2043 ,בחן ,104 ,אשל
2043 ,בחן ,9000 ,בחן
2043 ,בחן ,116 ,ברוש
2043 ,בחן ,111 ,דולב
2043 ,בחן ,112 ,דקל
2043 ,בחן ,114 ,זית
2043 ,בחן ,7001 ,יד חנה מאוחד
2043 ,בחן ,108 ,מורן
2043 ,בחן ,119 ,ערבה
2043 ,בחן ,118 ,צאלון
2043 ,בחן ,110 ,שיזף
2043 ,בחן ,113 ,שקד
2043 ,בחן ,115 ,שקמה
2043 ,בחן ,117 ,תאנה
2043 ,בחן ,109 ,תמר
762 ,בטחה ,9000 ,בטחה
234 ,ביצרון ,9000 ,ביצרון
234 ,ביצרון ,104 ,דרך הפיקוסים
234 ,ביצרון ,105 ,הותיקים
234 ,ביצרון ,103 ,החיילים
234 ,ביצרון ,106 ,המייסדים
234 ,ביצרון ,101 ,הנוריות
234 ,ביצרון ,102 ,הנרקיסים
234 ,ביצרון ,107 ,העולים
234 ,ביצרון ,108 ,העקרונים
234 ,ביצרון ,6500 ,שכ החיילים
998 ,ביר אל-מכסור ,105 ,א-זהרה
998 ,ביר אל-מכסור ,161 ,א-זיתון
998 ,ביר אל-מכסור ,162 ,א-זעתר
998 ,ביר אל-מכסור ,302 ,א-טבראני
998 ,ביר אל-מכסור ,204 ,א-טקה
998 ,ביר אל-מכסור ,141 ,א-לוז
998 ,ביר אל-מכסור ,339 ,א-לימון
998 ,ביר אל-מכסור ,121 ,א-נארג'ס
998 ,ביר אל-מכסור ,153 ,א-נג'אח
998 ,ביר אל-מכסור ,107 ,א-נועמאן
998 ,ביר אל-מכסור ,315 ,א-נור
998 ,ביר אל-מכסור ,139 ,א-נח'יל
998 ,ביר אל-מכסור ,160 ,א-נענע
998 ,ביר אל-מכסור ,322 ,א-ס'נובר
998 ,ביר אל-מכסור ,164 ,א-סדרה
998 ,ביר אל-מכסור ,152 ,א-סינדייאן
998 ,ביר אל-מכסור ,305 ,א-סלאם
998 ,ביר אל-מכסור ,352 ,א-סנאבל
998 ,ביר אל-מכסור ,183 ,א-סרג'
998 ,ביר אל-מכסור ,336 ,א-סרו
998 ,ביר אל-מכסור ,131 ,א-סריס
998 ,ביר אל-מכסור ,166 ,א-ראס
998 ,ביר אל-מכסור ,103 ,א-רביע
998 ,ביר אל-מכסור ,316 ,א-רוודאת
998 ,ביר אל-מכסור ,317 ,א-רוויכאת
998 ,ביר אל-מכסור ,200 ,א-רומאן
998 ,ביר אל-מכסור ,123 ,א-ריחאן
998 ,ביר אל-מכסור ,342 ,א-שורוק
998 ,ביר אל-מכסור ,341 ,א-תות
998 ,ביר אל-מכסור ,113 ,א-תין
998 ,ביר אל-מכסור ,321 ,א-תלה
998 ,ביר אל-מכסור ,328 ,אכליל אל-ג'בל
998 ,ביר אל-מכסור ,124 ,אל-אנוואר
998 ,ביר אל-מכסור ,306 ,אל-ארז
998 ,ביר אל-מכסור ,101 ,אל-בוטום
998 ,ביר אל-מכסור ,140 ,אל-בוסתאן
998 ,ביר אל-מכסור ,100 ,אל-ביר
998 ,ביר אל-מכסור ,320 ,אל-בלוט
998 ,ביר אל-מכסור ,354 ,אל-ג'וז
998 ,ביר אל-מכסור ,118 ,אל-הודא
998 ,ביר אל-מכסור ,168 ,אל-ואדי
998 ,ביר אל-מכסור ,330 ,אל-ואחה
998 ,ביר אל-מכסור ,337 ,אל-ורוד
998 ,ביר אל-מכסור ,102 ,אל-ח'ירבה
998 ,ביר אל-מכסור ,156 ,אל-ח'רוב
998 ,ביר אל-מכסור ,171 ,אל-חאווז
998 ,ביר אל-מכסור ,301 ,אל-חאכורה
998 ,ביר אל-מכסור ,325 ,אל-חסנייה
998 ,ביר אל-מכסור ,176 ,אל-יאסמין
998 ,ביר אל-מכסור ,303 ,אל-כסארה
998 ,ביר אל-מכסור ,348 ,אל-כרם
998 ,ביר אל-מכסור ,172 ,אל-מג'ליס
998 ,ביר אל-מכסור ,170 ,אל-מדארס
998 ,ביר אל-מכסור ,135 ,אל-מוזירה
998 ,ביר אל-מכסור ,181 ,אל-מוכמאן
998 ,ביר אל-מכסור ,163 ,אל-מונתזה
998 ,ביר אל-מכסור ,319 ,אל-מטלה
998 ,ביר אל-מכסור ,314 ,אל-מידאן
998 ,ביר אל-מכסור ,167 ,אל-מלעב
998 ,ביר אל-מכסור ,106 ,אל-מנס'ור
998 ,ביר אל-מכסור ,145 ,אל-מסאעיד
998 ,ביר אל-מכסור ,190 ,אל-מרג'
998 ,ביר אל-מכסור ,174 ,אל-מרכז
998 ,ביר אל-מכסור ,351 ,אל-עוואבדה
998 ,ביר אל-מכסור ,133 ,אל-עין
998 ,ביר אל-מכסור ,111 ,אל-עקד
998 ,ביר אל-מכסור ,104 ,אל-פורוסיה
998 ,ביר אל-מכסור ,9000 ,ביר אל-מכסור
998 ,ביר אל-מכסור ,134 ,עיכסאן
998 ,ביר אל-מכסור ,203 ,עקילה
1348 ,ביר הדאג' ,9000 ,ביר הדאג'
368 ,ביריה ,9000 ,ביריה
368 ,ביריה ,102 ,כהן איציק
368 ,ביריה ,101 ,מדר חיים
317 ,בית אורן ,9000 ,בית אורן
317 ,בית אורן ,7001 ,בית סוהר דמון
317 ,בית אורן ,7002 ,חוות בית אורן
3574 ,בית אל ,117 ,אבשלום
3574 ,בית אל ,188 ,אדיר זיק
3574 ,בית אל ,112 ,אופירה
3574 ,בית אל ,130 ,אלון
3574 ,בית אל ,182 ,אלעזר
3574 ,בית אל ,169 ,אסא המלך
3574 ,בית אל ,107 ,אעלה בתמר
3574 ,בית אל ,210 ,אפרים
3574 ,בית אל ,195 ,אצ"ל
3574 ,בית אל ,174 ,ארץ חמדה
3574 ,בית אל ,199 ,בועז
3574 ,בית אל ,108 ,ביל"ו
3574 ,בית אל ,9000 ,בית אל
3574 ,בית אל ,6500 ,בית אל ב'
3574 ,בית אל ,212 ,בנימין
3574 ,בית אל ,136 ,ברוש
3574 ,בית אל ,124 ,ברקת
3574 ,בית אל ,144 ,גינת אגוז
3574 ,בית אל ,166 ,גפן
3574 ,בית אל ,116 ,דקלה
3574 ,בית אל ,177 ,דרור
3574 ,בית אל ,132 ,דרך ביטחון
3574 ,בית אל ,200 ,דרך בית אל
3574 ,בית אל ,215 ,דרך האמהות
3574 ,בית אל ,153 ,דרך החיים
3574 ,בית אל ,140 ,דרך החלוצים
3574 ,בית אל ,216 ,דרך העמק
3574 ,בית אל ,208 ,דרך שבטי ישראל
3574 ,בית אל ,160 ,דרך שבעת המינים
3574 ,בית אל ,155 ,האולפנא
3574 ,בית אל ,187 ,האחוזה
3574 ,בית אל ,197 ,הגנה
3574 ,בית אל ,105 ,המעפילים
3574 ,בית אל ,146 ,המפעל
3574 ,בית אל ,157 ,הנזיר
3574 ,בית אל ,176 ,הנצי"ב
3574 ,בית אל ,190 ,הרב מרדכי אליהו
3574 ,בית אל ,189 ,הרב שפירא
3574 ,בית אל ,179 ,התחיה
3574 ,בית אל ,209 ,זבולון
3574 ,בית אל ,165 ,זית
3574 ,בית אל ,172 ,חזקיהו המלך
3574 ,בית אל ,156 ,חיבת ציון
3574 ,בית אל ,161 ,חיטה
3574 ,בית אל ,180 ,חלמיש
3574 ,בית אל ,120 ,חצר אדר
3574 ,בית אל ,114 ,חרובית
3574 ,בית אל ,181 ,יהודה המכבי
3574 ,בית אל ,170 ,יהושפט
3574 ,בית אל ,126 ,יהלום
3574 ,בית אל ,185 ,יוחנן
3574 ,בית אל ,183 ,יונתן
3574 ,בית אל ,171 ,יותם
3574 ,בית אל ,198 ,יכין
3574 ,בית אל ,119 ,ימית
3574 ,בית אל ,141 ,יפה נוף
3574 ,בית אל ,142 ,כ' בחשון
3574 ,בית אל ,173 ,כינור דוד
3574 ,בית אל ,101 ,כנפי נשרים
3574 ,בית אל ,214 ,לאה
3574 ,בית אל ,103 ,לבי במזרח
3574 ,בית אל ,207 ,לוי
3574 ,בית אל ,196 ,לח"י
3574 ,בית אל ,121 ,מבוא אבני החושן
3574 ,בית אל ,148 ,מבוא אתרוג
3574 ,בית אל ,134 ,מבוא האורנים
3574 ,בית אל ,147 ,מבוא הדס
3574 ,בית אל ,151 ,מבוא הישיבה
3574 ,בית אל ,139 ,מבוא התקומה
3574 ,בית אל ,127 ,מבוא ליבנה
3574 ,בית אל ,106 ,מבצע שלמה
3574 ,בית אל ,159 ,מי מרום
3574 ,בית אל ,211 ,מנשה
3574 ,בית אל ,150 ,מעגלי הרא"ה
3574 ,בית אל ,186 ,מעוז צור
3574 ,בית אל ,175 ,מעלה האמונה
3574 ,בית אל ,218 ,מעלה הרב אלון
3574 ,בית אל ,178 ,מעלה שופר
3574 ,בית אל ,109 ,מרבד הקסמים
3574 ,בית אל ,184 ,מתתיהו
3574 ,בית אל ,154 ,נריה
3574 ,בית אל ,158 ,סולם יעקב
3574 ,בית אל ,118 ,סופה
3574 ,בית אל ,125 ,ספיר
3574 ,בית אל ,135 ,ערבה
3574 ,בית אל ,145 ,ערוגות הבושם
3574 ,בית אל ,128 ,ערמון
3574 ,בית אל ,115 ,פרי-גן
3574 ,בית אל ,143 ,פריאל
3574 ,בית אל ,104 ,קול התור
3574 ,בית אל ,129 ,קטלב
3574 ,בית אל ,102 ,קיבוץ גלויות
3574 ,בית אל ,201 ,קרית הישיבה
3574 ,בית אל ,205 ,ראובן
3574 ,בית אל ,213 ,רחל
3574 ,בית אל ,162 ,רימון
3574 ,בית אל ,167 ,שאול המלך
3574 ,בית אל ,111 ,שבות סיני
3574 ,בית אל ,219 ,שביל האור
3574 ,בית אל ,217 ,שביל האמונה
3574 ,בית אל ,113 ,שדות
3574 ,בית אל ,110 ,שדרות לוז
3574 ,בית אל ,123 ,שוהם
3574 ,בית אל ,137 ,שיטה
3574 ,בית אל ,6503 ,שכ אבן פינה
3574 ,בית אל ,6511 ,שכ בן הודה
3574 ,בית אל ,6514 ,שכ גבעת האולפנה
3574 ,בית אל ,6508 ,שכ גינות בית אל
3574 ,בית אל ,6504 ,שכ דוד
3574 ,בית אל ,6510 ,שכ הואדי
3574 ,בית אל ,6515 ,שכ נוף
3574 ,בית אל ,6506 ,שכ ניצני בית אל
3574 ,בית אל ,6505 ,שכ ניצנית
3574 ,בית אל ,6513 ,שכ פסגת יעקב
3574 ,בית אל ,6512 ,שכ קדם
3574 ,בית אל ,6509 ,שכ ראשית
3574 ,בית אל ,6501 ,שכונה א
3574 ,בית אל ,6502 ,שכונה ב
3574 ,בית אל ,168 ,שלמה המלך
3574 ,בית אל ,206 ,שמעון
3574 ,בית אל ,131 ,שקד
3574 ,בית אל ,163 ,תאנה
3574 ,בית אל ,138 ,תדהר
3574 ,בית אל ,152 ,תורה ועבודה
3574 ,בית אל ,6507 ,תל חיים
3574 ,בית אל ,164 ,תמר
3574 ,בית אל ,133 ,תפוח
3574 ,בית אל ,122 ,תרשיש
562 ,בית אלעזרי ,9000 ,בית אלעזרי
562 ,בית אלעזרי ,101 ,הגפן
562 ,בית אלעזרי ,102 ,הזית
562 ,בית אלעזרי ,103 ,המייסדים
562 ,בית אלעזרי ,104 ,הרימון
562 ,בית אלעזרי ,105 ,התאנה
562 ,בית אלעזרי ,106 ,התמר
95 ,בית אלפא ,101 ,אולפן
95 ,בית אלפא ,9000 ,בית אלפא
95 ,בית אלפא ,102 ,מרכז קליטה גלבוע
3652 ,בית אריה ,125 ,אחירותם
3652 ,בית אריה ,155 ,איבן גבירול
3652 ,בית אריה ,111 ,אירוס
3652 ,בית אריה ,200 ,אלונים
3652 ,בית אריה ,144 ,אלתרמן נתן
3652 ,בית אריה ,143 ,אריאל מאיר
3652 ,בית אריה ,158 ,אריק איינשטיין
3652 ,בית אריה ,112 ,אשחר
3652 ,בית אריה ,9000 ,בית אריה
3652 ,בית אריה ,6500 ,גבעת הבריכה
3652 ,בית אריה ,202 ,גלבוע
3652 ,בית אריה ,105 ,דבורנית
3652 ,בית אריה ,131 ,דולב
3652 ,בית אריה ,203 ,דפנה
3652 ,בית אריה ,124 ,האלה
3652 ,בית אריה ,116 ,האלון
3652 ,בית אריה ,3107 ,האשל
3652 ,בית אריה ,205 ,האתרוג
3652 ,בית אריה ,3103 ,הברוש
3652 ,בית אריה ,115 ,הברושים
3652 ,בית אריה ,139 ,הגדי
3652 ,בית אריה ,117 ,הגפן
3652 ,בית אריה ,3102 ,הדקל
3652 ,בית אריה ,103 ,הזית
3652 ,בית אריה ,152 ,הלוי יהודה
3652 ,בית אריה ,138 ,העופר
3652 ,בית אריה ,151 ,הרב שבזי
3652 ,בית אריה ,3106 ,הרימון
3652 ,בית אריה ,3101 ,השיטה
3652 ,בית אריה ,142 ,השירה העברית
3652 ,בית אריה ,3105 ,השקד עופרים
3652 ,בית אריה ,118 ,התאנה
3652 ,בית אריה ,141 ,התמר
3652 ,בית אריה ,119 ,חוטמית
3652 ,בית אריה ,147 ,חזה עופרה
3652 ,בית אריה ,113 ,חיננית
3652 ,בית אריה ,129 ,חצב
3652 ,בית אריה ,133 ,חרצית
3652 ,בית אריה ,157 ,טיכו אנה
3652 ,בית אריה ,134 ,כוכבית
3652 ,בית אריה ,114 ,כליל החורש
3652 ,בית אריה ,130 ,כרכום
3652 ,בית אריה ,156 ,כרמים
3652 ,בית אריה ,101 ,לבונה
3652 ,בית אריה ,145 ,לביא אריק
3652 ,בית אריה ,106 ,לוטם
3652 ,בית אריה ,3104 ,מעלה הזית
3652 ,בית אריה ,206 ,מעלה שילה
3652 ,בית אריה ,201 ,מצפה ים
3652 ,בית אריה ,126 ,מרווה
3652 ,בית אריה ,204 ,מרק אסיה
3652 ,בית אריה ,110 ,ניסנית
3652 ,בית אריה ,132 ,נירית
3652 ,בית אריה ,6510 ,עופרים
3652 ,בית אריה ,121 ,עירית
3652 ,בית אריה ,127 ,ערבה
3652 ,בית אריה ,150 ,פן אלכסנדר
3652 ,בית אריה ,123 ,פעמונית
3652 ,בית אריה ,102 ,פריחת השקד
3652 ,בית אריה ,108 ,צורית
3652 ,בית אריה ,122 ,צלף
3652 ,בית אריה ,128 ,קורנית
3652 ,בית אריה ,107 ,קטלב
3652 ,בית אריה ,120 ,קיסוסית
3652 ,בית אריה ,149 ,רחל המשוררת
3652 ,בית אריה ,104 ,רקפת
3652 ,בית אריה ,140 ,שד הניצנים
3652 ,בית אריה ,148 ,שטרן יאיר
3652 ,בית אריה ,109 ,שיטים
3652 ,בית אריה ,146 ,שמר נעמי
3652 ,בית אריה ,3108 ,שקמה
3652 ,בית אריה ,153 ,שריג
3652 ,בית אריה ,154 ,תירוש
1076 ,בית ברל ,9000 ,בית ברל
1076 ,בית ברל ,7001 ,קלמניה
480 ,בית ג'ן ,387 ,אלאוח'וה
480 ,בית ג'ן ,370 ,אלאחלאם
480 ,בית ג'ן ,326 ,אלאמיר אלסייד
480 ,בית ג'ן ,276 ,אלאמל
480 ,בית ג'ן ,336 ,אלאנביא
480 ,בית ג'ן ,272 ,אלאס
480 ,בית ג'ן ,356 ,אלאסד
480 ,בית ג'ן ,227 ,אלבאבור
480 ,בית ג'ן ,297 ,אלבאשיק
480 ,בית ג'ן ,183 ,אלבוטום
480 ,בית ג'ן ,377 ,אלבורטוקאל
480 ,בית ג'ן ,304 ,אלבט
480 ,בית ג'ן ,155 ,אלביאדה
480 ,בית ג'ן ,175 ,אלביאדי
480 ,בית ג'ן ,221 ,אלבירק
480 ,בית ג'ן ,201 ,אלבירקי
480 ,בית ג'ן ,246 ,אלבית אלעתיק
480 ,בית ג'ן ,328 ,אלבלאן
480 ,בית ג'ן ,280 ,אלבלוט
480 ,בית ג'ן ,322 ,אלג'וז
480 ,בית ג'ן ,245 ,אלג'רמק
480 ,בית ג'ן ,373 ,אלד'הר
480 ,בית ג'ן ,268 ,אלדואר
480 ,בית ג'ן ,120 ,אלדוירי
480 ,בית ג'ן ,230 ,אלדוראק
480 ,בית ג'ן ,380 ,אלדורה
480 ,בית ג'ן ,6721 ,אלדידבה
480 ,בית ג'ן ,126 ,אלדראג'
480 ,בית ג'ן ,334 ,אלדרג'ה
480 ,בית ג'ן ,150 ,אלהודהוד
480 ,בית ג'ן ,341 ,אלהיל
480 ,בית ג'ן ,134 ,אלהנבל
480 ,בית ג'ן ,281 ,אלוארד אלג'ורי
480 ,בית ג'ן ,338 ,אלוורוד
480 ,בית ג'ן ,325 ,אלועל
480 ,בית ג'ן ,148 ,אלזאבוד
480 ,בית ג'ן ,286 ,אלזאן
480 ,בית ג'ן ,177 ,אלזאתוני
480 ,בית ג'ן ,262 ,אלזוהור
480 ,בית ג'ן ,264 ,אלזית
480 ,בית ג'ן ,294 ,אלזיתון
480 ,בית ג'ן ,344 ,אלזנבק
480 ,בית ג'ן ,366 ,אלזערור
480 ,בית ג'ן ,290 ,אלזעתר
480 ,בית ג'ן ,151 ,אלזריקי
480 ,בית ג'ן ,379 ,אלח'יט
480 ,בית ג'ן ,384 ,אלח'ס
480 ,בית ג'ן ,302 ,אלח'רוב
480 ,בית ג'ן ,374 ,אלח'תאף
480 ,בית ג'ן ,277 ,אלחבק
480 ,בית ג'ן ,307 ,אלחג'ל
480 ,בית ג'ן ,104 ,אלחדארי
480 ,בית ג'ן ,127 ,אלחופחאף
480 ,בית ג'ן ,391 ,אלחינדאג'
480 ,בית ג'ן ,319 ,אלחלוה
480 ,בית ג'ן ,197 ,אלחמאם
480 ,בית ג'ן ,363 ,אלחמל
480 ,בית ג'ן ,354 ,אלחסון
480 ,בית ג'ן ,105 ,אלטואלי
480 ,בית ג'ן ,330 ,אלטיון
480 ,בית ג'ן ,152 ,אלימאם
480 ,בית ג'ן ,165 ,אלינסון
480 ,בית ג'ן ,288 ,אלכאפור
480 ,בית ג'ן ,397 ,אלכיווי
480 ,בית ג'ן ,179 ,אלכינא
480 ,בית ג'ן ,163 ,אלכמון
480 ,בית ג'ן ,313 ,אלכנאר
480 ,בית ג'ן ,292 ,אלכרז
480 ,בית ג'ן ,138 ,אלכרמה
480 ,בית ג'ן ,125 ,אלכרק
480 ,בית ג'ן ,395 ,אללוז
480 ,בית ג'ן ,132 ,אללוטוס
480 ,בית ג'ן ,283 ,אללומאם
480 ,בית ג'ן ,164 ,אללימון
480 ,בית ג'ן ,121 ,אללקלק
480 ,בית ג'ן ,122 ,אלמדרסה
480 ,בית ג'ן ,110 ,אלמהא
480 ,בית ג'ן ,259 ,אלמוברה
480 ,בית ג'ן ,188 ,אלמוסתראח
480 ,בית ג'ן ,185 ,אלמחלב
480 ,בית ג'ן ,195 ,אלמטל
480 ,בית ג'ן ,311 ,אלמידאן
480 ,בית ג'ן ,114 ,אלמיס
480 ,בית ג'ן ,343 ,אלמיסק
480 ,בית ג'ן ,167 ,אלמל
480 ,בית ג'ן ,123 ,אלמלעב
480 ,בית ג'ן ,196 ,אלמנארה
480 ,בית ג'ן ,267 ,אלמנבר
480 ,בית ג'ן ,194 ,אלמנטרה
480 ,בית ג'ן ,6712 ,אלמסירב
480 ,בית ג'ן ,315 ,אלמספה
480 ,בית ג'ן ,316 ,אלמע'ארה
480 ,בית ג'ן ,295 ,אלמעסרה
480 ,בית ג'ן ,274 ,אלמקלע
480 ,בית ג'ן ,170 ,אלמרואני
480 ,בית ג'ן ,184 ,אלמרשקה
480 ,בית ג'ן ,233 ,אלמשמש
480 ,בית ג'ן ,320 ,אלנוקרה
480 ,בית ג'ן ,117 ,אלנורס
480 ,בית ג'ן ,186 ,אלנח'יל
480 ,בית ג'ן ,332 ,אלנימר
480 ,בית ג'ן ,6732 ,אלנסיבה
480 ,בית ג'ן ,149 ,אלנסר
480 ,בית ג'ן ,362 ,אלנעאמה
480 ,בית ג'ן ,162 ,אלנענע
480 ,בית ג'ן ,112 ,אלנעסה
480 ,בית ג'ן ,369 ,אלנרגיס
480 ,בית ג'ן ,282 ,אלסאוסן
480 ,בית ג'ן ,388 ,אלסבע
480 ,בית ג'ן ,209 ,אלסבר
480 ,בית ג'ן ,360 ,אלסדיר
480 ,בית ג'ן ,6714 ,אלסוואנה
480 ,בית ג'ן ,365 ,אלסוויד
480 ,בית ג'ן ,181 ,אלסולם
480 ,בית ג'ן ,371 ,אלסומאק
480 ,בית ג'ן ,305 ,אלסומן
480 ,בית ג'ן ,203 ,אלסופסאף
480 ,בית ג'ן ,169 ,אלסלוא
480 ,בית ג'ן ,115 ,אלסמרמר
480 ,בית ג'ן ,321 ,אלסנאבל
480 ,בית ג'ן ,224 ,אלסנאפרה
480 ,בית ג'ן ,166 ,אלסנובר
480 ,בית ג'ן ,113 ,אלסנונו
480 ,בית ג'ן ,389 ,אלסעבניה
480 ,בית ג'ן ,6715 ,אלספאיה ועין לג'רון
480 ,בית ג'ן ,236 ,אלסרוה
480 ,בית ג'ן ,168 ,אלסריס
480 ,בית ג'ן ,229 ,אלע'אר
480 ,בית ג'ן ,124 ,אלע'ורדא
480 ,בית ג'ן ,386 ,אלע'זאל
480 ,בית ג'ן ,299 ,אלע'טאס
480 ,בית ג'ן ,285 ,אלעבהר
480 ,בית ג'ן ,208 ,אלעוטרה
480 ,בית ג'ן ,284 ,אלעוינה
480 ,בית ג'ן ,157 ,אלעוליק
480 ,בית ג'ן ,222 ,אלעוסג'
480 ,בית ג'ן ,312 ,אלעזמיה
480 ,בית ג'ן ,216 ,אלעלם
480 ,בית ג'ן ,119 ,אלעמשקה
480 ,בית ג'ן ,253 ,אלענב
480 ,בית ג'ן ,333 ,אלעסל
480 ,בית ג'ן ,296 ,אלעקאב
480 ,בית ג'ן ,107 ,אלעקבאת
480 ,בית ג'ן ,116 ,אלערישה
480 ,בית ג'ן ,273 ,אלערער
480 ,בית ג'ן ,368 ,אלפהד
480 ,בית ג'ן ,6723 ,אלפוקרה
480 ,בית ג'ן ,158 ,אלפיג'ין
480 ,בית ג'ן ,351 ,אלפרס
480 ,בית ג'ן ,289 ,אלקאתל
480 ,בית ג'ן ,396 ,אלקבריש
480 ,בית ג'ן ,392 ,אלקדח
480 ,בית ג'ן ,6731 ,אלקובע
480 ,בית ג'ן ,337 ,אלקורונפול
480 ,בית ג'ן ,139 ,אלקורטאס
480 ,בית ג'ן ,331 ,אלקותון
480 ,בית ג'ן ,154 ,אלקירקיס
480 ,בית ג'ן ,372 ,אלקמח
480 ,בית ג'ן ,367 ,אלקנדול
480 ,בית ג'ן ,303 ,אלקסב
480 ,בית ג'ן ,317 ,אלקעדה
480 ,בית ג'ן ,171 ,אלקרקשאני
480 ,בית ג'ן ,137 ,אלראיה
480 ,בית ג'ן ,129 ,אלרביסה
480 ,בית ג'ן ,6734 ,אלרויס
480 ,בית ג'ן ,293 ,אלרומאן
480 ,בית ג'ן ,136 ,אלריאן
480 ,בית ג'ן ,258 ,אלריחאן
480 ,בית ג'ן ,270 ,אלרים
480 ,בית ג'ן ,217 ,אלרע'בה
480 ,בית ג'ן ,298 ,אלרפראף
480 ,בית ג'ן ,238 ,אלשאחוט
480 ,בית ג'ן ,161 ,אלשברק
480 ,בית ג'ן ,287 ,אלשג'רה
480 ,בית ג'ן ,111 ,אלשוחמאני
480 ,בית ג'ן ,140 ,אלשומר
480 ,בית ג'ן ,153 ,אלשחרור
480 ,בית ג'ן ,131 ,אלשיח
480 ,בית ג'ן ,176 ,אלשיתוי
480 ,בית ג'ן ,219 ,אלשמיה
480 ,בית ג'ן ,102 ,אלתופאחי
480 ,בית ג'ן ,103 ,אלתורכמאן
480 ,בית ג'ן ,133 ,אלתות
480 ,בית ג'ן ,249 ,אלתין
480 ,בית ג'ן ,235 ,אלתמר
480 ,בית ג'ן ,335 ,אלתנור
480 ,בית ג'ן ,314 ,אמין טריף
480 ,בית ג'ן ,339 ,אנאנאס
480 ,בית ג'ן ,210 ,אסאבע אלערוס
480 ,בית ג'ן ,228 ,באבונג'
480 ,בית ג'ן ,174 ,בהאא אלדין
480 ,בית ג'ן ,376 ,בוזריק
480 ,בית ג'ן ,254 ,בונדוק
480 ,בית ג'ן ,106 ,בוקראטי
480 ,בית ג'ן ,265 ,ביאדר אלסמאט
480 ,בית ג'ן ,324 ,ביאדר אלע'ירז
480 ,בית ג'ן ,353 ,ביאדר אלרביעה
480 ,בית ג'ן ,9000 ,בית ג'ן
480 ,בית ג'ן ,160 ,בית ג'ן עין אלאסד
480 ,בית ג'ן ,269 ,ברזא
480 ,בית ג'ן ,275 ,ברכת חאמד
480 ,בית ג'ן ,232 ,ג'וז אלהנד
480 ,בית ג'ן ,279 ,ג'וזת אלטיב
480 ,בית ג'ן ,266 ,ג'ומז
480 ,בית ג'ן ,225 ,ג'זאר
480 ,בית ג'ן ,212 ,ג'עסאס
480 ,בית ג'ן ,192 ,דבוקי
480 ,בית ג'ן ,357 ,דולפין
480 ,בית ג'ן ,218 ,דורדאר
480 ,בית ג'ן ,156 ,דפלה
480 ,בית ג'ן ,263 ,דרב אלתבאן
480 ,בית ג'ן ,6711 ,הגלעין הישן
480 ,בית ג'ן ,340 ,הזאר
480 ,בית ג'ן ,241 ,הליון
480 ,בית ג'ן ,226 ,זיזאפון
480 ,בית ג'ן ,189 ,זנג'ביל
480 ,בית ג'ן ,358 ,זראפה
480 ,בית ג'ן ,306 ,זרזור
480 ,בית ג'ן ,301 ,ח'וזאמה
480 ,בית ג'ן ,231 ,ח'וך
480 ,בית ג'ן ,348 ,ח'ולנג'אן
480 ,בית ג'ן ,6730 ,ח'לת אלג'ב
480 ,בית ג'ן ,199 ,ח'לת אלזע'לול
480 ,בית ג'ן ,172 ,ח'לת אלענק
480 ,בית ג'ן ,198 ,ח'לת למסאק
480 ,בית ג'ן ,378 ,ח'לת עיאד
480 ,בית ג'ן ,207 ,חבק
480 ,בית ג'ן ,349 ,חבת אלברכה
480 ,בית ג'ן ,6733 ,חיידר
480 ,בית ג'ן ,215 ,חלואני
480 ,בית ג'ן ,205 ,חמזה דרויש
480 ,בית ג'ן ,243 ,כובת אלעג'וז
480 ,בית ג'ן ,220 ,כולוניא
480 ,בית ג'ן ,187 ,כומטרה
480 ,בית ג'ן ,382 ,כוסא
480 ,בית ג'ן ,385 ,כוסברה
480 ,בית ג'ן ,327 ,כוראת'
480 ,בית ג'ן ,309 ,כורכור
480 ,בית ג'ן ,244 ,כילך
480 ,בית ג'ן ,101 ,כמאל ג'ונבלאט
480 ,בית ג'ן ,250 ,כסטנה
480 ,בית ג'ן ,200 ,כף אלדב
480 ,בית ג'ן ,248 ,כרום אלחסנה
480 ,בית ג'ן ,361 ,כרכדאן
480 ,בית ג'ן ,260 ,כרם סואר
480 ,בית ג'ן ,247 ,כרם עיד
480 ,בית ג'ן ,108 ,לואיזה
480 ,בית ג'ן ,257 ,לובלאב
480 ,בית ג'ן ,223 ,ליילאק
480 ,בית ג'ן ,128 ,מאלק אלחזין
480 ,בית ג'ן ,178 ,מדאואר
480 ,בית ג'ן ,211 ,מוחמד אבו שקרא
480 ,בית ג'ן ,364 ,מוסתכא
480 ,בית ג'ן ,355 ,מוע'ר אלעיד
480 ,בית ג'ן ,182 ,מיאל אלשמס
480 ,בית ג'ן ,383 ,מלפוף
480 ,בית ג'ן ,390 ,מרג' אלע'וזלאן
480 ,בית ג'ן ,291 ,מרדקוש
480 ,בית ג'ן ,6713 ,משפחת קבלאן
480 ,בית ג'ן ,109 ,נבעת אלחור
480 ,בית ג'ן ,352 ,נבעת סידו
480 ,בית ג'ן ,234 ,נג'אס
480 ,בית ג'ן ,204 ,נואף ע'זאלי
480 ,בית ג'ן ,255 ,נכתרין
480 ,בית ג'ן ,310 ,נפלה
480 ,בית ג'ן ,359 ,סולטאן אלאטרש
480 ,בית ג'ן ,180 ,סופרג'ל
480 ,בית ג'ן ,318 ,סוק אלד'איע
480 ,בית ג'ן ,300 ,סחלב
480 ,בית ג'ן ,251 ,סידרה
480 ,בית ג'ן ,237 ,סימסים
480 ,בית ג'ן ,271 ,סינדיאן
480 ,בית ג'ן ,346 ,סנג'אב
480 ,בית ג'ן ,323 ,סנטארוזה
480 ,בית ג'ן ,261 ,סרטבאני
480 ,בית ג'ן ,190 ,עג'לאן
480 ,בית ג'ן ,342 ,עדס
480 ,בית ג'ן ,6700 ,עין אלנום
480 ,בית ג'ן ,242 ,עכוב
480 ,בית ג'ן ,214 ,ענאב
480 ,בית ג'ן ,213 ,ענבר
480 ,בית ג'ן ,329 ,עסא אלראעי
480 ,בית ג'ן ,118 ,פירי
480 ,בית ג'ן ,381 ,פלפל
480 ,בית ג'ן ,130 ,פם אלסמקה
480 ,בית ג'ן ,240 ,קורס ענה
480 ,בית ג'ן ,206 ,קחואן
480 ,בית ג'ן ,252 ,קנדול
480 ,בית ג'ן ,256 ,קראסייה
480 ,בית ג'ן ,350 ,קרפה
480 ,בית ג'ן ,159 ,ראס אלעקבה
480 ,בית ג'ן ,6724 ,ראס טלעה וחלת עמירה
480 ,בית ג'ן ,173 ,רקאטי
480 ,בית ג'ן ,191 ,רשאד
480 ,בית ג'ן ,193 ,שאהין אלע'זאל
480 ,בית ג'ן ,278 ,שי פארסי
480 ,בית ג'ן ,6710 ,שכ אלע'דיר וסרטבה
480 ,בית ג'ן ,6720 ,שכ חיילים משוחררים
480 ,בית ג'ן ,202 ,שכיב ארסלאן
480 ,בית ג'ן ,375 ,שניר
480 ,בית ג'ן ,308 ,שרקרק
480 ,בית ג'ן ,393 ,תל אלפוח'אר
480 ,בית ג'ן ,394 ,תלאל אלריח
480 ,בית ג'ן ,239 ,תנתול
619 ,בית גוברין ,9000 ,בית גוברין
571 ,בית גמליאל ,9000 ,בית גמליאל
571 ,בית גמליאל ,101 ,הגפן
571 ,בית גמליאל ,102 ,ההדר
571 ,בית גמליאל ,103 ,הזית
571 ,בית גמליאל ,104 ,הרימון
571 ,בית גמליאל ,105 ,התאנה
571 ,בית גמליאל ,106 ,התמר
466 ,בית דגן ,155 ,אברהם אבינו
466 ,בית דגן ,139 ,איריס
466 ,בית דגן ,158 ,אלון
466 ,בית דגן ,141 ,ארבעת המינים
466 ,בית דגן ,130 ,בגין מנחם
466 ,בית דגן ,9000 ,בית דגן
466 ,בית דגן ,6503 ,בנה ביתך
466 ,בית דגן ,156 ,דולב
466 ,בית דגן ,115 ,דרך המ"ג
466 ,בית דגן ,116 ,האשכולית
466 ,בית דגן ,117 ,האתרוג
466 ,בית דגן ,131 ,הבנים
466 ,בית דגן ,144 ,הגולן
466 ,בית דגן ,150 ,הגליל
466 ,בית דגן ,120 ,הגפן
466 ,בית דגן ,143 ,הדס
466 ,בית דגן ,124 ,הזית
466 ,בית דגן ,122 ,החיטה
466 ,בית דגן ,149 ,החרמון
466 ,בית דגן ,128 ,היובל
466 ,בית דגן ,148 ,הכרמל
466 ,בית דגן ,142 ,הלולב
466 ,בית דגן ,129 ,הנגב
466 ,בית דגן ,107 ,העצמאות
466 ,בית דגן ,146 ,הערבה
466 ,בית דגן ,126 ,הרימון
466 ,בית דגן ,104 ,הרצל
466 ,בית דגן ,123 ,השעורה
466 ,בית דגן ,147 ,השפלה
466 ,בית דגן ,145 ,השרון
466 ,בית דגן ,125 ,התאנה
466 ,בית דגן ,121 ,התמר
466 ,בית דגן ,118 ,התפוז
466 ,בית דגן ,140 ,ורד
466 ,בית דגן ,106 ,ז'בוטינסקי
466 ,בית דגן ,132 ,חבצלת
466 ,בית דגן ,109 ,טרומפלדור
466 ,בית דגן ,151 ,יהודה
466 ,בית דגן ,136 ,יסמין
466 ,בית דגן ,133 ,כלנית
466 ,בית דגן ,102 ,כצנלסון
466 ,בית דגן ,135 ,לילך
466 ,בית דגן ,6502 ,נווה הדרים
466 ,בית דגן ,138 ,נרקיס
466 ,בית דגן ,134 ,סביון
466 ,בית דגן ,101 ,צה"ל
466 ,בית דגן ,113 ,קבוץ גלויות
466 ,בית דגן ,108 ,קפלן
466 ,בית דגן ,105 ,קק"ל
466 ,בית דגן ,114 ,רח ט
466 ,בית דגן ,112 ,רח י
466 ,בית דגן ,111 ,רמב"ן
466 ,בית דגן ,110 ,רמז
466 ,בית דגן ,137 ,רקפת
466 ,בית דגן ,6500 ,ש נווה גנים
466 ,בית דגן ,6501 ,ש נקודת חן
466 ,בית דגן ,6504 ,ש צמרות דגן
466 ,בית דגן ,103 ,שבזי
466 ,בית דגן ,127 ,שבעת המינים
466 ,בית דגן ,152 ,שומרון
466 ,בית דגן ,157 ,שקמה
723 ,בית הגדי ,9000 ,בית הגדי
373 ,בית הלוי ,9000 ,בית הלוי
373 ,בית הלוי ,6500 ,הרחבה
322 ,בית הלל ,9000 ,בית הלל
572 ,בית העמק ,9000 ,בית העמק
3645 ,בית הערבה ,9000 ,בית הערבה
242 ,בית השיטה ,9000 ,בית השיטה
353 ,בית זיד ,9000 ,בית זיד
353 ,בית זיד ,7001 ,כפר תקווה
710 ,בית זית ,9000 ,בית זית
710 ,בית זית ,106 ,בריכות היער
710 ,בית זית ,6500 ,גבעת ברושים
710 ,בית זית ,116 ,דרך הגבעה
710 ,בית זית ,101 ,דרך הזיתים
710 ,בית זית ,113 ,דרך הצבר
710 ,בית זית ,115 ,דרך השקד
710 ,בית זית ,108 ,האגם
710 ,בית זית ,111 ,האלה
710 ,בית זית ,112 ,האלון
710 ,בית זית ,107 ,הברוש
710 ,בית זית ,109 ,הגפן
710 ,בית זית ,102 ,הפסגה
710 ,בית זית ,114 ,מעלה הערבה
710 ,בית זית ,104 ,מעלה השיטה
710 ,בית זית ,103 ,נתיב האורנים
710 ,בית זית ,110 ,נתיב האפרסק
710 ,בית זית ,105 ,עין כרם
143 ,בית זרע ,9000 ,בית זרע
143 ,בית זרע ,101 ,בית ראשון במולדת
3575 ,בית חורון ,114 ,אבני אלגביש
3575 ,בית חורון ,102 ,איחוד העם
3575 ,בית חורון ,9000 ,בית חורון
3575 ,בית חורון ,115 ,דרך אוהד
3575 ,בית חורון ,107 ,דרך הנוף
3575 ,בית חורון ,106 ,דרך יהודה המכבי
3575 ,בית חורון ,104 ,המייסדים
3575 ,בית חורון ,101 ,הסובלנות
3575 ,בית חורון ,108 ,זאבי רחבעם
3575 ,בית חורון ,111 ,יהושע בן נון
3575 ,בית חורון ,103 ,כ"א בכסלו
3575 ,בית חורון ,110 ,מבוא דור ההמשך
3575 ,בית חורון ,109 ,מבוא המיצפור
3575 ,בית חורון ,105 ,מעלה בית חורון
3575 ,בית חורון ,113 ,נחלת אפרים
3575 ,בית חורון ,116 ,סיני
3575 ,בית חורון ,117 ,רקפת
3575 ,בית חורון ,112 ,שארה
877 ,בית חירות ,9000 ,בית חירות
877 ,בית חירות ,110 ,דרך ההדרים
877 ,בית חירות ,106 ,דרך הים
877 ,בית חירות ,107 ,האגוז
877 ,בית חירות ,104 ,האורן
877 ,בית חירות ,103 ,הדקל
877 ,בית חירות ,117 ,החורש
877 ,בית חירות ,108 ,הכיכר
877 ,בית חירות ,109 ,המדגרה
877 ,בית חירות ,111 ,הראשונים
877 ,בית חירות ,105 ,הרימון
877 ,בית חירות ,102 ,השונית
877 ,בית חירות ,112 ,התמר
877 ,בית חירות ,101 ,כוכב הים
877 ,בית חירות ,113 ,מורד ההדרים
877 ,בית חירות ,114 ,מעלה הפיקוס
877 ,בית חירות ,115 ,סמ האגוז
877 ,בית חירות ,116 ,סמ הראשונים
2033 ,בית חלקיה ,9000 ,בית חלקיה
159 ,בית חנן ,9000 ,בית חנן
159 ,בית חנן ,103 ,גבעת האילנות
159 ,בית חנן ,104 ,גבעת ההתיישבות
159 ,בית חנן ,105 ,דרך הברושים
159 ,בית חנן ,106 ,דרך המגשימים
159 ,בית חנן ,101 ,דרך המיסדים
159 ,בית חנן ,102 ,דרך הצבר
800 ,בית חנניה ,101 ,אודם
800 ,בית חנניה ,102 ,אלמוג
800 ,בית חנניה ,103 ,בהט
800 ,בית חנניה ,104 ,בזלת
800 ,בית חנניה ,9000 ,בית חנניה
800 ,בית חנניה ,105 ,ברקת
800 ,בית חנניה ,106 ,גרניט
800 ,בית חנניה ,107 ,דרך אמת המים
800 ,בית חנניה ,108 ,דרך הים
800 ,בית חנניה ,109 ,דרך הכרמל
800 ,בית חנניה ,110 ,חלמיש
800 ,בית חנניה ,111 ,טופז
800 ,בית חנניה ,112 ,טורקיז
800 ,בית חנניה ,113 ,ספיר
800 ,בית חנניה ,114 ,ענבר
1050 ,בית חשמונאי ,106 ,אלעזר החורני
1050 ,בית חשמונאי ,113 ,אשכול
1050 ,בית חשמונאי ,9000 ,בית חשמונאי
1050 ,בית חשמונאי ,111 ,דרך היין
1050 ,בית חשמונאי ,110 ,דרך הכרמים
1050 ,בית חשמונאי ,102 ,דרך המכבים
1050 ,בית חשמונאי ,115 ,הבציר
1050 ,בית חשמונאי ,116 ,הגפן
1050 ,בית חשמונאי ,117 ,הכרם
1050 ,בית חשמונאי ,105 ,יהודה המכבי
1050 ,בית חשמונאי ,118 ,יוחנן הגדי
1050 ,בית חשמונאי ,103 ,יונתן הוופסי
1050 ,בית חשמונאי ,112 ,עינב
1050 ,בית חשמונאי ,6500 ,שכ הכרמים
1050 ,בית חשמונאי ,104 ,שמעון התרסי
1050 ,בית חשמונאי ,114 ,שריג
288 ,בית יהושע ,103 ,אודם
288 ,בית יהושע ,108 ,ארגמן
288 ,בית יהושע ,9000 ,בית יהושע
288 ,בית יהושע ,104 ,ברקת
288 ,בית יהושע ,201 ,הגפן
288 ,בית יהושע ,204 ,הזית
288 ,בית יהושע ,202 ,החרוב
288 ,בית יהושע ,6500 ,הרחבה
288 ,בית יהושע ,205 ,הרימון
288 ,בית יהושע ,7001 ,הרציף
288 ,בית יהושע ,206 ,התאנה
288 ,בית יהושע ,203 ,התמר
288 ,בית יהושע ,105 ,יהלום
288 ,בית יהושע ,102 ,ספיר
288 ,בית יהושע ,101 ,שהם
288 ,בית יהושע ,106 ,שני
288 ,בית יהושע ,107 ,תכלת
265 ,בית יוסף ,9000 ,בית יוסף
200 ,בית ינאי ,109 ,אלמוג
200 ,בית ינאי ,9000 ,בית ינאי
200 ,בית ינאי ,107 ,דולפין
200 ,בית ינאי ,106 ,דקר
200 ,בית ינאי ,105 ,החבצלת
200 ,בית ינאי ,104 ,הים
200 ,בית ינאי ,101 ,הצוק
200 ,בית ינאי ,108 ,הרדוף
200 ,בית ינאי ,102 ,השיטה
200 ,בית ינאי ,111 ,מצפה ים
200 ,בית ינאי ,103 ,נר הלילה
200 ,בית ינאי ,110 ,קלר
326 ,בית יצחק-שער חפר ,9000 ,בית יצחק-שער חפר
326 ,בית יצחק-שער חפר ,117 ,דרך האילנות
326 ,בית יצחק-שער חפר ,122 ,דרך הרימונים
326 ,בית יצחק-שער חפר ,118 ,דרך השדות
326 ,בית יצחק-שער חפר ,119 ,דרך השרון
326 ,בית יצחק-שער חפר ,101 ,דרך חפר
326 ,בית יצחק-שער חפר ,104 ,דרך נירה
326 ,בית יצחק-שער חפר ,125 ,האורנים
326 ,בית יצחק-שער חפר ,137 ,האלה
326 ,בית יצחק-שער חפר ,140 ,האלונים
326 ,בית יצחק-שער חפר ,105 ,הבוסתן
326 ,בית יצחק-שער חפר ,112 ,הגנים
326 ,בית יצחק-שער חפר ,124 ,הגפן
326 ,בית יצחק-שער חפר ,120 ,הורדים
326 ,בית יצחק-שער חפר ,135 ,הזית
326 ,בית יצחק-שער חפר ,109 ,החבצלת
326 ,בית יצחק-שער חפר ,110 ,החצב
326 ,בית יצחק-שער חפר ,139 ,החרוב
326 ,בית יצחק-שער חפר ,107 ,הכלנית
326 ,בית יצחק-שער חפר ,128 ,הלילך
326 ,בית יצחק-שער חפר ,111 ,המגדל
326 ,בית יצחק-שער חפר ,115 ,הנרקיס
326 ,בית יצחק-שער חפר ,108 ,הסביון
326 ,בית יצחק-שער חפר ,127 ,העירית
326 ,בית יצחק-שער חפר ,106 ,העליה
326 ,בית יצחק-שער חפר ,114 ,הראשונים
326 ,בית יצחק-שער חפר ,134 ,הרותם
326 ,בית יצחק-שער חפר ,141 ,הרימון
326 ,בית יצחק-שער חפר ,116 ,הרקפת
326 ,בית יצחק-שער חפר ,138 ,השיטה
326 ,בית יצחק-שער חפר ,136 ,השקד
326 ,בית יצחק-שער חפר ,129 ,השקמה
326 ,בית יצחק-שער חפר ,123 ,התאנה
326 ,בית יצחק-שער חפר ,103 ,התמרים
326 ,בית יצחק-שער חפר ,132 ,משעול האילנות
326 ,בית יצחק-שער חפר ,133 ,סמטת 778
326 ,בית יצחק-שער חפר ,131 ,סמטת האקליפטוס
326 ,בית יצחק-שער חפר ,102 ,סמטת ההדרים
326 ,בית יצחק-שער חפר ,126 ,סמטת החרובים
326 ,בית יצחק-שער חפר ,130 ,סמטת הצבר
326 ,בית יצחק-שער חפר ,142 ,סמטת התות
326 ,בית יצחק-שער חפר ,113 ,רחבת הבנים
326 ,בית יצחק-שער חפר ,121 ,שער הכפר
430 ,בית לחם הגלילית ,9000 ,בית לחם הגלילית
430 ,בית לחם הגלילית ,107 ,דרך היער
430 ,בית לחם הגלילית ,109 ,האביב
430 ,בית לחם הגלילית ,105 ,הבוסתן
430 ,בית לחם הגלילית ,112 ,הדקל
430 ,בית לחם הגלילית ,116 ,החורש
430 ,בית לחם הגלילית ,104 ,היוגב
430 ,בית לחם הגלילית ,108 ,הכרם
430 ,בית לחם הגלילית ,115 ,העומר
430 ,בית לחם הגלילית ,110 ,העמק
430 ,בית לחם הגלילית ,102 ,הראשונים
430 ,בית לחם הגלילית ,103 ,השדות
430 ,בית לחם הגלילית ,101 ,השדרה הטמפלרית
430 ,בית לחם הגלילית ,106 ,התבואה
430 ,בית לחם הגלילית ,113 ,מגדל המים
430 ,בית לחם הגלילית ,114 ,סמטת הפעמון
430 ,בית לחם הגלילית ,111 ,רגבים
751 ,בית מאיר ,9000 ,בית מאיר
751 ,בית מאיר ,7001 ,רמות שפירא
784 ,בית נחמיה ,9000 ,בית נחמיה
784 ,בית נחמיה ,102 ,דרך הורד
784 ,בית נחמיה ,103 ,דרך החצב
784 ,בית נחמיה ,104 ,דרך החרצית
784 ,בית נחמיה ,101 ,דרך היסמין
784 ,בית נחמיה ,109 ,דרך היקינטון
784 ,בית נחמיה ,105 ,דרך הכלנית
16 ,בית ניר ,9000 ,בית ניר
672 ,בית נקופה ,9000 ,בית נקופה
672 ,בית נקופה ,105 ,האגוז
672 ,בית נקופה ,107 ,האורן
672 ,בית נקופה ,108 ,האלה
672 ,בית נקופה ,109 ,האלון
672 ,בית נקופה ,102 ,הברוש
672 ,בית נקופה ,104 ,הזית
672 ,בית נקופה ,101 ,המייסדים
672 ,בית נקופה ,106 ,הערבה
672 ,בית נקופה ,103 ,השקד
672 ,בית נקופה ,110 ,סמ התמר
202 ,בית עובד ,9000 ,בית עובד
202 ,בית עובד ,101 ,ההתיישבות
202 ,בית עובד ,102 ,המייסדים
202 ,בית עובד ,106 ,משעול איריס
202 ,בית עובד ,116 ,משעול דבורנית
202 ,בית עובד ,115 ,משעול הרדוף
202 ,בית עובד ,104 ,משעול חצב
202 ,בית עובד ,107 ,משעול חרצית
202 ,בית עובד ,108 ,משעול כלנית
202 ,בית עובד ,110 ,משעול כרכום
202 ,בית עובד ,111 ,משעול לוטם
202 ,בית עובד ,103 ,משעול מרווה
202 ,בית עובד ,113 ,משעול נורית
202 ,בית עובד ,112 ,משעול נרקיס
202 ,בית עובד ,117 ,משעול סביון
202 ,בית עובד ,105 ,משעול פעמונית
202 ,בית עובד ,109 ,משעול צבעוני
202 ,בית עובד ,114 ,משעול רותם
301 ,בית עוזיאל ,9000 ,בית עוזיאל
756 ,בית עזרא ,9000 ,בית עזרא
604 ,בית עריף ,105 ,ארבל
604 ,בית עריף ,103 ,אשלים
604 ,בית עריף ,9000 ,בית עריף
604 ,בית עריף ,106 ,דולב
604 ,בית עריף ,108 ,יעלים
604 ,בית עריף ,101 ,ירדן
604 ,בית עריף ,107 ,ערוגות
604 ,בית עריף ,109 ,פולג
604 ,בית עריף ,102 ,שניר
604 ,בית עריף ,104 ,תבור
212 ,בית צבי ,9000 ,בית צבי
598 ,בית קמה ,9000 ,בית קמה
365 ,בית קשת ,9000 ,בית קשת
365 ,בית קשת ,101 ,בית ראשון במולדת
848 ,בית רבן ,9000 ,בית רבן
1162 ,בית רימון ,114 ,ארגמן
1162 ,בית רימון ,9000 ,בית רימון
1162 ,בית רימון ,102 ,גינת האגוז
1162 ,בית רימון ,117 ,גלעד
1162 ,בית רימון ,103 ,הגפן
1162 ,בית רימון ,113 ,הדודאים
1162 ,בית רימון ,101 ,הכרם
1162 ,בית רימון ,107 ,התאנה
1162 ,בית רימון ,108 ,התפוח
1162 ,בית רימון ,110 ,חוט השני
1162 ,בית רימון ,115 ,חרמון
1162 ,בית רימון ,116 ,כרמל
1162 ,בית רימון ,106 ,מעיין גנים
1162 ,בית רימון ,111 ,ערוגות הבושם
1162 ,בית רימון ,109 ,עת הזמיר
1162 ,בית רימון ,104 ,פלח הרימון
1162 ,בית רימון ,105 ,פרדס רימונים
1162 ,בית רימון ,112 ,שושנת העמקים
1162 ,בית רימון ,118 ,שניר
9200 ,בית שאן ,113 ,אביטן יהושע
9200 ,בית שאן ,166 ,אביר יעקב
9200 ,בית שאן ,126 ,אבן שושן אברהם
9200 ,בית שאן ,128 ,אהבת ישראל
9200 ,בית שאן ,130 ,אהבת ציון
9200 ,בית שאן ,308 ,אהוד מנור
9200 ,בית שאן ,200 ,אורן
9200 ,בית שאן ,6519 ,אזור תעשיה מזרחי
9200 ,בית שאן ,6518 ,אזור תעשיה צפוני
9200 ,בית שאן ,223 ,אח"י אילת
9200 ,בית שאן ,224 ,אח"י דקר
9200 ,בית שאן ,161 ,אחד העם
9200 ,בית שאן ,236 ,אירוס
9200 ,בית שאן ,240 ,איתן רפאל
9200 ,בית שאן ,203 ,אלה
9200 ,בית שאן ,215 ,אלון
9200 ,בית שאן ,230 ,אמנון ותמר
9200 ,בית שאן ,183 ,אמנון ליפקין שחק
9200 ,בית שאן ,242 ,אצ"ל
9200 ,בית שאן ,202 ,ארז
9200 ,בית שאן ,301 ,אריק איינשטיין
9200 ,בית שאן ,201 ,אשל
9200 ,בית שאן ,328 ,בבא סאלי
9200 ,בית שאן ,150 ,בויאר אווה
9200 ,בית שאן ,156 ,בורוכוב
9200 ,בית שאן ,135 ,ביאליק
9200 ,בית שאן ,9000 ,בית שאן
9200 ,בית שאן ,136 ,בלפור
9200 ,בית שאן ,212 ,בן גוריון
9200 ,בית שאן ,168 ,בן יהודה
9200 ,בית שאן ,138 ,בן לולו
9200 ,בית שאן ,221 ,בן צבי
9200 ,בית שאן ,164 ,בר כוכבא
9200 ,בית שאן ,243 ,בר-לב חיים
9200 ,בית שאן ,219 ,ברוש
9200 ,בית שאן ,165 ,ברנר
9200 ,בית שאן ,252 ,גולדה מאיר
9200 ,בית שאן ,142 ,גולומב
9200 ,בית שאן ,320 ,גרינבר אורי צבי
9200 ,בית שאן ,143 ,דבורה הנביאה
9200 ,בית שאן ,244 ,דובדבן
9200 ,בית שאן ,241 ,דוד אלעזר
9200 ,בית שאן ,214 ,דולב
9200 ,בית שאן ,326 ,דונה גרציה
9200 ,בית שאן ,321 ,דורי יעקב
9200 ,בית שאן ,226 ,דיין משה
9200 ,בית שאן ,222 ,דקל
9200 ,בית שאן ,311 ,דרך הבנים
9200 ,בית שאן ,217 ,דרך השלום
9200 ,בית שאן ,312 ,דרך מאיר
9200 ,בית שאן ,260 ,האסיף
9200 ,בית שאן ,144 ,הבונים
9200 ,בית שאן ,262 ,הבוסתן
9200 ,בית שאן ,261 ,הבציר
9200 ,בית שאן ,271 ,הבקעה
9200 ,בית שאן ,192 ,הגביש
9200 ,בית שאן ,275 ,הגולן
9200 ,בית שאן ,179 ,הגלבוע
9200 ,בית שאן ,263 ,הגפן
9200 ,בית שאן ,264 ,הגת
9200 ,בית שאן ,267 ,הדגן
9200 ,בית שאן ,266 ,ההדר
9200 ,בית שאן ,280 ,החופית
9200 ,בית שאן ,265 ,החורש
9200 ,בית שאן ,191 ,החרמש
9200 ,בית שאן ,199 ,החרש
9200 ,בית שאן ,195 ,החשמל
9200 ,בית שאן ,188 ,היוזמה
9200 ,בית שאן ,187 ,היצירה
9200 ,בית שאן ,282 ,הירקון
9200 ,בית שאן ,218 ,היתד
9200 ,בית שאן ,273 ,הכרמל
9200 ,בית שאן ,255 ,הל"ה
9200 ,בית שאן ,250 ,המייסדים
9200 ,בית שאן ,114 ,המירון
9200 ,בית שאן ,163 ,המעפילים
9200 ,בית שאן ,122 ,המשטרה
9200 ,בית שאן ,132 ,הנגב
9200 ,בית שאן ,194 ,הסדנא
9200 ,בית שאן ,185 ,העבודה
9200 ,בית שאן ,6516 ,העיר העתיקה
9200 ,בית שאן ,189 ,העמל
9200 ,בית שאן ,141 ,העמק
9200 ,בית שאן ,117 ,העצמון
9200 ,בית שאן ,251 ,הפלמ"ח
9200 ,בית שאן ,145 ,הצנחנים
9200 ,בית שאן ,281 ,הקציר
9200 ,בית שאן ,256 ,הראל
9200 ,בית שאן ,119 ,הרב אברהם בדוש
9200 ,בית שאן ,175 ,הרב אהרון שטיינמן
9200 ,בית שאן ,322 ,הרב אליהו הכהן
9200 ,בית שאן ,327 ,הרב אלישע וישלינצקי
9200 ,בית שאן ,171 ,הרב יוסף שמחון
9200 ,בית שאן ,169 ,הרב יצחק הרצוג
9200 ,בית שאן ,329 ,הרב ישראל קבלו
9200 ,בית שאן ,125 ,הרב לסרי
9200 ,בית שאן ,258 ,הרב מרדכי אליהו
9200 ,בית שאן ,129 ,הרב סויסה דוד
9200 ,בית שאן ,330 ,הרב עובדיה יוסף
9200 ,בית שאן ,157 ,הרב קוק
9200 ,בית שאן ,177 ,הרב ש"ך
9200 ,בית שאן ,139 ,הרופאים
9200 ,בית שאן ,155 ,הרמב"ם
9200 ,בית שאן ,323 ,הרצוג חיים
9200 ,בית שאן ,106 ,הרצל
9200 ,בית שאן ,186 ,הרתך
9200 ,בית שאן ,274 ,השומרון
9200 ,בית שאן ,121 ,השחם
9200 ,בית שאן ,259 ,השייטת
9200 ,בית שאן ,110 ,השישה
9200 ,בית שאן ,270 ,השפלה
9200 ,בית שאן ,272 ,השרון
9200 ,בית שאן ,137 ,התומר
9200 ,בית שאן ,268 ,התירוש
9200 ,בית שאן ,193 ,התנופה
9200 ,בית שאן ,324 ,וייצמן עזר
9200 ,בית שאן ,206 ,ויצמן חיים
9200 ,בית שאן ,182 ,זאבי רחבעם
9200 ,בית שאן ,205 ,זית
9200 ,בית שאן ,231 ,חבצלת
9200 ,בית שאן ,103 ,חומה ומגדל
9200 ,בית שאן ,269 ,חטיבת גבעתי
9200 ,בית שאן ,225 ,חטיבת גולני
9200 ,בית שאן ,276 ,חטיבת הנח"ל
9200 ,בית שאן ,283 ,חטיבת קרייתי
9200 ,בית שאן ,228 ,חצב
9200 ,בית שאן ,284 ,חרוב
9200 ,בית שאן ,148 ,טולידנו
9200 ,בית שאן ,197 ,טיילת המלך חסן השני
9200 ,בית שאן ,140 ,טרומפלדור
9200 ,בית שאן ,238 ,ידין יגאל
9200 ,בית שאן ,109 ,יהודה הלוי
9200 ,בית שאן ,286 ,יונתן
9200 ,בית שאן ,307 ,יוסי בנאי
9200 ,בית שאן ,235 ,יסמין
9200 ,בית שאן ,313 ,יעקב ג'קיטו מאסטרו
9200 ,בית שאן ,302 ,יפה ירקוני
9200 ,בית שאן ,287 ,יפתח
9200 ,בית שאן ,112 ,ירושלים הבירה
9200 ,בית שאן ,304 ,ישראל פוליאקוב
9200 ,בית שאן ,210 ,כליל החורש
9200 ,בית שאן ,158 ,כנסת ישראל
9200 ,בית שאן ,131 ,כנרת
9200 ,בית שאן ,317 ,לאה גולדברג
9200 ,בית שאן ,249 ,לוי אשכול
9200 ,בית שאן ,288 ,לוי משה
9200 ,בית שאן ,289 ,לח"י
9200 ,בית שאן ,290 ,ליטני
9200 ,בית שאן ,196 ,ללוש אנדרה
9200 ,בית שאן ,291 ,לסקוב חיים
9200 ,בית שאן ,151 ,מאיר בעל הנס
9200 ,בית שאן ,173 ,מוטה גור
9200 ,בית שאן ,146 ,מיזוג גלויות
9200 ,בית שאן ,147 ,מכלוף יעקב
9200 ,בית שאן ,120 ,מעון עולים
9200 ,בית שאן ,292 ,מעפילי אגוז
9200 ,בית שאן ,293 ,מצדה
9200 ,בית שאן ,246 ,מקלף מרדכי
9200 ,בית שאן ,149 ,מרקוס
9200 ,בית שאן ,174 ,נהריים
9200 ,בית שאן ,6514 ,נוף הגלבוע
9200 ,בית שאן ,6515 ,נוף הירדן
9200 ,בית שאן ,233 ,נופר
9200 ,בית שאן ,134 ,נורדאו
9200 ,בית שאן ,237 ,נורית
9200 ,בית שאן ,310 ,נח מרדינגר
9200 ,בית שאן ,303 ,נחום היימן
9200 ,בית שאן ,294 ,נחשון
9200 ,בית שאן ,248 ,נרקיס עוזי
9200 ,בית שאן ,229 ,סחלב
9200 ,בית שאן ,167 ,סנש חנה
9200 ,בית שאן ,305 ,עוזי חיטמן
9200 ,בית שאן ,306 ,עופרה חזה
9200 ,בית שאן ,315 ,עזריה אלון
9200 ,בית שאן ,152 ,עליה
9200 ,בית שאן ,213 ,פיקוס
9200 ,בית שאן ,234 ,צבעוני
9200 ,בית שאן ,247 ,צה"ל
9200 ,בית שאן ,295 ,צור צבי
9200 ,בית שאן ,325 ,צורי נחמיה
9200 ,בית שאן ,227 ,ציפורן
9200 ,בית שאן ,207 ,צפצפה
9200 ,בית שאן ,296 ,קדש
9200 ,בית שאן ,184 ,קהילת יהדות סלוניקי
9200 ,בית שאן ,153 ,קפלן
9200 ,בית שאן ,6520 ,קרית רבין
9200 ,בית שאן ,170 ,רבי אברהם תורג'מן
9200 ,בית שאן ,319 ,רבי נחמן מברסלב
9200 ,בית שאן ,198 ,רבינוביץ חנן
9200 ,בית שאן ,159 ,רוגובסקי
9200 ,בית שאן ,318 ,רועי קליין
9200 ,בית שאן ,220 ,רותם
9200 ,בית שאן ,123 ,רח א
9200 ,בית שאן ,124 ,רח ד
9200 ,בית שאן ,118 ,רח ה
9200 ,בית שאן ,108 ,רח ו
9200 ,בית שאן ,102 ,רח ח
9200 ,בית שאן ,107 ,רח ט
9200 ,בית שאן ,316 ,רחל המשוררת
9200 ,בית שאן ,211 ,רימון
9200 ,בית שאן ,297 ,רמון אילן
9200 ,בית שאן ,160 ,רמז
9200 ,בית שאן ,232 ,רקפת
9200 ,בית שאן ,6502 ,ש בנה ביתך
9200 ,בית שאן ,6512 ,ש ז'בוטינסקי
9200 ,בית שאן ,6505 ,ש עממי
9200 ,בית שאן ,6506 ,ש צריפים אדומים
9200 ,בית שאן ,6503 ,ש קדמת עדן
9200 ,בית שאן ,105 ,שאול המלך
9200 ,בית שאן ,245 ,שד יצחק רבין
9200 ,בית שאן ,101 ,שד מנחם בגין
9200 ,בית שאן ,181 ,שדרות הארבעה
9200 ,בית שאן ,298 ,שומרון דן
9200 ,בית שאן ,309 ,שושנה דמארי
9200 ,בית שאן ,204 ,שז"ר זלמן
9200 ,בית שאן ,277 ,שטרן יאיר
9200 ,בית שאן ,208 ,שיטה
9200 ,בית שאן ,239 ,שיקמה
9200 ,בית שאן ,6507 ,שכ אליהו
9200 ,בית שאן ,6508 ,שכ יפה נוף
9200 ,בית שאן ,6524 ,שכ יצחק נבון
9200 ,בית שאן ,6523 ,שכ יצחק שמיר
9200 ,בית שאן ,6521 ,שכ מרכז
9200 ,בית שאן ,6504 ,שכ נווה הדקל
9200 ,בית שאן ,6511 ,שכ נווה הנחל
9200 ,בית שאן ,6517 ,שכ נוף גלעד
9200 ,בית שאן ,6501 ,שכ נוף גנים
9200 ,בית שאן ,6509 ,שכ נוף קדומים
9200 ,בית שאן ,6510 ,שכ נוף שאן
9200 ,בית שאן ,6513 ,שכ צמרת
9200 ,בית שאן ,299 ,שלד"ג
9200 ,בית שאן ,278 ,שלום הגליל
9200 ,בית שאן ,314 ,שמעון ויזנטל
9200 ,בית שאן ,300 ,שמר נעמי
9200 ,בית שאן ,154 ,שמש יצחק
9200 ,בית שאן ,216 ,שקד
9200 ,בית שאן ,253 ,שרת משה
9200 ,בית שאן ,254 ,ששת הימים
9200 ,בית שאן ,209 ,תאנה
9200 ,בית שאן ,180 ,תדהר
9200 ,בית שאן ,190 ,תוצרת הארץ
9200 ,בית שאן ,279 ,תמיר מרדכי
2610 ,בית שמש ,179 ,אבא נעמת
2610 ,בית שמש ,280 ,אביטל
2610 ,בית שמש ,340 ,אביי
2610 ,בית שמש ,293 ,אבן גבירול
2610 ,בית שמש ,237 ,אדם יקותיאל
2610 ,בית שמש ,215 ,אהבת ישראל
2610 ,בית שמש ,503 ,אהבת שלום
2610 ,בית שמש ,370 ,אהרונסון
2610 ,בית שמש ,371 ,אהרן שמי
2610 ,בית שמש ,318 ,אודם
2610 ,בית שמש ,455 ,אוהל יהושע
2610 ,בית שמש ,150 ,אולפן המשטרה
2610 ,בית שמש ,222 ,אור החיים
2610 ,בית שמש ,224 ,אור שמח
2610 ,בית שמש ,281 ,אורטל
2610 ,בית שמש ,6500 ,אזור התעשיה צפון
2610 ,בית שמש ,6511 ,אזור תעשיה מערבי
2610 ,בית שמש ,425 ,אחיה השילוני
2610 ,בית שמש ,223 ,אחיעזר
2610 ,בית שמש ,570 ,אילפא
2610 ,בית שמש ,380 ,אלבז
2610 ,בית שמש ,148 ,אלון יגאל
2610 ,בית שמש ,473 ,אליהו הנביא
2610 ,בית שמש ,127 ,אליכין
2610 ,בית שמש ,474 ,אלישע הנביא
2610 ,בית שמש ,161 ,אלנקוה רפאל
2610 ,בית שמש ,129 ,אלעזרי
2610 ,בית שמש ,211 ,אמרי אמת
2610 ,בית שמש ,504 ,אמרי נועם
2610 ,בית שמש ,513 ,אסתר
2610 ,בית שמש ,110 ,ארלוזורוב
2610 ,בית שמש ,242 ,אשכול לוי
2610 ,בית שמש ,231 ,אשר
2610 ,בית שמש ,487 ,באר שבע
2610 ,בית שמש ,128 ,בוסקילה יוסף
2610 ,בית שמש ,319 ,בזלת
2610 ,בית שמש ,123 ,ביאליק
2610 ,בית שמש ,109 ,ביחר שמשון
2610 ,בית שמש ,130 ,בית ג'מאל
2610 ,בית שמש ,149 ,בית זולוטוב
2610 ,בית שמש ,226 ,בית ישראל
2610 ,בית שמש ,9000 ,בית שמש
2610 ,בית שמש ,6509 ,בית שמש הותיקה
2610 ,בית שמש ,189 ,בן איש חי
2610 ,בית שמש ,202 ,בן אליעזר
2610 ,בית שמש ,254 ,בן גוריון פולה
2610 ,בית שמש ,158 ,בן גרא
2610 ,בית שמש ,252 ,בן זאב יהודה
2610 ,בית שמש ,450 ,בן עזאי
2610 ,בית שמש ,145 ,בן צבי
2610 ,בית שמש ,187 ,בן ציון אבא שאול
2610 ,בית שמש ,297 ,בנאי יוסי
2610 ,בית שמש ,124 ,בני דן
2610 ,בית שמש ,323 ,בנימין
2610 ,בית שמש ,325 ,בעל הסולם
2610 ,בית שמש ,505 ,בעל התניא
2610 ,בית שמש ,203 ,בר אילן
2610 ,בית שמש ,361 ,בר קפרא
2610 ,בית שמש ,229 ,ברכת אברהם
2610 ,בית שמש ,6527 ,גבעת בן צבי
2610 ,בית שמש ,6526 ,גבעת ויצמן
2610 ,בית שמש ,6517 ,גבעת סביון
2610 ,בית שמש ,6528 ,גבעת שז"ר
2610 ,בית שמש ,6501 ,גבעת שרת
2610 ,בית שמש ,232 ,גד
2610 ,בית שמש ,240 ,גונן שמואל
2610 ,בית שמש ,122 ,גור אריה
2610 ,בית שמש ,243 ,גלעד
2610 ,בית שמש ,6507 ,גני יהודית
2610 ,בית שמש ,6502 ,גני שמש
2610 ,בית שמש ,316 ,גרניט
2610 ,בית שמש ,550 ,דבורה הנביאה
2610 ,בית שמש ,453 ,דובר שלום
2610 ,בית שמש ,257 ,דורי יעקב
2610 ,בית שמש ,315 ,דלומיט
2610 ,בית שמש ,116 ,דליה
2610 ,בית שמש ,111 ,דם המכבים
2610 ,בית שמש ,515 ,דניאל
2610 ,בית שמש ,285 ,דרך החיים
2610 ,בית שמש ,247 ,דרך רבין יצחק
2610 ,בית שמש ,552 ,דרכי איש
2610 ,בית שמש ,449 ,דרכי אליעזר
2610 ,בית שמש ,272 ,האגס
2610 ,בית שמש ,502 ,האדמו"ר מבעלזא
2610 ,בית שמש ,506 ,האדמור מרוז'ין
2610 ,בית שמש ,507 ,האדר"ת
2610 ,בית שמש ,106 ,האומן
2610 ,בית שמש ,268 ,האורן
2610 ,בית שמש ,379 ,האחים
2610 ,בית שמש ,166 ,האיריס
2610 ,בית שמש ,125 ,האלה
2610 ,בית שמש ,273 ,האפרסק
2610 ,בית שמש ,138 ,האצ"ל
2610 ,בית שמש ,244 ,הארבל
2610 ,בית שמש ,274 ,הארז
2610 ,בית שמש ,174 ,הארזים
2610 ,בית שמש ,269 ,האתרוג
2610 ,בית שמש ,141 ,הבנאי
2610 ,בית שמש ,508 ,הבעל שם טוב
2610 ,בית שמש ,266 ,הברוש
2610 ,בית שמש ,509 ,הגאון מוילנא
2610 ,בית שמש ,251 ,הגולן
2610 ,בית שמש ,248 ,הגליל
2610 ,בית שמש ,115 ,הגפן
2610 ,בית שמש ,176 ,הדובדבן
2610 ,בית שמש ,278 ,הדולב
2610 ,בית שמש ,270 ,ההדס
2610 ,בית שמש ,263 ,ההדר
2610 ,בית שמש ,305 ,ההכשרה
2610 ,בית שמש ,476 ,הושע הנביא
2610 ,בית שמש ,155 ,הותיקים
2610 ,בית שמש ,193 ,החבצלת
2610 ,בית שמש ,526 ,החוזה מלובלין
2610 ,בית שמש ,104 ,החלוצים
2610 ,בית שמש ,188 ,החפץ חיים
2610 ,בית שמש ,191 ,החצב
2610 ,בית שמש ,264 ,החרובים
2610 ,בית שמש ,306 ,החרוצים
2610 ,בית שמש ,245 ,החרמון
2610 ,בית שמש ,192 ,החרצית
2610 ,בית שמש ,152 ,החרש
2610 ,בית שמש ,195 ,היסמין
2610 ,בית שמש ,301 ,היצירה
2610 ,בית שמש ,167 ,הכלנית
2610 ,בית שמש ,495 ,הלל
2610 ,בית שמש ,527 ,המגיד ממעזריטש
2610 ,בית שמש ,220 ,המהרי"ל דיסקין
2610 ,בית שמש ,136 ,המורה יעקב
2610 ,בית שמש ,260 ,המורה צבי
2610 ,בית שמש ,126 ,המכתש
2610 ,בית שמש ,304 ,המלאכה
2610 ,בית שמש ,511 ,המלך דוד
2610 ,בית שמש ,142 ,המסגר
2610 ,בית שמש ,121 ,המעפילים
2610 ,בית שמש ,112 ,המשלט
2610 ,בית שמש ,311 ,הנביא עובדיה
2610 ,בית שמש ,312 ,הנביא עמוס
2610 ,בית שמש ,529 ,הנביא צפניה
2610 ,בית שמש ,165 ,הנורית
2610 ,בית שמש ,528 ,הנצ"יב
2610 ,בית שמש ,169 ,הנרקיס
2610 ,בית שמש ,113 ,הנשיא
2610 ,בית שמש ,168 ,הסביון
2610 ,בית שמש ,308 ,הסוללה
2610 ,בית שמש ,163 ,הסיגלית
2610 ,בית שמש ,373 ,העונות
2610 ,בית שמש ,105 ,העליה
2610 ,בית שמש ,303 ,העמל
2610 ,בית שמש ,265 ,הערבה
2610 ,בית שמש ,275 ,הערמון
2610 ,בית שמש ,276 ,הצאלה
2610 ,בית שמש ,310 ,הצבע
2610 ,בית שמש ,448 ,הצדיק משטפנשט
2610 ,בית שמש ,194 ,הציפורן
2610 ,בית שמש ,286 ,הר בשן
2610 ,בית שמש ,289 ,הר גבנונים
2610 ,בית שמש ,300 ,הר הכרמל
2610 ,בית שמש ,287 ,הר המוריה
2610 ,בית שמש ,288 ,הר מירון
2610 ,בית שמש ,284 ,הר נבו
2610 ,בית שמש ,551 ,הרב אברהם שפירא
2610 ,בית שמש ,553 ,הרב איידלשטיין
2610 ,בית שמש ,534 ,הרב אלישיב
2610 ,בית שמש ,556 ,הרב ברוק
2610 ,בית שמש ,532 ,הרב גריינימן
2610 ,בית שמש ,531 ,הרב האדמו"ר החלוץ
2610 ,בית שמש ,216 ,הרב הרצוג
2610 ,בית שמש ,533 ,הרב יוסף עזרן
2610 ,בית שמש ,535 ,הרב יעקב יוסף
2610 ,בית שמש ,536 ,הרב יצחק כדורי
2610 ,בית שמש ,554 ,הרב ישראל גרוסמן
2610 ,בית שמש ,555 ,הרב ליפקוביץ
2610 ,בית שמש ,213 ,הרב מבריסק
2610 ,בית שמש ,537 ,הרב מנחם פרוש
2610 ,בית שמש ,538 ,הרב מרדכי צמח אליהו
2610 ,בית שמש ,214 ,הרב ספקטור
2610 ,בית שמש ,539 ,הרב קוק
2610 ,בית שמש ,546 ,הרב שלמה זלמן
2610 ,בית שמש ,540 ,הרב שלמה לורניץ
2610 ,בית שמש ,279 ,הרטוב
2610 ,בית שמש ,101 ,הרצל
2610 ,בית שמש ,164 ,הרקפת
2610 ,בית שמש ,547 ,הרש"ר הירש
2610 ,בית שמש ,144 ,השבעה
2610 ,בית שמש ,190 ,השושן
2610 ,בית שמש ,277 ,השיזף
2610 ,בית שמש ,261 ,השיטה
2610 ,בית שמש ,377 ,השלושה
2610 ,בית שמש ,131 ,השקד
2610 ,בית שמש ,262 ,התאנה
2610 ,בית שמש ,246 ,התבור
2610 ,בית שמש ,302 ,התעשיה
2610 ,בית שמש ,271 ,התפוח
2610 ,בית שמש ,307 ,וירג'יניה
2610 ,בית שמש ,207 ,ורד
2610 ,בית שמש ,103 ,ז'בוטינסקי
2610 ,בית שמש ,209 ,זכרון יעקב
2610 ,בית שמש ,480 ,זכריה הנביא
2610 ,בית שמש ,568 ,זעירי
2610 ,בית שמש ,382 ,חביבה רייך
2610 ,בית שמש ,481 ,חבקוק הנביא
2610 ,בית שמש ,485 ,חברון
2610 ,בית שמש ,384 ,חגואל
2610 ,בית שמש ,426 ,חגי הנביא
2610 ,בית שמש ,314 ,חולדה הנביאה
2610 ,בית שמש ,309 ,חושן
2610 ,בית שמש ,212 ,חזון איש
2610 ,בית שמש ,186 ,חיד"א
2610 ,בית שמש ,541 ,חלקת יהושע
2610 ,בית שמש ,444 ,חצרות ערבי הנחל
2610 ,בית שמש ,488 ,טבריה
2610 ,בית שמש ,321 ,טופז
2610 ,בית שמש ,299 ,טורקיז
2610 ,בית שמש ,372 ,טרומפלדור
2610 ,בית שמש ,378 ,יאיר
2610 ,בית שמש ,489 ,יבנה
2610 ,בית שמש ,255 ,ידין יגאל
2610 ,בית שמש ,294 ,יהודה הלוי
2610 ,בית שמש ,180 ,יהודה המכבי
2610 ,בית שמש ,317 ,יהלום
2610 ,בית שמש ,484 ,יואל הנביא
2610 ,בית שמש ,542 ,יוכבד
2610 ,בית שמש ,427 ,יונה בן אמיתי
2610 ,בית שמש ,376 ,יונתן
2610 ,בית שמש ,183 ,יונתן בן עוזיאל
2610 ,בית שמש ,523 ,יחזקאל הנביא
2610 ,בית שמש ,204 ,יצחק שדה
2610 ,בית שמש ,490 ,ירושלים
2610 ,בית שמש ,524 ,ירמיהו הנביא
2610 ,בית שמש ,521 ,ישעיהו הנביא
2610 ,בית שמש ,185 ,כביש 38
2610 ,בית שמש ,6001 ,ככר נוימן
2610 ,בית שמש ,221 ,לב שמחה
2610 ,בית שמש ,239 ,לוין אריה
2610 ,בית שמש ,235 ,לנר דן
2610 ,בית שמש ,241 ,לסקוב חיים
2610 ,בית שמש ,236 ,מגן קלמן
2610 ,בית שמש ,153 ,מורדי הגטאות
2610 ,בית שמש ,157 ,מורי יעקב
2610 ,בית שמש ,501 ,מחזיקי הדת
2610 ,בית שמש ,282 ,מיטל
2610 ,בית שמש ,518 ,מלאכי הנביא
2610 ,בית שמש ,238 ,מנדלר אלברט
2610 ,בית שמש ,118 ,מנוח
2610 ,בית שמש ,296 ,מנור אהוד
2610 ,בית שמש ,228 ,מנחת יצחק
2610 ,בית שמש ,132 ,מעלה דוד
2610 ,בית שמש ,133 ,מעלה הסיירים
2610 ,בית שמש ,134 ,מעלה הצופים
2610 ,בית שמש ,135 ,מעלה יאיר
2610 ,בית שמש ,500 ,מעלות הרמ"א
2610 ,בית שמש ,230 ,מעפילי אגוז
2610 ,בית שמש ,354 ,מערבא
2610 ,בית שמש ,225 ,מעשי חייא
2610 ,בית שמש ,139 ,מצדה
2610 ,בית שמש ,494 ,מצפה הרש"פ
2610 ,בית שמש ,256 ,מקלף מרדכי
2610 ,בית שמש ,560 ,מר זוטרא
2610 ,בית שמש ,564 ,מר ינוקא
2610 ,בית שמש ,350 ,מר עוקבא
2610 ,בית שמש ,522 ,מרים הנביאה
2610 ,בית שמש ,151 ,מרכז קליטה
2610 ,בית שמש ,381 ,משה ארנס
2610 ,בית שמש ,520 ,משה רבנו
2610 ,בית שמש ,108 ,משטרת הרטוב
2610 ,בית שמש ,259 ,משעול המתמיד
2610 ,בית שמש ,410 ,נהר הירדן
2610 ,בית שמש ,420 ,נהר הירקון
2610 ,בית שמש ,351 ,נהרדעא
2610 ,בית שמש ,6519 ,נוה ספיר
2610 ,בית שמש ,544 ,נועם אלימלך
2610 ,בית שמש ,6523 ,נופי אביב
2610 ,בית שמש ,178 ,נופי הדר
2610 ,בית שמש ,428 ,נחום הנביא
2610 ,בית שמש ,411 ,נחל אוריה
2610 ,בית שמש ,412 ,נחל איילון
2610 ,בית שמש ,441 ,נחל אכזיב
2610 ,בית שמש ,409 ,נחל אל-על
2610 ,בית שמש ,408 ,נחל אלכסנדר
2610 ,בית שמש ,413 ,נחל גילה
2610 ,בית שמש ,400 ,נחל דולב
2610 ,בית שמש ,439 ,נחל הבשור
2610 ,בית שמש ,419 ,נחל הקישון
2610 ,בית שמש ,433 ,נחל זוהר
2610 ,בית שמש ,430 ,נחל זויתן
2610 ,בית שמש ,434 ,נחל חבר
2610 ,בית שמש ,401 ,נחל יעלה
2610 ,בית שמש ,414 ,נחל לוז
2610 ,בית שמש ,415 ,נחל לכיש
2610 ,בית שמש ,402 ,נחל מאור
2610 ,בית שמש ,403 ,נחל מטע
2610 ,בית שמש ,416 ,נחל מיכה
2610 ,בית שמש ,442 ,נחל משמר
2610 ,בית שמש ,418 ,נחל נועם
2610 ,בית שמש ,417 ,נחל נחשון
2610 ,בית שמש ,443 ,נחל ניצנים
2610 ,בית שמש ,445 ,נחל סנסן
2610 ,בית שמש ,435 ,נחל עין גדי
2610 ,בית שמש ,436 ,נחל ערוגות
2610 ,בית שמש ,530 ,נחל פולג
2610 ,בית שמש ,548 ,נחל פרת
2610 ,בית שמש ,543 ,נחל קדרון
2610 ,בית שמש ,404 ,נחל קטלב
2610 ,בית שמש ,440 ,נחל רביבים
2610 ,בית שמש ,405 ,נחל רזיאל
2610 ,בית שמש ,421 ,נחל רמות
2610 ,בית שמש ,438 ,נחל רפאים
2610 ,בית שמש ,422 ,נחל שורק
2610 ,בית שמש ,423 ,נחל שחם
2610 ,בית שמש ,406 ,נחל שמשון
2610 ,בית שמש ,429 ,נחל שניר
2610 ,בית שמש ,407 ,נחל תמנע
2610 ,בית שמש ,424 ,נחל תמר
2610 ,בית שמש ,516 ,נחמיה
2610 ,בית שמש ,295 ,נעמי שמר
2610 ,בית שמש ,253 ,נפתלי
2610 ,בית שמש ,447 ,נריה הנביא
2610 ,בית שמש ,196 ,נתיב מאיר
2610 ,בית שמש ,313 ,נתן הנביא
2610 ,בית שמש ,210 ,סולם יעקב
2610 ,בית שמש ,360 ,סורא
2610 ,בית שמש ,208 ,סחלב
2610 ,בית שמש ,137 ,סיני
2610 ,בית שמש ,206 ,סיתוונית
2610 ,בית שמש ,322 ,סלע
2610 ,בית שמש ,199 ,סמ בן אליעזר
2610 ,בית שמש ,181 ,סמ האלון
2610 ,בית שמש ,154 ,סמ הגפן
2610 ,בית שמש ,143 ,סמ ויצ"ו
2610 ,בית שמש ,249 ,סמ עלית הנוער
2610 ,בית שמש ,117 ,סמדר
2610 ,בית שמש ,383 ,סנש
2610 ,בית שמש ,320 ,ספיר
2610 ,בית שמש ,517 ,עזרא
2610 ,בית שמש ,519 ,עידו הנביא
2610 ,בית שמש ,250 ,עלית הנוער
2610 ,בית שמש ,114 ,עמק הזיתים
2610 ,בית שמש ,452 ,עקביא בן מהללאל
2610 ,בית שמש ,355 ,פומפדיתא
2610 ,בית שמש ,6522 ,פסגות השבע
2610 ,בית שמש ,497 ,פעמי משיח
2610 ,בית שמש ,374 ,פרנק
2610 ,בית שמש ,375 ,פרנקל
2610 ,בית שמש ,177 ,פרס ראובן
2610 ,בית שמש ,491 ,צפת
2610 ,בית שמש ,6504 ,צריפים אדומים
2610 ,בית שמש ,197 ,קארו יוסף
2610 ,בית שמש ,457 ,קדושת אהרון
2610 ,בית שמש ,218 ,קהילת יעקב
2610 ,בית שמש ,486 ,קריית ארבע
2610 ,בית שמש ,324 ,קריסטל
2610 ,בית שמש ,6518 ,קרית אריה
2610 ,בית שמש ,6521 ,קרית בן אליעזר
2610 ,בית שמש ,6532 ,קרית החינוך
2610 ,בית שמש ,6520 ,קרית הרב ניסים
2610 ,בית שמש ,6530 ,קרית הרמ"א
2610 ,בית שמש ,147 ,קרן היסוד
2610 ,בית שמש ,234 ,ראובן
2610 ,בית שמש ,357 ,רב אבדימי
2610 ,בית שמש ,332 ,רב אבהו
2610 ,בית שמש ,338 ,רב אמי
2610 ,בית שמש ,342 ,רב אמימר
2610 ,בית שמש ,339 ,רב אשי
2610 ,בית שמש ,349 ,רב המנונא
2610 ,בית שמש ,337 ,רב זביד
2610 ,בית שמש ,343 ,רב זירא
2610 ,בית שמש ,563 ,רב חלקיה בר טובי
2610 ,בית שמש ,345 ,רב חנן
2610 ,בית שמש ,565 ,רב חננאל
2610 ,בית שמש ,561 ,רב חסדא
2610 ,בית שמש ,569 ,רב ייבא סבא
2610 ,בית שמש ,353 ,רב כהנא
2610 ,בית שמש ,348 ,רב מנשיא
2610 ,בית שמש ,359 ,רב מרי
2610 ,בית שמש ,567 ,רב משרשיא
2610 ,בית שמש ,341 ,רב עולא
2610 ,בית שמש ,358 ,רב עמרם
2610 ,בית שמש ,336 ,רב פפא
2610 ,בית שמש ,346 ,רב שילא
2610 ,בית שמש ,331 ,רב ששת
2610 ,בית שמש ,335 ,רב תנחומא
2610 ,בית שמש ,344 ,רבא
2610 ,בית שמש ,465 ,רבי אלעזר
2610 ,בית שמש ,557 ,רבי ברכיה
2610 ,בית שמש ,562 ,רבי חזקיה
2610 ,בית שמש ,571 ,רבי חלבו
2610 ,בית שמש ,558 ,רבי חנינא
2610 ,בית שמש ,468 ,רבי טרפון
2610 ,בית שמש ,463 ,רבי יהודה הנשיא
2610 ,בית שמש ,462 ,רבי יהושע
2610 ,בית שמש ,451 ,רבי יוסי בן קיסמא
2610 ,בית שמש ,458 ,רבי ינאי
2610 ,בית שמש ,559 ,רבי יצחק נפחא
2610 ,בית שמש ,456 ,רבי ישמעאל
2610 ,בית שמש ,464 ,רבי לויטס
2610 ,בית שמש ,467 ,רבי מאיר בעל הנס
2610 ,בית שמש ,460 ,רבי עקיבא
2610 ,בית שמש ,454 ,רבי פנחס בן יאיר
2610 ,בית שמש ,566 ,רבי פרדא
2610 ,בית שמש ,469 ,רבי צדוק
2610 ,בית שמש ,198 ,רבי צדקה יהודה
2610 ,בית שמש ,334 ,רבינא
2610 ,בית שמש ,159 ,רביצקי
2610 ,בית שמש ,471 ,רבן יוחנן בן זכאי
2610 ,בית שמש ,217 ,רבנו חיים הלוי
2610 ,בית שמש ,283 ,רויטל
2610 ,בית שמש ,514 ,רות
2610 ,בית שמש ,258 ,רזיאל דוד
2610 ,בית שמש ,298 ,רחל המשוררת
2610 ,בית שמש ,470 ,ריב"ל
2610 ,בית שמש ,182 ,רימון
2610 ,בית שמש ,347 ,ריש לקיש
2610 ,בית שמש ,140 ,רמב"ם
2610 ,בית שמש ,6525 ,רמת בית שמש
2610 ,בית שמש ,6531 ,רמת בית שמש ב
2610 ,בית שמש ,120 ,רמת לח"י
2610 ,בית שמש ,205 ,רש"י
2610 ,בית שמש ,461 ,רשב"ג
2610 ,בית שמש ,466 ,רשב"י
2610 ,בית שמש ,6513 ,ש 160
2610 ,בית שמש ,6514 ,ש 144
2610 ,בית שמש ,6515 ,ש 116
2610 ,בית שמש ,6505 ,ש ותיקים
2610 ,בית שמש ,6506 ,ש חצרות
2610 ,בית שמש ,6508 ,ש משק עזר
2610 ,בית שמש ,6512 ,ש קוטג'
2610 ,בית שמש ,6510 ,ש רמת לח"י
2610 ,בית שמש ,512 ,שאול המלך
2610 ,בית שמש ,171 ,שביל האשל
2610 ,בית שמש ,437 ,שביל הגיחון
2610 ,בית שמש ,102 ,שביל הל"ה
2610 ,בית שמש ,446 ,שביל הנחש
2610 ,בית שמש ,156 ,שביל התמרים
2610 ,בית שמש ,200 ,שד בן גוריון
2610 ,בית שמש ,162 ,שד הדקל
2610 ,בית שמש ,201 ,שד נהר הדן
2610 ,בית שמש ,549 ,שד נחל נובע
2610 ,בית שמש ,492 ,שד נחל צאלים
2610 ,בית שמש ,333 ,שד' האמוראים
2610 ,בית שמש ,119 ,שועלי שמשון
2610 ,בית שמש ,6503 ,שכ הגפן
2610 ,בית שמש ,6529 ,שכ השחר
2610 ,בית שמש ,6524 ,שכ מחסיה
2610 ,בית שמש ,6534 ,שכ קרית חזון עובדיה
2610 ,בית שמש ,6533 ,שכ רמת התנאים
2610 ,בית שמש ,510 ,שלמה המלך
2610 ,בית שמש ,493 ,שמואל הנביא
2610 ,בית שמש ,233 ,שמעון
2610 ,בית שמש ,227 ,שער השמיים
2610 ,בית שמש ,219 ,שפת אמת
2610 ,בית שמש ,172 ,שקמה
2610 ,בית שמש ,267 ,תאשור
2610 ,בית שמש ,175 ,תדהר
2610 ,בית שמש ,107 ,תחנת הרכבת
2610 ,בית שמש ,459 ,תכלת מרדכי
2610 ,בית שמש ,352 ,תלמוד בבלי
2610 ,בית שמש ,356 ,תלמוד ירושלמי
2610 ,בית שמש ,545 ,תמר
2610 ,בית שמש ,160 ,תפארת משה
2610 ,בית שמש ,525 ,תפארת נפתלי
248 ,בית שערים ,115 ,ארגון אפרים
248 ,בית שערים ,9000 ,בית שערים
248 ,בית שערים ,116 ,דרך הנוער
248 ,בית שערים ,111 ,דרך נהלל
248 ,בית שערים ,112 ,דרך רמת ישי
248 ,בית שערים ,101 ,העמק
248 ,בית שערים ,104 ,העץ הגדול
248 ,בית שערים ,103 ,הפרסה
248 ,בית שערים ,117 ,כרמל
248 ,בית שערים ,108 ,משעול האשכולית
248 ,בית שערים ,105 ,משעול הגן
248 ,בית שערים ,110 ,משעול הלימון
248 ,בית שערים ,107 ,משעול הנרקיסים
248 ,בית שערים ,113 ,משעול הפקאן
248 ,בית שערים ,106 ,משעול הרקפות
248 ,בית שערים ,109 ,משעול התפוז
248 ,בית שערים ,102 ,שביל החלב
248 ,בית שערים ,6501 ,שכ גבעת הכלניות
248 ,בית שערים ,6500 ,שכ הגורן
248 ,בית שערים ,6502 ,שכ הפרדס
747 ,בית שקמה ,9000 ,בית שקמה
252 ,ביתן אהרן ,102 ,בית יערי
252 ,ביתן אהרן ,9000 ,ביתן אהרן
252 ,ביתן אהרן ,101 ,בת חן
252 ,ביתן אהרן ,103 ,דרך האילנות
252 ,ביתן אהרן ,104 ,דרך הבאר
252 ,ביתן אהרן ,106 ,דרך המשתלה
252 ,ביתן אהרן ,108 ,האשל
252 ,ביתן אהרן ,107 ,ההדרים
252 ,ביתן אהרן ,109 ,הצבר
252 ,ביתן אהרן ,110 ,התפוז
252 ,ביתן אהרן ,6000 ,ככר המייסדים
252 ,ביתן אהרן ,111 ,סמ הבנים
252 ,ביתן אהרן ,105 ,שדרת הפיקוסים
3780 ,ביתר עילית ,117 ,אבן עזרא
3780 ,ביתר עילית ,185 ,אדמו"רי באיאן
3780 ,ביתר עילית ,222 ,אדמור"י ויז'ניץ
3780 ,ביתר עילית ,224 ,אוהב ישראל
3780 ,ביתר עילית ,210 ,אוהל שרה
3780 ,ביתר עילית ,149 ,אור אברהם
3780 ,ביתר עילית ,106 ,אור החיים
3780 ,ביתר עילית ,184 ,אורחות אהרון
3780 ,ביתר עילית ,6503 ,אזור תעשיה הערבה
3780 ,ביתר עילית ,6507 ,אזור תעשייה הערבה
3780 ,ביתר עילית ,169 ,איגרות משה
3780 ,ביתר עילית ,105 ,אלעזר המודעי
3780 ,ביתר עילית ,152 ,אם הבנים
3780 ,ביתר עילית ,137 ,אמרי סופר
3780 ,ביתר עילית ,158 ,אש קודש
3780 ,ביתר עילית ,193 ,באבא אלעזר
3780 ,ביתר עילית ,148 ,באר יעקב
3780 ,ביתר עילית ,9000 ,ביתר עילית
3780 ,ביתר עילית ,110 ,בן איש חי
3780 ,ביתר עילית ,202 ,בנין דוד
3780 ,ביתר עילית ,107 ,בעל התניא
3780 ,ביתר עילית ,6506 ,גבעה A
3780 ,ביתר עילית ,129 ,דברי אמונה
3780 ,ביתר עילית ,111 ,דברי חיים
3780 ,ביתר עילית ,119 ,דון יצחק אברבנאל
3780 ,ביתר עילית ,188 ,דרך בנימין הצדיק
3780 ,ביתר עילית ,229 ,דרך הרמ"ז
3780 ,ביתר עילית ,226 ,דרכי איש
3780 ,ביתר עילית ,179 ,האדמו"ר מבוסטון
3780 ,ביתר עילית ,136 ,האדמו"ר מלעלוב
3780 ,ביתר עילית ,114 ,האדמו"ר מסלונים
3780 ,ביתר עילית ,115 ,האדמו"ר מרוז'ין
3780 ,ביתר עילית ,150 ,הבבא סאלי
3780 ,ביתר עילית ,103 ,הבעל שם טוב
3780 ,ביתר עילית ,101 ,הגר"א
3780 ,ביתר עילית ,151 ,הדף היומי
3780 ,ביתר עילית ,125 ,הדר ביתר
3780 ,ביתר עילית ,227 ,החוזה מלובלין
3780 ,ביתר עילית ,109 ,החיד"א
3780 ,ביתר עילית ,126 ,הטוב והמטיב
3780 ,ביתר עילית ,192 ,המבי"ט
3780 ,ביתר עילית ,204 ,המגיד ממעזריטש
3780 ,ביתר עילית ,205 ,המהרי"ץ
3780 ,ביתר עילית ,216 ,הר"ן
3780 ,ביתר עילית ,220 ,הרב אבא שאול
3780 ,ביתר עילית ,162 ,הרב אוירבך
3780 ,ביתר עילית ,146 ,הרב אטון
3780 ,ביתר עילית ,138 ,הרב אלישיב
3780 ,ביתר עילית ,122 ,הרב אריאלי יצחק
3780 ,ביתר עילית ,201 ,הרב בנדר
3780 ,ביתר עילית ,147 ,הרב ברוידא
3780 ,ביתר עילית ,225 ,הרב ברוק בן-ציון
3780 ,ביתר עילית ,203 ,הרב ברים
3780 ,ביתר עילית ,187 ,הרב כדורי
3780 ,ביתר עילית ,218 ,הרב מוצפי יעקב
3780 ,ביתר עילית ,190 ,הרב מסלנט
3780 ,ביתר עילית ,180 ,הרב נאה חיים
3780 ,ביתר עילית ,182 ,הרב עטיה
3780 ,ביתר עילית ,121 ,הרב צדקא יהודה
3780 ,ביתר עילית ,159 ,הרב קנייבסקי
3780 ,ביתר עילית ,172 ,הרב קעניג
3780 ,ביתר עילית ,186 ,הרב רוזובסקי
3780 ,ביתר עילית ,217 ,הרב רייטפארט יצחק
3780 ,ביתר עילית ,171 ,הרב רפול
3780 ,ביתר עילית ,174 ,הרב שבדרון שלום
3780 ,ביתר עילית ,223 ,הרב שך
3780 ,ביתר עילית ,157 ,הרבי מזוועהיל
3780 ,ביתר עילית ,161 ,הרבי מליובאוויטש
3780 ,ביתר עילית ,160 ,הרבי מנדבורנה
3780 ,ביתר עילית ,164 ,הרבי מקרעטשניף
3780 ,ביתר עילית ,191 ,הרמ"ק
3780 ,ביתר עילית ,118 ,הרמב"ן
3780 ,ביתר עילית ,116 ,התעוררות תשובה
3780 ,ביתר עילית ,206 ,חב"ד
3780 ,ביתר עילית ,128 ,חושן משפט
3780 ,ביתר עילית ,120 ,חזון איש
3780 ,ביתר עילית ,207 ,חיי יצחק
3780 ,ביתר עילית ,196 ,חכמת אליעזר
3780 ,ביתר עילית ,189 ,חכמת חיים
3780 ,ביתר עילית ,168 ,חלקת יואב
3780 ,ביתר עילית ,166 ,חפץ חיים
3780 ,ביתר עילית ,104 ,חת"ם סופר
3780 ,ביתר עילית ,199 ,טוב דעת
3780 ,ביתר עילית ,139 ,יביע אומר
3780 ,ביתר עילית ,123 ,יוחנן בן זכאי
3780 ,ביתר עילית ,127 ,יורה דעה
3780 ,ביתר עילית ,209 ,יסוד העבודה
3780 ,ביתר עילית ,181 ,ישועות משה
3780 ,ביתר עילית ,144 ,ישיבת מיר
3780 ,ביתר עילית ,228 ,ישמח ישראל
3780 ,ביתר עילית ,221 ,כנסת יחזקאל
3780 ,ביתר עילית ,219 ,כנסת מרדכי
3780 ,ביתר עילית ,170 ,כף החיים
3780 ,ביתר עילית ,142 ,מאור עיניים
3780 ,ביתר עילית ,153 ,מבוא מרגלית
3780 ,ביתר עילית ,200 ,מעלות בת שבע
3780 ,ביתר עילית ,178 ,מעלות גאוני בריסק
3780 ,ביתר עילית ,135 ,מעלות דמשק אליעזר
3780 ,ביתר עילית ,231 ,מעלות הרב ליפקוביץ'
3780 ,ביתר עילית ,176 ,מעלות הרב מטשכנוב
3780 ,ביתר עילית ,134 ,מעלות הרב צדוק
3780 ,ביתר עילית ,113 ,מעלות הרי יהודה
3780 ,ביתר עילית ,234 ,מעלות חיה מושקא
3780 ,ביתר עילית ,131 ,מעלות חקר ועיון
3780 ,ביתר עילית ,233 ,מעלות יעל
3780 ,ביתר עילית ,124 ,מעלות ירושלים
3780 ,ביתר עילית ,132 ,מעלות כתר תורה
3780 ,ביתר עילית ,235 ,מעלות מנחת יהודה
3780 ,ביתר עילית ,198 ,מעלות פאר הריב"ש
3780 ,ביתר עילית ,232 ,מעלות רבי אהרון
3780 ,ביתר עילית ,175 ,מעלות רבי הלל
3780 ,ביתר עילית ,133 ,מעלות שושנה
3780 ,ביתר עילית ,155 ,מפעל הש"ס
3780 ,ביתר עילית ,167 ,נעם אלימלך
3780 ,ביתר עילית ,143 ,נתיבות שלום
3780 ,ביתר עילית ,145 ,עקבי אבירים
3780 ,ביתר עילית ,211 ,פחד יצחק
3780 ,ביתר עילית ,212 ,פנים מאירים
3780 ,ביתר עילית ,173 ,צמח צדק
3780 ,ביתר עילית ,237 ,צפנת פענח
3780 ,ביתר עילית ,108 ,קארו יוסף
3780 ,ביתר עילית ,213 ,קדושת לוי
3780 ,ביתר עילית ,230 ,קדושת ציון
3780 ,ביתר עילית ,154 ,רבי נחמן מברסלב
3780 ,ביתר עילית ,102 ,רבי עקיבא
3780 ,ביתר עילית ,112 ,רבי שבזי שלום
3780 ,ביתר עילית ,140 ,שבט הלוי
3780 ,ביתר עילית ,130 ,שד דרך האר"י
3780 ,ביתר עילית ,165 ,שדה חמד
3780 ,ביתר עילית ,6501 ,שכ האתרוג
3780 ,ביתר עילית ,6500 ,שכ הגפן
3780 ,ביתר עילית ,6504 ,שכ הדס
3780 ,ביתר עילית ,6502 ,שכ הדקל
3780 ,ביתר עילית ,6505 ,שכ התמר
3780 ,ביתר עילית ,214 ,שלום רב
3780 ,ביתר עילית ,197 ,שמן למאור
3780 ,ביתר עילית ,195 ,שער לוי
3780 ,ביתר עילית ,141 ,שפע חיים
3780 ,ביתר עילית ,215 ,תומכי תמימים
94 ,בלפוריה ,101 ,בית ראשון במולדת
94 ,בלפוריה ,9000 ,בלפוריה
94 ,בלפוריה ,102 ,הגפן
94 ,בלפוריה ,106 ,הזית
94 ,בלפוריה ,109 ,החיטה
94 ,בלפוריה ,107 ,הרימון
94 ,בלפוריה ,105 ,השעורה
94 ,בלפוריה ,103 ,התאנה
94 ,בלפוריה ,104 ,התמר
94 ,בלפוריה ,108 ,שבעת המינים
760 ,בן זכאי ,9000 ,בן זכאי
760 ,בן זכאי ,101 ,האורן
760 ,בן זכאי ,107 ,האלה
760 ,בן זכאי ,110 ,האתרוג
760 ,בן זכאי ,112 ,הברוש
760 ,בן זכאי ,108 ,הגפן
760 ,בן זכאי ,109 ,ההדס
760 ,בן זכאי ,103 ,הזית
760 ,בן זכאי ,105 ,החרוב
760 ,בן זכאי ,106 ,הצאלון
760 ,בן זכאי ,113 ,הרותם
760 ,בן זכאי ,111 ,הרימון
760 ,בן זכאי ,102 ,התאנה
760 ,בן זכאי ,104 ,התמר
712 ,בן עמי ,9000 ,בן עמי
712 ,בן עמי ,103 ,דרך פרחי הבר
712 ,בן עמי ,105 ,שביל החרציות
712 ,בן עמי ,102 ,שביל הנרקיסים
712 ,בן עמי ,101 ,שביל הסביונים
712 ,בן עמי ,104 ,שביל הרקפות
1084 ,בן שמן (כפר נוער) ,9000 ,בן שמן (כפר נוער)
2013 ,בן שמן (מושב) ,9000 ,בן שמן (מושב)
2013 ,בן שמן (מושב) ,101 ,דרך הגפן
2013 ,בן שמן (מושב) ,102 ,דרך הזית
2013 ,בן שמן (מושב) ,103 ,דרך הרימון
2013 ,בן שמן (מושב) ,104 ,דרך התמר
2013 ,בן שמן (מושב) ,105 ,החיטה
2013 ,בן שמן (מושב) ,106 ,השעורה
2013 ,בן שמן (מושב) ,107 ,התאנה
6100 ,בני ברק ,304 ,אבו חצירא ישראל
6100 ,בני ברק ,522 ,אבטליון
6100 ,בני ברק ,231 ,אביעד
6100 ,בני ברק ,507 ,אבן גבירול
6100 ,בני ברק ,531 ,אבן עזרא
6100 ,בני ברק ,530 ,אבן שפרוט
6100 ,בני ברק ,731 ,אבני נזר
6100 ,בני ברק ,201 ,אברבנאל
6100 ,בני ברק ,325 ,אדמורי אלכסנדר
6100 ,בני ברק ,815 ,אדמורי שאץ
6100 ,בני ברק ,817 ,אהבת שלום
6100 ,בני ברק ,835 ,אהרונוביץ ראובן
6100 ,בני ברק ,925 ,אהרונסון
6100 ,בני ברק ,729 ,אונקלוס
6100 ,בני ברק ,217 ,אוסישקין
6100 ,בני ברק ,904 ,אור החיים
6100 ,בני ברק ,805 ,אורליאן
6100 ,בני ברק ,6531 ,אזור תעשיה צפון
6100 ,בני ברק ,403 ,אחיה השילוני
6100 ,בני ברק ,515 ,אחיעזר
6100 ,בני ברק ,526 ,אלחריזי
6100 ,בני ברק ,843 ,אליהו הנביא
6100 ,בני ברק ,845 ,אלישע
6100 ,בני ברק ,721 ,אלקבץ
6100 ,בני ברק ,715 ,אלקלעי
6100 ,בני ברק ,887 ,אלרום
6100 ,בני ברק ,937 ,אלשיך
6100 ,בני ברק ,877 ,אמרי ברוך
6100 ,בני ברק ,816 ,אמרי חיים
6100 ,בני ברק ,934 ,אנילביץ
6100 ,בני ברק ,927 ,אסתר המלכה
6100 ,בני ברק ,422 ,אפשטיין פנחס
6100 ,בני ברק ,328 ,אצ"ל
6100 ,בני ברק ,724 ,אשל אברהם
6100 ,בני ברק ,893 ,באר מים חיים
6100 ,בני ברק ,949 ,בארי
6100 ,בני ברק ,310 ,בגין מנחם
6100 ,בני ברק ,973 ,בגנו משה
6100 ,בני ברק ,219 ,בורוכוב
6100 ,בני ברק ,741 ,בי"ח מעיני הישועה
6100 ,בני ברק ,222 ,ביאליק
6100 ,בני ברק ,601 ,ביל"ו
6100 ,בני ברק ,876 ,ביריה
6100 ,בני ברק ,801 ,בירנבוים
6100 ,בני ברק ,831 ,בית הלל
6100 ,בני ברק ,110 ,בית חורון
6100 ,בני ברק ,703 ,בית יוסף
6100 ,בני ברק ,889 ,בית שמאי
6100 ,בני ברק ,401 ,בן גוריון דוד
6100 ,בני ברק ,814 ,בן דוד אברהם
6100 ,בני ברק ,722 ,בן זומא
6100 ,בני ברק ,918 ,בן זכאי
6100 ,בני ברק ,312 ,בן יוסף שלמה
6100 ,בני ברק ,107 ,בן נון
6100 ,בני ברק ,981 ,בן נריה ברוך
6100 ,בני ברק ,440 ,בן סירא
6100 ,בני ברק ,732 ,בן עזאי
6100 ,בני ברק ,505 ,בן פתחיה
6100 ,בני ברק ,935 ,בן שטח
6100 ,בני ברק ,9000 ,בני ברק
6100 ,בני ברק ,215 ,בנימין אברהם
6100 ,בני ברק ,900 ,בעל התניא
6100 ,בני ברק ,901 ,בעל שם טוב
6100 ,בני ברק ,706 ,בר אילן
6100 ,בני ברק ,836 ,בר יוחאי
6100 ,בני ברק ,101 ,בר כוכבא
6100 ,בני ברק ,710 ,ברויאר
6100 ,בני ברק ,717 ,ברוריה
6100 ,בני ברק ,913 ,ברטנורה
6100 ,בני ברק ,874 ,ברנדשטטר
6100 ,בני ברק ,863 ,בתי אבות
6100 ,בני ברק ,442 ,בתי גולדשטין
6100 ,בני ברק ,970 ,גאוני בריסק
6100 ,בני ברק ,727 ,גבורי ישראל
6100 ,בני ברק ,6528 ,גבעת סוקולוב
6100 ,בני ברק ,871 ,גבעת פנחס
6100 ,בני ברק ,6508 ,גבעת רוקח
6100 ,בני ברק ,718 ,גוטמכר
6100 ,בני ברק ,955 ,גולומב
6100 ,בני ברק ,946 ,גורדון
6100 ,בני ברק ,525 ,גן העצמאות
6100 ,בני ברק ,240 ,גניחובסקי
6100 ,בני ברק ,305 ,גרונר
6100 ,בני ברק ,232 ,גרינברג
6100 ,בני ברק ,921 ,גרשטנקורן
6100 ,בני ברק ,302 ,ד"ר ברט
6100 ,בני ברק ,834 ,דבורה הנביאה
6100 ,בני ברק ,415 ,דוד המלך
6100 ,בני ברק ,867 ,דון יוסף הנשיא
6100 ,בני ברק ,624 ,דונולו
6100 ,בני ברק ,872 ,דמשק אליעזר
6100 ,בני ברק ,329 ,דנגור
6100 ,בני ברק ,926 ,דניאל
6100 ,בני ברק ,539 ,דקר
6100 ,בני ברק ,115 ,דרך אם המושבות
6100 ,בני ברק ,739 ,דרך הנשיא הראשון
6100 ,בני ברק ,917 ,האדמו"ר מבלז
6100 ,בני ברק ,846 ,האדמו"ר מגור
6100 ,בני ברק ,878 ,האדמו"ר מויז'ניץ
6100 ,בני ברק ,890 ,האדמו"ר מוישוי
6100 ,בני ברק ,822 ,האדמו"ר מצנז
6100 ,בני ברק ,809 ,האדמו"ר מקוצק
6100 ,בני ברק ,847 ,האדמו"ר מראדזמין
6100 ,בני ברק ,418 ,האדמו"ר מרוז'ין
6100 ,בני ברק ,818 ,האדמור מנדבורנא
6100 ,בני ברק ,625 ,האדמורי"ם מספינקא
6100 ,בני ברק ,423 ,הבנים
6100 ,בני ברק ,208 ,הגליל
6100 ,בני ברק ,428 ,הגר"א
6100 ,בני ברק ,225 ,הדסים
6100 ,בני ברק ,226 ,ההגנה
6100 ,בני ברק ,221 ,הוז דב
6100 ,בני ברק ,612 ,הושע
6100 ,בני ברק ,207 ,החלוצים
6100 ,בני ברק ,828 ,החרמון
6100 ,בני ברק ,313 ,החשמונאים
6100 ,בני ברק ,881 ,הילדסהיימר
6100 ,בני ברק ,616 ,הירדן
6100 ,בני ברק ,203 ,הירקון
6100 ,בני ברק ,103 ,הירש ברוך
6100 ,בני ברק ,513 ,הלוחמים
6100 ,בני ברק ,915 ,הלפרין
6100 ,בני ברק ,924 ,המהר"ל
6100 ,בני ברק ,309 ,המכבים
6100 ,בני ברק ,707 ,המר זבולון
6100 ,בני ברק ,210 ,הנגב
6100 ,בני ברק ,605 ,העליה השניה
6100 ,בני ברק ,716 ,הפלוגה הדתית
6100 ,בני ברק ,216 ,הפלמ"ח
6100 ,בני ברק ,884 ,הצנחנים
6100 ,בני ברק ,104 ,הקישון
6100 ,בני ברק ,524 ,הר הבית
6100 ,בני ברק ,529 ,הר נבו
6100 ,בני ברק ,812 ,הר סיני
6100 ,בני ברק ,501 ,הראשונים
6100 ,בני ברק ,248 ,הרב אברמסקי
6100 ,בני ברק ,984 ,הרב בארון קלמן
6100 ,בני ברק ,520 ,הרב בלוי
6100 ,בני ברק ,870 ,הרב בן יעקב
6100 ,בני ברק ,324 ,הרב ברוט
6100 ,בני ברק ,956 ,הרב גולד
6100 ,בני ברק ,959 ,הרב גרוסברד
6100 ,בני ברק ,911 ,הרב דסלר
6100 ,בני ברק ,939 ,הרב הירש
6100 ,בני ברק ,940 ,הרב וסרמן אלחנן
6100 ,בני ברק ,875 ,הרב ורנר
6100 ,בני ברק ,995 ,הרב זוננפלד יוסף
6100 ,בני ברק ,446 ,הרב זיטניצקי
6100 ,בני ברק ,996 ,הרב זמבא מנחם
6100 ,בני ברק ,213 ,הרב חדד שאול
6100 ,בני ברק ,898 ,הרב טולידנו
6100 ,בני ברק ,908 ,הרב יברוב משה
6100 ,בני ברק ,326 ,הרב יוסף חיים
6100 ,בני ברק ,997 ,הרב כהן שלמה
6100 ,בני ברק ,891 ,הרב כהנמן
6100 ,בני ברק ,957 ,הרב לנדא יעקב
6100 ,בני ברק ,888 ,הרב מיודסר
6100 ,בני ברק ,514 ,הרב מימון
6100 ,בני ברק ,842 ,הרב מלצר
6100 ,בני ברק ,250 ,הרב מנצור בן שמעון
6100 ,בני ברק ,245 ,הרב מצליח
6100 ,בני ברק ,713 ,הרב משה זאב פלדמן
6100 ,בני ברק ,807 ,הרב נסים יצחק
6100 ,בני ברק ,896 ,הרב סורוצקין
6100 ,בני ברק ,808 ,הרב עוזיאל
6100 ,בני ברק ,998 ,הרב עטיה עזרה
6100 ,בני ברק ,975 ,הרב פוברסקי
6100 ,בני ברק ,734 ,הרב פרדו משה
6100 ,בני ברק ,327 ,הרב פתאיה
6100 ,בני ברק ,519 ,הרב קוטלר
6100 ,בני ברק ,408 ,הרב קוק
6100 ,בני ברק ,516 ,הרב קליש
6100 ,בני ברק ,234 ,הרב ראט משולם
6100 ,בני ברק ,430 ,הרב רבינוב
6100 ,בני ברק ,810 ,הרב רבינוביץ אריה
6100 ,בני ברק ,855 ,הרב רוזובסקי
6100 ,בני ברק ,540 ,הרב שטרסר
6100 ,בני ברק ,228 ,הרב שך
6100 ,בני ברק ,964 ,הרב שלום שלום
6100 ,בני ברק ,417 ,הרב שמידמן יצחק
6100 ,בני ברק ,332 ,הרב שמש דוד
6100 ,בני ברק ,711 ,הרב שפירא
6100 ,בני ברק ,533 ,הרב שר
6100 ,בני ברק ,237 ,הרי גולן
6100 ,בני ברק ,445 ,הרצוג
6100 ,בני ברק ,224 ,הרצל
6100 ,בני ברק ,837 ,השומר
6100 ,בני ברק ,606 ,השלושה
6100 ,בני ברק ,218 ,השניים
6100 ,בני ברק ,249 ,השר אלון יגאל
6100 ,בני ברק ,883 ,השריונאים
6100 ,בני ברק ,444 ,התנאים
6100 ,בני ברק ,542 ,וגמן מ ב
6100 ,בני ברק ,922 ,וולפסון
6100 ,בני ברק ,912 ,וילקומירר
6100 ,בני ברק ,920 ,וינברג שמואל
6100 ,בני ברק ,243 ,וינרב
6100 ,בני ברק ,704 ,ועד ארבע ארצות
6100 ,בני ברק ,503 ,ז'בוטינסקי
6100 ,בני ברק ,607 ,זכריה
6100 ,בני ברק ,314 ,זרובבל
6100 ,בני ברק ,622 ,חבקוק
6100 ,בני ברק ,406 ,חברון
6100 ,בני ברק ,611 ,חגי
6100 ,בני ברק ,945 ,חולדה הנביאה
6100 ,בני ברק ,830 ,חזון איש
6100 ,בני ברק ,204 ,חי טייב
6100 ,בני ברק ,619 ,חיד"א
6100 ,בני ברק ,886 ,חידושי הרי"ם
6100 ,בני ברק ,602 ,חנקין
6100 ,בני ברק ,910 ,חפץ חיים
6100 ,בני ברק ,412 ,חרל"פ
6100 ,בני ברק ,610 ,חשב סופר
6100 ,בני ברק ,509 ,חת"ם סופר
6100 ,בני ברק ,405 ,טבריה
6100 ,בני ברק ,879 ,טכורש
6100 ,בני ברק ,873 ,טל חיים
6100 ,בני ברק ,220 ,טרומפלדור
6100 ,בני ברק ,425 ,יגאל
6100 ,בני ברק ,517 ,יהודה הלוי
6100 ,בני ברק ,933 ,יהודה הנשיא
6100 ,בני ברק ,953 ,יהודית
6100 ,בני ברק ,420 ,יהושע
6100 ,בני ברק ,620 ,יואל
6100 ,בני ברק ,804 ,יוחנן הסנדלר
6100 ,בני ברק ,844 ,יונה הנביא
6100 ,בני ברק ,311 ,יונתן
6100 ,בני ברק ,433 ,יחזקאל
6100 ,בני ברק ,714 ,יעבץ
6100 ,בני ברק ,947 ,יעל
6100 ,בני ברק ,961 ,יפה
6100 ,בני ברק ,897 ,יצחק מאיר הכהן
6100 ,בני ברק ,626 ,יצחק נפחא
6100 ,בני ברק ,414 ,ירושלים
6100 ,בני ברק ,432 ,ירמיהו
6100 ,בני ברק ,866 ,ישיבת בית מאיר
6100 ,בני ברק ,895 ,ישיבת ויז'ניץ
6100 ,בני ברק ,829 ,ישמח משה
6100 ,בני ברק ,435 ,ישעיהו
6100 ,בני ברק ,510 ,ישראל מסלנט
6100 ,בני ברק ,6001 ,ככר האחד עשר
6100 ,בני ברק ,6002 ,ככר האחים מאיר
6100 ,בני ברק ,6003 ,ככר התעשיה
6100 ,בני ברק ,106 ,כנרת
6100 ,בני ברק ,702 ,לוי יצחק
6100 ,בני ברק ,708 ,לוין מרישא
6100 ,בני ברק ,109 ,לח"י
6100 ,בני ברק ,333 ,ליפשיץ
6100 ,בני ברק ,212 ,לנדאו חיים
6100 ,בני ברק ,301 ,מבצע קדש
6100 ,בני ברק ,906 ,מהרש"ל
6100 ,בני ברק ,930 ,מוהליבר
6100 ,בני ברק ,824 ,מחזיקי הדת
6100 ,בני ברק ,102 ,מחנה דב
6100 ,בני ברק ,613 ,מיכה
6100 ,בני ברק ,538 ,מינץ
6100 ,בני ברק ,411 ,מירון
6100 ,בני ברק ,303 ,מכלוף דוד
6100 ,בני ברק ,841 ,מלאכי
6100 ,בני ברק ,227 ,מלבן
6100 ,בני ברק ,409 ,מנחם
6100 ,בני ברק ,938 ,מעיינה של תורה
6100 ,בני ברק ,506 ,מעלות הדף היומי
6100 ,בני ברק ,965 ,מעלות זלמן מילשטיין
6100 ,בני ברק ,543 ,מעלות יהודית וויל
6100 ,בני ברק ,982 ,מעלות מאיר
6100 ,בני ברק ,989 ,מעלות מרגלית
6100 ,בני ברק ,629 ,מפרץ שלמה
6100 ,בני ברק ,105 ,מצדה
6100 ,בני ברק ,944 ,מרים הנביאה
6100 ,בני ברק ,6532 ,מרכז בעלי מלאכה
6100 ,בני ברק ,628 ,משה אריה
6100 ,בני ברק ,441 ,משפחת מקובר
6100 ,בני ברק ,6534 ,מתחם הרכבת
6100 ,בני ברק ,827 ,מתתיהו
6100 ,בני ברק ,239 ,נויפלד
6100 ,בני ברק ,733 ,נועם אלימלך
6100 ,בני ברק ,223 ,נורדאו
6100 ,בני ברק ,233 ,נורוק
6100 ,בני ברק ,840 ,נחום
6100 ,בני ברק ,803 ,נחל שורק
6100 ,בני ברק ,833 ,נחמיה
6100 ,בני ברק ,869 ,נחמן מברסלב
6100 ,בני ברק ,802 ,נחשוני יהודה
6100 ,בני ברק ,230 ,נידוני בגדד
6100 ,בני ברק ,108 ,ניל"י
6100 ,בני ברק ,942 ,ניסנבוים
6100 ,בני ברק ,402 ,נתן הנביא
6100 ,בני ברק ,331 ,סומך
6100 ,בני ברק ,518 ,סוקולוב
6100 ,בני ברק ,894 ,סירוקה שמעון
6100 ,בני ברק ,909 ,סמ אפרים
6100 ,בני ברק ,523 ,סמ במברגר
6100 ,בני ברק ,929 ,סמ גוטליב
6100 ,בני ברק ,436 ,סמ הבנים
6100 ,בני ברק ,725 ,סמ החייל
6100 ,בני ברק ,919 ,סמ יבנה
6100 ,בני ברק ,960 ,סמ מנשה
6100 ,בני ברק ,862 ,סמ משה יוסף
6100 ,בני ברק ,603 ,סמ סירקין
6100 ,בני ברק ,421 ,סמ רחל
6100 ,בני ברק ,604 ,סמטת אז"ר
6100 ,בני ברק ,916 ,סמטת האר"י
6100 ,בני ברק ,954 ,סנש
6100 ,בני ברק ,936 ,סעדיה גאון
6100 ,בני ברק ,330 ,סרור דנה
6100 ,בני ברק ,971 ,עבודת ישראל
6100 ,בני ברק ,931 ,עובדיה
6100 ,בני ברק ,825 ,עזרא
6100 ,בני ברק ,404 ,עלי הכהן
6100 ,בני ברק ,614 ,עמוס
6100 ,בני ברק ,407 ,עמי
6100 ,בני ברק ,932 ,עמיאל
6100 ,בני ברק ,202 ,עמק יזרעאל
6100 ,בני ברק ,851 ,עמרם גאון
6100 ,בני ברק ,251 ,פדרמן דוזיה
6100 ,בני ברק ,413 ,פוקס אהרון דב
6100 ,בני ברק ,112 ,פורצי כלא עכו
6100 ,בני ברק ,928 ,פנקס
6100 ,בני ברק ,6502 ,פרדס כץ
6100 ,בני ברק ,504 ,פרל חיים
6100 ,בני ברק ,244 ,צבי
6100 ,בני ברק ,618 ,צייטלין
6100 ,בני ברק ,701 ,צירלסון
6100 ,בני ברק ,820 ,צמח צדיק
6100 ,בני ברק ,615 ,צפניה
6100 ,בני ברק ,410 ,צפת
6100 ,בני ברק ,813 ,קבוץ גלויות
6100 ,בני ברק ,736 ,קדושי גורליץ
6100 ,בני ברק ,737 ,קדושי לוב
6100 ,בני ברק ,892 ,קדושת יום טוב
6100 ,בני ברק ,806 ,קהילות יעקב
6100 ,בני ברק ,241 ,קובלסקי
6100 ,בני ברק ,811 ,קושניר
6100 ,בני ברק ,705 ,קלישר
6100 ,בני ברק ,962 ,קפלנסקי
6100 ,בני ברק ,6500 ,קרית הרצוג
6100 ,בני ברק ,6515 ,קרית יואל
6100 ,בני ברק ,6512 ,קרית נדבורנא
6100 ,בני ברק ,907 ,ראב"ד
6100 ,בני ברק ,885 ,רב אמי
6100 ,בני ברק ,848 ,רב אסי
6100 ,בני ברק ,821 ,רב אשי
6100 ,בני ברק ,853 ,רב האי גאון
6100 ,בני ברק ,958 ,רבי אבא
6100 ,בני ברק ,416 ,רבי אליעזר
6100 ,בני ברק ,720 ,רבי חייא
6100 ,בני ברק ,431 ,רבי יוסי
6100 ,בני ברק ,838 ,רבי ישמעאל
6100 ,בני ברק ,728 ,רבי מאיר
6100 ,בני ברק ,502 ,רבי עקיבא
6100 ,בני ברק ,826 ,רבינא
6100 ,בני ברק ,726 ,רבן גמליאל
6100 ,בני ברק ,511 ,רבנו אשר
6100 ,בני ברק ,608 ,רבנו בחיי
6100 ,בני ברק ,903 ,רבנו תם
6100 ,בני ברק ,609 ,רד"ק
6100 ,בני ברק ,882 ,רוזנהיים
6100 ,בני ברק ,308 ,רזיאל
6100 ,בני ברק ,943 ,רח 282
6100 ,בני ברק ,740 ,רח 333
6100 ,בני ברק ,508 ,רח 293
6100 ,בני ברק ,832 ,רח 294
6100 ,בני ברק ,738 ,רח 335
6100 ,בני ברק ,868 ,רח 435
6100 ,בני ברק ,849 ,רח 295
6100 ,בני ברק ,735 ,רח 338
6100 ,בני ברק ,315 ,רח 288
6100 ,בני ברק ,899 ,רח 339
6100 ,בני ברק ,541 ,רחביה
6100 ,בני ברק ,247 ,רחבת קלמר
6100 ,בני ברק ,627 ,רייך אריה
6100 ,בני ברק ,712 ,ריינס
6100 ,בני ברק ,438 ,רימון
6100 ,בני ברק ,963 ,רלב"ג
6100 ,בני ברק ,528 ,רמב"ם
6100 ,בני ברק ,823 ,רמב"ן
6100 ,בני ברק ,426 ,רמח"ל
6100 ,בני ברק ,6520 ,רמת אהרון
6100 ,בני ברק ,6521 ,רמת אלחנן
6100 ,בני ברק ,6533 ,רמת דוד
6100 ,בני ברק ,902 ,רש"י
6100 ,בני ברק ,839 ,רשב"ם
6100 ,בני ברק ,6517 ,ש אגי
6100 ,בני ברק ,6503 ,ש גורדון
6100 ,בני ברק ,6523 ,ש גני גד
6100 ,בני ברק ,6506 ,ש הפומז
6100 ,בני ברק ,6525 ,ש חזון איש
6100 ,בני ברק ,6526 ,ש חסכון
6100 ,בני ברק ,6509 ,ש טישלר
6100 ,בני ברק ,6504 ,ש לומיר
6100 ,בני ברק ,6510 ,ש משכנות
6100 ,בני ברק ,6518 ,ש נוה אחיעזר
6100 ,בני ברק ,6511 ,ש סגל
6100 ,בני ברק ,6535 ,ש עובדים
6100 ,בני ברק ,6536 ,ש עממי
6100 ,בני ברק ,6516 ,ש פוניבז'
6100 ,בני ברק ,6519 ,ש צהל
6100 ,בני ברק ,6507 ,ש ק הישיבה
6100 ,בני ברק ,6505 ,ש רוגוזין
6100 ,בני ברק ,6501 ,ש תל גבורים
6100 ,בני ברק ,854 ,שאול המלך
6100 ,בני ברק ,532 ,שבזי
6100 ,בני ברק ,527 ,שבטי ישראל
6100 ,בני ברק ,941 ,שד אביי ורבא
6100 ,בני ברק ,211 ,שד אינשטיין
6100 ,בני ברק ,880 ,שד האדמו"ר מטאלנא
6100 ,בני ברק ,948 ,שד הנצי"ב
6100 ,בני ברק ,242 ,שד הרב לוין אריה
6100 ,בני ברק ,923 ,שד כפר עציון
6100 ,בני ברק ,427 ,שד רבי טרפון
6100 ,בני ברק ,951 ,שד רמז
6100 ,בני ברק ,429 ,שד"ל
6100 ,בני ברק ,950 ,שדה יצחק
6100 ,בני ברק ,709 ,שח"ל
6100 ,בני ברק ,6537 ,שכ אבו לבן
6100 ,בני ברק ,6540 ,שכ ה
6100 ,בני ברק ,6539 ,שכ הפועל המזרחי א'
6100 ,בני ברק ,6541 ,שכ הפועל המזרחי ב'
6100 ,בני ברק ,6538 ,שכ הפועל המזרחי ג'
6100 ,בני ברק ,6529 ,שכ הר שלום
6100 ,בני ברק ,6524 ,שכ ויז'ניץ
6100 ,בני ברק ,6514 ,שכ יסודות
6100 ,בני ברק ,6522 ,שכ נווה און
6100 ,בני ברק ,6530 ,שכ פסגת רחל
6100 ,בני ברק ,6527 ,שכון ו
6100 ,בני ברק ,914 ,של"ה
6100 ,בני ברק ,236 ,שלוש השעות
6100 ,בני ברק ,850 ,שלמה המלך
6100 ,בני ברק ,434 ,שמואל הנביא
6100 ,בני ברק ,952 ,שמחוני
6100 ,בני ברק ,719 ,שמעון הצדיק
6100 ,בני ברק ,512 ,שמעיה
6100 ,בני ברק ,856 ,שמשון הגבור
6100 ,בני ברק ,111 ,שניר
6100 ,בני ברק ,905 ,שפת אמת
6100 ,בני ברק ,114 ,ששת הימים
6100 ,בני ברק ,306 ,תורה ומנוחה
6100 ,בני ברק ,819 ,תורת חיים
6100 ,בני ברק ,6513 ,תל גיבורים
6100 ,בני ברק ,113 ,תרפ"ד
1368 ,בני דקלים ,132 ,אהבת ישראל
1368 ,בני דקלים ,9000 ,בני דקלים
1368 ,בני דקלים ,134 ,גאולה וישועה
1368 ,בני דקלים ,120 ,גפן
1368 ,בני דקלים ,101 ,דרך גוש קטיף
1368 ,בני דקלים ,110 ,דרך הדרום
1368 ,בני דקלים ,102 ,האגם
1368 ,בני דקלים ,130 ,האמונה
1368 ,בני דקלים ,123 ,האשכול
1368 ,בני דקלים ,126 ,הבציר
1368 ,בני דקלים ,122 ,הזמורה
1368 ,בני דקלים ,144 ,הכלנית
1368 ,בני דקלים ,121 ,הכרם
1368 ,בני דקלים ,124 ,העינב
1368 ,בני דקלים ,143 ,הצבעוני
1368 ,בני דקלים ,142 ,הרקפת
1368 ,בני דקלים ,141 ,השקד
1368 ,בני דקלים ,125 ,התירוש
1368 ,בני דקלים ,140 ,התמר
1368 ,בני דקלים ,111 ,חיי עולם
1368 ,בני דקלים ,105 ,יפה נוף
1368 ,בני דקלים ,104 ,כיסופים
1368 ,בני דקלים ,106 ,נחלת אבות
1368 ,בני דקלים ,131 ,נצח ישראל
1368 ,בני דקלים ,107 ,סמטת הכותל
1368 ,בני דקלים ,108 ,סמטת ציון
1368 ,בני דקלים ,109 ,קריה נאמנה
1368 ,בני דקלים ,103 ,שדרות נווה דקלים
1368 ,בני דקלים ,112 ,שירת הים
1368 ,בני דקלים ,133 ,תורת חסד
592 ,בני דרום ,118 ,איתנים
592 ,בני דרום ,9000 ,בני דרום
592 ,בני דרום ,104 ,בעלי מלאכה
592 ,בני דרום ,102 ,דרך הים
592 ,בני דרום ,125 ,האורן
592 ,בני דרום ,121 ,האלה
592 ,בני דרום ,122 ,האלון
592 ,בני דרום ,136 ,האסיף
592 ,בני דרום ,132 ,הבציר
592 ,בני דרום ,129 ,הברוש
592 ,בני דרום ,134 ,הגדיד
592 ,בני דרום ,123 ,הגפן
592 ,בני דרום ,106 ,הדקליה
592 ,בני דרום ,120 ,הזית
592 ,בני דרום ,109 ,החצב
592 ,בני דרום ,135 ,החריש
592 ,בני דרום ,112 ,החרצית
592 ,בני דרום ,111 ,הכלנית
592 ,בני דרום ,131 ,המסיק
592 ,בני דרום ,110 ,הצבעוני
592 ,בני דרום ,133 ,הקטיף
592 ,בני דרום ,105 ,הראשונים
592 ,בני דרום ,130 ,הרימון
592 ,בני דרום ,137 ,השדה
592 ,בני דרום ,103 ,השחר
592 ,בני דרום ,124 ,השיטה
592 ,בני דרום ,128 ,השיקמה
592 ,בני דרום ,127 ,השקד
592 ,בני דרום ,126 ,התמר
592 ,בני דרום ,115 ,התקוה
592 ,בני דרום ,117 ,יובל הכ"ה
592 ,בני דרום ,113 ,כ"ב באדר
592 ,בני דרום ,114 ,מורשה
592 ,בני דרום ,108 ,מעלה החאן
592 ,בני דרום ,107 ,משעול התאנים
592 ,בני דרום ,119 ,נתיבות
592 ,בני דרום ,101 ,שדרת האשלים
592 ,בני דרום ,116 ,שיר המעלות
386 ,בני דרור ,102 ,אודם
386 ,בני דרור ,115 ,אלון
386 ,בני דרור ,113 ,אלמוגן
386 ,בני דרור ,106 ,ארבל
386 ,בני דרור ,9000 ,בני דרור
386 ,בני דרור ,107 ,גלבוע
386 ,בני דרור ,201 ,דרך הדורות
386 ,בני דרור ,120 ,דרך הדרור
386 ,בני דרור ,124 ,האורן
386 ,בני דרור ,119 ,האקליפטוס
386 ,בני דרור ,118 ,האשל
386 ,בני דרור ,122 ,הברוש
386 ,בני דרור ,123 ,הדס
386 ,בני דרור ,112 ,הדר
386 ,בני דרור ,103 ,הרדוף
386 ,בני דרור ,121 ,השיטה
386 ,בני דרור ,117 ,זית
386 ,בני דרור ,116 ,חרוב
386 ,בני דרור ,108 ,כרמל
386 ,בני דרור ,7001 ,מתחם קניון דרורים
386 ,בני דרור ,7002 ,קרית חינוך דרור
386 ,בני דרור ,111 ,רימון
386 ,בני דרור ,105 ,שניר
386 ,בני דרור ,114 ,תאנה
386 ,בני דרור ,101 ,תבור
386 ,בני דרור ,104 ,תמנע
4015 ,בני יהודה ,9000 ,בני יהודה
1363 ,בני נצרים ,9000 ,בני נצרים
448 ,בני עטרות ,9000 ,בני עטרות
448 ,בני עטרות ,110 ,דרך האקליפטוס
448 ,בני עטרות ,109 ,דרך הבארות
448 ,בני עטרות ,111 ,דרך הכרמים
448 ,בני עטרות ,108 ,דרך הפרחים
448 ,בני עטרות ,107 ,דרך הראשונים
448 ,בני עטרות ,101 ,הגפן
448 ,בני עטרות ,102 ,הזית
448 ,בני עטרות ,106 ,החיטה
448 ,בני עטרות ,105 ,הרימון
448 ,בני עטרות ,104 ,התאנה
448 ,בני עטרות ,103 ,התמר
1066 ,בני עי"ש ,101 ,בגין מנחם
1066 ,בני עי"ש ,9000 ,בני עי"ש
1066 ,בני עי"ש ,106 ,גבורי ישראל
1066 ,בני עי"ש ,6500 ,גני הכפר
1066 ,בני עי"ש ,6502 ,גני טל
1066 ,בני עי"ש ,127 ,דרך הנצחון
1066 ,בני עי"ש ,123 ,האורן
1066 ,בני עי"ש ,117 ,האיריסים
1066 ,בני עי"ש ,125 ,האלה
1066 ,בני עי"ש ,124 ,האלון
1066 ,בני עי"ש ,139 ,הגפן
1066 ,בני עי"ש ,136 ,הדס
1066 ,בני עי"ש ,112 ,הדקל
1066 ,בני עי"ש ,115 ,הורד
1066 ,בני עי"ש ,140 ,הזית
1066 ,בני עי"ש ,134 ,החיטה
1066 ,בני עי"ש ,114 ,החצב
1066 ,בני עי"ש ,121 ,הכהנים
1066 ,בני עי"ש ,102 ,הכלניות
1066 ,בני עי"ש ,107 ,העליה
1066 ,בני עי"ש ,126 ,הצלף
1066 ,בני עי"ש ,129 ,הרב אהרון דנין
1066 ,בני עי"ש ,118 ,הרב יחיא אלקשמה
1066 ,בני עי"ש ,122 ,הרימון
1066 ,בני עי"ש ,116 ,הרקפת
1066 ,בני עי"ש ,133 ,השעורה
1066 ,בני עי"ש ,128 ,השקד
1066 ,בני עי"ש ,135 ,השקמה
1066 ,בני עי"ש ,137 ,התאנה
1066 ,בני עי"ש ,120 ,התפוח
1066 ,בני עי"ש ,130 ,זנדני משה
1066 ,בני עי"ש ,6001 ,כיכר יחיא ענאקי
1066 ,בני עי"ש ,104 ,לילך
1066 ,בני עי"ש ,6501 ,נאות דליה
1066 ,בני עי"ש ,6504 ,נווה אורנים
1066 ,בני עי"ש ,141 ,סמ בגין
1066 ,בני עי"ש ,145 ,סמ דגן
1066 ,בני עי"ש ,131 ,סמ זנדני
1066 ,בני עי"ש ,119 ,סמ סיגלית
1066 ,בני עי"ש ,142 ,סמ קוסמת
1066 ,בני עי"ש ,144 ,סמ שיבולת
1066 ,בני עי"ש ,143 ,סמ שיפון
1066 ,בני עי"ש ,146 ,סמ תבואה
1066 ,בני עי"ש ,103 ,סמ תמר
1066 ,בני עי"ש ,147 ,סמטת תירוש
1066 ,בני עי"ש ,132 ,ערבי נחל
1066 ,בני עי"ש ,6503 ,פנינת טל
1066 ,בני עי"ש ,105 ,צאלה
1066 ,בני עי"ש ,113 ,רבין יצחק
1066 ,בני עי"ש ,138 ,תמר
418 ,בני ציון ,118 ,אגוז
418 ,בני ציון ,126 ,אנשי בראשית
418 ,בני ציון ,9000 ,בני ציון
418 ,בני ציון ,124 ,דרך השדות
418 ,בני ציון ,125 ,דרך השלולית
418 ,בני ציון ,116 ,הלימון
418 ,בני ציון ,120 ,המחרשה
418 ,בני ציון ,103 ,המנים
418 ,בני ציון ,102 ,הנחל
418 ,בני ציון ,112 ,התלתן
418 ,בני ציון ,121 ,חורשת האורנים
418 ,בני ציון ,122 ,חורשת האקליפטוס
418 ,בני ציון ,107 ,חמציץ
418 ,בני ציון ,108 ,חצב
418 ,בני ציון ,119 ,חרמש
418 ,בני ציון ,111 ,חרצית
418 ,בני ציון ,106 ,לוטוס
418 ,בני ציון ,128 ,לוטם
418 ,בני ציון ,104 ,מגל
418 ,בני ציון ,117 ,מרווה
418 ,בני ציון ,109 ,נענע
418 ,בני ציון ,114 ,סחלב
418 ,בני ציון ,105 ,סייפן
418 ,בני ציון ,115 ,פרג
418 ,בני ציון ,127 ,צאלון
418 ,בני ציון ,110 ,קורנית
418 ,בני ציון ,129 ,רימונים
418 ,בני ציון ,123 ,שביל קליפות התפוזים
418 ,בני ציון ,101 ,שיזף
418 ,בני ציון ,113 ,תורמוס
588 ,בני ראם ,101 ,אורן
588 ,בני ראם ,9000 ,בני ראם
588 ,בני ראם ,103 ,האירוס
588 ,בני ראם ,104 ,הברוש
588 ,בני ראם ,105 ,הגפן
588 ,בני ראם ,106 ,הדקל
588 ,בני ראם ,112 ,ההדס
588 ,בני ראם ,107 ,הורד
588 ,בני ראם ,108 ,הזית
588 ,בני ראם ,109 ,החרוב
588 ,בני ראם ,102 ,הטופח
588 ,בני ראם ,110 ,היסמין
588 ,בני ראם ,113 ,הכלנית
588 ,בני ראם ,111 ,הלילך
588 ,בני ראם ,117 ,הנורית
588 ,בני ראם ,118 ,הסיגלית
588 ,בני ראם ,119 ,העירית
588 ,בני ראם ,120 ,הפרג
588 ,בני ראם ,121 ,הצאלון
588 ,בני ראם ,122 ,הקורנית
588 ,בני ראם ,114 ,הרקפת
588 ,בני ראם ,123 ,השיטה
588 ,בני ראם ,116 ,כליל החורש
588 ,בני ראם ,115 ,מרווה
685 ,בניה ,9000 ,בניה
9800 ,בנימינה-גבעת עדה ,246 ,אדמונית החורש
9800 ,בנימינה-גבעת עדה ,6518 ,אזור תעשיה רכבת
9800 ,בנימינה-גבעת עדה ,6514 ,בנימינה
9800 ,בנימינה-גבעת עדה ,9000 ,בנימינה-גבעת עדה
9800 ,בנימינה-גבעת עדה ,185 ,בריכת המים
9800 ,בנימינה-גבעת עדה ,6520 ,גבעת עדה
9800 ,בנימינה-גבעת עדה ,6526 ,גבעת עדה הרחבה
9800 ,בנימינה-גבעת עדה ,6513 ,גני בנימינה
9800 ,בנימינה-גבעת עדה ,3165 ,דרך בנימינה ג"ע
9800 ,בנימינה-גבעת עדה ,3128 ,דרך הברושים ג"ע
9800 ,בנימינה-גבעת עדה ,151 ,דרך המסילה
9800 ,בנימינה-גבעת עדה ,123 ,דרך העצמאות
9800 ,בנימינה-גבעת עדה ,155 ,דרך ניל"י
9800 ,בנימינה-גבעת עדה ,183 ,דרך פיק"א
9800 ,בנימינה-גבעת עדה ,3103 ,דרך רגבים גבעת עדה
9800 ,בנימינה-גבעת עדה ,255 ,דרך תל צור
9800 ,בנימינה-גבעת עדה ,3118 ,האגוז
9800 ,בנימינה-גבעת עדה ,3117 ,האגס גבעת עדה
9800 ,בנימינה-גבעת עדה ,163 ,האגסים
9800 ,בנימינה-גבעת עדה ,178 ,האופק
9800 ,בנימינה-גבעת עדה ,198 ,האור
9800 ,בנימינה-גבעת עדה ,171 ,האורן
9800 ,בנימינה-גבעת עדה ,3132 ,האורנים גבעת עדה
9800 ,בנימינה-גבעת עדה ,147 ,האיכר
9800 ,בנימינה-גבעת עדה ,175 ,האירוס
9800 ,בנימינה-גבעת עדה ,161 ,האירית
9800 ,בנימינה-גבעת עדה ,3110 ,האלה גבעת עדה
9800 ,בנימינה-גבעת עדה ,3114 ,האלון
9800 ,בנימינה-גבעת עדה ,115 ,האמן
9800 ,בנימינה-גבעת עדה ,213 ,האסיף
9800 ,בנימינה-גבעת עדה ,218 ,האפיק
9800 ,בנימינה-גבעת עדה ,194 ,הארז
9800 ,בנימינה-גבעת עדה ,3120 ,הארזים גבעת עדה
9800 ,בנימינה-גבעת עדה ,3125 ,האשכול גבעת עדה
9800 ,בנימינה-גבעת עדה ,237 ,האשכולית
9800 ,בנימינה-גבעת עדה ,187 ,האשל
9800 ,בנימינה-גבעת עדה ,233 ,האתרוג
9800 ,בנימינה-גבעת עדה ,222 ,הבוסתן
9800 ,בנימינה-גבעת עדה ,184 ,הבושם
9800 ,בנימינה-גבעת עדה ,113 ,הבזלת
9800 ,בנימינה-גבעת עדה ,210 ,הבציר
9800 ,בנימינה-גבעת עדה ,160 ,הברוש
9800 ,בנימינה-גבעת עדה ,133 ,הגבורים
9800 ,בנימינה-גבעת עדה ,204 ,הגביע
9800 ,בנימינה-גבעת עדה ,3133 ,הגבעה גבעת עדה
9800 ,בנימינה-גבעת עדה ,211 ,הגדיש
9800 ,בנימינה-גבעת עדה ,141 ,הגדנ"ע
9800 ,בנימינה-גבעת עדה ,3129 ,הגומא גבעת עדה
9800 ,בנימינה-גבעת עדה ,121 ,הגורן
9800 ,בנימינה-גבעת עדה ,166 ,הגזית
9800 ,בנימינה-גבעת עדה ,219 ,הגיא
9800 ,בנימינה-גבעת עדה ,106 ,הגנים
9800 ,בנימינה-גבעת עדה ,3116 ,הגפן
9800 ,בנימינה-גבעת עדה ,139 ,הגפנים
9800 ,בנימינה-גבעת עדה ,128 ,הגת
9800 ,בנימינה-גבעת עדה ,149 ,הדגן
9800 ,בנימינה-גבעת עדה ,189 ,הדולב
9800 ,בנימינה-גבעת עדה ,3158 ,הדפנה גבעת עדה
9800 ,בנימינה-גבעת עדה ,3115 ,הדקל
9800 ,בנימינה-גבעת עדה ,132 ,הדקלים
9800 ,בנימינה-גבעת עדה ,3131 ,הדר גבעת עדה
9800 ,בנימינה-גבעת עדה ,202 ,הדרור
9800 ,בנימינה-גבעת עדה ,142 ,ההגנה
9800 ,בנימינה-גבעת עדה ,190 ,ההדס
9800 ,בנימינה-גבעת עדה ,224 ,ההרדופים
9800 ,בנימינה-גבעת עדה ,252 ,הושע
9800 ,בנימינה-גבעת עדה ,3112 ,הזית
9800 ,בנימינה-גבעת עדה ,135 ,החבצלת
9800 ,בנימינה-גבעת עדה ,3109 ,החוטמית
9800 ,בנימינה-גבעת עדה ,105 ,החורש
9800 ,בנימינה-גבעת עדה ,3102 ,החורשים
9800 ,בנימינה-גבעת עדה ,180 ,החיטה
9800 ,בנימינה-גבעת עדה ,3123 ,החיטים
9800 ,בנימינה-גבעת עדה ,3126 ,החלמוניות
9800 ,בנימינה-גבעת עדה ,172 ,החרוב
9800 ,בנימינה-גבעת עדה ,3127 ,החרובים
9800 ,בנימינה-גבעת עדה ,104 ,החרצית
9800 ,בנימינה-גבעת עדה ,116 ,החרש
9800 ,בנימינה-גבעת עדה ,103 ,הטחנה
9800 ,בנימינה-גבעת עדה ,220 ,היובל
9800 ,בנימינה-גבעת עדה ,186 ,היוגב
9800 ,בנימינה-גבעת עדה ,206 ,היין
9800 ,בנימינה-גבעת עדה ,144 ,היסמין
9800 ,בנימינה-גבעת עדה ,3106 ,היער גבעת עדה
9800 ,בנימינה-גבעת עדה ,154 ,היקב
9800 ,בנימינה-גבעת עדה ,3160 ,היקינתון גבעת עדה
9800 ,בנימינה-גבעת עדה ,140 ,הככר
9800 ,בנימינה-גבעת עדה ,129 ,הכלנית
9800 ,בנימינה-גבעת עדה ,173 ,הכרכום
9800 ,בנימינה-גבעת עדה ,207 ,הכרם
9800 ,בנימינה-גבעת עדה ,122 ,הכרמל
9800 ,בנימינה-גבעת עדה ,3168 ,הלבונה גבעת עדה
9800 ,בנימינה-גבעת עדה ,192 ,הלוטם
9800 ,בנימינה-גבעת עדה ,3150 ,הליבנה גבעת עדה
9800 ,בנימינה-גבעת עדה ,232 ,הלימון
9800 ,בנימינה-גבעת עדה ,208 ,המגל
9800 ,בנימינה-גבעת עדה ,209 ,המורג
9800 ,בנימינה-גבעת עדה ,150 ,המורה
9800 ,בנימינה-גבעת עדה ,126 ,המיסדים
9800 ,בנימינה-גבעת עדה ,117 ,המלאכה
9800 ,בנימינה-גבעת עדה ,221 ,המעיין
9800 ,בנימינה-גבעת עדה ,191 ,המרוה
9800 ,בנימינה-גבעת עדה ,169 ,המרחב
9800 ,בנימינה-גבעת עדה ,145 ,הנביאים
9800 ,בנימינה-גבעת עדה ,130 ,הנדיב
9800 ,בנימינה-גבעת עדה ,3164 ,הנופר גבעת עדה
9800 ,בנימינה-גבעת עדה ,136 ,הנורית
9800 ,בנימינה-גבעת עדה ,215 ,הנחל
9800 ,בנימינה-גבעת עדה ,3105 ,הניצנים
9800 ,בנימינה-גבעת עדה ,131 ,הנרקיס
9800 ,בנימינה-גבעת עדה ,102 ,הנשיא
9800 ,בנימינה-גבעת עדה ,201 ,הנשר
9800 ,בנימינה-גבעת עדה ,174 ,הסביון
9800 ,בנימינה-גבעת עדה ,3136 ,הסביון גבעת עדה
9800 ,בנימינה-גבעת עדה ,176 ,הסחלב
9800 ,בנימינה-גבעת עדה ,158 ,הסיגלית
9800 ,בנימינה-גבעת עדה ,146 ,הסמדר
9800 ,בנימינה-גבעת עדה ,167 ,הספיר
9800 ,בנימינה-גבעת עדה ,152 ,העבודה
9800 ,בנימינה-גבעת עדה ,3104 ,העומר גבעת עדה
9800 ,בנימינה-גבעת עדה ,205 ,העינב
9800 ,בנימינה-גבעת עדה ,3161 ,הערבה
9800 ,בנימינה-גבעת עדה ,216 ,הערבות
9800 ,בנימינה-גבעת עדה ,236 ,הפומלה
9800 ,בנימינה-גבעת עדה ,134 ,הפועל
9800 ,בנימינה-גבעת עדה ,217 ,הפלג
9800 ,בנימינה-גבעת עדה ,229 ,הפרדס
9800 ,בנימינה-גבעת עדה ,188 ,הצאלון
9800 ,בנימינה-גבעת עדה ,124 ,הציפורן
9800 ,בנימינה-גבעת עדה ,159 ,הקורל
9800 ,בנימינה-גבעת עדה ,226 ,הקטיף
9800 ,בנימינה-גבעת עדה ,3121 ,הקמה גבעת עדה
9800 ,בנימינה-גבעת עדה ,212 ,הקציר
9800 ,בנימינה-גבעת עדה ,3111 ,הראשונים גבעת עדה
9800 ,בנימינה-גבעת עדה ,3130 ,הרדוף
9800 ,בנימינה-גבעת עדה ,125 ,הרופא
9800 ,בנימינה-גבעת עדה ,3113 ,הרימון
9800 ,בנימינה-גבעת עדה ,101 ,הרימונים
9800 ,בנימינה-גבעת עדה ,127 ,הרקפת
9800 ,בנימינה-גבעת עדה ,181 ,השדה
9800 ,בנימינה-גבעת עדה ,138 ,השושנה
9800 ,בנימינה-גבעת עדה ,200 ,השחף
9800 ,בנימינה-גבעת עדה ,109 ,השחר
9800 ,בנימינה-גבעת עדה ,182 ,השיבולים
9800 ,בנימינה-גבעת עדה ,3153 ,השיבולת
9800 ,בנימינה-גבעת עדה ,193 ,השיזף
9800 ,בנימינה-גבעת עדה ,164 ,השיטה
9800 ,בנימינה-גבעת עדה ,112 ,השיש
9800 ,בנימינה-גבעת עדה ,110 ,השניים
9800 ,בנימינה-גבעת עדה ,3154 ,השסק גבעת עדה
9800 ,בנימינה-גבעת עדה ,196 ,השעורה
9800 ,בנימינה-גבעת עדה ,3124 ,השעורים
9800 ,בנימינה-גבעת עדה ,3119 ,השקד
9800 ,בנימינה-גבעת עדה ,203 ,השריג
9800 ,בנימינה-גבעת עדה ,165 ,התאנה
9800 ,בנימינה-גבעת עדה ,3148 ,התבואה
9800 ,בנימינה-גבעת עדה ,3162 ,התירוש
9800 ,בנימינה-גבעת עדה ,195 ,התלתן
9800 ,בנימינה-גבעת עדה ,114 ,התמסח
9800 ,בנימינה-גבעת עדה ,108 ,התמר
9800 ,בנימינה-גבעת עדה ,228 ,התפוז
9800 ,בנימינה-גבעת עדה ,3107 ,ח"י גבעת עדה
9800 ,בנימינה-גבעת עדה ,251 ,חבקוק
9800 ,בנימינה-גבעת עדה ,225 ,חגי
9800 ,בנימינה-גבעת עדה ,120 ,טיילת שוני
9800 ,בנימינה-גבעת עדה ,253 ,יואל
9800 ,בנימינה-גבעת עדה ,249 ,יונה
9800 ,בנימינה-גבעת עדה ,143 ,ימי בנימינה
9800 ,בנימינה-גבעת עדה ,6525 ,יפה נוף גבעת עדה
9800 ,בנימינה-גבעת עדה ,3169 ,כליל החורש
9800 ,בנימינה-גבעת עדה ,6517 ,כרמי בנימינה
9800 ,בנימינה-גבעת עדה ,250 ,מיכה
9800 ,בנימינה-גבעת עדה ,3108 ,מכון אור שמח ג"ע
9800 ,בנימינה-גבעת עדה ,6500 ,מע בנימינה
9800 ,בנימינה-גבעת עדה ,3156 ,מעלה הצבר גבעת עדה
9800 ,בנימינה-גבעת עדה ,7001 ,מצודת שוני
9800 ,בנימינה-גבעת עדה ,3101 ,מרכז קליטה גבעת עדה
9800 ,בנימינה-גבעת עדה ,3147 ,משעול האלומה ג"ע
9800 ,בנימינה-גבעת עדה ,3149 ,משעול החמנית ג"ע
9800 ,בנימינה-גבעת עדה ,6516 ,נחלת בנימינה
9800 ,בנימינה-גבעת עדה ,107 ,נחלת ז'בוטינסקי
9800 ,בנימינה-גבעת עדה ,156 ,סיני
9800 ,בנימינה-גבעת עדה ,3141 ,סמ האיריס גבעת עדה
9800 ,בנימינה-גבעת עדה ,3122 ,סמ הדגנים
9800 ,בנימינה-גבעת עדה ,3142 ,סמ הדגנית גבעת עדה
9800 ,בנימינה-גבעת עדה ,3134 ,סמ החבצלות
9800 ,בנימינה-גבעת עדה ,3140 ,סמ החרצית גבעת עדה
9800 ,בנימינה-גבעת עדה ,3144 ,סמ הכלניות
9800 ,בנימינה-גבעת עדה ,3163 ,סמ הכרמים
9800 ,בנימינה-גבעת עדה ,3143 ,סמ הנוריות
9800 ,בנימינה-גבעת עדה ,3145 ,סמ הנרקיסים
9800 ,בנימינה-גבעת עדה ,3159 ,סמ הסיתוונית ג"ע
9800 ,בנימינה-גבעת עדה ,3151 ,סמ העירית גבעת עדה
9800 ,בנימינה-גבעת עדה ,3152 ,סמ הפעמונית ג"ע
9800 ,בנימינה-גבעת עדה ,3137 ,סמ הצבעוני גבעת עדה
9800 ,בנימינה-גבעת עדה ,3135 ,סמ הרקפות
9800 ,בנימינה-גבעת עדה ,3139 ,סמ חצב גבעת עדה
9800 ,בנימינה-גבעת עדה ,177 ,סמטת החרוב
9800 ,בנימינה-גבעת עדה ,254 ,עמוס
9800 ,בנימינה-גבעת עדה ,162 ,עץ האגוז
9800 ,בנימינה-גבעת עדה ,170 ,עץ האלון
9800 ,בנימינה-גבעת עדה ,137 ,עץ הזית
9800 ,בנימינה-גבעת עדה ,214 ,עץ השקד
9800 ,בנימינה-גבעת עדה ,3155 ,עץ התאנה
9800 ,בנימינה-גבעת עדה ,231 ,פריחת ההדרים
9800 ,בנימינה-גבעת עדה ,3146 ,פריחת הסמדר
9800 ,בנימינה-גבעת עדה ,148 ,קרן היסוד
9800 ,בנימינה-גבעת עדה ,3167 ,רויטל גבעת עדה
9800 ,בנימינה-גבעת עדה ,157 ,רותם
9800 ,בנימינה-גבעת עדה ,6524 ,ש אילנות גבעת עדה
9800 ,בנימינה-גבעת עדה ,6501 ,ש גבעת חן
9800 ,בנימינה-גבעת עדה ,6502 ,ש חדש
9800 ,בנימינה-גבעת עדה ,6521 ,ש חדש גבעת עדה
9800 ,בנימינה-גבעת עדה ,6522 ,ש יוספטל גבעת עדה
9800 ,בנימינה-גבעת עדה ,6503 ,ש נוה עובד
9800 ,בנימינה-גבעת עדה ,6504 ,ש עובדים
9800 ,בנימינה-גבעת עדה ,6505 ,ש עמידר
9800 ,בנימינה-גבעת עדה ,6523 ,ש עמידר ג"ע
9800 ,בנימינה-גבעת עדה ,6506 ,ש עממי
9800 ,בנימינה-גבעת עדה ,247 ,שביל אל הגן
9800 ,בנימינה-גבעת עדה ,111 ,שביל החלב
9800 ,בנימינה-גבעת עדה ,245 ,שביל החלמונית
9800 ,בנימינה-גבעת עדה ,179 ,שביל הכרמלית
9800 ,בנימינה-גבעת עדה ,248 ,שביל הנוער
9800 ,בנימינה-גבעת עדה ,199 ,שביל הסתונית
9800 ,בנימינה-גבעת עדה ,197 ,שביל התורמוס
9800 ,בנימינה-גבעת עדה ,6507 ,שכ גב הפועל
9800 ,בנימינה-גבעת עדה ,6527 ,שכ הצמחונים ג"ע
9800 ,בנימינה-גבעת עדה ,6508 ,שכ הרכבת
9800 ,בנימינה-גבעת עדה ,6509 ,שכ ורבורג
9800 ,בנימינה-גבעת עדה ,6510 ,שכ ז'בוטינסקי
9800 ,בנימינה-גבעת עדה ,6515 ,שכ זרעוניה
9800 ,בנימינה-גבעת עדה ,6511 ,שכ יעקב
9800 ,בנימינה-גבעת עדה ,6512 ,שכ ישורון
9800 ,בנימינה-גבעת עדה ,153 ,ששת הימים
1326 ,בסמ"ה ,154 ,אבו בכר אלסדיק
1326 ,בסמ"ה ,120 ,אבו מדור
1326 ,בסמ"ה ,110 ,אבן אלהייתם
1326 ,בסמ"ה ,124 ,אבן אלקיים
1326 ,בסמ"ה ,198 ,אבן זיידון
1326 ,בסמ"ה ,152 ,אבן ח'לדון
1326 ,בסמ"ה ,176 ,אבן סינא
1326 ,בסמ"ה ,123 ,אבן תיימיה
1326 ,בסמ"ה ,155 ,אום אלקורא
1326 ,בסמ"ה ,116 ,אחמד שוקי
1326 ,בסמ"ה ,106 ,אל מלעב
1326 ,בסמ"ה ,160 ,אל עין
1326 ,בסמ"ה ,111 ,אל תקוא
1326 ,בסמ"ה ,137 ,אלאחוט
1326 ,בסמ"ה ,193 ,אלאנדלוס
1326 ,בסמ"ה ,197 ,אלאקסא
1326 ,בסמ"ה ,153 ,אלבוח'ארי
1326 ,בסמ"ה ,190 ,אלבטימאת
1326 ,בסמ"ה ,113 ,אלביאדר
1326 ,בסמ"ה ,168 ,אלביירוני
1326 ,בסמ"ה ,179 ,אלבסאתין
1326 ,בסמ"ה ,167 ,אלבראק
1326 ,בסמ"ה ,139 ,אלבריד
1326 ,בסמ"ה ,178 ,אלברקאן
1326 ,בסמ"ה ,115 ,אלג'אמע
1326 ,בסמ"ה ,161 ,אלג'זירה
1326 ,בסמ"ה ,185 ,אלגדראן
1326 ,בסמ"ה ,108 ,אלגולאן
1326 ,בסמ"ה ,147 ,אלגזאלי
1326 ,בסמ"ה ,175 ,אלואד
1326 ,בסמ"ה ,135 ,אלופאא
1326 ,בסמ"ה ,150 ,אלזהראא
1326 ,בסמ"ה ,203 ,אלזיתון
1326 ,בסמ"ה ,136 ,אלח'ליל
1326 ,בסמ"ה ,143 ,אלחג'אז
1326 ,בסמ"ה ,140 ,אלחף
1326 ,בסמ"ה ,142 ,אלחרייה
1326 ,בסמ"ה ,126 ,אלטוילה
1326 ,בסמ"ה ,186 ,אליאסמין
1326 ,בסמ"ה ,122 ,אלירמוק
1326 ,בסמ"ה ,117 ,אלכוארזמי
1326 ,בסמ"ה ,134 ,אלכלה
1326 ,בסמ"ה ,188 ,אלכפרין
1326 ,בסמ"ה ,157 ,אלמדינה
1326 ,בסמ"ה ,192 ,אלמותנבי
1326 ,בסמ"ה ,127 ,אלמחבה
1326 ,בסמ"ה ,164 ,אלמרוה
1326 ,בסמ"ה ,132 ,אלנג'אח
1326 ,בסמ"ה ,174 ,אלניל
1326 ,בסמ"ה ,196 ,אלסדיק
1326 ,בסמ"ה ,102 ,אלסדרי
1326 ,בסמ"ה ,304 ,אלסלאם
1326 ,בסמ"ה ,189 ,אלסלטאן
1326 ,בסמ"ה ,172 ,אלסעאדה
1326 ,בסמ"ה ,173 ,אלספאא
1326 ,בסמ"ה ,119 ,אלפאראבי
1326 ,בסמ"ה ,184 ,אלפארוק
1326 ,בסמ"ה ,156 ,אלפאתח
1326 ,בסמ"ה ,129 ,אלפרוש
1326 ,בסמ"ה ,171 ,אלקאדסיה
1326 ,בסמ"ה ,131 ,אלקאהרה
1326 ,בסמ"ה ,163 ,אלקדס
1326 ,בסמ"ה ,162 ,אלקלעה
1326 ,בסמ"ה ,170 ,אלראזי
1326 ,בסמ"ה ,166 ,אלרבידא
1326 ,בסמ"ה ,180 ,אלרוחה
1326 ,בסמ"ה ,109 ,אלרחמה
1326 ,בסמ"ה ,138 ,אלשאם
1326 ,בסמ"ה ,146 ,אלשאפעי
1326 ,בסמ"ה ,177 ,אלתות
1326 ,בסמ"ה ,112 ,אלתסאמוח
1326 ,בסמ"ה ,107 ,אם יונס
1326 ,בסמ"ה ,182 ,בגדאד
1326 ,בסמ"ה ,105 ,בלאל בן רבאח
1326 ,בסמ"ה ,9000 ,בסמ"ה
1326 ,בסמ"ה ,101 ,ברטעה
1326 ,בסמ"ה ,183 ,דהרת חמד
1326 ,בסמ"ה ,199 ,דמשק
1326 ,בסמ"ה ,151 ,ח'ור אלברזה
1326 ,בסמ"ה ,130 ,חוראא
1326 ,בסמ"ה ,114 ,חטין
1326 ,בסמ"ה ,302 ,חיפה
1326 ,בסמ"ה ,133 ,חלב
1326 ,בסמ"ה ,128 ,חסן בן ת'אבת
1326 ,בסמ"ה ,121 ,טארק בן זיאד
1326 ,בסמ"ה ,202 ,טיבה
1326 ,בסמ"ה ,303 ,יאפא
1326 ,בסמ"ה ,194 ,כלת חדאד
1326 ,בסמ"ה ,149 ,מאלק בן אנאס
1326 ,בסמ"ה ,301 ,מועאוויה
1326 ,בסמ"ה ,200 ,מכה
1326 ,בסמ"ה ,187 ,מרים בנת עמראן
1326 ,בסמ"ה ,159 ,סלאח אל דין
1326 ,בסמ"ה ,118 ,עומר אלמוכתאר
1326 ,בסמ"ה ,145 ,עומר בן אלח'טאב
1326 ,בסמ"ה ,144 ,עות'מאן בן עפאן
1326 ,בסמ"ה ,201 ,עין א-סהלה
1326 ,בסמ"ה ,165 ,עלי בן אבי טאלב
1326 ,בסמ"ה ,195 ,עליאא
1326 ,בסמ"ה ,169 ,קיסריה
1326 ,בסמ"ה ,141 ,ראס אלעין
1326 ,בסמ"ה ,148 ,רח 45
1326 ,בסמ"ה ,181 ,רח 76
1326 ,בסמ"ה ,191 ,רח 86
1326 ,בסמ"ה ,204 ,רח 97
1326 ,בסמ"ה ,125 ,תאנים
944 ,בסמת טבעון ,124 ,אבו בכר אלצדיק
944 ,בסמת טבעון ,132 ,איבן סינא
944 ,בסמת טבעון ,116 ,אל אמל
944 ,בסמת טבעון ,117 ,אל ואדי
944 ,בסמת טבעון ,133 ,אלבוסתאן
944 ,בסמת טבעון ,139 ,אלבחר
944 ,בסמת טבעון ,114 ,אלביאדר
944 ,בסמת טבעון ,119 ,אלביאן
944 ,בסמת טבעון ,102 ,אלבסאתין
944 ,בסמת טבעון ,122 ,אלג'אר
944 ,בסמת טבעון ,107 ,אלג'בל
944 ,בסמת טבעון ,105 ,אלזהור
944 ,בסמת טבעון ,106 ,אלזיתון
944 ,בסמת טבעון ,109 ,אלכרום
944 ,בסמת טבעון ,140 ,אלכרמה
944 ,בסמת טבעון ,135 ,אלנהדה
944 ,בסמת טבעון ,120 ,אלנזאז
944 ,בסמת טבעון ,112 ,אלנח'יל
944 ,בסמת טבעון ,104 ,אלנרג'ס
944 ,בסמת טבעון ,131 ,אלסנדיאן
944 ,בסמת טבעון ,115 ,אלפולאת
944 ,בסמת טבעון ,136 ,אלרביע
944 ,בסמת טבעון ,103 ,אלרומאן
944 ,בסמת טבעון ,121 ,אלרנד
944 ,בסמת טבעון ,129 ,אלשיח' נימר
944 ,בסמת טבעון ,111 ,אלתין
944 ,בסמת טבעון ,113 ,בית סומא
944 ,בסמת טבעון ,9000 ,בסמת טבעון
944 ,בסמת טבעון ,138 ,דרך יצחק רבין
944 ,בסמת טבעון ,108 ,היסמין
944 ,בסמת טבעון ,201 ,זובידאת
944 ,בסמת טבעון ,137 ,ח'אלד בן אלואליד
944 ,בסמת טבעון ,118 ,ח'ואלד
944 ,בסמת טבעון ,123 ,ח'רבה
944 ,בסמת טבעון ,110 ,חלף
944 ,בסמת טבעון ,126 ,מועד בן ג'בל
944 ,בסמת טבעון ,128 ,מחמוד אלשיך
944 ,בסמת טבעון ,101 ,משאיח סעדיה
944 ,בסמת טבעון ,125 ,עומר בן אלח'טאב
944 ,בסמת טבעון ,127 ,עלי בן אבי טאלב
944 ,בסמת טבעון ,134 ,ראס אלואדי
944 ,בסמת טבעון ,130 ,רחיים
483 ,בענה ,6742 ,אבו ח'רובה
483 ,בענה ,6781 ,אל לקאיא
483 ,בענה ,6750 ,אלח'לאל
483 ,בענה ,6743 ,אלח'נקה
483 ,בענה ,6746 ,אלכרם אלחמר
483 ,בענה ,6744 ,אלסוואעד
483 ,בענה ,6756 ,אלעריד
483 ,בענה ,6752 ,אלקלעה
483 ,בענה ,6755 ,בידר אלשוק
483 ,בענה ,9000 ,בענה
483 ,בענה ,6754 ,הכפר הישן
483 ,בענה ,6751 ,המגרשים
483 ,בענה ,6753 ,כרם אלדאר
483 ,בענה ,6740 ,כרם נחלה
483 ,בענה ,6745 ,ע'דארה
483 ,בענה ,6741 ,קטעת אלג'אמע
389 ,בצרה ,9000 ,בצרה
389 ,בצרה ,116 ,האורנים
389 ,בצרה ,101 ,הבאר
389 ,בצרה ,102 ,הגן
389 ,בצרה ,104 ,הנחלים
389 ,בצרה ,115 ,הנרקיס
389 ,בצרה ,109 ,העליה
389 ,בצרה ,103 ,הפרדס
389 ,בצרה ,107 ,הפרחים
389 ,בצרה ,106 ,הצופית
389 ,בצרה ,110 ,הראשונים
389 ,בצרה ,105 ,יונק הדבש
389 ,בצרה ,113 ,כלנית
389 ,בצרה ,108 ,מגדל המים
389 ,בצרה ,112 ,נורית
389 ,בצרה ,111 ,קבוץ גלויות
389 ,בצרה ,114 ,רקפת
589 ,בצת ,9000 ,בצת
864 ,בקוע ,9000 ,בקוע
3612 ,בקעות ,9000 ,בקעות
823 ,בר גיורא ,9000 ,בר גיורא
823 ,בר גיורא ,101 ,הגפן
823 ,בר גיורא ,102 ,הדקל
823 ,בר גיורא ,103 ,הערבה
823 ,בר גיורא ,104 ,הקטלב
823 ,בר גיורא ,105 ,הרימון
823 ,בר גיורא ,106 ,התמר
1191 ,בר יוחאי ,9000 ,בר יוחאי
3744 ,ברוכין ,9000 ,ברוכין
3744 ,ברוכין ,110 ,האדרת
3744 ,ברוכין ,109 ,ההדר
3744 ,ברוכין ,112 ,ההוד
3744 ,ברוכין ,111 ,הזוהר
3744 ,ברוכין ,104 ,החיל
3744 ,ברוכין ,107 ,הנוי
3744 ,ברוכין ,106 ,הנצח
3744 ,ברוכין ,102 ,העוז
3744 ,ברוכין ,101 ,הצבי
3744 ,ברוכין ,114 ,הרון
3744 ,ברוכין ,113 ,השבח
3744 ,ברוכין ,108 ,השגב
3744 ,ברוכין ,103 ,השיר
3744 ,ברוכין ,105 ,התהילה
428 ,ברור חיל ,9000 ,ברור חיל
2060 ,ברוש ,9000 ,ברוש
3710 ,ברכה ,101 ,אביר יעקב
3710 ,ברכה ,102 ,אבן ישראל
3710 ,ברכה ,103 ,איתן
3710 ,ברכה ,9000 ,ברכה
3710 ,ברכה ,104 ,ברכת יוסף
3710 ,ברכה ,105 ,הגלעד
3710 ,ברכה ,106 ,הדר
3710 ,ברכה ,7001 ,הר גריזים
3710 ,ברכה ,109 ,הרב איתמר בן גל
3710 ,ברכה ,107 ,חברון
3710 ,ברכה ,108 ,ירושלים
3710 ,ברכה ,110 ,מגדים
3710 ,ברכה ,111 ,מצפה שלם
3710 ,ברכה ,112 ,עלי עין
3710 ,ברכה ,113 ,צפנת
3710 ,ברכה ,114 ,קדם
3710 ,ברכה ,115 ,קרית הישיבה
3710 ,ברכה ,116 ,קשתי
3710 ,ברכה ,117 ,שערי ברכה
3710 ,ברכה ,118 ,תבואות
746 ,ברכיה ,9000 ,ברכיה
667 ,ברעם ,9000 ,ברעם
141 ,ברק ,9000 ,ברק
617 ,ברקאי ,9000 ,ברקאי
3654 ,ברקן ,109 ,אודם
3654 ,ברקן ,103 ,אלמוג
3654 ,ברקן ,131 ,אמנון ותמר
3654 ,ברקן ,136 ,אננס
3654 ,ברקן ,9000 ,ברקן
3654 ,ברקן ,106 ,ברקת
3654 ,ברקן ,101 ,דרך ברקן
3654 ,ברקן ,124 ,האגס
3654 ,ברקן ,117 ,האורן
3654 ,ברקן ,123 ,האלה
3654 ,ברקן ,133 ,האלון
3654 ,ברקן ,111 ,הארז
3654 ,ברקן ,118 ,האשל
3654 ,ברקן ,134 ,הברוש
3654 ,ברקן ,137 ,הדגנית
3654 ,ברקן ,112 ,הדקל
3654 ,ברקן ,119 ,הורדים
3654 ,ברקן ,113 ,הזית
3654 ,ברקן ,125 ,החצבים
3654 ,ברקן ,129 ,היקינטון
3654 ,ברקן ,130 ,הכלנית
3654 ,ברקן ,115 ,הנורית
3654 ,ברקן ,127 ,הנרקיס
3654 ,ברקן ,116 ,הסיגלית
3654 ,ברקן ,142 ,הסיוון
3654 ,ברקן ,126 ,העירית
3654 ,ברקן ,122 ,הערבה
3654 ,ברקן ,138 ,הרדוף
3654 ,ברקן ,121 ,הרותם
3654 ,ברקן ,128 ,השושנים
3654 ,ברקן ,114 ,השקד
3654 ,ברקן ,135 ,התאנה
3654 ,ברקן ,132 ,התפוח
3654 ,ברקן ,104 ,חלמיש
3654 ,ברקן ,108 ,יהלום
3654 ,ברקן ,139 ,יסמין
3654 ,ברקן ,140 ,יערה
3654 ,ברקן ,141 ,מורן
3654 ,ברקן ,7001 ,מעלה ישראל
3654 ,ברקן ,110 ,ספיר
3654 ,ברקן ,102 ,ענבר
3654 ,ברקן ,120 ,רימון
3654 ,ברקן ,105 ,שוהם
3654 ,ברקן ,7002 ,שכ קרוואנים
3654 ,ברקן ,107 ,שנהב
2038 ,ברקת ,105 ,אודם
2038 ,ברקת ,112 ,אור המזרח
2038 ,ברקת ,9000 ,ברקת
2038 ,ברקת ,107 ,הגפן
2038 ,ברקת ,102 ,הזית
2038 ,ברקת ,106 ,היהלום
2038 ,ברקת ,104 ,הכלנית
2038 ,ברקת ,109 ,הלבונה
2038 ,ברקת ,108 ,הספיר
2038 ,ברקת ,113 ,הפיקוס
2038 ,ברקת ,101 ,הפרחים
2038 ,ברקת ,110 ,הרב שלום יצחק
2038 ,ברקת ,103 ,התאנה
2038 ,ברקת ,111 ,חנה
1323 ,בת הדר ,9000 ,בת הדר
1361 ,בת חן ,9000 ,בת חן
1361 ,בת חן ,105 ,גפן
1361 ,בת חן ,102 ,הדס
1361 ,בת חן ,106 ,זית
1361 ,בת חן ,108 ,ערבה
1361 ,בת חן ,104 ,רותם
1361 ,בת חן ,103 ,רימון
1361 ,בת חן ,101 ,תאנה
1361 ,בת חן ,107 ,תמר
1319 ,בת חפר ,134 ,אורן
1319 ,בת חפר ,101 ,אלה
1319 ,בת חפר ,102 ,אשל
1319 ,בת חפר ,106 ,בארי
1319 ,בת חפר ,109 ,בית השיטה
1319 ,בת חפר ,107 ,בית זית
1319 ,בת חפר ,108 ,בית ניר
1319 ,בת חפר ,111 ,בית קמה
1319 ,בת חפר ,135 ,בית קשת
1319 ,בת חפר ,105 ,בצת
1319 ,בת חפר ,104 ,בקעות
1319 ,בת חפר ,103 ,ברקן
1319 ,בת חפר ,9000 ,בת חפר
1319 ,בת חפר ,112 ,גולן
1319 ,בת חפר ,115 ,גלבוע
1319 ,בת חפר ,114 ,גלעד
1319 ,בת חפר ,113 ,גמלא
1319 ,בת חפר ,133 ,דוחל
1319 ,בת חפר ,117 ,דוכיפת
1319 ,בת חפר ,118 ,דיה
1319 ,בת חפר ,116 ,דרור
1319 ,בת חפר ,127 ,חבצלת
1319 ,בת חפר ,123 ,חיטה
1319 ,בת חפר ,125 ,חיננית
1319 ,בת חפר ,122 ,חלבלוב
1319 ,בת חפר ,121 ,חלמונית
1319 ,בת חפר ,119 ,חמציץ
1319 ,בת חפר ,124 ,חצב
1319 ,בת חפר ,126 ,חרצית
1319 ,בת חפר ,128 ,שד הגליל
1319 ,בת חפר ,120 ,שד הנגב
1319 ,בת חפר ,131 ,שד הערבה
1319 ,בת חפר ,132 ,שד השומרון
1319 ,בת חפר ,110 ,שד השפלה
1319 ,בת חפר ,130 ,שד השרון
1319 ,בת חפר ,129 ,שד עמק חפר
6200 ,בת ים ,646 ,אוסישקין
6200 ,בת ים ,637 ,אופיר
6200 ,בת ים ,510 ,אורט ישראל
6200 ,בת ים ,6540 ,אזור התעשיה
6200 ,בת ים ,6541 ,אזור חוף הים
6200 ,בת ים ,657 ,אחד העם
6200 ,בת ים ,505 ,אילת
6200 ,בת ים ,649 ,אימבר
6200 ,בת ים ,315 ,אלמליח
6200 ,בת ים ,647 ,אלתרמן
6200 ,בת ים ,406 ,אנילביץ
6200 ,בת ים ,614 ,אסירי ציון
6200 ,בת ים ,645 ,אצ"ל
6200 ,בת ים ,638 ,ארזים
6200 ,בת ים ,100 ,אריק אינשטיין
6200 ,בת ים ,209 ,ארלוזורוב
6200 ,בת ים ,506 ,אש שלום
6200 ,בת ים ,434 ,אתרוג
6200 ,בת ים ,653 ,בורוכוב
6200 ,בת ים ,658 ,בזל
6200 ,בת ים ,129 ,בי"ח ממשלתי
6200 ,בת ים ,411 ,ביאליק
6200 ,בת ים ,664 ,ביל"ו
6200 ,בת ים ,423 ,בית וגן
6200 ,בת ים ,622 ,בכר ניסים
6200 ,בת ים ,221 ,בלפור
6200 ,בת ים ,124 ,בן יוסף שלמה
6200 ,בת ים ,402 ,בר אילן
6200 ,בת ים ,316 ,בר יהודה
6200 ,בת ים ,217 ,ברדיצ'ב אבא
6200 ,בת ים ,314 ,ברנר
6200 ,בת ים ,668 ,ברץ
6200 ,בת ים ,9000 ,בת ים
6200 ,בת ים ,307 ,גאולים
6200 ,בת ים ,322 ,גבעתי
6200 ,בת ים ,409 ,גולומב
6200 ,בת ים ,103 ,גוש עציון
6200 ,בת ים ,632 ,גלנטי
6200 ,בת ים ,652 ,גנוסר
6200 ,בת ים ,131 ,גנם מתתיהו
6200 ,בת ים ,553 ,גנרל קניג פייר
6200 ,בת ים ,624 ,גריזים
6200 ,בת ים ,135 ,ד"ר כהן משה
6200 ,בת ים ,313 ,ד"ר ניר נחום
6200 ,בת ים ,408 ,דגניה
6200 ,בת ים ,663 ,דוגית
6200 ,בת ים ,660 ,דוד המלך
6200 ,בת ים ,318 ,דולפין
6200 ,בת ים ,615 ,דליה
6200 ,בת ים ,426 ,דניאל
6200 ,בת ים ,301 ,דנין יחזקאל
6200 ,בת ים ,319 ,דקר
6200 ,בת ים ,555 ,דרך אלון יגאל
6200 ,בת ים ,212 ,דרך בן גוריון
6200 ,בת ים ,620 ,דרך מנחם בגין
6200 ,בת ים ,669 ,האדמו"ר מבאבוב
6200 ,בת ים ,606 ,האורגים
6200 ,בת ים ,631 ,הבנים
6200 ,בת ים ,666 ,הבריגדה
6200 ,בת ים ,120 ,הגבורים
6200 ,בת ים ,511 ,הגבעות
6200 ,בת ים ,419 ,הגדוד העברי
6200 ,בת ים ,672 ,הגולן
6200 ,בת ים ,536 ,הגלבוע
6200 ,בת ים ,122 ,הגליל
6200 ,בת ים ,642 ,הגלעד
6200 ,בת ים ,126 ,הגפן
6200 ,בת ים ,512 ,הדדי ציון שאול
6200 ,בת ים ,633 ,הדקל
6200 ,בת ים ,430 ,ההגנה
6200 ,בת ים ,125 ,ההסתדרות
6200 ,בת ים ,407 ,הוז דב
6200 ,בת ים ,202 ,הולצברג שמחה
6200 ,בת ים ,128 ,הזית
6200 ,בת ים ,111 ,החלוצים
6200 ,בת ים ,422 ,החרושת
6200 ,בת ים ,403 ,החשמונאים
6200 ,בת ים ,610 ,החשמל
6200 ,בת ים ,228 ,הים
6200 ,בת ים ,232 ,הירש
6200 ,בת ים ,542 ,הכרמל
6200 ,בת ים ,323 ,הל"א
6200 ,בת ים ,208 ,הלפר
6200 ,בת ים ,656 ,המיסדים
6200 ,בת ים ,603 ,המלאכה
6200 ,בת ים ,113 ,המעפילים
6200 ,בת ים ,6510 ,המרכז
6200 ,בת ים ,648 ,המשוררת רחל
6200 ,בת ים ,304 ,הנביאים
6200 ,בת ים ,621 ,הסבוראים
6200 ,בת ים ,605 ,הסוללים
6200 ,בת ים ,219 ,העמדות
6200 ,בת ים ,607 ,העמל
6200 ,בת ים ,543 ,העמק
6200 ,בת ים ,535 ,העצמון
6200 ,בת ים ,404 ,הפועל
6200 ,בת ים ,321 ,הפטמן
6200 ,בת ים ,609 ,הפלדה
6200 ,בת ים ,114 ,הפלמ"ח
6200 ,בת ים ,611 ,הצורפים
6200 ,בת ים ,104 ,הצנחנים
6200 ,בת ים ,320 ,הצפירה
6200 ,בת ים ,311 ,הקוממיות
6200 ,בת ים ,226 ,הראשונים
6200 ,בת ים ,110 ,הרב אביעד
6200 ,בת ים ,237 ,הרב בר שאול
6200 ,בת ים ,665 ,הרב גביזון
6200 ,בת ים ,416 ,הרב הרצוג
6200 ,בת ים ,544 ,הרב לוזון
6200 ,בת ים ,523 ,הרב לוי
6200 ,בת ים ,613 ,הרב מימון
6200 ,בת ים ,508 ,הרב ניסנבוים יצחק
6200 ,בת ים ,414 ,הרב עוזיאל
6200 ,בת ים ,628 ,הרב צבי יהודה
6200 ,בת ים ,214 ,הרב קוק
6200 ,בת ים ,240 ,הרב קוקיס
6200 ,בת ים ,619 ,הרותם
6200 ,בת ים ,210 ,הרצל
6200 ,בת ים ,413 ,הרשת
6200 ,בת ים ,623 ,השבטים
6200 ,בת ים ,529 ,השומרון
6200 ,בת ים ,112 ,השומרים
6200 ,בת ים ,106 ,השלושה
6200 ,בת ים ,308 ,השקמה
6200 ,בת ים ,644 ,השר ברזילי
6200 ,בת ים ,437 ,השר פנקס
6200 ,בת ים ,446 ,השר שפירא
6200 ,בת ים ,531 ,התבור
6200 ,בת ים ,641 ,התותחנים
6200 ,בת ים ,501 ,התחיה
6200 ,בת ים ,117 ,ויצמן
6200 ,בת ים ,604 ,וישינגרד ישראל
6200 ,בת ים ,302 ,ז'בוטינסקי
6200 ,בת ים ,305 ,ז'ילבר
6200 ,בת ים ,431 ,זמנהוף
6200 ,בת ים ,654 ,זרובבל
6200 ,בת ים ,6542 ,חוף הסלע
6200 ,בת ים ,667 ,חי"ש
6200 ,בת ים ,133 ,חלמית
6200 ,בת ים ,678 ,חלפון
6200 ,בת ים ,532 ,חצור
6200 ,בת ים ,639 ,טבנקין
6200 ,בת ים ,420 ,טרומפלדור
6200 ,בת ים ,412 ,טשרניחובסקי
6200 ,בת ים ,309 ,יהודה הלוי
6200 ,בת ים ,608 ,יוחנן הסנדלר
6200 ,בת ים ,132 ,יוסף הנשיא
6200 ,בת ים ,537 ,יטבתה
6200 ,בת ים ,677 ,יצחק נפחא
6200 ,בת ים ,312 ,יצחק שדה
6200 ,בת ים ,602 ,יקואל מנחם
6200 ,בת ים ,651 ,יקינטון
6200 ,בת ים ,102 ,ירושלים
6200 ,בת ים ,546 ,ירקוני
6200 ,בת ים ,218 ,ישראל בן ציון
6200 ,בת ים ,521 ,כ"ט בנובמבר
6200 ,בת ים ,140 ,כביש גבול חולון
6200 ,בת ים ,518 ,כהן אלי
6200 ,בת ים ,541 ,כובשי החרמון
6200 ,בת ים ,6001 ,ככר המגינים
6200 ,בת ים ,6002 ,ככר המדינה
6200 ,בת ים ,6003 ,ככר העצמאות
6200 ,בת ים ,6004 ,ככר וינגייט
6200 ,בת ים ,650 ,כנור דוד
6200 ,בת ים ,433 ,כנרת
6200 ,בת ים ,401 ,כצנלסון ברל
6200 ,בת ים ,517 ,לויתן
6200 ,בת ים ,629 ,לח"י
6200 ,בת ים ,522 ,ליבורנו
6200 ,בת ים ,224 ,ליבסקינד
6200 ,בת ים ,415 ,ליואי אברהם
6200 ,בת ים ,540 ,לכיש
6200 ,בת ים ,425 ,מ"ג
6200 ,בת ים ,503 ,מבצע סיני
6200 ,בת ים ,539 ,מגידו
6200 ,בת ים ,519 ,מודיליאני
6200 ,בת ים ,538 ,מודיעין
6200 ,בת ים ,670 ,מוהליבר
6200 ,בת ים ,634 ,מוכרי הסיגריות
6200 ,בת ים ,130 ,מחרוזת
6200 ,בת ים ,442 ,מייזל זלמן
6200 ,בת ים ,229 ,מלון מרינה
6200 ,בת ים ,227 ,מלון סאן טון
6200 ,בת ים ,436 ,מנדלי מוכר ספרים
6200 ,בת ים ,225 ,מסיקה
6200 ,בת ים ,216 ,מסריק
6200 ,בת ים ,6506 ,מע בת ים
6200 ,בת ים ,223 ,מעבר ישי
6200 ,בת ים ,6500 ,מעונות ים
6200 ,בת ים ,222 ,מעלה השחרור
6200 ,בת ים ,530 ,מצדה
6200 ,בת ים ,675 ,מצודת בית"ר
6200 ,בת ים ,127 ,מרבד הקסמים
6200 ,בת ים ,659 ,מרגנית
6200 ,בת ים ,325 ,משעול אייל
6200 ,בת ים ,121 ,נגבה
6200 ,בת ים ,239 ,נויקלן
6200 ,בת ים ,443 ,נורדאו מקס
6200 ,בת ים ,116 ,נחייסי אליהו
6200 ,בת ים ,108 ,ניצנה
6200 ,בת ים ,109 ,נעמי שמר
6200 ,בת ים ,630 ,נרקיס
6200 ,בת ים ,514 ,סולד הנרייטה
6200 ,בת ים ,215 ,סוקולוב
6200 ,בת ים ,515 ,סטרומה
6200 ,בת ים ,527 ,סמ האילנות
6200 ,בת ים ,525 ,סמ הורדים
6200 ,בת ים ,528 ,סמ הצפורנים
6200 ,בת ים ,231 ,סמ הראשונים
6200 ,בת ים ,526 ,סמ השושנים
6200 ,בת ים ,533 ,סמ השרון
6200 ,בת ים ,520 ,סמ ישראלס
6200 ,בת ים ,427 ,סמ מיסיסטרנו
6200 ,בת ים ,428 ,סמ נייגו
6200 ,בת ים ,524 ,סמ ניל"י
6200 ,בת ים ,507 ,סנה משה
6200 ,בת ים ,211 ,סנש חנה
6200 ,בת ים ,655 ,עגנון
6200 ,בת ים ,627 ,עדולם
6200 ,בת ים ,107 ,עפרה חזה
6200 ,בת ים ,206 ,פיינשטיין
6200 ,בת ים ,324 ,פרופ' יואלי
6200 ,בת ים ,410 ,פרלשטיין
6200 ,בת ים ,612 ,פרנק אנה
6200 ,בת ים ,616 ,פרץ
6200 ,בת ים ,625 ,צ'רניאקוב
6200 ,בת ים ,417 ,צה"ל
6200 ,בת ים ,105 ,קבוץ גלויות
6200 ,בת ים ,118 ,קדושי לוצק
6200 ,בת ים ,213 ,קדושי קהיר
6200 ,בת ים ,640 ,קהילת סלונים
6200 ,בת ים ,421 ,קורצ'ק
6200 ,בת ים ,418 ,קלויזנר
6200 ,בת ים ,601 ,קנמון אהוד
6200 ,בת ים ,6538 ,קרית באבוב
6200 ,בת ים ,441 ,קריתי
6200 ,בת ים ,626 ,קרן היסוד
6200 ,בת ים ,101 ,קרן קימת לישראל
6200 ,בת ים ,317 ,רבי בנימין
6200 ,בת ים ,552 ,רבינוביץ'
6200 ,בת ים ,502 ,רהב
6200 ,בת ים ,220 ,רוטשילד
6200 ,בת ים ,534 ,רזיאל דוד
6200 ,בת ים ,119 ,רח 271
6200 ,בת ים ,438 ,רייק חביבה
6200 ,בת ים ,435 ,רימון
6200 ,בת ים ,207 ,רמב"ם
6200 ,בת ים ,405 ,רמז
6200 ,בת ים ,6536 ,רמת הנשיא
6200 ,בת ים ,6526 ,רמת לוז
6200 ,בת ים ,643 ,רקפת
6200 ,בת ים ,6527 ,ש העיריה
6200 ,בת ים ,6537 ,ש הפוהמ"ז
6200 ,בת ים ,6516 ,ש ותיקים
6200 ,בת ים ,6528 ,ש עולה חדש
6200 ,בת ים ,6529 ,ש עמידר
6200 ,בת ים ,6517 ,ש רמות ים
6200 ,בת ים ,6530 ,ש רמת יוסף
6200 ,בת ים ,662 ,שאול המלך
6200 ,בת ים ,617 ,שבזי
6200 ,בת ים ,201 ,שד העצמאות
6200 ,בת ים ,680 ,שד וילרבאן
6200 ,בת ים ,310 ,שד יוספטל גיורא
6200 ,בת ים ,636 ,שטרן
6200 ,בת ים ,306 ,שיבת ציון
6200 ,בת ים ,635 ,שינדלר אוסקר
6200 ,בת ים ,6539 ,שכ הקרוונים
6200 ,בת ים ,6501 ,שכ סחנה ג
6200 ,בת ים ,6531 ,שכ סחנה ד
6200 ,בת ים ,432 ,שלום עליכם
6200 ,בת ים ,661 ,שלמה המלך
6200 ,בת ים ,676 ,שמעון הבורסקי
6200 ,בת ים ,671 ,שפרבר חיים
6200 ,בת ים ,439 ,שרת משה
6200 ,בת ים ,440 ,ששת הימים
6200 ,בת ים ,545 ,תל חי
6200 ,בת ים ,513 ,תנין
3794 ,בת עין ,9000 ,בת עין
3794 ,בת עין ,101 ,ישיבת בת עין
3794 ,בת עין ,102 ,מדרשת בת עין
33 ,בת שלמה ,9000 ,בת שלמה
33 ,בת שלמה ,108 ,האפרסק
33 ,בת שלמה ,103 ,הגפן
33 ,בת שלמה ,106 ,הזית
33 ,בת שלמה ,101 ,המייסדים
33 ,בת שלמה ,109 ,המשמש
33 ,בת שלמה ,104 ,הרימון
33 ,בת שלמה ,107 ,השזיף
33 ,בת שלמה ,102 ,השסק
33 ,בת שלמה ,105 ,התאנה
33 ,בת שלמה ,110 ,משעול הלימון
33 ,בת שלמה ,111 ,משעול הצבר
33 ,בת שלמה ,112 ,משעול התפוח
1292 ,ג'דיידה-מכר ,6730 ,אזור התעשייה
1292 ,ג'דיידה-מכר ,6713 ,אל מרג'
1292 ,ג'דיידה-מכר ,101 ,ג'דיידה
1292 ,ג'דיידה-מכר ,9000 ,ג'דיידה-מכר
1292 ,ג'דיידה-מכר ,6722 ,גרעין הכפר-ג'דיידה
1292 ,ג'דיידה-מכר ,6740 ,הגרעין הישן-מכר
1292 ,ג'דיידה-מכר ,6731 ,השכונה הדרומית-מכר
1292 ,ג'דיידה-מכר ,6742 ,השכונה המערבית-מכר
1292 ,ג'דיידה-מכר ,6741 ,השכונה הצפונית-מכר
1292 ,ג'דיידה-מכר ,201 ,מכר
1292 ,ג'דיידה-מכר ,199 ,רח 1100
1292 ,ג'דיידה-מכר ,111 ,רח 1010
1292 ,ג'דיידה-מכר ,210 ,רח 1110
1292 ,ג'דיידה-מכר ,121 ,רח 1020
1292 ,ג'דיידה-מכר ,220 ,רח 1120
1292 ,ג'דיידה-מכר ,130 ,רח 1030
1292 ,ג'דיידה-מכר ,230 ,רח 1130
1292 ,ג'דיידה-מכר ,140 ,רח 1040
1292 ,ג'דיידה-מכר ,240 ,רח 1140
1292 ,ג'דיידה-מכר ,150 ,רח 1050
1292 ,ג'דיידה-מכר ,160 ,רח 1060
1292 ,ג'דיידה-מכר ,169 ,רח 1070
1292 ,ג'דיידה-מכר ,179 ,רח 1080
1292 ,ג'דיידה-מכר ,189 ,רח 1090
1292 ,ג'דיידה-מכר ,102 ,רח 1001
1292 ,ג'דיידה-מכר ,200 ,רח 1101
1292 ,ג'דיידה-מכר ,112 ,רח 1011
1292 ,ג'דיידה-מכר ,211 ,רח 1111
1292 ,ג'דיידה-מכר ,122 ,רח 1021
1292 ,ג'דיידה-מכר ,221 ,רח 1121
1292 ,ג'דיידה-מכר ,131 ,רח 1031
1292 ,ג'דיידה-מכר ,231 ,רח 1131
1292 ,ג'דיידה-מכר ,141 ,רח 1041
1292 ,ג'דיידה-מכר ,241 ,רח 1141
1292 ,ג'דיידה-מכר ,151 ,רח 1051
1292 ,ג'דיידה-מכר ,161 ,רח 1061
1292 ,ג'דיידה-מכר ,170 ,רח 1071
1292 ,ג'דיידה-מכר ,180 ,רח 1081
1292 ,ג'דיידה-מכר ,190 ,רח 1091
1292 ,ג'דיידה-מכר ,103 ,רח 1002
1292 ,ג'דיידה-מכר ,202 ,רח 1102
1292 ,ג'דיידה-מכר ,113 ,רח 1012
1292 ,ג'דיידה-מכר ,212 ,רח 1112
1292 ,ג'דיידה-מכר ,222 ,רח 1122
1292 ,ג'דיידה-מכר ,132 ,רח 1032
1292 ,ג'דיידה-מכר ,232 ,רח 1132
1292 ,ג'דיידה-מכר ,142 ,רח 1042
1292 ,ג'דיידה-מכר ,242 ,רח 1142
1292 ,ג'דיידה-מכר ,152 ,רח 1052
1292 ,ג'דיידה-מכר ,162 ,רח 1062
1292 ,ג'דיידה-מכר ,171 ,רח 1072
1292 ,ג'דיידה-מכר ,181 ,רח 1082
1292 ,ג'דיידה-מכר ,191 ,רח 1092
1292 ,ג'דיידה-מכר ,104 ,רח 1003
1292 ,ג'דיידה-מכר ,203 ,רח 1103
1292 ,ג'דיידה-מכר ,114 ,רח 1013
1292 ,ג'דיידה-מכר ,213 ,רח 1113
1292 ,ג'דיידה-מכר ,123 ,רח 1023
1292 ,ג'דיידה-מכר ,223 ,רח 1123
1292 ,ג'דיידה-מכר ,133 ,רח 1033
1292 ,ג'דיידה-מכר ,233 ,רח 1133
1292 ,ג'דיידה-מכר ,143 ,רח 1043
1292 ,ג'דיידה-מכר ,153 ,רח 1053
1292 ,ג'דיידה-מכר ,163 ,רח 1063
1292 ,ג'דיידה-מכר ,172 ,רח 1073
1292 ,ג'דיידה-מכר ,182 ,רח 1083
1292 ,ג'דיידה-מכר ,192 ,רח 1093
1292 ,ג'דיידה-מכר ,105 ,רח 1004
1292 ,ג'דיידה-מכר ,204 ,רח 1104
1292 ,ג'דיידה-מכר ,115 ,רח 1014
1292 ,ג'דיידה-מכר ,214 ,רח 1114
1292 ,ג'דיידה-מכר ,124 ,רח 1024
1292 ,ג'דיידה-מכר ,224 ,רח 1124
1292 ,ג'דיידה-מכר ,134 ,רח 1034
1292 ,ג'דיידה-מכר ,144 ,רח 1044
1292 ,ג'דיידה-מכר ,154 ,רח 1054
1292 ,ג'דיידה-מכר ,164 ,רח 1064
1292 ,ג'דיידה-מכר ,173 ,רח 1074
1292 ,ג'דיידה-מכר ,183 ,רח 1084
1292 ,ג'דיידה-מכר ,193 ,רח 1094
1292 ,ג'דיידה-מכר ,106 ,רח 1005
1292 ,ג'דיידה-מכר ,205 ,רח 1105
1292 ,ג'דיידה-מכר ,116 ,רח 1015
1292 ,ג'דיידה-מכר ,215 ,רח 1115
1292 ,ג'דיידה-מכר ,125 ,רח 1025
1292 ,ג'דיידה-מכר ,225 ,רח 1125
1292 ,ג'דיידה-מכר ,135 ,רח 1035
1292 ,ג'דיידה-מכר ,145 ,רח 1045
1292 ,ג'דיידה-מכר ,155 ,רח 1055
1292 ,ג'דיידה-מכר ,165 ,רח 1065
1292 ,ג'דיידה-מכר ,174 ,רח 1075
1292 ,ג'דיידה-מכר ,184 ,רח 1085
1292 ,ג'דיידה-מכר ,194 ,רח 1095
1292 ,ג'דיידה-מכר ,107 ,רח 1006
1292 ,ג'דיידה-מכר ,206 ,רח 1106
1292 ,ג'דיידה-מכר ,117 ,רח 1016
1292 ,ג'דיידה-מכר ,216 ,רח 1116
1292 ,ג'דיידה-מכר ,126 ,רח 1026
1292 ,ג'דיידה-מכר ,226 ,רח 1126
1292 ,ג'דיידה-מכר ,136 ,רח 1036
1292 ,ג'דיידה-מכר ,236 ,רח 1136
1292 ,ג'דיידה-מכר ,146 ,רח 1046
1292 ,ג'דיידה-מכר ,156 ,רח 1056
1292 ,ג'דיידה-מכר ,166 ,רח 1066
1292 ,ג'דיידה-מכר ,175 ,רח 1076
1292 ,ג'דיידה-מכר ,185 ,רח 1086
1292 ,ג'דיידה-מכר ,195 ,רח 1096
1292 ,ג'דיידה-מכר ,108 ,רח 1007
1292 ,ג'דיידה-מכר ,207 ,רח 1107
1292 ,ג'דיידה-מכר ,118 ,רח 1017
1292 ,ג'דיידה-מכר ,217 ,רח 1117
1292 ,ג'דיידה-מכר ,127 ,רח 1027
1292 ,ג'דיידה-מכר ,227 ,רח 1127
1292 ,ג'דיידה-מכר ,137 ,רח 1037
1292 ,ג'דיידה-מכר ,237 ,רח 1137
1292 ,ג'דיידה-מכר ,147 ,רח 1047
1292 ,ג'דיידה-מכר ,157 ,רח 1057
1292 ,ג'דיידה-מכר ,176 ,רח 1077
1292 ,ג'דיידה-מכר ,186 ,רח 1087
1292 ,ג'דיידה-מכר ,196 ,רח 1097
1292 ,ג'דיידה-מכר ,109 ,רח 1008
1292 ,ג'דיידה-מכר ,208 ,רח 1108
1292 ,ג'דיידה-מכר ,119 ,רח 1018
1292 ,ג'דיידה-מכר ,218 ,רח 1118
1292 ,ג'דיידה-מכר ,128 ,רח 1028
1292 ,ג'דיידה-מכר ,228 ,רח 1128
1292 ,ג'דיידה-מכר ,138 ,רח 1038
1292 ,ג'דיידה-מכר ,238 ,רח 1138
1292 ,ג'דיידה-מכר ,148 ,רח 1048
1292 ,ג'דיידה-מכר ,158 ,רח 1058
1292 ,ג'דיידה-מכר ,167 ,רח 1068
1292 ,ג'דיידה-מכר ,177 ,רח 1078
1292 ,ג'דיידה-מכר ,187 ,רח 1088
1292 ,ג'דיידה-מכר ,197 ,רח 1098
1292 ,ג'דיידה-מכר ,110 ,רח 1009
1292 ,ג'דיידה-מכר ,209 ,רח 1109
1292 ,ג'דיידה-מכר ,120 ,רח 1019
1292 ,ג'דיידה-מכר ,219 ,רח 1119
1292 ,ג'דיידה-מכר ,129 ,רח 1029
1292 ,ג'דיידה-מכר ,229 ,רח 1129
1292 ,ג'דיידה-מכר ,139 ,רח 1039
1292 ,ג'דיידה-מכר ,239 ,רח 1139
1292 ,ג'דיידה-מכר ,149 ,רח 1049
1292 ,ג'דיידה-מכר ,159 ,רח 1059
1292 ,ג'דיידה-מכר ,168 ,רח 1069
1292 ,ג'דיידה-מכר ,178 ,רח 1079
1292 ,ג'דיידה-מכר ,188 ,רח 1089
1292 ,ג'דיידה-מכר ,198 ,רח 1099
1292 ,ג'דיידה-מכר ,6732 ,שיכונים דרומיים-מכר
1292 ,ג'דיידה-מכר ,6734 ,שיכונים מזרחיים-מכר
1292 ,ג'דיידה-מכר ,6711 ,שכ ברבור
1292 ,ג'דיידה-מכר ,6720 ,שכ דרומית-ג'דיידה
1292 ,ג'דיידה-מכר ,6721 ,שכ מזרח דרום-ג'דידה
1292 ,ג'דיידה-מכר ,6733 ,שכ מזרח דרומית-מכר
1292 ,ג'דיידה-מכר ,6712 ,שכ מזרחית-ג'דיידה
1292 ,ג'דיידה-מכר ,6723 ,שכ מערבית-ג'דיידה
1292 ,ג'דיידה-מכר ,6710 ,שכ צפונית-ג'דיידה
485 ,ג'ולס ,220 ,אלאמל
485 ,ג'ולס ,205 ,אלאס'יל
485 ,ג'ולס ,224 ,אלאע'ניאא
485 ,ג'ולס ,146 ,אלארז
485 ,ג'ולס ,158 ,אלבאבור
485 ,ג'ולס ,112 ,אלבאנזין
485 ,ג'ולס ,117 ,אלבאניאס
485 ,ג'ולס ,118 ,אלבאשא
485 ,ג'ולס ,177 ,אלבוסתאן
485 ,ג'ולס ,102 ,אלבטיח'ה
485 ,ג'ולס ,179 ,אלביארה
485 ,ג'ולס ,161 ,אלבידר
485 ,ג'ולס ,130 ,אלביר
485 ,ג'ולס ,194 ,אלבלח
485 ,ג'ולס ,121 ,אלבלסם
485 ,ג'ולס ,223 ,אלבנא
485 ,ג'ולס ,213 ,אלבנפסג'
485 ,ג'ולס ,211 ,אלברק
485 ,ג'ולס ,216 ,אלג'באל
485 ,ג'ולס ,162 ,אלג'בל
485 ,ג'ולס ,189 ,אלג'ורי
485 ,ג'ולס ,115 ,אלג'ליל
485 ,ג'ולס ,108 ,אלדיואן
485 ,ג'ולס ,140 ,אלואדי
485 ,ג'ולס ,163 ,אלואדי אלשמאלי
485 ,ג'ולס ,156 ,אלוזיאת
485 ,ג'ולס ,197 ,אלורד
485 ,ג'ולס ,210 ,אלורור
485 ,ג'ולס ,114 ,אלזהור
485 ,ג'ולס ,113 ,אלזהר
485 ,ג'ולס ,208 ,אלזית
485 ,ג'ולס ,164 ,אלזיתון
485 ,ג'ולס ,132 ,אלזקאק
485 ,ג'ולס ,214 ,אלח'זאן
485 ,ג'ולס ,147 ,אלח'יזראן
485 ,ג'ולס ,201 ,אלח'יל
485 ,ג'ולס ,202 ,אלח'לא
485 ,ג'ולס ,160 ,אלח'לאל
485 ,ג'ולס ,105 ,אלח'לה
485 ,ג'ולס ,212 ,אלח'רבה
485 ,ג'ולס ,159 ,אלח'רובה
485 ,ג'ולס ,174 ,אלחואר
485 ,ג'ולס ,173 ,אלחור
485 ,ג'ולס ,119 ,אלחורה
485 ,ג'ולס ,184 ,אלחורש
485 ,ג'ולס ,137 ,אלחיאת
485 ,ג'ולס ,207 ,אלחס'יד
485 ,ג'ולס ,123 ,אלכרום
485 ,ג'ולס ,204 ,אלכרז
485 ,ג'ולס ,186 ,אלכרם
485 ,ג'ולס ,155 ,אלכרמל
485 ,ג'ולס ,154 ,אלמג'ד
485 ,ג'ולס ,218 ,אלמוהרה
485 ,ג'ולס ,175 ,אלמוח'תארה
485 ,ג'ולס ,221 ,אלמוסתקבל
485 ,ג'ולס ,141 ,אלמורג'אן
485 ,ג'ולס ,104 ,אלמזראב
485 ,ג'ולס ,103 ,אלמטל
485 ,ג'ולס ,107 ,אלמידאן
485 ,ג'ולס ,187 ,אלמנזול
485 ,ג'ולס ,166 ,אלמנקוש
485 ,ג'ולס ,188 ,אלמע'איר
485 ,ג'ולס ,124 ,אלמע'ארה
485 ,ג'ולס ,171 ,אלמעס'רה
485 ,ג'ולס ,136 ,אלנג'מה
485 ,ג'ולס ,203 ,אלנואר
485 ,ג'ולס ,217 ,אלנורס
485 ,ג'ולס ,196 ,אלנח'יל
485 ,ג'ולס ,128 ,אלנח'ל
485 ,ג'ולס ,111 ,אלנחאס
485 ,ג'ולס ,190 ,אלנקאטה
485 ,ג'ולס ,165 ,אלנרג'ס
485 ,ג'ולס ,180 ,אלס'באר
485 ,ג'ולס ,101 ,אלס'בר
485 ,ג'ולס ,149 ,אלס'פא
485 ,ג'ולס ,139 ,אלסהל
485 ,ג'ולס ,191 ,אלסומאק
485 ,ג'ולס ,176 ,אלסיוף
485 ,ג'ולס ,138 ,אלסלאם
485 ,ג'ולס ,193 ,אלסמא
485 ,ג'ולס ,122 ,אלסמרא
485 ,ג'ולס ,109 ,אלסנדיאן
485 ,ג'ולס ,219 ,אלספאח
485 ,ג'ולס ,215 ,אלסריס
485 ,ג'ולס ,183 ,אלע'אר
485 ,ג'ולס ,170 ,אלע'ורבאל
485 ,ג'ולס ,206 ,אלע'זאל
485 ,ג'ולס ,209 ,אלעאלי
485 ,ג'ולס ,129 ,אלעיון
485 ,ג'ולס ,116 ,אלעלם
485 ,ג'ולס ,151 ,אלענאב
485 ,ג'ולס ,148 ,אלענב
485 ,ג'ולס ,106 ,אלענברה
485 ,ג'ולס ,120 ,אלקובה
485 ,ג'ולס ,181 ,אלרומאנה
485 ,ג'ולס ,192 ,אלרוע'ב
485 ,ג'ולס ,110 ,אלריאד'ה
485 ,ג'ולס ,135 ,אלרפיף
485 ,ג'ולס ,185 ,אלרפראף
485 ,ג'ולס ,157 ,אלשביבה
485 ,ג'ולס ,145 ,אלשורה
485 ,ג'ולס ,133 ,אלשיח'
485 ,ג'ולס ,134 ,אלשיח' עלי פארס
485 ,ג'ולס ,167 ,אלשמאל
485 ,ג'ולס ,144 ,אלשקרא
485 ,ג'ולס ,143 ,אלשרוק
485 ,ג'ולס ,195 ,אלתמר
485 ,ג'ולס ,150 ,אלתנור
485 ,ג'ולס ,9000 ,ג'ולס
485 ,ג'ולס ,127 ,ואדי חמרא
485 ,ג'ולס ,142 ,ירכא
485 ,ג'ולס ,126 ,כרם אלעדס
485 ,ג'ולס ,172 ,מארוש
485 ,ג'ולס ,152 ,מג'יזל
485 ,ג'ולס ,131 ,ראס ח'רוב
485 ,ג'ולס ,153 ,ראס קונבר
485 ,ג'ולס ,182 ,רומאן
485 ,ג'ולס ,125 ,תל גאד
485 ,ג'ולס ,169 ,תלה
627 ,ג'לג'וליה ,175 ,א-זיתונה
627 ,ג'לג'וליה ,125 ,א-נופליה
627 ,ג'לג'וליה ,146 ,א-נור
627 ,ג'לג'וליה ,178 ,א-נח'יל
627 ,ג'לג'וליה ,182 ,א-נרג'ס
627 ,ג'לג'וליה ,130 ,א-סלאם
627 ,ג'לג'וליה ,176 ,א-סנאבל
627 ,ג'לג'וליה ,171 ,א-סנובר
627 ,ג'לג'וליה ,120 ,א-ראזי
627 ,ג'לג'וליה ,115 ,א-רבאט
627 ,ג'לג'וליה ,140 ,א-רוודה
627 ,ג'לג'וליה ,172 ,א-רומאנה
627 ,ג'לג'וליה ,128 ,א-רחמה
627 ,ג'לג'וליה ,174 ,א-ריחאן
627 ,ג'לג'וליה ,165 ,א-שאם
627 ,ג'לג'וליה ,112 ,א-שאפעי
627 ,ג'לג'וליה ,185 ,א-תל
627 ,ג'לג'וליה ,109 ,א-תקווה
627 ,ג'לג'וליה ,108 ,אבו בכר
627 ,ג'לג'וליה ,129 ,אבו עבידה אלג'ראח
627 ,ג'לג'וליה ,135 ,אבן אל-הית'ם
627 ,ג'לג'וליה ,139 ,אבן חלדון
627 ,ג'לג'וליה ,122 ,אבן רושד
627 ,ג'לג'וליה ,167 ,אל איסראא
627 ,ג'לג'וליה ,148 ,אל חיכמה
627 ,ג'לג'וליה ,180 ,אל כראמה
627 ,ג'לג'וליה ,170 ,אל מעראג'
627 ,ג'לג'וליה ,134 ,אל קודס
627 ,ג'לג'וליה ,127 ,אל-אח'לאס
627 ,ג'לג'וליה ,150 ,אל-אחא
627 ,ג'לג'וליה ,133 ,אל-אימאן
627 ,ג'לג'וליה ,111 ,אל-איתיחאד
627 ,ג'לג'וליה ,119 ,אל-אמל
627 ,ג'לג'וליה ,132 ,אל-אנדלוס
627 ,ג'לג'וליה ,113 ,אל-ארז
627 ,ג'לג'וליה ,118 ,אל-בוח'ארי
627 ,ג'לג'וליה ,177 ,אל-ביארה
627 ,ג'לג'וליה ,117 ,אל-בירוני
627 ,ג'לג'וליה ,169 ,אל-ג'יזאיר
627 ,ג'לג'וליה ,163 ,אל-ג'ליל
627 ,ג'לג'וליה ,149 ,אל-הודא
627 ,ג'לג'וליה ,173 ,אל-וורוד
627 ,ג'לג'וליה ,137 ,אל-וחדה
627 ,ג'לג'וליה ,157 ,אל-ופאא
627 ,ג'לג'וליה ,158 ,אל-ופאק
627 ,ג'לג'וליה ,183 ,אל-זאקור
627 ,ג'לג'וליה ,121 ,אל-זהראא
627 ,ג'לג'וליה ,102 ,אל-ח'אן
627 ,ג'לג'וליה ,184 ,אל-חנסאא
627 ,ג'לג'וליה ,181 ,אל-יסמין
627 ,ג'לג'וליה ,106 ,אל-עדל
627 ,ג'לג'וליה ,155 ,אל-קדאסיה
627 ,ג'לג'וליה ,141 ,בגדד
627 ,ג'לג'וליה ,136 ,ביירות
627 ,ג'לג'וליה ,159 ,ביסאן
627 ,ג'לג'וליה ,9000 ,ג'לג'וליה
627 ,ג'לג'וליה ,138 ,דמשק
627 ,ג'לג'וליה ,186 ,ואדי קאנא
627 ,ג'לג'וליה ,126 ,ח'אלד אבן אל-ווליד
627 ,ג'לג'וליה ,151 ,ח'ריש
627 ,ג'לג'וליה ,152 ,חטין
627 ,ג'לג'וליה ,164 ,טבריה
627 ,ג'לג'וליה ,147 ,טריק חבלה
627 ,ג'לג'וליה ,153 ,יאפא
627 ,ג'לג'וליה ,145 ,כפר סבא
627 ,ג'לג'וליה ,161 ,מווילח
627 ,ג'לג'וליה ,144 ,מכה
627 ,ג'לג'וליה ,168 ,ס'פד
627 ,ג'לג'וליה ,101 ,סלאח א-דין
627 ,ג'לג'וליה ,131 ,ע'תמאן אבן עפאן
627 ,ג'לג'וליה ,114 ,עומר אבן אל ח'טאב
627 ,ג'לג'וליה ,116 ,עומר אל מוח'תאר
627 ,ג'לג'וליה ,156 ,עין ג'אלות
627 ,ג'לג'וליה ,154 ,עכא
627 ,ג'לג'וליה ,123 ,עלי אבן אבי טאלב
627 ,ג'לג'וליה ,166 ,ר'זה
627 ,ג'לג'וליה ,142 ,ראס א-טביב
627 ,ג'לג'וליה ,103 ,רח 10
627 ,ג'לג'וליה ,143 ,רח 110
627 ,ג'לג'וליה ,110 ,רח 112
627 ,ג'לג'וליה ,107 ,רח 105
627 ,ג'לג'וליה ,104 ,רח 87
627 ,ג'לג'וליה ,105 ,רח 88
627 ,ג'לג'וליה ,162 ,שמס א-דין
627 ,ג'לג'וליה ,179 ,שקאיק א-נעמאן
627 ,ג'לג'וליה ,187 ,תוניס
976 ,ג'נאביב (שבט) ,9000 ,ג'נאביב (שבט)
541 ,ג'סר א-זרקא ,142 ,אבו בקר אלסדיק
541 ,ג'סר א-זרקא ,135 ,אבן חלדון
541 ,ג'סר א-זרקא ,117 ,אבן סינא
541 ,ג'סר א-זרקא ,6731 ,אזור הים
541 ,ג'סר א-זרקא ,105 ,אל בחר
541 ,ג'סר א-זרקא ,150 ,אל לוז
541 ,ג'סר א-זרקא ,111 ,אלאסד
541 ,ג'סר א-זרקא ,144 ,אלביאדר
541 ,ג'סר א-זרקא ,153 ,אלביארה
541 ,ג'סר א-זרקא ,112 ,אלבריד
541 ,ג'סר א-זרקא ,137 ,אלהודהוד
541 ,ג'סר א-זרקא ,138 ,אלואדי
541 ,ג'סר א-זרקא ,123 ,אלזוקאק
541 ,ג'סר א-זרקא ,109 ,אלזור
541 ,ג'סר א-זרקא ,121 ,אלזיתון
541 ,ג'סר א-זרקא ,110 ,אלחרוב
541 ,ג'סר א-זרקא ,101 ,אלטואחין
541 ,ג'סר א-זרקא ,127 ,אלטנטורה
541 ,ג'סר א-זרקא ,104 ,אליאסמין
541 ,ג'סר א-זרקא ,115 ,אלכוארזמי
541 ,ג'סר א-זרקא ,143 ,אלכנסאא'
541 ,ג'סר א-זרקא ,118 ,אלכרמל
541 ,ג'סר א-זרקא ,103 ,אלמוארס
541 ,ג'סר א-זרקא ,113 ,אלמחג'ר
541 ,ג'סר א-זרקא ,130 ,אלמנארה
541 ,ג'סר א-זרקא ,141 ,אלמתנבי
541 ,ג'סר א-זרקא ,116 ,אלנהדה
541 ,ג'סר א-זרקא ,124 ,אלסאחה
541 ,ג'סר א-זרקא ,136 ,אלסדרה
541 ,ג'סר א-זרקא ,126 ,אלסמאר
541 ,ג'סר א-זרקא ,148 ,אלסראג'
541 ,ג'סר א-זרקא ,132 ,אלעין
541 ,ג'סר א-זרקא ,106 ,אלקודס
541 ,ג'סר א-זרקא ,119 ,אלקייסר
541 ,ג'סר א-זרקא ,107 ,אלקנאה
541 ,ג'סר א-זרקא ,151 ,אלראוודאת
541 ,ג'סר א-זרקא ,122 ,אלרמאן
541 ,ג'סר א-זרקא ,133 ,אלרמל
541 ,ג'סר א-זרקא ,154 ,אלשאטיא
541 ,ג'סר א-זרקא ,145 ,אלשאפיעי
541 ,ג'סר א-זרקא ,125 ,אלשומריה
541 ,ג'סר א-זרקא ,128 ,אלתמאסיח
541 ,ג'סר א-זרקא ,139 ,אנס בן מאלק
541 ,ג'סר א-זרקא ,149 ,בגדאד
541 ,ג'סר א-זרקא ,131 ,בלאל בן רבאח
541 ,ג'סר א-זרקא ,152 ,ג'בראן ח'ליל ג'בראן
541 ,ג'סר א-זרקא ,146 ,ג'ורת אלסלמון
541 ,ג'סר א-זרקא ,9000 ,ג'סר א-זרקא
541 ,ג'סר א-זרקא ,147 ,חטין
541 ,ג'סר א-זרקא ,120 ,מכה
541 ,ג'סר א-זרקא ,6721 ,מרכז הכפר
541 ,ג'סר א-זרקא ,140 ,סלאח אלדין
541 ,ג'סר א-זרקא ,102 ,עומר בן אלכטאב
541 ,ג'סר א-זרקא ,108 ,עות'מאן בן עפאן
541 ,ג'סר א-זרקא ,134 ,עלי בן אבי טאלב
541 ,ג'סר א-זרקא ,129 ,קריית אלסיאדין
541 ,ג'סר א-זרקא ,114 ,שג'רת אלדור
541 ,ג'סר א-זרקא ,6711 ,שכ אלמוארס
541 ,ג'סר א-זרקא ,6732 ,שכ אלרמל
541 ,ג'סר א-זרקא ,6720 ,שכ אלשומריה
541 ,ג'סר א-זרקא ,6730 ,שכונה דרומית
541 ,ג'סר א-זרקא ,6710 ,שכונה צפונית
487 ,ג'ש (גוש חלב) ,9000 ,ג'ש (גוש חלב)
628 ,ג'ת ,174 ,א-דופלא
628 ,ג'ת ,179 ,א-זאוויה
628 ,ג'ת ,180 ,א-זבדיה
628 ,ג'ת ,185 ,א-זהראוי
628 ,ג'ת ,201 ,א-זהרה
628 ,ג'ת ,202 ,א-זוהור
628 ,ג'ת ,186 ,א-זיתון
628 ,ג'ת ,184 ,א-זנג'ביל
628 ,ג'ת ,181 ,א-זעאריר
628 ,ג'ת ,183 ,א-זערורה
628 ,ג'ת ,182 ,א-זעתר
628 ,ג'ת ,200 ,א-טברי
628 ,ג'ת ,235 ,א-נח'יל
628 ,ג'ת ,234 ,א-נטוש
628 ,ג'ת ,236 ,א-נמלאת
628 ,ג'ת ,198 ,א-ס'ופסאף
628 ,ג'ת ,199 ,א-ס'נובר
628 ,ג'ת ,197 ,א-ס'פא
628 ,ג'ת ,190 ,א-סוידיה
628 ,ג'ת ,188 ,א-סולטאן סולימאן
628 ,ג'ת ,189 ,א-סינדיאן
628 ,ג'ת ,187 ,א-סעאדה
628 ,ג'ת ,177 ,א-רוודה
628 ,ג'ת ,176 ,א-רומאן
628 ,ג'ת ,178 ,א-ריחאן
628 ,ג'ת ,191 ,א-שאפיעי
628 ,ג'ת ,192 ,א-שומר
628 ,ג'ת ,196 ,א-שיח' מחמד א-ס'לאח
628 ,ג'ת ,194 ,א-שיח' סעיד
628 ,ג'ת ,195 ,א-שיח' עבד אללה
628 ,ג'ת ,193 ,א-שיח' רדא
628 ,ג'ת ,156 ,א-תין
628 ,ג'ת ,246 ,אבו א-ד'ר
628 ,ג'ת ,245 ,אבו א-דרדאא
628 ,ג'ת ,134 ,אבו איוב אל-אנס'ארי
628 ,ג'ת ,247 ,אבו בכר א-ס'דיק
628 ,ג'ת ,135 ,אבו ד'ר אל-ר'פארי
628 ,ג'ת ,253 ,אבו הורירה
628 ,ג'ת ,251 ,אבו כאסטרו
628 ,ג'ת ,252 ,אבו מוסא אל-אשערי
628 ,ג'ת ,136 ,אבו סנה
628 ,ג'ת ,137 ,אבו עובידה אל-ג'ראח
628 ,ג'ת ,250 ,אבו פראס אל-חמדני
628 ,ג'ת ,138 ,אבו קאהוק
628 ,ג'ת ,248 ,אבו תמאם
628 ,ג'ת ,121 ,אבן אלאסוד אלדואלי
628 ,ג'ת ,142 ,אדם
628 ,ג'ת ,241 ,אום סלמה
628 ,ג'ת ,143 ,אוסאמה בין זיד
628 ,ג'ת ,140 ,אחמד אבו עסבה
628 ,ג'ת ,139 ,אחמד אמין
628 ,ג'ת ,141 ,אחמד ג'באלי
628 ,ג'ת ,122 ,איבן אל-בח'אר
628 ,ג'ת ,123 ,איבן אל-היתם
628 ,ג'ת ,366 ,איבן בטוטה
628 ,ג'ת ,127 ,איבן זידון
628 ,ג'ת ,125 ,איבן ח'לדון
628 ,ג'ת ,131 ,איבן כת'יר
628 ,ג'ת ,132 ,איבן מנדור
628 ,ג'ת ,129 ,איבן סינא
628 ,ג'ת ,128 ,איבן סירין
628 ,ג'ת ,130 ,איבן עסאכר
628 ,ג'ת ,126 ,איבן רושד
628 ,ג'ת ,243 ,איבראהים אל-ח'ליל
628 ,ג'ת ,144 ,אל-אדריסי
628 ,ג'ת ,146 ,אל-אימאם אל-קורטובי
628 ,ג'ת ,145 ,אל-אקחוואן
628 ,ג'ת ,147 ,אל-ארז
628 ,ג'ת ,365 ,אל-את'ל
628 ,ג'ת ,221 ,אל-בדיה
628 ,ג'ת ,151 ,אל-ביאד
628 ,ג'ת ,152 ,אל-ביאדר
628 ,ג'ת ,153 ,אל-ביאן
628 ,ג'ת ,154 ,אל-ביר א-שרקי
628 ,ג'ת ,155 ,אל-בירוני
628 ,ג'ת ,149 ,אל-בראא
628 ,ג'ת ,148 ,אל-בתאני
628 ,ג'ת ,157 ,אל-ג'ורף
628 ,ג'ת ,160 ,אל-ג'למה
628 ,ג'ת ,161 ,אל-ג'מיזה
628 ,ג'ת ,162 ,אל-ג'נחאת
628 ,ג'ת ,158 ,אל-ג'עדה
628 ,ג'ת ,159 ,אל-ג'עריה
628 ,ג'ת ,237 ,אל-המדאנה
628 ,ג'ת ,239 ,אל-ווערה
628 ,ג'ת ,238 ,אל-וורוד
628 ,ג'ת ,172 ,אל-ח'וארג'
628 ,ג'ת ,173 ,אל-ח'ואריזמי
628 ,ג'ת ,169 ,אל-ח'לה
628 ,ג'ת ,170 ,אל-ח'ליל בין אחמד
628 ,ג'ת ,171 ,אל-ח'נסאא
628 ,ג'ת ,167 ,אל-ח'רב
628 ,ג'ת ,168 ,אל-ח'רוב
628 ,ג'ת ,163 ,אל-חג' יחיא
628 ,ג'ת ,164 ,אל-חיג'אז
628 ,ג'ת ,166 ,אל-חמידייאת
628 ,ג'ת ,165 ,אל-חסן אל-בס'רי
628 ,ג'ת ,240 ,אל-יקטין
628 ,ג'ת ,219 ,אל-כאפור
628 ,ג'ת ,220 ,אל-כירואן
628 ,ג'ת ,222 ,אל-מדינה אל-מנוורה
628 ,ג'ת ,232 ,אל-מוארס
628 ,ג'ת ,224 ,אל-מוס'רארה
628 ,ג'ת ,225 ,אל-מוסלא
628 ,ג'ת ,231 ,אל-מינטקה א-סנאעייה
628 ,ג'ת ,228 ,אל-מיקדאד בין אסווד
628 ,ג'ת ,233 ,אל-מירמיה
628 ,ג'ת ,230 ,אל-מנת'ור
628 ,ג'ת ,223 ,אל-מסק
628 ,ג'ת ,226 ,אל-מעס'רה
628 ,ג'ת ,227 ,אל-מקברה א-שרקייה
628 ,ג'ת ,229 ,אל-מקריזי
628 ,ג'ת ,204 ,אל-עטארין
628 ,ג'ת ,206 ,אל-ענאב
628 ,ג'ת ,207 ,אל-ענבר
628 ,ג'ת ,205 ,אל-עקד
628 ,ג'ת ,213 ,אל-פול
628 ,ג'ת ,210 ,אל-פח'ת'
628 ,ג'ת ,214 ,אל-פיג'ם
628 ,ג'ת ,211 ,אל-פראבי
628 ,ג'ת ,212 ,אל-פרדוס
628 ,ג'ת ,215 ,אל-קוב
628 ,ג'ת ,216 ,אל-קודס
628 ,ג'ת ,218 ,אל-קטאין
628 ,ג'ת ,208 ,אל-ר'זאלי
628 ,ג'ת ,242 ,אנס בין מאליק
628 ,ג'ת ,264 ,ביירות
628 ,ג'ת ,257 ,בילאל בין רבאח
628 ,ג'ת ,265 ,ביסאן
628 ,ג'ת ,262 ,ביר א-סמא
628 ,ג'ת ,261 ,ביר אל-בום
628 ,ג'ת ,263 ,ביר שבטבט
628 ,ג'ת ,259 ,בית אל-מועלם
628 ,ג'ת ,260 ,בית אל-מקדס
628 ,ג'ת ,258 ,בלקיס
628 ,ג'ת ,255 ,בס'רא א-שאם
628 ,ג'ת ,256 ,בר'דאד
628 ,ג'ת ,254 ,בראק א-סוח'נה
628 ,ג'ת ,267 ,ג'מיל נאיף
628 ,ג'ת ,9000 ,ג'ת
628 ,ג'ת ,266 ,ג'ת פדלה
628 ,ג'ת ,6783 ,ג'ת-שכונה דרומית
628 ,ג'ת ,6772 ,גרעין הכפר
628 ,ג'ת ,310 ,ד'הר א-שנאם
628 ,ג'ת ,286 ,ד. עבד א-רחים
628 ,ג'ת ,285 ,ד.דאווד ערו
628 ,ג'ת ,357 ,הוד
628 ,ג'ת ,358 ,וער אל-פאיז
628 ,ג'ת ,628 ,זוהור א-סלאם
628 ,ג'ת ,292 ,זיד בין חארת'ה
628 ,ג'ת ,291 ,זיד בין ת'אבת
628 ,ג'ת ,293 ,זינב בינת ג'חש
628 ,ג'ת ,279 ,ח'אלד בין אל-וליד
628 ,ג'ת ,281 ,ח'ושת אל-ח'רוף
628 ,ג'ת ,282 ,ח'לאיל נעים
628 ,ג'ת ,283 ,ח'לת זיתה
628 ,ג'ת ,284 ,ח'לת סמור
628 ,ג'ת ,278 ,ח'נדלה בין אבי עאמר
628 ,ג'ת ,270 ,חביב בין זיד
628 ,ג'ת ,268 ,חבלת אבו סלאמה
628 ,ג'ת ,269 ,חבלת אבו עלי
628 ,ג'ת ,280 ,חדיג'ה בינת ח'וילד
628 ,ג'ת ,271 ,חודיפה בין אל-ימאן
628 ,ג'ת ,274 ,חיטין
628 ,ג'ת ,276 ,חלימה א-סעדייה
628 ,ג'ת ,277 ,חמזה בן עבד אלמוטלב
628 ,ג'ת ,273 ,חסן בין ת'אבת
628 ,ג'ת ,275 ,חפיסה בינת עמרו
628 ,ג'ת ,272 ,חראא
628 ,ג'ת ,309 ,טה
628 ,ג'ת ,308 ,טלחה בין עוביד אללה
628 ,ג'ת ,359 ,יאסין
628 ,ג'ת ,362 ,יונס
628 ,ג'ת ,361 ,יוסף
628 ,ג'ת ,360 ,יעקוב
628 ,ג'ת ,341 ,כוסיה
628 ,ג'ת ,339 ,כעב בין זוהיר
628 ,ג'ת ,340 ,כעוש
628 ,ג'ת ,342 ,מאלק בין דינאר
628 ,ג'ת ,343 ,מוחמד אל-פאתח
628 ,ג'ת ,353 ,מוס בין נס'יר
628 ,ג'ת ,348 ,מוס'טפא מחמוד
628 ,ג'ת ,354 ,מוסא
628 ,ג'ת ,355 ,מוסא איבן לסיר
628 ,ג'ת ,349 ,מוסאעב בין עומיר
628 ,ג'ת ,350 ,מועד בין ג'בל
628 ,ג'ת ,344 ,מחמד מתולי א-שעראוי
628 ,ג'ת ,351 ,מערוף א-רסאפי
628 ,ג'ת ,352 ,מר'רת דיאב
628 ,ג'ת ,346 ,מרג' איבן עמאר
628 ,ג'ת ,347 ,מרים
628 ,ג'ת ,356 ,נוח
628 ,ג'ת ,294 ,סאחת עזיזה
628 ,ג'ת ,295 ,סאמראא
628 ,ג'ת ,307 ,סוהיב בין סנאן
628 ,ג'ת ,306 ,סלאח א-דין
628 ,ג'ת ,304 ,סלימאן
628 ,ג'ת ,303 ,סלמאן אל-פארסי
628 ,ג'ת ,296 ,סעד בין אבי וקאס
628 ,ג'ת ,298 ,סעד בין מועאד
628 ,ג'ת ,297 ,סעד בין עבאדה
628 ,ג'ת ,299 ,סעיד בין אל-מסיב
628 ,ג'ת ,300 ,סעיד בין ג'וביר
628 ,ג'ת ,301 ,סעיד בין זיד
628 ,ג'ת ,302 ,סעיד בין עאמר
628 ,ג'ת ,313 ,עבאס מחמוד אל-עקאד
628 ,ג'ת ,314 ,עבד א-רחמן בין עוף
628 ,ג'ת ,363 ,עבד אל-ראוף שרקיה
628 ,ג'ת ,318 ,עבד אללה בין עומר
628 ,ג'ת ,316 ,עבד אללה בין רואחה
628 ,ג'ת ,321 ,עדן
628 ,ג'ת ,332 ,עומיר בין סעד
628 ,ג'ת ,329 ,עומר בין אלח'טאב
628 ,ג'ת ,330 ,עומר בן עבד אל-עזיז
628 ,ג'ת ,325 ,עוקבה בן נאפע
628 ,ג'ת ,320 ,עות'מאן בין מוד'עין
628 ,ג'ת ,319 ,עות'מאן בין עפאן
628 ,ג'ת ,334 ,עין חריז
628 ,ג'ת ,333 ,עיסא
628 ,ג'ת ,327 ,עלי בין אבי טאלב
628 ,ג'ת ,328 ,עמאר בין יאסר
628 ,ג'ת ,331 ,עמרו בין אל-עאס'
628 ,ג'ת ,324 ,עסא אלראעי
628 ,ג'ת ,323 ,עסקלאן
628 ,ג'ת ,326 ,עקודת א-דרויש
628 ,ג'ת ,336 ,פאטמה א-זהראא
628 ,ג'ת ,335 ,פארוק אל-באז
628 ,ג'ת ,338 ,קורטובה
628 ,ג'ת ,287 ,ראבעה אל-עדויה
628 ,ג'ת ,288 ,ראס אל-פאיז
628 ,ג'ת ,289 ,ראס חוסין
628 ,ג'ת ,290 ,רג'ב
628 ,ג'ת ,110 ,רח 10
628 ,ג'ת ,120 ,רח 20
628 ,ג'ת ,101 ,רח 1
628 ,ג'ת ,111 ,רח 11
628 ,ג'ת ,102 ,רח 2
628 ,ג'ת ,112 ,רח 12
628 ,ג'ת ,103 ,רח 3
628 ,ג'ת ,113 ,רח 13
628 ,ג'ת ,104 ,רח 4
628 ,ג'ת ,114 ,רח 14
628 ,ג'ת ,105 ,רח 5
628 ,ג'ת ,115 ,רח 15
628 ,ג'ת ,106 ,רח 6
628 ,ג'ת ,116 ,רח 16
628 ,ג'ת ,107 ,רח 7
628 ,ג'ת ,117 ,רח 17
628 ,ג'ת ,108 ,רח 8
628 ,ג'ת ,118 ,רח 18
628 ,ג'ת ,109 ,רח 9
628 ,ג'ת ,119 ,רח 19
628 ,ג'ת ,6504 ,ש ימה
628 ,ג'ת ,6500 ,שכון א
628 ,ג'ת ,6501 ,שכון ב
628 ,ג'ת ,6502 ,שכון ג
628 ,ג'ת ,6503 ,שכון ד
628 ,ג'ת ,6781 ,שכונה מערבית
628 ,ג'ת ,6780 ,שכונה מערבית א
628 ,ג'ת ,6771 ,שכונה צפונית א
628 ,ג'ת ,6770 ,שכונה צפונית ב
872 ,גאולי תימן ,9000 ,גאולי תימן
872 ,גאולי תימן ,6500 ,גאולי תימן(שיכון)
872 ,גאולי תימן ,104 ,האלון
872 ,גאולי תימן ,107 ,הגפן
872 ,גאולי תימן ,102 ,הזית
872 ,גאולי תימן ,106 ,החרוב
872 ,גאולי תימן ,110 ,המייסדים
872 ,גאולי תימן ,103 ,הרימון
872 ,גאולי תימן ,105 ,התאנה
379 ,גאולים ,108 ,אעלה בתמר
379 ,גאולים ,9000 ,גאולים
379 ,גאולים ,113 ,דרך השדות
379 ,גאולים ,111 ,האורן
379 ,גאולים ,112 ,האילן
379 ,גאולים ,115 ,האילנות
379 ,גאולים ,107 ,הגפן
379 ,גאולים ,104 ,הדקל
379 ,גאולים ,110 ,ההדס
379 ,גאולים ,103 ,ההרחבה
379 ,גאולים ,102 ,ההשלמה
379 ,גאולים ,101 ,הותיקים
379 ,גאולים ,114 ,הפרחים
379 ,גאולים ,105 ,השמיניה
379 ,גאולים ,106 ,השקד
379 ,גאולים ,109 ,ככר הזיתים
853 ,גאליה ,121 ,אגוז
853 ,גאליה ,101 ,אלון
853 ,גאליה ,103 ,ארז
853 ,גאליה ,104 ,ברוש
853 ,גאליה ,9000 ,גאליה
853 ,גאליה ,105 ,דולב
853 ,גאליה ,106 ,דקל
853 ,גאליה ,102 ,צאלון
853 ,גאליה ,107 ,רימון
853 ,גאליה ,108 ,תאנה
352 ,גבולות ,9000 ,גבולות
424 ,גבים ,9000 ,גבים
86 ,גבע ,9000 ,גבע
3763 ,גבע בנימין ,112 ,אירוס
3763 ,גבע בנימין ,127 ,אמנון ותמר
3763 ,גבע בנימין ,125 ,אתרוג
3763 ,גבע בנימין ,117 ,בשמת
3763 ,גבע בנימין ,9000 ,גבע בנימין
3763 ,גבע בנימין ,157 ,האגוז
3763 ,גבע בנימין ,159 ,האורן
3763 ,גבע בנימין ,160 ,האלה
3763 ,גבע בנימין ,155 ,הארז
3763 ,גבע בנימין ,158 ,הברוש
3763 ,גבע בנימין ,154 ,הגפן
3763 ,גבע בנימין ,150 ,הזית
3763 ,גבע בנימין ,123 ,החצב
3763 ,גבע בנימין ,124 ,החרצית
3763 ,גבע בנימין ,114 ,הנרקיס
3763 ,גבע בנימין ,161 ,הערבה
3763 ,גבע בנימין ,115 ,הציפורן
3763 ,גבע בנימין ,106 ,הרדוף הנחלים
3763 ,גבע בנימין ,153 ,הרימון
3763 ,גבע בנימין ,156 ,השקד
3763 ,גבע בנימין ,151 ,התאנה
3763 ,גבע בנימין ,152 ,התמר
3763 ,גבע בנימין ,116 ,ורד
3763 ,גבע בנימין ,113 ,חבצלת
3763 ,גבע בנימין ,101 ,יסמין
3763 ,גבע בנימין ,102 ,יקינתון
3763 ,גבע בנימין ,105 ,כלנית
3763 ,גבע בנימין ,107 ,כרכום
3763 ,גבע בנימין ,118 ,לבונה
3763 ,גבע בנימין ,110 ,לילך
3763 ,גבע בנימין ,103 ,נופר
3763 ,גבע בנימין ,121 ,נורית
3763 ,גבע בנימין ,104 ,סביון
3763 ,גבע בנימין ,108 ,סיגלית
3763 ,גבע בנימין ,122 ,עירית
3763 ,גבע בנימין ,111 ,פרג
3763 ,גבע בנימין ,126 ,רוזמרין
3763 ,גבע בנימין ,109 ,רקפת
3763 ,גבע בנימין ,120 ,שד אדם
683 ,גבע כרמל ,9000 ,גבע כרמל
683 ,גבע כרמל ,101 ,דרך האורן
683 ,גבע כרמל ,102 ,דרך האילנות
683 ,גבע כרמל ,103 ,דרך האלון
683 ,גבע כרמל ,104 ,דרך האשל
683 ,גבע כרמל ,105 ,דרך הדקל
683 ,גבע כרמל ,106 ,דרך הדרים
683 ,גבע כרמל ,107 ,דרך הערבה
683 ,גבע כרמל ,108 ,דרך הצאלון
683 ,גבע כרמל ,109 ,דרך הרימון
683 ,גבע כרמל ,111 ,משעול האגוז
683 ,גבע כרמל ,112 ,משעול האשכולית
683 ,גבע כרמל ,113 ,משעול האתרוג
683 ,גבע כרמל ,114 ,משעול הבוסתן
683 ,גבע כרמל ,115 ,משעול הפרדס
683 ,גבע כרמל ,116 ,משעול השקד
683 ,גבע כרמל ,117 ,משעול התמר
683 ,גבע כרמל ,118 ,משעול התפוז
2014 ,גבעולים ,9000 ,גבעולים
3644 ,גבעון החדשה ,116 ,אגוז
3644 ,גבעון החדשה ,113 ,אילן
3644 ,גבעון החדשה ,119 ,אפרסמון
3644 ,גבעון החדשה ,9000 ,גבעון החדשה
3644 ,גבעון החדשה ,125 ,דובדבן
3644 ,גבעון החדשה ,117 ,דולב
3644 ,גבעון החדשה ,101 ,האורן
3644 ,גבעון החדשה ,111 ,האלון
3644 ,גבעון החדשה ,102 ,הגפן
3644 ,גבעון החדשה ,118 ,הדס
3644 ,גבעון החדשה ,126 ,הרותם
3644 ,גבעון החדשה ,104 ,הרימון
3644 ,גבעון החדשה ,108 ,התאנה
3644 ,גבעון החדשה ,115 ,זית
3644 ,גבעון החדשה ,106 ,חרוב
3644 ,גבעון החדשה ,122 ,כליל החורש
3644 ,גבעון החדשה ,109 ,מבוא האלה
3644 ,גבעון החדשה ,112 ,מבוא הארז
3644 ,גבעון החדשה ,124 ,מבוא האשל
3644 ,גבעון החדשה ,127 ,מבוא האתרוג
3644 ,גבעון החדשה ,105 ,מבוא הברוש
3644 ,גבעון החדשה ,114 ,מבוא הדקל
3644 ,גבעון החדשה ,128 ,מבוא הורד
3644 ,גבעון החדשה ,110 ,מבוא הלבנה
3644 ,גבעון החדשה ,129 ,מבוא המילה
3644 ,גבעון החדשה ,130 ,מבוא הערבה
3644 ,גבעון החדשה ,131 ,מבוא הפטל
3644 ,גבעון החדשה ,107 ,מבוא הצבר
3644 ,גבעון החדשה ,132 ,מבוא הצפצפה
3644 ,גבעון החדשה ,133 ,מבוא השיזף
3644 ,גבעון החדשה ,123 ,מבוא השיטה
3644 ,גבעון החדשה ,120 ,מבוא השקד
3644 ,גבעון החדשה ,103 ,מבוא השקמה
3644 ,גבעון החדשה ,121 ,מבוא התומר
1344 ,גבעות בר ,9000 ,גבעות בר
1362 ,גבעות עדן ,9000 ,גבעות עדן
1293 ,גבעת אבני ,107 ,אלון
1293 ,גבעת אבני ,9000 ,גבעת אבני
1293 ,גבעת אבני ,101 ,האורנים
1293 ,גבעת אבני ,104 ,האירוסים
1293 ,גבעת אבני ,106 ,הגפן
1293 ,גבעת אבני ,102 ,הדקלים
1293 ,גבעת אבני ,108 ,היסמין
1293 ,גבעת אבני ,103 ,הנרקיסים
1293 ,גבעת אבני ,105 ,הסביונים
1293 ,גבעת אבני ,109 ,כלניות
1293 ,גבעת אבני ,110 ,מעלה הארזים
1288 ,גבעת אלה ,111 ,אילון
1288 ,גבעת אלה ,101 ,אלונים
1288 ,גבעת אלה ,117 ,בית אורן
1288 ,גבעת אלה ,9000 ,גבעת אלה
1288 ,גבעת אלה ,102 ,גנוסר
1288 ,גבעת אלה ,106 ,דליה
1288 ,גבעת אלה ,110 ,דן
1288 ,גבעת אלה ,109 ,דפנה
1288 ,גבעת אלה ,103 ,הגליל
1288 ,גבעת אלה ,119 ,הגשר
1288 ,גבעת אלה ,107 ,הזורעים
1288 ,גבעת אלה ,120 ,הירדן
1288 ,גבעת אלה ,115 ,הכרמל
1288 ,גבעת אלה ,104 ,העמק
1288 ,גבעת אלה ,105 ,חניתה
1288 ,גבעת אלה ,108 ,מחניים
1288 ,גבעת אלה ,112 ,מסדה
1288 ,גבעת אלה ,116 ,מעיין צבי
1288 ,גבעת אלה ,118 ,נווה איתן
1288 ,גבעת אלה ,113 ,עין גב
1288 ,גבעת אלה ,114 ,שער הגולן
147 ,גבעת ברנר ,9000 ,גבעת ברנר
147 ,גבעת ברנר ,106 ,האלה
147 ,גבעת ברנר ,103 ,האשל
147 ,גבעת ברנר ,101 ,הדקל
147 ,גבעת ברנר ,102 ,השיזף
147 ,גבעת ברנר ,105 ,השיטה
147 ,גבעת ברנר ,100 ,השקד
147 ,גבעת ברנר ,104 ,התאנה
147 ,גבעת ברנר ,6500 ,שכ יחד
870 ,גבעת השלושה ,9000 ,גבעת השלושה
3730 ,גבעת זאב ,350 ,אבני החושן
3730 ,גבעת זאב ,6504 ,אגן האיילות
3730 ,גבעת זאב ,311 ,אדמונית
3730 ,גבעת זאב ,353 ,אודם
3730 ,גבעת זאב ,355 ,אחלמה
3730 ,גבעת זאב ,317 ,אירוס
3730 ,גבעת זאב ,327 ,אמנון ותמר
3730 ,גבעת זאב ,328 ,ארבעת המינים
3730 ,גבעת זאב ,303 ,אתרוג
3730 ,גבעת זאב ,407 ,בארות
3730 ,גבעת זאב ,204 ,בן נון יהושע
3730 ,גבעת זאב ,131 ,בנה ביתך
3730 ,גבעת זאב ,356 ,ברקת
3730 ,גבעת זאב ,402 ,גבע
3730 ,גבעת זאב ,7001 ,גבעון
3730 ,גבעת זאב ,9000 ,גבעת זאב
3730 ,גבעת זאב ,261 ,דוכיפת
3730 ,גבעת זאב ,262 ,דרגות
3730 ,גבעת זאב ,264 ,דרור
3730 ,גבעת זאב ,330 ,האיילות
3730 ,גבעת זאב ,343 ,הארי
3730 ,גבעת זאב ,118 ,הברוש
3730 ,גבעת זאב ,331 ,הגדי
3730 ,גבעת זאב ,301 ,הגיא
3730 ,גבעת זאב ,112 ,הדודאים
3730 ,גבעת זאב ,332 ,הדישון
3730 ,גבעת זאב ,345 ,הזאב
3730 ,גבעת זאב ,104 ,הזית
3730 ,גבעת זאב ,416 ,החליל
3730 ,גבעת זאב ,333 ,היחמור
3730 ,גבעת זאב ,334 ,היעל
3730 ,גבעת זאב ,417 ,הכינור
3730 ,גבעת זאב ,335 ,הכפיר
3730 ,גבעת זאב ,344 ,הלביא
3730 ,גבעת זאב ,101 ,המכבים
3730 ,גבעת זאב ,419 ,הנבל
3730 ,גבעת זאב ,336 ,העופר
3730 ,גבעת זאב ,337 ,הצבי
3730 ,גבעת זאב ,119 ,הר הארבל
3730 ,גבעת זאב ,120 ,הר המוריה
3730 ,גבעת זאב ,7002 ,הר שמואל
3730 ,גבעת זאב ,338 ,הראם
3730 ,גבעת זאב ,108 ,הרדוף
3730 ,גבעת זאב ,418 ,השופר
3730 ,גבעת זאב ,114 ,התומר
3730 ,גבעת זאב ,339 ,התן
3730 ,גבעת זאב ,257 ,זמיר
3730 ,גבעת זאב ,253 ,חבר
3730 ,גבעת זאב ,340 ,חי- בר
3730 ,גבעת זאב ,326 ,חרצית
3730 ,גבעת זאב ,341 ,חתול הבר
3730 ,גבעת זאב ,351 ,יהלום
3730 ,גבעת זאב ,259 ,ייטב
3730 ,גבעת זאב ,110 ,יסמין
3730 ,גבעת זאב ,254 ,יעלים
3730 ,גבעת זאב ,302 ,יקינטון
3730 ,גבעת זאב ,313 ,כלנית
3730 ,גבעת זאב ,411 ,לבונה
3730 ,גבעת זאב ,109 ,לוטם
3730 ,גבעת זאב ,306 ,לולב
3730 ,גבעת זאב ,357 ,לשם
3730 ,גבעת זאב ,105 ,מבוא הגפן
3730 ,גבעת זאב ,305 ,מבוא הדס
3730 ,גבעת זאב ,117 ,מבוא הזיתים
3730 ,גבעת זאב ,107 ,מבוא השמש
3730 ,גבעת זאב ,113 ,מבוא השקמה
3730 ,גבעת זאב ,106 ,מבוא תמר
3730 ,גבעת זאב ,6500 ,מורשת בנימין
3730 ,גבעת זאב ,404 ,מכמש
3730 ,גבעת זאב ,408 ,מצפה
3730 ,גבעת זאב ,310 ,מרגנית
3730 ,גבעת זאב ,6505 ,מרכז הישוב
3730 ,גבעת זאב ,409 ,משעול צוקים
3730 ,גבעת זאב ,6501 ,נווה מנחם
3730 ,גבעת זאב ,358 ,נופך
3730 ,גבעת זאב ,256 ,נחליאלי
3730 ,גבעת זאב ,319 ,סביון
3730 ,גבעת זאב ,252 ,סלעית
3730 ,גבעת זאב ,360 ,ספיר
3730 ,גבעת זאב ,405 ,עלמון
3730 ,גבעת זאב ,202 ,עמק איילון
3730 ,גבעת זאב ,412 ,ענתות
3730 ,גבעת זאב ,410 ,עפרה
3730 ,גבעת זאב ,263 ,עפרוני
3730 ,גבעת זאב ,304 ,ערבה
3730 ,גבעת זאב ,251 ,ערוגות
3730 ,גבעת זאב ,359 ,פטדה
3730 ,גבעת זאב ,325 ,פרחי הבר
3730 ,גבעת זאב ,260 ,פרת
3730 ,גבעת זאב ,250 ,צוקית
3730 ,גבעת זאב ,255 ,קדרון
3730 ,גבעת זאב ,406 ,קרית יערים
3730 ,גבעת זאב ,111 ,רותם
3730 ,גבעת זאב ,415 ,רימון
3730 ,גבעת זאב ,403 ,רמה
3730 ,גבעת זאב ,312 ,רקפת
3730 ,גבעת זאב ,203 ,שאול המלך
3730 ,גבעת זאב ,201 ,שבט בנימין
3730 ,גבעת זאב ,352 ,שוהם
3730 ,גבעת זאב ,342 ,שור הבר
3730 ,גבעת זאב ,115 ,שיטה
3730 ,גבעת זאב ,6502 ,שכ הדר הגבעה
3730 ,גבעת זאב ,6503 ,שכ יהודה ויהודית
3730 ,גבעת זאב ,102 ,שקד
3730 ,גבעת זאב ,103 ,תאנה
3730 ,גבעת זאב ,116 ,תדהר
3730 ,גבעת זאב ,258 ,תור
3730 ,גבעת זאב ,354 ,תרשיש
207 ,גבעת ח"ן ,105 ,ברכת עם
207 ,גבעת ח"ן ,9000 ,גבעת ח"ן
207 ,גבעת ח"ן ,116 ,מעבר לים
207 ,גבעת ח"ן ,108 ,סמטת בשורה
207 ,גבעת ח"ן ,110 ,סמטת חמוטל
207 ,גבעת ח"ן ,109 ,סמטת ילדות
207 ,גבעת ח"ן ,107 ,סמטת צפרירים
207 ,גבעת ח"ן ,115 ,פלוני יש לו
207 ,גבעת ח"ן ,101 ,פעמי אביב
207 ,גבעת ח"ן ,106 ,שירתי
207 ,גבעת ח"ן ,103 ,תרזה יפה
2018 ,גבעת חיים (איחוד) ,9000 ,גבעת חיים (איחוד)
173 ,גבעת חיים (מאוחד) ,9000 ,גבעת חיים (מאוחד)
4021 ,גבעת יואב ,126 ,ביר א-שגום
4021 ,גבעת יואב ,111 ,בית הבד
4021 ,גבעת יואב ,9000 ,גבעת יואב
4021 ,גבעת יואב ,105 ,דרך החלב
4021 ,גבעת יואב ,101 ,דרך המחרשה
4021 ,גבעת יואב ,118 ,דרך תמר
4021 ,גבעת יואב ,103 ,האסם
4021 ,גבעת יואב ,109 ,הבוקרים
4021 ,גבעת יואב ,102 ,הגורן
4021 ,גבעת יואב ,124 ,הגנים
4021 ,גבעת יואב ,104 ,החרמש
4021 ,גבעת יואב ,117 ,היוגב
4021 ,גבעת יואב ,108 ,המגל
4021 ,גבעת יואב ,112 ,המוסקים
4021 ,גבעת יואב ,106 ,המורג
4021 ,גבעת יואב ,120 ,המושבוץ
4021 ,גבעת יואב ,119 ,המייסדים
4021 ,גבעת יואב ,110 ,המסקלים
4021 ,גבעת יואב ,107 ,הנוקד
4021 ,גבעת יואב ,121 ,הצנחנים
4021 ,גבעת יואב ,122 ,התצפית
4021 ,גבעת יואב ,113 ,יצהר
4021 ,גבעת יואב ,123 ,כיכר הדורות
4021 ,גבעת יואב ,114 ,מגדים
4021 ,גבעת יואב ,115 ,קמה
4021 ,גבעת יואב ,116 ,שדמה
4021 ,גבעת יואב ,125 ,שדרת ההתיישבות
787 ,גבעת יערים ,102 ,איילת חן
787 ,גבעת יערים ,112 ,אמלל שיר
787 ,גבעת יערים ,9000 ,גבעת יערים
787 ,גבעת יערים ,111 ,דרך שבזי שלום
787 ,גבעת יערים ,114 ,היושבת בגנים
787 ,גבעת יערים ,110 ,הקדרה
787 ,גבעת יערים ,105 ,הר המור
787 ,גבעת יערים ,107 ,הריקמה
787 ,גבעת יערים ,109 ,לנר ולבשמים
787 ,גבעת יערים ,101 ,מרבד הקסמים
787 ,גבעת יערים ,108 ,על כנפי נשרים
787 ,גבעת יערים ,113 ,ענבל
787 ,גבעת יערים ,104 ,עת דודים
787 ,גבעת יערים ,103 ,פלח הרימון
787 ,גבעת יערים ,106 ,ריח הדס
919 ,גבעת ישעיהו ,9000 ,גבעת ישעיהו
802 ,גבעת כ"ח ,9000 ,גבעת כ"ח
802 ,גבעת כ"ח ,103 ,הגולן
802 ,גבעת כ"ח ,106 ,הגליל
802 ,גבעת כ"ח ,112 ,החרמון
802 ,גבעת כ"ח ,105 ,הירדן
802 ,גבעת כ"ח ,111 ,הכנרת
802 ,גבעת כ"ח ,110 ,הנגב
802 ,גבעת כ"ח ,107 ,הערבה
802 ,גבעת כ"ח ,108 ,השומרון
802 ,גבעת כ"ח ,109 ,השפלה
802 ,גבעת כ"ח ,113 ,התיכון
802 ,גבעת כ"ח ,102 ,לכיש
802 ,גבעת כ"ח ,104 ,סמטת הלבנון
802 ,גבעת כ"ח ,101 ,שדרת השלום
360 ,גבעת ניל"י ,9000 ,גבעת ניל"י
360 ,גבעת ניל"י ,100 ,האנפה
360 ,גבעת ניל"י ,101 ,הדוכיפת
360 ,גבעת ניל"י ,102 ,הדרור
360 ,גבעת ניל"י ,103 ,הנקר
360 ,גבעת ניל"י ,104 ,הסנונית
360 ,גבעת ניל"י ,105 ,העפרוני
360 ,גבעת ניל"י ,106 ,הצופית
360 ,גבעת ניל"י ,107 ,השחף
360 ,גבעת ניל"י ,108 ,השלדג
360 ,גבעת ניל"י ,109 ,התור
703 ,גבעת עוז ,9000 ,גבעת עוז
681 ,גבעת שמואל ,121 ,אהרון
681 ,גבעת שמואל ,222 ,אורי צבי גרינברג
681 ,גבעת שמואל ,139 ,אורנים
681 ,גבעת שמואל ,152 ,אלונים
681 ,גבעת שמואל ,163 ,אלעזר דוד
681 ,גבעת שמואל ,105 ,אפרים
681 ,גבעת שמואל ,153 ,ארזים
681 ,גבעת שמואל ,122 ,ארלוזורוב
681 ,גבעת שמואל ,145 ,אתרוג
681 ,גבעת שמואל ,106 ,בארי
681 ,גבעת שמואל ,220 ,בגין מנחם
681 ,גבעת שמואל ,116 ,ביאליק
681 ,גבעת שמואל ,162 ,בן גוריון
681 ,גבעת שמואל ,146 ,בן יוסף שלמה
681 ,גבעת שמואל ,125 ,בר אילן
681 ,גבעת שמואל ,142 ,בר כוכבא
681 ,גבעת שמואל ,225 ,ברלב חיים
681 ,גבעת שמואל ,109 ,ברנשטיין-כהן
681 ,גבעת שמואל ,9000 ,גבעת שמואל
681 ,גבעת שמואל ,239 ,גולדה מאיר
681 ,גבעת שמואל ,111 ,גוש עציון
681 ,גבעת שמואל ,229 ,דיין משה
681 ,גבעת שמואל ,234 ,דרך המלך
681 ,גבעת שמואל ,237 ,דרך יצחק רבין
681 ,גבעת שמואל ,201 ,הגפן
681 ,גבעת שמואל ,230 ,הולצברג שמחה
681 ,גבעת שמואל ,133 ,הזיתים
681 ,גבעת שמואל ,110 ,הללס שלמה
681 ,גבעת שמואל ,150 ,המכבים
681 ,גבעת שמואל ,227 ,המר זבולון
681 ,גבעת שמואל ,170 ,הנשיא
681 ,גבעת שמואל ,113 ,הסיבים
681 ,גבעת שמואל ,117 ,העבודה
681 ,גבעת שמואל ,132 ,הערבה
681 ,גבעת שמואל ,104 ,הפלמ"ח
681 ,גבעת שמואל ,161 ,הציונות
681 ,גבעת שמואל ,240 ,הרב יהושע יגל
681 ,גבעת שמואל ,169 ,הרב סולם משה
681 ,גבעת שמואל ,138 ,הרב שלמה גורן
681 ,גבעת שמואל ,204 ,הרימון
681 ,גבעת שמואל ,143 ,הרצוג
681 ,גבעת שמואל ,124 ,הרצל
681 ,גבעת שמואל ,157 ,השקד
681 ,גבעת שמואל ,202 ,התאנה
681 ,גבעת שמואל ,156 ,התומר
681 ,גבעת שמואל ,203 ,התמר
681 ,גבעת שמואל ,210 ,ויסמונסקי
681 ,גבעת שמואל ,114 ,ויצמן
681 ,גבעת שמואל ,127 ,ז'בוטינסקי
681 ,גבעת שמואל ,147 ,זאבי רחבעם(גנדי)
681 ,גבעת שמואל ,206 ,חיטמן עוזי
681 ,גבעת שמואל ,141 ,טירן
681 ,גבעת שמואל ,131 ,טרומפלדור
681 ,גבעת שמואל ,103 ,יהודה הלוי
681 ,גבעת שמואל ,226 ,יוני נתניהו
681 ,גבעת שמואל ,238 ,יצחק שמיר
681 ,גבעת שמואל ,208 ,ירושלים
681 ,גבעת שמואל ,155 ,ישיבת בני עקיבא
681 ,גבעת שמואל ,6008 ,ככר אורן
681 ,גבעת שמואל ,6005 ,ככר אשלים
681 ,גבעת שמואל ,6007 ,ככר דן
681 ,גבעת שמואל ,6004 ,ככר הבשור
681 ,גבעת שמואל ,6002 ,ככר ירדן
681 ,גבעת שמואל ,6001 ,ככר פיכמן
681 ,גבעת שמואל ,6006 ,ככר שניר
681 ,גבעת שמואל ,6003 ,ככר תבור
681 ,גבעת שמואל ,112 ,לוחמי הגטאות
681 ,גבעת שמואל ,231 ,לוי אשכול
681 ,גבעת שמואל ,172 ,ליפקין שחק אמנון
681 ,גבעת שמואל ,232 ,לנדאו חיים
681 ,גבעת שמואל ,144 ,מבצע קדש
681 ,גבעת שמואל ,228 ,מודעי יצחק
681 ,גבעת שמואל ,221 ,מוטה גור
681 ,גבעת שמואל ,135 ,מעון אקדמאים
681 ,גבעת שמואל ,118 ,נחום
681 ,גבעת שמואל ,115 ,נימרובר
681 ,גבעת שמואל ,128 ,סירני
681 ,גבעת שמואל ,236 ,סמ בוני אברהם
681 ,גבעת שמואל ,119 ,סמ הרב קוק
681 ,גבעת שמואל ,235 ,סמ זינגר יהודה
681 ,גבעת שמואל ,137 ,סמ יעקב
681 ,גבעת שמואל ,168 ,סמ נח
681 ,גבעת שמואל ,165 ,סמ נחום
681 ,גבעת שמואל ,171 ,סמ קורדובה
681 ,גבעת שמואל ,167 ,סמ רבקה
681 ,גבעת שמואל ,151 ,סמ רחל
681 ,גבעת שמואל ,129 ,סמטת הדס
681 ,גבעת שמואל ,123 ,סנש חנה
681 ,גבעת שמואל ,233 ,עגנון ש"י
681 ,גבעת שמואל ,136 ,עוזיאל
681 ,גבעת שמואל ,223 ,פיקסלר צבי
681 ,גבעת שמואל ,207 ,פנינה קליין
681 ,גבעת שמואל ,130 ,פרץ
681 ,גבעת שמואל ,148 ,קרן היסוד
681 ,גבעת שמואל ,120 ,קרפל ליפא
681 ,גבעת שמואל ,219 ,רזיאל דוד
681 ,גבעת שמואל ,154 ,רחבת אילן
681 ,גבעת שמואל ,166 ,רחבת קרן היסוד
681 ,גבעת שמואל ,134 ,רמב"ם
681 ,גבעת שמואל ,6500 ,רמת אילן
681 ,גבעת שמואל ,6510 ,רמת הדקלים
681 ,גבעת שמואל ,6511 ,רמת הדר
681 ,גבעת שמואל ,224 ,שאול המלך
681 ,גבעת שמואל ,126 ,שד הגבורים
681 ,גבעת שמואל ,101 ,שד העצמאות
681 ,גבעת שמואל ,107 ,שדה יצחק
681 ,גבעת שמואל ,108 ,שטיינברג
681 ,גבעת שמואל ,6501 ,שכ גבעת יהודה
681 ,גבעת שמואל ,6502 ,שכ גיורא
681 ,גבעת שמואל ,6503 ,שכ השחר
681 ,גבעת שמואל ,6508 ,שכ מרכז
681 ,גבעת שמואל ,6505 ,שכ פרדס רובין
681 ,גבעת שמואל ,6506 ,שכ פרדס רוזנבלום
681 ,גבעת שמואל ,6507 ,שכ קרית ישראל
681 ,גבעת שמואל ,205 ,שמר נעמי
681 ,גבעת שמואל ,140 ,שפינדל יונה
566 ,גבעת שמש ,9000 ,גבעת שמש
1077 ,גבעת שפירא ,9000 ,גבעת שפירא
793 ,גבעתי ,9000 ,גבעתי
6300 ,גבעתיים ,412 ,אבני זכרון
6300 ,גבעתיים ,107 ,אחדות העבודה
6300 ,גבעתיים ,620 ,אידמית
6300 ,גבעתיים ,509 ,אילת
6300 ,גבעתיים ,112 ,אלומות
6300 ,גבעתיים ,630 ,אלוף שדה
6300 ,גבעתיים ,206 ,אפנר
6300 ,גבעתיים ,602 ,אפק
6300 ,גבעתיים ,215 ,אצ"ל
6300 ,גבעתיים ,518 ,אריאל
6300 ,גבעתיים ,133 ,אריאל שרון
6300 ,גבעתיים ,313 ,ארלוזורוב
6300 ,גבעתיים ,613 ,ארנון
6300 ,גבעתיים ,109 ,בורוכוב
6300 ,גבעתיים ,121 ,בלוך
6300 ,גבעתיים ,318 ,בן צבי שמעון
6300 ,גבעתיים ,120 ,בן ציון ישראלי
6300 ,גבעתיים ,222 ,בראשית
6300 ,גבעתיים ,305 ,ברדיצ'בסקי
6300 ,גבעתיים ,220 ,ברוך
6300 ,גבעתיים ,128 ,גבולות
6300 ,גבעתיים ,204 ,גבע
6300 ,גבעתיים ,6500 ,גבעת קוזלובסקי
6300 ,גבעתיים ,6505 ,גבעת רמב"ם
6300 ,גבעתיים ,9000 ,גבעתיים
6300 ,גבעתיים ,118 ,גולדשטיין אהרון
6300 ,גבעתיים ,223 ,גולומב
6300 ,גבעתיים ,607 ,גונן
6300 ,גבעתיים ,116 ,גורדון
6300 ,גבעתיים ,402 ,גוש עציון
6300 ,גבעתיים ,131 ,גזית
6300 ,גבעתיים ,209 ,גלבוע
6300 ,גבעתיים ,505 ,גנסין
6300 ,גבעתיים ,135 ,דב
6300 ,גבעתיים ,409 ,דליה
6300 ,גבעתיים ,216 ,דן
6300 ,גבעתיים ,210 ,דרך בן גוריון דוד
6300 ,גבעתיים ,514 ,דרך השלום
6300 ,גבעתיים ,601 ,דרך יצחק רבין
6300 ,גבעתיים ,130 ,הגורן
6300 ,גבעתיים ,517 ,הדגנים
6300 ,גבעתיים ,513 ,ההגנה
6300 ,גבעתיים ,617 ,ההסתדרות
6300 ,גבעתיים ,410 ,הורדים
6300 ,גבעתיים ,614 ,הידיד
6300 ,גבעתיים ,201 ,הכנסת
6300 ,גבעתיים ,403 ,הלמד הא
6300 ,גבעתיים ,609 ,המאבק
6300 ,גבעתיים ,616 ,המאור
6300 ,גבעתיים ,205 ,המבוא
6300 ,גבעתיים ,507 ,המגדל
6300 ,גבעתיים ,114 ,המורד
6300 ,גבעתיים ,401 ,המלבן
6300 ,גבעתיים ,126 ,המעגל
6300 ,גבעתיים ,115 ,המעורר
6300 ,גבעתיים ,312 ,המעין
6300 ,גבעתיים ,211 ,המעלות
6300 ,גבעתיים ,310 ,המפנה
6300 ,גבעתיים ,203 ,המרי
6300 ,גבעתיים ,502 ,הנגבה
6300 ,גבעתיים ,610 ,הנדיב
6300 ,גבעתיים ,105 ,הפועל הצעיר
6300 ,גבעתיים ,411 ,הפלמ"ח
6300 ,גבעתיים ,506 ,הצנחנים
6300 ,גבעתיים ,213 ,הראל
6300 ,גבעתיים ,619 ,הרב צימבר
6300 ,גבעתיים ,622 ,הרצוג
6300 ,גבעתיים ,117 ,השומר
6300 ,גבעתיים ,124 ,השלבים
6300 ,גבעתיים ,122 ,השנים
6300 ,גבעתיים ,407 ,התע"ש
6300 ,גבעתיים ,301 ,ויצמן
6300 ,גבעתיים ,308 ,ז'בוטינסקי
6300 ,גבעתיים ,519 ,ח"ן
6300 ,גבעתיים ,111 ,חניתה
6300 ,גבעתיים ,634 ,טבנקין
6300 ,גבעתיים ,501 ,טיבר
6300 ,גבעתיים ,625 ,יבניאלי
6300 ,גבעתיים ,511 ,יד מרדכי
6300 ,גבעתיים ,636 ,ילדי טהרן
6300 ,גבעתיים ,612 ,ילין דוד
6300 ,גבעתיים ,125 ,יפה נוף
6300 ,גבעתיים ,132 ,יצחק
6300 ,גבעתיים ,302 ,יצחק שדה
6300 ,גבעתיים ,621 ,כורזין
6300 ,גבעתיים ,6001 ,ככר נח
6300 ,גבעתיים ,306 ,כלנית
6300 ,גבעתיים ,218 ,כנרת
6300 ,גבעתיים ,608 ,כף גימל
6300 ,גבעתיים ,101 ,כצנלסון
6300 ,גבעתיים ,137 ,לביא
6300 ,גבעתיים ,520 ,ליא קניג שטולפר
6300 ,גבעתיים ,408 ,לכיש
6300 ,גבעתיים ,611 ,מהרשק
6300 ,גבעתיים ,138 ,מורדי הגיטאות
6300 ,גבעתיים ,605 ,מנורה
6300 ,גבעתיים ,106 ,מנרה
6300 ,גבעתיים ,219 ,מסדה
6300 ,גבעתיים ,221 ,מעוז
6300 ,גבעתיים ,139 ,מעלה בועז
6300 ,גבעתיים ,319 ,מעלה יעקב
6300 ,גבעתיים ,606 ,מצולות ים
6300 ,גבעתיים ,6511 ,מרכז עסקים דרום
6300 ,גבעתיים ,404 ,משגב עם
6300 ,גבעתיים ,627 ,משמר הירדן
6300 ,גבעתיים ,212 ,ניצנה
6300 ,גבעתיים ,637 ,נעמ"ת
6300 ,גבעתיים ,309 ,נרקיס
6300 ,גבעתיים ,510 ,סירני אנצו
6300 ,גבעתיים ,123 ,סירקין
6300 ,גבעתיים ,113 ,סמ אליהו
6300 ,גבעתיים ,208 ,סמ אסירי ציון
6300 ,גבעתיים ,136 ,סמטת נחל
6300 ,גבעתיים ,134 ,סמטת צור
6300 ,גבעתיים ,512 ,סנש
6300 ,גבעתיים ,508 ,עברונה
6300 ,גבעתיים ,207 ,עין גדי
6300 ,גבעתיים ,406 ,עלית הנוער
6300 ,גבעתיים ,618 ,עמישב
6300 ,גבעתיים ,214 ,עמל
6300 ,גבעתיים ,103 ,ערבי נחל
6300 ,גבעתיים ,413 ,ערד
6300 ,גבעתיים ,127 ,פועלי הרכבת
6300 ,גבעתיים ,108 ,פטאי
6300 ,גבעתיים ,315 ,פינברג יעקב
6300 ,גבעתיים ,632 ,צביה
6300 ,גבעתיים ,314 ,צה"ל
6300 ,גבעתיים ,604 ,צפרירים
6300 ,גבעתיים ,631 ,קבוץ גלויות
6300 ,גבעתיים ,320 ,קושניר
6300 ,גבעתיים ,119 ,קלעי
6300 ,גבעתיים ,624 ,קפלנסקי
6300 ,גבעתיים ,104 ,קרית יוסף
6300 ,גבעתיים ,633 ,קרן היסוד
6300 ,גבעתיים ,110 ,קרן קימת לישראל
6300 ,גבעתיים ,603 ,רביבים
6300 ,גבעתיים ,626 ,רודין אלישע
6300 ,גבעתיים ,129 ,רוטברג מאיר
6300 ,גבעתיים ,304 ,רותם
6300 ,גבעתיים ,516 ,רחל
6300 ,גבעתיים ,224 ,ריינס
6300 ,גבעתיים ,515 ,רייק
6300 ,גבעתיים ,503 ,רמב"ם
6300 ,גבעתיים ,225 ,רמה
6300 ,גבעתיים ,504 ,רמז
6300 ,גבעתיים ,307 ,רקפת
6300 ,גבעתיים ,6501 ,ש עובדי קופ"ח
6300 ,גבעתיים ,6502 ,ש קרת
6300 ,גבעתיים ,6503 ,ש רוזנשטין
6300 ,גבעתיים ,6504 ,ש שילר
6300 ,גבעתיים ,317 ,שד אפיקים
6300 ,גבעתיים ,311 ,שדה בוקר
6300 ,גבעתיים ,6513 ,שיכוני דרום
6300 ,גבעתיים ,628 ,שילוב
6300 ,גבעתיים ,202 ,שינקין
6300 ,גבעתיים ,6507 ,שכ ארלוזורוב
6300 ,גבעתיים ,6509 ,שכ בורוכוב
6300 ,גבעתיים ,6512 ,שכ עובדי חברת חשמל
6300 ,גבעתיים ,6510 ,שכ פועלי הרכבת
6300 ,גבעתיים ,6508 ,שכ קרית יוסף
6300 ,גבעתיים ,6506 ,שכ שיינקין
6300 ,גבעתיים ,615 ,שפירא ישראל
6300 ,גבעתיים ,303 ,שר אהרן
6300 ,גבעתיים ,635 ,תפוצות ישראל
342 ,גברעם ,9000 ,גברעם
133 ,גבת ,9000 ,גבת
35 ,גדות ,101 ,בית ראשון במולדת
35 ,גדות ,9000 ,גדות
145 ,גדיש ,9000 ,גדיש
442 ,גדעונה ,9000 ,גדעונה
442 ,גדעונה ,108 ,האירוס
442 ,גדעונה ,111 ,האתרוג
442 ,גדעונה ,103 ,הגלבוע
442 ,גדעונה ,109 ,הגפן
442 ,גדעונה ,114 ,הזית
442 ,גדעונה ,107 ,החצב
442 ,גדעונה ,102 ,המייסדים
442 ,גדעונה ,101 ,המעיין
442 ,גדעונה ,113 ,הרימון
442 ,גדעונה ,112 ,השקד
442 ,גדעונה ,110 ,התאנה
442 ,גדעונה ,104 ,כלנית
442 ,גדעונה ,106 ,נרקיס
442 ,גדעונה ,105 ,רקפת
2550 ,גדרה ,236 ,אבא אחימאיר
2550 ,גדרה ,314 ,אבינדב ירוחם
2550 ,גדרה ,171 ,אגוז
2550 ,גדרה ,370 ,אהובה עוזרי
2550 ,גדרה ,270 ,אודם
2550 ,גדרה ,246 ,אורן
2550 ,גדרה ,6519 ,אזור תעשיה
2550 ,גדרה ,6503 ,אחוזת גדרה
2550 ,גדרה ,283 ,אילות
2550 ,גדרה ,217 ,אירוס
2550 ,גדרה ,250 ,אלה
2550 ,גדרה ,245 ,אלון
2550 ,גדרה ,159 ,אלי כהן
2550 ,גדרה ,256 ,אלמוגן
2550 ,גדרה ,233 ,אלשייך פינק
2550 ,גדרה ,192 ,אם כל חי
2550 ,גדרה ,363 ,אנה פרנק
2550 ,גדרה ,211 ,אעלה בתמר
2550 ,גדרה ,117 ,אפרים
2550 ,גדרה ,347 ,אצ"ג
2550 ,גדרה ,206 ,אצ"ל
2550 ,גדרה ,376 ,ארבע האימהות
2550 ,גדרה ,244 ,ארז
2550 ,גדרה ,204 ,ארקין צבי
2550 ,גדרה ,259 ,אשחר
2550 ,גדרה ,252 ,אשל
2550 ,גדרה ,105 ,אשר
2550 ,גדרה ,6511 ,אתר חפץ חיים
2550 ,גדרה ,251 ,אתרוג
2550 ,גדרה ,147 ,ב אבות למושב
2550 ,גדרה ,234 ,בורג יוסף
2550 ,גדרה ,199 ,בי"ח הרצפלד
2550 ,גדרה ,136 ,ביאליק
2550 ,גדרה ,140 ,בן גפן
2550 ,גדרה ,166 ,בן צבי יצחק
2550 ,גדרה ,161 ,בעלי מלאכה
2550 ,גדרה ,241 ,ברוש
2550 ,גדרה ,266 ,ברקת
2550 ,גדרה ,228 ,ג'מיל דוד
2550 ,גדרה ,9000 ,גדרה
2550 ,גדרה ,6513 ,גדרה הצעירה
2550 ,גדרה ,196 ,גומברג שבתאי
2550 ,גדרה ,303 ,ד"ר הסנר הוגו
2550 ,גדרה ,343 ,ד"ר פון ויזל
2550 ,גדרה ,372 ,דבורה הנביאה
2550 ,גדרה ,374 ,דבורה עומר
2550 ,גדרה ,351 ,דהרי מנחם
2550 ,גדרה ,300 ,דוכיפת
2550 ,גדרה ,257 ,דולב
2550 ,גדרה ,364 ,דונה גרציה נשיא
2550 ,גדרה ,114 ,דן
2550 ,גדרה ,102 ,דנציגר
2550 ,גדרה ,258 ,דפנה
2550 ,גדרה ,242 ,דקל
2550 ,גדרה ,302 ,דרור
2550 ,גדרה ,181 ,דרך ארץ
2550 ,גדרה ,238 ,דרך האילנות
2550 ,גדרה ,321 ,דרך הנחלים
2550 ,גדרה ,212 ,דרך הפרחים
2550 ,גדרה ,284 ,הארבל
2550 ,גדרה ,134 ,הבילוים
2550 ,גדרה ,158 ,הג"א
2550 ,גדרה ,293 ,הגולן
2550 ,גדרה ,182 ,הגורן
2550 ,גדרה ,288 ,הגלבוע
2550 ,גדרה ,273 ,הגליל
2550 ,גדרה ,274 ,הגלעד
2550 ,גדרה ,254 ,הדרים
2550 ,גדרה ,205 ,ההגנה
2550 ,גדרה ,232 ,הולצברג שמחה
2550 ,גדרה ,135 ,הורביץ
2550 ,גדרה ,209 ,הזית
2550 ,גדרה ,157 ,החומה
2550 ,גדרה ,202 ,החרוב
2550 ,גדרה ,275 ,החרמון
2550 ,גדרה ,210 ,היקב
2550 ,גדרה ,220 ,הכורמים
2550 ,גדרה ,277 ,הכרמל
2550 ,גדרה ,320 ,המהרי"ץ
2550 ,גדרה ,353 ,המציל היהודי
2550 ,גדרה ,230 ,המר זבולון
2550 ,גדרה ,281 ,הנגב
2550 ,גדרה ,145 ,הנקין מרדכי
2550 ,גדרה ,165 ,הנשיא
2550 ,גדרה ,154 ,העופרים
2550 ,גדרה ,156 ,העליה
2550 ,גדרה ,290 ,העמקים
2550 ,גדרה ,183 ,העצמאות
2550 ,גדרה ,317 ,הפרדס
2550 ,גדרה ,287 ,הר מרון
2550 ,גדרה ,149 ,הרב גמליאל יוסף
2550 ,גדרה ,126 ,הרב צוברי מאיר
2550 ,גדרה ,179 ,הרב שייבה ישראל
2550 ,גדרה ,119 ,הרב תנעמי
2550 ,גדרה ,349 ,הרב תעיזי יחיא
2550 ,גדרה ,170 ,הרבי מליובאוויטש
2550 ,גדרה ,296 ,הרדוף
2550 ,גדרה ,295 ,הרי אדום
2550 ,גדרה ,278 ,הרי מואב
2550 ,גדרה ,207 ,הרימון
2550 ,גדרה ,318 ,הרמב"ם
2550 ,גדרה ,260 ,הרצוג חיים
2550 ,גדרה ,127 ,הרצל
2550 ,גדרה ,184 ,הרצפלד
2550 ,גדרה ,285 ,השומרון
2550 ,גדרה ,289 ,השפלה
2550 ,גדרה ,282 ,השרון
2550 ,גדרה ,355 ,ויינר ישראל
2550 ,גדרה ,352 ,וייס ולדר
2550 ,גדרה ,113 ,ויצמן
2550 ,גדרה ,141 ,ז'בוטינסקי
2550 ,גדרה ,350 ,זאוברמן זאב
2550 ,גדרה ,125 ,זבולון
2550 ,גדרה ,185 ,זיגמן
2550 ,גדרה ,297 ,זמיר
2550 ,גדרה ,262 ,זרקון
2550 ,גדרה ,186 ,חבקוק
2550 ,גדרה ,219 ,חבקין יצחק
2550 ,גדרה ,172 ,חגי הנביא
2550 ,גדרה ,299 ,חוחית
2550 ,גדרה ,272 ,חושן
2550 ,גדרה ,142 ,חזית הדרום
2550 ,גדרה ,146 ,חזנוב
2550 ,גדרה ,360 ,חנה סנש
2550 ,גדרה ,225 ,חצב
2550 ,גדרה ,306 ,חרצית
2550 ,גדרה ,180 ,י"א חללי מינכן
2550 ,גדרה ,235 ,יגאל אלון
2550 ,גדרה ,112 ,יהודה
2550 ,גדרה ,313 ,יהודה הגדרתי
2550 ,גדרה ,153 ,יהונתן
2550 ,גדרה ,292 ,יהלום
2550 ,גדרה ,173 ,יואל
2550 ,גדרה ,174 ,יונה הנביא
2550 ,גדרה ,276 ,יזרעאל
2550 ,גדרה ,305 ,יסמין
2550 ,גדרה ,375 ,יפה ירקוני
2550 ,גדרה ,222 ,יפה נוף
2550 ,גדרה ,307 ,יקינטון
2550 ,גדרה ,294 ,ירדן
2550 ,גדרה ,239 ,ישעיהו ישראל
2550 ,גדרה ,304 ,ישראל אילן הולצמן
2550 ,גדרה ,124 ,יששכר
2550 ,גדרה ,155 ,כהנוב אליעזר
2550 ,גדרה ,311 ,כחלית
2550 ,גדרה ,223 ,כלנית
2550 ,גדרה ,120 ,כנפי נשרים
2550 ,גדרה ,6500 ,כפר אוריאל
2550 ,גדרה ,6501 ,כפר אליהו
2550 ,גדרה ,122 ,כצנלסון
2550 ,גדרה ,279 ,כרכום
2550 ,גדרה ,361 ,לאה גולדברג
2550 ,גדרה ,6512 ,לב גדרה
2550 ,גדרה ,6522 ,לב המושבה
2550 ,גדרה ,191 ,לובצקי
2550 ,גדרה ,110 ,לוי
2550 ,גדרה ,131 ,לוינסון
2550 ,גדרה ,308 ,לוע הארי
2550 ,גדרה ,144 ,ליבוביץ
2550 ,גדרה ,341 ,ליבנה
2550 ,גדרה ,101 ,לילינבלום
2550 ,גדרה ,215 ,לילך
2550 ,גדרה ,247 ,לרנר מרדכי
2550 ,גדרה ,267 ,לשם
2550 ,גדרה ,128 ,מגינים
2550 ,גדרה ,231 ,מוסינזון יגאל
2550 ,גדרה ,152 ,מוצקין
2550 ,גדרה ,178 ,מורי אהרון צברי
2550 ,גדרה ,7001 ,מחנה מרים
2550 ,גדרה ,193 ,מלר יוסף צבי
2550 ,גדרה ,286 ,מצדה
2550 ,גדרה ,163 ,מרבד הקסמים
2550 ,גדרה ,310 ,מרגנית
2550 ,גדרה ,200 ,משה ויהודית רוזנר
2550 ,גדרה ,164 ,משעול הרש"ש
2550 ,גדרה ,6510 ,נאות גדרה
2550 ,גדרה ,6517 ,נאות ראשונים
2550 ,גדרה ,187 ,נבט משה
2550 ,גדרה ,345 ,נהר שלום
2550 ,גדרה ,175 ,נווה אורנים
2550 ,גדרה ,151 ,נווה שולמית
2550 ,גדרה ,6514 ,נווה שלמה
2550 ,גדרה ,291 ,נוטמן הנס
2550 ,גדרה ,265 ,נופך
2550 ,גדרה ,226 ,נורית
2550 ,גדרה ,176 ,נחום
2550 ,גדרה ,337 ,נחל איילון
2550 ,גדרה ,333 ,נחל ארנון
2550 ,גדרה ,327 ,נחל דן
2550 ,גדרה ,338 ,נחל הבשור
2550 ,גדרה ,328 ,נחל הירמוך
2550 ,גדרה ,323 ,נחל הירקון
2550 ,גדרה ,334 ,נחל ליטאני
2550 ,גדרה ,322 ,נחל לכיש
2550 ,גדרה ,325 ,נחל נעמן
2550 ,גדרה ,336 ,נחל עדה
2550 ,גדרה ,324 ,נחל ערוגות
2550 ,גדרה ,329 ,נחל פארן
2550 ,גדרה ,335 ,נחל צאלים
2550 ,גדרה ,332 ,נחל קדרון
2550 ,גדרה ,330 ,נחל שורק
2550 ,גדרה ,326 ,נחל שניר
2550 ,גדרה ,331 ,נחל תנינים
2550 ,גדרה ,369 ,נחמה ריבלין
2550 ,גדרה ,248 ,נירנברג שניאור
2550 ,גדרה ,115 ,נפתלי
2550 ,גדרה ,214 ,נרקיס
2550 ,גדרה ,227 ,סביון
2550 ,גדרה ,133 ,סברדלוב
2550 ,גדרה ,348 ,סופר שלום
2550 ,גדרה ,316 ,סחלב
2550 ,גדרה ,168 ,סטרומה
2550 ,גדרה ,195 ,סיגלביץ שרה
2550 ,גדרה ,221 ,סמ דרך ארץ
2550 ,גדרה ,160 ,סמ הג"א
2550 ,גדרה ,218 ,סמ לובצקי
2550 ,גדרה ,208 ,סמדר
2550 ,גדרה ,298 ,סנונית
2550 ,גדרה ,116 ,סעידי שמחה
2550 ,גדרה ,271 ,ספיר
2550 ,גדרה ,280 ,עין גדי
2550 ,גדרה ,367 ,עליזה בגין
2550 ,גדרה ,177 ,עמוס
2550 ,גדרה ,368 ,עפרה חזה
2550 ,גדרה ,339 ,ערבה
2550 ,גדרה ,362 ,פולה בן גוריון
2550 ,גדרה ,139 ,פוקס
2550 ,גדרה ,264 ,פטדה
2550 ,גדרה ,132 ,פייבל
2550 ,גדרה ,129 ,פיינברג
2550 ,גדרה ,108 ,פינס
2550 ,גדרה ,342 ,פיקוס
2550 ,גדרה ,269 ,פנינה
2550 ,גדרה ,312 ,פעמונית
2550 ,גדרה ,309 ,פרג
2550 ,גדרה ,229 ,פרחי בר
2550 ,גדרה ,167 ,פרנקל אליהו
2550 ,גדרה ,253 ,צאלה
2550 ,גדרה ,216 ,צבעוני
2550 ,גדרה ,106 ,צברי סעדיה
2550 ,גדרה ,143 ,צוקרמן
2550 ,גדרה ,213 ,ציפורן
2550 ,גדרה ,6515 ,צמרת גדרה
2550 ,גדרה ,188 ,צפניה
2550 ,גדרה ,189 ,קבוץ גלויות
2550 ,גדרה ,249 ,קוסובסקי דניאל
2550 ,גדרה ,340 ,קטלב
2550 ,גדרה ,344 ,קפלינסקי משה
2550 ,גדרה ,198 ,קפלן יעקב מאיר הכהן
2550 ,גדרה ,121 ,קק"ל
2550 ,גדרה ,194 ,קרסיקוב אריה
2550 ,גדרה ,197 ,קרסנר אליעזר
2550 ,גדרה ,111 ,ראובן
2550 ,גדרה ,319 ,רבי יהודה
2550 ,גדרה ,366 ,רונה רמון
2550 ,גדרה ,224 ,רותם
2550 ,גדרה ,354 ,רז אריה
2550 ,גדרה ,346 ,רזיאל דוד
2550 ,גדרה ,123 ,רמז דוד
2550 ,גדרה ,6502 ,ש הנופש
2550 ,גדרה ,6504 ,ש נוה דוד
2550 ,גדרה ,6505 ,ש נוה משה
2550 ,גדרה ,6506 ,ש נוה ציון
2550 ,גדרה ,6507 ,ש סעד
2550 ,גדרה ,6508 ,ש צבא קבע
2550 ,גדרה ,6509 ,ש קטרה
2550 ,גדרה ,263 ,שבו
2550 ,גדרה ,162 ,שבזי
2550 ,גדרה ,104 ,שבטי ישראל
2550 ,גדרה ,169 ,שביל מקורות
2550 ,גדרה ,237 ,שד בגין מנחם
2550 ,גדרה ,201 ,שד בן גוריון
2550 ,גדרה ,240 ,שד ציונה צרפת
2550 ,גדרה ,261 ,שד רבין יצחק
2550 ,גדרה ,268 ,שוהם
2550 ,גדרה ,365 ,שולמית אלוני
2550 ,גדרה ,315 ,שושן
2550 ,גדרה ,373 ,שושנה דמארי
2550 ,גדרה ,255 ,שיטה
2550 ,גדרה ,6523 ,שכ אזורי גדרה
2550 ,גדרה ,6520 ,שכ גבעת העיריות
2550 ,גדרה ,6527 ,שכ גולדה
2550 ,גדרה ,6521 ,שכ המושבה הגרמנית
2550 ,גדרה ,6526 ,שכ הפועלים
2550 ,גדרה ,6518 ,שכ נווה אד"ר
2550 ,גדרה ,6516 ,שכ נווה שולמית
2550 ,גדרה ,6524 ,שכ עמק הנשר
2550 ,גדרה ,6525 ,שכ רשפים
2550 ,גדרה ,130 ,שכביץ
2550 ,גדרה ,301 ,שלדג
2550 ,גדרה ,137 ,שלום עליכם
2550 ,גדרה ,109 ,שמעון
2550 ,גדרה ,150 ,שפירא
2550 ,גדרה ,359 ,שפק שלמה
2550 ,גדרה ,103 ,שפרינצק
2550 ,גדרה ,203 ,שקד
2550 ,גדרה ,243 ,שקמה
2550 ,גדרה ,371 ,שרה אהרונסון
2550 ,גדרה ,118 ,שרעבי מנשה
2550 ,גדרה ,138 ,תרמ"ה
852 ,גונן ,101 ,בית ראשון במולדת
852 ,גונן ,9000 ,גונן
755 ,גורן ,9000 ,גורן
1219 ,גורנות הגליל ,9000 ,גורנות הגליל
457 ,גזית ,101 ,אודם
457 ,גזית ,102 ,ברקת
457 ,גזית ,9000 ,גזית
457 ,גזית ,103 ,טופז
457 ,גזית ,104 ,לשם
457 ,גזית ,105 ,ענבר
457 ,גזית ,106 ,צור
457 ,גזית ,107 ,שוהם
370 ,גזר ,9000 ,גזר
370 ,גזר ,101 ,ש קרוונים
706 ,גיאה ,9000 ,גיאה
196 ,גיבתון ,104 ,בן ישראל
196 ,גיבתון ,102 ,ברזילי
196 ,גיבתון ,9000 ,גיבתון
196 ,גיבתון ,101 ,האם ובניה
196 ,גיבתון ,103 ,המייסדים
196 ,גיבתון ,106 ,העמדות
196 ,גיבתון ,105 ,יהודית
1043 ,גיזו ,9000 ,גיזו
1204 ,גילון ,103 ,אודם
1204 ,גילון ,110 ,אופיר
1204 ,גילון ,114 ,אלמוג
1204 ,גילון ,112 ,בזלת
1204 ,גילון ,102 ,ברקת
1204 ,גילון ,109 ,גזית
1204 ,גילון ,9000 ,גילון
1204 ,גילון ,115 ,גרניט
1204 ,גילון ,104 ,טופז
1204 ,גילון ,113 ,יהלום
1204 ,גילון ,101 ,לשם
1204 ,גילון ,117 ,מצפה
1204 ,גילון ,107 ,ספיר
1204 ,גילון ,105 ,ענבר
1204 ,גילון ,106 ,שוהם
1204 ,גילון ,116 ,שחם
1204 ,גילון ,108 ,שנהב
1204 ,גילון ,111 ,תרשיש
736 ,גילת ,9000 ,גילת
736 ,גילת ,6500 ,שכ קרוונים
262 ,גינוסר ,9000 ,גינוסר
92 ,גיניגר ,108 ,אל היער
92 ,גיניגר ,101 ,בית ראשון במולדת
92 ,גיניגר ,9000 ,גיניגר
92 ,גיניגר ,118 ,דרך הגבעות
92 ,גיניגר ,122 ,דרך הדשאים
92 ,גיניגר ,105 ,דרך החשמל
92 ,גיניגר ,104 ,דרך הכרמים
92 ,גיניגר ,103 ,דרך המפעל
92 ,גיניגר ,102 ,דרך הראשונים
92 ,גיניגר ,106 ,דרך הרפת
92 ,גיניגר ,125 ,האשכולית
92 ,גיניגר ,114 ,החינוך המשותף
92 ,גיניגר ,120 ,הטבעונים
92 ,גיניגר ,128 ,היער המכושף
92 ,גיניגר ,111 ,המקומרים
92 ,גיניגר ,126 ,הפומלית
92 ,גיניגר ,117 ,הפסגה
92 ,גיניגר ,115 ,הצעירים
92 ,גיניגר ,124 ,השכונה הדרומית
92 ,גיניגר ,127 ,התפוז
92 ,גיניגר ,123 ,חלוצי דגניה ג'
92 ,גיניגר ,112 ,לינה כפרית
92 ,גיניגר ,119 ,משמר הוולגה
92 ,גיניגר ,110 ,עלי גבעה
92 ,גיניגר ,116 ,שביל האולפן
92 ,גיניגר ,107 ,שדרת הדקלים
92 ,גיניגר ,113 ,שדרת הזיתים
92 ,גיניגר ,109 ,שיכון ל
92 ,גיניגר ,121 ,שכונת הדר
863 ,גינתון ,9000 ,גינתון
863 ,גינתון ,115 ,האגוז
863 ,גינתון ,103 ,האורן
863 ,גינתון ,109 ,האלה
863 ,גינתון ,104 ,הארז
863 ,גינתון ,102 ,הברוש
863 ,גינתון ,105 ,הגנים
863 ,גינתון ,110 ,הדולב
863 ,גינתון ,111 ,הדקל
863 ,גינתון ,106 ,הזית
863 ,גינתון ,101 ,החרוב
863 ,גינתון ,116 ,הפקאן
863 ,גינתון ,112 ,הצאלון
863 ,גינתון ,107 ,הרימון
863 ,גינתון ,113 ,השקמה
863 ,גינתון ,108 ,התאנה
863 ,גינתון ,114 ,התמר
1206 ,גיתה ,9000 ,גיתה
3613 ,גיתית ,9000 ,גיתית
393 ,גלאון ,9000 ,גלאון
3606 ,גלגל ,9000 ,גלגל
346 ,גליל ים ,9000 ,גליל ים
369 ,גלעד (אבן יצחק) ,9000 ,גלעד (אבן יצחק)
745 ,גמזו ,9000 ,גמזו
745 ,גמזו ,103 ,האלון
745 ,גמזו ,102 ,הברוש
745 ,גמזו ,104 ,הגפן
745 ,גמזו ,107 ,הדס
745 ,גמזו ,105 ,הדקל
745 ,גמזו ,101 ,התאנה
745 ,גמזו ,106 ,מעלות יהונתן
1072 ,גן הדרום ,9000 ,גן הדרום
1072 ,גן הדרום ,102 ,הגפן
1072 ,גן הדרום ,103 ,הזית
1072 ,גן הדרום ,104 ,הרימון
1072 ,גן הדרום ,105 ,התאנה
1072 ,גן הדרום ,101 ,התמר
225 ,גן השומרון ,9000 ,גן השומרון
225 ,גן השומרון ,101 ,האורן
225 ,גן השומרון ,102 ,האקליפטוס
225 ,גן השומרון ,103 ,האשכולית
225 ,גן השומרון ,104 ,הברוש
225 ,גן השומרון ,125 ,הבריכה
225 ,גן השומרון ,105 ,הדקל
225 ,גן השומרון ,106 ,החלוצים 1934
225 ,גן השומרון ,108 ,המדגרה
225 ,גן השומרון ,109 ,המסיק
225 ,גן השומרון ,111 ,הפרדס
225 ,גן השומרון ,112 ,הקציר
225 ,גן השומרון ,113 ,התפוז
225 ,גן השומרון ,114 ,כביש 6403
225 ,גן השומרון ,115 ,כליל החורש
225 ,גן השומרון ,116 ,כרמי זיתים
225 ,גן השומרון ,117 ,סמ האלונים
225 ,גן השומרון ,118 ,סמ החרוב
225 ,גן השומרון ,120 ,סמ המחלבה
225 ,גן השומרון ,121 ,סמ הפיקוס
225 ,גן השומרון ,122 ,עובדי אדמה
225 ,גן השומרון ,124 ,פינלנד
225 ,גן השומרון ,123 ,פרחי הדר
239 ,גן חיים ,9000 ,גן חיים
239 ,גן חיים ,111 ,דרך המלך
239 ,גן חיים ,107 ,האיריס
239 ,גן חיים ,101 ,האלה
239 ,גן חיים ,114 ,הגפן
239 ,גן חיים ,102 ,הזית
239 ,גן חיים ,104 ,החרוב
239 ,גן חיים ,106 ,הכלנית
239 ,גן חיים ,112 ,הנרקיס
239 ,גן חיים ,103 ,הערבה
239 ,גן חיים ,110 ,הראשונים
239 ,גן חיים ,105 ,הרימון
239 ,גן חיים ,108 ,הרקפת
239 ,גן חיים ,115 ,השושן
239 ,גן חיים ,116 ,התאנה
239 ,גן חיים ,109 ,התמר
239 ,גן חיים ,113 ,נורית
734 ,גן יאשיה ,9000 ,גן יאשיה
734 ,גן יאשיה ,104 ,דרך הפרדס
734 ,גן יאשיה ,102 ,האנפה
734 ,גן יאשיה ,109 ,הגיא
734 ,גן יאשיה ,111 ,הגנים
734 ,גן יאשיה ,107 ,הגפן
734 ,גן יאשיה ,105 ,הזית
734 ,גן יאשיה ,110 ,החורשה
734 ,גן יאשיה ,112 ,החיטה
734 ,גן יאשיה ,101 ,המייסדים
734 ,גן יאשיה ,114 ,הרימון
734 ,גן יאשיה ,113 ,השדה
734 ,גן יאשיה ,108 ,השקד
734 ,גן יאשיה ,106 ,נחל ראובן
734 ,גן יאשיה ,103 ,שדרת האקליפטוס
166 ,גן יבנה ,288 ,א. צ. גרינברג
166 ,גן יבנה ,135 ,אביר יעקב
166 ,גן יבנה ,246 ,אגדת דשא
166 ,גן יבנה ,181 ,אגוז
166 ,גן יבנה ,107 ,אדמונית
166 ,גן יבנה ,134 ,אהוד בן גרא
166 ,גן יבנה ,109 ,אודם
166 ,גן יבנה ,156 ,אורנית
166 ,גן יבנה ,6520 ,אזור התעשיה
166 ,גן יבנה ,284 ,אחד העם
166 ,גן יבנה ,101 ,אחוזה
166 ,גן יבנה ,212 ,איילון
166 ,גן יבנה ,308 ,איריס
166 ,גן יבנה ,314 ,אלון
166 ,גן יבנה ,256 ,אלופי צה"ל
166 ,גן יבנה ,144 ,אלי כהן
166 ,גן יבנה ,221 ,אלכסנדר
166 ,גן יבנה ,356 ,אלעזר
166 ,גן יבנה ,225 ,אלף נשיקות
166 ,גן יבנה ,171 ,אלתרמן נתן
166 ,גן יבנה ,226 ,אמא אדמה
166 ,גן יבנה ,127 ,אצ"ל
166 ,גן יבנה ,253 ,ארבל
166 ,גן יבנה ,258 ,ארגמן
166 ,גן יבנה ,159 ,אשכול
166 ,גן יבנה ,160 ,אתרוג
166 ,גן יבנה ,283 ,בגין מנחם
166 ,גן יבנה ,286 ,ביאליק
166 ,גן יבנה ,155 ,בלנקשטיין מורלי
166 ,גן יבנה ,154 ,בן גוריון
166 ,גן יבנה ,131 ,בר יוחאי
166 ,גן יבנה ,350 ,בר כוכבא
166 ,גן יבנה ,234 ,ברקאי
166 ,גן יבנה ,255 ,ברקת
166 ,גן יבנה ,232 ,בשור
166 ,גן יבנה ,6514 ,גבעת הסביונים
166 ,גן יבנה ,285 ,גולדברג לאה
166 ,גן יבנה ,250 ,גולן
166 ,גן יבנה ,129 ,גיבורים
166 ,גן יבנה ,251 ,גלבוע
166 ,גן יבנה ,227 ,גן השקמים
166 ,גן יבנה ,9000 ,גן יבנה
166 ,גן יבנה ,102 ,ד"ר שמעון היימן
166 ,גן יבנה ,133 ,דבורה הנביאה
166 ,גן יבנה ,197 ,דליה
166 ,גן יבנה ,236 ,דלתון
166 ,גן יבנה ,220 ,דרך יצחק רבין
166 ,גן יבנה ,261 ,דרך ירושלים
166 ,גן יבנה ,187 ,דרך קנדה
166 ,גן יבנה ,260 ,דרך שתולים
166 ,גן יבנה ,358 ,האור
166 ,גן יבנה ,157 ,האורן
166 ,גן יבנה ,223 ,האלה
166 ,גן יבנה ,377 ,האנפה
166 ,גן יבנה ,164 ,האקליפטוס
166 ,גן יבנה ,185 ,האשל
166 ,גן יבנה ,280 ,הבר
166 ,גן יבנה ,183 ,הברוש
166 ,גן יבנה ,147 ,הגאולה
166 ,גן יבנה ,202 ,הגפן
166 ,גן יבנה ,295 ,הדגן
166 ,גן יבנה ,204 ,הדס
166 ,גן יבנה ,303 ,הדקל
166 ,גן יבנה ,205 ,הדרים
166 ,גן יבנה ,118 ,ההגנה
166 ,גן יבנה ,368 ,הודיה
166 ,גן יבנה ,179 ,הורד
166 ,גן יבנה ,296 ,הזורע
166 ,גן יבנה ,289 ,החיטה
166 ,גן יבנה ,299 ,החמניה
166 ,גן יבנה ,103 ,החצב
166 ,גן יבנה ,297 ,החציר
166 ,גן יבנה ,184 ,החרוב
166 ,גן יבנה ,106 ,החרצית
166 ,גן יבנה ,196 ,הטייסים
166 ,גן יבנה ,279 ,הטל
166 ,גן יבנה ,278 ,היורה
166 ,גן יבנה ,148 ,הישועה
166 ,גן יבנה ,367 ,הכד
166 ,גן יבנה ,195 ,הכישור
166 ,גן יבנה ,123 ,המגינים
166 ,גן יבנה ,291 ,המגל
166 ,גן יבנה ,369 ,המגן
166 ,גן יבנה ,298 ,המורג
166 ,גן יבנה ,186 ,המכבים
166 ,גן יבנה ,140 ,המלך דוד
166 ,גן יבנה ,141 ,המלך שלמה
166 ,גן יבנה ,272 ,המלקוש
166 ,גן יבנה ,194 ,המנור
166 ,גן יבנה ,217 ,המפרש
166 ,גן יבנה ,214 ,המשוט
166 ,גן יבנה ,287 ,המשוררת רחל
166 ,גן יבנה ,166 ,הנביאים
166 ,גן יבנה ,366 ,הנס
166 ,גן יבנה ,136 ,הנצחון
166 ,גן יבנה ,189 ,הנרקיס
166 ,גן יבנה ,115 ,הנשיא
166 ,גן יבנה ,190 ,הסביון
166 ,גן יבנה ,176 ,הסייפן
166 ,גן יבנה ,167 ,הסיירים
166 ,גן יבנה ,216 ,העוגן
166 ,גן יבנה ,199 ,העמל
166 ,גן יבנה ,116 ,העצמאות
166 ,גן יבנה ,311 ,הערבה
166 ,גן יבנה ,293 ,הערוגות
166 ,גן יבנה ,200 ,הפלדה
166 ,גן יבנה ,193 ,הפלך
166 ,גן יבנה ,119 ,הפלמ"ח
166 ,גן יבנה ,240 ,הפרח בגני
166 ,גן יבנה ,188 ,הצפורן
166 ,גן יבנה ,173 ,הקמה
166 ,גן יבנה ,281 ,הקציר
166 ,גן יבנה ,192 ,הר הצופים
166 ,גן יבנה ,138 ,הר סיני
166 ,גן יבנה ,145 ,הר ציון
166 ,גן יבנה ,114 ,הראשונים
166 ,גן יבנה ,122 ,הרב הרצוג
166 ,גן יבנה ,121 ,הרב קוק
166 ,גן יבנה ,294 ,הרגב
166 ,גן יבנה ,270 ,הרמב"ם
166 ,גן יבנה ,228 ,הרעות
166 ,גן יבנה ,126 ,הרצל
166 ,גן יבנה ,191 ,הרקפת
166 ,גן יבנה ,113 ,השומר
166 ,גן יבנה ,130 ,השופטים
166 ,גן יבנה ,177 ,השושן
166 ,גן יבנה ,290 ,השיבולים
166 ,גן יבנה ,128 ,השיטה
166 ,גן יבנה ,150 ,השניים
166 ,גן יבנה ,172 ,השעורה
166 ,גן יבנה ,370 ,התבור
166 ,גן יבנה ,215 ,התורן
166 ,גן יבנה ,292 ,התלמים
166 ,גן יבנה ,301 ,ויניפג
166 ,גן יבנה ,158 ,זיו
166 ,גן יבנה ,375 ,זמיר
166 ,גן יבנה ,218 ,חבר
166 ,גן יבנה ,244 ,חופים
166 ,גן יבנה ,275 ,חופית
166 ,גן יבנה ,208 ,חיים ויצמן
166 ,גן יבנה ,239 ,חלמיש
166 ,גן יבנה ,257 ,חמדה
166 ,גן יבנה ,6006 ,חסידי אומות עולם
166 ,גן יבנה ,249 ,חרמון
166 ,גן יבנה ,362 ,חשמונאים
166 ,גן יבנה ,277 ,יבולים
166 ,גן יבנה ,146 ,יבנאי יוסף
166 ,גן יבנה ,360 ,יהודה
166 ,גן יבנה ,271 ,יהודה הלוי
166 ,גן יבנה ,352 ,יהודית
166 ,גן יבנה ,198 ,יהלום
166 ,גן יבנה ,351 ,יוחנן
166 ,גן יבנה ,354 ,יונתן
166 ,גן יבנה ,353 ,ינאי
166 ,גן יבנה ,309 ,יסמין
166 ,גן יבנה ,230 ,יעלים
166 ,גן יבנה ,152 ,יפתח
166 ,גן יבנה ,161 ,יקותיאל אדם
166 ,גן יבנה ,312 ,יקינטון
166 ,גן יבנה ,210 ,ירקון
166 ,גן יבנה ,313 ,ישראל שטיין
166 ,גן יבנה ,6000 ,כיכר הבנים
166 ,גן יבנה ,6003 ,ככר הרצל
166 ,גן יבנה ,6002 ,ככר זמיר
166 ,גן יבנה ,6004 ,ככר מירון
166 ,גן יבנה ,6001 ,ככר מלכי ישראל
166 ,גן יבנה ,6005 ,ככר עצמאות
166 ,גן יבנה ,310 ,כלנית
166 ,גן יבנה ,104 ,כרכום
166 ,גן יבנה ,273 ,כרמים
166 ,גן יבנה ,252 ,כרמל
166 ,גן יבנה ,125 ,לח"י
166 ,גן יבנה ,307 ,לילך
166 ,גן יבנה ,206 ,לימון
166 ,גן יבנה ,178 ,לימור
166 ,גן יבנה ,357 ,לפיד
166 ,גן יבנה ,274 ,מגדים
166 ,גן יבנה ,365 ,מודיעין
166 ,גן יבנה ,238 ,מור
166 ,גן יבנה ,254 ,מוריה
166 ,גן יבנה ,139 ,מירון
166 ,גן יבנה ,363 ,מצדה
166 ,גן יבנה ,105 ,מרגנית
166 ,גן יבנה ,364 ,מרים
166 ,גן יבנה ,241 ,מתנות קטנות
166 ,גן יבנה ,361 ,מתתיהו
166 ,גן יבנה ,6518 ,נאות אשכול
166 ,גן יבנה ,6512 ,נאות המכבים
166 ,גן יבנה ,6519 ,נאות הפרחים
166 ,גן יבנה ,6516 ,נאות יבנאי
166 ,גן יבנה ,6513 ,נווה לימור
166 ,גן יבנה ,305 ,נורית
166 ,גן יבנה ,117 ,ניל"י
166 ,גן יבנה ,233 ,ניצנה
166 ,גן יבנה ,276 ,סמ התור
166 ,גן יבנה ,168 ,סמ התותחנים
166 ,גן יבנה ,207 ,סמ חטיבת גולני
166 ,גן יבנה ,111 ,ספיר
166 ,גן יבנה ,282 ,עגנון
166 ,גן יבנה ,151 ,עולי הגרדום
166 ,גן יבנה ,224 ,עופרה
166 ,גן יבנה ,231 ,עופרים
166 ,גן יבנה ,174 ,עמיחי יהודה
166 ,גן יבנה ,376 ,עפרוני
166 ,גן יבנה ,235 ,פולג
166 ,גן יבנה ,306 ,פעמונית
166 ,גן יבנה ,229 ,פרי גנך
166 ,גן יבנה ,219 ,צאלים
166 ,גן יבנה ,180 ,צה"ל
166 ,גן יבנה ,304 ,צוללים
166 ,גן יבנה ,259 ,צור
166 ,גן יבנה ,242 ,צליל מיתר
166 ,גן יבנה ,243 ,צמח בר
166 ,גן יבנה ,170 ,צנחנים
166 ,גן יבנה ,124 ,קבוץ גלויות
166 ,גן יבנה ,222 ,קדרון
166 ,גן יבנה ,209 ,קישון
166 ,גן יבנה ,142 ,רבי עקיבא
166 ,גן יבנה ,237 ,רביבים
166 ,גן יבנה ,213 ,רותם
166 ,גן יבנה ,163 ,רימון
166 ,גן יבנה ,6501 ,ש חדש
166 ,גן יבנה ,6515 ,ש נווה גן
166 ,גן יבנה ,6502 ,ש נווה עובד
166 ,גן יבנה ,6503 ,ש עובדים
166 ,גן יבנה ,6504 ,ש עמידר
166 ,גן יבנה ,132 ,שאול המלך
166 ,גן יבנה ,112 ,שבו
166 ,גן יבנה ,153 ,שבזי
166 ,גן יבנה ,110 ,שוהם
166 ,גן יבנה ,211 ,שורק
166 ,גן יבנה ,248 ,שזפון
166 ,גן יבנה ,378 ,שחף
166 ,גן יבנה ,165 ,שייטת
166 ,גן יבנה ,245 ,שיר השירים
166 ,גן יבנה ,6505 ,שכ ב סוכנות
166 ,גן יבנה ,6511 ,שכ גבעת שמשון
166 ,גן יבנה ,6506 ,שכ הותיקים
166 ,גן יבנה ,6507 ,שכ המושבה
166 ,גן יבנה ,6517 ,שכ מוטה גור
166 ,גן יבנה ,6508 ,שכ נאות הדרים
166 ,גן יבנה ,6509 ,שכ פועלים
166 ,גן יבנה ,6510 ,שכ שוטרים
166 ,גן יבנה ,379 ,שלדג
166 ,גן יבנה ,355 ,שלומציון
166 ,גן יבנה ,175 ,שליין זלמן
166 ,גן יבנה ,143 ,שמואל הנביא
166 ,גן יבנה ,359 ,שמעון
166 ,גן יבנה ,300 ,שמר נעמי
166 ,גן יבנה ,162 ,שקמה
166 ,גן יבנה ,169 ,שריון
166 ,גן יבנה ,302 ,תאנה
166 ,גן יבנה ,120 ,תורה ועבודה
166 ,גן יבנה ,149 ,תל חי
166 ,גן יבנה ,203 ,תמר
166 ,גן יבנה ,201 ,תפוח
166 ,גן יבנה ,108 ,תרשיש
1274 ,גן נר ,116 ,אורן
1274 ,גן נר ,101 ,אירוס
1274 ,גן נר ,115 ,ברוש
1274 ,גן נר ,9000 ,גן נר
1274 ,גן נר ,105 ,האלה
1274 ,גן נר ,117 ,הרב נורי דוד
1274 ,גן נר ,110 ,השקד
1274 ,גן נר ,121 ,חבצלת
1274 ,גן נר ,106 ,חצב
1274 ,גן נר ,126 ,חרצית
1274 ,גן נר ,118 ,יסמין
1274 ,גן נר ,122 ,יערה
1274 ,גן נר ,123 ,כלנית
1274 ,גן נר ,124 ,כרכום
1274 ,גן נר ,102 ,לוטם
1274 ,גן נר ,103 ,לילך
1274 ,גן נר ,111 ,נופר
1274 ,גן נר ,112 ,נורית
1274 ,גן נר ,113 ,נרקיס
1274 ,גן נר ,114 ,סביון
1274 ,גן נר ,107 ,סחלב
1274 ,גן נר ,108 ,סייפן
1274 ,גן נר ,125 ,צבעוני
1274 ,גן נר ,120 ,רקפת
1274 ,גן נר ,127 ,תמר
311 ,גן שורק ,9000 ,גן שורק
144 ,גן שלמה ,9000 ,גן שלמה
72 ,גן שמואל ,9000 ,גן שמואל
836 ,גנות ,9000 ,גנות
836 ,גנות ,101 ,האשל
836 ,גנות ,102 ,האתרוג
836 ,גנות ,103 ,הגפן
836 ,גנות ,104 ,ההדס
836 ,גנות ,105 ,הזית
836 ,גנות ,106 ,הערבה
836 ,גנות ,107 ,הפרדס
836 ,גנות ,108 ,הרימון
836 ,גנות ,109 ,התאנה
836 ,גנות ,110 ,התומר
549 ,גנות הדר ,101 ,אמנון ותמר
549 ,גנות הדר ,9000 ,גנות הדר
549 ,גנות הדר ,102 ,האדמונית
549 ,גנות הדר ,103 ,האירוסים
549 ,גנות הדר ,120 ,הבסמת
549 ,גנות הדר ,104 ,הדגניות
549 ,גנות הדר ,121 ,ההדס
549 ,גנות הדר ,105 ,ההרדופים
549 ,גנות הדר ,106 ,החמניות
549 ,גנות הדר ,107 ,החצב
549 ,גנות הדר ,108 ,היסמין
549 ,גנות הדר ,109 ,היקינטון
549 ,גנות הדר ,110 ,הלילך
549 ,גנות הדר ,111 ,הנורית
549 ,גנות הדר ,112 ,הנרקיסים
549 ,גנות הדר ,113 ,הסחלב
549 ,גנות הדר ,114 ,הסיגליות
549 ,גנות הדר ,115 ,הסייפן
549 ,גנות הדר ,116 ,הסתוונית
549 ,גנות הדר ,117 ,הצבעוני
549 ,גנות הדר ,118 ,הרקפות
549 ,גנות הדר ,119 ,השושנים
1103 ,גני הדר ,9000 ,גני הדר
1103 ,גני הדר ,101 ,האורן
1103 ,גני הדר ,104 ,האתרוג
1103 ,גני הדר ,102 ,ההדרים
1103 ,גני הדר ,103 ,הפרדס
1103 ,גני הדר ,108 ,התפוז
1103 ,גני הדר ,105 ,סמטת האשכולית
1103 ,גני הדר ,106 ,סמטת הלימון
1103 ,גני הדר ,107 ,סמטת הפומלית
1371 ,גני טל ,105 ,אלמוג
1371 ,גני טל ,9000 ,גני טל
1371 ,גני טל ,106 ,גפן
1371 ,גני טל ,109 ,האגם
1371 ,גני טל ,108 ,החווה
1371 ,גני טל ,110 ,המדרשה
1371 ,גני טל ,102 ,הפגודה
1371 ,גני טל ,104 ,ורד
1371 ,גני טל ,112 ,חוף הדקלים
1371 ,גני טל ,107 ,יסמין
1371 ,גני טל ,6001 ,ככר המייסדים
1371 ,גני טל ,111 ,נאות קטיף
1371 ,גני טל ,103 ,נוריה
1371 ,גני טל ,101 ,תפוח
862 ,גני יוחנן ,9000 ,גני יוחנן
862 ,גני יוחנן ,103 ,ההדס
862 ,גני יוחנן ,106 ,הורד
862 ,גני יוחנן ,102 ,היסמין
862 ,גני יוחנן ,108 ,הכלנית
862 ,גני יוחנן ,105 ,הנרקיס
862 ,גני יוחנן ,107 ,הסביון
862 ,גני יוחנן ,104 ,הצבעוני
862 ,גני יוחנן ,101 ,הרקפת
3823 ,גני מודיעין ,103 ,אבי דוד
3823 ,גני מודיעין ,126 ,אמרי בינה
3823 ,גני מודיעין ,118 ,בית יוסף
3823 ,גני מודיעין ,116 ,בן איש חי
3823 ,גני מודיעין ,119 ,בני בינה
3823 ,גני מודיעין ,9000 ,גני מודיעין
3823 ,גני מודיעין ,117 ,דברות משה
3823 ,גני מודיעין ,125 ,דעת תבונה
3823 ,גני מודיעין ,127 ,דרך חיים
3823 ,גני מודיעין ,110 ,הרמב"ם
3823 ,גני מודיעין ,101 ,טורי זהב
3823 ,גני מודיעין ,111 ,יערות דבש
3823 ,גני מודיעין ,107 ,כף חיים
3823 ,גני מודיעין ,123 ,מהר"י קאפח
3823 ,גני מודיעין ,114 ,מהרי"ץ
3823 ,גני מודיעין ,124 ,נחל מודיעין
3823 ,גני מודיעין ,112 ,פרי מגדים
3823 ,גני מודיעין ,121 ,קדושת הלוי
3823 ,גני מודיעין ,122 ,קול יהודה
3823 ,גני מודיעין ,113 ,רמ"א
3823 ,גני מודיעין ,115 ,רש"י
3823 ,גני מודיעין ,109 ,שבזי
3823 ,גני מודיעין ,6500 ,שכ גני מודיעין
3823 ,גני מודיעין ,104 ,שפת כהן
3823 ,גני מודיעין ,102 ,שתילי זהב
3823 ,גני מודיעין ,120 ,שתילי זיתים
218 ,גני עם ,111 ,אתרוג
218 ,גני עם ,105 ,ביער
218 ,גני עם ,104 ,בית העם
218 ,גני עם ,9000 ,גני עם
218 ,גני עם ,110 ,דרך הים
218 ,גני עם ,106 ,האקליפטוס
218 ,גני עם ,101 ,הבנים
218 ,גני עם ,102 ,הצבעוני
218 ,גני עם ,112 ,התות
218 ,גני עם ,107 ,יפה נוף
218 ,גני עם ,109 ,ירוק עד
218 ,גני עם ,103 ,סביונים
218 ,גני עם ,108 ,שירת השחרור
229 ,גני תקווה ,125 ,בארי
229 ,גני תקווה ,118 ,בית שאן
229 ,גני תקווה ,153 ,בקעת הירדן
229 ,גני תקווה ,106 ,גבעון
229 ,גני תקווה ,6500 ,גבעת סביון
229 ,גני תקווה ,6507 ,גבעת סביון החדשה
229 ,גני תקווה ,9000 ,גני תקווה
229 ,גני תקווה ,109 ,דגניה
229 ,גני תקווה ,161 ,דרך אילות
229 ,גני תקווה ,162 ,דרך האגמים
229 ,גני תקווה ,140 ,דרך הבשמים
229 ,גני תקווה ,136 ,דרך ההדרים
229 ,גני תקווה ,163 ,דרך הים
229 ,גני תקווה ,160 ,דרך המלך
229 ,גני תקווה ,139 ,דרך המשי
229 ,גני תקווה ,166 ,דרך העמק
229 ,גני תקווה ,143 ,דרך התמרים
229 ,גני תקווה ,164 ,דרך התקווה
229 ,גני תקווה ,127 ,האורנים
229 ,גני תקווה ,213 ,הברוש
229 ,גני תקווה ,142 ,הגולן
229 ,גני תקווה ,170 ,הגליל
229 ,גני תקווה ,212 ,הגלעד
229 ,גני תקווה ,179 ,הגפן
229 ,גני תקווה ,128 ,הדקל
229 ,גני תקווה ,145 ,החרמון
229 ,גני תקווה ,126 ,הכרמל
229 ,גני תקווה ,138 ,המצפה
229 ,גני תקווה ,103 ,הנגב
229 ,גני תקווה ,115 ,הנחל
229 ,גני תקווה ,165 ,הנשיא טרומן
229 ,גני תקווה ,116 ,העמקים
229 ,גני תקווה ,105 ,הערבה
229 ,גני תקווה ,121 ,הפסגה
229 ,גני תקווה ,154 ,הר נבו
229 ,גני תקווה ,113 ,הר סיני
229 ,גני תקווה ,120 ,הרי יהודה
229 ,גני תקווה ,152 ,הרי ירושלים
229 ,גני תקווה ,129 ,הרימון
229 ,גני תקווה ,135 ,הרמה
229 ,גני תקווה ,131 ,התאנה
229 ,גני תקווה ,175 ,זאב
229 ,גני תקווה ,172 ,יודפת
229 ,גני תקווה ,101 ,ים המלח
229 ,גני תקווה ,102 ,כנרת
229 ,גני תקווה ,110 ,מרחביה
229 ,גני תקווה ,6504 ,נוף סביון
229 ,גני תקווה ,112 ,סמ דביר
229 ,גני תקווה ,171 ,סמ הלבונה
229 ,גני תקווה ,123 ,סמ השרון
229 ,גני תקווה ,134 ,סמ כינרת
229 ,גני תקווה ,210 ,סמ עין גנים
229 ,גני תקווה ,173 ,סמ עמק יזרעאל
229 ,גני תקווה ,124 ,סמ קדש
229 ,גני תקווה ,146 ,סמ תבור
229 ,גני תקווה ,107 ,סמ תל חי
229 ,גני תקווה ,167 ,סמטת יובלים
229 ,גני תקווה ,104 ,עין גדי
229 ,גני תקווה ,141 ,עין חנוך
229 ,גני תקווה ,211 ,עין שמש
229 ,גני תקווה ,114 ,עמק זבולון
229 ,גני תקווה ,130 ,צאלון
229 ,גני תקווה ,111 ,קדושי השואה
229 ,גני תקווה ,122 ,קדושת יום טוב
229 ,גני תקווה ,117 ,קישון
229 ,גני תקווה ,6501 ,קרית ישמח משה
229 ,גני תקווה ,133 ,רבאון שלמה
229 ,גני תקווה ,147 ,רחבת אלון
229 ,גני תקווה ,149 ,רחבת גונן
229 ,גני תקווה ,150 ,רחבת דולב
229 ,גני תקווה ,6502 ,ש חדש
229 ,גני תקווה ,108 ,שדרת התקוות
229 ,גני תקווה ,6503 ,שכ בורוכוב
229 ,גני תקווה ,6505 ,שכ גנים
229 ,גני תקווה ,6506 ,שכ יפה נוף
842 ,געש ,9000 ,געש
463 ,געתון ,9000 ,געתון
39 ,גפן ,9000 ,גפן
1129 ,גרופית ,9000 ,גרופית
4022 ,גשור ,9000 ,גשור
305 ,גשר ,101 ,בית ראשון במולדת
305 ,גשר ,9000 ,גשר
574 ,גשר הזיו ,7001 ,ביס שדה אכזיב
574 ,גשר הזיו ,9000 ,גשר הזיו
574 ,גשר הזיו ,136 ,דור הבנים
574 ,גשר הזיו ,127 ,דרך הטבק
574 ,גשר הזיו ,160 ,דרך הקיבוץ
574 ,גשר הזיו ,120 ,דרך כליל החורש
574 ,גשר הזיו ,137 ,הגבעה
574 ,גשר הזיו ,138 ,המזכירות
574 ,גשר הזיו ,139 ,המרכז
574 ,גשר הזיו ,140 ,הראשונים
574 ,גשר הזיו ,141 ,חלזונות הגשם
574 ,גשר הזיו ,131 ,משעול הברוש
574 ,גשר הזיו ,132 ,נתיב הארז
574 ,גשר הזיו ,135 ,נתיב הגפן
574 ,גשר הזיו ,134 ,נתיב הדולב
574 ,גשר הזיו ,133 ,נתיב ההרדוף
574 ,גשר הזיו ,129 ,נתיב הזית
574 ,גשר הזיו ,128 ,נתיב החרוב
574 ,גשר הזיו ,119 ,נתיב היערה
574 ,גשר הזיו ,114 ,נתיב הלוטם
574 ,גשר הזיו ,115 ,נתיב המרגנית
574 ,גשר הזיו ,116 ,נתיב הנרקיס
574 ,גשר הזיו ,117 ,נתיב הסחלב
574 ,גשר הזיו ,118 ,נתיב הערבה
574 ,גשר הזיו ,142 ,רחבת הבונים
574 ,גשר הזיו ,143 ,רחבת הגרעינים
574 ,גשר הזיו ,144 ,רחבת ההגשמה
574 ,גשר הזיו ,145 ,רחבת המתנדבים
574 ,גשר הזיו ,146 ,רחבת הצופים
574 ,גשר הזיו ,147 ,רחבת פינת חי
574 ,גשר הזיו ,148 ,שביל בית הערבה
574 ,גשר הזיו ,149 ,שביל האיילה
574 ,גשר הזיו ,150 ,שביל הוורקשופ
574 ,גשר הזיו ,151 ,שביל החצב
574 ,גשר הזיו ,152 ,שביל החצרן
574 ,גשר הזיו ,153 ,שביל הלהבה
574 ,גשר הזיו ,154 ,שביל המזכיר
574 ,גשר הזיו ,155 ,שביל הניצנים
574 ,גשר הזיו ,156 ,שביל הסדרן
574 ,גשר הזיו ,157 ,שביל העופרים
574 ,גשר הזיו ,158 ,שביל הענפים
574 ,גשר הזיו ,159 ,שדרות השיקמים
574 ,גשר הזיו ,130 ,שדרת האורנים
340 ,גת (קיבוץ) ,9000 ,גת (קיבוץ)
128 ,גת רימון ,101 ,אילנות
128 ,גת רימון ,9000 ,גת רימון
128 ,גת רימון ,104 ,האקליפטוס
128 ,גת רימון ,102 ,הברוש
128 ,גת רימון ,103 ,התפוז
494 ,דאלית אל-כרמל ,484 ,אברהים אלח'ליל
494 ,דאלית אל-כרמל ,427 ,אדיסון
494 ,דאלית אל-כרמל ,449 ,אום כולתום
494 ,דאלית אל-כרמל ,305 ,אוסלו
494 ,דאלית אל-כרמל ,319 ,אוראנוס
494 ,דאלית אל-כרמל ,477 ,אורכיד
494 ,דאלית אל-כרמל ,6761 ,אזור המסעדות
494 ,דאלית אל-כרמל ,6756 ,אזור התעשיה
494 ,דאלית אל-כרמל ,6755 ,אזור כביש עוקף
494 ,דאלית אל-כרמל ,413 ,איינשטיין
494 ,דאלית אל-כרמל ,386 ,אילת
494 ,דאלית אל-כרמל ,171 ,אל ג'ליל
494 ,דאלית אל-כרמל ,485 ,אל נביא יעקוב
494 ,דאלית אל-כרמל ,140 ,אל נזהא
494 ,דאלית אל-כרמל ,486 ,אל סת נסב
494 ,דאלית אל-כרמל ,362 ,אל סת שהואנה
494 ,דאלית אל-כרמל ,268 ,אלאדאב
494 ,דאלית אל-כרמל ,445 ,אלאדיב
494 ,דאלית אל-כרמל ,253 ,אלאהאלי
494 ,דאלית אל-כרמל ,429 ,אלאהראם
494 ,דאלית אל-כרמל ,334 ,אלאול
494 ,דאלית אל-כרמל ,359 ,אלאוסתאד'
494 ,דאלית אל-כרמל ,272 ,אלאח'אא
494 ,דאלית אל-כרמל ,276 ,אלאח'לאס
494 ,דאלית אל-כרמל ,335 ,אלאחד
494 ,דאלית אל-כרמל ,471 ,אלאחסאן
494 ,דאלית אל-כרמל ,455 ,אלאמאנה
494 ,דאלית אל-כרמל ,384 ,אלאמיר
494 ,דאלית אל-כרמל ,279 ,אלאמל
494 ,דאלית אל-כרמל ,397 ,אלאנואר
494 ,דאלית אל-כרמל ,389 ,אלאנסאנייה
494 ,דאלית אל-כרמל ,494 ,אלאסיל
494 ,דאלית אל-כרמל ,475 ,אלאסראא
494 ,דאלית אל-כרמל ,316 ,אלארד'
494 ,דאלית אל-כרמל ,261 ,אלארז
494 ,דאלית אל-כרמל ,487 ,אלאשהאב
494 ,דאלית אל-כרמל ,489 ,אלבאסל
494 ,דאלית אל-כרמל ,351 ,אלבאשא
494 ,דאלית אל-כרמל ,405 ,אלבדיע
494 ,דאלית אל-כרמל ,482 ,אלבהאאיה
494 ,דאלית אל-כרמל ,498 ,אלבהג'ה
494 ,דאלית אל-כרמל ,367 ,אלביאדה
494 ,דאלית אל-כרמל ,265 ,אלבלאן
494 ,דאלית אל-כרמל ,138 ,אלבסאתין
494 ,דאלית אל-כרמל ,310 ,אלברונז
494 ,דאלית אל-כרמל ,452 ,אלג'דור
494 ,דאלית אל-כרמל ,231 ,אלג'וז
494 ,דאלית אל-כרמל ,173 ,אלג'ולן
494 ,דאלית אל-כרמל ,149 ,אלג'ורי
494 ,דאלית אל-כרמל ,396 ,אלג'מאל
494 ,דאלית אל-כרמל ,457 ,אלג'עארה
494 ,דאלית אל-כרמל ,290 ,אלג'עסאס
494 ,דאלית אל-כרמל ,312 ,אלד'הב
494 ,דאלית אל-כרמל ,357 ,אלדוקטור
494 ,דאלית אל-כרמל ,240 ,אלדוראק
494 ,דאלית אל-כרמל ,322 ,אלהאדי
494 ,דאלית אל-כרמל ,336 ,אלואחד
494 ,דאלית אל-כרמל ,333 ,אלואלי
494 ,דאלית אל-כרמל ,6760 ,אלוונסא
494 ,דאלית אל-כרמל ,107 ,אלוורוד
494 ,דאלית אל-כרמל ,441 ,אלותר
494 ,דאלית אל-כרמל ,144 ,אלזהור
494 ,דאלית אל-כרמל ,153 ,אלזיתון
494 ,דאלית אל-כרמל ,474 ,אלזכאה
494 ,דאלית אל-כרמל ,284 ,אלזנבק
494 ,דאלית אל-כרמל ,394 ,אלזעפראן
494 ,דאלית אל-כרמל ,400 ,אלזערור
494 ,דאלית אל-כרמל ,291 ,אלזעתר
494 ,דאלית אל-כרמל ,267 ,אלח'דמה
494 ,דאלית אל-כרמל ,211 ,אלח'ואלנד
494 ,דאלית אל-כרמל ,121 ,אלח'לואת
494 ,דאלית אל-כרמל ,472 ,אלחג'
494 ,דאלית אל-כרמל ,348 ,אלחדאד
494 ,דאלית אל-כרמל ,307 ,אלחואקיר
494 ,דאלית אל-כרמל ,152 ,אלחורש
494 ,דאלית אל-כרמל ,381 ,אלחזורי
494 ,דאלית אל-כרמל ,252 ,אלחכם
494 ,דאלית אל-כרמל ,376 ,אלחלאק'
494 ,דאלית אל-כרמל ,326 ,אלחלים
494 ,דאלית אל-כרמל ,258 ,אלחמאם
494 ,דאלית אל-כרמל ,324 ,אלחמיד
494 ,דאלית אל-כרמל ,259 ,אלחסון
494 ,דאלית אל-כרמל ,356 ,אלטאלב
494 ,דאלית אל-כרמל ,255 ,אלטוליפ
494 ,דאלית אל-כרמל ,146 ,אליאסמין
494 ,דאלית אל-כרמל ,283 ,אליאקות
494 ,דאלית אל-כרמל ,365 ,אליעפורי
494 ,דאלית אל-כרמל ,444 ,אלכאתב
494 ,דאלית אל-כרמל ,325 ,אלכביר
494 ,דאלית אל-כרמל ,492 ,אלכוות'ר
494 ,דאלית אל-כרמל ,323 ,אלכרים
494 ,דאלית אל-כרמל ,406 ,אללביב
494 ,דאלית אל-כרמל ,232 ,אללוז
494 ,דאלית אל-כרמל ,289 ,אלליימון
494 ,דאלית אל-כרמל ,404 ,אלמאס
494 ,דאלית אל-כרמל ,495 ,אלמג'ד
494 ,דאלית אל-כרמל ,358 ,אלמודיר
494 ,דאלית אל-כרמל ,379 ,אלמוהנדס
494 ,דאלית אל-כרמל ,105 ,אלמוח'טארה
494 ,דאלית אל-כרמל ,390 ,אלמוחאמי
494 ,דאלית אל-כרמל ,269 ,אלמוסאוא
494 ,דאלית אל-כרמל ,392 ,אלמועלם
494 ,דאלית אל-כרמל ,318 ,אלמושתרי
494 ,דאלית אל-כרמל ,208 ,אלמותנבי
494 ,דאלית אל-כרמל ,408 ,אלמזרעה
494 ,דאלית אל-כרמל ,278 ,אלמחבה
494 ,דאלית אל-כרמל ,302 ,אלמחפרה
494 ,דאלית אל-כרמל ,453 ,אלמיזאן
494 ,דאלית אל-כרמל ,332 ,אלמלק
494 ,דאלית אל-כרמל ,435 ,אלמנארה
494 ,דאלית אל-כרמל ,120 ,אלמנזול
494 ,דאלית אל-כרמל ,285 ,אלמסק
494 ,דאלית אל-כרמל ,476 ,אלמעראג'
494 ,דאלית אל-כרמל ,209 ,אלמערי
494 ,דאלית אל-כרמל ,317 ,אלמריך
494 ,דאלית אל-כרמל ,241 ,אלמשמש
494 ,דאלית אל-כרמל ,194 ,אלמתן
494 ,דאלית אל-כרמל ,439 ,אלנאי
494 ,דאלית אל-כרמל ,342 ,אלנג'אר
494 ,דאלית אל-כרמל ,383 ,אלנורס
494 ,דאלית אל-כרמל ,220 ,אלנזאזה
494 ,דאלית אל-כרמל ,274 ,אלנח'ווה
494 ,דאלית אל-כרמל ,402 ,אלנח'יל
494 ,דאלית אל-כרמל ,395 ,אלנחל
494 ,דאלית אל-כרמל ,399 ,אלנענע
494 ,דאלית אל-כרמל ,188 ,אלנעסה
494 ,דאלית אל-כרמל ,271 ,אלנק'אב
494 ,דאלית אל-כרמל ,170 ,אלנרג'ס
494 ,דאלית אל-כרמל ,275 ,אלסבאח
494 ,דאלית אל-כרמל ,398 ,אלסבאר
494 ,דאלית אל-כרמל ,309 ,אלסבור
494 ,דאלית אל-כרמל ,361 ,אלסדאקה
494 ,דאלית אל-כרמל ,103 ,אלסואניה
494 ,דאלית אל-כרמל ,195 ,אלסווידה
494 ,דאלית אל-כרמל ,193 ,אלסומאק
494 ,דאלית אל-כרמל ,148 ,אלסוסן
494 ,דאלית אל-כרמל ,483 ,אלסופייה
494 ,דאלית אל-כרמל ,403 ,אלסופסאף
494 ,דאלית אל-כרמל ,244 ,אלסופרג'ל
494 ,דאלית אל-כרמל ,380 ,אלסחאפי
494 ,דאלית אל-כרמל ,213 ,אלסטח
494 ,דאלית אל-כרמל ,411 ,אלסיאד
494 ,דאלית אל-כרמל ,377 ,אלסיידלי
494 ,דאלית אל-כרמל ,330 ,אלסלאם
494 ,דאלית אל-כרמל ,151 ,אלסנדיאן
494 ,דאלית אל-כרמל ,300 ,אלסנובר
494 ,דאלית אל-כרמל ,238 ,אלסקר
494 ,דאלית אל-כרמל ,450 ,אלסראט
494 ,דאלית אל-כרמל ,260 ,אלסרו
494 ,דאלית אל-כרמל ,233 ,אלסריס
494 ,דאלית אל-כרמל ,448 ,אלסת ח'ד'רה
494 ,דאלית אל-כרמל ,447 ,אלסת סארה
494 ,דאלית אל-כרמל ,154 ,אלע'אר
494 ,דאלית אל-כרמל ,236 ,אלע'זאל
494 ,דאלית אל-כרמל ,375 ,אלע'יידה
494 ,דאלית אל-כרמל ,327 ,אלעדל
494 ,דאלית אל-כרמל ,436 ,אלעוד
494 ,דאלית אל-כרמל ,497 ,אלעזה
494 ,דאלית אל-כרמל ,313 ,אלעזיז
494 ,דאלית אל-כרמל ,281 ,אלעטור
494 ,דאלית אל-כרמל ,328 ,אלעלים
494 ,דאלית אל-כרמל ,409 ,אלעמאמה
494 ,דאלית אל-כרמל ,242 ,אלענאב
494 ,דאלית אל-כרמל ,263 ,אלענב
494 ,דאלית אל-כרמל ,282 ,אלענבר
494 ,דאלית אל-כרמל ,488 ,אלפארס
494 ,דאלית אל-כרמל ,237 ,אלפהד
494 ,דאלית אל-כרמל ,311 ,אלפודה
494 ,דאלית אל-כרמל ,442 ,אלפיאנו
494 ,דאלית אל-כרמל ,443 ,אלפנון
494 ,דאלית אל-כרמל ,490 ,אלפרדוס
494 ,דאלית אל-כרמל ,280 ,אלק'דאא
494 ,דאלית אל-כרמל ,382 ,אלק'נדול
494 ,דאלית אל-כרמל ,372 ,אלקאדי
494 ,דאלית אל-כרמל ,256 ,אלקורונפול
494 ,דאלית אל-כרמל ,266 ,אלקטלב
494 ,דאלית אל-כרמל ,438 ,אלקמאן
494 ,דאלית אל-כרמל ,257 ,אלקרואן
494 ,דאלית אל-כרמל ,243 ,אלקרז
494 ,דאלית אל-כרמל ,496 ,אלראוודה
494 ,דאלית אל-כרמל ,440 ,אלרבאבה
494 ,דאלית אל-כרמל ,184 ,אלרוחראח
494 ,דאלית אל-כרמל ,264 ,אלרומאן
494 ,דאלית אל-כרמל ,363 ,אלרוע'ב
494 ,דאלית אל-כרמל ,329 ,אלרזאק
494 ,דאלית אל-כרמל ,314 ,אלרחים
494 ,דאלית אל-כרמל ,491 ,אלרחיק
494 ,דאלית אל-כרמל ,331 ,אלרחמאן
494 ,דאלית אל-כרמל ,407 ,אלריאד
494 ,דאלית אל-כרמל ,150 ,אלריחאן
494 ,דאלית אל-כרמל ,129 ,אלרנדה
494 ,דאלית אל-כרמל ,451 ,אלרסאלה
494 ,דאלית אל-כרמל ,446 ,אלשאער
494 ,דאלית אל-כרמל ,235 ,אלשברק
494 ,דאלית אל-כרמל ,227 ,אלשוף
494 ,דאלית אל-כרמל ,186 ,אלשיח' אלפאדל
494 ,דאלית אל-כרמל ,420 ,אלשיך חמוד
494 ,דאלית אל-כרמל ,458 ,אלשלאלה
494 ,דאלית אל-כרמל ,234 ,אלתופאח
494 ,דאלית אל-כרמל ,401 ,אלתות
494 ,דאלית אל-כרמל ,262 ,אלתין
494 ,דאלית אל-כרמל ,410 ,אלתלמיד'
494 ,דאלית אל-כרמל ,288 ,אלתמר
494 ,דאלית אל-כרמל ,270 ,אלתסאמח
494 ,דאלית אל-כרמל ,273 ,אלתעאוון
494 ,דאלית אל-כרמל ,454 ,אלתעיוש
494 ,דאלית אל-כרמל ,343 ,אמסטרדם
494 ,דאלית אל-כרמל ,246 ,אסטנבול
494 ,דאלית אל-כרמל ,180 ,אפלטון
494 ,דאלית אל-כרמל ,181 ,אריסטו
494 ,דאלית אל-כרמל ,292 ,אשדוד
494 ,דאלית אל-כרמל ,245 ,אתונה
494 ,דאלית אל-כרמל ,182 ,באב אלהווא
494 ,דאלית אל-כרמל ,293 ,באר שבע
494 ,דאלית אל-כרמל ,369 ,בהאא אלדין
494 ,דאלית אל-כרמל ,350 ,בודפשט
494 ,דאלית אל-כרמל ,247 ,בייג'ין
494 ,דאלית אל-כרמל ,434 ,ביילסאן
494 ,דאלית אל-כרמל ,254 ,ביירות
494 ,דאלית אל-כרמל ,460 ,ביל גייטס
494 ,דאלית אל-כרמל ,164 ,ביר דובל
494 ,דאלית אל-כרמל ,479 ,בית אלדין
494 ,דאלית אל-כרמל ,478 ,בנו מערוף
494 ,דאלית אל-כרמל ,349 ,בריסל
494 ,דאלית אל-כרמל ,340 ,ברלין
494 ,דאלית אל-כרמל ,109 ,ג'בל אלדרוז
494 ,דאלית אל-כרמל ,430 ,ג'ולנאר
494 ,דאלית אל-כרמל ,463 ,ג'ון לנון
494 ,דאלית אל-כרמל ,467 ,גלילאו
494 ,דאלית אל-כרמל ,414 ,גנדי
494 ,דאלית אל-כרמל ,6771 ,גרעין הכפר הישן
494 ,דאלית אל-כרמל ,168 ,ד'הרת אלביר
494 ,דאלית אל-כרמל ,9000 ,דאלית אל-כרמל
494 ,דאלית אל-כרמל ,249 ,דבלין
494 ,דאלית אל-כרמל ,421 ,דה וינצ'י
494 ,דאלית אל-כרמל ,387 ,דימונה
494 ,דאלית אל-כרמל ,480 ,דיר אלקמר
494 ,דאלית אל-כרמל ,134 ,דליה
494 ,דאלית אל-כרמל ,468 ,הרמב"ם
494 ,דאלית אל-כרמל ,385 ,הרצליה
494 ,דאלית אל-כרמל ,6758 ,השכונה המזרחית
494 ,דאלית אל-כרמל ,156 ,ואדי אלפש
494 ,דאלית אל-כרמל ,216 ,ואדי אלתים
494 ,דאלית אל-כרמל ,347 ,ואדי עיסא
494 ,דאלית אל-כרמל ,308 ,וינה
494 ,דאלית אל-כרמל ,306 ,ורשה
494 ,דאלית אל-כרמל ,320 ,זוחל
494 ,דאלית אל-כרמל ,364 ,זקריא
494 ,דאלית אל-כרמל ,304 ,ח'אתם סלימאן
494 ,דאלית אל-כרמל ,388 ,ח'לת אלאעואר
494 ,דאלית אל-כרמל ,178 ,ח'לת אלאעור
494 ,דאלית אל-כרמל ,163 ,ח'לת אלג'מל
494 ,דאלית אל-כרמל ,223 ,ח'לת אלקבלייה
494 ,דאלית אל-כרמל ,177 ,ח'לת חמזה
494 ,דאלית אל-כרמל ,167 ,ח'לת עלי
494 ,דאלית אל-כרמל ,198 ,חאצביא
494 ,דאלית אל-כרמל ,373 ,חולון
494 ,דאלית אל-כרמל ,368 ,חטין
494 ,דאלית אל-כרמל ,6751 ,חיילים משוחררים
494 ,דאלית אל-כרמל ,250 ,חיפה
494 ,דאלית אל-כרמל ,108 ,חלב
494 ,דאלית אל-כרמל ,296 ,טבריה
494 ,דאלית אל-כרמל ,469 ,טוליב
494 ,דאלית אל-כרמל ,248 ,טוקיו
494 ,דאלית אל-כרמל ,221 ,יד לבנים
494 ,דאלית אל-כרמל ,360 ,יהודה
494 ,דאלית אל-כרמל ,297 ,יפו
494 ,דאלית אל-כרמל ,473 ,יצחק רבין
494 ,דאלית אל-כרמל ,353 ,ירושלים
494 ,דאלית אל-כרמל ,6002 ,כיכר 1
494 ,דאלית אל-כרמל ,6003 ,כיכר 2
494 ,דאלית אל-כרמל ,6004 ,כיכר 3
494 ,דאלית אל-כרמל ,6005 ,כיכר 4
494 ,דאלית אל-כרמל ,6006 ,כיכר 5
494 ,דאלית אל-כרמל ,6007 ,כיכר 6
494 ,דאלית אל-כרמל ,6001 ,כיכר דואר אלח'רבה
494 ,דאלית אל-כרמל ,128 ,כמאל ג'ונבלאט
494 ,דאלית אל-כרמל ,101 ,כרמל
494 ,דאלית אל-כרמל ,352 ,לונדון
494 ,דאלית אל-כרמל ,425 ,לינקולן
494 ,דאלית אל-כרמל ,393 ,ליסבון
494 ,דאלית אל-כרמל ,230 ,מדריד
494 ,דאלית אל-כרמל ,345 ,מוסקוה
494 ,דאלית אל-כרמל ,417 ,מחמד עלי
494 ,דאלית אל-כרמל ,214 ,מלאת אלריש
494 ,דאלית אל-כרמל ,415 ,מנדלה
494 ,דאלית אל-כרמל ,423 ,מנחם בגין
494 ,דאלית אל-כרמל ,301 ,מרג' אלנג'אס
494 ,דאלית אל-כרמל ,6770 ,מרכז הכפר
494 ,דאלית אל-כרמל ,207 ,משה דיין
494 ,דאלית אל-כרמל ,157 ,נג'מת אלסובח
494 ,דאלית אל-כרמל ,277 ,נג'מת דוד
494 ,דאלית אל-כרמל ,286 ,נהריה
494 ,דאלית אל-כרמל ,229 ,נזזת אלע'יידא
494 ,דאלית אל-כרמל ,251 ,ניו דלהי
494 ,דאלית אל-כרמל ,354 ,ניו יורק
494 ,דאלית אל-כרמל ,428 ,ניוטון
494 ,דאלית אל-כרמל ,481 ,ניחא
494 ,דאלית אל-כרמל ,321 ,נפטון
494 ,דאלית אל-כרמל ,185 ,נקארה
494 ,דאלית אל-כרמל ,432 ,נרדין
494 ,דאלית אל-כרמל ,433 ,נרמין
494 ,דאלית אל-כרמל ,366 ,סבלאן
494 ,דאלית אל-כרמל ,110 ,סולטאן באשא אלאטרש
494 ,דאלית אל-כרמל ,303 ,סופיה
494 ,דאלית אל-כרמל ,210 ,סוקרטס
494 ,דאלית אל-כרמל ,465 ,סטיב ג'ובס
494 ,דאלית אל-כרמל ,374 ,סידנא אליא
494 ,דאלית אל-כרמל ,371 ,סידנא שמס
494 ,דאלית אל-כרמל ,183 ,סיח אלח'טיב
494 ,דאלית אל-כרמל ,141 ,סמ 2
494 ,דאלית אל-כרמל ,142 ,סמ 6
494 ,דאלית אל-כרמל ,470 ,סמאר
494 ,דאלית אל-כרמל ,206 ,סמטת אלביאדר
494 ,דאלית אל-כרמל ,315 ,עוטארד
494 ,דאלית אל-כרמל ,424 ,עזר וייצמן
494 ,דאלית אל-כרמל ,124 ,עין אלקבליה
494 ,דאלית אל-כרמל ,160 ,עין אלשמאליה
494 ,דאלית אל-כרמל ,298 ,עכו
494 ,דאלית אל-כרמל ,339 ,עמאן
494 ,דאלית אל-כרמל ,294 ,ערד
494 ,דאלית אל-כרמל ,190 ,עש אלע'וראב
494 ,דאלית אל-כרמל ,143 ,פח'ר אלדין אלמעני
494 ,דאלית אל-כרמל ,437 ,פיירוז
494 ,דאלית אל-כרמל ,418 ,פיקאסו
494 ,דאלית אל-כרמל ,189 ,פיתאע'ורס
494 ,דאלית אל-כרמל ,466 ,פלה
494 ,דאלית אל-כרמל ,337 ,פלוטו
494 ,דאלית אל-כרמל ,6753 ,פנינת הכרמל
494 ,דאלית אל-כרמל ,378 ,פראג
494 ,דאלית אל-כרמל ,426 ,פרויד
494 ,דאלית אל-כרמל ,355 ,פריס
494 ,דאלית אל-כרמל ,464 ,צ'פלין
494 ,דאלית אל-כרמל ,422 ,צ'רצ'יל
494 ,דאלית אל-כרמל ,6754 ,צומת המוחרקה
494 ,דאלית אל-כרמל ,412 ,צפת
494 ,דאלית אל-כרמל ,341 ,קהיר
494 ,דאלית אל-כרמל ,295 ,קיסריה
494 ,דאלית אל-כרמל ,416 ,קנדי
494 ,דאלית אל-כרמל ,217 ,רבאע עודה
494 ,דאלית אל-כרמל ,104 ,רבעת אלג'וזה
494 ,דאלית אל-כרמל ,431 ,רוז
494 ,דאלית אל-כרמל ,344 ,רומא
494 ,דאלית אל-כרמל ,459 ,רוקיטייה
494 ,דאלית אל-כרמל ,125 ,רח 20
494 ,דאלית אל-כרמל ,113 ,רח 30
494 ,דאלית אל-כרמל ,127 ,רח 11
494 ,דאלית אל-כרמל ,136 ,רח 31
494 ,דאלית אל-כרמל ,131 ,רח 12
494 ,דאלית אל-כרמל ,117 ,רח 22
494 ,דאלית אל-כרמל ,137 ,רח 32
494 ,דאלית אל-כרמל ,139 ,רח 33
494 ,דאלית אל-כרמל ,132 ,רח 34
494 ,דאלית אל-כרמל ,133 ,רח 5
494 ,דאלית אל-כרמל ,135 ,רח 15
494 ,דאלית אל-כרמל ,118 ,רח 35
494 ,דאלית אל-כרמל ,115 ,רח 16
494 ,דאלית אל-כרמל ,119 ,רח 26
494 ,דאלית אל-כרמל ,130 ,רח 36
494 ,דאלית אל-כרמל ,111 ,רח 17
494 ,דאלית אל-כרמל ,122 ,רח 27
494 ,דאלית אל-כרמל ,112 ,רח 18
494 ,דאלית אל-כרמל ,114 ,רח 28
494 ,דאלית אל-כרמל ,126 ,רח 19
494 ,דאלית אל-כרמל ,116 ,רח 29
494 ,דאלית אל-כרמל ,287 ,רחובות
494 ,דאלית אל-כרמל ,6515 ,ש יד לבנים
494 ,דאלית אל-כרמל ,461 ,שאגאל
494 ,דאלית אל-כרמל ,106 ,שוק אבו ענתר
494 ,דאלית אל-כרמל ,391 ,שטוקהולם
494 ,דאלית אל-כרמל ,102 ,שיח' אמין טריף
494 ,דאלית אל-כרמל ,239 ,שייח חסין אל עאבד
494 ,דאלית אל-כרמל ,6750 ,שיכונאת
494 ,דאלית אל-כרמל ,6795 ,שכ אלבסאתין
494 ,דאלית אל-כרמל ,6500 ,שכ אלוורוד
494 ,דאלית אל-כרמל ,6792 ,שכ אלח'רבה
494 ,דאלית אל-כרמל ,6780 ,שכ אלקשקוש
494 ,דאלית אל-כרמל ,6781 ,שכ אלרנדה
494 ,דאלית אל-כרמל ,6762 ,שכ אסואניה
494 ,דאלית אל-כרמל ,6790 ,שכ ביר דוביל
494 ,דאלית אל-כרמל ,6752 ,שכ ואדי אלפש
494 ,דאלית אל-כרמל ,6782 ,שכ ח'לת אלג'מל
494 ,דאלית אל-כרמל ,6791 ,שכ ח'לת נצאר
494 ,דאלית אל-כרמל ,6793 ,שכ ח'לת עלי
494 ,דאלית אל-כרמל ,6794 ,שכ ט'הרת אלביר
494 ,דאלית אל-כרמל ,6765 ,שכ מרג' אלנג'אס
494 ,דאלית אל-כרמל ,6501 ,שכ נג'מת אלצבאח
494 ,דאלית אל-כרמל ,6764 ,שכ עין אלשמאליה
494 ,דאלית אל-כרמל ,6757 ,שכונת המוסלמים
494 ,דאלית אל-כרמל ,462 ,שמעון פרס
494 ,דאלית אל-כרמל ,228 ,שקיב ארסלאן
494 ,דאלית אל-כרמל ,299 ,תל אביב
146 ,דבורה ,9000 ,דבורה
489 ,דבוריה ,194 ,אבו כבר אלצדיק
489 ,דבוריה ,105 ,אבו ספיאן
489 ,דבוריה ,191 ,אבו עובידה
489 ,דבוריה ,145 ,אבו רומאנה
489 ,דבוריה ,106 ,אבן אלהית'ם
489 ,דבוריה ,176 ,אבן ג'וביר
489 ,דבוריה ,225 ,אבן ח'לדון
489 ,דבוריה ,117 ,אבן סינא
489 ,דבוריה ,123 ,אכסאל
489 ,דבוריה ,139 ,אל אעמאר
489 ,דבוריה ,186 ,אל באבור
489 ,דבוריה ,216 ,אל ד'אהר
489 ,דבוריה ,230 ,אל ואד
489 ,דבוריה ,138 ,אל ח'יר
489 ,דבוריה ,143 ,אל חסן
489 ,דבוריה ,129 ,אל נאפורה
489 ,דבוריה ,178 ,אלאדריסי
489 ,דבוריה ,235 ,אלאח'וה
489 ,דבוריה ,160 ,אלאחראש
489 ,דבוריה ,203 ,אלאמין
489 ,דבוריה ,111 ,אלאמל
489 ,דבוריה ,107 ,אלאנדלס
489 ,דבוריה ,233 ,אלבארוני
489 ,דבוריה ,104 ,אלבוח'ארי
489 ,דבוריה ,180 ,אלבוסתאן
489 ,דבוריה ,125 ,אלבידר
489 ,דבוריה ,151 ,אלברקוק
489 ,דבוריה ,161 ,אלג'ורה
489 ,דבוריה ,187 ,אלג'ורי
489 ,דבוריה ,148 ,אלד'ראע
489 ,דבוריה ,201 ,אלדבה
489 ,דבוריה ,124 ,אלדיר
489 ,דבוריה ,114 ,אלהודא
489 ,דבוריה ,200 ,אלואחדה
489 ,דבוריה ,100 ,אלזהראא
489 ,דבוריה ,156 ,אלזיריקה
489 ,דבוריה ,102 ,אלזיתון
489 ,דבוריה ,153 ,אלזנבק
489 ,דבוריה ,109 ,אלזעבליה
489 ,דבוריה ,188 ,אלח'ואריזמי
489 ,דבוריה ,136 ,אלח'רוב
489 ,דבוריה ,149 ,אלחדר
489 ,דבוריה ,190 ,אלחכים
489 ,דבוריה ,131 ,אלטור
489 ,דבוריה ,154 ,אליאסמין
489 ,דבוריה ,175 ,אליעקובי
489 ,דבוריה ,219 ,אלכינא
489 ,דבוריה ,140 ,אלכנדי
489 ,דבוריה ,144 ,אלכרים
489 ,דבוריה ,184 ,אלמאלח
489 ,דבוריה ,214 ,אלמג'יד
489 ,דבוריה ,127 ,אלמג'נונה
489 ,דבוריה ,198 ,אלמחבה
489 ,דבוריה ,135 ,אלמלעב
489 ,דבוריה ,137 ,אלמסנסל
489 ,דבוריה ,177 ,אלמסעודי
489 ,דבוריה ,118 ,אלמסרארה
489 ,דבוריה ,221 ,אלמעאסר
489 ,דבוריה ,141 ,אלמראח
489 ,דבוריה ,110 ,אלמרעוין
489 ,דבוריה ,162 ,אלמרשקה
489 ,דבוריה ,116 ,אלמתנבי
489 ,דבוריה ,146 ,אלנג'ארה
489 ,דבוריה ,165 ,אלנוארס
489 ,דבוריה ,168 ,אלנור
489 ,דבוריה ,202 ,אלנעים
489 ,דבוריה ,152 ,אלסאוסן
489 ,דבוריה ,115 ,אלסאלח
489 ,דבוריה ,132 ,אלסאפח
489 ,דבוריה ,226 ,אלסביל
489 ,דבוריה ,101 ,אלסהל
489 ,דבוריה ,164 ,אלסעדי
489 ,דבוריה ,103 ,אלסעדיה
489 ,דבוריה ,217 ,אלספליה
489 ,דבוריה ,130 ,אלסרוו
489 ,דבוריה ,113 ,אלעדל
489 ,דבוריה ,126 ,אלעוג'ה
489 ,דבוריה ,171 ,אלעטור
489 ,דבוריה ,120 ,אלעין
489 ,דבוריה ,163 ,אלעלאמאת
489 ,דבוריה ,142 ,אלעמארה
489 ,דבוריה ,121 ,אלערקדה
489 ,דבוריה ,215 ,אלפאהום
489 ,דבוריה ,204 ,אלפארס
489 ,דבוריה ,182 ,אלפראבי
489 ,דבוריה ,211 ,אלפשארה
489 ,דבוריה ,199 ,אלראודה
489 ,דבוריה ,220 ,אלראזי
489 ,דבוריה ,205 ,אלראס
489 ,דבוריה ,155 ,אלראפידין
489 ,דבוריה ,181 ,אלרוסל
489 ,דבוריה ,207 ,אלרח'ם
489 ,דבוריה ,232 ,אלרידא
489 ,דבוריה ,231 ,אלרשיד
489 ,דבוריה ,234 ,אלשאפיעי
489 ,דבוריה ,236 ,אלשהד
489 ,דבוריה ,128 ,אלשראר
489 ,דבוריה ,209 ,אלתלה
489 ,דבוריה ,197 ,אלתסאמוח
489 ,דבוריה ,112 ,אלתעאון
489 ,דבוריה ,133 ,ביר אלחסניאת
489 ,דבוריה ,108 ,בלאל
489 ,דבוריה ,167 ,ג'רש
489 ,דבוריה ,169 ,דבורה
489 ,דבוריה ,9000 ,דבוריה
489 ,דבוריה ,170 ,ואדי אלדן
489 ,דבוריה ,206 ,חוג'אג'
489 ,דבוריה ,150 ,חיטין
489 ,דבוריה ,174 ,מראח אלסריס
489 ,דבוריה ,195 ,סלאח אלדין
489 ,דבוריה ,213 ,עומר בן אלח'טאב
489 ,דבוריה ,119 ,עין מאהל
489 ,דבוריה ,223 ,עלי בן אבי טאלב
489 ,דבוריה ,212 ,עתמאן בן עפאן
489 ,דבוריה ,208 ,שיעין
489 ,דבוריה ,222 ,שרחביל
849 ,דביר ,110 ,אגס
849 ,דביר ,135 ,אירוס
849 ,דביר ,130 ,אירית
849 ,דביר ,112 ,אלה
849 ,דביר ,146 ,אלון
849 ,דביר ,105 ,אנפה
849 ,דביר ,144 ,ארז
849 ,דביר ,148 ,גפן
849 ,דביר ,9000 ,דביר
849 ,דביר ,104 ,דוכיפת
849 ,דביר ,114 ,דרור
849 ,דביר ,102 ,דרך דולב
849 ,דביר ,101 ,דרך המייסדים
849 ,דביר ,147 ,הדס
849 ,דביר ,142 ,זית
849 ,דביר ,113 ,זמיר
849 ,דביר ,125 ,חלמונית
849 ,דביר ,116 ,חסידה
849 ,דביר ,139 ,חצב
849 ,דביר ,122 ,חרצית
849 ,דביר ,131 ,יסמין
849 ,דביר ,108 ,יסעור
849 ,דביר ,109 ,כחל
849 ,דביר ,145 ,כליל החורש
849 ,דביר ,138 ,כלנית
849 ,דביר ,134 ,כרכום
849 ,דביר ,127 ,לוטם
849 ,דביר ,140 ,לוע הארי
849 ,דביר ,136 ,לילך
849 ,דביר ,115 ,מגלן
849 ,דביר ,123 ,נופר
849 ,דביר ,129 ,נורית
849 ,דביר ,107 ,נחליאלי
849 ,דביר ,124 ,סביון
849 ,דביר ,133 ,סחלב
849 ,דביר ,118 ,סיקסק
849 ,דביר ,103 ,עגור
849 ,דביר ,121 ,פרג
849 ,דביר ,137 ,צבעוני
849 ,דביר ,106 ,צופית
849 ,דביר ,111 ,רותם
849 ,דביר ,143 ,רימון
849 ,דביר ,132 ,רקפת
849 ,דביר ,120 ,שביל הגרעינים
849 ,דביר ,119 ,שביל הסיפורים
849 ,דביר ,126 ,שושן
849 ,דביר ,117 ,שחף
849 ,דביר ,150 ,שקד
849 ,דביר ,141 ,שקמה
849 ,דביר ,128 ,תורמוס
849 ,דביר ,149 ,תמר
407 ,דברת ,9000 ,דברת
62 ,דגניה א' ,9000 ,דגניה א'
79 ,דגניה ב' ,9000 ,דגניה ב'
1067 ,דוב"ב ,9000 ,דוב"ב
3747 ,דולב ,9000 ,דולב
3747 ,דולב ,100 ,הגפן
3747 ,דולב ,101 ,הראי"ה
3747 ,דולב ,102 ,הרב גורן
3747 ,דולב ,103 ,הרב ישראלי
3747 ,דולב ,104 ,הרב לוין
3747 ,דולב ,109 ,הרב מרדכי אליהו
3747 ,דולב ,105 ,הרב נריה
3747 ,דולב ,106 ,הרב עוזיאל
3747 ,דולב ,107 ,התירוש
3747 ,דולב ,108 ,מעלה הכרמים
738 ,דור ,9000 ,דור
336 ,דורות ,9000 ,דורות
475 ,דחי ,9000 ,דחי
490 ,דייר אל-אסד ,131 ,אבו בכר
490 ,דייר אל-אסד ,138 ,אבו תמאם
490 ,דייר אל-אסד ,125 ,אבן ח'לדון
490 ,דייר אל-אסד ,140 ,אבן חיאן
490 ,דייר אל-אסד ,139 ,אבן רושד
490 ,דייר אל-אסד ,142 ,אלאח'טל
490 ,דייר אל-אסד ,164 ,אלאנביאא
490 ,דייר אל-אסד ,126 ,אלביאדר
490 ,דייר אל-אסד ,103 ,אלביארה
490 ,דייר אל-אסד ,127 ,אלבידר
490 ,דייר אל-אסד ,109 ,אלברכה
490 ,דייר אל-אסד ,104 ,אלג'בל
490 ,דייר אל-אסד ,147 ,אלג'לסה
490 ,דייר אל-אסד ,107 ,אלדיר
490 ,דייר אל-אסד ,130 ,אלדראג'
490 ,דייר אל-אסד ,155 ,אלורוד
490 ,דייר אל-אסד ,152 ,אלזהור
490 ,דייר אל-אסד ,165 ,אלזיתון
490 ,דייר אל-אסד ,156 ,אלזנבק
490 ,דייר אל-אסד ,163 ,אלזערור
490 ,דייר אל-אסד ,148 ,אלח'אן
490 ,דייר אל-אסד ,102 ,אלח'ד'ר
490 ,דייר אל-אסד ,115 ,אלח'לה
490 ,דייר אל-אסד ,129 ,אלח'רוב
490 ,דייר אל-אסד ,153 ,אליאסמין
490 ,דייר אל-אסד ,145 ,אלכרום
490 ,דייר אל-אסד ,117 ,אלכרימה
490 ,דייר אל-אסד ,169 ,אלכרם אלאחמר
490 ,דייר אל-אסד ,136 ,אלמותנבי
490 ,דייר אל-אסד ,137 ,אלמערי
490 ,דייר אל-אסד ,112 ,אלמראח
490 ,דייר אל-אסד ,162 ,אלנוקעה
490 ,דייר אל-אסד ,146 ,אלנס'ב אלטויל
490 ,דייר אל-אסד ,154 ,אלנרג'ס
490 ,דייר אל-אסד ,121 ,אלסבאט
490 ,דייר אל-אסד ,105 ,אלסוכן
490 ,דייר אל-אסד ,168 ,אלעורש
490 ,דייר אל-אסד ,101 ,אלעין
490 ,דייר אל-אסד ,116 ,אלעריד'
490 ,דייר אל-אסד ,167 ,אלקוס'יבה
490 ,דייר אל-אסד ,118 ,אלקלעה
490 ,דייר אל-אסד ,128 ,אלריאף
490 ,דייר אל-אסד ,157 ,אלריחאן
490 ,דייר אל-אסד ,141 ,אלשאפעי
490 ,דייר אל-אסד ,106 ,אלשבאב
490 ,דייר אל-אסד ,122 ,אלשועראא
490 ,דייר אל-אסד ,108 ,אלשיח' אלאסד
490 ,דייר אל-אסד ,123 ,אלתות
490 ,דייר אל-אסד ,166 ,בילאל אבן רבאח
490 ,דייר אל-אסד ,120 ,ג'ורת ברק
490 ,דייר אל-אסד ,6730 ,גרעין ישן
490 ,דייר אל-אסד ,9000 ,דייר אל-אסד
490 ,דייר אל-אסד ,135 ,הארון אלרשיד
490 ,דייר אל-אסד ,160 ,ח'אלד אבן אלוליד
490 ,דייר אל-אסד ,161 ,טארק אבן זיאד
490 ,דייר אל-אסד ,158 ,טה חוסין
490 ,דייר אל-אסד ,124 ,כרם אלזאהר
490 ,דייר אל-אסד ,6724 ,מגרשים
490 ,דייר אל-אסד ,159 ,נג'יב מחפוז
490 ,דייר אל-אסד ,119 ,ס'לאח אלדין
490 ,דייר אל-אסד ,132 ,עומר בן אלח'טאב
490 ,דייר אל-אסד ,133 ,עלי אבן אבי טאלב
490 ,דייר אל-אסד ,114 ,ראס אלחאכורה
490 ,דייר אל-אסד ,149 ,רבאע בשארה
490 ,דייר אל-אסד ,6723 ,שכ אלביארה
490 ,דייר אל-אסד ,6720 ,שכ אלברכה
490 ,דייר אל-אסד ,6710 ,שכ אלח'דר
490 ,דייר אל-אסד ,6711 ,שכ אלעין
490 ,דייר אל-אסד ,6722 ,שכ אלעריד'
490 ,דייר אל-אסד ,6712 ,שכ אלקוס'יבה
490 ,דייר אל-אסד ,6721 ,שכ אלקלעה
490 ,דייר אל-אסד ,143 ,תופיק זיאד
492 ,דייר חנא ,148 ,אבו בכר אל סדיק
492 ,דייר חנא ,105 ,אבו דאג'ר
492 ,דייר חנא ,168 ,אבו חנון
492 ,דייר חנא ,167 ,אבן ח'לדון
492 ,דייר חנא ,117 ,אבן סינא
492 ,דייר חנא ,101 ,אדוארד סעיד
492 ,דייר חנא ,146 ,אל אמיר
492 ,דייר חנא ,193 ,אל אפנדי
492 ,דייר חנא ,178 ,אל בורג'
492 ,דייר חנא ,174 ,אל ביארה
492 ,דייר חנא ,137 ,אל בילאן
492 ,דייר חנא ,202 ,אל ברכה אל ביד'אא
492 ,דייר חנא ,196 ,אל ג'דידה
492 ,דייר חנא ,129 ,אל וורוד
492 ,דייר חנא ,103 ,אל זהראא
492 ,דייר חנא ,153 ,אל זידאני
492 ,דייר חנא ,136 ,אל זיתון
492 ,דייר חנא ,130 ,אל זנבק
492 ,דייר חנא ,135 ,אל זעתר
492 ,דייר חנא ,210 ,אל ח'לה אל ג'רבייה
492 ,דייר חנא ,208 ,אל ח'לה אל שרקייה
492 ,דייר חנא ,143 ,אל ח'רוב
492 ,דייר חנא ,220 ,אל חאווז
492 ,דייר חנא ,172 ,אל חארה אל תחתה
492 ,דייר חנא ,141 ,אל חביב
492 ,דייר חנא ,192 ,אל חדיקה
492 ,דייר חנא ,179 ,אל חוראן
492 ,דייר חנא ,190 ,אל יאסמין
492 ,דייר חנא ,182 ,אל ירמוק
492 ,דייר חנא ,160 ,אל כרמל
492 ,דייר חנא ,198 ,אל מאסיה
492 ,דייר חנא ,128 ,אל מארג'
492 ,דייר חנא ,209 ,אל מוסטפא
492 ,דייר חנא ,211 ,אל מותנבי
492 ,דייר חנא ,216 ,אל מטל
492 ,דייר חנא ,158 ,אל מידאן
492 ,דייר חנא ,219 ,אל מנטקה אל סנאעייה
492 ,דייר חנא ,149 ,אל מנסור
492 ,דייר חנא ,188 ,אל מרוש
492 ,דייר חנא ,214 ,אל נאסרה
492 ,דייר חנא ,185 ,אל נהד'ה
492 ,דייר חנא ,150 ,אל נח'יל
492 ,דייר חנא ,213 ,אל נסר
492 ,דייר חנא ,140 ,אל נרג'ס
492 ,דייר חנא ,134 ,אל סג'רה
492 ,דייר חנא ,157 ,אל סעיד
492 ,דייר חנא ,207 ,אל ספא
492 ,דייר חנא ,206 ,אל עין
492 ,דייר חנא ,132 ,אל ערב
492 ,דייר חנא ,218 ,אל עריד'
492 ,דייר חנא ,200 ,אל פרדוס
492 ,דייר חנא ,177 ,אל פרח
492 ,דייר חנא ,152 ,אל צבאר
492 ,דייר חנא ,217 ,אל קאדסיה
492 ,דייר חנא ,184 ,אל קודס
492 ,דייר חנא ,176 ,אל קלעה
492 ,דייר חנא ,191 ,אל קראג'
492 ,דייר חנא ,142 ,אל רביע
492 ,דייר חנא ,173 ,אל רובעאן
492 ,דייר חנא ,181 ,אל רחמאן
492 ,דייר חנא ,147 ,אל ריחאן
492 ,דייר חנא ,215 ,אל שועאע
492 ,דייר חנא ,163 ,אלאנדלוס
492 ,דייר חנא ,111 ,אלבוסתאן
492 ,דייר חנא ,114 ,אלבוסתנג'י
492 ,דייר חנא ,162 ,אלביאדר
492 ,דייר חנא ,113 ,אלבנאת
492 ,דייר חנא ,108 ,אלברכה
492 ,דייר חנא ,120 ,אלח'וארזמי
492 ,דייר חנא ,164 ,אלח'ליל
492 ,דייר חנא ,110 ,אלחמדיה
492 ,דייר חנא ,154 ,אלמוג'ר
492 ,דייר חנא ,121 ,אלמערי
492 ,דייר חנא ,104 ,אלסהל
492 ,דייר חנא ,145 ,אלסופסאף
492 ,דייר חנא ,165 ,אלסלאם
492 ,דייר חנא ,133 ,אלעביר
492 ,דייר חנא ,119 ,אלראזי
492 ,דייר חנא ,131 ,אלרויס
492 ,דייר חנא ,116 ,אלרויסאת
492 ,דייר חנא ,194 ,אמיל חביבי
492 ,דייר חנא ,195 ,ארבע אל מודיר
492 ,דייר חנא ,159 ,ביסאן
492 ,דייר חנא ,138 ,ביר אל פראן
492 ,דייר חנא ,189 ,בלאל בן רבאח
492 ,דייר חנא ,123 ,ג'דר אלבלד
492 ,דייר חנא ,171 ,ד'אהר אל עומר
492 ,דייר חנא ,9000 ,דייר חנא
492 ,דייר חנא ,125 ,הארון אלרשיד
492 ,דייר חנא ,201 ,הגליל
492 ,דייר חנא ,112 ,ח'לת אלח'סה
492 ,דייר חנא ,118 ,ח'לת אלעונוק
492 ,דייר חנא ,212 ,חזוה
492 ,דייר חנא ,155 ,חטין
492 ,דייר חנא ,161 ,חיפה
492 ,דייר חנא ,102 ,חנא נקארה
492 ,דייר חנא ,175 ,טאהא חוסין
492 ,דייר חנא ,122 ,טארק בן זיאד
492 ,דייר חנא ,151 ,טולעת אל ח'מיסי
492 ,דייר חנא ,203 ,יאפה
492 ,דייר חנא ,139 ,כנעאן
492 ,דייר חנא ,126 ,כרם אבו סמרה
492 ,דייר חנא ,106 ,כרם איוב
492 ,דייר חנא ,205 ,כרם אל ספדי
492 ,דייר חנא ,124 ,כרם אלסביל
492 ,דייר חנא ,115 ,כרם אלשיח'
492 ,דייר חנא ,107 ,כרם ממלוק
492 ,דייר חנא ,127 ,כרם פרחאת
492 ,דייר חנא ,166 ,מי זיאדה
492 ,דייר חנא ,156 ,מערוף אל רסאפי
492 ,דייר חנא ,170 ,מר אליאס
492 ,דייר חנא ,169 ,מר יוחנא
492 ,דייר חנא ,204 ,נסב אלעין
492 ,דייר חנא ,109 ,סולטאן באשא
492 ,דייר חנא ,199 ,עכו
492 ,דייר חנא ,187 ,עלי בן אבי טאלב
492 ,דייר חנא ,186 ,עמאן
492 ,דייר חנא ,144 ,צפת
492 ,דייר חנא ,197 ,קווירי
493 ,דייר ראפאת ,9000 ,דייר ראפאת
2200 ,דימונה ,285 ,אבן גבירול
2200 ,דימונה ,290 ,אבן עזרא
2200 ,דימונה ,452 ,אגוז
2200 ,דימונה ,333 ,אדר
2200 ,דימונה ,434 ,אוריאל פרץ
2200 ,דימונה ,338 ,אזוב
2200 ,דימונה ,6529 ,אזור התעשייה
2200 ,דימונה ,529 ,אחד העם
2200 ,דימונה ,345 ,אלה
2200 ,דימונה ,284 ,אלחריזי
2200 ,דימונה ,440 ,אלי כהן
2200 ,דימונה ,435 ,אלירז פרץ
2200 ,דימונה ,221 ,אמירים
2200 ,דימונה ,356 ,אמנון ותמר
2200 ,דימונה ,443 ,אמנון ליפקין שחק
2200 ,דימונה ,242 ,אסף
2200 ,דימונה ,183 ,אף על פי כן
2200 ,דימונה ,548 ,אפרסק
2200 ,דימונה ,328 ,אקליפטוס
2200 ,דימונה ,110 ,אקסודוס
2200 ,דימונה ,439 ,ארז גרשטיין
2200 ,דימונה ,337 ,אשחר
2200 ,דימונה ,222 ,אשכול
2200 ,דימונה ,118 ,בבא סאלי
2200 ,דימונה ,415 ,בית שערים
2200 ,דימונה ,520 ,בנאי
2200 ,דימונה ,162 ,בר כוכבא
2200 ,דימונה ,516 ,ברזל
2200 ,דימונה ,207 ,ברנהיים
2200 ,דימונה ,334 ,ברקן
2200 ,דימונה ,339 ,בשמת
2200 ,דימונה ,353 ,בת חן
2200 ,דימונה ,306 ,גדיד
2200 ,דימונה ,408 ,גמלא
2200 ,דימונה ,537 ,ד"ר חיים בוגרשוב
2200 ,דימונה ,518 ,ד"ר פליקס זנדמן
2200 ,דימונה ,375 ,דבורה הנביאה
2200 ,דימונה ,539 ,דבורה עומר
2200 ,דימונה ,547 ,דובדבן
2200 ,דימונה ,307 ,דוגית
2200 ,דימונה ,226 ,דוד אלעזר
2200 ,דימונה ,442 ,דוד מימון
2200 ,דימונה ,197 ,דוכיפת
2200 ,דימונה ,340 ,דולב
2200 ,דימונה ,538 ,דיזינגוף
2200 ,דימונה ,278 ,דיין משה
2200 ,דימונה ,9000 ,דימונה
2200 ,דימונה ,517 ,דלקן
2200 ,דימונה ,239 ,דני
2200 ,דימונה ,335 ,דפנה
2200 ,דימונה ,433 ,דרור וינברג
2200 ,דימונה ,321 ,דרך בגין
2200 ,דימונה ,209 ,דרך ברלב
2200 ,דימונה ,276 ,דרך גדולי התורה
2200 ,דימונה ,234 ,דרך הצנחנים
2200 ,דימונה ,189 ,דרך השלום
2200 ,דימונה ,257 ,דרך חיים
2200 ,דימונה ,300 ,דרך יצחק שמיר
2200 ,דימונה ,210 ,דרך רבין
2200 ,דימונה ,132 ,האורן
2200 ,דימונה ,301 ,האילנות
2200 ,דימונה ,158 ,האיריס
2200 ,דימונה ,151 ,האלון
2200 ,דימונה ,105 ,האשל
2200 ,דימונה ,169 ,האתרוג
2200 ,דימונה ,269 ,הבעל שם טוב
2200 ,דימונה ,168 ,הברוש
2200 ,דימונה ,216 ,הגבעה
2200 ,דימונה ,223 ,הגיא
2200 ,דימונה ,104 ,הגפן
2200 ,דימונה ,438 ,הדס מלכא
2200 ,דימונה ,437 ,הדר כהן
2200 ,דימונה ,195 ,הדרור
2200 ,דימונה ,235 ,ההגנה
2200 ,דימונה ,106 ,ההדס
2200 ,דימונה ,102 ,הזית
2200 ,דימונה ,196 ,הזמיר
2200 ,דימונה ,549 ,החיטה
2200 ,דימונה ,174 ,החיל האלמוני
2200 ,דימונה ,166 ,החשמונאים
2200 ,דימונה ,198 ,היונה
2200 ,דימונה ,172 ,היורה
2200 ,דימונה ,527 ,הכינור
2200 ,דימונה ,115 ,הכלנית
2200 ,דימונה ,550 ,הכרם
2200 ,דימונה ,160 ,המכבים
2200 ,דימונה ,119 ,המכתש
2200 ,דימונה ,163 ,המלאכה
2200 ,דימונה ,180 ,המלך אחז
2200 ,דימונה ,181 ,המלך דוד
2200 ,דימונה ,155 ,המלקוש
2200 ,דימונה ,310 ,המסגר
2200 ,דימונה ,192 ,המעלה
2200 ,דימונה ,177 ,המעפיל
2200 ,דימונה ,218 ,המרגנית
2200 ,דימונה ,528 ,הנבל
2200 ,דימונה ,113 ,הנרקיס
2200 ,דימונה ,214 ,הסדנה
2200 ,דימונה ,164 ,הסנהדרין
2200 ,דימונה ,217 ,העליה
2200 ,דימונה ,311 ,העמל
2200 ,דימונה ,176 ,העצמאות
2200 ,דימונה ,312 ,הפועלים
2200 ,דימונה ,191 ,הפסגה
2200 ,דימונה ,531 ,הפעמון
2200 ,דימונה ,551 ,הפרדס
2200 ,דימונה ,143 ,הצאלה
2200 ,דימונה ,173 ,הצבר
2200 ,דימונה ,384 ,הר אדיר
2200 ,דימונה ,365 ,הר ארבל
2200 ,דימונה ,396 ,הר ארגמן
2200 ,דימונה ,386 ,הר גבים
2200 ,דימונה ,382 ,הר גולן
2200 ,דימונה ,392 ,הר גילה
2200 ,דימונה ,397 ,הר גריזים
2200 ,דימונה ,388 ,הר דב
2200 ,דימונה ,383 ,הר הנגב
2200 ,דימונה ,391 ,הר הצופים
2200 ,דימונה ,385 ,הר חזון
2200 ,דימונה ,387 ,הר חרוז
2200 ,דימונה ,363 ,הר חרמון
2200 ,דימונה ,390 ,הר יהל
2200 ,דימונה ,367 ,הר כנען
2200 ,דימונה ,393 ,הר כפיר
2200 ,דימונה ,369 ,הר כרכום
2200 ,דימונה ,368 ,הר כרמל
2200 ,דימונה ,357 ,הר מוריה
2200 ,דימונה ,366 ,הר מירון
2200 ,דימונה ,398 ,הר נבו
2200 ,דימונה ,389 ,הר נס
2200 ,דימונה ,123 ,הר סיני
2200 ,דימונה ,395 ,הר עצמון
2200 ,דימונה ,394 ,הר רביד
2200 ,דימונה ,381 ,הר שגיא
2200 ,דימונה ,380 ,הר שניר
2200 ,דימונה ,364 ,הר תבור
2200 ,דימונה ,268 ,הרב אבוחצירה
2200 ,דימונה ,428 ,הרב אברהם אלמליח
2200 ,דימונה ,525 ,הרב אברהם קוק
2200 ,דימונה ,524 ,הרב אליעזר אבוחצירה
2200 ,דימונה ,267 ,הרב אלנקוה
2200 ,דימונה ,271 ,הרב טייב
2200 ,דימונה ,526 ,הרב מרדכי אליהו
2200 ,דימונה ,523 ,הרב עובדיה יוסף
2200 ,דימונה ,522 ,הרבי מלובאויטש
2200 ,דימונה ,341 ,הרדוף הנחלים
2200 ,דימונה ,420 ,הרודיון
2200 ,דימונה ,521 ,הריח
2200 ,דימונה ,109 ,הרימון
2200 ,דימונה ,296 ,הרכס
2200 ,דימונה ,297 ,הרמה
2200 ,דימונה ,146 ,הרצל
2200 ,דימונה ,108 ,השבלים
2200 ,דימונה ,298 ,השיא
2200 ,דימונה ,152 ,השיטה
2200 ,דימונה ,249 ,השיריון
2200 ,דימונה ,199 ,השלוה
2200 ,דימונה ,552 ,השעורה
2200 ,דימונה ,170 ,השקד
2200 ,דימונה ,103 ,התאנה
2200 ,דימונה ,171 ,התירוש
2200 ,דימונה ,220 ,התפוצות
2200 ,דימונה ,6500 ,וילות טרומים
2200 ,דימונה ,255 ,ורדימון יצחק
2200 ,דימונה ,511 ,זוויתן
2200 ,דימונה ,376 ,חגי
2200 ,דימונה ,500 ,חוות הגמלים כורנוב
2200 ,דימונה ,230 ,חטיבת אלכסנדרוני
2200 ,דימונה ,250 ,חטיבת גבעתי
2200 ,דימונה ,233 ,חטיבת גולני
2200 ,דימונה ,232 ,חטיבת הנגב
2200 ,דימונה ,231 ,חטיבת הראל
2200 ,דימונה ,412 ,חמת גדר
2200 ,דימונה ,184 ,חנה סנש
2200 ,דימונה ,346 ,חצב
2200 ,דימונה ,331 ,חרוב
2200 ,דימונה ,519 ,חרט
2200 ,דימונה ,505 ,חרמש
2200 ,דימונה ,342 ,חרצית
2200 ,דימונה ,513 ,חרש
2200 ,דימונה ,264 ,טבנקין יצחק
2200 ,דימונה ,343 ,טופח
2200 ,דימונה ,557 ,טוראי דוד שירזי
2200 ,דימונה ,178 ,טיילת הספורט
2200 ,דימונה ,124 ,טיילת עמידר
2200 ,דימונה ,291 ,יגור
2200 ,דימונה ,286 ,יהודה הלוי
2200 ,דימונה ,374 ,יואל
2200 ,דימונה ,419 ,יודפת
2200 ,דימונה ,436 ,יוני נתניהו
2200 ,דימונה ,540 ,יצחק בשביס זינגר
2200 ,דימונה ,212 ,יציאת אירופה
2200 ,דימונה ,422 ,ירושלים
2200 ,דימונה ,371 ,ירמיה
2200 ,דימונה ,370 ,ישעיה
2200 ,דימונה ,165 ,כובשי אילת
2200 ,דימונה ,403 ,כורזין
2200 ,דימונה ,6004 ,כיכר אבי שחר
2200 ,דימונה ,6003 ,כיכר גבי סבג
2200 ,דימונה ,6005 ,כיכר עוזי בלהקר
2200 ,דימונה ,6001 ,ככר דנמרק
2200 ,דימונה ,6002 ,ככר ז'בוטינסקי
2200 ,דימונה ,325 ,כליל החורש
2200 ,דימונה ,512 ,להב
2200 ,דימונה ,266 ,לובטקין צביה
2200 ,דימונה ,506 ,לום
2200 ,דימונה ,332 ,ליבנה
2200 ,דימונה ,536 ,לילינבלום
2200 ,דימונה ,352 ,לילך
2200 ,דימונה ,159 ,מ"ג המעפילים
2200 ,דימונה ,281 ,מאיר בעל הנס
2200 ,דימונה ,444 ,מבצע אביב נעורים
2200 ,דימונה ,243 ,מבצע ברוש
2200 ,דימונה ,237 ,מבצע חורב
2200 ,דימונה ,261 ,מבצע יואב
2200 ,דימונה ,448 ,מבצע ליטני
2200 ,דימונה ,447 ,מבצע מנורה
2200 ,דימונה ,253 ,מבצע עובדה
2200 ,דימונה ,446 ,מבצע שלכת
2200 ,דימונה ,445 ,מבצע שלמה
2200 ,דימונה ,401 ,מבצר עכו
2200 ,דימונה ,507 ,מגל
2200 ,דימונה ,451 ,מגלן
2200 ,דימונה ,509 ,מגנט
2200 ,דימונה ,246 ,מדבר יהודה
2200 ,דימונה ,245 ,מדבר סיני
2200 ,דימונה ,200 ,מודיעין
2200 ,דימונה ,259 ,מולדה
2200 ,דימונה ,418 ,מונפור
2200 ,דימונה ,305 ,מורג
2200 ,דימונה ,330 ,מורן החורש
2200 ,דימונה ,194 ,מזור
2200 ,דימונה ,7001 ,מחנה עדי
2200 ,דימונה ,201 ,מילרוד אליעזר
2200 ,דימונה ,379 ,מלאכי
2200 ,דימונה ,101 ,מלכה הנרי
2200 ,דימונה ,502 ,מסור
2200 ,דימונה ,6501 ,מעברה
2200 ,דימונה ,260 ,מעון טללים
2200 ,דימונה ,320 ,מעלה ההר
2200 ,דימונה ,114 ,מצדה
2200 ,דימונה ,347 ,מרווה
2200 ,דימונה ,190 ,מרחבים
2200 ,דימונה ,377 ,מרים הנביאה
2200 ,דימונה ,133 ,מרכז קליטה
2200 ,דימונה ,503 ,מרצע
2200 ,דימונה ,116 ,משטרה
2200 ,דימונה ,252 ,משעול ארז
2200 ,דימונה ,236 ,משעול ההגנה
2200 ,דימונה ,248 ,משעול החירות
2200 ,דימונה ,224 ,משעול הסנונית
2200 ,דימונה ,274 ,משעול הרעות
2200 ,דימונה ,273 ,משעול התקוה
2200 ,דימונה ,247 ,משעול התקומה
2200 ,דימונה ,241 ,משעול חירם
2200 ,דימונה ,227 ,משעול רגבים
2200 ,דימונה ,238 ,משעול רוגל
2200 ,דימונה ,225 ,משעול שלדג
2200 ,דימונה ,161 ,מתתיהו הכהן
2200 ,דימונה ,6530 ,נאות הללי
2200 ,דימונה ,6535 ,נאות קטיף
2200 ,דימונה ,202 ,נבטים
2200 ,דימונה ,256 ,נגבה
2200 ,דימונה ,6513 ,נוה אביב
2200 ,דימונה ,6515 ,נוה חורש
2200 ,דימונה ,117 ,נוה מדבר
2200 ,דימונה ,208 ,נוה שלום
2200 ,דימונה ,348 ,נורית
2200 ,דימונה ,372 ,נחום
2200 ,דימונה ,532 ,נחלת בנימין
2200 ,דימונה ,203 ,נטעים
2200 ,דימונה ,121 ,ניצנה
2200 ,דימונה ,515 ,נפח
2200 ,דימונה ,204 ,נצנים
2200 ,דימונה ,308 ,נצרים
2200 ,דימונה ,295 ,נתיב הל"ה
2200 ,דימונה ,282 ,נתיב הנחש
2200 ,דימונה ,213 ,נתיב הצפורים
2200 ,דימונה ,182 ,נתיב יותם
2200 ,דימונה ,373 ,נתן הנביא
2200 ,דימונה ,554 ,סא"ל עמנואל מורנו
2200 ,דימונה ,147 ,סביון
2200 ,דימונה ,504 ,סדן
2200 ,דימונה ,508 ,סולם
2200 ,דימונה ,354 ,סחלב
2200 ,דימונה ,326 ,סחלבן החורש
2200 ,דימונה ,185 ,סטרומה
2200 ,דימונה ,344 ,סיגלון
2200 ,דימונה ,351 ,סיתונית
2200 ,דימונה ,263 ,סלא איירין
2200 ,דימונה ,556 ,סרן יובל נריה
2200 ,דימונה ,555 ,סרן עודד אמיר
2200 ,דימונה ,130 ,עבדת
2200 ,דימונה ,228 ,עופר אברהם
2200 ,דימונה ,148 ,עזרא ונחמיה
2200 ,דימונה ,167 ,עיינות
2200 ,דימונה ,423 ,עין גדי
2200 ,דימונה ,205 ,עלומים
2200 ,דימונה ,206 ,עלית הנוער
2200 ,דימונה ,378 ,עמוס
2200 ,דימונה ,193 ,ערוגות
2200 ,דימונה ,542 ,פ' שמואל יוסף עגנון
2200 ,דימונה ,6551 ,פארק תעשיה רותם
2200 ,דימונה ,279 ,פונדק דרכים
2200 ,דימונה ,501 ,פטיש
2200 ,דימונה ,407 ,פטרה
2200 ,דימונה ,272 ,פינטו חיים
2200 ,דימונה ,514 ,פלדה
2200 ,דימונה ,510 ,פלס
2200 ,דימונה ,414 ,פקיעין
2200 ,דימונה ,336 ,פרג
2200 ,דימונה ,546 ,פרופ' אהרון צ'חנובר
2200 ,דימונה ,543 ,פרופ' דן שכטמן
2200 ,דימונה ,545 ,פרופ' דניאל כהנמן
2200 ,דימונה ,544 ,פרופ' ישראל אומן
2200 ,דימונה ,541 ,פרופ' עדה יונת
2200 ,דימונה ,157 ,פרחי ארצנו
2200 ,דימונה ,349 ,צבעוני
2200 ,דימונה ,265 ,צוקרמן יצחק רחבה
2200 ,דימונה ,362 ,צורית
2200 ,דימונה ,404 ,ציפורי
2200 ,דימונה ,350 ,ציפורן
2200 ,דימונה ,360 ,צפצפה
2200 ,דימונה ,289 ,קארו יוסף
2200 ,דימונה ,219 ,קדמה
2200 ,דימונה ,120 ,קדש
2200 ,דימונה ,293 ,קהילת בריטניה
2200 ,דימונה ,6540 ,קהילת העבריים
2200 ,דימונה ,413 ,קומראן
2200 ,דימונה ,327 ,קטלב
2200 ,דימונה ,410 ,קיסריה
2200 ,דימונה ,417 ,קלעת נמרוד
2200 ,דימונה ,530 ,קלרינט
2200 ,דימונה ,399 ,קניון פרץ סנטר
2200 ,דימונה ,145 ,קציעות
2200 ,דימונה ,409 ,קצרין
2200 ,דימונה ,6550 ,קריה למחקר גרעיני
2200 ,דימונה ,6555 ,קרית המלאכה
2200 ,דימונה ,6554 ,קרית העמל
2200 ,דימונה ,6553 ,קרית התעשיה
2200 ,דימונה ,6503 ,קרית חנוך
2200 ,דימונה ,535 ,קרליבך
2200 ,דימונה ,405 ,קרני חיטין
2200 ,דימונה ,534 ,רבי נחמן מאומן
2200 ,דימונה ,533 ,רוטשילד
2200 ,דימונה ,432 ,רועי קליין
2200 ,דימונה ,303 ,רזיאל דוד
2200 ,דימונה ,144 ,רחבת אורון
2200 ,דימונה ,186 ,רחבת האיסיים
2200 ,דימונה ,244 ,רחבת האצ"ל
2200 ,דימונה ,142 ,רחבת האשלג
2200 ,דימונה ,229 ,רחבת הבנים
2200 ,דימונה ,254 ,רחבת הלח"י
2200 ,דימונה ,292 ,רחבת הנח"ל
2200 ,דימונה ,135 ,רחבת הסוכנות
2200 ,דימונה ,240 ,רחבת הפלמ"ח
2200 ,דימונה ,262 ,רחבת יונתן
2200 ,דימונה ,280 ,רחבת רמב"ם
2200 ,דימונה ,270 ,רחבת רש"י
2200 ,דימונה ,6504 ,רמת עופר
2200 ,דימונה ,156 ,רקפת
2200 ,דימונה ,6520 ,ש 4 קומות
2200 ,דימונה ,6505 ,ש אסבסט א ב
2200 ,דימונה ,6506 ,ש אסבסט ג
2200 ,דימונה ,6508 ,ש בתי אבן
2200 ,דימונה ,6509 ,ש הנצחון
2200 ,דימונה ,6511 ,ש טרומיים
2200 ,דימונה ,6512 ,ש לדוגמה
2200 ,דימונה ,6516 ,ש קומותיים
2200 ,דימונה ,6517 ,ש קליפורניה
2200 ,דימונה ,6518 ,ש רכבת
2200 ,דימונה ,211 ,שאול המלך
2200 ,דימונה ,288 ,שבזי שלום
2200 ,דימונה ,122 ,שבטי ישראל
2200 ,דימונה ,187 ,שד בן גוריון
2200 ,דימונה ,107 ,שד גולדה מאיר
2200 ,דימונה ,111 ,שד יגאל אלון
2200 ,דימונה ,251 ,שדה יצחק
2200 ,דימונה ,275 ,שדרות אריאל שרון
2200 ,דימונה ,450 ,שועלי מרום
2200 ,דימונה ,149 ,שוק עירוני
2200 ,דימונה ,355 ,שושן צחור
2200 ,דימונה ,112 ,שושנים
2200 ,דימונה ,302 ,שטרן יאיר
2200 ,דימונה ,406 ,שיבטה
2200 ,דימונה ,329 ,שיזף
2200 ,דימונה ,449 ,שייטת 13
2200 ,דימונה ,361 ,שיקמה
2200 ,דימונה ,6522 ,שכ בנה ביתך
2200 ,דימונה ,6541 ,שכ בסט
2200 ,דימונה ,6531 ,שכ הגבעה
2200 ,דימונה ,6532 ,שכ הערבה
2200 ,דימונה ,6536 ,שכ הר נוף
2200 ,דימונה ,6526 ,שכ חכמי ישראל
2200 ,דימונה ,6523 ,שכ יוספטל
2200 ,דימונה ,6552 ,שכ כפר הסטודנטים
2200 ,דימונה ,6528 ,שכ מלכי ישראל
2200 ,דימונה ,6521 ,שכ ממשית
2200 ,דימונה ,6538 ,שכ מעלה נתניהו
2200 ,דימונה ,6537 ,שכ מעלה שחק
2200 ,דימונה ,6524 ,שכ נוה גן
2200 ,דימונה ,6502 ,שכ נווה דוד
2200 ,דימונה ,6507 ,שכ נופי מדבר
2200 ,דימונה ,6527 ,שכ פרחי ארצנו
2200 ,דימונה ,6534 ,שכ קדמה
2200 ,דימונה ,6545 ,שכ שבטי ישראל
2200 ,דימונה ,6510 ,שכ שבעת המינים
2200 ,דימונה ,6533 ,שכ' השחר
2200 ,דימונה ,6525 ,שכון 3 קומות
2200 ,דימונה ,287 ,שמעון בר יוחאי
2200 ,דימונה ,441 ,שרה אהרונסון
2200 ,דימונה ,553 ,תא"ל אביגדור קהלני
2200 ,דימונה ,416 ,תל אפק
2200 ,דימונה ,429 ,תל בית אל
2200 ,דימונה ,125 ,תל גבעון
2200 ,דימונה ,426 ,תל דן
2200 ,דימונה ,400 ,תל חמה
2200 ,דימונה ,424 ,תל לכיש
2200 ,דימונה ,431 ,תל מגידו
2200 ,דימונה ,430 ,תל מקנה
2200 ,דימונה ,402 ,תל ערד
2200 ,דימונה ,411 ,תל פורן
2200 ,דימונה ,425 ,תל ציפורי
2200 ,דימונה ,427 ,תל קיסון
2063 ,דישון ,9000 ,דישון
300 ,דליה ,9000 ,דליה
431 ,דלתון ,9000 ,דלתון
1317 ,דמיידה ,103 ,אל ביידר
1317 ,דמיידה ,116 ,אל ביר
1317 ,דמיידה ,115 ,אל ג'וז
1317 ,דמיידה ,105 ,אל וואדי
1317 ,דמיידה ,110 ,אל ווסעה
1317 ,דמיידה ,113 ,אל וורוד
1317 ,דמיידה ,117 ,אל חלל
1317 ,דמיידה ,106 ,אל חקורה
1317 ,דמיידה ,114 ,אל חרוב
1317 ,דמיידה ,109 ,אל מיג'אנה
1317 ,דמיידה ,104 ,אל עגד
1317 ,דמיידה ,111 ,אל עין
1317 ,דמיידה ,108 ,אל רובעאן
1317 ,דמיידה ,112 ,אל ת'ינה
1317 ,דמיידה ,9000 ,דמיידה
1317 ,דמיידה ,107 ,כולת אל עג'ווה
303 ,דן ,9000 ,דן
302 ,דפנה ,101 ,בית ראשון במולדת
302 ,דפנה ,9000 ,דפנה
1241 ,דקל ,101 ,אורן
1241 ,דקל ,102 ,אלה
1241 ,דקל ,103 ,אשל
1241 ,דקל ,9000 ,דקל
1241 ,דקל ,6500 ,ש קרוונים
1241 ,דקל ,104 ,תמר
1349 ,דריג'את ,9000 ,דריג'את
1349 ,דריג'את ,110 ,רח 10
1349 ,דריג'את ,120 ,רח 20
1349 ,דריג'את ,130 ,רח 30
1349 ,דריג'את ,101 ,רח 1
1349 ,דריג'את ,102 ,רח 2
1349 ,דריג'את ,112 ,רח 12
1349 ,דריג'את ,122 ,רח 22
1349 ,דריג'את ,132 ,רח 32
1349 ,דריג'את ,103 ,רח 3
1349 ,דריג'את ,104 ,רח 4
1349 ,דריג'את ,114 ,רח 14
1349 ,דריג'את ,124 ,רח 24
1349 ,דריג'את ,134 ,רח 34
1349 ,דריג'את ,105 ,רח 5
1349 ,דריג'את ,106 ,רח 6
1349 ,דריג'את ,116 ,רח 16
1349 ,דריג'את ,126 ,רח 26
1349 ,דריג'את ,136 ,רח 36
1349 ,דריג'את ,107 ,רח 7
1349 ,דריג'את ,108 ,רח 8
1349 ,דריג'את ,118 ,רח 18
1349 ,דריג'את ,128 ,רח 28
1349 ,דריג'את ,138 ,רח 38
1349 ,דריג'את ,109 ,רח 9
1349 ,דריג'את ,6500 ,שכ ב
702 ,האון ,9000 ,האון
675 ,הבונים ,104 ,גלים
675 ,הבונים ,107 ,דרך הים
675 ,הבונים ,9000 ,הבונים
675 ,הבונים ,105 ,החרוב
675 ,הבונים ,102 ,הצדפים
675 ,הבונים ,101 ,כרמל וים
675 ,הבונים ,103 ,נוף ים
675 ,הבונים ,109 ,סמטת החצב
675 ,הבונים ,108 ,סמטת הנרקיסים
675 ,הבונים ,111 ,סמטת הרקפות
675 ,הבונים ,110 ,סמטת נר הלילה
675 ,הבונים ,106 ,שונית
356 ,הגושרים ,101 ,בית ראשון
356 ,הגושרים ,9000 ,הגושרים
191 ,הדר עם ,106 ,האסיף
191 ,הדר עם ,101 ,האקליפטוס
191 ,הדר עם ,119 ,הבציר
191 ,הדר עם ,116 ,הברוש
191 ,הדר עם ,9000 ,הדר עם
191 ,הדר עם ,102 ,ההדרים
191 ,הדר עם ,107 ,ההולנדים
191 ,הדר עם ,108 ,הזורע
191 ,הדר עם ,109 ,החריש
191 ,הדר עם ,118 ,היבול
191 ,הדר עם ,110 ,הלימון
191 ,הדר עם ,120 ,המסיק
191 ,הדר עם ,111 ,הנוטע
191 ,הדר עם ,104 ,הפרדס
191 ,הדר עם ,112 ,הקטיף
191 ,הדר עם ,113 ,הקציר
191 ,הדר עם ,105 ,הראשונים
191 ,הדר עם ,121 ,התירוש
191 ,הדר עם ,117 ,התפוז
191 ,הדר עם ,114 ,זו הדרך
191 ,הדר עם ,115 ,נבטים
191 ,הדר עם ,103 ,שדרת הכפר
9700 ,הוד השרון ,405 ,אביב
9700 ,הוד השרון ,458 ,אדר
9700 ,הוד השרון ,317 ,אהבה
9700 ,הוד השרון ,438 ,אהובה עוזרי
9700 ,הוד השרון ,298 ,אהוד בן גרא
9700 ,הוד השרון ,534 ,אהרונסון אהרון
9700 ,הוד השרון ,6527 ,אזור תעשיה
9700 ,הוד השרון ,6526 ,אזור תעשיה נוה נאמן
9700 ,הוד השרון ,179 ,אח"י דקר
9700 ,הוד השרון ,303 ,אחוה
9700 ,הוד השרון ,510 ,איזמרגד
9700 ,הוד השרון ,153 ,איילת השחר
9700 ,הוד השרון ,459 ,אייר
9700 ,הוד השרון ,336 ,אילת
9700 ,הוד השרון ,460 ,אלול
9700 ,הוד השרון ,203 ,אלוף הנצחון
9700 ,הוד השרון ,319 ,אלי כהן
9700 ,הוד השרון ,389 ,אלי מצד
9700 ,הוד השרון ,560 ,אליהו הנביא
9700 ,הוד השרון ,161 ,אלכסנדר הגדול
9700 ,הוד השרון ,192 ,אלעזר
9700 ,הוד השרון ,249 ,אלתרמן
9700 ,הוד השרון ,550 ,אם הקבוצות
9700 ,הוד השרון ,314 ,אנה פרנק
9700 ,הוד השרון ,312 ,אנצילביץ
9700 ,הוד השרון ,145 ,אנשי בראשית
9700 ,הוד השרון ,156 ,אנשי הכנסת הגדולה
9700 ,הוד השרון ,344 ,אסירי ציון
9700 ,הוד השרון ,186 ,אסף הרופא
9700 ,הוד השרון ,191 ,אפק
9700 ,הוד השרון ,383 ,ארבל
9700 ,הוד השרון ,117 ,ארלוזורוב
9700 ,הוד השרון ,357 ,אשכול
9700 ,הוד השרון ,175 ,בארי
9700 ,הוד השרון ,122 ,בורוכוב
9700 ,הוד השרון ,246 ,ביאליק
9700 ,הוד השרון ,173 ,ביח גן מגד
9700 ,הוד השרון ,328 ,ביכורים
9700 ,הוד השרון ,552 ,בית אורן
9700 ,הוד השרון ,559 ,בית קמה
9700 ,הוד השרון ,416 ,בן אב"י איתמר
9700 ,הוד השרון ,110 ,בן גוריון
9700 ,הוד השרון ,104 ,בן גמלא יהושע
9700 ,הוד השרון ,245 ,בן יהודה
9700 ,הוד השרון ,537 ,בן יוסף שלמה
9700 ,הוד השרון ,409 ,בן צבי יצחק
9700 ,הוד השרון ,129 ,בני ברית
9700 ,הוד השרון ,442 ,בצת
9700 ,הוד השרון ,264 ,בר לב חיים
9700 ,הוד השרון ,272 ,ברקת
9700 ,הוד השרון ,417 ,בשביס זינגר
9700 ,הוד השרון ,235 ,בת חן
9700 ,הוד השרון ,388 ,גבעת התצפית
9700 ,הוד השרון ,557 ,גברעם
9700 ,הוד השרון ,486 ,גולדה מאיר
9700 ,הוד השרון ,318 ,גולומב
9700 ,הוד השרון ,268 ,גור מרדכי
9700 ,הוד השרון ,124 ,גורדון
9700 ,הוד השרון ,511 ,גזית
9700 ,הוד השרון ,582 ,גיתית
9700 ,הוד השרון ,349 ,גלבוע
9700 ,הוד השרון ,490 ,גלגל המזלות
9700 ,הוד השרון ,6500 ,גני צבי
9700 ,הוד השרון ,536 ,גרונר דב
9700 ,הוד השרון ,275 ,גרניט
9700 ,הוד השרון ,397 ,גשמי ברכה
9700 ,הוד השרון ,452 ,דבורה
9700 ,הוד השרון ,434 ,דגניה
9700 ,הוד השרון ,454 ,דגנית
9700 ,הוד השרון ,394 ,דובדבן
9700 ,הוד השרון ,263 ,דוד אלעזר
9700 ,הוד השרון ,292 ,דוד המלך
9700 ,הוד השרון ,284 ,דוכיפת
9700 ,הוד השרון ,244 ,דור ההמשך
9700 ,הוד השרון ,267 ,דורי יעקב
9700 ,הוד השרון ,269 ,דיין משה
9700 ,הוד השרון ,358 ,דפנה
9700 ,הוד השרון ,238 ,דרך הים
9700 ,הוד השרון ,436 ,דרך כפר הדר
9700 ,הוד השרון ,301 ,דרך מגדיאל
9700 ,הוד השרון ,101 ,דרך רמתים
9700 ,הוד השרון ,221 ,האגודה
9700 ,הוד השרון ,512 ,האודם
9700 ,הוד השרון ,392 ,האוכמניות
9700 ,הוד השרון ,151 ,האורנים
9700 ,הוד השרון ,343 ,האיחוד
9700 ,הוד השרון ,180 ,האילנות
9700 ,הוד השרון ,128 ,האירוס
9700 ,הוד השרון ,256 ,האצ"ל
9700 ,הוד השרון ,218 ,האשל
9700 ,הוד השרון ,205 ,הבבלי
9700 ,הוד השרון ,233 ,הבוסתן
9700 ,הוד השרון ,402 ,הבנאי
9700 ,הוד השרון ,421 ,הבנות
9700 ,הוד השרון ,111 ,הבנים
9700 ,הוד השרון ,211 ,הברוש
9700 ,הוד השרון ,370 ,הבריח
9700 ,הוד השרון ,227 ,הגאולה
9700 ,הוד השרון ,333 ,הגולן
9700 ,הוד השרון ,237 ,הגיא
9700 ,הוד השרון ,109 ,הגיבור
9700 ,הוד השרון ,217 ,הגלעד
9700 ,הוד השרון ,120 ,הגן
9700 ,הוד השרון ,222 ,הגנים
9700 ,הוד השרון ,210 ,הגפן
9700 ,הוד השרון ,150 ,הגשר
9700 ,הוד השרון ,234 ,הדסים
9700 ,הוד השרון ,219 ,הדקלים
9700 ,הוד השרון ,116 ,הדרור
9700 ,הוד השרון ,360 ,הדרים
9700 ,הוד השרון ,137 ,ההגנה
9700 ,הוד השרון ,225 ,ההכשרות
9700 ,הוד השרון ,113 ,ההסתדרות
9700 ,הוד השרון ,9000 ,הוד השרון
9700 ,הוד השרון ,121 ,הורד
9700 ,הוד השרון ,387 ,הזוהר
9700 ,הוד השרון ,386 ,החומש
9700 ,הוד השרון ,228 ,החייל
9700 ,הוד השרון ,345 ,החלוצים
9700 ,הוד השרון ,352 ,החקלאי
9700 ,הוד השרון ,334 ,החרמון
9700 ,הוד השרון ,200 ,החרש
9700 ,הוד השרון ,163 ,החשמונאים
9700 ,הוד השרון ,165 ,הידיד
9700 ,הוד השרון ,204 ,היובלים
9700 ,הוד השרון ,119 ,היער
9700 ,הוד השרון ,340 ,הירדן
9700 ,הוד השרון ,274 ,הכהנים
9700 ,הוד השרון ,187 ,הכלנית
9700 ,הוד השרון ,182 ,הכרם
9700 ,הוד השרון ,216 ,הכרמל
9700 ,הוד השרון ,198 ,הכשרת הישוב
9700 ,הוד השרון ,435 ,הלויים
9700 ,הוד השרון ,261 ,הלח"י
9700 ,הוד השרון ,346 ,המגן
9700 ,הוד השרון ,224 ,המדרגות
9700 ,הוד השרון ,230 ,המוסדות
9700 ,הוד השרון ,252 ,המחתרות
9700 ,הוד השרון ,220 ,המיסד
9700 ,הוד השרון ,164 ,המכבים
9700 ,הוד השרון ,240 ,המכונאי
9700 ,הוד השרון ,143 ,המנחם
9700 ,הוד השרון ,362 ,המסגר
9700 ,הוד השרון ,214 ,המעבר
9700 ,הוד השרון ,335 ,המעגל
9700 ,הוד השרון ,236 ,המעלה
9700 ,הוד השרון ,213 ,המעלות
9700 ,הוד השרון ,361 ,המעפיל
9700 ,הוד השרון ,363 ,המקשר
9700 ,הוד השרון ,342 ,המתנחלים
9700 ,הוד השרון ,339 ,הנגב
9700 ,הוד השרון ,401 ,הנגר
9700 ,הוד השרון ,170 ,הנוטרים
9700 ,הוד השרון ,324 ,הנחל
9700 ,הוד השרון ,125 ,הנרקיס
9700 ,הוד השרון ,243 ,הסביון
9700 ,הוד השרון ,374 ,הסדן
9700 ,הוד השרון ,135 ,הסוכנות
9700 ,הוד השרון ,348 ,הסרגל
9700 ,הוד השרון ,146 ,העבודה
9700 ,הוד השרון ,325 ,העובד הציוני
9700 ,הוד השרון ,215 ,העליות
9700 ,הוד השרון ,115 ,העמל
9700 ,הוד השרון ,195 ,העמק
9700 ,הוד השרון ,231 ,הערבה
9700 ,הוד השרון ,375 ,הפטיש
9700 ,הוד השרון ,391 ,הפטל
9700 ,הוד השרון ,149 ,הפרדס
9700 ,הוד השרון ,350 ,הפרחים
9700 ,הוד השרון ,190 ,הפרסה
9700 ,הוד השרון ,126 ,הצבי
9700 ,הוד השרון ,189 ,הצבעוני
9700 ,הוד השרון ,351 ,הצברים
9700 ,הוד השרון ,194 ,הציונות
9700 ,הוד השרון ,376 ,הצנחנים
9700 ,הוד השרון ,412 ,הר מירון
9700 ,הוד השרון ,384 ,הר שגיא
9700 ,הוד השרון ,141 ,הראשונים
9700 ,הוד השרון ,181 ,הרב ביטון
9700 ,הוד השרון ,188 ,הרב שלום נגר
9700 ,הוד השרון ,461 ,הרדוף
9700 ,הוד השרון ,338 ,הרמה
9700 ,הוד השרון ,488 ,הרצוג חיים
9700 ,הוד השרון ,315 ,הרצל
9700 ,הוד השרון ,364 ,הרצפלד אברהם
9700 ,הוד השרון ,159 ,הרקון
9700 ,הוד השרון ,176 ,הרשות
9700 ,הוד השרון ,166 ,השומר
9700 ,הוד השרון ,226 ,השושנים
9700 ,הוד השרון ,365 ,השחר
9700 ,הוד השרון ,483 ,השיטה
9700 ,הוד השרון ,123 ,השלום
9700 ,הוד השרון ,118 ,השלושה
9700 ,הוד השרון ,114 ,השקמים
9700 ,הוד השרון ,355 ,התחיה
9700 ,הוד השרון ,144 ,התכלת
9700 ,הוד השרון ,102 ,התלמיד
9700 ,הוד השרון ,155 ,התמר
9700 ,הוד השרון ,393 ,התפוח
9700 ,הוד השרון ,106 ,התקומה
9700 ,הוד השרון ,411 ,וייצמן חיים
9700 ,הוד השרון ,199 ,ז'בוטינסקי
9700 ,הוד השרון ,440 ,זוויתן
9700 ,הוד השרון ,533 ,זייד אלכסנדר
9700 ,הוד השרון ,424 ,זלדה
9700 ,הוד השרון ,280 ,זמיר
9700 ,הוד השרון ,310 ,זקיף
9700 ,הוד השרון ,462 ,חבצלת
9700 ,הוד השרון ,463 ,חודשי השנה
9700 ,הוד השרון ,285 ,חוחית
9700 ,הוד השרון ,513 ,חולות נודדים
9700 ,הוד השרון ,258 ,חטיבה שבע
9700 ,הוד השרון ,168 ,חטיבת גבעתי
9700 ,הוד השרון ,304 ,חטיבת גולני
9700 ,הוד השרון ,257 ,חטיבת הראל
9700 ,הוד השרון ,260 ,חטיבת יפתח
9700 ,הוד השרון ,514 ,חלוקי נחל
9700 ,הוד השרון ,589 ,חליל
9700 ,הוד השרון ,277 ,חלמיש
9700 ,הוד השרון ,484 ,חמנית
9700 ,הוד השרון ,308 ,חנקין
9700 ,הוד השרון ,464 ,חצב
9700 ,הוד השרון ,586 ,חצוצרה
9700 ,הוד השרון ,457 ,חרצית
9700 ,הוד השרון ,465 ,חשוון
9700 ,הוד השרון ,466 ,טבת
9700 ,הוד השרון ,287 ,טווס
9700 ,הוד השרון ,515 ,טופז
9700 ,הוד השרון ,366 ,טרומפלדור
9700 ,הוד השרון ,247 ,טשרניחובסקי
9700 ,הוד השרון ,248 ,י ל פרץ
9700 ,הוד השרון ,379 ,ידידות
9700 ,הוד השרון ,266 ,ידין יגאל
9700 ,הוד השרון ,197 ,יהודה
9700 ,הוד השרון ,196 ,יהונתן
9700 ,הוד השרון ,297 ,יהושע בן נון
9700 ,הוד השרון ,516 ,יהלום
9700 ,הוד השרון ,183 ,יוחנן
9700 ,הוד השרון ,429 ,יונה וולך
9700 ,הוד השרון ,291 ,יונק הדבש
9700 ,הוד השרון ,138 ,יורדי הים
9700 ,הוד השרון ,563 ,יחזקאל
9700 ,הוד השרון ,306 ,יסוד המעלה
9700 ,הוד השרון ,467 ,יסמין
9700 ,הוד השרון ,532 ,יצחק שדה
9700 ,הוד השרון ,468 ,יקינטון
9700 ,הוד השרון ,207 ,ירושלמי
9700 ,הוד השרון ,445 ,ירמוך
9700 ,הוד השרון ,562 ,ירמיהו
9700 ,הוד השרון ,107 ,ישורון
9700 ,הוד השרון ,564 ,ישעיהו
9700 ,הוד השרון ,517 ,ישפה
9700 ,הוד השרון ,172 ,ישראלי הרופא
9700 ,הוד השרון ,229 ,כוכבן
9700 ,הוד השרון ,448 ,כזיב
9700 ,הוד השרון ,6005 ,כיכר ותיקי העיר
9700 ,הוד השרון ,584 ,כינור
9700 ,הוד השרון ,6001 ,ככר אוסישקין
9700 ,הוד השרון ,6002 ,ככר המגינים
9700 ,הוד השרון ,6004 ,ככר טוביה
9700 ,הוד השרון ,341 ,כנרת
9700 ,הוד השרון ,469 ,כסלו
9700 ,הוד השרון ,6501 ,כפר הדר
9700 ,הוד השרון ,555 ,כפר מנחם
9700 ,הוד השרון ,232 ,כרכום
9700 ,הוד השרון ,270 ,לב השכונה
9700 ,הוד השרון ,470 ,לוטם
9700 ,הוד השרון ,407 ,לילך
9700 ,הוד השרון ,265 ,לסקוב חיים
9700 ,הוד השרון ,518 ,לשם
9700 ,הוד השרון ,415 ,מאנגר איציק
9700 ,הוד השרון ,398 ,מבוא חמה
9700 ,הוד השרון ,403 ,מבוא קדם
9700 ,הוד השרון ,378 ,מבוי החרש
9700 ,הוד השרון ,337 ,מבצע משה
9700 ,הוד השרון ,322 ,מבצע קדש
9700 ,הוד השרון ,331 ,מבצע שלמה
9700 ,הוד השרון ,6505 ,מגדיאל
9700 ,הוד השרון ,162 ,מודיעין
9700 ,הוד השרון ,367 ,מולדת
9700 ,הוד השרון ,323 ,מוסד מוסנזון
9700 ,הוד השרון ,212 ,מורדי הגטאות
9700 ,הוד השרון ,380 ,מורשת
9700 ,הוד השרון ,491 ,מזל אריה
9700 ,הוד השרון ,509 ,מזל בתולה
9700 ,הוד השרון ,492 ,מזל גדי
9700 ,הוד השרון ,493 ,מזל דגים
9700 ,הוד השרון ,494 ,מזל דלי
9700 ,הוד השרון ,495 ,מזל טלה
9700 ,הוד השרון ,496 ,מזל מאזניים
9700 ,הוד השרון ,497 ,מזל עקרב
9700 ,הוד השרון ,498 ,מזל קשת
9700 ,הוד השרון ,499 ,מזל שור
9700 ,הוד השרון ,500 ,מזל תאומים
9700 ,הוד השרון ,455 ,מיטל
9700 ,הוד השרון ,590 ,מיתר
9700 ,הוד השרון ,206 ,מנוחה ונחלה
9700 ,הוד השרון ,580 ,מנצח
9700 ,הוד השרון ,309 ,מעיני
9700 ,הוד השרון ,593 ,מפוחית
9700 ,הוד השרון ,369 ,מצדה
9700 ,הוד השרון ,447 ,מצובה
9700 ,הוד השרון ,592 ,מצילתיים
9700 ,הוד השרון ,356 ,מרגוע
9700 ,הוד השרון ,293 ,מרגלית
9700 ,הוד השרון ,368 ,מרחביה
9700 ,הוד השרון ,157 ,מרים החשמונאית
9700 ,הוד השרון ,112 ,משאבים
9700 ,הוד השרון ,446 ,משושים
9700 ,הוד השרון ,171 ,משעול הגפנים
9700 ,הוד השרון ,332 ,מתתיהו
9700 ,הוד השרון ,433 ,נופית
9700 ,הוד השרון ,519 ,נופך
9700 ,הוד השרון ,471 ,נורית
9700 ,הוד השרון ,507 ,נחל בשור
9700 ,הוד השרון ,501 ,נחל דליות
9700 ,הוד השרון ,426 ,נחל הדס
9700 ,הוד השרון ,428 ,נחל הדר
9700 ,הוד השרון ,427 ,נחל ירקון
9700 ,הוד השרון ,502 ,נחל ערוגות
9700 ,הוד השרון ,503 ,נחל פרצים
9700 ,הוד השרון ,506 ,נחל צין
9700 ,הוד השרון ,425 ,נחל קנה
9700 ,הוד השרון ,288 ,נחליאלי
9700 ,הוד השרון ,382 ,נחשון
9700 ,הוד השרון ,347 ,נטעים
9700 ,הוד השרון ,329 ,ניצנים
9700 ,הוד השרון ,201 ,נעורים
9700 ,הוד השרון ,439 ,נעמי שמר
9700 ,הוד השרון ,307 ,נצח ישראל
9700 ,הוד השרון ,209 ,סאלד הנריאטה
9700 ,הוד השרון ,311 ,סוקולוב
9700 ,הוד השרון ,422 ,סטפניה
9700 ,הוד השרון ,473 ,סיון
9700 ,הוד השרון ,167 ,סיני
9700 ,הוד השרון ,474 ,סיתונית
9700 ,הוד השרון ,432 ,סמ אבני דרך
9700 ,הוד השרון ,184 ,סמ אגוז
9700 ,הוד השרון ,395 ,סמ אדמונית
9700 ,הוד השרון ,410 ,סמ אילת
9700 ,הוד השרון ,103 ,סמ בית הבד
9700 ,הוד השרון ,359 ,סמ האלון
9700 ,הוד השרון ,485 ,סמ האקליפטוס
9700 ,הוד השרון ,134 ,סמ האתרוג
9700 ,הוד השרון ,139 ,סמ הדבש
9700 ,הוד השרון ,142 ,סמ הזית
9700 ,הוד השרון ,136 ,סמ החיטה
9700 ,הוד השרון ,105 ,סמ הי"ח
9700 ,הוד השרון ,154 ,סמ הירק
9700 ,הוד השרון ,133 ,סמ הלולב
9700 ,הוד השרון ,420 ,סמ הר גילה
9700 ,הוד השרון ,132 ,סמ הרימון
9700 ,הוד השרון ,130 ,סמ השעורה
9700 ,הוד השרון ,174 ,סמ השריג
9700 ,הוד השרון ,131 ,סמ התאנה
9700 ,הוד השרון ,430 ,סמ נוגה
9700 ,הוד השרון ,259 ,סמ ניל"י
9700 ,הוד השרון ,381 ,סמ תירוש
9700 ,הוד השרון ,253 ,סמטת אבן
9700 ,הוד השרון ,326 ,סמטת הרינה
9700 ,הוד השרון ,281 ,סנונית
9700 ,הוד השרון ,530 ,סנש חנה
9700 ,הוד השרון ,208 ,סעדיה גאון
9700 ,הוד השרון ,278 ,ספיר
9700 ,הוד השרון ,594 ,סקסופון
9700 ,הוד השרון ,406 ,סתיו
9700 ,הוד השרון ,250 ,עגנון
9700 ,הוד השרון ,583 ,עוגב
9700 ,הוד השרון ,489 ,עזר וייצמן
9700 ,הוד השרון ,558 ,עין דור
9700 ,הוד השרון ,108 ,עין חי
9700 ,הוד השרון ,475 ,עירית
9700 ,הוד השרון ,377 ,עלית הנוער
9700 ,הוד השרון ,437 ,ענת גוב
9700 ,הוד השרון ,451 ,עקלתון
9700 ,הוד השרון ,371 ,פדויים
9700 ,הוד השרון ,535 ,פיינברג אבשלום
9700 ,הוד השרון ,169 ,פלמ"ח
9700 ,הוד השרון ,418 ,פן אלכסנדר
9700 ,הוד השרון ,587 ,פסנתר
9700 ,הוד השרון ,476 ,פעמונית
9700 ,הוד השרון ,477 ,פרג
9700 ,הוד השרון ,289 ,פשוש
9700 ,הוד השרון ,588 ,צ'לו
9700 ,הוד השרון ,202 ,צה"ל
9700 ,הוד השרון ,295 ,צופית
9700 ,הוד השרון ,276 ,צור
9700 ,הוד השרון ,520 ,צורן
9700 ,הוד השרון ,585 ,צליל
9700 ,הוד השרון ,444 ,צלמון
9700 ,הוד השרון ,305 ,קבוץ גלויות
9700 ,הוד השרון ,316 ,קורצ'אק יאנוש
9700 ,הוד השרון ,556 ,קיבוץ דליה
9700 ,הוד השרון ,553 ,קיבוץ יסודות
9700 ,הוד השרון ,441 ,קישון
9700 ,הוד השרון ,487 ,קציר אפריים
9700 ,הוד השרון ,423 ,רונית אלקבץ
9700 ,הוד השרון ,478 ,רותם
9700 ,הוד השרון ,538 ,רזיאל דוד
9700 ,הוד השרון ,373 ,רמב"ם
9700 ,הוד השרון ,6525 ,רמות מנחם
9700 ,הוד השרון ,177 ,רמז
9700 ,הוד השרון ,152 ,רעות
9700 ,הוד השרון ,372 ,רקפת
9700 ,הוד השרון ,385 ,רש"י
9700 ,הוד השרון ,6524 ,ש בלוקונים
9700 ,הוד השרון ,6502 ,ש גיורא
9700 ,הוד השרון ,6503 ,ש גיל עמל
9700 ,הוד השרון ,6507 ,ש מזרחי
9700 ,הוד השרון ,6508 ,ש נוה הדר
9700 ,הוד השרון ,6509 ,ש נוה נאמן
9700 ,הוד השרון ,6510 ,ש נוה עובד
9700 ,הוד השרון ,6511 ,ש סולל בונה
9700 ,הוד השרון ,6512 ,ש עמידר צריפים
9700 ,הוד השרון ,6513 ,ש עממי
9700 ,הוד השרון ,6504 ,ש פועלים א
9700 ,הוד השרון ,6506 ,ש פועלים ב
9700 ,הוד השרון ,6514 ,ש פתוח
9700 ,הוד השרון ,6515 ,ש רמת הדר
9700 ,הוד השרון ,6516 ,ש רסקו
9700 ,הוד השרון ,6517 ,ש שרת
9700 ,הוד השרון ,6518 ,ש תימנים מגדיאל
9700 ,הוד השרון ,299 ,שאול המלך
9700 ,הוד השרון ,521 ,שבו
9700 ,הוד השרון ,479 ,שבט
9700 ,הוד השרון ,353 ,שבי ציון
9700 ,הוד השרון ,399 ,שביל החלב
9700 ,הוד השרון ,193 ,שביל התיכון
9700 ,הוד השרון ,140 ,שביל התפוזים
9700 ,הוד השרון ,431 ,שבתאי
9700 ,הוד השרון ,178 ,שד האוטהקר
9700 ,הוד השרון ,408 ,שד הנשיאים
9700 ,הוד השרון ,413 ,שז"ר זלמן
9700 ,הוד השרון ,321 ,שחל
9700 ,הוד השרון ,279 ,שחם
9700 ,הוד השרון ,282 ,שחף
9700 ,הוד השרון ,271 ,שטיין
9700 ,הוד השרון ,531 ,שטרן אברהם
9700 ,הוד השרון ,396 ,שיזף
9700 ,הוד השרון ,148 ,שילה
9700 ,הוד השרון ,6521 ,שכ הכשרת הקיבוצים
9700 ,הוד השרון ,6522 ,שכ הנביאים
9700 ,הוד השרון ,6519 ,שכ העובד הציוני
9700 ,הוד השרון ,6523 ,שכ מלכים ושופטים
9700 ,הוד השרון ,6528 ,שכ רמתיים
9700 ,הוד השרון ,6520 ,שכון חדש
9700 ,הוד השרון ,283 ,שלדג
9700 ,הוד השרון ,254 ,שלום עליכם
9700 ,הוד השרון ,158 ,שלומציון
9700 ,הוד השרון ,294 ,שלמה המלך
9700 ,הוד השרון ,296 ,שמגר בן ענת
9700 ,הוד השרון ,561 ,שמואל הנביא
9700 ,הוד השרון ,313 ,שמיר
9700 ,הוד השרון ,160 ,שמעון הצדיק
9700 ,הוד השרון ,443 ,שניר
9700 ,הוד השרון ,390 ,שנת היובל
9700 ,הוד השרון ,551 ,שער העמקים
9700 ,הוד השרון ,320 ,שפרינצק
9700 ,הוד השרון ,286 ,שקנאי
9700 ,הוד השרון ,185 ,שרת משה
9700 ,הוד השרון ,302 ,ששת הימים
9700 ,הוד השרון ,354 ,תבור
9700 ,הוד השרון ,581 ,תווים
9700 ,הוד השרון ,591 ,תוף
9700 ,הוד השרון ,290 ,תור
9700 ,הוד השרון ,223 ,תל דן
9700 ,הוד השרון ,554 ,תל יצחק
9700 ,הוד השרון ,481 ,תמוז
9700 ,הוד השרון ,242 ,תרפ"ה
9700 ,הוד השרון ,523 ,תרשיש
9700 ,הוד השרון ,482 ,תשרי
726 ,הודיה ,9000 ,הודיה
1322 ,הודיות ,9000 ,הודיות
1169 ,הוואשלה (שבט) ,9000 ,הוואשלה (שבט)
956 ,הוזייל (שבט) ,103 ,אבו זאיד
956 ,הוזייל (שבט) ,102 ,אל טורי
956 ,הוזייל (שבט) ,9000 ,הוזייל (שבט)
956 ,הוזייל (שבט) ,104 ,סקר הזיל
956 ,הוזייל (שבט) ,101 ,עי נחל גרר
1186 ,הושעיה ,101 ,בדולח
1186 ,הושעיה ,103 ,גדיד
1186 ,הושעיה ,102 ,גן-אור
1186 ,הושעיה ,104 ,גני טל
1186 ,הושעיה ,109 ,דוגית
1186 ,הושעיה ,9000 ,הושעיה
1186 ,הושעיה ,110 ,כפר דרום
1186 ,הושעיה ,105 ,מורג
1186 ,הושעיה ,111 ,נצר חזני
1186 ,הושעיה ,112 ,נצרים
1186 ,הושעיה ,107 ,פאת השדה
1186 ,הושעיה ,108 ,קטיף
1186 ,הושעיה ,106 ,שירת הים
250 ,הזורע ,9000 ,הזורע
307 ,הזורעים ,9000 ,הזורעים
434 ,החותרים ,9000 ,החותרים
684 ,היוגב ,105 ,גבעת הטרשים
684 ,היוגב ,103 ,דרך בית עלמין
684 ,היוגב ,111 ,דרך הבוצרים
684 ,היוגב ,113 ,דרך הזורעים
684 ,היוגב ,108 ,דרך החולבים
684 ,היוגב ,109 ,דרך הכורמים
684 ,היוגב ,112 ,דרך המוסקים
684 ,היוגב ,110 ,דרך הרועות
684 ,היוגב ,114 ,דרך השדות
684 ,היוגב ,107 ,דרך השותלות
684 ,היוגב ,118 ,הגפנים
684 ,היוגב ,117 ,הזיתים
684 ,היוגב ,101 ,החורש
684 ,היוגב ,9000 ,היוגב
684 ,היוגב ,106 ,הנוטעות
684 ,היוגב ,102 ,הקוצר
684 ,היוגב ,104 ,הראשונות
684 ,היוגב ,116 ,השיבולים
684 ,היוגב ,115 ,השקדיות
1208 ,הילה ,9000 ,הילה
377 ,המעפיל ,9000 ,המעפיל
677 ,הסוללים ,9000 ,הסוללים
423 ,העוגן ,9000 ,העוגן
3769 ,הר אדר ,104 ,האורן
3769 ,הר אדר ,102 ,האלה
3769 ,הר אדר ,101 ,האלון
3769 ,הר אדר ,106 ,הברוש
3769 ,הר אדר ,125 ,הגיא
3769 ,הר אדר ,105 ,הערבה
3769 ,הר אדר ,9000 ,הר אדר
3769 ,הר אדר ,110 ,השקד
3769 ,הר אדר ,119 ,מבוא האגוז
3769 ,הר אדר ,132 ,מבוא האור
3769 ,הר אדר ,116 ,מבוא הארז
3769 ,הר אדר ,122 ,מבוא הבוסתן
3769 ,הר אדר ,108 ,מבוא הגפן
3769 ,הר אדר ,111 ,מבוא ההדס
3769 ,הר אדר ,107 ,מבוא הזית
3769 ,הר אדר ,130 ,מבוא החורש
3769 ,הר אדר ,124 ,מבוא החלילים
3769 ,הר אדר ,103 ,מבוא החרוב
3769 ,הר אדר ,129 ,מבוא היער
3769 ,הר אדר ,127 ,מבוא הכפר
3769 ,הר אדר ,114 ,מבוא הכרמים
3769 ,הר אדר ,131 ,מבוא הלבונה
3769 ,הר אדר ,121 ,מבוא המטע
3769 ,הר אדר ,117 ,מבוא הנטף
3769 ,הר אדר ,123 ,מבוא הפסגה
3769 ,הר אדר ,128 ,מבוא הרועים
3769 ,הר אדר ,113 ,מבוא הרימון
3769 ,הר אדר ,126 ,מבוא הרכס
3769 ,הר אדר ,112 ,מבוא התאנה
3769 ,הר אדר ,109 ,מבוא התומר
3769 ,הר אדר ,120 ,נוף הרים
3769 ,הר אדר ,118 ,שביל השלושה
3769 ,הר אדר ,115 ,שד הראל
3603 ,הר גילה ,110 ,דרך ההר
3603 ,הר גילה ,113 ,האלה
3603 ,הר גילה ,112 ,האלון
3603 ,הר גילה ,116 ,הזית
3603 ,הר גילה ,115 ,הליבנה
3603 ,הר גילה ,117 ,הקטלב
3603 ,הר גילה ,9000 ,הר גילה
3603 ,הר גילה ,114 ,הרימון
3603 ,הר גילה ,111 ,יורם פיצ'י בן מאיר
3603 ,הר גילה ,101 ,משעול החלמונית
3603 ,הר גילה ,100 ,משעול החצב
3603 ,הר גילה ,108 ,משעול המרווה
3603 ,הר גילה ,105 ,משעול הסלעית
3603 ,הר גילה ,106 ,משעול העיט הזהוב
3603 ,הר גילה ,104 ,משעול העפרוני
3603 ,הר גילה ,102 ,משעול הצופית
3603 ,הר גילה ,109 ,משעול הקורנית
3603 ,הר גילה ,103 ,משעול הקיכלי
3603 ,הר גילה ,107 ,משעול כליל החורש
1261 ,הר עמשא ,9000 ,הר עמשא
464 ,הראל ,9000 ,הראל
1249 ,הרדוף ,9000 ,הרדוף
6400 ,הרצליה ,134 ,אבוקה
6400 ,הרצליה ,482 ,אבטליון
6400 ,הרצליה ,496 ,אביב גל
6400 ,הרצליה ,754 ,אביגיל
6400 ,הרצליה ,500 ,אבן אודם
6400 ,הרצליה ,541 ,אבן גבירול
6400 ,הרצליה ,542 ,אבן עזרא
6400 ,הרצליה ,543 ,אבן שפרוט
6400 ,הרצליה ,544 ,אבן תיבון
6400 ,הרצליה ,953 ,אבניים
6400 ,הרצליה ,755 ,אבנר
6400 ,הרצליה ,451 ,אברבנאל
6400 ,הרצליה ,750 ,אגדת דשא
6400 ,הרצליה ,119 ,אגס
6400 ,הרצליה ,590 ,אגרת תימן
6400 ,הרצליה ,388 ,אהבת ציון
6400 ,הרצליה ,812 ,אהרונסון דשה
6400 ,הרצליה ,586 ,אונקלוס
6400 ,הרצליה ,419 ,אוסישקין
6400 ,הרצליה ,619 ,אופיר
6400 ,הרצליה ,637 ,אופק אוריאל
6400 ,הרצליה ,598 ,אוצר הצמחים
6400 ,הרצליה ,832 ,אור
6400 ,הרצליה ,445 ,אור החיים
6400 ,הרצליה ,372 ,אז"ר
6400 ,הרצליה ,6513 ,אזור התעשיה
6400 ,הרצליה ,6561 ,אזור פתוח
6400 ,הרצליה ,629 ,אזור שפת הים
6400 ,הרצליה ,6514 ,אזור תעשיה חדש
6400 ,הרצליה ,674 ,אח"י אילת
6400 ,הרצליה ,675 ,אח"י דקר
6400 ,הרצליה ,413 ,אחד העם
6400 ,הרצליה ,109 ,אחווה
6400 ,הרצליה ,613 ,אייבי נתן
6400 ,הרצליה ,580 ,איילת חן
6400 ,הרצליה ,814 ,איסר הראל
6400 ,הרצליה ,855 ,אירוסים
6400 ,הרצליה ,642 ,אל על
6400 ,הרצליה ,676 ,אלברט איינשטיין
6400 ,הרצליה ,562 ,אלדד הדני
6400 ,הרצליה ,399 ,אלומות
6400 ,הרצליה ,504 ,אלוף אלון יגאל
6400 ,הרצליה ,810 ,אלחריזי
6400 ,הרצליה ,803 ,אלי כהן
6400 ,הרצליה ,765 ,אלכסנדר ינאי
6400 ,הרצליה ,1386 ,אלמוג
6400 ,הרצליה ,136 ,אלן טיורינג
6400 ,הרצליה ,591 ,אלפסי
6400 ,הרצליה ,567 ,אלקלעי
6400 ,הרצליה ,317 ,אלרואי דוד
6400 ,הרצליה ,464 ,אלתרמן נתן
6400 ,הרצליה ,360 ,אמירים
6400 ,הרצליה ,671 ,אנדה
6400 ,הרצליה ,490 ,אנה פרנק
6400 ,הרצליה ,550 ,אנילביץ
6400 ,הרצליה ,952 ,אנפה
6400 ,הרצליה ,560 ,אסירי ציון
6400 ,הרצליה ,625 ,אסתר המלכה
6400 ,הרצליה ,829 ,אסתר ראב
6400 ,הרצליה ,143 ,אף על פי כן
6400 ,הרצליה ,1382 ,אפיק
6400 ,הרצליה ,767 ,אפרים
6400 ,הרצליה ,121 ,אפרסמון
6400 ,הרצליה ,678 ,אצ"ל
6400 ,הרצליה ,790 ,ארבל
6400 ,הרצליה ,867 ,ארגוב סשה
6400 ,הרצליה ,575 ,אריאל
6400 ,הרצליה ,833 ,אריאל שרון
6400 ,הרצליה ,147 ,אריה זייף
6400 ,הרצליה ,845 ,אריק איינשטיין
6400 ,הרצליה ,656 ,אריק לביא
6400 ,הרצליה ,303 ,ארלוזורוב
6400 ,הרצליה ,824 ,ארן זלמן
6400 ,הרצליה ,350 ,ארנסט וסוזי וודק
6400 ,הרצליה ,1383 ,אשד
6400 ,הרצליה ,335 ,בארי
6400 ,הרצליה ,858 ,בבה אידלסון
6400 ,הרצליה ,738 ,בובר מרטין
6400 ,הרצליה ,578 ,בוסל
6400 ,הרצליה ,830 ,בוקר
6400 ,הרצליה ,105 ,בורוכוב
6400 ,הרצליה ,820 ,בורלא יהודה
6400 ,הרצליה ,459 ,בורר יהודה
6400 ,הרצליה ,620 ,בזל
6400 ,הרצליה ,314 ,ביל"ו
6400 ,הרצליה ,514 ,בילינסון
6400 ,הרצליה ,721 ,בית אבות סרינה
6400 ,הרצליה ,847 ,בית חלומותי
6400 ,הרצליה ,352 ,בית"ר
6400 ,הרצליה ,737 ,בלפור
6400 ,הרצליה ,764 ,בן אליעזר אריה
6400 ,הרצליה ,309 ,בן גוריון
6400 ,הרצליה ,418 ,בן יהודה
6400 ,הרצליה ,689 ,בן יוסף שלמה
6400 ,הרצליה ,816 ,בן סרוק מנחם
6400 ,הרצליה ,461 ,בן שלום מאיר
6400 ,הרצליה ,513 ,בני בנימין
6400 ,הרצליה ,574 ,בצלאל
6400 ,הרצליה ,401 ,בר אילן
6400 ,הרצליה ,357 ,בר יוחאי
6400 ,הרצליה ,313 ,בר כוכבא
6400 ,הרצליה ,854 ,בראון
6400 ,הרצליה ,506 ,ברוך
6400 ,הרצליה ,498 ,ברית עולם
6400 ,הרצליה ,801 ,ברכה צפירה
6400 ,הרצליה ,307 ,ברנר
6400 ,הרצליה ,450 ,ברק
6400 ,הרצליה ,903 ,ברקת
6400 ,הרצליה ,566 ,ברש אשר
6400 ,הרצליה ,756 ,בת שבע
6400 ,הרצליה ,655 ,ג'ו עמר
6400 ,הרצליה ,209 ,גבורי עציון
6400 ,הרצליה ,443 ,גבעת החלומות
6400 ,הרצליה ,666 ,גבעת הסופר
6400 ,הרצליה ,6511 ,גבעת הפרחים
6400 ,הרצליה ,672 ,גבעת התחמושת
6400 ,הרצליה ,837 ,גדעון האוזנר
6400 ,הרצליה ,665 ,גדעון השופט
6400 ,הרצליה ,653 ,גובר רבקה
6400 ,הרצליה ,597 ,גולדברג לאה
6400 ,הרצליה ,901 ,גולדה מאיר
6400 ,הרצליה ,522 ,גולומב אליהו
6400 ,הרצליה ,302 ,גורדון א ד
6400 ,הרצליה ,201 ,גיל
6400 ,הרצליה ,471 ,גינת רפול
6400 ,הרצליה ,486 ,גיסין
6400 ,הרצליה ,785 ,גיתית
6400 ,הרצליה ,709 ,גלגל הפלדה
6400 ,הרצליה ,649 ,גלי תכלת
6400 ,הרצליה ,344 ,גלילות
6400 ,הרצליה ,6510 ,גני הרצליה
6400 ,הרצליה ,679 ,גרונר דב
6400 ,הרצליה ,825 ,גרינברג אורי צבי
6400 ,הרצליה ,234 ,גרמה זכריה
6400 ,הרצליה ,466 ,גת
6400 ,הרצליה ,1389 ,ד"ר צבי דינשטיין
6400 ,הרצליה ,839 ,דבורה עומר
6400 ,הרצליה ,519 ,דגניה
6400 ,הרצליה ,122 ,דובדבן
6400 ,הרצליה ,769 ,דובנוב
6400 ,הרצליה ,235 ,דוד אלעזר
6400 ,הרצליה ,623 ,דוד המלך
6400 ,הרצליה ,380 ,דודאים
6400 ,הרצליה ,492 ,דוכיפת
6400 ,הרצליה ,397 ,דולב
6400 ,הרצליה ,811 ,דון יוסף הנשיא
6400 ,הרצליה ,635 ,דורי
6400 ,הרצליה ,6541 ,דיור עממי
6400 ,הרצליה ,348 ,דינור בן ציון
6400 ,הרצליה ,204 ,דיצה
6400 ,הרצליה ,576 ,דליה
6400 ,הרצליה ,815 ,דליה רביקוביץ
6400 ,הרצליה ,577 ,דן
6400 ,הרצליה ,643 ,דפנה
6400 ,הרצליה ,491 ,דרור
6400 ,הרצליה ,800 ,דרך אלטנוילנד
6400 ,הרצליה ,775 ,דרך דיין משה
6400 ,הרצליה ,549 ,דרך ירושלים
6400 ,הרצליה ,310 ,דרך רמת השרון
6400 ,הרצליה ,630 ,דרך תל אביב
6400 ,הרצליה ,554 ,ה באייר
6400 ,הרצליה ,636 ,האבקליפטוס
6400 ,הרצליה ,692 ,האגוז
6400 ,הרצליה ,133 ,האוניברסיטה
6400 ,הרצליה ,599 ,האורנים
6400 ,הרצליה ,558 ,האילנות
6400 ,הרצליה ,851 ,האלה
6400 ,הרצליה ,693 ,האלון
6400 ,הרצליה ,817 ,האסיף
6400 ,הרצליה ,222 ,האר"י
6400 ,הרצליה ,694 ,הארז
6400 ,הרצליה ,609 ,האשל
6400 ,הרצליה ,476 ,האתרוג
6400 ,הרצליה ,488 ,הבוסתן
6400 ,הרצליה ,416 ,הבנים
6400 ,הרצליה ,331 ,הבעל שם טוב
6400 ,הרצליה ,611 ,הברוש
6400 ,הרצליה ,530 ,הבריגדה היהודית
6400 ,הרצליה ,951 ,הבשור
6400 ,הרצליה ,126 ,הבשן
6400 ,הרצליה ,334 ,הגאון מוילנה
6400 ,הרצליה ,748 ,הגבורה
6400 ,הרצליה ,551 ,הגדוד העברי
6400 ,הרצליה ,139 ,הגדעונים
6400 ,הרצליה ,328 ,הגלבוע
6400 ,הרצליה ,414 ,הגליל
6400 ,הרצליה ,1385 ,הגעש
6400 ,הרצליה ,607 ,הגפן
6400 ,הרצליה ,338 ,הדסה
6400 ,הרצליה ,478 ,הדקל
6400 ,הרצליה ,502 ,הדר
6400 ,הרצליה ,503 ,ההגנה
6400 ,הרצליה ,531 ,ההדסים
6400 ,הרצליה ,306 ,ההסתדרות
6400 ,הרצליה ,412 ,הואדי
6400 ,הרצליה ,533 ,הוז דב
6400 ,הרצליה ,408 ,הולצברג שמחה
6400 ,הרצליה ,762 ,הורדים
6400 ,הרצליה ,458 ,הזוהר
6400 ,הרצליה ,499 ,הזז חיים
6400 ,הרצליה ,525 ,הזיתים
6400 ,הרצליה ,400 ,הזמר העברי
6400 ,הרצליה ,866 ,החורש
6400 ,הרצליה ,715 ,החושלים
6400 ,הרצליה ,114 ,החיצים
6400 ,הרצליה ,390 ,החלום ופשרו
6400 ,הרצליה ,509 ,החלוץ
6400 ,הרצליה ,1381 ,החליל
6400 ,הרצליה ,465 ,החנית
6400 ,הרצליה ,695 ,החצב
6400 ,הרצליה ,696 ,החרוב
6400 ,הרצליה ,327 ,החרמון
6400 ,הרצליה ,470 ,החרמש
6400 ,הרצליה ,697 ,החרצית
6400 ,הרצליה ,391 ,החשמונאים
6400 ,הרצליה ,216 ,הטייסים
6400 ,הרצליה ,351 ,הי"א
6400 ,הרצליה ,463 ,הידיד
6400 ,הרצליה ,211 ,היורה
6400 ,הרצליה ,646 ,היסמין
6400 ,הרצליה ,484 ,הירדן
6400 ,הרצליה ,431 ,הירש
6400 ,הרצליה ,736 ,היתד
6400 ,הרצליה ,698 ,הכ"ג
6400 ,הרצליה ,430 ,הכוזרי
6400 ,הרצליה ,1394 ,הכינור
6400 ,הרצליה ,555 ,הכנסת
6400 ,הרצליה ,489 ,הכרם
6400 ,הרצליה ,329 ,הכרמל
6400 ,הרצליה ,647 ,הליבנה
6400 ,הרצליה ,662 ,הלילך
6400 ,הרצליה ,433 ,הלל
6400 ,הרצליה ,703 ,הלני המלכה
6400 ,הרצליה ,634 ,המגינים
6400 ,הרצליה ,740 ,המדע
6400 ,הרצליה ,734 ,המדרון
6400 ,הרצליה ,224 ,המלקוש
6400 ,הרצליה ,604 ,המנהרה
6400 ,הרצליה ,714 ,המנופים
6400 ,הרצליה ,661 ,המסילה
6400 ,הרצליה ,702 ,המעבר
6400 ,הרצליה ,535 ,המעיין
6400 ,הרצליה ,660 ,המעלות
6400 ,הרצליה ,652 ,המעפילים
6400 ,הרצליה ,1384 ,המפל
6400 ,הרצליה ,799 ,המפרש
6400 ,הרצליה ,363 ,המקובלים
6400 ,הרצליה ,628 ,המרגנית
6400 ,הרצליה ,752 ,המרי
6400 ,הרצליה ,339 ,המתמיד
6400 ,הרצליה ,444 ,הנביאים
6400 ,הרצליה ,534 ,הנדיב
6400 ,הרצליה ,456 ,הנוטרים
6400 ,הרצליה ,680 ,הנוריות
6400 ,הרצליה ,585 ,הניצנים
6400 ,הרצליה ,480 ,הנעורים
6400 ,הרצליה ,681 ,הנרקיסים
6400 ,הרצליה ,608 ,הנשיא יצחק בן צבי
6400 ,הרצליה ,407 ,הס
6400 ,הרצליה ,707 ,הסדנאות
6400 ,הרצליה ,215 ,הסיגליות
6400 ,הרצליה ,699 ,הסייפנים
6400 ,הרצליה ,797 ,העוגן
6400 ,הרצליה ,955 ,העופר
6400 ,הרצליה ,722 ,העירית
6400 ,הרצליה ,479 ,העלומים
6400 ,הרצליה ,521 ,העליה השניה
6400 ,הרצליה ,421 ,העצמאות
6400 ,הרצליה ,477 ,הערבה
6400 ,הרצליה ,606 ,הפועל
6400 ,הרצליה ,843 ,הפיוס
6400 ,הרצליה ,429 ,הפרדסים
6400 ,הרצליה ,852 ,הפרח בגני
6400 ,הרצליה ,548 ,הפרחים
6400 ,הרצליה ,117 ,הפרטיזנים
6400 ,הרצליה ,645 ,הפרסה
6400 ,הרצליה ,529 ,הצברים
6400 ,הרצליה ,798 ,הצדף
6400 ,הרצליה ,757 ,הצנחן אבא
6400 ,הרצליה ,316 ,הצנחנים
6400 ,הרצליה ,528 ,הצפצפות
6400 ,הרצליה ,615 ,הקדמה
6400 ,הרצליה ,739 ,הקוממיות
6400 ,הרצליה ,648 ,הקונגרס הציוני
6400 ,הרצליה ,485 ,הקוצרים
6400 ,הרצליה ,497 ,הקטיף
6400 ,הרצליה ,325 ,הקסם
6400 ,הרצליה ,1379 ,הקרן
6400 ,הרצליה ,420 ,הקרן הקימת
6400 ,הרצליה ,127 ,הר המוריה
6400 ,הרצליה ,373 ,הר מירון
6400 ,הרצליה ,103 ,הר נבו
6400 ,הרצליה ,104 ,הר סיני
6400 ,הרצליה ,123 ,הר עצמון
6400 ,הרצליה ,132 ,הר שומרון
6400 ,הרצליה ,570 ,הראובני
6400 ,הרצליה ,508 ,הראשונים
6400 ,הרצליה ,467 ,הרב גורן
6400 ,הרצליה ,437 ,הרב הלפרין מרדכי
6400 ,הרצליה ,304 ,הרב יוסף סאסי
6400 ,הרצליה ,472 ,הרב יעקב אבו חצירא
6400 ,הרצליה ,805 ,הרב מימון
6400 ,הרצליה ,375 ,הרב ניסים יצחק
6400 ,הרצליה ,836 ,הרב עובדיה יוסף
6400 ,הרצליה ,320 ,הרב עוזיאל
6400 ,הרצליה ,602 ,הרב פישמן
6400 ,הרצליה ,547 ,הרב קוק
6400 ,הרצליה ,853 ,הרדופים
6400 ,הרצליה ,723 ,הרותם
6400 ,הרצליה ,128 ,הרי גולן
6400 ,הרצליה ,129 ,הרי גלעד
6400 ,הרצליה ,603 ,הרכבת
6400 ,הרצליה ,527 ,הרמונים
6400 ,הרצליה ,556 ,הרצוג
6400 ,הרצליה ,9000 ,הרצליה
6400 ,הרצליה ,6562 ,הרצליה ב
6400 ,הרצליה ,778 ,הרצפלד אברהם
6400 ,הרצליה ,214 ,הרקפות
6400 ,הרצליה ,441 ,השולמית
6400 ,הרצליה ,557 ,השומר
6400 ,הרצליה ,795 ,השונית
6400 ,הרצליה ,362 ,השופטים
6400 ,הרצליה ,658 ,השושנים
6400 ,הרצליה ,865 ,השיטה
6400 ,הרצליה ,526 ,השקדים
6400 ,הרצליה ,724 ,השקמה
6400 ,הרצליה ,402 ,השרון
6400 ,הרצליה ,610 ,התאנה
6400 ,הרצליה ,326 ,התבור
6400 ,הרצליה ,823 ,התדהר
6400 ,הרצליה ,725 ,התומר
6400 ,הרצליה ,1378 ,התזמורת
6400 ,הרצליה ,361 ,התנאים
6400 ,הרצליה ,142 ,התקוה
6400 ,הרצליה ,434 ,וולפסון
6400 ,הרצליה ,789 ,ויזנטל שמעון
6400 ,הרצליה ,439 ,ויינשנק יחזקאל
6400 ,הרצליה ,616 ,וינגייט
6400 ,הרצליה ,404 ,ויצמן
6400 ,הרצליה ,516 ,ויתקין
6400 ,הרצליה ,368 ,ורבורג אוטו
6400 ,הרצליה ,826 ,ז'בוטינסקי
6400 ,הרצליה ,468 ,זאב
6400 ,הרצליה ,706 ,זבולון
6400 ,הרצליה ,726 ,זהר טל
6400 ,הרצליה ,125 ,זיסו
6400 ,הרצליה ,657 ,זמנהוף
6400 ,הרצליה ,355 ,חב"ד
6400 ,הרצליה ,617 ,חבצלת השרון
6400 ,הרצליה ,415 ,חברון
6400 ,הרצליה ,206 ,חדוה
6400 ,הרצליה ,312 ,חובבי ציון
6400 ,הרצליה ,592 ,חובות הלבבות
6400 ,הרצליה ,494 ,חוחית
6400 ,הרצליה ,376 ,חומה ומגדל
6400 ,הרצליה ,223 ,חוני המעגל
6400 ,הרצליה ,784 ,חורב
6400 ,הרצליה ,622 ,חזקיהו המלך
6400 ,הרצליה ,783 ,חירם
6400 ,הרצליה ,848 ,חנה מרון
6400 ,הרצליה ,640 ,חנה רובינא
6400 ,הרצליה ,460 ,חנקין
6400 ,הרצליה ,349 ,חפצי בה
6400 ,הרצליה ,904 ,חרש
6400 ,הרצליה ,332 ,חת"ם סופר
6400 ,הרצליה ,583 ,טאובר אמה
6400 ,הרצליה ,819 ,טביב מרדכי
6400 ,הרצליה ,367 ,טבנקין יצחק
6400 ,הרצליה ,793 ,טיילת חיים הרצוג
6400 ,הרצליה ,732 ,טיראן
6400 ,הרצליה ,131 ,טנא
6400 ,הרצליה ,364 ,טרומפלדור
6400 ,הרצליה ,512 ,טשרניחובסקי
6400 ,הרצליה ,559 ,יבנה
6400 ,הרצליה ,426 ,יבניאלי
6400 ,הרצליה ,1392 ,יגאל בשן
6400 ,הרצליה ,712 ,יד חרוצים
6400 ,הרצליה ,779 ,ידין יגאל
6400 ,הרצליה ,745 ,יהדות הדממה
6400 ,הרצליה ,539 ,יהודה הלוי
6400 ,הרצליה ,766 ,יהודה המכבי
6400 ,הרצליה ,342 ,יהודה הנשיא
6400 ,הרצליה ,742 ,יהונתן
6400 ,הרצליה ,704 ,יהושע בן נון
6400 ,הרצליה ,763 ,יהושפט המלך
6400 ,הרצליה ,864 ,יהלום
6400 ,הרצליה ,743 ,יואב
6400 ,הרצליה ,700 ,יודפת
6400 ,הרצליה ,711 ,יוחנן הסנדלר
6400 ,הרצליה ,1387 ,יוסי שריד
6400 ,הרצליה ,716 ,יורדי ים
6400 ,הרצליה ,741 ,יותם
6400 ,הרצליה ,141 ,יחיעם
6400 ,הרצליה ,510 ,יל"ג
6400 ,הרצליה ,870 ,ילן
6400 ,הרצליה ,869 ,ינאית
6400 ,הרצליה ,524 ,יסוד המעלה
6400 ,הרצליה ,589 ,יעבץ
6400 ,הרצליה ,758 ,יעל
6400 ,הרצליה ,396 ,יערה
6400 ,הרצליה ,505 ,יפה אריה לייב
6400 ,הרצליה ,846 ,יפה ירקוני
6400 ,הרצליה ,374 ,יפהר פסח
6400 ,הרצליה ,772 ,יפתח
6400 ,הרצליה ,212 ,יצחק שדה
6400 ,הרצליה ,835 ,יצחק שמיר
6400 ,הרצליה ,113 ,יציאת אירופה
6400 ,הרצליה ,385 ,יקינטון
6400 ,הרצליה ,781 ,ירמיהו
6400 ,הרצליה ,369 ,ישעיהו ישראל
6400 ,הרצליה ,553 ,כ"ט בנובמבר
6400 ,הרצליה ,593 ,כבוש העבודה
6400 ,הרצליה ,452 ,כוכב
6400 ,הרצליה ,6017 ,ככר אובה אקלה
6400 ,הרצליה ,6003 ,ככר דגון יואב
6400 ,הרצליה ,6001 ,ככר דה שליט
6400 ,הרצליה ,6013 ,ככר האוזנר גדעון
6400 ,הרצליה ,6007 ,ככר המתנדבים
6400 ,הרצליה ,6002 ,ככר הנס פריץ
6400 ,הרצליה ,6010 ,ככר הנשיא
6400 ,הרצליה ,6004 ,ככר הציונות
6400 ,הרצליה ,6016 ,ככר יעל רום
6400 ,הרצליה ,6012 ,ככר יקירי העיר
6400 ,הרצליה ,6011 ,ככר לוחמות השואה
6400 ,הרצליה ,6009 ,ככר מודעי
6400 ,הרצליה ,6006 ,ככר מיכאלי
6400 ,הרצליה ,6008 ,ככר סחארוב
6400 ,הרצליה ,6005 ,ככר עובד בן עמי
6400 ,הרצליה ,6015 ,ככר פאבל פרנקל
6400 ,הרצליה ,6014 ,ככר רוטרי
6400 ,הרצליה ,393 ,כלנית
6400 ,הרצליה ,563 ,כנפי נשרים
6400 ,הרצליה ,520 ,כנרת
6400 ,הרצליה ,323 ,כסופים
6400 ,הרצליה ,6578 ,כפר הדייגים
6400 ,הרצליה ,106 ,כצנלסון
6400 ,הרצליה ,768 ,כרכום
6400 ,הרצליה ,340 ,לביא שמעון
6400 ,הרצליה ,371 ,לובטקין צביה
6400 ,הרצליה ,387 ,לוטוס
6400 ,הרצליה ,794 ,לוטם
6400 ,הרצליה ,760 ,לוי אשכול
6400 ,הרצליה ,405 ,לוין שמעון זאב
6400 ,הרצליה ,683 ,לח"י
6400 ,הרצליה ,701 ,ליבוביץ ישעיהו
6400 ,הרצליה ,595 ,ליברמן ישראל
6400 ,הרצליה ,228 ,ליל הגשרים
6400 ,הרצליה ,230 ,ליפסקי
6400 ,הרצליה ,956 ,לכיש
6400 ,הרצליה ,821 ,למדן יצחק
6400 ,הרצליה ,863 ,לסקוב חיים
6400 ,הרצליה ,579 ,לשם
6400 ,הרצליה ,808 ,מאיר אריאל
6400 ,הרצליה ,146 ,מאיר דגן
6400 ,הרצליה ,727 ,מאנגר
6400 ,הרצליה ,770 ,מאפו
6400 ,הרצליה ,957 ,מארל
6400 ,הרצליה ,353 ,מבצע קדש
6400 ,הרצליה ,427 ,מגיני נגבה
6400 ,הרצליה ,532 ,מגן דוד
6400 ,הרצליה ,705 ,מדינת היהודים
6400 ,הרצליה ,600 ,מהר"ל
6400 ,הרצליה ,438 ,מובשוביץ בנימין
6400 ,הרצליה ,719 ,מודיעין
6400 ,הרצליה ,422 ,מוהליבר
6400 ,הרצליה ,1393 ,מוטה גור
6400 ,הרצליה ,144 ,מולדת
6400 ,הרצליה ,809 ,מולכו שלמה
6400 ,הרצליה ,684 ,מונטיפיורי
6400 ,הרצליה ,733 ,מוצקין
6400 ,הרצליה ,668 ,מורדות ים
6400 ,הרצליה ,112 ,מורדי הגטאות
6400 ,הרצליה ,225 ,מורי עופרי
6400 ,הרצליה ,148 ,מוריס לחיאני
6400 ,הרצליה ,379 ,מורן
6400 ,הרצליה ,428 ,מזא"ה
6400 ,הרצליה ,731 ,מיכ"ל
6400 ,הרצליה ,806 ,מלכי יהודה
6400 ,הרצליה ,802 ,מלכי ישראל
6400 ,הרצליה ,308 ,מלכין שרה
6400 ,הרצליה ,804 ,מנדלבלט צבי
6400 ,הרצליה ,685 ,מנדלי מוכר ספרים
6400 ,הרצליה ,202 ,מנורה
6400 ,הרצליה ,6563 ,מע גלילות
6400 ,הרצליה ,6564 ,מע נוף ים
6400 ,הרצליה ,6521 ,מעונות שרה
6400 ,הרצליה ,857 ,מעלה חנוך
6400 ,הרצליה ,773 ,מעלה יהודית
6400 ,הרצליה ,780 ,מעלה נחמה
6400 ,הרצליה ,102 ,מעפילי מרוקו
6400 ,הרצליה ,749 ,מצדה
6400 ,הרצליה ,546 ,מצפה ים
6400 ,הרצליה ,389 ,מרבד הקסמים
6400 ,הרצליה ,395 ,מרווה
6400 ,הרצליה ,130 ,מרזוק
6400 ,הרצליה ,518 ,מרחביה
6400 ,הרצליה ,771 ,מרים החשמונאית
6400 ,הרצליה ,8001 ,מרינה הרצליה
6400 ,הרצליה ,632 ,מרכוס דוד
6400 ,הרצליה ,341 ,משה
6400 ,הרצליה ,145 ,משה ארנס
6400 ,הרצליה ,710 ,משכית
6400 ,הרצליה ,149 ,משעול משה
6400 ,הרצליה ,124 ,משק עזרא
6400 ,הרצליה ,746 ,מתתיהו
6400 ,הרצליה ,6565 ,נאות הדקלים
6400 ,הרצליה ,596 ,נבו יוסף
6400 ,הרצליה ,221 ,נג'ר יצחק
6400 ,הרצליה ,840 ,נדבה יוסף
6400 ,הרצליה ,453 ,נוגה
6400 ,הרצליה ,6515 ,נוה אמירים
6400 ,הרצליה ,6566 ,נוה נמרוד
6400 ,הרצליה ,6542 ,נוה פועלים
6400 ,הרצליה ,669 ,נוף שדמות
6400 ,הרצליה ,377 ,נופר
6400 ,הרצליה ,601 ,נורדאו
6400 ,הרצליה ,356 ,נורוק
6400 ,הרצליה ,6543 ,נחלת עדה
6400 ,הרצליה ,659 ,נחמה הנדל
6400 ,הרצליה ,448 ,נחמיה
6400 ,הרצליה ,782 ,נחשון
6400 ,הרצליה ,728 ,ניל"י
6400 ,הרצליה ,787 ,ניר
6400 ,הרצליה ,572 ,נצח ישראל
6400 ,הרצליה ,673 ,נתיב הל"ה
6400 ,הרצליה ,729 ,נתיבות
6400 ,הרצליה ,138 ,נתן אלבז
6400 ,הרצליה ,788 ,נתניהו יוני
6400 ,הרצליה ,384 ,סביון
6400 ,הרצליה ,594 ,סולץ אלכסנדר
6400 ,הרצליה ,473 ,סומסום
6400 ,הרצליה ,406 ,סוקולוב
6400 ,הרצליה ,686 ,סטרומה
6400 ,הרצליה ,774 ,סידני עלי
6400 ,הרצליה ,677 ,סירני
6400 ,הרצליה ,411 ,סירקין
6400 ,הרצליה ,822 ,סמ בני ברית
6400 ,הרצליה ,761 ,סמ ברנדיס
6400 ,הרצליה ,440 ,סמ דגן
6400 ,הרצליה ,581 ,סמ זיו אור
6400 ,הרצליה ,227 ,סמ יפת בן זכריה מגד
6400 ,הרצליה ,641 ,סמ מקדש מלך
6400 ,הרצליה ,347 ,סמ נוף ילדות
6400 ,הרצליה ,135 ,סמ ניסנוב
6400 ,הרצליה ,392 ,סמ עינב
6400 ,הרצליה ,343 ,סמ צמרות
6400 ,הרצליה ,358 ,סמדר
6400 ,הרצליה ,582 ,סמילנסקי משה
6400 ,הרצליה ,457 ,סנה משה
6400 ,הרצליה ,115 ,סנהדרין
6400 ,הרצליה ,495 ,סנונית
6400 ,הרצליה ,744 ,סנפיר
6400 ,הרצליה ,324 ,סנש חנה
6400 ,הרצליה ,318 ,סעדיה גאון
6400 ,הרצליה ,902 ,ספיר
6400 ,הרצליה ,475 ,עגנון
6400 ,הרצליה ,137 ,עדה לאבלייס
6400 ,הרצליה ,571 ,עדס
6400 ,הרצליה ,359 ,עוזי חיטמן
6400 ,הרצליה ,718 ,עוזיהו המלך
6400 ,הרצליה ,336 ,עולי בבל
6400 ,הרצליה ,777 ,עולי הגרדום
6400 ,הרצליה ,449 ,עזרא הסופר
6400 ,הרצליה ,455 ,עין גדי
6400 ,הרצליה ,398 ,עינב
6400 ,הרצליה ,1388 ,עמוס עוז
6400 ,הרצליה ,219 ,עמיאל
6400 ,הרצליה ,827 ,עמיר
6400 ,הרצליה ,305 ,עפרוני
6400 ,הרצליה ,786 ,ערוגות
6400 ,הרצליה ,232 ,ערמונים
6400 ,הרצליה ,573 ,עשרת הרוגי מלכות
6400 ,הרצליה ,345 ,פולג
6400 ,הרצליה ,231 ,פוסטר הרולד
6400 ,הרצליה ,107 ,פועלי ציון
6400 ,הרצליה ,229 ,פורצי הדרך
6400 ,הרצליה ,687 ,פטריה
6400 ,הרצליה ,370 ,פינס
6400 ,הרצליה ,540 ,פינסקר
6400 ,הרצליה ,501 ,פלמ"ח
6400 ,הרצליה ,220 ,פנקס
6400 ,הרצליה ,842 ,פנת חיים
6400 ,הרצליה ,366 ,פסמן
6400 ,הרצליה ,233 ,פעמונית
6400 ,הרצליה ,849 ,פעמי אביב
6400 ,הרצליה ,378 ,פרגים
6400 ,הרצליה ,208 ,פרץ
6400 ,הרצליה ,330 ,פתח תקווה
6400 ,הרצליה ,386 ,צבעוני
6400 ,הרצליה ,454 ,צדק
6400 ,הרצליה ,217 ,צה"ל
6400 ,הרצליה ,493 ,צופית
6400 ,הרצליה ,841 ,צוקרמן יצחק
6400 ,הרצליה ,1391 ,ציוני לוב
6400 ,הרצליה ,791 ,ציפורי
6400 ,הרצליה ,735 ,צלע דרום
6400 ,הרצליה ,670 ,צלע צפון
6400 ,הרצליה ,311 ,צמרות
6400 ,הרצליה ,108 ,צעירי ציון
6400 ,הרצליה ,447 ,קארו יוסף
6400 ,הרצליה ,111 ,קבוץ גלויות
6400 ,הרצליה ,664 ,קדושי השואה
6400 ,הרצליה ,140 ,קדימה
6400 ,הרצליה ,517 ,קדיש לוז
6400 ,הרצליה ,423 ,קהילת ציון
6400 ,הרצליה ,621 ,קורן
6400 ,הרצליה ,523 ,קורצ'אק
6400 ,הרצליה ,654 ,קישון אפרים
6400 ,הרצליה ,688 ,קלוזנר
6400 ,הרצליה ,507 ,קלישר
6400 ,הרצליה ,862 ,קמין ברוך
6400 ,הרצליה ,612 ,קפלן
6400 ,הרצליה ,515 ,קפלנסקי
6400 ,הרצליה ,639 ,קציר אהרון
6400 ,הרצליה ,6552 ,קרית רבין
6400 ,הרצליה ,627 ,קרן היסוד
6400 ,הרצליה ,365 ,קרת
6400 ,הרצליה ,844 ,רא"ל ליפקין שחק
6400 ,הרצליה ,319 ,רא"ל משה לוי
6400 ,הרצליה ,424 ,ראש פינה
6400 ,הרצליה ,425 ,ראשון לציון
6400 ,הרצליה ,333 ,רבי עקיבא
6400 ,הרצליה ,101 ,רביבים
6400 ,הרצליה ,203 ,רבן גמליאל
6400 ,הרצליה ,813 ,רבנו תם
6400 ,הרצליה ,487 ,רגבים
6400 ,הרצליה ,859 ,רוזה גינוסר
6400 ,הרצליה ,776 ,רוזן פנחס
6400 ,הרצליה ,321 ,רוחמה
6400 ,הרצליה ,410 ,רופין
6400 ,הרצליה ,807 ,רות
6400 ,הרצליה ,300 ,רזיאל דוד
6400 ,הרצליה ,834 ,רחבעם זאבי
6400 ,הרצליה ,120 ,רחל
6400 ,הרצליה ,638 ,רחל המשוררת
6400 ,הרצליה ,394 ,ריחן
6400 ,הרצליה ,403 ,ריינס
6400 ,הרצליה ,682 ,רייק חביבה
6400 ,הרצליה ,205 ,רינה
6400 ,הרצליה ,218 ,רכב ישראל
6400 ,הרצליה ,417 ,רמב"ם
6400 ,הרצליה ,213 ,רמז
6400 ,הרצליה ,818 ,רמח"ל
6400 ,הרצליה ,6522 ,רמת אליהו
6400 ,הרצליה ,442 ,רנ"ק
6400 ,הרצליה ,116 ,רפי וקנין
6400 ,הרצליה ,537 ,רש"י
6400 ,הרצליה ,483 ,רשב"ם
6400 ,הרצליה ,6501 ,ש אזבסטונים
6400 ,הרצליה ,6523 ,ש אחים
6400 ,הרצליה ,6567 ,ש אכדיה
6400 ,הרצליה ,6568 ,ש אמריקאים
6400 ,הרצליה ,6569 ,ש אקדמאים
6400 ,הרצליה ,6544 ,ש בטיש
6400 ,הרצליה ,6502 ,ש בלוקונים
6400 ,הרצליה ,6545 ,ש גן רשל
6400 ,הרצליה ,6524 ,ש הדרום
6400 ,הרצליה ,6525 ,ש הפומ"ז
6400 ,הרצליה ,6546 ,ש התימנים
6400 ,הרצליה ,6547 ,ש ויצמן
6400 ,הרצליה ,6526 ,ש חדש רמת השרון
6400 ,הרצליה ,6570 ,ש חיילים משוחררים
6400 ,הרצליה ,6503 ,ש יד התשעה
6400 ,הרצליה ,6527 ,ש לומיר
6400 ,הרצליה ,6504 ,ש מפדה אזרחי
6400 ,הרצליה ,6528 ,ש משהב
6400 ,הרצליה ,6505 ,ש משכנות
6400 ,הרצליה ,6529 ,ש נוה ישראל
6400 ,הרצליה ,6506 ,ש נוה עמל
6400 ,הרצליה ,6507 ,ש נוה עמל אזבסט
6400 ,הרצליה ,6571 ,ש נוה תמר
6400 ,הרצליה ,6572 ,ש נוף ים
6400 ,הרצליה ,6580 ,ש עולים
6400 ,הרצליה ,6530 ,ש עממי
6400 ,הרצליה ,6573 ,ש פרוגרסיבים
6400 ,הרצליה ,6508 ,ש ציונים כלליים
6400 ,הרצליה ,6500 ,ש צריפים שוודיים
6400 ,הרצליה ,6532 ,ש רוגובין
6400 ,הרצליה ,6574 ,ש רמת ים
6400 ,הרצליה ,6549 ,ש רסקו
6400 ,הרצליה ,633 ,שאול המלך
6400 ,הרצליה ,730 ,שארית הפליטה
6400 ,הרצליה ,536 ,שבזי
6400 ,הרצליה ,650 ,שבט מנשה
6400 ,הרצליה ,322 ,שבטי ישראל
6400 ,הרצליה ,382 ,שביל האדמית
6400 ,הרצליה ,663 ,שביל היסמין
6400 ,הרצליה ,226 ,שביל הרב שמן יוסף
6400 ,הרצליה ,381 ,שביל השיטה
6400 ,הרצליה ,538 ,שבעת הכוכבים
6400 ,הרצליה ,713 ,שד אבא אבן
6400 ,הרצליה ,383 ,שד אמנון ותמר
6400 ,הרצליה ,792 ,שד בגין מנחם
6400 ,הרצליה ,511 ,שד ח"ן
6400 ,הרצליה ,409 ,שד לנצט יעקב
6400 ,הרצליה ,584 ,שד מיכאלי בן ציון
6400 ,הרצליה ,614 ,שד נוה עובד
6400 ,הרצליה ,446 ,שד"ל
6400 ,הרצליה ,618 ,שד' אלי לנדאו
6400 ,הרצליה ,587 ,שדה התעופה
6400 ,הרצליה ,651 ,שדרות התשעים ושלש
6400 ,הרצליה ,210 ,שוחט ישראל
6400 ,הרצליה ,462 ,שוידלסון
6400 ,הרצליה ,1390 ,שולמית כהן-קישיק
6400 ,הרצליה ,667 ,שומרון דן
6400 ,הרצליה ,474 ,שושנה דמארי
6400 ,הרצליה ,753 ,שז"ר
6400 ,הרצליה ,207 ,שח"ל
6400 ,הרצליה ,831 ,שחרית
6400 ,הרצליה ,850 ,שטורמן
6400 ,הרצליה ,354 ,שטרן אברהם
6400 ,הרצליה ,469 ,שיר הרעות
6400 ,הרצליה ,315 ,שירת גאולים
6400 ,הרצליה ,301 ,שירת הזמיר
6400 ,הרצליה ,6555 ,שכ גבעת הסופר
6400 ,הרצליה ,6533 ,שכ הפועלים
6400 ,הרצליה ,6582 ,שכ הרצליה פיתוח
6400 ,הרצליה ,6575 ,שכ חילים משוחררים
6400 ,הרצליה ,6576 ,שכ כפר רשף
6400 ,הרצליה ,6550 ,שכ מזרחי
6400 ,הרצליה ,6520 ,שכ מרכז
6400 ,הרצליה ,6581 ,שכ נווה עובד
6400 ,הרצליה ,6551 ,שכ נחלת ישראל
6400 ,הרצליה ,6509 ,שכ צמרות
6400 ,הרצליה ,6577 ,שכ תשליב
6400 ,הרצליה ,6512 ,שכון גורדון
6400 ,הרצליה ,624 ,שלוה
6400 ,הרצליה ,690 ,שלום עליכם
6400 ,הרצליה ,838 ,שלום רוזנפלד
6400 ,הרצליה ,631 ,שלומציון המלכה
6400 ,הרצליה ,759 ,שלונסקי
6400 ,הרצליה ,626 ,שלמה המלך
6400 ,הרצליה ,435 ,שמאי
6400 ,הרצליה ,568 ,שמואל הנגיד
6400 ,הרצליה ,588 ,שמיר משה
6400 ,הרצליה ,564 ,שמעוני דוד
6400 ,הרצליה ,481 ,שמעיה
6400 ,הרצליה ,828 ,שמר נעמי
6400 ,הרצליה ,432 ,שמשון הגבור
6400 ,הרצליה ,565 ,שניאור זלמן
6400 ,הרצליה ,708 ,שנקר אריה
6400 ,הרצליה ,605 ,שער הים
6400 ,הרצליה ,751 ,שפיים
6400 ,הרצליה ,691 ,שפינוזה
6400 ,הרצליה ,436 ,שפירא צבי
6400 ,הרצליה ,644 ,שפרינצק
6400 ,הרצליה ,868 ,שרה
6400 ,הרצליה ,561 ,שרעבי
6400 ,הרצליה ,747 ,שרת משה
6400 ,הרצליה ,1380 ,תוף מרים
6400 ,הרצליה ,569 ,תור הזהב
6400 ,הרצליה ,552 ,תל חי
6400 ,הרצליה ,110 ,תמר
6400 ,הרצליה ,6579 ,תע"ש
6400 ,הרצליה ,118 ,תפוח
6400 ,הרצליה ,856 ,תפוח חינני
6400 ,הרצליה ,954 ,תרשיש
1203 ,הררית ,107 ,דוכיפת
1203 ,הררית ,111 ,דרור
1203 ,הררית ,103 ,הארבל
1203 ,הררית ,114 ,המוביל
1203 ,הררית ,9000 ,הררית
1203 ,הררית ,108 ,יונק הדבש
1203 ,הררית ,101 ,יחד
1203 ,הררית ,113 ,כנרת
1203 ,הררית ,115 ,כרמל
1203 ,הררית ,102 ,נטופה
1203 ,הררית ,106 ,סמטת החרמון
1203 ,הררית ,105 ,סמטת הים
1203 ,הררית ,109 ,עגורים
1203 ,הררית ,112 ,צופית
1203 ,הררית ,104 ,רכס מירון
1203 ,הררית ,110 ,שחף
3639 ,ורד יריחו ,9000 ,ורד יריחו
1133 ,ורדון ,9000 ,ורדון
2742 ,זבארגה (שבט) ,9000 ,זבארגה (שבט)
2742 ,זבארגה (שבט) ,102 ,תל אל מלח
2742 ,זבארגה (שבט) ,101 ,תל ערד
815 ,זבדיאל ,9000 ,זבדיאל
44 ,זוהר ,105 ,אורן
44 ,זוהר ,106 ,אלון
44 ,זוהר ,104 ,גפן
44 ,זוהר ,109 ,הדס
44 ,זוהר ,9000 ,זוהר
44 ,זוהר ,102 ,זית
44 ,זוהר ,103 ,רימון
44 ,זוהר ,107 ,שקד
44 ,זוהר ,101 ,תאנה
44 ,זוהר ,108 ,תמר
584 ,זיקים ,9000 ,זיקים
788 ,זיתן ,101 ,הגפן
788 ,זיתן ,104 ,הדקל
788 ,זיתן ,103 ,החרוב
788 ,זיתן ,102 ,השלושה
788 ,זיתן ,9000 ,זיתן
9300 ,זכרון יעקב ,307 ,אגס
9300 ,זכרון יעקב ,219 ,אודם
9300 ,זכרון יעקב ,179 ,אח"י אילת
9300 ,זכרון יעקב ,6511 ,אחוזת ניל"י
9300 ,זכרון יעקב ,192 ,איילת השחר
9300 ,זכרון יעקב ,291 ,אלומות
9300 ,זכרון יעקב ,165 ,אלון יגאל
9300 ,זכרון יעקב ,202 ,אלונים
9300 ,זכרון יעקב ,182 ,אלי כהן
9300 ,זכרון יעקב ,285 ,אנפה
9300 ,זכרון יעקב ,260 ,אף על פי כן
9300 ,זכרון יעקב ,309 ,אפרסק
9300 ,זכרון יעקב ,157 ,אצ"ל
9300 ,זכרון יעקב ,267 ,אקסודוס
9300 ,זכרון יעקב ,102 ,אשכול
9300 ,זכרון יעקב ,297 ,בוסתן
9300 ,זכרון יעקב ,289 ,בזלת
9300 ,זכרון יעקב ,134 ,ביאליק
9300 ,זכרון יעקב ,129 ,בית הבד
9300 ,זכרון יעקב ,197 ,בית צורי אליהו
9300 ,זכרון יעקב ,231 ,בית צידה
9300 ,זכרון יעקב ,171 ,ברק
9300 ,זכרון יעקב ,238 ,ברקת
9300 ,זכרון יעקב ,143 ,גאולים
9300 ,זכרון יעקב ,6501 ,גבעת עדן
9300 ,זכרון יעקב ,294 ,גדיש
9300 ,זכרון יעקב ,198 ,גולומב אליהו
9300 ,זכרון יעקב ,266 ,גורן
9300 ,זכרון יעקב ,232 ,גזית
9300 ,זכרון יעקב ,273 ,דבורה
9300 ,זכרון יעקב ,133 ,דורות
9300 ,זכרון יעקב ,288 ,דרור
9300 ,זכרון יעקב ,224 ,דרך אבשלום
9300 ,זכרון יעקב ,184 ,דרך אהרן
9300 ,זכרון יעקב ,166 ,דרך הגרנות
9300 ,זכרון יעקב ,131 ,דרך היקב
9300 ,זכרון יעקב ,314 ,דרך הרכבת
9300 ,זכרון יעקב ,174 ,דרך השיטה
9300 ,זכרון יעקב ,172 ,דרך פינלס
9300 ,זכרון יעקב ,183 ,דרך שרה
9300 ,זכרון יעקב ,136 ,האורנים
9300 ,זכרון יעקב ,254 ,הארזים
9300 ,זכרון יעקב ,252 ,הבנים
9300 ,זכרון יעקב ,113 ,הברושים
9300 ,זכרון יעקב ,108 ,הגדוד העברי
9300 ,זכרון יעקב ,123 ,הגדעונים
9300 ,זכרון יעקב ,117 ,הגפן
9300 ,זכרון יעקב ,220 ,הגרניט
9300 ,זכרון יעקב ,149 ,הגת
9300 ,זכרון יעקב ,195 ,הדגן
9300 ,זכרון יעקב ,105 ,הדובדבן
9300 ,זכרון יעקב ,203 ,הדס
9300 ,זכרון יעקב ,293 ,הדרים
9300 ,זכרון יעקב ,158 ,ההגנה
9300 ,זכרון יעקב ,193 ,הזוהר
9300 ,זכרון יעקב ,135 ,הזית
9300 ,זכרון יעקב ,109 ,החי"ל
9300 ,זכרון יעקב ,246 ,החיטה
9300 ,זכרון יעקב ,306 ,החינוך העברי
9300 ,זכרון יעקב ,209 ,החלוצים
9300 ,זכרון יעקב ,216 ,החצב
9300 ,זכרון יעקב ,138 ,החרוב
9300 ,זכרון יעקב ,119 ,היין
9300 ,זכרון יעקב ,190 ,הכובשים
9300 ,זכרון יעקב ,214 ,הכלנית
9300 ,זכרון יעקב ,215 ,הכרכום
9300 ,זכרון יעקב ,189 ,הכרם
9300 ,זכרון יעקב ,185 ,הלל יפה
9300 ,זכרון יעקב ,122 ,המגינים
9300 ,זכרון יעקב ,159 ,המדע
9300 ,זכרון יעקב ,121 ,המיסדים
9300 ,זכרון יעקב ,177 ,המעפילים
9300 ,זכרון יעקב ,125 ,הנדיב
9300 ,זכרון יעקב ,248 ,הנוטרים
9300 ,זכרון יעקב ,213 ,הנרקיס
9300 ,זכרון יעקב ,126 ,הנשיא
9300 ,זכרון יעקב ,242 ,הסוכה
9300 ,זכרון יעקב ,217 ,הסתונית
9300 ,זכרון יעקב ,141 ,העבודה
9300 ,זכרון יעקב ,130 ,העליה
9300 ,זכרון יעקב ,178 ,העמל
9300 ,זכרון יעקב ,243 ,העצמאות
9300 ,זכרון יעקב ,188 ,הפדות
9300 ,זכרון יעקב ,241 ,הפלמ"ח
9300 ,זכרון יעקב ,253 ,הפנינה
9300 ,זכרון יעקב ,112 ,הפסגה
9300 ,זכרון יעקב ,304 ,הקונגרס הציוני
9300 ,זכרון יעקב ,222 ,הרב עזריאלי
9300 ,זכרון יעקב ,139 ,הרימון
9300 ,זכרון יעקב ,276 ,הרמב"ם
9300 ,זכרון יעקב ,124 ,הרצל
9300 ,זכרון יעקב ,212 ,הרקפת
9300 ,זכרון יעקב ,244 ,השומר
9300 ,זכרון יעקב ,250 ,השופטים
9300 ,זכרון יעקב ,101 ,השזיף
9300 ,זכרון יעקב ,265 ,השלושה
9300 ,זכרון יעקב ,256 ,השמורה
9300 ,זכרון יעקב ,116 ,השקד
9300 ,זכרון יעקב ,163 ,השקמה
9300 ,זכרון יעקב ,164 ,התאנה
9300 ,זכרון יעקב ,137 ,התומר
9300 ,זכרון יעקב ,313 ,התוף
9300 ,זכרון יעקב ,310 ,התזמורת
9300 ,זכרון יעקב ,148 ,התירוש
9300 ,זכרון יעקב ,162 ,התפוח
9300 ,זכרון יעקב ,274 ,התקומה
9300 ,זכרון יעקב ,153 ,וינגייט
9300 ,זכרון יעקב ,132 ,ז'בוטינסקי
9300 ,זכרון יעקב ,247 ,זייד אלכסנדר
9300 ,זכרון יעקב ,9000 ,זכרון יעקב
9300 ,זכרון יעקב ,287 ,חוגלה
9300 ,זכרון יעקב ,186 ,חווה חקלאית
9300 ,זכרון יעקב ,6508 ,חוות הברון
9300 ,זכרון יעקב ,284 ,חוחית
9300 ,זכרון יעקב ,160 ,חזון איש
9300 ,זכרון יעקב ,196 ,חכים אליהו
9300 ,זכרון יעקב ,281 ,חסידה
9300 ,זכרון יעקב ,300 ,חרצית
9300 ,זכרון יעקב ,221 ,טופז
9300 ,זכרון יעקב ,230 ,טיילת הברון
9300 ,זכרון יעקב ,140 ,טרומפלדור
9300 ,זכרון יעקב ,201 ,יאיר
9300 ,זכרון יעקב ,240 ,יהלום
9300 ,זכרון יעקב ,106 ,יסמין
9300 ,זכרון יעקב ,114 ,יפה נוף
9300 ,זכרון יעקב ,251 ,יצחק שדה
9300 ,זכרון יעקב ,152 ,יציאת אירופה
9300 ,זכרון יעקב ,118 ,כביש ארצי
9300 ,זכרון יעקב ,298 ,כט בנובמבר
9300 ,זכרון יעקב ,311 ,כינור
9300 ,זכרון יעקב ,6002 ,ככר אלכסנדרוני
9300 ,זכרון יעקב ,6001 ,ככר הבנים
9300 ,זכרון יעקב ,6004 ,ככר הבריגדה
9300 ,זכרון יעקב ,6005 ,ככר הראשונים
9300 ,זכרון יעקב ,6003 ,ככר פלוגת עורב
9300 ,זכרון יעקב ,167 ,כנפי נשרים
9300 ,זכרון יעקב ,233 ,לאה
9300 ,זכרון יעקב ,176 ,לח"י
9300 ,זכרון יעקב ,308 ,לימון
9300 ,זכרון יעקב ,228 ,לשם
9300 ,זכרון יעקב ,301 ,מגל
9300 ,זכרון יעקב ,263 ,מולדת
9300 ,זכרון יעקב ,321 ,מוסקט
9300 ,זכרון יעקב ,103 ,מייש
9300 ,זכרון יעקב ,275 ,מלכי יהודה
9300 ,זכרון יעקב ,173 ,מלכי ישראל
9300 ,זכרון יעקב ,292 ,מסיק
9300 ,זכרון יעקב ,161 ,מעלה הכרמל
9300 ,זכרון יעקב ,120 ,מעלה הראשונים
9300 ,זכרון יעקב ,200 ,מעלה מנחם
9300 ,זכרון יעקב ,262 ,מצדה
9300 ,זכרון יעקב ,187 ,מרבד הקסמים
9300 ,זכרון יעקב ,104 ,מרגוע
9300 ,זכרון יעקב ,6512 ,מרכז המושבה
9300 ,זכרון יעקב ,318 ,מרלו
9300 ,זכרון יעקב ,156 ,משעול הגן
9300 ,זכרון יעקב ,257 ,משעול מתניה
9300 ,זכרון יעקב ,261 ,נגבה
9300 ,זכרון יעקב ,6502 ,נוה הברון
9300 ,זכרון יעקב ,6503 ,נוה רמז
9300 ,זכרון יעקב ,6509 ,נוה שלו
9300 ,זכרון יעקב ,6504 ,נוה שרת
9300 ,זכרון יעקב ,211 ,נורית
9300 ,זכרון יעקב ,107 ,סביון
9300 ,זכרון יעקב ,150 ,סטרומה
9300 ,זכרון יעקב ,207 ,סמ האלה
9300 ,זכרון יעקב ,146 ,סמ הבאר
9300 ,זכרון יעקב ,272 ,סמ הנרקיס
9300 ,זכרון יעקב ,168 ,סמ הרוקח
9300 ,זכרון יעקב ,279 ,סמ הרש"ש
9300 ,זכרון יעקב ,147 ,סמ השאיבה
9300 ,זכרון יעקב ,271 ,סמ השקד
9300 ,זכרון יעקב ,299 ,סמ חורשן
9300 ,זכרון יעקב ,127 ,סמ יעבץ
9300 ,זכרון יעקב ,255 ,סמ נוף ים
9300 ,זכרון יעקב ,155 ,סמ תרמ"ב
9300 ,זכרון יעקב ,258 ,סמ תרשיש
9300 ,זכרון יעקב ,259 ,סמטת אף על פי כן
9300 ,זכרון יעקב ,305 ,סמטת הגרנות
9300 ,זכרון יעקב ,154 ,סמטת סטרומה
9300 ,זכרון יעקב ,325 ,סמיון
9300 ,זכרון יעקב ,169 ,סנש חנה
9300 ,זכרון יעקב ,268 ,ספינת אגוז
9300 ,זכרון יעקב ,239 ,ספיר
9300 ,זכרון יעקב ,286 ,עגור
9300 ,זכרון יעקב ,290 ,עורבני
9300 ,זכרון יעקב ,320 ,ענבים
9300 ,זכרון יעקב ,237 ,ענבר
9300 ,זכרון יעקב ,111 ,עציון
9300 ,זכרון יעקב ,204 ,ערבה
9300 ,זכרון יעקב ,278 ,פעמי תימן
9300 ,זכרון יעקב ,181 ,פרנק אנה
9300 ,זכרון יעקב ,282 ,פשוש
9300 ,זכרון יעקב ,175 ,צה"ל
9300 ,זכרון יעקב ,283 ,צופית
9300 ,זכרון יעקב ,229 ,צור
9300 ,זכרון יעקב ,145 ,קבוץ גלויות
9300 ,זכרון יעקב ,319 ,קברנה
9300 ,זכרון יעקב ,170 ,קדושי השואה
9300 ,זכרון יעקב ,264 ,קדמה
9300 ,זכרון יעקב ,115 ,קדש
9300 ,זכרון יעקב ,296 ,קציר
9300 ,זכרון יעקב ,324 ,קריניאן
9300 ,זכרון יעקב ,191 ,קרן היסוד
9300 ,זכרון יעקב ,312 ,קרן יער
9300 ,זכרון יעקב ,199 ,ראובן
9300 ,זכרון יעקב ,236 ,רבקה
9300 ,זכרון יעקב ,234 ,רחל
9300 ,זכרון יעקב ,326 ,ריזלינג
9300 ,זכרון יעקב ,180 ,רייק חביבה
9300 ,זכרון יעקב ,142 ,רמז
9300 ,זכרון יעקב ,6505 ,רמת צבי
9300 ,זכרון יעקב ,6506 ,ש צר עמידר
9300 ,זכרון יעקב ,277 ,שבזי
9300 ,זכרון יעקב ,144 ,שבי ציון
9300 ,זכרון יעקב ,210 ,שד בן גוריון
9300 ,זכרון יעקב ,225 ,שד יצחק רבין
9300 ,זכרון יעקב ,128 ,שד ניל"י
9300 ,זכרון יעקב ,280 ,שדרת מעוף הציפור
9300 ,זכרון יעקב ,218 ,שוהם
9300 ,זכרון יעקב ,295 ,שיבולת
9300 ,זכרון יעקב ,322 ,שיראז
9300 ,זכרון יעקב ,270 ,שירה
9300 ,זכרון יעקב ,227 ,שירת הים
9300 ,זכרון יעקב ,6515 ,שכ גבעת זמארין
9300 ,זכרון יעקב ,6513 ,שכ החורש
9300 ,זכרון יעקב ,6516 ,שכ השמורה
9300 ,זכרון יעקב ,6507 ,שכ יעקב
9300 ,זכרון יעקב ,6510 ,שכ מול היקב
9300 ,זכרון יעקב ,223 ,שכטר אבא
9300 ,זכרון יעקב ,302 ,שלף
9300 ,זכרון יעקב ,194 ,שעורה
9300 ,זכרון יעקב ,323 ,שרדונה
9300 ,זכרון יעקב ,249 ,ששת הימים
9300 ,זכרון יעקב ,205 ,תאשור
9300 ,זכרון יעקב ,206 ,תדהר
9300 ,זכרון יעקב ,303 ,תות
9300 ,זכרון יעקב ,208 ,תחנת הרכבת
9300 ,זכרון יעקב ,235 ,תמר
9300 ,זכרון יעקב ,151 ,תרמ"ב
9300 ,זכרון יעקב ,110 ,תרע"ב
9300 ,זכרון יעקב ,245 ,תש"ח
799 ,זכריה ,118 ,הזית
799 ,זכריה ,117 ,הרימון
799 ,זכריה ,115 ,השקד
799 ,זכריה ,116 ,התאנה
799 ,זכריה ,9000 ,זכריה
1290 ,זמר ,101 ,אבתאן
1290 ,זמר ,102 ,ביר א סכה
1290 ,זמר ,9000 ,זמר
1290 ,זמר ,103 ,מרג'ה
1065 ,זמרת ,9000 ,זמרת
816 ,זנוח ,9000 ,זנוח
2064 ,זרועה ,9000 ,זרועה
975 ,זרזיר ,237 ,אבו בכר אלסדיק
975 ,זרזיר ,208 ,אבן אלהית'ם
975 ,זרזיר ,342 ,אבן ח'לדון
975 ,זרזיר ,353 ,אחמד בן חנבל
975 ,זרזיר ,347 ,אחמד שאוקי
975 ,זרזיר ,287 ,אחסבאני
975 ,זרזיר ,186 ,אטלס
975 ,זרזיר ,362 ,איבן סינא
975 ,זרזיר ,354 ,איבן רושד
975 ,זרזיר ,324 ,אירוס
975 ,זרזיר ,273 ,אישראק
975 ,זרזיר ,173 ,אלאא
975 ,זרזיר ,336 ,אלאהראם
975 ,זרזיר ,140 ,אלאופוק
975 ,זרזיר ,231 ,אלאחנף בן קייס
975 ,זרזיר ,244 ,אלאיח'וה
975 ,זרזיר ,246 ,אלאימאן
975 ,זרזיר ,233 ,אלאמין
975 ,זרזיר ,302 ,אלאמל
975 ,זרזיר ,129 ,אלאמראא
975 ,זרזיר ,206 ,אלאנדלוס
975 ,זרזיר ,149 ,אלאנואר
975 ,זרזיר ,132 ,אלאסוד
975 ,זרזיר ,365 ,אלאסראא
975 ,זרזיר ,241 ,אלאסתקלאל
975 ,זרזיר ,309 ,אלאערב
975 ,זרזיר ,185 ,אלבואדי
975 ,זרזיר ,259 ,אלבוח'ארי
975 ,זרזיר ,303 ,אלבוראק
975 ,זרזיר ,232 ,אלבטום
975 ,זרזיר ,145 ,אלבידאא
975 ,זרזיר ,333 ,אלבלח
975 ,זרזיר ,165 ,אלבסאטין
975 ,זרזיר ,330 ,אלבתראא
975 ,זרזיר ,322 ,אלג'ולאן
975 ,זרזיר ,293 ,אלג'זירה
975 ,זרזיר ,121 ,אלג'ליל
975 ,זרזיר ,134 ,אלג'נאת
975 ,זרזיר ,301 ,אלג'נה
975 ,זרזיר ,292 ,אלדוחה
975 ,זרזיר ,115 ,אלהד'בה
975 ,זרזיר ,275 ,אלהייב
975 ,זרזיר ,226 ,אלואדי
975 ,זרזיר ,316 ,אלווטוס
975 ,זרזיר ,271 ,אלוורוד
975 ,זרזיר ,110 ,אלוערה
975 ,זרזיר ,343 ,אלופאא
975 ,זרזיר ,238 ,אלזהראא
975 ,זרזיר ,162 ,אלזוהרה
975 ,זרזיר ,373 ,אלזומורד
975 ,זרזיר ,112 ,אלזופה
975 ,זרזיר ,297 ,אלזיתון
975 ,זרזיר ,320 ,אלזמאן
975 ,זרזיר ,367 ,אלזנבק
975 ,זרזיר ,113 ,אלזעתר
975 ,זרזיר ,144 ,אלח'ד'ראא
975 ,זרזיר ,363 ,אלח'ואריזמי
975 ,זרזיר ,136 ,אלח'יראת
975 ,זרזיר ,334 ,אלח'ליל
975 ,זרזיר ,279 ,אלח'רוב
975 ,זרזיר ,124 ,אלחרמון
975 ,זרזיר ,125 ,אלטור
975 ,זרזיר ,176 ,אלירקון
975 ,זרזיר ,289 ,אלכות'ר
975 ,זרזיר ,169 ,אלכנסת
975 ,זרזיר ,219 ,אלכרום
975 ,זרזיר ,305 ,אלכרמה
975 ,זרזיר ,254 ,אללוז
975 ,זרזיר ,264 ,אללולו
975 ,זרזיר ,361 ,אלמאמון
975 ,זרזיר ,137 ,אלמדינה
975 ,זרזיר ,277 ,אלמדרסה
975 ,זרזיר ,340 ,אלמהא
975 ,זרזיר ,295 ,אלמוח'תאר
975 ,זרזיר ,130 ,אלמולוק
975 ,זרזיר ,332 ,אלמועלם
975 ,זרזיר ,359 ,אלמועתסם
975 ,זרזיר ,228 ,אלמות'נא
975 ,זרזיר ,218 ,אלמלול
975 ,זרזיר ,187 ,אלמנארה
975 ,זרזיר ,286 ,אלמראעי
975 ,זרזיר ,156 ,אלמרג' אלאח'ד'ר
975 ,זרזיר ,150 ,אלנאסרה
975 ,זרזיר ,311 ,אלנבי מוסא
975 ,זרזיר ,215 ,אלנביא סאלח
975 ,זרזיר ,197 ,אלנדים
975 ,זרזיר ,111 ,אלנואר
975 ,זרזיר ,172 ,אלנועמאן
975 ,זרזיר ,249 ,אלנור
975 ,זרזיר ,290 ,אלנורס
975 ,זרזיר ,200 ,אלניזק
975 ,זרזיר ,338 ,אלניל
975 ,זרזיר ,131 ,אלנימר
975 ,זרזיר ,175 ,אלנסמאת
975 ,זרזיר ,285 ,אלנענע
975 ,זרזיר ,127 ,אלנקב
975 ,זרזיר ,299 ,אלנרג'יס
975 ,זרזיר ,307 ,אלסאדה
975 ,זרזיר ,188 ,אלסאהרה
975 ,זרזיר ,209 ,אלסוקור
975 ,זרזיר ,107 ,אלסלאם
975 ,זרזיר ,207 ,אלסנדיאן
975 ,זרזיר ,281 ,אלספאא
975 ,זרזיר ,221 ,אלספוריה
975 ,זרזיר ,319 ,אלסרג'
975 ,זרזיר ,116 ,אלסרו
975 ,זרזיר ,213 ,אלסריס
975 ,זרזיר ,195 ,אלע'ד'נפר
975 ,זרזיר ,193 ,אלע'דיר
975 ,זרזיר ,252 ,אלע'פארי
975 ,זרזיר ,120 ,אלעאלי
975 ,זרזיר ,276 ,אלעולא
975 ,זרזיר ,157 ,אלעז
975 ,זרזיר ,240 ,אלעיין
975 ,זרזיר ,257 ,אלעיקאב
975 ,זרזיר ,230 ,אלעליק
975 ,זרזיר ,217 ,אלעקיק
975 ,זרזיר ,155 ,אלערב
975 ,זרזיר ,329 ,אלפוראת
975 ,זרזיר ,164 ,אלפיחאא
975 ,זרזיר ,245 ,אלפראבי
975 ,זרזיר ,328 ,אלקאדסיה
975 ,זרזיר ,341 ,אלקבס
975 ,זרזיר ,265 ,אלקודס
975 ,זרזיר ,222 ,אלקירואן
975 ,זרזיר ,158 ,אלקעקאע בן עמר
975 ,זרזיר ,133 ,אלראביה
975 ,זרזיר ,227 ,אלרד'ואן
975 ,זרזיר ,372 ,אלרואבי
975 ,זרזיר ,114 ,אלרואד
975 ,זרזיר ,294 ,אלריאד'
975 ,זרזיר ,368 ,אלריחאן
975 ,זרזיר ,360 ,אלרשיד
975 ,זרזיר ,160 ,אלשאטאא
975 ,זרזיר ,210 ,אלשאם
975 ,זרזיר ,247 ,אלשורוק
975 ,זרזיר ,375 ,אלת'וריא
975 ,זרזיר ,263 ,אלתאח'י
975 ,זרזיר ,308 ,אלתוראת'
975 ,זרזיר ,118 ,אלתות
975 ,זרזיר ,306 ,אלתין
975 ,זרזיר ,355 ,אלתקדום
975 ,זרזיר ,310 ,אנואר סאדאת
975 ,זרזיר ,216 ,אנס בן מאלק
975 ,זרזיר ,248 ,אנסאם
975 ,זרזיר ,205 ,אסיא
975 ,זרזיר ,284 ,אסיל
975 ,זרזיר ,291 ,אע'סאן
975 ,זרזיר ,253 ,אריאם
975 ,זרזיר ,366 ,אריג'
975 ,זרזיר ,214 ,אשדוד
975 ,זרזיר ,138 ,בדר
975 ,זרזיר ,139 ,בילאל בן רבאח
975 ,זרזיר ,369 ,בילסאן
975 ,זרזיר ,262 ,ביסאן
975 ,זרזיר ,212 ,בירות
975 ,זרזיר ,151 ,בית לחם
975 ,זרזיר ,282 ,בסמאן
975 ,זרזיר ,179 ,בר לב
975 ,זרזיר ,104 ,ג'ואמיס
975 ,זרזיר ,317 ,ג'ולאני
975 ,זרזיר ,323 ,ג'ומעה
975 ,זרזיר ,141 ,ג'נא
975 ,זרזיר ,250 ,ג'נאן
975 ,זרזיר ,105 ,גזאלין
975 ,זרזיר ,103 ,גריפאת
975 ,זרזיר ,143 ,ד'יאא
975 ,זרזיר ,190 ,דאפר
975 ,זרזיר ,159 ,דימה
975 ,זרזיר ,220 ,האבאנה
975 ,זרזיר ,202 ,האג'ר
975 ,זרזיר ,300 ,האדאס
975 ,זרזיר ,148 ,הגיפן
975 ,זרזיר ,101 ,היב אבו סיאח
975 ,זרזיר ,177 ,המוסך
975 ,זרזיר ,352 ,התעשיה
975 ,זרזיר ,236 ,ואאל
975 ,זרזיר ,198 ,זאיד
975 ,זרזיר ,242 ,זהראן
975 ,זרזיר ,183 ,זהרת אלמדאאן
975 ,זרזיר ,135 ,זמזם
975 ,זרזיר ,9000 ,זרזיר
975 ,זרזיר ,351 ,ח'אלד בן אלוליד
975 ,זרזיר ,335 ,חאתם אלטאאי
975 ,זרזיר ,239 ,חדיג'ה בנת ח'וילד
975 ,זרזיר ,161 ,חיפה
975 ,זרזיר ,325 ,חסן בן ת'אבת
975 ,זרזיר ,196 ,חפסה
975 ,זרזיר ,174 ,טבעון
975 ,זרזיר ,346 ,יאמין
975 ,זרזיר ,191 ,יגאל אלון
975 ,זרזיר ,204 ,יוסף אלסדיק
975 ,זרזיר ,344 ,יזן
975 ,זרזיר ,167 ,יערה
975 ,זרזיר ,194 ,יפא
975 ,זרזיר ,123 ,יפעאת
975 ,זרזיר ,269 ,יצחק רבין
975 ,זרזיר ,304 ,ית'ריב
975 ,זרזיר ,331 ,כאוכב
975 ,זרזיר ,192 ,כנאנה
975 ,זרזיר ,211 ,לובנאן
975 ,זרזיר ,119 ,לוגיין
975 ,זרזיר ,181 ,לימאן
975 ,זרזיר ,339 ,מאאריב
975 ,זרזיר ,117 ,מאריה אלקבטיה
975 ,זרזיר ,224 ,מוטה גור
975 ,זרזיר ,243 ,מוסלים
975 ,זרזיר ,261 ,מוראד
975 ,זרזיר ,102 ,מזאריב
975 ,זרזיר ,312 ,מנחם בגין
975 ,זרזיר ,283 ,מנסור
975 ,זרזיר ,321 ,מסאדה
975 ,זרזיר ,288 ,מסג'ד אלהודא
975 ,זרזיר ,122 ,מרג' בן עאמר
975 ,זרזיר ,170 ,משה דיין
975 ,זרזיר ,234 ,נג'ד
975 ,זרזיר ,370 ,נהלאל
975 ,זרזיר ,201 ,ניסאן
975 ,זרזיר ,203 ,סארה
975 ,זרזיר ,171 ,סאריה
975 ,זרזיר ,199 ,סדים
975 ,זרזיר ,142 ,סהארה
975 ,זרזיר ,280 ,סולטאן באשא
975 ,זרזיר ,371 ,סונדוס
975 ,זרזיר ,126 ,סינאא
975 ,זרזיר ,229 ,סלים אלת'אני
975 ,זרזיר ,274 ,סמא
975 ,זרזיר ,154 ,סעד בן וקאס
975 ,זרזיר ,235 ,סעיד בן זייד
975 ,זרזיר ,168 ,סעסע
975 ,זרזיר ,348 ,ע'די
975 ,זרזיר ,350 ,עאמר בן אלג'ראח
975 ,זרזיר ,152 ,עאתכה
975 ,זרזיר ,255 ,עדן
975 ,זרזיר ,272 ,עוטרה
975 ,זרזיר ,178 ,עומר בן אלח'טאב
975 ,זרזיר ,345 ,עות'מאן בן עפאן
975 ,זרזיר ,106 ,עיאדאת
975 ,זרזיר ,166 ,עין גדי
975 ,זרזיר ,184 ,עלי בן אבי טאלב
975 ,זרזיר ,153 ,עמאר בן יאסר
975 ,זרזיר ,109 ,ערב אלמזאריב
975 ,זרזיר ,182 ,עתלית
975 ,זרזיר ,313 ,פואד בן אליעזר
975 ,זרזיר ,337 ,צה"ל
975 ,זרזיר ,163 ,קוביס
975 ,זרזיר ,278 ,קוטוף
975 ,זרזיר ,223 ,קות'באן
975 ,זרזיר ,260 ,רד'ואן
975 ,זרזיר ,146 ,ריאן
975 ,זרזיר ,374 ,רנד
975 ,זרזיר ,266 ,רע'דאן
975 ,זרזיר ,256 ,שמוס
975 ,זרזיר ,298 ,שמעון פרס
975 ,זרזיר ,296 ,שרון
975 ,זרזיר ,326 ,תאג'
975 ,זרזיר ,180 ,תימאא
818 ,זרחיה ,9000 ,זרחיה
1321 ,ח'ואלד ,9000 ,ח'ואלד
986 ,ח'ואלד (שבט) ,9000 ,ח'ואלד (שבט)
235 ,חבצלת השרון ,101 ,אולפן עקיבא
235 ,חבצלת השרון ,103 ,דרך הבנים
235 ,חבצלת השרון ,104 ,דרך הים
235 ,חבצלת השרון ,107 ,הדקל
235 ,חבצלת השרון ,105 ,הנרקיסים
235 ,חבצלת השרון ,106 ,הפרדסים
235 ,חבצלת השרון ,9000 ,חבצלת השרון
235 ,חבצלת השרון ,6500 ,נוה הדר
235 ,חבצלת השרון ,6501 ,ש רסקו וילות
1110 ,חבר ,112 ,האנפה
1110 ,חבר ,111 ,הבז
1110 ,חבר ,101 ,הגלבוע
1110 ,חבר ,110 ,הדוכיפת
1110 ,חבר ,104 ,הדרור
1110 ,חבר ,105 ,החוחית
1110 ,חבר ,113 ,היונה
1110 ,חבר ,107 ,הינשוף
1110 ,חבר ,103 ,הכרמל
1110 ,חבר ,108 ,המגלן
1110 ,חבר ,109 ,העורב
1110 ,חבר ,106 ,הצופית
1110 ,חבר ,102 ,התבור
1110 ,חבר ,9000 ,חבר
3400 ,חברון ,7001 ,אדמות ישי
3400 ,חברון ,101 ,בית הדסה
3400 ,חברון ,102 ,בית חסון
3400 ,חברון ,108 ,בית קסטל
3400 ,חברון ,104 ,בית רומנו
3400 ,חברון ,105 ,בית שניאורסון
3400 ,חברון ,106 ,הרובע היהודי
3400 ,חברון ,9000 ,חברון
3400 ,חברון ,103 ,כולל אברהם אבינו
3400 ,חברון ,107 ,תל רומיידה
717 ,חגור ,109 ,אירוס
717 ,חגור ,202 ,דרור
717 ,חגור ,108 ,החבצלת
717 ,חגור ,107 ,הנורית
717 ,חגור ,101 ,הסולטן
717 ,חגור ,106 ,הרקפת
717 ,חגור ,201 ,השלדג
717 ,חגור ,9000 ,חגור
717 ,חגור ,103 ,יסמין
717 ,חגור ,104 ,כלנית
717 ,חגור ,203 ,נחליאלי
717 ,חגור ,105 ,נרקיס
717 ,חגור ,205 ,סלעית
717 ,חגור ,204 ,סנונית
3764 ,חגי ,9000 ,חגי
205 ,חגלה ,9000 ,חגלה
205 ,חגלה ,101 ,פרח בר
4026 ,חד-נס ,9000 ,חד-נס
618 ,חדיד ,111 ,ברקן
618 ,חדיד ,110 ,בשמת
618 ,חדיד ,104 ,האירוס
618 ,חדיד ,102 ,היסמין
618 ,חדיד ,107 ,הכלנית
618 ,חדיד ,105 ,הנרקיס
618 ,חדיד ,101 ,הסביון
618 ,חדיד ,109 ,הסייפן
618 ,חדיד ,112 ,הרדוף
618 ,חדיד ,106 ,הרקפת
618 ,חדיד ,103 ,השושן
618 ,חדיד ,9000 ,חדיד
618 ,חדיד ,113 ,לוטם
618 ,חדיד ,108 ,עין התכלת
6500 ,חדרה ,615 ,אביאל
6500 ,חדרה ,722 ,אבשלום
6500 ,חדרה ,560 ,אדני פז
6500 ,חדרה ,427 ,אהרונוביץ
6500 ,חדרה ,450 ,אהרונסון
6500 ,חדרה ,408 ,אוסישקין
6500 ,חדרה ,668 ,אופירה
6500 ,חדרה ,6560 ,אזבסט אליעזר
6500 ,חדרה ,6525 ,אזור תעשייה
6500 ,חדרה ,701 ,אחד העם
6500 ,חדרה ,906 ,אחוזת בית
6500 ,חדרה ,6598 ,אחוזת דניה
6500 ,חדרה ,117 ,אחימאיר אבא
6500 ,חדרה ,879 ,אידלווין
6500 ,חדרה ,872 ,אייל הכרמל
6500 ,חדרה ,954 ,איילון
6500 ,חדרה ,890 ,אילת
6500 ,חדרה ,268 ,אכזיב
6500 ,חדרה ,460 ,אלון יגאל
6500 ,חדרה ,271 ,אלונה
6500 ,חדרה ,740 ,אלוף יקותיאל אדם
6500 ,חדרה ,511 ,אלי כהן
6500 ,חדרה ,400 ,אלעזר דוד
6500 ,חדרה ,302 ,אסטוריה מאיר
6500 ,חדרה ,738 ,אסירי ציון
6500 ,חדרה ,561 ,אפיקי מים
6500 ,חדרה ,907 ,אפעל
6500 ,חדרה ,451 ,אפשטיין משה מרדכי
6500 ,חדרה ,216 ,אצ"ל
6500 ,חדרה ,562 ,ארזי הלבנון
6500 ,חדרה ,205 ,ארלוזורוב
6500 ,חדרה ,835 ,אשבל
6500 ,חדרה ,819 ,אשכול לוי
6500 ,חדרה ,581 ,באר אורה
6500 ,חדרה ,669 ,בארי
6500 ,חדרה ,434 ,בוארון ברוך
6500 ,חדרה ,734 ,בוטקובסקי
6500 ,חדרה ,228 ,בורוכוב
6500 ,חדרה ,409 ,בזק
6500 ,חדרה ,417 ,בי"ח הלל יפה
6500 ,חדרה ,713 ,ביאליק
6500 ,חדרה ,407 ,בילינסון
6500 ,חדרה ,207 ,ביס חקלאי עירון
6500 ,חדרה ,892 ,בלקינד ישראל
6500 ,חדרה ,241 ,בן אליעזר אריה
6500 ,חדרה ,898 ,בן יהודה
6500 ,חדרה ,897 ,בן עמי
6500 ,חדרה ,820 ,בן צבי יצחק
6500 ,חדרה ,952 ,בניאס
6500 ,חדרה ,300 ,בעלי המלאכה
6500 ,חדרה ,655 ,בר אילן מאיר
6500 ,חדרה ,506 ,ברזילי
6500 ,חדרה ,488 ,ברכת עטה
6500 ,חדרה ,237 ,ברמן דוד
6500 ,חדרה ,707 ,ברנדיס
6500 ,חדרה ,411 ,ברנר
6500 ,חדרה ,6520 ,גבעת בוסל
6500 ,חדרה ,6531 ,גבעת בילו א
6500 ,חדרה ,6532 ,גבעת בילו ב
6500 ,חדרה ,563 ,גבעת הלבונה
6500 ,חדרה ,703 ,גבעת המורה
6500 ,חדרה ,670 ,גדור
6500 ,חדרה ,908 ,גדות
6500 ,חדרה ,752 ,גולדה מאיר
6500 ,חדרה ,303 ,גולדנברג
6500 ,חדרה ,108 ,גולומב אליהו
6500 ,חדרה ,413 ,גורדון
6500 ,חדרה ,459 ,גורדון א ד
6500 ,חדרה ,955 ,גוש חלב
6500 ,חדרה ,719 ,גוש עציון
6500 ,חדרה ,909 ,גיבתון
6500 ,חדרה ,589 ,גיורא צחור
6500 ,חדרה ,850 ,גלוזגל
6500 ,חדרה ,255 ,גלוסקא
6500 ,חדרה ,6597 ,גלי חוף
6500 ,חדרה ,956 ,גלעד
6500 ,חדרה ,491 ,גמלא
6500 ,חדרה ,582 ,גן שמואל
6500 ,חדרה ,6567 ,גני ברנדיס
6500 ,חדרה ,6561 ,גני חדרה
6500 ,חדרה ,875 ,גפנוביץ
6500 ,חדרה ,910 ,גרופית
6500 ,חדרה ,452 ,גרינבוים יצחק
6500 ,חדרה ,957 ,גרנות
6500 ,חדרה ,240 ,ד"ר אביאור
6500 ,חדרה ,750 ,ד"ר ברין
6500 ,חדרה ,533 ,ד"ר דן ויליאם
6500 ,חדרה ,576 ,דב לאוטמן
6500 ,חדרה ,830 ,דבורה משבץ
6500 ,חדרה ,213 ,דגניה
6500 ,חדרה ,583 ,דוד המלך
6500 ,חדרה ,880 ,דולינסקי
6500 ,חדרה ,453 ,דורי יעקב
6500 ,חדרה ,498 ,דורשי ציון
6500 ,חדרה ,476 ,דיין משה
6500 ,חדרה ,855 ,דינארד
6500 ,חדרה ,275 ,דלתון
6500 ,חדרה ,958 ,דקל הדום
6500 ,חדרה ,103 ,דרך בית אליעזר
6500 ,חדרה ,539 ,דרך הרכבת
6500 ,חדרה ,1009 ,דרך נחל עירון
6500 ,חדרה ,521 ,ה באייר
6500 ,חדרה ,840 ,האבוקדו
6500 ,חדרה ,105 ,האגוז
6500 ,חדרה ,496 ,האגמון
6500 ,חדרה ,912 ,האדמה
6500 ,חדרה ,959 ,האדמונית
6500 ,חדרה ,960 ,האדר
6500 ,חדרה ,299 ,האומן
6500 ,חדרה ,1001 ,האור
6500 ,חדרה ,605 ,האורן
6500 ,חדרה ,671 ,האושר
6500 ,חדרה ,961 ,האזוב
6500 ,חדרה ,672 ,האחו
6500 ,חדרה ,673 ,האחוה
6500 ,חדרה ,837 ,האילנות
6500 ,חדרה ,753 ,האירוס
6500 ,חדרה ,106 ,האלה
6500 ,חדרה ,913 ,האלומה
6500 ,חדרה ,723 ,האלון
6500 ,חדרה ,914 ,האלונים
6500 ,חדרה ,643 ,האלמוג
6500 ,חדרה ,531 ,האמוראים
6500 ,חדרה ,642 ,האנפה
6500 ,חדרה ,915 ,האסיף
6500 ,חדרה ,962 ,האפרסמון
6500 ,חדרה ,107 ,האקליפטוס
6500 ,חדרה ,650 ,האר"י
6500 ,חדרה ,846 ,הארבל
6500 ,חדרה ,490 ,הארגמן
6500 ,חדרה ,724 ,הארז
6500 ,חדרה ,848 ,האשד
6500 ,חדרה ,102 ,האשור
6500 ,חדרה ,799 ,האשל
6500 ,חדרה ,725 ,האתרוג
6500 ,חדרה ,1002 ,הבאר
6500 ,חדרה ,916 ,הבונים
6500 ,חדרה ,1003 ,הבוסתן
6500 ,חדרה ,917 ,הבזלת
6500 ,חדרה ,619 ,הבנים
6500 ,חדרה ,918 ,הבציר
6500 ,חדרה ,674 ,הבקעה
6500 ,חדרה ,726 ,הברוש
6500 ,חדרה ,516 ,הבריגדה היהודית
6500 ,חדרה ,919 ,הבשמים
6500 ,חדרה ,963 ,הבשמת
6500 ,חדרה ,641 ,הבשן
6500 ,חדרה ,609 ,הגבורים
6500 ,חדרה ,517 ,הגדוד העברי
6500 ,חדרה ,847 ,הגולן
6500 ,חדרה ,921 ,הגושרים
6500 ,חדרה ,675 ,הגלבוע
6500 ,חדרה ,513 ,הגליל
6500 ,חדרה ,770 ,הגפן
6500 ,חדרה ,651 ,הגר"א
6500 ,חדרה ,922 ,הגת
6500 ,חדרה ,785 ,הגתית
6500 ,חדרה ,923 ,הדביר
6500 ,חדרה ,693 ,הדבש
6500 ,חדרה ,822 ,הדגן
6500 ,חדרה ,564 ,הדודאים
6500 ,חדרה ,613 ,הדולב
6500 ,חדרה ,640 ,הדייגים
6500 ,חדרה ,793 ,הדליה
6500 ,חדרה ,754 ,הדפנה
6500 ,חדרה ,203 ,הדקל
6500 ,חדרה ,639 ,הדרדר
6500 ,חדרה ,762 ,הדרור
6500 ,חדרה ,803 ,ההגנה
6500 ,חדרה ,817 ,ההדס
6500 ,חדרה ,600 ,ההדר
6500 ,חדרה ,454 ,ההסתדרות
6500 ,חדרה ,676 ,ההרדוף
6500 ,חדרה ,755 ,הורד
6500 ,חדרה ,310 ,הזגג
6500 ,חדרה ,584 ,הזוגן
6500 ,חדרה ,274 ,הזוויתן
6500 ,חדרה ,732 ,הזית
6500 ,חדרה ,924 ,הזכוכית
6500 ,חדרה ,763 ,הזמיר
6500 ,חדרה ,764 ,החוחית
6500 ,חדרה ,495 ,החולית
6500 ,חדרה ,638 ,החופית
6500 ,חדרה ,756 ,החורש
6500 ,חדרה ,652 ,החיד"א
6500 ,חדרה ,925 ,החיטה
6500 ,חדרה ,849 ,החיננית
6500 ,חדרה ,534 ,החלוץ
6500 ,חדרה ,787 ,החליל
6500 ,חדרה ,926 ,החלמיש
6500 ,חדרה ,585 ,החסידה
6500 ,חדרה ,637 ,החצב
6500 ,חדרה ,298 ,החרש
6500 ,חדרה ,553 ,החשמונאים
6500 ,חדרה ,225 ,הטייסים
6500 ,חדרה ,765 ,היונה
6500 ,חדרה ,297 ,היוצרים
6500 ,חדרה ,766 ,היסמין
6500 ,חדרה ,636 ,היסעור
6500 ,חדרה ,967 ,היערה
6500 ,חדרה ,269 ,הירדן
6500 ,חדרה ,520 ,הירקון
6500 ,חדרה ,757 ,הכלנית
6500 ,חדרה ,789 ,הכנור
6500 ,חדרה ,415 ,הכנסת
6500 ,חדרה ,677 ,הכנרת
6500 ,חדרה ,432 ,הכף גימל
6500 ,חדרה ,823 ,הכרם
6500 ,חדרה ,565 ,הכרמל
6500 ,חדרה ,540 ,הל"ה
6500 ,חדרה ,494 ,הלבנית
6500 ,חדרה ,635 ,הלגונה
6500 ,חדרה ,792 ,הלוטם
6500 ,חדרה ,882 ,הלוי
6500 ,חדרה ,120 ,הליבנה
6500 ,חדרה ,964 ,הלילך
6500 ,חדרה ,834 ,הלימון
6500 ,חדרה ,603 ,הלל יפה
6500 ,חדרה ,927 ,הלשם
6500 ,חדרה ,431 ,המגינים
6500 ,חדרה ,118 ,המולדת
6500 ,חדרה ,709 ,המורן
6500 ,חדרה ,221 ,המושבה
6500 ,חדרה ,653 ,המכבים
6500 ,חדרה ,586 ,המכללה
6500 ,חדרה ,712 ,המכתשים
6500 ,חדרה ,296 ,המסגר
6500 ,חדרה ,519 ,המעפילים
6500 ,חדרה ,399 ,המקשר
6500 ,חדרה ,532 ,הנביאים
6500 ,חדרה ,788 ,הנבל
6500 ,חדרה ,526 ,הנגב
6500 ,חדרה ,791 ,הנגינות
6500 ,חדרה ,773 ,הנורית
6500 ,חדרה ,119 ,הנחלים
6500 ,חדרה ,968 ,הנעמן
6500 ,חדרה ,541 ,הנצי"ב
6500 ,חדרה ,884 ,הנקין
6500 ,חדרה ,758 ,הנרקיס
6500 ,חדרה ,606 ,הנשיא ויצמן
6500 ,חדרה ,634 ,הנשר
6500 ,חדרה ,226 ,הס משה
6500 ,חדרה ,493 ,הסהרון
6500 ,חדרה ,736 ,הסיגלית
6500 ,חדרה ,871 ,הסלע האדום
6500 ,חדרה ,728 ,העבודה
6500 ,חדרה ,587 ,העגור
6500 ,חדרה ,786 ,העוגב
6500 ,חדרה ,514 ,העיר העתיקה
6500 ,חדרה ,616 ,העליה הראשונה
6500 ,חדרה ,210 ,העליה השניה
6500 ,חדרה ,930 ,הענבר
6500 ,חדרה ,804 ,העצמאות
6500 ,חדרה ,729 ,הערבה
6500 ,חדרה ,854 ,הפיקוס
6500 ,חדרה ,824 ,הפלג
6500 ,חדרה ,811 ,הפלמ"ח
6500 ,חדרה ,833 ,הפקאן
6500 ,חדרה ,832 ,הפרדס
6500 ,חדרה ,842 ,הצאלון
6500 ,חדרה ,905 ,הצבעוני
6500 ,חדרה ,566 ,הצוקים
6500 ,חדרה ,678 ,הצנחנים
6500 ,חדרה ,759 ,הצפורן
6500 ,חדרה ,710 ,הצפצפה
6500 ,חדרה ,836 ,הק
6500 ,חדרה ,456 ,הקומונה החדרתית
6500 ,חדרה ,527 ,הקונגרס
6500 ,חדרה ,704 ,הקורמורן
6500 ,חדרה ,825 ,הקידה
6500 ,חדרה ,567 ,הקינמון
6500 ,חדרה ,893 ,הקמה
6500 ,חדרה ,965 ,הקנה
6500 ,חדרה ,931 ,הר אביטל
6500 ,חדרה ,273 ,הר בנטל
6500 ,חדרה ,932 ,הר הנגב
6500 ,חדרה ,947 ,הר חורשן
6500 ,חדרה ,903 ,הר מירון
6500 ,חדרה ,815 ,הר סיני
6500 ,חדרה ,667 ,הר צין
6500 ,חדרה ,525 ,הראשונים
6500 ,חדרה ,654 ,הרב אבו חצירה
6500 ,חדרה ,700 ,הרב אורנשטיין
6500 ,חדרה ,554 ,הרב בוזגלו
6500 ,חדרה ,512 ,הרב דוד משתה
6500 ,חדרה ,625 ,הרב הרצוג
6500 ,חדרה ,550 ,הרב טייב חי
6500 ,חדרה ,116 ,הרב מוהליבר
6500 ,חדרה ,243 ,הרב מימון יהודה
6500 ,חדרה ,552 ,הרב ניסים
6500 ,חדרה ,309 ,הרב עובדיה יוסף
6500 ,חדרה ,626 ,הרב עוזיאל
6500 ,חדרה ,230 ,הרב קוק
6500 ,חדרה ,590 ,הרבי מילובביץ
6500 ,חדרה ,602 ,הרברט סמואל
6500 ,חדרה ,492 ,הרותם
6500 ,חדרה ,575 ,הרי בשמים
6500 ,חדרה ,806 ,הרימונים
6500 ,חדרה ,461 ,הרמב"ן
6500 ,חדרה ,601 ,הרצל
6500 ,חדרה ,760 ,הרקפת
6500 ,חדרה ,250 ,הרשש
6500 ,חדרה ,499 ,השומר
6500 ,חדרה ,809 ,השושנים
6500 ,חדרה ,633 ,השחף
6500 ,חדרה ,826 ,השיזף
6500 ,חדרה ,730 ,השיטה
6500 ,חדרה ,632 ,השלדג
6500 ,חדרה ,679 ,השלום
6500 ,חדרה ,843 ,השלכת
6500 ,חדרה ,790 ,השמינית
6500 ,חדרה ,933 ,השעורה
6500 ,חדרה ,731 ,השקד
6500 ,חדרה ,800 ,השקמה
6500 ,חדרה ,535 ,השרון
6500 ,חדרה ,813 ,התאנים
6500 ,חדרה ,1004 ,התבור
6500 ,חדרה ,761 ,התדהר
6500 ,חדרה ,827 ,התות
6500 ,חדרה ,680 ,התחיה
6500 ,חדרה ,859 ,התמסח
6500 ,חדרה ,805 ,התמרים
6500 ,חדרה ,767 ,התפוז
6500 ,חדרה ,939 ,התפוח
6500 ,חדרה ,681 ,התקוה
6500 ,חדרה ,878 ,וולפברג
6500 ,חדרה ,741 ,וידרקר יצחק
6500 ,חדרה ,887 ,וילנסקי
6500 ,חדרה ,227 ,ויתקין
6500 ,חדרה ,702 ,ז'בוטינסקי
6500 ,חדרה ,889 ,זודקביץ דב
6500 ,חדרה ,888 ,זולוטרוב
6500 ,חדרה ,568 ,חבצלת השרון
6500 ,חדרה ,780 ,חברת עזרא
6500 ,חדרה ,569 ,חגוי הסלע
6500 ,חדרה ,9000 ,חדרה
6500 ,חדרה ,6566 ,חדרה הירוקה
6500 ,חדרה ,6533 ,חדרה הצעירה
6500 ,חדרה ,6599 ,חדרה מזרח
6500 ,חדרה ,231 ,חובבי ציון
6500 ,חדרה ,570 ,חוט השני
6500 ,חדרה ,682 ,חולתה
6500 ,חדרה ,948 ,חומה ומגדל
6500 ,חדרה ,436 ,חוף הכרמל
6500 ,חדרה ,262 ,חורשת טל
6500 ,חדרה ,920 ,חזון איש
6500 ,חדרה ,737 ,חזני מיכאל
6500 ,חדרה ,623 ,חטיבת אלכסנדרוני
6500 ,חדרה ,768 ,חטיבת גבעתי
6500 ,חדרה ,821 ,חטיבת גולני
6500 ,חדרה ,1005 ,חטיבת הנח"ל
6500 ,חדרה ,769 ,חטיבת הראל
6500 ,חדרה ,113 ,חטיבת כרמלי
6500 ,חדרה ,114 ,חטיבת עודד
6500 ,חדרה ,115 ,חטיבת עציוני
6500 ,חדרה ,940 ,חיפה
6500 ,חדרה ,867 ,חלוצה
6500 ,חדרה ,873 ,חניתה
6500 ,חדרה ,304 ,חנקין יהושע
6500 ,חדרה ,812 ,חרובים
6500 ,חדרה ,272 ,חרמון
6500 ,חדרה ,256 ,טביב
6500 ,חדרה ,229 ,טיומקין
6500 ,חדרה ,689 ,טרבלוס
6500 ,חדרה ,206 ,טרומפלדור
6500 ,חדרה ,465 ,טשרניחובסקי שאול
6500 ,חדרה ,251 ,יבניאלי
6500 ,חדרה ,801 ,יגאל
6500 ,חדרה ,478 ,ידין יגאל
6500 ,חדרה ,306 ,יהודה הלוי
6500 ,חדרה ,538 ,יהודי פקיעין
6500 ,חדרה ,121 ,יונת הסלע
6500 ,חדרה ,556 ,יוסף בוסקילה
6500 ,חדרה ,588 ,יחיעם
6500 ,חדרה ,666 ,ים המלח
6500 ,חדרה ,683 ,ים סוף
6500 ,חדרה ,122 ,ינאי המלך
6500 ,חדרה ,735 ,יעקב רבונה
6500 ,חדרה ,6568 ,יער חדרה
6500 ,חדרה ,941 ,יפה נוף
6500 ,חדרה ,694 ,יפת בן יוסף
6500 ,חדרה ,808 ,יציאת אירופה
6500 ,חדרה ,202 ,ירושלים
6500 ,חדרה ,551 ,יש"י
6500 ,חדרה ,692 ,ישיבת בית שמואל
6500 ,חדרה ,220 ,ישעיהו
6500 ,חדרה ,656 ,כ"ח באייר
6500 ,חדרה ,802 ,כ"ט בנובמבר
6500 ,חדרה ,418 ,כביש מס' 2
6500 ,חדרה ,419 ,כביש מס' 4
6500 ,חדרה ,412 ,כהן אוסקר
6500 ,חדרה ,6001 ,ככר עמל
6500 ,חדרה ,891 ,כליל החורש
6500 ,חדרה ,899 ,כספי
6500 ,חדרה ,6601 ,כפר הים
6500 ,חדרה ,6501 ,כפר יוליאנה
6500 ,חדרה ,421 ,כצנלסון ברל
6500 ,חדרה ,831 ,לבוצקין
6500 ,חדרה ,627 ,לבזובסקי
6500 ,חדרה ,857 ,לוברסקי
6500 ,חדרה ,886 ,לויטס
6500 ,חדרה ,657 ,לח"י
6500 ,חדרה ,852 ,ליובין
6500 ,חדרה ,232 ,לילינבלום
6500 ,חדרה ,571 ,לכה דודי
6500 ,חדרה ,265 ,לכיש
6500 ,חדרה ,477 ,לסקוב חיים
6500 ,חדרה ,414 ,לרמן
6500 ,חדרה ,464 ,מאפו אברהם
6500 ,חדרה ,542 ,מבצע דני
6500 ,חדרה ,658 ,מבצע חורב
6500 ,חדרה ,543 ,מבצע חירם
6500 ,חדרה ,544 ,מבצע יואב
6500 ,חדרה ,659 ,מבצע יונתן
6500 ,חדרה ,545 ,מבצע נחשון
6500 ,חדרה ,546 ,מבצע עובדה
6500 ,חדרה ,522 ,מבצע עזרא
6500 ,חדרה ,660 ,מבצע עשר המכות
6500 ,חדרה ,529 ,מבצע קדש
6500 ,חדרה ,110 ,מדורסקי
6500 ,חדרה ,969 ,מדעים ויהדות
6500 ,חדרה ,305 ,מהר"ל
6500 ,חדרה ,257 ,מהרי"ץ
6500 ,חדרה ,416 ,מוסד תלפיות
6500 ,חדרה ,810 ,מורדי הגטאות
6500 ,חדרה ,234 ,מייזר יוסף
6500 ,חדרה ,883 ,מילנר
6500 ,חדרה ,628 ,מלחמת יום הכפורים
6500 ,חדרה ,607 ,מלחמת ששת הימים
6500 ,חדרה ,523 ,מלכי ישראל
6500 ,חדרה ,863 ,ממשית
6500 ,חדרה ,313 ,מנא"י
6500 ,חדרה ,684 ,מנרה
6500 ,חדרה ,6534 ,מעברת ברנדיס
6500 ,חדרה ,647 ,מצפה ים
6500 ,חדרה ,720 ,מקוה ישראל
6500 ,חדרה ,901 ,מקלף מרדכי
6500 ,חדרה ,661 ,מרבד הקסמים
6500 ,חדרה ,844 ,מרגלית
6500 ,חדרה ,537 ,מרדכי קוסטליץ
6500 ,חדרה ,970 ,מרכז הקליטה
6500 ,חדרה ,685 ,משאבי שדה
6500 ,חדרה ,942 ,משגב עם
6500 ,חדרה ,528 ,משה פסטרנק
6500 ,חדרה ,972 ,משמר הגבול
6500 ,חדרה ,442 ,משמר הים
6500 ,חדרה ,123 ,משק הפועלות
6500 ,חדרה ,6580 ,מתחם העירייה
6500 ,חדרה ,1006 ,נאות הכיכר
6500 ,חדרה ,6595 ,נאות רם
6500 ,חדרה ,6600 ,נאות שקד
6500 ,חדרה ,772 ,נהלל
6500 ,חדרה ,839 ,נהרונית
6500 ,חדרה ,966 ,נוב
6500 ,חדרה ,6559 ,נוה ברנדיס
6500 ,חדרה ,428 ,נוה כפרי
6500 ,חדרה ,6519 ,נווה הדרים
6500 ,חדרה ,814 ,נווה חורש
6500 ,חדרה ,648 ,נווה ים
6500 ,חדרה ,708 ,נורדאו
6500 ,חדרה ,851 ,נחומובסקי
6500 ,חדרה ,865 ,נחל כזיב
6500 ,חדרה ,943 ,נחל פולג
6500 ,חדרה ,860 ,נחל פרת
6500 ,חדרה ,861 ,נחל צין
6500 ,חדרה ,264 ,נחל רמון
6500 ,חדרה ,263 ,נחל שורק
6500 ,חדרה ,244 ,נחשונים
6500 ,חדרה ,429 ,ניל"י
6500 ,חדרה ,718 ,ניצנה
6500 ,חדרה ,864 ,ניצנים
6500 ,חדרה ,686 ,נערן
6500 ,חדרה ,862 ,נתיבות
6500 ,חדרה ,881 ,סגל
6500 ,חדרה ,111 ,סולד הנרייטה
6500 ,חדרה ,895 ,סוסקין
6500 ,חדרה ,885 ,סלוצקין
6500 ,חדרה ,618 ,סמ החאן
6500 ,חדרה ,224 ,סמ הרב רוקח
6500 ,חדרה ,743 ,סמ זבוטינסקי
6500 ,חדרה ,622 ,סמ יהודית
6500 ,חדרה ,614 ,סמ יובל
6500 ,חדרה ,217 ,סמ לוי שושנה
6500 ,חדרה ,621 ,סמ מיכאל
6500 ,חדרה ,572 ,סמדר
6500 ,חדרה ,549 ,סמולניק
6500 ,חדרה ,721 ,סמילנסקי
6500 ,חדרה ,751 ,סמסונוב
6500 ,חדרה ,953 ,סנש חנה
6500 ,חדרה ,727 ,ספיר יוסף
6500 ,חדרה ,463 ,עגנון
6500 ,חדרה ,841 ,עובדיה בן שלום
6500 ,חדרה ,662 ,עולי הגרדום
6500 ,חדרה ,869 ,עין ארובות
6500 ,חדרה ,260 ,עין בוקק
6500 ,חדרה ,868 ,עין גב
6500 ,חדרה ,573 ,עין גדי
6500 ,חדרה ,646 ,עין גנים
6500 ,חדרה ,691 ,עין הדבש
6500 ,חדרה ,6602 ,עין הים
6500 ,חדרה ,816 ,עין זיוון
6500 ,חדרה ,261 ,עין עבדת
6500 ,חדרה ,222 ,עינב לוי
6500 ,חדרה ,828 ,עירית
6500 ,חדרה ,242 ,על כנפי נשרים
6500 ,חדרה ,665 ,עלית הנוער
6500 ,חדרה ,435 ,עמק יזרעאל
6500 ,חדרה ,311 ,עמרם אברהם
6500 ,חדרה ,829 ,עצמון
6500 ,חדרה ,617 ,ער אציל
6500 ,חדרה ,574 ,ערוגות הבושם
6500 ,חדרה ,733 ,פיינברג
6500 ,חדרה ,900 ,פייקרש
6500 ,חדרה ,620 ,פינסקר
6500 ,חדרה ,112 ,פיק"א
6500 ,חדרה ,457 ,פלד אשר
6500 ,חדרה ,608 ,פלטרין
6500 ,חדרה ,983 ,פרופ' אברהם הרשקו
6500 ,חדרה ,981 ,פרופ' אהרן צ'חנובר
6500 ,חדרה ,982 ,פרופ' דן שכטמן
6500 ,חדרה ,980 ,פרופ' משה אדד
6500 ,חדרה ,233 ,פרידלנדר
6500 ,חדרה ,580 ,פרנק
6500 ,חדרה ,774 ,פרנקל יצחק
6500 ,חדרה ,462 ,פרץ
6500 ,חדרה ,944 ,צאלים
6500 ,חדרה ,838 ,צבר
6500 ,חדרה ,104 ,צה"ל
6500 ,חדרה ,430 ,צידקוב
6500 ,חדרה ,270 ,ציפורי
6500 ,חדרה ,215 ,צעירי שלום
6500 ,חדרה ,497 ,צפרה
6500 ,חדרה ,945 ,צפת
6500 ,חדרה ,307 ,קארו יוסף
6500 ,חדרה ,807 ,קבוץ גלויות
6500 ,חדרה ,649 ,קדימה
6500 ,חדרה ,245 ,קוטלר
6500 ,חדרה ,312 ,קומבה
6500 ,חדרה ,6596 ,קידמת ים
6500 ,חדרה ,547 ,קלז
6500 ,חדרה ,246 ,קנטרזי
6500 ,חדרה ,1011 ,קניון לב חדרה
6500 ,חדרה ,247 ,קסטל
6500 ,חדרה ,515 ,קרן קיימת לישראל
6500 ,חדרה ,845 ,קשת
6500 ,חדרה ,254 ,ראובן הכהן
6500 ,חדרה ,440 ,ראש הנקרה
6500 ,חדרה ,950 ,רבין יצחק
6500 ,חדרה ,858 ,רבינוביץ
6500 ,חדרה ,894 ,רודין
6500 ,חדרה ,248 ,רוטמן
6500 ,חדרה ,235 ,רון
6500 ,חדרה ,902 ,רזיאל דוד
6500 ,חדרה ,211 ,רח"ש
6500 ,חדרה ,874 ,ריחן
6500 ,חדרה ,771 ,רם
6500 ,חדרה ,604 ,רמב"ם
6500 ,חדרה ,455 ,רמח"ל
6500 ,חדרה ,433 ,רש"י
6500 ,חדרה ,663 ,רשב"ם
6500 ,חדרה ,6581 ,ש אזורים
6500 ,חדרה ,6535 ,ש אזרחי
6500 ,חדרה ,6570 ,ש אלי כהן
6500 ,חדרה ,6503 ,ש בוטקובסקי
6500 ,חדרה ,6504 ,ש בית אליעזר
6500 ,חדרה ,6505 ,ש בני עקיבא
6500 ,חדרה ,6582 ,ש האמוראים
6500 ,חדרה ,6506 ,ש הזיתים
6500 ,חדרה ,6536 ,ש ותיק רח ה
6500 ,חדרה ,6537 ,ש ותיק רח ו
6500 ,חדרה ,6538 ,ש ותיק רח ז
6500 ,חדרה ,6507 ,ש חדש
6500 ,חדרה ,6583 ,ש יד שמואל
6500 ,חדרה ,6539 ,ש יוספטל
6500 ,חדרה ,6508 ,ש לוינסון
6500 ,חדרה ,6509 ,ש מאוריציוס
6500 ,חדרה ,6541 ,ש נוה צבי
6500 ,חדרה ,6510 ,ש סלע
6500 ,חדרה ,6542 ,ש עובדים
6500 ,חדרה ,6584 ,ש עולי סין
6500 ,חדרה ,6543 ,ש עולים חדש
6500 ,חדרה ,6544 ,ש עמל א
6500 ,חדרה ,6545 ,ש עמל ב
6500 ,חדרה ,6546 ,ש פאר
6500 ,חדרה ,6563 ,ש רמב"ם
6500 ,חדרה ,6564 ,ש רמב"ם חדש
6500 ,חדרה ,6511 ,ש רסקו
6500 ,חדרה ,212 ,שאר ישוב
6500 ,חדרה ,252 ,שבזי שלום
6500 ,חדרה ,524 ,שבטי ישראל
6500 ,חדרה ,856 ,שגרגרוצקי
6500 ,חדרה ,971 ,שד אלכסנדר
6500 ,חדרה ,644 ,שד בגין מנחם
6500 ,חדרה ,705 ,שד בן גוריון
6500 ,חדרה ,911 ,שד האביב
6500 ,חדרה ,536 ,שד מינסוטה
6500 ,חדרה ,611 ,שד רוטשילד
6500 ,חדרה ,690 ,שד רחבעם זאבי
6500 ,חדרה ,946 ,שדה בוקר
6500 ,חדרה ,458 ,שדה יצחק
6500 ,חדרה ,223 ,שדרות כושי עפגין
6500 ,חדרה ,1007 ,שומרי החומות
6500 ,חדרה ,548 ,שועלי שמשון
6500 ,חדרה ,1008 ,שושנת העמקים
6500 ,חדרה ,742 ,שז"ר זלמן
6500 ,חדרה ,664 ,שח"ל
6500 ,חדרה ,853 ,שטרנין
6500 ,חדרה ,711 ,שיבטה
6500 ,חדרה ,518 ,שיבת ציון
6500 ,חדרה ,6585 ,שכ אגרובנק
6500 ,חדרה ,6586 ,שכ אולגה א
6500 ,חדרה ,6587 ,שכ אולגה ב
6500 ,חדרה ,6588 ,שכ אולגה ג
6500 ,חדרה ,6589 ,שכ אולגה ד
6500 ,חדרה ,6590 ,שכ אולגה צריפים
6500 ,חדרה ,6547 ,שכ אלרם
6500 ,חדרה ,6591 ,שכ אסבסט אולגה
6500 ,חדרה ,6548 ,שכ אפרים
6500 ,חדרה ,6549 ,שכ ביאליק
6500 ,חדרה ,6592 ,שכ בן גוריון
6500 ,חדרה ,6550 ,שכ ברנדיס
6500 ,חדרה ,6502 ,שכ גני אלון
6500 ,חדרה ,6551 ,שכ האוצר
6500 ,חדרה ,6552 ,שכ הגורן
6500 ,חדרה ,6512 ,שכ הפועל המזרחי
6500 ,חדרה ,6593 ,שכ ויצמן
6500 ,חדרה ,6603 ,שכ חופים
6500 ,חדרה ,6513 ,שכ חפצי בה
6500 ,חדרה ,6514 ,שכ יצחק
6500 ,חדרה ,6575 ,שכ מרכז
6500 ,חדרה ,6515 ,שכ נוה אליעזר
6500 ,חדרה ,6516 ,שכ נוה חיים
6500 ,חדרה ,6553 ,שכ נוה עובד
6500 ,חדרה ,6554 ,שכ נוה עובד א
6500 ,חדרה ,6555 ,שכ נוה עובד ב
6500 ,חדרה ,6556 ,שכ נוה עובד ג
6500 ,חדרה ,6604 ,שכ נווה חורש
6500 ,חדרה ,6557 ,שכ נחליאל
6500 ,חדרה ,6565 ,שכ ניסן
6500 ,חדרה ,6517 ,שכ שלום
6500 ,חדרה ,6521 ,שכ שלמה
6500 ,חדרה ,6558 ,שכ שמשון
6500 ,חדרה ,6594 ,שכון גזית
6500 ,חדרה ,6518 ,שכון יוגוסלבי
6500 ,חדרה ,6522 ,שכונת הפארק
6500 ,חדרה ,555 ,שלהבית
6500 ,חדרה ,818 ,שלמה
6500 ,חדרה ,949 ,שלמה המלך
6500 ,חדרה ,249 ,שמואלזון
6500 ,חדרה ,630 ,שמידט
6500 ,חדרה ,500 ,שמעון פרס
6500 ,חדרה ,422 ,שמעוני דוד
6500 ,חדרה ,238 ,שניאורסון
6500 ,חדרה ,267 ,שניר
6500 ,חדרה ,876 ,שפאק
6500 ,חדרה ,631 ,שפירא
6500 ,חדרה ,645 ,שפירא חיים משה
6500 ,חדרה ,308 ,שפרינצק יוסף
6500 ,חדרה ,866 ,שקמונה
6500 ,חדרה ,877 ,שקצר
6500 ,חדרה ,781 ,שרת משה
6500 ,חדרה ,420 ,תחנת הכח
6500 ,חדרה ,101 ,תחנת הרכבת מזרח
6500 ,חדרה ,687 ,תל דור
6500 ,חדרה ,688 ,תל דן
6500 ,חדרה ,870 ,תל חצור
6500 ,חדרה ,266 ,תמנע
6500 ,חדרה ,530 ,תנאים
6500 ,חדרה ,301 ,תעשייה
6500 ,חדרה ,253 ,תרמ"ב
6500 ,חדרה ,612 ,תרנ"א
6500 ,חדרה ,214 ,תרע"ב
6500 ,חדרה ,441 ,תרצה
948 ,חוג'ייראת (ד'הרה) ,9000 ,חוג'ייראת (ד'הרה)
160 ,חולדה ,9000 ,חולדה
6600 ,חולון ,552 ,אביבים
6600 ,חולון ,981 ,אבידן שמעון
6600 ,חולון ,534 ,אבן גבירול
6600 ,חולון ,726 ,אבן עזרא
6600 ,חולון ,230 ,אבנר
6600 ,חולון ,742 ,אברבנאל
6600 ,חולון ,231 ,אבשלום
6600 ,חולון ,774 ,אגוז
6600 ,חולון ,841 ,אדם יקותיאל
6600 ,חולון ,232 ,אהוד
6600 ,חולון ,554 ,אהל שרה
6600 ,חולון ,901 ,אהרונוביץ
6600 ,חולון ,419 ,אוסישקין
6600 ,חולון ,933 ,אופיר
6600 ,חולון ,221 ,אורי אלמוג
6600 ,חולון ,744 ,אורים
6600 ,חולון ,421 ,אז"ר
6600 ,חולון ,6501 ,אזור תעשיה ב
6600 ,חולון ,6500 ,אזור תעשייה א
6600 ,חולון ,6516 ,אזור תעשייה רסקו ב
6600 ,חולון ,828 ,אח"י דקר
6600 ,חולון ,712 ,אחד במאי
6600 ,חולון ,402 ,אחד העם
6600 ,חולון ,875 ,אילון פנחס
6600 ,חולון ,911 ,אילת
6600 ,חולון ,314 ,אימבר
6600 ,חולון ,609 ,איריס
6600 ,חולון ,941 ,אלופי צה"ל
6600 ,חולון ,535 ,אלחריזי
6600 ,חולון ,753 ,אלי כהן
6600 ,חולון ,1107 ,אליהו הנביא
6600 ,חולון ,233 ,אלישע
6600 ,חולון ,324 ,אלעזר דוד
6600 ,חולון ,541 ,אלקלעי
6600 ,חולון ,550 ,אלרואי
6600 ,חולון ,916 ,אנילביץ
6600 ,חולון ,370 ,אסירי ציון
6600 ,חולון ,914 ,אפעל
6600 ,חולון ,603 ,אצ"ל
6600 ,חולון ,811 ,ארבע ארצות
6600 ,חולון ,765 ,ארגוב מאיר
6600 ,חולון ,203 ,אריק אינשטיין
6600 ,חולון ,1030 ,אריק לביא
6600 ,חולון ,708 ,ארלוזורוב
6600 ,חולון ,792 ,ארליך שמחה
6600 ,חולון ,761 ,ארן זלמן
6600 ,חולון ,122 ,אשתאול
6600 ,חולון ,952 ,בובר מרטין
6600 ,חולון ,701 ,בוסל
6600 ,חולון ,925 ,בוסתנאי
6600 ,חולון ,909 ,בורוכוב
6600 ,חולון ,510 ,בזל
6600 ,חולון ,638 ,בי"ח וולפסון
6600 ,חולון ,503 ,ביאליק
6600 ,חולון ,409 ,ביל"ו
6600 ,חולון ,721 ,בילינסון
6600 ,חולון ,948 ,בית לחם
6600 ,חולון ,320 ,בית שערים
6600 ,חולון ,213 ,בלבן
6600 ,חולון ,720 ,בלום לאון
6600 ,חולון ,758 ,בן אליעזר אריה
6600 ,חולון ,282 ,בן הלל
6600 ,חולון ,423 ,בן יהודה
6600 ,חולון ,279 ,בן עמרם
6600 ,חולון ,327 ,בן-נון יוחאי
6600 ,חולון ,364 ,בני משה
6600 ,חולון ,234 ,בעל מחשבות
6600 ,חולון ,741 ,בעל שם טוב
6600 ,חולון ,306 ,בצלאל
6600 ,חולון ,954 ,בר אילן
6600 ,חולון ,303 ,בר כוכבא
6600 ,חולון ,705 ,ברדיצ'בסקי
6600 ,חולון ,257 ,ברודרזון
6600 ,חולון ,545 ,ברזביל
6600 ,חולון ,762 ,ברזילי ישראל
6600 ,חולון ,1026 ,ברטונוב דבורה
6600 ,חולון ,986 ,ברלב חיים
6600 ,חולון ,305 ,ברנדיס
6600 ,חולון ,522 ,ברנר
6600 ,חולון ,763 ,ברנשטיין פרץ
6600 ,חולון ,759 ,ברץ יוסף
6600 ,חולון ,235 ,ברק
6600 ,חולון ,356 ,ברקאי מיכאל
6600 ,חולון ,610 ,ברקן
6600 ,חולון ,970 ,ברקת ראובן
6600 ,חולון ,317 ,גאולים
6600 ,חולון ,635 ,גבעה 69
6600 ,חולון ,827 ,גבעת התחמושת
6600 ,חולון ,6546 ,גבעת תל גבורים
6600 ,חולון ,632 ,גבעתי
6600 ,חולון ,228 ,גדעון
6600 ,חולון ,969 ,גולדה מאיר
6600 ,חולון ,964 ,גולדשטיין פרץ
6600 ,חולון ,732 ,גולומב
6600 ,חולון ,910 ,גורדון
6600 ,חולון ,722 ,גוש חלב
6600 ,חולון ,633 ,גוש עציון
6600 ,חולון ,352 ,גלילי ישראל
6600 ,חולון ,997 ,גלעדי אהרון
6600 ,חולון ,915 ,גנסין
6600 ,חולון ,755 ,גרינבוים
6600 ,חולון ,798 ,גרינברג אורי צבי
6600 ,חולון ,216 ,גרץ צבי
6600 ,חולון ,752 ,ד"ר כהן היינץ
6600 ,חולון ,118 ,ד"ר פליקס זנדמן
6600 ,חולון ,210 ,ד"ר קפלינסקי נח
6600 ,חולון ,207 ,דבורה הנביאה
6600 ,חולון ,212 ,דבורה עומר
6600 ,חולון ,735 ,דגניה
6600 ,חולון ,958 ,דה שליט עמוס
6600 ,חולון ,211 ,דובנוב
6600 ,חולון ,836 ,דולפין
6600 ,חולון ,974 ,דורי יעקב
6600 ,חולון ,796 ,דיין משה
6600 ,חולון ,626 ,דליה
6600 ,חולון ,1020 ,דמארי שושנה
6600 ,חולון ,738 ,דן שומרון
6600 ,חולון ,236 ,דניאל
6600 ,חולון ,998 ,דרורי אמיר
6600 ,חולון ,785 ,דרך אלון יגאל
6600 ,חולון ,831 ,דרך בית העלמין
6600 ,חולון ,150 ,דרך הביטחון
6600 ,חולון ,101 ,דרך השבעה
6600 ,חולון ,436 ,דרך עזה
6600 ,חולון ,515 ,ה' באייר
6600 ,חולון ,729 ,האבות
6600 ,חולון ,106 ,האורגים
6600 ,חולון ,924 ,האחוה
6600 ,חולון ,128 ,האיזמל
6600 ,חולון ,994 ,האלוף אהרון יריב
6600 ,חולון ,372 ,האלוף תמרי נחמיה
6600 ,חולון ,806 ,האמוראים
6600 ,חולון ,238 ,האנקור
6600 ,חולון ,537 ,האר"י
6600 ,חולון ,347 ,הארז
6600 ,חולון ,340 ,האשל
6600 ,חולון ,291 ,הבוכנות
6600 ,חולון ,107 ,הבנאי
6600 ,חולון ,616 ,הבנים
6600 ,חולון ,824 ,הבקעה
6600 ,חולון ,239 ,הברבור
6600 ,חולון ,341 ,הברוש
6600 ,חולון ,809 ,הגאונים
6600 ,חולון ,105 ,הגביש
6600 ,חולון ,611 ,הגבעול
6600 ,חולון ,428 ,הגדוד העברי
6600 ,חולון ,366 ,הגדנעים
6600 ,חולון ,507 ,הגלבוע
6600 ,חולון ,502 ,הגליל
6600 ,חולון ,501 ,הגלעד
6600 ,חולון ,318 ,הגפן
6600 ,חולון ,743 ,הגר"א
6600 ,חולון ,652 ,הגת
6600 ,חולון ,241 ,הדייה
6600 ,חולון ,1005 ,הדס
6600 ,חולון ,936 ,הדקל
6600 ,חולון ,613 ,הדר
6600 ,חולון ,242 ,הדרור
6600 ,חולון ,431 ,ההגנה
6600 ,חולון ,304 ,ההסתדרות
6600 ,חולון ,922 ,הוגו ארנסט
6600 ,חולון ,930 ,הופיין
6600 ,חולון ,124 ,הזורע
6600 ,חולון ,654 ,הזית
6600 ,חולון ,243 ,הזמיר
6600 ,חולון ,604 ,החבצלת
6600 ,חולון ,244 ,החגלה
6600 ,חולון ,245 ,החוחית
6600 ,חולון ,108 ,החופר
6600 ,חולון ,129 ,החורטים
6600 ,חולון ,904 ,החי"ל
6600 ,חולון ,627 ,החי"ם
6600 ,חולון ,628 ,החי"ש
6600 ,חולון ,650 ,החיטה
6600 ,חולון ,905 ,החלוץ
6600 ,חולון ,815 ,החמניה
6600 ,חולון ,246 ,החמריה
6600 ,חולון ,247 ,החסידה
6600 ,חולון ,605 ,החצב
6600 ,חולון ,542 ,החרמון
6600 ,חולון ,991 ,החרצית
6600 ,חולון ,328 ,החרש
6600 ,חולון ,532 ,החשמונאים
6600 ,חולון ,248 ,הטווס
6600 ,חולון ,634 ,הטייסים
6600 ,חולון ,906 ,הי"ד
6600 ,חולון ,102 ,היובל
6600 ,חולון ,130 ,היוגב
6600 ,חולון ,125 ,היוצר
6600 ,חולון ,653 ,היסמין
6600 ,חולון ,287 ,היתד
6600 ,חולון ,249 ,הכחל
6600 ,חולון ,104 ,הכישור
6600 ,חולון ,319 ,הכנסת
6600 ,חולון ,937 ,הכרם
6600 ,חולון ,109 ,הלהב
6600 ,חולון ,514 ,הלוחמים
6600 ,חולון ,818 ,המגיד
6600 ,חולון ,410 ,המכבים
6600 ,חולון ,110 ,המכתש
6600 ,חולון ,111 ,המלאכה
6600 ,חולון ,121 ,המנור
6600 ,חולון ,713 ,המעורר
6600 ,חולון ,126 ,המעין
6600 ,חולון ,529 ,המעפילים
6600 ,חולון ,601 ,המצודה
6600 ,חולון ,993 ,המרגנית
6600 ,חולון ,112 ,המרכבה
6600 ,חולון ,113 ,המשביר
6600 ,חולון ,285 ,המשור
6600 ,חולון ,934 ,המתמיד
6600 ,חולון ,365 ,המתנדבים
6600 ,חולון ,201 ,הנביאים
6600 ,חולון ,103 ,הנגר
6600 ,חולון ,418 ,הנוטרים
6600 ,חולון ,133 ,הנחושת
6600 ,חולון ,250 ,הנחליאלי
6600 ,חולון ,607 ,הניצנים
6600 ,חולון ,114 ,הנפח
6600 ,חולון ,724 ,הנשיאים
6600 ,חולון ,229 ,הנשר
6600 ,חולון ,339 ,הס משה
6600 ,חולון ,808 ,הסבוראים
6600 ,חולון ,289 ,הסדן
6600 ,חולון ,115 ,הסדנה
6600 ,חולון ,123 ,הסולל
6600 ,חולון ,360 ,הסיגלון
6600 ,חולון ,371 ,הסיירת
6600 ,חולון ,251 ,הסלען
6600 ,חולון ,807 ,הסנהדרין
6600 ,חולון ,252 ,הסנונית
6600 ,חולון ,116 ,הסתת
6600 ,חולון ,803 ,העבודה
6600 ,חולון ,253 ,העגור
6600 ,חולון ,931 ,העוגן
6600 ,חולון ,907 ,העליה השניה
6600 ,חולון ,254 ,העפרוני
6600 ,חולון ,321 ,העצמון
6600 ,חולון ,817 ,הערבה
6600 ,חולון ,631 ,הפורצים
6600 ,חולון ,117 ,הפלד
6600 ,חולון ,417 ,הפלמ"ח
6600 ,חולון ,608 ,הפרדס
6600 ,חולון ,255 ,הצופית
6600 ,חולון ,132 ,הצורף
6600 ,חולון ,912 ,הציונות
6600 ,חולון ,429 ,הצנחנים
6600 ,חולון ,606 ,הצפורן
6600 ,חולון ,719 ,הצפירה
6600 ,חולון ,322 ,הקונגרס
6600 ,חולון ,256 ,הקוקיה
6600 ,חולון ,536 ,הקליר אלעזר
6600 ,חולון ,821 ,הר הצופים
6600 ,חולון ,520 ,הראובני
6600 ,חולון ,621 ,הראל
6600 ,חולון ,973 ,הרב באזוב דוד
6600 ,חולון ,442 ,הרב הרצוג
6600 ,חולון ,810 ,הרב ניסים יצחק
6600 ,חולון ,407 ,הרב קוק
6600 ,חולון ,1008 ,הרדוף הנחלים
6600 ,חולון ,127 ,הרוקמים
6600 ,חולון ,345 ,הרותם
6600 ,חולון ,342 ,הרימון
6600 ,חולון ,711 ,הרצפלד
6600 ,חולון ,602 ,הרקפת
6600 ,חולון ,523 ,הררי
6600 ,חולון ,134 ,השואבים
6600 ,חולון ,439 ,השומר
6600 ,חולון ,504 ,השומרון
6600 ,חולון ,204 ,השופטים
6600 ,חולון ,259 ,השחף
6600 ,חולון ,311 ,השחר
6600 ,חולון ,343 ,השיטה
6600 ,חולון ,823 ,השילוח
6600 ,חולון ,369 ,השמיר
6600 ,חולון ,292 ,השסתומים
6600 ,חולון ,651 ,השעורה
6600 ,חולון ,362 ,השקמה
6600 ,חולון ,258 ,השקנאי
6600 ,חולון ,408 ,השרון
6600 ,חולון ,368 ,השריון
6600 ,חולון ,624 ,התאנה
6600 ,חולון ,349 ,התבור
6600 ,חולון ,348 ,התומר
6600 ,חולון ,260 ,התור
6600 ,חולון ,642 ,התותחנים
6600 ,חולון ,357 ,התחיה
6600 ,חולון ,805 ,התנאים
6600 ,חולון ,825 ,התעלה
6600 ,חולון ,932 ,התקומה
6600 ,חולון ,337 ,וולפסון
6600 ,חולון ,760 ,וולקני יצחק
6600 ,חולון ,702 ,ויצמן
6600 ,חולון ,919 ,ויתקין
6600 ,חולון ,1001 ,ולנברג ראול
6600 ,חולון ,307 ,ורבורג
6600 ,חולון ,416 ,ז'בוטינסקי
6600 ,חולון ,737 ,ז'ן ז'ורס
6600 ,חולון ,548 ,זאבי רחבעם
6600 ,חולון ,278 ,זבולון
6600 ,חולון ,1029 ,זוהר מרים
6600 ,חולון ,284 ,זכריה
6600 ,חולון ,553 ,זכריה הרופא
6600 ,חולון ,717 ,זמנהוף
6600 ,חולון ,812 ,זרובבל
6600 ,חולון ,935 ,חב"ד
6600 ,חולון ,272 ,חבקוק
6600 ,חולון ,277 ,חברון
6600 ,חולון ,273 ,חגי
6600 ,חולון ,274 ,חובב
6600 ,חולון ,509 ,חובבי ציון
6600 ,חולון ,9000 ,חולון
6600 ,חולון ,6552 ,חולון הירוקה
6600 ,חולון ,6545 ,חולון הצעירה
6600 ,חולון ,704 ,חומה ומגדל
6600 ,חולון ,622 ,חזית חמש
6600 ,חולון ,1023 ,חיטמן עוזי
6600 ,חולון ,294 ,חיים חפר
6600 ,חולון ,740 ,חכמי ישראל
6600 ,חולון ,819 ,חלץ
6600 ,חולון ,1120 ,חניון אוטובוסים
6600 ,חולון ,1101 ,חנניה
6600 ,חולון ,415 ,חנקין
6600 ,חולון ,643 ,חסדאי
6600 ,חולון ,832 ,חצבה
6600 ,חולון ,747 ,חצרים
6600 ,חולון ,351 ,טבנקין יצחק
6600 ,חולון ,218 ,טהון
6600 ,חולון ,438 ,טרומפלדור
6600 ,חולון ,733 ,טשרניחובסקי
6600 ,חולון ,715 ,י"א באדר
6600 ,חולון ,361 ,י"א הספורטאים
6600 ,חולון ,276 ,יאיר הגלעדי
6600 ,חולון ,714 ,יבנה
6600 ,חולון ,771 ,יבניאלי שמואל
6600 ,חולון ,338 ,יד לגולה
6600 ,חולון ,323 ,ידין יגאל
6600 ,חולון ,517 ,יהודה הלוי
6600 ,חולון ,835 ,יהודה הנשיא
6600 ,חולון ,135 ,יהודה מקוגן
6600 ,חולון ,1102 ,יהושפט
6600 ,חולון ,261 ,יואב
6600 ,חולון ,1105 ,יואל
6600 ,חולון ,962 ,יום הכיפורים
6600 ,חולון ,288 ,יונה
6600 ,חולון ,280 ,יוסי בנאי
6600 ,חולון ,527 ,יוסף הנשיא
6600 ,חולון ,262 ,יותם
6600 ,חולון ,223 ,יחזקאל
6600 ,חולון ,801 ,יטבתה
6600 ,חולון ,734 ,יל"ג
6600 ,חולון ,330 ,יסוד המעלה
6600 ,חולון ,217 ,יעבץ
6600 ,חולון ,263 ,יעל
6600 ,חולון ,297 ,יעקב פרי (פרולוב)
6600 ,חולון ,980 ,יפה אברהם
6600 ,חולון ,772 ,יפה אליעזר
6600 ,חולון ,617 ,יפו
6600 ,חולון ,264 ,יפתח
6600 ,חולון ,531 ,יצחק הלוי
6600 ,חולון ,950 ,יריחו
6600 ,חולון ,202 ,ירמיהו
6600 ,חולון ,1021 ,ירקוני יפה
6600 ,חולון ,222 ,ישעיהו
6600 ,חולון ,767 ,ישראלי בן ציון
6600 ,חולון ,6001 ,ככר בארי
6600 ,חולון ,6008 ,ככר הלוחמים
6600 ,חולון ,6002 ,ככר המוסדות
6600 ,חולון ,6003 ,ככר השבעה
6600 ,חולון ,6004 ,ככר השלושה
6600 ,חולון ,6007 ,ככר ויצמן
6600 ,חולון ,6005 ,ככר סטרומה
6600 ,חולון ,6006 ,ככר סירן
6600 ,חולון ,612 ,כלנית
6600 ,חולון ,331 ,כנרת
6600 ,חולון ,754 ,כפר גלעדי
6600 ,חולון ,921 ,לבון פנחס
6600 ,חולון ,630 ,לוחמי הנגב
6600 ,חולון ,837 ,לויתן
6600 ,חולון ,639 ,לח"י
6600 ,חולון ,1007 ,ליבנה
6600 ,חולון ,404 ,לילינבלום
6600 ,חולון ,718 ,ליסין
6600 ,חולון ,506 ,לכיש
6600 ,חולון ,797 ,לנדאו חיים
6600 ,חולון ,716 ,לסל
6600 ,חולון ,794 ,לסקוב חיים
6600 ,חולון ,312 ,מאפו
6600 ,חולון ,620 ,מבצע סיני
6600 ,חולון ,826 ,מגידו
6600 ,חולון ,730 ,מודיעין
6600 ,חולון ,333 ,מוהליבר
6600 ,חולון ,1110 ,מוזיאון רכב הסטורי
6600 ,חולון ,990 ,מוטה גור
6600 ,חולון ,521 ,מולכו שלמה
6600 ,חולון ,516 ,מונטיפיורי
6600 ,חולון ,301 ,מוצקין
6600 ,חולון ,908 ,מורדי הגטאות
6600 ,חולון ,508 ,מזא"ה
6600 ,חולון ,967 ,מטולה
6600 ,חולון ,265 ,מיכה
6600 ,חולון ,773 ,מילגרום
6600 ,חולון ,444 ,מימון
6600 ,חולון ,290 ,מישאל
6600 ,חולון ,546 ,מכס וג'סי כהן
6600 ,חולון ,266 ,מלאכי
6600 ,חולון ,434 ,מנדלי מוכר ספרים
6600 ,חולון ,335 ,מנדלסון
6600 ,חולון ,131 ,מנוח
6600 ,חולון ,1022 ,מנור אהוד
6600 ,חולון ,814 ,מסילת העולים
6600 ,חולון ,963 ,מעלות
6600 ,חולון ,942 ,מפרץ שלמה
6600 ,חולון ,426 ,מקוה ישראל
6600 ,חולון ,799 ,מקלף מרדכי
6600 ,חולון ,226 ,מרבד הקסמים
6600 ,חולון ,219 ,מרדכי שפיגל
6600 ,חולון ,1106 ,מרים הנביאה
6600 ,חולון ,1050 ,מרכז רפואי וולפסון
6600 ,חולון ,405 ,מרקובצקי
6600 ,חולון ,200 ,משה רינת
6600 ,חולון ,445 ,משמר הירדן
6600 ,חולון ,784 ,משעול דר
6600 ,חולון ,788 ,משעול הבדולח
6600 ,חולון ,783 ,משעול הטופז
6600 ,חולון ,781 ,משעול היהלום
6600 ,חולון ,789 ,משעול הלשם
6600 ,חולון ,782 ,משעול הנופך
6600 ,חולון ,790 ,משעול הספירים
6600 ,חולון ,787 ,משעול הפז
6600 ,חולון ,851 ,משעול השוהם
6600 ,חולון ,649 ,משעול השקד
6600 ,חולון ,786 ,משעול התרשיש
6600 ,חולון ,636 ,משעול יוסף יהונתן
6600 ,חולון ,852 ,משעול ענבר
6600 ,חולון ,6600 ,מתחם חולות א
6600 ,חולון ,6601 ,מתחם חולות ב
6600 ,חולון ,6603 ,מתחם חולות ד
6600 ,חולון ,6517 ,נאות יהודית
6600 ,חולון ,6530 ,נאות שושנים
6600 ,חולון ,703 ,נג'ארה ישראל
6600 ,חולון ,367 ,נהריה
6600 ,חולון ,358 ,נובידבור
6600 ,חולון ,6527 ,נוה ארזים
6600 ,חולון ,6565 ,נווה רמז
6600 ,חולון ,293 ,נועה אשכול
6600 ,חולון ,427 ,נורדאו
6600 ,חולון ,1006 ,נורית
6600 ,חולון ,267 ,נחום
6600 ,חולון ,551 ,נחלת שלום
6600 ,חולון ,205 ,נחמיה
6600 ,חולון ,618 ,נחשון
6600 ,חולון ,430 ,נטר קרל
6600 ,חולון ,424 ,ניל"י
6600 ,חולון ,220 ,ניסנבאום
6600 ,חולון ,413 ,נס ציונה
6600 ,חולון ,710 ,נפחא
6600 ,חולון ,985 ,נרקיס עוזי
6600 ,חולון ,329 ,סאלק
6600 ,חולון ,353 ,סבידור מנחם
6600 ,חולון ,615 ,סביון
6600 ,חולון ,519 ,סולד הנריטה
6600 ,חולון ,401 ,סוקולוב
6600 ,חולון ,903 ,סירני
6600 ,חולון ,902 ,סירקין
6600 ,חולון ,995 ,סמ הניסנית
6600 ,חולון ,992 ,סמ הרוזמרין
6600 ,חולון ,310 ,סמולנסקין
6600 ,חולון ,987 ,סמטת הרעות
6600 ,חולון ,988 ,סמטת השלום
6600 ,חולון ,972 ,סנה משה
6600 ,חולון ,425 ,סנש
6600 ,חולון ,530 ,סעדיה גאון
6600 ,חולון ,332 ,ספיר יוסף
6600 ,חולון ,645 ,סרלין יוסף
6600 ,חולון ,955 ,עגנון ש"י
6600 ,חולון ,268 ,עובדיה
6600 ,חולון ,355 ,עוזיאל
6600 ,חולון ,206 ,עזרא
6600 ,חולון ,1104 ,עזריה
6600 ,חולון ,961 ,עטרות
6600 ,חולון ,802 ,עין גדי
6600 ,חולון ,816 ,עין יהב
6600 ,חולון ,1010 ,עירית גדולה
6600 ,חולון ,225 ,עמוס
6600 ,חולון ,120 ,עמינדב
6600 ,חולון ,913 ,עמל
6600 ,חולון ,619 ,עמק אילון
6600 ,חולון ,965 ,עמק דותן
6600 ,חולון ,411 ,עמק יזרעאל
6600 ,חולון ,296 ,עפרה חזה
6600 ,חולון ,344 ,פבריגט אנריקו
6600 ,חולון ,363 ,פדויי השבי
6600 ,חולון ,996 ,פהר אילונה
6600 ,חולון ,1024 ,פורת אורנה
6600 ,חולון ,433 ,פיארברג
6600 ,חולון ,756 ,פייר קניג
6600 ,חולון ,751 ,פיכמן
6600 ,חולון ,623 ,פילדלפיה
6600 ,חולון ,309 ,פינסקר
6600 ,חולון ,441 ,פנחס ספיר
6600 ,חולון ,422 ,פרוג
6600 ,חולון ,209 ,פרופ' שור
6600 ,חולון ,403 ,פרישמן
6600 ,חולון ,227 ,פרנק אנה
6600 ,חולון ,437 ,פרץ
6600 ,חולון ,414 ,פתח תקוה
6600 ,חולון ,511 ,צ'לנוב
6600 ,חולון ,748 ,צאלים
6600 ,חולון ,736 ,צבי תדמור
6600 ,חולון ,793 ,צדוק דוד
6600 ,חולון ,739 ,צעירי מזרח
6600 ,חולון ,269 ,צפניה
6600 ,חולון ,966 ,צפת
6600 ,חולון ,281 ,צרעה
6600 ,חולון ,302 ,קבוץ גלויות
6600 ,חולון ,326 ,קדושי בלזן
6600 ,חולון ,750 ,קדושי קהיר
6600 ,חולון ,354 ,קדיש לוז
6600 ,חולון ,960 ,קדמן
6600 ,חולון ,830 ,קהילת וילנה
6600 ,חולון ,829 ,קהילת ורשה
6600 ,חולון ,270 ,קוהלת
6600 ,חולון ,214 ,קורצ'ק
6600 ,חולון ,1027 ,קישון אפרים
6600 ,חולון ,951 ,קלויזנר
6600 ,חולון ,237 ,קלומל
6600 ,חולון ,420 ,קלישר
6600 ,חולון ,1028 ,קניג ליא
6600 ,חולון ,525 ,קפאח
6600 ,חולון ,513 ,קפלן
6600 ,חולון ,766 ,קפלנסקי
6600 ,חולון ,957 ,קציר אהרון
6600 ,חולון ,917 ,קצנלסון יצחק
6600 ,חולון ,406 ,קראוזה
6600 ,חולון ,6602 ,קרית יצחק רבין
6600 ,חולון ,6585 ,קרית מיכה
6600 ,חולון ,6555 ,קרית עבודה
6600 ,חולון ,6604 ,קרית פנחס אילון
6600 ,חולון ,968 ,קרית שמונה
6600 ,חולון ,625 ,קריתי
6600 ,חולון ,947 ,קרן היסוד
6600 ,חולון ,946 ,קרן קיימת
6600 ,חולון ,791 ,קרסל גצל
6600 ,חולון ,325 ,ראש פנה
6600 ,חולון ,412 ,ראשון לציון
6600 ,חולון ,308 ,רבי עקיבא
6600 ,חולון ,984 ,רבין יצחק
6600 ,חולון ,795 ,רבינוביץ יהושע
6600 ,חולון ,1031 ,רוזנבלום יאיר
6600 ,חולון ,512 ,רוטשילד
6600 ,חולון ,315 ,רום משה
6600 ,חולון ,334 ,רופין
6600 ,חולון ,764 ,רותר ולטר
6600 ,חולון ,928 ,רח ג אזור
6600 ,חולון ,614 ,רח ו תל גבורים
6600 ,חולון ,224 ,רח ט
6600 ,חולון ,820 ,רח כ"ג
6600 ,חולון ,776 ,רח כד
6600 ,חולון ,728 ,רחל
6600 ,חולון ,336 ,ריינס
6600 ,חולון ,971 ,רייק חביבה
6600 ,חולון ,283 ,רינגלבלום
6600 ,חולון ,533 ,רמב"ם
6600 ,חולון ,918 ,רמז
6600 ,חולון ,709 ,רמח"ל
6600 ,חולון ,943 ,רמת הגולן
6600 ,חולון ,999 ,רפאל איתן
6600 ,חולון ,524 ,רש"י
6600 ,חולון ,6547 ,ש אביב
6600 ,חולון ,6518 ,ש אזבסטונים
6600 ,חולון ,6576 ,ש אקדמאים
6600 ,חולון ,6519 ,ש בטיש
6600 ,חולון ,6586 ,ש ג'סי כהן
6600 ,חולון ,6520 ,ש ההסתדרות
6600 ,חולון ,6521 ,ש השומרונים
6600 ,חולון ,6587 ,ש חסכון
6600 ,חולון ,6531 ,ש מומחים
6600 ,חולון ,6522 ,ש מפדה אזרחי
6600 ,חולון ,6561 ,ש נאות רחל
6600 ,חולון ,6548 ,ש עולה חדש
6600 ,חולון ,6549 ,ש עולים תל גבורים
6600 ,חולון ,6523 ,ש עמידר
6600 ,חולון ,6524 ,ש פרוגרסיבים
6600 ,חולון ,6589 ,ש קליפורניה
6600 ,חולון ,6525 ,ש רסקו ב
6600 ,חולון ,6526 ,ש רסקו ג
6600 ,חולון ,6550 ,ש תל גבורים
6600 ,חולון ,518 ,ש"ץ צבי
6600 ,חולון ,982 ,שאלתיאל דוד
6600 ,חולון ,727 ,שבזי
6600 ,חולון ,746 ,שבטה
6600 ,חולון ,923 ,שבטי ישראל
6600 ,חולון ,920 ,שביל א.ד.ג.
6600 ,חולון ,983 ,שד בגין מנחם
6600 ,חולון ,800 ,שד בן גוריון
6600 ,חולון ,725 ,שד הוז דב
6600 ,חולון ,749 ,שד יוספטל
6600 ,חולון ,316 ,שד ירושלים
6600 ,חולון ,637 ,שד לוי אשכול
6600 ,חולון ,505 ,שד קוגל
6600 ,חולון ,540 ,שד"ל
6600 ,חולון ,745 ,שדה בוקר
6600 ,חולון ,543 ,שדה יצחק
6600 ,חולון ,770 ,שוחט מניה וישראל
6600 ,חולון ,350 ,שטרוק
6600 ,חולון ,813 ,שיבת ציון
6600 ,חולון ,6503 ,שיכון אגרובנק
6600 ,חולון ,6528 ,שיכון עממי
6600 ,חולון ,215 ,שיפר
6600 ,חולון ,6595 ,שכ גבעת חולון
6600 ,חולון ,6551 ,שכ גרין
6600 ,חולון ,6584 ,שכ הקרוונים
6600 ,חולון ,6596 ,שכ מולדת
6600 ,חולון ,6597 ,שכ קרית בן גוריון
6600 ,חולון ,6506 ,שכ קרית שרת
6600 ,חולון ,6577 ,שכון עירוני ג
6600 ,חולון ,6553 ,שכונת עם
6600 ,חולון ,346 ,שכטר
6600 ,חולון ,435 ,שלום עליכם
6600 ,חולון ,271 ,שמואל
6600 ,חולון ,526 ,שמואל הנגיד
6600 ,חולון ,359 ,שמורק
6600 ,חולון ,731 ,שמעוני
6600 ,חולון ,1103 ,שמעיה
6600 ,חולון ,1025 ,שמר נעמי
6600 ,חולון ,313 ,שמריהו לוין
6600 ,חולון ,275 ,שמשון
6600 ,חולון ,706 ,שנקר
6600 ,חולון ,940 ,שער האריות
6600 ,חולון ,629 ,שער הגיא
6600 ,חולון ,949 ,שער הפרחים
6600 ,חולון ,959 ,שער ציון
6600 ,חולון ,956 ,שפירא חיים משה
6600 ,חולון ,432 ,שפירא צבי
6600 ,חולון ,443 ,שפרינצק
6600 ,חולון ,295 ,שרה לוי תנאי
6600 ,חולון ,944 ,שרת משה
6600 ,חולון ,822 ,ששת הימים
6600 ,חולון ,723 ,תל חי
6600 ,חולון ,119 ,תמנע
6600 ,חולון ,1009 ,תמר
6600 ,חולון ,707 ,תרצ"ו
1239 ,חולית ,9000 ,חולית
253 ,חולתה ,101 ,בית ראשון במולדת
253 ,חולתה ,9000 ,חולתה
662 ,חוסן ,107 ,בית"ר
662 ,חוסן ,115 ,האגם
662 ,חוסן ,121 ,האורנים
662 ,חוסן ,120 ,האלה
662 ,חוסן ,117 ,האלונים
662 ,חוסן ,102 ,הבנים
662 ,חוסן ,122 ,הברושים
662 ,חוסן ,119 ,הדולב
662 ,חוסן ,113 ,הזיתים
662 ,חוסן ,106 ,החקלאים
662 ,חוסן ,116 ,החרוב
662 ,חוסן ,101 ,המייסדים
662 ,חוסן ,112 ,הנחל
662 ,חוסן ,114 ,הערבות
662 ,חוסן ,103 ,הראשונים
662 ,חוסן ,123 ,השקד
662 ,חוסן ,9000 ,חוסן
662 ,חוסן ,111 ,יהדות מרוקו
662 ,חוסן ,118 ,כליל החורש
662 ,חוסן ,124 ,מורן החורש
662 ,חוסן ,105 ,עולי סין
662 ,חוסן ,109 ,שביל חרות
662 ,חוסן ,108 ,שביל קק"ל
662 ,חוסן ,110 ,שלום שארלי שלוש
662 ,חוסן ,104 ,שלמה בן יוסף
1332 ,חוסנייה ,9000 ,חוסנייה
115 ,חופית ,101 ,אלמוג
115 ,חופית ,109 ,האקליפטוס
115 ,חופית ,102 ,הסנונית
115 ,חופית ,108 ,הצדף
115 ,חופית ,103 ,השלדג
115 ,חופית ,110 ,השקמים
115 ,חופית ,9000 ,חופית
115 ,חופית ,104 ,כוכב הים
115 ,חופית ,105 ,מורד הנחל
115 ,חופית ,107 ,שונית
115 ,חופית ,106 ,שחף
374 ,חוקוק ,101 ,בית ראשון במולדת
374 ,חוקוק ,9000 ,חוקוק
1303 ,חורה ,166 ,אבו בקר אלצדיק
1303 ,חורה ,149 ,אבו חאמד אלע'זאלי
1303 ,חורה ,104 ,אבו סמארה
1303 ,חורה ,158 ,אבו עביידה
1303 ,חורה ,117 ,אבן ח'לדון
1303 ,חורה ,146 ,אבן סינא
1303 ,חורה ,106 ,אחמד בן חנבל
1303 ,חורה ,143 ,איבן תאימייה
1303 ,חורה ,138 ,אל חוארזמי
1303 ,חורה ,242 ,אלאבדאע
1303 ,חורה ,223 ,אלאהראם
1303 ,חורה ,184 ,אלאח'אא
1303 ,חורה ,147 ,אלאח'וה
1303 ,חורה ,172 ,אלאח'לאס
1303 ,חורה ,133 ,אלאחסאן
1303 ,חורה ,173 ,אלאמל
1303 ,חורה ,165 ,אלאנדלוס
1303 ,חורה ,199 ,אלאנואר
1303 ,חורה ,224 ,אלאסראא
1303 ,חורה ,144 ,אלבוראק
1303 ,חורה ,220 ,אלביאדר
1303 ,חורה ,125 ,אלבירוני
1303 ,חורה ,229 ,אלג'אמע
1303 ,חורה ,196 ,אלג'זירה
1303 ,חורה ,140 ,אלג'מאמה
1303 ,חורה ,254 ,אלדוחא
1303 ,חורה ,154 ,אלהג'רה
1303 ,חורה ,171 ,אלהודא
1303 ,חורה ,145 ,אלואחה
1303 ,חורה ,122 ,אלופאא
1303 ,חורה ,227 ,אלזהור
1303 ,חורה ,139 ,אלזהראא
1303 ,חורה ,135 ,אלזיתון
1303 ,חורה ,159 ,אלזעתר
1303 ,חורה ,252 ,אלח'טאב
1303 ,חורה ,233 ,אלח'ליל
1303 ,חורה ,251 ,אלח'נסאא
1303 ,חורה ,200 ,אלחג'
1303 ,חורה ,161 ,אלחוסין בן עלי
1303 ,חורה ,112 ,אלחיאה
1303 ,חורה ,141 ,אלחכמה
1303 ,חורה ,228 ,אלחריה
1303 ,חורה ,150 ,אלחרם
1303 ,חורה ,245 ,אלטואחין
1303 ,חורה ,186 ,אלטויל
1303 ,חורה ,206 ,אלטיבה
1303 ,חורה ,113 ,אלירמוק
1303 ,חורה ,210 ,אלכרם
1303 ,חורה ,114 ,אלכרמה
1303 ,חורה ,137 ,אלמג'ד
1303 ,חורה ,120 ,אלמדינה
1303 ,חורה ,190 ,אלמהדי
1303 ,חורה ,136 ,אלמיזאן
1303 ,חורה ,225 ,אלמנארה
1303 ,חורה ,188 ,אלמנסור
1303 ,חורה ,157 ,אלמעתסם
1303 ,חורה ,208 ,אלמרוה
1303 ,חורה ,248 ,אלמריך
1303 ,חורה ,222 ,אלמתנבי
1303 ,חורה ,160 ,אלנג'את
1303 ,חורה ,216 ,אלנור
1303 ,חורה ,214 ,אלנח'יל
1303 ,חורה ,238 ,אלנעים
1303 ,חורה ,219 ,אלסביל
1303 ,חורה ,148 ,אלסבר
1303 ,חורה ,217 ,אלסלאם
1303 ,חורה ,253 ,אלסנאעה
1303 ,חורה ,168 ,אלספאא
1303 ,חורה ,131 ,אלעבאס
1303 ,חורה ,126 ,אלעטאא
1303 ,חורה ,241 ,אלעין
1303 ,חורה ,243 ,אלעמאל
1303 ,חורה ,116 ,אלעקאד
1303 ,חורה ,194 ,אלעקבה
1303 ,חורה ,230 ,אלערב
1303 ,חורה ,185 ,אלפוראת
1303 ,חורה ,119 ,אלפרוק
1303 ,חורה ,109 ,אלפרקאן
1303 ,חורה ,189 ,אלפתח
1303 ,חורה ,192 ,אלקאהרה
1303 ,חורה ,123 ,אלקדסייה
1303 ,חורה ,110 ,אלקודס
1303 ,חורה ,250 ,אלקירואן
1303 ,חורה ,240 ,אלקלעה
1303 ,חורה ,235 ,אלקסר
1303 ,חורה ,239 ,אלראיה
1303 ,חורה ,195 ,אלריבאט
1303 ,חורה ,164 ,אלריחאן
1303 ,חורה ,162 ,אלרמאן
1303 ,חורה ,198 ,אלשאם
1303 ,חורה ,155 ,אלשאפעי
1303 ,חורה ,132 ,אלשלאלה
1303 ,חורה ,234 ,אלשריף
1303 ,חורה ,236 ,אלת'קאפה
1303 ,חורה ,121 ,אלתין
1303 ,חורה ,174 ,אלתעאון
1303 ,חורה ,170 ,אלתקוא
1303 ,חורה ,191 ,אם אלקורא
1303 ,חורה ,193 ,בגדאד
1303 ,חורה ,163 ,בדר
1303 ,חורה ,204 ,ביסאן
1303 ,חורה ,156 ,בית אלמקדס
1303 ,חורה ,129 ,ג'בל אלזיתון
1303 ,חורה ,237 ,דג'לה
1303 ,חורה ,152 ,הארון אלרשיד
1303 ,חורה ,207 ,זמזם
1303 ,חורה ,9000 ,חורה
1303 ,חורה ,115 ,חטין
1303 ,חורה ,151 ,חיפה
1303 ,חורה ,153 ,חמזה
1303 ,חורה ,102 ,חסן אלבצרי
1303 ,חורה ,187 ,טארק בן זיאד
1303 ,חורה ,205 ,טבריה
1303 ,חורה ,130 ,יפו
1303 ,חורה ,211 ,מאלק
1303 ,חורה ,128 ,מוזדלפה
1303 ,חורה ,105 ,מועד בן ג'בל
1303 ,חורה ,249 ,מראקש
1303 ,חורה ,118 ,סלאח אלדין
1303 ,חורה ,213 ,סלמאן אלפארסי
1303 ,חורה ,103 ,סעד בן אבי ווקאס
1303 ,חורה ,142 ,עבדאלה בן רוחה
1303 ,חורה ,124 ,עומר אל מוח'תאר
1303 ,חורה ,221 ,עין סארה
1303 ,חורה ,101 ,עלי בן אבי טאלב
1303 ,חורה ,212 ,עסקלאן
1303 ,חורה ,197 ,ערפה
1303 ,חורה ,209 ,רג'ב
1303 ,חורה ,6702 ,שכ 1
1303 ,חורה ,6716 ,שכ 11
1303 ,חורה ,6711 ,שכ 2
1303 ,חורה ,6717 ,שכ 12
1303 ,חורה ,6703 ,שכ 3
1303 ,חורה ,6712 ,שכ 13
1303 ,חורה ,6704 ,שכ 4
1303 ,חורה ,6718 ,שכ 14
1303 ,חורה ,6705 ,שכ 5
1303 ,חורה ,6715 ,שכ 15
1303 ,חורה ,6706 ,שכ 6
1303 ,חורה ,6719 ,שכ 16
1303 ,חורה ,6707 ,שכ 7
1303 ,חורה ,6720 ,שכ 17
1303 ,חורה ,6713 ,שכ 8
1303 ,חורה ,6714 ,שכ 18
1303 ,חורה ,6709 ,שכ 9
1303 ,חורה ,6721 ,שכ 19
1303 ,חורה ,6701 ,שכ יסמין
1303 ,חורה ,169 ,שמס
496 ,חורפיש ,9000 ,חורפיש
355 ,חורשים ,9000 ,חורשים
1047 ,חזון ,9000 ,חזון
219 ,חיבת ציון ,104 ,אודים
219 ,חיבת ציון ,105 ,אל הפלחה
219 ,חיבת ציון ,103 ,דרור
219 ,חיבת ציון ,101 ,המגשימים
219 ,חיבת ציון ,107 ,הסילו
219 ,חיבת ציון ,102 ,הראשונים
219 ,חיבת ציון ,9000 ,חיבת ציון
219 ,חיבת ציון ,106 ,נוף השדות
219 ,חיבת ציון ,108 ,נוף חפר
3643 ,חיננית ,110 ,אגוז
3643 ,חיננית ,156 ,אגס
3643 ,חיננית ,136 ,אורן
3643 ,חיננית ,147 ,אירוס
3643 ,חיננית ,130 ,אלון
3643 ,חיננית ,135 ,אקליפטוס
3643 ,חיננית ,101 ,בית ראשון במולדת
3643 ,חיננית ,164 ,ברוש
3643 ,חיננית ,104 ,גולני
3643 ,חיננית ,159 ,גפן
3643 ,חיננית ,112 ,דובדבן
3643 ,חיננית ,148 ,דקל
3643 ,חיננית ,124 ,הגנ"א
3643 ,חיננית ,151 ,הדס
3643 ,חיננית ,102 ,הטייסים
3643 ,חיננית ,128 ,המוסד
3643 ,חיננית ,150 ,ורד
3643 ,חיננית ,154 ,זהבית
3643 ,חיננית ,139 ,זית
3643 ,חיננית ,117 ,ח. גבעתי
3643 ,חיננית ,119 ,ח. הנח"ל
3643 ,חיננית ,122 ,ח. כפיר
3643 ,חיננית ,118 ,ח. צנחנים
3643 ,חיננית ,143 ,חבצלת
3643 ,חיננית ,120 ,חטיבה 7
3643 ,חיננית ,9000 ,חיננית
3643 ,חיננית ,149 ,חרצית
3643 ,חיננית ,6500 ,טל מנשה
3643 ,חיננית ,108 ,יהלו"ם
3643 ,חיננית ,165 ,יסמין
3643 ,חיננית ,134 ,כלנית
3643 ,חיננית ,146 ,לוטם
3643 ,חיננית ,162 ,לילך
3643 ,חיננית ,152 ,לימון
3643 ,חיננית ,126 ,מג"ב
3643 ,חיננית ,111 ,מגלן
3643 ,חיננית ,123 ,מודיעין
3643 ,חיננית ,127 ,מורן
3643 ,חיננית ,125 ,מייתר
3643 ,חיננית ,145 ,מרגנית
3643 ,חיננית ,141 ,מרווה
3643 ,חיננית ,137 ,נירית
3643 ,חיננית ,133 ,נרקיס
3643 ,חיננית ,115 ,ס.חרוב
3643 ,חיננית ,129 ,סביון
3643 ,חיננית ,155 ,סחלב
3643 ,חיננית ,163 ,סייפן
3643 ,חיננית ,105 ,סיירת מטכ"ל
3643 ,חיננית ,109 ,עוקץ
3643 ,חיננית ,144 ,עירית
3643 ,חיננית ,116 ,פ. שריון
3643 ,חיננית ,166 ,פקאן
3643 ,חיננית ,138 ,פרג
3643 ,חיננית ,140 ,צבעוני
3643 ,חיננית ,103 ,צה"ל
3643 ,חיננית ,121 ,קרקל
3643 ,חיננית ,132 ,רותם
3643 ,חיננית ,131 ,רקפת
3643 ,חיננית ,107 ,שייטת 13
3643 ,חיננית ,113 ,שייטת 7
3643 ,חיננית ,106 ,שלדג
3643 ,חיננית ,114 ,שמונה מאתיים
3643 ,חיננית ,158 ,שקד
3643 ,חיננית ,142 ,תאנה
3643 ,חיננית ,157 ,תות
3643 ,חיננית ,160 ,תמר
3643 ,חיננית ,153 ,תפוז
4000 ,חיפה ,385 ,א סלט
4000 ,חיפה ,6531 ,א"ת מפרץ(צפון-מזרח)
4000 ,חיפה ,6530 ,א"ת מפרץ(צפון-מערב)
4000 ,חיפה ,543 ,אבדימי
4000 ,חיפה ,655 ,אביגיל
4000 ,חיפה ,134 ,אביר מרק
4000 ,חיפה ,437 ,אבן אל אתיר
4000 ,חיפה ,805 ,אבן אל מוקפע
4000 ,חיפה ,306 ,אבן גבירול
4000 ,חיפה ,863 ,אבן סינא
4000 ,חיפה ,849 ,אבן עזרא
4000 ,חיפה ,870 ,אבן רושד
4000 ,חיפה ,522 ,אבנר
4000 ,חיפה ,111 ,אברבנאל
4000 ,חיפה ,1246 ,אברהם גינזבורג
4000 ,חיפה ,682 ,אבשלום
4000 ,חיפה ,1219 ,אגוז
4000 ,חיפה ,709 ,אגמון
4000 ,חיפה ,1045 ,אד"ם הכהן
4000 ,חיפה ,271 ,אדיסון
4000 ,חיפה ,778 ,אדמונד פלג
4000 ,חיפה ,6541 ,אדמות יגור
4000 ,חיפה ,967 ,אהבת ישראל
4000 ,חיפה ,911 ,אהבת ציון
4000 ,חיפה ,629 ,אהוד
4000 ,חיפה ,1257 ,אהוד מנור
4000 ,חיפה ,1186 ,אהרון (רוזנפלד)
4000 ,חיפה ,220 ,אהרונוביץ יוסף
4000 ,חיפה ,485 ,אהרונסון
4000 ,חיפה ,756 ,אודם
4000 ,חיפה ,429 ,אוחנה נסים הרב
4000 ,חיפה ,656 ,אוליפנט
4000 ,חיפה ,347 ,אומיה
4000 ,חיפה ,1155 ,אוניברסיטת חיפה
4000 ,חיפה ,994 ,אוסטרובסקי ברוך
4000 ,חיפה ,198 ,אופיר
4000 ,חיפה ,1505 ,אור החיים
4000 ,חיפה ,1066 ,אורבך אליהו
4000 ,חיפה ,278 ,אורט
4000 ,חיפה ,1247 ,אורי וינברג
4000 ,חיפה ,1388 ,אורלנד יעקב
4000 ,חיפה ,763 ,אורן
4000 ,חיפה ,903 ,אושה
4000 ,חיפה ,1049 ,אז"ר
4000 ,חיפה ,1399 ,אזולאי יעקב השופט
4000 ,חיפה ,6710 ,אזור בי"ח פלימן
4000 ,חיפה ,6517 ,אזור מלאכה ומסחר
4000 ,חיפה ,6500 ,אזור שמן
4000 ,חיפה ,6529 ,אזור תעשייה
4000 ,חיפה ,215 ,אח"י אילת
4000 ,חיפה ,846 ,אחד העם
4000 ,חיפה ,502 ,אחוה
4000 ,חיפה ,504 ,אחז
4000 ,חיפה ,407 ,אטוגראי
4000 ,חיפה ,159 ,אידלסון בבה
4000 ,חיפה ,604 ,אידר
4000 ,חיפה ,971 ,איטליה
4000 ,חיפה ,624 ,אילון
4000 ,חיפה ,653 ,אילות
4000 ,חיפה ,698 ,אילנות
4000 ,חיפה ,1023 ,אילת
4000 ,חיפה ,883 ,אימבר נפתלי
4000 ,חיפה ,1041 ,אינטרנשיונל
4000 ,חיפה ,608 ,אינשטיין אלברט
4000 ,חיפה ,1121 ,איצקוביטש
4000 ,חיפה ,960 ,איצקוביץ שמואל
4000 ,חיפה ,719 ,איריס
4000 ,חיפה ,412 ,אירן
4000 ,חיפה ,377 ,איתין יהודה
4000 ,חיפה ,693 ,איתנים
4000 ,חיפה ,501 ,אל אדריסי
4000 ,חיפה ,408 ,אל אספהני
4000 ,חיפה ,340 ,אל בוכתורי
4000 ,חיפה ,804 ,אל גזאלי
4000 ,חיפה ,322 ,אל הילאל
4000 ,חיפה ,397 ,אל חרירי
4000 ,חיפה ,1003 ,אל כנסא
4000 ,חיפה ,696 ,אל מוהדי
4000 ,חיפה ,379 ,אל מורינה
4000 ,חיפה ,803 ,אל מותנבי
4000 ,חיפה ,441 ,אל מערי
4000 ,חיפה ,328 ,אל מרין
4000 ,חיפה ,434 ,אל עריש
4000 ,חיפה ,390 ,אל עתיקה
4000 ,חיפה ,405 ,אל פראבי
4000 ,חיפה ,1169 ,אלבז נתן
4000 ,חיפה ,118 ,אלונים
4000 ,חיפה ,658 ,אלחנן יצחק
4000 ,חיפה ,187 ,אלי כהן
4000 ,חיפה ,959 ,אליהו הנביא
4000 ,חיפה ,549 ,אלישע
4000 ,חיפה ,679 ,אלכסנדר ינאי
4000 ,חיפה ,574 ,אלמוג
4000 ,חיפה ,996 ,אלעזר דוד רא"ל
4000 ,חיפה ,921 ,אלפסי
4000 ,חיפה ,333 ,אלקלעי הרב
4000 ,חיפה ,1397 ,אלתר אליעזר
4000 ,חיפה ,800 ,אלתרמן נתן
4000 ,חיפה ,869 ,אמיר
4000 ,חיפה ,1222 ,אמנון ותמר
4000 ,חיפה ,785 ,אמציה המלך
4000 ,חיפה ,422 ,אנגל יואל
4000 ,חיפה ,480 ,אנגל יונה ד"ר
4000 ,חיפה ,995 ,אנדה עמיר
4000 ,חיפה ,132 ,אנה פרנק
4000 ,חיפה ,784 ,אנטוורפן
4000 ,חיפה ,302 ,אני מאמין
4000 ,חיפה ,414 ,אנילביץ מרדכי
4000 ,חיפה ,497 ,אנצ'ו סירני
4000 ,חיפה ,579 ,אסא המלך
4000 ,חיפה ,454 ,אסף דוד הרב
4000 ,חיפה ,1503 ,אספיס אברהם
4000 ,חיפה ,600 ,אסתר המלכה
4000 ,חיפה ,592 ,אצ"ל
4000 ,חיפה ,648 ,ארגמן
4000 ,חיפה ,1179 ,ארזי יהודה
4000 ,חיפה ,1390 ,אריה (לובה) אליאב
4000 ,חיפה ,823 ,ארלוזורוב
4000 ,חיפה ,1235 ,ארליך דוד פרופ'
4000 ,חיפה ,438 ,ארמן נחום
4000 ,חיפה ,116 ,ארן זלמן
4000 ,חיפה ,948 ,ארנון
4000 ,חיפה ,258 ,ארץ הצבי
4000 ,חיפה ,705 ,אשכולות
4000 ,חיפה ,1024 ,אשר
4000 ,חיפה ,1249 ,אשר עלמני
4000 ,חיפה ,791 ,באבל יצחק
4000 ,חיפה ,295 ,בהרב יקותיאל
4000 ,חיפה ,1167 ,בובר מרטין
4000 ,חיפה ,787 ,בודנהיימר מקס ד"ר
4000 ,חיפה ,300 ,בונה אליעזר
4000 ,חיפה ,126 ,בוסל יוסף
4000 ,חיפה ,673 ,בועז
4000 ,חיפה ,1037 ,בורוכוב
4000 ,חיפה ,1096 ,בורלא יהודה
4000 ,חיפה ,864 ,ביאליק
4000 ,חיפה ,418 ,ביילהארץ פרידריך
4000 ,חיפה ,594 ,בייליס מנחם מנדל
4000 ,חיפה ,643 ,ביכורים
4000 ,חיפה ,311 ,ביל"ו
4000 ,חיפה ,179 ,בילינסון
4000 ,חיפה ,344 ,ביר אל יזק
4000 ,חיפה ,795 ,בירם ד"ר
4000 ,חיפה ,727 ,בית אל
4000 ,חיפה ,239 ,בית אלפא
4000 ,חיפה ,741 ,בית לחם
4000 ,חיפה ,6550 ,בית עולים בת גלים
4000 ,חיפה ,332 ,בית שאן
4000 ,חיפה ,934 ,בית"ר
4000 ,חיפה ,281 ,בל אלכסנדר גרהם
4000 ,חיפה ,519 ,בלהה
4000 ,חיפה ,575 ,בלוך ארנסט
4000 ,חיפה ,958 ,בלום לאון
4000 ,חיפה ,1218 ,בליטנטל משה
4000 ,חיפה ,825 ,בלפור
4000 ,חיפה ,1055 ,במסילה
4000 ,חיפה ,1058 ,בן ארצי
4000 ,חיפה ,936 ,בן בבא
4000 ,חיפה ,966 ,בן הרוש דוד
4000 ,חיפה ,920 ,בן זכאי
4000 ,חיפה ,838 ,בן יהודה
4000 ,חיפה ,266 ,בן יוסף שלמה
4000 ,חיפה ,468 ,בן יעקב צבי
4000 ,חיפה ,313 ,בן עדיה שמואל
4000 ,חיפה ,103 ,בן עמי
4000 ,חיפה ,185 ,בן צבי יצחק
4000 ,חיפה ,980 ,בן צור שמואל
4000 ,חיפה ,1054 ,בן ציון שמחה
4000 ,חיפה ,1089 ,בן שבת שמואל
4000 ,חיפה ,842 ,בן שמן
4000 ,חיפה ,470 ,בני ברית
4000 ,חיפה ,1265 ,בנימין זאב בנדיקט
4000 ,חיפה ,1094 ,בנין זאב
4000 ,חיפה ,252 ,בעל התניא
4000 ,חיפה ,913 ,בעל שם טוב
4000 ,חיפה ,197 ,בעלי מלאכה
4000 ,חיפה ,933 ,בצלאל
4000 ,חיפה ,411 ,בצרה
4000 ,חיפה ,155 ,בר אילן
4000 ,חיפה ,822 ,בר גיורא
4000 ,חיפה ,191 ,בר יהודה ישראל
4000 ,חיפה ,979 ,בר יוחאי
4000 ,חיפה ,935 ,בר כוכבא
4000 ,חיפה ,569 ,בר רב האי דוד
4000 ,חיפה ,1144 ,ברגמן יעקב
4000 ,חיפה ,243 ,ברדיצב אבא
4000 ,חיפה ,265 ,ברודסקי זאב
4000 ,חיפה ,858 ,ברוואלד
4000 ,חיפה ,446 ,ברון הירש
4000 ,חיפה ,888 ,ברוריה
4000 ,חיפה ,943 ,ברזילי
4000 ,חיפה ,1180 ,ברכת משה
4000 ,חיפה ,1051 ,ברל כצנלסון
4000 ,חיפה ,1001 ,ברלב חיים
4000 ,חיפה ,717 ,ברנדייס
4000 ,חיפה ,770 ,ברנר
4000 ,חיפה ,491 ,ברנשטיין פרץ
4000 ,חיפה ,270 ,ברקת
4000 ,חיפה ,1079 ,בשור
4000 ,חיפה ,1410 ,בת חן
4000 ,חיפה ,7001 ,בתי הזיקוק
4000 ,חיפה ,324 ,ג'ובראן סלים
4000 ,חיפה ,639 ,ג'ורג' אליוט
4000 ,חיפה ,388 ,ג'למה
4000 ,חיפה ,919 ,גאולה
4000 ,חיפה ,495 ,גאולי תימן
4000 ,חיפה ,630 ,גבעון
4000 ,חיפה ,605 ,גבעת דאונס
4000 ,חיפה ,6712 ,גבעת זמר
4000 ,חיפה ,384 ,גדוד 22
4000 ,חיפה ,1077 ,גדליהו
4000 ,חיפה ,514 ,גדנ"ע
4000 ,חיפה ,839 ,גדעון
4000 ,חיפה ,782 ,גולדברג לאה
4000 ,חיפה ,1466 ,גולדמן נחום
4000 ,חיפה ,235 ,גולדפאדן אברהם
4000 ,חיפה ,1084 ,גולן שמחה
4000 ,חיפה ,993 ,גור יהודה
4000 ,חיפה ,1036 ,גורדון
4000 ,חיפה ,217 ,גורדוניה
4000 ,חיפה ,1506 ,גורן שלמה הרב
4000 ,חיפה ,884 ,גוש חלב
4000 ,חיפה ,1004 ,גוש עציון
4000 ,חיפה ,250 ,גזאל
4000 ,חיפה ,496 ,גיבורי גיטו ורשה
4000 ,חיפה ,351 ,גירני
4000 ,חיפה ,697 ,גלאל אל דין
4000 ,חיפה ,1033 ,גלבוע
4000 ,חיפה ,675 ,גלגל
4000 ,חיפה ,1387 ,גלוברמן יהושע
4000 ,חיפה ,461 ,גן אלי כהן
4000 ,חיפה ,1016 ,גן הבהאים
4000 ,חיפה ,1056 ,גנסין
4000 ,חיפה ,1504 ,גץ יצחק
4000 ,חיפה ,762 ,גרינבוים יצחק
4000 ,חיפה ,469 ,גרינברג אורי צבי
4000 ,חיפה ,474 ,גרינשפון הרצל
4000 ,חיפה ,1139 ,גרנד קניון
4000 ,חיפה ,4059 ,גשר פז
4000 ,חיפה ,613 ,גת
4000 ,חיפה ,500 ,דאהר אל עמר
4000 ,חיפה ,889 ,דבורה
4000 ,חיפה ,1092 ,דובנוב
4000 ,חיפה ,1140 ,דובר שלום
4000 ,חיפה ,373 ,דוברין נחום
4000 ,חיפה ,507 ,דוגית
4000 ,חיפה ,559 ,דוד המלך
4000 ,חיפה ,1413 ,דוכיפת
4000 ,חיפה ,1002 ,דולצ'ין אריה
4000 ,חיפה ,758 ,דונה גרציה
4000 ,חיפה ,1160 ,דוסטרובסקי אליעזר
4000 ,חיפה ,1039 ,דורות
4000 ,חיפה ,1099 ,דורי יעקב רא"ל
4000 ,חיפה ,622 ,דישראלי
4000 ,חיפה ,457 ,דנה יוסף הרב
4000 ,חיפה ,862 ,דניאל
4000 ,חיפה ,768 ,דניה
4000 ,חיפה ,168 ,דנינו אברהם
4000 ,חיפה ,723 ,דפנה
4000 ,חיפה ,510 ,דרור
4000 ,חיפה ,732 ,דרייפוס
4000 ,חיפה ,512 ,דרך אלנבי
4000 ,חיפה ,1242 ,דרך אריאל שרון
4000 ,חיפה ,288 ,דרך דיין משה
4000 ,חיפה ,690 ,דרך הים
4000 ,חיפה ,224 ,דרך העמקים
4000 ,חיפה ,348 ,דרך העצמאות
4000 ,חיפה ,1035 ,דרך חנקין
4000 ,חיפה ,1012 ,דרך יד לבנים
4000 ,חיפה ,367 ,דרך ידין יגאל
4000 ,חיפה ,354 ,דרך יפו
4000 ,חיפה ,603 ,דרך כרמל משה
4000 ,חיפה ,1250 ,דרך נחל הגיבורים
4000 ,חיפה ,801 ,דרך סטלה מריס
4000 ,חיפה ,190 ,דרך צייזל ירוחם
4000 ,חיפה ,554 ,דרך צרפת
4000 ,חיפה ,473 ,דרך רודיק שמעון
4000 ,חיפה ,662 ,דרך רופין
4000 ,חיפה ,152 ,האבות
4000 ,חיפה ,751 ,האג
4000 ,חיפה ,963 ,האומן
4000 ,חיפה ,364 ,האיטלקים
4000 ,חיפה ,985 ,האלה
4000 ,חיפה ,595 ,האלון
4000 ,חיפה ,151 ,האמהות
4000 ,חיפה ,138 ,האמונה
4000 ,חיפה ,147 ,האמוראים
4000 ,חיפה ,917 ,האסיף
4000 ,חיפה ,242 ,האצטדיון
4000 ,חיפה ,1101 ,האר"י
4000 ,חיפה ,127 ,הארזים
4000 ,חיפה ,984 ,האשחר
4000 ,חיפה ,123 ,האשל
4000 ,חיפה ,274 ,האשלג
4000 ,חיפה ,223 ,הבונים החפשיים
4000 ,חיפה ,1251 ,הבישוף חג'אר
4000 ,חיפה ,202 ,הבניה
4000 ,חיפה ,1021 ,הבנים
4000 ,חיפה ,355 ,הבנקים
4000 ,חיפה ,555 ,הבסטיליה
4000 ,חיפה ,1029 ,הבריכה
4000 ,חיפה ,218 ,הבשן
4000 ,חיפה ,149 ,הגאונים
4000 ,חיפה ,303 ,הגבורים
4000 ,חיפה ,292 ,הגביש
4000 ,חיפה ,160 ,הגדוד העברי
4000 ,חיפה ,476 ,הגומא
4000 ,חיפה ,1020 ,הגליל
4000 ,חיפה ,415 ,הגנים
4000 ,חיפה ,413 ,הגפן
4000 ,חיפה ,246 ,הדגן
4000 ,חיפה ,783 ,הדולב
4000 ,חיפה ,285 ,הדייגים
4000 ,חיפה ,1206 ,הדסה
4000 ,חיפה ,706 ,הדסים
4000 ,חיפה ,267 ,הדף היומי
4000 ,חיפה ,873 ,הדר
4000 ,חיפה ,6618 ,הדר הכרמל
4000 ,חיפה ,275 ,הדשנים
4000 ,חיפה ,552 ,ההגה
4000 ,חיפה ,393 ,הואדי
4000 ,חיפה ,1207 ,הוברמן ברוניסלב
4000 ,חיפה ,2675 ,הוד
4000 ,חיפה ,766 ,הויסמנס קמיל
4000 ,חיפה ,755 ,הולנד
4000 ,חיפה ,749 ,הולצברג שמחה
4000 ,חיפה ,685 ,הורדים
4000 ,חיפה ,233 ,הותיקים
4000 ,חיפה ,1216 ,הזז חיים
4000 ,חיפה ,523 ,הזית
4000 ,חיפה ,618 ,הזמיר
4000 ,חיפה ,131 ,החבצלת
4000 ,חיפה ,1189 ,החוף הדרומי
4000 ,חיפה ,511 ,החותרים
4000 ,חיפה ,528 ,החי"ל
4000 ,חיפה ,1502 ,החיד"א
4000 ,חיפה ,296 ,החיטה
4000 ,חיפה ,987 ,החיננית
4000 ,חיפה ,859 ,החלוץ
4000 ,חיפה ,212 ,החרושת
4000 ,חיפה ,294 ,החרמש
4000 ,חיפה ,203 ,החרש
4000 ,חיפה ,815 ,החשמונאים
4000 ,חיפה ,1015 ,החשמל
4000 ,חיפה ,432 ,הטחנות הגדולות
4000 ,חיפה ,659 ,הטנא
4000 ,חיפה ,743 ,היובל
4000 ,חיפה ,286 ,היוצק
4000 ,חיפה ,264 ,הים התיכון
4000 ,חיפה ,456 ,הינדס מתתיהו
4000 ,חיפה ,204 ,היצירה
4000 ,חיפה ,1008 ,הירדן
4000 ,חיפה ,420 ,הירוק
4000 ,חיפה ,305 ,הירמוך
4000 ,חיפה ,667 ,הירקון
4000 ,חיפה ,530 ,הכ"ג
4000 ,חיפה ,346 ,הכנסיות
4000 ,חיפה ,1065 ,הכשרת היישוב
4000 ,חיפה ,983 ,הליבנה
4000 ,חיפה ,1133 ,הלילך
4000 ,חיפה ,810 ,הלל
4000 ,חיפה ,1227 ,הללויה
4000 ,חיפה ,565 ,הלפרן ירמיהו
4000 ,חיפה ,1182 ,המאירי אביגדור
4000 ,חיפה ,255 ,המולדת
4000 ,חיפה ,277 ,המוסכים
4000 ,חיפה ,1086 ,המחנך נפחא
4000 ,חיפה ,646 ,המים
4000 ,חיפה ,922 ,המימוני
4000 ,חיפה ,428 ,המכס
4000 ,חיפה ,448 ,המלאכה
4000 ,חיפה ,205 ,המסגר
4000 ,חיפה ,144 ,המסורת
4000 ,חיפה ,2511 ,המסילה
4000 ,חיפה ,345 ,המעלות
4000 ,חיפה ,508 ,המעפילים
4000 ,חיפה ,686 ,המערות
4000 ,חיפה ,158 ,המפוח
4000 ,חיפה ,999 ,המפרי יוברט
4000 ,חיפה ,376 ,המרוניטים
4000 ,חיפה ,293 ,המשביר
4000 ,חיפה ,425 ,המשטרה
4000 ,חיפה ,845 ,הנביאים
4000 ,חיפה ,950 ,הנגב
4000 ,חיפה ,382 ,הנגיד
4000 ,חיפה ,161 ,הנוטר
4000 ,חיפה ,479 ,הנופר
4000 ,חיפה ,607 ,הנטקה
4000 ,חיפה ,353 ,הנמל
4000 ,חיפה ,268 ,הנפח
4000 ,חיפה ,154 ,הנצי"ב
4000 ,חיפה ,704 ,הנרקיסים
4000 ,חיפה ,715 ,הנשר
4000 ,חיפה ,818 ,הס
4000 ,חיפה ,817 ,הסביונים
4000 ,חיפה ,289 ,הסדנה
4000 ,חיפה ,276 ,הסולל
4000 ,חיפה ,1061 ,הסחלב
4000 ,חיפה ,701 ,הסנה
4000 ,חיפה ,200 ,הסנונית
4000 ,חיפה ,642 ,הספורט
4000 ,חיפה ,427 ,הסראיה
4000 ,חיפה ,206 ,העבודה
4000 ,חיפה ,553 ,העוגן
4000 ,חיפה ,6603 ,העיר התחתית
4000 ,חיפה ,526 ,העליה השניה
4000 ,חיפה ,261 ,העמלים
4000 ,חיפה ,824 ,העמק
4000 ,חיפה ,1178 ,הערמונים
4000 ,חיפה ,273 ,העתיד
4000 ,חיפה ,150 ,הפוסקים
4000 ,חיפה ,905 ,הפועל
4000 ,חיפה ,157 ,הפלוגות
4000 ,חיפה ,287 ,הפלס
4000 ,חיפה ,793 ,הפרחים
4000 ,חיפה ,513 ,הפרטיזנים
4000 ,חיפה ,1214 ,הפרסה
4000 ,חיפה ,409 ,הפרסים
4000 ,חיפה ,1414 ,הפשוש
4000 ,חיפה ,1142 ,הצדף
4000 ,חיפה ,1168 ,הצוללת דקר
4000 ,חיפה ,688 ,הצופים
4000 ,חיפה ,812 ,הצלבנים
4000 ,חיפה ,633 ,הצלפים
4000 ,חיפה ,1072 ,הצפירה
4000 ,חיפה ,169 ,הקבוצים
4000 ,חיפה ,105 ,הקונגרס
4000 ,חיפה ,196 ,הקורנס
4000 ,חיפה ,323 ,הקטר
4000 ,חיפה ,230 ,הקיטור
4000 ,חיפה ,308 ,הקישון
4000 ,חיפה ,527 ,הקלעים
4000 ,חיפה ,797 ,הקשת
4000 ,חיפה ,772 ,הראל
4000 ,חיפה ,163 ,הראשונים
4000 ,חיפה ,988 ,הרב אנקואה רפאל
4000 ,חיפה ,886 ,הרב הרצוג
4000 ,חיפה ,6715 ,הרב שאר ישוב כהן
4000 ,חיפה ,699 ,הרדוף
4000 ,חיפה ,316 ,הרון אל רשיד
4000 ,חיפה ,610 ,הרופא
4000 ,חיפה ,986 ,הרותם
4000 ,חיפה ,251 ,הרי"ף
4000 ,חיפה ,443 ,הרמן צבי
4000 ,חיפה ,279 ,הרץ היינריך
4000 ,חיפה ,856 ,הרצל
4000 ,חיפה ,850 ,הרצליה
4000 ,חיפה ,165 ,הרצפלד אברהם
4000 ,חיפה ,703 ,הרקפות
4000 ,חיפה ,304 ,השומר
4000 ,חיפה ,572 ,השופט שלום קסאן
4000 ,חיפה ,544 ,השחף
4000 ,חיפה ,830 ,השחר
4000 ,חיפה ,183 ,השיירה
4000 ,חיפה ,207 ,השיש
4000 ,חיפה ,1075 ,השל יהושע פרופ'
4000 ,חיפה ,931 ,השלוח
4000 ,חיפה ,837 ,השלום
4000 ,חיפה ,201 ,השקד
4000 ,חיפה ,538 ,השרון
4000 ,חיפה ,227 ,התאנה
4000 ,חיפה ,957 ,התבור
4000 ,חיפה ,683 ,התור
4000 ,חיפה ,551 ,התורן
4000 ,חיפה ,1025 ,התיכון
4000 ,חיפה ,754 ,התמר
4000 ,חיפה ,153 ,התנאים
4000 ,חיפה ,503 ,התקוה
4000 ,חיפה ,232 ,התקומה
4000 ,חיפה ,718 ,התשבי
4000 ,חיפה ,1000 ,התשעה
4000 ,חיפה ,383 ,התשעים ושלוש
4000 ,חיפה ,6525 ,ואדי סליב
4000 ,חיפה ,6526 ,ואדי רושמיה
4000 ,חיפה ,636 ,וודג'ווד
4000 ,חיפה ,283 ,ווט ג'יימס
4000 ,חיפה ,1105 ,ווטסון ליונל
4000 ,חיפה ,644 ,וולפסון
4000 ,חיפה ,961 ,וייז סטפן
4000 ,חיפה ,975 ,ויינשל אברהם ד"ר
4000 ,חיפה ,259 ,וילבוש נחום
4000 ,חיפה ,1392 ,וילנאי זאב
4000 ,חיפה ,831 ,וילנסקי
4000 ,חיפה ,1106 ,וינר יוסף
4000 ,חיפה ,1202 ,ויצ"ו
4000 ,חיפה ,734 ,ויקטור הוגו
4000 ,חיפה ,611 ,ויתקין יוסף
4000 ,חיפה ,1450 ,ולנברג ראול
4000 ,חיפה ,657 ,ורדיה
4000 ,חיפה ,556 ,ז'ורס
4000 ,חיפה ,738 ,זאב (זבוטינסקי)
4000 ,חיפה ,1124 ,זאב הים
4000 ,חיפה ,1134 ,זאב פרוש
4000 ,חיפה ,941 ,זבולון
4000 ,חיפה ,2674 ,זוהר
4000 ,חיפה ,733 ,זולא אמיל
4000 ,חיפה ,1141 ,זורע מאיר (זרו)
4000 ,חיפה ,707 ,זיוונית
4000 ,חיפה ,164 ,זייד אלכסנדר
4000 ,חיפה ,515 ,זילפה
4000 ,חיפה ,1252 ,זימנא נחום ד"ר
4000 ,חיפה ,1125 ,זינגר מנדל
4000 ,חיפה ,426 ,זיסו א ל
4000 ,חיפה ,1009 ,זמכשרי
4000 ,חיפה ,775 ,זמנהוף
4000 ,חיפה ,663 ,זרובבל
4000 ,חיפה ,1507 ,חב"ד
4000 ,חיפה ,969 ,חבובה אליהו
4000 ,חיפה ,1040 ,חביבה רייך
4000 ,חיפה ,976 ,חבס הכוהן ברכה
4000 ,חיפה ,1183 ,חבקוק
4000 ,חיפה ,840 ,חברון
4000 ,חיפה ,398 ,חדאד
4000 ,חיפה ,945 ,חדרה
4000 ,חיפה ,1412 ,חוחית
4000 ,חיפה ,1060 ,חולדה
4000 ,חיפה ,177 ,חומה ומגדל
4000 ,חיפה ,1010 ,חוסן
4000 ,חיפה ,1188 ,חוף בת גלים
4000 ,חיפה ,997 ,חוף דדו
4000 ,חיפה ,591 ,חוף הכרמל
4000 ,חיפה ,779 ,חוף השנהב
4000 ,חיפה ,1187 ,חוף זמיר
4000 ,חיפה ,400 ,חוף קרית חיים
4000 ,חיפה ,1210 ,חוצות המפרץ
4000 ,חיפה ,601 ,חורב
4000 ,חיפה ,721 ,חורשה
4000 ,חיפה ,1264 ,חושן
4000 ,חיפה ,1005 ,חזן יעקב
4000 ,חיפה ,582 ,חזקיהו המלך
4000 ,חיפה ,309 ,חטיבת גולני
4000 ,חיפה ,1093 ,חטיבת כרמלי
4000 ,חיפה ,799 ,חטיבת עודד
4000 ,חיפה ,990 ,חיות פרץ
4000 ,חיפה ,895 ,חיים
4000 ,חיפה ,1262 ,חיים קסטן
4000 ,חיפה ,537 ,חיל הים
4000 ,חיפה ,9000 ,חיפה
4000 ,חיפה ,209 ,חירם
4000 ,חיפה ,596 ,חכים אליהו
4000 ,חיפה ,208 ,חכמי ישראל
4000 ,חיפה ,210 ,חלוצי התעשיה
4000 ,חיפה ,1062 ,חליסה רח א
4000 ,חיפה ,1224 ,חלמיש
4000 ,חיפה ,211 ,חלץ
4000 ,חיפה ,341 ,חמאם אל פשה
4000 ,חיפה ,2678 ,חמדה
4000 ,חיפה ,680 ,חנה
4000 ,חיפה ,1022 ,חניתה
4000 ,חיפה ,1396 ,חסידי אומות העולם
4000 ,חיפה ,865 ,חסן שוקרי
4000 ,חיפה ,231 ,חפצי בה
4000 ,חיפה ,1389 ,חצוצרה בוואדי
4000 ,חיפה ,6580 ,חצרות כרמל
4000 ,חיפה ,107 ,חרב לאת
4000 ,חיפה ,711 ,חרוב
4000 ,חיפה ,932 ,חרמון
4000 ,חיפה ,380 ,חשבון
4000 ,חיפה ,464 ,חת נחום
4000 ,חיפה ,319 ,טאריק
4000 ,חיפה ,1150 ,טבנקין יצחק
4000 ,חיפה ,836 ,טבריה
4000 ,חיפה ,973 ,טברסקי ג'ניה
4000 ,חיפה ,481 ,טהון יעקב
4000 ,חיפה ,583 ,טובי תופיק
4000 ,חיפה ,213 ,טובים
4000 ,חיפה ,1201 ,טולצ'ינסקי ברוך
4000 ,חיפה ,494 ,טוסקניני ארתור
4000 ,חיפה ,1234 ,טופז
4000 ,חיפה ,1512 ,טיילת אריה גוראל
4000 ,חיפה ,1513 ,טיילת חולדה גורביץ
4000 ,חיפה ,245 ,טרומן
4000 ,חיפה ,726 ,טשרניחובסקי
4000 ,חיפה ,1200 ,י"א הספורטאים
4000 ,חיפה ,188 ,יבנאלי שמואל
4000 ,חיפה ,395 ,יבנה
4000 ,חיפה ,1391 ,יגאל אלון
4000 ,חיפה ,1067 ,יגור
4000 ,חיפה ,249 ,יגיע כפיים
4000 ,חיפה ,577 ,יהואש המלך
4000 ,חיפה ,307 ,יהודה הלוי
4000 ,חיפה ,1148 ,יהודה עמיחי
4000 ,חיפה ,1132 ,יהודית
4000 ,חיפה ,1260 ,יהורם גאון
4000 ,חיפה ,899 ,יהושע
4000 ,חיפה ,563 ,יהושפט המלך
4000 ,חיפה ,548 ,יואב
4000 ,חיפה ,857 ,יואל
4000 ,חיפה ,315 ,יודפת
4000 ,חיפה ,1082 ,יוחאי בן-נון
4000 ,חיפה ,366 ,יוחנן הקדוש
4000 ,חיפה ,661 ,יוכבד
4000 ,חיפה ,194 ,יוליוס סימון
4000 ,חיפה ,853 ,יונה
4000 ,חיפה ,539 ,יונתן
4000 ,חיפה ,1258 ,יוסי בנאי
4000 ,חיפה ,142 ,יוסיפון
4000 ,חיפה ,955 ,יוסף (הגדם)
4000 ,חיפה ,1153 ,יורדי הים
4000 ,חיפה ,628 ,יותם
4000 ,חיפה ,1042 ,יחזקאל
4000 ,חיפה ,880 ,יחיאל
4000 ,חיפה ,263 ,יחיעם
4000 ,חיפה ,944 ,יל"ג
4000 ,חיפה ,947 ,יל"ג דרומית
4000 ,חיפה ,946 ,יל"ג צפונית
4000 ,חיפה ,1151 ,ילדי טהרן
4000 ,חיפה ,1154 ,ינקולוביץ חיים
4000 ,חיפה ,517 ,יעל
4000 ,חיפה ,1198 ,יעקב שחר
4000 ,חיפה ,606 ,יערות
4000 ,חיפה ,1394 ,יערי מאיר
4000 ,חיפה ,904 ,יפה הלל
4000 ,חיפה ,757 ,יפה ושמואל שכטר
4000 ,חיפה ,1256 ,יפה ירקוני
4000 ,חיפה ,813 ,יפה נוף
4000 ,חיפה ,2679 ,יפעה
4000 ,חיפה ,664 ,יפתח
4000 ,חיפה ,748 ,יציאת אירופה
4000 ,חיפה ,176 ,יציב יצחק
4000 ,חיפה ,392 ,יקוט
4000 ,חיפה ,1465 ,יקינתון
4000 ,חיפה ,1261 ,ירדנה ארזי
4000 ,חיפה ,896 ,ירושלים
4000 ,חיפה ,1087 ,ירושלמי אליעזר ד"ר
4000 ,חיפה ,637 ,ישורון
4000 ,חיפה ,740 ,ישעיהו
4000 ,חיפה ,114 ,ישראלי בן ציון
4000 ,חיפה ,789 ,כ"ץ יאיר
4000 ,חיפה ,113 ,כ"ץ יעקב הרב
4000 ,חיפה ,189 ,כאהן יעקב
4000 ,חיפה ,692 ,כבירים
4000 ,חיפה ,101 ,כברי
4000 ,חיפה ,484 ,כגן רחל
4000 ,חיפה ,981 ,כהן ברוך
4000 ,חיפה ,386 ,כורי
4000 ,חיפה ,819 ,כורש
4000 ,חיפה ,350 ,כטיב
4000 ,חיפה ,370 ,כיאט
4000 ,חיפה ,6052 ,כיכר 6052
4000 ,חיפה ,6075 ,כיכר 6075
4000 ,חיפה ,6085 ,כיכר 6085
4000 ,חיפה ,6098 ,כיכר 6098
4000 ,חיפה ,6045 ,כיכר אבי רן
4000 ,חיפה ,6050 ,כיכר אדם פיש
4000 ,חיפה ,6042 ,כיכר אורי זוהר
4000 ,חיפה ,6066 ,כיכר אריה בן צבי
4000 ,חיפה ,6073 ,כיכר בוטרוס אבו מנה
4000 ,חיפה ,6077 ,כיכר בן ציון רייפר
4000 ,חיפה ,6116 ,כיכר ברוך רפפורט
4000 ,חיפה ,6148 ,כיכר גיורא פישר
4000 ,חיפה ,6053 ,כיכר ד"ר אלי נחמיאס
4000 ,חיפה ,6063 ,כיכר ד"ר אריך לב
4000 ,חיפה ,6091 ,כיכר דנמרק
4000 ,חיפה ,6092 ,כיכר הבורג'
4000 ,חיפה ,6041 ,כיכר הפיליפינים
4000 ,חיפה ,6117 ,כיכר ואצלב האוול
4000 ,חיפה ,6093 ,כיכר זאהי כרכבי
4000 ,חיפה ,6112 ,כיכר זינגר יוסף
4000 ,חיפה ,6143 ,כיכר חביב
4000 ,חיפה ,6072 ,כיכר חדוה שפיגל
4000 ,חיפה ,6055 ,כיכר חתני פרס נובל
4000 ,חיפה ,6065 ,כיכר יוסף קרני
4000 ,חיפה ,6146 ,כיכר יצחק חופי
4000 ,חיפה ,6058 ,כיכר להקת הגבעטרון
4000 ,חיפה ,6059 ,כיכר להקת הדודאים
4000 ,חיפה ,6062 ,כיכר להקת חלב ודבש
4000 ,חיפה ,6061 ,כיכר להקת כוורת
4000 ,חיפה ,6060 ,כיכר להקת משינה
4000 ,חיפה ,6069 ,כיכר לוי שבואלי
4000 ,חיפה ,6051 ,כיכר ליאו גולדהמר
4000 ,חיפה ,6054 ,כיכר מרדכי מנו
4000 ,חיפה ,6064 ,כיכר מרדכי שטיינברג
4000 ,חיפה ,6076 ,כיכר משה ורטמן
4000 ,חיפה ,6132 ,כיכר נפתלי אילתי
4000 ,חיפה ,6151 ,כיכר עמנואל זמיר
4000 ,חיפה ,6150 ,כיכר פאפא
4000 ,חיפה ,6108 ,כיכר פרימו לוי
4000 ,חיפה ,6070 ,כיכר קיצס וכהן
4000 ,חיפה ,6115 ,כיכר רובי
4000 ,חיפה ,6044 ,כיכר רוטרי חיפה
4000 ,חיפה ,6074 ,כיכר רומניה
4000 ,חיפה ,6056 ,כיכר רות מושכל
4000 ,חיפה ,6067 ,כיכר שלמה ג'מוס
4000 ,חיפה ,6057 ,כיכר שלמה וול
4000 ,חיפה ,6034 ,כיכר שמואל טנקוס
4000 ,חיפה ,6033 ,ככר אגמי אורי
4000 ,חיפה ,6046 ,ככר אונסק"ו
4000 ,חיפה ,6020 ,ככר אליזבט
4000 ,חיפה ,6016 ,ככר אמיל גרינצוויג
4000 ,חיפה ,6099 ,ככר אמיל חביבי
4000 ,חיפה ,6001 ,ככר אמפא
4000 ,חיפה ,6036 ,ככר אנואר סאדאת
4000 ,חיפה ,6087 ,ככר בולגריה
4000 ,חיפה ,6021 ,ככר בני אחוזה
4000 ,חיפה ,6088 ,ככר ברנר מילה
4000 ,חיפה ,6096 ,ככר ג'רלד פרדמן
4000 ,חיפה ,6002 ,ככר דוד
4000 ,חיפה ,6022 ,ככר דוד הכהן
4000 ,חיפה ,6080 ,ככר האוניברסיטה
4000 ,חיפה ,6003 ,ככר הגבורה
4000 ,חיפה ,6004 ,ככר ההגנה
4000 ,חיפה ,6005 ,ככר היינה
4000 ,חיפה ,6081 ,ככר הכט ראובן
4000 ,חיפה ,6024 ,ככר הלייט
4000 ,חיפה ,6083 ,ככר המר זבולון
4000 ,חיפה ,6023 ,ככר הנדריק סרברוב
4000 ,חיפה ,6040 ,ככר הפיליפינים
4000 ,חיפה ,6071 ,ככר הרב עובדיה יוסף
4000 ,חיפה ,6006 ,ככר הרברט סמואל
4000 ,חיפה ,6007 ,ככר הרקפות
4000 ,חיפה ,6090 ,ככר וידרא נפתלי
4000 ,חיפה ,6008 ,ככר זיו
4000 ,חיפה ,6089 ,ככר זכאי אברהם
4000 ,חיפה ,6000 ,ככר זלטין
4000 ,חיפה ,6025 ,ככר חיל הרפואה
4000 ,חיפה ,6103 ,ככר חיפה - בוסטון
4000 ,חיפה ,6026 ,ככר יד לבנים
4000 ,חיפה ,6035 ,ככר ישעיהו לייבוביץ
4000 ,חיפה ,6068 ,ככר כהן דוד
4000 ,חיפה ,6027 ,ככר כיאט
4000 ,חיפה ,6009 ,ככר מאירהוף
4000 ,חיפה ,6039 ,ככר מוסינזון יגאל
4000 ,חיפה ,6019 ,ככר מינה
4000 ,חיפה ,6028 ,ככר מסריק
4000 ,חיפה ,6017 ,ככר מעפילי קפריסין
4000 ,חיפה ,6010 ,ככר סולל בונה
4000 ,חיפה ,6011 ,ככר ספקטור
4000 ,חיפה ,6015 ,ככר עולי הגרדום
4000 ,חיפה ,6047 ,ככר עציוני משה
4000 ,חיפה ,6037 ,ככר פדרמן יקותיאל
4000 ,חיפה ,6149 ,ככר פול ומאי אריאלי
4000 ,חיפה ,6030 ,ככר פייצל
4000 ,חיפה ,6012 ,ככר פלומר
4000 ,חיפה ,6013 ,ככר פריס
4000 ,חיפה ,6029 ,ככר צייזל
4000 ,חיפה ,6031 ,ככר צים
4000 ,חיפה ,6014 ,ככר קפלנסקי
4000 ,חיפה ,6038 ,ככר שבבו שושנה
4000 ,חיפה ,6043 ,ככר שמידט אנטון
4000 ,חיפה ,6032 ,ככר שפירא יצחק
4000 ,חיפה ,736 ,כל ישראל חברים
4000 ,חיפה ,1172 ,כליל החורש
4000 ,חיפה ,691 ,כלניות
4000 ,חיפה ,1158 ,כלפון הרב
4000 ,חיפה ,906 ,כנרת
4000 ,חיפה ,540 ,כספי יעקב
4000 ,חיפה ,1103 ,כספרי ד"ר
4000 ,חיפה ,6615 ,כפר כבביר
4000 ,חיפה ,1117 ,כצמן משה
4000 ,חיפה ,471 ,כרוך אריה
4000 ,חיפה ,788 ,כרמי
4000 ,חיפה ,731 ,כרמיה
4000 ,חיפה ,6613 ,כרמל מערבי
4000 ,חיפה ,6630 ,כרמל מרכזי
4000 ,חיפה ,6612 ,כרמל צפוני
4000 ,חיפה ,677 ,לאה
4000 ,חיפה ,720 ,לאונרדו דה וינצי
4000 ,חיפה ,489 ,לב המפרץ
4000 ,חיפה ,465 ,לבון פנחס
4000 ,חיפה ,894 ,לבונה
4000 ,חיפה ,402 ,לבונטין
4000 ,חיפה ,186 ,לביא שלמה
4000 ,חיפה ,1501 ,לבנון ישראל
4000 ,חיפה ,938 ,לברי מרק
4000 ,חיפה ,878 ,לוד
4000 ,חיפה ,417 ,לוחמי הגטאות
4000 ,חיפה ,1116 ,לוחמי חרות ישראל
4000 ,חיפה ,649 ,לוטוס
4000 ,חיפה ,128 ,לוי יוסף
4000 ,חיפה ,253 ,לוי יצחק
4000 ,חיפה ,272 ,לוין אריה הרב
4000 ,חיפה ,1401 ,לוין גוטל
4000 ,חיפה ,882 ,לונץ
4000 ,חיפה ,535 ,לוץ שרל
4000 ,חיפה ,927 ,לוצטו
4000 ,חיפה ,1088 ,לזרוס אמה
4000 ,חיפה ,774 ,ליבריה
4000 ,חיפה ,238 ,ליויק
4000 ,חיפה ,626 ,ליטאניס
4000 ,חיפה ,182 ,לייב יפה
4000 ,חיפה ,998 ,לינדנשטראוס אהרון
4000 ,חיפה ,593 ,לינקולן אברהם
4000 ,חיפה ,378 ,ליפשיץ יוסף
4000 ,חיפה ,612 ,לכיש
4000 ,חיפה ,1163 ,לנ"י
4000 ,חיפה ,1120 ,לנדאו מיכאל ד"ר
4000 ,חיפה ,524 ,לסין אברהם
4000 ,חיפה ,467 ,לסקוב חיים רא"ל
4000 ,חיפה ,447 ,לשם
4000 ,חיפה ,359 ,מאי
4000 ,חיפה ,362 ,מאיר (רוטברג)
4000 ,חיפה ,483 ,מאיר גולדה
4000 ,חיפה ,1115 ,מאירוביץ צבי
4000 ,חיפה ,143 ,מאלי יעקב משה
4000 ,חיפה ,615 ,מאפו
4000 ,חיפה ,794 ,מאציני
4000 ,חיפה ,1229 ,מבוא הכרמל
4000 ,חיפה ,1231 ,מבוא חיפה
4000 ,חיפה ,991 ,מבצע יונתן
4000 ,חיפה ,181 ,מבצע קדש
4000 ,חיפה ,571 ,מג'דלאני ג'אן
4000 ,חיפה ,1471 ,מגדל אור
4000 ,חיפה ,576 ,מגורשי ספרד
4000 ,חיפה ,640 ,מגידו
4000 ,חיפה ,1043 ,מד אברהם
4000 ,חיפה ,342 ,מד אירביד
4000 ,חיפה ,573 ,מד אלגביש יוסף
4000 ,חיפה ,1126 ,מד בוניאק ראובן
4000 ,חיפה ,809 ,מד בית שערים
4000 ,חיפה ,952 ,מד בני אור
4000 ,חיפה ,1109 ,מד בת שבע
4000 ,חיפה ,814 ,מד גדרה
4000 ,חיפה ,972 ,מד גלעד
4000 ,חיפה ,829 ,מד גמלא
4000 ,חיפה ,926 ,מד דוניה טוביה
4000 ,חיפה ,404 ,מד דור
4000 ,חיפה ,1177 ,מד החרצית
4000 ,חיפה ,940 ,מד הלפרט נפתלי
4000 ,חיפה ,1017 ,מד המוכתר צבי
4000 ,חיפה ,867 ,מד הנביאים
4000 ,חיפה ,908 ,מד הקנאים
4000 ,חיפה ,968 ,מד הרצל
4000 ,חיפה ,939 ,מד השלוח צפון
4000 ,חיפה ,1111 ,מד זכריה
4000 ,חיפה ,780 ,מד זרובבל
4000 ,חיפה ,1110 ,מד חגית
4000 ,חיפה ,330 ,מד חגלה
4000 ,חיפה ,1112 ,מד חצב
4000 ,חיפה ,925 ,מד חרות
4000 ,חיפה ,833 ,מד מודיעין
4000 ,חיפה ,929 ,מד מחנות
4000 ,חיפה ,321 ,מד מילר נחום
4000 ,חיפה ,536 ,מד מעלה הדקר
4000 ,חיפה ,1108 ,מד מרים
4000 ,חיפה ,430 ,מד נוזהה ב
4000 ,חיפה ,956 ,מד נפתלי
4000 ,חיפה ,339 ,מד עגלון
4000 ,חיפה ,923 ,מד עמנואל
4000 ,חיפה ,1127 ,מד פנחס כהן
4000 ,חיפה ,832 ,מד פקיעין
4000 ,חיפה ,1146 ,מד צמח צדיק
4000 ,חיפה ,406 ,מד רשפון
4000 ,חיפה ,317 ,מד שוקרי
4000 ,חיפה ,821 ,מד שפינוזה
4000 ,חיפה ,318 ,מד תימן
4000 ,חיפה ,148 ,מוהליבר
4000 ,חיפה ,820 ,מונטיפיורי
4000 ,חיפה ,742 ,מור
4000 ,חיפה ,660 ,מורג
4000 ,חיפה ,458 ,מורד היסמין
4000 ,חיפה ,1063 ,מורדות נוה שאנן
4000 ,חיפה ,1171 ,מורן
4000 ,חיפה ,226 ,מושלי יעקב
4000 ,חיפה ,1509 ,מושקא חיה
4000 ,חיפה ,4061 ,מחלף גדוד 21
4000 ,חיפה ,4067 ,מחלף ההסתדרות
4000 ,חיפה ,4068 ,מחלף הקישון
4000 ,חיפה ,708 ,מחניים
4000 ,חיפה ,647 ,מייבלום דורון
4000 ,חיפה ,619 ,מיכ"ל המשורר
4000 ,חיפה ,930 ,מיכאל
4000 ,חיפה ,145 ,מיכואלס שלמה
4000 ,חיפה ,331 ,מילכה
4000 ,חיפה ,558 ,מילסון יוסף צבי
4000 ,חיפה ,1073 ,מימון הרב
4000 ,חיפה ,6540 ,מכון הטיהור
4000 ,חיפה ,753 ,מכון זואולוגי
4000 ,חיפה ,369 ,מכליס משה אהרון
4000 ,חיפה ,1046 ,מל"ל
4000 ,חיפה ,892 ,מלון גלרי
4000 ,חיפה ,928 ,מלון מגדל חיפה
4000 ,חיפה ,1184 ,מלון מרידיאן
4000 ,חיפה ,902 ,מלצ'ט
4000 ,חיפה ,241 ,מנגר איציק
4000 ,חיפה ,1050 ,מנדלי מוכר ספרים
4000 ,חיפה ,455 ,מנדלסון דבורה
4000 ,חיפה ,1300 ,מנהרות הכרמל
4000 ,חיפה ,887 ,מנחם
4000 ,חיפה ,670 ,מנילה
4000 ,חיפה ,363 ,מנצור
4000 ,חיפה ,834 ,מסדה
4000 ,חיפה ,433 ,מסילת הברזל
4000 ,חיפה ,1185 ,מסילת ישרים
4000 ,חיפה ,8004 ,מספנות ישראל
4000 ,חיפה ,6590 ,מע אחוזה
4000 ,חיפה ,6551 ,מע ק אליהו
4000 ,חיפה ,6502 ,מע ק שמואל
4000 ,חיפה ,668 ,מעברות
4000 ,חיפה ,498 ,מעון אבא חושי
4000 ,חיפה ,214 ,מעון אקדמאים
4000 ,חיפה ,499 ,מעון קלמן לוין
4000 ,חיפה ,645 ,מעין
4000 ,חיפה ,338 ,מעלה השחרור
4000 ,חיפה ,1230 ,מעלות אל עבאסי עסאם
4000 ,חיפה ,1138 ,מעלות בוגלה יונה
4000 ,חיפה ,1353 ,מעלות גרונר דב
4000 ,חיפה ,750 ,מעלות הבוצרים
4000 ,חיפה ,1130 ,מעלות חת"ם סופר
4000 ,חיפה ,1104 ,מעלות ישכיל
4000 ,חיפה ,240 ,מצובה
4000 ,חיפה ,1030 ,מצפה
4000 ,חיפה ,965 ,מקור ברוך
4000 ,חיפה ,192 ,מקלף מרדכי רא"ל
4000 ,חיפה ,567 ,מרגולין פנחס
4000 ,חיפה ,671 ,מרגלית
4000 ,חיפה ,1223 ,מרווה
4000 ,חיפה ,312 ,מרחביה
4000 ,חיפה ,744 ,מרכוס דוד
4000 ,חיפה ,962 ,מרכוס הרב
4000 ,חיפה ,747 ,מרכז הנופש
4000 ,חיפה ,1208 ,מרכז קסטרא
4000 ,חיפה ,8011 ,מרכז תעשיות מדע
4000 ,חיפה ,1301 ,מרמרי
4000 ,חיפה ,786 ,מרסיי
4000 ,חיפה ,282 ,מרקוני גוליאלמו
4000 ,חיפה ,599 ,משאש הרב
4000 ,חיפה ,260 ,משה חיים שפירא
4000 ,חיפה ,216 ,משטרת קרית חיים
4000 ,חיפה ,638 ,משמר הגבול
4000 ,חיפה ,234 ,משמר העמק
4000 ,חיפה ,1255 ,משעול יהודית ומאיר
4000 ,חיפה ,1228 ,משעול תורת חיים
4000 ,חיפה ,6513 ,מתחם האיצטדיון
4000 ,חיפה ,6520 ,מתחם מיכלי הנפט
4000 ,חיפה ,365 ,נאמנים
4000 ,חיפה ,335 ,נג'ד
4000 ,חיפה ,1181 ,נדב יצחק
4000 ,חיפה ,855 ,נהון יצחק
4000 ,חיפה ,541 ,נהלל
4000 ,חיפה ,291 ,נהריים
4000 ,חיפה ,678 ,נוגה
4000 ,חיפה ,1145 ,נוה גנים
4000 ,חיפה ,6575 ,נוה דוד
4000 ,חיפה ,2676 ,נוי
4000 ,חיפה ,951 ,נועה
4000 ,חיפה ,2677 ,נועם
4000 ,חיפה ,6711 ,נוף המפרץ
4000 ,חיפה ,885 ,נורדאו
4000 ,חיפה ,790 ,נורווגיה
4000 ,חיפה ,1176 ,נורית
4000 ,חיפה ,520 ,נח"ל
4000 ,חיפה ,542 ,נחליאלי
4000 ,חיפה ,1113 ,נחמיאס יוסף
4000 ,חיפה ,1027 ,נחמיה
4000 ,חיפה ,665 ,נחשון
4000 ,חיפה ,1076 ,נטר קרל
4000 ,חיפה ,280 ,ניוטון אייזיק
4000 ,חיפה ,1157 ,ניל"י
4000 ,חיפה ,1500 ,ניסים יצחק הרב
4000 ,חיפה ,1090 ,ניסנבוים
4000 ,חיפה ,716 ,ניצנים
4000 ,חיפה ,8003 ,נמל הדיג
4000 ,חיפה ,8002 ,נמל הקישון
4000 ,חיפה ,8001 ,נמל חיפה
4000 ,חיפה ,1018 ,נמרי דוד
4000 ,חיפה ,1162 ,נעמ"ת
4000 ,חיפה ,681 ,נעמי
4000 ,חיפה ,1386 ,נעמי שמר
4000 ,חיפה ,964 ,נתיב אליעזר
4000 ,חיפה ,761 ,נתיב אפקים
4000 ,חיפה ,1204 ,נתיב האור
4000 ,חיפה ,171 ,נתיב האתרוג
4000 ,חיפה ,130 ,נתיב ההדס
4000 ,חיפה ,175 ,נתיב הישיבה
4000 ,חיפה ,136 ,נתיב הלולב
4000 ,חיפה ,156 ,נתיב העולים
4000 ,חיפה ,122 ,נתיב הערבה
4000 ,חיפה ,1019 ,נתיב חן
4000 ,חיפה ,222 ,נתיב שד"ר
4000 ,חיפה ,442 ,נתיבי המפרץ
4000 ,חיפה ,444 ,נתנזון
4000 ,חיפה ,492 ,סבידור מנחם
4000 ,חיפה ,6611 ,סביוני הכרמל
4000 ,חיפה ,714 ,סגליות
4000 ,חיפה ,584 ,סוטין חיים
4000 ,חיפה ,724 ,סוכות
4000 ,חיפה ,916 ,סולד הנרייטה
4000 ,חיפה ,881 ,סוקולוב
4000 ,חיפה ,977 ,סורוקה משה
4000 ,חיפה ,564 ,סחרוב אנדרה
4000 ,חיפה ,581 ,סטרומה
4000 ,חיפה ,1467 ,סייפן
4000 ,חיפה ,1074 ,סילבר אבא הלל
4000 ,חיפה ,358 ,סיליזיאן
4000 ,חיפה ,875 ,סירקין
4000 ,חיפה ,1393 ,סכנין אברהם
4000 ,חיפה ,1170 ,סלוודור
4000 ,חיפה ,1007 ,סלומון יעקב
4000 ,חיפה ,247 ,סלמאן
4000 ,חיפה ,1217 ,סלעית
4000 ,חיפה ,617 ,סמ אהרון
4000 ,חיפה ,449 ,סמ אוריין מאיר
4000 ,חיפה ,320 ,סמ אני מאמין
4000 ,חיפה ,401 ,סמ אשקלון
4000 ,חיפה ,534 ,סמ בנימין
4000 ,חיפה ,953 ,סמ ברק
4000 ,חיפה ,760 ,סמ דן
4000 ,חיפה ,349 ,סמ הבוסתן
4000 ,חיפה ,532 ,סמ הוז דב
4000 ,חיפה ,450 ,סמ הזגג
4000 ,חיפה ,290 ,סמ הירמוך
4000 ,חיפה ,477 ,סמ הכנסיה
4000 ,חיפה ,796 ,סמ הכרמים
4000 ,חיפה ,868 ,סמ הנביאים
4000 ,חיפה ,343 ,סמ העמים
4000 ,חיפה ,435 ,סמ העצמאות
4000 ,חיפה ,529 ,סמ הצנחנים
4000 ,חיפה ,423 ,סמ הרכבת
4000 ,חיפה ,327 ,סמ ואדי סליב
4000 ,חיפה ,1352 ,סמ חג'אג'
4000 ,חיפה ,1205 ,סמ יהודה
4000 ,חיפה ,533 ,סמ מונש
4000 ,חיפה ,806 ,סמ מוסול
4000 ,חיפה ,826 ,סמ מירון
4000 ,חיפה ,431 ,סמ נוזהה
4000 ,חיפה ,712 ,סמ ספרון
4000 ,חיפה ,730 ,סמ עופרים
4000 ,חיפה ,368 ,סמ עזה
4000 ,חיפה ,871 ,סמ עתלית
4000 ,חיפה ,375 ,סמ פליק
4000 ,חיפה ,396 ,סמ צור
4000 ,חיפה ,861 ,סמ צידון
4000 ,חיפה ,374 ,סמ רובין
4000 ,חיפה ,835 ,סמ שמואל
4000 ,חיפה ,713 ,סמדר
4000 ,חיפה ,609 ,סמולנסקין
4000 ,חיפה ,1212 ,סמטת מטמון
4000 ,חיפה ,1211 ,סמטת ממון
4000 ,חיפה ,1165 ,סנה משה
4000 ,חיפה ,410 ,סנט לוקס
4000 ,חיפה ,914 ,סנש חנה
4000 ,חיפה ,598 ,סעדיה גאון
4000 ,חיפה ,1232 ,ספיר
4000 ,חיפה ,1221 ,סתוונית
4000 ,חיפה ,802 ,עבאס
4000 ,חיפה ,585 ,עגנון ש"י
4000 ,חיפה ,516 ,עדה
4000 ,חיפה ,739 ,עדולם
4000 ,חיפה ,729 ,עובדיה
4000 ,חיפה ,695 ,עודה נדה
4000 ,חיפה ,560 ,עוזיהו המלך
4000 ,חיפה ,866 ,עומר אל כיאם
4000 ,חיפה ,325 ,עומר אל כתאב
4000 ,חיפה ,1011 ,עוספיה
4000 ,חיפה ,1354 ,עזרא
4000 ,חיפה ,722 ,עין גדי
4000 ,חיפה ,357 ,עין דור
4000 ,חיפה ,6576 ,עין הים
4000 ,חיפה ,1137 ,עירם צילה
4000 ,חיפה ,436 ,עליית הנוער
4000 ,חיפה ,334 ,עמון
4000 ,חיפה ,860 ,עמוס
4000 ,חיפה ,1100 ,עמיקם ישראל
4000 ,חיפה ,1245 ,עמירם חרלף
4000 ,חיפה ,518 ,עמל
4000 ,חיפה ,844 ,עמק הזיתים
4000 ,חיפה ,1233 ,ענבר
4000 ,חיפה ,737 ,ענתות
4000 ,חיפה ,361 ,עספור
4000 ,חיפה ,1259 ,עפרה חזה
4000 ,חיפה ,587 ,עפרון ד"ר
4000 ,חיפה ,924 ,עצמון
4000 ,חיפה ,329 ,עקבה
4000 ,חיפה ,879 ,עקרון
4000 ,חיפה ,1080 ,ערד
4000 ,חיפה ,119 ,עשת אביגדור
4000 ,חיפה ,1014 ,פאר
4000 ,חיפה ,897 ,פבזנר
4000 ,חיפה ,178 ,פולד ברכה
4000 ,חיפה ,808 ,פועה
4000 ,חיפה ,475 ,פורטונה עמנואל
4000 ,חיפה ,982 ,פז סעדיה
4000 ,חיפה ,1203 ,פטל
4000 ,חיפה ,352 ,פטריה
4000 ,חיפה ,1052 ,פיארברג
4000 ,חיפה ,769 ,פייגין צבי
4000 ,חיפה ,1107 ,פייזר ד"ר
4000 ,חיפה ,310 ,פייצל
4000 ,חיפה ,1081 ,פיכמן יעקב
4000 ,חיפה ,792 ,פינלנד
4000 ,חיפה ,651 ,פינסקי דוד
4000 ,חיפה ,1032 ,פינסקר
4000 ,חיפה ,568 ,פינת גן
4000 ,חיפה ,621 ,פיק"א
4000 ,חיפה ,229 ,פישמן עדה (מיימון)
4000 ,חיפה ,767 ,פלג פרנק
4000 ,חיפה ,314 ,פלי"ם
4000 ,חיפה ,974 ,פלימן משה
4000 ,חיפה ,632 ,פלמ"ח
4000 ,חיפה ,1071 ,פנינה
4000 ,חיפה ,854 ,פנת בן יהודה
4000 ,חיפה ,843 ,פנת בן שמן
4000 ,חיפה ,942 ,פנת הרצל
4000 ,חיפה ,890 ,פנת לונץ
4000 ,חיפה ,876 ,פנת שפירא
4000 ,חיפה ,746 ,פסטר לואי
4000 ,חיפה ,381 ,פקדון
4000 ,חיפה ,1053 ,פרוג
4000 ,חיפה ,614 ,פרויד
4000 ,חיפה ,1508 ,פרומר דב
4000 ,חיפה ,937 ,פרידמן שמעון ד"ר
4000 ,חיפה ,898 ,פרישמן
4000 ,חיפה ,284 ,פרנץ לוי
4000 ,חיפה ,1248 ,פרץ זאב אטקס
4000 ,חיפה ,394 ,פרץ י. ל.
4000 ,חיפה ,1097 ,פרץ מרקיש
4000 ,חיפה ,1034 ,פת יעקב
4000 ,חיפה ,4045 ,צ'ק פוסט
4000 ,חיפה ,1152 ,צ'רניאבסקי אהרון
4000 ,חיפה ,459 ,צביה ויצחק
4000 ,חיפה ,1175 ,צבעוני
4000 ,חיפה ,765 ,צבר
4000 ,חיפה ,674 ,צדקיהו
4000 ,חיפה ,509 ,צה"ל
4000 ,חיפה ,389 ,צהיון
4000 ,חיפה ,1411 ,צופית
4000 ,חיפה ,1026 ,ציון
4000 ,חיפה ,172 ,ציזלינג אהרון
4000 ,חיפה ,262 ,צייטלין אהרון
4000 ,חיפה ,521 ,צילה
4000 ,חיפה ,851 ,ציפורי
4000 ,חיפה ,180 ,ציפר
4000 ,חיפה ,562 ,ציקלג
4000 ,חיפה ,759 ,צלמונה
4000 ,חיפה ,1123 ,צפורה זייד
4000 ,חיפה ,654 ,צפרירים
4000 ,חיפה ,841 ,צפת
4000 ,חיפה ,597 ,קאודרס בן ציון ד"ר
4000 ,חיפה ,1119 ,קארו יוסף הרב
4000 ,חיפה ,301 ,קבוץ גלויות
4000 ,חיפה ,440 ,קדושי בגדד
4000 ,חיפה ,588 ,קדושי דמשק
4000 ,חיפה ,256 ,קדושי השואה
4000 ,חיפה ,460 ,קדושי יאסי
4000 ,חיפה ,684 ,קדימה
4000 ,חיפה ,1385 ,קדיש לוז
4000 ,חיפה ,672 ,קדרון
4000 ,חיפה ,371 ,קהילת סלוניקי
4000 ,חיפה ,992 ,קויפמן יחזקאל פרופ'
4000 ,חיפה ,254 ,קול מבשר
4000 ,חיפה ,1044 ,קומוי נתן
4000 ,חיפה ,1460 ,קוסטה ריקה
4000 ,חיפה ,1220 ,קורנית
4000 ,חיפה ,771 ,קורצ'אק יאנוש
4000 ,חיפה ,1398 ,קושניר יוסף
4000 ,חיפה ,372 ,קיזרמן נתן
4000 ,חיפה ,1114 ,קיסרי אורי
4000 ,חיפה ,391 ,קיסריה
4000 ,חיפה ,424 ,קישלה
4000 ,חיפה ,531 ,קל"ח
4000 ,חיפה ,1095 ,קליבנוב
4000 ,חיפה ,1047 ,קלישר הרב
4000 ,חיפה ,650 ,קלר
4000 ,חיפה ,1102 ,קניאל יהושע הרב
4000 ,חיפה ,907 ,קניג פייר גנרל
4000 ,חיפה ,1209 ,קניון חיפה
4000 ,חיפה ,1190 ,קסטרא
4000 ,חיפה ,848 ,קסל
4000 ,חיפה ,1156 ,קפטן סטיב
4000 ,חיפה ,184 ,קפלן אלישבע
4000 ,חיפה ,269 ,קצנשטיין אריה
4000 ,חיפה ,452 ,קריב אברהם
4000 ,חיפה ,627 ,קריית ספר
4000 ,חיפה ,6552 ,קרית אליעזר
4000 ,חיפה ,6706 ,קרית הטכניון
4000 ,חיפה ,6601 ,קרית חיים מזרחית
4000 ,חיפה ,6602 ,קרית חיים מערבית
4000 ,חיפה ,6503 ,קרית חשמל
4000 ,חיפה ,6604 ,קרית רבין
4000 ,חיפה ,798 ,קרן היסוד
4000 ,חיפה ,170 ,קרן קיימת לישראל
4000 ,חיפה ,125 ,קרפל ד"ר
4000 ,חיפה ,1215 ,קשטן מיכאל
4000 ,חיפה ,387 ,ראהבת
4000 ,חיפה ,1083 ,ראם פנחס
4000 ,חיפה ,1128 ,ראש מיה
4000 ,חיפה ,949 ,ראש פינה
4000 ,חיפה ,891 ,רבוה
4000 ,חיפה ,580 ,רבי יהודה הנשיא
4000 ,חיפה ,225 ,רבי יוחנן הסנדלר
4000 ,חיפה ,918 ,רבי עקיבא
4000 ,חיפה ,1159 ,רבין אסתר ד"ר
4000 ,חיפה ,776 ,רביצקי אימרה
4000 ,חיפה ,735 ,רבנו תם
4000 ,חיפה ,416 ,רד"ק
4000 ,חיפה ,1243 ,רובי שפירא
4000 ,חיפה ,482 ,רובינא חנה
4000 ,חיפה ,1164 ,רובינשטיין יצחק הרב
4000 ,חיפה ,557 ,רודן
4000 ,חיפה ,462 ,רודנר עקיבא הרב
4000 ,חיפה ,297 ,רוזוב שמואל
4000 ,חיפה ,451 ,רוזן פנחס
4000 ,חיפה ,1192 ,רוטנברג פנחס ואברהם
4000 ,חיפה ,676 ,רות
4000 ,חיפה ,1006 ,רזיאל דוד
4000 ,חיפה ,326 ,רח 580
4000 ,חיפה ,472 ,רח 2611
4000 ,חיפה ,1193 ,רח 2622
4000 ,חיפה ,893 ,רח 822
4000 ,חיפה ,1225 ,רח 2632
4000 ,חיפה ,1194 ,רח 2623
4000 ,חיפה ,2653 ,רח 2653
4000 ,חיפה ,1236 ,רח 564
4000 ,חיפה ,1196 ,רח 2625
4000 ,חיפה ,2635 ,רח 2635
4000 ,חיפה ,1237 ,רח 735
4000 ,חיפה ,221 ,רח 665
4000 ,חיפה ,589 ,רח 9016
4000 ,חיפה ,299 ,רח 666
4000 ,חיפה ,570 ,רח 586
4000 ,חיפה ,1098 ,רח 547
4000 ,חיפה ,486 ,רח 2647
4000 ,חיפה ,1199 ,רח 2628
4000 ,חיפה ,487 ,רח 2648
4000 ,חיפה ,1013 ,רח 2588
4000 ,חיפה ,244 ,רח 688
4000 ,חיפה ,2659 ,רח 2659
4000 ,חיפה ,248 ,רח 689
4000 ,חיפה ,135 ,רח לו
4000 ,חיפה ,133 ,רח לח
4000 ,חיפה ,137 ,רח מא
4000 ,חיפה ,121 ,רח מו
4000 ,חיפה ,124 ,רח מח
4000 ,חיפה ,117 ,רח נא
4000 ,חיפה ,120 ,רח נד
4000 ,חיפה ,115 ,רח נו
4000 ,חיפה ,112 ,רח נט
4000 ,חיפה ,102 ,רח סא
4000 ,חיפה ,104 ,רח סג
4000 ,חיפה ,106 ,רח סה
4000 ,חיפה ,108 ,רח סז
4000 ,חיפה ,6048 ,רחבת הטמפלרים
4000 ,חיפה ,6049 ,רחבת המחוללים
4000 ,חיפה ,6018 ,רחבת כמיל שחאדה
4000 ,חיפה ,669 ,רחל
4000 ,חיפה ,525 ,רחף
4000 ,חיפה ,1091 ,רטנר יוחנן פרופ'
4000 ,חיפה ,140 ,ריינס הרב
4000 ,חיפה ,710 ,רימונים
4000 ,חיפה ,445 ,רינ"ה
4000 ,חיפה ,909 ,רמב"ן
4000 ,חיפה ,6607 ,רמות ספיר
4000 ,חיפה ,6610 ,רמות רמז
4000 ,חיפה ,6708 ,רמת אלון
4000 ,חיפה ,6596 ,רמת אלמוגי
4000 ,חיפה ,6595 ,רמת אשכול
4000 ,חיפה ,6597 ,רמת בגין
4000 ,חיפה ,6599 ,רמת בן גוריון
4000 ,חיפה ,6592 ,רמת גולדה
4000 ,חיפה ,6660 ,רמת גוראל
4000 ,חיפה ,6614 ,רמת התשבי
4000 ,חיפה ,6609 ,רמת חן
4000 ,חיפה ,620 ,רנ"ק
4000 ,חיפה ,694 ,רענן
4000 ,חיפה ,910 ,רש"י
4000 ,חיפה ,6504 ,ש אנגלו סכסים
4000 ,חיפה ,6505 ,ש אסבסט קרית חיים
4000 ,חיפה ,6506 ,ש אקדמאים
4000 ,חיפה ,6675 ,ש בולגרים חל
4000 ,חיפה ,6593 ,ש הוד הכרמל
4000 ,חיפה ,6554 ,ש ח משוח בת גלים
4000 ,חיפה ,6507 ,ש חדש קרית שמואל
4000 ,חיפה ,6508 ,ש חילים משוחררים
4000 ,חיפה ,6510 ,ש משכנות ק שמואל
4000 ,חיפה ,6676 ,ש נוה יוסף
4000 ,חיפה ,6511 ,ש סוכנות ק שמואל
4000 ,חיפה ,6512 ,ש ערוני מפרץ
4000 ,חיפה ,6557 ,ש צה"ל
4000 ,חיפה ,6514 ,ש קרית שמואל ב
4000 ,חיפה ,6577 ,ש קרית שפרינצק
4000 ,חיפה ,6650 ,ש רמת גאולה
4000 ,חיפה ,6636 ,ש רמת הדר
4000 ,חיפה ,6651 ,ש רמת ויזניץ
4000 ,חיפה ,6625 ,ש רמת שאול
4000 ,חיפה ,493 ,שאגאל מארק
4000 ,חיפה ,1028 ,שאול
4000 ,חיפה ,578 ,שאול המלך
4000 ,חיפה ,237 ,שאר ישוב
4000 ,חיפה ,978 ,שארית הפליטה
4000 ,חיפה ,773 ,שבדיה
4000 ,חיפה ,1118 ,שבזי שלום
4000 ,חיפה ,173 ,שבטי ישראל
4000 ,חיפה ,777 ,שביל אהבת שלום
4000 ,חיפה ,478 ,שביל חנא נקארה
4000 ,חיפה ,167 ,שביל פומרוק משה
4000 ,חיפה ,1122 ,שביל רבקה
4000 ,חיפה ,174 ,שבעת המינים
4000 ,חיפה ,403 ,שבתאי לוי
4000 ,חיפה ,602 ,שד אבא חושי
4000 ,חיפה ,399 ,שד בן גוריון
4000 ,חיפה ,546 ,שד בת גלים
4000 ,חיפה ,816 ,שד גולומב אליהו
4000 ,חיפה ,110 ,שד דגניה
4000 ,חיפה ,652 ,שד הברושים
4000 ,חיפה ,1161 ,שד ההגנה
4000 ,חיפה ,195 ,שד ההסתדרות
4000 ,חיפה ,1038 ,שד הל"ה
4000 ,חיפה ,360 ,שד המגינים
4000 ,חיפה ,550 ,שד המתנדבים
4000 ,חיפה ,666 ,שד הנדיב
4000 ,חיפה ,635 ,שד הנשיא
4000 ,חיפה ,687 ,שד הצבי
4000 ,חיפה ,811 ,שד הציונות
4000 ,חיפה ,141 ,שד הרא"ה
4000 ,חיפה ,912 ,שד וינגיט
4000 ,חיפה ,129 ,שד ורבורג
4000 ,חיפה ,1031 ,שד טרומפלדור
4000 ,חיפה ,689 ,שד יצחק
4000 ,חיפה ,625 ,שד מוריה
4000 ,חיפה ,228 ,שד מח"ל
4000 ,חיפה ,623 ,שד סיני
4000 ,חיפה ,915 ,שד קיש
4000 ,חיפה ,586 ,שד קרית אליעזר
4000 ,חיפה ,1135 ,שד רוז
4000 ,חיפה ,505 ,שד רוטשילד
4000 ,חיפה ,1057 ,שד רות הכהן
4000 ,חיפה ,1136 ,שד שיקגו
4000 ,חיפה ,1197 ,שד' אפרים קציר
4000 ,חיפה ,1511 ,שד' מייק ברנט
4000 ,חיפה ,1240 ,שד' עזר וייצמן
4000 ,חיפה ,109 ,שדה בוקר
4000 ,חיפה ,6534 ,שדה התעופה
4000 ,חיפה ,421 ,שדה יצחק
4000 ,חיפה ,1239 ,שדרות הדקלים
4000 ,חיפה ,1238 ,שהם צבי
4000 ,חיפה ,954 ,שוהם
4000 ,חיפה ,490 ,שוויצר אלברט ד"ר
4000 ,חיפה ,419 ,שומאכר גוטליב
4000 ,חיפה ,725 ,שומרון
4000 ,חיפה ,566 ,שונית
4000 ,חיפה ,728 ,שונמית
4000 ,חיפה ,989 ,שופמן גרשון
4000 ,חיפה ,1263 ,שושנה דמארי
4000 ,חיפה ,702 ,שושנת הכרמל
4000 ,חיפה ,453 ,שז"ר זלמן
4000 ,חיפה ,1166 ,שחאדה שלח
4000 ,חיפה ,1226 ,שחם
4000 ,חיפה ,219 ,שטיין ליאון
4000 ,חיפה ,970 ,שטרן יאיר
4000 ,חיפה ,257 ,שטרן יוסף
4000 ,חיפה ,337 ,שיבת ציון
4000 ,חיפה ,236 ,שיטרית בכור
4000 ,חיפה ,872 ,שימקין ד"ר
4000 ,חיפה ,1068 ,שינדלר אוסקר
4000 ,חיפה ,6522 ,שכ אחוזה
4000 ,חיפה ,6555 ,שכ גאולה
4000 ,חיפה ,6558 ,שכ החוף השקט
4000 ,חיפה ,6527 ,שכ המגינים
4000 ,חיפה ,6528 ,שכ המושבה הגרמנית
4000 ,חיפה ,6515 ,שכ הרכבת
4000 ,חיפה ,6509 ,שכ הרצליה
4000 ,חיפה ,6652 ,שכ ואדי טינה
4000 ,חיפה ,6521 ,שכ ואדי ניסנאס
4000 ,חיפה ,6578 ,שכ ואדי שיאח
4000 ,חיפה ,6608 ,שכ ורדיה
4000 ,חיפה ,6589 ,שכ זיו
4000 ,חיפה ,6677 ,שכ חליסא
4000 ,חיפה ,6553 ,שכ יזרעאליה
4000 ,חיפה ,6536 ,שכ יצחק נבון
4000 ,חיפה ,6579 ,שכ כפר סמיר
4000 ,חיפה ,6518 ,שכ כרמל צרפתי
4000 ,חיפה ,6605 ,שכ כרמליה
4000 ,חיפה ,6556 ,שכ מרכז הדר
4000 ,חיפה ,6532 ,שכ נאות פרס
4000 ,חיפה ,6674 ,שכ נווה גנים
4000 ,חיפה ,6524 ,שכ נווה שאנן
4000 ,חיפה ,6533 ,שכ נחלה
4000 ,חיפה ,6519 ,שכ קרית אליהו
4000 ,חיפה ,6501 ,שכ קרית חיים
4000 ,חיפה ,6516 ,שכ קרית שמואל
4000 ,חיפה ,6606 ,שכ רוממה
4000 ,חיפה ,6616 ,שכ שמבור
4000 ,חיפה ,6598 ,שכ שער העליה
4000 ,חיפה ,6523 ,שכ תל עמל
4000 ,חיפה ,631 ,שכטר יוסף ד"ר
4000 ,חיפה ,1069 ,של"ג
4000 ,חיפה ,1254 ,שלהוב ג'מיל
4000 ,חיפה ,1048 ,שלום עליכם
4000 ,חיפה ,590 ,שלומציון המלכה
4000 ,חיפה ,1395 ,שלון
4000 ,חיפה ,781 ,שלונסקי
4000 ,חיפה ,466 ,שלוסברג שמואל ד"ר
4000 ,חיפה ,561 ,שלמה המלך
4000 ,חיפה ,827 ,שמאי
4000 ,חיפה ,828 ,שמואל
4000 ,חיפה ,1070 ,שמעוני לייב
4000 ,חיפה ,847 ,שמריהו לוין
4000 ,חיפה ,877 ,שמש
4000 ,חיפה ,634 ,שמשון
4000 ,חיפה ,1059 ,שניאור זלמן
4000 ,חיפה ,900 ,שניים בנובמבר
4000 ,חיפה ,199 ,שנקר אריה
4000 ,חיפה ,700 ,שער הגיא
4000 ,חיפה ,641 ,שער הלבנון
4000 ,חיפה ,1085 ,שער פלמר
4000 ,חיפה ,874 ,שפירא
4000 ,חיפה ,807 ,שפרה
4000 ,חיפה ,852 ,שפרעם
4000 ,חיפה ,764 ,שקמה
4000 ,חיפה ,547 ,שקמונה
4000 ,חיפה ,356 ,שרה
4000 ,חיפה ,1129 ,שרעבי שלום רבי
4000 ,חיפה ,162 ,שרת משה
4000 ,חיפה ,166 ,ששת הימים
4000 ,חיפה ,463 ,תאד"ל
4000 ,חיפה ,1174 ,תאשור
4000 ,חיפה ,1470 ,תבואות הארץ
4000 ,חיפה ,1173 ,תדהר
4000 ,חיפה ,193 ,תובל
4000 ,חיפה ,1143 ,תומא אמיל
4000 ,חיפה ,146 ,תורה ועבודה
4000 ,חיפה ,545 ,תחנה מרכזית
4000 ,חיפה ,439 ,תחנת הכרמל
4000 ,חיפה ,506 ,תל אביב
4000 ,חיפה ,901 ,תל חי
4000 ,חיפה ,616 ,תל מאנה
4000 ,חיפה ,1064 ,תל עמל
4000 ,חיפה ,6594 ,תל שקמונה
4000 ,חיפה ,1078 ,תענך
4000 ,חיפה ,139 ,תרי"ג
4000 ,חיפה ,336 ,תרצה
4000 ,חיפה ,298 ,תרשיש
162 ,חירות ,114 ,בת-חן
162 ,חירות ,123 ,דרור
162 ,חירות ,106 ,דרך המייסדים
162 ,חירות ,124 ,דרך הפרדס
162 ,חירות ,116 ,דרך השדות
162 ,חירות ,115 ,דרך השקמים
162 ,חירות ,112 ,האלון
162 ,חירות ,109 ,האנפה
162 ,חירות ,105 ,האתרוג
162 ,חירות ,102 ,הבאר
162 ,חירות ,107 ,הבנים
162 ,חירות ,108 ,ההרחבה
162 ,חירות ,101 ,המשתלה
162 ,חירות ,110 ,הפיקוס
162 ,חירות ,103 ,הצבעוני
162 ,חירות ,104 ,הצבר
162 ,חירות ,111 ,השיזף
162 ,חירות ,118 ,השקד
162 ,חירות ,9000 ,חירות
162 ,חירות ,117 ,נוף
162 ,חירות ,122 ,סמ החצב
162 ,חירות ,121 ,סמ הכלנית
162 ,חירות ,120 ,סמ העירית
162 ,חירות ,113 ,עין התכלת
162 ,חירות ,6500 ,שכ בנים
1272 ,חלוץ ,101 ,ארזים
1272 ,חלוץ ,103 ,גלעד
1272 ,חלוץ ,104 ,גנים
1272 ,חלוץ ,105 ,הבאר
1272 ,חלוץ ,106 ,הדודאים
1272 ,חלוץ ,107 ,ההרים
1272 ,חלוץ ,108 ,הזמיר
1272 ,חלוץ ,126 ,הכפר הסקנדינבי
1272 ,חלוץ ,109 ,חבצלת השרון
1272 ,חלוץ ,110 ,חגווי הסלע
1272 ,חלוץ ,111 ,חוט השני
1272 ,חלוץ ,9000 ,חלוץ
1272 ,חלוץ ,112 ,כרמי
1272 ,חלוץ ,113 ,מור ולבונה
1272 ,חלוץ ,114 ,מים חיים
1272 ,חלוץ ,122 ,משכנות הרועים
1272 ,חלוץ ,115 ,ניצנים
1272 ,חלוץ ,116 ,סמטת מחמדים
1272 ,חלוץ ,117 ,סמטת שיר השירים
1272 ,חלוץ ,118 ,עצי היער
1272 ,חלוץ ,119 ,ערוגות הבושם
1272 ,חלוץ ,120 ,פלח הרימון
1272 ,חלוץ ,121 ,פרי מגדים
1272 ,חלוץ ,123 ,רסיסי לילה
1272 ,חלוץ ,124 ,שושנת העמקים
1272 ,חלוץ ,125 ,תלפיות
820 ,חלץ ,9000 ,חלץ
993 ,חמאם ,9000 ,חמאם
801 ,חמד ,102 ,הגפן
801 ,חמד ,101 ,הזית
801 ,חמד ,103 ,החיטה
801 ,חמד ,111 ,הנחלים
801 ,חמד ,106 ,העומר
801 ,חמד ,107 ,הרימון
801 ,חמד ,104 ,התאנה
801 ,חמד ,105 ,התמר
801 ,חמד ,9000 ,חמד
801 ,חמד ,119 ,נחל איילון
801 ,חמד ,109 ,נחל ארבל
801 ,חמד ,117 ,נחל גבעות
801 ,חמד ,115 ,נחל דולב
801 ,חמד ,118 ,נחל דן
801 ,חמד ,113 ,נחל הבשור
801 ,חמד ,116 ,נחל הירדן
801 ,חמד ,114 ,נחל זרי הדפנה
801 ,חמד ,108 ,נחל חרמון
801 ,חמד ,112 ,נחל שפירים
801 ,חמד ,110 ,נחל תבור
343 ,חמדיה ,101 ,בית ראשון במולדת
343 ,חמדיה ,9000 ,חמדיה
3646 ,חמדת ,101 ,הגלעד
3646 ,חמדת ,104 ,הירדן
3646 ,חמדת ,105 ,המייסדים
3646 ,חמדת ,9000 ,חמדת
3646 ,חמדת ,102 ,כביר
3646 ,חמדת ,103 ,מעבר יבוק
3646 ,חמדת ,106 ,משעול גוש קטיף
3609 ,חמרה ,9000 ,חמרה
807 ,חניאל ,101 ,האורן
807 ,חניאל ,102 ,הגבעה
807 ,חניאל ,105 ,הגיא
807 ,חניאל ,103 ,החרוב
807 ,חניאל ,104 ,הראשונים
807 ,חניאל ,9000 ,חניאל
807 ,חניאל ,6001 ,ככר אורנים
807 ,חניאל ,107 ,סמ הבאר
807 ,חניאל ,106 ,סמ הפרדס
280 ,חניתה ,101 ,אולפן קבוץ
280 ,חניתה ,110 ,דרך גן עדן
280 ,חניתה ,106 ,דרך הבריכה
280 ,חניתה ,105 ,דרך הזיתים
280 ,חניתה ,104 ,דרך השער המזרחי
280 ,חניתה ,103 ,דרך חומה ומגדל
280 ,חניתה ,102 ,דרך מגדל המים
280 ,חניתה ,111 ,דרך עין חור
280 ,חניתה ,9000 ,חניתה
280 ,חניתה ,108 ,מורד הגיא
280 ,חניתה ,109 ,מעלה דורבנים
280 ,חניתה ,107 ,מעלה הגבעה
280 ,חניתה ,112 ,שביל אלון
280 ,חניתה ,113 ,שביל ברוש
280 ,חניתה ,114 ,שביל קטלב
1257 ,חנתון ,111 ,איבי הנחל
1257 ,חנתון ,109 ,אמת המים
1257 ,חנתון ,107 ,באר מרים
1257 ,חנתון ,110 ,גשמי ברכה
1257 ,חנתון ,102 ,דרך האגם
1257 ,חנתון ,115 ,דרך נאות מדבר
1257 ,חנתון ,9000 ,חנתון
1257 ,חנתון ,108 ,טל ומטר
1257 ,חנתון ,103 ,יובל
1257 ,חנתון ,104 ,מעין
1257 ,חנתון ,112 ,משעול האפיק
1257 ,חנתון ,113 ,נווה גנים
1257 ,חנתון ,101 ,פלג
1257 ,חנתון ,106 ,רביבים
1257 ,חנתון ,105 ,שירת הים
4005 ,חספין ,9000 ,חספין
363 ,חפץ חיים ,9000 ,חפץ חיים
363 ,חפץ חיים ,101 ,מרכז קליטה
90 ,חפצי-בה ,9000 ,חפצי-בה
700 ,חצב ,9000 ,חצב
13 ,חצבה ,7001 ,בי"ס שדה חצבה
13 ,חצבה ,9000 ,חצבה
2034 ,חצור הגלילית ,170 ,אבני נחל
2034 ,חצור הגלילית ,6514 ,אזור התעשייה
2034 ,חצור הגלילית ,166 ,אלימלך נועם
2034 ,חצור הגלילית ,117 ,אתרוג
2034 ,חצור הגלילית ,115 ,בן גוריון
2034 ,חצור הגלילית ,127 ,בן יוסף שלמה
2034 ,חצור הגלילית ,201 ,בן ציון
2034 ,חצור הגלילית ,120 ,בנה ביתך
2034 ,חצור הגלילית ,105 ,דבורה הנביאה
2034 ,חצור הגלילית ,104 ,דרך הבנים
2034 ,חצור הגלילית ,107 ,דרך החולה
2034 ,חצור הגלילית ,205 ,דרך קק"ל
2034 ,חצור הגלילית ,126 ,דרך רבין
2034 ,חצור הגלילית ,133 ,האגס
2034 ,חצור הגלילית ,183 ,האומן
2034 ,חצור הגלילית ,187 ,האורג
2034 ,חצור הגלילית ,135 ,האורן
2034 ,חצור הגלילית ,159 ,האירוס
2034 ,חצור הגלילית ,198 ,האלה
2034 ,חצור הגלילית ,144 ,האקליפטוס
2034 ,חצור הגלילית ,181 ,הארז
2034 ,חצור הגלילית ,178 ,הארי
2034 ,חצור הגלילית ,182 ,האשחר
2034 ,חצור הגלילית ,103 ,האשל
2034 ,חצור הגלילית ,125 ,הבוסתן
2034 ,חצור הגלילית ,188 ,הבנאי
2034 ,חצור הגלילית ,152 ,הברוש
2034 ,חצור הגלילית ,171 ,הברושים
2034 ,חצור הגלילית ,124 ,הגפן
2034 ,חצור הגלילית ,139 ,הדובדבן
2034 ,חצור הגלילית ,196 ,הדולב
2034 ,חצור הגלילית ,154 ,הדקל
2034 ,חצור הגלילית ,204 ,הדרור
2034 ,חצור הגלילית ,163 ,ההדס
2034 ,חצור הגלילית ,189 ,הזגג
2034 ,חצור הגלילית ,134 ,הזית
2034 ,חצור הגלילית ,108 ,החסידה
2034 ,חצור הגלילית ,150 ,החרוב
2034 ,חצור הגלילית ,190 ,החרש
2034 ,חצור הגלילית ,157 ,היקינטון
2034 ,חצור הגלילית ,143 ,הכלנית
2034 ,חצור הגלילית ,131 ,המייסדים
2034 ,חצור הגלילית ,184 ,המסגר
2034 ,חצור הגלילית ,186 ,הנגר
2034 ,חצור הגלילית ,202 ,הנחליאלי
2034 ,חצור הגלילית ,185 ,הנפח
2034 ,חצור הגלילית ,106 ,הנשיא ויצמן
2034 ,חצור הגלילית ,112 ,הנשר
2034 ,חצור הגלילית ,158 ,הסחלב
2034 ,חצור הגלילית ,194 ,הסנונית
2034 ,חצור הגלילית ,160 ,הסתונית
2034 ,חצור הגלילית ,129 ,העינב
2034 ,חצור הגלילית ,162 ,הערבה
2034 ,חצור הגלילית ,180 ,הפיקוס
2034 ,חצור הגלילית ,193 ,הפשוש
2034 ,חצור הגלילית ,142 ,הצפצפה
2034 ,חצור הגלילית ,197 ,הקטלב
2034 ,חצור הגלילית ,149 ,הרב מורגנשטרן
2034 ,חצור הגלילית ,146 ,הרב פלדמן משה זאב
2034 ,חצור הגלילית ,153 ,הרותם
2034 ,חצור הגלילית ,130 ,הרימון
2034 ,חצור הגלילית ,111 ,הרקפת
2034 ,חצור הגלילית ,136 ,השזיף
2034 ,חצור הגלילית ,192 ,השחף
2034 ,חצור הגלילית ,151 ,השיטה
2034 ,חצור הגלילית ,122 ,השקד
2034 ,חצור הגלילית ,128 ,התאנה
2034 ,חצור הגלילית ,137 ,התפוח
2034 ,חצור הגלילית ,7001 ,חוות ינאי
2034 ,חצור הגלילית ,102 ,חוני המעגל
2034 ,חצור הגלילית ,147 ,חזון ישראל
2034 ,חצור הגלילית ,119 ,חצור א
2034 ,חצור הגלילית ,9000 ,חצור הגלילית
2034 ,חצור הגלילית ,145 ,טעם הצבי
2034 ,חצור הגלילית ,179 ,יונתן בן עוזיאל
2034 ,חצור הגלילית ,116 ,יצחק שמיר
2034 ,חצור הגלילית ,138 ,ירושלים
2034 ,חצור הגלילית ,167 ,יששכר בני
2034 ,חצור הגלילית ,199 ,כליל החורש
2034 ,חצור הגלילית ,203 ,לוי אשכול
2034 ,חצור הגלילית ,175 ,מבוא התנאים
2034 ,חצור הגלילית ,195 ,מבוא מצליח
2034 ,חצור הגלילית ,169 ,מנחם
2034 ,חצור הגלילית ,109 ,מנחם בגין
2034 ,חצור הגלילית ,164 ,מעלה הקרייה
2034 ,חצור הגלילית ,6504 ,נוה הרים
2034 ,חצור הגלילית ,6510 ,נוף המושבה
2034 ,חצור הגלילית ,148 ,נחמיה
2034 ,חצור הגלילית ,140 ,נרקיס
2034 ,חצור הגלילית ,165 ,נתיבות שלום
2034 ,חצור הגלילית ,141 ,סביון
2034 ,חצור הגלילית ,101 ,סולד הנריטה
2034 ,חצור הגלילית ,110 ,סמ הכרכום
2034 ,חצור הגלילית ,172 ,קדושת לוי
2034 ,חצור הגלילית ,6500 ,קריה חסידית
2034 ,חצור הגלילית ,6501 ,קרית בלוך
2034 ,חצור הגלילית ,6502 ,קרית החנוך
2034 ,חצור הגלילית ,174 ,רבי טרפון
2034 ,חצור הגלילית ,177 ,רבי מאיר בעל הנס
2034 ,חצור הגלילית ,176 ,רבי עקיבא
2034 ,חצור הגלילית ,6509 ,רובע מרכז
2034 ,חצור הגלילית ,132 ,רמב"ם
2034 ,חצור הגלילית ,114 ,רמון אילן
2034 ,חצור הגלילית ,6505 ,ש עמידר
2034 ,חצור הגלילית ,118 ,שד הרב עזיזי דיעי
2034 ,חצור הגלילית ,6511 ,שכ ארקיע
2034 ,חצור הגלילית ,6507 ,שכ אשטרום
2034 ,חצור הגלילית ,6512 ,שכ בנה ביתך
2034 ,חצור הגלילית ,6513 ,שכ הבוסתן
2034 ,חצור הגלילית ,6515 ,שכ הכרמים
2034 ,חצור הגלילית ,6508 ,שכ זאב
2034 ,חצור הגלילית ,168 ,שם שמואל
2034 ,חצור הגלילית ,156 ,שער הרחמים
2034 ,חצור הגלילית ,173 ,שפירא מאיר
2034 ,חצור הגלילית ,113 ,שפרינצק
2034 ,חצור הגלילית ,123 ,תמר
406 ,חצור-אשדוד ,9000 ,חצור-אשדוד
397 ,חצרים ,9000 ,חצרים
422 ,חרב לאת ,7001 ,בית ספר תום
422 ,חרב לאת ,102 ,האורן
422 ,חרב לאת ,103 ,האלה
422 ,חרב לאת ,107 ,האלון
422 ,חרב לאת ,104 ,הארז
422 ,חרב לאת ,108 ,הדולב
422 ,חרב לאת ,101 ,הדקל
422 ,חרב לאת ,105 ,הצאלון
422 ,חרב לאת ,106 ,השיטה
422 ,חרב לאת ,9000 ,חרב לאת
1024 ,חרוצים ,107 ,הגפן
1024 ,חרוצים ,105 ,ההדס
1024 ,חרוצים ,101 ,הזית
1024 ,חרוצים ,102 ,הערבה
1024 ,חרוצים ,103 ,הרימון
1024 ,חרוצים ,106 ,התאנה
1024 ,חרוצים ,104 ,התמר
1024 ,חרוצים ,9000 ,חרוצים
1247 ,חריש ,119 ,אביטל
1247 ,חריש ,6510 ,אבני חן
1247 ,חריש ,116 ,אדום
1247 ,חריש ,310 ,אודם
1247 ,חריש ,111 ,אורן
1247 ,חריש ,344 ,אחדות
1247 ,חריש ,327 ,אלה
1247 ,חריש ,103 ,אלון
1247 ,חריש ,324 ,אשל
1247 ,חריש ,326 ,אתרוג
1247 ,חריש ,321 ,ברוש
1247 ,חריש ,306 ,ברקת
1247 ,חריש ,110 ,גמלא
1247 ,חריש ,107 ,גפן
1247 ,חריש ,322 ,דולב
1247 ,חריש ,126 ,דפנה
1247 ,חריש ,303 ,דרך ארץ
1247 ,חריש ,102 ,הגולן
1247 ,חריש ,6511 ,החורש
1247 ,חריש ,350 ,החלומות
1247 ,חריש ,341 ,הקהילה
1247 ,חריש ,117 ,הר תבור
1247 ,חריש ,352 ,השראה
1247 ,חריש ,354 ,התחדשות
1247 ,חריש ,353 ,התמדה
1247 ,חריש ,323 ,זית
1247 ,חריש ,343 ,חברותא
1247 ,חריש ,313 ,חושן
1247 ,חריש ,9000 ,חריש
1247 ,חריש ,308 ,טופז
1247 ,חריש ,307 ,טורקיז
1247 ,חריש ,342 ,יחד
1247 ,חריש ,332 ,יסמין
1247 ,חריש ,331 ,יקינטון
1247 ,חריש ,336 ,כלנית
1247 ,חריש ,335 ,לוטם
1247 ,חריש ,309 ,לשם
1247 ,חריש ,311 ,מרגלית
1247 ,חריש ,333 ,נרקיס
1247 ,חריש ,338 ,סביון
1247 ,חריש ,337 ,סחלב
1247 ,חריש ,305 ,ספיר
1247 ,חריש ,312 ,ענבר
1247 ,חריש ,325 ,ערבה
1247 ,חריש ,347 ,ערבות
1247 ,חריש ,328 ,צאלון
1247 ,חריש ,334 ,צבעוני
1247 ,חריש ,351 ,קידמה
1247 ,חריש ,314 ,רובין
1247 ,חריש ,106 ,רימון
1247 ,חריש ,345 ,רעות
1247 ,חריש ,339 ,רקפת
1247 ,חריש ,330 ,שדרות האחווה
1247 ,חריש ,340 ,שדרות ההגשמה
1247 ,חריש ,304 ,שוהם
1247 ,חריש ,346 ,שותפות
1247 ,חריש ,6514 ,שכונת בצוותא
1247 ,חריש ,6512 ,שכונת הפרחים
1247 ,חריש ,6515 ,שכונת מעו"ף
1247 ,חריש ,329 ,שקד
1247 ,חריש ,320 ,תאנה
1247 ,חריש ,114 ,תמנע
1247 ,חריש ,120 ,תמר
1247 ,חריש ,355 ,תנופה
3717 ,חרמש ,9000 ,חרמש
1209 ,חרשים ,102 ,הדולינה
1209 ,חרשים ,104 ,הטאבון
1209 ,חרשים ,106 ,הסלמנדרה
1209 ,חרשים ,105 ,הענן
1209 ,חרשים ,103 ,הקטלב
1209 ,חרשים ,9000 ,חרשים
1209 ,חרשים ,101 ,כליל החורש
1209 ,חרשים ,107 ,שביל הגנים
3770 ,חשמונאים ,124 ,ברקת
3770 ,חשמונאים ,105 ,הארבל
3770 ,חשמונאים ,108 ,הגפן
3770 ,חשמונאים ,132 ,הדגן
3770 ,חשמונאים ,133 ,הדקל
3770 ,חשמונאים ,101 ,הזית
3770 ,חשמונאים ,107 ,החרמון
3770 ,חשמונאים ,114 ,היצהר
3770 ,חשמונאים ,110 ,הכרמל
3770 ,חשמונאים ,104 ,הלבונה
3770 ,חשמונאים ,111 ,המור
3770 ,חשמונאים ,112 ,הצור
3770 ,חשמונאים ,121 ,הר נבו
3770 ,חשמונאים ,109 ,הרימון
3770 ,חשמונאים ,102 ,התאנה
3770 ,חשמונאים ,103 ,התבור
3770 ,חשמונאים ,106 ,התירוש
3770 ,חשמונאים ,116 ,התמר
3770 ,חשמונאים ,9000 ,חשמונאים
3770 ,חשמונאים ,126 ,יהלום
3770 ,חשמונאים ,129 ,מוריה
3770 ,חשמונאים ,127 ,מעלה הגלבוע
3770 ,חשמונאים ,122 ,מעלה מירון
3770 ,חשמונאים ,117 ,משעול הכרמים
3770 ,חשמונאים ,118 ,משעול השקמים
3770 ,חשמונאים ,113 ,נטף
3770 ,חשמונאים ,119 ,עץ הדר
3770 ,חשמונאים ,120 ,עצמון
3770 ,חשמונאים ,115 ,ערבי נחל
3770 ,חשמונאים ,6500 ,רמת מודיעין
3770 ,חשמונאים ,128 ,שוהם
3770 ,חשמונאים ,123 ,שיאון
3770 ,חשמונאים ,130 ,שניר
3770 ,חשמונאים ,125 ,תרשיש
6700 ,טבריה ,440 ,אבו חצירה
6700 ,טבריה ,481 ,אבולעפיה
6700 ,טבריה ,117 ,אדרעי מאיר
6700 ,טבריה ,435 ,אהבת ציון
6700 ,טבריה ,115 ,אהל יעקב
6700 ,טבריה ,310 ,אוסישקין
6700 ,טבריה ,482 ,אור חיים
6700 ,טבריה ,469 ,אורנים
6700 ,טבריה ,6538 ,אזור תעשיה ומלאכה
6700 ,טבריה ,6537 ,אזור תעשיה רמת אגוז
6700 ,טבריה ,101 ,אחד העם
6700 ,טבריה ,301 ,אחוה
6700 ,טבריה ,113 ,אחוזת בית
6700 ,טבריה ,132 ,איחוד הצלה
6700 ,טבריה ,500 ,איילה
6700 ,טבריה ,236 ,אילת
6700 ,טבריה ,517 ,אירוסים
6700 ,טבריה ,521 ,אלה
6700 ,טבריה ,277 ,אלון
6700 ,טבריה ,483 ,אלון יגאל
6700 ,טבריה ,131 ,אלונים
6700 ,טבריה ,202 ,אלחדיף
6700 ,טבריה ,462 ,אלעזר דוד
6700 ,טבריה ,442 ,אלפסי
6700 ,טבריה ,188 ,אמנון ותמר
6700 ,טבריה ,195 ,אנפה
6700 ,טבריה ,355 ,אסתר המלכה
6700 ,טבריה ,136 ,אצ"ל
6700 ,טבריה ,239 ,ארז
6700 ,טבריה ,484 ,ארזים
6700 ,טבריה ,165 ,ארליך
6700 ,טבריה ,140 ,בהלול ציון
6700 ,טבריה ,110 ,ביאליק
6700 ,טבריה ,304 ,ביבאס
6700 ,טבריה ,485 ,בית הלל
6700 ,טבריה ,130 ,בית חולים
6700 ,טבריה ,468 ,בן אליעזר אריה
6700 ,טבריה ,235 ,בן זכאי
6700 ,טבריה ,444 ,בן צבי יצחק
6700 ,טבריה ,129 ,בר גיורא
6700 ,טבריה ,323 ,בר כוכבא
6700 ,טבריה ,192 ,ברוך בן טובים
6700 ,טבריה ,127 ,ברוריה
6700 ,טבריה ,309 ,ברנר
6700 ,טבריה ,451 ,ברץ
6700 ,טבריה ,134 ,גולדה מאיר
6700 ,טבריה ,109 ,גולומב
6700 ,טבריה ,124 ,גולני
6700 ,טבריה ,107 ,גורדון
6700 ,טבריה ,203 ,גוש חלב
6700 ,טבריה ,125 ,גוש עציון
6700 ,טבריה ,164 ,גינוסר
6700 ,טבריה ,199 ,דבורה הנביאה
6700 ,טבריה ,135 ,דגניה
6700 ,טבריה ,102 ,דהאן שמעון
6700 ,טבריה ,501 ,דובדבן
6700 ,טבריה ,184 ,דוד דנילוף
6700 ,טבריה ,325 ,דוד המלך
6700 ,טבריה ,252 ,דון יוסף
6700 ,טבריה ,243 ,דונה גרציה
6700 ,טבריה ,204 ,דרך גדוד ברק
6700 ,טבריה ,328 ,דרך הגבורה
6700 ,טבריה ,313 ,דרך הציונות
6700 ,טבריה ,275 ,דרך מנחם בגין
6700 ,טבריה ,290 ,דרך מר"ן
6700 ,טבריה ,180 ,דרך רונן
6700 ,טבריה ,438 ,האבות
6700 ,טבריה ,145 ,האמהות
6700 ,טבריה ,212 ,האר"י
6700 ,טבריה ,480 ,הבורג
6700 ,טבריה ,246 ,הבנים
6700 ,טבריה ,142 ,הגדוד העברי
6700 ,טבריה ,181 ,הגיבורים
6700 ,טבריה ,215 ,הגלבוע
6700 ,טבריה ,223 ,הגליל
6700 ,טבריה ,486 ,הגפן
6700 ,טבריה ,467 ,הדס
6700 ,טבריה ,511 ,הדקל
6700 ,טבריה ,108 ,הוז דב
6700 ,טבריה ,242 ,הופיין
6700 ,טבריה ,216 ,הורדוס
6700 ,טבריה ,260 ,הזית
6700 ,טבריה ,496 ,החרושת
6700 ,טבריה ,247 ,החרמון
6700 ,טבריה ,318 ,החשמונאים
6700 ,טבריה ,478 ,החשמל
6700 ,טבריה ,237 ,הים
6700 ,טבריה ,207 ,הירדן
6700 ,טבריה ,220 ,הירקון
6700 ,טבריה ,274 ,הכרם
6700 ,טבריה ,474 ,המאור
6700 ,טבריה ,493 ,המברג
6700 ,טבריה ,121 ,המגינים
6700 ,טבריה ,191 ,המטבעות
6700 ,טבריה ,487 ,המכבים
6700 ,טבריה ,488 ,המלאכה
6700 ,טבריה ,494 ,המשור
6700 ,טבריה ,230 ,המשטרה
6700 ,טבריה ,227 ,המשיח
6700 ,טבריה ,436 ,הנביאים
6700 ,טבריה ,305 ,הנוטר
6700 ,טבריה ,222 ,הנוצרים
6700 ,טבריה ,477 ,הניאון
6700 ,טבריה ,267 ,הנצחון
6700 ,טבריה ,518 ,הנרקיס
6700 ,טבריה ,445 ,הנשיא ויצמן
6700 ,טבריה ,6540 ,העיר העתיקה
6700 ,טבריה ,254 ,העמק
6700 ,טבריה ,214 ,העמקים
6700 ,טבריה ,185 ,העצמאות
6700 ,טבריה ,201 ,הפלמ"ח
6700 ,טבריה ,495 ,הפנס
6700 ,טבריה ,186 ,הפרחים
6700 ,טבריה ,123 ,הצנחנים
6700 ,טבריה ,455 ,הקדושים
6700 ,טבריה ,221 ,הקישון
6700 ,טבריה ,463 ,הקרן
6700 ,טבריה ,316 ,הר השקדים
6700 ,טבריה ,291 ,הרב אליישיב
6700 ,טבריה ,133 ,הרב ועקנין
6700 ,טבריה ,206 ,הרב חיים סמדג'א
6700 ,טבריה ,229 ,הרב חיים שפר
6700 ,טבריה ,245 ,הרב כהן משה
6700 ,טבריה ,200 ,הרב מסעוד מלול
6700 ,טבריה ,443 ,הרב עוזיאל
6700 ,טבריה ,489 ,הרב קוק
6700 ,טבריה ,183 ,הרב שך
6700 ,טבריה ,497 ,הרצוג
6700 ,טבריה ,103 ,השומר
6700 ,טבריה ,324 ,השופטים
6700 ,טבריה ,196 ,השחף
6700 ,טבריה ,217 ,השילוח
6700 ,טבריה ,205 ,השל"ה
6700 ,טבריה ,232 ,השלום
6700 ,טבריה ,269 ,התאנה
6700 ,טבריה ,208 ,התבור
6700 ,טבריה ,251 ,התנא נחום
6700 ,טבריה ,218 ,התנאים
6700 ,טבריה ,213 ,התעשיה
6700 ,טבריה ,143 ,וינגייט
6700 ,טבריה ,120 ,ורנר
6700 ,טבריה ,118 ,ז'בוטינסקי
6700 ,טבריה ,128 ,זיידל
6700 ,טבריה ,271 ,חבצלת
6700 ,טבריה ,144 ,חברון
6700 ,טבריה ,472 ,חוף גיא
6700 ,טבריה ,453 ,חזון איש
6700 ,טבריה ,452 ,חייא ובניו
6700 ,טבריה ,502 ,חלמונית
6700 ,טבריה ,503 ,חצב
6700 ,טבריה ,513 ,חרצית
6700 ,טבריה ,137 ,טבור הארץ
6700 ,טבריה ,9000 ,טבריה
6700 ,טבריה ,6535 ,טבריה עילית
6700 ,טבריה ,244 ,טולידנו
6700 ,טבריה ,248 ,טיילת יגאל אלון
6700 ,טבריה ,272 ,טל
6700 ,טבריה ,464 ,טמסות חיים
6700 ,טבריה ,311 ,טרומפלדור
6700 ,טבריה ,234 ,יהודה הלוי
6700 ,טבריה ,112 ,יהודה הנשיא
6700 ,טבריה ,233 ,יוסיפוס
6700 ,טבריה ,522 ,יערה
6700 ,טבריה ,447 ,יפה נוף
6700 ,טבריה ,430 ,יפתח הגלעדי
6700 ,טבריה ,448 ,ירושלים
6700 ,טבריה ,187 ,ישעיהו
6700 ,טבריה ,454 ,כהנא
6700 ,טבריה ,490 ,כוכב
6700 ,טבריה ,119 ,כי"ח
6700 ,טבריה ,6002 ,ככר העצמאות
6700 ,טבריה ,6001 ,ככר ז'בוטינסקי
6700 ,טבריה ,498 ,כליל החורש
6700 ,טבריה ,466 ,כלנית
6700 ,טבריה ,515 ,כרכום
6700 ,טבריה ,178 ,לבנה
6700 ,טבריה ,473 ,לוטם
6700 ,טבריה ,479 ,לוי אשכול
6700 ,טבריה ,268 ,לולב
6700 ,טבריה ,151 ,לח"י
6700 ,טבריה ,189 ,לימונית
6700 ,טבריה ,369 ,מבצע אביב נעורים
6700 ,טבריה ,358 ,מבצע בן עמי
6700 ,טבריה ,359 ,מבצע ברוש
6700 ,טבריה ,360 ,מבצע דני
6700 ,טבריה ,365 ,מבצע חולה
6700 ,טבריה ,361 ,מבצע חורב
6700 ,טבריה ,370 ,מבצע כחול וחום
6700 ,טבריה ,366 ,מבצע כחל
6700 ,טבריה ,357 ,מבצע מכבי
6700 ,טבריה ,356 ,מבצע נחשון
6700 ,טבריה ,367 ,מבצע נקר
6700 ,טבריה ,368 ,מבצע סבנה
6700 ,טבריה ,363 ,מבצע סנונית
6700 ,טבריה ,362 ,מבצע עובדה
6700 ,טבריה ,401 ,מבצע צוק איתן
6700 ,טבריה ,364 ,מבצע שלדג
6700 ,טבריה ,270 ,מגלן
6700 ,טבריה ,147 ,מודיעין
6700 ,טבריה ,273 ,מור
6700 ,טבריה ,6533 ,מורדות טבריה
6700 ,טבריה ,177 ,מורן
6700 ,טבריה ,146 ,מיכאל
6700 ,טבריה ,470 ,מינו
6700 ,טבריה ,104 ,מלון ארנון
6700 ,טבריה ,138 ,מלון וושינגטון
6700 ,טבריה ,141 ,מלון טבריה
6700 ,טבריה ,475 ,מלון רסיטל
6700 ,טבריה ,163 ,מסדה
6700 ,טבריה ,512 ,מעלה קק"ל
6700 ,טבריה ,327 ,מצדה
6700 ,טבריה ,240 ,מצפה כנרת
6700 ,טבריה ,514 ,מרווה
6700 ,טבריה ,238 ,מרכז בעלי מלאכה
6700 ,טבריה ,114 ,מרכז קליטה
6700 ,טבריה ,431 ,נבון יצחק
6700 ,טבריה ,432 ,נבות הכרמלי
6700 ,טבריה ,6532 ,נוף כנרת
6700 ,טבריה ,516 ,נורית
6700 ,טבריה ,450 ,נחמיה
6700 ,טבריה ,167 ,נחמני
6700 ,טבריה ,111 ,ניברג
6700 ,טבריה ,504 ,סביונים
6700 ,טבריה ,179 ,סייפן
6700 ,טבריה ,441 ,סיני
6700 ,טבריה ,523 ,סמ ציפורי
6700 ,טבריה ,459 ,סנש חנה
6700 ,טבריה ,449 ,עזרא
6700 ,טבריה ,465 ,עטר חיים
6700 ,טבריה ,429 ,עלית
6700 ,טבריה ,193 ,עפרוני
6700 ,טבריה ,303 ,עצמון
6700 ,טבריה ,330 ,פועלי ציון
6700 ,טבריה ,307 ,פיק"א
6700 ,טבריה ,510 ,צאלון
6700 ,טבריה ,476 ,צבעונית
6700 ,טבריה ,211 ,ציפורי
6700 ,טבריה ,161 ,צפת
6700 ,טבריה ,190 ,קורנית
6700 ,טבריה ,520 ,קיסוס
6700 ,טבריה ,139 ,קפלנסקי
6700 ,טבריה ,6531 ,קרית משה
6700 ,טבריה ,6501 ,קרית צאנז
6700 ,טבריה ,197 ,רבי יוסף קארו
6700 ,טבריה ,225 ,רבי מאיר
6700 ,טבריה ,336 ,רוטשילד
6700 ,טבריה ,505 ,רותם
6700 ,טבריה ,460 ,רזיאל דוד
6700 ,טבריה ,331 ,רח 110
6700 ,טבריה ,226 ,רח 411
6700 ,טבריה ,353 ,רח 522
6700 ,טבריה ,492 ,רח 503
6700 ,טבריה ,332 ,רח 115
6700 ,טבריה ,354 ,רח 526
6700 ,טבריה ,471 ,רח 539
6700 ,טבריה ,276 ,רחבעם זאבי (גנדי)
6700 ,טבריה ,126 ,רחל
6700 ,טבריה ,329 ,רמח"ל
6700 ,טבריה ,6530 ,רמת אגוז
6700 ,טבריה ,6502 ,רמת בן גוריון
6700 ,טבריה ,6503 ,רמת טבריה
6700 ,טבריה ,6504 ,רמת כנרת
6700 ,טבריה ,456 ,רקפת
6700 ,טבריה ,308 ,רש"י
6700 ,טבריה ,6505 ,ש הרמבם
6700 ,טבריה ,6506 ,ש ותיקים
6700 ,טבריה ,6507 ,ש חסכון ד
6700 ,טבריה ,6508 ,ש מווארס
6700 ,טבריה ,6500 ,ש נוה חן
6700 ,טבריה ,6509 ,ש סולל בונה
6700 ,טבריה ,6510 ,ש עובדים
6700 ,טבריה ,6511 ,ש עממי
6700 ,טבריה ,6512 ,ש עץ חיים
6700 ,טבריה ,6513 ,ש פלוס 200
6700 ,טבריה ,6514 ,ש ציונים כלליים
6700 ,טבריה ,6515 ,ש קליפורניה
6700 ,טבריה ,6516 ,ש רמז
6700 ,טבריה ,446 ,שבטי ישראל
6700 ,טבריה ,499 ,שביט
6700 ,טבריה ,266 ,שביל הכפיר
6700 ,טבריה ,259 ,שביל הלביא
6700 ,טבריה ,458 ,שד אלנטאון
6700 ,טבריה ,116 ,שד הרצל
6700 ,טבריה ,457 ,שד ספיר
6700 ,טבריה ,250 ,שד קפלן אליעזר
6700 ,טבריה ,105 ,שד קק"ל
6700 ,טבריה ,106 ,שדה יצחק
6700 ,טבריה ,461 ,שז"ר זלמן
6700 ,טבריה ,506 ,שיזף
6700 ,טבריה ,507 ,שיטה
6700 ,טבריה ,326 ,שיטרית
6700 ,טבריה ,6536 ,שכ אחווה
6700 ,טבריה ,6517 ,שכ אחוזת כנרת
6700 ,טבריה ,6518 ,שכ גני חמת
6700 ,טבריה ,6519 ,שכ דון יוסף הנשיא
6700 ,טבריה ,6520 ,שכ ההר
6700 ,טבריה ,6521 ,שכ מימוניה
6700 ,טבריה ,6522 ,שכ מנורה
6700 ,טבריה ,6523 ,שכ נוה הדר
6700 ,טבריה ,6524 ,שכ קרית שמואל
6700 ,טבריה ,6525 ,שכ רבי עקיבא
6700 ,טבריה ,6526 ,שכ רווקיה
6700 ,טבריה ,6541 ,שכ רמת טבריה א'
6700 ,טבריה ,6542 ,שכ רמת טבריה ג'
6700 ,טבריה ,6539 ,שכ שרת
6700 ,טבריה ,6528 ,שכ' בית וגן
6700 ,טבריה ,6527 ,שכון א
6700 ,טבריה ,6529 ,שכון ג
6700 ,טבריה ,219 ,שכם
6700 ,טבריה ,194 ,שלדג
6700 ,טבריה ,322 ,שלמה המלך
6700 ,טבריה ,198 ,שמואל הנגיד
6700 ,טבריה ,437 ,שפירא משה
6700 ,טבריה ,400 ,שפע חיים
6700 ,טבריה ,231 ,שפת הים
6700 ,טבריה ,508 ,שקד
6700 ,טבריה ,439 ,שרת משה
6700 ,טבריה ,241 ,תג'ר
6700 ,טבריה ,182 ,תהילה
6700 ,טבריה ,122 ,תל חי
6700 ,טבריה ,519 ,תלתן
6700 ,טבריה ,509 ,תמר
962 ,טובא-זנגריה ,122 ,אבו בקר אלסדיק
962 ,טובא-זנגריה ,113 ,אבן ח'לדון
962 ,טובא-זנגריה ,118 ,אבן סינא
962 ,טובא-זנגריה ,155 ,אל-ח'נסאא
962 ,טובא-זנגריה ,149 ,אל-נח'ל
962 ,טובא-זנגריה ,151 ,אל-רביע
962 ,טובא-זנגריה ,152 ,אל-רומאן
962 ,טובא-זנגריה ,107 ,אלאמל
962 ,טובא-זנגריה ,108 ,אלאנדלוס
962 ,טובא-זנגריה ,136 ,אלאסיל
962 ,טובא-זנגריה ,126 ,אלאקסא
962 ,טובא-זנגריה ,112 ,אלביאדר
962 ,טובא-זנגריה ,124 ,אלג'ולאן
962 ,טובא-זנגריה ,129 ,אלג'ליל
962 ,טובא-זנגריה ,150 ,אלגוזלאן
962 ,טובא-זנגריה ,106 ,אלהודא
962 ,טובא-זנגריה ,145 ,אלהייב
962 ,טובא-זנגריה ,141 ,אלוורוד
962 ,טובא-זנגריה ,140 ,אלזוהור
962 ,טובא-זנגריה ,114 ,אלזייתון
962 ,טובא-זנגריה ,121 ,אלח'טאב
962 ,טובא-זנגריה ,142 ,אלח'ליל
962 ,טובא-זנגריה ,157 ,אלח'רוב
962 ,טובא-זנגריה ,133 ,אלחולה
962 ,טובא-זנגריה ,104 ,אלחסיד
962 ,טובא-זנגריה ,120 ,אלכרום
962 ,טובא-זנגריה ,137 ,אלמג'ד
962 ,טובא-זנגריה ,154 ,אלמותנבי
962 ,טובא-זנגריה ,147 ,אלמנארה
962 ,טובא-זנגריה ,111 ,אלנוורס
962 ,טובא-זנגריה ,102 ,אלנור
962 ,טובא-זנגריה ,148 ,אלנרג'ס
962 ,טובא-זנגריה ,115 ,אלסלאם
962 ,טובא-זנגריה ,153 ,אלסנדיאן
962 ,טובא-זנגריה ,143 ,אלספא
962 ,טובא-זנגריה ,158 ,אלעין
962 ,טובא-זנגריה ,138 ,אלפארוק
962 ,טובא-זנגריה ,117 ,אלקודס
962 ,טובא-זנגריה ,135 ,אלרחמה
962 ,טובא-זנגריה ,105 ,אלריחאנה
962 ,טובא-זנגריה ,132 ,אלרשיד
962 ,טובא-זנגריה ,123 ,אלשאם
962 ,טובא-זנגריה ,160 ,אלשאפעי
962 ,טובא-זנגריה ,116 ,אלשייך
962 ,טובא-זנגריה ,130 ,בילאל
962 ,טובא-זנגריה ,119 ,בניאס
962 ,טובא-זנגריה ,159 ,ג'בראן ח'ליל ג'בראן
962 ,טובא-זנגריה ,110 ,ח'אלד בן אלוליד
962 ,טובא-זנגריה ,146 ,חאתם אלטאאי
962 ,טובא-זנגריה ,109 ,חמזה
962 ,טובא-זנגריה ,9000 ,טובא-זנגריה
962 ,טובא-זנגריה ,144 ,יגאל אלון
962 ,טובא-זנגריה ,131 ,מורג'אן
962 ,טובא-זנגריה ,127 ,מכה
962 ,טובא-זנגריה ,156 ,נהר אלאורדן
962 ,טובא-זנגריה ,125 ,סלאח אלדין
962 ,טובא-זנגריה ,134 ,עומר אלמוכתאר
962 ,טובא-זנגריה ,128 ,עות'מאן
962 ,טובא-זנגריה ,139 ,עין טובא
498 ,טורעאן ,132 ,אבו בכר
498 ,טורעאן ,152 ,אבן סינא
498 ,טורעאן ,171 ,אבן רושד
498 ,טורעאן ,159 ,אלאמל
498 ,טורעאן ,164 ,אלאנדלוס
498 ,טורעאן ,111 ,אלאנואר
498 ,טורעאן ,117 ,אלביאדר
498 ,טורעאן ,141 ,אלבלאט
498 ,טורעאן ,112 ,אלבלוט
498 ,טורעאן ,149 ,אלבסאתין
498 ,טורעאן ,170 ,אלג'בל
498 ,טורעאן ,124 ,אלג'זאלי
498 ,טורעאן ,173 ,אלדואויר
498 ,טורעאן ,177 ,אלדלה
498 ,טורעאן ,175 ,אלדמנה
498 ,טורעאן ,146 ,אלהודא
498 ,טורעאן ,131 ,אלואדי
498 ,טורעאן ,103 ,אלווקף
498 ,טורעאן ,125 ,אלזהראא
498 ,טורעאן ,121 ,אלזיתון
498 ,טורעאן ,134 ,אלזקאק
498 ,טורעאן ,129 ,אלח'רוב
498 ,טורעאן ,145 ,אלחאקורה
498 ,טורעאן ,115 ,אלחדיקה
498 ,טורעאן ,150 ,אלטבראני
498 ,טורעאן ,167 ,אלכורום
498 ,טורעאן ,144 ,אלכרם
498 ,טורעאן ,169 ,אלמולוק
498 ,טורעאן ,156 ,אלמותנבי
498 ,טורעאן ,119 ,אלמחבה
498 ,טורעאן ,123 ,אלמטל
498 ,טורעאן ,153 ,אלמלק
498 ,טורעאן ,113 ,אלמנאשר
498 ,טורעאן ,136 ,אלמנזול
498 ,טורעאן ,139 ,אלמסאקב
498 ,טורעאן ,138 ,אלמעסרה
498 ,טורעאן ,127 ,אלמקיל
498 ,טורעאן ,106 ,אלמתנזה
498 ,טורעאן ,114 ,אלנרג'ס
498 ,טורעאן ,148 ,אלסוק
498 ,טורעאן ,174 ,אלסלם
498 ,טורעאן ,109 ,אלסנדיאן
498 ,טורעאן ,116 ,אלספא
498 ,טורעאן ,110 ,אלסריס
498 ,טורעאן ,166 ,אלעודה
498 ,טורעאן ,162 ,אלעולא
498 ,טורעאן ,122 ,אלעסיליאת
498 ,טורעאן ,163 ,אלפאראבי
498 ,טורעאן ,135 ,אלפארוק
498 ,טורעאן ,161 ,אלפרדוס
498 ,טורעאן ,151 ,אלפרח
498 ,טורעאן ,102 ,אלקודס
498 ,טורעאן ,168 ,אלקנדול
498 ,טורעאן ,158 ,אלרביע
498 ,טורעאן ,104 ,אלרוודה
498 ,טורעאן ,120 ,אלרום
498 ,טורעאן ,157 ,אלריחאן
498 ,טורעאן ,143 ,אלרמאנה
498 ,טורעאן ,128 ,אלרשיד
498 ,טורעאן ,126 ,אלשאפעי
498 ,טורעאן ,137 ,אלשיח' פרג'
498 ,טורעאן ,155 ,אלתותה
498 ,טורעאן ,142 ,אסלאם
498 ,טורעאן ,165 ,בירות
498 ,טורעאן ,118 ,דואר אלפלק
498 ,טורעאן ,108 ,דרב אלברג'
498 ,טורעאן ,176 ,דרב אלחטיניה
498 ,טורעאן ,133 ,דרב אלכניסה
498 ,טורעאן ,160 ,דרב אלמסרב
498 ,טורעאן ,140 ,דרב אלנאסרה
498 ,טורעאן ,147 ,דרב אלסראר
498 ,טורעאן ,130 ,דרב עכא
498 ,טורעאן ,9000 ,טורעאן
498 ,טורעאן ,105 ,מחמוד דרויש
498 ,טורעאן ,172 ,סידי סלימאן
498 ,טורעאן ,107 ,סלאח אלדין
498 ,טורעאן ,154 ,עוטרא
498 ,טורעאן ,6731 ,שכ אלדלהם
498 ,טורעאן ,6724 ,שכ אלחמראא'
498 ,טורעאן ,6732 ,שכ אלטבראני
498 ,טורעאן ,6720 ,שכ אלכרום אלג'רביה
498 ,טורעאן ,6734 ,שכ אלמליק
498 ,טורעאן ,6711 ,שכ אלמנאשר
498 ,טורעאן ,6714 ,שכ אלמנזלה
498 ,טורעאן ,6722 ,שכ אלסראר
498 ,טורעאן ,6712 ,שכ אלעיסיליאת
498 ,טורעאן ,6713 ,שכ אלעמארה
498 ,טורעאן ,6723 ,שכ אלרמאנה
498 ,טורעאן ,6733 ,שכ אלתותי
498 ,טורעאן ,6730 ,שכ בית אלרסיף
498 ,טורעאן ,6735 ,שכ ד'הר אלשעירה
498 ,טורעאן ,6710 ,שכ ואדי אלעין
498 ,טורעאן ,6725 ,שכ ווסט אלבלד
498 ,טורעאן ,6721 ,שכ קבר אלעבד
2730 ,טייבה ,6710 ,אבו אל עקארב
2730 ,טייבה ,6760 ,אזור הקאנטרי
2730 ,טייבה ,6781 ,אזור תעשייה
2730 ,טייבה ,6720 ,אלבאטן
2730 ,טייבה ,6721 ,אלח'לה אלשמאליה
2730 ,טייבה ,6773 ,אלסלילמה
2730 ,טייבה ,6770 ,אלסמכה
2730 ,טייבה ,6730 ,אלקינה-שכונה מזרחית
2730 ,טייבה ,6750 ,אלראס
2730 ,טייבה ,6771 ,אלשל
2730 ,טייבה ,6712 ,אתר אשפה מזרחי
2730 ,טייבה ,6782 ,ג'למה
2730 ,טייבה ,6740 ,גרעין-העיר העתיקה
2730 ,טייבה ,6774 ,ואד אלאסד
2730 ,טייבה ,6772 ,ואד חמדאן
2730 ,טייבה ,9000 ,טייבה
2730 ,טייבה ,6761 ,כרם אלג'באלי המזרחי
2730 ,טייבה ,6762 ,כרם אלג'באלי המערבי
2730 ,טייבה ,6711 ,פרדיסיה
2730 ,טייבה ,6713 ,רוק
2730 ,טייבה ,6780 ,שכונת הבדואים
497 ,טייבה (בעמק) ,9000 ,טייבה (בעמק)
2720 ,טירה ,197 ,א-ד'הב
2720 ,טירה ,323 ,א-דואלי
2720 ,טירה ,137 ,א-דוחא
2720 ,טירה ,250 ,א-דורי
2720 ,טירה ,318 ,א-דינאוורי
2720 ,טירה ,254 ,א-זאנבק
2720 ,טירה ,253 ,א-זאעפראן
2720 ,טירה ,252 ,א-זאעתר
2720 ,טירה ,387 ,א-זבידייאת
2720 ,טירה ,116 ,א-זהראא
2720 ,טירה ,319 ,א-זהראווי
2720 ,טירה ,386 ,א-זהראניאת
2720 ,טירה ,255 ,א-זוהור
2720 ,טירה ,234 ,א-זייתון
2720 ,טירה ,398 ,א-טאבון
2720 ,טירה ,320 ,א-טברי
2720 ,טירה ,373 ,א-טור
2720 ,טירה ,347 ,א-לוד
2720 ,טירה ,268 ,א-ליימון
2720 ,טירה ,188 ,א-נאהדה
2720 ,טירה ,271 ,א-נאוורס
2720 ,טירה ,368 ,א-נאס'ירה
2720 ,טירה ,269 ,א-נבעה
2720 ,טירה ,138 ,א-נג'אח
2720 ,טירה ,166 ,א-נוג'ום
2720 ,טירה ,143 ,א-נור
2720 ,טירה ,270 ,א-נח'יל
2720 ,טירה ,171 ,א-נידאל
2720 ,טירה ,177 ,א-נס'ר
2720 ,טירה ,370 ,א-נקב
2720 ,טירה ,236 ,א-נרג'יס
2720 ,טירה ,119 ,א-ס'בר
2720 ,טירה ,366 ,א-ס'בראת
2720 ,טירה ,262 ,א-ס'נאוובר
2720 ,טירה ,200 ,א-ס'פא
2720 ,טירה ,261 ,א-ס'פסאף
2720 ,טירה ,259 ,א-סאווסן
2720 ,טירה ,256 ,א-סאנאבל
2720 ,טירה ,223 ,א-סאראיא
2720 ,טירה ,258 ,א-סונונו
2720 ,טירה ,394 ,א-סוק
2720 ,טירה ,257 ,א-סינדיאן
2720 ,טירה ,199 ,א-סיראג'
2720 ,טירה ,135 ,א-סלאם
2720 ,טירה ,190 ,א-סעאדה
2720 ,טירה ,163 ,א-ראוודה
2720 ,טירה ,128 ,א-ראזי
2720 ,טירה ,153 ,א-רביע
2720 ,טירה ,192 ,א-רג'אא
2720 ,טירה ,359 ,א-רוזייה
2720 ,טירה ,360 ,א-רוחה
2720 ,טירה ,233 ,א-רומאן
2720 ,טירה ,282 ,א-רחיק
2720 ,טירה ,164 ,א-רייאן
2720 ,טירה ,251 ,א-רייחאן
2720 ,טירה ,346 ,א-רמלה
2720 ,טירה ,260 ,א-שאהין
2720 ,טירה ,361 ,א-שאם
2720 ,טירה ,289 ,א-שאפיעי
2720 ,טירה ,141 ,א-שהיד מחמוד א-שארה
2720 ,טירה ,155 ,א-שוהדא
2720 ,טירה ,114 ,א-שורוק
2720 ,טירה ,165 ,א-שמס
2720 ,טירה ,152 ,א-תופאח
2720 ,טירה ,237 ,א-תות
2720 ,טירה ,232 ,א-תין
2720 ,טירה ,389 ,א-תלול
2720 ,טירה ,243 ,א-תמר
2720 ,טירה ,104 ,אבו בכר א-ס'דיק
2720 ,טירה ,292 ,אבו הוריירה
2720 ,טירה ,324 ,אבו חניפה
2720 ,טירה ,291 ,אבו מוסא אל-אשערי
2720 ,טירה ,6751 ,אבו סלאח
2720 ,טירה ,118 ,אבו עוביידה
2720 ,טירה ,310 ,אבן א-נפיס
2720 ,טירה ,308 ,אבן אל-את'יר
2720 ,טירה ,325 ,אבן אל-ביטאר
2720 ,טירה ,311 ,אבן אל-היית'ם
2720 ,טירה ,309 ,אבן אל-קיים
2720 ,טירה ,103 ,אבן בטוטה
2720 ,טירה ,101 ,אבן ח'לדון
2720 ,טירה ,125 ,אבן חאן
2720 ,טירה ,312 ,אבן חזם אל-אנדלוסי
2720 ,טירה ,108 ,אבן חנבל
2720 ,טירה ,313 ,אבן כת'יר
2720 ,טירה ,286 ,אבן מאליכ
2720 ,טירה ,285 ,אבן סינא
2720 ,טירה ,102 ,אבן רושד
2720 ,טירה ,284 ,אבן תיימייה
2720 ,טירה ,134 ,אד'אר
2720 ,טירה ,204 ,אוחוד
2720 ,טירה ,293 ,אוסאמה בן זייד
2720 ,טירה ,508 ,אחמד אבו ח'יט
2720 ,טירה ,528 ,אחמד אלחוראני
2720 ,טירה ,511 ,אחמד וח'ליל כיכאן
2720 ,טירה ,512 ,אחמד חאמד דעאס
2720 ,טירה ,516 ,אחמד סעיד קשוע
2720 ,טירה ,521 ,אחמד עיראקי
2720 ,טירה ,326 ,אחמד שאווקי
2720 ,טירה ,196 ,איאר
2720 ,טירה ,307 ,איבראהים טוקאן
2720 ,טירה ,180 ,איילול
2720 ,טירה ,122 ,אל- פירדאוס
2720 ,טירה ,315 ,אל-אדריסי
2720 ,טירה ,240 ,אל-אוקחוואן
2720 ,טירה ,238 ,אל-אורג'וואן
2720 ,טירה ,136 ,אל-אחלאם
2720 ,טירה ,146 ,אל-אחראר
2720 ,טירה ,139 ,אל-איחסאן
2720 ,טירה ,105 ,אל-איסראא
2720 ,טירה ,348 ,אל-איעדאדייה
2720 ,טירה ,147 ,אל-אמאנה
2720 ,טירה ,148 ,אל-אמל
2720 ,טירה ,154 ,אל-אנוואר
2720 ,טירה ,149 ,אל-אנס'אר
2720 ,טירה ,316 ,אל-אס'מעי
2720 ,טירה ,341 ,אל-אקס'א
2720 ,טירה ,120 ,אל-ארד
2720 ,טירה ,239 ,אל-ארז
2720 ,טירה ,354 ,אל-באניאס
2720 ,טירה ,241 ,אל-באשיק
2720 ,טירה ,157 ,אל-בהג'ה
2720 ,טירה ,184 ,אל-בוסתאן
2720 ,טירה ,158 ,אל-בוראק
2720 ,טירה ,221 ,אל-בורדה
2720 ,טירה ,278 ,אל-בורתוקאל
2720 ,טירה ,378 ,אל-בטוף
2720 ,טירה ,342 ,אל-ביאדר
2720 ,טירה ,343 ,אל-בייארה
2720 ,טירה ,317 ,אל-ביירוני
2720 ,טירה ,391 ,אל-ביר
2720 ,טירה ,279 ,אל-בלוט
2720 ,טירה ,242 ,אל-בנפסג'
2720 ,טירה ,355 ,אל-בס'רה
2720 ,טירה ,247 ,אל-ברקוק
2720 ,טירה ,396 ,אל-ג'וב
2720 ,טירה ,159 ,אל-ג'וד
2720 ,טירה ,246 ,אל-ג'וז
2720 ,טירה ,357 ,אל-ג'ולאן
2720 ,טירה ,244 ,אל-ג'ומיז
2720 ,טירה ,126 ,אל-ג'ומעה
2720 ,טירה ,245 ,אל-ג'ורי
2720 ,טירה ,356 ,אל-ג'זאר
2720 ,טירה ,344 ,אל-ג'ליל
2720 ,טירה ,195 ,אל-ג'סר
2720 ,טירה ,372 ,אל-ג'רמק
2720 ,טירה ,178 ,אל-הודא
2720 ,טירה ,187 ,אל-הילאל
2720 ,טירה ,179 ,אל-וואחה
2720 ,טירה ,273 ,אל-וורוד
2720 ,טירה ,272 ,אל-וורוואר
2720 ,טירה ,288 ,אל-ח'וואריזמי
2720 ,טירה ,345 ,אל-ח'ליל
2720 ,טירה ,156 ,אל-ח'מיס
2720 ,טירה ,287 ,אל-ח'נסאא
2720 ,טירה ,249 ,אל-ח'רוב
2720 ,טירה ,358 ,אל-חאס'באני
2720 ,טירה ,336 ,אל-חג'אג' בן יוסף
2720 ,טירה ,160 ,אל-חורייה
2720 ,טירה ,393 ,אל-חימה
2720 ,טירה ,388 ,אל-חלפה
2720 ,טירה ,210 ,אל-חמוויין
2720 ,טירה ,162 ,אל-חנאן
2720 ,טירה ,248 ,אל-חסון
2720 ,טירה ,107 ,אל-חפאיר
2720 ,טירה ,161 ,אל-חק
2720 ,טירה ,274 ,אל-יאסמין
2720 ,טירה ,275 ,אל-ימאמה
2720 ,טירה ,267 ,אל-כינא
2720 ,טירה ,365 ,אל-כינאיאת
2720 ,טירה ,280 ,אל-כנארי
2720 ,טירה ,406 ,אל-כראמה
2720 ,טירה ,185 ,אל-כרום
2720 ,טירה ,266 ,אל-כרז
2720 ,טירה ,123 ,אל-כרם
2720 ,טירה ,408 ,אל-כרמה
2720 ,טירה ,364 ,אל-כרמל
2720 ,טירה ,235 ,אל-לאוז
2720 ,טירה ,290 ,אל-מאמון
2720 ,טירה ,203 ,אל-מארווה
2720 ,טירה ,172 ,אל-מג'ד
2720 ,טירה ,213 ,אל-מדינה
2720 ,טירה ,175 ,אל-מוודא
2720 ,טירה ,151 ,אל-מוראבטון
2720 ,טירה ,390 ,אל-מורג'אן
2720 ,טירה ,173 ,אל-מחבה
2720 ,טירה ,367 ,אל-מחטה
2720 ,טירה ,399 ,אל-מיחראב
2720 ,טירה ,403 ,אל-מיחראת
2720 ,טירה ,176 ,אל-מינא
2720 ,טירה ,189 ,אל-מיסק
2720 ,טירה ,174 ,אל-מנארה
2720 ,טירה ,397 ,אל-מנג'ל
2720 ,טירה ,133 ,אל-מסג'ד
2720 ,טירה ,111 ,אל-מערי
2720 ,טירה ,401 ,אל-מרח
2720 ,טירה ,129 ,אל-משרוע
2720 ,טירה ,132 ,אל-ע'אבה
2720 ,טירה ,395 ,אל-ע'אר
2720 ,טירה ,363 ,אל-ע'ור
2720 ,טירה ,321 ,אל-ע'זאלי
2720 ,טירה ,402 ,אל-ע'רבאל
2720 ,טירה ,263 ,אל-עאווסאג'
2720 ,טירה ,362 ,אל-עג'מי
2720 ,טירה ,167 ,אל-עדל
2720 ,טירה ,168 ,אל-עולום
2720 ,טירה ,191 ,אל-עומאל
2720 ,טירה ,194 ,אל-עומדה
2720 ,טירה ,169 ,אל-עומראן
2720 ,טירה ,127 ,אל-עומרייה
2720 ,טירה ,376 ,אל-עין
2720 ,טירה ,407 ,אל-עינאב
2720 ,טירה ,410 ,אל-עינב
2720 ,טירה ,211 ,אל-עיראקיין
2720 ,טירה ,186 ,אל-ענבר
2720 ,טירה ,322 ,אל-פאראבי
2720 ,טירה ,281 ,אל-פול
2720 ,טירה ,170 ,אל-פלאחין
2720 ,טירה ,201 ,אל-קאדסייה
2720 ,טירה ,202 ,אל-קאהרה
2720 ,טירה ,124 ,אל-קאות'ר
2720 ,טירה ,121 ,אל-קודס
2720 ,טירה ,283 ,אל-קונדיל
2720 ,טירה ,264 ,אל-קורונפול
2720 ,טירה ,198 ,אל-קלם
2720 ,טירה ,265 ,אל-קמח
2720 ,טירה ,6760 ,אלמשכוע
2720 ,טירה ,314 ,אסיא
2720 ,טירה ,353 ,אריחא
2720 ,טירה ,230 ,באב א-נבי דאווד
2720 ,טירה ,231 ,באב א-סאהירה
2720 ,טירה ,225 ,באב אל-אסבאט
2720 ,טירה ,229 ,באב אל-ג'דיד
2720 ,טירה ,113 ,באב אל-ח'אן
2720 ,טירה ,228 ,באב אל-ח'ליל
2720 ,טירה ,227 ,באב אל-מע'ארבה
2720 ,טירה ,226 ,באב אל-עמוד
2720 ,טירה ,205 ,באדר
2720 ,טירה ,349 ,באקה אל-חטב
2720 ,טירה ,214 ,ביירות
2720 ,טירה ,140 ,בילאל בן רבאח
2720 ,טירה ,371 ,ביסאן
2720 ,טירה ,333 ,בלקיס
2720 ,טירה ,384 ,בני ס'עב
2720 ,טירה ,206 ,בע'דאד
2720 ,טירה ,294 ,ג'אבר בן חייאן
2720 ,טירה ,518 ,ג'אבר סולטאן
2720 ,טירה ,295 ,ג'ובראן ח'ליל
2720 ,טירה ,6780 ,ג'ורת קשוע
2720 ,טירה ,375 ,ג'ינין
2720 ,טירה ,404 ,ג'ירזים
2720 ,טירה ,337 ,ג'עפר בן אבי טאלב
2720 ,טירה ,519 ,ד'יאב סולטאני
2720 ,טירה ,526 ,דאוד אחמד ריזק
2720 ,טירה ,144 ,דאוד ראזק
2720 ,טירה ,6770 ,דאמו
2720 ,טירה ,208 ,דימשק
2720 ,טירה ,106 ,דרך א-סולטאני
2720 ,טירה ,131 ,דרך אל-בסה
2720 ,טירה ,400 ,האג'ר
2720 ,טירה ,304 ,הארון א-רשיד
2720 ,טירה ,6740 ,העיר העתיקה
2720 ,טירה ,297 ,זייד בן חארית'ה
2720 ,טירה ,220 ,זמזם
2720 ,טירה ,296 ,ח'אלד בן אל-ווליד
2720 ,טירה ,514 ,ח'ור אבו עייאד
2720 ,טירה ,525 ,ח'ליל עבד אלחק נאסר
2720 ,טירה ,515 ,חאנותה
2720 ,טירה ,339 ,חאפט איברהים
2720 ,טירה ,150 ,חור א-נדא
2720 ,טירה ,6750 ,חור אבו דקר
2720 ,טירה ,6710 ,חור אלנדא
2720 ,טירה ,6781 ,חור לס
2720 ,טירה ,207 ,חיטין
2720 ,טירה ,350 ,חייפא
2720 ,טירה ,219 ,חיראא
2720 ,טירה ,112 ,חמזה
2720 ,טירה ,405 ,חנטלה
2720 ,טירה ,527 ,חסין מוסטפא אלג'מאל
2720 ,טירה ,331 ,טאהא חסיין
2720 ,טירה ,330 ,טארק בן זיאד
2720 ,טירה ,110 ,טארק עבד אל-חי
2720 ,טירה ,379 ,טברייא
2720 ,טירה ,222 ,טייבה
2720 ,טירה ,9000 ,טירה
2720 ,טירה ,130 ,יאפא
2720 ,טירה ,383 ,יבוס
2720 ,טירה ,6731 ,כרם אלזיתון
2720 ,טירה ,524 ,לוטפי מוחמד סולטאן
2720 ,טירה ,340 ,לוקמאן אל-חכים
2720 ,טירה ,335 ,מאי זיאדה
2720 ,טירה ,218 ,מואתה
2720 ,טירה ,385 ,מוואריס א-ד'הב
2720 ,טירה ,303 ,מוחמד אל-פאתח
2720 ,טירה ,334 ,מוס'עב בן עומייר
2720 ,טירה ,507 ,מחמוד בשארה
2720 ,טירה ,522 ,מחמוד חסין קאסם
2720 ,טירה ,305 ,מחמוד טאהא אלע'זאוי
2720 ,טירה ,217 ,מכה
2720 ,טירה ,6730 ,מקורות
2720 ,טירה ,6741 ,מרכז העיר
2720 ,טירה ,352 ,נאבליס
2720 ,טירה ,338 ,נג'יב מחפוט
2720 ,טירה ,529 ,נזיה מטר
2720 ,טירה ,183 ,ניסאן
2720 ,טירה ,298 ,ס'לאח א-דין
2720 ,טירה ,409 ,ס'נעאא
2720 ,טירה ,369 ,ס'פד
2720 ,טירה ,302 ,סייף אל-דין קוטוז
2720 ,טירה ,411 ,סינאא
2720 ,טירה ,329 ,סלמאן אל-פארסי
2720 ,טירה ,328 ,סעד בן מועאד'
2720 ,טירה ,520 ,סעיד סולטאני
2720 ,טירה ,382 ,ע'זה
2720 ,טירה ,510 ,עבד א-רחמאן קדארה
2720 ,טירה ,509 ,עבד אל-חאפיט פדילי
2720 ,טירה ,142 ,עומר בן אל-ח'טאב
2720 ,טירה ,517 ,עוקאב אחמד קשוע
2720 ,טירה ,299 ,עות'מאן בן עפאן
2720 ,טירה ,374 ,עיבאל
2720 ,טירה ,216 ,עין ג'אלוד
2720 ,טירה ,381 ,עין כארם
2720 ,טירה ,380 ,עכא
2720 ,טירה ,117 ,עלי בן אבי טאלב
2720 ,טירה ,300 ,עמאר בן יאסר
2720 ,טירה ,301 ,עמרו בן אל-עאס'
2720 ,טירה ,277 ,עס'א א-ראעי
2720 ,טירה ,6771 ,עקפה
2720 ,טירה ,209 ,ערפה
2720 ,טירה ,306 ,פאטמה אל-הודהוד
2720 ,טירה ,332 ,פיירוז
2720 ,טירה ,224 ,קיבאא
2720 ,טירה ,392 ,קיסריה
2720 ,טירה ,115 ,קלקיליה
2720 ,טירה ,377 ,ראם אללה
2720 ,טירה ,351 ,ראס עאמר
2720 ,טירה ,448 ,רח 9310
2720 ,טירה ,500 ,רח 9410
2720 ,טירה ,436 ,רח 9420
2720 ,טירה ,463 ,רח 9230
2720 ,טירה ,472 ,רח 9240
2720 ,טירה ,443 ,רח 9340
2720 ,טירה ,451 ,רח 9440
2720 ,טירה ,502 ,רח 9450
2720 ,טירה ,428 ,רח 9180
2720 ,טירה ,483 ,רח 9011
2720 ,טירה ,465 ,רח 9221
2720 ,טירה ,454 ,רח 9421
2720 ,טירה ,460 ,רח 9231
2720 ,טירה ,444 ,רח 9341
2720 ,טירה ,422 ,רח 9161
2720 ,טירה ,456 ,רח 9171
2720 ,טירה ,489 ,רח 9302
2720 ,טירה ,450 ,רח 9402
2720 ,טירה ,476 ,רח 9012
2720 ,טירה ,412 ,רח 9312
2720 ,טירה ,439 ,רח 9322
2720 ,טירה ,434 ,רח 9422
2720 ,טירה ,459 ,רח 9232
2720 ,טירה ,432 ,רח 9152
2720 ,טירה ,482 ,רח 9262
2720 ,טירה ,425 ,רח 9172
2720 ,טירה ,427 ,רח 9182
2720 ,טירה ,421 ,רח 9392
2720 ,טירה ,415 ,רח 9313
2720 ,טירה ,495 ,רח 9423
2720 ,טירה ,493 ,רח 9433
2720 ,טירה ,416 ,רח 9043
2720 ,טירה ,464 ,רח 9393
2720 ,טירה ,414 ,רח 9404
2720 ,טירה ,485 ,רח 9014
2720 ,טירה ,501 ,רח 9314
2720 ,טירה ,435 ,רח 9324
2720 ,טירה ,413 ,רח 9044
2720 ,טירה ,417 ,רח 9344
2720 ,טירה ,469 ,רח 9444
2720 ,טירה ,497 ,רח 9054
2720 ,טירה ,446 ,רח 9305
2720 ,טירה ,452 ,רח 9405
2720 ,טירה ,478 ,רח 9015
2720 ,טירה ,453 ,רח 9415
2720 ,טירה ,503 ,רח 9025
2720 ,טירה ,481 ,רח 9325
2720 ,טירה ,504 ,רח 9435
2720 ,טירה ,426 ,רח 9445
2720 ,טירה ,491 ,רח 9295
2720 ,טירה ,474 ,רח 9006
2720 ,טירה ,470 ,רח 9206
2720 ,טירה ,431 ,רח 9406
2720 ,טירה ,479 ,רח 9016
2720 ,טירה ,471 ,רח 9216
2720 ,טירה ,467 ,רח 9416
2720 ,טירה ,441 ,רח 9326
2720 ,טירה ,496 ,רח 9426
2720 ,טירה ,419 ,רח 9436
2720 ,טירה ,461 ,רח 9446
2720 ,טירה ,424 ,רח 9396
2720 ,טירה ,475 ,רח 9007
2720 ,טירה ,480 ,רח 9017
2720 ,טירה ,499 ,רח 9317
2720 ,טירה ,440 ,רח 9327
2720 ,טירה ,429 ,רח 9427
2720 ,טירה ,442 ,רח 9347
2720 ,טירה ,490 ,רח 9447
2720 ,טירה ,458 ,רח 9167
2720 ,טירה ,437 ,רח 9008
2720 ,טירה ,488 ,רח 9018
2720 ,טירה ,462 ,רח 9228
2720 ,טירה ,473 ,רח 9238
2720 ,טירה ,445 ,רח 9338
2720 ,טירה ,433 ,רח 9148
2720 ,טירה ,505 ,רח 9348
2720 ,טירה ,420 ,רח 9448
2720 ,טירה ,423 ,רח 9178
2720 ,טירה ,486 ,רח 9278
2720 ,טירה ,418 ,רח 9188
2720 ,טירה ,438 ,רח 9009
2720 ,טירה ,487 ,רח 9019
2720 ,טירה ,466 ,רח 9219
2720 ,טירה ,494 ,רח 9319
2720 ,טירה ,492 ,רח 9419
2720 ,טירה ,447 ,רח 9339
2720 ,טירה ,468 ,רח 9439
2720 ,טירה ,498 ,רח 9049
2720 ,טירה ,430 ,רח 9159
2720 ,טירה ,506 ,רח 9459
2720 ,טירה ,455 ,רח 9169
2720 ,טירה ,449 ,רח 9079
2720 ,טירה ,484 ,רח 9279
2720 ,טירה ,457 ,רח 9399
2720 ,טירה ,182 ,רמדאן
2720 ,טירה ,6711 ,שכונה מזרחית
2720 ,טירה ,193 ,שעבאן
2720 ,טירה ,276 ,שקאאיק א-נועמאן
2720 ,טירה ,145 ,שרחביל בן חסנה
2720 ,טירה ,327 ,תאופיק זייאד
2720 ,טירה ,212 ,תבוכ
2720 ,טירה ,215 ,תוניס
2720 ,טירה ,181 ,תשרין
663 ,טירת יהודה ,9000 ,טירת יהודה
2100 ,טירת כרמל ,6528 ,אזור התעשיה
2100 ,טירת כרמל ,6521 ,איילת הכרמל
2100 ,טירת כרמל ,449 ,אלבז נתן
2100 ,טירת כרמל ,455 ,אליאס כרמל
2100 ,טירת כרמל ,105 ,אלמוג
2100 ,טירת כרמל ,113 ,אצ"ל
2100 ,טירת כרמל ,101 ,אריאל שרון
2100 ,טירת כרמל ,204 ,אתגר
2100 ,טירת כרמל ,115 ,ביאליק
2100 ,טירת כרמל ,310 ,בן צבי
2100 ,טירת כרמל ,445 ,גורדון
2100 ,טירת כרמל ,454 ,גיורא
2100 ,טירת כרמל ,111 ,דולפין
2100 ,טירת כרמל ,120 ,דקר
2100 ,טירת כרמל ,329 ,דרך נחל גלים
2100 ,טירת כרמל ,135 ,האגמית
2100 ,טירת כרמל ,326 ,האלה
2100 ,טירת כרמל ,325 ,האלון
2100 ,טירת כרמל ,133 ,האנפה
2100 ,טירת כרמל ,453 ,הארז
2100 ,טירת כרמל ,323 ,הברוש
2100 ,טירת כרמל ,131 ,הגל
2100 ,טירת כרמל ,320 ,הדקל
2100 ,טירת כרמל ,423 ,ההגנה
2100 ,טירת כרמל ,118 ,הורדים
2100 ,טירת כרמל ,321 ,הזית
2100 ,טירת כרמל ,322 ,החרוב
2100 ,טירת כרמל ,136 ,היסעור
2100 ,טירת כרמל ,114 ,היצירה
2100 ,טירת כרמל ,116 ,הכלניות
2100 ,טירת כרמל ,210 ,המלאכה
2100 ,טירת כרמל ,404 ,המעפילים
2100 ,טירת כרמל ,6529 ,המרכז לבריאות הנפש
2100 ,טירת כרמל ,117 ,הנרקיסים
2100 ,טירת כרמל ,208 ,הסדנא
2100 ,טירת כרמל ,134 ,הסייפן
2100 ,טירת כרמל ,137 ,העגור
2100 ,טירת כרמל ,425 ,העצמאות
2100 ,טירת כרמל ,211 ,הפטיש
2100 ,טירת כרמל ,409 ,הפלמ"ח
2100 ,טירת כרמל ,448 ,הצנחנים
2100 ,טירת כרמל ,452 ,הרב ברזני סולימן
2100 ,טירת כרמל ,440 ,הרב וינרוב
2100 ,טירת כרמל ,437 ,הרב זרביב
2100 ,טירת כרמל ,451 ,הרב מרדכי משה
2100 ,טירת כרמל ,435 ,הרב קוק
2100 ,טירת כרמל ,460 ,הרדוף
2100 ,טירת כרמל ,324 ,הרימון
2100 ,טירת כרמל ,434 ,הרצל
2100 ,טירת כרמל ,132 ,השחף
2100 ,טירת כרמל ,418 ,השחרור
2100 ,טירת כרמל ,138 ,השלדג
2100 ,טירת כרמל ,456 ,השריון
2100 ,טירת כרמל ,450 ,ויצמן
2100 ,טירת כרמל ,439 ,ז'בוטינסקי
2100 ,טירת כרמל ,461 ,חצב
2100 ,טירת כרמל ,104 ,חרצית
2100 ,טירת כרמל ,212 ,חת נחום
2100 ,טירת כרמל ,9000 ,טירת כרמל
2100 ,טירת כרמל ,121 ,י"א הספורטאים
2100 ,טירת כרמל ,202 ,יוזמה
2100 ,טירת כרמל ,110 ,יצחק שמיר
2100 ,טירת כרמל ,458 ,ירושלים
2100 ,טירת כרמל ,441 ,כ"ג יורדי הסירה
2100 ,טירת כרמל ,6001 ,ככר דקר
2100 ,טירת כרמל ,6005 ,ככר השריון
2100 ,טירת כרמל ,6002 ,ככר י"א הספורטאים
2100 ,טירת כרמל ,6003 ,ככר ירושלים
2100 ,טירת כרמל ,6500 ,כפר טירה
2100 ,טירת כרמל ,205 ,כרמלים
2100 ,טירת כרמל ,443 ,לוי אשכול
2100 ,טירת כרמל ,112 ,לח"י
2100 ,טירת כרמל ,447 ,לניאדו עזרא
2100 ,טירת כרמל ,433 ,מוצקין
2100 ,טירת כרמל ,431 ,מלחמת ששת הימים
2100 ,טירת כרמל ,6501 ,מע דרומית
2100 ,טירת כרמל ,6502 ,מע צפונית א
2100 ,טירת כרמל ,6503 ,מע צפונית ג
2100 ,טירת כרמל ,471 ,מעלה נוף
2100 ,טירת כרמל ,119 ,מפרש
2100 ,טירת כרמל ,6524 ,נאות כרמל
2100 ,טירת כרמל ,6520 ,נוה אליאס
2100 ,טירת כרמל ,6523 ,נווה גלים
2100 ,טירת כרמל ,430 ,נורי דוד
2100 ,טירת כרמל ,103 ,נורית
2100 ,טירת כרמל ,100 ,סחלב
2100 ,טירת כרמל ,102 ,סיגלית
2100 ,טירת כרמל ,305 ,סיני
2100 ,טירת כרמל ,426 ,סמ ההגנה 2
2100 ,טירת כרמל ,307 ,סמ המעפיל 10
2100 ,טירת כרמל ,401 ,סמ המעפיל 1
2100 ,טירת כרמל ,406 ,סמ המעפיל 4
2100 ,טירת כרמל ,301 ,סמ המעפיל 7
2100 ,טירת כרמל ,411 ,סמ המעפיל 8
2100 ,טירת כרמל ,410 ,סמ המעפיל 9
2100 ,טירת כרמל ,428 ,סמ הפלמח 1
2100 ,טירת כרמל ,414 ,סמ הפלמח 5
2100 ,טירת כרמל ,413 ,סמ הפלמח 6
2100 ,טירת כרמל ,306 ,סמ הפלמח 8
2100 ,טירת כרמל ,462 ,סמ הרדוף
2100 ,טירת כרמל ,209 ,סמ כרמל
2100 ,טירת כרמל ,417 ,סמ עליה ב 2
2100 ,טירת כרמל ,444 ,סמ שיטרית
2100 ,טירת כרמל ,457 ,עגנון ש"י
2100 ,טירת כרמל ,107 ,עוגן
2100 ,טירת כרמל ,206 ,עוצמה
2100 ,טירת כרמל ,415 ,עליה ב
2100 ,טירת כרמל ,109 ,צדף
2100 ,טירת כרמל ,207 ,קדמה
2100 ,טירת כרמל ,432 ,קרן היסוד
2100 ,טירת כרמל ,446 ,רזיאל
2100 ,טירת כרמל ,436 ,רמב"ם
2100 ,טירת כרמל ,6525 ,רמת בגין
2100 ,טירת כרמל ,6504 ,ש אגש
2100 ,טירת כרמל ,6505 ,ש אסבסטונים
2100 ,טירת כרמל ,6506 ,ש בן צבי
2100 ,טירת כרמל ,6507 ,ש גיורא
2100 ,טירת כרמל ,6508 ,ש ויצמן
2100 ,טירת כרמל ,6510 ,ש כהן
2100 ,טירת כרמל ,6511 ,ש ממשלתי
2100 ,טירת כרמל ,6512 ,ש עמידר
2100 ,טירת כרמל ,6513 ,ש שיטרית
2100 ,טירת כרמל ,6514 ,ש שרת
2100 ,טירת כרמל ,130 ,שדרות מנחם בגין
2100 ,טירת כרמל ,106 ,שונית
2100 ,טירת כרמל ,6515 ,שכ ביאליק
2100 ,טירת כרמל ,6516 ,שכ ברנר
2100 ,טירת כרמל ,6522 ,שכ גלי כרמל
2100 ,טירת כרמל ,6531 ,שכ הפרחים
2100 ,טירת כרמל ,6527 ,שכ כלניות
2100 ,טירת כרמל ,6526 ,שכ מעלה נוף
2100 ,טירת כרמל ,6517 ,שכ רמב"ם
2100 ,טירת כרמל ,6518 ,שכ שז"ר
2100 ,טירת כרמל ,6519 ,שכונת אשכול
2100 ,טירת כרמל ,213 ,שלמה גולד
2100 ,טירת כרמל ,438 ,שרת משה
2100 ,טירת כרמל ,108 ,תורן
2100 ,טירת כרמל ,203 ,תנופה
268 ,טירת צבי ,101 ,אולפן קיבוץ
268 ,טירת צבי ,9000 ,טירת צבי
462 ,טל שחר ,101 ,הכנרת
462 ,טל שחר ,108 ,המייסדות והמייסדים
462 ,טל שחר ,9000 ,טל שחר
462 ,טל שחר ,102 ,נהר הירדן
462 ,טל שחר ,103 ,נחל איילון
462 ,טל שחר ,112 ,נחל ארנון
462 ,טל שחר ,105 ,נחל דן
462 ,טל שחר ,104 ,נחל הבשור
462 ,טל שחר ,111 ,נחל לכיש
462 ,טל שחר ,110 ,נחל פארן
462 ,טל שחר ,106 ,נחל קדרון
462 ,טל שחר ,107 ,נחל שורק
462 ,טל שחר ,109 ,נחל שניר
1181 ,טל-אל ,115 ,אלה
1181 ,טל-אל ,114 ,אלון
1181 ,טל-אל ,103 ,האורן
1181 ,טל-אל ,111 ,האילן
1181 ,טל-אל ,106 ,הברוש
1181 ,טל-אל ,104 ,הגפן
1181 ,טל-אל ,105 ,הדס
1181 ,טל-אל ,108 ,הדקל
1181 ,טל-אל ,107 ,הזית
1181 ,טל-אל ,112 ,החצב
1181 ,טל-אל ,102 ,החרוב
1181 ,טל-אל ,101 ,הרימון
1181 ,טל-אל ,110 ,התאנה
1181 ,טל-אל ,9000 ,טל-אל
1181 ,טל-אל ,113 ,כליל החורש
1181 ,טל-אל ,109 ,מורן
1177 ,טללים ,7002 ,חוות זוהר המדבר
1177 ,טללים ,7001 ,חוות מתנת מדבר
1177 ,טללים ,9000 ,טללים
1177 ,טללים ,101 ,מרכז קליטה
3788 ,טלמון ,154 ,אורן
3788 ,טלמון ,153 ,אלה
3788 ,טלמון ,152 ,אלון
3788 ,טלמון ,118 ,אריאל
3788 ,טלמון ,146 ,ארץ ימיני
3788 ,טלמון ,148 ,ארץ צוף
3788 ,טלמון ,143 ,ארץ שלישה
3788 ,טלמון ,144 ,ארץ שעלים
3788 ,טלמון ,130 ,אשכול
3788 ,טלמון ,122 ,אשת לפידות
3788 ,טלמון ,150 ,ברוש
3788 ,טלמון ,141 ,ברכת הבנים
3788 ,טלמון ,139 ,ברכת שמים
3788 ,טלמון ,129 ,גפן
3788 ,טלמון ,135 ,דולב
3788 ,טלמון ,155 ,דרך אביה
3788 ,טלמון ,160 ,דרך הרי בשמים
3788 ,טלמון ,131 ,דרך שילה
3788 ,טלמון ,132 ,האופניים
3788 ,טלמון ,110 ,הגולן
3788 ,טלמון ,103 ,הגליל
3788 ,טלמון ,108 ,הגלעד
3788 ,טלמון ,151 ,היער
3788 ,טלמון ,107 ,הירדן
3788 ,טלמון ,170 ,הניצנים
3788 ,טלמון ,105 ,הערבה
3788 ,טלמון ,137 ,הר אפרים
3788 ,טלמון ,163 ,הר המור
3788 ,טלמון ,102 ,הר כרכום
3788 ,טלמון ,115 ,הראל
3788 ,טלמון ,123 ,הרואה
3788 ,טלמון ,167 ,הרימון
3788 ,טלמון ,166 ,התאנה
3788 ,טלמון ,106 ,התבור
3788 ,טלמון ,164 ,התמר
3788 ,טלמון ,128 ,זית
3788 ,טלמון ,140 ,חצב
3788 ,טלמון ,9000 ,טלמון
3788 ,טלמון ,120 ,יהושע בן נון
3788 ,טלמון ,111 ,ירושלים
3788 ,טלמון ,134 ,לבנה
3788 ,טלמון ,117 ,לבנון
3788 ,טלמון ,136 ,לוז
3788 ,טלמון ,145 ,לוטם
3788 ,טלמון ,119 ,מוריה
3788 ,טלמון ,169 ,מים חיים
3788 ,טלמון ,147 ,מרוה
3788 ,טלמון ,165 ,משעול הכרמים
3788 ,טלמון ,162 ,משעול צרור המור
3788 ,טלמון ,116 ,נווה
3788 ,טלמון ,112 ,נחל פרת
3788 ,טלמון ,104 ,נחל צין
3788 ,טלמון ,109 ,נחל שניר
3788 ,טלמון ,125 ,נחלי געש
3788 ,טלמון ,6500 ,נריה
3788 ,טלמון ,101 ,סיני
3788 ,טלמון ,138 ,עירית
3788 ,טלמון ,133 ,ערמון
3788 ,טלמון ,168 ,פרי מגדים
3788 ,טלמון ,142 ,קידה
3788 ,טלמון ,127 ,רמתיים צופים
3788 ,טלמון ,121 ,שאול המלך
3788 ,טלמון ,161 ,שיר השירים
3788 ,טלמון ,6501 ,שכונת חרשה
3788 ,טלמון ,6504 ,שכונת טלמון א'
3788 ,טלמון ,6502 ,שכונת כרם רעים
3788 ,טלמון ,6503 ,שכונת נוה טלמון
3788 ,טלמון ,149 ,שקד
3788 ,טלמון ,124 ,תומר דבורה
3788 ,טלמון ,126 ,תמנה
8900 ,טמרה ,6750 ,אבו רומאן
8900 ,טמרה ,6751 ,אלביק
8900 ,טמרה ,6740 ,אלג'בל
8900 ,טמרה ,6731 ,אלהרובה
8900 ,טמרה ,6771 ,אלורוד
8900 ,טמרה ,6741 ,אלחג'ארה
8900 ,טמרה ,6730 ,אלחמרה
8900 ,טמרה ,6721 ,אלמסלוקה
8900 ,טמרה ,6732 ,אלפאראבי
8900 ,טמרה ,6754 ,באב אלנסב
8900 ,טמרה ,6780 ,דריג'את
8900 ,טמרה ,6720 ,המסגד הישן
8900 ,טמרה ,6710 ,השכונה העליונה
8900 ,טמרה ,6711 ,ואדי אלעין
8900 ,טמרה ,6742 ,ואדי המאם
8900 ,טמרה ,6752 ,זיאדנה
8900 ,טמרה ,6753 ,ח'לאיל אלנוואר
8900 ,טמרה ,6773 ,ח'לת אלסבעה
8900 ,טמרה ,6774 ,ח'לת אלע'זאל
8900 ,טמרה ,6770 ,ח'לת שריף
8900 ,טמרה ,9000 ,טמרה
8900 ,טמרה ,6712 ,מטיראן
8900 ,טמרה ,6760 ,מרכז העיר
8900 ,טמרה ,6722 ,עירייה
8900 ,טמרה ,6772 ,שכונה צפונית
547 ,טמרה (יזרעאל) ,9000 ,טמרה (יזרעאל)
3743 ,טנא ,103 ,אזוב
3743 ,טנא ,111 ,האופק
3743 ,טנא ,107 ,הגבעה
3743 ,טנא ,112 ,החורשה
3743 ,טנא ,109 ,הנחל
3743 ,טנא ,108 ,הפסגה
3743 ,טנא ,110 ,חצב
3743 ,טנא ,9000 ,טנא
3743 ,טנא ,104 ,מרווה
3743 ,טנא ,113 ,סלעית
3743 ,טנא ,101 ,עומרים
3743 ,טנא ,106 ,עירית
3743 ,טנא ,105 ,קורנית
3743 ,טנא ,102 ,רכסים
1214 ,טפחות ,9000 ,טפחות
1295 ,יאנוח-ג'ת ,122 ,א-זוהור
1295 ,יאנוח-ג'ת ,176 ,א-זעתר
1295 ,יאנוח-ג'ת ,178 ,א-לוויזה
1295 ,יאנוח-ג'ת ,207 ,א-נג'מה
1295 ,יאנוח-ג'ת ,204 ,א-ס'ופיר
1295 ,יאנוח-ג'ת ,175 ,א-סהל
1295 ,יאנוח-ג'ת ,184 ,א-סוסנה
1295 ,יאנוח-ג'ת ,183 ,א-סופסאף
1295 ,יאנוח-ג'ת ,188 ,א-סחלב
1295 ,יאנוח-ג'ת ,190 ,א-רביע
1295 ,יאנוח-ג'ת ,195 ,א-שג'רה
1295 ,יאנוח-ג'ת ,192 ,א-שוקה
1295 ,יאנוח-ג'ת ,157 ,אל-אפראח
1295 ,יאנוח-ג'ת ,182 ,אל-אקחוואן
1295 ,יאנוח-ג'ת ,138 ,אל-ארז
1295 ,יאנוח-ג'ת ,198 ,אל-באשק
1295 ,יאנוח-ג'ת ,213 ,אל-בלאבל
1295 ,יאנוח-ג'ת ,180 ,אל-ברקוק
1295 ,יאנוח-ג'ת ,187 ,אל-ג'ורי
1295 ,יאנוח-ג'ת ,130 ,אל-הודהוד
1295 ,יאנוח-ג'ת ,109 ,אל-וורוד
1295 ,יאנוח-ג'ת ,189 ,אל-ח'אבה
1295 ,יאנוח-ג'ת ,208 ,אל-ח'לווה
1295 ,יאנוח-ג'ת ,215 ,אל-ח'פאש
1295 ,יאנוח-ג'ת ,211 ,אל-חאס'ל
1295 ,יאנוח-ג'ת ,194 ,אל-חארה
1295 ,יאנוח-ג'ת ,179 ,אל-חג'ל
1295 ,יאנוח-ג'ת ,197 ,אל-חסון
1295 ,יאנוח-ג'ת ,199 ,אל-ימאמה
1295 ,יאנוח-ג'ת ,173 ,אל-מארס
1295 ,יאנוח-ג'ת ,203 ,אל-מוח'תאר
1295 ,יאנוח-ג'ת ,206 ,אל-מיזאן
1295 ,יאנוח-ג'ת ,205 ,אל-מרג'
1295 ,יאנוח-ג'ת ,209 ,אל-מרוש
1295 ,יאנוח-ג'ת ,201 ,אל-ע'זאל
1295 ,יאנוח-ג'ת ,200 ,אל-עיקאב
1295 ,יאנוח-ג'ת ,181 ,אל-פול
1295 ,יאנוח-ג'ת ,177 ,אל-קרנפול
1295 ,יאנוח-ג'ת ,116 ,אלאס
1295 ,יאנוח-ג'ת ,135 ,אלבבור
1295 ,יאנוח-ג'ת ,139 ,אלביאד'
1295 ,יאנוח-ג'ת ,154 ,אלביאדר
1295 ,יאנוח-ג'ת ,106 ,אלבלוט
1295 ,יאנוח-ג'ת ,118 ,אלג'וז
1295 ,יאנוח-ג'ת ,123 ,אלג'נאנין
1295 ,יאנוח-ג'ת ,164 ,אלדוואלי
1295 ,יאנוח-ג'ת ,104 ,אלדוואר
1295 ,יאנוח-ג'ת ,136 ,אלואדי
1295 ,יאנוח-ג'ת ,112 ,אלזייתון
1295 ,יאנוח-ג'ת ,103 ,אלזנבקה
1295 ,יאנוח-ג'ת ,158 ,אלזערור
1295 ,יאנוח-ג'ת ,107 ,אלזקאקאת
1295 ,יאנוח-ג'ת ,174 ,אלח'ואח'את
1295 ,יאנוח-ג'ת ,172 ,אלח'לה
1295 ,יאנוח-ג'ת ,148 ,אלח'לוה
1295 ,יאנוח-ג'ת ,110 ,אלח'רוב
1295 ,יאנוח-ג'ת ,6507 ,אלחארה אלווסטאניה
1295 ,יאנוח-ג'ת ,6505 ,אלחארה אלע'רביה
1295 ,יאנוח-ג'ת ,6514 ,אלחארה אלפוקה
1295 ,יאנוח-ג'ת ,6502 ,אלחארה אלקדימה
1295 ,יאנוח-ג'ת ,6500 ,אלחארה אלשמאלייה
1295 ,יאנוח-ג'ת ,6519 ,אלחארה אלתחתה
1295 ,יאנוח-ג'ת ,160 ,אלחבאק'
1295 ,יאנוח-ג'ת ,133 ,אלחג'לה
1295 ,יאנוח-ג'ת ,114 ,אלחמדייאת
1295 ,יאנוח-ג'ת ,129 ,אלטווס
1295 ,יאנוח-ג'ת ,167 ,אלטוק
1295 ,יאנוח-ג'ת ,117 ,אלטיון
1295 ,יאנוח-ג'ת ,108 ,אליאסמין
1295 ,יאנוח-ג'ת ,127 ,אלכנאר
1295 ,יאנוח-ג'ת ,113 ,אלכרום
1295 ,יאנוח-ג'ת ,143 ,אלכרם
1295 ,יאנוח-ג'ת ,165 ,אללוז
1295 ,יאנוח-ג'ת ,166 ,אללוטוס
1295 ,יאנוח-ג'ת ,120 ,אללילך
1295 ,יאנוח-ג'ת ,163 ,אלמידאן
1295 ,יאנוח-ג'ת ,121 ,אלמיראמיה
1295 ,יאנוח-ג'ת ,169 ,אלמעסרה
1295 ,יאנוח-ג'ת ,151 ,אלנאטור
1295 ,יאנוח-ג'ת ,126 ,אלנוורס
1295 ,יאנוח-ג'ת ,140 ,אלנור
1295 ,יאנוח-ג'ת ,155 ,אלנח'יל
1295 ,יאנוח-ג'ת ,105 ,אלנח'לה
1295 ,יאנוח-ג'ת ,131 ,אלנסר
1295 ,יאנוח-ג'ת ,125 ,אלנרג'ס
1295 ,יאנוח-ג'ת ,170 ,אלסומאק
1295 ,יאנוח-ג'ת ,152 ,אלסידאויה
1295 ,יאנוח-ג'ת ,141 ,אלסנדיאנה
1295 ,יאנוח-ג'ת ,134 ,אלסנונו
1295 ,יאנוח-ג'ת ,132 ,אלסקר
1295 ,יאנוח-ג'ת ,156 ,אלסרווה
1295 ,יאנוח-ג'ת ,115 ,אלענב
1295 ,יאנוח-ג'ת ,171 ,אלעקד
1295 ,יאנוח-ג'ת ,111 ,אלרומאן
1295 ,יאנוח-ג'ת ,128 ,אלשחרור
1295 ,יאנוח-ג'ת ,159 ,אלשק'אק'
1295 ,יאנוח-ג'ת ,168 ,אלת'נייה
1295 ,יאנוח-ג'ת ,119 ,אלתינה
1295 ,יאנוח-ג'ת ,210 ,באב א-דאר
1295 ,יאנוח-ג'ת ,149 ,ביר אלקלאע
1295 ,יאנוח-ג'ת ,146 ,ברכת אלבלד
1295 ,יאנוח-ג'ת ,162 ,ג'בר רהיג'
1295 ,יאנוח-ג'ת ,101 ,ג'ת (בגליל)
1295 ,יאנוח-ג'ת ,150 ,דרב אלחאסל
1295 ,יאנוח-ג'ת ,142 ,דרב אלסאחל
1295 ,יאנוח-ג'ת ,191 ,דרומה
1295 ,יאנוח-ג'ת ,6509 ,חארת אלברכה
1295 ,יאנוח-ג'ת ,6508 ,חארת אלג'ונוד
1295 ,יאנוח-ג'ת ,6515 ,חארת אלח'לאל
1295 ,יאנוח-ג'ת ,6506 ,חארת אלכראג'
1295 ,יאנוח-ג'ת ,6501 ,חארת אלמחפרה
1295 ,יאנוח-ג'ת ,6512 ,חארת אלמיסה
1295 ,יאנוח-ג'ת ,6513 ,חארת אלמרוש
1295 ,יאנוח-ג'ת ,6518 ,חארת אלנמרה
1295 ,יאנוח-ג'ת ,6511 ,חארת אלעריד'
1295 ,יאנוח-ג'ת ,6504 ,חארת אלשכרה
1295 ,יאנוח-ג'ת ,6503 ,חארת דארומה
1295 ,יאנוח-ג'ת ,6516 ,חארת מלונה
1295 ,יאנוח-ג'ת ,6517 ,חארת קבר פאעור
1295 ,יאנוח-ג'ת ,102 ,יאנוח
1295 ,יאנוח-ג'ת ,9000 ,יאנוח-ג'ת
1295 ,יאנוח-ג'ת ,144 ,כאם אלדאר
1295 ,יאנוח-ג'ת ,214 ,כליל אג'בל
1295 ,יאנוח-ג'ת ,137 ,כסאר עמיס
1295 ,יאנוח-ג'ת ,145 ,כרם אלקעקור
1295 ,יאנוח-ג'ת ,153 ,מחמד סעד
1295 ,יאנוח-ג'ת ,202 ,מלונה
1295 ,יאנוח-ג'ת ,196 ,מראח א-דינאר
1295 ,יאנוח-ג'ת ,212 ,מראח אל-עג'אל
1295 ,יאנוח-ג'ת ,185 ,נג'מת אל-מג'ד
1295 ,יאנוח-ג'ת ,193 ,סידי בו ערוס
1295 ,יאנוח-ג'ת ,147 ,סתי שמסה
1295 ,יאנוח-ג'ת ,186 ,עיבאד א-שמס
1232 ,יבול ,9000 ,יבול
46 ,יבנאל ,133 ,אורן
46 ,יבנאל ,149 ,איזדרכת
46 ,יבנאל ,178 ,אירוס הגליל
46 ,יבנאל ,159 ,אלה
46 ,יבנאל ,158 ,אלון
46 ,יבנאל ,122 ,בית אבות יוקרה
46 ,יבנאל ,6509 ,בית גן
46 ,יבנאל ,131 ,בית וגן
46 ,יבנאל ,110 ,בן גוריון
46 ,יבנאל ,176 ,גפן
46 ,יבנאל ,138 ,גרעין מסדה
46 ,יבנאל ,171 ,דגן
46 ,יבנאל ,141 ,דולב
46 ,יבנאל ,154 ,דפנה
46 ,יבנאל ,175 ,דקל
46 ,יבנאל ,136 ,דרך הבקעה
46 ,יבנאל ,140 ,דרך ההר
46 ,יבנאל ,121 ,דרך המושבות
46 ,יבנאל ,116 ,הגורן
46 ,יבנאל ,145 ,הדס
46 ,יבנאל ,169 ,החיטה
46 ,יבנאל ,165 ,החרוב
46 ,יבנאל ,130 ,הכלנית
46 ,יבנאל ,112 ,המיסדים
46 ,יבנאל ,164 ,המעיין
46 ,יבנאל ,134 ,המרגנית
46 ,יבנאל ,132 ,הנוטרים
46 ,יבנאל ,142 ,הנרקיס
46 ,יבנאל ,143 ,הרדוף
46 ,יבנאל ,144 ,הרופאים
46 ,יבנאל ,129 ,הרקפת
46 ,יבנאל ,156 ,חטיבת גולני
46 ,יבנאל ,137 ,חיים לבקוב
46 ,יבנאל ,160 ,חלוצי תימן
46 ,יבנאל ,174 ,חצב
46 ,יבנאל ,127 ,חרציות
46 ,יבנאל ,172 ,טייסת הגליל
46 ,יבנאל ,9000 ,יבנאל
46 ,יבנאל ,139 ,יהודה
46 ,יבנאל ,123 ,ישראל שרפמן
46 ,יבנאל ,146 ,כרכום
46 ,יבנאל ,168 ,לוטם
46 ,יבנאל ,152 ,מבצע מייקלברג
46 ,יבנאל ,170 ,מגל
46 ,יבנאל ,167 ,מורג
46 ,יבנאל ,6502 ,מעברת יבנאל
46 ,יבנאל ,124 ,מעלה הגפן
46 ,יבנאל ,148 ,מרווה
46 ,יבנאל ,115 ,משמר השלושה
46 ,יבנאל ,177 ,נורית
46 ,יבנאל ,111 ,נפתלי
46 ,יבנאל ,126 ,סביון
46 ,יבנאל ,153 ,סיתוונית
46 ,יבנאל ,114 ,סמדר
46 ,יבנאל ,163 ,סמטת הזית
46 ,יבנאל ,161 ,סמטת השקד
46 ,יבנאל ,162 ,סמטת התמר
46 ,יבנאל ,151 ,עומר
46 ,יבנאל ,147 ,עירית
46 ,יבנאל ,135 ,ערבה
46 ,יבנאל ,155 ,צמרת יבנאל
46 ,יבנאל ,125 ,רימון
46 ,יבנאל ,6505 ,ש עולים
46 ,יבנאל ,6506 ,ש עמידר
46 ,יבנאל ,173 ,שיבולת
46 ,יבנאל ,157 ,שיזף
46 ,יבנאל ,166 ,שיקמה
46 ,יבנאל ,6507 ,שכ התימנים
46 ,יבנאל ,6508 ,שכ ישראל שרפמן
2660 ,יבנה ,367 ,אבוקדו
2660 ,יבנה ,240 ,אביבית
2660 ,יבנה ,220 ,אגוז
2660 ,יבנה ,241 ,אגמון
2660 ,יבנה ,233 ,אגס
2660 ,יבנה ,345 ,אהובה עוזרי
2660 ,יבנה ,325 ,אודם
2660 ,יבנה ,221 ,אזוב
2660 ,יבנה ,6518 ,אזור המלאכה
2660 ,יבנה ,6517 ,אזור התעשיה
2660 ,יבנה ,136 ,אטד
2660 ,יבנה ,234 ,אירוס
2660 ,יבנה ,368 ,אירוס הארגמן
2660 ,יבנה ,232 ,אלה
2660 ,יבנה ,121 ,אלעזר דוד
2660 ,יבנה ,237 ,אפרסמון
2660 ,יבנה ,182 ,אצ"ל
2660 ,יבנה ,236 ,ארז
2660 ,יבנה ,369 ,אשכולית
2660 ,יבנה ,228 ,אתרוג
2660 ,יבנה ,242 ,ברקן
2660 ,יבנה ,326 ,ברקת
2660 ,יבנה ,101 ,גבורי ישראל
2660 ,יבנה ,157 ,גבעה
2660 ,יבנה ,243 ,גומא
2660 ,יבנה ,327 ,גזית
2660 ,יבנה ,114 ,גיבורי החיל
2660 ,יבנה ,244 ,גלית
2660 ,יבנה ,328 ,גרנית
2660 ,יבנה ,341 ,דבורה עומר
2660 ,יבנה ,170 ,דוכיפת
2660 ,יבנה ,279 ,דולב
2660 ,יבנה ,387 ,דולפין
2660 ,יבנה ,352 ,דותן
2660 ,יבנה ,344 ,דליה רביקוביץ'
2660 ,יבנה ,245 ,דם המכבים
2660 ,יבנה ,351 ,דן
2660 ,יבנה ,222 ,דפנה
2660 ,יבנה ,180 ,דרך חג'ג' אהרון
2660 ,יבנה ,126 ,האומן
2660 ,יבנה ,127 ,האורגים
2660 ,יבנה ,210 ,האורן
2660 ,יבנה ,202 ,האיריס
2660 ,יבנה ,211 ,האלון
2660 ,יבנה ,393 ,האלמוג
2660 ,יבנה ,115 ,האמוראים
2660 ,יבנה ,213 ,האקליפטוס
2660 ,יבנה ,147 ,הארבל
2660 ,יבנה ,194 ,האשל
2660 ,יבנה ,231 ,הברוש
2660 ,יבנה ,148 ,הבשן
2660 ,יבנה ,246 ,הגולן
2660 ,יבנה ,160 ,הגלבוע
2660 ,יבנה ,247 ,הגליל
2660 ,יבנה ,149 ,הגלעד
2660 ,יבנה ,168 ,הגפן
2660 ,יבנה ,238 ,הגרי זכריה
2660 ,יבנה ,380 ,הדוגית
2660 ,יבנה ,379 ,הדייגים
2660 ,יבנה ,227 ,הדס
2660 ,יבנה ,171 ,הדקל
2660 ,יבנה ,184 ,הדרור
2660 ,יבנה ,280 ,ההגנה
2660 ,יבנה ,197 ,הזית
2660 ,יבנה ,186 ,הזמיר
2660 ,יבנה ,208 ,החבצלת
2660 ,יבנה ,108 ,החרש
2660 ,יבנה ,181 ,היסמין
2660 ,יבנה ,190 ,הירדן
2660 ,יבנה ,193 ,הירמוך
2660 ,יבנה ,353 ,הירקון
2660 ,יבנה ,175 ,הכלנית
2660 ,יבנה ,155 ,הכרמל
2660 ,יבנה ,176 ,הלילך
2660 ,יבנה ,125 ,המיסב
2660 ,יבנה ,383 ,המלחים
2660 ,יבנה ,124 ,המסילה
2660 ,יבנה ,376 ,המפרש
2660 ,יבנה ,377 ,המשוט
2660 ,יבנה ,166 ,הנביאים
2660 ,יבנה ,145 ,הנגב
2660 ,יבנה ,214 ,הנחל
2660 ,יבנה ,385 ,הנחשול
2660 ,יבנה ,388 ,הנמל
2660 ,יבנה ,203 ,הנרקיס
2660 ,יבנה ,162 ,הנשיאים
2660 ,יבנה ,188 ,הנשר
2660 ,יבנה ,201 ,הסביון
2660 ,יבנה ,129 ,הסופרים
2660 ,יבנה ,386 ,הסיפון
2660 ,יבנה ,395 ,הסירה
2660 ,יבנה ,116 ,העבודה
2660 ,יבנה ,278 ,העגור
2660 ,יבנה ,392 ,העוגן
2660 ,יבנה ,113 ,העמל
2660 ,יבנה ,172 ,העצמאות
2660 ,יבנה ,209 ,הערבה
2660 ,יבנה ,192 ,הפרת
2660 ,יבנה ,183 ,הצבעוני
2660 ,יבנה ,381 ,הצדף
2660 ,יבנה ,191 ,הקישון
2660 ,יבנה ,150 ,הר הזיתים
2660 ,יבנה ,156 ,הר מירון
2660 ,יבנה ,151 ,הר נבו
2660 ,יבנה ,152 ,הר סיני
2660 ,יבנה ,179 ,הראשונים
2660 ,יבנה ,212 ,הרב אבוחצירא
2660 ,יבנה ,178 ,הרב בוכריס
2660 ,יבנה ,248 ,הרדוף
2660 ,יבנה ,340 ,הרצל
2660 ,יבנה ,382 ,הרשת
2660 ,יבנה ,249 ,השומרון
2660 ,יבנה ,394 ,השונית
2660 ,יבנה ,130 ,השופטים
2660 ,יבנה ,173 ,השושנים
2660 ,יבנה ,187 ,השחף
2660 ,יבנה ,378 ,השייטים
2660 ,יבנה ,250 ,השפלה
2660 ,יבנה ,198 ,השקד
2660 ,יבנה ,199 ,השקמה
2660 ,יבנה ,251 ,השרון
2660 ,יבנה ,196 ,התאנה
2660 ,יבנה ,205 ,התבור
2660 ,יבנה ,375 ,התורן
2660 ,יבנה ,112 ,התנאים
2660 ,יבנה ,132 ,ורד
2660 ,יבנה ,219 ,ז'בוטינסקי
2660 ,יבנה ,252 ,זהבית
2660 ,יבנה ,109 ,חבלבל
2660 ,יבנה ,365 ,חבצלת החוף
2660 ,יבנה ,139 ,חטיבת גבעתי
2660 ,יבנה ,281 ,חידקל
2660 ,יבנה ,389 ,חירות
2660 ,יבנה ,163 ,חלמונית
2660 ,יבנה ,288 ,חמנית
2660 ,יבנה ,253 ,חצב
2660 ,יבנה ,229 ,חרוב
2660 ,יבנה ,185 ,חרמון
2660 ,יבנה ,254 ,חרצית
2660 ,יבנה ,9000 ,יבנה
2660 ,יבנה ,329 ,יהלום
2660 ,יבנה ,287 ,יערה
2660 ,יבנה ,255 ,יקינטון
2660 ,יבנה ,256 ,כוכבית
2660 ,יבנה ,257 ,כחלית
2660 ,יבנה ,384 ,לויתן
2660 ,יבנה ,258 ,לוע הארי
2660 ,יבנה ,230 ,לימון
2660 ,יבנה ,330 ,לשם
2660 ,יבנה ,102 ,מבצע דני
2660 ,יבנה ,103 ,מבצע חירם
2660 ,יבנה ,104 ,מבצע יואב
2660 ,יבנה ,135 ,מבצע יונתן
2660 ,יבנה ,105 ,מבצע עובדה
2660 ,יבנה ,133 ,מבצע קדש
2660 ,יבנה ,107 ,מבצעי תש"ח
2660 ,יבנה ,153 ,מוריה
2660 ,יבנה ,239 ,מורן
2660 ,יבנה ,131 ,מלכי יהודה
2660 ,יבנה ,134 ,מלכי ישראל
2660 ,יבנה ,259 ,מרגנית
2660 ,יבנה ,6502 ,מרכז מסחרי
2660 ,יבנה ,207 ,משעול בוהיניה
2660 ,יבנה ,300 ,משעול האדר
2660 ,יבנה ,298 ,משעול האלמוגן
2660 ,יבנה ,292 ,משעול האפרסק
2660 ,יבנה ,317 ,משעול הגויאבה
2660 ,יבנה ,306 ,משעול ההדרים
2660 ,יבנה ,295 ,משעול החבוש
2660 ,יבנה ,302 ,משעול הכרמים
2660 ,יבנה ,299 ,משעול הלבנה
2660 ,יבנה ,314 ,משעול המנגו
2660 ,יבנה ,293 ,משעול הפיקוס
2660 ,יבנה ,313 ,משעול הפקאן
2660 ,יבנה ,316 ,משעול הצבר
2660 ,יבנה ,291 ,משעול הצפצפה
2660 ,יבנה ,312 ,משעול הקוקוס
2660 ,יבנה ,290 ,משעול התדהר
2660 ,יבנה ,301 ,משעול התות
2660 ,יבנה ,315 ,משעול התפוז
2660 ,יבנה ,294 ,משעול התפוח
2660 ,יבנה ,296 ,משעול כליל החורש
2660 ,יבנה ,303 ,משעול סהרון
2660 ,יבנה ,311 ,משעול סיגלון
2660 ,יבנה ,304 ,משעול קטלב
2660 ,יבנה ,305 ,משעול תאשור
2660 ,יבנה ,6506 ,נאות אשכול
2660 ,יבנה ,6516 ,נאות בגין
2660 ,יבנה ,6507 ,נאות בן גוריון
2660 ,יבנה ,6519 ,נאות רבין
2660 ,יבנה ,6508 ,נאות שזר
2660 ,יבנה ,260 ,נהרונית
2660 ,יבנה ,164 ,נופר
2660 ,יבנה ,225 ,נורית
2660 ,יבנה ,261 ,נזמית
2660 ,יבנה ,361 ,נחל חריף
2660 ,יבנה ,362 ,נחל ערוגות
2660 ,יבנה ,363 ,נחל פולג
2660 ,יבנה ,364 ,נחל שניר
2660 ,יבנה ,177 ,נחליאלי
2660 ,יבנה ,286 ,ניצנית
2660 ,יבנה ,343 ,נעמי שמר
2660 ,יבנה ,270 ,נתיב הספורט
2660 ,יבנה ,348 ,נתן אלתרמן
2660 ,יבנה ,140 ,סחלב
2660 ,יבנה ,284 ,סיגל
2660 ,יבנה ,262 ,סייפן
2660 ,יבנה ,159 ,סמטת האנפה
2660 ,יבנה ,391 ,סנפיר
2660 ,יבנה ,331 ,ספיר
2660 ,יבנה ,263 ,סתוונית
2660 ,יבנה ,137 ,עדעד
2660 ,יבנה ,349 ,עוזי חיטמן
2660 ,יבנה ,146 ,עולי הגרדום
2660 ,יבנה ,142 ,עולש
2660 ,יבנה ,264 ,עירית
2660 ,יבנה ,283 ,ענבל
2660 ,יבנה ,265 ,ערמון
2660 ,יבנה ,266 ,ערער
2660 ,יבנה ,282 ,פארן
2660 ,יבנה ,141 ,פטל
2660 ,יבנה ,267 ,פעמונית
2660 ,יבנה ,268 ,פרג
2660 ,יבנה ,269 ,צאלון
2660 ,יבנה ,289 ,צורית
2660 ,יבנה ,143 ,צלף
2660 ,יבנה ,271 ,צפורן
2660 ,יבנה ,110 ,קדושי קהיר
2660 ,יבנה ,285 ,קורנית
2660 ,יבנה ,272 ,קינמון
2660 ,יבנה ,346 ,רבי יהודה הלוי
2660 ,יבנה ,371 ,רוזמרין
2660 ,יבנה ,273 ,רותם
2660 ,יבנה ,122 ,רח ד
2660 ,יבנה ,117 ,רח ה
2660 ,יבנה ,120 ,רח יב
2660 ,יבנה ,119 ,רח יד
2660 ,יבנה ,128 ,רח יז
2660 ,יבנה ,342 ,רחל המשוררת
2660 ,יבנה ,370 ,ריחן
2660 ,יבנה ,235 ,רימון
2660 ,יבנה ,6509 ,רמות בן צבי
2660 ,יבנה ,6510 ,רמות ויצמן
2660 ,יבנה ,189 ,רקפת
2660 ,יבנה ,6511 ,ש הפועלים
2660 ,יבנה ,6512 ,ש חולות
2660 ,יבנה ,6513 ,ש טרומיים
2660 ,יבנה ,6503 ,ש יבנית
2660 ,יבנה ,6504 ,ש נוה עובד
2660 ,יבנה ,347 ,ש"י עגנון
2660 ,יבנה ,174 ,שבזי
2660 ,יבנה ,366 ,שד גן רווה
2660 ,יבנה ,158 ,שד דואני
2660 ,יבנה ,200 ,שד הסנהדרין
2660 ,יבנה ,390 ,שד הציונות
2660 ,יבנה ,274 ,שד ירושלים
2660 ,יבנה ,332 ,שוהם
2660 ,יבנה ,275 ,שזיף
2660 ,יבנה ,195 ,שידלובסקי
2660 ,יבנה ,276 ,שיטה
2660 ,יבנה ,6505 ,שכ הארבע מאות
2660 ,יבנה ,6514 ,שכ נוה אילן
2660 ,יבנה ,6515 ,שכ צהל
2660 ,יבנה ,6500 ,שכ רכבת
2660 ,יבנה ,226 ,שנף
2660 ,יבנה ,144 ,תורמוס
2660 ,יבנה ,138 ,תלתן
2660 ,יבנה ,277 ,תמר
2660 ,יבנה ,118 ,תנועות הנוער
96 ,יגור ,102 ,אולפן קיבוץ
96 ,יגור ,101 ,בית ראשון במולדת
96 ,יגור ,144 ,דרך אחווה
96 ,יגור ,100 ,דרך הבאר
96 ,יגור ,148 ,דרך הטווס
96 ,יגור ,138 ,דרך הכרכום
96 ,יגור ,123 ,דרך הכרם
96 ,יגור ,111 ,דרך הכרמל
96 ,יגור ,143 ,דרך הלולים
96 ,יגור ,129 ,דרך הלימון
96 ,יגור ,149 ,דרך הפרדס
96 ,יגור ,152 ,דרך הקישון
96 ,יגור ,114 ,דרך הראשונים
96 ,יגור ,137 ,דרך השדות
96 ,יגור ,150 ,דרך התעשייה
96 ,יגור ,145 ,דרך חדר האוכל
96 ,יגור ,147 ,דרך יד למגינים
96 ,יגור ,112 ,דרך עמק זבולון
96 ,יגור ,141 ,דרך שומר המסילה
96 ,יגור ,9000 ,יגור
96 ,יגור ,153 ,כביש ההר הירוק
96 ,יגור ,113 ,כביש השלום
96 ,יגור ,151 ,כביש כפר חסידים
96 ,יגור ,118 ,מעלה הוואדי
96 ,יגור ,120 ,מעלה המצוקים
96 ,יגור ,116 ,סמ' ההר הקירח
96 ,יגור ,121 ,סמ' השקדיה
96 ,יגור ,127 ,שביל האגוז
96 ,יגור ,124 ,שביל האיזדרכת
96 ,יגור ,130 ,שביל האנפה
96 ,יגור ,125 ,שביל האקליפטוס
96 ,יגור ,103 ,שביל הברושים
96 ,יגור ,136 ,שביל הגבעות
96 ,יגור ,131 ,שביל הדוכיפת
96 ,יגור ,106 ,שביל החיטה
96 ,יגור ,142 ,שביל החלב
96 ,יגור ,140 ,שביל הכלנית
96 ,יגור ,117 ,שביל הכתר
96 ,יגור ,133 ,שביל הנחליאלי
96 ,יגור ,105 ,שביל הסילו
96 ,יגור ,115 ,שביל הסכר
96 ,יגור ,108 ,שביל הסליק
96 ,יגור ,128 ,שביל הסנדלריה
96 ,יגור ,139 ,שביל הסתוונית
96 ,יגור ,132 ,שביל העפרוני
96 ,יגור ,135 ,שביל הפיקוסים
96 ,יגור ,110 ,שביל הצאלון
96 ,יגור ,126 ,שביל השיקמה
96 ,יגור ,134 ,שביל השלדג
96 ,יגור ,104 ,שביל התאנה
96 ,יגור ,107 ,שביל התלתן
96 ,יגור ,109 ,שביל מגדל המים
96 ,יגור ,6504 ,שכ' גבעת המכוורת
96 ,יגור ,6507 ,שכונת בלוקונים
96 ,יגור ,6508 ,שכונת האיזדרכת
96 ,יגור ,6503 ,שכונת ההר
96 ,יגור ,6510 ,שכונת הכביש
96 ,יגור ,6506 ,שכונת הכרם
96 ,יגור ,6502 ,שכונת הכרמל
96 ,יגור ,6500 ,שכונת הלול
96 ,יגור ,6511 ,שכונת המשתלה
96 ,יגור ,6509 ,שכונת הנה"ח
96 ,יגור ,6501 ,שכונת הסילו
798 ,יגל ,103 ,הגפן
798 ,יגל ,102 ,הדקל
798 ,יגל ,101 ,הלימון
798 ,יגל ,9000 ,יגל
798 ,יגל ,6001 ,ככר חוני
577 ,יד בנימין ,105 ,אנפה
577 ,יד בנימין ,135 ,אפיקים
577 ,יד בנימין ,143 ,ארבל
577 ,יד בנימין ,114 ,גדרון
577 ,יד בנימין ,113 ,דיה
577 ,יד בנימין ,137 ,דרור
577 ,יד בנימין ,120 ,דרך האבות
577 ,יד בנימין ,116 ,דרך יונתן
577 ,יד בנימין ,124 ,דרך מיכה
577 ,יד בנימין ,134 ,האשל
577 ,יד בנימין ,130 ,הגפן
577 ,יד בנימין ,126 ,הושע
577 ,יד בנימין ,127 ,הנחלים
577 ,יד בנימין ,129 ,הראל
577 ,יד בנימין ,133 ,הרימון
577 ,יד בנימין ,138 ,השחף
577 ,יד בנימין ,139 ,השלו
577 ,יד בנימין ,132 ,התאנה
577 ,יד בנימין ,111 ,ורדית
577 ,יד בנימין ,112 ,זמיר
577 ,יד בנימין ,110 ,חוחית
577 ,יד בנימין ,119 ,חנה למדן
577 ,יד בנימין ,9000 ,יד בנימין
577 ,יד בנימין ,109 ,יסעור
577 ,יד בנימין ,103 ,יעלה
577 ,יד בנימין ,108 ,כחל
577 ,יד בנימין ,140 ,כרמל
577 ,יד בנימין ,115 ,מגלן
577 ,יד בנימין ,142 ,מוריה
577 ,יד בנימין ,141 ,מירון
577 ,יד בנימין ,121 ,מעלה עמוס
577 ,יד בנימין ,144 ,מצדה
577 ,יד בנימין ,107 ,נחליאלי
577 ,יד בנימין ,149 ,נשר
577 ,יד בנימין ,146 ,סמ גלבוע
577 ,יד בנימין ,123 ,סמ חגי
577 ,יד בנימין ,125 ,סמ יואל
577 ,יד בנימין ,122 ,סמ יונה
577 ,יד בנימין ,147 ,סמ עמשא
577 ,יד בנימין ,148 ,סמ עצמון
577 ,יד בנימין ,131 ,סמ שקד
577 ,יד בנימין ,106 ,סנונית
577 ,יד בנימין ,145 ,סער
577 ,יד בנימין ,104 ,עגור
577 ,יד בנימין ,101 ,צופית
577 ,יד בנימין ,6501 ,שכ נירים
577 ,יד בנימין ,6500 ,שכ נתיבה
577 ,יד בנימין ,6504 ,שכ עידית
577 ,יד בנימין ,6506 ,שכ שדות
577 ,יד בנימין ,6503 ,שכ שקמים
577 ,יד בנימין ,6502 ,שכ תמנה
577 ,יד בנימין ,6505 ,שכ תמר
577 ,יד בנימין ,102 ,שניר
577 ,יד בנימין ,128 ,תבור
1134 ,יד השמונה ,103 ,אבן הפינה
1134 ,יד השמונה ,106 ,אור העולם
1134 ,יד השמונה ,104 ,הרועה הטוב
1134 ,יד השמונה ,9000 ,יד השמונה
1134 ,יד השמונה ,107 ,מגדל עוז
1134 ,יד השמונה ,108 ,מים חיים
1134 ,יד השמונה ,105 ,נגה השחר
1134 ,יד השמונה ,111 ,סלע איתן
1134 ,יד השמונה ,110 ,עמנו אל
1134 ,יד השמונה ,101 ,צור ישראל
1134 ,יד השמונה ,102 ,שמש ומגן
1134 ,יד השמונה ,109 ,שר שלום
758 ,יד חנה ,9000 ,יד חנה
358 ,יד מרדכי ,9000 ,יד מרדכי
775 ,יד נתן ,105 ,הבנים
775 ,יד נתן ,102 ,הוורד
775 ,יד נתן ,101 ,המייסדים
775 ,יד נתן ,106 ,הפרדס
775 ,יד נתן ,9000 ,יד נתן
775 ,יד נתן ,104 ,משעול הגורן
775 ,יד נתן ,103 ,שדרת האקליפטוסים
64 ,יד רמב"ם ,9000 ,יד רמב"ם
1144 ,ידידה ,9000 ,ידידה
9400 ,יהוד-מונוסון ,127 ,אבן גבירול
9400 ,יהוד-מונוסון ,134 ,אברבנאל
9400 ,יהוד-מונוסון ,3112 ,אגוז נוה אפרים
9400 ,יהוד-מונוסון ,172 ,אור יצחק ישיבה
9400 ,יהוד-מונוסון ,6502 ,אזור תעשייה
9400 ,יהוד-מונוסון ,210 ,אלטלף אברהם
9400 ,יהוד-מונוסון ,176 ,אלי כהן
9400 ,יהוד-מונוסון ,192 ,אלעזר דוד
9400 ,יהוד-מונוסון ,153 ,אלפרט
9400 ,יהוד-מונוסון ,119 ,אנילביץ
9400 ,יהוד-מונוסון ,190 ,ארבעת המינים
9400 ,יהוד-מונוסון ,160 ,אריה בן אליעזר
9400 ,יהוד-מונוסון ,121 ,ארלוזורוב
9400 ,יהוד-מונוסון ,179 ,אשכול
9400 ,יהוד-מונוסון ,125 ,אשכנזי
9400 ,יהוד-מונוסון ,120 ,ביאליק
9400 ,יהוד-מונוסון ,177 ,ביאקובסקי
9400 ,יהוד-מונוסון ,165 ,ביל"ו
9400 ,יהוד-מונוסון ,194 ,בן גוריון דוד
9400 ,יהוד-מונוסון ,114 ,בן צבי
9400 ,יהוד-מונוסון ,164 ,בר סימנטוב יעקב
9400 ,יהוד-מונוסון ,161 ,ברנר
9400 ,יהוד-מונוסון ,3104 ,ברקת נוה אפרים
9400 ,יהוד-מונוסון ,185 ,גולדה מאיר
9400 ,יהוד-מונוסון ,118 ,גורדון
9400 ,יהוד-מונוסון ,212 ,גירון אברהם
9400 ,יהוד-מונוסון ,214 ,ד"ר שישקש חיים
9400 ,יהוד-מונוסון ,186 ,דרך דיין משה
9400 ,יהוד-מונוסון ,188 ,דרך החורש
9400 ,יהוד-מונוסון ,126 ,דרך העצמאות
9400 ,יהוד-מונוסון ,3102 ,האלמוג נוה אפרים
9400 ,יהוד-מונוסון ,195 ,האצ"ל
9400 ,יהוד-מונוסון ,3103 ,האתרוג נוה אפרים
9400 ,יהוד-מונוסון ,115 ,הגדוד העברי
9400 ,יהוד-מונוסון ,3106 ,הגפן נוה אפרים
9400 ,יהוד-מונוסון ,234 ,הדגנים
9400 ,יהוד-מונוסון ,3107 ,הדר נוה אפרים
9400 ,יהוד-מונוסון ,181 ,ההגנה
9400 ,יהוד-מונוסון ,171 ,הורדים
9400 ,יהוד-מונוסון ,196 ,הזית
9400 ,יהוד-מונוסון ,236 ,החרוב
9400 ,יהוד-מונוסון ,209 ,החרושת
9400 ,יהוד-מונוסון ,198 ,היובל
9400 ,יהוד-מונוסון ,130 ,הכלנית
9400 ,יהוד-מונוסון ,111 ,המייסדים
9400 ,יהוד-מונוסון ,213 ,המלאכה
9400 ,יהוד-מונוסון ,139 ,המעפילים
9400 ,יהוד-מונוסון ,246 ,המר זבולון
9400 ,יהוד-מונוסון ,169 ,הנרקיס
9400 ,יהוד-מונוסון ,3108 ,הפנינים נוה אפרים
9400 ,יהוד-מונוסון ,138 ,הקונגרס הציוני
9400 ,יהוד-מונוסון ,215 ,הרב חיים סינואני
9400 ,יהוד-מונוסון ,173 ,הרואה
9400 ,יהוד-מונוסון ,3109 ,הרימון נוה אפרים
9400 ,יהוד-מונוסון ,200 ,הרצוג חיים
9400 ,יהוד-מונוסון ,116 ,הרצל
9400 ,יהוד-מונוסון ,184 ,השלום
9400 ,יהוד-מונוסון ,159 ,השלושה
9400 ,יהוד-מונוסון ,211 ,התעשייה
9400 ,יהוד-מונוסון ,166 ,וינהויז זאב
9400 ,יהוד-מונוסון ,101 ,ויצמן
9400 ,יהוד-מונוסון ,168 ,ז'בוטינסקי
9400 ,יהוד-מונוסון ,154 ,חזנוביץ
9400 ,יהוד-מונוסון ,241 ,חיטה
9400 ,יהוד-מונוסון ,106 ,חסיד משה
9400 ,יהוד-מונוסון ,135 ,חתוכה סעדיה
9400 ,יהוד-מונוסון ,142 ,טורצ'ין ניסן
9400 ,יהוד-מונוסון ,197 ,טלבי יוסף
9400 ,יהוד-מונוסון ,108 ,טננבוים
9400 ,יהוד-מונוסון ,162 ,טרומפלדור
9400 ,יהוד-מונוסון ,9000 ,יהוד-מונוסון
9400 ,יהוד-מונוסון ,136 ,יהודה הלוי
9400 ,יהוד-מונוסון ,3111 ,יהלום נוה אפרים
9400 ,יהוד-מונוסון ,128 ,יונה
9400 ,יהוד-מונוסון ,170 ,יוספטל גיורא
9400 ,יהוד-מונוסון ,140 ,יצחק שדה
9400 ,יהוד-מונוסון ,183 ,כהן רם
9400 ,יהוד-מונוסון ,6001 ,ככר יצחק שדה
9400 ,יהוד-מונוסון ,155 ,כצנלסון
9400 ,יהוד-מונוסון ,105 ,לוחמי הגיטאות
9400 ,יהוד-מונוסון ,228 ,מבוא אילן
9400 ,יהוד-מונוסון ,230 ,מבוא אלה
9400 ,יהוד-מונוסון ,232 ,מבוא אתרוג
9400 ,יהוד-מונוסון ,221 ,מבוא ברוניקה
9400 ,יהוד-מונוסון ,199 ,מבוא ברוש
9400 ,יהוד-מונוסון ,191 ,מבוא גומא
9400 ,יהוד-מונוסון ,229 ,מבוא דולב
9400 ,יהוד-מונוסון ,222 ,מבוא דליה
9400 ,יהוד-מונוסון ,223 ,מבוא האירוס
9400 ,יהוד-מונוסון ,231 ,מבוא הדס
9400 ,יהוד-מונוסון ,233 ,מבוא הרדוף
9400 ,יהוד-מונוסון ,244 ,מבוא ורבנה
9400 ,יהוד-מונוסון ,224 ,מבוא זהבית
9400 ,יהוד-מונוסון ,225 ,מבוא חבצלת
9400 ,יהוד-מונוסון ,226 ,מבוא טופח
9400 ,יהוד-מונוסון ,227 ,מבוא יסמין
9400 ,יהוד-מונוסון ,220 ,מבוא לולב
9400 ,יהוד-מונוסון ,237 ,מבוא שיבולת
9400 ,יהוד-מונוסון ,103 ,מוהליבר
9400 ,יהוד-מונוסון ,174 ,מוצקין
9400 ,יהוד-מונוסון ,163 ,מלון אויה
9400 ,יהוד-מונוסון ,104 ,מלמד
9400 ,יהוד-מונוסון ,131 ,מקלב אורי
9400 ,יהוד-מונוסון ,143 ,מרבד הקסמים
9400 ,יהוד-מונוסון ,137 ,מרכוס דוד
9400 ,יהוד-מונוסון ,205 ,נבון
9400 ,יהוד-מונוסון ,6510 ,נווה מונוסון
9400 ,יהוד-מונוסון ,157 ,נורדאו
9400 ,יהוד-מונוסון ,113 ,ניל"י
9400 ,יהוד-מונוסון ,182 ,נתניהו יונתן
9400 ,יהוד-מונוסון ,107 ,סוקניק
9400 ,יהוד-מונוסון ,152 ,סירקין
9400 ,יהוד-מונוסון ,3118 ,סמ אגוז נוה אפרים
9400 ,יהוד-מונוסון ,3119 ,סמ הפנינים נוהאפרים
9400 ,יהוד-מונוסון ,117 ,סמ ויצמן
9400 ,יהוד-מונוסון ,122 ,סנש
9400 ,יהוד-מונוסון ,3110 ,ספיר נוה אפרים
9400 ,יהוד-מונוסון ,3101 ,עינבר נוה אפרים
9400 ,יהוד-מונוסון ,124 ,עץ האפרסק
9400 ,יהוד-מונוסון ,238 ,ערבה
9400 ,יהוד-מונוסון ,189 ,פרחי הבר
9400 ,יהוד-מונוסון ,145 ,צבי ישי
9400 ,יהוד-מונוסון ,180 ,צה"ל
9400 ,יהוד-מונוסון ,150 ,צוקרמן צביה ויצחק
9400 ,יהוד-מונוסון ,3117 ,צנובר נוה אפרים
9400 ,יהוד-מונוסון ,132 ,קדושי מצרים
9400 ,יהוד-מונוסון ,109 ,קפלן
9400 ,יהוד-מונוסון ,102 ,קפלנסקי
9400 ,יהוד-מונוסון ,6508 ,קרית ביאליסטוק
9400 ,יהוד-מונוסון ,6506 ,קרית הסביונים
9400 ,יהוד-מונוסון ,123 ,רמב"ם
9400 ,יהוד-מונוסון ,112 ,רמז
9400 ,יהוד-מונוסון ,6505 ,ש בית שמואל
9400 ,יהוד-מונוסון ,6501 ,ש נוה עובד
9400 ,יהוד-מונוסון ,6503 ,ש רסקו
9400 ,יהוד-מונוסון ,158 ,שבזי
9400 ,יהוד-מונוסון ,187 ,שד בגין
9400 ,יהוד-מונוסון ,3113 ,שוהם נוה אפרים
9400 ,יהוד-מונוסון ,167 ,שז"ר
9400 ,יהוד-מונוסון ,156 ,שיבת ציון
9400 ,יהוד-מונוסון ,193 ,שיטרית בכור
9400 ,יהוד-מונוסון ,240 ,שיפון
9400 ,יהוד-מונוסון ,3114 ,שנהב נוה אפרים
9400 ,יהוד-מונוסון ,239 ,שעורה
9400 ,יהוד-מונוסון ,178 ,שפירא משה חיים
9400 ,יהוד-מונוסון ,151 ,שצ'ופק מרים
9400 ,יהוד-מונוסון ,3115 ,שקד נוה אפרים
9400 ,יהוד-מונוסון ,175 ,ששת הימים
9400 ,יהוד-מונוסון ,201 ,תאנה
9400 ,יהוד-מונוסון ,243 ,תאשור
9400 ,יהוד-מונוסון ,242 ,תדהר
9400 ,יהוד-מונוסון ,3116 ,תמר נוה אפרים
9400 ,יהוד-מונוסון ,3105 ,תרשיש נוה אפרים
1158 ,יהל ,101 ,בית ראשון במולדת
1158 ,יהל ,9000 ,יהל
2009 ,יובל ,110 ,דן
2009 ,יובל ,101 ,דרך המייסדים
2009 ,יובל ,116 ,דרך המעכה
2009 ,יובל ,115 ,האנקור
2009 ,יובל ,106 ,הבנים
2009 ,יובל ,104 ,הגפן
2009 ,יובל ,114 ,הדוכיפת
2009 ,יובל ,119 ,ההרדוף
2009 ,יובל ,105 ,הזית
2009 ,יובל ,120 ,החרצית
2009 ,יובל ,112 ,העפרוני
2009 ,יובל ,121 ,הפרחים
2009 ,יובל ,113 ,הצופית
2009 ,יובל ,102 ,הרימון
2009 ,יובל ,107 ,השקד
2009 ,יובל ,103 ,התאנה
2009 ,יובל ,118 ,התורמוס
2009 ,יובל ,117 ,התמר
2009 ,יובל ,9000 ,יובל
2009 ,יובל ,122 ,משעול היובלים
2009 ,יובל ,123 ,משעול הציפורים
2009 ,יובל ,111 ,עיון
2009 ,יובל ,108 ,שיאון
2009 ,יובל ,109 ,שניר
1226 ,יובלים ,126 ,חורשת טל
1226 ,יובלים ,9000 ,יובלים
1226 ,יובלים ,107 ,מעיינות
1226 ,יובלים ,106 ,מעין היצירה
1226 ,יובלים ,108 ,נחל אורן
1226 ,יובלים ,112 ,נחל בצת
1226 ,יובלים ,105 ,נחל ברקן
1226 ,יובלים ,104 ,נחל געתון
1226 ,יובלים ,103 ,נחל דן
1226 ,יובלים ,101 ,נחל הארי
1226 ,יובלים ,102 ,נחל הוד
1226 ,יובלים ,110 ,נחל זויתן
1226 ,יובלים ,111 ,נחלים
1226 ,יובלים ,109 ,עין ורדית
1226 ,יובלים ,129 ,שביל אדמונית
1226 ,יובלים ,113 ,שביל איריס
1226 ,יובלים ,114 ,שביל אלה
1226 ,יובלים ,115 ,שביל אלון
1226 ,יובלים ,116 ,שביל ארז
1226 ,יובלים ,117 ,שביל ברוש
1226 ,יובלים ,118 ,שביל גביעונית
1226 ,יובלים ,119 ,שביל גדילן
1226 ,יובלים ,120 ,שביל גומא
1226 ,יובלים ,121 ,שביל גפן
1226 ,יובלים ,128 ,שביל גפנית
1226 ,יובלים ,122 ,שביל הדס
1226 ,יובלים ,123 ,שביל הרדוף
1226 ,יובלים ,124 ,שביל ורד
1226 ,יובלים ,125 ,שביל זית
1226 ,יובלים ,127 ,שביל חרוב
1112 ,יודפת ,112 ,בית העלמין
1112 ,יודפת ,113 ,האומנים
1112 ,יודפת ,111 ,הבאר
1112 ,יודפת ,109 ,הבוסתן
1112 ,יודפת ,116 ,הברושים
1112 ,יודפת ,117 ,הוורדים
1112 ,יודפת ,103 ,המורה
1112 ,יודפת ,101 ,המעגל
1112 ,יודפת ,106 ,המפעל
1112 ,יודפת ,104 ,המצפה
1112 ,יודפת ,102 ,המרכז
1112 ,יודפת ,105 ,העלייה
1112 ,יודפת ,110 ,הפרחים
1112 ,יודפת ,115 ,הפרסה
1112 ,יודפת ,107 ,הר עצמון
1112 ,יודפת ,108 ,התל
1112 ,יודפת ,9000 ,יודפת
1112 ,יודפת ,114 ,משמרת מימין
4007 ,יונתן ,107 ,אביטל
4007 ,יונתן ,111 ,אודם
4007 ,יונתן ,101 ,אל על
4007 ,יונתן ,112 ,אלון
4007 ,יונתן ,102 ,בזלת
4007 ,יונתן ,110 ,בשנית
4007 ,יונתן ,103 ,גמלא
4007 ,יונתן ,104 ,דליות
4007 ,יונתן ,120 ,דרך המייסדים
4007 ,יונתן ,121 ,דרך הממשיכים
4007 ,יונתן ,113 ,הגפן
4007 ,יונתן ,118 ,הדורבן
4007 ,יונתן ,116 ,הדס
4007 ,יונתן ,106 ,חרמון
4007 ,יונתן ,9000 ,יונתן
4007 ,יונתן ,117 ,כחל
4007 ,יונתן ,115 ,כליל החורש
4007 ,יונתן ,109 ,כרמים
4007 ,יונתן ,119 ,עיט
4007 ,יונתן ,105 ,שניר
4007 ,יונתן ,108 ,שפת הנחל
4007 ,יונתן ,122 ,תל פארג'
4007 ,יונתן ,114 ,תלתן
803 ,יושיביה ,9000 ,יושיביה
452 ,יזרעאל ,9000 ,יזרעאל
409 ,יחיעם ,9000 ,יחיעם
866 ,יטבתה ,7000 ,חי בר
866 ,יטבתה ,9000 ,יטבתה
3607 ,ייט"ב ,9000 ,ייט"ב
811 ,יכיני ,9000 ,יכיני
753 ,ינוב ,102 ,דרך החיטה
753 ,ינוב ,106 ,דרך השעורה
753 ,ינוב ,110 ,האורן
753 ,ינוב ,121 ,האלון
753 ,ינוב ,111 ,הארז
753 ,ינוב ,118 ,הדפנה
753 ,ינוב ,112 ,הדקל
753 ,ינוב ,113 ,הורדים
753 ,ינוב ,114 ,הלוטם
753 ,ינוב ,115 ,הצאלון
753 ,ינוב ,119 ,השיזף
753 ,ינוב ,116 ,השיטה
753 ,ינוב ,120 ,השקמה
753 ,ינוב ,9000 ,ינוב
753 ,ינוב ,105 ,מבוא הגפן
753 ,ינוב ,101 ,מורד התאנה
753 ,ינוב ,104 ,מעלה הרימון
753 ,ינוב ,103 ,נתיב הזית
753 ,ינוב ,117 ,שד התמר
2011 ,ינון ,9000 ,ינון
29 ,יסוד המעלה ,101 ,דרך השמורה
29 ,יסוד המעלה ,102 ,האגם
29 ,יסוד המעלה ,112 ,האילנות
29 ,יסוד המעלה ,103 ,האלונים
29 ,יסוד המעלה ,120 ,האשכולית
29 ,יסוד המעלה ,113 ,הבוסתן
29 ,יסוד המעלה ,106 ,הגפן
29 ,יסוד המעלה ,111 ,הורדים
29 ,יסוד המעלה ,109 ,הזית
29 ,יסוד המעלה ,107 ,החולה
29 ,יסוד המעלה ,104 ,החלוצים
29 ,יסוד המעלה ,117 ,הירדן
29 ,יסוד המעלה ,122 ,המגדל
29 ,יסוד המעלה ,116 ,המזח
29 ,יסוד המעלה ,108 ,המייסדים
29 ,יסוד המעלה ,114 ,הפרדסים
29 ,יסוד המעלה ,110 ,הרימונים
29 ,יסוד המעלה ,121 ,השזיף
29 ,יסוד המעלה ,105 ,השקד
29 ,יסוד המעלה ,118 ,התאנה
29 ,יסוד המעלה ,119 ,התמר
29 ,יסוד המעלה ,115 ,התעשיה
29 ,יסוד המעלה ,9000 ,יסוד המעלה
29 ,יסוד המעלה ,6500 ,ש האגם
440 ,יסודות ,9000 ,יסודות
575 ,יסעור ,9000 ,יסעור
1138 ,יעד ,105 ,אלת המסטיק
1138 ,יעד ,106 ,המייסדים
1138 ,יעד ,9000 ,יעד
1138 ,יעד ,108 ,מלא כרימון
1138 ,יעד ,102 ,נוף יבור
1138 ,יעד ,101 ,נחל חילזון
1138 ,יעד ,110 ,קו הרכס
1138 ,יעד ,109 ,ראש זית
1138 ,יעד ,103 ,רוח ים
1138 ,יעד ,104 ,רוח מזרחית
1138 ,יעד ,111 ,שירת הנוער
1138 ,יעד ,107 ,תריסר ברושים
1117 ,יעל ,9000 ,יעל
1044 ,יעף ,104 ,דרור
1044 ,יעף ,102 ,ינשוף
1044 ,יעף ,9000 ,יעף
1044 ,יעף ,105 ,נחליאלי
1044 ,יעף ,101 ,עגור
1044 ,יעף ,103 ,עפרוני
1044 ,יעף ,106 ,שחף
795 ,יערה ,9000 ,יערה
499 ,יפיע ,6730 ,אלאלמאני
499 ,יפיע ,6714 ,אלאמל
499 ,יפיע ,6710 ,אלביאדר
499 ,יפיע ,6722 ,אלבלד
499 ,יפיע ,6711 ,אלג'בל
499 ,יפיע ,6720 ,אלחבאא'ל
499 ,יפיע ,6732 ,אללקלום
499 ,יפיע ,6731 ,אלמדארס
499 ,יפיע ,6712 ,אלסירה
499 ,יפיע ,6723 ,אלעין
499 ,יפיע ,6741 ,אלעמאא'ר
499 ,יפיע ,6713 ,אלשמאלי
499 ,יפיע ,6752 ,אם אלעד'אם
499 ,יפיע ,6740 ,ואדי אלח'ארג'י
499 ,יפיע ,9000 ,יפיע
499 ,יפיע ,6721 ,מאר יעקוב
499 ,יפיע ,6742 ,מנזל אלנור
499 ,יפיע ,6750 ,מראח אלע'זלאן
499 ,יפיע ,6751 ,ראס אסמאעיל
499 ,יפיע ,300 ,רח 300
499 ,יפיע ,800 ,רח 800
499 ,יפיע ,110 ,רח 110
499 ,יפיע ,210 ,רח 210
499 ,יפיע ,310 ,רח 310
499 ,יפיע ,410 ,רח 410
499 ,יפיע ,510 ,רח 510
499 ,יפיע ,610 ,רח 610
499 ,יפיע ,710 ,רח 710
499 ,יפיע ,810 ,רח 810
499 ,יפיע ,120 ,רח 120
499 ,יפיע ,220 ,רח 220
499 ,יפיע ,320 ,רח 320
499 ,יפיע ,420 ,רח 420
499 ,יפיע ,520 ,רח 520
499 ,יפיע ,620 ,רח 620
499 ,יפיע ,130 ,רח 130
499 ,יפיע ,230 ,רח 230
499 ,יפיע ,330 ,רח 330
499 ,יפיע ,430 ,רח 430
499 ,יפיע ,140 ,רח 140
499 ,יפיע ,240 ,רח 240
499 ,יפיע ,250 ,רח 250
499 ,יפיע ,101 ,רח 101
499 ,יפיע ,201 ,רח 201
499 ,יפיע ,301 ,רח 301
499 ,יפיע ,401 ,רח 401
499 ,יפיע ,501 ,רח 501
499 ,יפיע ,601 ,רח 601
499 ,יפיע ,701 ,רח 701
499 ,יפיע ,801 ,רח 801
499 ,יפיע ,111 ,רח 111
499 ,יפיע ,211 ,רח 211
499 ,יפיע ,311 ,רח 311
499 ,יפיע ,411 ,רח 411
499 ,יפיע ,511 ,רח 511
499 ,יפיע ,611 ,רח 611
499 ,יפיע ,711 ,רח 711
499 ,יפיע ,811 ,רח 811
499 ,יפיע ,121 ,רח 121
499 ,יפיע ,221 ,רח 221
499 ,יפיע ,321 ,רח 321
499 ,יפיע ,421 ,רח 421
499 ,יפיע ,521 ,רח 521
499 ,יפיע ,621 ,רח 621
499 ,יפיע ,131 ,רח 131
499 ,יפיע ,231 ,רח 231
499 ,יפיע ,331 ,רח 331
499 ,יפיע ,631 ,רח 631
499 ,יפיע ,141 ,רח 141
499 ,יפיע ,241 ,רח 241
499 ,יפיע ,251 ,רח 251
499 ,יפיע ,102 ,רח 102
499 ,יפיע ,202 ,רח 202
499 ,יפיע ,302 ,רח 302
499 ,יפיע ,402 ,רח 402
499 ,יפיע ,502 ,רח 502
499 ,יפיע ,602 ,רח 602
499 ,יפיע ,702 ,רח 702
499 ,יפיע ,802 ,רח 802
499 ,יפיע ,112 ,רח 112
499 ,יפיע ,212 ,רח 212
499 ,יפיע ,312 ,רח 312
499 ,יפיע ,412 ,רח 412
499 ,יפיע ,512 ,רח 512
499 ,יפיע ,612 ,רח 612
499 ,יפיע ,712 ,רח 712
499 ,יפיע ,812 ,רח 812
499 ,יפיע ,122 ,רח 122
499 ,יפיע ,222 ,רח 222
499 ,יפיע ,322 ,רח 322
499 ,יפיע ,422 ,רח 422
499 ,יפיע ,522 ,רח 522
499 ,יפיע ,622 ,רח 622
499 ,יפיע ,132 ,רח 132
499 ,יפיע ,232 ,רח 232
499 ,יפיע ,332 ,רח 332
499 ,יפיע ,632 ,רח 632
499 ,יפיע ,142 ,רח 142
499 ,יפיע ,242 ,רח 242
499 ,יפיע ,252 ,רח 252
499 ,יפיע ,103 ,רח 103
499 ,יפיע ,203 ,רח 203
499 ,יפיע ,303 ,רח 303
499 ,יפיע ,403 ,רח 403
499 ,יפיע ,503 ,רח 503
499 ,יפיע ,603 ,רח 603
499 ,יפיע ,703 ,רח 703
499 ,יפיע ,803 ,רח 803
499 ,יפיע ,113 ,רח 113
499 ,יפיע ,213 ,רח 213
499 ,יפיע ,313 ,רח 313
499 ,יפיע ,413 ,רח 413
499 ,יפיע ,513 ,רח 513
499 ,יפיע ,613 ,רח 613
499 ,יפיע ,713 ,רח 713
499 ,יפיע ,813 ,רח 813
499 ,יפיע ,123 ,רח 123
499 ,יפיע ,223 ,רח 223
499 ,יפיע ,323 ,רח 323
499 ,יפיע ,423 ,רח 423
499 ,יפיע ,523 ,רח 523
499 ,יפיע ,623 ,רח 623
499 ,יפיע ,133 ,רח 133
499 ,יפיע ,233 ,רח 233
499 ,יפיע ,333 ,רח 333
499 ,יפיע ,633 ,רח 633
499 ,יפיע ,243 ,רח 243
499 ,יפיע ,253 ,רח 253
499 ,יפיע ,104 ,רח 104
499 ,יפיע ,204 ,רח 204
499 ,יפיע ,304 ,רח 304
499 ,יפיע ,404 ,רח 404
499 ,יפיע ,504 ,רח 504
499 ,יפיע ,604 ,רח 604
499 ,יפיע ,704 ,רח 704
499 ,יפיע ,804 ,רח 804
499 ,יפיע ,114 ,רח 114
499 ,יפיע ,214 ,רח 214
499 ,יפיע ,314 ,רח 314
499 ,יפיע ,414 ,רח 414
499 ,יפיע ,514 ,רח 514
499 ,יפיע ,614 ,רח 614
499 ,יפיע ,814 ,רח 814
499 ,יפיע ,124 ,רח 124
499 ,יפיע ,224 ,רח 224
499 ,יפיע ,324 ,רח 324
499 ,יפיע ,424 ,רח 424
499 ,יפיע ,524 ,רח 524
499 ,יפיע ,624 ,רח 624
499 ,יפיע ,134 ,רח 134
499 ,יפיע ,234 ,רח 234
499 ,יפיע ,334 ,רח 334
499 ,יפיע ,634 ,רח 634
499 ,יפיע ,254 ,רח 254
499 ,יפיע ,105 ,רח 105
499 ,יפיע ,205 ,רח 205
499 ,יפיע ,305 ,רח 305
499 ,יפיע ,405 ,רח 405
499 ,יפיע ,505 ,רח 505
499 ,יפיע ,605 ,רח 605
499 ,יפיע ,705 ,רח 705
499 ,יפיע ,805 ,רח 805
499 ,יפיע ,115 ,רח 115
499 ,יפיע ,215 ,רח 215
499 ,יפיע ,315 ,רח 315
499 ,יפיע ,515 ,רח 515
499 ,יפיע ,615 ,רח 615
499 ,יפיע ,815 ,רח 815
499 ,יפיע ,125 ,רח 125
499 ,יפיע ,225 ,רח 225
499 ,יפיע ,325 ,רח 325
499 ,יפיע ,425 ,רח 425
499 ,יפיע ,525 ,רח 525
499 ,יפיע ,625 ,רח 625
499 ,יפיע ,135 ,רח 135
499 ,יפיע ,235 ,רח 235
499 ,יפיע ,335 ,רח 335
499 ,יפיע ,635 ,רח 635
499 ,יפיע ,245 ,רח 245
499 ,יפיע ,255 ,רח 255
499 ,יפיע ,106 ,רח 106
499 ,יפיע ,206 ,רח 206
499 ,יפיע ,306 ,רח 306
499 ,יפיע ,406 ,רח 406
499 ,יפיע ,506 ,רח 506
499 ,יפיע ,606 ,רח 606
499 ,יפיע ,706 ,רח 706
499 ,יפיע ,806 ,רח 806
499 ,יפיע ,116 ,רח 116
499 ,יפיע ,216 ,רח 216
499 ,יפיע ,316 ,רח 316
499 ,יפיע ,416 ,רח 416
499 ,יפיע ,516 ,רח 516
499 ,יפיע ,616 ,רח 616
499 ,יפיע ,816 ,רח 816
499 ,יפיע ,126 ,רח 126
499 ,יפיע ,226 ,רח 226
499 ,יפיע ,326 ,רח 326
499 ,יפיע ,426 ,רח 426
499 ,יפיע ,626 ,רח 626
499 ,יפיע ,136 ,רח 136
499 ,יפיע ,236 ,רח 236
499 ,יפיע ,336 ,רח 336
499 ,יפיע ,246 ,רח 246
499 ,יפיע ,256 ,רח 256
499 ,יפיע ,107 ,רח 107
499 ,יפיע ,207 ,רח 207
499 ,יפיע ,307 ,רח 307
499 ,יפיע ,507 ,רח 507
499 ,יפיע ,607 ,רח 607
499 ,יפיע ,707 ,רח 707
499 ,יפיע ,807 ,רח 807
499 ,יפיע ,117 ,רח 117
499 ,יפיע ,217 ,רח 217
499 ,יפיע ,317 ,רח 317
499 ,יפיע ,417 ,רח 417
499 ,יפיע ,517 ,רח 517
499 ,יפיע ,617 ,רח 617
499 ,יפיע ,817 ,רח 817
499 ,יפיע ,127 ,רח 127
499 ,יפיע ,227 ,רח 227
499 ,יפיע ,327 ,רח 327
499 ,יפיע ,427 ,רח 427
499 ,יפיע ,627 ,רח 627
499 ,יפיע ,137 ,רח 137
499 ,יפיע ,237 ,רח 237
499 ,יפיע ,247 ,רח 247
499 ,יפיע ,257 ,רח 257
499 ,יפיע ,108 ,רח 108
499 ,יפיע ,208 ,רח 208
499 ,יפיע ,308 ,רח 308
499 ,יפיע ,408 ,רח 408
499 ,יפיע ,508 ,רח 508
499 ,יפיע ,608 ,רח 608
499 ,יפיע ,708 ,רח 708
499 ,יפיע ,808 ,רח 808
499 ,יפיע ,118 ,רח 118
499 ,יפיע ,218 ,רח 218
499 ,יפיע ,318 ,רח 318
499 ,יפיע ,418 ,רח 418
499 ,יפיע ,518 ,רח 518
499 ,יפיע ,618 ,רח 618
499 ,יפיע ,818 ,רח 818
499 ,יפיע ,128 ,רח 128
499 ,יפיע ,228 ,רח 228
499 ,יפיע ,328 ,רח 328
499 ,יפיע ,428 ,רח 428
499 ,יפיע ,628 ,רח 628
499 ,יפיע ,138 ,רח 138
499 ,יפיע ,238 ,רח 238
499 ,יפיע ,248 ,רח 248
499 ,יפיע ,258 ,רח 258
499 ,יפיע ,109 ,רח 109
499 ,יפיע ,209 ,רח 209
499 ,יפיע ,309 ,רח 309
499 ,יפיע ,409 ,רח 409
499 ,יפיע ,509 ,רח 509
499 ,יפיע ,609 ,רח 609
499 ,יפיע ,709 ,רח 709
499 ,יפיע ,809 ,רח 809
499 ,יפיע ,119 ,רח 119
499 ,יפיע ,219 ,רח 219
499 ,יפיע ,319 ,רח 319
499 ,יפיע ,419 ,רח 419
499 ,יפיע ,519 ,רח 519
499 ,יפיע ,619 ,רח 619
499 ,יפיע ,819 ,רח 819
499 ,יפיע ,129 ,רח 129
499 ,יפיע ,229 ,רח 229
499 ,יפיע ,329 ,רח 329
499 ,יפיע ,429 ,רח 429
499 ,יפיע ,629 ,רח 629
499 ,יפיע ,239 ,רח 239
499 ,יפיע ,249 ,רח 249
3566 ,יפית ,9000 ,יפית
134 ,יפעת ,101 ,בית ראשון במולדת
134 ,יפעת ,108 ,דרך החצר
134 ,יפעת ,102 ,דרך המשק
134 ,יפעת ,111 ,דרך ראשית ההתיישבות
134 ,יפעת ,112 ,הגיתות
134 ,יפעת ,106 ,הגנים
134 ,יפעת ,118 ,הכרם
134 ,יפעת ,110 ,הסלע
134 ,יפעת ,105 ,העמק
134 ,יפעת ,116 ,הפרדס
134 ,יפעת ,115 ,הצריפים
134 ,יפעת ,104 ,הראשונים
134 ,יפעת ,117 ,השדה
134 ,יפעת ,113 ,השרון-גבת
134 ,יפעת ,9000 ,יפעת
134 ,יפעת ,114 ,מעלה הגבעה
134 ,יפעת ,107 ,שביל החלב
134 ,יפעת ,103 ,שלושת הברושים
453 ,יפתח ,101 ,בית ראשון במולדת
453 ,יפתח ,9000 ,יפתח
3749 ,יצהר ,9000 ,יצהר
759 ,יציץ ,101 ,האלון
759 ,יציץ ,102 ,הגפן
759 ,יציץ ,103 ,הזית
759 ,יציץ ,104 ,הרימון
759 ,יציץ ,105 ,התאנה
759 ,יציץ ,106 ,התמר
759 ,יציץ ,9000 ,יציץ
759 ,יציץ ,107 ,סמ הדקל
759 ,יציץ ,108 ,סמ התפוז
417 ,יקום ,9000 ,יקום
3647 ,יקיר ,125 ,דליות
3647 ,יקיר ,120 ,דרך הצבי
3647 ,יקיר ,124 ,הגיתית
3647 ,יקיר ,107 ,הגפן
3647 ,יקיר ,106 ,הדרור
3647 ,יקיר ,109 ,הזית
3647 ,יקיר ,122 ,החליל
3647 ,יקיר ,123 ,הכינור
3647 ,יקיר ,114 ,הנבל
3647 ,יקיר ,121 ,הפעמון
3647 ,יקיר ,108 ,התאנה
3647 ,יקיר ,110 ,התמר
3647 ,יקיר ,126 ,יהלום
3647 ,יקיר ,9000 ,יקיר
3647 ,יקיר ,119 ,מבוא חמה
3647 ,יקיר ,118 ,מעלה השחר
3647 ,יקיר ,116 ,משעול החורש
3647 ,יקיר ,117 ,משעול התקוה
3647 ,יקיר ,104 ,נהר הירדן
3647 ,יקיר ,113 ,נחל דולב
3647 ,יקיר ,111 ,נחל דותן
3647 ,יקיר ,103 ,נחל הבשור
3647 ,יקיר ,112 ,נחל עוז
3647 ,יקיר ,101 ,נחל צין
3647 ,יקיר ,105 ,נחל שילה
3647 ,יקיר ,102 ,נחל תרצה
3647 ,יקיר ,127 ,ספיר
3647 ,יקיר ,115 ,שד ראשונים
3647 ,יקיר ,128 ,שוהם
241 ,יקנעם (מושבה) ,101 ,אגרא
241 ,יקנעם (מושבה) ,102 ,גבעת המגדל
241 ,יקנעם (מושבה) ,106 ,דרך העמק
241 ,יקנעם (מושבה) ,103 ,הגורן
241 ,יקנעם (מושבה) ,104 ,הגיא
241 ,יקנעם (מושבה) ,105 ,הגנים
241 ,יקנעם (מושבה) ,108 ,יפה נוף
241 ,יקנעם (מושבה) ,9000 ,יקנעם (מושבה)
241 ,יקנעם (מושבה) ,109 ,כליל החורש
241 ,יקנעם (מושבה) ,116 ,מעלה הבנים
241 ,יקנעם (מושבה) ,110 ,מעלה הגבעה
241 ,יקנעם (מושבה) ,111 ,מעלה הרקפות
241 ,יקנעם (מושבה) ,112 ,נוף יזרעאל
241 ,יקנעם (מושבה) ,113 ,נחל קרת
241 ,יקנעם (מושבה) ,114 ,נתיב הנוטרים
241 ,יקנעם (מושבה) ,115 ,שביל החלב
241 ,יקנעם (מושבה) ,107 ,שד הראשונים
240 ,יקנעם עילית ,220 ,אביטל
240 ,יקנעם עילית ,242 ,אורטל
240 ,יקנעם עילית ,6509 ,אזור המלאכה
240 ,יקנעם עילית ,223 ,אכזיב
240 ,יקנעם עילית ,250 ,אלון
240 ,יקנעם עילית ,167 ,אמנון ותמר
240 ,יקנעם עילית ,222 ,אפון
240 ,יקנעם עילית ,240 ,אפק
240 ,יקנעם עילית ,201 ,ארבל
240 ,יקנעם עילית ,241 ,בנטל
240 ,יקנעם עילית ,6511 ,גבעת האלונים
240 ,יקנעם עילית ,6513 ,גבעת יערה
240 ,יקנעם עילית ,6510 ,גבעת כלניות
240 ,יקנעם עילית ,219 ,גולן
240 ,יקנעם עילית ,226 ,גונן
240 ,יקנעם עילית ,252 ,גלבוע
240 ,יקנעם עילית ,6508 ,גני תעשיה
240 ,יקנעם עילית ,239 ,דליות
240 ,יקנעם עילית ,237 ,דן
240 ,יקנעם עילית ,180 ,דקל
240 ,יקנעם עילית ,269 ,דרך הארגמן
240 ,יקנעם עילית ,268 ,דרך היער
240 ,יקנעם עילית ,115 ,דרך העמק
240 ,יקנעם עילית ,249 ,האגם
240 ,יקנעם עילית ,164 ,האורן
240 ,יקנעם עילית ,158 ,האורנים
240 ,יקנעם עילית ,227 ,האילה
240 ,יקנעם עילית ,174 ,האילנות
240 ,יקנעם עילית ,125 ,האיריסים
240 ,יקנעם עילית ,127 ,האלה
240 ,יקנעם עילית ,154 ,האלונים
240 ,יקנעם עילית ,264 ,האקליפטוס
240 ,יקנעם עילית ,176 ,הארזים
240 ,יקנעם עילית ,229 ,האשל
240 ,יקנעם עילית ,260 ,הבוסתן
240 ,יקנעם עילית ,235 ,הבניאס
240 ,יקנעם עילית ,159 ,הברושים
240 ,יקנעם עילית ,251 ,הבשן
240 ,יקנעם עילית ,163 ,הגבורים
240 ,יקנעם עילית ,261 ,הגיא
240 ,יקנעם עילית ,253 ,הגלעד
240 ,יקנעם עילית ,263 ,הגפן
240 ,יקנעם עילית ,171 ,הדודאים
240 ,יקנעם עילית ,170 ,הדס
240 ,יקנעם עילית ,175 ,ההרדופים
240 ,יקנעם עילית ,108 ,הורדים
240 ,יקנעם עילית ,166 ,הזית
240 ,יקנעם עילית ,262 ,החורש
240 ,יקנעם עילית ,182 ,החצב
240 ,יקנעם עילית ,236 ,החצבני
240 ,יקנעם עילית ,225 ,החרמון
240 ,יקנעם עילית ,192 ,היזמה
240 ,יקנעם עילית ,116 ,היסמין
240 ,יקנעם עילית ,134 ,היצירה
240 ,יקנעם עילית ,177 ,היקינטון
240 ,יקנעם עילית ,118 ,הכלניות
240 ,יקנעם עילית ,132 ,הכרמל
240 ,יקנעם עילית ,133 ,המדע
240 ,יקנעם עילית ,233 ,המיצר
240 ,יקנעם עילית ,131 ,המלאכה
240 ,יקנעם עילית ,247 ,המפל
240 ,יקנעם עילית ,248 ,המצוק
240 ,יקנעם עילית ,107 ,הנוריות
240 ,יקנעם עילית ,109 ,הנרקיסים
240 ,יקנעם עילית ,172 ,הסחלבים
240 ,יקנעם עילית ,194 ,העוצמה
240 ,יקנעם עילית ,173 ,הערבה
240 ,יקנעם עילית ,135 ,העתיד
240 ,יקנעם עילית ,265 ,הפטל
240 ,יקנעם עילית ,254 ,הצופים
240 ,יקנעם עילית ,193 ,הצמיחה
240 ,יקנעם עילית ,119 ,הצפורנים
240 ,יקנעם עילית ,191 ,הקידמה
240 ,יקנעם עילית ,151 ,הרימון
240 ,יקנעם עילית ,157 ,הרקפות
240 ,יקנעם עילית ,161 ,השיטה
240 ,יקנעם עילית ,152 ,השקד
240 ,יקנעם עילית ,156 ,השקמה
240 ,יקנעם עילית ,153 ,התאנה
240 ,יקנעם עילית ,255 ,התבור
240 ,יקנעם עילית ,160 ,התמר
240 ,יקנעם עילית ,190 ,התנופה
240 ,יקנעם עילית ,130 ,התעשיה
240 ,יקנעם עילית ,6512 ,ורד העמקים
240 ,יקנעם עילית ,228 ,חורשת טל
240 ,יקנעם עילית ,121 ,חרצית
240 ,יקנעם עילית ,256 ,יודפת
240 ,יקנעם עילית ,224 ,יער אודם
240 ,יקנעם עילית ,162 ,יפה נוף
240 ,יקנעם עילית ,9000 ,יקנעם עילית
240 ,יקנעם עילית ,234 ,ירדן
240 ,יקנעם עילית ,232 ,ירמוך
240 ,יקנעם עילית ,165 ,כליל החורש
240 ,יקנעם עילית ,243 ,ליטאני
240 ,יקנעם עילית ,129 ,לילך
240 ,יקנעם עילית ,178 ,מורן
240 ,יקנעם עילית ,221 ,מירון
240 ,יקנעם עילית ,266 ,מעיין
240 ,יקנעם עילית ,120 ,מעלה הבנים
240 ,יקנעם עילית ,126 ,מרגנית
240 ,יקנעם עילית ,230 ,נופר
240 ,יקנעם עילית ,245 ,נחל הבשור
240 ,יקנעם עילית ,246 ,נחל השניים
240 ,יקנעם עילית ,244 ,נחל ערוגות
240 ,יקנעם עילית ,217 ,נחל שורק
240 ,יקנעם עילית ,181 ,סביון
240 ,יקנעם עילית ,122 ,סיגלית
240 ,יקנעם עילית ,257 ,סיני
240 ,יקנעם עילית ,124 ,סמטת המייסדים
240 ,יקנעם עילית ,123 ,סמטת הראשונים
240 ,יקנעם עילית ,259 ,עמק השלום
240 ,יקנעם עילית ,6515 ,פארק תעשיה ית"מ
240 ,יקנעם עילית ,128 ,פעמונית
240 ,יקנעם עילית ,155 ,צאלים
240 ,יקנעם עילית ,6517 ,קרית חינוך
240 ,יקנעם עילית ,179 ,רותם
240 ,יקנעם עילית ,6507 ,רמת יקנעם
240 ,יקנעם עילית ,6500 ,ש וילות
240 ,יקנעם עילית ,6502 ,ש חדש מערבי
240 ,יקנעם עילית ,6514 ,ש נוף העמק
240 ,יקנעם עילית ,6503 ,ש עמידר
240 ,יקנעם עילית ,6504 ,ש קוטג'
240 ,יקנעם עילית ,210 ,שד יצחק רבין
240 ,יקנעם עילית ,267 ,שד נשיאי ישראל
240 ,יקנעם עילית ,258 ,שדרות היובל
240 ,יקנעם עילית ,218 ,שומרון
240 ,יקנעם עילית ,238 ,שיאון
240 ,יקנעם עילית ,6519 ,שכ גבעת השיטה
240 ,יקנעם עילית ,6518 ,שכ המושבה החדשה
240 ,יקנעם עילית ,6516 ,שכ מרכז
240 ,יקנעם עילית ,6520 ,שכ שער הגיא
240 ,יקנעם עילית ,6505 ,שכון א דרום
240 ,יקנעם עילית ,6506 ,שכון א צפון
240 ,יקנעם עילית ,231 ,שניר
623 ,יראון ,101 ,בית ראשון במולדת
623 ,יראון ,9000 ,יראון
2026 ,ירדנה ,9000 ,ירדנה
831 ,ירוחם ,135 ,אבו חצירה
831 ,ירוחם ,141 ,אבן עזרא
831 ,ירוחם ,307 ,אבן-שפרוט
831 ,ירוחם ,319 ,אברהם
831 ,ירוחם ,145 ,אגמית
831 ,ירוחם ,303 ,אהוד מנור
831 ,ירוחם ,6510 ,אזור תעשייה זעירה
831 ,ירוחם ,6506 ,אזור תעשייה מזרח
831 ,ירוחם ,6511 ,אזור תעשייה מערב
831 ,ירוחם ,306 ,אל-חריזי
831 ,ירוחם ,127 ,אליהו הנביא
831 ,ירוחם ,132 ,אלכחייל
831 ,ירוחם ,311 ,אמא שלום
831 ,ירוחם ,148 ,אנפה
831 ,ירוחם ,230 ,אסימוב
831 ,ירוחם ,222 ,אשר
831 ,ירוחם ,202 ,בגין
831 ,ירוחם ,138 ,בורנשטיין צבי
831 ,ירוחם ,128 ,בית אשל
831 ,ירוחם ,229 ,בן לברט
831 ,ירוחם ,308 ,בן-סרוק
831 ,ירוחם ,309 ,בן-עטר
831 ,ירוחם ,6508 ,בנה ביתך
831 ,ירוחם ,216 ,בנימין
831 ,ירוחם ,101 ,בר כוכבא
831 ,ירוחם ,310 ,בר-יוחאי
831 ,ירוחם ,312 ,ברוריה
831 ,ירוחם ,246 ,גב זרחן
831 ,ירוחם ,249 ,גב חולית
831 ,ירוחם ,239 ,גב ימין
831 ,ירוחם ,243 ,גב עפרן
831 ,ירוחם ,244 ,גב צרור
831 ,ירוחם ,105 ,גבולות
831 ,ירוחם ,221 ,גד
831 ,ירוחם ,301 ,גולדברג לאה
831 ,ירוחם ,315 ,גולדה מאיר
831 ,ירוחם ,140 ,גולן
831 ,ירוחם ,103 ,גוש עציון
831 ,ירוחם ,147 ,גיבתון
831 ,ירוחם ,144 ,דוכיפת
831 ,ירוחם ,314 ,דונה גרציה
831 ,ירוחם ,188 ,דינה
831 ,ירוחם ,218 ,דן
831 ,ירוחם ,160 ,האופק
831 ,ירוחם ,130 ,הביל"ויים
831 ,ירוחם ,161 ,הברק
831 ,ירוחם ,151 ,הדקל
831 ,ירוחם ,136 ,ההגנה
831 ,ירוחם ,139 ,ההדסים
831 ,ירוחם ,211 ,ההדרים
831 ,ירוחם ,121 ,ההסתדרות
831 ,ירוחם ,131 ,הי הכהן
831 ,ירוחם ,162 ,הלבנה
831 ,ירוחם ,157 ,המעפילים
831 ,ירוחם ,163 ,המרום
831 ,ירוחם ,304 ,המשוררת
831 ,ירוחם ,120 ,הנביאים
831 ,ירוחם ,316 ,הנרייטה סאלד
831 ,ירוחם ,164 ,הסהר
831 ,ירוחם ,102 ,הפלמ"ח
831 ,ירוחם ,165 ,הפסגה
831 ,ירוחם ,166 ,הקשת
831 ,ירוחם ,109 ,הרב עוזיאל
831 ,ירוחם ,110 ,הרב קוק
831 ,ירוחם ,108 ,הרמב"ם
831 ,ירוחם ,104 ,הרצל
831 ,ירוחם ,167 ,השחר
831 ,ירוחם ,152 ,התמר
831 ,ירוחם ,300 ,ויקי שירן
831 ,ירוחם ,224 ,זבולון
831 ,ירוחם ,317 ,זוהרה אלפסייה
831 ,ירוחם ,326 ,זלדה
831 ,ירוחם ,119 ,חבקוק הנביא
831 ,ירוחם ,129 ,חובבי ציון
831 ,ירוחם ,125 ,חטיבת הנגב
831 ,ירוחם ,305 ,חיד"א
831 ,ירוחם ,235 ,חמה
831 ,ירוחם ,126 ,חשמונאים
831 ,ירוחם ,214 ,יהודה
831 ,ירוחם ,330 ,יהודה עמיחי
831 ,ירוחם ,215 ,יוסף
831 ,ירוחם ,133 ,יסמין
831 ,ירוחם ,323 ,יעקב
831 ,ירוחם ,321 ,יצחק
831 ,ירוחם ,207 ,יצחק בשביס זינגר
831 ,ירוחם ,106 ,יצחק שדה
831 ,ירוחם ,9000 ,ירוחם
831 ,ירוחם ,223 ,יששכר
831 ,ירוחם ,325 ,לאה
831 ,ירוחם ,217 ,לוי
831 ,ירוחם ,231 ,מאדים
831 ,ירוחם ,175 ,מבוא נחל מטמון
831 ,ירוחם ,209 ,מנדל
831 ,ירוחם ,180 ,מעגל האירוס
831 ,ירוחם ,153 ,מעגל האתרוג
831 ,ירוחם ,154 ,מעגל הגפן
831 ,ירוחם ,190 ,מעגל הזוגן
831 ,ירוחם ,181 ,מעגל החלמונית
831 ,ירוחם ,191 ,מעגל החצב
831 ,ירוחם ,192 ,מעגל היפרוק
831 ,ירוחם ,193 ,מעגל הכלנית
831 ,ירוחם ,194 ,מעגל הנורית
831 ,ירוחם ,195 ,מעגל הסתוונית
831 ,ירוחם ,183 ,מעגל העירית
831 ,ירוחם ,155 ,מעגל הערבה
831 ,ירוחם ,196 ,מעגל הפרג
831 ,ירוחם ,198 ,מעגל הצבעוני
831 ,ירוחם ,184 ,מעגל הרותם
831 ,ירוחם ,156 ,מעגל הרימון
831 ,ירוחם ,6507 ,נאות הדר
831 ,ירוחם ,233 ,נגה
831 ,ירוחם ,6505 ,נווה נוף
831 ,ירוחם ,6504 ,נווה עמק
831 ,ירוחם ,6509 ,נוף מדבר
831 ,ירוחם ,171 ,נחל אבנון
831 ,ירוחם ,178 ,נחל דוד
831 ,ירוחם ,158 ,נחל הבשור
831 ,ירוחם ,172 ,נחל חתירה
831 ,ירוחם ,176 ,נחל יעלים
831 ,ירוחם ,186 ,נחל עין בוקק
831 ,ירוחם ,179 ,נחל ערוגות
831 ,ירוחם ,159 ,נחל צאלים
831 ,ירוחם ,170 ,נחל צין
831 ,ירוחם ,177 ,נחל שועלים
831 ,ירוחם ,205 ,נלי זקס
831 ,ירוחם ,302 ,נעמי שמר
831 ,ירוחם ,219 ,נפתלי
831 ,ירוחם ,318 ,סוליקה חגואל
831 ,ירוחם ,124 ,סיני
831 ,ירוחם ,142 ,סלעית
831 ,ירוחם ,174 ,סמ נחל חביון
831 ,ירוחם ,173 ,סמ נחל ממשית
831 ,ירוחם ,182 ,סמטת הנמר
831 ,ירוחם ,197 ,סמטת הצבוע
831 ,ירוחם ,199 ,סמטת הקרקל
831 ,ירוחם ,200 ,סמטת השועל
831 ,ירוחם ,185 ,סמטת התן
831 ,ירוחם ,134 ,סנקר אשר
831 ,ירוחם ,204 ,עגנון
831 ,ירוחם ,116 ,עזרא הסופר
831 ,ירוחם ,248 ,עין זיק
831 ,ירוחם ,242 ,עין חווה
831 ,ירוחם ,236 ,עין ירקעם
831 ,ירוחם ,238 ,עין מור
831 ,ירוחם ,212 ,עין סהרונים
831 ,ירוחם ,240 ,עין עבדת
831 ,ירוחם ,237 ,עין עקב
831 ,ירוחם ,247 ,עין פרת
831 ,ירוחם ,245 ,עין צפית
831 ,ירוחם ,241 ,עין שביב
831 ,ירוחם ,111 ,עמוס הנביא
831 ,ירוחם ,143 ,עפרוני
831 ,ירוחם ,206 ,פנחס נבון
831 ,ירוחם ,203 ,פרס
831 ,ירוחם ,149 ,קנית
831 ,ירוחם ,6512 ,קרית אגיס
831 ,ירוחם ,213 ,ראובן
831 ,ירוחם ,327 ,רבי דוד בוזגלו
831 ,ירוחם ,122 ,רבי דוד מלול
831 ,ירוחם ,137 ,רבי כדיר בוכריס
831 ,ירוחם ,189 ,רבין
831 ,ירוחם ,322 ,רבקה
831 ,ירוחם ,234 ,רהב
831 ,ירוחם ,208 ,רוזלין זוסמן יאלו
831 ,ירוחם ,324 ,רחל
831 ,ירוחם ,225 ,ריה"ל
831 ,ירוחם ,150 ,ריטה לוי
831 ,ירוחם ,168 ,רכסים
831 ,ירוחם ,228 ,רס"ג
831 ,ירוחם ,201 ,רש"י
831 ,ירוחם ,226 ,רשב"ג
831 ,ירוחם ,232 ,שבתאי
831 ,ירוחם ,210 ,שד ורדימון
831 ,ירוחם ,187 ,שולמית
831 ,ירוחם ,6500 ,שכ אלי כהן
831 ,ירוחם ,6501 ,שכ בן גוריון
831 ,ירוחם ,6502 ,שכ יונתן
831 ,ירוחם ,6503 ,שכ שקד
831 ,ירוחם ,313 ,שלומציון
831 ,ירוחם ,227 ,שמואל הנגיד
831 ,ירוחם ,123 ,שמחוני אסף
831 ,ירוחם ,220 ,שמעון
831 ,ירוחם ,320 ,שרה
831 ,ירוחם ,146 ,תמירון
3000 ,ירושלים ,5658 ,א דרג'י
3000 ,ירושלים ,4213 ,א זוהור
3000 ,ירושלים ,4168 ,א טברי
3000 ,ירושלים ,5282 ,א טברי סמ2
3000 ,ירושלים ,4138 ,א טור
3000 ,ירושלים ,4382 ,א נהאדה
3000 ,ירושלים ,5660 ,א נח'יל
3000 ,ירושלים ,4380 ,א נתר
3000 ,ירושלים ,5251 ,א נתר סמ2
3000 ,ירושלים ,5255 ,א נתר סמ4
3000 ,ירושלים ,5256 ,א נתר סמ8
3000 ,ירושלים ,5257 ,א סאפא סמ11
3000 ,ירושלים ,4605 ,א סיוטי
3000 ,ירושלים ,4207 ,א סלייב
3000 ,ירושלים ,4022 ,א סעדייה
3000 ,ירושלים ,4073 ,א רוסול
3000 ,ירושלים ,5586 ,א שאבי
3000 ,ירושלים ,5105 ,א שאבי סמ1
3000 ,ירושלים ,4053 ,א שארף
3000 ,ירושלים ,5605 ,א שיפא סמ2
3000 ,ירושלים ,4250 ,א שמאעה
3000 ,ירושלים ,5541 ,א-זאויה אלהינדיה
3000 ,ירושלים ,4176 ,אאל אל-בית
3000 ,ירושלים ,5420 ,אאל אל-בית סמ10
3000 ,ירושלים ,5397 ,אאל אל-בית סמ1
3000 ,ירושלים ,5398 ,אאל אל-בית סמ2
3000 ,ירושלים ,5399 ,אאל אל-בית סמ4
3000 ,ירושלים ,5418 ,אאל אל-בית סמ6
3000 ,ירושלים ,5419 ,אאל אל-בית סמ8
3000 ,ירושלים ,5828 ,אאל טלב
3000 ,ירושלים ,5829 ,אאל טלב סמ1
3000 ,ירושלים ,1002 ,אבא אבן
3000 ,ירושלים ,3002 ,אבא אחימאיר
3000 ,ירושלים ,3058 ,אבא ברדיצב
3000 ,ירושלים ,1328 ,אבא חלקיה
3000 ,ירושלים ,3141 ,אבו אל-ליל
3000 ,ירושלים ,4667 ,אבו אלעלא אל מערי
3000 ,ירושלים ,5578 ,אבו אלקסם אשאבי
3000 ,ירושלים ,4424 ,אבו בכר א סדיק
3000 ,ירושלים ,5082 ,אבו בכר א סדיק סמ1
3000 ,ירושלים ,4152 ,אבו גנאם
3000 ,ירושלים ,4729 ,אבו ד'ר אלע'פארי
3000 ,ירושלים ,5244 ,אבו ד'ר ע'פארי סמ2
3000 ,ירושלים ,5246 ,אבו ד'ר ע'פארי סמ3
3000 ,ירושלים ,5245 ,אבו ד'ר ע'פארי סמ5
3000 ,ירושלים ,5421 ,אבו ד'ר ע'פארי סמ7
3000 ,ירושלים ,5763 ,אבו דוויח
3000 ,ירושלים ,5764 ,אבו דוויח סמ2
3000 ,ירושלים ,5765 ,אבו דוויח סמ4
3000 ,ירושלים ,5766 ,אבו דוויח סמ6
3000 ,ירושלים ,5137 ,אבו הוריירה סמ2
3000 ,ירושלים ,5138 ,אבו הוריירה סמ4
3000 ,ירושלים ,3218 ,אבו זיד אל-הילאל
3000 ,ירושלים ,4920 ,אבו חאמד
3000 ,ירושלים ,5248 ,אבו חאמד אל-ע'זאלי
3000 ,ירושלים ,4921 ,אבו חאמד סמ1
3000 ,ירושלים ,4692 ,אבו מאדי
3000 ,ירושלים ,5046 ,אבו מאדי סמ1
3000 ,ירושלים ,5047 ,אבו מאדי סמ3
3000 ,ירושלים ,5424 ,אבו ע'נים
3000 ,ירושלים ,5430 ,אבו ע'נים סמ10
3000 ,ירושלים ,5425 ,אבו ע'נים סמ1
3000 ,ירושלים ,5426 ,אבו ע'נים סמ3
3000 ,ירושלים ,5427 ,אבו ע'נים סמ5
3000 ,ירושלים ,5428 ,אבו ע'נים סמ7
3000 ,ירושלים ,5429 ,אבו ע'נים סמ8
3000 ,ירושלים ,4195 ,אבו עוביידה
3000 ,ירושלים ,5433 ,אבו עוביידה סמ10
3000 ,ירושלים ,5437 ,אבו עוביידה סמ20
3000 ,ירושלים ,5438 ,אבו עוביידה סמ21
3000 ,ירושלים ,5431 ,אבו עוביידה סמ2
3000 ,ירושלים ,5434 ,אבו עוביידה סמ12
3000 ,ירושלים ,5439 ,אבו עוביידה סמ22
3000 ,ירושלים ,5432 ,אבו עוביידה סמ4
3000 ,ירושלים ,5440 ,אבו עוביידה סמ24
3000 ,ירושלים ,5441 ,אבו עוביידה סמ26
3000 ,ירושלים ,5435 ,אבו עוביידה סמ17
3000 ,ירושלים ,5436 ,אבו עוביידה סמ19
3000 ,ירושלים ,4620 ,אבו פירס אל חמדני
3000 ,ירושלים ,5505 ,אבו פלאח
3000 ,ירושלים ,3112 ,אבו צוואנה
3000 ,ירושלים ,5701 ,אבו רביע
3000 ,ירושלים ,4699 ,אבו רוזאמה
3000 ,ירושלים ,4724 ,אבו ריאלה
3000 ,ירושלים ,3229 ,אבו ריאלה סמ2
3000 ,ירושלים ,5290 ,אבו ריאלה סמ4
3000 ,ירושלים ,4662 ,אבו תמאם
3000 ,ירושלים ,686 ,אבולעפיה
3000 ,ירושלים ,1762 ,אבטליון
3000 ,ירושלים ,1137 ,אבי יונה מיכאל
3000 ,ירושלים ,4586 ,אביגד נחמן
3000 ,ירושלים ,422 ,אביגדורי
3000 ,ירושלים ,1890 ,אביגור שאול
3000 ,ירושלים ,1725 ,אביגיל
3000 ,ירושלים ,463 ,אבידע
3000 ,ירושלים ,2020 ,אבידר יוסף
3000 ,ירושלים ,1093 ,אביזוהר
3000 ,ירושלים ,1796 ,אביטל
3000 ,ירושלים ,202 ,אבינדב
3000 ,ירושלים ,374 ,אבינועם ילין
3000 ,ירושלים ,1403 ,אביעד
3000 ,ירושלים ,4474 ,אביעזר
3000 ,ירושלים ,3015 ,אביר יעקב
3000 ,ירושלים ,1642 ,אבישי
3000 ,ירושלים ,735 ,אבישר
3000 ,ירושלים ,233 ,אביתר הכהן
3000 ,ירושלים ,464 ,אבל פן
3000 ,ירושלים ,1754 ,אבלס ולטר
3000 ,ירושלים ,5396 ,אבן אבו טאלב סמ1
3000 ,ירושלים ,4419 ,אבן בטוטה
3000 ,ירושלים ,4199 ,אבן ג'ובייר
3000 ,ירושלים ,4076 ,אבן ג'ראח
3000 ,ירושלים ,810 ,אבן גבירול
3000 ,ירושלים ,389 ,אבן האזל
3000 ,ירושלים ,4165 ,אבן ח'לדון
3000 ,ירושלים ,3294 ,אבן חארת'ה
3000 ,ירושלים ,3302 ,אבן חארת'ה סמ10
3000 ,ירושלים ,3295 ,אבן חארת'ה סמ1
3000 ,ירושלים ,3296 ,אבן חארת'ה סמ2
3000 ,ירושלים ,3303 ,אבן חארת'ה סמ12
3000 ,ירושלים ,3297 ,אבן חארת'ה סמ3
3000 ,ירושלים ,3298 ,אבן חארת'ה סמ5
3000 ,ירושלים ,3299 ,אבן חארת'ה סמ6
3000 ,ירושלים ,3300 ,אבן חארת'ה סמ7
3000 ,ירושלים ,3301 ,אבן חארת'ה סמ8
3000 ,ירושלים ,4422 ,אבן חווקל
3000 ,ירושלים ,4354 ,אבן חמאם
3000 ,ירושלים ,4099 ,אבן טולון
3000 ,ירושלים ,184 ,אבן יהושע
3000 ,ירושלים ,430 ,אבן ישראל
3000 ,ירושלים ,4745 ,אבן מסעוד
3000 ,ירושלים ,4619 ,אבן מרואן
3000 ,ירושלים ,4166 ,אבן סינא
3000 ,ירושלים ,652 ,אבן ספיר
3000 ,ירושלים ,806 ,אבן עזרא
3000 ,ירושלים ,4634 ,אבן רושד
3000 ,ירושלים ,5123 ,אבן רושד סמ1
3000 ,ירושלים ,4478 ,אבן שמואל
3000 ,ירושלים ,726 ,אבן שפרוט
3000 ,ירושלים ,1672 ,אבנר בן נר
3000 ,ירושלים ,1821 ,אבנר גרשון
3000 ,ירושלים ,4746 ,אבראהים בן אדהם
3000 ,ירושלים ,803 ,אברבנאל
3000 ,ירושלים ,539 ,אברהם ורדיגר
3000 ,ירושלים ,1639 ,אברהם כחילה
3000 ,ירושלים ,1551 ,אברהם מ.קירשנבוים
3000 ,ירושלים ,189 ,אברהם מסלונים
3000 ,ירושלים ,5160 ,אג'יאל
3000 ,ירושלים ,108 ,אגודת ספורט אליצור
3000 ,ירושלים ,1454 ,אגודת ספורט אס"א
3000 ,ירושלים ,1451 ,אגודת ספורט בית"ר
3000 ,ירושלים ,1452 ,אגודת ספורט הפועל
3000 ,ירושלים ,1455 ,אגודת ספורט מכבי
3000 ,ירושלים ,4559 ,אגמון
3000 ,ירושלים ,1173 ,אגמון אברהם
3000 ,ירושלים ,1159 ,אגסי שמעון
3000 ,ירושלים ,1522 ,אגרון
3000 ,ירושלים ,4960 ,אגרות משה
3000 ,ירושלים ,613 ,אגריפס
3000 ,ירושלים ,521 ,אדהאן יחייא
3000 ,ירושלים ,3650 ,אדיב אסעאף
3000 ,ירושלים ,191 ,אדלמן
3000 ,ירושלים ,472 ,אדלר שאול
3000 ,ירושלים ,5403 ,אדם
3000 ,ירושלים ,175 ,אדמון
3000 ,ירושלים ,1637 ,אדמונד סחייק
3000 ,ירושלים ,1534 ,אדמוני
3000 ,ירושלים ,124 ,אדניהו הכהן
3000 ,ירושלים ,1764 ,אהוד
3000 ,ירושלים ,680 ,אהל משה
3000 ,ירושלים ,602 ,אהל שלמה
3000 ,ירושלים ,232 ,אהלי יוסף
3000 ,ירושלים ,939 ,אהליאב
3000 ,ירושלים ,269 ,אהרון (שעשוע) עזרא
3000 ,ירושלים ,1939 ,אהרון כהן
3000 ,ירושלים ,1180 ,אהרונוב
3000 ,ירושלים ,1428 ,אהרוני
3000 ,ירושלים ,4819 ,אהרנפלד נחום
3000 ,ירושלים ,4577 ,אודם
3000 ,ירושלים ,4903 ,אוהל יהושע
3000 ,ירושלים ,3036 ,אולברייט ויליאם
3000 ,ירושלים ,1504 ,אוליפנט
3000 ,ירושלים ,1749 ,אוליצור
3000 ,ירושלים ,1299 ,אולשוונגר
3000 ,ירושלים ,4498 ,אולשן יצחק
3000 ,ירושלים ,109 ,אום אל דראג
3000 ,ירושלים ,5147 ,אום אל עמד
3000 ,ירושלים ,4708 ,אום אל עצאפיר
3000 ,ירושלים ,4930 ,אום אל עצאפיר סמ1
3000 ,ירושלים ,4931 ,אום אל עצאפיר סמ2
3000 ,ירושלים ,5291 ,אום אל עצאפיר סמ3
3000 ,ירושלים ,4733 ,אום אל צאמד
3000 ,ירושלים ,4747 ,אום אלמואמנין
3000 ,ירושלים ,4163 ,אום הרון
3000 ,ירושלים ,4221 ,אום טובא
3000 ,ירושלים ,4748 ,אום כולתום
3000 ,ירושלים ,4235 ,אום ליסון
3000 ,ירושלים ,5442 ,אום ליסון סמ1
3000 ,ירושלים ,5443 ,אום ליסון סמ2
3000 ,ירושלים ,5444 ,אום ליסון סמ3
3000 ,ירושלים ,5445 ,אום ליסון סמ4
3000 ,ירושלים ,5446 ,אום ליסון סמ6
3000 ,ירושלים ,5447 ,אום ליסון סמ8
3000 ,ירושלים ,903 ,אונקלוס
3000 ,ירושלים ,714 ,אוסישקין
3000 ,ירושלים ,253 ,אופירה
3000 ,ירושלים ,5021 ,אוצר הגאונים
3000 ,ירושלים ,208 ,אוצר הספרים
3000 ,ירושלים ,115 ,אור אלחנן
3000 ,ירושלים ,4527 ,אור החיים
3000 ,ירושלים ,146 ,אורבך אפרים
3000 ,ירושלים ,1231 ,אורוגואי
3000 ,ירושלים ,1545 ,אורי אורבך
3000 ,ירושלים ,3037 ,אורי בן ארי
3000 ,ירושלים ,183 ,אוריאל
3000 ,ירושלים ,5610 ,אוריאלי זכריהו
3000 ,ירושלים ,382 ,אורנשטיין
3000 ,ירושלים ,711 ,אושא
3000 ,ירושלים ,4701 ,אז תעש הר החוצבים
3000 ,ירושלים ,6594 ,אז תעשיה גב שאול
3000 ,ירושלים ,4132 ,אז תעשיה ואדי גוז
3000 ,ירושלים ,929 ,אזולאי עקיבא
3000 ,ירושלים ,6905 ,אזור תעשיה עטרות
3000 ,ירושלים ,6910 ,אזור תעשיה רוממה
3000 ,ירושלים ,6539 ,אזור תעשיה תלפיות
3000 ,ירושלים ,6901 ,אזור תעשייה ענתות
3000 ,ירושלים ,6907 ,אזור תעשייה שעפט
3000 ,ירושלים ,4273 ,אח'ואן אל צפא
3000 ,ירושלים ,1511 ,אחד העם
3000 ,ירושלים ,5858 ,אחוזת נוף
3000 ,ירושלים ,112 ,אחינעם
3000 ,ירושלים ,4560 ,אחלמה
3000 ,ירושלים ,4749 ,אחמד אבן חנבל
3000 ,ירושלים ,4925 ,אחמד אבן חנבל סמ 1
3000 ,ירושלים ,4926 ,אחמד אבן חנבל סמ2
3000 ,ירושלים ,4927 ,אחמד אבן חנבל סמ3
3000 ,ירושלים ,5292 ,אחמד אסעד אל דארי
3000 ,ירושלים ,5293 ,אחמד אסעד דארי סמ1
3000 ,ירושלים ,5294 ,אחמד אסעד דארי סמ3
3000 ,ירושלים ,5299 ,אחמד אסעד דארי סמ4
3000 ,ירושלים ,5306 ,אחמד אסעד דארי סמ5
3000 ,ירושלים ,5295 ,אחמד אסעד דארי סמ6
3000 ,ירושלים ,5296 ,אחמד אסעד דארי סמ7
3000 ,ירושלים ,5297 ,אחמד אסעד דארי סמ8
3000 ,ירושלים ,5298 ,אחמד אסעד דארי סמ9
3000 ,ירושלים ,3127 ,אחמד עדילה
3000 ,ירושלים ,4750 ,אחמד עלי
3000 ,ירושלים ,5307 ,אחמד עלי סמ1
3000 ,ירושלים ,5308 ,אחמד עלי סמ2
3000 ,ירושלים ,5309 ,אחמד עלי סמ4
3000 ,ירושלים ,5311 ,אחמד עלי סמ6
3000 ,ירושלים ,5312 ,אחמד עלי סמ8
3000 ,ירושלים ,4666 ,אחמד שאוקי
3000 ,ירושלים ,5144 ,אחמד שאוקי סמ3
3000 ,ירושלים ,5145 ,אחמד שאוקי סמ5
3000 ,ירושלים ,5146 ,אחמד שאוקי סמ8
3000 ,ירושלים ,1220 ,אטינגר
3000 ,ירושלים ,4597 ,איג אלכסנדר
3000 ,ירושלים ,4479 ,אידלזון אברהם
3000 ,ירושלים ,1229 ,אידר
3000 ,ירושלים ,4285 ,איחוד הכפר
3000 ,ירושלים ,1453 ,איילון דוד
3000 ,ירושלים ,4569 ,אילה
3000 ,ירושלים ,326 ,אילן מרדכי צבי
3000 ,ירושלים ,669 ,אילת
3000 ,ירושלים ,4169 ,אימרו אל קיס
3000 ,ירושלים ,5373 ,אימרו אל קיס סמ2
3000 ,ירושלים ,5346 ,אימרו אל קיס סמ6
3000 ,ירושלים ,1110 ,אינשטיין
3000 ,ירושלים ,4646 ,איסחאק אל מווסילי
3000 ,ירושלים ,5534 ,איסלייב
3000 ,ירושלים ,1285 ,איסלנד
3000 ,ירושלים ,5532 ,איסעאף נשאשיבי
3000 ,ירושלים ,6714 ,איצטדיון טדי
3000 ,ירושלים ,1802 ,איש מצליח
3000 ,ירושלים ,1040 ,איש שלום מרדכי
3000 ,ירושלים ,825 ,איתמר בן אבי
3000 ,ירושלים ,4652 ,איתן
3000 ,ירושלים ,4612 ,אל אדריסי
3000 ,ירושלים ,5374 ,אל אזאעה
3000 ,ירושלים ,5375 ,אל אזאעה סמ2
3000 ,ירושלים ,4410 ,אל אח'טל
3000 ,ירושלים ,5018 ,אל אחיאא
3000 ,ירושלים ,5040 ,אל אחילאס
3000 ,ירושלים ,5041 ,אל אחתראם
3000 ,ירושלים ,5043 ,אל אחתראם סמ1
3000 ,ירושלים ,5365 ,אל אחתראם סמ5
3000 ,ירושלים ,5044 ,אל אחתראם סמ15
3000 ,ירושלים ,5042 ,אל אחתראם סמ17
3000 ,ירושלים ,5045 ,אל אחתראם סמ9
3000 ,ירושלים ,5028 ,אל איאם
3000 ,ירושלים ,5032 ,אל איאם סמ5
3000 ,ירושלים ,2029 ,אל איאם סמ6
3000 ,ירושלים ,5031 ,אל איאם סמ7
3000 ,ירושלים ,5030 ,אל איאם סמ8
3000 ,ירושלים ,4050 ,אל אימאב מלק
3000 ,ירושלים ,4686 ,אל אימאם מוסלים
3000 ,ירושלים ,5038 ,אל אימאם מוסלים סמ4
3000 ,ירושלים ,5039 ,אל אימאם מוסלים סמ6
3000 ,ירושלים ,5513 ,אל אעואר
3000 ,ירושלים ,4051 ,אל אפדלייה
3000 ,ירושלים ,4639 ,אל אצמעי
3000 ,ירושלים ,5109 ,אל אצמעי סמ10
3000 ,ירושלים ,5110 ,אל אצמעי סמ4
3000 ,ירושלים ,5111 ,אל אצמעי סמ6
3000 ,ירושלים ,5112 ,אל אצמעי סמ8
3000 ,ירושלים ,4125 ,אל אקרוב
3000 ,ירושלים ,4631 ,אל ארדשיר
3000 ,ירושלים ,5280 ,אל ארז
3000 ,ירושלים ,5281 ,אל ארז סמ2
3000 ,ירושלים ,4085 ,אל ארמן
3000 ,ירושלים ,5531 ,אל באסי
3000 ,ירושלים ,9477 ,אל בארודי
3000 ,ירושלים ,4940 ,אל בארודי סמ4
3000 ,ירושלים ,4941 ,אל בארודי סמ6
3000 ,ירושלים ,4942 ,אל בארודי סמ8
3000 ,ירושלים ,5258 ,אל בדריה
3000 ,ירושלים ,5259 ,אל בדריה סמ2
3000 ,ירושלים ,593 ,אל בואסל
3000 ,ירושלים ,5519 ,אל בובריה
3000 ,ירושלים ,5161 ,אל בוח'ארי
3000 ,ירושלים ,4661 ,אל בוחתורי
3000 ,ירושלים ,5283 ,אל בוטום
3000 ,ירושלים ,4381 ,אל בוטמה
3000 ,ירושלים ,5455 ,אל בוטמה סמ1
3000 ,ירושלים ,4018 ,אל בוסטמי
3000 ,ירושלים ,4761 ,אל בוסתאן
3000 ,ירושלים ,5321 ,אל בוסתאן סמ10
3000 ,ירושלים ,3231 ,אל בוסתאן סמ11
3000 ,ירושלים ,5313 ,אל בוסתאן סמ2
3000 ,ירושלים ,5322 ,אל בוסתאן סמ12
3000 ,ירושלים ,3230 ,אל בוסתאן סמ3
3000 ,ירושלים ,5323 ,אל בוסתאן סמ13
3000 ,ירושלים ,5314 ,אל בוסתאן סמ4
3000 ,ירושלים ,5315 ,אל בוסתאן סמ5
3000 ,ירושלים ,5324 ,אל בוסתאן סמ15
3000 ,ירושלים ,5316 ,אל בוסתאן סמ6
3000 ,ירושלים ,5317 ,אל בוסתאן סמ7
3000 ,ירושלים ,3232 ,אל בוסתאן סמ17
3000 ,ירושלים ,5318 ,אל בוסתאן סמ8
3000 ,ירושלים ,5319 ,אל בוסתאן סמ9
3000 ,ירושלים ,4049 ,אל בורק
3000 ,ירושלים ,5284 ,אל בורתקאל
3000 ,ירושלים ,5285 ,אל בורתקאל סמ3
3000 ,ירושלים ,3356 ,אל בטן
3000 ,ירושלים ,4916 ,אל ביאדר
3000 ,ירושלים ,5325 ,אל ביאדר סמ1
3000 ,ירושלים ,4122 ,אל ביידר
3000 ,ירושלים ,5874 ,אל ביידר סמ1
3000 ,ירושלים ,5616 ,אל בלוטה סמ2
3000 ,ירושלים ,4983 ,אל בלח
3000 ,ירושלים ,5286 ,אל בללוט
3000 ,ירושלים ,5287 ,אל בללוט סמ1
3000 ,ירושלים ,4670 ,אל בניאס
3000 ,ירושלים ,5065 ,אל בניאס סמ2
3000 ,ירושלים ,5260 ,אל בראעם
3000 ,ירושלים ,5538 ,אל ג'בל אל ג'דיד
3000 ,ירושלים ,5775 ,אל ג'דיד
3000 ,ירושלים ,5776 ,אל ג'דיד סמ1
3000 ,ירושלים ,5777 ,אל ג'דיד סמ2
3000 ,ירושלים ,5778 ,אל ג'דיד סמ3
3000 ,ירושלים ,5779 ,אל ג'דיד סמ4
3000 ,ירושלים ,5780 ,אל ג'דיד סמ5
3000 ,ירושלים ,5781 ,אל ג'דיד סמ6
3000 ,ירושלים ,5782 ,אל ג'דיד סמ7
3000 ,ירושלים ,5783 ,אל ג'דיד סמ9
3000 ,ירושלים ,4693 ,אל ג'וזה
3000 ,ירושלים ,5539 ,אל ג'ורון
3000 ,ירושלים ,5261 ,אל ג'מעיה
3000 ,ירושלים ,5264 ,אל ג'מעיה אל תחתא
3000 ,ירושלים ,5265 ,אל ג'סר
3000 ,ירושלים ,4240 ,אל גאמע
3000 ,ירושלים ,4071 ,אל גבשה
3000 ,ירושלים ,5162 ,אל גדוד
3000 ,ירושלים ,5163 ,אל גדוד סמ3
3000 ,ירושלים ,5164 ,אל גדוד סמ4
3000 ,ירושלים ,5165 ,אל גדוד סמ7
3000 ,ירושלים ,4255 ,אל גול
3000 ,ירושלים ,5340 ,אל דאליה
3000 ,ירושלים ,4737 ,אל דהר
3000 ,ירושלים ,5133 ,אל דהר סמ3
3000 ,ירושלים ,4391 ,אל דוואר אל אוול
3000 ,ירושלים ,4934 ,אל דמיר
3000 ,ירושלים ,4935 ,אל דמיר סמ2
3000 ,ירושלים ,4936 ,אל דמיר סמ3
3000 ,ירושלים ,4937 ,אל דמיר סמ5
3000 ,ירושלים ,4938 ,אל דמיר סמ7
3000 ,ירושלים ,4939 ,אל דמיר סמ8
3000 ,ירושלים ,5341 ,אל דרראקה
3000 ,ירושלים ,5342 ,אל דרראקה סמ2
3000 ,ירושלים ,5343 ,אל דרראקה סמ3
3000 ,ירושלים ,4151 ,אל הדבה
3000 ,ירושלים ,5023 ,אל הודא
3000 ,ירושלים ,5024 ,אל הודא סמ11
3000 ,ירושלים ,5025 ,אל הודא סמ3
3000 ,ירושלים ,5026 ,אל הודא סמ7
3000 ,ירושלים ,5027 ,אל הודא סמ8
3000 ,ירושלים ,4738 ,אל הילאל
3000 ,ירושלים ,4004 ,אל ואד
3000 ,ירושלים ,5266 ,אל ווחדה
3000 ,ירושלים ,5456 ,אל ווחדה סמ2
3000 ,ירושלים ,5457 ,אל ווחדה סמ3
3000 ,ירושלים ,4399 ,אל וולאג'ה
3000 ,ירושלים ,4735 ,אל ווער
3000 ,ירושלים ,4763 ,אל ורד
3000 ,ירושלים ,5149 ,אל זהור
3000 ,ירושלים ,5150 ,אל זהור סמ1
3000 ,ירושלים ,5171 ,אל זהור סמ2
3000 ,ירושלים ,5617 ,אל זיתון סמ2
3000 ,ירושלים ,5345 ,אל זמבק
3000 ,ירושלים ,5580 ,אל ח'נסה
3000 ,ירושלים ,4671 ,אל חאלסה
3000 ,ירושלים ,5504 ,אל חארג'ה
3000 ,ירושלים ,5122 ,אל חג'אג' בן יוסף
3000 ,ירושלים ,5139 ,אל חג'אג' סמ10
3000 ,ירושלים ,5140 ,אל חג'אג' סמ4
3000 ,ירושלים ,5141 ,אל חג'אג' סמ5
3000 ,ירושלים ,4665 ,אל חגאג בן יוסף
3000 ,ירושלים ,5327 ,אל חיג'אר
3000 ,ירושלים ,5328 ,אל חיג'אר סמ2
3000 ,ירושלים ,3233 ,אל חיג'אר סמ4
3000 ,ירושלים ,5062 ,אל חכמה
3000 ,ירושלים ,4408 ,אל חמווי
3000 ,ירושלים ,4218 ,אל חניה
3000 ,ירושלים ,4407 ,אל חסבה
3000 ,ירושלים ,4042 ,אל חקארי
3000 ,ירושלים ,4141 ,אל חרדוב
3000 ,ירושלים ,3067 ,אל חרדוב סמ10
3000 ,ירושלים ,3046 ,אל חרדוב סמ1
3000 ,ירושלים ,3047 ,אל חרדוב סמ2
3000 ,ירושלים ,3068 ,אל חרדוב סמ12
3000 ,ירושלים ,3048 ,אל חרדוב סמ3
3000 ,ירושלים ,3115 ,אל חרדוב סמ13
3000 ,ירושלים ,3049 ,אל חרדוב סמ4
3000 ,ירושלים ,3050 ,אל חרדוב סמ5
3000 ,ירושלים ,3063 ,אל חרדוב סמ6
3000 ,ירושלים ,3116 ,אל חרדוב סמ16
3000 ,ירושלים ,3064 ,אל חרדוב סמ7
3000 ,ירושלים ,3065 ,אל חרדוב סמ8
3000 ,ירושלים ,3066 ,אל חרדוב סמ9
3000 ,ירושלים ,4694 ,אל חרמי שוקרי
3000 ,ירושלים ,5267 ,אל טאחונה
3000 ,ירושלים ,5268 ,אל טואל
3000 ,ירושלים ,5269 ,אל טואל סמ1
3000 ,ירושלים ,5606 ,אל טנטור סמ1
3000 ,ירושלים ,4139 ,אל כאלה
3000 ,ירושלים ,3129 ,אל כאלה סמ1
3000 ,ירושלים ,3130 ,אל כאלה סמ2
3000 ,ירושלים ,3019 ,אל כאלה סמ3
3000 ,ירושלים ,3131 ,אל כאלה סמ4
3000 ,ירושלים ,3021 ,אל כאלה סמ5
3000 ,ירושלים ,3022 ,אל כאלה סמ7
3000 ,ירושלים ,3039 ,אל כאלה סמ9
3000 ,ירושלים ,5579 ,אל כליף הישאם
3000 ,ירושלים ,4627 ,אל מאמון
3000 ,ירושלים ,5008 ,אל מאמון סמ10
3000 ,ירושלים ,5029 ,אל מאמון סמ4
3000 ,ירושלים ,5048 ,אל מאמון סמ6
3000 ,ירושלים ,5332 ,אל מדארס סמ4
3000 ,ירושלים ,4727 ,אל מדוורה
3000 ,ירושלים ,4386 ,אל מדרסה
3000 ,ירושלים ,4001 ,אל מוגהידין
3000 ,ירושלים ,4112 ,אל מוגראר
3000 ,ירושלים ,5068 ,אל מוודה
3000 ,ירושלים ,4697 ,אל מוטרן גבריל
3000 ,ירושלים ,4052 ,אל מונאדילין
3000 ,ירושלים ,5004 ,אל מונזר
3000 ,ירושלים ,5005 ,אל מונזר סמ6
3000 ,ירושלים ,4839 ,אל מונטאר
3000 ,ירושלים ,5703 ,אל מונטאר סמ2
3000 ,ירושלים ,3355 ,אל מונטאר סמ4
3000 ,ירושלים ,5271 ,אל מוסתשפא
3000 ,ירושלים ,5803 ,אל מועזמיה
3000 ,ירושלים ,4644 ,אל מועתסם
3000 ,ירושלים ,4271 ,אל מוקדסי
3000 ,ירושלים ,5349 ,אל מוקדסי סמ10
3000 ,ירושלים ,5377 ,אל מוקדסי סמ1
3000 ,ירושלים ,5366 ,אל מוקדסי סמ2
3000 ,ירושלים ,5347 ,אל מוקדסי סמ12
3000 ,ירושלים ,5350 ,אל מוקדסי סמ4
3000 ,ירושלים ,5344 ,אל מוקדסי סמ6
3000 ,ירושלים ,5348 ,אל מוקדסי סמ8
3000 ,ירושלים ,3600 ,אל מותנבי
3000 ,ירושלים ,4946 ,אל מחבה
3000 ,ירושלים ,5273 ,אל מיד'רא
3000 ,ירושלים ,4225 ,אל מישמיש
3000 ,ירושלים ,4092 ,אל מלך
3000 ,ירושלים ,5274 ,אל מנארה
3000 ,ירושלים ,5275 ,אל מנארה סמ1
3000 ,ירושלים ,5585 ,אל מנסוריה
3000 ,ירושלים ,4700 ,אל מסגיד
3000 ,ירושלים ,4736 ,אל מצאנע
3000 ,ירושלים ,5520 ,אל מקברה
3000 ,ירושלים ,4855 ,אל מקפה
3000 ,ירושלים ,5124 ,אל נאדר
3000 ,ירושלים ,5086 ,אל נג'אמה
3000 ,ירושלים ,5087 ,אל נג'אמה סמ3
3000 ,ירושלים ,5088 ,אל נג'אמה סמ5
3000 ,ירושלים ,5089 ,אל נג'אמה סמ7
3000 ,ירושלים ,5536 ,אל נרג'ס
3000 ,ירושלים ,5683 ,אל נרג'ס סמ1
3000 ,ירושלים ,5625 ,אל סאפח
3000 ,ירושלים ,5628 ,אל סאפח סמ3
3000 ,ירושלים ,5629 ,אל סאפח סמ4
3000 ,ירושלים ,4731 ,אל סהל
3000 ,ירושלים ,4074 ,אל סיידה
3000 ,ירושלים ,5277 ,אל סיל
3000 ,ירושלים ,5278 ,אל סילם
3000 ,ירושלים ,5067 ,אל סלאח
3000 ,ירושלים ,1292 ,אל סלוודור
3000 ,ירושלים ,4012 ,אל סלחייה
3000 ,ירושלים ,4232 ,אל סלעה
3000 ,ירושלים ,4993 ,אל סמאח
3000 ,ירושלים ,4994 ,אל סמאח סמ2
3000 ,ירושלים ,4687 ,אל ספדי אחמד
3000 ,ירושלים ,4742 ,אל ע'רבי
3000 ,ירושלים ,4995 ,אל ע'רבי סמ1
3000 ,ירושלים ,4996 ,אל ע'רבי סמ5
3000 ,ירושלים ,4716 ,אל עאזי איברהים
3000 ,ירושלים ,4642 ,אל עבאס
3000 ,ירושלים ,5512 ,אל עבאסייה
3000 ,ירושלים ,5838 ,אל עבאסייה סמ2
3000 ,ירושלים ,5839 ,אל עבאסייה סמ4
3000 ,ירושלים ,4013 ,אל עומרי
3000 ,ירושלים ,5279 ,אל עזזה
3000 ,ירושלים ,5085 ,אל עטאא
3000 ,ירושלים ,5518 ,אל עין
3000 ,ירושלים ,4717 ,אל עמר דהאר
3000 ,ירושלים ,4663 ,אל פאראבי
3000 ,ירושלים ,5081 ,אל פאראבי סמ1
3000 ,ירושלים ,5049 ,אל פאראבי סמ3
3000 ,ירושלים ,4130 ,אל קאע
3000 ,ירושלים ,4006 ,אל קדיסיה
3000 ,ירושלים ,4695 ,אל קובטיה מרים
3000 ,ירושלים ,4420 ,אל קובסא
3000 ,ירושלים ,4107 ,אל קוליה
3000 ,ירושלים ,4641 ,אל קינדי
3000 ,ירושלים ,4041 ,אל קירמי
3000 ,ירושלים ,4984 ,אל קמר
3000 ,ירושלים ,4987 ,אל קמר סמ4
3000 ,ירושלים ,4986 ,אל קמר סמ6
3000 ,ירושלים ,4988 ,אל קמר סמ8
3000 ,ירושלים ,4123 ,אל קסילה
3000 ,ירושלים ,4989 ,אל קסר
3000 ,ירושלים ,4398 ,אל קצאיל
3000 ,ירושלים ,4698 ,אל קרמי אבו סלמה
3000 ,ירושלים ,4718 ,אל ראוידה
3000 ,ירושלים ,4249 ,אל ראס
3000 ,ירושלים ,4719 ,אל שאפעיה זיינב
3000 ,ירושלים ,5036 ,אל שוקר
3000 ,ירושלים ,4384 ,אל שיפא
3000 ,ירושלים ,5037 ,אל תעאוון
3000 ,ירושלים ,5770 ,אל-אאמה
3000 ,ירושלים ,5771 ,אל-אאמה סמ1
3000 ,ירושלים ,5772 ,אל-אאמה סמ2
3000 ,ירושלים ,5773 ,אל-אאמה סמ3
3000 ,ירושלים ,5774 ,אל-אביאד
3000 ,ירושלים ,3170 ,אל-אביאד סמ11
3000 ,ירושלים ,3171 ,אל-אביאד סמ13
3000 ,ירושלים ,3167 ,אל-אביאד סמ5
3000 ,ירושלים ,3172 ,אל-אביאד סמ15
3000 ,ירושלים ,3168 ,אל-אביאד סמ7
3000 ,ירושלים ,3169 ,אל-אביאד סמ9
3000 ,ירושלים ,3069 ,אל-אוסתאד' תמאם
3000 ,ירושלים ,3113 ,אל-אוסתאד' תמאם סמ2
3000 ,ירושלים ,3114 ,אל-אוסתאד' תמאם סמ3
3000 ,ירושלים ,3070 ,אל-אוסתאד' תמאם סמ4
3000 ,ירושלים ,3071 ,אל-אוסתאד' תמאם סמ5
3000 ,ירושלים ,3072 ,אל-אוסתאד' תמאם סמ6
3000 ,ירושלים ,3073 ,אל-אוסתאד' תמאם סמ7
3000 ,ירושלים ,3074 ,אל-אוסתאד' תמאם סמ9
3000 ,ירושלים ,5627 ,אל-אמג'אד
3000 ,ירושלים ,5639 ,אל-אמג'אד סמ2
3000 ,ירושלים ,5666 ,אל-אמג'אד סמ4
3000 ,ירושלים ,5667 ,אל-אמג'אד סמ6
3000 ,ירושלים ,5668 ,אל-אמג'אד סמ8
3000 ,ירושלים ,3128 ,אל-אמינה
3000 ,ירושלים ,3173 ,אל-אנסאר
3000 ,ירושלים ,3174 ,אל-אנסאר סמ3
3000 ,ירושלים ,3175 ,אל-אנסאר סמ4
3000 ,ירושלים ,3176 ,אל-אנסאר סמ6
3000 ,ירושלים ,4167 ,אל-אספהאני
3000 ,ירושלים ,5615 ,אל-ביר
3000 ,ירושלים ,5194 ,אל-ביר אל-שרקי
3000 ,ירושלים ,5486 ,אל-ביר אל-שרקי סמ4
3000 ,ירושלים ,5491 ,אל-ביר אל-שרקי סמ5
3000 ,ירושלים ,5492 ,אל-ביר אל-שרקי סמ7
3000 ,ירושלים ,5493 ,אל-ביר אל-שרקי סמ9
3000 ,ירושלים ,5174 ,אל-בירכה
3000 ,ירושלים ,5494 ,אל-בית אל-עתיק
3000 ,ירושלים ,5537 ,אל-בלוטה
3000 ,ירושלים ,5495 ,אל-ברא'
3000 ,ירושלים ,5496 ,אל-ברא' סמ1
3000 ,ירושלים ,5497 ,אל-ברא' סמ3
3000 ,ירושלים ,5498 ,אל-ברא' סמ4
3000 ,ירושלים ,3152 ,אל-ג'בל
3000 ,ירושלים ,3153 ,אל-ג'בל סמ3
3000 ,ירושלים ,5784 ,אל-היל
3000 ,ירושלים ,5785 ,אל-היל סמ1
3000 ,ירושלים ,5786 ,אל-היל סמ3
3000 ,ירושלים ,5787 ,אל-היל סמ5
3000 ,ירושלים ,5788 ,אל-היל סמ6
3000 ,ירושלים ,5789 ,אל-היל סמ7
3000 ,ירושלים ,5790 ,אל-הרם
3000 ,ירושלים ,5791 ,אל-הרם סמ1
3000 ,ירושלים ,5792 ,אל-הרם סמ2
3000 ,ירושלים ,5589 ,אל-וועד
3000 ,ירושלים ,3137 ,אל-ופא'
3000 ,ירושלים ,3138 ,אל-ופא' סמ1
3000 ,ירושלים ,3139 ,אל-ופא' סמ4
3000 ,ירושלים ,5499 ,אל-זאוויה
3000 ,ירושלים ,4615 ,אל-זהרא'
3000 ,ירושלים ,4389 ,אל-זיתון
3000 ,ירושלים ,5458 ,אל-זנבק
3000 ,ירושלים ,5500 ,אל-חאמד
3000 ,ירושלים ,5793 ,אל-חארה שרקייה
3000 ,ירושלים ,5794 ,אל-חארה שרקייה סמ1
3000 ,ירושלים ,5795 ,אל-חארה שרקייה סמ2
3000 ,ירושלים ,5796 ,אל-חארה שרקייה סמ3
3000 ,ירושלים ,5797 ,אל-חארה שרקייה סמ4
3000 ,ירושלים ,5798 ,אל-חארה שרקייה סמ5
3000 ,ירושלים ,5799 ,אל-חארה שרקייה סמ6
3000 ,ירושלים ,5502 ,אל-חג'אב
3000 ,ירושלים ,5800 ,אל-חווא
3000 ,ירושלים ,5809 ,אל-חווא סמ1
3000 ,ירושלים ,5596 ,אל-חווארה
3000 ,ירושלים ,3234 ,אל-חיאה
3000 ,ירושלים ,3239 ,אל-חיאה סמ10
3000 ,ירושלים ,3235 ,אל-חיאה סמ1
3000 ,ירושלים ,3236 ,אל-חיאה סמ2
3000 ,ירושלים ,3237 ,אל-חיאה סמ4
3000 ,ירושלים ,3238 ,אל-חיאה סמ6
3000 ,ירושלים ,5326 ,אל-חיאה סמ8
3000 ,ירושלים ,4838 ,אל-חראיק
3000 ,ירושלים ,5329 ,אל-חראיק סמ1
3000 ,ירושלים ,5330 ,אל-חראיק סמ3
3000 ,ירושלים ,4274 ,אל-חרירי
3000 ,ירושלים ,5376 ,אל-חרירי סמ1
3000 ,ירושלים ,5459 ,אל-ט'הרה
3000 ,ירושלים ,5464 ,אל-ט'הרה סמ1
3000 ,ירושלים ,5465 ,אל-ט'הרה סמ2
3000 ,ירושלים ,5466 ,אל-ט'הרה סמ3
3000 ,ירושלים ,5467 ,אל-ט'הרה סמ4
3000 ,ירושלים ,5468 ,אל-ט'הרה סמ7
3000 ,ירושלים ,5597 ,אל-טנטור
3000 ,ירושלים ,5470 ,אל-טנטור סמ1
3000 ,ירושלים ,5598 ,אל-טפטאף
3000 ,ירושלים ,4200 ,אל-יעקובי
3000 ,ירושלים ,5877 ,אל-כורמא'
3000 ,ירושלים ,5887 ,אל-כורמא' סמ10
3000 ,ירושלים ,5878 ,אל-כורמא' סמ1
3000 ,ירושלים ,5879 ,אל-כורמא' סמ2
3000 ,ירושלים ,5880 ,אל-כורמא' סמ3
3000 ,ירושלים ,5881 ,אל-כורמא' סמ4
3000 ,ירושלים ,5882 ,אל-כורמא' סמ5
3000 ,ירושלים ,5883 ,אל-כורמא' סמ6
3000 ,ירושלים ,5884 ,אל-כורמא' סמ7
3000 ,ירושלים ,5885 ,אל-כורמא' סמ8
3000 ,ירושלים ,5886 ,אל-כורמא' סמ9
3000 ,ירושלים ,5469 ,אל-לימון
3000 ,ירושלים ,3287 ,אל-מא'ד'ון
3000 ,ירושלים ,3292 ,אל-מא'ד'ון סמ10
3000 ,ירושלים ,3293 ,אל-מא'ד'ון סמ12
3000 ,ירושלים ,3288 ,אל-מא'ד'ון סמ3
3000 ,ירושלים ,3289 ,אל-מא'ד'ון סמ5
3000 ,ירושלים ,3290 ,אל-מא'ד'ון סמ6
3000 ,ירושלים ,3291 ,אל-מא'ד'ון סמ8
3000 ,ירושלים ,3240 ,אל-מג'ד
3000 ,ירושלים ,3304 ,אל-מג'לס
3000 ,ירושלים ,3305 ,אל-מג'לס סמ1
3000 ,ירושלים ,3306 ,אל-מג'לס סמ2
3000 ,ירושלים ,3307 ,אל-מג'לס סמ4
3000 ,ירושלים ,5700 ,אל-מהאג'רין
3000 ,ירושלים ,5702 ,אל-מהאג'רין סמ2
3000 ,ירושלים ,4020 ,אל-מוולוויה
3000 ,ירושלים ,3308 ,אל-מוח'תאר
3000 ,ירושלים ,5272 ,אל-מוטלה
3000 ,ירושלים ,5813 ,אל-מונתזה
3000 ,ירושלים ,5819 ,אל-מונתזה סמ10
3000 ,ירושלים ,5814 ,אל-מונתזה סמ1
3000 ,ירושלים ,5815 ,אל-מונתזה סמ2
3000 ,ירושלים ,5820 ,אל-מונתזה סמ12
3000 ,ירושלים ,5816 ,אל-מונתזה סמ4
3000 ,ירושלים ,5817 ,אל-מונתזה סמ6
3000 ,ירושלים ,5818 ,אל-מונתזה סמ8
3000 ,ירושלים ,4086 ,אל-מוסתווסיף
3000 ,ירושלים ,5620 ,אל-מזאר
3000 ,ירושלים ,5175 ,אל-מחאג'ר
3000 ,ירושלים ,5687 ,אל-מחאג'ר סמ1
3000 ,ירושלים ,5197 ,אל-מחאג'ר סמ3
3000 ,ירושלים ,5688 ,אל-מחאג'ר סמ4
3000 ,ירושלים ,5689 ,אל-מחאג'ר סמ5
3000 ,ירושלים ,5690 ,אל-מחאג'ר סמ6
3000 ,ירושלים ,3310 ,אל-מטאר
3000 ,ירושלים ,3311 ,אל-מטאר סמ1
3000 ,ירושלים ,3312 ,אל-מטאר סמ3
3000 ,ירושלים ,3313 ,אל-מטאר סמ5
3000 ,ירושלים ,5607 ,אל-מיישה
3000 ,ירושלים ,3157 ,אל-מיעראג'
3000 ,ירושלים ,3158 ,אל-מיעראג' סמ1
3000 ,ירושלים ,3159 ,אל-מיעראג' סמ3
3000 ,ירושלים ,3160 ,אל-מיעראג' סמ4
3000 ,ירושלים ,3161 ,אל-מיעראג' סמ6
3000 ,ירושלים ,3162 ,אל-מיעראג' סמ7
3000 ,ירושלים ,5276 ,אל-מנג'ל
3000 ,ירושלים ,4613 ,אל-מסעודי
3000 ,ירושלים ,5378 ,אל-מסעודי סמ1
3000 ,ירושלים ,5691 ,אל-מעאני
3000 ,ירושלים ,3314 ,אל-מעהד
3000 ,ירושלים ,3315 ,אל-מעהד סמ1
3000 ,ירושלים ,3320 ,אל-מעהד סמ11
3000 ,ירושלים ,3316 ,אל-מעהד סמ3
3000 ,ירושלים ,3317 ,אל-מעהד סמ5
3000 ,ירושלים ,3318 ,אל-מעהד סמ7
3000 ,ירושלים ,3319 ,אל-מעהד סמ9
3000 ,ירושלים ,5692 ,אל-מעני סמ1
3000 ,ירושלים ,5177 ,אל-מקפה סמ10
3000 ,ירושלים ,5176 ,אל-מקפה סמ1
3000 ,ירושלים ,5178 ,אל-מקפה סמ12
3000 ,ירושלים ,5179 ,אל-מקפה סמ14
3000 ,ירושלים ,5181 ,אל-מקפה סמ5
3000 ,ירושלים ,5184 ,אל-מקפה סמ6
3000 ,ירושלים ,5180 ,אל-מקפה סמ16
3000 ,ירושלים ,5186 ,אל-מקפה סמ8
3000 ,ירושלים ,5187 ,אל-מקפה סמ9
3000 ,ירושלים ,5669 ,אל-מראבטין
3000 ,ירושלים ,5671 ,אל-מראבטין סמ1
3000 ,ירושלים ,5670 ,אל-מראבטין סמ2
3000 ,ירושלים ,5672 ,אל-מראבטין סמ3
3000 ,ירושלים ,5673 ,אל-מראבטין סמ4
3000 ,ירושלים ,5674 ,אל-מראבטין סמ5
3000 ,ירושלים ,5675 ,אל-מראבטין סמ6
3000 ,ירושלים ,5693 ,אל-מראבטין סמ7
3000 ,ירושלים ,5821 ,אל-מרוג'
3000 ,ירושלים ,5822 ,אל-מרוג' סמ1
3000 ,ירושלים ,5823 ,אל-מרוג' סמ2
3000 ,ירושלים ,5824 ,אל-מרוג' סמ3
3000 ,ירושלים ,5825 ,אל-מרוג' סמ4
3000 ,ירושלים ,5826 ,אל-מרוג' סמ5
3000 ,ירושלים ,5827 ,אל-מרוג' סמ6
3000 ,ירושלים ,3321 ,אל-מרכז אל-ג'מאהירי
3000 ,ירושלים ,4929 ,אל-משאהד
3000 ,ירושלים ,5189 ,אל-משאהד סמ1
3000 ,ירושלים ,5694 ,אל-משאהד סמ2
3000 ,ירושלים ,5188 ,אל-משאהד סמ3
3000 ,ירושלים ,5190 ,אל-משאהד סמ4
3000 ,ירושלים ,5695 ,אל-משאהד סמ5
3000 ,ירושלים ,5191 ,אל-משאהד סמ6
3000 ,ירושלים ,5696 ,אל-משאהד סמ7
3000 ,ירושלים ,5697 ,אל-משאהד סמ8
3000 ,ירושלים ,5379 ,אל-משרק
3000 ,ירושלים ,5380 ,אל-משרק סמ1
3000 ,ירושלים ,5381 ,אל-משרק סמ2
3000 ,ירושלים ,5830 ,אל-נאדי
3000 ,ירושלים ,5831 ,אל-נאדי סמ1
3000 ,ירושלים ,5832 ,אל-נאדי סמ3
3000 ,ירושלים ,5833 ,אל-נאדי סמ5
3000 ,ירושלים ,5621 ,אל-נבי יונס
3000 ,ירושלים ,5382 ,אל-נג'ום
3000 ,ירושלים ,5698 ,אל-נובלא'
3000 ,ירושלים ,5699 ,אל-נובלא' סמ2
3000 ,ירושלים ,5840 ,אל-נוורס
3000 ,ירושלים ,5834 ,אל-נפל
3000 ,ירושלים ,5835 ,אל-נפל סמ1
3000 ,ירושלים ,5836 ,אל-נפל סמ2
3000 ,ירושלים ,5622 ,אל-נת'יר סמ2
3000 ,ירושלים ,5623 ,אל-נת'יר סמ4
3000 ,ירושלים ,5624 ,אל-נת'יר סמ6
3000 ,ירושלים ,3163 ,אל-סאלחין
3000 ,ירושלים ,3164 ,אל-סאלחין סמ2
3000 ,ירושלים ,3165 ,אל-סאלחין סמ3
3000 ,ירושלים ,3166 ,אל-סאלחין סמ5
3000 ,ירושלים ,5704 ,אל-סביל
3000 ,ירושלים ,5706 ,אל-סביל סמ2
3000 ,ירושלים ,5707 ,אל-סביל סמ3
3000 ,ירושלים ,5708 ,אל-סביל סמ4
3000 ,ירושלים ,5709 ,אל-סביל סמ5
3000 ,ירושלים ,5705 ,אל-סביל סמ6
3000 ,ירושלים ,3023 ,אל-סהל אל-ג'דיד
3000 ,ירושלים ,3076 ,אל-סהל אל-ג'דיד סמ2
3000 ,ירושלים ,3077 ,אל-סהל אל-ג'דיד סמ4
3000 ,ירושלים ,3078 ,אל-סהל אל-ג'דיד סמ5
3000 ,ירושלים ,3117 ,אל-סהל אל-ג'דיד סמ6
3000 ,ירושלים ,3079 ,אל-סהל אל-ג'דיד סמ7
3000 ,ירושלים ,3118 ,אל-סהל אל-ג'דיד סמ8
3000 ,ירושלים ,3075 ,אל-סהל אל-ג'דידסמ10
3000 ,ירושלים ,4259 ,אל-סוויח
3000 ,ירושלים ,3177 ,אל-סוויח סמ3
3000 ,ירושלים ,5837 ,אל-סווסנה
3000 ,ירושלים ,5710 ,אל-סולטאן
3000 ,ירושלים ,5711 ,אל-סולטאן סמ1
3000 ,ירושלים ,5712 ,אל-סולטאן סמ2
3000 ,ירושלים ,5713 ,אל-סולטאן סמ4
3000 ,ירושלים ,5714 ,אל-סולטאן סמ5
3000 ,ירושלים ,5715 ,אל-סולטאן סמ6
3000 ,ירושלים ,5472 ,אל-סולם
3000 ,ירושלים ,5192 ,אל-סידר
3000 ,ירושלים ,5471 ,אל-סלאם
3000 ,ירושלים ,5716 ,אל-עדל
3000 ,ירושלים ,5717 ,אל-עדל סמ1
3000 ,ירושלים ,5718 ,אל-עדל סמ5
3000 ,ירושלים ,5719 ,אל-עדל סמ7
3000 ,ירושלים ,3178 ,אל-עהד
3000 ,ירושלים ,3179 ,אל-עהד סמ2
3000 ,ירושלים ,5383 ,אל-עולמא
3000 ,ירושלים ,5720 ,אל-עומרי
3000 ,ירושלים ,5721 ,אל-עומרי סמ3
3000 ,ירושלים ,5841 ,אל-עומריין
3000 ,ירושלים ,5842 ,אל-עומריין סמ1
3000 ,ירושלים ,5843 ,אל-עומריין סמ2
3000 ,ירושלים ,5844 ,אל-עשרק
3000 ,ירושלים ,5845 ,אל-עשרק סמ1
3000 ,ירושלים ,5846 ,אל-עשרק סמ3
3000 ,ירושלים ,4722 ,אל-פארוק
3000 ,ירושלים ,5847 ,אל-פארוק סמ1
3000 ,ירושלים ,5848 ,אל-פארוק סמ2
3000 ,ירושלים ,5849 ,אל-פארוק סמ3
3000 ,ירושלים ,3184 ,אל-פאתח
3000 ,ירושלים ,3322 ,אל-פורקאן
3000 ,ירושלים ,5850 ,אל-פרסאן
3000 ,ירושלים ,5851 ,אל-פרסאן סמ2
3000 ,ירושלים ,5852 ,אל-פרסאן סמ3
3000 ,ירושלים ,5853 ,אל-פרסאן סמ4
3000 ,ירושלים ,5854 ,אל-פרסאן סמ6
3000 ,ירושלים ,5855 ,אל-פרסאן סמ8
3000 ,ירושלים ,5856 ,אל-צלעה
3000 ,ירושלים ,5857 ,אל-צלעה סמ1
3000 ,ירושלים ,5859 ,אל-צלעה סמ2
3000 ,ירושלים ,5860 ,אל-צלעה סמ3
3000 ,ירושלים ,5861 ,אל-צלעה סמ4
3000 ,ירושלים ,5862 ,אל-צלעה סמ6
3000 ,ירושלים ,5863 ,אל-צלעה סמ8
3000 ,ירושלים ,4358 ,אל-צפא
3000 ,ירושלים ,5473 ,אל-צפא סמ2
3000 ,ירושלים ,5632 ,אל-צפא סמ4
3000 ,ירושלים ,5722 ,אל-קאדי אל-פאדל
3000 ,ירושלים ,5600 ,אל-קאעה
3000 ,ירושלים ,5474 ,אל-קאעה סמ1
3000 ,ירושלים ,3080 ,אל-קימה
3000 ,ירושלים ,4383 ,אל-קלעה
3000 ,ירושלים ,5633 ,אל-קצר
3000 ,ירושלים ,4377 ,אל-קש
3000 ,ירושלים ,5635 ,אל-ראביא
3000 ,ירושלים ,5636 ,אל-ראביא סמ2
3000 ,ירושלים ,3241 ,אל-רוא'יה
3000 ,ירושלים ,3242 ,אל-רוא'יה סמ1
3000 ,ירושלים ,3243 ,אל-רוא'יה סמ3
3000 ,ירושלים ,3244 ,אל-רוא'יה סמ4
3000 ,ירושלים ,3245 ,אל-רוא'יה סמ5
3000 ,ירושלים ,4366 ,אל-רחמה
3000 ,ירושלים ,5638 ,אל-רחמה סמ2
3000 ,ירושלים ,5483 ,אל-ריחאן
3000 ,ירושלים ,5193 ,אל-רסם
3000 ,ירושלים ,5723 ,אל-רסם סמ2
3000 ,ירושלים ,5724 ,אל-רסם סמ4
3000 ,ירושלים ,5875 ,אל-שהד
3000 ,ירושלים ,5876 ,אל-שהד סמ1
3000 ,ירושלים ,4150 ,אל-שיאח
3000 ,ירושלים ,5583 ,אל-שיח' ענבר
3000 ,ירושלים ,3107 ,אל-שיח' ענבר סמ10
3000 ,ירושלים ,3111 ,אל-שיח' ענבר סמ11
3000 ,ירושלים ,3104 ,אל-שיח' ענבר סמ2
3000 ,ירושלים ,3108 ,אל-שיח' ענבר סמ12
3000 ,ירושלים ,3105 ,אל-שיח' ענבר סמ6
3000 ,ירושלים ,3109 ,אל-שיח' ענבר סמ7
3000 ,ירושלים ,3106 ,אל-שיח' ענבר סמ8
3000 ,ירושלים ,3110 ,אל-שיח' ענבר סמ9
3000 ,ירושלים ,5864 ,אל-ת'ורי
3000 ,ירושלים ,5873 ,אל-ת'ורי סמ10
3000 ,ירושלים ,5865 ,אל-ת'ורי סמ2
3000 ,ירושלים ,5866 ,אל-ת'ורי סמ3
3000 ,ירושלים ,5867 ,אל-ת'ורי סמ4
3000 ,ירושלים ,5868 ,אל-ת'ורי סמ5
3000 ,ירושלים ,5869 ,אל-ת'ורי סמ6
3000 ,ירושלים ,5870 ,אל-ת'ורי סמ7
3000 ,ירושלים ,5871 ,אל-ת'ורי סמ8
3000 ,ירושלים ,5872 ,אל-ת'ורי סמ9
3000 ,ירושלים ,5484 ,אל-תווחיד סמ1
3000 ,ירושלים ,5485 ,אל-תווחיד סמ2
3000 ,ירושלים ,4891 ,אל-תין אל-ע'רבי
3000 ,ירושלים ,2346 ,אל-תסאמח
3000 ,ירושלים ,3247 ,אל-תסאמח סמ2
3000 ,ירושלים ,3248 ,אל-תסאמח סמ4
3000 ,ירושלים ,5725 ,אל-תראב
3000 ,ירושלים ,4856 ,אלאימאן
3000 ,ירושלים ,5125 ,אלאסראא
3000 ,ירושלים ,5479 ,אלבו יוסף
3000 ,ירושלים ,4857 ,אלביאן
3000 ,ירושלים ,4858 ,אלבירוני
3000 ,ירושלים ,5007 ,אלבירוני סמ10
3000 ,ירושלים ,5100 ,אלבירוני סמ4
3000 ,ירושלים ,4978 ,אלבירוני סמ8
3000 ,ירושלים ,1731 ,אלבק חנוך
3000 ,ירושלים ,346 ,אלגזי
3000 ,ירושלים ,4859 ,אלגניד
3000 ,ירושלים ,4864 ,אלהג'רה
3000 ,ירושלים ,5000 ,אלהיג'רה סמ1
3000 ,ירושלים ,4376 ,אלוני שחר
3000 ,ירושלים ,1168 ,אלוף יוחאי בן נון
3000 ,ירושלים ,1125 ,אלוף שאלתיאל
3000 ,ירושלים ,1425 ,אלוף שמחוני
3000 ,ירושלים ,4865 ,אלזבייר בן אלעוואם
3000 ,ירושלים ,1120 ,אלזה
3000 ,ירושלים ,4866 ,אלזיתונה
3000 ,ירושלים ,5166 ,אלזיתונה סמ10
3000 ,ירושלים ,5167 ,אלזיתונה סמ11
3000 ,ירושלים ,5168 ,אלזיתונה סמ12
3000 ,ירושלים ,5095 ,אלזיתונה סמ5
3000 ,ירושלים ,5169 ,אלזיתונה סמ7
3000 ,ירושלים ,5363 ,אלזיתונה סמ8
3000 ,ירושלים ,5170 ,אלזיתונה סמ9
3000 ,ירושלים ,5093 ,אלח'יר
3000 ,ירושלים ,3357 ,אלחארה אלווסטא
3000 ,ירושלים ,3358 ,אלחארה אלווסטא סמ2
3000 ,ירושלים ,3359 ,אלחארה אלווסטא סמ4
3000 ,ירושלים ,3360 ,אלחארה אלווסטא סמ8
3000 ,ירושלים ,4867 ,אלחדיביה
3000 ,ירושלים ,5064 ,אלחדיביה סמ3
3000 ,ירושלים ,5066 ,אלחדיביה סמ5
3000 ,ירושלים ,811 ,אלחריזי
3000 ,ירושלים ,4774 ,אלטמן אריה
3000 ,ירושלים ,1676 ,אלי כהן
3000 ,ירושלים ,928 ,אליאב יעקב
3000 ,ירושלים ,3017 ,אליאך יוסף
3000 ,ירושלים ,1844 ,אליאל דרור
3000 ,ירושלים ,1519 ,אליאשברג
3000 ,ירושלים ,1600 ,אליהו איקו חוצ'ה
3000 ,ירושלים ,615 ,אליהו יעקב בנאי
3000 ,ירושלים ,1529 ,אליוט
3000 ,ירושלים ,4488 ,אלימלך מלז'ינסק
3000 ,ירושלים ,1318 ,אליעזר הגדול
3000 ,ירושלים ,270 ,אליעזר שילוני
3000 ,ירושלים ,400 ,אליעזרוב
3000 ,ירושלים ,234 ,אליפז
3000 ,ירושלים ,4836 ,אליצור יהודה
3000 ,ירושלים ,4868 ,אלירמוך
3000 ,ירושלים ,212 ,אלישיב
3000 ,ירושלים ,510 ,אלישע
3000 ,ירושלים ,418 ,אלישר
3000 ,ירושלים ,4869 ,אלכארמה
3000 ,ירושלים ,4691 ,אלכסנדר אל ח'ורי
3000 ,ירושלים ,1311 ,אלכסנדריון
3000 ,ירושלים ,5195 ,אלמדינה מונורה
3000 ,ירושלים ,5369 ,אלמדינה מונורה סמ5
3000 ,ירושלים ,5686 ,אלמדינה מונורה סמ15
3000 ,ירושלים ,5370 ,אלמדינה מונורה סמ6
3000 ,ירושלים ,5371 ,אלמדינה מונורה סמ7
3000 ,ירושלים ,5372 ,אלמדינה מונורה סמ8
3000 ,ירושלים ,5685 ,אלמדינה מונורה סמ9
3000 ,ירושלים ,4576 ,אלמוג
3000 ,ירושלים ,3309 ,אלמוח'תאר סמ1
3000 ,ירושלים ,4872 ,אלמות'ינא בן חארת'א
3000 ,ירושלים ,4873 ,אלמזדלפה
3000 ,ירושלים ,1367 ,אלמליח אברהם
3000 ,ירושלים ,4014 ,אלמלכ אלמועט'ם עיסא
3000 ,ירושלים ,4874 ,אלמסק
3000 ,ירושלים ,4875 ,אלמרוחה
3000 ,ירושלים ,5075 ,אלמרוחה סמ10
3000 ,ירושלים ,5076 ,אלמרוחה סמ11
3000 ,ירושלים ,5077 ,אלמרוחה סמ12
3000 ,ירושלים ,5071 ,אלמרוחה סמ3
3000 ,ירושלים ,5078 ,אלמרוחה סמ13
3000 ,ירושלים ,5079 ,אלמרוחה סמ14
3000 ,ירושלים ,5070 ,אלמרוחה סמ5
3000 ,ירושלים ,5080 ,אלמרוחה סמ15
3000 ,ירושלים ,5072 ,אלמרוחה סמ7
3000 ,ירושלים ,5073 ,אלמרוחה סמ8
3000 ,ירושלים ,5074 ,אלמרוחה סמ9
3000 ,ירושלים ,4876 ,אלמרכז
3000 ,ירושלים ,4877 ,אלנור
3000 ,ירושלים ,5090 ,אלנור סמ4
3000 ,ירושלים ,4878 ,אלסבר
3000 ,ירושלים ,4879 ,אלסדאקה
3000 ,ירושלים ,5033 ,אלסדאקה סמ10
3000 ,ירושלים ,5034 ,אלסדאקה סמ12
3000 ,ירושלים ,5035 ,אלסדאקה סמ5
3000 ,ירושלים ,3120 ,אלסהל אלאח'דר
3000 ,ירושלים ,3125 ,אלסהל אלאח'דר סמ10
3000 ,ירושלים ,3121 ,אלסהל אלאח'דר סמ2
3000 ,ירושלים ,3126 ,אלסהל אלאח'דר סמ12
3000 ,ירושלים ,3122 ,אלסהל אלאח'דר סמ4
3000 ,ירושלים ,3123 ,אלסהל אלאח'דר סמ6
3000 ,ירושלים ,3124 ,אלסהל אלאח'דר סמ7
3000 ,ירושלים ,4880 ,אלסנאבל
3000 ,ירושלים ,4881 ,אלסעאדה
3000 ,ירושלים ,4648 ,אלעזר דוד
3000 ,ירושלים ,1323 ,אלעזר המודעי
3000 ,ירושלים ,339 ,אלעזר המכבי
3000 ,ירושלים ,5120 ,אלעלם
3000 ,ירושלים ,5121 ,אלעלם סמ3
3000 ,ירושלים ,5054 ,אלעקבה
3000 ,ירושלים ,5059 ,אלעקבה סמ2
3000 ,ירושלים ,5056 ,אלעקבה סמ5
3000 ,ירושלים ,5055 ,אלעקבה סמ7
3000 ,ירושלים ,5057 ,אלעקבה סמ8
3000 ,ירושלים ,110 ,אלעשה
3000 ,ירושלים ,4882 ,אלפג'ר
3000 ,ירושלים ,332 ,אלפנדרי
3000 ,ירושלים ,812 ,אלפסי
3000 ,ירושלים ,4883 ,אלפרדוס
3000 ,ירושלים ,931 ,אלקבץ
3000 ,ירושלים ,4884 ,אלקות'ר
3000 ,ירושלים ,4331 ,אלקחי מרדכי
3000 ,ירושלים ,1507 ,אלקלעי
3000 ,ירושלים ,315 ,אלקנה
3000 ,ירושלים ,4885 ,אלקעקאע
3000 ,ירושלים ,4602 ,אלראזי
3000 ,ירושלים ,4886 ,אלרדואן
3000 ,ירושלים ,5092 ,אלרדואן סמ3
3000 ,ירושלים ,1628 ,אלרואי דוד
3000 ,ירושלים ,4887 ,אלרסאלה
3000 ,ירושלים ,5126 ,אלרסאלה סמ3
3000 ,ירושלים ,368 ,אלשיך
3000 ,ירושלים ,4889 ,אלשימאא
3000 ,ירושלים ,5726 ,אלתון
3000 ,ירושלים ,5727 ,אלתון סמ2
3000 ,ירושלים ,5728 ,אלתון סמ4
3000 ,ירושלים ,5729 ,אלתון סמ5
3000 ,ירושלים ,5730 ,אלתון סמ7
3000 ,ירושלים ,4890 ,אלתין
3000 ,ירושלים ,5143 ,אלתקווא
3000 ,ירושלים ,5480 ,אם הבנים
3000 ,ירושלים ,1464 ,אמא שלום
3000 ,ירושלים ,5423 ,אמאם אבו חניפה סמ1
3000 ,ירושלים ,4668 ,אמאם אלבוח'ארי
3000 ,ירושלים ,5364 ,אמאם אלבוח'ארי סמ3
3000 ,ירושלים ,148 ,אמדורסקי ירחמיאל
3000 ,ירושלים ,1530 ,אמיל בוטה
3000 ,ירושלים ,1653 ,אמיל זולא
3000 ,ירושלים ,1033 ,אמיר
3000 ,ירושלים ,3226 ,אמיר דרורי
3000 ,ירושלים ,487 ,אמנון ליפקין-שחק
3000 ,ירושלים ,1633 ,אמציה
3000 ,ירושלים ,986 ,אמרי בינה
3000 ,ירושלים ,3034 ,אמרי חיים
3000 ,ירושלים ,284 ,אמרי נועם
3000 ,ירושלים ,4780 ,אמרי קלמן
3000 ,ירושלים ,3275 ,אמת המים
3000 ,ירושלים ,179 ,אמת ליעקב
3000 ,ירושלים ,5410 ,אנוסי משהד
3000 ,ירושלים ,4720 ,אנטון שוקרי לורנס
3000 ,ירושלים ,5801 ,אנטוניה
3000 ,ירושלים ,742 ,אנטוקולסקי מרדכי
3000 ,ירושלים ,1316 ,אנטיגנוס
3000 ,ירושלים ,1206 ,אנילביץ
3000 ,ירושלים ,4734 ,אנסארי
3000 ,ירושלים ,4625 ,אנסארי חסן בן ת'אבת
3000 ,ירושלים ,1635 ,אסא
3000 ,ירושלים ,4248 ,אסבעקאת
3000 ,ירושלים ,5001 ,אסטורה
3000 ,ירושלים ,4454 ,אסירי ציון
3000 ,ירושלים ,267 ,אסתר בר חיים
3000 ,ירושלים ,1790 ,אסתר המלכה
3000 ,ירושלים ,4888 ,אע'ביתה
3000 ,ירושלים ,5254 ,אע'ביתה סמ2
3000 ,ירושלים ,5731 ,אע'זייל
3000 ,ירושלים ,5732 ,אע'זייל סמ1
3000 ,ירושלים ,5733 ,אע'זייל סמ3
3000 ,ירושלים ,5734 ,אע'זייל סמ6
3000 ,ירושלים ,287 ,אעלה בתמר
3000 ,ירושלים ,821 ,אפודי
3000 ,ירושלים ,1772 ,אפרים
3000 ,ירושלים ,4178 ,אפרסמון
3000 ,ירושלים ,1735 ,אפרתה
3000 ,ירושלים ,1230 ,אפשטיין יעקב נחום
3000 ,ירושלים ,1244 ,אפשטיין יצחק
3000 ,ירושלים ,4315 ,אצ"ל
3000 ,ירושלים ,324 ,ארבל
3000 ,ירושלים ,857 ,ארבעת המינים
3000 ,ירושלים ,1440 ,ארגוב אליעזר
3000 ,ירושלים ,4119 ,ארד א סמר
3000 ,ירושלים ,4206 ,ארד אל עקבה
3000 ,ירושלים ,1811 ,ארונה היבוסי
3000 ,ירושלים ,261 ,ארזי הבירה
3000 ,ירושלים ,4812 ,ארזי ראובן
3000 ,ירושלים ,4489 ,ארי במסתרים
3000 ,ירושלים ,917 ,אריאל
3000 ,ירושלים ,1900 ,אריאל שרון
3000 ,ירושלים ,419 ,אריסטובלוס
3000 ,ירושלים ,1339 ,אריסטיאס
3000 ,ירושלים ,813 ,ארלוזורוב
3000 ,ירושלים ,1188 ,ארנון יעקב
3000 ,ירושלים ,6784 ,ארנונה הצעירה
3000 ,ירושלים ,1392 ,ארסט אברהם
3000 ,ירושלים ,236 ,ארץ חפץ
3000 ,ירושלים ,5411 ,ארציאלי חוה
3000 ,ירושלים ,4090 ,אררט
3000 ,ירושלים ,4455 ,אשכולי אהרון
3000 ,ירושלים ,119 ,אשכנזי
3000 ,ירושלים ,1789 ,אשר
3000 ,ירושלים ,366 ,אשתורי הפרחי
3000 ,ירושלים ,402 ,אתיופיה
3000 ,ירושלים ,6515 ,ב אונגר מאה שערים
3000 ,ירושלים ,4109 ,ב חנינה קלנדיה
3000 ,ירושלים ,518 ,ב טנוס מרכז מסחרי
3000 ,ירושלים ,6516 ,ב מילנר שכ ב
3000 ,ירושלים ,6517 ,ב ניטין מאה שערים
3000 ,ירושלים ,6518 ,ב סטואר מאה שערים
3000 ,ירושלים ,6519 ,ב רוט מאה שערים
3000 ,ירושלים ,4153 ,באב א זהרה
3000 ,ירושלים ,4253 ,באב א מגרבה
3000 ,ירושלים ,4173 ,באב א סיפה
3000 ,ירושלים ,4032 ,באב אל חדיד
3000 ,ירושלים ,4003 ,באב אל עמוד
3000 ,ירושלים ,1893 ,באום שלמה
3000 ,ירושלים ,4477 ,באזוב דוד
3000 ,ירושלים ,1665 ,באכר זאב
3000 ,ירושלים ,1054 ,באר מים חיים
3000 ,ירושלים ,654 ,באר שבע
3000 ,ירושלים ,1678 ,בארות יצחק
3000 ,ירושלים ,447 ,בארי
3000 ,ירושלים ,4005 ,בב חוטה
3000 ,ירושלים ,4844 ,בבלי חנה
3000 ,ירושלים ,1671 ,בג'יו חיים
3000 ,ירושלים ,5602 ,בדור
3000 ,ירושלים ,4740 ,בדר
3000 ,ירושלים ,5069 ,בדר סמ4
3000 ,ירושלים ,4198 ,בהא אל-דין
3000 ,ירושלים ,180 ,בהרן שלמה זלמן
3000 ,ירושלים ,4447 ,בובליק גדליה
3000 ,ירושלים ,4359 ,בובר מרטין
3000 ,ירושלים ,1221 ,בודנהימר
3000 ,ירושלים ,1869 ,בוזגלו אשריאל
3000 ,ירושלים ,1237 ,בוליביה
3000 ,ירושלים ,1882 ,בונהונטורה אנצ'ו
3000 ,ירושלים ,4504 ,בוני החומה
3000 ,ירושלים ,1619 ,בוסתנאי
3000 ,ירושלים ,1779 ,בועז
3000 ,ירושלים ,288 ,בועז הבבלי
3000 ,ירושלים ,4951 ,בוקסבאום מרדכי
3000 ,ירושלים ,4260 ,בורג אל פדי
3000 ,ירושלים ,4011 ,בורג לקלק
3000 ,ירושלים ,1205 ,בורוכוב
3000 ,ירושלים ,1116 ,בורלא יהודה
3000 ,ירושלים ,4562 ,בושם
3000 ,ירושלים ,1000 ,בזל
3000 ,ירושלים ,1132 ,בזק בצלאל
3000 ,ירושלים ,1850 ,בטיש שמעון
3000 ,ירושלים ,4204 ,בטן אל-הווא
3000 ,ירושלים ,3183 ,בטן אל-הווא סמ10
3000 ,ירושלים ,3180 ,בטן אל-הווא סמ3
3000 ,ירושלים ,3366 ,בטן אל-הווא סמ4
3000 ,ירושלים ,3367 ,בטן אל-הווא סמ6
3000 ,ירושלים ,3181 ,בטן אל-הווא סמ7
3000 ,ירושלים ,3182 ,בטן אל-הווא סמ8
3000 ,ירושלים ,731 ,בי רב יעקב
3000 ,ירושלים ,4705 ,בי"ח חמדת הרים
3000 ,ירושלים ,953 ,בי"ח עזרת נשים
3000 ,ירושלים ,1920 ,בי"ח שערי צדק
3000 ,ירושלים ,1459 ,בי"ס למדעים ואומנוי
3000 ,ירושלים ,1035 ,ביאליק
3000 ,ירושלים ,471 ,ביאנקיני
3000 ,ירושלים ,685 ,ביבאס
3000 ,ירושלים ,4427 ,ביבארס
3000 ,ירושלים ,5508 ,בידון
3000 ,ירושלים ,2008 ,ביח אלין
3000 ,ירושלים ,892 ,ביח כפר שאול
3000 ,ירושלים ,4136 ,ביח סנט גון
3000 ,ירושלים ,1121 ,בייט שמואל
3000 ,ירושלים ,5478 ,בייליס מנדל
3000 ,ירושלים ,1606 ,ביל"ו
3000 ,ירושלים ,4904 ,בינת יששכר
3000 ,ירושלים ,4673 ,ביסאן
3000 ,ירושלים ,478 ,ביצ'אצ'ו אברהם
3000 ,ירושלים ,1254 ,ביצור יהושע
3000 ,ירושלים ,5640 ,ביר אבו ח'שבה
3000 ,ירושלים ,5641 ,ביר אבו ח'שבה סמ1
3000 ,ירושלים ,5642 ,ביר אבו ח'שבה סמ3
3000 ,ירושלים ,4256 ,ביר איוב
3000 ,ירושלים ,5588 ,ביר אל אסביל
3000 ,ירושלים ,4743 ,ביר אל סביל
3000 ,ירושלים ,5487 ,ביר אל-חמראה'
3000 ,ירושלים ,4892 ,ביר אל-כורום
3000 ,ירושלים ,5198 ,ביר אל-כורום סמ1
3000 ,ירושלים ,5202 ,ביר אל-כורום סמ2
3000 ,ירושלים ,5199 ,ביר אל-כורום סמ3
3000 ,ירושלים ,5200 ,ביר אל-כורום סמ5
3000 ,ירושלים ,4893 ,ביר אל-עקבה
3000 ,ירושלים ,5205 ,ביר אל-עקבה סמ1
3000 ,ירושלים ,4894 ,ביר אלזחלוק
3000 ,ירושלים ,5204 ,ביר אלזחלוק סמ2
3000 ,ירושלים ,4226 ,ביר מנסור
3000 ,ירושלים ,4715 ,ביר סאלם
3000 ,ירושלים ,5338 ,ביר סאלם סמ11
3000 ,ירושלים ,5333 ,ביר סאלם סמ4
3000 ,ירושלים ,5334 ,ביר סאלם סמ6
3000 ,ירושלים ,5335 ,ביר סאלם סמ7
3000 ,ירושלים ,5336 ,ביר סאלם סמ8
3000 ,ירושלים ,5337 ,ביר סאלם סמ9
3000 ,ירושלים ,4212 ,ביר עונה
3000 ,ירושלים ,4707 ,ביר עמדך
3000 ,ירושלים ,1253 ,בירן שושנה
3000 ,ירושלים ,356 ,בירנבאום
3000 ,ירושלים ,4367 ,בית אל
3000 ,ירושלים ,1604 ,בית אשל
3000 ,ירושלים ,4215 ,בית גאלה
3000 ,ירושלים ,1283 ,בית גיורא
3000 ,ירושלים ,3276 ,בית האבן
3000 ,ירושלים ,895 ,בית הדפוס
3000 ,ירושלים ,5590 ,בית הטירה
3000 ,ירושלים ,1859 ,בית היוצר
3000 ,ירושלים ,1036 ,בית הכרם
3000 ,ירושלים ,5592 ,בית הכרמל
3000 ,ירושלים ,527 ,בית הלחמי
3000 ,ירושלים ,5594 ,בית המעיין
3000 ,ירושלים ,1750 ,בית הערבה
3000 ,ירושלים ,5591 ,בית הצופה
3000 ,ירושלים ,5593 ,בית הקרן
3000 ,ירושלים ,5595 ,בית הקשתות
3000 ,ירושלים ,4507 ,בית השואבה
3000 ,ירושלים ,1058 ,בית וגן
3000 ,ירושלים ,1828 ,בית חגלה
3000 ,ירושלים ,4106 ,בית חנינא החדשה
3000 ,ירושלים ,399 ,בית טובי העיר
3000 ,ירושלים ,622 ,בית יעקב
3000 ,ירושלים ,2202 ,בית יצחק
3000 ,ירושלים ,118 ,בית ישראל
3000 ,ירושלים ,4403 ,בית פאג'י
3000 ,ירושלים ,3119 ,בית פאג'י סמ2
3000 ,ירושלים ,673 ,בית צור
3000 ,ירושלים ,4334 ,בית צורי אליהו
3000 ,ירושלים ,4201 ,בית צפפה
3000 ,ירושלים ,4353 ,בית קנדה
3000 ,ירושלים ,971 ,בית שערים
3000 ,ירושלים ,1737 ,בית"ר
3000 ,ירושלים ,638 ,בכר נסים
3000 ,ירושלים ,4341 ,בלבן מאיר
3000 ,ירושלים ,4414 ,בלד אל קדימה
3000 ,ירושלים ,4126 ,בלדיה
3000 ,ירושלים ,2011 ,בלומנפלד
3000 ,ירושלים ,145 ,בלזר יצחק
3000 ,ירושלים ,351 ,בלילוס
3000 ,ירושלים ,168 ,בלכר נחום
3000 ,ירושלים ,833 ,בלפור
3000 ,ירושלים ,4554 ,בן אליעזר אריה
3000 ,ירושלים ,4426 ,בן אלעס עמר
3000 ,ירושלים ,1374 ,בן בבא
3000 ,ירושלים ,1680 ,בן גבריאל
3000 ,ירושלים ,4636 ,בן גוביל מעאדי
3000 ,ירושלים ,1320 ,בן גמלא יהושע
3000 ,ירושלים ,1432 ,בן דב יעקב
3000 ,ירושלים ,1388 ,בן דוסא
3000 ,ירושלים ,1883 ,בן דור יצחק
3000 ,ירושלים ,435 ,בן הלל
3000 ,ירושלים ,4461 ,בן זאב ישראל
3000 ,ירושלים ,1894 ,בן חמו שמעון
3000 ,ירושלים ,1411 ,בן חנן מיכאל
3000 ,ירושלים ,1382 ,בן חפץ טוביה
3000 ,ירושלים ,1371 ,בן טבאי
3000 ,ירושלים ,1310 ,בן יאיר אלעזר
3000 ,ירושלים ,426 ,בן יהודה
3000 ,ירושלים ,4329 ,בן יוסף שלמה
3000 ,ירושלים ,4585 ,בן יחזקאל מרדכי
3000 ,ירושלים ,1784 ,בן יפונה
3000 ,ירושלים ,459 ,בן ישראל מנשה
3000 ,ירושלים ,820 ,בן לברט
3000 ,ירושלים ,700 ,בן נריה ברוך
3000 ,ירושלים ,408 ,בן סירא
3000 ,ירושלים ,819 ,בן סרוק
3000 ,ירושלים ,4281 ,בן עדיה שמואל
3000 ,ירושלים ,5391 ,בן עדיה שמואל סמ6
3000 ,ירושלים ,905 ,בן עוזיאל
3000 ,ירושלים ,1812 ,בן עזאי
3000 ,ירושלים ,4470 ,בן עטר
3000 ,ירושלים ,136 ,בן עמרם
3000 ,ירושלים ,1086 ,בן ציון
3000 ,ירושלים ,4779 ,בן ציון נתניהו
3000 ,ירושלים ,1824 ,בן קהת עמרם
3000 ,ירושלים ,4640 ,בן רבאח בילאל
3000 ,ירושלים ,4425 ,בן שדאד ענטרה
3000 ,ירושלים ,1523 ,בן שמעון דוד
3000 ,ירושלים ,4895 ,בנאת אבו בכר
3000 ,ירושלים ,5206 ,בנאת אבו בכר סמ1
3000 ,ירושלים ,5207 ,בנאת אבו בכר סמ2
3000 ,ירושלים ,5208 ,בנאת אבו בכר סמ4
3000 ,ירושלים ,5209 ,בנאת אבו בכר סמ6
3000 ,ירושלים ,5210 ,בנאת אבו בכר סמ8
3000 ,ירושלים ,6851 ,בנה ביתך גילה
3000 ,ירושלים ,6797 ,בנה ביתך רמות
3000 ,ירושלים ,3552 ,בנטויץ
3000 ,ירושלים ,4609 ,בני אומייה
3000 ,ירושלים ,5351 ,בני אומייה סמ1
3000 ,ירושלים ,5358 ,בני אומייה סמ3
3000 ,ירושלים ,403 ,בני ברית
3000 ,ירושלים ,1373 ,בני בתירא
3000 ,ירושלים ,1644 ,בניהו
3000 ,ירושלים ,1712 ,בנימין
3000 ,ירושלים ,499 ,בנימין (בני) אלון
3000 ,ירושלים ,817 ,בנימין מטודלה
3000 ,ירושלים ,1181 ,בנק ישראל
3000 ,ירושלים ,4985 ,בסאן יצחק
3000 ,ירושלים ,1390 ,בעהם אריה
3000 ,ירושלים ,2102 ,בעל אור שמח
3000 ,ירושלים ,996 ,בעל השאילתות
3000 ,ירושלים ,165 ,בעל התניא
3000 ,ירושלים ,1835 ,בעלי מלאכה
3000 ,ירושלים ,643 ,בצלאל
3000 ,ירושלים ,4778 ,בצראווי בצלאל
3000 ,ירושלים ,104 ,בק
3000 ,ירושלים ,4369 ,בקור חולים
3000 ,ירושלים ,891 ,בקי
3000 ,ירושלים ,4820 ,בר און אורי
3000 ,ירושלים ,301 ,בר אילן
3000 ,ירושלים ,333 ,בר גיורא
3000 ,ירושלים ,729 ,בר זכאי ישעיה
3000 ,ירושלים ,1361 ,בר יוחאי
3000 ,ירושלים ,3004 ,בר יקר דב
3000 ,ירושלים ,249 ,בר כוכבא
3000 ,ירושלים ,1895 ,בר לביא רפאל
3000 ,ירושלים ,1536 ,בר ניסן
3000 ,ירושלים ,4497 ,בראון אליהו
3000 ,ירושלים ,1157 ,בראנד אהרון
3000 ,ירושלים ,316 ,בראשי זלמן
3000 ,ירושלים ,1091 ,ברגמן אליעזר
3000 ,ירושלים ,1304 ,ברגר
3000 ,ירושלים ,4788 ,ברדנוב ירמיהו
3000 ,ירושלים ,1144 ,ברודצקי
3000 ,ירושלים ,956 ,ברוזה שמואל
3000 ,ירושלים ,1057 ,ברויאר יצחק
3000 ,ירושלים ,624 ,ברוכוף משיח
3000 ,ירושלים ,5461 ,ברוכי יהושע
3000 ,ירושלים ,2051 ,ברוכים שמואל
3000 ,ירושלים ,4845 ,ברוסקינה מאשה
3000 ,ירושלים ,1326 ,ברוריה
3000 ,ירושלים ,1268 ,ברזיל
3000 ,ירושלים ,1799 ,ברזילי
3000 ,ירושלים ,4336 ,ברזני משה
3000 ,ירושלים ,1027 ,ברכיהו
3000 ,ירושלים ,4967 ,ברכת אברהם
3000 ,ירושלים ,1347 ,ברל לוקר
3000 ,ירושלים ,4822 ,ברנדויין שמואל
3000 ,ירושלים ,320 ,ברנדיס
3000 ,ירושלים ,1154 ,ברנט זרח
3000 ,ירושלים ,1335 ,ברניקי
3000 ,ירושלים ,1512 ,ברנר
3000 ,ירושלים ,1359 ,ברנשטיין פרץ
3000 ,ירושלים ,1770 ,ברק
3000 ,ירושלים ,4510 ,ברקאי
3000 ,ירושלים ,4030 ,ברקוק
3000 ,ירושלים ,4476 ,ברקלי שאול
3000 ,ירושלים ,4567 ,ברקת
3000 ,ירושלים ,1931 ,ברש מנחם רועי
3000 ,ירושלים ,1730 ,בת שבע
3000 ,ירושלים ,694 ,בתי ברוידה
3000 ,ירושלים ,377 ,בתי הורנשטין
3000 ,ירושלים ,186 ,בתי ויטנברג
3000 ,ירושלים ,182 ,בתי ורשא
3000 ,ירושלים ,456 ,בתי מונקץ
3000 ,ירושלים ,4361 ,בתי מחסה
3000 ,ירושלים ,692 ,בתי מינסק
3000 ,ירושלים ,927 ,בתי פרבשטין
3000 ,ירושלים ,699 ,בתי רנד סלנט
3000 ,ירושלים ,6865 ,בתיר
3000 ,ירושלים ,4896 ,ג'אבר בן חייאן
3000 ,ירושלים ,5211 ,ג'אבר בן חייאן סמ1
3000 ,ירושלים ,5212 ,ג'אבר בן חייאן סמ2
3000 ,ירושלים ,5213 ,ג'אבר בן חייאן סמ4
3000 ,ירושלים ,5888 ,ג'אמע אל-זאויה
3000 ,ירושלים ,5889 ,ג'אמע אל-זאויה סמ1
3000 ,ירושלים ,5890 ,ג'אמע אל-זאויה סמ2
3000 ,ירושלים ,5891 ,ג'אמע אל-זאויה סמ4
3000 ,ירושלים ,5892 ,ג'אמע אל-זאויה סמ6
3000 ,ירושלים ,4387 ,ג'בל אל ג'דיד
3000 ,ירושלים ,4223 ,ג'בל מוכבר
3000 ,ירושלים ,5214 ,ג'דה
3000 ,ירושלים ,5215 ,ג'דה סמ1
3000 ,ירושלים ,5152 ,ג'ובראן ח'ליל
3000 ,ירושלים ,5154 ,ג'ובראן ח'ליל סמ5
3000 ,ירושלים ,5155 ,ג'ובראן ח'ליל סמ6
3000 ,ירושלים ,4651 ,ג'ורג' אדם סמית
3000 ,ירושלים ,4475 ,ג'ינאו מאיר
3000 ,ירושלים ,5900 ,ג'עביץ
3000 ,ירושלים ,5901 ,ג'עביץ סמ1
3000 ,ירושלים ,5902 ,ג'עביץ סמ2
3000 ,ירושלים ,4696 ,ג'עפר א-סאדק
3000 ,ירושלים ,609 ,גאון
3000 ,ירושלים ,1345 ,גאוני יעקב
3000 ,ירושלים ,4131 ,גאמע עבדין
3000 ,ירושלים ,4800 ,גבירצמן משה
3000 ,ירושלים ,4142 ,גבל א טור
3000 ,ירושלים ,4233 ,גבל אטרש
3000 ,ירושלים ,4114 ,גבל אל רויס
3000 ,ירושלים ,4203 ,גבל קטן
3000 ,ירושלים ,655 ,גבע
3000 ,ירושלים ,663 ,גבעון
3000 ,ירושלים ,6731 ,גבעת אורן
3000 ,ירושלים ,6536 ,גבעת אורנים
3000 ,ירושלים ,6635 ,גבעת בית הכרם
3000 ,ירושלים ,6808 ,גבעת בנימין
3000 ,ירושלים ,6580 ,גבעת הארבעה
3000 ,ירושלים ,1465 ,גבעת היונים
3000 ,ירושלים ,6500 ,גבעת המבתר
3000 ,ירושלים ,6860 ,גבעת המטוס
3000 ,ירושלים ,4288 ,גבעת התחמושת
3000 ,ירושלים ,6760 ,גבעת חנניה
3000 ,ירושלים ,6699 ,גבעת משואה
3000 ,ירושלים ,6807 ,גבעת עומר
3000 ,ירושלים ,6852 ,גבעת קנדה
3000 ,ירושלים ,1143 ,גבעת שאול
3000 ,ירושלים ,6732 ,גבעת שהין
3000 ,ירושלים ,6820 ,גבעת שפירא
3000 ,ירושלים ,4813 ,גבריהו חיים
3000 ,ירושלים ,1785 ,גד
3000 ,ירושלים ,4764 ,גדוד החמישי
3000 ,ירושלים ,4768 ,גדוד חרמש
3000 ,ירושלים ,4755 ,גדוד מכמש
3000 ,ירושלים ,4228 ,גדירי
3000 ,ירושלים ,1759 ,גדליה
3000 ,ירושלים ,1674 ,גדליהו אלון
3000 ,ירושלים ,1774 ,גדעון
3000 ,ירושלים ,954 ,גדרה
3000 ,ירושלים ,1241 ,גואטמאלה
3000 ,ירושלים ,290 ,גואל צדק
3000 ,ירושלים ,1439 ,גוגה ומוריס רג'ואן
3000 ,ירושלים ,2151 ,גוטליב מאוריציו
3000 ,ירושלים ,4803 ,גוייטין דוד
3000 ,ירושלים ,129 ,גול בן ציון
3000 ,ירושלים ,4343 ,גולאק אשר
3000 ,ירושלים ,1404 ,גולד
3000 ,ירושלים ,4345 ,גולדברג לאה
3000 ,ירושלים ,1307 ,גולומב
3000 ,ירושלים ,3052 ,גולי קניה
3000 ,ירושלים ,6715 ,גונן א
3000 ,ירושלים ,6716 ,גונן ב
3000 ,ירושלים ,6717 ,גונן ג
3000 ,ירושלים ,6718 ,גונן ד
3000 ,ירושלים ,6719 ,גונן ה
3000 ,ירושלים ,6720 ,גונן ו
3000 ,ירושלים ,6721 ,גונן ח
3000 ,ירושלים ,6722 ,גונן ט
3000 ,ירושלים ,1210 ,גורדון
3000 ,ירושלים ,163 ,גוש 60 מוסררה
3000 ,ירושלים ,411 ,גוש 51 מוסררה
3000 ,ירושלים ,516 ,גוש 33 מרכז מסחרי
3000 ,ירושלים ,504 ,גוש 53 מוסררה
3000 ,ירושלים ,503 ,גוש 54 מוסררה
3000 ,ירושלים ,502 ,גוש 59 מוסררה
3000 ,ירושלים ,672 ,גזר
3000 ,ירושלים ,3265 ,גיא בן הינום
3000 ,ירושלים ,4689 ,גידין
3000 ,ירושלים ,4582 ,גיזה
3000 ,ירושלים ,1722 ,גיחון
3000 ,ירושלים ,6559 ,גילה-צפון מערב
3000 ,ירושלים ,3260 ,גינת אגוז
3000 ,ירושלים ,4818 ,גל
3000 ,ירושלים ,1385 ,גלבר
3000 ,ירושלים ,5010 ,גליק נלסון
3000 ,ירושלים ,4362 ,גלעד
3000 ,ירושלים ,1733 ,גלעדי
3000 ,ירושלים ,4852 ,גלעינית
3000 ,ירושלים ,1870 ,גלרנטר מנחם
3000 ,ירושלים ,216 ,גמול
3000 ,ירושלים ,4346 ,גמזון ראובן
3000 ,ירושלים ,4104 ,גמע אל קדים
3000 ,ירושלים ,1460 ,גן החיות התנכי
3000 ,ירושלים ,800 ,גן הכוזרי
3000 ,ירושלים ,1905 ,גן העשרים
3000 ,ירושלים ,6776 ,גני קטמון
3000 ,ירושלים ,1787 ,גנרל קניג פייר
3000 ,ירושלים ,1679 ,גסטר משה
3000 ,ירושלים ,4690 ,גסר א-נסף
3000 ,ירושלים ,1344 ,גפני שמחה
3000 ,ירושלים ,4418 ,גראללה חוסם א דין
3000 ,ירושלים ,1495 ,גראנאדוס
3000 ,ירושלים ,461 ,גרוזנברג
3000 ,ירושלים ,4242 ,גרון אל חומוס
3000 ,ירושלים ,4328 ,גרונר דב
3000 ,ירושלים ,5482 ,גרוס זאב
3000 ,ירושלים ,1274 ,גרוסמן
3000 ,ירושלים ,948 ,גרייבסקי פנחס
3000 ,ירושלים ,4445 ,גרינברג חיים
3000 ,ירושלים ,1276 ,גרינולד
3000 ,ירושלים ,4846 ,גריניגר פאול
3000 ,ירושלים ,4357 ,גרינשפן הרשל
3000 ,ירושלים ,4065 ,גריק פטריאכט
3000 ,ירושלים ,4841 ,גרמי ציון
3000 ,ירושלים ,1111 ,גרנות
3000 ,ירושלים ,1657 ,גרץ צבי
3000 ,ירושלים ,4805 ,גרשון מאיר
3000 ,ירושלים ,384 ,גשר האלוף יוסף גבע
3000 ,ירושלים ,385 ,גשר החיים
3000 ,ירושלים ,1006 ,גת
3000 ,ירושלים ,4145 ,גת שמנים
3000 ,ירושלים ,1001 ,ד"ר גבי אשכר
3000 ,ירושלים ,1003 ,ד"ר יוסף בורג
3000 ,ירושלים ,6104 ,ד"ר יצחק מוסקוביץ
3000 ,ירושלים ,1233 ,ד"ר מלכה שפיגל
3000 ,ירושלים ,5216 ,ד'את אל-נטאקין
3000 ,ירושלים ,5217 ,ד'את אל-נטאקין סמ1
3000 ,ירושלים ,5352 ,ד'ו אלנוריין
3000 ,ירושלים ,5353 ,ד'ו אלנוריין סמ2
3000 ,ירושלים ,5354 ,ד'ו אלנוריין סמ3
3000 ,ירושלים ,4721 ,דאחית אל מועלמין
3000 ,ירושלים ,5657 ,דאר א סלאם
3000 ,ירושלים ,4417 ,דאר חמד
3000 ,ירושלים ,1303 ,דב הוז
3000 ,ירושלים ,5528 ,דבה
3000 ,ירושלים ,3081 ,דבה סמ2
3000 ,ירושלים ,3082 ,דבה סמ4
3000 ,ירושלים ,401 ,דבורה הנביאה
3000 ,ירושלים ,670 ,דביר
3000 ,ירושלים ,993 ,דברי חיים
3000 ,ירושלים ,1100 ,דברי ירוחם
3000 ,ירושלים ,4571 ,דבש
3000 ,ירושלים ,1092 ,דגל ראובן
3000 ,ירושלים ,4578 ,דגן
3000 ,ירושלים ,1063 ,דגניה
3000 ,ירושלים ,4448 ,דה הז יעקב
3000 ,ירושלים ,4584 ,דה לימה נחמיה
3000 ,ירושלים ,1203 ,דה-רוסי
3000 ,ירושלים ,1266 ,דהומיי
3000 ,ירושלים ,4711 ,דהרה
3000 ,ירושלים ,394 ,דובב מישרים
3000 ,ירושלים ,1195 ,דובדבני ברוך
3000 ,ירושלים ,1629 ,דובנוב
3000 ,ירושלים ,4902 ,דובר שלום
3000 ,ירושלים ,5022 ,דוגה שלמה
3000 ,ירושלים ,4056 ,דוד
3000 ,ירושלים ,4704 ,דוד (העיר העתיקה)
3000 ,ירושלים ,130 ,דוד החזן
3000 ,ירושלים ,1528 ,דוד המלך
3000 ,ירושלים ,1904 ,דוד מרדכי מאיר
3000 ,ירושלים ,3140 ,דוואר אל-נח'יל
3000 ,ירושלים ,4241 ,דוויאת
3000 ,ירושלים ,1112 ,דוידסון
3000 ,ירושלים ,1291 ,דולצ'ין
3000 ,ירושלים ,275 ,דונה גרציה
3000 ,ירושלים ,420 ,דונואס
3000 ,ירושלים ,1831 ,דוסטרובסקי אריה
3000 ,ירושלים ,1331 ,דוסתאי
3000 ,ירושלים ,1664 ,דור ודורשיו
3000 ,ירושלים ,439 ,דורות ראשונים
3000 ,ירושלים ,397 ,דורש טוב
3000 ,ירושלים ,294 ,דינה
3000 ,ירושלים ,736 ,דינוביץ גיטל
3000 ,ירושלים ,1069 ,דינור בן ציון
3000 ,ירושלים ,1209 ,דיניץ שמחה
3000 ,ירושלים ,728 ,דיסקין
3000 ,ירושלים ,4245 ,דיר אבו טור
3000 ,ירושלים ,4174 ,דיר אל טפל
3000 ,ירושלים ,3200 ,דיר אל סיריאן
3000 ,ירושלים ,5218 ,דיר אל עמוד סמ10
3000 ,ירושלים ,3185 ,דיר אל-סנה
3000 ,ירושלים ,3186 ,דיר אל-סנה סמ1
3000 ,ירושלים ,3187 ,דיר אל-סנה סמ2
3000 ,ירושלים ,3188 ,דיר אל-סנה סמ3
3000 ,ירושלים ,3189 ,דיר אל-סנה סמ4
3000 ,ירושלים ,4897 ,דיר אל-עאמוד
3000 ,ירושלים ,5219 ,דיר אל-עאמוד סמ2
3000 ,ירושלים ,5220 ,דיר אל-עאמוד סמ4
3000 ,ירושלים ,5222 ,דיר אל-עאמוד סמ6
3000 ,ירושלים ,4208 ,דיר טנטורה
3000 ,ירושלים ,4105 ,דיר כרמיזן
3000 ,ירושלים ,4216 ,דיר קירמיזן
3000 ,ירושלים ,1505 ,דישראלי
3000 ,ירושלים ,5462 ,דלה פרגולה
3000 ,ירושלים ,211 ,דלמן
3000 ,ירושלים ,662 ,דלתון
3000 ,ירושלים ,4064 ,דמיטריוס הקדוש
3000 ,ירושלים ,1783 ,דן
3000 ,ירושלים ,1543 ,דני אנג'ל
3000 ,ירושלים ,507 ,דניאל
3000 ,ירושלים ,1499 ,דסקל
3000 ,ירושלים ,4055 ,דרג א טבוני
3000 ,ירושלים ,465 ,דרום
3000 ,ירושלים ,4950 ,דרוק שלמה זלמן
3000 ,ירושלים ,4332 ,דרזנר יחיאל
3000 ,ירושלים ,5530 ,דרך א זעים
3000 ,ירושלים ,5767 ,דרך אבו-ת'ור
3000 ,ירושלים ,5768 ,דרך אבו-ת'ור סמ2
3000 ,ירושלים ,5769 ,דרך אבו-ת'ור סמ4
3000 ,ירושלים ,535 ,דרך אהרון חיים כהן
3000 ,ירושלים ,5384 ,דרך אל-צוואנה
3000 ,ירושלים ,5385 ,דרך אל-צוואנה סמ1
3000 ,ירושלים ,5386 ,דרך אל-צוואנה סמ2
3000 ,ירושלים ,5387 ,דרך אל-צוואנה סמ3
3000 ,ירושלים ,5388 ,דרך אל-צוואנה סמ4
3000 ,ירושלים ,5389 ,דרך אל-צוואנה סמ5
3000 ,ירושלים ,5390 ,דרך אל-צוואנה סמ7
3000 ,ירושלים ,3217 ,דרך אל-שיאח
3000 ,ירושלים ,3132 ,דרך אל-שיאח סמ1
3000 ,ירושלים ,3133 ,דרך אל-שיאח סמ2
3000 ,ירושלים ,3134 ,דרך אל-שיאח סמ3
3000 ,ירושלים ,3135 ,דרך אל-שיאח סמ6
3000 ,ירושלים ,3136 ,דרך אל-שיאח סמ7
3000 ,ירושלים ,1167 ,דרך אלישר
3000 ,ירושלים ,1105 ,דרך בגין
3000 ,ירושלים ,4744 ,דרך ביר נבאלה
3000 ,ירושלים ,4632 ,דרך בית חורון
3000 ,ירושלים ,5127 ,דרך בית חנינא
3000 ,ירושלים ,1753 ,דרך בית לחם
3000 ,ירושלים ,1362 ,דרך בנבנישתי דוד
3000 ,ירושלים ,1466 ,דרך בנימינה
3000 ,ירושלים ,4113 ,דרך בר-לב
3000 ,ירושלים ,5893 ,דרך ג'בל מוכבר
3000 ,ירושלים ,5894 ,דרך ג'בל מוכבר סמ1
3000 ,ירושלים ,5899 ,דרך ג'בל מוכבר סמ11
3000 ,ירושלים ,5895 ,דרך ג'בל מוכבר סמ3
3000 ,ירושלים ,5896 ,דרך ג'בל מוכבר סמ5
3000 ,ירושלים ,5897 ,דרך ג'בל מוכבר סמ6
3000 ,ירושלים ,5898 ,דרך ג'בל מוכבר סמ9
3000 ,ירושלים ,1729 ,דרך גדוד 68
3000 ,ירושלים ,1469 ,דרך האחיות
3000 ,ירושלים ,1493 ,דרך האילנות
3000 ,ירושלים ,1540 ,דרך הבוצר
3000 ,ירושלים ,3369 ,דרך הגן
3000 ,ירושלים ,3370 ,דרך הגן סמ2
3000 ,ירושלים ,3371 ,דרך הגן סמ3
3000 ,ירושלים ,5300 ,דרך החורש
3000 ,ירושלים ,1801 ,דרך הטיילת
3000 ,ירושלים ,1538 ,דרך הטרסות
3000 ,ירושלים ,3373 ,דרך הכדים
3000 ,ירושלים ,1467 ,דרך הסלע
3000 ,ירושלים ,4339 ,דרך העופל
3000 ,ירושלים ,3258 ,דרך הפטרול
3000 ,ירושלים ,4177 ,דרך הר הזיתים
3000 ,ירושלים ,1541 ,דרך השומרה
3000 ,ירושלים ,1103 ,דרך השופט מאיר שמגר
3000 ,ירושלים ,3800 ,דרך השילוח
3000 ,ירושלים ,1179 ,דרך השלוה
3000 ,ירושלים ,1470 ,דרך התצפית
3000 ,ירושלים ,5359 ,דרך ואדי אל ג'וז
3000 ,ירושלים ,1128 ,דרך וולפסון דוד
3000 ,ירושלים ,4456 ,דרך זלאטע כהן
3000 ,ירושלים ,1714 ,דרך חברון
3000 ,ירושלים ,4728 ,דרך חיזמא סמ3
3000 ,ירושלים ,5091 ,דרך חיזמא סמ4
3000 ,ירושלים ,4932 ,דרך חיזמא סמ7
3000 ,ירושלים ,4933 ,דרך חיזמא סמ9
3000 ,ירושלים ,4135 ,דרך חיזמה
3000 ,ירושלים ,540 ,דרך חיים גורי
3000 ,ירושלים ,4146 ,דרך יריחו
3000 ,ירושלים ,3143 ,דרך יריחו סמ12
3000 ,ירושלים ,3142 ,דרך יריחו סמ6
3000 ,ירושלים ,3323 ,דרך כפר עקב
3000 ,ירושלים ,3324 ,דרך כפר עקב סמ1
3000 ,ירושלים ,3325 ,דרך כפר עקב סמ2
3000 ,ירושלים ,3326 ,דרך כפר עקב סמ3
3000 ,ירושלים ,3327 ,דרך כפר עקב סמ4
3000 ,ירושלים ,3328 ,דרך כפר עקב סמ5
3000 ,ירושלים ,1218 ,דרך כרמית
3000 ,ירושלים ,1346 ,דרך מודעי יצחק
3000 ,ירושלים ,485 ,דרך מוך
3000 ,ירושלים ,1240 ,דרך מן קלמן
3000 ,ירושלים ,1216 ,דרך מנחת
3000 ,ירושלים ,7001 ,דרך מעלה אדמים
3000 ,ירושלים ,1864 ,דרך משה ברעם
3000 ,ירושלים ,4685 ,דרך נבי סמואל
3000 ,ירושלים ,5529 ,דרך ע'אבה פוקא
3000 ,ירושלים ,4626 ,דרך עזמות
3000 ,ירושלים ,4801 ,דרך ענתות
3000 ,ירושלים ,3950 ,דרך צור באהר
3000 ,ירושלים ,1252 ,דרך קוליץ
3000 ,ירושלים ,1062 ,דרך קרית יובל
3000 ,ירושלים ,4128 ,דרך ראמאללה
3000 ,ירושלים ,3282 ,דרך ראמאללה סמ100
3000 ,ירושלים ,3286 ,דרך ראמאללה סמ120
3000 ,ירושלים ,4990 ,דרך ראמאללה סמ1
3000 ,ירושלים ,3283 ,דרך ראמאללה סמ111
3000 ,ירושלים ,4991 ,דרך ראמאללה סמ2
3000 ,ירושלים ,3284 ,דרך ראמאללה סמ112
3000 ,ירושלים ,4992 ,דרך ראמאללה סמ4
3000 ,ירושלים ,3285 ,דרך ראמאללה סמ116
3000 ,ירושלים ,1106 ,דרך רבין
3000 ,ירושלים ,1101 ,דרך רופין
3000 ,ירושלים ,1409 ,דרך שולוב אהרון
3000 ,ירושלים ,5128 ,דרך שועפאט
3000 ,ירושלים ,5134 ,דרך שועפאט סמ10
3000 ,ירושלים ,5114 ,דרך שועפאט סמ2
3000 ,ירושלים ,5115 ,דרך שועפאט סמ4
3000 ,ירושלים ,5116 ,דרך שועפאט סמ5
3000 ,ירושלים ,5135 ,דרך שועפאט סמ6
3000 ,ירושלים ,5118 ,דרך שועפאט סמ9
3000 ,ירושלים ,1468 ,דרך שורק
3000 ,ירושלים ,4162 ,דרך שכם
3000 ,ירושלים ,5083 ,דרך שכם סמ5
3000 ,ירושלים ,5119 ,דרך שעפאט סמ3
3000 ,ירושלים ,5113 ,דרך שעפאט סמ14
3000 ,ירושלים ,5117 ,דרך שעפאט סמ8
3000 ,ירושלים ,1249 ,האביבית
3000 ,ירושלים ,626 ,האגוז
3000 ,ירושלים ,1152 ,האדמו"ר מבויאן
3000 ,ירושלים ,1163 ,האדמו"ר מבוסטון
3000 ,ירושלים ,3031 ,האדמו"ר מבעלז
3000 ,ירושלים ,3030 ,האדמו"ר מגור
3000 ,ירושלים ,3032 ,האדמו"ר מויז'ניץ
3000 ,ירושלים ,4958 ,האדמו"ר מלובביץ
3000 ,ירושלים ,4961 ,האדמו"ר מסדיגורה
3000 ,ירושלים ,1158 ,האדמו"ר מרוז'ין
3000 ,ירושלים ,203 ,האדמורי"ם ליינער
3000 ,ירושלים ,997 ,האדריכל
3000 ,ירושלים ,827 ,האדרת
3000 ,ירושלים ,1170 ,האוזנר גדעון
3000 ,ירושלים ,1839 ,האומן
3000 ,ירושלים ,896 ,האופה
3000 ,ירושלים ,918 ,האור
3000 ,ירושלים ,1376 ,האורן
3000 ,ירושלים ,4547 ,האזוב
3000 ,ירושלים ,1193 ,האחוה
3000 ,ירושלים ,1294 ,האחות זלמה
3000 ,ירושלים ,4535 ,האחות יהודית
3000 ,ירושלים ,4063 ,האחים
3000 ,ירושלים ,4583 ,האחים להרן
3000 ,ירושלים ,1352 ,האייל
3000 ,ירושלים ,2010 ,האירית
3000 ,ירושלים ,4786 ,האלוף יקותיאל אדם
3000 ,ירושלים ,4762 ,האלוף עוזי נרקיס
3000 ,ירושלים ,4664 ,האמאם אבו הוריירה
3000 ,ירושלים ,4607 ,האמאם אבו חניפה
3000 ,ירושלים ,4608 ,האמאם אלחנבלי
3000 ,ירושלים ,5356 ,האמאם אלחנבלי סמ1
3000 ,ירושלים ,5357 ,האמאם אלחנבלי סמ2
3000 ,ירושלים ,4604 ,האמאם אלמלכי
3000 ,ירושלים ,5810 ,האמאם אלמלכי סמ1
3000 ,ירושלים ,5811 ,האמאם אלמלכי סמ2
3000 ,ירושלים ,5812 ,האמאם אלמלכי סמ3
3000 ,ירושלים ,4606 ,האמאם שאפעי
3000 ,ירושלים ,5096 ,האמאם שאפעי סמ3
3000 ,ירושלים ,520 ,האמונה
3000 ,ירושלים ,1248 ,האמוראים
3000 ,ירושלים ,360 ,האמרכלים
3000 ,ירושלים ,4542 ,האנפה
3000 ,ירושלים ,627 ,האפרסק
3000 ,ירושלים ,730 ,האר"ז
3000 ,ירושלים ,830 ,האר"י
3000 ,ירושלים ,4758 ,הארבעה
3000 ,ירושלים ,938 ,הארגמן
3000 ,ירושלים ,4320 ,הארון אל-רשיד
3000 ,ירושלים ,1048 ,הארזים
3000 ,ירושלים ,633 ,הארנון
3000 ,ירושלים ,4089 ,האשורים
3000 ,ירושלים ,619 ,האשכול
3000 ,ירושלים ,1377 ,האשל
3000 ,ירושלים ,1940 ,הבבא סאלי
3000 ,ירושלים ,4067 ,הבורסקאים
3000 ,ירושלים ,4515 ,הביכורים
3000 ,ירושלים ,1028 ,הבנאי
3000 ,ירושלים ,155 ,הבעש"ט
3000 ,ירושלים ,1013 ,הברון הירש
3000 ,ירושלים ,1709 ,הבריכה
3000 ,ירושלים ,392 ,הגאון מטורדא
3000 ,ירושלים ,727 ,הגאונים
3000 ,ירושלים ,1624 ,הגדוד העברי
3000 ,ירושלים ,1612 ,הגדנ"ע
3000 ,ירושלים ,4541 ,הגומא
3000 ,ירושלים ,1032 ,הגיא
3000 ,ירושלים ,679 ,הגלבוע
3000 ,ירושלים ,1858 ,הגלגל
3000 ,ירושלים ,648 ,הגליל
3000 ,ירושלים ,1463 ,הגן הטכנולוגי
3000 ,ירושלים ,4539 ,הגננת
3000 ,ירושלים ,4573 ,הגפן
3000 ,ירושלים ,716 ,הגר"א
3000 ,ירושלים ,4766 ,הגשר החי
3000 ,ירושלים ,4519 ,הגתית
3000 ,ירושלים ,634 ,הדאיה
3000 ,ירושלים ,1356 ,הדב
3000 ,ירושלים ,4563 ,הדולב
3000 ,ירושלים ,1351 ,הדישון
3000 ,ירושלים ,5448 ,הדמומית
3000 ,ירושלים ,5016 ,הדף היומי
3000 ,ירושלים ,1378 ,הדפנה
3000 ,ירושלים ,621 ,הדקל
3000 ,ירושלים ,5903 ,הדרך האמריקאית
3000 ,ירושלים ,5913 ,הדרך האמריקאית סמ10
3000 ,ירושלים ,5920 ,הדרך האמריקאית סמ20
3000 ,ירושלים ,5904 ,הדרך האמריקאית סמ1
3000 ,ירושלים ,5914 ,הדרך האמריקאית סמ11
3000 ,ירושלים ,5905 ,הדרך האמריקאית סמ2
3000 ,ירושלים ,5915 ,הדרך האמריקאית סמ12
3000 ,ירושלים ,5906 ,הדרך האמריקאית סמ3
3000 ,ירושלים ,5916 ,הדרך האמריקאית סמ13
3000 ,ירושלים ,5907 ,הדרך האמריקאית סמ4
3000 ,ירושלים ,6917 ,הדרך האמריקאית סמ14
3000 ,ירושלים ,5908 ,הדרך האמריקאית סמ5
3000 ,ירושלים ,5909 ,הדרך האמריקאית סמ6
3000 ,ירושלים ,5918 ,הדרך האמריקאית סמ16
3000 ,ירושלים ,5910 ,הדרך האמריקאית סמ7
3000 ,ירושלים ,5911 ,הדרך האמריקאית סמ8
3000 ,ירושלים ,5919 ,הדרך האמריקאית סמ18
3000 ,ירושלים ,5912 ,הדרך האמריקאית סמ9
3000 ,ירושלים ,4587 ,ההבטחה
3000 ,ירושלים ,4316 ,ההגנה
3000 ,ירושלים ,1379 ,ההדסים
3000 ,ירושלים ,434 ,ההסתדרות
3000 ,ירושלים ,5310 ,ההעברה
3000 ,ירושלים ,5453 ,ההרדוף
3000 ,ירושלים ,3057 ,ההתנדבויות
3000 ,ירושלים ,4860 ,הולצברג שמחה
3000 ,ירושלים ,1430 ,הועד הלאומי
3000 ,ירושלים ,4594 ,הופיין אליעזר
3000 ,ירושלים ,195 ,הורביץ
3000 ,ירושלים ,416 ,הורקנוס יוחנן
3000 ,ירושלים ,1334 ,הורקניה
3000 ,ירושלים ,302 ,הושע
3000 ,ירושלים ,4618 ,הותיקן
3000 ,ירושלים ,4854 ,הזהבית
3000 ,ירושלים ,337 ,הזית
3000 ,ירושלים ,1060 ,הזכרון
3000 ,ירושלים ,1084 ,הזמיר
3000 ,ירושלים ,4532 ,החבוש
3000 ,ירושלים ,415 ,החבצלת
3000 ,ירושלים ,5807 ,החו"ש
3000 ,ירושלים ,511 ,החומה השלישית
3000 ,ירושלים ,1030 ,החוצב
3000 ,ירושלים ,3060 ,החי"ל
3000 ,ירושלים ,1616 ,החי"ם
3000 ,ירושלים ,1613 ,החי"ש
3000 ,ירושלים ,1055 ,החיד"א
3000 ,ירושלים ,1017 ,החלוץ
3000 ,ירושלים ,1256 ,החלמית
3000 ,ירושלים ,1288 ,החמניה
3000 ,ירושלים ,5651 ,החנויות
3000 ,ירושלים ,4556 ,החסידה
3000 ,ירושלים ,4518 ,החצוצרות
3000 ,ירושלים ,614 ,החרוב
3000 ,ירושלים ,1863 ,החרושת
3000 ,ירושלים ,653 ,החרמון
3000 ,ירושלים ,1279 ,החרצית
3000 ,ירושלים ,257 ,החרש
3000 ,ירושלים ,338 ,החשמונאים
3000 ,ירושלים ,336 ,הטורים
3000 ,ירושלים ,1713 ,הטחנה
3000 ,ירושלים ,1410 ,הטייסים
3000 ,ירושלים ,637 ,היבוק
3000 ,ירושלים ,4528 ,היהודים
3000 ,ירושלים ,4678 ,היוצק
3000 ,ירושלים ,4649 ,היזמה
3000 ,ירושלים ,1702 ,היינריך היינה
3000 ,ירושלים ,1007 ,היכל המשפט
3000 ,ירושלים ,1647 ,הילדסהיימר עזריאל
3000 ,ירושלים ,5355 ,הינד אל חוסייני
3000 ,ירושלים ,5392 ,הינד אל חוסייני סמ2
3000 ,ירושלים ,1456 ,היען
3000 ,ירושלים ,4677 ,היצירה
3000 ,ירושלים ,1734 ,הירדן
3000 ,ירושלים ,636 ,הירמוך
3000 ,ירושלים ,715 ,הירשנברג
3000 ,ירושלים ,308 ,הישיבה
3000 ,ירושלים ,4394 ,הכביש להר חומה
3000 ,ירושלים ,4396 ,הכורכים
3000 ,ירושלים ,5806 ,הכותל
3000 ,ירושלים ,5567 ,הכותל הקטן
3000 ,ירושלים ,4095 ,הכלדיים
3000 ,ירושלים ,1267 ,הכלנית
3000 ,ירושלים ,4526 ,הכנור
3000 ,ירושלים ,4072 ,הכנסיות
3000 ,ירושלים ,171 ,הכנסת אורחים
3000 ,ירושלים ,1354 ,הכפיר
3000 ,ירושלים ,4057 ,הכרי
3000 ,ירושלים ,1280 ,הכרכום
3000 ,ירושלים ,676 ,הכרמל
3000 ,ירושלים ,1607 ,הל"ה
3000 ,ירושלים ,1516 ,הלב העברי
3000 ,ירושלים ,702 ,הלבנון
3000 ,ירושלים ,4552 ,הלוט
3000 ,ירושלים ,1022 ,הלוי אליעזר
3000 ,ירושלים ,4702 ,הלוי שלמה (מומו)
3000 ,ירושלים ,4385 ,הלטינים
3000 ,ירושלים ,2006 ,הלילך
3000 ,ירושלים ,432 ,הלל
3000 ,ירושלים ,1269 ,הלמן
3000 ,ירושלים ,404 ,הלני המלכה
3000 ,ירושלים ,5921 ,הלסה
3000 ,ירושלים ,5922 ,הלסה סמ1
3000 ,ירושלים ,5923 ,הלסה סמ3
3000 ,ירושלים ,5924 ,הלסה סמ4
3000 ,ירושלים ,5925 ,הלסה סמ5
3000 ,ירושלים ,5926 ,הלסה סמ6
3000 ,ירושלים ,1652 ,הלפרין ישראל
3000 ,ירושלים ,1423 ,הלר חיים
3000 ,ירושלים ,944 ,המ"ג
3000 ,ירושלים ,919 ,המאסף
3000 ,ירושלים ,369 ,המביט
3000 ,ירושלים ,5535 ,המבריא
3000 ,ירושלים ,1711 ,המבשר
3000 ,ירושלים ,1706 ,המגדל
3000 ,ירושלים ,1650 ,המגיד
3000 ,ירושלים ,4395 ,המדפיסים
3000 ,ירושלים ,651 ,המדרגות
3000 ,ירושלים ,1941 ,המהר"ל מפראג
3000 ,ירושלים ,910 ,המהר"מ מרוטנבורג
3000 ,ירושלים ,5320 ,המובילים
3000 ,ירושלים ,920 ,המון
3000 ,ירושלים ,1793 ,המוסכים
3000 ,ירושלים ,4179 ,המור
3000 ,ירושלים ,1044 ,המורה
3000 ,ירושלים ,1846 ,המזרחן
3000 ,ירושלים ,5460 ,המחנכת
3000 ,ירושלים ,2013 ,המחתרות
3000 ,ירושלים ,495 ,המטבע היהודי
3000 ,ירושלים ,1025 ,המיסדים
3000 ,ירושלים ,1825 ,המישור
3000 ,ירושלים ,4511 ,המלאך
3000 ,ירושלים ,1718 ,המלאך בלבן
3000 ,ירושלים ,256 ,המלאכה
3000 ,ירושלים ,1061 ,המלבים
3000 ,ירושלים ,1651 ,המליץ
3000 ,ירושלים ,429 ,המלך ג'ורג'
3000 ,ירושלים ,4028 ,המלך פייצל
3000 ,ירושלים ,1162 ,המלמד
3000 ,ירושלים ,4502 ,הממונה
3000 ,ירושלים ,1888 ,הממציא
3000 ,ירושלים ,5540 ,המנזר האתיופי
3000 ,ירושלים ,4077 ,המנזר היווני
3000 ,ירושלים ,5575 ,המסגד
3000 ,ירושלים ,197 ,המסגר
3000 ,ירושלים ,1380 ,המעיין
3000 ,ירושלים ,448 ,המעלות
3000 ,ירושלים ,1421 ,המעפילים
3000 ,ירושלים ,1533 ,המערבים
3000 ,ירושלים ,3263 ,המערות
3000 ,ירושלים ,1832 ,המפקד
3000 ,ירושלים ,5927 ,המפקד סמ1
3000 ,ירושלים ,5928 ,המפקד סמ3
3000 ,ירושלים ,5929 ,המפקד סמ5
3000 ,ירושלים ,5930 ,המפקד סמ7
3000 ,ירושלים ,1705 ,המצודה
3000 ,ירושלים ,1615 ,המצור
3000 ,ירושלים ,4524 ,המצילתיים
3000 ,ירושלים ,4506 ,המקובלים
3000 ,ירושלים ,1177 ,המר זבולון
3000 ,ירושלים ,5501 ,המרפא
3000 ,ירושלים ,1614 ,המשורינים
3000 ,ירושלים ,4465 ,המשורר אצ"ג
3000 ,ירושלים ,4503 ,המשוררים
3000 ,ירושלים ,1047 ,המשוררת
3000 ,ירושלים ,4683 ,המשוררת זלדה
3000 ,ירושלים ,1710 ,המשלט
3000 ,ירושלים ,4290 ,המשעול האחרון
3000 ,ירושלים ,973 ,המתנחלים בהר
3000 ,ירושלים ,348 ,הנביאים
3000 ,ירושלים ,4522 ,הנבל
3000 ,ירושלים ,4182 ,הנגבי שבתאי
3000 ,ירושלים ,2506 ,הנגר
3000 ,ירושלים ,1302 ,הנוטרים
3000 ,ירושלים ,4321 ,הנוצרים
3000 ,ירושלים ,1255 ,הנורית
3000 ,ירושלים ,4680 ,הנחושת
3000 ,ירושלים ,4308 ,הנחל
3000 ,ירושלים ,1207 ,הנטקה
3000 ,ירושלים ,4757 ,הניידות
3000 ,ירושלים ,1102 ,הניצן
3000 ,ירושלים ,1457 ,הנמר
3000 ,ירושלים ,691 ,הנציב
3000 ,ירושלים ,1282 ,הנרד
3000 ,ירושלים ,828 ,הנשיא
3000 ,ירושלים ,1172 ,הנשיא השישי
3000 ,ירושלים ,1524 ,הס משה
3000 ,ירושלים ,1260 ,הסביון
3000 ,ירושלים ,1836 ,הסדנא
3000 ,ירושלים ,5571 ,הסהר
3000 ,ירושלים ,1031 ,הסוללים
3000 ,ירושלים ,1043 ,הסופר
3000 ,ירושלים ,468 ,הסורג
3000 ,ירושלים ,1261 ,הסחלב
3000 ,ירושלים ,4850 ,הסיגלית
3000 ,ירושלים ,4782 ,הסייר
3000 ,ירושלים ,4765 ,הסיירת הירושלמית
3000 ,ירושלים ,1257 ,הסיפן
3000 ,ירושלים ,822 ,הסלח
3000 ,ירושלים ,210 ,הסנהדרין
3000 ,ירושלים ,4851 ,הסתונית
3000 ,ירושלים ,1029 ,הסתת
3000 ,ירושלים ,506 ,הע"ח
3000 ,ירושלים ,4521 ,העוגב
3000 ,ירושלים ,4514 ,העומר
3000 ,ירושלים ,4681 ,העופרת
3000 ,ירושלים ,1494 ,העטלף
3000 ,ירושלים ,4703 ,העיר העתיקה
3000 ,ירושלים ,5451 ,העירית
3000 ,ירושלים ,1090 ,העלוי
3000 ,ירושלים ,1122 ,העליה
3000 ,ירושלים ,144 ,העמלים
3000 ,ירושלים ,5404 ,העסקן
3000 ,ירושלים ,628 ,הערמונים
3000 ,ירושלים ,3259 ,העשור
3000 ,ירושלים ,1608 ,הפורצים
3000 ,ירושלים ,5804 ,הפטרארכיה הארמנית
3000 ,ירושלים ,3025 ,הפטריארכיה הקופטית
3000 ,ירושלים ,890 ,הפלאה
3000 ,ירושלים ,1630 ,הפלמ"ח
3000 ,ירושלים ,537 ,הפנתרים השחורים
3000 ,ירושלים ,1056 ,הפסגה
3000 ,ירושלים ,3054 ,הפרטיזנים
3000 ,ירושלים ,1860 ,הפרסה
3000 ,ירושלים ,916 ,הצבי
3000 ,ירושלים ,4853 ,הצבעוני
3000 ,ירושלים ,4572 ,הצבר
3000 ,ירושלים ,4463 ,הצדיק מטשכנוב
3000 ,ירושלים ,4975 ,הצדיק משטפנשט
3000 ,ירושלים ,4193 ,הצוף
3000 ,ירושלים ,1296 ,הציונות
3000 ,ירושלים ,1845 ,הצייר
3000 ,ירושלים ,1496 ,הצייר יוסי
3000 ,ירושלים ,1535 ,הצלם רחמים
3000 ,ירושלים ,2002 ,הצלף
3000 ,ירושלים ,525 ,הצנחנים
3000 ,ירושלים ,5452 ,הצפורן
3000 ,ירושלים ,1627 ,הצפירה
3000 ,ירושלים ,1381 ,הצפצפה
3000 ,ירושלים ,1160 ,הקבלן
3000 ,ירושלים ,4464 ,הקונגרס הציוני
3000 ,ירושלים ,5802 ,הקופטים
3000 ,ירושלים ,4548 ,הקורא
3000 ,ירושלים ,724 ,הקליר
3000 ,ירושלים ,5572 ,הקמרונות
3000 ,ירושלים ,4551 ,הקנה
3000 ,ירושלים ,1834 ,הקצין סילבר
3000 ,ירושלים ,4265 ,הקראים
3000 ,ירושלים ,5576 ,הקרדו
3000 ,ירושלים ,1141 ,הקריה רוממה
3000 ,ירושלים ,801 ,הקרן הקיימת
3000 ,ירושלים ,4120 ,הר הבית
3000 ,ירושלים ,4144 ,הר הזיתים
3000 ,ירושלים ,1912 ,הר הזכרון
3000 ,ירושלים ,3277 ,הר המשחה
3000 ,ירושלים ,6821 ,הר הצופים
3000 ,ירושלים ,6824 ,הר הצופים אידלסון
3000 ,ירושלים ,6825 ,הר הצופים רזניק
3000 ,ירושלים ,6823 ,הר הצופים ש אסבסט
3000 ,ירושלים ,1072 ,הר הרצל
3000 ,ירושלים ,6710 ,הר חרת
3000 ,ירושלים ,6826 ,הר צופים גולדסמיט
3000 ,ירושלים ,1715 ,הר ציון
3000 ,ירושלים ,6862 ,הר שלמון
3000 ,ירושלים ,102 ,הרא"ם
3000 ,ירושלים ,932 ,הראובני דוד
3000 ,ירושלים ,231 ,הרב אבא שאול
3000 ,ירושלים ,2201 ,הרב אבוהב
3000 ,ירושלים ,1156 ,הרב אבן דנן
3000 ,ירושלים ,3000 ,הרב אברהם זכות
3000 ,ירושלים ,378 ,הרב אברהם חזן
3000 ,ירושלים ,3038 ,הרב אברהם רביץ
3000 ,ירושלים ,992 ,הרב אברהם שפירא
3000 ,ירושלים ,370 ,הרב אברמסקי
3000 ,ירושלים ,424 ,הרב אגן
3000 ,ירושלים ,4451 ,הרב אונטרמן
3000 ,ירושלים ,5019 ,הרב אור שרגא
3000 ,ירושלים ,241 ,הרב אטון בן ציון
3000 ,ירושלים ,2101 ,הרב אלבוחר חיים
3000 ,ירושלים ,1814 ,הרב אלטשולר דוד
3000 ,ירושלים ,1935 ,הרב אליעזר דיאבילה
3000 ,ירושלים ,1171 ,הרב אליעזרי
3000 ,ירושלים ,4963 ,הרב אלמושנינו
3000 ,ירושלים ,4969 ,הרב אלנקווה
3000 ,ירושלים ,6111 ,הרב אלעזר גלבשטיין
3000 ,ירושלים ,1149 ,הרב אנגל וידאל
3000 ,ירושלים ,147 ,הרב אשלג
3000 ,ירושלים ,484 ,הרב בוקשפן
3000 ,ירושלים ,1930 ,הרב בינה אריה
3000 ,ירושלים ,107 ,הרב בלוי
3000 ,ירושלים ,229 ,הרב בנימין צביאלי
3000 ,ירושלים ,835 ,הרב ברודי
3000 ,ירושלים ,481 ,הרב בריכטא מנדל
3000 ,ירושלים ,4959 ,הרב ברים
3000 ,ירושלים ,823 ,הרב ברלין
3000 ,ירושלים ,4574 ,הרב ברמן יעקב
3000 ,ירושלים ,4954 ,הרב גולדקנופף
3000 ,ירושלים ,4372 ,הרב גורן שלמה
3000 ,ירושלים ,393 ,הרב גרוסברג חנוך
3000 ,ירושלים ,747 ,הרב דוד פוברסקי
3000 ,ירושלים ,3024 ,הרב דוד שניאורסון
3000 ,ירושלים ,743 ,הרב דילרוזה חיים
3000 ,ירושלים ,265 ,הרב הומינר
3000 ,ירושלים ,4956 ,הרב הירשלר
3000 ,ירושלים ,4441 ,הרב הרץ יוסף צבי
3000 ,ירושלים ,1422 ,הרב הרצוג
3000 ,ירושלים ,710 ,הרב והרבנית קאפח
3000 ,ירושלים ,1898 ,הרב ויינגרטן אברהם
3000 ,ירושלים ,740 ,הרב וילהלם
3000 ,ירושלים ,3016 ,הרב וינוגרד
3000 ,ירושלים ,4955 ,הרב ז'ולטי בצלאל
3000 ,ירושלים ,3014 ,הרב זווין
3000 ,ירושלים ,4952 ,הרב חדש מאיר
3000 ,ירושלים ,1016 ,הרב חוויתה כהן רחמי
3000 ,ירושלים ,273 ,הרב חיים שאול דוויק
3000 ,ירושלים ,138 ,הרב חכם שמעון
3000 ,ירושלים ,836 ,הרב חן
3000 ,ירושלים ,1942 ,הרב חסדאי קרשקש
3000 ,ירושלים ,4968 ,הרב טולדנו
3000 ,ירושלים ,1070 ,הרב טייב חי
3000 ,ירושלים ,3029 ,הרב יאדלר בן ציון
3000 ,ירושלים ,1648 ,הרב יהודה עמיטל
3000 ,ירושלים ,967 ,הרב יוסף גבאי
3000 ,ירושלים ,498 ,הרב יחיא שניאור
3000 ,ירושלים ,639 ,הרב יצחק בראשי
3000 ,ירושלים ,1808 ,הרב ישעיהו דולגין
3000 ,ירושלים ,1804 ,הרב כדורי יצחק
3000 ,ירושלים ,4957 ,הרב כהנמן
3000 ,ירושלים ,1884 ,הרב כלפון משה הכהן
3000 ,ירושלים ,4962 ,הרב לופיאן
3000 ,ירושלים ,1663 ,הרב מוהליבר שמואל
3000 ,ירושלים ,4976 ,הרב מוצפי יעקב
3000 ,ירושלים ,1191 ,הרב מוצפי סלמן
3000 ,ירושלים ,1068 ,הרב מימון
3000 ,ירושלים ,1932 ,הרב מן ההר
3000 ,ירושלים ,1549 ,הרב מנחם בצרי
3000 ,ירושלים ,1542 ,הרב מניטו
3000 ,ירושלים ,6110 ,הרב מרדכי פירון
3000 ,ירושלים ,1758 ,הרב משה בן טוב
3000 ,ירושלים ,1552 ,הרב משה צבי נריה
3000 ,ירושלים ,114 ,הרב נאה
3000 ,ירושלים ,981 ,הרב נורוק
3000 ,ירושלים ,1887 ,הרב ניסים יצחק
3000 ,ירושלים ,319 ,הרב ננס אליעזר
3000 ,ירושלים ,173 ,הרב סלנט
3000 ,ירושלים ,141 ,הרב עדני
3000 ,ירושלים ,1059 ,הרב עוזיאל
3000 ,ירושלים ,909 ,הרב עזרן ניסים
3000 ,ירושלים ,737 ,הרב עלוואן שבתי
3000 ,ירושלים ,1011 ,הרב עמיאל
3000 ,ירושלים ,712 ,הרב עראקי חיים
3000 ,ירושלים ,4491 ,הרב פורת ישראל
3000 ,ירושלים ,4965 ,הרב פטאל
3000 ,ירושלים ,5533 ,הרב פלקסר יצחק
3000 ,ירושלים ,3006 ,הרב פניז'ל
3000 ,ירושלים ,1064 ,הרב פרנק
3000 ,ירושלים ,1024 ,הרב צבי יהודה
3000 ,ירושלים ,272 ,הרב צדקה חוצ'ין
3000 ,ירושלים ,4832 ,הרב קהן חיים
3000 ,ירושלים ,1065 ,הרב קוסובסקי
3000 ,ירושלים ,421 ,הרב קוק
3000 ,ירושלים ,4966 ,הרב קלונימוס
3000 ,ירושלים ,4970 ,הרב קלכהיים עוזי
3000 ,ירושלים ,1155 ,הרב קצנלבוגן רפאל
3000 ,ירושלים ,1161 ,הרב רבין חזקיהו
3000 ,ירושלים ,4487 ,הרב רובין
3000 ,ירושלים ,1150 ,הרב רוזנטל דוד
3000 ,ירושלים ,1300 ,הרב ריינס
3000 ,ירושלים ,1080 ,הרב שאג
3000 ,ירושלים ,983 ,הרב שאולזון
3000 ,ירושלים ,5003 ,הרב שאולי
3000 ,ירושלים ,4469 ,הרב שבתאי חזקיה
3000 ,ירושלים ,4486 ,הרב שיף אברהם
3000 ,ירושלים ,492 ,הרב שך
3000 ,ירושלים ,3998 ,הרב שלום משאש
3000 ,ירושלים ,924 ,הרב שלמה וורטהיימר
3000 ,ירושלים ,616 ,הרב שמואל ברוך
3000 ,ירושלים ,2009 ,הרב שמואל נתן
3000 ,ירושלים ,1164 ,הרב שפרבר דוד
3000 ,ירושלים ,1239 ,הרב שפרן
3000 ,ירושלים ,4443 ,הרב שרייבר ישראל
3000 ,ירושלים ,1391 ,הרב שרים יוסף
3000 ,ירושלים ,988 ,הרב שרירא
3000 ,ירושלים ,538 ,הרבנית חיה מושקא
3000 ,ירושלים ,381 ,הרדב"ז
3000 ,ירושלים ,4430 ,הרואה
3000 ,ירושלים ,6870 ,הרובע היהודי
3000 ,ירושלים ,4323 ,הרובע הנוצרי
3000 ,ירושלים ,4550 ,הרוסמרין
3000 ,ירושלים ,289 ,הרחמים
3000 ,ירושלים ,5503 ,הרטום
3000 ,ירושלים ,1837 ,הרכב
3000 ,ירושלים ,1502 ,הרכבי
3000 ,ירושלים ,1797 ,הרכבים
3000 ,ירושלים ,1767 ,הרכבת
3000 ,ירושלים ,2111 ,הרליץ יוסף
3000 ,ירושלים ,805 ,הרן
3000 ,ירושלים ,1270 ,הרפוב הדומיניקנית
3000 ,ירושלים ,1186 ,הרפז ניסן
3000 ,ירושלים ,188 ,הרקח
3000 ,ירושלים ,923 ,הרקמה
3000 ,ירושלים ,1258 ,הרקפת
3000 ,ירושלים ,4842 ,הררי רפול
3000 ,ירושלים ,1081 ,הרשד"ם
3000 ,ירושלים ,1822 ,השגריר ארגוב שלמה
3000 ,ירושלים ,1301 ,השומר
3000 ,ירושלים ,1458 ,השועל
3000 ,ירושלים ,4505 ,השוערים
3000 ,ירושלים ,1517 ,השופט בנימין
3000 ,ירושלים ,1190 ,השופט חיים כהן
3000 ,ירושלים ,4523 ,השופר
3000 ,ירושלים ,629 ,השזיף
3000 ,ירושלים ,1609 ,השיירות
3000 ,ירושלים ,1826 ,השילוח
3000 ,ירושלים ,4817 ,השיריונאי
3000 ,ירושלים ,4540 ,השיש
3000 ,ירושלים ,4754 ,השישה עשר
3000 ,ירושלים ,721 ,השל"ה
3000 ,ירושלים ,3027 ,השלום והאחדות
3000 ,ירושלים ,4546 ,השליו
3000 ,ירושלים ,5570 ,השליחים
3000 ,ירושלים ,1287 ,השלכת
3000 ,ירושלים ,4034 ,השלשלת
3000 ,ירושלים ,4520 ,השמינית
3000 ,ירושלים ,4061 ,השער החדש
3000 ,ירושלים ,623 ,השקד
3000 ,ירושלים ,620 ,השקמה
3000 ,ירושלים ,4545 ,התאנה
3000 ,ירושלים ,701 ,התבור
3000 ,ירושלים ,5463 ,התבלין
3000 ,ירושלים ,1089 ,התומר
3000 ,ירושלים ,4525 ,התופים
3000 ,ירושלים ,630 ,התות
3000 ,ירושלים ,4783 ,התותחן
3000 ,ירושלים ,818 ,התיבונים
3000 ,ירושלים ,940 ,התכלת
3000 ,ירושלים ,1437 ,התלתן
3000 ,ירושלים ,4508 ,התמיד
3000 ,ירושלים ,4679 ,התמרוקים
3000 ,ירושלים ,739 ,התנאים
3000 ,ירושלים ,1847 ,התנופה
3000 ,ירושלים ,4164 ,התנחלות מוריה
3000 ,ירושלים ,1840 ,התעשיה
3000 ,ירושלים ,631 ,התפוח
3000 ,ירושלים ,1703 ,התקוה
3000 ,ירושלים ,1415 ,התקופה
3000 ,ירושלים ,5172 ,ו.אום אל עמיד סמ1
3000 ,ירושלים ,5173 ,ו.אום אל עמיד סמ2
3000 ,ירושלים ,5238 ,ו.אום אל עמיד סמ5
3000 ,ירושלים ,5243 ,ו.אום אל עמיד סמ6
3000 ,ירושלים ,5653 ,ואדי אום אל עמיד
3000 ,ירושלים ,4129 ,ואדי אל ג'וז
3000 ,ירושלים ,5654 ,ואדי אל ח'לף
3000 ,ירושלים ,5108 ,ואדי אל ח'לף סמ2
3000 ,ירושלים ,4898 ,ואדי אל חומוס
3000 ,ירושלים ,4230 ,ואדי אל נאר
3000 ,ירושלים ,5510 ,ואדי אל רבאבה
3000 ,ירושלים ,4411 ,ואדי התאומים
3000 ,ירושלים ,4252 ,ואדי חילווה
3000 ,ירושלים ,3267 ,ואדי חילווה סמ1
3000 ,ירושלים ,3268 ,ואדי חילווה סמ2
3000 ,ירושלים ,3269 ,ואדי חילווה סמ4
3000 ,ירושלים ,3270 ,ואדי חילווה סמ6
3000 ,ירושלים ,5515 ,ואדי יצול
3000 ,ירושלים ,5932 ,ואדי יצול סמ1
3000 ,ירושלים ,5933 ,ואדי יצול סמ2
3000 ,ירושלים ,5934 ,ואדי יצול סמ3
3000 ,ירושלים ,5935 ,ואדי יצול סמ4
3000 ,ירושלים ,5936 ,ואדי יצול סמ5
3000 ,ירושלים ,5937 ,ואדי יצול סמ6
3000 ,ירושלים ,5938 ,ואדי יצול סמ7
3000 ,ירושלים ,5939 ,ואדי יצול סמ8
3000 ,ירושלים ,5940 ,ואדי יצול סמ9
3000 ,ירושלים ,4158 ,ואדי מואזפין
3000 ,ירושלים ,4400 ,ואדי עבדאללה
3000 ,ירושלים ,3144 ,ואדי עבדאללה סמ1
3000 ,ירושלים ,3145 ,ואדי עבדאללה סמ3
3000 ,ירושלים ,3146 ,ואדי עבדאללה סמ4
3000 ,ירושלים ,3147 ,ואדי עבדאללה סמ5
3000 ,ירושלים ,3148 ,ואדי עבדאללה סמ6
3000 ,ירושלים ,4147 ,ואדי קדום
3000 ,ירושלים ,3196 ,ואדי קדום סמ10
3000 ,ירושלים ,3190 ,ואדי קדום סמ2
3000 ,ירושלים ,3191 ,ואדי קדום סמ3
3000 ,ירושלים ,3192 ,ואדי קדום סמ4
3000 ,ירושלים ,3193 ,ואדי קדום סמ5
3000 ,ירושלים ,3194 ,ואדי קדום סמ6
3000 ,ירושלים ,3195 ,ואדי קדום סמ8
3000 ,ירושלים ,5511 ,ואדי קדרון
3000 ,ירושלים ,1661 ,וודגווד
3000 ,ירושלים ,1246 ,וולטה העלית
3000 ,ירושלים ,912 ,וולך
3000 ,ירושלים ,4617 ,וורן צארלס
3000 ,ירושלים ,4002 ,ויה דולורוזה
3000 ,ירושלים ,906 ,ויטל חיים
3000 ,ירושלים ,1109 ,וייז שמואל
3000 ,ירושלים ,1082 ,וייסברג חיים
3000 ,ירושלים ,893 ,וייץ יוסף
3000 ,ירושלים ,1015 ,וילנאי זאב
3000 ,ירושלים ,1660 ,וינדהם דידס
3000 ,ירושלים ,1744 ,וינר אשר
3000 ,ירושלים ,4338 ,ויס יעקב
3000 ,ירושלים ,1071 ,ויסמן ליאו
3000 ,ירושלים ,3010 ,ויקטור ויוליוס
3000 ,ירושלים ,4493 ,ויתקון אלפרד
3000 ,ירושלים ,1178 ,ולוס
3000 ,ירושלים ,605 ,ולירו
3000 ,ירושלים ,517 ,ולנברג ראול
3000 ,ירושלים ,4485 ,ולנשטיין
3000 ,ירושלים ,4802 ,ונטורה משה
3000 ,ירושלים ,4611 ,ונסן לואי
3000 ,ירושלים ,3018 ,וסרמן פנחס
3000 ,ירושלים ,4483 ,ועד ארבע הארצות
3000 ,ירושלים ,1217 ,ורבורג
3000 ,ירושלים ,4180 ,ורדינון
3000 ,ירושלים ,193 ,ורהפטיג זרח
3000 ,ירושלים ,1896 ,ורשבסקי אריה
3000 ,ירושלים ,1526 ,ושינגטון
3000 ,ירושלים ,1741 ,ושיץ
3000 ,ירושלים ,1514 ,ז'בוטינסקי
3000 ,ירושלים ,266 ,זאב לב
3000 ,ירושלים ,1782 ,זבולון
3000 ,ירושלים ,4370 ,זהורית
3000 ,ירושלים ,5931 ,זהרת אל-צבאח
3000 ,ירושלים ,5941 ,זהרת אל-צבאח סמ1
3000 ,ירושלים ,5942 ,זהרת אל-צבאח סמ2
3000 ,ירושלים ,5943 ,זהרת אל-צבאח סמ4
3000 ,ירושלים ,1042 ,זוטא
3000 ,ירושלים ,4009 ,זויה א הונוד
3000 ,ירושלים ,143 ,זוננפלד
3000 ,ירושלים ,1182 ,זוסמן
3000 ,ירושלים ,4026 ,זוקוק א חילל
3000 ,ירושלים ,105 ,זיו יוסף
3000 ,ירושלים ,3220 ,זייד בן חארת'ה
3000 ,ירושלים ,3221 ,זייד בן חארת'ה סמ2
3000 ,ירושלים ,3222 ,זייד בן חארת'ה סמ4
3000 ,ירושלים ,3223 ,זייד בן חארת'ה סמ6
3000 ,ירושלים ,4404 ,זייד ת'אבת
3000 ,ירושלים ,1383 ,זילברג משה
3000 ,ירושלים ,4781 ,זילברשטיין
3000 ,ירושלים ,987 ,זית רענן
3000 ,ירושלים ,4134 ,זכלה
3000 ,ירושלים ,675 ,זכרון טוביה
3000 ,ירושלים ,964 ,זכרון יעקב
3000 ,ירושלים ,310 ,זכריה
3000 ,ירושלים ,132 ,זכריה הרופא
3000 ,ירושלים ,224 ,זלמן צורף
3000 ,ירושלים ,1934 ,זלץ נתן
3000 ,ירושלים ,4815 ,זלצברגר מכבי
3000 ,ירושלים ,467 ,זמורה
3000 ,ירושלים ,5129 ,זמזם
3000 ,ירושלים ,1525 ,זמנהוף
3000 ,ירושלים ,2012 ,זנגויל
3000 ,ירושלים ,4413 ,זעיין
3000 ,ירושלים ,5249 ,זעקוקה
3000 ,ירושלים ,5250 ,זעקוקה סמ1
3000 ,ירושלים ,6251 ,זעקוקה סמ2
3000 ,ירושלים ,5252 ,זעקוקה סמ3
3000 ,ירושלים ,5253 ,זעקוקה סמ4
3000 ,ירושלים ,4236 ,זעקיקה
3000 ,ירושלים ,3246 ,זערורה
3000 ,ירושלים ,3249 ,זערורה סמ1
3000 ,ירושלים ,3250 ,זערורה סמ3
3000 ,ירושלים ,239 ,זקס משה
3000 ,ירושלים ,4816 ,זר מרדכי
3000 ,ירושלים ,1778 ,זרובבל
3000 ,ירושלים ,4596 ,זרחי ישראל
3000 ,ירושלים ,5020 ,זריצקי דוד
3000 ,ירושלים ,493 ,זרע שמשון
3000 ,ירושלים ,4272 ,ח'אלד אבן אל ואליד
3000 ,ירושלים ,4767 ,ח'ולפא' ראשדין
3000 ,ירושלים ,5367 ,ח'ולפא' ראשדין סמ1
3000 ,ירושלים ,5368 ,ח'ולפא' ראשדין סמ2
3000 ,ירושלים ,4140 ,ח'ילת אל עין
3000 ,ירושלים ,4647 ,ח'ליל א סכאכיני
3000 ,ירושלים ,4096 ,ח'ליל אלח'אלדי
3000 ,ירושלים ,5156 ,ח'ליל סכאכיני סמ11
3000 ,ירושלים ,4979 ,ח'ליל סכאכיני סמ3
3000 ,ירושלים ,4980 ,ח'ליל סכאכיני סמ5
3000 ,ירושלים ,4981 ,ח'ליל סכאכיני סמ7
3000 ,ירושלים ,4982 ,ח'ליל סכאכיני סמ9
3000 ,ירושלים ,3083 ,ח'לת אל עין סמ1
3000 ,ירושלים ,3084 ,ח'לת אל עין סמ2
3000 ,ירושלים ,3085 ,ח'לת אל עין סמ3
3000 ,ירושלים ,3086 ,ח'לת אל עין סמ4
3000 ,ירושלים ,3087 ,ח'לת אל עין סמ5
3000 ,ירושלים ,3088 ,ח'לת אל עין סמ7
3000 ,ירושלים ,5655 ,ח'לת משעל
3000 ,ירושלים ,4947 ,ח'לת משעל סמ10
3000 ,ירושלים ,4949 ,ח'לת משעל סמ8
3000 ,ירושלים ,4977 ,ח'לת משעל סמ9
3000 ,ירושלים ,5587 ,ח'לת סינאד
3000 ,ירושלים ,5053 ,ח'לת סינאד סמ4
3000 ,ירושלים ,5052 ,ח'לת סינאד סמ6
3000 ,ירושלים ,4710 ,ח'רובה
3000 ,ירושלים ,5400 ,ח'רובה סמ5
3000 ,ירושלים ,4231 ,חאלת אל לוזה
3000 ,ירושלים ,5542 ,חאן אלסולטאן
3000 ,ירושלים ,5577 ,חאפז איבראהים
3000 ,ירושלים ,5107 ,חאפז איבראהים סמ3
3000 ,ירושלים ,3149 ,חארת אל-נצארה
3000 ,ירושלים ,3150 ,חארת אל-נצארה סמ1
3000 ,ירושלים ,3151 ,חארת אל-נצארה סמ2
3000 ,ירושלים ,4293 ,חאתם א טאי
3000 ,ירושלים ,4365 ,חב"ד
3000 ,ירושלים ,4726 ,חבאיל אל ערב
3000 ,ירושלים ,5339 ,חבאיל אל ערב סמ2
3000 ,ירושלים ,4326 ,חביב אבשלום
3000 ,ירושלים ,2007 ,חביב חיים
3000 ,ירושלים ,375 ,חבקוק
3000 ,ירושלים ,3045 ,חבר
3000 ,ירושלים ,170 ,חברת משניות
3000 ,ירושלים ,167 ,חברת ש"ס
3000 ,ירושלים ,166 ,חברת תהילים
3000 ,ירושלים ,134 ,חבשוש
3000 ,ירושלים ,4160 ,חגאזי
3000 ,ירושלים ,307 ,חגי
3000 ,ירושלים ,352 ,חגיז משה
3000 ,ירושלים ,1198 ,חובב משה
3000 ,ירושלים ,1506 ,חובבי ציון
3000 ,ירושלים ,4412 ,חוואש
3000 ,ירושלים ,1721 ,חוות הלמוד
3000 ,ירושלים ,509 ,חולדה הנביאה
3000 ,ירושלים ,1471 ,חומת הצלפים
3000 ,ירושלים ,4310 ,חומת נחמיה
3000 ,ירושלים ,6861 ,חומת שמואל
3000 ,ירושלים ,176 ,חוני המעגל
3000 ,ירושלים ,4322 ,חוש א שוויש
3000 ,ירושלים ,5543 ,חוש אבו פרחה
3000 ,ירושלים ,3202 ,חוש אל-אעור
3000 ,ירושלים ,3203 ,חוש אל-אעור סמ1
3000 ,ירושלים ,3204 ,חוש אל-אעור סמ3
3000 ,ירושלים ,3205 ,חוש אל-אעור סמ4
3000 ,ירושלים ,5544 ,חוש אלבאן
3000 ,ירושלים ,5545 ,חוש אלבחארי
3000 ,ירושלים ,5546 ,חוש אלבטש
3000 ,ירושלים ,5547 ,חוש אלבסטי
3000 ,ירושלים ,5548 ,חוש אלוארי
3000 ,ירושלים ,5550 ,חוש אלזארה
3000 ,ירושלים ,5549 ,חוש אלזבאדי
3000 ,ירושלים ,5551 ,חוש אלחטיב
3000 ,ירושלים ,5552 ,חוש אלחילו
3000 ,ירושלים ,5553 ,חוש אלמוקת
3000 ,ירושלים ,5554 ,חוש אלמסעודי
3000 ,ירושלים ,5555 ,חוש אלנחלה
3000 ,ירושלים ,5556 ,חוש אלעג'לוני
3000 ,ירושלים ,5557 ,חוש אלעדס
3000 ,ירושלים ,5558 ,חוש אלקרקי
3000 ,ירושלים ,5559 ,חוש אנצארי
3000 ,ירושלים ,4899 ,חוש דבש
3000 ,ירושלים ,5560 ,חוש דודו
3000 ,ירושלים ,5561 ,חוש דקידק
3000 ,ירושלים ,5562 ,חוש כרמי
3000 ,ירושלים ,5563 ,חוש מונה
3000 ,ירושלים ,5516 ,חוש מראע'ה
3000 ,ירושלים ,5564 ,חוש משעל
3000 ,ירושלים ,5565 ,חוש סריחאן
3000 ,ירושלים ,5566 ,חוש שאהין
3000 ,ירושלים ,1341 ,חות הנוער הציוני
3000 ,ירושלים ,4953 ,חזון איש
3000 ,ירושלים ,1066 ,חזון ציון
3000 ,ירושלים ,187 ,חזנוביץ
3000 ,ירושלים ,1601 ,חזקיהו המלך
3000 ,ירושלים ,4769 ,חטיבת גבעתי
3000 ,ירושלים ,248 ,חטיבת הראל
3000 ,ירושלים ,1833 ,חטיבת ירושלים
3000 ,ירושלים ,4094 ,חטיבת עציוני
3000 ,ירושלים ,3361 ,חי אל נעמאן
3000 ,ירושלים ,3362 ,חי אל נעמאן סמ3
3000 ,ירושלים ,5951 ,חי אל-מדארס
3000 ,ירושלים ,5960 ,חי אל-מדארס סמ10
3000 ,ירושלים ,5952 ,חי אל-מדארס סמ1
3000 ,ירושלים ,5961 ,חי אל-מדארס סמ11
3000 ,ירושלים ,5953 ,חי אל-מדארס סמ3
3000 ,ירושלים ,5962 ,חי אל-מדארס סמ13
3000 ,ירושלים ,5954 ,חי אל-מדארס סמ4
3000 ,ירושלים ,5955 ,חי אל-מדארס סמ5
3000 ,ירושלים ,5963 ,חי אל-מדארס סמ15
3000 ,ירושלים ,5956 ,חי אל-מדארס סמ6
3000 ,ירושלים ,5957 ,חי אל-מדארס סמ7
3000 ,ירושלים ,5958 ,חי אל-מדארס סמ8
3000 ,ירושלים ,5959 ,חי אל-מדארס סמ9
3000 ,ירושלים ,3363 ,חי אלנעמאן סמ4
3000 ,ירושלים ,3364 ,חי אלנעמאן סמ5
3000 ,ירושלים ,3365 ,חי אלנעמאן סמ7
3000 ,ירושלים ,5944 ,חי בשיר
3000 ,ירושלים ,5945 ,חי בשיר סמ1
3000 ,ירושלים ,5946 ,חי בשיר סמ2
3000 ,ירושלים ,5947 ,חי בשיר סמ3
3000 ,ירושלים ,5948 ,חי בשיר סמ4
3000 ,ירושלים ,5949 ,חי בשיר סמ6
3000 ,ירושלים ,5950 ,חי בשיר סמ8
3000 ,ירושלים ,3329 ,חי זע'ייר
3000 ,ירושלים ,3330 ,חי זע'ייר סמ1
3000 ,ירושלים ,3331 ,חי זע'ייר סמ2
3000 ,ירושלים ,3332 ,חי זע'ייר סמ3
3000 ,ירושלים ,3333 ,חי זע'ייר סמ4
3000 ,ירושלים ,3334 ,חי זע'ייר סמ6
3000 ,ירושלים ,3274 ,חי ציאם
3000 ,ירושלים ,3273 ,חי קראעין
3000 ,ירושלים ,137 ,חיבר
3000 ,ירושלים ,181 ,חיי אדם
3000 ,ירושלים ,220 ,חיים דוד הלוי
3000 ,ירושלים ,149 ,חיים עוזר
3000 ,ירושלים ,4756 ,חיל האויר
3000 ,ירושלים ,3949 ,חיל המודיעין
3000 ,ירושלים ,4110 ,חיל הנדסה
3000 ,ירושלים ,4770 ,חיל הקשר
3000 ,ירושלים ,4771 ,חיל חמוש
3000 ,ירושלים ,1618 ,חיל נשים
3000 ,ירושלים ,911 ,חירם
3000 ,ירושלים ,4333 ,חכים אליהו
3000 ,ירושלים ,1281 ,חכם אברהם
3000 ,ירושלים ,242 ,חכם עטיה עזרא
3000 ,ירושלים ,642 ,חכם שלום
3000 ,ירושלים ,1196 ,חכמי יוסף
3000 ,ירושלים ,154 ,חכמי לובלין
3000 ,ירושלים ,4214 ,חלאת חממה
3000 ,ירושלים ,674 ,חלחול
3000 ,ירושלים ,5224 ,חלימה אל-סעדייה
3000 ,ירושלים ,5225 ,חלימה אל-סעדייה סמ1
3000 ,ירושלים ,5226 ,חלימה אל-סעדייה סמ2
3000 ,ירושלים ,1827 ,חלץ
3000 ,ירושלים ,292 ,חלקת מחוקק
3000 ,ירושלים ,4237 ,חלת גיריס
3000 ,ירושלים ,5527 ,חמדאן עאיד
3000 ,ירושלים ,3089 ,חמוש
3000 ,ירושלים ,3090 ,חמוש סמ1
3000 ,ירושלים ,3091 ,חמוש סמ3
3000 ,ירושלים ,3092 ,חמוש סמ5
3000 ,ירושלים ,317 ,חנה
3000 ,ירושלים ,1235 ,חנה רחל מלודמיר
3000 ,ירושלים ,279 ,חנינא שיף
3000 ,ירושלים ,174 ,חנן
3000 ,ירושלים ,1363 ,חננאל
3000 ,ירושלים ,1641 ,חנניה
3000 ,ירושלים ,178 ,חסד לאברהם
3000 ,ירושלים ,1322 ,חסדא
3000 ,ירושלים ,412 ,חסון עוזי
3000 ,ירושלים ,1259 ,חסידוב
3000 ,ירושלים ,218 ,חסידי קרלין
3000 ,ירושלים ,344 ,חפץ חיים
3000 ,ירושלים ,947 ,חפצדי נחום
3000 ,ירושלים ,649 ,חצור
3000 ,ירושלים ,4097 ,חצר חלבי
3000 ,ירושלים ,1810 ,חצרון
3000 ,ירושלים ,1903 ,חקלאי זאב
3000 ,ירושלים ,4244 ,חרבת ב סחור
3000 ,ירושלים ,4224 ,חרבת סאלח
3000 ,ירושלים ,826 ,חרל"פ
3000 ,ירושלים ,1841 ,חרשי ברזל
3000 ,ירושלים ,4406 ,חרת א בוס
3000 ,ירושלים ,4405 ,חרת א תחתה
3000 ,ירושלים ,460 ,חשין
3000 ,ירושלים ,725 ,חתם סופר
3000 ,ירושלים ,5488 ,טאבליא
3000 ,ירושלים ,4675 ,טאלוק
3000 ,ירושלים ,4209 ,טבאליא
3000 ,ירושלים ,1329 ,טבי
3000 ,ירושלים ,3003 ,טבנקין יצחק
3000 ,ירושלים ,704 ,טבריה
3000 ,ירושלים ,1879 ,טדסקי גד
3000 ,ירושלים ,4630 ,טהא חוסיין
3000 ,ירושלים ,4945 ,טהא חוסיין סמ3
3000 ,ירושלים ,1224 ,טהון
3000 ,ירושלים ,1073 ,טוב משה
3000 ,ירושלים ,1312 ,טוביה
3000 ,ירושלים ,4616 ,טובלר
3000 ,ירושלים ,4798 ,טוניק יצחק
3000 ,ירושלים ,4600 ,טופז
3000 ,ירושלים ,1293 ,טור סיני
3000 ,ירושלים ,902 ,טור סיני זאב
3000 ,ירושלים ,1707 ,טורא
3000 ,ירושלים ,1200 ,טחורש כתריאל
3000 ,ירושלים ,1427 ,טיומקין מרדכי
3000 ,ירושלים ,500 ,טיילת ג'ימס מקדונלד
3000 ,ירושלים ,1865 ,טיילת גבריאל
3000 ,ירושלים ,3266 ,טיילת גיא בן הינום
3000 ,ירושלים ,1867 ,טיילת האז
3000 ,ירושלים ,3272 ,טיילת עיר שלם
3000 ,ירושלים ,3219 ,טיילת עמק צורים
3000 ,ירושלים ,455 ,טיכו
3000 ,ירושלים ,5735 ,טלחה
3000 ,ירושלים ,4436 ,טללים
3000 ,ירושלים ,4900 ,טלעת אלמשהאד
3000 ,ירושלים ,225 ,טרודי בירגר
3000 ,ירושלים ,4439 ,טרומן הרי
3000 ,ירושלים ,452 ,טרומפלדור
3000 ,ירושלים ,5331 ,טריק אל-מדארס
3000 ,ירושלים ,3251 ,טריק אל-מדארס סמ1
3000 ,ירושלים ,3252 ,טריק אל-מדארס סמ2
3000 ,ירושלים ,1405 ,טשרניחובסקי
3000 ,ירושלים ,1771 ,יאיר
3000 ,ירושלים ,4291 ,יאקות אל חמווי
3000 ,ירושלים ,4459 ,יגאל
3000 ,ירושלים ,383 ,יגיע כפים
3000 ,ירושלים ,3279 ,יד אבשלום
3000 ,ירושלים ,1911 ,יד ושם
3000 ,ירושלים ,1842 ,יד חרוצים
3000 ,ירושלים ,1603 ,יד מרדכי
3000 ,ירושלים ,946 ,יד שלמה
3000 ,ירושלים ,4773 ,ידין יגאל
3000 ,ירושלים ,1643 ,יהואש
3000 ,ירושלים ,1757 ,יהודה
3000 ,ירושלים ,4501 ,יהודה הלוי
3000 ,ירושלים ,341 ,יהודה המכבי
3000 ,ירושלים ,1337 ,יהודה הנשיא
3000 ,ירושלים ,100 ,יהודה שפירא
3000 ,ירושלים ,347 ,יהודית
3000 ,ירושלים ,255 ,יהויריב
3000 ,ירושלים ,1631 ,יהושע בן נון
3000 ,ירושלים ,4777 ,יהושע יעקב
3000 ,ירושלים ,157 ,יהושפט
3000 ,ירושלים ,4566 ,יהלום
3000 ,ירושלים ,1645 ,יואב
3000 ,ירושלים ,123 ,יואל
3000 ,ירושלים ,1908 ,יובב
3000 ,ירושלים ,223 ,יובל נאמן
3000 ,ירושלים ,1321 ,יוחנן בן זכאי
3000 ,ירושלים ,363 ,יוחנן הסנדלר
3000 ,ירושלים ,413 ,יוחנן מגוש חלב
3000 ,ירושלים ,1806 ,יוכבד
3000 ,ירושלים ,519 ,יוליאנוס קיסר
3000 ,ירושלים ,541 ,יוליה ורומן שפניוק
3000 ,ירושלים ,306 ,יונה
3000 ,ירושלים ,1638 ,יונתן
3000 ,ירושלים ,4553 ,יוסטמן משה
3000 ,ירושלים ,1314 ,יוסי בן יועזר
3000 ,ירושלים ,322 ,יוסף בן מתתיהו
3000 ,ירושלים ,335 ,יוסף הנשיא
3000 ,ירושלים ,297 ,יוסף ויטלזון
3000 ,ירושלים ,664 ,יוסף חיים
3000 ,ירושלים ,3197 ,יוסף פתיחה
3000 ,ירושלים ,3198 ,יוסף פתיחה סמ1
3000 ,ירושלים ,3199 ,יוסף פתיחה סמ2
3000 ,ירושלים ,4808 ,יוספטל גיורא
3000 ,ירושלים ,1617 ,יורדי הסירה
3000 ,ירושלים ,285 ,יורם ורונה קרן
3000 ,ירושלים ,1343 ,יורם רונן
3000 ,ירושלים ,4806 ,יושע מאיר
3000 ,ירושלים ,1632 ,יותם
3000 ,ירושלים ,697 ,יזרעאל
3000 ,ירושלים ,101 ,יחזקאל
3000 ,ירושלים ,1395 ,יחיל חיים
3000 ,ירושלים ,1130 ,ייבין יהושע
3000 ,ירושלים ,470 ,ילון חנוך
3000 ,ירושלים ,376 ,ילין דוד
3000 ,ירושלים ,1817 ,ים המלח
3000 ,ירושלים ,4279 ,ים סוף
3000 ,ירושלים ,1497 ,ימימה
3000 ,ירושלים ,935 ,ימין אבות
3000 ,ירושלים ,1701 ,ימין משה
3000 ,ירושלים ,414 ,ינאי
3000 ,ירושלים ,5405 ,ינובסקי דניאל
3000 ,ירושלים ,3553 ,יסקי חיים
3000 ,ירושלים ,438 ,יעבץ
3000 ,ירושלים ,1765 ,יעל
3000 ,ירושלים ,2129 ,יעקב אלעזר
3000 ,ירושלים ,1933 ,יעקב ונחמה אבן חן
3000 ,ירושלים ,354 ,יעקב מאיר
3000 ,ירושלים ,395 ,יעקבזון
3000 ,ירושלים ,1094 ,יער ירושלים
3000 ,ירושלים ,4537 ,יערי אברהם
3000 ,ירושלים ,1051 ,יפה נוף
3000 ,ירושלים ,529 ,יפה עינים
3000 ,ירושלים ,4543 ,יפה רום
3000 ,ירושלים ,4799 ,יפה שמואל
3000 ,ירושלים ,263 ,יפהן אברהם
3000 ,ירושלים ,612 ,יפו
3000 ,ירושלים ,1768 ,יפתח
3000 ,ירושלים ,4579 ,יצהר
3000 ,ירושלים ,1520 ,יצחק אלחנן
3000 ,ירושלים ,1537 ,יצחק בן נחום
3000 ,ירושלים ,4352 ,יצחק הנדיב
3000 ,ירושלים ,496 ,יצחק יעקב לויכטר
3000 ,ירושלים ,192 ,יצחק שמיר
3000 ,ירושלים ,3042 ,יציאת אירופה
3000 ,ירושלים ,4650 ,יציב
3000 ,ירושלים ,213 ,יקים
3000 ,ירושלים ,5449 ,יקינטון
3000 ,ירושלים ,647 ,יראון
3000 ,ירושלים ,9000 ,ירושלים
3000 ,ירושלים ,937 ,ירמיהו
3000 ,ירושלים ,127 ,ישא ברכה
3000 ,ירושלים ,169 ,ישועת יעקב
3000 ,ירושלים ,1719 ,ישי
3000 ,ירושלים ,1855 ,ישיבת איתרי
3000 ,ירושלים ,635 ,ישיבת בעלז
3000 ,ירושלים ,4292 ,ישיבת הכותל
3000 ,ירושלים ,457 ,ישיבת מרכז הרב
3000 ,ירושלים ,531 ,ישמח מלך
3000 ,ירושלים ,349 ,ישעיהו
3000 ,ירושלים ,359 ,ישעיהו פרס
3000 ,ירושלים ,1875 ,ישראל אלדד
3000 ,ירושלים ,718 ,ישראלס
3000 ,ירושלים ,1781 ,יששכר
3000 ,ירושלים ,5736 ,יתרב
3000 ,ירושלים ,5737 ,יתרב סמ1
3000 ,ירושלים ,4655 ,יתרון
3000 ,ירושלים ,295 ,כ"ח באייר
3000 ,ירושלים ,1165 ,כביש מס 7
3000 ,ירושלים ,4416 ,כביש מס' 60
3000 ,ירושלים ,358 ,כהן אפרים
3000 ,ירושלים ,720 ,כהנוב
3000 ,ירושלים ,1620 ,כובשי קטמון
3000 ,ירושלים ,644 ,כורזים
3000 ,ירושלים ,409 ,כורש
3000 ,ירושלים ,1623 ,כט' בנובמבר
3000 ,ירושלים ,391 ,כי טוב
3000 ,ירושלים ,618 ,כי"ח
3000 ,ירושלים ,6114 ,כיכר אליה קהואג'יאן
3000 ,ירושלים ,6071 ,כיכר אליקים בן מנחם
3000 ,ירושלים ,6088 ,כיכר אנדרה שוראקי
3000 ,ירושלים ,6085 ,כיכר ארצות הברית
3000 ,ירושלים ,6073 ,כיכר גוש קטיף
3000 ,ירושלים ,6069 ,כיכר גינטר פרידלנדר
3000 ,ירושלים ,6076 ,כיכר גפן
3000 ,ירושלים ,6093 ,כיכר דב שפרלינג
3000 ,ירושלים ,6113 ,כיכר דבורה דוסט
3000 ,ירושלים ,6098 ,כיכר הגבורה
3000 ,ירושלים ,6089 ,כיכר הלוחם הדרוזי
3000 ,ירושלים ,6252 ,כיכר העלוי מטולוצין
3000 ,ירושלים ,6108 ,כיכר הרב דב לוי
3000 ,ירושלים ,6115 ,כיכר הרב זילברמן
3000 ,ירושלים ,6082 ,כיכר הרב יהודה צדקה
3000 ,ירושלים ,6103 ,כיכר השולמית
3000 ,ירושלים ,6102 ,כיכר ויקטור שם טוב
3000 ,ירושלים ,6078 ,כיכר זית
3000 ,ירושלים ,6075 ,כיכר חיטה
3000 ,ירושלים ,6087 ,כיכר חנדלי
3000 ,ירושלים ,6107 ,כיכר יהודה עמיחי
3000 ,ירושלים ,6063 ,כיכר יהודה קיל
3000 ,ירושלים ,6083 ,כיכר יהושע זלטר
3000 ,ירושלים ,6086 ,כיכר יוסי בנאי
3000 ,ירושלים ,6106 ,כיכר יוסקה שפירא
3000 ,ירושלים ,6072 ,כיכר יעקב גיל
3000 ,ירושלים ,6081 ,כיכר יעקב כץ
3000 ,ירושלים ,6105 ,כיכר לוחמי האש
3000 ,ירושלים ,6092 ,כיכר מאיר מדן
3000 ,ירושלים ,6070 ,כיכר מארק שאגאל
3000 ,ירושלים ,6055 ,כיכר מרדכי חאיק
3000 ,ירושלים ,6109 ,כיכר משה יפה
3000 ,ירושלים ,6112 ,כיכר משפחת היימן
3000 ,ירושלים ,6061 ,כיכר נח(הנריק)פלוג
3000 ,ירושלים ,6094 ,כיכר סוזה מנדס
3000 ,ירושלים ,6084 ,כיכר סוללי הבירה
3000 ,ירושלים ,6068 ,כיכר פאול רויטמן
3000 ,ירושלים ,6116 ,כיכר פלורה ששון
3000 ,ירושלים ,6077 ,כיכר רימון
3000 ,ירושלים ,6096 ,כיכר רפי וקנין
3000 ,ירושלים ,6097 ,כיכר שושנה קלינר
3000 ,ירושלים ,6079 ,כיכר שעורה
3000 ,ירושלים ,6099 ,כיכר שרה קמינקר
3000 ,ירושלים ,6074 ,כיכר תאנה
3000 ,ירושלים ,6080 ,כיכר תמר
3000 ,ירושלים ,6032 ,ככר אברהם אבן שושן
3000 ,ירושלים ,6034 ,ככר אברהם יצחק הל-א
3000 ,ירושלים ,6035 ,ככר אהבת ישראל
3000 ,ירושלים ,6036 ,ככר אהרון ליפשיץ
3000 ,ירושלים ,6002 ,ככר אוסטר
3000 ,ירושלים ,6037 ,ככר אורד ויינגייט
3000 ,ירושלים ,6038 ,ככר אזרח מור
3000 ,ירושלים ,6003 ,ככר אל גזאלי
3000 ,ירושלים ,6101 ,ככר אל מנטאר
3000 ,ירושלים ,6039 ,ככר אליהו אלישר
3000 ,ירושלים ,6040 ,ככר אליהו ארמוזה
3000 ,ירושלים ,6041 ,ככר אלכסנדר היידן
3000 ,ירושלים ,6004 ,ככר אלנבי
3000 ,ירושלים ,6042 ,ככר אמרי שפר
3000 ,ירושלים ,6028 ,ככר אנה טיכו
3000 ,ירושלים ,6043 ,ככר אפרים די זהב
3000 ,ירושלים ,6044 ,ככר אקצין בנימין
3000 ,ירושלים ,6045 ,ככר ארוין שאול שומר
3000 ,ירושלים ,6056 ,ככר אריה ברוכיאלי
3000 ,ירושלים ,6047 ,ככר אריה זקס
3000 ,ירושלים ,6049 ,ככר ארמין שוורץ
3000 ,ירושלים ,6005 ,ככר ארן זלמן
3000 ,ירושלים ,6050 ,ככר ארצי שרמן
3000 ,ירושלים ,6065 ,ככר אשבל דב
3000 ,ירושלים ,6051 ,ככר בולגריה
3000 ,ירושלים ,6052 ,ככר בוסטון
3000 ,ירושלים ,6053 ,ככר בית החיים
3000 ,ירושלים ,6026 ,ככר בלומנטל
3000 ,ירושלים ,6054 ,ככר בן ציון גיני
3000 ,ירושלים ,6046 ,ככר בנימין שחור
3000 ,ירושלים ,6057 ,ככר בעלזא
3000 ,ירושלים ,6001 ,ככר בר כוכבא
3000 ,ירושלים ,6058 ,ככר ברוך שוחטמן
3000 ,ירושלים ,6059 ,ככר בתי מחסה
3000 ,ירושלים ,6008 ,ככר ג'ורג' מיני
3000 ,ירושלים ,6060 ,ככר גאלה גליקסיון
3000 ,ירושלים ,6062 ,ככר גדוד בית חורון
3000 ,ירושלים ,6006 ,ככר גדוד מוריה
3000 ,ירושלים ,6007 ,ככר גולי קפריסין
3000 ,ירושלים ,6064 ,ככר גיורא לוטן
3000 ,ירושלים ,6009 ,ככר גייקובסון אדי
3000 ,ירושלים ,6010 ,ככר דוד הנבי
3000 ,ירושלים ,6029 ,ככר דוד מרדכי מאיר
3000 ,ירושלים ,6066 ,ככר דוד(דאצא)אלקלעי
3000 ,ירושלים ,6011 ,ככר דניה
3000 ,ירושלים ,6012 ,ככר הארמני
3000 ,ירושלים ,6031 ,ככר החורבה
3000 ,ירושלים ,6067 ,ככר הרב מאיר יהודה
3000 ,ירושלים ,6013 ,ככר הרב קוק
3000 ,ירושלים ,6014 ,ככר מגנס
3000 ,ירושלים ,6015 ,ככר נורדאו
3000 ,ירושלים ,6016 ,ככר סיירת אגוז
3000 ,ירושלים ,6017 ,ככר סיירת חרוב
3000 ,ירושלים ,6018 ,ככר סיירת שקד
3000 ,ירושלים ,6019 ,ככר ספרא
3000 ,ירושלים ,6100 ,ככר ע'זיל
3000 ,ירושלים ,6020 ,ככר עומר כתב
3000 ,ירושלים ,6021 ,ככר פלומר
3000 ,ירושלים ,6091 ,ככר פרופ' עלי דיויס
3000 ,ירושלים ,6022 ,ככר צה"ל
3000 ,ירושלים ,6025 ,ככר ציון
3000 ,ירושלים ,6027 ,ככר צימוקי אריה
3000 ,ירושלים ,6030 ,ככר קליינברגר יצחק
3000 ,ירושלים ,6024 ,ככר ראובן רובין
3000 ,ירושלים ,6023 ,ככר רמז
3000 ,ירושלים ,6033 ,ככראברהם הלפרין
3000 ,ירושלים ,6000 ,ככרולירו חיים אהרון
3000 ,ירושלים ,5450 ,כמון
3000 ,ירושלים ,158 ,כנסת מרדכי
3000 ,ירושלים ,995 ,כנפי נשרים
3000 ,ירושלים ,325 ,כנרת
3000 ,ירושלים ,4824 ,כסא רחמים
3000 ,ירושלים ,1727 ,כספי מרדכי
3000 ,ירושלים ,1818 ,כף החיים
3000 ,ירושלים ,5643 ,כף מרים
3000 ,ירושלים ,646 ,כפר ברעם
3000 ,ירושלים ,6726 ,כפר דוד
3000 ,ירושלים ,221 ,כפר השלוח
3000 ,ירושלים ,4210 ,כפר חסאן
3000 ,ירושלים ,327 ,כפר נחום
3000 ,ירושלים ,3011 ,כפר עברי
3000 ,ירושלים ,1746 ,כפר עציון
3000 ,ירושלים ,1251 ,כפר שלמא
3000 ,ירושלים ,524 ,כץ מיכל
3000 ,ירושלים ,4137 ,כרם אל מופתי
3000 ,ירושלים ,4157 ,כרם אל עלמי
3000 ,ירושלים ,3093 ,כרם אל-הדרה
3000 ,ירושלים ,3094 ,כרם אל-הדרה סמ5
3000 ,ירושלים ,5136 ,כרם אנג'אץ
3000 ,ירושלים ,4133 ,כרם ארסאס
3000 ,ירושלים ,5288 ,כרם וולי
3000 ,ירושלים ,3374 ,כרם זיתים
3000 ,ירושלים ,4402 ,כרם חלה
3000 ,ירושלים ,4149 ,כרם כאלף
3000 ,ירושלים ,4401 ,כרם כניף
3000 ,ירושלים ,5517 ,כרם קמר
3000 ,ירושלים ,1038 ,כרמון
3000 ,ירושלים ,1659 ,כרמיה יצחק
3000 ,ירושלים ,5201 ,כרפס
3000 ,ירושלים ,991 ,כתב סופר
3000 ,ירושלים ,4480 ,לב שטרן
3000 ,ירושלים ,4181 ,לבונה
3000 ,ירושלים ,532 ,לבוקר רנה
3000 ,ירושלים ,373 ,לבוש מרדכי
3000 ,ירושלים ,5481 ,לביא תיאודור
3000 ,ירושלים ,3008 ,לבנה אליעזר
3000 ,ירושלים ,4789 ,לבנון צבי
3000 ,ירושלים ,955 ,לודביג בלום
3000 ,ירושלים ,4313 ,לוחמי הגטאות
3000 ,ירושלים ,4368 ,לוחמי הרובע בתש"ח
3000 ,ירושלים ,268 ,לוטה כהן
3000 ,ירושלים ,1780 ,לוי
3000 ,ירושלים ,251 ,לוי יצחק מברדיצב
3000 ,ירושלים ,1655 ,לויד גורג
3000 ,ירושלים ,1214 ,לוין שמריהו
3000 ,ירושלים ,440 ,לונץ משה
3000 ,ירושלים ,1792 ,לופו שמואל
3000 ,ירושלים ,1088 ,לופס אליהו
3000 ,ירושלים ,3009 ,לוץ צארלס
3000 ,ירושלים ,1805 ,לוצאטי לואג'י
3000 ,ירושלים ,1742 ,לוריא
3000 ,ירושלים ,968 ,לורך נתנאל
3000 ,ירושלים ,4324 ,לח"י
3000 ,ירושלים ,4066 ,לטין פטריאכט
3000 ,ירושלים ,4843 ,ליאון בן ציון
3000 ,ירושלים ,4833 ,ליבני איתן
3000 ,ירושלים ,387 ,ליה ון ליר
3000 ,ירושלים ,142 ,לייב דיין
3000 ,ירושלים ,2502 ,לייב הורביץ לומזה
3000 ,ירושלים ,1740 ,לייב יפה
3000 ,ירושלים ,1394 ,לייבוביץ
3000 ,ירושלים ,4790 ,ליכטמן אברהם דוד
3000 ,ירושלים ,1891 ,ליכטנשטיין
3000 ,ירושלים ,960 ,ליליאן
3000 ,ירושלים ,1521 ,לינקולן
3000 ,ירושלים ,4442 ,ליפסקי לואי
3000 ,ירושלים ,1885 ,ליפצ'ין סול
3000 ,ירושלים ,1853 ,ליפשיץ נחום
3000 ,ירושלים ,4570 ,ליש
3000 ,ירושלים ,666 ,לכיש
3000 ,ירושלים ,3556 ,למפל הדסה
3000 ,ירושלים ,913 ,למפרונטי
3000 ,ירושלים ,1874 ,לנגר יחיאל
3000 ,ירושלים ,190 ,לנדנר
3000 ,ירושלים ,1877 ,לנקין אליהו
3000 ,ירושלים ,150 ,לפידות
3000 ,ירושלים ,4599 ,לשם
3000 ,ירושלים ,162 ,מאה שערים
3000 ,ירושלים ,4688 ,מאי זיאדה
3000 ,ירושלים ,1298 ,מאיר אבנר
3000 ,ירושלים ,3999 ,מאיר וייס
3000 ,ירושלים ,536 ,מאיר ולנה טוביאנסקי
3000 ,ירושלים ,1527 ,מאפו
3000 ,ירושלים ,1067 ,מבוא אורי אבא
3000 ,ירושלים ,3055 ,מבוא אחישר
3000 ,ירושלים ,2504 ,מבוא בייניש סלנט
3000 ,ירושלים ,474 ,מבוא בית דוד
3000 ,ירושלים ,473 ,מבוא בית הכנסת
3000 ,ירושלים ,462 ,מבוא בן ברוך
3000 ,ירושלים ,1450 ,מבוא בנין
3000 ,ירושלים ,4190 ,מבוא בשמת
3000 ,ירושלים ,277 ,מבוא דוד רזניק
3000 ,ירושלים ,4319 ,מבוא די זהב
3000 ,ירושלים ,3051 ,מבוא דקר
3000 ,ירושלים ,851 ,מבוא האתרוג
3000 ,ירושלים ,4973 ,מבוא הברכה
3000 ,ירושלים ,4186 ,מבוא הדודאים
3000 ,ירושלים ,4974 ,מבוא החוסן
3000 ,ירושלים ,852 ,מבוא החיטה
3000 ,ירושלים ,853 ,מבוא הלולב
3000 ,ירושלים ,4312 ,מבוא המאבק
3000 ,ירושלים ,449 ,מבוא המתמיד
3000 ,ירושלים ,1397 ,מבוא העולה
3000 ,ירושלים ,3053 ,מבוא העשרה
3000 ,ירושלים ,4184 ,מבוא הצרי
3000 ,ירושלים ,4183 ,מבוא הקנמון
3000 ,ירושלים ,4301 ,מבוא הר שפר
3000 ,ירושלים ,3059 ,מבוא הררי
3000 ,ירושלים ,854 ,מבוא השעורה
3000 ,ירושלים ,1477 ,מבוא השער
3000 ,ירושלים ,4344 ,מבוא חיות פרץ
3000 ,ירושלים ,4266 ,מבוא חיי עולם
3000 ,ירושלים ,2501 ,מבוא חיים מקובנה
3000 ,ירושלים ,4303 ,מבוא חצרות
3000 ,ירושלים ,1625 ,מבוא יורם
3000 ,ירושלים ,713 ,מבוא יזהר
3000 ,ירושלים ,4298 ,מבוא יטבתה
3000 ,ירושלים ,2503 ,מבוא ילין יהושע
3000 ,ירושלים ,4189 ,מבוא יסמין
3000 ,ירושלים ,1079 ,מבוא יצחק
3000 ,ירושלים ,4294 ,מבוא לבנה
3000 ,ירושלים ,1417 ,מבוא מידב
3000 ,ירושלים ,4299 ,מבוא נחל צין
3000 ,ירושלים ,4305 ,מבוא נחליאל
3000 ,ירושלים ,4188 ,מבוא נטף
3000 ,ירושלים ,204 ,מבוא סם שפיגל
3000 ,ירושלים ,4300 ,מבוא עברונה
3000 ,ירושלים ,4314 ,מבוא פל ים
3000 ,ירושלים ,4348 ,מבוא פלג אדמונד
3000 ,ירושלים ,855 ,מבוא פרי האדמה
3000 ,ירושלים ,856 ,מבוא פרי העץ
3000 ,ירושלים ,1104 ,מבוא פרנקל שלמה
3000 ,ירושלים ,4297 ,מבוא צלמונה
3000 ,ירושלים ,4187 ,מבוא קטורה
3000 ,ירושלים ,4192 ,מבוא קידה
3000 ,ירושלים ,4185 ,מבוא קציעה
3000 ,ירושלים ,262 ,מבוא רוזנטל אברהם
3000 ,ירושלים ,1313 ,מבוא רוזנשטיין דב
3000 ,ירושלים ,4304 ,מבוא רפידים
3000 ,ירושלים ,4835 ,מבוא שלום בנימין
3000 ,ירושלים ,4191 ,מבוא שרף
3000 ,ירושלים ,4302 ,מבוא תמנע
3000 ,ירושלים ,1610 ,מבצע קדש
3000 ,ירושלים ,1189 ,מבקר המדינה
3000 ,ירושלים ,1049 ,מגדים
3000 ,ירושלים ,1317 ,מגדל נופים
3000 ,ירושלים ,258 ,מגדלי נחום
3000 ,ירושלים ,901 ,מגיני ירושלים
3000 ,ירושלים ,260 ,מגן האלף
3000 ,ירושלים ,458 ,מגרש הרוסים
3000 ,ירושלים ,4282 ,מדבר סיני
3000 ,ירושלים ,1474 ,מדרגות גן עדן
3000 ,ירושלים ,1448 ,מדרגות האסיף
3000 ,ירושלים ,1476 ,מדרגות הביקור
3000 ,ירושלים ,1480 ,מדרגות הבציר
3000 ,ירושלים ,1472 ,מדרגות הכפר
3000 ,ירושלים ,1447 ,מדרגות המסיק
3000 ,ירושלים ,1478 ,מדרגות הרומאים
3000 ,ירושלים ,1445 ,מדרגות מיץ פטל
3000 ,ירושלים ,980 ,מדרשה לתזונה
3000 ,ירושלים ,4428 ,מוג'יר א דין
3000 ,ירושלים ,323 ,מודיעין
3000 ,ירושלים ,1147 ,מוזיאון ישראל
3000 ,ירושלים ,4156 ,מוזיאון רוקפלר
3000 ,ירושלים ,1857 ,מוזס זיגפריד
3000 ,ירושלים ,4906 ,מוחמד אל פאתח
3000 ,ירושלים ,5097 ,מוחמד אל פאתח סמ1
3000 ,ירושלים ,5098 ,מוחמד אל פאתח סמ2
3000 ,ירושלים ,5753 ,מוחמד חמדן רבאיעה
3000 ,ירושלים ,4270 ,מוטה
3000 ,ירושלים ,6209 ,מוכתאר דאוד עוויסאת
3000 ,ירושלים ,832 ,מולכו
3000 ,ירושלים ,405 ,מונבז
3000 ,ירושלים ,4908 ,מוניר חסאן
3000 ,ירושלים ,3253 ,מוניר חסאן סמ1
3000 ,ירושלים ,3254 ,מוניר חסאן סמ3
3000 ,ירושלים ,3255 ,מוניר חסאן סמ4
3000 ,ירושלים ,4674 ,מוסא סייק
3000 ,ירושלים ,1675 ,מוסד אילין
3000 ,ירושלים ,122 ,מוסיוף שלמה
3000 ,ירושלים ,5247 ,מוסעב בן עומיר סמ1
3000 ,ירושלים ,6680 ,מוסקוביה
3000 ,ירושלים ,7002 ,מועדון הרכיבה
3000 ,ירושלים ,1438 ,מועלם שמעון נסים
3000 ,ירושלים ,6627 ,מוצא תחתית
3000 ,ירושלים ,5506 ,מוצעב בן עומייר
3000 ,ירושלים ,5684 ,מוצעב בן עומייר סמ3
3000 ,ירושלים ,1222 ,מוצקין אריה
3000 ,ירושלים ,475 ,מוצרי מכבי
3000 ,ירושלים ,4450 ,מורגנטאו הנרי
3000 ,ירושלים ,6655 ,מורדות בית וגן
3000 ,ירושלים ,915 ,מוריה
3000 ,ירושלים ,4082 ,מוריסטן
3000 ,ירושלים ,4155 ,מושבה אמריקנית
3000 ,ירושלים ,734 ,מזור
3000 ,ירושלים ,428 ,מזיא אהרן
3000 ,ירושלים ,678 ,מזכרת משה
3000 ,ירושלים ,4792 ,מזל אריה
3000 ,ירושלים ,4861 ,מזל גדי
3000 ,ירושלים ,4862 ,מזל דלי
3000 ,ירושלים ,4793 ,מזל טלה
3000 ,ירושלים ,4795 ,מזל מאזנים
3000 ,ירושלים ,4796 ,מזל קשת
3000 ,ירושלים ,4794 ,מזל שור
3000 ,ירושלים ,4828 ,מזל תאומים
3000 ,ירושלים ,3020 ,מזר בנימין
3000 ,ירושלים ,159 ,מזרחי יוסף
3000 ,ירושלים ,245 ,מזרחי מישאל
3000 ,ירושלים ,4351 ,מח"ל
3000 ,ירושלים ,3352 ,מחג'רת עתמה
3000 ,ירושלים ,3353 ,מחג'רת עתמה סמ2
3000 ,ירושלים ,3354 ,מחג'רת עתמה סמ3
3000 ,ירושלים ,254 ,מחוזא
3000 ,ירושלים ,4909 ,מחיסן
3000 ,ירושלים ,5406 ,מחיסן סמ1
3000 ,ירושלים ,5407 ,מחיסן סמ2
3000 ,ירושלים ,5409 ,מחיסן סמ3
3000 ,ירושלים ,3349 ,מחיסן סמ5
3000 ,ירושלים ,1611 ,מחלקי המים
3000 ,ירושלים ,4027 ,מחמדיה
3000 ,ירושלים ,3206 ,מחמוד ברבר
3000 ,ירושלים ,6571 ,מחנה יהודה
3000 ,ירושלים ,194 ,מחנה יצחק
3000 ,ירושלים ,4267 ,מחנה פליטים א ראם
3000 ,ירושלים ,4103 ,מחנה פליטים קלנדיה
3000 ,ירושלים ,4102 ,מחנה פליטים שעפט
3000 ,ירושלים ,6629 ,מחנה שנלר
3000 ,ירושלים ,113 ,מחניים
3000 ,ירושלים ,606 ,מיוחס
3000 ,ירושלים ,526 ,מיטיב נגן
3000 ,ירושלים ,1113 ,מיכאלסון
3000 ,ירושלים ,309 ,מיכה
3000 ,ירושלים ,111 ,מיכל
3000 ,ירושלים ,2505 ,מיכל הכהן
3000 ,ירושלים ,1098 ,מיכלין
3000 ,ירושלים ,4484 ,מינץ
3000 ,ירושלים ,1123 ,מינצברג
3000 ,ירושלים ,1275 ,מיס לנדאו
3000 ,ירושלים ,4284 ,מיצרי טירן
3000 ,ירושלים ,331 ,מירון
3000 ,ירושלים ,4482 ,מירסקי יצחק
3000 ,ירושלים ,1640 ,מישאל
3000 ,ירושלים ,5738 ,מכה
3000 ,ירושלים ,5739 ,מכה סמ1
3000 ,ירושלים ,1133 ,מכון לב
3000 ,ירושלים ,1319 ,מכור
3000 ,ירושלים ,4971 ,מכלוף עידן
3000 ,ירושלים ,1169 ,מכללת ירושלים לבנות
3000 ,ירושלים ,1250 ,מכסיקו
3000 ,ירושלים ,304 ,מלאכי
3000 ,ירושלים ,1815 ,מלון גני יהודה
3000 ,ירושלים ,1816 ,מלון דיפלומט
3000 ,ירושלים ,3061 ,מלון הייאט ריג'נסי
3000 ,ירושלים ,1748 ,מלון זוהר
3000 ,ירושלים ,477 ,מלון טירת בת שבע
3000 ,ירושלים ,433 ,מלון מגדל העיר
3000 ,ירושלים ,914 ,מלון רם
3000 ,ירושלים ,2050 ,מלון רמת תמיר
3000 ,ירושלים ,3062 ,מלון שער יפו
3000 ,ירושלים ,1708 ,מלכי
3000 ,ירושלים ,305 ,מלכי ישראל
3000 ,ירושלים ,4269 ,מלכי צדק
3000 ,ירושלים ,1668 ,מלל
3000 ,ירושלים ,1107 ,מלצר פיבל
3000 ,ירושלים ,454 ,ממילא
3000 ,ירושלים ,2003 ,מן
3000 ,ירושלים ,497 ,מנדל (ישראל) קפלן
3000 ,ירושלים ,1515 ,מנדלי מוכר ספרים
3000 ,ירושלים ,4473 ,מנדלסון אריק
3000 ,ירושלים ,1510 ,מנה
3000 ,ירושלים ,451 ,מנורה
3000 ,ירושלים ,4849 ,מנורי
3000 ,ירושלים ,4091 ,מנזר המארונים
3000 ,ירושלים ,1669 ,מנזר סן סימון
3000 ,ירושלים ,329 ,מנחם
3000 ,ירושלים ,490 ,מנחם ירושלים
3000 ,ירושלים ,1151 ,מנחם מנדל משקלוב
3000 ,ירושלים ,4907 ,מנחת יצחק
3000 ,ירושלים ,4010 ,מנטקה א חמרה
3000 ,ירושלים ,625 ,מני אליהו
3000 ,ירושלים ,1776 ,מנשה
3000 ,ירושלים ,4581 ,מס ראובן
3000 ,ירושלים ,5599 ,מסורת אל-מיה
3000 ,ירושלים ,5646 ,מסורת אל-מיה סמ1
3000 ,ירושלים ,689 ,מסילת ישרים
3000 ,ירושלים ,4446 ,מסליאנסקי צבי
3000 ,ירושלים ,5229 ,מסעדה
3000 ,ירושלים ,5740 ,מסעדה סמ1
3000 ,ירושלים ,5741 ,מסעדה סמ2
3000 ,ירושלים ,5742 ,מסעדה סמ3
3000 ,ירושלים ,5743 ,מסעדה סמ4
3000 ,ירושלים ,1656 ,מסריק
3000 ,ירושלים ,6775 ,מע תלפיות
3000 ,ירושלים ,1547 ,מעבר אהרן אליאס
3000 ,ירושלים ,4277 ,מעבר המיתלה
3000 ,ירושלים ,745 ,מעבר הראבד
3000 ,ירושלים ,443 ,מעבר מזרחי עזרא
3000 ,ירושלים ,1041 ,מעגל בית המדרש
3000 ,ירושלים ,1475 ,מעגל הניקבה
3000 ,ירושלים ,4342 ,מעגלות הרב פרדס
3000 ,ירושלים ,3026 ,מעגלי הרי"ם לוין
3000 ,ירושלים ,1366 ,מעגלי יבנה
3000 ,ירושלים ,4438 ,מעוז
3000 ,ירושלים ,804 ,מעוז עובד א
3000 ,ירושלים ,671 ,מעון
3000 ,ירושלים ,1353 ,מעון אנגלסקי
3000 ,ירושלים ,4658 ,מעון דליה
3000 ,ירושלים ,1819 ,מעון תלפיות
3000 ,ירושלים ,1115 ,מעונות בזק
3000 ,ירושלים ,5650 ,מעונות ברונפמן
3000 ,ירושלים ,1234 ,מעלה אהרון ראובני
3000 ,ירושלים ,4595 ,מעלה אורנים
3000 ,ירושלים ,5568 ,מעלה אלחנקה
3000 ,ירושלים ,293 ,מעלה דרגה
3000 ,ירושלים ,1272 ,מעלה האכסניה
3000 ,ירושלים ,1479 ,מעלה הבוסתן
3000 ,ירושלים ,1906 ,מעלה הברושים
3000 ,ירושלים ,3280 ,מעלה החאלבים
3000 ,ירושלים ,1174 ,מעלה הילמן
3000 ,ירושלים ,5569 ,מעלה המוגרבים
3000 ,ירושלים ,1473 ,מעלה המועצה
3000 ,ירושלים ,1418 ,מעלה הצליינים
3000 ,ירושלים ,1444 ,מעלה הצניר
3000 ,ירושלים ,1449 ,מעלה הקיסוס
3000 ,ירושלים ,1412 ,מעלה השבשבת
3000 ,ירושלים ,4512 ,מעלה השלום
3000 ,ירושלים ,3271 ,מעלה השלום סמ1
3000 ,ירושלים ,1355 ,מעלה זאב
3000 ,ירושלים ,4730 ,מעלה זיתים
3000 ,ירושלים ,4468 ,מעלה כגן הלנה
3000 ,ירושלים ,1443 ,מעלה מוזס
3000 ,ירושלים ,291 ,מעלה תימן
3000 ,ירושלים ,4115 ,מעלופיה
3000 ,ירושלים ,286 ,מעלות אהל שלמה
3000 ,ירושלים ,1124 ,מעלות אלפיה
3000 ,ירושלים ,6835 ,מעלות אלרם
3000 ,ירושלים ,1139 ,מעלות אפרים זלמן
3000 ,ירושלים ,1700 ,מעלות בדאהב יצחק
3000 ,ירושלים ,1830 ,מעלות בני
3000 ,ירושלים ,3033 ,מעלות דושינסקי
3000 ,ירושלים ,6501 ,מעלות דפנה
3000 ,ירושלים ,379 ,מעלות הדר והדס
3000 ,ירושלים ,5805 ,מעלות המדרשה
3000 ,ירושלים ,1074 ,מעלות המחנך
3000 ,ירושלים ,4008 ,מעלות הנזירות
3000 ,ירושלים ,837 ,מעלות הרב אטינגר
3000 ,ירושלים ,5808 ,מעלות הרב גץ
3000 ,ירושלים ,246 ,מעלות הרב מרודניק
3000 ,ירושלים ,1909 ,מעלות הרב נוריאל
3000 ,ירושלים ,5525 ,מעלות זע'ל
3000 ,ירושלים ,5012 ,מעלות חדוה
3000 ,ירושלים ,2130 ,מעלות חנן פורת
3000 ,ירושלים ,4437 ,מעלות כיסופים
3000 ,ירושלים ,1431 ,מעלות לגרלף סלמה
3000 ,ירושלים ,5015 ,מעלות מועדים
3000 ,ירושלים ,6837 ,מעלות מוריה
3000 ,ירושלים ,3372 ,מעלות מי השילוח
3000 ,ירושלים ,3262 ,מעלות מעיין הגיחון
3000 ,ירושלים ,1370 ,מעלות סימון
3000 ,ירושלים ,5509 ,מעלות סמרין
3000 ,ירושלים ,1243 ,מעלות סנש חנה
3000 ,ירושלים ,1429 ,מעלות עבאדי יצחק
3000 ,ירושלים ,5523 ,מעלות עדילה
3000 ,ירושלים ,4375 ,מעלות עיר דוד
3000 ,ירושלים ,5014 ,מעלות פדויים
3000 ,ירושלים ,1197 ,מעלות פרנקל אליהו
3000 ,ירושלים ,1140 ,מעלות צבי לצדיק
3000 ,ירושלים ,834 ,מעלות ריבלין משה
3000 ,ירושלים ,5013 ,מעלות רננים
3000 ,ירושלים ,1424 ,מעלות שנהר
3000 ,ירושלים ,4154 ,מעלות שער הפרחים
3000 ,ירושלים ,1148 ,מעלות שר שלום
3000 ,ירושלים ,4530 ,מעמדות ישראל
3000 ,ירושלים ,4388 ,מעמל אל תלג'
3000 ,ירושלים ,958 ,מענה שימחה
3000 ,ירושלים ,3278 ,מערת הנביאים
3000 ,ירושלים ,4654 ,מעש
3000 ,ירושלים ,1838 ,מעשה חושב
3000 ,ירושלים ,4653 ,מפעלות
3000 ,ירושלים ,4289 ,מפרץ שלמה
3000 ,ירושלים ,1752 ,מצדה
3000 ,ירושלים ,656 ,מצפה
3000 ,ירושלים ,486 ,מצפה בחורים
3000 ,ירושלים ,6857 ,מצפה גילה
3000 ,ירושלים ,1142 ,מצפה מנחם
3000 ,ירושלים ,3281 ,מקדם לעיר
3000 ,ירושלים ,4318 ,מקוה ישראל
3000 ,ירושלים ,1794 ,מקור חיים
3000 ,ירושלים ,957 ,מקלף אריה
3000 ,ירושלים ,5063 ,מקסימוס צאייג
3000 ,ירושלים ,4243 ,מר אליאס
3000 ,ירושלים ,4672 ,מרג' אבן עאמר
3000 ,ירושלים ,4943 ,מרג' אבן עאמר סמ4
3000 ,ירושלים ,5656 ,מרג' אל מהור
3000 ,ירושלים ,5103 ,מרג' אל מהור סמ5
3000 ,ירושלים ,5104 ,מרג' אל מהור סמ7
3000 ,ירושלים ,1306 ,מרגולין
3000 ,ירושלים ,4415 ,מרגיל מחוור
3000 ,ירושלים ,4544 ,מרגלית
3000 ,ירושלים ,1544 ,מרדכי ג'נאשוילי
3000 ,ירושלים ,1756 ,מרדכי היהודי
3000 ,ירושלים ,1185 ,מרה
3000 ,ירושלים ,5454 ,מרוה
3000 ,ירושלים ,1095 ,מרום ציון
3000 ,ירושלים ,4355 ,מרזוק משה
3000 ,ירושלים ,1848 ,מרטון יחזקאל
3000 ,ירושלים ,1500 ,מרטין לותר קינג
3000 ,ירושלים ,4825 ,מרידור אליהו
3000 ,ירושלים ,1760 ,מרים החשמונאית
3000 ,ירושלים ,1899 ,מרינוב חיים
3000 ,ירושלים ,1503 ,מרכוס
3000 ,ירושלים ,6554 ,מרכז (מגרש הרוסים)
3000 ,ירושלים ,6555 ,מרכז (מרכז כלל)
3000 ,ירושלים ,6553 ,מרכז העיר
3000 ,ירושלים ,4575 ,מרכז מסחרי גילה
3000 ,ירושלים ,1271 ,מרכז מסחרי ק יובל
3000 ,ירושלים ,897 ,מרכז ספיר
3000 ,ירושלים ,4531 ,מרכז קליטה גילה
3000 ,ירושלים ,1907 ,מרן הגרי"ש אלישיב
3000 ,ירושלים ,6090 ,מרן הרב עובדיה יוסף
3000 ,ירושלים ,1436 ,מרץ דוד
3000 ,ירושלים ,1245 ,מרקו ברוך
3000 ,ירושלים ,282 ,מרתה במברגר
3000 ,ירושלים ,4732 ,משאריף
3000 ,ירושלים ,5130 ,משאריף סמ5
3000 ,ירושלים ,1434 ,משאת משה
3000 ,ירושלים ,4364 ,משגב לדך
3000 ,ירושלים ,4751 ,משה (מוסא) פלד
3000 ,ירושלים ,198 ,משה ואסתר לעזער
3000 ,ירושלים ,244 ,משה קראוס
3000 ,ירושלים ,274 ,משה ששון
3000 ,ירושלים ,1745 ,משואות יצחק
3000 ,ירושלים ,4760 ,משטרת הישובים
3000 ,ירושלים ,1046 ,משיב מנחם
3000 ,ירושלים ,840 ,משכן נשיאי ישראל
3000 ,ירושלים ,1076 ,משכן שילה
3000 ,ירושלים ,688 ,משכנות
3000 ,ירושלים ,4660 ,משכנות השלום
3000 ,ירושלים ,6761 ,משכנות שאננים
3000 ,ירושלים ,1728 ,משמיע שלום
3000 ,ירושלים ,4278 ,משמר הגבול
3000 ,ירושלים ,1602 ,משמר העם
3000 ,ירושלים ,505 ,משמרות
3000 ,ירושלים ,4529 ,משמרות הכהונה
3000 ,ירושלים ,922 ,משמרת חיים
3000 ,ירושלים ,476 ,משעול הגבורה
3000 ,ירושלים ,5305 ,משעול הדקלים
3000 ,ירושלים ,4590 ,משעול ההדס
3000 ,ירושלים ,5302 ,משעול היערה
3000 ,ירושלים ,4591 ,משעול הכתרון
3000 ,ירושלים ,489 ,משעול הלוחמת אסתר
3000 ,ירושלים ,5301 ,משעול המגלית
3000 ,ירושלים ,5303 ,משעול הקורטם
3000 ,ירושלים ,4589 ,משעול הקורנית
3000 ,ירושלים ,4593 ,משעול הרותם
3000 ,ירושלים ,276 ,משעול זהב מרדכי
3000 ,ירושלים ,4592 ,משעול מורן
3000 ,ירושלים ,5304 ,משעול עוזרד
3000 ,ירושלים ,4492 ,משפט דרייפוס
3000 ,ירושלים ,6595 ,מתחם הולילנד
3000 ,ירושלים ,6557 ,מתחם ימק"א
3000 ,ירושלים ,1769 ,מתתיהו
3000 ,ירושלים ,534 ,נאות דוד
3000 ,ירושלים ,533 ,נאות דשא
3000 ,ירושלים ,681 ,נבו
3000 ,ירושלים ,610 ,נבון
3000 ,ירושלים ,4622 ,נבי שועייב
3000 ,ירושלים ,930 ,נג'ארה
3000 ,ירושלים ,592 ,נג'מת אל קדס
3000 ,ירושלים ,1605 ,נגבה
3000 ,ירושלים ,4804 ,נדבה יוסף
3000 ,ירושלים ,3056 ,נהגי הפרדות
3000 ,ירושלים ,1295 ,נהוראי
3000 ,ירושלים ,641 ,נהר פרת
3000 ,ירושלים ,658 ,נוב
3000 ,ירושלים ,1357 ,נובומיסקי משה
3000 ,ירושלים ,6678 ,נוה גרנות
3000 ,ירושלים ,6570 ,נוה צדק
3000 ,ירושלים ,6645 ,נוה שכטר
3000 ,ירושלים ,1166 ,נווה שאנן
3000 ,ירושלים ,2021 ,נונייז בנג'מין
3000 ,ירושלים ,5157 ,נוסייבה
3000 ,ירושלים ,6855 ,נוף גילה
3000 ,ירושלים ,1099 ,נוף הרים
3000 ,ירושלים ,6856 ,נוף ירושלים
3000 ,ירושלים ,6791 ,נוף רמות
3000 ,ירושלים ,228 ,נופי מדבר
3000 ,ירושלים ,4564 ,נופך
3000 ,ירושלים ,5006 ,נור אל הודא
3000 ,ירושלים ,4268 ,נור אל-דין
3000 ,ירושלים ,5661 ,נוראל הוודא
3000 ,ירושלים ,959 ,נורדאו
3000 ,ירושלים ,5524 ,נזלת אבו סוואי
3000 ,ירושלים ,3225 ,נזלת אבו סוואי סמ2
3000 ,ירושלים ,3208 ,נזלת אבו-סוואי סמ2
3000 ,ירושלים ,1126 ,נזר דוד
3000 ,ירושלים ,312 ,נחום
3000 ,ירושלים ,177 ,נחום איש גמזו
3000 ,ירושלים ,3227 ,נחלת יצחק
3000 ,ירושלים ,205 ,נחלת צבי
3000 ,ירושלים ,719 ,נחלת צדוק
3000 ,ירושלים ,444 ,נחלת שבעה
3000 ,ירושלים ,4373 ,נחמה
3000 ,ירושלים ,4516 ,נחמו
3000 ,ירושלים ,313 ,נחמיה
3000 ,ירושלים ,2016 ,נחמיה מנשה
3000 ,ירושלים ,1723 ,נחשון
3000 ,ירושלים ,4317 ,נטר
3000 ,ירושלים ,989 ,נטרונאי גאון
3000 ,ירושלים ,4787 ,ניב דוד
3000 ,ירושלים ,5393 ,ניהאד אבו ע'רבייה
3000 ,ירושלים ,6646 ,ניות
3000 ,ירושלים ,1208 ,ניידיץ
3000 ,ירושלים ,3013 ,ניימן שמואל
3000 ,ירושלים ,1670 ,ניל"י
3000 ,ירושלים ,1247 ,ניסן אברהם
3000 ,ירושלים ,1225 ,ניסנבאום
3000 ,ירושלים ,4826 ,ניקובה רינה
3000 ,ירושלים ,1338 ,ניקנור
3000 ,ירושלים ,1286 ,ניקרגואה
3000 ,ירושלים ,1849 ,נכון שלמה
3000 ,ירושלים ,530 ,נעים זמירות
3000 ,ירושלים ,5522 ,נעמאן
3000 ,ירושלים ,1726 ,נעמי
3000 ,ירושלים ,365 ,נפחא
3000 ,ירושלים ,1775 ,נפתלי
3000 ,ירושלים ,200 ,נציבין
3000 ,ירושלים ,1332 ,נקדימון
3000 ,ירושלים ,4327 ,נקר מאיר
3000 ,ירושלים ,650 ,נרבתה
3000 ,ירושלים ,5017 ,נרות שבת
3000 ,ירושלים ,703 ,נרקיס
3000 ,ירושלים ,4557 ,נשר
3000 ,ירושלים ,1340 ,נתאי הארבלי
3000 ,ירושלים ,4791 ,נתיב המזלות
3000 ,ירושלים ,1413 ,נתיב זהרה
3000 ,ירושלים ,738 ,נתיב יצחק
3000 ,ירושלים ,1012 ,נתיבות משנה
3000 ,ירושלים ,5476 ,נתיבי עם
3000 ,ירושלים ,508 ,נתן הנביא
3000 ,ירושלים ,4829 ,נתנזון איסר
3000 ,ירושלים ,4124 ,סאחת אל בלד
3000 ,ירושלים ,5977 ,סאלם אל-ראס
3000 ,ירושלים ,5978 ,סאלם אל-ראס סמ1
3000 ,ירושלים ,5979 ,סאלם אל-ראס סמ2
3000 ,ירושלים ,4848 ,סגולת ישראל
3000 ,ירושלים ,4494 ,סגל משה צבי
3000 ,ירושלים ,1008 ,סגן כהן מאיר ומיכאל
3000 ,ירושלים ,4811 ,סדן דב
3000 ,ירושלים ,4258 ,סהל אל עבדאן
3000 ,ירושלים ,4222 ,סוואחירה
3000 ,ירושלים ,4093 ,סוויקאת עלון
3000 ,ירושלים ,1673 ,סוזן דניאל-נטף
3000 ,ירושלים ,690 ,סוכת שלום
3000 ,ירושלים ,1215 ,סולד הנריטה
3000 ,ירושלים ,357 ,סולובייציק
3000 ,ירושלים ,4944 ,סולטאן קוטוז
3000 ,ירושלים ,4275 ,סולטן סולימאן
3000 ,ירושלים ,4739 ,סולטן קוטז
3000 ,ירושלים ,4432 ,סולם יעקב
3000 ,ירושלים ,667 ,סומך עובדיה
3000 ,ירושלים ,1342 ,סופר מרדכי
3000 ,ירושלים ,4083 ,סוק אבטימוס
3000 ,ירושלים ,4039 ,סוק אל בשורה
3000 ,ירושלים ,4087 ,סוק אל חוסור
3000 ,ירושלים ,4037 ,סוק אל לחמין
3000 ,ירושלים ,4036 ,סוק אל עטרין
3000 ,ירושלים ,4033 ,סוק אל קטנין
3000 ,ירושלים ,4035 ,סוק חן א זית
3000 ,ירושלים ,1518 ,סוקולוב
3000 ,ירושלים ,3040 ,סוקניק אליעזר
3000 ,ירושלים ,259 ,סורא
3000 ,ירושלים ,952 ,סורוצקין
3000 ,ירושלים ,479 ,סטוינובסקי רבקה
3000 ,ירושלים ,1176 ,סיוון עמירם
3000 ,ירושלים ,4453 ,סיון שלום
3000 ,ירושלים ,1135 ,סיימון אריה
3000 ,ירושלים ,4759 ,סיירת גולני
3000 ,ירושלים ,4440 ,סילבר אבא הלל
3000 ,ירושלים ,4251 ,סילוואן
3000 ,ירושלים ,1037 ,סילמן
3000 ,ירושלים ,1202 ,סימן טוב יעקב
3000 ,ירושלים ,1226 ,סירני
3000 ,ירושלים ,1902 ,סירקיס דניאל
3000 ,ירושלים ,4712 ,סירת אל חג'ה
3000 ,ירושלים ,3350 ,סירת אל חג'ה סמ1
3000 ,ירושלים ,3351 ,סירת אל חג'ה סמ2
3000 ,ירושלים ,4088 ,סית אמינה
3000 ,ירושלים ,5489 ,סכת אל-חדיד
3000 ,ירושלים ,4100 ,סלאח אל-דין
3000 ,ירושלים ,5394 ,סלאח אל-דין סמ1
3000 ,ירושלים ,5395 ,סלאח אל-דין סמ3
3000 ,ירושלים ,4363 ,סלומון אלפרד
3000 ,ירושלים ,442 ,סלומון יואל משה
3000 ,ירושלים ,5581 ,סלמאן אל פרסי
3000 ,ירושלים ,5744 ,סלמאן אל-פארסי
3000 ,ירושלים ,5745 ,סלמאן אל-פארסי סמ1
3000 ,ירושלים ,5746 ,סלמאן אל-פארסי סמ2
3000 ,ירושלים ,3095 ,סלמאן אל-פרסי סמ1
3000 ,ירושלים ,3096 ,סלמאן אל-פרסי סמ3
3000 ,ירושלים ,3097 ,סלמאן אל-פרסי סמ4
3000 ,ירושלים ,3098 ,סלמאן אל-פרסי סמ6
3000 ,ירושלים ,3099 ,סלמאן אל-פרסי סמ8
3000 ,ירושלים ,640 ,סלמן אליהו
3000 ,ירושלים ,1187 ,סלמן יעקב
3000 ,ירושלים ,824 ,סמ ברלין
3000 ,ירושלים ,687 ,סמ בתי גורל
3000 ,ירושלים ,1483 ,סמ הבאר
3000 ,ירושלים ,1482 ,סמ הגבעה
3000 ,ירושלים ,1486 ,סמ היין
3000 ,ירושלים ,1485 ,סמ היקב
3000 ,ירושלים ,1484 ,סמ העורב
3000 ,ירושלים ,5652 ,סמ צלאחיה
3000 ,ירושלים ,1654 ,סמאטס
3000 ,ירושלים ,3264 ,סמבוסקי
3000 ,ירושלים ,1052 ,סמדר
3000 ,ירושלים ,437 ,סמואל
3000 ,ירושלים ,1513 ,סמולנסקין
3000 ,ירושלים ,693 ,סמטת אוהבי ציון
3000 ,ירושלים ,1419 ,סמטת הפיקוס
3000 ,ירושלים ,280 ,סמטת נהר שלום
3000 ,ירושלים ,1788 ,סמטת ספראי
3000 ,ירושלים ,4706 ,סמיר אמיס
3000 ,ירושלים ,3335 ,סמיר אמיס סמ1
3000 ,ירושלים ,3336 ,סמיר אמיס סמ2
3000 ,ירושלים ,3337 ,סמיר אמיס סמ3
3000 ,ירושלים ,3338 ,סמיר אמיס סמ7
3000 ,ירושלים ,1349 ,סן מרטין
3000 ,ירושלים ,523 ,סן פול
3000 ,ירושלים ,3007 ,סנה משה
3000 ,ירושלים ,6502 ,סנהדריה מורחבת
3000 ,ירושלים ,4549 ,סנונית
3000 ,ירושלים ,4307 ,סנט ג'ורג'
3000 ,ירושלים ,4079 ,סנט גורגס
3000 ,ירושלים ,4058 ,סנט גיימס
3000 ,ירושלים ,4080 ,סנט הלנה
3000 ,ירושלים ,4084 ,סנט מרקס
3000 ,ירושלים ,4068 ,סנט פטר
3000 ,ירושלים ,4069 ,סנט פרנציס
3000 ,ירושלים ,815 ,סעדיה גאון
3000 ,ירושלים ,4211 ,ספח
3000 ,ירושלים ,5507 ,סראחן
3000 ,ירושלים ,5969 ,סרור
3000 ,ירושלים ,5970 ,סרור סמ1
3000 ,ירושלים ,1433 ,סרנא יחזקאל
3000 ,ירושלים ,1420 ,סתר המדרגה
3000 ,ירושלים ,5747 ,ע'סאן
3000 ,ירושלים ,4262 ,עאוגנה
3000 ,ירושלים ,4219 ,עאידה
3000 ,ירושלים ,4910 ,עבאדה בן אלסאמת
3000 ,ירושלים ,4247 ,עבאסיה
3000 ,ירושלים ,4645 ,עבד א-רחמאן דג'אני
3000 ,ירושלים ,4628 ,עבד אל חמיד שומאן
3000 ,ירושלים ,4638 ,עבד אל-עזיז
3000 ,ירושלים ,5748 ,עבד אל-עזיז סמ1
3000 ,ירושלים ,3339 ,עבדאללה בן עבאס
3000 ,ירושלים ,3340 ,עבדאללה בן עבאס סמ2
3000 ,ירושלים ,3341 ,עבדאללה בן עבאס סמ3
3000 ,ירושלים ,3342 ,עבדאללה בן עבאס סמ4
3000 ,ירושלים ,3343 ,עבדאללה בן עבאס סמ7
3000 ,ירושלים ,5972 ,עבדו
3000 ,ירושלים ,5973 ,עבדו סמ1
3000 ,ירושלים ,4234 ,עבדיה
3000 ,ירושלים ,5158 ,עבדלחמיד שומאן סמ5
3000 ,ירושלים ,5159 ,עבדלחמיד שומאן סמ7
3000 ,ירושלים ,219 ,עבודת ישראל
3000 ,ירושלים ,512 ,עדו הנביא
3000 ,ירושלים ,4831 ,עדן שמואל
3000 ,ירושלים ,1238 ,עו"ד יעקב רובין
3000 ,ירושלים ,1724 ,עובד
3000 ,ירושלים ,318 ,עובדיה
3000 ,ירושלים ,831 ,עובדיה מברטנורה
3000 ,ירושלים ,5514 ,עודה
3000 ,ירושלים ,5526 ,עוויס
3000 ,ירושלים ,3156 ,עוויס סמ2
3000 ,ירושלים ,4227 ,עוויסת
3000 ,ירושלים ,1634 ,עוזיה
3000 ,ירושלים ,4325 ,עולי הגרדום
3000 ,ירושלים ,4509 ,עולי הרגל
3000 ,ירושלים ,4175 ,עומר אבן אל-עאס
3000 ,ירושלים ,5981 ,עומר אבן אלעאס סמ1
3000 ,ירושלים ,5987 ,עומר אבן אלעאס סמ11
3000 ,ירושלים ,5982 ,עומר אבן אלעאס סמ3
3000 ,ירושלים ,5988 ,עומר אבן אלעאס סמ13
3000 ,ירושלים ,5983 ,עומר אבן אלעאס סמ4
3000 ,ירושלים ,5984 ,עומר אבן אלעאס סמ5
3000 ,ירושלים ,5989 ,עומר אבן אלעאס סמ15
3000 ,ירושלים ,5985 ,עומר אבן אלעאס סמ7
3000 ,ירושלים ,5986 ,עומר אבן אלעאס סמ9
3000 ,ירושלים ,4060 ,עומר אבן כתב
3000 ,ירושלים ,4629 ,עומר אל ח'יאם
3000 ,ירושלים ,5974 ,עומר אל-פארוק
3000 ,ירושלים ,5975 ,עומר אל-פארוק סמ2
3000 ,ירושלים ,5976 ,עומר אל-פארוק סמ3
3000 ,ירושלים ,172 ,עונג שבת
3000 ,ירושלים ,5659 ,עוקבה בן נאפע
3000 ,ירושלים ,4423 ,עות'מן בן עפאן
3000 ,ירושלים ,5360 ,עות'מן בן עפאן סמ6
3000 ,ירושלים ,4356 ,עזאר שמואל
3000 ,ירושלים ,808 ,עזה
3000 ,ירושלים ,371 ,עזר יולדות
3000 ,ירושלים ,126 ,עזרא
3000 ,ירושלים ,1138 ,עזריאל
3000 ,ירושלים ,1646 ,עזריה
3000 ,ירושלים ,425 ,עזרת ישראל
3000 ,ירושלים ,390 ,עזרת תורה
3000 ,ירושלים ,5230 ,עטון
3000 ,ירושלים ,4534 ,עטרות
3000 ,ירושלים ,4116 ,עין אום שריט
3000 ,ירושלים ,4263 ,עין אל-לוזה
3000 ,ירושלים ,3209 ,עין אל-לוזה סמ1
3000 ,ירושלים ,3210 ,עין אל-לוזה סמ3
3000 ,ירושלים ,3224 ,עין אל-לוזה סמ4
3000 ,ירושלים ,3211 ,עין אל-לוזה סמ5
3000 ,ירושלים ,3212 ,עין אל-לוזה סמ7
3000 ,ירושלים ,3213 ,עין אל-לוזה סמ9
3000 ,ירושלים ,1736 ,עין גדי
3000 ,ירושלים ,4217 ,עין חנטש
3000 ,ירושלים ,164 ,עין יעקב
3000 ,ירושלים ,1401 ,עין כרם
3000 ,ירושלים ,1717 ,עין רוגל
3000 ,ירושלים ,5980 ,עין רוגל סמ1
3000 ,ירושלים ,683 ,עינים למשפט
3000 ,ירושלים ,4121 ,עיסוויה
3000 ,ירושלים ,6875 ,עיר דוד
3000 ,ירושלים ,5131 ,עכרמה בן אבי ג'הל
3000 ,ירושלים ,5142 ,עכרמה בן ג'הל סמ1
3000 ,ירושלים ,4374 ,על"ר
3000 ,ירושלים ,4031 ,עלא דין
3000 ,ירושלים ,4196 ,עלי אבן אבו טאלב
3000 ,ירושלים ,314 ,עלי הכהן
3000 ,ירושלים ,5992 ,עלי חג'אזי
3000 ,ירושלים ,5289 ,עלי משעל
3000 ,ירושלים ,431 ,עליאש
3000 ,ירושלים ,4457 ,עלית הנוער
3000 ,ירושלים ,645 ,עלמא
3000 ,ירושלים ,898 ,עם ועולמו
3000 ,ירושלים ,311 ,עמוס
3000 ,ירושלים ,1720 ,עמינדב
3000 ,ירושלים ,4657 ,עמל
3000 ,ירושלים ,1236 ,עמנואל זיסמן
3000 ,ירושלים ,1662 ,עמנואל נח
3000 ,ירושלים ,6628 ,עמק הארזים
3000 ,ירושלים ,1487 ,עמק התימנים
3000 ,ירושלים ,1087 ,עמק ציון
3000 ,ירושלים ,1636 ,עמק רפאים
3000 ,ירושלים ,904 ,עמרם גאון
3000 ,ירושלים ,1910 ,עמשא
3000 ,ירושלים ,4633 ,ענאתה החדשה
3000 ,ירושלים ,717 ,ענתבי
3000 ,ירושלים ,661 ,ענתות
3000 ,ירושלים ,238 ,עץ הדר
3000 ,ירושלים ,617 ,עץ חיים
3000 ,ירושלים ,4296 ,עציון גבר
3000 ,ירושלים ,4676 ,עצמה
3000 ,ירושלים ,4048 ,עק אבו מדין
3000 ,ירושלים ,4025 ,עק אל אסאלה
3000 ,ירושלים ,4043 ,עק אל חלידיה
3000 ,ירושלים ,4045 ,עק דאר בריק
3000 ,ירושלים ,4078 ,עק חן א עקבת
3000 ,ירושלים ,4021 ,עק שיח ריחאן
3000 ,ירושלים ,4075 ,עקבת א בטיח
3000 ,ירושלים ,4070 ,עקבת א חנקה
3000 ,ירושלים ,4016 ,עקבת א מופתי
3000 ,ירושלים ,4117 ,עקבת א נאצר
3000 ,ירושלים ,4040 ,עקבת א סראיה
3000 ,ירושלים ,4047 ,עקבת א תות
3000 ,ירושלים ,5490 ,עקבת אל-ג'בל
3000 ,ירושלים ,4007 ,עקבת דרוויש
3000 ,ירושלים ,4015 ,עקבת חב רומן
3000 ,ירושלים ,4143 ,עקבת סוואנה
3000 ,ירושלים ,4054 ,עקבת ע נים
3000 ,ירושלים ,4024 ,עקבת ריסאס
3000 ,ירושלים ,4019 ,עקבת שאדד
3000 ,ירושלים ,4017 ,עקבת שיח חסן
3000 ,ירושלים ,4023 ,עקבת שיח לולו
3000 ,ירושלים ,4044 ,עקבת תקיה
3000 ,ירושלים ,1442 ,עקיבא כוס בינה
3000 ,ירושלים ,4709 ,עראק אל טאירה
3000 ,ירושלים ,5408 ,עראק אל טאירה סמ2
3000 ,ירושלים ,3044 ,ערד
3000 ,ירושלים ,3257 ,ערוגות הבושם
3000 ,ירושלים ,5749 ,ערפה
3000 ,ירושלים ,5750 ,ערפה סמ3
3000 ,ירושלים ,1732 ,עשהאל
3000 ,ירושלים ,1766 ,עתניאל
3000 ,ירושלים ,4837 ,פאגלין עמיחי
3000 ,ירושלים ,250 ,פאול פרנקל
3000 ,ירושלים ,5231 ,פאטמה אל-זהרא'
3000 ,ירושלים ,4306 ,פארן
3000 ,ירושלים ,372 ,פאת השולחן
3000 ,ירושלים ,4814 ,פגיס דן
3000 ,ירושלים ,4784 ,פדרמן דוד
3000 ,ירושלים ,120 ,פולונסקי
3000 ,ירושלים ,4538 ,פולוצקי יעקב
3000 ,ירושלים ,264 ,פומבדיתא
3000 ,ירושלים ,5573 ,פונדק ההודים
3000 ,ירושלים ,5002 ,פונט ישראל
3000 ,ירושלים ,972 ,פועה
3000 ,ירושלים ,1843 ,פועלי צדק
3000 ,ירושלים ,2014 ,פורת לאה
3000 ,ירושלים ,4775 ,פזנר חיים
3000 ,ירושלים ,406 ,פטרושקה שבתאי
3000 ,ירושלים ,3043 ,פטריה
3000 ,ירושלים ,1658 ,פטרסון
3000 ,ירושלים ,3555 ,פייבל
3000 ,ירושלים ,4669 ,פיידי אל עלמי
3000 ,ירושלים ,4997 ,פיידי אל עלמי סמ1
3000 ,ירושלים ,4998 ,פיידי אל עלמי סמ3
3000 ,ירושלים ,4999 ,פיידי אל עלמי סמ5
3000 ,ירושלים ,4337 ,פיינשטיין
3000 ,ירושלים ,4286 ,פייר ואן פאסן
3000 ,ירושלים ,4610 ,פיירוטי
3000 ,ירושלים ,1416 ,פיכמן
3000 ,ירושלים ,396 ,פין גיימס
3000 ,ירושלים ,1393 ,פינלס שמואל
3000 ,ירושלים ,345 ,פינס
3000 ,ירושלים ,1501 ,פינסקר
3000 ,ירושלים ,482 ,פינת סעדיה מרציאנו
3000 ,ירושלים ,1010 ,פיק חיים
3000 ,ירושלים ,1889 ,פיקרד לאו
3000 ,ירושלים ,103 ,פישל אהרן
3000 ,ירושלים ,1396 ,פישר מוריס
3000 ,ירושלים ,1704 ,פלא יועץ
3000 ,ירושלים ,1876 ,פלג שניאור
3000 ,ירושלים ,4776 ,פלוגת הטנקים
3000 ,ירושלים ,4311 ,פלוגת הכותל
3000 ,ירושלים ,1199 ,פלומבו
3000 ,ירושלים ,1871 ,פלוסר דוד
3000 ,ירושלים ,1228 ,פלורנטין
3000 ,ירושלים ,1866 ,פלט
3000 ,ירושלים ,1892 ,פלק זאב
3000 ,ירושלים ,3214 ,פנורמה
3000 ,ירושלים ,965 ,פנים מאירות
3000 ,ירושלים ,1284 ,פנימית כרמית
3000 ,ירושלים ,985 ,פנינה
3000 ,ירושלים ,1278 ,פנמה
3000 ,ירושלים ,1426 ,פנקס
3000 ,ירושלים ,6805 ,פסגת זאב
3000 ,ירושלים ,6811 ,פסגת זאב מזרח
3000 ,ירושלים ,6810 ,פסגת זאב מערב
3000 ,ירושלים ,6812 ,פסגת זאב צפון
3000 ,ירושלים ,6798 ,פסגת רמות
3000 ,ירושלים ,1277 ,פסח חברוני
3000 ,ירושלים ,4170 ,פקוד מרכז
3000 ,ירושלים ,330 ,פקיעין
3000 ,ירושלים ,423 ,פראג יצחק
3000 ,ירושלים ,4682 ,פראוור יהושע
3000 ,ירושלים ,1023 ,פרבשטיין
3000 ,ירושלים ,3261 ,פרדס רימונים
3000 ,ירושלים ,417 ,פרומקין
3000 ,ירושלים ,1129 ,פרופ רקח
3000 ,ירושלים ,1550 ,פרופ' אבנר חי שאקי
3000 ,ירושלים ,3035 ,פרופ' אנריקה פברגט
3000 ,ירושלים ,278 ,פרופ' גרשון שקד
3000 ,ירושלים ,491 ,פרופ' זאב בן חיים
3000 ,ירושלים ,296 ,פרופ' יום טוב עסיס
3000 ,ירושלים ,281 ,פרופ' מנדי רודן
3000 ,ירושלים ,300 ,פרופ' מנשה הראל
3000 ,ירושלים ,299 ,פרופ' עזרה זהר
3000 ,ירושלים ,1194 ,פרופ' צבי נוימן
3000 ,ירושלים ,1192 ,פרופ' רענן ויץ
3000 ,ירושלים ,4466 ,פרופס
3000 ,ירושלים ,723 ,פרוש
3000 ,ירושלים ,131 ,פרחי חיים
3000 ,ירושלים ,1050 ,פרחי חן
3000 ,ירושלים ,343 ,פרי חדש
3000 ,ירושלים ,4659 ,פרי עמל
3000 ,ירושלים ,4834 ,פרידלר יואל
3000 ,ירושלים ,153 ,פרידמן
3000 ,ירושלים ,1881 ,פרימו לוי
3000 ,ירושלים ,1682 ,פריר רחה
3000 ,ירושלים ,1408 ,פרישמן דוד
3000 ,ירושלים ,4847 ,פרל וויליאם
3000 ,ירושלים ,1358 ,פרלשטיין
3000 ,ירושלים ,1153 ,פרנס
3000 ,ירושלים ,4444 ,פרנקפורטר פליקס
3000 ,ירושלים ,1829 ,פרץ
3000 ,ירושלים ,1175 ,פררה אברהם
3000 ,ירושלים ,1308 ,פת יעקב
3000 ,ירושלים ,237 ,פתוחי חותם
3000 ,ירושלים ,925 ,פתח תקוה
3000 ,ירושלים ,214 ,פתחיה
3000 ,ירושלים ,5521 ,פתיחה
3000 ,ירושלים ,3028 ,פתייה יהודה
3000 ,ירושלים ,6543 ,צ'יאונה סוגיהארה
3000 ,ירושלים ,1227 ,צ'ילה
3000 ,ירושלים ,1213 ,צ'לנוב
3000 ,ירושלים ,4621 ,צאייג מקסימום
3000 ,ירושלים ,4561 ,צאלים
3000 ,ירושלים ,5990 ,צבאח אל-מג'ד
3000 ,ירושלים ,5991 ,צבאח אל-מג'ד סמ1
3000 ,ירושלים ,4565 ,צביה ויצחק
3000 ,ירושלים ,1681 ,צדקיהו
3000 ,ירושלים ,139 ,צהרי יחיא
3000 ,ירושלים ,6253 ,צומת מגני חיים
3000 ,ירושלים ,4458 ,צונדק
3000 ,ירושלים ,665 ,צוער
3000 ,ירושלים ,1488 ,צוקי הישועה
3000 ,ירושלים ,3005 ,צוקרמן ברוך
3000 ,ירושלים ,4220 ,צור בחר
3000 ,ירושלים ,1289 ,צור יעקב
3000 ,ירושלים ,5993 ,צורי
3000 ,ירושלים ,5994 ,צורי סמ1
3000 ,ירושלים ,1348 ,צייטלין
3000 ,ירושלים ,1078 ,צמח שלמה
3000 ,ירושלים ,6787 ,צמרת אלנבי
3000 ,ירושלים ,6762 ,צפון תלפיות
3000 ,ירושלים ,1823 ,צפורה
3000 ,ירושלים ,708 ,צפורי
3000 ,ירושלים ,1384 ,צפירה ברכה
3000 ,ירושלים ,303 ,צפניה
3000 ,ירושלים ,4433 ,צפרירים
3000 ,ירושלים ,707 ,צפת
3000 ,ירושלים ,1851 ,צרויה
3000 ,ירושלים ,1795 ,צרת
3000 ,ירושלים ,1118 ,קאסוטו
3000 ,ירושלים ,4643 ,קאעב בן זוהיר
3000 ,ירושלים ,135 ,קאפח
3000 ,ירושלים ,117 ,קארו יוסף
3000 ,ירושלים ,386 ,קב ונקי
3000 ,ירושלים ,1809 ,קבוץ גלויות
3000 ,ירושלים ,1045 ,קבק
3000 ,ירושלים ,4347 ,קדושי בבל
3000 ,ירושלים ,1350 ,קדושי סטרומה
3000 ,ירושלים ,1800 ,קדושי סלוניקי
3000 ,ירושלים ,4905 ,קדושת אהרון
3000 ,ירושלים ,4467 ,קדימה
3000 ,ירושלים ,1369 ,קדיש לוז
3000 ,ירושלים ,3215 ,קדמת ציון
3000 ,ירושלים ,4964 ,קהילות יעקב
3000 ,ירושלים ,4471 ,קהילות שו"ם
3000 ,ירושלים ,4495 ,קהילת אונגוואר
3000 ,ירושלים ,943 ,קהתי פנחס
3000 ,ירושלים ,934 ,קואנקה
3000 ,ירושלים ,1364 ,קובובי
3000 ,ירושלים ,4108 ,קוביבה
3000 ,ירושלים ,4533 ,קוברסקי חיים
3000 ,ירושלים ,6723 ,קוטגים גונן ט
3000 ,ירושלים ,966 ,קוטלר
3000 ,ירושלים ,4490 ,קוטשר יחזקאל
3000 ,ירושלים ,4536 ,קויפמן יחזקאל
3000 ,ירושלים ,1085 ,קול משה
3000 ,ירושלים ,1265 ,קולומביה
3000 ,ירושלים ,217 ,קונדר
3000 ,ירושלים ,2004 ,קוסטה ריקה
3000 ,ירושלים ,1738 ,קורא הדורות
3000 ,ירושלים ,933 ,קורדובירו
3000 ,ירושלים ,1117 ,קורות העיתים
3000 ,ירושלים ,1897 ,קורן אליהו
3000 ,ירושלים ,1878 ,קורץ
3000 ,ירושלים ,1273 ,קורצאק
3000 ,ירושלים ,4499 ,קושניר ניסן
3000 ,ירושלים ,4062 ,קזה נובה
3000 ,ירושלים ,4397 ,קיס נעמי
3000 ,ירושלים ,4911 ,קיסאן
3000 ,ירושלים ,5234 ,קיסאן סמ1
3000 ,ירושלים ,5235 ,קיסאן סמ11
3000 ,ירושלים ,2538 ,קיסאן סמ3
3000 ,ירושלים ,5237 ,קיסאן סמ13
3000 ,ירושלים ,5236 ,קיסאן סמ4
3000 ,ירושלים ,5239 ,קיסאן סמ5
3000 ,ירושלים ,5678 ,קיסאן סמ6
3000 ,ירושלים ,5240 ,קיסאן סמ7
3000 ,ירושלים ,5241 ,קיסאן סמ8
3000 ,ירושלים ,5242 ,קיסאן סמ9
3000 ,ירושלים ,4229 ,קיר אל פאר
3000 ,ירושלים ,1747 ,קלוזנר יוסף
3000 ,ירושלים ,1667 ,קליין
3000 ,ירושלים ,1223 ,קליינמן
3000 ,ירושלים ,976 ,קלימקר דב
3000 ,ירושלים ,2001 ,קלנר אריה
3000 ,ירושלים ,1336 ,קלעי חנוך
3000 ,ירושלים ,4601 ,קלרמון גנו
3000 ,ירושלים ,4598 ,קמזון יעקב דוד
3000 ,ירושלים ,1365 ,קמחי דב
3000 ,ירושלים ,6654 ,קמפוס גבעת רם
3000 ,ירושלים ,6827 ,קמפוס הר הצופים
3000 ,ירושלים ,1309 ,קנאי הגליל
3000 ,ירושלים ,5971 ,קנבר
3000 ,ירושלים ,5965 ,קנבר סמ1
3000 ,ירושלים ,5966 ,קנבר סמ2
3000 ,ירושלים ,5967 ,קנבר סמ3
3000 ,ירושלים ,5968 ,קנבר סמ4
3000 ,ירושלים ,4046 ,קנטר חדאיר
3000 ,ירושלים ,1461 ,קניון מלחה
3000 ,ירושלים ,4713 ,קסילה
3000 ,ירושלים ,4148 ,קע אל חארה
3000 ,ירושלים ,1108 ,קפלן
3000 ,ירושלים ,4409 ,קצה אל רצול
3000 ,ירושלים ,4912 ,קצילה
3000 ,ירושלים ,4360 ,קציר אהרון
3000 ,ירושלים ,1406 ,קצנלסון
3000 ,ירושלים ,2005 ,קראוס גרטרוד
3000 ,ירושלים ,5751 ,קרטבה
3000 ,ירושלים ,5752 ,קרטבה סמ1
3000 ,ירושלים ,514 ,קריב יצחק
3000 ,ירושלים ,6520 ,קרית אגי
3000 ,ירושלים ,6613 ,קרית איתרי
3000 ,ירושלים ,6503 ,קרית אריה
3000 ,ירושלים ,6647 ,קרית בן גוריון
3000 ,ירושלים ,6614 ,קרית בעלזא
3000 ,ירושלים ,6661 ,קרית האוניברסיטה
3000 ,ירושלים ,6686 ,קרית הדסה
3000 ,ירושלים ,6698 ,קרית היובל
3000 ,ירושלים ,6782 ,קרית המוסכים
3000 ,ירושלים ,4623 ,קרית הממשלה ש גרח
3000 ,ירושלים ,6560 ,קרית וולפסון
3000 ,ירושלים ,6623 ,קרית ויזניץ
3000 ,ירושלים ,6830 ,קרית מדע
3000 ,ירושלים ,6768 ,קרית מוריה
3000 ,ירושלים ,6605 ,קרית מטרסדורף
3000 ,ירושלים ,6704 ,קרית מנחם
3000 ,ירושלים ,1004 ,קרית משה
3000 ,ירושלים ,600 ,קרית עמנואל מורנו
3000 ,ירושלים ,6814 ,קרית קמניץ
3000 ,ירושלים ,1739 ,קרליבך
3000 ,ירושלים ,1211 ,קרמינצקי
3000 ,ירושלים ,1508 ,קרן היסוד
3000 ,ירושלים ,4472 ,קרני יהודה
3000 ,ירושלים ,4684 ,קרניבד רפאל
3000 ,ירושלים ,4059 ,קרקי
3000 ,ירושלים ,4330 ,קשאני אליעזר
3000 ,ירושלים ,488 ,רא"ל משה לוי
3000 ,ירושלים ,5584 ,ראבעה אל עדוויה
3000 ,ירושלים ,3228 ,ראבעה אל עדוויה סמ8
3000 ,ירושלים ,3100 ,ראבעה אל-עדוויה סמ2
3000 ,ירושלים ,3101 ,ראבעה אל-עדוויה סמ4
3000 ,ירושלים ,3102 ,ראבעה אל-עדוויה סמ6
3000 ,ירושלים ,3103 ,ראבעה אל-עדוויה סמ7
3000 ,ירושלים ,1777 ,ראובן
3000 ,ירושלים ,1242 ,ראובן מנשה רביע
3000 ,ירושלים ,1097 ,ראט משולם
3000 ,ירושלים ,227 ,ראם אליהו
3000 ,ירושלים ,4261 ,ראס אל בושתן
3000 ,ירושלים ,4913 ,ראס אל ג'אמע
3000 ,ירושלים ,5412 ,ראס אל ג'אמע סמ3
3000 ,ירושלים ,4127 ,ראס אל גאמע
3000 ,ירושלים ,5662 ,ראס אל טוויל
3000 ,ירושלים ,5094 ,ראס אל טוויל סמ5
3000 ,ירושלים ,4390 ,ראס אל טלעה
3000 ,ירושלים ,1854 ,ראס אל נתר
3000 ,ירושלים ,4254 ,ראס אל עמוד
3000 ,ירושלים ,4239 ,ראס אל עקבה
3000 ,ירושלים ,5647 ,ראס אל-ג'בל
3000 ,ירושלים ,5648 ,ראס אל-טלעה
3000 ,ירושלים ,4624 ,ראס חמיס
3000 ,ירושלים ,4257 ,ראס כובסא
3000 ,ירושלים ,3216 ,ראס כובסא סמ2
3000 ,ירושלים ,4714 ,ראס סאלם
3000 ,ירושלים ,5413 ,ראס סאלם סמ1
3000 ,ירושלים ,5414 ,ראס סאלם סמ3
3000 ,ירושלים ,5415 ,ראס סאלם סמ4
3000 ,ירושלים ,5416 ,ראס סאלם סמ5
3000 ,ירושלים ,5417 ,ראס סאלם סמ7
3000 ,ירושלים ,4723 ,ראס שחאדה
3000 ,ירושלים ,4429 ,ראע'ב נשאשיבי
3000 ,ירושלים ,5084 ,ראע'ב נשאשיבי סמ2
3000 ,ירושלים ,5102 ,ראע'ב נשאשיבי סמ4
3000 ,ירושלים ,6809 ,ראש אמיר
3000 ,ירושלים ,963 ,ראש פנה
3000 ,ירושלים ,962 ,ראשון לציון
3000 ,ירושלים ,367 ,ראשית חכמה
3000 ,ירושלים ,4772 ,רב אלוף לסקוב
3000 ,ירושלים ,1398 ,רב אשי
3000 ,ירושלים ,990 ,רב האי גאון
3000 ,ירושלים ,5401 ,רב החובל
3000 ,ירושלים ,247 ,רב עבוד חיים שאול
3000 ,ירושלים ,1014 ,רב צעיר
3000 ,ירושלים ,5759 ,רבאיעה סמ10
3000 ,ירושלים ,5754 ,רבאיעה סמ2
3000 ,ירושלים ,5760 ,רבאיעה סמ12
3000 ,ירושלים ,5755 ,רבאיעה סמ3
3000 ,ירושלים ,5756 ,רבאיעה סמ4
3000 ,ירושלים ,5757 ,רבאיעה סמ6
3000 ,ירושלים ,5758 ,רבאיעה סמ8
3000 ,ירושלים ,1743 ,רבדים
3000 ,ירושלים ,207 ,רבי אברהם נאה
3000 ,ירושלים ,684 ,רבי אריה
3000 ,ירושלים ,1018 ,רבי בנימין
3000 ,ירושלים ,1325 ,רבי זעירא
3000 ,ירושלים ,1333 ,רבי חייא
3000 ,ירושלים ,1330 ,רבי חלפתא
3000 ,ירושלים ,1387 ,רבי חנינא
3000 ,ירושלים ,1372 ,רבי טרפון
3000 ,ירושלים ,1327 ,רבי מאיר
3000 ,ירושלים ,199 ,רבי נחמן מברסלב
3000 ,ירושלים ,445 ,רבי עקיבא
3000 ,ירושלים ,1360 ,רבי צדוק
3000 ,ירושלים ,542 ,רבי רפאל אנקווה
3000 ,ירושלים ,201 ,רבי שלמה
3000 ,ירושלים ,1386 ,רבי תנחומא
3000 ,ירושלים ,4434 ,רביבים
3000 ,ירושלים ,1219 ,רבינוביץ אהרון
3000 ,ירושלים ,744 ,רבן זאב
3000 ,ירושלים ,121 ,רבנו גרשום
3000 ,ירושלים ,1856 ,רבנו פוליטי
3000 ,ירושלים ,353 ,רבנו תם
3000 ,ירושלים ,1786 ,רבקה
3000 ,ירושלים ,814 ,רד"ק
3000 ,ירושלים ,4349 ,רובוביץ אלכסנדר
3000 ,ירושלים ,1264 ,רובינשטיין
3000 ,ירושלים ,4421 ,רובע מוסלמי
3000 ,ירושלים ,1666 ,רוזאניס
3000 ,ירושלים ,1400 ,רוזן פנחס
3000 ,ירושלים ,4496 ,רוזנבלט
3000 ,ירושלים ,240 ,רוזנהים יעקב
3000 ,ירושלים ,1375 ,רוזנפלד
3000 ,ירושלים ,829 ,רוטנברג
3000 ,ירושלים ,1114 ,רוטשילד
3000 ,ירושלים ,942 ,רוממה
3000 ,ירושלים ,6615 ,רוממה עילית
3000 ,ירושלים ,4171 ,רומני
3000 ,ירושלים ,528 ,רועה צאן
3000 ,ירושלים ,156 ,רוקח שמעון
3000 ,ירושלים ,1649 ,רות
3000 ,ירושלים ,5601 ,רז יעקב
3000 ,ירושלים ,4335 ,רזיאל דוד
3000 ,ירושלים ,1943 ,רזיאל נאור אסתר
3000 ,ירושלים ,4871 ,רח 8070
3000 ,ירושלים ,4870 ,רח 8080
3000 ,ירושלים ,6565 ,רחביה
3000 ,ירושלים ,388 ,רחבת הטירונים
3000 ,ירושלים ,746 ,רחבת חביב שמעוני
3000 ,ירושלים ,480 ,רחבת חיים שלום הלוי
3000 ,ירושלים ,4371 ,רחבת יהודה חסיד
3000 ,ירושלים ,6048 ,רחבת רבינוביץ
3000 ,ירושלים ,125 ,רחובות הבוכרים
3000 ,ירושלים ,1621 ,רחל אמנו
3000 ,ירושלים ,4797 ,רחמילביץ משה
3000 ,ירושלים ,469 ,ריבלין אליעזר
3000 ,ירושלים ,441 ,ריבלין יוסף
3000 ,ירושלים ,152 ,רייכמן
3000 ,ירושלים ,838 ,רייק חביבה
3000 ,ירושלים ,1290 ,רינגלבלום
3000 ,ירושלים ,1324 ,ריש לקיש
3000 ,ירושלים ,6864 ,רכס לבן
3000 ,ירושלים ,362 ,רלב"ח
3000 ,ירושלים ,809 ,רמב"ן
3000 ,ירושלים ,5402 ,רמבה אייזיק
3000 ,ירושלים ,4914 ,רמדאן
3000 ,ירושלים ,5099 ,רמדאן סמ1
3000 ,ירושלים ,660 ,רמה
3000 ,ירושלים ,1131 ,רמון יוסף צבי
3000 ,ירושלים ,6792 ,רמות אלון
3000 ,ירושלים ,6795 ,רמות ב
3000 ,ירושלים ,6796 ,רמות ג
3000 ,ירושלים ,6794 ,רמות ד
3000 ,ירושלים ,6793 ,רמות פולין
3000 ,ירושלים ,6770 ,רמות רחל
3000 ,ירושלים ,1868 ,רמז דוד
3000 ,ירושלים ,6799 ,רמת איילות
3000 ,ירושלים ,6652 ,רמת בית הכרם
3000 ,ירושלים ,4280 ,רמת הגולן
3000 ,ירושלים ,974 ,רמת מוצא
3000 ,ירושלים ,6800 ,רמת שלמה
3000 ,ירושלים ,6656 ,רמת שרת
3000 ,ירושלים ,1873 ,רפאל יצחק
3000 ,ירושלים ,677 ,רפאל עזרא
3000 ,ירושלים ,705 ,רפאלי
3000 ,ירושלים ,160 ,רפפורט
3000 ,ירושלים ,133 ,רצאבי
3000 ,ירושלים ,3041 ,רקם
3000 ,ירושלים ,5011 ,רקנטי אברהם
3000 ,ירושלים ,328 ,רש"י
3000 ,ירושלים ,802 ,רשב"א
3000 ,ירושלים ,1315 ,רשב"ג
3000 ,ירושלים ,334 ,רשב"ם
3000 ,ירושלים ,6609 ,ש אוריאל
3000 ,ירושלים ,6690 ,ש אזב ק יובל
3000 ,ירושלים ,6780 ,ש אזב תלפיות
3000 ,ירושלים ,6537 ,ש אחווה
3000 ,ירושלים ,6691 ,ש אלחנני
3000 ,ירושלים ,6651 ,ש אלף גב רם
3000 ,ירושלים ,6616 ,ש ברוצקוס
3000 ,ירושלים ,6671 ,ש בתי אבן
3000 ,ירושלים ,6769 ,ש דיסקין
3000 ,ירושלים ,6617 ,ש דיסקין רוממה
3000 ,ירושלים ,6752 ,ש הגנה טלביה
3000 ,ירושלים ,6642 ,ש הריאל
3000 ,ירושלים ,6643 ,ש ותיק ק משה
3000 ,ירושלים ,6692 ,ש ותיקים ק יובל
3000 ,ירושלים ,6693 ,ש זוג ק יובל
3000 ,ירושלים ,6781 ,ש זוג תלפיות
3000 ,ירושלים ,6644 ,ש ח משוחררים
3000 ,ירושלים ,6590 ,ש חבד
3000 ,ירושלים ,6593 ,ש חסדוף
3000 ,ירושלים ,6658 ,ש חסכון בית וגן
3000 ,ירושלים ,6735 ,ש חסכון פלמח
3000 ,ירושלים ,6524 ,ש יוספטל
3000 ,ירושלים ,6696 ,ש מומחים ק יובל
3000 ,ירושלים ,6880 ,ש מורים בית הכרם
3000 ,ירושלים ,6533 ,ש מורשה
3000 ,ירושלים ,6606 ,ש מי נפתוח
3000 ,ירושלים ,6881 ,ש מימון
3000 ,ירושלים ,6697 ,ש מפונים ק יובל
3000 ,ירושלים ,6526 ,ש מפונים ש הנביא
3000 ,ירושלים ,6736 ,ש משרד החוץ
3000 ,ירושלים ,6626 ,ש נוה הבירה
3000 ,ירושלים ,6882 ,ש נוה הקריה
3000 ,ירושלים ,6806 ,ש נוה יעקב
3000 ,ירושלים ,6660 ,ש נוה עוזר
3000 ,ירושלים ,6648 ,ש נוה שאנן
3000 ,ירושלים ,6653 ,ש סטודנט גבעת רם
3000 ,ירושלים ,6771 ,ש סטודנטים אלנבי
3000 ,ירושלים ,6672 ,ש סטודנטים ק יובל
3000 ,ירושלים ,6772 ,ש סטון גונן
3000 ,ירושלים ,6673 ,ש סלע ק יובל
3000 ,ירושלים ,6724 ,ש סן סימון
3000 ,ירושלים ,6674 ,ש עובד ק יבל
3000 ,ירושלים ,6884 ,ש עובדי המכס
3000 ,ירושלים ,6737 ,ש עובדי מדינה
3000 ,ירושלים ,6619 ,ש עובדים רוממה
3000 ,ירושלים ,6783 ,ש עובדים תלפיות
3000 ,ירושלים ,6890 ,ש עולים רוממה
3000 ,ירושלים ,6891 ,ש עמידר רוממה
3000 ,ירושלים ,6675 ,ש עממי אורגו
3000 ,ירושלים ,6885 ,ש עממי ב כרם
3000 ,ירושלים ,6676 ,ש עממי גואטמלה
3000 ,ירושלים ,6785 ,ש עממי חברון
3000 ,ירושלים ,6677 ,ש עממי קרית יובל
3000 ,ירושלים ,6786 ,ש עממי תלפיות
3000 ,ירושלים ,6659 ,ש פאגי בית וגן
3000 ,ירושלים ,6525 ,ש פאגי סנהדריה
3000 ,ירושלים ,6625 ,ש פגי ג שאול
3000 ,ירושלים ,6574 ,ש פומז בצלאל
3000 ,ירושלים ,6725 ,ש פת
3000 ,ירושלים ,6763 ,ש צבא קבע אלנבי
3000 ,ירושלים ,6822 ,ש צמרת הבירה
3000 ,ירושלים ,6730 ,ש קואפרטיבים
3000 ,ירושלים ,6850 ,ש קוטג גילה
3000 ,ירושלים ,6751 ,ש קוממיות
3000 ,ירושלים ,6607 ,ש קרית צאנז
3000 ,ירושלים ,6608 ,ש רבנים
3000 ,ירושלים ,6504 ,ש רמת אשכול
3000 ,ירושלים ,6657 ,ש רמת דניה
3000 ,ירושלים ,6620 ,ש רמת מוצא
3000 ,ירושלים ,6740 ,ש רסקו
3000 ,ירושלים ,6700 ,ש רסקו קרית מנחם
3000 ,ירושלים ,6521 ,ש שמואל הנביא
3000 ,ירושלים ,6637 ,ש שערי אפרים
3000 ,ירושלים ,3012 ,שאדיקר נחום
3000 ,ירושלים ,106 ,שאול המלך
3000 ,ירושלים ,243 ,שאול ליברמן
3000 ,ירושלים ,4637 ,שאקר באדר
3000 ,ירושלים ,4588 ,שאר ישוב
3000 ,ירושלים ,4568 ,שבו
3000 ,ירושלים ,4517 ,שבות
3000 ,ירושלים ,709 ,שבזי
3000 ,ירושלים ,501 ,שבטי ישראל
3000 ,ירושלים ,1813 ,שביבי אש
3000 ,ירושלים ,1862 ,שביל האריה
3000 ,ירושלים ,1490 ,שביל החומה
3000 ,ירושלים ,5574 ,שביל החסידות
3000 ,ירושלים ,1861 ,שביל הטובה
3000 ,ירושלים ,1539 ,שביל היוגב
3000 ,ירושלים ,1491 ,שביל הצופית
3000 ,ירושלים ,1489 ,שביל הצוקים
3000 ,ירושלים ,1492 ,שביל השיבולים
3000 ,ירושלים ,961 ,שבע קהלות
3000 ,ירושלים ,632 ,שבת צדק
3000 ,ירושלים ,4741 ,שג'רת אל דור
3000 ,ירושלים ,941 ,שד בן גוריון
3000 ,ירושלים ,807 ,שד בן מימון
3000 ,ירושלים ,733 ,שד בן צבי יצחק
3000 ,ירושלים ,2052 ,שד גבעת משה
3000 ,ירושלים ,4452 ,שד גולדה מאיר
3000 ,ירושלים ,1435 ,שד דב יוסף
3000 ,ירושלים ,4753 ,שד דיין משה
3000 ,ירושלים ,1183 ,שד האומה
3000 ,ירושלים ,4393 ,שד האוניברסיטה
3000 ,ירושלים ,998 ,שד הזז חיים
3000 ,ירושלים ,1020 ,שד המאירי
3000 ,ירושלים ,1021 ,שד הרצל
3000 ,ירושלים ,1077 ,שד ויצמן
3000 ,ירושלים ,4276 ,שד לוי אשכול
3000 ,ירושלים ,1886 ,שד מאיר שמואל
3000 ,ירושלים ,1145 ,שד מגנס
3000 ,ירושלים ,3001 ,שד נוה יעקב
3000 ,ירושלים ,4752 ,שד סיירת דוכיפת
3000 ,ירושלים ,659 ,שד עין צורים
3000 ,ירושלים ,4378 ,שד פניגר יצחק
3000 ,ירושלים ,4340 ,שד צ'רצ'יל
3000 ,ירושלים ,1880 ,שד רותי ברעם
3000 ,ירושלים ,1389 ,שד ש"י עגנון
3000 ,ירושלים ,970 ,שד שז"ר
3000 ,ירושלים ,4392 ,שד שיירת הר הצופים
3000 ,ירושלים ,1368 ,שד שרת משה
3000 ,ירושלים ,604 ,שד"ל
3000 ,ירושלים ,4915 ,שדאד בן אוס
3000 ,ירושלים ,5132 ,שדאד בן אוס סמ1
3000 ,ירושלים ,361 ,שדה חמד
3000 ,ירושלים ,1305 ,שדה יצחק
3000 ,ירושלים ,1755 ,שדמי נחום
3000 ,ירושלים ,926 ,שדרות אריאל טמס
3000 ,ירושלים ,1852 ,שדרת בלומפילד
3000 ,ירושלים ,1134 ,שדרת המוזיאונים
3000 ,ירושלים ,4481 ,שדרת רמות
3000 ,ירושלים ,5101 ,שוהם
3000 ,ירושלים ,4821 ,שוורצברד שלום
3000 ,ירושלים ,4840 ,שוחט מניה
3000 ,ירושלים ,1019 ,שוכמן ישראל
3000 ,ירושלים ,355 ,שולאל יצחק
3000 ,ירושלים ,682 ,שומרון
3000 ,ירושלים ,209 ,שומרי אמונים
3000 ,ירושלים ,3554 ,שומרי ההר
3000 ,ירושלים ,4513 ,שוני הלכות
3000 ,ירושלים ,4101 ,שועפאט
3000 ,ירושלים ,1626 ,שופן
3000 ,ירושלים ,4081 ,שוק הצבעים
3000 ,ירושלים ,4038 ,שוק הצורפים
3000 ,ירושלים ,4194 ,שוקן זלמן
3000 ,ירושלים ,4460 ,שור משה
3000 ,ירושלים ,608 ,שורץ
3000 ,ירושלים ,410 ,שושן
3000 ,ירושלים ,1009 ,שושנה
3000 ,ירושלים ,4309 ,שושנים
3000 ,ירושלים ,1232 ,שותא רוסתאבלי
3000 ,ירושלים ,1716 ,שז"כ
3000 ,ירושלים ,1402 ,שח"ל
3000 ,ירושלים ,453 ,שחם מאיר
3000 ,ירושלים ,1039 ,שחר
3000 ,ירושלים ,1096 ,שחראי
3000 ,ירושלים ,1136 ,שטיינברג יחיאל
3000 ,ירושלים ,894 ,שטנר
3000 ,ירושלים ,350 ,שטראוס
3000 ,ירושלים ,5475 ,שטרוק
3000 ,ירושלים ,1297 ,שטרן אברהם
3000 ,ירושלים ,1820 ,שטרן יורי
3000 ,ירושלים ,4462 ,שי
3000 ,ירושלים ,4431 ,שיבת ציון
3000 ,ירושלים ,4246 ,שיח אחמד א ס
3000 ,ירושלים ,4197 ,שיח מוחמד א-צאלח
3000 ,ירושלים ,4238 ,שיח סואן
3000 ,ירושלים ,4161 ,שיח סעדוסעיד
3000 ,ירושלים ,4603 ,שייח אל כאלדי
3000 ,ירושלים ,5649 ,שייח' ג'אבר
3000 ,ירושלים ,4118 ,שייח' ג'ראח
3000 ,ירושלים ,4295 ,שייח' מחמוד
3000 ,ירושלים ,283 ,שיירת עטרות
3000 ,ירושלים ,657 ,שילה
3000 ,ירושלים ,1026 ,שילר
3000 ,ירושלים ,4614 ,שיק קונרד
3000 ,ירושלים ,3256 ,שיר למעלות
3000 ,ירושלים ,1498 ,שיר לשלמה
3000 ,ירושלים ,741 ,שיריזלי
3000 ,ירושלים ,1944 ,שירת אהרן
3000 ,ירושלים ,4435 ,שירת הים
3000 ,ירושלים ,271 ,שירת חנה
3000 ,ירושלים ,6764 ,שכ א טור 19
3000 ,ירושלים ,6765 ,שכ אבו תור גוש 18
3000 ,ירושלים ,6585 ,שכ אוהל משה
3000 ,ירושלים ,6564 ,שכ אוהל שלמה
3000 ,ירושלים ,6509 ,שכ ארזי הבירה
3000 ,ירושלים ,6777 ,שכ ארנונה
3000 ,ירושלים ,6902 ,שכ בטאנה
3000 ,ירושלים ,6529 ,שכ בית הכרם
3000 ,ירושלים ,6666 ,שכ בית וגן
3000 ,ירושלים ,6507 ,שכ בית ישראל
3000 ,ירושלים ,1798 ,שכ בית צפפה
3000 ,ירושלים ,6773 ,שכ בקעה
3000 ,ירושלים ,6586 ,שכ בתי ברוידא
3000 ,ירושלים ,6505 ,שכ גאולה
3000 ,ירושלים ,6766 ,שכ גאולים
3000 ,ירושלים ,6733 ,שכ גב מרדכי
3000 ,ירושלים ,6621 ,שכ גב שאול א
3000 ,ירושלים ,6622 ,שכ גב שאול ב
3000 ,ירושלים ,6510 ,שכ גבעת הורדים
3000 ,ירושלים ,6649 ,שכ גבעת רם
3000 ,ירושלים ,6624 ,שכ גבעת שאול
3000 ,ירושלים ,6514 ,שכ הבוכרים
3000 ,ירושלים ,6712 ,שכ הגיחון
3000 ,ירושלים ,6534 ,שכ המושבה הגרמנית
3000 ,ירושלים ,6741 ,שכ המושבה היונית
3000 ,ירושלים ,6638 ,שכ המקשר א
3000 ,ירושלים ,6650 ,שכ המקשר ב
3000 ,ירושלים ,6639 ,שכ המקשר ג
3000 ,ירושלים ,6528 ,שכ הפועלים
3000 ,ירושלים ,6640 ,שכ הר נוף
3000 ,ירושלים ,6551 ,שכ וולג'ה
3000 ,ירושלים ,6583 ,שכ זכרון טוביה
3000 ,ירושלים ,6572 ,שכ זכרון יוסף
3000 ,ירושלים ,6591 ,שכ זכרון משה
3000 ,ירושלים ,6552 ,שכ ח'רבת עטארה
3000 ,ירושלים ,6903 ,שכ חבאיל חמדן
3000 ,ירושלים ,6816 ,שכ יגיע כפיים
3000 ,ירושלים ,6767 ,שכ ימין משה
3000 ,ירושלים ,6636 ,שכ יפה נוף
3000 ,ירושלים ,6573 ,שכ כנסת א
3000 ,ירושלים ,6575 ,שכ כנסת ב
3000 ,ירושלים ,6576 ,שכ כנסת ג
3000 ,ירושלים ,4111 ,שכ כפר עקב
3000 ,ירושלים ,6818 ,שכ כפר שאול
3000 ,ירושלים ,6592 ,שכ כרם
3000 ,ירושלים ,6527 ,שכ כרם אברהם
3000 ,ירושלים ,6892 ,שכ ליפתא תחתית
3000 ,ירושלים ,6506 ,שכ מאה שערים
3000 ,ירושלים ,6584 ,שכ מזכרת משה
3000 ,ירושלים ,6904 ,שכ מחג'רת עתמה
3000 ,ירושלים ,6550 ,שכ מחנה ישראל
3000 ,ירושלים ,6512 ,שכ מחניים
3000 ,ירושלים ,6701 ,שכ מנחת
3000 ,ירושלים ,6540 ,שכ מסעודיה
3000 ,ירושלים ,6705 ,שכ מצפה מנחם
3000 ,ירושלים ,6532 ,שכ מקור ברוך
3000 ,ירושלים ,6778 ,שכ מקור חיים
3000 ,ירושלים ,6840 ,שכ נוף ציון
3000 ,ירושלים ,6508 ,שכ נחלאות
3000 ,ירושלים ,6579 ,שכ נחלת אחים
3000 ,ירושלים ,6569 ,שכ נחלת ישראל
3000 ,ירושלים ,6566 ,שכ נחלת צדוק
3000 ,ירושלים ,6567 ,שכ נחלת ציון
3000 ,ירושלים ,6531 ,שכ נחלת שבעה
3000 ,ירושלים ,6578 ,שכ נחלת שמעון
3000 ,ירושלים ,6522 ,שכ סנהדריה
3000 ,ירושלים ,6513 ,שכ עזרת תורה
3000 ,ירושלים ,6685 ,שכ עין כרם
3000 ,ירושלים ,6687 ,שכ עין כרם א
3000 ,ירושלים ,6688 ,שכ עין כרם ב
3000 ,ירושלים ,6689 ,שכ עין כרם ד
3000 ,ירושלים ,6670 ,שכ עיר גנים א
3000 ,ירושלים ,6702 ,שכ עיר גנים ב
3000 ,ירושלים ,6703 ,שכ עיר גנים ג
3000 ,ירושלים ,6535 ,שכ עמק רפאים
3000 ,ירושלים ,6610 ,שכ עץ חיים
3000 ,ירושלים ,6906 ,שכ עקבת סוואנה
3000 ,ירושלים ,6611 ,שכ קומונה
3000 ,ירושלים ,6541 ,שכ קטמון הישנה
3000 ,ירושלים ,6641 ,שכ קרית משה
3000 ,ירושלים ,6530 ,שכ קרית שמואל
3000 ,ירושלים ,6630 ,שכ רוחמה
3000 ,ירושלים ,6612 ,שכ רוממה
3000 ,ירושלים ,6863 ,שכ רמת הדסה
3000 ,ירושלים ,6568 ,שכ שבת צדק
3000 ,ירושלים ,4172 ,שכ שדה תעופה עטרות
3000 ,ירושלים ,6893 ,שכ שיך באדר
3000 ,ירושלים ,6774 ,שכ שמעה
3000 ,ירושלים ,6511 ,שכ שערי חסד
3000 ,ירושלים ,6577 ,שכ שערי ים
3000 ,ירושלים ,6538 ,שכ שערי פינה
3000 ,ירושלים ,6523 ,שכ תל ארזה
3000 ,ירושלים ,6779 ,שכ תלפיות
3000 ,ירושלים ,6853 ,שכון גילה
3000 ,ירושלים ,6900 ,שכוני נוסייבה
3000 ,ירושלים ,4725 ,שכונת השלום
3000 ,ירושלים ,3368 ,שכונת התימנים
3000 ,ירושלים ,4449 ,שכטמן יוסף
3000 ,ירושלים ,1034 ,שלאין
3000 ,ירושלים ,2015 ,שלו יצחק
3000 ,ירושלים ,1872 ,שלום הגליל
3000 ,ירושלים ,4830 ,שלום וצדק
3000 ,ירושלים ,1791 ,שלום יהודה
3000 ,ירושלים ,1509 ,שלום עליכם
3000 ,ירושלים ,450 ,שלומציון
3000 ,ירושלים ,1532 ,שלוש
3000 ,ירושלים ,1075 ,שלם נתן
3000 ,ירושלים ,4810 ,שלמה
3000 ,ירושלים ,1441 ,שלמה בן פתחיה הלוי
3000 ,ירושלים ,513 ,שלמה המלך
3000 ,ירושלים ,436 ,שמאי
3000 ,ירושלים ,515 ,שמאע אליהו
3000 ,ירושלים ,907 ,שמגר
3000 ,ירושלים ,1548 ,שמואל אבן אור
3000 ,ירושלים ,116 ,שמואל הנביא
3000 ,ירושלים ,427 ,שמואל הנגיד
3000 ,ירושלים ,1262 ,שמורק
3000 ,ירושלים ,1807 ,שמחת כהן
3000 ,ירושלים ,1119 ,שמי יצחק
3000 ,ירושלים ,4580 ,שמיר
3000 ,ירושלים ,4000 ,שמיר משה
3000 ,ירושלים ,4098 ,שמס א דין אסיוטי
3000 ,ירושלים ,5361 ,שמס אדין אסיוטי סמ1
3000 ,ירושלים ,5362 ,שמס אדין אסיוטי סמ3
3000 ,ירושלים ,1773 ,שמעון
3000 ,ירושלים ,407 ,שמעון בן שטח
3000 ,ירושלים ,342 ,שמעון המכבי
3000 ,ירושלים ,235 ,שמעון הצדיק
3000 ,ירושלים ,494 ,שמעון מישורי
3000 ,ירושלים ,1407 ,שמעוני
3000 ,ירושלים ,1761 ,שמעיה
3000 ,ירושלים ,215 ,שמריה
3000 ,ירושלים ,1763 ,שמשון
3000 ,ירושלים ,1146 ,שמשון רפאל הירש
3000 ,ירושלים ,4500 ,שנברג יוסף
3000 ,ירושלים ,4379 ,שני יוסף
3000 ,ירושלים ,1127 ,שניאור
3000 ,ירושלים ,999 ,שנירר שרה
3000 ,ירושלים ,1677 ,שסקין יעקב
3000 ,ירושלים ,5060 ,שעאבאן
3000 ,ירושלים ,4863 ,שעאבאן סמ3
3000 ,ירושלים ,5061 ,שעאבאן סמ4
3000 ,ירושלים ,5582 ,שער האריות
3000 ,ירושלים ,4029 ,שער הע'ואנמה
3000 ,ירושלים ,3201 ,שער ציון
3000 ,ירושלים ,4159 ,שער שכם
3000 ,ירושלים ,722 ,שערי חסד
3000 ,ירושלים ,899 ,שערי ירושלים
3000 ,ירושלים ,185 ,שערי משה
3000 ,ירושלים ,151 ,שערי פנה
3000 ,ירושלים ,603 ,שערי צדק
3000 ,ירושלים ,161 ,שערי שמיים
3000 ,ירושלים ,1901 ,שערי תורה
3000 ,ירושלים ,252 ,שפיצר
3000 ,ירושלים ,1212 ,שפירא צבי
3000 ,ירושלים ,398 ,שפע חיים
3000 ,ירושלים ,936 ,שפרה
3000 ,ירושלים ,706 ,שפרעם
3000 ,ירושלים ,607 ,שפת אמת
3000 ,ירושלים ,446 ,שץ
3000 ,ירושלים ,5679 ,שקאאיק אל-נעמאן
3000 ,ירושלים ,5680 ,שקאאיק אל-נעמאן סמ1
3000 ,ירושלים ,5681 ,שקאאיק אל-נעמאן סמ3
3000 ,ירושלים ,5682 ,שקאאיק אל-נעמאן סמ5
3000 ,ירושלים ,1399 ,שקי אינו
3000 ,ירושלים ,5964 ,שקיראת
3000 ,ירושלים ,4972 ,שרבני יהושע
3000 ,ירושלים ,4287 ,שרגאי זלמן
3000 ,ירושלים ,1546 ,שרה הקטנה (לבני)
3000 ,ירושלים ,230 ,שרה לוי תנאי
3000 ,ירושלים ,483 ,שרה עזריהו
3000 ,ירושלים ,298 ,שרה קנוט
3000 ,ירושלים ,1263 ,שרון
3000 ,ירושלים ,340 ,שרי ישראל
3000 ,ירושלים ,5477 ,שרי ראובן
3000 ,ירושלים ,816 ,שריון יצחק
3000 ,ירושלים ,1184 ,שרייבום יעקב
3000 ,ירושלים ,364 ,שרעבי שלום
3000 ,ירושלים ,4807 ,שרף זאב
3000 ,ירושלים ,4202 ,שרפאת
3000 ,ירושלים ,5663 ,שרפאת סמ3
3000 ,ירושלים ,5664 ,שרפאת סמ6
3000 ,ירושלים ,5665 ,שרפאת סמ8
3000 ,ירושלים ,4205 ,שרקיה אל בלד
3000 ,ירושלים ,4283 ,ששת הימים
3000 ,ירושלים ,4827 ,תבין אלי
3000 ,ירושלים ,4823 ,תג'ר שלמה ומשה
3000 ,ירושלים ,4350 ,תדהר
3000 ,ירושלים ,908 ,תובל
3000 ,ירושלים ,1201 ,תוסיה כהן
3000 ,ירושלים ,196 ,תוספות יום טוב
3000 ,ירושלים ,4656 ,תוצרת
3000 ,ירושלים ,1083 ,תורה ועבודה
3000 ,ירושלים ,921 ,תורה מציון
3000 ,ירושלים ,4809 ,תורן חיים
3000 ,ירושלים ,1531 ,תורת חיים
3000 ,ירושלים ,994 ,תורת חסד
3000 ,ירושלים ,321 ,תחכמוני
3000 ,ירושלים ,1005 ,תחנה מרכזית
3000 ,ירושלים ,206 ,תימא
3000 ,ירושלים ,4558 ,תירוש
3000 ,ירושלים ,945 ,תכלת מרדכי
3000 ,ירושלים ,6558 ,תל אל פול
3000 ,ירושלים ,1622 ,תל חי
3000 ,ירושלים ,1204 ,תלמה ילין
3000 ,ירושלים ,128 ,תלמודי אברהם
3000 ,ירושלים ,6836 ,תלפיות מזרח
3000 ,ירושלים ,3344 ,תמים אל-דארי
3000 ,ירושלים ,3345 ,תמים אל-דארי סמ3
3000 ,ירושלים ,3346 ,תמים אל-דארי סמ5
3000 ,ירושלים ,3347 ,תמים אל-דארי סמ7
3000 ,ירושלים ,3348 ,תמים אל-דארי סמ9
3000 ,ירושלים ,4785 ,תמיר שמואל
3000 ,ירושלים ,4264 ,תפארת ירושלים
3000 ,ירושלים ,4901 ,תפארת ישראל
3000 ,ירושלים ,668 ,תקוע
3000 ,ירושלים ,5761 ,תקי אל-דין
3000 ,ירושלים ,5762 ,תקי אל-דין סמ1
3000 ,ירושלים ,140 ,תרמ"ב
3000 ,ירושלים ,1053 ,תרצה
3000 ,ירושלים ,4555 ,תרשיש
3000 ,ירושלים ,732 ,תשב"ץ
718 ,ירחיב ,111 ,אנפה
718 ,ירחיב ,102 ,דוכיפת
718 ,ירחיב ,103 ,הדרור
718 ,ירחיב ,106 ,השחף
718 ,ירחיב ,101 ,זמיר
718 ,ירחיב ,109 ,חסידה
718 ,ירחיב ,9000 ,ירחיב
718 ,ירחיב ,104 ,נחליאלי
718 ,ירחיב ,110 ,סמ חופית
718 ,ירחיב ,114 ,סמ צופית
718 ,ירחיב ,105 ,סנונית
718 ,ירחיב ,107 ,עפרוני
718 ,ירחיב ,108 ,פשוש
718 ,ירחיב ,112 ,שקנאי
502 ,ירכא ,6730 ,אזור התעשייה
502 ,ירכא ,441 ,אל-תיג'ארה
502 ,ירכא ,443 ,אלאח'וה
502 ,ירכא ,107 ,אלאמל
502 ,ירכא ,135 ,אלאסיל
502 ,ירכא ,164 ,אלאשלול
502 ,ירכא ,226 ,אלבאבור
502 ,ירכא ,332 ,אלבאט
502 ,ירכא ,194 ,אלבוסתאן
502 ,ירכא ,101 ,אלביאדה
502 ,ירכא ,339 ,אלביאדר
502 ,ירכא ,155 ,אלביר אלגארבי
502 ,ירכא ,159 ,אלברקה
502 ,ירכא ,130 ,אלברקוק
502 ,ירכא ,188 ,אלג'וז
502 ,ירכא ,423 ,אלג'ורי
502 ,ירכא ,442 ,אלג'ליל
502 ,ירכא ,216 ,אלדבה
502 ,ירכא ,452 ,אלדידבה
502 ,ירכא ,112 ,אלהדאף
502 ,ירכא ,108 ,אלהלאל
502 ,ירכא ,168 ,אלהרם
502 ,ירכא ,229 ,אלודיאן
502 ,ירכא ,131 ,אלורוד
502 ,ירכא ,438 ,אלזוהור
502 ,ירכא ,111 ,אלזיתון
502 ,ירכא ,224 ,אלזקאק
502 ,ירכא ,223 ,אלח'לוה
502 ,ירכא ,169 ,אלח'רבה
502 ,ירכא ,421 ,אלח'רוב
502 ,ירכא ,154 ,אלחארה
502 ,ירכא ,160 ,אלחדב
502 ,ירכא ,215 ,אלחדיקה
502 ,ירכא ,117 ,אלחדית'ה
502 ,ירכא ,152 ,אלחמאם
502 ,ירכא ,411 ,אלחרארי
502 ,ירכא ,428 ,אלחריקה
502 ,ירכא ,6732 ,אלחשנה
502 ,ירכא ,170 ,אליאסמין
502 ,ירכא ,445 ,אלכרום
502 ,ירכא ,6711 ,אלכרם
502 ,ירכא ,156 ,אלמבסלה
502 ,ירכא ,134 ,אלמדאורה
502 ,ירכא ,225 ,אלמובארכה
502 ,ירכא ,137 ,אלמזאריב
502 ,ירכא ,383 ,אלמזארע
502 ,ירכא ,341 ,אלמעון
502 ,ירכא ,456 ,אלנאפורה
502 ,ירכא ,439 ,אלנהדה
502 ,ירכא ,136 ,אלנח'יל
502 ,ירכא ,124 ,אלנסבי
502 ,ירכא ,233 ,אלנסיג'
502 ,ירכא ,126 ,אלנרג'ס
502 ,ירכא ,114 ,אלסדאקה
502 ,ירכא ,449 ,אלסהלאת
502 ,ירכא ,220 ,אלסואדייה
502 ,ירכא ,408 ,אלסינאעה
502 ,ירכא ,455 ,אלסראיס
502 ,ירכא ,429 ,אלסרו
502 ,ירכא ,6731 ,אלסריסה
502 ,ירכא ,376 ,אלעאמר
502 ,ירכא ,448 ,אלעלום
502 ,ירכא ,208 ,אלעמארייה
502 ,ירכא ,207 ,אלענקור
502 ,ירכא ,330 ,אלעקדה
502 ,ירכא ,162 ,אלפיג'ן
502 ,ירכא ,410 ,אלקריה אלריאדיה
502 ,ירכא ,161 ,אלרביע
502 ,ירכא ,358 ,אלרהואת
502 ,ירכא ,412 ,אלרודאת
502 ,ירכא ,356 ,אלרוייס
502 ,ירכא ,157 ,אלשארע אשמאלי
502 ,ירכא ,6733 ,אלשכונאת
502 ,ירכא ,440 ,אלתאח'ים
502 ,ירכא ,396 ,ג'ורן אלסרסור
502 ,ירכא ,6735 ,ג'ורן אלצרצור
502 ,ירכא ,353 ,דרב אבו סנאן
502 ,ירכא ,203 ,דרב סאפאד
502 ,ירכא ,204 ,ואדי אום עאמר
502 ,ירכא ,437 ,ואדי סמאק
502 ,ירכא ,381 ,ח'אלית אלעדס
502 ,ירכא ,227 ,ח'אלית אלתות
502 ,ירכא ,192 ,ח'לואת אלרוג'אב
502 ,ירכא ,141 ,ח'לת אלמשמש
502 ,ירכא ,328 ,חכורת חרב
502 ,ירכא ,325 ,יוסף אלצדיק
502 ,ירכא ,9000 ,ירכא
502 ,ירכא ,182 ,כרם חנא
502 ,ירכא ,180 ,מראח זיאן
502 ,ירכא ,6720 ,מרכז הכפר
502 ,ירכא ,6734 ,משפחת קדמאני
502 ,ירכא ,447 ,נביא אלח'דר
502 ,ירכא ,335 ,סידנא אבו אסראיא
502 ,ירכא ,190 ,סנובר אלג'או
502 ,ירכא ,393 ,עריד אלשאמס
502 ,ירכא ,179 ,ראס אלאע'בר
502 ,ירכא ,6710 ,ראס אלמטל
502 ,ירכא ,409 ,רח 100
502 ,ירכא ,361 ,רח 200
502 ,ירכא ,388 ,רח 110
502 ,ירכא ,349 ,רח 210
502 ,ירכא ,210 ,רח 120
502 ,ירכא ,420 ,רח 220
502 ,ירכא ,230 ,רח 130
502 ,ירכא ,402 ,רח 230
502 ,ירכא ,171 ,רח 140
502 ,ירכא ,431 ,רח 240
502 ,ירכא ,183 ,רח 150
502 ,ירכא ,354 ,רח 250
502 ,ירכא ,197 ,רח 160
502 ,ירכא ,153 ,רח 260
502 ,ירכא ,110 ,רח 170
502 ,ירכא ,127 ,רח 180
502 ,ירכא ,337 ,רח 190
502 ,ירכא ,384 ,רח 101
502 ,ירכא ,142 ,רח 201
502 ,ירכא ,389 ,רח 111
502 ,ירכא ,350 ,רח 211
502 ,ירכא ,211 ,רח 121
502 ,ירכא ,370 ,רח 221
502 ,ירכא ,231 ,רח 131
502 ,ירכא ,403 ,רח 231
502 ,ירכא ,172 ,רח 141
502 ,ירכא ,404 ,רח 241
502 ,ירכא ,184 ,רח 151
502 ,ירכא ,355 ,רח 251
502 ,ירכא ,198 ,רח 161
502 ,ירכא ,379 ,רח 261
502 ,ירכא ,113 ,רח 171
502 ,ירכא ,128 ,רח 181
502 ,ירכא ,338 ,רח 191
502 ,ירכא ,385 ,רח 102
502 ,ירכא ,143 ,רח 202
502 ,ירכא ,390 ,רח 112
502 ,ירכא ,362 ,רח 212
502 ,ירכא ,212 ,רח 122
502 ,ירכא ,371 ,רח 222
502 ,ירכא ,324 ,רח 132
502 ,ירכא ,422 ,רח 232
502 ,ירכא ,173 ,רח 142
502 ,ירכא ,405 ,רח 242
502 ,ירכא ,185 ,רח 152
502 ,ירכא ,357 ,רח 252
502 ,ירכא ,199 ,רח 162
502 ,ירכא ,380 ,רח 262
502 ,ירכא ,115 ,רח 172
502 ,ירכא ,129 ,רח 182
502 ,ירכא ,340 ,רח 192
502 ,ירכא ,386 ,רח 103
502 ,ירכא ,144 ,רח 203
502 ,ירכא ,418 ,רח 113
502 ,ירכא ,363 ,רח 213
502 ,ירכא ,213 ,רח 123
502 ,ירכא ,372 ,רח 223
502 ,ירכא ,326 ,רח 133
502 ,ירכא ,424 ,רח 233
502 ,ירכא ,174 ,רח 143
502 ,ירכא ,406 ,רח 243
502 ,ירכא ,186 ,רח 153
502 ,ירכא ,205 ,רח 253
502 ,ירכא ,102 ,רח 163
502 ,ירכא ,433 ,רח 263
502 ,ירכא ,118 ,רח 173
502 ,ירכא ,132 ,רח 183
502 ,ירכא ,342 ,רח 193
502 ,ירכא ,387 ,רח 104
502 ,ירכא ,146 ,רח 204
502 ,ירכא ,391 ,רח 114
502 ,ירכא ,364 ,רח 214
502 ,ירכא ,214 ,רח 124
502 ,ירכא ,373 ,רח 224
502 ,ירכא ,451 ,רח 134
502 ,ירכא ,425 ,רח 234
502 ,ירכא ,175 ,רח 144
502 ,ירכא ,407 ,רח 244
502 ,ירכא ,187 ,רח 154
502 ,ירכא ,206 ,רח 254
502 ,ירכא ,103 ,רח 164
502 ,ירכא ,382 ,רח 264
502 ,ירכא ,119 ,רח 174
502 ,ירכא ,133 ,רח 184
502 ,ירכא ,343 ,רח 194
502 ,ירכא ,413 ,רח 105
502 ,ירכא ,347 ,רח 205
502 ,ירכא ,419 ,רח 115
502 ,ירכא ,365 ,רח 215
502 ,ירכא ,217 ,רח 125
502 ,ירכא ,397 ,רח 225
502 ,ירכא ,331 ,רח 135
502 ,ירכא ,426 ,רח 235
502 ,ירכא ,176 ,רח 145
502 ,ירכא ,374 ,רח 245
502 ,ירכא ,189 ,רח 155
502 ,ירכא ,150 ,רח 255
502 ,ירכא ,104 ,רח 165
502 ,ירכא ,434 ,רח 265
502 ,ירכא ,120 ,רח 175
502 ,ירכא ,138 ,רח 185
502 ,ירכא ,344 ,רח 195
502 ,ירכא ,414 ,רח 106
502 ,ירכא ,348 ,רח 206
502 ,ירכא ,209 ,רח 116
502 ,ירכא ,366 ,רח 216
502 ,ירכא ,218 ,רח 126
502 ,ירכא ,398 ,רח 226
502 ,ירכא ,163 ,רח 136
502 ,ירכא ,427 ,רח 236
502 ,ירכא ,177 ,רח 146
502 ,ירכא ,201 ,רח 246
502 ,ירכא ,191 ,רח 156
502 ,ירכא ,151 ,רח 256
502 ,ירכא ,105 ,רח 166
502 ,ירכא ,435 ,רח 266
502 ,ירכא ,121 ,רח 176
502 ,ירכא ,139 ,רח 186
502 ,ירכא ,345 ,רח 196
502 ,ירכא ,415 ,רח 107
502 ,ירכא ,147 ,רח 207
502 ,ירכא ,392 ,רח 117
502 ,ירכא ,367 ,רח 217
502 ,ירכא ,219 ,רח 127
502 ,ירכא ,399 ,רח 227
502 ,ירכא ,165 ,רח 137
502 ,ירכא ,430 ,רח 237
502 ,ירכא ,178 ,רח 147
502 ,ירכא ,351 ,רח 247
502 ,ירכא ,193 ,רח 157
502 ,ירכא ,375 ,רח 257
502 ,ירכא ,106 ,רח 167
502 ,ירכא ,436 ,רח 267
502 ,ירכא ,122 ,רח 177
502 ,ירכא ,333 ,רח 187
502 ,ירכא ,346 ,רח 197
502 ,ירכא ,416 ,רח 108
502 ,ירכא ,148 ,רח 208
502 ,ירכא ,394 ,רח 118
502 ,ירכא ,368 ,רח 218
502 ,ירכא ,221 ,רח 128
502 ,ירכא ,400 ,רח 228
502 ,ירכא ,166 ,רח 138
502 ,ירכא ,444 ,רח 238
502 ,ירכא ,202 ,רח 248
502 ,ירכא ,195 ,רח 158
502 ,ירכא ,377 ,רח 258
502 ,ירכא ,200 ,רח 168
502 ,ירכא ,123 ,רח 178
502 ,ירכא ,334 ,רח 188
502 ,ירכא ,359 ,רח 198
502 ,ירכא ,417 ,רח 109
502 ,ירכא ,149 ,רח 209
502 ,ירכא ,395 ,רח 119
502 ,ירכא ,369 ,רח 219
502 ,ירכא ,222 ,רח 129
502 ,ירכא ,401 ,רח 229
502 ,ירכא ,167 ,רח 139
502 ,ירכא ,446 ,רח 239
502 ,ירכא ,181 ,רח 149
502 ,ירכא ,432 ,רח 249
502 ,ירכא ,196 ,רח 159
502 ,ירכא ,378 ,רח 259
502 ,ירכא ,109 ,רח 169
502 ,ירכא ,125 ,רח 179
502 ,ירכא ,336 ,רח 189
502 ,ירכא ,360 ,רח 199
502 ,ירכא ,329 ,שארע אלבלאט
502 ,ירכא ,116 ,שארע אלעין
502 ,ירכא ,158 ,שארע אלשואחיט
502 ,ירכא ,228 ,שארע אסבר
502 ,ירכא ,232 ,שיח' יוסף גדבאן
502 ,ירכא ,453 ,שיח' מחמד מעדי
502 ,ירכא ,327 ,שיח' עלי אלפארס
502 ,ירכא ,450 ,תלת אסקנדר
183 ,ירקונה ,114 ,בית העם
183 ,ירקונה ,101 ,דרך הברוש
183 ,ירקונה ,102 ,דרך הפקאן
183 ,ירקונה ,103 ,דרך מייסדי ירקונה
183 ,ירקונה ,104 ,האלה
183 ,ירקונה ,113 ,האקליפטוס
183 ,ירקונה ,105 ,הדקל
183 ,ירקונה ,106 ,הערבה
183 ,ירקונה ,107 ,הפרדס
183 ,ירקונה ,108 ,הצאלון
183 ,ירקונה ,110 ,יפה נוף
183 ,ירקונה ,111 ,ירוק עד
183 ,ירקונה ,9000 ,ירקונה
183 ,ירקונה ,115 ,סביונים
183 ,ירקונה ,112 ,שירת השחרור
916 ,ישע ,9000 ,ישע
805 ,ישעי ,9000 ,ישעי
828 ,ישרש ,103 ,אורן
828 ,ישרש ,102 ,ברק
828 ,ישרש ,104 ,דן
828 ,ישרש ,105 ,חרוד
828 ,ישרש ,107 ,ירקון
828 ,ישרש ,9000 ,ישרש
828 ,ישרש ,106 ,לכיש
828 ,ישרש ,101 ,נ. הירדן
828 ,ישרש ,109 ,נעמן
828 ,ישרש ,110 ,ערבה
828 ,ישרש ,108 ,פולג
828 ,ישרש ,111 ,קישון
1227 ,יתד ,101 ,ארזים
1227 ,יתד ,102 ,הדסים
1227 ,יתד ,9000 ,יתד
1227 ,יתד ,103 ,שיטה
504 ,כאבול ,135 ,אבו אלופא
504 ,כאבול ,118 ,אבו בכר אלסדיק
504 ,כאבול ,143 ,אבו חאמד אלג'זאלי
504 ,כאבול ,149 ,אבו חדיפה אלנעמאן
504 ,כאבול ,111 ,אבן אלהיתם
504 ,כאבול ,158 ,אבן אלנפיס
504 ,כאבול ,114 ,אבן ח'לדון
504 ,כאבול ,107 ,אבן סינא
504 ,כאבול ,172 ,אבן רושד
504 ,כאבול ,169 ,אלאזהר
504 ,כאבול ,128 ,אלאמל
504 ,כאבול ,127 ,אלאפק
504 ,כאבול ,103 ,אלבידר
504 ,כאבול ,145 ,אלביר
504 ,כאבול ,144 ,אלבראעם
504 ,כאבול ,112 ,אלג'אביה
504 ,כאבול ,153 ,אלג'בל
504 ,כאבול ,167 ,אלג'ליל
504 ,כאבול ,147 ,אלג'רבאל
504 ,כאבול ,151 ,אלדיעה
504 ,כאבול ,129 ,אלדלה
504 ,כאבול ,154 ,אלואדי
504 ,כאבול ,105 ,אלורוד
504 ,כאבול ,126 ,אלזיתון
504 ,כאבול ,140 ,אלחאג'
504 ,כאבול ,159 ,אלחדיקה
504 ,כאבול ,117 ,אלחוואז
504 ,כאבול ,173 ,אלחיר
504 ,כאבול ,136 ,אלטאחונה
504 ,כאבול ,161 ,אלכוארזמי
504 ,כאבול ,156 ,אלכימה
504 ,כאבול ,122 ,אלכנסאא
504 ,כאבול ,155 ,אלכסוף
504 ,כאבול ,174 ,אלכרובה
504 ,כאבול ,160 ,אלמדור
504 ,כאבול ,125 ,אלמחראת
504 ,כאבול ,134 ,אלמנבר
504 ,כאבול ,146 ,אלמנג'ל
504 ,כאבול ,131 ,אלמסנע
504 ,כאבול ,119 ,אלמסרב
504 ,כאבול ,116 ,אלמעסרה
504 ,כאבול ,132 ,אלמרג'
504 ,כאבול ,168 ,אלנהדה
504 ,כאבול ,133 ,אלנור
504 ,כאבול ,163 ,אלנכלה
504 ,כאבול ,139 ,אלנקארה
504 ,כאבול ,115 ,אלנרג'ס
504 ,כאבול ,152 ,אלסאלם
504 ,כאבול ,130 ,אלסהל
504 ,כאבול ,137 ,אלסנדיאנה
504 ,כאבול ,124 ,אלסראג'
504 ,כאבול ,171 ,אלעריד
504 ,כאבול ,101 ,אלקדס
504 ,כאבול ,120 ,אלקנדיל
504 ,כאבול ,121 ,אלראזי
504 ,כאבול ,170 ,אלריחאנה
504 ,כאבול ,150 ,אלשאפערי
504 ,כאבול ,157 ,אלשיך רומי
504 ,כאבול ,102 ,בילאל בן רבאח
504 ,כאבול ,166 ,ג'זה
504 ,כאבול ,109 ,ח'אלד בן אלואליד
504 ,כאבול ,113 ,ח'ליל אלסכאכיני
504 ,כאבול ,141 ,חג'ר אלנקר
504 ,כאבול ,162 ,חיפה
504 ,כאבול ,164 ,יאפא
504 ,כאבול ,9000 ,כאבול
504 ,כאבול ,110 ,מי זיאדה
504 ,כאבול ,138 ,מרח אלג'זלאן
504 ,כאבול ,123 ,סויה
504 ,כאבול ,108 ,סידנא אלח'דר
504 ,כאבול ,142 ,עבאס אלעקאד
504 ,כאבול ,165 ,עלי בן אבי טאלב
504 ,כאבול ,104 ,עמר בן אלח'טאב
504 ,כאבול ,175 ,שקאנה
504 ,כאבול ,148 ,תוופיק זיאד
505 ,כאוכב אבו אל-היג'א ,9000 ,כאוכב אבו אל-היג'א
576 ,כברי ,9000 ,כברי
371 ,כדורי ,7001 ,אלון התבור
371 ,כדורי ,9000 ,כדורי
1338 ,כדיתה ,9000 ,כדיתה
3564 ,כוכב השחר ,123 ,בית הבד
3564 ,כוכב השחר ,101 ,דרך המשואות
3564 ,כוכב השחר ,102 ,דרך יריחו
3564 ,כוכב השחר ,125 ,האחזות נח"ל
3564 ,כוכב השחר ,110 ,הביכורים
3564 ,כוכב השחר ,107 ,הבציר
3564 ,כוכב השחר ,109 ,הגת
3564 ,כוכב השחר ,116 ,הזית
3564 ,כוכב השחר ,128 ,היין
3564 ,כוכב השחר ,112 ,היקב
3564 ,כוכב השחר ,118 ,הכרם
3564 ,כוכב השחר ,121 ,המנורה
3564 ,כוכב השחר ,122 ,המסיק
3564 ,כוכב השחר ,106 ,הנסכים
3564 ,כוכב השחר ,127 ,הר חצור
3564 ,כוכב השחר ,129 ,הר קידה
3564 ,כוכב השחר ,105 ,התירוש
3564 ,כוכב השחר ,104 ,י"ב באלול
3564 ,כוכב השחר ,115 ,יצהר
3564 ,כוכב השחר ,9000 ,כוכב השחר
3564 ,כוכב השחר ,120 ,כתית
3564 ,כוכב השחר ,113 ,מול נבו
3564 ,כוכב השחר ,117 ,מנחות
3564 ,כוכב השחר ,119 ,משכן שילה
3564 ,כוכב השחר ,103 ,נוף הירדן
3564 ,כוכב השחר ,114 ,נחל ייט"ב
3564 ,כוכב השחר ,124 ,נחלת אפרים
3564 ,כוכב השחר ,126 ,נר התמיד
3564 ,כוכב השחר ,130 ,סלע הרימון
3564 ,כוכב השחר ,111 ,ענבי הגפן
3564 ,כוכב השחר ,108 ,עת הזמיר
1224 ,כוכב יאיר ,3103 ,אבני החושן צור יגאל
1224 ,כוכב יאיר ,3104 ,אבני חן צור יגאל
1224 ,כוכב יאיר ,141 ,אגוז
1224 ,כוכב יאיר ,3130 ,אגמית צור יגאל
1224 ,כוכב יאיר ,3105 ,אודם צור יגאל
1224 ,כוכב יאיר ,142 ,אורן
1224 ,כוכב יאיר ,143 ,אלה
1224 ,כוכב יאיר ,144 ,אלון
1224 ,כוכב יאיר ,3180 ,אנקור צור יגאל
1224 ,כוכב יאיר ,3194 ,אפק
1224 ,כוכב יאיר ,3129 ,ארבל צור יגאל
1224 ,כוכב יאיר ,145 ,ארז
1224 ,כוכב יאיר ,146 ,אשל
1224 ,כוכב יאיר ,138 ,בארותיים
1224 ,כוכב יאיר ,137 ,בארי
1224 ,כוכב יאיר ,3158 ,בזלת צור יגאל
1224 ,כוכב יאיר ,3140 ,בזק צור יגאל
1224 ,כוכב יאיר ,136 ,בירנית
1224 ,כוכב יאיר ,131 ,בית אל
1224 ,כוכב יאיר ,3195 ,בית אלפא
1224 ,כוכב יאיר ,3150 ,ברוש צור יגאל
1224 ,כוכב יאיר ,135 ,ברעם
1224 ,כוכב יאיר ,134 ,ברק
1224 ,כוכב יאיר ,133 ,ברקאי
1224 ,כוכב יאיר ,3106 ,ברקת צור יגאל
1224 ,כוכב יאיר ,3141 ,בשור צור יגאל
1224 ,כוכב יאיר ,132 ,בת שלמה
1224 ,כוכב יאיר ,3190 ,גבעון צור יגאל
1224 ,כוכב יאיר ,124 ,גולן
1224 ,כוכב יאיר ,3196 ,גזר
1224 ,כוכב יאיר ,125 ,גילה
1224 ,כוכב יאיר ,123 ,גלבוע
1224 ,כוכב יאיר ,3191 ,גלגל צור יגאל
1224 ,כוכב יאיר ,127 ,גלעד
1224 ,כוכב יאיר ,122 ,גמלא
1224 ,כוכב יאיר ,121 ,גריזים
1224 ,כוכב יאיר ,3156 ,גרניט צור יגאל
1224 ,כוכב יאיר ,102 ,דבורה
1224 ,כוכב יאיר ,3197 ,דביר
1224 ,כוכב יאיר ,103 ,דולב
1224 ,כוכב יאיר ,104 ,דור
1224 ,כוכב יאיר ,105 ,דותן
1224 ,כוכב יאיר ,106 ,דיה
1224 ,כוכב יאיר ,107 ,דישון
1224 ,כוכב יאיר ,109 ,דליה
1224 ,כוכב יאיר ,108 ,דליות
1224 ,כוכב יאיר ,111 ,דלתון
1224 ,כוכב יאיר ,110 ,דן
1224 ,כוכב יאיר ,112 ,דפנה
1224 ,כוכב יאיר ,3169 ,דפנה צור יגאל
1224 ,כוכב יאיר ,114 ,דקלה
1224 ,כוכב יאיר ,113 ,דרגות
1224 ,כוכב יאיר ,3181 ,דרור צור יגאל
1224 ,כוכב יאיר ,139 ,דרך הגבעה
1224 ,כוכב יאיר ,201 ,דרך הפרחים
1224 ,כוכב יאיר ,3135 ,האילנות צור יגאל
1224 ,כוכב יאיר ,3160 ,האירוס צור יגאל
1224 ,כוכב יאיר ,218 ,האיריס
1224 ,כוכב יאיר ,219 ,הברקן
1224 ,כוכב יאיר ,220 ,הגומא
1224 ,כוכב יאיר ,221 ,הדודאים
1224 ,כוכב יאיר ,209 ,הדס
1224 ,כוכב יאיר ,153 ,ההר
1224 ,כוכב יאיר ,208 ,הורד
1224 ,כוכב יאיר ,156 ,הים
1224 ,כוכב יאיר ,207 ,היסמין
1224 ,כוכב יאיר ,206 ,הכלנית
1224 ,כוכב יאיר ,151 ,הכנרות
1224 ,כוכב יאיר ,205 ,הכרכום
1224 ,כוכב יאיר ,210 ,הלוטם
1224 ,כוכב יאיר ,154 ,המדבר
1224 ,כוכב יאיר ,157 ,המישר
1224 ,כוכב יאיר ,152 ,המכתש
1224 ,כוכב יאיר ,155 ,הנגב
1224 ,כוכב יאיר ,203 ,הנורית
1224 ,כוכב יאיר ,202 ,הנרקיס
1224 ,כוכב יאיר ,216 ,העמק
1224 ,כוכב יאיר ,215 ,הערבה
1224 ,כוכב יאיר ,3123 ,הר נבו צור יגאל
1224 ,כוכב יאיר ,3168 ,הרדוף צור יגאל
1224 ,כוכב יאיר ,3198 ,הרודיון
1224 ,כוכב יאיר ,3128 ,הרכסים צור יגאל
1224 ,כוכב יאיר ,214 ,השומרון
1224 ,כוכב יאיר ,3131 ,השחף צור יגאל
1224 ,כוכב יאיר ,213 ,השפלה
1224 ,כוכב יאיר ,212 ,השרון
1224 ,כוכב יאיר ,3142 ,זויתן צור יגאל
1224 ,כוכב יאיר ,3182 ,זמיר צור יגאל
1224 ,כוכב יאיר ,3109 ,חלוקי נחל צור יגאל
1224 ,כוכב יאיר ,3159 ,חלמיש צור יגאל
1224 ,כוכב יאיר ,3199 ,חצור
1224 ,כוכב יאיר ,3151 ,חרוב צור יגאל
1224 ,כוכב יאיר ,3108 ,טופז צור יגאל
1224 ,כוכב יאיר ,3155 ,יהלום צור יגאל
1224 ,כוכב יאיר ,3167 ,יסמין צור יגאל
1224 ,כוכב יאיר ,3172 ,יערה צור יגאל
1224 ,כוכב יאיר ,3200 ,ירושלים
1224 ,כוכב יאיר ,3120 ,כרמל צור יגאל
1224 ,כוכב יאיר ,211 ,לב הישוב
1224 ,כוכב יאיר ,3122 ,ליבנים צור יגאל
1224 ,כוכב יאיר ,3201 ,לכיש
1224 ,כוכב יאיר ,3110 ,לשם צור יגאל
1224 ,כוכב יאיר ,3170 ,מורן צור יגאל
1224 ,כוכב יאיר ,3202 ,מצדה
1224 ,כוכב יאיר ,6502 ,מצפה ספיר צור יגאל
1224 ,כוכב יאיר ,3161 ,מרגלית צור יגאל
1224 ,כוכב יאיר ,3124 ,נוף הרים צור יגאל
1224 ,כוכב יאיר ,3162 ,נופר צור יגאל
1224 ,כוכב יאיר ,3143 ,נחשון צור יגאל
1224 ,כוכב יאיר ,3144 ,נטפים צור יגאל
1224 ,כוכב יאיר ,3203 ,ניצנה
1224 ,כוכב יאיר ,3204 ,סוסיא
1224 ,כוכב יאיר ,3163 ,סייפן צור יגאל
1224 ,כוכב יאיר ,3164 ,סיתוונית צור יגאל
1224 ,כוכב יאיר ,3183 ,סנונית צור יגאל
1224 ,כוכב יאיר ,3107 ,ספיר צור יגאל
1224 ,כוכב יאיר ,3184 ,עגור צור יגאל
1224 ,כוכב יאיר ,3145 ,עובדת צור יגאל
1224 ,כוכב יאיר ,3125 ,עמק דותן צור יגאל
1224 ,כוכב יאיר ,3192 ,עמק האלה צור יגאל
1224 ,כוכב יאיר ,3193 ,ענתות צור יגאל
1224 ,כוכב יאיר ,3185 ,עפרוני צור יגאל
1224 ,כוכב יאיר ,3126 ,עצמון צור יגאל
1224 ,כוכב יאיר ,3121 ,פסגות צור יגאל
1224 ,כוכב יאיר ,6501 ,צור יגאל
1224 ,כוכב יאיר ,3157 ,צור צור יגאל
1224 ,כוכב יאיר ,3205 ,ציפורי
1224 ,כוכב יאיר ,3127 ,צרעה צור יגאל
1224 ,כוכב יאיר ,3146 ,קדרון צור יגאל
1224 ,כוכב יאיר ,3173 ,קורנית צור יגאל
1224 ,כוכב יאיר ,3206 ,קיסריה
1224 ,כוכב יאיר ,3132 ,רותם צור יגאל
1224 ,כוכב יאיר ,3165 ,רקפת צור יגאל
1224 ,כוכב יאיר ,3133 ,שד הדקלים צור יגאל
1224 ,כוכב יאיר ,101 ,שדרות הארץ
1224 ,כוכב יאיר ,3111 ,שוהם צור יגאל
1224 ,כוכב יאיר ,3102 ,שורק צור יגאל
1224 ,כוכב יאיר ,3166 ,שושן צחור צור יגאל
1224 ,כוכב יאיר ,3152 ,שיטה צור יגאל
1224 ,כוכב יאיר ,3134 ,שניר צור יגאל
1224 ,כוכב יאיר ,3153 ,שקד צור יגאל
1224 ,כוכב יאיר ,3136 ,תבור צור יגאל
3779 ,כוכב יעקב ,217 ,אבני חפץ
3779 ,כוכב יעקב ,208 ,אגן הסהר
3779 ,כוכב יעקב ,116 ,אדמור"י בעלז
3779 ,כוכב יעקב ,122 ,אדמור"י צאנז
3779 ,כוכב יעקב ,104 ,אהבת אמת
3779 ,כוכב יעקב ,103 ,אהבת ישראל
3779 ,כוכב יעקב ,106 ,אהבת שלום
3779 ,כוכב יעקב ,117 ,אוהל דוד
3779 ,כוכב יעקב ,200 ,אור חדש
3779 ,כוכב יעקב ,108 ,אמרי חיים
3779 ,כוכב יעקב ,213 ,אפיקי מים
3779 ,כוכב יעקב ,119 ,בית יוסף
3779 ,כוכב יעקב ,6501 ,גבעת הברכה
3779 ,כוכב יעקב ,207 ,גינת אגוז
3779 ,כוכב יעקב ,204 ,דבש וחלב
3779 ,כוכב יעקב ,227 ,דרך כוכב יעקב
3779 ,כוכב יעקב ,202 ,הר המור
3779 ,כוכב יעקב ,120 ,הרמב"ן
3779 ,כוכב יעקב ,201 ,חגווי הסלע
3779 ,כוכב יעקב ,102 ,חזון איש
3779 ,כוכב יעקב ,111 ,חמדת יצחק
3779 ,כוכב יעקב ,224 ,חסדי אבות
3779 ,כוכב יעקב ,101 ,חפץ חיים
3779 ,כוכב יעקב ,121 ,חת"ם סופר
3779 ,כוכב יעקב ,214 ,טיילת ערוגות הבושם
3779 ,כוכב יעקב ,210 ,טירת כסף
3779 ,כוכב יעקב ,223 ,טל אורות
3779 ,כוכב יעקב ,124 ,יפה נוף
3779 ,כוכב יעקב ,9000 ,כוכב יעקב
3779 ,כוכב יעקב ,125 ,לב בנים
3779 ,כוכב יעקב ,118 ,מאור השמש
3779 ,כוכב יעקב ,225 ,מבשר טוב
3779 ,כוכב יעקב ,216 ,מים חיים
3779 ,כוכב יעקב ,112 ,מנחת אהרון
3779 ,כוכב יעקב ,226 ,מעייני הישועה
3779 ,כוכב יעקב ,211 ,מעלה בית אל
3779 ,כוכב יעקב ,110 ,מעלות החוכמה
3779 ,כוכב יעקב ,107 ,מקור ברוך
3779 ,כוכב יעקב ,215 ,משכנות הרועים
3779 ,כוכב יעקב ,221 ,נהר שלום
3779 ,כוכב יעקב ,113 ,נתיב אלעזר
3779 ,כוכב יעקב ,114 ,עטרת צבי
3779 ,כוכב יעקב ,206 ,עצי הלבנון
3779 ,כוכב יעקב ,205 ,פרדס רימונים
3779 ,כוכב יעקב ,209 ,פרי מגדים
3779 ,כוכב יעקב ,115 ,צמח צדיק
3779 ,כוכב יעקב ,219 ,צמח צדק
3779 ,כוכב יעקב ,123 ,קדושת לוי
3779 ,כוכב יעקב ,105 ,קהילות יעקב
3779 ,כוכב יעקב ,203 ,קול התור
3779 ,כוכב יעקב ,220 ,שד אביר יעקב
3779 ,כוכב יעקב ,222 ,שיבת ציון
3779 ,כוכב יעקב ,218 ,שירה חדשה
3779 ,כוכב יעקב ,6503 ,שכ שומר אמונים
3779 ,כוכב יעקב ,6502 ,שכ שיר השירים
3779 ,כוכב יעקב ,126 ,שלום בנייך
3779 ,כוכב יעקב ,212 ,שמחת עולם
3779 ,כוכב יעקב ,109 ,תורה מציון
3779 ,כוכב יעקב ,6500 ,תל ציון
824 ,כוכב מיכאל ,101 ,הגפן
824 ,כוכב מיכאל ,117 ,הדקל
824 ,כוכב מיכאל ,115 ,הזית
824 ,כוכב מיכאל ,104 ,הכרם
824 ,כוכב מיכאל ,106 ,העינב
824 ,כוכב מיכאל ,110 ,הצבר
824 ,כוכב מיכאל ,111 ,הרימון
824 ,כוכב מיכאל ,113 ,השקד
824 ,כוכב מיכאל ,116 ,התאנה
824 ,כוכב מיכאל ,109 ,התבואה
824 ,כוכב מיכאל ,112 ,התמר
824 ,כוכב מיכאל ,105 ,זמורה
824 ,כוכב מיכאל ,9000 ,כוכב מיכאל
824 ,כוכב מיכאל ,6001 ,כיכר הראשונים
824 ,כוכב מיכאל ,103 ,סמדר
824 ,כוכב מיכאל ,6500 ,שכ הגפן
824 ,כוכב מיכאל ,6501 ,שכ הרימון
824 ,כוכב מיכאל ,6502 ,שכ התאנה
824 ,כוכב מיכאל ,102 ,שריג
1252 ,כורזים ,117 ,אורן
1252 ,כורזים ,113 ,אלה
1252 ,כורזים ,112 ,אלון
1252 ,כורזים ,103 ,ארבל
1252 ,כורזים ,110 ,בזלת
1252 ,כורזים ,105 ,גולן
1252 ,כורזים ,102 ,גליל
1252 ,כורזים ,106 ,גמלא
1252 ,כורזים ,115 ,דולב
1252 ,כורזים ,108 ,דליות
1252 ,כורזים ,116 ,דקל
1252 ,כורזים ,107 ,זוויתן
1252 ,כורזים ,7001 ,חות ורד הגליל
1252 ,כורזים ,101 ,ירדן
1252 ,כורזים ,9000 ,כורזים
1252 ,כורזים ,104 ,כנרת
1252 ,כורזים ,111 ,מעוף
1252 ,כורזים ,109 ,שיאון
1252 ,כורזים ,114 ,שיזף
1210 ,כחל ,104 ,אלה
1210 ,כחל ,103 ,אלון
1210 ,כחל ,102 ,ארבל
1210 ,כחל ,107 ,הר-כור
1210 ,כחל ,106 ,זית
1210 ,כחל ,108 ,ירדן
1210 ,כחל ,9000 ,כחל
1210 ,כחל ,101 ,כינרת
1210 ,כחל ,105 ,שקד
1367 ,כחלה ,9000 ,כחלה
840 ,כיסופים ,101 ,בית ראשון במולדת
840 ,כיסופים ,9000 ,כיסופים
1153 ,כישור ,9000 ,כישור
1153 ,כישור ,6500 ,כישורית
1183 ,כליל ,9000 ,כליל
1229 ,כלנית ,9000 ,כלנית
1331 ,כמאנה ,9000 ,כמאנה
1291 ,כמהין ,101 ,בית ראשון במולדת
1291 ,כמהין ,9000 ,כמהין
1201 ,כמון ,106 ,הגבעה הצפונית
1201 ,כמון ,113 ,הגת
1201 ,כמון ,107 ,הזריחה
1201 ,כמון ,105 ,החורש
1201 ,כמון ,109 ,המצוק
1201 ,כמון ,108 ,המצפור
1201 ,כמון ,104 ,המרעה
1201 ,כמון ,111 ,הראשונים
1201 ,כמון ,9000 ,כמון
1201 ,כמון ,102 ,כרמי זיתים
1201 ,כמון ,110 ,מגדל המים
1201 ,כמון ,103 ,נוף ים
1201 ,כמון ,101 ,פרשת מים
1201 ,כמון ,112 ,שעון השמש
2006 ,כנות ,9000 ,כנות
4028 ,כנף ,9000 ,כנף
63 ,כנרת (מושבה) ,6500 ,גבעת הזיתים
63 ,כנרת (מושבה) ,101 ,דרך הבזלת
63 ,כנרת (מושבה) ,110 ,דרך הזיתים
63 ,כנרת (מושבה) ,102 ,דרך הכלניות
63 ,כנרת (מושבה) ,125 ,דרך המעיין
63 ,כנרת (מושבה) ,126 ,האגם
63 ,כנרת (מושבה) ,106 ,האומנים
63 ,כנרת (מושבה) ,104 ,הגורן
63 ,כנרת (מושבה) ,124 ,הכרם
63 ,כנרת (מושבה) ,109 ,המייסדים
63 ,כנרת (מושבה) ,123 ,המסיק
63 ,כנרת (מושבה) ,115 ,המצפה
63 ,כנרת (מושבה) ,128 ,הפלג
63 ,כנרת (מושבה) ,107 ,הראשונים
63 ,כנרת (מושבה) ,116 ,הרדוף
63 ,כנרת (מושבה) ,108 ,חוף הירדן
63 ,כנרת (מושבה) ,119 ,חרצית
63 ,כנרת (מושבה) ,122 ,יצהר
63 ,כנרת (מושבה) ,9000 ,כנרת (מושבה)
63 ,כנרת (מושבה) ,121 ,לשם
63 ,כנרת (מושבה) ,112 ,מעלה הבריכה
63 ,כנרת (מושבה) ,120 ,מעלה ההר
63 ,כנרת (מושבה) ,103 ,נוף הים
63 ,כנרת (מושבה) ,117 ,נורית
63 ,כנרת (מושבה) ,118 ,סביון
63 ,כנרת (מושבה) ,111 ,סמטת הארמון
63 ,כנרת (מושבה) ,113 ,סמטת המדרגות
63 ,כנרת (מושבה) ,114 ,סמטת המוזיאון
63 ,כנרת (מושבה) ,127 ,סמטת הנחל
63 ,כנרת (מושבה) ,105 ,רחל
63 ,כנרת (מושבה) ,6501 ,שכ הכלניות
63 ,כנרת (מושבה) ,6503 ,שכ המעיין
63 ,כנרת (מושבה) ,6502 ,שכ' הבזלת
57 ,כנרת (קבוצה) ,9000 ,כנרת (קבוצה)
1059 ,כסיפה ,9000 ,כסיפה
1059 ,כסיפה ,6726 ,שכ 20
1059 ,כסיפה ,6703 ,שכ 30
1059 ,כסיפה ,6712 ,שכ 40
1059 ,כסיפה ,6727 ,שכ 80
1059 ,כסיפה ,6713 ,שכ 11
1059 ,כסיפה ,6722 ,שכ 21
1059 ,כסיפה ,6704 ,שכ 31
1059 ,כסיפה ,6709 ,שכ 41
1059 ,כסיפה ,6714 ,שכ 12
1059 ,כסיפה ,6723 ,שכ 22
1059 ,כסיפה ,6710 ,שכ 32
1059 ,כסיפה ,6705 ,שכ 42
1059 ,כסיפה ,6715 ,שכ 13
1059 ,כסיפה ,6724 ,שכ 23
1059 ,כסיפה ,6711 ,שכ 33
1059 ,כסיפה ,6706 ,שכ 43
1059 ,כסיפה ,6716 ,שכ 14
1059 ,כסיפה ,6701 ,שכ 24
1059 ,כסיפה ,6717 ,שכ 15
1059 ,כסיפה ,6702 ,שכ 25
1059 ,כסיפה ,6707 ,שכ 45
1059 ,כסיפה ,6718 ,שכ 16
1059 ,כסיפה ,6725 ,שכ 36
1059 ,כסיפה ,6708 ,שכ 46
1059 ,כסיפה ,6719 ,שכ 17
1059 ,כסיפה ,6720 ,שכ 18
1059 ,כסיפה ,6721 ,שכ 19
1059 ,כסיפה ,107 ,שקאאיק א-נועמאן
859 ,כסלון ,9000 ,כסלון
1296 ,כסרא-סמיע ,101 ,כסרא
1296 ,כסרא-סמיע ,9000 ,כסרא-סמיע
1296 ,כסרא-סמיע ,102 ,כפר סמיע
1296 ,כסרא-סמיע ,192 ,רח 10
1296 ,כסרא-סמיע ,231 ,רח 1310
1296 ,כסרא-סמיע ,246 ,רח 1410
1296 ,כסרא-סמיע ,187 ,רח 710
1296 ,כסרא-סמיע ,285 ,רח 1910
1296 ,כסרא-סמיע ,100 ,רח 1
1296 ,כסרא-סמיע ,193 ,רח 1001
1296 ,כסרא-סמיע ,300 ,רח 2001
1296 ,כסרא-סמיע ,103 ,רח 101
1296 ,כסרא-סמיע ,110 ,רח 201
1296 ,כסרא-סמיע ,210 ,רח 1201
1296 ,כסרא-סמיע ,124 ,רח 401
1296 ,כסרא-סמיע ,136 ,רח 501
1296 ,כסרא-סמיע ,249 ,רח 1501
1296 ,כסרא-סמיע ,162 ,רח 601
1296 ,כסרא-סמיע ,263 ,רח 1701
1296 ,כסרא-סמיע ,270 ,רח 1801
1296 ,כסרא-סמיע ,290 ,רח 1901
1296 ,כסרא-סמיע ,202 ,רח 11
1296 ,כסרא-סמיע ,232 ,רח 1311
1296 ,כסרא-סמיע ,247 ,רח 1411
1296 ,כסרא-סמיע ,172 ,רח 611
1296 ,כסרא-סמיע ,283 ,רח 1911
1296 ,כסרא-סמיע ,303 ,רח 21
1296 ,כסרא-סמיע ,279 ,רח 19121
1296 ,כסרא-סמיע ,298 ,רח 191221
1296 ,כסרא-סמיע ,160 ,רח 51721
1296 ,כסרא-סמיע ,295 ,רח 19131
1296 ,כסרא-סמיע ,141 ,רח 5041
1296 ,כסרא-סמיע ,268 ,רח 17041
1296 ,כסרא-סמיע ,155 ,רח 51641
1296 ,כסרא-סמיע ,257 ,רח 86551
1296 ,כסרא-סמיע ,151 ,רח 5161
1296 ,כסרא-סמיע ,158 ,רח 5171
1296 ,כסרא-סמיע ,121 ,רח 2091
1296 ,כסרא-סמיע ,288 ,רח 19091
1296 ,כסרא-סמיע ,109 ,רח 2
1296 ,כסרא-סמיע ,194 ,רח 1002
1296 ,כסרא-סמיע ,301 ,רח 2002
1296 ,כסרא-סמיע ,104 ,רח 102
1296 ,כסרא-סמיע ,204 ,רח 1102
1296 ,כסרא-סמיע ,111 ,רח 202
1296 ,כסרא-סמיע ,125 ,רח 402
1296 ,כסרא-סמיע ,163 ,רח 602
1296 ,כסרא-סמיע ,179 ,רח 702
1296 ,כסרא-סמיע ,264 ,רח 1702
1296 ,כסרא-סמיע ,271 ,רח 1802
1296 ,כסרא-סמיע ,291 ,רח 1902
1296 ,כסרא-סמיע ,209 ,רח 12
1296 ,כסרא-סמיע ,233 ,רח 1312
1296 ,כסרא-סמיע ,292 ,רח 1912
1296 ,כסרא-סמיע ,293 ,רח 19122
1296 ,כסרא-סמיע ,223 ,רח 1032
1296 ,כסרא-סמיע ,296 ,רח 19132
1296 ,כסרא-סמיע ,156 ,רח 51642
1296 ,כסרא-סמיע ,258 ,רח 86552
1296 ,כסרא-סמיע ,152 ,רח 5162
1296 ,כסרא-סמיע ,159 ,רח 5172
1296 ,כסרא-סמיע ,122 ,רח 3
1296 ,כסרא-סמיע ,195 ,רח 1003
1296 ,כסרא-סמיע ,302 ,רח 2003
1296 ,כסרא-סמיע ,105 ,רח 103
1296 ,כסרא-סמיע ,211 ,רח 1203
1296 ,כסרא-סמיע ,126 ,רח 403
1296 ,כסרא-סמיע ,250 ,רח 1503
1296 ,כסרא-סמיע ,180 ,רח 703
1296 ,כסרא-סמיע ,265 ,רח 1703
1296 ,כסרא-סמיע ,272 ,רח 1803
1296 ,כסרא-סמיע ,281 ,רח 1903
1296 ,כסרא-סמיע ,221 ,רח 13
1296 ,כסרא-סמיע ,190 ,רח 713
1296 ,כסרא-סמיע ,294 ,רח 1913
1296 ,כסרא-סמיע ,297 ,רח 19133
1296 ,כסרא-סמיע ,260 ,רח 86553
1296 ,כסרא-סמיע ,153 ,רח 5163
1296 ,כסרא-סמיע ,123 ,רח 4
1296 ,כסרא-סמיע ,106 ,רח 104
1296 ,כסרא-סמיע ,206 ,רח 1104
1296 ,כסרא-סמיע ,113 ,רח 204
1296 ,כסרא-סמיע ,212 ,רח 1204
1296 ,כסרא-סמיע ,139 ,רח 504
1296 ,כסרא-סמיע ,251 ,רח 1504
1296 ,כסרא-סמיע ,165 ,רח 604
1296 ,כסרא-סמיע ,266 ,רח 1704
1296 ,כסרא-סמיע ,280 ,רח 1904
1296 ,כסרא-סמיע ,237 ,רח 14
1296 ,כסרא-סמיע ,148 ,רח 514
1296 ,כסרא-סמיע ,259 ,רח 86554
1296 ,כסרא-סמיע ,154 ,רח 5164
1296 ,כסרא-סמיע ,135 ,רח 5
1296 ,כסרא-סמיע ,107 ,רח 105
1296 ,כסרא-סמיע ,207 ,רח 1105
1296 ,כסרא-סמיע ,114 ,רח 205
1296 ,כסרא-סמיע ,213 ,רח 1205
1296 ,כסרא-סמיע ,127 ,רח 405
1296 ,כסרא-סמיע ,241 ,רח 1405
1296 ,כסרא-סמיע ,140 ,רח 505
1296 ,כסרא-סמיע ,267 ,רח 1705
1296 ,כסרא-סמיע ,274 ,רח 1805
1296 ,כסרא-סמיע ,282 ,רח 1905
1296 ,כסרא-סמיע ,248 ,רח 15
1296 ,כסרא-סמיע ,236 ,רח 1315
1296 ,כסרא-סמיע ,149 ,רח 515
1296 ,כסרא-סמיע ,176 ,רח 615
1296 ,כסרא-סמיע ,256 ,רח 86555
1296 ,כסרא-סמיע ,161 ,רח 6
1296 ,כסרא-סמיע ,198 ,רח 1006
1296 ,כסרא-סמיע ,108 ,רח 106
1296 ,כסרא-סמיע ,208 ,רח 1106
1296 ,כסרא-סמיע ,115 ,רח 206
1296 ,כסרא-סמיע ,214 ,רח 1206
1296 ,כסרא-סמיע ,242 ,רח 1406
1296 ,כסרא-סמיע ,167 ,רח 606
1296 ,כסרא-סמיע ,284 ,רח 1906
1296 ,כסרא-סמיע ,150 ,רח 516
1296 ,כסרא-סמיע ,261 ,רח 86556
1296 ,כסרא-סמיע ,199 ,רח 1007
1296 ,כסרא-סמיע ,116 ,רח 207
1296 ,כסרא-סמיע ,215 ,רח 1207
1296 ,כסרא-סמיע ,129 ,רח 407
1296 ,כסרא-סמיע ,243 ,רח 1407
1296 ,כסרא-סמיע ,184 ,רח 707
1296 ,כסרא-סמיע ,286 ,רח 1907
1296 ,כסרא-סמיע ,262 ,רח 17
1296 ,כסרא-סמיע ,200 ,רח 1008
1296 ,כסרא-סמיע ,117 ,רח 208
1296 ,כסרא-סמיע ,216 ,רח 1208
1296 ,כסרא-סמיע ,229 ,רח 1308
1296 ,כסרא-סמיע ,244 ,רח 1408
1296 ,כסרא-סמיע ,142 ,רח 508
1296 ,כסרא-סמיע ,185 ,רח 708
1296 ,כסרא-סמיע ,289 ,רח 1908
1296 ,כסרא-סמיע ,269 ,רח 18
1296 ,כסרא-סמיע ,118 ,רח 209
1296 ,כסרא-סמיע ,245 ,רח 1409
1296 ,כסרא-סמיע ,143 ,רח 509
1296 ,כסרא-סמיע ,170 ,רח 609
1296 ,כסרא-סמיע ,186 ,רח 709
1296 ,כסרא-סמיע ,287 ,רח 1909
1296 ,כסרא-סמיע ,276 ,רח 19
978 ,כעביה-טבאש-חג'אג'רה ,105 ,אבו בכר אלסדיק
978 ,כעביה-טבאש-חג'אג'רה ,117 ,אבו-נסור
978 ,כעביה-טבאש-חג'אג'רה ,106 ,אלבוטמה
978 ,כעביה-טבאש-חג'אג'רה ,107 ,אלג'אמע
978 ,כעביה-טבאש-חג'אג'רה ,120 ,אלזהור
978 ,כעביה-טבאש-חג'אג'רה ,121 ,אלזייתון
978 ,כעביה-טבאש-חג'אג'רה ,108 ,אלחנוה
978 ,כעביה-טבאש-חג'אג'רה ,109 ,אלטואחין
978 ,כעביה-טבאש-חג'אג'רה ,122 ,אלטף
978 ,כעביה-טבאש-חג'אג'רה ,118 ,אליאסמין
978 ,כעביה-טבאש-חג'אג'רה ,123 ,אלכלה
978 ,כעביה-טבאש-חג'אג'רה ,110 ,אלמלעב
978 ,כעביה-טבאש-חג'אג'רה ,104 ,אלנח'יל
978 ,כעביה-טבאש-חג'אג'רה ,119 ,אלנרג'ס
978 ,כעביה-טבאש-חג'אג'רה ,124 ,אלסדרה
978 ,כעביה-טבאש-חג'אג'רה ,111 ,אלסודאני
978 ,כעביה-טבאש-חג'אג'רה ,113 ,אלסנובר
978 ,כעביה-טבאש-חג'אג'רה ,125 ,אלסריס
978 ,כעביה-טבאש-חג'אג'רה ,126 ,אלעאסי
978 ,כעביה-טבאש-חג'אג'רה ,127 ,אלעין
978 ,כעביה-טבאש-חג'אג'רה ,128 ,אלראס
978 ,כעביה-טבאש-חג'אג'רה ,129 ,אלרחמה
978 ,כעביה-טבאש-חג'אג'רה ,130 ,אלתלה
978 ,כעביה-טבאש-חג'אג'רה ,131 ,ח'ראמה
978 ,כעביה-טבאש-חג'אג'רה ,103 ,חג'אג'רה
978 ,כעביה-טבאש-חג'אג'רה ,102 ,טבאש
978 ,כעביה-טבאש-חג'אג'רה ,101 ,כעביה
978 ,כעביה-טבאש-חג'אג'רה ,9000 ,כעביה-טבאש-חג'אג'רה
978 ,כעביה-טבאש-חג'אג'רה ,115 ,עין אם עלי
978 ,כעביה-טבאש-חג'אג'רה ,153 ,רח 100
978 ,כעביה-טבאש-חג'אג'רה ,152 ,רח 10
978 ,כעביה-טבאש-חג'אג'רה ,164 ,רח 110
978 ,כעביה-טבאש-חג'אג'רה ,137 ,רח 20
978 ,כעביה-טבאש-חג'אג'רה ,175 ,רח 120
978 ,כעביה-טבאש-חג'אג'רה ,185 ,רח 130
978 ,כעביה-טבאש-חג'אג'רה ,195 ,רח 140
978 ,כעביה-טבאש-חג'אג'רה ,141 ,רח 50
978 ,כעביה-טבאש-חג'אג'רה ,206 ,רח 150
978 ,כעביה-טבאש-חג'אג'רה ,245 ,רח 60
978 ,כעביה-טבאש-חג'אג'רה ,216 ,רח 160
978 ,כעביה-טבאש-חג'אג'רה ,255 ,רח 70
978 ,כעביה-טבאש-חג'אג'רה ,225 ,רח 270
978 ,כעביה-טבאש-חג'אג'רה ,146 ,רח 80
978 ,כעביה-טבאש-חג'אג'רה ,235 ,רח 180
978 ,כעביה-טבאש-חג'אג'רה ,151 ,רח 1
978 ,כעביה-טבאש-חג'אג'רה ,154 ,רח 101
978 ,כעביה-טבאש-חג'אג'רה ,163 ,רח 11
978 ,כעביה-טבאש-חג'אג'רה ,165 ,רח 111
978 ,כעביה-טבאש-חג'אג'רה ,138 ,רח 21
978 ,כעביה-טבאש-חג'אג'רה ,176 ,רח 121
978 ,כעביה-טבאש-חג'אג'רה ,186 ,רח 131
978 ,כעביה-טבאש-חג'אג'רה ,196 ,רח 141
978 ,כעביה-טבאש-חג'אג'רה ,142 ,רח 51
978 ,כעביה-טבאש-חג'אג'רה ,207 ,רח 151
978 ,כעביה-טבאש-חג'אג'רה ,246 ,רח 61
978 ,כעביה-טבאש-חג'אג'רה ,217 ,רח 161
978 ,כעביה-טבאש-חג'אג'רה ,256 ,רח 71
978 ,כעביה-טבאש-חג'אג'רה ,226 ,רח 171
978 ,כעביה-טבאש-חג'אג'רה ,267 ,רח 81
978 ,כעביה-טבאש-חג'אג'רה ,236 ,רח 181
978 ,כעביה-טבאש-חג'אג'רה ,239 ,רח 2
978 ,כעביה-טבאש-חג'אג'רה ,155 ,רח 102
978 ,כעביה-טבאש-חג'אג'רה ,174 ,רח 12
978 ,כעביה-טבאש-חג'אג'רה ,166 ,רח 112
978 ,כעביה-טבאש-חג'אג'רה ,139 ,רח 22
978 ,כעביה-טבאש-חג'אג'רה ,177 ,רח 122
978 ,כעביה-טבאש-חג'אג'רה ,187 ,רח 132
978 ,כעביה-טבאש-חג'אג'רה ,197 ,רח 142
978 ,כעביה-טבאש-חג'אג'רה ,143 ,רח 52
978 ,כעביה-טבאש-חג'אג'רה ,208 ,רח 152
978 ,כעביה-טבאש-חג'אג'רה ,247 ,רח 62
978 ,כעביה-טבאש-חג'אג'רה ,257 ,רח 72
978 ,כעביה-טבאש-חג'אג'רה ,227 ,רח 172
978 ,כעביה-טבאש-חג'אג'רה ,147 ,רח 82
978 ,כעביה-טבאש-חג'אג'רה ,237 ,רח 182
978 ,כעביה-טבאש-חג'אג'רה ,240 ,רח 3
978 ,כעביה-טבאש-חג'אג'רה ,156 ,רח 103
978 ,כעביה-טבאש-חג'אג'רה ,132 ,רח 13
978 ,כעביה-טבאש-חג'אג'רה ,167 ,רח 113
978 ,כעביה-טבאש-חג'אג'רה ,178 ,רח 123
978 ,כעביה-טבאש-חג'אג'רה ,188 ,רח 133
978 ,כעביה-טבאש-חג'אג'רה ,198 ,רח 143
978 ,כעביה-טבאש-חג'אג'רה ,144 ,רח 53
978 ,כעביה-טבאש-חג'אג'רה ,209 ,רח 153
978 ,כעביה-טבאש-חג'אג'רה ,219 ,רח 163
978 ,כעביה-טבאש-חג'אג'רה ,258 ,רח 73
978 ,כעביה-טבאש-חג'אג'רה ,228 ,רח 173
978 ,כעביה-טבאש-חג'אג'רה ,148 ,רח 83
978 ,כעביה-טבאש-חג'אג'רה ,140 ,רח 4
978 ,כעביה-טבאש-חג'אג'רה ,157 ,רח 104
978 ,כעביה-טבאש-חג'אג'רה ,133 ,רח 14
978 ,כעביה-טבאש-חג'אג'רה ,168 ,רח 114
978 ,כעביה-טבאש-חג'אג'רה ,179 ,רח 124
978 ,כעביה-טבאש-חג'אג'רה ,189 ,רח 134
978 ,כעביה-טבאש-חג'אג'רה ,210 ,רח 154
978 ,כעביה-טבאש-חג'אג'רה ,248 ,רח 64
978 ,כעביה-טבאש-חג'אג'רה ,220 ,רח 164
978 ,כעביה-טבאש-חג'אג'רה ,259 ,רח 74
978 ,כעביה-טבאש-חג'אג'רה ,229 ,רח 174
978 ,כעביה-טבאש-חג'אג'רה ,149 ,רח 84
978 ,כעביה-טבאש-חג'אג'רה ,158 ,רח 105
978 ,כעביה-טבאש-חג'אג'רה ,205 ,רח 15
978 ,כעביה-טבאש-חג'אג'רה ,169 ,רח 115
978 ,כעביה-טבאש-חג'אג'רה ,180 ,רח 125
978 ,כעביה-טבאש-חג'אג'רה ,190 ,רח 135
978 ,כעביה-טבאש-חג'אג'רה ,200 ,רח 145
978 ,כעביה-טבאש-חג'אג'רה ,145 ,רח 55
978 ,כעביה-טבאש-חג'אג'רה ,249 ,רח 65
978 ,כעביה-טבאש-חג'אג'רה ,221 ,רח 165
978 ,כעביה-טבאש-חג'אג'רה ,260 ,רח 75
978 ,כעביה-טבאש-חג'אג'רה ,230 ,רח 175
978 ,כעביה-טבאש-חג'אג'רה ,159 ,רח 106
978 ,כעביה-טבאש-חג'אג'רה ,134 ,רח 16
978 ,כעביה-טבאש-חג'אג'רה ,170 ,רח 116
978 ,כעביה-טבאש-חג'אג'רה ,181 ,רח 126
978 ,כעביה-טבאש-חג'אג'רה ,191 ,רח 136
978 ,כעביה-טבאש-חג'אג'רה ,201 ,רח 146
978 ,כעביה-טבאש-חג'אג'רה ,241 ,רח 56
978 ,כעביה-טבאש-חג'אג'רה ,212 ,רח 156
978 ,כעביה-טבאש-חג'אג'רה ,250 ,רח 66
978 ,כעביה-טבאש-חג'אג'רה ,261 ,רח 76
978 ,כעביה-טבאש-חג'אג'רה ,231 ,רח 176
978 ,כעביה-טבאש-חג'אג'רה ,150 ,רח 86
978 ,כעביה-טבאש-חג'אג'רה ,254 ,רח 7
978 ,כעביה-טבאש-חג'אג'רה ,160 ,רח 107
978 ,כעביה-טבאש-חג'אג'רה ,135 ,רח 17
978 ,כעביה-טבאש-חג'אג'רה ,171 ,רח 117
978 ,כעביה-טבאש-חג'אג'רה ,182 ,רח 127
978 ,כעביה-טבאש-חג'אג'רה ,192 ,רח 137
978 ,כעביה-טבאש-חג'אג'רה ,202 ,רח 147
978 ,כעביה-טבאש-חג'אג'רה ,242 ,רח 57
978 ,כעביה-טבאש-חג'אג'רה ,213 ,רח 157
978 ,כעביה-טבאש-חג'אג'רה ,251 ,רח 67
978 ,כעביה-טבאש-חג'אג'רה ,222 ,רח 167
978 ,כעביה-טבאש-חג'אג'רה ,262 ,רח 77
978 ,כעביה-טבאש-חג'אג'רה ,232 ,רח 177
978 ,כעביה-טבאש-חג'אג'רה ,265 ,רח 8
978 ,כעביה-טבאש-חג'אג'רה ,161 ,רח 108
978 ,כעביה-טבאש-חג'אג'רה ,136 ,רח 18
978 ,כעביה-טבאש-חג'אג'רה ,172 ,רח 118
978 ,כעביה-טבאש-חג'אג'רה ,183 ,רח 128
978 ,כעביה-טבאש-חג'אג'רה ,193 ,רח 138
978 ,כעביה-טבאש-חג'אג'רה ,203 ,רח 148
978 ,כעביה-טבאש-חג'אג'רה ,243 ,רח 58
978 ,כעביה-טבאש-חג'אג'רה ,214 ,רח 158
978 ,כעביה-טבאש-חג'אג'רה ,252 ,רח 68
978 ,כעביה-טבאש-חג'אג'רה ,223 ,רח 168
978 ,כעביה-טבאש-חג'אג'רה ,263 ,רח 78
978 ,כעביה-טבאש-חג'אג'רה ,233 ,רח 178
978 ,כעביה-טבאש-חג'אג'רה ,268 ,רח 9
978 ,כעביה-טבאש-חג'אג'רה ,162 ,רח 109
978 ,כעביה-טבאש-חג'אג'רה ,238 ,רח 19
978 ,כעביה-טבאש-חג'אג'רה ,173 ,רח 119
978 ,כעביה-טבאש-חג'אג'רה ,184 ,רח 129
978 ,כעביה-טבאש-חג'אג'רה ,194 ,רח 139
978 ,כעביה-טבאש-חג'אג'רה ,204 ,רח 149
978 ,כעביה-טבאש-חג'אג'רה ,244 ,רח 59
978 ,כעביה-טבאש-חג'אג'רה ,215 ,רח 159
978 ,כעביה-טבאש-חג'אג'רה ,253 ,רח 69
978 ,כעביה-טבאש-חג'אג'רה ,224 ,רח 169
978 ,כעביה-טבאש-חג'אג'רה ,264 ,רח 79
978 ,כעביה-טבאש-חג'אג'רה ,234 ,רח 179
978 ,כעביה-טבאש-חג'אג'רה ,199 ,רח 199
857 ,כפר אביב ,104 ,הגפן
857 ,כפר אביב ,101 ,המייסדים
857 ,כפר אביב ,103 ,התאנה
857 ,כפר אביב ,102 ,התמר
857 ,כפר אביב ,9000 ,כפר אביב
3638 ,כפר אדומים ,116 ,ארץ בנימין
3638 ,כפר אדומים ,127 ,ארץ הצבי
3638 ,כפר אדומים ,137 ,ארץ חיטה
3638 ,כפר אדומים ,112 ,בורות המים
3638 ,כפר אדומים ,118 ,גבול יהודה
3638 ,כפר אדומים ,142 ,דבש התאנה
3638 ,כפר אדומים ,101 ,דרך אלון
3638 ,כפר אדומים ,143 ,דרך החיים
3638 ,כפר אדומים ,119 ,דרך המדבר
3638 ,כפר אדומים ,133 ,דרך המשמר
3638 ,כפר אדומים ,124 ,דרך הצופים
3638 ,כפר אדומים ,103 ,דרך פרת
3638 ,כפר אדומים ,123 ,האוג
3638 ,כפר אדומים ,152 ,האופק
3638 ,כפר אדומים ,131 ,האשל
3638 ,כפר אדומים ,151 ,הבקעה
3638 ,כפר אדומים ,117 ,הירדן
3638 ,כפר אדומים ,110 ,המייסדים
3638 ,כפר אדומים ,113 ,המעיין
3638 ,כפר אדומים ,154 ,המצוק
3638 ,כפר אדומים ,150 ,הנחל
3638 ,כפר אדומים ,153 ,הסלע
3638 ,כפר אדומים ,155 ,הצבאים
3638 ,כפר אדומים ,121 ,הצור
3638 ,כפר אדומים ,156 ,הקשת
3638 ,כפר אדומים ,126 ,הרותם
3638 ,כפר אדומים ,125 ,השיזף
3638 ,כפר אדומים ,130 ,השיטה
3638 ,כפר אדומים ,109 ,חגווי הסלע
3638 ,כפר אדומים ,138 ,חמדת השקד
3638 ,כפר אדומים ,139 ,כפות תמרים
3638 ,כפר אדומים ,9000 ,כפר אדומים
3638 ,כפר אדומים ,129 ,מבוא ירושלים
3638 ,כפר אדומים ,111 ,מי מבוע
3638 ,כפר אדומים ,102 ,מרכז קליטה
3638 ,כפר אדומים ,132 ,משכנות הרועים
3638 ,כפר אדומים ,122 ,משעול החלמיש
3638 ,כפר אדומים ,135 ,מתק חרוב
3638 ,כפר אדומים ,115 ,נוף בראשית
3638 ,כפר אדומים ,6501 ,נופי פרת
3638 ,כפר אדומים ,105 ,נחל אשלים
3638 ,כפר אדומים ,106 ,נחל ערוגות
3638 ,כפר אדומים ,104 ,נחל צאלים
3638 ,כפר אדומים ,107 ,נחל קדם
3638 ,כפר אדומים ,120 ,סתר המדרגה
3638 ,כפר אדומים ,114 ,עם אחד
3638 ,כפר אדומים ,128 ,ערבות מואב
3638 ,כפר אדומים ,136 ,פלח הרימון
3638 ,כפר אדומים ,144 ,פרי הגפן
3638 ,כפר אדומים ,134 ,צמח בר
3638 ,כפר אדומים ,140 ,שירת העשבים
3638 ,כפר אדומים ,6500 ,שכ אלון
3638 ,כפר אדומים ,141 ,שתילי הזית
364 ,כפר אוריה ,109 ,ארץ קדם
364 ,כפר אוריה ,102 ,דרך שמשון
364 ,כפר אוריה ,104 ,הבז האדום
364 ,כפר אוריה ,110 ,הגתות
364 ,כפר אוריה ,105 ,החיוואי
364 ,כפר אוריה ,115 ,הירגזי
364 ,כפר אוריה ,113 ,הכחל
364 ,כפר אוריה ,114 ,יערת הדבש
364 ,כפר אוריה ,111 ,כפיר אריה
364 ,כפר אוריה ,9000 ,כפר אוריה
364 ,כפר אוריה ,103 ,כרם השועלים
364 ,כפר אוריה ,107 ,כרמי תמנתה
364 ,כפר אוריה ,101 ,מעלה אוריה
364 ,כפר אוריה ,108 ,סלע עיטם
364 ,כפר אוריה ,106 ,קציר חיטים
364 ,כפר אוריה ,112 ,שבט דן
690 ,כפר אחים ,9000 ,כפר אחים
690 ,כפר אחים ,101 ,שכ קרוונים
220 ,כפר ביאליק ,112 ,אושה
220 ,כפר ביאליק ,107 ,ביאליק
220 ,כפר ביאליק ,102 ,החקלאים
220 ,כפר ביאליק ,103 ,הכלניות
220 ,כפר ביאליק ,9000 ,כפר ביאליק
220 ,כפר ביאליק ,110 ,לנדאור
220 ,כפר ביאליק ,101 ,מוסד אהבה
220 ,כפר ביאליק ,104 ,מיכאל
220 ,כפר ביאליק ,105 ,סולד הנריאטה
220 ,כפר ביאליק ,113 ,עמיהוד
220 ,כפר ביאליק ,111 ,שד ירושלים
177 ,כפר ביל"ו ,102 ,הגבעה
177 ,כפר ביל"ו ,103 ,הדבש
177 ,כפר ביל"ו ,119 ,הדקל
177 ,כפר ביל"ו ,104 ,ההדרים
177 ,כפר ביל"ו ,105 ,הזית
177 ,כפר ביל"ו ,118 ,המחרשה
177 ,כפר ביל"ו ,107 ,המייסדים
177 ,כפר ביל"ו ,108 ,הפעמון
177 ,כפר ביל"ו ,109 ,הפקאן
177 ,כפר ביל"ו ,110 ,הפרדסים
177 ,כפר ביל"ו ,111 ,הפרחים
177 ,כפר ביל"ו ,112 ,השעורה
177 ,כפר ביל"ו ,113 ,התאנה
177 ,כפר ביל"ו ,106 ,חבורת הדרום
177 ,כפר ביל"ו ,9000 ,כפר ביל"ו
177 ,כפר ביל"ו ,101 ,סמ גפן
177 ,כפר ביל"ו ,117 ,סמ חיטה
177 ,כפר ביל"ו ,116 ,סמ רימון
177 ,כפר ביל"ו ,115 ,סמ תמר
177 ,כפר ביל"ו ,6500 ,שיכון בנים
177 ,כפר ביל"ו ,114 ,תלמים
357 ,כפר בלום ,9000 ,כפר בלום
2010 ,כפר בן נון ,101 ,הגפן
2010 ,כפר בן נון ,102 ,הרימון
2010 ,כפר בן נון ,104 ,התאנה
2010 ,כפר בן נון ,103 ,התמר
2010 ,כפר בן נון ,9000 ,כפר בן נון
633 ,כפר ברא ,111 ,אבו עובדיה
633 ,כפר ברא ,104 ,אבן ח'לדון
633 ,כפר ברא ,120 ,אבן סינא
633 ,כפר ברא ,101 ,אבן רושד
633 ,כפר ברא ,126 ,אלאקסא
633 ,כפר ברא ,105 ,אלבוסתאן
633 ,כפר ברא ,137 ,אלבוראק
633 ,כפר ברא ,127 ,אלביאן
633 ,כפר ברא ,114 ,אלג'זאלי
633 ,כפר ברא ,128 ,אלהודא
633 ,כפר ברא ,121 ,אלוואדי
633 ,כפר ברא ,129 ,אלוויאם
633 ,כפר ברא ,112 ,אלזהרא
633 ,כפר ברא ,106 ,אלזיתון
633 ,כפר ברא ,122 ,אלחרם
633 ,כפר ברא ,130 ,אלירמוק
633 ,כפר ברא ,124 ,אלמדינה
633 ,כפר ברא ,131 ,אלמנארה
633 ,כפר ברא ,113 ,אלנור
633 ,כפר ברא ,110 ,אלסלאם
633 ,כפר ברא ,138 ,אלפוראת
633 ,כפר ברא ,117 ,אלקובה
633 ,כפר ברא ,125 ,אלקודס
633 ,כפר ברא ,119 ,אלראזי
633 ,כפר ברא ,132 ,אלרבאט
633 ,כפר ברא ,115 ,אלרוודה
633 ,כפר ברא ,103 ,אלרחמה
633 ,כפר ברא ,133 ,אלשאם
633 ,כפר ברא ,134 ,בגדאד
633 ,כפר ברא ,135 ,בדר
633 ,כפר ברא ,140 ,בילאל בן רבאח
633 ,כפר ברא ,139 ,דג'לה
633 ,כפר ברא ,116 ,חטין
633 ,כפר ברא ,107 ,חיפה
633 ,כפר ברא ,108 ,יפו
633 ,כפר ברא ,9000 ,כפר ברא
633 ,כפר ברא ,118 ,מכה
633 ,כפר ברא ,136 ,עומר אלמוכתאר
633 ,כפר ברא ,109 ,עכו
633 ,כפר ברא ,102 ,צלאח אלדין
132 ,כפר ברוך ,104 ,אגמית
132 ,כפר ברוך ,106 ,חופית
132 ,כפר ברוך ,9000 ,כפר ברוך
132 ,כפר ברוך ,101 ,לבנית
132 ,כפר ברוך ,109 ,מגלן
132 ,כפר ברוך ,105 ,סייפן
132 ,כפר ברוך ,107 ,סיקסק
132 ,כפר ברוך ,103 ,סלעית
132 ,כפר ברוך ,102 ,עיטם
132 ,כפר ברוך ,108 ,שקנאי
106 ,כפר גדעון ,101 ,בית ראשון במולדת
106 ,כפר גדעון ,104 ,גפן
106 ,כפר גדעון ,107 ,זית
106 ,כפר גדעון ,102 ,חיטה
106 ,כפר גדעון ,9000 ,כפר גדעון
106 ,כפר גדעון ,106 ,רימון
106 ,כפר גדעון ,103 ,שעורה
106 ,כפר גדעון ,105 ,תאנה
106 ,כפר גדעון ,108 ,תמר
427 ,כפר גלים ,9000 ,כפר גלים
310 ,כפר גליקסון ,9000 ,כפר גליקסון
76 ,כפר גלעדי ,101 ,בית ראשון
76 ,כפר גלעדי ,9000 ,כפר גלעדי
76 ,כפר גלעדי ,7001 ,תל חי
707 ,כפר דניאל ,101 ,ברוש
707 ,כפר דניאל ,102 ,גפן
707 ,כפר דניאל ,103 ,זית
707 ,כפר דניאל ,104 ,חרוב
707 ,כפר דניאל ,9000 ,כפר דניאל
707 ,כפר דניאל ,105 ,מייסדים
707 ,כפר דניאל ,106 ,רימון
707 ,כפר דניאל ,107 ,שיטה
707 ,כפר דניאל ,108 ,תאנה
707 ,כפר דניאל ,109 ,תמר
3796 ,כפר האורנים ,113 ,אהוד
3796 ,כפר האורנים ,101 ,האופק
3796 ,כפר האורנים ,106 ,האורנים
3796 ,כפר האורנים ,112 ,הגבעה
3796 ,כפר האורנים ,109 ,החורש
3796 ,כפר האורנים ,110 ,היער
3796 ,כפר האורנים ,118 ,המנורה
3796 ,כפר האורנים ,115 ,המעיין
3796 ,כפר האורנים ,103 ,העמק
3796 ,כפר האורנים ,117 ,הפסגה
3796 ,כפר האורנים ,102 ,הרכס
3796 ,כפר האורנים ,104 ,יהודה
3796 ,כפר האורנים ,111 ,יפה נוף
3796 ,כפר האורנים ,9000 ,כפר האורנים
3796 ,כפר האורנים ,105 ,נחל מודיעין
192 ,כפר החורש ,109 ,אילה
192 ,כפר החורש ,118 ,אפיק
192 ,כפר החורש ,104 ,אשחר
192 ,כפר החורש ,107 ,גורדוניה
192 ,כפר החורש ,113 ,דקל
192 ,כפר החורש ,108 ,זמיר
192 ,כפר החורש ,114 ,חצב
192 ,כפר החורש ,105 ,כלנית
192 ,כפר החורש ,9000 ,כפר החורש
192 ,כפר החורש ,103 ,כרכום
192 ,כפר החורש ,121 ,לאן
192 ,כפר החורש ,101 ,מגורונים
192 ,כפר החורש ,116 ,מהלך
192 ,כפר החורש ,102 ,מכבי הצעיר
192 ,כפר החורש ,111 ,סנונית
192 ,כפר החורש ,112 ,עופר
192 ,כפר החורש ,117 ,עמיעז
192 ,כפר החורש ,119 ,ענבר
192 ,כפר החורש ,110 ,צבר
192 ,כפר החורש ,120 ,קינן
192 ,כפר החורש ,106 ,רימון
192 ,כפר החורש ,122 ,שש
192 ,כפר החורש ,123 ,תוכי נשר
192 ,כפר החורש ,115 ,תרצה
254 ,כפר המכבי ,9000 ,כפר המכבי
582 ,כפר הנגיד ,102 ,האלה
582 ,כפר הנגיד ,105 ,האלון
582 ,כפר הנגיד ,106 ,הארז
582 ,כפר הנגיד ,107 ,הדולב
582 ,כפר הנגיד ,103 ,הזית
582 ,כפר הנגיד ,104 ,החרוב
582 ,כפר הנגיד ,101 ,הרותם
582 ,כפר הנגיד ,108 ,השיזף
582 ,כפר הנגיד ,109 ,השיקמה
582 ,כפר הנגיד ,9000 ,כפר הנגיד
890 ,כפר הנוער הדתי ,9000 ,כפר הנוער הדתי
443 ,כפר הנשיא ,9000 ,כפר הנשיא
187 ,כפר הס ,103 ,ארגון יזרעאל
187 ,כפר הס ,104 ,דרך ההרחבה
187 ,כפר הס ,102 ,דרך ההתיישבות
187 ,כפר הס ,111 ,דרך השדות
187 ,כפר הס ,114 ,האסיף
187 ,כפר הס ,116 ,הבוסתן
187 ,כפר הס ,113 ,הבציר
187 ,כפר הס ,106 ,הבריכה
187 ,כפר הס ,109 ,הגורן
187 ,כפר הס ,101 ,ההדרים
187 ,כפר הס ,110 ,הזיתים
187 ,כפר הס ,117 ,הכרם
187 ,כפר הס ,105 ,המחרשה
187 ,כפר הס ,108 ,העמדה
187 ,כפר הס ,115 ,הקוצר
187 ,כפר הס ,112 ,הקטיף
187 ,כפר הס ,107 ,השדרה
187 ,כפר הס ,9000 ,כפר הס
217 ,כפר הרא"ה ,102 ,בית חזון
217 ,כפר הרא"ה ,110 ,דרך תורה ועבודה
217 ,כפר הרא"ה ,116 ,הישיבה
217 ,כפר הרא"ה ,115 ,העמק
217 ,כפר הרא"ה ,114 ,הראשונים
217 ,כפר הרא"ה ,113 ,הרב צוקרמן
217 ,כפר הרא"ה ,111 ,חזון
217 ,כפר הרא"ה ,101 ,ישיבת בני עקיבא
217 ,כפר הרא"ה ,9000 ,כפר הרא"ה
217 ,כפר הרא"ה ,112 ,משעול הגבעה
888 ,כפר הרי"ף ,104 ,האלון
888 ,כפר הרי"ף ,102 ,הזית
888 ,כפר הרי"ף ,6500 ,הרחבה
888 ,כפר הרי"ף ,101 ,הרימון
888 ,כפר הרי"ף ,105 ,השקד
888 ,כפר הרי"ף ,106 ,התמר
888 ,כפר הרי"ף ,9000 ,כפר הרי"ף
888 ,כפר הרי"ף ,108 ,סמטת החרוב
888 ,כפר הרי"ף ,107 ,סמטת התאנה
888 ,כפר הרי"ף ,103 ,שדרת האילנות
190 ,כפר ויתקין ,101 ,אכסנית נוער
190 ,כפר ויתקין ,122 ,דרך האי
190 ,כפר ויתקין ,125 ,דרך הבאר
190 ,כפר ויתקין ,120 ,דרך החול
190 ,כפר ויתקין ,124 ,דרך הכפר
190 ,כפר ויתקין ,116 ,דרך הנחל
190 ,כפר ויתקין ,129 ,דרך מול הים
190 ,כפר ויתקין ,126 ,הדואר
190 ,כפר ויתקין ,103 ,הדוגית
190 ,כפר ויתקין ,121 ,הזנב
190 ,כפר ויתקין ,104 ,הלגונה
190 ,כפר ויתקין ,105 ,המגדלור
190 ,כפר ויתקין ,106 ,המזח
190 ,כפר ויתקין ,127 ,המחלבה
190 ,כפר ויתקין ,107 ,המפרש
190 ,כפר ויתקין ,108 ,הסיפון
190 ,כפר ויתקין ,109 ,הסירה
190 ,כפר ויתקין ,102 ,העוגן
190 ,כפר ויתקין ,110 ,הצדף
190 ,כפר ויתקין ,111 ,הקונכיה
190 ,כפר ויתקין ,115 ,הראשונים
190 ,כפר ויתקין ,112 ,השונית
190 ,כפר ויתקין ,123 ,כביש עוקף דרום
190 ,כפר ויתקין ,9000 ,כפר ויתקין
190 ,כפר ויתקין ,128 ,משעול גנים
190 ,כפר ויתקין ,130 ,משעול הפרדסים
190 ,כפר ויתקין ,131 ,סמ המרפאה
190 ,כפר ויתקין ,117 ,סמטת הכ"ד
320 ,כפר ורבורג ,102 ,הבאר
320 ,כפר ורבורג ,103 ,הבנים
320 ,כפר ורבורג ,104 ,הגן
320 ,כפר ורבורג ,105 ,ההרחבה
320 ,כפר ורבורג ,106 ,הזורעים
320 ,כפר ורבורג ,107 ,המחלבה
320 ,כפר ורבורג ,108 ,המחרשה
320 ,כפר ורבורג ,109 ,המייסדים
320 ,כפר ורבורג ,110 ,הממשיכים
320 ,כפר ורבורג ,111 ,העליה
320 ,כפר ורבורג ,9000 ,כפר ורבורג
320 ,כפר ורבורג ,120 ,סמ האפרסמון
320 ,כפר ורבורג ,121 ,סמ האפרסק
320 ,כפר ורבורג ,122 ,סמ הגפן
320 ,כפר ורבורג ,123 ,סמ הדגן
320 ,כפר ורבורג ,124 ,סמ הזית
320 ,כפר ורבורג ,125 ,סמ החיטה
320 ,כפר ורבורג ,126 ,סמ החרוב
320 ,כפר ורבורג ,127 ,סמ הרימון
320 ,כפר ורבורג ,128 ,סמ השיבולת
320 ,כפר ורבורג ,129 ,סמ השעורה
320 ,כפר ורבורג ,130 ,סמ התאנה
320 ,כפר ורבורג ,131 ,סמ התבואה
320 ,כפר ורבורג ,132 ,סמ התפוח
320 ,כפר ורבורג ,101 ,שכ קרוונים
1263 ,כפר ורדים ,187 ,אבני החושן
1263 ,כפר ורדים ,162 ,אדיר
1263 ,כפר ורדים ,191 ,אודם
1263 ,כפר ורדים ,140 ,אירוס
1263 ,כפר ורדים ,120 ,אלמוג
1263 ,כפר ורדים ,142 ,ארבל
1263 ,כפר ורדים ,116 ,אשכר
1263 ,כפר ורדים ,194 ,ברקת
1263 ,כפר ורדים ,180 ,בשור
1263 ,כפר ורדים ,144 ,גומא
1263 ,כפר ורדים ,119 ,גל
1263 ,כפר ורדים ,160 ,גלבוע
1263 ,כפר ורדים ,176 ,געתון
1263 ,כפר ורדים ,133 ,גפן
1263 ,כפר ורדים ,172 ,דישון
1263 ,כפר ורדים ,112 ,דפנה
1263 ,כפר ורדים ,156 ,דרור
1263 ,כפר ורדים ,136 ,דרך הגליל
1263 ,כפר ורדים ,126 ,דרך ההר
1263 ,כפר ורדים ,186 ,דרך הורדים
1263 ,כפר ורדים ,121 ,האורן
1263 ,כפר ורדים ,106 ,האלה
1263 ,כפר ורדים ,103 ,האלון
1263 ,כפר ורדים ,122 ,הארז
1263 ,כפר ורדים ,105 ,הדולב
1263 ,כפר ורדים ,148 ,הדס
1263 ,כפר ורדים ,118 ,החצב
1263 ,כפר ורדים ,123 ,החרוב
1263 ,כפר ורדים ,143 ,הרדוף
1263 ,כפר ורדים ,174 ,זויתן
1263 ,כפר ורדים ,130 ,זית
1263 ,כפר ורדים ,169 ,זמורה
1263 ,כפר ורדים ,155 ,זמיר
1263 ,כפר ורדים ,150 ,חבצלת
1263 ,כפר ורדים ,153 ,חוחית
1263 ,כפר ורדים ,134 ,חיטה
1263 ,כפר ורדים ,125 ,חירם
1263 ,כפר ורדים ,108 ,חרמון
1263 ,כפר ורדים ,117 ,חרצית
1263 ,כפר ורדים ,190 ,יהלום
1263 ,כפר ורדים ,110 ,יערה
1263 ,כפר ורדים ,149 ,יקינטון
1263 ,כפר ורדים ,173 ,ירדן
1263 ,כפר ורדים ,171 ,כזיב
1263 ,כפר ורדים ,9000 ,כפר ורדים
1263 ,כפר ורדים ,168 ,כרם
1263 ,כפר ורדים ,147 ,כרמל
1263 ,כפר ורדים ,167 ,לבונה
1263 ,כפר ורדים ,141 ,לוטם
1263 ,כפר ורדים ,195 ,לשם
1263 ,כפר ורדים ,177 ,מוסקט
1263 ,כפר ורדים ,166 ,מור
1263 ,כפר ורדים ,109 ,מורן
1263 ,כפר ורדים ,161 ,מירון
1263 ,כפר ורדים ,113 ,מרווה
1263 ,כפר ורדים ,124 ,משעול הכלנית
1263 ,כפר ורדים ,101 ,משעול הכרכום
1263 ,כפר ורדים ,114 ,משעול הנורית
1263 ,כפר ורדים ,102 ,משעול הנרקיס
1263 ,כפר ורדים ,115 ,משעול הסחלב
1263 ,כפר ורדים ,107 ,משעול הרקפת
1263 ,כפר ורדים ,146 ,משעול ורד
1263 ,כפר ורדים ,189 ,נופך
1263 ,כפר ורדים ,175 ,נעמן
1263 ,כפר ורדים ,170 ,סער
1263 ,כפר ורדים ,192 ,ספיר
1263 ,כפר ורדים ,181 ,עין גדי
1263 ,כפר ורדים ,154 ,עפרוני
1263 ,כפר ורדים ,145 ,ערבה
1263 ,כפר ורדים ,184 ,ערוגות
1263 ,כפר ורדים ,185 ,פארן
1263 ,כפר ורדים ,183 ,צאלים
1263 ,כפר ורדים ,182 ,צין
1263 ,כפר ורדים ,165 ,קורנית
1263 ,כפר ורדים ,104 ,רותם
1263 ,כפר ורדים ,164 ,ריחן
1263 ,כפר ורדים ,132 ,רימון
1263 ,כפר ורדים ,188 ,שוהם
1263 ,כפר ורדים ,127 ,שונית
1263 ,כפר ורדים ,163 ,שמיר
1263 ,כפר ורדים ,111 ,שניר
1263 ,כפר ורדים ,151 ,שקד
1263 ,כפר ורדים ,178 ,שריג
1263 ,כפר ורדים ,131 ,תאנה
1263 ,כפר ורדים ,152 ,תבור
1263 ,כפר ורדים ,135 ,תמר
1263 ,כפר ורדים ,193 ,תרשיש
1325 ,כפר זוהרים ,9000 ,כפר זוהרים
786 ,כפר זיתים ,107 ,דרך השלום
786 ,כפר זיתים ,103 ,האירוסים
786 ,כפר זיתים ,104 ,האלה
786 ,כפר זיתים ,102 ,ההדסים
786 ,כפר זיתים ,109 ,המייסדים
786 ,כפר זיתים ,108 ,כוכב יאיר
786 ,כפר זיתים ,9000 ,כפר זיתים
786 ,כפר זיתים ,105 ,משעול הזית
786 ,כפר זיתים ,101 ,נוף הצוק
786 ,כפר זיתים ,106 ,תמר
696 ,כפר חב"ד ,144 ,אגרות קודש
696 ,כפר חב"ד ,117 ,אדמו"ר האמצעי
696 ,כפר חב"ד ,125 ,אדמו"ר הזקן
696 ,כפר חב"ד ,128 ,אדמו"ר הצמח-צדק
696 ,כפר חב"ד ,126 ,אדמו"ר הריי"צ
696 ,כפר חב"ד ,123 ,אדמו"ר הרש"ב
696 ,כפר חב"ד ,127 ,אדמו"ר מהר"ש
696 ,כפר חב"ד ,129 ,אור התורה
696 ,כפר חב"ד ,140 ,אמרי בינה
696 ,כפר חב"ד ,138 ,באתי לגני
696 ,כפר חב"ד ,109 ,דבר מלכות
696 ,כפר חב"ד ,134 ,דידן נצח
696 ,כפר חב"ד ,103 ,דרך חיים
696 ,כפר חב"ד ,101 ,דרך מצותיך
696 ,כפר חב"ד ,114 ,הבעל שם טוב
696 ,כפר חב"ד ,120 ,הגאולה
696 ,כפר חב"ד ,130 ,המגיד ממעזריטש
696 ,כפר חב"ד ,131 ,הרב מרדכי ש. אשכנזי
696 ,כפר חב"ד ,122 ,הרב נחום טרבניק
696 ,כפר חב"ד ,124 ,הרב שניאור ז גורליק
696 ,כפר חב"ד ,132 ,הרבי מליובאוויטש
696 ,כפר חב"ד ,141 ,הרבנית חיה מושקא
696 ,כפר חב"ד ,119 ,הרבנית חנה
696 ,כפר חב"ד ,121 ,השליחות
696 ,כפר חב"ד ,142 ,חנוך לנער
696 ,כפר חב"ד ,6002 ,כיכר מבצע חינוך
696 ,כפר חב"ד ,6005 ,כיכר מבצע צדקה
696 ,כפר חב"ד ,6003 ,כיכר מבצע תורה
696 ,כפר חב"ד ,6004 ,כיכר מבצע תפילין
696 ,כפר חב"ד ,6001 ,כיכר נרות שבת קודש
696 ,כפר חב"ד ,9000 ,כפר חב"ד
696 ,כפר חב"ד ,143 ,לוח היום יום
696 ,כפר חב"ד ,111 ,לימוד החסידות
696 ,כפר חב"ד ,116 ,לקוטי דיבורים
696 ,כפר חב"ד ,110 ,לקוטי שיחות
696 ,כפר חב"ד ,139 ,לקוטי תורה
696 ,כפר חב"ד ,135 ,מעלה 770
696 ,כפר חב"ד ,105 ,ס"ו
696 ,כפר חב"ד ,115 ,ספר הזכרונות
696 ,כפר חב"ד ,137 ,ספר התניא
696 ,כפר חב"ד ,106 ,ע"ב
696 ,כפר חב"ד ,118 ,עטרת ראש
696 ,כפר חב"ד ,102 ,עץ החיים
696 ,כפר חב"ד ,133 ,צעירי חב"ד
696 ,כפר חב"ד ,108 ,רבי לוי יצחק
696 ,כפר חב"ד ,6500 ,שיכונים חדשים
696 ,כפר חב"ד ,6501 ,שכ לוי יצחק
696 ,כפר חב"ד ,107 ,שערי אורה
696 ,כפר חב"ד ,112 ,שערי תשובה
696 ,כפר חב"ד ,6516 ,תוחלת
696 ,כפר חב"ד ,136 ,תורת חיים
696 ,כפר חב"ד ,145 ,תורת מנחם
696 ,כפר חב"ד ,104 ,תורת שלום
696 ,כפר חב"ד ,113 ,תורת שמואל
609 ,כפר חושן ,9000 ,כפר חושן
255 ,כפר חיטים ,111 ,גפן
255 ,כפר חיטים ,110 ,דובדבן
255 ,כפר חיטים ,103 ,האגוז
255 ,כפר חיטים ,118 ,האלה
255 ,כפר חיטים ,101 ,האסיף
255 ,כפר חיטים ,106 ,הדקל
255 ,כפר חיטים ,107 ,הזית
255 ,כפר חיטים ,113 ,החיטה
255 ,כפר חיטים ,115 ,החמניה
255 ,כפר חיטים ,124 ,המגל
255 ,כפר חיטים ,102 ,הקוצר
255 ,כפר חיטים ,119 ,השדה
255 ,כפר חיטים ,125 ,השיזף
255 ,כפר חיטים ,117 ,חרוב
255 ,כפר חיטים ,9000 ,כפר חיטים
255 ,כפר חיטים ,121 ,מגדל המים
255 ,כפר חיטים ,116 ,נבטים
255 ,כפר חיטים ,104 ,נביעות
255 ,כפר חיטים ,112 ,רימונים
255 ,כפר חיטים ,120 ,שיבולים
255 ,כפר חיטים ,108 ,שעורה
255 ,כפר חיטים ,123 ,שקד
255 ,כפר חיטים ,105 ,שקדיה
255 ,כפר חיטים ,109 ,תאנה
255 ,כפר חיטים ,114 ,תמר
255 ,כפר חיטים ,122 ,תנובת השדה
193 ,כפר חיים ,9000 ,כפר חיים
1297 ,כפר חנניה ,9000 ,כפר חנניה
112 ,כפר חסידים א' ,109 ,בית קמה
112 ,כפר חסידים א' ,113 ,גבורי התהילה
112 ,כפר חסידים א' ,105 ,המייסדים
112 ,כפר חסידים א' ,110 ,הפועל המזרחי
112 ,כפר חסידים א' ,106 ,התמר
112 ,כפר חסידים א' ,9000 ,כפר חסידים א'
112 ,כפר חסידים א' ,107 ,נחל קדומים
112 ,כפר חסידים א' ,108 ,נחלת יעקב
112 ,כפר חסידים א' ,111 ,עבודת ישראל
112 ,כפר חסידים א' ,112 ,עלית הנוער
112 ,כפר חסידים א' ,6501 ,ש המזרחי
112 ,כפר חסידים א' ,6500 ,ש משכנות
112 ,כפר חסידים א' ,6502 ,ש עמידר
112 ,כפר חסידים א' ,6503 ,ש רסקו
112 ,כפר חסידים א' ,104 ,שערי תורה
112 ,כפר חסידים א' ,115 ,תל רגב
889 ,כפר חסידים ב' ,9000 ,כפר חסידים ב'
4004 ,כפר חרוב ,9000 ,כפר חרוב
673 ,כפר טרומן ,112 ,אקליפטוס
673 ,כפר טרומן ,102 ,האלה
673 ,כפר טרומן ,101 ,האלון
673 ,כפר טרומן ,111 ,הבציר
673 ,כפר טרומן ,103 ,הברוש
673 ,כפר טרומן ,106 ,הגפן
673 ,כפר טרומן ,104 ,הזית
673 ,כפר טרומן ,105 ,החרוב
673 ,כפר טרומן ,109 ,העינב
673 ,כפר טרומן ,6500 ,הרחבה
673 ,כפר טרומן ,113 ,הרימון
673 ,כפר טרומן ,110 ,התירוש
673 ,כפר טרומן ,107 ,זמורות
673 ,כפר טרומן ,9000 ,כפר טרומן
673 ,כפר טרומן ,108 ,שריגים
507 ,כפר יאסיף ,9000 ,כפר יאסיף
507 ,כפר יאסיף ,102 ,רח 100
507 ,כפר יאסיף ,202 ,רח 200
507 ,כפר יאסיף ,112 ,רח 110
507 ,כפר יאסיף ,212 ,רח 210
507 ,כפר יאסיף ,122 ,רח 120
507 ,כפר יאסיף ,218 ,רח 220
507 ,כפר יאסיף ,132 ,רח 130
507 ,כפר יאסיף ,223 ,רח 230
507 ,כפר יאסיף ,142 ,רח 140
507 ,כפר יאסיף ,152 ,רח 150
507 ,כפר יאסיף ,162 ,רח 160
507 ,כפר יאסיף ,101 ,רח 70
507 ,כפר יאסיף ,172 ,רח 170
507 ,כפר יאסיף ,182 ,רח 180
507 ,כפר יאסיף ,192 ,רח 190
507 ,כפר יאסיף ,103 ,רח 101
507 ,כפר יאסיף ,203 ,רח 201
507 ,כפר יאסיף ,113 ,רח 111
507 ,כפר יאסיף ,213 ,רח 211
507 ,כפר יאסיף ,123 ,רח 121
507 ,כפר יאסיף ,133 ,רח 131
507 ,כפר יאסיף ,143 ,רח 141
507 ,כפר יאסיף ,153 ,רח 151
507 ,כפר יאסיף ,163 ,רח 161
507 ,כפר יאסיף ,173 ,רח 171
507 ,כפר יאסיף ,183 ,רח 181
507 ,כפר יאסיף ,193 ,רח 191
507 ,כפר יאסיף ,104 ,רח 102
507 ,כפר יאסיף ,204 ,רח 202
507 ,כפר יאסיף ,114 ,רח 112
507 ,כפר יאסיף ,214 ,רח 212
507 ,כפר יאסיף ,124 ,רח 122
507 ,כפר יאסיף ,219 ,רח 222
507 ,כפר יאסיף ,134 ,רח 132
507 ,כפר יאסיף ,144 ,רח 142
507 ,כפר יאסיף ,154 ,רח 152
507 ,כפר יאסיף ,164 ,רח 162
507 ,כפר יאסיף ,174 ,רח 172
507 ,כפר יאסיף ,184 ,רח 182
507 ,כפר יאסיף ,194 ,רח 192
507 ,כפר יאסיף ,105 ,רח 103
507 ,כפר יאסיף ,205 ,רח 203
507 ,כפר יאסיף ,115 ,רח 113
507 ,כפר יאסיף ,125 ,רח 123
507 ,כפר יאסיף ,135 ,רח 133
507 ,כפר יאסיף ,145 ,רח 143
507 ,כפר יאסיף ,155 ,רח 153
507 ,כפר יאסיף ,165 ,רח 163
507 ,כפר יאסיף ,175 ,רח 173
507 ,כפר יאסיף ,185 ,רח 183
507 ,כפר יאסיף ,195 ,רח 193
507 ,כפר יאסיף ,106 ,רח 104
507 ,כפר יאסיף ,206 ,רח 204
507 ,כפר יאסיף ,116 ,רח 114
507 ,כפר יאסיף ,215 ,רח 214
507 ,כפר יאסיף ,126 ,רח 124
507 ,כפר יאסיף ,220 ,רח 224
507 ,כפר יאסיף ,136 ,רח 134
507 ,כפר יאסיף ,146 ,רח 144
507 ,כפר יאסיף ,156 ,רח 154
507 ,כפר יאסיף ,166 ,רח 164
507 ,כפר יאסיף ,176 ,רח 174
507 ,כפר יאסיף ,186 ,רח 184
507 ,כפר יאסיף ,196 ,רח 194
507 ,כפר יאסיף ,107 ,רח 105
507 ,כפר יאסיף ,207 ,רח 205
507 ,כפר יאסיף ,117 ,רח 115
507 ,כפר יאסיף ,127 ,רח 125
507 ,כפר יאסיף ,137 ,רח 135
507 ,כפר יאסיף ,147 ,רח 145
507 ,כפר יאסיף ,157 ,רח 155
507 ,כפר יאסיף ,167 ,רח 165
507 ,כפר יאסיף ,177 ,רח 175
507 ,כפר יאסיף ,187 ,רח 185
507 ,כפר יאסיף ,197 ,רח 195
507 ,כפר יאסיף ,108 ,רח 106
507 ,כפר יאסיף ,208 ,רח 206
507 ,כפר יאסיף ,119 ,רח 116
507 ,כפר יאסיף ,216 ,רח 216
507 ,כפר יאסיף ,128 ,רח 126
507 ,כפר יאסיף ,221 ,רח 226
507 ,כפר יאסיף ,138 ,רח 136
507 ,כפר יאסיף ,148 ,רח 146
507 ,כפר יאסיף ,158 ,רח 156
507 ,כפר יאסיף ,168 ,רח 166
507 ,כפר יאסיף ,178 ,רח 176
507 ,כפר יאסיף ,188 ,רח 186
507 ,כפר יאסיף ,198 ,רח 196
507 ,כפר יאסיף ,109 ,רח 107
507 ,כפר יאסיף ,209 ,רח 207
507 ,כפר יאסיף ,118 ,רח 117
507 ,כפר יאסיף ,129 ,רח 127
507 ,כפר יאסיף ,139 ,רח 137
507 ,כפר יאסיף ,149 ,רח 147
507 ,כפר יאסיף ,159 ,רח 157
507 ,כפר יאסיף ,169 ,רח 167
507 ,כפר יאסיף ,179 ,רח 177
507 ,כפר יאסיף ,189 ,רח 187
507 ,כפר יאסיף ,199 ,רח 197
507 ,כפר יאסיף ,110 ,רח 108
507 ,כפר יאסיף ,210 ,רח 208
507 ,כפר יאסיף ,120 ,רח 118
507 ,כפר יאסיף ,217 ,רח 218
507 ,כפר יאסיף ,130 ,רח 128
507 ,כפר יאסיף ,222 ,רח 228
507 ,כפר יאסיף ,140 ,רח 138
507 ,כפר יאסיף ,150 ,רח 148
507 ,כפר יאסיף ,160 ,רח 158
507 ,כפר יאסיף ,170 ,רח 168
507 ,כפר יאסיף ,180 ,רח 178
507 ,כפר יאסיף ,190 ,רח 188
507 ,כפר יאסיף ,200 ,רח 198
507 ,כפר יאסיף ,111 ,רח 109
507 ,כפר יאסיף ,211 ,רח 209
507 ,כפר יאסיף ,121 ,רח 119
507 ,כפר יאסיף ,131 ,רח 129
507 ,כפר יאסיף ,141 ,רח 139
507 ,כפר יאסיף ,151 ,רח 149
507 ,כפר יאסיף ,161 ,רח 159
507 ,כפר יאסיף ,171 ,רח 169
507 ,כפר יאסיף ,181 ,רח 179
507 ,כפר יאסיף ,191 ,רח 189
507 ,כפר יאסיף ,201 ,רח 199
233 ,כפר ידידיה ,101 ,אביחיל
233 ,כפר ידידיה ,102 ,בית האריזה
233 ,כפר ידידיה ,106 ,בני גאולים
233 ,כפר ידידיה ,112 ,דרך המשולש
233 ,כפר ידידיה ,104 ,הבנים
233 ,כפר ידידיה ,114 ,ההדרים
233 ,כפר ידידיה ,115 ,הזורע
233 ,כפר ידידיה ,110 ,המייסדים
233 ,כפר ידידיה ,108 ,הפיקוסים
233 ,כפר ידידיה ,113 ,הפרחים
233 ,כפר ידידיה ,103 ,הפרסה
233 ,כפר ידידיה ,116 ,הרימון
233 ,כפר ידידיה ,117 ,חישתיל
233 ,כפר ידידיה ,9000 ,כפר ידידיה
233 ,כפר ידידיה ,105 ,מול הים
233 ,כפר ידידיה ,118 ,על עבודת האדמה
233 ,כפר ידידיה ,107 ,עמק חפר
233 ,כפר ידידיה ,111 ,צבעוני השרון
233 ,כפר ידידיה ,109 ,שער הכפר
140 ,כפר יהושע ,113 ,אלה
140 ,כפר יהושע ,114 ,אלון
140 ,כפר יהושע ,121 ,אסיף
140 ,כפר יהושע ,126 ,בעלי המלאכה
140 ,כפר יהושע ,117 ,ברוש
140 ,כפר יהושע ,118 ,גפן
140 ,כפר יהושע ,115 ,דולב
140 ,כפר יהושע ,106 ,דרך בית העם
140 ,כפר יהושע ,101 ,דרך המייסדים
140 ,כפר יהושע ,104 ,דרך הפרדס
140 ,כפר יהושע ,102 ,דרך הרכבת
140 ,כפר יהושע ,105 ,דרך התבואה
140 ,כפר יהושע ,112 ,דרך התיישבות בנים
140 ,כפר יהושע ,110 ,הגורן
140 ,כפר יהושע ,107 ,הגנים
140 ,כפר יהושע ,108 ,הדקלים
140 ,כפר יהושע ,116 ,זית
140 ,כפר יהושע ,9000 ,כפר יהושע
140 ,כפר יהושע ,125 ,מגל
140 ,כפר יהושע ,123 ,סמטת החיטה
140 ,כפר יהושע ,109 ,סמטת התמר
140 ,כפר יהושע ,111 ,סמטת חרמש
140 ,כפר יהושע ,124 ,עומר
140 ,כפר יהושע ,120 ,פקאן
140 ,כפר יהושע ,122 ,קציר
140 ,כפר יהושע ,119 ,רימון
140 ,כפר יהושע ,103 ,שדרת מגדל המים
168 ,כפר יונה ,101 ,אגוז
168 ,כפר יונה ,305 ,אהבת אדם
168 ,כפר יונה ,198 ,אהוד מנור
168 ,כפר יונה ,275 ,אודם
168 ,כפר יונה ,201 ,אח"י אילת
168 ,כפר יונה ,105 ,אידה יהודית זידלר
168 ,כפר יונה ,187 ,איזמרגד
168 ,כפר יונה ,181 ,אילות
168 ,כפר יונה ,202 ,אלי כהן
168 ,כפר יונה ,102 ,אלמוגן
168 ,כפר יונה ,111 ,אלפסי
168 ,כפר יונה ,261 ,ארבל
168 ,כפר יונה ,186 ,ארגמן
168 ,כפר יונה ,300 ,אריאל שרון
168 ,כפר יונה ,128 ,אשוח
168 ,כפר יונה ,239 ,אשל
168 ,כפר יונה ,280 ,בזלת
168 ,כפר יונה ,6500 ,בית סוכנות
168 ,כפר יונה ,6501 ,בנה ביתך א
168 ,כפר יונה ,267 ,בנטל
168 ,כפר יונה ,273 ,ברקת
168 ,כפר יונה ,210 ,גבורי ישראל
168 ,כפר יונה ,6503 ,גבעת האלונים
168 ,כפר יונה ,173 ,גבעתי
168 ,כפר יונה ,174 ,גולני
168 ,כפר יונה ,117 ,גורדון
168 ,כפר יונה ,281 ,גזית
168 ,כפר יונה ,139 ,גילה
168 ,כפר יונה ,277 ,גן
168 ,כפר יונה ,276 ,גרניט
168 ,כפר יונה ,107 ,דבורה פישר
168 ,כפר יונה ,200 ,דוד בן גוריון
168 ,כפר יונה ,237 ,דולב
168 ,כפר יונה ,120 ,דן
168 ,כפר יונה ,118 ,דפנה
168 ,כפר יונה ,309 ,האהבה
168 ,כפר יונה ,302 ,האומץ
168 ,כפר יונה ,303 ,האור
168 ,כפר יונה ,242 ,האורן
168 ,כפר יונה ,306 ,האושר
168 ,כפר יונה ,312 ,האחדות
168 ,כפר יונה ,313 ,האחווה
168 ,כפר יונה ,134 ,האיריס
168 ,כפר יונה ,241 ,האלה
168 ,כפר יונה ,229 ,האלון
168 ,כפר יונה ,235 ,הארזים
168 ,כפר יונה ,176 ,האתרוג
168 ,כפר יונה ,227 ,הברוש
168 ,כפר יונה ,264 ,הבשן
168 ,כפר יונה ,257 ,הגולן
168 ,כפר יונה ,255 ,הגלבוע
168 ,כפר יונה ,251 ,הגליל
168 ,כפר יונה ,256 ,הגלעד
168 ,כפר יונה ,220 ,הגפן
168 ,כפר יונה ,136 ,הדודאים
168 ,כפר יונה ,221 ,הדקל
168 ,כפר יונה ,215 ,ההגנה
168 ,כפר יונה ,230 ,ההדס
168 ,כפר יונה ,124 ,ההדרים
168 ,כפר יונה ,304 ,ההודיה
168 ,כפר יונה ,137 ,הורדים
168 ,כפר יונה ,240 ,הזית
168 ,כפר יונה ,131 ,החבצלת
168 ,כפר יונה ,317 ,החוסן
168 ,כפר יונה ,126 ,החצב
168 ,כפר יונה ,234 ,החרוב
168 ,כפר יונה ,130 ,החרצית
168 ,כפר יונה ,314 ,הידידות
168 ,כפר יונה ,133 ,היסמין
168 ,כפר יונה ,254 ,הירדן
168 ,כפר יונה ,119 ,הירמוך
168 ,כפר יונה ,121 ,הירקון
168 ,כפר יונה ,123 ,הכלנית
168 ,כפר יונה ,258 ,הכרמל
168 ,כפר יונה ,114 ,הל"ה
168 ,כפר יונה ,190 ,הלוטם
168 ,כפר יונה ,212 ,המייסדים
168 ,כפר יונה ,132 ,המרגנית
168 ,כפר יונה ,310 ,המשאלות
168 ,כפר יונה ,252 ,הנגב
168 ,כפר יונה ,208 ,הנוער
168 ,כפר יונה ,125 ,הנרקיס
168 ,כפר יונה ,135 ,הסביון
168 ,כפר יונה ,308 ,העוצמה
168 ,כפר יונה ,213 ,העליה
168 ,כפר יונה ,127 ,העמק
168 ,כפר יונה ,204 ,העצמאות
168 ,כפר יונה ,233 ,הערבה
168 ,כפר יונה ,182 ,הפקאן
168 ,כפר יונה ,318 ,הפריחה
168 ,כפר יונה ,226 ,הצבר
168 ,כפר יונה ,205 ,הרב קוק
168 ,כפר יונה ,138 ,הרדוף
168 ,כפר יונה ,316 ,הרוגע
168 ,כפר יונה ,222 ,הרימון
168 ,כפר יונה ,315 ,הרעות
168 ,כפר יונה ,216 ,הרצל
168 ,כפר יונה ,122 ,הרקפת
168 ,כפר יונה ,266 ,השומרון
168 ,כפר יונה ,140 ,השושנים
168 ,כפר יונה ,232 ,השיטה
168 ,כפר יונה ,311 ,השלווה
168 ,כפר יונה ,301 ,השמחה
168 ,כפר יונה ,307 ,השפע
168 ,כפר יונה ,223 ,השקד
168 ,כפר יונה ,253 ,השרון
168 ,כפר יונה ,219 ,התאנה
168 ,כפר יונה ,228 ,התמר
168 ,כפר יונה ,224 ,התעשיה
168 ,כפר יונה ,218 ,ויצמן
168 ,כפר יונה ,188 ,חושן
168 ,כפר יונה ,195 ,חנה רובינא
168 ,כפר יונה ,260 ,חרמון
168 ,כפר יונה ,279 ,טופז
168 ,כפר יונה ,209 ,יהודה הלוי
168 ,כפר יונה ,106 ,יהודית ריכטר
168 ,כפר יונה ,211 ,יחזקאל
168 ,כפר יונה ,129 ,יפתח
168 ,כפר יונה ,271 ,יקינטון
168 ,כפר יונה ,6001 ,ככר בר כוכבא
168 ,כפר יונה ,268 ,כנען
168 ,כפר יונה ,9000 ,כפר יונה
168 ,כפר יונה ,108 ,לאה גוטליב
168 ,כפר יונה ,6511 ,לב הכפר
168 ,כפר יונה ,110 ,מוצקין
168 ,כפר יונה ,259 ,מוריה
168 ,כפר יונה ,243 ,מורן
168 ,כפר יונה ,262 ,מירון
168 ,כפר יונה ,116 ,מרכז קליטה
168 ,כפר יונה ,265 ,נבו
168 ,כפר יונה ,6508 ,נווה ניצנים
168 ,כפר יונה ,150 ,נחל בשור
168 ,כפר יונה ,151 ,נחל גלים
168 ,כפר יונה ,152 ,נחל געתון
168 ,כפר יונה ,153 ,נחל דליות
168 ,כפר יונה ,154 ,נחל דלתון
168 ,כפר יונה ,155 ,נחל זוויתן
168 ,כפר יונה ,180 ,נחל חלמיש
168 ,כפר יונה ,156 ,נחל חרוד
168 ,כפר יונה ,157 ,נחל כזיב
168 ,כפר יונה ,158 ,נחל מישר
168 ,כפר יונה ,159 ,נחל משושים
168 ,כפר יונה ,160 ,נחל נקרות
168 ,כפר יונה ,179 ,נחל ערוגות
168 ,כפר יונה ,161 ,נחל פולג
168 ,כפר יונה ,162 ,נחל צין
168 ,כפר יונה ,163 ,נחל קישון
168 ,כפר יונה ,164 ,נחל קמה
168 ,כפר יונה ,165 ,נחל רודד
168 ,כפר יונה ,166 ,נחל שגיא
168 ,כפר יונה ,167 ,נחל שובל
168 ,כפר יונה ,168 ,נחל שורק
168 ,כפר יונה ,169 ,נחל תנינים
168 ,כפר יונה ,199 ,נעמי שמר
168 ,כפר יונה ,203 ,סוקולוב
168 ,כפר יונה ,103 ,סיגלון
168 ,כפר יונה ,178 ,סייפן
168 ,כפר יונה ,177 ,סמ דן
168 ,כפר יונה ,141 ,סמ נורית
168 ,כפר יונה ,274 ,ספיר
168 ,כפר יונה ,109 ,עולי הגרדום
168 ,כפר יונה ,170 ,פלמ"ח
168 ,כפר יונה ,231 ,צאלון
168 ,כפר יונה ,270 ,צבעוני
168 ,כפר יונה ,282 ,צור
168 ,כפר יונה ,236 ,צלף
168 ,כפר יונה ,6509 ,צמרות השרון
168 ,כפר יונה ,171 ,צנחנים
168 ,כפר יונה ,206 ,רבי אלימלך
168 ,כפר יונה ,104 ,רות דיין
168 ,כפר יונה ,238 ,רותם
168 ,כפר יונה ,115 ,רזיאל
168 ,כפר יונה ,113 ,רמב"ם
168 ,כפר יונה ,6504 ,ש חדש
168 ,כפר יונה ,207 ,שד הדקלים
168 ,כפר יונה ,112 ,שד מנחם בגין
168 ,כפר יונה ,214 ,שד פישר מוריס
168 ,כפר יונה ,175 ,שד רבין
168 ,כפר יונה ,278 ,שהם
168 ,כפר יונה ,197 ,שושנה דמארי
168 ,כפר יונה ,244 ,שיזף
168 ,כפר יונה ,196 ,שייקה אופיר
168 ,כפר יונה ,6513 ,שכ אלי כהן
168 ,כפר יונה ,6512 ,שכ המושבה
168 ,כפר יונה ,6505 ,שכ קרן היסוד
168 ,כפר יונה ,6506 ,שכון א
168 ,כפר יונה ,6507 ,שכון ב
168 ,כפר יונה ,6510 ,שכון טרומי
168 ,כפר יונה ,225 ,שקמה
168 ,כפר יונה ,217 ,שרת
168 ,כפר יונה ,263 ,תבור
168 ,כפר יונה ,272 ,תורמוס
168 ,כפר יונה ,172 ,תותחנים
85 ,כפר יחזקאל ,101 ,העיגול
85 ,כפר יחזקאל ,104 ,הרחבה
85 ,כפר יחזקאל ,103 ,הרכבת
85 ,כפר יחזקאל ,9000 ,כפר יחזקאל
85 ,כפר יחזקאל ,102 ,מנחם
170 ,כפר יעבץ ,109 ,דרך הגבעה
170 ,כפר יעבץ ,105 ,דרך הגת
170 ,כפר יעבץ ,108 ,דרך הנירים
170 ,כפר יעבץ ,107 ,האשל
170 ,כפר יעבץ ,106 ,הבאר
170 ,כפר יעבץ ,113 ,הבוסתן
170 ,כפר יעבץ ,112 ,הגורן
170 ,כפר יעבץ ,110 ,החורש
170 ,כפר יעבץ ,104 ,הכרמים
170 ,כפר יעבץ ,111 ,הנחל
170 ,כפר יעבץ ,103 ,הפרדסים
170 ,כפר יעבץ ,102 ,הפרחים
170 ,כפר יעבץ ,101 ,הראשונים
170 ,כפר יעבץ ,9000 ,כפר יעבץ
508 ,כפר כמא ,152 ,אבזאך
508 ,כפר כמא ,100 ,אדיגה
508 ,כפר כמא ,145 ,אה נאפה
508 ,כפר כמא ,117 ,אושחה מאפ
508 ,כפר כמא ,119 ,אזאת
508 ,כפר כמא ,170 ,אזה
508 ,כפר כמא ,143 ,אלברוס
508 ,כפר כמא ,182 ,אנה
508 ,כפר כמא ,159 ,אפאס
508 ,כפר כמא ,151 ,אפיפס
508 ,כפר כמא ,148 ,אפץ'
508 ,כפר כמא ,112 ,באשתאש
508 ,כפר כמא ,161 ,באתר
508 ,כפר כמא ,153 ,בז'אדור'
508 ,כפר כמא ,162 ,בלאנה
508 ,כפר כמא ,163 ,בלם
508 ,כפר כמא ,146 ,בסלאנאי
508 ,כפר כמא ,108 ,ג'אמיה
508 ,כפר כמא ,171 ,גואשה
508 ,כפר כמא ,166 ,גומאף
508 ,כפר כמא ,172 ,גונס
508 ,כפר כמא ,178 ,גורת
508 ,כפר כמא ,126 ,דאה
508 ,כפר כמא ,168 ,דאנה
508 ,כפר כמא ,174 ,דאריי
508 ,כפר כמא ,181 ,דה'ווס
508 ,כפר כמא ,133 ,וואר'ה
508 ,כפר כמא ,157 ,ווביח
508 ,כפר כמא ,165 ,ווס
508 ,כפר כמא ,164 ,ווספס
508 ,כפר כמא ,138 ,וופש
508 ,כפר כמא ,115 ,זאפאצה
508 ,כפר כמא ,187 ,זאפה
508 ,כפר כמא ,102 ,חאם רוג
508 ,כפר כמא ,137 ,חאן רוג
508 ,כפר כמא ,111 ,חאנל'אשה
508 ,כפר כמא ,130 ,חאצ'ה
508 ,כפר כמא ,147 ,חאקוץ
508 ,כפר כמא ,131 ,חתוקאי
508 ,כפר כמא ,150 ,טוואפסה
508 ,כפר כמא ,156 ,יג'ירקואי
508 ,כפר כמא ,6002 ,כיכר ביברס
508 ,כפר כמא ,6001 ,כיכר קטז
508 ,כפר כמא ,9000 ,כפר כמא
508 ,כפר כמא ,134 ,ל'אמיג'
508 ,כפר כמא ,154 ,לאר'ה נאק'ה
508 ,כפר כמא ,144 ,מאז דוג
508 ,כפר כמא ,110 ,מאזה
508 ,כפר כמא ,198 ,מאזה נף
508 ,כפר כמא ,155 ,מאיקופ
508 ,כפר כמא ,149 ,מאמחיש
508 ,כפר כמא ,158 ,מאמר
508 ,כפר כמא ,188 ,מאפה
508 ,כפר כמא ,123 ,מארסה
508 ,כפר כמא ,124 ,מארקאווא
508 ,כפר כמא ,189 ,מזנף
508 ,כפר כמא ,191 ,מזפס
508 ,כפר כמא ,173 ,נאבזה
508 ,כפר כמא ,186 ,נאואס
508 ,כפר כמא ,185 ,נאלמס
508 ,כפר כמא ,135 ,נאלצ'יק
508 ,כפר כמא ,136 ,נאף
508 ,כפר כמא ,193 ,נאפה
508 ,כפר כמא ,179 ,נארת
508 ,כפר כמא ,125 ,נאשה
508 ,כפר כמא ,175 ,נגוף
508 ,כפר כמא ,116 ,נסה
508 ,כפר כמא ,180 ,נפנה
508 ,כפר כמא ,167 ,נרזאן
508 ,כפר כמא ,176 ,נשאן
508 ,כפר כמא ,128 ,נתכואי
508 ,כפר כמא ,118 ,סאיה
508 ,כפר כמא ,114 ,סאנה
508 ,כפר כמא ,141 ,סווסרק'וואה
508 ,כפר כמא ,103 ,ספסף
508 ,כפר כמא ,177 ,פאסה
508 ,כפר כמא ,196 ,פאשה
508 ,כפר כמא ,122 ,פחאצ'אצ'
508 ,כפר כמא ,127 ,פכאח רוג
508 ,כפר כמא ,105 ,פכה צ'אסה
508 ,כפר כמא ,121 ,פסא
508 ,כפר כמא ,183 ,פסארש
508 ,כפר כמא ,194 ,פסוור
508 ,כפר כמא ,195 ,פסנה
508 ,כפר כמא ,184 ,פסנפש
508 ,כפר כמא ,142 ,פשא
508 ,כפר כמא ,132 ,פשינה
508 ,כפר כמא ,129 ,צ'אם רוג
508 ,כפר כמא ,109 ,צ'ארקה
508 ,כפר כמא ,139 ,צ'מגוי
508 ,כפר כמא ,120 ,ציה
508 ,כפר כמא ,140 ,קברטאי
508 ,כפר כמא ,197 ,קפצ'ה
508 ,כפר כמא ,104 ,רוגזה
508 ,כפר כמא ,169 ,שאבזה
508 ,כפר כמא ,107 ,שאוו
508 ,כפר כמא ,101 ,שאפסור'
508 ,כפר כמא ,192 ,שוו
508 ,כפר כמא ,160 ,תאמה
508 ,כפר כמא ,113 ,תאמה רידז
508 ,כפר כמא ,106 ,תיר'ה
508 ,כפר כמא ,190 ,תמר
509 ,כפר כנא ,202 ,א-דוואלי
509 ,כפר כנא ,207 ,א-זנבק
509 ,כפר כנא ,195 ,א-זקוקיא
509 ,כפר כנא ,239 ,א-לוז
509 ,כפר כנא ,234 ,א-נור
509 ,כפר כנא ,209 ,א-נח'יל
509 ,כפר כנא ,216 ,א-נחאתין
509 ,כפר כנא ,237 ,א-נרג'ס
509 ,כפר כנא ,249 ,א-סוסנה
509 ,כפר כנא ,224 ,א-סנאבל
509 ,כפר כנא ,208 ,א-סרוואת
509 ,כפר כנא ,235 ,א-סת ראדייה
509 ,כפר כנא ,218 ,א-ראהבה ג'ובינה
509 ,כפר כנא ,250 ,א-רחמה
509 ,כפר כנא ,217 ,א-ריאחין
509 ,כפר כנא ,230 ,א-שאם
509 ,כפר כנא ,193 ,א-שג'רה
509 ,כפר כנא ,168 ,א-תין
509 ,כפר כנא ,176 ,אבו בכר
509 ,כפר כנא ,246 ,אבו קאסם א-שאבי
509 ,כפר כנא ,6713 ,אזור המועצה
509 ,כפר כנא ,6715 ,אזור התעשייה
509 ,כפר כנא ,184 ,איבן סינא
509 ,כפר כנא ,121 ,אישראקה
509 ,כפר כנא ,6744 ,אל ביאדה
509 ,כפר כנא ,260 ,אל-אמין
509 ,כפר כנא ,229 ,אל-אנדלוס
509 ,כפר כנא ,107 ,אל-בטריק
509 ,כפר כנא ,238 ,אל-ג'ורי
509 ,כפר כנא ,179 ,אל-ח'ורי יוסף
509 ,כפר כנא ,185 ,אל-ח'נסאא
509 ,כפר כנא ,170 ,אל-ח'רובה
509 ,כפר כנא ,172 ,אל-חאווז
509 ,כפר כנא ,200 ,אל-חוטיני
509 ,כפר כנא ,114 ,אל-חיכמה
509 ,כפר כנא ,183 ,אל-יאסמין
509 ,כפר כנא ,206 ,אל-ירמוך
509 ,כפר כנא ,261 ,אל-כרם
509 ,כפר כנא ,149 ,אל-כרם א-שימאלי
509 ,כפר כנא ,214 ,אל-מואזין
509 ,כפר כנא ,199 ,אל-מוטאלב
509 ,כפר כנא ,187 ,אל-מוטראן חג'אר
509 ,כפר כנא ,113 ,אל-מוס'לא
509 ,כפר כנא ,251 ,אל-מוסחראתי
509 ,כפר כנא ,257 ,אל-מועלם ג'אבר
509 ,כפר כנא ,165 ,אל-מוקדסי
509 ,כפר כנא ,177 ,אל-מח'אתיר
509 ,כפר כנא ,171 ,אל-מטל
509 ,כפר כנא ,215 ,אל-מנזול
509 ,כפר כנא ,191 ,אל-ע'זאלי
509 ,כפר כנא ,211 ,אל-עג'מי
509 ,כפר כנא ,248 ,אל-פול
509 ,כפר כנא ,232 ,אל-פרדוס
509 ,כפר כנא ,213 ,אל-קלעה
509 ,כפר כנא ,6720 ,אלח'לה
509 ,כפר כנא ,6753 ,אלמסאלה
509 ,כפר כנא ,6712 ,אלנקארה
509 ,כפר כנא ,6750 ,אלעין
509 ,כפר כנא ,6740 ,אם דלהם
509 ,כפר כנא ,243 ,אמיל חביבי
509 ,כפר כנא ,6743 ,בי"ס יסודי ג'
509 ,כפר כנא ,178 ,בלאן
509 ,כפר כנא ,192 ,ג'ורת אל-ביר
509 ,כפר כנא ,189 ,ג'לנאר
509 ,כפר כנא ,158 ,גובראן ח'ליל גובראן
509 ,כפר כנא ,245 ,גרנטה
509 ,כפר כנא ,210 ,דבת אל-ח'מאיסי
509 ,כפר כנא ,123 ,דרב א-זעתר
509 ,כפר כנא ,110 ,דרב א-סוק
509 ,כפר כנא ,6730 ,העיר העתיקה(אלבלד)
509 ,כפר כנא ,236 ,זקאק אל-עג'יבה
509 ,כפר כנא ,222 ,ח'אלד בן אל-וליד
509 ,כפר כנא ,247 ,ח'לת אל-ג'וז
509 ,כפר כנא ,117 ,ח'לת אל-עואודה
509 ,כפר כנא ,111 ,ח'לת חמדאן
509 ,כפר כנא ,197 ,ח'לת ס'קר
509 ,כפר כנא ,240 ,טארק בן זיאד
509 ,כפר כנא ,231 ,טברייה
509 ,כפר כנא ,258 ,יאפא
509 ,כפר כנא ,241 ,כמאל אל-יוסף
509 ,כפר כנא ,167 ,כעמו
509 ,כפר כנא ,9000 ,כפר כנא
509 ,כפר כנא ,122 ,מאי זיאדה
509 ,כפר כנא ,186 ,מארי ס'פורי
509 ,כפר כנא ,190 ,מאריה אל-קיבטיה
509 ,כפר כנא ,174 ,מטר
509 ,כפר כנא ,166 ,מנזיל
509 ,כפר כנא ,173 ,מנזיל עראר
509 ,כפר כנא ,220 ,סאחת אל-מוח'תאר
509 ,כפר כנא ,108 ,סירין
509 ,כפר כנא ,223 ,סמעאן אל-כנאוי
509 ,כפר כנא ,137 ,עומר איבן אל-ח'טאב
509 ,כפר כנא ,141 ,עומר בן עבד אל-עזיז
509 ,כפר כנא ,162 ,עות'מאן איבן עפאן
509 ,כפר כנא ,233 ,עלי אל-פארס
509 ,כפר כנא ,138 ,עלי בן אבי טאלב
509 ,כפר כנא ,204 ,עס'א א-ראעי
509 ,כפר כנא ,201 ,ערב א-ס'בח
509 ,כפר כנא ,154 ,צלאח א-דין
509 ,כפר כנא ,124 ,קאנא אל-ג'ליל
509 ,כפר כנא ,120 ,שארע א-זהראא
509 ,כפר כנא ,152 ,שארע א-נקארה
509 ,כפר כנא ,132 ,שארע א-ס'באר
509 ,כפר כנא ,157 ,שארע א-סקי
509 ,כפר כנא ,160 ,שארע א-רומאן
509 ,כפר כנא ,164 ,שארע א-שיח' סאלם
509 ,כפר כנא ,139 ,שארע אום דלהם
509 ,כפר כנא ,135 ,שארע אום זיתונה
509 ,כפר כנא ,146 ,שארע איבן רושד
509 ,כפר כנא ,155 ,שארע אל-בסאתין
509 ,כפר כנא ,253 ,שארע אל-ג'מאקיה
509 ,כפר כנא ,150 ,שארע אל-חמרייה
509 ,כפר כנא ,109 ,שארע אל-כנאאיס
509 ,כפר כנא ,242 ,שארע אל-מחדר
509 ,כפר כנא ,145 ,שארע אל-מלעב
509 ,כפר כנא ,127 ,שארע אל-מעאלי
509 ,כפר כנא ,130 ,שארע אל-מעאס'ר
509 ,כפר כנא ,126 ,שארע אל-משאווח
509 ,כפר כנא ,136 ,שארע אל-עבאהר
509 ,כפר כנא ,256 ,שארע אל-קנדול
509 ,כפר כנא ,175 ,שארע אלבלד אלקדימה
509 ,כפר כנא ,148 ,שארע ביארכ אל-ח'יל
509 ,כפר כנא ,143 ,שארע ג'בל ח'וויח'ה
509 ,כפר כנא ,119 ,שארע ג'בל סיח'
509 ,כפר כנא ,182 ,שארע ג'פתה
509 ,כפר כנא ,112 ,שארע ואדי זיד
509 ,כפר כנא ,131 ,שארע וערת סעד
509 ,כפר כנא ,140 ,שארע ח'לת אל-מריד
509 ,כפר כנא ,142 ,שארע ח'לת נאצר
509 ,כפר כנא ,159 ,שארע חנא אל-מטר
509 ,כפר כנא ,153 ,שארע טה חוסין
509 ,כפר כנא ,133 ,שארע כורום א-רישה
509 ,כפר כנא ,181 ,שארע כרם א-ראס
509 ,כפר כנא ,151 ,שארע כרם א-שיח'
509 ,כפר כנא ,128 ,שארע כרם אל-חדאדין
509 ,כפר כנא ,129 ,שארע מעד'ר
509 ,כפר כנא ,252 ,שארע ס'פד
509 ,כפר כנא ,134 ,שארע עין אל-ביד'ה
509 ,כפר כנא ,254 ,שארע עין ג'וזה
509 ,כפר כנא ,116 ,שארע ענתר
509 ,כפר כנא ,125 ,שארע פוק אל-עין
509 ,כפר כנא ,6700 ,שכ אל ג'מאג'יה
509 ,כפר כנא ,6714 ,שכ אלבסאתין
509 ,כפר כנא ,6722 ,שכ אלח'מריה
509 ,כפר כנא ,6723 ,שכ אלמחדר
509 ,כפר כנא ,6751 ,שכ אלמשוח
509 ,כפר כנא ,6721 ,שכ ג'בל ח'ויח'ה
509 ,כפר כנא ,6711 ,שכ ג'פתא
509 ,כפר כנא ,6724 ,שכ ואדי זיד
509 ,כפר כנא ,6742 ,שכ וערת סעד
509 ,כפר כנא ,6741 ,שכ כרום אלרישה
509 ,כפר כנא ,6710 ,שכ כרם אראס
509 ,כפר כנא ,6731 ,שכ ענטר
509 ,כפר כנא ,6754 ,שכ פוק אל עין
509 ,כפר כנא ,6752 ,שכונת בנה ביתך
509 ,כפר כנא ,169 ,תופיק אל-חכים
509 ,כפר כנא ,259 ,תל א-סבע
387 ,כפר מונש ,101 ,דרך הטרקטור
387 ,כפר מונש ,103 ,הפרדסים
387 ,כפר מונש ,105 ,הפרחים
387 ,כפר מונש ,106 ,הקורא
387 ,כפר מונש ,102 ,הרימון
387 ,כפר מונש ,9000 ,כפר מונש
387 ,כפר מונש ,107 ,סמטת הברוש
387 ,כפר מונש ,104 ,שביל החלב
1095 ,כפר מימון ,9000 ,כפר מימון
98 ,כפר מל"ל ,105 ,בן גמלא יהושע
98 ,כפר מל"ל ,110 ,דרך השדות
98 ,כפר מל"ל ,107 ,דרך השרון
98 ,כפר מל"ל ,109 ,האלונים
98 ,כפר מל"ל ,112 ,הבנים
98 ,כפר מל"ל ,108 ,הזית
98 ,כפר מל"ל ,104 ,הלימון
98 ,כפר מל"ל ,103 ,המיסדים
98 ,כפר מל"ל ,114 ,המנצח
98 ,כפר מל"ל ,106 ,הפרחים
98 ,כפר מל"ל ,102 ,הצנחנים
98 ,כפר מל"ל ,9000 ,כפר מל"ל
98 ,כפר מל"ל ,101 ,מוסד יד אסא
98 ,כפר מל"ל ,111 ,עין חי
98 ,כפר מל"ל ,113 ,שרת
510 ,כפר מנדא ,6741 ,אלבלדה אלקדימה
510 ,כפר מנדא ,6742 ,אלבסאתין
510 ,כפר מנדא ,6733 ,אלח'לאילה
510 ,כפר מנדא ,6740 ,אלחואשנה
510 ,כפר מנדא ,6711 ,אלמאר
510 ,כפר מנדא ,6731 ,אלמג'לס אל קדים
510 ,כפר מנדא ,6721 ,אלמע'ור
510 ,כפר מנדא ,6720 ,אלמעברה
510 ,כפר מנדא ,6734 ,אלמעסרה
510 ,כפר מנדא ,6722 ,אלנבעה
510 ,כפר מנדא ,6723 ,אלע'רביה
510 ,כפר מנדא ,6713 ,אלעוזיר
510 ,כפר מנדא ,6730 ,אלשאריע אלראיסי
510 ,כפר מנדא ,6732 ,אלתין
510 ,כפר מנדא ,6712 ,זיתון מזרחית
510 ,כפר מנדא ,6710 ,זערורה
510 ,כפר מנדא ,6714 ,טריק עראבה
510 ,כפר מנדא ,9000 ,כפר מנדא
274 ,כפר מנחם ,9000 ,כפר מנחם
274 ,כפר מנחם ,101 ,צפית
297 ,כפר מסריק ,9000 ,כפר מסריק
512 ,כפר מצר ,9000 ,כפר מצר
764 ,כפר מרדכי ,121 ,דפנה
764 ,כפר מרדכי ,104 ,הבוסתן
764 ,כפר מרדכי ,107 ,הבריכה
764 ,כפר מרדכי ,122 ,הדס
764 ,כפר מרדכי ,105 ,הכרם
764 ,כפר מרדכי ,106 ,הלילך
764 ,כפר מרדכי ,102 ,המטע
764 ,כפר מרדכי ,101 ,הפרדס
764 ,כפר מרדכי ,103 ,השדה
764 ,כפר מרדכי ,9000 ,כפר מרדכי
764 ,כפר מרדכי ,124 ,לבונה
764 ,כפר מרדכי ,108 ,מעלה המייסדים
764 ,כפר מרדכי ,123 ,מרווה
764 ,כפר מרדכי ,126 ,קורנית
764 ,כפר מרדכי ,125 ,ריחן
316 ,כפר נטר ,103 ,האורן
316 ,כפר נטר ,106 ,האלה
316 ,כפר נטר ,101 ,האלון
316 ,כפר נטר ,102 ,ההדרים
316 ,כפר נטר ,104 ,החרוב
316 ,כפר נטר ,108 ,הרותם
316 ,כפר נטר ,107 ,השיטה
316 ,כפר נטר ,105 ,השלום
316 ,כפר נטר ,9000 ,כפר נטר
345 ,כפר סאלד ,101 ,בית ראשון במולדת
345 ,כפר סאלד ,9000 ,כפר סאלד
6900 ,כפר סבא ,215 ,אביגיל
6900 ,כפר סבא ,222 ,אבידן דוד
6900 ,כפר סבא ,101 ,אבן גבירול
6900 ,כפר סבא ,102 ,אבן עזרא
6900 ,כפר סבא ,103 ,אבן שפרוט
6900 ,כפר סבא ,104 ,אברבנאל
6900 ,כפר סבא ,693 ,אברוצקי
6900 ,כפר סבא ,501 ,אגרון
6900 ,כפר סבא ,443 ,אדם יקותיאל
6900 ,כפר סבא ,502 ,אהרונוביץ
6900 ,כפר סבא ,694 ,אוסטרובסקי
6900 ,כפר סבא ,503 ,אוסטשינסקי
6900 ,כפר סבא ,146 ,אורט
6900 ,כפר סבא ,302 ,אז"ר
6900 ,כפר סבא ,6517 ,אזור המלאכה והתעשיה
6900 ,כפר סבא ,6511 ,אזור התעשיה
6900 ,כפר סבא ,6518 ,אזור מלאכה
6900 ,כפר סבא ,391 ,אח"י אילת
6900 ,כפר סבא ,392 ,אח"י דקר
6900 ,כפר סבא ,303 ,אחד העם
6900 ,כפר סבא ,610 ,אחוה
6900 ,כפר סבא ,505 ,איינשטיין
6900 ,כפר סבא ,504 ,אימבר
6900 ,כפר סבא ,165 ,אירגוני המתנדבים
6900 ,כפר סבא ,601 ,אירוס
6900 ,כפר סבא ,106 ,אלחריזי
6900 ,כפר סבא ,124 ,אליהו התשבי
6900 ,כפר סבא ,304 ,אלישבע
6900 ,כפר סבא ,131 ,אלישע
6900 ,כפר סבא ,701 ,אלעזר דוד
6900 ,כפר סבא ,105 ,אלקלעי
6900 ,כפר סבא ,577 ,אלתרמן
6900 ,כפר סבא ,478 ,אמנון ותמר
6900 ,כפר סבא ,671 ,אנגל
6900 ,כפר סבא ,506 ,אנה פרנק
6900 ,כפר סבא ,292 ,אנטק
6900 ,כפר סבא ,401 ,אנילביץ
6900 ,כפר סבא ,691 ,אנציקובסקי
6900 ,כפר סבא ,305 ,אסירי ציון
6900 ,כפר סבא ,715 ,אצ"ג
6900 ,כפר סבא ,590 ,ארבל
6900 ,כפר סבא ,662 ,ארגוב סשה
6900 ,כפר סבא ,507 ,ארלוזורוב
6900 ,כפר סבא ,508 ,אש שלום
6900 ,כפר סבא ,158 ,אשכול לוי
6900 ,כפר סבא ,306 ,בוסל
6900 ,כפר סבא ,285 ,בוסתן אבו סניני
6900 ,כפר סבא ,402 ,בורוכוב
6900 ,כפר סבא ,1320 ,בי"ח מאיר
6900 ,כפר סבא ,509 ,ביאליק
6900 ,כפר סבא ,510 ,ביל"ו
6900 ,כפר סבא ,307 ,בילינסון
6900 ,כפר סבא ,6512 ,בית ונוף
6900 ,כפר סבא ,403 ,בלבן
6900 ,כפר סבא ,404 ,בלום ליאון
6900 ,כפר סבא ,405 ,בלפור
6900 ,כפר סבא ,163 ,בלפר זאב
6900 ,כפר סבא ,390 ,בן אב"י איתמר
6900 ,כפר סבא ,522 ,בן גוריון
6900 ,כפר סבא ,309 ,בן יהודה
6900 ,כפר סבא ,659 ,בנאי יוסי
6900 ,כפר סבא ,541 ,בני ברית
6900 ,כפר סבא ,250 ,בקעת באר שבע
6900 ,כפר סבא ,242 ,בקעת בית נטופה
6900 ,כפר סבא ,241 ,בקעת הירח
6900 ,כפר סבא ,247 ,בקעת זוהר
6900 ,כפר סבא ,251 ,בקעת יבנאל
6900 ,כפר סבא ,249 ,בקעת יריחו
6900 ,כפר סבא ,245 ,בקעת סיירים
6900 ,כפר סבא ,248 ,בקעת עובדה
6900 ,כפר סבא ,246 ,בקעת צין
6900 ,כפר סבא ,244 ,בקעת רמון
6900 ,כפר סבא ,310 ,בר אילן
6900 ,כפר סבא ,407 ,ברדיצ'ב אבא
6900 ,כפר סבא ,603 ,ברוש
6900 ,כפר סבא ,311 ,ברנר
6900 ,כפר סבא ,380 ,ברק
6900 ,כפר סבא ,470 ,ברקן
6900 ,כפר סבא ,713 ,ברש אשר
6900 ,כפר סבא ,216 ,בת יפתח
6900 ,כפר סבא ,226 ,בת שבע
6900 ,כפר סבא ,511 ,בתי חבס
6900 ,כפר סבא ,657 ,ג'ו עמר
6900 ,כפר סבא ,512 ,גאולה
6900 ,כפר סבא ,554 ,גבורות
6900 ,כפר סבא ,587 ,גבורי ישראל
6900 ,כפר סבא ,1305 ,גביש
6900 ,כפר סבא ,802 ,גבעולים
6900 ,כפר סבא ,6500 ,גבעת אשכול
6900 ,כפר סבא ,408 ,גבעתי
6900 ,כפר סבא ,280 ,גד הנביא
6900 ,כפר סבא ,581 ,גדנ"ע
6900 ,כפר סבא ,129 ,גדעון
6900 ,כפר סבא ,606 ,גובר רבקה
6900 ,כפר סבא ,312 ,גולד זאב
6900 ,כפר סבא ,296 ,גולדברג לאה
6900 ,כפר סבא ,409 ,גולדשטין
6900 ,כפר סבא ,313 ,גולומב
6900 ,כפר סבא ,513 ,גורדון
6900 ,כפר סבא ,388 ,גיסות השריון
6900 ,כפר סבא ,546 ,גלבוע
6900 ,כפר סבא ,1311 ,גלגלי פלדה
6900 ,כפר סבא ,578 ,גלעד
6900 ,כפר סבא ,529 ,גלר זאב
6900 ,כפר סבא ,384 ,גן השרון
6900 ,כפר סבא ,6561 ,גני השרון
6900 ,כפר סבא ,365 ,גרוסמן חייקה
6900 ,כפר סבא ,314 ,גרץ
6900 ,כפר סבא ,174 ,דב לאוטמן
6900 ,כפר סבא ,128 ,דבורה הנביאה
6900 ,כפר סבא ,133 ,דוד המלך
6900 ,כפר סבא ,471 ,דודאים
6900 ,כפר סבא ,275 ,דוכיפת
6900 ,כפר סבא ,595 ,דולב
6900 ,כפר סבא ,852 ,דיין משה
6900 ,כפר סבא ,263 ,דינור
6900 ,כפר סבא ,708 ,דליה
6900 ,כפר סבא ,682 ,דמארי שושנה
6900 ,כפר סבא ,107 ,דניאל
6900 ,כפר סבא ,594 ,דקל
6900 ,כפר סבא ,171 ,דרך אפולוניה
6900 ,כפר סבא ,172 ,דרך אפק
6900 ,כפר סבא ,173 ,דרך הים
6900 ,כפר סבא ,270 ,דרך המוביל
6900 ,כפר סבא ,164 ,דרך המלך
6900 ,כפר סבא ,277 ,דרך הפועל
6900 ,כפר סבא ,483 ,דרך השרון
6900 ,כפר סבא ,255 ,דרך קדומים
6900 ,כפר סבא ,716 ,האגוז
6900 ,כפר סבא ,576 ,האורנים
6900 ,כפר סבא ,568 ,האחדות
6900 ,כפר סבא ,151 ,האילנות
6900 ,כפר סבא ,591 ,האלה
6900 ,כפר סבא ,315 ,האמוראים
6900 ,כפר סבא ,598 ,האמת
6900 ,כפר סבא ,160 ,האקליפטוס
6900 ,כפר סבא ,572 ,הארזים
6900 ,כפר סבא ,855 ,האשכולית
6900 ,כפר סבא ,159 ,האשל
6900 ,כפר סבא ,585 ,האתרוג
6900 ,כפר סבא ,387 ,הבנים
6900 ,כפר סבא ,351 ,הבריגדה
6900 ,כפר סבא ,608 ,הגדוד העברי
6900 ,כפר סבא ,573 ,הגולן
6900 ,כפר סבא ,316 ,הגליל
6900 ,כפר סבא ,411 ,הגנה
6900 ,כפר סבא ,149 ,הגפן
6900 ,כפר סבא ,515 ,הגר"א
6900 ,כפר סבא ,317 ,הדר
6900 ,כפר סבא ,516 ,ההסתדרות
6900 ,כפר סבא ,583 ,ההשכלה
6900 ,כפר סבא ,318 ,הוז דב
6900 ,כפר סבא ,162 ,הורביץ אלי
6900 ,כפר סבא ,108 ,הושע
6900 ,כפר סבא ,154 ,הזית
6900 ,כפר סבא ,468 ,הזמר
6900 ,כפר סבא ,584 ,החורשה
6900 ,כפר סבא ,319 ,החי"ל
6900 ,כפר סבא ,262 ,החי"ש
6900 ,כפר סבא ,477 ,החן
6900 ,כפר סבא ,152 ,החרוב
6900 ,כפר סבא ,1303 ,החרושת
6900 ,כפר סבא ,575 ,החרמון
6900 ,כפר סבא ,463 ,הטחנה
6900 ,כפר סבא ,413 ,הידיד
6900 ,כפר סבא ,167 ,הידידות
6900 ,כפר סבא ,1309 ,היוזמה
6900 ,כפר סבא ,207 ,היוצרים
6900 ,כפר סבא ,574 ,הירדן
6900 ,כפר סבא ,414 ,הכובשים
6900 ,כפר סבא ,592 ,הככר
6900 ,כפר סבא ,549 ,הכלנית
6900 ,כפר סבא ,320 ,הכנסת
6900 ,כפר סבא ,145 ,הכרם
6900 ,כפר סבא ,321 ,הכרמל
6900 ,כפר סבא ,109 ,הל"ה
6900 ,כפר סבא ,582 ,הלימון
6900 ,כפר סבא ,168 ,הלפיד
6900 ,כפר סבא ,456 ,המגשימים
6900 ,כפר סבא ,138 ,המחתרות
6900 ,כפר סבא ,571 ,המיסדים
6900 ,כפר סבא ,110 ,המלכים
6900 ,כפר סבא ,1310 ,המנופים
6900 ,כפר סבא ,1301 ,המסגר
6900 ,כפר סבא ,453 ,המעלות
6900 ,כפר סבא ,322 ,המעפילים
6900 ,כפר סבא ,396 ,המפלס
6900 ,כפר סבא ,675 ,המשורר יצחק
6900 ,כפר סבא ,406 ,המשטרה
6900 ,כפר סבא ,111 ,הנביאים
6900 ,כפר סבא ,264 ,הנגב
6900 ,כפר סבא ,1304 ,הנגר
6900 ,כפר סבא ,663 ,הנדל נחמה
6900 ,כפר סבא ,324 ,הס
6900 ,כפר סבא ,208 ,הסדנא
6900 ,כפר סבא ,325 ,הסנהדרין
6900 ,כפר סבא ,326 ,העבודה
6900 ,כפר סבא ,273 ,העגורים
6900 ,כפר סבא ,410 ,העליה השניה
6900 ,כפר סבא ,518 ,העמק
6900 ,כפר סבא ,389 ,העצמאות
6900 ,כפר סבא ,416 ,הפועל הצעיר
6900 ,כפר סבא ,417 ,הפורצים
6900 ,כפר סבא ,519 ,הפרדס
6900 ,כפר סבא ,602 ,הפרחים
6900 ,כפר סבא ,210 ,הפרסה
6900 ,כפר סבא ,711 ,הצבעוני
6900 ,כפר סבא ,156 ,הצבר
6900 ,כפר סבא ,593 ,הצדק
6900 ,כפר סבא ,600 ,הצומת
6900 ,כפר סבא ,460 ,הצנחנים
6900 ,כפר סבא ,705 ,הקשת
6900 ,כפר סבא ,630 ,הר אדום
6900 ,כפר סבא ,634 ,הר אדיר
6900 ,כפר סבא ,629 ,הר בוקר
6900 ,כפר סבא ,627 ,הר גריזים
6900 ,כפר סבא ,632 ,הר חזון
6900 ,כפר סבא ,622 ,הר חריף
6900 ,כפר סבא ,636 ,הר כמון
6900 ,כפר סבא ,631 ,הר כנען
6900 ,כפר סבא ,624 ,הר כרכום
6900 ,כפר סבא ,621 ,הר מירון
6900 ,כפר סבא ,633 ,הר נבו
6900 ,כפר סבא ,628 ,הר עצמון
6900 ,כפר סבא ,623 ,הר צין
6900 ,כפר סבא ,635 ,הר רביד
6900 ,כפר סבא ,626 ,הר רותם
6900 ,כפר סבא ,625 ,הר שוקף
6900 ,כפר סבא ,620 ,הר תבור
6900 ,כפר סבא ,132 ,הראשונים
6900 ,כפר סבא ,375 ,הרב אסף שמחה
6900 ,כפר סבא ,349 ,הרב עמיאל
6900 ,כפר סבא ,358 ,הרב קוק
6900 ,כפר סבא ,596 ,הרדוף
6900 ,כפר סבא ,155 ,הרימון
6900 ,כפר סבא ,397 ,הרמה
6900 ,כפר סבא ,166 ,הרעות
6900 ,כפר סבא ,551 ,הרצוג
6900 ,כפר סבא ,520 ,הרצל
6900 ,כפר סבא ,301 ,הרצפלד אברהם
6900 ,כפר סבא ,545 ,הרקפת
6900 ,כפר סבא ,415 ,השומר
6900 ,כפר סבא ,112 ,השופטים
6900 ,כפר סבא ,327 ,השחר
6900 ,כפר סבא ,161 ,השיטה
6900 ,כפר סבא ,126 ,השילוני
6900 ,כפר סבא ,469 ,השיר
6900 ,כפר סבא ,462 ,השלום
6900 ,כפר סבא ,265 ,השפלה
6900 ,כפר סבא ,328 ,השקדים
6900 ,כפר סבא ,148 ,השקמה
6900 ,כפר סבא ,552 ,השרון
6900 ,כפר סבא ,150 ,התאנה
6900 ,כפר סבא ,1308 ,התוכנה
6900 ,כפר סבא ,851 ,התותחנים
6900 ,כפר סבא ,521 ,התחיה
6900 ,כפר סבא ,153 ,התמר
6900 ,כפר סבא ,329 ,התנאים
6900 ,כפר סבא ,205 ,התע"ש
6900 ,כפר סבא ,566 ,התפוז
6900 ,כפר סבא ,223 ,וולך יונה
6900 ,כפר סבא ,687 ,ויינברג
6900 ,כפר סבא ,660 ,וילנסקי משה
6900 ,כפר סבא ,523 ,ויצמן
6900 ,כפר סבא ,678 ,וישני
6900 ,כפר סבא ,418 ,ויתקין
6900 ,כפר סבא ,330 ,ז'בוטינסקי
6900 ,כפר סבא ,674 ,זהבי דוד
6900 ,כפר סבא ,669 ,זטלר
6900 ,כפר סבא ,281 ,זכריה הנביא
6900 ,כפר סבא ,717 ,זלדה המשוררת
6900 ,כפר סבא ,689 ,זליוונסקי
6900 ,כפר סבא ,485 ,זמיר
6900 ,כפר סבא ,331 ,זמנהוף
6900 ,כפר סבא ,464 ,חב"ד
6900 ,כפר סבא ,544 ,חבצלת השרון
6900 ,כפר סבא ,260 ,חבקוק
6900 ,כפר סבא ,394 ,חברון
6900 ,כפר סבא ,291 ,חגי
6900 ,כפר סבא ,710 ,חובבי ציון
6900 ,כפר סבא ,276 ,חוחית
6900 ,כפר סבא ,130 ,חולדה הנביאה
6900 ,כפר סבא ,607 ,חומה ומגדל
6900 ,כפר סבא ,332 ,חורגין
6900 ,כפר סבא ,685 ,חזה עפרה
6900 ,כפר סבא ,382 ,חטיבת אלכסנדרוני
6900 ,כפר סבא ,395 ,חטיבת גולני
6900 ,כפר סבא ,386 ,חטיבת הבקעה
6900 ,כפר סבא ,378 ,חטיבת הנח"ל
6900 ,כפר סבא ,579 ,חטיבת הראל
6900 ,כפר סבא ,385 ,חטיבת יפתח
6900 ,כפר סבא ,654 ,חיטמן עוזי
6900 ,כפר סבא ,677 ,חיים
6900 ,כפר סבא ,699 ,חיימוביץ
6900 ,כפר סבא ,230 ,חנה
6900 ,כפר סבא ,344 ,חניתה
6900 ,כפר סבא ,224 ,חנני יוסף
6900 ,כפר סבא ,488 ,חסידה
6900 ,כפר סבא ,333 ,חצרות הדר
6900 ,כפר סבא ,399 ,חרצית
6900 ,כפר סבא ,419 ,טהון
6900 ,כפר סבא ,334 ,טירת צבי
6900 ,כפר סבא ,525 ,טרומן
6900 ,כפר סבא ,524 ,טרומפלדור
6900 ,כפר סבא ,420 ,טשרניחובסקי
6900 ,כפר סבא ,393 ,י"א הספורטאים
6900 ,כפר סבא ,335 ,יבנה
6900 ,כפר סבא ,714 ,יבניאלי
6900 ,כפר סבא ,1302 ,יד חרוצים
6900 ,כפר סבא ,609 ,ידין יגאל
6900 ,כפר סבא ,588 ,יהודה
6900 ,כפר סבא ,336 ,יהודה הלוי
6900 ,כפר סבא ,136 ,יהודה המכבי
6900 ,כפר סבא ,337 ,יהודה הנשיא
6900 ,כפר סבא ,526 ,יהלום
6900 ,כפר סבא ,113 ,יואל
6900 ,כפר סבא ,206 ,יוחנן הסנדלר
6900 ,כפר סבא ,114 ,יונה
6900 ,כפר סבא ,666 ,יוסף הדר
6900 ,כפר סבא ,115 ,יחזקאל
6900 ,כפר סבא ,338 ,יחיעם
6900 ,כפר סבא ,116 ,יל"ג
6900 ,כפר סבא ,141 ,ינאי המלך
6900 ,כפר סבא ,530 ,יסמין
6900 ,כפר סבא ,220 ,יעל
6900 ,כפר סבא ,472 ,יערה
6900 ,כפר סבא ,683 ,יצחק זיקו גרציאני
6900 ,כפר סבא ,550 ,יציאת אירופה
6900 ,כפר סבא ,527 ,ירושלים
6900 ,כפר סבא ,117 ,ירמיהו
6900 ,כפר סבא ,118 ,ישעיהו
6900 ,כפר סבא ,439 ,ישראל ישעיהו
6900 ,כפר סבא ,339 ,כהן
6900 ,כפר סבא ,421 ,כנפי נשרים
6900 ,כפר סבא ,528 ,כנרת
6900 ,כפר סבא ,9000 ,כפר סבא
6900 ,כפר סבא ,340 ,כצנלסון ברל
6900 ,כפר סבא ,536 ,לב הכפר
6900 ,כפר סבא ,455 ,לבון פנחס
6900 ,כפר סבא ,256 ,לבונה
6900 ,כפר סבא ,681 ,לביא אריק
6900 ,כפר סבא ,702 ,לובטקין צביה
6900 ,כפר סבא ,673 ,לוונברג
6900 ,כפר סבא ,672 ,לוי
6900 ,כפר סבא ,423 ,לוינסקי
6900 ,כפר סבא ,422 ,לופבן
6900 ,כפר סבא ,424 ,לילנבלום
6900 ,כפר סבא ,341 ,לסל
6900 ,כפר סבא ,684 ,מאיר אריאל
6900 ,כפר סבא ,342 ,מאפו
6900 ,כפר סבא ,379 ,מבצע יואב
6900 ,כפר סבא ,367 ,מבצע יונתן
6900 ,כפר סבא ,450 ,מגדלי ים התיכון
6900 ,כפר סבא ,658 ,מוהר עלי
6900 ,כפר סבא ,720 ,מוטה גור
6900 ,כפר סבא ,257 ,מור
6900 ,כפר סבא ,425 ,מורדי הגטאות
6900 ,כפר סבא ,473 ,מורן
6900 ,כפר סבא ,474 ,מזור
6900 ,כפר סבא ,696 ,מזרחי
6900 ,כפר סבא ,119 ,מיכה
6900 ,כפר סבא ,221 ,מיכל
6900 ,כפר סבא ,664 ,מנור אהוד
6900 ,כפר סבא ,6521 ,מעונות עממים
6900 ,כפר סבא ,569 ,מעלה האורנים
6900 ,כפר סבא ,475 ,מענית
6900 ,כפר סבא ,514 ,מפא"י
6900 ,כפר סבא ,137 ,מצדה
6900 ,כפר סבא ,427 ,מרבד הקסמים
6900 ,כפר סבא ,225 ,מרדכי
6900 ,כפר סבא ,217 ,מרים
6900 ,כפר סבא ,426 ,מרכוס
6900 ,כפר סבא ,140 ,מרכז קליטה ספיר
6900 ,כפר סבא ,258 ,משעול בית הבד
6900 ,כפר סבא ,801 ,משעול גיל
6900 ,כפר סבא ,170 ,משעול האהבה
6900 ,כפר סבא ,482 ,משעול האר"י
6900 ,כפר סבא ,599 ,משעול החיים
6900 ,כפר סבא ,169 ,משעול הסובלנות
6900 ,כפר סבא ,580 ,משעול הקלע
6900 ,כפר סבא ,567 ,משעול רשף
6900 ,כפר סבא ,286 ,משק האוצר
6900 ,כפר סבא ,6545 ,מתחם התחנה המרכזית
6900 ,כפר סבא ,6522 ,נאות דשא
6900 ,כפר סבא ,6535 ,נווה הדרים
6900 ,כפר סבא ,6514 ,נווה סורקיס
6900 ,כפר סבא ,553 ,נורדאו
6900 ,כפר סבא ,707 ,נורית
6900 ,כפר סבא ,259 ,נחום
6900 ,כפר סבא ,650 ,נחל הדס
6900 ,כפר סבא ,651 ,נחל קנה
6900 ,כפר סבא ,271 ,נחליאלי
6900 ,כפר סבא ,120 ,נחמיה
6900 ,כפר סבא ,531 ,נחשון
6900 ,כפר סבא ,428 ,נטר קרל
6900 ,כפר סבא ,709 ,ניל"י
6900 ,כפר סבא ,345 ,ניצנים
6900 ,כפר סבא ,556 ,ניר
6900 ,כפר סבא ,679 ,נלקין
6900 ,כפר סבא ,323 ,נעורים
6900 ,כפר סבא ,218 ,נעמי
6900 ,כפר סבא ,495 ,נרקיס עוזי
6900 ,כפר סבא ,492 ,נשר
6900 ,כפר סבא ,458 ,נתיב האבות
6900 ,כפר סבא ,282 ,נתן הנביא
6900 ,כפר סבא ,719 ,סביון
6900 ,כפר סבא ,6516 ,סביוני הכפר
6900 ,כפר סבא ,346 ,סולד
6900 ,כפר סבא ,690 ,סוסנובסקי
6900 ,כפר סבא ,532 ,סוקולוב
6900 ,כפר סבא ,704 ,סיגלית
6900 ,כפר סבא ,703 ,סייפן
6900 ,כפר סבא ,484 ,סיני
6900 ,כפר סבא ,429 ,סירני
6900 ,כפר סבא ,430 ,סירקין
6900 ,כפר סבא ,695 ,סלור
6900 ,כפר סבא ,139 ,סמ אביבים
6900 ,כפר סבא ,266 ,סמ אנוש
6900 ,כפר סבא ,491 ,סמ בז
6900 ,כפר סבא ,487 ,סמ דיה
6900 ,כפר סבא ,751 ,סמ הדרור
6900 ,כפר סבא ,267 ,סמ החושן
6900 ,כפר סבא ,803 ,סמ מעון
6900 ,כפר סבא ,143 ,סמ רון
6900 ,כפר סבא ,489 ,סמ שחף
6900 ,כפר סבא ,712 ,סמטת אברהם
6900 ,כפר סבא ,457 ,סמילנסקי
6900 ,כפר סבא ,480 ,סנה משה
6900 ,כפר סבא ,347 ,סנש
6900 ,כפר סבא ,686 ,ספיר
6900 ,כפר סבא ,668 ,סקיבין
6900 ,כפר סבא ,229 ,עגנון
6900 ,כפר סבא ,283 ,עובדיה הנביא
6900 ,כפר סבא ,452 ,עוגן
6900 ,כפר סבא ,348 ,עוזיאל
6900 ,כפר סבא ,121 ,עזרא
6900 ,כפר סבא ,490 ,עיט
6900 ,כפר סבא ,540 ,עין גדי
6900 ,כפר סבא ,451 ,עליה
6900 ,כפר סבא ,122 ,עמוס
6900 ,כפר סבא ,555 ,עמל
6900 ,כפר סבא ,653 ,עמנואל זמיר
6900 ,כפר סבא ,234 ,עמק אילון
6900 ,כפר סבא ,239 ,עמק בית שאן
6900 ,כפר סבא ,232 ,עמק דותן
6900 ,כפר סבא ,235 ,עמק האלה
6900 ,כפר סבא ,238 ,עמק החולה
6900 ,כפר סבא ,231 ,עמק זבולון
6900 ,כפר סבא ,243 ,עמק חורון
6900 ,כפר סבא ,233 ,עמק חפר
6900 ,כפר סבא ,237 ,עמק חרוד
6900 ,כפר סבא ,240 ,עמק יזרעאל
6900 ,כפר סבא ,236 ,עמק עירון
6900 ,כפר סבא ,538 ,עמרמי
6900 ,כפר סבא ,272 ,עפרוני
6900 ,כפר סבא ,350 ,עציון
6900 ,כפר סבא ,157 ,ערבה
6900 ,כפר סבא ,479 ,ערוגות הבושם
6900 ,כפר סבא ,1307 ,עתירי ידע
6900 ,כפר סבא ,431 ,פועלי ציון
6900 ,כפר סבא ,352 ,פיארברג
6900 ,כפר סבא ,353 ,פינס
6900 ,כפר סבא ,432 ,פלמ"ח
6900 ,כפר סבא ,354 ,פנקס
6900 ,כפר סבא ,433 ,פרוג שמעון
6900 ,כפר סבא ,697 ,פריבר
6900 ,כפר סבא ,355 ,פרישמן
6900 ,כפר סבא ,676 ,פרנקל פאבל
6900 ,כפר סבא ,356 ,פרץ
6900 ,כפר סבא ,656 ,פררה אברהם
6900 ,כפר סבא ,274 ,פשוש
6900 ,כפר סבא ,597 ,צאלון
6900 ,כפר סבא ,142 ,צדקיהו
6900 ,כפר סבא ,434 ,צה"ל
6900 ,כפר סבא ,435 ,צייטלין
6900 ,כפר סבא ,261 ,צפניה
6900 ,כפר סבא ,698 ,קארלינסקי
6900 ,כפר סבא ,436 ,קבוץ גלויות
6900 ,כפר סבא ,398 ,קבוצת ל"ג
6900 ,כפר סבא ,357 ,קדושי בלזן
6900 ,כפר סבא ,481 ,קדושי קהיר
6900 ,כפר סבא ,294 ,קדיש לוז
6900 ,כפר סבא ,295 ,קובנר אבא
6900 ,כפר סבא ,437 ,קורצ'ק
6900 ,כפר סבא ,359 ,קלישר
6900 ,כפר סבא ,692 ,קמינסקי
6900 ,כפר סבא ,438 ,קפלנסקי
6900 ,כפר סבא ,6515 ,קרית הרצוג
6900 ,כפר סבא ,6534 ,קרית ספיר
6900 ,כפר סבא ,517 ,קרן אברהם
6900 ,כפר סבא ,652 ,קרן היסוד
6900 ,כפר סבא ,655 ,קרן המוסיקה
6900 ,כפר סבא ,665 ,רוזנבלום יאיר
6900 ,כפר סבא ,534 ,רוטשילד
6900 ,כפר סבא ,535 ,רופין
6900 ,כפר סבא ,219 ,רות
6900 ,כפר סבא ,465 ,רזיאל דוד
6900 ,כפר סבא ,1306 ,רחבת מיכאל
6900 ,כפר סבא ,308 ,רחל המשוררת
6900 ,כפר סבא ,688 ,ריזנבך
6900 ,כפר סבא ,360 ,ריינס
6900 ,כפר סבא ,440 ,רייק חביבה
6900 ,כפר סבא ,441 ,רינגלבלום
6900 ,כפר סבא ,361 ,רמב"ם
6900 ,כפר סבא ,442 ,רמז דוד
6900 ,כפר סבא ,123 ,רמח"ל
6900 ,כפר סבא ,381 ,רעם
6900 ,כפר סבא ,667 ,רפפורט
6900 ,כפר סבא ,362 ,רש"י
6900 ,כפר סבא ,6562 ,ש אלי כהן
6900 ,כפר סבא ,6523 ,ש אליעזר
6900 ,כפר סבא ,6501 ,ש אסבסט קפלן
6900 ,כפר סבא ,6524 ,ש אקרמן
6900 ,כפר סבא ,6525 ,ש גרין
6900 ,כפר סבא ,6526 ,ש דגניה
6900 ,כפר סבא ,6510 ,ש הדרים
6900 ,כפר סבא ,6503 ,ש ותיקים
6900 ,כפר סבא ,6504 ,ש יוספטל
6900 ,כפר סבא ,6563 ,ש כסופים
6900 ,כפר סבא ,6528 ,ש למפרט
6900 ,כפר סבא ,6541 ,ש מאוריציוס
6900 ,כפר סבא ,6564 ,ש מוצקין
6900 ,כפר סבא ,6565 ,ש מזרחי
6900 ,כפר סבא ,6529 ,ש מלל
6900 ,כפר סבא ,6530 ,ש מעוז
6900 ,כפר סבא ,6566 ,ש משכנות
6900 ,כפר סבא ,6531 ,ש עוב ב ציון
6900 ,כפר סבא ,6505 ,ש עולים
6900 ,כפר סבא ,6506 ,ש עליה
6900 ,כפר סבא ,6507 ,ש עמידר
6900 ,כפר סבא ,6532 ,ש עממי
6900 ,כפר סבא ,6533 ,ש פרוגרסיבים
6900 ,כפר סבא ,6508 ,ש קפלן
6900 ,כפר סבא ,6542 ,ש תקומה
6900 ,כפר סבא ,134 ,שאול המלך
6900 ,כפר סבא ,444 ,שב"ד
6900 ,כפר סבא ,445 ,שבזי
6900 ,כפר סבא ,412 ,שד בגין מנחם
6900 ,כפר סבא ,144 ,שד גאולים
6900 ,כפר סבא ,125 ,שד"ל
6900 ,כפר סבא ,363 ,שדה יצחק
6900 ,כפר סבא ,466 ,שדרת המסוק
6900 ,כפר סבא ,227 ,שולמית
6900 ,כפר סבא ,589 ,שומרון
6900 ,כפר סבא ,548 ,שועלי שמשון
6900 ,כפר סבא ,369 ,שח"ל
6900 ,כפר סבא ,467 ,שטרן יאיר
6900 ,כפר סבא ,446 ,שיבת ציון
6900 ,כפר סבא ,293 ,שיזף
6900 ,כפר סבא ,670 ,שיינפיין
6900 ,כפר סבא ,447 ,שילר
6900 ,כפר סבא ,448 ,שיפר
6900 ,כפר סבא ,6502 ,שכ גאולים
6900 ,כפר סבא ,6519 ,שכ הזמר העברי
6900 ,כפר סבא ,6513 ,שכ החלוצים
6900 ,כפר סבא ,6567 ,שכ הפארק
6900 ,כפר סבא ,6570 ,שכ חצרות הדר
6900 ,כפר סבא ,6520 ,שכ ירוקה
6900 ,כפר סבא ,6560 ,שכ כפר סבא הצעירה
6900 ,כפר סבא ,6568 ,שכ עלומים
6900 ,כפר סבא ,6569 ,שכ פעמוני גליל
6900 ,כפר סבא ,6509 ,שכ רגבים
6900 ,כפר סבא ,6527 ,שכון העובד
6900 ,כפר סבא ,364 ,שלום עליכם
6900 ,כפר סבא ,718 ,שלונסקי
6900 ,כפר סבא ,135 ,שלמה המלך
6900 ,כפר סבא ,454 ,שמחה הולצברג
6900 ,כפר סבא ,371 ,שמחוני אסף
6900 ,כפר סבא ,537 ,שמעוני
6900 ,כפר סבא ,284 ,שמעיה הנביא
6900 ,כפר סבא ,661 ,שמר נעמי
6900 ,כפר סבא ,449 ,שמריהו לוין
6900 ,כפר סבא ,611 ,שניר
6900 ,כפר סבא ,372 ,שפרינצק
6900 ,כפר סבא ,228 ,שרה
6900 ,כפר סבא ,570 ,שרת משה
6900 ,כפר סבא ,533 ,ששת הימים
6900 ,כפר סבא ,605 ,תאשור
6900 ,כפר סבא ,539 ,תבור
6900 ,כפר סבא ,604 ,תדהר
6900 ,כפר סבא ,486 ,תור
6900 ,כפר סבא ,373 ,תורה ועבודה
6900 ,כפר סבא ,459 ,תל אביב-יפו
6900 ,כפר סבא ,374 ,תל חי
6900 ,כפר סבא ,476 ,תלם
6900 ,כפר סבא ,127 ,תרי עשר
107 ,כפר סילבר ,9000 ,כפר סילבר
249 ,כפר סירקין ,101 ,דרך אפק
249 ,כפר סירקין ,113 ,דרך השדות
249 ,כפר סירקין ,102 ,האילנות
249 ,כפר סירקין ,103 ,האירוסים
249 ,כפר סירקין ,104 ,הבנים
249 ,כפר סירקין ,105 ,הגפנים
249 ,כפר סירקין ,106 ,הדקלים
249 ,כפר סירקין ,114 ,ההרדופים
249 ,כפר סירקין ,107 ,הורדים
249 ,כפר סירקין ,108 ,הכלניות
249 ,כפר סירקין ,109 ,המרגניות
249 ,כפר סירקין ,110 ,הנרקיסים
249 ,כפר סירקין ,111 ,הסביונים
249 ,כפר סירקין ,112 ,הקציר
249 ,כפר סירקין ,9000 ,כפר סירקין
875 ,כפר עבודה ,9000 ,כפר עבודה
845 ,כפר עזה ,9000 ,כפר עזה
3488 ,כפר עציון ,101 ,ישיבת כפר עציון
3488 ,כפר עציון ,9000 ,כפר עציון
189 ,כפר פינס ,113 ,אוהל משה
189 ,כפר פינס ,111 ,דרך האלונים
189 ,כפר פינס ,101 ,דרך השדות
189 ,כפר פינס ,108 ,האורנים
189 ,כפר פינס ,109 ,הזיתים
189 ,כפר פינס ,110 ,החלוץ
189 ,כפר פינס ,102 ,הסילו
189 ,כפר פינס ,106 ,הסליק
189 ,כפר פינס ,103 ,הפועל המזרחי
189 ,כפר פינס ,9000 ,כפר פינס
189 ,כפר פינס ,105 ,מגדל המים
189 ,כפר פינס ,104 ,סמטת הגיבורים
189 ,כפר פינס ,114 ,קבוצת אברהם
189 ,כפר פינס ,112 ,שלום קרניאל
189 ,כפר פינס ,107 ,תורה ועבודה
634 ,כפר קאסם ,148 ,א זהרא
634 ,כפר קאסם ,189 ,א ראזי
634 ,כפר קאסם ,146 ,א רחמה
634 ,כפר קאסם ,164 ,א שאפי
634 ,כפר קאסם ,176 ,א שוהדא
634 ,כפר קאסם ,143 ,א- זיתון
634 ,כפר קאסם ,202 ,א- נוזהה
634 ,כפר קאסם ,101 ,א- סולטאני
634 ,כפר קאסם ,205 ,א- רדואן
634 ,כפר קאסם ,201 ,א- רמלה
634 ,כפר קאסם ,106 ,א-נור
634 ,כפר קאסם ,121 ,א-נסר
634 ,כפר קאסם ,226 ,א-ספא
634 ,כפר קאסם ,109 ,א-רבאט
634 ,כפר קאסם ,219 ,א-רוודא
634 ,כפר קאסם ,118 ,א-רשיד
634 ,כפר קאסם ,120 ,א-תוובה
634 ,כפר קאסם ,155 ,אבו בכר
634 ,כפר קאסם ,132 ,אבו חניפה
634 ,כפר קאסם ,105 ,אבו עמרן
634 ,כפר קאסם ,335 ,אבן אלערבי
634 ,כפר קאסם ,131 ,אבן חזם
634 ,כפר קאסם ,130 ,אבן חלדון
634 ,כפר קאסם ,334 ,אבן סירין
634 ,כפר קאסם ,336 ,אבן קת'יר
634 ,כפר קאסם ,216 ,אבן רושד
634 ,כפר קאסם ,209 ,אבן תיימייה
634 ,כפר קאסם ,340 ,אברהים ח'ליל אללה
634 ,כפר קאסם ,151 ,אוחוד
634 ,כפר קאסם ,6733 ,אזור שרובו בדואים
634 ,כפר קאסם ,339 ,אחמד בן חנבל
634 ,כפר קאסם ,136 ,אחמד שוויקי
634 ,כפר קאסם ,338 ,אל אוח'ווה
634 ,כפר קאסם ,213 ,אל אמל
634 ,כפר קאסם ,220 ,אל אנדלוס
634 ,כפר קאסם ,102 ,אל אנסאר
634 ,כפר קאסם ,127 ,אל אסרא
634 ,כפר קאסם ,169 ,אל אקצא
634 ,כפר קאסם ,107 ,אל ארד
634 ,כפר קאסם ,301 ,אל ארז
634 ,כפר קאסם ,135 ,אל בורק
634 ,כפר קאסם ,157 ,אל ביאדר
634 ,כפר קאסם ,211 ,אל בלאד
634 ,כפר קאסם ,188 ,אל ג'לאני
634 ,כפר קאסם ,218 ,אל הודא
634 ,כפר קאסם ,117 ,אל היג'רה
634 ,כפר קאסם ,323 ,אל וואחה
634 ,כפר קאסם ,163 ,אל וחדה
634 ,כפר קאסם ,129 ,אל וטן
634 ,כפר קאסם ,180 ,אל חליל
634 ,כפר קאסם ,245 ,אל חנדק
634 ,כפר קאסם ,114 ,אל חנסא
634 ,כפר קאסם ,140 ,אל חק
634 ,כפר קאסם ,172 ,אל חרם
634 ,כפר קאסם ,125 ,אל ירמוכ
634 ,כפר קאסם ,158 ,אל כראמה
634 ,כפר קאסם ,318 ,אל לוז
634 ,כפר קאסם ,222 ,אל מג'דל
634 ,כפר קאסם ,217 ,אל מג'זרה
634 ,כפר קאסם ,161 ,אל מדינה
634 ,כפר קאסם ,159 ,אל מוהאריגון
634 ,כפר קאסם ,113 ,אל מורוג
634 ,כפר קאסם ,137 ,אל מחפור
634 ,כפר קאסם ,200 ,אל מיזאן
634 ,כפר קאסם ,227 ,אל מנאר
634 ,כפר קאסם ,145 ,אל מנשייה
634 ,כפר קאסם ,242 ,אל מרווה
634 ,כפר קאסם ,112 ,אל ע'זאלי
634 ,כפר קאסם ,141 ,אל עוודה
634 ,כפר קאסם ,320 ,אל עפוו
634 ,כפר קאסם ,111 ,אל עקאד
634 ,כפר קאסם ,351 ,אל פל
634 ,כפר קאסם ,116 ,אל פתח
634 ,כפר קאסם ,171 ,אל קודס
634 ,כפר קאסם ,104 ,אל קסאם
634 ,כפר קאסם ,365 ,אל קריה
634 ,כפר קאסם ,303 ,אל תופאח
634 ,כפר קאסם ,362 ,אלאנפאל
634 ,כפר קאסם ,376 ,אלבאבור
634 ,כפר קאסם ,308 ,אלבהאא
634 ,כפר קאסם ,329 ,אלבולבול
634 ,כפר קאסם ,393 ,אלבוראק
634 ,כפר קאסם ,373 ,אלבלוט
634 ,כפר קאסם ,357 ,אלבראעם
634 ,כפר קאסם ,360 ,אלג'בל
634 ,כפר קאסם ,383 ,אלג'ולאן
634 ,כפר קאסם ,380 ,אלג'ורי
634 ,כפר קאסם ,377 ,אלג'ליל
634 ,כפר קאסם ,389 ,אלג'נאין
634 ,כפר קאסם ,322 ,אלגאר
634 ,כפר קאסם ,382 ,אלגרוב
634 ,כפר קאסם ,352 ,אלדהאר
634 ,כפר קאסם ,349 ,אלדיוואן
634 ,כפר קאסם ,321 ,אלהודהוד
634 ,כפר קאסם ,326 ,אלהלאל
634 ,כפר קאסם ,353 ,אלוורד
634 ,כפר קאסם ,306 ,אלזעתר
634 ,כפר קאסם ,359 ,אלחבק
634 ,כפר קאסם ,333 ,אלחמראא
634 ,כפר קאסם ,388 ,אליאקוט
634 ,כפר קאסם ,358 ,אליסמין
634 ,כפר קאסם ,327 ,אלמג'ד
634 ,כפר קאסם ,342 ,אלמדיאן
634 ,כפר קאסם ,332 ,אלמונא
634 ,כפר קאסם ,384 ,אלמעלב
634 ,כפר קאסם ,348 ,אלמעראג'
634 ,כפר קאסם ,381 ,אלמערפה
634 ,כפר קאסם ,343 ,אלמקתבא
634 ,כפר קאסם ,371 ,אלמרח
634 ,כפר קאסם ,312 ,אלנבע
634 ,כפר קאסם ,311 ,אלנסים
634 ,כפר קאסם ,304 ,אלנסרין
634 ,כפר קאסם ,387 ,אלנקאא
634 ,כפר קאסם ,344 ,אלסאחאר
634 ,כפר קאסם ,364 ,אלסוק
634 ,כפר קאסם ,314 ,אלסיאד
634 ,כפר קאסם ,374 ,אלסלאח
634 ,כפר קאסם ,378 ,אלסמארה
634 ,כפר קאסם ,309 ,אלסנאא
634 ,כפר קאסם ,356 ,אלסנאבל
634 ,כפר קאסם ,395 ,אלסנדיאן
634 ,כפר קאסם ,372 ,אלסנובר
634 ,כפר קאסם ,354 ,אלספסאף
634 ,כפר קאסם ,315 ,אלסרוו
634 ,כפר קאסם ,337 ,אלעדל
634 ,כפר קאסם ,350 ,אלעטאא
634 ,כפר קאסם ,313 ,אלפאנוס
634 ,כפר קאסם ,347 ,אלפוראת
634 ,כפר קאסם ,325 ,אלקוות'ר
634 ,כפר קאסם ,370 ,אלקנארי
634 ,כפר קאסם ,341 ,אלראשדון
634 ,כפר קאסם ,307 ,אלריחאן
634 ,כפר קאסם ,305 ,אלש'גרה
634 ,כפר קאסם ,391 ,אלשאם
634 ,כפר קאסם ,379 ,אלשוויטא
634 ,כפר קאסם ,392 ,אשביליה
634 ,כפר קאסם ,361 ,בגדאד
634 ,כפר קאסם ,166 ,בדר
634 ,כפר קאסם ,178 ,בילאל
634 ,כפר קאסם ,363 ,בירות
634 ,כפר קאסם ,394 ,גרנאטא
634 ,כפר קאסם ,181 ,דיר יאסין
634 ,כפר קאסם ,330 ,דמשק
634 ,כפר קאסם ,6720 ,הגרעין הישן
634 ,כפר קאסם ,366 ,הדקלים
634 ,כפר קאסם ,367 ,הזית
634 ,כפר קאסם ,386 ,הפרדס
634 ,כפר קאסם ,385 ,הרימון
634 ,כפר קאסם ,6710 ,השכונה החדשה
634 ,כפר קאסם ,368 ,התאנה
634 ,כפר קאסם ,369 ,התמר
634 ,כפר קאסם ,310 ,ודיע סרסור
634 ,כפר קאסם ,345 ,זין
634 ,כפר קאסם ,152 ,חאלד בן אל וליד
634 ,כפר קאסם ,302 ,חבאיב
634 ,כפר קאסם ,108 ,חטין
634 ,כפר קאסם ,149 ,חיטין
634 ,כפר קאסם ,375 ,חיפה
634 ,כפר קאסם ,231 ,חמזה
634 ,כפר קאסם ,316 ,חנדלה
634 ,כפר קאסם ,147 ,חסאן בן תאבת
634 ,כפר קאסם ,6730 ,טאהא
634 ,כפר קאסם ,110 ,יאפא
634 ,כפר קאסם ,9000 ,כפר קאסם
634 ,כפר קאסם ,390 ,לשבונה
634 ,כפר קאסם ,123 ,מאלכ בן אנס
634 ,כפר קאסם ,142 ,מכה
634 ,כפר קאסם ,331 ,מרים אלמג'דליה
634 ,כפר קאסם ,6711 ,משפחת עיסא
634 ,כפר קאסם ,128 ,סלאח א-דין
634 ,כפר קאסם ,346 ,ספד
634 ,כפר קאסם ,244 ,עבד אל כרים קאסם
634 ,כפר קאסם ,319 ,עדן
634 ,כפר קאסם ,103 ,עומר בן אל חטאב
634 ,כפר קאסם ,115 ,עומר בן עבד אל עזיז
634 ,כפר קאסם ,150 ,עותמן אל עפאן
634 ,כפר קאסם ,317 ,עכא
634 ,כפר קאסם ,174 ,עלי בן אבו טאלב
634 ,כפר קאסם ,355 ,עמאן
634 ,כפר קאסם ,6731 ,צרצור
634 ,כפר קאסם ,324 ,קבאא
634 ,כפר קאסם ,328 ,ראס אלח'רוב
634 ,כפר קאסם ,6732 ,שכ הבדואים הדרומית
634 ,כפר קאסם ,6734 ,שכ הבדואים המזרחית
634 ,כפר קאסם ,160 ,תבוכ
634 ,כפר קאסם ,230 ,תמרינדי
388 ,כפר קיש ,118 ,בזלת
388 ,כפר קיש ,103 ,האלה
388 ,כפר קיש ,111 ,האלון
388 ,כפר קיש ,110 ,הברושים
388 ,כפר קיש ,112 ,הגפן
388 ,כפר קיש ,102 ,הזית
388 ,כפר קיש ,109 ,החורשה
388 ,כפר קיש ,115 ,החרוב
388 ,כפר קיש ,106 ,המייסדים
388 ,כפר קיש ,114 ,השדות
388 ,כפר קיש ,108 ,השיזף
388 ,כפר קיש ,113 ,השקד
388 ,כפר קיש ,107 ,התאנה
388 ,כפר קיש ,105 ,התמר
388 ,כפר קיש ,120 ,כוכב הירדן
388 ,כפר קיש ,116 ,כליל החורש
388 ,כפר קיש ,9000 ,כפר קיש
388 ,כפר קיש ,101 ,נוף תבור
388 ,כפר קיש ,104 ,סימטת הגנים
388 ,כפר קיש ,119 ,סירין
388 ,כפר קיש ,117 ,תל רכש
654 ,כפר קרע ,176 ,א-ד'הרה
654 ,כפר קרע ,672 ,א-זעפראן
654 ,כפר קרע ,363 ,א-זערורה
654 ,כפר קרע ,391 ,א-נבי אדם
654 ,כפר קרע ,379 ,א-נג'ם
654 ,כפר קרע ,188 ,א-ניל
654 ,כפר קרע ,145 ,א-ס'יף
654 ,כפר קרע ,361 ,א-סולטאן
654 ,כפר קרע ,689 ,א-סיידה עאאישה
654 ,כפר קרע ,265 ,א-סיכה
654 ,כפר קרע ,238 ,א-סרוב
654 ,כפר קרע ,131 ,א-רביע
654 ,כפר קרע ,277 ,א-רוודה
654 ,כפר קרע ,387 ,א-ריאד
654 ,כפר קרע ,154 ,א-שיתאא
654 ,כפר קרע ,380 ,א-שעב
654 ,כפר קרע ,155 ,אאב
654 ,כפר קרע ,199 ,אאד'אר
654 ,כפר קרע ,675 ,אאיאת
654 ,כפר קרע ,648 ,אאל עמראן
654 ,כפר קרע ,232 ,אבו אלקאסם אלשאבי
654 ,כפר קרע ,200 ,אבו בכר אלסאדיק
654 ,כפר קרע ,147 ,אבו הוריירה
654 ,כפר קרע ,172 ,אבו זינה
654 ,כפר קרע ,290 ,אבו ח'ושום
654 ,כפר קרע ,184 ,אבו חניפה
654 ,כפר קרע ,244 ,אבו סופיאן
654 ,כפר קרע ,656 ,אבו עוביידה
654 ,כפר קרע ,234 ,אבו תמאם
654 ,כפר קרע ,311 ,אבן ח'לדון
654 ,כפר קרע ,119 ,אבן סינאא
654 ,כפר קרע ,682 ,אבן רושד
654 ,כפר קרע ,630 ,אבן תימייה
654 ,כפר קרע ,246 ,אוחוד
654 ,כפר קרע ,340 ,אום אל-בסל
654 ,כפר קרע ,309 ,אחמד שווקי
654 ,כפר קרע ,706 ,איאר
654 ,כפר קרע ,703 ,איבן בטוטא
654 ,כפר קרע ,316 ,אילול
654 ,כפר קרע ,275 ,אל-אחסאן
654 ,כפר קרע ,274 ,אל-אמאני
654 ,כפר קרע ,359 ,אל-אס'מאעי
654 ,כפר קרע ,390 ,אל-ארז
654 ,כפר קרע ,278 ,אל-ארקם
654 ,כפר קרע ,272 ,אל-באטן
654 ,כפר קרע ,296 ,אל-בורה
654 ,כפר קרע ,382 ,אל-בלוט
654 ,כפר קרע ,356 ,אל-בסה
654 ,כפר קרע ,388 ,אל-ברקוק
654 ,כפר קרע ,708 ,אל-ג'ורי
654 ,כפר קרע ,384 ,אל-ג'ילאני
654 ,כפר קרע ,362 ,אל-הודא
654 ,כפר קרע ,226 ,אל-וידאד
654 ,כפר קרע ,381 ,אל-ח'ואריזמי
654 ,כפר קרע ,357 ,אל-ח'יראת
654 ,כפר קרע ,288 ,אל-ח'ריף
654 ,כפר קרע ,354 ,אל-חוסיין בן עלי
654 ,כפר קרע ,377 ,אל-חורש
654 ,כפר קרע ,701 ,אל-חיאה
654 ,כפר קרע ,271 ,אל-חמרה
654 ,כפר קרע ,358 ,אל-יאקות
654 ,כפר קרע ,600 ,אל-כוכב
654 ,כפר קרע ,360 ,אל-מדינה אל-מנורה
654 ,כפר קרע ,141 ,אל-מונתזה
654 ,כפר קרע ,383 ,אל-מכתבה
654 ,כפר קרע ,660 ,אל-מנארה
654 ,כפר קרע ,335 ,אל-מראח'
654 ,כפר קרע ,639 ,אל-עדאלה
654 ,כפר קרע ,385 ,אל-עסל
654 ,כפר קרע ,101 ,אל-עקד
654 ,כפר קרע ,389 ,אל-ערבי
654 ,כפר קרע ,318 ,אל-פוארס
654 ,כפר קרע ,276 ,אל-פיום
654 ,כפר קרע ,642 ,אל-קרע
654 ,כפר קרע ,247 ,אלאבראר
654 ,כפר קרע ,611 ,אלאדיב
654 ,כפר קרע ,328 ,אלאזדהאר
654 ,כפר קרע ,125 ,אלאזהר
654 ,כפר קרע ,151 ,אלאח'טל
654 ,כפר קרע ,695 ,אלאח'לאס
654 ,כפר קרע ,127 ,אלאחבה
654 ,כפר קרע ,169 ,אלאחראש
654 ,כפר קרע ,109 ,אלאמיר
654 ,כפר קרע ,162 ,אלאמירה
654 ,כפר קרע ,179 ,אלאמל
654 ,כפר קרע ,295 ,אלאנדלס
654 ,כפר קרע ,310 ,אלאנואר
654 ,כפר קרע ,217 ,אלאסראא
654 ,כפר קרע ,355 ,אלאציל
654 ,כפר קרע ,216 ,אלאקצה
654 ,כפר קרע ,638 ,אלאת'יר
654 ,כפר קרע ,298 ,אלאתאר
654 ,כפר קרע ,314 ,אלבאבור
654 ,כפר קרע ,6701 ,אלבאר
654 ,כפר קרע ,136 ,אלבוסתאן
654 ,כפר קרע ,126 ,אלבח'ארי
654 ,כפר קרע ,668 ,אלביאדה
654 ,כפר קרע ,6725 ,אלביאדר
654 ,כפר קרע ,237 ,אלביר
654 ,כפר קרע ,115 ,אלבלסם
654 ,כפר קרע ,255 ,אלבנין
654 ,כפר קרע ,285 ,אלבראא
654 ,כפר קרע ,344 ,אלברג
654 ,כפר קרע ,676 ,אלבשרא
654 ,כפר קרע ,299 ,אלג'אמע
654 ,כפר קרע ,6727 ,אלג'ולאן
654 ,כפר קרע ,698 ,אלג'נה
654 ,כפר קרע ,146 ,אלגאר
654 ,כפר קרע ,263 ,אלגדאוול
654 ,כפר קרע ,678 ,אלגדיר
654 ,כפר קרע ,106 ,אלגוז
654 ,כפר קרע ,239 ,אלגוזלאן
654 ,כפר קרע ,331 ,אלגזאלי
654 ,כפר קרע ,204 ,אלגנאת
654 ,כפר קרע ,666 ,אלדאחיה
654 ,כפר קרע ,327 ,אלדחא
654 ,כפר קרע ,6720 ,אלדסת
654 ,כפר קרע ,661 ,אלהודהד
654 ,כפר קרע ,694 ,אלואסל
654 ,כפר קרע ,602 ,אלוואדי
654 ,כפר קרע ,191 ,אלוורוד
654 ,כפר קרע ,647 ,אלוותאק
654 ,כפר קרע ,153 ,אלוחדה
654 ,כפר קרע ,187 ,אלוסילה
654 ,כפר קרע ,222 ,אלוסים
654 ,כפר קרע ,688 ,אלועד
654 ,כפר קרע ,332 ,אלזהור
654 ,כפר קרע ,304 ,אלזהרא
654 ,כפר קרע ,650 ,אלזהראא
654 ,כפר קרע ,693 ,אלזהרייה
654 ,כפר קרע ,326 ,אלזובייר בן אלעואם
654 ,כפר קרע ,301 ,אלזינה
654 ,כפר קרע ,193 ,אלזיתון
654 ,כפר קרע ,213 ,אלזנבק
654 ,כפר קרע ,636 ,אלזנג'ביל
654 ,כפר קרע ,680 ,אלזעפראני
654 ,כפר קרע ,627 ,אלח'דר
654 ,כפר קרע ,674 ,אלח'לוד
654 ,כפר קרע ,338 ,אלח'רוב
654 ,כפר קרע ,6722 ,אלחווארנה
654 ,כפר קרע ,673 ,אלחור
654 ,כפר קרע ,626 ,אלחוריה
654 ,כפר קרע ,353 ,אלחכים
654 ,כפר קרע ,118 ,אלחכמה
654 ,כפר קרע ,223 ,אלחלווה
654 ,כפר קרע ,181 ,אלחמאמה
654 ,כפר קרע ,267 ,אלחמה
654 ,כפר קרע ,227 ,אלחנאן
654 ,כפר קרע ,209 ,אלחנין
654 ,כפר קרע ,303 ,אלחנסאא
654 ,כפר קרע ,157 ,אלחסאד
654 ,כפר קרע ,161 ,אלחסון
654 ,כפר קרע ,144 ,אלחסנאת
654 ,כפר קרע ,6730 ,אלט'הר
654 ,כפר קרע ,641 ,אלטיוור
654 ,כפר קרע ,192 ,אלטלה
654 ,כפר קרע ,240 ,אליאסמין
654 ,כפר קרע ,319 ,אליקין
654 ,כפר קרע ,128 ,אלירמוק
654 ,כפר קרע ,612 ,אלכוות'ר
654 ,כפר קרע ,111 ,אלכרם
654 ,כפר קרע ,677 ,אלכתאב
654 ,כפר קרע ,133 ,אללוטוס
654 ,כפר קרע ,150 ,אלליל
654 ,כפר קרע ,345 ,אלמדארס
654 ,כפר קרע ,6712 ,אלמהד
654 ,כפר קרע ,349 ,אלמוודה
654 ,כפר קרע ,266 ,אלמותנבי
654 ,כפר קרע ,635 ,אלמזאר
654 ,כפר קרע ,619 ,אלמזארע
654 ,כפר קרע ,6743 ,אלמזע'מתה
654 ,כפר קרע ,205 ,אלמח'תאר
654 ,כפר קרע ,6747 ,אלמטלה
654 ,כפר קרע ,334 ,אלמיזאן
654 ,כפר קרע ,687 ,אלמיעאד
654 ,כפר קרע ,300 ,אלמית'אק
654 ,כפר קרע ,210 ,אלמנסור
654 ,כפר קרע ,620 ,אלמנסורה
654 ,כפר קרע ,102 ,אלמערפה
654 ,כפר קרע ,6711 ,אלמפאח'ר
654 ,כפר קרע ,6742 ,אלמקאייל
654 ,כפר קרע ,696 ,אלמראעי
654 ,כפר קרע ,124 ,אלמרג
654 ,כפר קרע ,201 ,אלמרג'אן
654 ,כפר קרע ,214 ,אלמרווה
654 ,כפר קרע ,114 ,אלמשמש
654 ,כפר קרע ,120 ,אלנגאח
654 ,כפר קרע ,251 ,אלנגוא
654 ,כפר קרע ,320 ,אלנדאא
654 ,כפר קרע ,616 ,אלנהאר
654 ,כפר קרע ,621 ,אלנהר
654 ,כפר קרע ,134 ,אלנוורס
654 ,כפר קרע ,143 ,אלנור
654 ,כפר קרע ,603 ,אלנזהה
654 ,כפר קרע ,254 ,אלנזלה
654 ,כפר קרע ,221 ,אלנח'יל
654 ,כפר קרע ,112 ,אלנח'לה
654 ,כפר קרע ,622 ,אלנסים
654 ,כפר קרע ,649 ,אלנסר
654 ,כפר קרע ,116 ,אלנעים
654 ,כפר קרע ,186 ,אלנעמאן
654 ,כפר קרע ,351 ,אלנרג'ס
654 ,כפר קרע ,352 ,אלסאלחייה
654 ,כפר קרע ,610 ,אלסאפי
654 ,כפר קרע ,702 ,אלסבאח
654 ,כפר קרע ,606 ,אלסבע
654 ,כפר קרע ,683 ,אלסד
654 ,כפר קרע ,137 ,אלסד אלעאלי
654 ,כפר קרע ,631 ,אלסדאקה
654 ,כפר קרע ,166 ,אלסדרה
654 ,כפר קרע ,6702 ,אלסואניה
654 ,כפר קרע ,286 ,אלסונדס
654 ,כפר קרע ,658 ,אלסוסן
654 ,כפר קרע ,608 ,אלסופסאף
654 ,כפר קרע ,350 ,אלסחאבה
654 ,כפר קרע ,225 ,אלסחווה
654 ,כפר קרע ,256 ,אלסידה אמנה
654 ,כפר קרע ,260 ,אלסידה האגר
654 ,כפר קרע ,170 ,אלסידה זינב
654 ,כפר קרע ,233 ,אלסידה סארה
654 ,כפר קרע ,257 ,אלסידה סומייה
654 ,כפר קרע ,220 ,אלסלאם
654 ,כפר קרע ,618 ,אלסלוא
654 ,כפר קרע ,281 ,אלסלמה
654 ,כפר קרע ,149 ,אלסנאבל
654 ,כפר קרע ,245 ,אלסנדיאני
654 ,כפר קרע ,156 ,אלסנובר
654 ,כפר קרע ,123 ,אלסקר
654 ,כפר קרע ,297 ,אלסראג
654 ,כפר קרע ,632 ,אלסרווה
654 ,כפר קרע ,211 ,אלסריס
654 ,כפר קרע ,337 ,אלעבאס
654 ,כפר קרע ,6721 ,אלעדאסה
654 ,כפר קרע ,679 ,אלעוד
654 ,כפר קרע ,615 ,אלעזבה
654 ,כפר קרע ,342 ,אלעין
654 ,כפר קרע ,6733 ,אלעירק
654 ,כפר קרע ,129 ,אלעלם
654 ,כפר קרע ,189 ,אלעמור
654 ,כפר קרע ,605 ,אלענב
654 ,כפר קרע ,348 ,אלענדליב
654 ,כפר קרע ,637 ,אלעסר
654 ,כפר קרע ,158 ,אלערק
654 ,כפר קרע ,252 ,אלפארוק
654 ,כפר קרע ,347 ,אלפג'ר
654 ,כפר קרע ,287 ,אלפוראת
654 ,כפר קרע ,330 ,אלפראבי
654 ,כפר קרע ,643 ,אלפרדוס
654 ,כפר קרע ,159 ,אלפרזדק
654 ,כפר קרע ,305 ,אלפרח
654 ,כפר קרע ,219 ,אלפתח
654 ,כפר קרע ,657 ,אלצדיק
654 ,כפר קרע ,613 ,אלצפא
654 ,כפר קרע ,168 ,אלקאדי
654 ,כפר קרע ,6734 ,אלקבארה
654 ,כפר קרע ,139 ,אלקודס
654 ,כפר קרע ,215 ,אלקורנפול
654 ,כפר קרע ,6740 ,אלקטאין
654 ,כפר קרע ,167 ,אלקמר
654 ,כפר קרע ,329 ,אלראזי
654 ,כפר קרע ,207 ,אלרבאט
654 ,כפר קרע ,646 ,אלרג'ד
654 ,כפר קרע ,284 ,אלרוחה
654 ,כפר קרע ,644 ,אלרחיק
654 ,כפר קרע ,198 ,אלרחמה
654 ,כפר קרע ,185 ,אלריאן
654 ,כפר קרע ,700 ,אלריחאן
654 ,כפר קרע ,604 ,אלרמאן
654 ,כפר קרע ,325 ,אלרסאם
654 ,כפר קרע ,645 ,אלרשאד
654 ,כפר קרע ,343 ,אלשאם
654 ,כפר קרע ,697 ,אלשאפעי
654 ,כפר קרע ,333 ,אלשוגעאן
654 ,כפר קרע ,148 ,אלשיח'
654 ,כפר קרע ,235 ,אלשמס
654 ,כפר קרע ,614 ,אלתין
654 ,כפר קרע ,339 ,אלתל
654 ,כפר קרע ,664 ,אלתנסים
654 ,כפר קרע ,699 ,אלתקוא
654 ,כפר קרע ,262 ,אם אלגמאל
654 ,כפר קרע ,6728 ,אם אלנור
654 ,כפר קרע ,259 ,אם ח'אלד
654 ,כפר קרע ,6704 ,אם סליטי
654 ,כפר קרע ,294 ,אסמאא בנת אבו בכר
654 ,כפר קרע ,243 ,בדר
654 ,כפר קרע ,174 ,בורג' אלחמאם
654 ,כפר קרע ,261 ,בורקה
654 ,כפר קרע ,202 ,ביאדרנא
654 ,כפר קרע ,6746 ,בין אלדרב
654 ,כפר קרע ,164 ,ביסאן
654 ,כפר קרע ,195 ,בלאל בן רבאח
654 ,כפר קרע ,324 ,ג'ודי
654 ,כפר קרע ,183 ,ג'מאדי א-ת'אני
654 ,כפר קרע ,105 ,ג'מאדי אל-אוול
654 ,כפר קרע ,629 ,ג'נא
654 ,כפר קרע ,601 ,ג'נאת
654 ,כפר קרע ,667 ,גאר חראא
654 ,כפר קרע ,291 ,ד'ו אל-חיג'ה
654 ,כפר קרע ,269 ,ד'ו אל-קעדה
654 ,כפר קרע ,6715 ,דבת אלמוע'ור
654 ,כפר קרע ,132 ,דהרת גבר
654 ,כפר קרע ,6735 ,דחדאח אבו מורא
654 ,כפר קרע ,229 ,הארון אלרשיד
654 ,כפר קרע ,623 ,ואדי אלחמאם
654 ,כפר קרע ,341 ,וורדה
654 ,כפר קרע ,634 ,זריף אלטול
654 ,כפר קרע ,228 ,ח'אלד בן אלוליד
654 ,כפר קרע ,690 ,ח'ביזה
654 ,כפר קרע ,6726 ,ח'לאיל אלעביד
654 ,כפר קרע ,231 ,ח'ליל אלסכאכיני
654 ,כפר קרע ,6716 ,ח'לת אלחיזווה
654 ,כפר קרע ,6713 ,ח'לת אלסקיע
654 ,כפר קרע ,6717 ,ח'לת זבדה
654 ,כפר קרע ,6741 ,ח'לת שלח
654 ,כפר קרע ,171 ,חאן אלחלילי
654 ,כפר קרע ,6736 ,חאקורת אלעסלי
654 ,כפר קרע ,640 ,חאתם אלטאאי
654 ,כפר קרע ,292 ,חוש ברדק
654 ,כפר קרע ,336 ,חזיראן
654 ,כפר קרע ,313 ,חיטין
654 ,כפר קרע ,289 ,חמזה בן עבד אלמוטלב
654 ,כפר קרע ,142 ,חסאן בן תאבת
654 ,כפר קרע ,652 ,חסנאא
654 ,כפר קרע ,6729 ,ט'אהר יאסין
654 ,כפר קרע ,6744 ,ט'הרת אבו סלימאן
654 ,כפר קרע ,6749 ,ט'הרת אל סנדחאווי
654 ,כפר קרע ,6748 ,ט'הרת נח'לה
654 ,כפר קרע ,121 ,טאהא חוסיין
654 ,כפר קרע ,113 ,טאריק אל-ווסייה
654 ,כפר קרע ,152 ,טארק בן זיאד
654 ,כפר קרע ,177 ,טיבה
654 ,כפר קרע ,370 ,טריק א-דסת
654 ,כפר קרע ,107 ,טריק א-סנדחאווי
654 ,כפר קרע ,378 ,טריק א-ריחאנה
654 ,כפר קרע ,280 ,טריק אל-ביאדר
654 ,כפר קרע ,369 ,טריק אל-ג'ולאן
654 ,כפר קרע ,366 ,טריק אל-חוארנה
654 ,כפר קרע ,364 ,טריק אל-כבארה
654 ,כפר קרע ,371 ,טריק אל-מזר'מתה
654 ,כפר קרע ,374 ,טריק אל-מטלה
654 ,כפר קרע ,373 ,טריק אל-מפאח'יר
654 ,כפר קרע ,110 ,טריק אל-מקאייל
654 ,כפר קרע ,368 ,טריק אל-עדאסה
654 ,כפר קרע ,367 ,טריק אל-קטאיין
654 ,כפר קרע ,375 ,טריק בין א-דורוב
654 ,כפר קרע ,365 ,טריק ח'לת א-שלח
654 ,כפר קרע ,372 ,טריק סדר אל-ערוס
654 ,כפר קרע ,376 ,טריק סתאלין
654 ,כפר קרע ,108 ,יאסמינה
654 ,כפר קרע ,138 ,יאפה
654 ,כפר קרע ,317 ,יזיד בן מועאוויה
654 ,כפר קרע ,212 ,כאנון א-ת'אני
654 ,כפר קרע ,230 ,כאנון אל-אול
654 ,כפר קרע ,9000 ,כפר קרע
654 ,כפר קרע ,190 ,ליאלינא
654 ,כפר קרע ,662 ,מוחמד אלפאתח
654 ,כפר קרע ,282 ,מוחרם
654 ,כפר קרע ,705 ,מוסא בן נוסייר
654 ,כפר קרע ,270 ,מועאוייה
654 ,כפר קרע ,140 ,מכה אלמכרמה
654 ,כפר קרע ,6732 ,מקטל אבו אללימון
654 ,כפר קרע ,624 ,מרג' בן עאמר
654 ,כפר קרע ,178 ,מרג'אנה
654 ,כפר קרע ,173 ,מרים
654 ,כפר קרע ,6724 ,מרכז הכפר
654 ,כפר קרע ,250 ,נדאא אלחק
654 ,כפר קרע ,306 ,נוסרין
654 ,כפר קרע ,196 ,ניסאן
654 ,כפר קרע ,651 ,סג'א
654 ,כפר קרע ,6745 ,סדר אבו אחמד
654 ,כפר קרע ,6731 ,סדר אלערוס
654 ,כפר קרע ,322 ,סולימאן אלפרסי
654 ,כפר קרע ,659 ,סונבולה
654 ,כפר קרע ,308 ,סונדס
654 ,כפר קרע ,684 ,סוק עוקאד
654 ,כפר קרע ,386 ,סחלב
654 ,כפר קרע ,6710 ,סטלין
654 ,כפר קרע ,279 ,סיוואר
654 ,כפר קרע ,206 ,סירין
654 ,כפר קרע ,670 ,סלאח א-דין
654 ,כפר קרע ,323 ,סלמא
654 ,כפר קרע ,686 ,סלסביל
654 ,כפר קרע ,218 ,סנאבל
654 ,כפר קרע ,242 ,ספר
654 ,כפר קרע ,224 ,סת אלחבאיב
654 ,כפר קרע ,625 ,עאבר אלנזאזה
654 ,כפר קרע ,163 ,עאישה בנת אבו בכר
654 ,כפר קרע ,654 ,עאר תור
654 ,כפר קרע ,315 ,עומר אלמכתאר
654 ,כפר קרע ,293 ,עומר בן אלח'טאב
654 ,כפר קרע ,248 ,עותמאן בן עפאן
654 ,כפר קרע ,681 ,עין אלחלווה
654 ,כפר קרע ,691 ,עין וורדה
654 ,כפר קרע ,307 ,עין חוד
654 ,כפר קרע ,692 ,עין שחאדה
654 ,כפר קרע ,175 ,עלי בן אבי טאלב
654 ,כפר קרע ,203 ,עלי חידר זחאלקה
654 ,כפר קרע ,197 ,עמאר בן יאסר
654 ,כפר קרע ,312 ,עמר בן אלעאס
654 ,כפר קרע ,160 ,עספור
654 ,כפר קרע ,685 ,עספור אלג'נה
654 ,כפר קרע ,117 ,פאטמה א-זהראא
654 ,כפר קרע ,182 ,צור
654 ,כפר קרע ,704 ,צידא
654 ,כפר קרע ,194 ,קבאא אלדאירי
654 ,כפר קרע ,6703 ,קעקור אלעבסה
654 ,כפר קרע ,6714 ,קרית החינוך
654 ,כפר קרע ,321 ,ראבעה אלעדויה
654 ,כפר קרע ,665 ,ראמה
654 ,כפר קרע ,104 ,רביע א-ת'אני
654 ,כפר קרע ,253 ,רביע אל-אוול
654 ,כפר קרע ,135 ,רג'ב
654 ,כפר קרע ,283 ,ריחאנה
654 ,כפר קרע ,268 ,רמדאן
654 ,כפר קרע ,165 ,רפידה
654 ,כפר קרע ,208 ,שבאט
654 ,כפר קרע ,273 ,שוואל
654 ,כפר קרע ,258 ,שט אלערב
654 ,כפר קרע ,6723 ,שכ אלווסיה
654 ,כפר קרע ,264 ,שעבאן
654 ,כפר קרע ,6737 ,שקיף זאייד
654 ,כפר קרע ,122 ,תופיק אלחכים
654 ,כפר קרע ,707 ,תופיק זייאד
654 ,כפר קרע ,241 ,תישרין
654 ,כפר קרע ,180 ,תמוז
579 ,כפר ראש הנקרה ,104 ,דרך דרומית
579 ,כפר ראש הנקרה ,101 ,דרך הים
579 ,כפר ראש הנקרה ,105 ,דרך המייסדים
579 ,כפר ראש הנקרה ,102 ,דרך המעגל
579 ,כפר ראש הנקרה ,103 ,דרך צפונית
579 ,כפר ראש הנקרה ,9000 ,כפר ראש הנקרה
579 ,כפר ראש הנקרה ,106 ,מעלה הגבעה
579 ,כפר ראש הנקרה ,7001 ,מרכז קליטה
579 ,כפר ראש הנקרה ,109 ,משעול הארז
579 ,כפר ראש הנקרה ,108 ,נוף הים
579 ,כפר ראש הנקרה ,107 ,רכס הסולם
1130 ,כפר רוזנואלד (זרעית) ,9000 ,כפר רוזנואלד
295 ,כפר רופין ,9000 ,כפר רופין
1166 ,כפר רות ,9000 ,כפר רות
605 ,כפר שמאי ,9000 ,כפר שמאי
743 ,כפר שמואל ,9000 ,כפר שמואל
267 ,כפר שמריהו ,157 ,אגס
267 ,כפר שמריהו ,158 ,ארז
267 ,כפר שמריהו ,159 ,אשל
267 ,כפר שמריהו ,160 ,דולב
267 ,כפר שמריהו ,116 ,דרך אביב
267 ,כפר שמריהו ,108 ,דרך הבאר
267 ,כפר שמריהו ,109 ,דרך הגנים
267 ,כפר שמריהו ,106 ,דרך השדות
267 ,כפר שמריהו ,132 ,דרך רגבים
267 ,כפר שמריהו ,112 ,האורנים
267 ,כפר שמריהו ,111 ,האילנות
267 ,כפר שמריהו ,161 ,הדס
267 ,כפר שמריהו ,126 ,הדקלים
267 ,כפר שמריהו ,104 ,הורדים
267 ,כפר שמריהו ,105 ,הזורע
267 ,כפר שמריהו ,121 ,הזית
267 ,כפר שמריהו ,113 ,החבצלת
267 ,כפר שמריהו ,101 ,החורש
267 ,כפר שמריהו ,102 ,המעפילים
267 ,כפר שמריהו ,118 ,הנוטע
267 ,כפר שמריהו ,129 ,הנוריות
267 ,כפר שמריהו ,110 ,הנרקיסים
267 ,כפר שמריהו ,131 ,הסביון
267 ,כפר שמריהו ,153 ,הצבעונים
267 ,כפר שמריהו ,115 ,הקוצר
267 ,כפר שמריהו ,117 ,הרקפות
267 ,כפר שמריהו ,125 ,התלמים
267 ,כפר שמריהו ,147 ,התמר
267 ,כפר שמריהו ,150 ,כיכר הראשונים
267 ,כפר שמריהו ,9000 ,כפר שמריהו
267 ,כפר שמריהו ,162 ,לבנה
267 ,כפר שמריהו ,114 ,נוה אביב
267 ,כפר שמריהו ,103 ,נורדאו
267 ,כפר שמריהו ,135 ,סמ אביב
267 ,כפר שמריהו ,136 ,סמ האורנים
267 ,כפר שמריהו ,141 ,סמ האלון
267 ,כפר שמריהו ,137 ,סמ הברושים
267 ,כפר שמריהו ,119 ,סמ הזורע
267 ,כפר שמריהו ,133 ,סמ החרצית
267 ,כפר שמריהו ,107 ,קרן היסוד
267 ,כפר שמריהו ,144 ,שביל הבנים
267 ,כפר שמריהו ,155 ,שביל הדגנים
267 ,כפר שמריהו ,154 ,שביל החיטה
267 ,כפר שמריהו ,142 ,שביל החצבים
267 ,כפר שמריהו ,143 ,שביל החרוב
267 ,כפר שמריהו ,152 ,שביל היוגב
267 ,כפר שמריהו ,130 ,שביל היסמין
267 ,כפר שמריהו ,146 ,שביל הכורם
267 ,כפר שמריהו ,139 ,שביל הכלניות
267 ,כפר שמריהו ,151 ,שביל המעלות
267 ,כפר שמריהו ,138 ,שביל המערות
267 ,כפר שמריהו ,164 ,שביל הפקאן
267 ,כפר שמריהו ,134 ,שביל הרימונים
267 ,כפר שמריהו ,156 ,שביל השעורה
267 ,כפר שמריהו ,120 ,שביל השקד
267 ,כפר שמריהו ,145 ,שביל התאנים
267 ,כפר שמריהו ,140 ,שביל התפוזים
267 ,כפר שמריהו ,163 ,שיטה
47 ,כפר תבור ,135 ,אגוז
47 ,כפר תבור ,140 ,אדר
47 ,כפר תבור ,202 ,אודם
47 ,כפר תבור ,189 ,אורן
47 ,כפר תבור ,124 ,אירוס
47 ,כפר תבור ,188 ,אלה
47 ,כפר תבור ,190 ,אלון
47 ,כפר תבור ,192 ,ארז
47 ,כפר תבור ,196 ,אשל
47 ,כפר תבור ,200 ,בזלת
47 ,כפר תבור ,110 ,ביס שדה
47 ,כפר תבור ,180 ,בר גיורא
47 ,כפר תבור ,155 ,ברוש
47 ,כפר תבור ,166 ,ברקן
47 ,כפר תבור ,198 ,ברקת
47 ,כפר תבור ,210 ,גביש
47 ,כפר תבור ,171 ,גדוד העבודה
47 ,כפר תבור ,209 ,גזית
47 ,כפר תבור ,163 ,גפן
47 ,כפר תבור ,208 ,גרניט
47 ,כפר תבור ,129 ,דובדבן
47 ,כפר תבור ,195 ,דולב
47 ,כפר תבור ,119 ,דפנה
47 ,כפר תבור ,186 ,האצ"ל
47 ,כפר תבור ,223 ,הבריגדה היהודית
47 ,כפר תבור ,185 ,הגדוד העברי
47 ,כפר תבור ,121 ,הדס
47 ,כפר תבור ,162 ,הדר
47 ,כפר תבור ,176 ,ההגנה
47 ,כפר תבור ,165 ,הזית
47 ,כפר תבור ,170 ,החלוץ
47 ,כפר תבור ,151 ,החקלאי
47 ,כפר תבור ,150 ,היוצר
47 ,כפר תבור ,173 ,המגן
47 ,כפר תבור ,181 ,המושבה
47 ,כפר תבור ,169 ,המייסדים
47 ,כפר תבור ,149 ,המעיין
47 ,כפר תבור ,183 ,הנוטר
47 ,כפר תבור ,178 ,העליה
47 ,כפר תבור ,175 ,הפלוגות
47 ,כפר תבור ,187 ,הפלמ"ח
47 ,כפר תבור ,174 ,הרועה
47 ,כפר תבור ,168 ,השומר
47 ,כפר תבור ,177 ,התקומה
47 ,כפר תבור ,154 ,ורד
47 ,כפר תבור ,161 ,זמורה
47 ,כפר תבור ,184 ,חובבי ציון
47 ,כפר תבור ,203 ,חושן
47 ,כפר תבור ,146 ,חיטה
47 ,כפר תבור ,206 ,חלמיש
47 ,כפר תבור ,125 ,חצב
47 ,כפר תבור ,148 ,חרוב
47 ,כפר תבור ,153 ,חרושת
47 ,כפר תבור ,156 ,חרצית
47 ,כפר תבור ,199 ,טופז
47 ,כפר תבור ,205 ,יהלום
47 ,כפר תבור ,143 ,יערה
47 ,כפר תבור ,6021 ,כיכר אופיר מקס
47 ,כפר תבור ,6003 ,כיכר אופירה
47 ,כפר תבור ,6013 ,כיכר ארליך אשר
47 ,כפר תבור ,6020 ,כיכר גולדמן דוד
47 ,כפר תבור ,6014 ,כיכר גנדי
47 ,כפר תבור ,6015 ,כיכר האלונים
47 ,כפר תבור ,6022 ,כיכר הברון הירש
47 ,כפר תבור ,6016 ,כיכר הברון רוטשילד
47 ,כפר תבור ,6006 ,כיכר הברושים דרום
47 ,כפר תבור ,6005 ,כיכר הברושים צפון
47 ,כפר תבור ,6009 ,כיכר הגפנים
47 ,כפר תבור ,6007 ,כיכר הזיתים
47 ,כפר תבור ,6018 ,כיכר המועצה
47 ,כפר תבור ,6011 ,כיכר המורה ענתבי
47 ,כפר תבור ,6002 ,כיכר הערמונים
47 ,כפר תבור ,6012 ,כיכר השומר
47 ,כפר תבור ,6001 ,כיכר השזיפים
47 ,כפר תבור ,6004 ,כיכר התבור
47 ,כפר תבור ,6008 ,כיכר זמורה
47 ,כפר תבור ,6010 ,כיכר ירחן אהרון
47 ,כפר תבור ,6019 ,כיכר כהן יעקב משה
47 ,כפר תבור ,6017 ,ככר הברונית רוטשילד
47 ,כפר תבור ,134 ,כליל החורש
47 ,כפר תבור ,127 ,כלנית
47 ,כפר תבור ,9000 ,כפר תבור
47 ,כפר תבור ,197 ,כרמים
47 ,כפר תבור ,158 ,לוטם
47 ,כפר תבור ,172 ,לח"י
47 ,כפר תבור ,130 ,ליבנה
47 ,כפר תבור ,194 ,מורן
47 ,כפר תבור ,152 ,מלאכה
47 ,כפר תבור ,182 ,מעפילים
47 ,כפר תבור ,179 ,מקווה ישראל
47 ,כפר תבור ,167 ,מרגנית
47 ,כפר תבור ,118 ,מרווה
47 ,כפר תבור ,115 ,נוף התבור
47 ,כפר תבור ,123 ,נורית
47 ,כפר תבור ,126 ,נרקיס
47 ,כפר תבור ,164 ,סיגלית
47 ,כפר תבור ,157 ,סייפן
47 ,כפר תבור ,141 ,סמ היקב
47 ,כפר תבור ,201 ,ספיר
47 ,כפר תבור ,160 ,עינב
47 ,כפר תבור ,122 ,עירית
47 ,כפר תבור ,211 ,ענבר
47 ,כפר תבור ,131 ,ערבה
47 ,כפר תבור ,136 ,פקאן
47 ,כפר תבור ,138 ,צאלון
47 ,כפר תבור ,128 ,צבעוני
47 ,כפר תבור ,207 ,צור
47 ,כפר תבור ,133 ,צפצפה
47 ,כפר תבור ,116 ,קורנית
47 ,כפר תבור ,193 ,רותם
47 ,כפר תבור ,120 ,ריחן
47 ,כפר תבור ,147 ,רימון
47 ,כפר תבור ,159 ,רקפת
47 ,כפר תבור ,220 ,שדרות קק"ל
47 ,כפר תבור ,204 ,שוהם
47 ,כפר תבור ,213 ,שחם
47 ,כפר תבור ,117 ,שיזף
47 ,כפר תבור ,191 ,שיטה
47 ,כפר תבור ,132 ,שיקמה
47 ,כפר תבור ,101 ,שכ האלונים
47 ,כפר תבור ,105 ,שכ הגפנים
47 ,כפר תבור ,106 ,שכ הזיתים
47 ,כפר תבור ,111 ,שכ החרובים
47 ,כפר תבור ,107 ,שכ המיסדים
47 ,כפר תבור ,108 ,שכ הקוטגים
47 ,כפר תבור ,103 ,שכ הרימונים
47 ,כפר תבור ,104 ,שכ השזיפים
47 ,כפר תבור ,102 ,שכ השקדים
47 ,כפר תבור ,109 ,שכ התאנים
47 ,כפר תבור ,112 ,שכ זמורה
47 ,כפר תבור ,145 ,שעורה
47 ,כפר תבור ,137 ,שקד
47 ,כפר תבור ,142 ,תאנה
47 ,כפר תבור ,221 ,תבור
47 ,כפר תבור ,139 ,תות
47 ,כפר תבור ,212 ,תכלת
47 ,כפר תבור ,144 ,תלתן
3572 ,כפר תפוח ,120 ,אזור תעשייה רימון
3572 ,כפר תפוח ,101 ,האלון
3572 ,כפר תפוח ,102 ,הארז
3572 ,כפר תפוח ,104 ,הדס
3572 ,כפר תפוח ,103 ,הדר
3572 ,כפר תפוח ,107 ,הזית
3572 ,כפר תפוח ,108 ,התמר
3572 ,כפר תפוח ,106 ,יסמין
3572 ,כפר תפוח ,9000 ,כפר תפוח
3572 ,כפר תפוח ,105 ,לבונה
3572 ,כפר תפוח ,6500 ,רחלים
3572 ,כפר תפוח ,109 ,שבילי הגפן
38 ,כרי דשא ,9000 ,כרי דשא
1285 ,כרכום ,106 ,אלון
1285 ,כרכום ,102 ,בוסתן
1285 ,כרכום ,110 ,גולן
1285 ,כרכום ,101 ,הראשונים
1285 ,כרכום ,105 ,זית
1285 ,כרכום ,103 ,חרוב
1285 ,כרכום ,107 ,ירדן ההררי
1285 ,כרכום ,109 ,כינרת
1285 ,כרכום ,9000 ,כרכום
1285 ,כרכום ,104 ,רימון
1094 ,כרם ביבנה (ישיבה) ,9000 ,כרם ביבנה (ישיבה)
664 ,כרם בן זמרה ,108 ,אשכול אלנבי
664 ,כרם בן זמרה ,103 ,בציר
664 ,כרם בן זמרה ,104 ,גפן
664 ,כרם בן זמרה ,101 ,זמורה
664 ,כרם בן זמרה ,9000 ,כרם בן זמרה
664 ,כרם בן זמרה ,106 ,מעלה הכרם
664 ,כרם בן זמרה ,102 ,סמדר
664 ,כרם בן זמרה ,107 ,עוללות
664 ,כרם בן זמרה ,105 ,תירוש
88 ,כרם בן שמן ,9000 ,כרם בן שמן
580 ,כרם מהר"ל ,9000 ,כרם מהר"ל
1085 ,כרם שלום ,9000 ,כרם שלום
1264 ,כרמי יוסף ,106 ,אורן
1264 ,כרמי יוסף ,110 ,אלון
1264 ,כרמי יוסף ,114 ,ארז
1264 ,כרמי יוסף ,103 ,הברוש
1264 ,כרמי יוסף ,101 ,הגפן
1264 ,כרמי יוסף ,115 ,הורד
1264 ,כרמי יוסף ,107 ,הזית
1264 ,כרמי יוסף ,104 ,היסמין
1264 ,כרמי יוסף ,108 ,הנרקיס
1264 ,כרמי יוסף ,111 ,הרימון
1264 ,כרמי יוסף ,109 ,התאנה
1264 ,כרמי יוסף ,113 ,התמר
1264 ,כרמי יוסף ,105 ,כלנית
1264 ,כרמי יוסף ,9000 ,כרמי יוסף
1264 ,כרמי יוסף ,120 ,סיגלית
1264 ,כרמי יוסף ,102 ,סמדר
1264 ,כרמי יוסף ,112 ,רקפת
3766 ,כרמי צור ,106 ,אשכול
3766 ,כרמי צור ,108 ,דרך האבות
3766 ,כרמי צור ,109 ,דרך שורק
3766 ,כרמי צור ,102 ,הגפן
3766 ,כרמי צור ,103 ,הגת
3766 ,כרמי צור ,9000 ,כרמי צור
3766 ,כרמי צור ,105 ,סמדר
3766 ,כרמי צור ,104 ,עינב
3766 ,כרמי צור ,6500 ,שכ צור שלם
3766 ,כרמי צור ,101 ,שריג
3766 ,כרמי צור ,107 ,תירוש
1374 ,כרמי קטיף ,101 ,הגפן
1374 ,כרמי קטיף ,102 ,הזמורה
1374 ,כרמי קטיף ,103 ,היקב
1374 ,כרמי קטיף ,104 ,הכרם
1374 ,כרמי קטיף ,105 ,הנצר
1374 ,כרמי קטיף ,106 ,הסמדר
1374 ,כרמי קטיף ,107 ,העינב
1374 ,כרמי קטיף ,108 ,השריג
1374 ,כרמי קטיף ,109 ,התירוש
1374 ,כרמי קטיף ,9000 ,כרמי קטיף
1139 ,כרמיאל ,257 ,אביב
1139 ,כרמיאל ,275 ,אדום החזה
1139 ,כרמיאל ,6514 ,אזור התעשייה
1139 ,כרמיאל ,191 ,אטד
1139 ,כרמיאל ,178 ,איה
1139 ,כרמיאל ,124 ,אילנות
1139 ,כרמיאל ,141 ,איריס
1139 ,כרמיאל ,162 ,אלה
1139 ,כרמיאל ,325 ,אנפה
1139 ,כרמיאל ,185 ,אנקור
1139 ,כרמיאל ,261 ,אסיף
1139 ,כרמיאל ,133 ,ארבל
1139 ,כרמיאל ,134 ,אשור
1139 ,כרמיאל ,193 ,אשחר
1139 ,כרמיאל ,288 ,אשכולות
1139 ,כרמיאל ,198 ,אשל
1139 ,כרמיאל ,179 ,בז
1139 ,כרמיאל ,317 ,בזלת
1139 ,כרמיאל ,253 ,ביכורים
1139 ,כרמיאל ,209 ,בירנית
1139 ,כרמיאל ,264 ,בציר
1139 ,כרמיאל ,307 ,בשמים
1139 ,כרמיאל ,318 ,גביש
1139 ,כרמיאל ,6504 ,גבעת מכוש
1139 ,כרמיאל ,6507 ,גבעת רם
1139 ,כרמיאל ,249 ,גדיד
1139 ,כרמיאל ,224 ,גדרון
1139 ,כרמיאל ,313 ,גזית
1139 ,כרמיאל ,215 ,גמלא
1139 ,כרמיאל ,312 ,גרניט
1139 ,כרמיאל ,289 ,גת
1139 ,כרמיאל ,225 ,דגנית
1139 ,כרמיאל ,183 ,דוכיפת
1139 ,כרמיאל ,214 ,דלית
1139 ,כרמיאל ,168 ,דרור
1139 ,כרמיאל ,316 ,דרך התעשייה
1139 ,כרמיאל ,301 ,האומן
1139 ,כרמיאל ,158 ,האופן
1139 ,כרמיאל ,120 ,האורג
1139 ,כרמיאל ,129 ,האורן
1139 ,כרמיאל ,130 ,הארזים
1139 ,כרמיאל ,122 ,הבנאי
1139 ,כרמיאל ,128 ,הברושים
1139 ,כרמיאל ,297 ,הגורן
1139 ,כרמיאל ,103 ,הגליל
1139 ,כרמיאל ,112 ,הגפן
1139 ,כרמיאל ,167 ,הדס
1139 ,כרמיאל ,115 ,הדקל
1139 ,כרמיאל ,359 ,ההגנה
1139 ,כרמיאל ,110 ,הזית
1139 ,כרמיאל ,160 ,החבצלת
1139 ,כרמיאל ,202 ,החורש
1139 ,כרמיאל ,104 ,החרוב
1139 ,כרמיאל ,117 ,החרושת
1139 ,כרמיאל ,159 ,החרמש
1139 ,כרמיאל ,303 ,החשמל
1139 ,כרמיאל ,156 ,היוצרים
1139 ,כרמיאל ,298 ,היזם
1139 ,כרמיאל ,309 ,הכבאי
1139 ,כרמיאל ,155 ,המגל
1139 ,כרמיאל ,118 ,המלאכה
1139 ,כרמיאל ,121 ,המסגר
1139 ,כרמיאל ,296 ,המסילה
1139 ,כרמיאל ,151 ,המצודה
1139 ,כרמיאל ,119 ,הנגר
1139 ,כרמיאל ,135 ,הנפח
1139 ,כרמיאל ,300 ,הסדן
1139 ,כרמיאל ,106 ,העמק
1139 ,כרמיאל ,136 ,העסקים
1139 ,כרמיאל ,353 ,הפלמח
1139 ,כרמיאל ,172 ,הפסגה
1139 ,כרמיאל ,161 ,הפרחים
1139 ,כרמיאל ,222 ,הצפצפות
1139 ,כרמיאל ,368 ,הר הצופים
1139 ,כרמיאל ,149 ,הרדוף
1139 ,כרמיאל ,101 ,הרימון
1139 ,כרמיאל ,290 ,השוק
1139 ,כרמיאל ,147 ,השושנים
1139 ,כרמיאל ,266 ,השותלים
1139 ,כרמיאל ,114 ,השיש
1139 ,כרמיאל ,111 ,התאנה
1139 ,כרמיאל ,102 ,התמר
1139 ,כרמיאל ,304 ,התעשיה
1139 ,כרמיאל ,166 ,ורד
1139 ,כרמיאל ,292 ,זמורה
1139 ,כרמיאל ,324 ,זמיר
1139 ,כרמיאל ,184 ,חוחית
1139 ,כרמיאל ,360 ,חטיבת גבעתי
1139 ,כרמיאל ,351 ,חטיבת גולני
1139 ,כרמיאל ,361 ,חטיבת הנגב
1139 ,כרמיאל ,357 ,חטיבת הראל
1139 ,כרמיאל ,356 ,חטיבת יפתח
1139 ,כרמיאל ,363 ,חטיבת כרמלי
1139 ,כרמיאל ,366 ,חטיבת עציוני
1139 ,כרמיאל ,314 ,חלמיש
1139 ,כרמיאל ,126 ,חן
1139 ,כרמיאל ,216 ,חצור
1139 ,כרמיאל ,250 ,חריש
1139 ,כרמיאל ,207 ,חרמון
1139 ,כרמיאל ,226 ,חרצית
1139 ,כרמיאל ,305 ,טוחן
1139 ,כרמיאל ,265 ,טיילת בולטימור
1139 ,כרמיאל ,131 ,יודפת
1139 ,כרמיאל ,320 ,יונק הדבש
1139 ,כרמיאל ,323 ,יונת הבר
1139 ,כרמיאל ,132 ,יחיעם
1139 ,כרמיאל ,164 ,יסמין
1139 ,כרמיאל ,327 ,יסעור
1139 ,כרמיאל ,189 ,יערה
1139 ,כרמיאל ,299 ,יקב
1139 ,כרמיאל ,148 ,יקינתון
1139 ,כרמיאל ,369 ,כדורי
1139 ,כרמיאל ,127 ,כלנית
1139 ,כרמיאל ,6501 ,כפר הילדים
1139 ,כרמיאל ,177 ,כרכום
1139 ,כרמיאל ,9000 ,כרמיאל
1139 ,כרמיאל ,308 ,לבונה
1139 ,כרמיאל ,196 ,לבנה
1139 ,כרמיאל ,157 ,להב
1139 ,כרמיאל ,190 ,לוטם
1139 ,כרמיאל ,370 ,לטרון
1139 ,כרמיאל ,175 ,לילך
1139 ,כרמיאל ,365 ,מבצע אסף
1139 ,כרמיאל ,354 ,מבצע בן עמי
1139 ,כרמיאל ,367 ,מבצע חורב
1139 ,כרמיאל ,372 ,מבצע יבוסי
1139 ,כרמיאל ,352 ,מבצע יואב
1139 ,כרמיאל ,362 ,מבצע מכבי
1139 ,כרמיאל ,355 ,מבצע נחשון
1139 ,כרמיאל ,364 ,מבצע עובדה
1139 ,כרמיאל ,217 ,מגידו
1139 ,כרמיאל ,306 ,מור
1139 ,כרמיאל ,140 ,מורד הגיא
1139 ,כרמיאל ,204 ,מירון
1139 ,כרמיאל ,211 ,מעלה אורט
1139 ,כרמיאל ,116 ,מעלה הכרמים
1139 ,כרמיאל ,152 ,מעלה כמון
1139 ,כרמיאל ,125 ,מצפה נוף
1139 ,כרמיאל ,150 ,מרגנית
1139 ,כרמיאל ,192 ,מרווה
1139 ,כרמיאל ,113 ,מרכז קליטה
1139 ,כרמיאל ,170 ,משגב
1139 ,כרמיאל ,330 ,משעול אנפה
1139 ,כרמיאל ,203 ,משעול ארז
1139 ,כרמיאל ,163 ,משעול דולב
1139 ,כרמיאל ,213 ,משעול היין
1139 ,כרמיאל ,212 ,משעול הכורם
1139 ,כרמיאל ,107 ,משעול הסביונים
1139 ,כרמיאל ,145 ,משעול הסיפן
1139 ,כרמיאל ,108 ,משעול הרקפות
1139 ,כרמיאל ,142 ,משעול חצב
1139 ,כרמיאל ,139 ,משעול יגאל אלון
1139 ,כרמיאל ,165 ,משעול מורן
1139 ,כרמיאל ,146 ,משעול נורית
1139 ,כרמיאל ,284 ,משעול שדמה
1139 ,כרמיאל ,173 ,משעול שקד
1139 ,כרמיאל ,331 ,משעול שקנאי
1139 ,כרמיאל ,208 ,משעול תפן
1139 ,כרמיאל ,302 ,מתכת
1139 ,כרמיאל ,380 ,נהר הירדן
1139 ,כרמיאל ,315 ,נחושת
1139 ,כרמיאל ,398 ,נחל אל-על
1139 ,כרמיאל ,396 ,נחל בצת
1139 ,כרמיאל ,393 ,נחל געתון
1139 ,כרמיאל ,382 ,נחל דישון
1139 ,כרמיאל ,385 ,נחל דליה
1139 ,כרמיאל ,388 ,נחל דלתון
1139 ,כרמיאל ,394 ,נחל דן
1139 ,כרמיאל ,386 ,נחל חרוד
1139 ,כרמיאל ,391 ,נחל יגור
1139 ,כרמיאל ,387 ,נחל כזיב
1139 ,כרמיאל ,392 ,נחל נעמן
1139 ,כרמיאל ,395 ,נחל סער
1139 ,כרמיאל ,383 ,נחל עמוד
1139 ,כרמיאל ,384 ,נחל צבעון
1139 ,כרמיאל ,389 ,נחל צלמון
1139 ,כרמיאל ,397 ,נחל קלע
1139 ,כרמיאל ,390 ,נחל שגור
1139 ,כרמיאל ,381 ,נחל שניר
1139 ,כרמיאל ,186 ,נחליאלי
1139 ,כרמיאל ,328 ,נקר
1139 ,כרמיאל ,143 ,נרקיס
1139 ,כרמיאל ,180 ,נשר
1139 ,כרמיאל ,373 ,נתיב הל"ה
1139 ,כרמיאל ,144 ,נתיב הלוטוס
1139 ,כרמיאל ,358 ,נתיב הסיירים
1139 ,כרמיאל ,176 ,סיתוונית
1139 ,כרמיאל ,326 ,סלעית
1139 ,כרמיאל ,194 ,סמ נחליאלי
1139 ,כרמיאל ,286 ,סמדר
1139 ,כרמיאל ,200 ,סמטת צבעוני
1139 ,כרמיאל ,219 ,סנונית
1139 ,כרמיאל ,182 ,עגור
1139 ,כרמיאל ,276 ,עומר
1139 ,כרמיאל ,7001 ,עין כמונים
1139 ,כרמיאל ,295 ,עינב
1139 ,כרמיאל ,138 ,עירית
1139 ,כרמיאל ,187 ,עפרוני
1139 ,כרמיאל ,205 ,עצמון
1139 ,כרמיאל ,199 ,ערבה
1139 ,כרמיאל ,154 ,פארק אלון
1139 ,כרמיאל ,153 ,פארק הנוער
1139 ,כרמיאל ,374 ,פל-ים
1139 ,כרמיאל ,321 ,פשוש
1139 ,כרמיאל ,221 ,צאלון
1139 ,כרמיאל ,105 ,צה"ל
1139 ,כרמיאל ,322 ,צופית
1139 ,כרמיאל ,310 ,צור
1139 ,כרמיאל ,218 ,ציפורי
1139 ,כרמיאל ,260 ,קטיף
1139 ,כרמיאל ,251 ,קציר
1139 ,כרמיאל ,123 ,קרן היסוד
1139 ,כרמיאל ,169 ,רותם
1139 ,כרמיאל ,171 ,רמים
1139 ,כרמיאל ,6508 ,רמת רבין
1139 ,כרמיאל ,262 ,שביל אייר
1139 ,כרמיאל ,256 ,שביל אלול
1139 ,כרמיאל ,252 ,שביל הזורעים
1139 ,כרמיאל ,278 ,שביל חשון
1139 ,כרמיאל ,259 ,שביל טבת
1139 ,כרמיאל ,279 ,שביל כסלו
1139 ,כרמיאל ,263 ,שביל ניסן
1139 ,כרמיאל ,254 ,שביל סיון
1139 ,כרמיאל ,258 ,שביל שבט
1139 ,כרמיאל ,255 ,שביל תמוז
1139 ,כרמיאל ,277 ,שביל תשרי
1139 ,כרמיאל ,201 ,שגיא
1139 ,כרמיאל ,294 ,שד בית הכרם
1139 ,כרמיאל ,188 ,שד השלום
1139 ,כרמיאל ,174 ,שד נשיאי ישראל
1139 ,כרמיאל ,210 ,שד קק"ל
1139 ,כרמיאל ,285 ,שדמה
1139 ,כרמיאל ,291 ,שורק
1139 ,כרמיאל ,311 ,שחם
1139 ,כרמיאל ,181 ,שחף
1139 ,כרמיאל ,195 ,שיזף
1139 ,כרמיאל ,197 ,שיטה
1139 ,כרמיאל ,6503 ,שכ אשכול
1139 ,כרמיאל ,6506 ,שכ האירוסים
1139 ,כרמיאל ,6502 ,שכ הגליל
1139 ,כרמיאל ,6510 ,שכ הדר
1139 ,כרמיאל ,6511 ,שכ המייסדים
1139 ,כרמיאל ,6513 ,שכ הערבה
1139 ,כרמיאל ,6505 ,שכ טנה
1139 ,כרמיאל ,6512 ,שכ מגדים
1139 ,כרמיאל ,6509 ,שכ שגיא
1139 ,כרמיאל ,332 ,שלדג
1139 ,כרמיאל ,350 ,שער הגיא
1139 ,כרמיאל ,329 ,שקנאי
1139 ,כרמיאל ,287 ,שריג
1139 ,כרמיאל ,371 ,ששת הימים
1139 ,כרמיאל ,206 ,תבור
1139 ,כרמיאל ,137 ,תדהר
1139 ,כרמיאל ,223 ,תור
1139 ,כרמיאל ,293 ,תירוש
768 ,כרמיה ,102 ,האשכול
768 ,כרמיה ,104 ,הבציר
768 ,כרמיה ,101 ,הגפן
768 ,כרמיה ,105 ,היין
768 ,כרמיה ,103 ,התירוש
768 ,כרמיה ,9000 ,כרמיה
1198 ,כרמים ,9000 ,כרמים
1198 ,כרמים ,101 ,מרכז קליטה
3656 ,כרמל ,9000 ,כרמל
1207 ,לבון ,108 ,אודם
1207 ,לבון ,107 ,אלמוג
1207 ,לבון ,112 ,בזלת
1207 ,לבון ,105 ,ברקת
1207 ,לבון ,103 ,גביש
1207 ,לבון ,106 ,הפנינה
1207 ,לבון ,113 ,חושן
1207 ,לבון ,109 ,טופז
1207 ,לבון ,114 ,טורקיז
1207 ,לבון ,115 ,יהלום
1207 ,לבון ,9000 ,לבון
1207 ,לבון ,102 ,לשם
1207 ,לבון ,116 ,סמטת המים
1207 ,לבון ,110 ,ספיר
1207 ,לבון ,104 ,ענבר
1207 ,לבון ,117 ,צדף
1207 ,לבון ,118 ,קורל
1207 ,לבון ,101 ,שוהם
1207 ,לבון ,111 ,שחם
585 ,לביא ,101 ,אולפן
585 ,לביא ,102 ,בית ראשון במולדת
585 ,לביא ,9000 ,לביא
1230 ,לבנים ,9000 ,לבנים
2023 ,להב ,9000 ,להב
380 ,להבות הבשן ,102 ,אולפן
380 ,להבות הבשן ,101 ,בית ראשון במולדת
380 ,להבות הבשן ,9000 ,להבות הבשן
715 ,להבות חביבה ,9000 ,להבות חביבה
1271 ,להבים ,201 ,אגמית
1271 ,להבים ,136 ,אודם
1271 ,להבים ,118 ,איריס
1271 ,להבים ,202 ,אנפה
1271 ,להבים ,141 ,בארית
1271 ,להבים ,203 ,בז
1271 ,להבים ,204 ,בצנית
1271 ,להבים ,205 ,ברבור
1271 ,להבים ,206 ,ברכיה
1271 ,להבים ,105 ,ברקן
1271 ,להבים ,137 ,ברקת
1271 ,להבים ,207 ,גבתון
1271 ,להבים ,208 ,גדרון
1271 ,להבים ,106 ,גומא
1271 ,להבים ,123 ,גפן
1271 ,להבים ,101 ,דגנית
1271 ,להבים ,209 ,דוכיפת
1271 ,להבים ,210 ,דרור
1271 ,להבים ,135 ,דרך אבני החושן
1271 ,להבים ,211 ,הודן
1271 ,להבים ,107 ,הרדוף
1271 ,להבים ,102 ,ורד
1271 ,להבים ,212 ,ורדית
1271 ,להבים ,108 ,זוגן
1271 ,להבים ,122 ,זית
1271 ,להבים ,213 ,זמיר
1271 ,להבים ,214 ,זרזיר
1271 ,להבים ,215 ,חגלה
1271 ,להבים ,216 ,חוחית
1271 ,להבים ,217 ,חנקן
1271 ,להבים ,218 ,חסידה
1271 ,להבים ,104 ,חרצית
1271 ,להבים ,219 ,טבלן
1271 ,להבים ,109 ,טופח
1271 ,להבים ,220 ,טסית
1271 ,להבים ,221 ,ינשוף
1271 ,להבים ,222 ,יסעור
1271 ,להבים ,110 ,יערה
1271 ,להבים ,111 ,כוכבן
1271 ,להבים ,223 ,כוכית
1271 ,להבים ,225 ,כחול זנב
1271 ,להבים ,224 ,כחל
1271 ,להבים ,226 ,לבנית
1271 ,להבים ,9000 ,להבים
1271 ,להבים ,112 ,לוטם
1271 ,להבים ,227 ,לילית
1271 ,להבים ,228 ,מגלן
1271 ,להבים ,229 ,מדברון
1271 ,להבים ,113 ,מורן
1271 ,להבים ,230 ,מרומית
1271 ,להבים ,193 ,נחל אדוריים
1271 ,להבים ,195 ,נחל אילון
1271 ,להבים ,194 ,נחל אפיקים
1271 ,להבים ,191 ,נחל בוקק
1271 ,להבים ,189 ,נחל בצת
1271 ,להבים ,192 ,נחל ברק
1271 ,להבים ,190 ,נחל בשור
1271 ,להבים ,185 ,נחל גוברין
1271 ,להבים ,186 ,נחל גלים
1271 ,להבים ,182 ,נחל געתון
1271 ,להבים ,181 ,נחל גרר
1271 ,להבים ,188 ,נחל דולב
1271 ,להבים ,187 ,נחל דישון
1271 ,להבים ,183 ,נחל האלה
1271 ,להבים ,184 ,נחל הד
1271 ,להבים ,161 ,נחל זוהר
1271 ,להבים ,160 ,נחל זויתן
1271 ,להבים ,162 ,נחל זרחן
1271 ,להבים ,163 ,נחל חביבה
1271 ,להבים ,164 ,נחל חברון
1271 ,להבים ,165 ,נחל חדרה
1271 ,להבים ,166 ,נחל חצבה
1271 ,להבים ,159 ,נחל חרמון
1271 ,להבים ,168 ,נחל טוב
1271 ,להבים ,167 ,נחל טלה
1271 ,להבים ,172 ,נחל יבוק
1271 ,להבים ,173 ,נחל יגור
1271 ,להבים ,174 ,נחל יהודייה
1271 ,להבים ,175 ,נחל יסף
1271 ,להבים ,177 ,נחל יעלים
1271 ,להבים ,176 ,נחל ירוחם
1271 ,להבים ,171 ,נחל ירמוך
1271 ,להבים ,169 ,נחל ירקון
1271 ,להבים ,178 ,נחל יתיר
1271 ,להבים ,151 ,נחל כזיב
1271 ,להבים ,153 ,נחל כלח
1271 ,להבים ,154 ,נחל כרכום
1271 ,להבים ,152 ,נחל לכיש
1271 ,להבים ,156 ,נחל מגדים
1271 ,להבים ,155 ,נחל מחניים
1271 ,להבים ,231 ,נחליאלי
1271 ,להבים ,233 ,נץ
1271 ,להבים ,114 ,נרקיס
1271 ,להבים ,232 ,נשר
1271 ,להבים ,234 ,סבכי
1271 ,להבים ,235 ,סופית
1271 ,להבים ,103 ,סייפן
1271 ,להבים ,236 ,סלעית
1271 ,להבים ,237 ,סנונית
1271 ,להבים ,138 ,ספיר
1271 ,להבים ,238 ,עגור
1271 ,להבים ,115 ,עדעד
1271 ,להבים ,239 ,עיט
1271 ,להבים ,131 ,עין הבשור
1271 ,להבים ,134 ,עין זיק
1271 ,להבים ,128 ,עין מור
1271 ,להבים ,132 ,עין סהרונים
1271 ,להבים ,130 ,עין עבדת
1271 ,להבים ,126 ,עין עופרים
1271 ,להבים ,127 ,עין עקב
1271 ,להבים ,133 ,עין צין
1271 ,להבים ,129 ,עין שחק
1271 ,להבים ,240 ,עפרוני
1271 ,להבים ,241 ,פנינית
1271 ,להבים ,119 ,פעמונית
1271 ,להבים ,242 ,פרפור
1271 ,להבים ,243 ,פשוש
1271 ,להבים ,120 ,צבעוני
1271 ,להבים ,117 ,צלף
1271 ,להבים ,116 ,קידה
1271 ,להבים ,124 ,רימון
1271 ,להבים ,244 ,רקפת
1271 ,להבים ,125 ,שד תאנה
1271 ,להבים ,121 ,שד תמר
1271 ,להבים ,150 ,שדרות חיטה
1271 ,להבים ,180 ,שדרות שעורה
1271 ,להבים ,139 ,שוהם
1271 ,להבים ,245 ,שיטה
1271 ,להבים ,6501 ,שכ המניפה
1271 ,להבים ,6500 ,שכ המרכז
1271 ,להבים ,246 ,תפוח
1271 ,להבים ,140 ,תרשיש
7000 ,לוד ,324 ,אבו איימן
7000 ,לוד ,782 ,אבו בקר אל צדיק
7000 ,לוד ,223 ,אבו חצירא
7000 ,לוד ,189 ,אברהם אבינו
7000 ,לוד ,733 ,אברמוביץ שמעון
7000 ,לוד ,450 ,אגוז
7000 ,לוד ,378 ,אגס
7000 ,לוד ,412 ,אדיסון
7000 ,לוד ,730 ,אדמונית
7000 ,לוד ,224 ,אוסישקין
7000 ,לוד ,6510 ,אזור תעשיה צפון
7000 ,לוד ,149 ,אחד העם
7000 ,לוד ,333 ,אחד עשר ביולי
7000 ,לוד ,558 ,אחוה
7000 ,לוד ,362 ,אחימאיר אבא
7000 ,לוד ,787 ,איבן סינא
7000 ,לוד ,788 ,איבן תימיה
7000 ,לוד ,364 ,אילת
7000 ,לוד ,504 ,אינשטיין
7000 ,לוד ,163 ,איסמאילוב
7000 ,לוד ,520 ,איסקוב
7000 ,לוד ,184 ,אל מוקדסי
7000 ,לוד ,212 ,אלבז נתן
7000 ,לוד ,413 ,אליעזר יעקב
7000 ,לוד ,728 ,אלכסנדר
7000 ,לוד ,554 ,אלפעל
7000 ,לוד ,345 ,אלשוילי אבישי
7000 ,לוד ,424 ,אלתר רחל
7000 ,לוד ,457 ,אמנון ותמר
7000 ,לוד ,777 ,אנדלוס
7000 ,לוד ,402 ,אנילביץ
7000 ,לוד ,419 ,אפטושו
7000 ,לוד ,380 ,אפרסק
7000 ,לוד ,353 ,אקסודוס
7000 ,לוד ,707 ,ארבע העונות
7000 ,לוד ,451 ,ארז
7000 ,לוד ,401 ,ארלוזורוב
7000 ,לוד ,368 ,ארן זלמן
7000 ,לוד ,409 ,אשכול אלישבע
7000 ,לוד ,381 ,אשכולית
7000 ,לוד ,382 ,אשל
7000 ,לוד ,383 ,אתרוג
7000 ,לוד ,211 ,בורוכוב
7000 ,לוד ,206 ,ביאליק
7000 ,לוד ,182 ,ביבארס
7000 ,לוד ,414 ,ביל"ו
7000 ,לוד ,108 ,בית"ר
7000 ,לוד ,312 ,בלום לאון
7000 ,לוד ,320 ,בלפור
7000 ,לוד ,601 ,בן אליעזר אריה
7000 ,לוד ,347 ,בן ארי לירן
7000 ,לוד ,174 ,בן הורקנוס אליעזר
7000 ,לוד ,532 ,בן חמו אהרן
7000 ,לוד ,509 ,בן חקון
7000 ,לוד ,360 ,בן יהודה
7000 ,לוד ,323 ,בן יוסף אהרן
7000 ,לוד ,751 ,בן שמן
7000 ,לוד ,565 ,בעל התניא
7000 ,לוד ,308 ,בר אילן
7000 ,לוד ,315 ,בר כוכבא
7000 ,לוד ,734 ,בר-לב חיים
7000 ,לוד ,306 ,ברנר
7000 ,לוד ,336 ,ברק
7000 ,לוד ,232 ,בת שבע
7000 ,לוד ,411 ,גבורי ישראל
7000 ,לוד ,6516 ,גבעת הזיתים
7000 ,לוד ,363 ,גבריאל
7000 ,לוד ,225 ,גוטמן אריה
7000 ,לוד ,559 ,גולדברג לאה
7000 ,לוד ,207 ,גולומב
7000 ,לוד ,550 ,גולן
7000 ,לוד ,208 ,גורדון א ד
7000 ,לוד ,339 ,גושן
7000 ,לוד ,551 ,גלבוע
7000 ,לוד ,337 ,גלעד
7000 ,לוד ,6546 ,גני אביב
7000 ,לוד ,6511 ,גני יער
7000 ,לוד ,373 ,גנרל לקלרק
7000 ,לוד ,152 ,גרונר דב
7000 ,לוד ,213 ,גרטבול
7000 ,לוד ,366 ,גרינבוים
7000 ,לוד ,105 ,גרץ
7000 ,לוד ,183 ,גשר לוד
7000 ,לוד ,136 ,דבורה הנביאה
7000 ,לוד ,103 ,דביקאר יחזקאל
7000 ,לוד ,384 ,דובדבן
7000 ,לוד ,104 ,דובנוב
7000 ,לוד ,548 ,דוד ברוך
7000 ,לוד ,507 ,דיזרעאלי
7000 ,לוד ,205 ,דיין דבורה
7000 ,לוד ,521 ,דם המכבים
7000 ,לוד ,715 ,דן
7000 ,לוד ,708 ,דעי יואב
7000 ,לוד ,753 ,דקר
7000 ,לוד ,374 ,דרך בית הדין
7000 ,לוד ,255 ,דרך האחדות
7000 ,לוד ,757 ,דרך לוד הירוקה
7000 ,לוד ,459 ,דרך משמר נוף
7000 ,לוד ,767 ,דרך נופי חמד
7000 ,לוד ,555 ,דרכי משה
7000 ,לוד ,246 ,ה' באייר
7000 ,לוד ,564 ,האדמו"ר האמצעי
7000 ,לוד ,537 ,האורנים
7000 ,לוד ,754 ,האיילון
7000 ,לוד ,535 ,האילנות
7000 ,לוד ,530 ,האיריס
7000 ,לוד ,752 ,האלה
7000 ,לוד ,247 ,האצ"ל
7000 ,לוד ,755 ,האקליפטוס
7000 ,לוד ,110 ,הארבעים ושלושה
7000 ,לוד ,193 ,הבטיחות
7000 ,לוד ,165 ,הבעל שם טוב
7000 ,לוד ,181 ,הברדלסים
7000 ,לוד ,338 ,הברושים
7000 ,לוד ,714 ,הבשור
7000 ,לוד ,359 ,הגדוד העברי
7000 ,לוד ,167 ,הגיא
7000 ,לוד ,168 ,הגר
7000 ,לוד ,426 ,הדס
7000 ,לוד ,527 ,הדקל
7000 ,לוד ,452 ,הדרים
7000 ,לוד ,244 ,ההגנה
7000 ,לוד ,406 ,ההסתדרות
7000 ,לוד ,511 ,הוז דב
7000 ,לוד ,536 ,הורדים
7000 ,לוד ,319 ,החלוץ
7000 ,לוד ,301 ,החשמונאים
7000 ,לוד ,191 ,היוצרים
7000 ,לוד ,220 ,הכובשים
7000 ,לוד ,356 ,הלני המלכה
7000 ,לוד ,194 ,המחקר
7000 ,לוד ,176 ,המלאכה
7000 ,לוד ,192 ,המסגר
7000 ,לוד ,210 ,המפרי יוברט
7000 ,לוד ,765 ,המצביאים
7000 ,לוד ,776 ,הנביאים
7000 ,לוד ,779 ,הנילוס
7000 ,לוד ,331 ,הנשיא
7000 ,לוד ,233 ,הס משה
7000 ,לוד ,538 ,הסיירים
7000 ,לוד ,343 ,הסכמי אברהם
7000 ,לוד ,6550 ,העיר העתיקה
7000 ,לוד ,171 ,העמלים
7000 ,לוד ,219 ,העצמאות
7000 ,לוד ,778 ,הפירמידות
7000 ,לוד ,463 ,הפרחים
7000 ,לוד ,190 ,הצורפים
7000 ,לוד ,608 ,הצייר ראובן
7000 ,לוד ,534 ,הצנחנים
7000 ,לוד ,248 ,הקרן הקיימת לישראל
7000 ,לוד ,239 ,הרב אלישיב
7000 ,לוד ,604 ,הרב חורי חיים
7000 ,לוד ,116 ,הרב טייב חי
7000 ,לוד ,242 ,הרב יורם אברג'יל
7000 ,לוד ,218 ,הרב מרדכי אליהו
7000 ,לוד ,240 ,הרב עובדיה יוסף
7000 ,לוד ,318 ,הרב עוזיאל
7000 ,לוד ,316 ,הרב עזריה יעקב
7000 ,לוד ,307 ,הרב קוק
7000 ,לוד ,385 ,הרדוף
7000 ,לוד ,526 ,הרימון
7000 ,לוד ,544 ,הרץ הנרי
7000 ,לוד ,317 ,הרצוג
7000 ,לוד ,101 ,הרצל
7000 ,לוד ,543 ,הרקפות
7000 ,לוד ,313 ,השומרונים
7000 ,לוד ,209 ,השחרור
7000 ,לוד ,539 ,השייטת
7000 ,לוד ,523 ,התבור
7000 ,לוד ,508 ,התקוה
7000 ,לוד ,603 ,וגמן יששכר
7000 ,לוד ,302 ,ויגודסקי
7000 ,לוד ,403 ,ז'בוטינסקי
7000 ,לוד ,260 ,זאב ביסטריצקי
7000 ,לוד ,723 ,זויתן
7000 ,לוד ,605 ,זיגלבוים יואל
7000 ,לוד ,475 ,זית
7000 ,לוד ,166 ,זפרני
7000 ,לוד ,256 ,חאפז חאפי
7000 ,לוד ,606 ,חבצלת
7000 ,לוד ,461 ,חדיד ואונו
7000 ,לוד ,408 ,חזני סולומון
7000 ,לוד ,397 ,חטיבת הנגב
7000 ,לוד ,766 ,חטיבת הראל
7000 ,לוד ,561 ,חטיבת יפתח
7000 ,לוד ,726 ,חידקל
7000 ,לוד ,354 ,חיל הים
7000 ,לוד ,790 ,חלאד אבן אל ואליד
7000 ,לוד ,111 ,חללי אגדיר
7000 ,לוד ,706 ,חללי אגוז
7000 ,לוד ,386 ,חרוב
7000 ,לוד ,552 ,חרמון
7000 ,לוד ,476 ,חרצית
7000 ,לוד ,355 ,טויטו מרדכי
7000 ,לוד ,736 ,טולדנו ניסים
7000 ,לוד ,311 ,טולידאנו
7000 ,לוד ,142 ,טרומפלדור
7000 ,לוד ,109 ,טשרניחובסקי
7000 ,לוד ,350 ,י"א חללי מינכן
7000 ,לוד ,257 ,יגאל יהושע
7000 ,לוד ,102 ,יודפת
7000 ,לוד ,556 ,יוספטל גיורא
7000 ,לוד ,100 ,יחד שבטי ישראל
7000 ,לוד ,180 ,יסמין
7000 ,לוד ,197 ,יעקב אבינו
7000 ,לוד ,253 ,יער אודם
7000 ,לוד ,250 ,יער אמציה
7000 ,לוד ,251 ,יער ביריה
7000 ,לוד ,252 ,יער ברעם
7000 ,לוד ,254 ,יער עמינדב
7000 ,לוד ,249 ,יערות הכרמל
7000 ,לוד ,198 ,יצחק אבינו
7000 ,לוד ,196 ,יצחק שמיר
7000 ,לוד ,453 ,יקינטון
7000 ,לוד ,351 ,יריחו
7000 ,לוד ,727 ,ירמוך
7000 ,לוד ,519 ,ישיבת חב"ד
7000 ,לוד ,231 ,כהן אלי
7000 ,לוד ,202 ,כהן יעקב
7000 ,לוד ,304 ,כהן מיכאל
7000 ,לוד ,6001 ,ככר העצמאות
7000 ,לוד ,6002 ,ככר הפלמ"ח
7000 ,לוד ,6003 ,ככר השריון
7000 ,לוד ,6004 ,ככר קומנדו
7000 ,לוד ,387 ,כליל החורש
7000 ,לוד ,528 ,כלניות
7000 ,לוד ,143 ,כצנלסון
7000 ,לוד ,477 ,כרכום
7000 ,לוד ,425 ,כרמל
7000 ,לוד ,199 ,לאה אימנו
7000 ,לוד ,172 ,לב פסח
7000 ,לוד ,114 ,להמן
7000 ,לוד ,702 ,לובלין אהרון
7000 ,לוד ,9000 ,לוד
7000 ,לוד ,6512 ,לוד הירוקה
7000 ,לוד ,6515 ,לוד הצעירה
7000 ,לוד ,609 ,לוזון אפרים
7000 ,לוד ,169 ,לח"י
7000 ,לוד ,478 ,ליבנה
7000 ,לוד ,716 ,ליטאני
7000 ,לוד ,456 ,לילך
7000 ,לוד ,388 ,לימון
7000 ,לוד ,258 ,מאיר הר ציון
7000 ,לוד ,170 ,מבצע יהונתן
7000 ,לוד ,238 ,מודיעין
7000 ,לוד ,241 ,מוחמד רג'ב חסונה
7000 ,לוד ,789 ,מועאויה אבו ספיאן
7000 ,לוד ,119 ,מורדי הגטאות
7000 ,לוד ,389 ,מורן
7000 ,לוד ,562 ,מזרחי אברהם
7000 ,לוד ,549 ,מזרחי דוד
7000 ,לוד ,357 ,מחנות קפריסין
7000 ,לוד ,531 ,מידנק אריה
7000 ,לוד ,738 ,ממן יוסף
7000 ,לוד ,756 ,מנהרת העיר
7000 ,לוד ,195 ,מנחם בגין
7000 ,לוד ,303 ,מס דני
7000 ,לוד ,157 ,מסדה
7000 ,לוד ,371 ,מעון א
7000 ,לוד ,422 ,מעון אקדמאים
7000 ,לוד ,370 ,מעון ב
7000 ,לוד ,427 ,מעון עולים
7000 ,לוד ,479 ,מרגנית
7000 ,לוד ,506 ,מרזוק משה
7000 ,לוד ,230 ,משה רבינו
7000 ,לוד ,6521 ,נאות יצחק
7000 ,לוד ,416 ,נדבה יוסף
7000 ,לוד ,775 ,נהר הירדן
7000 ,לוד ,611 ,נוישטיין פנחס
7000 ,לוד ,115 ,נורדאו
7000 ,לוד ,480 ,נורית
7000 ,לוד ,334 ,נחושתן אורי
7000 ,לוד ,325 ,ניל"י
7000 ,לוד ,518 ,נימרוב
7000 ,לוד ,705 ,ניצנים
7000 ,לוד ,709 ,נירים
7000 ,לוד ,529 ,נרקיס
7000 ,לוד ,602 ,נתניהו יהונתן
7000 ,לוד ,481 ,סביון
7000 ,לוד ,6520 ,סביוני לוד
7000 ,לוד ,358 ,סג'רה
7000 ,לוד ,330 ,סולד
7000 ,לוד ,144 ,סוקולוב
7000 ,לוד ,729 ,סחלב
7000 ,לוד ,361 ,סטרומה
7000 ,לוד ,379 ,סיגלון
7000 ,לוד ,482 ,סיגלית
7000 ,לוד ,454 ,סייפן
7000 ,לוד ,164 ,סילבר אבא הלל
7000 ,לוד ,483 ,סיתונית
7000 ,לוד ,113 ,סלמה שלמה
7000 ,לוד ,737 ,סלניקיו
7000 ,לוד ,227 ,סמטת שלמה המלך
7000 ,לוד ,341 ,סן מרטין
7000 ,לוד ,214 ,סנש חנה
7000 ,לוד ,484 ,סער עקיבא
7000 ,לוד ,234 ,ספרן מאיר
7000 ,לוד ,106 ,עולי הגרדום
7000 ,לוד ,783 ,עומר אבן אלכטאב
7000 ,לוד ,786 ,עומר עבד אלעזיז
7000 ,לוד ,784 ,עותמן אבן עפאן
7000 ,לוד ,785 ,עלי אבי טאלב
7000 ,לוד ,703 ,עלי כותרת
7000 ,לוד ,369 ,עליה ב
7000 ,לוד ,410 ,עמנואל
7000 ,לוד ,335 ,ערבה
7000 ,לוד ,704 ,ערוגות
7000 ,לוד ,131 ,עשרת הרוגי מלכות
7000 ,לוד ,155 ,פוגה
7000 ,לוד ,739 ,פורצי מסך הברזל
7000 ,לוד ,237 ,פינלס
7000 ,לוד ,118 ,פינסקר
7000 ,לוד ,541 ,פינשטיין משה
7000 ,לוד ,731 ,פיקוס
7000 ,לוד ,525 ,פלדמן
7000 ,לוד ,235 ,פלומניק יעקב
7000 ,לוד ,503 ,פנקס דוד
7000 ,לוד ,372 ,פרימן מרדכי וחוה
7000 ,לוד ,221 ,פרנק אנה
7000 ,לוד ,390 ,צאלון
7000 ,לוד ,781 ,צאלח א דין
7000 ,לוד ,259 ,צבי איצקוביץ
7000 ,לוד ,557 ,צבר
7000 ,לוד ,545 ,צוללת דקר
7000 ,לוד ,458 ,ציפורן
7000 ,לוד ,563 ,צמח צדק
7000 ,לוד ,701 ,צמרות
7000 ,לוד ,546 ,קדושי קהילת מזריץ
7000 ,לוד ,460 ,קהילת ניו יורק
7000 ,לוד ,415 ,קוטנר אברהם
7000 ,לוד ,719 ,קישון
7000 ,לוד ,117 ,קלויזנר
7000 ,לוד ,138 ,קלר הלן
7000 ,לוד ,540 ,קני סוף
7000 ,לוד ,462 ,קנמון
7000 ,לוד ,407 ,קפלן
7000 ,לוד ,6549 ,קרית הספורט
7000 ,לוד ,6535 ,קרית חינוך
7000 ,לוד ,217 ,רבי טרפון
7000 ,לוד ,216 ,רבי עקיבא
7000 ,לוד ,780 ,רבין יצחק
7000 ,לוד ,200 ,רבקה אימנו
7000 ,לוד ,712 ,רגבים
7000 ,לוד ,329 ,רובין אריה
7000 ,לוד ,542 ,רוזנבלום
7000 ,לוד ,228 ,רוזנוולד ויליאם
7000 ,לוד ,332 ,רוקח
7000 ,לוד ,455 ,רותם
7000 ,לוד ,137 ,רזיאל
7000 ,לוד ,204 ,רח כו
7000 ,לוד ,154 ,רח לג
7000 ,לוד ,377 ,רח לל
7000 ,לוד ,107 ,רח מז
7000 ,לוד ,112 ,רח מח
7000 ,לוד ,310 ,רח ממ
7000 ,לוד ,156 ,רח מנ
7000 ,לוד ,203 ,רח מס
7000 ,לוד ,127 ,רח נדא
7000 ,לוד ,309 ,רח נו
7000 ,לוד ,126 ,רח נז
7000 ,לוד ,125 ,רח נח
7000 ,לוד ,123 ,רח נט
7000 ,לוד ,120 ,רח סא
7000 ,לוד ,124 ,רח סב
7000 ,לוד ,128 ,רח סג
7000 ,לוד ,122 ,רח סד
7000 ,לוד ,121 ,רח סה
7000 ,לוד ,129 ,רח סו
7000 ,לוד ,130 ,רח סז
7000 ,לוד ,134 ,רח סט
7000 ,לוד ,132 ,רח פא
7000 ,לוד ,135 ,רח פב
7000 ,לוד ,133 ,רח פג
7000 ,לוד ,140 ,רח פד
7000 ,לוד ,141 ,רח פה
7000 ,לוד ,158 ,רח פת
7000 ,לוד ,327 ,רח צא
7000 ,לוד ,328 ,רח צב
7000 ,לוד ,326 ,רח צג
7000 ,לוד ,146 ,רח קד
7000 ,לוד ,314 ,רח ש
7000 ,לוד ,344 ,רח שר
7000 ,לוד ,322 ,רח תג
7000 ,לוד ,321 ,רח תד
7000 ,לוד ,375 ,רח תה
7000 ,לוד ,349 ,רח תו
7000 ,לוד ,376 ,רח תח
7000 ,לוד ,201 ,רחל אימנו
7000 ,לוד ,139 ,רחל המשוררת
7000 ,לוד ,215 ,רמב"ם
7000 ,לוד ,505 ,רמז
7000 ,לוד ,6508 ,רמת אשכול
7000 ,לוד ,750 ,רפאל
7000 ,לוד ,515 ,רפאל הכהן
7000 ,לוד ,6500 ,ש אסבסט צפון
7000 ,לוד ,6526 ,ש אסבסטונים
7000 ,לוד ,6527 ,ש בנית
7000 ,לוד ,6501 ,ש זוגות צעירים
7000 ,לוד ,6536 ,ש חבד
7000 ,לוד ,6528 ,ש חסכון
7000 ,לוד ,6537 ,ש חסכון ג
7000 ,לוד ,6538 ,ש ממשלתי
7000 ,לוד ,6502 ,ש מפונים
7000 ,לוד ,6530 ,ש נוה זית
7000 ,לוד ,6503 ,ש נוה ירק
7000 ,לוד ,6547 ,ש נווה שלום
7000 ,לוד ,6539 ,ש עמידר
7000 ,לוד ,6531 ,ש עממי
7000 ,לוד ,6504 ,ש פתוח
7000 ,לוד ,6505 ,ש צפון
7000 ,לוד ,6540 ,ש רמט
7000 ,לוד ,6541 ,ש רסטרום
7000 ,לוד ,6542 ,ש רסקו
7000 ,לוד ,6506 ,ש שרת
7000 ,לוד ,188 ,שבט אפרים
7000 ,לוד ,186 ,שבט אשר
7000 ,לוד ,185 ,שבט גד
7000 ,לוד ,178 ,שבט זבולון
7000 ,לוד ,162 ,שבט יהודה
7000 ,לוד ,177 ,שבט יששכר
7000 ,לוד ,243 ,שבט לוי
7000 ,לוד ,187 ,שבט מנשה
7000 ,לוד ,179 ,שבט נפתלי
7000 ,לוד ,160 ,שבט ראובן
7000 ,לוד ,161 ,שבט שמעון
7000 ,לוד ,348 ,שד בן גוריון
7000 ,לוד ,421 ,שד דוד המלך
7000 ,לוד ,340 ,שד הציונות
7000 ,לוד ,524 ,שד ירושלים
7000 ,לוד ,305 ,שד צה"ל
7000 ,לוד ,700 ,שד ריסר מיכה
7000 ,לוד ,173 ,שד שורץ
7000 ,לוד ,148 ,שדה יצחק
7000 ,לוד ,547 ,שורץ נתן
7000 ,לוד ,713 ,שושן
7000 ,לוד ,391 ,שזיף
7000 ,לוד ,236 ,שטיינברג
7000 ,לוד ,392 ,שיטה
7000 ,לוד ,6517 ,שכ בן גוריון
7000 ,לוד ,6543 ,שכ ורדה
7000 ,לוד ,6544 ,שכ יד אברהם
7000 ,לוד ,6509 ,שכ יסמין
7000 ,לוד ,6513 ,שכ משמר נוף
7000 ,לוד ,6518 ,שכ נוה אלון
7000 ,לוד ,6519 ,שכ נוה נוף
7000 ,לוד ,6545 ,שכ סחנה
7000 ,לוד ,6507 ,שכ שבזי
7000 ,לוד ,6548 ,שכ שניר
7000 ,לוד ,226 ,שלום עליכם
7000 ,לוד ,405 ,שלמה המלך
7000 ,לוד ,711 ,שמואל
7000 ,לוד ,153 ,שמחוני אסף
7000 ,לוד ,175 ,שמעון בר יוחאי
7000 ,לוד ,222 ,שניאור זלמן
7000 ,לוד ,721 ,שניר
7000 ,לוד ,365 ,שפירא חיים משה
7000 ,לוד ,404 ,שפרינצק
7000 ,לוד ,485 ,שקד
7000 ,לוד ,393 ,שקמה
7000 ,לוד ,229 ,שרה אימנו
7000 ,לוד ,486 ,תאנה
7000 ,לוד ,394 ,תות
7000 ,לוד ,732 ,תילתן
7000 ,לוד ,710 ,תלמים
7000 ,לוד ,487 ,תמר
7000 ,לוד ,395 ,תפוז
7000 ,לוד ,396 ,תפוח
52 ,לוזית ,103 ,אורן
52 ,לוזית ,106 ,אלה
52 ,לוזית ,111 ,אלון
52 ,לוזית ,113 ,ארז
52 ,לוזית ,110 ,אשל
52 ,לוזית ,112 ,ברוש
52 ,לוזית ,108 ,דקל
52 ,לוזית ,114 ,זית
52 ,לוזית ,109 ,חרוב
52 ,לוזית ,9000 ,לוזית
52 ,לוזית ,102 ,ערבה
52 ,לוזית ,107 ,צפצפה
52 ,לוזית ,105 ,רימון
52 ,לוזית ,101 ,שקד
52 ,לוזית ,104 ,תאנה
595 ,לוחמי הגיטאות ,9000 ,לוחמי הגיטאות
1171 ,לוטם ,107 ,אורן
1171 ,לוטם ,105 ,אלה
1171 ,לוטם ,101 ,אלון
1171 ,לוטם ,106 ,ברוש
1171 ,לוטם ,103 ,זית
1171 ,לוטם ,104 ,חרוב
1171 ,לוטם ,102 ,כליל
1171 ,לוטם ,9000 ,לוטם
1171 ,לוטם ,109 ,נרקיסים
1171 ,לוטם ,108 ,סביונים
1255 ,לוטן ,9000 ,לוטן
674 ,לימן ,9000 ,לימן
24 ,לכיש ,100 ,אשכול
24 ,לכיש ,108 ,בציר
24 ,לכיש ,106 ,גפן
24 ,לכיש ,103 ,זמורה
24 ,לכיש ,105 ,כרמים
24 ,לכיש ,9000 ,לכיש
24 ,לכיש ,102 ,סמדר
24 ,לכיש ,104 ,עינב
24 ,לכיש ,107 ,שורק
24 ,לכיש ,101 ,שריג
1310 ,לפיד ,108 ,אגוז
1310 ,לפיד ,110 ,אורנים
1310 ,לפיד ,109 ,אלונים
1310 ,לפיד ,111 ,ארזים
1310 ,לפיד ,105 ,הגולן
1310 ,לפיד ,106 ,הגליל
1310 ,לפיד ,102 ,הדקל
1310 ,לפיד ,104 ,הזית
1310 ,לפיד ,107 ,הירדן
1310 ,לפיד ,101 ,הרי ירושלים
1310 ,לפיד ,103 ,השקמה
1310 ,לפיד ,113 ,ים המלח
1310 ,לפיד ,112 ,ים כנרת
1310 ,לפיד ,9000 ,לפיד
1173 ,לפידות ,9000 ,לפידות
1060 ,לקיה ,191 ,א-ד'הבי
1060 ,לקיה ,132 ,א-זאהיר
1060 ,לקיה ,141 ,א-זהראא
1060 ,לקיה ,137 ,א-זייתונה
1060 ,לקיה ,146 ,א-זנבג'
1060 ,לקיה ,101 ,א-טברי
1060 ,לקיה ,225 ,א-נקב
1060 ,לקיה ,127 ,א-נרג'ס
1060 ,לקיה ,219 ,א-סאח'נה
1060 ,לקיה ,118 ,א-סאלם
1060 ,לקיה ,220 ,א-סאמירייה
1060 ,לקיה ,227 ,א-סלימאנייה
1060 ,לקיה ,149 ,א-סנאבל
1060 ,לקיה ,135 ,א-רומאן
1060 ,לקיה ,223 ,א-ריחאנייה
1060 ,לקיה ,106 ,א-שאפיעי
1060 ,לקיה ,226 ,א-שג'רה
1060 ,לקיה ,121 ,א-תווחיד
1060 ,לקיה ,142 ,א-תין
1060 ,לקיה ,167 ,אבו אל-אסוד א-דואלי
1060 ,לקיה ,170 ,אבו אל-עתאהיה
1060 ,לקיה ,203 ,אבו אל-קאסם א-שאבי
1060 ,לקיה ,201 ,אבו בכר א-ראזי
1060 ,לקיה ,155 ,אבו בכר אבן אל-ערבי
1060 ,לקיה ,104 ,אבו הוריירה
1060 ,לקיה ,154 ,אבו חאמד אל-ר'זאלי
1060 ,לקיה ,230 ,אבו נעים אל-אצפהאני
1060 ,לקיה ,204 ,אבו ערב
1060 ,לקיה ,209 ,אבו פארס אל-חמדאני
1060 ,לקיה ,165 ,אבן חג'ר אל-עסקלאני
1060 ,לקיה ,139 ,אג'רוואן
1060 ,לקיה ,162 ,אחמד בן חנבל
1060 ,לקיה ,198 ,אחמד בן מאג'ד
1060 ,לקיה ,205 ,אחמד זוויל
1060 ,לקיה ,180 ,איבן א-נפיס
1060 ,לקיה ,199 ,איבן א-שאטר
1060 ,לקיה ,185 ,איבן איסחאק
1060 ,לקיה ,173 ,איבן אל-את'יר
1060 ,לקיה ,186 ,איבן אל-בטריק
1060 ,לקיה ,188 ,איבן אל-ג'ווזי
1060 ,לקיה ,187 ,איבן אל-ג'וזייה
1060 ,לקיה ,182 ,איבן אל-הית'ם
1060 ,לקיה ,202 ,איבן אל-ח'ואם
1060 ,לקיה ,177 ,איבן באג'ה
1060 ,לקיה ,193 ,איבן בטוטה
1060 ,לקיה ,194 ,איבן ג'ובייר
1060 ,לקיה ,184 ,איבן דורייד
1060 ,לקיה ,103 ,איבן ח'לדון
1060 ,לקיה ,151 ,איבן כת'יר
1060 ,לקיה ,160 ,איבן מאג'ה
1060 ,לקיה ,181 ,איבן מלכא אל-בגדאדי
1060 ,לקיה ,208 ,איבן מסעוד
1060 ,לקיה ,175 ,איבן סינא
1060 ,לקיה ,229 ,איבן עבאס
1060 ,לקיה ,178 ,איבן רושד
1060 ,לקיה ,102 ,איבן תיימיה
1060 ,לקיה ,126 ,אל-אוורקידא
1060 ,לקיה ,179 ,אל-אידריסי
1060 ,לקיה ,117 ,אל-איסראא'
1060 ,לקיה ,108 ,אל-איעתיסאם
1060 ,לקיה ,216 ,אל-אנדלוס
1060 ,לקיה ,115 ,אל-אסיל
1060 ,לקיה ,119 ,אל-אסילאח
1060 ,לקיה ,168 ,אל-אצמעי
1060 ,לקיה ,213 ,אל-אקצא
1060 ,לקיה ,109 ,אל-בדר
1060 ,לקיה ,207 ,אל-בוזג'אני
1060 ,לקיה ,164 ,אל-בוח'ארי
1060 ,לקיה ,113 ,אל-בוסתאן
1060 ,לקיה ,217 ,אל-בירה
1060 ,לקיה ,143 ,אל-בלסם
1060 ,לקיה ,123 ,אל-ג'ונפל
1060 ,לקיה ,110 ,אל-הודא
1060 ,לקיה ,116 ,אל-וורדה
1060 ,לקיה ,206 ,אל-ח'ואריזמי
1060 ,לקיה ,228 ,אל-חאצבאני
1060 ,לקיה ,114 ,אל-חיכמה
1060 ,לקיה ,124 ,אל-יאסמין
1060 ,לקיה ,196 ,אל-יעקובי
1060 ,לקיה ,107 ,אל-מותנבי
1060 ,לקיה ,120 ,אל-מנארה
1060 ,לקיה ,224 ,אל-מנצורה
1060 ,לקיה ,111 ,אל-מעראג'
1060 ,לקיה ,197 ,אל-מרזוקי
1060 ,לקיה ,112 ,אל-עהד
1060 ,לקיה ,144 ,אל-ענבר
1060 ,לקיה ,174 ,אל-פראבי
1060 ,לקיה ,171 ,אל-פרזדק
1060 ,לקיה ,215 ,אל-קאדיסייה
1060 ,לקיה ,212 ,אל-קודס
1060 ,לקיה ,152 ,אל-קורטובי
1060 ,לקיה ,195 ,אל-קזוויני
1060 ,לקיה ,125 ,אל-קרז
1060 ,לקיה ,183 ,אל-ר'זאלי
1060 ,לקיה ,1060 ,ארג'וואן
1060 ,לקיה ,221 ,באר א-סבע
1060 ,לקיה ,133 ,ביילסאן
1060 ,לקיה ,222 ,ביסאן
1060 ,לקיה ,140 ,בנאן
1060 ,לקיה ,147 ,ג'ורי
1060 ,לקיה ,134 ,ג'ללנאר
1060 ,לקיה ,169 ,ג'ריר
1060 ,לקיה ,158 ,ד'כריא אל-אנצארי
1060 ,לקיה ,148 ,דאליא
1060 ,לקיה ,130 ,זיינב
1060 ,לקיה ,214 ,חיטין
1060 ,לקיה ,211 ,חיפא
1060 ,לקיה ,218 ,יאפא
1060 ,לקיה ,192 ,יאקות אל-חמוי
1060 ,לקיה ,145 ,לילת אל-ג'דר
1060 ,לקיה ,9000 ,לקיה
1060 ,לקיה ,105 ,מאלכ בן אנס
1060 ,לקיה ,166 ,סיבויה
1060 ,לקיה ,150 ,סעיד בן ג'ובייר
1060 ,לקיה ,128 ,פריזיה
1060 ,לקיה ,210 ,צלאח א-זין
1060 ,לקיה ,129 ,רוח א-טיפל
1060 ,לקיה ,138 ,רנדא
1060 ,לקיה ,122 ,שג'אג' א-נועמאן
1060 ,לקיה ,6710 ,שכ 10
1060 ,לקיה ,6718 ,שכ 20
1060 ,לקיה ,6701 ,שכ 1
1060 ,לקיה ,6711 ,שכ 11
1060 ,לקיה ,6702 ,שכ 2
1060 ,לקיה ,6712 ,שכ 12
1060 ,לקיה ,6703 ,שכ 3
1060 ,לקיה ,6713 ,שכ 13
1060 ,לקיה ,6704 ,שכ 4
1060 ,לקיה ,6714 ,שכ 14
1060 ,לקיה ,6705 ,שכ 5
1060 ,לקיה ,6715 ,שכ 15
1060 ,לקיה ,6706 ,שכ 6
1060 ,לקיה ,6716 ,שכ 16
1060 ,לקיה ,6707 ,שכ 7
1060 ,לקיה ,6717 ,שכ 17
1060 ,לקיה ,6708 ,שכ 8
1060 ,לקיה ,6709 ,שכ 9
2055 ,מאור ,9000 ,מאור
102 ,מאיר שפיה ,9000 ,מאיר שפיה
771 ,מבוא ביתר ,107 ,אילות
771 ,מבוא ביתר ,112 ,אשל
771 ,מבוא ביתר ,116 ,האלה
771 ,מבוא ביתר ,110 ,הוותיקים
771 ,מבוא ביתר ,114 ,החורשים
771 ,מבוא ביתר ,109 ,היער
771 ,מבוא ביתר ,111 ,המייסדים
771 ,מבוא ביתר ,101 ,המעין
771 ,מבוא ביתר ,118 ,הנוטעים
771 ,מבוא ביתר ,103 ,הנחל
771 ,מבוא ביתר ,108 ,חד נס
771 ,מבוא ביתר ,9000 ,מבוא ביתר
771 ,מבוא ביתר ,113 ,נילי
771 ,מבוא ביתר ,102 ,ציפורי
771 ,מבוא ביתר ,105 ,רחף
771 ,מבוא ביתר ,104 ,שחק
771 ,מבוא ביתר ,106 ,שיאון
771 ,מבוא ביתר ,117 ,שעל
3569 ,מבוא דותן ,9000 ,מבוא דותן
3709 ,מבוא חורון ,144 ,אשכול בדולח
3709 ,מבוא חורון ,149 ,אשכול גדיד
3709 ,מבוא חורון ,107 ,אשכול גן אור
3709 ,מבוא חורון ,115 ,אשכול גני טל
3709 ,מבוא חורון ,145 ,אשכול דוגית
3709 ,מבוא חורון ,102 ,אשכול כדים
3709 ,מבוא חורון ,104 ,אשכול כפר ים
3709 ,מבוא חורון ,147 ,אשכול מורג
3709 ,מבוא חורון ,114 ,אשכול נוה דקלים
3709 ,מבוא חורון ,152 ,אשכול ניסנית
3709 ,מבוא חורון ,111 ,אשכול נצר חזני
3709 ,מבוא חורון ,106 ,אשכול פאת שדה
3709 ,מבוא חורון ,143 ,אשכול קטיף
3709 ,מבוא חורון ,113 ,אשכול רפיח ים
3709 ,מבוא חורון ,108 ,אשכול תל קטיפא
3709 ,מבוא חורון ,133 ,אשר
3709 ,מבוא חורון ,141 ,בנימין
3709 ,מבוא חורון ,131 ,גד
3709 ,מבוא חורון ,154 ,גנים
3709 ,מבוא חורון ,135 ,דן
3709 ,מבוא חורון ,153 ,דרך אלי סיני
3709 ,מבוא חורון ,146 ,דרך נצרים
3709 ,מבוא חורון ,142 ,דרך שלו
3709 ,מבוא חורון ,150 ,דרכי נועם
3709 ,מבוא חורון ,119 ,הושע
3709 ,מבוא חורון ,117 ,הנביאים
3709 ,מבוא חורון ,151 ,הרב צבי יהודה
3709 ,מבוא חורון ,155 ,השופטים
3709 ,מבוא חורון ,156 ,התעשיה
3709 ,מבוא חורון ,129 ,זבולון
3709 ,מבוא חורון ,138 ,זכריה
3709 ,מבוא חורון ,132 ,חבקוק
3709 ,מבוא חורון ,136 ,חגי
3709 ,מבוא חורון ,109 ,חומש
3709 ,מבוא חורון ,125 ,יהודה
3709 ,מבוא חורון ,120 ,יואל
3709 ,מבוא חורון ,126 ,יונה
3709 ,מבוא חורון ,139 ,יוסף
3709 ,מבוא חורון ,101 ,יפה נוף
3709 ,מבוא חורון ,127 ,יששכר
3709 ,מבוא חורון ,103 ,כפר דרום
3709 ,מבוא חורון ,123 ,לוי
3709 ,מבוא חורון ,9000 ,מבוא חורון
3709 ,מבוא חורון ,128 ,מיכה
3709 ,מבוא חורון ,140 ,מלאכי
3709 ,מבוא חורון ,116 ,מסילת ישרים
3709 ,מבוא חורון ,130 ,נחום
3709 ,מבוא חורון ,137 ,נפתלי
3709 ,מבוא חורון ,124 ,עובדיה
3709 ,מבוא חורון ,122 ,עמוס
3709 ,מבוא חורון ,134 ,צפניה
3709 ,מבוא חורון ,118 ,ראובן
3709 ,מבוא חורון ,148 ,רחבת הגיבורים
3709 ,מבוא חורון ,112 ,שאנור
3709 ,מבוא חורון ,110 ,שדרות אילון
3709 ,מבוא חורון ,105 ,שירת הים
3709 ,מבוא חורון ,121 ,שמעון
4204 ,מבוא חמה ,9000 ,מבוא חמה
1141 ,מבוא מודיעים ,9000 ,מבוא מודיעים
1318 ,מבואות ים ,9000 ,מבואות ים
3825 ,מבואות יריחו ,9000 ,מבואות יריחו
1080 ,מבועים ,102 ,איריס
1080 ,מבועים ,103 ,ברקן
1080 ,מבועים ,104 ,הגפן
1080 ,מבועים ,105 ,הורד
1080 ,מבועים ,106 ,הרדוף
1080 ,מבועים ,107 ,חרצית
1080 ,מבועים ,110 ,כלנית
1080 ,מבועים ,9000 ,מבועים
1080 ,מבועים ,101 ,מרכז אזורי
1080 ,מבועים ,108 ,נרקיס
1080 ,מבועים ,109 ,סייפן
1080 ,מבועים ,111 ,רקפת
829 ,מבטחים ,9000 ,מבטחים
573 ,מבקיעים ,9000 ,מבקיעים
1015 ,מבשרת ציון ,180 ,אגוז
1015 ,מבשרת ציון ,325 ,אופיר
1015 ,מבשרת ציון ,328 ,אופל
1015 ,מבשרת ציון ,401 ,אורנית
1015 ,מבשרת ציון ,6518 ,אזור תעשייה
1015 ,מבשרת ציון ,331 ,אחלמה
1015 ,מבשרת ציון ,153 ,אירוס
1015 ,מבשרת ציון ,103 ,אלון
1015 ,מבשרת ציון ,274 ,ארבל
1015 ,מבשרת ציון ,196 ,בזלת
1015 ,מבשרת ציון ,145 ,ביל"ו
1015 ,מבשרת ציון ,326 ,ברקת
1015 ,מבשרת ציון ,107 ,ברקת ראובן
1015 ,מבשרת ציון ,271 ,גלבוע
1015 ,מבשרת ציון ,6519 ,גן לאומי הקסטל
1015 ,מבשרת ציון ,277 ,דוכיפת
1015 ,מבשרת ציון ,144 ,דם המכבים
1015 ,מבשרת ציון ,322 ,דר
1015 ,מבשרת ציון ,157 ,האודם
1015 ,מבשרת ציון ,170 ,האורן
1015 ,מבשרת ציון ,191 ,האילנות
1015 ,מבשרת ציון ,161 ,האלה
1015 ,מבשרת ציון ,111 ,האפרסק
1015 ,מבשרת ציון ,164 ,הארזים
1015 ,מבשרת ציון ,185 ,האשכול
1015 ,מבשרת ציון ,113 ,הבוסתנים
1015 ,מבשרת ציון ,306 ,הבושם
1015 ,מבשרת ציון ,166 ,הברוש
1015 ,מבשרת ציון ,115 ,הגפן
1015 ,מבשרת ציון ,149 ,הדודאים
1015 ,מבשרת ציון ,129 ,הדס
1015 ,מבשרת ציון ,261 ,הדרור
1015 ,מבשרת ציון ,117 ,הזית
1015 ,מבשרת ציון ,260 ,הזמיר
1015 ,מבשרת ציון ,160 ,החרוב
1015 ,מבשרת ציון ,189 ,היצירה
1015 ,מבשרת ציון ,165 ,הכלנית
1015 ,מבשרת ציון ,179 ,הכרכום
1015 ,מבשרת ציון ,114 ,הכרמים
1015 ,מבשרת ציון ,320 ,הלוטם
1015 ,מבשרת ציון ,148 ,המעפילים
1015 ,מבשרת ציון ,150 ,המרגנית
1015 ,מבשרת ציון ,184 ,המשוריינים
1015 ,מבשרת ציון ,127 ,המשלט
1015 ,מבשרת ציון ,143 ,הנורית
1015 ,מבשרת ציון ,135 ,הנרקיס
1015 ,מבשרת ציון ,151 ,הסיפן
1015 ,מבשרת ציון ,262 ,הסנונית
1015 ,מבשרת ציון ,263 ,העפרוני
1015 ,מבשרת ציון ,122 ,הפלמ"ח
1015 ,מבשרת ציון ,324 ,הפנינה
1015 ,מבשרת ציון ,268 ,הר כנען
1015 ,מבשרת ציון ,154 ,הראל
1015 ,מבשרת ציון ,124 ,הראשונים
1015 ,מבשרת ציון ,118 ,הרב יוסף חיים
1015 ,מבשרת ציון ,253 ,הרב ניסים יצחק
1015 ,מבשרת ציון ,140 ,הרמב"ם
1015 ,מבשרת ציון ,132 ,השורק
1015 ,מבשרת ציון ,302 ,השחרור
1015 ,מבשרת ציון ,163 ,השלום
1015 ,מבשרת ציון ,102 ,השקד
1015 ,מבשרת ציון ,201 ,השקמה
1015 ,מבשרת ציון ,116 ,התאנה
1015 ,מבשרת ציון ,168 ,ורד
1015 ,מבשרת ציון ,403 ,זהבית
1015 ,מבשרת ציון ,204 ,זית רענן
1015 ,מבשרת ציון ,169 ,חבצלת
1015 ,מבשרת ציון ,147 ,חלמית
1015 ,מבשרת ציון ,133 ,חצב
1015 ,מבשרת ציון ,402 ,חרובית
1015 ,מבשרת ציון ,275 ,חרמון
1015 ,מבשרת ציון ,334 ,טופז
1015 ,מבשרת ציון ,175 ,יהודה הלוי
1015 ,מבשרת ציון ,142 ,יסמין
1015 ,מבשרת ציון ,333 ,ישפה
1015 ,מבשרת ציון ,6007 ,כיכר החטיבות
1015 ,מבשרת ציון ,104 ,כיכר הנופים
1015 ,מבשרת ציון ,6004 ,ככר אבני חן
1015 ,מבשרת ציון ,6002 ,ככר הבדולח
1015 ,מבשרת ציון ,6005 ,ככר הפסגות
1015 ,מבשרת ציון ,6001 ,ככר חלילים
1015 ,מבשרת ציון ,6003 ,ככר קדמוניות
1015 ,מבשרת ציון ,272 ,כרמל
1015 ,מבשרת ציון ,310 ,לבונה
1015 ,מבשרת ציון ,141 ,לשם
1015 ,מבשרת ציון ,159 ,מבוא א
1015 ,מבשרת ציון ,138 ,מבוא בושמת
1015 ,מבשרת ציון ,255 ,מבוא הדובדבן
1015 ,מבשרת ציון ,208 ,מבוא הדקל
1015 ,מבשרת ציון ,209 ,מבוא הורד
1015 ,מבשרת ציון ,312 ,מבוא הקינמון
1015 ,מבשרת ציון ,256 ,מבוא הראל
1015 ,מבשרת ציון ,192 ,מבוא השזיף
1015 ,מבשרת ציון ,276 ,מבוא חוגלה
1015 ,מבשרת ציון ,251 ,מבוא טללים
1015 ,מבשרת ציון ,202 ,מבוא יהלום
1015 ,מבשרת ציון ,254 ,מבוא יערה
1015 ,מבשרת ציון ,186 ,מבוא יערים
1015 ,מבשרת ציון ,203 ,מבוא לבנה
1015 ,מבשרת ציון ,183 ,מבוא נעמה
1015 ,מבשרת ציון ,187 ,מבוא סיירים
1015 ,מבשרת ציון ,303 ,מבוא ספיר
1015 ,מבשרת ציון ,188 ,מבוא סתוונית
1015 ,מבשרת ציון ,181 ,מבוא ערמון
1015 ,מבשרת ציון ,311 ,מבוא קטורה
1015 ,מבשרת ציון ,207 ,מבוא רימון
1015 ,מבשרת ציון ,136 ,מבוא תדהר
1015 ,מבשרת ציון ,125 ,מבצע נחשון
1015 ,מבשרת ציון ,304 ,מבצע קדש
1015 ,מבשרת ציון ,6512 ,מבשרת ירושלים
1015 ,מבשרת ציון ,9000 ,מבשרת ציון
1015 ,מבשרת ציון ,6500 ,מבשרת ציון א
1015 ,מבשרת ציון ,6501 ,מבשרת ציון ב
1015 ,מבשרת ציון ,101 ,מוצא הקטנה
1015 ,מבשרת ציון ,307 ,מור
1015 ,מבשרת ציון ,6520 ,מחצבת אבן סיד
1015 ,מבשרת ציון ,270 ,מירון
1015 ,מבשרת ציון ,6502 ,מע קסטל
1015 ,מבשרת ציון ,121 ,מעלה הקסטל
1015 ,מבשרת ציון ,112 ,מצפה הבירה
1015 ,מבשרת ציון ,335 ,מרגלית
1015 ,מבשרת ציון ,106 ,מרכז קליטה
1015 ,מבשרת ציון ,158 ,נהר פרת
1015 ,מבשרת ציון ,178 ,נוף הרים
1015 ,מבשרת ציון ,329 ,נפך
1015 ,מבשרת ציון ,146 ,נצח ישראל
1015 ,מבשרת ציון ,305 ,נתיב הפורצים
1015 ,מבשרת ציון ,206 ,סמ עינב
1015 ,מבשרת ציון ,313 ,סמדר
1015 ,מבשרת ציון ,139 ,סעדיה גאון
1015 ,מבשרת ציון ,130 ,עין חמד
1015 ,מבשרת ציון ,321 ,ענבר
1015 ,מבשרת ציון ,269 ,עצמון
1015 ,מבשרת ציון ,128 ,ערבה
1015 ,מבשרת ציון ,327 ,פטדה
1015 ,מבשרת ציון ,308 ,פרג
1015 ,מבשרת ציון ,137 ,פרי מגדים
1015 ,מבשרת ציון ,162 ,צבר
1015 ,מבשרת ציון ,193 ,צור
1015 ,מבשרת ציון ,123 ,קבוץ גלויות
1015 ,מבשרת ציון ,6521 ,קניון הראל
1015 ,מבשרת ציון ,6503 ,קרית חנוך
1015 ,מבשרת ציון ,252 ,קרן היסוד
1015 ,מבשרת ציון ,301 ,קרן קיימת
1015 ,מבשרת ציון ,194 ,רותם
1015 ,מבשרת ציון ,309 ,רחבת העצמאות
1015 ,מבשרת ציון ,167 ,רקפת
1015 ,מבשרת ציון ,6504 ,ש אלרם
1015 ,מבשרת ציון ,6505 ,ש זוגות צעירים
1015 ,מבשרת ציון ,6506 ,ש רסקו
1015 ,מבשרת ציון ,330 ,שבו
1015 ,מבשרת ציון ,120 ,שבטי ישראל
1015 ,מבשרת ציון ,155 ,שבי ציון
1015 ,מבשרת ציון ,171 ,שביל א
1015 ,מבשרת ציון ,172 ,שביל ב
1015 ,מבשרת ציון ,173 ,שביל ג
1015 ,מבשרת ציון ,174 ,שביל ד
1015 ,מבשרת ציון ,110 ,שבע אחיות
1015 ,מבשרת ציון ,126 ,שד החוצבים
1015 ,מבשרת ציון ,323 ,שד החושן
1015 ,מבשרת ציון ,176 ,שדה חמד
1015 ,מבשרת ציון ,156 ,שוהם
1015 ,מבשרת ציון ,131 ,שושנה
1015 ,מבשרת ציון ,195 ,שחם
1015 ,מבשרת ציון ,6507 ,שכ ג
1015 ,מבשרת ציון ,6513 ,שכ ה
1015 ,מבשרת ציון ,6514 ,שכ ו
1015 ,מבשרת ציון ,6515 ,שכ ז
1015 ,מבשרת ציון ,6516 ,שכ ט
1015 ,מבשרת ציון ,6511 ,שכ יא
1015 ,מבשרת ציון ,6522 ,שכ יג
1015 ,מבשרת ציון ,6509 ,שכ מוצא הקטנה
1015 ,מבשרת ציון ,6517 ,שכ נוף הרים
1015 ,מבשרת ציון ,6524 ,שכ נופי הסלע
1015 ,מבשרת ציון ,6523 ,שכ' נופי החורש
1015 ,מבשרת ציון ,6508 ,שכונה ד
1015 ,מבשרת ציון ,6510 ,שכונה ח
1015 ,מבשרת ציון ,134 ,שמעון
1015 ,מבשרת ציון ,205 ,שעורה
1015 ,מבשרת ציון ,182 ,ששת הימים
1015 ,מבשרת ציון ,273 ,תבור
1015 ,מבשרת ציון ,404 ,תירוש
1015 ,מבשרת ציון ,405 ,תמרים
1015 ,מבשרת ציון ,332 ,תרשיש
516 ,מג'ד אל-כרום ,200 ,אבו אלנואס
516 ,מג'ד אל-כרום ,201 ,אבו אלעתאהיה
516 ,מג'ד אל-כרום ,202 ,אבו בכר אלסדיק
516 ,מג'ד אל-כרום ,203 ,אבו ג'עפר אלמנסור
516 ,מג'ד אל-כרום ,204 ,אבו סלמא
516 ,מג'ד אל-כרום ,205 ,אבו סראג'
516 ,מג'ד אל-כרום ,206 ,אבו ערב
516 ,מג'ד אל-כרום ,500 ,אבו קאסם אלשאבי
516 ,מג'ד אל-כרום ,207 ,אבו תמאם
516 ,מג'ד אל-כרום ,208 ,אבן אלאת'יר
516 ,מג'ד אל-כרום ,209 ,אבן אלהייתם
516 ,מג'ד אל-כרום ,210 ,אבן אלורדי
516 ,מג'ד אל-כרום ,211 ,אבן אלנפיס
516 ,מג'ד אל-כרום ,212 ,אבן אלפארד
516 ,מג'ד אל-כרום ,213 ,אבן אלרושד
516 ,מג'ד אל-כרום ,214 ,אבן אסחאק
516 ,מג'ד אל-כרום ,215 ,אבן ח'לדון
516 ,מג'ד אל-כרום ,216 ,אבן חיאן
516 ,מג'ד אל-כרום ,217 ,אבן סירין
516 ,מג'ד אל-כרום ,218 ,אבן תימיה
516 ,מג'ד אל-כרום ,219 ,אבראהים טוקאן
516 ,מג'ד אל-כרום ,220 ,אדוארד סעיד
516 ,מג'ד אל-כרום ,221 ,אואליס
516 ,מג'ד אל-כרום ,222 ,אום כולתום
516 ,מג'ד אל-כרום ,223 ,אחמד בן בלא
516 ,מג'ד אל-כרום ,224 ,אחמד בשר
516 ,מג'ד אל-כרום ,225 ,אכרם זעיתר
516 ,מג'ד אל-כרום ,226 ,אלאורג'ואן
516 ,מג'ד אל-כרום ,228 ,אלאמל
516 ,מג'ד אל-כרום ,229 ,אלאנביא
516 ,מג'ד אל-כרום ,230 ,אלאנדלוס
516 ,מג'ד אל-כרום ,231 ,אלאנסאר
516 ,מג'ד אל-כרום ,232 ,אלאסוד
516 ,מג'ד אל-כרום ,233 ,אלאסלאח
516 ,מג'ד אל-כרום ,234 ,אלאקחואן
516 ,מג'ד אל-כרום ,235 ,אלארוז
516 ,מג'ד אל-כרום ,236 ,אלבאמיה
516 ,מג'ד אל-כרום ,237 ,אלבדר
516 ,מג'ד אל-כרום ,238 ,אלבהג'א
516 ,מג'ד אל-כרום ,239 ,אלבוח'ארי
516 ,מג'ד אל-כרום ,240 ,אלבוסתאן
516 ,מג'ד אל-כרום ,241 ,אלבוראק
516 ,מג'ד אל-כרום ,242 ,אלבורג'
516 ,מג'ד אל-כרום ,243 ,אלבחתרי
516 ,מג'ד אל-כרום ,244 ,אלבטם
516 ,מג'ד אל-כרום ,245 ,אלביירוני
516 ,מג'ד אל-כרום ,246 ,אלביר
516 ,מג'ד אל-כרום ,247 ,אלבירה
516 ,מג'ד אל-כרום ,248 ,אלבלוט
516 ,מג'ד אל-כרום ,249 ,אלבריקאת
516 ,מג'ד אל-כרום ,250 ,אלברקוק
516 ,מג'ד אל-כרום ,251 ,אלג'בל
516 ,מג'ד אל-כרום ,252 ,אלג'וב
516 ,מג'ד אל-כרום ,253 ,אלג'ולאן
516 ,מג'ד אל-כרום ,254 ,אלג'ורי
516 ,מג'ד אל-כרום ,255 ,אלג'זאלי
516 ,מג'ד אל-כרום ,256 ,אלג'יית
516 ,מג'ד אל-כרום ,257 ,אלג'לאלי
516 ,מג'ד אל-כרום ,258 ,אלג'רמק
516 ,מג'ד אל-כרום ,259 ,אלגאמע
516 ,מג'ד אל-כרום ,260 ,אלגאר
516 ,מג'ד אל-כרום ,261 ,אלדחאלה
516 ,מג'ד אל-כרום ,262 ,אלדחנון
516 ,מג'ד אל-כרום ,263 ,אלהודא
516 ,מג'ד אל-כרום ,264 ,אלהודהוד
516 ,מג'ד אל-כרום ,265 ,אלהלאל
516 ,מג'ד אל-כרום ,266 ,אלואחה
516 ,מג'ד אל-כרום ,267 ,אלודיאן
516 ,מג'ד אל-כרום ,268 ,אלוזאל
516 ,מג'ד אל-כרום ,269 ,אלוזיה
516 ,מג'ד אל-כרום ,270 ,אלורדה
516 ,מג'ד אל-כרום ,271 ,אלורוד
516 ,מג'ד אל-כרום ,272 ,אלזהראא
516 ,מג'ד אל-כרום ,273 ,אלזוהור
516 ,מג'ד אל-כרום ,274 ,אלזוקם
516 ,מג'ד אל-כרום ,275 ,אלזייתון
516 ,מג'ד אל-כרום ,276 ,אלזית
516 ,מג'ד אל-כרום ,277 ,אלזעאריר
516 ,מג'ד אל-כרום ,278 ,אלזעפראן
516 ,מג'ד אל-כרום ,279 ,אלזערור
516 ,מג'ד אל-כרום ,280 ,אלזעתר
516 ,מג'ד אל-כרום ,498 ,אלזקאק
516 ,מג'ד אל-כרום ,281 ,אלזריעה
516 ,מג'ד אל-כרום ,282 ,אלח'וארזמי
516 ,מג'ד אל-כרום ,283 ,אלח'וביזה
516 ,מג'ד אל-כרום ,284 ,אלח'זאן
516 ,מג'ד אל-כרום ,285 ,אלח'ליפה מועאויה
516 ,מג'ד אל-כרום ,286 ,אלח'נסאא
516 ,מג'ד אל-כרום ,287 ,אלחג'ל
516 ,מג'ד אל-כרום ,288 ,אלחוארה
516 ,מג'ד אל-כרום ,289 ,אלחלקאת
516 ,מג'ד אל-כרום ,290 ,אלחמראא
516 ,מג'ד אל-כרום ,291 ,אלטאבון
516 ,מג'ד אל-כרום ,292 ,אלטברי
516 ,מג'ד אל-כרום ,293 ,אלטויל
516 ,מג'ד אל-כרום ,294 ,אלטיון
516 ,מג'ד אל-כרום ,295 ,אלימאמה
516 ,מג'ד אל-כרום ,296 ,אלירמוק
516 ,מג'ד אל-כרום ,370 ,אלכראמה
516 ,מג'ד אל-כרום ,297 ,אלכרמל
516 ,מג'ד אל-כרום ,298 ,אלליאת
516 ,מג'ד אל-כרום ,299 ,אלליימון
516 ,מג'ד אל-כרום ,227 ,אלמאמון
516 ,מג'ד אל-כרום ,300 ,אלמאס
516 ,מג'ד אל-כרום ,301 ,אלמג'ד
516 ,מג'ד אל-כרום ,302 ,אלמדינה
516 ,מג'ד אל-כרום ,303 ,אלמוג'ר
516 ,מג'ד אל-כרום ,304 ,אלמוודה
516 ,מג'ד אל-כרום ,305 ,אלמוסתקבל
516 ,מג'ד אל-כרום ,306 ,אלמועתסם
516 ,מג'ד אל-כרום ,307 ,אלמותנבי
516 ,מג'ד אל-כרום ,308 ,אלמל
516 ,מג'ד אל-כרום ,309 ,אלמלאיאת
516 ,מג'ד אל-כרום ,310 ,אלמנאר
516 ,מג'ד אל-כרום ,311 ,אלמנג'ל
516 ,מג'ד אל-כרום ,312 ,אלמנסורה
516 ,מג'ד אל-כרום ,313 ,אלמנפלוטי
516 ,מג'ד אל-כרום ,314 ,אלמסק
516 ,מג'ד אל-כרום ,315 ,אלמעסרה
516 ,מג'ד אל-כרום ,316 ,אלמערפה
516 ,מג'ד אל-כרום ,317 ,אלמקדסי
516 ,מג'ד אל-כרום ,318 ,אלמראח
516 ,מג'ד אל-כרום ,319 ,אלמרג'
516 ,מג'ד אל-כרום ,320 ,אלמרוש
516 ,מג'ד אל-כרום ,321 ,אלמשמש
516 ,מג'ד אל-כרום ,322 ,אלמת'ור
516 ,מג'ד אל-כרום ,325 ,אלנהר
516 ,מג'ד אל-כרום ,326 ,אלנוזהא
516 ,מג'ד אל-כרום ,327 ,אלנויר
516 ,מג'ד אל-כרום ,328 ,אלנח'יל
516 ,מג'ד אל-כרום ,329 ,אלנירב
516 ,מג'ד אל-כרום ,330 ,אלנעמה
516 ,מג'ד אל-כרום ,331 ,אלנענע
516 ,מג'ד אל-כרום ,332 ,אלסבאט
516 ,מג'ד אל-כרום ,333 ,אלסבר
516 ,מג'ד אל-כרום ,334 ,אלסהל
516 ,מג'ד אל-כרום ,335 ,אלסולטאנה
516 ,מג'ד אל-כרום ,336 ,אלסומאק
516 ,מג'ד אל-כרום ,337 ,אלסופסאף
516 ,מג'ד אל-כרום ,338 ,אלסלאח'
516 ,מג'ד אל-כרום ,339 ,אלסלאם
516 ,מג'ד אל-כרום ,340 ,אלסלוא
516 ,מג'ד אל-כרום ,341 ,אלסנדבאד
516 ,מג'ד אל-כרום ,342 ,אלסנדיאן
516 ,מג'ד אל-כרום ,343 ,אלסנובר
516 ,מג'ד אל-כרום ,344 ,אלסעבניה
516 ,מג'ד אל-כרום ,345 ,אלסראג'
516 ,מג'ד אל-כרום ,346 ,אלסרג'
516 ,מג'ד אל-כרום ,347 ,אלסריס
516 ,מג'ד אל-כרום ,348 ,אלעבאסיין
516 ,מג'ד אל-כרום ,349 ,אלעדנדליב
516 ,מג'ד אל-כרום ,350 ,אלעוודה
516 ,מג'ד אל-כרום ,351 ,אלעולום
516 ,מג'ד אל-כרום ,352 ,אלעזה
516 ,מג'ד אל-כרום ,353 ,אלעזיב
516 ,מג'ד אל-כרום ,354 ,אלעטאא
516 ,מג'ד אל-כרום ,355 ,אלעטאר
516 ,מג'ד אל-כרום ,356 ,אלעין
516 ,מג'ד אל-כרום ,6773 ,אלעין-מג'ד אלכרום
516 ,מג'ד אל-כרום ,357 ,אלענב
516 ,מג'ד אל-כרום ,358 ,אלעספורה
516 ,מג'ד אל-כרום ,359 ,אלעקאד
516 ,מג'ד אל-כרום ,360 ,אלעראקיב
516 ,מג'ד אל-כרום ,361 ,אלפאראבי
516 ,מג'ד אל-כרום ,362 ,אלפרזדק
516 ,מג'ד אל-כרום ,363 ,אלקאדסייה
516 ,מג'ד אל-כרום ,364 ,אלקאהרא
516 ,מג'ד אל-כרום ,365 ,אלקודס
516 ,מג'ד אל-כרום ,366 ,אלקמח
516 ,מג'ד אל-כרום ,367 ,אלקנדול
516 ,מג'ד אל-כרום ,368 ,אלקניטרה
516 ,מג'ד אל-כרום ,369 ,אלקסאר
516 ,מג'ד אל-כרום ,371 ,אלקראר
516 ,מג'ד אל-כרום ,372 ,אלקרואן
516 ,מג'ד אל-כרום ,373 ,אלקרז
516 ,מג'ד אל-כרום ,374 ,אלקרינה
516 ,מג'ד אל-כרום ,375 ,אלראזי
516 ,מג'ד אל-כרום ,376 ,אלרבאט
516 ,מג'ד אל-כרום ,377 ,אלרבאעין
516 ,מג'ד אל-כרום ,378 ,אלרואייס
516 ,מג'ד אל-כרום ,379 ,אלרודה
516 ,מג'ד אל-כרום ,380 ,אלרויס
516 ,מג'ד אל-כרום ,381 ,אלרויסאת
516 ,מג'ד אל-כרום ,382 ,אלריאן
516 ,מג'ד אל-כרום ,383 ,אלרייחאן
516 ,מג'ד אל-כרום ,384 ,אלריף
516 ,מג'ד אל-כרום ,385 ,אלרשאדה
516 ,מג'ד אל-כרום ,386 ,אלשאם
516 ,מג'ד אל-כרום ,387 ,אלשועראא
516 ,מג'ד אל-כרום ,492 ,אלשטינה
516 ,מג'ד אל-כרום ,388 ,אלשייח
516 ,מג'ד אל-כרום ,389 ,אלשיימאא
516 ,מג'ד אל-כרום ,390 ,אלשעראוי
516 ,מג'ד אל-כרום ,392 ,אלתין
516 ,מג'ד אל-כרום ,393 ,אלתסאמוח
516 ,מג'ד אל-כרום ,394 ,אם אלזינאת
516 ,מג'ד אל-כרום ,395 ,אם אלסעיד
516 ,מג'ד אל-כרום ,396 ,אמיל תומא
516 ,מג'ד אל-כרום ,397 ,אמרוא אלקייס
516 ,מג'ד אל-כרום ,398 ,באב אלואדי
516 ,מג'ד אל-כרום ,399 ,באב אלזרב
516 ,מג'ד אל-כרום ,400 ,בגדאד
516 ,מג'ד אל-כרום ,402 ,ביירות
516 ,מג'ד אל-כרום ,403 ,ביסאן
516 ,מג'ד אל-כרום ,404 ,בלאל בן רבאח
516 ,מג'ד אל-כרום ,405 ,בלומיה
516 ,מג'ד אל-כרום ,406 ,ג'בל אלטור
516 ,מג'ד אל-כרום ,407 ,ג'בל אלכרמל
516 ,מג'ד אל-כרום ,408 ,ג'בל חיידר
516 ,מג'ד אל-כרום ,409 ,ג'בל כנעאן
516 ,מג'ד אל-כרום ,410 ,ג'בל עדאת'ר
516 ,מג'ד אל-כרום ,411 ,ג'ובראן
516 ,מג'ד אל-כרום ,412 ,ג'לון
516 ,מג'ד אל-כרום ,413 ,גאנדי
516 ,מג'ד אל-כרום ,414 ,גרנאטה
516 ,מג'ד אל-כרום ,415 ,ד'אהר אל עומר
516 ,מג'ד אל-כרום ,416 ,דמשק
516 ,מג'ד אל-כרום ,417 ,דרב אלטאחונה
516 ,מג'ד אל-כרום ,6780 ,דרומית-מג'ד אלכרום
516 ,מג'ד אל-כרום ,418 ,האג'ר
516 ,מג'ד אל-כרום ,419 ,הארון אלרשיד
516 ,מג'ד אל-כרום ,420 ,השאם בן עבד אלמלק
516 ,מג'ד אל-כרום ,421 ,זוביידה בן ג'עפר
516 ,מג'ד אל-כרום ,422 ,זיתייה
516 ,מג'ד אל-כרום ,423 ,זמזם
516 ,מג'ד אל-כרום ,424 ,זעפראן
516 ,מג'ד אל-כרום ,425 ,ח'אלד בן אלוליד
516 ,מג'ד אל-כרום ,426 ,ח'ליל מוטראן
516 ,מג'ד אל-כרום ,427 ,ח'לת אבו פארס
516 ,מג'ד אל-כרום ,428 ,ח'לת אבו רומאן
516 ,מג'ד אל-כרום ,429 ,ח'לת אלאערג'
516 ,מג'ד אל-כרום ,430 ,ח'לת גזאל
516 ,מג'ד אל-כרום ,431 ,ח'רבת אלח'ורי
516 ,מג'ד אל-כרום ,432 ,חאפז אבראהים
516 ,מג'ד אל-כרום ,433 ,חיפא
516 ,מג'ד אל-כרום ,434 ,חלב
516 ,מג'ד אל-כרום ,435 ,חנא מינא
516 ,מג'ד אל-כרום ,436 ,טהא חוסיין
516 ,מג'ד אל-כרום ,437 ,טליטה
516 ,מג'ד אל-כרום ,438 ,יאפא
516 ,מג'ד אל-כרום ,439 ,יוסף אדריס
516 ,מג'ד אל-כרום ,440 ,כביש אלמסג'ד אלקדים
516 ,מג'ד אל-כרום ,6770 ,כפר הישן-מגד אלכרום
516 ,מג'ד אל-כרום ,441 ,כרם אלסנדיאנה
516 ,מג'ד אל-כרום ,442 ,כרם מונד'ר
516 ,מג'ד אל-כרום ,443 ,לוקמאן
516 ,מג'ד אל-כרום ,444 ,מאי זייאדה
516 ,מג'ד אל-כרום ,445 ,מג'ארת עיסא
516 ,מג'ד אל-כרום ,9000 ,מג'ד אל-כרום
516 ,מג'ד אל-כרום ,446 ,מדראס
516 ,מג'ד אל-כרום ,447 ,מוארס אלעין
516 ,מג'ד אל-כרום ,448 ,מוג'ר אלחמאם
516 ,מג'ד אל-כרום ,449 ,מוחמד אלפאתח
516 ,מג'ד אל-כרום ,450 ,מוחמד עבדו
516 ,מג'ד אל-כרום ,451 ,מוסא בן נסיר
516 ,מג'ד אל-כרום ,6763 ,מזרחית חדשה
516 ,מג'ד אל-כרום ,6782 ,מזרחית-מג'ד אלכרום
516 ,מג'ד אל-כרום ,452 ,מכא
516 ,מג'ד אל-כרום ,6772 ,מערבית-מג'ד אלכרום
516 ,מג'ד אל-כרום ,453 ,מרואן בן אלחכם
516 ,מג'ד אל-כרום ,6762 ,מתחם בי"ס תיכון
516 ,מג'ד אל-כרום ,454 ,נוח אברהים
516 ,מג'ד אל-כרום ,455 ,נור אלדין זנקי
516 ,מג'ד אל-כרום ,456 ,נלסון מנדילה
516 ,מג'ד אל-כרום ,457 ,נמלייה
516 ,מג'ד אל-כרום ,458 ,סחמאתא
516 ,מג'ד אל-כרום ,459 ,סייד דרויש
516 ,מג'ד אל-כרום ,460 ,סייף אלדין קטז
516 ,מג'ד אל-כרום ,461 ,סינא
516 ,מג'ד אל-כרום ,462 ,סלאח אלדין
516 ,מג'ד אל-כרום ,463 ,סלסביל
516 ,מג'ד אל-כרום ,464 ,סעד בן אבי וקאס
516 ,מג'ד אל-כרום ,465 ,סעיד בן ג'ביר
516 ,מג'ד אל-כרום ,466 ,עאישה
516 ,מג'ד אל-כרום ,479 ,עאמר בן יאסר
516 ,מג'ד אל-כרום ,467 ,עבאדה בן אלסאמת
516 ,מג'ד אל-כרום ,468 ,עבד אלרחמן אלדאח'ל
516 ,מג'ד אל-כרום ,469 ,עומר אלח'יאם
516 ,מג'ד אל-כרום ,470 ,עומר בן אלח'טאב
516 ,מג'ד אל-כרום ,471 ,עומר בן עבד אלעזיז
516 ,מג'ד אל-כרום ,472 ,עוקבא בן נאפע
516 ,מג'ד אל-כרום ,473 ,עוש אלבומה
516 ,מג'ד אל-כרום ,474 ,עות'מאן בן עפאן
516 ,מג'ד אל-כרום ,475 ,עין גאלות
516 ,מג'ד אל-כרום ,476 ,עכא
516 ,מג'ד אל-כרום ,477 ,עלי בן אבי טאלב
516 ,מג'ד אל-כרום ,478 ,עליליאת
516 ,מג'ד אל-כרום ,480 ,עסא אלראעי
516 ,מג'ד אל-כרום ,481 ,עסקלאן
516 ,מג'ד אל-כרום ,482 ,פיירוז
516 ,מג'ד אל-כרום ,483 ,צור
516 ,מג'ד אל-כרום ,484 ,ציידא
516 ,מג'ד אל-כרום ,6771 ,צפונית חדשה
516 ,מג'ד אל-כרום ,6761 ,צפונית-מג'ד אלכרום
516 ,מג'ד אל-כרום ,485 ,קובת אלסח'רא
516 ,מג'ד אל-כרום ,486 ,קורטבה
516 ,מג'ד אל-כרום ,487 ,קותיבא בן מוסלם
516 ,מג'ד אל-כרום ,488 ,ראס ערוס
516 ,מג'ד אל-כרום ,489 ,ראשד חוסיין
516 ,מג'ד אל-כרום ,490 ,רפידה בנת קעב
516 ,מג'ד אל-כרום ,6760 ,ש מזרחי
516 ,מג'ד אל-כרום ,491 ,שדאד בן אוס
516 ,מג'ד אל-כרום ,493 ,שקאת אלרסייפי
516 ,מג'ד אל-כרום ,494 ,תונס
516 ,מג'ד אל-כרום ,495 ,תמים אלדארי
516 ,מג'ד אל-כרום ,496 ,תסנים
4201 ,מג'דל שמס ,9000 ,מג'דל שמס
481 ,מגאר ,6715 ,אזור תעשייה
481 ,מגאר ,6713 ,אלבס
481 ,מגאר ,6722 ,אלבסבאס
481 ,מגאר ,6751 ,אלמזירה
481 ,מגאר ,6730 ,בואב אלהוא
481 ,מגאר ,6742 ,בלטאת עד'ימה
481 ,מגאר ,6750 ,בקיר
481 ,מגאר ,6720 ,ג'ורת אבו כמרה
481 ,מגאר ,6711 ,דבת אלזעתר
481 ,מגאר ,6723 ,השכונה הותיקה
481 ,מגאר ,6733 ,זתון אלתין
481 ,מגאר ,6740 ,ח'לת אלשריף
481 ,מגאר ,6752 ,חיילים בדווים
481 ,מגאר ,6714 ,כביש אבו אלנמל
481 ,מגאר ,9000 ,מגאר
481 ,מגאר ,6721 ,מסגד צפוני
481 ,מגאר ,6712 ,עין מנסורה ותיקה
481 ,מגאר ,6717 ,ראס אלח'אביה דרום
481 ,מגאר ,6716 ,ראס אלחביה
481 ,מגאר ,6700 ,שכ אלתריק
481 ,מגאר ,6501 ,שכ ג'משה
481 ,מגאר ,6500 ,שכ מערבית
481 ,מגאר ,6710 ,שכונה מזרחית
481 ,מגאר ,6741 ,שכונה נוצרית
689 ,מגדים ,105 ,דרך אנשי הדממה
689 ,מגדים ,107 ,דרך נתיב הים
689 ,מגדים ,106 ,דרך שועלי החוף
689 ,מגדים ,112 ,האלמוג
689 ,מגדים ,103 ,הגלים
689 ,מגדים ,104 ,החופים
689 ,מגדים ,109 ,המעגן
689 ,מגדים ,108 ,המפרץ
689 ,מגדים ,101 ,העוגן
689 ,מגדים ,111 ,השונית
689 ,מגדים ,113 ,השחף
689 ,מגדים ,102 ,התורן
689 ,מגדים ,110 ,כוכב הים
689 ,מגדים ,9000 ,מגדים
65 ,מגדל ,103 ,האירוסים
65 ,מגדל ,104 ,האלה
65 ,מגדל ,105 ,האלון
65 ,מגדל ,106 ,האשכולית
65 ,מגדל ,107 ,הברוש
65 ,מגדל ,108 ,הגפן
65 ,מגדל ,109 ,הדר
65 ,מגדל ,110 ,ההרדופים
65 ,מגדל ,111 ,הזית
65 ,מגדל ,112 ,החצב
65 ,מגדל ,113 ,החרוב
65 ,מגדל ,114 ,החרצית
65 ,מגדל ,115 ,היערה
65 ,מגדל ,116 ,היקינטון
65 ,מגדל ,117 ,הכלניות
65 ,מגדל ,118 ,הכרכום
65 ,מגדל ,119 ,המייסדים
65 ,מגדל ,120 ,המנגו
65 ,מגדל ,121 ,המעיין
65 ,מגדל ,122 ,הנורית
65 ,מגדל ,123 ,הסביון
65 ,מגדל ,124 ,הסחלב
65 ,מגדל ,125 ,הערבה
65 ,מגדל ,126 ,הצאלון
65 ,מגדל ,127 ,הצבעוני
65 ,מגדל ,128 ,הראשונים
65 ,מגדל ,140 ,הרותם
65 ,מגדל ,139 ,הרימון
65 ,מגדל ,129 ,הרקפת
65 ,מגדל ,130 ,השיזף
65 ,מגדל ,131 ,השיטה
65 ,מגדל ,132 ,התאנה
65 ,מגדל ,133 ,התמר
65 ,מגדל ,7001 ,וילה מלצט
65 ,מגדל ,134 ,חרמון
65 ,מגדל ,7002 ,טבחה
65 ,מגדל ,102 ,כפר נופש חוף הואי
65 ,מגדל ,7003 ,כרי דשא
65 ,מגדל ,9000 ,מגדל
65 ,מגדל ,101 ,מעון נופים
65 ,מגדל ,135 ,נוף הארבל
65 ,מגדל ,138 ,נוף הגליל
65 ,מגדל ,136 ,נוף כנרת
65 ,מגדל ,137 ,עירית
874 ,מגדל העמק ,330 ,אביטל
874 ,מגדל העמק ,6512 ,אזור התעשיה צפון
874 ,מגדל העמק ,6511 ,אזור תעשיה דרום
874 ,מגדל העמק ,293 ,אחד העם
874 ,מגדל העמק ,236 ,איילון
874 ,מגדל העמק ,141 ,אלי כהן
874 ,מגדל העמק ,306 ,אמנון ליפקין-שחק
874 ,מגדל העמק ,234 ,ארבל
874 ,מגדל העמק ,144 ,בגד עור
874 ,מגדל העמק ,297 ,בית"ר
874 ,מגדל העמק ,331 ,בנטל
874 ,מגדל העמק ,121 ,גבעתי
874 ,מגדל העמק ,232 ,גולן
874 ,מגדל העמק ,233 ,גליל
874 ,מגדל העמק ,114 ,גלעד
874 ,מגדל העמק ,321 ,דוד אלעזר
874 ,מגדל העמק ,324 ,דן שומרון
874 ,מגדל העמק ,124 ,דרך העצמאות
874 ,מגדל העמק ,111 ,דרך חטיבת גולני
874 ,מגדל העמק ,133 ,האביב
874 ,מגדל העמק ,266 ,האומן
874 ,מגדל העמק ,105 ,האורנים
874 ,מגדל העמק ,275 ,האילן
874 ,מגדל העמק ,215 ,האיצטדיון
874 ,מגדל העמק ,174 ,האיריס
874 ,מגדל העמק ,123 ,האלה
874 ,מגדל העמק ,242 ,האלומות
874 ,מגדל העמק ,106 ,האלונים
874 ,מגדל העמק ,103 ,הארזים
874 ,מגדל העמק ,209 ,האריג
874 ,מגדל העמק ,216 ,האריזה
874 ,מגדל העמק ,264 ,הבנאי
874 ,מגדל העמק ,108 ,הברושים
874 ,מגדל העמק ,115 ,הבשן
874 ,מגדל העמק ,272 ,הגביש
874 ,מגדל העמק ,201 ,הגיא
874 ,מגדל העמק ,183 ,הדברת
874 ,מגדל העמק ,211 ,הדובדבן
874 ,מגדל העמק ,181 ,הדפנה
874 ,מגדל העמק ,278 ,ההגנה
874 ,מגדל העמק ,189 ,ההרדוף
874 ,מגדל העמק ,140 ,הורד
874 ,מגדל העמק ,142 ,הזית
874 ,מגדל העמק ,175 ,החבצלת
874 ,מגדל העמק ,139 ,החורש
874 ,מגדל העמק ,281 ,החורשה
874 ,מגדל העמק ,158 ,החימר
874 ,מגדל העמק ,250 ,החצב
874 ,מגדל העמק ,212 ,החרוב
874 ,מגדל העמק ,217 ,החרושת
874 ,מגדל העמק ,188 ,החרצית
874 ,מגדל העמק ,192 ,החשמל
874 ,מגדל העמק ,171 ,היסמין
874 ,מגדל העמק ,177 ,היערה
874 ,מגדל העמק ,265 ,היצירה
874 ,מגדל העמק ,178 ,היקינטון
874 ,מגדל העמק ,182 ,הכלנית
874 ,מגדל העמק ,110 ,הכרמל
874 ,מגדל העמק ,193 ,הלווין
874 ,מגדל העמק ,271 ,המגל
874 ,מגדל העמק ,184 ,המגנית
874 ,מגדל העמק ,194 ,המהנדס
874 ,מגדל העמק ,198 ,המחקר
874 ,מגדל העמק ,282 ,המטע
874 ,מגדל העמק ,143 ,המלאכה
874 ,מגדל העמק ,269 ,המסגר
874 ,מגדל העמק ,168 ,המצפה
874 ,מגדל העמק ,199 ,המתכת
874 ,מגדל העמק ,208 ,הנגב
874 ,מגדל העמק ,268 ,הנגר
874 ,מגדל העמק ,170 ,הנדיב
874 ,מגדל העמק ,180 ,הנופר
874 ,מגדל העמק ,169 ,הנורית
874 ,מגדל העמק ,157 ,הניצנים
874 ,מגדל העמק ,263 ,הנפח
874 ,מגדל העמק ,118 ,הנשיאים
874 ,מגדל העמק ,173 ,הסביון
874 ,מגדל העמק ,176 ,הסחלב
874 ,מגדל העמק ,164 ,הסיגלית
874 ,מגדל העמק ,251 ,הסתונית
874 ,מגדל העמק ,137 ,העליה
874 ,מגדל העמק ,122 ,העמל
874 ,מגדל העמק ,205 ,הערבה
874 ,מגדל העמק ,185 ,הפעמונית
874 ,מגדל העמק ,252 ,הפרג
874 ,מגדל העמק ,283 ,הפרדס
874 ,מגדל העמק ,224 ,הצאלון
874 ,מגדל העמק ,210 ,הצבר
874 ,מגדל העמק ,326 ,הצופים
874 ,מגדל העמק ,261 ,הצורן
874 ,מגדל העמק ,267 ,הצורף
874 ,מגדל העמק ,254 ,הצפורן
874 ,מגדל העמק ,179 ,הקיצנית
874 ,מגדל העמק ,126 ,הראשונים
874 ,מגדל העמק ,161 ,הרב בוסקילה רפאל
874 ,מגדל העמק ,153 ,הרב מלכה יוסף
874 ,מגדל העמק ,128 ,הרב עובדיה יוסף
874 ,מגדל העמק ,160 ,הרימון
874 ,מגדל העמק ,328 ,הרצל
874 ,מגדל העמק ,166 ,הרקפת
874 ,מגדל העמק ,186 ,השוהם
874 ,מגדל העמק ,145 ,השונמית
874 ,מגדל העמק ,187 ,השזרית
874 ,מגדל העמק ,107 ,השיטים
874 ,מגדל העמק ,203 ,השיקמה
874 ,מגדל העמק ,262 ,השיש
874 ,מגדל העמק ,130 ,השקד
874 ,מגדל העמק ,238 ,השרון
874 ,מגדל העמק ,213 ,התאנה
874 ,מגדל העמק ,202 ,התדהר
874 ,מגדל העמק ,136 ,התימורים
874 ,מגדל העמק ,159 ,התמר
874 ,מגדל העמק ,200 ,התעשיה
874 ,מגדל העמק ,206 ,התקשורת
874 ,מגדל העמק ,325 ,זאב ז'בוטינסקי
874 ,מגדל העמק ,131 ,זבולון
874 ,מגדל העמק ,229 ,חב"ד
874 ,מגדל העמק ,322 ,חיים בר-לב
874 ,מגדל העמק ,292 ,חיים הרצוג
874 ,מגדל העמק ,290 ,חיים ויצמן
874 ,מגדל העמק ,333 ,חיים לסקוב
874 ,מגדל העמק ,148 ,חיל האוויר
874 ,מגדל העמק ,230 ,חרמון
874 ,מגדל העמק ,304 ,יגאל ידין
874 ,מגדל העמק ,119 ,יודפת
874 ,מגדל העמק ,332 ,יוסיפון
874 ,מגדל העמק ,303 ,יעקב דורי
874 ,מגדל העמק ,243 ,יפה נוף
874 ,מגדל העמק ,291 ,יצחק בן צבי
874 ,מגדל העמק ,300 ,יצחק נבון
874 ,מגדל העמק ,235 ,ירדן
874 ,מגדל העמק ,237 ,ירקון
874 ,מגדל העמק ,204 ,כליל החורש
874 ,מגדל העמק ,334 ,לוחמי הגטאות
874 ,מגדל העמק ,147 ,לוי יוסף
874 ,מגדל העמק ,197 ,לוי מאוריציו
874 ,מגדל העמק ,253 ,לוע הארי
874 ,מגדל העמק ,296 ,לח"י
874 ,מגדל העמק ,9000 ,מגדל העמק
874 ,מגדל העמק ,335 ,מייסדים
874 ,מגדל העמק ,116 ,מירון
874 ,מגדל העמק ,336 ,מעפילים
874 ,מגדל העמק ,308 ,מרדכי גור
874 ,מגדל העמק ,323 ,מרדכי מקלף
874 ,מגדל העמק ,302 ,משה דיין
874 ,מגדל העמק ,299 ,משה לוי
874 ,מגדל העמק ,257 ,משעול האגמון
874 ,מגדל העמק ,249 ,משעול האילנות
874 ,מגדל העמק ,247 ,משעול האתרוג
874 ,מגדל העמק ,225 ,משעול הגדי
874 ,מגדל העמק ,255 ,משעול הדולב
874 ,מגדל העמק ,112 ,משעול הדקלים
874 ,מגדל העמק ,129 ,משעול הדרור
874 ,מגדל העמק ,245 ,משעול ההדס
874 ,מגדל העמק ,102 ,משעול ההדרים
874 ,מגדל העמק ,155 ,משעול הזמיר
874 ,מגדל העמק ,220 ,משעול היעלים
874 ,מגדל העמק ,162 ,משעול הכנרית
874 ,מגדל העמק ,227 ,משעול הכפיר
874 ,מגדל העמק ,248 ,משעול הכרמים
874 ,מגדל העמק ,256 ,משעול הלוטם
874 ,מגדל העמק ,246 ,משעול הלולב
874 ,מגדל העמק ,214 ,משעול הליבנה
874 ,מגדל העמק ,163 ,משעול הנחליאלי
874 ,מגדל העמק ,156 ,משעול העפרוני
874 ,מגדל העמק ,154 ,משעול הצבעוני
874 ,מגדל העמק ,228 ,משעול הצופית
874 ,מגדל העמק ,218 ,משעול הרותם
874 ,מגדל העמק ,226 ,משעול התור
874 ,מגדל העמק ,219 ,משעול התירוש
874 ,מגדל העמק ,172 ,נוף העמק
874 ,מגדל העמק ,207 ,נחל הצבי
874 ,מגדל העמק ,294 ,ניל"י
874 ,מגדל העמק ,221 ,נתיב הלילך
874 ,מגדל העמק ,135 ,סטרומה
874 ,מגדל העמק ,240 ,סיני
874 ,מגדל העמק ,152 ,סמ האיריסים
874 ,מגדל העמק ,151 ,סמ החבצלות
874 ,מגדל העמק ,113 ,סמ המרגניות
874 ,מגדל העמק ,150 ,סמ השושנים
874 ,מגדל העמק ,132 ,סמוכה יוסף
874 ,מגדל העמק ,301 ,עזר ויצמן
874 ,מגדל העמק ,117 ,עצמון
874 ,מגדל העמק ,190 ,פנינת העמק
874 ,מגדל העמק ,305 ,צבי צור
874 ,מגדל העמק ,191 ,ציפורי
874 ,מגדל העמק ,125 ,קדש
874 ,מגדל העמק ,295 ,קוממיות
874 ,מגדל העמק ,104 ,קישון
874 ,מגדל העמק ,1002 ,קריית חינוך
874 ,מגדל העמק ,6518 ,קרית בגין
874 ,מגדל העמק ,6508 ,קרית רבין
874 ,מגדל העמק ,138 ,קרן היסוד
874 ,מגדל העמק ,260 ,רח מס' 3
874 ,מגדל העמק ,6500 ,רמת אשכול
874 ,מגדל העמק ,6501 ,רמת בלפור
874 ,מגדל העמק ,6506 ,רמת גבריאל
874 ,מגדל העמק ,6502 ,רמת יזרעאל
874 ,מגדל העמק ,307 ,רפאל איתן
874 ,מגדל העמק ,327 ,שבי ציון
874 ,מגדל העמק ,165 ,שביל הנרקיס
874 ,מגדל העמק ,241 ,שד הבנים
874 ,מגדל העמק ,196 ,שד עמור שאול
874 ,מגדל העמק ,120 ,שדרות צבי אלדרוטי
874 ,מגדל העמק ,146 ,שומרון
874 ,מגדל העמק ,167 ,שושנת העמקים
874 ,מגדל העמק ,239 ,שיאון
874 ,מגדל העמק ,329 ,שיפון
874 ,מגדל העמק ,6510 ,שכ גן ישראל
874 ,מגדל העמק ,6519 ,שכ חימר
874 ,מגדל העמק ,6509 ,שכ יסמין
874 ,מגדל העמק ,6507 ,שכ יערת העמק
874 ,מגדל העמק ,6505 ,שכ יפה נוף
874 ,מגדל העמק ,1001 ,שכ מגדל אור
874 ,מגדל העמק ,6515 ,שכ מערבית
874 ,מגדל העמק ,6517 ,שכ מצפה העמק
874 ,מגדל העמק ,6503 ,שכ נוף העמק
874 ,מגדל העמק ,6504 ,שכ ספיר
874 ,מגדל העמק ,6513 ,שכ פנינת העמק
874 ,מגדל העמק ,6514 ,שכ רסקו
874 ,מגדל העמק ,6516 ,שכ שלום
874 ,מגדל העמק ,134 ,שלום עליכם
874 ,מגדל העמק ,231 ,שניר
874 ,מגדל העמק ,223 ,שפירא ישראל
874 ,מגדל העמק ,149 ,ששת הימים
874 ,מגדל העמק ,109 ,תבור
874 ,מגדל העמק ,270 ,תובל
874 ,מגדל העמק ,127 ,תשי"ג
3561 ,מגדל עוז ,6500 ,גבעת המבתר
3561 ,מגדל עוז ,9000 ,מגדל עוז
3751 ,מגדלים ,9000 ,מגדלים
586 ,מגידו ,9000 ,מגידו
375 ,מגל ,122 ,דרך האבוקדו
375 ,מגל ,102 ,דרך האופק
375 ,מגל ,100 ,דרך הברושים
375 ,מגל ,106 ,דרך החרמש
375 ,מגל ,103 ,דרך הראשונים
375 ,מגל ,104 ,דרך השדות
375 ,מגל ,105 ,דרך התצפית
375 ,מגל ,107 ,דרך עץ השדה
375 ,מגל ,108 ,הגפן
375 ,מגל ,109 ,הזית
375 ,מגל ,110 ,הצבר
375 ,מגל ,111 ,הרימון
375 ,מגל ,112 ,השקד
375 ,מגל ,113 ,התאנה
375 ,מגל ,114 ,התמר
375 ,מגל ,9000 ,מגל
375 ,מגל ,124 ,שביל אמירים
375 ,מגל ,117 ,שביל האורווה
375 ,מגל ,120 ,שביל הגנים
375 ,מגל ,127 ,שביל הדולב
375 ,מגל ,126 ,שביל ההדרים
375 ,מגל ,129 ,שביל הורדים
375 ,מגל ,116 ,שביל הזיתים
375 ,מגל ,121 ,שביל הצעירים
375 ,מגל ,115 ,שביל הצריפים
375 ,מגל ,128 ,שביל התורמוסים
375 ,מגל ,123 ,שביל טיילת
375 ,מגל ,125 ,שביל נוף
375 ,מגל ,119 ,שביל קומותיים
695 ,מגן ,9000 ,מגן
1155 ,מגן שאול ,112 ,אגוז
1155 ,מגן שאול ,104 ,אורן
1155 ,מגן שאול ,109 ,אלה
1155 ,מגן שאול ,110 ,אלון
1155 ,מגן שאול ,111 ,ארז
1155 ,מגן שאול ,105 ,אשל
1155 ,מגן שאול ,107 ,ברוש
1155 ,מגן שאול ,106 ,דקל
1155 ,מגן שאול ,100 ,זית
1155 ,מגן שאול ,102 ,חרוב
1155 ,מגן שאול ,9000 ,מגן שאול
1155 ,מגן שאול ,113 ,צאלון
1155 ,מגן שאול ,103 ,רימון
1155 ,מגן שאול ,108 ,שקד
1155 ,מגן שאול ,101 ,תאנה
722 ,מגשימים ,102 ,אורן
722 ,מגשימים ,118 ,דקל
722 ,מגשימים ,121 ,דרך המייסדים
722 ,מגשימים ,105 ,הברוש
722 ,מגשימים ,119 ,הגפן
722 ,מגשימים ,107 ,הכלנית
722 ,מגשימים ,103 ,הערבה
722 ,מגשימים ,106 ,הרדוף
722 ,מגשימים ,117 ,התאנה
722 ,מגשימים ,120 ,זית
722 ,מגשימים ,115 ,יסמין
722 ,מגשימים ,9000 ,מגשימים
722 ,מגשימים ,101 ,נבון דוד
722 ,מגשימים ,111 ,נרקיס
722 ,מגשימים ,116 ,סביון
722 ,מגשימים ,109 ,סייפן
722 ,מגשימים ,110 ,פעמונית
722 ,מגשימים ,108 ,רותם
722 ,מגשימים ,104 ,תמר
2029 ,מדרך עוז ,101 ,בית ראשון במולדת
2029 ,מדרך עוז ,6500 ,הרחבה
2029 ,מדרך עוז ,9000 ,מדרך עוז
1140 ,מדרשת בן גוריון ,116 ,אגי
1140 ,מדרשת בן גוריון ,109 ,אוח
1140 ,מדרשת בן גוריון ,118 ,אפרוח
1140 ,מדרשת בן גוריון ,101 ,אשטרום
1140 ,מדרשת בן גוריון ,114 ,גדרון
1140 ,מדרשת בן גוריון ,119 ,גוזל
1140 ,מדרשת בן גוריון ,146 ,האוניברסיטה
1140 ,מדרשת בן גוריון ,148 ,הבשמים
1140 ,מדרשת בן גוריון ,144 ,הנבטים
1140 ,מדרשת בן גוריון ,147 ,הר אבנון
1140 ,מדרשת בן גוריון ,127 ,הר צרור
1140 ,מדרשת בן גוריון ,126 ,הרי אדום
1140 ,מדרשת בן גוריון ,149 ,השבלולים
1140 ,מדרשת בן גוריון ,117 ,ורדית
1140 ,מדרשת בן גוריון ,115 ,זנבן
1140 ,מדרשת בן גוריון ,122 ,חוברה
1140 ,מדרשת בן גוריון ,124 ,חוד עקב
1140 ,מדרשת בן גוריון ,7003 ,חוות אורליה
1140 ,מדרשת בן גוריון ,7002 ,חוות דעת
1140 ,מדרשת בן גוריון ,7004 ,חוות כרמי עבדת
1140 ,מדרשת בן גוריון ,7001 ,חוות קורנמל
1140 ,מדרשת בן גוריון ,132 ,יהודה עמיחי
1140 ,מדרשת בן גוריון ,128 ,יהושע כהן
1140 ,מדרשת בן גוריון ,110 ,ינשוף
1140 ,מדרשת בן גוריון ,152 ,לאה גולדברג
1140 ,מדרשת בן גוריון ,108 ,לילית
1140 ,מדרשת בן גוריון ,112 ,מדברון
1140 ,מדרשת בן גוריון ,9000 ,מדרשת בן גוריון
1140 ,מדרשת בן גוריון ,129 ,מיכאל גל
1140 ,מדרשת בן גוריון ,143 ,מצוק הצינים
1140 ,מדרשת בן גוריון ,102 ,מרכז מיטרני
1140 ,מדרשת בן גוריון ,103 ,משעול חצב
1140 ,מדרשת בן גוריון ,6500 ,נווה צין
1140 ,מדרשת בן גוריון ,141 ,נחל דרוך
1140 ,מדרשת בן גוריון ,136 ,נחל הרועה
1140 ,מדרשת בן גוריון ,134 ,נחל חוארים
1140 ,מדרשת בן גוריון ,135 ,נחל חצץ
1140 ,מדרשת בן גוריון ,139 ,נחל נוקד
1140 ,מדרשת בן גוריון ,138 ,נחל צפית
1140 ,מדרשת בן גוריון ,140 ,נחל קרקש
1140 ,מדרשת בן גוריון ,123 ,נחל שועלים
1140 ,מדרשת בן גוריון ,137 ,נחל תלול
1140 ,מדרשת בן גוריון ,113 ,סלעית
1140 ,מדרשת בן גוריון ,133 ,ע. הלל
1140 ,מדרשת בן גוריון ,145 ,עין אורחות
1140 ,מדרשת בן גוריון ,105 ,עין זיק
1140 ,מדרשת בן גוריון ,151 ,עין מור
1140 ,מדרשת בן גוריון ,106 ,עין שביב
1140 ,מדרשת בן גוריון ,104 ,עין שרב
1140 ,מדרשת בן גוריון ,120 ,עפרוני
1140 ,מדרשת בן גוריון ,121 ,צופית
1140 ,מדרשת בן גוריון ,131 ,רחל המשוררת
1140 ,מדרשת בן גוריון ,150 ,רכס חלוקים
1140 ,מדרשת בן גוריון ,125 ,רמת דבשון
1140 ,מדרשת בן גוריון ,142 ,שדה צין
1140 ,מדרשת בן גוריון ,111 ,שעיר
1140 ,מדרשת בן גוריון ,107 ,תנשמת
1140 ,מדרשת בן גוריון ,130 ,תרצה אתר
897 ,מדרשת רופין ,9000 ,מדרשת רופין
3797 ,מודיעין עילית ,239 ,אבודרהם
3797 ,מודיעין עילית ,150 ,אבי עזרי
3797 ,מודיעין עילית ,237 ,אבן גבירול
3797 ,מודיעין עילית ,201 ,אבני נזר
3797 ,מודיעין עילית ,210 ,אור החיים
3797 ,מודיעין עילית ,219 ,אורחות צדיקים
3797 ,מודיעין עילית ,253 ,אשר לשלמה
3797 ,מודיעין עילית ,257 ,בעל הטורים
3797 ,מודיעין עילית ,236 ,בעש"ט
3797 ,מודיעין עילית ,230 ,דרך קרית ספר
3797 ,מודיעין עילית ,235 ,הגר"א
3797 ,מודיעין עילית ,128 ,המאירי
3797 ,מודיעין עילית ,231 ,הסבא מסלבודקא
3797 ,מודיעין עילית ,234 ,הרב אלישיב
3797 ,מודיעין עילית ,232 ,השל"ה
3797 ,מודיעין עילית ,252 ,זכרון שמואל
3797 ,מודיעין עילית ,228 ,חובת הלבבות
3797 ,מודיעין עילית ,152 ,חזון איש
3797 ,מודיעין עילית ,216 ,חזון דוד
3797 ,מודיעין עילית ,106 ,חפץ חיים
3797 ,מודיעין עילית ,212 ,חת"ם סופר
3797 ,מודיעין עילית ,251 ,ישועת דוד
3797 ,מודיעין עילית ,127 ,מהרי"ל
3797 ,מודיעין עילית ,9000 ,מודיעין עילית
3797 ,מודיעין עילית ,203 ,מנחת חינוך
3797 ,מודיעין עילית ,153 ,מנחת יהודה
3797 ,מודיעין עילית ,215 ,מנחת שלמה
3797 ,מודיעין עילית ,205 ,מסילת יוסף
3797 ,מודיעין עילית ,204 ,מסילת ישרים
3797 ,מודיעין עילית ,100 ,מעלות שמחה
3797 ,מודיעין עילית ,105 ,מרומי שדה
3797 ,מודיעין עילית ,207 ,משך חכמה
3797 ,מודיעין עילית ,222 ,משעול רבי אמי
3797 ,מודיעין עילית ,227 ,משעול רבי טרפון
3797 ,מודיעין עילית ,209 ,נודע ביהודה
3797 ,מודיעין עילית ,206 ,נתיבות המשפט
3797 ,מודיעין עילית ,241 ,נתיבות שלום
3797 ,מודיעין עילית ,233 ,סמטת אחיעזר
3797 ,מודיעין עילית ,217 ,עיון התלמוד
3797 ,מודיעין עילית ,213 ,פרי חדש
3797 ,מודיעין עילית ,151 ,קהילות יעקב
3797 ,מודיעין עילית ,214 ,קצות החושן
3797 ,מודיעין עילית ,6504 ,קרית דגל התורה
3797 ,מודיעין עילית ,254 ,ראב"ד
3797 ,מודיעין עילית ,242 ,ראשית חכמה
3797 ,מודיעין עילית ,225 ,רב ושמואל
3797 ,מודיעין עילית ,220 ,רבי יהודה הנשיא
3797 ,מודיעין עילית ,129 ,רבי נתן צבי
3797 ,מודיעין עילית ,223 ,רבי עקיבא
3797 ,מודיעין עילית ,221 ,רבי שמעון בר יוחאי
3797 ,מודיעין עילית ,255 ,רבינו תם
3797 ,מודיעין עילית ,240 ,ריטב"א
3797 ,מודיעין עילית ,260 ,רמב"ם
3797 ,מודיעין עילית ,258 ,רמב"ן
3797 ,מודיעין עילית ,261 ,רש"י
3797 ,מודיעין עילית ,256 ,רשב"א
3797 ,מודיעין עילית ,259 ,רשב"ם
3797 ,מודיעין עילית ,211 ,שאגת אריה
3797 ,מודיעין עילית ,229 ,שד אביי ורבא
3797 ,מודיעין עילית ,218 ,שד בית שמאי
3797 ,מודיעין עילית ,250 ,שד הרב מפוניבז'
3797 ,מודיעין עילית ,202 ,שדי חמד
3797 ,מודיעין עילית ,224 ,שדרות הלל ושמאי
3797 ,מודיעין עילית ,226 ,שדרות יחזקאל
3797 ,מודיעין עילית ,6502 ,שכ אחוזת ברכפלד
3797 ,מודיעין עילית ,6503 ,שכ נאות הפסגה
3797 ,מודיעין עילית ,6505 ,שכ נחלת חפציבה
3797 ,מודיעין עילית ,6506 ,שכ קרית מלך
3797 ,מודיעין עילית ,6501 ,שכ קרית ספר
3797 ,מודיעין עילית ,208 ,שער המלך
3797 ,מודיעין עילית ,200 ,שערי תשובה
1200 ,מודיעין-מכבים-רעות ,3254 ,אביטל מכבים -רעות
1200 ,מודיעין-מכבים-רעות ,700 ,אבני החושן
1200 ,מודיעין-מכבים-רעות ,3136 ,אגוז מכבים רעות
1200 ,מודיעין-מכבים-רעות ,115 ,אגס
1200 ,מודיעין-מכבים-רעות ,3253 ,אדיר מכבים-רעות
1200 ,מודיעין-מכבים-רעות ,168 ,אדמונית
1200 ,מודיעין-מכבים-רעות ,655 ,אדר
1200 ,מודיעין-מכבים-רעות ,703 ,אודם
1200 ,מודיעין-מכבים-רעות ,3225 ,אורן מכבים-רעות
1200 ,מודיעין-מכבים-רעות ,6505 ,אזור התעשיה
1200 ,מודיעין-מכבים-רעות ,711 ,אחלמה
1200 ,מודיעין-מכבים-רעות ,108 ,איזדרכת
1200 ,מודיעין-מכבים-רעות ,663 ,אייר
1200 ,מודיעין-מכבים-רעות ,3175 ,אירוסים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3201 ,אכסנית מכבים מ"ר
1200 ,מודיעין-מכבים-רעות ,3137 ,אלה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,662 ,אלול
1200 ,מודיעין-מכבים-רעות ,631 ,אלי כהן
1200 ,מודיעין-מכבים-רעות ,401 ,אליהו הנביא
1200 ,מודיעין-מכבים-רעות ,402 ,אלישע הנביא
1200 ,מודיעין-מכבים-רעות ,3176 ,אלמוג מכבים רעות
1200 ,מודיעין-מכבים-רעות ,107 ,אלמוגן
1200 ,מודיעין-מכבים-רעות ,3177 ,אמנון ותמר מ"ר
1200 ,מודיעין-מכבים-רעות ,3103 ,אנפה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,621 ,אסתר המלכה
1200 ,מודיעין-מכבים-רעות ,186 ,אפיקי מים
1200 ,מודיעין-מכבים-רעות ,617 ,אפרים
1200 ,מודיעין-מכבים-רעות ,761 ,אפרים קציר
1200 ,מודיעין-מכבים-רעות ,3252 ,ארבל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3138 ,ארז מכבים רעות
1200 ,מודיעין-מכבים-רעות ,759 ,אריאל שרון
1200 ,מודיעין-מכבים-רעות ,113 ,אשחר
1200 ,מודיעין-מכבים-רעות ,3117 ,אשכול מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3139 ,אשל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,611 ,אשר
1200 ,מודיעין-מכבים-רעות ,710 ,בדולח
1200 ,מודיעין-מכבים-רעות ,3118 ,בוסתן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,609 ,בנימין
1200 ,מודיעין-מכבים-רעות ,3140 ,ברוש מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3125 ,ברקנית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3178 ,ברקת מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3141 ,בשן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3142 ,גבעת הלבונה מ"ר
1200 ,מודיעין-מכבים-רעות ,615 ,גד
1200 ,מודיעין-מכבים-רעות ,3120 ,גדי מכבים רעות
1200 ,מודיעין-מכבים-רעות ,751 ,גולדה מאיר
1200 ,מודיעין-מכבים-רעות ,3255 ,גולן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,189 ,גינת אגוז
1200 ,מודיעין-מכבים-רעות ,207 ,גינת איילון
1200 ,מודיעין-מכבים-רעות ,210 ,גינת בית שאן
1200 ,מודיעין-מכבים-רעות ,174 ,גינת דותן
1200 ,מודיעין-מכבים-רעות ,220 ,גינת האלה
1200 ,מודיעין-מכבים-רעות ,175 ,גינת החולה
1200 ,מודיעין-מכבים-רעות ,215 ,גינת זבולון
1200 ,מודיעין-מכבים-רעות ,225 ,גינת יצחק רבין
1200 ,מודיעין-מכבים-רעות ,135 ,גינת מגדל עוז
1200 ,מודיעין-מכבים-רעות ,3179 ,גיתית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3143 ,גלבוע מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3251 ,גליל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3144 ,גלעד מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3119 ,גפן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,403 ,דבורה הנביאה
1200 ,מודיעין-מכבים-רעות ,167 ,דבורנית
1200 ,מודיעין-מכבים-רעות ,3145 ,דובדבן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,1529 ,דוד בן גוריון
1200 ,מודיעין-מכבים-רעות ,718 ,דוכיפת
1200 ,מודיעין-מכבים-רעות ,3226 ,דולב מכבים רעות
1200 ,מודיעין-מכבים-רעות ,211 ,דידי רחל הראל
1200 ,מודיעין-מכבים-רעות ,622 ,דינה
1200 ,מודיעין-מכבים-רעות ,601 ,דן
1200 ,מודיעין-מכבים-רעות ,169 ,דפנה
1200 ,מודיעין-מכבים-רעות ,3146 ,דקל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,102 ,דרך אלון יגאל
1200 ,מודיעין-מכבים-רעות ,3131 ,דרך ד"ר פלד ישראל
1200 ,מודיעין-מכבים-רעות ,3220 ,דרך המייסדים מ"ר
1200 ,מודיעין-מכבים-רעות ,3173 ,דרך הראשונים מ"ר
1200 ,מודיעין-מכבים-רעות ,137 ,דרך פרופ' פרג יאיר
1200 ,מודיעין-מכבים-רעות ,1506 ,האופה
1200 ,מודיעין-מכבים-רעות ,1508 ,האורג
1200 ,מודיעין-מכבים-רעות ,1511 ,הבנאי
1200 ,מודיעין-מכבים-רעות ,1507 ,הגנן
1200 ,מודיעין-מכבים-רעות ,198 ,הדודאים
1200 ,מודיעין-מכבים-רעות ,3126 ,הדורית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3147 ,הדס מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3110 ,הדרור מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3148 ,הדרים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3101 ,הורד מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3102 ,הזמיר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,1537 ,הזריחה
1200 ,מודיעין-מכבים-רעות ,1539 ,הזרימה
1200 ,מודיעין-מכבים-רעות ,1536 ,הזריעה
1200 ,מודיעין-מכבים-רעות ,1542 ,החורש
1200 ,מודיעין-מכבים-רעות ,1503 ,החרט
1200 ,מודיעין-מכבים-רעות ,1531 ,היער
1200 ,מודיעין-מכבים-רעות ,190 ,הכרמים
1200 ,מודיעין-מכבים-רעות ,650 ,הלוח העברי
1200 ,מודיעין-מכבים-רעות ,3105 ,הלוטוס מכבים רעות
1200 ,מודיעין-מכבים-רעות ,1512 ,המכונאי
1200 ,מודיעין-מכבים-רעות ,1534 ,המעיין
1200 ,מודיעין-מכבים-רעות ,404 ,הנביאים
1200 ,מודיעין-מכבים-רעות ,1535 ,הנביטה
1200 ,מודיעין-מכבים-רעות ,1514 ,הנגר
1200 ,מודיעין-מכבים-רעות ,1538 ,הנטיעה
1200 ,מודיעין-מכבים-רעות ,191 ,הניצנים
1200 ,מודיעין-מכבים-רעות ,1504 ,הנפח
1200 ,מודיעין-מכבים-רעות ,212 ,הנרייטה סאלד
1200 ,מודיעין-מכבים-רעות ,1502 ,הסוחר
1200 ,מודיעין-מכבים-רעות ,1509 ,הסתת
1200 ,מודיעין-מכבים-רעות ,1513 ,הצורף
1200 ,מודיעין-מכבים-רעות ,1532 ,הצמיחה
1200 ,מודיעין-מכבים-רעות ,1515 ,הקדר
1200 ,מודיעין-מכבים-רעות ,1541 ,הקידמה
1200 ,מודיעין-מכבים-רעות ,3301 ,הר אמיר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3327 ,הר ארגמן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3302 ,הר בוקר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3303 ,הר גוונים מ"ר
1200 ,מודיעין-מכבים-רעות ,3304 ,הר דלתון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3305 ,הר הילה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3306 ,הר ורדה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3307 ,הר זיו מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3308 ,הר חזון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3309 ,הר טללים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3310 ,הר יודפת מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3311 ,הר כנען מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3312 ,הר להבים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3313 ,הר מעוז מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3314 ,הר נטופה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3315 ,הר סנה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3316 ,הר עמינדב מ"ר
1200 ,מודיעין-מכבים-רעות ,3317 ,הר פורת מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3318 ,הר צפריר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3319 ,הר קדרים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3320 ,הר רביד מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3326 ,הר רמון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3322 ,הר שאול מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3321 ,הר שחר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3323 ,הר תורען מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3324 ,הר תמנע מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3227 ,הרדוף מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3104 ,הרימון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,320 ,הרכבת
1200 ,מודיעין-מכבים-רעות ,1500 ,השדרה המרכזית
1200 ,מודיעין-מכבים-רעות ,183 ,השולמית
1200 ,מודיעין-מכבים-רעות ,3116 ,ורדית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3228 ,זית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,405 ,זכריה הנביא
1200 ,מודיעין-מכבים-רעות ,762 ,זלמן שזר
1200 ,מודיעין-מכבים-רעות ,188 ,חבצלת השרון
1200 ,מודיעין-מכבים-רעות ,3180 ,חבצלת מכבים רעות
1200 ,מודיעין-מכבים-רעות ,203 ,חגווי הסלע
1200 ,מודיעין-מכבים-רעות ,406 ,חגי הנביא
1200 ,מודיעין-מכבים-רעות ,665 ,חגי ישראל
1200 ,מודיעין-מכבים-רעות ,3127 ,חוחית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,204 ,חוט השני
1200 ,מודיעין-מכבים-רעות ,410 ,חטיבה שבע
1200 ,מודיעין-מכבים-רעות ,411 ,חטיבת אלכסנדרוני
1200 ,מודיעין-מכבים-רעות ,412 ,חטיבת גבעתי
1200 ,מודיעין-מכבים-רעות ,413 ,חטיבת גולני
1200 ,מודיעין-מכבים-רעות ,414 ,חטיבת הנח"ל
1200 ,מודיעין-מכבים-רעות ,415 ,חטיבת הצנחנים
1200 ,מודיעין-מכבים-רעות ,417 ,חטיבת הראל
1200 ,מודיעין-מכבים-רעות ,416 ,חטיבת יפתח
1200 ,מודיעין-מכבים-רעות ,753 ,חיים הרצוג
1200 ,מודיעין-מכבים-רעות ,756 ,חיים ויצמן
1200 ,מודיעין-מכבים-רעות ,308 ,חיננית
1200 ,מודיעין-מכבים-רעות ,166 ,חלמונית
1200 ,מודיעין-מכבים-רעות ,724 ,חסידה
1200 ,מודיעין-מכבים-רעות ,3181 ,חצב מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3229 ,חרוב מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3149 ,חרמון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,165 ,חרצית
1200 ,מודיעין-מכבים-רעות ,652 ,חשון
1200 ,מודיעין-מכבים-רעות ,3124 ,טבלן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3230 ,טבק מכבים רעות
1200 ,מודיעין-מכבים-רעות ,654 ,טבת
1200 ,מודיעין-מכבים-רעות ,3182 ,טופז מכבים רעות
1200 ,מודיעין-מכבים-רעות ,627 ,טיילת אבני חן
1200 ,מודיעין-מכבים-רעות ,628 ,טיילת הציפורים
1200 ,מודיעין-מכבים-רעות ,3261 ,טללים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,602 ,יהודה
1200 ,מודיעין-מכבים-רעות ,315 ,יהודה המכבי
1200 ,מודיעין-מכבים-רעות ,701 ,יהלום
1200 ,מודיעין-מכבים-רעות ,420 ,יואל הנביא
1200 ,מודיעין-מכבים-רעות ,3350 ,יובלים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,421 ,יונה הנביא
1200 ,מודיעין-מכבים-רעות ,606 ,יוסף
1200 ,מודיעין-מכבים-רעות ,422 ,יחזקאל הנביא
1200 ,מודיעין-מכבים-רעות ,3121 ,יסעור מכבים רעות
1200 ,מודיעין-מכבים-רעות ,614 ,יעל הגיבורה
1200 ,מודיעין-מכבים-רעות ,742 ,יער אלונים
1200 ,מודיעין-מכבים-רעות ,739 ,יער אשתאול
1200 ,מודיעין-מכבים-רעות ,733 ,יער בארי
1200 ,מודיעין-מכבים-רעות ,732 ,יער בן שמן
1200 ,מודיעין-מכבים-רעות ,735 ,יער ברעם
1200 ,מודיעין-מכבים-רעות ,741 ,יער הזורע
1200 ,מודיעין-מכבים-רעות ,740 ,יער חניתה
1200 ,מודיעין-מכבים-רעות ,736 ,יער חצרים
1200 ,מודיעין-מכבים-רעות ,734 ,יער חרובית
1200 ,מודיעין-מכבים-רעות ,737 ,יער ירושלים
1200 ,מודיעין-מכבים-רעות ,738 ,יער יתיר
1200 ,מודיעין-מכבים-רעות ,3231 ,יערה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,731 ,יערות ישראל
1200 ,מודיעין-מכבים-רעות ,3256 ,יפה נוף מכבים רעות
1200 ,מודיעין-מכבים-רעות ,760 ,יצחק בן צבי
1200 ,מודיעין-מכבים-רעות ,755 ,יצחק נבון
1200 ,מודיעין-מכבים-רעות ,754 ,יצחק שמיר
1200 ,מודיעין-מכבים-רעות ,3183 ,יקינטון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,656 ,ירח-אב
1200 ,מודיעין-מכבים-רעות ,664 ,ירחים
1200 ,מודיעין-מכבים-רעות ,423 ,ירמיהו הנביא
1200 ,מודיעין-מכבים-רעות ,424 ,ישעיהו הנביא
1200 ,מודיעין-מכבים-רעות ,704 ,ישפה
1200 ,מודיעין-מכבים-רעות ,610 ,יששכר
1200 ,מודיעין-מכבים-רעות ,163 ,כחלית ההרים
1200 ,מודיעין-מכבים-רעות ,6016 ,ככר אבוקה
1200 ,מודיעין-מכבים-רעות ,6005 ,ככר האלופים
1200 ,מודיעין-מכבים-רעות ,6006 ,ככר הגבורה
1200 ,מודיעין-מכבים-רעות ,6007 ,ככר החטיבות
1200 ,מודיעין-מכבים-רעות ,6008 ,ככר הלווים
1200 ,מודיעין-מכבים-רעות ,6009 ,ככר המגינים
1200 ,מודיעין-מכבים-רעות ,6018 ,ככר המתנדבים
1200 ,מודיעין-מכבים-רעות ,6010 ,ככר הנביאים
1200 ,מודיעין-מכבים-רעות ,6011 ,ככר הפסגה
1200 ,מודיעין-מכבים-רעות ,6012 ,ככר השומרה
1200 ,מודיעין-מכבים-רעות ,6022 ,ככר השכנות
1200 ,מודיעין-מכבים-רעות ,6001 ,ככר חטיבת הנגב
1200 ,מודיעין-מכבים-רעות ,6002 ,ככר חטיבת הראל
1200 ,מודיעין-מכבים-רעות ,6013 ,ככר יקותיאלי
1200 ,מודיעין-מכבים-רעות ,6014 ,ככר לב רעות
1200 ,מודיעין-מכבים-רעות ,6003 ,ככר מלאכי הנביא
1200 ,מודיעין-מכבים-רעות ,6015 ,ככר משואה
1200 ,מודיעין-מכבים-רעות ,6004 ,ככר עמוס הנביא
1200 ,מודיעין-מכבים-רעות ,6020 ,ככר רבין
1200 ,מודיעין-מכבים-רעות ,6017 ,ככר שלהבת
1200 ,מודיעין-מכבים-רעות ,6021 ,ככר תנועת הנוער
1200 ,מודיעין-מכבים-רעות ,105 ,כליל החורש
1200 ,מודיעין-מכבים-רעות ,3232 ,כלנית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3115 ,כנרית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,653 ,כסלו
1200 ,מודיעין-מכבים-רעות ,717 ,כפתור ופרח
1200 ,מודיעין-מכבים-רעות ,177 ,כרכום
1200 ,מודיעין-מכבים-רעות ,3150 ,כרמל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,202 ,כתם פז
1200 ,מודיעין-מכבים-רעות ,619 ,לאה אמנו
1200 ,מודיעין-מכבים-רעות ,330 ,לב העיר
1200 ,מודיעין-מכבים-רעות ,112 ,לבונה
1200 ,מודיעין-מכבים-רעות ,3122 ,לביא מכבים רעות
1200 ,מודיעין-מכבים-רעות ,603 ,לוי
1200 ,מודיעין-מכבים-רעות ,1200 ,לוי אשכול
1200 ,מודיעין-מכבים-רעות ,171 ,לוע הארי
1200 ,מודיעין-מכבים-רעות ,3258 ,לילך מכבים רעות
1200 ,מודיעין-מכבים-רעות ,111 ,לימון
1200 ,מודיעין-מכבים-רעות ,708 ,לשם
1200 ,מודיעין-מכבים-רעות ,3262 ,מבוא רעות מ"ר
1200 ,מודיעין-מכבים-רעות ,430 ,מבצע דני
1200 ,מודיעין-מכבים-רעות ,431 ,מבצע דקל
1200 ,מודיעין-מכבים-רעות ,432 ,מבצע חורב
1200 ,מודיעין-מכבים-רעות ,433 ,מבצע חירם
1200 ,מודיעין-מכבים-רעות ,434 ,מבצע יואב
1200 ,מודיעין-מכבים-רעות ,435 ,מבצע יונתן
1200 ,מודיעין-מכבים-רעות ,436 ,מבצע לוט
1200 ,מודיעין-מכבים-רעות ,437 ,מבצע נחשון
1200 ,מודיעין-מכבים-רעות ,438 ,מבצע עובדה
1200 ,מודיעין-מכבים-רעות ,439 ,מבצע קדש
1200 ,מודיעין-מכבים-רעות ,440 ,מבצע קלשון
1200 ,מודיעין-מכבים-רעות ,110 ,מגדל דוד
1200 ,מודיעין-מכבים-רעות ,148 ,מגדל הלבנון
1200 ,מודיעין-מכבים-רעות ,715 ,מגדל המנורה
1200 ,מודיעין-מכבים-רעות ,716 ,מגדל ים
1200 ,מודיעין-מכבים-רעות ,132 ,מגדל עוז
1200 ,מודיעין-מכבים-רעות ,6500 ,מודיעין
1200 ,מודיעין-מכבים-רעות ,9000 ,מודיעין-מכבים-רעות
1200 ,מודיעין-מכבים-רעות ,425 ,מיכה הנביא
1200 ,מודיעין-מכבים-רעות ,3151 ,מירון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3184 ,מיתר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,6501 ,מכבים-רעות מ"ר
1200 ,מודיעין-מכבים-רעות ,616 ,מנשה
1200 ,מודיעין-מכבים-רעות ,106 ,מנתור
1200 ,מודיעין-מכבים-רעות ,103 ,מעלה אבנר
1200 ,מודיעין-מכבים-רעות ,104 ,מעלה ברוך מזרחי
1200 ,מודיעין-מכבים-רעות ,310 ,מעלה גדעון
1200 ,מודיעין-מכבים-רעות ,311 ,מעלה דורון
1200 ,מודיעין-מכבים-רעות ,623 ,מעלה דניאל גומז
1200 ,מודיעין-מכבים-רעות ,3152 ,מעלה המור מ"ר
1200 ,מודיעין-מכבים-רעות ,209 ,מעלה ילדי מעוז
1200 ,מודיעין-מכבים-רעות ,306 ,מעלה נמר
1200 ,מודיעין-מכבים-רעות ,309 ,מעלה עמיעז
1200 ,מודיעין-מכבים-רעות ,3153 ,מצפה רעות מ"ר
1200 ,מודיעין-מכבים-רעות ,3114 ,מרגנית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,162 ,מרווה
1200 ,מודיעין-מכבים-רעות ,3186 ,מרום מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3128 ,מרומית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,214 ,מרים בן פורת
1200 ,מודיעין-מכבים-רעות ,618 ,מרים החשמונאית
1200 ,מודיעין-מכבים-רעות ,213 ,מרסל ניניו-בוגר
1200 ,מודיעין-מכבים-רעות ,752 ,משה שרת
1200 ,מודיעין-מכבים-רעות ,3337 ,משעול אמיר בן אריה
1200 ,מודיעין-מכבים-רעות ,3335 ,משעול יניב בר און
1200 ,מודיעין-מכבים-רעות ,3338 ,משעול עוז צמח
1200 ,מודיעין-מכבים-רעות ,3336 ,משעול צבי זימן
1200 ,מודיעין-מכבים-רעות ,136 ,משעול שידלובסקי
1200 ,מודיעין-מכבים-רעות ,300 ,מתתיהו הכהן
1200 ,מודיעין-מכבים-רעות ,193 ,נאות דשא
1200 ,מודיעין-מכבים-רעות ,149 ,נהר הירדן
1200 ,מודיעין-מכבים-רעות ,3132 ,נוף קדומים מ"ר
1200 ,מודיעין-מכבים-רעות ,707 ,נופך
1200 ,מודיעין-מכבים-רעות ,3106 ,נופר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3187 ,נורית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3351 ,נחל אביב מכבים רעות
1200 ,מודיעין-מכבים-רעות ,151 ,נחל אלעל
1200 ,מודיעין-מכבים-רעות ,304 ,נחל ארנון
1200 ,מודיעין-מכבים-רעות ,155 ,נחל בוקק
1200 ,מודיעין-מכבים-רעות ,121 ,נחל בזק
1200 ,מודיעין-מכבים-רעות ,122 ,נחל בצת
1200 ,מודיעין-מכבים-רעות ,3352 ,נחל ברק מכבים רעות
1200 ,מודיעין-מכבים-רעות ,119 ,נחל גלים
1200 ,מודיעין-מכבים-רעות ,124 ,נחל געתון
1200 ,מודיעין-מכבים-רעות ,3353 ,נחל גרופית מ"ר
1200 ,מודיעין-מכבים-רעות ,125 ,נחל דליה
1200 ,מודיעין-מכבים-רעות ,3354 ,נחל דן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,128 ,נחל הבשור
1200 ,מודיעין-מכבים-רעות ,133 ,נחל הירמוך
1200 ,מודיעין-מכבים-רעות ,123 ,נחל הירקון
1200 ,מודיעין-מכבים-רעות ,3355 ,נחל המעיינות מ"ר
1200 ,מודיעין-מכבים-רעות ,153 ,נחל זוהר
1200 ,מודיעין-מכבים-רעות ,3356 ,נחל זויתן מ"ר
1200 ,מודיעין-מכבים-רעות ,150 ,נחל חבר
1200 ,מודיעין-מכבים-רעות ,3357 ,נחל חרות מ"ר
1200 ,מודיעין-מכבים-רעות ,3358 ,נחל טף מכבים רעות
1200 ,מודיעין-מכבים-רעות ,146 ,נחל יבנאל
1200 ,מודיעין-מכבים-רעות ,154 ,נחל יגור
1200 ,מודיעין-מכבים-רעות ,3359 ,נחל יעלים מ"ר
1200 ,מודיעין-מכבים-רעות ,3360 ,נחל כיסופים מ"ר
1200 ,מודיעין-מכבים-רעות ,3361 ,נחל לכיש מכבים רעות
1200 ,מודיעין-מכבים-רעות ,129 ,נחל מירון
1200 ,מודיעין-מכבים-רעות ,3362 ,נחל משושים מ"ר
1200 ,מודיעין-מכבים-רעות ,3363 ,נחל נטוף מכבים רעות
1200 ,מודיעין-מכבים-רעות ,130 ,נחל נטפים
1200 ,מודיעין-מכבים-רעות ,157 ,נחל נעמן
1200 ,מודיעין-מכבים-רעות ,3364 ,נחל סער מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3369 ,נחל עוז מכבים רעות
1200 ,מודיעין-מכבים-רעות ,134 ,נחל עיון
1200 ,מודיעין-מכבים-רעות ,156 ,נחל עמוד
1200 ,מודיעין-מכבים-רעות ,208 ,נחל ענבה
1200 ,מודיעין-מכבים-רעות ,3365 ,נחל ערוגות מ"ר
1200 ,מודיעין-מכבים-רעות ,143 ,נחל פארן
1200 ,מודיעין-מכבים-רעות ,3366 ,נחל פולג מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3367 ,נחל צאלים מ"ר
1200 ,מודיעין-מכבים-רעות ,158 ,נחל צופר
1200 ,מודיעין-מכבים-רעות ,144 ,נחל צין
1200 ,מודיעין-מכבים-רעות ,152 ,נחל ציפורי
1200 ,מודיעין-מכבים-רעות ,159 ,נחל צלמון
1200 ,מודיעין-מכבים-רעות ,147 ,נחל קטלב
1200 ,מודיעין-מכבים-רעות ,141 ,נחל קידרון
1200 ,מודיעין-מכבים-רעות ,3368 ,נחל קישון מ"ר
1200 ,מודיעין-מכבים-רעות ,145 ,נחל שורק
1200 ,מודיעין-מכבים-רעות ,127 ,נחל שיקמה
1200 ,מודיעין-מכבים-רעות ,142 ,נחל שניר
1200 ,מודיעין-מכבים-רעות ,131 ,נחל תבור
1200 ,מודיעין-מכבים-רעות ,126 ,נחל תנינים
1200 ,מודיעין-מכבים-רעות ,719 ,נחליאלי
1200 ,מודיעין-מכבים-רעות ,658 ,ניסן
1200 ,מודיעין-מכבים-רעות ,3257 ,ניצן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,607 ,נפתלי
1200 ,מודיעין-מכבים-רעות ,164 ,נץ החלב
1200 ,מודיעין-מכבים-רעות ,3129 ,נקר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3188 ,נרקיס מכבים רעות
1200 ,מודיעין-מכבים-רעות ,178 ,סביון
1200 ,מודיעין-מכבים-רעות ,3189 ,סביונים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,458 ,סגן נתן כהן
1200 ,מודיעין-מכבים-רעות ,3260 ,סחלב מכבים רעות
1200 ,מודיעין-מכבים-רעות ,109 ,סיגלון
1200 ,מודיעין-מכבים-רעות ,659 ,סיון
1200 ,מודיעין-מכבים-רעות ,3112 ,סייפן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,720 ,סלעית
1200 ,מודיעין-מכבים-רעות ,194 ,סמדר
1200 ,מודיעין-מכבים-רעות ,101 ,סנה משה
1200 ,מודיעין-מכבים-רעות ,3123 ,סנונית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3190 ,ספיר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,161 ,סתוונית
1200 ,מודיעין-מכבים-רעות ,3111 ,עגור מכבים רעות
1200 ,מודיעין-מכבים-רעות ,426 ,עובדיה הנביא
1200 ,מודיעין-מכבים-רעות ,206 ,עופר האיילים
1200 ,מודיעין-מכבים-רעות ,160 ,עמק איילון
1200 ,מודיעין-מכבים-רעות ,441 ,עמק בית שאן
1200 ,מודיעין-מכבים-רעות ,100 ,עמק דותן
1200 ,מודיעין-מכבים-רעות ,442 ,עמק האלה
1200 ,מודיעין-מכבים-רעות ,140 ,עמק החולה
1200 ,מודיעין-מכבים-רעות ,443 ,עמק זבולון
1200 ,מודיעין-מכבים-רעות ,750 ,עמק חפר
1200 ,מודיעין-מכבים-רעות ,660 ,עמק חרוד
1200 ,מודיעין-מכבים-רעות ,120 ,עמק יזרעאל
1200 ,מודיעין-מכבים-רעות ,706 ,ענבר
1200 ,מודיעין-מכבים-רעות ,721 ,עפרוני
1200 ,מודיעין-מכבים-רעות ,195 ,עצי היער
1200 ,מודיעין-מכבים-רעות ,3154 ,עצמון מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3107 ,ערבה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3155 ,ערבי נחל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,192 ,ערוגות הבושם
1200 ,מודיעין-מכבים-רעות ,139 ,ערער
1200 ,מודיעין-מכבים-רעות ,200 ,עת הזמיר
1200 ,מודיעין-מכבים-רעות ,626 ,פז
1200 ,מודיעין-מכבים-רעות ,702 ,פטדה
1200 ,מודיעין-מכבים-רעות ,138 ,פיקוס
1200 ,מודיעין-מכבים-רעות ,201 ,פלח הרימון
1200 ,מודיעין-מכבים-רעות ,3233 ,פעמונית מכבים רעות
1200 ,מודיעין-מכבים-רעות ,196 ,פרי מגדים
1200 ,מודיעין-מכבים-רעות ,3130 ,פשוש מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3113 ,צאלה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,182 ,צאלון
1200 ,מודיעין-מכבים-רעות ,3259 ,צבעוני מכבים רעות
1200 ,מודיעין-מכבים-רעות ,723 ,צוקית
1200 ,מודיעין-מכבים-רעות ,179 ,ציפורנית
1200 ,מודיעין-מכבים-רעות ,1533 ,צלע ההר
1200 ,מודיעין-מכבים-רעות ,197 ,צרור המור
1200 ,מודיעין-מכבים-רעות ,185 ,קול דודי
1200 ,מודיעין-מכבים-רעות ,199 ,קול התור
1200 ,מודיעין-מכבים-רעות ,3185 ,קורל מכבים רעות
1200 ,מודיעין-מכבים-רעות ,307 ,קידה
1200 ,מודיעין-מכבים-רעות ,3234 ,קיסוס מכבים רעות
1200 ,מודיעין-מכבים-רעות ,172 ,קיפודן
1200 ,מודיעין-מכבים-רעות ,3192 ,קרן מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3191 ,קשת מכבים רעות
1200 ,מודיעין-מכבים-רעות ,450 ,רא"ל דוד אלעזר
1200 ,מודיעין-מכבים-רעות ,451 ,רא"ל חיים ברלב
1200 ,מודיעין-מכבים-רעות ,452 ,רא"ל חיים לסקוב
1200 ,מודיעין-מכבים-רעות ,453 ,רא"ל יגאל ידין
1200 ,מודיעין-מכבים-רעות ,454 ,רא"ל יעקב דורי
1200 ,מודיעין-מכבים-רעות ,455 ,רא"ל מרדכי גור
1200 ,מודיעין-מכבים-רעות ,456 ,רא"ל מרדכי מקלף
1200 ,מודיעין-מכבים-רעות ,457 ,רא"ל משה דיין
1200 ,מודיעין-מכבים-רעות ,605 ,ראובן
1200 ,מודיעין-מכבים-רעות ,612 ,רבקה אמנו
1200 ,מודיעין-מכבים-רעות ,3156 ,רותם מכבים רעות
1200 ,מודיעין-מכבים-רעות ,620 ,רחל אמנו
1200 ,מודיעין-מכבים-רעות ,3325 ,רכסים מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3174 ,רס"ן אייל גלר
1200 ,מודיעין-מכבים-רעות ,6510 ,רעות מ"ר
1200 ,מודיעין-מכבים-רעות ,3193 ,רקפת מכבים רעות
1200 ,מודיעין-מכבים-רעות ,709 ,שבו
1200 ,מודיעין-מכבים-רעות ,657 ,שבט
1200 ,מודיעין-מכבים-רעות ,600 ,שבטי ישראל
1200 ,מודיעין-מכבים-רעות ,3194 ,שגיא מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3170 ,שד אורנים מ"ר
1200 ,מודיעין-מכבים-רעות ,1510 ,שד החמאם
1200 ,מודיעין-מכבים-רעות ,1501 ,שד המלאכות
1200 ,מודיעין-מכבים-רעות ,1540 ,שד המעלה
1200 ,מודיעין-מכבים-רעות ,1505 ,שד המקצועות
1200 ,מודיעין-מכבים-רעות ,1530 ,שד הרכס
1200 ,מודיעין-מכבים-רעות ,1516 ,שד התעשיות
1200 ,מודיעין-מכבים-רעות ,305 ,שד יצחק רבין
1200 ,מודיעין-מכבים-רעות ,630 ,שד מנחם בגין
1200 ,מודיעין-מכבים-רעות ,730 ,שד ענבה
1200 ,מודיעין-מכבים-רעות ,176 ,שדרות דם המכבים
1200 ,מודיעין-מכבים-רעות ,303 ,שדרות החשמונאים
1200 ,מודיעין-מכבים-רעות ,3172 ,שדרת המכבים מ"ר
1200 ,מודיעין-מכבים-רעות ,3195 ,שדרת הפרחים מ"ר
1200 ,מודיעין-מכבים-רעות ,3196 ,שדרת עומרים מ"ר
1200 ,מודיעין-מכבים-רעות ,3197 ,שוהם מכבים רעות
1200 ,מודיעין-מכבים-רעות ,205 ,שומרי החומות
1200 ,מודיעין-מכבים-רעות ,170 ,שושן צחור
1200 ,מודיעין-מכבים-רעות ,187 ,שושנת העמקים
1200 ,מודיעין-מכבים-רעות ,725 ,שחף
1200 ,מודיעין-מכבים-רעות ,3157 ,שיזף מכבים רעות
1200 ,מודיעין-מכבים-רעות ,184 ,שיר השירים
1200 ,מודיעין-מכבים-רעות ,722 ,שלדג
1200 ,מודיעין-מכבים-רעות ,613 ,שלומציון המלכה
1200 ,מודיעין-מכבים-רעות ,427 ,שמואל הנביא
1200 ,מודיעין-מכבים-רעות ,608 ,שמעון
1200 ,מודיעין-מכבים-רעות ,758 ,שמעון פרס
1200 ,מודיעין-מכבים-רעות ,714 ,שני
1200 ,מודיעין-מכבים-רעות ,3158 ,שקד מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3159 ,שקמה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,757 ,שרה אהרנסון
1200 ,מודיעין-מכבים-רעות ,604 ,שרה אמנו
1200 ,מודיעין-מכבים-רעות ,713 ,שש משזר
1200 ,מודיעין-מכבים-רעות ,3160 ,תאנה מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3161 ,תבור מכבים רעות
1200 ,מודיעין-מכבים-רעות ,3162 ,תומר מכבים רעות
1200 ,מודיעין-מכבים-רעות ,712 ,תכלת
1200 ,מודיעין-מכבים-רעות ,173 ,תלתן
1200 ,מודיעין-מכבים-רעות ,661 ,תמוז
1200 ,מודיעין-מכבים-רעות ,116 ,תנ"צ אפריים ברכה
1200 ,מודיעין-מכבים-רעות ,114 ,תפוח
1200 ,מודיעין-מכבים-רעות ,705 ,תרשיש
1200 ,מודיעין-מכבים-רעות ,651 ,תשרי
269 ,מולדת ,7001 ,אחוזת שושנה
269 ,מולדת ,115 ,בני ברית
269 ,מולדת ,110 ,גלבוע
269 ,מולדת ,108 ,גלעד
269 ,מולדת ,113 ,גנים
269 ,מולדת ,117 ,האסם
269 ,מולדת ,118 ,הבזלת
269 ,מולדת ,104 ,הרדוף
269 ,מולדת ,114 ,חומה ומגדל
269 ,מולדת ,105 ,חצב
269 ,מולדת ,116 ,חקלאים
269 ,מולדת ,100 ,יששכר
269 ,מולדת ,101 ,כוכב הירדן
269 ,מולדת ,112 ,מגדל מים
269 ,מולדת ,9000 ,מולדת
269 ,מולדת ,103 ,פיקוסים
269 ,מולדת ,107 ,פרדס
269 ,מולדת ,109 ,צבאים
269 ,מולדת ,106 ,ראשונים
269 ,מולדת ,102 ,שומרים
269 ,מולדת ,111 ,תבור
208 ,מוצא עילית ,122 ,ארזה
208 ,מוצא עילית ,101 ,בית החלמה ארזה
208 ,מוצא עילית ,112 ,האורן
208 ,מוצא עילית ,102 ,האלה
208 ,מוצא עילית ,110 ,האלונים
208 ,מוצא עילית ,108 ,האקליפטוס
208 ,מוצא עילית ,103 ,הארזים
208 ,מוצא עילית ,111 ,הברוש
208 ,מוצא עילית ,113 ,הזית
208 ,מוצא עילית ,104 ,הראשונים
208 ,מוצא עילית ,105 ,הרימון
208 ,מוצא עילית ,106 ,השקד
208 ,מוצא עילית ,107 ,התפוח
208 ,מוצא עילית ,115 ,מבוא הגפן
208 ,מוצא עילית ,114 ,מבוא הלילך
208 ,מוצא עילית ,9000 ,מוצא עילית
208 ,מוצא עילית ,121 ,מעון הרופא
208 ,מוצא עילית ,117 ,מעלה הארז
208 ,מוצא עילית ,116 ,סמ החרוב
208 ,מוצא עילית ,6500 ,ש רסקו
635 ,מוקייבלה ,9000 ,מוקייבלה
1163 ,מורן ,103 ,אלה
1163 ,מורן ,104 ,אלון
1163 ,מורן ,159 ,אלקלעה
1163 ,מורן ,101 ,גפן
1163 ,מורן ,106 ,זית
1163 ,מורן ,9000 ,מורן
1163 ,מורן ,105 ,רימון
1163 ,מורן ,107 ,תאנה
1163 ,מורן ,102 ,תמר
1178 ,מורשת ,104 ,אריאל
1178 ,מורשת ,107 ,בית אל
1178 ,מורשת ,112 ,דביר
1178 ,מורשת ,111 ,הר הלבנון
1178 ,מורשת ,102 ,השילוח
1178 ,מורשת ,103 ,יפה נוף
1178 ,מורשת ,101 ,ירושלים
1178 ,מורשת ,110 ,כרמל
1178 ,מורשת ,108 ,לבונה
1178 ,מורשת ,106 ,מוריה
1178 ,מורשת ,9000 ,מורשת
1178 ,מורשת ,109 ,משעול הראל
1178 ,מורשת ,113 ,נוה שאנן
1178 ,מורשת ,117 ,עיר דוד
1178 ,מורשת ,105 ,ציון
1178 ,מורשת ,116 ,רחביה
1178 ,מורשת ,115 ,שלם
1178 ,מורשת ,114 ,תלפיות
606 ,מזור ,101 ,הגפן
606 ,מזור ,113 ,הדקל
606 ,מזור ,109 ,היוגב
606 ,מזור ,104 ,היסמין
606 ,מזור ,105 ,הכלנית
606 ,מזור ,112 ,המייסדים
606 ,מזור ,110 ,הנוטע
606 ,מזור ,107 ,הנרקיס
606 ,מזור ,102 ,הפרדס
606 ,מזור ,106 ,הצבעוני
606 ,מזור ,103 ,הרימון
606 ,מזור ,108 ,הרקפות
606 ,מזור ,111 ,השחר
606 ,מזור ,9000 ,מזור
606 ,מזור ,114 ,סמ האירוס
606 ,מזור ,115 ,סמ הבוקרים
28 ,מזכרת בתיה ,102 ,אברהם מרדכי לוין
28 ,מזכרת בתיה ,249 ,אהרונסון שרה
28 ,מזכרת בתיה ,150 ,אורן
28 ,מזכרת בתיה ,243 ,אידלסון בבה
28 ,מזכרת בתיה ,250 ,איזדורה דנקן
28 ,מזכרת בתיה ,151 ,אלה
28 ,מזכרת בתיה ,262 ,אלול רינה
28 ,מזכרת בתיה ,131 ,אלון יגאל
28 ,מזכרת בתיה ,170 ,אלוני דב
28 ,מזכרת בתיה ,168 ,אנפה
28 ,מזכרת בתיה ,152 ,ארז
28 ,מזכרת בתיה ,119 ,ארקין אליעזר
28 ,מזכרת בתיה ,260 ,אשבל חיה
28 ,מזכרת בתיה ,123 ,אשכול
28 ,מזכרת בתיה ,153 ,אשל
28 ,מזכרת בתיה ,266 ,אתר תרצה
28 ,מזכרת בתיה ,271 ,בארון דבורה
28 ,מזכרת בתיה ,175 ,בבילה אליהו
28 ,מזכרת בתיה ,129 ,בית און
28 ,מזכרת בתיה ,198 ,בן גוריון
28 ,מזכרת בתיה ,259 ,בנודיז רחל
28 ,מזכרת בתיה ,167 ,ברוך צבי ברנשטיין
28 ,מזכרת בתיה ,154 ,ברוש
28 ,מזכרת בתיה ,160 ,ברי"ל יחיאל
28 ,מזכרת בתיה ,189 ,גבעתי
28 ,מזכרת בתיה ,174 ,גולד יעקב
28 ,מזכרת בתיה ,263 ,גולדברג לאה
28 ,מזכרת בתיה ,241 ,גולדה מאיר
28 ,מזכרת בתיה ,273 ,גור בתיה
28 ,מזכרת בתיה ,166 ,גלמן אברהם יעקב
28 ,מזכרת בתיה ,147 ,גפן
28 ,מזכרת בתיה ,242 ,גרוסמן חייקה
28 ,מזכרת בתיה ,165 ,דב
28 ,מזכרת בתיה ,155 ,דובדבן
28 ,מזכרת בתיה ,158 ,דולב
28 ,מזכרת בתיה ,268 ,דמארי שושנה
28 ,מזכרת בתיה ,144 ,דקל
28 ,מזכרת בתיה ,206 ,דרך היין
28 ,מזכרת בתיה ,161 ,האחים ארקין
28 ,מזכרת בתיה ,221 ,האסיף
28 ,מזכרת בתיה ,220 ,הבציר
28 ,מזכרת בתיה ,117 ,הגורן
28 ,מזכרת בתיה ,122 ,הדרים
28 ,מזכרת בתיה ,197 ,ההגנה
28 ,מזכרת בתיה ,135 ,הכנסת אורחים
28 ,מזכרת בתיה ,108 ,הלזנר עמנואל
28 ,מזכרת בתיה ,255 ,הלן קלר
28 ,מזכרת בתיה ,138 ,המגן
28 ,מזכרת בתיה ,118 ,המייסדים
28 ,מזכרת בתיה ,224 ,המסיק
28 ,מזכרת בתיה ,136 ,הנוטרים
28 ,מזכרת בתיה ,195 ,הניצחון
28 ,מזכרת בתיה ,125 ,הנשיא שז"ר
28 ,מזכרת בתיה ,186 ,העצמאות
28 ,מזכרת בתיה ,192 ,הצבי
28 ,מזכרת בתיה ,128 ,הצנחנים
28 ,מזכרת בתיה ,223 ,הקטיף
28 ,מזכרת בתיה ,222 ,הקציר
28 ,מזכרת בתיה ,111 ,הרב מוהליבר
28 ,מזכרת בתיה ,180 ,הרשקוביץ
28 ,מזכרת בתיה ,106 ,השוטרים
28 ,מזכרת בתיה ,148 ,התאנה
28 ,מזכרת בתיה ,187 ,וויסברג שלמה
28 ,מזכרת בתיה ,272 ,וולך יונה
28 ,מזכרת בתיה ,256 ,וולפסון מילכה
28 ,מזכרת בתיה ,143 ,ורד
28 ,מזכרת בתיה ,130 ,זטוצקי יעקב
28 ,מזכרת בתיה ,247 ,זייד ציפורה
28 ,מזכרת בתיה ,146 ,זית
28 ,מזכרת בתיה ,171 ,זמיר
28 ,מזכרת בתיה ,149 ,חיטה
28 ,מזכרת בתיה ,172 ,חיים משה פרס
28 ,מזכרת בתיה ,261 ,חסיה המיילדת
28 ,מזכרת בתיה ,156 ,חרוב
28 ,מזכרת בתיה ,169 ,חרצית
28 ,מזכרת בתיה ,275 ,טיכו אנה
28 ,מזכרת בתיה ,110 ,יואל דב פרס
28 ,מזכרת בתיה ,173 ,יחזקאל לוין
28 ,מזכרת בתיה ,267 ,ילין תלמה
28 ,מזכרת בתיה ,132 ,יסמין
28 ,מזכרת בתיה ,6002 ,כיכר האיכר
28 ,מזכרת בתיה ,208 ,כפות תמרים
28 ,מזכרת בתיה ,115 ,לוי חיים
28 ,מזכרת בתיה ,276 ,לוי תנאי שרה
28 ,מזכרת בתיה ,183 ,לויטה אהרון זליג
28 ,מזכרת בתיה ,159 ,לילך
28 ,מזכרת בתיה ,162 ,לסקובסקי יעקב
28 ,מזכרת בתיה ,251 ,מארי קירי
28 ,מזכרת בתיה ,199 ,מבצע יכין
28 ,מזכרת בתיה ,9000 ,מזכרת בתיה
28 ,מזכרת בתיה ,185 ,מידון
28 ,מזכרת בתיה ,196 ,מלר חיים
28 ,מזכרת בתיה ,252 ,מרתה גרהאם
28 ,מזכרת בתיה ,107 ,משה לוין
28 ,מזכרת בתיה ,163 ,משה מלר
28 ,מזכרת בתיה ,201 ,משעול דיין משה
28 ,מזכרת בתיה ,200 ,משעול ספיר פנחס
28 ,מזכרת בתיה ,203 ,משעול שלמה
28 ,מזכרת בתיה ,202 ,משעול שרת משה
28 ,מזכרת בתיה ,6504 ,נאות הראשונים
28 ,מזכרת בתיה ,258 ,נאמן נחמה
28 ,מזכרת בתיה ,254 ,נודל אידה
28 ,מזכרת בתיה ,133 ,נופר
28 ,מזכרת בתיה ,176 ,נורית
28 ,מזכרת בתיה ,182 ,ניימן מרדכי
28 ,מזכרת בתיה ,104 ,נתיב השיירות
28 ,מזכרת בתיה ,194 ,סבידור מנחם
28 ,מזכרת בתיה ,244 ,סולד הנרייטה
28 ,מזכרת בתיה ,142 ,סיגלית
28 ,מזכרת בתיה ,191 ,סמ הבאר
28 ,מזכרת בתיה ,190 ,סמ ש"י
28 ,מזכרת בתיה ,178 ,סמטת נחום (נעים)
28 ,מזכרת בתיה ,246 ,סנש חנה
28 ,מזכרת בתיה ,188 ,פלמ"ח
28 ,מזכרת בתיה ,177 ,קאלגרי
28 ,מזכרת בתיה ,270 ,קדמן גורית
28 ,מזכרת בתיה ,134 ,קרן היסוד
28 ,מזכרת בתיה ,264 ,רביקוביץ דליה
28 ,מזכרת בתיה ,269 ,רובינא חנה
28 ,מזכרת בתיה ,164 ,רובינשטיין יהושע
28 ,מזכרת בתיה ,120 ,רודבסקי יוסף
28 ,מזכרת בתיה ,116 ,רוטשילד
28 ,מזכרת בתיה ,253 ,רום יעל
28 ,מזכרת בתיה ,140 ,רותם
28 ,מזכרת בתיה ,274 ,רחל המשוררת
28 ,מזכרת בתיה ,248 ,רחל ינאית בן צבי
28 ,מזכרת בתיה ,145 ,רימון
28 ,מזכרת בתיה ,139 ,רקפת
28 ,מזכרת בתיה ,6500 ,ש וילות
28 ,מזכרת בתיה ,109 ,שאול
28 ,מזכרת בתיה ,207 ,שביל התפוזים
28 ,מזכרת בתיה ,124 ,שד אליהו
28 ,מזכרת בתיה ,105 ,שד בגין מנחם
28 ,מזכרת בתיה ,113 ,שד ויצמן
28 ,מזכרת בתיה ,193 ,שד מוטה גור
28 ,מזכרת בתיה ,240 ,שד רפאל סויסה
28 ,מזכרת בתיה ,257 ,שווילי הדסה
28 ,מזכרת בתיה ,245 ,שוחט מניה
28 ,מזכרת בתיה ,127 ,שחם יואב
28 ,מזכרת בתיה ,6505 ,שכ בר לב
28 ,מזכרת בתיה ,121 ,שמיר דב
28 ,מזכרת בתיה ,265 ,שמר נעמי
28 ,מזכרת בתיה ,157 ,שקד
28 ,מזכרת בתיה ,181 ,שקולניק אפרים
28 ,מזכרת בתיה ,184 ,שרשבסקי גרשון
28 ,מזכרת בתיה ,141 ,תמר
28 ,מזכרת בתיה ,103 ,תרמ"ג
104 ,מזרע ,9000 ,מזרע
517 ,מזרעה ,152 ,א-דבעה
517 ,מזרעה ,107 ,אבו בקר אלסדיק
517 ,מזרעה ,113 ,אבן סינא
517 ,מזרעה ,117 ,אבן רושד
517 ,מזרעה ,149 ,איבן ח'לדון
517 ,מזרעה ,151 ,איבן חיאן
517 ,מזרעה ,150 ,אל-איספהאני
517 ,מזרעה ,148 ,אל-בירוני
517 ,מזרעה ,120 ,אלאודבאא
517 ,מזרעה ,130 ,אלאזהאר
517 ,מזרעה ,101 ,אלאנביאא
517 ,מזרעה ,106 ,אלאנסאר
517 ,מזרעה ,102 ,אלאסבאט
517 ,מזרעה ,125 ,אלאסמעי
517 ,מזרעה ,127 ,אלאשג'אר
517 ,מזרעה ,144 ,אלבאשא
517 ,מזרעה ,126 ,אלבוחתורי
517 ,מזרעה ,122 ,אלג'אחז
517 ,מזרעה ,142 ,אלואדי
517 ,מזרעה ,128 ,אלוורוד
517 ,מזרעה ,118 ,אלזהראוי
517 ,מזרעה ,132 ,אלזיתון
517 ,מזרעה ,115 ,אלח'וארזמי
517 ,מזרעה ,124 ,אלח'נסאא
517 ,מזרעה ,103 ,אלחואריין
517 ,מזרעה ,145 ,אלטאחון
517 ,מזרעה ,136 ,אליאסמין
517 ,מזרעה ,100 ,אלמואמנין
517 ,מזרעה ,105 ,אלמוהאג'רין
517 ,מזרעה ,121 ,אלמותנביא
517 ,מזרעה ,146 ,אלמנארה
517 ,מזרעה ,139 ,אלמעסרה
517 ,מזרעה ,141 ,אלמקאטע
517 ,מזרעה ,131 ,אלנח'יל
517 ,מזרעה ,135 ,אלנרג'ס
517 ,מזרעה ,133 ,אלסבאר
517 ,מזרעה ,104 ,אלסחאבה
517 ,מזרעה ,111 ,אלעולמאא
517 ,מזרעה ,112 ,אלפוקהאא
517 ,מזרעה ,123 ,אלפרזדק
517 ,מזרעה ,116 ,אלקורטבי
517 ,מזרעה ,137 ,אלקנאטר
517 ,מזרעה ,114 ,אלראזי
517 ,מזרעה ,129 ,אלריאחין
517 ,מזרעה ,143 ,אלשאחוט
517 ,מזרעה ,119 ,אלשועראא
517 ,מזרעה ,134 ,אלתין
517 ,מזרעה ,138 ,דאר אלווקף
517 ,מזרעה ,9000 ,מזרעה
517 ,מזרעה ,147 ,סלאח א-דין
517 ,מזרעה ,110 ,עומר בן אלח'טאב
517 ,מזרעה ,109 ,עותמאן בן עפאן
517 ,מזרעה ,108 ,עלי בן אבו טאלב
517 ,מזרעה ,140 ,ראס אלמקסר
3599 ,מחולה ,9000 ,מחולה
1411 ,מחנה הילה ,9000 ,מחנה הילה
1418 ,מחנה טלי ,9000 ,מחנה טלי
1413 ,מחנה יהודית ,9000 ,מחנה יהודית
1416 ,מחנה יוכבד ,9000 ,מחנה יוכבד
1415 ,מחנה יפה ,9000 ,מחנה יפה
1196 ,מחנה יתיר ,9000 ,מחנה יתיר
1414 ,מחנה מרים ,9000 ,מחנה מרים
1412 ,מחנה תל נוף ,9000 ,מחנה תל נוף
308 ,מחניים ,101 ,בית ראשון במולדת
308 ,מחניים ,9000 ,מחניים
776 ,מחסיה ,9000 ,מחסיה
43 ,מטולה ,6502 ,אזור תעשייה
43 ,מטולה ,138 ,האיכר
43 ,מטולה ,115 ,האירוס
43 ,מטולה ,103 ,הארזים
43 ,מטולה ,125 ,האשד
43 ,מטולה ,126 ,הבלוע
43 ,מטולה ,130 ,הגולן
43 ,מטולה ,101 ,הגורן
43 ,מטולה ,136 ,הגפן
43 ,מטולה ,108 ,הדובדבן
43 ,מטולה ,124 ,הזית
43 ,מטולה ,137 ,החורשה
43 ,מטולה ,112 ,החצבים
43 ,מטולה ,127 ,החקורה
43 ,מטולה ,105 ,החרמון
43 ,מטולה ,122 ,הטחנה
43 ,מטולה ,128 ,הכלנית
43 ,מטולה ,106 ,הלבנון
43 ,מטולה ,121 ,המורג
43 ,מטולה ,109 ,המעיין
43 ,מטולה ,129 ,המפל
43 ,מטולה ,119 ,הנדיב
43 ,מטולה ,117 ,הנורית
43 ,מטולה ,116 ,הנרקיס
43 ,מטולה ,131 ,הסביון
43 ,מטולה ,132 ,הסחלב
43 ,מטולה ,133 ,הערבה
43 ,מטולה ,110 ,הצבעונים
43 ,מטולה ,6509 ,הר הצפייה
43 ,מטולה ,102 ,הראשונים
43 ,מטולה ,6500 ,הרחבה חדשה
43 ,מטולה ,134 ,הרימון
43 ,מטולה ,123 ,הרקפת
43 ,מטולה ,141 ,השמורה
43 ,מטולה ,111 ,התאנה
43 ,מטולה ,135 ,התנור
43 ,מטולה ,9000 ,מטולה
43 ,מטולה ,113 ,מעלה ארז
43 ,מטולה ,118 ,מעלה דדו
43 ,מטולה ,114 ,מעלה הצפיה
43 ,מטולה ,104 ,מצפה החולה
43 ,מטולה ,107 ,מצפה נפתלי
43 ,מטולה ,139 ,סמ הבוסתן
43 ,מטולה ,120 ,עיון
43 ,מטולה ,6505 ,ש דרום
43 ,מטולה ,6506 ,ש עמידר
43 ,מטולה ,6508 ,שכ הזיתים
43 ,מטולה ,6501 ,שכ הרחבה ג
822 ,מטע ,9000 ,מטע
1128 ,מי עמי ,100 ,האגס
1128 ,מי עמי ,101 ,האורן
1128 ,מי עמי ,102 ,האלון
1128 ,מי עמי ,103 ,הבוסתן
1128 ,מי עמי ,104 ,ההיאחזות
1128 ,מי עמי ,105 ,החורשה
1128 ,מי עמי ,106 ,החצב
1128 ,מי עמי ,110 ,המלאכה
1128 ,מי עמי ,107 ,הרקפות
1128 ,מי עמי ,108 ,התורמוס
1128 ,מי עמי ,9000 ,מי עמי
1128 ,מי עמי ,109 ,פרחי בר
2054 ,מיטב ,9000 ,מיטב
1154 ,מיטל ,9000 ,מיטל
649 ,מייסר ,9000 ,מייסר
4019 ,מיצר ,9000 ,מיצר
1282 ,מירב ,9000 ,מירב
607 ,מירון ,109 ,בר יוחאי
607 ,מירון ,103 ,הבנים
607 ,מירון ,105 ,הגפן
607 ,מירון ,106 ,הזוהר
607 ,מירון ,104 ,הזית
607 ,מירון ,108 ,החרוב
607 ,מירון ,102 ,המייסדים
607 ,מירון ,113 ,המעיין
607 ,מירון ,114 ,הרב צבי נריה
607 ,מירון ,107 ,התנאים
607 ,מירון ,111 ,חטיבה שבע
607 ,מירון ,9000 ,מירון
607 ,מירון ,112 ,מעלה הרשב"י
607 ,מירון ,110 ,משחררי מירון
731 ,מישר ,106 ,האנפה
731 ,מישר ,101 ,הדוכיפת
731 ,מישר ,102 ,הדרור
731 ,מישר ,104 ,הזמיר
731 ,מישר ,108 ,העפרוני
731 ,מישר ,105 ,הצופית
731 ,מישר ,103 ,השחף
731 ,מישר ,107 ,השלדג
731 ,מישר ,9000 ,מישר
731 ,מישר ,6500 ,שיכון בנים
1268 ,מיתר ,334 ,אורן
1268 ,מיתר ,507 ,איה
1268 ,מיתר ,281 ,אילות
1268 ,מיתר ,212 ,אירוס
1268 ,מיתר ,335 ,אלה
1268 ,מיתר ,336 ,אלון
1268 ,מיתר ,300 ,אנפה
1268 ,מיתר ,337 ,ארז
1268 ,מיתר ,285 ,אשכול
1268 ,מיתר ,203 ,אשל
1268 ,מיתר ,250 ,בארי
1268 ,מיתר ,125 ,בית אשל
1268 ,מיתר ,320 ,ברבור
1268 ,מיתר ,112 ,בשמת
1268 ,מיתר ,251 ,גאון הירדן
1268 ,מיתר ,252 ,גבולות
1268 ,מיתר ,503 ,גבתון
1268 ,מיתר ,201 ,גפן
1268 ,מיתר ,108 ,גרופית
1268 ,מיתר ,124 ,גרר
1268 ,מיתר ,290 ,דוכיפת
1268 ,מיתר ,509 ,דיה
1268 ,מיתר ,253 ,דן
1268 ,מיתר ,254 ,דפנה
1268 ,מיתר ,508 ,דרורית
1268 ,מיתר ,275 ,דרך הארץ
1268 ,מיתר ,301 ,דרך הבשור
1268 ,מיתר ,280 ,דרך הדרור
1268 ,מיתר ,255 ,דרך הראל
1268 ,מיתר ,101 ,דרך יתיר
1268 ,מיתר ,126 ,דרך מיתר
1268 ,מיתר ,272 ,הדס
1268 ,מיתר ,295 ,זהבן
1268 ,מיתר ,102 ,זוהר
1268 ,מיתר ,271 ,זית
1268 ,מיתר ,506 ,חוגלה
1268 ,מיתר ,294 ,חוחית
1268 ,מיתר ,505 ,חופית
1268 ,מיתר ,113 ,חלוצה
1268 ,מיתר ,211 ,חלמונית
1268 ,מיתר ,121 ,חלמיש
1268 ,מיתר ,325 ,חסידה
1268 ,מיתר ,213 ,חצב
1268 ,מיתר ,107 ,חצבה
1268 ,מיתר ,522 ,חצוצרן
1268 ,מיתר ,256 ,חצרים
1268 ,מיתר ,520 ,ינשוף
1268 ,מיתר ,322 ,יסעור
1268 ,מיתר ,515 ,יעל
1268 ,מיתר ,311 ,יעלים
1268 ,מיתר ,310 ,כובשים
1268 ,מיתר ,517 ,כחל
1268 ,מיתר ,258 ,כיסופים
1268 ,מיתר ,330 ,כלנית
1268 ,מיתר ,208 ,כרכום
1268 ,מיתר ,298 ,לבנית
1268 ,מיתר ,504 ,מגלן
1268 ,מיתר ,260 ,מגן
1268 ,מיתר ,302 ,מורג
1268 ,מיתר ,9000 ,מיתר
1268 ,מיתר ,115 ,ממשית
1268 ,מיתר ,103 ,מצדה
1268 ,מיתר ,259 ,משאבים
1268 ,מיתר ,221 ,משמר
1268 ,מיתר ,257 ,משעול יפתח
1268 ,מיתר ,276 ,משעול לאה
1268 ,מיתר ,265 ,משעול פלמ"ח
1268 ,מיתר ,262 ,נגבה
1268 ,מיתר ,105 ,נועם
1268 ,מיתר ,331 ,נורית
1268 ,מיתר ,296 ,נחליאלי
1268 ,מיתר ,123 ,ניצנה
1268 ,מיתר ,261 ,נירים
1268 ,מיתר ,510 ,נשר
1268 ,מיתר ,297 ,סלעית
1268 ,מיתר ,289 ,סנונית
1268 ,מיתר ,263 ,סעד
1268 ,מיתר ,104 ,עבדת
1268 ,מיתר ,324 ,עגור
1268 ,מיתר ,304 ,עופרים
1268 ,מיתר ,502 ,עורבני
1268 ,מיתר ,511 ,עיט
1268 ,מיתר ,512 ,עיטם
1268 ,מיתר ,309 ,עין בוקק
1268 ,מיתר ,308 ,עין זיק
1268 ,מיתר ,303 ,עין מור
1268 ,מיתר ,313 ,עין נטפים
1268 ,מיתר ,209 ,עירית
1268 ,מיתר ,264 ,עלומים
1268 ,מיתר ,292 ,עפרוני
1268 ,מיתר ,122 ,עציון גבר
1268 ,מיתר ,119 ,עצמון
1268 ,מיתר ,111 ,עקרב
1268 ,מיתר ,282 ,ערוגות
1268 ,מיתר ,286 ,ערוד
1268 ,מיתר ,312 ,פארן
1268 ,מיתר ,210 ,פרג
1268 ,מיתר ,293 ,פשוש
1268 ,מיתר ,202 ,צאלה
1268 ,מיתר ,306 ,צאלים
1268 ,מיתר ,117 ,צבאים
1268 ,מיתר ,516 ,צבי
1268 ,מיתר ,333 ,צבעוני
1268 ,מיתר ,291 ,צופית
1268 ,מיתר ,283 ,צופר
1268 ,מיתר ,518 ,צוקית
1268 ,מיתר ,307 ,צין
1268 ,מיתר ,120 ,צניפים
1268 ,מיתר ,116 ,צקלג
1268 ,מיתר ,106 ,קדש ברנע
1268 ,מיתר ,519 ,קורא
1268 ,מיתר ,525 ,קטה
1268 ,מיתר ,109 ,קטורה
1268 ,מיתר ,127 ,קנאים
1268 ,מיתר ,118 ,קציעות
1268 ,מיתר ,513 ,קרקל
1268 ,מיתר ,514 ,ראם
1268 ,מיתר ,266 ,רביבים
1268 ,מיתר ,273 ,רימון
1268 ,מיתר ,267 ,רמים
1268 ,מיתר ,268 ,רעים
1268 ,מיתר ,332 ,רקפת
1268 ,מיתר ,114 ,שבטה
1268 ,מיתר ,521 ,שדמית
1268 ,מיתר ,288 ,שדרות הזמיר
1268 ,מיתר ,287 ,שדרות היונה
1268 ,מיתר ,214 ,שדרות המייסדים
1268 ,מיתר ,270 ,שדרות השלום
1268 ,מיתר ,216 ,שדרות חברון
1268 ,מיתר ,500 ,שדרות כרמית
1268 ,מיתר ,215 ,שדרות עומרים
1268 ,מיתר ,269 ,שובל
1268 ,מיתר ,323 ,שחף
1268 ,מיתר ,207 ,שיזף
1268 ,מיתר ,204 ,שיטה
1268 ,מיתר ,6501 ,שכ הדרור
1268 ,מיתר ,6502 ,שכ הכלניות
1268 ,מיתר ,6500 ,שכ רבין
1268 ,מיתר ,299 ,שלדג
1268 ,מיתר ,501 ,שלו
1268 ,מיתר ,205 ,שקד
1268 ,מיתר ,305 ,שקמה
1268 ,מיתר ,523 ,שקנאי
1268 ,מיתר ,274 ,תאנה
1268 ,מיתר ,524 ,תור
1268 ,מיתר ,220 ,תמירון
1268 ,מיתר ,110 ,תמנע
1268 ,מיתר ,206 ,תמר
3614 ,מכורה ,9000 ,מכורה
1343 ,מכחול ,9000 ,מכחול
382 ,מכמורת ,103 ,אלמוג
382 ,מכמורת ,104 ,דולפין
382 ,מכמורת ,106 ,דרך החוף
382 ,מכמורת ,111 ,דרך הים
382 ,מכמורת ,114 ,הדולב
382 ,מכמורת ,107 ,המפתח
382 ,מכמורת ,105 ,העוגן
382 ,מכמורת ,108 ,הפרדס
382 ,מכמורת ,102 ,השחף
382 ,מכמורת ,112 ,כוכב הים
382 ,מכמורת ,9000 ,מכמורת
382 ,מכמורת ,109 ,מעלה האשלים
382 ,מכמורת ,101 ,סמ הדייגים
382 ,מכמורת ,115 ,שביל השקיעה
382 ,מכמורת ,113 ,שונית
382 ,מכמורת ,110 ,שלדג
1202 ,מכמנים ,104 ,איפה הילד
1202 ,מכמנים ,112 ,הגבעטרון
1202 ,מכמנים ,110 ,הדודאים
1202 ,מכמנים ,111 ,התרנגולים
1202 ,מכמנים ,101 ,זמר עברי
1202 ,מכמנים ,107 ,להקת הנחל
1202 ,מכמנים ,106 ,להקת כוורת
1202 ,מכמנים ,108 ,להקת משינה
1202 ,מכמנים ,109 ,להקת צלילי הכרם
1202 ,מכמנים ,105 ,להקת תיסלם
1202 ,מכמנים ,103 ,להקת תמוז
1202 ,מכמנים ,9000 ,מכמנים
1202 ,מכמנים ,113 ,שביל אבו בלאל
1202 ,מכמנים ,114 ,שביל התעשייה
1202 ,מכמנים ,102 ,שלישיית גשר הירקון
164 ,מלאה ,104 ,הגפן
164 ,מלאה ,101 ,הזית
164 ,מלאה ,9000 ,מלאה
164 ,מלאה ,103 ,רימון
164 ,מלאה ,102 ,תאנה
2044 ,מלילות ,9000 ,מלילות
596 ,מלכיה ,9000 ,מלכיה
2030 ,מנוחה ,104 ,הבאר
2030 ,מנוחה ,103 ,הבנים
2030 ,מנוחה ,105 ,הכפר
2030 ,מנוחה ,102 ,המגל
2030 ,מנוחה ,101 ,המייסדים
2030 ,מנוחה ,9000 ,מנוחה
1174 ,מנוף ,9000 ,מנוף
1205 ,מנות ,107 ,אודם
1205 ,מנות ,103 ,ברקת
1205 ,מנות ,106 ,יהלום
1205 ,מנות ,6001 ,כיכר אבני החושן
1205 ,מנות ,6002 ,כיכר בזלת
1205 ,מנות ,9000 ,מנות
1205 ,מנות ,105 ,נופך
1205 ,מנות ,108 ,סמטת אחלמה
1205 ,מנות ,109 ,סמטת לשם
1205 ,מנות ,104 ,ספיר
1205 ,מנות ,101 ,שהם
1205 ,מנות ,102 ,תרשיש
48 ,מנחמיה ,102 ,הבנים
48 ,מנחמיה ,108 ,הגפן
48 ,מנחמיה ,120 ,הדוכיפת
48 ,מנחמיה ,113 ,הדקל
48 ,מנחמיה ,104 ,ההדרים
48 ,מנחמיה ,111 ,הזיתים
48 ,מנחמיה ,103 ,החקלאי
48 ,מנחמיה ,110 ,הירדן
48 ,מנחמיה ,122 ,הכלנית
48 ,מנחמיה ,101 ,המייסדים
48 ,מנחמיה ,112 ,הרימון
48 ,מנחמיה ,109 ,הרקפת
48 ,מנחמיה ,121 ,התאנה
48 ,מנחמיה ,9000 ,מנחמיה
48 ,מנחמיה ,107 ,משעול הדרור
48 ,מנחמיה ,114 ,משעול הזמיר
48 ,מנחמיה ,118 ,משעול הסנונית
48 ,מנחמיה ,116 ,משעול העפרוני
48 ,מנחמיה ,117 ,משעול כנרית
48 ,מנחמיה ,115 ,משעול שלדג
48 ,מנחמיה ,119 ,עמק הירדן
48 ,מנחמיה ,106 ,קרן היסוד
48 ,מנחמיה ,6500 ,ש וילות
48 ,מנחמיה ,105 ,שבעת הבתים
48 ,מנחמיה ,6501 ,שכ עופר
347 ,מנרה ,101 ,בית ראשון במולדת
347 ,מנרה ,9000 ,מנרה
994 ,מנשית זבדה ,102 ,האלון
994 ,מנשית זבדה ,106 ,הורדים
994 ,מנשית זבדה ,107 ,הזית
994 ,מנשית זבדה ,108 ,הטווס
994 ,מנשית זבדה ,109 ,היונה
994 ,מנשית זבדה ,101 ,המלאכה
994 ,מנשית זבדה ,104 ,העם
994 ,מנשית זבדה ,103 ,השלום
994 ,מנשית זבדה ,105 ,התקווה
994 ,מנשית זבדה ,9000 ,מנשית זבדה
1258 ,מסד ,9000 ,מסד
263 ,מסדה ,101 ,בית ראשון במולדת
263 ,מסדה ,9000 ,מסדה
298 ,מסילות ,9000 ,מסילות
742 ,מסילת ציון ,111 ,הר יעלה
742 ,מסילת ציון ,112 ,הר סנסן
742 ,מסילת ציון ,113 ,הר עוזרר
742 ,מסילת ציון ,107 ,מלבר
742 ,מסילת ציון ,9000 ,מסילת ציון
742 ,מסילת ציון ,104 ,סמ מאלה
742 ,מסילת ציון ,103 ,קוצ'ין
742 ,מסילת ציון ,105 ,קרלה
748 ,מסלול ,9000 ,מסלול
4203 ,מסעדה ,9000 ,מסעדה
939 ,מסעודין אל-עזאזמה ,9000 ,מסעודין אל-עזאזמה
197 ,מעברות ,9000 ,מעברות
1082 ,מעגלים ,133 ,אלון
1082 ,מעגלים ,134 ,אשל
1082 ,מעגלים ,135 ,גפן
1082 ,מעגלים ,117 ,דוכיפת
1082 ,מעגלים ,118 ,דרור
1082 ,מעגלים ,106 ,האירוס
1082 ,מעגלים ,101 ,החבצלת
1082 ,מעגלים ,112 ,הלילך
1082 ,מעגלים ,102 ,הנרקיס
1082 ,מעגלים ,104 ,הערבה
1082 ,מעגלים ,103 ,השיטה
1082 ,מעגלים ,113 ,יקינטון
1082 ,מעגלים ,9000 ,מעגלים
1082 ,מעגלים ,110 ,משעול חצב
1082 ,מעגלים ,108 ,משעול חרצית
1082 ,מעגלים ,109 ,משעול כלנית
1082 ,מעגלים ,111 ,משעול נורית
1082 ,מעגלים ,132 ,משעול צאלון
1082 ,מעגלים ,126 ,נחל בוקק
1082 ,מעגלים ,124 ,נחל בקע
1082 ,מעגלים ,121 ,נחל גרר
1082 ,מעגלים ,120 ,נחל הבשור
1082 ,מעגלים ,129 ,נחל עובדת
1082 ,מעגלים ,125 ,נחל ערוגות
1082 ,מעגלים ,128 ,נחל פארן
1082 ,מעגלים ,130 ,נחל פרסה
1082 ,מעגלים ,122 ,נחל צאלים
1082 ,מעגלים ,123 ,נחל צין
1082 ,מעגלים ,127 ,נחל שקמה
1082 ,מעגלים ,114 ,סביון
1082 ,מעגלים ,115 ,סיגלית
1082 ,מעגלים ,107 ,צבעוני
1082 ,מעגלים ,131 ,רימון
1082 ,מעגלים ,105 ,רקפת
1082 ,מעגלים ,116 ,שחף
1082 ,מעגלים ,119 ,שלדג
678 ,מעגן ,101 ,בית ראשון במולדת
678 ,מעגן ,9000 ,מעגן
694 ,מעגן מיכאל ,101 ,אולפן קיבוץ
694 ,מעגן מיכאל ,7001 ,ביס שדה
694 ,מעגן מיכאל ,9000 ,מעגן מיכאל
272 ,מעוז חיים ,101 ,בית ראשון במולדת
272 ,מעוז חיים ,9000 ,מעוז חיים
3657 ,מעון ,9000 ,מעון
570 ,מעונה ,105 ,אלון
570 ,מעונה ,108 ,הברוש
570 ,מעונה ,102 ,הגפן
570 ,מעונה ,103 ,הדולב
570 ,מעונה ,101 ,הזית
570 ,מעונה ,106 ,הכרמל
570 ,מעונה ,107 ,כליל החורש
570 ,מעונה ,9000 ,מעונה
570 ,מעונה ,104 ,תמר
518 ,מעיליא ,145 ,אל נרגס
518 ,מעיליא ,153 ,אל-ליימון
518 ,מעיליא ,139 ,אלאמל
518 ,מעיליא ,134 ,אלבטחה
518 ,מעיליא ,116 ,אלביאדה
518 ,מעיליא ,115 ,אלביאדר
518 ,מעיליא ,114 ,אלברקה
518 ,מעיליא ,133 ,אלגמאלון
518 ,מעיליא ,123 ,אלגראני
518 ,מעיליא ,113 ,אלדבשה
518 ,מעיליא ,155 ,אלדיר
518 ,מעיליא ,144 ,אלדלב
518 ,מעיליא ,147 ,אלורוד
518 ,מעיליא ,132 ,אלחדיקה
518 ,מעיליא ,120 ,אלחמרה
518 ,מעיליא ,140 ,אלחסאד
518 ,מעיליא ,125 ,אלטלעה
518 ,מעיליא ,110 ,אליאסמין
518 ,מעיליא ,154 ,אלכניסה
518 ,מעיליא ,135 ,אלכרום
518 ,מעיליא ,119 ,אלמגארה
518 ,מעיליא ,131 ,אלמדרסה
518 ,מעיליא ,112 ,אלמזארע
518 ,מעיליא ,117 ,אלמטאמיט
518 ,מעיליא ,129 ,אלמראח
518 ,מעיליא ,128 ,אלמרג
518 ,מעיליא ,124 ,אלנכיל
518 ,מעיליא ,150 ,אלנקארה
518 ,מעיליא ,118 ,אלסיידה
518 ,מעיליא ,137 ,אלסינדיאני
518 ,מעיליא ,152 ,אלסנובר
518 ,מעיליא ,121 ,אלעמרי
518 ,מעיליא ,151 ,אלעסל
518 ,מעיליא ,149 ,אלפרח
518 ,מעיליא ,156 ,אלקדיס באסיליוס
518 ,מעיליא ,136 ,אלקדיסה תיריזה
518 ,מעיליא ,109 ,אלקלעה
518 ,מעיליא ,138 ,אלרומאן
518 ,מעיליא ,148 ,אלריחאן
518 ,מעיליא ,141 ,אלתינה
518 ,מעיליא ,111 ,באב אלנסב
518 ,מעיליא ,126 ,באב אלסיאקל
518 ,מעיליא ,108 ,בורנאד
518 ,מעיליא ,130 ,גובראן חליל גובראן
518 ,מעיליא ,122 ,דבשת בולוס
518 ,מעיליא ,127 ,דרב אלואדי
518 ,מעיליא ,146 ,דרב אלסולטאן
518 ,מעיליא ,105 ,חסונה
518 ,מעיליא ,104 ,כרם אלזיתון
518 ,מעיליא ,103 ,כרם גדבה
518 ,מעיליא ,143 ,כרם סאלם
518 ,מעיליא ,102 ,מאר אליאס
518 ,מעיליא ,107 ,מונפורט
518 ,מעיליא ,9000 ,מעיליא
518 ,מעיליא ,106 ,נוטרדאם
518 ,מעיליא ,101 ,רבאע סאלח
416 ,מעין ברוך ,9000 ,מעין ברוך
290 ,מעין צבי ,9000 ,מעין צבי
3616 ,מעלה אדומים ,103 ,אבני החושן
3616 ,מעלה אדומים ,158 ,אגן הסהר
3616 ,מעלה אדומים ,203 ,אוגדה
3616 ,מעלה אדומים ,210 ,אופירה
3616 ,מעלה אדומים ,159 ,איבי הנחל
3616 ,מעלה אדומים ,216 ,בית בלתין
3616 ,מעלה אדומים ,180 ,בת נדיב
3616 ,מעלה אדומים ,214 ,גבעת המיסדים
3616 ,מעלה אדומים ,220 ,דקלה
3616 ,מעלה אדומים ,132 ,דרך הגיא
3616 ,מעלה אדומים ,161 ,דרך הר הבית
3616 ,מעלה אדומים ,200 ,דרך הר הצופים
3616 ,מעלה אדומים ,211 ,דרך ימית
3616 ,מעלה אדומים ,130 ,דרך מדבר יהודה
3616 ,מעלה אדומים ,145 ,דרך מצפה נבו
3616 ,מעלה אדומים ,141 ,דרך קדם
3616 ,מעלה אדומים ,233 ,האבן
3616 ,מעלה אדומים ,192 ,האורן
3616 ,מעלה אדומים ,181 ,האזוב
3616 ,מעלה אדומים ,182 ,האלה
3616 ,מעלה אדומים ,246 ,האלמוג
3616 ,מעלה אדומים ,224 ,הארד
3616 ,מעלה אדומים ,183 ,האשל
3616 ,מעלה אדומים ,250 ,הבדולח
3616 ,מעלה אדומים ,244 ,הבזלת
3616 ,מעלה אדומים ,235 ,הגביש
3616 ,מעלה אדומים ,184 ,הגומא
3616 ,מעלה אדומים ,242 ,הגזית
3616 ,מעלה אדומים ,185 ,הגילגל
3616 ,מעלה אדומים ,232 ,הגיר
3616 ,מעלה אדומים ,236 ,הגעש
3616 ,מעלה אדומים ,231 ,הגרניט
3616 ,מעלה אדומים ,118 ,הגתית
3616 ,מעלה אדומים ,186 ,הדקל
3616 ,מעלה אדומים ,222 ,הזוגן
3616 ,מעלה אדומים ,153 ,הזיתים
3616 ,מעלה אדומים ,252 ,החומה
3616 ,מעלה אדומים ,154 ,החורן
3616 ,מעלה אדומים ,113 ,החליל
3616 ,מעלה אדומים ,187 ,החלמונית
3616 ,מעלה אדומים ,239 ,החלמיש
3616 ,מעלה אדומים ,163 ,החצוצרה
3616 ,מעלה אדומים ,228 ,החרסית
3616 ,מעלה אדומים ,241 ,הכורכר
3616 ,מעלה אדומים ,112 ,הכינור
3616 ,מעלה אדומים ,188 ,הכרכום
3616 ,מעלה אדומים ,133 ,המצדים
3616 ,מעלה אדומים ,225 ,המצוק
3616 ,מעלה אדומים ,167 ,המצילתים
3616 ,מעלה אדומים ,219 ,המרווה
3616 ,מעלה אדומים ,117 ,הנבל
3616 ,מעלה אדומים ,247 ,הנחושת
3616 ,מעלה אדומים ,101 ,הנחלים
3616 ,מעלה אדומים ,249 ,הנטיף
3616 ,מעלה אדומים ,227 ,הניקרה
3616 ,מעלה אדומים ,116 ,העוגב
3616 ,מעלה אדומים ,191 ,העירית
3616 ,מעלה אדומים ,165 ,הענבל
3616 ,מעלה אדומים ,258 ,הענבר
3616 ,מעלה אדומים ,193 ,הערבה
3616 ,מעלה אדומים ,137 ,העשור
3616 ,מעלה אדומים ,255 ,הפסנתר
3616 ,מעלה אדומים ,164 ,הפעמון
3616 ,מעלה אדומים ,194 ,הפרג
3616 ,מעלה אדומים ,248 ,הצוק
3616 ,מעלה אדומים ,245 ,הצור
3616 ,מעלה אדומים ,238 ,הצורן
3616 ,מעלה אדומים ,230 ,הציפחה
3616 ,מעלה אדומים ,195 ,הצלף
3616 ,מעלה אדומים ,115 ,הצלצל
3616 ,מעלה אדומים ,217 ,הצפצפה
3616 ,מעלה אדומים ,226 ,הקירטון
3616 ,מעלה אדומים ,196 ,הקנה
3616 ,מעלה אדומים ,114 ,הקרן
3616 ,מעלה אדומים ,166 ,הקתרוס
3616 ,מעלה אדומים ,151 ,הר גדור
3616 ,מעלה אדומים ,259 ,הר הלבונה
3616 ,מעלה אדומים ,150 ,הר מיכוור
3616 ,מעלה אדומים ,156 ,הר סרטבה
3616 ,מעלה אדומים ,197 ,הרותם
3616 ,מעלה אדומים ,229 ,הרכס
3616 ,מעלה אדומים ,243 ,השונית
3616 ,מעלה אדומים ,140 ,השופר
3616 ,מעלה אדומים ,234 ,השחם
3616 ,מעלה אדומים ,199 ,השיזף
3616 ,מעלה אדומים ,172 ,השיטה
3616 ,מעלה אדומים ,240 ,השיש
3616 ,מעלה אדומים ,131 ,השמינית
3616 ,מעלה אדומים ,173 ,השקמה
3616 ,מעלה אדומים ,157 ,השרון
3616 ,מעלה אדומים ,174 ,השרך
3616 ,מעלה אדומים ,162 ,התוף
3616 ,מעלה אדומים ,198 ,התלתן
3616 ,מעלה אדומים ,179 ,חגוי הסלע
3616 ,מעלה אדומים ,175 ,חוט השני
3616 ,מעלה אדומים ,215 ,חולית
3616 ,מעלה אדומים ,251 ,חלוקי הנחל
3616 ,מעלה אדומים ,206 ,חרובית
3616 ,מעלה אדומים ,138 ,יהלום
3616 ,מעלה אדומים ,155 ,כוכב הירדן
3616 ,מעלה אדומים ,6001 ,ככר יהלום
3616 ,מעלה אדומים ,146 ,מבוא בורות המים
3616 ,מעלה אדומים ,261 ,מבוא האורים
3616 ,מעלה אדומים ,265 ,מבוא האלון
3616 ,מעלה אדומים ,260 ,מבוא האשלג
3616 ,מעלה אדומים ,102 ,מבוא הגבים
3616 ,מעלה אדומים ,134 ,מבוא הורקניה
3616 ,מעלה אדומים ,218 ,מבוא המיתנן
3616 ,מעלה אדומים ,189 ,מבוא המלוח
3616 ,מעלה אדומים ,257 ,מבוא המשרוקית
3616 ,מעלה אדומים ,190 ,מבוא הסוף
3616 ,מעלה אדומים ,264 ,מבוא הר הזיתים
3616 ,מעלה אדומים ,135 ,מבוא הרודיון
3616 ,מעלה אדומים ,256 ,מבוא השליש
3616 ,מעלה אדומים ,262 ,מבוא יפה נוף
3616 ,מעלה אדומים ,263 ,מבוא נחל ערוגות
3616 ,מעלה אדומים ,149 ,מדליקי המשואות
3616 ,מעלה אדומים ,9000 ,מעלה אדומים
3616 ,מעלה אדומים ,223 ,נאות סיני
3616 ,מעלה אדומים ,221 ,נביעות
3616 ,מעלה אדומים ,237 ,נופי הסלע
3616 ,מעלה אדומים ,129 ,נתיב אפיקים
3616 ,מעלה אדומים ,109 ,נתיב האפוד
3616 ,מעלה אדומים ,202 ,נתיב העשרה
3616 ,מעלה אדומים ,136 ,נתיב הקרונית
3616 ,מעלה אדומים ,152 ,נתיב משואה
3616 ,מעלה אדומים ,205 ,סופה
3616 ,מעלה אדומים ,108 ,סמ אודם
3616 ,מעלה אדומים ,106 ,סמ ברקת
3616 ,מעלה אדומים ,111 ,סמ לשם
3616 ,מעלה אדומים ,105 ,סמ נופך
3616 ,מעלה אדומים ,128 ,סמ נחל אוג
3616 ,מעלה אדומים ,127 ,סמ נחל בוקק
3616 ,מעלה אדומים ,126 ,סמ נחל גורפן
3616 ,מעלה אדומים ,125 ,סמ נחל דרגות
3616 ,מעלה אדומים ,124 ,סמ נחל הרדוף
3616 ,מעלה אדומים ,123 ,סמ נחל ורדית
3616 ,מעלה אדומים ,122 ,סמ נחל זוהר
3616 ,מעלה אדומים ,121 ,סמ נחל חבר
3616 ,מעלה אדומים ,120 ,סמ נחל טור
3616 ,מעלה אדומים ,119 ,סמ נחל יעלים
3616 ,מעלה אדומים ,139 ,סמ ספיר
3616 ,מעלה אדומים ,107 ,סמ פטדה
3616 ,מעלה אדומים ,110 ,סמ שוהם
3616 ,מעלה אדומים ,104 ,סמ תרשיש
3616 ,מעלה אדומים ,213 ,עצמונה
3616 ,מעלה אדומים ,178 ,עת הזמיר
3616 ,מעלה אדומים ,6504 ,פארק ישראל מ אדומים
3616 ,מעלה אדומים ,204 ,פרי גן
3616 ,מעלה אדומים ,176 ,פרי מגדים
3616 ,מעלה אדומים ,209 ,פריאל
3616 ,מעלה אדומים ,160 ,צמח השדה
3616 ,מעלה אדומים ,254 ,קדש ברנע
3616 ,מעלה אדומים ,177 ,קול התור
3616 ,מעלה אדומים ,201 ,שד החברה הכלכלית
3616 ,מעלה אדומים ,212 ,שדות
3616 ,מעלה אדומים ,6502 ,שכ אבני החושן
3616 ,מעלה אדומים ,6508 ,שכ המצדים
3616 ,מעלה אדומים ,6507 ,שכ הנחלים
3616 ,מעלה אדומים ,6506 ,שכ כלי שיר
3616 ,מעלה אדומים ,6500 ,שכ מגדים
3616 ,מעלה אדומים ,6509 ,שכ מצפה נבו
3616 ,מעלה אדומים ,6501 ,שכ משואה
3616 ,מעלה אדומים ,6505 ,שכ נופי הסלע
3616 ,מעלה אדומים ,6503 ,שכ צמח השדה
3616 ,מעלה אדומים ,253 ,שלהבת
3616 ,מעלה אדומים ,207 ,תלמי יוסף
3608 ,מעלה אפרים ,6501 ,אזור התעשיה
3608 ,מעלה אפרים ,7001 ,בי"ס שדה
3608 ,מעלה אפרים ,101 ,גאון הירדן
3608 ,מעלה אפרים ,107 ,דרך בני אפרים
3608 ,מעלה אפרים ,103 ,דרך הגלעד
3608 ,מעלה אפרים ,104 ,דרך מנשה
3608 ,מעלה אפרים ,105 ,דרך משואה
3608 ,מעלה אפרים ,106 ,מבוא הבושם
3608 ,מעלה אפרים ,6500 ,מגורונים
3608 ,מעלה אפרים ,9000 ,מעלה אפרים
1127 ,מעלה גלבוע ,9000 ,מעלה גלבוע
4008 ,מעלה גמלא ,100 ,האלון
4008 ,מעלה גמלא ,101 ,הגפן
4008 ,מעלה גמלא ,102 ,הזית
4008 ,מעלה גמלא ,103 ,המנגו
4008 ,מעלה גמלא ,104 ,הרימון
4008 ,מעלה גמלא ,105 ,השיזף
4008 ,מעלה גמלא ,106 ,התאנה
4008 ,מעלה גמלא ,107 ,התמר
4008 ,מעלה גמלא ,9000 ,מעלה גמלא
4008 ,מעלה גמלא ,108 ,שמריהו גוטמן
286 ,מעלה החמישה ,9000 ,מעלה החמישה
3752 ,מעלה לבונה ,106 ,ה'באייר
3752 ,מעלה לבונה ,105 ,הכרכום
3752 ,מעלה לבונה ,111 ,הלבונה
3752 ,מעלה לבונה ,102 ,המור
3752 ,מעלה לבונה ,103 ,הצורי
3752 ,מעלה לבונה ,107 ,הציפורן
3752 ,מעלה לבונה ,101 ,הקטורת
3752 ,מעלה לבונה ,104 ,הקינמון
3752 ,מעלה לבונה ,9000 ,מעלה לבונה
3752 ,מעלה לבונה ,112 ,משעול דן
3752 ,מעלה לבונה ,113 ,משעול המייסדים
3752 ,מעלה לבונה ,108 ,משעול הרואה
3752 ,מעלה לבונה ,109 ,משעול חרמון
3752 ,מעלה לבונה ,110 ,שבולת
3651 ,מעלה מכמש ,113 ,אריאל
3651 ,מעלה מכמש ,109 ,דרך השלום
3651 ,מעלה מכמש ,103 ,האורן
3651 ,מעלה מכמש ,105 ,האחווה
3651 ,מעלה מכמש ,110 ,הגפן
3651 ,מעלה מכמש ,107 ,הדביר
3651 ,מעלה מכמש ,116 ,הראל
3651 ,מעלה מכמש ,112 ,ידידיה
3651 ,מעלה מכמש ,114 ,יפה נוף
3651 ,מעלה מכמש ,104 ,כליל החורש
3651 ,מעלה מכמש ,108 ,כלילת יופי
3651 ,מעלה מכמש ,106 ,מוריה
3651 ,מעלה מכמש ,9000 ,מעלה מכמש
3651 ,מעלה מכמש ,102 ,סלעית
3651 ,מעלה מכמש ,101 ,שדרות ירושלים
3651 ,מעלה מכמש ,111 ,שדרות ציון
3651 ,מעלה מכמש ,115 ,שלם
1327 ,מעלה עירון ,6712 ,אבו בכר-סאלם
1327 ,מעלה עירון ,6743 ,אבו שהאב-מוסמוס
1327 ,מעלה עירון ,6741 ,אגבריה-מוסמוס
1327 ,מעלה עירון ,6733 ,אגבריה-מושירפה
1327 ,מעלה עירון ,6713 ,אזור התעשייה-סאלם
1327 ,מעלה עירון ,6722 ,אלמורתפעה-זלפה
1327 ,מעלה עירון ,6732 ,אלמנשיה-מושירפה
1327 ,מעלה עירון ,501 ,ביאדה
1327 ,מעלה עירון ,6731 ,ג'בארין-מושירפה
1327 ,מעלה עירון ,6715 ,דרויש-סאלם
1327 ,מעלה עירון ,6735 ,הורדים-מוסמוס
1327 ,מעלה עירון ,6734 ,הכפר הישן-מושירפה
1327 ,מעלה עירון ,101 ,זלפה
1327 ,מעלה עירון ,6742 ,מוסמוס-דרום מזרח
1327 ,מעלה עירון ,201 ,מוצמוץ
1327 ,מעלה עירון ,301 ,מושיירפה
1327 ,מעלה עירון ,6740 ,מחאג'נה-מוסמוס
1327 ,מעלה עירון ,9000 ,מעלה עירון
1327 ,מעלה עירון ,401 ,סאלם
1327 ,מעלה עירון ,6710 ,סביחאת-סאלם
1327 ,מעלה עירון ,6711 ,עיאש-סאלם
1327 ,מעלה עירון ,6714 ,רפאעי-סאלם
1327 ,מעלה עירון ,6730 ,שכ ביאדה
1327 ,מעלה עירון ,6721 ,שכונה מזרחית-זלפה
1327 ,מעלה עירון ,6720 ,שכונה מערבית-זלפה
1327 ,מעלה עירון ,6744 ,שרקאווי-מוסמוס
3653 ,מעלה עמוס ,104 ,אבן עזרא
3653 ,מעלה עמוס ,101 ,יונתן בן עוזיאל
3653 ,מעלה עמוס ,9000 ,מעלה עמוס
3653 ,מעלה עמוס ,102 ,סמטת אונקלוס
3653 ,מעלה עמוס ,107 ,רבנו בחיי
3653 ,מעלה עמוס ,103 ,רמב"ן
3653 ,מעלה עמוס ,106 ,רש"י
3653 ,מעלה עמוס ,105 ,רשב"ם
3653 ,מעלה עמוס ,6500 ,שכ איבי הנחל
1063 ,מעלות-תרשיחא ,301 ,אבו אלעלא אלמערי
1063 ,מעלות-תרשיחא ,316 ,אבן סינא
1063 ,מעלות-תרשיחא ,238 ,אודם
1063 ,מעלות-תרשיחא ,6524 ,אזור התעשייה
1063 ,מעלות-תרשיחא ,337 ,אחוות עמים
1063 ,מעלות-תרשיחא ,299 ,אחמד שאוקי
1063 ,מעלות-תרשיחא ,331 ,אל-זיתון
1063 ,מעלות-תרשיחא ,308 ,אלון
1063 ,מעלות-תרשיחא ,306 ,אליא אבו מאדי
1063 ,מעלות-תרשיחא ,347 ,אלמוג
1063 ,מעלות-תרשיחא ,311 ,אלמותנבי
1063 ,מעלות-תרשיחא ,259 ,אסיף
1063 ,מעלות-תרשיחא ,215 ,אסתר המלכה
1063 ,מעלות-תרשיחא ,222 ,אצ"ל
1063 ,מעלות-תרשיחא ,196 ,ארבל
1063 ,מעלות-תרשיחא ,130 ,אשחר
1063 ,מעלות-תרשיחא ,251 ,אשכולות
1063 ,מעלות-תרשיחא ,252 ,אתרוג
1063 ,מעלות-תרשיחא ,348 ,בלסם
1063 ,מעלות-תרשיחא ,112 ,בן גוריון
1063 ,מעלות-תרשיחא ,258 ,בציר
1063 ,מעלות-תרשיחא ,140 ,ברקת
1063 ,מעלות-תרשיחא ,6527 ,גבעת האירוס
1063 ,מעלות-תרשיחא ,6516 ,גבעת הזיתים
1063 ,מעלות-תרשיחא ,6512 ,גבעת הסביונים
1063 ,מעלות-תרשיחא ,6514 ,גבעת הרקפות
1063 ,מעלות-תרשיחא ,6517 ,גבעת רבין
1063 ,מעלות-תרשיחא ,6528 ,גבעת תע"ש
1063 ,מעלות-תרשיחא ,190 ,גולן
1063 ,מעלות-תרשיחא ,139 ,גזית
1063 ,מעלות-תרשיחא ,6529 ,דאבשה
1063 ,מעלות-תרשיחא ,346 ,דובדבן
1063 ,מעלות-תרשיחא ,214 ,דוד המלך
1063 ,מעלות-תרשיחא ,247 ,דולב
1063 ,מעלות-תרשיחא ,231 ,דפנה
1063 ,מעלות-תרשיחא ,192 ,דרך יעקב חזן
1063 ,מעלות-תרשיחא ,314 ,האומנים
1063 ,מעלות-תרשיחא ,115 ,האורנים
1063 ,מעלות-תרשיחא ,144 ,האיריס
1063 ,מעלות-תרשיחא ,120 ,האלה
1063 ,מעלות-תרשיחא ,111 ,הארזים
1063 ,מעלות-תרשיחא ,328 ,הגבעה
1063 ,מעלות-תרשיחא ,114 ,הגליל
1063 ,מעלות-תרשיחא ,256 ,הדסים
1063 ,מעלות-תרשיחא ,260 ,הדרים
1063 ,מעלות-תרשיחא ,219 ,ההגנה
1063 ,מעלות-תרשיחא ,129 ,הורד
1063 ,מעלות-תרשיחא ,320 ,הורדים
1063 ,מעלות-תרשיחא ,118 ,הזית
1063 ,מעלות-תרשיחא ,241 ,החצב
1063 ,מעלות-תרשיחא ,123 ,החרוב
1063 ,מעלות-תרשיחא ,225 ,החרצית
1063 ,מעלות-תרשיחא ,230 ,החשמונאים
1063 ,מעלות-תרשיחא ,209 ,החשמל
1063 ,מעלות-תרשיחא ,145 ,הכלנית
1063 ,מעלות-תרשיחא ,319 ,הכנסיות
1063 ,מעלות-תרשיחא ,167 ,המדע
1063 ,מעלות-תרשיחא ,201 ,המלאכה
1063 ,מעלות-תרשיחא ,202 ,המסגר
1063 ,מעלות-תרשיחא ,141 ,המעפילים
1063 ,מעלות-תרשיחא ,168 ,המתכת
1063 ,מעלות-תרשיחא ,243 ,הסחלב
1063 ,מעלות-תרשיחא ,171 ,הסיגליות
1063 ,מעלות-תרשיחא ,221 ,הפלמ"ח
1063 ,מעלות-תרשיחא ,244 ,הצבעוני
1063 ,מעלות-תרשיחא ,104 ,הרב משאש
1063 ,מעלות-תרשיחא ,107 ,הרב קוק
1063 ,מעלות-תרשיחא ,179 ,הרדוף
1063 ,מעלות-תרשיחא ,210 ,הרותם
1063 ,מעלות-תרשיחא ,124 ,הרצל
1063 ,מעלות-תרשיחא ,146 ,הרקפת
1063 ,מעלות-תרשיחא ,223 ,השומר
1063 ,מעלות-תרשיחא ,315 ,השוק
1063 ,מעלות-תרשיחא ,304 ,השכונה
1063 ,מעלות-תרשיחא ,329 ,השלושה
1063 ,מעלות-תרשיחא ,307 ,השקדים
1063 ,מעלות-תרשיחא ,106 ,התאנה
1063 ,מעלות-תרשיחא ,212 ,התומר
1063 ,מעלות-תרשיחא ,228 ,התעשיינים
1063 ,מעלות-תרשיחא ,121 ,ז'בוטינסקי
1063 ,מעלות-תרשיחא ,254 ,חבצלת
1063 ,מעלות-תרשיחא ,324 ,חליל ג'ובראן
1063 ,מעלות-תרשיחא ,203 ,חרושת
1063 ,מעלות-תרשיחא ,191 ,חרמון
1063 ,מעלות-תרשיחא ,349 ,טוליפ
1063 ,מעלות-תרשיחא ,147 ,יסמין
1063 ,מעלות-תרשיחא ,143 ,יקינטון
1063 ,מעלות-תרשיחא ,236 ,ירדן
1063 ,מעלות-תרשיחא ,237 ,כזיב
1063 ,מעלות-תרשיחא ,6016 ,כיכר אלי בניטה
1063 ,מעלות-תרשיחא ,6018 ,כיכר אפריים קישון
1063 ,מעלות-תרשיחא ,6020 ,כיכר הדקל
1063 ,מעלות-תרשיחא ,6024 ,כיכר הוטרנים
1063 ,מעלות-תרשיחא ,6022 ,כיכר הכרמים
1063 ,מעלות-תרשיחא ,6023 ,כיכר הרב יוסף גבאי
1063 ,מעלות-תרשיחא ,6017 ,כיכר חנה סנש
1063 ,מעלות-תרשיחא ,6019 ,כיכר יצחק שמיר
1063 ,מעלות-תרשיחא ,6021 ,כיכר תרשיחא
1063 ,מעלות-תרשיחא ,6004 ,ככר אגוז
1063 ,מעלות-תרשיחא ,6012 ,ככר איתנים
1063 ,מעלות-תרשיחא ,6001 ,ככר גרס
1063 ,מעלות-תרשיחא ,6008 ,ככר היצירה
1063 ,מעלות-תרשיחא ,6002 ,ככר המזרקה
1063 ,מעלות-תרשיחא ,6010 ,ככר המייסדים
1063 ,מעלות-תרשיחא ,6013 ,ככר המרכז
1063 ,מעלות-תרשיחא ,6014 ,ככר הראשונים
1063 ,מעלות-תרשיחא ,6005 ,ככר ירושלים
1063 ,מעלות-תרשיחא ,6003 ,ככר נחשון
1063 ,מעלות-תרשיחא ,6009 ,ככר נשיאי ישראל
1063 ,מעלות-תרשיחא ,6011 ,ככר פרפיניון
1063 ,מעלות-תרשיחא ,6007 ,ככר קרן היסוד
1063 ,מעלות-תרשיחא ,6006 ,ככר רוטרי
1063 ,מעלות-תרשיחא ,6015 ,ככר רחבעם זאבי
1063 ,מעלות-תרשיחא ,117 ,כליל החורש
1063 ,מעלות-תרשיחא ,224 ,כרכום
1063 ,מעלות-תרשיחא ,193 ,כרמל
1063 ,מעלות-תרשיחא ,127 ,לוטוס
1063 ,מעלות-תרשיחא ,126 ,לוי אשכול
1063 ,מעלות-תרשיחא ,220 ,לח"י
1063 ,מעלות-תרשיחא ,180 ,ליבנה
1063 ,מעלות-תרשיחא ,312 ,מורד הגיא
1063 ,מעלות-תרשיחא ,131 ,מורן
1063 ,מעלות-תרשיחא ,194 ,מירון
1063 ,מעלות-תרשיחא ,327 ,מעלה אל מג'אהד
1063 ,מעלות-תרשיחא ,326 ,מעלה גולני
1063 ,מעלות-תרשיחא ,162 ,מעלה האלון
1063 ,מעלות-תרשיחא ,103 ,מעלה הבנים
1063 ,מעלות-תרשיחא ,334 ,מעלה הברוש
1063 ,מעלות-תרשיחא ,132 ,מעלה הגפן
1063 ,מעלות-תרשיחא ,133 ,מעלה הדליה
1063 ,מעלות-תרשיחא ,330 ,מעלה ההר
1063 ,מעלות-תרשיחא ,335 ,מעלה הכרמים
1063 ,מעלות-תרשיחא ,336 ,מעלה המגדל
1063 ,מעלות-תרשיחא ,323 ,מעלה המדרגות
1063 ,מעלות-תרשיחא ,302 ,מעלה המסגד
1063 ,מעלות-תרשיחא ,317 ,מעלה המעיין
1063 ,מעלות-תרשיחא ,134 ,מעלה הפרח
1063 ,מעלות-תרשיחא ,159 ,מעלה הרב ניסים
1063 ,מעלות-תרשיחא ,333 ,מעלה התאנה
1063 ,מעלות-תרשיחא ,135 ,מעלה ורד
1063 ,מעלות-תרשיחא ,102 ,מעלה חסון
1063 ,מעלות-תרשיחא ,150 ,מעלה יפה נוף
1063 ,מעלות-תרשיחא ,9000 ,מעלות-תרשיחא
1063 ,מעלות-תרשיחא ,195 ,מצדה
1063 ,מעלות-תרשיחא ,227 ,מרווה
1063 ,מעלות-תרשיחא ,275 ,משעול אדמונית החורש
1063 ,מעלות-תרשיחא ,313 ,משעול אל-ביאדר
1063 ,מעלות-תרשיחא ,264 ,משעול אלומות
1063 ,מעלות-תרשיחא ,276 ,משעול בכור האביב
1063 ,מעלות-תרשיחא ,277 ,משעול גבעונית
1063 ,מעלות-תרשיחא ,278 ,משעול דם המכבים
1063 ,מעלות-תרשיחא ,152 ,משעול האסיף
1063 ,מעלות-תרשיחא ,175 ,משעול האשל
1063 ,מעלות-תרשיחא ,163 ,משעול הבוסתן
1063 ,מעלות-תרשיחא ,166 ,משעול הביכורים
1063 ,מעלות-תרשיחא ,176 ,משעול הברוש
1063 ,מעלות-תרשיחא ,149 ,משעול הברקן
1063 ,מעלות-תרשיחא ,177 ,משעול הגומא
1063 ,מעלות-תרשיחא ,151 ,משעול הגורן
1063 ,מעלות-תרשיחא ,155 ,משעול הגעתון
1063 ,מעלות-תרשיחא ,300 ,משעול הדקל
1063 ,מעלות-תרשיחא ,279 ,משעול היביסקוס
1063 ,מעלות-תרשיחא ,343 ,משעול הידידות
1063 ,מעלות-תרשיחא ,109 ,משעול הכרמים
1063 ,מעלות-תרשיחא ,262 ,משעול הלוטם
1063 ,מעלות-תרשיחא ,157 ,משעול המוסיקה
1063 ,מעלות-תרשיחא ,181 ,משעול המרגנית
1063 ,מעלות-תרשיחא ,154 ,משעול הנעמן
1063 ,מעלות-תרשיחא ,211 ,משעול הסנונית
1063 ,מעלות-תרשיחא ,233 ,משעול הערבה
1063 ,מעלות-תרשיחא ,165 ,משעול הערמונים
1063 ,מעלות-תרשיחא ,338 ,משעול הפעמונים
1063 ,מעלות-תרשיחא ,153 ,משעול הקישון
1063 ,מעלות-תרשיחא ,137 ,משעול השחף
1063 ,מעלות-תרשיחא ,232 ,משעול השיטה
1063 ,מעלות-תרשיחא ,158 ,משעול השלדג
1063 ,מעלות-תרשיחא ,116 ,משעול השקד
1063 ,מעלות-תרשיחא ,156 ,משעול השקמה
1063 ,מעלות-תרשיחא ,164 ,משעול התאנים
1063 ,מעלות-תרשיחא ,108 ,משעול התמרים
1063 ,מעלות-תרשיחא ,280 ,משעול וינקה
1063 ,מעלות-תרשיחא ,281 ,משעול זמורה
1063 ,מעלות-תרשיחא ,282 ,משעול חלמונית
1063 ,מעלות-תרשיחא ,298 ,משעול טהא חוסיין
1063 ,מעלות-תרשיחא ,283 ,משעול טפח
1063 ,מעלות-תרשיחא ,284 ,משעול יערה
1063 ,מעלות-תרשיחא ,285 ,משעול כרמלית
1063 ,מעלות-תרשיחא ,286 ,משעול לילך
1063 ,מעלות-תרשיחא ,287 ,משעול מקור החסידה
1063 ,מעלות-תרשיחא ,288 ,משעול נץ החלב
1063 ,מעלות-תרשיחא ,110 ,משעול סביון
1063 ,מעלות-תרשיחא ,178 ,משעול סייפן
1063 ,מעלות-תרשיחא ,289 ,משעול סתונית
1063 ,מעלות-תרשיחא ,290 ,משעול ערמונית
1063 ,מעלות-תרשיחא ,291 ,משעול פעמונית
1063 ,מעלות-תרשיחא ,292 ,משעול צבר
1063 ,מעלות-תרשיחא ,293 ,משעול קטלב
1063 ,מעלות-תרשיחא ,294 ,משעול רמונית
1063 ,מעלות-תרשיחא ,295 ,משעול שיזף
1063 ,מעלות-תרשיחא ,6501 ,משקי עזר
1063 ,מעלות-תרשיחא ,303 ,נג'יב מחפוז
1063 ,מעלות-תרשיחא ,6526 ,נוף האגם
1063 ,מעלות-תרשיחא ,255 ,נורית
1063 ,מעלות-תרשיחא ,340 ,ניו יורק
1063 ,מעלות-תרשיחא ,342 ,ניזאר קבאני
1063 ,מעלות-תרשיחא ,344 ,ניירוז
1063 ,מעלות-תרשיחא ,161 ,נרקיס
1063 ,מעלות-תרשיחא ,207 ,נרקיס עוזי
1063 ,מעלות-תרשיחא ,239 ,ספיר
1063 ,מעלות-תרשיחא ,148 ,עדעד
1063 ,מעלות-תרשיחא ,182 ,עירית
1063 ,מעלות-תרשיחא ,226 ,עלית הנוער
1063 ,מעלות-תרשיחא ,200 ,עמוס לסקוב
1063 ,מעלות-תרשיחא ,113 ,פקיעין
1063 ,מעלות-תרשיחא ,138 ,צורית
1063 ,מעלות-תרשיחא ,332 ,ציפורן
1063 ,מעלות-תרשיחא ,245 ,קהילת יהדות צרפת
1063 ,מעלות-תרשיחא ,183 ,קורנית
1063 ,מעלות-תרשיחא ,257 ,קטיף
1063 ,מעלות-תרשיחא ,261 ,קציר
1063 ,מעלות-תרשיחא ,136 ,קק"ל
1063 ,מעלות-תרשיחא ,6502 ,קרית חנוך
1063 ,מעלות-תרשיחא ,105 ,קרן היסוד
1063 ,מעלות-תרשיחא ,208 ,רא"ל לסקוב חיים
1063 ,מעלות-תרשיחא ,345 ,רוזמרין
1063 ,מעלות-תרשיחא ,253 ,רימון
1063 ,מעלות-תרשיחא ,6525 ,רמת הנשיא שמעון פרס
1063 ,מעלות-תרשיחא ,217 ,שאול המלך
1063 ,מעלות-תרשיחא ,310 ,שביל אלחרירי
1063 ,מעלות-תרשיחא ,309 ,שביל אלסוסן
1063 ,מעלות-תרשיחא ,321 ,שביל האחווה
1063 ,מעלות-תרשיחא ,322 ,שביל האיריס
1063 ,מעלות-תרשיחא ,318 ,שביל המסגדים
1063 ,מעלות-תרשיחא ,339 ,שביל הפרחים
1063 ,מעלות-תרשיחא ,341 ,שביל התקווה
1063 ,מעלות-תרשיחא ,325 ,שביל רימונים
1063 ,מעלות-תרשיחא ,235 ,שד הנשיא הרצוג
1063 ,מעלות-תרשיחא ,213 ,שד ירושלים
1063 ,מעלות-תרשיחא ,240 ,שוהם
1063 ,מעלות-תרשיחא ,125 ,שז"ר זלמן
1063 ,מעלות-תרשיחא ,265 ,שיבולת
1063 ,מעלות-תרשיחא ,218 ,שיבת ציון
1063 ,מעלות-תרשיחא ,6520 ,שכ בן גוריון
1063 ,מעלות-תרשיחא ,6504 ,שכ בנה ביתך א
1063 ,מעלות-תרשיחא ,6505 ,שכ בנה ביתך ב
1063 ,מעלות-תרשיחא ,6511 ,שכ גלעד
1063 ,מעלות-תרשיחא ,6509 ,שכ דדו
1063 ,מעלות-תרשיחא ,6503 ,שכ האילנות
1063 ,מעלות-תרשיחא ,6513 ,שכ הורדים
1063 ,מעלות-תרשיחא ,6510 ,שכ הסיגליות
1063 ,מעלות-תרשיחא ,6518 ,שכ הראשונים
1063 ,מעלות-תרשיחא ,6519 ,שכ הרב קוק
1063 ,מעלות-תרשיחא ,6521 ,שכ הרצל
1063 ,מעלות-תרשיחא ,6508 ,שכ ואדי קורן
1063 ,מעלות-תרשיחא ,6515 ,שכ יפה נוף
1063 ,מעלות-תרשיחא ,6522 ,שכ מעלה חסון
1063 ,מעלות-תרשיחא ,6523 ,שכ קרן היסוד
1063 ,מעלות-תרשיחא ,6506 ,שכונת כהן
1063 ,מעלות-תרשיחא ,6507 ,שכונת עמית
1063 ,מעלות-תרשיחא ,263 ,שלהבית
1063 ,מעלות-תרשיחא ,216 ,שלמה המלך
1063 ,מעלות-תרשיחא ,242 ,שניר
1063 ,מעלות-תרשיחא ,205 ,שרירא שלמה
1063 ,מעלות-תרשיחא ,101 ,תבור
1063 ,מעלות-תרשיחא ,229 ,תדהר
1063 ,מעלות-תרשיחא ,204 ,תובל
1063 ,מעלות-תרשיחא ,305 ,תופיק אלחכים
1063 ,מעלות-תרשיחא ,128 ,תמר
1063 ,מעלות-תרשיחא ,206 ,תעשייה
1063 ,מעלות-תרשיחא ,6500 ,תרשיחא
344 ,מענית ,7001 ,גבעת חביבה
344 ,מענית ,102 ,דרך אורן
344 ,מענית ,103 ,דרך אלמוג
344 ,מענית ,104 ,דרך דרור
344 ,מענית ,106 ,דרך החוברים
344 ,מענית ,105 ,דרך החורש
344 ,מענית ,114 ,דרך הפילבוקס
344 ,מענית ,107 ,דרך זמיר
344 ,מענית ,108 ,דרך חביבה
344 ,מענית ,109 ,דרך חצב
344 ,מענית ,101 ,דרך מענית נרבתה
344 ,מענית ,110 ,דרך סלע
344 ,מענית ,111 ,דרך סער
344 ,מענית ,112 ,דרך רקפת
344 ,מענית ,113 ,דרך תומר
344 ,מענית ,9000 ,מענית
344 ,מענית ,100 ,שביל אחרי
344 ,מענית ,116 ,שביל איילה
344 ,מענית ,117 ,שביל אל על
344 ,מענית ,118 ,שביל אלה
344 ,מענית ,119 ,שביל אלון
344 ,מענית ,120 ,שביל גפן
344 ,מענית ,121 ,שביל דגן
344 ,מענית ,122 ,שביל דקל
344 ,מענית ,123 ,שביל הדר
344 ,מענית ,124 ,שביל הוותיקים
344 ,מענית ,115 ,שביל השקמה
344 ,מענית ,125 ,שביל יוסף קפלן
344 ,מענית ,126 ,שביל כלנית
344 ,מענית ,127 ,שביל לפיד
344 ,מענית ,128 ,שביל מעיין
344 ,מענית ,129 ,שביל ניצנים
344 ,מענית ,130 ,שביל נרקיס
344 ,מענית ,131 ,שביל סנונית
344 ,מענית ,132 ,שביל עופר
344 ,מענית ,133 ,שביל ערבה
344 ,מענית ,134 ,שביל צור
344 ,מענית ,135 ,שביל רימון
344 ,מענית ,136 ,שביל רעות
344 ,מענית ,137 ,שביל שיבולים
344 ,מענית ,138 ,שביל שלהבת
344 ,מענית ,139 ,שביל שנהב
344 ,מענית ,140 ,שביל תות
230 ,מעש ,107 ,אילת
230 ,מעש ,102 ,האורנים
230 ,מעש ,101 ,האילנות
230 ,מעש ,108 ,הברוש
230 ,מעש ,105 ,ההדרים
230 ,מעש ,104 ,הכלנית
230 ,מעש ,106 ,הצבעוני
230 ,מעש ,103 ,השלושה
230 ,מעש ,109 ,זרובבל
230 ,מעש ,9000 ,מעש
668 ,מפלסים ,9000 ,מפלסים
3745 ,מצדות יהודה ,9000 ,מצדות יהודה
325 ,מצובה ,9000 ,מצובה
757 ,מצליח ,101 ,הגפן
757 ,מצליח ,105 ,הדס
757 ,מצליח ,102 ,הרימון
757 ,מצליח ,103 ,התאנה
757 ,מצליח ,104 ,התמר
757 ,מצליח ,9000 ,מצליח
757 ,מצליח ,107 ,סמטת האילן
757 ,מצליח ,108 ,סמטת הדקל
757 ,מצליח ,106 ,סמטת הערבה
757 ,מצליח ,109 ,עופרה
58 ,מצפה ,9000 ,מצפה
1222 ,מצפה אבי"ב ,9000 ,מצפה אבי"ב
1370 ,מצפה אילן ,102 ,אשחר
1370 ,מצפה אילן ,103 ,דרך החורש
1370 ,מצפה אילן ,104 ,האלון
1370 ,מצפה אילן ,6001 ,כיכר ההדס
1370 ,מצפה אילן ,6002 ,כיכר הזית
1370 ,מצפה אילן ,6003 ,כיכר יערה
1370 ,מצפה אילן ,6004 ,כיכר קורנית
1370 ,מצפה אילן ,105 ,מבוא דולב
1370 ,מצפה אילן ,106 ,מבוא דפנה
1370 ,מצפה אילן ,107 ,מבוא האגס
1370 ,מצפה אילן ,108 ,מבוא האורן
1370 ,מצפה אילן ,109 ,מבוא האלה
1370 ,מצפה אילן ,110 ,מבוא החרוב
1370 ,מצפה אילן ,111 ,מבוא הלוטם
1370 ,מצפה אילן ,112 ,מבוא הקטלב
1370 ,מצפה אילן ,113 ,מבוא הרותם
1370 ,מצפה אילן ,114 ,מבוא השיזף
1370 ,מצפה אילן ,115 ,מבוא השקד
1370 ,מצפה אילן ,116 ,מבוא ורד
1370 ,מצפה אילן ,117 ,מבוא כליל החורש
1370 ,מצפה אילן ,118 ,מורן
1370 ,מצפה אילן ,9000 ,מצפה אילן
1370 ,מצפה אילן ,101 ,מצפה עירון
1370 ,מצפה אילן ,119 ,שד המרווה
3576 ,מצפה יריחו ,116 ,אבני מילואים
3576 ,מצפה יריחו ,124 ,אודם
3576 ,מצפה יריחו ,101 ,אורן
3576 ,מצפה יריחו ,102 ,אלה
3576 ,מצפה יריחו ,129 ,ביכורים
3576 ,מצפה יריחו ,103 ,ברוש
3576 ,מצפה יריחו ,122 ,ברקת
3576 ,מצפה יריחו ,126 ,גבעת ברקאי
3576 ,מצפה יריחו ,104 ,גלעד
3576 ,מצפה יריחו ,105 ,גפן
3576 ,מצפה יריחו ,111 ,דביר
3576 ,מצפה יריחו ,106 ,דקל
3576 ,מצפה יריחו ,130 ,דרך חיים
3576 ,מצפה יריחו ,118 ,האפוד
3576 ,מצפה יריחו ,107 ,הדס
3576 ,מצפה יריחו ,117 ,החושן
3576 ,מצפה יריחו ,119 ,המוריה
3576 ,מצפה יריחו ,115 ,הקטורת
3576 ,מצפה יריחו ,121 ,יהלום
3576 ,מצפה יריחו ,125 ,כיפת הירדן
3576 ,מצפה יריחו ,132 ,מבוא ישפה
3576 ,מצפה יריחו ,131 ,מבוא לשם
3576 ,מצפה יריחו ,114 ,מעלה אריאל
3576 ,מצפה יריחו ,9000 ,מצפה יריחו
3576 ,מצפה יריחו ,108 ,נחל ארנון
3576 ,מצפה יריחו ,109 ,נחל פרת
3576 ,מצפה יריחו ,112 ,עולי רגלים
3576 ,מצפה יריחו ,128 ,עומר
3576 ,מצפה יריחו ,110 ,שדרות ירושלים
3576 ,מצפה יריחו ,120 ,שוהם
3576 ,מצפה יריחו ,113 ,שירת הלויים
3576 ,מצפה יריחו ,127 ,שתי הלחם
3576 ,מצפה יריחו ,123 ,תרשיש
1190 ,מצפה נטופה ,110 ,אורן
1190 ,מצפה נטופה ,108 ,אלה
1190 ,מצפה נטופה ,114 ,ארז
1190 ,מצפה נטופה ,112 ,אשחר
1190 ,מצפה נטופה ,107 ,דולב
1190 ,מצפה נטופה ,101 ,הדס
1190 ,מצפה נטופה ,111 ,זית
1190 ,מצפה נטופה ,103 ,כליל החורש
1190 ,מצפה נטופה ,117 ,ליבנה
1190 ,מצפה נטופה ,105 ,מורן
1190 ,מצפה נטופה ,9000 ,מצפה נטופה
1190 ,מצפה נטופה ,104 ,ערבה
1190 ,מצפה נטופה ,102 ,רותם
1190 ,מצפה נטופה ,113 ,רימון
1190 ,מצפה נטופה ,109 ,שיזף
1190 ,מצפה נטופה ,106 ,שקד
1190 ,מצפה נטופה ,116 ,תאנה
1190 ,מצפה נטופה ,115 ,תמר
99 ,מצפה רמון ,150 ,אזור התעשיה
99 ,מצפה רמון ,7004 ,בי"ס שדה הר הנגב
99 ,מצפה רמון ,127 ,גנדי (רחבעם זאבי)
99 ,מצפה רמון ,130 ,דרך בראשית
99 ,מצפה רמון ,149 ,דרך מצפה הכוכבים
99 ,מצפה רמון ,131 ,דרך צלילי מדבר
99 ,מצפה רמון ,103 ,דרך רמון
99 ,מצפה רמון ,132 ,דרך שפת המדבר
99 ,מצפה רמון ,171 ,האירוס
99 ,מצפה רמון ,170 ,החצב
99 ,מצפה רמון ,172 ,הצבעוני
99 ,מצפה רמון ,124 ,הר אלדד
99 ,מצפה רמון ,118 ,הר ארדון
99 ,מצפה רמון ,146 ,הר בוקר
99 ,מצפה רמון ,122 ,הר חמת
99 ,מצפה רמון ,123 ,הר נפחא
99 ,מצפה רמון ,117 ,הר עודד
99 ,מצפה רמון ,105 ,הר עריף
99 ,מצפה רמון ,134 ,הר צין
99 ,מצפה רמון ,121 ,הר קטום
99 ,מצפה רמון ,116 ,הר קירטון
99 ,מצפה רמון ,115 ,הר שגיא
99 ,מצפה רמון ,128 ,השיטה
99 ,מצפה רמון ,7003 ,חוות האלפקות
99 ,מצפה רמון ,144 ,טיילת אלברט כץ
99 ,מצפה רמון ,7002 ,כלא נפחא
99 ,מצפה רמון ,133 ,מושבת האומנויות
99 ,מצפה רמון ,7001 ,מחנה טלי
99 ,מצפה רמון ,129 ,מעלה בן תור
99 ,מצפה רמון ,137 ,מעלה הדקל
99 ,מצפה רמון ,114 ,מעלה מחמל
99 ,מצפה רמון ,9000 ,מצפה רמון
99 ,מצפה רמון ,166 ,נחל אילות
99 ,מצפה רמון ,153 ,נחל ברק
99 ,מצפה רמון ,154 ,נחל גוונים
99 ,מצפה רמון ,101 ,נחל גרופית
99 ,מצפה רמון ,108 ,נחל האלה
99 ,מצפה רמון ,164 ,נחל ורדית
99 ,מצפה רמון ,113 ,נחל חוה
99 ,מצפה רמון ,159 ,נחל חולית
99 ,מצפה רמון ,162 ,נחל חמדה
99 ,מצפה רמון ,167 ,נחל טרשים
99 ,מצפה רמון ,151 ,נחל כרכום
99 ,מצפה רמון ,102 ,נחל מישר
99 ,מצפה רמון ,160 ,נחל מנוחה
99 ,מצפה רמון ,168 ,נחל ניצנה
99 ,מצפה רמון ,111 ,נחל נקרות
99 ,מצפה רמון ,163 ,נחל סירה
99 ,מצפה רמון ,147 ,נחל סלעית
99 ,מצפה רמון ,145 ,נחל סרפד
99 ,מצפה רמון ,109 ,נחל ערבה
99 ,מצפה רמון ,112 ,נחל ערוד
99 ,מצפה רמון ,155 ,נחל פארן
99 ,מצפה רמון ,120 ,נחל ציה
99 ,מצפה רמון ,107 ,נחל ציחור
99 ,מצפה רמון ,169 ,נחל צניפים
99 ,מצפה רמון ,161 ,נחל רעים
99 ,מצפה רמון ,125 ,נחל רעף
99 ,מצפה רמון ,7005 ,סוכה במדבר
99 ,מצפה רמון ,173 ,סמטת קרני רמון
99 ,מצפה רמון ,142 ,עין המערה
99 ,מצפה רמון ,136 ,עין זיק
99 ,מצפה רמון ,139 ,עין מור
99 ,מצפה רמון ,141 ,עין משק
99 ,מצפה רמון ,138 ,עין סהרונים
99 ,מצפה רמון ,104 ,עין עבדת
99 ,מצפה רמון ,135 ,עין עופרים
99 ,מצפה רמון ,140 ,עין עקב
99 ,מצפה רמון ,152 ,עין שביב
99 ,מצפה רמון ,148 ,עין שחק
99 ,מצפה רמון ,6502 ,רמת ספיר
99 ,מצפה רמון ,6500 ,ש צבא קבע
99 ,מצפה רמון ,110 ,שד בן גוריון
99 ,מצפה רמון ,126 ,שד הר הגמל
99 ,מצפה רמון ,143 ,שדרות קק"ל
99 ,מצפה רמון ,6501 ,שכון ד
3610 ,מצפה שלם ,9000 ,מצפה שלם
648 ,מצר ,9000 ,מצר
22 ,מקווה ישראל ,101 ,דרך הבנים
22 ,מקווה ישראל ,102 ,דרך הכרמים
22 ,מקווה ישראל ,103 ,כביש המאה
22 ,מקווה ישראל ,104 ,מעלה הדקלים
22 ,מקווה ישראל ,105 ,מעלה נטר
22 ,מקווה ישראל ,9000 ,מקווה ישראל
22 ,מקווה ישראל ,109 ,משעול תר"ל
22 ,מקווה ישראל ,6500 ,ש מורים
22 ,מקווה ישראל ,106 ,שד הדוידקה
22 ,מקווה ישראל ,107 ,שד הותיקים
22 ,מקווה ישראל ,108 ,שד כי"ח
22 ,מקווה ישראל ,110 ,שד קראוזה
843 ,מרגליות ,9000 ,מרגליות
4101 ,מרום גולן ,9000 ,מרום גולן
1340 ,מרחב עם ,7001 ,חוות טנא
1340 ,מרחב עם ,9000 ,מרחב עם
97 ,מרחביה (מושב) ,107 ,בתי הראשונים
97 ,מרחביה (מושב) ,101 ,הברוש
97 ,מרחביה (מושב) ,110 ,הגורן
97 ,מרחביה (מושב) ,103 ,הגפן
97 ,מרחביה (מושב) ,106 ,הזיתים
97 ,מרחביה (מושב) ,109 ,החיטה
97 ,מרחביה (מושב) ,108 ,המושבה
97 ,מרחביה (מושב) ,112 ,השעורה
97 ,מרחביה (מושב) ,105 ,יפה נוף
97 ,מרחביה (מושב) ,102 ,מול ההר
97 ,מרחביה (מושב) ,104 ,מול העמק
97 ,מרחביה (מושב) ,9000 ,מרחביה (מושב)
97 ,מרחביה (מושב) ,111 ,סמטת הדגן
66 ,מרחביה (קיבוץ) ,101 ,אולפן
66 ,מרחביה (קיבוץ) ,103 ,ארז
66 ,מרחביה (קיבוץ) ,108 ,אשל
66 ,מרחביה (קיבוץ) ,102 ,בית ראשון במולדת
66 ,מרחביה (קיבוץ) ,110 ,דרך האורן
66 ,מרחביה (קיבוץ) ,118 ,דרך האקליפטוס
66 ,מרחביה (קיבוץ) ,113 ,דרך החרוב
66 ,מרחביה (קיבוץ) ,117 ,דרך הפיקוס
66 ,מרחביה (קיבוץ) ,119 ,דרך הפקאן
66 ,מרחביה (קיבוץ) ,106 ,האלה
66 ,מרחביה (קיבוץ) ,105 ,התמר
66 ,מרחביה (קיבוץ) ,109 ,זית
66 ,מרחביה (קיבוץ) ,121 ,ליבנה
66 ,מרחביה (קיבוץ) ,9000 ,מרחביה (קיבוץ)
66 ,מרחביה (קיבוץ) ,111 ,משעול הברוש
66 ,מרחביה (קיבוץ) ,114 ,משעול הדקל
66 ,מרחביה (קיבוץ) ,112 ,משעול הצפצפה
66 ,מרחביה (קיבוץ) ,115 ,משעול הרימון
66 ,מרחביה (קיבוץ) ,116 ,משעול כליל החורש
66 ,מרחביה (קיבוץ) ,104 ,ערבה
66 ,מרחביה (קיבוץ) ,120 ,שיזף
66 ,מרחביה (קיבוץ) ,122 ,שיטה
66 ,מרחביה (קיבוץ) ,123 ,שקד
66 ,מרחביה (קיבוץ) ,107 ,תאנה
1098 ,מרכז שפירא ,6500 ,אחוזת אתרוג
1098 ,מרכז שפירא ,115 ,דגנים
1098 ,מרכז שפירא ,109 ,האתרוג
1098 ,מרכז שפירא ,104 ,הגפן
1098 ,מרכז שפירא ,116 ,הדס
1098 ,מרכז שפירא ,107 ,הזית
1098 ,מרכז שפירא ,102 ,החיטה
1098 ,מרכז שפירא ,110 ,המייסדים
1098 ,מרכז שפירא ,106 ,הרימון
1098 ,מרכז שפירא ,105 ,השעורה
1098 ,מרכז שפירא ,103 ,התאנה
1098 ,מרכז שפירא ,108 ,התמר
1098 ,מרכז שפירא ,112 ,לבונה
1098 ,מרכז שפירא ,118 ,לולב
1098 ,מרכז שפירא ,114 ,מגדים
1098 ,מרכז שפירא ,9000 ,מרכז שפירא
1098 ,מרכז שפירא ,111 ,עציון
1098 ,מרכז שפירא ,117 ,ערבה
1098 ,מרכז שפירא ,113 ,רננים
1098 ,מרכז שפירא ,101 ,שבעת המינים
421 ,משאבי שדה ,101 ,בית ראשון במולדת
421 ,משאבי שדה ,9000 ,משאבי שדה
765 ,משגב דב ,108 ,הבוצר
765 ,משגב דב ,103 ,הבוקר
765 ,משגב דב ,102 ,החורש
765 ,משגב דב ,101 ,היוגב
765 ,משגב דב ,106 ,הכורם
765 ,משגב דב ,105 ,המוסק
765 ,משגב דב ,107 ,הנוטע
765 ,משגב דב ,109 ,הרועה
765 ,משגב דב ,104 ,השותל
765 ,משגב דב ,9000 ,משגב דב
378 ,משגב עם ,101 ,בית ראשון במולדת
378 ,משגב עם ,9000 ,משגב עם
520 ,משהד ,100 ,אבו בכר אלסדיק
520 ,משהד ,101 ,אבו זערור
520 ,משהד ,102 ,אבו סלמא
520 ,משהד ,103 ,אבן - זיידון
520 ,משהד ,107 ,אבן - ח'ולד
520 ,משהד ,104 ,אבן - ח'לדון
520 ,משהד ,105 ,אבן - סינא
520 ,משהד ,108 ,אבן - עפאן
520 ,משהד ,106 ,אבן - רושד
520 ,משהד ,109 ,אבראהים טוקאן
520 ,משהד ,110 ,אוקחואן
520 ,משהד ,126 ,אל - אזהר
520 ,משהד ,127 ,אל - אח'טל
520 ,משהד ,111 ,אל - אקסא
520 ,משהד ,112 ,אל - ארז
520 ,משהד ,169 ,אל - באז
520 ,משהד ,128 ,אל - באשק
520 ,משהד ,129 ,אל - בוח'ארי
520 ,משהד ,130 ,אל - בטוף
520 ,משהד ,113 ,אל - ביארק
520 ,משהד ,132 ,אל - ג'ליל
520 ,משהד ,114 ,אל - דבה
520 ,משהד ,133 ,אל - הודהוד
520 ,משהד ,134 ,אל - הייס
520 ,משהד ,135 ,אל - וחדה
520 ,משהד ,136 ,אל - זנבק
520 ,משהד ,115 ,אל - ח'וזאם
520 ,משהד ,137 ,אל - ח'ולח'אל
520 ,משהד ,116 ,אל - ח'טאף
520 ,משהד ,117 ,אל - ח'נסאא
520 ,משהד ,138 ,אל - ח'רוב
520 ,משהד ,139 ,אל - חבק
520 ,משהד ,118 ,אל - חג'ל
520 ,משהד ,141 ,אל - חדת'ה
520 ,משהד ,140 ,אל - חסון
520 ,משהד ,142 ,אל - חרירי
520 ,משהד ,143 ,אל - טאא'י
520 ,משהד ,144 ,אל - טאבון
520 ,משהד ,145 ,אל - טברי
520 ,משהד ,170 ,אל - ימאמה
520 ,משהד ,146 ,אל - ירמוק
520 ,משהד ,147 ,אל - כרמל
520 ,משהד ,148 ,אל - מג'ויז
520 ,משהד ,119 ,אל - מדינה
520 ,משהד ,149 ,אל - מחג'ר
520 ,משהד ,150 ,אל - מטל
520 ,משהד ,151 ,אל - מנארה
520 ,משהד ,120 ,אל - מערי
520 ,משהד ,152 ,אל - נח'לה
520 ,משהד ,121 ,אל - נסרה
520 ,משהד ,153 ,אל - נעאמה
520 ,משהד ,172 ,אל - סבר
520 ,משהד ,154 ,אל - סואר
520 ,משהד ,155 ,אל - סוחסלייה
520 ,משהד ,156 ,אל - סנאבל
520 ,משהד ,171 ,אל - סקאקיני
520 ,משהד ,122 ,אל - סקר
520 ,משהד ,157 ,אל - עג'מה
520 ,משהד ,173 ,אל - עוליקה
520 ,משהד ,158 ,אל - עקד
520 ,משהד ,159 ,אל - פאנוס
520 ,משהד ,123 ,אל - פאראבי
520 ,משהד ,160 ,אל - פרזדק
520 ,משהד ,161 ,אל - קאדסיה
520 ,משהד ,162 ,אל - קאהרה
520 ,משהד ,163 ,אל - קודס
520 ,משהד ,164 ,אל - קלעה
520 ,משהד ,165 ,אל - קסטל
520 ,משהד ,166 ,אל - ראיה
520 ,משהד ,124 ,אל - שאפעי
520 ,משהד ,167 ,אל - שג'רה
520 ,משהד ,125 ,אל - שהיד
520 ,משהד ,168 ,אל - תמר
520 ,משהד ,131 ,אל-ג'אמע אל-קדים
520 ,משהד ,285 ,אל-חמאים
520 ,משהד ,174 ,אלביאדר
520 ,משהד ,175 ,אלביארה
520 ,משהד ,280 ,אלברקוק
520 ,משהד ,176 ,אלדהור
520 ,משהד ,177 ,אלדורה
520 ,משהד ,178 ,אלורוד
520 ,משהד ,179 ,אלזיתון
520 ,משהד ,180 ,אלחארה אלפוקא
520 ,משהד ,181 ,אלחג'ה
520 ,משהד ,279 ,אלחור
520 ,משהד ,182 ,אלטיון
520 ,משהד ,282 ,אלמהא
520 ,משהד ,183 ,אלמוכתאר
520 ,משהד ,184 ,אלמעסרה
520 ,משהד ,185 ,אלנבעה
520 ,משהד ,186 ,אלספחה
520 ,משהד ,187 ,אלעמאיר
520 ,משהד ,284 ,אלרים
520 ,משהד ,188 ,אלרינה
520 ,משהד ,189 ,אלשיך
520 ,משהד ,190 ,אלשמאליה
520 ,משהד ,191 ,אלתאנויה
520 ,משהד ,192 ,בג'דאד
520 ,משהד ,193 ,בדר
520 ,משהד ,194 ,בטריק
520 ,משהד ,195 ,ביילסאן
520 ,משהד ,196 ,ביירות
520 ,משהד ,197 ,ביסאן
520 ,משהד ,198 ,בלאבל
520 ,משהד ,199 ,בלאל
520 ,משהד ,200 ,בלוט
520 ,משהד ,201 ,בנו - הלאל
520 ,משהד ,202 ,ג'אמע אלנור
520 ,משהד ,203 ,ג'בל סיח'
520 ,משהד ,205 ,ג'ובראן
520 ,משהד ,204 ,ג'ורי
520 ,משהד ,206 ,ג'רג'יר
520 ,משהד ,207 ,ג'ריר
520 ,משהד ,208 ,גת חאפר
520 ,משהד ,209 ,ד'י יזן
520 ,משהד ,210 ,דאהר אל עומר
520 ,משהד ,211 ,דום
520 ,משהד ,212 ,דלבוז
520 ,משהד ,213 ,דמשק
520 ,משהד ,231 ,דרויש מחמוד
520 ,משהד ,214 ,הליון
520 ,משהד ,215 ,ואדי סאלם
520 ,משהד ,216 ,ואדי סמעאן
520 ,משהד ,217 ,זאן
520 ,משהד ,218 ,זופה
520 ,משהד ,219 ,זעתר
520 ,משהד ,220 ,זרזור
520 ,משהד ,221 ,ח'מיס
520 ,משהד ,222 ,חטין
520 ,משהד ,223 ,חיפה
520 ,משהד ,260 ,טוקאן פדוא
520 ,משהד ,224 ,יאסמין
520 ,משהד ,263 ,כמון
520 ,משהד ,281 ,כנא
520 ,משהד ,225 ,כנארי
520 ,משהד ,264 ,כסתנא
520 ,משהד ,267 ,כרואן
520 ,משהד ,226 ,כרז
520 ,משהד ,278 ,כרכדן
520 ,משהד ,227 ,לובנאן
520 ,משהד ,228 ,לוז
520 ,משהד ,229 ,למון
520 ,משהד ,230 ,מג'ידל
520 ,משהד ,232 ,מיי זיאדה
520 ,משהד ,233 ,מכה
520 ,משהד ,234 ,מנדלינא
520 ,משהד ,235 ,מנת'ור
520 ,משהד ,236 ,מעלול
520 ,משהד ,237 ,מרג' אבן עאמר
520 ,משהד ,9000 ,משהד
520 ,משהד ,238 ,נענע
520 ,משהד ,239 ,סאוסאן
520 ,משהד ,240 ,סדר
520 ,משהד ,241 ,סייד דרויש
520 ,משהד ,242 ,סיידנא עומר
520 ,משהד ,243 ,סיידנא עלי
520 ,משהד ,244 ,סירין
520 ,משהד ,245 ,סלאח אל דין
520 ,משהד ,246 ,סנדיאן
520 ,משהד ,247 ,סנונו
520 ,משהד ,248 ,סעד - זג'לול
520 ,משהד ,249 ,ספורייה
520 ,משהד ,250 ,עבד - אלוהאב
520 ,משהד ,251 ,עבד - אלנאסר
520 ,משהד ,252 ,עבד אללה מוסטפא
520 ,משהד ,253 ,עולם
520 ,משהד ,254 ,עומר אלמח'תאר
520 ,משהד ,255 ,עיון - ג'נאן
520 ,משהד ,256 ,עין - דבאש
520 ,משהד ,257 ,עין אלבידה
520 ,משהד ,258 ,עכא
520 ,משהד ,259 ,עמאן
520 ,משהד ,283 ,ענבר
520 ,משהד ,261 ,פיירוז
520 ,משהד ,262 ,פלסטין
520 ,משהד ,277 ,קורונפול
520 ,משהד ,265 ,קראסיה
520 ,משהד ,266 ,קרבונה
520 ,משהד ,268 ,ראג'ב
520 ,משהד ,269 ,ראס אלמנטאר
520 ,משהד ,270 ,רומאן
520 ,משהד ,271 ,ריחאן
520 ,משהד ,272 ,שחרור
520 ,משהד ,273 ,ת'ריא
520 ,משהד ,274 ,תוניס
520 ,משהד ,275 ,תות
520 ,משהד ,276 ,תין
3605 ,משואה ,9000 ,משואה
620 ,משואות יצחק ,115 ,האורן
620 ,משואות יצחק ,106 ,האשל
620 ,משואות יצחק ,102 ,הדקל
620 ,משואות יצחק ,112 ,הורד
620 ,משואות יצחק ,103 ,הזית
620 ,משואות יצחק ,110 ,החצב
620 ,משואות יצחק ,108 ,החרוב
620 ,משואות יצחק ,101 ,החרצית
620 ,משואות יצחק ,114 ,היקינטון
620 ,משואות יצחק ,109 ,הנורית
620 ,משואות יצחק ,111 ,הנרקיס
620 ,משואות יצחק ,113 ,הסתוונית
620 ,משואות יצחק ,104 ,הצבעוני
620 ,משואות יצחק ,105 ,הרימון
620 ,משואות יצחק ,107 ,התות
620 ,משואות יצחק ,9000 ,משואות יצחק
620 ,משואות יצחק ,116 ,סמטת האיריס
620 ,משואות יצחק ,118 ,סמטת החבצלת
620 ,משואות יצחק ,117 ,סמטת השושן
620 ,משואות יצחק ,119 ,סמטת כלנית
620 ,משואות יצחק ,120 ,סמטת רקפת
3785 ,משכיות ,9000 ,משכיות
670 ,משמר איילון ,102 ,האורן
670 ,משמר איילון ,101 ,האלה
670 ,משמר איילון ,103 ,האלון
670 ,משמר איילון ,105 ,הברוש
670 ,משמר איילון ,104 ,התאנה
670 ,משמר איילון ,9000 ,משמר איילון
563 ,משמר דוד ,9000 ,משמר דוד
732 ,משמר הירדן ,103 ,בני צפת
732 ,משמר הירדן ,101 ,המושבה
732 ,משמר הירדן ,104 ,המעיין
732 ,משמר הירדן ,108 ,העמק
732 ,משמר הירדן ,107 ,ורד הירדן
732 ,משמר הירדן ,9000 ,משמר הירדן
732 ,משמר הירדן ,106 ,נוף הגולן
732 ,משמר הירדן ,105 ,עטרת
732 ,משמר הירדן ,102 ,שושנת הירדן
395 ,משמר הנגב ,102 ,אולפן
395 ,משמר הנגב ,101 ,בית ראשון במולדת
395 ,משמר הנגב ,9000 ,משמר הנגב
130 ,משמר העמק ,101 ,מרכז קליטה
130 ,משמר העמק ,9000 ,משמר העמק
729 ,משמר השבעה ,105 ,אבטיחי מנחם
729 ,משמר השבעה ,102 ,איבניצקי דוד
729 ,משמר השבעה ,113 ,דולב
729 ,משמר השבעה ,111 ,הגפן
729 ,משמר השבעה ,116 ,הדס
729 ,משמר השבעה ,110 ,ההדרים
729 ,משמר השבעה ,117 ,הורד
729 ,משמר השבעה ,112 ,הזית
729 ,משמר השבעה ,135 ,המ"ג
729 ,משמר השבעה ,118 ,השיטה
729 ,משמר השבעה ,104 ,זעירא
729 ,משמר השבעה ,9000 ,משמר השבעה
729 ,משמר השבעה ,101 ,סמ יצחק
729 ,משמר השבעה ,106 ,עולמי יעקב
729 ,משמר השבעה ,103 ,קורדובה יצחק
729 ,משמר השבעה ,107 ,רוזנבלום קלמן
729 ,משמר השבעה ,108 ,שד הדקלים
729 ,משמר השבעה ,6500 ,שיכון הבנים
729 ,משמר השבעה ,109 ,שמיר אליהו
729 ,משמר השבעה ,115 ,שקד
729 ,משמר השבעה ,114 ,תמר
194 ,משמר השרון ,101 ,אולפן
194 ,משמר השרון ,9000 ,משמר השרון
213 ,משמרות ,9000 ,משמרות
425 ,משמרת ,9000 ,משמרת
791 ,משען ,9000 ,משען
1315 ,מתן ,101 ,אורן
1315 ,מתן ,113 ,איריס
1315 ,מתן ,118 ,אלון
1315 ,מתן ,125 ,דרך הנוער
1315 ,מתן ,103 ,הגפן
1315 ,מתן ,107 ,הדס
1315 ,מתן ,105 ,הדר
1315 ,מתן ,104 ,הזית
1315 ,מתן ,102 ,התאנה
1315 ,מתן ,119 ,ורד
1315 ,מתן ,109 ,חבצלת
1315 ,מתן ,106 ,חלמיש
1315 ,מתן ,110 ,חצב
1315 ,מתן ,112 ,כלנית
1315 ,מתן ,108 ,מור
1315 ,מתן ,9000 ,מתן
1315 ,מתן ,117 ,נוף השרון
1315 ,מתן ,115 ,נורית
1315 ,מתן ,121 ,נרקיס
1315 ,מתן ,122 ,סביון
1315 ,מתן ,114 ,צבעוני
1315 ,מתן ,120 ,צור
1315 ,מתן ,124 ,רותם
1315 ,מתן ,123 ,רימון
1315 ,מתן ,116 ,רקפת
1315 ,מתן ,111 ,שושן
1184 ,מתת ,9000 ,מתת
3648 ,מתתיהו ,105 ,האדמו"ר מצאנז
3648 ,מתתיהו ,102 ,הרב אברמסקי יחזקאל
3648 ,מתתיהו ,103 ,הרב גיפטר מרדכי
3648 ,מתתיהו ,104 ,הרב דסלר
3648 ,מתתיהו ,106 ,הרב הולנדר יצחק
3648 ,מתתיהו ,111 ,הרב ווסרמן
3648 ,מתתיהו ,109 ,הרב מאלין
3648 ,מתתיהו ,110 ,הרב מילר אביגדור
3648 ,מתתיהו ,115 ,הרב מנדלוביץ
3648 ,מתתיהו ,113 ,הרב סגל
3648 ,מתתיהו ,112 ,הרב סילבר אליעזר
3648 ,מתתיהו ,101 ,הרב פיינשטיין משה
3648 ,מתתיהו ,108 ,הרב קוטלר אהרון
3648 ,מתתיהו ,107 ,הרב קמניצקי יעקב
3648 ,מתתיהו ,114 ,הרב שיינברג
3648 ,מתתיהו ,9000 ,מתתיהו
4551 ,נאות גולן ,9000 ,נאות גולן
1124 ,נאות הכיכר ,9000 ,נאות הכיכר
408 ,נאות מרדכי ,101 ,בית ראשון
408 ,נאות מרדכי ,9000 ,נאות מרדכי
1197 ,נאות סמדר ,9000 ,נאות סמדר
524 ,נאעורה ,9000 ,נאעורה
396 ,נבטים ,110 ,אגס
396 ,נבטים ,101 ,אולפן
396 ,נבטים ,108 ,גיפסנית
396 ,נבטים ,102 ,ורד
396 ,נבטים ,116 ,זית
396 ,נבטים ,105 ,חמניה
396 ,נבטים ,117 ,לימון
396 ,נבטים ,9000 ,נבטים
396 ,נבטים ,106 ,נורית
396 ,נבטים ,109 ,סייפן
396 ,נבטים ,103 ,עדעד
396 ,נבטים ,107 ,ציפורן
396 ,נבטים ,115 ,רימון
396 ,נבטים ,114 ,שקד
396 ,נבטים ,111 ,תאנה
396 ,נבטים ,104 ,תורמוס
396 ,נבטים ,112 ,תמר
396 ,נבטים ,113 ,תפוח
315 ,נגבה ,112 ,אורן
315 ,נגבה ,108 ,איריס
315 ,נגבה ,111 ,אלון
315 ,נגבה ,116 ,הדקלים
315 ,נגבה ,110 ,הזית
315 ,נגבה ,101 ,המייסדים
315 ,נגבה ,102 ,הפרחים
315 ,נגבה ,107 ,חרצית
315 ,נגבה ,104 ,כלנית
315 ,נגבה ,9000 ,נגבה
315 ,נגבה ,106 ,עמק חפר
315 ,נגבה ,109 ,פרג
315 ,נגבה ,114 ,רימון
315 ,נגבה ,103 ,רקפת
315 ,נגבה ,115 ,שקד
315 ,נגבה ,113 ,תמר
3724 ,נגוהות ,9000 ,נגוהות
309 ,נהורה ,212 ,דרך הפרחים
309 ,נהורה ,103 ,האילנות
309 ,נהורה ,120 ,הברוש
309 ,נהורה ,102 ,הגפן
309 ,נהורה ,115 ,הדס
309 ,נהורה ,114 ,הדקל
309 ,נהורה ,110 ,הזית
309 ,נהורה ,107 ,החיטה
309 ,נהורה ,116 ,היסמין
309 ,נהורה ,112 ,הנרקיס
309 ,נהורה ,104 ,הרימון
309 ,נהורה ,119 ,השושנה
309 ,נהורה ,109 ,השעורה
309 ,נהורה ,113 ,השקד
309 ,נהורה ,105 ,התאנה
309 ,נהורה ,117 ,התמר
309 ,נהורה ,111 ,כלנית
309 ,נהורה ,106 ,לילך
309 ,נהורה ,9000 ,נהורה
309 ,נהורה ,108 ,צבעוני
309 ,נהורה ,118 ,רקפת
309 ,נהורה ,101 ,שכ קרוונים
80 ,נהלל ,101 ,ביס חקלאי תיכון
80 ,נהלל ,9000 ,נהלל
9100 ,נהריה ,217 ,אביר יעקב
9100 ,נהריה ,366 ,אדווה
9100 ,נהריה ,152 ,אדמית
9100 ,נהריה ,171 ,אהוד מנור
9100 ,נהריה ,180 ,אזור בנייה צפון
9100 ,נהריה ,6522 ,אזור תעשיה צפון
9100 ,נהריה ,410 ,אחד העם
9100 ,נהריה ,328 ,איסי לה מולינו
9100 ,נהריה ,512 ,איריס
9100 ,נהריה ,461 ,אירית
9100 ,נהריה ,158 ,אלי כהן
9100 ,נהריה ,177 ,אלי עמיר
9100 ,נהריה ,305 ,אליאס קלימיאן
9100 ,נהריה ,352 ,אלמוג
9100 ,נהריה ,354 ,אמנון
9100 ,נהריה ,240 ,אנילביץ
9100 ,נהריה ,174 ,ארז ביטון
9100 ,נהריה ,115 ,ארלוזורוב
9100 ,נהריה ,175 ,בוסתן ספרדי
9100 ,נהריה ,154 ,בורוכוב
9100 ,נהריה ,160 ,בי"ח הממשלתי
9100 ,נהריה ,110 ,ביאליק
9100 ,נהריה ,218 ,בילפלד
9100 ,נהריה ,414 ,בלפור
9100 ,נהריה ,457 ,בק ליאו
9100 ,נהריה ,444 ,בר יהודה
9100 ,נהריה ,235 ,בר כוכבא
9100 ,נהריה ,6513 ,גבעת אוסישקין
9100 ,נהריה ,6518 ,גבעת כצנלסון
9100 ,נהריה ,6514 ,גבעת רגום
9100 ,נהריה ,6500 ,גבעת שרת
9100 ,נהריה ,223 ,גדוד 21
9100 ,נהריה ,478 ,גדולי האומה
9100 ,נהריה ,423 ,גולומב
9100 ,נהריה ,131 ,גורדון
9100 ,נהריה ,206 ,גוש עציון
9100 ,נהריה ,448 ,גרינבוים
9100 ,נהריה ,510 ,גרניום
9100 ,נהריה ,362 ,דוגית
9100 ,נהריה ,422 ,דוד אלעזר
9100 ,נהריה ,143 ,דוד בן גאון
9100 ,נהריה ,279 ,דולב
9100 ,נהריה ,224 ,דרך העצמאות
9100 ,נהריה ,133 ,דרך השומר
9100 ,נהריה ,209 ,דרך השיירה
9100 ,נהריה ,476 ,דרך השלום
9100 ,נהריה ,211 ,דרך יחיעם
9100 ,נהריה ,213 ,דרך סטרומה
9100 ,נהריה ,459 ,דרך שלמה
9100 ,נהריה ,504 ,האדמונית
9100 ,נהריה ,136 ,האורן
9100 ,נהריה ,431 ,האיזדרכת
9100 ,נהריה ,433 ,האלון
9100 ,נהריה ,127 ,הארז
9100 ,נהריה ,427 ,הארנון
9100 ,נהריה ,134 ,האשל
9100 ,נהריה ,116 ,האתרוג
9100 ,נהריה ,118 ,הברוש
9100 ,נהריה ,511 ,הברקן
9100 ,נהריה ,142 ,הגאולה
9100 ,נהריה ,129 ,הגדוד העברי
9100 ,נהריה ,323 ,הגולן
9100 ,נהריה ,226 ,הגלעד
9100 ,נהריה ,502 ,הגמא
9100 ,נהריה ,465 ,הגנים
9100 ,נהריה ,111 ,הגפן
9100 ,נהריה ,513 ,הדבורנית
9100 ,נהריה ,351 ,הדולפין
9100 ,נהריה ,125 ,הדליה
9100 ,נהריה ,324 ,הדרור
9100 ,נהריה ,107 ,ההגנה
9100 ,נהריה ,117 ,ההדס
9100 ,נהריה ,106 ,ההדר
9100 ,נהריה ,122 ,הורד
9100 ,נהריה ,212 ,הזיתים
9100 ,נהריה ,325 ,הזמיר
9100 ,נהריה ,477 ,החזון
9100 ,נהריה ,130 ,החייל
9100 ,נהריה ,201 ,החלוץ
9100 ,נהריה ,121 ,החמנית
9100 ,נהריה ,205 ,החשמונאים
9100 ,נהריה ,522 ,הטפח
9100 ,נהריה ,153 ,היוצרים
9100 ,נהריה ,434 ,הירדן
9100 ,נהריה ,435 ,הירקון
9100 ,נהריה ,138 ,הכלנית
9100 ,נהריה ,264 ,הכרם
9100 ,נהריה ,254 ,הל"ה
9100 ,נהריה ,509 ,הליטים
9100 ,נהריה ,360 ,המגדלור
9100 ,נהריה ,436 ,המגינים
9100 ,נהריה ,417 ,המיסדים
9100 ,נהריה ,306 ,המלך דוד
9100 ,נהריה ,355 ,המעגן
9100 ,נהריה ,420 ,המעפילים
9100 ,נהריה ,363 ,המפרש
9100 ,נהריה ,507 ,המרוה
9100 ,נהריה ,229 ,הנגב
9100 ,נהריה ,137 ,הנורית
9100 ,נהריה ,515 ,הניסנית
9100 ,נהריה ,126 ,הנרקיס
9100 ,נהריה ,475 ,הנשיא
9100 ,נהריה ,449 ,הס משה
9100 ,נהריה ,113 ,הסביון
9100 ,נהריה ,503 ,הסיגלית
9100 ,נהריה ,521 ,הסיפן
9100 ,נהריה ,524 ,הסיתונית
9100 ,נהריה ,421 ,העליה
9100 ,נהריה ,103 ,הערבה
9100 ,נהריה ,128 ,הפלמ"ח
9100 ,נהריה ,353 ,הפנינה
9100 ,נהריה ,505 ,הפעמונית
9100 ,נהריה ,112 ,הפרג
9100 ,נהריה ,455 ,הפרטיזנים
9100 ,נהריה ,365 ,הצדף
9100 ,נהריה ,124 ,הציפורן
9100 ,נהריה ,514 ,הצלף
9100 ,נהריה ,276 ,הצנחנים
9100 ,נהריה ,428 ,הקישון
9100 ,נהריה ,236 ,הר מירון
9100 ,נהריה ,230 ,הר תבור
9100 ,נהריה ,238 ,הרב מימון
9100 ,נהריה ,317 ,הרב קוק
9100 ,נהריה ,104 ,הרדוף
9100 ,נהריה ,119 ,הרימון
9100 ,נהריה ,315 ,הרצוג
9100 ,נהריה ,404 ,הרצל
9100 ,נהריה ,139 ,הרקפת
9100 ,נהריה ,123 ,השושנה
9100 ,נהריה ,437 ,השיקמה
9100 ,נהריה ,231 ,השרון
9100 ,נהריה ,120 ,התאנה
9100 ,נהריה ,364 ,התורן
9100 ,נהריה ,517 ,התות
9100 ,נהריה ,519 ,התלתן
9100 ,נהריה ,426 ,וולפסון
9100 ,נהריה ,232 ,וייסבורג
9100 ,נהריה ,411 ,ויצמן
9100 ,נהריה ,416 ,ז'בוטינסקי
9100 ,נהריה ,215 ,זיסו
9100 ,נהריה ,233 ,זמנהוף
9100 ,נהריה ,105 ,חבצלת החוף
9100 ,נהריה ,250 ,חומה ומגדל
9100 ,נהריה ,161 ,חיים לבב
9100 ,נהריה ,135 ,חניתה
9100 ,נהריה ,308 ,חרמון
9100 ,נהריה ,520 ,חרצית
9100 ,נהריה ,407 ,טיילת אופירה נבון
9100 ,נהריה ,204 ,טרומפלדור
9100 ,נהריה ,253 ,י"ד ליל הגשרים
9100 ,נהריה ,132 ,יבנה
9100 ,נהריה ,202 ,יהודה המכבי
9100 ,נהריה ,277 ,יחידה 101
9100 ,נהריה ,151 ,ימבור יוסף
9100 ,נהריה ,147 ,יסמין
9100 ,נהריה ,301 ,יפה נוף
9100 ,נהריה ,463 ,יקינטון
9100 ,נהריה ,309 ,ירושלים
9100 ,נהריה ,441 ,ישורון
9100 ,נהריה ,251 ,כ"ג יורדי הסירה
9100 ,נהריה ,350 ,כוכב הים
9100 ,נהריה ,6001 ,ככר הדקל
9100 ,נהריה ,6002 ,ככר העיריה
9100 ,נהריה ,271 ,כליל החורש
9100 ,נהריה ,425 ,לבון פנחס
9100 ,נהריה ,409 ,לוחמי הגטאות
9100 ,נהריה ,501 ,לוטוס
9100 ,נהריה ,506 ,לוטם
9100 ,נהריה ,430 ,לוי יוסף
9100 ,נהריה ,450 ,לוין שמריהו
9100 ,נהריה ,272 ,ליבנה
9100 ,נהריה ,479 ,מבצע אנטבה
9100 ,נהריה ,252 ,מבצע חירם
9100 ,נהריה ,256 ,מבצע יונתן
9100 ,נהריה ,255 ,מבצע קדש
9100 ,נהריה ,316 ,מוריה
9100 ,נהריה ,472 ,מלון פלס אתנה
9100 ,נהריה ,439 ,מלון תפוז
9100 ,נהריה ,275 ,מלחמת יום הכיפורים
9100 ,נהריה ,156 ,מנביץ
9100 ,נהריה ,145 ,מסריק
9100 ,נהריה ,451 ,מעוז הים
9100 ,נהריה ,207 ,מצדה
9100 ,נהריה ,144 ,מרגנית
9100 ,נהריה ,446 ,מרכז הקליטה
9100 ,נהריה ,6512 ,נאות אלה
9100 ,נהריה ,6502 ,נאות ים
9100 ,נהריה ,9000 ,נהריה
9100 ,נהריה ,6501 ,נווה אלון
9100 ,נהריה ,6517 ,נווה יצחק רבין
9100 ,נהריה ,6515 ,נווה מנחם
9100 ,נהריה ,500 ,נופר
9100 ,נהריה ,419 ,נורדאו
9100 ,נהריה ,326 ,נחליאלי
9100 ,נהריה ,216 ,נחלת אשר
9100 ,נהריה ,304 ,נחמיה
9100 ,נהריה ,357 ,נחשול
9100 ,נהריה ,234 ,נימרובר
9100 ,נהריה ,172 ,נעמי שמר
9100 ,נהריה ,516 ,נר הלילה
9100 ,נהריה ,327 ,נשר
9100 ,נהריה ,408 ,סולד
9100 ,נהריה ,320 ,סוסקין
9100 ,נהריה ,401 ,סוקולוב
9100 ,נהריה ,523 ,סחלב
9100 ,נהריה ,432 ,סילבר
9100 ,נהריה ,263 ,סירקין נחמן
9100 ,נהריה ,258 ,סמ ביל"ו
9100 ,נהריה ,257 ,סמ ניל"י
9100 ,נהריה ,442 ,סמטת סיני
9100 ,נהריה ,173 ,סמי מיכאל
9100 ,נהריה ,314 ,סנה משה
9100 ,נהריה ,367 ,סנפיר
9100 ,נהריה ,225 ,סנש חנה
9100 ,נהריה ,447 ,עגנון
9100 ,נהריה ,464 ,עדעד
9100 ,נהריה ,176 ,עוזי חיטמן
9100 ,נהריה ,303 ,עזרא
9100 ,נהריה ,114 ,עין התכלת
9100 ,נהריה ,227 ,עמק יזרעאל
9100 ,נהריה ,249 ,פולד ברכה
9100 ,נהריה ,149 ,פורצי כלא עכו
9100 ,נהריה ,466 ,פטוניה
9100 ,נהריה ,415 ,פינסקר
9100 ,נהריה ,508 ,צבעוני
9100 ,נהריה ,358 ,צלופח
9100 ,נהריה ,214 ,קבוץ גלויות
9100 ,נהריה ,454 ,קורצ'ק ינוש
9100 ,נהריה ,402 ,קפלן
9100 ,נהריה ,141 ,קק"ל
9100 ,נהריה ,6516 ,קרית בן גוריון
9100 ,נהריה ,6520 ,קרית גרשון טץ
9100 ,נהריה ,6519 ,קרית החינוך
9100 ,נהריה ,445 ,קרן היסוד
9100 ,נהריה ,210 ,רבי עקיבא
9100 ,נהריה ,518 ,רותם
9100 ,נהריה ,319 ,רייך שמעון
9100 ,נהריה ,460 ,רייצ'ק עזריאל
9100 ,נהריה ,248 ,רייק חביבה
9100 ,נהריה ,356 ,ריף
9100 ,נהריה ,208 ,רמב"ם
9100 ,נהריה ,413 ,רמז
9100 ,נהריה ,6504 ,ש ממשלתי
9100 ,נהריה ,6505 ,ש מעון אקדמאים
9100 ,נהריה ,6506 ,ש עמידר
9100 ,נהריה ,6509 ,ש קרית חוף
9100 ,נהריה ,6507 ,ש קרית יוספטל
9100 ,נהריה ,6508 ,ש קרית עשור
9100 ,נהריה ,6510 ,ש רסקו
9100 ,נהריה ,307 ,שאול המלך
9100 ,נהריה ,318 ,שבזי
9100 ,נהריה ,242 ,שביל דבורה
9100 ,נהריה ,468 ,שביל דדון דוד
9100 ,נהריה ,429 ,שביל האלה
9100 ,נהריה ,453 ,שביל הימיה
9100 ,נהריה ,239 ,שביל הכרם
9100 ,נהריה ,260 ,שביל הלל
9100 ,נהריה ,452 ,שביל המעונה
9100 ,נהריה ,261 ,שביל הפרדס
9100 ,נהריה ,462 ,שביל הצבר
9100 ,נהריה ,456 ,שביל השיטה
9100 ,נהריה ,146 ,שביל התמר
9100 ,נהריה ,241 ,שביל חנה
9100 ,נהריה ,245 ,שביל יוחנן
9100 ,נהריה ,312 ,שביל יונתן
9100 ,נהריה ,244 ,שביל יעל
9100 ,נהריה ,262 ,שביל מצובה
9100 ,נהריה ,243 ,שביל מרים
9100 ,נהריה ,247 ,שביל מתתיהו
9100 ,נהריה ,259 ,שביל שמאי
9100 ,נהריה ,246 ,שביל שמעון
9100 ,נהריה ,321 ,שד אשכול
9100 ,נהריה ,228 ,שד בן צבי
9100 ,נהריה ,412 ,שד הגעתון
9100 ,נהריה ,403 ,שד קיי אברהם
9100 ,נהריה ,274 ,שד' הבאבא סאלי
9100 ,נהריה ,270 ,שד' ששת הימים
9100 ,נהריה ,140 ,שדה יצחק
9100 ,נהריה ,440 ,שדרות ורה
9100 ,נהריה ,458 ,שדרת שז"ר
9100 ,נהריה ,359 ,שונית
9100 ,נהריה ,361 ,שחף
9100 ,נהריה ,443 ,שטיינהרט
9100 ,נהריה ,418 ,שטינמץ מקס
9100 ,נהריה ,438 ,שטראוס הילדה
9100 ,נהריה ,6521 ,שכ גבעת טרומפלדור
9100 ,נהריה ,6523 ,שכ מרכז
9100 ,נהריה ,6511 ,שכ עין שרה
9100 ,נהריה ,6529 ,שכ' אריק שרון
9100 ,נהריה ,6526 ,שכ' יצחק נבון
9100 ,נהריה ,6528 ,שכ' יצחק שמיר
9100 ,נהריה ,6525 ,שכ' סם בויימל
9100 ,נהריה ,6527 ,שכ' שמעון פרס
9100 ,נהריה ,278 ,שלום הגליל
9100 ,נהריה ,302 ,שלמה המלך
9100 ,נהריה ,155 ,שפירא
9100 ,נהריה ,322 ,שפרינצק
9100 ,נהריה ,467 ,שקד
9100 ,נהריה ,313 ,שריר אפרים
9100 ,נהריה ,203 ,תל חי
4304 ,נוב ,9000 ,נוב
55 ,נוגה ,109 ,הגפן
55 ,נוגה ,108 ,הוורד
55 ,נוגה ,103 ,הזית
55 ,נוגה ,105 ,הכלנית
55 ,נוגה ,107 ,הסיגלית
55 ,נוגה ,104 ,הרימון
55 ,נוגה ,106 ,התאנה
55 ,נוגה ,102 ,התמר
55 ,נוגה ,9000 ,נוגה
55 ,נוגה ,110 ,רותם
55 ,נוגה ,101 ,שדרות הרקפת
3573 ,נוה צוף ,132 ,אגוז
3573 ,נוה צוף ,104 ,אורן
3573 ,נוה צוף ,119 ,אלון
3573 ,נוה צוף ,130 ,אצ"ל
3573 ,נוה צוף ,118 ,ארז
3573 ,נוה צוף ,126 ,אתרוג
3573 ,נוה צוף ,121 ,ברוש
3573 ,נוה צוף ,107 ,גפן
3573 ,נוה צוף ,112 ,דבש
3573 ,נוה צוף ,108 ,דגן
3573 ,נוה צוף ,123 ,דקל
3573 ,נוה צוף ,105 ,האלה
3573 ,נוה צוף ,134 ,הבוסתן
3573 ,נוה צוף ,114 ,הביכורים
3573 ,נוה צוף ,125 ,הדס
3573 ,נוה צוף ,129 ,ההגנה
3573 ,נוה צוף ,122 ,החרוב
3573 ,נוה צוף ,110 ,המייסדים
3573 ,נוה צוף ,133 ,המצודה
3573 ,נוה צוף ,135 ,השומר
3573 ,נוה צוף ,113 ,זית שמן
3573 ,נוה צוף ,101 ,חיטה
3573 ,נוה צוף ,115 ,יצהר
3573 ,נוה צוף ,109 ,לבונה
3573 ,נוה צוף ,127 ,לוחמי המחתרות
3573 ,נוה צוף ,131 ,לח"י
3573 ,נוה צוף ,117 ,מעלה החורש
3573 ,נוה צוף ,9000 ,נוה צוף
3573 ,נוה צוף ,128 ,ניל"י
3573 ,נוה צוף ,124 ,ערבי נחל
3573 ,נוה צוף ,111 ,רימון
3573 ,נוה צוף ,136 ,שבעת המינים
3573 ,נוה צוף ,106 ,שעורה
3573 ,נוה צוף ,120 ,שקד
3573 ,נוה צוף ,103 ,תאנה
3573 ,נוה צוף ,137 ,תורה ועבודה
3573 ,נוה צוף ,116 ,תירוש
3573 ,נוה צוף ,102 ,תמר
1366 ,נווה ,9000 ,נווה
926 ,נווה אבות ,9000 ,נווה אבות
590 ,נווה אור ,101 ,בית ראשון במולדת
590 ,נווה אור ,9000 ,נווה אור
4303 ,נווה אטי"ב ,9000 ,נווה אטי"ב
405 ,נווה אילן ,105 ,אגדת דשא
405 ,נווה אילן ,106 ,אל גינת אגוז
405 ,נווה אילן ,119 ,באר בשדה
405 ,נווה אילן ,115 ,גן השקמים
405 ,נווה אילן ,111 ,דרך התקוה
405 ,נווה אילן ,116 ,ההר הירוק
405 ,נווה אילן ,118 ,הפרח בגני
405 ,נווה אילן ,114 ,הרעות
405 ,נווה אילן ,112 ,השיירה
405 ,נווה אילן ,120 ,חופים
405 ,נווה אילן ,121 ,ירושלים של זהב
405 ,נווה אילן ,117 ,ירח
405 ,נווה אילן ,6002 ,ככר ים השיבולים
405 ,נווה אילן ,6001 ,ככר כלניות
405 ,נווה אילן ,6003 ,ככר רקפת
405 ,נווה אילן ,123 ,מקהלה עליזה
405 ,נווה אילן ,9000 ,נווה אילן
405 ,נווה אילן ,113 ,נוף ילדות
405 ,נווה אילן ,108 ,סימני דרך
405 ,נווה אילן ,110 ,עץ הרימון
405 ,נווה אילן ,109 ,עץ השדה
405 ,נווה אילן ,107 ,פרפר נחמד
405 ,נווה אילן ,122 ,צל עץ התמר
405 ,נווה אילן ,104 ,רוח סתיו
405 ,נווה אילן ,102 ,שבולת בשדה
405 ,נווה אילן ,101 ,שיר לשלום
405 ,נווה אילן ,103 ,שני שושנים
296 ,נווה איתן ,9000 ,נווה איתן
3725 ,נווה דניאל ,101 ,אלוני ממרא
3725 ,נווה דניאל ,102 ,בריכות שלמה
3725 ,נווה דניאל ,103 ,דרך אפרתה
3725 ,נווה דניאל ,104 ,דרך האבות
3725 ,נווה דניאל ,105 ,המוריה
3725 ,נווה דניאל ,112 ,הרי יהודה
3725 ,נווה דניאל ,106 ,מגדל עדר
3725 ,נווה דניאל ,9000 ,נווה דניאל
3725 ,נווה דניאל ,111 ,נוף הרודיון
3725 ,נווה דניאל ,107 ,נחל אשכול
3725 ,נווה דניאל ,108 ,נחל המעיינות
3725 ,נווה דניאל ,109 ,עמק ברכה
3725 ,נווה דניאל ,113 ,שדה המכפלה
3725 ,נווה דניאל ,110 ,שיבת רחל
1057 ,נווה זוהר ,101 ,דרך יהודה אלמוג
1057 ,נווה זוהר ,9000 ,נווה זוהר
1057 ,נווה זוהר ,7002 ,סדום
1057 ,נווה זוהר ,7001 ,עין בוקק
1314 ,נווה זיו ,110 ,דרך התמר
1314 ,נווה זיו ,101 ,הכלנית
1314 ,נווה זיו ,102 ,חצב
1314 ,נווה זיו ,103 ,יסמין
1314 ,נווה זיו ,104 ,כרכום
1314 ,נווה זיו ,105 ,מרגלית
1314 ,נווה זיו ,106 ,מרווה
1314 ,נווה זיו ,9000 ,נווה זיו
1314 ,נווה זיו ,107 ,נרקיס
1314 ,נווה זיו ,108 ,סביון
1314 ,נווה זיו ,109 ,סחלב
1314 ,נווה זיו ,111 ,צבעוני
1314 ,נווה זיו ,112 ,רקפת
1279 ,נווה חריף ,9000 ,נווה חריף
312 ,נווה ים ,9000 ,נווה ים
686 ,נווה ימין ,110 ,האגוז
686 ,נווה ימין ,111 ,האורן
686 ,נווה ימין ,103 ,הברוש
686 ,נווה ימין ,102 ,הגפן
686 ,נווה ימין ,105 ,הדקל
686 ,נווה ימין ,109 ,הדר
686 ,נווה ימין ,108 ,הזית
686 ,נווה ימין ,106 ,הערבה
686 ,נווה ימין ,104 ,הפיקוס
686 ,נווה ימין ,107 ,הרימון
686 ,נווה ימין ,101 ,התמר
686 ,נווה ימין ,9000 ,נווה ימין
686 ,נווה ימין ,6500 ,שיכון הבנים
858 ,נווה ירק ,101 ,האגוז
858 ,נווה ירק ,102 ,הברוש
858 ,נווה ירק ,103 ,הגפן
858 ,נווה ירק ,104 ,הזית
858 ,נווה ירק ,105 ,הפרדס
858 ,נווה ירק ,106 ,הרימון
858 ,נווה ירק ,107 ,השיזף
858 ,נווה ירק ,108 ,השקד
858 ,נווה ירק ,9000 ,נווה ירק
827 ,נווה מבטח ,102 ,האלומות
827 ,נווה מבטח ,105 ,האסיף
827 ,נווה מבטח ,104 ,הדגן
827 ,נווה מבטח ,107 ,החריש
827 ,נווה מבטח ,108 ,הקציר
827 ,נווה מבטח ,106 ,מגוב
827 ,נווה מבטח ,103 ,מורג
827 ,נווה מבטח ,9000 ,נווה מבטח
827 ,נווה מבטח ,101 ,שיבולים
1071 ,נווה מיכאל ,9000 ,נווה מיכאל
1259 ,נווה שלום ,9000 ,נווה שלום
15 ,נועם ,9000 ,נועם
1333 ,נוף איילון ,101 ,אריאל
1333 ,נוף איילון ,104 ,ברקת
1333 ,נוף איילון ,103 ,דביר
1333 ,נוף איילון ,109 ,החושן
1333 ,נוף איילון ,106 ,יהלום
1333 ,נוף איילון ,105 ,לשם
1333 ,נוף איילון ,102 ,מוריה
1333 ,נוף איילון ,9000 ,נוף איילון
1333 ,נוף איילון ,108 ,ספיר
1333 ,נוף איילון ,107 ,שוהם
1061 ,נוף הגליל ,286 ,אגוז
1061 ,נוף הגליל ,251 ,אגמון
1061 ,נוף הגליל ,276 ,אדמון
1061 ,נוף הגליל ,206 ,אורה
1061 ,נוף הגליל ,115 ,אורנים
1061 ,נוף הגליל ,6517 ,אזור תעש ציפורית
1061 ,נוף הגליל ,6501 ,אזור תעשיה הר יונה
1061 ,נוף הגליל ,6521 ,אזור תעשיה ומלאכה א
1061 ,נוף הגליל ,6520 ,אזור תעשיה מרכז
1061 ,נוף הגליל ,116 ,איריס
1061 ,נוף הגליל ,252 ,אלומה
1061 ,נוף הגליל ,253 ,אלמוג
1061 ,נוף הגליל ,226 ,אמרי סופר
1061 ,נוף הגליל ,102 ,ארבל
1061 ,נוף הגליל ,182 ,ארגמן
1061 ,נוף הגליל ,198 ,ארז
1061 ,נוף הגליל ,290 ,ארזי הלבנון
1061 ,נוף הגליל ,254 ,אשוח
1061 ,נוף הגליל ,225 ,בינת יששכר
1061 ,נוף הגליל ,197 ,ברוש
1061 ,נוף הגליל ,6518 ,גבעת ברק
1061 ,נוף הגליל ,101 ,גולן
1061 ,נוף הגליל ,278 ,גלעד
1061 ,נוף הגליל ,279 ,גנות
1061 ,נוף הגליל ,280 ,דגנית
1061 ,נוף הגליל ,222 ,דובר שלום
1061 ,נוף הגליל ,196 ,דולב
1061 ,נוף הגליל ,315 ,דליה
1061 ,נוף הגליל ,255 ,דפנה
1061 ,נוף הגליל ,118 ,דרך אמיר
1061 ,נוף הגליל ,145 ,דרך אריאל שרון
1061 ,נוף הגליל ,275 ,דרך הגליל
1061 ,נוף הגליל ,161 ,דרך החטיבות
1061 ,נוף הגליל ,158 ,דרך הציונות
1061 ,נוף הגליל ,124 ,דרך קרית הממשלה
1061 ,נוף הגליל ,221 ,האדמו"ר מבעלזא
1061 ,נוף הגליל ,236 ,האחווה
1061 ,נוף הגליל ,287 ,האלה
1061 ,נוף הגליל ,168 ,האלונים
1061 ,נוף הגליל ,289 ,האשור
1061 ,נוף הגליל ,285 ,האשל
1061 ,נוף הגליל ,231 ,הבוסתן
1061 ,נוף הגליל ,270 ,הגביש
1061 ,נוף הגליל ,160 ,הגבעה
1061 ,נוף הגליל ,318 ,הגומא
1061 ,נוף הגליל ,130 ,הגיא
1061 ,נוף הגליל ,143 ,הגפן
1061 ,נוף הגליל ,201 ,הדסים
1061 ,נוף הגליל ,185 ,הדר
1061 ,נוף הגליל ,157 ,הזית
1061 ,נוף הגליל ,203 ,החבצלת
1061 ,נוף הגליל ,151 ,החיטה
1061 ,נוף הגליל ,146 ,החצב
1061 ,נוף הגליל ,190 ,החרושת
1061 ,נוף הגליל ,234 ,הידידות
1061 ,נוף הגליל ,239 ,היונה
1061 ,נוף הגליל ,307 ,היוצר
1061 ,נוף הגליל ,191 ,היצירה
1061 ,נוף הגליל ,303 ,היתד
1061 ,נוף הגליל ,304 ,הלהב
1061 ,נוף הגליל ,301 ,המוביל
1061 ,נוף הגליל ,235 ,המוכתר מוהנא
1061 ,נוף הגליל ,163 ,המורד
1061 ,נוף הגליל ,122 ,המלאכה
1061 ,נוף הגליל ,183 ,המעוז
1061 ,נוף הגליל ,269 ,המעיין
1061 ,נוף הגליל ,305 ,המרכבה
1061 ,נוף הגליל ,215 ,המתכת
1061 ,נוף הגליל ,302 ,הסדנא
1061 ,נוף הגליל ,136 ,הספורט
1061 ,נוף הגליל ,193 ,העבודה
1061 ,נוף הגליל ,147 ,העליה
1061 ,נוף הגליל ,216 ,העמל
1061 ,נוף הגליל ,135 ,הפסגות
1061 ,נוף הגליל ,192 ,הפריון
1061 ,נוף הגליל ,141 ,הצבר
1061 ,נוף הגליל ,271 ,הצורף
1061 ,נוף הגליל ,317 ,הצלף
1061 ,נוף הגליל ,293 ,הר מצדה
1061 ,נוף הגליל ,220 ,הרב אהרן ליב שטימן
1061 ,נוף הגליל ,272 ,הרב עובדיה יוסף
1061 ,נוף הגליל ,274 ,הרב פנחס מילר
1061 ,נוף הגליל ,273 ,הרב שלום אלישיב
1061 ,נוף הגליל ,256 ,הרדוף
1061 ,נוף הגליל ,292 ,הרי יהודה
1061 ,נוף הגליל ,291 ,הרי ירושלים
1061 ,נוף הגליל ,294 ,הרי מנשה
1061 ,נוף הגליל ,202 ,השושנים
1061 ,נוף הגליל ,164 ,השקמה
1061 ,נוף הגליל ,148 ,התאנה
1061 ,נוף הגליל ,184 ,התחדשות
1061 ,נוף הגליל ,277 ,ורדים
1061 ,נוף הגליל ,120 ,חב"ד
1061 ,נוף הגליל ,281 ,חיננית
1061 ,נוף הגליל ,137 ,חמניות
1061 ,נוף הגליל ,167 ,חרוב
1061 ,נוף הגליל ,144 ,חרוד
1061 ,נוף הגליל ,112 ,חרמון
1061 ,נוף הגליל ,306 ,חרשי העץ
1061 ,נוף הגליל ,106 ,יודפת
1061 ,נוף הגליל ,149 ,יזרעאל
1061 ,נוף הגליל ,138 ,יסמין
1061 ,נוף הגליל ,165 ,יעל
1061 ,נוף הגליל ,257 ,יערה
1061 ,נוף הגליל ,258 ,יקינטון
1061 ,נוף הגליל ,282 ,ירדן
1061 ,נוף הגליל ,259 ,ירקה
1061 ,נוף הגליל ,134 ,כביש טבריה
1061 ,נוף הגליל ,310 ,כוכבית
1061 ,נוף הגליל ,6001 ,ככר אלי כהן
1061 ,נוף הגליל ,6002 ,ככר הגליל
1061 ,נוף הגליל ,316 ,כליל החורש
1061 ,נוף הגליל ,105 ,כלניות
1061 ,נוף הגליל ,103 ,כסולות
1061 ,נוף הגליל ,313 ,כרכום
1061 ,נוף הגליל ,114 ,כרמל
1061 ,נוף הגליל ,6523 ,לב העיר
1061 ,נוף הגליל ,260 ,לבנה
1061 ,נוף הגליל ,128 ,לוטם
1061 ,נוף הגליל ,194 ,לולב
1061 ,נוף הגליל ,261 ,לילך
1061 ,נוף הגליל ,169 ,לפידות
1061 ,נוף הגליל ,121 ,מגידו
1061 ,נוף הגליל ,262 ,מורן
1061 ,נוף הגליל ,107 ,מירון
1061 ,נוף הגליל ,152 ,מלון גני נצרת
1061 ,נוף הגליל ,109 ,מנחם אריאב
1061 ,נוף הגליל ,155 ,מעון אקדמאים
1061 ,נוף הגליל ,154 ,מעון סטודנטים
1061 ,נוף הגליל ,240 ,מעלה הראשונים
1061 ,נוף הגליל ,159 ,מעלה יצחק
1061 ,נוף הגליל ,127 ,מעלה עלית
1061 ,נוף הגליל ,283 ,מרגנית
1061 ,נוף הגליל ,6502 ,מרכז דקל
1061 ,נוף הגליל ,6503 ,מרכז לבון
1061 ,נוף הגליל ,153 ,מרכז קליטה
1061 ,נוף הגליל ,119 ,משעול דבורה
1061 ,נוף הגליל ,9000 ,נוף הגליל
1061 ,נוף הגליל ,6516 ,נוף יזרעאל
1061 ,נוף הגליל ,117 ,נוף תבור
1061 ,נוף הגליל ,139 ,נורית
1061 ,נוף הגליל ,129 ,נטופה
1061 ,נוף הגליל ,263 ,ניצן
1061 ,נוף הגליל ,284 ,ניצנית
1061 ,נוף הגליל ,113 ,נרקיסים
1061 ,נוף הגליל ,181 ,סביון
1061 ,נוף הגליל ,233 ,סחלב
1061 ,נוף הגליל ,264 ,סיגלית
1061 ,נוף הגליל ,140 ,סיוון
1061 ,נוף הגליל ,312 ,סייפן
1061 ,נוף הגליל ,131 ,סיתוונית
1061 ,נוף הגליל ,319 ,סמ גומא
1061 ,נוף הגליל ,265 ,עדעד
1061 ,נוף הגליל ,207 ,עולש
1061 ,נוף הגליל ,266 ,עטרה
1061 ,נוף הגליל ,208 ,עירית
1061 ,נוף הגליל ,104 ,עצמון
1061 ,נוף הגליל ,204 ,ערבה
1061 ,נוף הגליל ,267 ,פעמונית
1061 ,נוף הגליל ,209 ,צבעוני
1061 ,נוף הגליל ,268 ,צורית
1061 ,נוף הגליל ,111 ,ציפורי
1061 ,נוף הגליל ,205 ,ציפורן
1061 ,נוף הגליל ,224 ,קדושת אהרון
1061 ,נוף הגליל ,311 ,קורנית
1061 ,נוף הגליל ,125 ,קישון
1061 ,נוף הגליל ,6519 ,קרית יצחק רבין
1061 ,נוף הגליל ,314 ,רותם
1061 ,נוף הגליל ,232 ,ריחן
1061 ,נוף הגליל ,142 ,רימון
1061 ,נוף הגליל ,6512 ,רמת האירוסים
1061 ,נוף הגליל ,126 ,רקפת
1061 ,נוף הגליל ,6504 ,ש אשכול
1061 ,נוף הגליל ,6505 ,ש דרומית
1061 ,נוף הגליל ,6506 ,ש זוג צעירים
1061 ,נוף הגליל ,6507 ,ש צבא הקבע
1061 ,נוף הגליל ,6508 ,ש רסקו
1061 ,נוף הגליל ,6515 ,ש שלום
1061 ,נוף הגליל ,108 ,שומרון
1061 ,נוף הגליל ,227 ,שיר מעון
1061 ,נוף הגליל ,6509 ,שכ בן גוריון
1061 ,נוף הגליל ,6525 ,שכ בנה ביתך (דרום)
1061 ,נוף הגליל ,6524 ,שכ בנה ביתך (צפון)
1061 ,נוף הגליל ,6510 ,שכ הכרמים
1061 ,נוף הגליל ,6514 ,שכ זאב
1061 ,נוף הגליל ,6522 ,שכ פינת עדן
1061 ,נוף הגליל ,6526 ,שכ צפונית
1061 ,נוף הגליל ,6513 ,שכ רמת גולדה מאיר
1061 ,נוף הגליל ,6527 ,שכ רמת שמיר
1061 ,נוף הגליל ,6511 ,שכונת ספיר
1061 ,נוף הגליל ,166 ,שקד
1061 ,נוף הגליל ,288 ,תדהר
1061 ,נוף הגליל ,150 ,תירוש
1061 ,נוף הגליל ,223 ,תכלת מרדכי
1061 ,נוף הגליל ,195 ,תמר
3790 ,נופים ,105 ,האורן
3790 ,נופים ,102 ,האיריס
3790 ,נופים ,106 ,הברוש
3790 ,נופים ,104 ,הדקל
3790 ,נופים ,101 ,הכלנית
3790 ,נופים ,103 ,הצבעוני
3790 ,נופים ,9000 ,נופים
3790 ,נופים ,107 ,סיתוונית
1284 ,נופית ,108 ,אביטל
1284 ,נופית ,111 ,איתנים
1284 ,נופית ,109 ,ארבל
1284 ,נופית ,110 ,גולן
1284 ,נופית ,104 ,גלבוע
1284 ,נופית ,107 ,געש
1284 ,נופית ,129 ,הבריכה
1284 ,נופית ,101 ,הגליל
1284 ,נופית ,113 ,החרמון
1284 ,נופית ,103 ,הכרמל
1284 ,נופית ,114 ,העמק
1284 ,נופית ,102 ,הפסגה
1284 ,נופית ,105 ,התבור
1284 ,נופית ,124 ,כנען
1284 ,נופית ,126 ,מירון
1284 ,נופית ,117 ,משעול אלונים
1284 ,נופית ,119 ,משעול האלה
1284 ,נופית ,118 ,משעול הגיא
1284 ,נופית ,123 ,משעול הרדוף
1284 ,נופית ,121 ,משעול זבולון
1284 ,נופית ,120 ,משעול עדי
1284 ,נופית ,116 ,משעול רכסים
1284 ,נופית ,9000 ,נופית
1284 ,נופית ,122 ,סופה
1284 ,נופית ,106 ,סער
1284 ,נופית ,127 ,עצמון
1284 ,נופית ,112 ,צפרירים
1284 ,נופית ,115 ,קדים
1284 ,נופית ,125 ,רמון
257 ,נופך ,101 ,אבני החושן
257 ,נופך ,103 ,אודם
257 ,נופך ,104 ,ברקת
257 ,נופך ,106 ,טורקיז
257 ,נופך ,108 ,יהלום
257 ,נופך ,107 ,לשם
257 ,נופך ,9000 ,נופך
257 ,נופך ,109 ,סמ הרב דדון
257 ,נופך ,105 ,ספיר
257 ,נופך ,102 ,תרשיש
3726 ,נוקדים ,300 ,האירוס
3726 ,נוקדים ,303 ,החלמונית
3726 ,נוקדים ,301 ,הניסנית
3726 ,נוקדים ,302 ,העירית
3726 ,נוקדים ,6501 ,חוות שדה בר
3726 ,נוקדים ,6500 ,כפר אלדד
3726 ,נוקדים ,6502 ,מעלה רחבעם
3726 ,נוקדים ,9000 ,נוקדים
3726 ,נוקדים ,111 ,נחל אשל
3726 ,נוקדים ,103 ,נחל גורפן
3726 ,נוקדים ,105 ,נחל דוד
3726 ,נוקדים ,110 ,נחל דרגות
3726 ,נוקדים ,108 ,נחל חבר
3726 ,נוקדים ,106 ,נחל חצצון
3726 ,נוקדים ,112 ,נחל יעלים
3726 ,נוקדים ,104 ,נחל עמוס
3726 ,נוקדים ,102 ,נחל עציונה
3726 ,נוקדים ,109 ,נחל ערוגות
3726 ,נוקדים ,113 ,נחל קדם
3726 ,נוקדים ,101 ,נחל קדרון
3726 ,נוקדים ,107 ,נחל תקוע
447 ,נורדיה ,149 ,דרך הבאר
447 ,נורדיה ,150 ,דרך הפרדס
447 ,נורדיה ,115 ,האגוז
447 ,נורדיה ,121 ,האורן
447 ,נורדיה ,116 ,האלון
447 ,נורדיה ,142 ,האלונים
447 ,נורדיה ,125 ,האתרוג
447 ,נורדיה ,104 ,הבוסתן
447 ,נורדיה ,141 ,הבנים
447 ,נורדיה ,107 ,הגפן
447 ,נורדיה ,108 ,ההדס
447 ,נורדיה ,110 ,הורד
447 ,נורדיה ,117 ,הזית
447 ,נורדיה ,112 ,החבצלת
447 ,נורדיה ,147 ,החירות
447 ,נורדיה ,109 ,הכלנית
447 ,נורדיה ,148 ,המייסדים
447 ,נורדיה ,143 ,המנורה
447 ,נורדיה ,111 ,המרגנית
447 ,נורדיה ,114 ,הסביון
447 ,נורדיה ,113 ,הערבה
447 ,נורדיה ,122 ,הצאלון
447 ,נורדיה ,119 ,הרימון
447 ,נורדיה ,103 ,השדות
447 ,נורדיה ,120 ,השיטה
447 ,נורדיה ,123 ,השקד
447 ,נורדיה ,118 ,התאנה
447 ,נורדיה ,102 ,התדהר
447 ,נורדיה ,124 ,התמר
447 ,נורדיה ,146 ,ווג'ווד
447 ,נורדיה ,6501 ,כפר הגמלאים
447 ,נורדיה ,130 ,מגדלי הים התיכון
447 ,נורדיה ,101 ,מלון נורדיה
447 ,נורדיה ,145 ,מרגולין
447 ,נורדיה ,6500 ,נאות נורדיה
447 ,נורדיה ,9000 ,נורדיה
447 ,נורדיה ,6502 ,קרוונים
447 ,נורדיה ,140 ,שד נורדיה
447 ,נורדיה ,144 ,תל חי
833 ,נורית ,108 ,אזוב
833 ,נורית ,112 ,בוסתן
833 ,נורית ,111 ,טרגון
833 ,נורית ,6004 ,כיכר הגלבוע
833 ,נורית ,6003 ,כיכר הנדיב
833 ,נורית ,6002 ,כיכר נדרה
833 ,נורית ,6001 ,כיכר קק"ל
833 ,נורית ,102 ,כליל החורש
833 ,נורית ,105 ,לבונה
833 ,נורית ,107 ,מרווה
833 ,נורית ,9000 ,נורית
833 ,נורית ,110 ,עירית
833 ,נורית ,109 ,ער אציל
833 ,נורית ,101 ,ערוגות הבושם
833 ,נורית ,106 ,ציפורן
833 ,נורית ,103 ,קורנית
833 ,נורית ,104 ,קינמון
833 ,נורית ,113 ,רוזמרין
59 ,נחושה ,9000 ,נחושה
844 ,נחל עוז ,9000 ,נחל עוז
2045 ,נחלה ,105 ,אודם
2045 ,נחלה ,104 ,ברקת
2045 ,נחלה ,102 ,יהלום
2045 ,נחלה ,101 ,לשם
2045 ,נחלה ,9000 ,נחלה
2045 ,נחלה ,103 ,ספיר
3767 ,נחליאל ,101 ,אבני החושן
3767 ,נחליאל ,102 ,אודם
3767 ,נחליאל ,103 ,ברקת
3767 ,נחליאל ,105 ,יהלום
3767 ,נחליאל ,112 ,ישפה
3767 ,נחליאל ,108 ,לשם
3767 ,נחליאל ,104 ,נופך
3767 ,נחליאל ,9000 ,נחליאל
3767 ,נחליאל ,109 ,ספיר
3767 ,נחליאל ,110 ,פטדה
3767 ,נחליאל ,111 ,שבו
3767 ,נחליאל ,106 ,שוהם
3767 ,נחליאל ,107 ,תרשיש
449 ,נחלים ,106 ,גמלא
449 ,נחלים ,102 ,דן
449 ,נחלים ,110 ,זויתן
449 ,נחלים ,103 ,חרמון
449 ,נחלים ,111 ,טל
449 ,נחלים ,101 ,ירדן
449 ,נחלים ,109 ,לשם
449 ,נחלים ,9000 ,נחלים
449 ,נחלים ,112 ,סער
449 ,נחלים ,108 ,קדש
449 ,נחלים ,105 ,קורן
449 ,נחלים ,107 ,שיאון
449 ,נחלים ,104 ,שניר
809 ,נחם ,101 ,גפן
809 ,נחם ,104 ,חיטה
809 ,נחם ,9000 ,נחם
809 ,נחם ,103 ,רימון
809 ,נחם ,105 ,שעורה
809 ,נחם ,102 ,תאנה
809 ,נחם ,106 ,תמר
522 ,נחף ,6730 ,אלביאדר
522 ,נחף ,6711 ,אלבלוע וקבור אלנסרה
522 ,נחף ,6712 ,אלחווז ואלעין
522 ,נחף ,6710 ,אלמריג'
522 ,נחף ,6731 ,אלקובסי
522 ,נחף ,6720 ,העיר העתיקה
522 ,נחף ,6713 ,זוגות צעירים
522 ,נחף ,6740 ,חקל אלמע'רה
522 ,נחף ,6732 ,חקל אלעמוד
522 ,נחף ,6741 ,כביש ראשי ואלדבה
522 ,נחף ,9000 ,נחף
522 ,נחף ,164 ,רח 1000
522 ,נחף ,224 ,רח 2000
522 ,נחף ,101 ,רח 100
522 ,נחף ,172 ,רח 1100
522 ,נחף ,226 ,רח 2100
522 ,נחף ,107 ,רח 200
522 ,נחף ,176 ,רח 1200
522 ,נחף ,227 ,רח 2200
522 ,נחף ,117 ,רח 300
522 ,נחף ,181 ,רח 1300
522 ,נחף ,124 ,רח 400
522 ,נחף ,190 ,רח 1400
522 ,נחף ,127 ,רח 500
522 ,נחף ,194 ,רח 1500
522 ,נחף ,132 ,רח 600
522 ,נחף ,199 ,רח 1600
522 ,נחף ,142 ,רח 700
522 ,נחף ,204 ,רח 1700
522 ,נחף ,153 ,רח 800
522 ,נחף ,217 ,רח 1800
522 ,נחף ,159 ,רח 900
522 ,נחף ,220 ,רח 1900
522 ,נחף ,112 ,רח 210
522 ,נחף ,122 ,רח 310
522 ,נחף ,216 ,רח 1710
522 ,נחף ,165 ,רח 1001
522 ,נחף ,102 ,רח 101
522 ,נחף ,173 ,רח 1101
522 ,נחף ,115 ,רח 201
522 ,נחף ,179 ,רח 1201
522 ,נחף ,228 ,רח 2201
522 ,נחף ,123 ,רח 301
522 ,נחף ,182 ,רח 1301
522 ,נחף ,125 ,רח 401
522 ,נחף ,130 ,רח 501
522 ,נחף ,195 ,רח 1501
522 ,נחף ,136 ,רח 601
522 ,נחף ,200 ,רח 1601
522 ,נחף ,144 ,רח 701
522 ,נחף ,205 ,רח 1701
522 ,נחף ,154 ,רח 801
522 ,נחף ,218 ,רח 1801
522 ,נחף ,162 ,רח 901
522 ,נחף ,233 ,רח 2211
522 ,נחף ,187 ,רח 1311
522 ,נחף ,141 ,רח 611
522 ,נחף ,149 ,רח 711
522 ,נחף ,210 ,רח 1711
522 ,נחף ,168 ,רח 1002
522 ,נחף ,225 ,רח 2002
522 ,נחף ,105 ,רח 102
522 ,נחף ,108 ,רח 202
522 ,נחף ,177 ,רח 1202
522 ,נחף ,118 ,רח 302
522 ,נחף ,188 ,רח 1302
522 ,נחף ,191 ,רח 1402
522 ,נחף ,128 ,רח 502
522 ,נחף ,196 ,רח 1502
522 ,נחף ,133 ,רח 602
522 ,נחף ,143 ,רח 702
522 ,נחף ,212 ,רח 1702
522 ,נחף ,156 ,רח 802
522 ,נחף ,160 ,רח 902
522 ,נחף ,221 ,רח 1902
522 ,נחף ,113 ,רח 212
522 ,נחף ,166 ,רח 1003
522 ,נחף ,103 ,רח 103
522 ,נחף ,174 ,רח 1103
522 ,נחף ,116 ,רח 203
522 ,נחף ,180 ,רח 1203
522 ,נחף ,229 ,רח 2203
522 ,נחף ,183 ,רח 1303
522 ,נחף ,126 ,רח 403
522 ,נחף ,131 ,רח 503
522 ,נחף ,137 ,רח 603
522 ,נחף ,201 ,רח 1603
522 ,נחף ,145 ,רח 703
522 ,נחף ,206 ,רח 1703
522 ,נחף ,155 ,רח 803
522 ,נחף ,219 ,רח 1803
522 ,נחף ,163 ,רח 903
522 ,נחף ,150 ,רח 713
522 ,נחף ,211 ,רח 1713
522 ,נחף ,169 ,רח 1004
522 ,נחף ,106 ,רח 104
522 ,נחף ,109 ,רח 204
522 ,נחף ,178 ,רח 1204
522 ,נחף ,119 ,רח 304
522 ,נחף ,189 ,רח 1304
522 ,נחף ,192 ,רח 1404
522 ,נחף ,129 ,רח 504
522 ,נחף ,197 ,רח 1504
522 ,נחף ,134 ,רח 604
522 ,נחף ,213 ,רח 1704
522 ,נחף ,157 ,רח 804
522 ,נחף ,161 ,רח 904
522 ,נחף ,222 ,רח 1904
522 ,נחף ,114 ,רח 214
522 ,נחף ,167 ,רח 1005
522 ,נחף ,104 ,רח 105
522 ,נחף ,175 ,רח 1105
522 ,נחף ,230 ,רח 2205
522 ,נחף ,184 ,רח 1305
522 ,נחף ,138 ,רח 605
522 ,נחף ,202 ,רח 1605
522 ,נחף ,146 ,רח 705
522 ,נחף ,207 ,רח 1705
522 ,נחף ,151 ,רח 715
522 ,נחף ,170 ,רח 1006
522 ,נחף ,110 ,רח 206
522 ,נחף ,120 ,רח 306
522 ,נחף ,193 ,רח 1406
522 ,נחף ,198 ,רח 1506
522 ,נחף ,135 ,רח 606
522 ,נחף ,214 ,רח 1706
522 ,נחף ,158 ,רח 806
522 ,נחף ,223 ,רח 1906
522 ,נחף ,231 ,רח 2207
522 ,נחף ,185 ,רח 1307
522 ,נחף ,139 ,רח 607
522 ,נחף ,203 ,רח 1607
522 ,נחף ,147 ,רח 707
522 ,נחף ,208 ,רח 1707
522 ,נחף ,152 ,רח 717
522 ,נחף ,171 ,רח 1008
522 ,נחף ,111 ,רח 208
522 ,נחף ,121 ,רח 308
522 ,נחף ,215 ,רח 1708
522 ,נחף ,232 ,רח 2209
522 ,נחף ,186 ,רח 1309
522 ,נחף ,140 ,רח 609
522 ,נחף ,148 ,רח 709
522 ,נחף ,209 ,רח 1709
433 ,נחשולים ,7001 ,ביס שדה נחשולים
433 ,נחשולים ,9000 ,נחשולים
777 ,נחשון ,9000 ,נחשון
705 ,נחשונים ,9000 ,נחשונים
1147 ,נטועה ,9000 ,נטועה
4014 ,נטור ,9000 ,נטור
1369 ,נטע ,102 ,גוש קטיף
1369 ,נטע ,112 ,הר ארגמן
1369 ,נטע ,107 ,הר רמון
1369 ,נטע ,111 ,הר שחר
1369 ,נטע ,103 ,כפר דרום
1369 ,נטע ,101 ,מבוא מרשם
1369 ,נטע ,113 ,נחל בשור
1369 ,נטע ,110 ,נחל לכיש
1369 ,נטע ,109 ,נחל משמר
1369 ,נטע ,119 ,נחל נוקד
1369 ,נטע ,116 ,נחל עבדת
1369 ,נטע ,105 ,נחל עין גדי
1369 ,נטע ,108 ,נחל ערבה
1369 ,נטע ,115 ,נחל צאלים
1369 ,נטע ,106 ,נחל צופית
1369 ,נטע ,117 ,נחל רביבים
1369 ,נטע ,118 ,נחל רחף
1369 ,נטע ,114 ,נחל תמנע
1369 ,נטע ,9000 ,נטע
1369 ,נטע ,104 ,תל קטיפא
174 ,נטעים ,9000 ,נטעים
1254 ,נטף ,9000 ,נטף
523 ,ניין ,9000 ,ניין
3655 ,ניל"י ,113 ,אודם
3655 ,ניל"י ,104 ,אורן
3655 ,ניל"י ,111 ,בזלת
3655 ,ניל"י ,103 ,ברוש
3655 ,ניל"י ,112 ,דרך המייסדים
3655 ,ניל"י ,109 ,הבוסתן
3655 ,ניל"י ,102 ,הדקל
3655 ,ניל"י ,110 ,הדר
3655 ,ניל"י ,107 ,השיטה
3655 ,ניל"י ,105 ,זית
3655 ,ניל"י ,119 ,מעלה ההר
3655 ,ניל"י ,108 ,נוף ים
3655 ,ניל"י ,116 ,נחל געתון
3655 ,ניל"י ,121 ,נחל דן
3655 ,ניל"י ,120 ,נחל הבשור
3655 ,ניל"י ,114 ,נחל מודיעין
3655 ,ניל"י ,118 ,נחל מירון
3655 ,ניל"י ,115 ,נחל נטוף
3655 ,ניל"י ,117 ,נחל פארן
3655 ,ניל"י ,9000 ,ניל"י
3655 ,ניל"י ,101 ,שיזף
3655 ,ניל"י ,106 ,תדהר
351 ,ניצן ,122 ,אלי סיני
351 ,ניצן ,127 ,גדיד
351 ,ניצן ,138 ,דוד המלך
351 ,ניצן ,120 ,דרך גוש קטיף
351 ,ניצן ,125 ,דרך נווה דקלים
351 ,ניצן ,141 ,האגם
351 ,ניצן ,131 ,האמונה
351 ,ניצן ,101 ,האשל
351 ,ניצן ,130 ,הגאולה
351 ,ניצן ,102 ,הגפן
351 ,ניצן ,139 ,הדיונה
351 ,ניצן ,103 ,הזית
351 ,ניצן ,134 ,הנצח
351 ,ניצן ,140 ,הצוק
351 ,ניצן ,104 ,הרותם
351 ,ניצן ,105 ,הרימון
351 ,ניצן ,106 ,השיטה
351 ,ניצן ,133 ,השמחה
351 ,ניצן ,107 ,השקד
351 ,ניצן ,108 ,השקמה
351 ,ניצן ,110 ,התאנה
351 ,ניצן ,109 ,התמר
351 ,ניצן ,132 ,התקווה
351 ,ניצן ,136 ,יהודה
351 ,ניצן ,137 ,יוסף
351 ,ניצן ,135 ,כיסופים
351 ,ניצן ,124 ,כפר דרום
351 ,ניצן ,128 ,מורג
351 ,ניצן ,9000 ,ניצן
351 ,ניצן ,121 ,נצר חזני
351 ,ניצן ,123 ,עצמונה
351 ,ניצן ,126 ,פאת שדה
351 ,ניצן ,142 ,שירת הים
1419 ,ניצן ב' ,185 ,דרך הים
1419 ,ניצן ב' ,189 ,החוף
1419 ,ניצן ב' ,186 ,מורד הנחל
1419 ,ניצן ב' ,187 ,משל יותם
1419 ,ניצן ב' ,117 ,משעול אדום החזה
1419 ,ניצן ב' ,147 ,משעול האגוז
1419 ,ניצן ב' ,125 ,משעול האגמית
1419 ,ניצן ב' ,163 ,משעול האגס
1419 ,ניצן ב' ,139 ,משעול האדווה
1419 ,ניצן ב' ,156 ,משעול האורן
1419 ,ניצן ב' ,154 ,משעול האלה
1419 ,ניצן ב' ,155 ,משעול האלון
1419 ,ניצן ב' ,137 ,משעול האלמוג
1419 ,ניצן ב' ,121 ,משעול האנפה
1419 ,ניצן ב' ,150 ,משעול האפרסק
1419 ,ניצן ב' ,161 ,משעול האתרוג
1419 ,ניצן ב' ,122 ,משעול הבז
1419 ,ניצן ב' ,148 ,משעול הברוש
1419 ,ניצן ב' ,138 ,משעול הגלים
1419 ,ניצן ב' ,169 ,משעול הגפן
1419 ,ניצן ב' ,114 ,משעול הדוכיפת
1419 ,ניצן ב' ,153 ,משעול הדולב
1419 ,ניצן ב' ,145 ,משעול הדולפין
1419 ,ניצן ב' ,144 ,משעול הדייגים
1419 ,ניצן ב' ,124 ,משעול הדרור
1419 ,ניצן ב' ,160 ,משעול הזית
1419 ,ניצן ב' ,101 ,משעול הזמיר
1419 ,ניצן ב' ,102 ,משעול החוגלה
1419 ,ניצן ב' ,103 ,משעול החוחית
1419 ,ניצן ב' ,177 ,משעול החופית
1419 ,ניצן ב' ,104 ,משעול החסידה
1419 ,ניצן ב' ,166 ,משעול החרוב
1419 ,ניצן ב' ,105 ,משעול הינשוף
1419 ,ניצן ב' ,116 ,משעול הירגזי
1419 ,ניצן ב' ,173 ,משעול הלגונה
1419 ,ניצן ב' ,157 ,משעול הלימון
1419 ,ניצן ב' ,143 ,משעול המגדלור
1419 ,ניצן ב' ,142 ,משעול המזח
1419 ,ניצן ב' ,135 ,משעול המפרש
1419 ,ניצן ב' ,106 ,משעול הנחליאלי
1419 ,ניצן ב' ,107 ,משעול הנשר
1419 ,ניצן ב' ,141 ,משעול הסירה
1419 ,ניצן ב' ,108 ,משעול הסלעית
1419 ,ניצן ב' ,109 ,משעול הסנונית
1419 ,ניצן ב' ,110 ,משעול העגור
1419 ,ניצן ב' ,136 ,משעול העוגן
1419 ,ניצן ב' ,111 ,משעול העפרוני
1419 ,ניצן ב' ,152 ,משעול הערבה
1419 ,ניצן ב' ,151 ,משעול הצאלון
1419 ,ניצן ב' ,178 ,משעול הצדף
1419 ,ניצן ב' ,112 ,משעול הצופית
1419 ,ניצן ב' ,149 ,משעול הצפצפה
1419 ,ניצן ב' ,176 ,משעול הקונכיה
1419 ,ניצן ב' ,171 ,משעול הקכלי
1419 ,ניצן ב' ,170 ,משעול הרימון
1419 ,ניצן ב' ,172 ,משעול השונית
1419 ,ניצן ב' ,159 ,משעול השזיף
1419 ,ניצן ב' ,146 ,משעול השחף
1419 ,ניצן ב' ,113 ,משעול השחרור
1419 ,ניצן ב' ,175 ,משעול השלדג
1419 ,ניצן ב' ,123 ,משעול השליו
1419 ,ניצן ב' ,167 ,משעול השקד
1419 ,ניצן ב' ,115 ,משעול השקנאי
1419 ,ניצן ב' ,165 ,משעול התאנה
1419 ,ניצן ב' ,120 ,משעול התור
1419 ,ניצן ב' ,174 ,משעול התורן
1419 ,ניצן ב' ,162 ,משעול התות
1419 ,ניצן ב' ,168 ,משעול התמר
1419 ,ניצן ב' ,118 ,משעול התנשמת
1419 ,ניצן ב' ,158 ,משעול התפוז
1419 ,ניצן ב' ,164 ,משעול התפוח
1419 ,ניצן ב' ,140 ,משעול כוכב הים
1419 ,ניצן ב' ,128 ,משעול נחל אורן
1419 ,ניצן ב' ,134 ,משעול נחל בצת
1419 ,ניצן ב' ,126 ,משעול נחל הירקון
1419 ,ניצן ב' ,132 ,משעול נחל חילזון
1419 ,ניצן ב' ,133 ,משעול נחל עמוד
1419 ,ניצן ב' ,131 ,משעול נחל ערוגות
1419 ,ניצן ב' ,127 ,משעול נחל צין
1419 ,ניצן ב' ,130 ,משעול נחל ציפורי
1419 ,ניצן ב' ,129 ,משעול נחל רחף
1419 ,ניצן ב' ,9000 ,ניצן ב'
1419 ,ניצן ב' ,180 ,נתיב הציפורים
1419 ,ניצן ב' ,190 ,שדרת הקוצר
1195 ,ניצנה (קהילת חינוך) ,101 ,מרכז קליטה
1195 ,ניצנה (קהילת חינוך) ,9000 ,ניצנה (קהילת חינוך)
1280 ,ניצני סיני ,9000 ,ניצני סיני
851 ,ניצני עוז ,101 ,אלומות
851 ,ניצני עוז ,147 ,דרך השדות
851 ,ניצני עוז ,107 ,הבוסתן
851 ,ניצני עוז ,102 ,הפרחים
851 ,ניצני עוז ,103 ,השלושה
851 ,ניצני עוז ,9000 ,ניצני עוז
851 ,ניצני עוז ,104 ,ערוגות
851 ,ניצני עוז ,105 ,רגבים
851 ,ניצני עוז ,106 ,שיבולים
359 ,ניצנים ,9000 ,ניצנים
808 ,ניר אליהו ,9000 ,ניר אליהו
553 ,ניר בנים ,9000 ,ניר בנים
553 ,ניר בנים ,104 ,נתיב האפרסק
553 ,ניר בנים ,103 ,שביל החלב
553 ,ניר בנים ,102 ,שדרת הורד
553 ,ניר בנים ,101 ,שכ קרוונים
720 ,ניר גלים ,125 ,אשל
720 ,ניר גלים ,124 ,ברוש
720 ,ניר גלים ,123 ,גומא
720 ,ניר גלים ,122 ,דולב
720 ,ניר גלים ,121 ,הדס
720 ,ניר גלים ,105 ,המיסדים
720 ,ניר גלים ,101 ,השדרה החקלאית
720 ,ניר גלים ,104 ,התש"ט
720 ,ניר גלים ,120 ,ורד
720 ,ניר גלים ,119 ,זוטה לבנה
720 ,ניר גלים ,118 ,חרוב
720 ,ניר גלים ,115 ,טופח
720 ,ניר גלים ,103 ,יקינטון
720 ,ניר גלים ,7001 ,ישיבת נוה הרצוג
720 ,ניר גלים ,114 ,כליל החורש
720 ,ניר גלים ,113 ,ליבנה
720 ,ניר גלים ,116 ,מורן
720 ,ניר גלים ,112 ,נורית
720 ,ניר גלים ,9000 ,ניר גלים
720 ,ניר גלים ,102 ,ניר וגל
720 ,ניר גלים ,111 ,סחלב
720 ,ניר גלים ,110 ,ערבה
720 ,ניר גלים ,109 ,פיקוס
720 ,ניר גלים ,117 ,צאלון
720 ,ניר גלים ,108 ,קינמון
720 ,ניר גלים ,107 ,רותם
720 ,ניר גלים ,106 ,שיקמה
256 ,ניר דוד (תל עמל) ,101 ,בית ראשון במולדת
256 ,ניר דוד (תל עמל) ,9000 ,ניר דוד (תל עמל)
11 ,ניר ח"ן ,107 ,החרצית
11 ,ניר ח"ן ,106 ,היסמין
11 ,ניר ח"ן ,104 ,הכלנית
11 ,ניר ח"ן ,101 ,הנורית
11 ,ניר ח"ן ,105 ,הנרקיס
11 ,ניר ח"ן ,103 ,הסביון
11 ,ניר ח"ן ,102 ,הצבעוני
11 ,ניר ח"ן ,9000 ,ניר ח"ן
11 ,ניר ח"ן ,100 ,שדרות הפרחים
165 ,ניר יפה ,102 ,האלה
165 ,ניר יפה ,108 ,האלון
165 ,ניר יפה ,101 ,הארז
165 ,ניר יפה ,106 ,האתרוג
165 ,ניר יפה ,105 ,הברוש
165 ,ניר יפה ,107 ,הזית
165 ,ניר יפה ,104 ,הערבה
165 ,ניר יפה ,103 ,השקד
165 ,ניר יפה ,9000 ,ניר יפה
402 ,ניר יצחק ,9000 ,ניר יצחק
699 ,ניר ישראל ,9000 ,ניר ישראל
2047 ,ניר משה ,9000 ,ניר משה
69 ,ניר עוז ,9000 ,ניר עוז
348 ,ניר עם ,9000 ,ניר עם
769 ,ניר עציון ,117 ,אורנים
769 ,ניר עציון ,102 ,איילה
769 ,ניר עציון ,124 ,איתן
769 ,ניר עציון ,115 ,אלונים
769 ,ניר עציון ,103 ,אלמוג
769 ,ניר עציון ,114 ,אמיר
769 ,ניר עציון ,109 ,ארז
769 ,ניר עציון ,125 ,אריאל
769 ,ניר עציון ,106 ,גביש צבר
769 ,ניר עציון ,110 ,גפן
769 ,ניר עציון ,105 ,דקל
769 ,ניר עציון ,119 ,דרור
769 ,ניר עציון ,128 ,דרך נוף עציונים
769 ,ניר עציון ,108 ,חופים
769 ,ניר עציון ,6001 ,כיכר המייסדים
769 ,ניר עציון ,129 ,כלנית
769 ,ניר עציון ,126 ,כפיר
769 ,ניר עציון ,6500 ,כפר הנוער ימין אורד
769 ,ניר עציון ,112 ,כרמל
769 ,ניר עציון ,122 ,לביא
769 ,ניר עציון ,111 ,להבה
769 ,ניר עציון ,120 ,מצדה
769 ,ניר עציון ,9000 ,ניר עציון
769 ,ניר עציון ,113 ,נרקיס
769 ,ניר עציון ,123 ,סהר
769 ,ניר עציון ,127 ,עוז
769 ,ניר עציון ,118 ,עופר
769 ,ניר עציון ,121 ,קשת
769 ,ניר עציון ,101 ,ראם
769 ,ניר עציון ,104 ,רעים
769 ,ניר עציון ,116 ,שחף
769 ,ניר עציון ,107 ,שלהבת
2048 ,ניר עקיבא ,9000 ,ניר עקיבא
331 ,ניר צבי ,101 ,איתן
331 ,ניר צבי ,110 ,גומא
331 ,ניר צבי ,105 ,האשל
331 ,ניר צבי ,108 ,הגפן
331 ,ניר צבי ,112 ,הדסים
331 ,ניר צבי ,106 ,הדקל
331 ,ניר צבי ,111 ,הדרים
331 ,ניר צבי ,107 ,הערבה
331 ,ניר צבי ,103 ,הצאלון
331 ,ניר צבי ,102 ,הרימון
331 ,ניר צבי ,109 ,יערה
331 ,ניר צבי ,9000 ,ניר צבי
602 ,נירים ,9000 ,נירים
1236 ,נירית ,107 ,גולן
1236 ,נירית ,110 ,גלבוע
1236 ,נירית ,108 ,גלעד
1236 ,נירית ,105 ,הגליל
1236 ,נירית ,102 ,החרמון
1236 ,נירית ,101 ,הכרמל
1236 ,נירית ,115 ,הלל
1236 ,נירית ,103 ,התבור
1236 ,נירית ,106 ,מוריה
1236 ,נירית ,104 ,מירון
1236 ,נירית ,9000 ,נירית
1236 ,נירית ,116 ,שחר
1236 ,נירית ,6500 ,שכ נוף השרון
1236 ,נירית ,109 ,שניר
4035 ,נמרוד ,9000 ,נמרוד
825 ,נס הרים ,107 ,דרך הכרמים
825 ,נס הרים ,115 ,דרך המעיין
825 ,נס הרים ,102 ,דרך חלמית
825 ,נס הרים ,112 ,האלה
825 ,נס הרים ,110 ,הלוטם
825 ,נס הרים ,114 ,המרווה
825 ,נס הרים ,109 ,השיזף
825 ,נס הרים ,111 ,השקד
825 ,נס הרים ,108 ,כליל החורש
825 ,נס הרים ,101 ,לבנה
825 ,נס הרים ,104 ,מבוא היערה
825 ,נס הרים ,103 ,מורד הרוזמרין
825 ,נס הרים ,113 ,מעלה האלונים
825 ,נס הרים ,105 ,מעלה הקטלב
825 ,נס הרים ,106 ,מעלות אורן
825 ,נס הרים ,9000 ,נס הרים
1143 ,נס עמים ,9000 ,נס עמים
7200 ,נס ציונה ,194 ,אבנר בן נר
7200 ,נס ציונה ,181 ,אדום
7200 ,נס ציונה ,324 ,אהרון מאיר מזיא
7200 ,נס ציונה ,6527 ,אזור תעשיה א
7200 ,נס ציונה ,6528 ,אזור תעשיה ב
7200 ,נס ציונה ,321 ,איילון
7200 ,נס ציונה ,319 ,אינשטיין
7200 ,נס ציונה ,396 ,איתן רפאל
7200 ,נס ציונה ,463 ,אלה
7200 ,נס ציונה ,455 ,אלון
7200 ,נס ציונה ,149 ,אלי כהן
7200 ,נס ציונה ,363 ,אלתרמן
7200 ,נס ציונה ,356 ,אנה פרנק
7200 ,נס ציונה ,146 ,אנילביץ
7200 ,נס ציונה ,159 ,אצ"ל
7200 ,נס ציונה ,177 ,ארבל
7200 ,נס ציונה ,256 ,ארגמן
7200 ,נס ציונה ,457 ,ארזים
7200 ,נס ציונה ,365 ,בגין מנחם
7200 ,נס ציונה ,355 ,בוקסר אהרון
7200 ,נס ציונה ,218 ,בוקסר ירמיהו
7200 ,נס ציונה ,139 ,בורוכוב
7200 ,נס ציונה ,122 ,ביאליק
7200 ,נס ציונה ,214 ,ביל"ו
7200 ,נס ציונה ,305 ,בית השיקום
7200 ,נס ציונה ,311 ,בן אבי איתמר
7200 ,נס ציונה ,134 ,בן גוריון
7200 ,נס ציונה ,335 ,בן יהודה אבנר
7200 ,נס ציונה ,315 ,בני בנימין
7200 ,נס ציונה ,362 ,בר-כוכבא
7200 ,נס ציונה ,453 ,ברושים
7200 ,נס ציונה ,148 ,ברקת
7200 ,נס ציונה ,117 ,גאולה
7200 ,נס ציונה ,6511 ,גבעת האהבה
7200 ,נס ציונה ,6508 ,גבעת הצבר
7200 ,נס ציונה ,6522 ,גבעת התור
7200 ,נס ציונה ,6501 ,גבעת מיכאל
7200 ,נס ציונה ,6525 ,גבעת נוף
7200 ,נס ציונה ,169 ,גבעתי
7200 ,נס ציונה ,350 ,גוטהלף שמואל
7200 ,נס ציונה ,323 ,גולדה מאיר
7200 ,נס ציונה ,340 ,גולדה מילוסלבסקי
7200 ,נס ציונה ,111 ,גולומב
7200 ,נס ציונה ,113 ,גולי דמשק
7200 ,נס ציונה ,182 ,גולן
7200 ,נס ציונה ,138 ,גורדון
7200 ,נס ציונה ,136 ,גלילי
7200 ,נס ציונה ,6524 ,גני איריס
7200 ,נס ציונה ,6535 ,גני הדר
7200 ,נס ציונה ,378 ,געתון
7200 ,נס ציונה ,388 ,דבורנית
7200 ,נס ציונה ,211 ,דגל ישראל
7200 ,נס ציונה ,153 ,דגניה
7200 ,נס ציונה ,183 ,דוד אלעזר
7200 ,נס ציונה ,425 ,דוכיפת
7200 ,נס ציונה ,460 ,דולב
7200 ,נס ציונה ,371 ,דישון
7200 ,נס ציונה ,379 ,דן
7200 ,נס ציונה ,257 ,דפנה
7200 ,נס ציונה ,422 ,דרור
7200 ,נס ציונה ,392 ,דרך גבעות הכורכר
7200 ,נס ציונה ,330 ,דרך הנפת הדגל
7200 ,נס ציונה ,331 ,דרך השלום
7200 ,נס ציונה ,212 ,דרך רבין יצחק
7200 ,נס ציונה ,411 ,האביב
7200 ,נס ציונה ,185 ,האופק
7200 ,נס ציונה ,465 ,האורן
7200 ,נס ציונה ,318 ,האיזמל
7200 ,נס ציונה ,227 ,האירוסים
7200 ,נס ציונה ,445 ,האלופים
7200 ,נס ציונה ,247 ,האלמוגים
7200 ,נס ציונה ,191 ,האמהות
7200 ,נס ציונה ,404 ,האסיף
7200 ,נס ציונה ,454 ,האתרוג
7200 ,נס ציונה ,338 ,הבאר
7200 ,נס ציונה ,109 ,הבנים
7200 ,נס ציונה ,186 ,הגיא
7200 ,נס ציונה ,165 ,הגלבוע
7200 ,נס ציונה ,166 ,הגליל
7200 ,נס ציונה ,241 ,הגלים
7200 ,נס ציונה ,123 ,הגנה
7200 ,נס ציונה ,326 ,הגפן
7200 ,נס ציונה ,414 ,הדגן
7200 ,נס ציונה ,450 ,הדס
7200 ,נס ציונה ,221 ,הדקל
7200 ,נס ציונה ,133 ,ההדרים
7200 ,נס ציונה ,231 ,ההסתדרות
7200 ,נס ציונה ,222 ,הורד
7200 ,נס ציונה ,328 ,הזית
7200 ,נס ציונה ,456 ,החיטה
7200 ,נס ציונה ,413 ,החירות
7200 ,נס ציונה ,128 ,החלוץ
7200 ,נס ציונה ,309 ,החרש
7200 ,נס ציונה ,219 ,הטייסים
7200 ,נס ציונה ,408 ,היוגב
7200 ,נס ציונה ,434 ,היונה
7200 ,נס ציונה ,352 ,היימן ישראל
7200 ,נס ציונה ,226 ,הכלנית
7200 ,נס ציונה ,403 ,הכרמים
7200 ,נס ציונה ,237 ,הלב
7200 ,נס ציונה ,235 ,המאה ואחד
7200 ,נס ציונה ,171 ,המולדת
7200 ,נס ציונה ,316 ,המזמרה
7200 ,נס ציונה ,333 ,המייסדים
7200 ,נס ציונה ,170 ,המלאכה
7200 ,נס ציונה ,193 ,המלכים
7200 ,נס ציונה ,210 ,המנין הראשון
7200 ,נס ציונה ,308 ,המסגר
7200 ,נס ציונה ,446 ,הנבחרות
7200 ,נס ציונה ,436 ,הנקר
7200 ,נס ציונה ,225 ,הנרקיס
7200 ,נס ציונה ,447 ,הספורט
7200 ,נס ציונה ,310 ,העבודה
7200 ,נס ציונה ,429 ,העגור
7200 ,נס ציונה ,244 ,העוגן
7200 ,נס ציונה ,107 ,העצמאות
7200 ,נס ציונה ,317 ,הפטיש
7200 ,נס ציונה ,402 ,הפרדסים
7200 ,נס ציונה ,141 ,הפרטיזנים
7200 ,נס ציונה ,389 ,הציונות
7200 ,נס ציונה ,140 ,הצנחנים
7200 ,נס ציונה ,407 ,הקציר
7200 ,נס ציונה ,203 ,הרב בארי
7200 ,נס ציונה ,142 ,הרב שאול אחרק
7200 ,נס ציונה ,258 ,הרדוף
7200 ,נס ציונה ,327 ,הרימון
7200 ,נס ציונה ,105 ,הרמן מאיר
7200 ,נס ציונה ,206 ,הרצל
7200 ,נס ציונה ,233 ,הרקפת
7200 ,נס ציונה ,156 ,השומר
7200 ,נס ציונה ,190 ,השופטים
7200 ,נס ציונה ,243 ,השייטת
7200 ,נס ציונה ,228 ,השקד
7200 ,נס ציונה ,220 ,השקמה
7200 ,נס ציונה ,230 ,השריון
7200 ,נס ציונה ,325 ,התאנה
7200 ,נס ציונה ,458 ,התור
7200 ,נס ציונה ,164 ,התחיה
7200 ,נס ציונה ,329 ,התמר
7200 ,נס ציונה ,304 ,התרמ"ג
7200 ,נס ציונה ,103 ,ויצמן
7200 ,נס ציונה ,202 ,ז'בוטינסקי
7200 ,נס ציונה ,114 ,זאבי רחבעם
7200 ,נס ציונה ,420 ,זמיר
7200 ,נס ציונה ,359 ,זמל זוסיא
7200 ,נס ציונה ,127 ,זרובבל
7200 ,נס ציונה ,394 ,חבצלת
7200 ,נס ציונה ,242 ,חופים
7200 ,נס ציונה ,147 ,חושן
7200 ,נס ציונה ,430 ,חזן יעקב
7200 ,נס ציונה ,353 ,חזקיהו
7200 ,נס ציונה ,364 ,חיים בר-לב
7200 ,נס ציונה ,438 ,חסידה
7200 ,נס ציונה ,386 ,חצב
7200 ,נס ציונה ,462 ,חרוב
7200 ,נס ציונה ,180 ,חרמון
7200 ,נס ציונה ,145 ,טופז
7200 ,נס ציונה ,428 ,טוקאן
7200 ,נס ציונה ,216 ,טפר חיים
7200 ,נס ציונה ,354 ,יואש
7200 ,נס ציונה ,426 ,יונק הדבש
7200 ,נס ציונה ,387 ,יסמין
7200 ,נס ציונה ,224 ,יפה נוף
7200 ,נס ציונה ,125 ,יציאת אירופה
7200 ,נס ציונה ,252 ,יקינתון
7200 ,נס ציונה ,158 ,ירושלים
7200 ,נס ציונה ,374 ,ירקון
7200 ,נס ציונה ,368 ,כהנוב זאב
7200 ,נס ציונה ,423 ,כחליל
7200 ,נס ציונה ,6001 ,ככר המיסדים
7200 ,נס ציונה ,372 ,כנרת
7200 ,נס ציונה ,132 ,כצנלסון
7200 ,נס ציונה ,391 ,כרכום
7200 ,נס ציונה ,174 ,כרמל
7200 ,נס ציונה ,6532 ,לב המושבה
7200 ,נס ציונה ,104 ,לוחמי הגטאות
7200 ,נס ציונה ,259 ,לוטוס
7200 ,נס ציונה ,250 ,לוי משה
7200 ,נס ציונה ,102 ,לח"י
7200 ,נס ציונה ,334 ,לחמי שלום
7200 ,נס ציונה ,380 ,לילך
7200 ,נס ציונה ,366 ,לנדו דוד
7200 ,נס ציונה ,367 ,לסקוב
7200 ,נס ציונה ,395 ,לרר משה
7200 ,נס ציונה ,168 ,מבצע קדש
7200 ,נס ציונה ,441 ,מונטיפיורי
7200 ,נס ציונה ,175 ,מירון אליהו
7200 ,נס ציונה ,208 ,מכבי
7200 ,נס ציונה ,213 ,מכון למחקר
7200 ,נס ציונה ,167 ,מנור
7200 ,נס ציונה ,6502 ,מע נס ציונה
7200 ,נס ציונה ,154 ,מקוה ישראל
7200 ,נס ציונה ,101 ,מרגולין
7200 ,נס ציונה ,336 ,מרגנית
7200 ,נס ציונה ,161 ,משחתת אילת
7200 ,נס ציונה ,397 ,משמר הירדן
7200 ,נס ציונה ,131 ,נגבה
7200 ,נס ציונה ,6530 ,נווה הדר
7200 ,נס ציונה ,6531 ,נווה כרמית
7200 ,נס ציונה ,115 ,נורדאו
7200 ,נס ציונה ,382 ,נורית
7200 ,נס ציונה ,437 ,נחליאלי
7200 ,נס ציונה ,6537 ,נחלת ראובן
7200 ,נס ציונה ,361 ,נחמיאס שמואל
7200 ,נס ציונה ,160 ,נחשון
7200 ,נס ציונה ,172 ,ניל"י
7200 ,נס ציונה ,9000 ,נס ציונה
7200 ,נס ציונה ,377 ,נעמן
7200 ,נס ציונה ,390 ,סביון
7200 ,נס ציונה ,6504 ,סביוני הפארק
7200 ,נס ציונה ,6533 ,סביוני נצר
7200 ,נס ציונה ,126 ,סוקולוב
7200 ,נס ציונה ,383 ,סחלב
7200 ,נס ציונה ,124 ,סטרומה
7200 ,נס ציונה ,393 ,סיגלית
7200 ,נס ציונה ,384 ,סייפן
7200 ,נס ציונה ,137 ,סיני
7200 ,נס ציונה ,254 ,סיתוונית
7200 ,נס ציונה ,369 ,סלוביס שמואל
7200 ,נס ציונה ,196 ,סמ גנים
7200 ,נס ציונה ,234 ,סמ דליה
7200 ,נס ציונה ,215 ,סמ האחיות ניימן
7200 ,נס ציונה ,236 ,סמ האירוסים
7200 ,נס ציונה ,238 ,סנה משה
7200 ,נס ציונה ,421 ,סנונית
7200 ,נס ציונה ,119 ,סנש חנה
7200 ,נס ציונה ,332 ,סעדיה נחום
7200 ,נס ציונה ,320 ,ספיר פנחס
7200 ,נס ציונה ,339 ,עדן
7200 ,נס ציונה ,406 ,עומרים
7200 ,נס ציונה ,401 ,עונות השנה
7200 ,נס ציונה ,118 ,עזרא ונחמיה
7200 ,נס ציונה ,130 ,עליה
7200 ,נס ציונה ,6513 ,עמק ההדרים
7200 ,נס ציונה ,229 ,עמק השושנים
7200 ,נס ציונה ,431 ,עפרוני
7200 ,נס ציונה ,173 ,עצמון
7200 ,נס ציונה ,459 ,ערבה
7200 ,נס ציונה ,6526 ,פארק המדע
7200 ,נס ציונה ,375 ,פולג
7200 ,נס ציונה ,341 ,פייגה לרר
7200 ,נס ציונה ,246 ,פלג
7200 ,נס ציונה ,360 ,פלדמן יוסף
7200 ,נס ציונה ,121 ,פלמ"ח
7200 ,נס ציונה ,433 ,פלמינגו
7200 ,נס ציונה ,342 ,פנינה פצ'ורניק
7200 ,נס ציונה ,370 ,פניק דוד
7200 ,נס ציונה ,385 ,פעמונית
7200 ,נס ציונה ,337 ,פרג
7200 ,נס ציונה ,313 ,פרופ אברהם פצ'ורניק
7200 ,נס ציונה ,439 ,פשוש
7200 ,נס ציונה ,255 ,צבעוני
7200 ,נס ציונה ,110 ,צה"ל
7200 ,נס ציונה ,427 ,צופית
7200 ,נס ציונה ,452 ,צמרות
7200 ,נס ציונה ,461 ,צפצפה
7200 ,נס ציונה ,108 ,קבוץ גלויות
7200 ,נס ציונה ,357 ,קוברסקי משה
7200 ,נס ציונה ,209 ,קרן קיימת
7200 ,נס ציונה ,184 ,קשת
7200 ,נס ציונה ,301 ,ראובן
7200 ,נס ציונה ,405 ,רגבים
7200 ,נס ציונה ,201 ,רוטשילד
7200 ,נס ציונה ,351 ,רופמן שמואל
7200 ,נס ציונה ,195 ,רות המואביה
7200 ,נס ציונה ,253 ,רותם
7200 ,נס ציונה ,358 ,רחבעם
7200 ,נס ציונה ,112 ,רמב"ם
7200 ,נס ציונה ,322 ,רמון אילן
7200 ,נס ציונה ,129 ,רמז
7200 ,נס ציונה ,6503 ,רמת בן צבי
7200 ,נס ציונה ,6505 ,ש אזרחי
7200 ,נס ציונה ,6506 ,ש בלוקונים
7200 ,נס ציונה ,6507 ,ש הפועלים
7200 ,נס ציונה ,6509 ,ש יד אליעזר
7200 ,נס ציונה ,6510 ,ש נוה עובד
7200 ,נס ציונה ,6512 ,ש סלע
7200 ,נס ציונה ,6514 ,ש עמידר
7200 ,נס ציונה ,6515 ,ש פרוגרסיבים
7200 ,נס ציונה ,6516 ,ש רסקו
7200 ,נס ציונה ,163 ,שאול המלך
7200 ,נס ציונה ,412 ,שבועות
7200 ,נס ציונה ,150 ,שבזי
7200 ,נס ציונה ,162 ,שבטי ישראל
7200 ,נס ציונה ,415 ,שביט
7200 ,נס ציונה ,155 ,שד אפרים
7200 ,נס ציונה ,240 ,שד המעפילים
7200 ,נס ציונה ,400 ,שד העמק
7200 ,נס ציונה ,152 ,שדה יצחק
7200 ,נס ציונה ,187 ,שדות
7200 ,נס ציונה ,245 ,שונית
7200 ,נס ציונה ,373 ,שורק
7200 ,נס ציונה ,381 ,שושן
7200 ,נס ציונה ,432 ,שחף
7200 ,נס ציונה ,410 ,שיבולים
7200 ,נס ציונה ,157 ,שיבת ציון
7200 ,נס ציונה ,451 ,שיזף
7200 ,נס ציונה ,251 ,שינדלר אוסקר
7200 ,נס ציונה ,6536 ,שכ העמק
7200 ,נס ציונה ,6518 ,שכ טירת שלום
7200 ,נס ציונה ,6519 ,שכ כפר אהרון
7200 ,נס ציונה ,6520 ,שכ נוה ניר
7200 ,נס ציונה ,6529 ,שכ פסגת סלע
7200 ,נס ציונה ,6523 ,שכ רמת סמל
7200 ,נס ציונה ,6500 ,שכ שיכון הבנים
7200 ,נס ציונה ,424 ,שלדג
7200 ,נס ציונה ,6534 ,שמורת מליבו
7200 ,נס ציונה ,135 ,שמיד ישראל
7200 ,נס ציונה ,376 ,שניר
7200 ,נס ציונה ,464 ,שקמים
7200 ,נס ציונה ,435 ,שקנאי
7200 ,נס ציונה ,223 ,ששת הימים
7200 ,נס ציונה ,176 ,תבור
7200 ,נס ציונה ,207 ,תל אביב
7200 ,נס ציונה ,120 ,תל חי
7200 ,נס ציונה ,409 ,תלמים
186 ,נעורים ,9000 ,נעורים
3787 ,נעלה ,108 ,אודם
3787 ,נעלה ,114 ,בדולח
3787 ,נעלה ,102 ,ברקת
3787 ,נעלה ,109 ,חושן
3787 ,נעלה ,104 ,טופז
3787 ,נעלה ,106 ,טורקיז
3787 ,נעלה ,105 ,יהלום
3787 ,נעלה ,107 ,לשם
3787 ,נעלה ,6500 ,מעלה מודיעין
3787 ,נעלה ,9000 ,נעלה
3787 ,נעלה ,113 ,נפך
3787 ,נעלה ,110 ,ספיר
3787 ,נעלה ,103 ,ענבר
3787 ,נעלה ,112 ,פנינה
3787 ,נעלה ,101 ,קרית חינוך
3787 ,נעלה ,111 ,שחם
3787 ,נעלה ,115 ,תרשיש
3713 ,נעמ"ה ,9000 ,נעמ"ה
158 ,נען ,101 ,אולפן קיבוץ
158 ,נען ,9000 ,נען
3620 ,נערן ,9000 ,נערן
1041 ,נצאצרה (שבט) ,9000 ,נצאצרה (שבט)
1372 ,נצר חזני ,9000 ,נצר חזני
435 ,נצר סרני ,9000 ,נצר סרני
7300 ,נצרת ,511 ,א-שוהדא'
7300 ,נצרת ,419 ,אבן עאמר
7300 ,נצרת ,430 ,אום קובי
7300 ,נצרת ,6521 ,אזור תעשיה א
7300 ,נצרת ,6522 ,אזור תעשיה ב
7300 ,נצרת ,509 ,איכסאל
7300 ,נצרת ,391 ,אל ואדי אל ג'וואני
7300 ,נצרת ,250 ,אל-בישארה
7300 ,נצרת ,402 ,אל-כורום
7300 ,נצרת ,869 ,אל-פאח'ורה
7300 ,נצרת ,443 ,אניס כרדוש
7300 ,נצרת ,131 ,בי"ח איטלקי
7300 ,נצרת ,435 ,ביח החולים הצרפתי
7300 ,נצרת ,962 ,בילאל
7300 ,נצרת ,6520 ,ביר אבו אל ג'יש
7300 ,נצרת ,425 ,ביר אל אמיר
7300 ,נצרת ,6517 ,גבל א-דולה
7300 ,נצרת ,532 ,גבל חמודי
7300 ,נצרת ,440 ,גרנד ניו הוטל
7300 ,נצרת ,569 ,דרך א-סלאם
7300 ,נצרת ,426 ,דרך עפולה
7300 ,נצרת ,118 ,הגליל
7300 ,נצרת ,530 ,המוסכים
7300 ,נצרת ,6515 ,הרובע הלטיני
7300 ,נצרת ,840 ,ואדי אל-חאג'
7300 ,נצרת ,802 ,ח'אלת עיסא
7300 ,נצרת ,839 ,חאלת א-דיר
7300 ,נצרת ,359 ,כרם א-סאחב
7300 ,נצרת ,888 ,כרם אל-ג'מאל
7300 ,נצרת ,9000 ,נצרת
7300 ,נצרת ,201 ,סאפוריה
7300 ,נצרת ,366 ,סלזיאן
7300 ,נצרת ,889 ,עומר אבן אל-חטאב
7300 ,נצרת ,513 ,פאולוס השישי
7300 ,נצרת ,394 ,פנורמה
7300 ,נצרת ,210 ,רואוס אל-ג'יבאל
7300 ,נצרת ,417 ,רח 1000
7300 ,נצרת ,114 ,רח 4000
7300 ,נצרת ,1004 ,רח 9000
7300 ,נצרת ,866 ,רח 3100
7300 ,נצרת ,818 ,רח 4100
7300 ,נצרת ,675 ,רח 5100
7300 ,נצרת ,303 ,רח 6100
7300 ,נצרת ,915 ,רח 7100
7300 ,נצרת ,446 ,רח 7200
7300 ,נצרת ,423 ,רח 1010
7300 ,נצרת ,1040 ,רח 2010
7300 ,נצרת ,855 ,רח 3010
7300 ,נצרת ,582 ,רח 4010
7300 ,נצרת ,1013 ,רח 5010
7300 ,נצרת ,107 ,רח 6010
7300 ,נצרת ,947 ,רח 7010
7300 ,נצרת ,929 ,רח 8010
7300 ,נצרת ,140 ,רח 9010
7300 ,נצרת ,870 ,רח 1110
7300 ,נצרת ,691 ,רח 5110
7300 ,נצרת ,649 ,רח 6110
7300 ,נצרת ,457 ,רח 7210
7300 ,נצרת ,1038 ,רח 2020
7300 ,נצרת ,863 ,רח 3020
7300 ,נצרת ,742 ,רח 4020
7300 ,נצרת ,121 ,רח 6020
7300 ,נצרת ,892 ,רח 7020
7300 ,נצרת ,965 ,רח 8020
7300 ,נצרת ,147 ,רח 9020
7300 ,נצרת ,985 ,רח 1120
7300 ,נצרת ,694 ,רח 5120
7300 ,נצרת ,348 ,רח 6120
7300 ,נצרת ,467 ,רח 7220
7300 ,נצרת ,980 ,רח 1030
7300 ,נצרת ,1071 ,רח 3030
7300 ,נצרת ,750 ,רח 4030
7300 ,נצרת ,725 ,רח 5030
7300 ,נצרת ,621 ,רח 6030
7300 ,נצרת ,972 ,רח 7030
7300 ,נצרת ,157 ,רח 9030
7300 ,נצרת ,380 ,רח 5130
7300 ,נצרת ,667 ,רח 6130
7300 ,נצרת ,477 ,רח 7230
7300 ,נצרת ,873 ,רח 1040
7300 ,נצרת ,836 ,רח 3040
7300 ,נצרת ,431 ,רח 5040
7300 ,נצרת ,601 ,רח 6040
7300 ,נצרת ,1051 ,רח 7040
7300 ,נצרת ,166 ,רח 9040
7300 ,נצרת ,378 ,רח 5140
7300 ,נצרת ,333 ,רח 6140
7300 ,נצרת ,487 ,רח 7240
7300 ,נצרת ,1082 ,רח 1050
7300 ,נצרת ,502 ,רח 3050
7300 ,נצרת ,816 ,רח 4050
7300 ,נצרת ,592 ,רח 6050
7300 ,נצרת ,900 ,רח 7050
7300 ,נצרת ,731 ,רח 9050
7300 ,נצרת ,322 ,רח 6150
7300 ,נצרת ,497 ,רח 7250
7300 ,נצרת ,1091 ,רח 1060
7300 ,נצרת ,681 ,רח 5060
7300 ,נצרת ,225 ,רח 6060
7300 ,נצרת ,904 ,רח 7060
7300 ,נצרת ,689 ,רח 5160
7300 ,נצרת ,670 ,רח 6160
7300 ,נצרת ,790 ,רח 4070
7300 ,נצרת ,434 ,רח 5070
7300 ,נצרת ,614 ,רח 6070
7300 ,נצרת ,1061 ,רח 7070
7300 ,נצרת ,669 ,רח 6170
7300 ,נצרת ,516 ,רח 4080
7300 ,נצרת ,700 ,רח 5080
7300 ,נצרת ,219 ,רח 6080
7300 ,נצרת ,377 ,רח 7080
7300 ,נצרת ,672 ,רח 6180
7300 ,נצרת ,527 ,רח 4090
7300 ,נצרת ,1030 ,רח 5090
7300 ,נצרת ,631 ,רח 6090
7300 ,נצרת ,1064 ,רח 7090
7300 ,נצרת ,1104 ,רח 1001
7300 ,נצרת ,841 ,רח 2001
7300 ,נצרת ,846 ,רח 3001
7300 ,נצרת ,125 ,רח 6001
7300 ,נצרת ,920 ,רח 8001
7300 ,נצרת ,729 ,רח 9001
7300 ,נצרת ,1094 ,רח 1101
7300 ,נצרת ,763 ,רח 3101
7300 ,נצרת ,819 ,רח 4101
7300 ,נצרת ,676 ,רח 5101
7300 ,נצרת ,310 ,רח 6101
7300 ,נצרת ,916 ,רח 7101
7300 ,נצרת ,447 ,רח 7201
7300 ,נצרת ,320 ,רח 301
7300 ,נצרת ,1102 ,רח 1011
7300 ,נצרת ,102 ,רח 2011
7300 ,נצרת ,856 ,רח 3011
7300 ,נצרת ,521 ,רח 4011
7300 ,נצרת ,1014 ,רח 5011
7300 ,נצרת ,561 ,רח 6011
7300 ,נצרת ,141 ,רח 9011
7300 ,נצרת ,987 ,רח 1111
7300 ,נצרת ,643 ,רח 6111
7300 ,נצרת ,458 ,רח 7211
7300 ,נצרת ,1103 ,רח 1021
7300 ,נצרת ,1042 ,רח 2021
7300 ,נצרת ,311 ,רח 4021
7300 ,נצרת ,720 ,רח 5021
7300 ,נצרת ,893 ,רח 7021
7300 ,נצרת ,966 ,רח 8021
7300 ,נצרת ,148 ,רח 9021
7300 ,נצרת ,738 ,רח 5121
7300 ,נצרת ,468 ,רח 7221
7300 ,נצרת ,328 ,רח 321
7300 ,נצרת ,1080 ,רח 1031
7300 ,נצרת ,501 ,רח 3031
7300 ,נצרת ,751 ,רח 4031
7300 ,נצרת ,726 ,רח 5031
7300 ,נצרת ,622 ,רח 6031
7300 ,נצרת ,885 ,רח 7031
7300 ,נצרת ,158 ,רח 9031
7300 ,נצרת ,347 ,רח 6131
7300 ,נצרת ,478 ,רח 7231
7300 ,נצרת ,874 ,רח 1041
7300 ,נצרת ,506 ,רח 3041
7300 ,נצרת ,807 ,רח 4041
7300 ,נצרת ,1021 ,רח 5041
7300 ,נצרת ,218 ,רח 6041
7300 ,נצרת ,1052 ,רח 7041
7300 ,נצרת ,167 ,רח 9041
7300 ,נצרת ,379 ,רח 5141
7300 ,נצרת ,211 ,רח 6141
7300 ,נצרת ,488 ,רח 7241
7300 ,נצרת ,1083 ,רח 1051
7300 ,נצרת ,376 ,רח 4051
7300 ,נצרת ,703 ,רח 5051
7300 ,נצרת ,593 ,רח 6051
7300 ,נצרת ,973 ,רח 7051
7300 ,נצרת ,353 ,רח 6151
7300 ,נצרת ,498 ,רח 7251
7300 ,נצרת ,1092 ,רח 1061
7300 ,נצרת ,682 ,רח 5061
7300 ,נצרת ,610 ,רח 6061
7300 ,נצרת ,905 ,רח 7061
7300 ,נצרת ,690 ,רח 5161
7300 ,נצרת ,791 ,רח 4071
7300 ,נצרת ,1000 ,רח 5071
7300 ,נצרת ,224 ,רח 6071
7300 ,נצרת ,917 ,רח 7071
7300 ,נצרת ,321 ,רח 6171
7300 ,נצרת ,771 ,רח 4081
7300 ,נצרת ,701 ,רח 5081
7300 ,נצרת ,615 ,רח 6081
7300 ,נצרת ,1075 ,רח 1181
7300 ,נצרת ,673 ,רח 6181
7300 ,נצרת ,523 ,רח 4091
7300 ,נצרת ,414 ,רח 5091
7300 ,נצרת ,129 ,רח 6091
7300 ,נצרת ,666 ,רח 6191
7300 ,נצרת ,418 ,רח 1002
7300 ,נצרת ,842 ,רח 2002
7300 ,נצרת ,847 ,רח 3002
7300 ,נצרת ,365 ,רח 5002
7300 ,נצרת ,550 ,רח 6002
7300 ,נצרת ,921 ,רח 8002
7300 ,נצרת ,730 ,רח 9002
7300 ,נצרת ,1031 ,רח 1102
7300 ,נצרת ,126 ,רח 4102
7300 ,נצרת ,677 ,רח 5102
7300 ,נצרת ,640 ,רח 6102
7300 ,נצרת ,448 ,רח 7202
7300 ,נצרת ,512 ,רח 702
7300 ,נצרת ,251 ,רח 802
7300 ,נצרת ,1101 ,רח 1012
7300 ,נצרת ,857 ,רח 3012
7300 ,נצרת ,572 ,רח 4012
7300 ,נצרת ,1015 ,רח 5012
7300 ,נצרת ,562 ,רח 6012
7300 ,נצרת ,948 ,רח 7012
7300 ,נצרת ,930 ,רח 8012
7300 ,נצרת ,142 ,רח 9012
7300 ,נצרת ,986 ,רח 1112
7300 ,נצרת ,712 ,רח 5112
7300 ,נצרת ,392 ,רח 6112
7300 ,נצרת ,459 ,רח 7212
7300 ,נצרת ,520 ,רח 4022
7300 ,נצרת ,721 ,רח 5022
7300 ,נצרת ,110 ,רח 6022
7300 ,נצרת ,894 ,רח 7022
7300 ,נצרת ,967 ,רח 8022
7300 ,נצרת ,149 ,רח 9022
7300 ,נצרת ,714 ,רח 5122
7300 ,נצרת ,526 ,רח 6122
7300 ,נצרת ,469 ,רח 7222
7300 ,נצרת ,315 ,רח 1032
7300 ,נצרת ,505 ,רח 3032
7300 ,נצרת ,752 ,רח 4032
7300 ,נצרת ,727 ,רח 5032
7300 ,נצרת ,623 ,רח 6032
7300 ,נצרת ,159 ,רח 9032
7300 ,נצרת ,635 ,רח 6132
7300 ,נצרת ,479 ,רח 7232
7300 ,נצרת ,875 ,רח 1042
7300 ,נצרת ,507 ,רח 3042
7300 ,נצרת ,808 ,רח 4042
7300 ,נצרת ,1022 ,רח 5042
7300 ,נצרת ,230 ,רח 6042
7300 ,נצרת ,1053 ,רח 7042
7300 ,נצרת ,168 ,רח 9042
7300 ,נצרת ,489 ,רח 7242
7300 ,נצרת ,1084 ,רח 1052
7300 ,נצרת ,781 ,רח 4052
7300 ,נצרת ,406 ,רח 5052
7300 ,נצרת ,884 ,רח 7052
7300 ,נצרת ,668 ,רח 6152
7300 ,נצרת ,1096 ,רח 1062
7300 ,נצרת ,800 ,רח 4062
7300 ,נצרת ,683 ,רח 5062
7300 ,נצרת ,609 ,רח 6062
7300 ,נצרת ,906 ,רח 7062
7300 ,נצרת ,207 ,רח 6162
7300 ,נצרת ,792 ,רח 4072
7300 ,נצרת ,1001 ,רח 5072
7300 ,נצרת ,616 ,רח 6072
7300 ,נצרת ,910 ,רח 7072
7300 ,נצרת ,331 ,רח 6172
7300 ,נצרת ,772 ,רח 4082
7300 ,נצרת ,412 ,רח 5082
7300 ,נצרת ,302 ,רח 6082
7300 ,נצרת ,913 ,רח 7082
7300 ,נצרת ,318 ,רח 6182
7300 ,נצרת ,413 ,רח 5092
7300 ,נצרת ,349 ,רח 6192
7300 ,נצרת ,106 ,רח 1003
7300 ,נצרת ,843 ,רח 2003
7300 ,נצרת ,848 ,רח 3003
7300 ,נצרת ,116 ,רח 4003
7300 ,נצרת ,363 ,רח 5003
7300 ,נצרת ,551 ,רח 6003
7300 ,נצרת ,940 ,רח 7003
7300 ,נצרת ,922 ,רח 8003
7300 ,נצרת ,988 ,רח 1103
7300 ,נצרת ,1073 ,רח 3103
7300 ,נצרת ,820 ,רח 4103
7300 ,נצרת ,710 ,רח 5103
7300 ,נצרת ,357 ,רח 6103
7300 ,נצרת ,449 ,רח 7203
7300 ,נצרת ,316 ,רח 303
7300 ,נצרת ,1037 ,רח 2013
7300 ,נצרת ,867 ,רח 3013
7300 ,נצרת ,830 ,רח 4013
7300 ,נצרת ,1016 ,רח 5013
7300 ,נצרת ,563 ,רח 6013
7300 ,נצרת ,931 ,רח 8013
7300 ,נצרת ,143 ,רח 9013
7300 ,נצרת ,1002 ,רח 5113
7300 ,נצרת ,345 ,רח 6113
7300 ,נצרת ,460 ,רח 7213
7300 ,נצרת ,339 ,רח 313
7300 ,נצרת ,343 ,רח 413
7300 ,נצרת ,1112 ,רח 1023
7300 ,נצרת ,1043 ,רח 2023
7300 ,נצרת ,743 ,רח 4023
7300 ,נצרת ,1019 ,רח 5023
7300 ,נצרת ,119 ,רח 6023
7300 ,נצרת ,829 ,רח 7023
7300 ,נצרת ,968 ,רח 8023
7300 ,נצרת ,150 ,רח 9023
7300 ,נצרת ,639 ,רח 6123
7300 ,נצרת ,470 ,רח 7223
7300 ,נצרת ,765 ,רח 3033
7300 ,נצרת ,753 ,רח 4033
7300 ,נצרת ,427 ,רח 5033
7300 ,נצרת ,624 ,רח 6033
7300 ,נצרת ,952 ,רח 7033
7300 ,נצרת ,160 ,רח 9033
7300 ,נצרת ,346 ,רח 6133
7300 ,נצרת ,480 ,רח 7233
7300 ,נצרת ,876 ,רח 1043
7300 ,נצרת ,508 ,רח 3043
7300 ,נצרת ,809 ,רח 4043
7300 ,נצרת ,1023 ,רח 5043
7300 ,נצרת ,216 ,רח 6043
7300 ,נצרת ,1054 ,רח 7043
7300 ,נצרת ,368 ,רח 6143
7300 ,נצרת ,490 ,רח 7243
7300 ,נצרת ,782 ,רח 4053
7300 ,נצרת ,732 ,רח 5053
7300 ,נצרת ,205 ,רח 6053
7300 ,נצרת ,901 ,רח 7053
7300 ,נצרת ,317 ,רח 6153
7300 ,נצרת ,1097 ,רח 1063
7300 ,נצרת ,799 ,רח 4063
7300 ,נצרת ,304 ,רח 6063
7300 ,נצרת ,907 ,רח 7063
7300 ,נצרת ,313 ,רח 6163
7300 ,נצרת ,793 ,רח 4073
7300 ,נצרת ,686 ,רח 5073
7300 ,נצרת ,226 ,רח 6073
7300 ,נצרת ,911 ,רח 7073
7300 ,נצרת ,330 ,רח 6173
7300 ,נצרת ,773 ,רח 4083
7300 ,נצרת ,410 ,רח 5083
7300 ,נצרת ,301 ,רח 6083
7300 ,נצרת ,956 ,רח 7083
7300 ,נצרת ,326 ,רח 6183
7300 ,נצרת ,525 ,רח 4093
7300 ,נצרת ,733 ,רח 5093
7300 ,נצרת ,314 ,רח 6093
7300 ,נצרת ,101 ,רח 6193
7300 ,נצרת ,424 ,רח 1004
7300 ,נצרת ,844 ,רח 2004
7300 ,נצרת ,849 ,רח 3004
7300 ,נצרת ,570 ,רח 4004
7300 ,נצרת ,123 ,רח 6004
7300 ,נצרת ,941 ,רח 7004
7300 ,נצרת ,923 ,רח 8004
7300 ,נצרת ,1026 ,רח 9004
7300 ,נצרת ,1074 ,רח 3104
7300 ,נצרת ,574 ,רח 4104
7300 ,נצרת ,422 ,רח 5104
7300 ,נצרת ,369 ,רח 6104
7300 ,נצרת ,450 ,רח 7204
7300 ,נצרת ,524 ,רח 604
7300 ,נצרת ,372 ,רח 804
7300 ,נצרת ,1107 ,רח 1014
7300 ,נצרת ,858 ,רח 3014
7300 ,נצרת ,831 ,רח 4014
7300 ,נצרת ,1017 ,רח 5014
7300 ,נצרת ,564 ,רח 6014
7300 ,נצרת ,949 ,רח 7014
7300 ,נצרת ,144 ,רח 9014
7300 ,נצרת ,409 ,רח 114
7300 ,נצרת ,882 ,רח 1114
7300 ,נצרת ,1003 ,רח 5114
7300 ,נצרת ,371 ,רח 6114
7300 ,נצרת ,461 ,רח 7214
7300 ,נצרת ,1044 ,רח 2024
7300 ,נצרת ,451 ,רח 3024
7300 ,נצרת ,744 ,רח 4024
7300 ,נצרת ,722 ,רח 5024
7300 ,נצרת ,620 ,רח 6024
7300 ,נצרת ,960 ,רח 7024
7300 ,נצרת ,970 ,רח 8024
7300 ,נצרת ,151 ,רח 9024
7300 ,נצרת ,405 ,רח 124
7300 ,נצרת ,695 ,רח 5124
7300 ,נצרת ,647 ,רח 6124
7300 ,נצרת ,471 ,רח 7224
7300 ,נצרת ,120 ,רח 724
7300 ,נצרת ,982 ,רח 1034
7300 ,נצרת ,1006 ,רח 5034
7300 ,נצרת ,128 ,רח 6034
7300 ,נצרת ,953 ,רח 7034
7300 ,נצרת ,161 ,רח 9034
7300 ,נצרת ,662 ,רח 6134
7300 ,נצרת ,481 ,רח 7234
7300 ,נצרת ,877 ,רח 1044
7300 ,נצרת ,838 ,רח 3044
7300 ,נצרת ,810 ,רח 4044
7300 ,נצרת ,1024 ,רח 5044
7300 ,נצרת ,215 ,רח 6044
7300 ,נצרת ,1055 ,רח 7044
7300 ,נצרת ,636 ,רח 6144
7300 ,נצרת ,491 ,רח 7244
7300 ,נצרת ,1085 ,רח 1054
7300 ,נצרת ,206 ,רח 6054
7300 ,נצרת ,974 ,רח 7054
7300 ,נצרת ,337 ,רח 6154
7300 ,נצרת ,1098 ,רח 1064
7300 ,נצרת ,821 ,רח 4064
7300 ,נצרת ,1008 ,רח 5064
7300 ,נצרת ,367 ,רח 6064
7300 ,נצרת ,1059 ,רח 7064
7300 ,נצרת ,324 ,רח 6164
7300 ,נצרת ,794 ,רח 4074
7300 ,נצרת ,687 ,רח 5074
7300 ,נצרת ,221 ,רח 6074
7300 ,נצרת ,912 ,רח 7074
7300 ,נצרת ,329 ,רח 6174
7300 ,נצרת ,510 ,רח 4084
7300 ,נצרת ,702 ,רח 5084
7300 ,נצרת ,648 ,רח 6084
7300 ,נצרת ,674 ,רח 6184
7300 ,נצרת ,573 ,רח 4094
7300 ,נצרת ,306 ,רח 6094
7300 ,נצרת ,1116 ,רח 1005
7300 ,נצרת ,1032 ,רח 2005
7300 ,נצרת ,850 ,רח 3005
7300 ,נצרת ,571 ,רח 4005
7300 ,נצרת ,362 ,רח 5005
7300 ,נצרת ,942 ,רח 7005
7300 ,נצרת ,924 ,רח 8005
7300 ,נצרת ,1005 ,רח 9005
7300 ,נצרת ,989 ,רח 1105
7300 ,נצרת ,837 ,רח 3105
7300 ,נצרת ,375 ,רח 4105
7300 ,נצרת ,711 ,רח 5105
7300 ,נצרת ,354 ,רח 6105
7300 ,נצרת ,452 ,רח 7205
7300 ,נצרת ,1109 ,רח 1015
7300 ,נצרת ,859 ,רח 3015
7300 ,נצרת ,832 ,רח 4015
7300 ,נצרת ,1018 ,רח 5015
7300 ,נצרת ,565 ,רח 6015
7300 ,נצרת ,950 ,רח 7015
7300 ,נצרת ,933 ,רח 8015
7300 ,נצרת ,145 ,רח 9015
7300 ,נצרת ,883 ,רח 1115
7300 ,נצרת ,713 ,רח 5115
7300 ,נצרת ,361 ,רח 6115
7300 ,נצרת ,462 ,רח 7215
7300 ,נצרת ,1113 ,רח 1025
7300 ,נצרת ,1045 ,רח 2025
7300 ,נצרת ,745 ,רח 4025
7300 ,נצרת ,728 ,רח 5025
7300 ,נצרת ,568 ,רח 6025
7300 ,נצרת ,895 ,רח 7025
7300 ,נצרת ,961 ,רח 8025
7300 ,נצרת ,152 ,רח 9025
7300 ,נצרת ,978 ,רח 1125
7300 ,נצרת ,651 ,רח 6125
7300 ,נצרת ,472 ,רח 7225
7300 ,נצרת ,1117 ,רח 1035
7300 ,נצרת ,1072 ,רח 3035
7300 ,נצרת ,803 ,רח 4035
7300 ,נצרת ,407 ,רח 5035
7300 ,נצרת ,625 ,רח 6035
7300 ,נצרת ,887 ,רח 7035
7300 ,נצרת ,932 ,רח 8035
7300 ,נצרת ,162 ,רח 9035
7300 ,נצרת ,307 ,רח 6135
7300 ,נצרת ,482 ,רח 7235
7300 ,נצרת ,878 ,רח 1045
7300 ,נצרת ,1028 ,רח 2045
7300 ,נצרת ,865 ,רח 3045
7300 ,נצרת ,811 ,רח 4045
7300 ,נצרת ,994 ,רח 5045
7300 ,נצרת ,214 ,רח 6045
7300 ,נצרת ,1056 ,רח 7045
7300 ,נצרת ,340 ,רח 6145
7300 ,נצרת ,492 ,רח 7245
7300 ,נצרת ,1086 ,רח 1055
7300 ,נצרת ,783 ,רח 4055
7300 ,נצרת ,999 ,רח 5055
7300 ,נצרת ,132 ,רח 6055
7300 ,נצרת ,975 ,רח 7055
7300 ,נצרת ,764 ,רח 4065
7300 ,נצרת ,229 ,רח 6065
7300 ,נצרת ,908 ,רח 7065
7300 ,נצרת ,671 ,רח 6165
7300 ,נצרת ,795 ,רח 4075
7300 ,נצרת ,706 ,רח 5075
7300 ,נצרת ,223 ,רח 6075
7300 ,נצרת ,393 ,רח 6175
7300 ,נצרת ,113 ,רח 4085
7300 ,נצרת ,370 ,רח 6085
7300 ,נצרת ,1062 ,רח 7085
7300 ,נצרת ,312 ,רח 6185
7300 ,נצרת ,822 ,רח 4095
7300 ,נצרת ,130 ,רח 6095
7300 ,נצרת ,566 ,רח 6195
7300 ,נצרת ,429 ,רח 1006
7300 ,נצרת ,1033 ,רח 2006
7300 ,נצרת ,851 ,רח 3006
7300 ,נצרת ,580 ,רח 4006
7300 ,נצרת ,943 ,רח 7006
7300 ,נצרת ,925 ,רח 8006
7300 ,נצרת ,383 ,רח 5106
7300 ,נצרת ,453 ,רח 7206
7300 ,נצרת ,1108 ,רח 1016
7300 ,נצרת ,1039 ,רח 2016
7300 ,נצרת ,860 ,רח 3016
7300 ,נצרת ,833 ,רח 4016
7300 ,נצרת ,963 ,רח 8016
7300 ,נצרת ,146 ,רח 9016
7300 ,נצרת ,984 ,רח 1116
7300 ,נצרת ,688 ,רח 5116
7300 ,נצרת ,644 ,רח 6116
7300 ,נצרת ,463 ,רח 7216
7300 ,נצרת ,1114 ,רח 1026
7300 ,נצרת ,864 ,רח 3026
7300 ,נצרת ,746 ,רח 4026
7300 ,נצרת ,403 ,רח 5026
7300 ,נצרת ,122 ,רח 6026
7300 ,נצרת ,896 ,רח 7026
7300 ,נצרת ,969 ,רח 8026
7300 ,נצרת ,153 ,רח 9026
7300 ,נצרת ,341 ,רח 6126
7300 ,נצרת ,473 ,רח 7226
7300 ,נצרת ,871 ,רח 1036
7300 ,נצרת ,760 ,רח 3036
7300 ,נצרת ,780 ,רח 4036
7300 ,נצרת ,202 ,רח 6036
7300 ,נצרת ,1050 ,רח 7036
7300 ,נצרת ,163 ,רח 9036
7300 ,נצרת ,382 ,רח 5136
7300 ,נצרת ,308 ,רח 6136
7300 ,נצרת ,483 ,רח 7236
7300 ,נצרת ,879 ,רח 1046
7300 ,נצרת ,812 ,רח 4046
7300 ,נצרת ,995 ,רח 5046
7300 ,נצרת ,213 ,רח 6046
7300 ,נצרת ,1057 ,רח 7046
7300 ,נצרת ,637 ,רח 6146
7300 ,נצרת ,493 ,רח 7246
7300 ,נצרת ,1087 ,רח 1056
7300 ,נצרת ,784 ,רח 4056
7300 ,נצרת ,408 ,רח 5056
7300 ,נצרת ,124 ,רח 6056
7300 ,נצרת ,976 ,רח 7056
7300 ,נצרת ,334 ,רח 6156
7300 ,נצרת ,1093 ,רח 1066
7300 ,נצרת ,801 ,רח 4066
7300 ,נצרת ,684 ,רח 5066
7300 ,נצרת ,611 ,רח 6066
7300 ,נצרת ,909 ,רח 7066
7300 ,נצרת ,327 ,רח 6166
7300 ,נצרת ,517 ,רח 4076
7300 ,נצרת ,707 ,רח 5076
7300 ,נצרת ,630 ,רח 6076
7300 ,נצרת ,659 ,רח 6176
7300 ,נצרת ,415 ,רח 5086
7300 ,נצרת ,619 ,רח 6086
7300 ,נצרת ,1063 ,רח 7086
7300 ,נצרת ,653 ,רח 6186
7300 ,נצרת ,734 ,רח 5096
7300 ,נצרת ,632 ,רח 6096
7300 ,נצרת ,1034 ,רח 2007
7300 ,נצרת ,852 ,רח 3007
7300 ,נצרת ,1010 ,רח 5007
7300 ,נצרת ,552 ,רח 6007
7300 ,נצרת ,944 ,רח 7007
7300 ,נצרת ,926 ,רח 8007
7300 ,נצרת ,881 ,רח 1107
7300 ,נצרת ,678 ,רח 5107
7300 ,נצרת ,641 ,רח 6107
7300 ,נצרת ,454 ,רח 7207
7300 ,נצרת ,1105 ,רח 1017
7300 ,נצרת ,845 ,רח 2017
7300 ,נצרת ,861 ,רח 3017
7300 ,נצרת ,834 ,רח 4017
7300 ,נצרת ,404 ,רח 5017
7300 ,נצרת ,567 ,רח 6017
7300 ,נצרת ,890 ,רח 7017
7300 ,נצרת ,983 ,רח 1117
7300 ,נצרת ,692 ,רח 5117
7300 ,נצרת ,645 ,רח 6117
7300 ,נצרת ,464 ,רח 7217
7300 ,נצרת ,1099 ,רח 1027
7300 ,נצרת ,503 ,רח 3027
7300 ,נצרת ,747 ,רח 4027
7300 ,נצרת ,1020 ,רח 5027
7300 ,נצרת ,897 ,רח 7027
7300 ,נצרת ,154 ,רח 9027
7300 ,נצרת ,323 ,רח 127
7300 ,נצרת ,350 ,רח 6127
7300 ,נצרת ,474 ,רח 7227
7300 ,נצרת ,872 ,רח 1037
7300 ,נצרת ,761 ,רח 3037
7300 ,נצרת ,804 ,רח 4037
7300 ,נצרת ,992 ,רח 5037
7300 ,נצרת ,600 ,רח 6037
7300 ,נצרת ,886 ,רח 7037
7300 ,נצרת ,164 ,רח 9037
7300 ,נצרת ,381 ,רח 5137
7300 ,נצרת ,638 ,רח 6137
7300 ,נצרת ,484 ,רח 7237
7300 ,נצרת ,813 ,רח 4047
7300 ,נצרת ,996 ,רח 5047
7300 ,נצרת ,220 ,רח 6047
7300 ,נצרת ,1058 ,רח 7047
7300 ,נצרת ,652 ,רח 6147
7300 ,נצרת ,494 ,רח 7247
7300 ,נצרת ,1088 ,רח 1057
7300 ,נצרת ,785 ,רח 4057
7300 ,נצרת ,1007 ,רח 5057
7300 ,נצרת ,602 ,רח 6057
7300 ,נצרת ,977 ,רח 7057
7300 ,נצרת ,212 ,רח 6157
7300 ,נצרת ,880 ,רח 1067
7300 ,נצרת ,787 ,רח 4067
7300 ,נצרת ,704 ,רח 5067
7300 ,נצרת ,612 ,רח 6067
7300 ,נצרת ,336 ,רח 6167
7300 ,נצרת ,796 ,רח 4077
7300 ,נצרת ,411 ,רח 5077
7300 ,נצרת ,228 ,רח 6077
7300 ,נצרת ,665 ,רח 6177
7300 ,נצרת ,115 ,רח 4087
7300 ,נצרת ,1029 ,רח 5087
7300 ,נצרת ,356 ,רח 6087
7300 ,נצרת ,914 ,רח 7087
7300 ,נצרת ,817 ,רח 4097
7300 ,נצרת ,735 ,רח 5097
7300 ,נצרת ,633 ,רח 6097
7300 ,נצרת ,204 ,רח 6197
7300 ,נצרת ,1106 ,רח 1008
7300 ,נצרת ,1035 ,רח 2008
7300 ,נצרת ,853 ,רח 3008
7300 ,נצרת ,575 ,רח 4008
7300 ,נצרת ,1011 ,רח 5008
7300 ,נצרת ,117 ,רח 6008
7300 ,נצרת ,945 ,רח 7008
7300 ,נצרת ,927 ,רח 8008
7300 ,נצרת ,679 ,רח 5108
7300 ,נצרת ,355 ,רח 6108
7300 ,נצרת ,455 ,רח 7208
7300 ,נצרת ,1111 ,רח 1018
7300 ,נצרת ,1041 ,רח 2018
7300 ,נצרת ,740 ,רח 4018
7300 ,נצרת ,990 ,רח 5018
7300 ,נצרת ,108 ,רח 6018
7300 ,נצרת ,951 ,רח 7018
7300 ,נצרת ,384 ,רח 5118
7300 ,נצרת ,646 ,רח 6118
7300 ,נצרת ,465 ,רח 7218
7300 ,נצרת ,1115 ,רח 1028
7300 ,נצרת ,868 ,רח 3028
7300 ,נצרת ,748 ,רח 4028
7300 ,נצרת ,723 ,רח 5028
7300 ,נצרת ,208 ,רח 6028
7300 ,נצרת ,898 ,רח 7028
7300 ,נצרת ,155 ,רח 9028
7300 ,נצרת ,650 ,רח 5128
7300 ,נצרת ,660 ,רח 6128
7300 ,נצרת ,475 ,רח 7228
7300 ,נצרת ,762 ,רח 3038
7300 ,נצרת ,805 ,רח 4038
7300 ,נצרת ,993 ,רח 5038
7300 ,נצרת ,203 ,רח 6038
7300 ,נצרת ,954 ,רח 7038
7300 ,נצרת ,165 ,רח 9038
7300 ,נצרת ,364 ,רח 6138
7300 ,נצרת ,485 ,רח 7238
7300 ,נצרת ,814 ,רח 4048
7300 ,נצרת ,997 ,רח 5048
7300 ,נצרת ,590 ,רח 6048
7300 ,נצרת ,495 ,רח 7248
7300 ,נצרת ,1089 ,רח 1058
7300 ,נצרת ,786 ,רח 4058
7300 ,נצרת ,680 ,רח 5058
7300 ,נצרת ,222 ,רח 6058
7300 ,נצרת ,902 ,רח 7058
7300 ,נצרת ,217 ,רח 6158
7300 ,נצרת ,788 ,רח 4068
7300 ,נצרת ,705 ,רח 5068
7300 ,נצרת ,613 ,רח 6068
7300 ,נצרת ,319 ,רח 6168
7300 ,נצרת ,797 ,רח 4078
7300 ,נצרת ,708 ,רח 5078
7300 ,נצרת ,798 ,רח 4088
7300 ,נצרת ,401 ,רח 5088
7300 ,נצרת ,360 ,רח 6088
7300 ,נצרת ,736 ,רח 5098
7300 ,נצרת ,305 ,רח 6098
7300 ,נצרת ,1100 ,רח 1009
7300 ,נצרת ,1036 ,רח 2009
7300 ,נצרת ,854 ,רח 3009
7300 ,נצרת ,581 ,רח 4009
7300 ,נצרת ,1012 ,רח 5009
7300 ,נצרת ,560 ,רח 6009
7300 ,נצרת ,946 ,רח 7009
7300 ,נצרת ,928 ,רח 8009
7300 ,נצרת ,696 ,רח 5109
7300 ,נצרת ,642 ,רח 6109
7300 ,נצרת ,456 ,רח 7209
7300 ,נצרת ,1110 ,רח 1019
7300 ,נצרת ,862 ,רח 3019
7300 ,נצרת ,741 ,רח 4019
7300 ,נצרת ,991 ,רח 5019
7300 ,נצרת ,109 ,רח 6019
7300 ,נצרת ,891 ,רח 7019
7300 ,נצרת ,964 ,רח 8019
7300 ,נצרת ,693 ,רח 5119
7300 ,נצרת ,344 ,רח 6119
7300 ,נצרת ,466 ,רח 7219
7300 ,נצרת ,1070 ,רח 3029
7300 ,נצרת ,749 ,רח 4029
7300 ,נצרת ,724 ,רח 5029
7300 ,נצרת ,209 ,רח 6029
7300 ,נצרת ,971 ,רח 7029
7300 ,נצרת ,156 ,רח 9029
7300 ,נצרת ,1025 ,רח 5129
7300 ,נצרת ,661 ,רח 6129
7300 ,נצרת ,476 ,רח 7229
7300 ,נצרת ,835 ,רח 3039
7300 ,נצרת ,806 ,רח 4039
7300 ,נצרת ,428 ,רח 5039
7300 ,נצרת ,127 ,רח 6039
7300 ,נצרת ,955 ,רח 7039
7300 ,נצרת ,342 ,רח 6139
7300 ,נצרת ,486 ,רח 7239
7300 ,נצרת ,1081 ,רח 1049
7300 ,נצרת ,815 ,רח 4049
7300 ,נצרת ,998 ,רח 5049
7300 ,נצרת ,591 ,רח 6049
7300 ,נצרת ,899 ,רח 7049
7300 ,נצרת ,663 ,רח 6149
7300 ,נצרת ,496 ,רח 7249
7300 ,נצרת ,1090 ,רח 1059
7300 ,נצרת ,518 ,רח 4059
7300 ,נצרת ,697 ,רח 5059
7300 ,נצרת ,903 ,רח 7059
7300 ,נצרת ,1095 ,רח 6159
7300 ,נצרת ,789 ,רח 4069
7300 ,נצרת ,685 ,רח 5069
7300 ,נצרת ,617 ,רח 6069
7300 ,נצרת ,1060 ,רח 7069
7300 ,נצרת ,664 ,רח 6169
7300 ,נצרת ,770 ,רח 4079
7300 ,נצרת ,709 ,רח 5079
7300 ,נצרת ,358 ,רח 6079
7300 ,נצרת ,515 ,רח 4089
7300 ,נצרת ,416 ,רח 5089
7300 ,נצרת ,309 ,רח 6089
7300 ,נצרת ,1009 ,רח 5099
7300 ,נצרת ,634 ,רח 6099
7300 ,נצרת ,432 ,ש אחוה
7300 ,נצרת ,6500 ,ש הפועל הערבי
7300 ,נצרת ,6501 ,ש חילים משוחררים
7300 ,נצרת ,6518 ,ש נמסאווי
7300 ,נצרת ,6502 ,ש פועלי נצרת
7300 ,נצרת ,6519 ,שיכון שנלר
7300 ,נצרת ,6503 ,שכ אום קבאי
7300 ,נצרת ,6512 ,שכ אל בטריס
7300 ,נצרת ,6511 ,שכ אל דבס
7300 ,נצרת ,6514 ,שכ אל כרום
7300 ,נצרת ,6509 ,שכ אל מוחלס
7300 ,נצרת ,6507 ,שכ אל מוטראן
7300 ,נצרת ,6523 ,שכ ביר אל אמיר
7300 ,נצרת ,6510 ,שכ הורדים
7300 ,נצרת ,6513 ,שכ המרוניטים
7300 ,נצרת ,6524 ,שכ חאלת א-דיר
7300 ,נצרת ,6504 ,שכ מזרחית
7300 ,נצרת ,6508 ,שכ סאפפרה
7300 ,נצרת ,6516 ,שכ רום
7300 ,נצרת ,6506 ,שכון רם
7300 ,נצרת ,504 ,תופיק זיאד
2500 ,נשר ,170 ,אביר יעקב
2500 ,נשר ,189 ,אופקים
2500 ,נשר ,183 ,אורח חיים
2500 ,נשר ,200 ,אורן
2500 ,נשר ,6521 ,אזור מלאכה ומסחר
2500 ,נשר ,6527 ,אזור תעשייה
2500 ,נשר ,142 ,אשכולות
2500 ,נשר ,139 ,ברק
2500 ,נשר ,6500 ,בתי נשר
2500 ,נשר ,135 ,דבורה
2500 ,נשר ,237 ,דוכיפת
2500 ,נשר ,205 ,דליה
2500 ,נשר ,173 ,דרך בר יהודה
2500 ,נשר ,144 ,דרך החיים
2500 ,נשר ,181 ,דרך הטכניון
2500 ,נשר ,158 ,דרך הטף
2500 ,נשר ,185 ,דרך השלום
2500 ,נשר ,138 ,דרך משה
2500 ,נשר ,118 ,האילנות
2500 ,נשר ,222 ,האירוסים
2500 ,נשר ,224 ,האלון
2500 ,נשר ,228 ,האשל
2500 ,נשר ,256 ,הבונים
2500 ,נשר ,150 ,הבטחון
2500 ,נשר ,182 ,הבעש"ט
2500 ,נשר ,201 ,הברוש
2500 ,נשר ,134 ,הבריכה
2500 ,נשר ,172 ,הגבורה
2500 ,נשר ,128 ,הדקל
2500 ,נשר ,227 ,ההדס
2500 ,נשר ,143 ,ההסתדרות
2500 ,נשר ,120 ,ההשכלה
2500 ,נשר ,204 ,הורדים
2500 ,נשר ,132 ,הותיקים
2500 ,נשר ,202 ,הזית
2500 ,נשר ,133 ,החלוצים
2500 ,נשר ,194 ,החצב
2500 ,נשר ,213 ,החרוב
2500 ,נשר ,258 ,החרושת
2500 ,נשר ,239 ,היזמים
2500 ,נשר ,252 ,היצירה
2500 ,נשר ,127 ,הירוק
2500 ,נשר ,131 ,הכלניות
2500 ,נשר ,199 ,הלוטם
2500 ,נשר ,212 ,הלילך
2500 ,נשר ,124 ,המגינים
2500 ,נשר ,175 ,המורד
2500 ,נשר ,218 ,המורן
2500 ,נשר ,184 ,המייסדים
2500 ,נשר ,255 ,המלאכה
2500 ,נשר ,254 ,המסגר
2500 ,נשר ,251 ,המסילה
2500 ,נשר ,174 ,המעפילים
2500 ,נשר ,187 ,המצפה
2500 ,נשר ,197 ,המרגנית
2500 ,נשר ,121 ,המרכז
2500 ,נשר ,151 ,המשעול התלול
2500 ,נשר ,159 ,המתמיד
2500 ,נשר ,215 ,הנוריות
2500 ,נשר ,211 ,הסביון
2500 ,נשר ,223 ,הסחלב
2500 ,נשר ,122 ,העליה
2500 ,נשר ,190 ,העמק
2500 ,נשר ,229 ,הערבה
2500 ,נשר ,225 ,הערמונים
2500 ,נשר ,259 ,הפלדה
2500 ,נשר ,210 ,הצבעוני
2500 ,נשר ,145 ,הקוממיות
2500 ,נשר ,140 ,הקישון
2500 ,נשר ,156 ,הקצר
2500 ,נשר ,136 ,הרב בר מוחא מרדכי
2500 ,נשר ,217 ,הרדוף
2500 ,נשר ,230 ,הרותם
2500 ,נשר ,119 ,הרקפות
2500 ,נשר ,221 ,השושנים
2500 ,נשר ,214 ,השזיף
2500 ,נשר ,216 ,השיטה
2500 ,נשר ,257 ,השיש
2500 ,נשר ,219 ,השקמה
2500 ,נשר ,233 ,התאנה
2500 ,נשר ,125 ,התחנה
2500 ,נשר ,161 ,התיכון
2500 ,נשר ,220 ,התמר
2500 ,נשר ,253 ,התעשיה
2500 ,נשר ,226 ,התפוז
2500 ,נשר ,153 ,התשבי
2500 ,נשר ,146 ,זבולון
2500 ,נשר ,198 ,חבצלת
2500 ,נשר ,203 ,יסמין
2500 ,נשר ,137 ,יעל
2500 ,נשר ,130 ,יפה נוף
2500 ,נשר ,141 ,ישורון
2500 ,נשר ,110 ,כביש ארצי
2500 ,נשר ,6002 ,כיכר הדרך לטכניון
2500 ,נשר ,6001 ,ככר ההסתדרות
2500 ,נשר ,193 ,כליל החורש
2500 ,נשר ,234 ,מגלן
2500 ,נשר ,177 ,מורדי הגטאות
2500 ,נשר ,180 ,מיכאל
2500 ,נשר ,169 ,מעון לעובד
2500 ,נשר ,149 ,מעלה הגבורים
2500 ,נשר ,148 ,מעלות אליהו
2500 ,נשר ,6528 ,מרום כרמל
2500 ,נשר ,6501 ,מרכז חדש
2500 ,נשר ,236 ,נחשון
2500 ,נשר ,157 ,נרקיסים
2500 ,נשר ,9000 ,נשר
2500 ,נשר ,6513 ,נשר הותיקה
2500 ,נשר ,209 ,נתיב הארזים
2500 ,נשר ,178 ,נתיב הטרשים
2500 ,נשר ,160 ,נתיב הים
2500 ,נשר ,126 ,נתיב הכרמל
2500 ,נשר ,238 ,נתיב הנובל
2500 ,נשר ,208 ,נתיב הרימון
2500 ,נשר ,147 ,סיני
2500 ,נשר ,171 ,סמ בית אל
2500 ,נשר ,235 ,סמ התמר
2500 ,נשר ,232 ,סמ כליל החורש
2500 ,נשר ,129 ,עמוס
2500 ,נשר ,123 ,פרץ אברהם
2500 ,נשר ,195 ,צבר
2500 ,נשר ,188 ,צה"ל
2500 ,נשר ,191 ,ציון
2500 ,נשר ,231 ,צפצפה
2500 ,נשר ,152 ,קבוץ גלויות
2500 ,נשר ,192 ,קרן היסוד
2500 ,נשר ,6502 ,ש אגש
2500 ,נשר ,6503 ,ש ותיקים
2500 ,נשר ,6504 ,ש זוגות צעירים
2500 ,נשר ,6505 ,ש חדש בן דור
2500 ,נשר ,6507 ,ש חסכון בן דור
2500 ,נשר ,6509 ,ש חסכון נשר
2500 ,נשר ,6508 ,ש חסכון תל חנן
2500 ,נשר ,6510 ,ש מפונים נשר
2500 ,נשר ,6511 ,ש נט
2500 ,נשר ,6512 ,ש עובדי נשר
2500 ,נשר ,6514 ,ש עולים תל חנן
2500 ,נשר ,6515 ,ש עמידר בן דור
2500 ,נשר ,6516 ,ש עמידר נשר
2500 ,נשר ,6517 ,ש עממי נשר
2500 ,נשר ,6518 ,ש קרת
2500 ,נשר ,6519 ,ש רסקו נשר
2500 ,נשר ,154 ,שביל הבאר
2500 ,נשר ,179 ,שביל המדרון
2500 ,נשר ,155 ,שביל העמדה
2500 ,נשר ,186 ,שדרת היוצרים
2500 ,נשר ,6522 ,שכ בן דור
2500 ,נשר ,6523 ,שכ גבעת נשר
2500 ,נשר ,6520 ,שכ גבעת עמוס
2500 ,נשר ,6524 ,שכ מדרון
2500 ,נשר ,6525 ,שכ רמות יצחק
2500 ,נשר ,6526 ,שכ תל חנן
2500 ,נשר ,196 ,שקד
2500 ,נשר ,176 ,ששת הימים
3555 ,נתיב הגדוד ,9000 ,נתיב הגדוד
693 ,נתיב הל"ה ,101 ,אולפן קבוץ
693 ,נתיב הל"ה ,106 ,דרך האורנים
693 ,נתיב הל"ה ,103 ,דרך האלה
693 ,נתיב הל"ה ,105 ,דרך הבריכות
693 ,נתיב הל"ה ,104 ,דרך הכרם
693 ,נתיב הל"ה ,102 ,דרך הקבוץ
693 ,נתיב הל"ה ,107 ,דרך הראשונים
693 ,נתיב הל"ה ,128 ,הבוסתן
693 ,נתיב הל"ה ,123 ,הגפן
693 ,נתיב הל"ה ,124 ,הגת
693 ,נתיב הל"ה ,114 ,החסידות
693 ,נתיב הל"ה ,130 ,החרובים
693 ,נתיב הל"ה ,132 ,החשמליה
693 ,נתיב הל"ה ,122 ,היקב
693 ,נתיב הל"ה ,125 ,הכפר
693 ,נתיב הל"ה ,108 ,המדרגות
693 ,נתיב הל"ה ,119 ,העמק
693 ,נתיב הל"ה ,109 ,משעול האבנים
693 ,נתיב הל"ה ,127 ,משעול האשלים
693 ,נתיב הל"ה ,117 ,משעול הבאר
693 ,נתיב הל"ה ,112 ,משעול הגזית
693 ,נתיב הל"ה ,120 ,משעול הגן
693 ,נתיב הל"ה ,129 ,משעול הזית
693 ,נתיב הל"ה ,118 ,משעול הטללים
693 ,נתיב הל"ה ,113 ,משעול הכורכר
693 ,נתיב הל"ה ,115 ,משעול המים
693 ,נתיב הל"ה ,116 ,משעול המעיין
693 ,נתיב הל"ה ,121 ,משעול הנחל
693 ,נתיב הל"ה ,110 ,משעול הסלע
693 ,נתיב הל"ה ,111 ,משעול הצור
693 ,נתיב הל"ה ,126 ,משעול התאנים
693 ,נתיב הל"ה ,131 ,משעול חדר אוכל
693 ,נתיב הל"ה ,9000 ,נתיב הל"ה
1242 ,נתיב העשרה ,9000 ,נתיב העשרה
792 ,נתיב השיירה ,9000 ,נתיב השיירה
246 ,נתיבות ,128 ,אבן דנן
246 ,נתיבות ,159 ,אגוז
246 ,נתיבות ,258 ,אגס
246 ,נתיבות ,123 ,אהוד מנור
246 ,נתיבות ,237 ,אור לציון
246 ,נתיבות ,294 ,אורן
246 ,נתיבות ,300 ,אזוב
246 ,נתיבות ,6519 ,אזור תעשייה
246 ,נתיבות ,270 ,אחד עשר הנקודות
246 ,נתיבות ,287 ,אטד
246 ,נתיבות ,102 ,איש מצליח
246 ,נתיבות ,291 ,אלה
246 ,נתיבות ,295 ,אלון
246 ,נתיבות ,137 ,אלי כהן
246 ,נתיבות ,308 ,אלי סיני
246 ,נתיבות ,302 ,אליהו אילוז
246 ,נתיבות ,254 ,אפרסמון
246 ,נתיבות ,271 ,אפרסק
246 ,נתיבות ,192 ,אצ"ל
246 ,נתיבות ,178 ,ארזים
246 ,נתיבות ,140 ,אריאל שרון
246 ,נתיבות ,160 ,אריה אליאס
246 ,נתיבות ,165 ,אריק איינשטיין
246 ,נתיבות ,286 ,אשחר
246 ,נתיבות ,277 ,אשכולית
246 ,נתיבות ,196 ,אשל
246 ,נתיבות ,209 ,אתרוג
246 ,נתיבות ,310 ,בדולח
246 ,נתיבות ,193 ,ביל"ו
246 ,נתיבות ,145 ,בית וגן
246 ,נתיבות ,121 ,בן איש חי
246 ,נתיבות ,141 ,בן גוריון
246 ,נתיבות ,176 ,בעלי מלאכה
246 ,נתיבות ,195 ,ברוש
246 ,נתיבות ,6506 ,בריטיש קוטג'
246 ,נתיבות ,167 ,ג'ו עמר
246 ,נתיבות ,182 ,גבורי תשכ"ז
246 ,נתיבות ,6515 ,גבעת שיפרה
246 ,נתיבות ,321 ,גדיד
246 ,נתיבות ,256 ,גויאבה
246 ,נתיבות ,320 ,גן אור
246 ,נתיבות ,317 ,גני טל
246 ,נתיבות ,253 ,גפן
246 ,נתיבות ,181 ,ד"ר סמלו יוסף
246 ,נתיבות ,281 ,דובדבן
246 ,נתיבות ,314 ,דוגית
246 ,נתיבות ,298 ,דולב
246 ,נתיבות ,197 ,דקל
246 ,נתיבות ,242 ,דרכי רפאל
246 ,נתיבות ,177 ,האומן
246 ,נתיבות ,212 ,האורגים
246 ,נתיבות ,172 ,האר"י
246 ,נתיבות ,174 ,הבונים
246 ,נתיבות ,124 ,הגבורים
246 ,נתיבות ,6510 ,הגבעה המערבית
246 ,נתיבות ,191 ,הגנה
246 ,נתיבות ,214 ,הדס
246 ,נתיבות ,133 ,החזון איש
246 ,נתיבות ,171 ,החיד"א
246 ,נתיבות ,175 ,החרש
246 ,נתיבות ,134 ,הירדן
246 ,נתיבות ,146 ,הכותל
246 ,נתיבות ,303 ,המייסדים
246 ,נתיבות ,180 ,המסגר
246 ,נתיבות ,215 ,המשחררים
246 ,נתיבות ,213 ,הסוללים
246 ,נתיבות ,158 ,העצמאות
246 ,נתיבות ,179 ,הצורף
246 ,נתיבות ,143 ,הר הבית
246 ,נתיבות ,304 ,הראשונים
246 ,נתיבות ,101 ,הרב אבוחצירא
246 ,נתיבות ,208 ,הרב איפרגן
246 ,נתיבות ,131 ,הרב אלנקוה
246 ,נתיבות ,109 ,הרב בר אילן
246 ,נתיבות ,243 ,הרב גרשונוביץ
246 ,נתיבות ,103 ,הרב הרצוג
246 ,נתיבות ,105 ,הרב חורי חיים
246 ,נתיבות ,108 ,הרב חי הכהן
246 ,נתיבות ,163 ,הרב לוין
246 ,נתיבות ,238 ,הרב מזוז
246 ,נתיבות ,119 ,הרב מלול יוסף
246 ,נתיבות ,132 ,הרב משה חלפון הכהן
246 ,נתיבות ,250 ,הרב ניסים
246 ,נתיבות ,110 ,הרב עוזיאל
246 ,נתיבות ,151 ,הרב צבאן
246 ,נתיבות ,111 ,הרב קוק
246 ,נתיבות ,290 ,הרדוף הנחלים
246 ,נתיבות ,107 ,הרמב"ם
246 ,נתיבות ,150 ,הרצל
246 ,נתיבות ,104 ,הרשב"י
246 ,נתיבות ,161 ,השר שפירא
246 ,נתיבות ,249 ,ורדימון יצחק
246 ,נתיבות ,221 ,ז'בוטינסקי
246 ,נתיבות ,251 ,זית
246 ,נתיבות ,142 ,חב"ד
246 ,נתיבות ,272 ,חבוש
246 ,נתיבות ,211 ,חוצות היוצר
246 ,נתיבות ,120 ,חזני
246 ,נתיבות ,269 ,חטיבת הראל
246 ,נתיבות ,156 ,חי טייב
246 ,נתיבות ,230 ,חיל אוויר
246 ,נתיבות ,231 ,חיל הים
246 ,נתיבות ,232 ,חיל הנדסה
246 ,נתיבות ,233 ,חיל צנחנים
246 ,נתיבות ,236 ,חיל קשר
246 ,נתיבות ,234 ,חיל שריון
246 ,נתיבות ,235 ,חיל תותחנים
246 ,נתיבות ,162 ,חפץ חיים
246 ,נתיבות ,275 ,חרוב
246 ,נתיבות ,205 ,חרצית
246 ,נתיבות ,329 ,יהדות אוסטריה
246 ,נתיבות ,330 ,יהדות אוקראינה
246 ,נתיבות ,331 ,יהדות איטליה
246 ,נתיבות ,332 ,יהדות אלג'יריה
246 ,נתיבות ,333 ,יהדות ארגנטינה
246 ,נתיבות ,334 ,יהדות ארצות הברית
246 ,נתיבות ,335 ,יהדות אתיופיה
246 ,נתיבות ,336 ,יהדות בריטניה
246 ,נתיבות ,337 ,יהדות גרמניה
246 ,נתיבות ,338 ,יהדות הולנד
246 ,נתיבות ,339 ,יהדות הונגריה
246 ,נתיבות ,340 ,יהדות טוניסיה
246 ,נתיבות ,341 ,יהדות יוון
246 ,נתיבות ,342 ,יהדות לוב
246 ,נתיבות ,343 ,יהדות מקסיקו
246 ,נתיבות ,344 ,יהדות מרוקו
246 ,נתיבות ,345 ,יהדות ספרד
246 ,נתיבות ,346 ,יהדות פולין
246 ,נתיבות ,347 ,יהדות פורטוגל
246 ,נתיבות ,348 ,יהדות צרפת
246 ,נתיבות ,349 ,יהדות קנדה
246 ,נתיבות ,350 ,יהדות רומניה
246 ,נתיבות ,351 ,יהדות רוסיה
246 ,נתיבות ,352 ,יהדות תימן
246 ,נתיבות ,139 ,יהושוע אזולאי
246 ,נתיבות ,194 ,יובל
246 ,נתיבות ,307 ,יוסף אבו
246 ,נתיבות ,154 ,יוסף שילוח
246 ,נתיבות ,129 ,ילדי אוסלו
246 ,נתיבות ,169 ,יפה ירקוני
246 ,נתיבות ,168 ,יצחק שמיר
246 ,נתיבות ,305 ,יקירי העיר
246 ,נתיבות ,118 ,ישיבת הנגב
246 ,נתיבות ,293 ,כיסוסית
246 ,נתיבות ,206 ,כלנית
246 ,נתיבות ,318 ,כפר דרום
246 ,נתיבות ,328 ,כפר ים
246 ,נתיבות ,325 ,כרם עצמונה
246 ,נתיבות ,166 ,לאה גולדברג
246 ,נתיבות ,299 ,לוטם
246 ,נתיבות ,190 ,לח"י
246 ,נתיבות ,292 ,ליבנה
246 ,נתיבות ,278 ,לימון
246 ,נתיבות ,157 ,מבצע קדש
246 ,נתיבות ,122 ,מויאל שמעון
246 ,נתיבות ,147 ,מונטיפיורי
246 ,נתיבות ,324 ,מורג
246 ,נתיבות ,7001 ,מכללת חמדת הדרום
246 ,נתיבות ,113 ,מלכי ישראל
246 ,נתיבות ,130 ,מסעוד אלפסי
246 ,נתיבות ,187 ,מעפיל
246 ,נתיבות ,285 ,מרווה
246 ,נתיבות ,114 ,מרכז תעשיה
246 ,נתיבות ,6505 ,משה"ב
246 ,נתיבות ,280 ,משמש
246 ,נתיבות ,222 ,משעול בעל הנס
246 ,נתיבות ,201 ,משעול נתניהו
246 ,נתיבות ,312 ,נווה דקלים
246 ,נתיבות ,260 ,נחל ארנון
246 ,נתיבות ,261 ,נחל בוהן
246 ,נתיבות ,262 ,נחל גרופית
246 ,נתיבות ,263 ,נחל דן
246 ,נתיבות ,264 ,נחל המעיינות
246 ,נתיבות ,265 ,נחל ורדית
246 ,נתיבות ,266 ,נחל זויתן
246 ,נתיבות ,267 ,נחל חבר
246 ,נתיבות ,268 ,נחל טף
246 ,נתיבות ,239 ,נחלת שלמה
246 ,נתיבות ,319 ,ניסנית
246 ,נתיבות ,100 ,נעמי שמר
246 ,נתיבות ,313 ,נצר חזני
246 ,נתיבות ,323 ,נצרים
246 ,נתיבות ,204 ,נרקיס
246 ,נתיבות ,164 ,נתיב המעלה
246 ,נתיבות ,9000 ,נתיבות
246 ,נתיבות ,207 ,סביון
246 ,נתיבות ,136 ,סיני
246 ,נתיבות ,125 ,סשה ארגוב
246 ,נתיבות ,255 ,עינב
246 ,נתיבות ,188 ,עליה
246 ,נתיבות ,301 ,ער אציל
246 ,נתיבות ,210 ,ערבה
246 ,נתיבות ,282 ,ערמון
246 ,נתיבות ,6518 ,פארק תעשייה
246 ,נתיבות ,322 ,פאת שדה
246 ,נתיבות ,259 ,פטל
246 ,נתיבות ,189 ,פלמ"ח
246 ,נתיבות ,257 ,פקאן
246 ,נתיבות ,202 ,פרחי ישראל
246 ,נתיבות ,198 ,צאלה
246 ,נתיבות ,186 ,צה"ל
246 ,נתיבות ,296 ,צפצפה
246 ,נתיבות ,241 ,קהילות יעקב
246 ,נתיבות ,246 ,קורין
246 ,נתיבות ,311 ,קטיף
246 ,נתיבות ,289 ,קטלב
246 ,נתיבות ,288 ,קידה
246 ,נתיבות ,6517 ,קרית בן אריה
246 ,נתיבות ,6514 ,קרית מנחם
246 ,נתיבות ,6511 ,קרית משה
246 ,נתיבות ,106 ,רב יוסף הכהן
246 ,נתיבות ,116 ,רבי עקיבא
246 ,נתיבות ,153 ,רימון
246 ,נתיבות ,135 ,רמת הגולן
246 ,נתיבות ,316 ,רפיח ים
246 ,נתיבות ,203 ,רקפת
246 ,נתיבות ,6503 ,ש 2 קומות
246 ,נתיבות ,6500 ,ש וילות
246 ,נתיבות ,6502 ,ש טרומיים
246 ,נתיבות ,6508 ,ש נוה נוי
246 ,נתיבות ,115 ,שבטי ישראל
246 ,נתיבות ,155 ,שד ויצמן
246 ,נתיבות ,112 ,שד ירושלים
246 ,נתיבות ,126 ,שושנה דמרי
246 ,נתיבות ,276 ,שזיף
246 ,נתיבות ,199 ,שיטה
246 ,נתיבות ,138 ,שייקה אופיר
246 ,נתיבות ,297 ,שיקמה
246 ,נתיבות ,315 ,שירת הים
246 ,נתיבות ,6512 ,שכ אור החיים
246 ,נתיבות ,6509 ,שכ בבא סאלי
246 ,נתיבות ,6507 ,שכ הורד
246 ,נתיבות ,6516 ,שכ החורש
246 ,נתיבות ,6513 ,שכ נטעים
246 ,נתיבות ,6504 ,שכ שלום בוניך
246 ,נתיבות ,149 ,שלום בונייך
246 ,נתיבות ,306 ,שלום דנינו
246 ,נתיבות ,326 ,שליו
246 ,נתיבות ,240 ,שפתי חיים
246 ,נתיבות ,273 ,שקד
246 ,נתיבות ,152 ,תאנה
246 ,נתיבות ,283 ,תות
246 ,נתיבות ,327 ,תל קטיפה
246 ,נתיבות ,284 ,תלתן
246 ,נתיבות ,252 ,תמר
246 ,נתיבות ,274 ,תפוז
246 ,נתיבות ,279 ,תפוח
246 ,נתיבות ,185 ,תקומה
7400 ,נתניה ,101 ,אבא הלל סילבר
7400 ,נתניה ,704 ,אביעד
7400 ,נתניה ,602 ,אבן גבירול
7400 ,נתניה ,603 ,אבן עזרא
7400 ,נתניה ,103 ,אבנר בן נר
7400 ,נתניה ,859 ,אבנר חי שאקי
7400 ,נתניה ,102 ,אבנר מאיר
7400 ,נתניה ,601 ,אברבנאל
7400 ,נתניה ,591 ,אברהם חיים לודיוב
7400 ,נתניה ,568 ,אברהם מנוחין
7400 ,נתניה ,1005 ,אברהם פרידמן
7400 ,נתניה ,295 ,אגדת ים
7400 ,נתניה ,981 ,אד"ם הכהן
7400 ,נתניה ,374 ,אדית פיאף
7400 ,נתניה ,1071 ,אדמונית
7400 ,נתניה ,1226 ,אדר
7400 ,נתניה ,282 ,אהבת הארץ
7400 ,נתניה ,105 ,אהוד בן גרא
7400 ,נתניה ,604 ,אהרון
7400 ,נתניה ,801 ,אהרונוביץ
7400 ,נתניה ,639 ,אהרוני יצחק
7400 ,נתניה ,732 ,אהרן אמיר
7400 ,נתניה ,581 ,אוגוסט רנואר
7400 ,נתניה ,372 ,אווה גרדנר
7400 ,נתניה ,810 ,אולפן בן יהודה
7400 ,נתניה ,401 ,אוסישקין
7400 ,נתניה ,529 ,אוסקר שינדלר
7400 ,נתניה ,349 ,אופיר שיקה
7400 ,נתניה ,187 ,אורגד
7400 ,נתניה ,840 ,אורט
7400 ,נתניה ,690 ,אורי אורבך
7400 ,נתניה ,6509 ,אז תעשייה ע"ש ספיר
7400 ,נתניה ,234 ,אח"י דקר
7400 ,נתניה ,402 ,אחד העם
7400 ,נתניה ,446 ,אחימאיר
7400 ,נתניה ,605 ,אחרית הימים
7400 ,נתניה ,1046 ,איברהם לינקולן
7400 ,נתניה ,893 ,אייזיק ניוטון
7400 ,נתניה ,297 ,איילת השחר
7400 ,נתניה ,1228 ,אייר
7400 ,נתניה ,244 ,איכילוב
7400 ,נתניה ,298 ,אילאיל
7400 ,נתניה ,574 ,אילנה רובינא
7400 ,נתניה ,340 ,אימבר
7400 ,נתניה ,370 ,אינגריד ברגמן
7400 ,נתניה ,106 ,אינשטיין
7400 ,נתניה ,144 ,איסר הראל
7400 ,נתניה ,1101 ,אירוס הארגמן
7400 ,נתניה ,536 ,אירנה סנדלר
7400 ,נתניה ,1039 ,אל"מ פרידן בן ציון
7400 ,נתניה ,1231 ,אלול
7400 ,נתניה ,803 ,אלומות
7400 ,נתניה ,407 ,אלון צבי
7400 ,נתניה ,651 ,אלוני אבא
7400 ,נתניה ,644 ,אלחריזי
7400 ,נתניה ,451 ,אלי ויזל
7400 ,נתניה ,168 ,אלי כהן
7400 ,נתניה ,1013 ,אליאב לובה
7400 ,נתניה ,140 ,אליהו הגלעדי
7400 ,נתניה ,901 ,אליהו התשבי
7400 ,נתניה ,124 ,אליהו עטיה
7400 ,נתניה ,686 ,אלינור אוסטרום
7400 ,נתניה ,689 ,אליס מונרו
7400 ,נתניה ,899 ,אליס פון-באטנברג
7400 ,נתניה ,902 ,אלישע
7400 ,נתניה ,895 ,אלכסנדר בל
7400 ,נתניה ,621 ,אלכסנדר ינאי
7400 ,נתניה ,199 ,אלמוג
7400 ,נתניה ,1003 ,אלנקווה אפרים
7400 ,נתניה ,1114 ,אלעזר דוד
7400 ,נתניה ,894 ,אלפרד נובל
7400 ,נתניה ,107 ,אלקלעי
7400 ,נתניה ,1068 ,אמנון ותמר
7400 ,נתניה ,572 ,אמנון ליפקין שחק
7400 ,נתניה ,167 ,אמרי יוסף
7400 ,נתניה ,1158 ,אנדה עמיר
7400 ,נתניה ,201 ,אנדריוס
7400 ,נתניה ,900 ,אנה פרנק
7400 ,נתניה ,202 ,אנטוורפן
7400 ,נתניה ,635 ,אנילביץ
7400 ,נתניה ,1054 ,אנצו סירני
7400 ,נתניה ,590 ,אנשי החיל
7400 ,נתניה ,196 ,אסיף
7400 ,נתניה ,870 ,אסתר המלכה
7400 ,נתניה ,642 ,אפרים
7400 ,נתניה ,867 ,אפרים קישון
7400 ,נתניה ,978 ,אפשטיין
7400 ,נתניה ,1157 ,אצ"ג
7400 ,נתניה ,274 ,אצ"י
7400 ,נתניה ,503 ,אצ"ל
7400 ,נתניה ,1132 ,אקסודוס
7400 ,נתניה ,997 ,אריה לייב יפה
7400 ,נתניה ,358 ,אריק לביא
7400 ,נתניה ,802 ,ארלוזורוב
7400 ,נתניה ,1020 ,ארליך שמחה
7400 ,נתניה ,995 ,ארן זלמן
7400 ,נתניה ,1042 ,ארצי שרמן
7400 ,נתניה ,1217 ,אשר
7400 ,נתניה ,1153 ,בארון דבורה
7400 ,נתניה ,303 ,בארי
7400 ,נתניה ,1079 ,בגין מנחם
7400 ,נתניה ,804 ,בובליק גדליהו
7400 ,נתניה ,181 ,בובר
7400 ,נתניה ,862 ,בוכנר יהודה
7400 ,נתניה ,805 ,בוסל
7400 ,נתניה ,553 ,בורגנסקי ישראל
7400 ,נתניה ,304 ,בורוכוב
7400 ,נתניה ,441 ,ביאליק
7400 ,נתניה ,956 ,ביל"ו
7400 ,נתניה ,375 ,בילי הולידיי
7400 ,נתניה ,305 ,בילינסון
7400 ,נתניה ,231 ,בית גיל הזהב
7400 ,נתניה ,435 ,בית חלוצות
7400 ,נתניה ,449 ,בית פלדמן
7400 ,נתניה ,857 ,בכר שמחה
7400 ,נתניה ,914 ,בלוז פסקל
7400 ,נתניה ,960 ,בלומנפלד
7400 ,נתניה ,247 ,בלפור
7400 ,נתניה ,171 ,בלקינד נעמן
7400 ,נתניה ,660 ,בלקני פנחס
7400 ,נתניה ,267 ,בן איש חי
7400 ,נתניה ,444 ,בן אליעזר
7400 ,נתניה ,848 ,בן חנן יצחק
7400 ,נתניה ,505 ,בן יהודה
7400 ,נתניה ,643 ,בן יוסף שלמה
7400 ,נתניה ,846 ,בן מאיר ישראל
7400 ,נתניה ,1237 ,בן נתן רפאל
7400 ,נתניה ,556 ,בן עטר חיים
7400 ,נתניה ,506 ,בן ציון
7400 ,נתניה ,628 ,בן שלום עובדיה
7400 ,נתניה ,356 ,בנאי יוסי
7400 ,נתניה ,1041 ,בנג'מין ד'יזראלי
7400 ,נתניה ,1130 ,בני בנימין
7400 ,נתניה ,272 ,בני יששכר
7400 ,נתניה ,158 ,בנין המשטרה
7400 ,נתניה ,1036 ,בעל התניא
7400 ,נתניה ,403 ,בקמן
7400 ,נתניה ,306 ,בר אילן
7400 ,נתניה ,669 ,בר גיורא
7400 ,נתניה ,947 ,בר יהודה
7400 ,נתניה ,185 ,בר יוחאי
7400 ,נתניה ,307 ,בר כוכבא
7400 ,נתניה ,1111 ,בר לב חיים
7400 ,נתניה ,507 ,ברודצקי
7400 ,נתניה ,903 ,ברוריה
7400 ,נתניה ,1252 ,ברוש
7400 ,נתניה ,104 ,ברט אהרון
7400 ,נתניה ,890 ,ברכה ואריה סגלסון
7400 ,נתניה ,190 ,ברכה שמעון
7400 ,נתניה ,351 ,ברמן בני
7400 ,נתניה ,308 ,ברנר
7400 ,נתניה ,108 ,ברק בן אבינועם
7400 ,נתניה ,404 ,ברקת ראובן
7400 ,נתניה ,180 ,בשביס זינגר
7400 ,נתניה ,1244 ,בת חן
7400 ,נתניה ,147 ,גאולה כהן
7400 ,נתניה ,1035 ,גאון בני
7400 ,נתניה ,1015 ,גבורי ישראל
7400 ,נתניה ,162 ,גבורי ניצנים
7400 ,נתניה ,508 ,גבע
7400 ,נתניה ,6587 ,גבעת האירוסים
7400 ,נתניה ,309 ,גבעת חיים
7400 ,נתניה ,1216 ,גד
7400 ,נתניה ,109 ,גדעון
7400 ,נתניה ,806 ,גוטמכר אליהו
7400 ,נתניה ,1152 ,גוטמן נחום
7400 ,נתניה ,1150 ,גולדברג לאה
7400 ,נתניה ,953 ,גולדמינץ
7400 ,נתניה ,311 ,גולומב
7400 ,נתניה ,656 ,גולי קניה
7400 ,נתניה ,994 ,גור מרדכי
7400 ,נתניה ,310 ,גורדון
7400 ,נתניה ,1055 ,גלילי ישראל
7400 ,נתניה ,110 ,גליקסון
7400 ,נתניה ,533 ,גרונר דב
7400 ,נתניה ,371 ,גרטה גרבו
7400 ,נתניה ,958 ,גרינבוים
7400 ,נתניה ,706 ,גרינברג
7400 ,נתניה ,855 ,גרינוולד
7400 ,נתניה ,204 ,גרנדוס
7400 ,נתניה ,808 ,גרץ
7400 ,נתניה ,502 ,ד"ר אבו שדיד אברהם
7400 ,נתניה ,191 ,ד"ר אברהם בר מנחם
7400 ,נתניה ,885 ,ד"ר דב יוסף
7400 ,נתניה ,928 ,ד"ר יוסף בורג
7400 ,נתניה ,884 ,ד"ר משה זמורה
7400 ,נתניה ,1008 ,ד"ר פרח יהודה
7400 ,נתניה ,457 ,ד"ר שרייבר
7400 ,נתניה ,245 ,ד"רבן עמר חיים
7400 ,נתניה ,934 ,דב שילנסקי
7400 ,נתניה ,111 ,דבורה
7400 ,נתניה ,584 ,דבורה פוגל
7400 ,נתניה ,205 ,דברי חיים
7400 ,נתניה ,809 ,דובנוב
7400 ,נתניה ,929 ,דוד אבידן
7400 ,נתניה ,405 ,דוד המלך
7400 ,נתניה ,360 ,דודו דותן
7400 ,נתניה ,948 ,דווייט אייזנהאואר
7400 ,נתניה ,834 ,דוכיפת
7400 ,נתניה ,154 ,דולפין
7400 ,נתניה ,606 ,דון יוסף הנשיא
7400 ,נתניה ,637 ,דונה גרציה
7400 ,נתניה ,6581 ,דורה מ סוכנות
7400 ,נתניה ,6595 ,דורה צריפים
7400 ,נתניה ,1112 ,דורי יעקב
7400 ,נתניה ,406 ,דיזנגוף
7400 ,נתניה ,151 ,דייויד איימס
7400 ,נתניה ,828 ,דיין שמואל
7400 ,נתניה ,880 ,דימיטר פשב
7400 ,נתניה ,355 ,דמארי שושנה
7400 ,נתניה ,1219 ,דן
7400 ,נתניה ,904 ,דניאל
7400 ,נתניה ,685 ,דניס גינסברג
7400 ,נתניה ,279 ,דנמרק
7400 ,נתניה ,538 ,דנקנר
7400 ,נתניה ,188 ,דרור שלמה
7400 ,נתניה ,153 ,דרך אריאל שרון
7400 ,נתניה ,847 ,דרך דגניה
7400 ,נתניה ,1037 ,דרך היהלומים
7400 ,נתניה ,112 ,דרך הירדן
7400 ,נתניה ,826 ,דרך הפארק
7400 ,נתניה ,615 ,דרך הרכבת
7400 ,נתניה ,655 ,ה-93
7400 ,נתניה ,443 ,האדמור מויז'ניץ
7400 ,נתניה ,1016 ,האומנות
7400 ,נתניה ,877 ,האוניברסיטה
7400 ,נתניה ,708 ,האורג
7400 ,נתניה ,843 ,האורזים
7400 ,נתניה ,1246 ,האורן
7400 ,נתניה ,561 ,האחוה
7400 ,נתניה ,909 ,האחים רייט
7400 ,נתניה ,967 ,האיריסים
7400 ,נתניה ,1247 ,האלה
7400 ,נתניה ,1240 ,האלונים
7400 ,נתניה ,232 ,האניה ארינפורה
7400 ,נתניה ,1038 ,האצטדיון
7400 ,נתניה ,607 ,האר"י
7400 ,נתניה ,1207 ,הארבל
7400 ,נתניה ,1243 ,הארז
7400 ,נתניה ,932 ,הארי טרומן
7400 ,נתניה ,246 ,האתרוג
7400 ,נתניה ,1017 ,הבונים
7400 ,נתניה ,206 ,הביכורים
7400 ,נתניה ,853 ,הברבור
7400 ,נתניה ,1115 ,הבריגדה היהודית
7400 ,נתניה ,1206 ,הבשן
7400 ,נתניה ,652 ,הגאולה
7400 ,נתניה ,1024 ,הגביש
7400 ,נתניה ,207 ,הגדוד העברי
7400 ,נתניה ,836 ,הגוזל
7400 ,נתניה ,1200 ,הגולן
7400 ,נתניה ,563 ,הגילה
7400 ,נתניה ,509 ,הגלבוע
7400 ,נתניה ,510 ,הגליל
7400 ,נתניה ,1204 ,הגלעד
7400 ,נתניה ,208 ,הגפן
7400 ,נתניה ,812 ,הגר ברוך
7400 ,נתניה ,608 ,הגר"א
7400 ,נתניה ,1064 ,הדודאים
7400 ,נתניה ,564 ,הדיצה
7400 ,נתניה ,984 ,הדליות
7400 ,נתניה ,269 ,הדף היומי
7400 ,נתניה ,1248 ,הדקל
7400 ,נתניה ,209 ,הדר
7400 ,נתניה ,835 ,הדרור
7400 ,נתניה ,312 ,ההגנה
7400 ,נתניה ,986 ,ההדס
7400 ,נתניה ,540 ,ההסתדרות
7400 ,נתניה ,985 ,ההרדוף
7400 ,נתניה ,876 ,ההשכלה
7400 ,נתניה ,122 ,הוז דב
7400 ,נתניה ,210 ,הויסמנס קמיל
7400 ,נתניה ,262 ,הולנד
7400 ,נתניה ,990 ,הורדים
7400 ,נתניה ,1092 ,הזוהר
7400 ,נתניה ,1155 ,הזז חיים
7400 ,נתניה ,211 ,הזית
7400 ,נתניה ,832 ,הזמיר
7400 ,נתניה ,566 ,החדווה
7400 ,נתניה ,261 ,החוזה מלובלין
7400 ,נתניה ,212 ,החיטה
7400 ,נתניה ,878 ,החינוך העברי
7400 ,נתניה ,200 ,החלוץ הלוחם
7400 ,נתניה ,851 ,החסידה
7400 ,נתניה ,1116 ,החפץ חיים
7400 ,נתניה ,987 ,החצבים
7400 ,נתניה ,213 ,החרוב
7400 ,נתניה ,1202 ,החרמון
7400 ,נתניה ,313 ,החשמונאים
7400 ,נתניה ,1044 ,החשמל
7400 ,נתניה ,610 ,הטורים
7400 ,נתניה ,289 ,היאור
7400 ,נתניה ,562 ,הידידות
7400 ,נתניה ,408 ,היהלום
7400 ,נתניה ,501 ,היהלומן אברהמס
7400 ,נתניה ,989 ,היסמין
7400 ,נתניה ,1019 ,היצירה
7400 ,נתניה ,341 ,הכובשים
7400 ,נתניה ,214 ,הכלנית
7400 ,נתניה ,539 ,הכרמל
7400 ,נתניה ,622 ,הל"ה
7400 ,נתניה ,113 ,הלבנון
7400 ,נתניה ,991 ,הלילך
7400 ,נתניה ,534 ,הלפרין ירמיהו
7400 ,נתניה ,115 ,המגיד
7400 ,נתניה ,1026 ,המדע
7400 ,נתניה ,672 ,המהר"ל מפראג
7400 ,נתניה ,1242 ,המורן
7400 ,נתניה ,1027 ,המחקר
7400 ,נתניה ,1025 ,המחשב
7400 ,נתניה ,409 ,המייסדים
7400 ,נתניה ,926 ,המכביה
7400 ,נתניה ,1018 ,המלאכה
7400 ,נתניה ,116 ,המליץ
7400 ,נתניה ,156 ,המלכה אלנה
7400 ,נתניה ,253 ,המלכים
7400 ,נתניה ,844 ,המסגר
7400 ,נתניה ,410 ,המעפילים
7400 ,נתניה ,964 ,המרגניות
7400 ,נתניה ,347 ,המרד
7400 ,נתניה ,711 ,המרכבה
7400 ,נתניה ,411 ,המתמיד
7400 ,נתניה ,906 ,הנביאים
7400 ,נתניה ,511 ,הנגב
7400 ,נתניה ,845 ,הנגר
7400 ,נתניה ,412 ,הנוטע
7400 ,נתניה ,988 ,הנורית
7400 ,נתניה ,1043 ,הנרי קיסנג'ר
7400 ,נתניה ,215 ,הנרקיס
7400 ,נתניה ,280 ,הנשיא
7400 ,נתניה ,849 ,הנשר
7400 ,נתניה ,413 ,הס משה
7400 ,נתניה ,1063 ,הסחלב
7400 ,נתניה ,634 ,הספורטאי
7400 ,נתניה ,216 ,העומר
7400 ,נתניה ,710 ,העמל
7400 ,נתניה ,1249 ,הענבל
7400 ,נתניה ,833 ,הפועל הצעיר
7400 ,נתניה ,612 ,הפורצים
7400 ,נתניה ,837 ,הפלדה
7400 ,נתניה ,1072 ,הפרגים
7400 ,נתניה ,218 ,הפרדס
7400 ,נתניה ,290 ,הצור והגל
7400 ,נתניה ,1028 ,הצורן
7400 ,נתניה ,613 ,הצנחנים
7400 ,נתניה ,117 ,הצפירה
7400 ,נתניה ,1093 ,הקבלה
7400 ,נתניה ,292 ,הקברניט
7400 ,נתניה ,842 ,הקדר
7400 ,נתניה ,1051 ,הקונגרס הציוני
7400 ,נתניה ,614 ,הקליר
7400 ,נתניה ,1203 ,הר מירון
7400 ,נתניה ,541 ,הר ציון
7400 ,נתניה ,1205 ,הר שגיא
7400 ,נתניה ,937 ,הרב אבו חצירה
7400 ,נתניה ,545 ,הרב אהרונסון
7400 ,נתניה ,864 ,הרב אונטרמן יהודה
7400 ,נתניה ,1236 ,הרב איפרגן שלום
7400 ,נתניה ,663 ,הרב אליהו מרדכי
7400 ,נתניה ,547 ,הרב ברוד
7400 ,נתניה ,807 ,הרב גולד
7400 ,נתניה ,260 ,הרב הלברשטם ברוך
7400 ,נתניה ,865 ,הרב הרטום שמואל
7400 ,נתניה ,824 ,הרב זוארץ פריגיה
7400 ,נתניה ,514 ,הרב טביב
7400 ,נתניה ,860 ,הרב טולדנו
7400 ,נתניה ,266 ,הרב טייב חי
7400 ,נתניה ,1208 ,הרב יאנה
7400 ,נתניה ,945 ,הרב יוסף אלישיב
7400 ,נתניה ,148 ,הרב יוסף וברכה קאפח
7400 ,נתניה ,919 ,הרב יצחק הלוי
7400 ,נתניה ,271 ,הרב מגרליץ
7400 ,נתניה ,1235 ,הרב משה צבי נריה
7400 ,נתניה ,662 ,הרב נסים יצחק
7400 ,נתניה ,892 ,הרב עובדיה יוסף
7400 ,נתניה ,544 ,הרב עמיאל
7400 ,נתניה ,548 ,הרב פרידר אברהם
7400 ,נתניה ,330 ,הרב פרנקל ידידיה
7400 ,נתניה ,323 ,הרב קוק
7400 ,נתניה ,137 ,הרב קלישר
7400 ,נתניה ,546 ,הרב ראט משולם
7400 ,נתניה ,138 ,הרב ריינס
7400 ,נתניה ,933 ,הרב רפאל
7400 ,נתניה ,709 ,הרב רקח יעקב
7400 ,נתניה ,525 ,הרב שמעון גבאי
7400 ,נתניה ,332 ,הרופאה רבקה
7400 ,נתניה ,217 ,הרימון
7400 ,נתניה ,565 ,הרינה
7400 ,נתניה ,838 ,הרכב
7400 ,נתניה ,242 ,הרמ"א
7400 ,נתניה ,560 ,הרעות
7400 ,נתניה ,250 ,הרצוג
7400 ,נתניה ,418 ,הרצל
7400 ,נתניה ,221 ,הרקפת
7400 ,נתניה ,415 ,השבעה
7400 ,נתניה ,814 ,השומר
7400 ,נתניה ,119 ,השופטים
7400 ,נתניה ,222 ,השושנה
7400 ,נתניה ,850 ,השחף
7400 ,נתניה ,285 ,השחר
7400 ,נתניה ,957 ,השיאים
7400 ,נתניה ,1241 ,השיזף
7400 ,נתניה ,118 ,השילוח
7400 ,נתניה ,839 ,השיש
7400 ,נתניה ,671 ,השלה הקדוש
7400 ,נתניה ,223 ,השעורה
7400 ,נתניה ,219 ,השקד
7400 ,נתניה ,220 ,השקמה
7400 ,נתניה ,224 ,התאנה
7400 ,נתניה ,1091 ,התהילה
7400 ,נתניה ,852 ,התור
7400 ,נתניה ,1251 ,התלתן
7400 ,נתניה ,225 ,התמר
7400 ,נתניה ,175 ,התנאים
7400 ,נתניה ,252 ,התנועה הציונית
7400 ,נתניה ,121 ,התקומה
7400 ,נתניה ,120 ,התקופה
7400 ,נתניה ,1022 ,התרופה
7400 ,נתניה ,1154 ,וולך יונה
7400 ,נתניה ,123 ,וולפסון
7400 ,נתניה ,278 ,וילפריד ישראל
7400 ,נתניה ,579 ,וינסנט ואן גוך
7400 ,נתניה ,1023 ,וינר נחמה
7400 ,נתניה ,616 ,ויס יעקב
7400 ,נתניה ,650 ,ויסבורג
7400 ,נתניה ,942 ,ויסלברגר
7400 ,נתניה ,815 ,ויתקין
7400 ,נתניה ,1133 ,ולוס
7400 ,נתניה ,1004 ,ועידת הלסינקי
7400 ,נתניה ,879 ,וריאן פריי
7400 ,נתניה ,512 ,ז'בוטינסקי
7400 ,נתניה ,226 ,ז'ילבר
7400 ,נתניה ,1215 ,זבולון
7400 ,נתניה ,1073 ,זהבית
7400 ,נתניה ,256 ,זומרשטיין
7400 ,נתניה ,897 ,זיגמונד פרויד
7400 ,נתניה ,816 ,זייד אלכסנדר
7400 ,נתניה ,817 ,זמבה
7400 ,נתניה ,549 ,זמנהוף
7400 ,נתניה ,283 ,זמר נעורים
7400 ,נתניה ,314 ,זנגביל
7400 ,נתניה ,264 ,חב"ד
7400 ,נתניה ,1104 ,חבצלת החוף
7400 ,נתניה ,908 ,חבקוק
7400 ,נתניה ,907 ,חולדה
7400 ,נתניה ,6590 ,חוף גולדמינץ
7400 ,נתניה ,705 ,חורגין
7400 ,נתניה ,353 ,חזה עופרה
7400 ,נתניה ,970 ,חזני
7400 ,נתניה ,1033 ,חטיבת אלכסנדרוני
7400 ,נתניה ,1032 ,חטיבת גבעתי
7400 ,נתניה ,1030 ,חטיבת הראל
7400 ,נתניה ,1031 ,חטיבת קריתי
7400 ,נתניה ,439 ,חיבת ציון
7400 ,נתניה ,586 ,חיה בם
7400 ,נתניה ,182 ,חיות צבי פרץ
7400 ,נתניה ,350 ,חיטמן עוזי
7400 ,נתניה ,888 ,חיים צדוק
7400 ,נתניה ,552 ,חייקה גרוסמן
7400 ,נתניה ,1066 ,חלמונית
7400 ,נתניה ,365 ,חנה מרון
7400 ,נתניה ,617 ,חרל"פ
7400 ,נתניה ,1102 ,חרצית
7400 ,נתניה ,1222 ,חשון
7400 ,נתניה ,618 ,חת"ם סופר
7400 ,נתניה ,1224 ,טבת
7400 ,נתניה ,254 ,טהון יהושע
7400 ,נתניה ,619 ,טיומקין
7400 ,נתניה ,286 ,טרובדור
7400 ,נתניה ,315 ,טרומפלדור
7400 ,נתניה ,515 ,טשרניחובסקי
7400 ,נתניה ,516 ,יאיר
7400 ,נתניה ,366 ,יאיר רוזנבלום
7400 ,נתניה ,559 ,יאנוש בן גל
7400 ,נתניה ,570 ,יגאל אלון
7400 ,נתניה ,368 ,יגאל בשן
7400 ,נתניה ,963 ,יד חרוצים
7400 ,נתניה ,1213 ,יהודה
7400 ,נתניה ,517 ,יהודה הלוי
7400 ,נתניה ,316 ,יהודה הנשיא
7400 ,נתניה ,733 ,יהודה עמיחי
7400 ,נתניה ,125 ,יהושע בן נון
7400 ,נתניה ,276 ,יהושע קנז
7400 ,נתניה ,126 ,יואב
7400 ,נתניה ,518 ,יודפת
7400 ,נתניה ,910 ,יוהאן גוטנברג
7400 ,נתניה ,881 ,יוהאן ואן הולסט
7400 ,נתניה ,886 ,יוחנן בדר
7400 ,נתניה ,176 ,יוחנן בן זכאי
7400 ,נתניה ,173 ,יוחנן הסנדלר
7400 ,נתניה ,911 ,יונה
7400 ,נתניה ,954 ,יונה בוגולה
7400 ,נתניה ,620 ,יוסי בן יוסי
7400 ,נתניה ,1220 ,יוסף
7400 ,נתניה ,142 ,יוסף לפיד
7400 ,נתניה ,858 ,יוסף סמיה
7400 ,נתניה ,277 ,יוסף רוט
7400 ,נתניה ,927 ,יוסף שרטוב
7400 ,נתניה ,165 ,יוספטל
7400 ,נתניה ,1134 ,יורדי הים
7400 ,נתניה ,127 ,יותם
7400 ,נתניה ,931 ,יחזקאל
7400 ,נתניה ,551 ,יחיא הלוי
7400 ,נתניה ,339 ,יל"ג
7400 ,נתניה ,128 ,ילין
7400 ,נתניה ,197 ,יסעור
7400 ,נתניה ,129 ,יעב"ץ
7400 ,נתניה ,872 ,יעל אשת חבר הקיני
7400 ,נתניה ,935 ,יעקב אליאב
7400 ,נתניה ,1058 ,יעקב חזן
7400 ,נתניה ,676 ,יעקב מרידור
7400 ,נתניה ,363 ,יפה ירקוני
7400 ,נתניה ,193 ,יפעת
7400 ,נתניה ,131 ,יפתח הגלעדי
7400 ,נתניה ,714 ,יצחק (איזי) דורות
7400 ,נתניה ,177 ,יצחק הנפח
7400 ,נתניה ,799 ,יצחק חופי
7400 ,נתניה ,692 ,יצחק חסון
7400 ,נתניה ,1074 ,יקינתון
7400 ,נתניה ,912 ,ירמיהו
7400 ,נתניה ,913 ,ישעיהו
7400 ,נתניה ,1156 ,ישעיהו (סי) קינן
7400 ,נתניה ,691 ,ישעיהו ריקלין
7400 ,נתניה ,673 ,ישראל אלדד
7400 ,נתניה ,1214 ,יששכר
7400 ,נתניה ,861 ,כהן דוד הנזיר
7400 ,נתניה ,333 ,כוכב הצפון
7400 ,נתניה ,577 ,כוכבי השביט
7400 ,נתניה ,6010 ,ככר אמני ישראל
7400 ,נתניה ,6006 ,ככר הבנים
7400 ,נתניה ,6001 ,ככר העצמאות
7400 ,נתניה ,6002 ,ככר טבועי מצדה
7400 ,נתניה ,6007 ,ככר מטלון
7400 ,נתניה ,6003 ,ככר ציון
7400 ,נתניה ,6005 ,ככר רוטרי
7400 ,נתניה ,6004 ,ככר שתיים
7400 ,נתניה ,1223 ,כסלו
7400 ,נתניה ,936 ,כפר אוריה
7400 ,נתניה ,638 ,כפר עידוד
7400 ,נתניה ,998 ,כפר תבור
7400 ,נתניה ,1056 ,לאון אדרי
7400 ,נתניה ,993 ,לבון פנחס
7400 ,נתניה ,519 ,לבונטין
7400 ,נתניה ,163 ,לבנון חיים
7400 ,נתניה ,1002 ,לובטקין צביה
7400 ,נתניה ,268 ,לוחמי גליפולי
7400 ,נתניה ,1060 ,לוטם
7400 ,נתניה ,1212 ,לוי
7400 ,נתניה ,999 ,לוי אשכול
7400 ,נתניה ,920 ,לוי שושן
7400 ,נתניה ,965 ,לוין אריה
7400 ,נתניה ,157 ,לויתן
7400 ,נתניה ,641 ,לח"י
7400 ,נתניה ,654 ,ליבסקינד
7400 ,נתניה ,1234 ,ליבר זאב
7400 ,נתניה ,578 ,לידיה ליטביאק
7400 ,נתניה ,420 ,לילינבלום
7400 ,נתניה ,1047 ,לינדון ג'ונסון
7400 ,נתניה ,166 ,לישנסקי
7400 ,נתניה ,830 ,לנדאו שמואל
7400 ,נתניה ,1053 ,לסקוב חיים
7400 ,נתניה ,1000 ,מאיר אריאל
7400 ,נתניה ,557 ,מאיר דגן
7400 ,נתניה ,889 ,מאיר זורע
7400 ,נתניה ,543 ,מאיר יערי
7400 ,נתניה ,421 ,מאירוביץ
7400 ,נתניה ,972 ,מאנה
7400 ,נתניה ,683 ,מארי קירי
7400 ,נתניה ,730 ,מארק שאגל
7400 ,נתניה ,345 ,מבוא פורר שלום
7400 ,נתניה ,141 ,מגורי חיים כהן
7400 ,נתניה ,288 ,מגש הכסף
7400 ,נתניה ,682 ,מדע האביב
7400 ,נתניה ,1120 ,מודעי יצחק
7400 ,נתניה ,317 ,מוהליבר
7400 ,נתניה ,228 ,מול עמנואל
7400 ,נתניה ,818 ,מונטיפיורי
7400 ,נתניה ,701 ,מוסד בית תינה
7400 ,נתניה ,1151 ,מוסינזון יגאל
7400 ,נתניה ,959 ,מוצקין
7400 ,נתניה ,623 ,מורדי הגטאות
7400 ,נתניה ,230 ,מוריה
7400 ,נתניה ,373 ,מורין פורסטר
7400 ,נתניה ,192 ,מזא"ה
7400 ,נתניה ,6582 ,מחנה יעקב
7400 ,נתניה ,6520 ,מחנה עבודה
7400 ,נתניה ,164 ,מטולה
7400 ,נתניה ,198 ,מטרופוליס
7400 ,נתניה ,979 ,מיכ"ל
7400 ,נתניה ,813 ,מיכאל
7400 ,נתניה ,258 ,מימון
7400 ,נתניה ,169 ,מינץ
7400 ,נתניה ,249 ,מישל באקוס
7400 ,נתניה ,952 ,מכון וינגייט
7400 ,נתניה ,829 ,מכלוף גד
7400 ,נתניה ,422 ,מכנס גד
7400 ,נתניה ,916 ,מלאכי
7400 ,נתניה ,917 ,מלבן דורה
7400 ,נתניה ,436 ,מלון הנסיכה
7400 ,נתניה ,537 ,מלון כרמל
7400 ,נתניה ,426 ,מלון פארק
7400 ,נתניה ,344 ,מלחמת ששת הימים
7400 ,נתניה ,819 ,מלכין שרה
7400 ,נתניה ,587 ,מלכת שבא
7400 ,נתניה ,944 ,מנדלי מוכר ספרים
7400 ,נתניה ,183 ,מנדלסון
7400 ,נתניה ,352 ,מנור אהוד
7400 ,נתניה ,1029 ,מנלה גד
7400 ,נתניה ,520 ,מסדה
7400 ,נתניה ,918 ,מסילות
7400 ,נתניה ,233 ,מסריק
7400 ,נתניה ,273 ,מסרק
7400 ,נתניה ,6536 ,מע"ר חדש
7400 ,נתניה ,535 ,מעלה רחבעם זאבי
7400 ,נתניה ,251 ,מעלות אילת
7400 ,נתניה ,448 ,מעלות הרב וורנר
7400 ,נתניה ,320 ,מעלות הרב לאו
7400 ,נתניה ,1062 ,מעפילי אגוז
7400 ,נתניה ,1014 ,מפי
7400 ,נתניה ,423 ,מקדונלד
7400 ,נתניה ,1113 ,מקלף מרדכי
7400 ,נתניה ,281 ,מראת הפלאים
7400 ,נתניה ,573 ,מרדכי גרינטל
7400 ,נתניה ,377 ,מריה קאלאס
7400 ,נתניה ,452 ,מריה קוטרבה
7400 ,נתניה ,921 ,מרים
7400 ,נתניה ,800 ,מרסל ניניו בוגר
7400 ,נתניה ,820 ,מרק אברהם
7400 ,נתניה ,291 ,מרש הדייגים
7400 ,נתניה ,1090 ,מרשל פייר קניג
7400 ,נתניה ,863 ,משה הלוי לוין
7400 ,נתניה ,364 ,משה וילנסקי
7400 ,נתניה ,941 ,משה לב-עמי
7400 ,נתניה ,453 ,משה קראוס
7400 ,נתניה ,677 ,משה רוזנברג
7400 ,נתניה ,6508 ,משכנות זבולון המר
7400 ,נתניה ,130 ,משמר הירדן
7400 ,נתניה ,1011 ,משעול קיפניס
7400 ,נתניה ,362 ,משעול תמר
7400 ,נתניה ,359 ,משפחת מלבסקי
7400 ,נתניה ,342 ,מתתיהו הכהן
7400 ,נתניה ,6589 ,נאות בגין
7400 ,נתניה ,6500 ,נאות גנים
7400 ,נתניה ,241 ,נאות התכלת
7400 ,נתניה ,6511 ,נאות פנחס רוזן
7400 ,נתניה ,6513 ,נווה אבא
7400 ,נתניה ,6591 ,נווה עוז
7400 ,נתניה ,243 ,נווה שלום
7400 ,נתניה ,821 ,נויפלד
7400 ,נתניה ,335 ,נועה
7400 ,נתניה ,6518 ,נוף חפר
7400 ,נתניה ,1250 ,נופר
7400 ,נתניה ,938 ,נורדאו
7400 ,נתניה ,259 ,נורוק
7400 ,נתניה ,922 ,נחום
7400 ,נתניה ,887 ,נחום ניר רפאלקס
7400 ,נתניה ,856 ,נחל עוז
7400 ,נתניה ,575 ,נחמה ליפשיץ
7400 ,נתניה ,923 ,נחמיה
7400 ,נתניה ,624 ,ניל"י
7400 ,נתניה ,376 ,נינה סימון
7400 ,נתניה ,170 ,ניניו
7400 ,נתניה ,1227 ,ניסן
7400 ,נתניה ,132 ,ניסנבוים
7400 ,נתניה ,684 ,נלי זק"ש
7400 ,נתניה ,195 ,נעמה
7400 ,נתניה ,1218 ,נפתלי
7400 ,נתניה ,1061 ,נר הלילה
7400 ,נתניה ,811 ,נרי יצחק
7400 ,נתניה ,982 ,נתן אלתרמן המשורר
7400 ,נתניה ,357 ,נתן יונתן
7400 ,נתניה ,675 ,נתן ילין מור
7400 ,נתניה ,9000 ,נתניה
7400 ,נתניה ,731 ,ס. יזהר
7400 ,נתניה ,1052 ,סבידור מנחם
7400 ,נתניה ,1070 ,סביון
7400 ,נתניה ,1007 ,סולד הנריאטה
7400 ,נתניה ,369 ,סופיה לורן
7400 ,נתניה ,235 ,סוקולוב
7400 ,נתניה ,961 ,סטופ
7400 ,נתניה ,625 ,סטרומה
7400 ,נתניה ,1069 ,סיגלית
7400 ,נתניה ,1229 ,סיוון
7400 ,נתניה ,571 ,סיימון וייל
7400 ,נתניה ,248 ,סילברשטיין צבי
7400 ,נתניה ,299 ,סימני דרך
7400 ,נתניה ,924 ,סיני
7400 ,נתניה ,822 ,סירקין
7400 ,נתניה ,582 ,סלבדור דאלי
7400 ,נתניה ,331 ,סלומון יואל
7400 ,נתניה ,416 ,סמ סטודיו
7400 ,נתניה ,636 ,סמ עולי סידקוב
7400 ,נתניה ,866 ,סמ פיקסלר
7400 ,נתניה ,521 ,סמילנסקי
7400 ,נתניה ,1057 ,סנה משה
7400 ,נתניה ,626 ,סנש חנה
7400 ,נתניה ,236 ,סעדיה גאון
7400 ,נתניה ,424 ,ספיר יוסף
7400 ,נתניה ,869 ,סר ניקולס וינטון
7400 ,נתניה ,973 ,עגנון
7400 ,נתניה ,823 ,עובדיה אברהם הכהן
7400 ,נתניה ,203 ,עובדיה מברטנורא
7400 ,נתניה ,133 ,עולי הגרדום
7400 ,נתניה ,1081 ,עולש מצוי
7400 ,נתניה ,925 ,עזרא
7400 ,נתניה ,361 ,עטרי שוש
7400 ,נתניה ,1067 ,עירית
7400 ,נתניה ,681 ,עלי גנים
7400 ,נתניה ,300 ,עלי שלכת
7400 ,נתניה ,955 ,עליה
7400 ,נתניה ,715 ,עמוס מנור
7400 ,נתניה ,674 ,עמוס קינן
7400 ,נתניה ,668 ,עמית מאיר
7400 ,נתניה ,135 ,עמק חפר
7400 ,נתניה ,348 ,עמר ג'ו
7400 ,נתניה ,319 ,עציון
7400 ,נתניה ,569 ,עקבא סודק
7400 ,נתניה ,275 ,פאוול פרנקל
7400 ,נתניה ,6507 ,פארק האלונים
7400 ,נתניה ,6535 ,פארק שיאים
7400 ,נתניה ,354 ,פוליאקוב ישראל
7400 ,נתניה ,882 ,פייר אנייר
7400 ,נתניה ,179 ,פיירברג
7400 ,נתניה ,542 ,פיכמן
7400 ,נתניה ,134 ,פינס
7400 ,נתניה ,425 ,פינסקר
7400 ,נתניה ,583 ,פיקאסו
7400 ,נתניה ,1034 ,פלוגת מכבי
7400 ,נתניה ,321 ,פלמ"ח
7400 ,נתניה ,146 ,פנחס רוזן
7400 ,נתניה ,1131 ,פנצ'ו
7400 ,נתניה ,702 ,פנקס דוד
7400 ,נתניה ,296 ,פעמון הזכוכית
7400 ,נתניה ,1075 ,פעמונית
7400 ,נתניה ,678 ,פעמי אביב
7400 ,נתניה ,270 ,פרדי
7400 ,נתניה ,172 ,פרוג שמעון
7400 ,נתניה ,322 ,פרומקין
7400 ,נתניה ,186 ,פרופ' שור
7400 ,נתניה ,971 ,פרופס אהרון צבי
7400 ,נתניה ,680 ,פרח הלוטוס
7400 ,נתניה ,679 ,פרחי זהב
7400 ,נתניה ,318 ,פרידר עמנואל
7400 ,נתניה ,174 ,פרישמן
7400 ,נתניה ,712 ,פרנץ אופנהיימר
7400 ,נתניה ,883 ,פרנק פולר
7400 ,נתניה ,940 ,פרץ
7400 ,נתניה ,627 ,פתח תקוה
7400 ,נתניה ,143 ,צ'יאונה סוגיהארה
7400 ,נתניה ,257 ,צ'רצ'יל
7400 ,נתניה ,1245 ,צאלון
7400 ,נתניה ,905 ,צבי מויססקו
7400 ,נתניה ,1065 ,צבעוני
7400 ,נתניה ,567 ,צהלה
7400 ,נתניה ,667 ,צור צבי
7400 ,נתניה ,114 ,ציטלין הלל
7400 ,נתניה ,1076 ,ציפורן
7400 ,נתניה ,841 ,ציר טנצר
7400 ,נתניה ,825 ,צירלסון
7400 ,נתניה ,550 ,קאפח יחיא
7400 ,נתניה ,160 ,קדושי בלזן
7400 ,נתניה ,951 ,קדושי עירק
7400 ,נתניה ,915 ,קדיש לוז
7400 ,נתניה ,145 ,קדמה
7400 ,נתניה ,338 ,קדש
7400 ,נתניה ,1094 ,קהילת צפת
7400 ,נתניה ,1021 ,קומוי נתן
7400 ,נתניה ,980 ,קורצ'ק יאנוש
7400 ,נתניה ,324 ,קיש
7400 ,נתניה ,873 ,קלוזנר יוסף
7400 ,נתניה ,263 ,קנדי
7400 ,נתניה ,640 ,קניון השרון
7400 ,נתניה ,827 ,קפלן
7400 ,נתניה ,325 ,קפלנסקי
7400 ,נתניה ,854 ,קפקא
7400 ,נתניה ,943 ,קצנלסון יצחק
7400 ,נתניה ,438 ,קק"ל
7400 ,נתניה ,437 ,קראוזה
7400 ,נתניה ,992 ,קריניצי
7400 ,נתניה ,6510 ,קרית אליעזר קפלן
7400 ,נתניה ,6506 ,קרית השרון
7400 ,נתניה ,6505 ,קרית יהלום
7400 ,נתניה ,6504 ,קרית יצחק רבין
7400 ,נתניה ,6522 ,קרית צאנז
7400 ,נתניה ,874 ,קרל לוץ
7400 ,נתניה ,898 ,קרל פופר
7400 ,נתניה ,427 ,קרליבך
7400 ,נתניה ,189 ,קרמניאן משה
7400 ,נתניה ,977 ,קרן היסוד
7400 ,נתניה ,1210 ,ראובן
7400 ,נתניה ,531 ,ראול ולנברג
7400 ,נתניה ,524 ,ראש פנה
7400 ,נתניה ,428 ,ראשון לציון
7400 ,נתניה ,227 ,רבי טרפון
7400 ,נתניה ,229 ,רבי מאיר
7400 ,נתניה ,238 ,רבי עקיבא
7400 ,נתניה ,136 ,רבן גמליאל
7400 ,נתניה ,1040 ,רגב אריה
7400 ,נתניה ,629 ,רד"ק
7400 ,נתניה ,734 ,רוברט סטיבנסון
7400 ,נתניה ,687 ,רוזלין זוסמן
7400 ,נתניה ,949 ,רוטנברג
7400 ,נתניה ,1045 ,רונלד רייגן
7400 ,נתניה ,966 ,רופין ארתור
7400 ,נתניה ,576 ,רות ביידר גינסבורג
7400 ,נתניה ,871 ,רות המואביה
7400 ,נתניה ,1103 ,רותם
7400 ,נתניה ,139 ,רזיאל
7400 ,נתניה ,1110 ,רזניק דוד
7400 ,נתניה ,450 ,רחבת כצנלסון ראובן
7400 ,נתניה ,1006 ,רחבת מרואני מכלוף
7400 ,נתניה ,152 ,רחה פריאר
7400 ,נתניה ,588 ,רחל ברכות
7400 ,נתניה ,343 ,רחל המשוררת
7400 ,נתניה ,798 ,רחל פרנקלין
7400 ,נתניה ,585 ,רחל קורן
7400 ,נתניה ,326 ,ריבלין
7400 ,נתניה ,693 ,ריטה לוי מונטלצ'יני
7400 ,נתניה ,1232 ,רייך בני
7400 ,נתניה ,255 ,רייך לאון
7400 ,נתניה ,630 ,רייק חביבה
7400 ,נתניה ,713 ,רלף קליין
7400 ,נתניה ,530 ,רם ברוך
7400 ,נתניה ,631 ,רמב"ם
7400 ,נתניה ,632 ,רמב"ן
7400 ,נתניה ,554 ,רמבה אייזיק
7400 ,נתניה ,580 ,רמברנדט
7400 ,נתניה ,429 ,רמז דוד
7400 ,נתניה ,445 ,רמת הגולן
7400 ,נתניה ,6514 ,רמת זאב
7400 ,נתניה ,6596 ,רמת ידין
7400 ,נתניה ,6584 ,רמת נורדאו
7400 ,נתניה ,6585 ,רמת פולג
7400 ,נתניה ,658 ,רפאל איתן
7400 ,נתניה ,6515 ,רצועת החוף והמלונות
7400 ,נתניה ,633 ,רש"י
7400 ,נתניה ,6516 ,ש גן ברכה
7400 ,נתניה ,6597 ,ש דורה יבנה
7400 ,נתניה ,6598 ,ש דורה צפון
7400 ,נתניה ,6599 ,ש דורה קבלנים
7400 ,נתניה ,6586 ,ש דיור לעולה
7400 ,נתניה ,6523 ,ש המרכז
7400 ,נתניה ,6524 ,ש הפומז
7400 ,נתניה ,6525 ,ש הפועלים
7400 ,נתניה ,6574 ,ש טוברוק
7400 ,נתניה ,6501 ,ש מחנה מעבר
7400 ,נתניה ,6529 ,ש מכנס
7400 ,נתניה ,6611 ,ש סלע
7400 ,נתניה ,6530 ,ש עין התכלת
7400 ,נתניה ,6612 ,ש עמידר
7400 ,נתניה ,6576 ,ש עמידר פתוח
7400 ,נתניה ,6614 ,ש צאל
7400 ,נתניה ,6615 ,ש ציונים כלליים
7400 ,נתניה ,6616 ,ש רמת הרצל
7400 ,נתניה ,6531 ,ש רסקו
7400 ,נתניה ,6617 ,ש שמשון
7400 ,נתניה ,555 ,ש"ץ גרשון
7400 ,נתניה ,430 ,שאול המלך
7400 ,נתניה ,526 ,שבזי
7400 ,נתניה ,648 ,שבח
7400 ,נתניה ,1225 ,שבט
7400 ,נתניה ,1209 ,שבטי ישראל
7400 ,נתניה ,703 ,שד אליעזר
7400 ,נתניה ,504 ,שד בן אב"י
7400 ,נתניה ,996 ,שד בן גוריון
7400 ,נתניה ,609 ,שד בן צבי
7400 ,נתניה ,532 ,שד בנימין
7400 ,נתניה ,1001 ,שד גולדה מאיר
7400 ,נתניה ,417 ,שד ויצמן
7400 ,נתניה ,513 ,שד ח"ן
7400 ,נתניה ,419 ,שד ירושלים
7400 ,נתניה ,1260 ,שד לנטוס טום
7400 ,נתניה ,442 ,שד ניצה
7400 ,נתניה ,1078 ,שד עובד בן עמי
7400 ,נתניה ,237 ,שד עין התכלת
7400 ,נתניה ,239 ,שד צרפת
7400 ,נתניה ,149 ,שד שמשון
7400 ,נתניה ,159 ,שדה יצחק
7400 ,נתניה ,649 ,שדרות אגם כנרת
7400 ,נתניה ,661 ,שדרת האגמים
7400 ,נתניה ,527 ,שוב דוד
7400 ,נתניה ,431 ,שוהם
7400 ,נתניה ,265 ,שוורצבארד שלום
7400 ,נתניה ,1050 ,שולמית
7400 ,נתניה ,657 ,שומרון דן
7400 ,נתניה ,301 ,שושנת הפלאים
7400 ,נתניה ,983 ,שז"ר זלמן
7400 ,נתניה ,161 ,שטייגר
7400 ,נתניה ,432 ,שטמפפר
7400 ,נתניה ,975 ,שטנד
7400 ,נתניה ,974 ,שטריקר
7400 ,נתניה ,287 ,שיח השדה
7400 ,נתניה ,950 ,שיטרית
7400 ,נתניה ,976 ,שיפר יעקב
7400 ,נתניה ,589 ,שירה גורשמן
7400 ,נתניה ,294 ,שירת הספנים
7400 ,נתניה ,6521 ,שכ אבו שדיד
7400 ,נתניה ,6618 ,שכ אום חאלד
7400 ,נתניה ,6580 ,שכ בן ציון
7400 ,נתניה ,6651 ,שכ גבע ב
7400 ,נתניה ,6528 ,שכ גלי הים
7400 ,נתניה ,6601 ,שכ האגם
7400 ,נתניה ,6575 ,שכ נאות גולדה
7400 ,נתניה ,6502 ,שכ נאות חפציבה
7400 ,נתניה ,6588 ,שכ נאות שקד
7400 ,נתניה ,6619 ,שכ נוה איתמר
7400 ,נתניה ,6532 ,שכ נוה דוד
7400 ,נתניה ,6577 ,שכ נווה שלום
7400 ,נתניה ,6652 ,שכ נוף
7400 ,נתניה ,6655 ,שכ נוף גלים
7400 ,נתניה ,6600 ,שכ עיר ימים
7400 ,נתניה ,6533 ,שכ פרדס הגדוד
7400 ,נתניה ,6653 ,שכ רמת אפרים
7400 ,נתניה ,6654 ,שכ רמת חן
7400 ,נתניה ,6517 ,שכונת המדע
7400 ,נתניה ,707 ,שכטרמן
7400 ,נתניה ,946 ,שלום עליכם
7400 ,נתניה ,968 ,שלונסקי
7400 ,נתניה ,240 ,שלמה המלך
7400 ,נתניה ,194 ,שלמונזה
7400 ,נתניה ,930 ,שמואל
7400 ,נתניה ,327 ,שמואל הנציב
7400 ,נתניה ,962 ,שמורק
7400 ,נתניה ,600 ,שמורת אגם החולה
7400 ,נתניה ,665 ,שמורת נחל בניאס
7400 ,נתניה ,647 ,שמורת נחל בשור
7400 ,נתניה ,664 ,שמורת נחל דולב
7400 ,נתניה ,666 ,שמורת נחל עמוד
7400 ,נתניה ,659 ,שמורת נחל ערוגות
7400 ,נתניה ,646 ,שמורת נחל שורק
7400 ,נתניה ,611 ,שמורת נחל שניר
7400 ,נתניה ,302 ,שמחוני אסף
7400 ,נתניה ,1211 ,שמעון
7400 ,נתניה ,868 ,שמעון ויזנטל
7400 ,נתניה ,969 ,שניאור זלמן
7400 ,נתניה ,645 ,שנירר שרה
7400 ,נתניה ,433 ,שער הגיא
7400 ,נתניה ,434 ,שער העמק
7400 ,נתניה ,558 ,שער עובד
7400 ,נתניה ,1012 ,שפוני גדעון
7400 ,נתניה ,875 ,שפיגלמן אברהם
7400 ,נתניה ,184 ,שפינוזה
7400 ,נתניה ,528 ,שפירא אברהם
7400 ,נתניה ,328 ,שפירא משה
7400 ,נתניה ,831 ,שפרינצק
7400 ,נתניה ,150 ,שרה בת טובים
7400 ,נתניה ,334 ,שרי זכריה
7400 ,נתניה ,653 ,שרעבי
7400 ,נתניה ,329 ,שרת
7400 ,נתניה ,293 ,שתיקת הים
7400 ,נתניה ,1253 ,תאשור
7400 ,נתניה ,1254 ,תדהר
7400 ,נתניה ,896 ,תומס אדיסון
7400 ,נתניה ,1077 ,תורמוס
7400 ,נתניה ,440 ,תל חי
7400 ,נתניה ,1230 ,תמוז
7400 ,נתניה ,1009 ,תנועת נוער ציוני
7400 ,נתניה ,447 ,תפארת בנים
7400 ,נתניה ,284 ,תריסר שערים
7400 ,נתניה ,367 ,תרצה אתר
7400 ,נתניה ,1221 ,תשרי
525 ,סאג'ור ,120 ,אבנאא יעקב
525 ,סאג'ור ,140 ,אל-ח'לה
525 ,סאג'ור ,135 ,אל-לורי
525 ,סאג'ור ,112 ,אלבאבור
525 ,סאג'ור ,104 ,אלביאדר
525 ,סאג'ור ,119 ,אלבלאטה
525 ,סאג'ור ,106 ,אלברכה
525 ,סאג'ור ,130 ,אלג'בל
525 ,סאג'ור ,138 ,אלג'מאל
525 ,סאג'ור ,133 ,אלג'רף
525 ,סאג'ור ,128 ,אלורדה
525 ,סאג'ור ,134 ,אלזיר
525 ,סאג'ור ,123 ,אלזיתון
525 ,סאג'ור ,101 ,אלזנאר
525 ,סאג'ור ,125 ,אלח'לוה
525 ,סאג'ור ,124 ,אלחואכיר
525 ,סאג'ור ,122 ,אלחמרה
525 ,סאג'ור ,102 ,אלטיון
525 ,סאג'ור ,111 ,אלטין
525 ,סאג'ור ,108 ,אלכרם אלעמיק
525 ,סאג'ור ,139 ,אלמזבלה
525 ,סאג'ור ,116 ,אלמקאבר
525 ,סאג'ור ,131 ,אלנס'ב
525 ,סאג'ור ,114 ,אלנקאר
525 ,סאג'ור ,126 ,אלס'חירה
525 ,סאג'ור ,118 ,אלסהל
525 ,סאג'ור ,121 ,אלסרוה
525 ,סאג'ור ,5012 ,אלעטאר
525 ,סאג'ור ,113 ,אלעין
525 ,סאג'ור ,109 ,אלקובה
525 ,סאג'ור ,129 ,אלתנור
525 ,סאג'ור ,103 ,דהר אלביאד
525 ,סאג'ור ,110 ,דהר אלמגארה
525 ,סאג'ור ,127 ,ואדי חמיד
525 ,סאג'ור ,117 ,יוסף אלגריב
525 ,סאג'ור ,107 ,מסרב אלחסאדין
525 ,סאג'ור ,105 ,מע'ארת עובה
525 ,סאג'ור ,9000 ,סאג'ור
525 ,סאג'ור ,115 ,ראס אלמרג'
578 ,סאסא ,106 ,אדמונית
578 ,סאסא ,111 ,אנה פרנק
578 ,סאסא ,7001 ,ביס שדה הר מירון
578 ,סאסא ,116 ,דולב
578 ,סאסא ,109 ,הארזים
578 ,סאסא ,104 ,הבוסתן
578 ,סאסא ,113 ,הזיתים
578 ,סאסא ,102 ,המטעים
578 ,סאסא ,101 ,המייסדים
578 ,סאסא ,112 ,המשק
578 ,סאסא ,115 ,הר אדיר
578 ,סאסא ,114 ,הר חירם
578 ,סאסא ,105 ,הר מירון
578 ,סאסא ,108 ,חורשה
578 ,סאסא ,107 ,מורן
578 ,סאסא ,103 ,נוף הרים
578 ,סאסא ,9000 ,סאסא
578 ,סאסא ,110 ,קטלב
587 ,סביון ,193 ,אוריה
587 ,סביון ,131 ,אלון
587 ,סביון ,116 ,אמירים
587 ,סביון ,182 ,אנפה
587 ,סביון ,135 ,ארז
587 ,סביון ,3122 ,גן השקמים
587 ,סביון ,6500 ,גני יהודה
587 ,סביון ,107 ,דגן
587 ,סביון ,190 ,דוכיפת
587 ,סביון ,187 ,דרור
587 ,סביון ,112 ,האופק
587 ,סביון ,122 ,האורנים
587 ,סביון ,3101 ,האירוס גני יהודה
587 ,סביון ,133 ,האתרוג
587 ,סביון ,113 ,הברוש
587 ,סביון ,108 ,הגבעה
587 ,סביון ,129 ,הגדרות
587 ,סביון ,101 ,הגיא
587 ,סביון ,3102 ,הגנים גני יהודה
587 ,סביון ,136 ,הדס
587 ,סביון ,140 ,הדקל
587 ,סביון ,114 ,הדרום
587 ,סביון ,115 ,ההדרים
587 ,סביון ,197 ,הוורד
587 ,סביון ,3103 ,הורדים גני יהודה
587 ,סביון ,3104 ,הזית גני יהודה
587 ,סביון ,165 ,החורש
587 ,סביון ,150 ,החרמש
587 ,סביון ,123 ,המועדון
587 ,סביון ,3111 ,המתנחלים גני יהודה
587 ,סביון ,118 ,הנוף
587 ,סביון ,3119 ,הצבעוני גני יהודה
587 ,סביון ,110 ,הצבר
587 ,סביון ,130 ,הצפון
587 ,סביון ,139 ,הקשת
587 ,סביון ,109 ,הר דפנה
587 ,סביון ,120 ,הראשונים
587 ,סביון ,132 ,הרגב
587 ,סביון ,102 ,הרכס
587 ,סביון ,196 ,השושנה
587 ,סביון ,128 ,השחר
587 ,סביון ,124 ,השלוה
587 ,סביון ,137 ,השקד
587 ,סביון ,103 ,השקמה
587 ,סביון ,127 ,התומר
587 ,סביון ,104 ,התיכון
587 ,סביון ,134 ,התלם
587 ,סביון ,186 ,זמיר
587 ,סביון ,179 ,חוחית
587 ,סביון ,183 ,חופית
587 ,סביון ,181 ,יסעור
587 ,סביון ,6001 ,ככר העשור
587 ,סביון ,111 ,כרם הזיתים
587 ,סביון ,178 ,לבנית
587 ,סביון ,105 ,מבואות
587 ,סביון ,106 ,מגל
587 ,סביון ,144 ,מגלן
587 ,סביון ,194 ,נחליאלי
587 ,סביון ,9000 ,סביון
587 ,סביון ,191 ,סלעית
587 ,סביון ,148 ,סמ הגבעה
587 ,סביון ,151 ,סמ הגדרות
587 ,סביון ,166 ,סמ הגיא
587 ,סביון ,146 ,סמ הדרום
587 ,סביון ,198 ,סמ הוורד
587 ,סביון ,3124 ,סמ החבצלת גני יהודה
587 ,סביון ,3106 ,סמ החצב גני יהודה
587 ,סביון ,117 ,סמ החרמש
587 ,סביון ,3108 ,סמ הכלנית גני יהודה
587 ,סביון ,3115 ,סמ המרגנית
587 ,סביון ,3113 ,סמ הנורית גני יהודה
587 ,סביון ,3117 ,סמ הנרקיס גני יהודה
587 ,סביון ,3123 ,סמ העשור גני יהודה
587 ,סביון ,147 ,סמ הר דפנה
587 ,סביון ,3121 ,סמ הרקפת גני יהודה
587 ,סביון ,119 ,סמ השקמה
587 ,סביון ,184 ,סמ חופית
587 ,סביון ,145 ,סמ כרם הזיתים
587 ,סביון ,121 ,סמ מבואות
587 ,סביון ,149 ,סמ מגל
587 ,סביון ,126 ,סמדר
587 ,סביון ,195 ,סנונית
587 ,סביון ,188 ,עגורים
587 ,סביון ,177 ,עיטם
587 ,סביון ,125 ,עלומים
587 ,סביון ,189 ,עפרוני
587 ,סביון ,192 ,צופית
587 ,סביון ,176 ,צוקית
587 ,סביון ,138 ,רימונית
587 ,סביון ,185 ,שחף
587 ,סביון ,180 ,שלדג
2046 ,סגולה ,113 ,דרך הבציר
2046 ,סגולה ,103 ,דרך הגורן
2046 ,סגולה ,110 ,דרך הזורע
2046 ,סגולה ,102 ,דרך היוגב
2046 ,סגולה ,104 ,דרך המסיק
2046 ,סגולה ,107 ,משעול האסיף
2046 ,סגולה ,106 ,משעול הגדיד
2046 ,סגולה ,112 ,משעול הדיר
2046 ,סגולה ,111 ,משעול החריש
2046 ,סגולה ,109 ,משעול הנוקד
2046 ,סגולה ,105 ,משעול הקטיף
2046 ,סגולה ,108 ,משעול הקציר
2046 ,סגולה ,9000 ,סגולה
2046 ,סגולה ,101 ,שדרת האיכרים
942 ,סואעד (חמרייה) ,9000 ,סואעד (חמרייה)
989 ,סואעד (כמאנה) (שבט) ,9000 ,סואעד (כמאנה) (שבט)
526 ,סולם ,9000 ,סולם
3756 ,סוסיה ,9000 ,סוסיה
1238 ,סופה ,9000 ,סופה
7500 ,סח'נין ,6713 ,אזור התעשייה
7500 ,סח'נין ,6762 ,אל ג'אבה
7500 ,סח'נין ,6742 ,אל חלאן
7500 ,סח'נין ,6723 ,אל מסאחה
7500 ,סח'נין ,6711 ,אל מסרארה
7500 ,סח'נין ,6712 ,אלבלאנה
7500 ,סח'נין ,6770 ,אלברכה
7500 ,סח'נין ,6771 ,אלסיד
7500 ,סח'נין ,6710 ,אלרויס
7500 ,סח'נין ,6720 ,ג'ורת סמיעה
7500 ,סח'נין ,6774 ,ד'יל נח'לה
7500 ,סח'נין ,6751 ,העיר העתיקה
7500 ,סח'נין ,6761 ,ואדי אל ספא
7500 ,סח'נין ,6772 ,ואדי אלעין
7500 ,סח'נין ,6732 ,זוגות צעירים
7500 ,סח'נין ,6741 ,ח'לת אל סח'ר
7500 ,סח'נין ,6760 ,ח'לת בלוט
7500 ,סח'נין ,6722 ,ח'לת ח'שיש שכ ורדים
7500 ,סח'נין ,6730 ,ח'לת חסן
7500 ,סח'נין ,6752 ,מרכז העיר
7500 ,סח'נין ,6773 ,מרסאן
7500 ,סח'נין ,6740 ,משפחת ח'לאילה
7500 ,סח'נין ,6763 ,משפחת טרביה
7500 ,סח'נין ,9000 ,סח'נין
7500 ,סח'נין ,6721 ,קסום
7500 ,סח'נין ,6750 ,שכונת הנוצרים
1170 ,סייד (שבט) ,9000 ,סייד (שבט)
1245 ,סלמה ,101 ,אורט
1245 ,סלמה ,102 ,אל אסנאבל
1245 ,סלמה ,103 ,אל בוטם
1245 ,סלמה ,104 ,אל בוסתאן
1245 ,סלמה ,105 ,אל ביאן
1245 ,סלמה ,106 ,אל בלאבל
1245 ,סלמה ,107 ,אל בלח
1245 ,סלמה ,108 ,אל ברכה
1245 ,סלמה ,109 ,אל ג'ורי
1245 ,סלמה ,110 ,אל ג'ליל
1245 ,סלמה ,111 ,אל דורדאר
1245 ,סלמה ,112 ,אל וואדי
1245 ,סלמה ,113 ,אל וורוד
1245 ,סלמה ,114 ,אל זוהור
1245 ,סלמה ,115 ,אל זיתון
1245 ,סלמה ,116 ,אל זנבק
1245 ,סלמה ,121 ,אל ח'ילאן
1245 ,סלמה ,117 ,אל ח'ללה
1245 ,סלמה ,118 ,אל ח'רוב
1245 ,סלמה ,119 ,אל חדיקה
1245 ,סלמה ,122 ,אל חירבה
1245 ,סלמה ,123 ,אל חלאל
1245 ,סלמה ,124 ,אל לאוז
1245 ,סלמה ,120 ,אל מג'ד
1245 ,סלמה ,126 ,אל מלולה
1245 ,סלמה ,127 ,אל מנאר
1245 ,סלמה ,128 ,אל מסכ
1245 ,סלמה ,129 ,אל מראח
1245 ,סלמה ,130 ,אל מרווה
1245 ,סלמה ,131 ,אל מרכז
1245 ,סלמה ,132 ,אל נאורס
1245 ,סלמה ,133 ,אל נוצוב אל תד'כארי
1245 ,סלמה ,134 ,אל נור
1245 ,סלמה ,135 ,אל נח'יל
1245 ,סלמה ,136 ,אל נעאם
1245 ,סלמה ,137 ,אל נרג'ס
1245 ,סלמה ,138 ,אל סהל
1245 ,סלמה ,139 ,אל סופסאף
1245 ,סלמה ,140 ,אל סלאם
1245 ,סלמה ,141 ,אל סנדבאד
1245 ,סלמה ,142 ,אל סנדיאנה
1245 ,סלמה ,143 ,אל סנובר
1245 ,סלמה ,144 ,אל עין
1245 ,סלמה ,145 ,אל עקד
1245 ,סלמה ,146 ,אל צפא
1245 ,סלמה ,147 ,אל קרז
1245 ,סלמה ,148 ,אל ראוד'ה
1245 ,סלמה ,149 ,אל רזק
1245 ,סלמה ,150 ,אל ריחאן
1245 ,סלמה ,151 ,אל תל
1245 ,סלמה ,9000 ,סלמה
3567 ,סלעית ,9000 ,סלעית
1156 ,סמר ,9000 ,סמר
3777 ,סנסנה ,103 ,אעלה בתמר
3777 ,סנסנה ,102 ,אפיקי מים
3777 ,סנסנה ,107 ,גינת אגוז
3777 ,סנסנה ,104 ,מעין גנים
3777 ,סנסנה ,9000 ,סנסנה
3777 ,סנסנה ,106 ,פרדס רימונים
3777 ,סנסנה ,105 ,קול התור
419 ,סעד ,9000 ,סעד
1360 ,סעוה ,9000 ,סעוה
454 ,סער ,9000 ,סער
1176 ,ספיר ,110 ,אשבורן
1176 ,ספיר ,101 ,גבים
1176 ,ספיר ,103 ,הר ארחות
1176 ,ספיר ,107 ,הר כרכום
1176 ,ספיר ,105 ,הר נטפים
1176 ,ספיר ,106 ,הר רמון
1176 ,ספיר ,109 ,הר שגיא
1176 ,ספיר ,104 ,הר שחורת
1176 ,ספיר ,102 ,נקרות
1176 ,ספיר ,9000 ,ספיר
1176 ,ספיר ,114 ,עומר
1176 ,ספיר ,112 ,עין רחל
1176 ,ספיר ,108 ,צין
1176 ,ספיר ,113 ,קצרה
1176 ,ספיר ,111 ,שלהב
610 ,סתריה ,108 ,אבני החושן
610 ,סתריה ,110 ,אודם
610 ,סתריה ,109 ,אלמוג
610 ,סתריה ,102 ,ברקת
610 ,סתריה ,111 ,הגפן
610 ,סתריה ,115 ,הזית
610 ,סתריה ,113 ,החיטה
610 ,סתריה ,112 ,הרימון
610 ,סתריה ,116 ,השעורה
610 ,סתריה ,114 ,התאנה
610 ,סתריה ,117 ,התמר
610 ,סתריה ,101 ,טופז
610 ,סתריה ,103 ,יהלום
610 ,סתריה ,107 ,לשם
610 ,סתריה ,106 ,ספיר
610 ,סתריה ,9000 ,סתריה
610 ,סתריה ,104 ,ענבר
610 ,סתריה ,118 ,שבעת המינים
610 ,סתריה ,105 ,שוהם
610 ,סתריה ,6500 ,שכון הבנים
610 ,סתריה ,119 ,תרשיש
4501 ,ע'ג'ר ,109 ,אלאמאם
4501 ,ע'ג'ר ,114 ,אלואדי
4501 ,ע'ג'ר ,125 ,אלופאא
4501 ,ע'ג'ר ,103 ,אלורוד
4501 ,ע'ג'ר ,119 ,אלזיתון
4501 ,ע'ג'ר ,123 ,אלזנאבק
4501 ,ע'ג'ר ,108 ,אלחדיקה
4501 ,ע'ג'ר ,111 ,אלחכים
4501 ,ע'ג'ר ,124 ,אליאסמין
4501 ,ע'ג'ר ,122 ,אלמג'הול
4501 ,ע'ג'ר ,117 ,אלמדרסה
4501 ,ע'ג'ר ,127 ,אלמזאר
4501 ,ע'ג'ר ,120 ,אלמח'תפי
4501 ,ע'ג'ר ,131 ,אלמנטקה אלסנאעייה
4501 ,ע'ג'ר ,106 ,אלמרג'
4501 ,ע'ג'ר ,110 ,אלנהדה
4501 ,ע'ג'ר ,101 ,אלנח'יל
4501 ,ע'ג'ר ,113 ,אלסדאקה
4501 ,ע'ג'ר ,112 ,אלסהלה
4501 ,ע'ג'ר ,102 ,אלסלאם
4501 ,ע'ג'ר ,105 ,אלסלייב
4501 ,ע'ג'ר ,126 ,אלעג'מה
4501 ,ע'ג'ר ,104 ,אלעטור
4501 ,ע'ג'ר ,118 ,אלעמוד
4501 ,ע'ג'ר ,128 ,אלעריד
4501 ,ע'ג'ר ,129 ,אלפרעי
4501 ,ע'ג'ר ,116 ,אלקדים
4501 ,ע'ג'ר ,107 ,אלרודה
4501 ,ע'ג'ר ,121 ,אלשיח' מהנא
4501 ,ע'ג'ר ,132 ,אלשיח' מוחמד חסין
4501 ,ע'ג'ר ,115 ,אלשלאל
4501 ,ע'ג'ר ,9000 ,ע'ג'ר
4501 ,ע'ג'ר ,130 ,שכארת אלארבעיין
892 ,עבדון ,9000 ,עבדון
376 ,עברון ,9000 ,עברון
794 ,עגור ,9000 ,עגור
1199 ,עדי ,112 ,אודם
1199 ,עדי ,119 ,אלה
1199 ,עדי ,118 ,אלון
1199 ,עדי ,113 ,ברקת
1199 ,עדי ,117 ,גפן
1199 ,עדי ,105 ,הברוש
1199 ,עדי ,101 ,הגומא
1199 ,עדי ,106 ,הדס
1199 ,עדי ,102 ,הדקל
1199 ,עדי ,116 ,הורד
1199 ,עדי ,110 ,הזית
1199 ,עדי ,104 ,החרוב
1199 ,עדי ,103 ,השקד
1199 ,עדי ,111 ,טופז
1199 ,עדי ,108 ,יהלום
1199 ,עדי ,115 ,מרגלית
1199 ,עדי ,109 ,ספיר
1199 ,עדי ,9000 ,עדי
1199 ,עדי ,114 ,שנהב
1199 ,עדי ,107 ,תמר
2035 ,עדנים ,113 ,דרך האיכרים
2035 ,עדנים ,111 ,דרך הכפר
2035 ,עדנים ,112 ,הבאר
2035 ,עדנים ,105 ,ההדס
2035 ,עדנים ,103 ,ההדר
2035 ,עדנים ,109 ,היסמין
2035 ,עדנים ,102 ,הלבונה
2035 ,עדנים ,108 ,המור
2035 ,עדנים ,110 ,המרווה
2035 ,עדנים ,104 ,הריחן
2035 ,עדנים ,101 ,הרקון
2035 ,עדנים ,115 ,השדות
2035 ,עדנים ,6001 ,כיכר המחלבה
2035 ,עדנים ,6000 ,כיכר המייסדים
2035 ,עדנים ,106 ,סמ האפרסמון
2035 ,עדנים ,107 ,סמ הרוזמרין
2035 ,עדנים ,114 ,סמ מגדל המים
2035 ,עדנים ,9000 ,עדנים
826 ,עוזה ,9000 ,עוזה
528 ,עוזייר ,9000 ,עוזייר
737 ,עולש ,101 ,מגל
737 ,עולש ,102 ,מורג
737 ,עולש ,103 ,נוף הרים
737 ,עולש ,104 ,נוף שדות
737 ,עולש ,9000 ,עולש
737 ,עולש ,105 ,שדרות היוגב
666 ,עומר ,138 ,אגוז
666 ,עומר ,284 ,אגמון
666 ,עומר ,258 ,אדמונית
666 ,עומר ,260 ,אוכמנית
666 ,עומר ,113 ,אורן
666 ,עומר ,111 ,אטד
666 ,עומר ,262 ,אירוס
666 ,עומר ,306 ,אלומות
666 ,עומר ,131 ,אלון
666 ,עומר ,266 ,אפרסמון
666 ,עומר ,106 ,אקליפטוס
666 ,עומר ,139 ,ארז
666 ,עומר ,175 ,אשוח
666 ,עומר ,305 ,אשכולות
666 ,עומר ,116 ,אשל
666 ,עומר ,307 ,בית הבד
666 ,עומר ,114 ,ברוש
666 ,עומר ,268 ,ברקן
666 ,עומר ,6500 ,גני עומר
666 ,עומר ,253 ,דבורנית
666 ,עומר ,256 ,דגנית
666 ,עומר ,130 ,דפנה
666 ,עומר ,252 ,דקל
666 ,עומר ,259 ,דרדר
666 ,עומר ,186 ,דרך האגם
666 ,עומר ,187 ,דרך היער
666 ,עומר ,185 ,דרך הנחל
666 ,עומר ,118 ,האלה
666 ,עומר ,207 ,הגדרון
666 ,עומר ,303 ,הגורן
666 ,עומר ,151 ,הגפן
666 ,עומר ,304 ,הגת
666 ,עומר ,158 ,הדוכיפת
666 ,עומר ,107 ,הדס
666 ,עומר ,117 ,הדר
666 ,עומר ,119 ,הזית
666 ,עומר ,205 ,החופית
666 ,עומר ,105 ,החורשה
666 ,עומר ,189 ,החסידה
666 ,עומר ,209 ,הטבלן
666 ,עומר ,188 ,הינשוף
666 ,עומר ,302 ,היקב
666 ,עומר ,112 ,הכרם
666 ,עומר ,208 ,הנחשון
666 ,עומר ,159 ,הנשר
666 ,עומר ,204 ,הסלעית
666 ,עומר ,157 ,העפרוני
666 ,עומר ,206 ,הפנינית
666 ,עומר ,203 ,הקורא
666 ,עומר ,143 ,הרדוף
666 ,עומר ,109 ,הרימון
666 ,עומר ,142 ,השיטה
666 ,עומר ,201 ,התנשמת
666 ,עומר ,280 ,חבצלת
666 ,עומר ,274 ,חיננית
666 ,עומר ,276 ,חלמונית
666 ,עומר ,101 ,חצב
666 ,עומר ,108 ,חרוב
666 ,עומר ,279 ,חרצית
666 ,עומר ,141 ,יערה
666 ,עומר ,308 ,יצהר
666 ,עומר ,255 ,כוכבן
666 ,עומר ,140 ,כליל החורש
666 ,עומר ,136 ,כלנית
666 ,עומר ,263 ,כרכום
666 ,עומר ,269 ,לבונה
666 ,עומר ,133 ,לבנה
666 ,עומר ,285 ,לוטם
666 ,עומר ,277 ,לילך
666 ,עומר ,281 ,לימונית
666 ,עומר ,270 ,מור
666 ,עומר ,199 ,מעבר האגמית
666 ,עומר ,192 ,מעבר האנפה
666 ,עומר ,191 ,מעבר החורשה
666 ,עומר ,195 ,מעבר היסעור
666 ,עומר ,197 ,מעבר הליבנית
666 ,עומר ,194 ,מעבר השחף
666 ,עומר ,193 ,מעבר השלדג
666 ,עומר ,196 ,מעבר השקנאי
666 ,עומר ,198 ,מעבר התמירון
666 ,עומר ,123 ,מרגנית
666 ,עומר ,264 ,מרווה
666 ,עומר ,120 ,משעול אורן
666 ,עומר ,128 ,משעול אתרוג
666 ,עומר ,147 ,משעול דולב
666 ,עומר ,171 ,משעול האדמון
666 ,עומר ,183 ,משעול הברבור
666 ,עומר ,181 ,משעול הדרור
666 ,עומר ,210 ,משעול הורדית
666 ,עומר ,172 ,משעול הזהבן
666 ,עומר ,160 ,משעול הזמיר
666 ,עומר ,173 ,משעול החוגלה
666 ,עומר ,161 ,משעול החוחית
666 ,עומר ,162 ,משעול היונה
666 ,עומר ,163 ,משעול הירקון
666 ,עומר ,174 ,משעול הלילית
666 ,עומר ,165 ,משעול הנץ
666 ,עומר ,166 ,משעול הסנונית
666 ,עומר ,182 ,משעול העגור
666 ,עומר ,167 ,משעול העורב
666 ,עומר ,155 ,משעול העיט
666 ,עומר ,169 ,משעול הפשוש
666 ,עומר ,168 ,משעול הצופית
666 ,עומר ,211 ,משעול הצוקית
666 ,עומר ,200 ,משעול הקוקיה
666 ,עומר ,170 ,משעול השחרור
666 ,עומר ,156 ,משעול התור
666 ,עומר ,202 ,משעול זרזיר
666 ,עומר ,148 ,משעול מורן
666 ,עומר ,146 ,משעול סיסם
666 ,עומר ,150 ,משעול שקמה
666 ,עומר ,287 ,נורית
666 ,עומר ,164 ,נחליאלי
666 ,עומר ,300 ,ניצנים
666 ,עומר ,135 ,נרקיס
666 ,עומר ,257 ,סביון
666 ,עומר ,275 ,סחלב
666 ,עומר ,124 ,סיגלון
666 ,עומר ,282 ,סיגלית
666 ,עומר ,254 ,סייפן
666 ,עומר ,267 ,סרפד
666 ,עומר ,278 ,סתונית
666 ,עומר ,125 ,עד עד
666 ,עומר ,9000 ,עומר
666 ,עומר ,301 ,עומרים
666 ,עומר ,104 ,ערבה
666 ,עומר ,6501 ,פארק תעשיה
666 ,עומר ,103 ,פלפלון
666 ,עומר ,273 ,פעמונית
666 ,עומר ,261 ,פרג
666 ,עומר ,144 ,צאלה
666 ,עומר ,286 ,צבעוני
666 ,עומר ,126 ,צבר
666 ,עומר ,251 ,ציפורן
666 ,עומר ,127 ,צלף
666 ,עומר ,110 ,צפצפה
666 ,עומר ,271 ,קידה
666 ,עומר ,265 ,קינמון
666 ,עומר ,115 ,רותם
666 ,עומר ,137 ,רקפת
666 ,עומר ,272 ,שושן
666 ,עומר ,310 ,שיבולים
666 ,עומר ,145 ,שיזף
666 ,עומר ,121 ,שקד
666 ,עומר ,132 ,שקמה
666 ,עומר ,134 ,תאנה
666 ,עומר ,177 ,תאשור
666 ,עומר ,176 ,תדהר
666 ,עומר ,309 ,תירוש
666 ,עומר ,122 ,תמר
666 ,עומר ,149 ,תפוז
666 ,עומר ,283 ,תפוח
810 ,עופר ,107 ,האורן
810 ,עופר ,106 ,האלה
810 ,עופר ,101 ,האלון
810 ,עופר ,110 ,הברוש
810 ,עופר ,109 ,הדקל
810 ,עופר ,111 ,הזית
810 ,עופר ,105 ,החרוב
810 ,עופר ,108 ,הרימון
810 ,עופר ,104 ,השקד
810 ,עופר ,102 ,התאנה
810 ,עופר ,103 ,כליל החורש
810 ,עופר ,9000 ,עופר
3617 ,עופרה ,130 ,אולפנא
3617 ,עופרה ,105 ,אמונים
3617 ,עופרה ,106 ,אצ"ל
3617 ,עופרה ,161 ,אשר
3617 ,עופרה ,133 ,בינה ודעת
3617 ,עופרה ,155 ,בני יעקב
3617 ,עופרה ,6500 ,גבעת צבי
3617 ,עופרה ,159 ,גד
3617 ,עופרה ,156 ,דינה
3617 ,עופרה ,157 ,דן
3617 ,עופרה ,107 ,דרך ארץ ימיני
3617 ,עופרה ,136 ,דרך החיים
3617 ,עופרה ,126 ,דרך חנן
3617 ,עופרה ,108 ,הגפן
3617 ,עופרה ,131 ,הדבש
3617 ,עופרה ,132 ,הדובדבן
3617 ,עופרה ,109 ,ההגנה
3617 ,עופרה ,103 ,הזית
3617 ,עופרה ,140 ,הכרם
3617 ,עופרה ,110 ,המטע
3617 ,עופרה ,101 ,המייסדים
3617 ,עופרה ,104 ,הסלע
3617 ,עופרה ,111 ,הפלמ"ח
3617 ,עופרה ,124 ,הצבר
3617 ,עופרה ,134 ,הראשונים
3617 ,עופרה ,112 ,הרימון
3617 ,עופרה ,127 ,השומר
3617 ,עופרה ,135 ,השקד
3617 ,עופרה ,102 ,התאנה
3617 ,עופרה ,113 ,התמר
3617 ,עופרה ,114 ,התעשיה
3617 ,עופרה ,163 ,זבולון
3617 ,עופרה ,115 ,ט' באייר
3617 ,עופרה ,154 ,יהודה
3617 ,עופרה ,160 ,יוסף
3617 ,עופרה ,162 ,יששכר
3617 ,עופרה ,152 ,לוי
3617 ,עופרה ,116 ,לח"י
3617 ,עופרה ,120 ,מורג
3617 ,עופרה ,117 ,מעלה בני עקיבא
3617 ,עופרה ,122 ,מקימי הגדר
3617 ,עופרה ,118 ,משעול הר אפרים
3617 ,עופרה ,119 ,ניל"י
3617 ,עופרה ,158 ,נפתלי
3617 ,עופרה ,121 ,סמ בנימין
3617 ,עופרה ,9000 ,עופרה
3617 ,עופרה ,123 ,פנינת חן
3617 ,עופרה ,128 ,צה"ל
3617 ,עופרה ,151 ,ראובן
3617 ,עופרה ,6501 ,ש קרוונים
3617 ,עופרה ,150 ,שבטי ישראל
3617 ,עופרה ,125 ,שדה עופרים
3617 ,עופרה ,6507 ,שכ בית"ר
3617 ,עופרה ,6506 ,שכ הכרם
3617 ,עופרה ,6504 ,שכ הצבר
3617 ,עופרה ,7001 ,שכ עמונה
3617 ,עופרה ,6505 ,שכ פנינת חן
3617 ,עופרה ,6502 ,שכ צמרת
3617 ,עופרה ,6503 ,שכ שדה עופרים
3617 ,עופרה ,153 ,שמעון
32 ,עוצם ,9000 ,עוצם
957 ,עוקבי (בנו עוקבה) ,9000 ,עוקבי (בנו עוקבה)
328 ,עזוז ,9000 ,עזוז
1149 ,עזר ,119 ,אנפה
1149 ,עזר ,105 ,דוכיפת
1149 ,עזר ,114 ,דרור
1149 ,עזר ,110 ,האורן
1149 ,עזר ,111 ,האלה
1149 ,עזר ,112 ,האלון
1149 ,עזר ,113 ,הברוש
1149 ,עזר ,108 ,הדקל
1149 ,עזר ,102 ,הורד
1149 ,עזר ,109 ,הזית
1149 ,עזר ,115 ,החוחית
1149 ,עזר ,104 ,הכלנית
1149 ,עזר ,101 ,הלילך
1149 ,עזר ,103 ,הנרקיס
1149 ,עזר ,106 ,הפרג
1149 ,עזר ,116 ,הפשוש
1149 ,עזר ,107 ,הצבעוני
1149 ,עזר ,117 ,סנונית
1149 ,עזר ,9000 ,עזר
1149 ,עזר ,118 ,שחף
837 ,עזריאל ,102 ,הכרם
837 ,עזריאל ,101 ,הרימון
837 ,עזריאל ,104 ,התאנה
837 ,עזריאל ,103 ,התפוח
837 ,עזריאל ,9000 ,עזריאל
711 ,עזריה ,9000 ,עזריה
817 ,עזריקם ,9000 ,עזריקם
969 ,עטאוונה (שבט) ,9000 ,עטאוונה (שבט)
3658 ,עטרת ,109 ,ארץ צוף
3658 ,עטרת ,108 ,באר זית
3658 ,עטרת ,106 ,בני חשמונאי
3658 ,עטרת ,105 ,גיבורי התהילה
3658 ,עטרת ,104 ,הגדוד העברי
3658 ,עטרת ,103 ,הנביא שמואל
3658 ,עטרת ,101 ,הרי גופנה
3658 ,עטרת ,102 ,מעלה ההר
3658 ,עטרת ,107 ,נחלת אפרים
3658 ,עטרת ,9000 ,עטרת
3658 ,עטרת ,110 ,רמתיים צופים
1175 ,עידן ,9000 ,עידן
530 ,עיילבון ,166 ,אבו בקר
530 ,עיילבון ,177 ,אבו הבר
530 ,עיילבון ,119 ,אבן ח'לדון
530 ,עיילבון ,117 ,אבן סינא
530 ,עיילבון ,201 ,אברהים חוא
530 ,עיילבון ,134 ,אום קולת'ום
530 ,עיילבון ,139 ,אלארז
530 ,עיילבון ,149 ,אלבטוף
530 ,עיילבון ,146 ,אלביאדר
530 ,עיילבון ,109 ,אלבסאתין
530 ,עיילבון ,173 ,אלבשארה
530 ,עיילבון ,161 ,אלג'אדורי
530 ,עיילבון ,151 ,אלג'בל
530 ,עיילבון ,172 ,אלג'וד
530 ,עיילבון ,144 ,אלג'ליל
530 ,עיילבון ,112 ,אלהודא
530 ,עיילבון ,157 ,אלואדי
530 ,עיילבון ,116 ,אלוחדה
530 ,עיילבון ,138 ,אלורוד
530 ,עיילבון ,135 ,אלזייתון
530 ,עיילבון ,203 ,אלזעתר
530 ,עיילבון ,200 ,אלח'ורי מורקוס
530 ,עיילבון ,156 ,אלח'לה
530 ,עיילבון ,154 ,אלחואקיר
530 ,עיילבון ,152 ,אלטבראני
530 ,עיילבון ,181 ,אלטור
530 ,עיילבון ,136 ,אליאסמין
530 ,עיילבון ,121 ,אלירמוק
530 ,עיילבון ,101 ,אלכנאאיס
530 ,עיילבון ,115 ,אלכראמה
530 ,עיילבון ,160 ,אלכרמל
530 ,עיילבון ,174 ,אלמג'ד
530 ,עיילבון ,104 ,אלמגלס
530 ,עיילבון ,113 ,אלמוטראן
530 ,עיילבון ,103 ,אלמחבה
530 ,עיילבון ,153 ,אלמטל
530 ,עיילבון ,169 ,אלמינה
530 ,עיילבון ,143 ,אלמל
530 ,עיילבון ,155 ,אלמפרק
530 ,עיילבון ,162 ,אלמרג'
530 ,עיילבון ,120 ,אלנאסרה
530 ,עיילבון ,106 ,אלנור
530 ,עיילבון ,142 ,אלנח'יל
530 ,עיילבון ,108 ,אלנחל
530 ,עיילבון ,128 ,אלניל
530 ,עיילבון ,137 ,אלנרג'ס
530 ,עיילבון ,105 ,אלסחווה
530 ,עיילבון ,102 ,אלסלאם
530 ,עיילבון ,140 ,אלסנדיאן
530 ,עיילבון ,159 ,אלעזוניה
530 ,עיילבון ,175 ,אלענאב
530 ,עיילבון ,127 ,אלפוראת
530 ,עיילבון ,122 ,אלקודס
530 ,עיילבון ,141 ,אלקנדול
530 ,עיילבון ,176 ,אלקרמה
530 ,עיילבון ,110 ,אלראודאת
530 ,עיילבון ,118 ,אלראזי
530 ,עיילבון ,114 ,אלרביע
530 ,עיילבון ,148 ,אלרוס
530 ,עיילבון ,132 ,אלרחבאני
530 ,עיילבון ,129 ,אלשאם
530 ,עיילבון ,170 ,אלתג'דידה
530 ,עיילבון ,171 ,אלתעאוון
530 ,עיילבון ,125 ,ביירות
530 ,עיילבון ,126 ,בית לחם
530 ,עיילבון ,202 ,בלאל
530 ,עיילבון ,182 ,ג'דודנא
530 ,עיילבון ,145 ,דרב אלעין
530 ,עיילבון ,150 ,ואדי עטואת
530 ,עיילבון ,131 ,ודיע אלסאפי
530 ,עיילבון ,158 ,חטין
530 ,עיילבון ,123 ,חיפה
530 ,עיילבון ,163 ,חנא נקארה
530 ,עיילבון ,130 ,לובנאן
530 ,עיילבון ,180 ,מאר גריס
530 ,עיילבון ,107 ,מואסי
530 ,עיילבון ,124 ,סידא
530 ,עיילבון ,111 ,סלימאן זריק
530 ,עיילבון ,133 ,עבד אלוהאב
530 ,עיילבון ,165 ,עומר אבן אלח'טאב
530 ,עיילבון ,9000 ,עיילבון
530 ,עיילבון ,168 ,עלי אבן אבי טאלב
530 ,עיילבון ,167 ,ענתרה
530 ,עיילבון ,179 ,פיירוז
530 ,עיילבון ,178 ,פרג' סרור
530 ,עיילבון ,164 ,תאופיק זיאד
156 ,עיינות ,9000 ,עיינות
511 ,עילוט ,267 ,אבו אדריס
511 ,עילוט ,111 ,אבו בכר אלצדיק
511 ,עילוט ,262 ,אבו ח'יאל
511 ,עילוט ,282 ,אבו חניפה
511 ,עילוט ,108 ,אבו מוסא
511 ,עילוט ,179 ,אבו עוביידה
511 ,עילוט ,276 ,אבו עיאש
511 ,עילוט ,263 ,אבו ראס
511 ,עילוט ,140 ,אבו תנהא
511 ,עילוט ,184 ,אבן בטוטה
511 ,עילוט ,107 ,אבן סינא
511 ,עילוט ,249 ,אג'נאדין
511 ,עילוט ,284 ,אחמד אבן חנבל
511 ,עילוט ,323 ,אחמד אלאסעד
511 ,עילוט ,205 ,אל מותנבי
511 ,עילוט ,209 ,אלאבטאל
511 ,עילוט ,245 ,אלאנדלוס
511 ,עילוט ,192 ,אלאקסא
511 ,עילוט ,261 ,אלארבעין
511 ,עילוט ,106 ,אלבאבא
511 ,עילוט ,254 ,אלביאדה
511 ,עילוט ,127 ,אלביאדר
511 ,עילוט ,161 ,אלג'בל
511 ,עילוט ,307 ,אלג'דיר
511 ,עילוט ,172 ,אלג'ולאן
511 ,עילוט ,102 ,אלג'ליל
511 ,עילוט ,251 ,אלג'עאתין
511 ,עילוט ,252 ,אלג'ראבה
511 ,עילוט ,168 ,אלהודא
511 ,עילוט ,128 ,אלואד
511 ,עילוט ,118 ,אלוארד
511 ,עילוט ,123 ,אלזהראא
511 ,עילוט ,238 ,אלזהראוי
511 ,עילוט ,191 ,אלזייתון
511 ,עילוט ,279 ,אלזיר
511 ,עילוט ,234 ,אלזקאק
511 ,עילוט ,253 ,אלח'דירה
511 ,עילוט ,314 ,אלח'דר
511 ,עילוט ,325 ,אלח'ורי יוסף
511 ,עילוט ,185 ,אלחאג' מוסטפא
511 ,עילוט ,315 ,אלחואכיר
511 ,עילוט ,260 ,אלחכורה אלג'רבייה
511 ,עילוט ,240 ,אלחליל
511 ,עילוט ,237 ,אלטברי
511 ,עילוט ,138 ,אליאסמין
511 ,עילוט ,115 ,אלירמוק
511 ,עילוט ,206 ,אלכנסאא
511 ,עילוט ,316 ,אלכראמה
511 ,עילוט ,130 ,אלכרום
511 ,עילוט ,145 ,אלמאמור
511 ,עילוט ,183 ,אלמגידל
511 ,עילוט ,250 ,אלמדאביס
511 ,עילוט ,222 ,אלמדינה
511 ,עילוט ,156 ,אלמדרסה
511 ,עילוט ,322 ,אלמוכתאר דיב
511 ,עילוט ,204 ,אלמידאן
511 ,עילוט ,174 ,אלמנזל
511 ,עילוט ,134 ,אלמראח
511 ,עילוט ,210 ,אלנאורס
511 ,עילוט ,105 ,אלנאסרה
511 ,עילוט ,297 ,אלנבי אבראהים
511 ,עילוט ,293 ,אלנבי אדם
511 ,עילוט ,294 ,אלנבי אדריס
511 ,עילוט ,300 ,אלנבי אסמאעיל
511 ,עילוט ,305 ,אלנבי הוד
511 ,עילוט ,298 ,אלנבי יוסף
511 ,עילוט ,299 ,אלנבי יעקוב
511 ,עילוט ,135 ,אלנבי לוט
511 ,עילוט ,302 ,אלנבי מוסא
511 ,עילוט ,295 ,אלנבי נוח
511 ,עילוט ,296 ,אלנבי סאלח
511 ,עילוט ,301 ,אלנבי עיסא
511 ,עילוט ,303 ,אלנבי שועייב
511 ,עילוט ,193 ,אלנור
511 ,עילוט ,195 ,אלניל
511 ,עילוט ,132 ,אלנסר
511 ,עילוט ,125 ,אלנרג'ס
511 ,עילוט ,255 ,אלסבאת
511 ,עילוט ,200 ,אלסדרה
511 ,עילוט ,317 ,אלסהל
511 ,עילוט ,157 ,אלסולטאן
511 ,עילוט ,109 ,אלסלאם
511 ,עילוט ,131 ,אלסנאבל
511 ,עילוט ,150 ,אלסנובר
511 ,עילוט ,170 ,אלספא
511 ,עילוט ,149 ,אלספאח
511 ,עילוט ,288 ,אלעולום
511 ,עילוט ,318 ,אלעומדה
511 ,עילוט ,129 ,אלעין
511 ,עילוט ,197 ,אלערב
511 ,עילוט ,321 ,אלפארוק
511 ,עילוט ,278 ,אלפקירה
511 ,עילוט ,319 ,אלפרש
511 ,עילוט ,139 ,אלקודס
511 ,עילוט ,166 ,אלקיירואן
511 ,עילוט ,259 ,אלקנדולה
511 ,עילוט ,122 ,אלקרונפול
511 ,עילוט ,320 ,אלראדי
511 ,עילוט ,310 ,אלרובעאן
511 ,עילוט ,304 ,אלרומי
511 ,עילוט ,312 ,אלרוסאן
511 ,עילוט ,246 ,אלשאם
511 ,עילוט ,281 ,אלשאפעי
511 ,עילוט ,144 ,אלשועראא
511 ,עילוט ,146 ,אלשיך רביע
511 ,עילוט ,213 ,אלתות
511 ,עילוט ,258 ,אם אלחג'ל
511 ,עילוט ,313 ,בהראן
511 ,עילוט ,121 ,ביסאן
511 ,עילוט ,229 ,ביר אלעין
511 ,עילוט ,198 ,בלאל בן רבאח
511 ,עילוט ,275 ,ברגות
511 ,עילוט ,272 ,בשיר
511 ,עילוט ,160 ,ג'בל סיך
511 ,עילוט ,212 ,דג'לה
511 ,עילוט ,158 ,דפלה
511 ,עילוט ,311 ,דרב אלואוי
511 ,עילוט ,224 ,דרך אהל אלג'נה
511 ,עילוט ,230 ,דרך אלג'ורידה
511 ,עילוט ,228 ,דרך אלחארה
511 ,עילוט ,226 ,דרך אלטואחין
511 ,עילוט ,223 ,דרך אלמטאמיר
511 ,עילוט ,227 ,דרך אלמכבאת
511 ,עילוט ,225 ,דרך קורטובה
511 ,עילוט ,203 ,הארון אלרשיד
511 ,עילוט ,231 ,זבדה
511 ,עילוט ,274 ,זטמה
511 ,עילוט ,257 ,זרזורה
511 ,עילוט ,271 ,ח'טיב
511 ,עילוט ,194 ,חאלד אבן אלוליד
511 ,עילוט ,218 ,חוסין אבו עיאש
511 ,עילוט ,247 ,חטין
511 ,עילוט ,119 ,חיפה
511 ,עילוט ,292 ,חנא נקארה
511 ,עילוט ,211 ,טאהא חוסין
511 ,עילוט ,236 ,טארק אבן זיאד
511 ,עילוט ,143 ,טבריה
511 ,עילוט ,270 ,יאסין
511 ,עילוט ,242 ,יאפא
511 ,עילוט ,196 ,יונס
511 ,עילוט ,265 ,לובאני
511 ,עילוט ,103 ,לימון
511 ,עילוט ,283 ,מאלק
511 ,עילוט ,324 ,מוכתאר חסן
511 ,עילוט ,286 ,מוסטפא אלחמאד
511 ,עילוט ,159 ,מופלח סעיד
511 ,עילוט ,215 ,מיי זיאדה
511 ,עילוט ,306 ,מרים
511 ,עילוט ,241 ,נאבליס
511 ,עילוט ,162 ,סאלח
511 ,עילוט ,291 ,סאלח אלחאג'
511 ,עילוט ,290 ,סאלח אלחמאד
511 ,עילוט ,309 ,סאלח מוחמד
511 ,עילוט ,173 ,סלאג'ה
511 ,עילוט ,120 ,סלאח אלדין
511 ,עילוט ,113 ,סלטי
511 ,עילוט ,235 ,סלימאן אלח'ליל
511 ,עילוט ,268 ,סלימאני
511 ,עילוט ,232 ,סמונייה
511 ,עילוט ,216 ,סעיד אבו זוויד
511 ,עילוט ,181 ,סעיד סאלח
511 ,עילוט ,176 ,ספד
511 ,עילוט ,264 ,ספורי
511 ,עילוט ,101 ,ספוריה
511 ,עילוט ,287 ,עבד אלח'ליל
511 ,עילוט ,217 ,עבד אללה אלחאג'
511 ,עילוט ,148 ,עבד אלרחמאן
511 ,עילוט ,273 ,עבוד
511 ,עילוט ,266 ,עודת אללה
511 ,עילוט ,117 ,עומר אבן אלח'טאב
511 ,עילוט ,280 ,עומר בן עבד אלעזיז
511 ,עילוט ,126 ,עותמאן בן עפאן
511 ,עילוט ,9000 ,עילוט
511 ,עילוט ,221 ,עין אלשמאלייה
511 ,עילוט ,248 ,עין ג'אלות
511 ,עילוט ,239 ,עכא
511 ,עילוט ,136 ,עלי בן אבי טאלב
511 ,עילוט ,165 ,עלי סברי
511 ,עילוט ,243 ,עסקלאן
511 ,עילוט ,180 ,פאדל אבו ראס
511 ,עילוט ,256 ,קסי
511 ,עילוט ,208 ,ראס אלתין
511 ,עילוט ,289 ,רובעאן סאלח
511 ,עילוט ,214 ,רמדאן
511 ,עילוט ,244 ,רמלה
511 ,עילוט ,269 ,שחאדה
511 ,עילוט ,285 ,שיח אסמאעיל
511 ,עילוט ,141 ,שיך אחמד
511 ,עילוט ,308 ,תאופיק מוחמד
687 ,עין איילה ,101 ,הגפן
687 ,עין איילה ,102 ,הזית
687 ,עין איילה ,103 ,החיטה
687 ,עין איילה ,104 ,הרימון
687 ,עין איילה ,105 ,השעורה
687 ,עין איילה ,106 ,התאנה
687 ,עין איילה ,107 ,התמר
687 ,עין איילה ,9000 ,עין איילה
546 ,עין אל-אסד ,131 ,אבו ג'לאל
546 ,עין אל-אסד ,140 ,אל אח'וה
546 ,עין אל-אסד ,143 ,אל דרג'ה
546 ,עין אל-אסד ,129 ,אל ורוד
546 ,עין אל-אסד ,145 ,אל ח'רובה
546 ,עין אל-אסד ,136 ,אל מוסתקבל
546 ,עין אל-אסד ,128 ,אל מטל
546 ,עין אל-אסד ,148 ,אל מקסבה
546 ,עין אל-אסד ,125 ,אל נבעה
546 ,עין אל-אסד ,138 ,אל סולטאן
546 ,עין אל-אסד ,132 ,אל עבדיה
546 ,עין אל-אסד ,144 ,אל עקבה
546 ,עין אל-אסד ,142 ,אל רומאן
546 ,עין אל-אסד ,103 ,אל-אבנאא
546 ,עין אל-אסד ,113 ,אל-בוסתאן
546 ,עין אל-אסד ,112 ,אל-ביאדר
546 ,עין אל-אסד ,102 ,אל-ג'נוד
546 ,עין אל-אסד ,117 ,אל-זיתון
546 ,עין אל-אסד ,109 ,אל-מוע'אר
546 ,עין אל-אסד ,101 ,אל-עין
546 ,עין אל-אסד ,106 ,אלח'לוה
546 ,עין אל-אסד ,120 ,אלכרום
546 ,עין אל-אסד ,124 ,אלמג'דל
546 ,עין אל-אסד ,137 ,מוראד
546 ,עין אל-אסד ,134 ,עין אל חד'רה
546 ,עין אל-אסד ,9000 ,עין אל-אסד
546 ,עין אל-אסד ,122 ,עין ספוא
546 ,עין אל-אסד ,127 ,ענאן
546 ,עין אל-אסד ,130 ,ראס אל רבאע
546 ,עין אל-אסד ,111 ,רח 11
546 ,עין אל-אסד ,104 ,רח 4
546 ,עין אל-אסד ,114 ,רח 14
546 ,עין אל-אסד ,105 ,רח 5
546 ,עין אל-אסד ,115 ,רח 15
546 ,עין אל-אסד ,116 ,רח 16
546 ,עין אל-אסד ,107 ,רח 7
546 ,עין אל-אסד ,108 ,רח 8
546 ,עין אל-אסד ,118 ,רח 18
273 ,עין גב ,101 ,בית ראשון במולדת
273 ,עין גב ,9000 ,עין גב
2042 ,עין גדי ,101 ,ביס שדה
2042 ,עין גדי ,102 ,בית ראשון במולדת
2042 ,עין גדי ,7001 ,מצדה
2042 ,עין גדי ,9000 ,עין גדי
436 ,עין דור ,102 ,בעלת האוב
436 ,עין דור ,114 ,דבורה הנביאה
436 ,עין דור ,111 ,דרך התבור
436 ,עין דור ,125 ,הברוש
436 ,עין דור ,113 ,הגורן
436 ,עין דור ,121 ,הגן
436 ,עין דור ,117 ,הדקל
436 ,עין דור ,123 ,הזית
436 ,עין דור ,104 ,החבצלת
436 ,עין דור ,107 ,החינוך
436 ,עין דור ,126 ,החרובים
436 ,עין דור ,112 ,היוגבים
436 ,עין דור ,118 ,הכלנית
436 ,עין דור ,109 ,הכרמים
436 ,עין דור ,127 ,המוסדניקים
436 ,עין דור ,108 ,המייסדים
436 ,עין דור ,122 ,הפיקוס
436 ,עין דור ,120 ,הפקאן
436 ,עין דור ,110 ,הפרדס
436 ,עין דור ,105 ,השדות
436 ,עין דור ,103 ,השומר הצעיר
436 ,עין דור ,116 ,השיזף
436 ,עין דור ,106 ,השקד
436 ,עין דור ,119 ,התורמוס
436 ,עין דור ,128 ,התות
436 ,עין דור ,124 ,ילדי השמש
436 ,עין דור ,9000 ,עין דור
436 ,עין דור ,101 ,שאול המלך
436 ,עין דור ,115 ,שדרות מסע
1240 ,עין הבשור ,105 ,אוגדה
1240 ,עין הבשור ,101 ,דקלה
1240 ,עין הבשור ,106 ,זהרון
1240 ,עין הבשור ,107 ,חרובית
1240 ,עין הבשור ,102 ,ימית
1240 ,עין הבשור ,103 ,מבוא סיני
1240 ,עין הבשור ,108 ,נביעות
1240 ,עין הבשור ,9000 ,עין הבשור
1240 ,עין הבשור ,109 ,פריאל
1240 ,עין הבשור ,104 ,שדות
74 ,עין הוד ,9000 ,עין הוד
167 ,עין החורש ,9000 ,עין החורש
289 ,עין המפרץ ,9000 ,עין המפרץ
383 ,עין הנצי"ב ,9000 ,עין הנצי"ב
367 ,עין העמק ,6500 ,הרחבה
367 ,עין העמק ,9000 ,עין העמק
270 ,עין השופט ,102 ,אולפן קיבוץ
270 ,עין השופט ,101 ,בית ראשון במולדת
270 ,עין השופט ,9000 ,עין השופט
676 ,עין השלושה ,101 ,בית ראשון במולדת
676 ,עין השלושה ,9000 ,עין השלושה
157 ,עין ורד ,112 ,באר-גן
157 ,עין ורד ,104 ,דרור
157 ,עין ורד ,111 ,דרך בית הכנסת
157 ,עין ורד ,115 ,האלון
157 ,עין ורד ,107 ,הברכות
157 ,עין ורד ,108 ,הדרים
157 ,עין ורד ,128 ,היובל
157 ,עין ורד ,101 ,הירדן
157 ,עין ורד ,116 ,הכלנית
157 ,עין ורד ,126 ,הלימון
157 ,עין ורד ,103 ,המעיין
157 ,עין ורד ,110 ,הנוער
157 ,עין ורד ,102 ,הנחל
157 ,עין ורד ,120 ,הסביון
157 ,עין ורד ,121 ,הסייפן
157 ,עין ורד ,113 ,הפרדס
157 ,עין ורד ,105 ,הפרסה
157 ,עין ורד ,117 ,הצבעוני
157 ,עין ורד ,114 ,הצבר
157 ,עין ורד ,106 ,הראשונים
157 ,עין ורד ,119 ,השזיף
157 ,עין ורד ,118 ,התורמוס
157 ,עין ורד ,127 ,התפוז
157 ,עין ורד ,6002 ,ככר הברכות
157 ,עין ורד ,6006 ,ככר הירדן
157 ,עין ורד ,6001 ,ככר העם
157 ,עין ורד ,6005 ,ככר הצאלון
157 ,עין ורד ,6003 ,ככר הראשונים
157 ,עין ורד ,9000 ,עין ורד
157 ,עין ורד ,109 ,צאלון
4503 ,עין זיוון ,101 ,בית ראשון במולדת
4503 ,עין זיוון ,9000 ,עין זיוון
1320 ,עין חוד ,9000 ,עין חוד
1053 ,עין חצבה ,9000 ,עין חצבה
89 ,עין חרוד (איחוד) ,9000 ,עין חרוד (איחוד)
82 ,עין חרוד (מאוחד) ,9000 ,עין חרוד (מאוחד)
806 ,עין יהב ,9000 ,עין יהב
813 ,עין יעקב ,105 ,אלון
813 ,עין יעקב ,109 ,ברוש
813 ,עין יעקב ,101 ,גפן
813 ,עין יעקב ,106 ,דקל
813 ,עין יעקב ,107 ,האלה
813 ,עין יעקב ,112 ,הדס מלכא
813 ,עין יעקב ,108 ,זית
813 ,עין יעקב ,103 ,חרוב
813 ,עין יעקב ,115 ,יוני נתניהו
813 ,עין יעקב ,104 ,משעול האלה
813 ,עין יעקב ,9000 ,עין יעקב
813 ,עין יעקב ,114 ,עמנואל מורנו
813 ,עין יעקב ,113 ,רועי קליין
813 ,עין יעקב ,116 ,רחבעם זאבי
813 ,עין יעקב ,102 ,רימון
813 ,עין יעקב ,117 ,רפאל איתן
813 ,עין יעקב ,110 ,תאנה
813 ,עין יעקב ,111 ,תמר
1056 ,עין כרם-בי"ס חקלאי ,9000 ,עין כרם-בי"ס חקלאי
426 ,עין כרמל ,9000 ,עין כרמל
426 ,עין כרמל ,101 ,שכ קרוונים
532 ,עין מאהל ,213 ,א-דורה
532 ,עין מאהל ,139 ,א-זהראא
532 ,עין מאהל ,218 ,א-זוהור
532 ,עין מאהל ,138 ,א-זיתון
532 ,עין מאהל ,124 ,א-זנבקה
532 ,עין מאהל ,207 ,א-זעתר
532 ,עין מאהל ,149 ,א-טחאוי
532 ,עין מאהל ,225 ,א-נבע
532 ,עין מאהל ,202 ,א-נס'ר
532 ,עין מאהל ,182 ,א-סביח
532 ,עין מאהל ,130 ,א-סולטאן
532 ,עין מאהל ,234 ,א-סכאכיני
532 ,עין מאהל ,211 ,א-סלאם
532 ,עין מאהל ,122 ,א-ראזי
532 ,עין מאהל ,178 ,א-רבאעין
532 ,עין מאהל ,229 ,א-רביע
532 ,עין מאהל ,228 ,א-רדא
532 ,עין מאהל ,208 ,א-רודה
532 ,עין מאהל ,145 ,א-רומאן
532 ,עין מאהל ,244 ,א-ריאד
532 ,עין מאהל ,131 ,א-רסאפי
532 ,עין מאהל ,105 ,א-שאבי
532 ,עין מאהל ,132 ,א-שאם
532 ,עין מאהל ,119 ,א-שאפיעי
532 ,עין מאהל ,114 ,א-שואקה
532 ,עין מאהל ,147 ,א-שקיאן
532 ,עין מאהל ,226 ,א-תבאנה
532 ,עין מאהל ,197 ,אבו בקר א-ס'דיק
532 ,עין מאהל ,187 ,אבו ד'ר אל-ע'פארי
532 ,עין מאהל ,257 ,אבו דבוס
532 ,עין מאהל ,115 ,אבו עוביידה
532 ,עין מאהל ,220 ,אדם
532 ,עין מאהל ,120 ,אחמד איבן חנבל
532 ,עין מאהל ,206 ,אחמד שוקי
532 ,עין מאהל ,116 ,איבן זידון
532 ,עין מאהל ,112 ,איבן ח'לדון
532 ,עין מאהל ,247 ,איבן חזם
532 ,עין מאהל ,117 ,איבן כת'יר
532 ,עין מאהל ,154 ,איבן סינא
532 ,עין מאהל ,192 ,איבן רושד
532 ,עין מאהל ,269 ,אל-אימאן
532 ,עין מאהל ,106 ,אל-אנדלוס
532 ,עין מאהל ,123 ,אל-אס'יל
532 ,עין מאהל ,222 ,אל-אס'מעי
532 ,עין מאהל ,185 ,אל-אפנדי
532 ,עין מאהל ,201 ,אל-אקס'א
532 ,עין מאהל ,217 ,אל-באיר
532 ,עין מאהל ,152 ,אל-בוח'ארי
532 ,עין מאהל ,227 ,אל-בוסתאן
532 ,עין מאהל ,148 ,אל-בירוני
532 ,עין מאהל ,223 ,אל-בריד
532 ,עין מאהל ,254 ,אל-ג'בל אל-אביד
532 ,עין מאהל ,136 ,אל-ג'והרה
532 ,עין מאהל ,190 ,אל-ג'וז
532 ,עין מאהל ,235 ,אל-ג'ליל
532 ,עין מאהל ,264 ,אל-הודא
532 ,עין מאהל ,203 ,אל-ואד
532 ,עין מאהל ,191 ,אל-ח'טיב
532 ,עין מאהל ,184 ,אל-ח'נסאא
532 ,עין מאהל ,103 ,אל-חאווז
532 ,עין מאהל ,101 ,אל-חארה א-שמאלייה
532 ,עין מאהל ,163 ,אל-חדיקה
532 ,עין מאהל ,248 ,אל-חיכמה
532 ,עין מאהל ,142 ,אל-חסנין
532 ,עין מאהל ,153 ,אל-יאסמין
532 ,עין מאהל ,209 ,אל-כמאל
532 ,עין מאהל ,204 ,אל-כראמה
532 ,עין מאהל ,165 ,אל-כרם
532 ,עין מאהל ,193 ,אל-מואריס
532 ,עין מאהל ,270 ,אל-מוס'טפא
532 ,עין מאהל ,150 ,אל-מוס'רארה
532 ,עין מאהל ,181 ,אל-מועתס'ם
532 ,עין מאהל ,110 ,אל-מותנבי
532 ,עין מאהל ,155 ,אל-מותרבה
532 ,עין מאהל ,140 ,אל-מחמיד
532 ,עין מאהל ,194 ,אל-מטל
532 ,עין מאהל ,146 ,אל-מילה אל-קדימה
532 ,עין מאהל ,133 ,אל-מלאס'יה
532 ,עין מאהל ,113 ,אל-מנסור
532 ,עין מאהל ,134 ,אל-מנפלוטי
532 ,עין מאהל ,158 ,אל-מנשר
532 ,עין מאהל ,250 ,אל-מעאלי
532 ,עין מאהל ,170 ,אל-מעס'רה
532 ,עין מאהל ,252 ,אל-מקטראן
532 ,עין מאהל ,232 ,אל-מרואה
532 ,עין מאהל ,107 ,אל-ע'זאלי
532 ,עין מאהל ,156 ,אל-עבדוני
532 ,עין מאהל ,219 ,אל-עימארה
532 ,עין מאהל ,171 ,אל-עין
532 ,עין מאהל ,258 ,אל-עקד
532 ,עין מאהל ,249 ,אל-פיאד
532 ,עין מאהל ,151 ,אל-פיחאא
532 ,עין מאהל ,199 ,אל-פרדוס
532 ,עין מאהל ,238 ,אל-פרזדק
532 ,עין מאהל ,135 ,אל-קאדסיה
532 ,עין מאהל ,100 ,אל-קודס
532 ,עין מאהל ,6710 ,אלביאדר
532 ,עין מאהל ,6720 ,אלווערה
532 ,עין מאהל ,6733 ,אלסואדיה
532 ,עין מאהל ,6734 ,אלעאבהרה
532 ,עין מאהל ,6731 ,אם נסאר
532 ,עין מאהל ,144 ,אשביליה
532 ,עין מאהל ,224 ,בדר
532 ,עין מאהל ,242 ,ביירות
532 ,עין מאהל ,245 ,בילאל בין רבאח
532 ,עין מאהל ,195 ,בלקיס
532 ,עין מאהל ,175 ,בע'דאד
532 ,עין מאהל ,102 ,ג'בל סיח'
532 ,עין מאהל ,127 ,ג'בר
532 ,עין מאהל ,129 ,ג'ריר
532 ,עין מאהל ,168 ,דימשק
532 ,עין מאהל ,177 ,דרב א-סוק
532 ,עין מאהל ,215 ,האשם
532 ,עין מאהל ,6732 ,ואדי אלח'וויך
532 ,עין מאהל ,243 ,זין אל-עאבדין
532 ,עין מאהל ,266 ,זיתא
532 ,עין מאהל ,161 ,ח'אלד בין אל-וליד
532 ,עין מאהל ,109 ,חאפז איבראהים
532 ,עין מאהל ,253 ,חואלה
532 ,עין מאהל ,236 ,חיטין
532 ,עין מאהל ,532 ,חיפא
532 ,עין מאהל ,198 ,טה חוסין
532 ,עין מאהל ,267 ,טלחה בין עוביד אללה
532 ,עין מאהל ,216 ,יאפא
532 ,עין מאהל ,164 ,יוסוף
532 ,עין מאהל ,121 ,מאלכ איבן אנס
532 ,עין מאהל ,231 ,מועאד בין ג'בל
532 ,עין מאהל ,272 ,מועאויה
532 ,עין מאהל ,265 ,מכה
532 ,עין מאהל ,259 ,מנדח
532 ,עין מאהל ,189 ,מראח אל-ג'ראנה
532 ,עין מאהל ,210 ,מראח ג'בר
532 ,עין מאהל ,6721 ,מרכז הכפר
532 ,עין מאהל ,233 ,נג'יב מחפוז
532 ,עין מאהל ,186 ,ניזאר קבאני
532 ,עין מאהל ,268 ,נסר בין עאסם
532 ,עין מאהל ,162 ,ס'אלח א-דין
532 ,עין מאהל ,128 ,סולימאן
532 ,עין מאהל ,271 ,סירין
532 ,עין מאהל ,159 ,סמארה
532 ,עין מאהל ,246 ,סעד בין מועאד
532 ,עין מאהל ,200 ,ע'רנטה
532 ,עין מאהל ,183 ,עוכאז
532 ,עין מאהל ,263 ,עומר בן עבד אל-עזיז
532 ,עין מאהל ,160 ,עות'מאן בין עפאן
532 ,עין מאהל ,260 ,עיון אל-ג'נאן
532 ,עין מאהל ,125 ,עין אל-ג'וזה
532 ,עין מאהל ,261 ,עין אל-מוסיא
532 ,עין מאהל ,179 ,עין אל-עוליקה
532 ,עין מאהל ,9000 ,עין מאהל
532 ,עין מאהל ,118 ,עלי בן אבי טאלב
532 ,עין מאהל ,262 ,קבשאנה
532 ,עין מאהל ,111 ,קורדובה
532 ,עין מאהל ,255 ,ראבעה אל-עדוייה
532 ,עין מאהל ,176 ,ראס אל-בידר
532 ,עין מאהל ,167 ,שארע א-סואדייה
532 ,עין מאהל ,212 ,שארע אום נאסר
532 ,עין מאהל ,141 ,שארע אל-ביאדר
532 ,עין מאהל ,172 ,שארע אל-וערה
532 ,עין מאהל ,237 ,שארע אל-ח'לה
532 ,עין מאהל ,169 ,שארע אל-כרום
532 ,עין מאהל ,104 ,שארע אל-מנזלה
532 ,עין מאהל ,173 ,שארע אל-עבהרה
532 ,עין מאהל ,188 ,שארע ואדי אל-ח'ויח'
532 ,עין מאהל ,6711 ,שכ אל-ח'לה
532 ,עין מאהל ,6730 ,שכ אלכרום
532 ,עין מאהל ,6712 ,שכ אלמנזלה
532 ,עין מאהל ,157 ,שעין
521 ,עין נקובא ,101 ,האגוז
521 ,עין נקובא ,102 ,האגס
521 ,עין נקובא ,103 ,האפרסק
521 ,עין נקובא ,104 ,הגנים
521 ,עין נקובא ,105 ,הדקלים
521 ,עין נקובא ,111 ,המעיין
521 ,עין נקובא ,107 ,השלום
521 ,עין נקובא ,108 ,השקד
521 ,עין נקובא ,109 ,התאנים
521 ,עין נקובא ,110 ,התפוח
521 ,עין נקובא ,9000 ,עין נקובא
223 ,עין עירון ,100 ,אל המעיין
223 ,עין עירון ,101 ,גבעת הרקפות
223 ,עין עירון ,110 ,הבאר
223 ,עין עירון ,102 ,הקשת
223 ,עין עירון ,109 ,כוכב הצפון
223 ,עין עירון ,104 ,נוף הזריחה
223 ,עין עירון ,105 ,סמ קדימה
223 ,עין עירון ,9000 ,עין עירון
223 ,עין עירון ,106 ,קדמה
223 ,עין עירון ,107 ,ראשוני המושב
223 ,עין עירון ,108 ,שביל החלב
622 ,עין צורים ,110 ,בירייה
622 ,עין צורים ,111 ,גוש עציון
622 ,עין צורים ,118 ,הגלבוע
622 ,עין צורים ,116 ,להגשמה
622 ,עין צורים ,114 ,מגשימים
622 ,עין צורים ,115 ,מרחבים
622 ,עין צורים ,117 ,נחשון
622 ,עין צורים ,112 ,נתיבות
622 ,עין צורים ,9000 ,עין צורים
622 ,עין צורים ,113 ,שחל
4502 ,עין קנייא ,9000 ,עין קנייא
514 ,עין ראפה ,108 ,אל אנאב
514 ,עין ראפה ,139 ,אל-ווערה
514 ,עין ראפה ,143 ,אל-ח'לה
514 ,עין ראפה ,126 ,אל-עין
514 ,עין ראפה ,112 ,אל-קריה
514 ,עין ראפה ,107 ,אלוואד
514 ,עין ראפה ,134 ,אלכרום
514 ,עין ראפה ,127 ,אלכרם
514 ,עין ראפה ,145 ,אלמישה
514 ,עין ראפה ,157 ,אסכידניא
514 ,עין ראפה ,149 ,בוסתאן
514 ,עין ראפה ,153 ,בלדי
514 ,עין ראפה ,137 ,בעיקשה
514 ,עין ראפה ,132 ,ברקוק
514 ,עין ראפה ,154 ,ג'וז
514 ,עין ראפה ,103 ,ג'ורי
514 ,עין ראפה ,113 ,ג'ורן
514 ,עין ראפה ,125 ,ג'ניאן
514 ,עין ראפה ,146 ,דליה
514 ,עין ראפה ,155 ,ורד
514 ,עין ראפה ,106 ,זוזו
514 ,עין ראפה ,138 ,זיתון
514 ,עין ראפה ,128 ,זערור
514 ,עין ראפה ,114 ,זעתר
514 ,עין ראפה ,124 ,חאכורה
514 ,עין ראפה ,151 ,חורש
514 ,עין ראפה ,120 ,חרוב
514 ,עין ראפה ,117 ,יסמין
514 ,עין ראפה ,131 ,כראב אלקיק
514 ,עין ראפה ,129 ,לואיזה
514 ,עין ראפה ,122 ,לוז
514 ,עין ראפה ,130 ,לימון
514 ,עין ראפה ,116 ,מעלה ההר
514 ,עין ראפה ,109 ,מעסרה
514 ,עין ראפה ,111 ,מראח
514 ,עין ראפה ,148 ,משמש
514 ,עין ראפה ,101 ,נבעה
514 ,עין ראפה ,144 ,נח'יל
514 ,עין ראפה ,119 ,נענע
514 ,עין ראפה ,104 ,נרג'ס
514 ,עין ראפה ,121 ,סבר
514 ,עין ראפה ,105 ,סוסן
514 ,עין ראפה ,142 ,סלאם
514 ,עין ראפה ,136 ,סנסלה
514 ,עין ראפה ,123 ,עין אל-עמור
514 ,עין ראפה ,110 ,עין סובא
514 ,עין ראפה ,9000 ,עין ראפה
514 ,עין ראפה ,141 ,ענב
514 ,עין ראפה ,133 ,עראק אלמטבך
514 ,עין ראפה ,150 ,עריש
514 ,עין ראפה ,102 ,קרונפול
514 ,עין ראפה ,158 ,רוזמרין
514 ,עין ראפה ,118 ,ריחאן
514 ,עין ראפה ,135 ,תות
514 ,עין ראפה ,140 ,תין
514 ,עין ראפה ,115 ,תפאח
139 ,עין שמר ,9000 ,עין שמר
880 ,עין שריד ,122 ,אגמית
880 ,עין שריד ,120 ,ברבור
880 ,עין שריד ,118 ,דוכיפת
880 ,עין שריד ,119 ,דרור
880 ,עין שריד ,101 ,האנפה
880 ,עין שריד ,121 ,זיזית
880 ,עין שריד ,104 ,זמיר
880 ,עין שריד ,110 ,חוגלה
880 ,עין שריד ,111 ,חוחית
880 ,עין שריד ,116 ,חופית
880 ,עין שריד ,108 ,יסעור
880 ,עין שריד ,123 ,כחל
880 ,עין שריד ,114 ,נחליאלי
880 ,עין שריד ,115 ,סייפן
880 ,עין שריד ,102 ,סלעית
880 ,עין שריד ,117 ,סנונית
880 ,עין שריד ,105 ,עגור
880 ,עין שריד ,113 ,עיט
880 ,עין שריד ,9000 ,עין שריד
880 ,עין שריד ,107 ,עפרוני
880 ,עין שריד ,106 ,פשוש
880 ,עין שריד ,103 ,צוקית
880 ,עין שריד ,112 ,שחף
880 ,עין שריד ,109 ,שלדג
880 ,עין שריד ,124 ,שקנאי
1251 ,עין תמר ,9000 ,עין תמר
871 ,עינת ,9000 ,עינת
1187 ,עיר אובות ,9000 ,עיר אובות
7600 ,עכו ,334 ,אבות ובנים
7600 ,עכו ,628 ,אבירי יוחנן
7600 ,עכו ,321 ,אבן גבירול
7600 ,עכו ,280 ,אבני החושן
7600 ,עכו ,322 ,אברבנאל
7600 ,עכו ,304 ,אברהם בן-שושן
7600 ,עכו ,270 ,אברהם דנינו
7600 ,עכו ,387 ,אגם
7600 ,עכו ,141 ,אגס
7600 ,עכו ,476 ,אהוד מנור
7600 ,עכו ,312 ,אוסישקין
7600 ,עכו ,107 ,אזור התעשיה
7600 ,עכו ,6502 ,אזור התעשייה
7600 ,עכו ,6539 ,אזור תעשיה דרום
7600 ,עכו ,6541 ,אזור תעשיה שער נעמן
7600 ,עכו ,6548 ,אזור תעשייה אלרום
7600 ,עכו ,420 ,אח"י אילת
7600 ,עכו ,309 ,אחד העם
7600 ,עכו ,348 ,אחים קנדי
7600 ,עכו ,473 ,איה
7600 ,עכו ,120 ,איינשטיין
7600 ,עכו ,606 ,אל ג'זאר
7600 ,עכו ,611 ,אלחריזי
7600 ,עכו ,629 ,אלכסנדר מוקדון
7600 ,עכו ,417 ,אלמוג
7600 ,עכו ,131 ,אלקבץ
7600 ,עכו ,306 ,אלקלעי
7600 ,עכו ,612 ,אמלפי
7600 ,עכו ,359 ,אנה פרנק
7600 ,עכו ,506 ,אנילביץ'
7600 ,עכו ,513 ,אסירי המבצר
7600 ,עכו ,361 ,אסתר ראב
7600 ,עכו ,371 ,אפרים
7600 ,עכו ,129 ,ארגוב
7600 ,עכו ,135 ,אריאל
7600 ,עכו ,6547 ,את מילואות דרום
7600 ,עכו ,6544 ,את קרית הפלדה
7600 ,עכו ,152 ,אתגר
7600 ,עכו ,310 ,בבל
7600 ,עכו ,106 ,בולטימור
7600 ,עכו ,315 ,בורוכוב
7600 ,עכו ,331 ,בורלא
7600 ,עכו ,122 ,בורנשטיין
7600 ,עכו ,445 ,בי"ח מזרע
7600 ,עכו ,215 ,ביל"ו
7600 ,עכו ,631 ,בית לוסיניאן
7600 ,עכו ,630 ,בלדווין
7600 ,עכו ,219 ,בן יהודה
7600 ,עכו ,501 ,בן עמי
7600 ,עכו ,369 ,בנימין
7600 ,עכו ,613 ,בנימין מטודלה
7600 ,עכו ,326 ,בר-לב
7600 ,עכו ,213 ,ברל
7600 ,עכו ,220 ,ברנר
7600 ,עכו ,300 ,בת שבע
7600 ,עכו ,6501 ,גבעת התמרים
7600 ,עכו ,368 ,גד
7600 ,עכו ,108 ,גדוד 22
7600 ,עכו ,363 ,גולדה מאיר
7600 ,עכו ,314 ,גולומב
7600 ,עכו ,316 ,גורדון
7600 ,עכו ,515 ,גיבורי סיני
7600 ,עכו ,381 ,גל
7600 ,עכו ,614 ,גנואה
7600 ,עכו ,632 ,דאהר אל עומר
7600 ,עכו ,619 ,דאסין
7600 ,עכו ,412 ,דב גרונר
7600 ,עכו ,360 ,דבורה בארון
7600 ,עכו ,356 ,דבורה עומר
7600 ,עכו ,504 ,דוד נוי
7600 ,עכו ,481 ,דוכיפת
7600 ,עכו ,138 ,דולב
7600 ,עכו ,185 ,דושניצקי
7600 ,עכו ,149 ,דותן
7600 ,עכו ,237 ,דיכטר
7600 ,עכו ,372 ,דן
7600 ,עכו ,475 ,דרור
7600 ,עכו ,205 ,דרך הארבעה
7600 ,עכו ,416 ,דרך החיים
7600 ,עכו ,411 ,דרך הים
7600 ,עכו ,328 ,דרך המכללה
7600 ,עכו ,130 ,דרך השלום
7600 ,עכו ,150 ,דרך נבון יצחק
7600 ,עכו ,109 ,דרך צפת
7600 ,עכו ,287 ,האודם
7600 ,עכו ,429 ,האורן
7600 ,עכו ,288 ,האחלמה
7600 ,עכו ,426 ,האילן
7600 ,עכו ,268 ,האיקליפטוס
7600 ,עכו ,461 ,האירוסים
7600 ,עכו ,452 ,האלה
7600 ,עכו ,256 ,האנפה
7600 ,עכו ,217 ,הארגמן
7600 ,עכו ,264 ,הארז
7600 ,עכו ,241 ,האשכולית
7600 ,עכו ,428 ,האשל
7600 ,עכו ,244 ,האתרוג
7600 ,עכו ,633 ,הבהאים
7600 ,עכו ,204 ,הבוסתן
7600 ,עכו ,427 ,הברוש
7600 ,עכו ,332 ,הבריגדה היהודית
7600 ,עכו ,252 ,הברק
7600 ,עכו ,282 ,הברקת
7600 ,עכו ,327 ,הגאולה
7600 ,עכו ,123 ,הגומא
7600 ,עכו ,262 ,הגיא
7600 ,עכו ,341 ,הגן
7600 ,עכו ,342 ,הגפן
7600 ,עכו ,456 ,הדגנית
7600 ,עכו ,308 ,הדס
7600 ,עכו ,432 ,הדקל
7600 ,עכו ,509 ,ההגנה
7600 ,עכו ,453 ,ההרדוף
7600 ,עכו ,425 ,הזית
7600 ,עכו ,438 ,החבצלת
7600 ,עכו ,224 ,החורש
7600 ,עכו ,345 ,החירות
7600 ,עכו ,344 ,החרוב
7600 ,עכו ,112 ,החרושת
7600 ,עכו ,419 ,החרמון
7600 ,עכו ,457 ,החרצית
7600 ,עכו ,126 ,החרש
7600 ,עכו ,104 ,החשמל
7600 ,עכו ,284 ,היהלום
7600 ,עכו ,103 ,היוצר
7600 ,עכו ,203 ,היוצרים
7600 ,עכו ,132 ,היימן
7600 ,עכו ,459 ,היסמין
7600 ,עכו ,225 ,היערה
7600 ,עכו ,292 ,הישפה
7600 ,עכו ,434 ,הכלנית
7600 ,עכו ,253 ,הכפיר
7600 ,עכו ,424 ,הכרם
7600 ,עכו ,259 ,הלביא
7600 ,עכו ,458 ,הלוטוס
7600 ,עכו ,266 ,הלילך
7600 ,עכו ,242 ,הלימון
7600 ,עכו ,283 ,הלשם
7600 ,עכו ,117 ,המגל
7600 ,עכו ,124 ,המוסכים
7600 ,עכו ,111 ,המלאכה
7600 ,עכו ,245 ,המנדרינה
7600 ,עכו ,413 ,המסגר
7600 ,עכו ,211 ,המעפילים
7600 ,עכו ,415 ,המפעל
7600 ,עכו ,366 ,המר"ן
7600 ,עכו ,267 ,המרגנית
7600 ,עכו ,460 ,המרווה
7600 ,עכו ,634 ,המרוניטים
7600 ,עכו ,302 ,הנדיב
7600 ,עכו ,286 ,הנופך
7600 ,עכו ,455 ,הנורית
7600 ,עכו ,317 ,הנרייטה סאלד
7600 ,עכו ,439 ,הנרקיס
7600 ,עכו ,260 ,הנשר
7600 ,עכו ,214 ,הס
7600 ,עכו ,435 ,הסביון
7600 ,עכו ,330 ,הסדנה
7600 ,עכו ,436 ,הסיגלית
7600 ,עכו ,257 ,הסייפן
7600 ,עכו ,285 ,הספיר
7600 ,עכו ,518 ,הספנים
7600 ,עכו ,305 ,העלייה
7600 ,עכו ,333 ,העמל
7600 ,עכו ,505 ,העצמאות
7600 ,עכו ,255 ,הערבה
7600 ,עכו ,293 ,הפטדה
7600 ,עכו ,251 ,הפלמ"ח
7600 ,עכו ,347 ,הפרחים
7600 ,עכו ,269 ,הצבעוני
7600 ,עכו ,635 ,הצלבנים
7600 ,עכו ,254 ,הקורנס
7600 ,עכו ,223 ,הרב לופס
7600 ,עכו ,233 ,הרב נסים
7600 ,עכו ,263 ,הרותם
7600 ,עכו ,430 ,הרימון
7600 ,עכו ,125 ,הרכבת
7600 ,עכו ,301 ,הרמב"ם
7600 ,עכו ,402 ,הרצוג
7600 ,עכו ,403 ,הרצל
7600 ,עכו ,437 ,הרקפת
7600 ,עכו ,294 ,השבו
7600 ,עכו ,281 ,השוהם
7600 ,עכו ,623 ,השוק הלבן
7600 ,עכו ,624 ,השוק העותמני
7600 ,עכו ,6550 ,השוק העירוני
7600 ,עכו ,454 ,השושנים
7600 ,עכו ,258 ,השחק
7600 ,עכו ,433 ,השיטה
7600 ,עכו ,261 ,השיר
7600 ,עכו ,114 ,השיש
7600 ,עכו ,343 ,התאנה
7600 ,עכו ,6551 ,התחנה המרכזית
7600 ,עכו ,101 ,התעשייה
7600 ,עכו ,243 ,התפוז
7600 ,עכו ,291 ,התרשיש
7600 ,עכו ,144 ,ויולה
7600 ,עכו ,507 ,ויצמן
7600 ,עכו ,319 ,ויתקין
7600 ,עכו ,616 ,ונציה
7600 ,עכו ,508 ,ז'בוטינסקי
7600 ,עכו ,376 ,זבולון
7600 ,עכו ,358 ,זהרירה חריפאי
7600 ,עכו ,615 ,זיגורד
7600 ,עכו ,362 ,זלדה
7600 ,עכו ,610 ,זלמן הצורף
7600 ,עכו ,477 ,זמיר
7600 ,עכו ,627 ,חאן אל עומדן
7600 ,עכו ,620 ,חאן אל פרנג
7600 ,עכו ,625 ,חאן אל שוורדה
7600 ,עכו ,115 ,חוף ארגמן
7600 ,עכו ,116 ,חוף התמרים
7600 ,עכו ,127 ,חזה
7600 ,עכו ,414 ,חטיבת גולני
7600 ,עכו ,386 ,טל
7600 ,עכו ,226 ,טניס
7600 ,עכו ,510 ,טרומפלדור
7600 ,עכו ,409 ,יאנוש קורצ'אק
7600 ,עכו ,236 ,יגאל ידין
7600 ,עכו ,6538 ,יד נתן ביס חקלאי
7600 ,עכו ,374 ,יהודה
7600 ,עכו ,303 ,יהודה הלוי
7600 ,עכו ,503 ,יהושפט
7600 ,עכו ,384 ,יובל
7600 ,עכו ,636 ,יוליוס קיסר
7600 ,עכו ,110 ,יונתן החשמונאי
7600 ,עכו ,377 ,יוסף
7600 ,עכו ,637 ,יוסף בן מתתיהו
7600 ,עכו ,202 ,יוסף גדיש
7600 ,עכו ,246 ,יוסף גרשון
7600 ,עכו ,235 ,יוסף כתראן
7600 ,עכו ,271 ,יחזקאל אגא
7600 ,עכו ,638 ,יחיאל מפריס
7600 ,עכו ,335 ,יחיאלי
7600 ,עכו ,228 ,יל"ג
7600 ,עכו ,346 ,יפה נוף
7600 ,עכו ,338 ,יצחק שדה
7600 ,עכו ,340 ,יקותיאל אדם
7600 ,עכו ,423 ,ירושלים
7600 ,עכו ,128 ,ירקוני
7600 ,עכו ,375 ,יששכר
7600 ,עכו ,6004 ,ככר הישיבה
7600 ,עכו ,6003 ,ככר ליידי אדית
7600 ,עכו ,6001 ,ככר עבוד
7600 ,עכו ,6002 ,ככר פרחי
7600 ,עכו ,145 ,כנור
7600 ,עכו ,478 ,כנרית
7600 ,עכו ,207 ,כפר עברי
7600 ,עכו ,7002 ,כפר פילדלפיה
7600 ,עכו ,209 ,כצנלסון
7600 ,עכו ,354 ,לאה גולדברג
7600 ,עכו ,639 ,לואי דה פיליפו
7600 ,עכו ,640 ,לואי התשיעי
7600 ,עכו ,502 ,לוחמי הגטאות
7600 ,עכו ,365 ,לוי
7600 ,עכו ,641 ,ליאופולד השני
7600 ,עכו ,339 ,לסקוב
7600 ,עכו ,325 ,מוטה גור
7600 ,עכו ,313 ,מונטיפיורי
7600 ,עכו ,329 ,מוסד עתידות
7600 ,עכו ,527 ,מחנה נופש לחייל
7600 ,עכו ,382 ,מטר
7600 ,עכו ,143 ,מיתר
7600 ,עכו ,148 ,מנדולינה
7600 ,עכו ,350 ,מנוף
7600 ,עכו ,370 ,מנשה
7600 ,עכו ,6503 ,מע מזרע
7600 ,עכו ,6505 ,מעב משמר הים
7600 ,עכו ,6506 ,מעברה
7600 ,עכו ,385 ,מעין
7600 ,עכו ,519 ,מעלה החורשה
7600 ,עכו ,451 ,מעלה הכרמים
7600 ,עכו ,422 ,מפרץ שלמה
7600 ,עכו ,6555 ,מקדש הבהאים
7600 ,עכו ,351 ,מרים בן פורת
7600 ,עכו ,355 ,מרים ילן שטקליס
7600 ,עכו ,102 ,מרכז הבאהים
7600 ,עכו ,1001 ,מרכז לבריאות הנפש
7600 ,עכו ,526 ,מרכז רסקו
7600 ,עכו ,617 ,מרקו פולו
7600 ,עכו ,517 ,משה צורי
7600 ,עכו ,421 ,משה שרת
7600 ,עכו ,121 ,משמר הים
7600 ,עכו ,146 ,נבל
7600 ,עכו ,6534 ,נוה אביב
7600 ,עכו ,6507 ,נוה משה דיין
7600 ,עכו ,6532 ,נוה נתניהו
7600 ,עכו ,6529 ,נוה ספיר
7600 ,עכו ,6536 ,נווה גנים
7600 ,עכו ,642 ,נור א-דין
7600 ,עכו ,380 ,נחל
7600 ,עכו ,480 ,נחליאלי
7600 ,עכו ,626 ,נמל הדייגים
7600 ,עכו ,484 ,נעמי שמר
7600 ,עכו ,514 ,נפולאון בונפרטה
7600 ,עכו ,373 ,נפתלי
7600 ,עכו ,357 ,נתיבה בן יהודה
7600 ,עכו ,607 ,ס'לאח א-דין
7600 ,עכו ,643 ,סולימאן באשה
7600 ,עכו ,644 ,סידני סמית'
7600 ,עכו ,472 ,סלעית
7600 ,עכו ,212 ,סמ הטורים
7600 ,עכו ,218 ,סמולנסקין
7600 ,עכו ,210 ,סנש חנה
7600 ,עכו ,151 ,ספורט
7600 ,עכו ,525 ,סר אייזיק
7600 ,עכו ,408 ,עגנון
7600 ,עכו ,485 ,עוזי חיטמן
7600 ,עכו ,136 ,עוזרי
7600 ,עכו ,208 ,עטרות
7600 ,עכו ,601 ,עיר עתיקה 10
7600 ,עכו ,605 ,עיר עתיקה 11
7600 ,עכו ,602 ,עיר עתיקה 12
7600 ,עכו ,603 ,עיר עתיקה 13
7600 ,עכו ,604 ,עיר עתיקה 14
7600 ,עכו ,9000 ,עכו
7600 ,עכו ,234 ,עליית הנוער
7600 ,עכו ,470 ,עפרוני
7600 ,עכו ,206 ,עציון
7600 ,עכו ,134 ,פולי
7600 ,עכו ,645 ,פורצי המבצר
7600 ,עכו ,622 ,פח'ר א-דין
7600 ,עכו ,618 ,פיזה
7600 ,עכו ,646 ,פיליפ אוגוסטוס
7600 ,עכו ,216 ,פינסקר
7600 ,עכו ,383 ,פלג
7600 ,עכו ,483 ,פלמינגו
7600 ,עכו ,105 ,פנקס
7600 ,עכו ,272 ,פר"ח אהרון
7600 ,עכו ,247 ,פרלמן
7600 ,עכו ,647 ,פרנציסקוס
7600 ,עכו ,323 ,פרנקו
7600 ,עכו ,471 ,פשוש
7600 ,עכו ,147 ,צ'לו
7600 ,עכו ,609 ,צאלח ובצרי
7600 ,עכו ,474 ,צופית
7600 ,עכו ,133 ,צפירה
7600 ,עכו ,320 ,קדושי השואה
7600 ,עכו ,307 ,קדושי קהיר
7600 ,עכו ,201 ,קיבוץ גלויות
7600 ,עכו ,648 ,קפארלי
7600 ,עכו ,516 ,קפלן
7600 ,עכו ,6520 ,קרית וולפסון
7600 ,עכו ,364 ,ראובן
7600 ,עכו ,353 ,רחל המשוררת
7600 ,עכו ,239 ,רחמים פיטוסי
7600 ,עכו ,137 ,ריבלין
7600 ,עכו ,649 ,ריצ'רד לב ארי
7600 ,עכו ,650 ,רמב"ן
7600 ,עכו ,221 ,רמז
7600 ,עכו ,227 ,רמח"ל
7600 ,עכו ,6508 ,ש אגש רכבת
7600 ,עכו ,6509 ,ש אקדמאים
7600 ,עכו ,6510 ,ש משמר הים
7600 ,עכו ,6511 ,ש נוה ים
7600 ,עכו ,6512 ,ש עמידר
7600 ,עכו ,6513 ,ש עמידר חדש
7600 ,עכו ,6514 ,ש צבורי
7600 ,עכו ,6515 ,ש צפון
7600 ,עכו ,6516 ,ש צפון ב
7600 ,עכו ,6517 ,ש צפון ג
7600 ,עכו ,6519 ,ש צפוף
7600 ,עכו ,6521 ,ש קליפורניה
7600 ,עכו ,6522 ,ש רסקו
7600 ,עכו ,651 ,שבט אשר
7600 ,עכו ,289 ,שביל האגת
7600 ,עכו ,265 ,שביל הדרור
7600 ,עכו ,290 ,שביל הטופז
7600 ,עכו ,295 ,שביל מור ולבונה
7600 ,עכו ,296 ,שביל תכלת וארגמן
7600 ,עכו ,230 ,שדליץ
7600 ,עכו ,486 ,שושנה דמארי
7600 ,עכו ,482 ,שחף
7600 ,עכו ,410 ,שטראוס
7600 ,עכו ,140 ,שיזף
7600 ,עכו ,6537 ,שכ בגין
7600 ,עכו ,6523 ,שכ בן גוריון
7600 ,עכו ,6524 ,שכ גולדה מאיר
7600 ,עכו ,6546 ,שכ האקוודוקט
7600 ,עכו ,6549 ,שכ העיר העתיקה
7600 ,עכו ,6535 ,שכ הקרוונים
7600 ,עכו ,6526 ,שכ יעל
7600 ,עכו ,6552 ,שכ מוריה
7600 ,עכו ,6527 ,שכ משק עזר
7600 ,עכו ,6540 ,שכ נאות ים
7600 ,עכו ,6528 ,שכ נוה אלון
7600 ,עכו ,6504 ,שכ נפוליאון
7600 ,עכו ,6530 ,שכ עין אלסט
7600 ,עכו ,6531 ,שכ פרי אליעזר
7600 ,עכו ,6543 ,שכ צפון הכרם
7600 ,עכו ,6533 ,שכ שפת הים
7600 ,עכו ,6525 ,שכונת רם
7600 ,עכו ,479 ,שלדג
7600 ,עכו ,232 ,שלום הגליל
7600 ,עכו ,522 ,שלמה בן-יוסף
7600 ,עכו ,248 ,שלמה קליין
7600 ,עכו ,367 ,שמעון
7600 ,עכו ,238 ,שמעון לוזון
7600 ,עכו ,229 ,שני אליהו
7600 ,עכו ,608 ,שער ניקנור
7600 ,עכו ,431 ,שפירא
7600 ,עכו ,139 ,שקדיה
7600 ,עכו ,352 ,שרה לוי תנאי
7600 ,עכו ,418 ,ששת הימים
7600 ,עכו ,521 ,תחנת הרכבת
7600 ,עכו ,324 ,תחנת נסיונות
7600 ,עכו ,222 ,תכלת
7600 ,עכו ,652 ,תלמי
7600 ,עכו ,142 ,תפוח
1146 ,עלומים ,9000 ,עלומים
3765 ,עלי ,102 ,אודם
3765 ,עלי ,6507 ,אזור תעשייה
3765 ,עלי ,107 ,ברקת
3765 ,עלי ,108 ,גבעת הלבונה
3765 ,עלי ,111 ,דרך י"ד באלול
3765 ,עלי ,154 ,דרך עמיחי
3765 ,עלי ,101 ,האגוז
3765 ,עלי ,103 ,האורן
3765 ,עלי ,104 ,האלה
3765 ,עלי ,105 ,האלון
3765 ,עלי ,106 ,הארז
3765 ,עלי ,109 ,הדובדבן
3765 ,עלי ,110 ,הדולב
3765 ,עלי ,144 ,ההרדוף
3765 ,עלי ,112 ,הורד
3765 ,עלי ,113 ,החבצלת
3765 ,עלי ,152 ,החורש
3765 ,עלי ,114 ,החצב
3765 ,עלי ,115 ,החרוב
3765 ,עלי ,116 ,היסמין
3765 ,עלי ,117 ,היערה
3765 ,עלי ,118 ,היקינטון
3765 ,עלי ,147 ,הירדן
3765 ,עלי ,119 ,הכינור
3765 ,עלי ,120 ,הכלנית
3765 ,עלי ,121 ,הכרכום
3765 ,עלי ,123 ,הלילך
3765 ,עלי ,146 ,המוריה
3765 ,עלי ,125 ,המרגנית
3765 ,עלי ,126 ,הנבל
3765 ,עלי ,127 ,הנורית
3765 ,עלי ,128 ,הנרקיס
3765 ,עלי ,129 ,הסביון
3765 ,עלי ,130 ,הסייפן
3765 ,עלי ,132 ,העשור
3765 ,עלי ,134 ,הפעמון
3765 ,עלי ,135 ,הפרג
3765 ,עלי ,136 ,הצבעוני
3765 ,עלי ,137 ,הצפצפה
3765 ,עלי ,145 ,הר ציון
3765 ,עלי ,138 ,הרותם
3765 ,עלי ,139 ,הרקפת
3765 ,עלי ,142 ,השקד
3765 ,עלי ,143 ,השקמה
3765 ,עלי ,150 ,יהלום
3765 ,עלי ,122 ,לוע הארי
3765 ,עלי ,124 ,לשם
3765 ,עלי ,153 ,מבצע הראל
3765 ,עלי ,148 ,מבצע נחשון
3765 ,עלי ,6502 ,נאות אילן
3765 ,עלי ,6501 ,נאות חן
3765 ,עלי ,6503 ,נוה שהם
3765 ,עלי ,6500 ,נוה שיר
3765 ,עלי ,6504 ,נוף הרים
3765 ,עלי ,131 ,ספיר
3765 ,עלי ,9000 ,עלי
3765 ,עלי ,133 ,פטדה
3765 ,עלי ,140 ,שד עלי הכהן
3765 ,עלי ,141 ,שהם
3765 ,עלי ,6508 ,שכ היובל
3765 ,עלי ,6506 ,שכ ט
3765 ,עלי ,6509 ,שכ פלגי מים
3765 ,עלי ,6505 ,שכ שונה
3765 ,עלי ,149 ,שניר
3765 ,עלי ,151 ,תרשיש
3727 ,עלי זהב ,102 ,אודם
3727 ,עלי זהב ,101 ,אזמרגד
3727 ,עלי זהב ,303 ,אלה
3727 ,עלי זהב ,313 ,אלון
3727 ,עלי זהב ,110 ,אלמוג
3727 ,עלי זהב ,301 ,ארז
3727 ,עלי זהב ,317 ,אשחר
3727 ,עלי זהב ,316 ,ברוש
3727 ,עלי זהב ,105 ,ברקת
3727 ,עלי זהב ,103 ,גביש
3727 ,עלי זהב ,310 ,גפן
3727 ,עלי זהב ,309 ,דובדבן
3727 ,עלי זהב ,304 ,דולב
3727 ,עלי זהב ,315 ,דפנה
3727 ,עלי זהב ,308 ,הדס
3727 ,עלי זהב ,111 ,חושן
3727 ,עלי זהב ,106 ,יהלום
3727 ,עלי זהב ,307 ,לוטם
3727 ,עלי זהב ,104 ,ספיר
3727 ,עלי זהב ,9000 ,עלי זהב
3727 ,עלי זהב ,107 ,ענבר
3727 ,עלי זהב ,311 ,ערבה
3727 ,עלי זהב ,109 ,צור
3727 ,עלי זהב ,108 ,שוהם
3727 ,עלי זהב ,312 ,שקד
3727 ,עלי זהב ,305 ,שקמה
3727 ,עלי זהב ,314 ,תאנה
3727 ,עלי זהב ,306 ,תדהר
3727 ,עלי זהב ,302 ,תמר
688 ,עלמה ,9000 ,עלמה
3715 ,עלמון ,104 ,אבני חושן
3715 ,עלמון ,113 ,אייל אציל
3715 ,עלמון ,105 ,אשבול
3715 ,עלמון ,102 ,האומנים
3715 ,עלמון ,101 ,כנפי רוח
3715 ,עלמון ,116 ,מדבר יהודה
3715 ,עלמון ,117 ,מדבר סיני
3715 ,עלמון ,118 ,מדבר פראן
3715 ,עלמון ,110 ,מורד נחל פרת
3715 ,עלמון ,108 ,מעלה בנימין
3715 ,עלמון ,106 ,נואית
3715 ,עלמון ,111 ,נחל אוג
3715 ,עלמון ,109 ,נחל קדרון
3715 ,עלמון ,115 ,נמר המדבר
3715 ,עלמון ,107 ,עירית
3715 ,עלמון ,9000 ,עלמון
3715 ,עלמון ,114 ,פסמון המדבר
3715 ,עלמון ,103 ,צורית
3715 ,עלמון ,112 ,קוצן זהוב
1212 ,עמוקה ,9000 ,עמוקה
3824 ,עמיחי ,106 ,אשכול
3824 ,עמיחי ,104 ,גפן
3824 ,עמיחי ,105 ,זמורה
3824 ,עמיחי ,107 ,כנה
3824 ,עמיחי ,101 ,כרם
3824 ,עמיחי ,102 ,סמדר
3824 ,עמיחי ,9000 ,עמיחי
3824 ,עמיחי ,103 ,ענב
3824 ,עמיחי ,108 ,שורק
779 ,עמינדב ,102 ,אלון
779 ,עמינדב ,101 ,ארז
779 ,עמינדב ,108 ,האגוז
779 ,עמינדב ,115 ,האגס
779 ,עמינדב ,104 ,הברוש
779 ,עמינדב ,111 ,הגפן
779 ,עמינדב ,107 ,הדקל
779 ,עמינדב ,109 ,ההדס
779 ,עמינדב ,113 ,הזית
779 ,עמינדב ,106 ,החרוב
779 ,עמינדב ,110 ,הערבה
779 ,עמינדב ,114 ,הרימון
779 ,עמינדב ,103 ,השקד
779 ,עמינדב ,112 ,התאנה
779 ,עמינדב ,105 ,התמר
779 ,עמינדב ,116 ,התפוח
779 ,עמינדב ,9000 ,עמינדב
385 ,עמיעד ,9000 ,עמיעד
318 ,עמיעוז ,9000 ,עמיעוז
773 ,עמיקם ,102 ,הכלנית
773 ,עמיקם ,103 ,הנורית
773 ,עמיקם ,104 ,הנרקיס
773 ,עמיקם ,105 ,הצבעוני
773 ,עמיקם ,101 ,הרקפת
773 ,עמיקם ,9000 ,עמיקם
319 ,עמיר ,101 ,בית ראשון במולדת
319 ,עמיר ,9000 ,עמיר
3660 ,עמנואל ,121 ,אדמורי אלכסנדר
3660 ,עמנואל ,107 ,אהלי יעקב
3660 ,עמנואל ,201 ,אורן
3660 ,עמנואל ,114 ,אילת השחר
3660 ,עמנואל ,116 ,איש מצליח
3660 ,עמנואל ,130 ,אלישע
3660 ,עמנואל ,202 ,ארז
3660 ,עמנואל ,112 ,אתר יבנה
3660 ,עמנואל ,101 ,בית ישראל
3660 ,עמנואל ,120 ,בעל התניא
3660 ,עמנואל ,203 ,ברוש
3660 ,עמנואל ,206 ,ברזל
3660 ,עמנואל ,134 ,דבורה הנביאה
3660 ,עמנואל ,109 ,דרך יוסף הצדיק
3660 ,עמנואל ,105 ,הבעל שם טוב
3660 ,עמנואל ,104 ,הגאון מוילנה
3660 ,עמנואל ,108 ,החוזה מלובלין
3660 ,עמנואל ,204 ,התעשיה
3660 ,עמנואל ,129 ,חבקוק
3660 ,עמנואל ,117 ,חזון עובדיה
3660 ,עמנואל ,103 ,חת"ם סופר
3660 ,עמנואל ,126 ,יואל
3660 ,עמנואל ,125 ,יחזקאל
3660 ,עמנואל ,207 ,יסמין
3660 ,עמנואל ,106 ,לוין פנחס
3660 ,עמנואל ,102 ,מאיר מפרמשלאן
3660 ,עמנואל ,127 ,מיכה
3660 ,עמנואל ,132 ,מלאכי
3660 ,עמנואל ,133 ,מרים הנביאה
3660 ,עמנואל ,208 ,מתכת
3660 ,עמנואל ,128 ,נחום
3660 ,עמנואל ,111 ,נתיב אור החיים
3660 ,עמנואל ,119 ,נתיב חפץ חיים
3660 ,עמנואל ,110 ,נתיב רבבות אפרים
3660 ,עמנואל ,123 ,נתיב שתילי זיתים
3660 ,עמנואל ,113 ,נתיבות שלום
3660 ,עמנואל ,205 ,סלע
3660 ,עמנואל ,9000 ,עמנואל
3660 ,עמנואל ,131 ,צפניה
3660 ,עמנואל ,118 ,רבי ישראל אבוחצירא
3660 ,עמנואל ,115 ,שבט הלוי
3660 ,עמנואל ,122 ,שדרות חיי שלום
3660 ,עמנואל ,124 ,שמואל
708 ,עמקה ,108 ,אעלה בתמר
708 ,עמקה ,107 ,גן ירק
708 ,עמקה ,110 ,דוכיפת
708 ,עמקה ,104 ,האורנים
708 ,עמקה ,103 ,האר"י
708 ,עמקה ,105 ,הזית
708 ,עמקה ,102 ,הכרם
708 ,עמקה ,109 ,הנחל
708 ,עמקה ,101 ,כנפי נשרים
708 ,עמקה ,111 ,נוף הים
708 ,עמקה ,9000 ,עמקה
3712 ,ענב ,9000 ,ענב
3712 ,ענב ,6500 ,שכ הגפן
534 ,עספיא ,325 ,א-דואלי
534 ,עספיא ,240 ,א-דיואן
534 ,עספיא ,329 ,א-דרדאר
534 ,עספיא ,377 ,א-זיראעה
534 ,עספיא ,197 ,א-זית
534 ,עספיא ,228 ,א-זנבק
534 ,עספיא ,182 ,א-זעפראן
534 ,עספיא ,315 ,א-זערור
534 ,עספיא ,321 ,א-זעתר
534 ,עספיא ,298 ,א-טאבון
534 ,עספיא ,388 ,א-טביב
534 ,עספיא ,297 ,א-טיון
534 ,עספיא ,341 ,א-לובלאב
534 ,עספיא ,290 ,א-לוז
534 ,עספיא ,344 ,א-לולאב
534 ,עספיא ,356 ,א-לימון
534 ,עספיא ,192 ,א-לירה
534 ,עספיא ,348 ,א-נאדי
534 ,עספיא ,373 ,א-נבאק
534 ,עספיא ,269 ,א-נהדה
534 ,עספיא ,349 ,א-נזאזה
534 ,עספיא ,261 ,א-נח'וה
534 ,עספיא ,169 ,א-נח'יל
534 ,עספיא ,239 ,א-סבאר
534 ,עספיא ,398 ,א-סג'רה
534 ,עספיא ,253 ,א-סדף
534 ,עספיא ,204 ,א-סהיל
534 ,עספיא ,180 ,א-סומאק
534 ,עספיא ,226 ,א-סופסאף
534 ,עספיא ,292 ,א-סיאדה
534 ,עספיא ,296 ,א-סיכה
534 ,עספיא ,303 ,א-סלאם
534 ,עספיא ,177 ,א-סנאבל
534 ,עספיא ,327 ,א-סנאסל
534 ,עספיא ,170 ,א-סנדיאן
534 ,עספיא ,276 ,א-סנובר
534 ,עספיא ,211 ,א-ספאח
534 ,עספיא ,203 ,א-סקר
534 ,עספיא ,270 ,א-סראג'
534 ,עספיא ,167 ,א-סרו
534 ,עספיא ,330 ,א-סת ח'דרה
534 ,עספיא ,193 ,א-רביע
534 ,עספיא ,320 ,א-רומאן
534 ,עספיא ,293 ,א-רחאריח
534 ,עספיא ,168 ,א-ריחאן
534 ,עספיא ,354 ,א-רנדה
534 ,עספיא ,238 ,א-שועלה
534 ,עספיא ,278 ,א-שלאלה
534 ,עספיא ,264 ,א-תופאח
534 ,עספיא ,236 ,א-תין
534 ,עספיא ,353 ,א-תנוב
534 ,עספיא ,393 ,אבו דרע
534 ,עספיא ,291 ,אום א-דרג'
534 ,עספיא ,194 ,אום עדוס
534 ,עספיא ,6747 ,אזור בי"ס רונסון
534 ,עספיא ,6727 ,אזור בתי הקברות
534 ,עספיא ,6737 ,אזור הכנסיה המרונית
534 ,עספיא ,6738 ,אזור המועצה
534 ,עספיא ,6735 ,אזור המתנ"ס
534 ,עספיא ,6736 ,אזור חטיבת ביניים
534 ,עספיא ,346 ,אכליל אל-ג'בל
534 ,עספיא ,365 ,אל-אדחא
534 ,עספיא ,302 ,אל-אוח'ווה
534 ,עספיא ,246 ,אל-אונטוש
534 ,עספיא ,267 ,אל-אחראש
534 ,עספיא ,260 ,אל-אמל
534 ,עספיא ,295 ,אל-אעדאדייה
534 ,עספיא ,369 ,אל-אפאק
534 ,עספיא ,367 ,אל-ארבעין
534 ,עספיא ,343 ,אל-ארג'ואן
534 ,עספיא ,383 ,אל-ארז
534 ,עספיא ,363 ,אל-באחית'
534 ,עספיא ,375 ,אל-בוטום
534 ,עספיא ,382 ,אל-ביאדה
534 ,עספיא ,305 ,אל-ביאדר
534 ,עספיא ,317 ,אל-בידאיה
534 ,עספיא ,396 ,אל-בידר
534 ,עספיא ,385 ,אל-בישארה
534 ,עספיא ,179 ,אל-בלאן
534 ,עספיא ,332 ,אל-בלוט
534 ,עספיא ,176 ,אל-בסאתין
534 ,עספיא ,201 ,אל-ברבור
534 ,עספיא ,245 ,אל-ברטעייה
534 ,עספיא ,244 ,אל-ברכה
534 ,עספיא ,379 ,אל-ברנס
534 ,עספיא ,235 ,אל-ברקוק
534 ,עספיא ,243 ,אל-ג'אמעה
534 ,עספיא ,280 ,אל-ג'וד
534 ,עספיא ,301 ,אל-ג'וז
534 ,עספיא ,183 ,אל-ג'ומיז
534 ,עספיא ,247 ,אל-ג'ורין
534 ,עספיא ,360 ,אל-ג'רף
534 ,עספיא ,371 ,אל-הדארה
534 ,עספיא ,254 ,אל-הושאני
534 ,עספיא ,208 ,אל-הינדבא
534 ,עספיא ,263 ,אל-הנבל
534 ,עספיא ,352 ,אל-הרבג'
534 ,עספיא ,283 ,אל-ואדי
534 ,עספיא ,185 ,אל-זיתון
534 ,עספיא ,237 ,אל-ח'ורפיש
534 ,עספיא ,161 ,אל-ח'זאן
534 ,עספיא ,202 ,אל-ח'ירבה א-שרקייה
534 ,עספיא ,335 ,אל-ח'לאיל
534 ,עספיא ,340 ,אל-ח'לווה
534 ,עספיא ,220 ,אל-ח'נדק
534 ,עספיא ,250 ,אל-ח'רוב
534 ,עספיא ,331 ,אל-חג'ל
534 ,עספיא ,221 ,אל-חואקיר
534 ,עספיא ,232 ,אל-חופחאף
534 ,עספיא ,256 ,אל-חור
534 ,עספיא ,366 ,אל-חורייה
534 ,עספיא ,164 ,אל-חיכמה
534 ,עספיא ,196 ,אל-חיסאד
534 ,עספיא ,178 ,אל-חיסאדה
534 ,עספיא ,347 ,אל-חנטל
534 ,עספיא ,342 ,אל-כורכום
534 ,עספיא ,399 ,אל-כותאן
534 ,עספיא ,262 ,אל-כינא
534 ,עספיא ,184 ,אל-כנז
534 ,עספיא ,248 ,אל-כניסה
534 ,עספיא ,109 ,אל-כסרה
534 ,עספיא ,355 ,אל-כרז
534 ,עספיא ,287 ,אל-כרם
534 ,עספיא ,390 ,אל-כרמל
534 ,עספיא ,307 ,אל-מדארס
534 ,עספיא ,359 ,אל-מוחרקה
534 ,עספיא ,186 ,אל-מונג'ל
534 ,עספיא ,265 ,אל-מוסתקי
534 ,עספיא ,289 ,אל-מועלם
534 ,עספיא ,311 ,אל-מור'ור
534 ,עספיא ,218 ,אל-מזאר
534 ,עספיא ,214 ,אל-מזרעה
534 ,עספיא ,198 ,אל-מחג'ר
534 ,עספיא ,387 ,אל-מחראת'
534 ,עספיא ,313 ,אל-מטאמיר
534 ,עספיא ,326 ,אל-מטחנה
534 ,עספיא ,389 ,אל-מילאד
534 ,עספיא ,300 ,אל-מנאר
534 ,עספיא ,251 ,אל-מנסיג'
534 ,עספיא ,171 ,אל-מערפה
534 ,עספיא ,391 ,אל-מפארק
534 ,עספיא ,225 ,אל-מקאזח
534 ,עספיא ,337 ,אל-מקתלה
534 ,עספיא ,384 ,אל-מראח
534 ,עספיא ,281 ,אל-מראעי
534 ,עספיא ,351 ,אל-מרג'
534 ,עספיא ,308 ,אל-משחרה
534 ,עספיא ,386 ,אל-משריק
534 ,עספיא ,230 ,אל-עאיק
534 ,עספיא ,374 ,אל-עוליק
534 ,עספיא ,172 ,אל-עלת
534 ,עספיא ,336 ,אל-ענב
534 ,עספיא ,234 ,אל-עקוב
534 ,עספיא ,312 ,אל-פורן
534 ,עספיא ,324 ,אל-קאדי
534 ,עספיא ,380 ,אל-קוזחייה
534 ,עספיא ,227 ,אל-קורונפול
534 ,עספיא ,304 ,אל-קחואן
534 ,עספיא ,207 ,אל-קלעה
534 ,עספיא ,181 ,אל-קנדול
534 ,עספיא ,381 ,אל-קסב
534 ,עספיא ,319 ,אל-קרמוט
534 ,עספיא ,358 ,אל-ר'אר
534 ,עספיא ,376 ,אל-שומר
534 ,עספיא ,6730 ,אלרחאריח
534 ,עספיא ,189 ,אתרג'
534 ,עספיא ,205 ,באב אל-אעואג'
534 ,עספיא ,231 ,באבוניג'
534 ,עספיא ,271 ,באזילת
534 ,עספיא ,372 ,בילסאן
534 ,עספיא ,6720 ,בית אבות נוצרי
534 ,עספיא ,273 ,בני מערוף
534 ,עספיא ,6703 ,בתי הספר היסודיים
534 ,עספיא ,6714 ,גרעין הכפר
534 ,עספיא ,6716 ,המעיין
534 ,עספיא ,6723 ,השכונה המערבית
534 ,עספיא ,102 ,ואדי א-שעיר
534 ,עספיא ,206 ,זהרת א-רביע
534 ,עספיא ,6740 ,זוחלוק
534 ,עספיא ,338 ,זנג'ביל
534 ,עספיא ,306 ,זקאק א-דיר
534 ,עספיא ,370 ,ח'וביזה
534 ,עספיא ,339 ,ח'לת אל-עמיקה
534 ,עספיא ,209 ,ח'לת סינג'אב
534 ,עספיא ,378 ,ח'לת סלאמה
534 ,עספיא ,316 ,ח'לת סעד
534 ,עספיא ,175 ,ח'לת עביד
534 ,עספיא ,272 ,ח'לת שריף
534 ,עספיא ,242 ,חיטין
534 ,עספיא ,6733 ,חיילים משוחררים א'
534 ,עספיא ,6731 ,חיילים משוחררים ב'
534 ,עספיא ,6743 ,טהרת אלנמלה ב לאומי
534 ,עספיא ,6744 ,טהרת אלנמלה ב קדמני
534 ,עספיא ,188 ,טלעת א-רג'מה
534 ,עספיא ,147 ,טריק א-דרנו
534 ,עספיא ,149 ,טריק אל-באבור
534 ,עספיא ,322 ,טריק אל-מסיל
534 ,עספיא ,6712 ,כיכר השכונה המזרחית
534 ,עספיא ,328 ,כפת א-שיח'
534 ,עספיא ,350 ,למניבר
534 ,עספיא ,6713 ,מגדל המים הישן
534 ,עספיא ,6717 ,מעל הכביש הראשי
534 ,עספיא ,229 ,מעסרת אל-עד'אם
534 ,עספיא ,345 ,מראמייה
534 ,עספיא ,318 ,מרג' א-נרג'ס
534 ,עספיא ,6748 ,משפחת אלשיח
534 ,עספיא ,6710 ,משפחת טרביה
534 ,עספיא ,6725 ,משפחת מנצור
534 ,עספיא ,199 ,נזאזת א-זית
534 ,עספיא ,173 ,סאנט
534 ,עספיא ,6732 ,סטלה כרמל
534 ,עספיא ,6734 ,סידנא אבו עבדאללה
534 ,עספיא ,255 ,סלמאן אל-פארסי
534 ,עספיא ,397 ,סלמנדרה
534 ,עספיא ,210 ,סניא
534 ,עספיא ,241 ,עוסוף
534 ,עספיא ,275 ,עוש ר'וראב
534 ,עספיא ,368 ,עין אל-בידה
534 ,עספיא ,284 ,עין אל-ג'וז
534 ,עספיא ,111 ,עין אל-חאיק
534 ,עספיא ,285 ,עין אל-עלק
534 ,עספיא ,257 ,עין שרף
534 ,עספיא ,9000 ,עספיא
534 ,עספיא ,279 ,עתאן
534 ,עספיא ,187 ,פיג'אן
534 ,עספיא ,357 ,קורידה
534 ,עספיא ,190 ,קורסענה
534 ,עספיא ,6745 ,רבעון עבוד
534 ,עספיא ,323 ,רובעאן עבוד
534 ,עספיא ,103 ,רח 11
534 ,עספיא ,148 ,רח 21
534 ,עספיא ,112 ,רח 31
534 ,עספיא ,131 ,רח 2
534 ,עספיא ,104 ,רח 12
534 ,עספיא ,113 ,רח 32
534 ,עספיא ,132 ,רח 3
534 ,עספיא ,105 ,רח 13
534 ,עספיא ,150 ,רח 23
534 ,עספיא ,114 ,רח 33
534 ,עספיא ,133 ,רח 4
534 ,עספיא ,106 ,רח 14
534 ,עספיא ,160 ,רח 24
534 ,עספיא ,115 ,רח 34
534 ,עספיא ,134 ,רח 5
534 ,עספיא ,107 ,רח 15
534 ,עספיא ,116 ,רח 35
534 ,עספיא ,135 ,רח 6
534 ,עספיא ,108 ,רח 16
534 ,עספיא ,162 ,רח 26
534 ,עספיא ,136 ,רח 7
534 ,עספיא ,163 ,רח 27
534 ,עספיא ,137 ,רח 8
534 ,עספיא ,110 ,רח 18
534 ,עספיא ,101 ,רח 9
534 ,עספיא ,146 ,רח 19
534 ,עספיא ,166 ,רח 29
534 ,עספיא ,6715 ,ש מזרחית מעל המעיין
534 ,עספיא ,213 ,שארע אל-כרום
534 ,עספיא ,174 ,שארע דהרת א-נימלה
534 ,עספיא ,233 ,שארע זוחלוק
534 ,עספיא ,249 ,שארע ח'לת חמדאן
534 ,עספיא ,165 ,שד אבא חושי
534 ,עספיא ,6726 ,שכ אל-כרום
534 ,עספיא ,6741 ,שכ אלבאבור
534 ,עספיא ,6739 ,שכ אלדרנו
534 ,עספיא ,6749 ,שכ אלמסיל
534 ,עספיא ,6722 ,שכ הבדואים המערבית
534 ,עספיא ,6512 ,שכ הזיתים
534 ,עספיא ,6724 ,שכ ח'לת חמדאן
534 ,עספיא ,6511 ,שכ מזרחית
534 ,עספיא ,6721 ,שכונה נוצרית מערבית
534 ,עספיא ,395 ,תג'דידאת עסאף
534 ,עספיא ,6746 ,תחנת המשטרה
534 ,עספיא ,268 ,תל א-נדא
7700 ,עפולה ,103 ,אבן גבירול
7700 ,עפולה ,136 ,אבן עזרא
7700 ,עפולה ,326 ,אגוז
7700 ,עפולה ,530 ,אגמון
7700 ,עפולה ,183 ,אגמיה
7700 ,עפולה ,545 ,אדמונית
7700 ,עפולה ,542 ,אדר
7700 ,עפולה ,218 ,אהבת ישראל
7700 ,עפולה ,321 ,אודם
7700 ,עפולה ,428 ,אומן
7700 ,עפולה ,345 ,אוסישקין
7700 ,עפולה ,453 ,אורן
7700 ,עפולה ,6521 ,אז תעש עפולה עילית
7700 ,עפולה ,293 ,אזוב
7700 ,עפולה ,6522 ,אזור תעשיה א'
7700 ,עפולה ,6523 ,אזור תעשיה ב'
7700 ,עפולה ,252 ,אחד העם
7700 ,עפולה ,635 ,אחירותם
7700 ,עפולה ,513 ,אטד
7700 ,עפולה ,307 ,איינשטיין
7700 ,עפולה ,541 ,אירוס
7700 ,עפולה ,375 ,אל על
7700 ,עפולה ,102 ,אלבז נתן
7700 ,עפולה ,134 ,אלתרמן
7700 ,עפולה ,537 ,אמנון ותמר
7700 ,עפולה ,6500 ,אסבסטונ עלית
7700 ,עפולה ,369 ,אסיף
7700 ,עפולה ,128 ,אפרים
7700 ,עפולה ,269 ,אפשטיין
7700 ,עפולה ,533 ,אקליפטוס
7700 ,עפולה ,389 ,ארבע האמהות
7700 ,עפולה ,472 ,אריאל שרון
7700 ,עפולה ,644 ,אשחר
7700 ,עפולה ,529 ,אשל
7700 ,עפולה ,399 ,אתר תרצה
7700 ,עפולה ,448 ,בגין עליזה
7700 ,עפולה ,249 ,בורוכוב
7700 ,עפולה ,202 ,ביאליק
7700 ,עפולה ,351 ,ביל"ו
7700 ,עפולה ,391 ,בית אבות משלב
7700 ,עפולה ,329 ,בית וגן
7700 ,עפולה ,205 ,בן יהודה
7700 ,עפולה ,169 ,בן צבי יצחק
7700 ,עפולה ,632 ,בני ברית
7700 ,עפולה ,625 ,בני דן
7700 ,עפולה ,633 ,בני ישראל
7700 ,עפולה ,626 ,בני עקיבא
7700 ,עפולה ,627 ,בני ציון
7700 ,עפולה ,368 ,בציר
7700 ,עפולה ,628 ,בר גיורא
7700 ,עפולה ,377 ,בר כוכבא
7700 ,עפולה ,653 ,בר לב חיים
7700 ,עפולה ,331 ,ברזילאי יוסף
7700 ,עפולה ,337 ,ברכאן דב
7700 ,עפולה ,447 ,ברנר
7700 ,עפולה ,362 ,ברקת
7700 ,עפולה ,454 ,בשמת
7700 ,עפולה ,207 ,בשן
7700 ,עפולה ,225 ,גבורי סיני
7700 ,עפולה ,113 ,גבעתי
7700 ,עפולה ,418 ,גולדברג לאה
7700 ,עפולה ,234 ,גולומב אליהו
7700 ,עפולה ,208 ,גולן
7700 ,עפולה ,651 ,גור מרדכי
7700 ,עפולה ,254 ,גורדון
7700 ,עפולה ,222 ,גוש עציון
7700 ,עפולה ,214 ,גלעד
7700 ,עפולה ,554 ,גפן
7700 ,עפולה ,645 ,דבורנית
7700 ,עפולה ,531 ,דובדבן
7700 ,עפולה ,450 ,דובנוב יואש
7700 ,עפולה ,281 ,דוד אלעזר
7700 ,עפולה ,240 ,דורי יעקב
7700 ,עפולה ,605 ,דליות
7700 ,עפולה ,420 ,דמארי שושנה
7700 ,עפולה ,538 ,דפנה
7700 ,עפולה ,364 ,דרך בן גוריון
7700 ,עפולה ,280 ,דרך גולני
7700 ,עפולה ,231 ,האבות
7700 ,עפולה ,305 ,האביב
7700 ,עפולה ,410 ,האגס
7700 ,עפולה ,372 ,האופק
7700 ,עפולה ,276 ,האורג
7700 ,עפולה ,311 ,האורנים
7700 ,עפולה ,172 ,האירוסים
7700 ,עפולה ,503 ,האלה
7700 ,עפולה ,501 ,האלון
7700 ,עפולה ,228 ,האמוראים
7700 ,עפולה ,502 ,הארז
7700 ,עפולה ,313 ,האתרוג
7700 ,עפולה ,427 ,הבוכרים
7700 ,עפולה ,316 ,הבונה
7700 ,עפולה ,430 ,הבנאי
7700 ,עפולה ,338 ,הבנים
7700 ,עפולה ,419 ,הבעל שם טוב
7700 ,עפולה ,506 ,הברוש
7700 ,עפולה ,463 ,הברזל
7700 ,עפולה ,117 ,הבריגדה העברית
7700 ,עפולה ,242 ,הגאונים
7700 ,עפולה ,379 ,הגדוד העברי
7700 ,עפולה ,282 ,הגודד
7700 ,עפולה ,514 ,הגומא
7700 ,עפולה ,634 ,הגיא
7700 ,עפולה ,322 ,הגלבוע
7700 ,עפולה ,209 ,הגליל
7700 ,עפולה ,306 ,הגן
7700 ,עפולה ,636 ,הגת
7700 ,עפולה ,303 ,הדגן
7700 ,עפולה ,191 ,הדוכיפת
7700 ,עפולה ,504 ,הדולב
7700 ,עפולה ,451 ,הדס
7700 ,עפולה ,508 ,הדקל
7700 ,עפולה ,413 ,הדרור
7700 ,עפולה ,226 ,ההגנה
7700 ,עפולה ,308 ,ההדסים
7700 ,עפולה ,312 ,ההדרים
7700 ,עפולה ,301 ,ההסתדרות
7700 ,עפולה ,392 ,הוז זלמן
7700 ,עפולה ,332 ,הולנד
7700 ,עפולה ,516 ,הורד
7700 ,עפולה ,190 ,הזהבן
7700 ,עפולה ,195 ,הזמיר
7700 ,עפולה ,468 ,החדשנות
7700 ,עפולה ,187 ,החוחית
7700 ,עפולה ,543 ,החורש
7700 ,עפולה ,318 ,החלוץ
7700 ,עפולה ,547 ,החמניה
7700 ,עפולה ,181 ,החסידה
7700 ,עפולה ,239 ,החסידים
7700 ,עפולה ,175 ,החצב
7700 ,עפולה ,278 ,החרט
7700 ,עפולה ,424 ,החרמש
7700 ,עפולה ,178 ,החרצית
7700 ,עפולה ,241 ,החשמונאים
7700 ,עפולה ,431 ,החשמל
7700 ,עפולה ,188 ,הטבלן
7700 ,עפולה ,432 ,היוגב
7700 ,עפולה ,465 ,היזמות
7700 ,עפולה ,619 ,היצירה
7700 ,עפולה ,198 ,הירקון
7700 ,עפולה ,433 ,הכורם
7700 ,עפולה ,403 ,הכלנית
7700 ,עפולה ,344 ,הכנסת
7700 ,עפולה ,352 ,הכשרת הישוב
7700 ,עפולה ,186 ,הליבנית
7700 ,עפולה ,423 ,המגל
7700 ,עפולה ,315 ,המגן
7700 ,עפולה ,461 ,המדע
7700 ,עפולה ,434 ,המחשלים
7700 ,עפולה ,414 ,המייסדים
7700 ,עפולה ,488 ,המכבים
7700 ,עפולה ,422 ,המלאכה
7700 ,עפולה ,394 ,המלכה אסתר
7700 ,עפולה ,229 ,המלכים
7700 ,עפולה ,279 ,המסגר
7700 ,עפולה ,607 ,המסיק
7700 ,עפולה ,393 ,הנביאה דבורה
7700 ,עפולה ,230 ,הנביאים
7700 ,עפולה ,129 ,הנגר
7700 ,עפולה ,374 ,הנוטר
7700 ,עפולה ,402 ,הנופר
7700 ,עפולה ,173 ,הנורית
7700 ,עפולה ,341 ,הנשיא וייצמן
7700 ,עפולה ,171 ,הסביון
7700 ,עפולה ,425 ,הסדן
7700 ,עפולה ,349 ,הסוכנות היהודית
7700 ,עפולה ,245 ,הסופרים
7700 ,עפולה ,180 ,הסחלב
7700 ,עפולה ,174 ,הסייפן
7700 ,עפולה ,177 ,הסיתוונית
7700 ,עפולה ,133 ,הספורטק
7700 ,עפולה ,304 ,העבודה
7700 ,עפולה ,138 ,העולים
7700 ,עפולה ,343 ,העליה
7700 ,עפולה ,277 ,העמל
7700 ,עפולה ,193 ,העפרוני
7700 ,עפולה ,243 ,הפייטנים
7700 ,עפולה ,464 ,הפלדה
7700 ,עפולה ,262 ,הפלמ"ח
7700 ,עפולה ,509 ,הצאלון
7700 ,עפולה ,189 ,הצוקית
7700 ,עפולה ,489 ,הצורף
7700 ,עפולה ,471 ,הציונות
7700 ,עפולה ,206 ,הצנחנים
7700 ,עפולה ,309 ,הצפצפות
7700 ,עפולה ,108 ,הקוממיות
7700 ,עפולה ,355 ,הקונגרס הציוני
7700 ,עפולה ,466 ,הקידמה
7700 ,עפולה ,373 ,הקישון
7700 ,עפולה ,244 ,הקנאים
7700 ,עפולה ,317 ,הקשת
7700 ,עפולה ,408 ,הראשונים
7700 ,עפולה ,561 ,הרב דב הכהן קוק
7700 ,עפולה ,217 ,הרב יוסף חיים
7700 ,עפולה ,367 ,הרב לוין
7700 ,עפולה ,256 ,הרב ניסים אהרון
7700 ,עפולה ,270 ,הרב עמיאל
7700 ,עפולה ,300 ,הרב ציוני פרץ
7700 ,עפולה ,219 ,הרב קוק
7700 ,עפולה ,342 ,הרב קירשטיין אשר
7700 ,עפולה ,257 ,הרב רצון דוד
7700 ,עפולה ,417 ,הרב שיטרית יהודה
7700 ,עפולה ,564 ,הרבי מלובביץ'
7700 ,עפולה ,515 ,הרדוף
7700 ,עפולה ,221 ,הרוגי מלכות
7700 ,עפולה ,310 ,הרימון
7700 ,עפולה ,620 ,הרסן
7700 ,עפולה ,107 ,הרצוג
7700 ,עפולה ,201 ,הרצל
7700 ,עפולה ,404 ,הרקפת
7700 ,עפולה ,357 ,הרשב"י
7700 ,עפולה ,388 ,השומר
7700 ,עפולה ,127 ,השופטים
7700 ,עפולה ,192 ,השחף
7700 ,עפולה ,314 ,השחר
7700 ,עפולה ,194 ,השלדג
7700 ,עפולה ,125 ,השלום
7700 ,עפולה ,505 ,השקד
7700 ,עפולה ,507 ,השקמה
7700 ,עפולה ,185 ,השקנאי
7700 ,עפולה ,435 ,השרטט
7700 ,עפולה ,462 ,התוכנה
7700 ,עפולה ,227 ,התנאים
7700 ,עפולה ,275 ,התעשיה
7700 ,עפולה ,126 ,התקוה
7700 ,עפולה ,455 ,התקומה
7700 ,עפולה ,436 ,וולך יונה
7700 ,עפולה ,366 ,וולפסון
7700 ,עפולה ,237 ,וינגייט
7700 ,עפולה ,336 ,ורדה
7700 ,עפולה ,112 ,ז'בוטינסקי
7700 ,עפולה ,556 ,זית
7700 ,עפולה ,439 ,חביבה רייך
7700 ,עפולה ,440 ,חבצלת
7700 ,עפולה ,130 ,חברון
7700 ,עפולה ,199 ,חופית
7700 ,עפולה ,354 ,חות הלמוד
7700 ,עפולה ,302 ,חטיבה תשע
7700 ,עפולה ,395 ,חטיבת כפיר
7700 ,עפולה ,182 ,חטפית
7700 ,עפולה ,347 ,חיבת ציון
7700 ,עפולה ,552 ,חיטה
7700 ,עפולה ,294 ,חיננית
7700 ,עפולה ,179 ,חלמונית
7700 ,עפולה ,324 ,חנקין יהושע
7700 ,עפולה ,470 ,חפץ חיים
7700 ,עפולה ,441 ,חרוב
7700 ,עפולה ,426 ,חרוד
7700 ,עפולה ,371 ,חריש
7700 ,עפולה ,211 ,חרמון
7700 ,עפולה ,400 ,טבצ'ניק
7700 ,עפולה ,608 ,טבת
7700 ,עפולה ,646 ,טופח
7700 ,עפולה ,235 ,טרומפלדור
7700 ,עפולה ,109 ,טשרניחובסקי
7700 ,עפולה ,268 ,יאיר
7700 ,עפולה ,236 ,ידין יגאל
7700 ,עפולה ,248 ,יהודה הלוי
7700 ,עפולה ,363 ,יהלום
7700 ,עפולה ,382 ,יוספטל גיורא
7700 ,עפולה ,540 ,יסמין
7700 ,עפולה ,517 ,יערה
7700 ,עפולה ,250 ,יפה אברהם
7700 ,עפולה ,460 ,יצחק נבון
7700 ,עפולה ,560 ,יצחק שמיר
7700 ,עפולה ,609 ,יצפור
7700 ,עפולה ,296 ,יקינתון
7700 ,עפולה ,213 ,ירדן
7700 ,עפולה ,323 ,ירושלים
7700 ,עפולה ,437 ,ירקוני יפה
7700 ,עפולה ,170 ,כדורי
7700 ,עפולה ,166 ,כהן יגאל
7700 ,עפולה ,385 ,כורש
7700 ,עפולה ,297 ,כחלית
7700 ,עפולה ,6001 ,ככר העצמאות
7700 ,עפולה ,334 ,כליל החורש
7700 ,עפולה ,215 ,כנרת
7700 ,עפולה ,346 ,כפר הנער ניר העמק
7700 ,עפולה ,255 ,כצנלסון
7700 ,עפולה ,536 ,כרכום
7700 ,עפולה ,266 ,כרמלית
7700 ,עפולה ,442 ,לוטם
7700 ,עפולה ,452 ,לולב
7700 ,עפולה ,412 ,לח"י
7700 ,עפולה ,443 ,לילך
7700 ,עפולה ,652 ,לסקוב חיים
7700 ,עפולה ,396 ,לשם
7700 ,עפולה ,106 ,מאפו
7700 ,עפולה ,139 ,מגשימים
7700 ,עפולה ,562 ,מולגטו טסמה
7700 ,עפולה ,510 ,מורן
7700 ,עפולה ,137 ,מזרחי אפרים
7700 ,עפולה ,380 ,מלכין שרה
7700 ,עפולה ,131 ,מנשה
7700 ,עפולה ,223 ,מעפילים
7700 ,עפולה ,104 ,מצדה
7700 ,עפולה ,111 ,מצפה
7700 ,עפולה ,295 ,מקלף מרדכי
7700 ,עפולה ,406 ,מרבד הקסמים
7700 ,עפולה ,518 ,מרווה
7700 ,עפולה ,233 ,מרכוס דוד
7700 ,עפולה ,390 ,מרכז הקליטה
7700 ,עפולה ,548 ,משה בלנדר
7700 ,עפולה ,610 ,משושים
7700 ,עפולה ,387 ,משמר הירדן
7700 ,עפולה ,359 ,משק הפועלות
7700 ,עפולה ,421 ,נגבה
7700 ,עפולה ,339 ,נוה יעקב
7700 ,עפולה ,353 ,נורדאו
7700 ,עפולה ,416 ,ניל"י
7700 ,עפולה ,132 ,נפתלי
7700 ,עפולה ,638 ,נרקיסים
7700 ,עפולה ,534 ,סהרון
7700 ,עפולה ,101 ,סולד הנריטה
7700 ,עפולה ,247 ,סוקולוב
7700 ,עפולה ,528 ,סיגליות
7700 ,עפולה ,639 ,סלסילה
7700 ,עפולה ,184 ,סלעית
7700 ,עפולה ,105 ,סמילנסקי
7700 ,עפולה ,291 ,סמר
7700 ,עפולה ,415 ,סנש חנה
7700 ,עפולה ,356 ,ספיר
7700 ,עפולה ,197 ,עגור
7700 ,עפולה ,429 ,עגנון ש"י
7700 ,עפולה ,299 ,עדעד
7700 ,עפולה ,411 ,עומר
7700 ,עפולה ,121 ,עורבני
7700 ,עפולה ,549 ,עירית
7700 ,עפולה ,167 ,עלית הנוער
7700 ,עפולה ,550 ,עמנואל מורנו
7700 ,עפולה ,407 ,ענבל
7700 ,עפולה ,9000 ,עפולה
7700 ,עפולה ,6520 ,עפולה הצעירה
7700 ,עפולה ,6526 ,עפולה תחתית
7700 ,עפולה ,210 ,עצמון
7700 ,עפולה ,519 ,ערבה
7700 ,עפולה ,361 ,ערוגות
7700 ,עפולה ,511 ,ערער
7700 ,עפולה ,611 ,פולג
7700 ,עפולה ,629 ,פועלי ציון
7700 ,עפולה ,348 ,פיק"א
7700 ,עפולה ,532 ,פיקוס
7700 ,עפולה ,631 ,פל-ים
7700 ,עפולה ,204 ,פנקס צבי
7700 ,עפולה ,176 ,פעמונית
7700 ,עפולה ,444 ,פקן
7700 ,עפולה ,272 ,פרג
7700 ,עפולה ,469 ,פרדה יזזאו אקלום
7700 ,עפולה ,622 ,פרסה
7700 ,עפולה ,612 ,פרצים
7700 ,עפולה ,165 ,פשוש
7700 ,עפולה ,613 ,צאלים
7700 ,עפולה ,640 ,צבעוני
7700 ,עפולה ,520 ,צבר
7700 ,עפולה ,123 ,צופית
7700 ,עפולה ,381 ,צים
7700 ,עפולה ,614 ,צין
7700 ,עפולה ,615 ,ציפורי
7700 ,עפולה ,546 ,ציפורן
7700 ,עפולה ,616 ,צלמון
7700 ,עפולה ,521 ,צלף
7700 ,עפולה ,641 ,צמר
7700 ,עפולה ,350 ,קבוץ גלויות
7700 ,עפולה ,135 ,קדה
7700 ,עפולה ,224 ,קדושי השואה
7700 ,עפולה ,623 ,קדרון
7700 ,עפולה ,320 ,קהלת ציון
7700 ,עפולה ,168 ,קורצ'אק
7700 ,עפולה ,384 ,קטיף
7700 ,עפולה ,624 ,קלט
7700 ,עפולה ,292 ,קנה
7700 ,עפולה ,360 ,קניאל דוד
7700 ,עפולה ,325 ,קנמון יעקב
7700 ,עפולה ,251 ,קפלן
7700 ,עפולה ,370 ,קציר
7700 ,עפולה ,327 ,קרן היסוד
7700 ,עפולה ,246 ,קרן קימת לישראל
7700 ,עפולה ,164 ,ראסל
7700 ,עפולה ,456 ,רבי דוד עראמה
7700 ,עפולה ,563 ,רבי נחמן מברסלב
7700 ,עפולה ,6524 ,רובע יזרעאל
7700 ,עפולה ,539 ,רוזמרין
7700 ,עפולה ,438 ,רום יעל
7700 ,עפולה ,522 ,רותם
7700 ,עפולה ,637 ,ריכרד קאופמן
7700 ,עפולה ,220 ,רמב"ם
7700 ,עפולה ,203 ,רמז
7700 ,עפולה ,654 ,רפול
7700 ,עפולה ,216 ,רש"י
7700 ,עפולה ,6501 ,ש אקדמאים
7700 ,עפולה ,6502 ,ש בי"ח המרכזי
7700 ,עפולה ,6503 ,ש גאולים
7700 ,עפולה ,6504 ,ש גבעת המורה
7700 ,עפולה ,6519 ,ש דרוקר
7700 ,עפולה ,6505 ,ש הבוכרים
7700 ,עפולה ,6512 ,ש הפועלים
7700 ,עפולה ,6506 ,ש חסכון עלית
7700 ,עפולה ,6507 ,ש יוגוסלבים
7700 ,עפולה ,6508 ,ש יער
7700 ,עפולה ,6509 ,ש נחשון
7700 ,עפולה ,6510 ,ש עמידר
7700 ,עפולה ,6511 ,ש עממי עלית
7700 ,עפולה ,6513 ,ש רווקיה
7700 ,עפולה ,6514 ,ש רכבת
7700 ,עפולה ,6515 ,ש רסקו
7700 ,עפולה ,253 ,שבזי
7700 ,עפולה ,267 ,שבטי ישראל
7700 ,עפולה ,551 ,שבעת המינים
7700 ,עפולה ,333 ,שד ארלוזורוב
7700 ,עפולה ,630 ,שד בגין מנחם
7700 ,עפולה ,196 ,שד קונטיקט
7700 ,עפולה ,328 ,שד רבין יצחק
7700 ,עפולה ,449 ,שד רובע יזרעאל
7700 ,עפולה ,232 ,שדה יצחק
7700 ,עפולה ,122 ,שדמית
7700 ,עפולה ,358 ,שוהם
7700 ,עפולה ,617 ,שורק
7700 ,עפולה ,523 ,שושן
7700 ,עפולה ,535 ,שושנים
7700 ,עפולה ,409 ,שיבולים
7700 ,עפולה ,271 ,שיבת ציון
7700 ,עפולה ,512 ,שיזף
7700 ,עפולה ,524 ,שיטה
7700 ,עפולה ,383 ,שינבוים
7700 ,עפולה ,6518 ,שכ בנה ביתך
7700 ,עפולה ,6525 ,שכ נווה יוסף
7700 ,עפולה ,6516 ,שכ עלית
7700 ,עפולה ,6517 ,שכ רוממה
7700 ,עפולה ,642 ,שלהבית
7700 ,עפולה ,376 ,שלום עליכם
7700 ,עפולה ,238 ,שמחוני אסף
7700 ,עפולה ,398 ,שמר נעמי
7700 ,עפולה ,621 ,שמשון שחורי
7700 ,עפולה ,340 ,שניאור זלמן שז"ר
7700 ,עפולה ,553 ,שעורה
7700 ,עפולה ,110 ,שפינוזה
7700 ,עפולה ,335 ,שפירא מאיר
7700 ,עפולה ,319 ,שפרינצק
7700 ,עפולה ,386 ,שרת
7700 ,עפולה ,555 ,תאנה
7700 ,עפולה ,445 ,תאשור
7700 ,עפולה ,643 ,תגית
7700 ,עפולה ,446 ,תדהר
7700 ,עפולה ,298 ,תורמוס
7700 ,עפולה ,378 ,תל חי
7700 ,עפולה ,557 ,תמר
7700 ,עפולה ,397 ,תרשיש
917 ,עצמון שגב ,9000 ,עצמון שגב
531 ,עראבה ,247 ,אבו אלהיתם
531 ,עראבה ,234 ,אבו בכר
531 ,עראבה ,246 ,אבו הוריירה
531 ,עראבה ,456 ,אבו סלאמה
531 ,עראבה ,208 ,אבו עובידה
531 ,עראבה ,131 ,אבו קרעה
531 ,עראבה ,235 ,אבו תמאם
531 ,עראבה ,242 ,אבן אלנפיס
531 ,עראבה ,154 ,אבן חיאן
531 ,עראבה ,510 ,אבן סינא
531 ,עראבה ,184 ,אבן רושד
531 ,עראבה ,455 ,אבן תימיה
531 ,עראבה ,150 ,אבראהים אלח'ליל
531 ,עראבה ,129 ,אדריס אלנגדי
531 ,עראבה ,254 ,אום אלקורא
531 ,עראבה ,397 ,אחמד אלתופיק
531 ,עראבה ,485 ,אחמד בן בלא
531 ,עראבה ,492 ,אחסאן עבד אלקודס
531 ,עראבה ,495 ,איבן ח'לדון
531 ,עראבה ,494 ,איליא אבו מאדי
531 ,עראבה ,186 ,אל ג'וזלאן
531 ,עראבה ,162 ,אל-חוסון
531 ,עראבה ,383 ,אל-חוסן
531 ,עראבה ,446 ,אל-לד
531 ,עראבה ,337 ,אלאבראר
531 ,עראבה ,315 ,אלאג'דאד
531 ,עראבה ,323 ,אלאהראם
531 ,עראבה ,158 ,אלאומם
531 ,עראבה ,340 ,אלאזהאר
531 ,עראבה ,312 ,אלאח'אא
531 ,עראבה ,216 ,אלאח'טל
531 ,עראבה ,140 ,אלאחראר
531 ,עראבה ,321 ,אלאימאן
531 ,עראבה ,188 ,אלאכלאס
531 ,עראבה ,475 ,אלאמגאד
531 ,עראבה ,286 ,אלאמין
531 ,עראבה ,241 ,אלאמיר
531 ,עראבה ,262 ,אלאמל
531 ,עראבה ,261 ,אלאנדלוס
531 ,עראבה ,305 ,אלאנהאר
531 ,עראבה ,339 ,אלאנואר
531 ,עראבה ,170 ,אלאסבאט
531 ,עראבה ,202 ,אלאסואר
531 ,עראבה ,342 ,אלאסוד
531 ,עראבה ,232 ,אלאסלאח
531 ,עראבה ,301 ,אלאסרא
531 ,עראבה ,330 ,אלאפנאן
531 ,עראבה ,302 ,אלאפראח
531 ,עראבה ,151 ,אלאקסא
531 ,עראבה ,145 ,אלבאדיה
531 ,עראבה ,437 ,אלבדר
531 ,עראבה ,119 ,אלבואסל
531 ,עראבה ,201 ,אלבוראק
531 ,עראבה ,279 ,אלבטוף
531 ,עראבה ,331 ,אלביאדר
531 ,עראבה ,137 ,אלביאן
531 ,עראבה ,445 ,אלביידר
531 ,עראבה ,249 ,אלביר
531 ,עראבה ,447 ,אלבירה
531 ,עראבה ,105 ,אלבלאן
531 ,עראבה ,470 ,אלבלד
531 ,עראבה ,482 ,אלבלדיה
531 ,עראבה ,311 ,אלבסאתין
531 ,עראבה ,471 ,אלבסרה
531 ,עראבה ,516 ,אלברקוק
531 ,עראבה ,205 ,אלבתרא
531 ,עראבה ,504 ,אלג'אר
531 ,עראבה ,133 ,אלג'בל
531 ,עראבה ,182 ,אלג'דבאן
531 ,עראבה ,304 ,אלג'דיר
531 ,עראבה ,196 ,אלג'זאאר
531 ,עראבה ,472 ,אלג'זאיר
531 ,עראבה ,100 ,אלג'ליל
531 ,עראבה ,222 ,אלג'מל
531 ,עראבה ,6751 ,אלג'רביה
531 ,עראבה ,271 ,אלג'רמק
531 ,עראבה ,343 ,אלגוארח
531 ,עראבה ,160 ,אלגולאן
531 ,עראבה ,204 ,אלדורה
531 ,עראבה ,493 ,אלדורי
531 ,עראבה ,221 ,אלהאדי
531 ,עראבה ,197 ,אלהודא
531 ,עראבה ,481 ,אלהודהוד
531 ,עראבה ,487 ,אלהלאל
531 ,עראבה ,346 ,אלהמם
531 ,עראבה ,348 ,אלואדי
531 ,עראבה ,272 ,אלואחה
531 ,עראבה ,450 ,אלוורוד
531 ,עראבה ,149 ,אלוחדאת
531 ,עראבה ,307 ,אלוחדה
531 ,עראבה ,243 ,אלולאא
531 ,עראבה ,244 ,אלופאא
531 ,עראבה ,398 ,אלורד
531 ,עראבה ,318 ,אלזייתון
531 ,עראבה ,486 ,אלזעפראן
531 ,עראבה ,520 ,אלזערור
531 ,עראבה ,298 ,אלזקאק
531 ,עראבה ,177 ,אלח'דק
531 ,עראבה ,265 ,אלח'ואלד
531 ,עראבה ,199 ,אלח'ולוד
531 ,עראבה ,115 ,אלח'לה
531 ,עראבה ,104 ,אלח'ליל
531 ,עראבה ,183 ,אלח'נסאא'
531 ,עראבה ,127 ,אלח'רובה
531 ,עראבה ,290 ,אלחאסבאני
531 ,עראבה ,508 ,אלחבק
531 ,עראבה ,283 ,אלחג'אז
531 ,עראבה ,121 ,אלחג'אר
531 ,עראבה ,521 ,אלחגל
531 ,עראבה ,325 ,אלחדארה
531 ,עראבה ,344 ,אלחוכמאא
531 ,עראבה ,264 ,אלחוריה
531 ,עראבה ,111 ,אלחטיב
531 ,עראבה ,477 ,אלחיאה
531 ,עראבה ,256 ,אלחכמה
531 ,עראבה ,309 ,אלחמרא
531 ,עראבה ,393 ,אלחנאוייה
531 ,עראבה ,164 ,אלחנאן
531 ,עראבה ,109 ,אלחפאר
531 ,עראבה ,142 ,אלחריקה
531 ,עראבה ,300 ,אלטאי
531 ,עראבה ,136 ,אלטויל
531 ,עראבה ,212 ,אלטור
531 ,עראבה ,114 ,אליאסמין
531 ,עראבה ,153 ,אלימן
531 ,עראבה ,332 ,אלכואדיר
531 ,עראבה ,217 ,אלכוארזמי
531 ,עראבה ,146 ,אלכליג'
531 ,עראבה ,277 ,אלכרום
531 ,עראבה ,452 ,אלכרמה
531 ,עראבה ,255 ,אלכרמל
531 ,עראבה ,252 ,אללוז
531 ,עראבה ,506 ,אלמאס
531 ,עראבה ,310 ,אלמג'ד
531 ,עראבה ,112 ,אלמג'רבה
531 ,עראבה ,161 ,אלמדאאן
531 ,עראבה ,327 ,אלמדינה
531 ,עראבה ,517 ,אלמהא
531 ,עראבה ,435 ,אלמהד
531 ,עראבה ,143 ,אלמוכתאר
531 ,עראבה ,266 ,אלמוסאוה
531 ,עראבה ,273 ,אלמוסטפא
531 ,עראבה ,155 ,אלמועתסם
531 ,עראבה ,215 ,אלמותנבי
531 ,עראבה ,476 ,אלמחבה
531 ,עראבה ,282 ,אלמחט
531 ,עראבה ,468 ,אלמטל
531 ,עראבה ,166 ,אלמיידאן
531 ,עראבה ,385 ,אלמל
531 ,עראבה ,333 ,אלמנאהיל
531 ,עראבה ,173 ,אלמנארה
531 ,עראבה ,157 ,אלמנבר
531 ,עראבה ,251 ,אלמנג'ל
531 ,עראבה ,280 ,אלמנהל
531 ,עראבה ,522 ,אלמנת'ור
531 ,עראבה ,462 ,אלמסק
531 ,עראבה ,292 ,אלמסרה
531 ,עראבה ,319 ,אלמעסרה
531 ,עראבה ,276 ,אלמעראג'
531 ,עראבה ,106 ,אלמערי
531 ,עראבה ,192 ,אלמערפה
531 ,עראבה ,102 ,אלמרג'
531 ,עראבה ,6750 ,אלמרוש
531 ,עראבה ,453 ,אלנאקורה
531 ,עראבה ,269 ,אלנג'אח
531 ,עראבה ,278 ,אלנג'אר
531 ,עראבה ,345 ,אלנהדה
531 ,עראבה ,180 ,אלנובלא
531 ,עראבה ,107 ,אלנור
531 ,עראבה ,448 ,אלנורס
531 ,עראבה ,253 ,אלנח'יל
531 ,עראבה ,230 ,אלנחל
531 ,עראבה ,198 ,אלנסים
531 ,עראבה ,229 ,אלנסר
531 ,עראבה ,324 ,אלנעים
531 ,עראבה ,193 ,אלנקב
531 ,עראבה ,329 ,אלנרג'ס
531 ,עראבה ,338 ,אלסבאר
531 ,עראבה ,126 ,אלסג'רה
531 ,עראבה ,209 ,אלסד
531 ,עראבה ,274 ,אלסדאקה
531 ,עראבה ,474 ,אלסווסנה
531 ,עראבה ,257 ,אלסויס
531 ,עראבה ,467 ,אלסולטאן
531 ,עראבה ,466 ,אלסופסאף
531 ,עראבה ,239 ,אלסלאם
531 ,עראבה ,489 ,אלסלוא
531 ,עראבה ,191 ,אלסנאבל
531 ,עראבה ,464 ,אלסנדבאד
531 ,עראבה ,465 ,אלסנדיאן
531 ,עראבה ,433 ,אלסעאדה
531 ,עראבה ,148 ,אלספא
531 ,עראבה ,314 ,אלסראג'
531 ,עראבה ,200 ,אלסראט
531 ,עראבה ,436 ,אלסרג
531 ,עראבה ,218 ,אלע'ופראן
531 ,עראבה ,524 ,אלע'רבי
531 ,עראבה ,263 ,אלעדל
531 ,עראבה ,293 ,אלעהד
531 ,עראבה ,238 ,אלעולא
531 ,עראבה ,484 ,אלעטאר
531 ,עראבה ,496 ,אלעין
531 ,עראבה ,347 ,אלעלם
531 ,עראבה ,219 ,אלעלמין
531 ,עראבה ,505 ,אלענאב
531 ,עראבה ,285 ,אלענאן
531 ,עראבה ,518 ,אלעסל
531 ,עראבה ,503 ,אלעקאד
531 ,עראבה ,284 ,אלערין
531 ,עראבה ,167 ,אלעריש
531 ,עראבה ,529 ,אלערמש
531 ,עראבה ,207 ,אלפאנוס
531 ,עראבה ,179 ,אלפארוק
531 ,עראבה ,296 ,אלפג'ר
531 ,עראבה ,390 ,אלפול
531 ,עראבה ,335 ,אלפונון
531 ,עראבה ,128 ,אלפוראת
531 ,עראבה ,225 ,אלפורסאן
531 ,עראבה ,237 ,אלפראבי
531 ,עראבה ,181 ,אלפרג'
531 ,עראבה ,473 ,אלפרדוס
531 ,עראבה ,488 ,אלפרזדק
531 ,עראבה ,322 ,אלצדיק
531 ,עראבה ,386 ,אלצפדי
531 ,עראבה ,174 ,אלקאדי
531 ,עראבה ,469 ,אלקאהרה
531 ,עראבה ,118 ,אלקודס
531 ,עראבה ,220 ,אלקזאז
531 ,עראבה ,289 ,אלקמר
531 ,עראבה ,502 ,אלקנדול
531 ,עראבה ,281 ,אלקסטל
531 ,עראבה ,461 ,אלקרואן
531 ,עראבה ,519 ,אלקרז
531 ,עראבה ,334 ,אלר'וסון
531 ,עראבה ,291 ,אלראג'יד
531 ,עראבה ,139 ,אלראזי
531 ,עראבה ,432 ,אלראיה
531 ,עראבה ,159 ,אלרבאט
531 ,עראבה ,113 ,אלרביע
531 ,עראבה ,294 ,אלרדואן
531 ,עראבה ,176 ,אלרוגמה
531 ,עראבה ,457 ,אלרויס
531 ,עראבה ,187 ,אלרומאן
531 ,עראבה ,248 ,אלרחמה
531 ,עראבה ,532 ,אלרחראח
531 ,עראבה ,308 ,אלריאד
531 ,עראבה ,245 ,אלריאחין
531 ,עראבה ,442 ,אלריאן
531 ,עראבה ,525 ,אלרייחאן
531 ,עראבה ,228 ,אלרסול
531 ,עראבה ,270 ,אלשאטי
531 ,עראבה ,507 ,אלשאם
531 ,עראבה ,434 ,אלשהד
531 ,עראבה ,336 ,אלשוגון
531 ,עראבה ,297 ,אלשועאע
531 ,עראבה ,223 ,אלשועלה
531 ,עראבה ,194 ,אלשיח' דאוד
531 ,עראבה ,534 ,אלשייך עיסא
531 ,עראבה ,351 ,אלשיך יוסף
531 ,עראבה ,460 ,אלשימאא
531 ,עראבה ,288 ,אלשמס
531 ,עראבה ,169 ,אלשעב
531 ,עראבה ,267 ,אלשריף
531 ,עראבה ,185 ,אלת'באת
531 ,עראבה ,240 ,אלתאובה
531 ,עראבה ,313 ,אלתסאמוח
531 ,עראבה ,203 ,אלתעאון
531 ,עראבה ,135 ,אלתקוא
531 ,עראבה ,275 ,אסואן
531 ,עראבה ,382 ,אסיל עאסלה
531 ,עראבה ,122 ,אריחא
531 ,עראבה ,116 ,באב אלואדי
531 ,עראבה ,108 ,באב אלעאמוד
531 ,עראבה ,144 ,באניאס
531 ,עראבה ,156 ,בור סעיד
531 ,עראבה ,451 ,ביירות
531 ,עראבה ,303 ,ביסאן
531 ,עראבה ,175 ,ביר אלחמאם
531 ,עראבה ,101 ,ביר אלמיי
531 ,עראבה ,394 ,ביר אלתנור
531 ,עראבה ,178 ,ג'בל אלערב
531 ,עראבה ,317 ,ג'דה
531 ,עראבה ,152 ,ג'למה שמאליה
531 ,עראבה ,299 ,ג'נין
531 ,עראבה ,132 ,ג'רש
531 ,עראבה ,458 ,גאנדי
531 ,עראבה ,6731 ,גרעין הכפר
531 ,עראבה ,141 ,ד'אהר אלעומר
531 ,עראבה ,130 ,דג'לה
531 ,עראבה ,147 ,דמשק
531 ,עראבה ,491 ,האגר
531 ,עראבה ,103 ,ואדי אלח'שב
531 ,עראבה ,6741 ,ואדי אלנתור
531 ,עראבה ,6740 ,ואדי אלעין
531 ,עראבה ,6712 ,ואדי חוסין
531 ,עראבה ,392 ,ואדי ת'ור
531 ,עראבה ,431 ,זהר אללוז
531 ,עראבה ,110 ,זמזם
531 ,עראבה ,227 ,ח'אלד בן אלוליד
531 ,עראבה ,213 ,ח'ליל אלסכאכיני
531 ,עראבה ,511 ,חאפז אבראהים
531 ,עראבה ,349 ,חיטין
531 ,עראבה ,259 ,חיפא
531 ,עראבה ,389 ,חיר יאסין
531 ,עראבה ,438 ,חלב
531 ,עראבה ,440 ,טאהא חוסין
531 ,עראבה ,499 ,טארק בן זיאד
531 ,עראבה ,260 ,טבריא
531 ,עראבה ,231 ,טלחה בן אלזוביר
531 ,עראבה ,326 ,טראבלוס
531 ,עראבה ,250 ,יאפא
531 ,עראבה ,165 ,כניסת אלסיידה
531 ,עראבה ,449 ,כפר ענאן
531 ,עראבה ,459 ,לוקמאן
531 ,עראבה ,497 ,מאי זיאדה
531 ,עראבה ,533 ,מוחמד עראבי
531 ,עראבה ,530 ,מוקלס
531 ,עראבה ,258 ,מכה
531 ,עראבה ,306 ,מנא
531 ,עראבה ,454 ,מראקש
531 ,עראבה ,171 ,מרג' אלזוהור
531 ,עראבה ,168 ,מרים אלעדראא
531 ,עראבה ,531 ,משטה
531 ,עראבה ,287 ,נאבלס
531 ,עראבה ,439 ,נגד
531 ,עראבה ,514 ,נגיב מחפוז
531 ,עראבה ,388 ,נוח איבראהים
531 ,עראבה ,483 ,נלסון מנדילה
531 ,עראבה ,295 ,סולטאן אלערב
531 ,עראבה ,163 ,סוקראט
531 ,עראבה ,490 ,סיבויה
531 ,עראבה ,444 ,סייד דרויש
531 ,עראבה ,443 ,סינאא
531 ,עראבה ,384 ,סלאח אלדין
531 ,עראבה ,316 ,סנעא
531 ,עראבה ,463 ,סעד בן אבי וקאס
531 ,עראבה ,125 ,ספד
531 ,עראבה ,509 ,עבד אלווהאב
531 ,עראבה ,206 ,עדן
531 ,עראבה ,391 ,עומר אלג'רבוני
531 ,עראבה ,214 ,עומר אלמוח'תאר
531 ,עראבה ,138 ,עומר בן אלח'טאב
531 ,עראבה ,172 ,עומר בן עבד אלעזיר
531 ,עראבה ,233 ,עוקאז'
531 ,עראבה ,211 ,עותמאן בן עפאן
531 ,עראבה ,120 ,עזה
531 ,עראבה ,353 ,עיסא אלחשיש
531 ,עראבה ,441 ,עכא
531 ,עראבה ,352 ,עלי אלסח
531 ,עראבה ,189 ,עלי בן אבי טאלב
531 ,עראבה ,320 ,עמאן
531 ,עראבה ,498 ,עמאר בן יאסר
531 ,עראבה ,190 ,עמרו בן אלעאס
531 ,עראבה ,268 ,עסקלאן
531 ,עראבה ,9000 ,עראבה
531 ,עראבה ,224 ,ערפה
531 ,עראבה ,513 ,עתלית
531 ,עראבה ,210 ,פאטמה אלזהראא
531 ,עראבה ,478 ,פיירוז
531 ,עראבה ,515 ,צ'ה גווארה
531 ,עראבה ,500 ,צור
531 ,עראבה ,501 ,צידון
531 ,עראבה ,124 ,קורטובה
531 ,עראבה ,528 ,קלעון
531 ,עראבה ,226 ,ראס אלעין
531 ,עראבה ,527 ,ראס אלתין
531 ,עראבה ,479 ,רפח
531 ,עראבה ,523 ,שארע ואדי חוסיין
531 ,עראבה ,123 ,שג'רת אלדור
531 ,עראבה ,6711 ,שכ אלביר
531 ,עראבה ,6752 ,שכ אלבלאן
531 ,עראבה ,6710 ,שכ אלחאלה
531 ,עראבה ,6720 ,שכ ג'למה
531 ,עראבה ,6713 ,שכונת כנאענה
531 ,עראבה ,6730 ,שכונת עאצלה
531 ,עראבה ,117 ,תדמר
531 ,עראבה ,195 ,תוניס
531 ,עראבה ,512 ,תופיק אלחכים
531 ,עראבה ,395 ,תופיק זיאד
531 ,עראבה ,134 ,תקרית
1246 ,עראמשה ,9000 ,עראמשה
1335 ,ערב אל נעים ,101 ,אל ביידר
1335 ,ערב אל נעים ,102 ,אל דהר
1335 ,ערב אל נעים ,103 ,אל זיתון
1335 ,ערב אל נעים ,104 ,אל ח'לה
1335 ,ערב אל נעים ,105 ,אל ח'רוב
1335 ,ערב אל נעים ,106 ,אל כתיף
1335 ,ערב אל נעים ,107 ,אל נעים
1335 ,ערב אל נעים ,108 ,אל סריס
1335 ,ערב אל נעים ,109 ,אל רחראח
1335 ,ערב אל נעים ,110 ,אל תלה
1335 ,ערב אל נעים ,9000 ,ערב אל נעים
2560 ,ערד ,123 ,אביב
2560 ,ערד ,281 ,אגוז
2560 ,ערד ,321 ,אגט
2560 ,ערד ,465 ,אגם
2560 ,ערד ,417 ,אגמית
2560 ,ערד ,282 ,אגס
2560 ,ערד ,459 ,אדווה
2560 ,ערד ,261 ,אודם
2560 ,ערד ,260 ,אופן
2560 ,ערד ,243 ,אופק
2560 ,ערד ,156 ,אופקים
2560 ,ערד ,6516 ,אזור התעשיה
2560 ,ערד ,6520 ,אזור מלונות
2560 ,ערד ,106 ,אחוה
2560 ,ערד ,444 ,אחז המלך
2560 ,ערד ,330 ,אחלמה
2560 ,ערד ,114 ,אטד
2560 ,ערד ,362 ,אילת השחר
2560 ,ערד ,271 ,אירוסים
2560 ,ערד ,416 ,אנפה
2560 ,ערד ,422 ,אנקור
2560 ,ערד ,443 ,אסא המלך
2560 ,ערד ,463 ,אפיק
2560 ,ערד ,286 ,ארבל
2560 ,ערד ,462 ,אשד
2560 ,ערד ,305 ,אתרוג
2560 ,ערד ,319 ,בדולח
2560 ,ערד ,317 ,בדיל
2560 ,ערד ,323 ,בולבוס
2560 ,ערד ,238 ,בועז
2560 ,ערד ,458 ,בוקק
2560 ,ערד ,421 ,בז
2560 ,ערד ,181 ,בזלת
2560 ,ערד ,202 ,בן יאיר אלעזר
2560 ,ערד ,249 ,בצלאל
2560 ,ערד ,247 ,ברזל
2560 ,ערד ,162 ,ברקן
2560 ,ערד ,262 ,ברקת
2560 ,ערד ,457 ,בשור
2560 ,ערד ,179 ,גביש
2560 ,ערד ,307 ,גיר
2560 ,ערד ,352 ,גיתית
2560 ,ערד ,119 ,גל
2560 ,ערד ,297 ,גלבוע
2560 ,ערד ,312 ,גרניט
2560 ,ערד ,440 ,דוד המלך
2560 ,ערד ,404 ,דוכיפת
2560 ,ערד ,146 ,דימונה
2560 ,ערד ,143 ,דרך באר שבע
2560 ,ערד ,220 ,דרך הגז
2560 ,ערד ,234 ,דרך זוהר
2560 ,ערד ,295 ,הבונים
2560 ,ערד ,140 ,הגיא
2560 ,ערד ,104 ,הגלעד
2560 ,ערד ,129 ,הדס
2560 ,ערד ,103 ,ההר
2560 ,ערד ,153 ,החומה
2560 ,ערד ,176 ,החוצבים
2560 ,ערד ,175 ,היוצקים
2560 ,ערד ,102 ,הכרמל
2560 ,ערד ,157 ,המדרגות
2560 ,ערד ,115 ,המכבים
2560 ,ערד ,221 ,המלאכה
2560 ,ערד ,112 ,המרכז
2560 ,ערד ,141 ,הספורט
2560 ,ערד ,184 ,העץ
2560 ,ערד ,113 ,הפלמ"ח
2560 ,ערד ,241 ,הצבי
2560 ,ערד ,144 ,הקודחים
2560 ,ערד ,122 ,הקנאים
2560 ,ערד ,272 ,ורד
2560 ,ערד ,415 ,זמיר
2560 ,ערד ,414 ,זרזיר
2560 ,ערד ,429 ,חבר
2560 ,ערד ,207 ,חברון
2560 ,ערד ,318 ,חוואר
2560 ,ערד ,6500 ,חוות אביר
2560 ,ערד ,407 ,חוחית
2560 ,ערד ,116 ,חולדה
2560 ,ערד ,423 ,חופית
2560 ,ערד ,155 ,חימר
2560 ,ערד ,322 ,חלוקים
2560 ,ערד ,358 ,חלילית
2560 ,ערד ,273 ,חלמונית
2560 ,ערד ,426 ,חסידה
2560 ,ערד ,149 ,חרוב
2560 ,ערד ,298 ,חרמון
2560 ,ערד ,313 ,חרסית
2560 ,ערד ,163 ,חרצית
2560 ,ערד ,170 ,חשמל
2560 ,ערד ,287 ,טביה
2560 ,ערד ,310 ,טוף
2560 ,ערד ,314 ,טופז
2560 ,ערד ,311 ,טורקיז
2560 ,ערד ,134 ,טיילת
2560 ,ערד ,296 ,טייסים
2560 ,ערד ,320 ,טין
2560 ,ערד ,460 ,טל
2560 ,ערד ,139 ,יאשיהו
2560 ,ערד ,201 ,יהודה
2560 ,ערד ,452 ,יובל
2560 ,ערד ,412 ,יונה
2560 ,ערד ,205 ,יונתן
2560 ,ערד ,442 ,יותם המלך
2560 ,ערד ,239 ,יכין
2560 ,ערד ,289 ,יסמין
2560 ,ערד ,427 ,יסעור
2560 ,ערד ,274 ,יערה
2560 ,ערד ,206 ,ירושלים
2560 ,ערד ,331 ,ישפה
2560 ,ערד ,270 ,כביש 31
2560 ,ערד ,308 ,כורכר
2560 ,ערד ,292 ,כידוד
2560 ,ערד ,6001 ,כיכר המייסדים
2560 ,ערד ,355 ,כינור
2560 ,ערד ,275 ,כלנית
2560 ,ערד ,6501 ,כפר ילדים נרדים
2560 ,ערד ,410 ,לילית
2560 ,ערד ,285 ,לילך
2560 ,ערד ,326 ,לס
2560 ,ערד ,257 ,לשם
2560 ,ערד ,105 ,מבצע לוט
2560 ,ערד ,132 ,מגדל
2560 ,ערד ,245 ,מגשימים
2560 ,ערד ,101 ,מואב
2560 ,ערד ,264 ,מור
2560 ,ערד ,216 ,מזור
2560 ,ערד ,357 ,מזמור
2560 ,ערד ,124 ,מזרח
2560 ,ערד ,359 ,מיתר
2560 ,ערד ,213 ,מכון ווג'ס
2560 ,ערד ,251 ,מכונה
2560 ,ערד ,211 ,מלון מרגוע
2560 ,ערד ,332 ,מלכיט
2560 ,ערד ,254 ,מנוף
2560 ,ערד ,237 ,מעוז
2560 ,ערד ,147 ,מעון
2560 ,ערד ,455 ,מעיין
2560 ,ערד ,360 ,מעלות
2560 ,ערד ,178 ,מפוח
2560 ,ערד ,464 ,מפל
2560 ,ערד ,215 ,מצדה
2560 ,ערד ,177 ,מקבת
2560 ,ערד ,212 ,מרכז הקליטה
2560 ,ערד ,110 ,מרכז קליטה ב
2560 ,ערד ,263 ,משמר
2560 ,ערד ,454 ,נביעות
2560 ,ערד ,125 ,נגה
2560 ,ערד ,118 ,נוף
2560 ,ערד ,329 ,נופך
2560 ,ערד ,244 ,נורית
2560 ,ערד ,174 ,נחושת
2560 ,ערד ,461 ,נחל
2560 ,ערד ,354 ,ניגונים
2560 ,ערד ,236 ,נפטא
2560 ,ערד ,401 ,נץ
2560 ,ערד ,413 ,נקר
2560 ,ערד ,276 ,נרקיס
2560 ,ערד ,405 ,נשר
2560 ,ערד ,217 ,נתיב דבורה
2560 ,ערד ,151 ,נתיב הגפן
2560 ,ערד ,203 ,נתיב הלל
2560 ,ערד ,150 ,נתיב התאנה
2560 ,ערד ,152 ,נתיב התמר
2560 ,ערד ,218 ,נתיב יעל
2560 ,ערד ,219 ,נתיב מרים
2560 ,ערד ,222 ,סדן
2560 ,ערד ,232 ,סהר
2560 ,ערד ,182 ,סיגים
2560 ,ערד ,107 ,סיירים
2560 ,ערד ,158 ,סיני
2560 ,ערד ,424 ,סיס
2560 ,ערד ,277 ,סיפן
2560 ,ערד ,131 ,סלע
2560 ,ערד ,418 ,סלעית
2560 ,ערד ,164 ,סמ האקליפטוס
2560 ,ערד ,408 ,סנונית
2560 ,ערד ,327 ,ספינל
2560 ,ערד ,265 ,ספיר
2560 ,ערד ,315 ,ספן
2560 ,ערד ,411 ,עגור
2560 ,ערד ,161 ,עדעד
2560 ,ערד ,159 ,עוזיהו
2560 ,ערד ,180 ,עופרת
2560 ,ערד ,409 ,עורב
2560 ,ערד ,403 ,עיט
2560 ,ערד ,142 ,עין התכלת
2560 ,ערד ,117 ,עינות
2560 ,ערד ,278 ,עירית
2560 ,ערד ,160 ,עמל
2560 ,ערד ,130 ,ענב
2560 ,ערד ,350 ,ענבלים
2560 ,ערד ,128 ,ענבר
2560 ,ערד ,420 ,עפרוני
2560 ,ערד ,127 ,ערבה
2560 ,ערד ,9000 ,ערד
2560 ,ערד ,451 ,ערוץ
2560 ,ערד ,108 ,פטיו
2560 ,ערד ,453 ,פלג
2560 ,ערד ,255 ,פלדה
2560 ,ערד ,428 ,פשוש
2560 ,ערד ,259 ,צאלים
2560 ,ערד ,279 ,צבעוני
2560 ,ערד ,126 ,צבר
2560 ,ערד ,419 ,צופית
2560 ,ערד ,293 ,צופר
2560 ,ערד ,133 ,צור
2560 ,ערד ,306 ,צורן
2560 ,ערד ,356 ,צליל
2560 ,ערד ,361 ,צפרירים
2560 ,ערד ,266 ,קדם
2560 ,ערד ,288 ,קדרון
2560 ,ערד ,328 ,קוורץ
2560 ,ערד ,223 ,קשת
2560 ,ערד ,456 ,רביבים
2560 ,ערד ,6502 ,רובע אבישור
2560 ,ערד ,6503 ,רובע אשלים
2560 ,ערד ,6504 ,רובע חלמיש
2560 ,ערד ,6505 ,רובע טללים
2560 ,ערד ,6506 ,רובע יהושפט
2560 ,ערד ,6507 ,רובע יעלים
2560 ,ערד ,6508 ,רובע לבאות
2560 ,ערד ,6509 ,רובע נעורים
2560 ,ערד ,316 ,רוטיל
2560 ,ערד ,154 ,רות
2560 ,ערד ,256 ,רותם
2560 ,ערד ,267 ,רימון
2560 ,ערד ,185 ,רכב
2560 ,ערד ,246 ,רענן
2560 ,ערד ,280 ,רקפת
2560 ,ערד ,6510 ,ש ראשונים
2560 ,ערד ,204 ,שאול
2560 ,ערד ,441 ,שאול המלך
2560 ,ערד ,210 ,שד חן
2560 ,ערד ,268 ,שוהם
2560 ,ערד ,353 ,שופר
2560 ,ערד ,290 ,שושן
2560 ,ערד ,406 ,שחף
2560 ,ערד ,284 ,שיזף
2560 ,ערד ,351 ,שיר
2560 ,ערד ,363 ,שיר השירים
2560 ,ערד ,252 ,שיש
2560 ,ערד ,6515 ,שכ גבים
2560 ,ערד ,6511 ,שכ גני משי
2560 ,ערד ,6519 ,שכ הרדוף
2560 ,ערד ,6521 ,שכ חצבים
2560 ,ערד ,6517 ,שכ מבוא שקד
2560 ,ערד ,6514 ,שכ מעוף
2560 ,ערד ,6513 ,שכ רותם
2560 ,ערד ,6518 ,שכ רננים
2560 ,ערד ,6512 ,שכ שקד
2560 ,ערד ,425 ,שלדג
2560 ,ערד ,242 ,שלוה
2560 ,ערד ,445 ,שלמה המלך
2560 ,ערד ,253 ,שמיר
2560 ,ערד ,250 ,שמעון
2560 ,ערד ,294 ,תבור
2560 ,ערד ,283 ,תדהר
2560 ,ערד ,402 ,תור
2560 ,ערד ,325 ,תכליל
2560 ,ערד ,291 ,תלתן
2560 ,ערד ,248 ,תעשיה
2560 ,ערד ,138 ,תפוח
2560 ,ערד ,269 ,תרשיש
593 ,ערוגות ,105 ,דרך הצבר
593 ,ערוגות ,103 ,דרך השדות
593 ,ערוגות ,110 ,האפרסק
593 ,ערוגות ,106 ,הזית
593 ,ערוגות ,108 ,הרימון
593 ,ערוגות ,107 ,התאנה
593 ,ערוגות ,109 ,התפוח
593 ,ערוגות ,113 ,כ"ב באדר
593 ,ערוגות ,9000 ,ערוגות
593 ,ערוגות ,104 ,שד הכורמים
593 ,ערוגות ,101 ,שד המייסדים
593 ,ערוגות ,102 ,שד הראשונים
637 ,ערערה ,134 ,א-ד'ורה
637 ,ערערה ,459 ,א-דפלה
637 ,ערערה ,420 ,א-זהראוי
637 ,ערערה ,141 ,א-זוקאק
637 ,ערערה ,142 ,א-זית
637 ,ערערה ,143 ,א-זיתון
637 ,ערערה ,429 ,א-זנבק
637 ,ערערה ,347 ,א-טאבון
637 ,ערערה ,461 ,א-טיונה
637 ,ערערה ,280 ,א-לוד
637 ,ערערה ,376 ,א-לוז
637 ,ערערה ,337 ,א-לולו
637 ,ערערה ,454 ,א-לימון
637 ,ערערה ,324 ,א-נג'אח
637 ,ערערה ,414 ,א-נג'אר
637 ,ערערה ,472 ,א-נהדה
637 ,ערערה ,181 ,א-נורס
637 ,ערערה ,353 ,א-נזאזאת
637 ,ערערה ,182 ,א-נסים
637 ,ערערה ,468 ,א-נסר
637 ,ערערה ,289 ,א-נרג'ס
637 ,ערערה ,183 ,א-ס'אבון
637 ,ערערה ,416 ,א-ס'באר'
637 ,ערערה ,184 ,א-ס'דפה
637 ,ערערה ,297 ,א-ס'ופס'אף
637 ,ערערה ,290 ,א-סדר
637 ,ערערה ,185 ,א-סואני
637 ,ערערה ,186 ,א-סוהול
637 ,ערערה ,430 ,א-סוסן
637 ,ערערה ,445 ,א-סיכה
637 ,ערערה ,443 ,א-סמואל
637 ,ערערה ,190 ,א-סנאבל
637 ,ערערה ,346 ,א-סראיא
637 ,ערערה ,298 ,א-סרו
637 ,ערערה ,313 ,א-צנובר
637 ,ערערה ,202 ,א-ראזי
637 ,ערערה ,204 ,א-ראחילון
637 ,ערערה ,330 ,א-ראחלון
637 ,ערערה ,203 ,א-רוחה
637 ,ערערה ,375 ,א-רומאן
637 ,ערערה ,354 ,א-רחמה
637 ,ערערה ,456 ,א-רים
637 ,ערערה ,206 ,א-רימאל
637 ,ערערה ,205 ,א-ריף
637 ,ערערה ,321 ,א-רמלה
637 ,ערערה ,351 ,א-רפאעייה
637 ,ערערה ,207 ,א-שאטי
637 ,ערערה ,208 ,א-שאם
637 ,ערערה ,210 ,א-שאפיעי
637 ,ערערה ,211 ,א-שבכה
637 ,ערערה ,465 ,א-שחרור
637 ,ערערה ,427 ,א-שיח' אימאם
637 ,ערערה ,352 ,א-שיח' ח'ילף
637 ,ערערה ,213 ,א-שיראע
637 ,ערערה ,215 ,א-תבאן
637 ,ערערה ,216 ,א-תחריר
637 ,ערערה ,380 ,א-תין
637 ,ערערה ,348 ,א-תנור
637 ,ערערה ,218 ,א-תסאמוח
637 ,ערערה ,102 ,אבו בכר א-ס'דיק
637 ,ערערה ,405 ,אבו סלאמא
637 ,ערערה ,425 ,אבו ערב
637 ,ערערה ,221 ,אום א-שוף
637 ,ערערה ,6741 ,אזור בי"ס אלהלאל
637 ,ערערה ,6721 ,אזור בי"ס אלסלאם
637 ,ערערה ,6715 ,אזור בי"ס טה חסין
637 ,ערערה ,6720 ,אזור בית הקברות
637 ,ערערה ,6744 ,אזור המסגד
637 ,ערערה ,106 ,איבן א-נפיס
637 ,ערערה ,421 ,איבן סינא
637 ,ערערה ,107 ,איבן רושד
637 ,ערערה ,111 ,אילול
637 ,ערערה ,6730 ,אל באטן
637 ,ערערה ,6710 ,אל ג'רס
637 ,ערערה ,6731 ,אל דהראת
637 ,ערערה ,6743 ,אל מסקא
637 ,ערערה ,115 ,אל-אוח'ווה
637 ,ערערה ,448 ,אל-אטלאל
637 ,ערערה ,110 ,אל-אידריסי
637 ,ערערה ,338 ,אל-אמואג'
637 ,ערערה ,116 ,אל-אמל
637 ,ערערה ,117 ,אל-באבור
637 ,ערערה ,471 ,אל-באז
637 ,ערערה ,118 ,אל-באשא
637 ,ערערה ,232 ,אל-בוטמה
637 ,ערערה ,120 ,אל-בוס'לה
637 ,ערערה ,121 ,אל-בוסתאן
637 ,ערערה ,122 ,אל-בורג'
637 ,ערערה ,237 ,אל-בורזה
637 ,ערערה ,433 ,אל-בחר
637 ,ערערה ,130 ,אל-ביאדר
637 ,ערערה ,235 ,אל-בלוט
637 ,ערערה ,455 ,אל-בסמה
637 ,ערערה ,131 ,אל-בקאא
637 ,ערערה ,451 ,אל-בקוליאת
637 ,ערערה ,431 ,אל-ברקוק
637 ,ערערה ,442 ,אל-ג'ואהרי
637 ,ערערה ,132 ,אל-ג'ורי
637 ,ערערה ,437 ,אל-ג'זר
637 ,ערערה ,419 ,אל-ג'רן
637 ,ערערה ,136 ,אל-הדבה
637 ,ערערה ,137 ,אל-הילאל
637 ,ערערה ,138 ,אל-וחדה
637 ,ערערה ,139 ,אל-ויפאק
637 ,ערערה ,140 ,אל-וקפייה
637 ,ערערה ,349 ,אל-ח'אביה
637 ,ערערה ,146 ,אל-ח'ארג'ה
637 ,ערערה ,423 ,אל-ח'ואריזמי
637 ,ערערה ,377 ,אל-ח'וח'
637 ,ערערה ,460 ,אל-ח'ור
637 ,ערערה ,415 ,אל-ח'יאט
637 ,ערערה ,449 ,אל-ח'ייאלה
637 ,ערערה ,148 ,אל-ח'ימה
637 ,ערערה ,149 ,אל-ח'לה
637 ,ערערה ,150 ,אל-ח'נסא
637 ,ערערה ,453 ,אל-ח'רדל
637 ,ערערה ,359 ,אל-חאטף
637 ,ערערה ,258 ,אל-חג'אר
637 ,ערערה ,469 ,אל-חג'ל
637 ,ערערה ,340 ,אל-חואמה
637 ,ערערה ,151 ,אל-חיאת
637 ,ערערה ,257 ,אל-חיג'אז
637 ,ערערה ,152 ,אל-חיכמה
637 ,ערערה ,259 ,אל-חלפא
637 ,ערערה ,153 ,אל-חנטור
637 ,ערערה ,350 ,אל-חניה
637 ,ערערה ,467 ,אל-חסון
637 ,ערערה ,452 ,אל-יקטין
637 ,ערערה ,355 ,אל-כותאב
637 ,ערערה ,466 ,אל-כירואן
637 ,ערערה ,201 ,אל-כנדי
637 ,ערערה ,155 ,אל-כראמה
637 ,ערערה ,392 ,אל-כרז
637 ,ערערה ,156 ,אל-כרם
637 ,ערערה ,157 ,אל-כרמה
637 ,ערערה ,331 ,אל-כרמל
637 ,ערערה ,341 ,אל-מד
637 ,ערערה ,160 ,אל-מואזן
637 ,ערערה ,161 ,אל-מואסם
637 ,ערערה ,163 ,אל-מוח'תאר
637 ,ערערה ,164 ,אל-מונה
637 ,ערערה ,281 ,אל-מוסאוואה
637 ,ערערה ,167 ,אל-מוסחראתי
637 ,ערערה ,357 ,אל-מוע'ור
637 ,ערערה ,168 ,אל-מורג'אן
637 ,ערערה ,169 ,אל-מורתפעה
637 ,ערערה ,170 ,אל-מותנבי
637 ,ערערה ,447 ,אל-מח'בז
637 ,ערערה ,436 ,אל-מחאר
637 ,ערערה ,171 ,אל-מחראת'
637 ,ערערה ,172 ,אל-מטל
637 ,ערערה ,362 ,אל-מיזראב
637 ,ערערה ,173 ,אל-מינאא
637 ,ערערה ,179 ,אל-מיסקאה
637 ,ערערה ,378 ,אל-מישמיש
637 ,ערערה ,174 ,אל-מנאג'ל
637 ,ערערה ,175 ,אל-מנאהל
637 ,ערערה ,334 ,אל-מנארה
637 ,ערערה ,345 ,אל-מנזול
637 ,ערערה ,285 ,אל-מניג'ל
637 ,ערערה ,365 ,אל-מס'אבח
637 ,ערערה ,177 ,אל-מסעודי
637 ,ערערה ,180 ,אל-מעס'רה
637 ,ערערה ,424 ,אל-מקריזי
637 ,ערערה ,339 ,אל-מרסאה
637 ,ערערה ,383 ,אל-ע'אבה
637 ,ערערה ,300 ,אל-ע'אר
637 ,ערערה ,193 ,אל-עדאלה
637 ,ערערה ,195 ,אל-עין
637 ,ערערה ,464 ,אל-עיקאב
637 ,ערערה ,197 ,אל-עיקד
637 ,ערערה ,446 ,אל-עיש
637 ,ערערה ,432 ,אל-עכוב
637 ,ערערה ,381 ,אל-ענב
637 ,ערערה ,470 ,אל-ענדליב
637 ,ערערה ,438 ,אל-ערער
637 ,ערערה ,361 ,אל-עתבה
637 ,ערערה ,363 ,אל-פאנוס
637 ,ערערה ,198 ,אל-פאראבי
637 ,ערערה ,434 ,אל-פנאר
637 ,ערערה ,379 ,אל-צבאר
637 ,ערערה ,114 ,אל-קודס
637 ,ערערה ,450 ,אל-קטאני
637 ,ערערה ,200 ,אל-קמח
637 ,ערערה ,462 ,אל-קס'ב
637 ,ערערה ,191 ,אל-ר'ורבאל
637 ,ערערה ,6714 ,אלביאר
637 ,ערערה ,6711 ,אלביר
637 ,ערערה ,6716 ,אלח'רבה
637 ,ערערה ,223 ,אמינה
637 ,ערערה ,274 ,אריחא
637 ,ערערה ,326 ,ארם
637 ,ערערה ,228 ,את'ל
637 ,ערערה ,399 ,באבונג'
637 ,ערערה ,230 ,בדריה
637 ,ערערה ,231 ,בהלול
637 ,ערערה ,407 ,בינת א-שאטי
637 ,ערערה ,233 ,ביר א-זרקא
637 ,ערערה ,428 ,בירם א-תוניסי
637 ,ערערה ,384 ,בלח
637 ,ערערה ,236 ,בעל
637 ,ערערה ,391 ,ברדה
637 ,ערערה ,238 ,ג'אבר בן חיאן
637 ,ערערה ,387 ,ג'ומיז
637 ,ערערה ,356 ,ג'לח
637 ,ערערה ,382 ,דאליא
637 ,ערערה ,241 ,דגון
637 ,ערערה ,390 ,דום
637 ,ערערה ,411 ,הארון האשם רשיד
637 ,ערערה ,410 ,הישאם שראבי
637 ,ערערה ,244 ,ואדי א-טואחין
637 ,ערערה ,250 ,ואדי א-תמאסיח
637 ,ערערה ,6712 ,ואדי אל קצב
637 ,ערערה ,245 ,ואדי אל-מילח
637 ,ערערה ,249 ,ואדי אל-ר'וזלאן
637 ,ערערה ,247 ,ואדי בוראק
637 ,ערערה ,248 ,ואדי ג'ורף
637 ,ערערה ,251 ,זאהיה אל-חדיד
637 ,ערערה ,463 ,זמזם
637 ,ערערה ,396 ,זעפראן
637 ,ערערה ,388 ,זערור
637 ,ערערה ,397 ,זעתר
637 ,ערערה ,457 ,זריאב
637 ,ערערה ,401 ,ח'ושח'אש
637 ,ערערה ,254 ,ח'ליל א-סכאכיני
637 ,ערערה ,255 ,ח'לת זאהר
637 ,ערערה ,386 ,ח'רוב
637 ,ערערה ,413 ,חדאד
637 ,ערערה ,6713 ,חור צקר
637 ,ערערה ,260 ,חנזלה
637 ,ערערה ,261 ,חסן בן עלי
637 ,ערערה ,263 ,חסן פתחי
637 ,ערערה ,373 ,טבריא
637 ,ערערה ,264 ,טה חוסיין
637 ,ערערה ,135 ,טריק א-ד'הראת
637 ,ערערה ,327 ,טריק א-סולטנה
637 ,ערערה ,192 ,טריק אל-ג'רס
637 ,ערערה ,400 ,יאנסון
637 ,ערערה ,266 ,יאפא
637 ,ערערה ,422 ,יאקוט
637 ,ערערה ,270 ,יבוס
637 ,ערערה ,276 ,כנעאן
637 ,ערערה ,358 ,לחוט
637 ,ערערה ,406 ,מאי זיאדה
637 ,ערערה ,440 ,מוזפר א-נואב
637 ,ערערה ,395 ,מירמיה
637 ,ערערה ,426 ,נוח איבראהים
637 ,ערערה ,287 ,ניסאן
637 ,ערערה ,398 ,נענע
637 ,ערערה ,374 ,ס'פד
637 ,ערערה ,291 ,סיבויה
637 ,ערערה ,293 ,סיור
637 ,ערערה ,294 ,סינאן
637 ,ערערה ,101 ,עארה
637 ,ערערה ,303 ,עארוונה
637 ,ערערה ,439 ,עבהר
637 ,ערערה ,332 ,עבהרייה
637 ,ערערה ,304 ,עומר בן אל-ח'טאב
637 ,ערערה ,305 ,עות'מאן בן עפאן
637 ,ערערה ,306 ,עין אום א-ס'מל
637 ,ערערה ,385 ,עינאב
637 ,ערערה ,473 ,עלי בן אבי טאלב
637 ,ערערה ,9000 ,ערערה
637 ,ערערה ,310 ,פיניקיא
637 ,ערערה ,314 ,קוזח
637 ,ערערה ,637 ,קצר אל-פיד
637 ,ערערה ,6732 ,קרית חינוך
637 ,ערערה ,441 ,ראבעה אל-עדוויה
637 ,ערערה ,317 ,ראס א-נבע
637 ,ערערה ,318 ,ראס אל-עין
637 ,ערערה ,458 ,רתיבה אל-חפני
637 ,ערערה ,6740 ,שכונה מערבית צפונית
637 ,ערערה ,6742 ,שכונה צפונית מזרחית
637 ,ערערה ,402 ,תופיק זיאד
637 ,ערערה ,394 ,תות
637 ,ערערה ,323 ,תשרין
1192 ,ערערה-בנגב ,135 ,אבו בקר אל צדיק
1192 ,ערערה-בנגב ,193 ,אבו נואס
1192 ,ערערה-בנגב ,147 ,אבן חלדון
1192 ,ערערה-בנגב ,129 ,אבן סינא
1192 ,ערערה-בנגב ,166 ,אבן רשד
1192 ,ערערה-בנגב ,6730 ,אזור התעשייה
1192 ,ערערה-בנגב ,182 ,אל אנדלוס
1192 ,ערערה-בנגב ,122 ,אל אנסאר
1192 ,ערערה-בנגב ,130 ,אל בחירה
1192 ,ערערה-בנגב ,189 ,אל ביאן
1192 ,ערערה-בנגב ,148 ,אל זהארא
1192 ,ערערה-בנגב ,149 ,אל יממה
1192 ,ערערה-בנגב ,136 ,אל ירמוק
1192 ,ערערה-בנגב ,123 ,אל מוהאגרין
1192 ,ערערה-בנגב ,137 ,אל מועתסם
1192 ,ערערה-בנגב ,150 ,אל מידאן
1192 ,ערערה-בנגב ,151 ,אל סאדיק
1192 ,ערערה-בנגב ,161 ,אל קותר
1192 ,ערערה-בנגב ,124 ,אל שאפעי
1192 ,ערערה-בנגב ,131 ,אלאח'לאס
1192 ,ערערה-בנגב ,153 ,אלאסבאט
1192 ,ערערה-בנגב ,138 ,אלאסלאח
1192 ,ערערה-בנגב ,167 ,אלבאדיה
1192 ,ערערה-בנגב ,168 ,אלבירוני
1192 ,ערערה-בנגב ,177 ,אלג'ינאן
1192 ,ערערה-בנגב ,191 ,אלזייתון
1192 ,ערערה-בנגב ,116 ,אלח'נסאא
1192 ,ערערה-בנגב ,169 ,אלחורזמי
1192 ,ערערה-בנגב ,154 ,אלחרמין
1192 ,ערערה-בנגב ,117 ,אליסמין
1192 ,ערערה-בנגב ,192 ,אלמג'ד
1192 ,ערערה-בנגב ,162 ,אלמוסתקבל
1192 ,ערערה-בנגב ,163 ,אלמחבה
1192 ,ערערה-בנגב ,125 ,אלמראבטין
1192 ,ערערה-בנגב ,133 ,אלנהדא
1192 ,ערערה-בנגב ,128 ,אלנור
1192 ,ערערה-בנגב ,180 ,אלנורס
1192 ,ערערה-בנגב ,190 ,אלסבר
1192 ,ערערה-בנגב ,175 ,אלסדאקה
1192 ,ערערה-בנגב ,178 ,אלסחאבה
1192 ,ערערה-בנגב ,139 ,אלסלאם
1192 ,ערערה-בנגב ,126 ,אלספאא
1192 ,ערערה-בנגב ,183 ,אלע'רא
1192 ,ערערה-בנגב ,155 ,אלעורבה
1192 ,ערערה-בנגב ,140 ,אלענאן
1192 ,ערערה-בנגב ,174 ,אלפארוק
1192 ,ערערה-בנגב ,118 ,אלפאתח
1192 ,ערערה-בנגב ,181 ,אלפרג
1192 ,ערערה-בנגב ,156 ,אלפרדוס
1192 ,ערערה-בנגב ,141 ,אלקאדסיה
1192 ,ערערה-בנגב ,157 ,אלקודס
1192 ,ערערה-בנגב ,134 ,אלריאחין
1192 ,ערערה-בנגב ,171 ,אלריאן
1192 ,ערערה-בנגב ,158 ,אלרשיד
1192 ,ערערה-בנגב ,188 ,אלשאם
1192 ,ערערה-בנגב ,176 ,אלשמס
1192 ,ערערה-בנגב ,179 ,אלשרוק
1192 ,ערערה-בנגב ,172 ,אלתסאמח
1192 ,ערערה-בנגב ,119 ,אלתקווה
1192 ,ערערה-בנגב ,159 ,זוביידה
1192 ,ערערה-בנגב ,120 ,חאלד אבן אלוליד
1192 ,ערערה-בנגב ,127 ,חאמזה
1192 ,ערערה-בנגב ,121 ,חטיין
1192 ,ערערה-בנגב ,195 ,חליל אבו סעלוק
1192 ,ערערה-בנגב ,194 ,טאלב אבו עראר
1192 ,ערערה-בנגב ,132 ,טארק בן זיאד
1192 ,ערערה-בנגב ,173 ,טלחה בן אלזוביר
1192 ,ערערה-בנגב ,142 ,סלאח אל דין
1192 ,ערערה-בנגב ,184 ,סלמאן אלפארסי
1192 ,ערערה-בנגב ,146 ,סעד בן אבי וקאס
1192 ,ערערה-בנגב ,143 ,עומר אל מוכתאר
1192 ,ערערה-בנגב ,144 ,עלי בן אבי טאלב
1192 ,ערערה-בנגב ,145 ,עמרו בן אלאס
1192 ,ערערה-בנגב ,9000 ,ערערה-בנגב
1192 ,ערערה-בנגב ,6703 ,שכ 1
1192 ,ערערה-בנגב ,6713 ,שכ 21
1192 ,ערערה-בנגב ,6710 ,שכ 41
1192 ,ערערה-בנגב ,6712 ,שכ 51
1192 ,ערערה-בנגב ,6702 ,שכ 2
1192 ,ערערה-בנגב ,6714 ,שכ 22
1192 ,ערערה-בנגב ,6711 ,שכ 2ב
1192 ,ערערה-בנגב ,6704 ,שכ 3
1192 ,ערערה-בנגב ,6715 ,שכ 23
1192 ,ערערה-בנגב ,6705 ,שכ 4
1192 ,ערערה-בנגב ,6732 ,שכ 4א
1192 ,ערערה-בנגב ,6706 ,שכ 5
1192 ,ערערה-בנגב ,6731 ,שכ 5א
1192 ,ערערה-בנגב ,6707 ,שכ 6
1192 ,ערערה-בנגב ,6709 ,שכ 7
1192 ,ערערה-בנגב ,6708 ,שכ 8
1192 ,ערערה-בנגב ,160 ,תאבוק
591 ,עשרת ,112 ,אלה
591 ,עשרת ,111 ,ברקן
591 ,עשרת ,109 ,גומא
591 ,עשרת ,110 ,דולב
591 ,עשרת ,113 ,האגוז
591 ,עשרת ,102 ,האורן
591 ,עשרת ,115 ,האלון
591 ,עשרת ,107 ,האשל
591 ,עשרת ,105 ,הגפן
591 ,עשרת ,106 ,הדקל
591 ,עשרת ,114 ,הזית
591 ,עשרת ,116 ,החרוב
591 ,עשרת ,117 ,הפרדס
591 ,עשרת ,101 ,הרימון
591 ,עשרת ,104 ,השקמה
591 ,עשרת ,119 ,התאנה
591 ,עשרת ,120 ,התדהר
591 ,עשרת ,118 ,התמר
591 ,עשרת ,9000 ,עשרת
591 ,עשרת ,108 ,ריזה
591 ,עשרת ,103 ,שקד
53 ,עתלית ,130 ,אמת המים
53 ,עתלית ,174 ,אנפה
53 ,עתלית ,186 ,אסיף
53 ,עתלית ,6513 ,בריטיש קוטג'
53 ,עתלית ,131 ,גבעת הבריכות
53 ,עתלית ,6518 ,גבעת הפרחים
53 ,עתלית ,144 ,גבעת סביון
53 ,עתלית ,145 ,גבעת שרון
53 ,עתלית ,6517 ,גני עתלית
53 ,עתלית ,217 ,דגה
53 ,עתלית ,155 ,דרך הבנים
53 ,עתלית ,122 ,דרך הים
53 ,עתלית ,180 ,דרך השדות
53 ,עתלית ,115 ,האגוז
53 ,עתלית ,116 ,האורן
53 ,עתלית ,128 ,האיריס
53 ,עתלית ,134 ,האלון
53 ,עתלית ,151 ,הארגמן
53 ,עתלית ,117 ,האשל
53 ,עתלית ,105 ,הבציר
53 ,עתלית ,104 ,הגורן
53 ,עתלית ,159 ,הגיבורים
53 ,עתלית ,195 ,הגפן
53 ,עתלית ,194 ,הגת
53 ,עתלית ,149 ,הדקל
53 ,עתלית ,119 ,ההרדוף
53 ,עתלית ,127 ,הורד
53 ,עתלית ,204 ,הזית
53 ,עתלית ,198 ,הזמורה
53 ,עתלית ,109 ,החורש
53 ,עתלית ,192 ,החיטה
53 ,עתלית ,202 ,החרוב
53 ,עתלית ,196 ,היקב
53 ,עתלית ,156 ,היקינטון
53 ,עתלית ,103 ,הכלניות
53 ,עתלית ,197 ,הכרם
53 ,עתלית ,203 ,הלוחמים
53 ,עתלית ,153 ,הלוטוס
53 ,עתלית ,129 ,הלילך
53 ,עתלית ,102 ,המבצר
53 ,עתלית ,205 ,המגינים
53 ,עתלית ,201 ,המחתרות
53 ,עתלית ,112 ,המייסדים
53 ,עתלית ,173 ,המעיין
53 ,עתלית ,120 ,הנרקיסים
53 ,עתלית ,139 ,הסביון
53 ,עתלית ,154 ,הסחלב
53 ,עתלית ,150 ,הסייפן
53 ,עתלית ,124 ,הסלע
53 ,עתלית ,110 ,העליה
53 ,עתלית ,158 ,הפקאן
53 ,עתלית ,170 ,הצבעוני
53 ,עתלית ,182 ,הקלשון
53 ,עתלית ,123 ,הרימון
53 ,עתלית ,126 ,הרקפת
53 ,עתלית ,114 ,השקד
53 ,עתלית ,199 ,השריג
53 ,עתלית ,213 ,התורן
53 ,עתלית ,193 ,התירוש
53 ,עתלית ,147 ,התכלת
53 ,עתלית ,207 ,התקומה
53 ,עתלית ,208 ,חבצלת
53 ,עתלית ,176 ,חוות אהרונסון
53 ,עתלית ,152 ,חופית
53 ,עתלית ,184 ,חמניה
53 ,עתלית ,220 ,חצב
53 ,עתלית ,148 ,חצרות איכרים
53 ,עתלית ,187 ,חרמש
53 ,עתלית ,209 ,חרצית
53 ,עתלית ,146 ,יפה נוף
53 ,עתלית ,188 ,כותנה
53 ,עתלית ,206 ,כלנית
53 ,עתלית ,224 ,כרכום
53 ,עתלית ,6523 ,לב עתלית
53 ,עתלית ,218 ,לגונה
53 ,עתלית ,190 ,מגל
53 ,עתלית ,189 ,מורג
53 ,עתלית ,6500 ,מושבה א
53 ,עתלית ,6501 ,מושבה ב
53 ,עתלית ,185 ,מזמרה
53 ,עתלית ,183 ,מחרשה
53 ,עתלית ,215 ,מכמורת
53 ,עתלית ,172 ,מלח הארץ
53 ,עתלית ,6502 ,מעברה
53 ,עתלית ,211 ,מפרש
53 ,עתלית ,111 ,מרכז קליטה
53 ,עתלית ,106 ,משעול האשכולות
53 ,עתלית ,125 ,משעול הגפנים
53 ,עתלית ,6503 ,נוה דקל
53 ,עתלית ,6504 ,נוה משה
53 ,עתלית ,160 ,נחל אורן
53 ,עתלית ,166 ,נחל אלונה
53 ,עתלית ,167 ,נחל בוסתן
53 ,עתלית ,161 ,נחל ברקן
53 ,עתלית ,165 ,נחל גלים
53 ,עתלית ,162 ,נחל דליה
53 ,עתלית ,168 ,נחל הוד
53 ,עתלית ,163 ,נחל ספונים
53 ,עתלית ,164 ,נחל שלף
53 ,עתלית ,121 ,נתיב הכרמים
53 ,עתלית ,6514 ,סביוני עתלית
53 ,עתלית ,219 ,סירה
53 ,עתלית ,171 ,סמ האלמוג
53 ,עתלית ,142 ,סמ הארז
53 ,עתלית ,137 ,סמ הברוש
53 ,עתלית ,141 ,סמ היסמין
53 ,עתלית ,132 ,סמ הלוטם
53 ,עתלית ,140 ,סמ המרגנית
53 ,עתלית ,135 ,סמ הנורית
53 ,עתלית ,136 ,סמ הסיגלית
53 ,עתלית ,138 ,סמ התמר
53 ,עתלית ,223 ,סתוונית
53 ,עתלית ,212 ,עוגן
53 ,עתלית ,210 ,עין הים
53 ,עתלית ,9000 ,עתלית
53 ,עתלית ,179 ,פלמינגו
53 ,עתלית ,225 ,פרג
53 ,עתלית ,181 ,קציר
53 ,עתלית ,221 ,רותם
53 ,עתלית ,6505 ,ש חדש
53 ,עתלית ,6506 ,ש עמידר
53 ,עתלית ,214 ,שונית
53 ,עתלית ,222 ,שושן
53 ,עתלית ,157 ,שושנת הים
53 ,עתלית ,178 ,שחף
53 ,עתלית ,216 ,שייטים
53 ,עתלית ,6508 ,שכ אלון
53 ,עתלית ,6512 ,שכ ארגמן
53 ,עתלית ,6509 ,שכ הדרום
53 ,עתלית ,6510 ,שכ חברת המלח
53 ,עתלית ,6519 ,שכ חופית
53 ,עתלית ,6515 ,שכ יפה נוף
53 ,עתלית ,6520 ,שכ נחלים
53 ,עתלית ,6511 ,שכ קנדי
53 ,עתלית ,6516 ,שכ שושנת הים
53 ,עתלית ,177 ,שלדג
53 ,עתלית ,191 ,תלם
53 ,עתלית ,175 ,תמירון
3748 ,עתניאל ,9000 ,עתניאל
1151 ,פארן ,101 ,ברק
1151 ,פארן ,102 ,ורדית
1151 ,פארן ,103 ,ערבה
1151 ,פארן ,104 ,עשת
1151 ,פארן ,9000 ,פארן
1151 ,פארן ,105 ,ציחור
1151 ,פארן ,106 ,רעים
3768 ,פדואל ,101 ,אור עולם
3768 ,פדואל ,108 ,בית אל
3768 ,פדואל ,109 ,גבעת הלבונה
3768 ,פדואל ,102 ,גלעד
3768 ,פדואל ,107 ,הר המור
3768 ,פדואל ,104 ,הראל
3768 ,פדואל ,110 ,יפה נוף
3768 ,פדואל ,103 ,לבנון
3768 ,פדואל ,111 ,משעול העוז
3768 ,פדואל ,106 ,עיר דוד
3768 ,פדואל ,9000 ,פדואל
3768 ,פדואל ,105 ,ציון
750 ,פדויים ,9000 ,פדויים
838 ,פדיה ,9000 ,פדיה
1104 ,פוריה - כפר עבודה ,102 ,דרך בית העלמין
1104 ,פוריה - כפר עבודה ,111 ,האלה
1104 ,פוריה - כפר עבודה ,107 ,האלון
1104 ,פוריה - כפר עבודה ,115 ,הארזים
1104 ,פוריה - כפר עבודה ,106 ,הגפן
1104 ,פוריה - כפר עבודה ,108 ,ההדס
1104 ,פוריה - כפר עבודה ,105 ,הזית
1104 ,פוריה - כפר עבודה ,110 ,החרוב
1104 ,פוריה - כפר עבודה ,113 ,הערבה
1104 ,פוריה - כפר עבודה ,114 ,הרותם
1104 ,פוריה - כפר עבודה ,101 ,הרימון
1104 ,פוריה - כפר עבודה ,112 ,השיטה
1104 ,פוריה - כפר עבודה ,116 ,השיקמה
1104 ,פוריה - כפר עבודה ,104 ,השקד
1104 ,פוריה - כפר עבודה ,103 ,התאנה
1104 ,פוריה - כפר עבודה ,109 ,התמר
1104 ,פוריה - כפר עבודה ,9000 ,פוריה - כפר עבודה
1105 ,פוריה - נווה עובד ,7001 ,בי"ח פוריה
1105 ,פוריה - נווה עובד ,102 ,הנוריות
1105 ,פוריה - נווה עובד ,101 ,מרכז קליטה
1105 ,פוריה - נווה עובד ,9000 ,פוריה - נווה עובד
1313 ,פוריה עילית ,101 ,דרך החמה
1313 ,פוריה עילית ,102 ,דרך החצב
1313 ,פוריה עילית ,103 ,דרך הים
1313 ,פוריה עילית ,104 ,דרך המצוק
1313 ,פוריה עילית ,105 ,דרך העמק
1313 ,פוריה עילית ,106 ,דרך השדה
1313 ,פוריה עילית ,107 ,דרך השקד
1313 ,פוריה עילית ,108 ,דרך התבור
1313 ,פוריה עילית ,109 ,הזית
1313 ,פוריה עילית ,110 ,הרימון
1313 ,פוריה עילית ,111 ,השיזף
1313 ,פוריה עילית ,9000 ,פוריה עילית
537 ,פוריידיס ,6731 ,אלעין
537 ,פוריידיס ,6711 ,השיכון
537 ,פוריידיס ,6722 ,ט'הראת
537 ,פוריידיס ,6720 ,מרכז הכפר
537 ,פוריידיס ,9000 ,פוריידיס
537 ,פוריידיס ,115 ,צריפים
537 ,פוריידיס ,106 ,רח 10
537 ,פוריידיס ,119 ,רח 1
537 ,פוריידיס ,121 ,רח 11
537 ,פוריידיס ,117 ,רח 2
537 ,פוריידיס ,108 ,רח 12
537 ,פוריידיס ,120 ,רח 3
537 ,פוריידיס ,109 ,רח 13
537 ,פוריידיס ,103 ,רח 4
537 ,פוריידיס ,110 ,רח 14
537 ,פוריידיס ,111 ,רח 14 א
537 ,פוריידיס ,112 ,רח 14 ב
537 ,פוריידיס ,101 ,רח 5
537 ,פוריידיס ,102 ,רח 5 א
537 ,פוריידיס ,113 ,רח 15
537 ,פוריידיס ,114 ,רח 15 א
537 ,פוריידיס ,104 ,רח 6
537 ,פוריידיס ,118 ,רח 6 א
537 ,פוריידיס ,105 ,רח 7
537 ,פוריידיס ,116 ,רח 7 א
537 ,פוריידיס ,122 ,רח 8
537 ,פוריידיס ,107 ,רח 9
537 ,פוריידיס ,6721 ,שטח בנוי
537 ,פוריידיס ,6730 ,שכונה מזרחית
537 ,פוריידיס ,6710 ,שכונה צפונית
767 ,פורת ,114 ,אשר
767 ,פורת ,117 ,אתרוג
767 ,פורת ,107 ,בנימין
767 ,פורת ,104 ,גד
767 ,פורת ,103 ,דן
767 ,פורת ,116 ,הדס
767 ,פורת ,101 ,הותיקים
767 ,פורת ,100 ,הראשונים
767 ,פורת ,115 ,הרב מכלוף יאנה
767 ,פורת ,112 ,זבולון
767 ,פורת ,109 ,יהודה
767 ,פורת ,108 ,יוסף
767 ,פורת ,106 ,יששכר
767 ,פורת ,111 ,לוי
767 ,פורת ,113 ,נפתלי
767 ,פורת ,9000 ,פורת
767 ,פורת ,105 ,רואבן
767 ,פורת ,102 ,שבטי ישראל
767 ,פורת ,110 ,שמעון
749 ,פטיש ,9000 ,פטיש
1185 ,פלך ,9000 ,פלך
597 ,פלמחים ,101 ,אולפן
597 ,פלמחים ,7001 ,מחנה הילה
597 ,פלמחים ,9000 ,פלמחים
3723 ,פני חבר ,9000 ,פני חבר
3659 ,פסגות ,101 ,ארבל
3659 ,פסגות ,102 ,גלבוע
3659 ,פסגות ,103 ,גריזים
3659 ,פסגות ,104 ,הר ההר
3659 ,פסגות ,105 ,חורב
3659 ,פסגות ,106 ,כרמל
3659 ,פסגות ,107 ,מירון
3659 ,פסגות ,108 ,נבו
3659 ,פסגות ,109 ,סיני
3659 ,פסגות ,110 ,עצמון
3659 ,פסגות ,111 ,פארן
3659 ,פסגות ,9000 ,פסגות
3659 ,פסגות ,112 ,תבור
535 ,פסוטה ,101 ,אבן סינא
535 ,פסוטה ,102 ,אבן רושד
535 ,פסוטה ,103 ,אלאכטל
535 ,פסוטה ,104 ,אלאנואר
535 ,פסוטה ,105 ,אלבאבור
535 ,פסוטה ,106 ,אלבוסתאן
535 ,פסוטה ,107 ,אלביאד
535 ,פסוטה ,108 ,אלביאדר
535 ,פסוטה ,109 ,אלביארה
535 ,פסוטה ,110 ,אלבירכה
535 ,פסוטה ,111 ,אלג'חאליף
535 ,פסוטה ,112 ,אלדרג'ה
535 ,פסוטה ,113 ,אלוזה
535 ,פסוטה ,114 ,אלזיתון
535 ,פסוטה ,115 ,אלזקאק
535 ,פסוטה ,116 ,אלח'רב
535 ,פסוטה ,117 ,אלח'רוב
535 ,פסוטה ,118 ,אליאסמין
535 ,פסוטה ,119 ,אלכניסה
535 ,פסוטה ,120 ,אלכרום
535 ,פסוטה ,121 ,אלמידאן
535 ,פסוטה ,122 ,אלמעסרה
535 ,פסוטה ,123 ,אלמקלע
535 ,פסוטה ,124 ,אלמראח
535 ,פסוטה ,125 ,אלמרג'
535 ,פסוטה ,126 ,אלמרכז
535 ,פסוטה ,127 ,אלנקארה
535 ,פסוטה ,128 ,אלנרג'ס
535 ,פסוטה ,129 ,אלסומאכה
535 ,פסוטה ,130 ,אלסלעה
535 ,פסוטה ,131 ,אלסמארה
535 ,פסוטה ,132 ,אלסנאבל
535 ,פסוטה ,133 ,אלעין
535 ,פסוטה ,134 ,אלקלעה
535 ,פסוטה ,135 ,אלרביע
535 ,פסוטה ,136 ,אלרויסה
535 ,פסוטה ,137 ,אלרומאן
535 ,פסוטה ,138 ,אלריחאן
535 ,פסוטה ,139 ,אלריף
535 ,פסוטה ,140 ,אלשועלה
535 ,פסוטה ,141 ,באב אלעורה
535 ,פסוטה ,142 ,גובראן חליל גובראן
535 ,פסוטה ,143 ,הקדושה תרזה
535 ,פסוטה ,144 ,יוחנא פאולוס השני
535 ,פסוטה ,145 ,יחיא בן עדי
535 ,פסוטה ,147 ,מאר אליאס
535 ,פסוטה ,148 ,סנונו
535 ,פסוטה ,149 ,פאולוס השישי
535 ,פסוטה ,146 ,פטריארך לחאם
535 ,פסוטה ,9000 ,פסוטה
535 ,פסוטה ,150 ,תטראמה
2059 ,פעמי תש"ז ,101 ,הגפן
2059 ,פעמי תש"ז ,102 ,הזית
2059 ,פעמי תש"ז ,103 ,החיטה
2059 ,פעמי תש"ז ,104 ,הרימון
2059 ,פעמי תש"ז ,105 ,השעורה
2059 ,פעמי תש"ז ,106 ,התאנה
2059 ,פעמי תש"ז ,107 ,התמר
2059 ,פעמי תש"ז ,9000 ,פעמי תש"ז
3615 ,פצאל ,9000 ,פצאל
536 ,פקיעין (בוקייעה) ,111 ,אבו גראד
536 ,פקיעין (בוקייעה) ,191 ,אלאחאא
536 ,פקיעין (בוקייעה) ,129 ,אלאמל
536 ,פקיעין (בוקייעה) ,195 ,אלאסיל
536 ,פקיעין (בוקייעה) ,105 ,אלבטמה
536 ,פקיעין (בוקייעה) ,130 ,אלביאדה
536 ,פקיעין (בוקייעה) ,150 ,אלביאדר
536 ,פקיעין (בוקייעה) ,194 ,אלבלאן
536 ,פקיעין (בוקייעה) ,169 ,אלבלסם
536 ,פקיעין (בוקייעה) ,143 ,אלברג'
536 ,פקיעין (בוקייעה) ,104 ,אלברזה
536 ,פקיעין (בוקייעה) ,135 ,אלברייה
536 ,פקיעין (בוקייעה) ,177 ,אלג'אר
536 ,פקיעין (בוקייעה) ,163 ,אלג'בל
536 ,פקיעין (בוקייעה) ,173 ,אלג'וז
536 ,פקיעין (בוקייעה) ,155 ,אלג'ורי
536 ,פקיעין (בוקייעה) ,199 ,אלג'זאל
536 ,פקיעין (בוקייעה) ,113 ,אלגחר
536 ,פקיעין (בוקייעה) ,117 ,אלהדא
536 ,פקיעין (בוקייעה) ,185 ,אלהדהד
536 ,פקיעין (בוקייעה) ,161 ,אלואדי
536 ,פקיעין (בוקייעה) ,156 ,אלוורוד
536 ,פקיעין (בוקייעה) ,144 ,אלופאא
536 ,פקיעין (בוקייעה) ,147 ,אלזהור
536 ,פקיעין (בוקייעה) ,114 ,אלזיר
536 ,פקיעין (בוקייעה) ,119 ,אלזיתון
536 ,פקיעין (בוקייעה) ,176 ,אלזנבק
536 ,פקיעין (בוקייעה) ,110 ,אלזערור
536 ,פקיעין (בוקייעה) ,141 ,אלח'לוה
536 ,פקיעין (בוקייעה) ,140 ,אלח'רובה
536 ,פקיעין (בוקייעה) ,159 ,אלחור
536 ,פקיעין (בוקייעה) ,133 ,אלחסאד
536 ,פקיעין (בוקייעה) ,148 ,אלחקמה
536 ,פקיעין (בוקייעה) ,172 ,אללוז
536 ,פקיעין (בוקייעה) ,158 ,אלליימון
536 ,פקיעין (בוקייעה) ,166 ,אלמג'ד
536 ,פקיעין (בוקייעה) ,122 ,אלמג'סל
536 ,פקיעין (בוקייעה) ,149 ,אלמזחלוני
536 ,פקיעין (בוקייעה) ,127 ,אלמחג'ר אלגרבי
536 ,פקיעין (בוקייעה) ,146 ,אלמטבלה
536 ,פקיעין (בוקייעה) ,157 ,אלמיאדין
536 ,פקיעין (בוקייעה) ,139 ,אלמיס
536 ,פקיעין (בוקייעה) ,106 ,אלמל
536 ,פקיעין (בוקייעה) ,118 ,אלמסררה
536 ,פקיעין (בוקייעה) ,167 ,אלמראבט
536 ,פקיעין (בוקייעה) ,134 ,אלמראח
536 ,פקיעין (בוקייעה) ,142 ,אלנוורס
536 ,פקיעין (בוקייעה) ,120 ,אלנור
536 ,פקיעין (בוקייעה) ,180 ,אלנח'יל
536 ,פקיעין (בוקייעה) ,187 ,אלנרג'ס
536 ,פקיעין (בוקייעה) ,178 ,אלסבאר
536 ,פקיעין (בוקייעה) ,103 ,אלסלאם
536 ,פקיעין (בוקייעה) ,197 ,אלסמאק
536 ,פקיעין (בוקייעה) ,108 ,אלסנדיאן
536 ,פקיעין (בוקייעה) ,179 ,אלספסאף
536 ,פקיעין (בוקייעה) ,171 ,אלסריס
536 ,פקיעין (בוקייעה) ,198 ,אלענאב
536 ,פקיעין (בוקייעה) ,112 ,אלעקבה
536 ,פקיעין (בוקייעה) ,124 ,אלעראדין
536 ,פקיעין (בוקייעה) ,165 ,אלערזאן
536 ,פקיעין (בוקייעה) ,109 ,אלקנדול
536 ,פקיעין (בוקייעה) ,132 ,אלקסב
536 ,פקיעין (בוקייעה) ,175 ,אלקרונפול
536 ,פקיעין (בוקייעה) ,128 ,אלרג'ב
536 ,פקיעין (בוקייעה) ,115 ,אלרומאן
536 ,פקיעין (בוקייעה) ,182 ,אלריחאן
536 ,פקיעין (בוקייעה) ,186 ,אלשברק
536 ,פקיעין (בוקייעה) ,107 ,אלשיכונאת
536 ,פקיעין (בוקייעה) ,174 ,אלתבג'
536 ,פקיעין (בוקייעה) ,121 ,אלתות
536 ,פקיעין (בוקייעה) ,136 ,אלתינה
536 ,פקיעין (בוקייעה) ,123 ,אלתפאח
536 ,פקיעין (בוקייעה) ,153 ,ארעיש אלענב
536 ,פקיעין (בוקייעה) ,152 ,באב אלואדי
536 ,פקיעין (בוקייעה) ,168 ,באב אלמרג'
536 ,פקיעין (בוקייעה) ,137 ,ביר אלמתחנה
536 ,פקיעין (בוקייעה) ,116 ,בית אלשעב
536 ,פקיעין (בוקייעה) ,131 ,ואדי אלמרבאע
536 ,פקיעין (בוקייעה) ,193 ,כלת עלם
536 ,פקיעין (בוקייעה) ,145 ,כניס אליהוד
536 ,פקיעין (בוקייעה) ,164 ,כניסת אלקאתוליק
536 ,פקיעין (בוקייעה) ,154 ,כרום אלשראקה
536 ,פקיעין (בוקייעה) ,196 ,כרם אלבדרא
536 ,פקיעין (בוקייעה) ,151 ,לבוד אם נעימה
536 ,פקיעין (בוקייעה) ,190 ,מלעב קורת אלקדם
536 ,פקיעין (בוקייעה) ,188 ,מסטאח אלקטין
536 ,פקיעין (בוקייעה) ,184 ,נאדי אלאחווה
536 ,פקיעין (בוקייעה) ,181 ,סיידת אלג'ליל
536 ,פקיעין (בוקייעה) ,200 ,סירייה
536 ,פקיעין (בוקייעה) ,138 ,עג'רמה
536 ,פקיעין (בוקייעה) ,162 ,עין אלבסתאן
536 ,פקיעין (בוקייעה) ,125 ,עין אלבראניה
536 ,פקיעין (בוקייעה) ,160 ,עין אלג'נאן
536 ,פקיעין (בוקייעה) ,9000 ,פקיעין (בוקייעה)
536 ,פקיעין (בוקייעה) ,102 ,פקיעין המערבית
281 ,פקיעין חדשה ,9000 ,פקיעין חדשה
7800 ,פרדס חנה-כרכור ,198 ,אבוקה
7800 ,פרדס חנה-כרכור ,521 ,אביבים
7800 ,פרדס חנה-כרכור ,193 ,אבן גבירול
7800 ,פרדס חנה-כרכור ,298 ,אגס
7800 ,פרדס חנה-כרכור ,146 ,אזוב
7800 ,פרדס חנה-כרכור ,6568 ,אזור תעשיה צפוני
7800 ,פרדס חנה-כרכור ,262 ,אחדות
7800 ,פרדס חנה-כרכור ,502 ,אחוזה
7800 ,פרדס חנה-כרכור ,6553 ,אחוזה הירוקה
7800 ,פרדס חנה-כרכור ,283 ,אילת
7800 ,פרדס חנה-כרכור ,241 ,איריס
7800 ,פרדס חנה-כרכור ,240 ,אלה
7800 ,פרדס חנה-כרכור ,509 ,אלומות
7800 ,פרדס חנה-כרכור ,303 ,אלון
7800 ,פרדס חנה-כרכור ,112 ,אלונים
7800 ,פרדס חנה-כרכור ,544 ,אלחריזי
7800 ,פרדס חנה-כרכור ,271 ,אליהו
7800 ,פרדס חנה-כרכור ,273 ,אלישע
7800 ,פרדס חנה-כרכור ,577 ,אלכסנדרוני
7800 ,פרדס חנה-כרכור ,230 ,אנקור
7800 ,פרדס חנה-כרכור ,522 ,אסיף
7800 ,פרדס חנה-כרכור ,536 ,אסתר
7800 ,פרדס חנה-כרכור ,296 ,אפרסק
7800 ,פרדס חנה-כרכור ,571 ,אצ"ל
7800 ,פרדס חנה-כרכור ,292 ,ארבל
7800 ,פרדס חנה-כרכור ,208 ,ארז
7800 ,פרדס חנה-כרכור ,316 ,אשכולית
7800 ,פרדס חנה-כרכור ,184 ,אשל
7800 ,פרדס חנה-כרכור ,310 ,אתרוג
7800 ,פרדס חנה-כרכור ,264 ,בזל
7800 ,פרדס חנה-כרכור ,534 ,ביל"ו
7800 ,פרדס חנה-כרכור ,164 ,ביס חקלאי
7800 ,פרדס חנה-כרכור ,151 ,בית אל
7800 ,פרדס חנה-כרכור ,150 ,בן שטח
7800 ,פרדס חנה-כרכור ,597 ,בציר
7800 ,פרדס חנה-כרכור ,185 ,בר גיורא
7800 ,פרדס חנה-כרכור ,216 ,בר כוכבא
7800 ,פרדס חנה-כרכור ,564 ,ברקאי
7800 ,פרדס חנה-כרכור ,239 ,ברקן
7800 ,פרדס חנה-כרכור ,581 ,ברקת
7800 ,פרדס חנה-כרכור ,558 ,בשן
7800 ,פרדס חנה-כרכור ,6501 ,בתי בלום
7800 ,פרדס חנה-כרכור ,6502 ,בתי קומות
7800 ,פרדס חנה-כרכור ,250 ,גאולה
7800 ,פרדס חנה-כרכור ,530 ,גבע
7800 ,פרדס חנה-כרכור ,6544 ,גבעת הבאר
7800 ,פרדס חנה-כרכור ,6543 ,גבעת הברושים
7800 ,פרדס חנה-כרכור ,6545 ,גבעת היהלום
7800 ,פרדס חנה-כרכור ,579 ,גבעתי
7800 ,פרדס חנה-כרכור ,578 ,גדנ"ע
7800 ,פרדס חנה-כרכור ,565 ,גולן
7800 ,פרדס חנה-כרכור ,576 ,גולני
7800 ,פרדס חנה-כרכור ,237 ,גומא
7800 ,פרדס חנה-כרכור ,199 ,גורדוניה
7800 ,פרדס חנה-כרכור ,223 ,גוש חלב
7800 ,פרדס חנה-כרכור ,320 ,גינת אגוז
7800 ,פרדס חנה-כרכור ,235 ,גלבוע
7800 ,פרדס חנה-כרכור ,124 ,גלעד
7800 ,פרדס חנה-כרכור ,307 ,גמלא
7800 ,פרדס חנה-כרכור ,226 ,גן עוז
7800 ,פרדס חנה-כרכור ,6572 ,גן שלמה
7800 ,פרדס חנה-כרכור ,6561 ,גני סמדר
7800 ,פרדס חנה-כרכור ,255 ,דביר
7800 ,פרדס חנה-כרכור ,352 ,דגן
7800 ,פרדס חנה-כרכור ,504 ,דגניה
7800 ,פרדס חנה-כרכור ,305 ,דובדבן
7800 ,פרדס חנה-כרכור ,510 ,דודאים
7800 ,פרדס חנה-כרכור ,259 ,דוכיפת
7800 ,פרדס חנה-כרכור ,236 ,דולב
7800 ,פרדס חנה-כרכור ,568 ,דן
7800 ,פרדס חנה-כרכור ,214 ,דניאל
7800 ,פרדס חנה-כרכור ,567 ,דפנה
7800 ,פרדס חנה-כרכור ,153 ,דרור
7800 ,פרדס חנה-כרכור ,456 ,דרך גל הזהב
7800 ,פרדס חנה-כרכור ,113 ,דרך הבנים
7800 ,פרדס חנה-כרכור ,104 ,דרך הים
7800 ,פרדס חנה-כרכור ,225 ,דרך הציפורים
7800 ,פרדס חנה-כרכור ,561 ,דרך הרופא
7800 ,פרדס חנה-כרכור ,156 ,דרך יזרעאל
7800 ,פרדס חנה-כרכור ,266 ,דרך למרחב
7800 ,פרדס חנה-כרכור ,194 ,דרך משמרות
7800 ,פרדס חנה-כרכור ,125 ,דרך פיק"א
7800 ,פרדס חנה-כרכור ,508 ,דרך קדמה
7800 ,פרדס חנה-כרכור ,196 ,דרכי נועם
7800 ,פרדס חנה-כרכור ,212 ,האדנים
7800 ,פרדס חנה-כרכור ,600 ,האומנות
7800 ,פרדס חנה-כרכור ,102 ,האורנים
7800 ,פרדס חנה-כרכור ,261 ,האחווה
7800 ,פרדס חנה-כרכור ,598 ,האיכר
7800 ,פרדס חנה-כרכור ,563 ,האקליפטוס
7800 ,פרדס חנה-כרכור ,142 ,הבוטנים
7800 ,פרדס חנה-כרכור ,311 ,הבוסתן
7800 ,פרדס חנה-כרכור ,574 ,הבריגדה
7800 ,פרדס חנה-כרכור ,515 ,הברכה
7800 ,פרדס חנה-כרכור ,206 ,הגאון
7800 ,פרדס חנה-כרכור ,371 ,הגבורה
7800 ,פרדס חנה-כרכור ,147 ,הגדוד
7800 ,פרדס חנה-כרכור ,559 ,הגורן
7800 ,פרדס חנה-כרכור ,438 ,הגיתית
7800 ,פרדס חנה-כרכור ,501 ,הגליל
7800 ,פרדס חנה-כרכור ,312 ,הגלעין
7800 ,פרדס חנה-כרכור ,114 ,הגנה
7800 ,פרדס חנה-כרכור ,107 ,הגפן
7800 ,פרדס חנה-כרכור ,132 ,הדסים
7800 ,פרדס חנה-כרכור ,105 ,הדקלים
7800 ,פרדס חנה-כרכור ,139 ,הדרים
7800 ,פרדס חנה-כרכור ,6573 ,הדרים הצעירה
7800 ,פרדס חנה-כרכור ,161 ,ההסתדרות
7800 ,פרדס חנה-כרכור ,136 ,הוד
7800 ,פרדס חנה-כרכור ,186 ,הזית
7800 ,פרדס חנה-כרכור ,207 ,החורש
7800 ,פרדס חנה-כרכור ,631 ,החי"ל
7800 ,פרדס חנה-כרכור ,632 ,החלוץ
7800 ,פרדס חנה-כרכור ,444 ,החליל
7800 ,פרדס חנה-כרכור ,589 ,החץ
7800 ,פרדס חנה-כרכור ,181 ,הטללים
7800 ,פרדס חנה-כרכור ,442 ,היובל
7800 ,פרדס חנה-כרכור ,535 ,היוגב
7800 ,פרדס חנה-כרכור ,551 ,הימאים
7800 ,פרדס חנה-כרכור ,313 ,הכרם
7800 ,פרדס חנה-כרכור ,569 ,המגדל
7800 ,פרדס חנה-כרכור ,531 ,המגינים
7800 ,פרדס חנה-כרכור ,295 ,המדרשייה
7800 ,פרדס חנה-כרכור ,506 ,המושב
7800 ,פרדס חנה-כרכור ,422 ,המזמור
7800 ,פרדס חנה-כרכור ,503 ,המייסדים
7800 ,פרדס חנה-כרכור ,421 ,המיתר
7800 ,פרדס חנה-כרכור ,111 ,המלאכה
7800 ,פרדס חנה-כרכור ,257 ,המסילה
7800 ,פרדס חנה-כרכור ,401 ,המעין
7800 ,פרדס חנה-כרכור ,155 ,המעלה
7800 ,פרדס חנה-כרכור ,141 ,המשמר
7800 ,פרדס חנה-כרכור ,436 ,הנבל
7800 ,פרדס חנה-כרכור ,106 ,הנדיב
7800 ,פרדס חנה-כרכור ,119 ,הנוטר
7800 ,פרדס חנה-כרכור ,526 ,הנח"ל
7800 ,פרדס חנה-כרכור ,447 ,הנחילות
7800 ,פרדס חנה-כרכור ,584 ,הניצנים
7800 ,פרדס חנה-כרכור ,118 ,הנשיא
7800 ,פרדס חנה-כרכור ,538 ,העליה
7800 ,פרדס חנה-כרכור ,599 ,העמק
7800 ,פרדס חנה-כרכור ,318 ,העסיס
7800 ,פרדס חנה-כרכור ,200 ,העפרוני
7800 ,פרדס חנה-כרכור ,100 ,הערער
7800 ,פרדס חנה-כרכור ,437 ,העשור
7800 ,פרדס חנה-כרכור ,420 ,הפעמון
7800 ,פרדס חנה-כרכור ,633 ,הצבי
7800 ,פרדס חנה-כרכור ,319 ,הצוף
7800 ,פרדס חנה-כרכור ,176 ,הצנחנים
7800 ,פרדס חנה-כרכור ,140 ,הצפירה
7800 ,פרדס חנה-כרכור ,315 ,הקטיף
7800 ,פרדס חנה-כרכור ,446 ,הקרן
7800 ,פרדס חנה-כרכור ,556 ,הקשת
7800 ,פרדס חנה-כרכור ,555 ,הראל
7800 ,פרדס חנה-כרכור ,238 ,הרדוף
7800 ,פרדס חנה-כרכור ,103 ,הרימון
7800 ,פרדס חנה-כרכור ,110 ,השדה
7800 ,פרדס חנה-כרכור ,120 ,השומר
7800 ,פרדס חנה-כרכור ,529 ,השופטים
7800 ,פרדס חנה-כרכור ,562 ,השחר
7800 ,פרדס חנה-כרכור ,209 ,השיטה
7800 ,פרדס חנה-כרכור ,423 ,השיר
7800 ,פרדס חנה-כרכור ,430 ,השמינית
7800 ,פרדס חנה-כרכור ,513 ,השניים
7800 ,פרדס חנה-כרכור ,294 ,התנאים
7800 ,פרדס חנה-כרכור ,157 ,התעשיה
7800 ,פרדס חנה-כרכור ,450 ,התקווה
7800 ,פרדס חנה-כרכור ,242 ,ורד
7800 ,פרדס חנה-כרכור ,243 ,זהבית
7800 ,פרדס חנה-כרכור ,247 ,זכריה
7800 ,פרדס חנה-כרכור ,228 ,זמיר
7800 ,פרדס חנה-כרכור ,129 ,חבצלת
7800 ,פרדס חנה-כרכור ,258 ,חבקוק
7800 ,פרדס חנה-כרכור ,267 ,חברון
7800 ,פרדס חנה-כרכור ,204 ,חגי
7800 ,פרדס חנה-כרכור ,251 ,חגלה
7800 ,פרדס חנה-כרכור ,351 ,חיטה
7800 ,פרדס חנה-כרכור ,408 ,חיננית
7800 ,פרדס חנה-כרכור ,6554 ,חלומות כרכור
7800 ,פרדס חנה-כרכור ,137 ,חלוצים
7800 ,פרדס חנה-כרכור ,169 ,חן
7800 ,פרדס חנה-כרכור ,220 ,חסידה
7800 ,פרדס חנה-כרכור ,443 ,חצוצרה
7800 ,פרדס חנה-כרכור ,101 ,חרובים
7800 ,פרדס חנה-כרכור ,123 ,חרושת
7800 ,פרדס חנה-כרכור ,174 ,חרמון
7800 ,פרדס חנה-כרכור ,221 ,חשמונאים
7800 ,פרדס חנה-כרכור ,162 ,טוקאיר מוסד
7800 ,פרדס חנה-כרכור ,205 ,טוריים מרכז קליטה
7800 ,פרדס חנה-כרכור ,550 ,טייסים
7800 ,פרדס חנה-כרכור ,274 ,טפח
7800 ,פרדס חנה-כרכור ,192 ,יהודה הלוי
7800 ,פרדס חנה-כרכור ,636 ,יהלום
7800 ,פרדס חנה-כרכור ,595 ,יובל
7800 ,פרדס חנה-כרכור ,233 ,יונה
7800 ,פרדס חנה-כרכור ,246 ,יחזקאל
7800 ,פרדס חנה-כרכור ,148 ,ינשוף
7800 ,פרדס חנה-כרכור ,275 ,יקינטון
7800 ,פרדס חנה-כרכור ,560 ,ירושלים
7800 ,פרדס חנה-כרכור ,505 ,כוכב
7800 ,פרדס חנה-כרכור ,277 ,כוכבית
7800 ,פרדס חנה-כרכור ,248 ,כורש
7800 ,פרדס חנה-כרכור ,6004 ,כיכר אוריון
7800 ,פרדס חנה-כרכור ,6000 ,כיכר המושב כרכור
7800 ,פרדס חנה-כרכור ,435 ,כינור
7800 ,פרדס חנה-כרכור ,6002 ,ככר הבריכה
7800 ,פרדס חנה-כרכור ,6003 ,ככר המושבה
7800 ,פרדס חנה-כרכור ,6001 ,ככר מירקין
7800 ,פרדס חנה-כרכור ,517 ,כנרת
7800 ,פרדס חנה-כרכור ,245 ,כפר עבודה
7800 ,פרדס חנה-כרכור ,6552 ,כרכור
7800 ,פרדס חנה-כרכור ,224 ,כרמל
7800 ,פרדס חנה-כרכור ,278 ,כתימה
7800 ,פרדס חנה-כרכור ,165 ,לבונה
7800 ,פרדס חנה-כרכור ,279 ,לוטם
7800 ,פרדס חנה-כרכור ,575 ,לח"י
7800 ,פרדס חנה-כרכור ,280 ,לילך
7800 ,פרדס חנה-כרכור ,268 ,לימון
7800 ,פרדס חנה-כרכור ,166 ,לשם
7800 ,פרדס חנה-כרכור ,138 ,מגד
7800 ,פרדס חנה-כרכור ,244 ,מדרשת נועם
7800 ,פרדס חנה-כרכור ,211 ,מודיעין
7800 ,פרדס חנה-כרכור ,116 ,מוריה
7800 ,פרדס חנה-כרכור ,402 ,מורן
7800 ,פרדס חנה-כרכור ,566 ,מטולה
7800 ,פרדס חנה-כרכור ,403 ,מיצפור
7800 ,פרדס חנה-כרכור ,548 ,מירון
7800 ,פרדס חנה-כרכור ,222 ,מכבים
7800 ,פרדס חנה-כרכור ,580 ,מכורה
7800 ,פרדס חנה-כרכור ,249 ,מלאכי
7800 ,פרדס חנה-כרכור ,299 ,מנגו
7800 ,פרדס חנה-כרכור ,300 ,מנדרינה
7800 ,פרדס חנה-כרכור ,6503 ,מעון לעובד
7800 ,פרדס חנה-כרכור ,6504 ,מעון לעובד כ
7800 ,פרדס חנה-כרכור ,586 ,מעיין גנים
7800 ,פרדס חנה-כרכור ,590 ,מעלה גיבורים
7800 ,פרדס חנה-כרכור ,133 ,מעפילים
7800 ,פרדס חנה-כרכור ,210 ,מצדה
7800 ,פרדס חנה-כרכור ,439 ,מצילתיים
7800 ,פרדס חנה-כרכור ,309 ,מקווה
7800 ,פרדס חנה-כרכור ,404 ,מרגנית
7800 ,פרדס חנה-כרכור ,537 ,מרדכי
7800 ,פרדס חנה-כרכור ,281 ,מרווה
7800 ,פרדס חנה-כרכור ,6500 ,מרכז
7800 ,פרדס חנה-כרכור ,178 ,מרכז קליטה
7800 ,פרדס חנה-כרכור ,195 ,נבו
7800 ,פרדס חנה-כרכור ,518 ,נבטים
7800 ,פרדס חנה-כרכור ,557 ,נוגה
7800 ,פרדס חנה-כרכור ,6551 ,נוה איתנים
7800 ,פרדס חנה-כרכור ,6508 ,נוה הדרים
7800 ,פרדס חנה-כרכור ,163 ,נוה שלוה ביח
7800 ,פרדס חנה-כרכור ,6571 ,נווה גד
7800 ,פרדס חנה-כרכור ,6557 ,נווה גנים
7800 ,פרדס חנה-כרכור ,6560 ,נווה הדקלים
7800 ,פרדס חנה-כרכור ,6565 ,נווה התות
7800 ,פרדס חנה-כרכור ,6562 ,נווה פרדסים
7800 ,פרדס חנה-כרכור ,6563 ,נווה רותם
7800 ,פרדס חנה-כרכור ,321 ,נוי
7800 ,פרדס חנה-כרכור ,405 ,נורית
7800 ,פרדס חנה-כרכור ,516 ,נחלה
7800 ,פרדס חנה-כרכור ,253 ,נחליאלי
7800 ,פרדס חנה-כרכור ,202 ,נחמיה
7800 ,פרדס חנה-כרכור ,634 ,נחשון
7800 ,פרדס חנה-כרכור ,591 ,נטע
7800 ,פרדס חנה-כרכור ,532 ,ניל"י
7800 ,פרדס חנה-כרכור ,6564 ,ניצני השרון
7800 ,פרדס חנה-כרכור ,554 ,ניר
7800 ,פרדס חנה-כרכור ,539 ,נעורים
7800 ,פרדס חנה-כרכור ,254 ,נץ
7800 ,פרדס חנה-כרכור ,406 ,נרקיס
7800 ,פרדס חנה-כרכור ,229 ,נשר
7800 ,פרדס חנה-כרכור ,407 ,סביון
7800 ,פרדס חנה-כרכור ,545 ,סגולה
7800 ,פרדס חנה-כרכור ,115 ,סיני
7800 ,פרדס חנה-כרכור ,594 ,סמ אדם
7800 ,פרדס חנה-כרכור ,322 ,סמ בוסתן
7800 ,פרדס חנה-כרכור ,603 ,סמ האריגה
7800 ,פרדס חנה-כרכור ,570 ,סמ הגן
7800 ,פרדס חנה-כרכור ,289 ,סמ הדיה
7800 ,פרדס חנה-כרכור ,452 ,סמ הזוהר
7800 ,פרדס חנה-כרכור ,451 ,סמ הזיו
7800 ,פרדס חנה-כרכור ,601 ,סמ היצירה
7800 ,פרדס חנה-כרכור ,549 ,סמ המושב
7800 ,פרדס חנה-כרכור ,187 ,סמ המטע
7800 ,פרדס חנה-כרכור ,602 ,סמ הריקמה
7800 ,פרדס חנה-כרכור ,293 ,סמ התשבי
7800 ,פרדס חנה-כרכור ,454 ,סמ זריחה
7800 ,פרדס חנה-כרכור ,455 ,סמ חמה
7800 ,פרדס חנה-כרכור ,126 ,סמ חריש
7800 ,פרדס חנה-כרכור ,189 ,סמ חרצית
7800 ,פרדס חנה-כרכור ,234 ,סמ יודפת
7800 ,פרדס חנה-כרכור ,290 ,סמ ירדן
7800 ,פרדס חנה-כרכור ,301 ,סמ כלנית
7800 ,פרדס חנה-כרכור ,592 ,סמ מגשימים
7800 ,פרדס חנה-כרכור ,282 ,סמ מרחבים
7800 ,פרדס חנה-כרכור ,190 ,סמ סיגלית
7800 ,פרדס חנה-כרכור ,285 ,סמ פעמונית
7800 ,פרדס חנה-כרכור ,453 ,סמ פריחה
7800 ,פרדס חנה-כרכור ,287 ,סמ צאלון
7800 ,פרדס חנה-כרכור ,291 ,סמ צבעוני
7800 ,פרדס חנה-כרכור ,540 ,סמ צבר
7800 ,פרדס חנה-כרכור ,286 ,סמ צפורן
7800 ,פרדס חנה-כרכור ,269 ,סמ רותם
7800 ,פרדס חנה-כרכור ,191 ,סמ רקפת
7800 ,פרדס חנה-כרכור ,583 ,סמ רשף
7800 ,פרדס חנה-כרכור ,227 ,סמ שומרון
7800 ,פרדס חנה-כרכור ,308 ,סמ שיזף
7800 ,פרדס חנה-כרכור ,328 ,סמטת אפרסמון
7800 ,פרדס חנה-כרכור ,179 ,סמטת דגניה א'
7800 ,פרדס חנה-כרכור ,323 ,סמטת דגניה ב'
7800 ,פרדס חנה-כרכור ,512 ,סמטת הבונה
7800 ,פרדס חנה-כרכור ,324 ,סמטת הדורות
7800 ,פרדס חנה-כרכור ,514 ,סמטת הידידים
7800 ,פרדס חנה-כרכור ,326 ,סמטת התפוח
7800 ,פרדס חנה-כרכור ,372 ,סמטת מתיתיהו
7800 ,פרדס חנה-כרכור ,400 ,סמטת פאר
7800 ,פרדס חנה-כרכור ,252 ,סנונית
7800 ,פרדס חנה-כרכור ,635 ,ספיר
7800 ,פרדס חנה-כרכור ,441 ,עוגב
7800 ,פרדס חנה-כרכור ,284 ,עולש
7800 ,פרדס חנה-כרכור ,355 ,עומרים
7800 ,פרדס חנה-כרכור ,213 ,עזרא
7800 ,פרדס חנה-כרכור ,288 ,עיט
7800 ,פרדס חנה-כרכור ,306 ,עינב
7800 ,פרדס חנה-כרכור ,543 ,עירון
7800 ,פרדס חנה-כרכור ,170 ,עירית
7800 ,פרדס חנה-כרכור ,519 ,עלומים
7800 ,פרדס חנה-כרכור ,134 ,עמל
7800 ,פרדס חנה-כרכור ,524 ,עמר
7800 ,פרדס חנה-כרכור ,440 ,ענבל
7800 ,פרדס חנה-כרכור ,149 ,עציון
7800 ,פרדס חנה-כרכור ,143 ,עצמאות
7800 ,פרדס חנה-כרכור ,173 ,עצמון
7800 ,פרדס חנה-כרכור ,511 ,ערבה
7800 ,פרדס חנה-כרכור ,314 ,ערוגות
7800 ,פרדס חנה-כרכור ,327 ,ערמון
7800 ,פרדס חנה-כרכור ,188 ,פאר
7800 ,פרדס חנה-כרכור ,215 ,פדויים
7800 ,פרדס חנה-כרכור ,297 ,פומלית
7800 ,פרדס חנה-כרכור ,197 ,פלג
7800 ,פרדס חנה-כרכור ,117 ,פלמ"ח
7800 ,פרדס חנה-כרכור ,272 ,פנחס
7800 ,פרדס חנה-כרכור ,9000 ,פרדס חנה-כרכור
7800 ,פרדס חנה-כרכור ,637 ,פרחי הדר
7800 ,פרדס חנה-כרכור ,219 ,פשוש
7800 ,פרדס חנה-כרכור ,131 ,צהלה
7800 ,פרדס חנה-כרכור ,445 ,צליל
7800 ,פרדס חנה-כרכור ,432 ,צלצלי שמע
7800 ,פרדס חנה-כרכור ,433 ,צלצלי תרועה
7800 ,פרדס חנה-כרכור ,6566 ,צמרת המושבה
7800 ,פרדס חנה-כרכור ,203 ,צפניה
7800 ,פרדס חנה-כרכור ,525 ,צפרירים
7800 ,פרדס חנה-כרכור ,553 ,קארו יוסף
7800 ,פרדס חנה-כרכור ,6555 ,קדמת פרדס חנה
7800 ,פרדס חנה-כרכור ,175 ,קוממיות
7800 ,פרדס חנה-כרכור ,596 ,קורנית
7800 ,פרדס חנה-כרכור ,304 ,קלמנטינה
7800 ,פרדס חנה-כרכור ,507 ,קלע
7800 ,פרדס חנה-כרכור ,354 ,קמה
7800 ,פרדס חנה-כרכור ,572 ,קנאים
7800 ,פרדס חנה-כרכור ,523 ,קציר
7800 ,פרדס חנה-כרכור ,533 ,קרן היסוד
7800 ,פרדס חנה-כרכור ,144 ,ראשונים
7800 ,פרדס חנה-כרכור ,256 ,רבי עקיבא
7800 ,פרדס חנה-כרכור ,593 ,רביד
7800 ,פרדס חנה-כרכור ,152 ,רמב"ם
7800 ,פרדס חנה-כרכור ,108 ,רעות
7800 ,פרדס חנה-כרכור ,6506 ,ש גאולה
7800 ,פרדס חנה-כרכור ,6507 ,ש הפועלים
7800 ,פרדס חנה-כרכור ,6509 ,ש התימנים
7800 ,פרדס חנה-כרכור ,6510 ,ש חדש
7800 ,פרדס חנה-כרכור ,6525 ,ש יפה נוף
7800 ,פרדס חנה-כרכור ,6542 ,ש לויט
7800 ,פרדס חנה-כרכור ,6511 ,ש מגד
7800 ,פרדס חנה-כרכור ,6512 ,ש מגד א
7800 ,פרדס חנה-כרכור ,6513 ,ש מזרחי
7800 ,פרדס חנה-כרכור ,6514 ,ש ממשלתי
7800 ,פרדס חנה-כרכור ,6515 ,ש משכנות כ
7800 ,פרדס חנה-כרכור ,6516 ,ש נוה אפרים
7800 ,פרדס חנה-כרכור ,6517 ,ש נוה אשר
7800 ,פרדס חנה-כרכור ,6518 ,ש נוה מרדכי
7800 ,פרדס חנה-כרכור ,6519 ,ש נוה מרחב
7800 ,פרדס חנה-כרכור ,6520 ,ש נוה עובד
7800 ,פרדס חנה-כרכור ,6521 ,ש עובדים
7800 ,פרדס חנה-כרכור ,6522 ,ש עולים
7800 ,פרדס חנה-כרכור ,6523 ,ש עולים חדש
7800 ,פרדס חנה-כרכור ,6524 ,ש עמידר
7800 ,פרדס חנה-כרכור ,6526 ,ש עממי
7800 ,פרדס חנה-כרכור ,6527 ,ש פועל מזרחי
7800 ,פרדס חנה-כרכור ,6528 ,ש פועלים
7800 ,פרדס חנה-כרכור ,6529 ,ש פיקא
7800 ,פרדס חנה-כרכור ,6530 ,ש רמז
7800 ,פרדס חנה-כרכור ,6531 ,ש רסקו
7800 ,פרדס חנה-כרכור ,6532 ,ש שב"ל
7800 ,פרדס חנה-כרכור ,6533 ,ש תל שלום א
7800 ,פרדס חנה-כרכור ,6534 ,ש תל שלום ב
7800 ,פרדס חנה-כרכור ,527 ,שאננים
7800 ,פרדס חנה-כרכור ,265 ,שב"ל
7800 ,פרדס חנה-כרכור ,546 ,שבזי
7800 ,פרדס חנה-כרכור ,218 ,שבי ציון
7800 ,פרדס חנה-כרכור ,182 ,שביל התלמיד
7800 ,פרדס חנה-כרכור ,270 ,שד ציונות
7800 ,פרדס חנה-כרכור ,154 ,שד קרן קימת
7800 ,פרדס חנה-כרכור ,356 ,שדרות מחנה שמונים
7800 ,פרדס חנה-כרכור ,370 ,שוהם
7800 ,פרדס חנה-כרכור ,127 ,שונית
7800 ,פרדס חנה-כרכור ,431 ,שופר
7800 ,פרדס חנה-כרכור ,130 ,שושן
7800 ,פרדס חנה-כרכור ,231 ,שחף
7800 ,פרדס חנה-כרכור ,520 ,שיבולים
7800 ,פרדס חנה-כרכור ,6550 ,שכ אהבת ציון
7800 ,פרדס חנה-כרכור ,6558 ,שכ אורות
7800 ,פרדס חנה-כרכור ,6535 ,שכ אלון
7800 ,פרדס חנה-כרכור ,6536 ,שכ גאולה אסבסט
7800 ,פרדס חנה-כרכור ,6547 ,שכ האור
7800 ,פרדס חנה-כרכור ,6546 ,שכ הגבורים
7800 ,פרדס חנה-כרכור ,6567 ,שכ הדר
7800 ,פרדס חנה-כרכור ,6537 ,שכ החילים
7800 ,פרדס חנה-כרכור ,6548 ,שכ זוהר
7800 ,פרדס חנה-כרכור ,6569 ,שכ יובלים
7800 ,פרדס חנה-כרכור ,6541 ,שכ מור
7800 ,פרדס חנה-כרכור ,6538 ,שכ משכנות ישראל
7800 ,פרדס חנה-כרכור ,6556 ,שכ פרחי הדר
7800 ,פרדס חנה-כרכור ,6539 ,שכ קורן
7800 ,פרדס חנה-כרכור ,6570 ,שכ קנדי
7800 ,פרדס חנה-כרכור ,6549 ,שכ שבטי ישראל
7800 ,פרדס חנה-כרכור ,6540 ,שכון המשטרה
7800 ,פרדס חנה-כרכור ,232 ,שלדג
7800 ,פרדס חנה-כרכור ,542 ,שלום
7800 ,פרדס חנה-כרכור ,177 ,שלומציון
7800 ,פרדס חנה-כרכור ,325 ,שמואל הנגיד
7800 ,פרדס חנה-כרכור ,552 ,שמשון
7800 ,פרדס חנה-כרכור ,638 ,שניר
7800 ,פרדס חנה-כרכור ,350 ,שעורה
7800 ,פרדס חנה-כרכור ,171 ,שקדים
7800 ,פרדס חנה-כרכור ,183 ,שקמה
7800 ,פרדס חנה-כרכור ,410 ,תאשור
7800 ,פרדס חנה-כרכור ,353 ,תבואה
7800 ,פרדס חנה-כרכור ,172 ,תבור
7800 ,פרדס חנה-כרכור ,409 ,תדהר
7800 ,פרדס חנה-כרכור ,128 ,תומר
7800 ,פרדס חנה-כרכור ,434 ,תוף
7800 ,פרדס חנה-כרכור ,167 ,תותחנים
7800 ,פרדס חנה-כרכור ,263 ,תחיה
7800 ,פרדס חנה-כרכור ,135 ,תחכמוני
7800 ,פרדס חנה-כרכור ,168 ,תל אילן מוסד
7800 ,פרדס חנה-כרכור ,6559 ,תל יצחק
7800 ,פרדס חנה-כרכור ,201 ,תל צבי
7800 ,פרדס חנה-כרכור ,180 ,תלמים
7800 ,פרדס חנה-כרכור ,541 ,תלפיות
7800 ,פרדס חנה-כרכור ,302 ,תמר
7800 ,פרדס חנה-כרכור ,317 ,תפוז
7800 ,פרדס חנה-כרכור ,260 ,תקומה
7800 ,פרדס חנה-כרכור ,573 ,תרע"ג
7800 ,פרדס חנה-כרכור ,145 ,תרפ"ט
171 ,פרדסיה ,195 ,איילון
171 ,פרדסיה ,173 ,אשחר
171 ,פרדסיה ,160 ,בי"ח לב השרון
171 ,פרדסיה ,171 ,בשמת
171 ,פרדסיה ,6506 ,גני השרון
171 ,פרדסיה ,194 ,דן
171 ,פרדסיה ,191 ,דרך האחווה
171 ,פרדסיה ,128 ,דרך המייסדים
171 ,פרדסיה ,101 ,דרך הצנחנים
171 ,פרדסיה ,190 ,דרך הרעות
171 ,פרדסיה ,192 ,דרך השלווה
171 ,פרדסיה ,137 ,האגוז
171 ,פרדסיה ,205 ,האודם
171 ,פרדסיה ,154 ,האורן
171 ,פרדסיה ,131 ,האירוס
171 ,פרדסיה ,120 ,האלה
171 ,פרדסיה ,122 ,האלון
171 ,פרדסיה ,111 ,האקליפטוס
171 ,פרדסיה ,203 ,הארבל
171 ,פרדסיה ,123 ,הארז
171 ,פרדסיה ,156 ,האשל
171 ,פרדסיה ,116 ,הברוש
171 ,פרדסיה ,198 ,הבשור
171 ,פרדסיה ,201 ,הגלבוע
171 ,פרדסיה ,117 ,הגפן
171 ,פרדסיה ,148 ,הדובדבן
171 ,פרדסיה ,172 ,הדס
171 ,פרדסיה ,121 ,הדקל
171 ,פרדסיה ,6508 ,הדר השרון
171 ,פרדסיה ,144 ,הורד
171 ,פרדסיה ,139 ,הזית
171 ,פרדסיה ,132 ,החצב
171 ,פרדסיה ,135 ,החרוב
171 ,פרדסיה ,204 ,החרמון
171 ,פרדסיה ,104 ,החרצית
171 ,פרדסיה ,142 ,היסמין
171 ,פרדסיה ,196 ,הירדן
171 ,פרדסיה ,130 ,הכלנית
171 ,פרדסיה ,202 ,הכרמל
171 ,פרדסיה ,158 ,הנורית
171 ,פרדסיה ,127 ,הנרקיס
171 ,פרדסיה ,129 ,הסביון
171 ,פרדסיה ,155 ,הערבה
171 ,פרדסיה ,147 ,הפטל
171 ,פרדסיה ,149 ,הפיקוס
171 ,פרדסיה ,151 ,הפקאן
171 ,פרדסיה ,157 ,הצאלון
171 ,פרדסיה ,136 ,הצבר
171 ,פרדסיה ,145 ,הרותם
171 ,פרדסיה ,119 ,הרימון
171 ,פרדסיה ,125 ,הרקפת
171 ,פרדסיה ,143 ,השושן
171 ,פרדסיה ,134 ,השקד
171 ,פרדסיה ,133 ,השקמה
171 ,פרדסיה ,126 ,התאנה
171 ,פרדסיה ,200 ,התבור
171 ,פרדסיה ,146 ,התדהר
171 ,פרדסיה ,115 ,התהילה
171 ,פרדסיה ,138 ,התות
171 ,פרדסיה ,118 ,התמר
171 ,פרדסיה ,141 ,התפוח
171 ,פרדסיה ,176 ,חמנית
171 ,פרדסיה ,175 ,יערה
171 ,פרדסיה ,174 ,לוטם
171 ,פרדסיה ,183 ,לילך
171 ,פרדסיה ,6500 ,מחנה א
171 ,פרדסיה ,6501 ,מחנה ב
171 ,פרדסיה ,6502 ,מחנה ה
171 ,פרדסיה ,6503 ,מחנה ו
171 ,פרדסיה ,199 ,מירון
171 ,פרדסיה ,179 ,מרווה
171 ,פרדסיה ,6505 ,נאות השרון
171 ,פרדסיה ,181 ,ניצנית
171 ,פרדסיה ,170 ,סיגלית
171 ,פרדסיה ,185 ,סייפן
171 ,פרדסיה ,107 ,סמ הדרור
171 ,פרדסיה ,159 ,סמ הורד
171 ,פרדסיה ,109 ,סמ היונה
171 ,פרדסיה ,106 ,סמ השחף
171 ,פרדסיה ,108 ,סמ התור
171 ,פרדסיה ,184 ,עולש
171 ,פרדסיה ,197 ,ערוגות
171 ,פרדסיה ,103 ,פישר יונה
171 ,פרדסיה ,182 ,פרג
171 ,פרדסיה ,9000 ,פרדסיה
171 ,פרדסיה ,180 ,צבעוני
171 ,פרדסיה ,177 ,קינמון
171 ,פרדסיה ,178 ,ריחן
171 ,פרדסיה ,102 ,רמב"ם
171 ,פרדסיה ,6507 ,רמות נתניה
171 ,פרדסיה ,112 ,שבזי שלום
171 ,פרדסיה ,152 ,שביל הארז
171 ,פרדסיה ,114 ,שביל הארזים
171 ,פרדסיה ,140 ,שביל הרימון
171 ,פרדסיה ,153 ,שביל התמר
171 ,פרדסיה ,206 ,שגיא
171 ,פרדסיה ,110 ,שד בן גוריון
171 ,פרדסיה ,150 ,שד ההדרים
171 ,פרדסיה ,124 ,שד הנשיא
171 ,פרדסיה ,113 ,שד ניסים
171 ,פרדסיה ,193 ,שניר
171 ,פרדסיה ,105 ,שרת
599 ,פרוד ,9000 ,פרוד
2053 ,פרזון ,9000 ,פרזון
1231 ,פרי גן ,103 ,הגפן
1231 ,פרי גן ,101 ,הדקל
1231 ,פרי גן ,102 ,התאנה
1231 ,פרי גן ,9000 ,פרי גן
7900 ,פתח תקווה ,306 ,א ד ג
7900 ,פתח תקווה ,6567 ,א"ת פארק ינאי
7900 ,פתח תקווה ,1425 ,אבא ברדיצ'ב
7900 ,פתח תקווה ,256 ,אביאל
7900 ,פתח תקווה ,1288 ,אבידן דוד
7900 ,פתח תקווה ,492 ,אבימלך
7900 ,פתח תקווה ,101 ,אבן גבירול
7900 ,פתח תקווה ,102 ,אבן עזרא
7900 ,פתח תקווה ,313 ,אברבנאל
7900 ,פתח תקווה ,463 ,אגרון גרשון
7900 ,פתח תקווה ,620 ,אהבת ציון
7900 ,פתח תקווה ,874 ,אהרון הכהן בייפוס
7900 ,פתח תקווה ,417 ,אהרונוביץ יוסף
7900 ,פתח תקווה ,228 ,אהרונסון שרה
7900 ,פתח תקווה ,1426 ,אוגלר ישראל
7900 ,פתח תקווה ,796 ,אודם
7900 ,פתח תקווה ,1114 ,אונקלוס
7900 ,פתח תקווה ,232 ,אוסישקין מנחם
7900 ,פתח תקווה ,749 ,אופיר
7900 ,פתח תקווה ,493 ,אופקים
7900 ,פתח תקווה ,563 ,אור יחזקאל
7900 ,פתח תקווה ,124 ,אורלוב זאב
7900 ,פתח תקווה ,324 ,אורלנסקי
7900 ,פתח תקווה ,1179 ,אורן אבי
7900 ,פתח תקווה ,269 ,אושר גולדוסר
7900 ,פתח תקווה ,702 ,אז"ר
7900 ,פתח תקווה ,6565 ,אזור מסחר
7900 ,פתח תקווה ,166 ,אזור תעשיה החדש
7900 ,פתח תקווה ,160 ,אזור תעשיה סגולה
7900 ,פתח תקווה ,6566 ,אזור תעשייה חדש
7900 ,פתח תקווה ,318 ,אחד העם
7900 ,פתח תקווה ,450 ,אחוה יעקב
7900 ,פתח תקווה ,825 ,אחים יפה
7900 ,פתח תקווה ,142 ,אחים ישראלית
7900 ,פתח תקווה ,1254 ,אחימאיר אבא
7900 ,פתח תקווה ,1269 ,אידלמן
7900 ,פתח תקווה ,224 ,איכילוב יצחק
7900 ,פתח תקווה ,175 ,אילניה
7900 ,פתח תקווה ,705 ,אילת
7900 ,פתח תקווה ,1010 ,אימבר
7900 ,פתח תקווה ,1110 ,אינשטיין
7900 ,פתח תקווה ,1295 ,אלדד ישראל
7900 ,פתח תקווה ,243 ,אלוף רחבעם זאבי
7900 ,פתח תקווה ,661 ,אלחריזי
7900 ,פתח תקווה ,494 ,אליקים
7900 ,פתח תקווה ,495 ,אלישיב
7900 ,פתח תקווה ,111 ,אלכסנדר ינאי
7900 ,פתח תקווה ,1410 ,אלכסנדר פן
7900 ,פתח תקווה ,845 ,אלמוג
7900 ,פתח תקווה ,314 ,אלקחי מרדכי
7900 ,פתח תקווה ,550 ,אלקניאן
7900 ,פתח תקווה ,812 ,אלתר מיהוד
7900 ,פתח תקווה ,535 ,אלתרמן נתן
7900 ,פתח תקווה ,1315 ,אמסטר לאה
7900 ,פתח תקווה ,985 ,אמסטרדם
7900 ,פתח תקווה ,814 ,אנגל יואל
7900 ,פתח תקווה ,934 ,אנדרסן
7900 ,פתח תקווה ,826 ,אנה פרנק
7900 ,פתח תקווה ,808 ,אנילביץ מרדכי
7900 ,פתח תקווה ,1214 ,אנסקי
7900 ,פתח תקווה ,1169 ,אסטה
7900 ,פתח תקווה ,1011 ,אסירי ציון
7900 ,פתח תקווה ,486 ,אסתר המלכה
7900 ,פתח תקווה ,1005 ,אפעל
7900 ,פתח תקווה ,726 ,אפשטיין
7900 ,פתח תקווה ,311 ,אצ"ל
7900 ,פתח תקווה ,673 ,אריאל דרסינובר
7900 ,פתח תקווה ,510 ,אריה בן אליעזר
7900 ,פתח תקווה ,282 ,אריה ליב רובינשטיין
7900 ,פתח תקווה ,916 ,ארלוזורוב
7900 ,פתח תקווה ,130 ,אש שלום
7900 ,פתח תקווה ,1126 ,אשכול לוי
7900 ,פתח תקווה ,219 ,אשכנזי יחזקאל
7900 ,פתח תקווה ,1327 ,אשרוב מישה
7900 ,פתח תקווה ,936 ,אשרי יהודה
7900 ,פתח תקווה ,650 ,בבלי
7900 ,פתח תקווה ,1276 ,בגלייבטר
7900 ,פתח תקווה ,457 ,בד"ר
7900 ,פתח תקווה ,143 ,בהט
7900 ,פתח תקווה ,1227 ,בובר מרטין
7900 ,פתח תקווה ,104 ,בוך ישי
7900 ,פתח תקווה ,496 ,בוסל
7900 ,פתח תקווה ,915 ,בורוכוב
7900 ,פתח תקווה ,980 ,בורלא יהודה
7900 ,פתח תקווה ,599 ,בורשטיין חיים
7900 ,פתח תקווה ,1006 ,בזל
7900 ,פתח תקווה ,987 ,בי"ח בילינסון
7900 ,פתח תקווה ,327 ,ביאליק
7900 ,פתח תקווה ,1153 ,ביטרמן מרדכי
7900 ,פתח תקווה ,196 ,ביילי חיים
7900 ,פתח תקווה ,150 ,בייליס
7900 ,פתח תקווה ,901 ,ביל"ו
7900 ,פתח תקווה ,1145 ,בירנבוים נתן
7900 ,פתח תקווה ,114 ,בית חלוצים
7900 ,פתח תקווה ,465 ,בית ריזין
7900 ,פתח תקווה ,1261 ,בלום ליאון
7900 ,פתח תקווה ,1012 ,בלטימור
7900 ,פתח תקווה ,810 ,בלפור
7900 ,פתח תקווה ,1248 ,בן גוריון
7900 ,פתח תקווה ,622 ,בן דוד יצחק
7900 ,פתח תקווה ,1019 ,בן דרור מרדכי
7900 ,פתח תקווה ,1117 ,בן זכאי
7900 ,פתח תקווה ,249 ,בן חור
7900 ,פתח תקווה ,809 ,בן יהודה
7900 ,פתח תקווה ,344 ,בן יוסף שלמה
7900 ,פתח תקווה ,1424 ,בן יעקב צבי
7900 ,פתח תקווה ,343 ,בן משה
7900 ,פתח תקווה ,658 ,בן נון יהושע
7900 ,פתח תקווה ,1224 ,בן צבי יצחק
7900 ,פתח תקווה ,951 ,בני ברית
7900 ,פתח תקווה ,446 ,בנימיני אמיר
7900 ,פתח תקווה ,1206 ,בס זליג
7900 ,פתח תקווה ,237 ,בעל התניא
7900 ,פתח תקווה ,333 ,בעל שם טוב
7900 ,פתח תקווה ,922 ,בצרי יוסף
7900 ,פתח תקווה ,260 ,בקר אהרון
7900 ,פתח תקווה ,420 ,בר אילן
7900 ,פתח תקווה ,466 ,בר גיורא
7900 ,פתח תקווה ,443 ,בר יוחאי
7900 ,פתח תקווה ,127 ,בר כוכבא
7900 ,פתח תקווה ,1279 ,בר-לוי אשר
7900 ,פתח תקווה ,553 ,ברא"ז זאב
7900 ,פתח תקווה ,263 ,בראון אריה
7900 ,פתח תקווה ,145 ,ברגמן צבי
7900 ,פתח תקווה ,981 ,ברוד מכס
7900 ,פתח תקווה ,1264 ,ברוורמן ישראל
7900 ,פתח תקווה ,205 ,ברוידה
7900 ,פתח תקווה ,1308 ,ברוך איסחקוב
7900 ,פתח תקווה ,1164 ,ברון די מנשה
7900 ,פתח תקווה ,212 ,ברון הירש
7900 ,פתח תקווה ,191 ,ברוריה
7900 ,פתח תקווה ,1025 ,ברט אהרון
7900 ,פתח תקווה ,1250 ,ברטונוב יהושע
7900 ,פתח תקווה ,973 ,ברטנורא
7900 ,פתח תקווה ,573 ,ברלוביץ יצחק
7900 ,פתח תקווה ,805 ,ברנדה זאב
7900 ,פתח תקווה ,115 ,ברנדייס
7900 ,פתח תקווה ,379 ,ברנו יצחקוב
7900 ,פתח תקווה ,429 ,ברנט זרח
7900 ,פתח תקווה ,461 ,ברנר
7900 ,פתח תקווה ,123 ,ברנשטיין כהן
7900 ,פתח תקווה ,1262 ,ברץ יוסף
7900 ,פתח תקווה ,223 ,ברקוביץ יצחק
7900 ,פתח תקווה ,795 ,ברקת
7900 ,פתח תקווה ,1139 ,ברש אשר
7900 ,פתח תקווה ,6546 ,בת גנים
7900 ,פתח תקווה ,452 ,בת שבע
7900 ,פתח תקווה ,176 ,בת שלמה
7900 ,פתח תקווה ,657 ,גאולי תימן
7900 ,פתח תקווה ,983 ,גבעת השלושה מלבן
7900 ,פתח תקווה ,750 ,גדנ"ע
7900 ,פתח תקווה ,177 ,גדרה
7900 ,פתח תקווה ,173 ,גהל מרטין
7900 ,פתח תקווה ,261 ,גובר רבקה
7900 ,פתח תקווה ,211 ,גוטמן
7900 ,פתח תקווה ,569 ,גולדברג דוד
7900 ,פתח תקווה ,1009 ,גולדברג יצחק
7900 ,פתח תקווה ,536 ,גולדברג לאה
7900 ,פתח תקווה ,210 ,גולדנהירש
7900 ,פתח תקווה ,473 ,גולדרייך
7900 ,פתח תקווה ,1423 ,גולדשטיין פרץ
7900 ,פתח תקווה ,105 ,גולומב אליהו
7900 ,פתח תקווה ,1155 ,גונן
7900 ,פתח תקווה ,747 ,גורדון מרדכי
7900 ,פתח תקווה ,1118 ,גוש חלב
7900 ,פתח תקווה ,459 ,גוש עציון
7900 ,פתח תקווה ,155 ,גזית
7900 ,פתח תקווה ,956 ,גיסות השריון
7900 ,פתח תקווה ,136 ,גיסין אבשלום
7900 ,פתח תקווה ,1413 ,גלבוע אמיר
7900 ,פתח תקווה ,353 ,גלין
7900 ,פתח תקווה ,144 ,גליס
7900 ,פתח תקווה ,1257 ,גליצנשטיין
7900 ,פתח תקווה ,1271 ,גלמן נחמן
7900 ,פתח תקווה ,1256 ,גלעד חיים
7900 ,פתח תקווה ,755 ,גמילי שלום
7900 ,פתח תקווה ,1170 ,גן הבנים
7900 ,פתח תקווה ,6601 ,גני הדר
7900 ,פתח תקווה ,834 ,גפני אברהם
7900 ,פתח תקווה ,1306 ,גפני שמחה
7900 ,פתח תקווה ,262 ,גרוטו אלכסנדר
7900 ,פתח תקווה ,544 ,גרוניגר פאול
7900 ,פתח תקווה ,345 ,גרונר דב
7900 ,פתח תקווה ,1417 ,גרוסמן חייקה
7900 ,פתח תקווה ,485 ,גרין יונה
7900 ,פתח תקווה ,538 ,גרינבוים
7900 ,פתח תקווה ,1275 ,גרינברג אורי צבי
7900 ,פתח תקווה ,157 ,גרינברג חיים
7900 ,פתח תקווה ,940 ,גרינגרט
7900 ,פתח תקווה ,131 ,גרינשטיין
7900 ,פתח תקווה ,1013 ,גרינשפן
7900 ,פתח תקווה ,141 ,גרניט
7900 ,פתח תקווה ,1278 ,גרף צבי
7900 ,פתח תקווה ,116 ,גרץ צבי
7900 ,פתח תקווה ,1318 ,ד"ר כהן אליהו
7900 ,פתח תקווה ,277 ,ד"ר רחימה מנשה
7900 ,פתח תקווה ,1419 ,דב שילנסקי
7900 ,פתח תקווה ,421 ,דבורה הנביאה
7900 ,פתח תקווה ,1229 ,דגל ראובן
7900 ,פתח תקווה ,362 ,דגניה
7900 ,פתח תקווה ,626 ,דובדבני משה
7900 ,פתח תקווה ,910 ,דובנוב שמעון
7900 ,פתח תקווה ,970 ,דוד הכורך
7900 ,פתח תקווה ,483 ,דון יוסף נשיא
7900 ,פתח תקווה ,953 ,דורון
7900 ,פתח תקווה ,1312 ,דורני שלום
7900 ,פתח תקווה ,169 ,דיין משה
7900 ,פתח תקווה ,403 ,דינוביץ אבשלום
7900 ,פתח תקווה ,963 ,דינור
7900 ,פתח תקווה ,121 ,דיסקין בנימין
7900 ,פתח תקווה ,957 ,דנמרק
7900 ,פתח תקווה ,1014 ,דנקנר מאיר
7900 ,פתח תקווה ,954 ,דפנה
7900 ,פתח תקווה ,958 ,דקר
7900 ,פתח תקווה ,138 ,דרזנר יחיאל
7900 ,פתח תקווה ,745 ,דרייפוס
7900 ,פתח תקווה ,1035 ,דרך אם המושבות
7900 ,פתח תקווה ,555 ,דרך בגין מנחם
7900 ,פתח תקווה ,1036 ,דרך החיים
7900 ,פתח תקווה ,360 ,דרך יצחק רבין
7900 ,פתח תקווה ,436 ,דרך לוד
7900 ,פתח תקווה ,1284 ,דרך מכבית
7900 ,פתח תקווה ,103 ,דרך ספיר יוסף
7900 ,פתח תקווה ,1174 ,דרנגר טובה ושמשון
7900 ,פתח תקווה ,407 ,ה באייר
7900 ,פתח תקווה ,1138 ,האביב
7900 ,פתח תקווה ,1238 ,האגמון
7900 ,פתח תקווה ,1314 ,האדמו"ר איפרגן שלום
7900 ,פתח תקווה ,168 ,האופן
7900 ,פתח תקווה ,918 ,האורים
7900 ,פתח תקווה ,1136 ,האורנים
7900 ,פתח תקווה ,134 ,האחים בכר
7900 ,פתח תקווה ,950 ,האחים בן עזר
7900 ,פתח תקווה ,364 ,האחים פרחי
7900 ,פתח תקווה ,239 ,האחים רוזוב
7900 ,פתח תקווה ,203 ,האחים שטרייט
7900 ,פתח תקווה ,404 ,האילנות
7900 ,פתח תקווה ,969 ,האירוסים
7900 ,פתח תקווה ,841 ,האלונים
7900 ,פתח תקווה ,113 ,האלוף דוד מרכוס
7900 ,פתח תקווה ,331 ,האר"י הקדוש
7900 ,פתח תקווה ,1235 ,הארז
7900 ,פתח תקווה ,140 ,האתרוג
7900 ,פתח תקווה ,147 ,הבורסקאי
7900 ,פתח תקווה ,1156 ,הבטחון
7900 ,פתח תקווה ,122 ,הבנים
7900 ,פתח תקווה ,1237 ,הברוש
7900 ,פתח תקווה ,583 ,הגיתית
7900 ,פתח תקווה ,1141 ,הגפן
7900 ,פתח תקווה ,449 ,הגר"א
7900 ,פתח תקווה ,574 ,הדאיה עובדיה
7900 ,פתח תקווה ,842 ,הדף היומי
7900 ,פתח תקווה ,1128 ,הדקל
7900 ,פתח תקווה ,6603 ,הדר גנים
7900 ,פתח תקווה ,6507 ,הדר המושבות
7900 ,פתח תקווה ,621 ,הדרור
7900 ,פתח תקווה ,220 ,ההגנה
7900 ,פתח תקווה ,497 ,ההדס
7900 ,פתח תקווה ,1305 ,ההדר
7900 ,פתח תקווה ,230 ,ההסתדרות
7900 ,פתח תקווה ,706 ,הוברמן
7900 ,פתח תקווה ,917 ,הוז דב
7900 ,פתח תקווה ,467 ,הולצברג שמחה
7900 ,פתח תקווה ,1137 ,הורד
7900 ,פתח תקווה ,612 ,הושע
7900 ,פתח תקווה ,460 ,הותיקים
7900 ,פתח תקווה ,623 ,הזוהר
7900 ,פתח תקווה ,881 ,הזז חיים
7900 ,פתח תקווה ,1140 ,הזית
7900 ,פתח תקווה ,1132 ,הזמיר
7900 ,פתח תקווה ,1124 ,החבצלת
7900 ,פתח תקווה ,1204 ,החי"ל
7900 ,פתח תקווה ,584 ,החליל
7900 ,פתח תקווה ,634 ,החמישה
7900 ,פתח תקווה ,350 ,החצב
7900 ,פתח תקווה ,1242 ,החרוב
7900 ,פתח תקווה ,1033 ,החרוצים
7900 ,פתח תקווה ,162 ,החרושת
7900 ,פתח תקווה ,1265 ,החרמון
7900 ,פתח תקווה ,172 ,החרש
7900 ,פתח תקווה ,442 ,החשמונאים
7900 ,פתח תקווה ,1201 ,היבנר
7900 ,פתח תקווה ,1210 ,היסמין
7900 ,פתח תקווה ,1004 ,היצירה
7900 ,פתח תקווה ,712 ,הירדן
7900 ,פתח תקווה ,112 ,הירקונים
7900 ,פתח תקווה ,756 ,הכהן
7900 ,פתח תקווה ,1258 ,הכורם
7900 ,פתח תקווה ,578 ,הכינור
7900 ,פתח תקווה ,844 ,הכרם
7900 ,פתח תקווה ,410 ,הל"ה
7900 ,פתח תקווה ,1208 ,הלוי
7900 ,פתח תקווה ,482 ,הלולב
7900 ,פתח תקווה ,972 ,הלילך
7900 ,פתח תקווה ,1232 ,הלימון
7900 ,פתח תקווה ,608 ,הלל הזקן
7900 ,פתח תקווה ,718 ,הלל זכריה
7900 ,פתח תקווה ,986 ,הלסינקי
7900 ,פתח תקווה ,1157 ,הלפיד
7900 ,פתח תקווה ,351 ,הלפרין
7900 ,פתח תקווה ,341 ,המגיד
7900 ,פתח תקווה ,408 ,המגינים
7900 ,פתח תקווה ,1158 ,המגשימים
7900 ,פתח תקווה ,1029 ,המדע
7900 ,פתח תקווה ,1143 ,המהר"ל מפראג
7900 ,פתח תקווה ,979 ,המורה אליהו
7900 ,פתח תקווה ,984 ,המורים
7900 ,פתח תקווה ,581 ,המיתר
7900 ,פתח תקווה ,108 ,המכבים
7900 ,פתח תקווה ,629 ,המלכים
7900 ,פתח תקווה ,170 ,המסילה
7900 ,פתח תקווה ,402 ,המעפילים
7900 ,פתח תקווה ,1018 ,המפלסים
7900 ,פתח תקווה ,1015 ,המפעלים
7900 ,פתח תקווה ,582 ,המצילתיים
7900 ,פתח תקווה ,180 ,המר זבולון
7900 ,פתח תקווה ,965 ,המרגניות
7900 ,פתח תקווה ,1016 ,המרץ
7900 ,פתח תקווה ,1418 ,המשוררת זלדה
7900 ,פתח תקווה ,240 ,המשטרה
7900 ,פתח תקווה ,1207 ,המתנחלים
7900 ,פתח תקווה ,603 ,הנביאים
7900 ,פתח תקווה ,710 ,הנגב
7900 ,פתח תקווה ,437 ,הנח"ל
7900 ,פתח תקווה ,1127 ,הניצנים
7900 ,פתח תקווה ,617 ,הנצחון
7900 ,פתח תקווה ,500 ,הנצי"ב
7900 ,פתח תקווה ,964 ,הנרקיסים
7900 ,פתח תקווה ,1203 ,הנשיאים
7900 ,פתח תקווה ,231 ,הס משה
7900 ,פתח תקווה ,1022 ,הסדנה
7900 ,פתח תקווה ,1109 ,הסיבים
7900 ,פתח תקווה ,441 ,הסנהדרין
7900 ,פתח תקווה ,1230 ,הסתונית
7900 ,פתח תקווה ,146 ,העובד הציוני
7900 ,פתח תקווה ,579 ,העוגב
7900 ,פתח תקווה ,585 ,העינבל
7900 ,פתח תקווה ,227 ,העליה השניה
7900 ,פתח תקווה ,1205 ,העצמאות
7900 ,פתח תקווה ,310 ,העצמון
7900 ,פתח תקווה ,654 ,הערבה
7900 ,פתח תקווה ,924 ,הפועל הצעיר
7900 ,פתח תקווה ,912 ,הפורצים
7900 ,פתח תקווה ,921 ,הפלמ"ח
7900 ,פתח תקווה ,1030 ,הפסגות
7900 ,פתח תקווה ,1299 ,הפרדס
7900 ,פתח תקווה ,1135 ,הפרח
7900 ,פתח תקווה ,1218 ,הפרטיזנים
7900 ,פתח תקווה ,1234 ,הצבר
7900 ,פתח תקווה ,961 ,הצוערים
7900 ,פתח תקווה ,1168 ,הצייר
7900 ,פתח תקווה ,1233 ,הצנחנים
7900 ,פתח תקווה ,1159 ,הצפירה
7900 ,פתח תקווה ,968 ,הצפצפה
7900 ,פתח תקווה ,428 ,הקונגרס
7900 ,פתח תקווה ,1026 ,הקידמה
7900 ,פתח תקווה ,498 ,הקציר
7900 ,פתח תקווה ,576 ,הקרן
7900 ,פתח תקווה ,1142 ,הקשת
7900 ,פתח תקווה ,580 ,הקתרוס
7900 ,פתח תקווה ,491 ,הר ציון
7900 ,פתח תקווה ,133 ,הראל איסר
7900 ,פתח תקווה ,233 ,הראשונים
7900 ,פתח תקווה ,335 ,הרב אבורביע
7900 ,פתח תקווה ,832 ,הרב אהרונסון
7900 ,פתח תקווה ,595 ,הרב אויערבך משה
7900 ,פתח תקווה ,546 ,הרב אונטרמן
7900 ,פתח תקווה ,447 ,הרב אורבך מאיר
7900 ,פתח תקווה ,1198 ,הרב אחיעד אטינגר
7900 ,פתח תקווה ,952 ,הרב אילויצקי
7900 ,פתח תקווה ,558 ,הרב אלבז
7900 ,פתח תקווה ,508 ,הרב אלקלעי
7900 ,פתח תקווה ,1196 ,הרב ביטון חביב
7900 ,פתח תקווה ,106 ,הרב בלוי משה
7900 ,פתח תקווה ,445 ,הרב גולד
7900 ,פתח תקווה ,596 ,הרב גורן שלמה
7900 ,פתח תקווה ,651 ,הרב גלוסקא
7900 ,פתח תקווה ,572 ,הרב דייטש יעקב
7900 ,פתח תקווה ,319 ,הרב הלר
7900 ,פתח תקווה ,448 ,הרב הרצוג
7900 ,פתח תקווה ,1231 ,הרב וולף
7900 ,פתח תקווה ,475 ,הרב חנזין
7900 ,פתח תקווה ,254 ,הרב חפוטא יאיר
7900 ,פתח תקווה ,315 ,הרב טולידנו
7900 ,פתח תקווה ,559 ,הרב טייב יצחק
7900 ,פתח תקווה ,974 ,הרב יחבוב אהרון
7900 ,פתח תקווה ,288 ,הרב יצחק דבוריץ
7900 ,פתח תקווה ,287 ,הרב ישעיהו הבר
7900 ,פתח תקווה ,1038 ,הרב מבורך עידן
7900 ,פתח תקווה ,218 ,הרב מוהליבר שמואל
7900 ,פתח תקווה ,739 ,הרב מימון
7900 ,פתח תקווה ,1027 ,הרב מלכה משה
7900 ,פתח תקווה ,670 ,הרב משורר ישעיהו
7900 ,פתח תקווה ,846 ,הרב ניימן יעקב
7900 ,פתח תקווה ,591 ,הרב ניסים יצחק
7900 ,פתח תקווה ,1291 ,הרב נריה צבי
7900 ,פתח תקווה ,1283 ,הרב ספיר
7900 ,פתח תקווה ,488 ,הרב עבודי ניסן
7900 ,פתח תקווה ,272 ,הרב עובדיה יוסף
7900 ,פתח תקווה ,430 ,הרב עוזיאל
7900 ,פתח תקווה ,567 ,הרב עטיה עזרא
7900 ,פתח תקווה ,328 ,הרב עמיאל משה
7900 ,פתח תקווה ,967 ,הרב פונצ'יק מנחם
7900 ,פתח תקווה ,199 ,הרב פינטו מסעוד
7900 ,פתח תקווה ,592 ,הרב פינצ'י
7900 ,פתח תקווה ,667 ,הרב פלדמן
7900 ,פתח תקווה ,568 ,הרב צדקה יהודה
7900 ,פתח תקווה ,422 ,הרב צירלסון
7900 ,פתח תקווה ,731 ,הרב צעירי שלמה
7900 ,פתח תקווה ,1325 ,הרב קדר
7900 ,פתח תקווה ,820 ,הרב קוק
7900 ,פתח תקווה ,323 ,הרב קטרוני
7900 ,פתח תקווה ,624 ,הרב קלישר
7900 ,פתח תקווה ,586 ,הרב ריין אברהם
7900 ,פתח תקווה ,425 ,הרב ריינס יצחק
7900 ,פתח תקווה ,117 ,הרב שלזינגר עקיבא
7900 ,פתח תקווה ,719 ,הרב שקדי
7900 ,פתח תקווה ,1163 ,הרב"ש
7900 ,פתח תקווה ,587 ,הרבנית מרגלית
7900 ,פתח תקווה ,342 ,הרברט סמואל
7900 ,פתח תקווה ,1144 ,הרד"ק
7900 ,פתח תקווה ,565 ,הרז"ה
7900 ,פתח תקווה ,1130 ,הרימון
7900 ,פתח תקווה ,167 ,הרכב
7900 ,פתח תקווה ,163 ,הרכבת
7900 ,פתח תקווה ,1021 ,הרכש
7900 ,פתח תקווה ,721 ,הרמתי כהן שלום
7900 ,פתח תקווה ,213 ,הרצל
7900 ,פתח תקווה ,1167 ,הרצפלד אברהם
7900 ,פתח תקווה ,1123 ,הרקפת
7900 ,פתח תקווה ,1116 ,השדרה
7900 ,פתח תקווה ,451 ,השומר
7900 ,פתח תקווה ,631 ,השופטים
7900 ,פתח תקווה ,577 ,השופר
7900 ,פתח תקווה ,966 ,השושנים
7900 ,פתח תקווה ,499 ,השחר
7900 ,פתח תקווה ,1236 ,השיטה
7900 ,פתח תקווה ,1160 ,השילוח
7900 ,פתח תקווה ,416 ,השלושה
7900 ,פתח תקווה ,1017 ,השפלה
7900 ,פתח תקווה ,1161 ,השקמה
7900 ,פתח תקווה ,707 ,השרון
7900 ,פתח תקווה ,1122 ,התאנה
7900 ,פתח תקווה ,1172 ,התוכנה
7900 ,פתח תקווה ,575 ,התוף
7900 ,פתח תקווה ,615 ,התחיה
7900 ,פתח תקווה ,1125 ,התמר
7900 ,פתח תקווה ,1031 ,התנופה
7900 ,פתח תקווה ,1166 ,התעשיה
7900 ,פתח תקווה ,1239 ,התפוז
7900 ,פתח תקווה ,821 ,התשעים ושלוש
7900 ,פתח תקווה ,1246 ,וויז סטפן
7900 ,פתח תקווה ,226 ,וולפסון דוד
7900 ,פתח תקווה ,476 ,ויזנטל שמעון
7900 ,פתח תקווה ,1181 ,ויטנברג יצחק
7900 ,פתח תקווה ,594 ,וינברגר שמואל
7900 ,פתח תקווה ,209 ,וינגיט
7900 ,פתח תקווה ,321 ,וינצקי ליפא
7900 ,פתח תקווה ,174 ,וינקלר אלתר
7900 ,פתח תקווה ,487 ,ויסבורג
7900 ,פתח תקווה ,414 ,ויסוקר קופל
7900 ,פתח תקווה ,1212 ,ויצמן
7900 ,פתח תקווה ,291 ,ולוביק שלמה
7900 ,פתח תקווה ,1171 ,ולנברג ראול
7900 ,פתח תקווה ,270 ,ונגרובר
7900 ,פתח תקווה ,454 ,ועד ארבע ארצות
7900 ,פתח תקווה ,1173 ,ורדינון אליעזר
7900 ,פתח תקווה ,1313 ,ושדי זכריה
7900 ,פתח תקווה ,905 ,ז'בוטינסקי
7900 ,פתח תקווה ,462 ,זבלודוביץ
7900 ,פתח תקווה ,423 ,זהבי שמעון
7900 ,פתח תקווה ,1001 ,זולא אמיל
7900 ,פתח תקווה ,908 ,זיגלבוים
7900 ,פתח תקווה ,1129 ,זייד אלכסנדר
7900 ,פתח תקווה ,1311 ,זכאי חיים
7900 ,פתח תקווה ,178 ,זכרון יעקב
7900 ,פתח תקווה ,827 ,זכרון משה
7900 ,פתח תקווה ,633 ,זכריה
7900 ,פתח תקווה ,1180 ,זלוטניק שלמה
7900 ,פתח תקווה ,1296 ,זמיר עמנואל
7900 ,פתח תקווה ,1322 ,זמירות
7900 ,פתח תקווה ,746 ,זמנהוף
7900 ,פתח תקווה ,245 ,זנגויל ישראל
7900 ,פתח תקווה ,346 ,זפרן מרדכי
7900 ,פתח תקווה ,517 ,זקן השומרים
7900 ,פתח תקווה ,743 ,זרובבל יעקב
7900 ,פתח תקווה ,929 ,ח"ן
7900 ,פתח תקווה ,347 ,חב"ד
7900 ,פתח תקווה ,605 ,חבקוק
7900 ,פתח תקווה ,616 ,חברון
7900 ,פתח תקווה ,627 ,חגי
7900 ,פתח תקווה ,1223 ,חגין פנחס
7900 ,פתח תקווה ,818 ,חדד סנדר
7900 ,פתח תקווה ,179 ,חדרה
7900 ,פתח תקווה ,216 ,חובבי ציון
7900 ,פתח תקווה ,307 ,חזון איש
7900 ,פתח תקווה ,511 ,חזן יעקב
7900 ,פתח תקווה ,505 ,חטיבת אלכסנדרוני
7900 ,פתח תקווה ,907 ,חיון דוד
7900 ,פתח תקווה ,419 ,חיסין
7900 ,פתח תקווה ,241 ,חכם יוסף חיים
7900 ,פתח תקווה ,653 ,חכמי ישראל
7900 ,פתח תקווה ,400 ,חכמי לובלין
7900 ,פתח תקווה ,120 ,חלוצת הפרדסנות
7900 ,פתח תקווה ,154 ,חלמיש
7900 ,פתח תקווה ,875 ,חנני יוסף
7900 ,פתח תקווה ,110 ,חנקין יהושע
7900 ,פתח תקווה ,570 ,חסדי שמעון
7900 ,פתח תקווה ,229 ,חפץ חיים
7900 ,פתח תקווה ,815 ,חפץ מרדכי
7900 ,פתח תקווה ,1113 ,חת"ם סופר
7900 ,פתח תקווה ,717 ,טביב אברהם
7900 ,פתח תקווה ,1244 ,טבנקין יצחק
7900 ,פתח תקווה ,192 ,טבצ'ניק דוד
7900 ,פתח תקווה ,503 ,טבריה
7900 ,פתח תקווה ,708 ,טוסקניני
7900 ,פתח תקווה ,1414 ,טיכו אנה
7900 ,פתח תקווה ,1289 ,טרגר חנה
7900 ,פתח תקווה ,920 ,טרומפלדור
7900 ,פתח תקווה ,1105 ,טשרניחובסקי
7900 ,פתח תקווה ,1219 ,י"ד הבנים
7900 ,פתח תקווה ,181 ,יבנאל
7900 ,פתח תקווה ,453 ,יבנה
7900 ,פתח תקווה ,1023 ,יגיע כפיים
7900 ,פתח תקווה ,1259 ,יגנס
7900 ,פתח תקווה ,822 ,יהוד
7900 ,פתח תקווה ,302 ,יהודה הלוי
7900 ,פתח תקווה ,919 ,יהלום בנימין
7900 ,פתח תקווה ,610 ,יואל
7900 ,פתח תקווה ,273 ,יואלי זלמן שנאורסון
7900 ,פתח תקווה ,440 ,יוכלמן יחיאל
7900 ,פתח תקווה ,611 ,יונה
7900 ,פתח תקווה ,727 ,יוספסברג
7900 ,פתח תקווה ,1421 ,יורדי הסירה
7900 ,פתח תקווה ,1301 ,יחיעם
7900 ,פתח תקווה ,1225 ,יטקובסקי אחים
7900 ,פתח תקווה ,933 ,יל"ג
7900 ,פתח תקווה ,648 ,ילדי טהרן
7900 ,פתח תקווה ,666 ,ילדי טרנסניסטרה
7900 ,פתח תקווה ,754 ,ילדי מרישין
7900 ,פתח תקווה ,801 ,ילין אבינועם
7900 ,פתח תקווה ,1221 ,ילישביץ יעקב
7900 ,פתח תקווה ,1320 ,ימין שושן
7900 ,פתח תקווה ,126 ,ימיני דב
7900 ,פתח תקווה ,751 ,יניב
7900 ,פתח תקווה ,182 ,יסוד המעלה
7900 ,פתח תקווה ,1112 ,יעבץ זאב
7900 ,פתח תקווה ,1034 ,יעקב כהן
7900 ,פתח תקווה ,294 ,יעקב שמואל רוזנצויג
7900 ,פתח תקווה ,516 ,יערי מאיר
7900 ,פתח תקווה ,1226 ,יפה נוף
7900 ,פתח תקווה ,955 ,יפרח
7900 ,פתח תקווה ,725 ,יצחק אלחנן
7900 ,פתח תקווה ,1415 ,יצחק בשביס זינגר
7900 ,פתח תקווה ,300 ,יצחק שמיר
7900 ,פתח תקווה ,709 ,יצחקי יצחק
7900 ,פתח תקווה ,412 ,יציאת אירופה
7900 ,פתח תקווה ,542 ,יקותיאל אדם
7900 ,פתח תקווה ,424 ,ירושלים
7900 ,פתח תקווה ,641 ,ישעיהו ישראל
7900 ,פתח תקווה ,878 ,ישראל ושרה
7900 ,פתח תקווה ,716 ,ישראלוב
7900 ,פתח תקווה ,405 ,כ"ז בניסן
7900 ,פתח תקווה ,415 ,כ"ט בנובמבר
7900 ,פתח תקווה ,914 ,כבוש העבודה
7900 ,פתח תקווה ,200 ,כביש חוצה שומרון
7900 ,פתח תקווה ,813 ,כהן חיים
7900 ,פתח תקווה ,662 ,כוחלני חיה
7900 ,פתח תקווה ,507 ,כורש המלך
7900 ,פתח תקווה ,6001 ,ככר אגוז
7900 ,פתח תקווה ,6002 ,ככר אורוגואי
7900 ,פתח תקווה ,6003 ,ככר ברוש
7900 ,פתח תקווה ,6004 ,ככר גפן
7900 ,פתח תקווה ,6005 ,ככר דולב
7900 ,פתח תקווה ,6007 ,ככר הברון רוטשילד
7900 ,פתח תקווה ,6006 ,ככר הדס
7900 ,פתח תקווה ,6010 ,ככר המייסדים
7900 ,פתח תקווה ,6008 ,ככר המלחים
7900 ,פתח תקווה ,6009 ,ככר ויצמן
7900 ,פתח תקווה ,606 ,כנסת ישראל
7900 ,פתח תקווה ,6600 ,כפר אברהם
7900 ,פתח תקווה ,6542 ,כפר גנים א
7900 ,פתח תקווה ,6543 ,כפר גנים ב
7900 ,פתח תקווה ,6540 ,כפר גנים ג
7900 ,פתח תקווה ,6575 ,כפר הבפטיסטים
7900 ,פתח תקווה ,806 ,כץ מיכל לייב
7900 ,פתח תקווה ,193 ,כצנלסון אהרון
7900 ,פתח תקווה ,913 ,כצנלסון ברל
7900 ,פתח תקווה ,305 ,כרמלי
7900 ,פתח תקווה ,1274 ,לבון פנחס
7900 ,פתח תקווה ,1293 ,לברי מרק
7900 ,פתח תקווה ,1042 ,לובה אליאב
7900 ,פתח תקווה ,1270 ,לובטקין צביה
7900 ,פתח תקווה ,1037 ,לודיוב
7900 ,פתח תקווה ,1321 ,לוויס עמנואל
7900 ,פתח תקווה ,1217 ,לוחמי הגיטו
7900 ,פתח תקווה ,409 ,לוחמי השחרור
7900 ,פתח תקווה ,1243 ,לוין
7900 ,פתח תקווה ,876 ,לוין יוסף
7900 ,פתח תקווה ,959 ,לוינסון כלב
7900 ,פתח תקווה ,280 ,לויתן דב
7900 ,פתח תקווה ,1039 ,לויתן חנן
7900 ,פתח תקווה ,637 ,לח"י
7900 ,פתח תקווה ,676 ,ליאורה לייבוביץ
7900 ,פתח תקווה ,472 ,ליבני איתן
7900 ,פתח תקווה ,128 ,ליברכט עקיבא
7900 ,פתח תקווה ,246 ,ליברמן אהרון
7900 ,פתח תקווה ,537 ,ליויק
7900 ,פתח תקווה ,159 ,לילנבלום
7900 ,פתח תקווה ,1245 ,ליסין
7900 ,פתח תקווה ,217 ,ליפקיס זאב
7900 ,פתח תקווה ,1297 ,ליפשיץ דניאל
7900 ,פתח תקווה ,941 ,לם ישראל
7900 ,פתח תקווה ,843 ,למדן יצחק
7900 ,פתח תקווה ,255 ,למפרט צבי
7900 ,פתח תקווה ,435 ,לנדוי שמואל חיים
7900 ,פתח תקווה ,541 ,לסקוב חיים
7900 ,פתח תקווה ,794 ,לשם
7900 ,פתח תקווה ,925 ,מ"ג
7900 ,פתח תקווה ,1133 ,מ' חיים יוסף הרמן
7900 ,פתח תקווה ,663 ,מאיה ירון
7900 ,פתח תקווה ,183 ,מאיר שפיה
7900 ,פתח תקווה ,1351 ,מאירוב
7900 ,פתח תקווה ,135 ,מאירי פנחס
7900 ,פתח תקווה ,931 ,מאפו
7900 ,פתח תקווה ,1162 ,מבטחים
7900 ,פתח תקווה ,1040 ,מבצע דקל
7900 ,פתח תקווה ,1346 ,מבצע חורב
7900 ,פתח תקווה ,1345 ,מבצע יואב
7900 ,פתח תקווה ,1347 ,מבצע יפתח
7900 ,פתח תקווה ,119 ,מבצע קדש
7900 ,פתח תקווה ,978 ,מהרשק בני
7900 ,פתח תקווה ,161 ,מודיעין
7900 ,פתח תקווה ,1028 ,מוטה גור
7900 ,פתח תקווה ,1412 ,מולדובסקי קדיה
7900 ,פתח תקווה ,214 ,מונטיפיורי
7900 ,פתח תקווה ,1222 ,מוסטובוי נח
7900 ,פתח תקווה ,1294 ,מוסינזון יגאל
7900 ,פתח תקווה ,906 ,מוצקין
7900 ,פתח תקווה ,660 ,מורי צברי
7900 ,פתח תקווה ,598 ,מוריאל יהודה
7900 ,פתח תקווה ,638 ,מוריה
7900 ,פתח תקווה ,1146 ,מזא"ה
7900 ,פתח תקווה ,184 ,מזכרת בתיה
7900 ,פתח תקווה ,308 ,מזרחי אברהם
7900 ,פתח תקווה ,645 ,מחנה יהודה
7900 ,פתח תקווה ,185 ,מטולה
7900 ,פתח תקווה ,1215 ,מטות יהודה
7900 ,פתח תקווה ,248 ,מטלון יצחק
7900 ,פתח תקווה ,1220 ,מייזנר
7900 ,פתח תקווה ,1002 ,מיכאליס שלמה
7900 ,פתח תקווה ,607 ,מיכה
7900 ,פתח תקווה ,652 ,מיכל
7900 ,פתח תקווה ,1007 ,מילס שמואל
7900 ,פתח תקווה ,411 ,מילר שלמה
7900 ,פתח תקווה ,728 ,מינץ בנימין
7900 ,פתח תקווה ,923 ,מירזיוף משיח
7900 ,פתח תקווה ,1202 ,מירקין מרדכי
7900 ,פתח תקווה ,830 ,מכנס גד
7900 ,פתח תקווה ,632 ,מלאכי
7900 ,פתח תקווה ,744 ,מלחמת ששת הימים
7900 ,פתח תקווה ,816 ,מנדלסון
7900 ,פתח תקווה ,512 ,מנחת שלמה
7900 ,פתח תקווה ,1416 ,מניה שוחט
7900 ,פתח תקווה ,1102 ,מסדה
7900 ,פתח תקווה ,1251 ,מסקין
7900 ,פתח תקווה ,489 ,מסריק
7900 ,פתח תקווה ,649 ,מעפילי מפקורה
7900 ,פתח תקווה ,1211 ,מקליס מאיר
7900 ,פתח תקווה ,355 ,מרגלית
7900 ,פתח תקווה ,268 ,מרדכי בצ'איב
7900 ,פתח תקווה ,722 ,מרחבי שלום
7900 ,פתח תקווה ,471 ,מרידור יעקב
7900 ,פתח תקווה ,286 ,מרים ובנימין הולצמן
7900 ,פתח תקווה ,1411 ,מרים ילן שטקליס
7900 ,פתח תקווה ,238 ,מרכוס משה
7900 ,פתח תקווה ,1060 ,מרכז רפואי רבין
7900 ,פתח תקווה ,340 ,מש' יאיר אסיסקוביץ'
7900 ,פתח תקווה ,1000 ,משה שמיר
7900 ,פתח תקווה ,545 ,משולם משה
7900 ,פתח תקווה ,334 ,משמר הירדן
7900 ,פתח תקווה ,1319 ,משעול אביאלי
7900 ,פתח תקווה ,274 ,משעול אברהם ברון
7900 ,פתח תקווה ,672 ,משעול אברהם כגן
7900 ,פתח תקווה ,266 ,משעול אברהם סמו
7900 ,פתח תקווה ,317 ,משעול אהרון מרקוס
7900 ,פתח תקווה ,671 ,משעול אפרים כוכבי
7900 ,פתח תקווה ,253 ,משעול אפשטיין
7900 ,פתח תקווה ,293 ,משעול אריה חג'בי
7900 ,פתח תקווה ,939 ,משעול בורוכוב ירון
7900 ,פתח תקווה ,988 ,משעול בן עזר מינה
7900 ,פתח תקווה ,900 ,משעול ג'ורי מועלם
7900 ,פתח תקווה ,636 ,משעול גורני שלום
7900 ,פתח תקווה ,1316 ,משעול גינזבורג יוסף
7900 ,פתח תקווה ,665 ,משעול גלובמן
7900 ,פתח תקווה ,295 ,משעול דוד טזרטס
7900 ,פתח תקווה ,1323 ,משעול דיקר שמשון
7900 ,פתח תקווה ,283 ,משעול דנה גליק
7900 ,פתח תקווה ,1329 ,משעול האהבה
7900 ,פתח תקווה ,1332 ,משעול האושר
7900 ,פתח תקווה ,656 ,משעול הבנים
7900 ,פתח תקווה ,1338 ,משעול הדורות
7900 ,פתח תקווה ,1334 ,משעול החברות
7900 ,פתח תקווה ,1330 ,משעול החיבה
7900 ,פתח תקווה ,1337 ,משעול המשאלות
7900 ,פתח תקווה ,1197 ,משעול הנס גלנר
7900 ,פתח תקווה ,1335 ,משעול הערכים
7900 ,פתח תקווה ,1333 ,משעול הקהילות
7900 ,פתח תקווה ,378 ,משעול הרב יצחק קאץ
7900 ,פתח תקווה ,1331 ,משעול השורשים
7900 ,פתח תקווה ,1336 ,משעול השלום
7900 ,פתח תקווה ,1328 ,משעול השמחה
7900 ,פתח תקווה ,275 ,משעול חזקיה שמעונוב
7900 ,פתח תקווה ,669 ,משעול חסיד גדליה
7900 ,פתח תקווה ,1324 ,משעול טלישבסקי
7900 ,פתח תקווה ,674 ,משעול יהודה קורן
7900 ,פתח תקווה ,278 ,משעול יהודה שבתאי
7900 ,פתח תקווה ,363 ,משעול יהודה שטרנפלד
7900 ,פתח תקווה ,289 ,משעול יואל סימקין
7900 ,פתח תקווה ,354 ,משעול ימימה מינס
7900 ,פתח תקווה ,292 ,משעול יעקב אוסטר
7900 ,פתח תקווה ,942 ,משעול יעקב קלדרון
7900 ,פתח תקווה ,1200 ,משעול לויצה בבחאנוב
7900 ,פתח תקווה ,1317 ,משעול מוריס אליעזר
7900 ,פתח תקווה ,664 ,משעול מינס מנשה
7900 ,פתח תקווה ,290 ,משעול מלכה זליקוביץ
7900 ,פתח תקווה ,589 ,משעול מרדכי וולפסון
7900 ,פתח תקווה ,1216 ,משעול מרקוס
7900 ,פתח תקווה ,258 ,משעול משפחת מקלב
7900 ,פתח תקווה ,480 ,משעול נשיא יוסף
7900 ,פתח תקווה ,259 ,משעול פינסון אהרון
7900 ,פתח תקווה ,835 ,משעול פרבר
7900 ,פתח תקווה ,276 ,משעול צבי אקרמן
7900 ,פתח תקווה ,281 ,משעול ראובן זמיר
7900 ,פתח תקווה ,312 ,משעול ראשוני שעריה
7900 ,פתח תקווה ,284 ,משעול רחמים דרשן
7900 ,פתח תקווה ,677 ,משעול שאול שי
7900 ,פתח תקווה ,6655 ,נאות צור
7900 ,פתח תקווה ,330 ,נבנצאל
7900 ,פתח תקווה ,635 ,נוביק רבקה
7900 ,פתח תקווה ,6521 ,נוה עוז
7900 ,פתח תקווה ,455 ,נוה רעים
7900 ,פתח תקווה ,6547 ,נווה גן
7900 ,פתח תקווה ,6541 ,נווה גנים
7900 ,פתח תקווה ,6539 ,נווה עוז הרחבה
7900 ,פתח תקווה ,6538 ,נווה עוז מערב
7900 ,פתח תקווה ,215 ,נורדאו מכס
7900 ,פתח תקווה ,609 ,נחום
7900 ,פתח תקווה ,236 ,נחלת צבי
7900 ,פתח תקווה ,601 ,נחמן מברסלב
7900 ,פתח תקווה ,148 ,נחשון
7900 ,פתח תקווה ,1292 ,ניגונים
7900 ,פתח תקווה ,1119 ,ניסן משה
7900 ,פתח תקווה ,1213 ,ניר נחום
7900 ,פתח תקווה ,186 ,נס ציונה
7900 ,פתח תקווה ,1120 ,נפש חיה
7900 ,פתח תקווה ,413 ,נצח ישראל
7900 ,פתח תקווה ,1427 ,נקאש
7900 ,פתח תקווה ,1285 ,נקר יוסף
7900 ,פתח תקווה ,1401 ,נתיב היובל
7900 ,פתח תקווה ,514 ,נתן
7900 ,פתח תקווה ,1190 ,נתניהו יוני
7900 ,פתח תקווה ,242 ,סביון
7900 ,פתח תקווה ,753 ,סגליות
7900 ,פתח תקווה ,1263 ,סוטין חיים
7900 ,פתח תקווה ,1268 ,סוטיצקי אברהם
7900 ,פתח תקווה ,628 ,סולד הנריטה
7900 ,פתח תקווה ,309 ,סוקולוב
7900 ,פתח תקווה ,361 ,סורוקה משה
7900 ,פתח תקווה ,207 ,סטרומה
7900 ,פתח תקווה ,1249 ,סטרכילביץ צבי
7900 ,פתח תקווה ,976 ,סילבר אבא הלל
7900 ,פתח תקווה ,713 ,סיני
7900 ,פתח תקווה ,1422 ,סירני אנצו
7900 ,פתח תקווה ,431 ,סלבנדי
7900 ,פתח תקווה ,817 ,סלומון
7900 ,פתח תקווה ,222 ,סלור
7900 ,פתח תקווה ,715 ,סלנט שמואל
7900 ,פתח תקווה ,938 ,סמ אסיה
7900 ,פתח תקווה ,352 ,סמ אפרתי שמחה
7900 ,פתח תקווה ,165 ,סמ הרב ירמה
7900 ,פתח תקווה ,198 ,סמ התבור
7900 ,פתח תקווה ,285 ,סמטת משפחת ישרים
7900 ,פתח תקווה ,811 ,סמילנסקי
7900 ,פתח תקווה ,1273 ,סנדרוב
7900 ,פתח תקווה ,833 ,סנה משה
7900 ,פתח תקווה ,320 ,סנש חנה
7900 ,פתח תקווה ,902 ,סעדיה גאון
7900 ,פתח תקווה ,642 ,ספקטור
7900 ,פתח תקווה ,975 ,עגנון
7900 ,פתח תקווה ,509 ,עדש שפיק
7900 ,פתח תקווה ,614 ,עובדיה
7900 ,פתח תקווה ,221 ,עוזר חיים
7900 ,פתח תקווה ,204 ,עולי בבל
7900 ,פתח תקווה ,1148 ,עולי הגרדום
7900 ,פתח תקווה ,880 ,עופרי סעדיה
7900 ,פתח תקווה ,504 ,עזרא ונחמיה
7900 ,פתח תקווה ,264 ,עזריאל
7900 ,פתח תקווה ,588 ,עידוד ישראל
7900 ,פתח תקווה ,1108 ,עין גדי
7900 ,פתח תקווה ,304 ,עין גנים
7900 ,פתח תקווה ,602 ,עמוס
7900 ,פתח תקווה ,1302 ,עמי
7900 ,פתח תקווה ,1304 ,עמינח נחמיה
7900 ,פתח תקווה ,474 ,עמיצור אברהם
7900 ,פתח תקווה ,1303 ,עמיקם
7900 ,פתח תקווה ,1003 ,עמל
7900 ,פתח תקווה ,337 ,עמק השבע
7900 ,פתח תקווה ,643 ,ענבר
7900 ,פתח תקווה ,187 ,עתלית
7900 ,פתח תקווה ,470 ,פאגלין עמיחי
7900 ,פתח תקווה ,6660 ,פארק תעשיה אזורים
7900 ,פתח תקווה ,164 ,פוגל הרמן
7900 ,פתח תקווה ,349 ,פוחס דוד
7900 ,פתח תקווה ,1032 ,פוקס ישראל
7900 ,פתח תקווה ,804 ,פיארברג
7900 ,פתח תקווה ,926 ,פיכמן יעקב
7900 ,פתח תקווה ,326 ,פינס יחיאל מיכל
7900 ,פתח תקווה ,118 ,פינסקר
7900 ,פתח תקווה ,564 ,פינקל נתן צבי
7900 ,פתח תקווה ,1020 ,פינשטיין חיה
7900 ,פתח תקווה ,829 ,פיק"א
7900 ,פתח תקווה ,265 ,פלד משה
7900 ,פתח תקווה ,1253 ,פלדמן יוסף
7900 ,פתח תקווה ,316 ,פנחסי יעקב
7900 ,פתח תקווה ,566 ,פני מנחם
7900 ,פתח תקווה ,823 ,פנקס
7900 ,פתח תקווה ,271 ,פסח קולר
7900 ,פתח תקווה ,831 ,פסקל פרץ
7900 ,פתח תקווה ,433 ,פפר יוסף
7900 ,פתח תקווה ,1101 ,פקיעין
7900 ,פתח תקווה ,930 ,פרוג שמעון
7900 ,פתח תקווה ,201 ,פרומקין
7900 ,פתח תקווה ,1121 ,פרופ ליבוביץ ישעיהו
7900 ,פתח תקווה ,971 ,פרופ' טור
7900 ,פתח תקווה ,1041 ,פרופ' ישראל יבין
7900 ,פתח תקווה ,836 ,פרופ' פאול נתן
7900 ,פתח תקווה ,194 ,פרופ' פלר נח
7900 ,פתח תקווה ,208 ,פרופ' שור
7900 ,פתח תקווה ,647 ,פרידמן אלעזר
7900 ,פתח תקווה ,562 ,פרידמן ילין
7900 ,פתח תקווה ,301 ,פרישמן דוד
7900 ,פתח תקווה ,819 ,פרנקפורטר דוד
7900 ,פתח תקווה ,932 ,פרץ
7900 ,פתח תקווה ,206 ,פרץ נפתלי
7900 ,פתח תקווה ,9000 ,פתח תקווה
7900 ,פתח תקווה ,426 ,צ'לנוב יחיאל
7900 ,פתח תקווה ,477 ,צדוק אסתר
7900 ,פתח תקווה ,927 ,צה"ל
7900 ,פתח תקווה ,1272 ,צוויק בן ציון
7900 ,פתח תקווה ,1287 ,צוקרמן יצחק
7900 ,פתח תקווה ,1228 ,ציפורי טובה
7900 ,פתח תקווה ,501 ,צלח שלום
7900 ,פתח תקווה ,604 ,צפניה
7900 ,פתח תקווה ,502 ,צפת
7900 ,פתח תקווה ,6604 ,צריפים עמישב
7900 ,פתח תקווה ,6522 ,ק סביסלוצקי
7900 ,פתח תקווה ,807 ,קאמינקר
7900 ,פתח תקווה ,418 ,קארו יוסף
7900 ,פתח תקווה ,401 ,קבוץ גלויות
7900 ,פתח תקווה ,279 ,קדושי מאורעות תרפ"ט
7900 ,פתח תקווה ,456 ,קדושי פרהוד
7900 ,פתח תקווה ,723 ,קדושי קהיר
7900 ,פתח תקווה ,977 ,קדיש לוז
7900 ,פתח תקווה ,513 ,קהילות יעקב
7900 ,פתח תקווה ,561 ,קהילת יוסטון
7900 ,פתח תקווה ,336 ,קהילת משקוליץ
7900 ,פתח תקווה ,659 ,קהילת פינסק
7900 ,פתח תקווה ,247 ,קהילת פראג
7900 ,פתח תקווה ,543 ,קהילת רובנה
7900 ,פתח תקווה ,560 ,קהילת שיקגו
7900 ,פתח תקווה ,1024 ,קוגלמן תרזה
7900 ,פתח תקווה ,1298 ,קוז'ידלו
7900 ,פתח תקווה ,1260 ,קולקר שלום
7900 ,פתח תקווה ,877 ,קופלמן אברהם
7900 ,פתח תקווה ,724 ,קורצ'ק ינוש
7900 ,פתח תקווה ,625 ,קיש
7900 ,פתח תקווה ,935 ,קלויזנר
7900 ,פתח תקווה ,257 ,קמינסקי רבקה
7900 ,פתח תקווה ,1290 ,קנופף יוסף
7900 ,פתח תקווה ,928 ,קפלן אליעזר
7900 ,פתח תקווה ,1131 ,קפלנסקי שלמה
7900 ,פתח תקווה ,225 ,קציר אהרון
7900 ,פתח תקווה ,590 ,קראוסמן מרדכי
7900 ,פתח תקווה ,668 ,קרוואני מרים
7900 ,פתח תקווה ,909 ,קרול יעקב
7900 ,פתח תקווה ,139 ,קרימיניצקי
7900 ,פתח תקווה ,6630 ,קרית אלון
7900 ,פתח תקווה ,6514 ,קרית אליעזר פרי
7900 ,פתח תקווה ,6500 ,קרית אריה
7900 ,פתח תקווה ,6511 ,קרית דוד אלעזר
7900 ,פתח תקווה ,6606 ,קרית המשוררים
7900 ,פתח תקווה ,6515 ,קרית הרב סלומון
7900 ,פתח תקווה ,6607 ,קרית השר חזני
7900 ,פתח תקווה ,6608 ,קרית השר ספיר
7900 ,פתח תקווה ,6523 ,קרית מטלון
7900 ,פתח תקווה ,6524 ,קרית משה
7900 ,פתח תקווה ,469 ,קרמרמן יוסף
7900 ,פתח תקווה ,720 ,קרן היסוד
7900 ,פתח תקווה ,802 ,קרן קימת לישראל
7900 ,פתח תקווה ,1326 ,קרני אורי
7900 ,פתח תקווה ,303 ,קשאני אליעזר
7900 ,פתח תקווה ,267 ,ר' אריה לוין
7900 ,פתח תקווה ,828 ,ראב אחים
7900 ,פתח תקווה ,571 ,ראם שלמה
7900 ,פתח תקווה ,427 ,ראש העין
7900 ,פתח תקווה ,188 ,ראש פינה
7900 ,פתח תקווה ,189 ,ראשון לציון
7900 ,פתח תקווה ,675 ,רבי אלעזר אבוחצירא
7900 ,פתח תקווה ,593 ,רבי יהודה הנשיא
7900 ,פתח תקווה ,444 ,רבי עקיבא
7900 ,פתח תקווה ,406 ,רבינוביץ יעקב
7900 ,פתח תקווה ,151 ,רבינזון יחזקאל
7900 ,פתח תקווה ,153 ,רבניצקי
7900 ,פתח תקווה ,1286 ,רואי אליעזר
7900 ,פתח תקווה ,551 ,רובינא חנה
7900 ,פתח תקווה ,515 ,רוזובסקי יוסף
7900 ,פתח תקווה ,678 ,רוזנבלט משה
7900 ,פתח תקווה ,1266 ,רוטברד ברוך
7900 ,פתח תקווה ,129 ,רוטשילד
7900 ,פתח תקווה ,158 ,רום יעל
7900 ,פתח תקווה ,904 ,רופין
7900 ,פתח תקווה ,464 ,רוקח ישראל
7900 ,פתח תקווה ,613 ,רות
7900 ,פתח תקווה ,630 ,רזיאל דוד
7900 ,פתח תקווה ,534 ,רח 600
7900 ,פתח תקווה ,1111 ,רח 701
7900 ,פתח תקווה ,557 ,רח 503
7900 ,פתח תקווה ,556 ,רח 573
7900 ,פתח תקווה ,527 ,רח 593
7900 ,פתח תקווה ,1175 ,רח 793
7900 ,פתח תקווה ,1240 ,רח 604
7900 ,פתח תקווה ,540 ,רח 574
7900 ,פתח תקווה ,528 ,רח 594
7900 ,פתח תקווה ,1176 ,רח 794
7900 ,פתח תקווה ,359 ,רח 575
7900 ,פתח תקווה ,529 ,רח 595
7900 ,פתח תקווה ,1177 ,רח 795
7900 ,פתח תקווה ,434 ,רח 936
7900 ,פתח תקווה ,554 ,רח 576
7900 ,פתח תקווה ,530 ,רח 596
7900 ,פתח תקווה ,531 ,רח 597
7900 ,פתח תקווה ,532 ,רח 598
7900 ,פתח תקווה ,533 ,רח 599
7900 ,פתח תקווה ,190 ,רחובות
7900 ,פתח תקווה ,322 ,רחל המשוררת
7900 ,פתח תקווה ,1267 ,רחל ונחום
7900 ,פתח תקווה ,1044 ,רחמים בובליל
7900 ,פתח תקווה ,539 ,ריבלין
7900 ,פתח תקווה ,1420 ,רייס רפאל
7900 ,פתח תקווה ,962 ,רייק חביבה
7900 ,פתח תקווה ,125 ,רינגלבלום
7900 ,פתח תקווה ,1115 ,רמ"א
7900 ,פתח תקווה ,235 ,רמב"ם
7900 ,פתח תקווה ,752 ,רמב"ן
7900 ,פתח תקווה ,458 ,רמז דוד
7900 ,פתח תקווה ,432 ,רמח"ל
7900 ,פתח תקווה ,484 ,רמת הגולן
7900 ,פתח תקווה ,6525 ,רמת סיב
7900 ,פתח תקווה ,152 ,רנ"ק
7900 ,פתח תקווה ,252 ,רפאל איתן
7900 ,פתח תקווה ,1280 ,רפאלי שרגא
7900 ,פתח תקווה ,132 ,רפפורט נתן
7900 ,פתח תקווה ,640 ,רצון מנחם
7900 ,פתח תקווה ,903 ,רש"י
7900 ,פתח תקווה ,348 ,רשיש חדוה
7900 ,פתח תקווה ,6629 ,ש 3 קומות עמישב
7900 ,פתח תקווה ,6582 ,ש אחדות
7900 ,פתח תקווה ,6583 ,ש אחדות חדש
7900 ,פתח תקווה ,6506 ,ש אלמגור
7900 ,פתח תקווה ,6609 ,ש אסבסט עמישב
7900 ,פתח תקווה ,6584 ,ש ארגוב
7900 ,פתח תקווה ,6610 ,ש בת שבע
7900 ,פתח תקווה ,6561 ,ש גורדון
7900 ,פתח תקווה ,6526 ,ש גן בועז
7900 ,פתח תקווה ,6527 ,ש גני גזית
7900 ,פתח תקווה ,6544 ,ש גת רמון
7900 ,פתח תקווה ,6585 ,ש הגרמנים
7900 ,פתח תקווה ,6545 ,ש המורים
7900 ,פתח תקווה ,6586 ,ש העצמאות
7900 ,פתח תקווה ,6616 ,ש ותיקים פג'ה
7900 ,פתח תקווה ,6587 ,ש זוגות צעירים
7900 ,פתח תקווה ,6617 ,ש חדש עמישב
7900 ,פתח תקווה ,6588 ,ש חילים משוחררים
7900 ,פתח תקווה ,6618 ,ש חסידוף
7900 ,פתח תקווה ,6619 ,ש חסכון
7900 ,פתח תקווה ,6589 ,ש יבהם
7900 ,פתח תקווה ,6590 ,ש לומיר
7900 ,פתח תקווה ,6529 ,ש ליפוביץ
7900 ,פתח תקווה ,6620 ,ש מזרחי חדש
7900 ,פתח תקווה ,6591 ,ש מזרחי שעריה
7900 ,פתח תקווה ,6581 ,ש מחנה יהודה
7900 ,פתח תקווה ,6571 ,ש מעון עובדים
7900 ,פתח תקווה ,6592 ,ש מפונים
7900 ,פתח תקווה ,6593 ,ש מפם
7900 ,פתח תקווה ,6594 ,ש נוה יוסף
7900 ,פתח תקווה ,6572 ,ש נכסי חן
7900 ,פתח תקווה ,6622 ,ש סגל פג'ה
7900 ,פתח תקווה ,6530 ,ש סולל בונה
7900 ,פתח תקווה ,6623 ,ש סלע
7900 ,פתח תקווה ,6531 ,ש סלע חדש
7900 ,פתח תקווה ,6532 ,ש עובדי בילינסון
7900 ,פתח תקווה ,6533 ,ש עובדים
7900 ,פתח תקווה ,6595 ,ש עירוני חפץ חיים
7900 ,פתח תקווה ,6624 ,ש עירוני שעריה
7900 ,פתח תקווה ,6596 ,ש עמידר ב
7900 ,פתח תקווה ,6625 ,ש עמידר עמישב
7900 ,פתח תקווה ,6626 ,ש עמידר פג'ה
7900 ,פתח תקווה ,6597 ,ש עממי חפץ חיים
7900 ,פתח תקווה ,6598 ,ש עקיבא
7900 ,פתח תקווה ,6534 ,ש פאג"י
7900 ,פתח תקווה ,6599 ,ש פרסים
7900 ,פתח תקווה ,6651 ,ש פרסים עמישב
7900 ,פתח תקווה ,6501 ,ש צדור
7900 ,פתח תקווה ,6502 ,ש ציונים כללים
7900 ,פתח תקווה ,6627 ,ש קומות עמישב
7900 ,פתח תקווה ,6652 ,ש קומות שעריה
7900 ,פתח תקווה ,6503 ,ש רוגובין
7900 ,פתח תקווה ,6535 ,ש רמת ורבר
7900 ,פתח תקווה ,6536 ,ש רסקו
7900 ,פתח תקווה ,6653 ,ש שבת אחים
7900 ,פתח תקווה ,6654 ,ש שטרייס
7900 ,פתח תקווה ,6628 ,ש תקומה
7900 ,פתח תקווה ,490 ,שאול המלך
7900 ,פתח תקווה ,1043 ,שאול חרנם
7900 ,פתח תקווה ,824 ,שבדיה
7900 ,פתח תקווה ,325 ,שבזי שלום
7900 ,פתח תקווה ,519 ,שבט אשר
7900 ,פתח תקווה ,520 ,שבט בנימין
7900 ,פתח תקווה ,521 ,שבט גד
7900 ,פתח תקווה ,522 ,שבט דן
7900 ,פתח תקווה ,524 ,שבט יהודה
7900 ,פתח תקווה ,518 ,שבט יוסף
7900 ,פתח תקווה ,548 ,שבט יששכר
7900 ,פתח תקווה ,547 ,שבט לוי
7900 ,פתח תקווה ,549 ,שבט נפתלי
7900 ,פתח תקווה ,525 ,שבט ראובן
7900 ,פתח תקווה ,506 ,שבטי ישראל
7900 ,פתח תקווה ,911 ,שדה יצחק
7900 ,פתח תקווה ,1281 ,שדרובצקי שלמה
7900 ,פתח תקווה ,793 ,שוהם
7900 ,פתח תקווה ,1277 ,שווימר רבקה
7900 ,פתח תקווה ,149 ,שוולב משה
7900 ,פתח תקווה ,1209 ,שולזינגר
7900 ,פתח תקווה ,619 ,שולמית
7900 ,פתח תקווה ,1252 ,שז"ר זלמן
7900 ,פתח תקווה ,156 ,שחם
7900 ,פתח תקווה ,107 ,שטמפפר יהושע
7900 ,פתח תקווה ,1149 ,שטרוק הרמן
7900 ,פתח תקווה ,1255 ,שטרן אברהם
7900 ,פתח תקווה ,748 ,שיטרית בכור
7900 ,פתח תקווה ,6517 ,שיכון הפועל המזרחי
7900 ,פתח תקווה ,937 ,שילר שלמה
7900 ,פתח תקווה ,109 ,שיפר יצחק
7900 ,פתח תקווה ,332 ,שיפריס נתן
7900 ,פתח תקווה ,195 ,שירת אסתר
7900 ,פתח תקווה ,6510 ,שכ אחים ישראלית
7900 ,פתח תקווה ,6631 ,שכ בילינסון
7900 ,פתח תקווה ,6516 ,שכ בר יהודה
7900 ,פתח תקווה ,6537 ,שכ גן שלמה
7900 ,פתח תקווה ,6504 ,שכ הרכבת
7900 ,פתח תקווה ,6612 ,שכ יוספטל
7900 ,פתח תקווה ,6562 ,שכ משכנות גנים
7900 ,פתח תקווה ,6512 ,שכ נווה מעוז
7900 ,פתח תקווה ,6528 ,שכ סגולה
7900 ,פתח תקווה ,6505 ,שכ עין גנים
7900 ,פתח תקווה ,6602 ,שכ עמישב
7900 ,פתח תקווה ,6633 ,שכ קרוונים
7900 ,פתח תקווה ,6508 ,שכ קרול
7900 ,פתח תקווה ,6509 ,שכ שיפר
7900 ,פתח תקווה ,552 ,שכטר יוסף
7900 ,פתח תקווה ,1154 ,של"ג
7900 ,פתח תקווה ,137 ,שלום עליכם
7900 ,פתח תקווה ,1199 ,שלום שמן
7900 ,פתח תקווה ,468 ,שלומציון המלכה
7900 ,פתח תקווה ,250 ,שלונסקי אברהם
7900 ,פתח תקווה ,639 ,שלמה
7900 ,פתח תקווה ,438 ,שמואל הנגיד
7900 ,פתח תקווה ,600 ,שמואל יבניאלי
7900 ,פתח תקווה ,197 ,שמואלביץ מרדכי
7900 ,פתח תקווה ,251 ,שמואלי
7900 ,פתח תקווה ,714 ,שמחוני אסף
7900 ,פתח תקווה ,1310 ,שמחי דוד
7900 ,פתח תקווה ,1134 ,שמעוני דוד
7900 ,פתח תקווה ,655 ,שמעיה ואבטליון
7900 ,פתח תקווה ,1008 ,שמשון
7900 ,פתח תקווה ,1147 ,שניאור זלמן
7900 ,פתח תקווה ,1107 ,שנקר אריה
7900 ,פתח תקווה ,6632 ,שעריה
7900 ,פתח תקווה ,234 ,שפיגל זוסיה
7900 ,פתח תקווה ,1307 ,שפילברג שרגא
7900 ,פתח תקווה ,729 ,שפינוזה
7900 ,פתח תקווה ,803 ,שפירא הרמן צבי
7900 ,פתח תקווה ,730 ,שפרינצק
7900 ,פתח תקווה ,1282 ,שקלובסקי אליהו
7900 ,פתח תקווה ,329 ,שרעבי שלום
7900 ,פתח תקווה ,960 ,שרת משה
7900 ,פתח תקווה ,646 ,תבורי משה
7900 ,פתח תקווה ,1247 ,תדהר דוד
7900 ,פתח תקווה ,171 ,תוצרת הארץ
7900 ,פתח תקווה ,879 ,תור הזהב
7900 ,פתח תקווה ,439 ,תורה ועבודה
7900 ,פתח תקווה ,202 ,תל חי
7900 ,פתח תקווה ,742 ,תלמים
7900 ,פתח תקווה ,618 ,תפוצות ישראל
839 ,פתחיה ,9000 ,פתחיה
413 ,צאלים ,102 ,אולפן קיבוץ
413 ,צאלים ,101 ,בית ראשון
413 ,צאלים ,9000 ,צאלים
1180 ,צביה ,102 ,דרך הקשת
1180 ,צביה ,103 ,האלה
1180 ,צביה ,106 ,האלון
1180 ,צביה ,107 ,הטל
1180 ,צביה ,105 ,הכליל
1180 ,צביה ,101 ,השחר
1180 ,צביה ,104 ,השקד
1180 ,צביה ,9000 ,צביה
1213 ,צבעון ,9000 ,צבעון
465 ,צובה ,101 ,אולפן קיבוץ
465 ,צובה ,9000 ,צובה
1136 ,צוחר ,101 ,מרכז קליטה
1136 ,צוחר ,9000 ,צוחר
1111 ,צופיה ,9000 ,צופיה
3791 ,צופים ,114 ,אורן
3791 ,צופים ,115 ,אלון
3791 ,צופים ,116 ,ארז
3791 ,צופים ,117 ,ברוש
3791 ,צופים ,105 ,האגוז
3791 ,צופים ,111 ,האלה
3791 ,צופים ,109 ,האתרוג
3791 ,צופים ,107 ,הגפן
3791 ,צופים ,113 ,הדר
3791 ,צופים ,110 ,ההדס
3791 ,צופים ,104 ,הזית
3791 ,צופים ,108 ,החרוב
3791 ,צופים ,103 ,הרימון
3791 ,צופים ,101 ,השקד
3791 ,צופים ,112 ,התאנה
3791 ,צופים ,102 ,התמר
3791 ,צופים ,118 ,לוז
3791 ,צופים ,122 ,לוטם
3791 ,צופים ,119 ,משעול נרקיס
3791 ,צופים ,120 ,סחלב
3791 ,צופים ,106 ,ערבי נחל
3791 ,צופים ,9000 ,צופים
3791 ,צופים ,121 ,שומשום
198 ,צופית ,110 ,ברוש
198 ,צופית ,111 ,גפן
198 ,צופית ,112 ,דקל
198 ,צופית ,101 ,האילנות
198 ,צופית ,108 ,הבאר
198 ,צופית ,113 ,הגנים
198 ,צופית ,105 ,הדר
198 ,צופית ,102 ,הזית
198 ,צופית ,115 ,החצב
198 ,צופית ,114 ,החרוב
198 ,צופית ,109 ,המצפה
198 ,צופית ,107 ,המשעול
198 ,צופית ,104 ,הקשת
198 ,צופית ,103 ,השדרה
198 ,צופית ,106 ,מעלה הגן
198 ,צופית ,9000 ,צופית
1150 ,צופר ,9000 ,צופר
1102 ,צוקי ים ,101 ,דרך האלמוג
1102 ,צוקי ים ,102 ,דרך הזיתים
1102 ,צוקי ים ,103 ,דרך הים
1102 ,צוקי ים ,111 ,דרך השונית
1102 ,צוקי ים ,110 ,החוף
1102 ,צוקי ים ,104 ,סמ גלים
1102 ,צוקי ים ,105 ,סמ הצדף
1102 ,צוקי ים ,106 ,סמ השחף
1102 ,צוקי ים ,107 ,סמ כוכב הים
1102 ,צוקי ים ,108 ,סמ נר הלילה
1102 ,צוקי ים ,109 ,סמ שלדג
1102 ,צוקי ים ,9000 ,צוקי ים
1262 ,צוקים ,9000 ,צוקים
1113 ,צור הדסה ,266 ,אגוז
1113 ,צור הדסה ,165 ,אגמית
1113 ,צור הדסה ,114 ,אגס
1113 ,צור הדסה ,267 ,אורן
1113 ,צור הדסה ,113 ,אלה
1113 ,צור הדסה ,107 ,אלון
1113 ,צור הדסה ,156 ,אנפה
1113 ,צור הדסה ,141 ,אפרסמון
1113 ,צור הדסה ,268 ,ארז
1113 ,צור הדסה ,148 ,אשד
1113 ,צור הדסה ,134 ,בשמת
1113 ,צור הדסה ,146 ,דוכיפת
1113 ,צור הדסה ,126 ,דולב
1113 ,צור הדסה ,117 ,דפנה
1113 ,צור הדסה ,269 ,דקל
1113 ,צור הדסה ,142 ,דרור
1113 ,צור הדסה ,153 ,דרך השמורה
1113 ,צור הדסה ,151 ,הזית
1113 ,צור הדסה ,133 ,הקנמון
1113 ,צור הדסה ,150 ,הר כתרון
1113 ,צור הדסה ,278 ,הרדוף
1113 ,צור הדסה ,123 ,ורד
1113 ,צור הדסה ,157 ,ורדית
1113 ,צור הדסה ,160 ,חוגלה
1113 ,צור הדסה ,149 ,טללים
1113 ,צור הדסה ,106 ,יסמין
1113 ,צור הדסה ,270 ,יסעור
1113 ,צור הדסה ,147 ,יקינטון
1113 ,צור הדסה ,271 ,כחל
1113 ,צור הדסה ,272 ,כליל החורש
1113 ,צור הדסה ,104 ,כלנית
1113 ,צור הדסה ,132 ,כרכום
1113 ,צור הדסה ,131 ,לבונה
1113 ,צור הדסה ,145 ,לוטם
1113 ,צור הדסה ,110 ,מבוא הגפן
1113 ,צור הדסה ,124 ,מבוא הדס
1113 ,צור הדסה ,111 ,מבוא החרוב
1113 ,צור הדסה ,143 ,מבוא חצב
1113 ,צור הדסה ,139 ,מבוא נרד
1113 ,צור הדסה ,144 ,מבוא סיגלית
1113 ,צור הדסה ,112 ,מבוא תמר
1113 ,צור הדסה ,130 ,מור
1113 ,צור הדסה ,137 ,מרווה
1113 ,צור הדסה ,101 ,נורית
1113 ,צור הדסה ,155 ,נחליאלי
1113 ,צור הדסה ,135 ,נטפים
1113 ,צור הדסה ,109 ,נרקיס
1113 ,צור הדסה ,105 ,סביון
1113 ,צור הדסה ,158 ,סייפן
1113 ,צור הדסה ,159 ,סלעית
1113 ,צור הדסה ,161 ,סנונית
1113 ,צור הדסה ,154 ,עגור
1113 ,צור הדסה ,121 ,עירית
1113 ,צור הדסה ,273 ,עפרוני
1113 ,צור הדסה ,115 ,ערבה
1113 ,צור הדסה ,122 ,פרג
1113 ,צור הדסה ,119 ,צבעוני
1113 ,צור הדסה ,9000 ,צור הדסה
1113 ,צור הדסה ,127 ,צפית
1113 ,צור הדסה ,274 ,קורנית
1113 ,צור הדסה ,102 ,קטלב
1113 ,צור הדסה ,138 ,קידה
1113 ,צור הדסה ,136 ,קציעה
1113 ,צור הדסה ,125 ,רכסים
1113 ,צור הדסה ,108 ,רמון
1113 ,צור הדסה ,120 ,רקפת
1113 ,צור הדסה ,140 ,שד הרי יהודה
1113 ,צור הדסה ,128 ,שורק
1113 ,צור הדסה ,116 ,שיטה
1113 ,צור הדסה ,275 ,שיקמה
1113 ,צור הדסה ,6500 ,שכ הר כתרון
1113 ,צור הדסה ,163 ,שלדג
1113 ,צור הדסה ,129 ,שלמון
1113 ,צור הדסה ,103 ,שקד
1113 ,צור הדסה ,276 ,תאנה
1113 ,צור הדסה ,277 ,תור
1113 ,צור הדסה ,118 ,תות
1113 ,צור הדסה ,152 ,תלתן
1345 ,צור יצחק ,105 ,נחל איילון
1345 ,צור יצחק ,101 ,נחל אלכסנדר
1345 ,צור יצחק ,111 ,נחל ברק
1345 ,צור יצחק ,109 ,נחל דוד
1345 ,צור יצחק ,106 ,נחל הדס
1345 ,צור יצחק ,108 ,נחל ערוגות
1345 ,צור יצחק ,107 ,נחל פארן
1345 ,צור יצחק ,102 ,נחל פולג
1345 ,צור יצחק ,110 ,נחל צלמון
1345 ,צור יצחק ,103 ,נחל קנה
1345 ,צור יצחק ,104 ,נחל שילה
1345 ,צור יצחק ,9000 ,צור יצחק
276 ,צור משה ,6501 ,בוסתן הכפר
276 ,צור משה ,109 ,דוכיפת
276 ,צור משה ,103 ,האילנות
276 ,צור משה ,115 ,האנפה
276 ,צור משה ,136 ,האתרוג
276 ,צור משה ,138 ,הבוסתן
276 ,צור משה ,110 ,הבנים
276 ,צור משה ,139 ,הגפן
276 ,צור משה ,108 ,הדקלים
276 ,צור משה ,119 ,הדרור
276 ,צור משה ,6502 ,הדרים
276 ,צור משה ,143 ,ההדס
276 ,צור משה ,140 ,ההדר
276 ,צור משה ,142 ,הזית
276 ,צור משה ,111 ,הזמיר
276 ,צור משה ,107 ,החורשים
276 ,צור משה ,135 ,החיטה
276 ,צור משה ,102 ,החלוצים
276 ,צור משה ,117 ,החסידה
276 ,צור משה ,134 ,הלולב
276 ,צור משה ,114 ,הסנונית
276 ,צור משה ,113 ,העגור
276 ,צור משה ,130 ,הערבה
276 ,צור משה ,131 ,הפרדס
276 ,צור משה ,101 ,הפרדסים
276 ,צור משה ,144 ,הצבר
276 ,צור משה ,137 ,הרימון
276 ,צור משה ,118 ,השחף
276 ,צור משה ,104 ,השיבולים
276 ,צור משה ,112 ,השלדג
276 ,צור משה ,132 ,השעורה
276 ,צור משה ,133 ,התאנה
276 ,צור משה ,141 ,התמר
276 ,צור משה ,106 ,סמ דוד פלורנטין
276 ,צור משה ,116 ,סמ האנפה
276 ,צור משה ,105 ,פלורנטין דוד
276 ,צור משה ,9000 ,צור משה
276 ,צור משה ,6500 ,שכ גבעת משה
1148 ,צור נתן ,110 ,דרך השקד
1148 ,צור נתן ,112 ,מבוא האלה
1148 ,צור נתן ,111 ,מבוא האלון
1148 ,צור נתן ,121 ,מבוא האשל
1148 ,צור נתן ,113 ,מבוא האתרוג
1148 ,צור נתן ,114 ,מבוא הגפן
1148 ,צור נתן ,115 ,מבוא הדס
1148 ,צור נתן ,122 ,מבוא הזית
1148 ,צור נתן ,116 ,מבוא החרוב
1148 ,צור נתן ,117 ,מבוא הערבה
1148 ,צור נתן ,123 ,מבוא הפיקוס
1148 ,צור נתן ,124 ,מבוא הצפצפה
1148 ,צור נתן ,119 ,מבוא הרותם
1148 ,צור נתן ,118 ,מבוא הרימון
1148 ,צור נתן ,120 ,מבוא התאנה
1148 ,צור נתן ,126 ,משעול דוכיפת
1148 ,צור נתן ,125 ,משעול דרור
1148 ,צור נתן ,9000 ,צור נתן
774 ,צוריאל ,9000 ,צוריאל
1221 ,צורית ,107 ,האלה
1221 ,צורית ,101 ,האלון
1221 ,צורית ,109 ,הארז
1221 ,צורית ,105 ,הברוש
1221 ,צורית ,117 ,הדובדבן
1221 ,צורית ,118 ,הדקל
1221 ,צורית ,102 ,הזית
1221 ,צורית ,116 ,החרוב
1221 ,צורית ,112 ,הכלנית
1221 ,צורית ,106 ,הסחלב
1221 ,צורית ,108 ,הערבה
1221 ,צורית ,111 ,הצלף
1221 ,צורית ,104 ,הרימון
1221 ,צורית ,110 ,השיטה
1221 ,צורית ,115 ,השקד
1221 ,צורית ,114 ,התאנה
1221 ,צורית ,113 ,התמר
1221 ,צורית ,9000 ,צורית
613 ,ציפורי ,108 ,אמת המים
613 ,ציפורי ,114 ,דרך המנזר
613 ,ציפורי ,107 ,הזיתים
613 ,ציפורי ,117 ,החרובים
613 ,ציפורי ,119 ,המייסדים
613 ,ציפורי ,112 ,המצודה
613 ,ציפורי ,103 ,המשנה
613 ,ציפורי ,115 ,הסנהדרין
613 ,ציפורי ,106 ,הפסיפס
613 ,ציפורי ,111 ,חלב ודבש
613 ,ציפורי ,110 ,מבצע דקל
613 ,ציפורי ,9000 ,ציפורי
613 ,ציפורי ,118 ,קטרון
613 ,ציפורי ,113 ,רבי יהודה הנשיא
613 ,ציפורי ,101 ,רימונים
613 ,ציפורי ,116 ,ריש לקיש
796 ,צלפון ,111 ,האגוז
796 ,צלפון ,110 ,האתרוג
796 ,צלפון ,102 ,הגפן
796 ,צלפון ,105 ,הדקל
796 ,צלפון ,116 ,ההדס
796 ,צלפון ,101 ,הזית
796 ,צלפון ,113 ,החרוב
796 ,צלפון ,114 ,הערבה
796 ,צלפון ,115 ,הצבר
796 ,צלפון ,104 ,הרימון
796 ,צלפון ,112 ,השקד
796 ,צלפון ,107 ,התאנה
796 ,צלפון ,117 ,התמר
796 ,צלפון ,9000 ,צלפון
636 ,צנדלה ,105 ,אבו בכר אלסדיק
636 ,צנדלה ,114 ,אלאנסר
636 ,צנדלה ,104 ,אלביאדר
636 ,צנדלה ,113 ,אלמוהג'ריין
636 ,צנדלה ,109 ,אלמועתסם
636 ,צנדלה ,115 ,אלמזאר
636 ,צנדלה ,111 ,אלסולטאני
636 ,צנדלה ,112 ,אלקדס
636 ,צנדלה ,116 ,בלאל בן רבאח
636 ,צנדלה ,107 ,בן אלהייתם
636 ,צנדלה ,108 ,בן סינא
636 ,צנדלה ,110 ,ג'אבר בן חייאן
636 ,צנדלה ,117 ,וערת אלתינה
636 ,צנדלה ,106 ,מוחמד אלפאתח
636 ,צנדלה ,103 ,מרג' אבן עאמר
636 ,צנדלה ,102 ,סלאח אלדין
636 ,צנדלה ,101 ,עומר בן אלח'טאב
636 ,צנדלה ,9000 ,צנדלה
594 ,צפריה ,106 ,דרך החיטה
594 ,צפריה ,103 ,הארז
594 ,צפריה ,104 ,הגפן
594 ,צפריה ,107 ,הזית
594 ,צפריה ,101 ,הרימון
594 ,צפריה ,105 ,התאנה
594 ,צפריה ,102 ,התמר
594 ,צפריה ,9000 ,צפריה
1079 ,צפרירים ,9000 ,צפרירים
8000 ,צפת ,352 ,אבוהב
8000 ,צפת ,362 ,אבריטש
8000 ,צפת ,359 ,אדרעי משה
8000 ,צפת ,124 ,אהרון נחמיאס
8000 ,צפת ,459 ,אודם
8000 ,צפת ,334 ,אולפן סוכנות
8000 ,צפת ,6500 ,אזור הוילות
8000 ,צפת ,6534 ,אזור תעשיה חדש
8000 ,צפת ,6529 ,אזור תעשיה ישן
8000 ,צפת ,360 ,איסקוב ליאון
8000 ,צפת ,422 ,איש גמזו
8000 ,צפת ,366 ,אלואיל אריה
8000 ,צפת ,558 ,אלומות
8000 ,צפת ,429 ,אלעזר דוד
8000 ,צפת ,341 ,אלקבץ שלמה
8000 ,צפת ,353 ,אלשיך
8000 ,צפת ,421 ,אנילביץ
8000 ,צפת ,433 ,אצ"ל
8000 ,צפת ,702 ,ארז הלבנון
8000 ,צפת ,358 ,ארלוזורוב
8000 ,צפת ,346 ,ב"ק ישראל
8000 ,צפת ,500 ,בגין מנחם
8000 ,צפת ,407 ,בי"ח רבקה זיו
8000 ,צפת ,110 ,ביאליק
8000 ,צפת ,306 ,בית בוסל
8000 ,צפת ,116 ,בית חנה
8000 ,צפת ,333 ,בית יוסף
8000 ,צפת ,340 ,בר יוחאי
8000 ,צפת ,460 ,ברקת
8000 ,צפת ,600 ,בת הרים
8000 ,צפת ,6526 ,גבעת שושנה
8000 ,צפת ,704 ,גרגר הנחלים
8000 ,צפת ,123 ,דולב
8000 ,צפת ,105 ,דורון צבי
8000 ,צפת ,465 ,דרך אבני החושן
8000 ,צפת ,335 ,דרך החסידים
8000 ,צפת ,436 ,דרך היצירה
8000 ,צפת ,434 ,דרך הרמב"ם
8000 ,צפת ,439 ,דרך השומשום
8000 ,צפת ,440 ,דרך השוקולד
8000 ,צפת ,700 ,דרך עכברא
8000 ,צפת ,226 ,דרך קק"ל
8000 ,צפת ,575 ,האגוז
8000 ,צפת ,577 ,האורן
8000 ,צפת ,701 ,האזוב
8000 ,צפת ,411 ,האחד עשר
8000 ,צפת ,580 ,האלון
8000 ,צפת ,556 ,האסיף
8000 ,צפת ,343 ,האר"י
8000 ,צפת ,576 ,הארזים
8000 ,צפת ,579 ,הברוש
8000 ,צפת ,109 ,הגדוד השלישי
8000 ,צפת ,601 ,הגולן
8000 ,צפת ,557 ,הגורן
8000 ,צפת ,107 ,הגליל
8000 ,צפת ,508 ,הגפן
8000 ,צפת ,424 ,הדסה
8000 ,צפת ,525 ,הדרור
8000 ,צפת ,328 ,ההגנה
8000 ,צפת ,405 ,ההסתדרות
8000 ,צפת ,142 ,הופרט יעקב
8000 ,צפת ,506 ,הזית
8000 ,צפת ,414 ,החלוץ
8000 ,צפת ,442 ,החלל
8000 ,צפת ,603 ,החרמון
8000 ,צפת ,446 ,החשמל
8000 ,צפת ,444 ,הטכנולוגיה
8000 ,צפת ,438 ,היזמות
8000 ,צפת ,503 ,הירדן
8000 ,צפת ,140 ,הכהן אברהם
8000 ,צפת ,516 ,הכלנית
8000 ,צפת ,356 ,הכשרת הישוב
8000 ,צפת ,447 ,המדע
8000 ,צפת ,530 ,המעוף
8000 ,צפת ,347 ,המקובלים
8000 ,צפת ,372 ,המשיח
8000 ,צפת ,535 ,הנחלים
8000 ,צפת ,518 ,הנרקיס
8000 ,צפת ,415 ,הנשיא
8000 ,צפת ,519 ,הסביון
8000 ,צפת ,6540 ,העיר העתיקה
8000 ,צפת ,219 ,העצמאות
8000 ,צפת ,437 ,הפיתוח
8000 ,צפת ,212 ,הפלמ"ח
8000 ,צפת ,515 ,הפרחים
8000 ,צפת ,703 ,הצבר
8000 ,צפת ,445 ,הצמיחה
8000 ,צפת ,560 ,הקציר
8000 ,צפת ,608 ,הר אביטל
8000 ,צפת ,610 ,הר אדיר
8000 ,צפת ,605 ,הר ארבל
8000 ,צפת ,606 ,הר בנטל
8000 ,צפת ,607 ,הר גלבוע
8000 ,צפת ,609 ,הר גלעד
8000 ,צפת ,501 ,הר וגיא
8000 ,צפת ,604 ,הר כרמל
8000 ,צפת ,222 ,הרב אריה לייב קפלן
8000 ,צפת ,374 ,הרב זילברמן שמואל
8000 ,צפת ,435 ,הרב חורי חיים
8000 ,צפת ,371 ,הרב לוי ביסטריצקי
8000 ,צפת ,376 ,הרב מניני ביתאן
8000 ,צפת ,400 ,הרב מרדכי אליהו
8000 ,צפת ,373 ,הרב קפלן שמחה
8000 ,צפת ,108 ,הרבי מלובאוויטש
8000 ,צפת ,504 ,הרימון
8000 ,צפת ,342 ,הרמונים
8000 ,צפת ,416 ,הרצל
8000 ,צפת ,517 ,הרקפת
8000 ,צפת ,111 ,השבעה
8000 ,צפת ,555 ,השיבולים
8000 ,צפת ,578 ,השקד
8000 ,צפת ,507 ,התאנה
8000 ,צפת ,602 ,התבור
8000 ,צפת ,505 ,התמר
8000 ,צפת ,423 ,ויצמן
8000 ,צפת ,221 ,ז'בוטינסקי זאב
8000 ,צפת ,355 ,זגגי
8000 ,צפת ,127 ,זמיר
8000 ,צפת ,363 ,זק"ש סוניה
8000 ,צפת ,130 ,חוחית
8000 ,צפת ,357 ,חטיבת יפתח
8000 ,צפת ,336 ,חת"ם סופר
8000 ,צפת ,464 ,יהלום
8000 ,צפת ,528 ,יונה
8000 ,צפת ,367 ,יוני
8000 ,צפת ,106 ,יוספטל
8000 ,צפת ,129 ,יסעור
8000 ,צפת ,337 ,יעבץ
8000 ,צפת ,431 ,יפה נוף
8000 ,צפת ,135 ,יצחק אמיתי
8000 ,צפת ,134 ,יצחק פרנקל
8000 ,צפת ,211 ,ירושלים
8000 ,צפת ,113 ,כ"ב ילדי מעלות
8000 ,צפת ,443 ,כ"ג יורדי הסירה
8000 ,צפת ,565 ,כוכב הצפון
8000 ,צפת ,6001 ,ככר דוד גלבוע
8000 ,צפת ,6002 ,ככר המגינים
8000 ,צפת ,581 ,כליל החורש
8000 ,צפת ,312 ,לבנון צבי
8000 ,צפת ,417 ,לוחמי הגטאות
8000 ,צפת ,564 ,לוטם
8000 ,צפת ,427 ,לוי יעקב
8000 ,צפת ,430 ,לח"י
8000 ,צפת ,463 ,לשם
8000 ,צפת ,115 ,מאור חיים
8000 ,צפת ,348 ,מגיני צפת
8000 ,צפת ,338 ,מונטיפיורי
8000 ,צפת ,380 ,מזרחי מנחם
8000 ,צפת ,141 ,מיבר מאיר
8000 ,צפת ,561 ,מכמנים
8000 ,צפת ,218 ,מלון דוד
8000 ,צפת ,217 ,מלון פסגה
8000 ,צפת ,225 ,מעלה הרמ"ק
8000 ,צפת ,6532 ,מעלה צפת
8000 ,צפת ,425 ,מעלות הרב סאסי כהן
8000 ,צפת ,339 ,מעלות משה
8000 ,צפת ,351 ,מעלות עולי הגרדום
8000 ,צפת ,413 ,מעלות קדוש אליהו
8000 ,צפת ,118 ,מעלות רפאל
8000 ,צפת ,550 ,מצפה האגם
8000 ,צפת ,120 ,מצפה הימים
8000 ,צפת ,410 ,מרדכי חביב
8000 ,צפת ,6533 ,מרום כנרת
8000 ,צפת ,365 ,מרזר אריה
8000 ,צפת ,6512 ,מרכז העיר
8000 ,צפת ,419 ,מרכז קליטה
8000 ,צפת ,119 ,מרכז קליטה כנען
8000 ,צפת ,121 ,מרכז קליטה מירון
8000 ,צפת ,375 ,מרכז קליטה צה"ל
8000 ,צפת ,136 ,משה ציפר
8000 ,צפת ,402 ,משה רביב
8000 ,צפת ,354 ,נג'ארה
8000 ,צפת ,6515 ,נווה אורנים
8000 ,צפת ,450 ,נוף הבשן
8000 ,צפת ,452 ,נוף הגולן
8000 ,צפת ,458 ,נוף הגליל
8000 ,צפת ,455 ,נוף ההר
8000 ,צפת ,451 ,נוף החולה
8000 ,צפת ,457 ,נוף החרמון
8000 ,צפת ,453 ,נוף הירדן
8000 ,צפת ,456 ,נוף העמק
8000 ,צפת ,122 ,נוף טליה
8000 ,צפת ,6531 ,נוף כנרת
8000 ,צפת ,461 ,נופך
8000 ,צפת ,537 ,נחל דן
8000 ,צפת ,536 ,נחל עמוד
8000 ,צפת ,526 ,נחליאלי
8000 ,צפת ,131 ,נץ
8000 ,צפת ,126 ,נשרים
8000 ,צפת ,408 ,סולד הנריאטה
8000 ,צפת ,381 ,סמ רימונים
8000 ,צפת ,332 ,סמטה ב
8000 ,צפת ,310 ,סמטה ד
8000 ,צפת ,311 ,סמטה ה
8000 ,צפת ,319 ,סמטה ו
8000 ,צפת ,214 ,סמטה יז
8000 ,צפת ,213 ,סמטה יט
8000 ,צפת ,462 ,ספיר
8000 ,צפת ,370 ,עבו
8000 ,צפת ,6527 ,עכברה
8000 ,צפת ,215 ,עליה ב
8000 ,צפת ,527 ,עפרוני
8000 ,צפת ,125 ,פצ'רסקי אלכסנדר
8000 ,צפת ,426 ,פרומצ'נקו
8000 ,צפת ,529 ,פשוש
8000 ,צפת ,368 ,צה"ל
8000 ,צפת ,562 ,צופית
8000 ,צפת ,132 ,ציונה תג'ר
8000 ,צפת ,9000 ,צפת
8000 ,צפת ,313 ,קארו יוסף
8000 ,צפת ,103 ,קבוץ גלויות
8000 ,צפת ,364 ,קולונל רביצקי
8000 ,צפת ,345 ,קורצ'ק
8000 ,צפת ,454 ,קצה הנוף
8000 ,צפת ,6525 ,קרית ברסלב
8000 ,צפת ,6501 ,קרית הציירים
8000 ,צפת ,6502 ,קרית חב"ד
8000 ,צפת ,6528 ,קרית מאור חיים
8000 ,צפת ,428 ,קרן היסוד
8000 ,צפת ,117 ,רבי דוד ומשה
8000 ,צפת ,307 ,רח ה
8000 ,צפת ,315 ,רח ט
8000 ,צפת ,317 ,רח טו
8000 ,צפת ,316 ,רח טז
8000 ,צפת ,308 ,רח יא
8000 ,צפת ,309 ,רח יב
8000 ,צפת ,329 ,רח יג
8000 ,צפת ,321 ,רח יז
8000 ,צפת ,320 ,רח יח
8000 ,צפת ,318 ,רח יט
8000 ,צפת ,322 ,רח כ"א
8000 ,צפת ,208 ,רח כג
8000 ,צפת ,216 ,רח כה
8000 ,צפת ,206 ,רח כו
8000 ,צפת ,205 ,רח כז
8000 ,צפת ,201 ,רח כח
8000 ,צפת ,203 ,רח ל
8000 ,צפת ,204 ,רח לא
8000 ,צפת ,344 ,רידב"ז
8000 ,צפת ,418 ,רמז דוד
8000 ,צפת ,6530 ,רמת מנחם בגין
8000 ,צפת ,6516 ,רמת רזים
8000 ,צפת ,6503 ,ש בנה ביתך
8000 ,צפת ,6504 ,ש דרום
8000 ,צפת ,6505 ,ש חסכון
8000 ,צפת ,6506 ,ש כנען
8000 ,צפת ,6507 ,ש מודרג
8000 ,צפת ,6508 ,ש נוה הדר
8000 ,צפת ,6509 ,ש עירוני
8000 ,צפת ,6510 ,ש עמידר
8000 ,צפת ,6511 ,ש עממי
8000 ,צפת ,6513 ,ש רופאים
8000 ,צפת ,6514 ,ש רסקו
8000 ,צפת ,510 ,שבעת המינים
8000 ,צפת ,563 ,שגב
8000 ,צפת ,466 ,שוהם
8000 ,צפת ,114 ,שז"ר זלמן
8000 ,צפת ,6517 ,שכ איביקור
8000 ,צפת ,6518 ,שכ גני הדר
8000 ,צפת ,6519 ,שכ הדר
8000 ,צפת ,6520 ,שכ הר כנען
8000 ,צפת ,6537 ,שכ יצחק רבין
8000 ,צפת ,6535 ,שכ נוף טליה
8000 ,צפת ,6521 ,שכ ספיר
8000 ,צפת ,6522 ,שכ קרית שרה
8000 ,צפת ,6536 ,שכ רמת אליה
8000 ,צפת ,6538 ,שכ שקמה
8000 ,צפת ,6523 ,שכונת אשכול
8000 ,צפת ,6524 ,שכונת עופר
8000 ,צפת ,361 ,שמי מנחם
8000 ,צפת ,133 ,שמשון הולצמן
8000 ,צפת ,420 ,שפרינצק
8000 ,צפת ,128 ,תור
8000 ,צפת ,350 ,תרפ"ט
612 ,צרופה ,101 ,הגפן
612 ,צרופה ,102 ,הזית
612 ,צרופה ,103 ,החיטה
612 ,צרופה ,104 ,הרימון
612 ,צרופה ,105 ,השעורה
612 ,צרופה ,106 ,השקד
612 ,צרופה ,107 ,התאנה
612 ,צרופה ,108 ,התמר
612 ,צרופה ,9000 ,צרופה
567 ,צרעה ,9000 ,צרעה
1234 ,קבועה (שבט) ,9000 ,קבועה (שבט)
334 ,קבוצת יבנה ,101 ,אולפן קיבוץ
334 ,קבוצת יבנה ,9000 ,קבוצת יבנה
3557 ,קדומים ,6505 ,אזור התעשיה
3557 ,קדומים ,138 ,אחלמה
3557 ,קדומים ,167 ,איש ימינך
3557 ,קדומים ,180 ,אלה
3557 ,קדומים ,179 ,אלון
3557 ,קדומים ,156 ,אריאל
3557 ,קדומים ,123 ,ברכה
3557 ,קדומים ,103 ,ברקת
3557 ,קדומים ,109 ,גבעות עולם
3557 ,קדומים ,110 ,גבעת שלם
3557 ,קדומים ,153 ,גלעד
3557 ,קדומים ,122 ,גריזים
3557 ,קדומים ,113 ,גת קדומים
3557 ,קדומים ,128 ,דגן
3557 ,קדומים ,132 ,דרך בר-און אורי
3557 ,קדומים ,170 ,דרך גאולה
3557 ,קדומים ,130 ,דרך הציונות
3557 ,קדומים ,131 ,דרך הראשונים
3557 ,קדומים ,168 ,דרך יצחק מלצר
3557 ,קדומים ,157 ,האדרת
3557 ,קדומים ,141 ,הגיתית
3557 ,קדומים ,129 ,הזית
3557 ,קדומים ,173 ,החורשה
3557 ,קדומים ,104 ,החושן
3557 ,קדומים ,159 ,החיל
3557 ,קדומים ,164 ,החליל
3557 ,קדומים ,161 ,היובל
3557 ,קדומים ,139 ,היצירה
3557 ,קדומים ,144 ,הכינור
3557 ,קדומים ,171 ,המייסדים
3557 ,קדומים ,142 ,הנבל
3557 ,קדומים ,158 ,העוז
3557 ,קדומים ,143 ,העשור
3557 ,קדומים ,165 ,הפעמון
3557 ,קדומים ,145 ,הקרן
3557 ,קדומים ,163 ,הר המור
3557 ,קדומים ,107 ,הררי קדם
3557 ,קדומים ,160 ,השקד
3557 ,קדומים ,121 ,חלקת השדה
3557 ,קדומים ,148 ,חפצי בה
3557 ,קדומים ,177 ,חרצית
3557 ,קדומים ,152 ,טל חרמון
3557 ,קדומים ,105 ,יהלום
3557 ,קדומים ,150 ,יפה נוף
3557 ,קדומים ,126 ,יצהר
3557 ,קדומים ,175 ,כלנית
3557 ,קדומים ,115 ,כרמי קדם
3557 ,קדומים ,124 ,לשם
3557 ,קדומים ,114 ,מכללת קדומים
3557 ,קדומים ,169 ,מעלה אילן
3557 ,קדומים ,111 ,מעלה דוד
3557 ,קדומים ,146 ,מעלה חמד
3557 ,קדומים ,151 ,מעלה רש"י
3557 ,קדומים ,116 ,מעלה שניאור
3557 ,קדומים ,112 ,מצפה קדומים
3557 ,קדומים ,135 ,משעול הגפן
3557 ,קדומים ,133 ,משעול החיטה
3557 ,קדומים ,136 ,משעול הרימון
3557 ,קדומים ,162 ,משעול השעורה
3557 ,קדומים ,134 ,משעול התאנה
3557 ,קדומים ,137 ,משעול התמר
3557 ,קדומים ,155 ,נבו
3557 ,קדומים ,178 ,נורית
3557 ,קדומים ,147 ,נחלה
3557 ,קדומים ,154 ,סיני
3557 ,קדומים ,172 ,סמטת הניגון
3557 ,קדומים ,106 ,ספיר
3557 ,קדומים ,149 ,צופיה
3557 ,קדומים ,118 ,צפנת
3557 ,קדומים ,9000 ,קדומים
3557 ,קדומים ,166 ,קול צופייך
3557 ,קדומים ,120 ,רבבות אפרים
3557 ,קדומים ,176 ,רקפת
3557 ,קדומים ,125 ,שבו
3557 ,קדומים ,102 ,שוהם
3557 ,קדומים ,140 ,שילה
3557 ,קדומים ,6500 ,שכ גבעת שלם
3557 ,קדומים ,6502 ,שכ גת קדומים
3557 ,קדומים ,6504 ,שכ כרמי קדם
3557 ,קדומים ,6503 ,שכ מצפה ישי
3557 ,קדומים ,6501 ,שכ מצפה קדומים
3557 ,קדומים ,108 ,תבואות שמש
3557 ,קדומים ,174 ,תורמוס
3557 ,קדומים ,127 ,תירוש
3557 ,קדומים ,119 ,תרצה
3557 ,קדומים ,101 ,תרשיש
195 ,קדימה-צורן ,272 ,אדום
195 ,קדימה-צורן ,6512 ,אזור תעשייה מערבי
195 ,קדימה-צורן ,144 ,אח"י דקר
195 ,קדימה-צורן ,112 ,אחד העם
195 ,קדימה-צורן ,166 ,אלמוג
195 ,קדימה-צורן ,3118 ,אמנון ותמר צורן
195 ,קדימה-צורן ,283 ,ארבל
195 ,קדימה-צורן ,242 ,ארגמן
195 ,קדימה-צורן ,3205 ,אריות הגולן צורן
195 ,קדימה-צורן ,230 ,בגין מנחם
195 ,קדימה-צורן ,110 ,ביאליק
195 ,קדימה-צורן ,282 ,בית הכרם
195 ,קדימה-צורן ,133 ,בר יהודה
195 ,קדימה-צורן ,102 ,ברנר
195 ,קדימה-צורן ,286 ,ברקת
195 ,קדימה-צורן ,149 ,בשמת
195 ,קדימה-צורן ,6514 ,גבעת מנחם בגין
195 ,קדימה-צורן ,162 ,גד
195 ,קדימה-צורן ,123 ,גורדון
195 ,קדימה-צורן ,145 ,ד"ר שפירא
195 ,קדימה-צורן ,251 ,דובדבן
195 ,קדימה-צורן ,244 ,דישון
195 ,קדימה-צורן ,118 ,דליה
195 ,קדימה-צורן ,3114 ,דפנה צורן
195 ,קדימה-צורן ,245 ,דרך דוד בן גוריון
195 ,קדימה-צורן ,290 ,דרך היער
195 ,קדימה-צורן ,100 ,דרך התות
195 ,קדימה-צורן ,3120 ,דרך לב השרון צורן
195 ,קדימה-צורן ,201 ,האגוז
195 ,קדימה-צורן ,3111 ,האדמונית צורן
195 ,קדימה-צורן ,277 ,האופה
195 ,קדימה-צורן ,275 ,האורג
195 ,קדימה-צורן ,191 ,האורן
195 ,קדימה-צורן ,196 ,האזדרכת
195 ,קדימה-צורן ,3102 ,האילן צורן
195 ,קדימה-צורן ,3115 ,האילנות צורן
195 ,קדימה-צורן ,150 ,האירוסים
195 ,קדימה-צורן ,3125 ,האיריס צורן
195 ,קדימה-צורן ,229 ,האלה
195 ,קדימה-צורן ,119 ,האלון
195 ,קדימה-צורן ,235 ,האלונים
195 ,קדימה-צורן ,195 ,האלמוגן
195 ,קדימה-צורן ,127 ,האמירים
195 ,קדימה-צורן ,209 ,האנפה
195 ,קדימה-צורן ,163 ,האפרסמון
195 ,קדימה-צורן ,197 ,האקליפטוס
195 ,קדימה-צורן ,198 ,הארז
195 ,קדימה-צורן ,206 ,האשכולית
195 ,קדימה-צורן ,199 ,האשל
195 ,קדימה-צורן ,151 ,האתרוג
195 ,קדימה-צורן ,216 ,הברבור
195 ,קדימה-צורן ,3103 ,הברוש צורן
195 ,קדימה-צורן ,160 ,הברושים
195 ,קדימה-צורן ,266 ,הגולן
195 ,קדימה-צורן ,254 ,הגומא
195 ,קדימה-צורן ,260 ,הגלבוע
195 ,קדימה-צורן ,263 ,הגליל
195 ,קדימה-צורן ,262 ,הגלעד
195 ,קדימה-צורן ,3104 ,הגפן צורן
195 ,קדימה-צורן ,182 ,הגפנים
195 ,קדימה-צורן ,220 ,הדוכיפת
195 ,קדימה-צורן ,3105 ,הדולב צורן
195 ,קדימה-צורן ,115 ,הדולפין
195 ,קדימה-צורן ,3106 ,הדס צורן
195 ,קדימה-צורן ,156 ,הדרור
195 ,קדימה-צורן ,116 ,ההגנה
195 ,קדימה-צורן ,168 ,ההדסים
195 ,קדימה-צורן ,159 ,ההדר
195 ,קדימה-צורן ,3113 ,ההדרים צורן
195 ,קדימה-צורן ,108 ,ההסתדרות
195 ,קדימה-צורן ,137 ,הורדים
195 ,קדימה-צורן ,3107 ,הזית צורן
195 ,קדימה-צורן ,218 ,הזמיר
195 ,קדימה-צורן ,278 ,הזרם
195 ,קדימה-צורן ,175 ,החבצלת
195 ,קדימה-צורן ,210 ,החוגלה
195 ,קדימה-צורן ,221 ,החליל
195 ,קדימה-צורן ,207 ,החסידה
195 ,קדימה-צורן ,3127 ,החצב צורן
195 ,קדימה-צורן ,253 ,החצבים
195 ,קדימה-צורן ,3108 ,החרוב צורן
195 ,קדימה-צורן ,183 ,החרובים
195 ,קדימה-צורן ,267 ,החרמון
195 ,קדימה-צורן ,3124 ,החרצית צורן
195 ,קדימה-צורן ,280 ,החשמל
195 ,קדימה-צורן ,213 ,הטווס
195 ,קדימה-צורן ,148 ,היובל
195 ,קדימה-צורן ,219 ,היונה
195 ,קדימה-צורן ,153 ,היסמין
195 ,קדימה-צורן ,208 ,היען
195 ,קדימה-צורן ,255 ,היער
195 ,קדימה-צורן ,3109 ,היערה צורן
195 ,קדימה-צורן ,190 ,היקינטון
195 ,קדימה-צורן ,227 ,הכינור
195 ,קדימה-צורן ,169 ,הכלנית
195 ,קדימה-צורן ,259 ,הכרכום
195 ,קדימה-צורן ,154 ,הלבונה
195 ,קדימה-צורן ,187 ,הלולב
195 ,קדימה-צורן ,205 ,הלימון
195 ,קדימה-צורן ,147 ,המור
195 ,קדימה-צורן ,226 ,המיתר
195 ,קדימה-צורן ,279 ,המעלית
195 ,קדימה-צורן ,3122 ,המרגנית צורן
195 ,קדימה-צורן ,276 ,המתכת
195 ,קדימה-צורן ,223 ,הנבל
195 ,קדימה-צורן ,264 ,הנגב
195 ,קדימה-צורן ,281 ,הנגר
195 ,קדימה-צורן ,185 ,הנוריות
195 ,קדימה-צורן ,3110 ,הנורית צורן
195 ,קדימה-צורן ,157 ,הנחליאלי
195 ,קדימה-צורן ,240 ,הניצן
195 ,קדימה-צורן ,3126 ,הנירים צורן
195 ,קדימה-צורן ,171 ,הנרקיס
195 ,קדימה-צורן ,217 ,הנשר
195 ,קדימה-צורן ,176 ,הסביון
195 ,קדימה-צורן ,3112 ,הסביונים צורן
195 ,קדימה-צורן ,3117 ,הסחלב צורן
195 ,קדימה-צורן ,252 ,הסחלבים
195 ,קדימה-צורן ,257 ,הסיגלון
195 ,קדימה-צורן ,3123 ,הסיתונית צורן
195 ,קדימה-צורן ,164 ,הסנונית
195 ,קדימה-צורן ,233 ,העגור
195 ,קדימה-צורן ,215 ,העורב
195 ,קדימה-צורן ,214 ,העיט
195 ,קדימה-צורן ,186 ,הערבה
195 ,קדימה-צורן ,241 ,הערמונים
195 ,קדימה-צורן ,106 ,הפלמ"ח
195 ,קדימה-צורן ,224 ,הפעמון
195 ,קדימה-צורן ,3121 ,הפקאן צורן
195 ,קדימה-צורן ,202 ,הפקאנים
195 ,קדימה-צורן ,188 ,הפרג
195 ,קדימה-צורן ,250 ,הפרדס
195 ,קדימה-צורן ,231 ,הפשוש
195 ,קדימה-צורן ,172 ,הצבעוני
195 ,קדימה-צורן ,152 ,הצבר
195 ,קדימה-צורן ,3116 ,הצברים צורן
195 ,קדימה-צורן ,212 ,הציפורים
195 ,קדימה-צורן ,173 ,הציפורן
195 ,קדימה-צורן ,222 ,הצליל
195 ,קדימה-צורן ,184 ,הקשת
195 ,קדימה-צורן ,268 ,הר נבו
195 ,קדימה-צורן ,236 ,הרב אביקסיס
195 ,קדימה-צורן ,129 ,הרב אלקלעי
195 ,קדימה-צורן ,193 ,הרותם
195 ,קדימה-צורן ,181 ,הרימון
195 ,קדימה-צורן ,124 ,הרצוג
195 ,קדימה-צורן ,111 ,הרצל
195 ,קדימה-צורן ,3128 ,הרקפות צורן
195 ,קדימה-צורן ,170 ,הרקפת
195 ,קדימה-צורן ,265 ,השומרון
195 ,קדימה-צורן ,174 ,השושן
195 ,קדימה-צורן ,165 ,השחף
195 ,קדימה-צורן ,258 ,השיבולת
195 ,קדימה-צורן ,200 ,השיזף
195 ,קדימה-צורן ,194 ,השיטה
195 ,קדימה-צורן ,211 ,השלדג
195 ,קדימה-צורן ,203 ,השקד
195 ,קדימה-צורן ,3101 ,השקמה צורן
195 ,קדימה-צורן ,192 ,השקמים
195 ,קדימה-צורן ,261 ,השרון
195 ,קדימה-צורן ,180 ,התאנה
195 ,קדימה-צורן ,270 ,התבור
195 ,קדימה-צורן ,121 ,התפוז
195 ,קדימה-צורן ,204 ,התפוח
195 ,קדימה-צורן ,113 ,ויצמן
195 ,קדימה-צורן ,120 ,ויתקין
195 ,קדימה-צורן ,125 ,וסרמן סופיה
195 ,קדימה-צורן ,140 ,ז'בוטינסקי
195 ,קדימה-צורן ,3119 ,חולות גאולים צורן
195 ,קדימה-צורן ,139 ,חמנית
195 ,קדימה-צורן ,114 ,חנקין
195 ,קדימה-צורן ,109 ,טהון יהושע
195 ,קדימה-צורן ,238 ,טיילת השרון הירוק
195 ,קדימה-צורן ,285 ,יהלום
195 ,קדימה-צורן ,232 ,יונק הדבש
195 ,קדימה-צורן ,134 ,יפה נוף
195 ,קדימה-צורן ,130 ,כהן אלי
195 ,קדימה-צורן ,143 ,כהן יעקב
195 ,קדימה-צורן ,189 ,כליל החורש
195 ,קדימה-צורן ,269 ,כנרת
195 ,קדימה-צורן ,103 ,כצנלסון
195 ,קדימה-צורן ,146 ,לוע הארי
195 ,קדימה-צורן ,3129 ,לילך צורן
195 ,קדימה-צורן ,246 ,לשם
195 ,קדימה-צורן ,105 ,מ"ג
195 ,קדימה-צורן ,273 ,מואב
195 ,קדימה-צורן ,141 ,מורדי הגטאות
195 ,קדימה-צורן ,135 ,מימון
195 ,קדימה-צורן ,271 ,מירון
195 ,קדימה-צורן ,138 ,מעלה שלמה
195 ,קדימה-צורן ,131 ,משמר הירדן
195 ,קדימה-צורן ,117 ,נופר
195 ,קדימה-צורן ,274 ,סולד
195 ,קדימה-צורן ,301 ,סימטת קפלן
195 ,קדימה-צורן ,177 ,סמ אבני סעדיה
195 ,קדימה-צורן ,239 ,סמ הברון
195 ,קדימה-צורן ,155 ,סמ הגן
195 ,קדימה-צורן ,178 ,סמ ההדס
195 ,קדימה-צורן ,225 ,סמ הפעמון
195 ,קדימה-צורן ,237 ,סמ מדיני מאיר
195 ,קדימה-צורן ,158 ,סמ רמז
195 ,קדימה-צורן ,256 ,סמדר
195 ,קדימה-צורן ,247 ,סמטת בן צבי
195 ,קדימה-צורן ,248 ,סמטת היסמין
195 ,קדימה-צורן ,126 ,סנפיר
195 ,קדימה-צורן ,287 ,ספיר
195 ,קדימה-צורן ,132 ,עדעד
195 ,קדימה-צורן ,249 ,עמק החולה
195 ,קדימה-צורן ,101 ,עמק יזרעאל
195 ,קדימה-צורן ,3200 ,עפרוני צורן
195 ,קדימה-צורן ,6511 ,פארק תעשיות השרון
195 ,קדימה-צורן ,3201 ,צופית צורן
195 ,קדימה-צורן ,6510 ,צורן
195 ,קדימה-צורן ,6508 ,קדימה
195 ,קדימה-צורן ,9000 ,קדימה-צורן
195 ,קדימה-צורן ,136 ,קורצ'ק
195 ,קדימה-צורן ,6509 ,קרית שלמה
195 ,קדימה-צורן ,142 ,רוזנבך
195 ,קדימה-צורן ,107 ,רוטשילד
195 ,קדימה-צורן ,122 ,רמז
195 ,קדימה-צורן ,6513 ,רמת אמיר
195 ,קדימה-צורן ,6515 ,רמת שמואל
195 ,קדימה-צורן ,6500 ,ש אזורים
195 ,קדימה-צורן ,6501 ,ש המועצה
195 ,קדימה-צורן ,6502 ,ש חדש
195 ,קדימה-צורן ,6503 ,ש עמידר
195 ,קדימה-צורן ,6504 ,ש שרת
195 ,קדימה-צורן ,104 ,שבזי
195 ,קדימה-צורן ,300 ,שבט אפרים
195 ,קדימה-צורן ,298 ,שבט אשר
195 ,קדימה-צורן ,293 ,שבט בנימין
195 ,קדימה-צורן ,296 ,שבט דן
195 ,קדימה-צורן ,299 ,שבט זבולון
195 ,קדימה-צורן ,294 ,שבט יהודה
195 ,קדימה-צורן ,295 ,שבט יששכר
195 ,קדימה-צורן ,297 ,שבט נפתלי
195 ,קדימה-צורן ,291 ,שבט ראובן
195 ,קדימה-צורן ,292 ,שבט שמעון
195 ,קדימה-צורן ,3204 ,שביל הפרחים צורן
195 ,קדימה-צורן ,3208 ,שביל התפוזים צורן
195 ,קדימה-צורן ,128 ,שד בן צבי
195 ,קדימה-צורן ,167 ,שד הדקלים
195 ,קדימה-צורן ,3207 ,שד יצחק רבין צורן
195 ,קדימה-צורן ,234 ,שדות
195 ,קדימה-צורן ,288 ,שוהם
195 ,קדימה-צורן ,3202 ,שושן צחור צורן
195 ,קדימה-צורן ,3206 ,שושנת העמקים צורן
195 ,קדימה-צורן ,6505 ,שכ אילנות
195 ,קדימה-צורן ,6507 ,שכ השרון הירוק
195 ,קדימה-צורן ,6506 ,שכ יציב
195 ,קדימה-צורן ,284 ,שניר
195 ,קדימה-צורן ,3203 ,שקנאי צורן
195 ,קדימה-צורן ,161 ,תדהר
195 ,קדימה-צורן ,243 ,תכלת
195 ,קדימה-צורן ,289 ,תמנע
392 ,קדמה ,9000 ,קדמה
4025 ,קדמת צבי ,9000 ,קדמת צבי
3781 ,קדר ,101 ,אור
3781 ,קדר ,109 ,אחדות
3781 ,קדר ,102 ,אחווה
3781 ,קדר ,107 ,הזית
3781 ,קדר ,108 ,השחר
3781 ,קדר ,103 ,זריחה
3781 ,קדר ,106 ,עדן
3781 ,קדר ,9000 ,קדר
3781 ,קדר ,105 ,קשת
3781 ,קדר ,104 ,רעות
615 ,קדרון ,108 ,אפרסק
615 ,קדרון ,101 ,בן צבי יצחק
615 ,קדרון ,113 ,דליה
615 ,קדרון ,106 ,האגס
615 ,קדרון ,105 ,הגפן
615 ,קדרון ,103 ,הדר
615 ,קדרון ,110 ,הורד
615 ,קדרון ,104 ,הזית
615 ,קדרון ,109 ,החרצית
615 ,קדרון ,114 ,הכלנית
615 ,קדרון ,111 ,הלילך
615 ,קדרון ,112 ,הנורית
615 ,קדרון ,102 ,הרימון
615 ,קדרון ,115 ,התאנה
615 ,קדרון ,116 ,התמר
615 ,קדרון ,107 ,התפוח
615 ,קדרון ,9000 ,קדרון
1211 ,קדרים ,9000 ,קדרים
964 ,קודייראת א-צאנע(שבט) ,9000 ,קודייראת א-צאנע
972 ,קוואעין (שבט) ,9000 ,קוואעין (שבט)
766 ,קוממיות ,9000 ,קוממיות
1179 ,קורנית ,111 ,ארבל
1179 ,קורנית ,115 ,בצת
1179 ,קורנית ,117 ,געתון
1179 ,קורנית ,110 ,דישון
1179 ,קורנית ,108 ,דן
1179 ,קורנית ,113 ,הים
1179 ,קורנית ,102 ,הירדן
1179 ,קורנית ,118 ,חילזון
1179 ,קורנית ,107 ,חרמון
1179 ,קורנית ,120 ,יצהר
1179 ,קורנית ,105 ,יראון
1179 ,קורנית ,116 ,כזיב
1179 ,קורנית ,104 ,מורן
1179 ,קורנית ,109 ,מירון
1179 ,קורנית ,101 ,מעלה קורנית
1179 ,קורנית ,119 ,נעמן
1179 ,קורנית ,106 ,צבעון
1179 ,קורנית ,122 ,ציפורי
1179 ,קורנית ,9000 ,קורנית
1179 ,קורנית ,103 ,שניר
1179 ,קורנית ,121 ,שעל
1179 ,קורנית ,114 ,שרך
1179 ,קורנית ,112 ,תבור
1052 ,קטורה ,9000 ,קטורה
1167 ,קיסריה ,189 ,אביב
1167 ,קיסריה ,161 ,אדוה
1167 ,קיסריה ,122 ,אודם
1167 ,קיסריה ,212 ,אודם-פארק התעשייה
1167 ,קיסריה ,156 ,אור
1167 ,קיסריה ,129 ,איריס
1167 ,קיסריה ,202 ,אלון התבור
1167 ,קיסריה ,140 ,אלמוג
1167 ,קיסריה ,204 ,אלת המסטיק
1167 ,קיסריה ,187 ,אקוודוקט
1167 ,קיסריה ,195 ,ארבע העונות
1167 ,קיסריה ,134 ,אשכולית
1167 ,קיסריה ,176 ,בז
1167 ,קיסריה ,230 ,בזלת
1167 ,קיסריה ,200 ,ברק
1167 ,קיסריה ,232 ,ברקן
1167 ,קיסריה ,123 ,ברקת
1167 ,קיסריה ,213 ,ברקת-פארק התעשייה
1167 ,קיסריה ,241 ,גיא
1167 ,קיסריה ,251 ,גל
1167 ,קיסריה ,6513 ,גני קיסריה
1167 ,קיסריה ,223 ,גרניט
1167 ,קיסריה ,162 ,דוגית
1167 ,קיסריה ,173 ,דוכיפת
1167 ,קיסריה ,174 ,דרור
1167 ,קיסריה ,250 ,דרך הים
1167 ,קיסריה ,143 ,דרך פל-ים
1167 ,קיסריה ,144 ,דרך קיסריה
1167 ,קיסריה ,112 ,האגוז
1167 ,קיסריה ,111 ,האגס
1167 ,קיסריה ,228 ,האורג
1167 ,קיסריה ,109 ,האורן
1167 ,קיסריה ,110 ,האלון
1167 ,קיסריה ,201 ,האשל
1167 ,קיסריה ,126 ,האתרוג
1167 ,קיסריה ,108 ,הברוש
1167 ,קיסריה ,105 ,הגפן
1167 ,קיסריה ,151 ,הגשם
1167 ,קיסריה ,164 ,הדולב
1167 ,קיסריה ,253 ,הדיונה
1167 ,קיסריה ,103 ,הדקל
1167 ,קיסריה ,125 ,הדר
1167 ,קיסריה ,127 ,הורדים
1167 ,קיסריה ,152 ,הזוהר
1167 ,קיסריה ,104 ,הזית
1167 ,קיסריה ,114 ,החרוב
1167 ,קיסריה ,225 ,החרש
1167 ,קיסריה ,210 ,הטוחן
1167 ,קיסריה ,153 ,הכוכבים
1167 ,קיסריה ,224 ,הכורם
1167 ,קיסריה ,128 ,הכלניות
1167 ,קיסריה ,118 ,הכרמל
1167 ,קיסריה ,158 ,הלבנה
1167 ,קיסריה ,107 ,המגדל
1167 ,קיסריה ,227 ,הנופר
1167 ,קיסריה ,182 ,הנץ
1167 ,קיסריה ,169 ,הנקר
1167 ,קיסריה ,252 ,הצדף
1167 ,קיסריה ,165 ,הרדוף
1167 ,קיסריה ,203 ,הרדוף הנחלים
1167 ,קיסריה ,226 ,הרוקם
1167 ,קיסריה ,113 ,הרימון
1167 ,קיסריה ,157 ,הרקיע
1167 ,קיסריה ,168 ,השונית
1167 ,קיסריה ,115 ,השזיף
1167 ,קיסריה ,141 ,השחף
1167 ,קיסריה ,139 ,השחר
1167 ,קיסריה ,205 ,השיטה
1167 ,קיסריה ,254 ,השמורה
1167 ,קיסריה ,159 ,השמים
1167 ,קיסריה ,155 ,השמש
1167 ,קיסריה ,102 ,השקד
1167 ,קיסריה ,106 ,התאנה
1167 ,קיסריה ,175 ,התור
1167 ,קיסריה ,101 ,התמר
1167 ,קיסריה ,180 ,זמיר
1167 ,קיסריה ,131 ,חבצלת
1167 ,קיסריה ,209 ,חבצלת החוף
1167 ,קיסריה ,255 ,חולית
1167 ,קיסריה ,170 ,חוף הזהב
1167 ,קיסריה ,199 ,חורף
1167 ,קיסריה ,229 ,חלמיש
1167 ,קיסריה ,124 ,טופז
1167 ,קיסריה ,240 ,טל
1167 ,קיסריה ,207 ,ידיד החולות
1167 ,קיסריה ,146 ,יהלום
1167 ,קיסריה ,214 ,יהלום-פארק התעשייה
1167 ,קיסריה ,138 ,כיכר הצמרות
1167 ,קיסריה ,177 ,כנרית
1167 ,קיסריה ,163 ,ליבנה
1167 ,קיסריה ,120 ,לימון
1167 ,קיסריה ,145 ,לשם
1167 ,קיסריה ,217 ,לשם-פארק התעשייה
1167 ,קיסריה ,160 ,מאדים
1167 ,קיסריה ,192 ,מבוא הצאלון
1167 ,קיסריה ,142 ,מבוא חמה
1167 ,קיסריה ,116 ,מלון דן קיסריה
1167 ,קיסריה ,117 ,מלון סמדר
1167 ,קיסריה ,136 ,מנדרינה
1167 ,קיסריה ,231 ,משמרות
1167 ,קיסריה ,6512 ,נאות גולף
1167 ,קיסריה ,149 ,נגה
1167 ,קיסריה ,206 ,נר הלילה
1167 ,קיסריה ,130 ,נרקיס
1167 ,קיסריה ,183 ,נשר
1167 ,קיסריה ,171 ,סלעית
1167 ,קיסריה ,191 ,סמ הדולב
1167 ,קיסריה ,190 ,סמ הליבנה
1167 ,קיסריה ,181 ,סנונית
1167 ,קיסריה ,211 ,סנונית-פארק התעשייה
1167 ,קיסריה ,166 ,סנפיר
1167 ,קיסריה ,121 ,ספיר
1167 ,קיסריה ,215 ,ספיר-פארק התעשייה
1167 ,קיסריה ,196 ,סתיו
1167 ,קיסריה ,167 ,עגור
1167 ,קיסריה ,184 ,עיט
1167 ,קיסריה ,178 ,ענבר
1167 ,קיסריה ,179 ,עפרוני
1167 ,קיסריה ,221 ,ערה
1167 ,קיסריה ,6520 ,פארק התעשייה
1167 ,קיסריה ,135 ,פומלו
1167 ,קיסריה ,172 ,צופית
1167 ,קיסריה ,9000 ,קיסריה
1167 ,קיסריה ,7001 ,קיסריה העתיקה
1167 ,קיסריה ,119 ,קיסריה וילות
1167 ,קיסריה ,150 ,קיסרית
1167 ,קיסריה ,197 ,קיץ
1167 ,קיסריה ,208 ,רותם המדבר
1167 ,קיסריה ,6514 ,רמות קיסריה
1167 ,קיסריה ,148 ,רעם
1167 ,קיסריה ,132 ,רקפת
1167 ,קיסריה ,154 ,שביט
1167 ,קיסריה ,188 ,שבתאי
1167 ,קיסריה ,186 ,שד רוטשילד
1167 ,קיסריה ,133 ,שוהם
1167 ,קיסריה ,216 ,שוהם-פארק התעשייה
1167 ,קיסריה ,222 ,שחם
1167 ,קיסריה ,6506 ,שכ אבני חן
1167 ,קיסריה ,6504 ,שכ הביכורים
1167 ,קיסריה ,6510 ,שכ ההדרים
1167 ,קיסריה ,6507 ,שכ החוף
1167 ,קיסריה ,6503 ,שכ החורש
1167 ,קיסריה ,6501 ,שכ הים
1167 ,קיסריה ,6511 ,שכ המעוף
1167 ,קיסריה ,6505 ,שכ העדי
1167 ,קיסריה ,6509 ,שכ הפרחים
1167 ,קיסריה ,6502 ,שכ הציפורים
1167 ,קיסריה ,6500 ,שכ הצמרות
1167 ,קיסריה ,6508 ,שכ הרקיע
1167 ,קיסריה ,6515 ,שכונה בטבע
1167 ,קיסריה ,185 ,שלדג
1167 ,קיסריה ,137 ,תפוז
1167 ,קיסריה ,147 ,תרשיש
1167 ,קיסריה ,218 ,תרשיש-פארק התעשייה
414 ,קלחים ,9000 ,קלחים
3601 ,קליה ,9000 ,קליה
638 ,קלנסווה ,218 ,אבו בכר
638 ,קלנסווה ,259 ,אבו הורירה
638 ,קלנסווה ,237 ,אבו חניפה
638 ,קלנסווה ,247 ,אבן ח'ולדון
638 ,קלנסווה ,241 ,אבן חנבל
638 ,קלנסווה ,220 ,אבן מסעוד
638 ,קלנסווה ,165 ,אבן סינא
638 ,קלנסווה ,245 ,אבן קאת'יר
638 ,קלנסווה ,170 ,אבן רושד
638 ,קלנסווה ,281 ,אג'אנדין
638 ,קלנסווה ,258 ,אוחוד
638 ,קלנסווה ,6741 ,אזור דרום מערבי
638 ,קלנסווה ,6730 ,אזור דרומי
638 ,קלנסווה ,6710 ,אזור הכדורגל
638 ,קלנסווה ,6731 ,אזור העירייה
638 ,קלנסווה ,6720 ,אזור מזרחי
638 ,קלנסווה ,6740 ,אזור מעל הנחל
638 ,קלנסווה ,6721 ,אזור צפוני
638 ,קלנסווה ,230 ,אל-אבראר
638 ,קלנסווה ,200 ,אל-אמאן
638 ,קלנסווה ,185 ,אל-אנדלוס
638 ,קלנסווה ,210 ,אל-אסאלה
638 ,קלנסווה ,286 ,אל-אסבאט
638 ,קלנסווה ,250 ,אל-אספהאני
638 ,קלנסווה ,228 ,אל-אקסא
638 ,קלנסווה ,260 ,אל-ארקם
638 ,קלנסווה ,238 ,אל-בוח'ארי
638 ,קלנסווה ,215 ,אל-בורג'
638 ,קלנסווה ,192 ,אל-בטוף
638 ,קלנסווה ,184 ,אל-ביאדר
638 ,קלנסווה ,183 ,אל-ביסאן
638 ,קלנסווה ,196 ,אל-בירווה
638 ,קלנסווה ,243 ,אל-בירוני
638 ,קלנסווה ,197 ,אל-בלדיה
638 ,קלנסווה ,273 ,אל-בלוט
638 ,קלנסווה ,179 ,אל-בנפסג'
638 ,קלנסווה ,206 ,אל-בראק
638 ,קלנסווה ,232 ,אל-ג'ילאני
638 ,קלנסווה ,198 ,אל-ג'ליל
638 ,קלנסווה ,240 ,אל-דסוקי
638 ,קלנסווה ,213 ,אל-הודא
638 ,קלנסווה ,173 ,אל-ואדי
638 ,קלנסווה ,186 ,אל-ורוד
638 ,קלנסווה ,223 ,אל-זביר
638 ,קלנסווה ,169 ,אל-זגאריד
638 ,קלנסווה ,265 ,אל-זהרא
638 ,קלנסווה ,267 ,אל-זיתון
638 ,קלנסווה ,172 ,אל-זנבק
638 ,קלנסווה ,187 ,אל-זעתר
638 ,קלנסווה ,249 ,אל-ח'וארזמי
638 ,קלנסווה ,254 ,אל-ח'נדק
638 ,קלנסווה ,205 ,אל-חארה
638 ,קלנסווה ,264 ,אל-חג'אז
638 ,קלנסווה ,201 ,אל-חוריה
638 ,קלנסווה ,283 ,אל-חליל
638 ,קלנסווה ,280 ,אל-חמרא
638 ,קלנסווה ,171 ,אל-חפאייר
638 ,קלנסווה ,233 ,אל-טברי
638 ,קלנסווה ,252 ,אל-ירמוך
638 ,קלנסווה ,270 ,אל-כהף
638 ,קלנסווה ,182 ,אל-כרמל
638 ,קלנסווה ,262 ,אל-מדינה
638 ,קלנסווה ,261 ,אל-מהדי
638 ,קלנסווה ,202 ,אל-מואדה
638 ,קלנסווה ,162 ,אל-מלק
638 ,קלנסווה ,177 ,אל-מסק
638 ,קלנסווה ,272 ,אל-נכיל
638 ,קלנסווה ,181 ,אל-נענע
638 ,קלנסווה ,271 ,אל-סבר
638 ,קלנסווה ,189 ,אל-סובל
638 ,קלנסווה ,188 ,אל-סוסן
638 ,קלנסווה ,246 ,אל-עולום
638 ,קלנסווה ,175 ,אל-ענבר
638 ,קלנסווה ,248 ,אל-פאראבי
638 ,קלנסווה ,209 ,אל-פאתח
638 ,קלנסווה ,178 ,אל-פול
638 ,קלנסווה ,193 ,אל-פלוג'ה
638 ,קלנסווה ,287 ,אל-קובה
638 ,קלנסווה ,163 ,אל-קודס
638 ,קלנסווה ,174 ,אל-קורונפל
638 ,קלנסווה ,279 ,אל-קירואן
638 ,קלנסווה ,242 ,אל-קנדי
638 ,קלנסווה ,231 ,אל-ראזי
638 ,קלנסווה ,234 ,אל-ראפעי
638 ,קלנסווה ,217 ,אל-ראשידיה
638 ,קלנסווה ,255 ,אל-רבאט
638 ,קלנסווה ,203 ,אל-רחמה
638 ,קלנסווה ,269 ,אל-רמאן
638 ,קלנסווה ,167 ,אל-רשיד
638 ,קלנסווה ,224 ,אל-שפא
638 ,קלנסווה ,266 ,אל-תות
638 ,קלנסווה ,268 ,אל-תין
638 ,קלנסווה ,180 ,אלג'ורי
638 ,קלנסווה ,214 ,אלחואכיר
638 ,קלנסווה ,226 ,אלחסן בן עלי
638 ,קלנסווה ,195 ,אלמותלת
638 ,קלנסווה ,204 ,אלמעראג'
638 ,קלנסווה ,176 ,אלנרג'ס
638 ,קלנסווה ,207 ,אלסעאדה
638 ,קלנסווה ,194 ,אריחא
638 ,קלנסווה ,284 ,באב אל-עאמוד
638 ,קלנסווה ,288 ,באב חטה
638 ,קלנסווה ,253 ,בדר
638 ,קלנסווה ,225 ,בלאל
638 ,קלנסווה ,235 ,ג'דה
638 ,קלנסווה ,229 ,ג'עפר בן אבי טאלב
638 ,קלנסווה ,251 ,גרנאטה
638 ,קלנסווה ,212 ,דאר אלקוראן
638 ,קלנסווה ,256 ,ח'אלד בן אל וליד
638 ,קלנסווה ,199 ,חטין
638 ,קלנסווה ,277 ,חיפה
638 ,קלנסווה ,227 ,חמזה
638 ,קלנסווה ,278 ,טארק אבן זיאד
638 ,קלנסווה ,257 ,טלחה
638 ,קלנסווה ,190 ,מג'דל
638 ,קלנסווה ,275 ,מואתה
638 ,קלנסווה ,263 ,מכה
638 ,קלנסווה ,274 ,סוראקה
638 ,קלנסווה ,164 ,סלאח אל-דין
638 ,קלנסווה ,221 ,סעד בן וקאס
638 ,קלנסווה ,166 ,עומר בן אל-ח'טאב
638 ,קלנסווה ,219 ,עותמאן בן עפאן
638 ,קלנסווה ,216 ,עלי בן אבי טאלב
638 ,קלנסווה ,191 ,עסקלאן
638 ,קלנסווה ,276 ,קורטבה
638 ,קלנסווה ,9000 ,קלנסווה
638 ,קלנסווה ,147 ,רח 20
638 ,קלנסווה ,119 ,רח 120
638 ,קלנסווה ,116 ,רח 30
638 ,קלנסווה ,135 ,רח 40
638 ,קלנסווה ,144 ,רח 150
638 ,קלנסווה ,101 ,רח 1
638 ,קלנסווה ,103 ,רח 11
638 ,קלנסווה ,105 ,רח 111
638 ,קלנסווה ,134 ,רח 21
638 ,קלנסווה ,120 ,רח 121
638 ,קלנסווה ,155 ,רח 131
638 ,קלנסווה ,136 ,רח 41
638 ,קלנסווה ,145 ,רח 151
638 ,קלנסווה ,102 ,רח 2
638 ,קלנסווה ,112 ,רח 12
638 ,קלנסווה ,109 ,רח 112
638 ,קלנסווה ,153 ,רח 132
638 ,קלנסווה ,137 ,רח 42
638 ,קלנסווה ,146 ,רח 152
638 ,קלנסווה ,111 ,רח 3
638 ,קלנסווה ,113 ,רח 13
638 ,קלנסווה ,110 ,רח 113
638 ,קלנסווה ,127 ,רח 123
638 ,קלנסווה ,156 ,רח 133
638 ,קלנסווה ,121 ,רח 4
638 ,קלנסווה ,114 ,רח 14
638 ,קלנסווה ,104 ,רח 114
638 ,קלנסווה ,128 ,רח 124
638 ,קלנסווה ,157 ,רח 134
638 ,קלנסווה ,138 ,רח 44
638 ,קלנסווה ,123 ,רח 5
638 ,קלנסווה ,122 ,רח 15
638 ,קלנסווה ,106 ,רח 115
638 ,קלנסווה ,115 ,רח 25
638 ,קלנסווה ,154 ,רח 135
638 ,קלנסווה ,141 ,רח 155
638 ,קלנסווה ,131 ,רח 6
638 ,קלנסווה ,151 ,רח 16
638 ,קלנסווה ,107 ,רח 116
638 ,קלנסווה ,124 ,רח 126
638 ,קלנסווה ,158 ,רח 136
638 ,קלנסווה ,143 ,רח 146
638 ,קלנסווה ,142 ,רח 156
638 ,קלנסווה ,152 ,רח 17
638 ,קלנסווה ,108 ,רח 117
638 ,קלנסווה ,125 ,רח 127
638 ,קלנסווה ,160 ,רח 137
638 ,קלנסווה ,132 ,רח 18
638 ,קלנסווה ,149 ,רח 118
638 ,קלנסווה ,117 ,רח 128
638 ,קלנסווה ,159 ,רח 138
638 ,קלנסווה ,139 ,רח 148
638 ,קלנסווה ,148 ,רח 158
638 ,קלנסווה ,133 ,רח 19
638 ,קלנסווה ,118 ,רח 119
638 ,קלנסווה ,126 ,רח 129
638 ,קלנסווה ,161 ,רח 139
638 ,קלנסווה ,140 ,רח 149
638 ,קלנסווה ,6500 ,שכ כרמים
4024 ,קלע ,9000 ,קלע
1243 ,קציר ,101 ,דרך האלון
1243 ,קציר ,110 ,האורן
1243 ,קציר ,103 ,האלה
1243 ,קציר ,102 ,הארז
1243 ,קציר ,124 ,הבוצר
1243 ,קציר ,119 ,הגורן
1243 ,קציר ,116 ,הגת
1243 ,קציר ,118 ,הדגן
1243 ,קציר ,114 ,הזורע
1243 ,קציר ,105 ,הזית
1243 ,קציר ,127 ,החורש
1243 ,קציר ,106 ,החרוב
1243 ,קציר ,120 ,החרמש
1243 ,קציר ,109 ,היקב
1243 ,קציר ,121 ,המגוב
1243 ,קציר ,123 ,המגל
1243 ,קציר ,122 ,המורג
1243 ,קציר ,125 ,המחרשה
1243 ,קציר ,126 ,המצפה
1243 ,קציר ,104 ,הפטל
1243 ,קציר ,112 ,הקוצר
1243 ,קציר ,117 ,הקמה
1243 ,קציר ,107 ,הרדוף
1243 ,קציר ,108 ,מורן
1243 ,קציר ,9000 ,קציר
1243 ,קציר ,111 ,שד האמירים
1243 ,קציר ,115 ,שדמה
1243 ,קציר ,113 ,שיבולת
1347 ,קצר א-סר ,9000 ,קצר א-סר
4100 ,קצרין ,107 ,אביטל
4100 ,קצרין ,262 ,אבני איתן
4100 ,קצרין ,313 ,אגוז
4100 ,קצרין ,106 ,אגמית
4100 ,קצרין ,306 ,אגס
4100 ,קצרין ,203 ,אוליפנט
4100 ,קצרין ,261 ,אורטל
4100 ,קצרין ,6501 ,אזור התעשיה
4100 ,קצרין ,113 ,איה
4100 ,קצרין ,298 ,אילון
4100 ,קצרין ,254 ,אל-רום
4100 ,קצרין ,268 ,אלוני הבשן
4100 ,קצרין ,118 ,אלונים
4100 ,קצרין ,249 ,אליעד
4100 ,קצרין ,263 ,אניעם
4100 ,קצרין ,114 ,אנפה
4100 ,קצרין ,255 ,אפיק
4100 ,קצרין ,314 ,אפרסמון
4100 ,קצרין ,312 ,אפרסק
4100 ,קצרין ,187 ,ארבל
4100 ,קצרין ,201 ,ארז
4100 ,קצרין ,128 ,אשחר
4100 ,קצרין ,284 ,אשלים
4100 ,קצרין ,308 ,אתרוג
4100 ,קצרין ,210 ,בדולח
4100 ,קצרין ,211 ,בהט
4100 ,קצרין ,7001 ,בי"ס שדה גולן
4100 ,קצרין ,256 ,בני יהודה
4100 ,קצרין ,277 ,בניאס
4100 ,קצרין ,158 ,ברנע
4100 ,קצרין ,212 ,ברקת
4100 ,קצרין ,285 ,בשור
4100 ,קצרין ,188 ,בתרא
4100 ,קצרין ,213 ,גביש
4100 ,קצרין ,189 ,גבע
4100 ,קצרין ,253 ,גבעת יואב
4100 ,קצרין ,153 ,גלבון
4100 ,קצרין ,190 ,גנוסר
4100 ,קצרין ,282 ,געתון
4100 ,קצרין ,260 ,גשור
4100 ,קצרין ,125 ,דבורנית
4100 ,קצרין ,183 ,דברת
4100 ,קצרין ,303 ,דובדבן
4100 ,קצרין ,123 ,דוכיפת
4100 ,קצרין ,129 ,דולב
4100 ,קצרין ,115 ,דליות
4100 ,קצרין ,281 ,דן
4100 ,קצרין ,133 ,דרור
4100 ,קצרין ,130 ,דרך החרמון
4100 ,קצרין ,230 ,דרך היין
4100 ,קצרין ,231 ,דרך המים
4100 ,קצרין ,232 ,דרך השמן
4100 ,קצרין ,154 ,האומנים
4100 ,קצרין ,127 ,האלה
4100 ,קצרין ,199 ,האפיק
4100 ,קצרין ,165 ,הבאר
4100 ,קצרין ,164 ,הבציר
4100 ,קצרין ,132 ,הגפן
4100 ,קצרין ,163 ,הגת
4100 ,קצרין ,116 ,הדס
4100 ,קצרין ,172 ,החלב
4100 ,קצרין ,112 ,החצב
4100 ,קצרין ,166 ,היובל
4100 ,קצרין ,162 ,היינן
4100 ,קצרין ,169 ,היקב
4100 ,קצרין ,238 ,הכד
4100 ,קצרין ,160 ,הכרם
4100 ,קצרין ,170 ,המור
4100 ,קצרין ,168 ,המסיק
4100 ,קצרין ,157 ,המעיין
4100 ,קצרין ,237 ,המפל
4100 ,קצרין ,120 ,המשושים
4100 ,קצרין ,236 ,הנהר
4100 ,קצרין ,235 ,הנחל
4100 ,קצרין ,121 ,הערבות
4100 ,קצרין ,173 ,הפלג
4100 ,קצרין ,122 ,הצבעונים
4100 ,קצרין ,234 ,השוקת
4100 ,קצרין ,102 ,השיזף
4100 ,קצרין ,174 ,השמן
4100 ,קצרין ,135 ,ורדית
4100 ,קצרין ,110 ,זויתן
4100 ,קצרין ,167 ,זית
4100 ,קצרין ,134 ,זמירון
4100 ,קצרין ,272 ,חד נס
4100 ,קצרין ,152 ,חופית
4100 ,קצרין ,179 ,חוקוק
4100 ,קצרין ,264 ,חיספין
4100 ,קצרין ,184 ,חמת
4100 ,קצרין ,149 ,חסידה
4100 ,קצרין ,214 ,טופז
4100 ,קצרין ,186 ,טיילת אפק
4100 ,קצרין ,111 ,טיילת גמלא
4100 ,קצרין ,204 ,טיילת חרמון
4100 ,קצרין ,137 ,טיילת נוה
4100 ,קצרין ,178 ,יבנאל
4100 ,קצרין ,185 ,יהודיה
4100 ,קצרין ,274 ,יהלום
4100 ,קצרין ,191 ,יודפת
4100 ,קצרין ,265 ,יונתן
4100 ,קצרין ,300 ,יעלים
4100 ,קצרין ,233 ,יצהר
4100 ,קצרין ,175 ,יראון
4100 ,קצרין ,275 ,ירדן
4100 ,קצרין ,276 ,ירמוך
4100 ,קצרין ,283 ,ירקון
4100 ,קצרין ,216 ,ישפה
4100 ,קצרין ,156 ,כוכבא
4100 ,קצרין ,192 ,כורזין
4100 ,קצרין ,293 ,כזיב
4100 ,קצרין ,6003 ,כיכר גילבון
4100 ,קצרין ,6001 ,כיכר דליות
4100 ,קצרין ,6005 ,כיכר היין
4100 ,קצרין ,6006 ,כיכר המים
4100 ,קצרין ,6004 ,כיכר זוויתן
4100 ,קצרין ,6002 ,כיכר סער
4100 ,קצרין ,6007 ,כיכר קצרין העתיקה
4100 ,קצרין ,6000 ,כיכר שיאון
4100 ,קצרין ,273 ,כנף
4100 ,קצרין ,176 ,כנרות
4100 ,קצרין ,257 ,כפר חרוב
4100 ,קצרין ,171 ,לבונה
4100 ,קצרין ,124 ,ליבנה
4100 ,קצרין ,217 ,לשם
4100 ,קצרין ,247 ,מבוא חמה
4100 ,קצרין ,193 ,מגדל
4100 ,קצרין ,215 ,מיצר
4100 ,קצרין ,194 ,מירון
4100 ,קצרין ,218 ,מרגלית
4100 ,קצרין ,246 ,מרום גולן
4100 ,קצרין ,109 ,משעול אדמון
4100 ,קצרין ,104 ,משעול אודם
4100 ,קצרין ,101 ,משעול אורחה
4100 ,קצרין ,136 ,משעול בארית
4100 ,קצרין ,105 ,משעול בנטל
4100 ,קצרין ,119 ,משעול גבתון
4100 ,קצרין ,117 ,משעול גדרון
4100 ,קצרין ,151 ,משעול חוגלה
4100 ,קצרין ,150 ,משעול חיוואי
4100 ,קצרין ,103 ,משעול חרמונית
4100 ,קצרין ,148 ,משעול יונה
4100 ,קצרין ,147 ,משעול ינשוף
4100 ,קצרין ,146 ,משעול יסעור
4100 ,קצרין ,131 ,משעול נמרון
4100 ,קצרין ,144 ,משעול נקר
4100 ,קצרין ,143 ,משעול נשר
4100 ,קצרין ,141 ,משעול סלעית
4100 ,קצרין ,250 ,נאות גולן
4100 ,קצרין ,258 ,נוב
4100 ,קצרין ,251 ,נווה אטיב
4100 ,קצרין ,145 ,נחליאלי
4100 ,קצרין ,296 ,נחשון
4100 ,קצרין ,266 ,נטור
4100 ,קצרין ,155 ,ניזמית
4100 ,קצרין ,270 ,נמרוד
4100 ,קצרין ,294 ,נעמן
4100 ,קצרין ,195 ,סוסיתא
4100 ,קצרין ,161 ,סמדר
4100 ,קצרין ,290 ,סמך
4100 ,קצרין ,142 ,סנונית
4100 ,קצרין ,200 ,סער
4100 ,קצרין ,219 ,ספיר
4100 ,קצרין ,140 ,עגור
4100 ,קצרין ,248 ,עין זיוון
4100 ,קצרין ,309 ,עינב
4100 ,קצרין ,220 ,עינבר
4100 ,קצרין ,297 ,עמל
4100 ,קצרין ,138 ,עפרוני
4100 ,קצרין ,311 ,ערמונים
4100 ,קצרין ,288 ,פארן
4100 ,קצרין ,295 ,פולג
4100 ,קצרין ,224 ,פנינה
4100 ,קצרין ,126 ,פעמונית
4100 ,קצרין ,310 ,פקאן
4100 ,קצרין ,289 ,פרת
4100 ,קצרין ,299 ,צאלים
4100 ,קצרין ,315 ,צבר
4100 ,קצרין ,196 ,ציפורי
4100 ,קצרין ,279 ,צלמון
4100 ,קצרין ,197 ,צמח
4100 ,קצרין ,292 ,צפית
4100 ,קצרין ,198 ,צפת
4100 ,קצרין ,269 ,קדמת צבי
4100 ,קצרין ,177 ,קדש
4100 ,קצרין ,271 ,קלע אלון
4100 ,קצרין ,9000 ,קצרין
4100 ,קצרין ,259 ,קשת
4100 ,קצרין ,6502 ,רובע אפק
4100 ,קצרין ,6503 ,רובע בתרא
4100 ,קצרין ,6504 ,רובע גמלא
4100 ,קצרין ,6508 ,רובע הבוסתן
4100 ,קצרין ,6506 ,רובע חן
4100 ,קצרין ,6507 ,רובע נחלים
4100 ,קצרין ,6505 ,רובע קדמה
4100 ,קצרין ,245 ,רח 20
4100 ,קצרין ,239 ,רח 14
4100 ,קצרין ,240 ,רח 15
4100 ,קצרין ,241 ,רח 16
4100 ,קצרין ,242 ,רח 17
4100 ,קצרין ,243 ,רח 18
4100 ,קצרין ,244 ,רח 19
4100 ,קצרין ,108 ,רחבת הדודאים
4100 ,קצרין ,182 ,רימון
4100 ,קצרין ,181 ,רמות גלעד
4100 ,קצרין ,252 ,רמת מגשימים
4100 ,קצרין ,221 ,שוהם
4100 ,קצרין ,286 ,שורק
4100 ,קצרין ,223 ,שחרון
4100 ,קצרין ,139 ,שיאון
4100 ,קצרין ,291 ,שיטים
4100 ,קצרין ,180 ,שימרון
4100 ,קצרין ,6500 ,שכ נווה
4100 ,קצרין ,278 ,שניר
4100 ,קצרין ,316 ,שסק
4100 ,קצרין ,267 ,שעל
4100 ,קצרין ,305 ,שקד
4100 ,קצרין ,287 ,שקמה
4100 ,קצרין ,159 ,שריג
4100 ,קצרין ,302 ,תאנה
4100 ,קצרין ,280 ,תבור
4100 ,קצרין ,202 ,תדהר
4100 ,קצרין ,304 ,תמר
4100 ,קצרין ,307 ,תפוח
4100 ,קצרין ,222 ,תרשיש
2620 ,קרית אונו ,169 ,אבן גבירול
2620 ,קרית אונו ,158 ,אבן עזרא
2620 ,קרית אונו ,282 ,אהרונסון אהרון
2620 ,קרית אונו ,122 ,אוסישקין
2620 ,קרית אונו ,208 ,אחאב
2620 ,קרית אונו ,209 ,איריס
2620 ,קרית אונו ,259 ,אלון יגאל
2620 ,קרית אונו ,164 ,אמיל זולה
2620 ,קרית אונו ,314 ,אמיר
2620 ,קרית אונו ,154 ,אנגל
2620 ,קרית אונו ,187 ,אסתר
2620 ,קרית אונו ,252 ,אצ"ל
2620 ,קרית אונו ,125 ,ארלוזורוב
2620 ,קרית אונו ,170 ,ארנון
2620 ,קרית אונו ,210 ,אשכול לוי
2620 ,קרית אונו ,307 ,בגין מנחם
2620 ,קרית אונו ,315 ,בוקצ'ין
2620 ,קרית אונו ,102 ,ביאליק
2620 ,קרית אונו ,118 ,בילינסון
2620 ,קרית אונו ,112 ,בלפור
2620 ,קרית אונו ,128 ,בן יהודה
2620 ,קרית אונו ,202 ,בצלאל גרינברג
2620 ,קרית אונו ,237 ,בר יהודה
2620 ,קרית אונו ,302 ,בר לב חיים
2620 ,קרית אונו ,106 ,ברכה
2620 ,קרית אונו ,107 ,ברנר
2620 ,קרית אונו ,123 ,ברש
2620 ,קרית אונו ,108 ,גולומב
2620 ,קרית אונו ,313 ,גולני
2620 ,קרית אונו ,111 ,גורדון
2620 ,קרית אונו ,6512 ,גני אילן
2620 ,קרית אונו ,121 ,דב הוז
2620 ,קרית אונו ,142 ,דבורה הנביאה
2620 ,קרית אונו ,201 ,דבורה עומר
2620 ,קרית אונו ,155 ,דוד דבורה
2620 ,קרית אונו ,203 ,דוד המלך
2620 ,קרית אונו ,295 ,דרך איתן
2620 ,קרית אונו ,173 ,דרך בר אילן
2620 ,קרית אונו ,301 ,דרך דורי יעקב
2620 ,קרית אונו ,305 ,דרך הגנים
2620 ,קרית אונו ,294 ,האגוז
2620 ,קרית אונו ,273 ,האגס
2620 ,קרית אונו ,293 ,האלון
2620 ,קרית אונו ,277 ,האפרסק
2620 ,קרית אונו ,292 ,הארז
2620 ,קרית אונו ,276 ,האתרוג
2620 ,קרית אונו ,211 ,הבטחון
2620 ,קרית אונו ,250 ,הברוש
2620 ,קרית אונו ,185 ,הגדוד העברי
2620 ,קרית אונו ,152 ,הגפן
2620 ,קרית אונו ,275 ,הדובדבן
2620 ,קרית אונו ,257 ,הדסה
2620 ,קרית אונו ,244 ,הדרור
2620 ,קרית אונו ,254 ,ההגנה
2620 ,קרית אונו ,151 ,ההדר
2620 ,קרית אונו ,280 ,הולצברג שמחה
2620 ,קרית אונו ,212 ,הורד
2620 ,קרית אונו ,157 ,הושע
2620 ,קרית אונו ,213 ,הזית
2620 ,קרית אונו ,245 ,הזמיר
2620 ,קרית אונו ,214 ,החבצלת
2620 ,קרית אונו ,238 ,החשמל
2620 ,קרית אונו ,192 ,הירקון
2620 ,קרית אונו ,215 ,הכלנית
2620 ,קרית אונו ,306 ,הכפר
2620 ,קרית אונו ,284 ,הלימון
2620 ,קרית אונו ,136 ,המגינים
2620 ,קרית אונו ,221 ,המהרי"ץ
2620 ,קרית אונו ,304 ,המייסדים
2620 ,קרית אונו ,216 ,המעגל
2620 ,קרית אונו ,110 ,המעפילים
2620 ,קרית אונו ,145 ,הנביאים
2620 ,קרית אונו ,120 ,הנשיא
2620 ,קרית אונו ,113 ,הס
2620 ,קרית אונו ,188 ,העמל
2620 ,קרית אונו ,137 ,הפלמ"ח
2620 ,קרית אונו ,310 ,הפרדס
2620 ,קרית אונו ,308 ,הצבעוני
2620 ,קרית אונו ,242 ,הצבר
2620 ,קרית אונו ,171 ,הקישון
2620 ,קרית אונו ,217 ,הקשת
2620 ,קרית אונו ,262 ,הר גלבוע
2620 ,קרית אונו ,261 ,הר הכרמל
2620 ,קרית אונו ,263 ,הר חרמון
2620 ,קרית אונו ,260 ,הר תבור
2620 ,קרית אונו ,132 ,הרב ימיני חיים
2620 ,קרית אונו ,134 ,הרב שלום יצחק הלוי
2620 ,קרית אונו ,251 ,הרוגי מלכות בבל
2620 ,קרית אונו ,218 ,הרימון
2620 ,קרית אונו ,303 ,הרצוג חיים
2620 ,קרית אונו ,103 ,הרצל
2620 ,קרית אונו ,234 ,הרצפלד
2620 ,קרית אונו ,163 ,השדרה האקדמית
2620 ,קרית אונו ,279 ,השזיף
2620 ,קרית אונו ,135 ,השלושה
2620 ,קרית אונו ,246 ,השקד
2620 ,קרית אונו ,231 ,השקמה
2620 ,קרית אונו ,219 ,התאנה
2620 ,קרית אונו ,198 ,התזמורת
2620 ,קרית אונו ,220 ,התמר
2620 ,קרית אונו ,278 ,התפוז
2620 ,קרית אונו ,274 ,התפוח
2620 ,קרית אונו ,127 ,וולך
2620 ,קרית אונו ,195 ,וילסון
2620 ,קרית אונו ,116 ,וינגיט
2620 ,קרית אונו ,193 ,ושינגטון
2620 ,קרית אונו ,109 ,ז'בוטינסקי
2620 ,קרית אונו ,247 ,זיידמן
2620 ,קרית אונו ,150 ,חזקיהו
2620 ,קרית אונו ,229 ,חטיבת אלכסנדרוני
2620 ,קרית אונו ,101 ,טרומן
2620 ,קרית אונו ,117 ,טרומפלדור
2620 ,קרית אונו ,126 ,טשרניחובסקי
2620 ,קרית אונו ,149 ,יהואש
2620 ,קרית אונו ,138 ,יהודה הלוי
2620 ,קרית אונו ,166 ,יהודה המכבי
2620 ,קרית אונו ,186 ,יהודית
2620 ,קרית אונו ,318 ,יהושע באום
2620 ,קרית אונו ,199 ,יחזקאל
2620 ,קרית אונו ,189 ,יעל
2620 ,קרית אונו ,317 ,יפה נוף
2620 ,קרית אונו ,230 ,יצחק נבון
2620 ,קרית אונו ,232 ,יצחק שמיר
2620 ,קרית אונו ,148 ,ירושלים
2620 ,קרית אונו ,159 ,ירמוך
2620 ,קרית אונו ,144 ,ירמיהו
2620 ,קרית אונו ,141 ,ישעיהו
2620 ,קרית אונו ,316 ,כ"ץ
2620 ,קרית אונו ,6001 ,ככר האחים גרין
2620 ,קרית אונו ,124 ,כצנלסון
2620 ,קרית אונו ,228 ,לודרדייל לייקס
2620 ,קרית אונו ,500 ,לוחמי האש
2620 ,קרית אונו ,312 ,לוין
2620 ,קרית אונו ,133 ,לוס אנג'לס
2620 ,קרית אונו ,253 ,לח"י
2620 ,קרית אונו ,194 ,לינקולן
2620 ,קרית אונו ,184 ,לכיש
2620 ,קרית אונו ,258 ,לנדאו
2620 ,קרית אונו ,182 ,מגידו
2620 ,קרית אונו ,222 ,מודיעין
2620 ,קרית אונו ,167 ,מונטיפיורי
2620 ,קרית אונו ,181 ,מורדי הגטאות
2620 ,קרית אונו ,160 ,מלצ"ט
2620 ,קרית אונו ,191 ,מעלות נעורים
2620 ,קרית אונו ,172 ,מצדה
2620 ,קרית אונו ,281 ,משה אהרון טוב
2620 ,קרית אונו ,320 ,נהר הירדן
2620 ,קרית אונו ,174 ,נורדאו
2620 ,קרית אונו ,327 ,נחל גמלא
2620 ,קרית אונו ,325 ,נחל דליות
2620 ,קרית אונו ,321 ,נחל דן
2620 ,קרית אונו ,328 ,נחל חרמון
2620 ,קרית אונו ,326 ,נחל משושים
2620 ,קרית אונו ,324 ,נחל סער
2620 ,קרית אונו ,323 ,נחל שיאון
2620 ,קרית אונו ,322 ,נחל שניר
2620 ,קרית אונו ,153 ,נחלת נשלסק
2620 ,קרית אונו ,129 ,סוקולוב
2620 ,קרית אונו ,177 ,סמ בזל
2620 ,קרית אונו ,175 ,סמ דרייפוס
2620 ,קרית אונו ,291 ,סמ האשל
2620 ,קרית אונו ,309 ,סמ העץ הבודד
2620 ,קרית אונו ,176 ,סמ חובבי ציון
2620 ,קרית אונו ,319 ,סמטת קלרי גרינוולד
2620 ,קרית אונו ,256 ,סנה משה
2620 ,קרית אונו ,180 ,סנש
2620 ,קרית אונו ,156 ,סעדיה גאון
2620 ,קרית אונו ,248 ,עגנון
2620 ,קרית אונו ,235 ,עזר ויצמן
2620 ,קרית אונו ,105 ,עזר ומרזוק
2620 ,קרית אונו ,146 ,עמוס
2620 ,קרית אונו ,266 ,עמק איילון
2620 ,קרית אונו ,267 ,עמק האלה
2620 ,קרית אונו ,265 ,עמק הורדים
2620 ,קרית אונו ,268 ,עמק הירדן
2620 ,קרית אונו ,264 ,עמק יזרעאל
2620 ,קרית אונו ,115 ,פנקס
2620 ,קרית אונו ,6518 ,פסגת אונו
2620 ,קרית אונו ,207 ,פקיעין
2620 ,קרית אונו ,6515 ,פרדס רייספלד
2620 ,קרית אונו ,224 ,צדקיהו
2620 ,קרית אונו ,225 ,צה"ל
2620 ,קרית אונו ,236 ,קורצ'ק
2620 ,קרית אונו ,114 ,קפלן
2620 ,קרית אונו ,9000 ,קרית אונו
2620 ,קרית אונו ,290 ,רבין יצחק
2620 ,קרית אונו ,161 ,רוטשילד
2620 ,קרית אונו ,130 ,רופין
2620 ,קרית אונו ,311 ,רח 10
2620 ,קרית אונו ,200 ,רחבת בני ברית
2620 ,קרית אונו ,240 ,רחבת דראכטן
2620 ,קרית אונו ,243 ,רחבת האורן
2620 ,קרית אונו ,271 ,רייק חביבה
2620 ,קרית אונו ,140 ,רמב"ן
2620 ,קרית אונו ,226 ,רמה
2620 ,קרית אונו ,131 ,רמז
2620 ,קרית אונו ,227 ,רקפת
2620 ,קרית אונו ,139 ,רש"י
2620 ,קרית אונו ,6501 ,ש אנגלו סקסי
2620 ,קרית אונו ,6504 ,ש חברת חשמל
2620 ,קרית אונו ,6505 ,ש ירון
2620 ,קרית אונו ,6507 ,ש עובדים
2620 ,קרית אונו ,6508 ,ש קיראון
2620 ,קרית אונו ,6509 ,ש רימון
2620 ,קרית אונו ,6510 ,ש רסקו
2620 ,קרית אונו ,147 ,שאול המלך
2620 ,קרית אונו ,196 ,שבזי
2620 ,קרית אונו ,249 ,שד בן גוריון
2620 ,קרית אונו ,223 ,שד סולד הנריטה
2620 ,קרית אונו ,283 ,שד קציר אהרון
2620 ,קרית אונו ,104 ,שד קרן קימת
2620 ,קרית אונו ,119 ,שדה יצחק
2620 ,קרית אונו ,272 ,שוחט מניה
2620 ,קרית אונו ,233 ,שולמית אלוני
2620 ,קרית אונו ,241 ,שטרן יאיר
2620 ,קרית אונו ,6511 ,שכ גרין
2620 ,קרית אונו ,6516 ,שכ עבר הירדן
2620 ,קרית אונו ,6517 ,שכ שער הקריה
2620 ,קרית אונו ,168 ,שלמה המלך
2620 ,קרית אונו ,143 ,שמואל הנביא
2620 ,קרית אונו ,183 ,תל חי
2620 ,קרית אונו ,255 ,תנועת המרי
3611 ,קרית ארבע ,6502 ,אזור התעשייה
3611 ,קרית ארבע ,115 ,ארץ חמדה
3611 ,קרית ארבע ,6501 ,אשמורת יצחק
3611 ,קרית ארבע ,110 ,בן יפונה
3611 ,קרית ארבע ,117 ,בתי אפרים
3611 ,קרית ארבע ,111 ,גבעת האבות
3611 ,קרית ארבע ,106 ,גור אריה
3611 ,קרית ארבע ,107 ,דוד המלך
3611 ,קרית ארבע ,102 ,הרב צבי יהודה
3611 ,קרית ארבע ,114 ,טבנקין
3611 ,קרית ארבע ,116 ,יאיר
3611 ,קרית ארבע ,105 ,יהושע בן נון
3611 ,קרית ארבע ,103 ,יוני נתניהו
3611 ,קרית ארבע ,121 ,ישיבה תיכונית
3611 ,קרית ארבע ,120 ,מכללת יהודה
3611 ,קרית ארבע ,108 ,מלון אשכולות
3611 ,קרית ארבע ,122 ,מעלות חברון
3611 ,קרית ארבע ,6503 ,נופי ממרא
3611 ,קרית ארבע ,109 ,נחל אשכול
3611 ,קרית ארבע ,119 ,סולם אלדד
3611 ,קרית ארבע ,104 ,עמק חברון
3611 ,קרית ארבע ,9000 ,קרית ארבע
3611 ,קרית ארבע ,113 ,רא"ם
3611 ,קרית ארבע ,101 ,רמת ממרא
3611 ,קרית ארבע ,112 ,שד החלוצים
3611 ,קרית ארבע ,6500 ,שכ הקרוונים
6800 ,קרית אתא ,326 ,אבן גבירול
6800 ,קרית אתא ,328 ,אבן עזרא
6800 ,קרית אתא ,187 ,אגמית
6800 ,קרית אתא ,353 ,אורנים
6800 ,קרית אתא ,6548 ,אזור התעשיה
6800 ,קרית אתא ,336 ,אחד העם
6800 ,קרית אתא ,225 ,אינשטין
6800 ,קרית אתא ,206 ,אלבז
6800 ,קרית אתא ,302 ,אליהו הנביא
6800 ,קרית אתא ,144 ,אלמוגן
6800 ,קרית אתא ,664 ,אלשייך
6800 ,קרית אתא ,108 ,אנילביץ
6800 ,קרית אתא ,137 ,אנפה
6800 ,קרית אתא ,237 ,ארלוזורוב
6800 ,קרית אתא ,694 ,אשכנזי
6800 ,קרית אתא ,285 ,אתא
6800 ,קרית אתא ,167 ,בונר
6800 ,קרית אתא ,1001 ,בי"ס ארצי לשוטרים
6800 ,קרית אתא ,337 ,ביאליק
6800 ,קרית אתא ,651 ,ביל"ו
6800 ,קרית אתא ,352 ,בית לחם
6800 ,קרית אתא ,115 ,בית"ר
6800 ,קרית אתא ,633 ,בכורים
6800 ,קרית אתא ,339 ,בן יהודה
6800 ,קרית אתא ,207 ,בן יוסף אהרון
6800 ,קרית אתא ,644 ,בן יוסף שלמה
6800 ,קרית אתא ,105 ,בן עמי
6800 ,קרית אתא ,691 ,בן צבי יצחק
6800 ,קרית אתא ,343 ,בן ציון
6800 ,קרית אתא ,309 ,בעל שם טוב
6800 ,קרית אתא ,629 ,בצרי יוסף
6800 ,קרית אתא ,672 ,בר אילן
6800 ,קרית אתא ,665 ,בר יהודה
6800 ,קרית אתא ,661 ,בר יוחאי
6800 ,קרית אתא ,106 ,בר כוכבא
6800 ,קרית אתא ,488 ,ברבור
6800 ,קרית אתא ,334 ,ברדיצ'בסקי
6800 ,קרית אתא ,636 ,ברנדייס
6800 ,קרית אתא ,235 ,ברנר
6800 ,קרית אתא ,6501 ,בתי מפעל
6800 ,קרית אתא ,176 ,ג'קומטי אלברטו
6800 ,קרית אתא ,681 ,גבורי הקריה
6800 ,קרית אתא ,6534 ,גבעה א'
6800 ,קרית אתא ,6549 ,גבעת הרקפות
6800 ,קרית אתא ,6507 ,גבעת טל
6800 ,קרית אתא ,6550 ,גבעת רם
6800 ,קרית אתא ,162 ,גוגן
6800 ,קרית אתא ,169 ,גויה
6800 ,קרית אתא ,234 ,גולומב
6800 ,קרית אתא ,112 ,גוש עציון
6800 ,קרית אתא ,350 ,גלבוע
6800 ,קרית אתא ,174 ,דאלי
6800 ,קרית אתא ,179 ,דגה
6800 ,קרית אתא ,134 ,דוכיפת
6800 ,קרית אתא ,148 ,דפנה
6800 ,קרית אתא ,287 ,דקר
6800 ,קרית אתא ,312 ,דרך אושה
6800 ,קרית אתא ,624 ,דרך דשנים
6800 ,קרית אתא ,667 ,דרך חיפה
6800 ,קרית אתא ,156 ,דרך יגור
6800 ,קרית אתא ,256 ,דרך יצחק רבין
6800 ,קרית אתא ,322 ,דרך כפר חסידים
6800 ,קרית אתא ,155 ,דרך מנחם בגין
6800 ,קרית אתא ,221 ,דרך שפרעם
6800 ,קרית אתא ,141 ,האורן
6800 ,קרית אתא ,152 ,האלה
6800 ,קרית אתא ,356 ,האלון
6800 ,קרית אתא ,634 ,האסיף
6800 ,קרית אתא ,151 ,הארז
6800 ,קרית אתא ,107 ,הארי השואג
6800 ,קרית אתא ,143 ,האשל
6800 ,קרית אתא ,351 ,הברוש
6800 ,קרית אתא ,635 ,הגבורה
6800 ,קרית אתא ,632 ,הגורן
6800 ,קרית אתא ,242 ,הגליל
6800 ,קרית אתא ,304 ,הגפן
6800 ,קרית אתא ,142 ,הדס
6800 ,קרית אתא ,320 ,הדקלים
6800 ,קרית אתא ,246 ,ההגנה
6800 ,קרית אתא ,123 ,הושע
6800 ,קרית אתא ,650 ,הותיקים
6800 ,קרית אתא ,306 ,הזית
6800 ,קרית אתא ,154 ,החרוב
6800 ,קרית אתא ,316 ,היערה
6800 ,קרית אתא ,666 ,הירדן
6800 ,קרית אתא ,675 ,הירקון
6800 ,קרית אתא ,676 ,הכלנית
6800 ,קרית אתא ,315 ,הלל
6800 ,קרית אתא ,121 ,המ"ג
6800 ,קרית אתא ,330 ,המגינים
6800 ,קרית אתא ,359 ,המחרשה
6800 ,קרית אתא ,314 ,המיסדים
6800 ,קרית אתא ,120 ,המכבים
6800 ,קרית אתא ,276 ,המלאכה
6800 ,קרית אתא ,671 ,המעפילים
6800 ,קרית אתא ,301 ,הנביאים
6800 ,קרית אתא ,272 ,הנגב
6800 ,קרית אתא ,161 ,הנרי מור
6800 ,קרית אתא ,677 ,הנרקיסים
6800 ,קרית אתא ,233 ,הס
6800 ,קרית אתא ,273 ,העמק
6800 ,קרית אתא ,220 ,העצמאות
6800 ,קרית אתא ,355 ,הערמונים
6800 ,קרית אתא ,230 ,הצנחנים
6800 ,קרית אתא ,358 ,הקורנית
6800 ,קרית אתא ,682 ,הקישון
6800 ,קרית אתא ,241 ,הרב דוידאשווילי
6800 ,קרית אתא ,209 ,הרב עוזיאל
6800 ,קרית אתא ,653 ,הרב קוק
6800 ,קרית אתא ,147 ,הרדוף
6800 ,קרית אתא ,130 ,הרי גולן
6800 ,קרית אתא ,117 ,הרצוג
6800 ,קרית אתא ,229 ,הרצל
6800 ,קרית אתא ,145 ,הרר צבי
6800 ,קרית אתא ,122 ,השבעה
6800 ,קרית אתא ,311 ,השומר
6800 ,קרית אתא ,308 ,השופטים
6800 ,קרית אתא ,284 ,השוק
6800 ,קרית אתא ,357 ,השיטה
6800 ,קרית אתא ,331 ,השקדים
6800 ,קרית אתא ,153 ,השקמה
6800 ,קרית אתא ,307 ,התאנים
6800 ,קרית אתא ,243 ,התבור
6800 ,קרית אתא ,329 ,התמרים
6800 ,קרית אתא ,288 ,התנאים
6800 ,קרית אתא ,345 ,התעלה
6800 ,קרית אתא ,669 ,התעשיה
6800 ,קרית אתא ,166 ,ואן גוך
6800 ,קרית אתא ,185 ,וולסקיז
6800 ,קרית אתא ,215 ,וינגייט
6800 ,קרית אתא ,216 ,ויצמן
6800 ,קרית אתא ,347 ,ורד
6800 ,קרית אתא ,110 ,ז'בוטינסקי
6800 ,קרית אתא ,323 ,זבולון
6800 ,קרית אתא ,263 ,זכריה
6800 ,קרית אתא ,157 ,זמיר
6800 ,קרית אתא ,217 ,זמנהוף
6800 ,קרית אתא ,678 ,חבצלת
6800 ,קרית אתא ,260 ,חבקוק
6800 ,קרית אתא ,683 ,חברון
6800 ,קרית אתא ,262 ,חגי
6800 ,קרית אתא ,186 ,חוחית
6800 ,קרית אתא ,140 ,חופית
6800 ,קרית אתא ,219 ,חנקין
6800 ,קרית אתא ,332 ,חפץ חיים
6800 ,קרית אתא ,201 ,טביב אברהם
6800 ,קרית אתא ,245 ,טרומפלדור
6800 ,קרית אתא ,643 ,טשרניחובסקי
6800 ,קרית אתא ,114 ,יא באדר
6800 ,קרית אתא ,279 ,יבניאלי שמואל
6800 ,קרית אתא ,327 ,יהודה הלוי
6800 ,קרית אתא ,317 ,יהודה הנשיא
6800 ,קרית אתא ,212 ,יהושע בן נון
6800 ,קרית אתא ,267 ,יואב
6800 ,קרית אתא ,124 ,יואל
6800 ,קרית אתא ,127 ,יונה
6800 ,קרית אתא ,254 ,יוספטל
6800 ,קרית אתא ,637 ,יל"ג
6800 ,קרית אתא ,150 ,יסמין
6800 ,קרית אתא ,188 ,יסעור
6800 ,קרית אתא ,102 ,יצחק שדה
6800 ,קרית אתא ,313 ,ירושלים
6800 ,קרית אתא ,318 ,ירמיהו
6800 ,קרית אתא ,265 ,ישעיהו
6800 ,קרית אתא ,628 ,כביש ראשי
6800 ,קרית אתא ,129 ,כהן אלי
6800 ,קרית אתא ,645 ,כהן יעקב
6800 ,קרית אתא ,6001 ,ככר בני ברית
6800 ,קרית אתא ,6002 ,ככר העיריה
6800 ,קרית אתא ,6003 ,ככר הפרחים
6800 ,קרית אתא ,321 ,כנרת
6800 ,קרית אתא ,172 ,לוטרק
6800 ,קרית אתא ,295 ,לוי יפה
6800 ,קרית אתא ,173 ,לז'ה
6800 ,קרית אתא ,640 ,לילינבלום
6800 ,קרית אתא ,289 ,מאיה
6800 ,קרית אתא ,181 ,מאנה
6800 ,קרית אתא ,333 ,מאפו
6800 ,קרית אתא ,111 ,מבצע סיני
6800 ,קרית אתא ,191 ,מגלן
6800 ,קרית אתא ,182 ,מגריט
6800 ,קרית אתא ,170 ,מודיליאני
6800 ,קרית אתא ,222 ,מולר הוגו
6800 ,קרית אתא ,175 ,מונדריאן
6800 ,קרית אתא ,354 ,מוצקין
6800 ,קרית אתא ,324 ,מורדי הגטאות
6800 ,קרית אתא ,146 ,מורן
6800 ,קרית אתא ,168 ,מטיס
6800 ,קרית אתא ,128 ,מיכה
6800 ,קרית אתא ,116 ,מימון
6800 ,קרית אתא ,118 ,מינץ
6800 ,קרית אתא ,178 ,מירו
6800 ,קרית אתא ,684 ,מירון
6800 ,קרית אתא ,264 ,מלאכי
6800 ,קרית אתא ,340 ,מנדלי מוכר ספרים
6800 ,קרית אתא ,689 ,מנלה גד
6800 ,קרית אתא ,6502 ,מע ק נחום
6800 ,קרית אתא ,271 ,מעלה החרמון
6800 ,קרית אתא ,348 ,מקור ברוך
6800 ,קרית אתא ,202 ,מרזוק
6800 ,קרית אתא ,6503 ,מרכז העיר
6800 ,קרית אתא ,252 ,מרכז חדש
6800 ,קרית אתא ,113 ,משאש יוסף
6800 ,קרית אתא ,6551 ,נווה אברהם
6800 ,קרית אתא ,305 ,נוף הכרמל
6800 ,קרית אתא ,228 ,נורדאו
6800 ,קרית אתא ,259 ,נחום
6800 ,קרית אתא ,674 ,נחל נעמן
6800 ,קרית אתא ,692 ,נחל קדרון
6800 ,קרית אתא ,226 ,נחליאלי
6800 ,קרית אתא ,231 ,ניל"י
6800 ,קרית אתא ,138 ,נץ
6800 ,קרית אתא ,194 ,נקר
6800 ,קרית אתא ,208 ,נתיב
6800 ,קרית אתא ,325 ,נתיב הדרור
6800 ,קרית אתא ,281 ,נתיב השומרון
6800 ,קרית אתא ,213 ,סולד הנריטה
6800 ,קרית אתא ,223 ,סוקולוב
6800 ,קרית אתא ,165 ,סזאן
6800 ,קרית אתא ,668 ,סטמפל משה
6800 ,קרית אתא ,192 ,סייפן
6800 ,קרית אתא ,236 ,סירקין
6800 ,קרית אתא ,349 ,סלח שלום
6800 ,קרית אתא ,136 ,סלעית
6800 ,קרית אתא ,282 ,סמ אביב
6800 ,קרית אתא ,270 ,סמ אילון
6800 ,קרית אתא ,296 ,סמ אתא
6800 ,קרית אתא ,171 ,סמ ג'ורג' בראק
6800 ,קרית אתא ,119 ,סמ הגלעדי
6800 ,קרית אתא ,291 ,סמ הערבה
6800 ,קרית אתא ,292 ,סמ הפקאן
6800 ,קרית אתא ,293 ,סמ השחר
6800 ,קרית אתא ,266 ,סמ יאיר
6800 ,קרית אתא ,294 ,סמ עין התכלת
6800 ,קרית אתא ,344 ,סמולנסקין
6800 ,קרית אתא ,189 ,סנונית
6800 ,קרית אתא ,205 ,סנש חנה
6800 ,קרית אתא ,177 ,סרה
6800 ,קרית אתא ,342 ,עבודה
6800 ,קרית אתא ,197 ,עגור
6800 ,קרית אתא ,319 ,עגנון ש"י
6800 ,קרית אתא ,126 ,עובדיה
6800 ,קרית אתא ,204 ,עזאר שמואל
6800 ,קרית אתא ,139 ,עייט
6800 ,קרית אתא ,627 ,עיר גנים
6800 ,קרית אתא ,125 ,עמוס
6800 ,קרית אתא ,159 ,עפרוני
6800 ,קרית אתא ,274 ,עצמון
6800 ,קרית אתא ,280 ,עשת אביגדור
6800 ,קרית אתא ,210 ,פולד ברכה
6800 ,קרית אתא ,224 ,פינסקר
6800 ,קרית אתא ,164 ,פיקסו
6800 ,קרית אתא ,109 ,פלמ"ח
6800 ,קרית אתא ,335 ,פרישמן
6800 ,קרית אתא ,338 ,פרץ
6800 ,קרית אתא ,196 ,פשוש
6800 ,קרית אתא ,659 ,צאלח יחיא
6800 ,קרית אתא ,693 ,צדוק אברהם
6800 ,קרית אתא ,158 ,צופית
6800 ,קרית אתא ,218 ,צוצלת
6800 ,קרית אתא ,190 ,צוקית
6800 ,קרית אתא ,277 ,צפורי
6800 ,קרית אתא ,261 ,צפניה
6800 ,קרית אתא ,685 ,צפת
6800 ,קרית אתא ,660 ,קארו יוסף
6800 ,קרית אתא ,642 ,קבוץ גלויות
6800 ,קרית אתא ,195 ,קיכלי
6800 ,קרית אתא ,104 ,קיש
6800 ,קרית אתא ,686 ,קלאוזנר
6800 ,קרית אתא ,184 ,קלה
6800 ,קרית אתא ,654 ,קלישר
6800 ,קרית אתא ,183 ,קנדינסקי
6800 ,קרית אתא ,9000 ,קרית אתא
6800 ,קרית אתא ,6504 ,קרית בנימין
6800 ,קרית אתא ,673 ,קרן היסוד
6800 ,קרית אתא ,663 ,קרן קימת לישראל
6800 ,קרית אתא ,286 ,ראשונים
6800 ,קרית אתא ,131 ,רגב אריה
6800 ,קרית אתא ,180 ,רודן
6800 ,קרית אתא ,227 ,רופין
6800 ,קרית אתא ,149 ,רותם
6800 ,קרית אתא ,641 ,רזיאל דוד
6800 ,קרית אתא ,655 ,ריינס
6800 ,קרית אתא ,657 ,רמב"ם
6800 ,קרית אתא ,240 ,רמז
6800 ,קרית אתא ,163 ,רנואר
6800 ,קרית אתא ,275 ,רקפת
6800 ,קרית אתא ,679 ,רש"י
6800 ,קרית אתא ,6505 ,ש אגש א
6800 ,קרית אתא ,6506 ,ש אגש ב
6800 ,קרית אתא ,6508 ,ש אגש ק בנימין
6800 ,קרית אתא ,6509 ,ש אזבסט גב ב
6800 ,קרית אתא ,6510 ,ש אקדמאים
6800 ,קרית אתא ,6511 ,ש ארע ק נחום
6800 ,קרית אתא ,6512 ,ש ארעי א
6800 ,קרית אתא ,6513 ,ש ארעי ב
6800 ,קרית אתא ,6514 ,ש ארעי ג
6800 ,קרית אתא ,6515 ,ש ארעי ד
6800 ,קרית אתא ,6516 ,ש בולגרים
6800 ,קרית אתא ,6517 ,ש בלוקונים
6800 ,קרית אתא ,6518 ,ש בן גוריון
6800 ,קרית אתא ,6520 ,ש הפוהמז
6800 ,קרית אתא ,6522 ,ש חדש
6800 ,קרית אתא ,6523 ,ש חילים ק נחום
6800 ,קרית אתא ,6524 ,ש חסכון
6800 ,קרית אתא ,6528 ,ש מועצה
6800 ,קרית אתא ,6529 ,ש מרמרוש
6800 ,קרית אתא ,6530 ,ש משכנות פרוסטיג
6800 ,קרית אתא ,6531 ,ש נוה חן
6800 ,קרית אתא ,6532 ,ש עולה חדש
6800 ,קרית אתא ,6535 ,ש עמידר א
6800 ,קרית אתא ,6536 ,ש עמידר ב
6800 ,קרית אתא ,6537 ,ש עמידר ג
6800 ,קרית אתא ,6538 ,ש עמידר ק פרוסטיג
6800 ,קרית אתא ,6539 ,ש עממי ק בנימין
6800 ,קרית אתא ,6540 ,ש פאי
6800 ,קרית אתא ,160 ,שאגל
6800 ,קרית אתא ,656 ,שבזי
6800 ,קרית אתא ,346 ,שבטי ישראל
6800 ,קרית אתא ,232 ,שד בורוכוב
6800 ,קרית אתא ,290 ,שד בית וגן
6800 ,קרית אתא ,239 ,שד הציונות
6800 ,קרית אתא ,310 ,שד כצנלסון
6800 ,קרית אתא ,283 ,שדה בוקר
6800 ,קרית אתא ,646 ,שושנת העמקים
6800 ,קרית אתא ,278 ,שחל
6800 ,קרית אתא ,238 ,שחף
6800 ,קרית אתא ,211 ,שחרור
6800 ,קרית אתא ,687 ,שטיינברג
6800 ,קרית אתא ,662 ,שטנד
6800 ,קרית אתא ,649 ,שיבת ציון
6800 ,קרית אתא ,6552 ,שכ אברמסקי
6800 ,קרית אתא ,6521 ,שכ בית וגן
6800 ,קרית אתא ,6553 ,שכ גבעת אלונים
6800 ,קרית אתא ,6542 ,שכ התימנים
6800 ,קרית אתא ,6519 ,שכ צמרת אלונים
6800 ,קרית אתא ,6544 ,שכ ק בנימין
6800 ,קרית אתא ,6545 ,שכ ק פרוסטיג
6800 ,קרית אתא ,6546 ,שכ ק שטנד
6800 ,קרית אתא ,6547 ,שכ קרית נחום
6800 ,קרית אתא ,6543 ,שכ תל חי
6800 ,קרית אתא ,135 ,שלדג
6800 ,קרית אתא ,341 ,שלום עליכם
6800 ,קרית אתא ,193 ,שליו
6800 ,קרית אתא ,214 ,שמחוני אסף
6800 ,קרית אתא ,688 ,שניאור זלמן
6800 ,קרית אתא ,680 ,שפרינצק
6800 ,קרית אתא ,103 ,שפריצר נתן
6800 ,קרית אתא ,200 ,שרקרק
6800 ,קרית אתא ,690 ,שרת משה
6800 ,קרית אתא ,199 ,תור
6800 ,קרית אתא ,133 ,תל חי
9500 ,קרית ביאליק ,230 ,אגוז
9500 ,קרית ביאליק ,146 ,אהובה עוזרי
9500 ,קרית ביאליק ,281 ,אהוד מנור
9500 ,קרית ביאליק ,151 ,אושה
9500 ,קרית ביאליק ,6506 ,אזור התעשיה
9500 ,קרית ביאליק ,199 ,אחד העם
9500 ,קרית ביאליק ,129 ,אילון
9500 ,קרית ביאליק ,240 ,אירוס
9500 ,קרית ביאליק ,133 ,אפרים
9500 ,קרית ביאליק ,261 ,אפרסמון
9500 ,קרית ביאליק ,224 ,אצ"ל
9500 ,קרית ביאליק ,241 ,אקליפטוס
9500 ,קרית ביאליק ,288 ,אריה אליאס
9500 ,קרית ביאליק ,285 ,אריק איינשטיין
9500 ,קרית ביאליק ,125 ,אשל
9500 ,קרית ביאליק ,206 ,אשר
9500 ,קרית ביאליק ,202 ,בורלא
9500 ,קרית ביאליק ,122 ,בנימין
9500 ,קרית ביאליק ,215 ,בעלי המלאכה
9500 ,קרית ביאליק ,201 ,ברנר
9500 ,קרית ביאליק ,6512 ,גבעת הרקפות
9500 ,קרית ביאליק ,211 ,גד
9500 ,קרית ביאליק ,225 ,גולן
9500 ,קרית ביאליק ,106 ,גוש חלב
9500 ,קרית ביאליק ,163 ,גלבוע
9500 ,קרית ביאליק ,286 ,דבורה עומר
9500 ,קרית ביאליק ,148 ,דגניה
9500 ,קרית ביאליק ,103 ,דגניות
9500 ,קרית ביאליק ,263 ,דובדבן
9500 ,קרית ביאליק ,228 ,דוכיפת
9500 ,קרית ביאליק ,193 ,דליה
9500 ,קרית ביאליק ,124 ,דן
9500 ,קרית ביאליק ,128 ,דפנה
9500 ,קרית ביאליק ,229 ,דרור
9500 ,קרית ביאליק ,277 ,דרך בגין
9500 ,קרית ביאליק ,296 ,דרך האמנים
9500 ,קרית ביאליק ,119 ,דרך עכו
9500 ,קרית ביאליק ,137 ,האורנים
9500 ,קרית ביאליק ,182 ,האלונים
9500 ,קרית ביאליק ,204 ,הארזים
9500 ,קרית ביאליק ,131 ,הברושים
9500 ,קרית ביאליק ,159 ,הגליל
9500 ,קרית ביאליק ,188 ,הגנים
9500 ,קרית ביאליק ,126 ,הגפן
9500 ,קרית ביאליק ,132 ,הדקלים
9500 ,קרית ביאליק ,160 ,ההגנה
9500 ,קרית ביאליק ,140 ,הזית
9500 ,קרית ביאליק ,150 ,החקלאים
9500 ,קרית ביאליק ,218 ,היוזם
9500 ,קרית ביאליק ,219 ,היוצר
9500 ,קרית ביאליק ,216 ,המסגר
9500 ,קרית ביאליק ,154 ,המעפיל
9500 ,קרית ביאליק ,136 ,הנרקיסים
9500 ,קרית ביאליק ,220 ,הסדנה
9500 ,קרית ביאליק ,179 ,העליה
9500 ,קרית ביאליק ,156 ,העמקים
9500 ,קרית ביאליק ,114 ,העצמאות
9500 ,קרית ביאליק ,212 ,הערמונים
9500 ,קרית ביאליק ,120 ,הפלמ"ח
9500 ,קרית ביאליק ,243 ,הפקאן
9500 ,קרית ביאליק ,177 ,הפרטיזנים
9500 ,קרית ביאליק ,235 ,הקריון
9500 ,קרית ביאליק ,247 ,הרב חנניה יום טוב
9500 ,קרית ביאליק ,168 ,הרב מימון
9500 ,קרית ביאליק ,231 ,השומר
9500 ,קרית ביאליק ,205 ,השקדים
9500 ,קרית ביאליק ,130 ,השקמה
9500 ,קרית ביאליק ,207 ,התאנים
9500 ,קרית ביאליק ,101 ,התל
9500 ,קרית ביאליק ,189 ,התמרים
9500 ,קרית ביאליק ,138 ,ורד
9500 ,קרית ביאליק ,181 ,ז'בוטינסקי
9500 ,קרית ביאליק ,123 ,זבולון
9500 ,קרית ביאליק ,142 ,זינגר
9500 ,קרית ביאליק ,110 ,חביבה
9500 ,קרית ביאליק ,139 ,חבצלת
9500 ,קרית ביאליק ,239 ,חוגלה
9500 ,קרית ביאליק ,173 ,חיים וייסבורג
9500 ,קרית ביאליק ,274 ,חיננית
9500 ,קרית ביאליק ,108 ,חנה
9500 ,קרית ביאליק ,134 ,חניתה
9500 ,קרית ביאליק ,175 ,חצב
9500 ,קרית ביאליק ,167 ,חרובים
9500 ,קרית ביאליק ,217 ,חרושת
9500 ,קרית ביאליק ,161 ,חרמון
9500 ,קרית ביאליק ,242 ,חרצית
9500 ,קרית ביאליק ,283 ,יגאל בשן
9500 ,קרית ביאליק ,152 ,יגור
9500 ,קרית ביאליק ,121 ,יהודה
9500 ,קרית ביאליק ,209 ,יהונתן
9500 ,קרית ביאליק ,109 ,יוסף
9500 ,קרית ביאליק ,180 ,יוספטל גיורא
9500 ,קרית ביאליק ,135 ,יחיעם
9500 ,קרית ביאליק ,273 ,יסמין
9500 ,קרית ביאליק ,290 ,יפה ירקוני
9500 ,קרית ביאליק ,246 ,יצחק שמיר
9500 ,קרית ביאליק ,270 ,יקינתון
9500 ,קרית ביאליק ,292 ,ישראל פוליאקוב
9500 ,קרית ביאליק ,6001 ,ככר בובר
9500 ,קרית ביאליק ,6002 ,ככר ברגר ביאטה
9500 ,קרית ביאליק ,6004 ,ככר הפרחים
9500 ,קרית ביאליק ,6005 ,ככר יד לבנים
9500 ,קרית ביאליק ,6003 ,ככר רופין
9500 ,קרית ביאליק ,112 ,כלניות
9500 ,קרית ביאליק ,162 ,כנען
9500 ,קרית ביאליק ,191 ,כנרת
9500 ,קרית ביאליק ,186 ,כרמל
9500 ,קרית ביאליק ,178 ,לוחמי הגיטאות
9500 ,קרית ביאליק ,275 ,לוטם
9500 ,קרית ביאליק ,190 ,לוי יוסף
9500 ,קרית ביאליק ,236 ,לח"י
9500 ,קרית ביאליק ,276 ,לילך
9500 ,קרית ביאליק ,144 ,לנדאור
9500 ,קרית ביאליק ,221 ,מולר הנס
9500 ,קרית ביאליק ,195 ,מנשה
9500 ,קרית ביאליק ,6006 ,מצילי יהודי הולנד
9500 ,קרית ביאליק ,272 ,מרגנית
9500 ,קרית ביאליק ,6510 ,נאות גזית
9500 ,קרית ביאליק ,237 ,נורית
9500 ,קרית ביאליק ,287 ,נחום היימן
9500 ,קרית ביאליק ,227 ,נחליאלי
9500 ,קרית ביאליק ,238 ,ניל"י
9500 ,קרית ביאליק ,184 ,ניצנים
9500 ,קרית ביאליק ,185 ,ניר עם
9500 ,קרית ביאליק ,279 ,נעמי שמר
9500 ,קרית ביאליק ,117 ,נפתלי
9500 ,קרית ביאליק ,291 ,נתן אלתרמן
9500 ,קרית ביאליק ,143 ,סולד
9500 ,קרית ביאליק ,174 ,סחלב
9500 ,קרית ביאליק ,170 ,סיגליות
9500 ,קרית ביאליק ,271 ,סיתוונית
9500 ,קרית ביאליק ,294 ,סמ גבי שושן
9500 ,קרית ביאליק ,171 ,סמ הצאלים
9500 ,קרית ביאליק ,295 ,סמ חנה מרון
9500 ,קרית ביאליק ,293 ,סמ שייקה אופיר
9500 ,קרית ביאליק ,226 ,סנונית
9500 ,קרית ביאליק ,284 ,ספי ריבלין
9500 ,קרית ביאליק ,196 ,עגנון ש"י
9500 ,קרית ביאליק ,280 ,עוזי חיטמן
9500 ,קרית ביאליק ,289 ,עופרה חזה
9500 ,קרית ביאליק ,223 ,עפרוני
9500 ,קרית ביאליק ,187 ,עצמון
9500 ,קרית ביאליק ,262 ,פטל
9500 ,קרית ביאליק ,166 ,פרישמן
9500 ,קרית ביאליק ,198 ,פרץ
9500 ,קרית ביאליק ,176 ,צבעוני
9500 ,קרית ביאליק ,104 ,צברים
9500 ,קרית ביאליק ,214 ,צופית
9500 ,קרית ביאליק ,6505 ,צור שלום
9500 ,קרית ביאליק ,155 ,ציפורי
9500 ,קרית ביאליק ,105 ,ציפורן
9500 ,קרית ביאליק ,153 ,קישון
9500 ,קרית ביאליק ,9000 ,קרית ביאליק
9500 ,קרית ביאליק ,6500 ,קרית שמריהו
9500 ,קרית ביאליק ,6511 ,קרית שמריהו מזרח
9500 ,קרית ביאליק ,115 ,קרן היסוד
9500 ,קרית ביאליק ,147 ,קרן קיימת לישראל
9500 ,קרית ביאליק ,118 ,ראובן
9500 ,קרית ביאליק ,210 ,רחל
9500 ,קרית ביאליק ,141 ,רימון
9500 ,קרית ביאליק ,113 ,רקפות
9500 ,קרית ביאליק ,6501 ,ש חסכון
9500 ,קרית ביאליק ,6502 ,ש עמידר
9500 ,קרית ביאליק ,116 ,שבטי ישראל
9500 ,קרית ביאליק ,192 ,שביל הנרקיסים
9500 ,קרית ביאליק ,278 ,שביל הפרדס
9500 ,קרית ביאליק ,127 ,שד בן גוריון
9500 ,קרית ביאליק ,232 ,שד הבנים
9500 ,קרית ביאליק ,233 ,שד המייסדים
9500 ,קרית ביאליק ,107 ,שד הרצל
9500 ,קרית ביאליק ,149 ,שד ויצמן
9500 ,קרית ביאליק ,208 ,שד ח"ן
9500 ,קרית ביאליק ,145 ,שד ירושלים
9500 ,קרית ביאליק ,245 ,שד רבין
9500 ,קרית ביאליק ,282 ,שושנה דמארי
9500 ,קרית ביאליק ,111 ,שושנים
9500 ,קרית ביאליק ,6514 ,שיכון רסקו
9500 ,קרית ביאליק ,6503 ,שכ אפק
9500 ,קרית ביאליק ,6513 ,שכ הפרפר
9500 ,קרית ביאליק ,6504 ,שכ סביניה
9500 ,קרית ביאליק ,200 ,שלום עליכם
9500 ,קרית ביאליק ,194 ,שמעון
9500 ,קרית ביאליק ,158 ,שפרעם
9500 ,קרית ביאליק ,157 ,תבור
9500 ,קרית ביאליק ,260 ,תות
9500 ,קרית ביאליק ,102 ,תמר
2630 ,קרית גת ,569 ,אביגיל הכרמלית
2630 ,קרית גת ,411 ,אבץ
2630 ,קרית גת ,330 ,אדוריים
2630 ,קרית גת ,401 ,אדר
2630 ,קרית גת ,276 ,אהוד בן גרא
2630 ,קרית גת ,412 ,אודם
2630 ,קרית גת ,501 ,אורון
2630 ,קרית גת ,6511 ,אזור המלאכה
2630 ,קרית גת ,585 ,אחלמה
2630 ,קרית גת ,413 ,איזמרגד
2630 ,קרית גת ,277 ,אילון הזבולוני
2630 ,קרית גת ,541 ,איתן נאוה
2630 ,קרית גת ,397 ,אלול
2630 ,קרית גת ,543 ,אלי כהן
2630 ,קרית גת ,162 ,אליהו הנביא
2630 ,קרית גת ,175 ,אלישע
2630 ,קרית גת ,459 ,אלכסנדרוני
2630 ,קרית גת ,321 ,אמציה
2630 ,קרית גת ,322 ,אסא
2630 ,קרית גת ,571 ,אסתר המלכה
2630 ,קרית גת ,358 ,אפק
2630 ,קרית גת ,360 ,אפרת
2630 ,קרית גת ,437 ,אקסודוס
2630 ,קרית גת ,343 ,אשור
2630 ,קרית גת ,414 ,אשלגן
2630 ,קרית גת ,547 ,באבא סאלי
2630 ,קרית גת ,186 ,ביל"ו
2630 ,קרית גת ,302 ,בית יוסף
2630 ,קרית גת ,548 ,בעל שם טוב
2630 ,קרית גת ,581 ,ברקת
2630 ,קרית גת ,345 ,גאון הירדן
2630 ,קרית גת ,462 ,גבעתי
2630 ,קרית גת ,445 ,גדנ"ע
2630 ,קרית גת ,278 ,גדעון בן יואש
2630 ,קרית גת ,565 ,גולדה מאיר
2630 ,קרית גת ,461 ,גולני
2630 ,קרית גת ,536 ,גרופית
2630 ,קרית גת ,281 ,דוד המלך
2630 ,קרית גת ,341 ,דור
2630 ,קרית גת ,183 ,דן
2630 ,קרית גת ,147 ,דרך הדרום
2630 ,קרית גת ,180 ,דרך החרושת
2630 ,קרית גת ,546 ,האדמו"ר מחב"ד
2630 ,קרית גת ,245 ,האופה
2630 ,קרית גת ,390 ,האורג
2630 ,קרית גת ,226 ,האורן
2630 ,קרית גת ,227 ,האזדרכת
2630 ,קרית גת ,145 ,האלה
2630 ,קרית גת ,224 ,האלון
2630 ,קרית גת ,157 ,האפרסמון
2630 ,קרית גת ,225 ,האקליפטוס
2630 ,קרית גת ,143 ,הארז
2630 ,קרית גת ,246 ,הבונה
2630 ,קרית גת ,415 ,הבונים
2630 ,קרית גת ,244 ,הבורר
2630 ,קרית גת ,142 ,הברוש
2630 ,קרית גת ,416 ,הברזל
2630 ,קרית גת ,442 ,הגבורה
2630 ,קרית גת ,149 ,הגפן
2630 ,קרית גת ,141 ,הדקל
2630 ,קרית גת ,146 ,הוסטל עצמאות
2630 ,קרית גת ,240 ,הזורע
2630 ,קרית גת ,139 ,הזית
2630 ,קרית גת ,241 ,החורש
2630 ,קרית גת ,429 ,החייל האלמוני
2630 ,קרית גת ,185 ,החלוץ
2630 ,קרית גת ,243 ,הטוחן
2630 ,קרית גת ,391 ,היוצר
2630 ,קרית גת ,116 ,היסמין
2630 ,קרית גת ,160 ,הכרמל
2630 ,קרית גת ,151 ,הלוז
2630 ,קרית גת ,152 ,הלילך
2630 ,קרית גת ,428 ,המאבק
2630 ,קרית גת ,430 ,המולדת
2630 ,קרית גת ,193 ,המחתרות
2630 ,קרית גת ,178 ,המלאכה
2630 ,קרית גת ,392 ,המסגר
2630 ,קרית גת ,194 ,המעפיל
2630 ,קרית גת ,435 ,המעפיל האלמוני
2630 ,קרית גת ,427 ,המרי העברי
2630 ,קרית גת ,248 ,המשרטט
2630 ,קרית גת ,176 ,הנורית
2630 ,קרית גת ,564 ,הנרייטה סאלד
2630 ,קרית גת ,123 ,הסחלב
2630 ,קרית גת ,393 ,העבודה
2630 ,קרית גת ,502 ,העיינות
2630 ,קרית גת ,511 ,הערבה
2630 ,קרית גת ,465 ,הפורצים
2630 ,קרית גת ,417 ,הפלדה
2630 ,קרית גת ,458 ,הפלוגה הדתית
2630 ,קרית גת ,166 ,הפרדס
2630 ,קרית גת ,432 ,הפרטיזנים
2630 ,קרית גת ,344 ,הצדיק משטפנשט
2630 ,קרית גת ,247 ,הצובע
2630 ,קרית גת ,446 ,הצנחנים
2630 ,קרית גת ,122 ,הקוממיות
2630 ,קרית גת ,242 ,הקוצר
2630 ,קרית גת ,217 ,הר ארדון
2630 ,קרית גת ,222 ,הר בן יאיר
2630 ,קרית גת ,223 ,הר גמלא
2630 ,קרית גת ,219 ,הר חברון
2630 ,קרית גת ,215 ,הר חזון
2630 ,קרית גת ,221 ,הר מצדה
2630 ,קרית גת ,216 ,הר עצמון
2630 ,קרית גת ,220 ,הר פאר
2630 ,קרית גת ,218 ,הר קדומים
2630 ,קרית גת ,129 ,הרב דן מרדכי הכהן
2630 ,קרית גת ,376 ,הרב יורם אברג'ל
2630 ,קרית גת ,165 ,הרב מרדכי אלמליח
2630 ,קרית גת ,407 ,הרב ניסים מויאל
2630 ,קרית גת ,339 ,הרב עובדיה יוסף
2630 ,קרית גת ,184 ,הרב שלום דרמר
2630 ,קרית גת ,130 ,הרימון
2630 ,קרית גת ,205 ,השומרון
2630 ,קרית גת ,182 ,השיטה
2630 ,קרית גת ,154 ,השקד
2630 ,קרית גת ,211 ,השקמים
2630 ,קרית גת ,136 ,התאנה
2630 ,קרית גת ,249 ,התופר
2630 ,קרית גת ,444 ,התותחנים
2630 ,קרית גת ,159 ,התמרים
2630 ,קרית גת ,425 ,התקומה
2630 ,קרית גת ,418 ,זהב
2630 ,קרית גת ,574 ,זלדה שניאורסון
2630 ,קרית גת ,419 ,זרחן
2630 ,קרית גת ,323 ,חזקיהו
2630 ,קרית גת ,357 ,חידקל
2630 ,קרית גת ,457 ,חיל הנדסה
2630 ,קרית גת ,568 ,חנה הנביאה
2630 ,קרית גת ,566 ,חנה סנש
2630 ,קרית גת ,398 ,חשון
2630 ,קרית גת ,420 ,טופז
2630 ,קרית גת ,171 ,טיילת הבוסתן
2630 ,קרית גת ,365 ,טיילת כנען
2630 ,קרית גת ,282 ,יאיר הגלעדי
2630 ,קרית גת ,102 ,יהודה
2630 ,קרית גת ,542 ,יהודה קן-דרור
2630 ,קרית גת ,325 ,יהושפט
2630 ,קרית גת ,421 ,יהלום
2630 ,קרית גת ,283 ,יפתח הגלעדי
2630 ,קרית גת ,438 ,יציאת תש"ז
2630 ,קרית גת ,586 ,ישפה
2630 ,קרית גת ,436 ,כ"ג
2630 ,קרית גת ,6006 ,ככר ברק
2630 ,קרית גת ,6003 ,ככר הגבורה
2630 ,קרית גת ,6004 ,ככר הירדן
2630 ,קרית גת ,6002 ,ככר המעפיל
2630 ,קרית גת ,6005 ,ככר הנגב
2630 ,קרית גת ,6001 ,ככר שאול המלך
2630 ,קרית גת ,400 ,כסלו
2630 ,קרית גת ,423 ,כסף
2630 ,קרית גת ,577 ,לאה אמנו
2630 ,קרית גת ,573 ,לאה גולדברג
2630 ,קרית גת ,424 ,לשם
2630 ,קרית גת ,544 ,מאיר פיינשטיין
2630 ,קרית גת ,232 ,מבוא בנימין
2630 ,קרית גת ,331 ,מבוא גבעון
2630 ,קרית גת ,329 ,מבוא גלגל
2630 ,קרית גת ,332 ,מבוא דביר
2630 ,קרית גת ,213 ,מבוא החרמון
2630 ,קרית גת ,439 ,מבוא הרב קוק
2630 ,קרית גת ,124 ,מבוא התבור
2630 ,קרית גת ,460 ,מבוא חיל האויר
2630 ,קרית גת ,410 ,מבוא ים
2630 ,קרית גת ,422 ,מבוא ירח
2630 ,קרית גת ,156 ,מבוא ישעיהו
2630 ,קרית גת ,327 ,מבוא לבנה
2630 ,קרית גת ,340 ,מבוא ליש
2630 ,קרית גת ,348 ,מבוא מואב
2630 ,קרית גת ,468 ,מבוא מטכ"ל
2630 ,קרית גת ,496 ,מבוא מימן
2630 ,קרית גת ,364 ,מבוא נוב
2630 ,קרית גת ,402 ,מבוא ניסן
2630 ,קרית גת ,491 ,מבוא סדן
2630 ,קרית גת ,403 ,מבוא סיון
2630 ,קרית גת ,328 ,מבוא עזקה
2630 ,קרית גת ,334 ,מבוא עפרה
2630 ,קרית גת ,493 ,מבוא פטיש
2630 ,קרית גת ,409 ,מבוא קדם
2630 ,קרית גת ,362 ,מבוא שניר
2630 ,קרית גת ,440 ,מבוא תש"ח
2630 ,קרית גת ,451 ,מבצע חורב
2630 ,קרית גת ,453 ,מבצע חירם
2630 ,קרית גת ,452 ,מבצע יואב
2630 ,קרית גת ,450 ,מבצע עובדה
2630 ,קרית גת ,431 ,מדינת היהודים
2630 ,קרית גת ,443 ,מודיעין
2630 ,קרית גת ,284 ,מיכה
2630 ,קרית גת ,209 ,מירון
2630 ,קרית גת ,530 ,מפרץ ים סוף
2630 ,קרית גת ,572 ,מרים הנביאה
2630 ,קרית גת ,6501 ,מרכז מסחרי
2630 ,קרית גת ,200 ,מרכז קליטה
2630 ,קרית גת ,538 ,משה ברזני
2630 ,קרית גת ,449 ,משמר הגבול
2630 ,קרית גת ,107 ,משעול אהרון
2630 ,קרית גת ,352 ,משעול אופיר
2630 ,קרית גת ,535 ,משעול אפעה
2630 ,קרית גת ,233 ,משעול אשר
2630 ,קרית גת ,353 ,משעול גושן
2630 ,קרית גת ,359 ,משעול גיחון
2630 ,קרית גת ,346 ,משעול גלעד
2630 ,קרית גת ,528 ,משעול גרר
2630 ,קרית גת ,230 ,משעול גרשון
2630 ,קרית גת ,464 ,משעול דקר
2630 ,קרית גת ,121 ,משעול האיריס
2630 ,קרית גת ,190 ,משעול האצ"ל
2630 ,קרית גת ,212 ,משעול הארבל
2630 ,קרית גת ,137 ,משעול האשכולית
2630 ,קרית גת ,150 ,משעול האתרוג
2630 ,קרית גת ,506 ,משעול הבשור
2630 ,קרית גת ,455 ,משעול הגדוד העברי
2630 ,קרית גת ,207 ,משעול הגלבוע
2630 ,קרית גת ,133 ,משעול הדובדבן
2630 ,קרית גת ,202 ,משעול הדישון
2630 ,קרית גת ,120 ,משעול הדפנה
2630 ,קרית גת ,191 ,משעול ההגנה
2630 ,קרית גת ,140 ,משעול ההדרים
2630 ,קרית גת ,128 ,משעול הורד
2630 ,קרית גת ,117 ,משעול החבצלת
2630 ,קרית גת ,192 ,משעול הלח"י
2630 ,קרית גת ,138 ,משעול הלימון
2630 ,קרית גת ,523 ,משעול המכתש
2630 ,קרית גת ,466 ,משעול המעפיל
2630 ,קרית גת ,119 ,משעול הסביון
2630 ,קרית גת ,115 ,משעול הסייפן
2630 ,קרית גת ,434 ,משעול העפלה
2630 ,קרית גת ,170 ,משעול הפ"ז
2630 ,קרית גת ,195 ,משעול הפלמ"ח
2630 ,קרית גת ,114 ,משעול הפעמונית
2630 ,קרית גת ,456 ,משעול השומר
2630 ,קרית גת ,127 ,משעול השושן
2630 ,קרית גת ,153 ,משעול התפוז
2630 ,קרית גת ,131 ,משעול התפוח
2630 ,קרית גת ,234 ,משעול זבולון
2630 ,קרית גת ,532 ,משעול זוהר
2630 ,קרית גת ,161 ,משעול חגי
2630 ,קרית גת ,108 ,משעול חנוך
2630 ,קרית גת ,524 ,משעול חצירה
2630 ,קרית גת ,110 ,משעול חצרון
2630 ,קרית גת ,517 ,משעול חתירה
2630 ,קרית גת ,347 ,משעול יבוס
2630 ,קרית גת ,324 ,משעול יהואש
2630 ,קרית גת ,235 ,משעול יוסף
2630 ,קרית גת ,515 ,משעול יעלים
2630 ,קרית גת ,109 ,משעול כרמי
2630 ,קרית גת ,239 ,משעול מנשה
2630 ,קרית גת ,507 ,משעול סיירים
2630 ,קרית גת ,169 ,משעול עינב
2630 ,קרית גת ,504 ,משעול ערוגות
2630 ,קרית גת ,509 ,משעול פארן
2630 ,קרית גת ,527 ,משעול צאלים
2630 ,קרית גת ,363 ,משעול צור
2630 ,קרית גת ,508 ,משעול צין
2630 ,קרית גת ,518 ,משעול צניפים
2630 ,קרית גת ,534 ,משעול צפע
2630 ,קרית גת ,361 ,משעול קישון
2630 ,קרית גת ,522 ,משעול קמה
2630 ,קרית גת ,351 ,משעול שבא
2630 ,קרית גת ,104 ,משעול שמעון
2630 ,קרית גת ,288 ,משעול שמשון
2630 ,קרית גת ,463 ,משעול תנין
2630 ,קרית גת ,549 ,נועם אלימלך
2630 ,קרית גת ,588 ,נועם ישראל
2630 ,קרית גת ,587 ,נופך
2630 ,קרית גת ,447 ,נח"ל
2630 ,קרית גת ,557 ,נחל איילון
2630 ,קרית גת ,561 ,נחל אלכסנדר
2630 ,קרית גת ,552 ,נחל ארנון
2630 ,קרית גת ,554 ,נחל אשכול
2630 ,קרית גת ,551 ,נחל גוברין
2630 ,קרית גת ,556 ,נחל דולב
2630 ,קרית גת ,560 ,נחל דליות
2630 ,קרית גת ,553 ,נחל זויתן
2630 ,קרית גת ,563 ,נחל ירקון
2630 ,קרית גת ,559 ,נחל נעמן
2630 ,קרית גת ,555 ,נחל צאלים
2630 ,קרית גת ,558 ,נחל קדרון
2630 ,קרית גת ,562 ,נחל רביבים
2630 ,קרית גת ,196 ,ניל"י
2630 ,קרית גת ,350 ,נינוה
2630 ,קרית גת ,575 ,נעמי שמר
2630 ,קרית גת ,181 ,נפתלי
2630 ,קרית גת ,545 ,נתיבות השלום
2630 ,קרית גת ,540 ,נתן אלבז
2630 ,קרית גת ,490 ,נתרן
2630 ,קרית גת ,529 ,סטרומה
2630 ,קרית גת ,111 ,סמ הכלנית
2630 ,קרית גת ,582 ,ספיר
2630 ,קרית גת ,333 ,עדולם
2630 ,קרית גת ,326 ,עוזיהו
2630 ,קרית גת ,426 ,עולי הגרדום
2630 ,קרית גת ,505 ,עין עקרבים
2630 ,קרית גת ,512 ,עציון גבר
2630 ,קרית גת ,286 ,עתניאל בן קנז
2630 ,קרית גת ,6523 ,פארק תעשייה רבין
2630 ,קרית גת ,492 ,פלטינה
2630 ,קרית גת ,163 ,פקיעין
2630 ,קרית גת ,342 ,פרת
2630 ,קרית גת ,494 ,צורן
2630 ,קרית גת ,366 ,ציקלג
2630 ,קרית גת ,274 ,קס אבא יצחק יאסו
2630 ,קרית גת ,454 ,קס אבא מנשה זמרו
2630 ,קרית גת ,9000 ,קרית גת
2630 ,קרית גת ,105 ,ראובן
2630 ,קרית גת ,188 ,רבי אברהם חמו
2630 ,קרית גת ,144 ,רבי אברהם לחיאני
2630 ,קרית גת ,118 ,רבי דוד אבודרהם
2630 ,קרית גת ,177 ,רבי דוד דנינו
2630 ,קרית גת ,576 ,רבקה אמנו
2630 ,קרית גת ,6516 ,רובע אתרי המקרא
2630 ,קרית גת ,6500 ,רובע בני ישראל
2630 ,קרית גת ,6502 ,רובע גליקסון
2630 ,קרית גת ,6503 ,רובע הבנים
2630 ,קרית גת ,6518 ,רובע ההעפלה
2630 ,קרית גת ,6505 ,רובע הנביאים
2630 ,קרית גת ,6522 ,רובע הפרחים
2630 ,קרית גת ,6520 ,רובע הקוממיות
2630 ,קרית גת ,6506 ,רובע השופטים
2630 ,קרית גת ,6507 ,רובע התעשיה
2630 ,קרית גת ,6521 ,רובע מגדים
2630 ,קרית גת ,6508 ,רובע מלכי ישראל
2630 ,קרית גת ,6509 ,רובע פ"ז
2630 ,קרית גת ,6517 ,רובע צה"ל
2630 ,קרית גת ,275 ,רחבת איבצן
2630 ,קרית גת ,396 ,רחבת אייר
2630 ,קרית גת ,513 ,רחבת באר אורה
2630 ,קרית גת ,237 ,רחבת גד
2630 ,קרית גת ,135 ,רחבת האגס
2630 ,קרית גת ,375 ,רחבת האדמונית
2630 ,קרית גת ,533 ,רחבת האלמוגים
2630 ,קרית גת ,148 ,רחבת הגביעונית
2630 ,קרית גת ,164 ,רחבת הדונגית
2630 ,קרית גת ,126 ,רחבת החלמונית
2630 ,קרית גת ,377 ,רחבת הכרמית
2630 ,קרית גת ,203 ,רחבת הר הזיתים
2630 ,קרית גת ,204 ,רחבת הר הצופים
2630 ,קרית גת ,208 ,רחבת הר סיני
2630 ,קרית גת ,525 ,רחבת חלוצה
2630 ,קרית גת ,399 ,רחבת טבת
2630 ,קרית גת ,389 ,רחבת יהל
2630 ,קרית גת ,519 ,רחבת יותם
2630 ,קרית גת ,520 ,רחבת ימין
2630 ,קרית גת ,292 ,רחבת יעל
2630 ,קרית גת ,173 ,רחבת ירמיהו
2630 ,קרית גת ,238 ,רחבת יששכר
2630 ,קרית גת ,206 ,רחבת מוריה
2630 ,קרית גת ,475 ,רחבת ממשית
2630 ,קרית גת ,531 ,רחבת ניצנה
2630 ,קרית גת ,526 ,רחבת עבדת
2630 ,קרית גת ,380 ,רחבת עין בוקק
2630 ,קרית גת ,384 ,רחבת עין גדי
2630 ,קרית גת ,378 ,רחבת עין הבשור
2630 ,קרית גת ,379 ,רחבת עין המערה
2630 ,קרית גת ,385 ,רחבת עין ירקעם
2630 ,קרית גת ,386 ,רחבת עין מור
2630 ,קרית גת ,383 ,רחבת עין נטפים
2630 ,קרית גת ,382 ,רחבת עין עברונה
2630 ,קרית גת ,503 ,רחבת עין צוקים
2630 ,קרית גת ,387 ,רחבת עין תמר
2630 ,קרית גת ,510 ,רחבת צופר
2630 ,קרית גת ,514 ,רחבת קטורה
2630 ,קרית גת ,537 ,רחבת רותם
2630 ,קרית גת ,287 ,רחבת שאול המלך
2630 ,קרית גת ,516 ,רחבת שזפון
2630 ,קרית גת ,467 ,רחבת שיבת ציון
2630 ,קרית גת ,388 ,רחבת תמנע
2630 ,קרית גת ,406 ,רחבת תשרי
2630 ,קרית גת ,579 ,רחל אמנו
2630 ,קרית גת ,336 ,רמות דוד
2630 ,קרית גת ,6510 ,ש הזרע
2630 ,קרית גת ,6512 ,ש חב"ד
2630 ,קרית גת ,6513 ,ש רסקו
2630 ,קרית גת ,584 ,שבו
2630 ,קרית גת ,404 ,שבט
2630 ,קרית גת ,179 ,שבטה
2630 ,קרית גת ,174 ,שבטי ישראל
2630 ,קרית גת ,495 ,שבעת המינים
2630 ,קרית גת ,433 ,שד אגוז
2630 ,קרית גת ,113 ,שד גת
2630 ,קרית גת ,125 ,שד העצמאות
2630 ,קרית גת ,280 ,שד השופטים
2630 ,קרית גת ,172 ,שד יחזקאל
2630 ,קרית גת ,112 ,שד לכיש
2630 ,קרית גת ,285 ,שד מלכי ישראל
2630 ,קרית גת ,168 ,שד פולק ישראל
2630 ,קרית גת ,441 ,שד צה"ל
2630 ,קרית גת ,570 ,שד' האשה העברייה
2630 ,קרית גת ,550 ,שד' כרמי גת
2630 ,קרית גת ,580 ,שדרות אבני החושן
2630 ,קרית גת ,335 ,שדרות אתרי המקרא
2630 ,קרית גת ,236 ,שדרת אפרים
2630 ,קרית גת ,189 ,שדרת גבורי ישראל
2630 ,קרית גת ,279 ,שדרת דבורה
2630 ,קרית גת ,201 ,שדרת הבשן
2630 ,קרית גת ,214 ,שדרת הגולן
2630 ,קרית גת ,155 ,שדרת המגדים
2630 ,קרית גת ,158 ,שדרת הפרחים
2630 ,קרית גת ,231 ,שדרת לוי
2630 ,קרית גת ,106 ,שדרת משה
2630 ,קרית גת ,408 ,שדרת פ"ז
2630 ,קרית גת ,583 ,שוהם
2630 ,קרית גת ,521 ,שונית
2630 ,קרית גת ,356 ,שורק
2630 ,קרית גת ,448 ,שיריון
2630 ,קרית גת ,6504 ,שכ רובע החורש
2630 ,קרית גת ,6519 ,שכ רמות דוד
2630 ,קרית גת ,6514 ,שכ רפאל
2630 ,קרית גת ,6515 ,שכ שער הדרום
2630 ,קרית גת ,539 ,שלמה אפשטיין
2630 ,קרית גת ,290 ,שלמה המלך
2630 ,קרית גת ,289 ,שמגר בן ענת
2630 ,קרית גת ,167 ,שמואל הנביא
2630 ,קרית גת ,567 ,שרה אהרונסון
2630 ,קרית גת ,578 ,שרה אמנו
2630 ,קרית גת ,405 ,תמוז
2630 ,קרית גת ,349 ,תרשיש
2300 ,קרית טבעון ,125 ,אורנים
2300 ,קרית טבעון ,175 ,אזור תעשיה
2300 ,קרית טבעון ,166 ,אלון יגאל
2300 ,קרית טבעון ,117 ,אלונים
2300 ,קרית טבעון ,245 ,אלכסנדר פן
2300 ,קרית טבעון ,186 ,אלעזר דוד
2300 ,קרית טבעון ,118 ,אמנון ותמר
2300 ,קרית טבעון ,149 ,אסבסטונים
2300 ,קרית טבעון ,244 ,אריק שרון
2300 ,קרית טבעון ,127 ,בורוכוב
2300 ,קרית טבעון ,160 ,בן צבי
2300 ,קרית טבעון ,6514 ,בצל אורנים
2300 ,קרית טבעון ,187 ,ברלב חיים
2300 ,קרית טבעון ,180 ,ברק בן אבינועם
2300 ,קרית טבעון ,140 ,גולומב
2300 ,קרית טבעון ,188 ,גור מרדכי
2300 ,קרית טבעון ,161 ,גורדון
2300 ,קרית טבעון ,6512 ,גן לאומי בית שערים
2300 ,קרית טבעון ,176 ,דבורה הנביאה
2300 ,קרית טבעון ,123 ,דגניות
2300 ,קרית טבעון ,150 ,דולב
2300 ,קרית טבעון ,189 ,דיין משה
2300 ,קרית טבעון ,136 ,דרך קרית עמל
2300 ,קרית טבעון ,163 ,האיריס
2300 ,קרית טבעון ,113 ,האלה
2300 ,קרית טבעון ,145 ,הבונים
2300 ,קרית טבעון ,108 ,הברושים
2300 ,קרית טבעון ,185 ,הגומא
2300 ,קרית טבעון ,106 ,הגפן
2300 ,קרית טבעון ,147 ,הדס
2300 ,קרית טבעון ,167 ,הדקל
2300 ,קרית טבעון ,104 ,הורדים
2300 ,קרית טבעון ,204 ,הזית
2300 ,קרית טבעון ,116 ,החורש
2300 ,קרית טבעון ,121 ,החרוב
2300 ,קרית טבעון ,184 ,הכרכום
2300 ,קרית טבעון ,132 ,הכרמל
2300 ,קרית טבעון ,156 ,הליבנה
2300 ,קרית טבעון ,129 ,המגדל
2300 ,קרית טבעון ,135 ,המזרח
2300 ,קרית טבעון ,137 ,המייסדים
2300 ,קרית טבעון ,151 ,המעיין
2300 ,קרית טבעון ,111 ,המרגניות
2300 ,קרית טבעון ,191 ,הסחלב
2300 ,קרית טבעון ,115 ,העליה
2300 ,קרית טבעון ,133 ,העמק
2300 ,קרית טבעון ,183 ,הצבעוני
2300 ,קרית טבעון ,112 ,הציפורנים
2300 ,קרית טבעון ,182 ,הרותם
2300 ,קרית טבעון ,124 ,הרימונים
2300 ,קרית טבעון ,171 ,הרצל
2300 ,קרית טבעון ,168 ,השומרים
2300 ,קרית טבעון ,122 ,השושנים
2300 ,קרית טבעון ,114 ,השיטים
2300 ,קרית טבעון ,101 ,השקדים
2300 ,קרית טבעון ,107 ,התאנה
2300 ,קרית טבעון ,138 ,התשבי
2300 ,קרית טבעון ,173 ,ויצמן (חיים)
2300 ,קרית טבעון ,130 ,זבולון
2300 ,קרית טבעון ,141 ,זייד אלכסנדר
2300 ,קרית טבעון ,105 ,חבצלת
2300 ,קרית טבעון ,146 ,חנקין יהושע
2300 ,קרית טבעון ,200 ,חצב
2300 ,קרית טבעון ,197 ,חרצית
2300 ,קרית טבעון ,128 ,יהודה הנשיא
2300 ,קרית טבעון ,240 ,יוסף בורג
2300 ,קרית טבעון ,144 ,יזרעאל
2300 ,קרית טבעון ,162 ,יסמין
2300 ,קרית טבעון ,193 ,יערה
2300 ,קרית טבעון ,152 ,יפה נוף
2300 ,קרית טבעון ,238 ,יצחק נבון
2300 ,קרית טבעון ,207 ,יצחק שדה
2300 ,קרית טבעון ,242 ,יצחק שמיר
2300 ,קרית טבעון ,6007 ,כיכר אורים
2300 ,קרית טבעון ,6006 ,כיכר העליה
2300 ,קרית טבעון ,6001 ,ככר בן גוריון
2300 ,קרית טבעון ,6003 ,ככר הבנים
2300 ,קרית טבעון ,6004 ,ככר עלית הנוער
2300 ,קרית טבעון ,6002 ,ככר פטישי דב
2300 ,קרית טבעון ,6005 ,ככר קומפיין
2300 ,קרית טבעון ,120 ,כלניות
2300 ,קרית טבעון ,157 ,כצנלסון
2300 ,קרית טבעון ,165 ,לילך
2300 ,קרית טבעון ,148 ,מוסד אורים
2300 ,קרית טבעון ,196 ,מורן
2300 ,קרית טבעון ,232 ,מנחם בגין
2300 ,קרית טבעון ,181 ,מעלה אבשלום
2300 ,קרית טבעון ,205 ,מעלה השיזף
2300 ,קרית טבעון ,134 ,מרדכי (טננבוים)
2300 ,קרית טבעון ,195 ,מרוה
2300 ,קרית טבעון ,247 ,מרים רות
2300 ,קרית טבעון ,6511 ,מרכז טבעון
2300 ,קרית טבעון ,159 ,מרכז מסחרי חדש
2300 ,קרית טבעון ,109 ,משעול הלוטם
2300 ,קרית טבעון ,102 ,נוריות
2300 ,קרית טבעון ,177 ,נחל קדומים
2300 ,קרית טבעון ,110 ,נרקיסים
2300 ,קרית טבעון ,201 ,סביון
2300 ,קרית טבעון ,172 ,סולד הנריאטה
2300 ,קרית טבעון ,154 ,סיגליות
2300 ,קרית טבעון ,198 ,סייפן
2300 ,קרית טבעון ,208 ,סימטת הגליל
2300 ,קרית טבעון ,170 ,סמ אבשלום
2300 ,קרית טבעון ,143 ,סמ האחים פיש
2300 ,קרית טבעון ,103 ,סמ האלה
2300 ,קרית טבעון ,206 ,סמ הפרגים
2300 ,קרית טבעון ,142 ,סנש חנה
2300 ,קרית טבעון ,202 ,סתוונית
2300 ,קרית טבעון ,203 ,עירית
2300 ,קרית טבעון ,246 ,עמוס עוז
2300 ,קרית טבעון ,169 ,עמלים
2300 ,קרית טבעון ,194 ,ערבה
2300 ,קרית טבעון ,139 ,קישון
2300 ,קרית טבעון ,6501 ,קרית חרושת
2300 ,קרית טבעון ,9000 ,קרית טבעון
2300 ,קרית טבעון ,6510 ,קרית עמל
2300 ,קרית טבעון ,131 ,קרן קיימת
2300 ,קרית טבעון ,243 ,קרת
2300 ,קרית טבעון ,190 ,רבין יצחק
2300 ,קרית טבעון ,233 ,רכבת העמק
2300 ,קרית טבעון ,126 ,רמז
2300 ,קרית טבעון ,6502 ,רמת הדסה
2300 ,קרית טבעון ,6504 ,רמת טבעון
2300 ,קרית טבעון ,119 ,רקפות
2300 ,קרית טבעון ,6509 ,ש אילה
2300 ,קרית טבעון ,6503 ,ש אקדמאים
2300 ,קרית טבעון ,6505 ,ש מומחים
2300 ,קרית טבעון ,6506 ,ש עולים
2300 ,קרית טבעון ,6507 ,ש עמלים
2300 ,קרית טבעון ,6500 ,ש קרית טבעון
2300 ,קרית טבעון ,248 ,ש. שלום
2300 ,קרית טבעון ,231 ,ש.ב אורבך
2300 ,קרית טבעון ,236 ,שולמית אלוני
2300 ,קרית טבעון ,6508 ,שכ אלרואי
2300 ,קרית טבעון ,6513 ,שכ מעלה טבעון
2300 ,קרית טבעון ,178 ,שלום עליכם
2300 ,קרית טבעון ,179 ,שפרינצק
2300 ,קרית טבעון ,192 ,שקמה
2300 ,קרית טבעון ,158 ,שרת משה
2300 ,קרית טבעון ,199 ,תמר
9600 ,קרית ים ,290 ,אביר יעקב
9600 ,קרית ים ,239 ,אגוזים
9600 ,קרית ים ,156 ,אהבת ציון
9600 ,קרית ים ,199 ,אורן
9600 ,קרית ים ,6502 ,אזור תעשיה
9600 ,קרית ים ,151 ,אחד העם
9600 ,קרית ים ,237 ,אילנות
9600 ,קרית ים ,127 ,אילת
9600 ,קרית ים ,284 ,אירוס
9600 ,קרית ים ,188 ,אלון
9600 ,קרית ים ,186 ,אלי כהן
9600 ,קרית ים ,216 ,אפרים
9600 ,קרית ים ,250 ,אצ"ל
9600 ,קרית ים ,236 ,ארזים
9600 ,קרית ים ,209 ,ארן זלמן
9600 ,קרית ים ,189 ,אשל
9600 ,קרית ים ,212 ,אשר
9600 ,קרית ים ,259 ,אתרוג
9600 ,קרית ים ,187 ,בובר מרטין
9600 ,קרית ים ,148 ,בוזגלו דוד
9600 ,קרית ים ,107 ,בורוכוב
9600 ,קרית ים ,165 ,ביל"ו
9600 ,קרית ים ,180 ,בית סרז'נטים
9600 ,קרית ים ,242 ,בליטי מאיר
9600 ,קרית ים ,214 ,בנימין
9600 ,קרית ים ,185 ,בר יהודה
9600 ,קרית ים ,152 ,ברדיצ'בסקי
9600 ,קרית ים ,210 ,ברזילי ישראל
9600 ,קרית ים ,271 ,גאולה כהן
9600 ,קרית ים ,218 ,גד
9600 ,קרית ים ,243 ,גולדה מאיר
9600 ,קרית ים ,103 ,גולומב אליהו
9600 ,קרית ים ,231 ,גולן
9600 ,קרית ים ,109 ,גורדון
9600 ,קרית ים ,130 ,גלבוע
9600 ,קרית ים ,232 ,גלעד
9600 ,קרית ים ,261 ,גן סולד
9600 ,קרית ים ,257 ,גפן
9600 ,קרית ים ,275 ,דגנית
9600 ,קרית ים ,121 ,דוד המלך
9600 ,קרית ים ,245 ,דולב
9600 ,קרית ים ,220 ,דן
9600 ,קרית ים ,193 ,דקל
9600 ,קרית ים ,134 ,הברוש
9600 ,קרית ים ,203 ,הבשור
9600 ,קרית ים ,202 ,הגליל
9600 ,קרית ים ,145 ,הגעתון
9600 ,קרית ים ,260 ,הדס
9600 ,קרית ים ,248 ,הדרים
9600 ,קרית ים ,140 ,ההגנה
9600 ,קרית ים ,147 ,הורד
9600 ,קרית ים ,190 ,הזית
9600 ,קרית ים ,117 ,החשמונאים
9600 ,קרית ים ,143 ,הירדן
9600 ,קרית ים ,253 ,המחתרת
9600 ,קרית ים ,160 ,המייסדים
9600 ,קרית ים ,102 ,המעפילים
9600 ,קרית ים ,200 ,הנגב
9600 ,קרית ים ,163 ,הנוטרים
9600 ,קרית ים ,141 ,העליה
9600 ,קרית ים ,138 ,העצמאות
9600 ,קרית ים ,201 ,הערבה
9600 ,קרית ים ,101 ,הקוממיות
9600 ,קרית ים ,149 ,הקישון
9600 ,קרית ים ,178 ,הראשונים
9600 ,קרית ים ,241 ,הרב משאש יוסף
9600 ,קרית ים ,247 ,הרדוף
9600 ,קרית ים ,196 ,השומרון
9600 ,קרית ים ,120 ,השופטים
9600 ,קרית ים ,206 ,התקומה
9600 ,קרית ים ,164 ,ז'בוטינסקי
9600 ,קרית ים ,221 ,זבולון
9600 ,קרית ים ,283 ,חבצלת
9600 ,קרית ים ,285 ,חצב
9600 ,קרית ים ,234 ,חרובים
9600 ,קרית ים ,106 ,טרומפלדור
9600 ,קרית ים ,153 ,טשרניחובסקי
9600 ,קרית ים ,217 ,יהודה
9600 ,קרית ים ,115 ,יהודה הלוי
9600 ,קרית ים ,213 ,יוסף
9600 ,קרית ים ,174 ,יוספטל
9600 ,קרית ים ,129 ,יחיעם
9600 ,קרית ים ,293 ,יסמין
9600 ,קרית ים ,122 ,יצחק קינן
9600 ,קרית ים ,286 ,יקינתון
9600 ,קרית ים ,208 ,ישעיהו ישראל
9600 ,קרית ים ,222 ,יששכר
9600 ,קרית ים ,6001 ,ככר לוי אשכול
9600 ,קרית ים ,276 ,כלנית
9600 ,קרית ים ,144 ,כצנלסון
9600 ,קרית ים ,240 ,לבון פנחס
9600 ,קרית ים ,131 ,להמן הרברט
9600 ,קרית ים ,105 ,לוחמי הגטאות
9600 ,קרית ים ,244 ,לוטם
9600 ,קרית ים ,223 ,לוי
9600 ,קרית ים ,252 ,לח"י
9600 ,קרית ים ,292 ,לילך
9600 ,קרית ים ,207 ,לכיש
9600 ,קרית ים ,205 ,מגידו
9600 ,קרית ים ,204 ,מודיעין
9600 ,קרית ים ,113 ,מוהליבר
9600 ,קרית ים ,246 ,מורן
9600 ,קרית ים ,155 ,מיכל
9600 ,קרית ים ,197 ,מירון
9600 ,קרית ים ,157 ,מנדלי מוכר ספרים
9600 ,קרית ים ,215 ,מנשה
9600 ,קרית ים ,198 ,מצדה
9600 ,קרית ים ,277 ,מרגנית
9600 ,קרית ים ,173 ,מרכז קליטה
9600 ,קרית ים ,251 ,ניל"י
9600 ,קרית ים ,169 ,נמיר מרדכי
9600 ,קרית ים ,301 ,נמרי יעקב
9600 ,קרית ים ,272 ,נעמי שמר
9600 ,קרית ים ,142 ,נעמן
9600 ,קרית ים ,219 ,נפתלי
9600 ,קרית ים ,278 ,נרקיס
9600 ,קרית ים ,279 ,סביון
9600 ,קרית ים ,6511 ,סביוני ים
9600 ,קרית ים ,123 ,סולד הנריאטה
9600 ,קרית ים ,179 ,סולד רוברט
9600 ,קרית ים ,112 ,סוקולוב
9600 ,קרית ים ,125 ,סטרומה
9600 ,קרית ים ,291 ,סיגלית
9600 ,קרית ים ,282 ,סמ סביון
9600 ,קרית ים ,228 ,סנש חנה
9600 ,קרית ים ,176 ,ספיר פנחס
9600 ,קרית ים ,227 ,עגנון
9600 ,קרית ים ,211 ,עדולם
9600 ,קרית ים ,128 ,עציון
9600 ,קרית ים ,233 ,ערמונים
9600 ,קרית ים ,114 ,פינסקר
9600 ,קרית ים ,124 ,פנקס
9600 ,קרית ים ,6512 ,פסגות ים
9600 ,קרית ים ,158 ,פרישמן
9600 ,קרית ים ,159 ,פרץ
9600 ,קרית ים ,238 ,צאלון
9600 ,קרית ים ,139 ,צה"ל
9600 ,קרית ים ,281 ,ציפורן
9600 ,קרית ים ,110 ,קפלן
9600 ,קרית ים ,9000 ,קרית ים
9600 ,קרית ים ,162 ,קרן קימת
9600 ,קרית ים ,225 ,ראובן
9600 ,קרית ים ,256 ,רימון
9600 ,קרית ים ,116 ,רמב"ם
9600 ,קרית ים ,280 ,רקפת
9600 ,קרית ים ,6500 ,ש אגש
9600 ,קרית ים ,6501 ,ש אזבסטונים
9600 ,קרית ים ,6514 ,ש אלמוגים
9600 ,קרית ים ,6503 ,ש גליה
9600 ,קרית ים ,6504 ,ש חדש
9600 ,קרית ים ,6505 ,ש טיבר עמידר
9600 ,קרית ים ,6507 ,ש עמידר
9600 ,קרית ים ,6508 ,ש צבא קבע
9600 ,קרית ים ,6509 ,ש קליפורניה
9600 ,קרית ים ,226 ,שבזי
9600 ,קרית ים ,119 ,שבטי ישראל
9600 ,קרית ים ,270 ,שד אלון יגאל
9600 ,קרית ים ,154 ,שד ביאליק
9600 ,קרית ים ,183 ,שד בן צבי
9600 ,קרית ים ,171 ,שד ההסתדרות
9600 ,קרית ים ,111 ,שד הפלמ"ח
9600 ,קרית ים ,150 ,שד הרצל
9600 ,קרית ים ,118 ,שד ויצמן
9600 ,קרית ים ,161 ,שד ירושלים
9600 ,קרית ים ,104 ,שדה יצחק
9600 ,קרית ים ,168 ,שז"ר זלמן
9600 ,קרית ים ,182 ,שטח שרון
9600 ,קרית ים ,235 ,שיקמים
9600 ,קרית ים ,6506 ,שכ א'
9600 ,קרית ים ,6513 ,שכ בנה ביתך
9600 ,קרית ים ,6510 ,שכונה ד
9600 ,קרית ים ,224 ,שמעון
9600 ,קרית ים ,229 ,שפירא משה
9600 ,קרית ים ,146 ,שפרינצק
9600 ,קרית ים ,258 ,שקד
9600 ,קרית ים ,170 ,שרה אהרונסון
9600 ,קרית ים ,184 ,שרת משה
9600 ,קרית ים ,255 ,תאנה
9600 ,קרית ים ,230 ,תבור
9600 ,קרית ים ,254 ,תמר
1137 ,קרית יערים ,103 ,אבינדב
1137 ,קרית יערים ,108 ,אזנים לתורה
1137 ,קרית יערים ,118 ,בית יוסף
1137 ,קרית יערים ,102 ,גורדון
1137 ,קרית יערים ,6501 ,דרך אברהם
1137 ,קרית יערים ,129 ,דרך הרמב"ם
1137 ,קרית יערים ,106 ,הבעל שם טוב
1137 ,קרית יערים ,127 ,הגפן
1137 ,קרית יערים ,105 ,הגר"א
1137 ,קרית יערים ,126 ,הדגן
1137 ,קרית יערים ,121 ,הזית
1137 ,קרית יערים ,107 ,הרב בלוך
1137 ,קרית יערים ,104 ,הרי"ף
1137 ,קרית יערים ,122 ,הרימון
1137 ,קרית יערים ,128 ,התאנה
1137 ,קרית יערים ,120 ,התמר
1137 ,קרית יערים ,119 ,חפץ חיים
1137 ,קרית יערים ,125 ,יעקב
1137 ,קרית יערים ,101 ,יצחק
1137 ,קרית יערים ,123 ,ירושלים
1137 ,קרית יערים ,111 ,מנחת שלמה
1137 ,קרית יערים ,116 ,מעלה יוסף
1137 ,קרית יערים ,113 ,מעלות דוד
1137 ,קרית יערים ,114 ,מעלות חיים
1137 ,קרית יערים ,109 ,מעלות קדושי טעלז
1137 ,קרית יערים ,124 ,משעול דוד
1137 ,קרית יערים ,115 ,משעול מנשה
1137 ,קרית יערים ,6505 ,נווה אליעזר
1137 ,קרית יערים ,6500 ,נוף יערים
1137 ,קרית יערים ,110 ,נחלת יעקב
1137 ,קרית יערים ,9000 ,קרית יערים
1137 ,קרית יערים ,117 ,רש"י
1137 ,קרית יערים ,6504 ,שכ אחוזת יערים
1137 ,קרית יערים ,6503 ,שכ מבואות הבירה
1137 ,קרית יערים ,6502 ,שכ נחלת יעקב
2039 ,קרית יערים(מוסד) ,9000 ,קרית יערים(מוסד)
8200 ,קרית מוצקין ,114 ,אהרון
8200 ,קרית מוצקין ,116 ,אוסישקין
8200 ,קרית מוצקין ,106 ,אושה
8200 ,קרית מוצקין ,121 ,אח"י אילת
8200 ,קרית מוצקין ,122 ,אילון הזבולוני
8200 ,קרית מוצקין ,109 ,אירוס
8200 ,קרית מוצקין ,192 ,אלון יגאל
8200 ,קרית מוצקין ,211 ,אלמוגי יוסף
8200 ,קרית מוצקין ,206 ,אלעזר דוד
8200 ,קרית מוצקין ,213 ,אנילביץ מרדכי
8200 ,קרית מוצקין ,189 ,אשכול לוי
8200 ,קרית מוצקין ,6507 ,את כורדני ב'
8200 ,קרית מוצקין ,215 ,בגין מנחם
8200 ,קרית מוצקין ,218 ,בן צבי
8200 ,קרית מוצקין ,156 ,בנאי יוסי
8200 ,קרית מוצקין ,129 ,בר אילן
8200 ,קרית מוצקין ,117 ,ברק
8200 ,קרית מוצקין ,6500 ,בתי השבים
8200 ,קרית מוצקין ,175 ,גורדון
8200 ,קרית מוצקין ,138 ,גן העצמאות
8200 ,קרית מוצקין ,119 ,גרושקביץ
8200 ,קרית מוצקין ,112 ,דבורה
8200 ,קרית מוצקין ,127 ,דוד המלך
8200 ,קרית מוצקין ,180 ,דולצ'ין אריה
8200 ,קרית מוצקין ,149 ,דליה
8200 ,קרית מוצקין ,124 ,דקר
8200 ,קרית מוצקין ,113 ,דרך עכו
8200 ,קרית מוצקין ,219 ,האוזנר גדעון
8200 ,קרית מוצקין ,207 ,האלה
8200 ,קרית מוצקין ,179 ,הבנים
8200 ,קרית מוצקין ,221 ,הברוש
8200 ,קרית מוצקין ,131 ,הגדוד העברי
8200 ,קרית מוצקין ,128 ,הגולן
8200 ,קרית מוצקין ,110 ,הדס
8200 ,קרית מוצקין ,157 ,ההגנה
8200 ,קרית מוצקין ,153 ,הזית
8200 ,קרית מוצקין ,143 ,החשמונאים
8200 ,קרית מוצקין ,135 ,הילדים
8200 ,קרית מוצקין ,139 ,הירדן
8200 ,קרית מוצקין ,144 ,המגינים
8200 ,קרית מוצקין ,168 ,המעפילים
8200 ,קרית מוצקין ,217 ,המר זבולון
8200 ,קרית מוצקין ,151 ,הסביון
8200 ,קרית מוצקין ,136 ,העליה
8200 ,קרית מוצקין ,223 ,הערבה
8200 ,קרית מוצקין ,154 ,הרב הרצוג
8200 ,קרית מוצקין ,173 ,הרב לוי יוסף
8200 ,קרית מוצקין ,130 ,הרב עוזיאל
8200 ,קרית מוצקין ,102 ,הרב קוק
8200 ,קרית מוצקין ,148 ,הרקפת
8200 ,קרית מוצקין ,152 ,ורד
8200 ,קרית מוצקין ,145 ,ז'בוטינסקי
8200 ,קרית מוצקין ,204 ,חזני מיכאל
8200 ,קרית מוצקין ,188 ,חיבת ציון
8200 ,קרית מוצקין ,161 ,חיטמן עוזי
8200 ,קרית מוצקין ,181 ,חיים טופול
8200 ,קרית מוצקין ,140 ,טרומפלדור
8200 ,קרית מוצקין ,120 ,יבנה
8200 ,קרית מוצקין ,126 ,יהודה הלוי
8200 ,קרית מוצקין ,186 ,יודפת
8200 ,קרית מוצקין ,184 ,יוסף שילוח
8200 ,קרית מוצקין ,169 ,יוספטל
8200 ,קרית מוצקין ,115 ,יעל
8200 ,קרית מוצקין ,224 ,ירושלים
8200 ,קרית מוצקין ,146 ,ירקוני יפה
8200 ,קרית מוצקין ,123 ,יששכר
8200 ,קרית מוצקין ,150 ,כלנית
8200 ,קרית מוצקין ,108 ,כצנלסון
8200 ,קרית מוצקין ,172 ,לאה גולדברג
8200 ,קרית מוצקין ,166 ,לביא אריק
8200 ,קרית מוצקין ,185 ,מודיעין
8200 ,קרית מוצקין ,214 ,מוטה גור
8200 ,קרית מוצקין ,137 ,מורדי הגטאות
8200 ,קרית מוצקין ,107 ,מכבי
8200 ,קרית מוצקין ,164 ,מנור אהוד
8200 ,קרית מוצקין ,220 ,מנחם
8200 ,קרית מוצקין ,177 ,מנשה קדישמן
8200 ,קרית מוצקין ,163 ,מסדה
8200 ,קרית מוצקין ,165 ,משוררים
8200 ,קרית מוצקין ,6520 ,נווה גנים
8200 ,קרית מוצקין ,170 ,נחום גוטמן
8200 ,קרית מוצקין ,132 ,ניל"י
8200 ,קרית מוצקין ,158 ,נרקיס
8200 ,קרית מוצקין ,183 ,נתיב רמז
8200 ,קרית מוצקין ,201 ,נתניהו יונתן
8200 ,קרית מוצקין ,174 ,סמ אורן
8200 ,קרית מוצקין ,194 ,סמ ארז
8200 ,קרית מוצקין ,171 ,סמ אתרוג
8200 ,קרית מוצקין ,195 ,סמ בית"ר
8200 ,קרית מוצקין ,196 ,סמ גלעד
8200 ,קרית מוצקין ,193 ,סמ דקל
8200 ,קרית מוצקין ,155 ,סמ הפלמ"ח
8200 ,קרית מוצקין ,111 ,סמ הקרב
8200 ,קרית מוצקין ,178 ,סמ תמר
8200 ,קרית מוצקין ,277 ,סמטת הסופרים
8200 ,קרית מוצקין ,134 ,סנש חנה
8200 ,קרית מוצקין ,142 ,סעדיה גאון
8200 ,קרית מוצקין ,190 ,ספיר פנחס
8200 ,קרית מוצקין ,167 ,עופרה חזה
8200 ,קרית מוצקין ,191 ,עין גדי
8200 ,קרית מוצקין ,176 ,עלוני נח
8200 ,קרית מוצקין ,208 ,פוגלמן מרדכי
8200 ,קרית מוצקין ,210 ,פז
8200 ,קרית מוצקין ,209 ,צאלון
8200 ,קרית מוצקין ,216 ,צה"ל
8200 ,קרית מוצקין ,203 ,ציגל אברהם
8200 ,קרית מוצקין ,159 ,ציפורן
8200 ,קרית מוצקין ,199 ,קדיש לוז
8200 ,קרית מוצקין ,225 ,קורצ'אק
8200 ,קרית מוצקין ,202 ,קליבנוב
8200 ,קרית מוצקין ,9000 ,קרית מוצקין
8200 ,קרית מוצקין ,160 ,רוקח
8200 ,קרית מוצקין ,125 ,רמב"ם
8200 ,קרית מוצקין ,118 ,רנ"ס
8200 ,קרית מוצקין ,141 ,רש"י
8200 ,קרית מוצקין ,6508 ,ש אגש
8200 ,קרית מוצקין ,6513 ,ש דירות עם א
8200 ,קרית מוצקין ,6514 ,ש דירות עם ב
8200 ,קרית מוצקין ,6516 ,ש מומחים
8200 ,קרית מוצקין ,6517 ,ש פנטגון
8200 ,קרית מוצקין ,6518 ,ש צכי
8200 ,קרית מוצקין ,6519 ,ש רומבואיד
8200 ,קרית מוצקין ,6510 ,ש רסקו
8200 ,קרית מוצקין ,187 ,ש"י עגנון
8200 ,קרית מוצקין ,198 ,שביל הגפן
8200 ,קרית מוצקין ,197 ,שביל הרימון
8200 ,קרית מוצקין ,200 ,שד בן גוריון
8200 ,קרית מוצקין ,104 ,שד גושן משה
8200 ,קרית מוצקין ,133 ,שד ויצמן
8200 ,קרית מוצקין ,103 ,שד קרן קימת
8200 ,קרית מוצקין ,105 ,שדה יצחק
8200 ,קרית מוצקין ,182 ,שושנה דמארי
8200 ,קרית מוצקין ,226 ,שז"ר זלמן
8200 ,קרית מוצקין ,6505 ,שכ אביבים
8200 ,קרית מוצקין ,6511 ,שכ אזבסטונים
8200 ,קרית מוצקין ,6515 ,שכ מוצקין הצעירה
8200 ,קרית מוצקין ,6512 ,שכ צפון מוצקין
8200 ,קרית מוצקין ,278 ,שלום שבזי
8200 ,קרית מוצקין ,162 ,שמר נעמי
8200 ,קרית מוצקין ,147 ,שפרינצק
8200 ,קרית מוצקין ,205 ,שרת משה
8200 ,קרית מוצקין ,222 ,תדהר
8200 ,קרית מוצקין ,212 ,תמרי נחמיה
1034 ,קרית מלאכי ,259 ,אבוקדו
1034 ,קרית מלאכי ,305 ,אבירי לב
1034 ,קרית מלאכי ,250 ,אגוז
1034 ,קרית מלאכי ,271 ,אגס
1034 ,קרית מלאכי ,202 ,אדם יקותיאל
1034 ,קרית מלאכי ,296 ,אהובה עוזרי
1034 ,קרית מלאכי ,306 ,אופרה
1034 ,קרית מלאכי ,135 ,אזור התעשיה
1034 ,קרית מלאכי ,6518 ,אזור התעשייה
1034 ,קרית מלאכי ,120 ,אילת
1034 ,קרית מלאכי ,163 ,איריס
1034 ,קרית מלאכי ,203 ,אלון יגאל
1034 ,קרית מלאכי ,204 ,אלעזר דוד
1034 ,קרית מלאכי ,261 ,אננס
1034 ,קרית מלאכי ,238 ,אנפה
1034 ,קרית מלאכי ,6501 ,אסבסטונים
1034 ,קרית מלאכי ,265 ,אפרסמון
1034 ,קרית מלאכי ,274 ,אפרסק
1034 ,קרית מלאכי ,205 ,אשכול לוי
1034 ,קרית מלאכי ,258 ,אשכולית
1034 ,קרית מלאכי ,268 ,אתרוג
1034 ,קרית מלאכי ,110 ,בגין מנחם
1034 ,קרית מלאכי ,227 ,בורג יוסף
1034 ,קרית מלאכי ,127 ,בן אהרון אליהו
1034 ,קרית מלאכי ,206 ,בן צבי יצחק
1034 ,קרית מלאכי ,129 ,בר יהודה
1034 ,קרית מלאכי ,119 ,גובר רבקה ומרדכי
1034 ,קרית מלאכי ,215 ,גולדה מאיר
1034 ,קרית מלאכי ,122 ,גולן
1034 ,קרית מלאכי ,222 ,גור מרדכי
1034 ,קרית מלאכי ,105 ,גושן
1034 ,קרית מלאכי ,6509 ,גני שמואל
1034 ,קרית מלאכי ,147 ,גרונר דב
1034 ,קרית מלאכי ,253 ,דובדבן
1034 ,קרית מלאכי ,239 ,דוד אלבג' עבודי
1034 ,קרית מלאכי ,234 ,דוכיפת
1034 ,קרית מלאכי ,207 ,דולצ'ין אריה
1034 ,קרית מלאכי ,225 ,דורי יעקב
1034 ,קרית מלאכי ,231 ,דיה
1034 ,קרית מלאכי ,208 ,דיין משה
1034 ,קרית מלאכי ,237 ,דרור
1034 ,קרית מלאכי ,170 ,דרך יגאל הורביץ
1034 ,קרית מלאכי ,181 ,האומן
1034 ,קרית מלאכי ,143 ,הבאר
1034 ,קרית מלאכי ,144 ,הבשור
1034 ,קרית מלאכי ,112 ,הגליל
1034 ,קרית מלאכי ,106 ,הגפן
1034 ,קרית מלאכי ,161 ,הורדים
1034 ,קרית מלאכי ,114 ,הזית
1034 ,קרית מלאכי ,182 ,החרושת
1034 ,קרית מלאכי ,189 ,היוזמה
1034 ,קרית מלאכי ,183 ,היוצר
1034 ,קרית מלאכי ,209 ,הירדן
1034 ,קרית מלאכי ,111 ,הכרמל
1034 ,קרית מלאכי ,102 ,המ"ג
1034 ,קרית מלאכי ,184 ,המלאכה
1034 ,קרית מלאכי ,109 ,הנגב
1034 ,קרית מלאכי ,133 ,העליה
1034 ,קרית מלאכי ,185 ,העמל
1034 ,קרית מלאכי ,130 ,העמק
1034 ,קרית מלאכי ,107 ,הערבה
1034 ,קרית מלאכי ,167 ,הציפורן
1034 ,קרית מלאכי ,188 ,הקידמה
1034 ,קרית מלאכי ,201 ,הרב אבו חצירא
1034 ,קרית מלאכי ,134 ,הרב איפרגן
1034 ,קרית מלאכי ,149 ,הרב גורן שלמה
1034 ,קרית מלאכי ,293 ,הרב יורם אברג'יל
1034 ,קרית מלאכי ,117 ,הרב ניסים יצחק
1034 ,קרית מלאכי ,292 ,הרב עובדיה יוסף
1034 ,קרית מלאכי ,128 ,הרב עוזיאל
1034 ,קרית מלאכי ,210 ,הרב פינטו חיים
1034 ,קרית מלאכי ,151 ,הרבי מליובאוויטש
1034 ,קרית מלאכי ,297 ,הרבנית חנה שנאורסון
1034 ,קרית מלאכי ,219 ,הרדוף
1034 ,קרית מלאכי ,226 ,הרצוג חיים
1034 ,קרית מלאכי ,118 ,הרצל
1034 ,קרית מלאכי ,113 ,השומרון
1034 ,קרית מלאכי ,162 ,השושנים
1034 ,קרית מלאכי ,132 ,השפלה
1034 ,קרית מלאכי ,171 ,התאנה
1034 ,קרית מלאכי ,126 ,התומר
1034 ,קרית מלאכי ,104 ,ויצמן
1034 ,קרית מלאכי ,211 ,ורדימון
1034 ,קרית מלאכי ,139 ,ז'בוטינסקי
1034 ,קרית מלאכי ,272 ,חבוש
1034 ,קרית מלאכי ,304 ,חומת מגן
1034 ,קרית מלאכי ,186 ,חלוצי התעשיה
1034 ,קרית מלאכי ,229 ,חסידה
1034 ,קרית מלאכי ,212 ,ידין יגאל
1034 ,קרית מלאכי ,124 ,יודפת
1034 ,קרית מלאכי ,228 ,יונה
1034 ,קרית מלאכי ,164 ,יסמין
1034 ,קרית מלאכי ,220 ,יקינטון
1034 ,קרית מלאכי ,115 ,כהן אלי
1034 ,קרית מלאכי ,6003 ,ככר הבנים
1034 ,קרית מלאכי ,6001 ,ככר הבעל שם טוב
1034 ,קרית מלאכי ,6002 ,ככר הנשיאים
1034 ,קרית מלאכי ,165 ,כלנית
1034 ,קרית מלאכי ,214 ,כנען
1034 ,קרית מלאכי ,150 ,לב הקריה
1034 ,קרית מלאכי ,294 ,לוי יצחק שניאורסאהן
1034 ,קרית מלאכי ,262 ,לימון
1034 ,קרית מלאכי ,213 ,לסקוב חיים
1034 ,קרית מלאכי ,191 ,מאיר עזרא
1034 ,קרית מלאכי ,300 ,מבצע יונתן
1034 ,קרית מלאכי ,302 ,מבצע ליטני
1034 ,קרית מלאכי ,301 ,מבצע סבנה
1034 ,קרית מלאכי ,291 ,מבצע שלמה
1034 ,קרית מלאכי ,125 ,מגידו
1034 ,קרית מלאכי ,216 ,מלמיסון ריאל
1034 ,קרית מלאכי ,257 ,מנגו
1034 ,קרית מלאכי ,123 ,מצדה
1034 ,קרית מלאכי ,141 ,מרכז הקליטה
1034 ,קרית מלאכי ,254 ,משמש
1034 ,קרית מלאכי ,6516 ,נווה אור
1034 ,קרית מלאכי ,235 ,נחליאלי
1034 ,קרית מלאכי ,148 ,נחלת הר חב"ד
1034 ,קרית מלאכי ,221 ,נרקיס
1034 ,קרית מלאכי ,224 ,נתניהו יונתן
1034 ,קרית מלאכי ,108 ,סאן דייגו
1034 ,קרית מלאכי ,241 ,סוויסה אברהם
1034 ,קרית מלאכי ,116 ,סנש חנה
1034 ,קרית מלאכי ,217 ,ספיר פנחס
1034 ,קרית מלאכי ,101 ,ספרא
1034 ,קרית מלאכי ,232 ,עגור
1034 ,קרית מלאכי ,140 ,עזרא ונחמיה
1034 ,קרית מלאכי ,303 ,ענבי זעם
1034 ,קרית מלאכי ,260 ,פומלה
1034 ,קרית מלאכי ,252 ,פקאן
1034 ,קרית מלאכי ,187 ,פקר
1034 ,קרית מלאכי ,223 ,צור צבי
1034 ,קרית מלאכי ,131 ,צימוקי אריה
1034 ,קרית מלאכי ,263 ,קיווי
1034 ,קרית מלאכי ,264 ,קלמנטינה
1034 ,קרית מלאכי ,9000 ,קרית מלאכי
1034 ,קרית מלאכי ,146 ,רזיאל דוד
1034 ,קרית מלאכי ,266 ,רימון
1034 ,קרית מלאכי ,142 ,רמב"ם
1034 ,קרית מלאכי ,166 ,רקפת
1034 ,קרית מלאכי ,145 ,רש"י
1034 ,קרית מלאכי ,6507 ,ש בן גוריון
1034 ,קרית מלאכי ,6513 ,ש המייסדים
1034 ,קרית מלאכי ,6502 ,ש הנרקיסים
1034 ,קרית מלאכי ,6512 ,ש וייצמן
1034 ,קרית מלאכי ,6503 ,ש זוגות צעירים
1034 ,קרית מלאכי ,6505 ,ש חדש
1034 ,קרית מלאכי ,6506 ,ש חסכון
1034 ,קרית מלאכי ,6500 ,ש יגאל אלון
1034 ,קרית מלאכי ,6508 ,ש צבא קבע
1034 ,קרית מלאכי ,136 ,שבזי
1034 ,קרית מלאכי ,242 ,שד אריק שרון
1034 ,קרית מלאכי ,103 ,שד בן גוריון
1034 ,קרית מלאכי ,137 ,שד ירושלים
1034 ,קרית מלאכי ,240 ,שד קרן הידידות
1034 ,קרית מלאכי ,290 ,שד' קק"ל
1034 ,קרית מלאכי ,218 ,שדה יצחק
1034 ,קרית מלאכי ,121 ,שז"ר
1034 ,קרית מלאכי ,267 ,שזיף
1034 ,קרית מלאכי ,236 ,שחף
1034 ,קרית מלאכי ,6515 ,שכ גן
1034 ,קרית מלאכי ,6514 ,שכ המ"ג
1034 ,קרית מלאכי ,6511 ,שכ הרצל
1034 ,קרית מלאכי ,6510 ,שכ יגר
1034 ,קרית מלאכי ,6504 ,שכ נחלת הר חב"ד
1034 ,קרית מלאכי ,6517 ,שכ קיבוץ גלויות
1034 ,קרית מלאכי ,233 ,שלדג
1034 ,קרית מלאכי ,230 ,שליו
1034 ,קרית מלאכי ,295 ,שמעון פרס
1034 ,קרית מלאכי ,256 ,שסק
1034 ,קרית מלאכי ,251 ,שקד
1034 ,קרית מלאכי ,138 ,שרת משה
1034 ,קרית מלאכי ,269 ,תות
1034 ,קרית מלאכי ,273 ,תמר
1034 ,קרית מלאכי ,190 ,תמרי
1034 ,קרית מלאכי ,255 ,תפוז
1034 ,קרית מלאכי ,270 ,תפוח
3746 ,קרית נטפים ,102 ,נופי הדר
3746 ,קרית נטפים ,101 ,עלומים
3746 ,קרית נטפים ,9000 ,קרית נטפים
78 ,קרית ענבים ,9000 ,קרית ענבים
469 ,קרית עקרון ,6521 ,א"ת מסחר עקרון 2000
469 ,קרית עקרון ,203 ,אגמיה
469 ,קרית עקרון ,218 ,אודם
469 ,קרית עקרון ,127 ,אחד העם
469 ,קרית עקרון ,126 ,אלי כהן
469 ,קרית עקרון ,185 ,אנפה
469 ,קרית עקרון ,216 ,בוסי סנט ג'ורג'
469 ,קרית עקרון ,6501 ,ביל"ו סנטר
469 ,קרית עקרון ,151 ,בעלי מלאכה
469 ,קרית עקרון ,159 ,בר חיים יהודה
469 ,קרית עקרון ,220 ,ברקת
469 ,קרית עקרון ,146 ,גהלי יעקב
469 ,קרית עקרון ,169 ,גולני
469 ,קרית עקרון ,6520 ,גני חן
469 ,קרית עקרון ,111 ,דובדבן
469 ,קרית עקרון ,136 ,דולב
469 ,קרית עקרון ,131 ,דרך ירושלים
469 ,קרית עקרון ,110 ,האבות
469 ,קרית עקרון ,168 ,האגוז
469 ,קרית עקרון ,193 ,האירוס
469 ,קרית עקרון ,197 ,האלון
469 ,קרית עקרון ,112 ,הבנים
469 ,קרית עקרון ,104 ,הברוש
469 ,קרית עקרון ,103 ,הגפן
469 ,קרית עקרון ,165 ,הדס
469 ,קרית עקרון ,129 ,הדקל
469 ,קרית עקרון ,101 ,הזית
469 ,קרית עקרון ,194 ,החבצלת
469 ,קרית עקרון ,171 ,היהלום
469 ,קרית עקרון ,163 ,הלבונה
469 ,קרית עקרון ,195 ,הלוטם
469 ,קרית עקרון ,142 ,הלוי דוד
469 ,קרית עקרון ,132 ,המייסדים
469 ,קרית עקרון ,192 ,הנורית
469 ,קרית עקרון ,181 ,הסביונים
469 ,קרית עקרון ,180 ,העופרים
469 ,קרית עקרון ,172 ,הפנינים
469 ,קרית עקרון ,173 ,הצנחנים
469 ,קרית עקרון ,149 ,הרב דוד יוסף מחלי
469 ,קרית עקרון ,123 ,הרב דוד צדוק הלוי
469 ,קרית עקרון ,137 ,הרב מימון
469 ,קרית עקרון ,114 ,הרב עובדיה יעבץ
469 ,קרית עקרון ,164 ,הרב עובדיה לוי
469 ,קרית עקרון ,133 ,הרמב"ם
469 ,קרית עקרון ,135 ,הרצל
469 ,קרית עקרון ,118 ,השיטה
469 ,קרית עקרון ,166 ,השקד
469 ,קרית עקרון ,196 ,התאנה
469 ,קרית עקרון ,102 ,התמר
469 ,קרית עקרון ,107 ,ויצמן
469 ,קרית עקרון ,189 ,חופית
469 ,קרית עקרון ,215 ,חסן מלך מרוקו השני
469 ,קרית עקרון ,167 ,חרוב
469 ,קרית עקרון ,205 ,חרצית
469 ,קרית עקרון ,219 ,טופז
469 ,קרית עקרון ,147 ,יורדי הסירה
469 ,קרית עקרון ,175 ,יעקב כהן
469 ,קרית עקרון ,105 ,יערה
469 ,קרית עקרון ,176 ,יצחק מאמו
469 ,קרית עקרון ,154 ,כובשי החרמון
469 ,קרית עקרון ,6005 ,כיכר החינוך
469 ,קרית עקרון ,6003 ,כיכר הידע
469 ,קרית עקרון ,6002 ,כיכר הצדק
469 ,קרית עקרון ,6001 ,כיכר הרעות
469 ,קרית עקרון ,182 ,כלנית
469 ,קרית עקרון ,115 ,לוחמי הגטאות
469 ,קרית עקרון ,122 ,מאיר בעל הנס
469 ,קרית עקרון ,139 ,מדעי שלום
469 ,קרית עקרון ,138 ,מורן
469 ,קרית עקרון ,119 ,מרבד הקסמים
469 ,קרית עקרון ,6519 ,נאות אביבים
469 ,קרית עקרון ,6518 ,נאות הדרים
469 ,קרית עקרון ,124 ,נווה אשכול
469 ,קרית עקרון ,6510 ,נווה יוסף
469 ,קרית עקרון ,201 ,נופר
469 ,קרית עקרון ,183 ,נרקיס
469 ,קרית עקרון ,190 ,סלעית
469 ,קרית עקרון ,186 ,סנונית
469 ,קרית עקרון ,174 ,ספיר
469 ,קרית עקרון ,177 ,עובדיה בן שלום
469 ,קרית עקרון ,144 ,עמיחי
469 ,קרית עקרון ,191 ,עפרוני
469 ,קרית עקרון ,202 ,צופית
469 ,קרית עקרון ,170 ,קהילת אוהיו
469 ,קרית עקרון ,9000 ,קרית עקרון
469 ,קרית עקרון ,210 ,קרן היסוד
469 ,קרית עקרון ,160 ,רח 10
469 ,קרית עקרון ,145 ,רח 14
469 ,קרית עקרון ,106 ,רקפת
469 ,קרית עקרון ,6503 ,ש ההסתדרות
469 ,קרית עקרון ,6504 ,ש ורבורג
469 ,קרית עקרון ,6507 ,ש טרומיים
469 ,קרית עקרון ,6509 ,ש נוה עובד
469 ,קרית עקרון ,6511 ,ש עמידר צריפים
469 ,קרית עקרון ,6513 ,ש צריפים
469 ,קרית עקרון ,6512 ,ש צריפים אדומים
469 ,קרית עקרון ,6514 ,ש קרן היסוד
469 ,קרית עקרון ,108 ,שבזי
469 ,קרית עקרון ,120 ,שד יצחק רבין
469 ,קרית עקרון ,217 ,שוהם
469 ,קרית עקרון ,188 ,שחף
469 ,קרית עקרון ,187 ,שחפית
469 ,קרית עקרון ,121 ,שטרן יאיר
469 ,קרית עקרון ,117 ,שיבת ציון
469 ,קרית עקרון ,200 ,שיזף
469 ,קרית עקרון ,184 ,שיחנית
469 ,קרית עקרון ,6515 ,שכ בתים נטושים
469 ,קרית עקרון ,6516 ,שכ יחד
469 ,קרית עקרון ,6500 ,שכ נווה אשכול
469 ,קרית עקרון ,6506 ,שכ שבת אחים
469 ,קרית עקרון ,150 ,שלום חנש
469 ,קרית עקרון ,116 ,שלמה בן יוסף
469 ,קרית עקרון ,113 ,שלמה דרף
469 ,קרית עקרון ,155 ,שקמה
469 ,קרית עקרון ,125 ,שרת משה
412 ,קרית שלמה ,9000 ,קרית שלמה
2800 ,קרית שמונה ,111 ,אדם יקותיאל
2800 ,קרית שמונה ,359 ,אוחנה שמואל
2800 ,קרית שמונה ,402 ,אורי אילן
2800 ,קרית שמונה ,376 ,אורן
2800 ,קרית שמונה ,6521 ,אזור תעשיה דרום
2800 ,קרית שמונה ,6520 ,אזור תעשיה צפון
2800 ,קרית שמונה ,229 ,אח"י אילת
2800 ,קרית שמונה ,310 ,אחד העם
2800 ,קרית שמונה ,362 ,אירוס
2800 ,קרית שמונה ,378 ,אל-על
2800 ,קרית שמונה ,373 ,אלי הורוביץ
2800 ,קרית שמונה ,388 ,אלמוג
2800 ,קרית שמונה ,201 ,ארלוזורוב
2800 ,קרית שמונה ,307 ,ארנון
2800 ,קרית שמונה ,304 ,בבא סאלי
2800 ,קרית שמונה ,353 ,בגין מנחם
2800 ,קרית שמונה ,383 ,בוסתן
2800 ,קרית שמונה ,390 ,בזלת
2800 ,קרית שמונה ,309 ,ביאליק
2800 ,קרית שמונה ,391 ,בניאס
2800 ,קרית שמונה ,225 ,ברזילי ישראל
2800 ,קרית שמונה ,211 ,ברל
2800 ,קרית שמונה ,219 ,ברנר
2800 ,קרית שמונה ,364 ,גבעת שחומית
2800 ,קרית שמונה ,374 ,גיבורים
2800 ,קרית שמונה ,393 ,גמלא
2800 ,קרית שמונה ,210 ,דן
2800 ,קרית שמונה ,407 ,דן דיין
2800 ,קרית שמונה ,333 ,הבנים
2800 ,קרית שמונה ,161 ,הברונזה
2800 ,קרית שמונה ,160 ,הברזל
2800 ,קרית שמונה ,115 ,הגלעד
2800 ,קרית שמונה ,404 ,הגנה
2800 ,קרית שמונה ,166 ,הגרניט
2800 ,קרית שמונה ,305 ,הדישון
2800 ,קרית שמונה ,350 ,הוורדים
2800 ,קרית שמונה ,222 ,החולה
2800 ,קרית שמונה ,312 ,הירדן
2800 ,קרית שמונה ,306 ,הירקון
2800 ,קרית שמונה ,108 ,הלבנון
2800 ,קרית שמונה ,207 ,המחתרת
2800 ,קרית שמונה ,114 ,המכבים
2800 ,קרית שמונה ,408 ,הנגב
2800 ,קרית שמונה ,162 ,הנחושת
2800 ,קרית שמונה ,313 ,הנשיא
2800 ,קרית שמונה ,165 ,העופרת
2800 ,קרית שמונה ,164 ,הפלדה
2800 ,קרית שמונה ,145 ,הר אביטל
2800 ,קרית שמונה ,138 ,הר אדיר
2800 ,קרית שמונה ,139 ,הר אדמון
2800 ,קרית שמונה ,140 ,הר ארבל
2800 ,קרית שמונה ,147 ,הר גילה
2800 ,קרית שמונה ,134 ,הר הכרמל
2800 ,קרית שמונה ,131 ,הר המוריה
2800 ,קרית שמונה ,107 ,הר הצופים
2800 ,קרית שמונה ,137 ,הר התבור
2800 ,קרית שמונה ,143 ,הר חזון
2800 ,קרית שמונה ,149 ,הר חרמון
2800 ,קרית שמונה ,141 ,הר כנען
2800 ,קרית שמונה ,136 ,הר מצדה
2800 ,קרית שמונה ,148 ,הר ציון
2800 ,קרית שמונה ,144 ,הר שגיא
2800 ,קרית שמונה ,332 ,הר שומרון
2800 ,קרית שמונה ,213 ,הרב הרצוג
2800 ,קרית שמונה ,102 ,הרב יעקב סאינה
2800 ,קרית שמונה ,334 ,הרב מימון משה
2800 ,קרית שמונה ,331 ,הרב משאש יוסף
2800 ,קרית שמונה ,327 ,הרב עובדיה יוסף
2800 ,קרית שמונה ,231 ,הרב קוק
2800 ,קרית שמונה ,146 ,הרי אפרים
2800 ,קרית שמונה ,130 ,הרי גלבוע
2800 ,קרית שמונה ,133 ,הרי יהודה
2800 ,קרית שמונה ,208 ,הרצל
2800 ,קרית שמונה ,212 ,השופטים
2800 ,קרית שמונה ,356 ,חבצלת
2800 ,קרית שמונה ,221 ,חומה ומגדל
2800 ,קרית שמונה ,300 ,חניתה אטיאס קידר
2800 ,קרית שמונה ,358 ,חצב
2800 ,קרית שמונה ,352 ,חרצית
2800 ,קרית שמונה ,389 ,טל
2800 ,קרית שמונה ,401 ,טרומפלדור
2800 ,קרית שמונה ,203 ,טשרניחובסקי
2800 ,קרית שמונה ,200 ,יגר יוסף
2800 ,קרית שמונה ,202 ,יהודה הלוי
2800 ,קרית שמונה ,361 ,יסמין
2800 ,קרית שמונה ,6003 ,כיכר אורי אזולאי
2800 ,קרית שמונה ,6004 ,כיכר הבנים
2800 ,קרית שמונה ,6006 ,כיכר השואה והתקומה
2800 ,קרית שמונה ,6007 ,כיכר התעשיינים
2800 ,קרית שמונה ,6005 ,כיכר טוטיה אדיב
2800 ,קרית שמונה ,6001 ,ככר ההסתדרות
2800 ,קרית שמונה ,6002 ,ככר צהל
2800 ,קרית שמונה ,355 ,כלנית
2800 ,קרית שמונה ,301 ,כנרת
2800 ,קרית שמונה ,380 ,לוטם
2800 ,קרית שמונה ,232 ,מבוא אמנה
2800 ,קרית שמונה ,235 ,מבוא הרצי"ה
2800 ,קרית שמונה ,6515 ,מורדות נפתלי
2800 ,קרית שמונה ,117 ,מלול יעקב
2800 ,קרית שמונה ,223 ,מלון הצפון
2800 ,קרית שמונה ,406 ,מלון פגודה
2800 ,קרית שמונה ,209 ,מלחמת ששת הימים
2800 ,קרית שמונה ,326 ,מלכה
2800 ,קרית שמונה ,363 ,מעלה יאיר
2800 ,קרית שמונה ,226 ,מקורות
2800 ,קרית שמונה ,411 ,מרגנית
2800 ,קרית שמונה ,6522 ,מרכז העיר
2800 ,קרית שמונה ,204 ,משמר הגבול
2800 ,קרית שמונה ,125 ,נוף חרמון
2800 ,קרית שמונה ,357 ,נורית
2800 ,קרית שמונה ,384 ,נחל ג'לבון
2800 ,קרית שמונה ,368 ,נחל דישון
2800 ,קרית שמונה ,399 ,נחל הגעתון
2800 ,קרית שמונה ,366 ,נחל זוויתן
2800 ,קרית שמונה ,370 ,נחל חבושית
2800 ,קרית שמונה ,372 ,נחל חרמון
2800 ,קרית שמונה ,375 ,נחל יהודייה
2800 ,קרית שמונה ,387 ,נחל ירמוך
2800 ,קרית שמונה ,367 ,נחל משגב
2800 ,קרית שמונה ,381 ,נחל נקרות
2800 ,קרית שמונה ,371 ,נחל סנאים
2800 ,קרית שמונה ,385 ,נחל סער
2800 ,קרית שמונה ,386 ,נחל עורבים
2800 ,קרית שמונה ,403 ,נחל עיון
2800 ,קרית שמונה ,369 ,נחל ערער
2800 ,קרית שמונה ,396 ,נחל צאלים
2800 ,קרית שמונה ,397 ,נחל צלמון
2800 ,קרית שמונה ,394 ,נחל קישון
2800 ,קרית שמונה ,398 ,נחל רמון
2800 ,קרית שמונה ,395 ,נחל שורק
2800 ,קרית שמונה ,377 ,נחל שיאון
2800 ,קרית שמונה ,382 ,נחל שני
2800 ,קרית שמונה ,365 ,נחל שניר
2800 ,קרית שמונה ,392 ,נחל תנור
2800 ,קרית שמונה ,379 ,נטפים
2800 ,קרית שמונה ,351 ,נרקיס
2800 ,קרית שמונה ,360 ,סביון
2800 ,קרית שמונה ,405 ,סולד
2800 ,קרית שמונה ,224 ,סיני
2800 ,קרית שמונה ,413 ,סלומונוביץ
2800 ,קרית שמונה ,227 ,סלינגר דוד
2800 ,קרית שמונה ,105 ,סמ החרמון
2800 ,קרית שמונה ,218 ,סמ המלכים
2800 ,קרית שמונה ,214 ,סמ הנביאים
2800 ,קרית שמונה ,205 ,סמ הנוטר
2800 ,קרית שמונה ,217 ,סמ הצוקים
2800 ,קרית שמונה ,101 ,סמ הרי נפתלי
2800 ,קרית שמונה ,220 ,סנש
2800 ,קרית שמונה ,320 ,עגנון
2800 ,קרית שמונה ,215 ,עולי הגרדום
2800 ,קרית שמונה ,302 ,עין זהב
2800 ,קרית שמונה ,354 ,עירית
2800 ,קרית שמונה ,409 ,עלית הנוער
2800 ,קרית שמונה ,325 ,עקיבא
2800 ,קרית שמונה ,230 ,פלוגת אלון
2800 ,קרית שמונה ,412 ,פרי אליעזר
2800 ,קרית שמונה ,233 ,צמרת הגליל
2800 ,קרית שמונה ,9000 ,קרית שמונה
2800 ,קרית שמונה ,328 ,קרן היסוד
2800 ,קרית שמונה ,234 ,קרן קימת לישראל
2800 ,קרית שמונה ,400 ,קשת
2800 ,קרית שמונה ,228 ,רזיאל
2800 ,קרית שמונה ,314 ,רמב"ם
2800 ,קרית שמונה ,308 ,רש"י
2800 ,קרית שמונה ,6501 ,ש אשכול
2800 ,קרית שמונה ,6502 ,ש מודרגים
2800 ,קרית שמונה ,6503 ,ש מזרח
2800 ,קרית שמונה ,6504 ,ש רכבת
2800 ,קרית שמונה ,6505 ,ש שרת
2800 ,קרית שמונה ,118 ,שד האצ"ל
2800 ,קרית שמונה ,329 ,שד יובל יניב
2800 ,קרית שמונה ,410 ,שד תל חי
2800 ,קרית שמונה ,216 ,שדה יצחק
2800 ,קרית שמונה ,6518 ,שכ אצ"ל
2800 ,קרית שמונה ,6506 ,שכ בנה ביתך
2800 ,קרית שמונה ,6517 ,שכ הורדים
2800 ,קרית שמונה ,6507 ,שכ ז'בוטינסקי
2800 ,קרית שמונה ,6508 ,שכ יגאל אלון
2800 ,קרית שמונה ,6509 ,שכ משה חיים שפירא
2800 ,קרית שמונה ,6519 ,שכ נוף חרמון
2800 ,קרית שמונה ,6511 ,שכון א
2800 ,קרית שמונה ,6512 ,שכון ב
2800 ,קרית שמונה ,6513 ,שכון ג
2800 ,קרית שמונה ,6514 ,שכון ד
2800 ,קרית שמונה ,6516 ,שכון ו
2800 ,קרית שמונה ,311 ,שמעוני דוד
2800 ,קרית שמונה ,303 ,שפרינצק
3640 ,קרני שומרון ,6501 ,אלוני שילה
3640 ,קרני שומרון ,207 ,אלישיב
3640 ,קרני שומרון ,3114 ,אפיקים מעלה שומרון
3640 ,קרני שומרון ,3126 ,ארבל
3640 ,קרני שומרון ,210 ,ארגוני הלוחמים
3640 ,קרני שומרון ,3101 ,ארנון מעלה שומרון
3640 ,קרני שומרון ,3116 ,ברקת
3640 ,קרני שומרון ,7001 ,גינות שומרון
3640 ,קרני שומרון ,3127 ,דולב
3640 ,קרני שומרון ,3102 ,דן מעלה שומרון
3640 ,קרני שומרון ,3117 ,דפנה מעלה שומרון
3640 ,קרני שומרון ,3103 ,דרגות מעלה שומרון
3640 ,קרני שומרון ,161 ,האבוב
3640 ,קרני שומרון ,120 ,האורנים
3640 ,קרני שומרון ,119 ,האילנות
3640 ,קרני שומרון ,105 ,האלון
3640 ,קרני שומרון ,216 ,האמונה
3640 ,קרני שומרון ,221 ,האצ"ל
3640 ,קרני שומרון ,115 ,הארז
3640 ,קרני שומרון ,104 ,הברוש
3640 ,קרני שומרון ,3123 ,הברזל
3640 ,קרני שומרון ,3104 ,הבשור מעלה שומרון
3640 ,קרני שומרון ,201 ,הגבורה
3640 ,קרני שומרון ,162 ,הגיתית
3640 ,קרני שומרון ,101 ,הגלעד
3640 ,קרני שומרון ,116 ,הגפן
3640 ,קרני שומרון ,124 ,הדקל
3640 ,קרני שומרון ,123 ,ההדס
3640 ,קרני שומרון ,202 ,ההתנחלות
3640 ,קרני שומרון ,214 ,הזכות
3640 ,קרני שומרון ,218 ,החלוצים
3640 ,קרני שומרון ,129 ,החרוב
3640 ,קרני שומרון ,3122 ,החרש
3640 ,קרני שומרון ,3105 ,הירדן מעלה שומרון
3640 ,קרני שומרון ,217 ,המייסדים
3640 ,קרני שומרון ,159 ,הנבל
3640 ,קרני שומרון ,206 ,הנוטעים
3640 ,קרני שומרון ,127 ,הנחל
3640 ,קרני שומרון ,107 ,הניצנים
3640 ,קרני שומרון ,102 ,הנרקיס
3640 ,קרני שומרון ,164 ,העוגב
3640 ,קרני שומרון ,232 ,העוז
3640 ,קרני שומרון ,219 ,העמל
3640 ,קרני שומרון ,117 ,הערבה
3640 ,קרני שומרון ,163 ,הפעמון
3640 ,קרני שומרון ,156 ,הצלצלים
3640 ,קרני שומרון ,204 ,הקוממיות
3640 ,קרני שומרון ,205 ,הראשונים
3640 ,קרני שומרון ,118 ,הרימון
3640 ,קרני שומרון ,114 ,השקמה
3640 ,קרני שומרון ,160 ,התוף
3640 ,קרני שומרון ,103 ,התמר
3640 ,קרני שומרון ,3124 ,התעשייה
3640 ,קרני שומרון ,203 ,התקוה
3640 ,קרני שומרון ,209 ,חומה ומגדל
3640 ,קרני שומרון ,155 ,חלילים
3640 ,קרני שומרון ,3128 ,חרמון
3640 ,קרני שומרון ,3106 ,יעלים מעלה שומרון
3640 ,קרני שומרון ,225 ,יפה נוף
3640 ,קרני שומרון ,224 ,ישיבת הסדר
3640 ,קרני שומרון ,226 ,כיסופים
3640 ,קרני שומרון ,3118 ,כיתן מעלה שומרון
3640 ,קרני שומרון ,154 ,כלי שיר
3640 ,קרני שומרון ,3113 ,לבונה מעלה שומרון
3640 ,קרני שומרון ,222 ,לח"י
3640 ,קרני שומרון ,3119 ,מעלה הדסה
3640 ,קרני שומרון ,6502 ,מעלה שומרון
3640 ,קרני שומרון ,211 ,מעלות קדושים
3640 ,קרני שומרון ,113 ,משעול הורדים
3640 ,קרני שומרון ,109 ,משעול החבצלת
3640 ,קרני שומרון ,112 ,משעול החצב
3640 ,קרני שומרון ,122 ,משעול החרצית
3640 ,קרני שומרון ,110 ,משעול היסמין
3640 ,קרני שומרון ,106 ,משעול הכלנית
3640 ,קרני שומרון ,125 ,משעול הסביון
3640 ,קרני שומרון ,108 ,משעול הצבעוני
3640 ,קרני שומרון ,121 ,משעול הרדוף
3640 ,קרני שומרון ,111 ,משעול הרקפת
3640 ,קרני שומרון ,126 ,משעול התאנה
3640 ,קרני שומרון ,231 ,נוה עליזה
3640 ,קרני שומרון ,6500 ,נווה מנחם
3640 ,קרני שומרון ,3120 ,נחשונים
3640 ,קרני שומרון ,223 ,ניל"י
3640 ,קרני שומרון ,3107 ,נעמן מעלה שומרון
3640 ,קרני שומרון ,150 ,סמ הכנורות
3640 ,קרני שומרון ,152 ,סמ המצילתיים
3640 ,קרני שומרון ,153 ,סמ הענבלים
3640 ,קרני שומרון ,151 ,סמ השלישים
3640 ,קרני שומרון ,3115 ,סמדר מעלה שומרון
3640 ,קרני שומרון ,3121 ,ערוגות
3640 ,קרני שומרון ,3108 ,צאלים מעלה שומרון
3640 ,קרני שומרון ,220 ,צופים
3640 ,קרני שומרון ,208 ,קבוץ גלויות
3640 ,קרני שומרון ,213 ,קנין הארץ
3640 ,קרני שומרון ,9000 ,קרני שומרון
3640 ,קרני שומרון ,212 ,רחבת אסירי ציון
3640 ,קרני שומרון ,157 ,שביל החצוצרה
3640 ,קרני שומרון ,158 ,שביל השופר
3640 ,קרני שומרון ,128 ,שדרות רחבעם
3640 ,קרני שומרון ,3109 ,שיאון מעלה שומרון
3640 ,קרני שומרון ,215 ,שיבת ציון
3640 ,קרני שומרון ,3110 ,שניר מעלה שומרון
3640 ,קרני שומרון ,3111 ,שקמה מעלה שומרון
3640 ,קרני שומרון ,3125 ,תבור
3640 ,קרני שומרון ,3112 ,תרצה מעלה שומרון
4006 ,קשת ,9000 ,קשת
543 ,ראמה ,139 ,אבן סינא
543 ,ראמה ,183 ,אלאימאן
543 ,ראמה ,141 ,אלאמל
543 ,ראמה ,187 ,אלאסד
543 ,ראמה ,105 ,אלאסילה
543 ,ראמה ,154 ,אלארז
543 ,ראמה ,175 ,אלבאבור
543 ,ראמה ,196 ,אלבורג'
543 ,ראמה ,145 ,אלביאן
543 ,ראמה ,155 ,אלביידר
543 ,ראמה ,147 ,אלבלאט
543 ,ראמה ,170 ,אלבלד
543 ,ראמה ,130 ,אלבסאתין
543 ,ראמה ,204 ,אלג'בל
543 ,ראמה ,146 ,אלג'ורי
543 ,ראמה ,173 ,אלדהב
543 ,ראמה ,212 ,אלדורי
543 ,ראמה ,138 ,אלואדי
543 ,ראמה ,110 ,אלורוד
543 ,ראמה ,117 ,אלזהור
543 ,ראמה ,120 ,אלזקאק
543 ,ראמה ,168 ,אלח'זאן
543 ,ראמה ,182 ,אלח'לוה
543 ,ראמה ,115 ,אלח'רוב
543 ,ראמה ,172 ,אלחארה
543 ,ראמה ,153 ,אלחורה
543 ,ראמה ,124 ,אלחנאן
543 ,ראמה ,179 ,אלחסון
543 ,ראמה ,195 ,אלחצן
543 ,ראמה ,111 ,אליאסמין
543 ,ראמה ,156 ,אלכותלה
543 ,ראמה ,205 ,אלכנאר
543 ,ראמה ,131 ,אלכרום
543 ,ראמה ,119 ,אלמאס
543 ,ראמה ,125 ,אלמג'ארה
543 ,ראמה ,159 ,אלמג'ד
543 ,ראמה ,164 ,אלמג'לס
543 ,ראמה ,116 ,אלמחטה
543 ,ראמה ,199 ,אלמטל
543 ,ראמה ,109 ,אלמידאן
543 ,ראמה ,127 ,אלמיזאן
543 ,ראמה ,163 ,אלמילאד
543 ,ראמה ,104 ,אלמלעב
543 ,ראמה ,201 ,אלמנאר
543 ,ראמה ,137 ,אלמנטקה אלסנאעיה
543 ,ראמה ,142 ,אלמסג'ד
543 ,ראמה ,102 ,אלמסיל
543 ,ראמה ,165 ,אלמסרה
543 ,ראמה ,188 ,אלמפארק
543 ,ראמה ,160 ,אלמקאלי
543 ,ראמה ,121 ,אלמראח
543 ,ראמה ,151 ,אלמראם
543 ,ראמה ,135 ,אלמרג'
543 ,ראמה ,197 ,אלנאורס
543 ,ראמה ,149 ,אלנבעה
543 ,ראמה ,171 ,אלנג'אר
543 ,ראמה ,194 ,אלנג'ום
543 ,ראמה ,202 ,אלנואר
543 ,ראמה ,114 ,אלנור
543 ,ראמה ,126 ,אלנח'יל
543 ,ראמה ,189 ,אלנמר
543 ,ראמה ,167 ,אלנסר
543 ,ראמה ,136 ,אלנענע
543 ,ראמה ,118 ,אלנרג'ס
543 ,ראמה ,122 ,אלסאפי
543 ,ראמה ,150 ,אלסאקיה
543 ,ראמה ,106 ,אלסביל
543 ,ראמה ,133 ,אלסואן
543 ,ראמה ,177 ,אלסובאט
543 ,ראמה ,103 ,אלסועוד
543 ,ראמה ,152 ,אלסופסאף
543 ,ראמה ,144 ,אלסלאם
543 ,ראמה ,128 ,אלסנדיאן
543 ,ראמה ,129 ,אלסנובר
543 ,ראמה ,184 ,אלסעאדה
543 ,ראמה ,185 ,אלעדל
543 ,ראמה ,193 ,אלענדליב
543 ,ראמה ,140 ,אלפארוק
543 ,ראמה ,186 ,אלפורסאן
543 ,ראמה ,169 ,אלפרח
543 ,ראמה ,198 ,אלקלעה
543 ,ראמה ,161 ,אלקנאיאת
543 ,ראמה ,157 ,אלקסטל
543 ,ראמה ,174 ,אלקסר
543 ,ראמה ,143 ,אלראביה
543 ,ראמה ,132 ,אלרוכאם
543 ,ראמה ,134 ,אלרומאן
543 ,ראמה ,191 ,אלריאד
543 ,ראמה ,180 ,אלריחאן
543 ,ראמה ,108 ,אלשביבה
543 ,ראמה ,107 ,אלשהד
543 ,ראמה ,178 ,אלתמר
543 ,ראמה ,166 ,אלתסאמח
543 ,ראמה ,158 ,אלתקסים
543 ,ראמה ,192 ,באט אלמוג'ר
543 ,ראמה ,176 ,בית אלשעב
543 ,ראמה ,203 ,בלסם
543 ,ראמה ,209 ,בראעם
543 ,ראמה ,112 ,דיר אלאתין
543 ,ראמה ,100 ,חיידר
543 ,ראמה ,210 ,חכים
543 ,ראמה ,113 ,כניסיה קתולית
543 ,ראמה ,162 ,כניסת אלרום
543 ,ראמה ,206 ,נאטור
543 ,ראמה ,190 ,נבע סעיד
543 ,ראמה ,181 ,סולטאן באשא
543 ,ראמה ,101 ,עין אלאסראר
543 ,ראמה ,123 ,עין אלג'זירה
543 ,ראמה ,148 ,עין אלחודין
543 ,ראמה ,211 ,פאנוס
543 ,ראמה ,207 ,קביסה
543 ,ראמה ,208 ,קרואן
543 ,ראמה ,9000 ,ראמה
543 ,ראמה ,200 ,רואבי
1334 ,ראס אל-עין ,9000 ,ראס אל-עין
990 ,ראס עלי ,9000 ,ראס עלי
2640 ,ראש העין ,532 ,אביבים
2640 ,ראש העין ,536 ,אביטל
2640 ,ראש העין ,134 ,אבן גבירול
2640 ,ראש העין ,107 ,אבן עזרא
2640 ,ראש העין ,216 ,אבצ"ן
2640 ,ראש העין ,301 ,אגוז
2640 ,ראש העין ,302 ,אגמית
2640 ,ראש העין ,303 ,אגס
2640 ,ראש העין ,542 ,אדיר
2640 ,ראש העין ,304 ,אדמונית
2640 ,ראש העין ,503 ,אהלי קידר
2640 ,ראש העין ,305 ,אוג
2640 ,ראש העין ,273 ,אודם
2640 ,ראש העין ,477 ,אורי ליפשיץ
2640 ,ראש העין ,500 ,אוריהו הנביא
2640 ,ראש העין ,306 ,אורן
2640 ,ראש העין ,6525 ,אזור תעשיה
2640 ,ראש העין ,185 ,אחוה
2640 ,ראש העין ,488 ,אחיה השילוני
2640 ,ראש העין ,307 ,איה
2640 ,ראש העין ,528 ,איילון
2640 ,ראש העין ,308 ,אילות
2640 ,ראש העין ,309 ,אילנות
2640 ,ראש העין ,310 ,אירוס
2640 ,ראש העין ,432 ,אלברט איינשטיין
2640 ,ראש העין ,311 ,אלה
2640 ,ראש העין ,312 ,אלון
2640 ,ראש העין ,482 ,אלוף הרב גורן
2640 ,ראש העין ,424 ,אלי הורוביץ
2640 ,ראש העין ,443 ,אלי ויזל
2640 ,ראש העין ,490 ,אליהו הנביא
2640 ,ראש העין ,491 ,אלישע הנביא
2640 ,ראש העין ,276 ,אלמוג
2640 ,ראש העין ,225 ,אלשייך רחמים
2640 ,ראש העין ,313 ,אמנון ותמר
2640 ,ראש העין ,298 ,אנפה
2640 ,ראש העין ,515 ,אעלה בתמר
2640 ,ראש העין ,223 ,אפיק
2640 ,ראש העין ,143 ,אפק
2640 ,ראש העין ,213 ,אפרת
2640 ,ראש העין ,184 ,אצ"ל
2640 ,ראש העין ,531 ,ארבל
2640 ,ראש העין ,314 ,ארז
2640 ,ראש העין ,428 ,אריאב אורי
2640 ,ראש העין ,421 ,אריאל שרון
2640 ,ראש העין ,156 ,ארץ ישראל השלמה
2640 ,ראש העין ,315 ,אשחר
2640 ,ראש העין ,316 ,אשכולית
2640 ,ראש העין ,317 ,אשל
2640 ,ראש העין ,318 ,אתרוג
2640 ,ראש העין ,204 ,בגין עליזה
2640 ,ראש העין ,271 ,בזלת
2640 ,ראש העין ,554 ,בזק
2640 ,ראש העין ,111 ,בן נון יהושע
2640 ,ראש העין ,144 ,בעל מלאכת שלמה
2640 ,ראש העין ,253 ,ברבור
2640 ,ראש העין ,321 ,ברוש
2640 ,ראש העין ,469 ,ברכה צפירה
2640 ,ראש העין ,529 ,ברק
2640 ,ראש העין ,538 ,ברקן
2640 ,ראש העין ,275 ,ברקת
2640 ,ראש העין ,548 ,בשן
2640 ,ראש העין ,459 ,בת שבע דה רוטשילד
2640 ,ראש העין ,121 ,ג'ון קנדי
2640 ,ראש העין ,146 ,גבורי ישראל
2640 ,ראש העין ,6516 ,גבעת הסלעים
2640 ,ראש העין ,182 ,גבעתי
2640 ,ראש העין ,294 ,גבתון
2640 ,ראש העין ,299 ,גדרון
2640 ,ראש העין ,480 ,גולדה מאיר
2640 ,ראש העין ,297 ,גזית
2640 ,ראש העין ,137 ,גלוסקא
2640 ,ראש העין ,537 ,גלעד
2640 ,ראש העין ,6515 ,גני אפק
2640 ,ראש העין ,6517 ,גני טל
2640 ,ראש העין ,322 ,גפן
2640 ,ראש העין ,103 ,גרונר דב
2640 ,ראש העין ,433 ,גרטי קורי
2640 ,ראש העין ,434 ,גרטרוד עליון
2640 ,ראש העין ,108 ,גרמה מיכאל
2640 ,ראש העין ,272 ,גרניט
2640 ,ראש העין ,422 ,דב לאוטמן
2640 ,ראש העין ,485 ,דבורה הנביאה
2640 ,ראש העין ,425 ,דוב יוסף
2640 ,ראש העין ,120 ,דוד המלך
2640 ,ראש העין ,427 ,דוד מימון
2640 ,ראש העין ,323 ,דוכיפת
2640 ,ראש העין ,470 ,דוש קריאל גרדוש
2640 ,ראש העין ,324 ,דייה
2640 ,ראש העין ,415 ,דיין משה
2640 ,ראש העין ,471 ,דליה רביקוביץ
2640 ,ראש העין ,325 ,דליות
2640 ,ראש העין ,326 ,דם המכבים
2640 ,ראש העין ,327 ,דן
2640 ,ראש העין ,328 ,דפנה
2640 ,ראש העין ,149 ,דקר
2640 ,ראש העין ,329 ,דרגות
2640 ,ראש העין ,420 ,דרך בגין מנחם
2640 ,ראש העין ,250 ,דרך הציונות
2640 ,ראש העין ,270 ,דרך השומרון
2640 ,ראש העין ,561 ,הא באייר
2640 ,ראש העין ,160 ,הבנים
2640 ,ראש העין ,331 ,הבקעה
2640 ,ראש העין ,332 ,הבשור
2640 ,ראש העין ,155 ,הגליל
2640 ,ראש העין ,178 ,הגר"א
2640 ,ראש העין ,501 ,הדודאים
2640 ,ראש העין ,333 ,הדס
2640 ,ראש העין ,411 ,הדקלים
2640 ,ראש העין ,256 ,הדרור
2640 ,ראש העין ,495 ,הושע הנביא
2640 ,ראש העין ,262 ,הזמיר
2640 ,ראש העין ,199 ,החורשים
2640 ,ראש העין ,197 ,החלוץ
2640 ,ראש העין ,552 ,החליל
2640 ,ראש העין ,206 ,החמישה
2640 ,ראש העין ,136 ,החשמונאים
2640 ,ראש העין ,187 ,הטייס
2640 ,ראש העין ,174 ,היצירה
2640 ,ראש העין ,139 ,הירקון
2640 ,ראש העין ,511 ,הלבונה
2640 ,ראש העין ,209 ,הלח"י
2640 ,ראש העין ,141 ,המוביל הארצי
2640 ,ראש העין ,192 ,המילדת
2640 ,ראש העין ,334 ,המכתש
2640 ,ראש העין ,268 ,המלאכה
2640 ,ראש העין ,140 ,המסילה
2640 ,ראש העין ,198 ,המעפיל
2640 ,ראש העין ,191 ,המצפה
2640 ,ראש העין ,173 ,המרץ
2640 ,ראש העין ,205 ,המתמיד
2640 ,ראש העין ,119 ,הנביאים
2640 ,ראש העין ,208 ,הסופר
2640 ,ראש העין ,193 ,הסלע
2640 ,ראש העין ,122 ,הסנהדרין
2640 ,ראש העין ,175 ,העבודה
2640 ,ראש העין ,254 ,העורב
2640 ,ראש העין ,553 ,העליה
2640 ,ראש העין ,132 ,העצמאות
2640 ,ראש העין ,335 ,הפרחים
2640 ,ראש העין ,181 ,הצנחנים
2640 ,ראש העין ,189 ,הקטר
2640 ,ראש העין ,506 ,הר המור
2640 ,ראש העין ,183 ,הראשונים
2640 ,ראש העין ,214 ,הרב ונה יצחק
2640 ,ראש העין ,200 ,הרב חג'בי דוד
2640 ,ראש העין ,138 ,הרב יצהרי צדוק
2640 ,ראש העין ,109 ,הרב כלף
2640 ,ראש העין ,110 ,הרב משה פנחס
2640 ,ראש העין ,220 ,הרב עובדיה יוסף
2640 ,ראש העין ,129 ,הרב שלום מנצורה
2640 ,ראש העין ,435 ,הרברט סיימון
2640 ,ראש העין ,190 ,הרכבת
2640 ,ראש העין ,105 ,הרעות
2640 ,ראש העין ,165 ,הרצל
2640 ,ראש העין ,106 ,הרש"ש
2640 ,ראש העין ,336 ,השפלה
2640 ,ראש העין ,337 ,השרון
2640 ,ראש העין ,172 ,השריון
2640 ,ראש העין ,152 ,התנאים
2640 ,ראש העין ,212 ,התעשייה
2640 ,ראש העין ,169 ,התקוה
2640 ,ראש העין ,210 ,וגנר
2640 ,ראש העין ,179 ,וולפסון
2640 ,ראש העין ,338 ,ורד
2640 ,ראש העין ,293 ,ורדית
2640 ,ראש העין ,410 ,ז'בוטינסקי זאב
2640 ,ראש העין ,452 ,זהרה אלפסיה
2640 ,ראש העין ,535 ,זוהר
2640 ,ראש העין ,341 ,זיו
2640 ,ראש העין ,458 ,זיקו גרציאני
2640 ,ראש העין ,342 ,זית
2640 ,ראש העין ,519 ,זכריה הנביא
2640 ,ראש העין ,215 ,זכריה משה
2640 ,ראש העין ,343 ,זרזיר
2640 ,ראש העין ,295 ,זרעית
2640 ,ראש העין ,517 ,חבקוק הנביא
2640 ,ראש העין ,518 ,חגי הנביא
2640 ,ראש העין ,255 ,חוגלה
2640 ,ראש העין ,258 ,חוחית
2640 ,ראש העין ,261 ,חופית
2640 ,ראש העין ,180 ,חותמי המגילה
2640 ,ראש העין ,126 ,חזון איש
2640 ,ראש העין ,418 ,חטיבת אלכסנדרוני
2640 ,ראש העין ,483 ,חיים הרצוג
2640 ,ראש העין ,481 ,חיים וייצמן
2640 ,ראש העין ,446 ,חיים חפר
2640 ,ראש העין ,277 ,חלמיש
2640 ,ראש העין ,473 ,חנה רובינא
2640 ,ראש העין ,468 ,חנוך לוין
2640 ,ראש העין ,292 ,חסידה
2640 ,ראש העין ,196 ,חפץ חיים
2640 ,ראש העין ,344 ,חצב
2640 ,ראש העין ,345 ,חרוב
2640 ,ראש העין ,346 ,חרמון
2640 ,ראש העין ,274 ,חרסית
2640 ,ראש העין ,347 ,חרצית
2640 ,ראש העין ,186 ,חתוכה יורם
2640 ,ראש העין ,544 ,טללים
2640 ,ראש העין ,133 ,יבניאלי
2640 ,ראש העין ,479 ,יגאל אלון
2640 ,ראש העין ,423 ,יגאל כהן אורגד
2640 ,ראש העין ,348 ,יהודה
2640 ,ראש העין ,125 ,יהודה הלוי
2640 ,ראש העין ,281 ,יהלום
2640 ,ראש העין ,520 ,יואל הנביא
2640 ,ראש העין ,266 ,יונה
2640 ,ראש העין ,492 ,יונה הנביא
2640 ,ראש העין ,462 ,יונה וולך
2640 ,ראש העין ,474 ,יונתן רטוש
2640 ,ראש העין ,455 ,יוסי בנאי
2640 ,ראש העין ,516 ,יחזקאל הנביא
2640 ,ראש העין ,147 ,יחיא יצחק הלוי
2640 ,ראש העין ,226 ,יחיאל סנדרוב
2640 ,ראש העין ,349 ,יסמין
2640 ,ראש העין ,263 ,יסעור
2640 ,ראש העין ,350 ,יערה
2640 ,ראש העין ,466 ,יפה ירקוני
2640 ,ראש העין ,351 ,יקינטון
2640 ,ראש העין ,352 ,ירגזי
2640 ,ראש העין ,530 ,ירדן
2640 ,ראש העין ,171 ,ירושלים השלמה
2640 ,ראש העין ,510 ,יריעות שלמה
2640 ,ראש העין ,557 ,ירמוך
2640 ,ראש העין ,499 ,ירמיהו הנביא
2640 ,ראש העין ,494 ,ישעיהו הנביא
2640 ,ראש העין ,426 ,ישראל בן שמעון רצון
2640 ,ראש העין ,378 ,כחל
2640 ,ראש העין ,6007 ,כיכר האקורדיון
2640 ,ראש העין ,6004 ,כיכר החצוצרה
2640 ,ראש העין ,6002 ,כיכר הפסנתר
2640 ,ראש העין ,6009 ,כיכר הפעמון
2640 ,ראש העין ,6008 ,כיכר הצ'לו
2640 ,ראש העין ,6003 ,כיכר התווים
2640 ,ראש העין ,6011 ,כיכר צוות
2640 ,ראש העין ,6010 ,כיכר שוטר חיים רדמי
2640 ,ראש העין ,6006 ,כיכר תוף מרים
2640 ,ראש העין ,551 ,כינור
2640 ,ראש העין ,6001 ,ככר אלי כהן
2640 ,ראש העין ,353 ,כלנית
2640 ,ראש העין ,177 ,כנפי נשרים
2640 ,ראש העין ,265 ,כפיר
2640 ,ראש העין ,354 ,כרכום
2640 ,ראש העין ,355 ,כרמל
2640 ,ראש העין ,457 ,לאה גולדברג
2640 ,ראש העין ,264 ,לביא
2640 ,ראש העין ,361 ,לילך
2640 ,ראש העין ,362 ,לימון
2640 ,ראש העין ,363 ,לכיש
2640 ,ראש העין ,218 ,לשם
2640 ,ראש העין ,566 ,מבצע דני
2640 ,ראש העין ,563 ,מבצע הראל
2640 ,ראש העין ,564 ,מבצע חירם
2640 ,ראש העין ,562 ,מבצע נחשון
2640 ,ראש העין ,565 ,מבצע עובדה
2640 ,ראש העין ,512 ,מגדים
2640 ,ראש העין ,508 ,מגדל דוד
2640 ,ראש העין ,364 ,מגידו
2640 ,ראש העין ,409 ,מגלן
2640 ,ראש העין ,151 ,מהרי"ץ
2640 ,ראש העין ,127 ,מהריק"א
2640 ,ראש העין ,176 ,מודיעין
2640 ,ראש העין ,201 ,מוזס עפרה
2640 ,ראש העין ,413 ,מורטון מנדל
2640 ,ראש העין ,365 ,מורן
2640 ,ראש העין ,6500 ,מחנה א
2640 ,ראש העין ,6501 ,מחנה ב
2640 ,ראש העין ,6502 ,מחנה ג
2640 ,ראש העין ,496 ,מיכה הנביא
2640 ,ראש העין ,366 ,מירון
2640 ,ראש העין ,534 ,מישר
2640 ,ראש העין ,546 ,מיתר
2640 ,ראש העין ,207 ,מלאכי יוסף
2640 ,ראש העין ,436 ,מלווין קלווין
2640 ,ראש העין ,170 ,מלכי ישראל
2640 ,ראש העין ,533 ,מנור
2640 ,ראש העין ,150 ,מעודה שלמה
2640 ,ראש העין ,221 ,מעיין
2640 ,ראש העין ,194 ,מצדה
2640 ,ראש העין ,6518 ,מצפה אפק
2640 ,ראש העין ,135 ,מרבד הקסמים
2640 ,ראש העין ,367 ,מרגנית
2640 ,ראש העין ,484 ,מרים הנביאה
2640 ,ראש העין ,465 ,מרים ילן שטקליס
2640 ,ראש העין ,444 ,משה לוי
2640 ,ראש העין ,168 ,משעול הרב מלמד שלמה
2640 ,ראש העין ,217 ,משעול הרב שלמה מדר
2640 ,ראש העין ,118 ,משעול זכריה בשארי
2640 ,ראש העין ,116 ,משעול יחיאל שמעון
2640 ,ראש העין ,224 ,משעול עובדיה גמליאל
2640 ,ראש העין ,167 ,משעול עלי (הכהן)
2640 ,ראש העין ,117 ,משעול ציונה אושרי
2640 ,ראש העין ,368 ,נגב
2640 ,ראש העין ,437 ,נדין גורדימר
2640 ,ראש העין ,547 ,נווה אפיקים
2640 ,ראש העין ,6520 ,נווה אפק
2640 ,ראש העין ,456 ,נחום גוטמן
2640 ,ראש העין ,497 ,נחום הנביא
2640 ,ראש העין ,383 ,נחל עירון
2640 ,ראש העין ,386 ,נחל ערוגות
2640 ,ראש העין ,391 ,נחל קישון
2640 ,ראש העין ,416 ,נחל רבה
2640 ,ראש העין ,252 ,נחליאלי
2640 ,ראש העין ,219 ,נחשול
2640 ,ראש העין ,438 ,נילס בוהר
2640 ,ראש העין ,451 ,ניסים אלוני
2640 ,ראש העין ,431 ,נלי זקס
2640 ,ראש העין ,475 ,נעמי שמר
2640 ,ראש העין ,556 ,נעמן
2640 ,ראש העין ,376 ,נץ
2640 ,ראש העין ,369 ,נקר
2640 ,ראש העין ,370 ,נשר
2640 ,ראש העין ,453 ,נתן אלתרמן
2640 ,ראש העין ,487 ,נתן הנביא
2640 ,ראש העין ,464 ,נתן יונתן
2640 ,ראש העין ,115 ,נתן שאול
2640 ,ראש העין ,371 ,סביון
2640 ,ראש העין ,372 ,סיגלית
2640 ,ראש העין ,380 ,סייפן
2640 ,ראש העין ,161 ,סיני
2640 ,ראש העין ,373 ,סיתונית
2640 ,ראש העין ,251 ,סלעית
2640 ,ראש העין ,509 ,סמדר
2640 ,ראש העין ,259 ,סנונית
2640 ,ראש העין ,130 ,סעדיה גאון
2640 ,ראש העין ,527 ,סער
2640 ,ראש העין ,145 ,ספיר יעקב
2640 ,ראש העין ,296 ,עגור
2640 ,ראש העין ,381 ,עדולם
2640 ,ראש העין ,412 ,עובדיה בן שלום
2640 ,ראש העין ,521 ,עובדיה הנביא
2640 ,ראש העין ,550 ,עוגב
2640 ,ראש העין ,382 ,עודד
2640 ,ראש העין ,463 ,עוזי חיטמן
2640 ,ראש העין ,476 ,עופרה חזה
2640 ,ראש העין ,375 ,עורבני
2640 ,ראש העין ,505 ,עין גדי
2640 ,ראש העין ,384 ,עירית
2640 ,ראש העין ,188 ,עלית הנוער
2640 ,ראש העין ,493 ,עמוס הנביא
2640 ,ראש העין ,203 ,עמוסי מנחם
2640 ,ראש העין ,267 ,עמל
2640 ,ראש העין ,154 ,עמרני אברהם
2640 ,ראש העין ,280 ,ענבר
2640 ,ראש העין ,257 ,עפרוני
2640 ,ראש העין ,385 ,ערבה
2640 ,ראש העין ,387 ,פארן
2640 ,ראש העין ,6521 ,פארק תעשיה אפק
2640 ,ראש העין ,439 ,פול סמואלסון
2640 ,ראש העין ,526 ,פולג
2640 ,ראש העין ,222 ,פלג
2640 ,ראש העין ,507 ,פלח הלימון
2640 ,ראש העין ,540 ,פעמון
2640 ,ראש העין ,445 ,פרופ' מיכאל וינגרטן
2640 ,ראש העין ,388 ,צאלון
2640 ,ראש העין ,389 ,צבעוני
2640 ,ראש העין ,195 ,צדק
2640 ,ראש העין ,114 ,צה"ל
2640 ,ראש העין ,390 ,צופית
2640 ,ראש העין ,379 ,צוקית
2640 ,ראש העין ,278 ,צור
2640 ,ראש העין ,541 ,צליל
2640 ,ראש העין ,498 ,צפניה הנביא
2640 ,ראש העין ,513 ,צרור המור
2640 ,ראש העין ,417 ,קבוץ גלויות
2640 ,ראש העין ,211 ,קהילות בני ישראל
2640 ,ראש העין ,157 ,קוממיות
2640 ,ראש העין ,440 ,קרל לנדשטיינר
2640 ,ראש העין ,545 ,קרן
2640 ,ראש העין ,539 ,קשת
2640 ,ראש העין ,549 ,קתרוס
2640 ,ראש העין ,472 ,ראובן רובין
2640 ,ראש העין ,9000 ,ראש העין
2640 ,ראש העין ,158 ,רבי אביץ יחיא
2640 ,ראש העין ,153 ,רבי עקיבא
2640 ,ראש העין ,525 ,רביבים
2640 ,ראש העין ,441 ,רוזלין יאלו
2640 ,ראש העין ,461 ,רחל המשוררת
2640 ,ראש העין ,392 ,ריחן
2640 ,ראש העין ,442 ,ריטה לוי מונטצ'ינו
2640 ,ראש העין ,393 ,רימון
2640 ,ראש העין ,131 ,רמב"ם
2640 ,ראש העין ,162 ,רמת הגולן
2640 ,ראש העין ,394 ,רקפת
2640 ,ראש העין ,123 ,רש"י
2640 ,ראש העין ,6504 ,ש חסכון
2640 ,ראש העין ,6506 ,ש עממי
2640 ,ראש העין ,430 ,ש"י עגנון
2640 ,ראש העין ,142 ,שבזי
2640 ,ראש העין ,163 ,שבטי ישראל
2640 ,ראש העין ,543 ,שגיא
2640 ,ראש העין ,600 ,שדרות בן גוריון
2640 ,ראש העין ,269 ,שדרת עפרוני
2640 ,ראש העין ,279 ,שוהם
2640 ,ראש העין ,395 ,שורק
2640 ,ראש העין ,396 ,שושן
2640 ,ראש העין ,460 ,שושנה דמארי
2640 ,ראש העין ,502 ,שושנת העמקים
2640 ,ראש העין ,260 ,שחף
2640 ,ראש העין ,397 ,שיזף
2640 ,ראש העין ,450 ,שייקה אופיר
2640 ,ראש העין ,124 ,שילה
2640 ,ראש העין ,166 ,שיר השירים
2640 ,ראש העין ,6514 ,שכ אביב
2640 ,ראש העין ,6519 ,שכ הרקפות
2640 ,ראש העין ,6503 ,שכ נתן
2640 ,ראש העין ,6524 ,שכ צה"ל
2640 ,ראש העין ,6522 ,שכ רמב"ם
2640 ,ראש העין ,6523 ,שכ שבזי
2640 ,ראש העין ,6508 ,שכון א
2640 ,ראש העין ,6509 ,שכון ב
2640 ,ראש העין ,6510 ,שכון ג
2640 ,ראש העין ,6511 ,שכון ד
2640 ,ראש העין ,6512 ,שכון ה
2640 ,ראש העין ,6513 ,שכון ו
2640 ,ראש העין ,398 ,שלדג
2640 ,ראש העין ,128 ,שלום זכריה
2640 ,ראש העין ,478 ,שלום צברי
2640 ,ראש העין ,164 ,שלמה המלך
2640 ,ראש העין ,486 ,שמואל הנביא
2640 ,ראש העין ,489 ,שמעיה הנביא
2640 ,ראש העין ,399 ,שסק
2640 ,ראש העין ,400 ,שקד
2640 ,ראש העין ,401 ,שקמה
2640 ,ראש העין ,467 ,שרה לוי תנאי
2640 ,ראש העין ,377 ,שרקרק
2640 ,ראש העין ,159 ,ששת הימים
2640 ,ראש העין ,402 ,תאנה
2640 ,ראש העין ,403 ,תבור
2640 ,ראש העין ,404 ,תור
2640 ,ראש העין ,504 ,תורי זהב
2640 ,ראש העין ,405 ,תות
2640 ,ראש העין ,406 ,תמר
2640 ,ראש העין ,407 ,תענך
2640 ,ראש העין ,408 ,תפוח
2640 ,ראש העין ,454 ,תרצה אתר
2640 ,ראש העין ,282 ,תרשיש
26 ,ראש פינה ,171 ,אודם
26 ,ראש פינה ,133 ,אזור תעשיה
26 ,ראש פינה ,161 ,ברנשטיין
26 ,ראש פינה ,172 ,ברקת
26 ,ראש פינה ,173 ,גרניט
26 ,ראש פינה ,154 ,דרך בן אריה
26 ,ראש פינה ,123 ,דרך הבוסתנים
26 ,ראש פינה ,103 ,דרך הגליל
26 ,ראש פינה ,131 ,דרך החלמוניות
26 ,ראש פינה ,134 ,דרך הטחנה
26 ,ראש פינה ,162 ,דרך היער
26 ,ראש פינה ,124 ,דרך היקב
26 ,ראש פינה ,130 ,דרך המרכז
26 ,ראש פינה ,128 ,דרך הרהט
26 ,ראש פינה ,157 ,דרך עתיקה
26 ,ראש פינה ,168 ,האגס
26 ,ראש פינה ,144 ,האורנים
26 ,ראש פינה ,132 ,האירוס
26 ,ראש פינה ,165 ,האלה
26 ,ראש פינה ,164 ,האלון
26 ,ראש פינה ,140 ,האפרסק
26 ,ראש פינה ,142 ,הברוש
26 ,ראש פינה ,152 ,הברושים
26 ,ראש פינה ,127 ,הגרנות
26 ,ראש פינה ,163 ,הדקל
26 ,ראש פינה ,129 ,הדרך הטורקית
26 ,ראש פינה ,135 ,הורד
26 ,ראש פינה ,145 ,הזיתים
26 ,ראש פינה ,125 ,החורש
26 ,ראש פינה ,107 ,החלוצים
26 ,ראש פינה ,153 ,החלמית
26 ,ראש פינה ,136 ,החצב
26 ,ראש פינה ,119 ,החקלאי
26 ,ראש פינה ,166 ,החרוב
26 ,ראש פינה ,167 ,החרצית
26 ,ראש פינה ,121 ,הכלנית
26 ,ראש פינה ,104 ,הנדיב
26 ,ראש פינה ,120 ,הנורית
26 ,ראש פינה ,137 ,הנרקיס
26 ,ראש פינה ,174 ,הסלע
26 ,ראש פינה ,110 ,העליה
26 ,ראש פינה ,148 ,הצבר
26 ,ראש פינה ,106 ,הראשונים
26 ,ראש פינה ,151 ,הרדוף
26 ,ראש פינה ,118 ,הרחבה חקלאית
26 ,ראש פינה ,105 ,הרחוב העליון
26 ,ראש פינה ,149 ,הרימונים
26 ,ראש פינה ,122 ,הרקפת
26 ,ראש פינה ,141 ,השזיף
26 ,ראש פינה ,150 ,השיטה
26 ,ראש פינה ,116 ,השלום
26 ,ראש פינה ,138 ,התורמוס
26 ,ראש פינה ,146 ,התמרים
26 ,ראש פינה ,169 ,התפוח
26 ,ראש פינה ,158 ,חן
26 ,ראש פינה ,175 ,יהלום
26 ,ראש פינה ,170 ,מעלה בנימין
26 ,ראש פינה ,115 ,מעלה גיא אוני
26 ,ראש פינה ,6514 ,מעלה כנען
26 ,ראש פינה ,155 ,משעול גולן
26 ,ראש פינה ,117 ,משעול גונן
26 ,ראש פינה ,126 ,משעול האקליפטוס
26 ,ראש פינה ,143 ,משעול התאנה
26 ,ראש פינה ,159 ,משעול התמר
26 ,ראש פינה ,108 ,ניסנבוים
26 ,ראש פינה ,109 ,סולד הנריאטה
26 ,ראש פינה ,156 ,סמ גלילי
26 ,ראש פינה ,176 ,ספיר
26 ,ראש פינה ,112 ,פטר פביאן
26 ,ראש פינה ,102 ,פני גולן
26 ,ראש פינה ,177 ,צור
26 ,ראש פינה ,113 ,קרן היסוד
26 ,ראש פינה ,114 ,קרן קימת
26 ,ראש פינה ,9000 ,ראש פינה
26 ,ראש פינה ,6500 ,ש אשטרום
26 ,ראש פינה ,6501 ,ש הבנים
26 ,ראש פינה ,6502 ,ש עמידר
26 ,ראש פינה ,6503 ,ש קרן היסוד
26 ,ראש פינה ,6504 ,ש רסקו
26 ,ראש פינה ,101 ,שוב דוד
26 ,ראש פינה ,178 ,שוהם
26 ,ראש פינה ,6510 ,שכ האמנים
26 ,ראש פינה ,6517 ,שכ הגפנים
26 ,ראש פינה ,6505 ,שכ הגרנות
26 ,ראש פינה ,6511 ,שכ הזיתים
26 ,ראש פינה ,6515 ,שכ הכרמים
26 ,ראש פינה ,6518 ,שכ הראשונים
26 ,ראש פינה ,6516 ,שכ פני גולן
26 ,ראש פינה ,6512 ,שכון ב
26 ,ראש פינה ,6513 ,שכון דרומי
26 ,ראש פינה ,111 ,שמאי עמיקם
26 ,ראש פינה ,139 ,תל אביב
3602 ,ראש צורים ,9000 ,ראש צורים
8300 ,ראשון לציון ,1395 ,אבא אבן
8300 ,ראשון לציון ,1284 ,אבא קובנר
8300 ,ראשון לציון ,136 ,אבו חצירה
8300 ,ראשון לציון ,700 ,אבי האסירים
8300 ,ראשון לציון ,558 ,אבידן שמעון
8300 ,ראשון לציון ,947 ,אביחיל
8300 ,ראשון לציון ,927 ,אביעזר
8300 ,ראשון לציון ,1253 ,אבן גבירול
8300 ,ראשון לציון ,1051 ,אבן חן
8300 ,ראשון לציון ,216 ,אבן שושן
8300 ,ראשון לציון ,694 ,אבן תמר
8300 ,ראשון לציון ,1135 ,אבני איתן
8300 ,ראשון לציון ,1042 ,אבני החושן
8300 ,ראשון לציון ,653 ,אבנר מאיר
8300 ,ראשון לציון ,364 ,אברבנאל
8300 ,ראשון לציון ,813 ,אברמוביץ
8300 ,ראשון לציון ,818 ,אבשלום
8300 ,ראשון לציון ,731 ,אגוזי
8300 ,ראשון לציון ,932 ,אדר
8300 ,ראשון לציון ,688 ,אהוד בן גרא
8300 ,ראשון לציון ,596 ,אהרון הכהן
8300 ,ראשון לציון ,752 ,אהרון וברוך
8300 ,ראשון לציון ,758 ,אהרון עמרם
8300 ,ראשון לציון ,210 ,אהרונוביץ
8300 ,ראשון לציון ,736 ,אהרונסון אהרון
8300 ,ראשון לציון ,1172 ,אוגדה
8300 ,ראשון לציון ,1146 ,אודם
8300 ,ראשון לציון ,5175 ,אוזן אהרון
8300 ,ראשון לציון ,433 ,אולגה ויהושע
8300 ,ראשון לציון ,326 ,אוסישקין
8300 ,ראשון לציון ,1181 ,אופירה
8300 ,ראשון לציון ,5155 ,אופק
8300 ,ראשון לציון ,1287 ,אור החיים
8300 ,ראשון לציון ,440 ,אור כשדים
8300 ,ראשון לציון ,1154 ,אורטל
8300 ,ראשון לציון ,1264 ,אורי צבי
8300 ,ראשון לציון ,5191 ,אורנבך אשר
8300 ,ראשון לציון ,835 ,אורנה פורת
8300 ,ראשון לציון ,861 ,אושה
8300 ,ראשון לציון ,314 ,אז"ר
8300 ,ראשון לציון ,141 ,אזולאי דוד
8300 ,ראשון לציון ,6597 ,אזור אגני חימצון
8300 ,ראשון לציון ,6599 ,אזור התעשיה גן שורק
8300 ,ראשון לציון ,6564 ,אזור מסחר ובילוי
8300 ,ראשון לציון ,6566 ,אזור תעשיה חדש
8300 ,ראשון לציון ,993 ,אח"י בת גלים
8300 ,ראשון לציון ,992 ,אח"י געש
8300 ,ראשון לציון ,976 ,אח"י דקר
8300 ,ראשון לציון ,983 ,אח"י חנית
8300 ,ראשון לציון ,977 ,אח"י חץ
8300 ,ראשון לציון ,994 ,אח"י טיראן
8300 ,ראשון לציון ,984 ,אח"י להב
8300 ,ראשון לציון ,981 ,אח"י מבטח
8300 ,ראשון לציון ,979 ,אח"י מזנק
8300 ,ראשון לציון ,998 ,אח"י מעוז
8300 ,ראשון לציון ,980 ,אח"י משגב
8300 ,ראשון לציון ,995 ,אח"י נוגה
8300 ,ראשון לציון ,982 ,אח"י סופה
8300 ,ראשון לציון ,991 ,אח"י סער
8300 ,ראשון לציון ,990 ,אח"י רשף
8300 ,ראשון לציון ,999 ,אח"י שבא
8300 ,ראשון לציון ,997 ,אח"י תנין
8300 ,ראשון לציון ,304 ,אחד העם
8300 ,ראשון לציון ,6560 ,אחוזת אבות
8300 ,ראשון לציון ,278 ,אחות שרה
8300 ,ראשון לציון ,289 ,אחימאיר אב"א
8300 ,ראשון לציון ,359 ,אחיעזר
8300 ,ראשון לציון ,790 ,אידלסון בבה
8300 ,ראשון לציון ,429 ,איזקסון צבי
8300 ,ראשון לציון ,814 ,אייזנבנד
8300 ,ראשון לציון ,863 ,אילון
8300 ,ראשון לציון ,257 ,אימבר
8300 ,ראשון לציון ,228 ,אינשטיין
8300 ,ראשון לציון ,652 ,איש מצליח
8300 ,ראשון לציון ,1185 ,איתם
8300 ,ראשון לציון ,381 ,איתמר בן אב"י
8300 ,ראשון לציון ,444 ,איתן
8300 ,ראשון לציון ,1147 ,אל-רום
8300 ,ראשון לציון ,528 ,אלון יגאל
8300 ,ראשון לציון ,1138 ,אלוני הבשן
8300 ,ראשון לציון ,934 ,אלחנן
8300 ,ראשון לציון ,1270 ,אלחריזי
8300 ,ראשון לציון ,446 ,אלטלנה
8300 ,ראשון לציון ,1323 ,אלטמן סידני
8300 ,ראשון לציון ,844 ,אלי יבלון
8300 ,ראשון לציון ,1160 ,אלי על
8300 ,ראשון לציון ,303 ,אלירז שלמה
8300 ,ראשון לציון ,5159 ,אלישע
8300 ,ראשון לציון ,615 ,אלפסי
8300 ,ראשון לציון ,692 ,אלפרד מרדכי
8300 ,ראשון לציון ,1256 ,אלקבץ
8300 ,ראשון לציון ,353 ,אלקלעי
8300 ,ראשון לציון ,387 ,אלרואי
8300 ,ראשון לציון ,5197 ,אלתר דוד הכהן
8300 ,ראשון לציון ,1281 ,אלתרמן
8300 ,ראשון לציון ,388 ,אמזלג
8300 ,ראשון לציון ,337 ,אמיל זולא
8300 ,ראשון לציון ,482 ,אנגל אהרון
8300 ,ראשון לציון ,1370 ,אנה טיכו
8300 ,ראשון לציון ,546 ,אנוש
8300 ,ראשון לציון ,105 ,אנילביץ
8300 ,ראשון לציון ,1168 ,אניעם
8300 ,ראשון לציון ,824 ,אסא
8300 ,ראשון לציון ,629 ,אסירי ציון
8300 ,ראשון לציון ,598 ,אסנת ברזאני
8300 ,ראשון לציון ,842 ,אסתר ארדיטי
8300 ,ראשון לציון ,918 ,אסתר המלכה
8300 ,ראשון לציון ,488 ,אסתר וינדמן
8300 ,ראשון לציון ,472 ,אסתר(אסתרינה) צרפתי
8300 ,ראשון לציון ,679 ,אעלה בתמר
8300 ,ראשון לציון ,1166 ,אפיק
8300 ,ראשון לציון ,830 ,אפרים קישון
8300 ,ראשון לציון ,907 ,אצ"ל
8300 ,ראשון לציון ,198 ,ארבל
8300 ,ראשון לציון ,493 ,ארבלי אלמוזלינו
8300 ,ראשון לציון ,771 ,ארגוב סשה
8300 ,ראשון לציון ,924 ,ארגמן
8300 ,ראשון לציון ,1313 ,ארו קנט' ג'וזף
8300 ,ראשון לציון ,122 ,אריאב חיים
8300 ,ראשון לציון ,834 ,אריק איינשטיין
8300 ,ראשון לציון ,207 ,ארלוזורוב
8300 ,ראשון לציון ,115 ,ארנון משה
8300 ,ראשון לציון ,103 ,אש שלום
8300 ,ראשון לציון ,545 ,אשחר
8300 ,ראשון לציון ,5134 ,בארי
8300 ,ראשון לציון ,1080 ,בדולח
8300 ,ראשון לציון ,920 ,בהט
8300 ,ראשון לציון ,1262 ,בובר מרטין
8300 ,ראשון לציון ,677 ,בוגלה יונה
8300 ,ראשון לציון ,1355 ,בוהר נילס
8300 ,ראשון לציון ,1337 ,בולטימור דוד
8300 ,ראשון לציון ,406 ,בורג יוסף
8300 ,ראשון לציון ,267 ,בורוכוב
8300 ,ראשון לציון ,678 ,בוריס אוסובצקי
8300 ,ראשון לציון ,258 ,בוריס זמנסקי
8300 ,ראשון לציון ,1282 ,בורלא
8300 ,ראשון לציון ,292 ,בי"ס שדה
8300 ,ראשון לציון ,311 ,ביאליק
8300 ,ראשון לציון ,7001 ,ביח נאות מרגלית
8300 ,ראשון לציון ,719 ,ביל"ו
8300 ,ראשון לציון ,231 ,בילינסון
8300 ,ראשון לציון ,681 ,בינט מאיר
8300 ,ראשון לציון ,5109 ,ביס חקלאי
8300 ,ראשון לציון ,539 ,בירגר יצחק
8300 ,ראשון לציון ,747 ,בית אבות עד 120
8300 ,ראשון לציון ,5107 ,בית אשר
8300 ,ראשון לציון ,1261 ,בית הלוי
8300 ,ראשון לציון ,674 ,בית הלל
8300 ,ראשון לציון ,862 ,בית הערבה
8300 ,ראשון לציון ,864 ,בית יהושע
8300 ,ראשון לציון ,358 ,בית יוסף
8300 ,ראשון לציון ,737 ,בית לחם
8300 ,ראשון לציון ,675 ,בית שמאי
8300 ,ראשון לציון ,810 ,בית תרצה
8300 ,ראשון לציון ,372 ,בית"ר
8300 ,ראשון לציון ,680 ,בלאו ראובן
8300 ,ראשון לציון ,409 ,בלבן אליעזר
8300 ,ראשון לציון ,1329 ,בלו סול
8300 ,ראשון לציון ,713 ,בלפור
8300 ,ראשון לציון ,716 ,בלקינד שמשון
8300 ,ראשון לציון ,374 ,בן אהרון אורפלי
8300 ,ראשון לציון ,1288 ,בן איש חי
8300 ,ראשון לציון ,415 ,בן אליעזר
8300 ,ראשון לציון ,430 ,בן דוד שלמה
8300 ,ראשון לציון ,400 ,בן זאב
8300 ,ראשון לציון ,635 ,בן זכאי
8300 ,ראשון לציון ,775 ,בן חיים פאול
8300 ,ראשון לציון ,727 ,בן יהודה
8300 ,ראשון לציון ,431 ,בן לולו שמעון
8300 ,ראשון לציון ,1258 ,בן סרוק
8300 ,ראשון לציון ,5187 ,בן עובדיה יוסף
8300 ,ראשון לציון ,5111 ,בן פורת מרים
8300 ,ראשון לציון ,748 ,בן פנחס דוד
8300 ,ראשון לציון ,295 ,בן צבי יצחק
8300 ,ראשון לציון ,5163 ,בן-דוד יצחק
8300 ,ראשון לציון ,1338 ,בנאסרף ברוך
8300 ,ראשון לציון ,1266 ,בנבנישתי
8300 ,ראשון לציון ,1156 ,בני חייל
8300 ,ראשון לציון ,1159 ,בני יהודה
8300 ,ראשון לציון ,463 ,בני עקיבא
8300 ,ראשון לציון ,959 ,בסקינד נדב
8300 ,ראשון לציון ,1259 ,בעל הטורים
8300 ,ראשון לציון ,449 ,בקר
8300 ,ראשון לציון ,699 ,בקרמן אדם
8300 ,ראשון לציון ,963 ,בר אברהם
8300 ,ראשון לציון ,710 ,בר אילן
8300 ,ראשון לציון ,812 ,בר יוחאי
8300 ,ראשון לציון ,508 ,בר כוכבא
8300 ,ראשון לציון ,194 ,בר-לב חיים
8300 ,ראשון לציון ,1308 ,ברג פול
8300 ,ראשון לציון ,1267 ,ברגמן
8300 ,ראשון לציון ,1330 ,ברגסון הנרי
8300 ,ראשון לציון ,663 ,ברגר אריה
8300 ,ראשון לציון ,360 ,ברד אברהם
8300 ,ראשון לציון ,1349 ,ברודסקי יוסף
8300 ,ראשון לציון ,279 ,ברון דבורה
8300 ,ראשון לציון ,227 ,ברזילי
8300 ,ראשון לציון ,465 ,ברית יוסף
8300 ,ראשון לציון ,841 ,ברכה פולד
8300 ,ראשון לציון ,392 ,ברנדיס
8300 ,ראשון לציון ,411 ,ברניצקי נתן
8300 ,ראשון לציון ,219 ,ברנר
8300 ,ראשון לציון ,659 ,ברנשטיין
8300 ,ראשון לציון ,630 ,ברק
8300 ,ראשון לציון ,1043 ,ברקת
8300 ,ראשון לציון ,1268 ,ברש אשר
8300 ,ראשון לציון ,452 ,ברשבסקי
8300 ,ראשון לציון ,5180 ,בשארי סעדיה ויוסף
8300 ,ראשון לציון ,1328 ,בשביס זינגר יצחק
8300 ,ראשון לציון ,778 ,בתלם
8300 ,ראשון לציון ,5182 ,ג'ו עמר
8300 ,ראשון לציון ,1340 ,ג'קוב פרנסואה
8300 ,ראשון לציון ,859 ,גאולים
8300 ,ראשון לציון ,1321 ,גארי בקר
8300 ,ראשון לציון ,5131 ,גבורי ישראל
8300 ,ראשון לציון ,589 ,גבים
8300 ,ראשון לציון ,5153 ,גבין משה
8300 ,ראשון לציון ,6555 ,גבעת אביב
8300 ,ראשון לציון ,1155 ,גבעת יואב
8300 ,ראשון לציון ,389 ,גבעתי
8300 ,ראשון לציון ,707 ,גבריהו
8300 ,ראשון לציון ,1250 ,גדולי ישראל
8300 ,ראשון לציון ,631 ,גדעון
8300 ,ראשון לציון ,787 ,גובר רבקה
8300 ,ראשון לציון ,509 ,גולדברג הנדבן
8300 ,ראשון לציון ,143 ,גולדה מאיר
8300 ,ראשון לציון ,776 ,גולדמן נחום
8300 ,ראשון לציון ,204 ,גולומב
8300 ,ראשון לציון ,129 ,גולינקין
8300 ,ראשון לציון ,270 ,גור
8300 ,ראשון לציון ,217 ,גורדון
8300 ,ראשון לציון ,1347 ,גורדימר נאדין
8300 ,ראשון לציון ,5138 ,גורה שושנה ומשה
8300 ,ראשון לציון ,536 ,גוש חלב
8300 ,ראשון לציון ,362 ,גוש עציון
8300 ,ראשון לציון ,660 ,גיבשטיין יהושע
8300 ,ראשון לציון ,401 ,גינדי מנחם
8300 ,ראשון לציון ,860 ,גינוסר
8300 ,ראשון לציון ,937 ,גינזבורג
8300 ,ראשון לציון ,328 ,גיסין
8300 ,ראשון לציון ,132 ,גלוסקא
8300 ,ראשון לציון ,726 ,גלוסקין
8300 ,ראשון לציון ,1356 ,גלזר דונלד
8300 ,ראשון לציון ,144 ,גלילי ישראל
8300 ,ראשון לציון ,1317 ,גלשאו שלדון
8300 ,ראשון לציון ,238 ,גן יבנה
8300 ,ראשון לציון ,5192 ,גנות צבי
8300 ,ראשון לציון ,336 ,גרוזנברג
8300 ,ראשון לציון ,785 ,גרוסמן חייקה
8300 ,ראשון לציון ,691 ,גריזים
8300 ,ראשון לציון ,390 ,גרינבוים
8300 ,ראשון לציון ,591 ,גרינשפן הרשל
8300 ,ראשון לציון ,473 ,גרמי רחל
8300 ,ראשון לציון ,1055 ,גרניט
8300 ,ראשון לציון ,202 ,גרץ
8300 ,ראשון לציון ,5119 ,גרצברג שיקא
8300 ,ראשון לציון ,698 ,גרשברג אברהם
8300 ,ראשון לציון ,560 ,גרשוני אריה
8300 ,ראשון לציון ,1170 ,גשור
8300 ,ראשון לציון ,865 ,גשר
8300 ,ראשון לציון ,1342 ,ד"ר ז'יוואגו
8300 ,ראשון לציון ,487 ,ד"ר ליאו רוזנבליט
8300 ,ראשון לציון ,996 ,דבור
8300 ,ראשון לציון ,622 ,דבורה הנביאה
8300 ,ראשון לציון ,585 ,דבורנית
8300 ,ראשון לציון ,1037 ,דג הזהב
8300 ,ראשון לציון ,385 ,דגניה
8300 ,ראשון לציון ,1324 ,דה-הבשי ג'ורג'
8300 ,ראשון לציון ,203 ,דובנוב
8300 ,ראשון לציון ,476 ,דוד אלעזר
8300 ,ראשון לציון ,498 ,דוד המזכיר
8300 ,ראשון לציון ,111 ,דוד המלך
8300 ,ראשון לציון ,356 ,דוד הראובני
8300 ,ראשון לציון ,833 ,דודו דותן
8300 ,ראשון לציון ,424 ,דוידזון
8300 ,ראשון לציון ,569 ,דולב
8300 ,ראשון לציון ,489 ,דונה גרציה
8300 ,ראשון לציון ,552 ,דורי
8300 ,ראשון לציון ,1173 ,די-זהב
8300 ,ראשון לציון ,897 ,דיסקונט
8300 ,ראשון לציון ,866 ,דליה
8300 ,ראשון לציון ,5201 ,דליה פרי
8300 ,ראשון לציון ,1291 ,דמארי שושנה
8300 ,ראשון לציון ,867 ,דן
8300 ,ראשון לציון ,654 ,דניאל הנביא
8300 ,ראשון לציון ,1178 ,דקלה
8300 ,ראשון לציון ,756 ,דר אליקום
8300 ,ראשון לציון ,928 ,דרבלה
8300 ,ראשון לציון ,245 ,דרובין יואל
8300 ,ראשון לציון ,340 ,דרור
8300 ,ראשון לציון ,1000 ,דרך ה-1000
8300 ,ראשון לציון ,892 ,דרך ההצלחה
8300 ,ראשון לציון ,5101 ,דרך המכבים
8300 ,ראשון לציון ,550 ,דרך חיים הרצוג
8300 ,ראשון לציון ,118 ,דרך ישראל ישעיהו
8300 ,ראשון לציון ,953 ,דרך עדן
8300 ,ראשון לציון ,275 ,דרך צה"ל
8300 ,ראשון לציון ,5152 ,דשבסקי ישראל
8300 ,ראשון לציון ,1057 ,ה' באייר
8300 ,ראשון לציון ,738 ,האבות
8300 ,ראשון לציון ,502 ,האגוז
8300 ,ראשון לציון ,5128 ,האגס
8300 ,ראשון לציון ,955 ,האגרת
8300 ,ראשון לציון ,730 ,האו"ן
8300 ,ראשון לציון ,572 ,האוכמנית
8300 ,ראשון לציון ,1369 ,האוניברסיטה
8300 ,ראשון לציון ,1205 ,האורגן
8300 ,ראשון לציון ,608 ,האורן
8300 ,ראשון לציון ,5123 ,האורנים
8300 ,ראשון לציון ,555 ,האחד עשר
8300 ,ראשון לציון ,366 ,האחים אביוב
8300 ,ראשון לציון ,702 ,האחים אוסטשינסקי
8300 ,ראשון לציון ,5148 ,האחים גלזר
8300 ,ראשון לציון ,696 ,האחים וינברג
8300 ,ראשון לציון ,395 ,האחים זיגר
8300 ,ראשון לציון ,215 ,האחים יעקובי
8300 ,ראשון לציון ,420 ,האחים סולימן
8300 ,ראשון לציון ,244 ,האחים סמילצ'נסקי
8300 ,ראשון לציון ,496 ,האחים עוקשי
8300 ,ראשון לציון ,5174 ,האילן
8300 ,ראשון לציון ,1035 ,האילתית
8300 ,ראשון לציון ,151 ,האיריס
8300 ,ראשון לציון ,603 ,האלה
8300 ,ראשון לציון ,602 ,האלון
8300 ,ראשון לציון ,486 ,האלוף דוד
8300 ,ראשון לציון ,1015 ,האלמוגים
8300 ,ראשון לציון ,240 ,האם
8300 ,ראשון לציון ,628 ,האמוראים
8300 ,ראשון לציון ,1041 ,האניה
8300 ,ראשון לציון ,5117 ,האפרסמון
8300 ,ראשון לציון ,564 ,האפרסק
8300 ,ראשון לציון ,566 ,האקליפטוס
8300 ,ראשון לציון ,705 ,האר"י
8300 ,ראשון לציון ,616 ,הארז
8300 ,ראשון לציון ,5122 ,האשכולית
8300 ,ראשון לציון ,504 ,האשל
8300 ,ראשון לציון ,612 ,האתרוג
8300 ,ראשון לציון ,849 ,הבה"דים
8300 ,ראשון לציון ,184 ,הבוצרים
8300 ,ראשון לציון ,441 ,הבן הראשון
8300 ,ראשון לציון ,5115 ,הבנים
8300 ,ראשון לציון ,708 ,הבעל שם טוב
8300 ,ראשון לציון ,316 ,הברון הירש
8300 ,ראשון לציון ,521 ,הברוש
8300 ,ראשון לציון ,5116 ,הברושים
8300 ,ראשון לציון ,206 ,הבריגדה
8300 ,ראשון לציון ,1030 ,הברקן
8300 ,ראשון לציון ,242 ,הבשן
8300 ,ראשון לציון ,370 ,הגאון מוילנה
8300 ,ראשון לציון ,755 ,הגבורה היהודית
8300 ,ראשון לציון ,187 ,הגבעול
8300 ,ראשון לציון ,352 ,הגדוד העברי
8300 ,ראשון לציון ,178 ,הגומא
8300 ,ראשון לציון ,1211 ,הגיתית
8300 ,ראשון לציון ,199 ,הגלבוע
8300 ,ראשון לציון ,236 ,הגליל
8300 ,ראשון לציון ,1049 ,הגלים
8300 ,ראשון לציון ,241 ,הגלעד
8300 ,ראשון לציון ,297 ,הגננת בתיה
8300 ,ראשון לציון ,786 ,הגננת הראשונה
8300 ,ראשון לציון ,213 ,הגפן
8300 ,ראשון לציון ,183 ,הגת
8300 ,ראשון לציון ,261 ,הדגל העברי
8300 ,ראשון לציון ,189 ,הדגן
8300 ,ראשון לציון ,568 ,הדובדבן
8300 ,ראשון לציון ,1016 ,הדוגית
8300 ,ראשון לציון ,544 ,הדודאים
8300 ,ראשון לציון ,576 ,הדוכיפת
8300 ,ראשון לציון ,1034 ,הדולפין
8300 ,ראשון לציון ,1033 ,הדיונון
8300 ,ראשון לציון ,1011 ,הדייגים
8300 ,ראשון לציון ,948 ,הדסה
8300 ,ראשון לציון ,501 ,הדקל
8300 ,ראשון לציון ,908 ,ההגנה
8300 ,ראשון לציון ,898 ,ההגשמה
8300 ,ראשון לציון ,514 ,ההדס
8300 ,ראשון לציון ,5121 ,ההדר
8300 ,ראשון לציון ,250 ,ההדרים
8300 ,ראשון לציון ,1004 ,ההזנק
8300 ,ראשון לציון ,899 ,ההישג
8300 ,ראשון לציון ,769 ,ההכשרות
8300 ,ראשון לציון ,180 ,ההסתדרות
8300 ,ראשון לציון ,181 ,ההרדוף
8300 ,ראשון לציון ,1006 ,ההשכלה
8300 ,ראשון לציון ,768 ,ההתיישבות
8300 ,ראשון לציון ,5161 ,הוברמן אברהם (צבי)
8300 ,ראשון לציון ,223 ,הוז דב
8300 ,ראשון לציון ,667 ,הולצברג שמחה
8300 ,ראשון לציון ,1325 ,הופמן רואלד
8300 ,ראשון לציון ,505 ,הורד
8300 ,ראשון לציון ,243 ,הורוביץ
8300 ,ראשון לציון ,165 ,הזהבית
8300 ,ראשון לציון ,1029 ,הזהרון
8300 ,ראשון לציון ,869 ,הזורעים
8300 ,ראשון לציון ,1283 ,הזז
8300 ,ראשון לציון ,254 ,הזית
8300 ,ראשון לציון ,192 ,הזמורה
8300 ,ראשון לציון ,649 ,הזמיר
8300 ,ראשון לציון ,579 ,הזרזיר
8300 ,ראשון לציון ,517 ,החבצלת
8300 ,ראשון לציון ,583 ,החגלה
8300 ,ראשון לציון ,986 ,החדשנות
8300 ,ראשון לציון ,584 ,החוחית
8300 ,ראשון לציון ,537 ,החורש
8300 ,ראשון לציון ,478 ,החיד"א
8300 ,ראשון לציון ,310 ,החייל האלמוני
8300 ,ראשון לציון ,839 ,החילות
8300 ,ראשון לציון ,131 ,החלוץ
8300 ,ראשון לציון ,1150 ,החלוצים
8300 ,ראשון לציון ,1208 ,החליל
8300 ,ראשון לציון ,157 ,החלמונית
8300 ,ראשון לציון ,563 ,החמנית
8300 ,ראשון לציון ,341 ,החפץ חיים
8300 ,ראשון לציון ,515 ,החצב
8300 ,ראשון לציון ,1201 ,החצוצרה
8300 ,ראשון לציון ,605 ,החרוב
8300 ,ראשון לציון ,222 ,החרמון
8300 ,ראשון לציון ,152 ,החרצית
8300 ,ראשון לציון ,507 ,החשמונאים
8300 ,ראשון לציון ,985 ,הטיילת
8300 ,ראשון לציון ,185 ,היוגבים
8300 ,ראשון לציון ,1389 ,היוזמה
8300 ,ראשון לציון ,1348 ,הייז פול
8300 ,ראשון לציון ,149 ,היין
8300 ,ראשון לציון ,803 ,הייסמן
8300 ,ראשון לציון ,160 ,היסמין
8300 ,ראשון לציון ,1005 ,היעדים
8300 ,ראשון לציון ,5136 ,היער
8300 ,ראשון לציון ,191 ,היצהר
8300 ,ראשון לציון ,1386 ,היצירה
8300 ,ראשון לציון ,182 ,היקב
8300 ,ראשון לציון ,156 ,היקינטון
8300 ,ראשון לציון ,235 ,הירדן
8300 ,ראשון לציון ,269 ,הירמוך
8300 ,ראשון לציון ,225 ,הירקון
8300 ,ראשון לציון ,281 ,הירשפלד
8300 ,ראשון לציון ,380 ,הכובש
8300 ,ראשון לציון ,376 ,הכוזרי
8300 ,ראשון לציון ,186 ,הכורמים
8300 ,ראשון לציון ,1209 ,הכינור
8300 ,ראשון לציון ,516 ,הכלנית
8300 ,ראשון לציון ,126 ,הכנסת
8300 ,ראשון לציון ,1027 ,הכסיף
8300 ,ראשון לציון ,600 ,הכרכום
8300 ,ראשון לציון ,305 ,הכרמל
8300 ,ראשון לציון ,169 ,הכרפס
8300 ,ראשון לציון ,933 ,הכשרת הישוב
8300 ,ראשון לציון ,956 ,הכתר
8300 ,ראשון לציון ,967 ,הלבונה
8300 ,ראשון לציון ,1032 ,הלוויתן
8300 ,ראשון לציון ,838 ,הלוחמות
8300 ,ראשון לציון ,224 ,הלוחמים
8300 ,ראשון לציון ,168 ,הלוטם
8300 ,ראשון לציון ,825 ,הלוי יוסף
8300 ,ראשון לציון ,510 ,הלולב
8300 ,ראשון לציון ,627 ,הליבנה
8300 ,ראשון לציון ,5143 ,הלימון
8300 ,ראשון לציון ,378 ,הלל נפתלי
8300 ,ראשון לציון ,329 ,הלפרין
8300 ,ראשון לציון ,896 ,המאה ועשרים
8300 ,ראשון לציון ,926 ,המגילה
8300 ,ראשון לציון ,1380 ,המדע
8300 ,ראשון לציון ,672 ,המהר"ל מפראג
8300 ,ראשון לציון ,1008 ,המו"פ
8300 ,ראשון לציון ,5147 ,המוכתר
8300 ,ראשון לציון ,455 ,המוסיקה
8300 ,ראשון לציון ,5164 ,המורה אברהם
8300 ,ראשון לציון ,170 ,המורן
8300 ,ראשון לציון ,5118 ,המושבה
8300 ,ראשון לציון ,468 ,המחנות העולים
8300 ,ראשון לציון ,900 ,המיזם
8300 ,ראשון לציון ,5114 ,המייסדים
8300 ,ראשון לציון ,1014 ,המלחים
8300 ,ראשון לציון ,958 ,המלך
8300 ,ראשון לציון ,1007 ,המנהיגות
8300 ,ראשון לציון ,851 ,המעיין
8300 ,ראשון לציון ,645 ,המעפילים
8300 ,ראשון לציון ,1206 ,המפוחית
8300 ,ראשון לציון ,1019 ,המפרש
8300 ,ראשון לציון ,1202 ,המצילתיים
8300 ,ראשון לציון ,161 ,המרגנית
8300 ,ראשון לציון ,1020 ,המשוט
8300 ,ראשון לציון ,706 ,המשורר שלום
8300 ,ראשון לציון ,1279 ,המשוררת רחל
8300 ,ראשון לציון ,205 ,המתנדבים
8300 ,ראשון לציון ,651 ,הנביאים
8300 ,ראשון לציון ,553 ,הנגב
8300 ,ראשון לציון ,1272 ,הנגיד
8300 ,ראשון לציון ,650 ,הנוטרים
8300 ,ראשון לציון ,466 ,הנוער הלאומי
8300 ,ראשון לציון ,464 ,הנוער העובד
8300 ,ראשון לציון ,179 ,הנופר
8300 ,ראשון לציון ,158 ,הנורית
8300 ,ראשון לציון ,554 ,הנח"ל
8300 ,ראשון לציון ,5183 ,הנחלה
8300 ,ראשון לציון ,582 ,הנחליאלי
8300 ,ראשון לציון ,1010 ,הנחשול
8300 ,ראשון לציון ,1018 ,הנמל
8300 ,ראשון לציון ,733 ,הנצי"ב מוולוז'ין
8300 ,ראשון לציון ,154 ,הנרקיס
8300 ,ראשון לציון ,268 ,הס
8300 ,ראשון לציון ,164 ,הסביון
8300 ,ראשון לציון ,150 ,הסוכה
8300 ,ראשון לציון ,155 ,הסחלב
8300 ,ראשון לציון ,163 ,הסיגלית
8300 ,ראשון לציון ,1021 ,הסיפון
8300 ,ראשון לציון ,1040 ,הסירה
8300 ,ראשון לציון ,148 ,הסמדר
8300 ,ראשון לציון ,676 ,הסנהדרין
8300 ,ראשון לציון ,1028 ,הסנפיר
8300 ,ראשון לציון ,166 ,הסתוונית
8300 ,ראשון לציון ,1204 ,העוגב
8300 ,ראשון לציון ,1038 ,העוגן
8300 ,ראשון לציון ,581 ,העורב
8300 ,ראשון לציון ,5120 ,העינב
8300 ,ראשון לציון ,606 ,העליה
8300 ,ראשון לציון ,943 ,העמית יצחק
8300 ,ראשון לציון ,188 ,העסיס
8300 ,ראשון לציון ,578 ,העפרוני
8300 ,ראשון לציון ,5124 ,העצמאות
8300 ,ראשון לציון ,538 ,הערבה
8300 ,ראשון לציון ,570 ,הערמון
8300 ,ראשון לציון ,988 ,הפארק
8300 ,ראשון לציון ,930 ,הפור
8300 ,ראשון לציון ,221 ,הפורצים
8300 ,ראשון לציון ,1036 ,הפזית
8300 ,ראשון לציון ,567 ,הפיקוס
8300 ,ראשון לציון ,5199 ,הפיקוסים
8300 ,ראשון לציון ,623 ,הפלמ"ח
8300 ,ראשון לציון ,1203 ,הפסנתר
8300 ,ראשון לציון ,177 ,הפעמונית
8300 ,ראשון לציון ,153 ,הפרג
8300 ,ראשון לציון ,229 ,הפרדס הראשון
8300 ,ראשון לציון ,513 ,הפרח
8300 ,ראשון לציון ,974 ,הפריגטה
8300 ,ראשון לציון ,609 ,הצאלים
8300 ,ראשון לציון ,159 ,הצבעוני
8300 ,ראשון לציון ,565 ,הצבר
8300 ,ראשון לציון ,1026 ,הצדף
8300 ,ראשון לציון ,462 ,הצופים
8300 ,ראשון לציון ,162 ,הצפורן
8300 ,ראשון לציון ,296 ,הצפירה
8300 ,ראשון לציון ,571 ,הצפצפה
8300 ,ראשון לציון ,1022 ,הקברניט
8300 ,ראשון לציון ,1025 ,הקונכיה
8300 ,ראשון לציון ,1343 ,הקוסם מלובלין
8300 ,ראשון לציון ,580 ,הקוקיה
8300 ,ראשון לציון ,1396 ,הקידמה
8300 ,ראשון לציון ,220 ,הקישון
8300 ,ראשון לציון ,1207 ,הקלרנית
8300 ,ראשון לציון ,643 ,הקנאים
8300 ,ראשון לציון ,6535 ,הקריה החקלאית
8300 ,ראשון לציון ,657 ,הר המוריה
8300 ,ראשון לציון ,658 ,הר סיני
8300 ,ראשון לציון ,239 ,הר ציון
8300 ,ראשון לציון ,1276 ,הרב אונטרמן
8300 ,ראשון לציון ,146 ,הרב איבגי
8300 ,ראשון לציון ,815 ,הרב ברוק
8300 ,ראשון לציון ,541 ,הרב גוטמן צבי
8300 ,ראשון לציון ,1274 ,הרב גורן
8300 ,ראשון לציון ,133 ,הרב גרופי שלום
8300 ,ראשון לציון ,732 ,הרב גרינברג ברוך
8300 ,ראשון לציון ,720 ,הרב דמארי
8300 ,ראשון לציון ,393 ,הרב הרצוג
8300 ,ראשון לציון ,809 ,הרב זינגר
8300 ,ראשון לציון ,619 ,הרב זכריה פנחס
8300 ,ראשון לציון ,137 ,הרב חדד חיים
8300 ,ראשון לציון ,618 ,הרב חיון גדליה
8300 ,ראשון לציון ,497 ,הרב חיים דוד הלוי
8300 ,ראשון לציון ,820 ,הרב חרל"פ
8300 ,ראשון לציון ,399 ,הרב טולידאנו
8300 ,ראשון לציון ,929 ,הרב טייב
8300 ,ראשון לציון ,561 ,הרב טרוביץ שמואל
8300 ,ראשון לציון ,1076 ,הרב יוסף עזרן
8300 ,ראשון לציון ,425 ,הרב ינובסקי
8300 ,ראשון לציון ,5149 ,הרב יעקובוביץ
8300 ,ראשון לציון ,821 ,הרב מימון
8300 ,ראשון לציון ,124 ,הרב מינץ
8300 ,ראשון לציון ,121 ,הרב נורוק
8300 ,ראשון לציון ,535 ,הרב ניסים
8300 ,ראשון לציון ,1275 ,הרב נריה
8300 ,ראשון לציון ,823 ,הרב סובהי
8300 ,ראשון לציון ,351 ,הרב עוזיאל
8300 ,ראשון לציון ,109 ,הרב עוזרי
8300 ,ראשון לציון ,5125 ,הרב צאלח משה
8300 ,ראשון לציון ,407 ,הרב צדוק
8300 ,ראשון לציון ,350 ,הרב קוק
8300 ,ראשון לציון ,354 ,הרב רפפורט
8300 ,ראשון לציון ,494 ,הרב שלום שרעבי
8300 ,ראשון לציון ,5190 ,הרב שלינקה יצחק
8300 ,ראשון לציון ,479 ,הרב שמעוני מרדכי
8300 ,ראשון לציון ,1277 ,הרבי מלובביץ
8300 ,ראשון לציון ,734 ,הרברט סמואל
8300 ,ראשון לציון ,174 ,הרותם
8300 ,ראשון לציון ,237 ,הרימון
8300 ,ראשון לציון ,854 ,הרמטכ"לים
8300 ,ראשון לציון ,1039 ,הרפסודה
8300 ,ראשון לציון ,1303 ,הרץ גוסטב
8300 ,ראשון לציון ,307 ,הרצל
8300 ,ראשון לציון ,601 ,הרקפת
8300 ,ראשון לציון ,1271 ,הרשב"א
8300 ,ראשון לציון ,1351 ,הרשקו אברהם
8300 ,ראשון לציון ,5193 ,הרשקוביץ
8300 ,ראשון לציון ,1023 ,הרשת
8300 ,ראשון לציון ,1381 ,השגשוג
8300 ,ראשון לציון ,249 ,השדה
8300 ,ראשון לציון ,512 ,השומר
8300 ,ראשון לציון ,469 ,השומר הצעיר
8300 ,ראשון לציון ,197 ,השומרון
8300 ,ראשון לציון ,1031 ,השונית
8300 ,ראשון לציון ,106 ,השופטים
8300 ,ראשון לציון ,607 ,השזיף
8300 ,ראשון לציון ,253 ,השחר
8300 ,ראשון לציון ,604 ,השיטה
8300 ,ראשון לציון ,1013 ,השייטים
8300 ,ראשון לציון ,975 ,השייטת
8300 ,ראשון לציון ,1273 ,השירה העברית
8300 ,ראשון לציון ,500 ,השירות המטאורולוגי
8300 ,ראשון לציון ,5141 ,השמורה
8300 ,ראשון לציון ,5132 ,השניים
8300 ,ראשון לציון ,248 ,השקד
8300 ,ראשון לציון ,256 ,השקמה
8300 ,ראשון לציון ,902 ,השר שפירא משה
8300 ,ראשון לציון ,957 ,השרביט
8300 ,ראשון לציון ,193 ,השריג
8300 ,ראשון לציון ,255 ,התאנה
8300 ,ראשון לציון ,260 ,התבור
8300 ,ראשון לציון ,1387 ,התגלית
8300 ,ראשון לציון ,252 ,התומר
8300 ,ראשון לציון ,1210 ,התוף
8300 ,ראשון לציון ,577 ,התור
8300 ,ראשון לציון ,173 ,התורמוס
8300 ,ראשון לציון ,1024 ,התורן
8300 ,ראשון לציון ,573 ,התות
8300 ,ראשון לציון ,1200 ,התזמורת
8300 ,ראשון לציון ,1075 ,התחנה לחקר חקלאות
8300 ,ראשון לציון ,190 ,התירוש
8300 ,ראשון לציון ,945 ,התכלת
8300 ,ראשון לציון ,172 ,התלתן
8300 ,ראשון לציון ,5133 ,התמר
8300 ,ראשון לציון ,648 ,התנאים
8300 ,ראשון לציון ,454 ,התעשיין
8300 ,ראשון לציון ,5126 ,התפוז
8300 ,ראשון לציון ,610 ,התפוח
8300 ,ראשון לציון ,259 ,התקוה
8300 ,ראשון לציון ,251 ,וולפסון
8300 ,ראשון לציון ,418 ,ויגודסקי
8300 ,ראשון לציון ,1365 ,ויזל אלי
8300 ,ראשון לציון ,384 ,וייז סטפן
8300 ,ראשון לציון ,142 ,וילנסקי וולף
8300 ,ראשון לציון ,5154 ,וינברג אברהם
8300 ,ראשון לציון ,412 ,ויניק
8300 ,ראשון לציון ,343 ,ויצברד
8300 ,ראשון לציון ,327 ,ויצמן
8300 ,ראשון לציון ,325 ,ויתקין
8300 ,ראשון לציון ,5196 ,ולד יצחק (איגו)
8300 ,ראשון לציון ,451 ,וקסלר משה
8300 ,ראשון לציון ,1363 ,ורבורג אוטו
8300 ,ראשון לציון ,1341 ,ורמוס הרולד
8300 ,ראשון לציון ,293 ,ורניק ישראל
8300 ,ראשון לציון ,201 ,ז'בוטינסקי
8300 ,ראשון לציון ,520 ,זאב
8300 ,ראשון לציון ,470 ,זבולון המר
8300 ,ראשון לציון ,302 ,זדל
8300 ,ראשון לציון ,272 ,זהבי צבי וצפורה
8300 ,ראשון לציון ,4150 ,זוסמן
8300 ,ראשון לציון ,117 ,זינר דוד ויפה
8300 ,ראשון לציון ,5162 ,זיצר חוה ובנימין
8300 ,ראשון לציון ,783 ,זכרון שרה
8300 ,ראשון לציון ,5157 ,זלצמן אהרון ומנחם
8300 ,ראשון לציון ,1263 ,זמנהוף
8300 ,ראשון לציון ,1333 ,זקס נלי
8300 ,ראשון לציון ,633 ,זרובבל
8300 ,ראשון לציון ,922 ,ח"ן
8300 ,ראשון לציון ,394 ,חב"ד
8300 ,ראשון לציון ,718 ,חבקוק שלום
8300 ,ראשון לציון ,735 ,חברון
8300 ,ראשון לציון ,625 ,חגי הנביא
8300 ,ראשון לציון ,1169 ,חד-נס
8300 ,ראשון לציון ,5156 ,חובב מאיר וצבי
8300 ,ראשון לציון ,936 ,חומה
8300 ,ראשון לציון ,856 ,חומה ומגדל
8300 ,ראשון לציון ,382 ,חזון איש
8300 ,ראשון לציון ,665 ,חזן יעקב
8300 ,ראשון לציון ,1136 ,חטיבה שבע
8300 ,ראשון לציון ,475 ,חטיבת קריתי
8300 ,ראשון לציון ,397 ,חי"ש
8300 ,ראשון לציון ,666 ,חיים בן אפרים
8300 ,ראשון לציון ,739 ,חיים ופניה חיסין
8300 ,ראשון לציון ,831 ,חיים חפר
8300 ,ראשון לציון ,910 ,חיל האויר
8300 ,ראשון לציון ,915 ,חיל ההנדסה
8300 ,ראשון לציון ,939 ,חיל החימוש
8300 ,ראשון לציון ,913 ,חיל הים
8300 ,ראשון לציון ,940 ,חיל המודיעין
8300 ,ראשון לציון ,921 ,חיל הצנחנים
8300 ,ראשון לציון ,941 ,חיל הקשר
8300 ,ראשון לציון ,911 ,חיל הרגלים
8300 ,ראשון לציון ,942 ,חיל הרפואה
8300 ,ראשון לציון ,923 ,חיל השלישות
8300 ,ראשון לציון ,914 ,חיל השריון
8300 ,ראשון לציון ,912 ,חיל התותחנים
8300 ,ראשון לציון ,1225 ,חיספין
8300 ,ראשון לציון ,5158 ,חכם עזרא כהן
8300 ,ראשון לציון ,529 ,חלוצי יסוד המעלה
8300 ,ראשון לציון ,218 ,חלץ
8300 ,ראשון לציון ,944 ,חמ"ד
8300 ,ראשון לציון ,875 ,חמדיה
8300 ,ראשון לציון ,5150 ,חנה זמר
8300 ,ראשון לציון ,874 ,חניתה
8300 ,ראשון לציון ,701 ,חנקין
8300 ,ראשון לציון ,662 ,חסד ואמת
8300 ,ראשון לציון ,1257 ,חסדאי
8300 ,ראשון לציון ,145 ,חסידי אומות העולם
8300 ,ראשון לציון ,761 ,חצור
8300 ,ראשון לציון ,1187 ,חצר-אדר
8300 ,ראשון לציון ,1300 ,חתני פרס נובל
8300 ,ראשון לציון ,715 ,טביב
8300 ,ראשון לציון ,773 ,טבנקין יצחק
8300 ,ראשון לציון ,740 ,טהון
8300 ,ראשון לציון ,5170 ,טובה סנהדראי
8300 ,ראשון לציון ,437 ,טוליפמן
8300 ,ראשון לציון ,742 ,טופורובסקי
8300 ,ראשון לציון ,1052 ,טופז
8300 ,ראשון לציון ,530 ,טיבוביץ יהודה
8300 ,ראשון לציון ,743 ,טיומקין
8300 ,ראשון לציון ,870 ,טירת צבי
8300 ,ראשון לציון ,317 ,טרומפלדור
8300 ,ראשון לציון ,817 ,טרכטנברג
8300 ,ראשון לציון ,214 ,טשרניחובסקי
8300 ,ראשון לציון ,506 ,יאיר
8300 ,ראשון לציון ,5112 ,יאיר דורון
8300 ,ראשון לציון ,1354 ,יאלו רוזלין
8300 ,ראשון לציון ,282 ,יבניאלי
8300 ,ראשון לציון ,1001 ,יברבוים
8300 ,ראשון לציון ,852 ,ידין
8300 ,ראשון לציון ,348 ,יהודה
8300 ,ראשון לציון ,344 ,יהודה הלוי
8300 ,ראשון לציון ,5169 ,יהודה ואביבה מגר
8300 ,ראשון לציון ,345 ,יהודה לייב
8300 ,ראשון לציון ,1044 ,יהלום
8300 ,ראשון לציון ,532 ,יואל כהן
8300 ,ראשון לציון ,587 ,יובלים
8300 ,ראשון לציון ,722 ,יודילוביץ'
8300 ,ראשון לציון ,398 ,יוחנן הסנדלר
8300 ,ראשון לציון ,686 ,יונה הנביא
8300 ,ראשון לציון ,1137 ,יונתן
8300 ,ראשון לציון ,626 ,יוסף הנשיא
8300 ,ראשון לציון ,895 ,יוסף טומי לפיד
8300 ,ראשון לציון ,460 ,יוענה ז'בוטינסקי
8300 ,ראשון לציון ,624 ,יחזקאל הנביא
8300 ,ראשון לציון ,383 ,יל"ג
8300 ,ראשון לציון ,966 ,ילדי טהרן
8300 ,ראשון לציון ,741 ,ילין דוד
8300 ,ראשון לציון ,1177 ,ימית
8300 ,ראשון לציון ,843 ,יעל רום
8300 ,ראשון לציון ,836 ,יעקב בן סירא
8300 ,ראשון לציון ,779 ,יעקב נהמי
8300 ,ראשון לציון ,5171 ,יעקבי שאול
8300 ,ראשון לציון ,438 ,יעקובזון
8300 ,ראשון לציון ,592 ,יערי מאיר
8300 ,ראשון לציון ,5198 ,יפה ירקוני
8300 ,ראשון לציון ,646 ,יפתח
8300 ,ראשון לציון ,5200 ,יצחק שמיר
8300 ,ראשון לציון ,712 ,ירושלים
8300 ,ראשון לציון ,495 ,ירח יעקובי
8300 ,ראשון לציון ,620 ,ירמיהו
8300 ,ראשון לציון ,621 ,ישעיהו
8300 ,ראשון לציון ,534 ,ישראל ופאני
8300 ,ראשון לציון ,901 ,ישראלי שמעון
8300 ,ראשון לציון ,1336 ,כ"ץ סר ברנרד
8300 ,ראשון לציון ,965 ,כביש חולון בת ים
8300 ,ראשון לציון ,789 ,כגן רחל
8300 ,ראשון לציון ,5113 ,כהן אלי
8300 ,ראשון לציון ,108 ,כהן יעקב
8300 ,ראשון לציון ,404 ,כהן מנחם
8300 ,ראשון לציון ,784 ,כהנסקי עדינה
8300 ,ראשון לציון ,916 ,כורש
8300 ,ראשון לציון ,6006 ,כיכר מג"ב
8300 ,ראשון לציון ,6001 ,ככר המיסדים
8300 ,ראשון לציון ,6004 ,ככר העצמאות
8300 ,ראשון לציון ,6005 ,ככר יום-טוב ראובן
8300 ,ראשון לציון ,6003 ,ככר כ"ט בנובמבר
8300 ,ראשון לציון ,6002 ,ככר מודיעין
8300 ,ראשון לציון ,5172 ,כל ישראל חברים
8300 ,ראשון לציון ,669 ,כמוס צדוק ומלכה
8300 ,ראשון לציון ,1162 ,כנף
8300 ,ראשון לציון ,234 ,כנרת
8300 ,ראשון לציון ,1227 ,כפר דרום
8300 ,ראשון לציון ,872 ,כפר חיטים
8300 ,ראשון לציון ,1157 ,כפר חרוב
8300 ,ראשון לציון ,876 ,כפר מסריק
8300 ,ראשון לציון ,526 ,כצלר פייבוש
8300 ,ראשון לציון ,209 ,כצנלסון
8300 ,ראשון לציון ,1368 ,כתר ארם צובא
8300 ,ראשון לציון ,527 ,כתריאל
8300 ,ראשון לציון ,459 ,לאה רבין
8300 ,ראשון לציון ,5173 ,לאונר נעם
8300 ,ראשון לציון ,721 ,לבונטין
8300 ,ראשון לציון ,791 ,לביטוב זהרה
8300 ,ראשון לציון ,723 ,לובמן חביב
8300 ,ראשון לציון ,5129 ,לוז
8300 ,ראשון לציון ,112 ,לוי אשכול
8300 ,ראשון לציון ,744 ,לוי יצחק מברדיצב
8300 ,ראשון לציון ,1360 ,לוי מונטלצ'יני ריטה
8300 ,ראשון לציון ,961 ,לוי משה
8300 ,ראשון לציון ,342 ,לוין אשר
8300 ,ראשון לציון ,524 ,לוין מיכאל וחנה
8300 ,ראשון לציון ,176 ,לוע הארי
8300 ,ראשון לציון ,1334 ,לוריא סלבדור
8300 ,ראשון לציון ,935 ,לזרוב
8300 ,ראשון לציון ,909 ,לח"י
8300 ,ראשון לציון ,964 ,ליבוביץ צבי
8300 ,ראשון לציון ,5179 ,ליבין יוסף וריסיה
8300 ,ראשון לציון ,262 ,לילינבלום
8300 ,ראשון לציון ,562 ,לילך
8300 ,ראשון לציון ,5142 ,ליפשיץ שלמה לייב
8300 ,ראשון לציון ,134 ,לישנסקי יוסף
8300 ,ראשון לציון ,1265 ,למדן
8300 ,ראשון לציון ,443 ,לנדאו חיים
8300 ,ראשון לציון ,1304 ,לנדאו לב דוידוביץ
8300 ,ראשון לציון ,853 ,לסקוב
8300 ,ראשון לציון ,1045 ,לשם
8300 ,ראשון לציון ,846 ,מאיר דגן
8300 ,ראשון לציון ,484 ,מאיר דיזנגוף
8300 ,ראשון לציון ,724 ,מאירוביץ
8300 ,ראשון לציון ,226 ,מאפו
8300 ,ראשון לציון ,1165 ,מבוא חמה
8300 ,ראשון לציון ,693 ,מבוא ישורון
8300 ,ראשון לציון ,1140 ,מבואות חרמון
8300 ,ראשון לציון ,1074 ,מבצע אביב נעורים
8300 ,ראשון לציון ,1069 ,מבצע ברוש
8300 ,ראשון לציון ,1068 ,מבצע ברק
8300 ,ראשון לציון ,1073 ,מבצע דין וחשבון
8300 ,ראשון לציון ,1058 ,מבצע דני
8300 ,ראשון לציון ,1059 ,מבצע הראל
8300 ,ראשון לציון ,1072 ,מבצע חומת מגן
8300 ,ראשון לציון ,1060 ,מבצע חורב
8300 ,ראשון לציון ,1071 ,מבצע חירם
8300 ,ראשון לציון ,1070 ,מבצע יואב
8300 ,ראשון לציון ,1064 ,מבצע יונתן
8300 ,ראשון לציון ,1065 ,מבצע ליטאני
8300 ,ראשון לציון ,1047 ,מבצע משה
8300 ,ראשון לציון ,978 ,מבצע נועה
8300 ,ראשון לציון ,1061 ,מבצע נחשון
8300 ,ראשון לציון ,1063 ,מבצע עובדה
8300 ,ראשון לציון ,1067 ,מבצע ענבי זעם
8300 ,ראשון לציון ,283 ,מבצע קדש
8300 ,ראשון לציון ,1012 ,מבצע שלמה
8300 ,ראשון לציון ,1066 ,מבצע תמוז
8300 ,ראשון לציון ,1017 ,מגדלור
8300 ,ראשון לציון ,1361 ,מודליאני פרנקו
8300 ,ראשון לציון ,338 ,מוהליבר
8300 ,ראשון לציון ,167 ,מוטה גור
8300 ,ראשון לציון ,5178 ,מוליק (שמואל) איל
8300 ,ראשון לציון ,355 ,מולכו שלמה
8300 ,ראשון לציון ,320 ,מונטיפיורי
8300 ,ראשון לציון ,717 ,מוסאל יצחק
8300 ,ראשון לציון ,1286 ,מוסינזון יגאל
8300 ,ראשון לציון ,801 ,מוצקין
8300 ,ראשון לציון ,951 ,מור
8300 ,ראשון לציון ,518 ,מורדי הגטאות
8300 ,ראשון לציון ,1306 ,מוריי גל-מן
8300 ,ראשון לציון ,1252 ,מורשת ישראל
8300 ,ראשון לציון ,1383 ,מושביץ
8300 ,ראשון לציון ,522 ,מזיא
8300 ,ראשון לציון ,938 ,מזל אליעזר
8300 ,ראשון לציון ,371 ,מזרחי משה
8300 ,ראשון לציון ,749 ,מחלי הלוי
8300 ,ראשון לציון ,6500 ,מחנה רביבים
8300 ,ראשון לציון ,878 ,מחניים
8300 ,ראשון לציון ,5165 ,מידן עמירם
8300 ,ראשון לציון ,284 ,מיכה יוסף
8300 ,ראשון לציון ,491 ,מיכואלס שלמה
8300 ,ראשון לציון ,5167 ,מילר צבי
8300 ,ראשון לציון ,1151 ,מיצר
8300 ,ראשון לציון ,5160 ,מירה גל
8300 ,ראשון לציון ,196 ,מירון
8300 ,ראשון לציון ,492 ,מיש"ר
8300 ,ראשון לציון ,467 ,מכבי צעיר
8300 ,ראשון לציון ,637 ,מלאכי
8300 ,ראשון לציון ,1346 ,מלך הגשם
8300 ,ראשון לציון ,113 ,מלכי ישראל
8300 ,ראשון לציון ,5186 ,מלל ניסים
8300 ,ראשון לציון ,294 ,מן גרשון
8300 ,ראשון לציון ,232 ,מנדלי
8300 ,ראשון לציון ,1290 ,מנור אהוד
8300 ,ראשון לציון ,887 ,מסילות
8300 ,ראשון לציון ,6580 ,מעברת נחלת יהודה
8300 ,ראשון לציון ,1301 ,מעגל השלום
8300 ,ראשון לציון ,882 ,מעוז חיים
8300 ,ראשון לציון ,750 ,מעונות ברכה
8300 ,ראשון לציון ,1143 ,מעלה גמלא
8300 ,ראשון לציון ,883 ,מעלה החמישה
8300 ,ראשון לציון ,765 ,מענית
8300 ,ראשון לציון ,5135 ,מעפילי אקסודוס
8300 ,ראשון לציון ,638 ,מעפילי מרוקו
8300 ,ראשון לציון ,614 ,מעפילי קפריסין
8300 ,ראשון לציון ,511 ,מצדה
8300 ,ראשון לציון ,474 ,מקלף
8300 ,ראשון לציון ,276 ,מרבד הקסמים
8300 ,ראשון לציון ,379 ,מרגולין
8300 ,ראשון לציון ,917 ,מרדכי היהודי
8300 ,ראשון לציון ,1145 ,מרום גולן
8300 ,ראשון לציון ,1280 ,מרים
8300 ,ראשון לציון ,6550 ,מרכז העיר
8300 ,ראשון לציון ,6598 ,מרכז עסקים שורק
8300 ,ראשון לציון ,6551 ,מרכז ראשון (מערב)
8300 ,ראשון לציון ,432 ,מרקוביץ אברהם אהרון
8300 ,ראשון לציון ,1312 ,מרקוביץ הרי
8300 ,ראשון לציון ,1003 ,מרשתת
8300 ,ראשון לציון ,670 ,משה בן נחמן
8300 ,ראשון לציון ,471 ,משואות יצחק
8300 ,ראשון לציון ,119 ,משורר השואה
8300 ,ראשון לציון ,1364 ,משחק העיניים
8300 ,ראשון לציון ,408 ,משמר הירדן
8300 ,ראשון לציון ,884 ,משמר השלושה
8300 ,ראשון לציון ,590 ,משעול אדווה
8300 ,ראשון לציון ,574 ,משעול אשד
8300 ,ראשון לציון ,1228 ,משעול בריכת רם
8300 ,ראשון לציון ,1358 ,משעול הכימיה
8300 ,ראשון לציון ,1357 ,משעול הכלכלה
8300 ,ראשון לציון ,1359 ,משעול הספרות
8300 ,ראשון לציון ,1302 ,משעול הפיסיקה
8300 ,ראשון לציון ,1327 ,משעול הרפואה
8300 ,ראשון לציון ,1171 ,משעול זוויתן
8300 ,ראשון לציון ,690 ,משעול יזרעאל
8300 ,ראשון לציון ,689 ,משעול כנען
8300 ,ראשון לציון ,5168 ,משעול משה ובנימין
8300 ,ראשון לציון ,1129 ,משעול משושים
8300 ,ראשון לציון ,575 ,משעול פלג
8300 ,ראשון לציון ,1085 ,מתחם היקב
8300 ,ראשון לציון ,6513 ,מתחם צוריאל
8300 ,ראשון לציון ,6595 ,נאות אשלים
8300 ,ראשון לציון ,1164 ,נאות גולן
8300 ,ראשון לציון ,1179 ,נאות סיני
8300 ,ראשון לציון ,6568 ,נאות שקמה
8300 ,ראשון לציון ,1174 ,נביעות
8300 ,ראשון לציון ,857 ,נגבה
8300 ,ראשון לציון ,811 ,נהריים
8300 ,ראשון לציון ,1158 ,נוב
8300 ,ראשון לציון ,6524 ,נוה הדרים
8300 ,ראשון לציון ,6526 ,נוה זאב
8300 ,ראשון לציון ,6516 ,נוה מרדכי
8300 ,ראשון לציון ,729 ,נוה ציון
8300 ,ראשון לציון ,1141 ,נווה אטי"ב
8300 ,ראשון לציון ,1190 ,נווה דקלים
8300 ,ראשון לציון ,6514 ,נווה הלל
8300 ,ראשון לציון ,6579 ,נווה ים
8300 ,ראשון לציון ,725 ,נורדאו
8300 ,ראשון לציון ,952 ,נח מוזס
8300 ,ראשון לציון ,1188 ,נח"ל ים
8300 ,ראשון לציון ,1142 ,נח"ל נמרוד
8300 ,ראשון לציון ,264 ,נחל שורק
8300 ,ראשון לציון ,6590 ,נחלת יהודה
8300 ,ראשון לציון ,349 ,נחמה
8300 ,ראשון לציון ,5176 ,נחמוני
8300 ,ראשון לציון ,499 ,נחמיאס יעקב
8300 ,ראשון לציון ,611 ,נחמיה
8300 ,ראשון לציון ,1221 ,נטור
8300 ,ראשון לציון ,318 ,ניל"י
8300 ,ראשון לציון ,1175 ,ניר אברהם
8300 ,ראשון לציון ,6559 ,ניר בנים
8300 ,ראשון לציון ,877 ,ניר דוד
8300 ,ראשון לציון ,767 ,נירים
8300 ,ראשון לציון ,485 ,נסיה
8300 ,ראשון לציון ,1186 ,נעמה
8300 ,ראשון לציון ,819 ,נעמן
8300 ,ראשון לציון ,766 ,נצר סירני
8300 ,ראשון לציון ,1182 ,נתיב העשרה
8300 ,ראשון לציון ,140 ,נתן שלמה
8300 ,ראשון לציון ,1339 ,נתנס דניאל
8300 ,ראשון לציון ,559 ,סגיס יהודה
8300 ,ראשון לציון ,426 ,סגל מרדכי יואל
8300 ,ראשון לציון ,323 ,סולד
8300 ,ראשון לציון ,1322 ,סולו רוברט
8300 ,ראשון לציון ,427 ,סופר יעקב
8300 ,ראשון לציון ,728 ,סוקולוב
8300 ,ראשון לציון ,447 ,סחרוב דוד
8300 ,ראשון לציון ,639 ,סטרומה
8300 ,ראשון לציון ,5185 ,סיטרמן (מיכאל)
8300 ,ראשון לציון ,128 ,סילבר
8300 ,ראשון לציון ,1314 ,סימון הרברט
8300 ,ראשון לציון ,760 ,סימונובסקי משה
8300 ,ראשון לציון ,1362 ,סיפורי אגדות
8300 ,ראשון לציון ,770 ,סירקין נחמן
8300 ,ראשון לציון ,5151 ,סלומון
8300 ,ראשון לציון ,753 ,סמ בנימין
8300 ,ראשון לציון ,306 ,סמ בצלאל
8300 ,ראשון לציון ,477 ,סמ ברקוביץ
8300 ,ראשון לציון ,762 ,סמ גן נחום
8300 ,ראשון לציון ,757 ,סמ הרעות
8300 ,ראשון לציון ,1309 ,סמואלסון פול
8300 ,ראשון לציון ,703 ,סמילנסקי
8300 ,ראשון לציון ,413 ,סניור
8300 ,ראשון לציון ,807 ,סנש חנה
8300 ,ראשון לציון ,640 ,סעדיה גאון
8300 ,ראשון לציון ,130 ,ספינת השלושה
8300 ,ראשון לציון ,904 ,ספיר יוסף
8300 ,ראשון לציון ,127 ,עגנון ש"י
8300 ,ראשון לציון ,5184 ,עדה
8300 ,ראשון לציון ,656 ,עובדיה
8300 ,ראשון לציון ,832 ,עוזי חיטמן
8300 ,ראשון לציון ,247 ,עולי הגרדום
8300 ,ראשון לציון ,5194 ,עומר דבורה
8300 ,ראשון לציון ,595 ,עזר(ויצמן)
8300 ,ראשון לציון ,613 ,עזרא
8300 ,ראשון לציון ,102 ,עזרה ובצרון
8300 ,ראשון לציון ,361 ,עטרות
8300 ,ראשון לציון ,946 ,עטרת זהב
8300 ,ראשון לציון ,588 ,עיינות
8300 ,ראשון לציון ,885 ,עין גב
8300 ,ראשון לציון ,763 ,עין דור
8300 ,ראשון לציון ,886 ,עין המפרץ
8300 ,ראשון לציון ,764 ,עין הנצי"ב
8300 ,ראשון לציון ,312 ,עין הקורא
8300 ,ראשון לציון ,1152 ,עין זיוון
8300 ,ראשון לציון ,368 ,עין צורים
8300 ,ראשון לציון ,805 ,על כנפי נשרים
8300 ,ראשון לציון ,265 ,עלומים
8300 ,ראשון לציון ,458 ,עליזה בגין
8300 ,ראשון לציון ,445 ,עלית הנוער
8300 ,ראשון לציון ,685 ,עמוס הנביא
8300 ,ראשון לציון ,880 ,עמיר
8300 ,ראשון לציון ,5102 ,עמנואל (רינגלבלום)
8300 ,ראשון לציון ,697 ,עמנואלה בן יעקב
8300 ,ראשון לציון ,1053 ,ענבר
8300 ,ראשון לציון ,5017 ,עפרה חזה
8300 ,ראשון לציון ,1189 ,עצמונה
8300 ,ראשון לציון ,655 ,עראקי שלמה
8300 ,ראשון לציון ,954 ,פאול גרונינגר
8300 ,ראשון לציון ,309 ,פאר זלקינד
8300 ,ראשון לציון ,6562 ,פארק אגמי ראשונים
8300 ,ראשון לציון ,1319 ,פוגל רוברט
8300 ,ראשון לציון ,101 ,פוזננסקי מנחם
8300 ,ראשון לציון ,711 ,פוחצ'בסקי מיכל
8300 ,ראשון לציון ,457 ,פולה בן גוריון
8300 ,ראשון לציון ,423 ,פופל מרדכי
8300 ,ראשון לציון ,375 ,פורת אורי
8300 ,ראשון לציון ,642 ,פטריה
8300 ,ראשון לציון ,298 ,פיין בנימין
8300 ,ראשון לציון ,547 ,פיין נחמן
8300 ,ראשון לציון ,1305 ,פיינמן ריצארד
8300 ,ראשון לציון ,533 ,פייס
8300 ,ראשון לציון ,339 ,פינברג יוסף
8300 ,ראשון לציון ,333 ,פינס
8300 ,ראשון לציון ,593 ,פינשטיין יונה צבי
8300 ,ראשון לציון ,402 ,פיק"א
8300 ,ראשון לציון ,816 ,פישלזון
8300 ,ראשון לציון ,1335 ,פישר אדמונד
8300 ,ראשון לציון ,840 ,פלוגות החפרים
8300 ,ראשון לציון ,434 ,פלוטיצקי
8300 ,ראשון לציון ,962 ,פלטין נפתלי
8300 ,ראשון לציון ,5188 ,פליישר חיה
8300 ,ראשון לציון ,5189 ,פליציה קראי
8300 ,ראשון לציון ,1316 ,פנזיאס ארנו
8300 ,ראשון לציון ,442 ,פנחסוביץ
8300 ,ראשון לציון ,422 ,פנינה ומשה
8300 ,ראשון לציון ,1050 ,פנינת הים
8300 ,ראשון לציון ,905 ,פנקס דוד
8300 ,ראשון לציון ,1331 ,פסטרנק בוריס
8300 ,ראשון לציון ,414 ,פרוג שמעון
8300 ,ראשון לציון ,971 ,פרופ' חבוט
8300 ,ראשון לציון ,263 ,פרופ' נחום
8300 ,ראשון לציון ,774 ,פרי יעקב
8300 ,ראשון לציון ,1176 ,פרי-גן
8300 ,ראשון לציון ,1310 ,פרידמן מילטון
8300 ,ראשון לציון ,410 ,פרימן יעקב
8300 ,ראשון לציון ,322 ,פרישמן
8300 ,ראשון לציון ,782 ,פרנק אנה
8300 ,ראשון לציון ,347 ,פרנק צבי
8300 ,ראשון לציון ,893 ,פרנקל
8300 ,ראשון לציון ,1366 ,פרס שמעון
8300 ,ראשון לציון ,759 ,פרסול נעמי
8300 ,ראשון לציון ,230 ,פרץ
8300 ,ראשון לציון ,436 ,פרשקובסקי
8300 ,ראשון לציון ,346 ,צ"ג בנות
8300 ,ראשון לציון ,1350 ,צ'חנובר אהרן
8300 ,ראשון לציון ,285 ,צ'לנוב
8300 ,ראשון לציון ,114 ,צייטלין
8300 ,ראשון לציון ,246 ,צלליכין
8300 ,ראשון לציון ,439 ,צמח
8300 ,ראשון לציון ,5146 ,צמיר אלימלך
8300 ,ראשון לציון ,687 ,צפניה הנביא
8300 ,ראשון לציון ,751 ,צרנוב יצחק
8300 ,ראשון לציון ,556 ,קאליב
8300 ,ראשון לציון ,1332 ,קאנטי אליאס
8300 ,ראשון לציון ,1353 ,קאסין רנה
8300 ,ראשון לציון ,1260 ,קאפח
8300 ,ראשון לציון ,357 ,קארו יוסף
8300 ,ראשון לציון ,5127 ,קבוץ גלויות
8300 ,ראשון לציון ,1183 ,קדמת סיני
8300 ,ראשון לציון ,1144 ,קדמת צבי
8300 ,ראשון לציון ,6512 ,קדמת ראשון
8300 ,ראשון לציון ,138 ,קהילת ניו ג'רסי
8300 ,ראשון לציון ,391 ,קוזי יוסף
8300 ,ראשון לציון ,1320 ,קוזנץ שמעון
8300 ,ראשון לציון ,557 ,קוצר אריה
8300 ,ראשון לציון ,1081 ,קורל
8300 ,ראשון לציון ,695 ,קורנית
8300 ,ראשון לציון ,135 ,קורצ'ק יאנוש
8300 ,ראשון לציון ,453 ,קזושנר אברהם
8300 ,ראשון לציון ,386 ,קידוש השם
8300 ,ראשון לציון ,1352 ,קיסינג'ר הנרי
8300 ,ראשון לציון ,1285 ,קיפניס
8300 ,ראשון לציון ,683 ,קירז'נר
8300 ,ראשון לציון ,519 ,קלאוזנר יוסף
8300 ,ראשון לציון ,1326 ,קלוג אהרון
8300 ,ראשון לציון ,531 ,קליביצקי
8300 ,ראשון לציון ,1318 ,קליין לורנץ
8300 ,ראשון לציון ,1278 ,קלישר
8300 ,ראשון לציון ,543 ,קלמן
8300 ,ראשון לציון ,1148 ,קלע
8300 ,ראשון לציון ,673 ,קמפינסקי
8300 ,ראשון לציון ,456 ,קמרון אברהם
8300 ,ראשון לציון ,1311 ,קנטורוביץ ליאוניד
8300 ,ראשון לציון ,931 ,קנר
8300 ,ראשון לציון ,208 ,קפלן
8300 ,ראשון לציון ,286 ,קפלנסקי
8300 ,ראשון לציון ,1161 ,קצרין
8300 ,ראשון לציון ,704 ,קרון אהרון
8300 ,ראשון לציון ,1082 ,קריסטל
8300 ,ראשון לציון ,6578 ,קרית גנים
8300 ,ראשון לציון ,6574 ,קרית הלאום
8300 ,ראשון לציון ,6567 ,קרית חתני פרס נובל
8300 ,ראשון לציון ,6571 ,קרית כורש
8300 ,ראשון לציון ,6569 ,קרית כרמים
8300 ,ראשון לציון ,277 ,קרית ספר
8300 ,ראשון לציון ,6517 ,קרית קאליב
8300 ,ראשון לציון ,6572 ,קרית ראשון
8300 ,ראשון לציון ,6530 ,קרית שמחה
8300 ,ראשון לציון ,332 ,קרל נטר
8300 ,ראשון לציון ,754 ,קרלן פרץ
8300 ,ראשון לציון ,331 ,קרן היסוד
8300 ,ראשון לציון ,330 ,קרן קימת לישראל
8300 ,ראשון לציון ,417 ,קרצ'בסקי
8300 ,ראשון לציון ,1139 ,קשת
8300 ,ראשון לציון ,597 ,ראב"ע
8300 ,ראשון לציון ,299 ,ראובן
8300 ,ראשון לציון ,421 ,ראובן ובת שבע
8300 ,ראשון לציון ,490 ,ראול ולנברג
8300 ,ראשון לציון ,661 ,ראם
8300 ,ראשון לציון ,9000 ,ראשון לציון
8300 ,ראשון לציון ,369 ,רבדים
8300 ,ראשון לציון ,1307 ,רבי איזידור יצחק
8300 ,ראשון לציון ,634 ,רבי טרפון
8300 ,ראשון לציון ,671 ,רבי יהודה הנשיא
8300 ,ראשון לציון ,5177 ,רבי יוסף בוכריץ
8300 ,ראשון לציון ,636 ,רבי מאיר
8300 ,ראשון לציון ,5144 ,רבי משה כלפון הכהן
8300 ,ראשון לציון ,599 ,רבי נחמיה מוטא
8300 ,ראשון לציון ,641 ,רבי עקיבא
8300 ,ראשון לציון ,1056 ,רביד
8300 ,ראשון לציון ,542 ,רבינוביץ שמשון
8300 ,ראשון לציון ,647 ,רבנו תם
8300 ,ראשון לציון ,664 ,רגב מרדכי
8300 ,ראשון לציון ,481 ,רגוניס יהודה
8300 ,ראשון לציון ,617 ,רד"ק
8300 ,ראשון לציון ,788 ,רובינא חנה
8300 ,ראשון לציון ,772 ,רובינשטיין ארתור
8300 ,ראשון לציון ,960 ,רוז'נסקי מרדכי
8300 ,ראשון לציון ,280 ,רוזין
8300 ,ראשון לציון ,308 ,רוזנטל
8300 ,ראשון לציון ,480 ,רוזנשטיין מרדכי
8300 ,ראשון לציון ,274 ,רוט יעקב
8300 ,ראשון לציון ,804 ,רוטנברג
8300 ,ראשון לציון ,301 ,רוטשילד
8300 ,ראשון לציון ,291 ,רויז יוסף
8300 ,ראשון לציון ,549 ,רון חיים
8300 ,ראשון לציון ,746 ,רופין
8300 ,ראשון לציון ,745 ,רוקח
8300 ,ראשון לציון ,894 ,רזיאל דוד
8300 ,ראשון לציון ,428 ,רח 461
8300 ,ראשון לציון ,1390 ,רח 272
8300 ,ראשון לציון ,1391 ,רח 275
8300 ,ראשון לציון ,777 ,רחל ינאית בן צבי
8300 ,ראשון לציון ,1254 ,ריב"ל
8300 ,ראשון לציון ,5166 ,ריינגולד חנה
8300 ,ראשון לציון ,709 ,ריינס
8300 ,ראשון לציון ,781 ,רייק חביבה
8300 ,ראשון לציון ,1315 ,ריכטר ברטון
8300 ,ראשון לציון ,363 ,רמב"ם
8300 ,ראשון לציון ,1163 ,רמות
8300 ,ראשון לציון ,233 ,רמז
8300 ,ראשון לציון ,1255 ,רמח"ל
8300 ,ראשון לציון ,1153 ,רמת מגשימים
8300 ,ראשון לציון ,450 ,רפאל אריה
8300 ,ראשון לציון ,594 ,רפול
8300 ,ראשון לציון ,1184 ,רפידים
8300 ,ראשון לציון ,365 ,רש"י
8300 ,ראשון לציון ,6556 ,ש בולגרים
8300 ,ראשון לציון ,373 ,ש בן ציון
8300 ,ראשון לציון ,6518 ,ש גורדון
8300 ,ראשון לציון ,6573 ,ש גזית
8300 ,ראשון לציון ,6583 ,ש חדש- נחלת יהודה
8300 ,ראשון לציון ,6543 ,ש חסכון
8300 ,ראשון לציון ,6503 ,ש מיבדן
8300 ,ראשון לציון ,6520 ,ש משפחות צריפין
8300 ,ראשון לציון ,6521 ,ש נוה עובד
8300 ,ראשון לציון ,6558 ,ש נוה פועלים
8300 ,ראשון לציון ,6570 ,ש נווה דקלים
8300 ,ראשון לציון ,6504 ,ש סלע חדש
8300 ,ראשון לציון ,6544 ,ש עובדים
8300 ,ראשון לציון ,6584 ,ש עובדים נח יהודה
8300 ,ראשון לציון ,6522 ,ש עמידר צריפים
8300 ,ראשון לציון ,6575 ,ש קליפורניה
8300 ,ראשון לציון ,6576 ,ש רכבת
8300 ,ראשון לציון ,6577 ,ש רמת אליהו
8300 ,ראשון לציון ,6506 ,ש שקד
8300 ,ראשון לציון ,116 ,שאול המלך
8300 ,ראשון לציון ,5130 ,שבזי שלום
8300 ,ראשון לציון ,644 ,שבטי ישראל
8300 ,ראשון לציון ,1388 ,שביט
8300 ,ראשון לציון ,483 ,שביל אלונים
8300 ,ראשון לציון ,792 ,שביל האחים ימיני
8300 ,ראשון לציון ,826 ,שבת אחים
8300 ,ראשון לציון ,367 ,שגיא יהודה
8300 ,ראשון לציון ,300 ,שד בן גוריון
8300 ,ראשון לציון ,925 ,שד דיין משה
8300 ,ראשון לציון ,5139 ,שד היובל
8300 ,ראשון לציון ,5145 ,שד הציונות
8300 ,ראשון לציון ,120 ,שד התקומה
8300 ,ראשון לציון ,416 ,שד זמסקי מאיר
8300 ,ראשון לציון ,503 ,שד יעקב
8300 ,ראשון לציון ,195 ,שד יצחק רבין
8300 ,ראשון לציון ,290 ,שד מינסטר
8300 ,ראשון לציון ,288 ,שד מנחם בגין
8300 ,ראשון לציון ,540 ,שד נים
8300 ,ראשון לציון ,970 ,שד רחבעם (זאבי)
8300 ,ראשון לציון ,881 ,שדה אליהו
8300 ,ראשון לציון ,888 ,שדה ורבורג
8300 ,ראשון לציון ,200 ,שדה חמד
8300 ,ראשון לציון ,266 ,שדה יצחק
8300 ,ראשון לציון ,858 ,שדה נחום
8300 ,ראשון לציון ,1180 ,שדות
8300 ,ראשון לציון ,891 ,שדמות דבורה
8300 ,ראשון לציון ,845 ,שדרות ההדרכה
8300 ,ראשון לציון ,987 ,שדרות הטכנולוגיה
8300 ,ראשון לציון ,973 ,שדרות העתיד
8300 ,ראשון לציון ,972 ,שדרות הראשונים
8300 ,ראשון לציון ,837 ,שדרות צריפין
8300 ,ראשון לציון ,271 ,שדרת תש"ח
8300 ,ראשון לציון ,1046 ,שוהם
8300 ,ראשון לציון ,287 ,שוחט מניה
8300 ,ראשון לציון ,5110 ,שולמית אלוני
8300 ,ראשון לציון ,822 ,שולמן צבי
8300 ,ראשון לציון ,147 ,שומרה
8300 ,ראשון לציון ,780 ,שוסהיים חנה
8300 ,ראשון לציון ,919 ,שושן הבירה
8300 ,ראשון לציון ,682 ,שושנה
8300 ,ראשון לציון ,949 ,שושנת יעקב
8300 ,ראשון לציון ,906 ,שז"ר זלמן
8300 ,ראשון לציון ,1226 ,שיאון
8300 ,ראשון לציון ,714 ,שיבת ציון
8300 ,ראשון לציון ,1292 ,שייקה אופיר
8300 ,ראשון לציון ,969 ,שייקה דן
8300 ,ראשון לציון ,6519 ,שיכוני המזרח
8300 ,ראשון לציון ,335 ,שינקין
8300 ,ראשון לציון ,1344 ,שירה
8300 ,ראשון לציון ,6561 ,שכ אביבה
8300 ,ראשון לציון ,6502 ,שכ אברמוביץ'
8300 ,ראשון לציון ,6507 ,שכ אזור התעשיה
8300 ,ראשון לציון ,6541 ,שכ בנות חיל
8300 ,ראשון לציון ,6523 ,שכ ברנשטיין
8300 ,ראשון לציון ,6508 ,שכ גאולה
8300 ,ראשון לציון ,6563 ,שכ גני ראשון
8300 ,ראשון לציון ,6527 ,שכ הפועל המזרחי
8300 ,ראשון לציון ,6510 ,שכ השומר
8300 ,ראשון לציון ,6542 ,שכ כפר אריה
8300 ,ראשון לציון ,6501 ,שכ כצנלסון
8300 ,ראשון לציון ,6528 ,שכ מזרחי
8300 ,ראשון לציון ,6529 ,שכ מישור הנוף
8300 ,ראשון לציון ,6565 ,שכ נוה חוף
8300 ,ראשון לציון ,6525 ,שכ נווה דקלים
8300 ,ראשון לציון ,6587 ,שכ נעורים
8300 ,ראשון לציון ,6585 ,שכ קבוץ
8300 ,ראשון לציון ,6515 ,שכ ראשונים
8300 ,ראשון לציון ,6531 ,שכ רמב"ם
8300 ,ראשון לציון ,6511 ,שכ רמז
8300 ,ראשון לציון ,211 ,שלום עליכם
8300 ,ראשון לציון ,1269 ,שלונסקי
8300 ,ראשון לציון ,377 ,שליט
8300 ,ראשון לציון ,551 ,שלמה
8300 ,ראשון לציון ,107 ,שלמה המלך
8300 ,ראשון לציון ,1090 ,שלמה לוי
8300 ,ראשון לציון ,448 ,שלנג זאב
8300 ,ראשון לציון ,632 ,שמגר בן ענת
8300 ,ראשון לציון ,110 ,שמואל הנביא
8300 ,ראשון לציון ,435 ,שמוטקין בנימין
8300 ,ראשון לציון ,212 ,שמעוני
8300 ,ראשון לציון ,1289 ,שמר נעמי
8300 ,ראשון לציון ,321 ,שמריהו לוין
8300 ,ראשון לציון ,313 ,שמשון
8300 ,ראשון לציון ,586 ,שמשון זליג
8300 ,ראשון לציון ,1054 ,שנהב
8300 ,ראשון לציון ,104 ,שניאור זלמן
8300 ,ראשון לציון ,1382 ,שנקר אריה
8300 ,ראשון לציון ,1149 ,שעל
8300 ,ראשון לציון ,879 ,שער הגולן
8300 ,ראשון לציון ,950 ,שער המלך
8300 ,ראשון לציון ,850 ,שער יפו
8300 ,ראשון לציון ,847 ,שער ירושלים
8300 ,ראשון לציון ,848 ,שער ראשון
8300 ,ראשון לציון ,1002 ,שפטל אריה
8300 ,ראשון לציון ,324 ,שפינוזה
8300 ,ראשון לציון ,334 ,שפירא צבי הרמן
8300 ,ראשון לציון ,273 ,שפרינצק
8300 ,ראשון לציון ,668 ,שקולניק
8300 ,ראשון לציון ,396 ,שרה ואליהו
8300 ,ראשון לציון ,889 ,שרונה
8300 ,ראשון לציון ,684 ,שרוני ברוך
8300 ,ראשון לציון ,525 ,שרירא שמואל
8300 ,ראשון לציון ,523 ,שרף חיים
8300 ,ראשון לציון ,903 ,שרת משה
8300 ,ראשון לציון ,1062 ,ששת הימים
8300 ,ראשון לציון ,125 ,תדהר דוד
8300 ,ראשון לציון ,1345 ,תהילה
8300 ,ראשון לציון ,1251 ,תור הזהב
8300 ,ראשון לציון ,123 ,תורה ועבודה
8300 ,ראשון לציון ,319 ,תל חי
8300 ,ראשון לציון ,890 ,תל יצחק
8300 ,ראשון לציון ,461 ,תנועות הנוער
8300 ,ראשון לציון ,419 ,תנחום
8300 ,ראשון לציון ,405 ,תרזיה
8300 ,ראשון לציון ,315 ,תרמ"ב
8300 ,ראשון לציון ,1048 ,תרשיש
3795 ,רבבה ,108 ,ארץ חמדה
3795 ,רבבה ,111 ,בני אפרים
3795 ,רבבה ,106 ,ברכת הארץ
3795 ,רבבה ,117 ,גבעות עולם
3795 ,רבבה ,113 ,דבורה הנביאה
3795 ,רבבה ,116 ,הגפן
3795 ,רבבה ,118 ,הזית
3795 ,רבבה ,101 ,הר השומרון
3795 ,רבבה ,115 ,הרימון
3795 ,רבבה ,105 ,הררי קדם
3795 ,רבבה ,107 ,טל שמים
3795 ,רבבה ,110 ,יהושע בן נון
3795 ,רבבה ,114 ,יוסף הצדיק
3795 ,רבבה ,112 ,כלב בן יפונה
3795 ,רבבה ,104 ,נחל קנה
3795 ,רבבה ,103 ,נחל שילה
3795 ,רבבה ,102 ,נחל תרצה
3795 ,רבבה ,109 ,נחלת אבות
3795 ,רבבה ,9000 ,רבבה
564 ,רבדים ,9000 ,רבדים
354 ,רביבים ,101 ,אולפן
354 ,רביבים ,102 ,בית ראשון במולדת
354 ,רביבים ,7001 ,חוות אנטיגנוס
354 ,רביבים ,7002 ,חוות נאות
354 ,רביבים ,7003 ,חוות רוטה
354 ,רביבים ,9000 ,רביבים
1225 ,רביד ,9000 ,רביד
390 ,רגבה ,100 ,אמנון ותמר
390 ,רגבה ,102 ,ברוש
390 ,רגבה ,104 ,האורן
390 ,רגבה ,103 ,האירוס
390 ,רגבה ,105 ,האלה
390 ,רגבה ,106 ,האשבל
390 ,רגבה ,107 ,הברקן
390 ,רגבה ,108 ,הגומא
390 ,רגבה ,109 ,הגפן
390 ,רגבה ,110 ,הדבורנית
390 ,רגבה ,111 ,הדולב
390 ,רגבה ,112 ,הדקל
390 ,רגבה ,113 ,ההדס
390 ,רגבה ,114 ,ההרדוף
390 ,רגבה ,115 ,הזית
390 ,רגבה ,116 ,החיטה
390 ,רגבה ,117 ,החלמונית
390 ,רגבה ,118 ,החצב
390 ,רגבה ,119 ,החרצית
390 ,רגבה ,120 ,היקינתון
390 ,רגבה ,121 ,הכלנית
390 ,רגבה ,122 ,הכרכום
390 ,רגבה ,133 ,הלוטם
390 ,רגבה ,123 ,המרווה
390 ,רגבה ,124 ,הצאלון
390 ,רגבה ,125 ,הצבעוני
390 ,רגבה ,126 ,הקורנית
390 ,רגבה ,127 ,הרימון
390 ,רגבה ,128 ,הרקפת
390 ,רגבה ,129 ,השעורה
390 ,רגבה ,130 ,התאנה
390 ,רגבה ,131 ,התמר
390 ,רגבה ,132 ,קינמון
390 ,רגבה ,9000 ,רגבה
444 ,רגבים ,9000 ,רגבים
1161 ,רהט ,385 ,א-נג'את
1161 ,רהט ,397 ,א-ראשד
1161 ,רהט ,396 ,א-רוואד
1161 ,רהט ,392 ,א-רייף
1161 ,רהט ,210 ,אבו בכר אלסדיק
1161 ,רהט ,248 ,אבו הוריירה
1161 ,רהט ,171 ,אבו חניפה
1161 ,רהט ,227 ,אבו טלחה אלאנסארי
1161 ,רהט ,267 ,אבו נואס
1161 ,רהט ,211 ,אבו עביידה אלג'ראח
1161 ,רהט ,6730 ,אבו עוביידה
1161 ,רהט ,305 ,אבו תלול
1161 ,רהט ,306 ,אבן אלרומי
1161 ,רהט ,329 ,אבן בטוטה
1161 ,רהט ,6719 ,אבן ח'לדון
1161 ,רהט ,239 ,אבן חנבל
1161 ,רהט ,6735 ,אבן סינא
1161 ,רהט ,6740 ,אבן רושד
1161 ,רהט ,260 ,אברהים טוקאן
1161 ,רהט ,158 ,אג'נאדין
1161 ,רהט ,136 ,אוחד
1161 ,רהט ,183 ,אום אלקרא
1161 ,רהט ,307 ,אום בטין
1161 ,רהט ,172 ,אום רשרש
1161 ,רהט ,220 ,אזוביר בן אלעואם
1161 ,רהט ,399 ,איבן חיאן
1161 ,רהט ,6774 ,אל אנדלוס
1161 ,רהט ,6704 ,אל אנסאר
1161 ,רהט ,6736 ,אל בדר
1161 ,רהט ,6716 ,אל ביאדר
1161 ,רהט ,6718 ,אל הודא
1161 ,רהט ,6706 ,אל ואחה
1161 ,רהט ,6775 ,אל כראמה
1161 ,רהט ,6713 ,אל מרוא
1161 ,רהט ,6779 ,אל ניל
1161 ,רהט ,6729 ,אל סדרה
1161 ,רהט ,6701 ,אל סלאח
1161 ,רהט ,6731 ,אל סנאבל
1161 ,רהט ,6710 ,אל ספא
1161 ,רהט ,6711 ,אל ע'דיר
1161 ,רהט ,6776 ,אל עבאס
1161 ,רהט ,6777 ,אל פארוק
1161 ,רהט ,6720 ,אל קאדסייה
1161 ,רהט ,6728 ,אל רואבי
1161 ,רהט ,6778 ,אל רשיד
1161 ,רהט ,395 ,אל-אומה
1161 ,רהט ,398 ,אל-ח'רוב
1161 ,רהט ,386 ,אל-מוהנדס
1161 ,רהט ,387 ,אל-מועלם
1161 ,רהט ,388 ,אל-מר'אר
1161 ,רהט ,356 ,אל-עוודה
1161 ,רהט ,393 ,אל-עורוב
1161 ,רהט ,275 ,אלאג'ואד
1161 ,רהט ,287 ,אלאדבא
1161 ,רהט ,340 ,אלאהראם
1161 ,רהט ,365 ,אלאזהר
1161 ,רהט ,6723 ,אלאח'א
1161 ,רהט ,291 ,אלאחווה
1161 ,רהט ,212 ,אלאיובי
1161 ,רהט ,6705 ,אלאימאן
1161 ,רהט ,319 ,אלאמל
1161 ,רהט ,145 ,אלאנבאר
1161 ,רהט ,114 ,אלאנביא
1161 ,רהט ,123 ,אלאנסאן
1161 ,רהט ,100 ,אלאנעאם
1161 ,רהט ,101 ,אלאנפאל
1161 ,רהט ,108 ,אלאסרא
1161 ,רהט ,320 ,אלאעראף
1161 ,רהט ,341 ,אלאקסא
1161 ,רהט ,240 ,אלארדן
1161 ,רהט ,261 ,אלבאדיה
1161 ,רהט ,276 ,אלבואדי
1161 ,רהט ,184 ,אלבוח'ארי
1161 ,רהט ,241 ,אלבחרין
1161 ,רהט ,384 ,אלבטין
1161 ,רהט ,6732 ,אלביאן
1161 ,רהט ,141 ,אלבלד
1161 ,רהט ,159 ,אלבסרה
1161 ,רהט ,366 ,אלבסתאן
1161 ,רהט ,142 ,אלברוג'
1161 ,רהט ,277 ,אלברכה
1161 ,רהט ,343 ,אלבתרא
1161 ,רהט ,299 ,אלג'ולן
1161 ,רהט ,124 ,אלג'ומעה
1161 ,רהט ,231 ,אלג'זאיר
1161 ,רהט ,367 ,אלג'ליל
1161 ,רהט ,146 ,אלג'נדל
1161 ,רהט ,368 ,אלג'עדה
1161 ,רהט ,152 ,אלדאליה
1161 ,רהט ,207 ,אלדוחה
1161 ,רהט ,125 ,אלדח'אן
1161 ,רהט ,344 ,אלהג'רה
1161 ,רהט ,292 ,אלהדיה
1161 ,רהט ,308 ,אלהודג'
1161 ,רהט ,345 ,אלהלאל
1161 ,רהט ,6772 ,אלואדי
1161 ,רהט ,369 ,אלוורד
1161 ,רהט ,160 ,אלוחדאת
1161 ,רהט ,143 ,אלוטן
1161 ,רהט ,346 ,אלופא
1161 ,רהט ,126 ,אלזוחרוף
1161 ,רהט ,120 ,אלזומר
1161 ,רהט ,270 ,אלזייר
1161 ,רהט ,251 ,אלזייתון
1161 ,רהט ,347 ,אלזיתונה
1161 ,רהט ,348 ,אלזמורד
1161 ,רהט ,370 ,אלזנאבק
1161 ,רהט ,371 ,אלזרקא
1161 ,רהט ,282 ,אלח'באז
1161 ,רהט ,257 ,אלח'ד'ר
1161 ,רהט ,295 ,אלח'זאן
1161 ,רהט ,232 ,אלח'יר
1161 ,רהט ,163 ,אלח'ליל
1161 ,רהט ,213 ,אלח'לפאא
1161 ,רהט ,186 ,אלח'נסא
1161 ,רהט ,115 ,אלחג'
1161 ,רהט ,372 ,אלחג'אז
1161 ,רהט ,280 ,אלחדיד
1161 ,רהט ,400 ,אלחוסין
1161 ,רהט ,321 ,אלחורייה
1161 ,רהט ,293 ,אלחיאת
1161 ,רהט ,6770 ,אלחכוך
1161 ,רהט ,322 ,אלחכמה
1161 ,רהט ,6722 ,אלחנאן
1161 ,רהט ,137 ,אלחנדק
1161 ,רהט ,242 ,אלחנפי
1161 ,רהט ,373 ,אלחסבאני
1161 ,רהט ,185 ,אלחרם
1161 ,רהט ,147 ,אלטאיף
1161 ,רהט ,127 ,אלטארק
1161 ,רהט ,134 ,אלטור
1161 ,רהט ,208 ,אלטייב
1161 ,רהט ,179 ,אלטייבה
1161 ,רהט ,153 ,אליאסמין
1161 ,רהט ,168 ,אלימאמה
1161 ,רהט ,228 ,אלימן
1161 ,רהט ,6709 ,אלירמוך
1161 ,רהט ,294 ,אלכאפור
1161 ,רהט ,109 ,אלכהף
1161 ,רהט ,233 ,אלכוית
1161 ,רהט ,243 ,אלכרם
1161 ,רהט ,374 ,אלכרמל
1161 ,רהט ,301 ,אללוד
1161 ,רהט ,102 ,אלמאידה
1161 ,רהט ,221 ,אלמג'ד
1161 ,רהט ,361 ,אלמג'דל
1161 ,רהט ,330 ,אלמדינה אלמונארה
1161 ,רהט ,309 ,אלמהבאש
1161 ,רהט ,375 ,אלמהד
1161 ,רהט ,331 ,אלמוסאואה
1161 ,רהט ,164 ,אלמחבה
1161 ,רהט ,283 ,אלמחטה
1161 ,רהט ,6712 ,אלמידאן
1161 ,רהט ,285 ,אלמיזאן
1161 ,רהט ,349 ,אלמכתבה
1161 ,רהט ,252 ,אלמנאמה
1161 ,רהט ,173 ,אלמנסור
1161 ,רהט ,174 ,אלמערי
1161 ,רהט ,350 ,אלמערפה
1161 ,רהט ,351 ,אלמרכז
1161 ,רהט ,376 ,אלמתנאן
1161 ,רהט ,165 ,אלנאסרה
1161 ,רהט ,110 ,אלנבי אברהים
1161 ,רהט ,103 ,אלנבי הוד
1161 ,רהט ,105 ,אלנבי יוסף
1161 ,רהט ,128 ,אלנבי נוח
1161 ,רהט ,352 ,אלנבע
1161 ,רהט ,6702 ,אלנג'אח
1161 ,רהט ,129 ,אלנג'ם
1161 ,רהט ,6717 ,אלנהדה
1161 ,רהט ,302 ,אלנהר
1161 ,רהט ,6703 ,אלנור
1161 ,רהט ,166 ,אלנח'יל
1161 ,רהט ,111 ,אלנחל
1161 ,רהט ,6725 ,אלנכיל
1161 ,רהט ,187 ,אלנעים
1161 ,רהט ,139 ,אלנצר
1161 ,רהט ,268 ,אלנקב
1161 ,רהט ,154 ,אלנרג'ס
1161 ,רהט ,296 ,אלסבר
1161 ,רהט ,333 ,אלסדאקה
1161 ,רהט ,236 ,אלסחאבה
1161 ,רהט ,289 ,אלסלאם
1161 ,רהט ,334 ,אלסלאמה
1161 ,רהט ,169 ,אלסלאסל
1161 ,רהט ,281 ,אלסנאעה
1161 ,רהט ,155 ,אלסנדיאן
1161 ,רהט ,156 ,אלספסאף
1161 ,רהט ,311 ,אלסר
1161 ,רהט ,188 ,אלע'זאלי
1161 ,רהט ,237 ,אלע'פראן
1161 ,רהט ,262 ,אלע'ררה
1161 ,רהט ,335 ,אלעדל
1161 ,רהט ,336 ,אלעהד
1161 ,רהט ,279 ,אלעומאל
1161 ,רהט ,337 ,אלעטאא
1161 ,רהט ,338 ,אלעפאף
1161 ,רהט ,271 ,אלעראק
1161 ,רהט ,342 ,אלפאתח
1161 ,רהט ,288 ,אלפג'ר
1161 ,רהט ,116 ,אלפורקאן
1161 ,רהט ,6773 ,אלפורת
1161 ,רהט ,6708 ,אלפרדוס
1161 ,רהט ,269 ,אלפרזדק
1161 ,רהט ,130 ,אלפתח
1161 ,רהט ,200 ,אלקאהרה
1161 ,רהט ,382 ,אלקאפלה
1161 ,רהט ,148 ,אלקדר
1161 ,רהט ,197 ,אלקודס
1161 ,רהט ,312 ,אלקווין
1161 ,רהט ,138 ,אלקות'ר
1161 ,רהט ,313 ,אלקיסום
1161 ,רהט ,135 ,אלקלם
1161 ,רהט ,131 ,אלקמר
1161 ,רהט ,339 ,אלקנדיל
1161 ,רהט ,354 ,אלקרואן
1161 ,רהט ,377 ,אלקרונפל
1161 ,רהט ,6721 ,אלראזי
1161 ,רהט ,201 ,אלרבאט
1161 ,רהט ,332 ,אלרביע
1161 ,רהט ,189 ,אלרדואן
1161 ,רהט ,190 ,אלרודה
1161 ,רהט ,284 ,אלרח'אם
1161 ,רהט ,6707 ,אלרחמה
1161 ,רהט ,234 ,אלריאד
1161 ,רהט ,191 ,אלריאן
1161 ,רהט ,157 ,אלריחאן
1161 ,רהט ,355 ,אלרים
1161 ,רהט ,273 ,אלרמלה
1161 ,רהט ,112 ,אלרעד
1161 ,רהט ,362 ,אלשאם
1161 ,רהט ,263 ,אלשאפעי
1161 ,רהט ,238 ,אלשהאמה
1161 ,רהט ,182 ,אלשוערא
1161 ,רהט ,133 ,אלשורא
1161 ,רהט ,144 ,אלשמס
1161 ,רהט ,357 ,אלשרוק
1161 ,רהט ,358 ,אלשרק
1161 ,רהט ,286 ,אלת'גארה
1161 ,רהט ,106 ,אלתובה
1161 ,רהט ,359 ,אלתחריר
1161 ,רהט ,192 ,אלתייאסיר
1161 ,רהט ,149 ,אלתין
1161 ,רהט ,360 ,אלתינה
1161 ,רהט ,193 ,אלתלאוה
1161 ,רהט ,324 ,אלתסאמח
1161 ,רהט ,297 ,אלתעלה
1161 ,רהט ,6715 ,אלתקוא
1161 ,רהט ,222 ,אנס בן מאלכ
1161 ,רהט ,151 ,אספהאן
1161 ,רהט ,353 ,אסקלאטי
1161 ,רהט ,300 ,אסתנבול
1161 ,רהט ,298 ,אקרא
1161 ,רהט ,378 ,אריחא
1161 ,רהט ,391 ,באב אל-עאמוד
1161 ,רהט ,379 ,באבל
1161 ,רהט ,140 ,בדר
1161 ,רהט ,223 ,ביסאן
1161 ,רהט ,175 ,ביר אלסבע
1161 ,רהט ,202 ,בירות
1161 ,רהט ,176 ,בלאל בן רבאח
1161 ,רהט ,380 ,בניאס
1161 ,רהט ,310 ,בע'דאד
1161 ,רהט ,314 ,ברייר
1161 ,רהט ,258 ,ג'בראן ח'ליל ג'בראן
1161 ,רהט ,401 ,ג'בריל
1161 ,רהט ,194 ,ג'דה
1161 ,רהט ,167 ,ג'נין
1161 ,רהט ,214 ,ד'ו אלנורין
1161 ,רהט ,215 ,דובאי
1161 ,רהט ,203 ,דמשק
1161 ,רהט ,363 ,ואדי אלחאסי
1161 ,רהט ,229 ,זיד בן אלארקם
1161 ,רהט ,315 ,זייד בן ת'אבת
1161 ,רהט ,381 ,זמזם
1161 ,רהט ,216 ,ח'אלד בן אלואליד
1161 ,רהט ,303 ,חטין
1161 ,רהט ,177 ,חיפא
1161 ,רהט ,161 ,חלב
1161 ,רהט ,150 ,חנין
1161 ,רהט ,217 ,חסאן בן ת'אבת
1161 ,רהט ,117 ,טאהא
1161 ,רהט ,253 ,טאהא חוסין
1161 ,רהט ,323 ,טארק בן זיאד
1161 ,רהט ,178 ,טבריה
1161 ,רהט ,254 ,טורקיה
1161 ,רהט ,204 ,טראבלוס
1161 ,רהט ,325 ,טרפה בן אלעבד
1161 ,רהט ,316 ,יאסור
1161 ,רהט ,121 ,יאסין
1161 ,רהט ,170 ,יאפא
1161 ,רהט ,389 ,יבנא
1161 ,רהט ,317 ,כוילפה
1161 ,רהט ,264 ,כרכור
1161 ,רהט ,249 ,לובנאן
1161 ,רהט ,118 ,לוקמאן
1161 ,רהט ,250 ,ליביא
1161 ,רהט ,162 ,מואתא
1161 ,רהט ,180 ,מוסלם
1161 ,רהט ,224 ,מועד בן ג'בל
1161 ,רהט ,195 ,מינא
1161 ,רהט ,326 ,מכה
1161 ,רהט ,198 ,מסקט
1161 ,רהט ,245 ,מסר
1161 ,רהט ,218 ,מעאויה בן אביסופיאן
1161 ,רהט ,113 ,מרים אלעד'רא
1161 ,רהט ,265 ,נאבלוס
1161 ,רהט ,327 ,נג'ד
1161 ,רהט ,266 ,נג'יב מחפוז
1161 ,רהט ,364 ,סוריא
1161 ,רהט ,205 ,סנעאא
1161 ,רהט ,219 ,סעד בן אבי וקאס
1161 ,רהט ,390 ,סרפנד
1161 ,רהט ,122 ,ע'אפר
1161 ,רהט ,255 ,ע'רנאטה
1161 ,רהט ,6726 ,עדן
1161 ,רהט ,278 ,עומר אלמוח'תאר
1161 ,רהט ,225 ,עומר בן אלח'טאב
1161 ,רהט ,246 ,עומר בן עבד אלעזיז
1161 ,רהט ,230 ,עות'מאן אבן עפאן
1161 ,רהט ,328 ,עזה
1161 ,רהט ,209 ,עין ג'אלות
1161 ,רהט ,181 ,עכא
1161 ,רהט ,196 ,עלי בן אבי טאלב
1161 ,רהט ,199 ,עמאן
1161 ,רהט ,318 ,עמוריה
1161 ,רהט ,247 ,עמרו בן אלעאס
1161 ,רהט ,304 ,עתלית
1161 ,רהט ,119 ,פאטר
1161 ,רהט ,107 ,פלסטין
1161 ,רהט ,272 ,צלאח אלדין
1161 ,רהט ,290 ,קבאא
1161 ,רהט ,256 ,קורטבה
1161 ,רהט ,226 ,קייס בן אלמלוח
1161 ,רהט ,9000 ,רהט
1161 ,רהט ,274 ,רפח
1161 ,רהט ,6741 ,שכ 41
1161 ,רהט ,6724 ,שכ 23
1161 ,רהט ,6733 ,שכ 35
1161 ,רהט ,6714 ,שכ 6
1161 ,רהט ,6737 ,שכ 16
1161 ,רהט ,6734 ,שכ 36
1161 ,רהט ,6738 ,שכ 56
1161 ,רהט ,6739 ,שכ 37
1161 ,רהט ,6771 ,שכ 58
1161 ,רהט ,383 ,תדמר
1161 ,רהט ,235 ,תונס
1161 ,רהט ,259 ,תופיק אלחכים
2051 ,רווחה ,9000 ,רווחה
2016 ,רוויה ,9000 ,רוויה
1341 ,רוח מדבר ,9000 ,רוח מדבר
362 ,רוחמה ,101 ,בית ראשון במולדת
362 ,רוחמה ,9000 ,רוחמה
539 ,רומאנה ,9000 ,רומאנה
997 ,רומת הייב ,9000 ,רומת הייב
3619 ,רועי ,9000 ,רועי
3782 ,רותם ,9000 ,רותם
854 ,רחוב ,9000 ,רחוב
8400 ,רחובות ,627 ,אבן עזרא
8400 ,רחובות ,724 ,אברבנאל
8400 ,רחובות ,326 ,אברך חנה
8400 ,רחובות ,538 ,אברמסון מירון
8400 ,רחובות ,253 ,אגמון
8400 ,רחובות ,283 ,אגמיה
8400 ,רחובות ,128 ,אדמונית
8400 ,רחובות ,691 ,אהרון מזיא
8400 ,רחובות ,312 ,אהרונוביץ יוסף
8400 ,רחובות ,458 ,אהרוני ישראל
8400 ,רחובות ,768 ,אודם
8400 ,רחובות ,431 ,אוסישקין
8400 ,רחובות ,104 ,אופנהיימר
8400 ,רחובות ,362 ,אורבך חיים
8400 ,רחובות ,573 ,אורי צבי גרינברג
8400 ,רחובות ,587 ,אושה
8400 ,רחובות ,6540 ,אז תעשיה ע"ש רכטמן
8400 ,רחובות ,6568 ,אזורי וייצמן
8400 ,רחובות ,453 ,אחד העם
8400 ,רחובות ,6508 ,אחוזת הנשיא
8400 ,רחובות ,483 ,אחים קיבוביץ
8400 ,רחובות ,244 ,אטד
8400 ,רחובות ,686 ,אידלבאום משה
8400 ,רחובות ,459 ,איזנברג
8400 ,רחובות ,811 ,אייזנר זאב
8400 ,רחובות ,408 ,אינשטיין
8400 ,רחובות ,539 ,אלומות
8400 ,רחובות ,478 ,אליעזר יעקבסון
8400 ,רחובות ,548 ,אלכס יוסף צבי
8400 ,רחובות ,775 ,אלמוג
8400 ,רחובות ,465 ,אלקלעי
8400 ,רחובות ,779 ,אלתרמן נתן
8400 ,רחובות ,152 ,אמנון ותמר
8400 ,רחובות ,547 ,אמרי חיים
8400 ,רחובות ,322 ,אנילביץ
8400 ,רחובות ,809 ,אסתר ואברהם
8400 ,רחובות ,471 ,אפל צבי
8400 ,רחובות ,770 ,ארגמן
8400 ,רחובות ,240 ,ארז
8400 ,רחובות ,714 ,ארלוזורוב
8400 ,רחובות ,359 ,ארליך צבי
8400 ,רחובות ,339 ,ארצי זאב
8400 ,רחובות ,132 ,אשבל
8400 ,רחובות ,712 ,אשד שמעון
8400 ,רחובות ,233 ,אשל
8400 ,רחובות ,220 ,אשרוב
8400 ,רחובות ,6572 ,את ע"ש ב.צ הורוביץ
8400 ,רחובות ,497 ,באר מים חיים
8400 ,רחובות ,303 ,בארי
8400 ,רחובות ,323 ,בוסל
8400 ,רחובות ,675 ,בוסתן
8400 ,רחובות ,460 ,בוסתנאי
8400 ,רחובות ,344 ,בורגין יהושע
8400 ,רחובות ,319 ,בורוכוב
8400 ,רחובות ,694 ,ביאלר קלמן
8400 ,רחובות ,416 ,ביל"ו
8400 ,רחובות ,414 ,בית הפועלים
8400 ,רחובות ,336 ,בכר מיכאל
8400 ,רחובות ,657 ,בלקינד ישראל
8400 ,רחובות ,718 ,בן אהרן יוסף
8400 ,רחובות ,517 ,בן אפרים נפתלי
8400 ,רחובות ,590 ,בן ארי שרגא
8400 ,רחובות ,223 ,בן גוריון
8400 ,רחובות ,683 ,בן דוד פנחס
8400 ,רחובות ,468 ,בן יהודה אליעזר
8400 ,רחובות ,722 ,בן יהודה מנחם
8400 ,רחובות ,603 ,בן ישראל
8400 ,רחובות ,436 ,בני משה
8400 ,רחובות ,442 ,בנימין
8400 ,רחובות ,246 ,בעל התניא
8400 ,רחובות ,367 ,בקמן נח
8400 ,רחובות ,806 ,בקר בועז
8400 ,רחובות ,518 ,בר אילן
8400 ,רחובות ,734 ,בר כוכבא
8400 ,רחובות ,591 ,בר-לב חיים
8400 ,רחובות ,106 ,ברגמן
8400 ,רחובות ,142 ,ברזיל
8400 ,רחובות ,709 ,ברזילי משה
8400 ,רחובות ,534 ,ברמן יעקב
8400 ,רחובות ,432 ,ברנר
8400 ,רחובות ,580 ,ברקת
8400 ,רחובות ,719 ,בשארי אברהם
8400 ,רחובות ,285 ,בשיסט מרדכי
8400 ,רחובות ,155 ,בשמת
8400 ,רחובות ,413 ,גאולה
8400 ,רחובות ,427 ,גבורי ישראל
8400 ,רחובות ,6516 ,גבעת החרובים
8400 ,רחובות ,219 ,גבריאלוב
8400 ,רחובות ,433 ,גדוד 52
8400 ,רחובות ,706 ,גדסי אהרן
8400 ,רחובות ,109 ,גוטהילף שרה ושמואל
8400 ,רחובות ,457 ,גולדברג
8400 ,רחובות ,763 ,גולדה מאיר
8400 ,רחובות ,486 ,גולדין
8400 ,רחובות ,340 ,גולדשטיין
8400 ,רחובות ,611 ,גולומב
8400 ,רחובות ,469 ,גור יהודה
8400 ,רחובות ,461 ,גורדון
8400 ,רחובות ,481 ,גורודיסקי
8400 ,רחובות ,516 ,גוש עציון
8400 ,רחובות ,6542 ,גינות סביון
8400 ,רחובות ,399 ,גינזבורג זלמן
8400 ,רחובות ,205 ,גלגל
8400 ,רחובות ,805 ,גלוסקא זכריה
8400 ,רחובות ,409 ,גלוסקין
8400 ,רחובות ,491 ,גלזר אליהו
8400 ,רחובות ,242 ,גמליאל בן זכאי
8400 ,רחובות ,6528 ,גני הדר
8400 ,רחובות ,6515 ,גני סביון
8400 ,רחובות ,6505 ,גני שילר
8400 ,רחובות ,329 ,גרץ
8400 ,רחובות ,681 ,דגן
8400 ,רחובות ,637 ,דהרי שלום
8400 ,רחובות ,251 ,דובדבן
8400 ,רחובות ,347 ,דובנבוים גרשון
8400 ,רחובות ,314 ,דובנוב
8400 ,רחובות ,225 ,דוד אלעזר
8400 ,רחובות ,294 ,דוידוב חי רחמים
8400 ,רחובות ,284 ,דוכיפת
8400 ,רחובות ,241 ,דולב
8400 ,רחובות ,635 ,דולינסקי
8400 ,רחובות ,762 ,דולצ'ין אריה
8400 ,רחובות ,490 ,דונדיקוב
8400 ,רחובות ,715 ,דור יהודה
8400 ,רחובות ,713 ,דיין שמעון
8400 ,רחובות ,153 ,דליה
8400 ,רחובות ,496 ,דמשק אליעזר
8400 ,רחובות ,335 ,דניאלי עמוס
8400 ,רחובות ,154 ,דפנה
8400 ,רחובות ,231 ,דקל
8400 ,רחובות ,659 ,דרויאן יעקב
8400 ,רחובות ,276 ,דרור
8400 ,רחובות ,376 ,דרך אצ"ל
8400 ,רחובות ,685 ,דרך בן ארי יצחק
8400 ,רחובות ,655 ,דרך בן גרא
8400 ,רחובות ,226 ,דרך גד
8400 ,רחובות ,299 ,דרך החקלאות
8400 ,רחובות ,260 ,דרך הים
8400 ,רחובות ,499 ,דרך ויסגל מאיר
8400 ,רחובות ,304 ,דרך יבנה
8400 ,רחובות ,593 ,דרך ירושלים
8400 ,רחובות ,699 ,דרך כץ יצחק
8400 ,רחובות ,695 ,דרך פסטרנק
8400 ,רחובות ,716 ,הא באייר
8400 ,רחובות ,520 ,האגוז
8400 ,רחובות ,495 ,האדמו"ר מקרעטשניף
8400 ,רחובות ,634 ,האורן
8400 ,רחובות ,430 ,האחים טרבס
8400 ,רחובות ,150 ,האירוסים
8400 ,רחובות ,250 ,האלה
8400 ,רחובות ,255 ,האלוף פרי יעקב
8400 ,רחובות ,429 ,האמהות
8400 ,רחובות ,729 ,האמוראים
8400 ,רחובות ,660 ,האר"י
8400 ,רחובות ,380 ,הבושם
8400 ,רחובות ,245 ,הבן איש חי
8400 ,רחובות ,443 ,הבנים
8400 ,רחובות ,513 ,הבעש"ט
8400 ,רחובות ,550 ,הברוש
8400 ,רחובות ,510 ,הבריגדה
8400 ,רחובות ,744 ,הגאונים
8400 ,רחובות ,780 ,הגולן
8400 ,רחובות ,424 ,הגורן
8400 ,רחובות ,615 ,הגליל
8400 ,רחובות ,782 ,הגלעד
8400 ,רחובות ,307 ,הגפן
8400 ,רחובות ,502 ,הגר"א
8400 ,רחובות ,612 ,הדגניות
8400 ,רחובות ,298 ,הדופיה
8400 ,רחובות ,405 ,הדר
8400 ,רחובות ,511 ,ההגנה
8400 ,רחובות ,624 ,ההדס
8400 ,רחובות ,601 ,ההסתדרות
8400 ,רחובות ,606 ,הוז דב
8400 ,רחובות ,766 ,הולצברג שמחה
8400 ,רחובות ,108 ,הולצמן חיים
8400 ,רחובות ,378 ,הורביץ אלי
8400 ,רחובות ,113 ,הורוביץ צבי
8400 ,רחובות ,745 ,הזוהר
8400 ,רחובות ,306 ,הזית
8400 ,רחובות ,609 ,החבורה
8400 ,רחובות ,309 ,החיטה
8400 ,רחובות ,641 ,החיננית
8400 ,רחובות ,456 ,החלוץ
8400 ,רחובות ,647 ,החצב
8400 ,רחובות ,663 ,החרוב
8400 ,רחובות ,642 ,החרצית
8400 ,רחובות ,705 ,החשמונאים
8400 ,רחובות ,617 ,היסמין
8400 ,רחובות ,618 ,הירדן
8400 ,רחובות ,623 ,הכלניות
8400 ,רחובות ,407 ,הכרם
8400 ,רחובות ,325 ,הלל יעקב
8400 ,רחובות ,101 ,המדע
8400 ,רחובות ,439 ,המלכים
8400 ,רחובות ,196 ,המנוף
8400 ,רחובות ,308 ,המעפיל
8400 ,רחובות ,364 ,המרגנית
8400 ,רחובות ,578 ,המרד
8400 ,רחובות ,441 ,הנביאים
8400 ,רחובות ,509 ,הנוטר
8400 ,רחובות ,645 ,הנורית
8400 ,רחובות ,619 ,הנרקיסים
8400 ,רחובות ,463 ,הנשיא הראשון
8400 ,רחובות ,313 ,הס
8400 ,רחובות ,646 ,הסביון
8400 ,רחובות ,621 ,הסגליות
8400 ,רחובות ,151 ,הסמדר
8400 ,רחובות ,586 ,הסנהדרין
8400 ,רחובות ,455 ,העבודה
8400 ,רחובות ,644 ,העירית
8400 ,רחובות ,544 ,העליה
8400 ,רחובות ,614 ,העמק
8400 ,רחובות ,295 ,העצמאות
8400 ,רחובות ,640 ,הערבה
8400 ,רחובות ,610 ,הפועל הצעיר
8400 ,רחובות ,129 ,הפקולטה לחקלאות
8400 ,רחובות ,643 ,הפרג
8400 ,רחובות ,577 ,הפרטיזנים
8400 ,רחובות ,554 ,הצבעוני
8400 ,רחובות ,296 ,הציונות
8400 ,רחובות ,512 ,הצנחנים
8400 ,רחובות ,297 ,הקטיף
8400 ,רחובות ,266 ,הר אביטל
8400 ,רחובות ,263 ,הר ארבל
8400 ,רחובות ,289 ,הר ארדון
8400 ,רחובות ,265 ,הר גלבוע
8400 ,רחובות ,291 ,הר גריזים
8400 ,רחובות ,288 ,הר הזיתים
8400 ,רחובות ,262 ,הר המוריה
8400 ,רחובות ,287 ,הר הנגב
8400 ,רחובות ,261 ,הר הצופים
8400 ,רחובות ,264 ,הר חרמון
8400 ,רחובות ,268 ,הר כנען
8400 ,רחובות ,290 ,הר כרכום
8400 ,רחובות ,269 ,הר מירון
8400 ,רחובות ,272 ,הר נבו
8400 ,רחובות ,292 ,הר סיני
8400 ,רחובות ,271 ,הר ציון
8400 ,רחובות ,286 ,הר שלמה
8400 ,רחובות ,267 ,הר תבור
8400 ,רחובות ,450 ,הרא"ה
8400 ,רחובות ,501 ,הרא"ז
8400 ,רחובות ,760 ,הראל מרדכי
8400 ,רחובות ,484 ,הרב בר שאול
8400 ,רחובות ,565 ,הרב גרז זבולון
8400 ,רחובות ,710 ,הרב דוד ישראל
8400 ,רחובות ,521 ,הרב הרצוג
8400 ,רחובות ,551 ,הרב מאור יוסף
8400 ,רחובות ,735 ,הרב מאיר
8400 ,רחובות ,749 ,הרב מדר זכריה
8400 ,רחובות ,564 ,הרב מזרחי יעקב
8400 ,רחובות ,527 ,הרב מימון
8400 ,רחובות ,503 ,הרב מלצר
8400 ,רחובות ,732 ,הרב משולם
8400 ,רחובות ,200 ,הרב נדב דוד
8400 ,רחובות ,652 ,הרב נריה משה צבי
8400 ,רחובות ,654 ,הרב קאפח יוסף
8400 ,רחובות ,697 ,הרב שאולי
8400 ,רחובות ,523 ,הרב שטיינמן צבי
8400 ,רחובות ,549 ,הרב שיבר
8400 ,רחובות ,237 ,הרדוף
8400 ,רחובות ,211 ,הרה"ג עובדיה דמתי
8400 ,רחובות ,636 ,הרותם
8400 ,רחובות ,274 ,הרי אדום
8400 ,רחובות ,270 ,הרי השומרון
8400 ,רחובות ,273 ,הרי יהודה
8400 ,רחובות ,402 ,הרימון
8400 ,רחובות ,403 ,הרצל
8400 ,רחובות ,622 ,הרקפת
8400 ,רחובות ,434 ,הרשנזון
8400 ,רחובות ,504 ,השומרים
8400 ,רחובות ,440 ,השופטים
8400 ,רחובות ,310 ,השעורה
8400 ,רחובות ,422 ,השקד
8400 ,רחובות ,613 ,השרון
8400 ,רחובות ,311 ,התאנה
8400 ,רחובות ,515 ,התחיה
8400 ,רחובות ,728 ,התנאים
8400 ,רחובות ,514 ,התקוה
8400 ,רחובות ,575 ,התקומה
8400 ,רחובות ,143 ,וולפסוון
8400 ,רחובות ,334 ,וידר זאב
8400 ,רחובות ,522 ,וייסבורג
8400 ,רחובות ,425 ,וילקומיץ שמחה
8400 ,רחובות ,529 ,וינר יוסף
8400 ,רחובות ,454 ,ויצמן
8400 ,רחובות ,120 ,ויקס
8400 ,רחובות ,687 ,ולנברג ראול
8400 ,רחובות ,157 ,ורד
8400 ,רחובות ,315 ,ז"ס
8400 ,רחובות ,428 ,ז'בוטינסקי
8400 ,רחובות ,800 ,זילברמן שאול
8400 ,רחובות ,799 ,זכריה חממי
8400 ,רחובות ,731 ,זכריה משה
8400 ,רחובות ,626 ,זק"ש אפרים
8400 ,רחובות ,602 ,זרסקי מנחם מנדי
8400 ,רחובות ,525 ,חב"ד
8400 ,רחובות ,280 ,חוגלה
8400 ,רחובות ,277 ,חוחית
8400 ,רחובות ,676 ,חורש
8400 ,רחובות ,771 ,חושן
8400 ,רחובות ,552 ,חזון איש
8400 ,רחובות ,707 ,חזית הדרום
8400 ,רחובות ,507 ,חי"ש
8400 ,רחובות ,600 ,חכם עפג'ין אברהם
8400 ,רחובות ,137 ,חלבלוב
8400 ,רחובות ,301 ,חנקין
8400 ,רחובות ,533 ,חס"מ
8400 ,רחובות ,282 ,חסידה
8400 ,רחובות ,476 ,חפץ
8400 ,רחובות ,553 ,חפץ חיים
8400 ,רחובות ,6510 ,חצרות המושבה
8400 ,רחובות ,207 ,חרוצים
8400 ,רחובות ,201 ,חרושת
8400 ,רחובות ,417 ,חרל"פ
8400 ,רחובות ,349 ,חרש משה
8400 ,רחובות ,784 ,חתוכה זכריה
8400 ,רחובות ,321 ,טוב צפורה
8400 ,רחובות ,213 ,טוכמן יעקב
8400 ,רחובות ,415 ,טלר
8400 ,רחובות ,218 ,טפר ישראל
8400 ,רחובות ,608 ,טרומפלדור
8400 ,רחובות ,532 ,טרייבוש
8400 ,רחובות ,435 ,טשרניחובסקי
8400 ,רחובות ,446 ,י.ל. פרץ
8400 ,רחובות ,737 ,יאיר
8400 ,רחובות ,324 ,יד ושם
8400 ,רחובות ,475 ,יד לבנים
8400 ,רחובות ,140 ,ידיד החולות
8400 ,רחובות ,765 ,ידין יגאל
8400 ,רחובות ,426 ,יהואש
8400 ,רחובות ,629 ,יהודה הלוי
8400 ,רחובות ,751 ,יהודה המכבי
8400 ,רחובות ,243 ,יהושע בן חנניה
8400 ,רחובות ,584 ,יהלום
8400 ,רחובות ,224 ,יונתן נתניהו
8400 ,רחובות ,384 ,יוסף חיים צדוק
8400 ,רחובות ,130 ,יוספזון
8400 ,רחובות ,723 ,יחזקאל
8400 ,רחובות ,704 ,יחיא יגאל
8400 ,רחובות ,318 ,יל"ג
8400 ,רחובות ,776 ,ילין חיים
8400 ,רחובות ,136 ,ילקוט הרועים
8400 ,רחובות ,452 ,יעבץ
8400 ,רחובות ,451 ,יעקב
8400 ,רחובות ,389 ,יעקב ילין
8400 ,רחובות ,391 ,יעקב תמרי
8400 ,רחובות ,692 ,יעקובי יצחק
8400 ,רחובות ,127 ,יערה
8400 ,רחובות ,574 ,יפת דוד
8400 ,רחובות ,202 ,יצירה
8400 ,רחובות ,631 ,ירמיהו
8400 ,רחובות ,630 ,ישעיהו
8400 ,רחובות ,748 ,ישעיהו יוסף
8400 ,רחובות ,210 ,יתום משה
8400 ,רחובות ,594 ,כהן אורי
8400 ,רחובות ,650 ,כהן יחזקאל
8400 ,רחובות ,357 ,כהן מיכאל
8400 ,רחובות ,346 ,כהנוב אברהם
8400 ,רחובות ,370 ,כהנוביץ גרשון
8400 ,רחובות ,741 ,כובשי החרמון
8400 ,רחובות ,717 ,כובשי יוסף
8400 ,רחובות ,361 ,כוכבי דוד
8400 ,רחובות ,209 ,כישור
8400 ,רחובות ,6001 ,ככר בלהה
8400 ,רחובות ,6002 ,ככר העצמאות
8400 ,רחובות ,6004 ,ככר יצחק שדה
8400 ,רחובות ,6003 ,ככר רמה
8400 ,רחובות ,134 ,כליל
8400 ,רחובות ,711 ,כנרת
8400 ,רחובות ,135 ,כץ יצחק
8400 ,רחובות ,810 ,כצנלסון ישראל
8400 ,רחובות ,119 ,כרמל
8400 ,רחובות ,382 ,לבונה
8400 ,רחובות ,485 ,לבקוביץ
8400 ,רחובות ,638 ,לדיז'ינסקי
8400 ,רחובות ,386 ,לובה אליאב
8400 ,רחובות ,365 ,לוטם
8400 ,רחובות ,761 ,לוי אשכול
8400 ,רחובות ,808 ,לוי שלמה
8400 ,רחובות ,410 ,לוין אפשטין
8400 ,רחובות ,360 ,לוסטיג
8400 ,רחובות ,801 ,לוסטרניק אביבה ומתת
8400 ,רחובות ,160 ,לוע הארי
8400 ,רחובות ,487 ,לוצקי חוה
8400 ,רחובות ,488 ,לח"י
8400 ,רחובות ,355 ,לינדה בצלאל
8400 ,רחובות ,342 ,ליפקין
8400 ,רחובות ,595 ,לסקוב חיים
8400 ,רחובות ,769 ,לשם
8400 ,רחובות ,656 ,מאאס הרמן
8400 ,רחובות ,494 ,מאמר מרדכי
8400 ,רחובות ,444 ,מאפו
8400 ,רחובות ,543 ,מבצע ליטני
8400 ,רחובות ,662 ,מגיני הגליל
8400 ,רחובות ,727 ,מדהלה יעקב
8400 ,רחובות ,725 ,מדהלה יצחק
8400 ,רחובות ,721 ,מוגזח יצחק
8400 ,רחובות ,383 ,מודעי יצחק
8400 ,רחובות ,466 ,מוהליבר
8400 ,רחובות ,592 ,מוטה גור
8400 ,רחובות ,404 ,מוסקוביץ
8400 ,רחובות ,804 ,מוקסיי פנחס
8400 ,רחובות ,381 ,מור
8400 ,רחובות ,412 ,מורדי הגטאות
8400 ,רחובות ,358 ,מורי חיים
8400 ,רחובות ,239 ,מורן
8400 ,רחובות ,628 ,מזרחי דוד
8400 ,רחובות ,738 ,מזרחי משה
8400 ,רחובות ,649 ,מילצן חיים אליעזר
8400 ,רחובות ,530 ,מילר טובה וטוביה
8400 ,רחובות ,112 ,מכון ויצמן
8400 ,רחובות ,221 ,מלחמת יום הכפורים
8400 ,רחובות ,633 ,מלמד שלום
8400 ,רחובות ,480 ,מלמוד משה
8400 ,רחובות ,445 ,מנדלי מוכר ספרים
8400 ,רחובות ,449 ,מנוחה ונחלה
8400 ,רחובות ,596 ,מנוחין אוריאל
8400 ,רחובות ,665 ,מני עזרא
8400 ,רחובות ,6551 ,מע ב רחובות
8400 ,רחובות ,6517 ,מע ב רמה
8400 ,רחובות ,6552 ,מע ג רחובות
8400 ,רחובות ,6553 ,מע ד רחובות
8400 ,רחובות ,6554 ,מע קרית משה
8400 ,רחובות ,107 ,מעון אקדמאים
8400 ,רחובות ,111 ,מעון סטודנטים
8400 ,רחובות ,206 ,מפוח
8400 ,רחובות ,332 ,מצדה
8400 ,רחובות ,437 ,מקוב בתיה
8400 ,רחובות ,138 ,מקור החסידה
8400 ,רחובות ,222 ,מרבד הקסמים
8400 ,רחובות ,506 ,מרגולין
8400 ,רחובות ,479 ,מרדכי נחמן
8400 ,רחובות ,133 ,מרווה
8400 ,רחובות ,372 ,מרטון
8400 ,רחובות ,753 ,מרים מזרחי
8400 ,רחובות ,850 ,מרכז רפואי קפלן
8400 ,רחובות ,374 ,מרשוב
8400 ,רחובות ,369 ,משקה יצחק
8400 ,רחובות ,750 ,מששוילי מנחם
8400 ,רחובות ,6537 ,מתחם התחנה המרכזית
8400 ,רחובות ,605 ,נג'ארה ישראל
8400 ,רחובות ,438 ,נגבה
8400 ,רחובות ,6571 ,נוה הדר
8400 ,רחובות ,6555 ,נוה יהודה
8400 ,רחובות ,6564 ,נוה עמית
8400 ,רחובות ,555 ,נווה אלון
8400 ,רחובות ,144 ,נווה מץ
8400 ,רחובות ,599 ,נוטמן אברהם
8400 ,רחובות ,366 ,נופר
8400 ,רחובות ,447 ,נורדאו
8400 ,רחובות ,204 ,נחושתן
8400 ,רחובות ,275 ,נחליאלי
8400 ,רחובות ,118 ,נחמני אבינועם
8400 ,רחובות ,576 ,ניל"י
8400 ,רחובות ,678 ,ניצן
8400 ,רחובות ,377 ,נפתולסקי נח
8400 ,רחובות ,139 ,נר הלילה
8400 ,רחובות ,519 ,נתיב הל"ה
8400 ,רחובות ,759 ,נתיב יום טוב
8400 ,רחובות ,778 ,נתיב משה
8400 ,רחובות ,785 ,נתיב נוטמן דוד
8400 ,רחובות ,666 ,ס.יזהר
8400 ,רחובות ,203 ,סדנה
8400 ,רחובות ,802 ,סוחובולסקי צבי
8400 ,רחובות ,419 ,סוקולוב
8400 ,רחובות ,238 ,סחלב
8400 ,רחובות ,217 ,סטולבוב
8400 ,רחובות ,579 ,סטרומה
8400 ,רחובות ,489 ,סיטקוב
8400 ,רחובות ,158 ,סייפן
8400 ,רחובות ,474 ,סיני
8400 ,רחובות ,131 ,סיסם
8400 ,רחובות ,462 ,סיקו מאיר
8400 ,רחובות ,505 ,סירני
8400 ,רחובות ,331 ,סירקין
8400 ,רחובות ,159 ,סיתוונית
8400 ,רחובות ,540 ,סמ אשכולית
8400 ,רחובות ,498 ,סמ בני ברית
8400 ,רחובות ,400 ,סמ האתרוג
8400 ,רחובות ,698 ,סמ הברקן
8400 ,רחובות ,664 ,סמ החרוב
8400 ,רחובות ,696 ,סמ היקינטון
8400 ,רחובות ,589 ,סמ הכרכום
8400 ,רחובות ,354 ,סמ הלילך
8400 ,רחובות ,757 ,סמ המוכתר
8400 ,רחובות ,472 ,סמ הפרדס
8400 ,רחובות ,756 ,סמ הרב בשארי שלום
8400 ,רחובות ,492 ,סמ הרבבסקי
8400 ,רחובות ,702 ,סמ השלום
8400 ,רחובות ,632 ,סמ התמר
8400 ,רחובות ,740 ,סמ חנה
8400 ,רחובות ,542 ,סמ לימון
8400 ,רחובות ,473 ,סמ משה
8400 ,רחובות ,758 ,סמ נחמני
8400 ,רחובות ,541 ,סמ תפוז
8400 ,רחובות ,388 ,סמ' שרה ומרדכי גריס
8400 ,רחובות ,373 ,סמטת היקב
8400 ,רחובות ,371 ,סמטת חביבי
8400 ,רחובות ,418 ,סמילנסקי
8400 ,רחובות ,767 ,סנה משה
8400 ,רחובות ,720 ,סעדיה גאון
8400 ,רחובות ,598 ,סעדיה הכהן
8400 ,רחובות ,581 ,ספיר
8400 ,רחובות ,214 ,סרנגה משולם
8400 ,רחובות ,674 ,עגנון ש"י
8400 ,רחובות ,254 ,עולי תימן
8400 ,רחובות ,680 ,עומר
8400 ,רחובות ,742 ,עוקשי ישראל
8400 ,רחובות ,703 ,עותמי שלום
8400 ,רחובות ,448 ,עזרא
8400 ,רחובות ,616 ,עין התכלת
8400 ,רחובות ,677 ,עינב
8400 ,רחובות ,537 ,עמ"י
8400 ,רחובות ,500 ,עמר
8400 ,רחובות ,774 ,ענבר
8400 ,רחובות ,125 ,ענתין
8400 ,רחובות ,726 ,עקיבא
8400 ,רחובות ,197 ,עשת
8400 ,רחובות ,6513 ,פ.ת אפריקה ישראל
8400 ,רחובות ,6507 ,פארק המדע
8400 ,רחובות ,546 ,פדובה טוביה
8400 ,רחובות ,330 ,פוגל בן ציון
8400 ,רחובות ,348 ,פומרנץ
8400 ,רחובות ,535 ,פורר יהושע
8400 ,רחובות ,661 ,פורר משה
8400 ,רחובות ,102 ,פייקס דוד
8400 ,רחובות ,411 ,פינס
8400 ,רחובות ,467 ,פינסקר
8400 ,רחובות ,328 ,פינקלשטין
8400 ,רחובות ,648 ,פינשטיין
8400 ,רחובות ,103 ,פלאוט מנחם
8400 ,רחובות ,117 ,פלדי יחיאל
8400 ,רחובות ,508 ,פלמ"ח
8400 ,רחובות ,379 ,פנחס ספיר
8400 ,רחובות ,228 ,פניגר יצחק
8400 ,רחובות ,345 ,פנקס אברהם
8400 ,רחובות ,236 ,פקיעין
8400 ,רחובות ,105 ,פקריס
8400 ,רחובות ,6569 ,פרדס גאליה
8400 ,רחובות ,110 ,פרדס פישר
8400 ,רחובות ,464 ,פרוג
8400 ,רחובות ,557 ,פרופ' אומן ישראל
8400 ,רחובות ,568 ,פרופ' אורבך אפרים
8400 ,רחובות ,572 ,פרופ' ארנסט סימון
8400 ,רחובות ,563 ,פרופ' בירק יהודית
8400 ,רחובות ,571 ,פרופ' ברגמן שמואל
8400 ,רחובות ,562 ,פרופ' הרשקו אברהם
8400 ,רחובות ,670 ,פרופ' חיים הררי
8400 ,רחובות ,556 ,פרופ' כהנמן דניאל
8400 ,רחובות ,669 ,פרופ' מייקל לוויט
8400 ,רחובות ,668 ,פרופ' מילשטיין דוד
8400 ,רחובות ,559 ,פרופ' נאמן יובל
8400 ,רחובות ,667 ,פרופ' פיאמנטה משה
8400 ,רחובות ,560 ,פרופ' צ'חנובר אהרון
8400 ,רחובות ,558 ,פרופ' קציר אפרים
8400 ,רחובות ,561 ,פרופ' רבל מישל
8400 ,רחובות ,569 ,פרופ' ריבלין יוסף
8400 ,רחובות ,570 ,פרופ' רצהבי יהודה
8400 ,רחובות ,341 ,פריגוזין
8400 ,רחובות ,368 ,פריד משה
8400 ,רחובות ,316 ,פרישמן
8400 ,רחובות ,390 ,פרץ שלום
8400 ,רחובות ,597 ,פרשני אברהם
8400 ,רחובות ,279 ,פשוש
8400 ,רחובות ,247 ,צאלון
8400 ,רחובות ,249 ,צבר
8400 ,רחובות ,278 ,צופית
8400 ,רחובות ,566 ,צור שמשון
8400 ,רחובות ,350 ,צייכנר
8400 ,רחובות ,588 ,ציפורי
8400 ,רחובות ,743 ,צעידי שלום
8400 ,רחובות ,653 ,קאופמן חיים
8400 ,רחובות ,607 ,קארו
8400 ,רחובות ,235 ,קהילת טורונטו
8400 ,רחובות ,545 ,קובנר
8400 ,רחובות ,333 ,קוסובר
8400 ,רחובות ,232 ,קורנית
8400 ,רחובות ,777 ,קורצ'אק יאנוש
8400 ,רחובות ,293 ,קושלביץ בנימין
8400 ,רחובות ,100 ,קטלב
8400 ,רחובות ,338 ,קינד דוד
8400 ,רחובות ,234 ,קינד מוטי
8400 ,רחובות ,536 ,קיפניס משה
8400 ,רחובות ,423 ,קליבנר עמיחי
8400 ,רחובות ,343 ,קליין דב
8400 ,רחובות ,477 ,קלישר
8400 ,רחובות ,807 ,קלסדי דוד
8400 ,רחובות ,352 ,קנדלר
8400 ,רחובות ,651 ,קנטרוביץ
8400 ,רחובות ,658 ,קסלמן נחום
8400 ,רחובות ,736 ,קפרא מנשה
8400 ,רחובות ,305 ,קק"ל
8400 ,רחובות ,353 ,קרומר נח
8400 ,רחובות ,693 ,קרוננברג יוסף
8400 ,רחובות ,6532 ,קרית דוד
8400 ,רחובות ,6526 ,קרית ההגנה
8400 ,רחובות ,6570 ,קרית ויז'ניץ
8400 ,רחובות ,6509 ,קרית ויצמן
8400 ,רחובות ,6567 ,קרית משה
8400 ,רחובות ,6566 ,קרית משה דרום
8400 ,רחובות ,351 ,רוז'נסקי
8400 ,רחובות ,421 ,רוטרי
8400 ,רחובות ,302 ,רופין
8400 ,רחובות ,493 ,רופמן שמואל
8400 ,רחובות ,121 ,רח ה
8400 ,רחובות ,122 ,רח ו
8400 ,רחובות ,123 ,רח ז
8400 ,רחובות ,124 ,רח ט
8400 ,רחובות ,300 ,רח טז
8400 ,רחובות ,9000 ,רחובות
8400 ,רחובות ,6536 ,רחובות החדשה
8400 ,רחובות ,526 ,רחל
8400 ,רחובות ,739 ,רייפן יעקב
8400 ,רחובות ,625 ,רמב"ם
8400 ,רחובות ,363 ,רמז
8400 ,רחובות ,671 ,רמח"ל
8400 ,רחובות ,6531 ,רמת אהרון
8400 ,רחובות ,747 ,רמת יגאל
8400 ,רחובות ,604 ,רש"י
8400 ,רחובות ,6518 ,ש אושיות
8400 ,רחובות ,6557 ,ש אסבסט קרית משה
8400 ,רחובות ,6558 ,ש אפרים צריפים
8400 ,רחובות ,406 ,ש בן ציון
8400 ,רחובות ,6559 ,ש גן יוסף
8400 ,רחובות ,6519 ,ש הפוהמז
8400 ,רחובות ,6501 ,ש וולפסון
8400 ,רחובות ,6560 ,ש ותיקים
8400 ,רחובות ,6520 ,ש חסכון
8400 ,רחובות ,6521 ,ש מילצ'ן
8400 ,רחובות ,6527 ,ש מרמורק
8400 ,רחובות ,6502 ,ש נוה ויצמן
8400 ,רחובות ,6562 ,ש סלע
8400 ,רחובות ,6563 ,ש קלט קרית משה
8400 ,רחובות ,6575 ,ש קרוונים
8400 ,רחובות ,482 ,ש"י
8400 ,רחובות ,730 ,שבזי
8400 ,רחובות ,733 ,שבתאי
8400 ,רחובות ,585 ,שד בגין מנחם
8400 ,רחובות ,327 ,שד וולקני
8400 ,רחובות ,401 ,שד חן
8400 ,רחובות ,684 ,שד מקס ואמפרו שיין
8400 ,רחובות ,356 ,שד רפאל בן מנחם
8400 ,רחובות ,470 ,שד"ל
8400 ,רחובות ,116 ,שדרת הקבוצים
8400 ,רחובות ,583 ,שוהם
8400 ,רחובות ,754 ,שוסייוב שלום
8400 ,רחובות ,156 ,שושן
8400 ,רחובות ,126 ,שושן אריה
8400 ,רחובות ,620 ,שושנת העמקים
8400 ,רחובות ,212 ,שחר ישראל
8400 ,רחובות ,337 ,שטיין משה
8400 ,רחובות ,528 ,שטינברג
8400 ,רחובות ,679 ,שיבולים
8400 ,רחובות ,230 ,שיזף
8400 ,רחובות ,227 ,שיטה מלבינה
8400 ,רחובות ,115 ,שיין ברנט
8400 ,רחובות ,229 ,שיקמה
8400 ,רחובות ,6529 ,שכ אבני חן
8400 ,רחובות ,6530 ,שכ גבעתי
8400 ,רחובות ,6522 ,שכ דניה
8400 ,רחובות ,6534 ,שכ היובל
8400 ,רחובות ,6523 ,שכ חבצלת
8400 ,רחובות ,6524 ,שכ חסידי קרעשניף
8400 ,רחובות ,6504 ,שכ יד ויצמן
8400 ,רחובות ,6533 ,שכ מקוב
8400 ,רחובות ,6511 ,שכ נאות כרמים
8400 ,רחובות ,6525 ,שכ נוה אלון
8400 ,רחובות ,6535 ,שכ עין גנים
8400 ,רחובות ,6512 ,שכ רחובות ההולנדית
8400 ,רחובות ,6565 ,שכ שלמה אבן גבירול
8400 ,רחובות ,6514 ,שכ שעריים
8400 ,רחובות ,6506 ,שכ תחנת רכבת
8400 ,רחובות ,755 ,שמואלין אהרן
8400 ,רחובות ,708 ,שמחי אברהם
8400 ,רחובות ,141 ,שמעון פרנס
8400 ,רחובות ,387 ,שמעון פרס
8400 ,רחובות ,317 ,שמעוני דוד
8400 ,רחובות ,772 ,שני
8400 ,רחובות ,781 ,שניר
8400 ,רחובות ,701 ,שעובי זרח
8400 ,רחובות ,746 ,שער הגיא
8400 ,רחובות ,420 ,שפינוזה ברוך
8400 ,רחובות ,531 ,שקולניק אהרן
8400 ,רחובות ,639 ,שר עירא
8400 ,רחובות ,783 ,שרעבי יעקב סעדיה
8400 ,רחובות ,764 ,שרת משה
8400 ,רחובות ,375 ,ששת הימים
8400 ,רחובות ,252 ,תאשור
8400 ,רחובות ,248 ,תדהר
8400 ,רחובות ,281 ,תור האביב
8400 ,רחובות ,114 ,תמיר עקיבא
8400 ,רחובות ,524 ,תר"ן
8400 ,רחובות ,773 ,תרשיש
3822 ,רחלים ,9000 ,רחלים
3822 ,רחלים ,6500 ,שכ נופי נחמיה
540 ,ריחאניה ,115 ,אבזאח
540 ,ריחאניה ,101 ,אדיגה
540 ,ריחאניה ,132 ,אזה
540 ,ריחאניה ,103 ,אלברוס
540 ,ריחאניה ,130 ,אסא
540 ,ריחאניה ,131 ,ביסלאני
540 ,ריחאניה ,129 ,ברקוק
540 ,ריחאניה ,121 ,דאנה
540 ,ריחאניה ,107 ,וובך
540 ,ריחאניה ,126 ,וזרמס
540 ,ריחאניה ,110 ,וסה
540 ,ריחאניה ,120 ,ח'אן
540 ,ריחאניה ,122 ,מאזה
540 ,ריחאניה ,119 ,מאפה
540 ,ריחאניה ,123 ,נאלמס
540 ,ריחאניה ,124 ,נאלצ'יק
540 ,ריחאניה ,114 ,נאספ
540 ,ריחאניה ,117 ,נאפ
540 ,ריחאניה ,102 ,נארת
540 ,ריחאניה ,109 ,נסה
540 ,ריחאניה ,118 ,נשאן
540 ,ריחאניה ,111 ,סאוסרק'וה
540 ,ריחאניה ,116 ,סינמיס
540 ,ריחאניה ,128 ,סתנאי
540 ,ריחאניה ,113 ,פסנה
540 ,ריחאניה ,112 ,פשנה
540 ,ריחאניה ,125 ,צייה
540 ,ריחאניה ,127 ,קאזבק
540 ,ריחאניה ,108 ,קברדאי
540 ,ריחאניה ,106 ,קובאן
540 ,ריחאניה ,105 ,ריחאן
540 ,ריחאניה ,9000 ,ריחאניה
540 ,ריחאניה ,104 ,תימר
3568 ,ריחן ,102 ,דרך הגן
3568 ,ריחן ,101 ,דרך הנוף
3568 ,ריחן ,107 ,האופק
3568 ,ריחן ,105 ,האלומה
3568 ,ריחן ,106 ,האסיף
3568 ,ריחן ,104 ,החצב
3568 ,ריחן ,109 ,החרוב
3568 ,ריחן ,103 ,הפרדס
3568 ,ריחן ,108 ,השחר
3568 ,ריחן ,9000 ,ריחן
3568 ,ריחן ,110 ,שביל החרוזים
3568 ,ריחן ,112 ,שביל הסייפן
3568 ,ריחן ,111 ,שביל הרימון
542 ,ריינה ,395 ,א-דוואלי
542 ,ריינה ,410 ,א-דר
542 ,ריינה ,243 ,א-זהרה
542 ,ריינה ,195 ,א-זוהור
542 ,ריינה ,282 ,א-זייתון
542 ,ריינה ,339 ,א-זעים
542 ,ריינה ,161 ,א-זערור
542 ,ריינה ,394 ,א-זעתר
542 ,ריינה ,341 ,א-זקאק
542 ,ריינה ,229 ,א-טאבון
542 ,ריינה ,310 ,א-טברי
542 ,ריינה ,362 ,א-טוילה
542 ,ריינה ,288 ,א-טיון
542 ,ריינה ,193 ,א-ליימון
542 ,ריינה ,185 ,א-נבי שועייב
542 ,ריינה ,197 ,א-נבעה
542 ,ריינה ,348 ,א-נהדה
542 ,ריינה ,231 ,א-נוזהה
542 ,ריינה ,335 ,א-נועמאן
542 ,ריינה ,228 ,א-נופג'י
542 ,ריינה ,365 ,א-נוקטה
542 ,ריינה ,210 ,א-נור
542 ,ריינה ,246 ,א-נורוס
542 ,ריינה ,317 ,א-נח'יל
542 ,ריינה ,225 ,א-ניירוז
542 ,ריינה ,351 ,א-ניל
542 ,ריינה ,278 ,א-נימר
542 ,ריינה ,227 ,א-סאחה
542 ,ריינה ,399 ,א-סביל
542 ,ריינה ,338 ,א-סדר
542 ,ריינה ,372 ,א-סווסן
542 ,ריינה ,270 ,א-סחאבה
542 ,ריינה ,137 ,א-סחייה
542 ,ריינה ,380 ,א-סיידה עאישה
542 ,ריינה ,370 ,א-סינדייאן
542 ,ריינה ,309 ,א-סיראט
542 ,ריינה ,352 ,א-סלאח
542 ,ריינה ,334 ,א-סלאם
542 ,ריינה ,303 ,א-סנובר
542 ,ריינה ,166 ,א-סעאדה
542 ,ריינה ,106 ,א-ספא
542 ,ריינה ,373 ,א-ספסאף
542 ,ריינה ,116 ,א-סרווה
542 ,ריינה ,140 ,א-סריס
542 ,ריינה ,340 ,א-צאדיק
542 ,ריינה ,184 ,א-ראהבאת
542 ,ריינה ,398 ,א-ראוי
542 ,ריינה ,379 ,א-ראזי
542 ,ריינה ,104 ,א-ראם
542 ,ריינה ,168 ,א-רביע
542 ,ריינה ,148 ,א-רואבי
542 ,ריינה ,393 ,א-רוואפד
542 ,ריינה ,264 ,א-רוודה
542 ,ריינה ,397 ,א-רומאן
542 ,ריינה ,277 ,א-רחמה
542 ,ריינה ,353 ,א-ריאד
542 ,ריינה ,311 ,א-ריאן
542 ,ריינה ,222 ,א-ריזק
542 ,ריינה ,183 ,א-רייחאן
542 ,ריינה ,392 ,א-רים
542 ,ריינה ,186 ,א-ריף
542 ,ריינה ,164 ,א-שאם
542 ,ריינה ,208 ,א-שאפיעי
542 ,ריינה ,343 ,א-שאר'ור
542 ,ריינה ,286 ,א-שהבאא
542 ,ריינה ,387 ,א-שועאע
542 ,ריינה ,315 ,א-שועראא
542 ,ריינה ,177 ,א-שיח' חסן חישמה
542 ,ריינה ,389 ,א-שיפאא
542 ,ריינה ,388 ,א-שיראע
542 ,ריינה ,204 ,א-שעאנין
542 ,ריינה ,356 ,א-שריף
542 ,ריינה ,131 ,א-תוופיק
542 ,ריינה ,201 ,א-תות
542 ,ריינה ,302 ,א-תין
542 ,ריינה ,280 ,א-תקוא
542 ,ריינה ,274 ,א-תקסים
542 ,ריינה ,120 ,אבו אל-קאסם א-שאבי
542 ,ריינה ,115 ,אבו בכר א-צדיק
542 ,ריינה ,103 ,אבו הוריירה
542 ,ריינה ,412 ,אבו זיד
542 ,ריינה ,192 ,אבו תמאם
542 ,ריינה ,411 ,אום אל-חיראן
542 ,ריינה ,6741 ,אזור התעשייה
542 ,ריינה ,276 ,אחמד עוראבי
542 ,ריינה ,141 ,אחמד שווקי
542 ,ריינה ,296 ,איבן אל-היית'ם
542 ,ריינה ,255 ,איבן אל-קייאם
542 ,ריינה ,142 ,איבן זיידון
542 ,ריינה ,118 ,איבן ח'לדון
542 ,ריינה ,223 ,איבן חיאן
542 ,ריינה ,308 ,איבן כת'יר
542 ,ריינה ,381 ,איבן סהל
542 ,ריינה ,320 ,איבן סינא
542 ,ריינה ,126 ,איבן רושד
542 ,ריינה ,180 ,איזמיר
542 ,ריינה ,214 ,איליא אבו מאד'י
542 ,ריינה ,408 ,איסדוד
542 ,ריינה ,298 ,איסטנבול
542 ,ריינה ,112 ,אל-אבראר
542 ,ריינה ,213 ,אל-אודבאא'
542 ,ריינה ,150 ,אל-אווס
542 ,ריינה ,123 ,אל-אח'טל
542 ,ריינה ,292 ,אל-אידריסי
542 ,ריינה ,289 ,אל-איח'וה
542 ,ריינה ,350 ,אל-איסלאח
542 ,ריינה ,297 ,אל-איספהאני
542 ,ריינה ,333 ,אל-איעתיס'אם
542 ,ריינה ,390 ,אל-איתיחאד
542 ,ריינה ,245 ,אל-אמין
542 ,ריינה ,102 ,אל-אנדלוס
542 ,ריינה ,124 ,אל-אסטורה
542 ,ריינה ,108 ,אל-אפראח
542 ,ריינה ,358 ,אל-ארז
542 ,ריינה ,159 ,אל-בוח'ארי
542 ,ריינה ,130 ,אל-בוחתורי
542 ,ריינה ,275 ,אל-בוסתאן
542 ,ריינה ,157 ,אל-בוראק
542 ,ריינה ,344 ,אל-בחר
542 ,ריינה ,544 ,אל-בטוף
542 ,ריינה ,322 ,אל-ביאן
542 ,ריינה ,218 ,אל-ביידר
542 ,ריינה ,147 ,אל-ביק
542 ,ריינה ,257 ,אל-בירוני
542 ,ריינה ,312 ,אל-בלאן
542 ,ריינה ,235 ,אל-ברותסתאנת
542 ,ריינה ,301 ,אל-ברכה
542 ,ריינה ,360 ,אל-ברקוק
542 ,ריינה ,233 ,אל-בשארה
542 ,ריינה ,128 ,אל-בתראא
542 ,ריינה ,134 ,אל-ג'אחז
542 ,ריינה ,169 ,אל-ג'בל
542 ,ריינה ,170 ,אל-ג'וד
542 ,ריינה ,347 ,אל-ג'ולאן
542 ,ריינה ,149 ,אל-ג'ליל
542 ,ריינה ,160 ,אל-ג'נינה
542 ,ריינה ,273 ,אל-ג'עדה
542 ,ריינה ,109 ,אל-ג'רמק
542 ,ריינה ,330 ,אל-הנאא
542 ,ריינה ,176 ,אל-ואדי
542 ,ריינה ,377 ,אל-ואדי א-שרקי
542 ,ריינה ,316 ,אל-ווארשה
542 ,ריינה ,232 ,אל-וורוד
542 ,ריינה ,230 ,אל-ח'אן
542 ,ריינה ,346 ,אל-ח'דר
542 ,ריינה ,133 ,אל-ח'ואריזמי
542 ,ריינה ,203 ,אל-ח'ורי
542 ,ריינה ,162 ,אל-ח'זאן
542 ,ריינה ,331 ,אל-ח'טיב
542 ,ריינה ,263 ,אל-ח'יזראן
542 ,ריינה ,260 ,אל-ח'ירבה
542 ,ריינה ,154 ,אל-ח'לה
542 ,ריינה ,163 ,אל-ח'לווה
542 ,ריינה ,318 ,אל-ח'ליל
542 ,ריינה ,136 ,אל-ח'נסאא
542 ,ריינה ,401 ,אל-ח'רוב
542 ,ריינה ,105 ,אל-ח'רסאנה
542 ,ריינה ,242 ,אל-חאווז
542 ,ריינה ,132 ,אל-חג'אג'
542 ,ריינה ,406 ,אל-חדת'ה
542 ,ריינה ,342 ,אל-חואכיר
542 ,ריינה ,279 ,אל-חוסיין
542 ,ריינה ,323 ,אל-חורש
542 ,ריינה ,284 ,אל-חיאה
542 ,ריינה ,374 ,אל-חמרא
542 ,ריינה ,293 ,אל-חסן
542 ,ריינה ,400 ,אל-חריר
542 ,ריינה ,127 ,אל-ימאמה
542 ,ריינה ,355 ,אל-יקין
542 ,ריינה ,135 ,אל-כווכב
542 ,ריינה ,543 ,אל-כורום
542 ,ריינה ,384 ,אל-כיתאב
542 ,ריינה ,319 ,אל-כנאר
542 ,ריינה ,283 ,אל-כרם
542 ,ריינה ,212 ,אל-כת'וליכ
542 ,ריינה ,272 ,אל-מג'ד
542 ,ריינה ,101 ,אל-מדינה
542 ,ריינה ,345 ,אל-מדרסה
542 ,ריינה ,121 ,אל-מוג'מע
542 ,ריינה ,155 ,אל-מוואזייה
542 ,ריינה ,205 ,אל-מוח'תאר
542 ,ריינה ,217 ,אל-מוטראן
542 ,ריינה ,259 ,אל-מונטאר
542 ,ריינה ,265 ,אל-מוצטפא
542 ,ריינה ,125 ,אל-מותנבי
542 ,ריינה ,405 ,אל-מזאר
542 ,ריינה ,175 ,אל-מזרעה
542 ,ריינה ,206 ,אל-מטל
542 ,ריינה ,547 ,אל-מיזאן
542 ,ריינה ,542 ,אל-מיחראב
542 ,ריינה ,144 ,אל-מיימון
542 ,ריינה ,244 ,אל-מיסם
542 ,ריינה ,248 ,אל-מלעב
542 ,ריינה ,324 ,אל-מנארה
542 ,ריינה ,209 ,אל-מנג'ל
542 ,ריינה ,386 ,אל-מנהל
542 ,ריינה ,369 ,אל-מנטרה
542 ,ריינה ,173 ,אל-מעאלי
542 ,ריינה ,226 ,אל-מעס'רה
542 ,ריינה ,117 ,אל-מערי
542 ,ריינה ,332 ,אל-מר'ארה
542 ,ריינה ,327 ,אל-מראעי
542 ,ריינה ,250 ,אל-מרג'ה
542 ,ריינה ,107 ,אל-מרווה
542 ,ריינה ,404 ,אל-נעמה
542 ,ריינה ,546 ,אל-נקב
542 ,ריינה ,181 ,אל-עאסי
542 ,ריינה ,287 ,אל-עבאס
542 ,ריינה ,211 ,אל-עוד
542 ,ריינה ,385 ,אל-עולום
542 ,ריינה ,366 ,אל-עזאם
542 ,ריינה ,306 ,אל-עטאר
542 ,ריינה ,313 ,אל-עינאב
542 ,ריינה ,305 ,אל-עמל
542 ,ריינה ,548 ,אל-ענבר
542 ,ריינה ,294 ,אל-ענדליב
542 ,ריינה ,167 ,אל-ערבי
542 ,ריינה ,239 ,אל-עריש
542 ,ריינה ,359 ,אל-פאנוס
542 ,ריינה ,299 ,אל-פאראבי
542 ,ריינה ,251 ,אל-פארוק
542 ,ריינה ,151 ,אל-פוראת
542 ,ריינה ,172 ,אל-פורסאן
542 ,ריינה ,402 ,אל-פל
542 ,ריינה ,174 ,אל-פרדוס
542 ,ריינה ,119 ,אל-פרזדק
542 ,ריינה ,207 ,אל-פרח
542 ,ריינה ,253 ,אל-קאדיסייה
542 ,ריינה ,325 ,אל-קאעה
542 ,ריינה ,256 ,אל-קובה
542 ,ריינה ,196 ,אל-קודס
542 ,ריינה ,336 ,אל-קוסילה
542 ,ריינה ,391 ,אל-קימה
542 ,ריינה ,261 ,אל-קלעה
542 ,ריינה ,314 ,אל-קנאה
542 ,ריינה ,188 ,אל-קנטרה
542 ,ריינה ,403 ,אל-קראג'את
542 ,ריינה ,171 ,אל-ר'אר
542 ,ריינה ,153 ,אל-ר'דיר
542 ,ריינה ,198 ,אל-ר'זאלי
542 ,ריינה ,114 ,אל-ר'זייל
542 ,ריינה ,337 ,אל-ר'נאם
542 ,ריינה ,6731 ,אלבחשורה
542 ,ריינה ,6734 ,אלביאדר
542 ,ריינה ,6733 ,אלבלדה אלקדימה
542 ,ריינה ,6745 ,אלג'בל אלקבלי
542 ,ריינה ,6710 ,אלואדי אלשרקי
542 ,ריינה ,6740 ,אלמוזיה
542 ,ריינה ,6720 ,אלריינה אלג'דידה
542 ,ריינה ,179 ,אנקרה
542 ,ריינה ,139 ,אסיא
542 ,ריינה ,110 ,אריחא
542 ,ריינה ,383 ,אשבילייה
542 ,ריינה ,326 ,באניאס
542 ,ריינה ,129 ,בילאל בן רבאח
542 ,ריינה ,190 ,ביר א-סיכה
542 ,ריינה ,375 ,בית ג'ן
542 ,ריינה ,145 ,בלקיס
542 ,ריינה ,158 ,ג'בל אל-קיבלה
542 ,ריינה ,122 ,ג'ריר
542 ,ריינה ,354 ,ג'רש
542 ,ריינה ,413 ,גובראן ח'ליל גובראן
542 ,ריינה ,182 ,דאהר ראשד
542 ,ריינה ,152 ,דג'לה
542 ,ריינה ,165 ,דימשק
542 ,ריינה ,202 ,דיר א-לאתין
542 ,ריינה ,376 ,דרב א-נסארה
542 ,ריינה ,6746 ,דרב אלנאסרה
542 ,ריינה ,371 ,דרך אל-בחשורה
542 ,ריינה ,267 ,דרך אל-ביר א-שימאלי
542 ,ריינה ,364 ,דרך אל-ביר א-תחתאני
542 ,ריינה ,138 ,דרך כווכב א-שימאל
542 ,ריינה ,368 ,חוראן
542 ,ריינה ,6743 ,חי בלאל
542 ,ריינה ,6742 ,חי דאר רזק
542 ,ריינה ,258 ,חיטין
542 ,ריינה ,194 ,חלב
542 ,ריינה ,545 ,טבריה
542 ,ריינה ,220 ,טה חוסין
542 ,ריינה ,143 ,טלת אל-ואד
542 ,ריינה ,552 ,כאיד טאטור
542 ,ריינה ,6744 ,כוכב אלשמאלי
542 ,ריינה ,367 ,לובנאן
542 ,ריינה ,300 ,לוקמאן אל-חכים
542 ,ריינה ,266 ,מאי זייאדה
542 ,ריינה ,268 ,מואתה
542 ,ריינה ,349 ,מור'ור א-דור
542 ,ריינה ,156 ,מחמד אל-פאתח
542 ,ריינה ,357 ,מסר
542 ,ריינה ,6732 ,מע'ר אלדור
542 ,ריינה ,382 ,נג'יב מחפוז
542 ,ריינה ,549 ,סבאא
542 ,ריינה ,409 ,סוחמתא
542 ,ריינה ,187 ,סיידנא מוסא
542 ,ריינה ,236 ,סיידת אל-ג'ליל
542 ,ריינה ,290 ,סילאן
542 ,ריינה ,189 ,סירין
542 ,ריינה ,550 ,סנעאא
542 ,ריינה ,262 ,עומר איבן אל-ח'טאב
542 ,ריינה ,551 ,עומר איבן אל-עאס
542 ,ריינה ,178 ,עיין ג'כלה
542 ,ריינה ,219 ,עין אל-קטעה
542 ,ריינה ,295 ,עכא
542 ,ריינה ,407 ,עסקלאן
542 ,ריינה ,378 ,פאטמה א-זהראא
542 ,ריינה ,252 ,פארס אל-אקצא
542 ,ריינה ,285 ,קורטובה
542 ,ריינה ,111 ,קיבאא
542 ,ריינה ,9000 ,ריינה
542 ,ריינה ,146 ,שהרזאד
542 ,ריינה ,6730 ,שכ אלביר אלשמאלי
542 ,ריינה ,6735 ,שכ אלביר אלתחתאני
542 ,ריינה ,6711 ,שכ דרב אלסוק
542 ,ריינה ,6500 ,שכ כוכב הצפון
542 ,ריינה ,113 ,שפא עמרו
542 ,ריינה ,414 ,שרע דרב א-סוק
542 ,ריינה ,224 ,תוופיק אל-חכים
542 ,ריינה ,191 ,תופיק זיאד
542 ,ריינה ,234 ,תל אל-כניס
3565 ,רימונים ,103 ,אפרסמון
3565 ,רימונים ,104 ,בעלי מלאכה
3565 ,רימונים ,118 ,האירוס
3565 ,רימונים ,102 ,הבושם
3565 ,רימונים ,108 ,הגומא
3565 ,רימונים ,105 ,הגפן
3565 ,רימונים ,115 ,הזית
3565 ,רימונים ,124 ,הירדן
3565 ,רימונים ,117 ,הערבה
3565 ,רימונים ,107 ,הרדוף
3565 ,רימונים ,112 ,התאנה
3565 ,רימונים ,120 ,התמר
3565 ,רימונים ,101 ,יסמין
3565 ,רימונים ,123 ,משעול האודם
3565 ,רימונים ,106 ,משעול האתרוג
3565 ,רימונים ,109 ,משעול הדורות
3565 ,רימונים ,111 ,משעול החושן
3565 ,רימונים ,116 ,משעול הצבר
3565 ,רימונים ,119 ,משעול נווה מדבר
3565 ,רימונים ,113 ,משעול נופים
3565 ,רימונים ,114 ,נוף הרים
3565 ,רימונים ,121 ,נחל ייטב
3565 ,רימונים ,122 ,נחל פרת
3565 ,רימונים ,126 ,סלע הרימון
3565 ,רימונים ,125 ,ערבות יריחו
3565 ,רימונים ,9000 ,רימונים
3565 ,רימונים ,110 ,שדרות הרימון
616 ,רינתיה ,111 ,דרך המבואות
616 ,רינתיה ,112 ,דרך המייסדים
616 ,רינתיה ,101 ,האורנים
616 ,רינתיה ,102 ,החורש
616 ,רינתיה ,104 ,החצב
616 ,רינתיה ,105 ,החרובים
616 ,רינתיה ,106 ,הכלנית
616 ,רינתיה ,103 ,הסיגלון
616 ,רינתיה ,110 ,הפקאן
616 ,רינתיה ,107 ,הרותם
616 ,רינתיה ,108 ,הרימון
616 ,רינתיה ,109 ,הרקפת
616 ,רינתיה ,6002 ,ככר האורנים
616 ,רינתיה ,6003 ,ככר הזכרון
616 ,רינתיה ,6001 ,ככר המייסדים
616 ,רינתיה ,9000 ,רינתיה
922 ,רכסים ,152 ,אשל
922 ,רכסים ,132 ,בני תורה
922 ,רכסים ,6509 ,גבעה א
922 ,רכסים ,6507 ,גבעה ג
922 ,רכסים ,6508 ,גבעה ד
922 ,רכסים ,6506 ,גבעת חרובים
922 ,רכסים ,151 ,דפנה
922 ,רכסים ,150 ,דקל
922 ,רכסים ,114 ,דרך המדרשים
922 ,רכסים ,162 ,דרך הרב משקובסקי
922 ,רכסים ,161 ,דרך פלץ יוסף
922 ,רכסים ,113 ,דרך שרה
922 ,רכסים ,123 ,האורנים
922 ,רכסים ,133 ,האירוסים
922 ,רכסים ,128 ,האלונים
922 ,רכסים ,129 ,הגפן
922 ,רכסים ,157 ,הדס
922 ,רכסים ,131 ,הורדים
922 ,רכסים ,156 ,החזון איש
922 ,רכסים ,148 ,החרוב
922 ,רכסים ,125 ,הכלניות
922 ,רכסים ,126 ,הנרקיסים
922 ,רכסים ,127 ,הרב עובדיה יוסף
922 ,רכסים ,118 ,הרב עוזיאל
922 ,רכסים ,122 ,הרב קוק
922 ,רכסים ,112 ,הרב קלרמן
922 ,רכסים ,124 ,הרימונים
922 ,רכסים ,155 ,השקד
922 ,רכסים ,154 ,השקמים
922 ,רכסים ,147 ,זית
922 ,רכסים ,144 ,חרצית
922 ,רכסים ,6002 ,ככר אלון יגאל
922 ,רכסים ,6001 ,ככר מימון
922 ,רכסים ,135 ,לב אליהו
922 ,רכסים ,159 ,לילך
922 ,רכסים ,163 ,מעלות דוד
922 ,רכסים ,143 ,מרגנית
922 ,רכסים ,142 ,נורית
922 ,רכסים ,149 ,סביון
922 ,רכסים ,134 ,סמ הורדים
922 ,רכסים ,160 ,ערבה
922 ,רכסים ,141 ,פרג
922 ,רכסים ,6500 ,קרית אור חדש
922 ,רכסים ,153 ,רותם
922 ,רכסים ,9000 ,רכסים
922 ,רכסים ,6501 ,ש משכנות
922 ,רכסים ,6502 ,ש עמידר
922 ,רכסים ,6503 ,ש עמידר גב ב
922 ,רכסים ,6504 ,ש רסקו
922 ,רכסים ,119 ,שמעונוביץ
922 ,רכסים ,145 ,שעורה
922 ,רכסים ,158 ,תאנה
922 ,רכסים ,146 ,תמר
1069 ,רם-און ,9000 ,רם-און
4702 ,רמות ,9000 ,רמות
206 ,רמות השבים ,111 ,בית העם
206 ,רמות השבים ,134 ,דרך המלך
206 ,רמות השבים ,125 ,דרך הסולטן
206 ,רמות השבים ,104 ,האלונים
206 ,רמות השבים ,132 ,האקליפטוס
206 ,רמות השבים ,105 ,הבנים
206 ,רמות השבים ,106 ,הברושים
206 ,רמות השבים ,114 ,הבריכה
206 ,רמות השבים ,113 ,הגבעה
206 ,רמות השבים ,124 ,הדקל
206 ,רמות השבים ,133 ,החורשה
206 ,רמות השבים ,126 ,החצב
206 ,רמות השבים ,117 ,הירקנים
206 ,רמות השבים ,107 ,הכרם
206 ,רמות השבים ,102 ,הלימון
206 ,רמות השבים ,103 ,המיסדים
206 ,רמות השבים ,112 ,המעגל
206 ,רמות השבים ,122 ,המשאבים
206 ,רמות השבים ,115 ,הפקן
206 ,רמות השבים ,119 ,הפרדס
206 ,רמות השבים ,108 ,הצבר
206 ,רמות השבים ,118 ,השמש העולה
206 ,רמות השבים ,116 ,השקד
206 ,רמות השבים ,128 ,התות
206 ,רמות השבים ,101 ,התמר
206 ,רמות השבים ,130 ,התרנגול
206 ,רמות השבים ,120 ,מעלה הורדים
206 ,רמות השבים ,129 ,נווה צאלים
206 ,רמות השבים ,127 ,סמ אל על
206 ,רמות השבים ,131 ,סמ השדה
206 ,רמות השבים ,123 ,סמ מגן
206 ,רמות השבים ,121 ,סמטת הנוער
206 ,רמות השבים ,9000 ,רמות השבים
206 ,רמות השבים ,110 ,שרת משה
735 ,רמות מאיר ,105 ,גבעת הכלניות
735 ,רמות מאיר ,107 ,דרך החממות
735 ,רמות מאיר ,106 ,דרך הלולים
735 ,רמות מאיר ,108 ,דרך הפרדסים
735 ,רמות מאיר ,110 ,דרך השדות
735 ,רמות מאיר ,104 ,הורדים
735 ,רמות מאיר ,109 ,המחצבה
735 ,רמות מאיר ,102 ,הראשונים
735 ,רמות מאיר ,114 ,השקמה
735 ,רמות מאיר ,103 ,נתיב העליה
735 ,רמות מאיר ,111 ,סמ הבאר
735 ,רמות מאיר ,113 ,סמ היקב
735 ,רמות מאיר ,101 ,קהילות צפון אפריקה
735 ,רמות מאיר ,9000 ,רמות מאיר
735 ,רמות מאיר ,112 ,שד הזיתים
445 ,רמות מנשה ,106 ,בוני הנגב
445 ,רמות מנשה ,101 ,בית ראשון במולדת
445 ,רמות מנשה ,111 ,האקליפטוס
445 ,רמות מנשה ,110 ,הברושים
445 ,רמות מנשה ,117 ,הגיא
445 ,רמות מנשה ,104 ,ההקמה
445 ,רמות מנשה ,113 ,החלב
445 ,רמות מנשה ,103 ,המייסדים
445 ,רמות מנשה ,114 ,הנוי
445 ,רמות מנשה ,112 ,הסלע הגדול
445 ,רמות מנשה ,105 ,העליה
445 ,רמות מנשה ,107 ,הקומונה
445 ,רמות מנשה ,108 ,הרקפות
445 ,רמות מנשה ,102 ,השדות
445 ,רמות מנשה ,109 ,התורמוסים
445 ,רמות מנשה ,6002 ,כיכר החקלאים
445 ,רמות מנשה ,6003 ,כיכר החרוב
445 ,רמות מנשה ,6001 ,כיכר המעיינות
445 ,רמות מנשה ,6004 ,כיכר לה-ספיצה
445 ,רמות מנשה ,116 ,מעלה הנחל
445 ,רמות מנשה ,118 ,משעול המטע
445 ,רמות מנשה ,115 ,עין מחוללים
445 ,רמות מנשה ,9000 ,רמות מנשה
372 ,רמות נפתלי ,9000 ,רמות נפתלי
8500 ,רמלה ,421 ,אבו חצירה
8500 ,רמלה ,496 ,אבן חלדון
8500 ,רמלה ,491 ,אבן סינא
8500 ,רמלה ,331 ,אגוזי
8500 ,רמלה ,618 ,אגסי שמעון
8500 ,רמלה ,122 ,אדרת
8500 ,רמלה ,719 ,אהרון הכהן
8500 ,רמלה ,334 ,אוסישקין
8500 ,רמלה ,106 ,אזור התעשיה
8500 ,רמלה ,6542 ,אזור התעשייה א'
8500 ,רמלה ,6511 ,אזור תעשיה נשר
8500 ,רמלה ,304 ,אחד במאי
8500 ,רמלה ,333 ,אחד העם
8500 ,רמלה ,105 ,אחווה ושלום
8500 ,רמלה ,252 ,אחימאיר אבא
8500 ,רמלה ,227 ,אינשטיין
8500 ,רמלה ,547 ,איפרגן שלום
8500 ,רמלה ,493 ,אל בוסתן
8500 ,רמלה ,490 ,אל זהרא
8500 ,רמלה ,140 ,אל זוהור
8500 ,רמלה ,497 ,אל נור
8500 ,רמלה ,492 ,אל סולטאן
8500 ,רמלה ,143 ,אל סינדיאן
8500 ,רמלה ,142 ,אל סנאביל
8500 ,רמלה ,141 ,אל ריחאן
8500 ,רמלה ,546 ,אלבז נתן
8500 ,רמלה ,736 ,אלוני ניסים
8500 ,רמלה ,111 ,אלי כהן
8500 ,רמלה ,517 ,אליהו הנביא
8500 ,רמלה ,278 ,אלמוג
8500 ,רמלה ,744 ,אמדורסקי בני
8500 ,רמלה ,127 ,אמונה
8500 ,רמלה ,606 ,אמיל זולא
8500 ,רמלה ,461 ,אמנון ותמר
8500 ,רמלה ,139 ,אמנון ליפקין שחק
8500 ,רמלה ,210 ,אנגל
8500 ,רמלה ,6530 ,אסבסטונים
8500 ,רמלה ,712 ,אסתר המלכה
8500 ,רמלה ,110 ,אצ"ל
8500 ,רמלה ,229 ,אקסודוס
8500 ,רמלה ,751 ,ארגוב סשה
8500 ,רמלה ,320 ,ארלוזורוב
8500 ,רמלה ,781 ,אתרוג
8500 ,רמלה ,433 ,בוגנים אהרון
8500 ,רמלה ,342 ,בורוכוב
8500 ,רמלה ,365 ,בטה עזרא
8500 ,רמלה ,502 ,ביאליק
8500 ,רמלה ,336 ,בילינסון
8500 ,רמלה ,411 ,בינט מאיר
8500 ,רמלה ,103 ,בית הסוהר
8500 ,רמלה ,211 ,בית"ר
8500 ,רמלה ,220 ,בלפור
8500 ,רמלה ,255 ,בן אליעזר אריה
8500 ,רמלה ,437 ,בן גוריון
8500 ,רמלה ,206 ,בן יהודה
8500 ,רמלה ,312 ,בן יוסף אהרון
8500 ,רמלה ,535 ,בן יוסף שלמה
8500 ,רמלה ,258 ,בן עטר חיים
8500 ,רמלה ,417 ,בן צבי יצחק
8500 ,רמלה ,752 ,בנאי יוסי
8500 ,רמלה ,711 ,בעל שם טוב
8500 ,רמלה ,114 ,בעלי המלאכה
8500 ,רמלה ,302 ,בר אילן
8500 ,רמלה ,541 ,בר יוחאי
8500 ,רמלה ,420 ,ברזילי
8500 ,רמלה ,361 ,ברלב חיים
8500 ,רמלה ,346 ,ברנר
8500 ,רמלה ,494 ,ג'ובראן חליל
8500 ,רמלה ,6548 ,ג'וואריש
8500 ,רמלה ,424 ,גבורי ישראל
8500 ,רמלה ,714 ,גבע
8500 ,רמלה ,6537 ,גבעת הדר
8500 ,רמלה ,705 ,גדעון
8500 ,רמלה ,753 ,גוטמן נחום
8500 ,רמלה ,224 ,גולומב
8500 ,רמלה ,345 ,גורדון
8500 ,רמלה ,423 ,גטו ורשה
8500 ,רמלה ,6500 ,גן חקל
8500 ,רמלה ,6550 ,גני דן
8500 ,רמלה ,222 ,גרץ
8500 ,רמלה ,610 ,ד"ר סאלק
8500 ,רמלה ,612 ,ד"ר קוך
8500 ,רמלה ,526 ,דבורה הנביאה
8500 ,רמלה ,548 ,דדון יעקב
8500 ,רמלה ,280 ,דוגית
8500 ,רמלה ,438 ,דוד אלעזר
8500 ,רמלה ,703 ,דוד המלך
8500 ,רמלה ,124 ,דוכיפת
8500 ,רמלה ,750 ,דותן דודו
8500 ,רמלה ,410 ,דיין משה
8500 ,רמלה ,737 ,דמארי שושנה
8500 ,רמלה ,138 ,דן שומרון
8500 ,רמלה ,791 ,דרור
8500 ,רמלה ,353 ,האורן
8500 ,רמלה ,350 ,האילנות
8500 ,רמלה ,777 ,האיריסים
8500 ,רמלה ,354 ,האלה
8500 ,רמלה ,448 ,האלונים
8500 ,רמלה ,495 ,הארון אלרשיד
8500 ,רמלה ,500 ,הארזים
8500 ,רמלה ,451 ,האשל
8500 ,רמלה ,352 ,הברוש
8500 ,רמלה ,218 ,הגדוד העברי
8500 ,רמלה ,479 ,הגלעד
8500 ,רמלה ,603 ,הגלעדי
8500 ,רמלה ,447 ,הגפן
8500 ,רמלה ,128 ,הגשמה
8500 ,רמלה ,355 ,הדודאים
8500 ,רמלה ,368 ,הדס
8500 ,רמלה ,760 ,הדקל
8500 ,רמלה ,348 ,הדר
8500 ,רמלה ,240 ,ההגנה
8500 ,רמלה ,343 ,הוז דב
8500 ,רמלה ,440 ,הולצברג שמחה
8500 ,רמלה ,512 ,הושע
8500 ,רמלה ,446 ,הזית
8500 ,רמלה ,609 ,הח'ליפה סלימאן
8500 ,רמלה ,449 ,החבצלת
8500 ,רמלה ,129 ,החזון
8500 ,רמלה ,340 ,החלוץ
8500 ,רמלה ,108 ,החשמונאים
8500 ,רמלה ,263 ,החשמל
8500 ,רמלה ,763 ,היסמין
8500 ,רמלה ,112 ,היצירה
8500 ,רמלה ,476 ,הירדן
8500 ,רמלה ,761 ,הכלנית
8500 ,רמלה ,477 ,הכרמל
8500 ,רמלה ,462 ,הלילך
8500 ,רמלה ,403 ,הלל אברהם
8500 ,רמלה ,723 ,הלל הזקן
8500 ,רמלה ,505 ,המגדל הלבן
8500 ,רמלה ,234 ,המכבים
8500 ,רמלה ,616 ,המסדר הפרנציסקני
8500 ,רמלה ,614 ,המעפילים
8500 ,רמלה ,6513 ,המרכז האזרחי
8500 ,רמלה ,359 ,הניצן
8500 ,רמלה ,726 ,הס
8500 ,רמלה ,113 ,העבודה
8500 ,רמלה ,6512 ,העיר העתיקה
8500 ,רמלה ,335 ,הפלמ"ח
8500 ,רמלה ,782 ,הפעמונית
8500 ,רמלה ,430 ,הצנחנים
8500 ,רמלה ,136 ,הקדוש ג'וארג'יוס
8500 ,רמלה ,116 ,הר הגלבוע
8500 ,רמלה ,118 ,הר חרמון
8500 ,רמלה ,117 ,הר מירון
8500 ,רמלה ,718 ,הר סיני
8500 ,רמלה ,120 ,הר עצמון
8500 ,רמלה ,219 ,הראל
8500 ,רמלה ,250 ,הרב אפריאט
8500 ,רמלה ,503 ,הרב הרצוג
8500 ,רמלה ,709 ,הרב טולידנו
8500 ,רמלה ,253 ,הרב לוין אריה
8500 ,רמלה ,315 ,הרב מימון
8500 ,רמלה ,325 ,הרב עוזיאל
8500 ,רמלה ,375 ,הרב עופרי שלום
8500 ,רמלה ,241 ,הרב פרנקל
8500 ,רמלה ,602 ,הרב פתיה יחזקאל
8500 ,רמלה ,318 ,הרב קוק
8500 ,רמלה ,322 ,הרב ריינס
8500 ,רמלה ,260 ,הרב שרעבי מרדכי
8500 ,רמלה ,728 ,הרב ששון ישראל
8500 ,רמלה ,605 ,הרב ששון שמחה
8500 ,רמלה ,274 ,הרדוף
8500 ,רמלה ,121 ,הרי הבשן
8500 ,רמלה ,119 ,הרי הגולן
8500 ,רמלה ,115 ,הרי יהודה
8500 ,רמלה ,775 ,הרמ"א
8500 ,רמלה ,316 ,הרמב"ם
8500 ,רמלה ,762 ,הרקפת
8500 ,רמלה ,233 ,השומר
8500 ,רמלה ,443 ,השלום
8500 ,רמלה ,367 ,השקמה
8500 ,רמלה ,431 ,השריון
8500 ,רמלה ,356 ,התאנים
8500 ,רמלה ,478 ,התבור
8500 ,רמלה ,450 ,התמר
8500 ,רמלה ,130 ,התקווה
8500 ,רמלה ,428 ,התשעה
8500 ,רמלה ,754 ,וולך יונה
8500 ,רמלה ,413 ,וילנה
8500 ,רמלה ,730 ,וינגייט
8500 ,רמלה ,339 ,ויתקין
8500 ,רמלה ,418 ,וכיל יחזקאל
8500 ,רמלה ,458 ,ורדים
8500 ,רמלה ,701 ,ז'בוטינסקי
8500 ,רמלה ,511 ,זכריה
8500 ,רמלה ,275 ,זמיר
8500 ,רמלה ,611 ,חביבי אמיל
8500 ,רמלה ,532 ,חברון
8500 ,רמלה ,508 ,חגי
8500 ,רמלה ,795 ,חוחית
8500 ,רמלה ,740 ,חזה עפרה
8500 ,רמלה ,745 ,חזקיהו אבנר
8500 ,רמלה ,429 ,חטיבת גולני
8500 ,רמלה ,427 ,חטיבת קריתי
8500 ,רמלה ,738 ,חיטמן עוזי
8500 ,רמלה ,305 ,חיים אשר
8500 ,רמלה ,132 ,חיים לסקוב
8500 ,רמלה ,604 ,חניתה
8500 ,רמלה ,457 ,טוינה אברהם
8500 ,רמלה ,358 ,טופז
8500 ,רמלה ,459 ,טטראשווילי יצחק
8500 ,רמלה ,444 ,טייטלבום
8500 ,רמלה ,225 ,טרומפלדור
8500 ,רמלה ,204 ,טשרניחובסקי
8500 ,רמלה ,534 ,יבנה
8500 ,רמלה ,145 ,יגאל אלון
8500 ,רמלה ,133 ,יגאל ידין
8500 ,רמלה ,746 ,ידין יוסי
8500 ,רמלה ,203 ,יהודה הלוי
8500 ,רמלה ,755 ,יהונתן נתן
8500 ,רמלה ,513 ,יהושע בן נון
8500 ,רמלה ,529 ,יואל
8500 ,רמלה ,460 ,יובלים
8500 ,רמלה ,524 ,יונה הנביא
8500 ,רמלה ,404 ,יוסף חיים
8500 ,רמלה ,364 ,יוספטל גיורא
8500 ,רמלה ,230 ,יורדי הים
8500 ,רמלה ,523 ,יחזקאל
8500 ,רמלה ,716 ,יעל
8500 ,רמלה ,144 ,יעקב דורי
8500 ,רמלה ,722 ,יצחק שלמה
8500 ,רמלה ,518 ,ירמיהו
8500 ,רמלה ,525 ,ישעיהו
8500 ,רמלה ,6003 ,ככר בריל
8500 ,רמלה ,6004 ,ככר דן
8500 ,רמלה ,6001 ,ככר ההגנה
8500 ,רמלה ,6005 ,ככר החשמל
8500 ,רמלה ,6002 ,ככר המדינה
8500 ,רמלה ,475 ,כנרת
8500 ,רמלה ,6549 ,כפר מיעוטים
8500 ,רמלה ,307 ,כצנלסון
8500 ,רמלה ,464 ,לבונה
8500 ,רמלה ,756 ,לביא אריק
8500 ,רמלה ,262 ,לוחמי בית"ר
8500 ,רמלה ,326 ,לוחמי גוש עציון
8500 ,רמלה ,232 ,לוחמי הבריגדה
8500 ,רמלה ,405 ,לוחמי הגטאות
8500 ,רמלה ,436 ,לוי אליהו סלם
8500 ,רמלה ,419 ,לוי אשכול
8500 ,רמלה ,704 ,לוי יחזקאל
8500 ,רמלה ,743 ,לוין חנוך
8500 ,רמלה ,205 ,לילינבלום
8500 ,רמלה ,104 ,לכיש
8500 ,רמלה ,257 ,לנדאו חיים
8500 ,רמלה ,735 ,מאיר אריאל
8500 ,רמלה ,439 ,מבצע יונתן
8500 ,רמלה ,465 ,מבצע משה
8500 ,רמלה ,313 ,מבצע קדש
8500 ,רמלה ,323 ,מוהליבר
8500 ,רמלה ,725 ,מונטיפיורי
8500 ,רמלה ,308 ,מוצקין
8500 ,רמלה ,463 ,מור
8500 ,רמלה ,542 ,מיכה
8500 ,רמלה ,521 ,מלאכי
8500 ,רמלה ,747 ,מנור אהוד
8500 ,רמלה ,702 ,מנחם דניאל
8500 ,רמלה ,314 ,מס דני
8500 ,רמלה ,527 ,מסדה
8500 ,רמלה ,6531 ,מעברה ב
8500 ,רמלה ,6532 ,מעברה ג
8500 ,רמלה ,351 ,מרגנית
8500 ,רמלה ,134 ,מרדכי גור
8500 ,רמלה ,107 ,מרדכי היהודי
8500 ,רמלה ,137 ,מרדכי מקלף
8500 ,רמלה ,442 ,מרדכי סעדיה
8500 ,רמלה ,341 ,מרזוק
8500 ,רמלה ,545 ,מרים הנביאה
8500 ,רמלה ,226 ,מרכוס
8500 ,רמלה ,131 ,משה לוי
8500 ,רמלה ,516 ,משה רבנו
8500 ,רמלה ,6540 ,נאות יצחק רבין
8500 ,רמלה ,6544 ,נוה הדרים
8500 ,רמלה ,6545 ,נוה מאיר
8500 ,רמלה ,6551 ,נווה דקלים
8500 ,רמלה ,6508 ,נווה יהונתן
8500 ,רמלה ,6539 ,נווה רם
8500 ,רמלה ,213 ,נורדאו
8500 ,רמלה ,357 ,נורית
8500 ,רמלה ,509 ,נחמיה
8500 ,רמלה ,277 ,נחשול
8500 ,רמלה ,366 ,סביון
8500 ,רמלה ,514 ,סומך עובדיה
8500 ,רמלה ,310 ,סוקולוב
8500 ,רמלה ,306 ,סטרומה
8500 ,רמלה ,794 ,סיקסק
8500 ,רמלה ,330 ,סמולנסקין
8500 ,רמלה ,425 ,סמטת תוחלת
8500 ,רמלה ,279 ,סנפיר
8500 ,רמלה ,217 ,סנש חנה
8500 ,רמלה ,706 ,סעדיה גאון
8500 ,רמלה ,778 ,ספיר
8500 ,רמלה ,519 ,עובדיה
8500 ,רמלה ,543 ,עזרא
8500 ,רמלה ,715 ,עין דור
8500 ,רמלה ,785 ,עין חמד
8500 ,רמלה ,520 ,עמוס
8500 ,רמלה ,416 ,עמיחי
8500 ,רמלה ,739 ,עמיחי יהודה
8500 ,רמלה ,327 ,עמל
8500 ,רמלה ,231 ,פולד ברכה
8500 ,רמלה ,757 ,פוליאקוב ישראל
8500 ,רמלה ,720 ,פועלי ציון
8500 ,רמלה ,324 ,פינס
8500 ,רמלה ,332 ,פינסקר
8500 ,רמלה ,506 ,פלמינג
8500 ,רמלה ,319 ,פנקס
8500 ,רמלה ,619 ,פסטר
8500 ,רמלה ,537 ,פקיעין
8500 ,רמלה ,515 ,פרויד
8500 ,רמלה ,261 ,פרופ' בוריס נמטייב
8500 ,רמלה ,329 ,פרנקל ישראל
8500 ,רמלה ,309 ,פרץ
8500 ,רמלה ,792 ,פשוש
8500 ,רמלה ,544 ,צדקה משה
8500 ,רמלה ,415 ,צה"ל
8500 ,רמלה ,123 ,צופית
8500 ,רמלה ,530 ,צור
8500 ,רמלה ,538 ,צידון
8500 ,רמלה ,776 ,ציון אשר
8500 ,רמלה ,256 ,צלח מנשה
8500 ,רמלה ,360 ,צפור גן עדן
8500 ,רמלה ,522 ,צפניה
8500 ,רמלה ,536 ,צפת
8500 ,רמלה ,6505 ,צריפי עיריה
8500 ,רמלה ,613 ,קהילת דטרויט
8500 ,רמלה ,270 ,קורצ'אק
8500 ,רמלה ,748 ,קישון אפרים
8500 ,רמלה ,724 ,קלוזנר
8500 ,רמלה ,406 ,קליטה עירונית
8500 ,רמלה ,344 ,קפלן
8500 ,רמלה ,6543 ,קרית האומנים
8500 ,רמלה ,6506 ,קרית מנחם בגין
8500 ,רמלה ,434 ,קרמר אריה
8500 ,רמלה ,426 ,קרן היסוד
8500 ,רמלה ,125 ,רבאט
8500 ,רמלה ,742 ,רביקוביץ דליה
8500 ,רמלה ,456 ,רותם
8500 ,רמלה ,441 ,רזיאל דוד
8500 ,רמלה ,238 ,רח כ
8500 ,רמלה ,236 ,רח לח
8500 ,רמלה ,533 ,רח עה
8500 ,רמלה ,710 ,רח רז
8500 ,רמלה ,617 ,רח תבת
8500 ,רמלה ,620 ,רח תיג
8500 ,רמלה ,528 ,רח תיז
8500 ,רמלה ,607 ,רח תכא
8500 ,רמלה ,259 ,רייסר מיכה
8500 ,רמלה ,9000 ,רמלה
8500 ,רמלה ,6538 ,רמת דן
8500 ,רמלה ,135 ,רפאל איתן
8500 ,רמלה ,708 ,רש"י
8500 ,רמלה ,6501 ,ש אגש
8500 ,רמלה ,6502 ,ש בילו
8500 ,רמלה ,6533 ,ש גיורא
8500 ,רמלה ,6534 ,ש דו משפחתי
8500 ,רמלה ,6517 ,ש חסכון
8500 ,רמלה ,6536 ,ש כביש הטבעת
8500 ,רמלה ,6519 ,ש מגדל אילון
8500 ,רמלה ,6520 ,ש מול העיריה
8500 ,רמלה ,6521 ,ש מזרחי
8500 ,רמלה ,6523 ,ש משכנות
8500 ,רמלה ,6524 ,ש נוה דוד
8500 ,רמלה ,6525 ,ש נשר
8500 ,רמלה ,6526 ,ש עובדים
8500 ,רמלה ,6527 ,ש עולים
8500 ,רמלה ,6528 ,ש עממי
8500 ,רמלה ,6535 ,ש קרוונים
8500 ,רמלה ,435 ,ש"ץ גרשון
8500 ,רמלה ,412 ,שבזי
8500 ,רמלה ,301 ,שד הרצל
8500 ,רמלה ,303 ,שד ויצמן
8500 ,רמלה ,208 ,שדה יצחק
8500 ,רמלה ,276 ,שונית
8500 ,רמלה ,432 ,שטיין יהודה
8500 ,רמלה ,251 ,שטרן יאיר
8500 ,רמלה ,337 ,שיבת ציון
8500 ,רמלה ,271 ,שיזף
8500 ,רמלה ,273 ,שיטה
8500 ,רמלה ,741 ,שייקה אופיר
8500 ,רמלה ,6510 ,שכ אשכול
8500 ,רמלה ,6546 ,שכ בן גוריון
8500 ,רמלה ,6529 ,שכ הגבעה
8500 ,רמלה ,6556 ,שכ הפועלים
8500 ,רמלה ,6503 ,שכ הרכבת
8500 ,רמלה ,6518 ,שכ השופטים
8500 ,רמלה ,6509 ,שכ וייצמן
8500 ,רמלה ,6547 ,שכ יפה נוף
8500 ,רמלה ,6504 ,שכ צר עמידר
8500 ,רמלה ,6507 ,שכ רג"מ
8500 ,רמלה ,6555 ,שכ שפרינצק
8500 ,רמלה ,793 ,שלדג
8500 ,רמלה ,321 ,שלום עליכם
8500 ,רמלה ,707 ,שלמה המלך
8500 ,רמלה ,779 ,שמ"ח
8500 ,רמלה ,507 ,שמואל הנביא
8500 ,רמלה ,328 ,שמחוני
8500 ,רמלה ,749 ,שמר נעמי
8500 ,רמלה ,531 ,שמש משה
8500 ,רמלה ,501 ,שמשון הגבור
8500 ,רמלה ,347 ,שפינוזה
8500 ,רמלה ,608 ,שפיק עדס
8500 ,רמלה ,214 ,שפירא אברהם
8500 ,רמלה ,422 ,שפירא משה חיים
8500 ,רמלה ,311 ,שפרינצק
8500 ,רמלה ,414 ,שרת
8500 ,רמלה ,202 ,תל חי
8500 ,רמלה ,272 ,תלתן
8500 ,רמלה ,254 ,תמיר שמואל
8500 ,רמלה ,126 ,תקומה
8600 ,רמת גן ,1653 ,אבא ברדיצ'ב
8600 ,רמת גן ,810 ,אבטליון
8600 ,רמת גן ,224 ,אבי הר-אבן
8600 ,רמת גן ,116 ,אביבים
8600 ,רמת גן ,300 ,אביגדור קרמר
8600 ,רמת גן ,106 ,אביגיל
8600 ,רמת גן ,1307 ,אבישי
8600 ,רמת גן ,1667 ,אבן אביתור
8600 ,רמת גן ,1666 ,אבן חביב
8600 ,רמת גן ,1507 ,אבן ספיר
8600 ,רמת גן ,1608 ,אבן עזרא
8600 ,רמת גן ,1651 ,אבני החושן
8600 ,רמת גן ,1510 ,אבנר מאיר
8600 ,רמת גן ,3117 ,אגמון רמת אפעל
8600 ,רמת גן ,1308 ,אהוד
8600 ,רמת גן ,406 ,אהליאב
8600 ,רמת גן ,1627 ,אהרונסון שרה
8600 ,רמת גן ,1638 ,אונברסיטה בר אילן
8600 ,רמת גן ,717 ,אורה
8600 ,רמת גן ,1010 ,אורי ניסן
8600 ,רמת גן ,1723 ,אוריאל פרץ
8600 ,רמת גן ,1762 ,אורן
8600 ,רמת גן ,6549 ,אזור הבילויים
8600 ,רמת גן ,6510 ,אזור התעשיה
8600 ,רמת גן ,1704 ,אחד העם
8600 ,רמת גן ,123 ,אחימאיר אבא
8600 ,רמת גן ,1509 ,אינשטיין
8600 ,רמת גן ,225 ,אירנה נוסקוביץ
8600 ,רמת גן ,141 ,איתמר
8600 ,רמת גן ,1747 ,איתן יונה
8600 ,רמת גן ,3101 ,אל על רמת אפעל
8600 ,רמת גן ,3102 ,אלומות רמת אפעל
8600 ,רמת גן ,313 ,אלונים
8600 ,רמת גן ,1801 ,אלוף דוד
8600 ,רמת גן ,1108 ,אלוף הנצחון
8600 ,רמת גן ,1721 ,אלוף יצחק חופי
8600 ,רמת גן ,1838 ,אלוף שדה
8600 ,רמת גן ,1024 ,אלוף שמחוני
8600 ,רמת גן ,138 ,אלטלנה
8600 ,רמת גן ,809 ,אלימלך
8600 ,רמת גן ,1724 ,אלירז פרץ
8600 ,רמת גן ,1312 ,אלישיב
8600 ,רמת גן ,1836 ,אלישע
8600 ,רמת גן ,718 ,אלכסנדר
8600 ,רמת גן ,1831 ,אלכסנדרוני
8600 ,רמת גן ,3155 ,אלמוג רמת אפעל
8600 ,רמת גן ,1316 ,אלרואי
8600 ,רמת גן ,1656 ,אלרום
8600 ,רמת גן ,1525 ,אמיר
8600 ,רמת גן ,1115 ,אמנון ותמר
8600 ,רמת גן ,504 ,אנגלנדר יהודה
8600 ,רמת גן ,102 ,אנה פרנק
8600 ,רמת גן ,127 ,אנקורים
8600 ,רמת גן ,109 ,אסף
8600 ,רמת גן ,6640 ,אפעל-מרכז סמינריוני
8600 ,רמת גן ,1513 ,אפרים
8600 ,רמת גן ,1613 ,אצ"ל
8600 ,רמת גן ,3113 ,ארגמן רמת אפעל
8600 ,רמת גן ,137 ,אריה מולודיק
8600 ,רמת גן ,601 ,ארלוזורוב
8600 ,רמת גן ,1617 ,ארנון
8600 ,רמת גן ,222 ,ארניה
8600 ,רמת גן ,217 ,אשתאול
8600 ,רמת גן ,1125 ,באדר יוחנן
8600 ,רמת גן ,1654 ,באר אורה
8600 ,רמת גן ,1318 ,באר שבע
8600 ,רמת גן ,319 ,בדנר
8600 ,רמת גן ,509 ,בועז
8600 ,רמת גן ,6586 ,בורסה
8600 ,רמת גן ,415 ,בורסת היהלומים
8600 ,רמת גן ,701 ,ביאליק
8600 ,רמת גן ,515 ,בית אל
8600 ,רמת גן ,1012 ,בית הלל
8600 ,רמת גן ,1628 ,בית הערבה
8600 ,רמת גן ,609 ,בית חורון
8600 ,רמת גן ,1303 ,בית לחם
8600 ,רמת גן ,1013 ,בית שמאי
8600 ,רמת גן ,1505 ,בן אליעזר אריה
8600 ,רמת גן ,3308 ,בן גוריון כפר אז"ר
8600 ,רמת גן ,704 ,בן יוסף
8600 ,רמת גן ,1629 ,בן סרוק
8600 ,רמת גן ,223 ,בן שמן
8600 ,רמת גן ,1804 ,בני הנביאים
8600 ,רמת גן ,1006 ,בני משה
8600 ,רמת גן ,1713 ,בניהו
8600 ,רמת גן ,315 ,בנימין
8600 ,רמת גן ,1543 ,בעל שם טוב
8600 ,רמת גן ,407 ,בצלאל
8600 ,רמת גן ,1737 ,בקעת ארבל
8600 ,רמת גן ,1736 ,בקעת גינוסר
8600 ,רמת גן ,616 ,בר גיורא
8600 ,רמת גן ,1536 ,בר יוחאי
8600 ,רמת גן ,1306 ,בר כוכבא
8600 ,רמת גן ,1542 ,ברודצקי
8600 ,רמת גן ,101 ,ברוריה
8600 ,רמת גן ,1760 ,ברוש
8600 ,רמת גן ,1726 ,ברנשטיין פרץ
8600 ,רמת גן ,1102 ,ברקאי
8600 ,רמת גן ,3151 ,ברקת רמת אפעל
8600 ,רמת גן ,1765 ,בשמת
8600 ,רמת גן ,1539 ,בת שוע
8600 ,רמת גן ,215 ,גאולים
8600 ,רמת גן ,1715 ,גבעת גאולה
8600 ,רמת גן ,6573 ,גבעת הברושים
8600 ,רמת גן ,1819 ,גבעתי
8600 ,רמת גן ,1415 ,גדעון
8600 ,רמת גן ,1110 ,גולומב
8600 ,רמת גן ,1829 ,גולני
8600 ,רמת גן ,1208 ,גורי יהודה
8600 ,רמת גן ,1800 ,גיבורי מלחמת יוה"כ
8600 ,רמת גן ,1746 ,גילדסגיים
8600 ,רמת גן ,1741 ,גפונוב
8600 ,רמת גן ,3107 ,גרופית רמת אפעל
8600 ,רמת גן ,1403 ,גרנדוס
8600 ,רמת גן ,1603 ,גרשום
8600 ,רמת גן ,1734 ,ד"ר אליהו
8600 ,רמת גן ,707 ,ד"ר כהן
8600 ,רמת גן ,401 ,דב
8600 ,רמת גן ,1526 ,דבורה הנביאה
8600 ,רמת גן ,804 ,דוד
8600 ,רמת גן ,3145 ,דולב רמת אפעל
8600 ,רמת גן ,1609 ,דונש
8600 ,רמת גן ,1550 ,דיין משה
8600 ,רמת גן ,1630 ,דן
8600 ,רמת גן ,1771 ,דן שומרון
8600 ,רמת גן ,1663 ,דנגור
8600 ,רמת גן ,1824 ,דניאל
8600 ,רמת גן ,1842 ,דקר
8600 ,רמת גן ,108 ,דרך אבא הלל
8600 ,רמת גן ,1639 ,דרך אשכול
8600 ,רמת גן ,416 ,דרך בגין מנחם
8600 ,רמת גן ,903 ,דרך בן גוריון דוד
8600 ,רמת גן ,1634 ,דרך הנשיא הראשון
8600 ,רמת גן ,1635 ,דרך התפוצות
8600 ,רמת גן ,1423 ,דרך יצחק רבין
8600 ,רמת גן ,1750 ,דרך לוד
8600 ,רמת גן ,1839 ,דרך מוטה גור
8600 ,רמת גן ,1117 ,דרך נגבה
8600 ,רמת גן ,1742 ,דרך שיבא
8600 ,רמת גן ,147 ,דרך ששת הימים
8600 ,רמת גן ,3310 ,ה באייר כפר אז"ר
8600 ,רמת גן ,1106 ,האגדה
8600 ,רמת גן ,3140 ,האגוז רמת אפעל
8600 ,רמת גן ,3139 ,האוכמניות רמת אפעל
8600 ,רמת גן ,1016 ,האורים
8600 ,רמת גן ,1730 ,האחות חיה
8600 ,רמת גן ,6013 ,האחים אל-כוויתי
8600 ,רמת גן ,412 ,האחים בז'רנו
8600 ,רמת גן ,1735 ,האחים ליטוינסקי
8600 ,רמת גן ,3119 ,האטד רמת אפעל
8600 ,רמת גן ,1767 ,האילנות
8600 ,רמת גן ,3110 ,האלה רמת אפעל
8600 ,רמת גן ,3141 ,האלון רמת אפעל
8600 ,רמת גן ,206 ,האלוף אלברט מנדלר
8600 ,רמת גן ,1621 ,האם
8600 ,רמת גן ,1107 ,האמוראים
8600 ,רמת גן ,1516 ,האר"י
8600 ,רמת גן ,318 ,הארז
8600 ,רמת גן ,801 ,האשל
8600 ,רמת גן ,3103 ,האתרוג רמת אפעל
8600 ,רמת גן ,611 ,הבאר
8600 ,רמת גן ,1631 ,הבילויים
8600 ,רמת גן ,1018 ,הבנים
8600 ,רמת גן ,3309 ,הבנים כפר אז"ר
8600 ,רמת גן ,1658 ,הבשן
8600 ,רמת גן ,1529 ,הגאון אליהו
8600 ,רמת גן ,1657 ,הגולן
8600 ,רמת גן ,1004 ,הגלגל
8600 ,רמת גן ,305 ,הגפן
8600 ,רמת גן ,904 ,הגת
8600 ,רמת גן ,3132 ,הדודאים רמת אפעל
8600 ,רמת גן ,1764 ,הדס
8600 ,רמת גן ,310 ,הדקלים
8600 ,רמת גן ,304 ,הדר
8600 ,רמת גן ,125 ,הדרורים
8600 ,רמת גן ,1612 ,ההגנה
8600 ,רמת גן ,3302 ,ההגנה כפר אז"ר
8600 ,רמת גן ,1105 ,ההלכה
8600 ,רמת גן ,3137 ,הורד רמת אפעל
8600 ,רמת גן ,1812 ,הושע
8600 ,רמת גן ,905 ,הזיתים
8600 ,רמת גן ,126 ,הזמיר
8600 ,רמת גן ,3125 ,החוגה רמת אפעל
8600 ,רמת גן ,1520 ,החי"ל
8600 ,רמת גן ,815 ,החייל האלמוני
8600 ,רמת גן ,409 ,החילזון
8600 ,רמת גן ,517 ,החיצון
8600 ,רמת גן ,1025 ,החץ
8600 ,רמת גן ,3116 ,החצב רמת אפעל
8600 ,רמת גן ,3111 ,החרוב רמת אפעל
8600 ,רמת גן ,209 ,החרושת
8600 ,רמת גן ,1659 ,החרמון
8600 ,רמת גן ,3124 ,החרצית רמת אפעל
8600 ,רמת גן ,617 ,החשמונאים
8600 ,רמת גן ,1707 ,הטייסים
8600 ,רמת גן ,1541 ,היד החזקה
8600 ,רמת גן ,203 ,היוגב
8600 ,רמת גן ,410 ,היזמה
8600 ,רמת גן ,3118 ,היסמין רמת אפעל
8600 ,רמת גן ,518 ,היצירה
8600 ,רמת גן ,1604 ,הירדן
8600 ,רמת גן ,1020 ,היתד
8600 ,רמת גן ,1575 ,הכבאים
8600 ,רמת גן ,3120 ,הלוטוס רמת אפעל
8600 ,רמת גן ,221 ,הלפיד
8600 ,רמת גן ,1820 ,המאה ואחד
8600 ,רמת גן ,1729 ,המאירי
8600 ,רמת גן ,212 ,המבדיל
8600 ,רמת גן ,1616 ,המחתרת
8600 ,רמת גן ,1003 ,המיתר
8600 ,רמת גן ,514 ,המכבי
8600 ,רמת גן ,103 ,המלאכה
8600 ,רמת גן ,1313 ,המלך יוסף
8600 ,רמת גן ,605 ,המלך ינאי
8600 ,רמת גן ,702 ,המעגל
8600 ,רמת גן ,403 ,המעיין
8600 ,רמת גן ,216 ,המעפיל
8600 ,רמת גן ,612 ,המציי"ר
8600 ,רמת גן ,210 ,המקור
8600 ,רמת גן ,111 ,המרגנית
8600 ,רמת גן ,220 ,המשטרה
8600 ,רמת גן ,508 ,המתמיד
8600 ,רמת גן ,705 ,הנגיד
8600 ,רמת גן ,1204 ,הנח"ל
8600 ,רמת גן ,3304 ,הנח"ל כפר אז"ר
8600 ,רמת גן ,1009 ,הנעורים
8600 ,רמת גן ,3136 ,הנצי"ב רמת אפעל
8600 ,רמת גן ,1015 ,הסולם
8600 ,רמת גן ,1201 ,הסנה
8600 ,רמת גן ,1717 ,הספארי
8600 ,רמת גן ,1802 ,הסרן דב
8600 ,רמת גן ,202 ,העמל
8600 ,רמת גן ,3303 ,העצמאות כפר אז"ר
8600 ,רמת גן ,3142 ,הערבה רמת אפעל
8600 ,רמת גן ,119 ,הערמונים
8600 ,רמת גן ,1702 ,הפארק הלאומי
8600 ,רמת גן ,204 ,הפודים
8600 ,רמת גן ,619 ,הפורצים
8600 ,רמת גן ,906 ,הפטמן
8600 ,רמת גן ,3301 ,הפלמ"ח כפר אז"ר
8600 ,רמת גן ,3129 ,הפרגים רמת אפעל
8600 ,רמת גן ,812 ,הפרסה
8600 ,רמת גן ,411 ,הצלע
8600 ,רמת גן ,1011 ,הרא"ה
8600 ,רמת גן ,1607 ,הראב"ע
8600 ,רמת גן ,1515 ,הראובני
8600 ,רמת גן ,1837 ,הראל
8600 ,רמת גן ,503 ,הראשונים
8600 ,רמת גן ,1773 ,הרב האלוף גורן
8600 ,רמת גן ,602 ,הרב חבה אליהו
8600 ,רמת גן ,1676 ,הרב חי טייב יצחק
8600 ,רמת גן ,1664 ,הרב לוין
8600 ,רמת גן ,1640 ,הרב לנדרס
8600 ,רמת גן ,1605 ,הרבי מריז'ין
8600 ,רמת גן ,3130 ,הרדוף רמת אפעל
8600 ,רמת גן ,408 ,הרי הגלעד
8600 ,רמת גן ,309 ,הרימון
8600 ,רמת גן ,1305 ,הרכסים
8600 ,רמת גן ,802 ,הרצל
8600 ,רמת גן ,520 ,הרקון
8600 ,רמת גן ,3126 ,הרקפת רמת אפעל
8600 ,רמת גן ,1622 ,הררי
8600 ,רמת גן ,703 ,השוטרת
8600 ,רמת גן ,1007 ,השומר
8600 ,רמת גן ,3307 ,השומר כפר אז"ר
8600 ,רמת גן ,1005 ,השורה
8600 ,רמת גן ,1116 ,השושן
8600 ,רמת גן ,3128 ,השיטה רמת אפעל
8600 ,רמת גן ,1833 ,השלושים ושלושה
8600 ,רמת גן ,3143 ,השקד רמת אפעל
8600 ,רמת גן ,1844 ,השקמה
8600 ,רמת גן ,1517 ,השר משה
8600 ,רמת גן ,1123 ,התנאים
8600 ,רמת גן ,301 ,התקוה
8600 ,רמת גן ,316 ,ויינשל אליעזר
8600 ,רמת גן ,813 ,ז"ר
8600 ,רמת גן ,205 ,ז'בוטינסקי
8600 ,רמת גן ,708 ,זוהר
8600 ,רמת גן ,822 ,זיסמן שלום
8600 ,רמת גן ,1816 ,זכריה
8600 ,רמת גן ,1114 ,חבצלת
8600 ,רמת גן ,1821 ,חבקוק
8600 ,רמת גן ,1405 ,חבר
8600 ,רמת גן ,1304 ,חברון
8600 ,רמת גן ,1808 ,חגי
8600 ,רמת גן ,1514 ,חד נס
8600 ,רמת גן ,1301 ,חורגין
8600 ,רמת גן ,1122 ,חזון אי"ש
8600 ,רמת גן ,1002 ,חיבת ציון
8600 ,רמת גן ,1772 ,חיים בר-לב
8600 ,רמת גן ,1610 ,חמי זכריה
8600 ,רמת גן ,1668 ,חמיר
8600 ,רמת גן ,1205 ,חצור
8600 ,רמת גן ,1533 ,חרוד
8600 ,רמת גן ,143 ,חרוזים
8600 ,רמת גן ,211 ,חרות
8600 ,רמת גן ,1425 ,טבנקין
8600 ,רמת גן ,1008 ,טור הברושים
8600 ,רמת גן ,1518 ,טרומן
8600 ,רמת גן ,1501 ,י"א באדר
8600 ,רמת גן ,1810 ,יאיר
8600 ,רמת גן ,1023 ,יבנה
8600 ,רמת גן ,6511 ,יד לבנים
8600 ,רמת גן ,1311 ,יד שלום
8600 ,רמת גן ,1210 ,ידידיה
8600 ,רמת גן ,115 ,ידע עם
8600 ,רמת גן ,1101 ,יהודה הנשיא
8600 ,רמת גן ,104 ,יהודית
8600 ,רמת גן ,1708 ,יהושע בן נון
8600 ,רמת גן ,1705 ,יהושפט
8600 ,רמת גן ,3150 ,יהלום רמת אפעל
8600 ,רמת גן ,110 ,יואב
8600 ,רמת גן ,3312 ,יובל כפר אז"ר
8600 ,רמת גן ,816 ,יוהנה
8600 ,רמת גן ,603 ,יוחנן
8600 ,רמת גן ,1021 ,יונה
8600 ,רמת גן ,817 ,יוסף הגלילי
8600 ,רמת גן ,3306 ,יוסף טרומפלדור
8600 ,רמת גן ,1417 ,יוסף צבי
8600 ,רמת גן ,824 ,יוספי
8600 ,רמת גן ,145 ,יורם טהרלב
8600 ,רמת גן ,1815 ,יחזקאל
8600 ,רמת גן ,511 ,יכין
8600 ,רמת גן ,1665 ,יעבץ
8600 ,רמת גן ,1528 ,יעל הגבורה
8600 ,רמת גן ,132 ,יעלים
8600 ,רמת גן ,523 ,יעקב רוזן
8600 ,רמת גן ,1763 ,יערה
8600 ,רמת גן ,1310 ,יריחו
8600 ,רמת גן ,1807 ,ירמיה
8600 ,רמת גן ,1601 ,ישורון
8600 ,רמת גן ,1825 ,ישעיהו
8600 ,רמת גן ,405 ,כביש נתיבי אילון
8600 ,רמת גן ,1745 ,כביש עוקף
8600 ,רמת גן ,1728 ,כהן יעקב
8600 ,רמת גן ,6012 ,כיכר אלוף דני מט
8600 ,רמת גן ,6011 ,כיכר מלכיאל בבייב
8600 ,רמת גן ,6014 ,כיכר סביח
8600 ,רמת גן ,6015 ,כיכר סימן-טוב גנה
8600 ,רמת גן ,6004 ,ככר גרונר
8600 ,רמת גן ,6001 ,ככר הלוחמים
8600 ,רמת גן ,6005 ,ככר העצמאות
8600 ,רמת גן ,6008 ,ככר הצבי
8600 ,רמת גן ,6006 ,ככר הציונות
8600 ,רמת גן ,6002 ,ככר הרב פרדס
8600 ,רמת גן ,6007 ,ככר יהודה
8600 ,רמת גן ,6010 ,ככר נורי מורד
8600 ,רמת גן ,6009 ,ככר עוזי חיטמן
8600 ,רמת גן ,6003 ,ככר רמב"ם
8600 ,רמת גן ,1402 ,כנפי נשרים
8600 ,רמת גן ,1619 ,כנרת
8600 ,רמת גן ,1826 ,כף גימל
8600 ,רמת גן ,6620 ,כפר אז"ר
8600 ,רמת גן ,1719 ,כפר המכביה
8600 ,רמת גן ,6600 ,כפר מסובים רמת אפעל
8600 ,רמת גן ,1834 ,כרמלי
8600 ,רמת גן ,510 ,לאן
8600 ,רמת גן ,1503 ,לב הזהב
8600 ,רמת גן ,3158 ,לבונה רמת אפעל
8600 ,רמת גן ,1218 ,לביא
8600 ,רמת גן ,1203 ,לוחמי סיני
8600 ,רמת גן ,516 ,לוי
8600 ,רמת גן ,1424 ,לויטה
8600 ,רמת גן ,1614 ,לח"י
8600 ,רמת גן ,1744 ,לייב יפה
8600 ,רמת גן ,1530 ,לנדאו חיים
8600 ,רמת גן ,1112 ,מאיר בעל הנס
8600 ,רמת גן ,139 ,מבוא אנה פרנק
8600 ,רמת גן ,720 ,מבוא אריה
8600 ,רמת גן ,805 ,מבוא דוד
8600 ,רמת גן ,218 ,מבוא העמל
8600 ,רמת גן ,1545 ,מבוא הקונגרס
8600 ,רמת גן ,322 ,מבוא הרימון
8600 ,רמת גן ,1309 ,מבוא התיכון
8600 ,רמת גן ,818 ,מבוא זאב
8600 ,רמת גן ,908 ,מבוא חרמון
8600 ,רמת גן ,808 ,מבוא יהל"ם
8600 ,רמת גן ,1727 ,מבוא מנדס
8600 ,רמת גן ,1546 ,מבוא נגבה
8600 ,רמת גן ,219 ,מבוא נס
8600 ,רמת גן ,819 ,מבוא נתן
8600 ,רמת גן ,1642 ,מבצע עין
8600 ,רמת גן ,146 ,מבצע קדש
8600 ,רמת גן ,902 ,מגדים
8600 ,רמת גן ,519 ,מודעי יצחק
8600 ,רמת גן ,114 ,מוזס נח
8600 ,רמת גן ,1111 ,מולכו
8600 ,רמת גן ,1120 ,מוצא
8600 ,רמת גן ,303 ,מורדי הגטאות
8600 ,רמת גן ,1214 ,מוריה
8600 ,רמת גן ,3104 ,מחולה רמת אפעל
8600 ,רמת גן ,1221 ,מחניים
8600 ,רמת גן ,1828 ,מחץ
8600 ,רמת גן ,1014 ,מטה אהרון
8600 ,רמת גן ,1647 ,מטולה
8600 ,רמת גן ,1113 ,מיכאל
8600 ,רמת גן ,1817 ,מיכה
8600 ,רמת גן ,823 ,מירקין
8600 ,רמת גן ,1001 ,מל"ל
8600 ,רמת גן ,1809 ,מלאכי
8600 ,רמת גן ,1725 ,מלחי שלמה
8600 ,רמת גן ,1706 ,מלכי צדק
8600 ,רמת גן ,1618 ,מנדל מתתיהו
8600 ,רמת גן ,1732 ,מנדס
8600 ,רמת גן ,1409 ,מנחם
8600 ,רמת גן ,213 ,מנחם יצחק
8600 ,רמת גן ,1216 ,מעלה הבנים
8600 ,רמת גן ,811 ,מעלה הנשר
8600 ,רמת גן ,608 ,מעלה הצבי
8600 ,רמת גן ,607 ,מעלה הצופים
8600 ,רמת גן ,302 ,מעלה השואבה
8600 ,רמת גן ,1211 ,מעלה השחר
8600 ,רמת גן ,1813 ,מצדה
8600 ,רמת גן ,512 ,מצפה
8600 ,רמת גן ,314 ,מקדונלד
8600 ,רמת גן ,1643 ,מקס ואנה ווב
8600 ,רמת גן ,1420 ,מרדכי
8600 ,רמת גן ,149 ,מרדכי בן פורת
8600 ,רמת גן ,614 ,מרים
8600 ,רמת גן ,1748 ,מרכז רפואי ע"ש שיבא
8600 ,רמת גן ,1644 ,משה גמליאל
8600 ,רמת גן ,3105 ,משואה רמת אפעל
8600 ,רמת גן ,1648 ,משמר הירדן
8600 ,רמת גן ,725 ,משעול ספראי
8600 ,רמת גן ,320 ,משעול צבי ינאי
8600 ,רמת גן ,6513 ,מתחם האיצטדיון
8600 ,רמת גן ,1716 ,מתחם מסובים
8600 ,רמת גן ,6515 ,מתחם נגבה
8600 ,רמת גן ,6590 ,מתחם קניון איילון
8600 ,רמת גן ,1720 ,מתניה
8600 ,רמת גן ,3123 ,נבטים רמת אפעל
8600 ,רמת גן ,1404 ,נדוני קהיר
8600 ,רמת גן ,1671 ,נהרדעא
8600 ,רמת גן ,6541 ,נוה יהושע
8600 ,רמת גן ,1538 ,נוה יעקב
8600 ,רמת גן ,6630 ,נווה אפעל
8600 ,רמת גן ,610 ,נורדאו
8600 ,רמת גן ,130 ,נורית
8600 ,רמת גן ,3131 ,נורית רמת אפעל
8600 ,רמת גן ,1823 ,נחום
8600 ,רמת גן ,1841 ,נחלה
8600 ,רמת גן ,136 ,נחליאלי
8600 ,רמת גן ,722 ,נחלת יוסף
8600 ,רמת גן ,1413 ,נחשון
8600 ,רמת גן ,1661 ,נטר
8600 ,רמת גן ,144 ,נץ
8600 ,רמת גן ,122 ,נצנים
8600 ,רמת גן ,1547 ,נרדימון
8600 ,רמת גן ,1845 ,נשיאי ישראל
8600 ,רמת גן ,803 ,נתן
8600 ,רמת גן ,1118 ,סאלק
8600 ,רמת גן ,207 ,סביון
8600 ,רמת גן ,201 ,סוקולוב
8600 ,רמת גן ,1669 ,סורא
8600 ,רמת גן ,1673 ,סטופ
8600 ,רמת גן ,214 ,סטרומה
8600 ,רמת גן ,1766 ,סיגלית
8600 ,רמת גן ,1632 ,סיירת דוכיפת
8600 ,רמת גן ,1646 ,סמ אלברט
8600 ,רמת גן ,317 ,סמ בדש ציון
8600 ,רמת גן ,3152 ,סמ ברקת ר"א
8600 ,רמת גן ,615 ,סמ הבאר
8600 ,רמת גן ,1641 ,סמ היסמין
8600 ,רמת גן ,606 ,סמ המעלות
8600 ,רמת גן ,1215 ,סמ השלוח
8600 ,רמת גן ,907 ,סמ התלמיד
8600 ,רמת גן ,1000 ,סמ חיים אינטרטור
8600 ,רמת גן ,1416 ,סמ יפתח
8600 ,רמת גן ,1544 ,סמ לבני
8600 ,רמת גן ,3156 ,סמ ספיר רמת אפעל
8600 ,רמת גן ,1749 ,סמ עמק האלה
8600 ,רמת גן ,820 ,סמ קרן
8600 ,רמת גן ,1418 ,סמ שער הגיא
8600 ,רמת גן ,124 ,סמדר
8600 ,רמת גן ,1414 ,סמטת אירנה סנדלר
8600 ,רמת גן ,1521 ,סמטת מנשה
8600 ,רמת גן ,1677 ,סמטת ק"ם
8600 ,רמת גן ,1805 ,סן מרטין
8600 ,רמת גן ,1103 ,סנהדרין
8600 ,רמת גן ,1124 ,סנש חנה
8600 ,רמת גן ,1733 ,ספיר יוסף
8600 ,רמת גן ,3154 ,ספיר רמת אפעל
8600 ,רמת גן ,1814 ,עובדיה
8600 ,רמת גן ,1803 ,עודד
8600 ,רמת גן ,1401 ,עוזיאל
8600 ,רמת גן ,1615 ,עולי הגרדום
8600 ,רמת גן ,1709 ,עזריאל
8600 ,רמת גן ,1302 ,עטרות
8600 ,רמת גן ,1822 ,עידו
8600 ,רמת גן ,1674 ,עין יהב
8600 ,רמת גן ,505 ,עיר גנים
8600 ,רמת גן ,1504 ,עלומים
8600 ,רמת גן ,1624 ,עליה ראשונה
8600 ,רמת גן ,1623 ,עליה שלישית
8600 ,רמת גן ,1625 ,עליה שניה
8600 ,רמת גן ,1637 ,עלית הנוער
8600 ,רמת גן ,1827 ,עמוס
8600 ,רמת גן ,1524 ,עמינדב
8600 ,רמת גן ,1209 ,עמיקם
8600 ,רמת גן ,1739 ,עמק דותן
8600 ,רמת גן ,1738 ,עמק האלה
8600 ,רמת גן ,1740 ,עמק הארזים
8600 ,רמת גן ,1633 ,עמק החולה
8600 ,רמת גן ,1212 ,עציון
8600 ,רמת גן ,1407 ,פבריגט
8600 ,רמת גן ,1670 ,פומבדיתא
8600 ,רמת גן ,3311 ,פלי"ם כפר אז"ר
8600 ,רמת גן ,1502 ,פנחס
8600 ,רמת גן ,308 ,פני הגבעה
8600 ,רמת גן ,1602 ,פנקס
8600 ,רמת גן ,604 ,פרוג שמעון
8600 ,רמת גן ,1548 ,פרופס
8600 ,רמת גן ,1540 ,פתייה
8600 ,רמת גן ,121 ,צאלים
8600 ,רמת גן ,3114 ,צאלים רמת אפעל
8600 ,רמת גן ,402 ,צבי
8600 ,רמת גן ,3305 ,צה"ל כפר אז"ר
8600 ,רמת גן ,716 ,צומת לבבי
8600 ,רמת גן ,3127 ,צופר רמת אפעל
8600 ,רמת גן ,3153 ,צור רמת אפעל
8600 ,רמת גן ,312 ,צוריאל
8600 ,רמת גן ,1207 ,צל הגבעה
8600 ,רמת גן ,1818 ,צפניה
8600 ,רמת גן ,1650 ,קארו יוסף
8600 ,רמת גן ,1119 ,קדמה
8600 ,רמת גן ,1620 ,קדרון
8600 ,רמת גן ,1222 ,קוממיות
8600 ,רמת גן ,1419 ,קיש
8600 ,רמת גן ,714 ,קלאוזנר
8600 ,רמת גן ,1213 ,קליר אברהם ונעימה
8600 ,רמת גן ,148 ,קניון אילון
8600 ,רמת גן ,1412 ,קפלן
8600 ,רמת גן ,3133 ,קציעה רמת אפעל
8600 ,רמת גן ,1731 ,קציר
8600 ,רמת גן ,1835 ,קרייתי
8600 ,רמת גן ,719 ,קריניצי
8600 ,רמת גן ,6557 ,קרית גן
8600 ,רמת גן ,6631 ,קרית סגל נווה אפעל
8600 ,רמת גן ,6571 ,קרית קריניצי
8600 ,רמת גן ,1220 ,קרסקי
8600 ,רמת גן ,1830 ,רא"ל משה לוי
8600 ,רמת גן ,1649 ,ראש פנה
8600 ,רמת גן ,1636 ,רבדים
8600 ,רמת גן ,1022 ,רבי
8600 ,רמת גן ,1017 ,רד"ק
8600 ,רמת גן ,1660 ,רודין
8600 ,רמת גן ,1832 ,רוזן
8600 ,רמת גן ,1315 ,רוחמה
8600 ,רמת גן ,1751 ,רועי קליין
8600 ,רמת גן ,307 ,רוקח
8600 ,רמת גן ,107 ,רות
8600 ,רמת גן ,1761 ,רותם
8600 ,רמת גן ,1806 ,רזיאל
8600 ,רמת גן ,105 ,רחובות הנהר
8600 ,רמת גן ,142 ,רייק חביבה
8600 ,רמת גן ,3144 ,רימון רמת אפעל
8600 ,רמת גן ,1121 ,רימלט אלימלך
8600 ,רמת גן ,134 ,רמבה
8600 ,רמת גן ,1411 ,רמז
8600 ,רמת גן ,6610 ,רמת אפעל
8600 ,רמת גן ,9000 ,רמת גן
8600 ,רמת גן ,6572 ,רמת חן
8600 ,רמת גן ,6547 ,רמת עמידר
8600 ,רמת גן ,6502 ,רמת שיקמה
8600 ,רמת גן ,128 ,רננים
8600 ,רמת גן ,3315 ,רפאל איתן כפר אז"ר
8600 ,רמת גן ,131 ,רקפת
8600 ,רמת גן ,208 ,רש"י
8600 ,רמת גן ,6574 ,ש אחיות
8600 ,רמת גן ,6575 ,ש אקדמאים
8600 ,רמת גן ,6566 ,ש גרין
8600 ,רמת גן ,6543 ,ש הבולגרים
8600 ,רמת גן ,6558 ,ש הפומז
8600 ,רמת גן ,6559 ,ש חרות
8600 ,רמת גן ,6546 ,ש מפדה אזרחי
8600 ,רמת גן ,6560 ,ש משכנות
8600 ,רמת גן ,6576 ,ש סלמה
8600 ,רמת גן ,6561 ,ש סלמה מזריץ
8600 ,רמת גן ,6578 ,ש סלע
8600 ,רמת גן ,6562 ,ש עירוני
8600 ,רמת גן ,6579 ,ש עממי רסקו
8600 ,רמת גן ,6580 ,ש עמק דותן
8600 ,רמת גן ,6548 ,ש פרדס אברלה
8600 ,רמת גן ,6563 ,ש פרוגרסיבים
8600 ,רמת גן ,6581 ,ש צבא קבע
8600 ,רמת גן ,6582 ,ש צנחנים
8600 ,רמת גן ,6556 ,ש רמת יצחק
8600 ,רמת גן ,6583 ,ש רסקו
8600 ,רמת גן ,1743 ,ש"י עגנון
8600 ,רמת גן ,140 ,שאול סוירי
8600 ,רמת גן ,713 ,שאנן
8600 ,רמת גן ,1672 ,שאר ישוב
8600 ,רמת גן ,807 ,שד אברהם
8600 ,רמת גן ,1701 ,שד בן צבי
8600 ,רמת גן ,3109 ,שד האורנים רמת אפעל
8600 ,רמת גן ,3112 ,שד הארזים רמת אפעל
8600 ,רמת גן ,3108 ,שד הברושים רמת אפעל
8600 ,רמת גן ,814 ,שד הגבורים
8600 ,רמת גן ,721 ,שד הטייס
8600 ,רמת גן ,706 ,שד הילד
8600 ,רמת גן ,129 ,שד הכלניות
8600 ,רמת גן ,118 ,שד הנרקיסים
8600 ,רמת גן ,112 ,שד העם הצרפתי
8600 ,רמת גן ,1519 ,שד הצנחנים
8600 ,רמת גן ,1508 ,שד הקונגרס
8600 ,רמת גן ,1408 ,שד השחרור
8600 ,רמת גן ,901 ,שד התמרים
8600 ,רמת גן ,113 ,שד טובים
8600 ,רמת גן ,1718 ,שד יהדות פילדלפיה
8600 ,רמת גן ,1202 ,שד ירושלים
8600 ,רמת גן ,712 ,שד למדן
8600 ,רמת גן ,613 ,שד נחמה
8600 ,רמת גן ,1675 ,שד ק"ם
8600 ,רמת גן ,1703 ,שדרות הצבי
8600 ,רמת גן ,1690 ,שדרות ויצ"ו
8600 ,רמת גן ,133 ,שדרת גידי
8600 ,רמת גן ,417 ,שוהם
8600 ,רמת גן ,1206 ,שועלי שמשון
8600 ,רמת גן ,117 ,שושנים
8600 ,רמת גן ,1846 ,שז"ר
8600 ,רמת גן ,3157 ,שחם רמת אפעל
8600 ,רמת גן ,821 ,שיאון
8600 ,רמת גן ,6621 ,שיכון בנים כפר אז"ר
8600 ,רמת גן ,6506 ,שיכון ותיקים
8600 ,רמת גן ,6512 ,שיכון עלית
8600 ,רמת גן ,715 ,שילה
8600 ,רמת גן ,6530 ,שכ בר אילן
8600 ,רמת גן ,6584 ,שכ גב גאולה
8600 ,רמת גן ,6507 ,שכ גפן
8600 ,רמת גן ,6503 ,שכ הלל
8600 ,רמת גן ,6587 ,שכ חרוזים
8600 ,רמת גן ,6504 ,שכ מרום נווה
8600 ,רמת גן ,6545 ,שכ נווה רם
8600 ,רמת גן ,6501 ,שכ נחלת גנים
8600 ,רמת גן ,6564 ,שכ ק בורוכוב
8600 ,רמת גן ,6588 ,שכ ראשונים
8600 ,רמת גן ,6516 ,שכ שפירא
8600 ,רמת גן ,6565 ,שכ תל גנים
8600 ,רמת גן ,6508 ,שכ תל יהודה
8600 ,רמת גן ,3135 ,שכטרמן רמת אפעל
8600 ,רמת גן ,1722 ,שלום עליכם
8600 ,רמת גן ,135 ,שלום שבזי
8600 ,רמת גן ,1527 ,שלומציון
8600 ,רמת גן ,1714 ,שלם
8600 ,רמת גן ,120 ,שמואלי
8600 ,רמת גן ,507 ,שמחה
8600 ,רמת גן ,1710 ,שמעוני
8600 ,רמת גן ,413 ,שמרלינג
8600 ,רמת גן ,1652 ,שניאור זלמן
8600 ,רמת גן ,506 ,שפירא
8600 ,רמת גן ,306 ,שקדיה
8600 ,רמת גן ,1662 ,שרירא גאון
8600 ,רמת גן ,806 ,שרת משה
8600 ,רמת גן ,711 ,שתיל
8600 ,רמת גן ,1843 ,תדהר
8600 ,רמת גן ,3122 ,תדהר רמת אפעל
8600 ,רמת גן ,404 ,תובל
8600 ,רמת גן ,6505 ,תל בנימין
8600 ,רמת גן ,6585 ,תל השומר
8600 ,רמת גן ,1512 ,תל חי
8600 ,רמת גן ,311 ,תלפיות
8600 ,רמת גן ,1104 ,תמרי נחמיה
8600 ,רמת גן ,1410 ,תנועת המרי
8600 ,רמת גן ,522 ,תע"ש
8600 ,רמת גן ,1606 ,תפארת ישראל
8600 ,רמת גן ,6601 ,תפארת צבי רמת אפעל
8600 ,רמת גן ,1645 ,תרס"ח
8600 ,רמת גן ,502 ,תרע"ד
8600 ,רמת גן ,521 ,תרפ"ב
8600 ,רמת גן ,1109 ,תרצה
8600 ,רמת גן ,501 ,תש"י
135 ,רמת דוד ,7001 ,מחנה יהודית
135 ,רמת דוד ,9000 ,רמת דוד
184 ,רמת הכובש ,9000 ,רמת הכובש
335 ,רמת השופט ,101 ,בית ראשון במולדת
335 ,רמת השופט ,9000 ,רמת השופט
2650 ,רמת השרון ,465 ,אבו חצירה
2650 ,רמת השרון ,189 ,אגוז
2650 ,רמת השרון ,107 ,אגרת תימן
2650 ,רמת השרון ,371 ,אהוד
2650 ,רמת השרון ,221 ,אוסישקין
2650 ,רמת השרון ,376 ,אופיר
2650 ,רמת השרון ,236 ,אז"ר
2650 ,רמת השרון ,6540 ,אזור בתכנון
2650 ,רמת השרון ,6504 ,אזור תעשייה
2650 ,רמת השרון ,200 ,אחד העם
2650 ,רמת השרון ,395 ,איה
2650 ,רמת השרון ,454 ,אילון
2650 ,רמת השרון ,230 ,אילנות
2650 ,רמת השרון ,116 ,אילת
2650 ,רמת השרון ,350 ,איריס
2650 ,רמת השרון ,217 ,אלה
2650 ,רמת השרון ,195 ,אלון יגאל
2650 ,רמת השרון ,343 ,אלי כהן
2650 ,רמת השרון ,263 ,אני מאמין
2650 ,רמת השרון ,436 ,אנפה
2650 ,רמת השרון ,318 ,אנקור
2650 ,רמת השרון ,471 ,ארבל
2650 ,רמת השרון ,440 ,ארז
2650 ,רמת השרון ,280 ,ארינה
2650 ,רמת השרון ,130 ,ארלוזורוב
2650 ,רמת השרון ,244 ,ארנון
2650 ,רמת השרון ,268 ,אשל אברהם
2650 ,רמת השרון ,196 ,בגין מנחם
2650 ,רמת השרון ,214 ,בוסתנאי
2650 ,רמת השרון ,390 ,בועז
2650 ,רמת השרון ,148 ,בוקק
2650 ,רמת השרון ,112 ,בית גוברין
2650 ,רמת השרון ,120 ,בית הלל
2650 ,רמת השרון ,137 ,בית השואבה
2650 ,רמת השרון ,118 ,בית שמאי
2650 ,רמת השרון ,146 ,בלפור
2650 ,רמת השרון ,427 ,בן גוריון
2650 ,רמת השרון ,126 ,בן זכאי
2650 ,רמת השרון ,367 ,בן חיים
2650 ,רמת השרון ,388 ,בן נון יהושע
2650 ,רמת השרון ,289 ,בן שלום
2650 ,רמת השרון ,356 ,בנימיני צבי
2650 ,רמת השרון ,127 ,בר יוחאי
2650 ,רמת השרון ,400 ,בר כוכבא
2650 ,רמת השרון ,372 ,בראשית
2650 ,רמת השרון ,259 ,ברנשטיין כהן
2650 ,רמת השרון ,368 ,ברק
2650 ,רמת השרון ,431 ,בשן
2650 ,רמת השרון ,241 ,גאולים
2650 ,רמת השרון ,472 ,גבעון
2650 ,רמת השרון ,235 ,גד
2650 ,רמת השרון ,444 ,גדעון
2650 ,רמת השרון ,287 ,גולומב
2650 ,רמת השרון ,430 ,גולן
2650 ,רמת השרון ,399 ,גונדר אברהם
2650 ,רמת השרון ,164 ,גורדון
2650 ,רמת השרון ,473 ,גוש חלב
2650 ,רמת השרון ,453 ,גיחון
2650 ,רמת השרון ,479 ,גלילי ישראל
2650 ,רמת השרון ,301 ,גלעד
2650 ,רמת השרון ,429 ,גמלא
2650 ,רמת השרון ,264 ,גמליאל
2650 ,רמת השרון ,391 ,גן הבנים
2650 ,רמת השרון ,227 ,דבורה הנביאה
2650 ,רמת השרון ,401 ,דגן
2650 ,רמת השרון ,551 ,דוכיפת
2650 ,רמת השרון ,461 ,דולב
2650 ,רמת השרון ,201 ,דותן דודו
2650 ,רמת השרון ,392 ,דן
2650 ,רמת השרון ,190 ,דקל
2650 ,רמת השרון ,182 ,דרך הטניס
2650 ,רמת השרון ,197 ,דרך הצעירים
2650 ,רמת השרון ,163 ,דרך ראשונים
2650 ,רמת השרון ,175 ,האומנויות
2650 ,רמת השרון ,387 ,האורן
2650 ,רמת השרון ,257 ,האחיות
2650 ,רמת השרון ,105 ,האלון
2650 ,רמת השרון ,507 ,האלוף יצחק חופי
2650 ,רמת השרון ,402 ,האצ"ל
2650 ,רמת השרון ,333 ,האר"י
2650 ,רמת השרון ,176 ,האתרוג
2650 ,רמת השרון ,419 ,הבאר
2650 ,רמת השרון ,212 ,הבנים
2650 ,רמת השרון ,172 ,הבעש"ט
2650 ,רמת השרון ,245 ,הברושים
2650 ,רמת השרון ,373 ,הברכה
2650 ,רמת השרון ,397 ,הבשם
2650 ,רמת השרון ,348 ,הגבעה
2650 ,רמת השרון ,147 ,הגדודים
2650 ,רמת השרון ,455 ,הגיא
2650 ,רמת השרון ,393 ,הגינה
2650 ,רמת השרון ,486 ,הגליל
2650 ,רמת השרון ,423 ,הגנים
2650 ,רמת השרון ,154 ,הגפן
2650 ,רמת השרון ,305 ,הדגנית
2650 ,רמת השרון ,346 ,הדיה
2650 ,רמת השרון ,149 ,הדסים
2650 ,רמת השרון ,161 ,הדר
2650 ,רמת השרון ,243 ,הדרור
2650 ,רמת השרון ,237 ,ההגנה
2650 ,רמת השרון ,131 ,הוז דב
2650 ,רמת השרון ,177 ,הושע
2650 ,רמת השרון ,203 ,הזוהר
2650 ,רמת השרון ,242 ,הזורע
2650 ,רמת השרון ,153 ,הזית
2650 ,רמת השרון ,306 ,הזמיר
2650 ,רמת השרון ,317 ,החוגה
2650 ,רמת השרון ,322 ,החוחית
2650 ,רמת השרון ,435 ,החי"ל
2650 ,רמת השרון ,101 ,החלוץ
2650 ,רמת השרון ,162 ,החרוב
2650 ,רמת השרון ,166 ,החרש
2650 ,רמת השרון ,456 ,הטבק
2650 ,רמת השרון ,394 ,היוגב
2650 ,רמת השרון ,345 ,היונה
2650 ,רמת השרון ,307 ,היסעור
2650 ,רמת השרון ,215 ,הכרמל
2650 ,רמת השרון ,434 ,הלפיד
2650 ,רמת השרון ,327 ,המאבק
2650 ,רמת השרון ,375 ,המור
2650 ,רמת השרון ,246 ,המחתרת
2650 ,רמת השרון ,325 ,המייסדים
2650 ,רמת השרון ,362 ,המכבים
2650 ,רמת השרון ,450 ,המלאכה
2650 ,רמת השרון ,136 ,המלכים
2650 ,רמת השרון ,167 ,המסגר
2650 ,רמת השרון ,457 ,המעין
2650 ,רמת השרון ,256 ,המעפילים
2650 ,רמת השרון ,403 ,המצור
2650 ,רמת השרון ,480 ,המרד
2650 ,רמת השרון ,260 ,המרי
2650 ,רמת השרון ,240 ,המתנחלים
2650 ,רמת השרון ,269 ,הנ"ץ
2650 ,רמת השרון ,111 ,הנביאים
2650 ,רמת השרון ,254 ,הנוטע
2650 ,רמת השרון ,223 ,הנוטר
2650 ,רמת השרון ,320 ,הנוער
2650 ,רמת השרון ,232 ,הנצח
2650 ,רמת השרון ,309 ,הנשר
2650 ,רמת השרון ,187 ,הס משה
2650 ,רמת השרון ,351 ,הסיפן
2650 ,רמת השרון ,386 ,העבודה
2650 ,רמת השרון ,443 ,העומר
2650 ,רמת השרון ,451 ,העמל
2650 ,רמת השרון ,458 ,העמק
2650 ,רמת השרון ,310 ,העפרוני
2650 ,רמת השרון ,404 ,הפורצים
2650 ,רמת השרון ,248 ,הפלמ"ח
2650 ,רמת השרון ,359 ,הפרדס
2650 ,רמת השרון ,418 ,הפרחים
2650 ,רמת השרון ,501 ,הצופים
2650 ,רמת השרון ,296 ,הצפורן
2650 ,רמת השרון ,439 ,הקבוץ
2650 ,רמת השרון ,253 ,הקוצר
2650 ,רמת השרון ,228 ,הקישון
2650 ,רמת השרון ,210 ,הר נבו
2650 ,רמת השרון ,224 ,הרב אורבוך
2650 ,רמת השרון ,144 ,הרב הרצוג
2650 ,רמת השרון ,202 ,הרב יעקב אדלשטיין
2650 ,רמת השרון ,134 ,הרב קוק
2650 ,רמת השרון ,135 ,הרב ריינס
2650 ,רמת השרון ,424 ,הרדוף
2650 ,רמת השרון ,150 ,הרמון
2650 ,רמת השרון ,485 ,השדות
2650 ,רמת השרון ,452 ,השדמה
2650 ,רמת השרון ,377 ,השומר
2650 ,רמת השרון ,463 ,השומרון
2650 ,רמת השרון ,378 ,השופטים
2650 ,רמת השרון ,315 ,השחף
2650 ,רמת השרון ,321 ,השיטה
2650 ,רמת השרון ,462 ,השילוח
2650 ,רמת השרון ,382 ,השלום
2650 ,רמת השרון ,420 ,השמורה
2650 ,רמת השרון ,459 ,השפלה
2650 ,רמת השרון ,323 ,השקד
2650 ,רמת השרון ,270 ,השקמה
2650 ,רמת השרון ,385 ,השרון
2650 ,רמת השרון ,152 ,התאנה
2650 ,רמת השרון ,151 ,התומר
2650 ,רמת השרון ,304 ,התלמים
2650 ,רמת השרון ,265 ,התקוה
2650 ,רמת השרון ,414 ,התרבות
2650 ,רמת השרון ,165 ,ויתקין
2650 ,רמת השרון ,267 ,ז' בחשון
2650 ,רמת השרון ,347 ,ז'בוטינסקי
2650 ,רמת השרון ,298 ,זלמן שניאור
2650 ,רמת השרון ,211 ,זרובבל
2650 ,רמת השרון ,381 ,זרזיר
2650 ,רמת השרון ,311 ,חבצלת
2650 ,רמת השרון ,171 ,חטיבת אלכסנדרוני
2650 ,רמת השרון ,303 ,חטיבת הנגב
2650 ,רמת השרון ,312 ,חטיבת הראל
2650 ,רמת השרון ,481 ,חיש
2650 ,רמת השרון ,349 ,חרושת
2650 ,רמת השרון ,415 ,חרמון
2650 ,רמת השרון ,128 ,חשמונאים
2650 ,רמת השרון ,159 ,טהון
2650 ,רמת השרון ,553 ,טווס
2650 ,רמת השרון ,155 ,טרומפלדור
2650 ,רמת השרון ,416 ,יאיר
2650 ,רמת השרון ,247 ,יבוק
2650 ,רמת השרון ,239 ,יבנה
2650 ,רמת השרון ,168 ,יגיע כפים
2650 ,רמת השרון ,249 ,יהודה
2650 ,רמת השרון ,119 ,יהודה הלוי
2650 ,רמת השרון ,121 ,יהודה הנשיא
2650 ,רמת השרון ,421 ,יוני נתניהו
2650 ,רמת השרון ,188 ,יותם
2650 ,רמת השרון ,432 ,יחיעם
2650 ,רמת השרון ,369 ,יפתח
2650 ,רמת השרון ,417 ,יצהר
2650 ,רמת השרון ,272 ,יצחק אלחנן
2650 ,רמת השרון ,143 ,יצחק שדה
2650 ,רמת השרון ,364 ,ירדן
2650 ,רמת השרון ,250 ,ירמוך
2650 ,רמת השרון ,178 ,ירמיהו
2650 ,רמת השרון ,425 ,כ"ט בנובמבר
2650 ,רמת השרון ,299 ,כהן יעקב
2650 ,רמת השרון ,6001 ,ככר ההסתדרות
2650 ,רמת השרון ,6002 ,ככר העצמאות
2650 ,רמת השרון ,331 ,כלנית
2650 ,רמת השרון ,361 ,כפר אוריה
2650 ,רמת השרון ,255 ,כצנלסון
2650 ,רמת השרון ,370 ,כרכום
2650 ,רמת השרון ,422 ,לבונה
2650 ,רמת השרון ,262 ,לוחמי הגטאות
2650 ,רמת השרון ,405 ,לח"י
2650 ,רמת השרון ,406 ,לילך
2650 ,רמת השרון ,115 ,לכיש
2650 ,רמת השרון ,213 ,למרחב
2650 ,רמת השרון ,273 ,מבצע קדש
2650 ,רמת השרון ,174 ,מגידו
2650 ,רמת השרון ,113 ,מודיעין
2650 ,רמת השרון ,145 ,מוהליבר
2650 ,רמת השרון ,208 ,מוריה
2650 ,רמת השרון ,374 ,מורן
2650 ,רמת השרון ,6501 ,מורשה שלב ג
2650 ,רמת השרון ,142 ,מסדה
2650 ,רמת השרון ,6502 ,מע א ר השרון
2650 ,רמת השרון ,181 ,מעון אקדמאים
2650 ,רמת השרון ,6500 ,מעונות מורשה
2650 ,רמת השרון ,449 ,מעין חרוד
2650 ,רמת השרון ,428 ,מצדה
2650 ,רמת השרון ,124 ,מרגנית
2650 ,רמת השרון ,231 ,מרדכי
2650 ,רמת השרון ,6541 ,מרכז הטניס
2650 ,רמת השרון ,6543 ,מתחם גלילות
2650 ,רמת השרון ,6542 ,מתחם תע"ש
2650 ,רמת השרון ,106 ,נהרדעא
2650 ,רמת השרון ,156 ,נווה רעים
2650 ,רמת השרון ,160 ,נורדאו
2650 ,רמת השרון ,138 ,נורית
2650 ,רמת השרון ,229 ,נחל בשור
2650 ,רמת השרון ,475 ,נחל עמוד
2650 ,רמת השרון ,354 ,נחל קדרון
2650 ,רמת השרון ,448 ,נחל שורק
2650 ,רמת השרון ,433 ,נחשון
2650 ,רמת השרון ,407 ,נטף
2650 ,רמת השרון ,266 ,ניל"י
2650 ,רמת השרון ,389 ,נעמי
2650 ,רמת השרון ,117 ,נצנה
2650 ,רמת השרון ,103 ,נרקיסים
2650 ,רמת השרון ,222 ,סולד
2650 ,רמת השרון ,209 ,סוקולוב
2650 ,רמת השרון ,216 ,סיני
2650 ,רמת השרון ,502 ,סלע
2650 ,רמת השרון ,337 ,סמ אגדים
2650 ,רמת השרון ,123 ,סמ אלחריזי
2650 ,רמת השרון ,185 ,סמ אסף
2650 ,רמת השרון ,191 ,סמ בית שמאי
2650 ,רמת השרון ,157 ,סמ במעלה
2650 ,רמת השרון ,251 ,סמ געתון
2650 ,רמת השרון ,330 ,סמ דליה
2650 ,רמת השרון ,332 ,סמ היסמין
2650 ,רמת השרון ,308 ,סמ המעלות
2650 ,רמת השרון ,199 ,סמ המשי
2650 ,רמת השרון ,186 ,סמ הנרקיס
2650 ,רמת השרון ,490 ,סמ הקמה
2650 ,רמת השרון ,110 ,סמ הרב
2650 ,רמת השרון ,180 ,סמ ויצ"ו
2650 ,רמת השרון ,339 ,סמ ורד
2650 ,רמת השרון ,340 ,סמ חצב
2650 ,רמת השרון ,341 ,סמ חרצית
2650 ,רמת השרון ,520 ,סמ יואב
2650 ,רמת השרון ,198 ,סמ לוטם
2650 ,רמת השרון ,482 ,סמ למרחב
2650 ,רמת השרון ,491 ,סמ מגד
2650 ,רמת השרון ,470 ,סמ מעין חרוד
2650 ,רמת השרון ,355 ,סמ נחל קדרון
2650 ,רמת השרון ,335 ,סמ סביון
2650 ,רמת השרון ,141 ,סמ סיגלית
2650 ,רמת השרון ,252 ,סמ פיקר
2650 ,רמת השרון ,530 ,סמ צאלון
2650 ,רמת השרון ,338 ,סמ צבעוני
2650 ,רמת השרון ,336 ,סמ צלף
2650 ,רמת השרון ,129 ,סמ רקפת
2650 ,רמת השרון ,109 ,סמ שאר ישוב
2650 ,רמת השרון ,319 ,סמ שוהם
2650 ,רמת השרון ,334 ,סמדר
2650 ,רמת השרון ,344 ,סמטת אחיקר
2650 ,רמת השרון ,261 ,סמטת סטלה לוי
2650 ,רמת השרון ,238 ,סנה משה
2650 ,רמת השרון ,383 ,סנונית
2650 ,רמת השרון ,139 ,סנש חנה
2650 ,רמת השרון ,365 ,עבדת
2650 ,רמת השרון ,316 ,עגור
2650 ,רמת השרון ,192 ,עגנון
2650 ,רמת השרון ,193 ,עוזיה
2650 ,רמת השרון ,104 ,עזרא
2650 ,רמת השרון ,140 ,עין גדי
2650 ,רמת השרון ,329 ,עליה
2650 ,רמת השרון ,342 ,עצמון
2650 ,רמת השרון ,184 ,ערוגות
2650 ,רמת השרון ,468 ,פדויים
2650 ,רמת השרון ,503 ,פי גלילות
2650 ,רמת השרון ,158 ,פרץ
2650 ,רמת השרון ,552 ,פשוש
2650 ,רמת השרון ,438 ,צבר
2650 ,רמת השרון ,474 ,ציפורי
2650 ,רמת השרון ,258 ,צירלסון
2650 ,רמת השרון ,464 ,קבוץ גלויות
2650 ,רמת השרון ,302 ,קדושי סטרומה
2650 ,רמת השרון ,173 ,קדש
2650 ,רמת השרון ,426 ,קהילת בבל
2650 ,רמת השרון ,379 ,קהילת וילנה
2650 ,רמת השרון ,226 ,קהילת לודג'
2650 ,רמת השרון ,328 ,קוממיות
2650 ,רמת השרון ,6535 ,קרית יערים
2650 ,רמת השרון ,352 ,קרן היסוד
2650 ,רמת השרון ,504 ,רב מכר
2650 ,רמת השרון ,358 ,רבנו תם
2650 ,רמת השרון ,492 ,רגבים
2650 ,רמת השרון ,233 ,רד"ק
2650 ,רמת השרון ,360 ,רות
2650 ,רמת השרון ,408 ,רותם
2650 ,רמת השרון ,409 ,רזיאל
2650 ,רמת השרון ,219 ,רמב"ם
2650 ,רמת השרון ,169 ,רמז
2650 ,רמת השרון ,9000 ,רמת השרון
2650 ,רמת השרון ,6544 ,רצועת הנופש
2650 ,רמת השרון ,6545 ,רצועת ספורט ונופש
2650 ,רמת השרון ,220 ,רש"י
2650 ,רמת השרון ,234 ,רשב"ם
2650 ,רמת השרון ,6505 ,ש גבעת הלבונה
2650 ,רמת השרון ,6539 ,ש גולן
2650 ,רמת השרון ,6507 ,ש המועצה
2650 ,רמת השרון ,6508 ,ש הפועלים
2650 ,רמת השרון ,6509 ,ש חסכון
2650 ,רמת השרון ,6510 ,ש יוספטל
2650 ,רמת השרון ,6511 ,ש ליטא
2650 ,רמת השרון ,6512 ,ש מומחים
2650 ,רמת השרון ,6513 ,ש מורשה חדש
2650 ,רמת השרון ,6514 ,ש מזרחי
2650 ,רמת השרון ,6515 ,ש משכנות
2650 ,רמת השרון ,6516 ,ש נוה ארנון
2650 ,רמת השרון ,6519 ,ש נוה רום
2650 ,רמת השרון ,6520 ,ש נוה רסקו
2650 ,רמת השרון ,6538 ,ש נווה מגן
2650 ,רמת השרון ,6521 ,ש עובדים
2650 ,רמת השרון ,6522 ,ש עולים
2650 ,רמת השרון ,6523 ,ש עממי
2650 ,רמת השרון ,6524 ,ש עממי חדש
2650 ,רמת השרון ,6525 ,ש פקר
2650 ,רמת השרון ,6526 ,ש פרוגרסיבים
2650 ,רמת השרון ,6527 ,ש צבא קבע
2650 ,רמת השרון ,6528 ,ש קדמה
2650 ,רמת השרון ,6529 ,ש קוטגים
2650 ,רמת השרון ,6531 ,ש קליפורניה
2650 ,רמת השרון ,6532 ,ש רסקו מערב
2650 ,רמת השרון ,357 ,שארית ישראל
2650 ,רמת השרון ,114 ,שבזי
2650 ,רמת השרון ,132 ,שבטי ישראל
2650 ,רמת השרון ,102 ,שבי ציון
2650 ,רמת השרון ,225 ,שד ביאליק
2650 ,רמת השרון ,218 ,שד האלוף מאיר עמית
2650 ,רמת השרון ,271 ,שד הילד
2650 ,רמת השרון ,206 ,שד ויצמן
2650 ,רמת השרון ,505 ,שד יריב אהרון
2650 ,רמת השרון ,466 ,שדרות יצחק רבין
2650 ,רמת השרון ,410 ,שושן
2650 ,רמת השרון ,133 ,שח"ל
2650 ,רמת השרון ,324 ,שיבולת
2650 ,רמת השרון ,363 ,שינקין
2650 ,רמת השרון ,460 ,שיפון
2650 ,רמת השרון ,6536 ,שכ אלון
2650 ,רמת השרון ,6537 ,שכ הדר
2650 ,רמת השרון ,6533 ,שכ הכפר הירוק
2650 ,רמת השרון ,6517 ,שכ נווה גן
2650 ,רמת השרון ,6503 ,שכ עמידר
2650 ,רמת השרון ,6534 ,שכ ק שאול
2650 ,רמת השרון ,550 ,שלדג
2650 ,רמת השרון ,170 ,שלום עליכם
2650 ,רמת השרון ,445 ,שמגר
2650 ,רמת השרון ,122 ,שמואל הנגיד
2650 ,רמת השרון ,506 ,שמחה הולצברג
2650 ,רמת השרון ,446 ,שמשון
2650 ,רמת השרון ,411 ,שניר
2650 ,רמת השרון ,179 ,שץ צבי
2650 ,רמת השרון ,437 ,שרף
2650 ,רמת השרון ,353 ,ששת הימים
2650 ,רמת השרון ,207 ,תבור
2650 ,רמת השרון ,125 ,תחכמוני
2650 ,רמת השרון ,412 ,תירוש
2650 ,רמת השרון ,194 ,תמנע
2650 ,רמת השרון ,183 ,תע"ש
2650 ,רמת השרון ,447 ,תענך
2650 ,רמת השרון ,441 ,תרזה
2650 ,רמת השרון ,326 ,תרפ"ד
2650 ,רמת השרון ,442 ,תרשיש
4029 ,רמת טראמפ ,9000 ,רמת טראמפ
178 ,רמת יוחנן ,101 ,אולפן
178 ,רמת יוחנן ,9000 ,רמת יוחנן
178 ,רמת יוחנן ,7001 ,שכ קרוונים
122 ,רמת ישי ,194 ,אגס
122 ,רמת ישי ,139 ,אדמונית החורש
122 ,רמת ישי ,179 ,אורנית
122 ,רמת ישי ,170 ,אזור תעשיה
122 ,רמת ישי ,180 ,אלמון
122 ,רמת ישי ,108 ,אמנון ותמר
122 ,רמת ישי ,200 ,אפרסמון
122 ,רמת ישי ,184 ,אפרסק
122 ,רמת ישי ,159 ,אקליפטוס
122 ,רמת ישי ,168 ,אשל
122 ,רמת ישי ,198 ,גומא
122 ,רמת ישי ,141 ,דבורנית
122 ,רמת ישי ,196 ,דובדבן
122 ,רמת ישי ,129 ,דפנה
122 ,רמת ישי ,138 ,האגוז
122 ,רמת ישי ,102 ,האורן
122 ,רמת ישי ,128 ,האיריסים
122 ,רמת ישי ,112 ,האלון
122 ,רמת ישי ,101 ,הארז
122 ,רמת ישי ,111 ,האתרוג
122 ,רמת ישי ,156 ,הברוש
122 ,רמת ישי ,120 ,הדגן
122 ,רמת ישי ,118 ,הדקל
122 ,רמת ישי ,113 ,ההדס
122 ,רמת ישי ,204 ,החורשה
122 ,רמת ישי ,148 ,החצבים
122 ,רמת ישי ,117 ,היסמין
122 ,רמת ישי ,147 ,היקינטון
122 ,רמת ישי ,121 ,הכלניות
122 ,רמת ישי ,146 ,המרגניות
122 ,רמת ישי ,122 ,הנרקיסים
122 ,רמת ישי ,119 ,הערבה
122 ,רמת ישי ,144 ,הפרגים
122 ,רמת ישי ,162 ,הפרדס
122 ,רמת ישי ,133 ,הצאלון
122 ,רמת ישי ,134 ,הצבעוני
122 ,רמת ישי ,158 ,הצפצפה
122 ,רמת ישי ,115 ,הרימון
122 ,רמת ישי ,116 ,הרקפות
122 ,רמת ישי ,114 ,השיזף
122 ,רמת ישי ,135 ,השקמים
122 ,רמת ישי ,105 ,התאנה
122 ,רמת ישי ,167 ,חבצלת
122 ,רמת ישי ,104 ,חוות נוה יער
122 ,רמת ישי ,160 ,חורש האלונים
122 ,רמת ישי ,145 ,חלמית
122 ,רמת ישי ,166 ,חרצית
122 ,רמת ישי ,173 ,יערה
122 ,רמת ישי ,151 ,כליל החורש
122 ,רמת ישי ,199 ,לימון
122 ,רמת ישי ,132 ,מורן
122 ,רמת ישי ,197 ,מטע
122 ,רמת ישי ,193 ,מנגו
122 ,רמת ישי ,172 ,מעלה דולב
122 ,רמת ישי ,123 ,מעלה הגפן
122 ,רמת ישי ,126 ,מעלה החורש
122 ,רמת ישי ,127 ,מעלה הרדוף
122 ,רמת ישי ,150 ,מעלה כרכום
122 ,רמת ישי ,106 ,מעלה לבונה
122 ,רמת ישי ,109 ,מעלה פרג
122 ,רמת ישי ,157 ,מעלה שיטים
122 ,רמת ישי ,143 ,מעלה שקמה
122 ,רמת ישי ,169 ,מרווה
122 ,רמת ישי ,191 ,נקטרינה
122 ,רמת ישי ,202 ,סחלב
122 ,רמת ישי ,182 ,סיגלון
122 ,רמת ישי ,171 ,סיגליות
122 ,רמת ישי ,176 ,סמ אשחר
122 ,רמת ישי ,154 ,סמ האלה
122 ,רמת ישי ,152 ,סמ ההדר
122 ,רמת ישי ,131 ,סמ הורד
122 ,רמת ישי ,124 ,סמ הזית
122 ,רמת ישי ,136 ,סמ החרוב
122 ,רמת ישי ,177 ,סמ הכרם
122 ,רמת ישי ,153 ,סמ הלוטם
122 ,רמת ישי ,149 ,סמ הסביון
122 ,רמת ישי ,183 ,סמ השיזף
122 ,רמת ישי ,175 ,סמ השסק
122 ,רמת ישי ,137 ,סמ השקד
122 ,רמת ישי ,125 ,סמ התמר
122 ,רמת ישי ,130 ,סמ לילך
122 ,רמת ישי ,187 ,סמ לימונית
122 ,רמת ישי ,155 ,סמ מור
122 ,רמת ישי ,161 ,סמ מרגנית
122 ,רמת ישי ,174 ,סמ נוריות
122 ,רמת ישי ,110 ,סמ ציפורן
122 ,רמת ישי ,186 ,סמ ריחן
122 ,רמת ישי ,178 ,ספיון
122 ,רמת ישי ,185 ,סתוונית
122 ,רמת ישי ,140 ,עירית
122 ,רמת ישי ,203 ,פיקוס
122 ,רמת ישי ,192 ,פקאן
122 ,רמת ישי ,181 ,צבר
122 ,רמת ישי ,190 ,קוקוס
122 ,רמת ישי ,164 ,קורנית
122 ,רמת ישי ,188 ,רוזמרין
122 ,רמת ישי ,9000 ,רמת ישי
122 ,רמת ישי ,6501 ,ש עובדים
122 ,רמת ישי ,6502 ,ש עמידר
122 ,רמת ישי ,6503 ,שכון ב
122 ,רמת ישי ,6504 ,שכון חילים
122 ,רמת ישי ,189 ,תות
122 ,רמת ישי ,142 ,תלתן
122 ,רמת ישי ,195 ,תפוח
4701 ,רמת מגשימים ,9000 ,רמת מגשימים
339 ,רמת צבי ,104 ,בית העם
339 ,רמת צבי ,107 ,דרך הפרדס
339 ,רמת צבי ,105 ,דרך השדה
339 ,רמת צבי ,106 ,דרך התבור
339 ,רמת צבי ,103 ,הגלבוע
339 ,רמת צבי ,101 ,הגליל
339 ,רמת צבי ,111 ,הזית
339 ,רמת צבי ,102 ,הראשונים
339 ,רמת צבי ,114 ,הרימון
339 ,רמת צבי ,110 ,השיזף
339 ,רמת צבי ,112 ,השקד
339 ,רמת צבי ,113 ,התאנה
339 ,רמת צבי ,109 ,סמטת המחלבה
339 ,רמת צבי ,115 ,סמטת השלושה
339 ,רמת צבי ,9000 ,רמת צבי
460 ,רמת רזיאל ,9000 ,רמת רזיאל
127 ,רמת רחל ,101 ,אכסנית נוער
127 ,רמת רחל ,102 ,גדוד העבודה
127 ,רמת רחל ,9000 ,רמת רחל
789 ,רנן ,101 ,הורד
789 ,רנן ,102 ,הנורית
789 ,רנן ,103 ,הסייפן
789 ,רנן ,9000 ,רנן
713 ,רעים ,9000 ,רעים
8700 ,רעננה ,165 ,אבן גבירול
8700 ,רעננה ,167 ,אבן עזרא
8700 ,רעננה ,243 ,אברבנאל
8700 ,רעננה ,338 ,אבשלום
8700 ,רעננה ,244 ,אהוד מנור
8700 ,רעננה ,507 ,אהרונסון שרה
8700 ,רעננה ,229 ,אוסטרובסקי
8700 ,רעננה ,231 ,אופסטרלנד
8700 ,רעננה ,166 ,אזולאי
8700 ,רעננה ,6507 ,אזור התעשיה
8700 ,רעננה ,271 ,אח"י דקר
8700 ,רעננה ,148 ,אחד העם
8700 ,רעננה ,103 ,אחוזה
8700 ,רעננה ,240 ,אינשטיין
8700 ,רעננה ,274 ,אלחריזי
8700 ,רעננה ,385 ,אלי כהן
8700 ,רעננה ,289 ,אלכסנדר פן
8700 ,רעננה ,154 ,אלעזר דוד
8700 ,רעננה ,163 ,אלפסי
8700 ,רעננה ,164 ,אלקלעי
8700 ,רעננה ,281 ,אלתרמן נתן
8700 ,רעננה ,136 ,אנדה עמיר
8700 ,רעננה ,505 ,אנה פרנק
8700 ,רעננה ,383 ,אנילביץ
8700 ,רעננה ,312 ,אסירי ציון
8700 ,רעננה ,162 ,אסתר המלכה
8700 ,רעננה ,251 ,אריה
8700 ,רעננה ,308 ,אריק לביא
8700 ,רעננה ,207 ,ארלוזורוב
8700 ,רעננה ,233 ,אשכול לוי
8700 ,רעננה ,303 ,אשר
8700 ,רעננה ,468 ,בגין מנחם
8700 ,רעננה ,249 ,בובר
8700 ,רעננה ,139 ,בורוכוב
8700 ,רעננה ,187 ,בורלא יהודה
8700 ,רעננה ,205 ,ביאליק
8700 ,רעננה ,250 ,ביל"ו
8700 ,רעננה ,469 ,בית אבות אחוזת בית
8700 ,רעננה ,460 ,בית אבות משען
8700 ,רעננה ,275 ,בית הלל
8700 ,רעננה ,221 ,בית השואבה
8700 ,רעננה ,279 ,בית שמאי
8700 ,רעננה ,252 ,בן גוריון
8700 ,רעננה ,125 ,בן זכאי
8700 ,רעננה ,266 ,בן יהודה
8700 ,רעננה ,280 ,בנטוב מרדכי
8700 ,רעננה ,339 ,בני אמדורסקי
8700 ,רעננה ,114 ,בר אילן
8700 ,רעננה ,119 ,בר יוחאי
8700 ,רעננה ,168 ,בר כוכבא
8700 ,רעננה ,206 ,בר-גיורא
8700 ,רעננה ,501 ,ברלב חיים
8700 ,רעננה ,214 ,ברנדיס
8700 ,רעננה ,209 ,ברנר
8700 ,רעננה ,340 ,ג'ו עמר
8700 ,רעננה ,301 ,גאולה
8700 ,רעננה ,466 ,גובר רבקה
8700 ,רעננה ,391 ,גוטמן נחום
8700 ,רעננה ,102 ,גולדברג לאה
8700 ,רעננה ,415 ,גולדה מאיר
8700 ,רעננה ,314 ,גולומב
8700 ,רעננה ,503 ,גונן שמואל
8700 ,רעננה ,232 ,גור מרדכי
8700 ,רעננה ,220 ,גורדון
8700 ,רעננה ,263 ,גרונר דב
8700 ,רעננה ,151 ,גרינברג אורי צבי
8700 ,רעננה ,272 ,גרץ
8700 ,רעננה ,135 ,דבורה עומר
8700 ,רעננה ,134 ,דגניה
8700 ,רעננה ,245 ,דובנוב
8700 ,רעננה ,456 ,דוכיפת
8700 ,רעננה ,358 ,דולב
8700 ,רעננה ,504 ,דורי יעקב
8700 ,רעננה ,425 ,דיין משה
8700 ,רעננה ,341 ,דליה רביקוביץ'
8700 ,רעננה ,359 ,דפנה
8700 ,רעננה ,484 ,דרוקר
8700 ,רעננה ,198 ,דרור
8700 ,רעננה ,398 ,דרך האוניברסיטה
8700 ,רעננה ,344 ,דרך החקלאים
8700 ,רעננה ,465 ,דרך הפארק
8700 ,רעננה ,345 ,דרך כפר נחמן
8700 ,רעננה ,517 ,האגם
8700 ,רעננה ,479 ,האופק
8700 ,רעננה ,189 ,האורן
8700 ,רעננה ,200 ,האחווה
8700 ,רעננה ,322 ,האיריס
8700 ,רעננה ,293 ,האלה
8700 ,רעננה ,307 ,האלון
8700 ,רעננה ,428 ,האצ"ל
8700 ,רעננה ,130 ,האר"י
8700 ,רעננה ,292 ,הארז
8700 ,רעננה ,516 ,האשד
8700 ,רעננה ,475 ,האשל
8700 ,רעננה ,552 ,הבדולח
8700 ,רעננה ,234 ,הבנים
8700 ,רעננה ,305 ,הברוש
8700 ,רעננה ,515 ,הבריכה
8700 ,רעננה ,382 ,הגדוד העברי
8700 ,רעננה ,270 ,הגדנ"ע
8700 ,רעננה ,402 ,הגולן
8700 ,רעננה ,472 ,הגלבוע
8700 ,רעננה ,143 ,הגליל
8700 ,רעננה ,477 ,הגלים
8700 ,רעננה ,476 ,הגפן
8700 ,רעננה ,327 ,הדליה
8700 ,רעננה ,378 ,הדקל
8700 ,רעננה ,120 ,ההגנה
8700 ,רעננה ,112 ,ההסתדרות
8700 ,רעננה ,123 ,הוז דב
8700 ,רעננה ,371 ,הולצברג שמחה
8700 ,רעננה ,192 ,הורד
8700 ,רעננה ,481 ,הזוהר
8700 ,רעננה ,188 ,הזז חיים
8700 ,רעננה ,295 ,הזית
8700 ,רעננה ,455 ,הזמיר
8700 ,רעננה ,451 ,החוחית
8700 ,רעננה ,290 ,החורש
8700 ,רעננה ,248 ,החורשה
8700 ,רעננה ,386 ,החי"ל
8700 ,רעננה ,318 ,החלוץ
8700 ,רעננה ,511 ,החליל
8700 ,רעננה ,323 ,החצב
8700 ,רעננה ,235 ,החרוב
8700 ,רעננה ,304 ,החרושת
8700 ,רעננה ,473 ,החרמון
8700 ,רעננה ,510 ,החרצית
8700 ,רעננה ,161 ,החשמונאים
8700 ,רעננה ,316 ,הטייסים
8700 ,רעננה ,478 ,הטללים
8700 ,רעננה ,551 ,היהלום
8700 ,רעננה ,110 ,היובל
8700 ,רעננה ,326 ,היסמין
8700 ,רעננה ,365 ,היצירה
8700 ,רעננה ,521 ,היקינתון
8700 ,רעננה ,354 ,הירדן
8700 ,רעננה ,355 ,הירמוך
8700 ,רעננה ,372 ,הכוכב
8700 ,רעננה ,298 ,הכלנית
8700 ,רעננה ,470 ,הכרמל
8700 ,רעננה ,239 ,הל"ה
8700 ,רעננה ,282 ,הלבונה
8700 ,רעננה ,520 ,הלוטוס
8700 ,רעננה ,450 ,הלימון
8700 ,רעננה ,238 ,המחתרות
8700 ,רעננה ,202 ,המיסדים
8700 ,רעננה ,513 ,המיתר
8700 ,רעננה ,360 ,המלאכה
8700 ,רעננה ,522 ,המלכים
8700 ,רעננה ,351 ,המסגר
8700 ,רעננה ,236 ,המעיין
8700 ,רעננה ,309 ,המעלות
8700 ,רעננה ,404 ,המעפילים
8700 ,רעננה ,321 ,המרגנית
8700 ,רעננה ,181 ,המרד
8700 ,רעננה ,519 ,הנבל
8700 ,רעננה ,320 ,הנגב
8700 ,רעננה ,403 ,הנוטרים
8700 ,רעננה ,368 ,הנופר
8700 ,רעננה ,325 ,הנורית
8700 ,רעננה ,237 ,הנחל
8700 ,רעננה ,196 ,הנרקיס
8700 ,רעננה ,375 ,הנשיאים
8700 ,רעננה ,457 ,הנשר
8700 ,רעננה ,175 ,הס משה
8700 ,רעננה ,380 ,הסביון
8700 ,רעננה ,363 ,הסדנא
8700 ,רעננה ,508 ,הסולן
8700 ,רעננה ,514 ,הסחלב
8700 ,רעננה ,328 ,הסיגלית
8700 ,רעננה ,459 ,הסייפן
8700 ,רעננה ,453 ,הסנונית
8700 ,רעננה ,132 ,העבודה
8700 ,רעננה ,461 ,העיט
8700 ,רעננה ,458 ,העפרוני
8700 ,רעננה ,137 ,העצמאות
8700 ,רעננה ,379 ,הערבה
8700 ,רעננה ,169 ,הפלמ"ח
8700 ,רעננה ,550 ,הפנינה
8700 ,רעננה ,518 ,הפעמונים
8700 ,רעננה ,506 ,הפרחים
8700 ,רעננה ,366 ,הצאלון
8700 ,רעננה ,324 ,הצבעוני
8700 ,רעננה ,356 ,הצבר
8700 ,רעננה ,512 ,הצליל
8700 ,רעננה ,158 ,הצנחנים
8700 ,רעננה ,111 ,הקדושים
8700 ,רעננה ,353 ,הקישון
8700 ,רעננה ,509 ,הקרן
8700 ,רעננה ,480 ,הקשת
8700 ,רעננה ,381 ,הר סיני
8700 ,רעננה ,268 ,הרב אפרים
8700 ,רעננה ,393 ,הרב בן אור
8700 ,רעננה ,285 ,הרב נסים יצחק
8700 ,רעננה ,126 ,הרב קוק
8700 ,רעננה ,296 ,הרדוף
8700 ,רעננה ,329 ,הרותם
8700 ,רעננה ,306 ,הרמון
8700 ,רעננה ,226 ,הרצוג
8700 ,רעננה ,215 ,הרצל
8700 ,רעננה ,153 ,הרקפת
8700 ,רעננה ,482 ,השביט
8700 ,רעננה ,138 ,השומר
8700 ,רעננה ,211 ,השוק
8700 ,רעננה ,454 ,השחפים
8700 ,רעננה ,178 ,השחר
8700 ,רעננה ,369 ,השיזף
8700 ,רעננה ,299 ,השיטה
8700 ,רעננה ,376 ,השלום
8700 ,רעננה ,553 ,השנהב
8700 ,רעננה ,294 ,השקד
8700 ,רעננה ,291 ,השקמה
8700 ,רעננה ,216 ,השרון
8700 ,רעננה ,159 ,השריון
8700 ,רעננה ,373 ,התאנה
8700 ,רעננה ,471 ,התבור
8700 ,רעננה ,367 ,התדהר
8700 ,רעננה ,452 ,התור
8700 ,רעננה ,191 ,התחיה
8700 ,רעננה ,374 ,התמר
8700 ,רעננה ,269 ,התנאים
8700 ,רעננה ,362 ,התעשיה
8700 ,רעננה ,377 ,התפוז
8700 ,רעננה ,190 ,התקוה
8700 ,רעננה ,199 ,התקומה
8700 ,רעננה ,387 ,וינגייט
8700 ,רעננה ,204 ,ויצמן
8700 ,רעננה ,286 ,ולנברג ראול
8700 ,רעננה ,227 ,ז'בוטינסקי
8700 ,רעננה ,364 ,זרחין אלכסנדר
8700 ,רעננה ,333 ,חזון איש
8700 ,רעננה ,116 ,חזן יעקב
8700 ,רעננה ,177 ,חטיבת אלכסנדרוני
8700 ,רעננה ,176 ,חטיבת גבעתי
8700 ,רעננה ,389 ,חטיבת גולני
8700 ,רעננה ,390 ,חטיבת הראל
8700 ,רעננה ,230 ,חיבת ציון
8700 ,רעננה ,342 ,חיים חפר
8700 ,רעננה ,416 ,חייקה גרוסמן
8700 ,רעננה ,140 ,חנקין
8700 ,רעננה ,108 ,חפץ חיים
8700 ,רעננה ,106 ,טבנקין יצחק
8700 ,רעננה ,133 ,טרומפלדור
8700 ,רעננה ,246 ,טשרניחובסקי
8700 ,רעננה ,463 ,יאיר
8700 ,רעננה ,343 ,יאיר רוזנבלום
8700 ,רעננה ,427 ,ידין יגאל
8700 ,רעננה ,219 ,יהודה הלוי
8700 ,רעננה ,152 ,יהודה הנשיא
8700 ,רעננה ,346 ,יהודה עמיחי
8700 ,רעננה ,347 ,יעקב אורלנד
8700 ,רעננה ,396 ,יערה
8700 ,רעננה ,141 ,יפה אליעזר
8700 ,רעננה ,348 ,יפה ירקוני
8700 ,רעננה ,400 ,יצחק גרציאני
8700 ,רעננה ,388 ,יצחק שדה
8700 ,רעננה ,313 ,יציאת אירופה
8700 ,רעננה ,184 ,ירושלים
8700 ,רעננה ,186 ,כביש חיפה
8700 ,רעננה ,399 ,כהן-כגן רחל
8700 ,רעננה ,349 ,כליל החורש
8700 ,רעננה ,352 ,כנרת
8700 ,רעננה ,6500 ,כפר בתיה
8700 ,רעננה ,6501 ,כפר נחמן
8700 ,רעננה ,183 ,כצנלסון ברל
8700 ,רעננה ,6530 ,לב הפארק
8700 ,רעננה ,277 ,לבהרי
8700 ,רעננה ,224 ,לזר
8700 ,רעננה ,288 ,לח"י
8700 ,רעננה ,426 ,לסקוב חיים
8700 ,רעננה ,222 ,מאה וששים
8700 ,רעננה ,350 ,מאפו
8700 ,רעננה ,384 ,מבצע קדש
8700 ,רעננה ,107 ,מגדל
8700 ,רעננה ,330 ,מולדת
8700 ,רעננה ,334 ,מונטיפיורי
8700 ,רעננה ,109 ,מוצקין
8700 ,רעננה ,319 ,מורדי הגטאות
8700 ,רעננה ,337 ,מורשה
8700 ,רעננה ,6502 ,מחנה עולים
8700 ,רעננה ,146 ,מכבי
8700 ,רעננה ,254 ,מנדלי
8700 ,רעננה ,417 ,מניה שוחט
8700 ,רעננה ,142 ,מסדה
8700 ,רעננה ,6503 ,מעון לעובד
8700 ,רעננה ,502 ,מקלף מרדכי
8700 ,רעננה ,405 ,מרדכי זעירא
8700 ,רעננה ,131 ,מרים ילן שטקליס
8700 ,רעננה ,217 ,מרכז קליטה
8700 ,רעננה ,406 ,משה וילנסקי
8700 ,רעננה ,6529 ,נאות הדר
8700 ,רעננה ,172 ,נאות שדה
8700 ,רעננה ,128 ,נג'ארה
8700 ,רעננה ,6505 ,נוה ברכה
8700 ,רעננה ,6506 ,נוה דוד רמז
8700 ,רעננה ,267 ,נורדאו
8700 ,רעננה ,255 ,נחמיה
8700 ,רעננה ,171 ,נחשון
8700 ,רעננה ,256 ,ניל"י
8700 ,רעננה ,407 ,נעמי שמר
8700 ,רעננה ,253 ,נתיב האבות
8700 ,רעננה ,149 ,נתן יונתן
8700 ,רעננה ,203 ,סולד
8700 ,רעננה ,218 ,סוקולוב
8700 ,רעננה ,331 ,סטרומה
8700 ,רעננה ,276 ,סירקין
8700 ,רעננה ,284 ,סמ הגשר
8700 ,רעננה ,357 ,סמ הדס
8700 ,רעננה ,392 ,סמ החומה
8700 ,רעננה ,160 ,סמ החשמונאים
8700 ,רעננה ,180 ,סמ המכבים
8700 ,רעננה ,483 ,סמ נוגה
8700 ,רעננה ,297 ,סמ רקפת
8700 ,רעננה ,464 ,סנה משה
8700 ,רעננה ,335 ,סנש חנה
8700 ,רעננה ,124 ,סעדיה גאון
8700 ,רעננה ,408 ,סשה ארגוב
8700 ,רעננה ,409 ,ע. הלל
8700 ,רעננה ,401 ,עגנון
8700 ,רעננה ,410 ,עוזי חיטמן
8700 ,רעננה ,370 ,עטרות
8700 ,רעננה ,411 ,עלי מוהר
8700 ,רעננה ,361 ,עמל
8700 ,רעננה ,412 ,עפרה חזה
8700 ,רעננה ,104 ,עציון
8700 ,רעננה ,144 ,עקיבא
8700 ,רעננה ,394 ,עתידים
8700 ,רעננה ,300 ,פוזין
8700 ,רעננה ,332 ,פטריה
8700 ,רעננה ,257 ,פינס
8700 ,רעננה ,265 ,פינסקר
8700 ,רעננה ,208 ,פרדס משותף
8700 ,רעננה ,264 ,פרישמן
8700 ,רעננה ,302 ,פרץ
8700 ,רעננה ,418 ,צביה לובטקין
8700 ,רעננה ,115 ,צימנד
8700 ,רעננה ,242 ,ציפמן
8700 ,רעננה ,170 ,קארו יוסף
8700 ,רעננה ,317 ,קבוץ גלויות
8700 ,רעננה ,182 ,קוממיות
8700 ,רעננה ,287 ,קורצ'ק ינוש
8700 ,רעננה ,225 ,קזן
8700 ,רעננה ,213 ,קלאוזנר
8700 ,רעננה ,228 ,קלישר
8700 ,רעננה ,273 ,קפלן
8700 ,רעננה ,258 ,קצין
8700 ,רעננה ,6522 ,קרית בן צבי
8700 ,רעננה ,6512 ,קרית גולומב
8700 ,רעננה ,6508 ,קרית גנים
8700 ,רעננה ,6533 ,קרית הספורט
8700 ,רעננה ,6532 ,קרית וייצמן
8700 ,רעננה ,6515 ,קרית שז"ר
8700 ,רעננה ,6509 ,קרית שרת
8700 ,רעננה ,310 ,קרן היסוד
8700 ,רעננה ,147 ,רבוצקי
8700 ,רעננה ,278 ,רבן גמליאל
8700 ,רעננה ,262 ,רוזן פנחס
8700 ,רעננה ,212 ,רזיאל דוד
8700 ,רעננה ,105 ,רחל המשוררת
8700 ,רעננה ,259 ,ריינס
8700 ,רעננה ,336 ,רייק חביבה
8700 ,רעננה ,462 ,רימלט אלימלך
8700 ,רעננה ,210 ,רמב"ם
8700 ,רעננה ,118 ,רמח"ל
8700 ,רעננה ,395 ,רננים
8700 ,רעננה ,9000 ,רעננה
8700 ,רעננה ,201 ,רש"י
8700 ,רעננה ,6510 ,ש בלוקונים
8700 ,רעננה ,6511 ,ש חדש
8700 ,רעננה ,6514 ,ש מזרחי
8700 ,רעננה ,6516 ,ש עובדים
8700 ,רעננה ,6518 ,ש עולים
8700 ,רעננה ,6519 ,ש עמידר
8700 ,רעננה ,6520 ,ש עממי
8700 ,רעננה ,6521 ,ש פועלים
8700 ,רעננה ,174 ,שבזי
8700 ,רעננה ,261 ,שבטי ישראל
8700 ,רעננה ,397 ,שביל הזהב
8700 ,רעננה ,315 ,שברץ
8700 ,רעננה ,413 ,שושנה דמארי
8700 ,רעננה ,6523 ,שכ אחד העם
8700 ,רעננה ,6524 ,שכ אריה
8700 ,רעננה ,6525 ,שכ אשר
8700 ,רעננה ,6531 ,שכ לזר
8700 ,רעננה ,6526 ,שכ לסטר
8700 ,רעננה ,6535 ,שכ נאות שדה
8700 ,רעננה ,6527 ,שכ פרץ
8700 ,רעננה ,6528 ,שכ ציפמן
8700 ,רעננה ,6504 ,שכ רום 2000
8700 ,רעננה ,247 ,שלום עליכם
8700 ,רעננה ,101 ,שלונסקי אברהם
8700 ,רעננה ,260 ,שליט
8700 ,רעננה ,173 ,שמואל הנגיד
8700 ,רעננה ,223 ,שמריהו לוין
8700 ,רעננה ,185 ,שפינוזה ברוך
8700 ,רעננה ,179 ,שפירא איזי
8700 ,רעננה ,467 ,שפירא משה
8700 ,רעננה ,241 ,ששת הימים
8700 ,רעננה ,113 ,תל חי
8700 ,רעננה ,414 ,תרצה אתר
1228 ,רקפת ,110 ,אגוז
1228 ,רקפת ,119 ,אורן
1228 ,רקפת ,104 ,אלה
1228 ,רקפת ,107 ,אלון
1228 ,רקפת ,112 ,ארז
1228 ,רקפת ,101 ,דולב
1228 ,רקפת ,108 ,דקל
1228 ,רקפת ,113 ,זית
1228 ,רקפת ,114 ,חרוב
1228 ,רקפת ,106 ,כליל החורש
1228 ,רקפת ,116 ,ליבנה
1228 ,רקפת ,117 ,ערבה
1228 ,רקפת ,103 ,רימון
1228 ,רקפת ,9000 ,רקפת
1228 ,רקפת ,109 ,שיזף
1228 ,רקפת ,105 ,שקד
1228 ,רקפת ,115 ,שקמה
1228 ,רקפת ,111 ,תאנה
1228 ,רקפת ,118 ,תות
1228 ,רקפת ,102 ,תמר
247 ,רשפון ,101 ,דפנה
247 ,רשפון ,103 ,דרך הגן
247 ,רשפון ,112 ,דרך ההרדופים
247 ,רשפון ,111 ,דרך המסילה
247 ,רשפון ,114 ,דרך הפקאנים
247 ,רשפון ,113 ,דרך הפרדס
247 ,רשפון ,110 ,דרך השיבולים
247 ,רשפון ,117 ,האתרוג
247 ,רשפון ,109 ,החרוב
247 ,רשפון ,108 ,הכפר
247 ,רשפון ,105 ,הפרחים
247 ,רשפון ,106 ,הרותם
247 ,רשפון ,104 ,הרימון
247 ,רשפון ,102 ,השקד
247 ,רשפון ,107 ,התמר
247 ,רשפון ,116 ,התפוז
247 ,רשפון ,120 ,סמ האירוס
247 ,רשפון ,121 ,סמ הכלנית
247 ,רשפון ,122 ,סמ הנורית
247 ,רשפון ,123 ,סמ הצבעוני
247 ,רשפון ,124 ,סמ הרקפת
247 ,רשפון ,9000 ,רשפון
247 ,רשפון ,115 ,שביל החלב
247 ,רשפון ,130 ,שביל התפוזים
437 ,רשפים ,9000 ,רשפים
1260 ,רתמים ,101 ,מרכז קליטה
1260 ,רתמים ,9000 ,רתמים
324 ,שאר ישוב ,106 ,הירדן
324 ,שאר ישוב ,105 ,המעיין
324 ,שאר ישוב ,101 ,טל
324 ,שאר ישוב ,102 ,יובלים
324 ,שאר ישוב ,103 ,נחלים
324 ,שאר ישוב ,104 ,פלגי מים
324 ,שאר ישוב ,9000 ,שאר ישוב
1377 ,שבי דרום ,109 ,אפריון
1377 ,שבי דרום ,101 ,גוש קטיף
1377 ,שבי דרום ,107 ,דביר
1377 ,שבי דרום ,103 ,הלבנון
1377 ,שבי דרום ,105 ,יפה נוף
1377 ,שבי דרום ,102 ,כפר דרום
1377 ,שבי דרום ,108 ,נווה
1377 ,שבי דרום ,104 ,קריית מלך
1377 ,שבי דרום ,9000 ,שבי דרום
1377 ,שבי דרום ,106 ,תלפיות
282 ,שבי ציון ,105 ,אלון
282 ,שבי ציון ,104 ,דקל
282 ,שבי ציון ,101 ,דרך הים
282 ,שבי ציון ,128 ,דרך השדות
282 ,שבי ציון ,132 ,האדמה
282 ,שבי ציון ,102 ,האלה
282 ,שבי ציון ,129 ,האסיף
282 ,שבי ציון ,103 ,הברוש
282 ,שבי ציון ,126 ,הגפן
282 ,שבי ציון ,125 ,הזית
282 ,שבי ציון ,107 ,החוף
282 ,שבי ציון ,131 ,החורשה
282 ,שבי ציון ,134 ,החמניות
282 ,שבי ציון ,133 ,הכותנה
282 ,שבי ציון ,124 ,המנור
282 ,שבי ציון ,108 ,המפרש
282 ,שבי ציון ,114 ,הנחשול
282 ,שבי ציון ,120 ,הסירה
282 ,שבי ציון ,111 ,העוגן
282 ,שבי ציון ,112 ,הפנגווין
282 ,שבי ציון ,130 ,הפרדס
282 ,שבי ציון ,113 ,הצלצל
282 ,שבי ציון ,106 ,הרדוף
282 ,שבי ציון ,122 ,התורן
282 ,שבי ציון ,115 ,ורד
282 ,שבי ציון ,127 ,טיילת מיכה
282 ,שבי ציון ,119 ,כוכב הים
282 ,שבי ציון ,109 ,נר הלילה
282 ,שבי ציון ,135 ,סמטת ההדרים
282 ,שבי ציון ,110 ,סנונית
282 ,שבי ציון ,118 ,קונכיה
282 ,שבי ציון ,116 ,קרן
282 ,שבי ציון ,9000 ,שבי ציון
282 ,שבי ציון ,121 ,שחף
282 ,שבי ציון ,117 ,שיקשק
282 ,שבי ציון ,123 ,תמירון
3571 ,שבי שומרון ,6500 ,ש קרוונים
3571 ,שבי שומרון ,9000 ,שבי שומרון
3571 ,שבי שומרון ,101 ,שובו בנים
913 ,שבלי - אום אל-גנם ,120 ,אבו מדוור
913 ,שבלי - אום אל-גנם ,102 ,אום אל-גנם
913 ,שבלי - אום אל-גנם ,134 ,אום בוטמה
913 ,שבלי - אום אל-גנם ,150 ,אום ג'בל
913 ,שבלי - אום אל-גנם ,160 ,אלאנואר
913 ,שבלי - אום אל-גנם ,118 ,אלבטין
913 ,שבלי - אום אל-גנם ,121 ,אלביאדר
913 ,שבלי - אום אל-גנם ,163 ,אלבלאטה
913 ,שבלי - אום אל-גנם ,159 ,אלבלוט
913 ,שבלי - אום אל-גנם ,126 ,אלברכה
913 ,שבלי - אום אל-גנם ,131 ,אלג'אמע
913 ,שבלי - אום אל-גנם ,176 ,אלגרז
913 ,שבלי - אום אל-גנם ,116 ,אלד'הרה
913 ,שבלי - אום אל-גנם ,138 ,אלד'יאבי
913 ,שבלי - אום אל-גנם ,146 ,אלדבאצ'ה
913 ,שבלי - אום אל-גנם ,167 ,אלדום
913 ,שבלי - אום אל-גנם ,129 ,אלוסטאני
913 ,שבלי - אום אל-גנם ,137 ,אלוסעה
913 ,שבלי - אום אל-גנם ,124 ,אלזהרא
913 ,שבלי - אום אל-גנם ,135 ,אלזור
913 ,שבלי - אום אל-גנם ,175 ,אלזיתון
913 ,שבלי - אום אל-גנם ,162 ,אלח'לה
913 ,שבלי - אום אל-גנם ,157 ,אלח'רוב
913 ,שבלי - אום אל-גנם ,111 ,אלחאווז
913 ,שבלי - אום אל-גנם ,109 ,אלחארה
913 ,שבלי - אום אל-גנם ,105 ,אלטבקה
913 ,שבלי - אום אל-גנם ,103 ,אלטור
913 ,שבלי - אום אל-גנם ,125 ,אללוז
913 ,שבלי - אום אל-גנם ,106 ,אלמג'ארה
913 ,שבלי - אום אל-גנם ,152 ,אלמג'יר
913 ,שבלי - אום אל-גנם ,143 ,אלמדנג'את
913 ,שבלי - אום אל-גנם ,107 ,אלמדראג'
913 ,שבלי - אום אל-גנם ,110 ,אלמדרסה
913 ,שבלי - אום אל-גנם ,114 ,אלמזאר
913 ,שבלי - אום אל-גנם ,165 ,אלמחאג'ר
913 ,שבלי - אום אל-גנם ,171 ,אלמטבאת
913 ,שבלי - אום אל-גנם ,130 ,אלמידאן
913 ,שבלי - אום אל-גנם ,154 ,אלמצאיה
913 ,שבלי - אום אל-גנם ,139 ,אלמת'לת'
913 ,שבלי - אום אל-גנם ,149 ,אלסוואנייה
913 ,שבלי - אום אל-גנם ,122 ,אלסנדיאן
913 ,שבלי - אום אל-גנם ,161 ,אלסריס
913 ,שבלי - אום אל-גנם ,147 ,אלע'אבה
913 ,שבלי - אום אל-גנם ,169 ,אלעבהר
913 ,שבלי - אום אל-גנם ,166 ,אלעיאדה
913 ,שבלי - אום אל-גנם ,128 ,אלפרדוס
913 ,שבלי - אום אל-גנם ,148 ,אלפרש
913 ,שבלי - אום אל-גנם ,177 ,אלצנובר
913 ,שבלי - אום אל-גנם ,117 ,אלקסאטל
913 ,שבלי - אום אל-גנם ,142 ,אלקסברה
913 ,שבלי - אום אל-גנם ,141 ,אלרביד'את
913 ,שבלי - אום אל-גנם ,112 ,אלרואג'יח
913 ,שבלי - אום אל-גנם ,123 ,אלרינה
913 ,שבלי - אום אל-גנם ,133 ,אלרמאן
913 ,שבלי - אום אל-גנם ,178 ,אלשראר
913 ,שבלי - אום אל-גנם ,158 ,אלשתול
913 ,שבלי - אום אל-גנם ,140 ,אלתון
913 ,שבלי - אום אל-גנם ,115 ,באבור אלעת'מאן
913 ,שבלי - אום אל-גנם ,168 ,ביר אללוזה
913 ,שבלי - אום אל-גנם ,145 ,ביר אלשרקה
913 ,שבלי - אום אל-גנם ,155 ,ג'ורת אלג'זלאן
913 ,שבלי - אום אל-גנם ,119 ,ד'ראע איוב
913 ,שבלי - אום אל-גנם ,170 ,ואדי אלמשתא
913 ,שבלי - אום אל-גנם ,108 ,ח'לת אלנסור
913 ,שבלי - אום אל-גנם ,179 ,ח'לת אלפול
913 ,שבלי - אום אל-גנם ,151 ,חואסה
913 ,שבלי - אום אל-גנם ,113 ,מקאם אמנה
913 ,שבלי - אום אל-גנם ,164 ,מקאם עאסי
913 ,שבלי - אום אל-גנם ,182 ,סביתה
913 ,שבלי - אום אל-גנם ,153 ,סוק אלח'אן
913 ,שבלי - אום אל-גנם ,156 ,סרטבה
913 ,שבלי - אום אל-גנם ,136 ,עין אלזעטוטה
913 ,שבלי - אום אל-גנם ,132 ,עין אלטביל
913 ,שבלי - אום אל-גנם ,174 ,עין אלקסיב
913 ,שבלי - אום אל-גנם ,104 ,עין אלתינה
913 ,שבלי - אום אל-גנם ,173 ,עין מקלד
913 ,שבלי - אום אל-גנם ,144 ,עלואן
913 ,שבלי - אום אל-גנם ,127 ,קסטה
913 ,שבלי - אום אל-גנם ,101 ,שבלי
913 ,שבלי - אום אל-גנם ,9000 ,שבלי - אום אל-גנם
1286 ,שגב-שלום ,121 ,אבו אלעתאהיה
1286 ,שגב-שלום ,109 ,אבו בקר אלסדיק
1286 ,שגב-שלום ,116 ,אבו חניפה אלנעמאן
1286 ,שגב-שלום ,144 ,אבו עוביידה אלגראח
1286 ,שגב-שלום ,101 ,אבו תמאם
1286 ,שגב-שלום ,145 ,אבונא אבראהים
1286 ,שגב-שלום ,108 ,אבונא אדם
1286 ,שגב-שלום ,170 ,אבן אלהיתם
1286 ,שגב-שלום ,110 ,אבן אלמוקפע
1286 ,שגב-שלום ,125 ,אבן סינא
1286 ,שגב-שלום ,162 ,אבן עוף
1286 ,שגב-שלום ,115 ,אחמד אבן חנבל
1286 ,שגב-שלום ,123 ,אחמד זויל
1286 ,שגב-שלום ,146 ,אחסאן עבד אלקדוס
1286 ,שגב-שלום ,147 ,אל אנביא
1286 ,שגב-שלום ,136 ,אל גזאלי
1286 ,שגב-שלום ,106 ,אלאנדלוס
1286 ,שגב-שלום ,119 ,אלגולאן
1286 ,שגב-שלום ,134 ,אלדיואן
1286 ,שגב-שלום ,103 ,אלוורוד
1286 ,שגב-שלום ,148 ,אלזהרא
1286 ,שגב-שלום ,149 ,אלירמוך
1286 ,שגב-שלום ,150 ,אלכנסא
1286 ,שגב-שלום ,159 ,אלמדינה
1286 ,שגב-שלום ,102 ,אלמוסתקבל
1286 ,שגב-שלום ,118 ,אלפרדוס
1286 ,שגב-שלום ,151 ,אלקאדסיה
1286 ,שגב-שלום ,105 ,אלקודס
1286 ,שגב-שלום ,152 ,אלקורונפול
1286 ,שגב-שלום ,126 ,אלראזי
1286 ,שגב-שלום ,160 ,אלשאפעי
1286 ,שגב-שלום ,122 ,אמראו אלקיס
1286 ,שגב-שלום ,129 ,בדר
1286 ,שגב-שלום ,114 ,בילאל אבן רבאח
1286 ,שגב-שלום ,153 ,ג'אבר אבן חיאן
1286 ,שגב-שלום ,154 ,ג'בראן חליל ג'בראן
1286 ,שגב-שלום ,107 ,האגר
1286 ,שגב-שלום ,155 ,הארון אלרשיד
1286 ,שגב-שלום ,131 ,ואדי מכה
1286 ,שגב-שלום ,127 ,זהיר אבן אלעואם
1286 ,שגב-שלום ,138 ,זמזם
1286 ,שגב-שלום ,113 ,חאלד אבן אלוליד
1286 ,שגב-שלום ,132 ,חטין
1286 ,שגב-שלום ,157 ,טאהא חוסין
1286 ,שגב-שלום ,158 ,טארק אבן זיאד
1286 ,שגב-שלום ,117 ,מאלק אבן אנס
1286 ,שגב-שלום ,130 ,מואתא
1286 ,שגב-שלום ,135 ,מוסא אבן מימון
1286 ,שגב-שלום ,104 ,מכה
1286 ,שגב-שלום ,156 ,מלך חוסין
1286 ,שגב-שלום ,139 ,נבי מוסא
1286 ,שגב-שלום ,142 ,נביא איוב
1286 ,שגב-שלום ,161 ,נהאונד
1286 ,שגב-שלום ,143 ,נוח
1286 ,שגב-שלום ,141 ,סידנא יוסף
1286 ,שגב-שלום ,112 ,סלאח אלדין
1286 ,שגב-שלום ,111 ,סלמאן אלפארסי
1286 ,שגב-שלום ,128 ,סעד בן אבי וקאס
1286 ,שגב-שלום ,124 ,עבאס אבן פרנאס
1286 ,שגב-שלום ,163 ,עומר אבן אלח'טאב
1286 ,שגב-שלום ,164 ,עומר אלח'יאם
1286 ,שגב-שלום ,140 ,עיסא
1286 ,שגב-שלום ,166 ,עלי בן אבו טאלב
1286 ,שגב-שלום ,120 ,עמאן
1286 ,שגב-שלום ,167 ,עמר אלמח'תאר
1286 ,שגב-שלום ,168 ,ענתר אבן שדאד
1286 ,שגב-שלום ,133 ,עסגלאן
1286 ,שגב-שלום ,137 ,ערפה
1286 ,שגב-שלום ,165 ,עתמאן אבן עפאן
1286 ,שגב-שלום ,9000 ,שגב-שלום
1286 ,שגב-שלום ,169 ,שייך עודה
1286 ,שגב-שלום ,6702 ,שכ 1
1286 ,שגב-שלום ,6701 ,שכ 2
1286 ,שגב-שלום ,6703 ,שכ 3
1286 ,שגב-שלום ,6705 ,שכ 4
1286 ,שגב-שלום ,6704 ,שכ 5
1286 ,שגב-שלום ,6706 ,שכ 6
1286 ,שגב-שלום ,6707 ,שכ 7
1286 ,שגב-שלום ,6708 ,שכ 8
721 ,שדה אילן ,105 ,אסיף
721 ,שדה אילן ,109 ,גורן
721 ,שדה אילן ,101 ,חריש
721 ,שדה אילן ,103 ,כרם
721 ,שדה אילן ,104 ,מגל
721 ,שדה אילן ,107 ,מסיק
721 ,שדה אילן ,102 ,קציר
721 ,שדה אילן ,9000 ,שדה אילן
721 ,שדה אילן ,106 ,תירוש
304 ,שדה אליהו ,101 ,אולפן קיבוץ
304 ,שדה אליהו ,9000 ,שדה אליהו
861 ,שדה אליעזר ,101 ,אורן
861 ,שדה אליעזר ,102 ,אלה
861 ,שדה אליעזר ,108 ,אלון
861 ,שדה אליעזר ,110 ,אשל
861 ,שדה אליעזר ,103 ,דקל
861 ,שדה אליעזר ,107 ,הדר
861 ,שדה אליעזר ,111 ,הזית
861 ,שדה אליעזר ,105 ,סיגלון
861 ,שדה אליעזר ,106 ,ערבה
861 ,שדה אליעזר ,9000 ,שדה אליעזר
861 ,שדה אליעזר ,109 ,שקד
861 ,שדה אליעזר ,104 ,תאנה
885 ,שדה בוקר ,7001 ,חוות נחל הרועה
885 ,שדה בוקר ,9000 ,שדה בוקר
36 ,שדה דוד ,108 ,גן השקמים
36 ,שדה דוד ,109 ,ההולך בשדות
36 ,שדה דוד ,104 ,הזיתים
36 ,שדה דוד ,105 ,הכרמים
36 ,שדה דוד ,101 ,המייסדים
36 ,שדה דוד ,103 ,הפרחים
36 ,שדה דוד ,110 ,הרימון
36 ,שדה דוד ,106 ,התאנה
36 ,שדה דוד ,102 ,מבוא ניצן
36 ,שדה דוד ,107 ,נוף השדות
36 ,שדה דוד ,9000 ,שדה דוד
284 ,שדה ורבורג ,108 ,דום הדקל
284 ,שדה ורבורג ,104 ,דרך גן הילדים
284 ,שדה ורבורג ,101 ,דרך האורנים
284 ,שדה ורבורג ,103 ,דרך הדקל
284 ,שדה ורבורג ,102 ,דרך ההדרים
284 ,שדה ורבורג ,105 ,דרך הפרחים
284 ,שדה ורבורג ,118 ,דרך תל אשר
284 ,שדה ורבורג ,113 ,האלה
284 ,שדה ורבורג ,106 ,האלון
284 ,שדה ורבורג ,107 ,הארז
284 ,שדה ורבורג ,109 ,הברוש
284 ,שדה ורבורג ,114 ,הגפן
284 ,שדה ורבורג ,112 ,ההדס
284 ,שדה ורבורג ,110 ,הזית
284 ,שדה ורבורג ,115 ,הרימון
284 ,שדה ורבורג ,111 ,השקד
284 ,שדה ורבורג ,116 ,השקמה
284 ,שדה ורבורג ,117 ,סמ הילד
284 ,שדה ורבורג ,9000 ,שדה ורבורג
284 ,שדה ורבורג ,6500 ,שכון בנים
293 ,שדה יואב ,9000 ,שדה יואב
142 ,שדה יעקב ,110 ,דרך ארץ
142 ,שדה יעקב ,115 ,דרך האלון
142 ,שדה יעקב ,102 ,דרך המיסדים
142 ,שדה יעקב ,109 ,דרך המעין
142 ,שדה יעקב ,114 ,דרך הנחל
142 ,שדה יעקב ,113 ,דרך השדות
142 ,שדה יעקב ,107 ,דרך חריש
142 ,שדה יעקב ,111 ,דרך עליית הנוער
142 ,שדה יעקב ,108 ,דרך קטיף
142 ,שדה יעקב ,103 ,דרך שח"ל
142 ,שדה יעקב ,6001 ,כיכר המחלבה
142 ,שדה יעקב ,106 ,מעלה הגבעה
142 ,שדה יעקב ,104 ,מעלה הגורן
142 ,שדה יעקב ,105 ,מעלה המגדל
142 ,שדה יעקב ,112 ,משעול הגנים
142 ,שדה יעקב ,101 ,נוה עמיאל
142 ,שדה יעקב ,9000 ,שדה יעקב
2008 ,שדה יצחק ,101 ,האלון
2008 ,שדה יצחק ,102 ,הברוש
2008 ,שדה יצחק ,103 ,הזית
2008 ,שדה יצחק ,104 ,המייסדים
2008 ,שדה יצחק ,105 ,הערבה
2008 ,שדה יצחק ,106 ,הפיקוס
2008 ,שדה יצחק ,107 ,הצאלון
2008 ,שדה יצחק ,108 ,השיטה
2008 ,שדה יצחק ,109 ,התמר
2008 ,שדה יצחק ,9000 ,שדה יצחק
18 ,שדה משה ,9000 ,שדה משה
259 ,שדה נחום ,9000 ,שדה נחום
329 ,שדה נחמיה ,9000 ,שדה נחמיה
1058 ,שדה ניצן ,9000 ,שדה ניצן
739 ,שדה עוזיהו ,9000 ,שדה עוזיהו
2049 ,שדה צבי ,9000 ,שדה צבי
327 ,שדות ים ,101 ,נופשונית
327 ,שדות ים ,9000 ,שדות ים
27 ,שדות מיכה ,9000 ,שדות מיכה
1223 ,שדי אברהם ,102 ,גפן
1223 ,שדי אברהם ,103 ,זית
1223 ,שדי אברהם ,104 ,רימון
1223 ,שדי אברהם ,9000 ,שדי אברהם
1223 ,שדי אברהם ,101 ,תאנה
2015 ,שדי חמד ,114 ,אורן
2015 ,שדי חמד ,107 ,אלון
2015 ,שדי חמד ,113 ,ארז
2015 ,שדי חמד ,110 ,ברוש
2015 ,שדי חמד ,105 ,גפן
2015 ,שדי חמד ,115 ,הדס
2015 ,שדי חמד ,102 ,החרצית
2015 ,שדי חמד ,116 ,זית
2015 ,שדי חמד ,109 ,חיטה
2015 ,שדי חמד ,112 ,סמטת שיטה
2015 ,שדי חמד ,103 ,רימון
2015 ,שדי חמד ,106 ,שבעת המינים
2015 ,שדי חמד ,9000 ,שדי חמד
2015 ,שדי חמד ,117 ,שדרת הדקלים
2015 ,שדי חמד ,111 ,שיקמה
2015 ,שדי חמד ,108 ,שעורה
2015 ,שדי חמד ,104 ,תאנה
2015 ,שדי חמד ,101 ,תמר
2057 ,שדי תרומות ,9000 ,שדי תרומות
555 ,שדמה ,101 ,הזורעים
555 ,שדמה ,106 ,החורשה
555 ,שדמה ,105 ,המורג
555 ,שדמה ,102 ,הנוקדים
555 ,שדמה ,103 ,הקוצרים
555 ,שדמה ,104 ,סמטת המגל
555 ,שדמה ,9000 ,שדמה
555 ,שדמה ,6500 ,שיכון בנים
306 ,שדמות דבורה ,9000 ,שדמות דבורה
3578 ,שדמות מחולה ,9000 ,שדמות מחולה
1031 ,שדרות ,312 ,אבו חצירה ישראל
1031 ,שדרות ,253 ,אבן גבירול
1031 ,שדרות ,392 ,אדמונית החורש
1031 ,שדרות ,255 ,אהבת ישראל
1031 ,שדרות ,162 ,אהבת ציון
1031 ,שדרות ,456 ,אהוד מנור
1031 ,שדרות ,359 ,אוסלו
1031 ,שדרות ,147 ,אורנים
1031 ,שדרות ,6514 ,אזור התעשיה
1031 ,שדרות ,166 ,אחד העם
1031 ,שדרות ,107 ,אלבז נתן
1031 ,שדרות ,504 ,אלומות
1031 ,שדרות ,165 ,אלון יגאל
1031 ,שדרות ,440 ,אלי כהן
1031 ,שדרות ,435 ,אלי מויאל
1031 ,שדרות ,210 ,אלישע הנביא
1031 ,שדרות ,477 ,אלמוגן
1031 ,שדרות ,330 ,אלנבי
1031 ,שדרות ,290 ,אלעזר דוד
1031 ,שדרות ,257 ,אלתרמן נתן
1031 ,שדרות ,380 ,אמנון ותמר
1031 ,שדרות ,350 ,אמסטרדם
1031 ,שדרות ,488 ,אנפה
1031 ,שדרות ,154 ,אסירי ציון
1031 ,שדרות ,484 ,ארבל
1031 ,שדרות ,259 ,ארזים
1031 ,שדרות ,453 ,אריק איינשטיין
1031 ,שדרות ,451 ,אריק לביא
1031 ,שדרות ,194 ,ארן זלמן
1031 ,שדרות ,260 ,אשור
1031 ,שדרות ,497 ,אשכולית
1031 ,שדרות ,354 ,אתונה
1031 ,שדרות ,261 ,בורוכוב
1031 ,שדרות ,223 ,ביאליק
1031 ,שדרות ,250 ,בית אהרון
1031 ,שדרות ,358 ,בלגרד
1031 ,שדרות ,176 ,בלפור
1031 ,שדרות ,160 ,בן יהודה
1031 ,שדרות ,104 ,בן צבי יצחק
1031 ,שדרות ,262 ,בר אילן
1031 ,שדרות ,258 ,בר-לב חיים
1031 ,שדרות ,365 ,בריסל
1031 ,שדרות ,224 ,ברית ערים
1031 ,שדרות ,183 ,ברנר
1031 ,שדרות ,388 ,בשמת
1031 ,שדרות ,457 ,ג'ו עמר
1031 ,שדרות ,487 ,גדרון
1031 ,שדרות ,263 ,גולומב
1031 ,שדרות ,479 ,גולן
1031 ,שדרות ,264 ,גולני
1031 ,שדרות ,332 ,גורדוניה
1031 ,שדרות ,167 ,גלילי ישראל
1031 ,שדרות ,175 ,גרינבאום
1031 ,שדרות ,513 ,גרניט
1031 ,שדרות ,393 ,גרנית
1031 ,שדרות ,361 ,דבלין
1031 ,שדרות ,265 ,דגניה
1031 ,שדרות ,496 ,דובדבן
1031 ,שדרות ,442 ,דוד המלך
1031 ,שדרות ,267 ,דורי יעקב
1031 ,שדרות ,191 ,דיין משה
1031 ,שדרות ,389 ,דליה
1031 ,שדרות ,207 ,דניאל
1031 ,שדרות ,480 ,דפנה
1031 ,שדרות ,150 ,דרך דוד כהן
1031 ,שדרות ,164 ,דרך ההנדסה
1031 ,שדרות ,216 ,דרך העליה
1031 ,שדרות ,337 ,דרך הרב שלום איפרגן
1031 ,שדרות ,243 ,דרך יצחק שמיר
1031 ,שדרות ,298 ,דרך מאיר גולדה
1031 ,שדרות ,168 ,דרך רונית אלקבץ
1031 ,שדרות ,364 ,האג
1031 ,שדרות ,420 ,האחוזה
1031 ,שדרות ,145 ,האלה
1031 ,שדרות ,187 ,האמוראים
1031 ,שדרות ,505 ,האסיף
1031 ,שדרות ,136 ,האצ"ל
1031 ,שדרות ,268 ,האר"י
1031 ,שדרות ,101 ,האשל
1031 ,שדרות ,142 ,הבנים
1031 ,שדרות ,318 ,הבעל שם טוב
1031 ,שדרות ,269 ,הגיא
1031 ,שדרות ,174 ,הגיבורים
1031 ,שדרות ,252 ,הגליל
1031 ,שדרות ,310 ,הגפן
1031 ,שדרות ,140 ,הדגל
1031 ,שדרות ,133 ,ההגנה
1031 ,שדרות ,130 ,ההסתדרות
1031 ,שדרות ,336 ,ההעפלה
1031 ,שדרות ,333 ,הולצברג שמחה
1031 ,שדרות ,110 ,הורד
1031 ,שדרות ,172 ,החיד"א
1031 ,שדרות ,272 ,החלוץ
1031 ,שדרות ,507 ,החריש
1031 ,שדרות ,334 ,החשמונאים
1031 ,שדרות ,169 ,הלל הזקן
1031 ,שדרות ,273 ,הלל יפה
1031 ,שדרות ,353 ,הלסינקי
1031 ,שדרות ,274 ,המגינים
1031 ,שדרות ,508 ,המדע
1031 ,שדרות ,280 ,המכבים
1031 ,שדרות ,275 ,המעפילים
1031 ,שדרות ,143 ,הנחלים
1031 ,שדרות ,276 ,הנציב
1031 ,שדרות ,320 ,הס משה
1031 ,שדרות ,134 ,העצמאות
1031 ,שדרות ,155 ,הפלמ"ח
1031 ,שדרות ,105 ,הצאלה
1031 ,שדרות ,278 ,הקוממיות
1031 ,שדרות ,173 ,הקונגרס
1031 ,שדרות ,506 ,הקטיף
1031 ,שדרות ,509 ,הקידמה
1031 ,שדרות ,438 ,הרב אבא אבוחצירא
1031 ,שדרות ,244 ,הרב אליישיב
1031 ,שדרות ,152 ,הרב בן נון יעקב
1031 ,שדרות ,218 ,הרב דוד בר חן
1031 ,שדרות ,436 ,הרב וישליצקי אלישע
1031 ,שדרות ,226 ,הרב יעקב יצחקי
1031 ,שדרות ,249 ,הרב כדורי
1031 ,שדרות ,186 ,הרב מוהליבר
1031 ,שדרות ,199 ,הרב מימון
1031 ,שדרות ,246 ,הרב מרדכי אליהו
1031 ,שדרות ,247 ,הרב עובדיה יוסף
1031 ,שדרות ,455 ,הרב קוק
1031 ,שדרות ,245 ,הרב שך
1031 ,שדרות ,177 ,הרב שלום בר חנין
1031 ,שדרות ,227 ,הרב שלום משאש
1031 ,שדרות ,248 ,הרבי מילובביץ
1031 ,שדרות ,279 ,הרימון
1031 ,שדרות ,189 ,הרצוג
1031 ,שדרות ,158 ,הרצל
1031 ,שדרות ,181 ,הרצפלד
1031 ,שדרות ,111 ,הרקפת
1031 ,שדרות ,135 ,השומר
1031 ,שדרות ,251 ,השופטים
1031 ,שדרות ,102 ,השיטה
1031 ,שדרות ,294 ,השקד
1031 ,שדרות ,188 ,השרון
1031 ,שדרות ,144 ,התמר
1031 ,שדרות ,313 ,ויצמן
1031 ,שדרות ,180 ,ויתקין
1031 ,שדרות ,352 ,ונציה
1031 ,שדרות ,125 ,ורדימון
1031 ,שדרות ,459 ,זוהרה אלפסיה
1031 ,שדרות ,209 ,זכריה
1031 ,שדרות ,132 ,זרח משה
1031 ,שדרות ,201 ,חבקוק
1031 ,שדרות ,282 ,חברון
1031 ,שדרות ,119 ,חגי
1031 ,שדרות ,283 ,חומה ומגדל
1031 ,שדרות ,7001 ,חות שקמים
1031 ,שדרות ,284 ,חזון איש
1031 ,שדרות ,128 ,חטיבת גבעתי
1031 ,שדרות ,325 ,חטיבת הנגב
1031 ,שדרות ,285 ,חטיבת הראל
1031 ,שדרות ,286 ,חטיבת יפתח
1031 ,שדרות ,287 ,חטיבת כרמלי
1031 ,שדרות ,288 ,חטיבת צנחנים
1031 ,שדרות ,500 ,חיטה
1031 ,שדרות ,468 ,חליל
1031 ,שדרות ,171 ,חללי אגוז
1031 ,שדרות ,441 ,חנה סנש
1031 ,שדרות ,233 ,חצב
1031 ,שדרות ,470 ,חצוצרה
1031 ,שדרות ,490 ,חרוב
1031 ,שדרות ,179 ,טיומקין
1031 ,שדרות ,302 ,טיילת ספיר פנחס
1031 ,שדרות ,192 ,טרומפלדור
1031 ,שדרות ,289 ,יבניאלי
1031 ,שדרות ,222 ,יהדות איטליה
1031 ,שדרות ,225 ,יהודה הלוי
1031 ,שדרות ,452 ,יהודה עמיחי
1031 ,שדרות ,213 ,יהושע בן נון
1031 ,שדרות ,202 ,יואל
1031 ,שדרות ,203 ,יונה
1031 ,שדרות ,230 ,יונתן פולארד
1031 ,שדרות ,197 ,יוסף חיים
1031 ,שדרות ,129 ,יוספטל
1031 ,שדרות ,113 ,יורדי הסירה
1031 ,שדרות ,122 ,יחזקאל
1031 ,שדרות ,141 ,יערה
1031 ,שדרות ,439 ,יצחק נבון
1031 ,שדרות ,293 ,יצחק שדה
1031 ,שדרות ,381 ,יקינטון
1031 ,שדרות ,190 ,ירושלים
1031 ,שדרות ,117 ,ירמיהו
1031 ,שדרות ,106 ,ישיבת ציון
1031 ,שדרות ,118 ,ישעיהו
1031 ,שדרות ,315 ,ישראל ישעיהו
1031 ,שדרות ,395 ,כוכבית
1031 ,שדרות ,6010 ,כיכר אנטוני
1031 ,שדרות ,6007 ,כיכר בגין
1031 ,שדרות ,6026 ,כיכר בית החיים
1031 ,שדרות ,6028 ,כיכר בראשית
1031 ,שדרות ,6008 ,כיכר גנדי
1031 ,שדרות ,6022 ,כיכר דוד המלך
1031 ,שדרות ,6035 ,כיכר האחוזה
1031 ,שדרות ,6039 ,כיכר האיבה
1031 ,שדרות ,6027 ,כיכר הבנים
1031 ,שדרות ,6012 ,כיכר הברזל
1031 ,שדרות ,6036 ,כיכר הגבורה
1031 ,שדרות ,6040 ,כיכר הגופר
1031 ,שדרות ,6029 ,כיכר הדורות
1031 ,שדרות ,6037 ,כיכר החלוצים
1031 ,שדרות ,6033 ,כיכר החליל
1031 ,שדרות ,6032 ,כיכר הכינור
1031 ,שדרות ,6015 ,כיכר המדע
1031 ,שדרות ,6004 ,כיכר המוסיקה
1031 ,שדרות ,6013 ,כיכר המלאכה
1031 ,שדרות ,6011 ,כיכר המלך חסן
1031 ,שדרות ,6034 ,כיכר הנביאים
1031 ,שדרות ,6031 ,כיכר הנבל
1031 ,שדרות ,6006 ,כיכר הסטודנטים
1031 ,שדרות ,6003 ,כיכר הרכבת
1031 ,שדרות ,6038 ,כיכר השעון
1031 ,שדרות ,6041 ,כיכר התעשיה
1031 ,שדרות ,6020 ,כיכר חב"ד
1031 ,שדרות ,6014 ,כיכר חנוכייה
1031 ,שדרות ,6023 ,כיכר יהונתן יפרח
1031 ,שדרות ,6019 ,כיכר מוטה גור
1031 ,שדרות ,6005 ,כיכר ספרא
1031 ,שדרות ,6030 ,כיכר ציון
1031 ,שדרות ,6024 ,כיכר צלנדרוף
1031 ,שדרות ,6017 ,כיכר קק"ל
1031 ,שדרות ,6025 ,כיכר קריספל
1031 ,שדרות ,6016 ,כיכר רפול
1031 ,שדרות ,6021 ,כיכר שמחה ברנר
1031 ,שדרות ,6018 ,כיכר שמעון עמר
1031 ,שדרות ,6009 ,כיכר שרון
1031 ,שדרות ,471 ,כינור
1031 ,שדרות ,6002 ,ככר המושבות
1031 ,שדרות ,295 ,כלנית
1031 ,שדרות ,340 ,כצנלסון
1031 ,שדרות ,454 ,לאה גולדברג
1031 ,שדרות ,437 ,לובה אליאב
1031 ,שדרות ,443 ,לוחמי האש
1031 ,שדרות ,383 ,לוטוס
1031 ,שדרות ,296 ,לוטם
1031 ,שדרות ,355 ,לונדון
1031 ,שדרות ,185 ,לח"י
1031 ,שדרות ,363 ,ליסבון
1031 ,שדרות ,345 ,מבצע אנטבה
1031 ,שדרות ,343 ,מבצע חורב
1031 ,שדרות ,157 ,מבצע יואב
1031 ,שדרות ,241 ,מבצע יונתן
1031 ,שדרות ,240 ,מבצע משה
1031 ,שדרות ,108 ,מבצע סיני
1031 ,שדרות ,346 ,מבצע עובדה
1031 ,שדרות ,234 ,מבצע קדש
1031 ,שדרות ,493 ,מגנוליה
1031 ,שדרות ,362 ,מדריד
1031 ,שדרות ,341 ,מונטיפיורי משה
1031 ,שדרות ,321 ,מוצקין
1031 ,שדרות ,482 ,מורן
1031 ,שדרות ,120 ,מיכה
1031 ,שדרות ,206 ,מלאכי
1031 ,שדרות ,349 ,מצדה
1031 ,שדרות ,299 ,מקווה ישראל
1031 ,שדרות ,390 ,מרגנית
1031 ,שדרות ,478 ,מרום
1031 ,שדרות ,212 ,מרים הנביאה
1031 ,שדרות ,159 ,מרכז קליטה
1031 ,שדרות ,121 ,משה רבנו
1031 ,שדרות ,254 ,משעול אברהם
1031 ,שדרות ,328 ,משעול אימבר
1031 ,שדרות ,256 ,משעול איריס
1031 ,שדרות ,331 ,משעול ביל"ו
1031 ,שדרות ,242 ,משעול האלונים
1031 ,שדרות ,146 ,משעול האתרוג
1031 ,שדרות ,271 ,משעול הדס
1031 ,שדרות ,344 ,משעול הטייסים
1031 ,שדרות ,317 ,משעול הירדן
1031 ,שדרות ,200 ,משעול הל"ה
1031 ,שדרות ,348 ,משעול המחתרות
1031 ,שדרות ,131 ,משעול המייסדים
1031 ,שדרות ,277 ,משעול הנרקיס
1031 ,שדרות ,138 ,משעול הנשיא
1031 ,שדרות ,148 ,משעול הערבה
1031 ,שדרות ,319 ,משעול חובבי ציון
1031 ,שדרות ,322 ,משעול חנקין
1031 ,שדרות ,291 ,משעול יעקב
1031 ,שדרות ,292 ,משעול יצחק
1031 ,שדרות ,342 ,משעול יציאת אירופה
1031 ,שדרות ,324 ,משעול כי"ח
1031 ,שדרות ,297 ,משעול לילך
1031 ,שדרות ,347 ,משעול סטרומה
1031 ,שדרות ,339 ,משעול פועלי ציון
1031 ,שדרות ,304 ,משעול פעמונית
1031 ,שדרות ,338 ,משעול פתח תקוה
1031 ,שדרות ,316 ,משעול קק"ל
1031 ,שדרות ,306 ,משעול רבקה
1031 ,שדרות ,6500 ,נאות אביב
1031 ,שדרות ,6504 ,נאות אשכול
1031 ,שדרות ,6507 ,נאות הדקל
1031 ,שדרות ,6513 ,נאות המייסדים
1031 ,שדרות ,6502 ,נאות הנביאים
1031 ,שדרות ,6515 ,נאות הנשיא
1031 ,שדרות ,6512 ,נאות יצחק רבין
1031 ,שדרות ,469 ,נבל
1031 ,שדרות ,236 ,נוגה
1031 ,שדרות ,510 ,נופר
1031 ,שדרות ,311 ,נורית
1031 ,שדרות ,204 ,נחום
1031 ,שדרות ,431 ,נחל איילון
1031 ,שדרות ,428 ,נחל אשלים
1031 ,שדרות ,433 ,נחל דולב
1031 ,שדרות ,426 ,נחל הבשור
1031 ,שדרות ,422 ,נחל חידקל
1031 ,שדרות ,430 ,נחל לכיש
1031 ,שדרות ,434 ,נחל ניצנה
1031 ,שדרות ,421 ,נחל פרת
1031 ,שדרות ,427 ,נחל צאלים
1031 ,שדרות ,429 ,נחל שיזף
1031 ,שדרות ,432 ,נחל שקמה
1031 ,שדרות ,182 ,נחשון
1031 ,שדרות ,499 ,נטע
1031 ,שדרות ,239 ,ניל"י
1031 ,שדרות ,356 ,ניס
1031 ,שדרות ,232 ,ניצן
1031 ,שדרות ,300 ,ניצנה
1031 ,שדרות ,6501 ,ניר עם
1031 ,שדרות ,461 ,נעמי שמר
1031 ,שדרות ,217 ,נצח ישראל
1031 ,שדרות ,208 ,נתן
1031 ,שדרות ,301 ,סביון
1031 ,שדרות ,384 ,סחלב
1031 ,שדרות ,465 ,סיטאר
1031 ,שדרות ,386 ,סייפן
1031 ,שדרות ,112 ,סמ אגוז
1031 ,שדרות ,266 ,סמ דולב
1031 ,שדרות ,161 ,סמ האלון
1031 ,שדרות ,231 ,סמ הפלדה
1031 ,שדרות ,314 ,סמ השיקמה
1031 ,שדרות ,458 ,סמי אלמוגרבי
1031 ,שדרות ,464 ,ספי ריבלין
1031 ,שדרות ,394 ,סתוונית
1031 ,שדרות ,198 ,עגנון
1031 ,שדרות ,450 ,עוזי חיטמן
1031 ,שדרות ,270 ,עולי הגרדום
1031 ,שדרות ,503 ,עומר
1031 ,שדרות ,512 ,עינב
1031 ,שדרות ,326 ,פינס
1031 ,שדרות ,327 ,פינסקר
1031 ,שדרות ,483 ,פיקוס
1031 ,שדרות ,303 ,פנקס
1031 ,שדרות ,467 ,פסנתר
1031 ,שדרות ,495 ,פקאן
1031 ,שדרות ,351 ,פראג
1031 ,שדרות ,360 ,פריז
1031 ,שדרות ,476 ,צאלון
1031 ,שדרות ,382 ,צבעוני (טוליפ)
1031 ,שדרות ,387 ,ציפורן
1031 ,שדרות ,474 ,צפצפה
1031 ,שדרות ,184 ,קארו יוסף
1031 ,שדרות ,126 ,קבוץ גלויות
1031 ,שדרות ,281 ,קהילת מילנו
1031 ,שדרות ,357 ,קופנהגן
1031 ,שדרות ,492 ,קינמון
1031 ,שדרות ,124 ,קרן היסוד
1031 ,שדרות ,305 ,קרן קיימת לישראל
1031 ,שדרות ,229 ,רב בן ציון אבא שאול
1031 ,שדרות ,463 ,רבי דוד בוזגלו
1031 ,שדרות ,460 ,רבי דוד חסין
1031 ,שדרות ,462 ,רבי משה אלבז
1031 ,שדרות ,329 ,רוטשילד
1031 ,שדרות ,366 ,רומא
1031 ,שדרות ,238 ,רופין
1031 ,שדרות ,481 ,רותם
1031 ,שדרות ,178 ,רזיאל דוד
1031 ,שדרות ,307 ,רחל
1031 ,שדרות ,127 ,רמב"ם
1031 ,שדרות ,6503 ,ש זוג צעירים
1031 ,שדרות ,6505 ,ש פטיו
1031 ,שדרות ,195 ,שאר ישוב
1031 ,שדרות ,221 ,שבזי שלום
1031 ,שדרות ,237 ,שביט
1031 ,שדרות ,235 ,שד בגין מנחם
1031 ,שדרות ,9000 ,שדרות
1031 ,שדרות ,473 ,שדרות האילנות
1031 ,שדרות ,472 ,שדרות האירוסים
1031 ,שדרות ,489 ,שדרות האקליפטוס
1031 ,שדרות ,444 ,שדרות הילדים
1031 ,שדרות ,445 ,שדרות נחל הירדן
1031 ,שדרות ,385 ,שושן צחור
1031 ,שדרות ,511 ,שושנה
1031 ,שדרות ,109 ,שז"ר זלמן
1031 ,שדרות ,502 ,שיבולים
1031 ,שדרות ,6508 ,שכ בן גוריון
1031 ,שדרות ,6509 ,שכ ברנר שמחה
1031 ,שדרות ,6510 ,שכ הברוש
1031 ,שדרות ,6506 ,שכ חופית
1031 ,שדרות ,6511 ,שכ מ-3
1031 ,שדרות ,6519 ,שכ' בן גוריון ב'
1031 ,שדרות ,6520 ,שכ' הכלניות
1031 ,שדרות ,6521 ,שכ' המוסיקה
1031 ,שדרות ,6517 ,שכ' יוקרה
1031 ,שדרות ,6518 ,שכ' נאות השיקמה
1031 ,שדרות ,205 ,שמואל
1031 ,שדרות ,139 ,שמשון
1031 ,שדרות ,308 ,שניאור זלמן
1031 ,שדרות ,485 ,שניר
1031 ,שדרות ,391 ,שנית
1031 ,שדרות ,501 ,שעורה
1031 ,שדרות ,103 ,שפירא חיים
1031 ,שדרות ,491 ,שקדיה
1031 ,שדרות ,309 ,שרה
1031 ,שדרות ,196 ,שרת משה
1031 ,שדרות ,494 ,תאנה
1031 ,שדרות ,486 ,תבור
1031 ,שדרות ,475 ,תדהר
1031 ,שדרות ,466 ,תופים
1031 ,שדרות ,193 ,תל חי
1031 ,שדרות ,498 ,תפוז
741 ,שואבה ,105 ,האלון
741 ,שואבה ,103 ,הדולב
741 ,שואבה ,104 ,החורש
741 ,שואבה ,102 ,החרוב
741 ,שואבה ,100 ,המייסדים
741 ,שואבה ,7001 ,חוות שער הגיא
741 ,שואבה ,101 ,יפה נוף
741 ,שואבה ,9000 ,שואבה
761 ,שובה ,9000 ,שובה
394 ,שובל ,101 ,אולפן קיבוץ
394 ,שובל ,9000 ,שובל
1304 ,שוהם ,305 ,אורן
1304 ,שוהם ,173 ,ארז
1304 ,שוהם ,121 ,אשכול
1304 ,שוהם ,164 ,בזלת
1304 ,שוהם ,302 ,ברוש
1304 ,שוהם ,106 ,ברקת
1304 ,שוהם ,136 ,בשמת
1304 ,שוהם ,165 ,גזית
1304 ,שוהם ,181 ,גפן
1304 ,שוהם ,162 ,גרניט
1304 ,שוהם ,201 ,דולב
1304 ,שוהם ,202 ,דקל
1304 ,שוהם ,105 ,האודם
1304 ,שוהם ,200 ,האלה
1304 ,שוהם ,210 ,האתרוג
1304 ,שוהם ,126 ,הבשור
1304 ,שוהם ,191 ,הזוהר
1304 ,שוהם ,132 ,החבל
1304 ,שוהם ,104 ,החושן
1304 ,שוהם ,118 ,הירדן
1304 ,שוהם ,212 ,הלימון
1304 ,שוהם ,174 ,הלפיד
1304 ,שוהם ,166 ,המכבים
1304 ,שוהם ,161 ,הסלע
1304 ,שוהם ,113 ,הפרדס
1304 ,שוהם ,145 ,הקשת
1304 ,שוהם ,211 ,התפוז
1304 ,שוהם ,182 ,זית
1304 ,שוהם ,122 ,זמורה
1304 ,שוהם ,179 ,חיטה
1304 ,שוהם ,116 ,חפר
1304 ,שוהם ,301 ,חצב
1304 ,שוהם ,151 ,חרמון
1304 ,שוהם ,107 ,טופז
1304 ,שוהם ,108 ,יהלום
1304 ,שוהם ,117 ,יזרעאל
1304 ,שוהם ,134 ,יסמין
1304 ,שוהם ,6004 ,כיכר אגמית
1304 ,שוהם ,6005 ,כיכר אנפה
1304 ,שוהם ,6006 ,כיכר ברבור
1304 ,שוהם ,6007 ,כיכר דוכיפת
1304 ,שוהם ,6008 ,כיכר דרור
1304 ,שוהם ,6036 ,כיכר העיט
1304 ,שוהם ,6027 ,כיכר זהבן
1304 ,שוהם ,6009 ,כיכר זמיר
1304 ,שוהם ,6029 ,כיכר זרזיר
1304 ,שוהם ,6003 ,כיכר חוחית
1304 ,שוהם ,6010 ,כיכר חסידה
1304 ,שוהם ,6001 ,כיכר טסית
1304 ,שוהם ,6011 ,כיכר יונה
1304 ,שוהם ,6012 ,כיכר ינשוף
1304 ,שוהם ,6013 ,כיכר יסעור
1304 ,שוהם ,6034 ,כיכר ירקון
1304 ,שוהם ,6030 ,כיכר כנרית
1304 ,שוהם ,6031 ,כיכר מגלן
1304 ,שוהם ,6002 ,כיכר נחליאלי
1304 ,שוהם ,6026 ,כיכר נץ
1304 ,שוהם ,6028 ,כיכר סופית
1304 ,שוהם ,6016 ,כיכר סייפן
1304 ,שוהם ,6017 ,כיכר סנונית
1304 ,שוהם ,6014 ,כיכר עגור
1304 ,שוהם ,6015 ,כיכר עפרוני
1304 ,שוהם ,6018 ,כיכר פשוש
1304 ,שוהם ,6019 ,כיכר צופית
1304 ,שוהם ,6025 ,כיכר צוקית
1304 ,שוהם ,6033 ,כיכר קוקיה
1304 ,שוהם ,6020 ,כיכר שחף
1304 ,שוהם ,6021 ,כיכר שלדג
1304 ,שוהם ,6022 ,כיכר שקנאי
1304 ,שוהם ,6023 ,כיכר שרקרק
1304 ,שוהם ,6032 ,כיכר תור
1304 ,שוהם ,6024 ,כיכר תנשמת
1304 ,שוהם ,6035 ,כיכר תפוחית
1304 ,שוהם ,137 ,כרכום
1304 ,שוהם ,152 ,כרמל
1304 ,שוהם ,127 ,לכיש
1304 ,שוהם ,110 ,לשם
1304 ,שוהם ,175 ,מודיעים
1304 ,שוהם ,142 ,מעלה הגלבוע
1304 ,שוהם ,144 ,מעלה החורש
1304 ,שוהם ,141 ,מעלה העצמאות
1304 ,שוהם ,143 ,מצפה
1304 ,שוהם ,102 ,מרגלית
1304 ,שוהם ,133 ,נורית
1304 ,שוהם ,135 ,נרקיס
1304 ,שוהם ,203 ,סמ הארז
1304 ,שוהם ,213 ,סמטת האשכוליות
1304 ,שוהם ,109 ,ספיר
1304 ,שוהם ,128 ,עדולם
1304 ,שוהם ,103 ,עינבר
1304 ,שוהם ,123 ,ענב
1304 ,שוהם ,190 ,ערבה
1304 ,שוהם ,304 ,פומלית
1304 ,שוהם ,167 ,צור
1304 ,שוהם ,168 ,צורן
1304 ,שוהם ,155 ,קדם
1304 ,שוהם ,204 ,רותם
1304 ,שוהם ,187 ,רחבת בדולח
1304 ,שוהם ,169 ,רחבת גזית
1304 ,שוהם ,124 ,רחבת גת
1304 ,שוהם ,119 ,רחבת החולה
1304 ,שוהם ,160 ,רחבת חלמיש
1304 ,שוהם ,138 ,רחבת כלנית
1304 ,שוהם ,189 ,רחבת נגה
1304 ,שוהם ,188 ,רחבת נטיף
1304 ,שוהם ,111 ,רחבת עדי
1304 ,שוהם ,186 ,רחבת פנינה
1304 ,שוהם ,180 ,רימון
1304 ,שוהם ,303 ,רקפת
1304 ,שוהם ,131 ,שגב
1304 ,שוהם ,154 ,שגיא
1304 ,שוהם ,195 ,שד עמק איילון
1304 ,שוהם ,9000 ,שוהם
1304 ,שוהם ,163 ,שחם
1304 ,שוהם ,178 ,שיבולת
1304 ,שוהם ,207 ,שיזף
1304 ,שוהם ,205 ,שיטה
1304 ,שוהם ,6508 ,שכ אלונים
1304 ,שוהם ,6504 ,שכ ברושים
1304 ,שוהם ,6501 ,שכ גבעולים
1304 ,שוהם ,6509 ,שכ הדסים
1304 ,שוהם ,6505 ,שכ ורדים
1304 ,שוהם ,6502 ,שכ חמניות
1304 ,שוהם ,6500 ,שכ טללים
1304 ,שוהם ,6506 ,שכ יובלים
1304 ,שוהם ,6503 ,שכ יעלים
1304 ,שוהם ,6510 ,שכ סחלבים
1304 ,שוהם ,6507 ,שכ רקפות
1304 ,שוהם ,112 ,שני
1304 ,שוהם ,206 ,שקמה
1304 ,שוהם ,125 ,שריג
1304 ,שוהם ,176 ,תאנה
1304 ,שוהם ,184 ,תאשור
1304 ,שוהם ,153 ,תבור
1304 ,שוהם ,185 ,תדהר
1304 ,שוהם ,120 ,תירוש
1304 ,שוהם ,177 ,תמר
1304 ,שוהם ,129 ,תענך
1304 ,שוהם ,130 ,תפן
1304 ,שוהם ,101 ,תרשיש
614 ,שומרה ,9000 ,שומרה
1265 ,שומריה ,9000 ,שומריה
415 ,שוקדה ,9000 ,שוקדה
456 ,שורש ,102 ,האלה
456 ,שורש ,103 ,האלון
456 ,שורש ,104 ,הזית
456 ,שורש ,105 ,החרוב
456 ,שורש ,106 ,הלוטם
456 ,שורש ,107 ,הפסגה
456 ,שורש ,108 ,הרימון
456 ,שורש ,109 ,השיזף
456 ,שורש ,110 ,השקד
456 ,שורש ,101 ,טיילת שלום
456 ,שורש ,111 ,יפה נוף
456 ,שורש ,112 ,לבונה
456 ,שורש ,115 ,מלון שורש
456 ,שורש ,9000 ,שורש
1235 ,שורשים ,9000 ,שורשים
224 ,שושנת העמקים ,102 ,אמנון ותמר
224 ,שושנת העמקים ,103 ,האשל
224 ,שושנת העמקים ,101 ,הדקל
224 ,שושנת העמקים ,105 ,ההדר
224 ,שושנת העמקים ,104 ,החוף
224 ,שושנת העמקים ,6500 ,צוקי ים
224 ,שושנת העמקים ,9000 ,שושנת העמקים
527 ,שזור ,9000 ,שזור
7 ,שחר ,105 ,הבציר
7 ,שחר ,103 ,המייסדים
7 ,שחר ,106 ,המסיק
7 ,שחר ,107 ,הקטיף
7 ,שחר ,104 ,הראשונים
7 ,שחר ,102 ,קיבוץ גלויות
7 ,שחר ,101 ,שדרת הדקלים
7 ,שחר ,9000 ,שחר
1266 ,שחרות ,9000 ,שחרות
865 ,שיבולים ,103 ,גפן
865 ,שיבולים ,106 ,זית
865 ,שיבולים ,101 ,חיטה
865 ,שיבולים ,105 ,רימון
865 ,שיבולים ,9000 ,שיבולים
865 ,שיבולים ,102 ,שעורה
865 ,שיבולים ,104 ,תאנה
1378 ,שיזף ,7002 ,חוות אמיר
1378 ,שיזף ,7001 ,חוות מעיין הנעורים
1378 ,שיזף ,9000 ,שיזף
1267 ,שיטים ,9000 ,שיטים
658 ,שייח' דנון ,112 ,אל-ביאדה
658 ,שייח' דנון ,109 ,אל-ביאדר
658 ,שייח' דנון ,113 ,אל-ביר
658 ,שייח' דנון ,102 ,אל-הלאל
658 ,שייח' דנון ,118 ,אל-זיתון
658 ,שייח' דנון ,120 ,אל-ח'זאן
658 ,שייח' דנון ,106 ,אל-ח'רבה
658 ,שייח' דנון ,117 ,אל-מטלה
658 ,שייח' דנון ,107 ,אל-מידאן
658 ,שייח' דנון ,111 ,אל-מסג'ד
658 ,שייח' דנון ,105 ,אל-מסג'ד אלג'דיד
658 ,שייח' דנון ,108 ,אל-מעאסר
658 ,שייח' דנון ,121 ,אל-נור
658 ,שייח' דנון ,115 ,אל-סלאלם
658 ,שייח' דנון ,101 ,אל-סלאם
658 ,שייח' דנון ,114 ,אל-רבאט
658 ,שייח' דנון ,119 ,אל-רודאת
658 ,שייח' דנון ,110 ,אל-רחמה
658 ,שייח' דנון ,116 ,אל-ריאדה
658 ,שייח' דנון ,104 ,אל-תין
658 ,שייח' דנון ,103 ,שייח דאוד
658 ,שייח' דנון ,9000 ,שייח' דנון
3641 ,שילה ,156 ,אבני החושן
3641 ,שילה ,140 ,אוהל מועד
3641 ,שילה ,151 ,אורים ותומים
3641 ,שילה ,163 ,אחיה
3641 ,שילה ,150 ,אלקנה
3641 ,שילה ,146 ,ארגמן
3641 ,שילה ,154 ,ארון הברית
3641 ,שילה ,104 ,ארץ חפץ
3641 ,שילה ,126 ,אש הקודש
3641 ,שילה ,121 ,ברכת האילנות
3641 ,שילה ,111 ,גאולים
3641 ,שילה ,127 ,גאולת הארץ
3641 ,שילה ,120 ,גורל הנחלות
3641 ,שילה ,105 ,האגוז
3641 ,שילה ,162 ,האפוד
3641 ,שילה ,106 ,הארז
3641 ,שילה ,124 ,הביכורים
3641 ,שילה ,109 ,הגפן
3641 ,שילה ,107 ,ההדס
3641 ,שילה ,110 ,הזית
3641 ,שילה ,103 ,החבצלת
3641 ,שילה ,113 ,הכרם
3641 ,שילה ,139 ,המסילה העולה
3641 ,שילה ,149 ,המעמר
3641 ,שילה ,144 ,הפרוכת
3641 ,שילה ,131 ,הקטורת
3641 ,שילה ,160 ,הר אפרים
3641 ,שילה ,164 ,הרואה
3641 ,שילה ,102 ,הרימון
3641 ,שילה ,142 ,השני
3641 ,שילה ,108 ,התאנה
3641 ,שילה ,143 ,התכלת
3641 ,שילה ,112 ,התפוח
3641 ,שילה ,135 ,טו באב
3641 ,שילה ,161 ,י באייר
3641 ,שילה ,132 ,מחול הכרם
3641 ,שילה ,148 ,מלאכות המשכן
3641 ,שילה ,155 ,מעלה הארון
3641 ,שילה ,153 ,מעלה הברית
3641 ,שילה ,157 ,מעלה החושן
3641 ,שילה ,133 ,מעלה הכרם
3641 ,שילה ,134 ,מעלה המחול
3641 ,שילה ,137 ,מעלה העדות
3641 ,שילה ,145 ,מעלה הקטורת
3641 ,שילה ,141 ,מעלה השני
3641 ,שילה ,130 ,מקורות
3641 ,שילה ,138 ,משכן העדות
3641 ,שילה ,152 ,משעול ההר
3641 ,שילה ,136 ,משעול החינוך
3641 ,שילה ,101 ,נחלה
3641 ,שילה ,147 ,עד כי יבוא שילה
3641 ,שילה ,123 ,עדי עד
3641 ,שילה ,122 ,עולי הרגל
3641 ,שילה ,159 ,עצי שיטים
3641 ,שילה ,125 ,קידה
3641 ,שילה ,7001 ,שבות רחל
3641 ,שילה ,9000 ,שילה
3641 ,שילה ,6502 ,שכ הנחלות
3641 ,שילה ,6500 ,שכ עלי הכהן
3641 ,שילה ,6501 ,שכ רמת שמואל
3641 ,שילה ,158 ,תפילת חנה
1165 ,שילת ,115 ,אירוס
1165 ,שילת ,101 ,אלה
1165 ,שילת ,102 ,אלון
1165 ,שילת ,103 ,ברוש
1165 ,שילת ,104 ,גפן
1165 ,שילת ,105 ,דרך האורנים
1165 ,שילת ,106 ,דרך הזית
1165 ,שילת ,130 ,דרך החממות
1165 ,שילת ,107 ,דרך החרוב
1165 ,שילת ,120 ,דרך החשמונאים
1165 ,שילת ,125 ,דרך המכבים
1165 ,שילת ,116 ,ורד
1165 ,שילת ,113 ,חצב
1165 ,שילת ,117 ,חרצית
1165 ,שילת ,114 ,יסמין
1165 ,שילת ,119 ,נורית
1165 ,שילת ,108 ,סמ הערבה
1165 ,שילת ,6500 ,פארק תעשיות ומסחר
1165 ,שילת ,109 ,רותם
1165 ,שילת ,110 ,רימון
1165 ,שילת ,118 ,רקפת
1165 ,שילת ,9000 ,שילת
1165 ,שילת ,111 ,שקד
1165 ,שילת ,112 ,תאנה
1160 ,שכניה ,9000 ,שכניה
873 ,שלווה ,9000 ,שלווה
1373 ,שלווה במדבר ,9000 ,שלווה במדבר
439 ,שלוחות ,9000 ,שלוחות
812 ,שלומי ,6509 ,אזור התעשייה
812 ,שלומי ,108 ,אחיהוד בן שלומי
812 ,שלומי ,198 ,איבי הנחל
812 ,שלומי ,105 ,אלבז נתן
812 ,שלומי ,145 ,אנפה
812 ,שלומי ,6508 ,גבעת הארזים
812 ,שלומי ,140 ,דוכיפת
812 ,שלומי ,146 ,דרור
812 ,שלומי ,110 ,דרך אזולאי סנדי
812 ,שלומי ,137 ,דרך הארזים
812 ,שלומי ,135 ,האלה
812 ,שלומי ,136 ,האלון
812 ,שלומי ,165 ,הבריאה
812 ,שלומי ,184 ,ההבשלה
812 ,שלומי ,183 ,ההמצאה
812 ,שלומי ,186 ,ההשראה
812 ,שלומי ,170 ,ההתגשמות
812 ,שלומי ,181 ,הזריעה
812 ,שלומי ,187 ,החורש
812 ,שלומי ,160 ,החורשה
812 ,שלומי ,130 ,הטיילת האנגלית
812 ,שלומי ,196 ,היער הקסום
812 ,שלומי ,166 ,היצירה
812 ,שלומי ,164 ,הכריה
812 ,שלומי ,161 ,המייסדים
812 ,שלומי ,185 ,הפריחה
812 ,שלומי ,162 ,הצמיחה
812 ,שלומי ,182 ,הקציר
812 ,שלומי ,107 ,הרב חזן מסעוד
812 ,שלומי ,103 ,הרב מימון
812 ,שלומי ,119 ,הרב עובדיה יוסף
812 ,שלומי ,104 ,הרב עוזיאל
812 ,שלומי ,169 ,התנופה
812 ,שלומי ,106 ,ז'בוטינסקי
812 ,שלומי ,163 ,זיכרון משה
812 ,שלומי ,141 ,זמיר
812 ,שלומי ,134 ,חוף הים
812 ,שלומי ,191 ,חורשת האקליפטוס
812 ,שלומי ,197 ,חלוקי הנחל
812 ,שלומי ,155 ,יסמין
812 ,שלומי ,138 ,יער חניתה
812 ,שלומי ,121 ,יפה נוף
812 ,שלומי ,147 ,יצחק רבין
812 ,שלומי ,175 ,כחל
812 ,שלומי ,153 ,לילך
812 ,שלומי ,6501 ,מעברת שלומי
812 ,שלומי ,113 ,מעלה שטרן יאיר
812 ,שלומי ,109 ,מרכז קליטה
812 ,שלומי ,6506 ,נווה רבין
812 ,שלומי ,159 ,נוף הרים
812 ,שלומי ,151 ,נורית
812 ,שלומי ,150 ,ניצן
812 ,שלומי ,157 ,נרקיס
812 ,שלומי ,154 ,סחלב
812 ,שלומי ,177 ,סמטת העייט
812 ,שלומי ,199 ,סמטת נחל ייטב
812 ,שלומי ,195 ,סמטת נחל נטפים
812 ,שלומי ,144 ,סנונית
812 ,שלומי ,142 ,עפרוני
812 ,שלומי ,193 ,עץ המשאלות
812 ,שלומי ,192 ,עץ השדה
812 ,שלומי ,194 ,ערבי הנחל
812 ,שלומי ,6510 ,פנינת הצפון
812 ,שלומי ,126 ,קהילת בריטניה
812 ,שלומי ,125 ,קהילת שוויץ
812 ,שלומי ,171 ,רח מס' 22
812 ,שלומי ,172 ,רח מס' 34
812 ,שלומי ,173 ,רח מס' 35
812 ,שלומי ,174 ,רח מס' 36
812 ,שלומי ,168 ,רח מס' 38
812 ,שלומי ,156 ,רקפת
812 ,שלומי ,118 ,שביל האודם
812 ,שלומי ,115 ,שביל הברקת
812 ,שלומי ,117 ,שביל היהלום
812 ,שלומי ,116 ,שביל הלשם
812 ,שלומי ,114 ,שביל הספיר
812 ,שלומי ,112 ,שד בן גוריון
812 ,שלומי ,190 ,שדרות יערית
812 ,שלומי ,152 ,שושן
812 ,שלומי ,143 ,שחף
812 ,שלומי ,6502 ,שכ בן גוריון
812 ,שלומי ,6503 ,שכ בנה ביתך
812 ,שלומי ,6504 ,שכ דדו
812 ,שלומי ,6507 ,שכ מורד ההר
812 ,שלומי ,6505 ,שכ שלומית
812 ,שלומי ,6511 ,שכונת יערית
812 ,שלומי ,9000 ,שלומי
812 ,שלומי ,102 ,ששת הימים
812 ,שלומי ,176 ,תור
1364 ,שלומית ,9000 ,שלומית
366 ,שמיר ,101 ,בית ראשון במולדת
366 ,שמיר ,9000 ,שמיר
3784 ,שמעה ,9000 ,שמעה
432 ,שמרת ,108 ,אורים
432 ,שמרת ,109 ,אורן
432 ,שמרת ,113 ,אלה
432 ,שמרת ,112 ,אלמוג
432 ,שמרת ,103 ,ארז
432 ,שמרת ,116 ,דקל
432 ,שמרת ,102 ,דרור
432 ,שמרת ,105 ,הדר
432 ,שמרת ,110 ,חצב
432 ,שמרת ,101 ,יחדיו
432 ,שמרת ,117 ,ניצנים
432 ,שמרת ,107 ,עומר
432 ,שמרת ,111 ,רימון
432 ,שמרת ,115 ,שחף
432 ,שמרת ,106 ,שיבולים
432 ,שמרת ,104 ,שלהבת
432 ,שמרת ,9000 ,שמרת
432 ,שמרת ,114 ,שקד
1337 ,שמשית ,112 ,אודם
1337 ,שמשית ,111 ,אלמוג
1337 ,שמשית ,102 ,ארגמן
1337 ,שמשית ,121 ,ברקת
1337 ,שמשית ,110 ,גביש
1337 ,שמשית ,120 ,גזית
1337 ,שמשית ,108 ,גרניט
1337 ,שמשית ,123 ,האשד
1337 ,שמשית ,104 ,חושן
1337 ,שמשית ,107 ,יובלים
1337 ,שמשית ,118 ,לשם
1337 ,שמשית ,116 ,מעיינות
1337 ,שמשית ,114 ,מפלים
1337 ,שמשית ,105 ,מרגלית
1337 ,שמשית ,113 ,נחלים
1337 ,שמשית ,106 ,ספיר
1337 ,שמשית ,119 ,ענבר
1337 ,שמשית ,109 ,פלג
1337 ,שמשית ,115 ,צורן
1337 ,שמשית ,117 ,שוהם
1337 ,שמשית ,101 ,שחם
1337 ,שמשית ,9000 ,שמשית
1337 ,שמשית ,103 ,שני
1337 ,שמשית ,122 ,תכלת
1287 ,שני ,9000 ,שני
1132 ,שניר ,101 ,בי"ס שדה חרמון
1132 ,שניר ,103 ,דרך הבניאס
1132 ,שניר ,108 ,דרך הגולן
1132 ,שניר ,112 ,דרך הדולב
1132 ,שניר ,111 ,דרך הדן
1132 ,שניר ,109 ,דרך החצבני
1132 ,שניר ,102 ,דרך החרמון
1132 ,שניר ,105 ,דרך הירדן
1132 ,שניר ,110 ,דרך המעיין
1132 ,שניר ,104 ,דרך המפל
1132 ,שניר ,107 ,דרך הסער
1132 ,שניר ,106 ,דרך השיאון
1132 ,שניר ,9000 ,שניר
538 ,שעב ,101 ,א-דוואר
538 ,שעב ,150 ,א-דיסה
538 ,שעב ,106 ,א-זיתונה
538 ,שעב ,111 ,א-זנבקה
538 ,שעב ,138 ,א-זעתר
538 ,שעב ,130 ,א-נאפורה
538 ,שעב ,139 ,א-נבע
538 ,שעב ,125 ,א-סוק
538 ,שעב ,121 ,א-סינדיאנה
538 ,שעב ,149 ,א-סלאם
538 ,שעב ,110 ,א-ראזי
538 ,שעב ,141 ,א-רביע
538 ,שעב ,145 ,א-שאע'ור
538 ,שעב ,116 ,א-שאפיעי
538 ,שעב ,135 ,אבו בקר א-ס'דיק
538 ,שעב ,118 ,אבו חניפה
538 ,שעב ,117 ,אחמד איבן חנבל
538 ,שעב ,107 ,איבן ח'לדון
538 ,שעב ,108 ,איבן כת'יר
538 ,שעב ,113 ,איבן סינא
538 ,שעב ,151 ,איעביב
538 ,שעב ,120 ,אל-בוח'ארי
538 ,שעב ,126 ,אל-ביאדר
538 ,שעב ,127 ,אל-ג'אמע
538 ,שעב ,100 ,אל-ג'בל
538 ,שעב ,114 ,אל-ג'ליל
538 ,שעב ,136 ,אל-ואד
538 ,שעב ,109 ,אל-וורוד
538 ,שעב ,124 ,אל-ח'ואריזמי
538 ,שעב ,103 ,אל-ח'זאן
538 ,שעב ,144 ,אל-חילזון
538 ,שעב ,146 ,אל-חנני
538 ,שעב ,132 ,אל-כורום
538 ,שעב ,133 ,אל-כניסה
538 ,שעב ,137 ,אל-כראמה
538 ,שעב ,105 ,אל-מותנבי
538 ,שעב ,148 ,אל-מחג'ר
538 ,שעב ,123 ,אל-מעס'רה
538 ,שעב ,115 ,אל-עין
538 ,שעב ,140 ,אל-פרדוס
538 ,שעב ,134 ,בלקיס
538 ,שעב ,142 ,ג'בל א-זוכאן
538 ,שעב ,102 ,ג'בל א-שיח'
538 ,שעב ,122 ,ח'אלד איבן אל-וליד
538 ,שעב ,147 ,ח'לת אל-עואבנה
538 ,שעב ,104 ,חיפא
538 ,שעב ,143 ,יענין
538 ,שעב ,119 ,מאלכ איבן אנס
538 ,שעב ,152 ,מראח אל-ע'וזלאן
538 ,שעב ,128 ,עומר אל-מוח'תאר
538 ,שעב ,9000 ,שעב
538 ,שעב ,154 ,תאופיק זיאד
4009 ,שעל ,9000 ,שעל
856 ,שעלבים ,9000 ,שעלבים
661 ,שער אפרים ,101 ,אודם
661 ,שער אפרים ,102 ,אלמוג
661 ,שער אפרים ,103 ,ברקת
661 ,שער אפרים ,104 ,גריזים
661 ,שער אפרים ,105 ,הארבל
661 ,שער אפרים ,106 ,הארז
661 ,שער אפרים ,107 ,הגלבוע
661 ,שער אפרים ,108 ,הגלעד
661 ,שער אפרים ,109 ,הגפן
661 ,שער אפרים ,110 ,ההדס
661 ,שער אפרים ,111 ,ההרים
661 ,שער אפרים ,112 ,הזמורה
661 ,שער אפרים ,113 ,החרמון
661 ,שער אפרים ,114 ,הלשם
661 ,שער אפרים ,115 ,הנופר
661 ,שער אפרים ,116 ,הסלעים
661 ,שער אפרים ,117 ,הספיר
661 ,שער אפרים ,118 ,הרימון
661 ,שער אפרים ,119 ,התאנה
661 ,שער אפרים ,120 ,התבור
661 ,שער אפרים ,121 ,התמר
661 ,שער אפרים ,122 ,טופז
661 ,שער אפרים ,123 ,יהלום
661 ,שער אפרים ,124 ,נופך
661 ,שער אפרים ,125 ,עצמון
661 ,שער אפרים ,126 ,שגיא
661 ,שער אפרים ,127 ,שד הזיתים
661 ,שער אפרים ,128 ,שוהם
661 ,שער אפרים ,129 ,שנהב
661 ,שער אפרים ,130 ,שניר
661 ,שער אפרים ,9000 ,שער אפרים
264 ,שער הגולן ,101 ,בית ראשון במולדת
264 ,שער הגולן ,9000 ,שער הגולן
237 ,שער העמקים ,9000 ,שער העמקים
921 ,שער מנשה ,9000 ,שער מנשה
3826 ,שער שומרון ,124 ,אביבים
3826 ,שער שומרון ,107 ,אבנט
3826 ,שער שומרון ,104 ,אודם
3826 ,שער שומרון ,105 ,אלמוג
3826 ,שער שומרון ,113 ,אפוד
3826 ,שער שומרון ,117 ,בזלת
3826 ,שער שומרון ,121 ,ביריה
3826 ,שער שומרון ,103 ,ברקת
3826 ,שער שומרון ,114 ,גרניט
3826 ,שער שומרון ,128 ,דגניה
3826 ,שער שומרון ,127 ,דן
3826 ,שער שומרון ,123 ,דפנה שערי תקווה
3826 ,שער שומרון ,3101 ,האורן עץ אפרים
3826 ,שער שומרון ,135 ,האורן שערי תקווה
3826 ,שער שומרון ,139 ,האיילים
3826 ,שער שומרון ,134 ,האירוס
3826 ,שער שומרון ,3122 ,האלה
3826 ,שער שומרון ,3103 ,האלון
3826 ,שער שומרון ,3105 ,הארז
3826 ,שער שומרון ,3106 ,האשל
3826 ,שער שומרון ,3111 ,הברוש
3826 ,שער שומרון ,3121 ,הדולב
3826 ,שער שומרון ,3124 ,הדפנה עץ אפרים
3826 ,שער שומרון ,3110 ,הדקל
3826 ,שער שומרון ,3113 ,הזית עץ אפרים
3826 ,שער שומרון ,130 ,הזית שערי תקווה
3826 ,שער שומרון ,138 ,החצב
3826 ,שער שומרון ,3102 ,החרוב
3826 ,שער שומרון ,129 ,הירקון
3826 ,שער שומרון ,132 ,הכלנית
3826 ,שער שומרון ,3123 ,הליבנה
3826 ,שער שומרון ,141 ,העופרים
3826 ,שער שומרון ,3112 ,הערבה
3826 ,שער שומרון ,140 ,הצבאים
3826 ,שער שומרון ,133 ,הצבעוני
3826 ,שער שומרון ,3104 ,הרימון
3826 ,שער שומרון ,137 ,הרקפת
3826 ,שער שומרון ,120 ,הררית
3826 ,שער שומרון ,3109 ,השיטה
3826 ,שער שומרון ,136 ,השקד שערי תקווה
3826 ,שער שומרון ,3120 ,השקמה
3826 ,שער שומרון ,3107 ,התאנה עץ אפרים
3826 ,שער שומרון ,131 ,התאנה שערי תקווה
3826 ,שער שומרון ,3108 ,התמר
3826 ,שער שומרון ,106 ,חושן
3826 ,שער שומרון ,102 ,חלמיש
3826 ,שער שומרון ,101 ,חרסית
3826 ,שער שומרון ,108 ,יהלום
3826 ,שער שומרון ,126 ,כנרת
3826 ,שער שומרון ,125 ,נגבה
3826 ,שער שומרון ,115 ,סלע
3826 ,שער שומרון ,3126 ,סמטת המורן
3826 ,שער שומרון ,3114 ,סמטת השקד עץ אפרים
3826 ,שער שומרון ,3125 ,סמטת התות
3826 ,שער שומרון ,109 ,ספיר
3826 ,שער שומרון ,116 ,ענבר
3826 ,שער שומרון ,6501 ,עץ אפרים
3826 ,שער שומרון ,118 ,צוק
3826 ,שער שומרון ,112 ,שהם
3826 ,שער שומרון ,9000 ,שער שומרון
3826 ,שער שומרון ,6502 ,שערי תקווה
3826 ,שער שומרון ,122 ,תל חי
3826 ,שער שומרון ,111 ,תרשיש
232 ,שפיים ,7001 ,שמורת השרון
232 ,שפיים ,9000 ,שפיים
692 ,שפיר ,102 ,האסיף
692 ,שפיר ,103 ,הבציר
692 ,שפיר ,104 ,הגדיד
692 ,שפיר ,105 ,הגורן
692 ,שפיר ,106 ,הזורע
692 ,שפיר ,107 ,החורש
692 ,שפיר ,101 ,המייסדים
692 ,שפיר ,108 ,הנוטע
692 ,שפיר ,9000 ,שפיר
846 ,שפר ,7001 ,חמדת ימים
846 ,שפר ,9000 ,שפר
8800 ,שפרעם ,483 ,א-דאמון
8800 ,שפרעם ,171 ,א-דואלי
8800 ,שפרעם ,484 ,א-דוארה
8800 ,שפרעם ,485 ,א-דוראת
8800 ,שפרעם ,486 ,א-דורי
8800 ,שפרעם ,487 ,א-דיר
8800 ,שפרעם ,488 ,א-דמידה
8800 ,שפרעם ,273 ,א-דמימי
8800 ,שפרעם ,489 ,א-דעקה
8800 ,שפרעם ,490 ,א-דראג'
8800 ,שפרעם ,491 ,א-דרבאשי
8800 ,שפרעם ,492 ,א-דרדאר
8800 ,שפרעם ,496 ,א-זאהיריה
8800 ,שפרעם ,292 ,א-זאהר
8800 ,שפרעם ,497 ,א-זאוייה
8800 ,שפרעם ,498 ,א-זהרה
8800 ,שפרעם ,499 ,א-זוודאת
8800 ,שפרעם ,501 ,א-זיזפון
8800 ,שפרעם ,500 ,א-זייאן
8800 ,שפרעם ,503 ,א-זיין
8800 ,שפרעם ,502 ,א-זייתון
8800 ,שפרעם ,504 ,א-זנבק
8800 ,שפרעם ,730 ,א-זעפראן
8800 ,שפרעם ,168 ,א-זערור
8800 ,שפרעם ,505 ,א-זעתר
8800 ,שפרעם ,506 ,א-זקאק
8800 ,שפרעם ,180 ,א-זרזור
8800 ,שפרעם ,339 ,א-זרעייה
8800 ,שפרעם ,507 ,א-זרקא
8800 ,שפרעם ,356 ,א-טאבר'ה
8800 ,שפרעם ,256 ,א-טאחונה
8800 ,שפרעם ,528 ,א-טבק
8800 ,שפרעם ,343 ,א-טברי
8800 ,שפרעם ,218 ,א-טוואל
8800 ,שפרעם ,529 ,א-טיאר
8800 ,שפרעם ,270 ,א-טיור
8800 ,שפרעם ,283 ,א-טירה
8800 ,שפרעם ,540 ,א-לאתין
8800 ,שפרעם ,149 ,א-לד
8800 ,שפרעם ,541 ,א-לוז
8800 ,שפרעם ,473 ,א-לור'ד
8800 ,שפרעם ,190 ,א-ליימון
8800 ,שפרעם ,160 ,א-נבי סאלח
8800 ,שפרעם ,573 ,א-נבעה
8800 ,שפרעם ,135 ,א-נג'אח
8800 ,שפרעם ,574 ,א-נג'אר
8800 ,שפרעם ,179 ,א-נוורס
8800 ,שפרעם ,208 ,א-נימר
8800 ,שפרעם ,254 ,א-נירוז
8800 ,שפרעם ,204 ,א-נמוז
8800 ,שפרעם ,235 ,א-נסר
8800 ,שפרעם ,576 ,א-נעום
8800 ,שפרעם ,188 ,א-נענע
8800 ,שפרעם ,577 ,א-נפאפעה
8800 ,שפרעם ,578 ,א-נרג'ס
8800 ,שפרעם ,580 ,א-סאפח
8800 ,שפרעם ,581 ,א-סביל
8800 ,שפרעם ,932 ,א-סבר
8800 ,שפרעם ,583 ,א-סהל
8800 ,שפרעם ,584 ,א-סובאט
8800 ,שפרעם ,172 ,א-סוכר
8800 ,שפרעם ,286 ,א-סומאכ
8800 ,שפרעם ,585 ,א-סוק
8800 ,שפרעם ,586 ,א-סוקור
8800 ,שפרעם ,230 ,א-סורה
8800 ,שפרעם ,352 ,א-סחאלי
8800 ,שפרעם ,587 ,א-סלבאק
8800 ,שפרעם ,589 ,א-סמארה
8800 ,שפרעם ,590 ,א-סנדייאנה
8800 ,שפרעם ,477 ,א-ר'זאלי
8800 ,שפרעם ,625 ,א-ראהבאת
8800 ,שפרעם ,627 ,א-ראשד
8800 ,שפרעם ,629 ,א-רהו
8800 ,שפרעם ,630 ,א-רואפיד
8800 ,שפרעם ,328 ,א-רוג'ום
8800 ,שפרעם ,631 ,א-רוודה
8800 ,שפרעם ,635 ,א-רומאן
8800 ,שפרעם ,632 ,א-רחבאני
8800 ,שפרעם ,634 ,א-ריחאנייה
8800 ,שפרעם ,633 ,א-רייאן
8800 ,שפרעם ,884 ,א-רייחאן
8800 ,שפרעם ,142 ,א-ריינה
8800 ,שפרעם ,183 ,א-רשיד
8800 ,שפרעם ,636 ,א-שאר'ור
8800 ,שפרעם ,894 ,א-שיח' אבו עלי נסר
8800 ,שפרעם ,893 ,א-שייח' אמין טריף
8800 ,שפרעם ,648 ,א-שימאל
8800 ,שפרעם ,917 ,א-שעאע
8800 ,שפרעם ,706 ,א-ת'אמין מין אדאר
8800 ,שפרעם ,934 ,א-תומור
8800 ,שפרעם ,974 ,א-תמור
8800 ,שפרעם ,961 ,א-תמר אל-הינדי
8800 ,שפרעם ,169 ,אבו א-סראיא
8800 ,שפרעם ,423 ,אבו אל-נג'ק
8800 ,שפרעם ,226 ,אבו אל-קמח
8800 ,שפרעם ,422 ,אבו ד'ר אל-ר'פארי
8800 ,שפרעם ,349 ,אבו חרב
8800 ,שפרעם ,126 ,אבו מסנסל
8800 ,שפרעם ,266 ,אבו סברי חמדי
8800 ,שפרעם ,154 ,אבו סלמא
8800 ,שפרעם ,424 ,אבו סמרה
8800 ,שפרעם ,330 ,אבו סנאן
8800 ,שפרעם ,312 ,אבו עפן
8800 ,שפרעם ,117 ,אבו שהאב
8800 ,שפרעם ,426 ,אבו שושי
8800 ,שפרעם ,427 ,אבו תמאם
8800 ,שפרעם ,206 ,אגזם
8800 ,שפרעם ,203 ,אדוארד סעיד
8800 ,שפרעם ,704 ,אוול אייאר
8800 ,שפרעם ,431 ,אום א-דרג'
8800 ,שפרעם ,432 ,אום א-זינאת
8800 ,שפרעם ,122 ,אום א-תות
8800 ,שפרעם ,914 ,אום אל-פחם
8800 ,שפרעם ,278 ,אום אלחממיס
8800 ,שפרעם ,433 ,אום אלסחאלי
8800 ,שפרעם ,133 ,אום אלפרג
8800 ,שפרעם ,434 ,אום כולת'ום
8800 ,שפרעם ,276 ,אום רומאנה
8800 ,שפרעם ,436 ,אום רושרוש
8800 ,שפרעם ,115 ,אזור
8800 ,שפרעם ,6810 ,אזור התעשיה
8800 ,שפרעם ,437 ,אחמד בן בלא
8800 ,שפרעם ,438 ,אחמד שאוקי
8800 ,שפרעם ,272 ,איבטין
8800 ,שפרעם ,429 ,איבן א-רושד
8800 ,שפרעם ,428 ,איבן אל-עמיד
8800 ,שפרעם ,430 ,איבן ח'לדון
8800 ,שפרעם ,322 ,איבן סינא
8800 ,שפרעם ,210 ,איברהים נימר חוסין
8800 ,שפרעם ,245 ,אילא הדאסה
8800 ,שפרעם ,439 ,איליא אבו מאדי
8800 ,שפרעם ,661 ,אירביד
8800 ,שפרעם ,440 ,אל-אבל
8800 ,שפרעם ,442 ,אל-אג'אס
8800 ,שפרעם ,223 ,אל-אדיב
8800 ,שפרעם ,443 ,אל-אום תריזא
8800 ,שפרעם ,444 ,אל-אח'טל
8800 ,שפרעם ,445 ,אל-אחסאן
8800 ,שפרעם ,446 ,אל-איסכנדרייה
8800 ,שפרעם ,177 ,אל-אמאני
8800 ,שפרעם ,308 ,אל-אסאייל
8800 ,שפרעם ,158 ,אל-אפר'אני
8800 ,שפרעם ,441 ,אל-אר'אני
8800 ,שפרעם ,447 ,אל-ארז
8800 ,שפרעם ,449 ,אל-באב אל-ר'רבי
8800 ,שפרעם ,450 ,אל-באבור
8800 ,שפרעם ,983 ,אל-באדייה
8800 ,שפרעם ,451 ,אל-באז
8800 ,שפרעם ,452 ,אל-בארוכ
8800 ,שפרעם ,260 ,אל-בוח'ארי
8800 ,שפרעם ,156 ,אל-בוראק
8800 ,שפרעם ,354 ,אל-בוריני
8800 ,שפרעם ,921 ,אל-בורתוקאל
8800 ,שפרעם ,453 ,אל-בחר
8800 ,שפרעם ,454 ,אל-בטריק
8800 ,שפרעם ,455 ,אל-ביטריה
8800 ,שפרעם ,196 ,אל-ביידר
8800 ,שפרעם ,456 ,אל-ביירוני
8800 ,שפרעם ,457 ,אל-בירה
8800 ,שפרעם ,458 ,אל-בלאבל
8800 ,שפרעם ,207 ,אל-בלאט
8800 ,שפרעם ,459 ,אל-בלאן
8800 ,שפרעם ,460 ,אל-בלוט
8800 ,שפרעם ,249 ,אל-בלח
8800 ,שפרעם ,217 ,אל-בס
8800 ,שפרעם ,461 ,אל-בסה
8800 ,שפרעם ,175 ,אל-בקארה
8800 ,שפרעם ,462 ,אל-בראדיש
8800 ,שפרעם ,464 ,אל-ברי
8800 ,שפרעם ,238 ,אל-ברר'ותי
8800 ,שפרעם ,468 ,אל-ג'אחז
8800 ,שפרעם ,469 ,אל-ג'אמע
8800 ,שפרעם ,476 ,אל-ג'ורן
8800 ,שפרעם ,478 ,אל-ג'זר
8800 ,שפרעם ,686 ,אל-ג'יש
8800 ,שפרעם ,303 ,אל-ג'ליל
8800 ,שפרעם ,481 ,אל-ג'רוס
8800 ,שפרעם ,482 ,אל-ג'רמק
8800 ,שפרעם ,316 ,אל-האשמי
8800 ,שפרעם ,493 ,אל-הודהוד
8800 ,שפרעם ,494 ,אל-ואדי
8800 ,שפרעם ,495 ,אל-וז
8800 ,שפרעם ,250 ,אל-וליד
8800 ,שפרעם ,513 ,אל-ח'ואריזמי
8800 ,שפרעם ,184 ,אל-ח'ולפא
8800 ,שפרעם ,516 ,אל-ח'וריה
8800 ,שפרעם ,209 ,אל-ח'טיב
8800 ,שפרעם ,144 ,אל-ח'לאייל
8800 ,שפרעם ,519 ,אל-ח'ליל
8800 ,שפרעם ,524 ,אל-ח'מסה
8800 ,שפרעם ,525 ,אל-ח'נסאא
8800 ,שפרעם ,527 ,אל-ח'רוב
8800 ,שפרעם ,508 ,אל-חאג'
8800 ,שפרעם ,509 ,אל-חבק
8800 ,שפרעם ,174 ,אל-חג'אג'
8800 ,שפרעם ,191 ,אל-חג'וג'
8800 ,שפרעם ,103 ,אל-חג'יראת
8800 ,שפרעם ,511 ,אל-חג'ל
8800 ,שפרעם ,512 ,אל-חדאד
8800 ,שפרעם ,514 ,אל-חולה
8800 ,שפרעם ,173 ,אל-חוסרי
8800 ,שפרעם ,517 ,אל-חורש
8800 ,שפרעם ,510 ,אל-חיג'אז
8800 ,שפרעם ,518 ,אל-חיזב
8800 ,שפרעם ,520 ,אל-חילף
8800 ,שפרעם ,143 ,אל-חלאג'
8800 ,שפרעם ,521 ,אל-חמאם
8800 ,שפרעם ,522 ,אל-חמדאני
8800 ,שפרעם ,523 ,אל-חמיד
8800 ,שפרעם ,526 ,אל-חסבה
8800 ,שפרעם ,166 ,אל-חסון
8800 ,שפרעם ,219 ,אל-חסן
8800 ,שפרעם ,515 ,אל-חסרום
8800 ,שפרעם ,530 ,אל-יאסמין
8800 ,שפרעם ,291 ,אל-ימאמה
8800 ,שפרעם ,261 ,אל-ימן
8800 ,שפרעם ,139 ,אל-ירמוכ
8800 ,שפרעם ,531 ,אל-כאברי
8800 ,שפרעם ,532 ,אל-כברה
8800 ,שפרעם ,942 ,אל-כוות'ר
8800 ,שפרעם ,257 ,אל-כוסברה
8800 ,שפרעם ,619 ,אל-כמאני
8800 ,שפרעם ,533 ,אל-כמון
8800 ,שפרעם ,534 ,אל-כנאר
8800 ,שפרעם ,621 ,אל-כנדי
8800 ,שפרעם ,622 ,אל-כסאייר
8800 ,שפרעם ,101 ,אל-כסאראת
8800 ,שפרעם ,535 ,אל-כסתנא
8800 ,שפרעם ,536 ,אל-כעביה
8800 ,שפרעם ,248 ,אל-כראג'
8800 ,שפרעם ,876 ,אל-כראוויה
8800 ,שפרעם ,537 ,אל-כרוואן
8800 ,שפרעם ,623 ,אל-כרז
8800 ,שפרעם ,976 ,אל-כרכ
8800 ,שפרעם ,167 ,אל-כרכס
8800 ,שפרעם ,298 ,אל-כרמל
8800 ,שפרעם ,539 ,אל-כתאב
8800 ,שפרעם ,315 ,אל-לג'ון
8800 ,שפרעם ,138 ,אל-מבארקה
8800 ,שפרעם ,544 ,אל-מדאחל
8800 ,שפרעם ,924 ,אל-מדח'ל א-שרקי
8800 ,שפרעם ,923 ,אל-מדח'ל אל-ג'נובי
8800 ,שפרעם ,185 ,אל-מדרסה
8800 ,שפרעם ,545 ,אל-מהנא
8800 ,שפרעם ,547 ,אל-מוח'תאר
8800 ,שפרעם ,546 ,אל-מוטראן חג'אר
8800 ,שפרעם ,294 ,אל-מונדיה
8800 ,שפרעם ,548 ,אל-מועלם בולוס
8800 ,שפרעם ,566 ,אל-מוקדסי
8800 ,שפרעם ,549 ,אל-מותנבי
8800 ,שפרעם ,550 ,אל-מזאר
8800 ,שפרעם ,551 ,אל-מזרעה
8800 ,שפרעם ,552 ,אל-מחאדד
8800 ,שפרעם ,553 ,אל-מחבה
8800 ,שפרעם ,554 ,אל-מחפרה
8800 ,שפרעם ,555 ,אל-מטר
8800 ,שפרעם ,556 ,אל-מלעב א-רייאדי
8800 ,שפרעם ,557 ,אל-מנאג'ר
8800 ,שפרעם ,558 ,אל-מנארה
8800 ,שפרעם ,559 ,אל-מנטרה
8800 ,שפרעם ,560 ,אל-מנסורה
8800 ,שפרעם ,561 ,אל-מנפלוטי
8800 ,שפרעם ,563 ,אל-מסלא
8800 ,שפרעם ,564 ,אל-מסלח'
8800 ,שפרעם ,565 ,אל-מעסרה
8800 ,שפרעם ,107 ,אל-מערי
8800 ,שפרעם ,542 ,אל-מר'אוור
8800 ,שפרעם ,543 ,אל-מר'ארבה
8800 ,שפרעם ,567 ,אל-מרג'
8800 ,שפרעם ,170 ,אל-מריכ
8800 ,שפרעם ,568 ,אל-משארף
8800 ,שפרעם ,569 ,אל-משהד
8800 ,שפרעם ,570 ,אל-משמש
8800 ,שפרעם ,973 ,אל-נח'יל
8800 ,שפרעם ,295 ,אל-עהדה אל-עומרייה
8800 ,שפרעם ,602 ,אל-עוכל
8800 ,שפרעם ,181 ,אל-פארוק
8800 ,שפרעם ,611 ,אל-פוסתוק
8800 ,שפרעם ,118 ,אל-קאדיסייה
8800 ,שפרעם ,873 ,אל-קאהירה
8800 ,שפרעם ,618 ,אל-קורונפול
8800 ,שפרעם ,620 ,אל-קנדול
8800 ,שפרעם ,624 ,אל-קרעאוי
8800 ,שפרעם ,471 ,אל-ר'אזי
8800 ,שפרעם ,941 ,אל-ר'אר
8800 ,שפרעם ,628 ,אל-ר'בראא
8800 ,שפרעם ,474 ,אל-ר'דיר
8800 ,שפרעם ,475 ,אל-ר'ורבאל
8800 ,שפרעם ,125 ,אל-ר'זאלייאת
8800 ,שפרעם ,479 ,אל-ר'ית
8800 ,שפרעם ,176 ,אל-ר'נאמה
8800 ,שפרעם ,480 ,אל-ר'נטוס
8800 ,שפרעם ,918 ,אל-שוויקה
8800 ,שפרעם ,228 ,אלאקצה אלעמריה
8800 ,שפרעם ,448 ,אלבורתקאל
8800 ,שפרעם ,463 ,אלברווה
8800 ,שפרעם ,6773 ,אלברכה-העיר העתיקה
8800 ,שפרעם ,280 ,אלברכוכ
8800 ,שפרעם ,466 ,אלבשת
8800 ,שפרעם ,472 ,אלג'בל
8800 ,שפרעם ,470 ,אלג'עונה
8800 ,שפרעם ,926 ,אלדאהר
8800 ,שפרעם ,6775 ,אלהוארה-העיר העתיקה
8800 ,שפרעם ,200 ,אלוני
8800 ,שפרעם ,6741 ,אלוסטאני
8800 ,שפרעם ,351 ,אלוער
8800 ,שפרעם ,6778 ,אלח'לוה-העיר העתיקה
8800 ,שפרעם ,6777 ,אלח'נדק-העיר העתיקה
8800 ,שפרעם ,6783 ,אלח'רוביה
8800 ,שפרעם ,237 ,אלחדת'ה
8800 ,שפרעם ,6713 ,אלחרש
8800 ,שפרעם ,222 ,אליאכוכ
8800 ,שפרעם ,155 ,אליאס סלבאק
8800 ,שפרעם ,279 ,אלכוטראן
8800 ,שפרעם ,251 ,אלכותר
8800 ,שפרעם ,6711 ,אלכסאר
8800 ,שפרעם ,213 ,אלכסטל
8800 ,שפרעם ,6714 ,אלכרך
8800 ,שפרעם ,259 ,אלמג'דל
8800 ,שפרעם ,6715 ,אלמדרסה אלת'נאויה
8800 ,שפרעם ,161 ,אלמוחסן
8800 ,שפרעם ,800 ,אלמכמאנה
8800 ,שפרעם ,6776 ,אלמכתב-העיר העתיקה
8800 ,שפרעם ,275 ,אלמנטאר
8800 ,שפרעם ,562 ,אלמנשיה
8800 ,שפרעם ,6772 ,אלמראח-העיר העתיקה
8800 ,שפרעם ,358 ,אלנאבלסי
8800 ,שפרעם ,571 ,אלנאעורה
8800 ,שפרעם ,6740 ,אלנאעמה
8800 ,שפרעם ,572 ,אלנאקורה
8800 ,שפרעם ,345 ,אלנבי רובין
8800 ,שפרעם ,575 ,אלנהר
8800 ,שפרעם ,310 ,אלנקב
8800 ,שפרעם ,579 ,אלסאלחה
8800 ,שפרעם ,582 ,אלסג'רה
8800 ,שפרעם ,910 ,אלסויד
8800 ,שפרעם ,588 ,אלסמאן
8800 ,שפרעם ,201 ,אלסמיריה
8800 ,שפרעם ,591 ,אלסנובר
8800 ,שפרעם ,592 ,אלסעדה
8800 ,שפרעם ,285 ,אלסעדיה
8800 ,שפרעם ,313 ,אלספאח
8800 ,שפרעם ,164 ,אלספאר
8800 ,שפרעם ,132 ,אלספסאף
8800 ,שפרעם ,165 ,אלספרה
8800 ,שפרעם ,593 ,אלסקר
8800 ,שפרעם ,594 ,אלסרג'את
8800 ,שפרעם ,595 ,אלסרוו
8800 ,שפרעם ,596 ,אלסריס
8800 ,שפרעם ,467 ,אלע'אבסיה
8800 ,שפרעם ,597 ,אלעאסי
8800 ,שפרעם ,130 ,אלעבאסיה
8800 ,שפרעם ,598 ,אלעבוד
8800 ,שפרעם ,599 ,אלעג'וז
8800 ,שפרעם ,601 ,אלעואדיה
8800 ,שפרעם ,600 ,אלעוודה
8800 ,שפרעם ,299 ,אלעוליק
8800 ,שפרעם ,350 ,אלעטאריה
8800 ,שפרעם ,603 ,אלעיון
8800 ,שפרעם ,178 ,אלעין אלע'רבי
8800 ,שפרעם ,608 ,אלעיקאב
8800 ,שפרעם ,604 ,אלעמאר
8800 ,שפרעם ,605 ,אלעמורי
8800 ,שפרעם ,606 ,אלענאב
8800 ,שפרעם ,146 ,אלענדליב
8800 ,שפרעם ,607 ,אלעסקלאני
8800 ,שפרעם ,112 ,אלעקבי
8800 ,שפרעם ,134 ,אלעראקיב
8800 ,שפרעם ,609 ,אלערישה
8800 ,שפרעם ,244 ,אלפאלוג'ה
8800 ,שפרעם ,263 ,אלפארסי
8800 ,שפרעם ,610 ,אלפג'את
8800 ,שפרעם ,145 ,אלפהד
8800 ,שפרעם ,6744 ,אלפואר מערבי
8800 ,שפרעם ,309 ,אלפורן
8800 ,שפרעם ,612 ,אלפיג'ן
8800 ,שפרעם ,187 ,אלפל
8800 ,שפרעם ,613 ,אלפלפל
8800 ,שפרעם ,182 ,אלפר
8800 ,שפרעם ,614 ,אלפראדה
8800 ,שפרעם ,205 ,אלפרהוד
8800 ,שפרעם ,148 ,אלפרס
8800 ,שפרעם ,615 ,אלקאק
8800 ,שפרעם ,239 ,אלקדומי
8800 ,שפרעם ,616 ,אלקהווה
8800 ,שפרעם ,944 ,אלקובה
8800 ,שפרעם ,617 ,אלקוטראן
8800 ,שפרעם ,6774 ,אלקלעה-העיר העתיקה
8800 ,שפרעם ,6712 ,אלקסארא אבן סיד
8800 ,שפרעם ,637 ,אלשאם
8800 ,שפרעם ,240 ,אלשאפיעי
8800 ,שפרעם ,638 ,אלשבעאנה
8800 ,שפרעם ,231 ,אלשג'רה
8800 ,שפרעם ,639 ,אלשהאבי
8800 ,שפרעם ,640 ,אלשהד
8800 ,שפרעם ,641 ,אלשוהדא
8800 ,שפרעם ,891 ,אלשוויכה
8800 ,שפרעם ,642 ,אלשומר
8800 ,שפרעם ,643 ,אלשונה
8800 ,שפרעם ,644 ,אלשוערא
8800 ,שפרעם ,645 ,אלשוף
8800 ,שפרעם ,646 ,אלשופאניה
8800 ,שפרעם ,647 ,אלשיכון
8800 ,שפרעם ,651 ,אלשכארה
8800 ,שפרעם ,296 ,אלשלבי
8800 ,שפרעם ,236 ,אלשנאר
8800 ,שפרעם ,650 ,אלשעבי
8800 ,שפרעם ,119 ,אלשרופיה
8800 ,שפרעם ,652 ,אלשתיוי
8800 ,שפרעם ,653 ,אלתונה
8800 ,שפרעם ,654 ,אלתין
8800 ,שפרעם ,655 ,אלתל
8800 ,שפרעם ,656 ,אלתלאל א-סבע
8800 ,שפרעם ,214 ,אלתסאמח
8800 ,שפרעם ,657 ,אלתפאח
8800 ,שפרעם ,658 ,אמיל גרינצוויג
8800 ,שפרעם ,659 ,אמיל תומא
8800 ,שפרעם ,152 ,אסעד מנסור
8800 ,שפרעם ,660 ,אספהאן
8800 ,שפרעם ,159 ,אעולא
8800 ,שפרעם ,357 ,ארסלאן
8800 ,שפרעם ,6770 ,באב א-דיר-עיר עתיקה
8800 ,שפרעם ,662 ,באב אל-הווא
8800 ,שפרעם ,665 ,בדראן משיעל
8800 ,שפרעם ,666 ,בוליביה
8800 ,שפרעם ,667 ,בוקרשט
8800 ,שפרעם ,668 ,בורג' א-סוכר
8800 ,שפרעם ,163 ,בטרירך כלימנסו בחות
8800 ,שפרעם ,669 ,ביאר אל-עדס
8800 ,שפרעם ,670 ,ביירות
8800 ,שפרעם ,671 ,ביסאן
8800 ,שפרעם ,225 ,ביר אבו חוסין
8800 ,שפרעם ,111 ,ביר אלסיכה
8800 ,שפרעם ,908 ,בית א-שעב
8800 ,שפרעם ,6760 ,בית הקברות הדרוזי
8800 ,שפרעם ,863 ,ביתהופן
8800 ,שפרעם ,193 ,בלד אלשיך
8800 ,שפרעם ,673 ,בנקוק
8800 ,שפרעם ,664 ,בר'דאד
8800 ,שפרעם ,674 ,ברזיל
8800 ,שפרעם ,675 ,ברעם
8800 ,שפרעם ,678 ,ג'בור יוסף ג'בור
8800 ,שפרעם ,679 ,ג'בל א-טור
8800 ,שפרעם ,680 ,ג'בל אל-ערב
8800 ,שפרעם ,681 ,ג'דרו
8800 ,שפרעם ,281 ,ג'וב א-נח'ל
8800 ,שפרעם ,677 ,ג'וב יוסף
8800 ,שפרעם ,290 ,ג'וויד
8800 ,שפרעם ,682 ,ג'וז אל-הינד
8800 ,שפרעם ,683 ,ג'ול ג'מאל
8800 ,שפרעם ,684 ,ג'ולס
8800 ,שפרעם ,868 ,ג'יפארא
8800 ,שפרעם ,687 ,ג'מילה בו חיירד
8800 ,שפרעם ,688 ,ג'נין
8800 ,שפרעם ,689 ,ג'עפר
8800 ,שפרעם ,227 ,גאנדי
8800 ,שפרעם ,220 ,גבע
8800 ,שפרעם ,685 ,גומיז
8800 ,שפרעם ,284 ,גלידאן
8800 ,שפרעם ,108 ,גמל עבד אלנאסר
8800 ,שפרעם ,690 ,דאהור סלימאן תלחמי
8800 ,שפרעם ,943 ,דאווד סלמאן תלחמי
8800 ,שפרעם ,692 ,דאחס
8800 ,שפרעם ,694 ,דהר אל-כניס
8800 ,שפרעם ,699 ,דוביאן
8800 ,שפרעם ,348 ,דיר אלקאסי
8800 ,שפרעם ,700 ,דיר חנא
8800 ,שפרעם ,247 ,דלאתא
8800 ,שפרעם ,701 ,דמשק
8800 ,שפרעם ,949 ,דרך א-טייבה
8800 ,שפרעם ,425 ,דרך אבו שאח
8800 ,שפרעם ,920 ,דרך אבו תאבת
8800 ,שפרעם ,922 ,דרך אל-בורג'
8800 ,שפרעם ,928 ,דרך אל-בירכה
8800 ,שפרעם ,927 ,דרך אל-בסלייה
8800 ,שפרעם ,948 ,דרך אל-ווסטאני
8800 ,שפרעם ,937 ,דרך אל-חי אל-אח'דר
8800 ,שפרעם ,946 ,דרך אל-כרם
8800 ,שפרעם ,952 ,דרך אל-מטלה
8800 ,שפרעם ,930 ,דרך אל-מידאן
8800 ,שפרעם ,324 ,דרך אל-פוואר
8800 ,שפרעם ,938 ,דרך אל-קלעה
8800 ,שפרעם ,234 ,דרך אלחי אלח'דר
8800 ,שפרעם ,1002 ,דרך אלשאהין
8800 ,שפרעם ,114 ,דרך באב אלדיר
8800 ,שפרעם ,663 ,דרך באב אלחואסל
8800 ,שפרעם ,314 ,דרך ג'באתא
8800 ,שפרעם ,950 ,דרך ואדי א-סקיע
8800 ,שפרעם ,931 ,דרך ואדי אל-חמאם
8800 ,שפרעם ,947 ,דרך מדרסת אל-עין
8800 ,שפרעם ,332 ,דרך עג'רוש
8800 ,שפרעם ,929 ,דרך עין עאפיה
8800 ,שפרעם ,702 ,דרע אל-משירפה
8800 ,שפרעם ,703 ,דרע מרשאן
8800 ,שפרעם ,325 ,דרעא
8800 ,שפרעם ,705 ,הארון א-רשיד
8800 ,שפרעם ,221 ,הישאם א-שראבי
8800 ,שפרעם ,6771 ,העיר העתיקה
8800 ,שפרעם ,264 ,הראשון במאי
8800 ,שפרעם ,709 ,ואדי א-סנע
8800 ,שפרעם ,137 ,ואדי א-שעיר
8800 ,שפרעם ,707 ,ואדי אל-ג'מאל
8800 ,שפרעם ,708 ,ואדי אל-מלק
8800 ,שפרעם ,710 ,ואדי בלאן
8800 ,שפרעם ,6781 ,ואדי הושה
8800 ,שפרעם ,346 ,ואדי הושי
8800 ,שפרעם ,720 ,ואדי חנין
8800 ,שפרעם ,271 ,ואדי סלאמי
8800 ,שפרעם ,722 ,ואן כוח'
8800 ,שפרעם ,6782 ,ווערת ג'רוס
8800 ,שפרעם ,721 ,ויא תריסטי
8800 ,שפרעם ,723 ,וערת אלמשאחר
8800 ,שפרעם ,724 ,וערת אלסובח
8800 ,שפרעם ,691 ,זאהר עומר א-זידאני
8800 ,שפרעם ,697 ,זהר א-שריף
8800 ,שפרעם ,693 ,זהר אל-וסטאני
8800 ,שפרעם ,695 ,זהר אל-קלעה
8800 ,שפרעם ,696 ,זהר חסן
8800 ,שפרעם ,698 ,זהר שרתא
8800 ,שפרעם ,725 ,זודאת אל-חריק
8800 ,שפרעם ,726 ,זומורוד
8800 ,שפרעם ,727 ,זופא
8800 ,שפרעם ,728 ,זידאן סלאמה
8800 ,שפרעם ,729 ,זיתון אל-פרוטסטנט
8800 ,שפרעם ,302 ,זמארין
8800 ,שפרעם ,301 ,זרעין
8800 ,שפרעם ,775 ,ח'דר אבו אל-עולא
8800 ,שפרעם ,258 ,ח'ואלד
8800 ,שפרעם ,756 ,ח'ירבת ג'דין
8800 ,שפרעם ,771 ,ח'ירבת ח'ראזה
8800 ,שפרעם ,739 ,ח'לאייל א-נור
8800 ,שפרעם ,741 ,ח'ליל אלסכאכיני
8800 ,שפרעם ,743 ,ח'לף אחמד א-סעידי
8800 ,שפרעם ,744 ,ח'לת איר'ראב
8800 ,שפרעם ,745 ,ח'לת אלח'מאש
8800 ,שפרעם ,746 ,ח'לת אליהוד
8800 ,שפרעם ,747 ,ח'לת אלעבהר
8800 ,שפרעם ,748 ,ח'לת אלעיאדה
8800 ,שפרעם ,749 ,ח'לת אלפארס
8800 ,שפרעם ,6710 ,ח'לת נסים
8800 ,שפרעם ,778 ,ח'ניפס
8800 ,שפרעם ,754 ,ח'ראמת אלדרוז
8800 ,שפרעם ,755 ,ח'ראמת אלמסיחיין
8800 ,שפרעם ,757 ,ח'רדל
8800 ,שפרעם ,731 ,חאסביא
8800 ,שפרעם ,732 ,חאפז אברהים
8800 ,שפרעם ,895 ,חארת אבו רחמה
8800 ,שפרעם ,733 ,חארת אלח'לווה
8800 ,שפרעם ,734 ,חארת אלמכתב
8800 ,שפרעם ,939 ,חארת אלמראח
8800 ,שפרעם ,735 ,חארת באב אלחואסל
8800 ,שפרעם ,911 ,חג'ר אלנוס
8800 ,שפרעם ,194 ,חואסה
8800 ,שפרעם ,736 ,חוסין עליאן
8800 ,שפרעם ,737 ,חוראן
8800 ,שפרעם ,738 ,חיטין
8800 ,שפרעם ,6751 ,חיילים משוחררים א+ב
8800 ,שפרעם ,6743 ,חיילים משוחררים צפו
8800 ,שפרעם ,740 ,חלב
8800 ,שפרעם ,742 ,חלים אבו רחמה
8800 ,שפרעם ,750 ,חמוד
8800 ,שפרעם ,265 ,חנא מויס
8800 ,שפרעם ,751 ,חנא נקארה
8800 ,שפרעם ,752 ,חנא סלימאן חדאד
8800 ,שפרעם ,753 ,חנא עספור
8800 ,שפרעם ,215 ,חסן נימר חוסין
8800 ,שפרעם ,288 ,חסן סלאמה
8800 ,שפרעם ,6792 ,ט'הר אלכניס
8800 ,שפרעם ,758 ,טאחונת א-ראהיב
8800 ,שפרעם ,759 ,טבריה
8800 ,שפרעם ,760 ,טואחין כורדאני
8800 ,שפרעם ,761 ,טובאס
8800 ,שפרעם ,907 ,טמרה
8800 ,שפרעם ,320 ,טנטורא
8800 ,שפרעם ,762 ,טראבין
8800 ,שפרעם ,945 ,טרביה
8800 ,שפרעם ,916 ,טרפא אבן אלעבד
8800 ,שפרעם ,913 ,טרפה איבן אל-עבד
8800 ,שפרעם ,770 ,יאפא
8800 ,שפרעם ,764 ,יוחנא בולוס א-ת'אני
8800 ,שפרעם ,765 ,יום אלארד'
8800 ,שפרעם ,766 ,יוסף אידריס
8800 ,שפרעם ,767 ,יוסף כרכבי
8800 ,שפרעם ,768 ,יוסף סליבא
8800 ,שפרעם ,769 ,יני יני
8800 ,שפרעם ,772 ,יריחו
8800 ,שפרעם ,773 ,כאבול
8800 ,שפרעם ,909 ,כאמלה מרושי
8800 ,שפרעם ,874 ,כוויכאת
8800 ,שפרעם ,192 ,כווכב
8800 ,שפרעם ,957 ,כוזברה
8800 ,שפרעם ,875 ,כורדאני
8800 ,שפרעם ,253 ,כלת א-תון
8800 ,שפרעם ,128 ,כלת אלג'אמע
8800 ,שפרעם ,777 ,כמאל ג'ונבלאט
8800 ,שפרעם ,779 ,כפר ברעם
8800 ,שפרעם ,331 ,כפר יאסיף
8800 ,שפרעם ,780 ,כפר כנא
8800 ,שפרעם ,781 ,כפר לאם
8800 ,שפרעם ,317 ,כפר סבת
8800 ,שפרעם ,347 ,כפר ענאן
8800 ,שפרעם ,782 ,כפר קאסם
8800 ,שפרעם ,783 ,כפר קדום
8800 ,שפרעם ,198 ,כראד אל-ח'יט
8800 ,שפרעם ,784 ,כרום אלענב
8800 ,שפרעם ,935 ,כרם א-זייתון
8800 ,שפרעם ,289 ,כרם אלח'ורי
8800 ,שפרעם ,785 ,כרם ג'בור
8800 ,שפרעם ,326 ,לוביה
8800 ,שפרעם ,786 ,לורד
8800 ,שפרעם ,787 ,לקייה
8800 ,שפרעם ,788 ,מאיר וילנר
8800 ,שפרעם ,763 ,מאר יוחנא אלמועמדאן
8800 ,שפרעם ,6752 ,מאראשון
8800 ,שפרעם ,789 ,מארון אלראס
8800 ,שפרעם ,336 ,מארתן לותר קינג
8800 ,שפרעם ,790 ,מדריד
8800 ,שפרעם ,293 ,מדרסת אל-בסלייה
8800 ,שפרעם ,791 ,מואיד אברהים
8800 ,שפרעם ,793 ,מוופק דיאב
8800 ,שפרעם ,794 ,מוחמד אל-ענזה
8800 ,שפרעם ,157 ,מוחמד גמגום
8800 ,שפרעם ,186 ,מוחמד חמאדי
8800 ,שפרעם ,795 ,מוחמד עבד אל-ווהאב
8800 ,שפרעם ,162 ,מוחמד עבדו
8800 ,שפרעם ,796 ,מועין בסיסו
8800 ,שפרעם ,797 ,מועתז
8800 ,שפרעם ,798 ,מוצרט
8800 ,שפרעם ,792 ,מור'ור אלר'יט
8800 ,שפרעם ,216 ,מחמד חמודי
8800 ,שפרעם ,799 ,מחמוד דרוויש
8800 ,שפרעם ,971 ,מיכאיל נועימה
8800 ,שפרעם ,801 ,מיסלון
8800 ,שפרעם ,802 ,מיעאר
8800 ,שפרעם ,803 ,מירון
8800 ,שפרעם ,359 ,מלבס
8800 ,שפרעם ,804 ,מנסור ארמלי
8800 ,שפרעם ,341 ,מנסור כרדוש
8800 ,שפרעם ,805 ,מנסור קורטאם
8800 ,שפרעם ,329 ,מסכנא
8800 ,שפרעם ,806 ,מעלול
8800 ,שפרעם ,807 ,מקאם א-שופה
8800 ,שפרעם ,808 ,מרג' א-זוהור
8800 ,שפרעם ,809 ,מרג' עיון
8800 ,שפרעם ,810 ,מרים בוארדי
8800 ,שפרעם ,6790 ,מרכז מסחר-ע עתיקה
8800 ,שפרעם ,811 ,נאבולי
8800 ,שפרעם ,896 ,נאבלוס
8800 ,שפרעם ,189 ,נאג'י אלעלי
8800 ,שפרעם ,812 ,נבטיה
8800 ,שפרעם ,813 ,נג'ד
8800 ,שפרעם ,342 ,נגיב נסאר
8800 ,שפרעם ,195 ,נגראן
8800 ,שפרעם ,814 ,נוואר א-שמס
8800 ,שפרעם ,818 ,נועום אל-ג'ליל
8800 ,שפרעם ,815 ,ניזאר קבאני
8800 ,שפרעם ,816 ,ניקולא א-דור
8800 ,שפרעם ,817 ,נלסון מנדילא
8800 ,שפרעם ,819 ,נעים קאסם נפאע
8800 ,שפרעם ,820 ,נעמה חוסין ח'אזם
8800 ,שפרעם ,104 ,נצרת
8800 ,שפרעם ,821 ,סאבא בחות'
8800 ,שפרעם ,822 ,סאבע סמא
8800 ,שפרעם ,202 ,סאברין
8800 ,שפרעם ,839 ,סבארתאכוס
8800 ,שפרעם ,121 ,סבסטיא
8800 ,שפרעם ,824 ,סג'רת אל-יהוד
8800 ,שפרעם ,268 ,סובחי יאסין
8800 ,שפרעם ,825 ,סוואר א-ד'הב
8800 ,שפרעם ,826 ,סולטאן באשא אל-אטרש
8800 ,שפרעם ,6731 ,סופתעאדי
8800 ,שפרעם ,829 ,סח'נין
8800 ,שפרעם ,323 ,סחמאתא
8800 ,שפרעם ,232 ,סייד דרויש
8800 ,שפרעם ,827 ,סילאן
8800 ,שפרעם ,828 ,סירין
8800 ,שפרעם ,830 ,סליבא
8800 ,שפרעם ,831 ,סלימאן אבו עביד
8800 ,שפרעם ,832 ,סלימאן אל-ח'ורי
8800 ,שפרעם ,833 ,סלפית
8800 ,שפרעם ,344 ,סמר
8800 ,שפרעם ,274 ,סעד בן אבי וקאס
8800 ,שפרעם ,834 ,סעד זר'לול
8800 ,שפרעם ,835 ,סעד ח'ניפס
8800 ,שפרעם ,836 ,סעד נכד
8800 ,שפרעם ,241 ,סעיד אחמד ח'טיב
8800 ,שפרעם ,871 ,ספד
8800 ,שפרעם ,199 ,ספוריה
8800 ,שפרעם ,837 ,ספסאף
8800 ,שפרעם ,838 ,ספרג'ל
8800 ,שפרעם ,872 ,סרפנד
8800 ,שפרעם ,6700 ,סרקיס
8800 ,שפרעם ,840 ,סת נזירה
8800 ,שפרעם ,676 ,ע'אבת טבעון
8800 ,שפרעם ,333 ,עארף עארף
8800 ,שפרעם ,841 ,עבאד א-שמס
8800 ,שפרעם ,141 ,עבד א-נבי
8800 ,שפרעם ,842 ,עבד אל-חלים חאפז
8800 ,שפרעם ,335 ,עבד אלכרים אלכרמי
8800 ,שפרעם ,242 ,עבד אלמלק
8800 ,שפרעם ,212 ,עבד אלקאדר אלגזארי
8800 ,שפרעם ,337 ,עבד אלרחים מחמוד
8800 ,שפרעם ,305 ,עבד אלרחמן אלדאכל
8800 ,שפרעם ,246 ,עבדאללה בהיג'
8800 ,שפרעם ,843 ,עבדאללה חסון
8800 ,שפרעם ,243 ,עבדאללה קדרי
8800 ,שפרעם ,844 ,עבוד
8800 ,שפרעם ,845 ,עבלין
8800 ,שפרעם ,846 ,עבס
8800 ,שפרעם ,6717 ,עג'רוש
8800 ,שפרעם ,297 ,עווד
8800 ,שפרעם ,224 ,עולם
8800 ,שפרעם ,304 ,עומר אבן אלעאס
8800 ,שפרעם ,211 ,עומר אלמכתאר
8800 ,שפרעם ,267 ,עומר סעסעאני
8800 ,שפרעם ,6793 ,עוסמאן
8800 ,שפרעם ,847 ,עוסמאן חוג'יראת
8800 ,שפרעם ,307 ,עטא אלזיר
8800 ,שפרעם ,321 ,עיון קארא
8800 ,שפרעם ,936 ,עין אל-חמלה
8800 ,שפרעם ,852 ,עין חוד
8800 ,שפרעם ,850 ,עין סעיד
8800 ,שפרעם ,853 ,עין קנביט
8800 ,שפרעם ,851 ,עין ר'זאל
8800 ,שפרעם ,282 ,עכברה
8800 ,שפרעם ,311 ,עלי עאשור
8800 ,שפרעם ,327 ,עלמא
8800 ,שפרעם ,854 ,עמאן
8800 ,שפרעם ,855 ,עמקא
8800 ,שפרעם ,856 ,ענזה
8800 ,שפרעם ,857 ,ענתרה
8800 ,שפרעם ,338 ,עסא א-ראעי
8800 ,שפרעם ,858 ,עסקלאן
8800 ,שפרעם ,859 ,עראבה
8800 ,שפרעם ,147 ,ערוס א-תורכמאן
8800 ,שפרעם ,340 ,פאטמה אלזהרא
8800 ,שפרעם ,306 ,פואד חגאזי
8800 ,שפרעם ,861 ,פואד נסאר
8800 ,שפרעם ,862 ,פורט סעיד
8800 ,שפרעם ,864 ,פיירוז
8800 ,שפרעם ,865 ,פיקאסו
8800 ,שפרעם ,866 ,פסוטה
8800 ,שפרעם ,262 ,פראדי
8800 ,שפרעם ,867 ,פרח
8800 ,שפרעם ,869 ,צור
8800 ,שפרעם ,870 ,צידון
8800 ,שפרעם ,255 ,צלאח א-דין
8800 ,שפרעם ,252 ,צפד
8800 ,שפרעם ,334 ,קאסם אמין
8800 ,שפרעם ,269 ,קדיתא
8800 ,שפרעם ,6718 ,קדמאני
8800 ,שפרעם ,940 ,קוסטנטין והילאנה
8800 ,שפרעם ,877 ,קוריש
8800 ,שפרעם ,879 ,קיביה
8800 ,שפרעם ,880 ,קיס
8800 ,שפרעם ,881 ,קיסריה
8800 ,שפרעם ,969 ,ראם אללה
8800 ,שפרעם ,962 ,ראס אל-עין
8800 ,שפרעם ,129 ,ראס עלי
8800 ,שפרעם ,882 ,ראשד חוסין
8800 ,שפרעם ,353 ,רדא עזאם
8800 ,שפרעם ,319 ,רויס
8800 ,שפרעם ,915 ,רח 730
8800 ,שפרעם ,368 ,רח 340
8800 ,שפרעם ,399 ,רח 370
8800 ,שפרעם ,381 ,רח 570
8800 ,שפרעם ,925 ,רח 770
8800 ,שפרעם ,362 ,רח 201
8800 ,שפרעם ,360 ,רח 131
8800 ,שפרעם ,404 ,רח 751
8800 ,שפרעם ,369 ,רח 361
8800 ,שפרעם ,379 ,רח 561
8800 ,שפרעם ,392 ,רח 661
8800 ,שפרעם ,382 ,רח 571
8800 ,שפרעם ,388 ,רח 602
8800 ,שפרעם ,371 ,רח 412
8800 ,שפרעם ,415 ,רח 832
8800 ,שפרעם ,401 ,רח 742
8800 ,שפרעם ,378 ,רח 552
8800 ,שפרעם ,919 ,רח 752
8800 ,שפרעם ,393 ,רח 662
8800 ,שפרעם ,367 ,רח 313
8800 ,שפרעם ,372 ,רח 413
8800 ,שפרעם ,410 ,רח 813
8800 ,שפרעם ,391 ,רח 643
8800 ,שפרעם ,380 ,רח 563
8800 ,שפרעם ,383 ,רח 573
8800 ,שפרעם ,385 ,רח 583
8800 ,שפרעם ,408 ,רח 783
8800 ,שפרעם ,374 ,רח 493
8800 ,שפרעם ,397 ,רח 704
8800 ,שפרעם ,402 ,רח 744
8800 ,שפרעם ,405 ,רח 754
8800 ,שפרעם ,420 ,רח 854
8800 ,שפרעם ,370 ,רח 374
8800 ,שפרעם ,386 ,רח 584
8800 ,שפרעם ,365 ,רח 294
8800 ,שפרעם ,409 ,רח 805
8800 ,שפרעם ,375 ,רח 535
8800 ,שפרעם ,421 ,רח 855
8800 ,שפרעם ,389 ,רח 606
8800 ,שפרעם ,411 ,רח 816
8800 ,שפרעם ,413 ,רח 826
8800 ,שפרעם ,363 ,רח 236
8800 ,שפרעם ,400 ,רח 736
8800 ,שפרעם ,417 ,רח 846
8800 ,שפרעם ,366 ,רח 307
8800 ,שפרעם ,373 ,רח 417
8800 ,שפרעם ,390 ,רח 627
8800 ,שפרעם ,414 ,רח 827
8800 ,שפרעם ,376 ,רח 547
8800 ,שפרעם ,403 ,רח 747
8800 ,שפרעם ,394 ,רח 667
8800 ,שפרעם ,406 ,רח 767
8800 ,שפרעם ,384 ,רח 577
8800 ,שפרעם ,396 ,רח 697
8800 ,שפרעם ,398 ,רח 728
8800 ,שפרעם ,377 ,רח 548
8800 ,שפרעם ,418 ,רח 848
8800 ,שפרעם ,395 ,רח 668
8800 ,שפרעם ,407 ,רח 778
8800 ,שפרעם ,412 ,רח 819
8800 ,שפרעם ,416 ,רח 839
8800 ,שפרעם ,361 ,רח 149
8800 ,שפרעם ,419 ,רח 849
8800 ,שפרעם ,364 ,רח 279
8800 ,שפרעם ,387 ,רח 599
8800 ,שפרעם ,153 ,רמלה
8800 ,שפרעם ,885 ,רשיד אל-ח'ורי
8800 ,שפרעם ,886 ,שבדיה
8800 ,שפרעם ,888 ,שבעא
8800 ,שפרעם ,889 ,שדאד
8800 ,שפרעם ,890 ,שהרזאד
8800 ,שפרעם ,1001 ,שוכרי מבאריכי
8800 ,שפרעם ,892 ,שחאדה שחאדה
8800 ,שפרעם ,887 ,שיבלי אל-עריאן
8800 ,שפרעם ,6811 ,שכ א-טייבה
8800 ,שפרעם ,6742 ,שכ אבו שח
8800 ,שפרעם ,6716 ,שכ אבו תאבת
8800 ,שפרעם ,6780 ,שכ אל-בורג'
8800 ,שפרעם ,6720 ,שכ אל-בסלייה
8800 ,שפרעם ,6719 ,שכ אל-כאשף
8800 ,שפרעם ,6785 ,שכ אל-מטלה
8800 ,שפרעם ,6732 ,שכ אלחי אלח'דר
8800 ,שפרעם ,6784 ,שכ אלכרם
8800 ,שפרעם ,6721 ,שכ אלמידאן
8800 ,שפרעם ,6761 ,שכ אלעין
8800 ,שפרעם ,6750 ,שכ ג'באתא
8800 ,שפרעם ,6830 ,שכ ג'באתא מערבית
8800 ,שפרעם ,6722 ,שכ ואדי אלחמאם
8800 ,שפרעם ,6791 ,שכ ואדי אלסקיע
8800 ,שפרעם ,6703 ,שכ עין עאפיה
8800 ,שפרעם ,6730 ,שכ שאהין-חסון
8800 ,שפרעם ,897 ,שעאנין
8800 ,שפרעם ,150 ,שעב
8800 ,שפרעם ,898 ,שפיק עזאם
8800 ,שפרעם ,9000 ,שפרעם
8800 ,שפרעם ,899 ,תבריז
8800 ,שפרעם ,901 ,תוניס
8800 ,שפרעם ,106 ,תופיק זיאד
8800 ,שפרעם ,902 ,תופיק טובי
8800 ,שפרעם ,903 ,תין הלאל
8800 ,שפרעם ,905 ,תל א-דהב
8800 ,שפרעם ,906 ,תל א-זיב
8800 ,שפרעם ,967 ,תל א-נסים
8800 ,שפרעם ,933 ,תל אל-כניס
8800 ,שפרעם ,277 ,תל אל-קאדי
8800 ,שפרעם ,904 ,תל אלזבדה
8800 ,שפרעם ,900 ,תר'לב
3649 ,שקד ,9000 ,שקד
1233 ,שקף ,102 ,אשכול
1233 ,שקף ,101 ,הגפן
1233 ,שקף ,107 ,הכרם
1233 ,שקף ,106 ,זמורה
1233 ,שקף ,108 ,סמדר
1233 ,שקף ,103 ,עינב
1233 ,שקף ,9000 ,שקף
1233 ,שקף ,104 ,שריג
1233 ,שקף ,105 ,תירוש
292 ,שרונה ,105 ,הגפן
292 ,שרונה ,103 ,הזורע
292 ,שרונה ,101 ,החורש
292 ,שרונה ,102 ,הקוצר
292 ,שרונה ,104 ,השקד
292 ,שרונה ,6001 ,כיכר הזית
292 ,שרונה ,9000 ,שרונה
1114 ,שריגים (לי-און) ,9000 ,שריגים (לי-און)
126 ,שריד ,108 ,אורן
126 ,שריד ,111 ,איילה
126 ,שריד ,122 ,אילנות
126 ,שריד ,123 ,אלון
126 ,שריד ,112 ,אלמוג
126 ,שריד ,104 ,אנפה
126 ,שריד ,101 ,בית ראשון במולדת
126 ,שריד ,105 ,ברוש
126 ,שריד ,129 ,גפן
126 ,שריד ,118 ,דגן
126 ,שריד ,121 ,זית
126 ,שריד ,113 ,זמיר
126 ,שריד ,116 ,חבצלת
126 ,שריד ,120 ,חצב
126 ,שריד ,103 ,לפיד
126 ,שריד ,117 ,מעיין
126 ,שריד ,102 ,ניצנים
126 ,שריד ,109 ,נשר
126 ,שריד ,125 ,סחלב
126 ,שריד ,119 ,עומר
126 ,שריד ,115 ,עופר
126 ,שריד ,114 ,ערבה
126 ,שריד ,127 ,פשוש
126 ,שריד ,126 ,צבעוני
126 ,שריד ,124 ,רקפת
126 ,שריד ,128 ,שחר
126 ,שריד ,110 ,שיבולים
126 ,שריד ,106 ,שלהבת
126 ,שריד ,107 ,שקד
126 ,שריד ,9000 ,שריד
398 ,שרשרת ,9000 ,שרשרת
1045 ,שתולה ,9000 ,שתולה
763 ,שתולים ,115 ,האורן
763 ,שתולים ,105 ,האלה
763 ,שתולים ,104 ,האלון
763 ,שתולים ,103 ,הארז
763 ,שתולים ,102 ,הברוש
763 ,שתולים ,108 ,הגפן
763 ,שתולים ,107 ,הדולב
763 ,שתולים ,106 ,הדקל
763 ,שתולים ,111 ,ההדס
763 ,שתולים ,112 ,הזית
763 ,שתולים ,109 ,הרימון
763 ,שתולים ,101 ,השיטה
763 ,שתולים ,113 ,השקד
763 ,שתולים ,110 ,התאנה
763 ,שתולים ,114 ,התמר
763 ,שתולים ,120 ,שד הבנים
763 ,שתולים ,9000 ,שתולים
2062 ,תאשור ,101 ,הזית
2062 ,תאשור ,103 ,החיטה
2062 ,תאשור ,102 ,הרימון
2062 ,תאשור ,104 ,התאנה
2062 ,תאשור ,105 ,התמר
2062 ,תאשור ,9000 ,תאשור
2061 ,תדהר ,106 ,אירוס
2061 ,תדהר ,103 ,הדס
2061 ,תדהר ,102 ,הזית
2061 ,תדהר ,104 ,נורית
2061 ,תדהר ,105 ,סביון
2061 ,תדהר ,107 ,סמטת יקינטון
2061 ,תדהר ,109 ,סמטת מרווה
2061 ,תדהר ,108 ,סמטת נרקיס
2061 ,תדהר ,110 ,סמטת סיפן
2061 ,תדהר ,101 ,שדרות המייסדים
2061 ,תדהר ,9000 ,תדהר
1172 ,תובל ,101 ,דרך האורנים
1172 ,תובל ,102 ,דרך הברושים
1172 ,תובל ,103 ,דרך הרוח
1172 ,תובל ,104 ,דרך הרימונים
1172 ,תובל ,105 ,דרך לנוף
1172 ,תובל ,107 ,האגוז
1172 ,תובל ,108 ,האלונים
1172 ,תובל ,109 ,הגפן
1172 ,תובל ,110 ,החצבים
1172 ,תובל ,111 ,הכליל
1172 ,תובל ,112 ,הקידה
1172 ,תובל ,106 ,משעול הרפתות
1172 ,תובל ,113 ,שביל האלה
1172 ,תובל ,114 ,שביל הבוסתן
1172 ,תובל ,115 ,שביל החרוב
1172 ,תובל ,116 ,שביל העוזרר
1172 ,תובל ,117 ,שביל השזיף
1172 ,תובל ,118 ,שביל השקד
1172 ,תובל ,119 ,שדרת התאנים
1172 ,תובל ,9000 ,תובל
3558 ,תומר ,9000 ,תומר
1083 ,תושיה ,9000 ,תושיה
163 ,תימורים ,101 ,שכ קרוונים
163 ,תימורים ,9000 ,תימורים
10 ,תירוש ,9000 ,תירוש
5000 ,תל אביב - יפו ,2825 ,אב
5000 ,תל אביב - יפו ,1366 ,אבא ושרה נאמן
5000 ,תל אביב - יפו ,2350 ,אבודרהם
5000 ,תל אביב - יפו ,1632 ,אבולעפיה
5000 ,תל אביב - יפו ,4535 ,אבולעפיה רבקה ושלמה
5000 ,תל אביב - יפו ,391 ,אבוקה
5000 ,תל אביב - יפו ,1669 ,אבטליון
5000 ,תל אביב - יפו ,2743 ,אביאסף
5000 ,תל אביב - יפו ,3111 ,אביגור שאול
5000 ,תל אביב - יפו ,4781 ,אבידן דוד
5000 ,תל אביב - יפו ,2990 ,אבידן שמעון
5000 ,תל אביב - יפו ,2737 ,אביטל
5000 ,תל אביב - יפו ,2751 ,אבימלך
5000 ,תל אביב - יפו ,2027 ,אבינרי יצחק
5000 ,תל אביב - יפו ,1995 ,אביצור שמואל
5000 ,תל אביב - יפו ,316 ,אבישי
5000 ,תל אביב - יפו ,2765 ,אביתר
5000 ,תל אביב - יפו ,922 ,אבן ג'נאח
5000 ,תל אביב - יפו ,1040 ,אבן גבירול
5000 ,תל אביב - יפו ,1685 ,אבן חלדון
5000 ,תל אביב - יפו ,1713 ,אבן סינא
5000 ,תל אביב - יפו ,330 ,אבן ספיר
5000 ,תל אביב - יפו ,1718 ,אבן רשד
5000 ,תל אביב - יפו ,1991 ,אבן שושן אברהם
5000 ,תל אביב - יפו ,2514 ,אבני זכרון
5000 ,תל אביב - יפו ,313 ,אבנר
5000 ,תל אביב - יפו ,2213 ,אברבנאל
5000 ,תל אביב - יפו ,4738 ,אברך ישעיהו
5000 ,תל אביב - יפו ,476 ,אגדתי ברוך
5000 ,תל אביב - יפו ,434 ,אגריפס
5000 ,תל אביב - יפו ,533 ,אד"ם הכהן
5000 ,תל אביב - יפו ,1795 ,אדיל עאזר
5000 ,תל אביב - יפו ,377 ,אדירים
5000 ,תל אביב - יפו ,2951 ,אדלר יש"י וברכה
5000 ,תל אביב - יפו ,1747 ,אדם ברוך
5000 ,תל אביב - יפו ,4114 ,אדמון ידידייה
5000 ,תל אביב - יפו ,2830 ,אדר
5000 ,תל אביב - יפו ,902 ,אהבת ציון
5000 ,תל אביב - יפו ,479 ,אהובה עוזרי
5000 ,תל אביב - יפו ,312 ,אהוד
5000 ,תל אביב - יפו ,2261 ,אהלי יעקב
5000 ,תל אביב - יפו ,539 ,אהרון דוד גורדון
5000 ,תל אביב - יפו ,4064 ,אהרון טוב משה
5000 ,תל אביב - יפו ,629 ,אהרונוביץ
5000 ,תל אביב - יפו ,1207 ,אהרונסון
5000 ,תל אביב - יפו ,1377 ,אהרן מגד
5000 ,תל אביב - יפו ,1609 ,אוארבך
5000 ,תל אביב - יפו ,219 ,אוגרית
5000 ,תל אביב - יפו ,1941 ,אוהב ישראל
5000 ,תל אביב - יפו ,1977 ,אוז'שקובה
5000 ,תל אביב - יפו ,4150 ,אויסטרך דויד
5000 ,תל אביב - יפו ,1508 ,אוליפנט
5000 ,תל אביב - יפו ,1360 ,אולשן יצחק
5000 ,תל אביב - יפו ,1644 ,אומץ
5000 ,תל אביב - יפו ,4752 ,אונטרמן איסר יהודה
5000 ,תל אביב - יפו ,1160 ,אוניברסיטת תל -אביב
5000 ,תל אביב - יפו ,4139 ,אוניקובסקי פרץ
5000 ,תל אביב - יפו ,808 ,אונקלוס
5000 ,תל אביב - יפו ,435 ,אוסישקין
5000 ,תל אביב - יפו ,2449 ,אופטושו יוסף
5000 ,תל אביב - יפו ,218 ,אופיר
5000 ,תל אביב - יפו ,4142 ,אופיר שייקה
5000 ,תל אביב - יפו ,4505 ,אופירה יוספי
5000 ,תל אביב - יפו ,190 ,אופנהיימר
5000 ,תל אביב - יפו ,2164 ,אור החיים
5000 ,תל אביב - יפו ,1022 ,אורי
5000 ,תל אביב - יפו ,3103 ,אורי צבי גרינברג
5000 ,תל אביב - יפו ,2220 ,אוריאל אקוסטה
5000 ,תל אביב - יפו ,2834 ,אורים
5000 ,תל אביב - יפו ,4697 ,אורלוב חנה
5000 ,תל אביב - יפו ,187 ,אושה
5000 ,תל אביב - יפו ,2448 ,אז"ר
5000 ,תל אביב - יפו ,1790 ,אזוב
5000 ,תל אביב - יפו ,6851 ,אזור הספורט
5000 ,תל אביב - יפו ,6656 ,אזור תעשייה
5000 ,תל אביב - יפו ,6500 ,אזורי חן
5000 ,תל אביב - יפו ,380 ,אח"י דקר
5000 ,תל אביב - יפו ,1104 ,אחד העם
5000 ,תל אביב - יפו ,1335 ,אחווה
5000 ,תל אביב - יפו ,1261 ,אחוזת בית
5000 ,תל אביב - יפו ,1715 ,אחילוף
5000 ,תל אביב - יפו ,281 ,אחימאיר אבא
5000 ,תל אביב - יפו ,2764 ,אחימעץ
5000 ,תל אביב - יפו ,2729 ,אחיעזר
5000 ,תל אביב - יפו ,2427 ,אחרון יוסף
5000 ,תל אביב - יפו ,1909 ,אטאטורק
5000 ,תל אביב - יפו ,2249 ,איגר עקיבא
5000 ,תל אביב - יפו ,1138 ,אידלסון אברהם
5000 ,תל אביב - יפו ,4660 ,אידלסון רחל ושמואל
5000 ,תל אביב - יפו ,4706 ,איזמוזי'ק לאה ודויד
5000 ,תל אביב - יפו ,443 ,אייבשיץ
5000 ,תל אביב - יפו ,2248 ,איילת השחר
5000 ,תל אביב - יפו ,2831 ,אייר
5000 ,תל אביב - יפו ,1344 ,איל"ן
5000 ,תל אביב - יפו ,1352 ,אילת
5000 ,תל אביב - יפו ,724 ,אימבר
5000 ,תל אביב - יפו ,126 ,אינשטיין
5000 ,תל אביב - יפו ,2144 ,איסקוב נסים
5000 ,תל אביב - יפו ,1548 ,איסרליש
5000 ,תל אביב - יפו ,3008 ,אירוס
5000 ,תל אביב - יפו ,2762 ,איתיאל
5000 ,תל אביב - יפו ,4635 ,איתין אהרון
5000 ,תל אביב - יפו ,4504 ,אלבז נתן
5000 ,תל אביב - יפו ,1740 ,אלביטר עבדאלראוף
5000 ,תל אביב - יפו ,324 ,אלדד הדני
5000 ,תל אביב - יפו ,1136 ,אלו מציאות
5000 ,תל אביב - יפו ,2824 ,אלול
5000 ,תל אביב - יפו ,2524 ,אלון יגאל
5000 ,תל אביב - יפו ,1002 ,אלוני ניסים
5000 ,תל אביב - יפו ,2634 ,אלוף
5000 ,תל אביב - יפו ,2234 ,אלוף בצלות
5000 ,תל אביב - יפו ,2866 ,אלוף דן לנר
5000 ,תל אביב - יפו ,1564 ,אלוף מגן קלמן
5000 ,תל אביב - יפו ,1319 ,אלחנן יצחק
5000 ,תל אביב - יפו ,523 ,אלחריזי
5000 ,תל אביב - יפו ,4130 ,אלטמן אריה
5000 ,תל אביב - יפו ,4561 ,אלי כהן
5000 ,תל אביב - יפו ,4065 ,אליאב בנימין
5000 ,תל אביב - יפו ,4063 ,אליאש מרים
5000 ,תל אביב - יפו ,332 ,אליהו מפירארה
5000 ,תל אביב - יפו ,1254 ,אליוט ג'ורג'
5000 ,תל אביב - יפו ,2957 ,אליעז רפאל
5000 ,תל אביב - יפו ,1652 ,אליעזר בן הורקנוס
5000 ,תל אביב - יפו ,1601 ,אליפלט
5000 ,תל אביב - יפו ,2381 ,אליקום
5000 ,תל אביב - יפו ,1519 ,אלישברג
5000 ,תל אביב - יפו ,1226 ,אלישיב
5000 ,תל אביב - יפו ,424 ,אלכסנדר ינאי
5000 ,תל אביב - יפו ,381 ,אלמגור
5000 ,תל אביב - יפו ,4696 ,אלמוג דוד
5000 ,תל אביב - יפו ,1135 ,אלנבי
5000 ,תל אביב - יפו ,1400 ,אלעזר (רוקח)
5000 ,תל אביב - יפו ,1670 ,אלעזר בן יוסי
5000 ,תל אביב - יפו ,1647 ,אלעזר בן עזריה
5000 ,תל אביב - יפו ,1654 ,אלעזר בן צדוק
5000 ,תל אביב - יפו ,815 ,אלעזר החורני
5000 ,תל אביב - יפו ,1634 ,אלפסי
5000 ,תל אביב - יפו ,4044 ,אלפרין חיים
5000 ,תל אביב - יפו ,286 ,אלקחי מרדכי
5000 ,תל אביב - יפו ,440 ,אלקלעי
5000 ,תל אביב - יפו ,1372 ,אלרואי
5000 ,תל אביב - יפו ,1228 ,אלשיך
5000 ,תל אביב - יפו ,269 ,אלתרמן נתן
5000 ,תל אביב - יפו ,1717 ,אמונים
5000 ,תל אביב - יפו ,1368 ,אמזלג
5000 ,תל אביב - יפו ,603 ,אמיל זולה
5000 ,תל אביב - יפו ,393 ,אמירים
5000 ,תל אביב - יפו ,308 ,אמנון ותמר
5000 ,תל אביב - יפו ,516 ,אמסטרדם
5000 ,תל אביב - יפו ,1262 ,אנגל
5000 ,תל אביב - יפו ,2468 ,אנדה עמיר
5000 ,תל אביב - יפו ,2151 ,אנדרוס
5000 ,תל אביב - יפו ,175 ,אנדרסן
5000 ,תל אביב - יפו ,4087 ,אנוסי משהד
5000 ,תל אביב - יפו ,1032 ,אנטוקולסקי
5000 ,תל אביב - יפו ,813 ,אנטיגונוס
5000 ,תל אביב - יפו ,2664 ,אני מאמין
5000 ,תל אביב - יפו ,2650 ,אנילביץ מרדכי
5000 ,תל אביב - יפו ,268 ,אנכי
5000 ,תל אביב - יפו ,2435 ,אנסקי
5000 ,תל אביב - יפו ,2392 ,אסירי ציון
5000 ,תל אביב - יפו ,1830 ,אסף הרופא
5000 ,תל אביב - יפו ,743 ,אסתר המלכה
5000 ,תל אביב - יפו ,1625 ,אפ"ק
5000 ,תל אביב - יפו ,195 ,אפטר יעקב
5000 ,תל אביב - יפו ,1912 ,אפלטון
5000 ,תל אביב - יפו ,133 ,אפקים
5000 ,תל אביב - יפו ,2210 ,אפרים קישון
5000 ,תל אביב - יפו ,920 ,אפשטיין
5000 ,תל אביב - יפו ,4527 ,אפשטיין יעקב
5000 ,תל אביב - יפו ,2731 ,אצ"ל
5000 ,תל אביב - יפו ,4576 ,אקסלרוד נתן
5000 ,תל אביב - יפו ,436 ,ארבע ארצות
5000 ,תל אביב - יפו ,2190 ,ארבר מנחם
5000 ,תל אביב - יפו ,1985 ,ארדון מרדכי
5000 ,תל אביב - יפו ,143 ,ארזי יהודה
5000 ,תל אביב - יפו ,2552 ,ארטר יצחק
5000 ,תל אביב - יפו ,2631 ,אריאל
5000 ,תל אביב - יפו ,274 ,אריכא יוסף
5000 ,תל אביב - יפו ,1905 ,אריסטו
5000 ,תל אביב - יפו ,816 ,אריסטובול
5000 ,תל אביב - יפו ,934 ,ארלוזורוב
5000 ,תל אביב - יפו ,1964 ,ארליך
5000 ,תל אביב - יפו ,2237 ,ארלנג'ר
5000 ,תל אביב - יפו ,472 ,ארם משה
5000 ,תל אביב - יפו ,611 ,ארנון
5000 ,תל אביב - יפו ,1559 ,ארניה אסוולדו
5000 ,תל אביב - יפו ,4759 ,ארסטידס סוזה מנדס
5000 ,תל אביב - יפו ,4562 ,ארצי יצחק
5000 ,תל אביב - יפו ,266 ,אש שלום
5000 ,תל אביב - יפו ,2168 ,אשדוד
5000 ,תל אביב - יפו ,197 ,אשכול לוי
5000 ,תל אביב - יפו ,326 ,אשכנזי
5000 ,תל אביב - יפו ,1357 ,אשקלון
5000 ,תל אביב - יפו ,2605 ,אשרמן יוסף
5000 ,תל אביב - יפו ,437 ,אשתורי הפרחי
5000 ,תל אביב - יפו ,1552 ,ב"ק ישראל
5000 ,תל אביב - יפו ,2684 ,באכר זאב
5000 ,תל אביב - יפו ,358 ,באסולה
5000 ,תל אביב - יפו ,530 ,באר טוביה
5000 ,תל אביב - יפו ,1370 ,באר יעקב
5000 ,תל אביב - יפו ,2102 ,באר שבע
5000 ,תל אביב - יפו ,125 ,בארט
5000 ,תל אביב - יפו ,1008 ,בארי
5000 ,תל אביב - יפו ,835 ,בבלי
5000 ,תל אביב - יפו ,2854 ,בדולח
5000 ,תל אביב - יפו ,802 ,בובליק
5000 ,תל אביב - יפו ,2683 ,בובר שלמה
5000 ,תל אביב - יפו ,618 ,בוגרשוב
5000 ,תל אביב - יפו ,915 ,בודנהיימר
5000 ,תל אביב - יפו ,468 ,בויאר אברהם
5000 ,תל אביב - יפו ,3106 ,בוני העיר
5000 ,תל אביב - יפו ,2431 ,בוסל
5000 ,תל אביב - יפו ,1001 ,בוסקוביץ
5000 ,תל אביב - יפו ,1308 ,בוסתנאי
5000 ,תל אביב - יפו ,2745 ,בועז
5000 ,תל אביב - יפו ,737 ,בוקי בן יגלי
5000 ,תל אביב - יפו ,2567 ,בוקסנבאום יוסף
5000 ,תל אביב - יפו ,467 ,בוקשפן דוד
5000 ,תל אביב - יפו ,4095 ,בור נילס
5000 ,תל אביב - יפו ,1122 ,בורוכוב
5000 ,תל אביב - יפו ,196 ,בורלא יהודה
5000 ,תל אביב - יפו ,2416 ,בורמה
5000 ,תל אביב - יפו ,2964 ,בושם
5000 ,תל אביב - יפו ,450 ,בזל
5000 ,תל אביב - יפו ,514 ,בחור אליהו
5000 ,תל אביב - יפו ,1137 ,ביאליק
5000 ,תל אביב - יפו ,4041 ,בייליס מנחם מנדל
5000 ,תל אביב - יפו ,605 ,ביירון
5000 ,תל אביב - יפו ,754 ,ביל"ו
5000 ,תל אביב - יפו ,4572 ,בילויה
5000 ,תל אביב - יפו ,911 ,בילטמור
5000 ,תל אביב - יפו ,628 ,בילינסון
5000 ,תל אביב - יפו ,495 ,בינט מאיר
5000 ,תל אביב - יפו ,2548 ,ביצרון
5000 ,תל אביב - יפו ,351 ,ביריה
5000 ,תל אביב - יפו ,831 ,בירנבוים
5000 ,תל אביב - יפו ,2968 ,בירנית
5000 ,תל אביב - יפו ,2624 ,בית אורן
5000 ,תל אביב - יפו ,373 ,בית אל
5000 ,תל אביב - יפו ,1520 ,בית אלפא
5000 ,תל אביב - יפו ,1668 ,בית אשל
5000 ,תל אביב - יפו ,2329 ,בית גוברין
5000 ,תל אביב - יפו ,1142 ,בית החיים
5000 ,תל אביב - יפו ,1550 ,בית הלל
5000 ,תל אביב - יפו ,1375 ,בית הנוער
5000 ,תל אביב - יפו ,1258 ,בית השואבה
5000 ,תל אביב - יפו ,2366 ,בית חדש
5000 ,תל אביב - יפו ,224 ,בית חורון
5000 ,תל אביב - יפו ,1227 ,בית יוסף
5000 ,תל אביב - יפו ,6651 ,בית יעקב
5000 ,תל אביב - יפו ,2459 ,בית יצקר
5000 ,תל אביב - יפו ,2653 ,בית ירח
5000 ,תל אביב - יפו ,1134 ,בית לחם
5000 ,תל אביב - יפו ,1521 ,בית עובד
5000 ,תל אביב - יפו ,2175 ,בית עולים שמעון
5000 ,תל אביב - יפו ,1824 ,בית פלט
5000 ,תל אביב - יפו ,283 ,בית צורי אליהו
5000 ,תל אביב - יפו ,2325 ,בית קציר
5000 ,תל אביב - יפו ,2635 ,בית קשת
5000 ,תל אביב - יפו ,1720 ,בית רעים
5000 ,תל אביב - יפו ,1553 ,בית שמאי
5000 ,תל אביב - יפו ,2752 ,ביתוס
5000 ,תל אביב - יפו ,2262 ,בכורי ציון
5000 ,תל אביב - יפו ,4042 ,בלאס שמחה
5000 ,תל אביב - יפו ,2369 ,בלבן מאיר
5000 ,תל אביב - יפו ,1026 ,בלוך דוד
5000 ,תל אביב - יפו ,109 ,בלום ליאון
5000 ,תל אביב - יפו ,4098 ,בלומנפלד יהודה
5000 ,תל אביב - יפו ,1264 ,בלפור
5000 ,תל אביב - יפו ,940 ,בלקינד
5000 ,תל אביב - יפו ,2715 ,בלשן
5000 ,תל אביב - יפו ,1539 ,בן אב"י איתמר
5000 ,תל אביב - יפו ,1522 ,בן אביגדור
5000 ,תל אביב - יפו ,1733 ,בן אחיטוב
5000 ,תל אביב - יפו ,2335 ,בן אשר
5000 ,תל אביב - יפו ,4593 ,בן גל מיכאל
5000 ,תל אביב - יפו ,1650 ,בן גמליאל
5000 ,תל אביב - יפו ,4127 ,בן דוד חיים
5000 ,תל אביב - יפו ,102 ,בן הלל מרדכי
5000 ,תל אביב - יפו ,2550 ,בן זאב
5000 ,תל אביב - יפו ,237 ,בן זבארה
5000 ,תל אביב - יפו ,4670 ,בן חיים פאול
5000 ,תל אביב - יפו ,2633 ,בן חיל
5000 ,תל אביב - יפו ,2739 ,בן חסד
5000 ,תל אביב - יפו ,510 ,בן יהודה
5000 ,תל אביב - יפו ,282 ,בן יוסף שלמה
5000 ,תל אביב - יפו ,2559 ,בן יעקב
5000 ,תל אביב - יפו ,201 ,בן ישי
5000 ,תל אביב - יפו ,926 ,בן לברט
5000 ,תל אביב - יפו ,836 ,בן סירא
5000 ,תל אביב - יפו ,2983 ,בן סירה יעקב
5000 ,תל אביב - יפו ,924 ,בן סרוק
5000 ,תל אביב - יפו ,524 ,בן עזרא
5000 ,תל אביב - יפו ,2226 ,בן עטר
5000 ,תל אביב - יפו ,625 ,בן עמי
5000 ,תל אביב - יפו ,1657 ,בן שטח שמעון
5000 ,תל אביב - יפו ,2515 ,בן שמן
5000 ,תל אביב - יפו ,925 ,בן שפרוט
5000 ,תל אביב - יפו ,2225 ,בנבנישתי
5000 ,תל אביב - יפו ,2170 ,בנטוב מרדכי
5000 ,תל אביב - יפו ,225 ,בני אפרים
5000 ,תל אביב - יפו ,2235 ,בני ברק
5000 ,תל אביב - יפו ,1651 ,בני בתירא
5000 ,תל אביב - יפו ,804 ,בני דן
5000 ,תל אביב - יפו ,830 ,בני משה
5000 ,תל אביב - יפו ,318 ,בניהו
5000 ,תל אביב - יפו ,322 ,בנימין מטודלה
5000 ,תל אביב - יפו ,2570 ,בנימיני אהרון
5000 ,תל אביב - יפו ,4013 ,בסוק חיים מנחם דר'
5000 ,תל אביב - יפו ,1989 ,בעל הטורים
5000 ,תל אביב - יפו ,2343 ,בעל העקידה
5000 ,תל אביב - יפו ,1982 ,בעלי התוספות
5000 ,תל אביב - יפו ,1020 ,בצלאל
5000 ,תל אביב - יפו ,1801 ,בצרה
5000 ,תל אביב - יפו ,4774 ,בקר אהרון
5000 ,תל אביב - יפו ,1109 ,בר אילן
5000 ,תל אביב - יפו ,739 ,בר גיורא
5000 ,תל אביב - יפו ,1610 ,בר הופמן
5000 ,תל אביב - יפו ,2373 ,בר יוחאי
5000 ,תל אביב - יפו ,1132 ,בר כוכבא
5000 ,תל אביב - יפו ,390 ,בראלי
5000 ,תל אביב - יפו ,2629 ,ברגי בנימין
5000 ,תל אביב - יפו ,1417 ,ברגנר אליזבת
5000 ,תל אביב - יפו ,177 ,ברגסון
5000 ,תל אביב - יפו ,1102 ,ברדיצ'בסקי
5000 ,תל אביב - יפו ,123 ,ברודי
5000 ,תל אביב - יפו ,111 ,ברודצקי
5000 ,תל אביב - יפו ,4710 ,ברוידס אברהם
5000 ,תל אביב - יפו ,462 ,ברון עקיבא
5000 ,תל אביב - יפו ,1129 ,ברוק צבי
5000 ,תל אביב - יפו ,2505 ,ברוריה
5000 ,תל אביב - יפו ,288 ,ברזאני משה
5000 ,תל אביב - יפו ,194 ,ברזיל
5000 ,תל אביב - יפו ,1275 ,ברזילי
5000 ,תל אביב - יפו ,297 ,ברטונוב יהושע
5000 ,תל אביב - יפו ,234 ,בריג אמיל
5000 ,תל אביב - יפו ,1036 ,בריינין ראובן
5000 ,תל אביב - יפו ,2708 ,ברכיה
5000 ,תל אביב - יפו ,238 ,ברכיהו הנקדן
5000 ,תל אביב - יפו ,4778 ,ברלין אליהו
5000 ,תל אביב - יפו ,4723 ,ברלין ישעיהו
5000 ,תל אביב - יפו ,160 ,ברלינר
5000 ,תל אביב - יפו ,1658 ,ברנדט וילי
5000 ,תל אביב - יפו ,819 ,ברנדיס
5000 ,תל אביב - יפו ,1364 ,ברנט
5000 ,תל אביב - יפו ,2682 ,ברנפלד שמעון
5000 ,תל אביב - יפו ,1246 ,ברנר
5000 ,תל אביב - יפו ,540 ,ברנשטיין אדוארד
5000 ,תל אביב - יפו ,1130 ,ברנשטין כהן
5000 ,תל אביב - יפו ,4043 ,ברסלבי יוסף
5000 ,תל אביב - יפו ,4615 ,ברסקי יהודה לייב
5000 ,תל אביב - יפו ,302 ,ברק
5000 ,תל אביב - יפו ,141 ,ברקאי שמואל
5000 ,תל אביב - יפו ,1045 ,ברקוביץ
5000 ,תל אביב - יפו ,110 ,ברש אשר
5000 ,תל אביב - יפו ,4147 ,ברתנא מרדכי
5000 ,תל אביב - יפו ,1832 ,בת גלים
5000 ,תל אביב - יפו ,319 ,בת חן
5000 ,תל אביב - יפו ,1854 ,בת ים
5000 ,תל אביב - יפו ,304 ,בת יפתח
5000 ,תל אביב - יפו ,466 ,בת מרים יוכבד
5000 ,תל אביב - יפו ,1947 ,בת עין
5000 ,תל אביב - יפו ,1621 ,בת עמי
5000 ,תל אביב - יפו ,2645 ,בת ציון
5000 ,תל אביב - יפו ,1743 ,בת שבע דה רוטשילד
5000 ,תל אביב - יפו ,483 ,בתיה
5000 ,תל אביב - יפו ,1203 ,גאולה
5000 ,תל אביב - יפו ,646 ,גארי ברתיני
5000 ,תל אביב - יפו ,2212 ,גבולות
5000 ,תל אביב - יפו ,2979 ,גבירטיג מרדכי
5000 ,תל אביב - יפו ,2818 ,גבעולים
5000 ,תל אביב - יפו ,1560 ,גבעון
5000 ,תל אביב - יפו ,2347 ,גבעת בן יהודה
5000 ,תל אביב - יפו ,944 ,גבעת המורה
5000 ,תל אביב - יפו ,6520 ,גבעת הפרחים
5000 ,תל אביב - יפו ,6700 ,גבעת הרצל
5000 ,תל אביב - יפו ,1571 ,גבעת התחמושת
5000 ,תל אביב - יפו ,6741 ,גבעת התמרים
5000 ,תל אביב - יפו ,2397 ,גבעת כ"ח
5000 ,תל אביב - יפו ,2632 ,גבעתי
5000 ,תל אביב - יפו ,1862 ,גברן חליל גברן
5000 ,תל אביב - יפו ,2836 ,גדיש
5000 ,תל אביב - יפו ,2713 ,גדליה
5000 ,תל אביב - יפו ,350 ,גדנ"ע
5000 ,תל אביב - יפו ,726 ,גדעון
5000 ,תל אביב - יפו ,1231 ,גדרה
5000 ,תל אביב - יפו ,2798 ,גואל
5000 ,תל אביב - יפו ,2573 ,גובר רבקה
5000 ,תל אביב - יפו ,2422 ,גוז'קוב
5000 ,תל אביב - יפו ,2553 ,גוטלובר
5000 ,תל אביב - יפו ,706 ,גוטליב
5000 ,תל אביב - יפו ,1392 ,גוטמאכר
5000 ,תל אביב - יפו ,3350 ,גוטמן נחום
5000 ,תל אביב - יפו ,1501 ,גולדברג
5000 ,תל אביב - יפו ,267 ,גולדברג לאה
5000 ,תל אביב - יפו ,1442 ,גולדמן נחום
5000 ,תל אביב - יפו ,2440 ,גולדפאדן
5000 ,תל אביב - יפו ,2302 ,גולומב
5000 ,תל אביב - יפו ,338 ,גולי אריתראה
5000 ,תל אביב - יפו ,337 ,גולי קניה
5000 ,תל אביב - יפו ,2970 ,גולינקין מרדכי
5000 ,תל אביב - יפו ,1401 ,גומא
5000 ,תל אביב - יפו ,2603 ,גונן
5000 ,תל אביב - יפו ,2853 ,גופר
5000 ,תל אביב - יפו ,908 ,גור יהודה
5000 ,תל אביב - יפו ,711 ,גורדון י ל
5000 ,תל אביב - יפו ,2426 ,גורי ישראל
5000 ,תל אביב - יפו ,4099 ,גורלסקי ברוך
5000 ,תל אביב - יפו ,1976 ,גורקי
5000 ,תל אביב - יפו ,1711 ,גוש 7040
5000 ,תל אביב - יפו ,2385 ,גוש 7062
5000 ,תל אביב - יפו ,2153 ,גוש 6992
5000 ,תל אביב - יפו ,2387 ,גוש 7063
5000 ,תל אביב - יפו ,2178 ,גוש 6993
5000 ,תל אביב - יפו ,1428 ,גוש 7004
5000 ,תל אביב - יפו ,1385 ,גוש 7005
5000 ,תל אביב - יפו ,1349 ,גוש 7006
5000 ,תל אביב - יפו ,1376 ,גוש 7008
5000 ,תל אביב - יפו ,1914 ,גוש 7048
5000 ,תל אביב - יפו ,1710 ,גוש 7019
5000 ,תל אביב - יפו ,2386 ,גוש 6969
5000 ,תל אביב - יפו ,529 ,גוש חלב
5000 ,תל אביב - יפו ,2659 ,גוש עציון
5000 ,תל אביב - יפו ,4510 ,גז מטילדה
5000 ,תל אביב - יפו ,213 ,גזית
5000 ,תל אביב - יפו ,719 ,גזר
5000 ,תל אביב - יפו ,191 ,גייגר
5000 ,תל אביב - יפו ,4038 ,גילוץ דבורה ומנחם
5000 ,תל אביב - יפו ,2516 ,גינוסר
5000 ,תל אביב - יפו ,2551 ,גינצבורג
5000 ,תל אביב - יפו ,2469 ,גיתית
5000 ,תל אביב - יפו ,2424 ,גלאנץ לייב
5000 ,תל אביב - יפו ,4534 ,גלבוע אמיר
5000 ,תל אביב - יפו ,4500 ,גלבלום אריה
5000 ,תל אביב - יפו ,2522 ,גלוסקא זכריה
5000 ,תל אביב - יפו ,941 ,גלוסקין
5000 ,תל אביב - יפו ,1402 ,גלזנר
5000 ,תל אביב - יפו ,3105 ,גלילי ישראל
5000 ,תל אביב - יפו ,1019 ,גליצנשטין
5000 ,תל אביב - יפו ,294 ,גליקסברג
5000 ,תל אביב - יפו ,632 ,גליקסון
5000 ,תל אביב - יפו ,4650 ,גלעזר אליהו
5000 ,תל אביב - יפו ,3003 ,גלעינית
5000 ,תל אביב - יפו ,3023 ,גמזו חיים דר'
5000 ,תל אביב - יפו ,6510 ,גני יהושע
5000 ,תל אביב - יפו ,6543 ,גני צהלה
5000 ,תל אביב - יפו ,2219 ,גנני
5000 ,תל אביב - יפו ,626 ,גנסין
5000 ,תל אביב - יפו ,208 ,גנתון
5000 ,תל אביב - יפו ,4089 ,גסטר משה
5000 ,תל אביב - יפו ,1799 ,געש
5000 ,תל אביב - יפו ,2146 ,גר צדק
5000 ,תל אביב - יפו ,1245 ,גרוזנברג
5000 ,תל אביב - יפו ,117 ,גרונימן
5000 ,תל אביב - יפו ,285 ,גרונר דב
5000 ,תל אביב - יפו ,1744 ,גרוסמן חייקה
5000 ,תל אביב - יפו ,3011 ,גרוסמן מאיר
5000 ,תל אביב - יפו ,1741 ,גרטרוד קראוס
5000 ,תל אביב - יפו ,457 ,גריזים
5000 ,תל אביב - יפו ,2466 ,גרינבוים
5000 ,תל אביב - יפו ,543 ,גרץ
5000 ,תל אביב - יפו ,4096 ,גרשום שלום
5000 ,תל אביב - יפו ,1270 ,גת רימון
5000 ,תל אביב - יפו ,4138 ,ד"ר כרמי יוגב
5000 ,תל אביב - יפו ,4513 ,ד"ר פלד רות
5000 ,תל אביב - יפו ,1972 ,דאנטה
5000 ,תל אביב - יפו ,1940 ,דב ממזריץ
5000 ,תל אביב - יפו ,4785 ,דבורה
5000 ,תל אביב - יפו ,367 ,דבורה הנביאה
5000 ,תל אביב - יפו ,480 ,דבורה עומר
5000 ,תל אביב - יפו ,2141 ,דבלין אלפרד
5000 ,תל אביב - יפו ,2163 ,דברי חיים
5000 ,תל אביב - יפו ,2840 ,דגן
5000 ,תל אביב - יפו ,1324 ,דגניה
5000 ,תל אביב - יפו ,810 ,דה האז
5000 ,תל אביב - יפו ,1042 ,דה וינצ'י לאונרדו
5000 ,תל אביב - יפו ,2353 ,דה מודינה אריה
5000 ,תל אביב - יפו ,1271 ,דה פיג'וטו
5000 ,תל אביב - יפו ,1958 ,דה פיליפ אדיס
5000 ,תל אביב - יפו ,1992 ,דואר שושנה
5000 ,תל אביב - יפו ,1034 ,דובנוב
5000 ,תל אביב - יפו ,1735 ,דוד הראובני
5000 ,תל אביב - יפו ,4605 ,דוד רחל (שרעבי)
5000 ,תל אביב - יפו ,1728 ,דודאים
5000 ,תל אביב - יפו ,2855 ,דוחן
5000 ,תל אביב - יפו ,2690 ,דוידקה
5000 ,תל אביב - יפו ,1038 ,דוליצקי
5000 ,תל אביב - יפו ,4536 ,דולצ'ין אריה
5000 ,תל אביב - יפו ,1354 ,דון-יחייא שבתי
5000 ,תל אביב - יפו ,1796 ,דונולו הרופא
5000 ,תל אביב - יפו ,2711 ,דורון
5000 ,תל אביב - יפו ,1000 ,דוש
5000 ,תל אביב - יפו ,2419 ,די רוסי
5000 ,תל אביב - יפו ,404 ,דיזנגוף
5000 ,תל אביב - יפו ,638 ,דיזנגוף סנטר
5000 ,תל אביב - יפו ,333 ,דילה רינה יוסף
5000 ,תל אביב - יפו ,2617 ,דימונה
5000 ,תל אביב - יפו ,2194 ,דימיטר פשב
5000 ,תל אביב - יפו ,471 ,דיסנצ'יק אריה
5000 ,תל אביב - יפו ,1624 ,דיצה
5000 ,תל אביב - יפו ,2442 ,דיק
5000 ,תל אביב - יפו ,1807 ,דישון
5000 ,תל אביב - יפו ,1302 ,דליה רביקוביץ'
5000 ,תל אביב - יפו ,2534 ,דם המכבים
5000 ,תל אביב - יפו ,1970 ,דני קיי
5000 ,תל אביב - יפו ,1211 ,דניאל
5000 ,תל אביב - יפו ,904 ,דנין
5000 ,תל אביב - יפו ,2710 ,דעואל
5000 ,תל אביב - יפו ,1016 ,דפנה
5000 ,תל אביב - יפו ,3009 ,דקל
5000 ,תל אביב - יפו ,4102 ,דר' מיכאלי יצחק
5000 ,תל אביב - יפו ,630 ,דרויאנוב
5000 ,תל אביב - יפו ,1988 ,דרור
5000 ,תל אביב - יפו ,293 ,דרזנר יחיאל
5000 ,תל אביב - יפו ,3022 ,דריוס מיו
5000 ,תל אביב - יפו ,2445 ,דריפוס
5000 ,תל אביב - יפו ,1048 ,דרך אליאב לובה
5000 ,תל אביב - יפו ,1555 ,דרך בגין
5000 ,תל אביב - יפו ,2412 ,דרך בן צבי
5000 ,תל אביב - יפו ,2766 ,דרך בר-לב חיים
5000 ,תל אביב - יפו ,2535 ,דרך דיין משה
5000 ,תל אביב - יפו ,2545 ,דרך הגבורה
5000 ,תל אביב - יפו ,2658 ,דרך ההגנה
5000 ,תל אביב - יפו ,2601 ,דרך הטייסים
5000 ,תל אביב - יפו ,2604 ,דרך השלום
5000 ,תל אביב - יפו ,2169 ,דרך יגאל ידין
5000 ,תל אביב - יפו ,1301 ,דרך יפו
5000 ,תל אביב - יפו ,1011 ,דרך נמיר מרדכי
5000 ,תל אביב - יפו ,2378 ,דרך שלמה
5000 ,תל אביב - יפו ,2443 ,דשבסקי
5000 ,תל אביב - יפו ,916 ,הא באייר
5000 ,תל אביב - יפו ,1714 ,האגוז
5000 ,תל אביב - יפו ,2714 ,האגמון
5000 ,תל אביב - יפו ,271 ,האוזנר דב
5000 ,תל אביב - יפו ,2579 ,האומנים
5000 ,תל אביב - יפו ,2862 ,האחים אל כוויתי
5000 ,תל אביב - יפו ,4046 ,האחים בילסקי
5000 ,תל אביב - יפו ,1523 ,האחים מסלאויטה
5000 ,תל אביב - יפו ,1825 ,האלומה
5000 ,תל אביב - יפו ,758 ,האלקושי
5000 ,תל אביב - יפו ,167 ,האמוראים
5000 ,תל אביב - יפו ,2313 ,האסיף
5000 ,תל אביב - יפו ,2544 ,האספרגוס
5000 ,תל אביב - יפו ,2717 ,האקליפטוס
5000 ,תל אביב - יפו ,1205 ,האר"י
5000 ,תל אביב - יפו ,1541 ,הארבעה
5000 ,תל אביב - יפו ,364 ,הארד
5000 ,תל אביב - יפו ,1840 ,הארון אל רשיד
5000 ,תל אביב - יפו ,1813 ,האשכולית
5000 ,תל אביב - יפו ,1838 ,האתרוג
5000 ,תל אביב - יפו ,108 ,הברון הירש
5000 ,תל אביב - יפו ,2716 ,הברוש
5000 ,תל אביב - יפו ,360 ,הברזל
5000 ,תל אביב - יפו ,444 ,הבשן
5000 ,תל אביב - יפו ,2646 ,הגבור האלמוני
5000 ,תל אביב - יפו ,2189 ,הגבורים
5000 ,תל אביב - יפו ,2253 ,הגדוד העברי
5000 ,תל אביב - יפו ,335 ,הגולן
5000 ,תל אביב - יפו ,1998 ,הגיחון
5000 ,תל אביב - יפו ,2680 ,הגייסות
5000 ,תל אביב - יפו ,1111 ,הגלבוע
5000 ,תל אביב - יפו ,2242 ,הגליל
5000 ,תל אביב - יפו ,447 ,הגלעד
5000 ,תל אביב - יפו ,1659 ,הגמנסיה העברית
5000 ,תל אביב - יפו ,1013 ,הגפן
5000 ,תל אביב - יפו ,2236 ,הגר"א
5000 ,תל אביב - יפו ,1771 ,הדגה
5000 ,תל אביב - יפו ,2415 ,הדגל
5000 ,תל אביב - יפו ,1656 ,הדואר
5000 ,תל אביב - יפו ,1753 ,הדולפין
5000 ,תל אביב - יפו ,1780 ,הדייגים
5000 ,תל אביב - יפו ,2542 ,הדמומית
5000 ,תל אביב - יפו ,527 ,הדסה
5000 ,תל אביב - יפו ,398 ,הדף היומי
5000 ,תל אביב - יפו ,236 ,הדר יוסף
5000 ,תל אביב - יפו ,1739 ,ההגה
5000 ,תל אביב - יפו ,1839 ,ההדס
5000 ,תל אביב - יפו ,756 ,הוברמן
5000 ,תל אביב - יפו ,2704 ,הודיה
5000 ,תל אביב - יפו ,608 ,הוז דב
5000 ,תל אביב - יפו ,1910 ,הולצברג שמחה
5000 ,תל אביב - יפו ,2733 ,הונא
5000 ,תל אביב - יפו ,124 ,הופיין
5000 ,תל אביב - יפו ,4094 ,הופמן יעקב
5000 ,תל אביב - יפו ,4664 ,הופנקו משה
5000 ,תל אביב - יפו ,2686 ,הורודצקי
5000 ,תל אביב - יפו ,408 ,הושע
5000 ,תל אביב - יפו ,839 ,הזוהר
5000 ,תל אביב - יפו ,3018 ,הזז חיים
5000 ,תל אביב - יפו ,1014 ,הזית
5000 ,תל אביב - יפו ,2421 ,הזמיר
5000 ,תל אביב - יפו ,1679 ,הזרם
5000 ,תל אביב - יפו ,2606 ,החבצלת
5000 ,תל אביב - יפו ,2657 ,החותרים
5000 ,תל אביב - יפו ,2451 ,החזיון
5000 ,תל אביב - יפו ,1802 ,החטה
5000 ,תל אביב - יפו ,2201 ,החלוצים
5000 ,תל אביב - יפו ,1885 ,החלפנים
5000 ,תל אביב - יפו ,2543 ,החצב
5000 ,תל אביב - יפו ,2407 ,החצוצרה
5000 ,תל אביב - יפו ,1725 ,החרוב
5000 ,תל אביב - יפו ,1325 ,החרמון
5000 ,תל אביב - יפו ,2414 ,החרמש
5000 ,תל אביב - יפו ,2541 ,החרצית
5000 ,תל אביב - יפו ,2245 ,החרש
5000 ,תל אביב - יפו ,1107 ,החשמונאים
5000 ,תל אביב - יפו ,1274 ,החשמל
5000 ,תל אביב - יפו ,352 ,הטית זין
5000 ,תל אביב - יפו ,2437 ,היהודי זיס
5000 ,תל אביב - יפו ,2712 ,היימן
5000 ,תל אביב - יפו ,2157 ,היינריך היינה
5000 ,תל אביב - יפו ,4554 ,הייקל יוסף
5000 ,תל אביב - יפו ,1315 ,היכל התלמוד
5000 ,תל אביב - יפו ,826 ,הילדסהיימר
5000 ,תל אביב - יפו ,1680 ,היסוד
5000 ,תל אביב - יפו ,2539 ,היסמין
5000 ,תל אביב - יפו ,1146 ,הירדן
5000 ,תל אביב - יפו ,461 ,הירקון
5000 ,תל אביב - יפו ,2454 ,הירשביין
5000 ,תל אביב - יפו ,707 ,הירשנברג
5000 ,תל אביב - יפו ,1641 ,היתד
5000 ,תל אביב - יפו ,1202 ,הכובשים
5000 ,תל אביב - יפו ,2187 ,הכוזרי
5000 ,תל אביב - יפו ,2425 ,הכינור
5000 ,תל אביב - יפו ,1678 ,הכישור
5000 ,תל אביב - יפו ,2531 ,הכלנית
5000 ,תל אביב - יפו ,834 ,הכנסת הגדולה
5000 ,תל אביב - יפו ,612 ,הכרם
5000 ,תל אביב - יפו ,1224 ,הכרמל
5000 ,תל אביב - יפו ,2161 ,הלה טוני
5000 ,תל אביב - יפו ,3020 ,הלוחמים
5000 ,תל אביב - יפו ,1803 ,הלוטוס
5000 ,תל אביב - יפו ,4100 ,הלוי ז'ק
5000 ,תל אביב - יפו ,2162 ,הלוי משה
5000 ,תל אביב - יפו ,1819 ,הלימון
5000 ,תל אביב - יפו ,1233 ,הלל הזקן
5000 ,תל אביב - יפו ,357 ,הלמד הא
5000 ,תל אביב - יפו ,932 ,הלסינקי
5000 ,תל אביב - יפו ,1811 ,הלענה
5000 ,תל אביב - יפו ,2411 ,הלפיד
5000 ,תל אביב - יפו ,508 ,הלפרין
5000 ,תל אביב - יפו ,2675 ,המאבק
5000 ,תל אביב - יפו ,270 ,המאירי אביגדור
5000 ,תל אביב - יפו ,2402 ,המבצעים
5000 ,תל אביב - יפו ,1884 ,המגדלור
5000 ,תל אביב - יפו ,1263 ,המגיד
5000 ,תל אביב - יפו ,1794 ,המגיד מדובנא
5000 ,תל אביב - יפו ,2410 ,המגן
5000 ,תל אביב - יפו ,1605 ,המחוגה
5000 ,תל אביב - יפו ,1133 ,המכבי
5000 ,תל אביב - יפו ,2800 ,המכבש
5000 ,תל אביב - יפו ,1524 ,המלאכה
5000 ,תל אביב - יפו ,1759 ,המלחים
5000 ,תל אביב - יפו ,735 ,המליץ
5000 ,תל אביב - יפו ,738 ,המלך ג'ורג'
5000 ,תל אביב - יפו ,1660 ,המלך חירם
5000 ,תל אביב - יפו ,1676 ,המנוע
5000 ,תל אביב - יפו ,2376 ,המנור
5000 ,תל אביב - יפו ,1554 ,המסגר
5000 ,תל אביב - יפו ,2679 ,המערכה
5000 ,תל אביב - יפו ,2462 ,המפעל
5000 ,תל אביב - יפו ,1765 ,המפרש
5000 ,תל אביב - יפו ,303 ,המצביאים
5000 ,תל אביב - יפו ,1387 ,המצודה
5000 ,תל אביב - יפו ,2409 ,המצפן
5000 ,תל אביב - יפו ,1525 ,המקצוע
5000 ,תל אביב - יפו ,1342 ,המרד
5000 ,תל אביב - יפו ,1833 ,המרפא
5000 ,תל אביב - יפו ,2463 ,המרץ
5000 ,תל אביב - יפו ,2206 ,המשביר
5000 ,תל אביב - יפו ,1764 ,המשוט
5000 ,תל אביב - יפו ,1606 ,המשור
5000 ,תל אביב - יפו ,2405 ,המשלטים
5000 ,תל אביב - יפו ,130 ,המשמר האזרחי
5000 ,תל אביב - יפו ,840 ,המשנה
5000 ,תל אביב - יפו ,6530 ,המשתלה
5000 ,תל אביב - יפו ,132 ,המתנדב
5000 ,תל אביב - יפו ,729 ,הנביאים
5000 ,תל אביב - יפו ,2240 ,הנגב
5000 ,תל אביב - יפו ,1603 ,הנגרים
5000 ,תל אביב - יפו ,131 ,הנוטר
5000 ,תל אביב - יפו ,1834 ,הנופש
5000 ,תל אביב - יפו ,365 ,הנחושת
5000 ,תל אביב - יפו ,2533 ,הניצנים
5000 ,תל אביב - יפו ,1546 ,הנצי"ב
5000 ,תל אביב - יפו ,2568 ,הנרקיס
5000 ,תל אביב - יפו ,841 ,הנשיאים
5000 ,תל אביב - יפו ,1139 ,הס משה
5000 ,תל אביב - יפו ,1946 ,הסבא משפולה
5000 ,תל אביב - יפו ,166 ,הסבוראים
5000 ,תל אביב - יפו ,2377 ,הסדנה
5000 ,תל אביב - יפו ,2525 ,הסוללים
5000 ,תל אביב - יפו ,2681 ,הסיירים
5000 ,תל אביב - יפו ,1800 ,הסנה
5000 ,תל אביב - יפו ,1761 ,הספינה
5000 ,תל אביב - יפו ,1114 ,העבודה
5000 ,תל אביב - יפו ,2423 ,העוגב
5000 ,תל אביב - יפו ,1754 ,העוגן
5000 ,תל אביב - יפו ,2216 ,העליה
5000 ,תל אביב - יפו ,1707 ,העליה השניה
5000 ,תל אביב - יפו ,2375 ,העמל
5000 ,תל אביב - יפו ,1842 ,הערבה
5000 ,תל אביב - יפו ,1722 ,הערמון
5000 ,תל אביב - יפו ,2640 ,הפורצים
5000 ,תל אביב - יפו ,2460 ,הפטיש
5000 ,תל אביב - יפו ,1542 ,הפטמן
5000 ,תל אביב - יפו ,1677 ,הפלך
5000 ,תל אביב - יפו ,2644 ,הפלמ"ח
5000 ,תל אביב - יפו ,1702 ,הפנינים
5000 ,תל אביב - יפו ,2529 ,הפרגים
5000 ,תל אביב - יפו ,525 ,הפרדס
5000 ,תל אביב - יפו ,2663 ,הפרטיזן היהודי
5000 ,תל אביב - יפו ,320 ,הפרסה
5000 ,תל אביב - יפו ,2607 ,הצבי
5000 ,תל אביב - יפו ,2530 ,הצבר
5000 ,תל אביב - יפו ,1751 ,הצדף
5000 ,תל אביב - יפו ,1896 ,הצורפים
5000 ,תל אביב - יפו ,2537 ,הצלף
5000 ,תל אביב - יפו ,379 ,הצנחנים
5000 ,תל אביב - יפו ,6602 ,הצפון החדש
5000 ,תל אביב - יפו ,6590 ,הצפון הישן
5000 ,תל אביב - יפו ,2540 ,הצפורן
5000 ,תל אביב - יפו ,1526 ,הצפירה
5000 ,תל אביב - יפו ,1719 ,הצרי
5000 ,תל אביב - יפו ,1766 ,הקברניט
5000 ,תל אביב - יפו ,2326 ,הקונגרס
5000 ,תל אביב - יפו ,1805 ,הקיקיון
5000 ,תל אביב - יפו ,522 ,הקליר
5000 ,תל אביב - יפו ,2403 ,הקלעים
5000 ,תל אביב - יפו ,2394 ,הקנאים
5000 ,תל אביב - יפו ,6690 ,הקריה
5000 ,תל אביב - יפו ,2417 ,הקרן
5000 ,תל אביב - יפו ,2408 ,הקשת
5000 ,תל אביב - יפו ,448 ,הר נבו
5000 ,תל אביב - יפו ,1260 ,הר סיני
5000 ,תל אביב - יפו ,2214 ,הרא"ש
5000 ,תל אביב - יפו ,2332 ,הראב"ע
5000 ,תל אביב - יפו ,353 ,הראל
5000 ,תל אביב - יפו ,4118 ,הרב באזוב דוד
5000 ,תל אביב - יפו ,2956 ,הרב בידאני עובדיה
5000 ,תל אביב - יפו ,2865 ,הרב גביזון חי
5000 ,תל אביב - יפו ,1005 ,הרב גורן שלמה
5000 ,תל אביב - יפו ,2025 ,הרב הלר חיים
5000 ,תל אביב - יפו ,1438 ,הרב הרצוג
5000 ,תל אביב - יפו ,1436 ,הרב טולידנו
5000 ,תל אביב - יפו ,2863 ,הרב טייב יצחק
5000 ,תל אביב - יפו ,4157 ,הרב יעקב טראב
5000 ,תל אביב - יפו ,847 ,הרב ניסים
5000 ,תל אביב - יפו ,1394 ,הרב עוזיאל
5000 ,תל אביב - יפו ,828 ,הרב עמיאל
5000 ,תל אביב - יפו ,822 ,הרב פרידמן
5000 ,תל אביב - יפו ,2233 ,הרב פרנקל
5000 ,תל אביב - יפו ,4624 ,הרב צוברי יוסף
5000 ,תל אביב - יפו ,820 ,הרב צירלסון
5000 ,תל אביב - יפו ,1204 ,הרב קוק
5000 ,תל אביב - יפו ,2685 ,הרב רייפמן
5000 ,תל אביב - יפו ,2973 ,הרב רפאל אנקאווא
5000 ,תל אביב - יפו ,1626 ,הרבי מבכרך
5000 ,תל אביב - יפו ,1949 ,הרבי מויטבסק
5000 ,תל אביב - יפו ,1948 ,הרבי מלילוב
5000 ,תל אביב - יפו ,1936 ,הרבי מפשיסחא
5000 ,תל אביב - יפו ,1962 ,הרבי מקוטוב
5000 ,תל אביב - יפו ,1950 ,הרבי מקוצק
5000 ,תל אביב - יפו ,1951 ,הרבי מקרלין
5000 ,תל אביב - יפו ,221 ,הרבסט קרול
5000 ,תל אביב - יפו ,307 ,הרדוף
5000 ,תל אביב - יפו ,4508 ,הרה"ג מחבוב יחיא
5000 ,תל אביב - יפו ,336 ,הרוגי מלכות
5000 ,תל אביב - יפו ,2619 ,הרודיון
5000 ,תל אביב - יפו ,2797 ,הרותם
5000 ,תל אביב - יפו ,115 ,הרטגלס
5000 ,תל אביב - יפו ,2852 ,הרטוב
5000 ,תל אביב - יפו ,1012 ,הרימון
5000 ,תל אביב - יפו ,1557 ,הרכב
5000 ,תל אביב - יפו ,2687 ,הרכבי אברהם
5000 ,תל אביב - יפו ,1269 ,הרכבת
5000 ,תל אביב - יפו ,184 ,הרכש
5000 ,תל אביב - יפו ,4665 ,הרמלין יוסף
5000 ,תל אביב - יפו ,2744 ,הרן
5000 ,תל אביב - יפו ,4608 ,הרנ"ה
5000 ,תל אביב - יפו ,4075 ,הרץ יוסף צבי
5000 ,תל אביב - יפו ,2215 ,הרצל
5000 ,תל אביב - יפו ,2972 ,הרצפלד אברהם
5000 ,תל אביב - יפו ,2528 ,הרקפת
5000 ,תל אביב - יפו ,103 ,הררי
5000 ,תל אביב - יפו ,1633 ,הש"ך
5000 ,תל אביב - יפו ,1234 ,השומר
5000 ,תל אביב - יפו ,2243 ,השומרון
5000 ,תל אביב - יפו ,943 ,השופט נופך
5000 ,תל אביב - יפו ,727 ,השופטים
5000 ,תל אביב - יפו ,2406 ,השופר
5000 ,תל אביב - יפו ,2203 ,השוק
5000 ,תל אביב - יפו ,2204 ,השוק סמ
5000 ,תל אביב - יפו ,315 ,השושנים
5000 ,תל אביב - יפו ,1752 ,השחף
5000 ,תל אביב - יפו ,477 ,השחקנים
5000 ,תל אביב - יפו ,1259 ,השחר
5000 ,תל אביב - יפו ,1827 ,השיבולת
5000 ,תל אביב - יפו ,2719 ,השיטה
5000 ,תל אביב - יפו ,2404 ,השיירות
5000 ,תל אביב - יפו ,2312 ,השילוח
5000 ,תל אביב - יפו ,1822 ,השיפון
5000 ,תל אביב - יפו ,2732 ,השכל
5000 ,תל אביב - יפו ,441 ,השל"ה
5000 ,תל אביב - יפו ,2674 ,השלושה
5000 ,תל אביב - יפו ,1340 ,השנית
5000 ,תל אביב - יפו ,1821 ,השעורה
5000 ,תל אביב - יפו ,1527 ,השפלה
5000 ,תל אביב - יפו ,1804 ,השקמה
5000 ,תל אביב - יפו ,2441 ,השרביט
5000 ,תל אביב - יפו ,2241 ,השרון
5000 ,תל אביב - יפו ,1015 ,התאנה
5000 ,תל אביב - יפו ,1238 ,התבור
5000 ,תל אביב - יפו ,1763 ,התורן
5000 ,תל אביב - יפו ,1639 ,התחיה
5000 ,תל אביב - יפו ,6658 ,התחנה המרכזית החדשה
5000 ,תל אביב - יפו ,6659 ,התחנה המרכזית הישנה
5000 ,תל אביב - יפו ,2346 ,התיבונים
5000 ,תל אביב - יפו ,1326 ,התלמי יהושע
5000 ,תל אביב - יפו ,1010 ,התמר
5000 ,תל אביב - יפו ,165 ,התנאים
5000 ,תל אביב - יפו ,2461 ,התנופה
5000 ,תל אביב - יפו ,504 ,התניא
5000 ,תל אביב - יפו ,1746 ,התעודה האדומה
5000 ,תל אביב - יפו ,402 ,התערוכה
5000 ,תל אביב - יפו ,1528 ,התעשיה
5000 ,תל אביב - יפו ,2778 ,התקוה
5000 ,תל אביב - יפו ,1638 ,התקומה
5000 ,תל אביב - יפו ,2668 ,התשעים ושלוש
5000 ,תל אביב - יפו ,4509 ,ואלין משה
5000 ,תל אביב - יפו ,2667 ,ווג'ווד
5000 ,תל אביב - יפו ,2566 ,וולמן יהודה
5000 ,תל אביב - יפו ,2255 ,וולפסון
5000 ,תל אביב - יפו ,520 ,וורמיזה
5000 ,תל אביב - יפו ,535 ,ויזל
5000 ,תל אביב - יפו ,2223 ,ויטל חיים
5000 ,תל אביב - יפו ,4740 ,ויטלה מאוריציו
5000 ,תל אביב - יפו ,4687 ,ויטנברג יצחק
5000 ,תל אביב - יפו ,226 ,וייז ג'ורג'
5000 ,תל אביב - יפו ,1745 ,ויינר זאב
5000 ,תל אביב - יפו ,3113 ,ויינשל
5000 ,תל אביב - יפו ,542 ,וייס אייזיק הירש
5000 ,תל אביב - יפו ,292 ,וייס יעקב
5000 ,תל אביב - יפו ,2999 ,ויל קורט
5000 ,תל אביב - יפו ,2738 ,וילון
5000 ,תל אביב - יפו ,506 ,וילנא
5000 ,תל אביב - יפו ,4621 ,וילנסקי משה
5000 ,תל אביב - יפו ,1505 ,וילסון
5000 ,תל אביב - יפו ,2639 ,וינגייט
5000 ,תל אביב - יפו ,4733 ,וינשטיין נחום
5000 ,תל אביב - יפו ,387 ,ויסבורג חיים
5000 ,תל אביב - יפו ,910 ,ויסוצקי
5000 ,תל אביב - יפו ,4104 ,ויסר חנה ויעקב
5000 ,תל אביב - יפו ,829 ,ויצמן
5000 ,תל אביב - יפו ,1973 ,ויקטור הוגו
5000 ,תל אביב - יפו ,1300 ,ויקי שירן
5000 ,תל אביב - יפו ,455 ,ויתקין
5000 ,תל אביב - יפו ,2147 ,וכטנגוב
5000 ,תל אביב - יפו ,1969 ,ולבה יואל
5000 ,תל אביב - יפו ,361 ,ולנברג ראול
5000 ,תל אביב - יפו ,1808 ,ולנסיה
5000 ,תל אביב - יפו ,4090 ,ונטורה משה
5000 ,תל אביב - יפו ,1612 ,וסרמן יעקב
5000 ,תל אביב - יפו ,821 ,ועידת קטוביץ
5000 ,תל אביב - יפו ,4549 ,וקנין רפי
5000 ,תל אביב - יפו ,1124 ,ורבורג
5000 ,תל אביב - יפו ,2725 ,ורד
5000 ,תל אביב - יפו ,2709 ,ורדיאל
5000 ,תל אביב - יפו ,2439 ,ורשבסקי
5000 ,תל אביב - יפו ,2724 ,ותיק
5000 ,תל אביב - יפו ,456 ,ז'בוטינסקי
5000 ,תל אביב - יפו ,604 ,ז'ורס ז'אן
5000 ,תל אביב - יפו ,1699 ,ז'קלין כהנוב
5000 ,תל אביב - יפו ,277 ,זאב
5000 ,תל אביב - יפו ,4162 ,זאבי רחבעם (גנדי)
5000 ,תל אביב - יפו ,2750 ,זבד
5000 ,תל אביב - יפו ,2202 ,זבולון
5000 ,תל אביב - יפו ,1738 ,זהבי דוד
5000 ,תל אביב - יפו ,1007 ,זהרירה חריפאי
5000 ,תל אביב - יפו ,2429 ,זולצר
5000 ,תל אביב - יפו ,848 ,זטורי משה
5000 ,תל אביב - יפו ,2438 ,זיגלבוים
5000 ,תל אביב - יפו ,1043 ,זיו רבקה
5000 ,תל אביב - יפו ,2446 ,זייד אלכסנדר
5000 ,תל אביב - יפו ,2504 ,זימן
5000 ,תל אביב - יפו ,4588 ,זינגר בשביס יצחק
5000 ,תל אביב - יפו ,4076 ,זיע"א יוסף חיים
5000 ,תל אביב - יפו ,1782 ,זיקים
5000 ,תל אביב - יפו ,239 ,זכות משה
5000 ,תל אביב - יפו ,938 ,זכרון יעקב
5000 ,תל אביב - יפו ,1857 ,זכרון קדושים
5000 ,תל אביב - יפו ,405 ,זכריה
5000 ,תל אביב - יפו ,2536 ,זכריני
5000 ,תל אביב - יפו ,1666 ,זלדה
5000 ,תל אביב - יפו ,936 ,זלוציסטי
5000 ,תל אביב - יפו ,509 ,זלטופולסקי
5000 ,תל אביב - יפו ,4595 ,זליבנסקי
5000 ,תל אביב - יפו ,278 ,זליג
5000 ,תל אביב - יפו ,4780 ,זליגמן מקס
5000 ,תל אביב - יפו ,4641 ,זמיר עמנואל
5000 ,תל אביב - יפו ,742 ,זמנהוף
5000 ,תל אביב - יפו ,837 ,זמר חנה
5000 ,תל אביב - יפו ,449 ,זנגביל
5000 ,תל אביב - יפו ,4647 ,זעירא מרדכי
5000 ,תל אביב - יפו ,4691 ,זק"ש נלי
5000 ,תל אביב - יפו ,1208 ,זרובבל
5000 ,תל אביב - יפו ,465 ,זרובבל יעקב
5000 ,תל אביב - יפו ,2706 ,זרח
5000 ,תל אביב - יפו ,4769 ,זריצקי יוסף
5000 ,תל אביב - יפו ,2848 ,זרעים
5000 ,תל אביב - יפו ,290 ,חביב אבשלום
5000 ,תל אביב - יפו ,1967 ,חביבי אמיל
5000 ,תל אביב - יפו ,411 ,חבקוק
5000 ,תל אביב - יפו ,2158 ,חבר הלאומים
5000 ,תל אביב - יפו ,939 ,חברה חדשה
5000 ,תל אביב - יפו ,1141 ,חברון
5000 ,תל אביב - יפו ,1339 ,חברת ש"ס
5000 ,תל אביב - יפו ,1280 ,חבשוש חיים
5000 ,תל אביב - יפו ,432 ,חגי
5000 ,תל אביב - יפו ,4079 ,חדד סעדיא
5000 ,תל אביב - יפו ,930 ,חדרה
5000 ,תל אביב - יפו ,624 ,חובבי ציון
5000 ,תל אביב - יפו ,2333 ,חובות הלבבות
5000 ,תל אביב - יפו ,531 ,חולדה
5000 ,תל אביב - יפו ,1518 ,חומה ומגדל
5000 ,תל אביב - יפו ,812 ,חוני המעגל
5000 ,תל אביב - יפו ,1393 ,חוסן
5000 ,תל אביב - יפו ,640 ,חוף גורדון
5000 ,תל אביב - יפו ,242 ,חופשי דוד
5000 ,תל אביב - יפו ,1404 ,חזון איש
5000 ,תל אביב - יפו ,4542 ,חזן יעקב
5000 ,תל אביב - יפו ,2230 ,חזנוביץ
5000 ,תל אביב - יפו ,1643 ,חזק
5000 ,תל אביב - יפו ,2383 ,חזקיהו המלך
5000 ,תל אביב - יפו ,128 ,חי"ש
5000 ,תל אביב - יפו ,1952 ,חידושי הרי"ם
5000 ,תל אביב - יפו ,2337 ,חיוג יהודה
5000 ,תל אביב - יפו ,1115 ,חיות פרץ
5000 ,תל אביב - יפו ,903 ,חיותמן
5000 ,תל אביב - יפו ,2518 ,חיי אדם
5000 ,תל אביב - יפו ,1727 ,חייבי משה
5000 ,תל אביב - יפו ,1123 ,חיים ואלישע
5000 ,תל אביב - יפו ,134 ,חיל המשמר
5000 ,תל אביב - יפו ,2465 ,חיל השריון
5000 ,תל אביב - יפו ,334 ,חילו יצחק
5000 ,תל אביב - יפו ,3002 ,חיננית
5000 ,תל אביב - יפו ,750 ,חיסין
5000 ,תל אביב - יפו ,2821 ,חירותנו
5000 ,תל אביב - יפו ,284 ,חכים אליהו
5000 ,תל אביב - יפו ,4124 ,חכם שמעון
5000 ,תל אביב - יפו ,2367 ,חכמי אתונה
5000 ,תל אביב - יפו ,1512 ,חכמי דוד
5000 ,תל אביב - יפו ,2364 ,חכמי לובלין
5000 ,תל אביב - יפו ,1028 ,חכמי קירואן
5000 ,תל אביב - יפו ,4080 ,חלד יוסף
5000 ,תל אביב - יפו ,3001 ,חלמית
5000 ,תל אביב - יפו ,1424 ,חלץ
5000 ,תל אביב - יפו ,478 ,חנה מרון
5000 ,תל אביב - יפו ,2788 ,חנוך
5000 ,תל אביב - יפו ,1671 ,חנינא בן דוסא
5000 ,תל אביב - יפו ,1653 ,חנינא בן תרדיון
5000 ,תל אביב - יפו ,2669 ,חניתה
5000 ,תל אביב - יפו ,2994 ,חננאל אשר
5000 ,תל אביב - יפו ,1379 ,חנניה
5000 ,תל אביב - יפו ,912 ,חנקין
5000 ,תל אביב - יפו ,2148 ,חסידי האומות
5000 ,תל אביב - יפו ,2513 ,חפץ חיים
5000 ,תל אביב - יפו ,4754 ,חפץ ישה
5000 ,תל אביב - יפו ,2705 ,חפר
5000 ,תל אביב - יפו ,262 ,חצור
5000 ,תל אביב - יפו ,1604 ,חצרים
5000 ,תל אביב - יפו ,2670 ,חרוץ
5000 ,תל אביב - יפו ,1895 ,חריף אייזיק
5000 ,תל אביב - יפו ,2835 ,חשון
5000 ,תל אביב - יפו ,502 ,חת"ם סופר
5000 ,תל אביב - יפו ,174 ,טאגור רבינדרנארט
5000 ,תל אביב - יפו ,2560 ,טביוב
5000 ,תל אביב - יפו ,347 ,טבנקין יצחק
5000 ,תל אביב - יפו ,634 ,טבריה
5000 ,תל אביב - יפו ,1531 ,טברסקי
5000 ,תל אביב - יפו ,2833 ,טבת
5000 ,תל אביב - יפו ,1887 ,טג'יר שמואל וסולטנה
5000 ,תל אביב - יפו ,452 ,טהון יהושע
5000 ,תל אביב - יפו ,1957 ,טוביה הרופא
5000 ,תל אביב - יפו ,339 ,טובים עמנואל
5000 ,תל אביב - יפו ,2032 ,טוכולסקי קורט
5000 ,תל אביב - יפו ,1823 ,טולוז
5000 ,תל אביב - יפו ,388 ,טולקובסקי
5000 ,תל אביב - יפו ,755 ,טוסקניני
5000 ,תל אביב - יפו ,2620 ,טור מלכא
5000 ,תל אביב - יפו ,120 ,טורטשינר
5000 ,תל אביב - יפו ,2348 ,טורי זהב
5000 ,תל אביב - יפו ,299 ,טורקוב זיגמונד
5000 ,תל אביב - יפו ,1502 ,טיומקין
5000 ,תל אביב - יפו ,619 ,טייגר היל
5000 ,תל אביב - יפו ,1906 ,טימן יעקב
5000 ,תל אביב - יפו ,2519 ,טירת צבי
5000 ,תל אביב - יפו ,2793 ,טלמון
5000 ,תל אביב - יפו ,1770 ,טרומן הרי
5000 ,תל אביב - יפו ,1128 ,טרומפלדור
5000 ,תל אביב - יפו ,343 ,טריטש
5000 ,תל אביב - יפו ,2796 ,טרפון
5000 ,תל אביב - יפו ,2691 ,טשרנא
5000 ,תל אביב - יפו ,1140 ,טשרניחובסקי
5000 ,תל אביב - יפו ,2794 ,יאיר
5000 ,תל אביב - יפו ,1322 ,יבנאל
5000 ,תל אביב - יפו ,1250 ,יבנה
5000 ,תל אביב - יפו ,2637 ,יגור
5000 ,תל אביב - יפו ,1529 ,יגיע כפיים
5000 ,תל אביב - יפו ,228 ,יד המעביר
5000 ,תל אביב - יפו ,1530 ,יד חרוצים
5000 ,תל אביב - יפו ,2338 ,ידעיה הפניני
5000 ,תל אביב - יפו ,614 ,יהואש
5000 ,תל אביב - יפו ,1328 ,יהודה החסיד
5000 ,תל אביב - יפו ,1986 ,יהודה הימית
5000 ,תל אביב - יפו ,1101 ,יהודה הלוי
5000 ,תל אביב - יפו ,814 ,יהודה המכבי
5000 ,תל אביב - יפו ,181 ,יהודה הנשיא
5000 ,תל אביב - יפו ,4501 ,יהודה הצעיר
5000 ,תל אביב - יפו ,1993 ,יהודה מרגוזה
5000 ,תל אביב - יפו ,311 ,יהונתן
5000 ,תל אביב - יפו ,426 ,יהושע בן נון
5000 ,תל אביב - יפו ,2005 ,יהושע בן פרחיה
5000 ,תל אביב - יפו ,536 ,יהל"ל
5000 ,תל אביב - יפו ,314 ,יואב
5000 ,תל אביב - יפו ,414 ,יואל הנביא
5000 ,תל אביב - יפו ,528 ,יודפת
5000 ,תל אביב - יפו ,1257 ,יוחנן בן זכאי
5000 ,תל אביב - יפו ,806 ,יוחנן הגדי
5000 ,תל אביב - יפו ,420 ,יוחנן הורקנוס
5000 ,תל אביב - יפו ,1253 ,יוחנן הסנדלר
5000 ,תל אביב - יפו ,4671 ,יוחננוף כהן שמואל
5000 ,תל אביב - יפו ,1214 ,יום טוב
5000 ,תל אביב - יפו ,2331 ,יון מצולה
5000 ,תל אביב - יפו ,2769 ,יונדב
5000 ,תל אביב - יפו ,1201 ,יונה הנביא
5000 ,תל אביב - יפו ,3101 ,יוניצ'מן
5000 ,תל אביב - יפו ,807 ,יונתן הופסי
5000 ,תל אביב - יפו ,513 ,יוסט
5000 ,תל אביב - יפו ,2006 ,יוסי בן יוסי
5000 ,תל אביב - יפו ,1894 ,יוסי ידין
5000 ,תל אביב - יפו ,757 ,יוסף אליהו
5000 ,תל אביב - יפו ,241 ,יוסף האפרתי
5000 ,תל אביב - יפו ,1252 ,יוסף הנשיא
5000 ,תל אביב - יפו ,1673 ,יועזר איש הבירה
5000 ,תל אביב - יפו ,2837 ,יורה
5000 ,תל אביב - יפו ,2847 ,יורם
5000 ,תל אביב - יפו ,1343 ,יורם קניוק
5000 ,תל אביב - יפו ,2728 ,יזהר
5000 ,תל אביב - יפו ,415 ,יחזקאל
5000 ,תל אביב - יפו ,1369 ,יחיאלי
5000 ,תל אביב - יפו ,2723 ,יחיעם
5000 ,תל אביב - יפו ,1856 ,יטבת
5000 ,תל אביב - יפו ,2145 ,ילדי טהרן
5000 ,תל אביב - יפו ,919 ,ילין דוד
5000 ,תל אביב - יפו ,1549 ,ילין מור נתן
5000 ,תל אביב - יפו ,2520 ,ילקוט הרועים
5000 ,תל אביב - יפו ,1829 ,ים סוף
5000 ,תל אביב - יפו ,2841 ,ימין
5000 ,תל אביב - יפו ,2526 ,ינון
5000 ,תל אביב - יפו ,2547 ,יניב
5000 ,תל אביב - יפו ,2250 ,יסוד המעלה
5000 ,תל אביב - יפו ,1820 ,יסעור
5000 ,תל אביב - יפו ,1242 ,יעב"ץ
5000 ,תל אביב - יפו ,4033 ,יעוד ודרך
5000 ,תל אביב - יפו ,746 ,יעל
5000 ,תל אביב - יפו ,1816 ,יעקב ג'ורג' חנאניא
5000 ,תל אביב - יפו ,4756 ,יעקב חודורוב
5000 ,תל אביב - יפו ,4760 ,יעקב שבתאי
5000 ,תל אביב - יפו ,4544 ,יעקובוב אוריאל
5000 ,תל אביב - יפו ,3104 ,יערי מאיר
5000 ,תל אביב - יפו ,1267 ,יפה בצלאל
5000 ,תל אביב - יפו ,453 ,יפה נוף
5000 ,תל אביב - יפו ,6504 ,יפו העתיקה
5000 ,תל אביב - יפו ,211 ,יפעת
5000 ,תל אביב - יפו ,2546 ,יפרח
5000 ,תל אביב - יפו ,1994 ,יפת
5000 ,תל אביב - יפו ,2612 ,יפתח
5000 ,תל אביב - יפו ,4009 ,יצחק (זיקו) גרציאני
5000 ,תל אביב - יפו ,2562 ,יצחק שדה
5000 ,תל אביב - יפו ,2660 ,יציאת אירופה
5000 ,תל אביב - יפו ,212 ,יציץ
5000 ,תל אביב - יפו ,2792 ,יקותיאל
5000 ,תל אביב - יפו ,186 ,יקותיאלי יוסף
5000 ,תל אביב - יפו ,1341 ,יקינתון
5000 ,תל אביב - יפו ,2507 ,יקנעם
5000 ,תל אביב - יפו ,3281 ,ירבלום מרק
5000 ,תל אביב - יפו ,832 ,ירושלמי
5000 ,תל אביב - יפו ,445 ,יריחו
5000 ,תל אביב - יפו ,403 ,ירמיהו
5000 ,תל אביב - יפו ,2345 ,יש"ר מקנדיא
5000 ,תל אביב - יפו ,609 ,ישורון
5000 ,תל אביב - יפו ,1399 ,ישיבת וולוז'ין
5000 ,תל אביב - יפו ,1222 ,ישכון
5000 ,תל אביב - יפו ,431 ,ישעיהו
5000 ,תל אביב - יפו ,4159 ,ישעיהו ליבוביץ
5000 ,תל אביב - יפו ,4153 ,ישראל טל
5000 ,תל אביב - יפו ,2306 ,ישראל מסלנט
5000 ,תל אביב - יפו ,1942 ,ישראל מריז'ין
5000 ,תל אביב - יפו ,1956 ,ישראלי יצחק
5000 ,תל אביב - יפו ,709 ,ישראליס
5000 ,תל אביב - יפו ,2736 ,ישרש
5000 ,תל אביב - יפו ,4082 ,יששכרוב חי
5000 ,תל אביב - יפו ,401 ,כ"ג יורדי הסירה
5000 ,תל אביב - יפו ,4093 ,כ"ץ בן ציון
5000 ,תל אביב - יפו ,1945 ,כ"ץ יעקב יוסף
5000 ,תל אביב - יפו ,2789 ,כביר
5000 ,תל אביב - יפו ,704 ,כהן הרמן
5000 ,תל אביב - יפו ,4603 ,כהן יעקב
5000 ,תל אביב - יפו ,2388 ,כהן עופר
5000 ,תל אביב - יפו ,942 ,כהנשטם
5000 ,תל אביב - יפו ,2314 ,כוכבי יצחק
5000 ,תל אביב - יפו ,382 ,כורזים
5000 ,תל אביב - יפו ,1507 ,כורש
5000 ,תל אביב - יפו ,6056 ,כיכר אבנר שאקי
5000 ,תל אביב - יפו ,6029 ,כיכר אבריאל אהוד
5000 ,תל אביב - יפו ,6000 ,כיכר אורלנד יעקב
5000 ,תל אביב - יפו ,6059 ,כיכר ברכה צפירה
5000 ,תל אביב - יפו ,6054 ,כיכר דמארי שושנה
5000 ,תל אביב - יפו ,6024 ,כיכר הראל איסר
5000 ,תל אביב - יפו ,6058 ,כיכר יוסל ברגנר
5000 ,תל אביב - יפו ,6057 ,כיכר יוסף גבע
5000 ,תל אביב - יפו ,6023 ,כיכר ספורטה רפאל
5000 ,תל אביב - יפו ,375 ,כיסופים
5000 ,תל אביב - יפו ,6037 ,ככר 2401
5000 ,תל אביב - יפו ,6028 ,ככר 3944
5000 ,תל אביב - יפו ,6055 ,ככר אבנור חנה
5000 ,תל אביב - יפו ,6032 ,ככר אופנבך ז'ק
5000 ,תל אביב - יפו ,6052 ,ככר אורבוך ג'ניה
5000 ,תל אביב - יפו ,6044 ,ככר אורי אביבה
5000 ,תל אביב - יפו ,6036 ,ככר אנה פרנק
5000 ,תל אביב - יפו ,6001 ,ככר ב' בנובמבר
5000 ,תל אביב - יפו ,6003 ,ככר ביאליק
5000 ,תל אביב - יפו ,6033 ,ככר בלוך ארנסט
5000 ,תל אביב - יפו ,6008 ,ככר ברנשטין לאונר
5000 ,תל אביב - יפו ,6041 ,ככר גורדון אורי
5000 ,תל אביב - יפו ,6030 ,ככר גרשוין ג'ורג'
5000 ,תל אביב - יפו ,6004 ,ככר דיזנגוף
5000 ,תל אביב - יפו ,6045 ,ככר דיק יוסף ג'ורג'
5000 ,תל אביב - יפו ,6039 ,ככר דר' דג'אני פואד
5000 ,תל אביב - יפו ,6005 ,ככר הבימה
5000 ,תל אביב - יפו ,6006 ,ככר ההסתדרות
5000 ,תל אביב - יפו ,6007 ,ככר היל
5000 ,תל אביב - יפו ,6002 ,ככר הכנסת
5000 ,תל אביב - יפו ,6009 ,ככר המדינה
5000 ,תל אביב - יפו ,6010 ,ככר המושבות
5000 ,תל אביב - יפו ,6011 ,ככר המלך אלברט
5000 ,תל אביב - יפו ,6012 ,ככר הסוכנות
5000 ,תל אביב - יפו ,6013 ,ככר השוטר
5000 ,תל אביב - יפו ,6034 ,ככר וולך יונה
5000 ,תל אביב - יפו ,6014 ,ככר חסידי האומות
5000 ,תל אביב - יפו ,6025 ,ככר יצחק רבין
5000 ,תל אביב - יפו ,6027 ,ככר ישורון אבות
5000 ,תל אביב - יפו ,6026 ,ככר כהן חיים
5000 ,תל אביב - יפו ,6038 ,ככר לביא אריק
5000 ,תל אביב - יפו ,6048 ,ככר לוי-תנאי שרה
5000 ,תל אביב - יפו ,6053 ,ככר לוין אריה הרב
5000 ,תל אביב - יפו ,6015 ,ככר לונדון
5000 ,תל אביב - יפו ,6016 ,ככר מגן דוד
5000 ,תל אביב - יפו ,6022 ,ככר מהלר גוסטב
5000 ,תל אביב - יפו ,6017 ,ככר מיכאלס
5000 ,תל אביב - יפו ,6018 ,ככר נמיר
5000 ,תל אביב - יפו ,6046 ,ככר סגן בוקעי יעקב
5000 ,תל אביב - יפו ,6031 ,ככר סתר מרדכי
5000 ,תל אביב - יפו ,6019 ,ככר פלומר
5000 ,תל אביב - יפו ,6020 ,ככר קדומים
5000 ,תל אביב - יפו ,6021 ,ככר שטרן מרדכי
5000 ,תל אביב - יפו ,6043 ,ככר שמאלי אליעזר
5000 ,תל אביב - יפו ,6035 ,ככר שנברג ארנולד
5000 ,תל אביב - יפו ,1367 ,כל ישראל חברים
5000 ,תל אביב - יפו ,1218 ,כנפי נשרים
5000 ,תל אביב - יפו ,1330 ,כנרת
5000 ,תל אביב - יפו ,2828 ,כסלו
5000 ,תל אביב - יפו ,2211 ,כפר גלעדי
5000 ,תל אביב - יפו ,118 ,כפר יונה
5000 ,תל אביב - יפו ,1307 ,כפר סבא
5000 ,תל אביב - יפו ,6826 ,כפר שלם
5000 ,תל אביב - יפו ,2746 ,כצנלסון בת שבע
5000 ,תל אביב - יפו ,700 ,כרם התימנים
5000 ,תל אביב - יפו ,2310 ,כרם חמד
5000 ,תל אביב - יפו ,2790 ,כרמי
5000 ,תל אביב - יפו ,3016 ,כרמי דב
5000 ,תל אביב - יפו ,1106 ,כרמיה
5000 ,תל אביב - יפו ,1380 ,כרמלית
5000 ,תל אביב - יפו ,1117 ,לאן א
5000 ,תל אביב - יפו ,1147 ,לאן ב
5000 ,תל אביב - יפו ,752 ,לב אברהם
5000 ,תל אביב - יפו ,3280 ,לבון פנחס
5000 ,תל אביב - יפו ,1276 ,לבונטין
5000 ,תל אביב - יפו ,2735 ,לבלוב
5000 ,תל אביב - יפו ,2307 ,לבנדה
5000 ,תל אביב - יפו ,2434 ,לבנדובסקי
5000 ,תל אביב - יפו ,116 ,לבנון חיים
5000 ,תל אביב - יפו ,4575 ,לבני איתן
5000 ,תל אביב - יפו ,2648 ,לה גארדיה
5000 ,תל אביב - יפו ,809 ,לואי מרשל
5000 ,תל אביב - יפו ,2981 ,לובטקין צביה
5000 ,תל אביב - יפו ,2301 ,לודויפול
5000 ,תל אביב - יפו ,1815 ,לוזים
5000 ,תל אביב - יפו ,2643 ,לוחמי גליפולי
5000 ,תל אביב - יפו ,1440 ,לוי יוסף
5000 ,תל אביב - יפו ,503 ,לוי יצחק
5000 ,תל אביב - יפו ,4086 ,לוי שלום אהרון
5000 ,תל אביב - יפו ,1504 ,לויד ג'ורג'
5000 ,תל אביב - יפו ,341 ,לויטה
5000 ,תל אביב - יפו ,182 ,לויטן יצחק
5000 ,תל אביב - יפו ,4646 ,לוין קיפניס
5000 ,תל אביב - יפו ,4152 ,לוין שלום
5000 ,תל אביב - יפו ,2020 ,לוינסון אברהם
5000 ,תל אביב - יפו ,2328 ,לוינסקי
5000 ,תל אביב - יפו ,1736 ,לונדון בצלאל
5000 ,תל אביב - יפו ,1108 ,לונץ
5000 ,תל אביב - יפו ,1350 ,לוע הארי
5000 ,תל אביב - יפו ,631 ,לוריא
5000 ,תל אביב - יפו ,2860 ,לח"י
5000 ,תל אביב - יפו ,113 ,לחובר
5000 ,תל אביב - יפו ,728 ,לטריס
5000 ,תל אביב - יפו ,1959 ,ליב בן שרה
5000 ,תל אביב - יפו ,2864 ,ליבנה אליעזר
5000 ,תל אביב - יפו ,534 ,ליברמן
5000 ,תל אביב - יפו ,2467 ,ליוויק
5000 ,תל אביב - יפו ,4091 ,ליכטר יהושע
5000 ,תל אביב - יפו ,496 ,לילי מנחם
5000 ,תל אביב - יפו ,1031 ,ליליאן
5000 ,תל אביב - יפו ,1309 ,לילינבלום
5000 ,תל אביב - יפו ,1629 ,לינקאוס יוסף פופר
5000 ,תל אביב - יפו ,1506 ,לינקולן
5000 ,תל אביב - יפו ,933 ,ליסין
5000 ,תל אביב - יפו ,1675 ,ליענר שלמה
5000 ,תל אביב - יפו ,945 ,ליפסקי לואי
5000 ,תל אביב - יפו ,2791 ,ליש
5000 ,תל אביב - יפו ,4123 ,לישנסקי יוסף
5000 ,תל אביב - יפו ,715 ,לכיש
5000 ,תל אביב - יפו ,199 ,למדן יצחק
5000 ,תל אביב - יפו ,2795 ,למואל
5000 ,תל אביב - יפו ,2160 ,למרטין
5000 ,תל אביב - יפו ,538 ,לסל
5000 ,תל אביב - יפו ,1538 ,לסקוב חיים
5000 ,תל אביב - יפו ,2358 ,לסקר
5000 ,תל אביב - יפו ,2734 ,לפידות
5000 ,תל אביב - יפו ,1321 ,לפין
5000 ,תל אביב - יפו ,2341 ,לצרוס משה
5000 ,תל אביב - יפו ,2444 ,לקרט
5000 ,תל אביב - יפו ,1279 ,לשם
5000 ,תל אביב - יפו ,1423 ,מאה שערים
5000 ,תל אביב - יפו ,2368 ,מאור הגולה
5000 ,תל אביב - יפו ,4721 ,מאור משה
5000 ,תל אביב - יפו ,1934 ,מאור עינים
5000 ,תל אביב - יפו ,4773 ,מאייר מרדכי
5000 ,תל אביב - יפו ,2420 ,מאירבר
5000 ,תל אביב - יפו ,511 ,מאנגר איציק
5000 ,תל אביב - יפו ,1041 ,מאנה
5000 ,תל אביב - יפו ,616 ,מאפו
5000 ,תל אביב - יפו ,1817 ,מבוא 3949
5000 ,תל אביב - יפו ,394 ,מבוא גילת
5000 ,תל אביב - יפו ,395 ,מבוא גניגר
5000 ,תל אביב - יפו ,396 ,מבוא געתון
5000 ,תל אביב - יפו ,397 ,מבוא גרופית
5000 ,תל אביב - יפו ,2985 ,מבוא וולקר יוסף
5000 ,תל אביב - יפו ,2801 ,מבחר
5000 ,תל אביב - יפו ,138 ,מבטחים
5000 ,תל אביב - יפו ,249 ,מבצע קדש
5000 ,תל אביב - יפו ,2396 ,מבקיעים
5000 ,תל אביב - יפו ,2787 ,מבשר
5000 ,תל אביב - יפו ,1337 ,מגדל
5000 ,תל אביב - יפו ,216 ,מגדל שרשן
5000 ,תל אביב - יפו ,6534 ,מגדלי נאמן
5000 ,תל אביב - יפו ,717 ,מגידו
5000 ,תל אביב - יפו ,1990 ,מגן אברהם
5000 ,תל אביב - יפו ,135 ,מגשימים
5000 ,תל אביב - יפו ,1789 ,מד אנדרומדה
5000 ,תל אביב - יפו ,2915 ,מדמון מנחם
5000 ,תל אביב - יפו ,1933 ,מדרש פנחס
5000 ,תל אביב - יפו ,2138 ,מדרש תנחומא
5000 ,תל אביב - יפו ,4502 ,מהלל העדני
5000 ,תל אביב - יפו ,501 ,מהר"ל
5000 ,תל אביב - יפו ,2365 ,מהרש"א
5000 ,תל אביב - יפו ,1018 ,מודיליאני
5000 ,תל אביב - יפו ,433 ,מודיעין
5000 ,תל אביב - יפו ,1244 ,מוהליבר
5000 ,תל אביב - יפו ,4085 ,מוהר יחיאל
5000 ,תל אביב - יפו ,918 ,מוזיר יעקב
5000 ,תל אביב - יפו ,1500 ,מוזס ולטר
5000 ,תל אביב - יפו ,2572 ,מוזס יהודה ונח
5000 ,תל אביב - יפו ,389 ,מויאל אהרון
5000 ,תל אביב - יפו ,2232 ,מולדת
5000 ,תל אביב - יפו ,1310 ,מולכו שלמה
5000 ,תל אביב - יפו ,2625 ,מונאש
5000 ,תל אביב - יפו ,1255 ,מונטיפיורי
5000 ,תל אביב - יפו ,921 ,מוסינזון
5000 ,תל אביב - יפו ,4135 ,מוסינזון יגאל
5000 ,תל אביב - יפו ,2967 ,מוצא
5000 ,תל אביב - יפו ,454 ,מוצקין
5000 ,תל אביב - יפו ,3116 ,מוקדי משה
5000 ,תל אביב - יפו ,2965 ,מור
5000 ,תל אביב - יפו ,1150 ,מורד הטיילת
5000 ,תל אביב - יפו ,2652 ,מורדי הגטאות
5000 ,תל אביב - יפו ,2357 ,מורה נבוכים
5000 ,תל אביב - יפו ,178 ,מוריץ דניאל
5000 ,תל אביב - יפו ,210 ,מורשת
5000 ,תל אביב - יפו ,2727 ,מושיע
5000 ,תל אביב - יפו ,1265 ,מזא"ה
5000 ,תל אביב - יפו ,1881 ,מזל אריה
5000 ,תל אביב - יפו ,1872 ,מזל בתולה
5000 ,תל אביב - יפו ,1880 ,מזל גדי
5000 ,תל אביב - יפו ,1877 ,מזל דגים
5000 ,תל אביב - יפו ,1879 ,מזל דלי
5000 ,תל אביב - יפו ,1878 ,מזל טלה
5000 ,תל אביב - יפו ,1873 ,מזל מאזנים
5000 ,תל אביב - יפו ,1876 ,מזל סרטן
5000 ,תל אביב - יפו ,1874 ,מזל עקרב
5000 ,תל אביב - יפו ,1875 ,מזל קשת
5000 ,תל אביב - יפו ,1882 ,מזל שור
5000 ,תל אביב - יפו ,1883 ,מזל תאומים
5000 ,תל אביב - יפו ,2209 ,מזרחי
5000 ,תל אביב - יפו ,2958 ,מח"ל
5000 ,תל אביב - יפו ,2018 ,מחנה אריאל
5000 ,תל אביב - יפו ,1323 ,מחנה יוסף
5000 ,תל אביב - יפו ,1845 ,מחפוז נג'יב
5000 ,תל אביב - יפו ,3004 ,מחרוזת
5000 ,תל אביב - יפו ,1333 ,מטולה
5000 ,תל אביב - יפו ,2327 ,מטלון
5000 ,תל אביב - יפו ,929 ,מטמון כהן
5000 ,תל אביב - יפו ,204 ,מיזאן
5000 ,תל אביב - יפו ,2565 ,מיטב
5000 ,תל אביב - יפו ,2019 ,מייזל זלמן
5000 ,תל אביב - יפו ,4083 ,מייטוס אליהו
5000 ,תל אביב - יפו ,1121 ,מיכ"ל
5000 ,תל אביב - יפו ,3102 ,מיכאל (נאמן)
5000 ,תל אביב - יפו ,1971 ,מיכאל אנג'לו
5000 ,תל אביב - יפו ,413 ,מיכה
5000 ,תל אביב - יפו ,4775 ,מילוא יוסף
5000 ,תל אביב - יפו ,4101 ,מילר כהן אניטה
5000 ,תל אביב - יפו ,2362 ,מימון שלמה
5000 ,תל אביב - יפו ,384 ,מינץ בנימין
5000 ,תל אביב - יפו ,2428 ,מינקובסקי
5000 ,תל אביב - יפו ,2150 ,מיצקביץ אדם
5000 ,תל אביב - יפו ,1532 ,מיקוניס שמואל
5000 ,תל אביב - יפו ,2246 ,מירון
5000 ,תל אביב - יפו ,1788 ,מכלל יופי
5000 ,תל אביב - יפו ,1779 ,מכמורת
5000 ,תל אביב - יפו ,1223 ,מל"ן
5000 ,תל אביב - יפו ,416 ,מלאכי
5000 ,תל אביב - יפו ,2336 ,מלבי"ם
5000 ,תל אביב - יפו ,546 ,מלון אורה
5000 ,תל אביב - יפו ,716 ,מלון אסטור
5000 ,תל אביב - יפו ,602 ,מלון בזל
5000 ,תל אביב - יפו ,1151 ,מלון ימית
5000 ,תל אביב - יפו ,3160 ,מלון מנדרין
5000 ,תל אביב - יפו ,641 ,מלון קרלטון
5000 ,תל אביב - יפו ,2613 ,מלילות
5000 ,תל אביב - יפו ,730 ,מלכי ישראל
5000 ,תל אביב - יפו ,1249 ,מלצ'ט
5000 ,תל אביב - יפו ,2817 ,מלקוש
5000 ,תל אביב - יפו ,617 ,מנדלי מוכר ספרים
5000 ,תל אביב - יפו ,701 ,מנדלסון
5000 ,תל אביב - יפו ,2558 ,מנדלקרן
5000 ,תל אביב - יפו ,1563 ,מנדלר אלברט אברהם
5000 ,תל אביב - יפו ,451 ,מנדלשטם
5000 ,תל אביב - יפו ,1853 ,מנדלשטם אוסיפ
5000 ,תל אביב - יפו ,1831 ,מנוחה
5000 ,תל אביב - יפו ,207 ,מנורה
5000 ,תל אביב - יפו ,2521 ,מנורת המאור
5000 ,תל אביב - יפו ,183 ,מניה וישראל
5000 ,תל אביב - יפו ,2323 ,מנשה בן ישראל
5000 ,תל אביב - יפו ,951 ,מסוף ארלוזורוב
5000 ,תל אביב - יפו ,950 ,מסוף רדינג
5000 ,תל אביב - יפו ,2231 ,מסילת העולים
5000 ,תל אביב - יפו ,2221 ,מסילת וולפסון
5000 ,תל אביב - יפו ,2360 ,מסילת ישרים
5000 ,תל אביב - יפו ,2614 ,מסלול
5000 ,תל אביב - יפו ,300 ,מסקין אהרון
5000 ,תל אביב - יפו ,6827 ,מע א כ שלם
5000 ,תל אביב - יפו ,6815 ,מע ב כ שלם
5000 ,תל אביב - יפו ,6536 ,מע יד המעביר
5000 ,תל אביב - יפו ,6555 ,מע"ר
5000 ,תל אביב - יפו ,4001 ,מעבר 333
5000 ,תל אביב - יפו ,470 ,מעבר 2389
5000 ,תל אביב - יפו ,2508 ,מעבר יבוק
5000 ,תל אביב - יפו ,1602 ,מעון
5000 ,תל אביב - יפו ,279 ,מעונות אוניברסיטה
5000 ,תל אביב - יפו ,1044 ,מעלה שמיר משה
5000 ,תל אביב - יפו ,1809 ,מענית
5000 ,תל אביב - יפו ,2960 ,מעפילי אגוז
5000 ,תל אביב - יפו ,1963 ,מעפילי סלואדור
5000 ,תל אביב - יפו ,1701 ,מפרץ שלמה
5000 ,תל אביב - יפו ,532 ,מצדה
5000 ,תל אביב - יפו ,2850 ,מצובה
5000 ,תל אביב - יפו ,2638 ,מצודת בית"ר
5000 ,תל אביב - יפו ,718 ,מצפה
5000 ,תל אביב - יפו ,1277 ,מקוה ישראל
5000 ,תל אביב - יפו ,2342 ,מקור חיים
5000 ,תל אביב - יפו ,1569 ,מקלף מרדכי
5000 ,תל אביב - יפו ,4744 ,מקס ברוד
5000 ,תל אביב - יפו ,2636 ,מקראי קדש
5000 ,תל אביב - יפו ,2649 ,מרגולין
5000 ,תל אביב - יפו ,1850 ,מרגוע
5000 ,תל אביב - יפו ,838 ,מרגלית מאיר
5000 ,תל אביב - יפו ,309 ,מרגנית
5000 ,תל אביב - יפו ,4054 ,מרד גטו ורשה
5000 ,תל אביב - יפו ,2989 ,מרדור מוניה
5000 ,תל אביב - יפו ,137 ,מרדכי (מוטה) גור
5000 ,תל אביב - יפו ,1697 ,מרזוק ועזר
5000 ,תל אביב - יפו ,1731 ,מרזוק מחמד
5000 ,תל אביב - יפו ,2205 ,מרחביה
5000 ,תל אביב - יפו ,4644 ,מרידור יעקב
5000 ,תל אביב - יפו ,811 ,מרים החשמונאית
5000 ,תל אביב - יפו ,645 ,מרינה
5000 ,תל אביב - יפו ,346 ,מרכוס דוד
5000 ,תל אביב - יפו ,1112 ,מרכז בעלי מלאכה
5000 ,תל אביב - יפו ,1046 ,מרכז גולדה מאיר
5000 ,תל אביב - יפו ,6514 ,מרכז הירידים
5000 ,תל אביב - יפו ,6575 ,מרכז רפואי סוראסקי
5000 ,תל אביב - יפו ,2207 ,מרכלת
5000 ,תל אביב - יפו ,753 ,מרמורק
5000 ,תל אביב - יפו ,3112 ,מרסל ינקו
5000 ,תל אביב - יפו ,280 ,מרק יעקב
5000 ,תל אביב - יפו ,3015 ,מרקיש פרץ
5000 ,תל אביב - יפו ,2464 ,משגב עם
5000 ,תל אביב - יפו ,4684 ,משה (שלוש)
5000 ,תל אביב - יפו ,2903 ,משה בריל בר-כוכבא
5000 ,תל אביב - יפו ,4586 ,משה ז"ק
5000 ,תל אביב - יפו ,159 ,משל ירוחם
5000 ,תל אביב - יפו ,189 ,משמר הגבול
5000 ,תל אביב - יפו ,323 ,משמר הירדן
5000 ,תל אביב - יפו ,1126 ,משעול יעקב
5000 ,תל אביב - יפו ,2695 ,משעול שירה
5000 ,תל אביב - יפו ,438 ,משעול שמידט יוסף
5000 ,תל אביב - יפו ,4047 ,משפחת חלפי
5000 ,תל אביב - יפו ,6850 ,מתחם התחנה(מנשייה)
5000 ,תל אביב - יפו ,8000 ,מתחם שטח נמל ת"א
5000 ,תל אביב - יפו ,805 ,מתתיהו כהן גדול
5000 ,תל אביב - יפו ,2950 ,נ"א (כפר שלם)
5000 ,תל אביב - יפו ,4626 ,נאדיה חילו
5000 ,תל אביב - יפו ,4677 ,נאמן יובל פרופ'
5000 ,תל אביב - יפו ,1798 ,נבטים
5000 ,תל אביב - יפו ,1232 ,נג'ארה ישראל
5000 ,תל אביב - יפו ,2622 ,נגבה
5000 ,תל אביב - יפו ,2747 ,נדב
5000 ,תל אביב - יפו ,2502 ,נהלל
5000 ,תל אביב - יפו ,1024 ,נהרדעא
5000 ,תל אביב - יפו ,383 ,נהריים
5000 ,תל אביב - יפו ,6828 ,נוה אליעזר
5000 ,תל אביב - יפו ,6829 ,נוה ברבור
5000 ,תל אביב - יפו ,6830 ,נוה כפיר
5000 ,תל אביב - יפו ,1317 ,נוה צדק
5000 ,תל אביב - יפו ,2254 ,נוה שאנן
5000 ,תל אביב - יפו ,1371 ,נוה שלום
5000 ,תל אביב - יפו ,6776 ,נוה תקוה
5000 ,תל אביב - יפו ,6535 ,נווה דן
5000 ,תל אביב - יפו ,6750 ,נווה עופר
5000 ,תל אביב - יפו ,2193 ,נוזהה
5000 ,תל אביב - יפו ,1980 ,נועם
5000 ,תל אביב - יפו ,2702 ,נוריאל
5000 ,תל אביב - יפו ,306 ,נורית
5000 ,תל אביב - יפו ,112 ,נח
5000 ,תל אביב - יפו ,412 ,נחום
5000 ,תל אביב - יפו ,1562 ,נחל איילון
5000 ,תל אביב - יפו ,2139 ,נחל הבשור
5000 ,תל אביב - יפו ,2143 ,נחל עוז
5000 ,תל אביב - יפו ,2184 ,נחל שורק
5000 ,תל אביב - יפו ,371 ,נחלאות
5000 ,תל אביב - יפו ,1217 ,נחליאל
5000 ,תל אביב - יפו ,1235 ,נחלת בנימין
5000 ,תל אביב - יפו ,2509 ,נחלת יצחק
5000 ,תל אביב - יפו ,1617 ,נחמה
5000 ,תל אביב - יפו ,1212 ,נחמיה
5000 ,תל אביב - יפו ,1944 ,נחמן מברסלב
5000 ,תל אביב - יפו ,1266 ,נחמני
5000 ,תל אביב - יפו ,410 ,נחשון
5000 ,תל אביב - יפו ,2856 ,נטעים
5000 ,תל אביב - יפו ,1256 ,נטר קרל
5000 ,תל אביב - יפו ,2398 ,ניל"י
5000 ,תל אביב - יפו ,348 ,ניסן כהן
5000 ,תל אביב - יפו ,827 ,ניסנבוים
5000 ,תל אביב - יפו ,1618 ,ניצנה
5000 ,תל אביב - יפו ,6831 ,ניר אביב
5000 ,תל אביב - יפו ,2538 ,ניר עם
5000 ,תל אביב - יפו ,2671 ,נירים
5000 ,תל אביב - יפו ,340 ,נמירובר
5000 ,תל אביב - יפו ,1758 ,נמל יפו
5000 ,תל אביב - יפו ,409 ,נמל תל אביב
5000 ,תל אביב - יפו ,4066 ,נמרי דוידקה
5000 ,תל אביב - יפו ,2393 ,נס הרים
5000 ,תל אביב - יפו ,2155 ,נס לגויים
5000 ,תל אביב - יפו ,1144 ,נס ציונה
5000 ,תל אביב - יפו ,1730 ,נסאר ג'ורג'
5000 ,תל אביב - יפו ,2749 ,נעמן
5000 ,תל אביב - יפו ,1113 ,נפחא יצחק
5000 ,תל אביב - יפו ,725 ,נצח ישראל
5000 ,תל אביב - יפו ,291 ,נקר מאיר
5000 ,תל אביב - יפו ,3005 ,נרדור
5000 ,תל אביב - יפו ,1709 ,נרדי נחום
5000 ,תל אביב - יפו ,4716 ,נרקיס עוזי
5000 ,תל אביב - יפו ,2621 ,נשרי צבי
5000 ,תל אביב - יפו ,1871 ,נתיב המזלות
5000 ,תל אביב - יפו ,2812 ,נתיבות
5000 ,תל אביב - יפו ,1578 ,נתיבי איילון דרום
5000 ,תל אביב - יפו ,1580 ,נתיבי איילון צפון
5000 ,תל אביב - יפו ,2780 ,נתן
5000 ,תל אביב - יפו ,607 ,נתן החכם
5000 ,תל אביב - יפו ,3120 ,סאמט שמעון
5000 ,תל אביב - יפו ,305 ,סביון
5000 ,תל אביב - יפו ,2260 ,סג'רה
5000 ,תל אביב - יפו ,1619 ,סגולה
5000 ,תל אביב - יפו ,4088 ,סגל ירושלים
5000 ,תל אביב - יפו ,1691 ,סגל צבי הרמן
5000 ,תל אביב - יפו ,4568 ,סגל שמוליק
5000 ,תל אביב - יפו ,2022 ,סהרון
5000 ,תל אביב - יפו ,1694 ,סוגיהרה סמפו
5000 ,תל אביב - יפו ,1021 ,סוטין
5000 ,תל אביב - יפו ,1009 ,סולד הנרייטה
5000 ,תל אביב - יפו ,240 ,סומו יהודה
5000 ,תל אביב - יפו ,3006 ,סומקן
5000 ,תל אביב - יפו ,2571 ,סוסליק אריה
5000 ,תל אביב - יפו ,3250 ,סוסקין אברהם
5000 ,תל אביב - יפו ,429 ,סוקולוב
5000 ,תל אביב - יפו ,1913 ,סוקרטס
5000 ,תל אביב - יפו ,4081 ,סחרוב אנדרי
5000 ,תל אביב - יפו ,776 ,סטארה אלברט
5000 ,תל אביב - יפו ,2988 ,סטוצ'בסקי יהויכין
5000 ,תל אביב - יפו ,4779 ,סטימצקי
5000 ,תל אביב - יפו ,2651 ,סטרומה
5000 ,תל אביב - יפו ,1567 ,סטרכילביץ צבי
5000 ,תל אביב - יפו ,3140 ,סי אנד סאן
5000 ,תל אביב - יפו ,2827 ,סיוון
5000 ,תל אביב - יפו ,1724 ,סייפן
5000 ,תל אביב - יפו ,4067 ,סילמן יהודה קדיש
5000 ,תל אביב - יפו ,2703 ,סימן טוב
5000 ,תל אביב - יפו ,2662 ,סירני חיים
5000 ,תל אביב - יפו ,620 ,סירקין
5000 ,תל אביב - יפו ,114 ,סלואדור
5000 ,תל אביב - יפו ,2239 ,סלומון
5000 ,תל אביב - יפו ,2556 ,סלונימסקי
5000 ,תל אביב - יפו ,1732 ,סלסלה
5000 ,תל אביב - יפו ,2976 ,סלע יעקב
5000 ,תל אביב - יפו ,4069 ,סלע עמנואל
5000 ,תל אביב - יפו ,2630 ,סמ איתן
5000 ,תל אביב - יפו ,4784 ,סמ אלון
5000 ,תל אביב - יפו ,2105 ,סמ באר שבע
5000 ,תל אביב - יפו ,4048 ,סמ בארי
5000 ,תל אביב - יפו ,1303 ,סמ בית הבד
5000 ,תל אביב - יפו ,547 ,סמ בן יהודה
5000 ,תל אביב - יפו ,2374 ,סמ בר יוחאי
5000 ,תל אביב - יפו ,3201 ,סמ גולי קניה
5000 ,תל אביב - יפו ,713 ,סמ גור אריה
5000 ,תל אביב - יפו ,1148 ,סמ גן מאיר
5000 ,תל אביב - יפו ,1268 ,סמ האור
5000 ,תל אביב - יפו ,1278 ,סמ הגינה
5000 ,תל אביב - יפו ,512 ,סמ החובבים
5000 ,תל אביב - יפו ,1332 ,סמ החרמון
5000 ,תל אביב - יפו ,1216 ,סמ הכרמל
5000 ,תל אביב - יפו ,2217 ,סמ העליה
5000 ,תל אביב - יפו ,2372 ,סמ הר ציון
5000 ,תל אביב - יפו ,1243 ,סמ התבור
5000 ,תל אביב - יפו ,423 ,סמ התרסי
5000 ,תל אביב - יפו ,459 ,סמ זנגביל
5000 ,תל אביב - יפו ,2826 ,סמ זרת
5000 ,תל אביב - יפו ,2400 ,סמ חכמי ישראל
5000 ,תל אביב - יפו ,4522 ,סמ טאגור
5000 ,תל אביב - יפו ,712 ,סמ יל"ג
5000 ,תל אביב - יפו ,2802 ,סמ כביר
5000 ,תל אביב - יפו ,1338 ,סמ מגדל
5000 ,תל אביב - יפו ,1316 ,סמ מולכו
5000 ,תל אביב - יפו ,418 ,סמ מיכה
5000 ,תל אביב - יפו ,460 ,סמ מנדלשטם
5000 ,תל אביב - יפו ,2721 ,סמ מסילה א
5000 ,תל אביב - יפו ,2722 ,סמ מסילה ב
5000 ,תל אביב - יפו ,2768 ,סמ מעוז
5000 ,תל אביב - יפו ,359 ,סמ משמר הירדן
5000 ,תל אביב - יפו ,521 ,סמ נ"ס
5000 ,תל אביב - יפו ,1318 ,סמ נוה צדק
5000 ,תל אביב - יפו ,430 ,סמ נורדאו
5000 ,תל אביב - יפו ,463 ,סמ נחושתן
5000 ,תל אביב - יפו ,2334 ,סמ עובד
5000 ,תל אביב - יפו ,428 ,סמ עמוס
5000 ,תל אביב - יפו ,2317 ,סמ ענתבי
5000 ,תל אביב - יפו ,325 ,סמ צדוק חיים
5000 ,תל אביב - יפו ,2382 ,סמ ראשית חכמה
5000 ,תל אביב - יפו ,2355 ,סמ רבי פוזילוב
5000 ,תל אביב - יפו ,733 ,סמ רשבג
5000 ,תל אביב - יפו ,1329 ,סמ שבזי
5000 ,תל אביב - יפו ,1355 ,סמ שלוש
5000 ,תל אביב - יפו ,1237 ,סמ שפ"ר
5000 ,תל אביב - יפו ,2167 ,סמ שרירא
5000 ,תל אביב - יפו ,1313 ,סמ תחכמוני
5000 ,תל אביב - יפו ,3115 ,סמבורסקי דניאל
5000 ,תל אביב - יפו ,2782 ,סמדר
5000 ,תל אביב - יפו ,621 ,סמולנסקין
5000 ,תל אביב - יפו ,1118 ,סמטה אלמונית
5000 ,תל אביב - יפו ,1120 ,סמטה פלונית
5000 ,תל אביב - יפו ,1696 ,סמטת הש"ך
5000 ,תל אביב - יפו ,2696 ,סמטת נטע
5000 ,תל אביב - יפו ,1145 ,סמטת שרה עזריהו
5000 ,תל אביב - יפו ,905 ,סמילנסקי
5000 ,תל אביב - יפו ,400 ,סנה משה
5000 ,תל אביב - יפו ,4675 ,סנהדראי טובה
5000 ,תל אביב - יפו ,844 ,סנהדרין
5000 ,תל אביב - יפו ,2961 ,סנפיר
5000 ,תל אביב - יפו ,2654 ,סנש חנה
5000 ,תל אביב - יפו ,1503 ,סעדיה גאון
5000 ,תל אביב - יפו ,732 ,ספיר אליהו
5000 ,תל אביב - יפו ,1996 ,ספיר ברוך
5000 ,תל אביב - יפו ,1047 ,ספיר פנחס
5000 ,תל אביב - יפו ,2975 ,סרלין יוסף
5000 ,תל אביב - יפו ,4600 ,סשא ארגוב
5000 ,תל אביב - יפו ,4661 ,ע הלל
5000 ,תל אביב - יפו ,2707 ,עבאס אברהם
5000 ,תל אביב - יפו ,1965 ,עבד אל-גני
5000 ,תל אביב - יפו ,1932 ,עבודת ישראל
5000 ,תל אביב - יפו ,2742 ,עברי
5000 ,תל אביב - יפו ,1806 ,עגור
5000 ,תל אביב - יפו ,198 ,עגנון ש"י
5000 ,תל אביב - יפו ,4649 ,עדה
5000 ,תל אביב - יפו ,2156 ,עדולם
5000 ,תל אביב - יפו ,417 ,עובדיה הנביא
5000 ,תל אביב - יפו ,439 ,עובדיה מברטנורה
5000 ,תל אביב - יפו ,2608 ,עודד
5000 ,תל אביב - יפו ,2701 ,עוזר
5000 ,תל אביב - יפו ,345 ,עולי הגרדום
5000 ,תל אביב - יפו ,1979 ,עולי ציון
5000 ,תל אביב - יפו ,1814 ,עולש
5000 ,תל אביב - יפו ,2777 ,עזאי
5000 ,תל אביב - יפו ,2149 ,עזה
5000 ,תל אביב - יפו ,1209 ,עזרא הסופר
5000 ,תל אביב - יפו ,1389 ,עזריה
5000 ,תל אביב - יפו ,518 ,עזריה מן האדומים
5000 ,תל אביב - יפו ,354 ,עטרות
5000 ,תל אביב - יפו ,2627 ,עיינות
5000 ,תל אביב - יפו ,2623 ,עין גב
5000 ,תל אביב - יפו ,446 ,עין גדי
5000 ,תל אביב - יפו ,1314 ,עין גנים
5000 ,תל אביב - יפו ,1543 ,עין דור
5000 ,תל אביב - יפו ,2247 ,עין הקורא
5000 ,תל אביב - יפו ,1251 ,עין ורד
5000 ,תל אביב - יפו ,2563 ,עין זיתים
5000 ,תל אביב - יפו ,740 ,עין חרוד
5000 ,תל אביב - יפו ,1334 ,עין יעקב
5000 ,תל אביב - יפו ,310 ,עיר שמש
5000 ,תל אביב - יפו ,2024 ,עירית
5000 ,תל אביב - יפו ,1006 ,על פרשת דרכים
5000 ,תל אביב - יפו ,136 ,עלומים
5000 ,תל אביב - יפו ,2501 ,עלית הנוער
5000 ,תל אביב - יפו ,613 ,עם ישראל חי
5000 ,תל אביב - יפו ,442 ,עמדן יעקב
5000 ,תל אביב - יפו ,427 ,עמוס
5000 ,תל אביב - יפו ,4628 ,עמיחי יהודה
5000 ,תל אביב - יפו ,2561 ,עמינדב
5000 ,תל אביב - יפו ,2001 ,עמיעד
5000 ,תל אביב - יפו ,2391 ,עמיקם
5000 ,תל אביב - יפו ,1614 ,עמיקם אליהו (קשאק)
5000 ,תל אביב - יפו ,2321 ,עמיר
5000 ,תל אביב - יפו ,4540 ,עמירן עמנואל
5000 ,תל אביב - יפו ,2602 ,עמישב
5000 ,תל אביב - יפו ,515 ,עמנואל הרומי
5000 ,תל אביב - יפו ,2647 ,עמק איילון
5000 ,תל אביב - יפו ,2506 ,עמק ברכה
5000 ,תל אביב - יפו ,223 ,עמרי
5000 ,תל אביב - יפו ,1510 ,עמרם גאון
5000 ,תל אביב - יפו ,2316 ,ענתבי
5000 ,תל אביב - יפו ,385 ,ענתות
5000 ,תל אביב - יפו ,1628 ,עציון גבר
5000 ,תל אביב - יפו ,1320 ,עצמון
5000 ,תל אביב - יפו ,901 ,עקיבא אריה
5000 ,תל אביב - יפו ,2503 ,ערבי נחל
5000 ,תל אביב - יפו ,321 ,עשהאל
5000 ,תל אביב - יפו ,4777 ,פאגלין עמיחי
5000 ,תל אביב - יפו ,6586 ,פארק צ'ארלס קלור
5000 ,תל אביב - יפו ,2564 ,פאת השולחן
5000 ,תל אביב - יפו ,1689 ,פברגט אנריקה
5000 ,תל אביב - יפו ,2689 ,פוזננסקי שמואל
5000 ,תל אביב - יפו ,4517 ,פוחובסקי אריה
5000 ,תל אביב - יפו ,4638 ,פוירשטיין אמיל
5000 ,תל אביב - יפו ,760 ,פולד ברכה
5000 ,תל אביב - יפו ,1025 ,פומבדיתא
5000 ,תל אביב - יפו ,392 ,פומרוק יוסף
5000 ,תל אביב - יפו ,1570 ,פועה
5000 ,תל אביב - יפו ,1622 ,פוריה
5000 ,תל אביב - יפו ,1975 ,פושקין
5000 ,תל אביב - יפו ,1861 ,פחד יצחק
5000 ,תל אביב - יפו ,119 ,פטאי יוסף
5000 ,תל אביב - יפו ,2672 ,פטריה
5000 ,תל אביב - יפו ,2666 ,פטרסון
5000 ,תל אביב - יפו ,1248 ,פיארברג
5000 ,תל אביב - יפו ,935 ,פייבל
5000 ,תל אביב - יפו ,4612 ,פיינברג יוסף
5000 ,תל אביב - יפו ,289 ,פיינשטיין
5000 ,תל אביב - יפו ,1791 ,פייר מנדס פרנס
5000 ,תל אביב - יפו ,107 ,פיכמן
5000 ,תל אביב - יפו ,378 ,פילדלפיה
5000 ,תל אביב - יפו ,703 ,פילון
5000 ,תל אביב - יפו ,179 ,פיליכובסקי
5000 ,תל אביב - יפו ,4125 ,פילץ אריה
5000 ,תל אביב - יפו ,2238 ,פין
5000 ,תל אביב - יפו ,801 ,פינלס
5000 ,תל אביב - יפו ,1331 ,פינס
5000 ,תל אביב - יפו ,627 ,פינסקר
5000 ,תל אביב - יפו ,4619 ,פינקל שמעון
5000 ,תל אביב - יפו ,2023 ,פיקוס
5000 ,תל אביב - יפו ,1023 ,פישמן מימון
5000 ,תל אביב - יפו ,2839 ,פלג
5000 ,תל אביב - יפו ,4543 ,פלדרמן וילהלם
5000 ,תל אביב - יפו ,2767 ,פלוגות
5000 ,תל אביב - יפו ,2218 ,פלורנטין
5000 ,תל אביב - יפו ,2786 ,פלטיאל
5000 ,תל אביב - יפו ,2532 ,פליטי הספר
5000 ,תל אביב - יפו ,2340 ,פלקירה
5000 ,תל אביב - יפו ,4771 ,פן אלכסנדר
5000 ,תל אביב - יפו ,2785 ,פנואל
5000 ,תל אביב - יפו ,1667 ,פנחס בן יאיר
5000 ,תל אביב - יפו ,1960 ,פנץ נתן
5000 ,תל אביב - יפו ,914 ,פנקס
5000 ,תל אביב - יפו ,1968 ,פסטלוצי
5000 ,תל אביב - יפו ,1712 ,פסטר
5000 ,תל אביב - יפו ,176 ,פסטרנק אריה
5000 ,תל אביב - יפו ,842 ,פעמוני יוסף
5000 ,תל אביב - יפו ,2198 ,פעמונית
5000 ,תל אביב - יפו ,458 ,פקיעין
5000 ,תל אביב - יפו ,507 ,פראג
5000 ,תל אביב - יפו ,1613 ,פראנצויז
5000 ,תל אביב - יפו ,162 ,פראנקל
5000 ,תל אביב - יפו ,622 ,פרוג
5000 ,תל אביב - יפו ,144 ,פרויד
5000 ,תל אביב - יפו ,2447 ,פרופ' פנינה זלצמן
5000 ,תל אביב - יפו ,185 ,פרופס אהרון צבי
5000 ,תל אביב - יפו ,3000 ,פרוץ לאו
5000 ,תל אביב - יפו ,2616 ,פרזון
5000 ,תל אביב - יפו ,2511 ,פרחי אביב
5000 ,תל אביב - יפו ,636 ,פרי אליעזר
5000 ,תל אביב - יפו ,2510 ,פרי מגדים
5000 ,תל אביב - יפו ,2315 ,פרי עץ חיים
5000 ,תל אביב - יפו ,296 ,פרידלנד צבי
5000 ,תל אביב - יפו ,4538 ,פרידמן שרגא
5000 ,תל אביב - יפו ,4748 ,פרייגרזון צבי
5000 ,תל אביב - יפו ,1544 ,פריש דניאל
5000 ,תל אביב - יפו ,714 ,פרישמן
5000 ,תל אביב - יפו ,4764 ,פרלוק משה
5000 ,תל אביב - יפו ,4741 ,פרלשטיין יצחק
5000 ,תל אביב - יפו ,4024 ,פרלשטיין פסיה ויעקב
5000 ,תל אביב - יפו ,4625 ,פרנץ קפקא
5000 ,תל אביב - יפו ,505 ,פרנקפורט
5000 ,תל אביב - יפו ,276 ,פרסיץ שושנה
5000 ,תל אביב - יפו ,2228 ,פרץ י ל
5000 ,תל אביב - יפו ,328 ,פתחיה מרגנשבורג
5000 ,תל אביב - יפו ,2227 ,צ'לנוב
5000 ,תל אביב - יפו ,298 ,צ'מרינסקי ברוך
5000 ,תל אביב - יפו ,4113 ,צ'רטוק שמשון
5000 ,תל אביב - יפו ,4129 ,צ'רצ'יל וינסטון
5000 ,תל אביב - יפו ,1917 ,צבי צור (צ'רה)
5000 ,תל אביב - יפו ,1240 ,צדוק הכהן
5000 ,תל אביב - יפו ,301 ,צה"ל
5000 ,תל אביב - יפו ,1954 ,צהלון הרופא
5000 ,תל אביב - יפו ,777 ,צוויג סטפן
5000 ,תל אביב - יפו ,2555 ,צויפל
5000 ,תל אביב - יפו ,2436 ,צונזר
5000 ,תל אביב - יפו ,3014 ,צונץ
5000 ,תל אביב - יפו ,1773 ,צופית
5000 ,תל אביב - יפו ,2962 ,צוקרמן יצחק
5000 ,תל אביב - יפו ,2784 ,צורישדי
5000 ,תל אביב - יפו ,1390 ,צורית
5000 ,תל אביב - יפו ,406 ,צידון
5000 ,תל אביב - יפו ,1953 ,ציהתל"י
5000 ,תל אביב - יפו ,1033 ,צייטלין
5000 ,תל אביב - יפו ,4062 ,צימפל פרידריך
5000 ,תל אביב - יפו ,4550 ,צלאן פאול
5000 ,תל אביב - יפו ,2770 ,צלופחד
5000 ,תל אביב - יפו ,2971 ,צלילי חנינא
5000 ,תל אביב - יפו ,2318 ,צמח דוד
5000 ,תל אביב - יפו ,121 ,צמחי היהודים
5000 ,תל אביב - יפו ,1729 ,צנובר
5000 ,תל אביב - יפו ,419 ,צפניה
5000 ,תל אביב - יפו ,2783 ,צפריר
5000 ,תל אביב - יפו ,633 ,צפת
5000 ,תל אביב - יפו ,1561 ,צקלג
5000 ,תל אביב - יפו ,1607 ,צריפין
5000 ,תל אביב - יפו ,2966 ,צרעה
5000 ,תל אביב - יפו ,349 ,ק"ם
5000 ,תל אביב - יפו ,3150 ,קאונטרי קלאב
5000 ,תל אביב - יפו ,1547 ,קארו יוסף
5000 ,תל אביב - יפו ,2309 ,קבוץ גלויות
5000 ,תל אביב - יפו ,248 ,קדושי השואה
5000 ,תל אביב - יפו ,1760 ,קדם
5000 ,תל אביב - יפו ,2611 ,קדמוני אסא
5000 ,תל אביב - יפו ,2779 ,קדמיאל
5000 ,תל אביב - יפו ,2996 ,קדמן גורית
5000 ,תל אביב - יפו ,1849 ,קדרון
5000 ,תל אביב - יפו ,263 ,קדש ברנע
5000 ,תל אביב - יפו ,231 ,קהילת אודסה
5000 ,תל אביב - יפו ,2010 ,קהילת אוסטרליה
5000 ,תל אביב - יפו ,229 ,קהילת בודפסט
5000 ,תל אביב - יפו ,246 ,קהילת ביאליסטוק
5000 ,תל אביב - יפו ,2982 ,קהילת ברזיל
5000 ,תל אביב - יפו ,247 ,קהילת בריסק
5000 ,תל אביב - יפו ,251 ,קהילת ונציה
5000 ,תל אביב - יפו ,230 ,קהילת ורשה
5000 ,תל אביב - יפו ,254 ,קהילת ז'יטומיר
5000 ,תל אביב - יפו ,4141 ,קהילת זגלביה
5000 ,תל אביב - יפו ,233 ,קהילת טשרנוביץ
5000 ,תל אביב - יפו ,227 ,קהילת יאסי
5000 ,תל אביב - יפו ,244 ,קהילת לבוב
5000 ,תל אביב - יפו ,235 ,קהילת לודג'
5000 ,תל אביב - יפו ,2720 ,קהילת מכסיקו
5000 ,תל אביב - יפו ,2718 ,קהילת ניו יורק
5000 ,תל אביב - יפו ,252 ,קהילת סאלוניקי
5000 ,תל אביב - יפו ,202 ,קהילת סופיה
5000 ,תל אביב - יפו ,1219 ,קהילת עדן
5000 ,תל אביב - יפו ,255 ,קהילת פאדובה
5000 ,תל אביב - יפו ,256 ,קהילת פוזנא
5000 ,תל אביב - יפו ,257 ,קהילת קובנה
5000 ,תל אביב - יפו ,258 ,קהילת קיוב
5000 ,תל אביב - יפו ,232 ,קהילת קישינוב
5000 ,תל אביב - יפו ,639 ,קהילת קליבלנד
5000 ,תל אביב - יפו ,2199 ,קהילת קנדה
5000 ,תל אביב - יפו ,245 ,קהילת קרקוב
5000 ,תל אביב - יפו ,253 ,קהילת ריגא
5000 ,תל אביב - יפו ,3108 ,קובנר אבא
5000 ,תל אביב - יפו ,1439 ,קויפמן יחזקאל
5000 ,תל אביב - יפו ,4693 ,קול משה
5000 ,תל אביב - יפו ,140 ,קוממיות
5000 ,תל אביב - יפו ,1608 ,קומפרט
5000 ,תל אביב - יפו ,1437 ,קוסובסקי
5000 ,תל אביב - יפו ,2140 ,קוץ' אדוארד
5000 ,תל אביב - יפו ,1581 ,קור פאול
5000 ,תל אביב - יפו ,519 ,קורדובה
5000 ,תל אביב - יפו ,2224 ,קורדובירו
5000 ,תל אביב - יפו ,606 ,קורולנקו
5000 ,תל אביב - יפו ,2980 ,קורין עזרא
5000 ,תל אביב - יפו ,1835 ,קורצ'אק יאנוש
5000 ,תל אביב - יפו ,3012 ,קורקידי
5000 ,תל אביב - יפו ,2021 ,קיוסו אלברט
5000 ,תל אביב - יפו ,4045 ,קיכלר זילברמן לנה
5000 ,תל אביב - יפו ,4563 ,קיסרי אורי
5000 ,תל אביב - יפו ,2673 ,קיסריה
5000 ,תל אביב - יפו ,200 ,קיציס יוסף
5000 ,תל אביב - יפו ,2626 ,קיש
5000 ,תל אביב - יפו ,145 ,קלוזנר
5000 ,תל אביב - יפו ,517 ,קלונימוס
5000 ,תל אביב - יפו ,937 ,קליי
5000 ,תל אביב - יפו ,4655 ,קלין רלף
5000 ,תל אביב - יפו ,1241 ,קלישר
5000 ,תל אביב - יפו ,2741 ,קלמן
5000 ,תל אביב - יפו ,2693 ,קלמר משה
5000 ,תל אביב - יפו ,171 ,קלצ'קין
5000 ,תל אביב - יפו ,2838 ,קמה
5000 ,תל אביב - יפו ,2776 ,קמואל
5000 ,תל אביב - יפו ,2450 ,קמינסקה
5000 ,תל אביב - יפו ,2308 ,קנטור
5000 ,תל אביב - יפו ,1215 ,קפאח יחיה
5000 ,תל אביב - יפו ,1540 ,קפלן
5000 ,תל אביב - יפו ,1890 ,קפלן יעקב ד"ר
5000 ,תל אביב - יפו ,4037 ,קפלן שלמה
5000 ,תל אביב - יפו ,2430 ,קפלנסקי
5000 ,תל אביב - יפו ,222 ,קפריסין
5000 ,תל אביב - יפו ,1039 ,קצנלסון יצחק
5000 ,תל אביב - יפו ,2031 ,קרוא ברוך
5000 ,תל אביב - יפו ,122 ,קרויס
5000 ,תל אביב - יפו ,4581 ,קריגר משה
5000 ,תל אביב - יפו ,2753 ,קרייתי
5000 ,תל אביב - יפו ,6501 ,קרית החינוך
5000 ,תל אביב - יפו ,6553 ,קרית המוזיאונים
5000 ,תל אביב - יפו ,1509 ,קרית ספר
5000 ,תל אביב - יפו ,6549 ,קרית עתידים
5000 ,תל אביב - יפו ,168 ,קרית שאול
5000 ,תל אביב - יפו ,1511 ,קרליבך
5000 ,תל אביב - יפו ,913 ,קרמניצקי
5000 ,תל אביב - יפו ,173 ,קרן היסוד
5000 ,תל אביב - יפו ,265 ,קרן קיימת לישראל
5000 ,תל אביב - יפו ,105 ,קרני יהודה
5000 ,תל אביב - יפו ,342 ,קרפל ליפא
5000 ,תל אביב - יפו ,2349 ,קרשקש חסדאי
5000 ,תל אביב - יפו ,287 ,קשאני אליעזר
5000 ,תל אביב - יפו ,2354 ,ראב"ד
5000 ,תל אביב - יפו ,1516 ,ראם
5000 ,תל אביב - יפו ,1974 ,ראסין ז'אן
5000 ,תל אביב - יפו ,2244 ,ראש פינה
5000 ,תל אביב - יפו ,2251 ,ראשון לציון
5000 ,תל אביב - יפו ,1431 ,ראשונים
5000 ,תל אביב - יפו ,2379 ,ראשית חכמה
5000 ,תל אביב - יפו ,1565 ,רב אלוף דוד אלעזר
5000 ,תל אביב - יפו ,1027 ,רב אלוף יעקב דורי
5000 ,תל אביב - יפו ,193 ,רב אשי
5000 ,תל אביב - יפו ,1984 ,רב האי גאון
5000 ,תל אביב - יפו ,1762 ,רב החובל
5000 ,תל אביב - יפו ,824 ,רב צעיר
5000 ,תל אביב - יפו ,355 ,רבדים
5000 ,תל אביב - יפו ,2007 ,רבי אדא
5000 ,תל אביב - יפו ,2009 ,רבי אחא
5000 ,תל אביב - יפו ,1997 ,רבי חנינא
5000 ,תל אביב - יפו ,2003 ,רבי יוחנן
5000 ,תל אביב - יפו ,1225 ,רבי מאיר
5000 ,תל אביב - יפו ,2361 ,רבי מאיר בעל הנס
5000 ,תל אביב - יפו ,2002 ,רבי נחמן
5000 ,תל אביב - יפו ,1230 ,רבי עקיבא
5000 ,תל אביב - יפו ,1999 ,רבי פנחס
5000 ,תל אביב - יפו ,2008 ,רבי תנחום
5000 ,תל אביב - יפו ,376 ,רביבים
5000 ,תל אביב - יפו ,192 ,רבינא
5000 ,תל אביב - יפו ,1239 ,רבינוביץ שפ"ר
5000 ,תל אביב - יפו ,1229 ,רבן גמליאל
5000 ,תל אביב - יפו ,1631 ,רבנו חננאל
5000 ,תל אביב - יפו ,2165 ,רבנו ירוחם
5000 ,תל אביב - יפו ,1127 ,רבנו תם
5000 ,תל אביב - יפו ,1533 ,רבניצקי
5000 ,תל אביב - יפו ,923 ,רד"ק
5000 ,תל אביב - יפו ,101 ,רדינג
5000 ,תל אביב - יפו ,3110 ,רובין ראובן
5000 ,תל אביב - יפו ,2557 ,רובין שלמה
5000 ,תל אביב - יפו ,3107 ,רובינא חנה
5000 ,תל אביב - יפו ,4728 ,רובינשטיין ארתור
5000 ,תל אביב - יפו ,2029 ,רובינשטיין יצחק
5000 ,תל אביב - יפו ,169 ,רודנסקי שמואל
5000 ,תל אביב - יפו ,203 ,רוזאניס
5000 ,תל אביב - יפו ,366 ,רוזוב
5000 ,תל אביב - יפו ,4539 ,רוזין יהושע
5000 ,תל אביב - יפו ,3019 ,רוזן פנחס
5000 ,תל אביב - יפו ,759 ,רוזנבאום
5000 ,תל אביב - יפו ,4499 ,רוזנבלום הרצל
5000 ,תל אביב - יפו ,3114 ,רוזנבלום יאיר
5000 ,תל אביב - יפו ,2152 ,רוזנבלט צבי
5000 ,תל אביב - יפו ,2045 ,רוזנפלד שלום
5000 ,תל אביב - יפו ,4673 ,רוזנשטיין אברהם
5000 ,תל אביב - יפו ,1616 ,רוחמה
5000 ,תל אביב - יפו ,2974 ,רוטנשטרייך
5000 ,תל אביב - יפו ,2339 ,רויגו אברהם
5000 ,תל אביב - יפו ,209 ,רוממה
5000 ,תל אביב - יפו ,1978 ,רומן רולן
5000 ,תל אביב - יפו ,205 ,רומנו
5000 ,תל אביב - יפו ,329 ,רומנילי
5000 ,תל אביב - יפו ,2775 ,רוני
5000 ,תל אביב - יפו ,1663 ,רוסלאן
5000 ,תל אביב - יפו ,3109 ,רופא המחתרות
5000 ,תל אביב - יפו ,610 ,רופין
5000 ,תל אביב - יפו ,1304 ,רוקח שמעון
5000 ,תל אביב - יפו ,745 ,רות
5000 ,תל אביב - יפו ,2748 ,רזי
5000 ,תל אביב - יפו ,1655 ,רזיאל
5000 ,תל אביב - יפו ,4040 ,רח 1100
5000 ,תל אביב - יפו ,1858 ,רח 200
5000 ,תל אביב - יפו ,4788 ,רח 2300
5000 ,תל אביב - יפו ,2399 ,רח 2400
5000 ,תל אביב - יפו ,4511 ,רח 3900
5000 ,תל אביב - יפו ,2700 ,רח 1310
5000 ,תל אביב - יפו ,4512 ,רח 2310
5000 ,תל אביב - יפו ,2453 ,רח 2410
5000 ,תל אביב - יפו ,2035 ,רח 3910
5000 ,תל אביב - יפו ,3121 ,רח 2120
5000 ,תל אביב - יפו ,1695 ,רח 3320
5000 ,תל אביב - יפו ,4514 ,רח 3820
5000 ,תל אביב - יפו ,4053 ,רח 1130
5000 ,תל אביב - יפו ,4515 ,רח 3130
5000 ,תל אביב - יפו ,4768 ,רח 4230
5000 ,תל אביב - יפו ,1347 ,רח 1330
5000 ,תל אביב - יפו ,4518 ,רח 4830
5000 ,תל אביב - יפו ,1748 ,רח 3930
5000 ,תל אביב - יפו ,3130 ,רח 2040
5000 ,תל אביב - יפו ,1750 ,רח 3040
5000 ,תל אביב - יפו ,4059 ,רח 1140
5000 ,תל אביב - יפו ,4149 ,רח 2140
5000 ,תל אביב - יפו ,4116 ,רח 1240
5000 ,תל אביב - יפו ,4519 ,רח 2240
5000 ,תל אביב - יפו ,4006 ,רח 440
5000 ,תל אביב - יפו ,1755 ,רח 3740
5000 ,תל אביב - יפו ,4521 ,רח 4840
5000 ,תל אביב - יפו ,1672 ,רח 3350
5000 ,תל אביב - יפו ,4524 ,רח 3850
5000 ,תל אביב - יפו ,4526 ,רח 3860
5000 ,תל אביב - יפו ,4528 ,רח 2170
5000 ,תל אביב - יפו ,372 ,רח 2370
5000 ,תל אביב - יפו ,4010 ,רח 470
5000 ,תל אביב - יפו ,4529 ,רח 3870
5000 ,תל אביב - יפו ,4530 ,רח 4870
5000 ,תל אביב - יפו ,4036 ,רח 1080
5000 ,תל אביב - יפו ,1844 ,רח 3180
5000 ,תל אביב - יפו ,4133 ,רח 1280
5000 ,תל אביב - יפו ,4789 ,רח 2280
5000 ,תל אביב - יפו ,2849 ,רח 3780
5000 ,תל אביב - יפו ,4532 ,רח 3880
5000 ,תל אביב - יפו ,4030 ,רח 980
5000 ,תל אביב - יפו ,4533 ,רח 1290
5000 ,תל אביב - יפו ,473 ,רח 2390
5000 ,תל אביב - יפו ,1774 ,רח 101
5000 ,תל אביב - יפו ,4782 ,רח 2301
5000 ,תל אביב - יפו ,1925 ,רח 401
5000 ,תל אביב - יפו ,4537 ,רח 3901
5000 ,תל אביב - יפו ,4783 ,רח 2311
5000 ,תל אביב - יפו ,4021 ,רח 711
5000 ,תל אביב - יפו ,4154 ,רח 3911
5000 ,תל אביב - יפו ,1684 ,רח 3021
5000 ,תל אביב - יפו ,4052 ,רח 1121
5000 ,תל אביב - יפו ,4103 ,רח 1221
5000 ,תל אביב - יפו ,475 ,רח 2421
5000 ,תל אביב - יפו ,4541 ,רח 3821
5000 ,תל אביב - יפו ,4026 ,רח 921
5000 ,תל אביב - יפו ,4158 ,רח 3921
5000 ,תל אביב - יפו ,1865 ,רח 3031
5000 ,תל אביב - יפו ,1781 ,רח 131
5000 ,תל אביב - יפו ,4146 ,רח 2131
5000 ,תל אביב - יפו ,1742 ,רח 3731
5000 ,תל אביב - יפו ,4545 ,רח 4831
5000 ,תל אביב - יפו ,1706 ,רח 3931
5000 ,תל אביב - יפו ,4060 ,רח 1141
5000 ,תל אביב - יפו ,1693 ,רח 3341
5000 ,תל אביב - יפו ,1889 ,רח 3741
5000 ,תל אביב - יפו ,4546 ,רח 4841
5000 ,תל אביב - יפו ,2171 ,רח 3941
5000 ,תל אביב - יפו ,2181 ,רח 251א
5000 ,תל אביב - יפו ,1687 ,רח 3351
5000 ,תל אביב - יפו ,4547 ,רח 3851
5000 ,תל אביב - יפו ,2014 ,רח 3061
5000 ,תל אביב - יפו ,1852 ,רח 3161
5000 ,תל אביב - יפו ,1690 ,רח 3361
5000 ,תל אביב - יפו ,4548 ,רח 3861
5000 ,תל אביב - יפו ,4551 ,רח 3871
5000 ,תל אביב - יפו ,2995 ,רח 4871
5000 ,תל אביב - יפו ,4553 ,רח 3281
5000 ,תל אביב - יפו ,4620 ,רח 2381
5000 ,תל אביב - יפו ,2154 ,רח 3381
5000 ,תל אביב - יפו ,4555 ,רח 4881
5000 ,תל אביב - יפו ,1837 ,רח 3191
5000 ,תל אביב - יפו ,4556 ,רח 1291
5000 ,תל אביב - יפו ,1899 ,רח 3891
5000 ,תל אביב - יפו ,1768 ,רח 3102
5000 ,תל אביב - יפו ,4558 ,רח 4202
5000 ,תל אביב - יפו ,4559 ,רח 2302
5000 ,תל אביב - יפו ,1924 ,רח 402
5000 ,תל אביב - יפו ,4560 ,רח 3902
5000 ,תל אביב - יפו ,4531 ,רח 2312
5000 ,תל אביב - יפו ,1904 ,רח 3412
5000 ,תל אביב - יפו ,2195 ,רח 3812
5000 ,תל אביב - יפו ,4025 ,רח 912
5000 ,תל אביב - יפו ,2038 ,רח 3912
5000 ,תל אביב - יפו ,2131 ,רח 222
5000 ,תל אביב - יפו ,2986 ,רח 4222
5000 ,תל אביב - יפו ,4564 ,רח 3622
5000 ,תל אביב - יפו ,4565 ,רח 3822
5000 ,תל אביב - יפו ,4566 ,רח 2232
5000 ,תל אביב - יפו ,4567 ,רח 4232
5000 ,תל אביב - יפו ,1346 ,רח 1332
5000 ,תל אביב - יפו ,4016 ,רח 632
5000 ,תל אביב - יפו ,4569 ,רח 3832
5000 ,תל אביב - יפו ,4570 ,רח 4832
5000 ,תל אביב - יפו ,1700 ,רח 3932
5000 ,תל אביב - יפו ,4061 ,רח 1142
5000 ,תל אביב - יפו ,1818 ,רח 3142
5000 ,תל אביב - יפו ,1767 ,רח 3742
5000 ,תל אביב - יפו ,4573 ,רח 4842
5000 ,תל אביב - יפו ,1783 ,רח 152
5000 ,תל אביב - יפו ,4574 ,רח 3152
5000 ,תל אביב - יפו ,4121 ,רח 1252
5000 ,תל אביב - יפו ,1311 ,רח 3952
5000 ,תל אביב - יפו ,3117 ,רח 2362
5000 ,תל אביב - יפו ,2259 ,רח 3362
5000 ,תל אביב - יפו ,4577 ,רח 3662
5000 ,תל אביב - יפו ,4578 ,רח 3862
5000 ,תל אביב - יפו ,4579 ,רח 4862
5000 ,תל אביב - יפו ,4580 ,רח 2172
5000 ,תל אביב - יפו ,4131 ,רח 1272
5000 ,תל אביב - יפו ,4582 ,רח 3872
5000 ,תל אביב - יפו ,4583 ,רח 4872
5000 ,תל אביב - יפו ,1665 ,רח 282
5000 ,תל אביב - יפו ,4584 ,רח 2282
5000 ,תל אביב - יפו ,368 ,רח 2382
5000 ,תל אביב - יפו ,1848 ,רח 192
5000 ,תל אביב - יפו ,4587 ,רח 1292
5000 ,תל אביב - יפו ,2182 ,רח 3392
5000 ,תל אביב - יפו ,1900 ,רח 3892
5000 ,תל אביב - יפו ,2997 ,רח 4892
5000 ,תל אביב - יפו ,1769 ,רח 103
5000 ,תל אביב - יפו ,2754 ,רח 1303
5000 ,תל אביב - יפו ,4591 ,רח 2303
5000 ,תל אביב - יפו ,1923 ,רח 403
5000 ,תל אביב - יפו ,1635 ,רח 3903
5000 ,תל אביב - יפו ,4592 ,רח 2213
5000 ,תל אביב - יפו ,1903 ,רח 3413
5000 ,תל אביב - יפו ,2256 ,רח 3813
5000 ,תל אביב - יפו ,2039 ,רח 3913
5000 ,תל אביב - יפו ,2987 ,רח 4223
5000 ,תל אביב - יפו ,2121 ,רח 423
5000 ,תל אביב - יפו ,3024 ,רח 2423
5000 ,תל אביב - יפו ,4160 ,רח 3923
5000 ,תל אביב - יפו ,1828 ,רח 133
5000 ,תל אביב - יפו ,4055 ,רח 1133
5000 ,תל אביב - יפו ,4105 ,רח 1233
5000 ,תל אביב - יפו ,4594 ,רח 2233
5000 ,תל אביב - יפו ,4596 ,רח 4833
5000 ,תל אביב - יפו ,2028 ,רח 3933
5000 ,תל אביב - יפו ,362 ,רח 2143
5000 ,תל אביב - יפו ,4007 ,רח 443
5000 ,תל אביב - יפו ,4598 ,רח 4843
5000 ,תל אביב - יפו ,1776 ,רח 153
5000 ,תל אביב - יפו ,4599 ,רח 3153
5000 ,תל אביב - יפו ,4122 ,רח 1253
5000 ,תל אביב - יפו ,4034 ,רח 1063
5000 ,תל אביב - יפו ,3118 ,רח 2363
5000 ,תל אביב - יפו ,4601 ,רח 3863
5000 ,תל אביב - יפו ,4602 ,רח 4863
5000 ,תל אביב - יפו ,4035 ,רח 1073
5000 ,תל אביב - יפו ,4132 ,רח 1273
5000 ,תל אביב - יפו ,4604 ,רח 3873
5000 ,תל אביב - יפו ,4072 ,רח 1183
5000 ,תל אביב - יפו ,1692 ,רח 283
5000 ,תל אביב - יפו ,369 ,רח 2383
5000 ,תל אביב - יפו ,2390 ,רח 3383
5000 ,תל אביב - יפו ,4606 ,רח 3483
5000 ,תל אביב - יפו ,2457 ,רח 3783
5000 ,תל אביב - יפו ,4607 ,רח 3883
5000 ,תל אביב - יפו ,4077 ,רח 1193
5000 ,תל אביב - יפו ,1851 ,רח 3193
5000 ,תל אביב - יפו ,4609 ,רח 1293
5000 ,תל אביב - יפו ,4610 ,רח 2293
5000 ,תל אביב - יפו ,2330 ,רח 2393
5000 ,תל אביב - יפו ,2183 ,רח 3393
5000 ,תל אביב - יפו ,4019 ,רח 693
5000 ,תל אביב - יפו ,1915 ,רח 3893
5000 ,תל אביב - יפו ,4611 ,רח 2304
5000 ,תל אביב - יפו ,2033 ,רח 3404
5000 ,תל אביב - יפו ,4613 ,רח 2314
5000 ,תל אביב - יפו ,1901 ,רח 3414
5000 ,תל אביב - יפו ,4014 ,רח 614
5000 ,תל אביב - יפו ,2036 ,רח 3914
5000 ,תל אביב - יפו ,4032 ,רח 1024
5000 ,תל אביב - יפו ,4617 ,רח 3034
5000 ,תל אביב - יפו ,1891 ,רח 3134
5000 ,תל אביב - יפו ,4106 ,רח 1234
5000 ,תל אביב - יפו ,4618 ,רח 2234
5000 ,תל אביב - יפו ,4111 ,רח 4834
5000 ,תל אביב - יפו ,1812 ,רח 3144
5000 ,תל אביב - יפו ,1582 ,רח 1244
5000 ,תל אביב - יפו ,4008 ,רח 444
5000 ,תל אביב - יפו ,1893 ,רח 3754
5000 ,תל אביב - יפו ,4622 ,רח 4854
5000 ,תל אביב - יפו ,3119 ,רח 2364
5000 ,תל אביב - יפו ,4623 ,רח 3864
5000 ,תל אביב - יפו ,4070 ,רח 1174
5000 ,תל אביב - יפו ,1573 ,רח 1274
5000 ,תל אביב - יפו ,2870 ,רח 1084
5000 ,תל אביב - יפו ,927 ,רח 1184
5000 ,תל אביב - יפו ,4627 ,רח 2184
5000 ,תל אביב - יפו ,370 ,רח 2384
5000 ,תל אביב - יפו ,2472 ,רח 3784
5000 ,תל אביב - יפו ,4629 ,רח 4884
5000 ,תל אביב - יפו ,4078 ,רח 1194
5000 ,תל אביב - יפו ,4630 ,רח 1294
5000 ,תל אביב - יפו ,4631 ,רח 2294
5000 ,תל אביב - יפו ,4632 ,רח 3594
5000 ,תל אביב - יפו ,4056 ,רח 3694
5000 ,תל אביב - יפו ,1916 ,רח 3894
5000 ,תל אביב - יפו ,1615 ,רח 305
5000 ,תל אביב - יפו ,2580 ,רח 1305
5000 ,תל אביב - יפו ,4633 ,רח 2305
5000 ,תל אביב - יפו ,1926 ,רח 3405
5000 ,תל אביב - יפו ,1636 ,רח 3905
5000 ,תל אביב - יפו ,4049 ,רח 1115
5000 ,תל אביב - יפו ,4097 ,רח 1215
5000 ,תל אביב - יפו ,4634 ,רח 4215
5000 ,תל אביב - יפו ,4015 ,רח 615
5000 ,תל אביב - יפו ,4636 ,רח 3615
5000 ,תל אביב - יפו ,2037 ,רח 3915
5000 ,תל אביב - יפו ,4637 ,רח 2225
5000 ,תל אביב - יפו ,2044 ,רח 2425
5000 ,תל אביב - יפו ,4639 ,רח 4825
5000 ,תל אביב - יפו ,2569 ,רח 1135
5000 ,תל אביב - יפו ,4107 ,רח 1235
5000 ,תל אביב - יפו ,4640 ,רח 2235
5000 ,תל אביב - יפו ,1987 ,רח 3335
5000 ,תל אביב - יפו ,4642 ,רח 4835
5000 ,תל אביב - יפו ,1698 ,רח 3935
5000 ,תל אביב - יפו ,1661 ,רח 345
5000 ,תל אביב - יפו ,4645 ,רח 4845
5000 ,תל אביב - יפו ,1863 ,רח 3945
5000 ,תל אביב - יפו ,1787 ,רח 155
5000 ,תל אביב - יפו ,2991 ,רח 4155
5000 ,תל אביב - יפו ,4002 ,רח 355
5000 ,תל אביב - יפו ,1786 ,רח 165
5000 ,תל אביב - יפו ,4787 ,רח 2365
5000 ,תל אביב - יפו ,4648 ,רח 3865
5000 ,תל אביב - יפו ,2969 ,רח 4075
5000 ,תל אביב - יפו ,1841 ,רח 3175
5000 ,תל אביב - יפו ,1574 ,רח 1275
5000 ,תל אביב - יפו ,1640 ,רח 375
5000 ,תל אביב - יפו ,4651 ,רח 3875
5000 ,תל אביב - יפו ,243 ,רח 975
5000 ,תל אביב - יפו ,2871 ,רח 1085
5000 ,תל אביב - יפו ,4653 ,רח 2185
5000 ,תל אביב - יפו ,474 ,רח 1285
5000 ,תל אביב - יפו ,2384 ,רח 2385
5000 ,תל אביב - יפו ,2452 ,רח 3785
5000 ,תל אביב - יפו ,1888 ,רח 3885
5000 ,תל אביב - יפו ,1855 ,רח 3195
5000 ,תל אביב - יפו ,4656 ,רח 2295
5000 ,תל אביב - יפו ,4657 ,רח 695
5000 ,תל אביב - יפו ,4658 ,רח 3895
5000 ,תל אביב - יפו ,2581 ,רח 1306
5000 ,תל אביב - יפו ,4659 ,רח 2306
5000 ,תל אביב - יפו ,2030 ,רח 3406
5000 ,תל אביב - יפו ,4050 ,רח 1116
5000 ,תל אביב - יפו ,1864 ,רח 3416
5000 ,תל אביב - יפו ,1627 ,רח 3916
5000 ,תל אביב - יפו ,4767 ,רח 4226
5000 ,תל אביב - יפו ,1361 ,רח 1326
5000 ,תל אביב - יפו ,2269 ,רח 3426
5000 ,תל אביב - יפו ,4662 ,רח 4826
5000 ,תל אביב - יפו ,1749 ,רח 3036
5000 ,תל אביב - יפו ,4108 ,רח 1236
5000 ,תל אביב - יפו ,4663 ,רח 2336
5000 ,תל אביב - יפו ,1686 ,רח 3336
5000 ,תל אביב - יפו ,2413 ,רח 3846
5000 ,תל אביב - יפו ,3025 ,רח 3946
5000 ,תל אביב - יפו ,4003 ,רח 356
5000 ,תל אביב - יפו ,4666 ,רח 3356
5000 ,תל אביב - יפו ,1843 ,רח 3756
5000 ,תל אביב - יפו ,4023 ,רח 856
5000 ,תל אביב - יפו ,4667 ,רח 3856
5000 ,תל אביב - יפו ,4668 ,רח 4856
5000 ,תל אביב - יפו ,4669 ,רח 4166
5000 ,תל אביב - יפו ,4017 ,רח 666
5000 ,תל אביב - יפו ,1575 ,רח 1276
5000 ,תל אביב - יפו ,4674 ,רח 3876
5000 ,תל אביב - יפו ,4027 ,רח 976
5000 ,תל אביב - יפו ,4074 ,רח 1186
5000 ,תל אביב - יפו ,4676 ,רח 1286
5000 ,תל אביב - יפו ,2172 ,רח 3386
5000 ,תל אביב - יפו ,4682 ,רח 4886
5000 ,תל אביב - יפו ,1004 ,רח 1296
5000 ,תל אביב - יפו ,4679 ,רח 2296
5000 ,תל אביב - יפו ,4680 ,רח 3896
5000 ,תל אביב - יפו ,1866 ,רח 107
5000 ,תל אביב - יפו ,4681 ,רח 2307
5000 ,תל אביב - יפו ,1927 ,רח 3407
5000 ,תל אביב - יפו ,2698 ,רח 3807
5000 ,תל אביב - יפו ,1703 ,רח 3907
5000 ,תל אביב - יפו ,1681 ,רח 3017
5000 ,תל אביב - יפו ,4683 ,רח 2217
5000 ,תל אביב - יפו ,2984 ,רח 4217
5000 ,תל אביב - יפו ,4685 ,רח 3817
5000 ,תל אביב - יפו ,1630 ,רח 3917
5000 ,תל אביב - יפו ,4686 ,רח 4227
5000 ,תל אביב - יפו ,1362 ,רח 1327
5000 ,תל אביב - יפו ,4688 ,רח 4827
5000 ,תל אביב - יפו ,1772 ,רח 3927
5000 ,תל אביב - יפו ,4109 ,רח 1237
5000 ,תל אביב - יפו ,4690 ,רח 3837
5000 ,תל אביב - יפו ,4112 ,רח 4837
5000 ,תל אביב - יפו ,1847 ,רח 3937
5000 ,תל אביב - יפו ,2166 ,רח 247
5000 ,תל אביב - יפו ,4119 ,רח 1247
5000 ,תל אביב - יפו ,4137 ,רח 4847
5000 ,תל אביב - יפו ,3026 ,רח 3947
5000 ,תל אביב - יפו ,1778 ,רח 157
5000 ,תל אביב - יפו ,1359 ,רח 3357
5000 ,תל אביב - יפו ,4694 ,רח 3857
5000 ,תל אביב - יפו ,4695 ,רח 4857
5000 ,תל אביב - יפו ,2993 ,רח 4867
5000 ,תל אביב - יפו ,1576 ,רח 1277
5000 ,תל אביב - יפו ,2319 ,רח 3677
5000 ,תל אביב - יפו ,4698 ,רח 3777
5000 ,תל אביב - יפו ,4699 ,רח 3877
5000 ,תל אביב - יפו ,4700 ,רח 4877
5000 ,תל אביב - יפו ,4028 ,רח 977
5000 ,תל אביב - יפו ,4770 ,רח 1287
5000 ,תל אביב - יפו ,2177 ,רח 3387
5000 ,תל אביב - יפו ,1886 ,רח 3887
5000 ,תל אביב - יפו ,2806 ,רח 4887
5000 ,תל אביב - יפו ,1003 ,רח 1297
5000 ,תל אביב - יפו ,4702 ,רח 2297
5000 ,תל אביב - יפו ,4020 ,רח 697
5000 ,תל אביב - יפו ,4703 ,רח 3897
5000 ,תל אביב - יפו ,4571 ,רח 4897
5000 ,תל אביב - יפו ,4140 ,רח 2008
5000 ,תל אביב - יפו ,4704 ,רח 4208
5000 ,תל אביב - יפו ,4705 ,רח 2308
5000 ,תל אביב - יפו ,1907 ,רח 3408
5000 ,תל אביב - יפו ,1704 ,רח 3908
5000 ,תל אביב - יפו ,1682 ,רח 3018
5000 ,תל אביב - יפו ,4051 ,רח 1118
5000 ,תל אביב - יפו ,4022 ,רח 818
5000 ,תל אביב - יפו ,4707 ,רח 3818
5000 ,תל אביב - יפו ,4155 ,רח 3918
5000 ,תל אביב - יפו ,1867 ,רח 3028
5000 ,תל אביב - יפו ,4708 ,רח 2228
5000 ,תל אביב - יפו ,4709 ,רח 4228
5000 ,תל אביב - יפו ,1358 ,רח 1328
5000 ,תל אביב - יפו ,2470 ,רח 3628
5000 ,תל אביב - יפו ,4711 ,רח 4828
5000 ,תל אביב - יפו ,1757 ,רח 3928
5000 ,תל אביב - יפו ,4057 ,רח 1138
5000 ,תל אביב - יפו ,1938 ,רח 238
5000 ,תל אביב - יפו ,4110 ,רח 1238
5000 ,תל אביב - יפו ,1662 ,רח 3338
5000 ,תל אביב - יפו ,4713 ,רח 3838
5000 ,תל אביב - יפו ,4714 ,רח 4838
5000 ,תל אביב - יפו ,2389 ,רח 3938
5000 ,תל אביב - יפו ,4717 ,רח 3848
5000 ,תל אביב - יפו ,3027 ,רח 3948
5000 ,תל אביב - יפו ,4718 ,רח 4158
5000 ,תל אביב - יפו ,4719 ,רח 3858
5000 ,תל אביב - יפו ,4720 ,רח 4858
5000 ,תל אביב - יפו ,4128 ,רח 1268
5000 ,תל אביב - יפו ,4018 ,רח 668
5000 ,תל אביב - יפו ,4722 ,רח 3768
5000 ,תל אביב - יפו ,4724 ,רח 4868
5000 ,תל אביב - יפו ,1826 ,רח 3178
5000 ,תל אביב - יפו ,1577 ,רח 1278
5000 ,תל אביב - יפו ,4725 ,רח 3878
5000 ,תל אביב - יפו ,4726 ,רח 2188
5000 ,תל אביב - יפו ,4727 ,רח 1288
5000 ,תל אביב - יפו ,4614 ,רח 4888
5000 ,תל אביב - יפו ,1566 ,רח 1098
5000 ,תל אביב - יפו ,1859 ,רח 3198
5000 ,תל אביב - יפו ,4729 ,רח 2298
5000 ,תל אביב - יפו ,4730 ,רח 3898
5000 ,תל אביב - יפו ,2016 ,רח 4898
5000 ,תל אביב - יפו ,4731 ,רח 2209
5000 ,תל אביב - יפו ,4732 ,רח 2309
5000 ,תל אביב - יפו ,4031 ,רח 1019
5000 ,תל אביב - יפו ,1683 ,רח 3019
5000 ,תל אביב - יפו ,4734 ,רח 3619
5000 ,תל אביב - יפו ,4156 ,רח 3919
5000 ,תל אביב - יפו ,4735 ,רח 3029
5000 ,תל אביב - יפו ,4736 ,רח 3129
5000 ,תל אביב - יפו ,4737 ,רח 2229
5000 ,תל אביב - יפו ,4765 ,רח 4229
5000 ,תל אביב - יפו ,1353 ,רח 1329
5000 ,תל אביב - יפו ,4005 ,רח 429
5000 ,תל אביב - יפו ,4739 ,רח 3429
5000 ,תל אביב - יפו ,2471 ,רח 3629
5000 ,תל אביב - יפו ,1723 ,רח 3929
5000 ,תל אביב - יפו ,4058 ,רח 1139
5000 ,תל אביב - יפו ,4148 ,רח 2139
5000 ,תל אביב - יפו ,4115 ,רח 1239
5000 ,תל אביב - יפו ,4742 ,רח 4839
5000 ,תל אביב - יפו ,4120 ,רח 1249
5000 ,תל אביב - יפו ,4503 ,רח 4849
5000 ,תל אביב - יפו ,4746 ,רח 4859
5000 ,תל אביב - יפו ,4747 ,רח 2169
5000 ,תל אביב - יפו ,2356 ,רח 2369
5000 ,תל אביב - יפו ,4750 ,רח 4869
5000 ,תל אביב - יפו ,4071 ,רח 1179
5000 ,תל אביב - יפו ,1846 ,רח 3179
5000 ,תל אביב - יפו ,946 ,רח 1279
5000 ,תל אביב - יפו ,1642 ,רח 379
5000 ,תל אביב - יפו ,2370 ,רח 2379
5000 ,תל אביב - יפו ,4011 ,רח 479
5000 ,תל אביב - יפו ,4751 ,רח 3879
5000 ,תל אביב - יפו ,4029 ,רח 979
5000 ,תל אביב - יפו ,4753 ,רח 1289
5000 ,תל אביב - יפו ,2176 ,רח 3389
5000 ,תל אביב - יפו ,4012 ,רח 589
5000 ,תל אביב - יפו ,4755 ,רח 3789
5000 ,תל אביב - יפו ,1892 ,רח 3889
5000 ,תל אביב - יפו ,4039 ,רח 1099
5000 ,תל אביב - יפו ,4757 ,רח 2299
5000 ,תל אביב - יפו ,4758 ,רח 3899
5000 ,תל אביב - יפו ,2303 ,רח א מכבי חדשה
5000 ,תל אביב - יפו ,1908 ,רח א סחנה ג
5000 ,תל אביב - יפו ,2924 ,רח ב כפר שלם
5000 ,תל אביב - יפו ,2304 ,רח ב מכבי חדשה
5000 ,תל אביב - יפו ,1930 ,רח ב סחנה ג
5000 ,תל אביב - יפו ,2305 ,רח ג מכבי חדשה
5000 ,תל אביב - יפו ,2012 ,רח ג סחנה ג
5000 ,תל אביב - יפו ,1931 ,רח ד סחנה ג
5000 ,תל אביב - יפו ,1568 ,רח ה
5000 ,תל אביב - יפו ,2916 ,רח ה כפר שלם
5000 ,תל אביב - יפו ,2013 ,רח ה סחנה ג
5000 ,תל אביב - יפו ,2922 ,רח ו כפר שלם
5000 ,תל אביב - יפו ,1585 ,רח ז
5000 ,תל אביב - יפו ,2914 ,רח ז כפר שלם
5000 ,תל אביב - יפו ,2927 ,רח ח כ שלם
5000 ,תל אביב - יפו ,1911 ,רח ח סחנה ג
5000 ,תל אביב - יפו ,2928 ,רח ט כ שלם
5000 ,תל אביב - יפו ,2933 ,רח טו כפר שלם
5000 ,תל אביב - יפו ,2935 ,רח טז כפר שלם
5000 ,תל אביב - יפו ,2955 ,רח י כ שלם
5000 ,תל אביב - יפו ,2926 ,רח יא כ שלם
5000 ,תל אביב - יפו ,1928 ,רח יא סחנה ג
5000 ,תל אביב - יפו ,2925 ,רח יב כ שלם
5000 ,תל אביב - יפו ,1929 ,רח יב סחנה ג
5000 ,תל אביב - יפו ,2929 ,רח יג כ שלם
5000 ,תל אביב - יפו ,2930 ,רח יד כ שלם
5000 ,תל אביב - יפו ,2931 ,רח יז כ שלם
5000 ,תל אביב - יפו ,2919 ,רח יח כ שלם
5000 ,תל אביב - יפו ,2918 ,רח יט כ שלם
5000 ,תל אביב - יפו ,2910 ,רח כ כ שלם
5000 ,תל אביב - יפו ,2948 ,רח כא כפר שלם
5000 ,תל אביב - יפו ,2946 ,רח כג כפר שלם
5000 ,תל אביב - יפו ,2911 ,רח כד כ שלם
5000 ,תל אביב - יפו ,2912 ,רח כה כ שלם
5000 ,תל אביב - יפו ,2921 ,רח כו כ שלם
5000 ,תל אביב - יפו ,2905 ,רח כז כ שלם
5000 ,תל אביב - יפו ,2920 ,רח כח כ שלם
5000 ,תל אביב - יפו ,2917 ,רח כט כ שלם
5000 ,תל אביב - יפו ,2945 ,רח ל כפר שלם
5000 ,תל אביב - יפו ,2937 ,רח לא כפר שלם
5000 ,תל אביב - יפו ,2943 ,רח לג כפר שלם
5000 ,תל אביב - יפו ,2944 ,רח לד כפר שלם
5000 ,תל אביב - יפו ,2941 ,רח לה כפר שלם
5000 ,תל אביב - יפו ,2942 ,רח לו כפר שלם
5000 ,תל אביב - יפו ,2952 ,רח לז כפר שלם
5000 ,תל אביב - יפו ,2949 ,רח לח כפר שלם
5000 ,תל אביב - יפו ,2913 ,רח לט כ שלם
5000 ,תל אביב - יפו ,2901 ,רח מ כ שלם
5000 ,תל אביב - יפו ,4763 ,רח מ"ה
5000 ,תל אביב - יפו ,2808 ,רח מא כ שלם
5000 ,תל אביב - יפו ,2810 ,רח מב כ שלם
5000 ,תל אביב - יפו ,2809 ,רח מג כ שלם
5000 ,תל אביב - יפו ,2811 ,רח מד כ שלם
5000 ,תל אביב - יפו ,2807 ,רח מו כ שלם
5000 ,תל אביב - יפו ,2011 ,רח מו סחנה ג
5000 ,תל אביב - יפו ,2904 ,רח מז כ שלם
5000 ,תל אביב - יפו ,2906 ,רח מח כ שלם
5000 ,תל אביב - יפו ,2907 ,רח מט כ שלם
5000 ,תל אביב - יפו ,2909 ,רח נ כ שלם
5000 ,תל אביב - יפו ,2953 ,רח נב כ שלם
5000 ,תל אביב - יפו ,2954 ,רח נה כ שלם
5000 ,תל אביב - יפו ,2947 ,רח נו כ שלם
5000 ,תל אביב - יפו ,2938 ,רח נז כ שלם
5000 ,תל אביב - יפו ,2939 ,רח נח כ שלם
5000 ,תל אביב - יפו ,2902 ,רח נט כ שלם
5000 ,תל אביב - יפו ,2940 ,רח סא כ שלם
5000 ,תל אביב - יפו ,2932 ,רח סב כ שלם
5000 ,תל אביב - יפו ,2934 ,רח סג כ שלם
5000 ,תל אביב - יפו ,2936 ,רח סד כ שלם
5000 ,תל אביב - יפו ,2610 ,רח סה כ שלם
5000 ,תל אביב - יפו ,2609 ,רח סו כ שלם
5000 ,תל אביב - יפו ,2851 ,רח סל
5000 ,תל אביב - יפו ,2923 ,רח עג כ שלם
5000 ,תל אביב - יפו ,2763 ,רח פ
5000 ,תל אביב - יפו ,2759 ,רח פג
5000 ,תל אביב - יפו ,2760 ,רח פד
5000 ,תל אביב - יפו ,2758 ,רח פה
5000 ,תל אביב - יפו ,2757 ,רח פז
5000 ,תל אביב - יפו ,2756 ,רח צ
5000 ,תל אביב - יפו ,2977 ,רח רי"ז
5000 ,תל אביב - יפו ,2004 ,רחבת אבוטבול יעקב
5000 ,תל אביב - יפו ,6042 ,רחבת אלחנני אריה
5000 ,תל אביב - יפו ,6040 ,רחבת לאונרד ברנשטין
5000 ,תל אביב - יפו ,845 ,רחבת מילאנו
5000 ,תל אביב - יפו ,2699 ,רחבת מרטון ארנסט
5000 ,תל אביב - יפו ,545 ,רחל
5000 ,תל אביב - יפו ,482 ,רחל כהן כגן
5000 ,תל אביב - יפו ,1664 ,רטוש יונתן
5000 ,תל אביב - יפו ,2992 ,רי"ז
5000 ,תל אביב - יפו ,1514 ,ריב"ל
5000 ,תל אביב - יפו ,4678 ,ריבולוב מנחם
5000 ,תל אביב - יפו ,721 ,רייך
5000 ,תל אביב - יפו ,705 ,ריינס
5000 ,תל אביב - יפו ,2661 ,רייס רפאל
5000 ,תל אביב - יפו ,2655 ,רייק חביבה
5000 ,תל אביב - יפו ,823 ,רילף יצחק
5000 ,תל אביב - יפו ,2432 ,רינגלבלום
5000 ,תל אביב - יפו ,4585 ,רינהרט מקס
5000 ,תל אביב - יפו ,1981 ,ריש גלותא
5000 ,תל אביב - יפו ,4520 ,רכטר זאב
5000 ,תל אביב - יפו ,2344 ,רלב"ג
5000 ,תל אביב - יפו ,1236 ,רמב"ם
5000 ,תל אביב - יפו ,3021 ,רמבה אייזיק
5000 ,תל אביב - יפו ,1029 ,רמברנדט
5000 ,תל אביב - יפו ,374 ,רמה
5000 ,תל אביב - יפו ,356 ,רמות נפתלי
5000 ,תל אביב - יפו ,6550 ,רמות צהלה
5000 ,תל אביב - יפו ,928 ,רמז דוד
5000 ,תל אביב - יפו ,1273 ,רמח"ל
5000 ,תל אביב - יפו ,6519 ,רמת אביב החדשה
5000 ,תל אביב - יפו ,6800 ,רמת הטייסים
5000 ,תל אביב - יפו ,541 ,רנ"ק
5000 ,תל אביב - יפו ,2208 ,רענן
5000 ,תל אביב - יפו ,215 ,רפידים
5000 ,תל אביב - יפו ,2694 ,רצאבי זכריה
5000 ,תל אביב - יפו ,2781 ,רצון
5000 ,תל אביב - יפו ,635 ,רציף הירקון
5000 ,תל אביב - יפו ,1143 ,רציף הרברט סמואל
5000 ,תל אביב - יפו ,275 ,רקנאטי
5000 ,תל אביב - יפו ,1116 ,רש"י
5000 ,תל אביב - יפו ,2726 ,רשף
5000 ,תל אביב - יפו ,2755 ,רשפים
5000 ,תל אביב - יפו ,6516 ,ש אפקה
5000 ,תל אביב - יפו ,6600 ,ש בבלי
5000 ,תל אביב - יפו ,6537 ,ש דן
5000 ,תל אביב - יפו ,6525 ,ש הדר יוסף
5000 ,תל אביב - יפו ,6801 ,ש הקאוקזים
5000 ,תל אביב - יפו ,6743 ,ש חסכון יפו
5000 ,תל אביב - יפו ,6726 ,ש חסכון כביש בת ים
5000 ,תל אביב - יפו ,6538 ,ש יוגוסלבים
5000 ,תל אביב - יפו ,6539 ,ש ישגב
5000 ,תל אביב - יפו ,6511 ,ש לדוגמא
5000 ,תל אביב - יפו ,6512 ,ש לדוגמא ב
5000 ,תל אביב - יפו ,6791 ,ש מגורים ב
5000 ,תל אביב - יפו ,6526 ,ש מחל
5000 ,תל אביב - יפו ,6527 ,ש מעוז אביב
5000 ,תל אביב - יפו ,6540 ,ש מעונות
5000 ,תל אביב - יפו ,6513 ,ש נוה אביבים
5000 ,תל אביב - יפו ,6541 ,ש נוה משכן
5000 ,תל אביב - יפו ,6802 ,ש נוה צהל
5000 ,תל אביב - יפו ,6832 ,ש נוה שלם
5000 ,תל אביב - יפו ,6542 ,ש נוה שרת
5000 ,תל אביב - יפו ,6744 ,ש סחנה א
5000 ,תל אביב - יפו ,6781 ,ש סלע ק שלום
5000 ,תל אביב - יפו ,6528 ,ש עולי צכיה
5000 ,תל אביב - יפו ,6727 ,ש עממי שד ירושלים
5000 ,תל אביב - יפו ,6782 ,ש צבא קבע ק שלום
5000 ,תל אביב - יפו ,6545 ,ש צהלה
5000 ,תל אביב - יפו ,6728 ,ש צהלון
5000 ,תל אביב - יפו ,6783 ,ש קרית שלום
5000 ,תל אביב - יפו ,6515 ,ש רמת אביב
5000 ,תל אביב - יפו ,6546 ,ש רמת החיל
5000 ,תל אביב - יפו ,6792 ,ש רמת ישראל
5000 ,תל אביב - יפו ,6547 ,ש שנחאי
5000 ,תל אביב - יפו ,4652 ,ש"בי יעקב מאור
5000 ,תל אביב - יפו ,161 ,ש"י
5000 ,תל אביב - יפו ,708 ,ש"ץ
5000 ,תל אביב - יפו ,1534 ,שאר ישוב
5000 ,תל אביב - יפו ,1637 ,שארית ישראל
5000 ,תל אביב - יפו ,1327 ,שבזי
5000 ,תל אביב - יפו ,1535 ,שבח
5000 ,תל אביב - יפו ,2829 ,שבט
5000 ,תל אביב - יפו ,157 ,שבט אשר
5000 ,תל אביב - יפו ,149 ,שבט בנימין
5000 ,תל אביב - יפו ,147 ,שבט גד
5000 ,תל אביב - יפו ,156 ,שבט דן
5000 ,תל אביב - יפו ,154 ,שבט זבולון
5000 ,תל אביב - יפו ,153 ,שבט יהודה
5000 ,תל אביב - יפו ,158 ,שבט יוסף
5000 ,תל אביב - יפו ,152 ,שבט יששכר
5000 ,תל אביב - יפו ,151 ,שבט לוי
5000 ,תל אביב - יפו ,146 ,שבט ראובן
5000 ,תל אביב - יפו ,150 ,שבט שמעון
5000 ,תל אביב - יפו ,1983 ,שבטי ישראל
5000 ,תל אביב - יפו ,1797 ,שביב
5000 ,תל אביב - יפו ,2074 ,שביל 2161
5000 ,תל אביב - יפו ,4092 ,שביל אג'ימן ישעיהו
5000 ,תל אביב - יפו ,1432 ,שביל האטד
5000 ,תל אביב - יפו ,2517 ,שביל החלב
5000 ,תל אביב - יפו ,2692 ,שביל מרדכי
5000 ,תל אביב - יפו ,2258 ,שביל עכו
5000 ,תל אביב - יפו ,2832 ,שבלים
5000 ,תל אביב - יפו ,1125 ,שבע השקמים
5000 ,תל אביב - יפו ,2740 ,שבתאי
5000 ,תל אביב - יפו ,4557 ,שגאל מרק
5000 ,תל אביב - יפו ,1897 ,שגב
5000 ,תל אביב - יפו ,425 ,שד נורדאו
5000 ,תל אביב - יפו ,818 ,שד סמאטס
5000 ,תל אביב - יפו ,1272 ,שד"ל
5000 ,תל אביב - יפו ,172 ,שד' אבנר מאיר
5000 ,תל אביב - יפו ,751 ,שד' בן ציון
5000 ,תל אביב - יפו ,1035 ,שד' דוד המלך
5000 ,תל אביב - יפו ,1939 ,שד' הבעש"ט
5000 ,תל אביב - יפו ,2549 ,שד' ההשכלה
5000 ,תל אביב - יפו ,2418 ,שד' הותיקים
5000 ,תל אביב - יפו ,2665 ,שד' החי"ל
5000 ,תל אביב - יפו ,2656 ,שד' המעפילים
5000 ,תל אביב - יפו ,909 ,שד' הציונות
5000 ,תל אביב - יפו ,2371 ,שד' הר ציון
5000 ,תל אביב - יפו ,2322 ,שד' השלכת
5000 ,תל אביב - יפו ,2222 ,שד' ושינגטון
5000 ,תל אביב - יפו ,1935 ,שד' חב"ד
5000 ,תל אביב - יפו ,2363 ,שד' חכמי ישראל
5000 ,תל אביב - יפו ,2642 ,שד' יד לבנים
5000 ,תל אביב - יפו ,1545 ,שד' יהודית
5000 ,תל אביב - יפו ,464 ,שד' יוצ"ר
5000 ,תל אביב - יפו ,1620 ,שד' ירושלים
5000 ,תל אביב - יפו ,722 ,שד' מסריק
5000 ,תל אביב - יפו ,180 ,שד' רוקח
5000 ,תל אביב - יפו ,1037 ,שד' שאול המלך
5000 ,תל אביב - יפו ,2959 ,שד' ששת הימים
5000 ,תל אביב - יפו ,749 ,שד' תרס"ט
5000 ,תל אביב - יפו ,1170 ,שדה תעופה שדה דב
5000 ,תל אביב - יפו ,4552 ,שדמי נחום
5000 ,תל אביב - יפו ,537 ,שדרות בן גוריון
5000 ,תל אביב - יפו ,723 ,שדרות ח"ן
5000 ,תל אביב - יפו ,1103 ,שדרות רוטשילד
5000 ,תל אביב - יפו ,386 ,שוהם מתתיהו
5000 ,תל אביב - יפו ,4068 ,שוהם צילה
5000 ,תל אביב - יפו ,1961 ,שויצר דוביד
5000 ,תל אביב - יפו ,744 ,שולמית
5000 ,תל אביב - יפו ,2554 ,שולמן
5000 ,תל אביב - יפו ,1515 ,שונצינו
5000 ,תל אביב - יפו ,4163 ,שוסטקוביץ דימיטרי
5000 ,תל אביב - יפו ,2263 ,שוקן
5000 ,תל אביב - יפו ,917 ,שור
5000 ,תל אביב - יפו ,220 ,שורר חיים
5000 ,תל אביב - יפו ,3007 ,שז"ר זלמן
5000 ,תל אביב - יפו ,2352 ,שח"ל
5000 ,תל אביב - יפו ,4004 ,שטח התערוכה הישנה
5000 ,תל אביב - יפו ,1536 ,שטיבל
5000 ,תל אביב - יפו ,1305 ,שטיין
5000 ,תל אביב - יפו ,344 ,שטיינר
5000 ,תל אביב - יפו ,2311 ,שטינברג
5000 ,תל אביב - יפו ,849 ,שטינמן
5000 ,תל אביב - יפו ,2688 ,שטינשנידר
5000 ,תל אביב - יפו ,720 ,שטנד
5000 ,תל אביב - יפו ,1247 ,שטראוס
5000 ,תל אביב - יפו ,1030 ,שטרוק
5000 ,תל אביב - יפו ,4701 ,שטרייכמן יחזקאל
5000 ,תל אביב - יפו ,817 ,שטריקר
5000 ,תל אביב - יפו ,2257 ,שטרן אברהם (יאיר)
5000 ,תל אביב - יפו ,4654 ,שטרן איזק
5000 ,תל אביב - יפו ,1793 ,שטרנהרץ
5000 ,תל אביב - יפו ,544 ,שי"ר
5000 ,תל אביב - יפו ,2324 ,שיבת ציון
5000 ,תל אביב - יפו ,272 ,שיטרית
5000 ,תל אביב - יפו ,1110 ,שיינקין
5000 ,תל אביב - יפו ,2908 ,שייקה דן
5000 ,תל אביב - יפו ,1017 ,שילה
5000 ,תל אביב - יפו ,2433 ,שילר
5000 ,תל אביב - יפו ,4516 ,שינדלר אוסקר
5000 ,תל אביב - יפו ,273 ,שינמן פנחס
5000 ,תל אביב - יפו ,2359 ,שיפר
5000 ,תל אביב - יפו ,3013 ,שיק
5000 ,תל אביב - יפו ,6742 ,שכ אבו כביר
5000 ,תל אביב - יפו ,6508 ,שכ ביצרון
5000 ,תל אביב - יפו ,6803 ,שכ גב אלישיב
5000 ,תל אביב - יפו ,6771 ,שכ גב משה א
5000 ,תל אביב - יפו ,6615 ,שכ גב עמל א
5000 ,תל אביב - יפו ,6601 ,שכ גב עמל ב
5000 ,תל אביב - יפו ,6816 ,שכ גב שמשון
5000 ,תל אביב - יפו ,6533 ,שכ גבעת עליה
5000 ,תל אביב - יפו ,6517 ,שכ גלילות
5000 ,תל אביב - יפו ,6852 ,שכ גן ההגנה
5000 ,תל אביב - יפו ,6548 ,שכ גן הירקון
5000 ,תל אביב - יפו ,6770 ,שכ דקר (יפו א')
5000 ,תל אביב - יפו ,6817 ,שכ הארגזים
5000 ,תל אביב - יפו ,6818 ,שכ הבולגרים
5000 ,תל אביב - יפו ,6691 ,שכ הרכבת
5000 ,תל אביב - יפו ,6509 ,שכ התקווה
5000 ,תל אביב - יפו ,6652 ,שכ ולהלה
5000 ,תל אביב - יפו ,6653 ,שכ חסן בק
5000 ,תל אביב - יפו ,6507 ,שכ יד אליהו
5000 ,תל אביב - יפו ,6819 ,שכ ידידיה
5000 ,תל אביב - יפו ,6661 ,שכ יפו (דרום)
5000 ,תל אביב - יפו ,6662 ,שכ יפו (מרכז)
5000 ,תל אביב - יפו ,6665 ,שכ יפו (צפון)
5000 ,תל אביב - יפו ,6616 ,שכ כ סומיל
5000 ,תל אביב - יפו ,6502 ,שכ כוכב הצפון
5000 ,תל אביב - יפו ,6650 ,שכ כרם התימנים
5000 ,תל אביב - יפו ,6820 ,שכ לבנה
5000 ,תל אביב - יפו ,6506 ,שכ מונטיפיורי
5000 ,תל אביב - יפו ,6571 ,שכ מחלול א
5000 ,תל אביב - יפו ,6572 ,שכ מחלול ב
5000 ,תל אביב - יפו ,6773 ,שכ מכבי חדשה
5000 ,תל אביב - יפו ,6701 ,שכ מכבי ישנה
5000 ,תל אביב - יפו ,6523 ,שכ מעוז אביב ב'
5000 ,תל אביב - יפו ,6531 ,שכ נאות אפקה א'
5000 ,תל אביב - יפו ,6532 ,שכ נאות אפקה ב'
5000 ,תל אביב - יפו ,6755 ,שכ נוה שאנן
5000 ,תל אביב - יפו ,6640 ,שכ נווה חן
5000 ,תל אביב - יפו ,6655 ,שכ נווה צדק
5000 ,תל אביב - יפו ,6585 ,שכ נורדיה
5000 ,תל אביב - יפו ,6544 ,שכ נחלת יצחק
5000 ,תל אביב - יפו ,6610 ,שכ עג'מי
5000 ,תל אביב - יפו ,6774 ,שכ עובד
5000 ,תל אביב - יפו ,6821 ,שכ עזרא
5000 ,תל אביב - יפו ,6521 ,שכ פלורנטין
5000 ,תל אביב - יפו ,6716 ,שכ קזנובה
5000 ,תל אביב - יפו ,6630 ,שכ קרית שאול
5000 ,תל אביב - יפו ,6756 ,שכ ראשלצ
5000 ,תל אביב - יפו ,6522 ,שכ רביבים
5000 ,תל אביב - יפו ,6524 ,שכ רמת אביב ג'
5000 ,תל אביב - יפו ,6729 ,שכ שבת
5000 ,תל אביב - יפו ,6775 ,שכ שיבת ציון
5000 ,תל אביב - יפו ,6518 ,שכ שיך מוניס
5000 ,תל אביב - יפו ,6784 ,שכ שמעון
5000 ,תל אביב - יפו ,6505 ,שכ שפירא
5000 ,תל אביב - יפו ,6717 ,שכ שפת הים
5000 ,תל אביב - יפו ,6529 ,שכ תל ברוך
5000 ,תל אביב - יפו ,6745 ,שכון ד
5000 ,תל אביב - יפו ,615 ,של"ג
5000 ,תל אביב - יפו ,1611 ,שלבים
5000 ,תל אביב - יפו ,1348 ,שלהבית
5000 ,תל אביב - יפו ,1810 ,שלוחות
5000 ,תל אביב - יפו ,623 ,שלום עליכם
5000 ,תל אביב - יפו ,803 ,שלומציון המלכה
5000 ,תל אביב - יפו ,295 ,שלונסקי
5000 ,תל אביב - יפו ,1336 ,שלוש
5000 ,תל אביב - יפו ,2401 ,שלושת החיצים
5000 ,תל אביב - יפו ,825 ,שלזינגר
5000 ,תל אביב - יפו ,710 ,שלמה המלך
5000 ,תל אביב - יפו ,484 ,שלמה קאשי
5000 ,תל אביב - יפו ,2730 ,שלמון
5000 ,תל אביב - יפו ,1860 ,שם הגדולים
5000 ,תל אביב - יפו ,317 ,שמגר
5000 ,תל אביב - יפו ,1716 ,שמואל בן עדיה
5000 ,תל אביב - יפו ,526 ,שמואל הנגיד
5000 ,תל אביב - יפו ,1955 ,שמואל ירחינאי
5000 ,תל אביב - יפו ,2774 ,שמחה
5000 ,תל אביב - יפו ,1688 ,שמי יחיאל
5000 ,תל אביב - יפו ,142 ,שמיר
5000 ,תל אביב - יפו ,1648 ,שמעון בן זומא
5000 ,תל אביב - יפו ,1649 ,שמעון בן עזאי
5000 ,תל אביב - יפו ,1705 ,שמעון הבורסקי
5000 ,תל אביב - יפו ,1645 ,שמעון הצדיק
5000 ,תל אביב - יפו ,422 ,שמעון התרסי
5000 ,תל אביב - יפו ,4590 ,שמעון מלחי
5000 ,תל אביב - יפו ,106 ,שמעוני
5000 ,תל אביב - יפו ,1646 ,שמעיה
5000 ,תל אביב - יפו ,748 ,שמריהו לוין
5000 ,תל אביב - יפו ,1365 ,שמרלינג
5000 ,תל אביב - יפו ,731 ,שמשון
5000 ,תל אביב - יפו ,1131 ,שניאור זלמן
5000 ,תל אביב - יפו ,1674 ,שניצלר
5000 ,תל אביב - יפו ,4776 ,שניצר שמואל
5000 ,תל אביב - יפו ,264 ,שניר
5000 ,תל אביב - יפו ,1441 ,שנקר אריה
5000 ,תל אביב - יפו ,2641 ,שער הגיא
5000 ,תל אביב - יפו ,407 ,שער ציון
5000 ,תל אביב - יפו ,1756 ,שערי ניקנור
5000 ,תל אביב - יפו ,2380 ,שערי תשובה
5000 ,תל אביב - יפו ,139 ,שפיה
5000 ,תל אביב - יפו ,702 ,שפינוזה
5000 ,תל אביב - יפו ,2456 ,שפיר קליין
5000 ,תל אביב - יפו ,2628 ,שפירא ישראל
5000 ,תל אביב - יפו ,747 ,שפירא צבי
5000 ,תל אביב - יפו ,1558 ,שפע טל
5000 ,תל אביב - יפו ,1537 ,שפרינצק
5000 ,תל אביב - יפו ,1708 ,שפתי ישנים
5000 ,תל אביב - יפו ,1551 ,שץ גגה גרשון
5000 ,תל אביב - יפו ,3010 ,שקד
5000 ,תל אביב - יפו ,4689 ,שרון אריה
5000 ,תל אביב - יפו ,1721 ,שריד
5000 ,תל אביב - יפו ,2998 ,שרים אליהו הרב
5000 ,תל אביב - יפו ,2159 ,שרירא גאון
5000 ,תל אביב - יפו ,4126 ,שרמן משה
5000 ,תל אביב - יפו ,1306 ,שרעבי
5000 ,תל אביב - יפו ,4084 ,שרעבי שלום
5000 ,תל אביב - יפו ,2819 ,שרשים
5000 ,תל אביב - יפו ,906 ,שרת משה
5000 ,תל אביב - יפו ,2773 ,ששון
5000 ,תל אביב - יפו ,2815 ,שתולים
5000 ,תל אביב - יפו ,1836 ,שתי האחיות
5000 ,תל אביב - יפו ,331 ,תבואות הארץ
5000 ,תל אביב - יפו ,4525 ,תבין אלי
5000 ,תל אביב - יפו ,1966 ,תג'ר ציונה
5000 ,תל אביב - יפו ,2799 ,תדהר דוד
5000 ,תל אביב - יפו ,2527 ,תובל
5000 ,תל אביב - יפו ,833 ,תוספתא
5000 ,תל אביב - יפו ,2523 ,תוצרת הארץ
5000 ,תל אביב - יפו ,1517 ,תושיה
5000 ,תל אביב - יפו ,1726 ,תות
5000 ,תל אביב - יפו ,1312 ,תחכמוני
5000 ,תל אביב - יפו ,2275 ,תחנה מרכזית
5000 ,תל אביב - יפו ,2320 ,תחנה מרכזית החדשה
5000 ,תל אביב - יפו ,399 ,תירוש
5000 ,תל אביב - יפו ,6503 ,תכנית ל
5000 ,תל אביב - יפו ,9000 ,תל אביב - יפו
5000 ,תל אביב - יפו ,217 ,תל ברוך
5000 ,תל אביב - יפו ,2173 ,תל גבורים
5000 ,תל אביב - יפו ,741 ,תל חי
5000 ,תל אביב - יפו ,6654 ,תל חיים
5000 ,תל אביב - יפו ,2820 ,תמוז
5000 ,תל אביב - יפו ,4786 ,תמיר שמואל
5000 ,תל אביב - יפו ,2618 ,תמנע
5000 ,תל אביב - יפו ,1737 ,תמריר
5000 ,תל אביב - יפו ,2615 ,תעוז
5000 ,תל אביב - יפו ,1734 ,תפוח
5000 ,תל אביב - יפו ,2963 ,תקוע
5000 ,תל אביב - יפו ,2771 ,תרדיון
5000 ,תל אביב - יפו ,1213 ,תרמ"ב
5000 ,תל אביב - יפו ,2395 ,תרפ"א
5000 ,תל אביב - יפו ,1382 ,תרפט
5000 ,תל אביב - יפו ,1351 ,תרצ"ו
5000 ,תל אביב - יפו ,1623 ,תרצה
5000 ,תל אביב - יפו ,931 ,תש"ח
5000 ,תל אביב - יפו ,2772 ,תשבי
5000 ,תל אביב - יפו ,2822 ,תשרי
84 ,תל יוסף ,9000 ,תל יוסף
287 ,תל יצחק ,6500 ,אחוזת פולג
287 ,תל יצחק ,101 ,נוה הדסה
287 ,תל יצחק ,9000 ,תל יצחק
154 ,תל מונד ,241 ,אבני חן
154 ,תל מונד ,114 ,אורנית
154 ,תל מונד ,6515 ,אזור תעשייה
154 ,תל מונד ,104 ,ארבל
154 ,תל מונד ,246 ,ברקת
154 ,תל מונד ,222 ,בת חן
154 ,תל מונד ,218 ,דולב
154 ,תל מונד ,217 ,דליה
154 ,תל מונד ,170 ,דן
154 ,תל מונד ,239 ,דרור
154 ,תל מונד ,251 ,דרך אבנט
154 ,תל מונד ,125 ,דרך בני דרור
154 ,תל מונד ,240 ,דרך הלורד
154 ,תל מונד ,211 ,דרך הראשונים
154 ,תל מונד ,183 ,האגס
154 ,תל מונד ,163 ,האהבה
154 ,תל מונד ,253 ,האודם
154 ,תל מונד ,121 ,האורן
154 ,תל מונד ,161 ,האחווה
154 ,תל מונד ,201 ,האיריס
154 ,תל מונד ,116 ,האלה
154 ,תל מונד ,118 ,האלון
154 ,תל מונד ,228 ,האנפה
154 ,תל מונד ,184 ,האפרסמון
154 ,תל מונד ,247 ,הארגמן
154 ,תל מונד ,257 ,הבוצר
154 ,תל מונד ,234 ,הברוש
154 ,תל מונד ,173 ,הבשור
154 ,תל מונד ,102 ,הגולן
154 ,תל מונד ,152 ,הגלבוע
154 ,תל מונד ,153 ,הגפן
154 ,תל מונד ,179 ,הדובדבן
154 ,תל מונד ,224 ,הדוכיפת
154 ,תל מונד ,203 ,הדפנה
154 ,תל מונד ,115 ,הדקל
154 ,תל מונד ,204 ,ההדס
154 ,תל מונד ,103 ,ההדרים
154 ,תל מונד ,205 ,הורד
154 ,תל מונד ,256 ,הזורע
154 ,תל מונד ,227 ,הזמיר
154 ,תל מונד ,213 ,החבצלת
154 ,תל מונד ,250 ,החוחית
154 ,תל מונד ,229 ,החסידה
154 ,תל מונד ,215 ,החצב
154 ,תל מונד ,101 ,החרוב
154 ,תל מונד ,167 ,החרות
154 ,תל מונד ,160 ,החרמון
154 ,תל מונד ,206 ,החרצית
154 ,תל מונד ,252 ,הטופז
154 ,תל מונד ,164 ,הידידות
154 ,תל מונד ,244 ,היהלום
154 ,תל מונד ,221 ,היסמין
154 ,תל מונד ,175 ,הירדן
154 ,תל מונד ,171 ,הירמוך
154 ,תל מונד ,200 ,הירקון
154 ,תל מונד ,258 ,הכורם
154 ,תל מונד ,209 ,הכלנית
154 ,תל מונד ,155 ,הכרמל
154 ,תל מונד ,212 ,הלילך
154 ,תל מונד ,235 ,הלימון
154 ,תל מונד ,248 ,הלשם
154 ,תל מונד ,231 ,המטעים
154 ,תל מונד ,237 ,המנגו
154 ,תל מונד ,243 ,המרגלית
154 ,תל מונד ,219 ,המרגנית
154 ,תל מונד ,255 ,הנוטע
154 ,תל מונד ,208 ,הנורית
154 ,תל מונד ,202 ,הנרקיס
154 ,תל מונד ,210 ,הסביון
154 ,תל מונד ,214 ,הסיגלית
154 ,תל מונד ,216 ,הסייפן
154 ,תל מונד ,225 ,הסלעית
154 ,תל מונד ,226 ,הסנונית
154 ,תל מונד ,242 ,הספיר
154 ,תל מונד ,249 ,העפרוני
154 ,תל מונד ,254 ,הערבה
154 ,תל מונד ,180 ,הפטל
154 ,תל מונד ,236 ,הפקאן
154 ,תל מונד ,177 ,הפרדס
154 ,תל מונד ,220 ,הצבעוני
154 ,תל מונד ,232 ,הצבר
154 ,תל מונד ,230 ,הצופית
154 ,תל מונד ,106 ,הרותם
154 ,תל מונד ,159 ,הרימון
154 ,תל מונד ,162 ,הרעות
154 ,תל מונד ,207 ,הרקפת
154 ,תל מונד ,223 ,השחף
154 ,תל מונד ,181 ,השיזף
154 ,תל מונד ,165 ,השלווה
154 ,תל מונד ,117 ,השקד
154 ,תל מונד ,158 ,השקמה
154 ,תל מונד ,119 ,התאנה
154 ,תל מונד ,156 ,התבור
154 ,תל מונד ,120 ,התמר
154 ,תל מונד ,233 ,התפוז
154 ,תל מונד ,182 ,התפוח
154 ,תל מונד ,166 ,התקוה
154 ,תל מונד ,176 ,זויתן
154 ,תל מונד ,238 ,זית
154 ,תל מונד ,193 ,יער אלונים
154 ,תל מונד ,187 ,יער בארי
154 ,תל מונד ,189 ,יער בית קשת
154 ,תל מונד ,192 ,יער ברעם
154 ,תל מונד ,190 ,יער האילנות
154 ,תל מונד ,186 ,יער חורשים
154 ,תל מונד ,188 ,יער לביא
154 ,תל מונד ,185 ,יער להב
154 ,תל מונד ,194 ,יער מגידו
154 ,תל מונד ,191 ,יער ציפורי
154 ,תל מונד ,7001 ,כלא תל מונד
154 ,תל מונד ,108 ,כנען
154 ,תל מונד ,6500 ,כפר זיו
154 ,תל מונד ,172 ,לכיש
154 ,תל מונד ,6501 ,מחנה עולים
154 ,תל מונד ,154 ,מירון
154 ,תל מונד ,178 ,מעלה הפרדס
154 ,תל מונד ,151 ,מצדה
154 ,תל מונד ,109 ,משעול המרוה
154 ,תל מונד ,126 ,משעול התאנה
154 ,תל מונד ,157 ,משעול מוריה
154 ,תל מונד ,105 ,נבו
154 ,תל מונד ,6502 ,נוה עובד ב
154 ,תל מונד ,107 ,סיני
154 ,תל מונד ,122 ,פולג
154 ,תל מונד ,6514 ,קרית חינוך
154 ,תל מונד ,6503 ,ש אלי כהן
154 ,תל מונד ,6504 ,ש הדר חיים א
154 ,תל מונד ,6511 ,ש הדר חיים ב
154 ,תל מונד ,6512 ,ש הדר חיים ג
154 ,תל מונד ,6505 ,ש וולפסון
154 ,תל מונד ,6507 ,ש נוה עובד א
154 ,תל מונד ,6506 ,ש נוה שפרינצק
154 ,תל מונד ,6508 ,ש עמידר צריפ
154 ,תל מונד ,245 ,שוהם
154 ,תל מונד ,174 ,שורק
154 ,תל מונד ,6509 ,שכ אשכול
154 ,תל מונד ,6510 ,שכ יעקב
154 ,תל מונד ,9000 ,תל מונד
103 ,תל עדשים ,101 ,אגוז
103 ,תל עדשים ,102 ,אגס
103 ,תל עדשים ,109 ,גפן
103 ,תל עדשים ,119 ,דרך אלומה
103 ,תל עדשים ,117 ,האורן
103 ,תל עדשים ,114 ,הבוץ
103 ,תל עדשים ,113 ,הברושים
103 ,תל עדשים ,115 ,החרוב
103 ,תל עדשים ,111 ,היקים
103 ,תל עדשים ,110 ,המטע
103 ,תל עדשים ,107 ,העולים
103 ,תל עדשים ,116 ,הראשונים
103 ,תל עדשים ,118 ,סמטת החורש
103 ,תל עדשים ,103 ,רימון
103 ,תל עדשים ,108 ,שזיף
103 ,תל עדשים ,6500 ,שכ אלומה
103 ,תל עדשים ,104 ,תאנה
103 ,תל עדשים ,112 ,תות
103 ,תל עדשים ,9000 ,תל עדשים
103 ,תל עדשים ,105 ,תמר
103 ,תל עדשים ,106 ,תפוח
719 ,תל קציר ,101 ,בית ראשון במולדת
719 ,תל קציר ,9000 ,תל קציר
1054 ,תל שבע ,135 ,אבו בקר
1054 ,תל שבע ,101 ,אבן ח'לדון
1054 ,תל שבע ,116 ,אום ג'ביל
1054 ,תל שבע ,102 ,אלאמל
1054 ,תל שבע ,124 ,אלאס'יל
1054 ,תל שבע ,117 ,אלבאבור
1054 ,תל שבע ,182 ,אלבאניאס
1054 ,תל שבע ,114 ,אלבוסתאן
1054 ,תל שבע ,187 ,אלביר
1054 ,תל שבע ,123 ,אלברק
1054 ,תל שבע ,118 ,אלג'בל
1054 ,תל שבע ,115 ,אלדיר
1054 ,תל שבע ,105 ,אלואדי
1054 ,תל שבע ,119 ,אלזהר
1054 ,תל שבע ,120 ,אלח'ד'ר
1054 ,תל שבע ,122 ,אלח'רבה
1054 ,תל שבע ,138 ,אלח'רוב
1054 ,תל שבע ,121 ,אלטור
1054 ,תל שבע ,104 ,אלכרימה
1054 ,תל שבע ,141 ,אלמאמון
1054 ,תל שבע ,103 ,אלמותנבי
1054 ,תל שבע ,106 ,אלמזארב
1054 ,תל שבע ,184 ,אלרוחה
1054 ,תל שבע ,207 ,טאהא חסין
1054 ,תל שבע ,185 ,נוח
1054 ,תל שבע ,183 ,רומאן
1054 ,תל שבע ,6720 ,שכ 10
1054 ,תל שבע ,6727 ,שכ 20
1054 ,תל שבע ,6718 ,שכ 1
1054 ,תל שבע ,6721 ,שכ 11
1054 ,תל שבע ,6728 ,שכ 21
1054 ,תל שבע ,6719 ,שכ 2
1054 ,תל שבע ,6722 ,שכ 12
1054 ,תל שבע ,6703 ,שכ 3
1054 ,תל שבע ,6723 ,שכ 13
1054 ,תל שבע ,6712 ,שכ 4
1054 ,תל שבע ,6724 ,שכ 14
1054 ,תל שבע ,6710 ,שכ 5
1054 ,תל שבע ,6715 ,שכ 15
1054 ,תל שבע ,6716 ,שכ 45
1054 ,תל שבע ,6702 ,שכ 6
1054 ,תל שבע ,6726 ,שכ 16
1054 ,תל שבע ,6701 ,שכ 26
1054 ,תל שבע ,6706 ,שכ 36
1054 ,תל שבע ,6709 ,שכ 7
1054 ,תל שבע ,6713 ,שכ 37
1054 ,תל שבע ,6704 ,שכ 8
1054 ,תל שבע ,6707 ,שכ 28
1054 ,תל שבע ,6708 ,שכ 38
1054 ,תל שבע ,6717 ,שכ 9
1054 ,תל שבע ,6711 ,שכ ישנה
1054 ,תל שבע ,9000 ,תל שבע
1054 ,תל שבע ,186 ,תלה
1283 ,תל תאומים ,101 ,קרוונים
1283 ,תל תאומים ,9000 ,תל תאומים
3719 ,תלם ,9000 ,תלם
1051 ,תלמי אליהו ,9000 ,תלמי אליהו
2003 ,תלמי אלעזר ,100 ,דרך השדות
2003 ,תלמי אלעזר ,101 ,דרך שאול
2003 ,תלמי אלעזר ,102 ,הבוסתן
2003 ,תלמי אלעזר ,103 ,ההדרים
2003 ,תלמי אלעזר ,109 ,המחלבה
2003 ,תלמי אלעזר ,108 ,הנוקדים
2003 ,תלמי אלעזר ,104 ,הראשונים
2003 ,תלמי אלעזר ,105 ,השקמה
2003 ,תלמי אלעזר ,107 ,התבלינים
2003 ,תלמי אלעזר ,106 ,התורמוס
2003 ,תלמי אלעזר ,110 ,סמ ילדות
2003 ,תלמי אלעזר ,112 ,שביל הגפן
2003 ,תלמי אלעזר ,111 ,שביל הזית
2003 ,תלמי אלעזר ,113 ,שביל החיטה
2003 ,תלמי אלעזר ,114 ,שביל החרוב
2003 ,תלמי אלעזר ,115 ,שביל הכותנה
2003 ,תלמי אלעזר ,116 ,שביל הנרקיס
2003 ,תלמי אלעזר ,117 ,שביל הרימון
2003 ,תלמי אלעזר ,118 ,שביל השעורה
2003 ,תלמי אלעזר ,9000 ,תלמי אלעזר
2050 ,תלמי ביל"ו ,9000 ,תלמי ביל"ו
1237 ,תלמי יוסף ,9000 ,תלמי יוסף
727 ,תלמי יחיאל ,103 ,הגפן
727 ,תלמי יחיאל ,104 ,הדרים
727 ,תלמי יחיאל ,106 ,הזית
727 ,תלמי יחיאל ,105 ,החיטה
727 ,תלמי יחיאל ,102 ,החקלאים
727 ,תלמי יחיאל ,107 ,הרימון
727 ,תלמי יחיאל ,101 ,שכ קרוונים
727 ,תלמי יחיאל ,9000 ,תלמי יחיאל
744 ,תלמי יפה ,9000 ,תלמי יפה
814 ,תלמים ,108 ,גפן
814 ,תלמים ,107 ,זית
814 ,תלמים ,104 ,חיטה
814 ,תלמים ,106 ,רימון
814 ,תלמים ,102 ,שדרת שבעת המינים
814 ,תלמים ,105 ,שעורה
814 ,תלמים ,101 ,תאנה
814 ,תלמים ,9000 ,תלמים
814 ,תלמים ,103 ,תמר
1244 ,תמרת ,103 ,האורן
1244 ,תמרת ,109 ,האיריס
1244 ,תמרת ,104 ,האלה
1244 ,תמרת ,101 ,האלון
1244 ,תמרת ,106 ,הברוש
1244 ,תמרת ,117 ,הגפן
1244 ,תמרת ,111 ,הדס
1244 ,תמרת ,107 ,הזית
1244 ,תמרת ,105 ,החרוב
1244 ,תמרת ,102 ,הלבנה
1244 ,תמרת ,113 ,הלוטם
1244 ,תמרת ,114 ,הסירה
1244 ,תמרת ,120 ,הרימון
1244 ,תמרת ,108 ,השיטה
1244 ,תמרת ,122 ,התאנה
1244 ,תמרת ,119 ,התמר
1244 ,תמרת ,115 ,יערה
1244 ,תמרת ,121 ,יקינטון
1244 ,תמרת ,110 ,מורן
1244 ,תמרת ,112 ,קידה
1244 ,תמרת ,118 ,רקפת
1244 ,תמרת ,116 ,תדהר
1244 ,תמרת ,9000 ,תמרת
2002 ,תנובות ,122 ,אלומים
2002 ,תנובות ,101 ,גלבוע
2002 ,תנובות ,115 ,הגולן
2002 ,תנובות ,102 ,הגלעד
2002 ,תנובות ,103 ,החרמון
2002 ,תנובות ,104 ,הכרמל
2002 ,תנובות ,105 ,המוריה
2002 ,תנובות ,106 ,הצופים
2002 ,תנובות ,107 ,השומרון
2002 ,תנובות ,108 ,התבור
2002 ,תנובות ,109 ,חורב
2002 ,תנובות ,124 ,יבולים
2002 ,תנובות ,110 ,כנען
2002 ,תנובות ,111 ,מצדה
2002 ,תנובות ,112 ,נבו
2002 ,תנובות ,123 ,נירים
2002 ,תנובות ,125 ,עומר
2002 ,תנובות ,113 ,עצמון
2002 ,תנובות ,114 ,ציון
2002 ,תנובות ,120 ,שיבולים
2002 ,תנובות ,121 ,תלמים
2002 ,תנובות ,9000 ,תנובות
752 ,תעוז ,102 ,הגיא
752 ,תעוז ,103 ,ההר
752 ,תעוז ,101 ,המעיין
752 ,תעוז ,9000 ,תעוז
709 ,תפרח ,6500 ,ארץ הנגב
709 ,תפרח ,6501 ,קרית חינוך
709 ,תפרח ,9000 ,תפרח
665 ,תקומה ,103 ,הזורעים
665 ,תקומה ,105 ,החלוצים
665 ,תקומה ,101 ,המייסדים
665 ,תקומה ,104 ,התקווה
665 ,תקומה ,102 ,י"א הנקודות
665 ,תקומה ,6001 ,כיכר יחד שבטי ישראל
665 ,תקומה ,106 ,משואה לתקומה
665 ,תקומה ,107 ,תורה ועבודה
665 ,תקומה ,9000 ,תקומה
3563 ,תקוע ,9000 ,תקוע
970 ,תראבין א-צאנע (שבט) ,101 ,ואדי אל חליל
970 ,תראבין א-צאנע (שבט) ,9000 ,תראבין א-צאנע (שבט)
1346 ,תראבין א-צאנע(ישוב) ,101 ,רח 1
1346 ,תראבין א-צאנע(ישוב) ,111 ,רח 11
1346 ,תראבין א-צאנע(ישוב) ,121 ,רח 21
1346 ,תראבין א-צאנע(ישוב) ,102 ,רח 2
1346 ,תראבין א-צאנע(ישוב) ,112 ,רח 12
1346 ,תראבין א-צאנע(ישוב) ,103 ,רח 3
1346 ,תראבין א-צאנע(ישוב) ,113 ,רח 13
1346 ,תראבין א-צאנע(ישוב) ,123 ,רח 23
1346 ,תראבין א-צאנע(ישוב) ,114 ,רח 14
1346 ,תראבין א-צאנע(ישוב) ,105 ,רח 5
1346 ,תראבין א-צאנע(ישוב) ,115 ,רח 15
1346 ,תראבין א-צאנע(ישוב) ,106 ,רח 6
1346 ,תראבין א-צאנע(ישוב) ,116 ,רח 16
1346 ,תראבין א-צאנע(ישוב) ,107 ,רח 7
1346 ,תראבין א-צאנע(ישוב) ,117 ,רח 17
1346 ,תראבין א-צאנע(ישוב) ,108 ,רח 8
1346 ,תראבין א-צאנע(ישוב) ,109 ,רח 9
1346 ,תראבין א-צאנע(ישוב) ,119 ,רח 19
1346 ,תראבין א-צאנע(ישוב) ,6701 ,שכ 1
1346 ,תראבין א-צאנע(ישוב) ,6702 ,שכ 2
1346 ,תראבין א-צאנע(ישוב) ,6703 ,שכ 3
1346 ,תראבין א-צאנע(ישוב) ,6704 ,שכ 4
1346 ,תראבין א-צאנע(ישוב) ,6705 ,שכ 5
1346 ,תראבין א-צאנע(ישוב) ,9000 ,תראבין א-צאנע(ישוב)
778 ,תרום ,9000 ,תרום
`;