import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { API_DOMAIN_IMG } from "../Libs/config";

function SingleEventType({
  eventTypeImg,
  eventTypeImgNoPeople,
  eventTypeIcon,
  eventTypeName,
  eventTypeNameEng,
  // updateCurrWishCategories,
  event,
  // toggleCreatePopup,
  setCurrentWishlistMainCategories
}) {


  console.log('event: ',event)

  // const setCurrentWishlistMainCategories = (event) => {
  //   const updatedWishlistMainCat = JSON.parse(event?.mainCategory)
  //     .filter(cat => cat.checked)
  //     .map(cat => {
  //       if (cat.checked) {
  //         return cat;
  //       }
  //     }

  //     )
  //   updateCurrWishCategories(updatedWishlistMainCat)
  //   toggleCreatePopup(false, event)
  // }

  // console.log(`${API_DOMAIN_IMG + eventTypeImg}`,'API_DOMAIN: ',API_DOMAIN_IMG,'eventTypeImg: ',eventTypeImg)
  return (
    <div
      className="main-choose-event__single-event-type single-event-type"
      data-type={eventTypeNameEng}
    >
      <div
        onClick={()=>setCurrentWishlistMainCategories(event)}
        to="/create-event"
        // state={{
        //   eventTypeName: eventTypeName,
        //   eventTypeNameEng: eventTypeNameEng,
        // }}
        className="single-event-type__content"
      >
        <div className="single-event-type__image-ibg">
          <img src={`${API_DOMAIN_IMG}${eventTypeImg}`} className="img-people" alt={eventTypeImg} />
          <img src={eventTypeImgNoPeople} className="img-no-people" alt={1} />
        </div>
        <div className="single-event-type__text-content">
          <div className="single-event-type__icon">
            <img src={`${API_DOMAIN_IMG}${eventTypeIcon}`} alt="icon" />
          </div>
          <h3 className="single-event-type__title">{eventTypeName}</h3>
          <div className="single-event-type__btn-container btn-container btn-container_center">
            <button className="btn">כן! אני רוצה לפתוח אירוע</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleEventType;
