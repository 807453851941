import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import SelectedGiftsPopup from "./selected-gifts-popup";

export default function ItemAddedToCartPopup({
  updatePopupState,
  addItemHandler,
  isItemAdded,
  currWishlist,
  currUserObject,
  setCurrWishlist,
  updatePopupSelectedGifts,
}) {
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const additionalGift = Boolean(URLParams?.get("additionalGift"));
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  useEffect(() => {
    console.log("addPopupDebug currUserObject", currUserObject);
    return () => {
      updatePopupState(false);
    };
  }, []);

  const closePopupHandler = () => {
    
    addItemHandler((prevObj) => {
      const newObj = { ...prevObj, added: false };
      return newObj;
    });
    updatePopupState(false);
  };

  return (
    <>
   

      <div
        id="item-added-to-cart-popup"
        aria-hidden="true"
        className="popup popup_show"
      >
        <div className="popup__wrapper">
          <div className="popup__content">
            <div className="popup__bg-ibg">
              <img src="/img/popups/popup-green-bg.png" alt="green hearts" />
            </div>
            <button
              data-close
              type="button"
              onClick={closePopupHandler}
              className="popup__close"
            ></button>
            <div className="popup__body">
              <div className="popup__image-ibg_contain">
                <img
                  src="/img/popups/item-added-to-cart.svg"
                  alt="item added to cart"
                />
              </div>
              <h3 className="popup__title">
                המוצר התווסף לסל על סך של <span className="money-icon">₪</span>
                {isItemAdded.prodPrice}
                <br />
                יש לך {isItemAdded.wishlistProductsNumber} מוצרים בסך של
              </h3>
              <p style={isMobile ? { fontSize: '4.25rem' } : {}}
              className="popup__price">
                <span className="money-icon">₪</span>
                <span className="text">{parseFloat(isItemAdded.wishlistPrice).toFixed(2)}</span>
                </p>

              <div className="popup__buttons-container btn-container-center">
                <button
                  type="button"
                  data-close
                  onClick={closePopupHandler}
                  className="btn-white gift"
                >
                  להמשיך לבחור מתנות
                  <span className="icon">
                    <img src="/img/svg/gift-black.svg" alt="" />
                  </span>
                </button>
                <button
                  type="button"
                  data-close
                  onClick={() => {
                   closePopupHandler();
                   updatePopupSelectedGifts(true);
                  }}
                  
                  className="btn-white gift"
                >
                  צפיה ברשימת המתנות
                  <span className="icon">
                    <img src="/img/svg/gift-black.svg" alt="" />
                  </span>
                </button>

                {currUserObject == null ? (
                  <NavLink
                    to={`/credentials-new-wishlist/${currWishlistId}`}
                    onClick={() => {
                      closePopupHandler();
                    }}
                    data-close
                    className="btn-white continue-edit"
                  >
                    {additionalGift
                      ? "Add gift to wishlist"
                      : "המשך ביצירת האירוע"}
                  </NavLink>
                ) : (
                  <>
                    {!additionalGift && (
                      <NavLink
                        to={
                          additionalGift
                            ? { pathname: 'my-events', state: { openCategories: 1 } }
                            : `/create-event-second-step/${currWishlistId}`
                        }
                        onClick={() => {
                          closePopupHandler();
                        }}
                        data-close
                        className="btn-white continue-edit"
                      >
                        {additionalGift
                          ? "Add gift to wishlist"
                          : "המשך ביצירת האירוע"}
                      </NavLink>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
