import React from "react";
import { blessingBg, blessingBgNoPeople } from "../Assets/images";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function SuccessfullPaymentMyBank() {
  const navigate = useNavigate();

  const URLParams = new URLSearchParams(window.location.search);
  const didRegister = URLParams?.get("didRegister");

  const handleNavigation = () => {
    navigate('/my-bank', { state: { purchased: true } });
  };


  return (
    <main className="successful-payment" data-fullscreen>
      <section className="successful-payment__main main-successful-payment">
        <div className="main-successful-payment__bg-image-ibg">
          <img src={blessingBg} className="img-people" alt="bg" />
          <img src={blessingBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="main-successful-payment__content-window">
          <h1 className="main-successful-payment__title">להתראות</h1>
          <h2 className="main-successful-payment__subtitle">
            סיימת את התהליך נתראה במסיבה
          </h2>
          <button
            style={{ paddingLeft: '2em' }}
            className="main-successful-payment__subtitle btn"
            onClick={handleNavigation}
          >
            Go to purchased
          </button>
        </div>
      </section>
    </main>
  );
}

export default SuccessfullPaymentMyBank;
