import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import TopBar from "../Components/TopBar";
import CategoryCard from "../Components/CategoryCard";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Tooltip from '@mui/material/Tooltip';

import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom/dist";
import CreateEventPopup from "../Popups/CreateEventPopup";

import { eventTypeImgNoPeople1 } from "../Assets/images";
import ProductsFilter from "../Components/products-filter";

import SingleEventType from "../Components/single-event-type";
import NotLoggedTopBar from "../Components/NotLoggedTopBar";
import { Header } from "rsuite";

const tabButtons = [
  { text: "אירוע חדש", id: 0 },
  { text: "האירועים שלי", id: 1 },
  { text: "היסטוריית אירועים", id: 2 },
  { text: "אירועים שהוזמנתי", id: 3 },
  { text: "אירועים עתידים", id: 4 },

];

export default function MyEvents({
  currUserObject,
  currWishlist,
  setCurrWishlist,
  setWhishlistArray,
  whishlistArray,
  myEventsActiveTab,
  setMyEventsActiveTab,
  updateCurrWishCategories,
  filterToggle,
  setFilterObject,
  wishlistCategories,
  setWishCategories,
  isLoggedIn,
  setEventType,
}) {
  // const [wishlistCategories, setWishCategories] = useState([])
  const [isCreatePopup, setIsCreatePopup] = useState(false);
  const [currentIndex, setCurrentIndex] = useState('null');
  const [isMobile, setIsMobile] = useState(false);

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const location = useLocation();
  const [eventData, setEventData] = useState({
    englishName: "",
  });
  const activeWishlistId = location?.state?.activeWishlist;
  const wishlist = location?.state?.activeWishlist;

  const createPopupRef = useRef(null);
  const [activeLinkId, setActiveLinkId] = useState('');
  const [currentLine, setCurrentLine] = useState('null');

  const onMouseOverLink = (id, currentLine) => {
    setActiveLinkId(id)
    setCurrentLine(currentLine)
  }

  const onMouseLeaveLink = () => {
   setActiveLinkId(null)
   setCurrentLine(null)
  }


  const handleCreateEventPopupClose = () => {
    console.log("handleCreateEventPopup");
    setIsCreatePopup(false);
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  console.log(wishlist)

  useEffect(() => {
    if (isCreatePopup) {
      document.documentElement.classList.add("popup-show", "loaded");
    } else {
      document.documentElement.classList.remove("popup-show", "loaded");
    }
  }, [isCreatePopup]);

  const toggleCreatePopup = (data, eventData) => {
    if (eventData) {
      setEventData(eventData);
    }
    // console.log('eventData', eventData)
    setIsCreatePopup((prev) => {
      if (data) {
        return data;
      } else {
        return !prev;
      }
    });
  };

  //1111111111111111111
  const setCurrentWishlistMainCategories = (event) => {
    console.log("setCurrentWishlistMainCategories", event);
    const updatedWishlistMainCat = JSON.parse(event?.mainCategory)
      .filter((cat) => cat.checked)
      .map((cat) => {
        if (cat.checked) {
          return cat;
        }
      });
    setEventType(event);
    localStorage.setItem("eventType", event);

    updateCurrWishCategories(updatedWishlistMainCat);
    toggleCreatePopup(false, event);
  };

  useEffect(() => {
    console.log("wishlist", wishlist);
    if (!wishlist) return;
    setCurrentWishlistMainCategories(wishlist);

    return () => { };
  }, [wishlist]);

  useEffect(() => {
    if (location?.state?.openCategories) {
      changeTabHandler(0);
    }
    if (location?.state?.openTabNumber) {
      changeTabHandler(1);
      switch (location?.state?.openTabNumber) {
        case 0:
          changeTabHandler(0);
          break;
        case 1:
          changeTabHandler(1);
          break;
        case 2:
          changeTabHandler(2);
          break;
        case 3:
          changeTabHandler(3);
          break;
        default:
          break;
      }
    }

    return () => { };
  }, [location?.state]);

  // Extract the state from the history location object

  // Extract the state from the location object

  const navigate = useNavigate();

  useEffect(() => {
    getWishlistList();
  }, []);

  function editWhishListItem() {
    alert("JJJJJJJJJ")
    navigate("/update-wishlist");
  }
///22222222222
  function goToWhilistSelect(wishlist) {
    console.log("goToWhilistSelect_wishlist", wishlist);
    // console.log('goToWhilistSelect_wishlist_1',JSON.parse(wishlist.wishListObj))
    const { id: whishlistId } = wishlist;
    const parsedWishlist = JSON.parse(wishlist.wishListObj);
    const mainCategory = JSON.parse(parsedWishlist.mainCategory);
    
    const updatedWishlistMainCat = mainCategory
      .filter((cat) => cat.checked)
      .map((cat) => cat);
    

      updateCurrWishCategories(updatedWishlistMainCat);

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("my whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(
            `/all-categories-new-event?wishlistId=${whishlistId}&additionalGift=true`,
          );
        } else {
          //setLoading(false);
          alert("Something went wrong 111111111");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }
  const toggleDatePicker = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };

  function getWishlistList() {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetMyWishlists?userId=${currUserObject?.user?.id}`,
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist list", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                myWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong 22222222");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetHistoryWishlists?userId=${currUserObject?.user?.id}`,
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("history wishlists", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                historyWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong 3333333");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

      //*
      axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetFutureWishlists?userId=${currUserObject?.user?.id}`,
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("history wishlists", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                futureWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong 3333333");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetInvitedWishlists?userId=${currUserObject?.user?.id}`,
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("invited wishlists", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                friendsWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong 444444444");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }

  // async function getWishListCategories() {
  //   try {
  //     const response = await axios.get(`${API_DOMAIN}/api/ProductCategory/getWishlistCategories`);
  //     const { data } = response;
  //     if (data.result) {
  //       // console.log('handleGetMainCategories: ', data)
  //       setWishCategories(data.mainCategoriesList)
  //     }
  //   } catch (error) {
  //     alert('Something went wrong getWishListCategories', error)
  //   }
  // }

  // useEffect(() => {
  //   getWishListCategories()
  // }, []);
  const goToCartByWishlist1 = () => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

  };

  

  const goToCartByWishlist = (whishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
            transactions: response.data.newTransictions
          });

          navigate(`/cart/${whishlistId}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const goToChooseGift = (whishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {


let eventDate = new Date(response.data.whishlistObject.eventDate); // Convert to Date object if needed
let currentDate = new Date(); // Get current date

// Calculate the difference in milliseconds
let differenceInTime = currentDate - eventDate;

// Convert the time difference to days, months, and years
let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); // Convert time difference to days
let differenceInMonths = Math.floor(differenceInDays / 30); // Approximate conversion to months
let differenceInYears = Math.floor(differenceInMonths / 12); // Convert months to years

// Check if the eventDate is more than 7 days in the past
if (differenceInDays > 7) {
    let message = "";

    if (differenceInYears >= 1) {
        let remainingMonths = differenceInMonths % 12; // Calculate remaining months after dividing by 12
        message = `האירוע עבר לפני ${differenceInYears} שנים ו-${remainingMonths} חודשים, לא ניתן לבחור מתנות`;
    } else if (differenceInMonths >= 1) {
        message = `האירוע עבר לפני ${differenceInMonths} חודשים, לא ניתן לבחור מתנות`;
    } else {
        message = `האירוע עבר לפני ${differenceInDays} ימים, לא ניתן לבחור מתנות`;
    }

    alert(message);
    return;
}

          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          })
  

          navigate(`/choose-present-or-send-money/${whishlistId}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const changeTabHandler = (id) => {
    setMyEventsActiveTab(id);
  };


  useEffect(()=>{
console.log("wishlistWWWWWWWWWWWWW",whishlistArray)

  },[])

  const removeMyWishlist = (wishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/RemoveWishlist?userId=${currUserObject.user.id}&wishListId=${wishlistId}`,
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          setWhishlistArray((prevObj) => {
            const newMyWishlistsArray = whishlistArray.myWishlists.filter(
              (item) => item.id !== wishlistId,
            );
            const newObj = {
              ...prevObj,
              myWishlists: newMyWishlistsArray,
            };
            return newObj;
          });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const removeHistoryWishlist = (wishlistId) => {
    // Show confirmation dialog
    const confirmation = window.confirm(
      "האם את.ה בטוח.ה שאת.ה רוצה למחוק את האירוע? מחיקת האירוע תמחק את רשימת המתנות שנבחרה לאירוע זה."
    );
  
    // If the user confirms, proceed with the deletion
    if (confirmation) {
      axios
        .get(
          `${API_DOMAIN}/api/ProductCategory/RemoveHistoryWishlist?userId=${currUserObject.user.id}&wishListId=${wishlistId}`
        )
        .then((response) => {
          if (response.data.result) {
            setWhishlistArray((prevObj) => {
              const newHistoryWishlistsArray =
                whishlistArray.historyWishlists.filter(
                  (item) => item.id !== wishlistId
                );
              const newObj = {
                ...prevObj,
                historyWishlists: newHistoryWishlistsArray,
              };
              return newObj;
            });
          } else {
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Error, please try again");
        });
    }
  };
  

  const removeFriendWishlist = (wishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/RemoveInviteWishlist?userId=${currUserObject.user.id}&wishListId=${wishlistId}`,
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          setWhishlistArray((prevObj) => {
            const newFriendWishlistsArray =
              whishlistArray.friendsWishlists.filter(
                (item) => item.id !== wishlistId,
              );
            const newObj = {
              ...prevObj,
              friendsWishlists: newFriendWishlistsArray,
            };
            return newObj;
          });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const shareMyWishlist = (e, wishlistId) => {
    const button = e.target.closest(".share");
    button.style.position = "relative";
    const shareLink =
      window.location.origin + "?inviteWishlistId=" + wishlistId;

    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        const hint = document.createElement("div");
        hint.classList.add("single-books-item__dropdown-table-item");
        hint.innerHTML = `<p class="value">קישור הזמנה לאירוע הועתק ללוח</p>`;
        hint.style.cssText =
          "position: absolute; top: 140%; right: 50%; transform: translateX(50%); width: auto; height: auto; object-fit: unset; white-space: nowrap;";
        button.appendChild(hint);
        setTimeout(() => {
          button.removeChild(hint);
        }, 2000);
      })
      .catch((error) => {
        console.error("Ошибка при копировании в буфер обмена:", error);
      });
  };

  const [sortFilter, setSortFilter] = useState({
    sortType: "asc",
  });

  {/*
  useEffect(() => {
    const sortType = sortFilter.sortType;
    const currentActiveTab = myEventsActiveTab;
    const sortBy = "creationDate";
    // console.log(sortFilter);
    if (
      myEventsActiveTab === 1 ||
      myEventsActiveTab === 2 ||
      myEventsActiveTab === 3
    ) {
      setWhishlistArray((prevObj) => {
        const newObj = prevObj;
        let prevArr = [];
        if (myEventsActiveTab === 1) {
          prevArr = prevObj.myWishlists;
        } else if (myEventsActiveTab === 2) {
          prevArr = prevObj.historyWishlists;
        } else if (myEventsActiveTab === 3) {
          prevArr = prevObj.friendsWishlists;
        }

        let filteredArr = [];
        if (prevArr && prevArr.length) {
          filteredArr = prevArr;

          filteredArr.sort((a, b) => {
            const aValue = String(a[sortBy]);
            const bValue = String(b[sortBy]);
            if (sortType === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          });
        }

        if (myEventsActiveTab === 1) {
          return { ...newObj, myWishlists: filteredArr };
        } else if (myEventsActiveTab === 2) {
          return { ...newObj, historyWishlists: filteredArr };
        } else if (myEventsActiveTab === 3) {
          return { ...newObj, friendsWishlists: filteredArr };
        }
      });
    }
  }, [sortFilter, myEventsActiveTab]);*/}
  console.log("whishlistArray", whishlistArray);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    }
  }, []);

  return (
    <main className="choose-event">
      {myEventsActiveTab === 0 &&

          <TopBar
            imgUrl="../img/common/topbar-bg.png"
            text="אירוע חדש"
            icon="img/svg/party-icon.svg"
          />
        }

      {myEventsActiveTab === 1 && (
        <TopBar
          imgUrl="../img/common/topbar-bg.png"
          text="האירועים שלי"
          icon="img/svg/party-icon.svg"
        />
      )}
      {myEventsActiveTab === 2 && (
        <TopBar
          imgUrl="img/common/topbar-bg-green.png"
          text="היסטורית אירועים"
          icon="img/svg/globe.svg"
        />
      )}
      {myEventsActiveTab === 3 && (
        <TopBar
          imgUrl="img/common/topbar-bg-green.png"
          text="אירועים שהוזמנתי"
          icon="img/svg/globe.svg"
        />
      )}
          {myEventsActiveTab === 4 && (
        <TopBar
          imgUrl="../img/common/topbar-bg.png"
          text="אירועים עתידים"
          icon="img/svg/party-icon.svg"
        />
      )}
    

      {isLoggedIn && (
        <div className="choose-event__breadcrumbs breadcrumbs">
          <div className="breadcrumbs__container">
            <ul className="breadcrumbs__list">
              <li className="breadcrumbs__item">
                <NavLink to="/">דף הבית</NavLink>
              </li>
              {myEventsActiveTab == 0 && (
                <>
                  <li className="breadcrumbs__item">
                    <p>אירוע חדש</p>
                  </li>
                </>
              )}
              {myEventsActiveTab == 1 && (
                <>
                  <li className="breadcrumbs__item">
                    <p>האירועים שלי</p>
                  </li>
                </>
              )}
              {myEventsActiveTab == 2 && (
                <>
                  <li className="breadcrumbs__item">
                    <p>היסטוריית אירועים</p>
                  </li>
                </>
              )}
              {myEventsActiveTab == 3 && (
                <>
                  <li className="breadcrumbs__item">
                    <p>אירועים שהוזמנתי</p>
                  </li>
                </>
              )}
             {myEventsActiveTab == 4 && (
                <>
                  <li className="breadcrumbs__item">
                    <p>אירועים עתידים</p>
                  </li>
                </>
              )}


            </ul>
          </div>
        </div>
      )}

      {isCreatePopup ? (
        <CreateEventPopup
          currUserObject={currUserObject}
          currWishlist={currWishlist}
          setCurrWishlist={setCurrWishlist}
          toggleCreatePopup={toggleCreatePopup}
          eventData={eventData}
          setFilterObject={setFilterObject}
          createPopupRef={createPopupRef}
          handleClose={handleCreateEventPopupClose}
          isLoggedIn={isLoggedIn}
        />
      ) : (
        ""
      )}

      <section className="choose-event__main main-choose-event">
        <div className="main-choose-event__container">
          {isLoggedIn && (
            <div className="main-choose-event__header">
              <div className="main-choose-event__navigation">
                <ul className="main-choose-event__nav-list">
                  {tabButtons?.map((btn, index) =>
                    currUserObject || index == 0 ? (
                      <li
                        key={btn.id}
                        className="main-choose-event__nav-item"
                      >
                        <button
                          onClick={() => changeTabHandler(btn.id)}
                          type="button"
                          className={`main-choose-event__button ${btn.id === myEventsActiveTab ? "active" : ""
                            }`}
                        >
                          {btn.text}
                        </button>
                      </li>
                    ) : (
                      ""
                    ),
                  )}
                </ul>
              </div>
           {/*   {myEventsActiveTab !== 0 && (
                <div className="main-choose-event__filter">
                  <p className="text">מיין</p>
                  <button
                    type="button"
                    className="arrow bottom"
                    onClick={() =>
                      setSortFilter({
                        sortType: "desc",
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.117"
                      height="9.115"
                      viewBox="0 0 17.117 9.115"
                    >
                      <g
                        id="Сгруппировать_912"
                        data-name="Сгруппировать 912"
                        transform="translate(19.135 -7.296) rotate(90)"
                      >
                        <g
                          id="Сгруппировать_540"
                          data-name="Сгруппировать 540"
                          transform="translate(14.998 17.723) rotate(180)"
                        >
                          <line
                            id="Линия_434"
                            data-name="Линия 434"
                            y1="7.147"
                            x2="6.289"
                            transform="translate(0)"
                            fill="none"
                            stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                            strokeLinecap="round"
                            strokeWidth="2"
                          />
                          <path
                            id="Контур_6897"
                            data-name="Контур 6897"
                            d="M0,0,6.289,6.861"
                            transform="translate(0 7.432)"
                            fill="none"
                            stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                            strokeLinecap="round"
                            strokeWidth="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="arrow top"
                    onClick={() =>
                      setSortFilter({
                        sortType: "asc",
                      })
                    }
                    onMouseEnter={() => onMouseOverLink(0)}
                    onMouseLeave={onMouseLeaveLink}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.117"
                      height="9.115"
                      viewBox="0 0 17.117 9.115"
                    >
                      <g
                        id="Сгруппировать_913"
                        data-name="Сгруппировать 913"
                        transform="translate(19.708 -6.837) rotate(90)"
                      >
                        <g
                          id="Сгруппировать_539"
                          data-name="Сгруппировать 539"
                          transform="translate(8.25 4.002)"
                        >
                          <line
                            id="Линия_434"
                            data-name="Линия 434"
                            y1="7.147"
                            x2="6.289"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#000"
                            strokeLinecap="round"
                            strokeWidth="2"
                          />
                          <path
                            id="Контур_6897"
                            data-name="Контур 6897"
                            d="M0,0,6.289,6.861"
                            transform="translate(0 7.432)"
                            fill="none"
                            stroke="#000"
                            strokeLinecap="round"
                            strokeWidth="2"
                          />
                        </g>
                      </g>
                    </svg>

                  </button>
                </div>
              )}*/}
            </div>
          )}

          {myEventsActiveTab == 0 && (
            <>
              {!isLoggedIn && (
                <div className="main-choose-event__not-logged-header">
                  <div className="main-category__steps-numbers numbers-steps step-1">
                    <ul className="numbers-steps__list">
                      <li className="numbers-steps__list-item">
                        <div className="numbers-steps__step">1</div>
                      </li>
                      <li className="numbers-steps__list-item">
                        <div className="numbers-steps__step">2</div>
                      </li>
                      <li className="numbers-steps__list-item">
                        <div className="numbers-steps__step">3</div>
                      </li>
                      <li className="numbers-steps__list-item">

                        <div className="numbers-steps__step">4</div>
                      </li>
                    </ul>
                  </div>
                  <div className="main-choose-event__titles">
                    <h2 className="main-category__title"></h2>
                    <h3 className="main-choose-event__big-title">
                    תכף עוברים לשלב הכיפי!
                    </h3>
                    <h3 className="main-category__title">
                    יש לבחור את האירוע המיוחד שלך
                    </h3>
                  </div>
                </div>
              )}
              <ul className="main-all-categories__list">
                {wishlistCategories &&
                  wishlistCategories.map((eventType, index) => {
                    return (
                      <li
                        key={index}
                        className="main-choose-event__list-item"
                      >
                        <SingleEventType
                          eventTypeImg={eventType.categoryImage}
                          eventTypeImgNoPeople={eventTypeImgNoPeople1}
                          eventTypeIcon={eventType.categoryIcon}
                          eventTypeName={eventType.categoryName}
                          eventTypeNameEng={eventType.englishName}
                          // updateCurrWishCategories={updateCurrWishCategories}
                          event={eventType}
                          // toggleCreatePopup={toggleCreatePopup}
                          setCurrentWishlistMainCategories={
                            setCurrentWishlistMainCategories
                          }
                        />
                      </li>
                    );
                    {
                      /* } */
                    }
                  })}
              </ul>
            </>
          )}

          {myEventsActiveTab == 1 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__my-events-list">
                {whishlistArray.myWishlists.map((wishlist, index) => {
                  console.log("jjjjjjjjjjjjjj", wishlist)
                  
                  const wishlistObj = JSON.parse(wishlist.wishListObj);
                  const imgUrl = wishlistObj?.categoryImage
                    ? API_DOMAIN_IMG + wishlistObj?.categoryImage
                    : "img/choose-event-type/1.png";

                  return (
                    <li
                      key={wishlist.id}
                      className="main-choose-event__my-events-item"
                    >
                      <div className="my-event-single">
                        <div className="my-event-single__content">
                          <div className="my-event-single__image-ibg">
                            <img
                              // src="img/choose-event-type/1.png"
                              src={imgUrl}
                              className="img-people"
                              alt="1"
                            />
                            <img
                              src="img/no-people-images/choose-event-type/1.png"
                              className="img-no-people"
                              alt="1"
                            />
                          </div>
                          <div className="my-event-single__text-content">
                            <h3 className="my-event-single__title">
                              {wishlist.name}
                            </h3>
                            <p className="my-event-single__date">
                              {new Date(wishlist?.eventDate).toLocaleDateString('en-GB', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                              })}
                            </p>



                            <div className="my-event-single__actions"   style={isMobile ? { gap: '0.5rem' } : {}}>
                              {wishlist.isRemovable && (
                                       
                                <button
                                style={{
                                  position: 'relative',
                                  ...(isMobile ? { flexBasis: 'calc(28.66666% - 0.7291666625rem)' } : {})
                                }}
                              
                                className={`button delete side-menu__linky ${activeLinkId === 15 ? "active red" : ""}`}

                                  onClick={() => removeMyWishlist(wishlist.id)}
                                  onMouseEnter={() => onMouseOverLink(15, wishlist.id)}
                                  onMouseLeave={onMouseLeaveLink}
                                >
                                  <svg
                                    id="Trash_can"
                                    data-name="Trash can"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="24.153"
                                    height="28.357"
                                    viewBox="0 0 24.153 28.357"
                                  >
                                    <defs>
                                      <clipPath id="clip-path">
                                        <rect
                                          id="Прямоугольник_1981"
                                          data-name="Прямоугольник 1981"
                                          width="22.385"
                                          height="19.451"
                                          transform="translate(0 0)"
                                          fill="none"
                                          stroke="#707070"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                      <clipPath id="clip-path-2">
                                        <rect
                                          id="Прямоугольник_1983"
                                          data-name="Прямоугольник 1983"
                                          width="14.146"
                                          height="6.189"
                                          fill="none"
                                          stroke="#707070"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Прямоугольник_1980"
                                      data-name="Прямоугольник 1980"
                                      transform="translate(0 5.78)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1.4"
                                    >
                                      <rect
                                        width="24.153"
                                        height="3.537"
                                        rx="1.768"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.7"
                                        y="0.7"
                                        width="22.753"
                                        height="2.137"
                                        rx="1.068"
                                        fill="none"
                                      />
                                    </g>
                                    <line
                                      id="Линия_491"
                                      data-name="Линия 491"
                                      x2="1.603"
                                      y2="11.543"
                                      transform="translate(6.809 11.427)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_492"
                                      data-name="Линия 492"
                                      y2="11.543"
                                      transform="translate(11.96 11.427)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_493"
                                      data-name="Линия 493"
                                      x1="1.483"
                                      y2="11.291"
                                      transform="translate(15.292 11.412)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <g
                                      id="Группа_масок_833"
                                      data-name="Группа масок 833"
                                      transform="translate(0.884 8.906)"
                                      clipPath="url(#clip-path)"
                                    >
                                      <g
                                        id="Контур_8526"
                                        data-name="Контур 8526"
                                        transform="translate(8.235 -3.346)"
                                        fill="none"
                                      >
                                        <path
                                          d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                          stroke="none"
                                        />
                                        <path
                                          d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                          stroke="none"
                                          fill="#707070"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Группа_масок_834"
                                      data-name="Группа масок 834"
                                      transform="translate(5.004 0)"
                                      clipPath="url(#clip-path-2)"
                                    >
                                      <g
                                        id="Прямоугольник_1982"
                                        data-name="Прямоугольник 1982"
                                        transform="translate(0 2.652)"
                                        fill="none"
                                        stroke="#707070"
                                        strokeWidth="1.4"
                                      >
                                        <rect
                                          width="13.262"
                                          height="5.305"
                                          rx="2.652"
                                          stroke="none"
                                        />
                                        <rect
                                          x="0.7"
                                          y="0.7"
                                          width="11.862"
                                          height="3.905"
                                          rx="1.952"
                                          fill="none"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  { activeLinkId ==15 && currentLine == wishlist.id &&
                                    <div className="side-menu__toaster">
                                    מחיקה                             
                                      </div>}
           
                                  
                                </button>
                              )}            
    
                             {/* <NavLink to="/reminders" >                                       
                            <button
                             style={{position: 'relative'}}
                              className={`button notify side-menu__linky ${activeLinkId === 3 ? "active red" : ""}`}
                              onClick={() => removeMyWishlist(wishlist.id)}
                              onMouseEnter={() => onMouseOverLink(3, wishlist.id)}
                              onMouseLeave={onMouseLeaveLink}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.851"
                                  height="31.251"
                                  viewBox="0 0 24.851 31.251"
                                >
                                  <g
                                    id="Сгруппировать_1792"
                                    data-name="Сгруппировать 1792"
                                    transform="translate(0.769 0.788)"
                                  >
                                    <g
                                      id="Artboard_7"
                                      data-name="Artboard 7"
                                      transform="translate(0 0)"
                                    >
                                      <path
                                        id="Контур_10012"
                                        data-name="Контур 10012"
                                        d="M30.472,28.909a1.418,1.418,0,0,0-.6-.716c-3.316-1.846-2.656-3.182-3.178-9.164-.476-5.3-1.873-9.069-5.926-9.88a.2.2,0,0,1-.143-.125.213.213,0,0,1,.02-.193,2.466,2.466,0,0,0,.4-1.432,2.184,2.184,0,0,0-1.038-1.993,2.026,2.026,0,0,0-2.185.058,2.193,2.193,0,0,0-.937,2.046,2.382,2.382,0,0,0,.4,1.321.213.213,0,0,1,.025.188.2.2,0,0,1-.133.13c-4.068.811-5.465,4.582-5.941,9.88-.522,5.982.138,7.319-3.178,9.164a1.4,1.4,0,0,0-.583.716,2,2,0,0,0,.131,1.817,1.861,1.861,0,0,0,1.527.9H28.8a1.86,1.86,0,0,0,1.538-.9A2,2,0,0,0,30.472,28.909Z"
                                        transform="translate(-7.317 -5.114)"
                                        fill="none"
                                        stroke="#707070"
                                        strokeWidth="1.5"
                                      />
                                      <path
                                        id="Контур_10013"
                                        data-name="Контур 10013"
                                        d="M18.311,22.45a2.7,2.7,0,0,1-2.934,2.376A2.709,2.709,0,0,1,12.43,22.45Z"
                                        transform="translate(-3.722 4.862)"
                                        fill="none"
                                        stroke="#707070"
                                        strokeWidth="1.5"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                { activeLinkId ==3 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                    תזכורות
                                </div>}
                              </button>
                              </NavLink>*/}

                              <button
                                type="button"
                                style={{
                                  position: 'relative',
                                  ...(
                                    isMobile
                                      ? { flexBasis: 'calc(32.66666% - 0.7291666625rem)' }
                                      : {}
                                  )
                                }}
                                className={`button gift side-menu__linky ${activeLinkId === 5 ? "active red" : ""}`}
                                onClick={() => goToWhilistSelect(wishlist)}
                                onMouseEnter={() => onMouseOverLink(5, wishlist.id)}
                                onMouseLeave={onMouseLeaveLink}

                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.868"
                                  height="30.872"
                                  viewBox="0 0 27.868 30.872"
                                >
                                  <g
                                    id="Layer_6"
                                    data-name="Layer 6"
                                    transform="translate(0.1 0.117)"
                                  >
                                    <path
                                      id="Контур_7573"
                                      data-name="Контур 7573"
                                      d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                      transform="translate(-9.9 -4.909)"
                                      fill="#707070"
                                      stroke="#707070"
                                      strokeWidth="0.2"
                                    />
                                  </g>
                              
                                </svg>
                                { activeLinkId ==5 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                   בחירת המתנות
                                </div>}

                              </button>
                              <button
                                style={{
                                  position: 'relative',
                                  ...(
                                    isMobile
                                      ? { flexBasis: 'calc(32.66666% - 0.7291666625rem)' }
                                      : {}
                                  )
                                }}                               className={`button favorites side-menu__linky ${activeLinkId === 6 ? "active red" : ""}`}
                               onMouseEnter={() => onMouseOverLink(6, wishlist.id)}
                                onClick={() => goToCartByWishlist(wishlist.id)}
                                onMouseLeave={onMouseLeaveLink}

                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26.406"
                                  height="32.951"
                                  viewBox="0 0 26.406 32.951"
                                >
                                  <g
                                    id="Компонент_360_1"
                                    data-name="Компонент 360 – 1"
                                    transform="translate(0.751 0.75)"
                                  >
                                    <path
                                      id="heart"
                                      d="M31.046,32.562l5.834,5.9,6.267-6.333a4.2,4.2,0,0,0,0-5.894,4.094,4.094,0,0,0-5.832,0l-.432.437-.432-.437a4.093,4.093,0,0,0-5.832,0,4.2,4.2,0,0,0,0,5.894l.43.434Z"
                                      transform="translate(-24.705 -12.506)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Artboard_2"
                                      data-name="Artboard 2"
                                      d="M87.935,18.61a9.431,9.431,0,0,1,1.582.617,3.751,3.751,0,0,1,1.859,3.3c.018.867,0,1.737,0,2.644H94.83a2.209,2.209,0,0,1,2.331,2.048q.742,5.519,1.474,11.04c.41,3.082.829,6.162,1.229,9.246a2.186,2.186,0,0,1-1.743,2.509.563.563,0,0,0-.108.051H76.876a2.484,2.484,0,0,1-1.465-.933,2.108,2.108,0,0,1-.4-1.506q.58-4.405,1.171-8.816.532-4.027,1.07-8.06c.164-1.229.321-2.457.491-3.679a2.195,2.195,0,0,1,2.13-1.892c1.085-.02,2.169-.007,3.255-.007.386-.138.386-.255.386-.373a26.565,26.565,0,0,1,.057-2.789,3.841,3.841,0,0,1,3.244-3.343.852.852,0,0,0,.139-.053Z"
                                      transform="translate(-74.992 -18.61)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1.5"
                                    />
                                  </g>
                                </svg>
                                { activeLinkId ==6 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                   סל המתנות
                                </div>}
		  
                              </button>


                              
                              {/*
                              <button className="button date" onClick={toggleDatePicker}>
                                {/*{isDatePickerOpen && (
                                  <DatePicker
                                    selected={new Date(wishlist?.eventDate)}  // Pass a Date object
                                    readOnly={true}  // Make it non-editable
                                    dateFormat="dd/mm/yyyy"  // Adjust the date format as needed
                                    inline
                                  />
                                )}
                                <svg
                                  id="iocn"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28.362"
                                  height="30.656"
                                  viewBox="0 0 28.362 30.656"
                                >
                                  <path
                                    id="Контур_7703"
                                    data-name="Контур 7703"
                                    d="M29.674,33.628a3.066,3.066,0,0,0,2.162-.876,2.988,2.988,0,0,0,.9-2.131V8.777A3.027,3.027,0,0,0,29.687,5.77H7.416A3.027,3.027,0,0,0,4.37,8.777V30.621a3.027,3.027,0,0,0,3.046,3.007ZM5.636,30.621V8.777A1.781,1.781,0,0,1,7.416,7.02H29.674a1.781,1.781,0,0,1,1.8,1.757V30.621a1.781,1.781,0,0,1-1.78,1.757H7.416A1.781,1.781,0,0,1,5.636,30.621Z"
                                    transform="translate(-4.37 -2.972)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7704"
                                    data-name="Контур 7704"
                                    d="M11.879,10.291V4.552a.717.717,0,0,0-.717-.732.732.732,0,0,0-.732.732v5.739a.725.725,0,0,0,1.449,0Z"
                                    transform="translate(-2.886 -3.82)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7705"
                                    data-name="Контур 7705"
                                    d="M21.439,10.291V4.552a.732.732,0,0,0-.732-.732.717.717,0,0,0-.717.732v5.739a.725.725,0,0,0,1.449,0Z"
                                    transform="translate(-0.546 -3.82)"
                                    fill="#707070"
                                  />
                                  <rect
                                    id="Прямоугольник_1873"
                                    data-name="Прямоугольник 1873"
                                    width="26.755"
                                    height="1.449"
                                    transform="translate(0.717 8.532)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7706"
                                    data-name="Контур 7706"
                                    d="M9.962,15.481a.861.861,0,1,0-.861.861A.861.861,0,0,0,9.962,15.481Z"
                                    transform="translate(-3.429 -1.185)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7707"
                                    data-name="Контур 7707"
                                    d="M14.522,15.481a.861.861,0,1,0-.861.861A.861.861,0,0,0,14.522,15.481Z"
                                    transform="translate(-2.32 -1.185)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7708"
                                    data-name="Контур 7708"
                                    d="M19.06,15.49a.875.875,0,1,0-.875.861.861.861,0,0,0,.875-.861Z"
                                    transform="translate(-1.226 -1.192)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7709"
                                    data-name="Контур 7709"
                                    d="M23.592,15.481a.861.861,0,1,0-.861.861A.861.861,0,0,0,23.592,15.481Z"
                                    transform="translate(-0.115 -1.185)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7710"
                                    data-name="Контур 7710"
                                    d="M9.962,19.651a.861.861,0,1,0-.861.861A.861.861,0,0,0,9.962,19.651Z"
                                    transform="translate(-3.429 -0.168)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7711"
                                    data-name="Контур 7711"
                                    d="M14.522,19.651a.861.861,0,1,0-.861.861A.861.861,0,0,0,14.522,19.651Z"
                                    transform="translate(-2.32 -0.168)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7712"
                                    data-name="Контур 7712"
                                    d="M19.06,19.66a.875.875,0,1,0-.875.861.861.861,0,0,0,.875-.861Z"
                                    transform="translate(-1.226 -0.175)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7713"
                                    data-name="Контур 7713"
                                    d="M23.592,19.651a.861.861,0,1,0-.861.861A.861.861,0,0,0,23.592,19.651Z"
                                    transform="translate(-0.115 -0.168)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7714"
                                    data-name="Контур 7714"
                                    d="M9.962,23.821a.861.861,0,1,0-.861.861.861.861,0,0,0,.861-.861Z"
                                    transform="translate(-3.429 0.849)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7715"
                                    data-name="Контур 7715"
                                    d="M14.522,23.821a.861.861,0,1,0-.861.861.861.861,0,0,0,.861-.861Z"
                                    transform="translate(-2.32 0.849)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7716"
                                    data-name="Контур 7716"
                                    d="M19.06,23.83a.875.875,0,1,0-.875.861.861.861,0,0,0,.875-.861Z"
                                    transform="translate(-1.226 0.841)"
                                    fill="#707070"
                                  />
                                </svg>
                              </button>
                              */}
                              <NavLink
                               to={`/update-wishlist/:${wishlist.id}`}
                                state={{
                                 wishlist: wishlist,
                                 eventTypeName: wishlist.name,
                                 eventTypeDate: wishlist.eventDate,
                                 eventTypeId: wishlist.id,

                                }}
                                 //onClick={()=> editWhishListItem(wishlist.name)}
                                 style={{
                                  position: 'relative',
                                  ...(
                                    isMobile
                                      ? { flexBasis: 'calc(25.6667% - 0.729167rem)' }
                                      : {}
                                  )
                                }}                                className={`button edit side-menu__linky ${activeLinkId === 8 ? "active red" : ""}`}
                                onMouseEnter={() => onMouseOverLink(8, wishlist.id)}
                                 onMouseLeave={onMouseLeaveLink}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="19.121"
                                  viewBox="0 0 20 19.121"
                                >
                                  <g
                                    id="edit-3"
                                    transform="translate(-2 -1.879)"
                                  >
                                    <path
                                      id="Контур_8553"
                                      data-name="Контур 8553"
                                      d="M12,20h9"
                                      fill="none"
                                      stroke="#959595"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Контур_8554"
                                      data-name="Контур 8554"
                                      d="M16.5,3.5a2.121,2.121,0,1,1,3,3L7,19,3,20l1-4Z"
                                      fill="none"
                                      stroke="#959595"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                                { activeLinkId ==8 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                    עריכת אירוע
                                </div>}
                              </NavLink>
                              <div>
      
      <button
         style={{
          position: 'relative',
          ...(
            isMobile
              ? { flexBasis: 'calc(32.66666% - 0.7291666625rem)',         width: 'calc(16.66666% + 2.27083rem)'
              }
              : {}
          )
        }}                        
        className={`button share side-menu__linky ${activeLinkId === 16 ? "active red" : ""}`}
        onClick={(e) => shareMyWishlist(e, wishlist.id)}
        onMouseEnter={() => onMouseOverLink(16, wishlist.id)}
        onMouseLeave={onMouseLeaveLink}
      >

        <svg
          id="Сгруппировать_1791"
          data-name="Сгруппировать 1791"
          xmlns="http://www.w3.org/2000/svg"
          width="25.743"
          height="31.05"
          viewBox="0 0 25.743 31.05"
        >
          <path
            id="Контур_8542"
            data-name="Контур 8542"
            d="M13.065,25.983a.472.472,0,0,1-.222,0l-8.919-5.2h-.1l-.18-.125a3.814,3.814,0,0,1-1.554-3.121V16.274a1.706,1.706,0,0,1,0-.4A5.077,5.077,0,0,1,7.087,11.71h6.935a5.049,5.049,0,0,1,3.995,1.942.529.529,0,1,1-.832.652,3.981,3.981,0,0,0-3.163-1.54H7.087a4.023,4.023,0,0,0-3.953,3.26,1.276,1.276,0,0,0,0,.264V17.4a2.774,2.774,0,0,0,1.124,2.261l.125.1h0L13.3,24.9a.527.527,0,0,1,.194.721.513.513,0,0,1-.43.361Z"
            transform="translate(-2.081 0.379)"
            fill="#707070"
          />
          <path
            id="Контур_8541"
            data-name="Контур 8541"
            d="M9.631,13.835h0A5.424,5.424,0,0,1,9.6,3h0a5.424,5.424,0,1,1,0,10.833Zm0-9.71A4.116,4.116,0,0,0,5.567,8.481a4.116,4.116,0,0,0,4.064,4.355h.291a4.355,4.355,0,1,0,0-8.711H9.631Z"
            transform="translate(-1.158 -2.995)"
            fill="#707070"
          />
          <path
            id="Контур_8547"
            data-name="Контур 8547"
            d="M39.436,25.22a4.466,4.466,0,0,0-3.149-.9,2.774,2.774,0,0,1-.472,0,2.885,2.885,0,0,1-2.774-2.774.68.68,0,0,1,0-.236v-.194a2.913,2.913,0,0,1,2.9-2.9h1.29a3.093,3.093,0,0,0,.874,0,3.343,3.343,0,0,0-.9-6.575,3.44,3.44,0,0,0-3.343,3.218,3.662,3.662,0,0,1-1.235,2.414,3.149,3.149,0,0,1-1.193.652,6.935,6.935,0,0,1-1.775.125,3.357,3.357,0,0,0,0,6.7,4.425,4.425,0,0,1,2.774.624A3.482,3.482,0,0,1,33.624,27c.236.652.194,1.387.458,2a3.343,3.343,0,1,0,5.4-3.676Z"
            transform="translate(-15.623 -0.792)"
            fill="none"
            stroke="#707070"
            strokeWidth="1.5"
          />


        </svg> 
        { activeLinkId == 16 && currentLine == wishlist.id &&
           <div className="side-menu__toaster">
            העתקת קישור
        </div>}

      </button>     
      </div>      
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {myEventsActiveTab == 2 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__books">
                {whishlistArray.historyWishlists.map((wishlist, index) => {
                  const wishListObj = JSON.parse(wishlist?.wishListObj);
                  const imgUrl = wishListObj?.categoryImage
                    ? API_DOMAIN_IMG + wishListObj?.categoryImage
                    : "img/choose-event-type/1.png";

                  return (
                    <li
                      key={wishlist.id}
                      className="main-choose-event__books-item"
                    >
                      <div className="single-books-item">
                        <div className="single-books-item__content">
                          <div className="single-books-item__image-ibg">
                            <img
                              src={imgUrl}
                              className="img-people"
                              alt="1"
                            />
                            <img
                              src="img/no-people-images/choose-event-type/1.png"
                              className="img-no-people"
                              alt="1"
                            />
                          </div>
                          <div className="single-books-item__text-content">
                            <h3 className="single-books-item__title">
                              {wishlist.name}
                            </h3>
                            <p className="single-books-item__date">
                              {wishlist.eventDate.slice(8, 10)}/{wishlist.eventDate.slice(5, 7)}/{wishlist.eventDate.slice(0, 4)}
                            </p>

                            <div className="single-books-item__actions">
                              {wishlist.isRemovable && (
                                <button
                                style={{position: 'relative'}}
                                className={`button delete side-menu__linkd ${activeLinkId === 17 ? "active red" : ""}`}
                                onClick={() =>
                                    removeHistoryWishlist(wishlist.id)
                              
                                  }  
                                  onMouseLeave={onMouseLeaveLink}
                                  onMouseEnter={() => onMouseOverLink(17, wishlist.id)}
                                >
                                  <svg
                                    id="Trash_can"
                                    data-name="Trash can"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="24.153"
                                    height="28.357"
                                    viewBox="0 0 24.153 28.357"
                                  >
                                    <defs>
                                      <clipPath id="clip-path">
                                        <rect
                                          id="Прямоугольник_1981"
                                          data-name="Прямоугольник 1981"
                                          width="22.385"
                                          height="19.451"
                                          transform="translate(0 0)"
                                          fill="none"
                                          stroke="#716e6e"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                      <clipPath id="clip-path-2">
                                        <rect
                                          id="Прямоугольник_1983"
                                          data-name="Прямоугольник 1983"
                                          width="14.146"
                                          height="6.189"
                                          fill="none"
                                          stroke="#716e6e"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Прямоугольник_1980"
                                      data-name="Прямоугольник 1980"
                                      transform="translate(0 5.78)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeWidth="1.4"
                                    >
                                      <rect
                                        width="24.153"
                                        height="3.537"
                                        rx="1.768"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.7"
                                        y="0.7"
                                        width="22.753"
                                        height="2.137"
                                        rx="1.068"
                                        fill="none"
                                      />
                                    </g>
                                    <line
                                      id="Линия_491"
                                      data-name="Линия 491"
                                      x2="1.603"
                                      y2="11.543"
                                      transform="translate(6.809 11.427)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_492"
                                      data-name="Линия 492"
                                      y2="11.543"
                                      transform="translate(11.96 11.427)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_493"
                                      data-name="Линия 493"
                                      x1="1.483"
                                      y2="11.291"
                                      transform="translate(15.292 11.412)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <g
                                      id="Группа_масок_833"
                                      data-name="Группа масок 833"
                                      transform="translate(0.884 8.906)"
                                      clipPath="url(#clip-path)"
                                    >
                                      <g
                                        id="Контур_8526"
                                        data-name="Контур 8526"
                                        transform="translate(8.235 -3.346)"
                                        fill="none"
                                      >
                                        <path
                                          d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                          stroke="none"
                                        />
                                        <path
                                          d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                          stroke="none"
                                          fill="#716e6e"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Группа_масок_834"
                                      data-name="Группа масок 834"
                                      transform="translate(5.004 0)"
                                      clipPath="url(#clip-path-2)"
                                    >
                                      <g
                                        id="Прямоугольник_1982"
                                        data-name="Прямоугольник 1982"
                                        transform="translate(0 2.652)"
                                        fill="none"
                                        stroke="#716e6e"
                                        strokeWidth="1.4"
                                      >
                                        <rect
                                          width="13.262"
                                          height="5.305"
                                          rx="2.652"
                                          stroke="none"
                                        />
                                        <rect
                                          x="0.7"
                                          y="0.7"
                                          width="11.862"
                                          height="3.905"
                                          rx="1.952"
                                          fill="none"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  { activeLinkId ==17 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                   מחיקה
                                </div>}
          
                                </button>
                              )}
 
                              <button
                                style={{
                                  position: 'relative',
                                  ...(
                                    isMobile
                                      ? { flexBasis: 'calc(32.66666% - 0.7291666625rem)' }
                                      : {}
                                  )
                                }}                               className={`button favorites side-menu__linky ${activeLinkId === 6 ? "active red" : ""}`}
                               onMouseEnter={() => onMouseOverLink(6, wishlist.id)}
                                onClick={() => goToCartByWishlist(wishlist.id)}
                                onMouseLeave={onMouseLeaveLink}

                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26.406"
                                  height="32.951"
                                  viewBox="0 0 26.406 32.951"
                                >
                                  <g
                                    id="Компонент_360_1"
                                    data-name="Компонент 360 – 1"
                                    transform="translate(0.751 0.75)"
                                  >
                                    <path
                                      id="heart"
                                      d="M31.046,32.562l5.834,5.9,6.267-6.333a4.2,4.2,0,0,0,0-5.894,4.094,4.094,0,0,0-5.832,0l-.432.437-.432-.437a4.093,4.093,0,0,0-5.832,0,4.2,4.2,0,0,0,0,5.894l.43.434Z"
                                      transform="translate(-24.705 -12.506)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Artboard_2"
                                      data-name="Artboard 2"
                                      d="M87.935,18.61a9.431,9.431,0,0,1,1.582.617,3.751,3.751,0,0,1,1.859,3.3c.018.867,0,1.737,0,2.644H94.83a2.209,2.209,0,0,1,2.331,2.048q.742,5.519,1.474,11.04c.41,3.082.829,6.162,1.229,9.246a2.186,2.186,0,0,1-1.743,2.509.563.563,0,0,0-.108.051H76.876a2.484,2.484,0,0,1-1.465-.933,2.108,2.108,0,0,1-.4-1.506q.58-4.405,1.171-8.816.532-4.027,1.07-8.06c.164-1.229.321-2.457.491-3.679a2.195,2.195,0,0,1,2.13-1.892c1.085-.02,2.169-.007,3.255-.007.386-.138.386-.255.386-.373a26.565,26.565,0,0,1,.057-2.789,3.841,3.841,0,0,1,3.244-3.343.852.852,0,0,0,.139-.053Z"
                                      transform="translate(-74.992 -18.61)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1.5"
                                    />
                                  </g>
                                </svg>
                                { activeLinkId ==6 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster" style={{top: '5px'}}>
                                   סל המתנות
                                </div>}
		  
                              </button>
                              <button
                               style={{position: 'relative'}}
                               onClick={() => goToChooseGift(wishlist.id)}
                               className={`button favorites side-menu__linkd ${activeLinkId === 19 ? "active red" : ""}`}
                               onMouseLeave={onMouseLeaveLink}
                               onMouseEnter={() => onMouseOverLink(19, wishlist.id)}
                              > 
                           <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.868"
                                  height="30.872"
                                  viewBox="0 0 27.868 30.872"
                                >
                                  <g
                                    id="Layer_6"
                                    data-name="Layer 6"
                                    transform="translate(0.1 0.117)"
                                  >
                                    <path
                                      id="Контур_7573"
                                      data-name="Контур 7573"
                                      d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                      transform="translate(-9.9 -4.909)"
                                      fill="#716e6e"
                                      stroke="#716e6e"
                                      strokeWidth="0.2"
                                    />
                                  </g>
                                 
                                </svg>
                                { activeLinkId ==19 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                   בחירת מתנות
                                </div>}
                              </button>
                              <div className="arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9.115"
                                  height="17.117"
                                  viewBox="0 0 9.115 17.117"
                                >
                                  <g
                                    id="Сгруппировать_74339"
                                    data-name="Сгруппировать 74339"
                                    transform="translate(16.411 19.135) rotate(180)"
                                  >
                                    <g
                                      id="Сгруппировать_540"
                                      data-name="Сгруппировать 540"
                                      transform="translate(14.998 17.723) rotate(180)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0)"
                                        fill="none"
                                        stroke="#a7a7a7"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#a7a7a7"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="single-books-item__dropdown-body">
                            <div className="single-books-item__dropdown-content">
                              <div className="single-books-item__dropdown-table">
                                <div className="single-books-item__dropdown-table-column">
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">תאריך האירוע</p>
                                    <p className="value">
  {wishlist.eventDate.slice(8, 10)}/{wishlist.eventDate.slice(5, 7)}/{wishlist.eventDate.slice(0, 4)}
</p>

                                  </div>
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">שעת הגעה</p>
                                    <p className="value">
                                      {wishlist.arrivalTime}
                                    </p>
                                  </div>
                                </div>
                                <div className="single-books-item__dropdown-table-column">
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">כתובת האירוע</p>
                                    {wishlist.address ? <p className="value">{wishlist.address}</p> : null}
                                    </div>
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">טלפון לתיאומים</p>
                                    <p className="value">
                                      {wishlist.contactPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p className="single-books-item__dropdown-bottom-text">
                                בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר
                                בחרו מתוך רשימת המתנות המועדפת שלי אז למה אתם
                                מחכים?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      

                 
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {myEventsActiveTab == 3 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__books">
                {whishlistArray.friendsWishlists.map((wishlist, index) => {
                  const wishListObj = JSON.parse(wishlist?.wishListObj);
                  console.log("!!!!!!!!!!!", wishlist)
                  const imgUrl = wishListObj?.categoryImage
                    ? API_DOMAIN_IMG + wishListObj?.categoryImage
                    : "img/choose-event-type/1.png";

                  return (
                    <li
                      key={wishlist.id}
                      className="main-choose-event__books-item"
                    >
                      <div className="single-books-item">
                        <div className="single-books-item__content">
                          <div className="single-books-item__image-ibg">
                            <img
                              src={imgUrl}
                              className="img-people"
                              alt="1"
                            />
                            <img
                              src="img/no-people-images/choose-event-type/1.png"
                              className="img-no-people"
                              alt="1"
                            />
                          </div>
                          <div className="single-books-item__text-content">
                            <h3 className="single-books-item__title">
                              {wishlist.name}
                            </h3>
                            <p className="single-books-item__date">
                              {wishlist.eventDate.slice(8, 10)}/{wishlist.eventDate.slice(5, 7)}/{wishlist.eventDate.slice(0, 4)}
                            </p>

                            <div className="single-books-item__actions">
                              {wishlist.isRemovable && (
                                <button
                                style={{position: 'relative'}}
                                  onClick={() =>
                                    removeFriendWishlist(wishlist.id)
                                  }
                                  className={`button delete side-menu__linkd ${activeLinkId === 20 ? "active red" : ""}`}
                                  onMouseLeave={onMouseLeaveLink}
                                  onMouseEnter={() => onMouseOverLink(20, wishlist.id)}
                                >
                                  <svg
                                    id="Trash_can"
                                    data-name="Trash can"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="24.153"
                                    height="28.357"
                                    viewBox="0 0 24.153 28.357"
                                  >
                                    <defs>
                                      <clipPath id="clip-path">
                                        <rect
                                          id="Прямоугольник_1981"
                                          data-name="Прямоугольник 1981"
                                          width="22.385"
                                          height="19.451"
                                          transform="translate(0 0)"
                                          fill="none"
                                          stroke="#716e6e"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                      <clipPath id="clip-path-2">
                                        <rect
                                          id="Прямоугольник_1983"
                                          data-name="Прямоугольник 1983"
                                          width="14.146"
                                          height="6.189"
                                          fill="none"
                                          stroke="#716e6e"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Прямоугольник_1980"
                                      data-name="Прямоугольник 1980"
                                      transform="translate(0 5.78)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeWidth="1.4"
                                    >
                                      <rect
                                        width="24.153"
                                        height="3.537"
                                        rx="1.768"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.7"
                                        y="0.7"
                                        width="22.753"
                                        height="2.137"
                                        rx="1.068"
                                        fill="none"
                                      />
                                    </g>
                                    <line
                                      id="Линия_491"
                                      data-name="Линия 491"
                                      x2="1.603"
                                      y2="11.543"
                                      transform="translate(6.809 11.427)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_492"
                                      data-name="Линия 492"
                                      y2="11.543"
                                      transform="translate(11.96 11.427)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_493"
                                      data-name="Линия 493"
                                      x1="1.483"
                                      y2="11.291"
                                      transform="translate(15.292 11.412)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <g
                                      id="Группа_масок_833"
                                      data-name="Группа масок 833"
                                      transform="translate(0.884 8.906)"
                                      clipPath="url(#clip-path)"
                                    >
                                      <g
                                        id="Контур_8526"
                                        data-name="Контур 8526"
                                        transform="translate(8.235 -3.346)"
                                        fill="none"
                                      >
                                        <path
                                          d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                          stroke="none"
                                        />
                                        <path
                                          d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                          stroke="none"
                                          fill="#716e6e"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Группа_масок_834"
                                      data-name="Группа масок 834"
                                      transform="translate(5.004 0)"
                                      clipPath="url(#clip-path-2)"
                                    >
                                      <g
                                        id="Прямоугольник_1982"
                                        data-name="Прямоугольник 1982"
                                        transform="translate(0 2.652)"
                                        fill="none"
                                        stroke="#716e6e"
                                        strokeWidth="1.4"
                                      >
                                        <rect
                                          width="13.262"
                                          height="5.305"
                                          rx="2.652"
                                          stroke="none"
                                        />
                                        <rect
                                          x="0.7"
                                          y="0.7"
                                          width="11.862"
                                          height="3.905"
                                          rx="1.952"
                                          fill="none"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  { activeLinkId ==20 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                   מחיקה
                                </div>}
                                </button>
                              )}
                              <NavLink
                                to={`/choose-present-or-send-money/${wishlist.id}`}
                              //  style={{position: 'relative'}}
                                className={`button gift side-menu__linkd ${activeLinkId === 30 ? "active red" : ""}`}
                                onMouseLeave={onMouseLeaveLink}
                                onMouseEnter={() => onMouseOverLink(30, wishlist.id)}

                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.868"
                                  height="30.872"
                                  viewBox="0 0 27.868 30.872"
                                >
                                  <g
                                    id="Layer_6"
                                    data-name="Layer 6"
                                    transform="translate(0.1 0.117)"
                                  >
                                    <path
                                      id="Контур_7573"
                                      data-name="Контур 7573"
                                      d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                      transform="translate(-9.9 -4.909)"
                                      fill="#716e6e"
                                      stroke="#716e6e"
                                      strokeWidth="0.2"
                                    />
                                  </g>
                                 
                                </svg> { activeLinkId ==30 && currentLine == wishlist.id &&
                                   <div className="side-menu__toaster">
                                  בחירת מתנות
                                </div>}
                              </NavLink>
                              <div className="arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9.115"
                                  height="17.117"
                                  viewBox="0 0 9.115 17.117"
                                >
                                  <g
                                    id="Сгруппировать_74339"
                                    data-name="Сгруппировать 74339"
                                    transform="translate(16.411 19.135) rotate(180)"
                                  >
                                    <g
                                      id="Сгруппировать_540"
                                      data-name="Сгруппировать 540"
                                      transform="translate(14.998 17.723) rotate(180)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0)"
                                        fill="none"
                                        stroke="#a7a7a7"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#a7a7a7"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                          
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="single-books-item__dropdown-body">
                            <div className="single-books-item__dropdown-content">
                              <div className="single-books-item__dropdown-table">
                                <div className="single-books-item__dropdown-table-column">
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">תאריך האירוע</p>
                                    <p className="value">
                                      {wishlist.eventDate.slice(8, 10)}/{wishlist.eventDate.slice(5, 7)}/{wishlist.eventDate.slice(0, 4)}
                                    </p>
                                  </div>
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">שעת הגעה</p>
                                    <p className="value">
                                      {wishlist.eventDate.slice(11, 16)}
                                    </p>
                                  </div>
                                </div>
                                <div className="single-books-item__dropdown-table-column">
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">כתובת האירוע</p>
                                    {(wishlist?.street || wishlist?.houseNumber || wishlist?.city) ? (
    <p className="value">
        {wishlist?.street + " " + wishlist?.houseNumber + ", " + wishlist?.city}
    </p>
) : null}
                                  </div>
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">טלפון לתיאומים</p>
                                    <p className="value">
                                      {wishlist.contactPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p className="single-books-item__dropdown-bottom-text">
                                בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר
                                בחרו מתוך רשימת המתנות המועדפת שלי אז למה אתם
                                מחכים?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

{myEventsActiveTab == 4 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__books">
                {whishlistArray.futureWishlists?.map((wishlist, index) => {
                //  const wishListObj = JSON.parse(wishlist?.wishListObj);
                  console.log("!!!!!!!!!!!", wishlist)
            
                  const wishlistObj = JSON.parse(wishlist.wishListObj);
                  const imgUrl = wishlistObj?.categoryImage
                    ? API_DOMAIN_IMG + wishlistObj?.categoryImage
                    : "img/choose-event-type/1.png";

                  return (
                    <li
                      key={wishlist.id}
                      className="main-choose-event__books-item"
                    >
                      <div className="single-books-item">
                        <div className="single-books-item__content">
                          <div className="single-books-item__image-ibg">
                            <img
                              src={imgUrl}
                              className="img-people"
                              alt="1"
                            />
                            <img
                              src="img/no-people-images/choose-event-type/1.png"
                              className="img-no-people"
                              alt="1"
                            />
                          </div>
                          <div className="single-books-item__text-content">
                            <h3 className="single-books-item__title">
                              {wishlist.name}
                            </h3>
                            <p className="single-books-item__date">
                              {new Date(wishlist.eventDate).toLocaleDateString('en-GB')}
                            </p>


                        
                          </div>

                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}


          
        </div>
      </section>
     {isLoggedIn &&
      <div className="form-credentials-section__buttons-container mobile">
        <button
          type="submit"
          className="btn footer-mobile"
          style={{ justifyContent: "space-between", display: "flex", overflow: "hidden", padding:0, zIndex: '3000'}}    
        
        >
          <div style={{ flex: 1}}>
            <NavLink
              to="/"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.516"
                  height="25.445"
                  viewBox="0 0 25.516 25.445"
                >
                  <path
                    id="home_1_"
                    data-name="home (1)"
                    d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                    transform="translate(0.771 0.601)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>
              <p className="text1">ראשי</p>
            </NavLink>
          </div>
          <div style={{ flex: 1 }}>
          <NavLink to="/contact-us" >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={27}
                height={26}
                viewBox="0 0 27 26"
              >
                <g
                  id="Сгруппировать_1499"
                  data-name="Сгруппировать 1499"
                  transform="translate(1177 -2115)"
                >
                  <g
                    id="Эллипс_411"
                    data-name="Эллипс 411"
                    transform="translate(-1177 2115)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_412"
                    data-name="Эллипс 412"
                    transform="translate(-1159 2122)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_413"
                    data-name="Эллипс 413"
                    transform="translate(-1175 2132)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <line
                    id="Линия_524"
                    data-name="Линия 524"
                    x2={10}
                    y2={4}
                    transform="translate(-1168.5 2120.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                  <line
                    id="Линия_525"
                    data-name="Линия 525"
                    y1={6}
                    x2={9}
                    transform="translate(-1166.5 2128.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </span>
            <p className="text1">צרו קשר</p>
          </NavLink>
          </div>
          <div style={{ flex: 1, height: '100%' }}>
          <NavLink
            to="/my-bank"
          >
            <span className="icon">
              <svg
                id="Artboard_1"
                data-name="Artboard 1"
                xmlns="http://www.w3.org/2000/svg"
                width="23.901"
                height="24.498"
                viewBox="0 0 23.901 24.498"
              >
                <path
                  id="Контур_10114"
                  data-name="Контур 10114"
                  d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                  transform="translate(6.338 -33.519)"
                  fill="#707070"
                />
                <path
                  id="Контур_10115"
                  data-name="Контур 10115"
                  d="M16,54h.891v.891H16V54Z"
                  transform="translate(6.12 -35.737)"
                  fill="#707070"
                />
                <path
                  id="Контур_10116"
                  data-name="Контур 10116"
                  d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                  transform="translate(-15.361 -30.746)"
                  fill="#707070"
                />
                <path
                  id="Контур_10117"
                  data-name="Контур 10117"
                  d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                  transform="translate(-21 -29.083)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2304"
                  data-name="Прямоугольник 2304"
                  width="1.4"
                  height="2.8"
                  transform="translate(7.107 2.8)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2305"
                  data-name="Прямоугольник 2305"
                  width="0.7"
                  height="2.8"
                  transform="translate(9.207)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2306"
                  data-name="Прямоугольник 2306"
                  width="0.7"
                  height="2.8"
                  transform="translate(13.407 8.399)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2307"
                  data-name="Прямоугольник 2307"
                  width="1.4"
                  height="1.4"
                  transform="translate(14.807 11.899)"
                  fill="#707070"
                />
                <path
                  id="Контур_10118"
                  data-name="Контур 10118"
                  d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                  transform="translate(-18.31 -13.535)"
                  fill="#707070"
                />
                <path
                  id="Контур_10119"
                  data-name="Контур 10119"
                  d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                  transform="translate(-20.664 -14.17)"
                  fill="#707070"
                />
                <path
                  id="Контур_10120"
                  data-name="Контур 10120"
                  d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                  transform="translate(-42.772 -20.762)"
                  fill="#707070"
                />
                <path
                  id="Контур_10121"
                  data-name="Контур 10121"
                  d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                  transform="translate(-37.854 -20.969)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2308"
                  data-name="Прямоугольник 2308"
                  width="0.7"
                  height="0.7"
                  transform="translate(9.207 3.5)"
                  fill="#707070"
                />
              </svg>
            </span>
            <p className="text1">הקופה שלי</p>

          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
           // onClick={() => goToCartByWishlist1()}
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.846"
                height="24.798"
                viewBox="0 0 19.846 24.798"
              >
                <g
                  id="Сгруппировать_74057"
                  data-name="Сгруппировать 74057"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Artboard_2"
                    data-name="Artboard 2"
                    d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                    transform="translate(-74.992 -18.61)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth={1}
                  />
                  <path
                    id="heart"
                    d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                    transform="translate(-25.26 -15.21)"
                    fill="#ec1b53"
                  />
                </g>
              </svg>
            </span>
            <p className="text1">סל המתנות</p>
          </NavLink>
          </div>
          <div style={{padding:' 1.2vh 0 1.2vh 0' ,flex: 1, backgroundColor: myEventsActiveTab === 0 ? "#ec1b53" : '' }}>
          <NavLink
            to="/my-events"
            onClick={() => setMyEventsActiveTab(0)}
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29.959"
                height="22.842"
                viewBox="0 0 29.959 22.842"
              >
                <g
                  id="Сгруппировать_74063"
                  data-name="Сгруппировать 74063"
                  transform="translate(-14 0.5)"
                >
                  <g
                    id="Artboard_3"
                    data-name="Artboard 3"
                    transform="translate(22.214 0)"
                  >
                    <path
                      id="Контур_38424"
                      data-name="Контур 38424"
                      d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                      transform="translate(-24.307 -20.873)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : "#707070"}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38425"
                      data-name="Контур 38425"
                      d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                      transform="translate(-20.77 -14.613)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38426"
                      data-name="Контур 38426"
                      d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                      transform="translate(-14.469 -14.389)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_526"
                      data-name="Линия 526"
                      y2="4.793"
                      transform="translate(9.839 13.419)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_527"
                      data-name="Линия 527"
                      y2="8.147"
                      transform="translate(7.443 11.023)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_528"
                      data-name="Линия 528"
                      y2="11.684"
                      transform="translate(5.047 8.445)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_529"
                      data-name="Линия 529"
                      y2="7.055"
                      transform="translate(2.651 14.033)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <circle
                      id="Эллипс_414"
                      data-name="Эллипс 414"
                      cx="1.438"
                      cy="1.438"
                      r="1.438"
                      transform="translate(10.319)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38427"
                      data-name="Контур 38427"
                      d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                      transform="translate(-27.244 -17.922)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38428"
                      data-name="Контур 38428"
                      d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                      transform="translate(-31.988 -10.775)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38429"
                      data-name="Контур 38429"
                      d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                      transform="translate(-20.474 -9.487)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38430"
                      data-name="Контур 38430"
                      d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                      transform="translate(-28.181 -7.104)"
                      fill="none"
                      stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                  <g
                    id="Сгруппировать_1710"
                    data-name="Сгруппировать 1710"
                    transform="translate(14 2.322)"
                  >
                    <g
                      id="Контур_10101"
                      data-name="Контур 10101"
                      transform="translate(0 1.369)"
                      fill="#3c9e1c"
                    >
                      <path
                        d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                        stroke="none"
                      />
                      <path
                        d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                        stroke={myEventsActiveTab === 0 ? "white" : '#707070'}
                        fill="#fff"
                      />
                    </g>
                    <text
                      id="_"
                      data-name="+"
                      transform="translate(6.845 12.059)"
                      fill="#fff"
                      fontSize="11"
                      fontFamily="Assistant-SemiBold, Assistant"
                      fontWeight="600"
                    >
                      <tspan
                        x="2.783"
                        y="0"
                      >
                        +
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
            </span>
            <p className="text1" style={{color: myEventsActiveTab === 0 ? "white" : ''}}>אירוע חדש</p>


          </NavLink>
          </div>

        </button>
      </div>}
    </main>
  );
}
