import React, { useEffect, useRef, useState } from "react";

import {
  orangeBgImage,
  orangeBgImageNoPeople,
  prevBtnImage,
} from "../Assets/images";
import ChooseLoginOrRegister from "../Credentials/choose-login-or-register";
import Register from "../Credentials/register";
import WeSendYouSmsPopup from "../Popups/we-send-you-sms-popup";
import CodeNotRightPopup from "../Popups/code-not-right-popup";
import Login from "../Credentials/login";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoginReferalWishlist from "../Credentials/ReferalWishlist/login-referal-wishlist";
import RegisterReferalWishlist from "../Credentials/ReferalWishlist/register-referal-wishlist";

const currWishlistId = Number(localStorage.getItem('currWishlistId'))

function CredentialsReferal({ isLoggedInHandler, updatePopupState, currUserObject, setCurrUserObject, isRedirectPresent,setNotLoggedIn,setUserNotFirstTime,updateDidRegistered }) {
  const location = useLocation();
  const navigate = useNavigate();
  const hasNewWishlistPath = location.pathname.includes('credentials-new-wishlist');
  console.log('location_state', location.state)
  const params = location?.state ? location.state : {};
  const currWishlistId = params?.currWishlistId;

  const [stepNumber, setStepNumber] = useState(2);
  const [isOrange, setOrange] = useState(true);
  const [isGreenMobile, setGreenMobile] = useState(true);
  const [isTopNavButtons, setTopNavButtons] = useState(false);
  const [isLoginOrRegister, setLoginOrRegister] = useState({
    choose: true,
    register: false,
    login: false,
  });
  const [isForm, setForm] = useState(true);

  const [isVerifyNumberPopup, setVerifyNumberPopup] = useState(false);
  const [isCodeNotRightPopup, setCodeNotRightPopup] = useState(false);
  const [codeNotRightMessage, setCodeNotRightMessage] = useState('')

  const [isLoginWithPage, setLoginWithPage] = useState({
    loginWithPhone: false,
    loginWithSms: false,
  });

  const [mainBgImage, setMainBgImage] = useState({
    main: orangeBgImage,
    noPeople: orangeBgImageNoPeople,
  });

  const [registerStep, setRegisterStep] = useState({
    userDetails: false,
    verifyPhone: false,
    acceptTerms: false,
    afterRegister: false,
  });

  const [loginStep, setLoginStep] = useState({
    enterPhone: false,
    loginWithSms: false,
  });

  const [progress, setProgress] = useState(0);

  const updateRegisterStep = (stateToUpdate) => {
    setRegisterStep((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };
  const updateLoginStep = (stateToUpdate) => {
    setLoginStep((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };

  const updateLoginOrRegister = (stateToUpdate) => {
    setLoginOrRegister((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };
  const updateStepNumber = (number) => {
    setStepNumber(number);
  };
  const updateColorMode = (isOrange) => {
   /* setOrange(isOrange);*/
  };
  const updateGreenMobile = (isGreen) => {
    setGreenMobile(isGreen);
  };
  const updateTopNavButtons = (isTopNav) => {
    setTopNavButtons(isTopNav);
  };

  const updateMainBgImage = (image, imageNoPeople) => {
   /* setMainBgImage({
      main: image,
      noPeople: imageNoPeople,
    });*/
  };

  const updateIsForm = (value) => {
    setForm(value);
  };

  const updateVerifyNumberPopup = (value) => {
    setVerifyNumberPopup(value);
  };
  const updateCodeNotRightPopup = (value) => {
    setCodeNotRightPopup(value);
  };
  const updateCodeNotRightMessage = (value) => {
    setCodeNotRightMessage(value);
  };
  const updateLoginWith = (stateToUpdate) => {
    setLoginWithPage((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };

  const updateProgress = (value) => {
    setProgress(value);
  };

  return (
    <>
      <main
        className={`credentials-page ${isForm ? "credentials-page_form" : ""} ${isOrange ? "credentials-page_orange" : ""
          } ${isTopNavButtons ? "credentials-page_nav-buttons" : ""} ${registerStep.userDetails ? "register" : ""
          } ${isLoginOrRegister.choose ? "choose-login-or-register" : ""} ${isLoginWithPage.loginWithSms ? "login-with login-with_sms" : ""
          } ${registerStep.acceptTerms ? "terms-form" : ""} ${registerStep.afterRegister ? "after-register" : ""
          } ${isLoginWithPage.loginWithPhone ? "login-with" : ""}`}
      >
        <section
          className={`credentials-section ${isGreenMobile ? "green-mobile" : ""
            }`}
        >
          <div className="credentials-section__bg-image-ibg">
            <img src={mainBgImage.main} className="img-people" alt="bg" />
            <img
              src={mainBgImage.noPeople}
              className="img-no-people"
              alt="bg"
            />
          </div>
          <div className="credentials-section__content-window">
            <div className="credentials-section__steps">
              <div
                className={`credentials-section__steps-numbers numbers-steps ${isOrange ? "numbers-steps_orange" : ""
                  } step-${stepNumber}`}
              >
                <ul className="numbers-steps__list">
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">1</div>
                  </li>
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">2</div>
                  </li>
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">3</div>
                  </li>
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">4</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="credentials-section__content">
              {isLoginOrRegister.choose ? (
                <ChooseLoginOrRegister
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateRegisterStep={updateRegisterStep}
                  updateLoginStep={updateLoginStep}
                  updateLoginWith={updateLoginWith}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                />
              ) : (
                ""
              )}
              {isLoginOrRegister.register ? (
                <RegisterReferalWishlist
                  isLoggedInHandler={isLoggedInHandler}
                  currUserObject={currUserObject}
                  setCurrUserObject={setCurrUserObject}
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  isTopNavButtons={isTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateVerifyNumberPopup={updateVerifyNumberPopup}
                  updateCodeNotRightPopup={updateCodeNotRightPopup}
                  updatePopupState={updatePopupState}
                  updateLoginWith={updateLoginWith}
                  registerStep={registerStep}
                  updateRegisterStep={updateRegisterStep}
                  updateIsForm={updateIsForm}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                  updateLoginStep={updateLoginStep}
                  updateCodeNotRightMessage={updateCodeNotRightMessage}
                  isRedirectPresent={isRedirectPresent}
                  setNotLoggedIn={setNotLoggedIn}
                  updateDidRegistered={updateDidRegistered}
                />
              ) : (
                ""
              )}
              {isLoginOrRegister.login ? (
                <LoginReferalWishlist
                  isLoggedInHandler={isLoggedInHandler}
                  currUserObject={currUserObject}
                  setCurrUserObject={setCurrUserObject}
                  updateLoginStep={updateLoginStep}
                  loginStep={loginStep}
                  isTopNavButtons={isTopNavButtons}
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateLoginWith={updateLoginWith}
                  updateVerifyNumberPopup={updateVerifyNumberPopup}
                  updateCodeNotRightPopup={updateCodeNotRightPopup}
                  updatePopupState={updatePopupState}
                  updateIsForm={updateIsForm}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                  updateRegisterStep={updateRegisterStep}
                  updateCodeNotRightMessage={updateCodeNotRightMessage}
                  isRedirectPresent={isRedirectPresent}
                  setNotLoggedIn={setNotLoggedIn}
                  setUserNotFirstTime={setUserNotFirstTime}
                  updateDidRegistered={updateDidRegistered}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </main>
      {isVerifyNumberPopup && (
        <WeSendYouSmsPopup
          updateVerifyNumberPopup={updateVerifyNumberPopup}
          updatePopupState={updatePopupState}
        />
      )}
      {isCodeNotRightPopup && (
        <CodeNotRightPopup
          codeNotRightMessage={codeNotRightMessage}
          updateCodeNotRightPopup={updateCodeNotRightPopup}
          updatePopupState={updatePopupState}
        />
      )}
    </>
  );
}

export default CredentialsReferal;
