import React, { useEffect, useState } from "react";
import { cart1, giftBasketTopbar, topbarBg } from "../Assets/images";
import SinglePurchase from "../Components/purchase-single";
import TransactionItem from "../Components/TransactionItem"; // Import the new component
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";

function Cart({
  currWishlist,
  setCurrWishlist,
  cartForFriendsList,
  setCartForFriendsList,
  setMyEventsActiveTab,
  currUserObject,
}) {
  const { wishlistId } = useParams();

  // Set default tab to "unpurchased"
  const [cartType, setCartType] = useState("unpurchased");

  const [sortFilter, setSortFilter] = useState({
    isOpen: false,
    filter: "date",
    sortType: "asc",
  });

  const handleSortDrop = () => {
    const newValue = !sortFilter.isOpen;
    setSortFilter({
      isOpen: newValue,
      filter: sortFilter.filter,
      sortType: sortFilter.sortType,
    });
  };

  const setFilterPrice = () => {
    setSortFilter({
      isOpen: false,
      filter: "price",
      sortType: sortFilter.sortType,
    });
  };
  const setFilterDate = () => {
    setSortFilter({
      isOpen: false,
      filter: "date",
      sortType: sortFilter.sortType,
    });
  };

  const setFilterStatus = () => {
    setSortFilter({
      isOpen: false,
      filter: "status",
      sortType: sortFilter.sortType,
    });
  };

  useEffect(() => {
    const sortType = sortFilter.sortType;
    const currentActiveTab = cartType;
    const sortBy = sortFilter.filter;
    console.log(sortFilter);

    setCurrWishlist((prevObj) => {
      const newObj = prevObj;
      const prevArr = prevObj.whishlistItemsDetailsList;
      let filteredArr = [];
      if (prevArr && prevArr.length) {
        filteredArr = prevArr;

        filteredArr.sort((a, b) => {
          const aValue = String(a[sortBy]);
          const bValue = String(b[sortBy]);
          if (sortType === "asc") {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        });
        console.log(filteredArr);
      }

      return { ...newObj, whishlistItemsDetailsList: filteredArr };
    });
  }, [sortFilter]);

  function isStatusRelevantByCartType(productStatus) {
    const conditionPurchased = /buy by me|buy by buyer/; // For "purchased"
    const conditionNotPurchased = /not buy/; // For "unpurchased"

    if (cartType === "unpurchased") {
      return conditionNotPurchased.test(productStatus);
    } else if (cartType === "purchased") {
      return conditionPurchased.test(productStatus);
    } else {
      return false;
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    } else {
      if (!currWishlist.whishlistObject) {
        console.log("no current wishlist");
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${wishlistId}`
          )
          .then((response) => {

            debugger;
            console.log(response.data);
            if (response.data.result) {
              setCurrWishlist({
                whishlistItemsList: response.data.whishlistItemsList,
                whishlistObject: response.data.whishlistObject,
                whishlistItemsDetailsList:
               response.data.whishlistItemsDetailsList,
                transactions: response.data.newTransictions, // Make sure to include transactions
              });
            } else {
              alert("Something went wrong");
            }
          })
          .catch((error) => {
            console.error(error);
            alert("Error, please try again");
          });
      }
    }
  }, []);

  useEffect(() => {
    console.log("Current Wishlist:", currWishlist);
  }, [currWishlist]);

  return (
    <main className="gift-basket">
      <section className="gift-basket__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src={topbarBg} alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              סל המתנות שלי
              <span className="icon">
                <img src={giftBasketTopbar} alt="gift basket topbar" />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <div className="gift-basket__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>סל המתנות שלי</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="gift-basket__main main-gift-basket">
        <div className="main-gift-basket__container">
          <div className="main-gift-basket__navigation">
            <ul className="main-gift-basket__nav-list">
              <li
                className={`main-gift-basket__nav-item green ${
                  cartType === "unpurchased" ? "active" : ""
                }`}
              >
                <button onClick={() => setCartType("unpurchased")}>
                  <span className="icon">
                    {/* SVG Icon */}
                  </span>
                  <span className="text">מתנות שלא נרכשו</span>
                </button>
              </li>
              <li
                className={`main-gift-basket__nav-item red ${
                  cartType === "purchased" ? "active" : ""
                }`}
              >
                <button onClick={() => setCartType("purchased")}>
                  <span className="icon">
                    {/* SVG Icon */}
                  </span>
                  <span className="text">המתנות שלי</span>
                </button>
              </li>
              <li
                className={`main-gift-basket__nav-item red ${
                  cartType === "money_received" ? "active" : ""
                }`}
              >
                <button onClick={() => setCartType("money_received")}>
                  <span className="icon">
                    {/* SVG Icon */}
                  </span>
                  <span className="text">כסף שקבלתי</span>
                </button>
              </li>
            </ul>
          </div>
        

          <div className="main-gift-basket__purchased">
            <div className="main-gift-basket__body">
              {cartType === "money_received" ? (
                <ul className="main-gift-basket__my-purchases-list">
                  {currWishlist.transactions &&
                    currWishlist.transactions.map((transaction, index) => (
                      <li
                        key={index}
                        className="main-gift-basket__my-purchases-item"
                      >
                        <TransactionItem data={transaction} />
                      </li>
                    ))}
                </ul>
              ) : (
                <ul className="main-gift-basket__my-purchases-list">
                  {currWishlist &&
                    currWishlist.whishlistItemsDetailsList &&
                    currWishlist.whishlistItemsDetailsList.map(
                      (product, index) =>
                        isStatusRelevantByCartType(product.status) ? (
                          <li
                            key={index}
                            className="main-gift-basket__my-purchases-item"
                          >
                            <SinglePurchase
                              data={product}
                              currUserObject={currUserObject}
                              currWishlist={currWishlist}
                              setCurrWishlist={setCurrWishlist}
                            />
                          </li>
                        ) : null
                    )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Cart;
