import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  mainBgImage,
  mainBgImageNoPeople,
  orangeBgImage,
  orangeBgImageNoPeople,
  prevBtnImage,
} from "../Assets/images";

import {
  validateTextInput,
  inputMaxCount,
  validateFullPhoneNumber,
} from "../Libs/forms";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import CodeNotRightPopup from "../Popups/code-not-right-popup";

function Login({
  getOTPLimitObj,
  isLoggedInHandler,
  updateLoginStep,
  loginStep,
  isTopNavButtons,
  updateLoginOrRegister,
  updateStepNumber,
  updateColorMode,
  updateTopNavButtons,
  updateMainBgImage,
  updateLoginWith,
  updateVerifyNumberPopup,
  updatePopupState,
  updateIsForm,
  updateCodeNotRightPopup,
  progress,
  updateProgress,
  updateGreenMobile,
  updateRegisterStep,
  currUserObject,
  setCurrUserObject,
  updateCodeNotRightMessage,
  isRedirectPresent,
  setNotLoggedIn,
  setUserNotFirstTime,
  updateDidRegistered,
  codeNotRightMessage,
  isCodeNotRightPopup,
  resetOtpInputs,
  addUserIdToCreatedWishlist,
  doSuccessfullyLoggedinLogic

}) {

  const location = useLocation();
  const navigate = useNavigate();
  const { wishlistId } = useParams()
  const hasNewWishlistPath = location.pathname.includes('credentials-new-wishlist');
  const params = location?.state ? location.state : {};
  const currWishlistId = params?.currWishlistId;
  console.log('login_params: location', location, params?.currWishlistId)
  const [rightSmsCode, setRightSmsCode] = useState("");
  const [isUserExists, setIsUserExists] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [otpTimeLeft, setOTPTimeLeft] = useState();
  const [phoneFormInfo, setPhoneFormInfo] = useState({
    phone: "",
    isRemember: false,
  });

  const [isLoading, setLoading] = useState(false);


  console.log("isUserExists: ", isUserExists);

  const setChooseLoginOrRegister = () => {
    updateLoginOrRegister({
      choose: true,
      register: false,
      login: false,
    });
    updateMainBgImage(orangeBgImage, orangeBgImageNoPeople);
    updateColorMode(true);
    updateStepNumber(2);
    updateTopNavButtons(false);
    updateGreenMobile(true);
  };
  const setRegister = () => {
    updateLoginOrRegister({
      choose: false,
      register: true,
      login: false,
    });
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateColorMode(false);
    updateStepNumber(3);
    updateTopNavButtons(true);
    updateRegisterStep({
      userDetails: true,
      verifyPhone: false,
      acceptTerms: false,
      afterRegister: false,
    });
    updateProgress(5);
    updateGreenMobile(false);
  };
  const setApprove = () => {
    updateLoginOrRegister({
      approve: true
    });
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateColorMode(false);
    updateStepNumber(3);
    updateTopNavButtons(true);
    updateRegisterStep({
      userDetails: true,
      verifyPhone: false,
      acceptTerms: false,
      afterRegister: false,
    });
    updateProgress(5);
    updateGreenMobile(false);
  };
  function goToSubmitSmsCodeStep() {
    updateLoginStep({
      enterPhone: false,
      loginWithSms: true,
    });
    updateVerifyNumberPopup(true);
    updateColorMode(false);
    updateTopNavButtons(true);
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updatePopupState(true);
    updateIsForm(true);
    updateLoginWith({
      loginWithPhone: false,
      loginWithSms: true,
    });
    updateProgress(20);
    window.scrollTo(0, 0);

  }

  function submitPhoneForm(e) {
    e.preventDefault();

    const form = e.target;

    const phoneInput = form["tel"];
    const phoneNumberValue = form["tel"].value;
    setPhoneNumber(phoneNumberValue);
    const isRememberMe = form["isRememberMe"];
    const isFormValid = validateFullPhoneNumber(phoneInput);

    if (isFormValid) {
      const OTPLimitObj = getOTPLimitObj();
      console.log("OTPLimitObj", OTPLimitObj);
      if (OTPLimitObj.isLimited) {
        alert(`שלחית OTP מוגבל, נשאר ${OTPLimitObj.waitTime} שניות`);
        return;
      }
      setPhoneFormInfo({
        phone: form["tel"].value,
        isRemember: isRememberMe.checked,
      });
      setLoading(true);
      axios
        .get(
          `${API_DOMAIN}/api/Authenticate/GetOTP?phoneNumber=${form["tel"].value}`
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {
            setLoading(false);
            //setRightSmsCode(response.data.otpCode);

            goToSubmitSmsCodeStep();

          } else {
            setLoading(false);
            setIsUserExists(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          alert("Error, please try again");
        });
    }
  }

  function resendOTPHandler(e) {
    const OTPLimitObj = getOTPLimitObj();
    console.log("OTPLimitObj", OTPLimitObj);
    if (OTPLimitObj.isLimited) {
      alert(`שלחית OTP מוגבל, נשאר ${OTPLimitObj.waitTime} שניות`);
      return;
    }
    e.preventDefault()
    setLoading(true);
    axios
      .get(
        `${API_DOMAIN}/api/Authenticate/GetOTP?phoneNumber=${phoneFormInfo.phone}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          setLoading(false);
          //setRightSmsCode(response.data.otpCode);

          isLoggedInHandler(true);
          goToSubmitSmsCodeStep();
        } else {
          setLoading(false);
          setIsUserExists(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert("Error, please try again");
      });
  }


  async function loginOTP(otpCode, phoneNumber) {

    const data = {
      phoneNumber: phoneNumber,
      OTPCode: otpCode,
    };
    console.log(data)
    axios
      .post(
        `${API_DOMAIN}/api/Authenticate/LoginWithOTP`,
        data
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          
          doSuccessfullyLoggedinLogic(response);

        } else {
          setLoading(false);
          updateCodeNotRightPopup(true);
          updatePopupState(true);
          updateCodeNotRightMessage(response.data.message)
          resetOtpInputs()
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert("Error, please try again");
      });
  }
  const goToNextCodeInput = (e) => {
    const target = e.target;
    const value = target.value;
    target.blur();
    if (target.parentElement.previousElementSibling) {
      const nextEl = target.parentElement.previousElementSibling.children[0];
      nextEl.focus();
      if (value.length > 1) {
        nextEl.value = value.slice(1);
        nextEl.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };
  const goToPrevCodeInput = (e) => {
    const target = e.target;
    target.blur();
    if (target.parentElement.nextElementSibling) {
      target.parentElement.nextElementSibling.children[0].focus();
    }
  };

  function submitSmsCodeForm(e) {
    e.preventDefault();

    const form = e.target;
    let isCodeValid = false,
      isCodeInputsValid = [false, false, false, false, false];
    let userCode = "";
    for (let i = 0; i < form["code"].length; i++) {
      isCodeInputsValid[i] = validateTextInput(form["code"][i], 1);
      userCode += form["code"][i].value;
    }
    isCodeValid = isCodeInputsValid.every((el) => el === true);
    if (isCodeValid) {
      userCode = userCode.split("").reverse().join("");
      loginOTP(userCode, phoneFormInfo.phone);
      /*  if (userCode === rightSmsCode) {
        navigate("/");
      } else {
        updateCodeNotRightPopup(true);
        updatePopupState(true);
      }  */
    }
    window.scrollTo(0, 0);

  }

  function goToEnterPhoneStep() {
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateColorMode(false);
    updateStepNumber(3);
    updateTopNavButtons(true);
    updateLoginStep({
      enterPhone: true,
      loginWithSms: false,
    });
    updateLoginWith({
      loginWithPhone: true,
      loginWithSms: false,
    });
    window.scrollTo(0, 0);

  }

  const handleCodeInput = (e) => {
    if (e.target.closest("label").classList.contains("input-error")) {
      validateTextInput(e.target, 1);
    }
    if (e.target.value) {
      goToNextCodeInput(e);
    } else {
      goToPrevCodeInput(e);
    }
    inputMaxCount(e.target, 1);
  };
  const goBackIfCodeInputEmpty = (e) => {
    if (
      e.target.value === "" &&
      (e.key === "Backspace" || e.key === "Delete")
    ) {
      goToPrevCodeInput(e);
    }
  };

  const onOtpInputFocus = (e) => {
    e.target.closest('input').select()

  }

  return (
    <>

      {isCodeNotRightPopup && (
        <CodeNotRightPopup
          codeNotRightMessage={codeNotRightMessage}
          updateCodeNotRightPopup={updateCodeNotRightPopup}
          updatePopupState={updatePopupState}
          resendOTPHandler={resendOTPHandler}
        />
      )}
      {isTopNavButtons ? (
        <div className="credentials-section__top-nav-buttons">
          <button
            className="credentials-section__top-nav-btn reg"
            onClick={setRegister}
          >
חדשים כאן?          </button>
          <button className="credentials-section__top-nav-btn login active">
           כבר נרשמתי בעבר
          </button>
        </div>
      ) : (
        ""
      )}
      {loginStep.enterPhone ? (
        <>
          <button
            type="button"
            onClick={setChooseLoginOrRegister}
            className="credentials-section__prev-btn"
          >
            <img src={prevBtnImage} alt="arrow left black" />
          </button>
          <h1 className="credentials-section__title credentials-section__title_icon-top">
            <div className="mobile-icon">
              <img src="img/credentials/login-with.svg" alt="icon" />
            </div>
            כניסה
          </h1>
          <h2 className="credentials-section__text">
הכנס את מספר הפלאפון שלך
          </h2>

          <div
            className={`credentials-section__form-wrapper ${isLoading ? "loading" : ""
              }`}
          >
            <form
              action="#"
              onSubmit={submitPhoneForm}
              className="credentials-section__form form-credentials-section"
            >
              <label
                htmlFor="form-credentials-section__tel"
                className="form-credentials-section__input-wrapper input-wrapper"
              >
                <span className="input-title">...המספר נייד שלי הוא</span>
                <input
                  autoComplete="off"
                  type="tel"
                  name="tel"
                  id="form-credentials-section__tel"
                  className="input"
                  defaultValue={phoneFormInfo.phone}
                  onInput={(e) => {
                    if (
                      e.target
                        .closest("label")
                        .classList.contains("input-error")
                    ) {
                      validateFullPhoneNumber(e.target);
                    }
                    inputMaxCount(e.target, 10);
                  }}
                />
                <span className="error-text">
                  מספר פלאפון לא תקין
                </span>
                {isUserExists == false && (
                  <span className="not-exist">משתמש לא קיים</span>
                )}
              </label>

              <p className="credentials-section__text">או</p>

              <a
                href="https://facebook.com"
                className="credentials-section__facebook-link"
              >
                <svg
                  id="Facebook"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16.997"
                  viewBox="0 0 25 16.997"
                >
                  <rect
                    id="Прямоугольник_392"
                    data-name="Прямоугольник 392"
                    width="16"
                    height="16"
                    transform="translate(9)"
                    fill="none"
                  />
                  <path
                    id="Контур_1"
                    data-name="Контур 1"
                    d="M84.543,13.4V7.3h2.085l.3-2.383H84.543V3.426c0-.67.223-1.191,1.191-1.191H87V.074C86.7.074,85.957,0,85.138,0a2.875,2.875,0,0,0-3.053,3.128V4.915H80V7.3h2.085V13.4Z"
                    transform="translate(-80 3.592)"
                    fillRule="evenodd"
                  />
                </svg>
                התחברות עם פייסבוק
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                >
                  <g
                    id="Компонент_1"
                    data-name="Компонент 1"
                    transform="translate(12) rotate(90)"
                  >
                    <path
                      id="Контур_36"
                      data-name="Контур 36"
                      d="M7.529,9.176,2,3.647,3.647,2,7.529,5.882,11.411,2l1.647,1.647Z"
                      transform="translate(-2 0.353)"
                    />
                    <rect
                      id="Прямоугольник_1467"
                      data-name="Прямоугольник 1467"
                      width="12"
                      height="12"
                      fill="none"
                    />
                  </g>
                </svg>
              </a>
              <div className="form-credentials-section__check">
                <div className="checkbox">
                  <input
                    id="c_1"
                    className="checkbox__input"
                    defaultChecked={phoneFormInfo.isRemember}
                    type="checkbox"
                    value="1"
                    name="isRememberMe"
                  />
                  <label htmlFor="c_1" className="checkbox__label">
                    <span className="checkbox__text">זכור אותי</span>
                  </label>
                </div>
              </div>

 

              <div className="form-credentials-section__buttons-container">
                <button type="submit" className="btn">
                  שלחו לי קוד
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      {loginStep.loginWithSms ? (
        <>
          <button
            onClick={goToEnterPhoneStep}
            className="credentials-section__prev-btn"
          >
            <img src={prevBtnImage} alt="arrow left black" />
          </button>
          <h1 className="credentials-section__title credentials-section__title_icon-top">
            <div className="mobile-icon">
              <img src="img/credentials/login-with.svg" alt="icon" />
            </div>
            אימות
          </h1>
          <h2 className="credentials-section__text">מלא/י את הקוד שנשלח לפלאפון</h2>

          <div className="credentials-section__form-wrapper">
            <form
              onSubmit={submitSmsCodeForm}
              className="credentials-section__form form-credentials-section"
            >
              <div className="form-credentials-section__code-wrapper">
                <div className="form-credentials-section__code">
                  <label
                    htmlFor="code-input-1"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-1"
                      className="code-input"
                      onFocus={onOtpInputFocus}

                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-2"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-2"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-3"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-3"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-4"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-4"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="code-input-5"
                    className="form-credentials-section__code-input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="number"
                      name="code"
                      id="code-input-5"
                      className="code-input"
                      onFocus={onOtpInputFocus}
                      onKeyDown={(e) => {
                        goBackIfCodeInputEmpty(e);
                      }}
                      onInput={(e) => {
                        handleCodeInput(e);
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="form-credentials-section__buttons-container">
                <button type="submit" className="btn">
                  כניסה
                </button>
              </div>
              <div className="form-credentials-section__buttons-container resend">
             
                <p className="credentials-section__text text-link" style={{marginTop: '5vh'}}>
                לא קיבלתי קוד אימות <a style={{cursor: 'pointer'}} onClick={resendOTPHandler}>שלחו לי שוב</a>
              </p>
              </div>

              {/* <a href="#" className="form-credentials-section__red-link">
                אם הקוד שגוי
              </a> */}
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="credentials-section__progress">
        <div className="percent" style={{ right: `${progress}%` }}>
          {`${progress}%`}
        </div>
        <div className="bar">
          <div className="background"></div>
          <div className="percent-line" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </>
  );
}

export default Login;
