import React, { useEffect, useState } from "react";
import { API_DOMAIN_IMG } from "../Libs/config";
import { eventTypeImgNoPeople1 } from "../Assets/images";
import { NavLink, useNavigate } from "react-router-dom/dist";

export default function CategoryCard({
  data: { id, categoryIcon, categoryImage, categoryName },
  wishlistId,
  newEvent,
  allCategoriesList,
  setProductsFilterObject,
  productsFilterObjectz,
  isMobile,
  setIsMobile,
  setSelectedCategoriesMobile,
  selectedCategoriesMobile
}) {
  const navigate = useNavigate();
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const additionalGift = Boolean(URLParams?.get("additionalGift"));
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (allCategoriesList) {
      setCategories(() => {
        const currentMainCategory = allCategoriesList.find(
          (category) => category.mainCategory?.id === Number(id)
        );
        return currentMainCategory?.productCategory;
      });
    }
  }, [categories]);

  useEffect(() => {
    if (categories) {
      setSelectedCategories([...categories]);
    }
  }, [categories]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigateToProducts = (e) => {
    const { target } = e;
    if (target.closest(".single-category-type__footer")) return;
    const urlCategories = selectedCategories.length
      ? selectedCategories
      : categories;
    if (newEvent) {
      navigate(
        `/products-new-event/${id}/?wishlistId=${currWishlistId}${additionalGift ? "&additionalGift=" + additionalGift : ""
        }${urlCategories.length
          ? `&category=${urlCategories.map((item) => item?.id).join()}`
          : ""
        }`
      );

    } else {
      navigate(`/products/${id}/?wishlistId=${currWishlistId}`);
    }
  };

  const filterFunction = (e, index, category) => {
    const value = e.target.value;

    if (e.target.checked) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(
        selectedCategories.filter(
          (selectedCategory) => selectedCategory?.id !== category?.id
        )
      );
    }

 //   console.log("Selected Categories:", selectedCategories);
  };
  const filterFunctionMobile = (e, index, category) => {
    debugger;
    const value = e.target.value;
           e.stopPropagation();
     if (e.target.checked) {
      setSelectedCategoriesMobile([...selectedCategoriesMobile, category.id]);
    } else {
      setSelectedCategoriesMobile(
        selectedCategoriesMobile.filter(
          (selectedCategory) => selectedCategory !== category?.id
        )
      );
    }

    console.log("Selected Categories:", selectedCategories);
  };
  return (
    <>

    {!isMobile &&
    <li className="main-category__list-item" onClick={toggleDropdown}>
      <div className="main-category__single-category-type single-category-type">
        <div
       onClick={navigateToProducts}
          state={{
            id: id
          }}
          className="single-category-type__content active"
        >
          <div className="single-category-type__image-ibg">
            <img
              src={`${API_DOMAIN_IMG}${categoryImage}`}
              alt=""
              className="img-people"
            />
            <img
              src={eventTypeImgNoPeople1}
              alt=""
              className="img-no-people"
            />
          </div>
          <div
            className="single-category-type__text-content"
            style={{ paddingBottom: "1vw" }}
          >
            <div className="single-category-type__icon">
              <img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" />{" "}
            </div>
            <h3 className="single-category-type__title">{categoryName}</h3>
            <div className="single-category-type__btn-container btn-container btn-container_center">
              <button className="btn">לצפייה</button>
            </div>
            <div className="single-category-type__footer">
              <div className="single-category-type__dropdown">
                <button
                  type="button"
                  className="single-category-type__dropdown-btn"
                >
                  <img
                    src="../img/svg/filter-icon-black.svg"
                    alt="filter"
                  />
                  ביחרו קטגוריות למתנות
                  <img
                    src="../img/svg/arrow-bottom-black.svg"
                    alt="arrow bottom"
                  />
                </button>
                <div
                  className={`single-category-type__dropdown-body ${isDropdownOpen ? "open" : ""
                    }`}
                >
                  <ul className={'single-category-type__dropdown-list '}>
                    {categories &&
                      categories.map((category, index) => (
                        <div key={index} className="checkbox">
                          <input
                            id={`manufacturer_${category?.id}`}
                            className="checkbox__input manufacturer-check"
                            type="checkbox"
                            defaultValue={category?.id}
                            checked={selectedCategories.some(
                              (c) => c?.id === category?.id
                            )}
                            name="form[]"
                            onChange={(e) =>
                              filterFunction(e, null, category)
                            }
                          />
                          <label
                            htmlFor={`manufacturer_${category?.id}`}
                            className="checkbox__label"
                            id="changePink"
                          >
                            <span
                              className="checkbox__text"
                              style={{ color: "#000" }}
                            >
                              {category.niceName}
                            </span>
                          </label>
                        </div>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>}

    {isMobile &&
    <li className="main-category__list-item" >
      <div className="main-category__single-category-type single-category-type">
        <div
       onClick={!isMobile  ? navigateToProducts : null}
        // onClick={!isMobile || (isMobile && isDropdownOpen) ? navigateToProducts : null}
          state={{
            id: id
          }}
          className="single-category-type__content active"
        >
   
          <div
            className="single-category-type__text-content"
            style={{ paddingBottom: "1vw" }}
            onClick={toggleDropdown}
          >
            <div className="single-category-type__icon">
              <img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" />{" "}
            </div>
            <h3 className="single-category-type__title">{categoryName}</h3>
            <div className="single-category-type__btn-container btn-container btn-container_center">
            </div>
       
          </div>         

          </div>
            {isDropdownOpen &&
                <div
                  className={`single-category-type__dropdown-body}`}
                >
                  <ul className={'single-category-type__dropdown-list wishlist'}>
                    {categories &&
                      categories.map((category, index) => (
                        <div key={index} className="checkbox line">
                       <input
    id={`manufacturer_${category?.id}`}
    className="checkbox__input manufacturer-check custom-checkbox"
    type="checkbox"
    defaultValue={category?.id}
    checked={selectedCategoriesMobile.some((c) => c === category?.id)}
    name="form[]"
    onChange={(e) => filterFunctionMobile(e, null, category)}
/>

<label
    htmlFor={`manufacturer_${category?.id}`}
    className="checkbox__label"
    id="changePink"
>
    <span className="checkbox__text" style={{ color: "#000" }}>
        {category.niceName}
    </span>
</label>

                      
                        </div>
                      ))}
                  </ul>
                </div>}
              
            </div>
    </li>}
    



    </>
  );
}
