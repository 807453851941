import React, { useRef } from "react";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";

const Controls = ({ zoomIn, zoomOut, resetTransform,onClose }) => (
  <div className="image-popup-control-wrapper">
    <button className="image-popup-controls" onClick={() => zoomIn()}>+</button>
    <button className="image-popup-controls" onClick={() => zoomOut()}>-</button>
    <button className="image-popup-controls" onClick={onClose}>x</button>
    {/* <button className="image-popup-controls" onClick={() => resetTransform()}>x</button> */}
    {/* <button onClick={onClose}>close</button> */}
  </div>
);

const Component = ({ imageUrl, onClose }) => {
  const transformComponentRef = useRef(null);

  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      zoomToElement("imgExample");
    }
  };

  return (
    <TransformWrapper
      initialScale={1}
      // initialPositionX={200}
      // initialPositionY={100}
      ref={transformComponentRef}
      className="transform-wrapper"
    >

      {(utils) => (
        <React.Fragment>
          <Controls {...utils} onClose={onClose} />
          <TransformComponent style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <img src={imageUrl} alt="" id="imgExample" />

            <div onClick={zoomToImage}></div>
          </TransformComponent>
        </React.Fragment>
      )
      }
    </TransformWrapper>
  );
};

export default function ImagePopup({ imageUrl, onClose }) {
  return (
    <>
      <div className="image-popup-overlay">
        <Component imageUrl={imageUrl} onClose={onClose} />
      </div>
    </>

  );
}