import React from "react";
import { NavLink } from "react-router-dom";
import { API_DOMAIN_IMG } from "../../Libs/config";
import { eventTypeImgNoPeople1 } from "../../Assets/images";

export default function MyBankSingleCategory({
  data: {
    categoryIcon,
    categoryImage,
    creationDate,
    description,
    id,
    niceName,
  },
  mainCategoryId,
  currWishlistId,
  newEvent
}) {
  return (
    <li className="main-category__list-item">
      <div className="main-category__single-category-type single-category-type">
        <NavLink
          to={`/my-bank/products/${mainCategoryId}/${id}?wishlistId=${currWishlistId}`}
          className="single-category-type__content active"
        >
          <div className="single-category-type__image-ibg">
            <img src={eventTypeImgNoPeople1} alt="1"   className="img-no-people"/>
            <img src={`${API_DOMAIN_IMG}${categoryImage}`} className="img-people"  alt="" />
          </div>
          <div className="single-category-type__text-content" style={{paddingBottom: '1rem'}}>
            <div className="single-category-type__icon"><img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" /> </div>
            <h3 className="single-category-type__title">{niceName}</h3>
            <p className="single-category-type__text">{description}</p>
            <div className="single-category-type__btn-container btn-container btn-container_center">
              <button className="btn">Choose</button>
            </div>
            {/* <div className="single-category-type__footer">
              <div className="single-category-type__dropdown">
                <button
                  type="button"
                  className="single-category-type__dropdown-btn"
                >
                  <img src="../img/svg/filter-icon-black.svg" alt="filter" />
                  ביחרו קטגוריות למתנות
                  <img
                    src="../img/svg/arrow-bottom-black.svg"
                    alt="arrow bottom"
                  />
                </button>
                <div className="single-category-type__dropdown-body">
                  <ul className="single-category-type__dropdown-list">
                    {dropdownCategories &&
                      dropdownCategories.map((dropCategory) => {
                        const { id, name, active } = dropCategory;

                        return (
                          <li
                            key={id}
                            className={`single-category-type__dropdown-list-item ${
                              active ? "active" : ""
                            }`}
                          >
                            <p>{name}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </NavLink>
      </div>
    </li>
  );
}
