import React from "react";
import { blessingBg, blessingBgNoPeople } from "../Assets/images";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function SuccessfullPayment() {
  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  return (
    <main className="successful-payment" data-fullscreen>
      <section className="successful-payment__main main-successful-payment">
        <div className="main-successful-payment__bg-image-ibg">
          <img src={blessingBg} className="img-people" alt="bg" />
          <img src={blessingBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="main-successful-payment__content-window">
          <h1 className="main-successful-payment__title">להתראות</h1>
          <h2 className="main-successful-payment__subtitle">
            סיימת את התהליך קניית המתנה
          </h2>
        </div>
      </section>
    </main>
  );
}
 
export default SuccessfullPayment;
