import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function EventsHistory() {
  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  return (
    <main class="choose-event events-history">
      <section class="choose-event__topbar topbar">
        <div class="topbar__bg-img-ibg">
          <img src="img/common/topbar-bg-green.png" alt="topbar" />
        </div>
        <div class="topbar__container">
          <div class="topbar__title-wrapper">
            <h1 class="topbar__title">
              אירועים שהוזמנתי
              <span class="icon">
                <img src="img/svg/globe.svg" alt="globe" />
              </span>
            </h1>
          </div>
        </div>
      </section>

      <div class="choose-event__breadcrumbs breadcrumbs">
        <div class="breadcrumbs__container">
          <ul class="breadcrumbs__list">
            <li class="breadcrumbs__item">
              <a href="#">דף הבית</a>
            </li>
            <li class="breadcrumbs__item">
              <a href="#">יום הולדת לנשים</a>
            </li>
            <li class="breadcrumbs__item">
              <a href="#">מתנות מומלצות ליום הולדת לנשים</a>
            </li>
          </ul>
        </div>
      </div>

      <section class="choose-event__main main-choose-event">
        <div class="main-choose-event__container">
          <div class="main-choose-event__header">
            <div class="main-choose-event__navigation">
              <ul class="main-choose-event__nav-list">
                <li class="main-choose-event__nav-item">
                  <button type="button" class="main-choose-event__button ">
                    אירוע חדש
                  </button>
                </li>
                <li class="main-choose-event__nav-item">
                  <button type="button" class="main-choose-event__button">
                    האירועים שלי
                  </button>
                </li>
                <li class="main-choose-event__nav-item">
                  <button type="button" class="main-choose-event__button">
                    היסטוריית אירועים
                  </button>
                </li>
                <li class="main-choose-event__nav-item">
                  <button
                    type="button"
                    class="main-choose-event__button active"
                  >
                    אירועים שהוזמנתי
                  </button>
                </li>
              </ul>
            </div>
            <div class="main-choose-event__filter">
              <p class="text">מיין</p>
              <button type="button" class="arrow bottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_912"
                    data-name="Сгруппировать 912"
                    transform="translate(19.135 -7.296) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_540"
                      data-name="Сгруппировать 540"
                      transform="translate(14.998 17.723) rotate(180)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <button type="button" class="arrow top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_913"
                    data-name="Сгруппировать 913"
                    transform="translate(19.708 -6.837) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_539"
                      data-name="Сгруппировать 539"
                      transform="translate(8.25 4.002)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="main-choose-event__body">
            <ul class="main-choose-event__books">
              <li class="main-choose-event__books-item">
                <div class="single-books-item">
                  <div class="single-books-item__content">
                    <div class="single-books-item__image-ibg">
                      <img
                        src="img/choose-event-type/1.png"
                        class="img-people"
                        alt="1"
                      />
                      <img
                        src="img/no-people-images/choose-event-type/1.png"
                        class="img-no-people"
                        alt="1"
                      />
                    </div>
                    <div class="single-books-item__text-content">
                      <h3 class="single-books-item__title">
                        יום הולדת 12 לשירלי
                      </h3>
                      <p class="single-books-item__date">18/6/20</p>
                      <div class="single-books-item__actions">
                        <button class="button delete">
                          <svg
                            id="Trash_can"
                            data-name="Trash can"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            width="24.153"
                            height="28.357"
                            viewBox="0 0 24.153 28.357"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Прямоугольник_1981"
                                  data-name="Прямоугольник 1981"
                                  width="22.385"
                                  height="19.451"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#716e6e"
                                  stroke-width="1.4"
                                />
                              </clipPath>
                              <clipPath id="clip-path-2">
                                <rect
                                  id="Прямоугольник_1983"
                                  data-name="Прямоугольник 1983"
                                  width="14.146"
                                  height="6.189"
                                  fill="none"
                                  stroke="#716e6e"
                                  stroke-width="1.4"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Прямоугольник_1980"
                              data-name="Прямоугольник 1980"
                              transform="translate(0 5.78)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-width="1.4"
                            >
                              <rect
                                width="24.153"
                                height="3.537"
                                rx="1.768"
                                stroke="none"
                              />
                              <rect
                                x="0.7"
                                y="0.7"
                                width="22.753"
                                height="2.137"
                                rx="1.068"
                                fill="none"
                              />
                            </g>
                            <line
                              id="Линия_491"
                              data-name="Линия 491"
                              x2="1.603"
                              y2="11.543"
                              transform="translate(6.809 11.427)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <line
                              id="Линия_492"
                              data-name="Линия 492"
                              y2="11.543"
                              transform="translate(11.96 11.427)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <line
                              id="Линия_493"
                              data-name="Линия 493"
                              x1="1.483"
                              y2="11.291"
                              transform="translate(15.292 11.412)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <g
                              id="Группа_масок_833"
                              data-name="Группа масок 833"
                              transform="translate(0.884 8.906)"
                              clip-path="url(#clip-path)"
                            >
                              <g
                                id="Контур_8526"
                                data-name="Контур 8526"
                                transform="translate(8.235 -3.346)"
                                fill="none"
                              >
                                <path
                                  d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                  stroke="none"
                                />
                                <path
                                  d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                  stroke="none"
                                  fill="#716e6e"
                                />
                              </g>
                            </g>
                            <g
                              id="Группа_масок_834"
                              data-name="Группа масок 834"
                              transform="translate(5.004 0)"
                              clip-path="url(#clip-path-2)"
                            >
                              <g
                                id="Прямоугольник_1982"
                                data-name="Прямоугольник 1982"
                                transform="translate(0 2.652)"
                                fill="none"
                                stroke="#716e6e"
                                stroke-width="1.4"
                              >
                                <rect
                                  width="13.262"
                                  height="5.305"
                                  rx="2.652"
                                  stroke="none"
                                />
                                <rect
                                  x="0.7"
                                  y="0.7"
                                  width="11.862"
                                  height="3.905"
                                  rx="1.952"
                                  fill="none"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                        <button class="button gift">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.868"
                            height="30.872"
                            viewBox="0 0 27.868 30.872"
                          >
                            <g
                              id="Layer_6"
                              data-name="Layer 6"
                              transform="translate(0.1 0.117)"
                            >
                              <path
                                id="Контур_7573"
                                data-name="Контур 7573"
                                d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                transform="translate(-9.9 -4.909)"
                                fill="#716e6e"
                                stroke="#716e6e"
                                stroke-width="0.2"
                              />
                            </g>
                          </svg>
                        </button>
                        <div class="arrow">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.115"
                            height="17.117"
                            viewBox="0 0 9.115 17.117"
                          >
                            <g
                              id="Сгруппировать_74339"
                              data-name="Сгруппировать 74339"
                              transform="translate(16.411 19.135) rotate(180)"
                            >
                              <g
                                id="Сгруппировать_540"
                                data-name="Сгруппировать 540"
                                transform="translate(14.998 17.723) rotate(180)"
                              >
                                <line
                                  id="Линия_434"
                                  data-name="Линия 434"
                                  y1="7.147"
                                  x2="6.289"
                                  transform="translate(0)"
                                  fill="none"
                                  stroke="#a7a7a7"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                />
                                <path
                                  id="Контур_6897"
                                  data-name="Контур 6897"
                                  d="M0,0,6.289,6.861"
                                  transform="translate(0 7.432)"
                                  fill="none"
                                  stroke="#a7a7a7"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div class="single-books-item__dropdown-body">
                      <div class="single-books-item__dropdown-content">
                        <div class="single-books-item__dropdown-table">
                          <div class="single-books-item__dropdown-table-column">
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">תאריך האירוע</p>
                              <p class="value">18/10/22</p>
                            </div>
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">שעת הגעה</p>
                              <p class="value">17:30</p>
                            </div>
                          </div>
                          <div class="single-books-item__dropdown-table-column">
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">כתובת האירוע</p>
                              <p class="value">כפר יונה</p>
                            </div>
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">טלפון לתיאומים</p>
                              <p class="value">054 - 88852224</p>
                            </div>
                          </div>
                        </div>
                        <p class="single-books-item__dropdown-bottom-text">
                          בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר בחרו
                          מתוך רשימת המתנות המועדפת שלי אז למה אתם מחכים?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="main-choose-event__books-item">
                <div class="single-books-item">
                  <div class="single-books-item__content">
                    <div class="single-books-item__image-ibg">
                      <img
                        src="img/choose-event-type/1.png"
                        class="img-people"
                        alt="1"
                      />
                      <img
                        src="img/no-people-images/choose-event-type/1.png"
                        class="img-no-people"
                        alt="1"
                      />
                    </div>
                    <div class="single-books-item__text-content">
                      <h3 class="single-books-item__title">
                        יום הולדת 12 לשירלי
                      </h3>
                      <p class="single-books-item__date">18/6/20</p>
                      <div class="single-books-item__actions">
                        <button class="button delete">
                          <svg
                            id="Trash_can"
                            data-name="Trash can"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            width="24.153"
                            height="28.357"
                            viewBox="0 0 24.153 28.357"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Прямоугольник_1981"
                                  data-name="Прямоугольник 1981"
                                  width="22.385"
                                  height="19.451"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#716e6e"
                                  stroke-width="1.4"
                                />
                              </clipPath>
                              <clipPath id="clip-path-2">
                                <rect
                                  id="Прямоугольник_1983"
                                  data-name="Прямоугольник 1983"
                                  width="14.146"
                                  height="6.189"
                                  fill="none"
                                  stroke="#716e6e"
                                  stroke-width="1.4"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Прямоугольник_1980"
                              data-name="Прямоугольник 1980"
                              transform="translate(0 5.78)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-width="1.4"
                            >
                              <rect
                                width="24.153"
                                height="3.537"
                                rx="1.768"
                                stroke="none"
                              />
                              <rect
                                x="0.7"
                                y="0.7"
                                width="22.753"
                                height="2.137"
                                rx="1.068"
                                fill="none"
                              />
                            </g>
                            <line
                              id="Линия_491"
                              data-name="Линия 491"
                              x2="1.603"
                              y2="11.543"
                              transform="translate(6.809 11.427)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <line
                              id="Линия_492"
                              data-name="Линия 492"
                              y2="11.543"
                              transform="translate(11.96 11.427)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <line
                              id="Линия_493"
                              data-name="Линия 493"
                              x1="1.483"
                              y2="11.291"
                              transform="translate(15.292 11.412)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <g
                              id="Группа_масок_833"
                              data-name="Группа масок 833"
                              transform="translate(0.884 8.906)"
                              clip-path="url(#clip-path)"
                            >
                              <g
                                id="Контур_8526"
                                data-name="Контур 8526"
                                transform="translate(8.235 -3.346)"
                                fill="none"
                              >
                                <path
                                  d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                  stroke="none"
                                />
                                <path
                                  d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                  stroke="none"
                                  fill="#716e6e"
                                />
                              </g>
                            </g>
                            <g
                              id="Группа_масок_834"
                              data-name="Группа масок 834"
                              transform="translate(5.004 0)"
                              clip-path="url(#clip-path-2)"
                            >
                              <g
                                id="Прямоугольник_1982"
                                data-name="Прямоугольник 1982"
                                transform="translate(0 2.652)"
                                fill="none"
                                stroke="#716e6e"
                                stroke-width="1.4"
                              >
                                <rect
                                  width="13.262"
                                  height="5.305"
                                  rx="2.652"
                                  stroke="none"
                                />
                                <rect
                                  x="0.7"
                                  y="0.7"
                                  width="11.862"
                                  height="3.905"
                                  rx="1.952"
                                  fill="none"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                        <button class="button gift">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.868"
                            height="30.872"
                            viewBox="0 0 27.868 30.872"
                          >
                            <g
                              id="Layer_6"
                              data-name="Layer 6"
                              transform="translate(0.1 0.117)"
                            >
                              <path
                                id="Контур_7573"
                                data-name="Контур 7573"
                                d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                transform="translate(-9.9 -4.909)"
                                fill="#716e6e"
                                stroke="#716e6e"
                                stroke-width="0.2"
                              />
                            </g>
                          </svg>
                        </button>
                        <div class="arrow">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.115"
                            height="17.117"
                            viewBox="0 0 9.115 17.117"
                          >
                            <g
                              id="Сгруппировать_74339"
                              data-name="Сгруппировать 74339"
                              transform="translate(16.411 19.135) rotate(180)"
                            >
                              <g
                                id="Сгруппировать_540"
                                data-name="Сгруппировать 540"
                                transform="translate(14.998 17.723) rotate(180)"
                              >
                                <line
                                  id="Линия_434"
                                  data-name="Линия 434"
                                  y1="7.147"
                                  x2="6.289"
                                  transform="translate(0)"
                                  fill="none"
                                  stroke="#a7a7a7"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                />
                                <path
                                  id="Контур_6897"
                                  data-name="Контур 6897"
                                  d="M0,0,6.289,6.861"
                                  transform="translate(0 7.432)"
                                  fill="none"
                                  stroke="#a7a7a7"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div class="single-books-item__dropdown-body">
                      <div class="single-books-item__dropdown-content">
                        <div class="single-books-item__dropdown-table">
                          <div class="single-books-item__dropdown-table-column">
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">תאריך האירוע</p>
                              <p class="value">18/10/22</p>
                            </div>
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">שעת הגעה</p>
                              <p class="value">17:30</p>
                            </div>
                          </div>
                          <div class="single-books-item__dropdown-table-column">
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">כתובת האירוע</p>
                              <p class="value">כפר יונה</p>
                            </div>
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">טלפון לתיאומים</p>
                              <p class="value">054 - 88852224</p>
                            </div>
                          </div>
                        </div>
                        <p class="single-books-item__dropdown-bottom-text">
                          בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר בחרו
                          מתוך רשימת המתנות המועדפת שלי אז למה אתם מחכים?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="main-choose-event__books-item">
                <div class="single-books-item">
                  <div class="single-books-item__content">
                    <div class="single-books-item__image-ibg">
                      <img
                        src="img/choose-event-type/1.png"
                        class="img-people"
                        alt="1"
                      />
                      <img
                        src="img/no-people-images/choose-event-type/1.png"
                        class="img-no-people"
                        alt="1"
                      />
                    </div>
                    <div class="single-books-item__text-content">
                      <h3 class="single-books-item__title">
                        יום הולדת 12 לשירלי
                      </h3>
                      <p class="single-books-item__date">18/6/20</p>
                      <div class="single-books-item__actions">
                        <button class="button delete">
                          <svg
                            id="Trash_can"
                            data-name="Trash can"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            width="24.153"
                            height="28.357"
                            viewBox="0 0 24.153 28.357"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Прямоугольник_1981"
                                  data-name="Прямоугольник 1981"
                                  width="22.385"
                                  height="19.451"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#716e6e"
                                  stroke-width="1.4"
                                />
                              </clipPath>
                              <clipPath id="clip-path-2">
                                <rect
                                  id="Прямоугольник_1983"
                                  data-name="Прямоугольник 1983"
                                  width="14.146"
                                  height="6.189"
                                  fill="none"
                                  stroke="#716e6e"
                                  stroke-width="1.4"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Прямоугольник_1980"
                              data-name="Прямоугольник 1980"
                              transform="translate(0 5.78)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-width="1.4"
                            >
                              <rect
                                width="24.153"
                                height="3.537"
                                rx="1.768"
                                stroke="none"
                              />
                              <rect
                                x="0.7"
                                y="0.7"
                                width="22.753"
                                height="2.137"
                                rx="1.068"
                                fill="none"
                              />
                            </g>
                            <line
                              id="Линия_491"
                              data-name="Линия 491"
                              x2="1.603"
                              y2="11.543"
                              transform="translate(6.809 11.427)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <line
                              id="Линия_492"
                              data-name="Линия 492"
                              y2="11.543"
                              transform="translate(11.96 11.427)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <line
                              id="Линия_493"
                              data-name="Линия 493"
                              x1="1.483"
                              y2="11.291"
                              transform="translate(15.292 11.412)"
                              fill="none"
                              stroke="#716e6e"
                              stroke-linecap="round"
                              stroke-width="1.4"
                            />
                            <g
                              id="Группа_масок_833"
                              data-name="Группа масок 833"
                              transform="translate(0.884 8.906)"
                              clip-path="url(#clip-path)"
                            >
                              <g
                                id="Контур_8526"
                                data-name="Контур 8526"
                                transform="translate(8.235 -3.346)"
                                fill="none"
                              >
                                <path
                                  d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                  stroke="none"
                                />
                                <path
                                  d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                  stroke="none"
                                  fill="#716e6e"
                                />
                              </g>
                            </g>
                            <g
                              id="Группа_масок_834"
                              data-name="Группа масок 834"
                              transform="translate(5.004 0)"
                              clip-path="url(#clip-path-2)"
                            >
                              <g
                                id="Прямоугольник_1982"
                                data-name="Прямоугольник 1982"
                                transform="translate(0 2.652)"
                                fill="none"
                                stroke="#716e6e"
                                stroke-width="1.4"
                              >
                                <rect
                                  width="13.262"
                                  height="5.305"
                                  rx="2.652"
                                  stroke="none"
                                />
                                <rect
                                  x="0.7"
                                  y="0.7"
                                  width="11.862"
                                  height="3.905"
                                  rx="1.952"
                                  fill="none"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                        <button class="button gift">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.868"
                            height="30.872"
                            viewBox="0 0 27.868 30.872"
                          >
                            <g
                              id="Layer_6"
                              data-name="Layer 6"
                              transform="translate(0.1 0.117)"
                            >
                              <path
                                id="Контур_7573"
                                data-name="Контур 7573"
                                d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                transform="translate(-9.9 -4.909)"
                                fill="#716e6e"
                                stroke="#716e6e"
                                stroke-width="0.2"
                              />
                            </g>
                          </svg>
                        </button>
                        <div class="arrow">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.115"
                            height="17.117"
                            viewBox="0 0 9.115 17.117"
                          >
                            <g
                              id="Сгруппировать_74339"
                              data-name="Сгруппировать 74339"
                              transform="translate(16.411 19.135) rotate(180)"
                            >
                              <g
                                id="Сгруппировать_540"
                                data-name="Сгруппировать 540"
                                transform="translate(14.998 17.723) rotate(180)"
                              >
                                <line
                                  id="Линия_434"
                                  data-name="Линия 434"
                                  y1="7.147"
                                  x2="6.289"
                                  transform="translate(0)"
                                  fill="none"
                                  stroke="#a7a7a7"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                />
                                <path
                                  id="Контур_6897"
                                  data-name="Контур 6897"
                                  d="M0,0,6.289,6.861"
                                  transform="translate(0 7.432)"
                                  fill="none"
                                  stroke="#a7a7a7"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div class="single-books-item__dropdown-body">
                      <div class="single-books-item__dropdown-content">
                        <div class="single-books-item__dropdown-table">
                          <div class="single-books-item__dropdown-table-column">
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">תאריך האירוע</p>
                              <p class="value">18/10/22</p>
                            </div>
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">שעת הגעה</p>
                              <p class="value">17:30</p>
                            </div>
                          </div>
                          <div class="single-books-item__dropdown-table-column">
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">כתובת האירוע</p>
                              <p class="value">כפר יונה</p>
                            </div>
                            <div class="single-books-item__dropdown-table-item">
                              <p class="name">טלפון לתיאומים</p>
                              <p class="value">054 - 88852224</p>
                            </div>
                          </div>
                        </div>
                        <p class="single-books-item__dropdown-bottom-text">
                          בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר בחרו
                          מתוך רשימת המתנות המועדפת שלי אז למה אתם מחכים?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}
