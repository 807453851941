import React from 'react'

export default function TopBar({imgUrl,text,icon}) {
  return (
    <section className="choose-event__topbar topbar">
    <div className="topbar__bg-img-ibg">
      <img src={imgUrl} alt="topbar" />
    </div>
    <div className="topbar__container">
      <div className="topbar__title-wrapper">
        <h1 className="topbar__title">{text}
          <span className="icon">
            <img src={icon} alt="globe" />
          </span>
        </h1>
      </div>
    </div>
  </section>
  )
}
