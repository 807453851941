import React, { useEffect, useState } from "react";
import {
  eventTypeImg1,
  eventTypeImgNoPeople1,
  giftBasketTopbar,
  topbarBgGreen,
} from "../Assets/images";
import { NavLink, useNavigate } from "react-router-dom";

function SendPresent() {
  const eventsList = [
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת 12",
      titleHighlited: "לישראלה ישראלי",
      date: "5/7/22",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת 12",
      titleHighlited: "לישראלה ישראלי",
      date: "5/7/22",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת 12",
      titleHighlited: "לישראלה ישראלי",
      date: "5/7/22",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  return (
    <main className="event-add-presents">
      <section className="event-add-presents__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src={topbarBgGreen} alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              בחירת מתנות
              <span className="icon">
                <img src={giftBasketTopbar} alt="gift basket topbar" />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <div className="event-add-presents__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>send present</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="event-add-presents__main main-event-add-presents">
        <div className="main-event-add-presents__container">
          <h2 className="main-event-add-presents__title">
            ?לאיזה אירוע את/ה רוצה לבחור מתנות
          </h2>
          <ul className="main-event-add-presents__list">
            {eventsList.map((event, index) => (
              <li key={index} className="main-event-add-presents__item">
                <div className="main-event-add-presents__item-content single-event-presents">
                  <div className="single-event-presents__body">
                    <div className="single-event-presents__image-ibg">
                      <img src={event.img} className="img-people" alt={1} />
                      <img
                        src={event.imgNoPeople}
                        className="img-no-people"
                        alt={1}
                      />
                    </div>
                    <div className="single-event-presents__text-content">
                      <h3 className="single-event-presents__title">
                        {event.title} <span>{event.titleHighlited}</span>
                      </h3>
                      <p className="single-event-presents__date">
                        {event.date}
                      </p>
                      <div className="single-event-presents__btn-container">
                        <NavLink
                          to="/choose-present-or-send-money"
                          className="btn"
                        >
                          בחר/י מתנות
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </main>
  );
}

export default SendPresent;
