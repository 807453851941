import React, { useEffect } from "react";

const PreventFrame = () => {


useEffect(()=>{
    window.parent.location="/successful-payment-referal"
},[])
    return (
        <>
        
        </>
    );
}

export default PreventFrame;
