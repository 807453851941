import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_DOMAIN } from "../Libs/config";

function TransactionItem({
  data: {
    amount,
    buyerName,
    blessingTitle,
    blesingText,
    giftGiverUserId,
    wishlistId,
    image,
    productName,
    price,
    status,
    wishListItemId,
    isRemovable,
  },
  currUserObject,
  currWishlist,
  setCurrWishlist,
}) {

  // Fetch the user data based on giftGiverUserId



  return (
    <div className="my-purchase-single">
      <div className="my-purchase-single__content">
      
        <div className="my-purchase-single__text-content">
          <h2 className="my-purchase-single__name">{buyerName || ""}</h2>
          <p style={{marginRight: '3.5rem'}} className="my-purchase-single__price">₪{amount}</p>
          <p style={{ marginRight: '3.5rem' }} className="my-purchase-single__price">
  {blessingTitle} {blesingText}
</p>

          <div className="my-purchase-single__actions">
            {isRemovable && (
              <button className="button delete"
              // onClick={() => deleteProduct()}
              >
                {/* Your delete SVG here */}
              </button>
            )}

            <div
              className={`status ${
                status === "buy by me"
                  ? "red"
                  : status === "buy by buyer"
                  ? "green"
                  : status === "not buy"
                  ? "gray"
                  : ""
              }`}
            >
              <p>
                {status === "buy by me"
                  ? "רכשתי"
                  : status === "buy by buyer"
                  ? "חבר רכש"
                  : status === "not buy"
                  ? "טרם נרכש"
                  : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionItem;
