import React, { useEffect, useState } from "react";
import CategorySingleCard from "../Components/CategorySingleCard";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom/dist";
import { API_DOMAIN_IMG } from "../Libs/config";
import { API_DOMAIN } from "../Libs/config";
import CategoryNewEventSingleCard from "../Components/CategoryNewEventSingleCard";

export default function CategoryNewEvent({
  allCategoriesList,
  newEvent,
  filterToggle,
  filterObject,
  setFilterObject,
  eventType,
  setEventType,
}) {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [manufacturers, setManufacturers] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsTemp, setProductsTemp] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  // console.log("<Category categories", categories);
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const additionalGift = Boolean(URLParams?.get("additionalGift"));
  // console.log('manufacturers_productsAll', productsAll)
  const { categoryId } = useParams();

  useEffect(() => {
    if (productsAll.length) {
      const manufacturersArr = productsAll.reduce((result, current) => {
        const { manufacturer } = current;
        const isDuplicate = result.some(
          (resultManufacturer) => resultManufacturer === manufacturer,
        );

        if (!isDuplicate && manufacturer !== "") {
          result.push(manufacturer);
        }

        return result;
      }, []);
      // console.log('manufacturersArr:',manufacturersArr)
      setManufacturers(manufacturersArr);
      setFilterObject((prevObj) => {
        const newObj = {
          ...prevObj,
          manufacturers: manufacturersArr,
          lastChangedType: "",
        };
        return newObj;
      });
    }
  }, [productsAll]);

  useEffect(() => {
    // console.log('filterObject.choosedCategoriesList',filterObject.choosedCategoriesList)
    getProductsByCategoriesList(filterObject.choosedCategoriesList);
    setFilterObject((prevObj) => {
      const newObj = { ...prevObj, manufacturers: [], lastChangedType: "" };
      return newObj;
    });
    document.querySelectorAll(".manufacturer-check").forEach((item) => {
      if (item.checked) item.checked = false;
    });
    return () => {};
  }, []);

  useEffect(() => {
    // console.log('filterObject:', filterObject);
    if (filterObject.lastChangedType === "category") {
      getProductsByCategoriesList(filterObject.choosedCategoriesList);
      setFilterObject((prevObj) => {
        // const newObj = { ...prevObj, manufacturers: [], lastChangedType: "" };
        const newObj = { ...prevObj, manufacturers: [], lastChangedType: "" };
        return newObj;
      });
      document.querySelectorAll(".manufacturer-check").forEach((item) => {
        if (item.checked) item.checked = false;
      });
    } else if (filterObject.lastChangedType !== "") {
      handleFilterProducts(productsAll);
    }
  }, [filterObject]);

  const handleFilterChecks = (
    e,
    choosedCategory = null,
    manufacturer = null,
  ) => {
    if (e.target.id.includes("category-check")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [
              ...prevObj.choosedCategoriesList,
              choosedCategory,
            ],
            lastChangedType: "category",
          };
          return newObj;
        });

        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`,
        );
        if (headerCheck) {
          headerCheck.checked = true;
        }
      } else {
        let newCategoriesList = [...filterObject.choosedCategoriesList];
        filterObject.choosedCategoriesList.filter((item) => {
          if (
            item.categoryId === choosedCategory.categoryId &&
            item.productsId === choosedCategory.productsId
          ) {
            newCategoriesList.splice(newCategoriesList.indexOf(item), 1);
          }
          return newCategoriesList;
        });

        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [...newCategoriesList],
            lastChangedType: "category",
          };
          return newObj;
        });

        // getProductsByCategoriesList(prevCategoriesList);
        // setChoosedCategoriesList(prevCategoriesList);
        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`,
        );
        if (headerCheck) {
          headerCheck.checked = false;
        }
      }
    } else if (e.target.id.includes("manufacturer_")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          if (!prevObj.manufacturersList.includes(manufacturer)) {
            // Convert the manufacturersList to a Set to remove duplicates
            const uniqueManufacturers = new Set(prevObj.manufacturersList);

            // Add the new manufacturer to the Set by creating a new Set
            const updatedManufacturersSet = new Set([
              ...uniqueManufacturers,
              manufacturer,
            ]);

            // Convert the Set back to an array
            const updatedManufacturersList = [...updatedManufacturersSet];

            const newObj = {
              ...prevObj,
              manufacturersList: updatedManufacturersList,
              priceRange: null,
              lastChangedType: "manufacturer",
            };
            return newObj;
          }
          return prevObj;
        });
      } else {
        setFilterObject((prevObj) => {
          let newArr = [...prevObj?.manufacturersList];
          newArr = newArr.filter((item) => item !== manufacturer);
          console.log(newArr);
          const newObj = {
            ...prevObj,
            manufacturersList: newArr,
            priceRange: null,
            lastChangedType: "manufacturer",
          };
          return newObj;
        });
      }
    }
  };

  const handleFilterProducts = (array) => {
    setProducts(() => {
      const newArr = array.filter((product) => {
        if (!filterObject.manufacturersList.length) {
          if (!filterObject.priceRange) {
            return productsAll;
          } else {
            return (
              product.price >= filterObject.priceRange[0] &&
              product.price <= filterObject.priceRange[1]
            );
          }
        }
        if (!filterObject.priceRange) {
          return filterObject.manufacturersList.includes(product.manufacturer);
        } else {
          return (
            filterObject.manufacturersList.includes(product.manufacturer) &&
            product.price >= filterObject.priceRange[0] &&
            product.price <= filterObject.priceRange[1]
          );
        }
      });

      return newArr;
    });
  };

  const getProductsByCategoriesList = (idList) => {
    if (idList.length) {
      setProducts([]);
      setProductsAll([]);
      let finalArray = [];
      let i = 0;
      idList.forEach((item) => {
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${item.productsId}`,
          )
          .then((response) => {
            // console.log(response.data.result.products);
            const allProducts = response.data.result.products;
            finalArray = [...finalArray, ...allProducts];

            i++;
            if (i === idList.length) {
              // console.log(finalArray);
              setProducts(finalArray);
              setProductsAll(finalArray);
            }
          })
          .catch((error) => {
            console.error(error);
            i++;
            alert("Error, please try again");
          });
      });
    }
  };

  // console.log('allCategoriesList__: ', allCategoriesList)
  useEffect(() => {
    if (allCategoriesList) {
      setCategories(() => {
        const currentMainCategory = allCategoriesList.find(
          (category) => category.mainCategory.id === Number(categoryId),
        );

        return currentMainCategory?.productCategory;
      });
    }
  }, [allCategoriesList]);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    const isCatNewEvent = window.location.href.includes("category-new-event");
    if (!currentUser) {
      if (!isCatNewEvent) {
        // navigate("/credentials");
      }
    } else {
      // axios
      //   .get(
      //     `${API_DOMAIN}/api/ProductCategory/GetCategories`
      //   )
      //   .then((response) => {
      //     const getCategories = response.data.result.categories;
      //     const resultCategories = singleCategories.filter((obj) =>
      //       getCategories.some((item) => item.id === obj.id)
      //     );
      //     setCategories(resultCategories);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     alert("Error, please try again");
      //   });
    }
    if (!eventType) {
      const localEventType = localStorage.getItem("eventType");
      if (localEventType)
        setEventType(JSON.parse(localEventType));
    }
  }, []);

  return (
    <main className="category category-page">
      <section
        className="category__topbar topbar topbar--small-img"
      >
        <div className="topbar__container">
          <div className="topbar__title-wrapper" style={{position: "relative"}}>
            {/* <h1 className="topbar__title">מתנות מומלצות ליום הולדת לנשים</h1> */}
            <div className="topbar__bg-img-ibg topbar__bg-img-ibg--with-shadows topbar__bg-img-ibg--small">
              {/* <img
            src="../img/common/topbar-category.png"
            alt="topbar"
            className="img-people"
          />
          <img
            src="../img/no-people-images/common/topbar-category.png"
            alt="topbar"
            className="img-no-people"
          /> */}
              <img
                src={
                `${API_DOMAIN_IMG}${eventType?.categoryImage}`}
                alt={eventType?.categoryName}
                className="img-people"
              />
            </div>
            <h1 className="topbar__title">מתנות מומלצות ל{eventType?.categoryName}</h1>
          </div>
        </div>
      </section>

      <div className="category__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink to="/my-events">האירועים שלי</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink
                to={
                  newEvent
                    ? `/all-categories-new-events?wishlistId=${currWishlistId}`
                    : `/all-categories?wishlistId=${currWishlistId}`
                }
              >
                קטגוריות
              </NavLink>
            </li>
            {/*
            <li className="breadcrumbs__item">
              <p>קטגורית מוצרים</p>
            </li>
              */}
          </ul>
        </div>
      </div>

      <section className="category__main main-category">
        <div className="main-category__container">
          {/* <div className="main-category__filter-btn-container filter-btn-container">
            <button type="button" className="filter-btn">
              <img src="../img/svg/filter-icon.png" alt="filter icon" />
            </button>
          </div> */}

          <div className="main-category__steps-numbers numbers-steps step-2">
            <ul className="numbers-steps__list">
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">1</div>
              </li>
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">2</div>
              </li>
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">3</div>
              </li>
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">4</div>
              </li>
            </ul>
          </div>

          <h2 className="main-category__title">
            להתחיל לבחור את המתנות המושלמות למסיבה שלך
          </h2>

          <ul className="main-category__list">
            {/* {singleCategories &&
              singleCategories.map((category) => (
                <CategorySingleCard data={category} key={category.id} />
              ))} */}
            {categories &&
              categories.map((category) => (
                <CategoryNewEventSingleCard
                  data={category}
                  key={category.id}
                  mainCategoryId={categoryId}
                  currWishlistId={currWishlistId}
                  newEvent={newEvent}
                  allCategoriesList={allCategoriesList}
                  manufacturers={manufacturers}
                  filterFunction={handleFilterChecks}
                  setFilterObject={setFilterObject}
                  filterObject={filterObject}
                />
              ))}
          </ul>
        </div>
      </section>
    </main>
  );
}
