import React, { useEffect } from "react";
import CategoryCard from "../Components/CategoryCard";
import { NavLink, useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { useState } from "react";

export default function AllCategories({ newEvent, allCategoriesList, setAllCategoriesList, setShowCategMenuItem }) {
  const navigate = useNavigate();

  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    } else {
    }
  }, []);

  useEffect(() => {
    if (!setShowCategMenuItem) return
    setShowCategMenuItem(true)
    // return (
    //   // setShowCategMenuItem(false)
    // )
  }, []);

  return (
    <main className="all-categories">
      <section className="all-categories__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src="img/common/topbar-bg.png" alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              אירוע חדש
              <span className="icon">
                <img src="img/svg/party-icon.svg" alt="party icon" />
              </span>
            </h1>
          </div>
        </div>
      </section>
      {/* <section className="category__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img
            src="../img/common/topbar-category.png"
            alt="topbar"
            className="img-people"
          />
          <img
            src="../img/no-people-images/common/topbar-category.png"
            alt="topbar"
            className="img-no-people"
          />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">מתנות מומלצות ליום הולדת לנשים</h1>
          </div>
        </div>
      </section> */}

      <div className="all-categories__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink to="/my-events">my events</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>categories</p>
            </li>
          </ul>
        </div>
      </div>

      <section className="all-categories__main main-all-categories">
        <div className="main-all-categories__container">
          <div className="main-all-categories__body">
            <div className="main-all-categories__steps-numbers numbers-steps step-1">
              <ul className="numbers-steps__list">
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">1</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">2</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">3</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">4</div>
                </li>
              </ul>
            </div>
            <div className="main-all-categories__titles-block">
              <h2 className="main-all-categories__title">למימוש הכסף למתנה</h2>
              <h3 className="main-all-categories__subtitle">
                בחר קטגוריה והזמן מתנה לכל מי שתרצה
              </h3>
            </div>

            <ul className="main-all-categories__list">
              {allCategoriesList &&
                allCategoriesList.map((category) => (
                  <CategoryCard
                    newEvent={newEvent}
                    key={category.mainCategory?.id}
                    data={category.mainCategory}
                    wishlistId={currWishlistId}
                  />
                ))}
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}
