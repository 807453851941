import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function MobileFooter() {
    return(
    <>
      <div className="form-credentials-section__buttons-container mobile">
        <button
          type="submit"
          className="btn footer-mobile"
          style={{ justifyContent: "space-between", display: "flex", overflow: "hidden", padding:"7px"}}    
        
        >
          <div style={{ flex: 1}}>
            <NavLink
              to="/"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.516"
                  height="25.445"
                  viewBox="0 0 25.516 25.445"
                >
                  <path
                    id="home_1_"
                    data-name="home (1)"
                    d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                    transform="translate(0.771 0.601)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>
              <p className="text1 invited">ראשי</p>
            </NavLink>
          </div>
          <div style={{ flex: 1 }}>
          <NavLink to="/contact-us" >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={27}
                height={26}
                viewBox="0 0 27 26"
              >
                <g
                  id="Сгруппировать_1499"
                  data-name="Сгруппировать 1499"
                  transform="translate(1177 -2115)"
                >
                  <g
                    id="Эллипс_411"
                    data-name="Эллипс 411"
                    transform="translate(-1177 2115)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_412"
                    data-name="Эллипс 412"
                    transform="translate(-1159 2122)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_413"
                    data-name="Эллипс 413"
                    transform="translate(-1175 2132)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <line
                    id="Линия_524"
                    data-name="Линия 524"
                    x2={10}
                    y2={4}
                    transform="translate(-1168.5 2120.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                  <line
                    id="Линия_525"
                    data-name="Линия 525"
                    y1={6}
                    x2={9}
                    transform="translate(-1166.5 2128.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </span>
            <p className="text1 invited">צרו קשר</p>
          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
            to="/my-bank"
          >
            <span className="icon">
            <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19.846"
                                  height="24.798"
                                  viewBox="0 0 19.846 24.798"
                                >
                                  <g
                                    id="Сгруппировать_74057"
                                    data-name="Сгруппировать 74057"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Artboard_2"
                                      data-name="Artboard 2"
                                      d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                                      transform="translate(-74.992 -18.61)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="heart"
                                      d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                                      transform="translate(-25.26 -15.21)"
                                      fill="#ec1b53"
                                    />
                                  </g>
                                </svg>
            </span>
            <p className="text1 invited">רכישת מתנות</p>

          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
          //  onClick={() => goToCartByWishlist1()}
          >
            <span className="icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 43.485 43.268"
              >
                <g
                  id="Artboard_3"
                  data-name="Artboard 3"
                  transform="translate(0.699 0.5)"
                >
                  <g id="New_Symbol_2" transform="translate(-44.16 -36.43)">
                    <g
                      id="Сгруппировать_74376"
                      data-name="Сгруппировать 74376"
                      transform="translate(44.16 36.43)"
                    >
                      <path
                        id="Контур_39505"
                        data-name="Контур 39505"
                        d="M238.65,72.914c0-2.767,1.327-4.6,2.2-4.6"
                        transform="translate(-214.206 -64.303)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39506"
                        data-name="Контур 39506"
                        d="M132.134,99.84l1.077,2.182,2.407.349-1.742,1.7.411,2.4-2.153-1.131-2.153,1.131.411-2.4-1.742-1.7,2.407-.349Z"
                        transform="translate(-118.031 -91.871)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39507"
                        data-name="Контур 39507"
                        d="M157,236.993l-.256-1.5,1.741-1.7-2.407-.349L155,231.27l-1.077,2.182-2.407.349.814.794"
                        transform="translate(-138.027 -206.782)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <g
                        id="Сгруппировать_74372"
                        data-name="Сгруппировать 74372"
                        transform="translate(14.103 0)"
                      >
                        <line
                          id="Линия_588"
                          data-name="Линия 588"
                          y2="1.171"
                          transform="translate(2.164)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_589"
                          data-name="Линия 589"
                          x1="1.173"
                          transform="translate(3.016 2.164)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_590"
                          data-name="Линия 590"
                          y1="1.171"
                          transform="translate(2.025 3.016)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_591"
                          data-name="Линия 591"
                          x2="1.171"
                          transform="translate(0 2.023)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                      </g>
                      <g
                        id="Сгруппировать_74373"
                        data-name="Сгруппировать 74373"
                        transform="translate(1.772 9.774)"
                      >
                        <line
                          id="Линия_592"
                          data-name="Линия 592"
                          y2="1.171"
                          transform="translate(2.164)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_593"
                          data-name="Линия 593"
                          x1="1.171"
                          transform="translate(3.016 2.164)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_594"
                          data-name="Линия 594"
                          y1="1.173"
                          transform="translate(2.023 3.016)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_595"
                          data-name="Линия 595"
                          x2="1.171"
                          transform="translate(0 2.025)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                      </g>
                      <path
                        id="Контур_39508"
                        data-name="Контур 39508"
                        d="M319.912,137.721s.752-1.44,2.03-.989,1.469,3.2-3.135,4.119c-3.011-3.6-1.136-5.625.143-5.174S319.912,137.721,319.912,137.721Z"
                        transform="translate(-282.873 -123.148)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <line
                        id="Линия_596"
                        data-name="Линия 596"
                        x1="7.568"
                        transform="translate(0 20.457)"
                        fill="none"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        strokeDasharray="0 0 10.66 21.31"
                      />
                      <path
                        id="Контур_39509"
                        data-name="Контур 39509"
                        d="M90.445,229.19,70.494,239.448,50.21,229.14"
                        transform="translate(-49.45 -204.92)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        strokeDasharray="0 2 0 2"
                      />
                      <path
                        id="Контур_39510"
                        data-name="Контур 39510"
                        d="M99.138,57.83A1.339,1.339,0,1,0,97.8,59.17,1.34,1.34,0,0,0,99.138,57.83Z"
                        transform="translate(-89.887 -53.969)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39511"
                        data-name="Контур 39511"
                        d="M226.479,232.92a1.34,1.34,0,1,0-1.34,1.34A1.34,1.34,0,0,0,226.479,232.92Z"
                        transform="translate(-201.223 -207.053)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <g
                        id="Сгруппировать_74375"
                        data-name="Сгруппировать 74375"
                        transform="translate(0 4.215)"
                      >
                        <g
                          id="Сгруппировать_74374"
                          data-name="Сгруппировать 74374"
                          transform="translate(34.105)"
                        >
                          <line
                            id="Линия_597"
                            data-name="Линия 597"
                            y2="1.171"
                            transform="translate(2.164)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                          <line
                            id="Линия_598"
                            data-name="Линия 598"
                            x1="1.171"
                            transform="translate(3.018 2.164)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                          <line
                            id="Линия_599"
                            data-name="Линия 599"
                            y1="1.171"
                            transform="translate(2.025 3.016)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                          <line
                            id="Линия_600"
                            data-name="Линия 600"
                            x2="1.173"
                            transform="translate(0 2.023)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                        </g>
                        <path
                          id="Контур_39512"
                          data-name="Контур 39512"
                          d="M137.15,199.2h30.4"
                          transform="translate(-125.463 -182.958)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          strokeDasharray="0 0 10.66 21.31"
                        />
                        <line
                          id="Линия_601"
                          data-name="Линия 601"
                          x1="7.733"
                          y1="3.975"
                          transform="translate(34.355 33.904)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_602"
                          data-name="Линия 602"
                          y1="3.975"
                          x2="7.732"
                          transform="translate(0 33.904)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39513"
                          data-name="Контур 39513"
                          d="M86.248,199.2,65.2,210.019,44.16,199.2"
                          transform="translate(-44.16 -182.958)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_603"
                          data-name="Линия 603"
                          x1="1.242"
                          y1="0.907"
                          transform="translate(31.582 8.575)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_604"
                          data-name="Линия 604"
                          x1="2.771"
                          y1="2.022"
                          transform="translate(39.317 14.22)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_605"
                          data-name="Линия 605"
                          x1="10.507"
                          y2="7.667"
                          transform="translate(0 8.575)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39514"
                          data-name="Контур 39514"
                          d="M173.59,86.356l2.7-2a3.519,3.519,0,0,1,3.806-.22"
                          transform="translate(-157.323 -81.96)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39515"
                          data-name="Контур 39515"
                          d="M286.8,170.52a12.516,12.516,0,0,0-3.539,7.635"
                          transform="translate(-253.21 -157.883)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39516"
                          data-name="Контур 39516"
                          d="M252.687,174.05c0,.948-1.067.553-1.067,1.5s1.147.948,1.147,1.9-1.147.949-1.147,1.9,1.147.949,1.147,1.9"
                          transform="translate(-225.546 -160.969)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39517"
                          data-name="Контур 39517"
                          d="M184.09,141.44s3.127,1.274,2.874,8.78"
                          transform="translate(-166.503 -132.458)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39518"
                          data-name="Контур 39518"
                          d="M145.5,211.3s.757.255.757,2.648"
                          transform="translate(-132.764 -193.538)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39519"
                          data-name="Контур 39519"
                          d="M44.16,199.2v21.636H86.248V199.2"
                          transform="translate(-44.16 -182.958)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                      </g>
                      <path
                        id="Контур_39520"
                        data-name="Контур 39520"
                        d="M145.5,328.98c1.443,0,1.443,1.913,2.886,1.913s1.443-1.913,2.884-1.913,1.443,1.913,2.886,1.913,1.443-1.913,2.886-1.913,1.443,1.913,2.886,1.913,1.443-1.913,2.886-1.913"
                        transform="translate(-132.764 -292.212)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39521"
                        data-name="Контур 39521"
                        d="M101.415,172.482s-.185-1.59,1.107-1.929,2.97,1.8-.274,5.09c-4.447-1.268-4.039-3.954-2.747-4.295S101.415,172.482,101.415,172.482Z"
                        transform="translate(-91.782 -153.665)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39522"
                        data-name="Контур 39522"
                        d="M245.882,156.71l1.482,2.179H244.4Z"
                        transform="translate(-219.234 -141.593)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39523"
                        data-name="Контур 39523"
                        d="M231.657,59.607c0-3.6-1.844-6.517-4.119-6.517s-4.119,2.917-4.119,6.517,1.844,6.517,4.119,6.517S231.657,63.2,231.657,59.607Z"
                        transform="translate(-200.89 -50.996)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <p className="text1 invited">אירועים שהוזמנתי</p>
          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
            to="/my-events"
          //  onClick={() => setMyEventsActiveTab(0)}
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29.959"
                height="22.842"
                viewBox="0 0 29.959 22.842"
              >
                <g
                  id="Сгруппировать_74063"
                  data-name="Сгруппировать 74063"
                  transform="translate(-14 0.5)"
                >
                  <g
                    id="Artboard_3"
                    data-name="Artboard 3"
                    transform="translate(22.214 0)"
                  >
                    <path
                      id="Контур_38424"
                      data-name="Контур 38424"
                      d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                      transform="translate(-24.307 -20.873)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38425"
                      data-name="Контур 38425"
                      d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                      transform="translate(-20.77 -14.613)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38426"
                      data-name="Контур 38426"
                      d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                      transform="translate(-14.469 -14.389)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_526"
                      data-name="Линия 526"
                      y2="4.793"
                      transform="translate(9.839 13.419)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_527"
                      data-name="Линия 527"
                      y2="8.147"
                      transform="translate(7.443 11.023)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_528"
                      data-name="Линия 528"
                      y2="11.684"
                      transform="translate(5.047 8.445)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_529"
                      data-name="Линия 529"
                      y2="7.055"
                      transform="translate(2.651 14.033)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <circle
                      id="Эллипс_414"
                      data-name="Эллипс 414"
                      cx="1.438"
                      cy="1.438"
                      r="1.438"
                      transform="translate(10.319)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38427"
                      data-name="Контур 38427"
                      d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                      transform="translate(-27.244 -17.922)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38428"
                      data-name="Контур 38428"
                      d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                      transform="translate(-31.988 -10.775)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38429"
                      data-name="Контур 38429"
                      d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                      transform="translate(-20.474 -9.487)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38430"
                      data-name="Контур 38430"
                      d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                      transform="translate(-28.181 -7.104)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                  <g
                    id="Сгруппировать_1710"
                    data-name="Сгруппировать 1710"
                    transform="translate(14 2.322)"
                  >
                    <g
                      id="Контур_10101"
                      data-name="Контур 10101"
                      transform="translate(0 1.369)"
                      fill="#3c9e1c"
                    >
                      <path
                        d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                        stroke="none"
                      />
                      <path
                        d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                        stroke="none"
                        fill="#fff"
                      />
                    </g>
                    <text
                      id="_"
                      data-name="+"
                      transform="translate(6.845 12.059)"
                      fill="#fff"
                      fontSize="11"
                      fontFamily="Assistant-SemiBold, Assistant"
                      fontWeight="600"
                    >
                      <tspan
                        x="2.783"
                        y="0"
                      >
                        +
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
            </span>
            <p className="text1 invited">אירוע חדש</p>


          </NavLink>
          </div>

        </button>
      </div>
    </>)

}
export default  MobileFooter;