import React, { useEffect, useRef, useState } from "react";
import { greenPopupBg } from "../Assets/images";
import alreadyBought from '../Assets/img/popups/already-bought/1.png'
import { API_DOMAIN_IMG } from "../Libs/config";

function WhoAlsoBoughtPopup({ whoAlsoBought, togglePopup }) {


  console.log('whoAlsoBought', whoAlsoBought)

  return (

    <div
      id="already-bought-popup"
      aria-hidden="true"
      className="popup popup_show"
      data-fullscreen=""
    >
      <div className="popup__wrapper">
        <div className="popup__content">
          <button
            data-close=""
            type="button"
            className="popup__close already-bought"
            onClick={() => togglePopup()}
          />
          <div className="popup__body">
            <h1 className="popup__title">מי כבר רכש מתנה</h1>
            <ul className="popup__list">

              {whoAlsoBought.map((person, index) => (
                <li key={index} className="popup__list-item">
                  <div className="avatar">
                    <img src={person.userPic ? API_DOMAIN_IMG + "/server-images/" + person.userPic :  alreadyBought } alt="" />
                  </div>
                  <p className="text name">{person.fullName}</p>
                  <p className="text product">{person.productName} </p>
                  {/*<label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>*/}
                </li>
              ))}

            </ul>
          </div>
        </div>
      </div>
    </div>

  );
}

export default WhoAlsoBoughtPopup;
