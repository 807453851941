import React, { useEffect } from "react";
import CategoryCard from "../Components/CategoryCard";
import { NavLink, useLinkClickHandler, useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { useState } from "react";
import MyBankCategoryCard from "../Components/MyBank/MyBankCategoryCard";
import TopBarIMg from '../Assets/img/common/topbar-bg-green.png'
import PartyIcon from '../Assets/img/svg/party-icon.svg'
import TopBar from "../Components/TopBar";



export default function MyBankAllCategories({
  newEvent,
  currentWishListCategories,
  setShowCategMenuItem,
  setFilterObject,
  eventType,
  setEventType,
  allCategoriesList,
  setProductsFilterObject,
  productsFilterObject,
  currWishlist }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const [selectedCategoriesMobile, setSelectedCategoriesMobile] = useState([])

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    } else {
    }
  }, []);
  const navigateToMatchProducts = (e) => {
    const { target } = e;
     if (target.closest(".single-category-type__footer")) return;
     const urlCategories = selectedCategoriesMobile.length
       ? selectedCategoriesMobile
       : allCategoriesList.map((item) => item?.id);
     
       navigate(
         `/my-bank-products/1/
         ${urlCategories.length
           ? `&category=${urlCategories.join()}`
           : ""
         }`
       );
  
   };


  useEffect(() => {
    let allIds = allCategoriesList?.map(obj => obj?.productCategory?.map(item => item.id)).flat();
    setSelectedCategoriesMobile(allIds);
  },[allCategoriesList])
  // useEffect(() => {
  //   localStorage.removeItem('filterObject')
  //   setFilterObject({
  //     choosedCategoriesList: [],
  //     manufacturersList: [],
  //     priceRange: null,
  //     lastChangedType: "",
  //   })

  // }, []);

  return (
    <main
    
      data-fullscreen
    >

             <section className="topbar my_bank_all_categories">
     
        <div className="topbar__bg-img-ibg">
          <img src="../../img/common/topbar-bg-green.png" alt="topbar" />
        </div>
        <div className="topbar__container">
                      <h1 className="topbar__title all__categories">
                        הקופה שלי
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="156.143"
                            height="156.143"
                            viewBox="0 0 156.143 156.143"
                          >
                            <g
                              id="a"
                              transform="translate(-51.41 -86.97)"
                            >
                              <g
                                id="b"
                                transform="translate(52.91 88.559)"
                              >
                                <circle
                                  id="Эллипс_423"
                                  data-name="Эллипс 423"
                                  cx="49.772"
                                  cy="49.772"
                                  r="49.772"
                                  transform="translate(26.8 26.711)"
                                  fill="#fffcef"
                                  opacity="0.1"
                                />
                                <circle
                                  id="Эллипс_424"
                                  data-name="Эллипс 424"
                                  cx="9.189"
                                  cy="9.189"
                                  r="9.189"
                                  transform="translate(13.904 120.773)"
                                  fill="#3c9e1c"
                                  opacity="0.7"
                                />
                                <circle
                                  id="Эллипс_425"
                                  data-name="Эллипс 425"
                                  cx="15.314"
                                  cy="15.314"
                                  r="15.314"
                                  transform="translate(114.736 7.69)"
                                  fill="#fff"
                                  opacity="0.4"
                                />
                                <circle
                                  id="Эллипс_426"
                                  data-name="Эллипс 426"
                                  cx="76.5"
                                  cy="76.5"
                                  r="76.5"
                                  transform="translate(0 0.054)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="3"
                                  strokeDasharray="5 5"
                                />
                              </g>
                              <g
                                id="Сгруппировать_1514"
                                data-name="Сгруппировать 1514"
                                transform="translate(52.91 88.47)"
                              >
                                <path
                                  id="Контур_38461"
                                  data-name="Контур 38461"
                                  d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                                  transform="translate(-52.91 -88.47)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="3"
                                  strokeDasharray="0 20"
                                  fillRule="evenodd"
                                  opacity="0"
                                />
                                <line
                                  id="Линия_534"
                                  data-name="Линия 534"
                                  y2="55.91"
                                  transform="translate(33.112 51.048)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38462"
                                  data-name="Контур 38462"
                                  d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                                  transform="translate(-175.548 -376.237)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="1905"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 710 60 197.5 0"
                                />
                                <path
                                  id="Контур_38463"
                                  data-name="Контур 38463"
                                  d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                                  transform="translate(-175.548 -265.559)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38464"
                                  data-name="Контур 38464"
                                  d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                                  transform="translate(-179.241 -243.422)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="950"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 877.5 40 0 60"
                                />
                                <path
                                  id="Контур_38465"
                                  data-name="Контур 38465"
                                  d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                                  transform="translate(-175.548 -221.285)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="1077.5"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 78 467 0 560"
                                />
                                <path
                                  id="Контур_38466"
                                  data-name="Контур 38466"
                                  d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                                  transform="translate(-364.492 -307.186)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="497.5"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 438.76 40 0 60"
                                />
                                <path
                                  id="Контур_38467"
                                  data-name="Контур 38467"
                                  d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                                  transform="translate(-153.411 -413.133)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38468"
                                  data-name="Контур 38468"
                                  d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                                  transform="translate(-153.411 -221.285)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38469"
                                  data-name="Контур 38469"
                                  d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                                  transform="translate(-416.145 -309.834)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38470"
                                  data-name="Контур 38470"
                                  d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                                  transform="translate(-416.145 -295.076)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38471"
                                  data-name="Контур 38471"
                                  d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                                  transform="translate(-381.258 -343.036)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38472"
                                  data-name="Контур 38472"
                                  d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                                  transform="translate(-178.061 -174.79)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="851.26"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                                />
                                <line
                                  id="Линия_535"
                                  data-name="Линия 535"
                                  x2="46.186"
                                  transform="translate(53.479 104.526)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 0 40 628.76 560"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </h1>
                    </div>
      </section>
      {/* <main className="all-categories">*/}
  {/*<section className="my-bank__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img
            src={TopBarIMg}
            alt="topbar"
          />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              הקופה שלי
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="156.143"
                  height="156.143"
                  viewBox="0 0 156.143 156.143"
                >
                  <g
                    id="a"
                    transform="translate(-51.41 -86.97)"
                  >
                    <g
                      id="b"
                      transform="translate(52.91 88.559)"
                    >
                      <circle
                        id="Эллипс_423"
                        data-name="Эллипс 423"
                        cx="49.772"
                        cy="49.772"
                        r="49.772"
                        transform="translate(26.8 26.711)"
                        fill="#fffcef"
                        opacity="0.1"
                      />
                      <circle
                        id="Эллипс_424"
                        data-name="Эллипс 424"
                        cx="9.189"
                        cy="9.189"
                        r="9.189"
                        transform="translate(13.904 120.773)"
                        fill="#3c9e1c"
                        opacity="0.7"
                      />
                      <circle
                        id="Эллипс_425"
                        data-name="Эллипс 425"
                        cx="15.314"
                        cy="15.314"
                        r="15.314"
                        transform="translate(114.736 7.69)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <circle
                        id="Эллипс_426"
                        data-name="Эллипс 426"
                        cx="76.5"
                        cy="76.5"
                        r="76.5"
                        transform="translate(0 0.054)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                        strokeDasharray="5 5"
                      />
                    </g>
                    <g
                      id="Сгруппировать_1514"
                      data-name="Сгруппировать 1514"
                      transform="translate(52.91 88.47)"
                    >
                      <path
                        id="Контур_38461"
                        data-name="Контур 38461"
                        d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                        transform="translate(-52.91 -88.47)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                        strokeDasharray="0 20"
                        fillRule="evenodd"
                        opacity="0"
                      />
                      <line
                        id="Линия_534"
                        data-name="Линия 534"
                        y2="55.91"
                        transform="translate(33.112 51.048)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38462"
                        data-name="Контур 38462"
                        d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                        transform="translate(-175.548 -376.237)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDashoffset="1905"
                        strokeWidth="3"
                        strokeDasharray="0 0 0 0 710 60 197.5 0"
                      />
                      <path
                        id="Контур_38463"
                        data-name="Контур 38463"
                        d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                        transform="translate(-175.548 -265.559)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38464"
                        data-name="Контур 38464"
                        d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                        transform="translate(-179.241 -243.422)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDashoffset="950"
                        strokeWidth="3"
                        strokeDasharray="0 0 0 0 877.5 40 0 60"
                      />
                      <path
                        id="Контур_38465"
                        data-name="Контур 38465"
                        d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                        transform="translate(-175.548 -221.285)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDashoffset="1077.5"
                        strokeWidth="3"
                        strokeDasharray="0 0 0 0 78 467 0 560"
                      />
                      <path
                        id="Контур_38466"
                        data-name="Контур 38466"
                        d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                        transform="translate(-364.492 -307.186)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDashoffset="497.5"
                        strokeWidth="3"
                        strokeDasharray="0 0 0 0 438.76 40 0 60"
                      />
                      <path
                        id="Контур_38467"
                        data-name="Контур 38467"
                        d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                        transform="translate(-153.411 -413.133)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38468"
                        data-name="Контур 38468"
                        d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                        transform="translate(-153.411 -221.285)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38469"
                        data-name="Контур 38469"
                        d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                        transform="translate(-416.145 -309.834)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38470"
                        data-name="Контур 38470"
                        d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                        transform="translate(-416.145 -295.076)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38471"
                        data-name="Контур 38471"
                        d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                        transform="translate(-381.258 -343.036)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Контур_38472"
                        data-name="Контур 38472"
                        d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                        transform="translate(-178.061 -174.79)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDashoffset="851.26"
                        strokeWidth="3"
                        strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                      />
                      <line
                        id="Линия_535"
                        data-name="Линия 535"
                        x2="46.186"
                        transform="translate(53.479 104.526)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        strokeDasharray="0 0 0 0 0 40 628.76 560"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </h1>
          </div>
        </div>
      </section>*/}

     {/* <div className="all-categories__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink to="/my-bank">הקופה שלי</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>קטגוריות</p>
            </li>
          </ul>
        </div>
      </div>*/}

      <section className="all-categories__main main-all-categories">
        <div className="main-all-categories__container">
          <div className="main-all-categories__body">
           {/* <div className="main-all-categories__steps-numbers numbers-steps step-2">
              <ul className="numbers-steps__list">
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">1</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">2</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">3</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">4</div>
                </li>
              </ul>
            </div>
            <div className="main-all-categories__titles-block">
              <h2 className="main-all-categories__title">למימוש הכסף למתנה</h2>
              <h3 className="main-all-categories__subtitle">
                בחר קטגוריה והזמן מתנה לכל מי שתרצה
              </h3>
            </div>*/}

            <ul className="main-all-categories__list">
              {allCategoriesList &&
                allCategoriesList.map((category) => (
                  <MyBankCategoryCard
                    newEvent={newEvent}
                    key={category.mainCategory.id}
                    data={category.mainCategory}
                    wishlistId={currWishlistId}
                    allCategoriesList={allCategoriesList}
                    setProductsFilterObject={setProductsFilterObject}
                    productsFilterObject={productsFilterObject}
                    setIsMobile={setIsMobile}
                    isMobile={isMobile}
                    setSelectedCategoriesMobile={setSelectedCategoriesMobile}
                    selectedCategoriesMobile={selectedCategoriesMobile}
                  />
                 

                ))}
            </ul>       
          </div>
          {isMobile && 
          <div className="not-scroll">
            <div className="contact-us-form__btn-container btn-container_center">
              <button
                className="btn wishlist"
               onClick={navigateToMatchProducts}
              >
                לשלב הבא
              </button>
            </div>
            </div>}
        </div>

    
      </section>
    </main>
  );
}
