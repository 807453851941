import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Zoom } from "swiper";
import Product from "../Components/Product";
import axios from "axios";
import "swiper/css";
import "swiper/css/zoom";
import { API_DOMAIN_IMG, API_DOMAIN } from "../Libs/config";
import MyBankProduct from "../Components/MyBank/MyBankProduct";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { hardCodeProducts } from "../Libs/dummy-products";

// Initialize Swiper modules
SwiperCore.use([Navigation, Zoom]);



const parseArrays = (jsonString) => {
  try {
    // Check if jsonString is undefined or null
    if (jsonString === undefined || jsonString === null) {
      return [];
    }

    // Replace all single quotes with double quotes
    const validJsonString = jsonString.replace(/'/g, '"');

    // Parse the modified JSON string
    return JSON.parse(validJsonString);
  } catch (error) {
    console.error("Error parsing JSON string:", error);
    return [];
  }
};

const parseKeysValues = (product) => {
  const parsedProduct = {
    ...product,
    variations: parseArrays(product.variations),
    deliveryTypes: parseArrays(product.deliveryTypes),
    expandedDescription: parseArrays(product.expandedDescription),
    images: Array.isArray(product.images) ? product.images : product.images.split(",").filter((item) => item !== ""),
  };
  return parsedProduct;
}


export default function ProductPageNewEvent({
  updatePopupState,
  addItemHandler,
  openImagePopup,
  currUserObject,
  setCurrUserObject,
  currWishlist,
  goToChoosedPresentForm,
}) {
  const { productId } = useParams();
  const mainSwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);
  const swiperRecent = useRef(null);
  const swiperRelated = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexRecent, setActiveIndexRecent] = useState(0);
  const [isBeginningRelated, setIsBeginningRelated] = useState(true);
  const [isEndRelated, setIsEndRelated] = useState(false);

  const [relatedPrevActive, setRelatedPrevActive] = useState(true);
  const [recentPrevActive, setRecentPrevActive] = useState(true);
  const [thumbPrevActive, setThumbPrevActive] = useState(true);
  const [productData, setProductData] = useState(null);
  const [productPrices, setProductPrices] = useState({
    price: null,
    discountPrice: null,
  });
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");




  useEffect(() => {
    // console.log(swiperRelated.current?.swiper?.realIndex == 0, 'swiperRelated.current?.swiper?.realIndex ')
    if (swiperRelated.current?.swiper?.realIndex == 0) {
      setRelatedPrevActive(true);
    } else {
      setRelatedPrevActive(false);
    }
  }, [swiperRelated.current?.swiper?.realIndex]);

  useEffect(() => {
    // console.log(swiperRecent.current?.swiper?.realIndex == 0, 'swiperRecent.current?.swiper?.realIndex ')
    if (swiperRecent.current?.swiper?.realIndex == 0) {
      setRecentPrevActive(true);
    } else {
      setRecentPrevActive(false);
    }
  }, [swiperRecent.current?.swiper?.realIndex]);

  useEffect(() => {
    // console.log(thumbnailSwiperRef.current?.swiper?.realIndex == 0, 'thumbnailSwiperRef.current?.swiper?.realIndex ')
    if (thumbnailSwiperRef.current?.swiper?.realIndex == 0) {
      setThumbPrevActive(true);
    } else {
      setThumbPrevActive(false);
    }
  }, [thumbnailSwiperRef.current?.swiper?.realIndex]);

  const handleReachEndRelated = () => {
    setIsBeginningRelated(false);
    setIsEndRelated(true);
  };

  const handleReachBeginningRelated = () => {
    setIsBeginningRelated(true);
    setIsEndRelated(false);
  };
  // console.log('isEndRelated:', isEndRelated, 'isBeginningRelated:', isBeginningRelated)
  // console.log('mainSwiperRef', mainSwiperRef.current?.swiper?.slides.length - 1, mainSwiperRef.current?.swiper?.realIndex)

  useEffect(() => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      mainSwiperRef.current.swiper?.on("slideChange", () => {
        setActiveIndex(mainSwiperRef.current.swiper?.realIndex);
      });
    }

  }, []);

  useEffect(() => {
    console.log("productId", productId);
    if (productId) {
      getProductDetails();
    }

  }, [productId])
  


  function getProductDetails() {
    axios(
      `${API_DOMAIN}/api/ProductCategory/GetProductById?productId=${productId}`
    ).then((response) => {
      console.log("product details", response.data);
      const parsedProduct = parseKeysValues(response.data.product);
      console.log('parsedProduct', parsedProduct)
      setProductData(parsedProduct);
      const firstVariation = parsedProduct.variations[0];
      console.log(typeof firstVariation)
      if (typeof firstVariation == "number") {
        setProductPrices({
          price: +firstVariation?.price,
          discountPrice: +firstVariation?.discountPrice,
        });
      } else {
        setProductPrices({
          price: parsedProduct?.price,
          discountPrice: parsedProduct?.discountPrice,
        });
      }
      const deliveryPrice = +parsedProduct?.deliveryTypes[0]?.price;
      console.log('deliveryPrice', deliveryPrice)
      if (typeof deliveryPrice == "number") {
        setDeliveryPrice(+parsedProduct?.deliveryTypes[0]?.price);
      }

    });
  }

  const handleVariationPrices = (option) => {
    setProductPrices({
      price: +option.price,
      discountPrice: +option.discountPrice,
    });
  }

  console.log(productPrices, 'productPrices')
  const handleThumbnailClick = (index) => {
    const swiperInstance = mainSwiperRef.current.swiper;
    const currentIndex = swiperInstance.realIndex;
    const lastIndex = swiperInstance.slides.length - 1;

    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      // mainSwiperRef.current.swiper?.slideTo(index);
      swiperInstance.slideTo(index);
    }
  };

  const handlePrevNext = (direction) => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      const swiperInstance = mainSwiperRef.current.swiper;
      const swiperInstanceThumb = thumbnailSwiperRef.current.swiper;
      const currentIndex = swiperInstance.realIndex;
      const lastIndex = swiperInstance.slides.length - 1;

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiperInstance.slideTo(newIndex);
        swiperInstanceThumb.slideTo(newIndex);
      }
    }
  };

  const handleSlideStep = (swiper, direction) => {
    if (swiper && swiper.slideTo) {
      const currentIndex = swiper.realIndex;
      const lastIndex = swiper.slides.length - 1;

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiper.slideTo(newIndex);
      }
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    }
  }, []);

  return (
    <main className="single-product">
      <div className="products__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>

            <li className="breadcrumbs__item">
              <p>מוצרים</p>
            </li>
          </ul>
        </div>
      </div>

      <section className="single-product__main main-single-product">
        <div className="main-single-product__container">
          <div className="main-single-product__body">
            <div className="main-single-product__gallery-col">
              <div className="main-single-product__main-slider-block">
                <Swiper
                  // style={{ position: "relative" }}
                  ref={mainSwiperRef}
                  className="main-single-product__main-slider swiper"
                  zoom={true}
                  navigation={true}
                  modules={[Zoom, Navigation]}
                  grabCursor={true}
                  onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                >
                  {productData?.images && productData.images?.map(imgUrl => {


                    const imageUrl = imgUrl.includes('http') ? imgUrl : API_DOMAIN_IMG + imgUrl


                    return (
                      <SwiperSlide
                        key={imgUrl}
                        className="main-single-product__main-slide swiper-slide"
                      >
                        <div className="main-single-product__image-ibg swiper-zoom-container">
                          <img
                            src={imageUrl}
                            alt={productData.id.toString()}
                          />
                        </div>
                        <div className="bottom-buttons" style={{ zIndex: "100" }}>
                          <button
                            type="button"
                            className="zoom"
                            onClick={(e) => openImagePopup(e, imageUrl)}

                          // onClick={() => mainSwiperRef.current.swiper.zoom.in()}
                          >
                            <svg
                              id="Компонент_480_15"
                              data-name="Компонент 480 – 15"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24.504"
                              height="32"
                              viewBox="0 0 24.504 32"
                            >
                              <g
                                id="Сгруппировать_1793"
                                data-name="Сгруппировать 1793"
                                transform="translate(-137 -418.133)"
                              >
                                <g
                                  id="Компонент_21_21"
                                  data-name="Компонент 21 – 21"
                                  transform="translate(137 422)"
                                >
                                  <g id="loupe" transform="translate(0)">
                                    <g
                                      id="Сгруппировать_369"
                                      data-name="Сгруппировать 369"
                                    >
                                      <g
                                        id="Сгруппировать_368"
                                        data-name="Сгруппировать 368"
                                        transform="translate(0)"
                                      >
                                        <path
                                          id="Контур_3180"
                                          data-name="Контур 3180"
                                          d="M359.1,358.057l-6.2-6.67a1.029,1.029,0,0,0-1.53,0,1.226,1.226,0,0,0,0,1.645l6.2,6.67a1.029,1.029,0,0,0,1.53,0A1.225,1.225,0,0,0,359.1,358.057Z"
                                          transform="translate(-334.91 -334.91)"
                                          fill="#707070"
                                        />
                                        <g
                                          id="Сгруппировать_367"
                                          data-name="Сгруппировать 367"
                                          transform="translate(0)"
                                        >
                                          <g
                                            id="Сгруппировать_366"
                                            data-name="Сгруппировать 366"
                                          >
                                            <path
                                              id="Контур_3179"
                                              data-name="Контур 3179"
                                              d="M10,0A10,10,0,1,0,20,10,10.01,10.01,0,0,0,10,0Zm0,18.152A8.153,8.153,0,1,1,18.152,10,8.162,8.162,0,0,1,10,18.152Z"
                                              fill="#707070"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <text
                                id="_"
                                data-name="+"
                                transform="translate(3.004)"
                                fill="#707070"
                                fontSize="20"
                                fontFamily="Assistant-SemiBold, Assistant"
                                fontWeight="600"
                              >
                                <tspan x="12" y="20" space="preserve">
                                  +{" "}
                                </tspan>
                              </text>
                            </svg>
                          </button>
                          <button type="button" className="delete">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="31.515"
                              height="37"
                              viewBox="0 0 31.515 37"
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Прямоугольник_1981"
                                    data-name="Прямоугольник 1981"
                                    width="29.208"
                                    height="25.38"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#3c9e1c"
                                    strokeWidth="1.4"
                                  />
                                </clipPath>
                                <clipPath id="clip-path-2">
                                  <rect
                                    id="Прямоугольник_1983"
                                    data-name="Прямоугольник 1983"
                                    width="18.458"
                                    height="8.075"
                                    fill="none"
                                    stroke="#3c9e1c"
                                    strokeWidth="1.4"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Trash_can"
                                data-name="Trash can"
                                transform="translate(0 -2.813)"
                              >
                                <g
                                  id="Прямоугольник_1980"
                                  data-name="Прямоугольник 1980"
                                  transform="translate(0 10.354)"
                                  fill="none"
                                  stroke="#3c9e1c"
                                  strokeWidth="1.4"
                                >
                                  <rect
                                    width="31.515"
                                    height="4.614"
                                    rx="2.307"
                                    stroke="none"
                                  />
                                  <rect
                                    x="0.7"
                                    y="0.7"
                                    width="30.115"
                                    height="3.214"
                                    rx="1.607"
                                    fill="none"
                                  />
                                </g>
                                <line
                                  id="Линия_491"
                                  data-name="Линия 491"
                                  x2="2.092"
                                  y2="15.061"
                                  transform="translate(8.884 17.722)"
                                  fill="none"
                                  stroke="#3c9e1c"
                                  strokeLinecap="round"
                                  strokeWidth="1.4"
                                />
                                <line
                                  id="Линия_492"
                                  data-name="Линия 492"
                                  y2="15.061"
                                  transform="translate(15.605 17.722)"
                                  fill="none"
                                  stroke="#3c9e1c"
                                  strokeLinecap="round"
                                  strokeWidth="1.4"
                                />
                                <line
                                  id="Линия_493"
                                  data-name="Линия 493"
                                  x1="1.935"
                                  y2="14.733"
                                  transform="translate(19.953 17.703)"
                                  fill="none"
                                  stroke="#3c9e1c"
                                  strokeLinecap="round"
                                  strokeWidth="1.4"
                                />
                                <g
                                  id="Группа_масок_833"
                                  data-name="Группа масок 833"
                                  transform="translate(1.154 14.433)"
                                  clipPath="url(#clip-path)"
                                >
                                  <g
                                    id="Контур_8526"
                                    data-name="Контур 8526"
                                    transform="translate(8.612 -4.365)"
                                    fill="none"
                                  >
                                    <path
                                      d="M-5.19,0H16.826a1.918,1.918,0,0,1,1.81,2.011L16.524,26.479a1.918,1.918,0,0,1-1.81,2.011H-3.079a1.918,1.918,0,0,1-1.81-2.011L-7,2.011A1.918,1.918,0,0,1-5.19,0Z"
                                      stroke="none"
                                    />
                                    <path
                                      d="M -5.190471649169922 1.399999618530273 C -5.375249862670898 1.399999618530273 -5.580936431884766 1.638710021972656 -5.598752975463867 1.965187072753906 L -3.488882064819336 26.41834831237793 L -3.488882064819336 26.4786376953125 C -3.488882064819336 26.82835960388184 -3.272671699523926 27.08967971801758 -3.079351425170898 27.08967971801758 L 14.71437835693359 27.08967971801758 C 14.9076976776123 27.08967971801758 15.1239185333252 26.82835960388184 15.1239185333252 26.4786376953125 L 15.1239185333252 26.41834831237793 L 17.23379135131836 1.965187072753906 C 17.21597290039062 1.638711929321289 17.01027488708496 1.399999618530273 16.82549858093262 1.399999618530273 L -5.190471649169922 1.399999618530273 M -5.190471649169922 -1.9073486328125e-06 L 16.82549858093262 -1.9073486328125e-06 C 17.82487869262695 -1.9073486328125e-06 18.63503837585449 0.9003696441650391 18.63503837585449 2.011037826538086 L 16.52391815185547 26.4786376953125 C 16.52391815185547 27.58930969238281 15.71375846862793 28.48967933654785 14.71437835693359 28.48967933654785 L -3.079351425170898 28.48967933654785 C -4.07872200012207 28.48967933654785 -4.888881683349609 27.58930969238281 -4.888881683349609 26.4786376953125 L -7.000001907348633 2.011037826538086 C -7.000001907348633 0.9003696441650391 -6.189842224121094 -1.9073486328125e-06 -5.190471649169922 -1.9073486328125e-06 Z"
                                      stroke="none"
                                      fill="#3c9e1c"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Группа_масок_834"
                                  data-name="Группа масок 834"
                                  transform="translate(6.529 2.813)"
                                  clipPath="url(#clip-path-2)"
                                >
                                  <g
                                    id="Прямоугольник_1982"
                                    data-name="Прямоугольник 1982"
                                    transform="translate(0 3.461)"
                                    fill="none"
                                    stroke="#3c9e1c"
                                    strokeWidth="1.4"
                                  >
                                    <rect
                                      width="17.304"
                                      height="6.922"
                                      rx="3.461"
                                      stroke="none"
                                    />
                                    <rect
                                      x="0.7"
                                      y="0.7"
                                      width="15.904"
                                      height="5.522"
                                      rx="2.761"
                                      fill="none"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                          <button type="button" className="favorite">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.846"
                              height="24.798"
                              viewBox="0 0 19.846 24.798"
                            >
                              <g
                                id="Сгруппировать_74057"
                                data-name="Сгруппировать 74057"
                                transform="translate(0.5 0.5)"
                              >
                                <path
                                  id="Artboard_2"
                                  data-name="Artboard 2"
                                  d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                                  transform="translate(-74.992 -18.61)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeWidth="1"
                                ></path>
                                <path
                                  id="heart"
                                  d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                                  transform="translate(-25.26 -15.21)"
                                  fill="#ec1b53"
                                ></path>
                              </g>
                            </svg>
                          </button>
                        </div>

                        <button
                          type="button"
                          className="search-btn"
                          onClick={(e) => openImagePopup(e, imageUrl)}
                        >
                          <svg
                            id="loupe"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.667"
                            height="20.667"
                            viewBox="0 0 20.667 20.667"
                          >
                            <g id="Сгруппировать_33" data-name="Сгруппировать 33">
                              <g
                                id="Сгруппировать_32"
                                data-name="Сгруппировать 32"
                              >
                                <path
                                  id="Контур_117"
                                  data-name="Контур 117"
                                  d="M9.1,0a9.1,9.1,0,1,0,9.1,9.1A9.112,9.112,0,0,0,9.1,0Zm0,16.522A7.421,7.421,0,1,1,16.522,9.1,7.429,7.429,0,0,1,9.1,16.522Z"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g
                              id="Сгруппировать_35"
                              data-name="Сгруппировать 35"
                              transform="translate(14.17 14.17)"
                            >
                              <g
                                id="Сгруппировать_34"
                                data-name="Сгруппировать 34"
                              >
                                <path
                                  id="Контур_118"
                                  data-name="Контур 118"
                                  d="M357.3,356.109l-4.817-4.817a.84.84,0,0,0-1.188,1.188l4.817,4.817a.84.84,0,1,0,1.188-1.188Z"
                                  transform="translate(-351.046 -351.046)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </SwiperSlide>
                    )
                  }

                  )}
                </Swiper>

                <div className="thumbnail-swiper-container">
                  <Swiper
                    style={{ position: "relative", marginTop: "30px" }}
                    ref={thumbnailSwiperRef}
                    slidesPerView={4}
                    spaceBetween={10}
                    onSlideChange={(swiper) => {
                      setActiveIndex(swiper.realIndex);
                    }}
                  >

                  </Swiper>
                  <div className="main-single-product__main-navigation single-product">
                    <div
                      className="main-single-product__main-prev"
                      style={{
                        display: `${thumbPrevActive ? "none" : "flex"}`,
                      }}
                      onClick={() => handlePrevNext("prev")}
                    >
                      <img
                        src="../img/svg/arrow-left-black.svg"
                        alt="Previous"
                      />
                    </div>
                    <div
                      className="main-single-product__main-next"
                      style={{
                        display: `${mainSwiperRef.current?.swiper?.slides.length - 4 ==
                          mainSwiperRef.current?.swiper?.realIndex
                          ? "none"
                          : "flex"
                          }`,
                      }}
                      onClick={() => handlePrevNext("next")}
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="Next" />
                    </div>
                  </div>
                </div>

                {/* <button type="button" className="search-btn" onClick={(e) => openImagePopup(e,imgUrl)}>
                  <svg id="loupe" xmlns="http://www.w3.org/2000/svg" width="20.667" height="20.667" viewBox="0 0 20.667 20.667">
                    <g id="Сгруппировать_33" data-name="Сгруппировать 33">
                      <g id="Сгруппировать_32" data-name="Сгруппировать 32">
                        <path id="Контур_117" data-name="Контур 117" d="M9.1,0a9.1,9.1,0,1,0,9.1,9.1A9.112,9.112,0,0,0,9.1,0Zm0,16.522A7.421,7.421,0,1,1,16.522,9.1,7.429,7.429,0,0,1,9.1,16.522Z" fill="#fff" />
                      </g>
                    </g>
                    <g id="Сгруппировать_35" data-name="Сгруппировать 35" transform="translate(14.17 14.17)">
                      <g id="Сгруппировать_34" data-name="Сгруппировать 34">
                        <path id="Контур_118" data-name="Контур 118" d="M357.3,356.109l-4.817-4.817a.84.84,0,0,0-1.188,1.188l4.817,4.817a.84.84,0,1,0,1.188-1.188Z" transform="translate(-351.046 -351.046)" fill="#fff" />
                      </g>
                    </g>
                  </svg>

                </button> */}
              </div>
            </div>
            <div className="main-single-product__content-col">
              <div className="main-single-product__content">
                <h1 className="main-single-product__title">
                  {productData?.name}
                </h1>
                <div className="main-single-product__price">

                  <div className={`${productPrices?.discountPrice ? "old" : "new"}`}>
                    {/* <span className="text">{productPrices?.price + deliveryPrice ?? 0}</span> */}
                    <span className="money-icon">   ₪</span>
                    <span className="text">{isNaN(deliveryPrice) ? +productPrices?.price : productPrices?.price + deliveryPrice}</span>
 
                  </div>
                  {
                    productPrices?.discountPrice ? (
                      <div className="new">
                         <span className="money-icon">      ₪     </span>
                        <span className="text">{isNaN(deliveryPrice) ? productPrices?.discountPrice : productPrices?.discountPrice + deliveryPrice ?? 0}</span>
                        
                      </div>
                    ) : ""
                  }


                </div>
                <div className="main-single-product__rating rating rating_set">
                  <div className="rating__body">
                    <div className="rating__active"></div>
                    <div className="rating__items">
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="1"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="2"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="3"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="4"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="5"
                        name="rating"
                      />
                    </div>
                  </div>
                  <div className="rating__value">5</div>
                </div>

                <div className="main-single-product__describe">
                  <h2>{productData?.description}</h2>
                  <button type="button">קרא עוד</button>
                </div>

                <div className="main-single-product__form-wrapper">
                  <form
                    action=""
                    onSubmit={(e) => e.preventDefault()}
                    className="main-single-product__form form-main-single-product"
                  >
                    {productData?.variations?.length > 0 && (

                      <div className="form-main-single-product__size-selector">
                        <h3 className="form-main-single-product__title">:Variations</h3>
                        <div className="form-main-single-product__size-options">
                          {productData?.variations.map((variation, index) => (
                            <div className="options__item" key={variation.id}>
                              <input
                                id={`color_${index}`}
                                className="options__input"
                                defaultChecked={index === 0}
                                type="radio"
                                defaultValue="1"
                                name="color"

                              />
                              <label
                                htmlFor={`color_${index}`}
                                className="options__label"
                                onClick={() => handleVariationPrices(variation)}
                              >{variation.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )

                    }
                    {
                      productData?.deliveryTypes?.length > 0 && (
                        <div className="form-main-single-product__size-selector">
                          <h3 className="form-main-single-product__title">:Delivery types</h3>
                          <div className="form-main-single-product__size-options">
                            {productData?.deliveryTypes.map((deliveryType, index) => (
                              <div className="options__item" key={deliveryType.id}>
                                <input
                                  id={`deliveryType_${deliveryType.id}`}
                                  className="options__input"
                                  defaultChecked={index === 0}
                                  type="radio"
                                  defaultValue="1"
                                  name="deliveryType"
                                />
                                <label
                                  htmlFor={`deliveryType_${deliveryType.id}`}
                                  className="options__label"
                                  onClick={() => {
                                    setDeliveryPrice(+deliveryType.price)
                                  }
                                  }
                                >
                                  <span className="options__text">{deliveryType.name}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    }
                    <div className="form-main-single-product__quantity-wrapper">
                      <p className="form-main-single-product__quantity-title">
                        כמות
                      </p>
                      <div
                        data-quantity
                        className="form-main-single-product__quantity quantity"
                      >
                        <button
                          data-quantity-plus
                          type="button"
                          className="quantity__button quantity__button_plus"
                        >
                          +
                        </button>
                        <div className="quantity__input">
                          <input
                            data-quantity-value
                            autoComplete="off"
                            type="text"
                            name="form[]"
                            defaultValue="1"
                          />
                        </div>
                        <button
                          data-quantity-minus
                          type="button"
                          className="quantity__button quantity__button_minus"
                        >
                          -
                        </button>
                      </div>
                    </div>

                    <div className="form-main-single-product__bottom-buttons">
                      <button
                        className="btn"
                        onClick={() => {
                          // window.history.go(-1);
                          goToChoosedPresentForm(productData)
                          
                        }}
                      >
                         לקנות מתנה
                      </button>
                      {/* <a
                        href="javascript:void(0)"
                        onClick={() => {
                          window.history.go(-1);
                        }}
                        className="btn-black"
                      >
                        סיימתי לבחור מתנות
                      </a> */}
                    </div>
                  </form>
                </div>

                <div className="main-single-product__mobile-bottom-text">
                  <p>
                    <span>זמן טיפול: </span>יחושבו כשהמוצר יבחר
                    <br /> <span>זמן משלוח: </span>משלוח מהיר 3-6 ימי עסקים
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="main-single-product__spollers-block">
            <div data-spollers className="main-single-product__spollers">
              {productData?.expandedDescription?.map((expandedDescription, index) => (
                <details className="main-single-product__spollers-item" key={expandedDescription.id}>
                  <summary className="main-single-product__spollers-title">
                    {expandedDescription.title}
                  </summary>
                  <div className="main-single-product__spollers-body">
                    <p>
                      {expandedDescription.text}
                    </p>
                    <p>
                      <span>זמן טיפול: </span>יחושבו כשהמוצר יבחר
                      <br />
                      <span>זמן משלוח: </span>משלוח מהיר 3-6 ימי עסקים
                    </p>
                  </div>
                </details>
              )
              )}
              {/* <details className="main-single-product__spollers-item">
                <summary className="main-single-product__spollers-title">
                  תיאור מורחב
                </summary>
                <div className="main-single-product__spollers-body">
                  <p>
                    ורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם
                    איפסום דולור סיט אמט, קולורס מונפרד אדנדום סילקוף, מרגשי
                    ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח.
                    עמחליף קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה.
                    דס איאקוליס וולופטה דיאם. וסטיבולום קונסקטורר אדיפיסינג
                    אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם.
                    וסטיבולום{" "}
                  </p>
                  <p>
                    <span>זמן טיפול: </span>יחושבו כשהמוצר יבחר
                    <br />
                    <span>זמן משלוח: </span>משלוח מהיר 3-6 ימי עסקים
                  </p>
                </div>
              </details>
              <details className="main-single-product__spollers-item">
                <summary className="main-single-product__spollers-title">
                  תיאור מורחב
                </summary>
                <div className="main-single-product__spollers-body">
                  <p>
                    ורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם
                    איפסום דולור סיט אמט, קולורס מונפרד אדנדום סילקוף, מרגשי
                    ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח.
                    עמחליף קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה.
                    דס איאקוליס וולופטה דיאם. וסטיבולום קונסקטורר אדיפיסינג
                    אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם.
                    וסטיבולום{" "}
                  </p>
                  <p>
                    <span>זמן טיפול: </span>יחושבו כשהמוצר יבחר
                    <br />
                    <span>זמן משלוח: </span>משלוח מהיר 3-6 ימי עסקים
                  </p>
                </div>
              </details>
              <details className="main-single-product__spollers-item">
                <summary className="main-single-product__spollers-title">
                  תיאור מורחב
                </summary>
                <div className="main-single-product__spollers-body">
                  <p>
                    ורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם
                    איפסום דולור סיט אמט, קולורס מונפרד אדנדום סילקוף, מרגשי
                    ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח.
                    עמחליף קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה.
                    דס איאקוליס וולופטה דיאם. וסטיבולום קונסקטורר אדיפיסינג
                    אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם.
                    וסטיבולום{" "}
                  </p>
                  <p>
                    <span>זמן טיפול: </span>יחושבו כשהמוצר יבחר
                    <br />
                    <span>זמן משלוח: </span>משלוח מהיר 3-6 ימי עסקים
                  </p>
                </div>
              </details>
              <details className="main-single-product__spollers-item">
                <summary className="main-single-product__spollers-title">
                  תיאור מורחב
                </summary>
                <div className="main-single-product__spollers-body">
                  <p>
                    ורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם
                    איפסום דולור סיט אמט, קולורס מונפרד אדנדום סילקוף, מרגשי
                    ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח.
                    עמחליף קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה.
                    דס איאקוליס וולופטה דיאם. וסטיבולום קונסקטורר אדיפיסינג
                    אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם.
                    וסטיבולום{" "}
                  </p>
                  <p>
                    <span>זמן טיפול: </span>יחושבו כשהמוצר יבחר
                    <br />
                    <span>זמן משלוח: </span>משלוח מהיר 3-6 ימי עסקים
                  </p>
                </div>
              </details> */}
            </div>
          </div>

          <div className="main-single-product__footer">
            <div className="main-single-product__likes">
              <div className="icon">
                <svg
                  id="Сгруппировать_626"
                  data-name="Сгруппировать 626"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.64"
                  height="15.706"
                  viewBox="0 0 17.64 15.706"
                >
                  <path
                    id="heart"
                    d="M31.341,33.83l6.886,6.886,7.4-7.4a4.868,4.868,0,1,0-6.884-6.884l-.509.51-.51-.51a4.868,4.868,0,1,0-6.884,6.884l.508.507Z"
                    transform="translate(-29.41 -25.01)"
                    fill="#ec1b53"
                  />
                </svg>
              </div>
              <p className="number">3.0K</p>
            </div>

            <div className="main-single-product__bottom-ratings">
              <div className="main-single-product__rating-wrapper">
                <div className="rating rating_set">
                  <div className="rating__body">
                    <div className="rating__active"></div>
                    <div className="rating__items">
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="1"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="2"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="3"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="4"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="5"
                        name="rating"
                      />
                    </div>
                  </div>
                  <div className="rating__value">5</div>
                </div>

                <p className="rating-text">44 ביקורות לספק</p>
              </div>
              <div className="main-single-product__rating-wrapper">
                <div className="rating rating_set">
                  <div className="rating__body">
                    <div className="rating__active"></div>
                    <div className="rating__items">
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="1"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="2"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="3"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="4"
                        name="rating"
                      />
                      <input
                        type="radio"
                        className="rating__item"
                        defaultValue="5"
                        name="rating"
                      />
                    </div>
                  </div>
                  <div className="rating__value">5</div>
                </div>

                <p className="rating-text">44 ביקורות למוצר</p>
              </div>
            </div>
          </div>
        </div>
      </section>

  {/* <section className="main-single-product__main main-products main-products_related">
        <div className="main-products__container">
          <h2 className="main-products__title">מוצרים נילווים</h2>
          <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

          <div className="main-products__slider-block">
            <Swiper
              style={{
                position: "relative",
                marginTop: "30px",
                marginBottom: "40px",
                padding: "1.4583333333vw",
              }}
              ref={swiperRelated}
              slidesPerView={3}
              spaceBetween={40}
              onSlideChange={(swiper) => {
                setActiveIndexRecent(swiper.realIndex);
              }}
              onReachEnd={handleReachEndRelated}
              onReachBeginning={handleReachBeginningRelated}
            >
              {hardCodeProducts?.map((product, index) => (
                <SwiperSlide
                  key={product.id}
                  className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                    }`}
                  onClick={() => handleThumbnailClick(index)}
                >

                  <MyBankProduct
                    key={product.id}
                    data={product}
                    updatePopupState={updatePopupState}
                    addItemHandler={addItemHandler}
                    openImagePopup={openImagePopup}
                    currUserObject={currUserObject}
                    setCurrUserObject={setCurrUserObject}
                    currWishlist={currWishlist}
                    goToChoosedPresentForm={goToChoosedPresentForm}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="main-products__slider-nav">
              <div
                className="main-products__slider-prev"
                style={{ display: `${relatedPrevActive ? "none" : "flex"}` }}
                onClick={() =>
                  handleSlideStep(swiperRelated.current.swiper, "prev")
                }
              >
                <img src="../img/svg/arrow-left-black.svg" alt="" />
              </div>
              <div
                className="main-products__slider-next"
                style={{
                  display: `${swiperRelated.current?.swiper?.slides.length - 3 ==
                    swiperRelated.current?.swiper?.realIndex
                    ? "none"
                    : "flex"
                    }`,
                }}
                onClick={() =>
                  handleSlideStep(swiperRelated.current.swiper, "next")
                }
              >
                <img src="../img/svg/arrow-left-black.svg" alt="" />
              </div>
            </div>
          </div>

          <div className="main-products__btn-container btn-container_center">
            <a href="#" className="btn-outline-white">
              לא מצאתי רוצה לראות עוד מוצרים
            </a>
          </div>
        </div>
      </section>

      <section className="main-single-product__main main-products main-products_recent">
        <div className="main-products__container">
          <h2 className="main-products__title">מוצרים אחרונים שחיפשתי</h2>
          <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

          <div className="main-products__slider-block">
            <Swiper
              style={{
                position: "relative",
                marginTop: "30px",
                marginBottom: "40px",
                padding: "1.4583333333vw",
              }}
              ref={swiperRecent}
              slidesPerView={3}
              spaceBetween={40}
              onSlideChange={(swiper) => {
                setActiveIndexRecent(swiper.realIndex);
              }}
            >
              {hardCodeProducts?.map((product, index) => (
                <SwiperSlide
                  key={product.id}
                  className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                    }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <MyBankProduct
                    key={product.id}
                    data={product}
                    updatePopupState={updatePopupState}
                    addItemHandler={addItemHandler}
                    openImagePopup={openImagePopup}
                    currUserObject={currUserObject}
                    setCurrUserObject={setCurrUserObject}
                    currWishlist={currWishlist}
                    goToChoosedPresentForm={goToChoosedPresentForm}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="main-products__slider-nav">
              <div
                className="main-products__slider-prev"
                style={{ display: `${recentPrevActive ? "none" : "flex"}` }}
                onClick={() =>
                  handleSlideStep(swiperRecent.current.swiper, "prev")
                }
              >
                <img src="../img/svg/arrow-left-black.svg" alt="" />
              </div>
              <div
                className="main-products__slider-next"
                style={{
                  display: `${swiperRecent.current?.swiper?.slides.length - 3 ==
                    swiperRecent.current?.swiper?.realIndex
                    ? "none"
                    : "flex"
                    }`,
                }}
                onClick={() =>
                  handleSlideStep(swiperRecent.current.swiper, "next")
                }
              >
                <img src="../img/svg/arrow-left-black.svg" alt="" />
              </div>
            </div>
          </div>

          <div className="main-products__btn-container btn-container_center">
            <a href="#" className="btn-outline-white">
              לא מצאתי רוצה לראות עוד מוצרים
            </a>
          </div>
        </div>
      </section>*/}
    </main>
  );
}
