import React, { useEffect, useRef, useState } from "react";
import { greenPopupBg, pinkPopupBg } from "../Assets/images";
import { useNavigate } from "react-router-dom";

function ExitPopup({ updateVerifyNumberPopup, updatePopupState }) {
  const navigate = useNavigate();

  function closeSendSmsPopup() {
    window.close();

   updateVerifyNumberPopup(false);
   updatePopupState(false);
  }
  function closeWindow() {
    navigate("/");

  }
  

  return (
    <div
      id="we-send-you-sms-popup-green"
      aria-hidden="true"
      className="popup popup_show"
    >
      <div className="popup__wrapper">
        <div className="popup__content">
          <div className="popup__bg-ibg">
            <img src={greenPopupBg} alt="green hearts" />
          </div>
          <button
            data-close
            onClick={closeSendSmsPopup}
            type="button"
            className="popup__close"
          />
          <div className="popup__body">
           {/* <div className="popup__image-ibg_contain">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="164.456"
                height={75}
                viewBox="0 0 164.456 75"
              >
                <g
                  id="Сгруппировать_1275"
                  data-name="Сгруппировать 1275"
                  transform="translate(-129.514 -361.195)"
                >
                  <path
                    id="Контур_10157"
                    data-name="Контур 10157"
                    d="M21.514,0H142.941a21.514,21.514,0,0,1,0,43.029H21.514A21.514,21.514,0,0,1,21.514,0Z"
                    transform="translate(129.514 369.081)"
                    fill="#f8af1d"
                  />
                  <text
                    id="_"
                    data-name="****"
                    transform="translate(211.741 419.195)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={1}
                    fontSize={56}
                    fontFamily="Assistant-Bold, Assistant"
                    fontWeight={700}
                  >
                    <tspan x="50.512" y={0}>
                      ****
                    </tspan>
                  </text>
                </g>
              </svg>
            </div>*/}
            <h2 className="popup__title" style={{ fontSize: '30px', marginBottom: '7px'}}>תודה שבחרת בנו</h2>
            <h3 className="popup__title">נתראה באירוע הבא שלך</h3>
            <div className="popup__buttons-container">
              <button onClick={closeWindow} className="btn-white">
              חזרה לדף הבית
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExitPopup;
