import React, { useEffect, useState } from "react";
import * as noUiSlider from "nouislider";
import { API_DOMAIN_IMG } from "../Libs/config";
// import Slider from '@mui/material/Slider';
import Nouislider from "nouislider-react";
// import "nouislider/distribute/nouislider.css";
// Підключення стилів з scss/base/forms/range.scss
// у файлі scss/forms/forms.scss

// Підключення стилів з node_modules
import "nouislider/dist/nouislider.css";

let isFirstLoad = true;
let priceSlider = null;

function ProductsFilter({
  filterToggle,
  allCategoriesList,
  filterObject,
  setFilterObject,
  productsFilterObject,
  setProductsFilterObject,
  filterFunction,
  manufacturers,
  rangePriceMinMax,
}) {
  console.log('filterObject_filterObject', filterObject);
  const [isRangeInit, setIsRangeInit] = useState(false);

  // useEffect(() => {

  //   // if (filterObject.choosedCategoriesList.length && isFirstLoad) {
  //   if (filterObject.choosedCategoriesList.length) {
  //     isFirstLoad = false;
  //     filterObject.choosedCategoriesList.map((_, index) => {
  //       const searchCategoryInterval = setInterval(() => {
  //         const choosedCategory = document.getElementById(
  //           `category-check_${filterObject.choosedCategoriesList[index].categoryId}_${filterObject.choosedCategoriesList[index].productsId}`
  //         );
  //         // console.log('filterObject.choosedCategoriesList', choosedCategory);

  //         if (choosedCategory) {
  //           choosedCategory.checked = true;
  //           const headerChoosedCategory = document.getElementById(
  //             `header-category-check_${filterObject.choosedCategoriesList[index].categoryId}_${filterObject.choosedCategoriesList[index].productsId}`
  //           );
  //           const header = choosedCategory.closest('.filter__option-categories-item');
  //           if (header) {
  //             header.setAttribute('open', true)
  //           }
  //           if (headerChoosedCategory) {
  //             headerChoosedCategory.checked = true;
  //           }
  //           clearInterval(searchCategoryInterval);
  //         }
  //       });
  //     })

  //   }
  // }, [filterObject]);


  // useEffect(() => {
  //   manufacturers.map((manufac, index) => {
  //     console.log('filterObject.manufacturersList',filterObject.manufacturersList)
  //     if (filterObject.manufacturersList?.includes(manufac)) {
  //       const checkBox = document.getElementById(`manufacturer_${index}`);
  //       console.log('checkBox',checkBox)
  //       checkBox.checked = true;
  //       setFilterObject((prevObj) => {
  //         const uniqueManufacturersSet = new Set([...prevObj.manufacturersList, manufac]);
  //         const uniqueManufacturersArray = Array.from(uniqueManufacturersSet);
  //         const newObj = {
  //           ...prevObj,
  //           manufacturersList: uniqueManufacturersArray,
  //           priceRange: null,
  //           lastChangedType: "manufacturer",
  //         };
  //         return newObj;
  //       });
  //     }
  //   });
  // }, [manufacturers,filterObject.choosedCategoriesList]);

  // console.log('filterObjectProductsPage',filterObject)


  function rangeInit() {
    priceSlider = document.querySelector("#range");
    if (isRangeInit) return
    if (priceSlider) {
      let textFrom = priceSlider.getAttribute("data-from");
      let textTo = priceSlider.getAttribute("data-to");
      let step = priceSlider.getAttribute("data-step");
      noUiSlider.create(priceSlider, {
        start: [Number(textFrom), Number(textTo)],
        connect: [false, true, false],
        tooltips: [true, true],
        margin: 100,
        step: Number(step),
        range: {
          min: [Number(textFrom)],
          max: [Number(textTo)],
        },
      });

      priceSlider.noUiSlider.on("change", (e) => {
        const value = priceSlider.noUiSlider.get(true);
        filterFunction("price", value);
        // setFilterObject((prevObj) => {
        //   const newObj = {
        //     ...prevObj,
        //     priceRange: value,
        //     lastChangedType: "price",
        //   };
        //   return newObj;
        // });
      });
      setIsRangeInit(true)
    }
  }
  function updateRange(min, max) {
    priceSlider.noUiSlider.updateOptions({
      // start: max,
      range: {
        min: min,
        max: max,
      },
    });
    priceSlider.noUiSlider.set([min, max]);
  }

  function clearFilter () {
    setProductsFilterObject({
      categoryList: [],
      priceFrom: 0,
      priceTo: 100000000,
      brand: [],
    })
  }

  useEffect(() => {
    // console.log('priceSlider', priceSlider)
    // if (!priceSlider) {
      rangeInit();
    // }
  }, []);
  useEffect(() => {
    if (rangePriceMinMax.min && rangePriceMinMax.max) {
    //  updateRange(rangePriceMinMax.min, rangePriceMinMax.max);
    }
  }, [rangePriceMinMax]);

  return (
    <>
      <div className="filter">
        <div className="filter__body">
          <div className="filter__header">
            <button className="filter__clear" onClick={clearFilter}>נקה</button>
            <h3 className="filter__title">
              <span className="icon">
                <img src={"/img/svg/filter-icon-black.svg"} alt="filter icon" />
              </span>
              
              <span className="text">{/*סינון למתנות נשים*/}</span>
            </h3>
            <button className="filter__close" onClick={filterToggle} />
          </div>
          <div className="filter__content">
            <div data-spollers className="filter__options">
              <details className="filter__options-item" open>
                <summary className="filter__options-title">
                  קטגוריות
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="9.119"
                      viewBox="0 0 14.828 9.119"
                    >
                      <path
                        id="Контур_10392"
                        data-name="Контур 10392"
                        d="M6.26,0,0,6l6.26,6"
                        transform="translate(1.414 7.674) rotate(-90)"
                        fill="none"
                        stroke="#c7c9d2"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </summary>
                <div className="filter__options-body">
                  <div data-spollers className="filter__option-categories">
                    {allCategoriesList &&
                      allCategoriesList.map((category) => (
                        <details
                          key={category.mainCategory?.id}
                          className="filter__option-categories-item"
                        >
                          <summary className="filter__option-categories-title">
                            <span className="icon">
                              <img
                                src={`${API_DOMAIN_IMG}${category.mainCategory.categoryIcon}`}
                                alt=""
                              />
                            </span>
                            <span className="text">
                              {category.mainCategory.categoryAdminName}

                            </span>
                            <span className="arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9.119"
                                height="14.828"
                                viewBox="0 0 9.119 14.828"
                              >
                                <path
                                  id="Контур_10344"
                                  data-name="Контур 10344"
                                  d="M6.26,0,0,6l6.26,6"
                                  transform="translate(1.445 1.414)"
                                  fill="none"
                                  stroke="#f55882"
                                  strokeLinecap="round"
                                  strokeWidth={2}
                                />
                              </svg>
                            </span>
                          </summary>
                          <div className="filter__option-categories-body">
                            {category.productCategory.map((subcategory) => (
                              <div className="checkbox" key={subcategory?.id}>
                                {/* {console.log('debugg_IDS:subcategory?.id, category.mainCategory?.id',subcategory?.id, category.mainCategory?.id)} */}
                                <input
                                  id={`category-check_${category.mainCategory?.id}_${subcategory?.id}`}
                                  className="checkbox__input"
                                  type="checkbox"
                                  defaultValue={subcategory?.id}
                                  // onChange={(e) =>
                                  //   filterFunction(e, {
                                  //     categoryId: String(
                                  //       category.mainCategory?.id
                                  //     ),
                                  //     productsId: String(subcategory?.id),
                                  //   })
                                  // }
                                  checked={productsFilterObject?.categoryList?.includes(String(subcategory?.id))}
                                  onChange={(e) =>
                                    // filterFunction(e, {
                                    //   categoryId: String(
                                    //     category.mainCategory?.id
                                    //   ),
                                    //   productsId: String(subcategory?.id),
                                    // })
                                    filterFunction("category", String(subcategory?.id), e.target.checked)
                                  }
                                  name={`category-check_${category.mainCategory?.id}_${subcategory?.id}`}
                                />
                                <label
                                  htmlFor={`category-check_${category.mainCategory?.id}_${subcategory?.id}`}
                                  className="checkbox__label"
                                >
                                  <span className="checkbox__text">
                                    {subcategory.niceName}
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </details>
                      ))}
                  </div>
                </div>
              </details>
             {/* <details className="filter__options-item" open>
                <summary className="filter__options-title">
                  מותג
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="9.119"
                      viewBox="0 0 14.828 9.119"
                    >
                      <path
                        id="Контур_10392"
                        data-name="Контур 10392"
                        d="M6.26,0,0,6l6.26,6"
                        transform="translate(1.414 7.674) rotate(-90)"
                        fill="none"
                        stroke="#c7c9d2"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </summary>
                <div className="filter__options-body">
                  {manufacturers &&
                    manufacturers.map((manufacturer, index) => (
                      <div key={index} className="checkbox">
                        <input
                          id={`manufacturer_${index}`}
                          className="checkbox__input manufacturer-check"
                          type="checkbox"
                          defaultValue={index}
                          checked={productsFilterObject?.brand?.includes(String(manufacturer))}
                          name="form[]"
                          onChange={(e) =>
                            filterFunction("brand", String(manufacturer), e.target.checked)
                          }
                        />
                        <label
                          htmlFor={`manufacturer_${index}`}
                          className="checkbox__label"
                        >
                          <span className="checkbox__text">{manufacturer}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </details>*/}
       {/*       <details className="filter__options-item" open>
                <summary className="filter__options-title">
                  מחיר
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="9.119"
                      viewBox="0 0 14.828 9.119"
                    >
                      <path
                        id="Контур_10392"
                        data-name="Контур 10392"
                        d="M6.26,0,0,6l6.26,6"
                        transform="translate(1.414 7.674) rotate(-90)"
                        fill="none"
                        stroke="#c7c9d2"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </summary>
                <div className="filter__options-body">
                  <div className="filter__range-wrapper">

                    <div
                      className="filter__range"
                      id="range"
                      data-from={rangePriceMinMax.min}
                      data-to={rangePriceMinMax.max}
                      data-step={100}
                    ></div>
                    <div className="filter__range-values">
                      <div className="min">
                        <span>₪</span> {rangePriceMinMax.min}
                      </div>
                      <div className="max">
                        <span>₪</span> {rangePriceMinMax.max}
                      </div>
                    </div>
                  </div>
                </div>
              </details>*/}
              {/* <details className="filter__options-item" open>
                <summary className="filter__options-title">
                  גודל
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="9.119"
                      viewBox="0 0 14.828 9.119"
                    >
                      <path
                        id="Контур_10392"
                        data-name="Контур 10392"
                        d="M6.26,0,0,6l6.26,6"
                        transform="translate(1.414 7.674) rotate(-90)"
                        fill="none"
                        stroke="#c7c9d2"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </summary>
                <div className="filter__options-body">
                  <div className="form-main-single-product__size-selector">
                    <div className="form-main-single-product__size-options">
                      <div className="options__item">
                        <input
                          id="size_1"
                          className="options__input"
                          defaultChecked
                          type="radio"
                          defaultValue={1}
                          name="size"
                        />
                        <label htmlFor="size_1" className="options__label">
                          <span className="options__text">S</span>
                        </label>
                      </div>
                      <div className="options__item">
                        <input
                          id="size_2"
                          className="options__input"
                          type="radio"
                          defaultValue={2}
                          name="size"
                        />
                        <label htmlFor="size_2" className="options__label">
                          <span className="options__text">M</span>
                        </label>
                      </div>
                      <div className="options__item">
                        <input
                          id="size_3"
                          className="options__input"
                          defaultChecked
                          type="radio"
                          defaultValue={3}
                          name="size"
                        />
                        <label htmlFor="size_3" className="options__label">
                          <span className="options__text">L</span>
                        </label>
                      </div>
                      <div className="options__item">
                        <input
                          id="size_4"
                          className="options__input"
                          type="radio"
                          defaultValue={4}
                          name="size"
                        />
                        <label htmlFor="size_4" className="options__label">
                          <span className="options__text">XL</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="filter__options-item" open>
                <summary className="filter__options-title">
                  צבעים
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="9.119"
                      viewBox="0 0 14.828 9.119"
                    >
                      <path
                        id="Контур_10392"
                        data-name="Контур 10392"
                        d="M6.26,0,0,6l6.26,6"
                        transform="translate(1.414 7.674) rotate(-90)"
                        fill="none"
                        stroke="#c7c9d2"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </summary>
                <div className="filter__options-body">
                  <div className="form-main-single-product__color-selector">
                    <div className="form-main-single-product__color-options">
                      <div className="options__item">
                        <input
                          id="color_1"
                          className="options__input"
                          defaultChecked
                          type="radio"
                          defaultValue={1}
                          name="color"
                        />
                        <label
                          htmlFor="color_1"
                          className="options__label"
                          style={{ backgroundColor: "#f9a3ba" }}
                        />
                      </div>
                      <div className="options__item">
                        <input
                          id="color_2"
                          className="options__input"
                          type="radio"
                          defaultValue={2}
                          name="color"
                        />
                        <label
                          htmlFor="color_2"
                          className="options__label"
                          style={{ backgroundColor: "#000" }}
                        />
                      </div>
                      <div className="options__item">
                        <input
                          id="color_3"
                          className="options__input"
                          defaultChecked
                          type="radio"
                          defaultValue={3}
                          name="color"
                        />
                        <label
                          htmlFor="color_3"
                          className="options__label"
                          style={{ backgroundColor: "#fff" }}
                        />
                      </div>
                      <div className="options__item">
                        <input
                          id="color_4"
                          className="options__input"
                          defaultChecked
                          type="radio"
                          defaultValue={4}
                          name="color"
                        />
                        <label
                          htmlFor="color_4"
                          className="options__label"
                          style={{ backgroundColor: "#cbf1ff" }}
                        />
                      </div>
                      <div className="options__item">
                        <input
                          id="color_5"
                          className="options__input"
                          type="radio"
                          defaultValue={5}
                          name="color"
                        />
                        <label
                          htmlFor="color_5"
                          className="options__label"
                          style={{ backgroundColor: "#f8a131" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </details> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsFilter;
