import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import axios from "axios";

import logoImg from "../Assets/img/logo.png";
import {
  americanExpress,
  bit,
  mastercard,
  mastercardBlue,
  paybox,
  paypal,
  pci,
  visa,
} from "../Assets/images";

function Footer({ isLoggedIn, setMyEventsActiveTab, wishlistCategories, setCurrWishlist }) {
  const navigate = useNavigate();

  const goToCartByWishlist = () => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${sortWishlists[0].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

  };

  const redirectToNewEvent = () => {
    navigate('/my-events')
    setMyEventsActiveTab(0);
  }

  const redirectToMyEvents = () => {
   setMyEventsActiveTab(1);
   if(isLoggedIn)
   {
    navigate('/my-events')
    }
  else
  {
  localStorage.setItem('intendedRoute', '/my-events'); // Store intended route
   navigate('/credentials')
  }
  }
  const redirectToEventsHistory = () => { 
    setMyEventsActiveTab(2);
    if(isLoggedIn)
      {
       navigate('/my-events')
       }
     else
     {
     localStorage.setItem('intendedRoute', '/my-events'); // Store intended route
      navigate('/credentials')
     }
  }

  
  const redirectToMyBookedEvents = () => {
    setMyEventsActiveTab(3);
    if(isLoggedIn)
      {
       navigate('/my-events')
       }
     else
     {
     localStorage.setItem('intendedRoute', '/my-events'); // Store intended route
      navigate('/credentials')
     }
  }

  const openFilteredEvents = (wishlist) => {
    setMyEventsActiveTab(0);
    navigate('/my-events', { state: { activeWishlist: wishlist } });
  }

  const redirectToMyBank =()=>{
    if(isLoggedIn)
      {
       navigate('/my-bank')
       }
     else
     {
     localStorage.setItem('intendedRoute', '/my-bank'); // Store intended route
      navigate('/credentials')
     }
  }
const navigateToProfil =()=>{
  if(isLoggedIn)
    {
     navigate('/edit-profile')
     }
   else
   {
   localStorage.setItem('intendedRoute', '/edit-profile'); // Store intended route
    navigate('/credentials')
   }
}
  return (
    <>
      <footer className="footer">
        <div className="footer__top top-footer">
          <div className="top-footer__container">
            <div className="top-footer__body">
              <div className="top-footer__titles">
                <h2 className="top-footer__title">?רוצים לקבל ניוזלטר</h2>
                <h3 className="top-footer__subtitle">
                  מלאו פרטיכם וקבלו מאיתנו את המוצרים הכי עדכניים
                </h3>
              </div>
              <div className="top-footer__form-block">
                <form action="#" className="top-footer__form">
                  <label
                    htmlFor="top-footer__email"
                    className="top-footer__input-wrapper"
                  >
                    <input
                      autoComplete="off"
                      type="email"
                      name="email"
                      id="top-footer__email"
                      placeholder="מלאו את האימייל"
                    />
                    <button type="submit" className="btn">
                      שלחו
                    </button>
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__container">
          <div className="footer__body">
            <div className="footer__col">
              <h3 className="footer__title">מה האירוע שלך?</h3>
              <ul className="footer__links-list">
                {wishlistCategories?.map((item, index) => (
                  <li className="footer__links-item" key={item.id}>
                    <a
                      onClick={() => openFilteredEvents(item)}
                      className="footer__link"
                    >
                      {item.categoryName}
                    </a>
                  </li>
                ))}

              </ul>
            </div>
            <div className="footer__col">
              <h3 className="footer__title">מפת אתר</h3>
              <ul className="footer__links-list">
                <li className="footer__links-item">
                  <NavLink to="/" className="footer__link">
                    דף הבית
                  </NavLink>
                </li>
                <li className="footer__links-item">
                  <NavLink to="/explaining-steps" className="footer__link">
                    איך זה עובד?
                  </NavLink>
                </li>
                <li className="footer__links-item">
                  <a onClick={redirectToNewEvent} className="footer__link" >
                    אירוע חדש
                  </a>
                </li>
                <li className="footer__links-item">
                  <a onClick={redirectToMyEvents} className="footer__link">
                    אירועים שלי
                  </a>
                </li>
                <li className="footer__links-item">
                  <a onClick={redirectToMyBank}  className="footer__link">
                    הקופה שלי
                  </a>
                </li>
                <li className="footer__links-item">
                  <a onClick={redirectToMyBookedEvents} className="footer__link">
                    האירועים שהוזמנתי
                  </a>
                </li>
                <li className="footer__links-item">
                  <NavLink to="/conditions" className="footer__link">
                    תנאי שימוש
                  </NavLink>
                </li>
                <li className="footer__links-item">
                  <NavLink to="/terms" className="footer__link" href="#">
                    תקנון
                  </NavLink>
                </li>
                <li className="footer__links-item">
                  <NavLink to="/contact-us" className="footer__link" href="#">
                    צור קשר
                  </NavLink>
                </li>
                <li className="footer__links-item">
                  <a onClick={navigateToProfil} className="footer__link">
                    פרטים אישיים
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__col footer__col_big">
              <div className="footer__col-body">
                <div className="footer__col-item">
                  <h3 className="footer__title">אתם מזמינים</h3>
                  <ul className="footer__links-list">
                    <li className="footer__links-item">
                      <a onClick={redirectToMyEvents} className="footer__link">
                        האירועים שלי
                      </a>
                    </li>
                    <li className="footer__links-item">
                      <a onClick={redirectToEventsHistory} className="footer__link">
                        היסטוריית אירועים
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer__col-item">
                  <h3 className="footer__title">אתם מוזמנים</h3>
                  <ul className="footer__links-list">
                    <li className="footer__links-item">
                      <a onClick={redirectToMyBookedEvents} className="footer__link">
                        האירועים שהוזמנתי
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer__col-item footer__col-item_100">
                  <h3 className="footer__title">סוג תשלום</h3>
                  <ul className="footer__payments-list">
                    <li className="footer__payments-list-item">
                      <img src={visa} alt="visa" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={americanExpress} alt="american express" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={mastercardBlue} alt="mastercard blue" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={mastercard} alt="mastercard" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={pci} alt="pci" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={paypal} alt="paypal" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={paybox} alt="paybox" />
                    </li>
                    <li className="footer__payments-list-item">
                      <img src={bit} alt="bit" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer__col footer__col_socials">
              <h3 className="footer__title">צור קשר</h3>
              <div className="footer__icon-links-block">
                <a href="#" className="footer__link-icon">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.599"
                      height="19.988"
                      viewBox="0 0 16.599 19.988"
                    >
                      <g id="Places" transform="translate(0.6 0.6)">
                        <path
                          id="Контур_114"
                          data-name="Контур 114"
                          d="M7.681,10.983a3.242,3.242,0,0,0,3.26-3.235A3.341,3.341,0,0,0,7.681,4.4a3.242,3.242,0,0,0-3.26,3.235A3.422,3.422,0,0,0,7.681,10.983ZM2.208,2.2a7.773,7.773,0,0,1,10.945,0,7.628,7.628,0,0,1,0,10.86l-5.473,5.43-5.473-5.43A7.844,7.844,0,0,1,2.208,2.2Z"
                          transform="translate(-0.025 0.05)"
                          fill="none"
                          stroke="#ec1b53"
                          strokeWidth="1.2"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">דרך מנחם בגין 42, רמת גן</span>
                </a>
                <a href="tel:03-0000000" className="footer__link-icon">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.567"
                      height="23.568"
                      viewBox="0 0 23.567 23.568"
                    >
                      <g id="call" transform="translate(0.616 0.671)">
                        <g
                          id="Сгруппировать_6"
                          data-name="Сгруппировать 6"
                          transform="translate(0 0)"
                        >
                          <g
                            id="Сгруппировать_5"
                            data-name="Сгруппировать 5"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Контур_1"
                              data-name="Контур 1"
                              d="M21.848,17.717l-2.47-1.647-2.2-1.464a.973.973,0,0,0-1.306.212l-1.359,1.747a.968.968,0,0,1-1.235.256,15.82,15.82,0,0,1-4.418-3.4A15.933,15.933,0,0,1,5.46,9a.968.968,0,0,1,.256-1.235L7.463,6.405A.973.973,0,0,0,7.675,5.1L6.256,2.971,4.564.433A.973.973,0,0,0,3.255.139L1.3,1.309A2.384,2.384,0,0,0,.221,2.71C-.312,4.655-.42,8.947,6.457,15.824s11.168,6.769,13.113,6.236a2.383,2.383,0,0,0,1.4-1.083l1.17-1.951A.973.973,0,0,0,21.848,17.717Z"
                              transform="translate(0 0)"
                              fill="none"
                              stroke="#ec1b53"
                              strokeWidth="1.2"
                            />
                            <path
                              id="Контур_2"
                              data-name="Контур 2"
                              d="M282.866,62.56a6.538,6.538,0,0,1,6.53,6.53.384.384,0,1,0,.768,0,7.307,7.307,0,0,0-7.3-7.3.384.384,0,1,0,0,.768Z"
                              transform="translate(-270.19 -59.103)"
                              fill="#ec1b53"
                            />
                            <path
                              id="Контур_3"
                              data-name="Контур 3"
                              d="M282.865,115.524a4.231,4.231,0,0,1,4.226,4.226.384.384,0,1,0,.768,0,5,5,0,0,0-4.994-4.994.384.384,0,0,0,0,.768Z"
                              transform="translate(-270.189 -109.762)"
                              fill="#ec1b53"
                            />
                            <path
                              id="Контур_4"
                              data-name="Контур 4"
                              d="M282.866,168.491a1.923,1.923,0,0,1,1.921,1.921.384.384,0,0,0,.768,0,2.692,2.692,0,0,0-2.689-2.689.384.384,0,0,0,0,.768Z"
                              transform="translate(-270.19 -160.424)"
                              fill="#ec1b53"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className="text">03-0000000</span>
                </a>
                <a href="mailto:office@yyy.co.il" className="footer__link-icon">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.896"
                      height="15.49"
                      viewBox="0 0 23.896 15.49"
                    >
                      <g id="Messages" transform="translate(0.6 0.6)">
                        <path
                          id="Контур_50"
                          data-name="Контур 50"
                          d="M19.859,2.382H2.837l8.511,5.954ZM0,2.382A2.647,2.647,0,0,1,2.837,0H19.859A2.647,2.647,0,0,1,22.7,2.382v9.527a2.647,2.647,0,0,1-2.837,2.382H2.837A2.647,2.647,0,0,1,0,11.908Z"
                          fill="none"
                          stroke="#ec1b53"
                          strokeWidth="1.2"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">office@yyy.co.il</span>
                </a>
                <p className="footer__link-icon">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="Сгруппировать_74424"
                        data-name="Сгруппировать 74424"
                        transform="translate(-961 -141.59)"
                      >
                        <g
                          id="Контур_39351"
                          data-name="Контур 39351"
                          transform="translate(961 141.59)"
                          fill="none"
                        >
                          <path
                            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
                            stroke="none"
                          />
                          <path
                            d="M 12 1.200000762939453 C 9.115209579467773 1.200000762939453 6.403099060058594 2.323400497436523 4.363250732421875 4.363250732421875 C 2.323400497436523 6.403099060058594 1.200000762939453 9.115209579467773 1.200000762939453 12 C 1.200000762939453 14.88479042053223 2.323400497436523 17.59690093994141 4.363250732421875 19.63674926757812 C 6.403099060058594 21.67659950256348 9.115209579467773 22.79999923706055 12 22.79999923706055 C 14.88479042053223 22.79999923706055 17.59690093994141 21.67659950256348 19.63674926757812 19.63674926757812 C 21.67659950256348 17.59690093994141 22.79999923706055 14.88479042053223 22.79999923706055 12 C 22.79999923706055 9.115209579467773 21.67659950256348 6.403099060058594 19.63674926757812 4.363250732421875 C 17.59690093994141 2.323400497436523 14.88479042053223 1.200000762939453 12 1.200000762939453 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z"
                            stroke="none"
                            fill="#ec1b53"
                          />
                        </g>
                        <line
                          id="Линия_466"
                          data-name="Линия 466"
                          y1="5"
                          x2="6"
                          transform="translate(972.5 151.09)"
                          fill="none"
                          stroke="#ec1b53"
                          strokeLinecap="round"
                          strokeWidth="1.2"
                        />
                        <line
                          id="Линия_467"
                          data-name="Линия 467"
                          y2="5"
                          transform="translate(972.5 151.09)"
                          fill="none"
                          stroke="#ec1b53"
                          strokeLinecap="round"
                          strokeWidth="1.2"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">
                    שעות פעילות: א'-ה' 9:00-19:00 ו' וערבי חג 9:00-15:00 טלפון:
                    03-6005141
                  </span>
                </p>
              </div>
              <ul className="footer__socials-list">
                <li className="footer__socials-item">
                  <a href="#" className="footer__socials-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <g
                        id="Сгруппировать_792"
                        data-name="Сгруппировать 792"
                        transform="translate(0 0.383)"
                      >
                        <g
                          id="Прямоугольник_1902"
                          data-name="Прямоугольник 1902"
                          transform="translate(0 -0.383)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                        >
                          <rect width="32" height="32" rx="10" stroke="none" />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="31"
                            height="31"
                            rx="9.5"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Эллипс_357"
                          data-name="Эллипс 357"
                          transform="translate(10 9.617)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                        >
                          <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
                          <circle cx="6.5" cy="6.5" r="6" fill="none" />
                        </g>
                        <g
                          id="Эллипс_358"
                          data-name="Эллипс 358"
                          transform="translate(22 4.617)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="3"
                        >
                          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
                          <circle cx="1.5" cy="1.5" fill="none" />
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="footer__socials-item">
                  <a href="#" className="footer__socials-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.901"
                      height="26.799"
                      viewBox="0 0 13.901 26.799"
                    >
                      <path
                        id="facebook"
                        d="M16.732,4.284h2.355V.182A30.413,30.413,0,0,0,15.656,0c-3.4,0-5.722,2.136-5.722,6.062V9.675H6.187V14.26H9.934V25.8h4.594V14.261h3.6L18.7,9.676H14.528V6.516c0-1.325.358-2.233,2.2-2.233Z"
                        transform="translate(-5.687 0.5)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                      />
                    </svg>
                  </a>
                </li>
                <li className="footer__socials-item">
                  <a href="#" className="footer__socials-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.027"
                      height="25.028"
                      viewBox="0 0 25.027 25.028"
                    >
                      <g id="call" transform="translate(0.418 0.465)">
                        <g
                          id="Сгруппировать_6"
                          data-name="Сгруппировать 6"
                          transform="translate(0 0)"
                        >
                          <g
                            id="Сгруппировать_5"
                            data-name="Сгруппировать 5"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Контур_1"
                              data-name="Контур 1"
                              d="M23.675,19.2,21,17.414l-2.379-1.586a1.054,1.054,0,0,0-1.415.23L15.731,17.95a1.049,1.049,0,0,1-1.339.278,17.143,17.143,0,0,1-4.787-3.689A17.265,17.265,0,0,1,5.916,9.752a1.049,1.049,0,0,1,.278-1.339L8.087,6.941a1.054,1.054,0,0,0,.23-1.415L6.779,3.219,4.946.469A1.054,1.054,0,0,0,3.527.15L1.413,1.419A2.583,2.583,0,0,0,.24,2.937C-.338,5.045-.455,9.695,7,17.147s12.1,7.336,14.21,6.757a2.583,2.583,0,0,0,1.518-1.173l1.268-2.114A1.054,1.054,0,0,0,23.675,19.2Z"
                              transform="translate(0 0)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="0.8"
                            />
                            <path
                              id="Контур_2"
                              data-name="Контур 2"
                              d="M282.781,62.39a5.092,5.092,0,0,1,5.086,5.086.3.3,0,1,0,.6,0,5.691,5.691,0,0,0-5.685-5.685.3.3,0,0,0,0,.6Z"
                              transform="translate(-266.117 -59.698)"
                              fill="#fff"
                            />
                            <path
                              id="Контур_3"
                              data-name="Контур 3"
                              d="M282.78,115.354a3.3,3.3,0,0,1,3.291,3.291.3.3,0,1,0,.6,0,3.894,3.894,0,0,0-3.889-3.889.3.3,0,0,0,0,.6Z"
                              transform="translate(-267.968 -108.86)"
                              fill="#fff"
                            />
                            <path
                              id="Контур_4"
                              data-name="Контур 4"
                              d="M282.781,168.321a1.5,1.5,0,0,1,1.5,1.5.3.3,0,0,0,.6,0,2.1,2.1,0,0,0-2.094-2.094.3.3,0,0,0,0,.6Z"
                              transform="translate(-268.562 -159.458)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="footer__socials-item">
                  <a href="#" className="footer__socials-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.496"
                      height="24"
                      viewBox="0 0 31.496 24"
                    >
                      <g id="Messages" transform="translate(0.4)">
                        <rect
                          id="Прямоугольник_117"
                          data-name="Прямоугольник 117"
                          width="31"
                          height="24"
                          fill="none"
                        />
                        <path
                          id="Контур_50"
                          data-name="Контур 50"
                          d="M26.859,3.382c-1.182-.575-21.881-1.089-23.022,0s5.755,8.454,11.511,8.454S28.041,3.957,26.859,3.382ZM0,3.382A3.642,3.642,0,0,1,3.837,0H26.859A3.642,3.642,0,0,1,30.7,3.382V16.908a3.642,3.642,0,0,1-3.837,3.382H3.837A3.642,3.642,0,0,1,0,16.908Z"
                          transform="translate(0 1.681)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="0.8"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__bottom">
            <p className="footer__copyright">
              כל הזכויות שמורות ל <span>YYY</span>
            </p>
            <p className="footer__power-by">
              Power By <span>WebXP</span>
            </p>
          </div>
        </div>
      </footer>
      {/*
      <div className="form-credentials-section__buttons-container mobile">
        <button
          type="submit"
          className="btn footer-mobile"
          style={{ justifyContent: "space-between", display: "flex" }}
        >
          <div style={{ flex: 1}}>
            <NavLink
              to="/"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.516"
                  height="25.445"
                  viewBox="0 0 25.516 25.445"
                >
                  <path
                    id="home_1_"
                    data-name="home (1)"
                    d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                    transform="translate(0.771 0.601)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>
              <p className="text1">ראשי</p>
            </NavLink>
          </div>
          <div style={{ flex: 1 }}>
          <NavLink to="/contact-us" >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={27}
                height={26}
                viewBox="0 0 27 26"
              >
                <g
                  id="Сгруппировать_1499"
                  data-name="Сгруппировать 1499"
                  transform="translate(1177 -2115)"
                >
                  <g
                    id="Эллипс_411"
                    data-name="Эллипс 411"
                    transform="translate(-1177 2115)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_412"
                    data-name="Эллипс 412"
                    transform="translate(-1159 2122)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_413"
                    data-name="Эллипс 413"
                    transform="translate(-1175 2132)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <line
                    id="Линия_524"
                    data-name="Линия 524"
                    x2={10}
                    y2={4}
                    transform="translate(-1168.5 2120.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                  <line
                    id="Линия_525"
                    data-name="Линия 525"
                    y1={6}
                    x2={9}
                    transform="translate(-1166.5 2128.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </span>
            <p className="text1">צרו קשר</p>
          </NavLink>
          </div>
          <div style={{ flex: 1, height: '100%' }}>
          <NavLink
            to="/my-bank"
          >
            <span className="icon">
              <svg
                id="Artboard_1"
                data-name="Artboard 1"
                xmlns="http://www.w3.org/2000/svg"
                width="23.901"
                height="24.498"
                viewBox="0 0 23.901 24.498"
              >
                <path
                  id="Контур_10114"
                  data-name="Контур 10114"
                  d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                  transform="translate(6.338 -33.519)"
                  fill="#707070"
                />
                <path
                  id="Контур_10115"
                  data-name="Контур 10115"
                  d="M16,54h.891v.891H16V54Z"
                  transform="translate(6.12 -35.737)"
                  fill="#707070"
                />
                <path
                  id="Контур_10116"
                  data-name="Контур 10116"
                  d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                  transform="translate(-15.361 -30.746)"
                  fill="#707070"
                />
                <path
                  id="Контур_10117"
                  data-name="Контур 10117"
                  d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                  transform="translate(-21 -29.083)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2304"
                  data-name="Прямоугольник 2304"
                  width="1.4"
                  height="2.8"
                  transform="translate(7.107 2.8)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2305"
                  data-name="Прямоугольник 2305"
                  width="0.7"
                  height="2.8"
                  transform="translate(9.207)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2306"
                  data-name="Прямоугольник 2306"
                  width="0.7"
                  height="2.8"
                  transform="translate(13.407 8.399)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2307"
                  data-name="Прямоугольник 2307"
                  width="1.4"
                  height="1.4"
                  transform="translate(14.807 11.899)"
                  fill="#707070"
                />
                <path
                  id="Контур_10118"
                  data-name="Контур 10118"
                  d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                  transform="translate(-18.31 -13.535)"
                  fill="#707070"
                />
                <path
                  id="Контур_10119"
                  data-name="Контур 10119"
                  d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                  transform="translate(-20.664 -14.17)"
                  fill="#707070"
                />
                <path
                  id="Контур_10120"
                  data-name="Контур 10120"
                  d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                  transform="translate(-42.772 -20.762)"
                  fill="#707070"
                />
                <path
                  id="Контур_10121"
                  data-name="Контур 10121"
                  d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                  transform="translate(-37.854 -20.969)"
                  fill="#707070"
                />
                <rect
                  id="Прямоугольник_2308"
                  data-name="Прямоугольник 2308"
                  width="0.7"
                  height="0.7"
                  transform="translate(9.207 3.5)"
                  fill="#707070"
                />
              </svg>
            </span>
            <p className="text1">הקופה שלי</p>

          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
            onClick={() => goToCartByWishlist()}
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.846"
                height="24.798"
                viewBox="0 0 19.846 24.798"
              >
                <g
                  id="Сгруппировать_74057"
                  data-name="Сгруппировать 74057"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Artboard_2"
                    data-name="Artboard 2"
                    d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                    transform="translate(-74.992 -18.61)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth={1}
                  />
                  <path
                    id="heart"
                    d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                    transform="translate(-25.26 -15.21)"
                    fill="#ec1b53"
                  />
                </g>
              </svg>
            </span>
            <p className="text1">סל המתנות</p>
          </NavLink>
          </div>
          <div style={{ flex: 1 }}>
          <NavLink
            to="/my-events"
            onClick={() => setMyEventsActiveTab(0)}
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29.959"
                height="22.842"
                viewBox="0 0 29.959 22.842"
              >
                <g
                  id="Сгруппировать_74063"
                  data-name="Сгруппировать 74063"
                  transform="translate(-14 0.5)"
                >
                  <g
                    id="Artboard_3"
                    data-name="Artboard 3"
                    transform="translate(22.214 0)"
                  >
                    <path
                      id="Контур_38424"
                      data-name="Контур 38424"
                      d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                      transform="translate(-24.307 -20.873)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38425"
                      data-name="Контур 38425"
                      d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                      transform="translate(-20.77 -14.613)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38426"
                      data-name="Контур 38426"
                      d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                      transform="translate(-14.469 -14.389)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_526"
                      data-name="Линия 526"
                      y2="4.793"
                      transform="translate(9.839 13.419)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_527"
                      data-name="Линия 527"
                      y2="8.147"
                      transform="translate(7.443 11.023)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_528"
                      data-name="Линия 528"
                      y2="11.684"
                      transform="translate(5.047 8.445)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_529"
                      data-name="Линия 529"
                      y2="7.055"
                      transform="translate(2.651 14.033)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <circle
                      id="Эллипс_414"
                      data-name="Эллипс 414"
                      cx="1.438"
                      cy="1.438"
                      r="1.438"
                      transform="translate(10.319)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38427"
                      data-name="Контур 38427"
                      d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                      transform="translate(-27.244 -17.922)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38428"
                      data-name="Контур 38428"
                      d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                      transform="translate(-31.988 -10.775)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38429"
                      data-name="Контур 38429"
                      d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                      transform="translate(-20.474 -9.487)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38430"
                      data-name="Контур 38430"
                      d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                      transform="translate(-28.181 -7.104)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                  <g
                    id="Сгруппировать_1710"
                    data-name="Сгруппировать 1710"
                    transform="translate(14 2.322)"
                  >
                    <g
                      id="Контур_10101"
                      data-name="Контур 10101"
                      transform="translate(0 1.369)"
                      fill="#3c9e1c"
                    >
                      <path
                        d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                        stroke="none"
                      />
                      <path
                        d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                        stroke="none"
                        fill="#fff"
                      />
                    </g>
                    <text
                      id="_"
                      data-name="+"
                      transform="translate(6.845 12.059)"
                      fill="#fff"
                      fontSize="11"
                      fontFamily="Assistant-SemiBold, Assistant"
                      fontWeight="600"
                    >
                      <tspan
                        x="2.783"
                        y="0"
                      >
                        +
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
            </span>
            <p className="text1">אירוע חדש</p>


          </NavLink>
          </div>

        </button>
      </div>*/}
    </>
  );
}

export default Footer;
