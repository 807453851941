import React, { useEffect, useRef, useState } from "react";
import { greenPopupBg } from "../Assets/images";
import useClickOutside from "../hooks/useClickOutside";
import axios from "axios";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";

import {
  useNavigate,
} from "react-router-dom";
function TooFarDate({ handleCloseExpiredPopup, isLoggedIn, userId, eventId, eventName, selectedDay, selectedMonth, selectedYear, eventData }) {
  const navigate = useNavigate();

  //const refIsEventExpPopup = useClickOutside(handleClose);
    useEffect(()=>{
console.log("*************",userId)
    },[userId])

  useEffect(() => {
//    updatePopupState(true);
  }, []);
  const remindMe=()=>{
    const date = new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`);
    const isoDate = date.toISOString();
    if(isLoggedIn)
    {
   
        const sendObj = {
        userId: userId, 
        wishlistCategoryID: eventId,
        eventName: eventName,
        eventDate: isoDate 

      }
      axios
      .post(`${API_DOMAIN}/api/ProductCategory/addReminder`, sendObj)
      .then((response) => {
        // console.log(response.data);
        if (response.data.result) {
    
        } else {
        }
      })
      .catch((error) => {

        alert("Error, please try again");
      });
  }
  const sendObj = {
    userId: userId,
    eventName: eventName,
    name: eventName,
    eventDate: isoDate,
    description: "description",
    productsIdList: [],
  //  eventDate: new Date(utcDate).toISOString(),
    wishListObj: JSON.stringify(eventData)
  };
  
  axios
    .post(`${API_DOMAIN}/api/ProductCategory/CreateWishlist`, sendObj)
    .then((response) => {
      // Handle success
      console.log("Wishlist created successfully:", response.data);
      // You can also update the UI or perform other actions here
    })
    .catch((error) => {
      // Handle error
      console.error("Error creating wishlist:", error);
      // Optionally, display an error message to the user
    });
  

  if(isLoggedIn){
      alert("נשלח לך תזכורת חודש לפני האירוע")
      navigate("/")
    }
    else{
      navigate("/credentials-reminder")
    }
  }

  return (
    <div
  id="code-not-right-popup-pink"
  aria-hidden="true"
  className="popup popup_show"
  style={{zIndex:'999999'}}
>
  <div className="popup__wrapper">
    <div className="popup__content" 
   // ref={refIsEventExpPopup}
    >
      <div className="popup__bg-ibg">
        <img src={greenPopupBg} alt="pink hearts" />
      </div>
      <button
        data-close=""
        type="button"
        onClick={handleCloseExpiredPopup} 
        className="popup__close"
      />
      <div className="popup__body">
        <div className="popup__image-ibg_contain">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103.089"
            height={124}
            viewBox="0 0 103.089 124"
          >
            <g
              id="Сгруппировать_1194"
              data-name="Сгруппировать 1194"
              transform="translate(-3955.911 -866.532)"
            >
              <path
                id="Контур_10156"
                data-name="Контур 10156"
                d="M53.133,17.033a9.7,9.7,0,0,1,16.644,0l41.831,69.895a9.7,9.7,0,0,1-8.322,14.679H19.624A9.7,9.7,0,0,1,11.3,86.927L27.858,59.264Z"
                transform="translate(3946 871.572)"
                fill="#f8af1d"
              />
              <text
                id="_"
                data-name="!"
                transform="translate(4007.455 962.532)"
                fill="none"
                stroke="#fff"
                strokeWidth={1}
                fontSize={93}
                fontFamily="Assistant-Bold, Assistant"
                fontWeight={700}
              >
                <tspan x="15.438" y={0}>
                  !
                </tspan>
              </text>
            </g>
          </svg>
        </div>
        <h3 className="popup__title">מועד האירוע גדול מחודש<br/>
        האם להזכיר לתזכר אותך חודש לפני?</h3>
        <div className="popup__buttons-container">
        <button
           onClick={remindMe} 
           className="btn-white">
            כן תזכרו אותי
          </button>
          <button
           onClick={handleCloseExpiredPopup} 
           className="btn-white">
           להחליף תאריך
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default TooFarDate;
