// useClickOutsideMulti.js
import { useEffect, useRef } from "react";

export const useClickOutsideMulti = () => {
  const refMap = useRef(new Map());

  useEffect(() => {
    const handleClickOutside = event => {
      refMap.current.forEach((onClickOutside, ref) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const addClickOutsideRef = (ref, onClickOutside) => {
    refMap.current.set(ref, onClickOutside);
  };

  const removeClickOutsideRef = (ref) => {
    refMap.current.delete(ref);
  };

  return { addClickOutsideRef, removeClickOutsideRef };
};
