import React, { useEffect, useState } from "react";

function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const distance = targetTime - now;

    const days =
      Math.floor(distance / (1000 * 60 * 60 * 24)) < 10
        ? "0" + Math.floor(distance / (1000 * 60 * 60 * 24))
        : Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours =
      Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) < 10
        ? "0" +
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes =
      Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) < 10
        ? "0" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds =
      Math.floor((distance % (1000 * 60)) / 1000) < 10
        ? "0" + Math.floor((distance % (1000 * 60)) / 1000)
        : Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  // const [timer, setTimer] = useState(calculateTimeLeft());
  const [timer, setTimer] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    if (targetDate) {
      const countdown = setInterval(() => {
        setTimer(calculateTimeLeft());
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    }
  }, [targetDate]);

  return (
    <div className="timer-topbar__body">
      <div className="timer-topbar__section seconds">
        <div className="timer-topbar__section-number">{timer.seconds}</div>
        <div className="timer-topbar__section-name">שניות</div>
      </div>
      <div className="timer-topbar__section minutes">
        <div className="timer-topbar__section-number">{timer.minutes}</div>
        <div className="timer-topbar__section-name">דקות</div>
      </div>
      <div className="timer-topbar__section hours">
        <div className="timer-topbar__section-number">{timer.hours}</div>
        <div className="timer-topbar__section-name">שעות</div>
      </div>
      <div className="timer-topbar__section days">
        <div className="timer-topbar__section-number">{timer.days}</div>
        <div className="timer-topbar__section-name">ימים</div>
      </div>
    </div>
  );
}

export default CountdownTimer;
