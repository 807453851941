import React, { useEffect, useRef, useState } from "react";
import { greenPopupBg } from "../Assets/images";

function CodeNotRightPopup({ updatePopupState, updateCodeNotRightPopup, codeNotRightMessage,resendOTPHandler }) {
  function closeCodeNotRightPopup() {
    updatePopupState(false);
    updateCodeNotRightPopup(false);
  }

  return (
    <div
      id="code-not-right-popup-pink"
      aria-hidden="true"
      className="popup popup_show"
    >
      <div className="popup__wrapper">


        <div className="popup__content">
          <div className="popup__bg-ibg">
            <img src={greenPopupBg} alt="pink hearts" />
          </div>
          <button
            data-close
            type="button"
            onClick={() => {
              updateCodeNotRightPopup(false);
              updatePopupState(false);
            }}
            className="popup__close"
          ></button>
          <div className="popup__body">
            <div className="popup__image-ibg_contain">
            <svg xmlns="http://www.w3.org/2000/svg" width="103.089" height="124" viewBox="0 0 103.089 124">
							<g id="Сгруппировать_1194" data-name="Сгруппировать 1194" transform="translate(-3955.911 -866.532)">
								<path id="Контур_10156" data-name="Контур 10156" d="M53.133,17.033a9.7,9.7,0,0,1,16.644,0l41.831,69.895a9.7,9.7,0,0,1-8.322,14.679H19.624A9.7,9.7,0,0,1,11.3,86.927L27.858,59.264Z" transform="translate(3946 871.572)" fill="#f8af1d"></path>
								<text id="_" data-name="!" transform="translate(4007.455 962.532)" fill="none" stroke="#fff" stroke-width="1" font-size="93" font-family="open sans hebrew,sans-serif!important;" font-weight="700">
									<tspan x="15.438" y="0">!</tspan>
								</text>
							</g>
						</svg>
            </div>
            <h3 className="popup__title">{codeNotRightMessage}</h3>
            {/* <h3 className="popup__title">הקוד שהזנת שגוי</h3> */}
            <div className="popup__buttons-container">
              <button
                onClick={(e) => {
                  resendOTPHandler(e)
                  updateCodeNotRightPopup(false);
                  updatePopupState(false);
                }}
                className="btn-white"
              >
                שלח לי קוד חדש
              </button>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
}

export default CodeNotRightPopup;



// <div class="popup__content">
//   <div class="popup__bg-ibg">
//     <img src="img/popups/popup-green-bg.png" alt="green hearts">
//   </div>
//   <button data-close="" type="button" class="popup__close"></button>
//   <div class="popup__body">

//     <div class="popup__image-ibg_contain">
//       <svg xmlns="http://www.w3.org/2000/svg" width="103.089" height="124" viewBox="0 0 103.089 124">
//         <g id="Сгруппировать_1194" data-name="Сгруппировать 1194" transform="translate(-3955.911 -866.532)">
//           <path id="Контур_10156" data-name="Контур 10156" d="M53.133,17.033a9.7,9.7,0,0,1,16.644,0l41.831,69.895a9.7,9.7,0,0,1-8.322,14.679H19.624A9.7,9.7,0,0,1,11.3,86.927L27.858,59.264Z" transform="translate(3946 871.572)" fill="#f8af1d"></path>
//           <text id="_" data-name="!" transform="translate(4007.455 962.532)" fill="none" stroke="#fff" stroke-width="1" font-size="93" font-family="Assistant-Bold, Assistant" font-weight="700">
//             <tspan x="15.438" y="0">!</tspan>
//           </text>
//         </g>
//       </svg>
//     </div>
//     <h3 class="popup__title">הקוד שהזנת שגוי</h3>

//     <div class="popup__buttons-container">
//       <a href="#" class="btn-white">שלח לי קוד חדש</a>
//     </div>

//   </div>
// </div>