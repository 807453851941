import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { blessingBg, blessingBgNoPeople, logoWithBg } from "../Assets/images";
import ExitPopup from "../Popups/ExitPopup"; // Import ExitPopup component

function EventCreated({ setMyEventsActiveTab }) {
  const navigate = useNavigate();
  const [showExitPopup, setShowExitPopup] = useState(false); // State variable to control the visibility of the popup

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials-new-wishlist/");
    }
  }, []);

  return (
    <>
<div className={`${showExitPopup ? "hiiii" : ""}`}></div>
    <main className={`event-created ${showExitPopup ? "blurred" : ""}`} data-fullscreen>
      <section className="event-created__main main-event-created">
        <div className="main-event-created__bg-image-ibg">
          <img src={blessingBg} className="img-people" alt="bg" />
          <img src={blessingBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="main-event-created__content-window">
          <h1 className="main-event-created__title">
            תהליך ההזמנה לאירוע שלך הסתיים צוות אתר מתרגש יחד איתך{" "}
          </h1>
          <div className="main-event-created__logo">
            <img src={logoWithBg} alt="Logo" />
          </div>
          <div className="main-event__btn-wrapper">
            <div className="main-event-created__btn-container btn-container_center">
              <NavLink
                to="/my-events"
                onClick={() => {
                  setMyEventsActiveTab(0);
                }}
                className="btn"
              >
                יש לך אירוע נוסף
              </NavLink>
            </div>
            <div className="main-event-created__btn-container btn-container_center">
              <button
                onClick={() => setShowExitPopup(true)} // Show the ExitPopup when this button is clicked
                className="btn-white"
              >
                אין לי אירוע
              </button>
            </div>
          </div>
        </div>
      </section>
      {showExitPopup && ( // Render the ExitPopup component conditionally based on showExitPopup state
        <ExitPopup
          updateVerifyNumberPopup={setShowExitPopup} // Pass function to update showExitPopup state to the ExitPopup component
          updatePopupState={() => setShowExitPopup(false)} // Pass function to update showExitPopup state to the ExitPopup component
        />
      )}
    </main>
    </>
  );
}

export default EventCreated;
