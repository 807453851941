import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Rating } from "react-simple-star-rating";
import { API_DOMAIN } from "../Libs/config";

export default function ProductPlaceholder({
  // data: { id, imgUrl, title, btnText, text, oldPrice, currentPrice, currency },
  data,
  updatePopupState,
  addItemHandler,
  openImagePopup,
  currWishlist,
  setCurrWishlist,
  currUserObject,
  newEvent,
}) {
  const navigate = useNavigate();

  const [rating, setRating] = useState(5);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    console.log("rate", rate);
    // other logic
  };

  const handleImageDoubleClick = (event, swiperRef) => {
    // Zoom the image
    event.preventDefault();
    const mainSwiperInstance = swiperRef.current.swiper;
    mainSwiperInstance.zoom.toggle();
  };

  const handleCardClick = (e) => {
    const { target } = e;
    
    if (
      target.closest(".button.search") ||
      target.closest(".single-products-card__text-content") ||
      target.closest(".button.favorites")
      )
      return;
      console.log(target);
    // Handle card click to open one root
    navigate(`/single-product/${data?.id}`);
  };

  const handleButtonClick = (event, id) => {
    // Handle button click to open another root
    event.stopPropagation();
    console.log("product id", id);
    updatePopupState(true);
    let totalWishlistPrice = 0;
    console.log(currWishlist.whishlistItemsDetailsList);
    currWishlist.whishlistItemsDetailsList.map(
      (prod) => (totalWishlistPrice += Number(prod.price))
    );
    addItemHandler({
      added: true,
      prodPrice: data.price,
      wishlistPrice: (totalWishlistPrice += Number(data.price)),
    });
    const paramsObj = {
      userId: currUserObject?.user?.id,
      wishlistId: currWishlist.whishlistObject?.id,
      description: currWishlist.whishlistObject.description,
      productsIdList: [id],
    };

    axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
        paramsObj
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          console.log("data that we have got", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.wishlist.whishlistItemsList,
            whishlistObject: response.data.wishlist.whishlistObject,
            whishlistItemsDetailsList:
              response.data.wishlist.whishlistItemsDetailsList,
          });
          /*  setCurrWishlist((prevObj) => {
            const prevArr = prevObj.whishlistItemsList;
            const newArr = [
              ...prevArr,
              {
                id: data.id,
                productName: data.name,
                productsId: "",
                providerId: data.providerId,
                providerName: "",
                status: "not buy",
                wishlistId: currWishlist.whishlistObject.id,
              },
            ];
            const newObj = prevObj;
            newObj.whishlistItemsList = newArr;
            return newObj;
          }); */
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
    // navigate("/item-added-to-cart-popup");
  };

  return (
    <>
      {/* {showImagePopup &&
        createPortal(
          <ImagePopup imageUrl={imgUrl} onClose={closeImagePopup} />,
          containerRef.current
        )} */}
      <li
        className="products-list__item swiper-slide placeholder"
        style={{ listStyle: "none" }}
      >
        <div className="products-list__item-content single-products-card">
          <div
            className="single-products-card__body"
          >
            <div className="single-products-card__image-ibg">
              {/* <img src={data.images} alt="1" /> */}
              {/* <div className="single-products-card__buttons">
                {currWishlist &&
                  currWishlist.whishlistItemsList &&
                  currWishlist.whishlistItemsList.some(
                    (item) => item.productsId === data.id
                  ) && (
                    <button type="button" className="button favorites"
                      onClick={(e) => {
                        // handleButtonClick(e, data.id);
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.647"
                        height="35.781"
                        viewBox="0 0 28.647 35.781"
                      >
                        <g
                          id="Компонент_354_12"
                          data-name="Компонент 354 – 12"
                          transform="translate(0.751 0.75)"
                        >
                          <path
                            id="heart"
                            d="M31.193,33.241l6.359,6.426,6.83-6.9a4.577,4.577,0,0,0,0-6.425,4.462,4.462,0,0,0-6.357,0l-.47.476-.471-.476a4.462,4.462,0,0,0-6.357,0,4.577,4.577,0,0,0,0,6.425l.469.473Z"
                            transform="translate(-24.281 -11.381)"
                            fill="#ec1b53"
                            stroke="#ec1b53"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Artboard_2"
                            data-name="Artboard 2"
                            d="M89.1,18.61a10.28,10.28,0,0,1,1.724.672,4.089,4.089,0,0,1,2.026,3.594c.02.946,0,1.893,0,2.882h3.765a2.408,2.408,0,0,1,2.541,2.232q.809,6.016,1.607,12.033c.446,3.359.9,6.717,1.339,10.078a2.383,2.383,0,0,1-1.9,2.735.614.614,0,0,0-.118.055H77.046a2.708,2.708,0,0,1-1.6-1.017,2.3,2.3,0,0,1-.439-1.642q.632-4.8,1.277-9.609.58-4.389,1.166-8.785c.179-1.339.35-2.679.536-4.01a2.392,2.392,0,0,1,2.321-2.062c1.182-.022,2.364-.007,3.547-.008.421-.15.421-.278.421-.406a28.955,28.955,0,0,1,.063-3.04,4.186,4.186,0,0,1,3.536-3.644.928.928,0,0,0,.152-.058Z"
                            transform="translate(-74.992 -18.61)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </button>
                  )}

                <button type="button" className="button delete">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    width="31.515"
                    height="37"
                    viewBox="0 0 31.515 37"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Прямоугольник_1981"
                          data-name="Прямоугольник 1981"
                          width="29.208"
                          height="25.38"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#3c9e1c"
                          strokeWidth="1.4"
                        />
                      </clipPath>
                      <clipPath id="clip-path-2">
                        <rect
                          id="Прямоугольник_1983"
                          data-name="Прямоугольник 1983"
                          width="18.458"
                          height="8.075"
                          fill="none"
                          stroke="#3c9e1c"
                          strokeWidth="1.4"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Trash_can"
                      data-name="Trash can"
                      transform="translate(0 -2.813)"
                    >
                      <g
                        id="Прямоугольник_1980"
                        data-name="Прямоугольник 1980"
                        transform="translate(0 10.354)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      >
                        <rect
                          width="31.515"
                          height="4.614"
                          rx="2.307"
                          stroke="none"
                        />
                        <rect
                          x="0.7"
                          y="0.7"
                          width="30.115"
                          height="3.214"
                          rx="1.607"
                          fill="none"
                        />
                      </g>
                      <line
                        id="Линия_491"
                        data-name="Линия 491"
                        x2="2.092"
                        y2="15.061"
                        transform="translate(8.884 17.722)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeWidth="1.4"
                      />
                      <line
                        id="Линия_492"
                        data-name="Линия 492"
                        y2="15.061"
                        transform="translate(15.605 17.722)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeWidth="1.4"
                      />
                      <line
                        id="Линия_493"
                        data-name="Линия 493"
                        x1="1.935"
                        y2="14.733"
                        transform="translate(19.953 17.703)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeWidth="1.4"
                      />
                      <g
                        id="Группа_масок_833"
                        data-name="Группа масок 833"
                        transform="translate(1.154 14.433)"
                        clipPath="url(#clip-path)"
                      >
                        <g
                          id="Контур_8526"
                          data-name="Контур 8526"
                          transform="translate(8.612 -4.365)"
                          fill="none"
                        >
                          <path
                            d="M-5.19,0H16.826a1.918,1.918,0,0,1,1.81,2.011L16.524,26.479a1.918,1.918,0,0,1-1.81,2.011H-3.079a1.918,1.918,0,0,1-1.81-2.011L-7,2.011A1.918,1.918,0,0,1-5.19,0Z"
                            stroke="none"
                          />
                          <path
                            d="M -5.190471649169922 1.399999618530273 C -5.375249862670898 1.399999618530273 -5.580936431884766 1.638710021972656 -5.598752975463867 1.965187072753906 L -3.488882064819336 26.41834831237793 L -3.488882064819336 26.4786376953125 C -3.488882064819336 26.82835960388184 -3.272671699523926 27.08967971801758 -3.079351425170898 27.08967971801758 L 14.71437835693359 27.08967971801758 C 14.9076976776123 27.08967971801758 15.1239185333252 26.82835960388184 15.1239185333252 26.4786376953125 L 15.1239185333252 26.41834831237793 L 17.23379135131836 1.965187072753906 C 17.21597290039062 1.638711929321289 17.01027488708496 1.399999618530273 16.82549858093262 1.399999618530273 L -5.190471649169922 1.399999618530273 M -5.190471649169922 -1.9073486328125e-06 L 16.82549858093262 -1.9073486328125e-06 C 17.82487869262695 -1.9073486328125e-06 18.63503837585449 0.9003696441650391 18.63503837585449 2.011037826538086 L 16.52391815185547 26.4786376953125 C 16.52391815185547 27.58930969238281 15.71375846862793 28.48967933654785 14.71437835693359 28.48967933654785 L -3.079351425170898 28.48967933654785 C -4.07872200012207 28.48967933654785 -4.888881683349609 27.58930969238281 -4.888881683349609 26.4786376953125 L -7.000001907348633 2.011037826538086 C -7.000001907348633 0.9003696441650391 -6.189842224121094 -1.9073486328125e-06 -5.190471649169922 -1.9073486328125e-06 Z"
                            stroke="none"
                            fill="#3c9e1c"
                          />
                        </g>
                      </g>
                      <g
                        id="Группа_масок_834"
                        data-name="Группа масок 834"
                        transform="translate(6.529 2.813)"
                        clipPath="url(#clip-path-2)"
                      >
                        <g
                          id="Прямоугольник_1982"
                          data-name="Прямоугольник 1982"
                          transform="translate(0 3.461)"
                          fill="none"
                          stroke="#3c9e1c"
                          strokeWidth="1.4"
                        >
                          <rect
                            width="17.304"
                            height="6.922"
                            rx="3.461"
                            stroke="none"
                          />
                          <rect
                            x="0.7"
                            y="0.7"
                            width="15.904"
                            height="5.522"
                            rx="2.761"
                            fill="none"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <button
                  type="button"
                  className="button search"
                  onClick={(e) => openImagePopup(e, data.images)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                  >
                    <g id="loupe" transform="translate(0 0)">
                      <g id="Сгруппировать_33" data-name="Сгруппировать 33">
                        <g id="Сгруппировать_32" data-name="Сгруппировать 32">
                          <path
                            id="Контур_117"
                            data-name="Контур 117"
                            d="M12.768,0A12.768,12.768,0,1,0,25.536,12.768,12.783,12.783,0,0,0,12.768,0Zm0,23.179A10.411,10.411,0,1,1,23.179,12.768,10.423,10.423,0,0,1,12.768,23.179Z"
                            fill="#707070"
                          />
                        </g>
                      </g>
                      <g
                        id="Сгруппировать_35"
                        data-name="Сгруппировать 35"
                        transform="translate(21.503 21.503)"
                      >
                        <g id="Сгруппировать_34" data-name="Сгруппировать 34">
                          <path
                            id="Контур_118"
                            data-name="Контур 118"
                            d="M357.3,356.109l-4.817-4.817a.84.84,0,0,0-1.188,1.188l4.817,4.817a.84.84,0,1,0,1.188-1.188Z"
                            transform="translate(-351.046 -351.046)"
                            fill="#707070"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div> */}

              <button
                href=""
                className="single-products-card__link btn"
                onClick={(e) => {
                  // handleButtonClick(e, data.id);
                }}
              >
 YYY את זה אני רוצה              </button>
            </div>
            <div className="single-products-card__text-content">
              <h4 className="single-products-card__title">awdawd</h4>
              <div className="single-products-card__rating  rating_set">
                <Rating
                  // onClick={handleRating}
                  size={10}
                  // initialValue={rating}
                // className='single-products-card__rating'
                // onPointerEnter={handleRating}
                // onPointerLeave={onPointerLeave}
                // onPointerMove={onPointerMove}
                /* Available Props */
                />
                <div className="rating__body">
                  <div className="rating__active"></div>
                  <div className="rating__items">
                    <input type="radio" className="rating__item" defaultValue="1" name="rating" />
                    <input type="radio" className="rating__item" defaultValue="2" name="rating" />
                    <input type="radio" className="rating__item" defaultValue="3" name="rating" />
                    <input type="radio" className="rating__item" defaultValue="4" name="rating" />
                    <input type="radio" className="rating__item" defaultValue="5" name="rating" />
                  </div>
                </div>
                <div className="rating__value">5</div>
              </div>

              <p className="single-products-card__text">ugikgyk</p>
              <div className="single-products-card__price">
                <div className="new">
                  <span className="money-icon">₪</span>
                  <span className="text">awdwad</span>
                </div>
                <div className="old">
                  <span className="money-icon">₪</span>
                  <span className="text">awdawdwa</span>
                </div>
              </div>
            </div>
            <div className="single-products-card__buttons single-products-card__buttons_mobile">
              <button type="button" className="button delete">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  width="31.515"
                  height="37"
                  viewBox="0 0 31.515 37"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Прямоугольник_1981"
                        data-name="Прямоугольник 1981"
                        width="29.208"
                        height="25.38"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      />
                    </clipPath>
                    <clipPath id="clip-path-2">
                      <rect
                        id="Прямоугольник_1983"
                        data-name="Прямоугольник 1983"
                        width="18.458"
                        height="8.075"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Trash_can"
                    data-name="Trash can"
                    transform="translate(0 -2.813)"
                  >
                    <g
                      id="Прямоугольник_1980"
                      data-name="Прямоугольник 1980"
                      transform="translate(0 10.354)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeWidth="1.4"
                    >
                      <rect
                        width="31.515"
                        height="4.614"
                        rx="2.307"
                        stroke="none"
                      />
                      <rect
                        x="0.7"
                        y="0.7"
                        width="30.115"
                        height="3.214"
                        rx="1.607"
                        fill="none"
                      />
                    </g>
                    <line
                      id="Линия_491"
                      data-name="Линия 491"
                      x2="2.092"
                      y2="15.061"
                      transform="translate(8.884 17.722)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeLinecap="round"
                      strokeWidth="1.4"
                    />
                    <line
                      id="Линия_492"
                      data-name="Линия 492"
                      y2="15.061"
                      transform="translate(15.605 17.722)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeLinecap="round"
                      strokeWidth="1.4"
                    />
                    <line
                      id="Линия_493"
                      data-name="Линия 493"
                      x1="1.935"
                      y2="14.733"
                      transform="translate(19.953 17.703)"
                      fill="none"
                      stroke="#3c9e1c"
                      strokeLinecap="round"
                      strokeWidth="1.4"
                    />
                    <g
                      id="Группа_масок_833"
                      data-name="Группа масок 833"
                      transform="translate(1.154 14.433)"
                      clipPath="url(#clip-path)"
                    >
                      <g
                        id="Контур_8526"
                        data-name="Контур 8526"
                        transform="translate(8.612 -4.365)"
                        fill="none"
                      >
                        <path
                          d="M-5.19,0H16.826a1.918,1.918,0,0,1,1.81,2.011L16.524,26.479a1.918,1.918,0,0,1-1.81,2.011H-3.079a1.918,1.918,0,0,1-1.81-2.011L-7,2.011A1.918,1.918,0,0,1-5.19,0Z"
                          stroke="none"
                        />
                        <path
                          d="M -5.190471649169922 1.399999618530273 C -5.375249862670898 1.399999618530273 -5.580936431884766 1.638710021972656 -5.598752975463867 1.965187072753906 L -3.488882064819336 26.41834831237793 L -3.488882064819336 26.4786376953125 C -3.488882064819336 26.82835960388184 -3.272671699523926 27.08967971801758 -3.079351425170898 27.08967971801758 L 14.71437835693359 27.08967971801758 C 14.9076976776123 27.08967971801758 15.1239185333252 26.82835960388184 15.1239185333252 26.4786376953125 L 15.1239185333252 26.41834831237793 L 17.23379135131836 1.965187072753906 C 17.21597290039062 1.638711929321289 17.01027488708496 1.399999618530273 16.82549858093262 1.399999618530273 L -5.190471649169922 1.399999618530273 M -5.190471649169922 -1.9073486328125e-06 L 16.82549858093262 -1.9073486328125e-06 C 17.82487869262695 -1.9073486328125e-06 18.63503837585449 0.9003696441650391 18.63503837585449 2.011037826538086 L 16.52391815185547 26.4786376953125 C 16.52391815185547 27.58930969238281 15.71375846862793 28.48967933654785 14.71437835693359 28.48967933654785 L -3.079351425170898 28.48967933654785 C -4.07872200012207 28.48967933654785 -4.888881683349609 27.58930969238281 -4.888881683349609 26.4786376953125 L -7.000001907348633 2.011037826538086 C -7.000001907348633 0.9003696441650391 -6.189842224121094 -1.9073486328125e-06 -5.190471649169922 -1.9073486328125e-06 Z"
                          stroke="none"
                          fill="#3c9e1c"
                        />
                      </g>
                    </g>
                    <g
                      id="Группа_масок_834"
                      data-name="Группа масок 834"
                      transform="translate(6.529 2.813)"
                      clipPath="url(#clip-path-2)"
                    >
                      <g
                        id="Прямоугольник_1982"
                        data-name="Прямоугольник 1982"
                        transform="translate(0 3.461)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeWidth="1.4"
                      >
                        <rect
                          width="17.304"
                          height="6.922"
                          rx="3.461"
                          stroke="none"
                        />
                        <rect
                          x="0.7"
                          y="0.7"
                          width="15.904"
                          height="5.522"
                          rx="2.761"
                          fill="none"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
              <button type="button" className="button favorites"
                onClick={(e) => {
                  // handleButtonClick(e, data.id);

                }}
              >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.647"
                  height="35.781"
                  viewBox="0 0 28.647 35.781"
                >
                  <g
                    id="Компонент_354_12"
                    data-name="Компонент 354 – 12"
                    transform="translate(0.751 0.75)"
                  >
                    <path
                      id="heart"
                      d="M31.193,33.241l6.359,6.426,6.83-6.9a4.577,4.577,0,0,0,0-6.425,4.462,4.462,0,0,0-6.357,0l-.47.476-.471-.476a4.462,4.462,0,0,0-6.357,0,4.577,4.577,0,0,0,0,6.425l.469.473Z"
                      transform="translate(-24.281 -11.381)"
                      fill="#ec1b53"
                      stroke="#ec1b53"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Artboard_2"
                      data-name="Artboard 2"
                      d="M89.1,18.61a10.28,10.28,0,0,1,1.724.672,4.089,4.089,0,0,1,2.026,3.594c.02.946,0,1.893,0,2.882h3.765a2.408,2.408,0,0,1,2.541,2.232q.809,6.016,1.607,12.033c.446,3.359.9,6.717,1.339,10.078a2.383,2.383,0,0,1-1.9,2.735.614.614,0,0,0-.118.055H77.046a2.708,2.708,0,0,1-1.6-1.017,2.3,2.3,0,0,1-.439-1.642q.632-4.8,1.277-9.609.58-4.389,1.166-8.785c.179-1.339.35-2.679.536-4.01a2.392,2.392,0,0,1,2.321-2.062c1.182-.022,2.364-.007,3.547-.008.421-.15.421-.278.421-.406a28.955,28.955,0,0,1,.063-3.04,4.186,4.186,0,0,1,3.536-3.644.928.928,0,0,0,.152-.058Z"
                      transform="translate(-74.992 -18.61)"
                      fill="none"
                      stroke="#707070"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
