import React, { useState } from 'react'
import { greenPopupBg } from '../Assets/images'
import { validateFullPhoneNumber, inputMaxCount } from '../Libs/forms';
import axios from 'axios'
import { API_DOMAIN } from '../Libs/config';



function AddSmsPhones({ toggleAddSmsPopup, whishlistId }) {

  const [phoneNumbers, setPhoneNumbers] = useState(['']);

  const handlePhoneNumberChange = (index, e) => {
    e.preventDefault()
    const value = e.target.value;
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handleAddPhoneNumber = (e) => {
    e.preventDefault()
    if (phoneNumbers.every(phoneNumber => phoneNumber.trim() !== '')) {
      setPhoneNumbers([...phoneNumbers, '']);
    }
  };

  console.log('phoneNumbers:', phoneNumbers)

  const handleRemovePhoneNumber = (index, e) => {
    e.preventDefault()
    const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const submitPhones = (e) => {
    e.preventDefault()
    const arePhonesValid = [];
    let allValid = false;
    phoneNumbers.map((phone, index) => {
      const telInputFirst = document.getElementById(`form-credentials-section__tel-${index}`);
      const isTelFirst = validateFullPhoneNumber(telInputFirst);
      console.log('isTelFirst', isTelFirst)
      arePhonesValid.push(isTelFirst)
    })

    allValid = arePhonesValid.every(value => value === true)

    if (allValid === false) return;
    console.log('arePhonesValid', arePhonesValid)

    const params = { whishlistId, phoneNumbers }

    axios
      .post(
        `${API_DOMAIN}/api/DummyAppiSMSPhones`,
        params
      )
      .then((response) => {
        console.log(response.data);

      })
      .catch((error) => {
        // setLoading(false);
        // console.error(error.response.data.message);
        console.log(error);

      });

  }


  return (
    <div
      id="we-send-you-sms-popup-green"
      aria-hidden="true"
      className="popup popup_show popup-phones"
    >
      <div className="popup__wrapper">
        <div className="popup__content">
          <div className="popup__bg-ibg">
            <img src={greenPopupBg} alt="green hearts" />
          </div>
          <button
            data-close
            onClick={toggleAddSmsPopup}
            type="button"
            className="popup__close"
          />
          <div className="popup__body">
            <div className="popup__image-ibg_contain">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="164.456"
                height={75}
                viewBox="0 0 164.456 75"
              >
                <g
                  id="Сгруппировать_1275"
                  data-name="Сгруппировать 1275"
                  transform="translate(-129.514 -361.195)"
                >
                  <path
                    id="Контур_10157"
                    data-name="Контур 10157"
                    d="M21.514,0H142.941a21.514,21.514,0,0,1,0,43.029H21.514A21.514,21.514,0,0,1,21.514,0Z"
                    transform="translate(129.514 369.081)"
                    fill="#f8af1d"
                  />
                  <text
                    id="_"
                    data-name="****"
                    transform="translate(211.741 419.195)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={1}
                    fontSize={56}
                    fontFamily="Assistant-Bold, Assistant"
                    fontWeight={700}
                  >
                    <tspan x="50.512" y={0}>
                      ****
                    </tspan>
                  </text>
                </g>
              </svg>
            </div>
            <h2 className="popup__title">רשימת מספרי פלאפון</h2>
            {phoneNumbers.map((phoneNumber, index) => (
              <div key={index} className='popup__phone-row'>
                <label
                  htmlFor={`form-credentials-section__tel-${index}`}
                  className="input-wrapper"
                  style={{marginBottom:'0px'}}
                >
                  <span className="input-title">מספר פלאפון</span>
                  <input
                    autoComplete="off"
                    type="number"
                    name="tel-1"
                    id={`form-credentials-section__tel-${index}`}
                    className="input"
                    value={phoneNumber}
                    onInput={(e) => {
                      inputMaxCount(e.target, 10, "phone");
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateFullPhoneNumber(e.target);
                      }
                      handlePhoneNumberChange(index, e)
                    }}
                    onWheel={(e) => e.target.blur()} 
                  />
                  <span className="error-text">
                    מספר פלאפון לא תקין
                  </span>
                </label>

                <button className='popup__phone-remove' onClick={(e) => handleRemovePhoneNumber(index, e)}>-</button>
              </div>
            ))}
            <div className="popup-phones__submit add-phone">
              <button className='btn-white popup__phone-btns' onClick={handleAddPhoneNumber}>הוסף מספר</button>
            </div>
            <div className="popup-phones__submit">
              <button className='btn popup__phone-btns' onClick={submitPhones}>שתף באמצעות sms</button>
            </div>
            <div className="popup__buttons-container">
              <button onClick={toggleAddSmsPopup} className="btn-white">
                סגור/י
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddSmsPhones